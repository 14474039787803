import { showSnackbar } from './notification';
import { emitChecklistSocket } from './utils';
import { resourceManager } from '../helpers';

export const DELETE_SUCCESS = 'checklists/DELETE_SUCCESS';

export const LOAD = 'checklists/LOAD';
export const LOAD_FAIL = 'checklists/LOAD_FAIL';
export const LOAD_SUCCESS = 'checklists/LOAD_SUCCESS';

export const LOAD_VENDORS_SUCCESS = 'checklists/LOAD_VENDORS_SUCCESS';

export const RESET = 'checklists/RESET';

export const UPDATE_SUCCESS = 'checklists/UPDATE_SUCCESS';

export const UPDATE_QUESTIONNAIRE_RESPONSE = 'checklists/UPDATE_QUESTIONNAIRE_RESPONSE';
export const UPDATE_QUESTIONNAIRE_RESPONSE_SUCCESS =
    'checklists/UPDATE_QUESTIONNAIRE_RESPONSE_SUCCESS';
export const UPDATE_QUESTIONNAIRE_RESPONSE_FAIL = 'checklists/UPDATE_QUESTIONNAIRE_RESPONSE_FAIL';

export const UPDATE_QUESTIONNAIRE_REVIEW = 'checklists/UPDATE_QUESTIONNAIRE_REVIEW';
export const UPDATE_QUESTIONNAIRE_REVIEW_SUCCESS = 'checklists/UPDATE_QUESTIONNAIRE_REVIEW_SUCCESS';
export const UPDATE_QUESTIONNAIRE_REVIEW_FAIL = 'checklists/UPDATE_QUESTIONNAIRE_REVIEW_FAIL';

export const deleteChecklist = (checklistId) => {
    return resourceManager({
        method: 'del',
        url: `/checklists/${checklistId}`,
        onSuccess: ({ result, dispatch }) => {
            const deleteAction = { type: DELETE_SUCCESS, result };
            dispatch(
                emitChecklistSocket(
                    result.id,
                    deleteAction,
                    'Update Received: Checklist was deleted'
                )
            );
            dispatch(showSnackbar('Checklist deleted'));
        },
    });
};

export const loadChecklist = (checklistId) => {
    return resourceManager({
        method: 'get',
        url: `/checklists/${checklistId}`,
        onStart: ({ dispatch }) => dispatch({ type: LOAD }),
        onSuccess: ({ result, dispatch }) => {
            dispatch({ type: LOAD_SUCCESS, result });
        },
        onFailure: ({ dispatch, error }) => dispatch({ type: LOAD_FAIL, error }),
    });
};

export const loadChecklistVendors = (checklistId) => {
    return resourceManager({
        method: 'get',
        url: `/checklists/${checklistId}/vendors`,
        onSuccess: ({ result, dispatch }) => dispatch({ type: LOAD_VENDORS_SUCCESS, result }),
    });
};

export const resetChecklist = () => {
    return { type: RESET };
};

export const reloadChecklist = (checklistId) => {
    return resourceManager({
        method: 'get',
        url: `/checklists/${checklistId}`,
        onSuccess: ({ dispatch, getState, result }) => {
            const lastUpdated = getState().checklists.getIn(['checklist', 'updated_at']);

            // Dates are returned as strings, so can be compared
            if (result.updated_at !== lastUpdated) {
                dispatch(
                    showSnackbar('Received New Updates', {
                        dismissAfter: 3500,
                    })
                );
                // Load the template again if new data has come in
                return dispatch(loadChecklist(result.id));
            }
        },
    });
};

export const sendChecklistReminderEmail = (checklistId, questionnaireId) => {
    return resourceManager({
        method: 'post',
        url: `/checklists/${checklistId}/questionnaires/${questionnaireId}/reminder`,
        onSuccess: ({ dispatch }) => {
            dispatch(showSnackbar('Reminder email sent to vendor'));
        },
        onFailure: ({ dispatch, error }) => {
            dispatch(showSnackbar(`Email failed to send: ${error.message}`), { isError: true });
        },
    });
};

export const updateChecklist = (checklistId, data, message) => {
    return resourceManager({
        method: 'put',
        url: `/checklists/${checklistId}`,
        requestOptions: { data },
        onSuccess: ({ result, dispatch }) => {
            const updateAction = { type: UPDATE_SUCCESS, result };
            dispatch(emitChecklistSocket(result.id, updateAction));
            dispatch(showSnackbar(message || 'Checklist saved'));
        },
    });
};

export const updateQuestionnaireResponse = (checklistId, questionnaireId, data, message) => {
    return resourceManager({
        method: 'put',
        url: `/checklists/${checklistId}/questionnaires/${questionnaireId}/responses`,
        requestOptions: { data },
        onStart: ({ dispatch }) => {
            dispatch({ type: UPDATE_QUESTIONNAIRE_RESPONSE, questionnaireId });
        },
        onSuccess: ({ result, dispatch }) => {
            const updateAction = {
                type: UPDATE_QUESTIONNAIRE_RESPONSE_SUCCESS,
                questionnaireId,
                result,
            };
            dispatch(emitChecklistSocket(checklistId, updateAction));
            dispatch(showSnackbar(message || 'Response updated'));

            // Need to return result for `createChecklistFileUploadResponse`
            return result;
        },
        onFailure: ({ dispatch, error }) => {
            dispatch({ type: UPDATE_QUESTIONNAIRE_RESPONSE_FAIL, questionnaireId, error });
        },
    });
};

export const updateQuestionnaireReview = (checklistId, questionnaireId, data, msg, msgOpts) => {
    return resourceManager({
        method: 'put',
        url: `/checklists/${checklistId}/questionnaires/${questionnaireId}/reviews`,
        requestOptions: { data },
        onStart: ({ dispatch }) => dispatch({ type: UPDATE_QUESTIONNAIRE_REVIEW, questionnaireId }),
        onSuccess: ({ result, dispatch }) => {
            const updateAction = {
                type: UPDATE_QUESTIONNAIRE_REVIEW_SUCCESS,
                questionnaireId,
                result,
            };
            dispatch(emitChecklistSocket(checklistId, updateAction));
            dispatch(showSnackbar(msg || 'Item updated', msgOpts));
        },
        onFailure: ({ dispatch, error }) => {
            dispatch({ type: UPDATE_QUESTIONNAIRE_REVIEW_FAIL, questionnaireId, error });
        },
    });
};

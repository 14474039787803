import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { ProjectContentSection } from './components';

export class TemplateProjectContentForm extends PureComponent {
    static propTypes = {
        addConditionalLogicHandler: PropTypes.func.isRequired,
        array: PropTypes.object.isRequired,
        change: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        projectId: PropTypes.number.isRequired,
        projectSections: PropTypes.array.isRequired,
        showFormValidation: PropTypes.bool,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
        useManualNumbering: PropTypes.bool,
        useSectionDividers: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = { openSectionId: null };
    }

    get styles() {
        return require('./index.scss');
    }

    openSectionHandler = (sectionId) => {
        const { openSectionId } = this.state;

        // No section should be selected if the numbers match (open section is being closed)
        const newSectionId = sectionId === openSectionId ? null : sectionId;
        this.setState({ openSectionId: newSectionId });
    };

    render() {
        const {
            addConditionalLogicHandler,
            array,
            change,
            disabled,
            projectId,
            projectSections,
            showFormValidation,
            tagOptions,
            templateVariableOptions,
            useManualNumbering,
            useSectionDividers,
        } = this.props;

        const { openSectionId } = this.state;

        if (projectSections.length === 0) {
            return (
                <div className="text-muted text-center">
                    <em>No sections have been added yet</em>
                </div>
            );
        }

        const projectSectionItems = projectSections.map((projectSection) => {
            return (
                <ProjectContentSection
                    addConditionalLogicHandler={addConditionalLogicHandler}
                    array={array}
                    change={change}
                    disabled={disabled}
                    key={projectSection.id}
                    projectId={projectId}
                    projectSection={projectSection}
                    showForm={openSectionId === projectSection.id}
                    showFormHandler={this.openSectionHandler}
                    showFormValidation={showFormValidation}
                    tagOptions={tagOptions}
                    templateVariableOptions={templateVariableOptions}
                    useManualNumbering={useManualNumbering}
                    useSectionDividers={useSectionDividers}
                />
            );
        });

        return <div className="list-group">{projectSectionItems}</div>;
    }
}

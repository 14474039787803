import { isEmpty } from 'lodash';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { getSectionNumberingString } from '@og-pro/shared-config/helpers';

import { DocxHeading, Label } from '../../..';
import { SDv2Title } from './SDv2Title';

export class Title extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        conditionalSubQuestionNumber: PropTypes.number,
        hasPrompt: PropTypes.bool,
        htmlFor: PropTypes.string,
        isDocx: PropTypes.bool,
        isOGThemeEnabledForComponents: PropTypes.bool,
        isRequired: PropTypes.bool,
        isTitle: PropTypes.bool,
        sectionNumber: PropTypes.number,
        showValidation: PropTypes.bool,
        subsectionNumber: PropTypes.number,
        title: PropTypes.string,
        useManualNumbering: PropTypes.bool,
        useSectionDividers: PropTypes.bool,
        valid: PropTypes.bool,
        warning: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    };

    get sectionNumbering() {
        const { conditionalSubQuestionNumber, sectionNumber, subsectionNumber } = this.props;

        return getSectionNumberingString({
            sectionNumber,
            subsectionNumber,
            subSectionItemNumber: conditionalSubQuestionNumber,
            useLetterForSubsectionItem: false,
        });
    }

    get basicTitle() {
        const { isRequired, isTitle, title } = this.props;

        const defaultTitle = isTitle ? 'Section Name' : 'Question';
        const itemTitle = title || defaultTitle;
        return isRequired ? `${itemTitle}*` : itemTitle;
    }

    get numberedTitle() {
        const { isDocx, sectionNumber } = this.props;

        if (!sectionNumber) {
            return this.basicTitle;
        }

        if (isDocx) {
            return (
                <>
                    {this.sectionNumbering}
                    <t />
                    {this.basicTitle}
                </>
            );
        }

        return `${this.sectionNumbering} ${this.basicTitle}`;
    }

    renderDocx() {
        const {
            conditionalSubQuestionNumber,
            subsectionNumber,
            useManualNumbering,
            useSectionDividers,
        } = this.props;

        const text =
            useManualNumbering || useSectionDividers ? this.numberedTitle : this.basicTitle;
        const style = { marginBottom: 0 };

        // NOTE: these Heading tags translate to different types of Heading depending
        // on the useSectionDividers value. Check docx_helper.js for more info

        // regular question
        let domElement = 'h4';

        // this is the most nested a question can be, below a
        // question of type header and being a conditional question
        if (conditionalSubQuestionNumber && subsectionNumber > 0) {
            domElement = 'h6';
        }

        // this is a second level nesting, whether is a conditional question
        // or a parent question below a question header
        if (conditionalSubQuestionNumber || subsectionNumber > 0) {
            domElement = 'h5';
        }

        return (
            <DocxHeading
                domElement={domElement}
                legacy={React.createElement(domElement, { style }, text)}
                numbering={this.sectionNumbering}
                style={style}
                title={this.basicTitle}
                useSectionDividers={useSectionDividers}
            />
        );
    }

    renderTitle() {
        // `warning` is used to mark a questionnaire response as incomplete without failing
        // validation. Validation will only fail if the response is incomplete and required.
        const { isTitle, showValidation, valid, warning } = this.props;
        const numberedTitle = this.numberedTitle;
        const hasWarning = !isEmpty(warning);

        if (isTitle) {
            return numberedTitle;
        }

        if (!showValidation) {
            if (hasWarning || !valid) {
                // Item has not been completed
                return numberedTitle;
            }
            return (
                <span>
                    <i className="fa fa-check text-success" /> {numberedTitle}
                </span>
            );
        }

        let validationData;
        if (!valid) {
            validationData = ['text-danger', 'times'];
        } else if (hasWarning) {
            validationData = ['text-warning', 'exclamation-triangle'];
        } else {
            validationData = ['text-success', 'check'];
        }

        const [className, icon] = validationData;
        return (
            <span className={className}>
                <i className={`fa fa-${icon}`} /> {numberedTitle}
            </span>
        );
    }

    render() {
        const {
            className,
            conditionalSubQuestionNumber,
            hasPrompt,
            htmlFor,
            isDocx,
            isOGThemeEnabledForComponents,
            isTitle,
        } = this.props;

        if (isDocx) {
            return this.renderDocx();
        }

        if (isOGThemeEnabledForComponents) {
            // TODO: PRO-1885 integrate validation status
            return (
                <SDv2Title
                    hasPrompt={hasPrompt}
                    isChildQuestion={!!conditionalSubQuestionNumber}
                    isSectionHeader={isTitle}
                    title={this.numberedTitle}
                />
            );
        }

        const defaultClassName = isTitle ? 'questionnaire-section-header' : undefined;
        return (
            <Label
                className={className || defaultClassName}
                {...(htmlFor && { htmlFor })}
                label={this.renderTitle()}
            />
        );
    }
}

export function noOp(value) {
    return value;
}

export function maskPhone(value) {
    if (!value) {
        return value;
    }
    if (value.length >= 5) {
        return `${value.slice(0, 5)} ${value.slice(5)}`;
    }
    return value;
}

export function maskNumberWithCommas(value, options = {}) {
    if (!value) return value;

    let formattedValue = value;

    const { maximumFractionDigits } = options;

    if (maximumFractionDigits) {
        // round formatted value to the number of decimal places specified
        formattedValue = Number(value).toFixed(maximumFractionDigits);
    }

    const [numberToTransform, ...decimals] = formattedValue.toString().split('.');

    // https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
    const transformedNumber = numberToTransform.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return [transformedNumber, ...decimals].join('.');
}

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    VendorCertificationSelfReportingForm,
    VerifiedCertificationsDisclaimer,
    VerifiedCertificationsList,
} from './components';
import {
    getVendorCertificationsJS,
    getSelfReportedCertificationsJS,
    getVerifiedCertificationsJS,
} from './selectors';
import { getUserJS } from '../../selectors';
import { loadVendorCertifications } from '../../../actions/adminVendor';
import { LoadingSpinner, LoadingError, PageTitle, Main } from '../../../components';

export const VendorCertifications = ({ isModal }) => {
    const dispatch = useDispatch();
    const styles = require('./index.scss');

    const loading = useSelector((state) => state.adminVendor.get('loadingCertifications'));
    const loadError = useSelector((state) => state.adminVendor.get('loadCertificationsError'));
    const selfReportedCertifications = useSelector(getSelfReportedCertificationsJS);
    const user = useSelector(getUserJS);
    const vendorCertificationsJS = useSelector(getVendorCertificationsJS);
    const verifiedCertifications = useSelector(getVerifiedCertificationsJS);

    useEffect(() => {
        if (!vendorCertificationsJS || vendorCertificationsJS.length === 0) {
            dispatch(loadVendorCertifications(user.vendor.id));
        }
    });

    if (loading) {
        return <LoadingSpinner />;
    }

    if (loadError) {
        return <LoadingError error={loadError} />;
    }

    return (
        <Main className="row">
            <PageTitle title="Certifications" />
            <div
                className={classnames(
                    isModal ? 'col-sm-10 col-sm-offset-1' : 'col-sm-8 col-sm-offset-2',
                    styles.vendorCertifications
                )}
            >
                <div className={styles.header}>
                    <h2>Certifications</h2>
                    <div className={styles.helpText}>
                        Help buyers understand what demographics and certifications your business
                        holds.
                    </div>
                </div>
                {verifiedCertifications?.length > 0 && (
                    <VerifiedCertificationsList verifiedCertifications={verifiedCertifications} />
                )}
                <VendorCertificationSelfReportingForm
                    certifications={selfReportedCertifications}
                    user={user}
                />
                {(!verifiedCertifications || verifiedCertifications.length === 0) && (
                    <VerifiedCertificationsDisclaimer />
                )}
            </div>
        </Main>
    );
};

VendorCertifications.propTypes = {
    isModal: PropTypes.bool,
};

import { createSelector } from 'reselect';

import { getEvaluationModalProposal } from '../selectors';
import { getEvaluationProjectJS } from '../../selectors';

export const getProposalCriteriaScoreFormValues = createSelector(
    [getEvaluationProjectJS, getEvaluationModalProposal],
    (project, proposal) => {
        if (project && proposal) {
            const { scoringCriteria } = project.evaluation;
            return proposal.proposalEvaluation.orderedProposalCriteriaScores.map(
                (orderedProposalCriteriaScore, index) => {
                    const scoringCriterium = scoringCriteria[index];
                    const proposalCriteriaScore = orderedProposalCriteriaScore || {
                        scoring_criterium_id: scoringCriterium.id,
                    };
                    return {
                        ...proposalCriteriaScore,
                        scoringCriterium,
                    };
                }
            );
        }
        return [];
    }
);

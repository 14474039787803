import PropTypes from 'prop-types';
import React from 'react';
import { Box } from '@og-pro/ui';

import { CDSButton } from '../../../../../components';

export const SDv2Toolbar = ({ buttons }) => {
    const styles = require('./index.scss');

    return (
        <Box>
            <Box className={styles.toolsContainer} px={4}>
                <Box fontWeight={500} mr={3}>
                    Tools:
                </Box>
                {buttons.map(({ inProgressButton, ...buttonProps }, index) => (
                    <Box key={index} mr={2}>
                        <CDSButton
                            className={inProgressButton ? styles.inProgressButton : styles.linkLike}
                            size="small"
                            variant={inProgressButton ? undefined : 'text'}
                            {...buttonProps}
                        />
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

SDv2Toolbar.propTypes = {
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            inProgressButton: PropTypes.bool,
        })
    ).isRequired,
};

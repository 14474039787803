import { questionLogicFieldNames } from '@og-pro/shared-config/questionLogics';

const { LINKABLE, LINKABLE_ID } = questionLogicFieldNames;

// Used to prevent potential value collisions between the linkable item ID and project section ID
export const deserializeData = (data) => {
    if (data[LINKABLE] && data[LINKABLE_ID]) {
        return {
            ...data,
            [LINKABLE_ID]: `${data[LINKABLE]}.${data[LINKABLE_ID]}`,
        };
    }
    return data;
};

export const serializeData = (data) => {
    // Decode the encode `LINKABLE_ID` and convert to number unless a sharedId (UUID format)
    const rawLinkableId = data[LINKABLE_ID].split('.')[1];
    const linkableId = Number.isNaN(Number(rawLinkableId)) ? rawLinkableId : Number(rawLinkableId);
    return {
        ...data,
        [LINKABLE_ID]: linkableId,
    };
};

import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { containerStyle, headerStyle, iconStyle, titleStyle } from './styles';
import { qaTagName } from '../../../../constants';

export const FormGroup = ({ Icon, children, title }) => {
    const [showInputs, setShowInputs] = useState(true);

    return (
        <Box sx={containerStyle}>
            <Box sx={headerStyle}>
                <Typography sx={titleStyle} variant="h4">
                    <Icon sx={iconStyle} />
                    {title}
                </Typography>
                <IconButton
                    aria-label={`${showInputs ? 'Collapse' : 'Expand'} ${title}`}
                    onClick={() => setShowInputs(!showInputs)}
                    qaTag={`${qaTagName}-expand-${title}`}
                >
                    {showInputs ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </Box>
            {showInputs && children}
        </Box>
    );
};

FormGroup.propTypes = {
    Icon: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
};

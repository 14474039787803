const each = require('lodash/each');
const snakeCase = require('lodash/snakeCase');
const get = require('lodash/get');
const { default: anchorme } = require('anchorme');

// Takes an array of strings and turns it into a map of identical key, value pairs
exports.listToDict = (list) => {
    const newDict = {};
    each(list, function (val) {
        newDict[snakeCase(val).toUpperCase()] = val;
    });
    return newDict;
};

/**
 * Builds an object from an array of items based on a specified key.
 *
 * @param {Array} items - The array of items to be mapped.
 * @param {string} keyToMap - The property name to map the items by.
 * @param {Object} [opts] - Optional parameters for mapping.
 * @param {Function} [opts.transform] - A function to transform the item before mapping.
 * @returns {Object} A map object with keys derived from the items' specified property.
 */
exports.buildMap = (items, keyToMap, opts) => {
    return items.reduce((map, item) => {
        const key = item[keyToMap];
        if (key !== undefined) {
            let value = item;
            if (opts && opts.transform) {
                value = opts.transform(item);
            }
            map[key] = value;
        }
        return map;
    }, {});
};

exports.buildSet = (items, keyToMap) => {
    return items.reduce((set, item) => {
        const value = item[keyToMap];
        if (value !== undefined) {
            set.add(value);
        }
        return set;
    }, new Set());
};

/**
 * Generates an ordered map from a list of sorted comparators
 *
 * @param {array} orderedList list of sorted comparators
 * @return {object}           ordered sort map
 */
exports.buildCustomSortOrderMap = (orderedList) => {
    return orderedList.reduce((map, item, idx) => {
        map[item] = idx;
        return map;
    }, {});
};

/**
 * Generates a pastel shade color from a string (used to generate user avatar color)
 * https://link.medium.com/4lvjj9yFrV
 *
 * @param  {string} str String of any length
 * @return {string}     HSL color string
 */
exports.stringToHslColor = (str) => {
    // Return dark gray color if no string is provided
    if (!str) {
        return 'hsl(0, 0%, 50%)';
    }

    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash); // eslint-disable-line no-bitwise
    }

    const h = hash % 360;
    return `hsl(${h}, 100%, 20%)`;
};

exports.isNumber = (valueToCheck) => {
    return !Number.isNaN(Number.parseFloat(valueToCheck));
};

exports.isOpenGovAdmin = (user) => {
    if (user && user.email) {
        return !!user.email.match(/^pro-admin(.*?)@opengov\.com$/);
    }
    return false;
};

exports.isTier1SupportUser = (user) => {
    if (user && user.email) {
        return user.email === 'pro-admin+robin@opengov.com';
    }
    return false;
};

exports.currencyFormatter = (value) => {
    const formatter = new Intl.NumberFormat('en-US', {
        currency: 'USD',
        minimumFractionDigits: 2,
        style: 'currency',
    });

    if (!Number.isNaN(Number.parseFloat(value))) {
        return formatter.format(value);
    }

    return value;
};

/**
 * Check if a user is the contract's awarded vendor
 * @param {object} user The user being checked
 * @param {object} contract The contract the user is checking permission for
 * @returns {boolean} Whether the user is the contract's awarded vendor
 */
exports.isUserAwardedVendor = (user, contract) => {
    return (
        user &&
        user.isVendor &&
        contract &&
        contract.contractParty &&
        contract.contractParty.vendor &&
        get(user, 'vendor_id') === contract.contractParty.vendor.id
    );
};

/**
 * Searches timeline `location` strings to replace newlines with `<br /> tags
 * and links with `<a>` tags
 * @param {string} location The timeline `location` string
 * @returns {string} Html compatible string
 */
exports.linkifyLocation = (location) => {
    return anchorme({
        input: location.replace(/\n/g, '<br />'),
        options: {
            attributes: {
                target: '_blank',
            },
        },
    });
};

/**
 * Recursively builds a string of letters from the provided number.
 * When the number exceeds 26, a sequence of letters will be built. Ex. 27 returns 'AA'
 * @param {number} number The number
 * @returns {string} String of letter(s) representing the provided number
 */
const getLetterFromNumber = (number) => {
    const buildLettersFromNumber = (letters, curNumber) => {
        if (curNumber < 0) {
            return letters;
        }
        const startNumber = 'A'.charCodeAt(0);
        const updatedLetters = String.fromCharCode((curNumber % 26) + startNumber) + letters;
        const nextNumber = Math.floor(curNumber / 26) - 1;
        return buildLettersFromNumber(updatedLetters, nextNumber);
    };

    return buildLettersFromNumber('', number - 1);
};

/**
 * Provides a section numbering string up to 3 levels deep or uses the provided manual number
 * @param {string} manualNumber Manual number to use instead of constructing a number string
 * @param {number} sectionNumber First level section number
 * @param {number} subsectionNumber Second level section number
 * @param {number} subSectionItemNumber Third level section number
 * @param {boolean} [useLetterForSubsectionItem=false] Option to use a letter instead of number for the subsection item
 * @param {boolean} [useManualNumbering=false] Option to use `manualNumber` instead of constructing a number string
 * @returns {string} The provided `manualNumber` or a string with the following format: 1.1.1. or 1.1.A.
 */
exports.getSectionNumberingString = ({
    manualNumber,
    sectionNumber,
    subsectionNumber,
    subSectionItemNumber,
    useLetterForSubsectionItem = false,
    useManualNumbering = false,
    disableNumbering,
}) => {
    if (disableNumbering) {
        return '';
    }
    if (useManualNumbering) {
        return manualNumber || '';
    }

    let sectionNumberText = `${sectionNumber}.`;
    if (subsectionNumber > 0) {
        sectionNumberText += `${subsectionNumber}.`;
    }
    if (subSectionItemNumber) {
        sectionNumberText += `${
            useLetterForSubsectionItem
                ? getLetterFromNumber(subSectionItemNumber)
                : subSectionItemNumber
        }.`;
    }
    return sectionNumberText;
};

/**
 * Adds section and subsection numbering to `questionnaires` or `projectSections`
 * @param {object[]} items Array of `questionnaires` or `projectSections` to number
 * @returns {object[]} Updated array `questionnaires` or `projectSections` with `sectionNumber` and `subsectionNumber` properties added
 */
exports.numberItems = (items) => {
    const DIVIDER = 'divider'; // Cannot import from `sectionTypeNames` as it creates circular dependency with `listToDict` above
    let isSubsection = false;
    let sectionNumber = 0;
    let subsectionNumber = 0;

    return (items || []).map((item) => {
        // Skip section and subsection numbering for items that are hidden or conditional
        if (
            item.isHidden ||
            item.isHiddenByLogic ||
            item.isConditionalSubQuestion ||
            item.disableNumbering
        ) {
            // Skip numbering
        } else if (item.isTitle || item.section_type === DIVIDER) {
            isSubsection = true;
            sectionNumber++;
            subsectionNumber = 0;
        } else if (isSubsection) {
            subsectionNumber++;
        } else {
            sectionNumber++;
        }

        return {
            ...item,
            sectionNumber,
            subsectionNumber,
        };
    });
};

/**
 * Takes a number and converts to a string with commas for display purposes
 * @param {number} number Number to normalize
 * @returns {string} Display text for number
 */
exports.normalizeNumberWithCommas = (number) => {
    const int = number.toString().split('.');
    int[0] = int[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return int.join('.');
};

exports.hexToRGB = (hex, alpha) => {
    if (!hex) return null;

    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }
    return `rgb(${r}, ${g}, ${b})`;
};

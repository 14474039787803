import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Button } from '../../../../../components';

export class GuidedSectionButton extends PureComponent {
    static propTypes = {
        onClick: PropTypes.func.isRequired,
        className: PropTypes.string,
        text: PropTypes.string.isRequired,
        info: PropTypes.string,
        type: PropTypes.oneOf(['required', 'optional', 'recommended']),
        customIcon: PropTypes.string,
        customTypeText: PropTypes.string,
        disabled: PropTypes.bool,
        qaTag: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = { buttonHover: false };
    }

    get styles() {
        return require('./index.scss');
    }

    getButtonData() {
        switch (this.props.type) {
            case 'optional':
                return {
                    icon: 'plus',
                    style: 'warning',
                    typeIcon: 'question-circle',
                    typeText: 'Optional',
                    class: this.styles.optional,
                };
            case 'recommended':
                return {
                    icon: 'plus',
                    style: 'info',
                    hover: 'primary',
                    typeIcon: 'star',
                    typeText: 'Recommended',
                    class: this.styles.recommended,
                };
            case 'required':
                return {
                    icon: 'pencil',
                    style: 'success',
                    typeIcon: 'exclamation-triangle',
                    typeText: 'Required section',
                    class: this.styles.required,
                };
            default:
                return {};
        }
    }

    toggleHoverOn = () => {
        this.setState({ buttonHover: true });
    };

    toggleHoverOff = () => {
        this.setState({ buttonHover: false });
    };

    renderInfo() {
        const { info } = this.props;
        if (!info) return null;

        return <div className={this.styles.infoSection}>{info}</div>;
    }

    renderTypeText(buttonData) {
        return (
            <div>
                <i className={`fa fa-${buttonData.typeIcon}`} />
                &nbsp;
                {this.props.customTypeText || buttonData.typeText}
            </div>
        );
    }

    render() {
        const { onClick, text, className, disabled, customIcon, qaTag } = this.props;
        const { buttonHover } = this.state;
        const buttonData = this.getButtonData();

        if (customIcon) {
            buttonData.icon = customIcon;
        }

        // Allow the style to be overriden on hover
        buttonData.hover = buttonData.hover || buttonData.style;

        const bsStyle = buttonHover ? buttonData.hover : 'default';
        const bsColorClass = buttonHover ? '' : buttonData.class;

        return (
            <div className={`${className || ''} ${this.styles.guidedSection}`}>
                <Button
                    bsSize="large"
                    bsStyle={bsStyle}
                    className={bsColorClass}
                    disabled={disabled}
                    onClick={onClick}
                    onMouseEnter={this.toggleHoverOn}
                    onMouseLeave={this.toggleHoverOff}
                    qaTag={qaTag}
                >
                    <i className={`fa fa-${buttonData.icon}`} /> {text}
                </Button>
                <div className={`text-${buttonData.style} ${this.styles.typeText}`}>
                    {this.renderTypeText(buttonData)}
                    {this.renderInfo()}
                </div>
            </div>
        );
    }
}

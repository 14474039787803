import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

/**
 * Custom React hook to set default values for query parameters in the URL.
 *
 * @param {Array<{ paramName: string, defaultValue: string }>} defaultParams - An array of objects containing paramName and defaultValue.
 * @param {string} location - The location prop.
 */
export const useRouteDefaultQueryParams = (defaultParams = {}) => {
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        // Check and set default values for each parameter
        defaultParams.forEach(({ paramName, defaultValue }) => {
            if (!searchParams.has(paramName)) {
                searchParams.set(paramName, defaultValue);
            }
        });

        setSearchParams(searchParams);
    }, []);
};

/**
 * Helper tools for migration from `react-table@6.x.x` to `@tanstack/react-table@8.x.x`.
 *
 */

import { createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper();
const defaultCell = (cellData) => cellData.renderValue();

/**
 * Adapts `react-table@6.x.x` column definitions to `@tanstack/react-table@8.x.x`.
 *
 * @param {Array} columns Column definitions from `react-table` v6.
 * @return {Array} Column definitions for `@tanstack/react-table` v8.
 */
const columnAdapter = (columns) => {
    return columns.map((c) => {
        // v6 compatibility, realigning old prop names to new prop names
        const columnProps = {
            header: c.Header,
            cell: c.Cell || defaultCell,
            size: c.width,
            minSize: c.minWidth,
            maxSize: c.maxWidth,
            headerClassName: c.headerClassName,
            // everything else
            ...c,
        };

        if (c.accessor) {
            return columnHelper.accessor(c.accessor, {
                ...columnProps,

                /*
                 * RT8 will replace `.` with `_` in `accessor` values that are strings.
                 * This breaks column sorts that use the `accessor | id` to refetch data, such as the Contracts list.
                 * We need to explicitly set the `id` to the `accessor` if it's a string or fallback on `id`.                 *
                 */
                id: typeof c.accessor === 'string' ? c.accessor : c.id,
            });
        }

        // columns for simple display only
        return columnHelper.display(columnProps);
    });
};

/**
 * Reformats cell data for `react-table@6.x.x` cell content rendering.
 *
 * @param {*} cell Cell data from `@tanstack/react-table` v8.
 * @return {*} Cell content from `react-table` v6 render function.
 */
const cellAdapter = (cell) => {
    return {
        ...cell.getContext(),
        // v6 compatibility
        original: cell.row.original,
        value: cell.getValue(),
    };
};

/**
 * Formats rows for export.
 *
 * @param {Array} rows Data rows from react-table v8.
 *
 * @returns {Array} Modified array of rows for export.
 */
const formatForExport = (rows) =>
    rows.map((r) => ({
        // v6 compatibility
        // in order to support the v6 implementation, we need to map these values to the row object

        // eslint-disable-next-line no-underscore-dangle
        ...r._valuesCache,
        _original: r.original,
    }));

export const migrationTools = {
    cellAdapter,
    columnAdapter,
    formatForExport,
};

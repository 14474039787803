import React from 'react';

import { ReduxFormTextField } from '@og-pro/ui';
import { useSelector } from 'react-redux';
import { Field, getFormValues } from 'redux-form';

import {
    createCustomFieldFormName,
    customFieldFields,
    instructionsModes,
} from '@og-pro/shared-config/customFormService/customField';

const { NAME, INSTRUCTIONS_TEXT, INSTRUCTIONS_MODE } = customFieldFields;
const { TOOLTIP, DESCRIPTION } = instructionsModes;

export const ShortTextPreview = () => {
    const formValues =
        useSelector((state) => getFormValues(createCustomFieldFormName)(state)) || {};
    const instructionsText = formValues[INSTRUCTIONS_TEXT];
    return (
        <Field
            component={ReduxFormTextField}
            description={
                formValues[INSTRUCTIONS_MODE] === DESCRIPTION ? instructionsText : undefined
            }
            disabled
            label={formValues[NAME] || 'Your title will go here'}
            name="shortText_PREVIEW"
            sx={{
                width: '100%',
            }}
            tooltip={formValues[INSTRUCTIONS_MODE] === TOOLTIP ? instructionsText : undefined}
        />
    );
};

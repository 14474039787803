import React from 'react';
import PropTypes from 'prop-types';

import { CellWrapper } from '../CellWrapper';

export const TextCell = ({ api, node, value }) => {
    return (
        <CellWrapper api={api} node={node}>
            {value}
        </CellWrapper>
    );
};

TextCell.propTypes = {
    api: PropTypes.object,
    node: PropTypes.object,
    value: PropTypes.any,
};

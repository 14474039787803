import React, { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getFormSyncErrors } from 'redux-form';
import { Box, Typography } from '@og-pro/ui';
import { useFlags, FLAGS } from '@og-pro/launch-darkly/client';

import { projectTypesDict } from '@og-pro/shared-config/projects';

import { ReviewChecklistBanner } from './Banner';
import { ReviewChecklistSection } from './Section';
import { ReviewContainer } from './ReviewContainer';
import { ReviewChecklistSectionsContainer } from './SectionsContainer';
import { buildTabRoute } from '../../../../../../helpers';
import {
    setFormCompleteConfirmation,
    showFormValidation,
} from '../../../../../../actions/project/create/projectCreate';
import { ProjectCreateV2FunctionsContext, ProjectCreateV2NavContext } from '../../context';
import { projectSectionsToNavSections } from '../../../../../../components/SDv2/helpers';
import { SUBMIT_INTAKE_FOR_REVIEW } from '../../../../../../constants/menuActions';
import { menuActionHandler } from '../../../../../../actions/govProjects';
import { getSDv2WritingSections } from '../../../selectors';

export const ProjectReviewChecklist = () => {
    const signaturesTab = useFlags(FLAGS.ENABLE_SIGNATURES_TAB);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const projectSections = useSelector(getSDv2WritingSections);
    const { activeSectionId, setActiveSectionId } = useContext(ProjectCreateV2NavContext);
    const {
        form,
        location,
        project,
        project: { isIntake, lastUpdatedAt, useManualNumbering },
        reviewPath,
    } = useContext(ProjectCreateV2FunctionsContext);
    const errorsSelectors = getFormSyncErrors(form);
    const formErrors = useSelector((state) => errorsSelectors(state));
    const hasIncompleteSections = !!formErrors?.allSections;

    useEffect(() => {
        dispatch(showFormValidation());
    }, []);

    useEffect(() => {
        dispatch(
            setFormCompleteConfirmation(
                hasIncompleteSections ? null : new Date(lastUpdatedAt).getTime()
            )
        );
    }, [hasIncompleteSections, lastUpdatedAt]);

    const sections = projectSectionsToNavSections({
        projectSections,
        active: activeSectionId,
        setActive: setActiveSectionId,
        useManualNumbering,
        showFormValidation: true,
        matchErrorToSection: (section) => {
            return formErrors?.sections && formErrors.sections[section.id];
        },
        hideEmptyDividers: true,
    });

    const styles = require('./index.scss');
    const buildRoute = buildTabRoute(location);
    const onReviewClick = () => {
        if (isIntake) {
            dispatch(menuActionHandler(SUBMIT_INTAKE_FOR_REVIEW, project));
        } else {
            navigate(reviewPath);
        }
    };

    const buttonText = isIntake ? 'Submit Project Request' : 'Review Approval Setup';
    const isContractDocument = project.type === projectTypesDict.CONTRACT;
    const isContractDocumentOrIntake = isContractDocument || isIntake;
    const showSignaturesSection =
        isContractDocument && !!project.signatures.filter((s) => !s.isHiddenByLogic).length > 0;
    const showingSignaturesTab =
        isContractDocument &&
        signaturesTab &&
        project.signatures.filter((s) => !s.isHiddenByLogic).length > 0;

    return (
        <ReviewContainer
            description="Check for any errors before moving your document into the review stage."
            onSubmit={onReviewClick}
            showingSignaturesTab={showingSignaturesTab}
            submitButtonText={buttonText}
            title="Final Project Review"
        >
            <ReviewChecklistBanner
                buttonText={buttonText}
                hasIncompleteSections={hasIncompleteSections}
                onReviewClick={onReviewClick}
            />
            <Box mb={3}>
                <Typography variant="h3">Project Properties Checklist</Typography>
                <Box className={styles.sectionsContainer}>
                    <ReviewChecklistSection
                        incomplete={formErrors?.sections[1]}
                        onClick={() => navigate(buildRoute('project-properties'))}
                        section={{ title: 'Project Information' }}
                    />
                    <ReviewChecklistSection
                        incompleteFields={
                            formErrors?.upfrontQuestions &&
                            formErrors?.upfrontQuestions.filter((e) => !!e).length
                        }
                        last={isContractDocument}
                        onClick={() =>
                            navigate(`${buildRoute('project-properties')}?activeSection=1`)
                        }
                        section={{ title: 'Document Set Up' }}
                    />
                    {!isContractDocument && (
                        <ReviewChecklistSection
                            incompleteFields={formErrors?.rawSummary ? 1 : 0}
                            last={isContractDocumentOrIntake}
                            onClick={() =>
                                navigate(`${buildRoute('project-properties')}?activeSection=2`)
                            }
                            section={{ title: 'Summary & Background' }}
                        />
                    )}
                    {!isContractDocumentOrIntake && (
                        <>
                            <ReviewChecklistSection
                                incompleteFields={formErrors?.projectProperties.timeline}
                                onClick={() =>
                                    navigate(`${buildRoute('project-properties')}?activeSection=3`)
                                }
                                section={{ title: 'Timeline' }}
                            />
                            <ReviewChecklistSection
                                incompleteFields={formErrors?.projectProperties.postingOptions}
                                last
                                onClick={() =>
                                    navigate(`${buildRoute('project-properties')}?activeSection=4`)
                                }
                                section={{ title: 'Posting Options' }}
                            />
                        </>
                    )}
                </Box>
            </Box>
            <Box mb={3}>
                <Typography variant="h3">Document Outline Checklist</Typography>
                <ReviewChecklistSectionsContainer buildRoute={buildRoute} sections={sections} />
            </Box>
            {showSignaturesSection && (
                <Box mb={3}>
                    <Typography variant="h3">Signatures</Typography>
                    <Box className={styles.sectionsContainer}>
                        <ReviewChecklistSection
                            incomplete={/* eslint-disable-line */ formErrors?.signatures._error}
                            onClick={() => navigate(buildRoute('signatures'))}
                            section={{ title: 'Signatures' }}
                        />
                    </Box>
                </Box>
            )}
        </ReviewContainer>
    );
};

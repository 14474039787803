import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Modal } from 'react-bootstrap';

import { ListImportForm1 } from './ListImportForm1';
import { ListImportForm2 } from './ListImportForm2';
import { parseListItemsFromString } from '../../../../../../../../utils';

export class ListImportModal extends PureComponent {
    static propTypes = {
        onHide: PropTypes.func.isRequired,
        onImport: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            page: 1,
        };
    }

    submitForm1 = ({ rawText }) => {
        try {
            const importListItems = parseListItemsFromString(rawText);
            this.setState({
                importListItems,
                page: 2,
            });
        } catch (e) {
            this.setState({ importError: e.message });
        }
    };

    submitForm2 = ({ listItems }) => {
        const { onImport } = this.props;

        onImport(listItems);
    };

    render() {
        const { onHide } = this.props;

        const { importError, importListItems, page } = this.state;

        return (
            <Modal onHide={onHide} show>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">Import List Items</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {page === 1 ? (
                        <ListImportForm1 importError={importError} onSubmit={this.submitForm1} />
                    ) : (
                        <ListImportForm2
                            initialValues={{ listItems: importListItems }}
                            onCancel={onHide}
                            onSubmit={this.submitForm2}
                        />
                    )}
                </Modal.Body>
            </Modal>
        );
    }
}

import { TableCell as MuiTableCell, TableRow, Typography } from '@og-pro/ui';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import { statusTypes } from '@og-pro/shared-config/requestTypes';

import { colsDict, columns } from './constants';
import { TemplateStatusLabel } from '../../../../../components';

const { NAME, STATUS, CREATED_BY, UPDATED_AT } = colsDict;
const { ARCHIVED, PUBLISHED } = statusTypes;

const TableCell = ({ columnName, ...props }) => {
    const { bodyProps } = columns[columnName];

    return (
        <MuiTableCell
            sx={(theme) => ({
                padding: `${theme.spacing(2)} !important`,
                verticalAlign: 'top',
            })}
            {...bodyProps}
            {...props}
        />
    );
};

TableCell.propTypes = {
    columnName: PropTypes.string.isRequired,
};

export const RequestTypesRow = ({
    onClick,
    requestType: { id, name, status, updated_at: updatedAt, user },
}) => {
    return (
        <TableRow
            data-qa={`requestTypesRow-row${id}`}
            hover
            onClick={onClick}
            sx={{
                ':hover': {
                    cursor: 'pointer',
                },
            }}
        >
            <TableCell columnName={NAME}>
                <Typography
                    color={capitalDesignTokens.semanticColors.foreground.action}
                    variant="h4"
                >
                    {name || 'Untitled'}
                </Typography>
            </TableCell>
            <TableCell columnName={STATUS}>
                <TemplateStatusLabel
                    isArchived={status === ARCHIVED}
                    isPublished={status === PUBLISHED}
                    size="small"
                    useOpenGovStyle
                />
            </TableCell>
            <TableCell columnName={CREATED_BY}>
                {user && <Typography component="span">{user.displayName}</Typography>}
            </TableCell>
            <TableCell columnName={UPDATED_AT}>
                <Typography>{moment(updatedAt).format('M/D/YY [at] h:mma')}</Typography>
            </TableCell>
        </TableRow>
    );
};

RequestTypesRow.propTypes = {
    onClick: PropTypes.func.isRequired,
    requestType: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string,
        status: PropTypes.string.isRequired,
        updated_at: PropTypes.string.isRequired,
        user: PropTypes.shape({
            displayName: PropTypes.string.isRequired,
        }),
    }).isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';

import { RouterLink } from '../../RouterLink/RouterLink';
import { dateTZFormatter } from '../../../helpers';
import { timezoneAbbreviations } from '../../../constants';

export const ReverseAuctionListBanner = ({ liveReverseAuctionList }) => {
    const styles = require('./index.scss');

    return (
        <div className={`row ${styles.container}`}>
            <div className={styles.icon}>
                <i className="fa fa-exclamation-triangle fa-2x" />
            </div>
            <div>
                <h2 className={styles.title}>Reverse Auction(s) Happening Now</h2>
                <ul className={styles.listItem}>
                    {liveReverseAuctionList.map((auction) => {
                        const {
                            auctionEndDate,
                            govTimezone,
                            projectId,
                            proposalId,
                            title,
                            vendorId,
                        } = auction;
                        return (
                            <li key={proposalId}>
                                <RouterLink
                                    className={styles.link}
                                    qaTag="reverseAuctionBanner-viewAuction"
                                    to={`/vendors/${vendorId}/proposals/${proposalId}/projects/${projectId}/reverse-auction`}
                                >
                                    {title}
                                </RouterLink>{' '}
                                is live until{' '}
                                {dateTZFormatter(
                                    govTimezone,
                                    'll [at] h:mm a'
                                )({ value: auctionEndDate })}
                                &nbsp;({timezoneAbbreviations[govTimezone]})
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

ReverseAuctionListBanner.propTypes = {
    liveReverseAuctionList: PropTypes.array.isRequired,
};

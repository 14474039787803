import { fromJS } from 'immutable';

import * as requisitionGroupsActions from '../actions/requisitionGroups';

const initialRequisitionGroupEditState = {
    copyingItem: false,
    copyItemError: null,
    deleteItemError: null,
    deletingItem: false,
    loadingItem: false,
    loadItemError: null,
    requisitionGroup: null,
    updateItemError: null,
    updatingItem: false,
};

const initialState = fromJS({
    ...initialRequisitionGroupEditState,
    loadedGroups: false,
    loadGroupsError: null,
    loadingGroups: false,
    requisitionGroups: [],
});

export default function requisitionGroupsReducer(state = initialState, action = {}) {
    switch (action.type) {
        case requisitionGroupsActions.COPY_GROUP:
            return state.merge(
                fromJS({
                    copyingItem: true,
                    copyItemError: null,
                })
            );
        case requisitionGroupsActions.COPY_GROUP_FAIL:
            return state.merge(
                fromJS({
                    copyingItem: false,
                    copyItemError: action.error && action.error.message,
                })
            );
        case requisitionGroupsActions.COPY_GROUP_SUCCESS:
            return state.merge(
                fromJS({
                    copyingItem: false,
                })
            );
        case requisitionGroupsActions.DELETE_GROUP:
            return state.merge(
                fromJS({
                    deleteItemError: null,
                    deletingItem: true,
                })
            );
        case requisitionGroupsActions.DELETE_GROUP_FAIL:
            return state.merge(
                fromJS({
                    deleteItemError: action.error && action.error.message,
                    deletingItem: false,
                })
            );
        case requisitionGroupsActions.DELETE_GROUP_SUCCESS:
            return state.merge(
                fromJS({
                    deletingItem: false,
                })
            );
        case requisitionGroupsActions.LOAD_GROUP:
            return state.merge(
                fromJS({
                    loadingItem: true,
                    loadItemError: null,
                })
            );
        case requisitionGroupsActions.LOAD_GROUP_FAIL:
            return state.merge(
                fromJS({
                    loadingItem: false,
                    loadItemError: action.error && action.error.message,
                })
            );
        case requisitionGroupsActions.LOAD_GROUP_SUCCESS:
            return state.merge(
                fromJS({
                    loadingItem: false,
                    requisitionGroup: fromJS(action.result),
                })
            );
        case requisitionGroupsActions.LOAD_GROUPS:
            return state.merge(
                fromJS({
                    loadingGroups: true,
                    loadGroupsError: null,
                })
            );
        case requisitionGroupsActions.LOAD_GROUPS_FAIL:
            return state.merge(
                fromJS({
                    loadedGroups: false,
                    loadingGroups: false,
                    loadGroupsError: action.error && action.error.message,
                })
            );
        case requisitionGroupsActions.LOAD_GROUPS_SUCCESS:
            return state.merge(
                fromJS({
                    // When filtered query, don't set loadedGroups to true
                    ...(!action.isFiltered && { loadedGroups: true }),
                    loadingGroups: false,
                    requisitionGroups: fromJS(action.result),
                })
            );
        case requisitionGroupsActions.RESET_GROUPS:
            return state.merge(
                fromJS({
                    loadedGroups: false,
                })
            );
        case requisitionGroupsActions.RESET_GROUP_EDIT:
            return state.merge(fromJS(initialRequisitionGroupEditState));
        case requisitionGroupsActions.UPDATE_GROUP:
            return state.merge(
                fromJS({
                    updatingItem: true,
                    updateItemError: null,
                })
            );
        case requisitionGroupsActions.UPDATE_GROUP_FAIL:
            return state.merge(
                fromJS({
                    updatingItem: false,
                    updateItemError: action.error && action.error.message,
                })
            );
        case requisitionGroupsActions.UPDATE_GROUP_SUCCESS:
            return state.merge(
                fromJS({
                    updatingItem: false,
                    requisitionGroup: fromJS(action.result),
                })
            );
        default:
            return state;
    }
}

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { CDSButton } from '..';

export class OrderButton extends PureComponent {
    static propTypes = {
        direction: PropTypes.number.isRequired,
        disabled: PropTypes.bool,
        index: PropTypes.number.isRequired,
        isFirstItem: PropTypes.bool.isRequired,
        isLastItem: PropTypes.bool.isRequired,
        stopPropagation: PropTypes.bool,
        swap: PropTypes.func.isRequired,
    };

    clickHandler = (e) => {
        const { direction, disabled, index, swap, stopPropagation } = this.props;

        if (stopPropagation) {
            e.stopPropagation();
        }

        if (!disabled) {
            swap(index, direction);
        }
    };

    render() {
        const { isFirstItem, isLastItem, direction, disabled } = this.props;
        const styles = require('./OrderButtons.scss');

        const icon = direction > 0 ? 'fa-caret-down' : 'fa-caret-up';
        const disabledStyle = direction > 0 ? isLastItem : isFirstItem;

        let buttonStyle = styles.orderButton;
        if (disabled || disabledStyle) {
            buttonStyle += ` ${styles.disabled}`;
        }

        return (
            <CDSButton
                className={buttonStyle}
                disabled={disabled}
                noPadding
                onClick={this.clickHandler}
                size="large"
                variant="text"
            >
                <i className={`fa ${icon}`} />
            </CDSButton>
        );
    }
}

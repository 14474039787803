import { createSelector } from 'reselect';
import { reduce } from 'lodash';

import {
    getCommentThreadsJS,
    getCriteriaMap,
    getProjectJS,
    getProjectSubsectionsMap,
} from '../GovApp/selectors';

export const getSectionCommentCounts = createSelector(
    [getCommentThreadsJS, getProjectJS, getCriteriaMap, getProjectSubsectionsMap],
    (commentThreads, project, criteriaMap, projectSubsectionsMap) => {
        if (!project) return {};

        const commentCounts = reduce(
            commentThreads,
            (countObj, threads) => {
                const { criterion_id: criterionId, project_subsection_id: projectSubsectionId } =
                    threads[0];

                let associatedModel;
                if (criterionId) {
                    associatedModel = criteriaMap[criterionId];
                } else if (projectSubsectionId) {
                    associatedModel = projectSubsectionsMap[projectSubsectionId];
                }

                if (!associatedModel) return countObj;

                const projectSectionId = associatedModel.project_section_id;

                if (!countObj[projectSectionId]) {
                    countObj[projectSectionId] = 0;
                }
                countObj[projectSectionId] += threads.length;
                return countObj;
            },
            {}
        );

        // Add zeros to all projectSections that don't have comments
        project.projectSections.forEach((projectSection) => {
            if (!commentCounts[projectSection.id]) {
                commentCounts[projectSection.id] = 0;
            }
        });

        return commentCounts;
    }
);

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { AttachmentRowItem } from '../../../../../components';

export class AttachmentTable extends PureComponent {
    static propTypes = {
        attachments: PropTypes.array.isRequired,
    };

    get styles() {
        return require('./AttachmentTable.scss');
    }

    render() {
        const { attachments } = this.props;

        if (attachments.length === 0) {
            return (
                <div className="text-muted text-center">
                    <em>No attachments were included</em>
                </div>
            );
        }

        const AttachmentTableRows = attachments.map((attachment) => (
            <li key={attachment.id}>
                <AttachmentRowItem attachment={attachment} />
            </li>
        ));

        return (
            <ul
                className={classnames(
                    'list-unstyled',
                    'attachment-section',
                    this.styles.attachmentTable
                )}
            >
                {AttachmentTableRows}
            </ul>
        );
    }
}

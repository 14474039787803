import { fromJS } from 'immutable';
import { includes, values } from 'lodash';

import { moduleTypesDict } from '@og-pro/shared-config/modules';

import attachmentsReducer from './attachments';
import criteriaReducer from './criteria';
import generativeAiScopeReducer from './generativeAiScope';
import preInviteReducer from './preInvite';
import scoredProposalsReducer from './scoredProposals';
import sectionDescReducer from './sectionDescriptions';
import * as attachmentActions from '../../actions/govProjects/attachments';
import * as criteriaActions from '../../actions/govProjects/criteria';
import * as evaluationActions from '../../actions/evaluations';
import * as generativeAiScopeActions from '../../actions/govProjects/generativeAiScope';
import * as govPermissionsActions from '../../actions/govPermissions';
import * as govProjectActions from '../../actions/govProjects';
import * as preInviteActions from '../../actions/govProjects/preInvite';
import * as projectCreateActions from '../../actions/project/create/projectCreate';
import * as projectSocketActions from '../../actions/projectSocket';
import * as proposalEvaluationActions from '../../actions/proposalEvaluations';
import * as reverseAuctionSocketActions from '../../actions/reverseAuctionSocket';
import * as sectionDescActions from '../../actions/govProjects/sectionDescriptions';
import * as subscriptionsActions from '../../actions/subscriptions';

const { INTAKE, BUILDER, SOURCING, EVALUATION } = moduleTypesDict;

const initialProjectStatusHistory = {
    loadedStatusHistory: false,
    loadingStatusHistory: false,
    loadStatusHistoryError: null,
    projectStatusHistory: null,
};

const initialState = fromJS({
    ...initialProjectStatusHistory,
    connectedClients: [],
    loadingAgreements: false,
    loadedReqRelations: false,
    loadedSelected: false,
    loadingProjectDocuments: false,
    loadingSelected: false,
    loadProjectDocumentsError: null,
    projectAssociations: [],
    reqRelations: [],
    shouldForceReload: false,
    showInstructionsModal: false,
    showPublicDisplayOptionsModal: false,
    showVendorDiscoveryModal: false,
    showEditTimelinesModal: false,
    updateLoading: false,
});

// Do not update moduleData permissions if a broadcast event (contains user specific info)
function updateProject(state, action) {
    if (action.projectSocketBroadcast) {
        const intakePath = ['selectedProject', 'moduleData', INTAKE, 'permissions'];
        const builderPath = ['selectedProject', 'moduleData', BUILDER, 'permissions'];
        const sourcingPath = ['selectedProject', 'moduleData', SOURCING, 'permissions'];
        const evaluationPath = ['selectedProject', 'moduleData', EVALUATION, 'permissions'];
        const intakePermissions = state.getIn(intakePath);
        const builderPermissions = state.getIn(builderPath);
        const sourcingPermissions = state.getIn(sourcingPath);
        const evaluationPermissions = state.getIn(evaluationPath);

        // Use the new project data, but preserve the user's permissions
        return state
            .set('selectedProject', fromJS(action.result))
            .setIn(intakePath, intakePermissions)
            .setIn(builderPath, builderPermissions)
            .setIn(sourcingPath, sourcingPermissions)
            .setIn(evaluationPath, evaluationPermissions);
    }

    return state.set('selectedProject', fromJS(action.result));
}

export default function govProjectsReducer(state = initialState, action = {}) {
    switch (action.type) {
        case govProjectActions.LOAD:
            return state.merge(
                fromJS({
                    loadingSelected: true,
                    loadedSelected: false,
                    loadSelectedError: null,
                    selectedProject: null,
                })
            );
        case govProjectActions.LOAD_SUCCESS: {
            return state.merge(
                fromJS({
                    loadingSelected: false,
                    loadedSelected: true,
                    selectedProject: fromJS(action.result),
                })
            );
        }
        case govProjectActions.LOAD_FAIL:
            return state.merge(
                fromJS({
                    loadingSelected: false,
                    loadedSelected: false,
                    loadSelectedError: action.error && action.error.message,
                })
            );
        case projectCreateActions.RESET:
            return state.merge(
                fromJS({
                    ...initialProjectStatusHistory,
                    loadingSelected: false,
                    loadSelectedError: null,
                    selectedProject: null,
                    loadedSelected: false,
                })
            );
        case govProjectActions.RESET: {
            // avoid resetting the loadingSelected value so that when navigating from a project
            // to another project we avoid a race condition in redux that makes an error screen
            // appear and disappear as data is loading
            return initialState.set('loadingSelected', state.get('loadingSelected'));
        }
        case govProjectActions.UPDATE:
            return state.merge(
                fromJS({
                    updateLoading: true,
                    updateError: null,
                })
            );
        case govProjectActions.UPDATE_SUCCESS:
            return updateProject(state, action).set('updateLoading', false);
        case govProjectActions.UPDATE_FAIL:
            return state.merge(
                fromJS({
                    updateLoading: false,
                    updateError: action.error,
                })
            );
        case govProjectActions.LOAD_PROJECT_ASSOCIATIONS:
            return state.merge(
                fromJS({
                    loadingProjectAssociations: true,
                    loadProjectAssociationsError: null,
                })
            );
        case govProjectActions.LOAD_PROJECT_ASSOCIATIONS_SUCCESS:
            return state.merge(
                fromJS({
                    loadingProjectAssociations: false,
                    projectAssociations: fromJS(action.result),
                })
            );
        case govProjectActions.LOAD_PROJECT_ASSOCIATIONS_FAIL:
            return state.merge(
                fromJS({
                    loadingProjectAssociations: false,
                    loadProjectAssociationsError: action.error && action.error.message,
                })
            );
        case govProjectActions.LOAD_PROJECT_DOCUMENTS:
            return state.merge(
                fromJS({
                    loadProjectDocumentsError: null,
                    loadingProjectDocuments: true,
                })
            );
        case govProjectActions.LOAD_PROJECT_DOCUMENTS_SUCCESS:
            return state.merge(
                fromJS({
                    loadingProjectDocuments: false,
                    projectContracts: fromJS(action.contracts),
                    projectDocBuilders: fromJS(action.docBuilders),
                })
            );
        case govProjectActions.LOAD_PROJECT_DOCUMENTS_FAIL:
            return state.merge(
                fromJS({
                    loadProjectDocumentsError: action.error && action.error.message,
                    loadingProjectDocuments: false,
                })
            );
        case govProjectActions.LOAD_PROJECT_STATUS_HISTORY:
            return state.merge(
                fromJS({
                    loadingStatusHistory: true,
                    loadStatusHistoryError: null,
                    projectStatusHistory: null,
                })
            );
        case govProjectActions.LOAD_PROJECT_STATUS_HISTORY_SUCCESS:
            return state.merge(
                fromJS({
                    loadingStatusHistory: false,
                    loadedStatusHistory: true,
                    projectStatusHistory: fromJS(action.result),
                })
            );
        case govProjectActions.LOAD_PROJECT_STATUS_HISTORY_FAIL:
            return state.merge(
                fromJS({
                    loadingStatusHistory: false,
                    loadStatusHistoryError: action.error && action.error.message,
                })
            );
        case govProjectActions.LOAD_PROJECT_REQ_RELATIONS_SUCCESS:
            return state.merge(
                fromJS({
                    loadedReqRelations: false,
                    reqRelations: action.result,
                })
            );
        case govProjectActions.CREATE_PROJECT_REQ_RELATIONS_SUCCESS:
            return state.set(
                'reqRelations',
                state.get('reqRelations').concat(fromJS(action.result))
            );
        case govProjectActions.SHOW_INSTRUCTIONS_MODAL:
            return state.merge(
                fromJS({
                    showInstructionsModal: true,
                    instructionsModalType: action.instructionType,
                    instructionsModalData: fromJS(action.instructionData),
                })
            );
        case govProjectActions.HIDE_INSTRUCTIONS_MODAL:
            return state.merge(
                fromJS({
                    showInstructionsModal: false,
                    instructionsModalType: null,
                    instructionsModalData: null,
                })
            );
        case govProjectActions.SHOW_PUBLIC_DISPLAY_OPTIONS_MODAL:
            return state.set('showPublicDisplayOptionsModal', true);
        case govProjectActions.HIDE_PUBLIC_DISPLAY_OPTIONS_MODAL:
            return state.set('showPublicDisplayOptionsModal', false);
        case govProjectActions.SHOW_EDIT_TIMELINES_MODAL:
            return state.set('showEditTimelinesModal', true);
        case govProjectActions.HIDE_EDIT_TIMELINES_MODAL:
            return state.set('showEditTimelinesModal', false);
        case govProjectActions.SHOW_VENDOR_DISCOVERY_MODAL:
            return state.set('showVendorDiscoveryModal', true);
        case govProjectActions.HIDE_VENDOR_DISCOVERY_MODAL:
            return state.set('showVendorDiscoveryModal', false);
        case govPermissionsActions.ADD_PERMISSION_SUCCESS:
        case govPermissionsActions.REMOVE_PERMISSION_SUCCESS:
            if (action.projectId === state.getIn(['selectedProject', 'id'])) {
                return state.setIn(['selectedProject', 'userPermissions'], fromJS(action.result));
            }
            return state;
        case projectCreateActions.UPDATE_SUCCESS:
        case projectCreateActions.SUBMIT_SUCCESS:
        case evaluationActions.CREATE_SUCCESS:
        case evaluationActions.UPDATE_SUCCESS:
        case proposalEvaluationActions.SELECT_PROPOSAL_SUCCESS:
            return updateProject(state, action);
        case govProjectActions.GET_PROPOSAL_VIEWER_AGREEMENT_DATA:
            return state.merge(
                fromJS({
                    loadingAgreements: true,
                    loadAgreementsError: null,
                })
            );
        case govProjectActions.GET_PROPOSAL_VIEWER_AGREEMENT_DATA_FAIL:
            return state.merge(
                fromJS({
                    loadingAgreements: false,
                    loadAgreementsError: action.error && action.error.message,
                })
            );
        case govProjectActions.GET_PROPOSAL_VIEWER_AGREEMENT_DATA_SUCCESS:
            return state.merge(
                fromJS({
                    agreements: fromJS(action.result.agreements),
                    loadingAgreements: false,
                    standardDocument: fromJS(action.result.standardDocument),
                })
            );
        case govProjectActions.SUBMIT_PROPOSAL_VIEWER_AGREEMENT:
            return state.merge(
                fromJS({
                    agreements: fromJS(action.result),
                })
            );
        case proposalEvaluationActions.UPDATE_ADMIN_SCORECARD:
            return state.setIn(
                ['selectedProject', 'evaluation', 'adminScorecard'],
                fromJS(action.result)
            );
        case reverseAuctionSocketActions.AUCTION_EXTENDED:
            return state
                .setIn(['selectedProject', 'auctionExtensionCount'], action.auctionExtensionCount)
                .setIn(['selectedProject', 'auctionEndDate'], action.auctionEndDate);
        case projectSocketActions.CONNECTED_CLIENT_COUNT:
            return state.set('connectedClients', fromJS(action.data));
        case projectSocketActions.LEAVE:
            return state.set('connectedClients', fromJS([]));
        case projectSocketActions.FORCE_RELOAD_PROJECT:
            return state.set('shouldForceReload', true);
        case govProjectActions.RESET_FORCE_RELOAD:
            return state.set('shouldForceReload', false);
        case criteriaActions.CREATE_SUCCESS:
            return state.setIn(
                ['selectedProject', 'criteria'],
                state.getIn(['selectedProject', 'criteria']).push(fromJS(action.result))
            );
        case subscriptionsActions.SUBSCRIBE_GOV_SUCCESS:
            return state.updateIn(['selectedProject', 'followers'], (list) =>
                list.push(fromJS(action.user))
            );
        case subscriptionsActions.UNSUBSCRIBE_GOV_SUCCESS:
            return state.updateIn(['selectedProject', 'followers'], (list) =>
                list.filter((subscriber) => subscriber.get('id') !== action.userId)
            );
        case proposalEvaluationActions.BACKGROUND_RELOAD_AGGREGATE:
        case proposalEvaluationActions.LOAD_AGGREGATE_SUCCESS:
        case proposalEvaluationActions.UPDATE:
        case proposalEvaluationActions.UPDATE_SUCCESS:
        case proposalEvaluationActions.UPDATE_FAIL: {
            const scoredProposalsPath = ['selectedProject', 'scoredProposals'];
            const proposalsState = state.getIn(scoredProposalsPath);
            if (!proposalsState) {
                return state;
            }
            return state.setIn(scoredProposalsPath, scoredProposalsReducer(proposalsState, action));
        }
        default:
            if (includes(values(sectionDescActions), action.type)) {
                const sectionDescKeys = ['selectedProject', 'sectionDescriptions'];
                const initialSectionDescList = state.getIn(sectionDescKeys);
                return state.setIn(
                    sectionDescKeys,
                    sectionDescReducer(initialSectionDescList, action)
                );
            }

            if (includes(values(criteriaActions), action.type)) {
                const criteriaKeys = ['selectedProject', 'criteria'];
                const initCriteriaList = state.getIn(criteriaKeys);
                return state.setIn(criteriaKeys, criteriaReducer(initCriteriaList, action));
            }

            // Handle attachment actions via attachment reducer
            if (includes(values(attachmentActions), action.type)) {
                const attachmentKeys = ['selectedProject', 'attachments'];
                const attachmentsState = state.getIn(attachmentKeys);
                return state.setIn(attachmentKeys, attachmentsReducer(attachmentsState, action));
            }

            if (includes(values(preInviteActions), action.type)) {
                const preInviteKeys = ['selectedProject', 'preInvite'];
                const initPreInviteList = state.getIn(preInviteKeys);
                return state.setIn(preInviteKeys, preInviteReducer(initPreInviteList, action));
            }

            if (Object.values(generativeAiScopeActions).includes(action.type)) {
                const generativeAiScopeKeys = ['selectedProject', 'generativeAiScope'];
                const initGenerativeAiScope = state.getIn(generativeAiScopeKeys);
                return state.setIn(
                    generativeAiScopeKeys,
                    generativeAiScopeReducer(initGenerativeAiScope, action)
                );
            }

            return state;
    }
}

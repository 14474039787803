import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getSubscribers, isVendor, isSubscribedToProject } from './selectors';
import { Button, UserListPopover } from '..';
import { subscribe, unsubscribe } from '../../actions/subscriptions';
import { showConfirmationSimpleModal } from '../../actions/confirmation';

const mapStateToProps = (state, props) => {
    return {
        subscribed: isSubscribedToProject(state, props),
        subscribers: getSubscribers(state, props),
        vendor: isVendor(state, props),
    };
};

const mapDispatchToProps = {
    showConfirmationSimpleModal,
    subscribe,
    unsubscribe,
};

// @connect
class ConnectedSubscribeButton extends Component {
    static propTypes = {
        isGovernment: PropTypes.bool,
        onComplete: PropTypes.func,
        project: PropTypes.object.isRequired,
        showConfirmationSimpleModal: PropTypes.func.isRequired,
        subscribe: PropTypes.func.isRequired,
        subscribed: PropTypes.bool.isRequired,
        subscribers: PropTypes.array.isRequired,
        unsubscribe: PropTypes.func.isRequired,
        vendor: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            subscribeButtonHover: false,
            subscribing: false,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    toggleHoverOn = () => {
        const { subscribed } = this.props;

        if (subscribed) {
            this.setState({ subscribeButtonHover: true });
        }
    };

    toggleHoverOff = () => {
        this.setState({ subscribeButtonHover: false });
    };

    subscribeHandler = () => {
        const { isGovernment, onComplete, project, subscribed } = this.props;

        const action = subscribed ? this.props.unsubscribe : this.props.subscribe;

        return action(project, isGovernment, {
            onComplete: () => {
                this.setState({ subscribing: false });

                if (onComplete) {
                    onComplete();
                }
            },
            onStart: () => this.setState({ subscribing: true }),
        });
    };

    clickHandler = (e) => {
        const { subscribed, vendor } = this.props;

        e.stopPropagation();

        if (subscribed && vendor) {
            return this.props.showConfirmationSimpleModal(this.subscribeHandler, {
                text:
                    'Are you sure you want to unfollow this project?\n\n' +
                    'Warning: You will stop receiving notifications about updates for this project. ' +
                    'If you have submitted a proposal, unfollowing is not recommended.',
                btnText: 'Yes, Unfollow',
                icon: 'bell-slash-o',
            });
        }

        return this.subscribeHandler();
    };

    renderSubscribers() {
        const { subscribers } = this.props;

        const followersCount = subscribers.length;
        const followersText = followersCount === 1 ? 'follower' : 'followers';

        return (
            <div className={this.styles.subscribersContainer}>
                {followersCount === 0 ? (
                    <small className="text-danger">
                        <i className="fa fa-exclamation-triangle" /> No followers
                    </small>
                ) : (
                    <UserListPopover popoverId="followers-list" users={subscribers}>
                        <Button bsStyle="link" qaTag="subscribeButton-followersPopover" zeroPadding>
                            <small>
                                {followersCount} {followersText}
                            </small>
                        </Button>
                    </UserListPopover>
                )}
            </div>
        );
    }

    renderSubscribeType() {
        const { subscribers } = this.props;

        const subscribeType = get(subscribers, [0, 'projectVendorSubscriptions', 'type']);
        if (!subscribeType) {
            return null;
        }

        return (
            <div className={this.styles.subscribersContainer}>
                <small className="text-muted">
                    <i className="fa fa-tag" /> <em>{subscribeType}</em>
                </small>
            </div>
        );
    }

    render() {
        const { isGovernment, subscribed } = this.props;

        const { subscribing } = this.state;

        let buttonContent;
        let buttonStyle;

        if (subscribed && this.state.subscribeButtonHover) {
            buttonContent = 'Unfollow';
            buttonStyle = 'danger';
        } else if (subscribed) {
            buttonContent = 'Following';
            buttonStyle = 'info';
        } else {
            buttonContent = (
                <span>
                    <i className="fa fa-lg fa-rss text-primary" /> Follow
                </span>
            );
            buttonStyle = 'default';
        }

        const tooltip = subscribed
            ? 'Unfollow to stop receiving notifications'
            : 'Follow to receive project update notifications';

        return (
            <div className={`no-print ${this.styles.subscribeContainer}`}>
                <Button
                    bsSize="sm"
                    bsStyle={buttonStyle}
                    className={this.styles.subscribeButton}
                    disabled={subscribing}
                    onClick={this.clickHandler}
                    onMouseEnter={this.toggleHoverOn}
                    onMouseLeave={this.toggleHoverOff}
                    qaTag="sharedComponent-connectedSubscribeButton"
                    tooltip={tooltip}
                >
                    {buttonContent}
                </Button>
                {isGovernment ? this.renderSubscribers() : this.renderSubscribeType()}
            </div>
        );
    }
}

export const SubscribeButton = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedSubscribeButton);

import React from 'react';

import { SDv2WordTemplatesList } from './SDv2List';
import { WORD_TEMPLATES_LIST_PROP_TYPES } from './types';

export const WordTemplatesList = ({
    docxTemplates,
    updating,
    uploading,
    updateDefaultDocxTemplate,
    deleteHandler,
    updateDocxTemplate,
}) => {
    return (
        <SDv2WordTemplatesList
            {...{
                docxTemplates,
                updating,
                uploading,
                updateDefaultDocxTemplate,
                deleteHandler,
                updateDocxTemplate,
            }}
        />
    );
};

WordTemplatesList.propTypes = WORD_TEMPLATES_LIST_PROP_TYPES;

import React from 'react';
import PropTypes from 'prop-types';

import { CDSButton } from '../../CDSButtons';

export const BaseSectionsNavBackButton = ({ children, onClick }) => {
    const styles = require('./index.scss');

    return (
        <CDSButton
            className={styles.navButton}
            onClick={() => (onClick ? onClick() : null)}
            qaTag="navButtons-back"
            variant="text"
        >
            <span>
                <i className="fa fa-chevron-left fa-fw" /> Back &ndash;&nbsp;
            </span>
            <span className={styles.sectionName}>{children}</span>
        </CDSButton>
    );
};

BaseSectionsNavBackButton.propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
};

import { flatten, get, isNumber, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withRouter } from '@og-pro-migration-tools/react-router';

import {
    CriteriaScore,
    CriteriaScoreDocx,
    ReportDocxTable,
    DataTable,
    StatusLabel,
} from '../../../../../components';
import { formatCSVExportScorePercentage, roundNumber } from '../../../../../helpers';

const MAX_ROWS = 20;
const MINIMUM_OVERFLOW_ROWS_FOR_PAGINATION = 2;

class ConnectedAggregateEvaluationTable extends PureComponent {
    static propTypes = {
        evaluators: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
            })
        ).isRequired,
        getEvaluationPath: PropTypes.func,
        isDocx: PropTypes.bool,
        proposals: PropTypes.array.isRequired,
        router: PropTypes.object.isRequired,
        showAnonymizedEvaluators: PropTypes.bool.isRequired,
        showPercentageTotals: PropTypes.bool,
        totalWeight: PropTypes.number.isRequired,
        useLandscape: PropTypes.bool,
        usesRankedScoring: PropTypes.bool,
    };

    totalScoringCriteriaData = {
        maxScore: 100,
        title: 'Total Score',
    };

    totalRankedCriteriaData = {
        maxScore: 100,
        title: 'Total Average Rank',
    };

    get styles() {
        return require('./AggregateEvaluationTable.scss');
    }

    render() {
        const {
            evaluators,
            getEvaluationPath,
            isDocx,
            proposals,
            router,
            showAnonymizedEvaluators,
            showPercentageTotals,
            totalWeight,
            usesRankedScoring,
        } = this.props;

        const aggPath = '/aggregate-evaluations';
        const excludedLabelStyle = {
            backgroundColor: '#777',
            fontWeight: 'bold',
            color: '#fff',
            textAlign: 'center',
        };

        const vendorColumn = {
            Header: 'Vendor',
            id: 'vendor-name-column',
            accessor: (row) => row.companyName || 'Unnamed Company',
            Cell: (props) => (
                <>
                    <span
                        className={this.styles.selectableCell}
                        onClick={() =>
                            router.push(
                                getEvaluationPath(`${aggPath}/proposals/${props.original.id}`)
                            )
                        }
                    >
                        {props.value}
                    </span>
                    {props.original.isExcluded && (
                        <div>
                            <StatusLabel bsStyle="muted">Excluded</StatusLabel>
                        </div>
                    )}
                </>
            ),
            docxHtml: ({ datum, value }) => (
                <div>
                    {value}
                    {datum.isExcluded && <div style={excludedLabelStyle}>Excluded</div>}
                </div>
            ), // Used for docx table
        };

        const evaluatorColumns = evaluators.map((evaluator, idx) => ({
            Header: showAnonymizedEvaluators ? `Evaluator ${idx + 1}` : evaluator.displayName,
            accessor: showPercentageTotals
                ? `proposalEvaluations[${idx}].totalScore`
                : `proposalEvaluations[${idx}].totalScoreByPoints`,
            Cell: (props) => (
                <CriteriaScore
                    className={this.styles.criteriaScore}
                    criteriaScore={roundNumber(props.value, 1)}
                    isTotal
                    onClick={() =>
                        router.push(getEvaluationPath(`${aggPath}/evaluators/${evaluator.id}`))
                    }
                    rankedScore={get(props, `original.proposalEvaluations[${idx}].rankedScore`)}
                    scoringCriteria={this.totalScoringCriteriaData}
                    showPercentageTotals={showPercentageTotals}
                    totalScorePercentage={get(
                        props,
                        `original.proposalEvaluations[${idx}].totalScore`
                    )}
                    usesRankedScoring={usesRankedScoring}
                />
            ),
            // eslint-disable-next-line react/prop-types
            DocxCell: ({ datum, value }) => (
                <CriteriaScoreDocx
                    criteriaScore={roundNumber(value, 1)}
                    isTotal
                    rankedScore={get(datum, `proposalEvaluations[${idx}].rankedScore`)}
                    scoringCriteria={this.totalScoringCriteriaData}
                    showPercentageTotals={showPercentageTotals}
                    totalScorePercentage={get(datum, `proposalEvaluations[${idx}].totalScore`)}
                    usesRankedScoring={usesRankedScoring}
                />
            ), // Used for docx table
        }));

        const totalColumn = {
            Header: (
                <div className={`data-table-text-wrap ${this.styles.totalCol}`}>
                    Total Score
                    {!showPercentageTotals && (
                        <>
                            <br />
                            (Max Score {totalWeight})
                        </>
                    )}
                </div>
            ),
            accessor: showPercentageTotals ? 'totalScore' : 'totalScoreByPoints',
            Cell: (props) => (
                <CriteriaScore
                    className={this.styles.totalCol}
                    criteriaScore={roundNumber(props.value, 2)}
                    isTotal
                    scoringCriteria={this.totalScoringCriteriaData}
                    showPercentageTotals={showPercentageTotals}
                    totalScorePercentage={get(props, 'original.totalScore')}
                />
            ),
            // eslint-disable-next-line react/prop-types
            DocxCell: ({ datum, value }) => (
                <CriteriaScoreDocx
                    criteriaScore={roundNumber(value, 2)}
                    isTotal
                    scoringCriteria={this.totalScoringCriteriaData}
                    showPercentageTotals={showPercentageTotals}
                    style={{ fontWeight: 'bold' }}
                    totalScorePercentage={get(datum, 'totalScore')}
                />
            ), // Used for docx table
        };

        const columns = [vendorColumn, ...evaluatorColumns, totalColumn];

        if (usesRankedScoring) {
            const rankedScoreCount = proposals.filter((prop) => isNumber(prop.rankedScore)).length;
            const calculateTotalRankedScorePercentage = (value) => {
                if (!isNumber(value)) {
                    return null;
                }
                return ((rankedScoreCount - value) / (rankedScoreCount - 1)) * 100;
            };
            const sharedCellProps = (value) => {
                return {
                    criteriaScore: roundNumber(value, 2),
                    isTotal: true,
                    scoringCriteria: this.totalRankedCriteriaData,
                    totalScorePercentage: calculateTotalRankedScorePercentage(value),
                };
            };
            columns.push({
                Header: (
                    <div className={`data-table-text-wrap ${this.styles.totalCol}`}>
                        Total Average Rank
                    </div>
                ),
                accessor: 'rankedScore',
                Cell: (props) => (
                    <CriteriaScore
                        className={this.styles.totalCol}
                        {...sharedCellProps(props.value)}
                    />
                ),
                // eslint-disable-next-line react/prop-types
                DocxCell: ({ value }) => (
                    <CriteriaScoreDocx style={{ fontWeight: 'bold' }} {...sharedCellProps(value)} />
                ), // Used for docx table
            });
        }

        const usePagination = proposals.length > MAX_ROWS + MINIMUM_OVERFLOW_ROWS_FOR_PAGINATION;

        const formatDataForCSVExport = (data) => {
            const headers = [
                'Vendor',
                ...flatten(
                    evaluatorColumns.map((column) => {
                        const evaluatorName = column.Header;
                        if (usesRankedScoring) {
                            return [`${evaluatorName} Score`, `${evaluatorName} Rank`];
                        }
                        return evaluatorName;
                    })
                ),
                'Total Score',
                ...(usesRankedScoring ? ['Total Average Rank'] : []),
            ];

            const rows = data.map((dataRow) => {
                return [
                    dataRow[vendorColumn.id],
                    ...flatten(
                        evaluatorColumns.map((column, index) => {
                            const totalScore = formatCSVExportScorePercentage(
                                dataRow[column.accessor],
                                showPercentageTotals
                            );
                            if (usesRankedScoring) {
                                const rankedScore = formatCSVExportScorePercentage(
                                    dataRow._original.proposalEvaluations[index].rankedScore // eslint-disable-line no-underscore-dangle
                                );
                                return [totalScore, rankedScore];
                            }
                            return totalScore;
                        })
                    ),
                    formatCSVExportScorePercentage(
                        dataRow[totalColumn.accessor],
                        showPercentageTotals
                    ),
                    ...(usesRankedScoring
                        ? [formatCSVExportScorePercentage(dataRow.rankedScore)]
                        : []),
                ];
            });

            return [headers].concat(rows);
        };

        if (isDocx) {
            return (
                <ReportDocxTable
                    columns={columns}
                    data={orderBy(proposals, 'totalScoreByPoints', 'desc')}
                    useLandscape={this.props.useLandscape}
                />
            );
        }

        return (
            <DataTable
                className="-striped -highlight"
                columns={columns}
                csvExportOptions={{
                    fileName: 'Aggregate Scores Summary',
                    getFormattedCSVData: formatDataForCSVExport,
                    headers: true,
                }}
                data={proposals}
                defaultPageSize={usePagination ? MAX_ROWS : proposals.length}
                showCSVExport
                showPagination={usePagination}
            />
        );
    }
}

export const AggregateEvaluationTable = withRouter(ConnectedAggregateEvaluationTable);

import PropTypes from 'prop-types';

import { useVendorProposalSocket } from '../../hooks/vendorProposalHooks';

export const ProposalCreateSync = ({ proposalId }) => {
    // Subscribe to vendor proposal socket
    useVendorProposalSocket(proposalId);

    // This component doesn't render anything
    return null;
};

ProposalCreateSync.propTypes = {
    proposalId: PropTypes.number.isRequired,
};

import { createSelector } from 'reselect';

export const getShowPreInviteModal = (state) =>
    state.govProjects.getIn(['selectedProject', 'preInvite', 'showModal']);
export const getShowExpandedSupplierNetworkModal = (state) =>
    state.govProjects.getIn(['selectedProject', 'preInvite', 'showExpandedSupplierNetworkModal']);

export const getPreInviteLoadingState = (state) =>
    state.govProjects.getIn(['selectedProject', 'preInvite', 'loading']);

const getRawPreInviteEmailsList = (state) =>
    state.govProjects.getIn(['selectedProject', 'preInvite', 'emailsList']);

export const getPreInviteEmailsList = createSelector(
    [getRawPreInviteEmailsList],
    (rawEmailsList) => {
        if (rawEmailsList) {
            return rawEmailsList.toJS();
        }
        return [];
    }
);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

import { getProjectJS } from '../selectors';
import { hideShareLinkModal, showShareLinkModal } from '../../../actions/projectPost';

const mapStateToProps = (state) => {
    return {
        project: getProjectJS(state),
        showModal: state.projectPost.get('showShareLinkModal'),
        shareLink: state.projectPost.get('shareLink'),
    };
};

const mapDispatchToProps = {
    hideModal: hideShareLinkModal,
    showShareLinkModal,
};

// @connect
class ConnectedProjectShareLinkModal extends Component {
    static propTypes = {
        hideModal: PropTypes.func.isRequired,
        project: PropTypes.shape({
            wasPosted: PropTypes.bool,
        }),
        shareLink: PropTypes.string,
        showModal: PropTypes.bool.isRequired,
        showShareLinkModal: PropTypes.func.isRequired,
    };

    componentDidUpdate(prevProps) {
        if (!prevProps.showModal && this.props.showModal) {
            this.selectText();
        }
    }

    handleShareLinkClick = () => {
        const { shareLink } = this.props;

        this.props.showShareLinkModal(null, null, shareLink);
    };

    // Adapted from https://stackoverflow.com/a/34748190
    selectText = () => {
        if (this.shareLink && document.createRange && window.getSelection) {
            const link = this.shareLink.firstChild;
            const range = document.createRange();
            range.setStart(link, 0);
            range.setEnd(link, link.length);

            const sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
        }
    };

    setLinkRef = (linkRef) => {
        this.shareLink = linkRef;
    };

    render() {
        const { hideModal, project, shareLink, showModal } = this.props;

        const styles = require('./ProjectShareLinkModal.scss');
        return (
            <Modal onHide={hideModal} show={showModal}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">Project Share Link</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <p className={styles.info}>Use this link to publicly share your project:</p>
                    <div
                        className={`${styles.link} text-primary`}
                        onClick={this.handleShareLinkClick}
                        ref={this.setLinkRef}
                    >
                        {shareLink}
                    </div>
                    <p className="text-success">
                        <em>Project share link has been copied to clipboard</em>
                    </p>
                    {project && !project.wasPosted && (
                        <p className={`${styles.info}`}>
                            <strong className="text-danger">WARNING!</strong>
                            <br />
                            This project has not been published yet.
                            <br />
                            The project will not be publicly viewable until it is published.
                        </p>
                    )}
                </Modal.Body>
            </Modal>
        );
    }
}

export const ProjectShareLinkModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedProjectShareLinkModal);

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@og-pro/ui';

import { CDSButton } from '../../../../../../../components';

export const AddPhaseButton = ({ disabled, itemsLength, onClick }) => {
    return (
        <Box alignItems="center" display="flex">
            <Box flex={1} pr={2}>
                <hr />
            </Box>
            <Box flex={1} textAlign="center">
                <CDSButton
                    aria-label="add-evaluation-phase"
                    disabled={disabled}
                    onClick={onClick}
                    qaTag="templateEdit-addEvaluationPhase"
                    variant="secondary"
                >
                    <i className="fa fa-plus" />{' '}
                    {itemsLength === 0 ? 'Add Evaluation Phase' : 'Add Another Evaluation Phase'}
                </CDSButton>
            </Box>
            <Box flex={1} pl={2}>
                <hr />
            </Box>
        </Box>
    );
};

AddPhaseButton.propTypes = {
    disabled: PropTypes.bool,
    itemsLength: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
};

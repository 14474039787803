import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Box } from '@og-pro/ui';

import { questionLogicLinkableModelNames } from '@og-pro/shared-config/questionLogics';

import { CDSButton } from '../../../CDSButtons';
import { SDv2QuestionLogicIcon } from '../../../../../containers/GovApp/TemplateAdmin/components';
import { useElementDimensions } from '../../../../../hooks';
import { FOOTER_FORM_WIDTH_BREAKPOINT } from '../../constants';

const { PROJECT_SECTION } = questionLogicLinkableModelNames;

export const Footer = ({
    invalid,
    buttonLabel = 'Save',
    onCancel,
    onDelete,
    onSave,
    section,
    showAutomationsButton = false,
}) => {
    const ref = useRef();
    const { matches } = useElementDimensions(
        ref,
        ({ width }) => width && width < FOOTER_FORM_WIDTH_BREAKPOINT
    );
    const styles = require('../../shared.scss');

    return (
        <div
            ref={(r) => {
                ref.current = r;
            }}
        >
            <Box
                alignItems={matches ? 'flex-start' : 'center'}
                display="flex"
                flexDirection={matches ? 'column-reverse' : 'row'}
                justifyContent="space-between"
            >
                <Box>
                    <Box alignItems="center" display="flex">
                        <Box mr={1}>
                            <CDSButton
                                disabled={invalid}
                                onClick={() => onSave()}
                                qaTag="projectSectionForm-headerSave"
                                variant="primary"
                            >
                                {buttonLabel}
                            </CDSButton>
                        </Box>
                        <Box mr={1}>
                            <CDSButton
                                onClick={() => onCancel()}
                                qaTag="projectSectionForm-headerCancel"
                                variant="secondary"
                            >
                                Cancel
                            </CDSButton>
                        </Box>
                        {onDelete && (
                            <CDSButton
                                className={classnames(styles.deleteButton, {
                                    [styles.textRight]: matches,
                                })}
                                noPadding
                                onClick={() => onDelete()}
                                qaTag="projectSectionForm-delete"
                                size="small"
                                variant="text"
                            >
                                <i className="fa fa-trash" /> Delete section
                            </CDSButton>
                        )}
                    </Box>
                </Box>
                {showAutomationsButton && (
                    <Box mb={matches ? 2 : 0} textAlign="right">
                        <SDv2QuestionLogicIcon
                            linkable={PROJECT_SECTION}
                            linkableItem={section}
                            projectSection={section}
                        />
                    </Box>
                )}
            </Box>
        </div>
    );
};

Footer.propTypes = {
    buttonLabel: PropTypes.string,
    invalid: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onSave: PropTypes.func.isRequired,
    section: PropTypes.shape({}),
    showAutomationsButton: PropTypes.bool,
};

import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';

import { Button, InputText } from '../../../../components';
import { getVendorLists } from '../selectors';
import { fieldNames, form } from './constants';
import { validate } from './validate';
import { createOrUpdateVendorList } from '../../../../actions/vendorList';

const { NAME } = fieldNames;

const formConfig = {
    form,
    validate,
};

const mapStateToProps = (state, props) => {
    const vendorLists = getVendorLists(state);
    const vendorList = vendorLists.filter((item) => item.id === props.id)[0];
    return {
        governmentId: props.governmentId,
        vendorList,
        initialValues: vendorList,
    };
};

const mapDispatchToProps = {
    createOrUpdateVendorList,
};

class ConnectedVendorListModal extends React.Component {
    static propTypes = {
        governmentId: PropTypes.number.isRequired,
        hideModal: PropTypes.func.isRequired,
        vendorList: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        }),
        handleSubmit: PropTypes.func.isRequired,
        createOrUpdateVendorList: PropTypes.func.isRequired,
    };

    hideModal = () => {
        this.props.hideModal();
    };

    submit = (data) => {
        const { vendorList, governmentId } = this.props;
        const list = {
            name: data[NAME],
        };

        if (vendorList) {
            list.id = vendorList.id;
        }

        this.props.createOrUpdateVendorList(governmentId, list).then(() => this.hideModal());
    };

    render() {
        const { vendorList, handleSubmit } = this.props;

        return (
            <Modal onHide={this.hideModal} show>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">
                        {vendorList ? `Edit ${vendorList.name}` : 'Create Vendor List'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(this.submit)}>
                        <Field
                            component={InputText}
                            help="Enter a name for the list"
                            label="List Name"
                            name={NAME}
                            placeholder="Vendor List Name"
                            type="text"
                        />
                        <Button bsStyle="primary" type="submit">
                            Save Vendor List
                        </Button>
                    </form>
                </Modal.Body>
            </Modal>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedVendorListModal);

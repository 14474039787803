import React from 'react';
import { Box } from '@og-pro/ui';

import { PROP_TYPES } from './constants';
import { DateInput } from './Date';
import { TextDateInput } from './TextDate';
import { LocationInput } from './Location';
import { PublicInput } from './Public';
import { AgendaInput } from './Agenda';
import { AuctionInput } from './Auction';

export const TimelineInputs = (props) => {
    const { isTemplate, project, timeline } = props;
    const styles = require('../shared.scss');

    return (
        <Box className={styles.inputsContainer}>
            {!timeline.hasTextDate && <DateInput {...props} />}
            {timeline.hasTextDate && <TextDateInput {...props} />}
            {(timeline.locationField || timeline.isCustomDate) && <LocationInput {...props} />}
            {
                // mind: condition is negated
                !(
                    (!timeline.isCustomDate && !timeline.isRequiredField) ||
                    (timeline.isCustomDate && !timeline.showLocation)
                ) && <PublicInput {...props} />
            }
            {!!project &&
                !isTemplate &&
                (timeline.preProposalAgendaUrlField || timeline.isPublic) && (
                    <AgendaInput {...props} />
                )}
            {timeline.hasAuctionEndDateField && <AuctionInput {...props} />}
        </Box>
    );
};

TimelineInputs.propTypes = PROP_TYPES;

import moment from 'moment';
import { createSelector } from 'reselect';

import { actionTypes } from '@og-pro/shared-config/requisitionLogs';
import { statusTypes } from '@og-pro/shared-config/requisitions';

import { getAllRequisitionLogs } from '../../../selectors';

const getRequisitionLogText = (requisitionLog) => {
    const { actionType, date, user } = requisitionLog;
    if (actionType === actionTypes.SUBMIT) {
        return `Submitted by ${user.displayName} on ${moment(date).format('M/D/YY [at] h:mma')}`;
    }
    if (actionType === actionTypes.BUDGET_OVERRIDE) {
        return `Budget Override by ${user.displayName} on ${moment(date).format(
            'M/D/YY [at] h:mma'
        )}`;
    }
    return `Returned on ${moment(date).format('M/D/YY [at] h:mma')}`;
};

export const getStepRequisitionLogsDisplay = createSelector(
    [getAllRequisitionLogs],
    (requisitionLogs) => {
        return requisitionLogs
            .filter((requisitionLog) => {
                return (
                    requisitionLog.actionType === actionTypes.SUBMIT ||
                    ([actionTypes.BUDGET_OVERRIDE, actionTypes.STATUS_CHANGE].includes(
                        requisitionLog.actionType
                    ) &&
                        requisitionLog.requisitionStatus === statusTypes.DRAFT_REJECTED)
                );
            })
            .map((requisitionLog) => {
                return {
                    text: getRequisitionLogText(requisitionLog),
                };
            });
    }
);

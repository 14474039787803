import { times } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { StarDisplay } from './StarDisplay';

export class StarsDisplay extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        onSelect: PropTypes.func,
        starColors: PropTypes.arrayOf(PropTypes.string.isRequired),
        starsCount: PropTypes.number,
        starSize: PropTypes.number,
        value: PropTypes.number,
    };

    static defaultProps = {
        starColors: ['#d9534f', '#f0ad4e', '#ed9c28', '#5cb85c', '#449d44'],
        starsCount: 5,
        starSize: 1,
    };

    constructor(props) {
        super(props);

        this.state = {
            hoverValue: null,
        };
    }

    removeHoverValue = () => this.setState({ hoverValue: null });

    setHoverValue = (hoverValue) => this.setState({ hoverValue });

    render() {
        const { disabled, onSelect, starColors, starsCount, starSize, value } = this.props;

        const { hoverValue } = this.state;

        const selectedValue = Math.round(hoverValue || value);
        const starColor = starColors[((selectedValue || 1) - 1) % starColors.length];

        return times(starsCount).map((index) => {
            const number = index + 1;
            return (
                <StarDisplay
                    disabled={disabled}
                    highlightColor={starColor}
                    isHighlighted={!!selectedValue && selectedValue >= number}
                    key={number}
                    number={number}
                    onSelect={onSelect}
                    removeHoverValue={this.removeHoverValue}
                    setHoverValue={this.setHoverValue}
                    size={starSize}
                />
            );
        });
    }
}

import { projectPermissionsDict } from '@og-pro/shared-config/projects';

const { VIEW, EDIT, CREATOR } = projectPermissionsDict;

export const permissionsSelectData = [
    { label: 'View', value: VIEW },
    { label: 'Edit', value: EDIT },
];

export const creatorSelectData = [{ label: 'Creator', value: CREATOR }];

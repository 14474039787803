import PropTypes from 'prop-types';
import React from 'react';

import { DisplaySection, HtmlContent } from '../..';

export const ContractInternalData = ({ contract: { isPublic, note, rebid } }) => {
    const styles = require('../index.scss');

    return (
        <DisplaySection
            header="INTERNAL INFORMATION"
            items={[
                {
                    icon: 'pencil',
                    label: 'Notes',
                    value: note ? (
                        <HtmlContent content={note} />
                    ) : (
                        <span className={styles.italicText}>None</span>
                    ),
                },
                {
                    icon: 'bullhorn',
                    label: 'Public',
                    value: (
                        <span className={`text-${isPublic ? 'success' : 'danger'}`}>
                            <i className="fa fa-dot-circle-o" /> {isPublic ? 'Yes' : 'No'}
                        </span>
                    ),
                },
                {
                    icon: 'refresh',
                    label: 'Re-Bid',
                    value: (
                        <span className={`text-${rebid ? 'success' : 'danger'}`}>
                            <i className="fa fa-dot-circle-o" /> {rebid ? 'Yes' : 'No'}
                        </span>
                    ),
                },
            ]}
        />
    );
};

ContractInternalData.propTypes = {
    contract: PropTypes.shape({
        isPublic: PropTypes.bool,
        note: PropTypes.string,
        rebid: PropTypes.bool,
    }).isRequired,
};

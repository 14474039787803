import { Box, Chip, TableCell as MuiTableCell, TableRow, Typography } from '@og-pro/ui';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import { statusTypes } from '@og-pro/shared-config/reviewSequences';

import { colsDict, columns } from './constants';
import { TemplateStatusLabel } from '../../../../components';

const { NAME, STATUS, OWNER, REVIEW_GROUP_COUNT, LAST_UPDATED_AT } = colsDict;
const { ARCHIVED, PUBLISHED } = statusTypes;

const TableCell = ({ columnName, ...props }) => {
    const { bodyProps } = columns[columnName];

    return (
        <MuiTableCell
            sx={(theme) => ({
                padding: `${theme.spacing(2)} !important`,
                verticalAlign: 'top',
            })}
            {...bodyProps}
            {...props}
        />
    );
};

TableCell.propTypes = {
    columnName: PropTypes.string.isRequired,
};

export const ReviewSequencesListItem = ({
    onClick,
    reviewSequence: {
        id,
        isDefault,
        isException,
        lastUpdatedAt,
        name,
        requestTypeGroupSequences,
        status,
        user,
    },
}) => {
    return (
        <TableRow
            data-qa={`reviewSequenceList-listItem${id}`}
            hover
            onClick={onClick}
            sx={{
                ':hover': {
                    cursor: 'pointer',
                },
            }}
        >
            <TableCell columnName={NAME}>
                <Typography
                    color={capitalDesignTokens.semanticColors.foreground.action}
                    variant="h4"
                >
                    {name || 'Untitled'}
                </Typography>
                <Box marginTop={0.5}>
                    {isDefault && (
                        <Chip
                            color="primary"
                            label="Default Sequence"
                            size="extraSmall"
                            variant="tag"
                        />
                    )}
                    {isException && (
                        <Chip color="warning" label="Exception" size="extraSmall" variant="tag" />
                    )}
                </Box>
            </TableCell>
            <TableCell columnName={STATUS}>
                <TemplateStatusLabel
                    isArchived={status === ARCHIVED}
                    isPublished={status === PUBLISHED}
                    size="small"
                    useOpenGovStyle
                />
            </TableCell>
            <TableCell columnName={OWNER}>
                {user && <Typography component="span">{user.displayName}</Typography>}
            </TableCell>
            <TableCell columnName={REVIEW_GROUP_COUNT}>
                <Typography>{requestTypeGroupSequences.length}</Typography>
            </TableCell>
            <TableCell columnName={LAST_UPDATED_AT}>
                <Typography>{moment(lastUpdatedAt).format('M/D/YY [at] h:mma')}</Typography>
            </TableCell>
        </TableRow>
    );
};

ReviewSequencesListItem.propTypes = {
    onClick: PropTypes.func.isRequired,
    reviewSequence: PropTypes.shape({
        id: PropTypes.number.isRequired,
        isDefault: PropTypes.bool.isRequired,
        isException: PropTypes.bool.isRequired,
        name: PropTypes.string,
        lastUpdatedAt: PropTypes.string.isRequired,
        requestTypeGroupSequences: PropTypes.array.isRequired,
        status: PropTypes.number.isRequired,
        user: PropTypes.shape({
            displayName: PropTypes.string.isRequired,
        }),
    }).isRequired,
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DropdownButton, Button } from 'react-bootstrap';
import { every } from 'lodash';

import { MenuItem } from '..';
import { trackEvent } from '../../helpers';

export class SuggestionListMultiItem extends Component {
    static propTypes = {
        disabled: PropTypes.bool,
        isReverseAuction: PropTypes.bool.isRequired,
        suggestion: PropTypes.object.isRequired,
        options: PropTypes.array.isRequired,
    };

    shouldComponentUpdate(nextProps) {
        // Prevents component from being updated everytime the ProjectCreate
        // form is updated
        return (
            nextProps.disabled !== this.props.disabled ||
            nextProps.suggestion !== this.props.suggestion ||
            nextProps.options.length !== this.props.options.length ||
            !every(nextProps.options, (opt, i) => opt.title === this.props.options[i].title)
        );
    }

    clickHandler = (item) => {
        const { disabled, suggestion } = this.props;
        if (disabled) return false;

        trackEvent('Suggested Item Added', { ...suggestion });
        item.clickHandler(suggestion);
    };

    handleSingleOptionClick = () => {
        const { options } = this.props;

        this.clickHandler(options[0]);
    };

    renderNoOptionsItem() {
        const styles = require('./SuggestionList.scss');
        return (
            <Button className={`list-group-item ${styles.group}`} disabled={this.props.disabled}>
                {this.renderButtonTitle()}
            </Button>
        );
    }

    renderSingleOptionItem() {
        const styles = require('./SuggestionList.scss');
        return (
            <Button
                className={`list-group-item ${styles.group}`}
                disabled={this.props.disabled}
                onClick={this.handleSingleOptionClick}
            >
                {this.renderButtonTitle()}
            </Button>
        );
    }

    renderButtonTitle() {
        const styles = require('./SuggestionList.scss');
        return (
            <span>
                <i className={`fa fa-fw fa-plus text-info ${styles.icon}`} />
                {this.props.suggestion.title}
            </span>
        );
    }

    render() {
        const { disabled, isReverseAuction, options = [], suggestion } = this.props;

        const styles = require('./SuggestionList.scss');

        if (options.length === 0) return this.renderNoOptionsItem();
        if (isReverseAuction || options.length === 1) return this.renderSingleOptionItem();

        const MenuOptions = options.map((opt, index) => (
            <MenuItem
                key={index}
                onClick={() => this.clickHandler(opt)}
                qaTag={`suggestionList-${opt.title}`}
            >
                {opt.title}
            </MenuItem>
        ));

        return (
            <DropdownButton
                className={`list-group-item ${styles.group}`}
                disabled={disabled}
                id={suggestion.id || suggestion.title}
                noCaret
                pullRight
                title={this.renderButtonTitle()}
            >
                <MenuItem header qaTag="suggestionList-addItemTo">
                    Add Item To:
                </MenuItem>
                {MenuOptions}
            </DropdownButton>
        );
    }
}

import React from 'react';
import PropTypes from 'prop-types';

import { CDSButton } from '../../CDSButtons';

export const BaseSectionsNavNextButton = ({ children, onClick }) => {
    const styles = require('./index.scss');

    return (
        <CDSButton
            className={styles.navButton}
            onClick={() => (onClick ? onClick() : null)}
            qaTag="navButtons-next"
            variant="text"
        >
            <span className={styles.sectionName}>{children}</span>
            <span>
                &nbsp;&ndash; Next <i className="fa fa-chevron-right fa-fw" />
            </span>
        </CDSButton>
    );
};

BaseSectionsNavNextButton.propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
};

import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { reduxForm, Field } from 'redux-form';
import { Box } from '@og-pro/ui';

import { fieldName, formName } from '../constants';
import { SearchSelect, FormError } from '../../../../components';
import { useEsriGeolocation } from '../../../../hooks';
import { normalizeFindAddressCandidate } from '../../../../helpers';

const ConnectedSuppliersNetworkModalForm = ({ change, loading, onLocationsChange }) => {
    const esriCategory = 'City,Region,Postal';
    const { loading: locationLoading, suggest, findAddressCandidates } = useEsriGeolocation();
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const [errored, setErrored] = useState(false);

    const suggestionsSearch = useCallback(async (val) => {
        setErrored(false);

        if (val?.length < 3) {
            return;
        }

        try {
            const suggestions = await suggest({
                text: val,
                category: esriCategory,
                maxSuggestions: 6,
            });
            setOptions(
                suggestions
                    .filter(({ text }) => !/county/gi.test(text))
                    .map((suggestion) => ({
                        label: suggestion.text,
                        value: suggestion.text,
                        magicKey: suggestion.magicKey,
                    }))
            );
        } catch (e) {
            setErrored(true);
        }
    }, []);
    // debounce the suggestionsSearch to avoid calling it immediately after the user types
    const debouncedSuggestionsSearch = useCallback(debounce(suggestionsSearch, 300), []);

    /**
     * Handles the input onChange when an option is selected / deleted.
     * When an option is selected it will have the props { label, value, magicKey }
     * Those props are temporary, it's not what we actually want to select. So we have
     * to make a call to findAddressCandidates to get the actual location data.
     * Once we have that data we run it through a normalize function to standardize it a bit.
     * Once that is done we will have an array of normalized locations and we manually trigger
     * a change event to update the form.
     * As you select 2, 3, 4 locations, some of the locations in the values array will be already
     * normalized. We detect those because they don't have the "magicKey" prop.
     */
    const onChange = useCallback(async (values) => {
        setErrored(false);

        if (!values.length) {
            return onLocationsChange([]);
        }

        try {
            const normalizedValues = await Promise.all(
                values.map((selectedValue) => {
                    // if there's no magicKey it means the value is already normalized
                    // we leave it as is
                    if (!selectedValue.magicKey) {
                        return selectedValue;
                    }

                    return findAddressCandidates({
                        SingleLine: selectedValue.label,
                        magicKey: selectedValue.magicKey,
                        category: esriCategory,
                    }).then((res) => {
                        if (!res.length) {
                            // we should never reach this point unless something went wrong with ESRI itself
                            throw new Error('No results found.');
                        }

                        return normalizeFindAddressCandidate(res[0]);
                    });
                })
            );
            change(fieldName, normalizedValues);
            onLocationsChange(normalizedValues);
        } catch (e) {
            setErrored(true);
        }
    }, []);

    // controls the call to the suggest API so that we can populate a list of
    // options relevant to what the user typed
    useEffect(() => {
        if (inputValue) {
            debouncedSuggestionsSearch(inputValue);
        } else {
            setOptions([]);
        }

        return () => {
            debouncedSuggestionsSearch.cancel();
        };
    }, [inputValue, debouncedSuggestionsSearch]);

    /*
        getOptionValue below is intentional. The "label" and the "value" for this input's value comes from the label prop.
        This feeds from the normalizeFindAddressCandidate which in turn feeds from findAddressCandidates.
        findAddressCandidates has no unique identifier that can be used as "value". So the label is used instead.
        (its unique enough, for example "New York, NY, USA").
    */
    return (
        <Box>
            <Field
                aria-label="Search Locations"
                blurInputOnSelect
                closeMenuOnSelect
                component={SearchSelect}
                disabled={loading}
                getOptionValue={(option) => option.label}
                inputValue={inputValue}
                isClearable
                isLoading={loading}
                isMulti
                label="Search by Location"
                name={fieldName}
                noOptionsMessage={() => (locationLoading ? 'Loading...' : 'Type to search')}
                onChange={(v) => onChange(v)}
                onInputChange={(v) => setInputValue(v)}
                options={options}
                placeholder="Search a Location"
                qaTag="supplierNetworkModal-searchSelect"
                rawValue
                showValidation
                useOpenGovStyle
            />
            {errored && (
                <FormError
                    error="There was an error contacting the geolocation service. Please try again"
                    useOpenGovStyle
                />
            )}
        </Box>
    );
};

ConnectedSuppliersNetworkModalForm.propTypes = {
    change: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    onLocationsChange: PropTypes.func.isRequired,
};

export const SuppliersNetworkModalForm = reduxForm({
    enableReinitialize: true,
    form: formName,
})(ConnectedSuppliersNetworkModalForm);

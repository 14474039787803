import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { statusTypes } from '@og-pro/shared-config/requisitions';

import { qaTagPageName } from '../constants';
import { showConfirmationSimpleModal } from '../../../../actions/confirmation';
import { copyRequisition, deleteRequisition } from '../../../../actions/requisitions';
import { CDSButton, CDSDropdownButton, MenuItem } from '../../../../components';
import { getRequisitionJS } from '../../../../selectors/govApp';

const { DRAFT } = statusTypes;

export const RequisitionsCreateActionButtons = ({
    disabled,
    saveRequisition,
    showGlobalFormValidation,
}) => {
    const styles = require('./index.scss');
    const dispatch = useDispatch();
    const { id, status } = useSelector(getRequisitionJS);

    const isDeletable = status === DRAFT;

    const deleteAction = () => dispatch(deleteRequisition(id));
    const copyAction = () => dispatch(copyRequisition(id));

    const deleteHandler = () =>
        dispatch(
            showConfirmationSimpleModal(deleteAction, {
                btnText: 'Delete Request',
                icon: 'trash',
                text: 'Are you sure you want to delete this request?\nThis action cannot be undone.',
            })
        );
    const copyHandler = () =>
        dispatch(
            showConfirmationSimpleModal(copyAction, {
                bsStyle: 'primary',
                btnText: 'Copy Request',
                icon: 'copy',
                text: 'Are you sure you want to create a copy this request?',
            })
        );

    return (
        <div className={styles.actionButtons}>
            <CDSDropdownButton
                aria-label="More Actions"
                disabled={disabled}
                id={`${qaTagPageName}-more-button`}
                pullRight
                qaTag={`${qaTagPageName}-moreActions`}
                title="Actions"
                variant="secondary-alt"
            >
                <MenuItem
                    onClick={showGlobalFormValidation}
                    qaTag={`${qaTagPageName}-showFormErrors`}
                >
                    <i className="fa fa-fw fa-exclamation-triangle" /> Show Form Errors
                </MenuItem>
                <MenuItem onClick={copyHandler} qaTag={`${qaTagPageName}-copy`}>
                    <i className="fa fa-fw fa-copy" /> Copy Request
                </MenuItem>
                {isDeletable && (
                    <MenuItem onClick={deleteHandler} qaTag={`${qaTagPageName}-delete`}>
                        <i className="fa fa-fw fa-trash" /> Delete
                    </MenuItem>
                )}
            </CDSDropdownButton>
            <CDSButton
                aria-label="Save"
                disabled={disabled}
                onClick={() => saveRequisition()}
                qaTag={`${qaTagPageName}-save`}
                size="default"
                variant="secondary-alt"
            >
                Save Draft
            </CDSButton>
        </div>
    );
};

RequisitionsCreateActionButtons.propTypes = {
    disabled: PropTypes.bool,
    saveRequisition: PropTypes.func.isRequired,
    showGlobalFormValidation: PropTypes.func.isRequired,
};

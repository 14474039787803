import { isNull, isNumber } from 'lodash';

import {
    sectionTypeNames,
    allowedIntakeSections,
    defaultSectionConfigs,
    defaultSectionConfigsMap,
} from '@og-pro/shared-config/sections';
import { numberItems, getSectionNumberingString } from '@og-pro/shared-config/helpers';

const { INTRODUCTION, DIVIDER, EVALUATION_CRITERIA, EVALUATION_PHASE } = sectionTypeNames;

/**
 * Takes an array of sections and returns a hierarchy-ize version where
 * each Divider or Title is going to have `children` containing their children
 *
 * @param {object[]} items Array of `projectSections`
 * @returns {object[]} Array of elements where parents have `children` containing the elements
 */
export const itemsToHierarchy = (items) => {
    const hierarchy = [];
    let current = null;
    let i = 0;

    while (i < items.length) {
        const item = items[i];

        if (item.section_type === DIVIDER) {
            if (current) {
                hierarchy.push(current);
            }

            current = {
                ...item,
                children: [],
            };
        } else if (current) {
            current.children.push(item);
        } else {
            hierarchy.push(item);
        }

        i += 1;
    }

    if (current) {
        hierarchy.push(current);
    }

    return hierarchy;
};

/**
 * Returns an array of section.index from all the sections that can be navigated
 *
 * @param {object[]} sections Array of sections with hierarchy
 * @param {boolean} navigateDividers Whether or not to include elements with children
 * @returns {number[]} Array of indexes (the props, not the actual indexes)
 */
export const getNavigableSectionsIndexes = (sections = [], navigateDividers = false) => {
    return sections.reduce((acc, section) => {
        let indexes = [];

        if (!section.children?.length || (section.children?.length && navigateDividers)) {
            indexes.push(section.index);
        }

        indexes = indexes.concat((section.children || []).map((child) => child.index));

        return acc.concat(indexes);
    }, []);
};

/**
 * Returns a section - either a parent or a child within - finding by the index prop
 *
 * @param {object[]} sections Array of sections with hierarchy
 * @param {number} index index value to find
 * @returns {object} a single section
 */
export const getSectionByIndex = (sections, index) => {
    if (isNull(index)) {
        return null;
    }

    let i = 0;
    while (i < sections.length) {
        const section = sections[i];

        if (section.index === index) {
            return section;
        }

        if (section.children?.length) {
            const child = section.children.find((c) => c.index === index);

            if (child) {
                return child;
            }
        }

        i += 1;
    }

    return null;
};

/**
 * Given an array of sections with hierarchy where one of them is active
 * returns the element that comes before or after when navigating
 *
 * @param {object[]} sections Array of sections with hierarchy
 * @param {number[]} navigableIndexes Array of the index prop of all the navigable sections
 * @param {number} sum positive or negative value to which to go (back or forth)
 * @returns {number} the index value relative to the navigableIndexes array in which the desired element is
 */
export const getPrevOrNextIndex = (sections, navigableIndexes, sum = 1) => {
    const activeSection = sections.find((section) => section.active);

    if (activeSection) {
        const navigableIndex = navigableIndexes.findIndex((index) => index === activeSection.index);
        let indexToGoTo = null;

        if (navigableIndex === -1) {
            if (sum === 1) {
                indexToGoTo = navigableIndexes.find((index) => index > activeSection.index);
            }

            if (sum === -1) {
                indexToGoTo = navigableIndexes.findLast((index) => index < activeSection.index);
            }
        } else {
            indexToGoTo = navigableIndexes[navigableIndex + sum];
        }

        return isNumber(indexToGoTo) ? indexToGoTo : null;
    }

    const parentWithActiveChild = sections.find((section) => {
        return section.children?.find((child) => child.active);
    });
    const activeChild = parentWithActiveChild?.children.find((child) => child.active);

    if (activeChild) {
        const navigableIndex = navigableIndexes.findIndex((index) => index === activeChild.index);
        const indexToGoTo = navigableIndexes[navigableIndex + sum];

        return isNumber(indexToGoTo) ? indexToGoTo : null;
    }

    return null;
};

/**
 * Given an array of sections with hierarchy where one of them is active
 * returns the previous element
 *
 * @param {object[]} sections Array of sections with hierarchy
 * @param {number[]} navigableIndexes Array of the index prop of all the navigable sections
 * @returns {object} the previous section, if any
 */
export const getBackSection = (sections, navigableIndexes) => {
    const prevIndex = getPrevOrNextIndex(sections, navigableIndexes, -1);

    return getSectionByIndex(sections, prevIndex);
};

/**
 * Given an array of sections with hierarchy where one of them is active
 * returns the next element
 *
 * @param {object[]} sections Array of sections with hierarchy
 * @param {number[]} navigableIndexes Array of the index prop of all the navigable sections
 * @returns {object} the next section, if any
 */
export const getNextSection = (sections, navigableIndexes) => {
    const nextIndex = getPrevOrNextIndex(sections, navigableIndexes);

    return getSectionByIndex(sections, nextIndex);
};

/**
 * Takes an array of project sections and returns them as a hierarchy including the section numbers
 *
 * @param {object[]} projectSections
 * @param {boolean} useManualNumbering
 * @returns {object[]}
 */
export const projectSectionsToNumberedHierarchy = (projectSections, useManualNumbering) => {
    const sections = numberItems(projectSections || []).map((section, index) => {
        if (useManualNumbering) {
            return {
                ...section,
                index,
            };
        }

        const { sectionNumber, subsectionNumber } = section;
        const numbering = getSectionNumberingString({
            sectionNumber,
            subsectionNumber,
        });

        return {
            ...section,
            index, // keeping the index here because when we create a hierarchy below it will get lost
            numbering,
            manualNumber: null,
        };
    });
    // first pass we number the items and form the title we are displaying
    // second pass we create the hierarchy which produces `children: [...]`
    // and then take only the title from the data above
    return itemsToHierarchy(sections);
};

/**
 * Turns a project sections array to the sections needed for the nav elements
 * @param {object[]} projectSections Array of project/template sections
 * @param {number} active currently active element
 * @param {function} setActive function to change the currently active element
 * @param {boolean} useManualNumbering
 * @param {object} formErrors contains validation status
 * @param {boolean} showFormValidation to show validation or not
 * @param {function} matchErrorToSection Custom function that accepts a full section as parameter and returns an error
 * @param {boolean} hideEmptyDividers Hides dividers that have nothing inside
 * @returns {object[]} Array of nav sections
 */
export const projectSectionsToNavSections = ({
    projectSections = [],
    active,
    setActive,
    useManualNumbering,
    formErrors = {},
    showFormValidation = false,
    matchErrorToSection = null,
    hideEmptyDividers = false,
}) => {
    const getShouldShowValidationError = (section) => {
        /*
         * To fully check for validation errors, we have to check multiple places...
         *
         * One object for the section "meta" (title, manual numbering, etc)
         * `formErrors.projectSections[section.index].hasErrors`
         *
         * Another for actual content (required fields on list items, etc);
         * `formErrors.projectSectionErrors[section.id]`
         */
        const projectSectionHasError = matchErrorToSection
            ? matchErrorToSection(section)
            : formErrors?.projectSections?.[section.index]?.hasErrors ||
              formErrors?.projectSectionErrors?.[section.id];

        return projectSectionHasError && showFormValidation;
    };

    const sections = projectSectionsToNumberedHierarchy(projectSections, useManualNumbering).map(
        (section) => {
            return {
                id: section.id,
                title: section.title,
                numbering: section.numbering || null,
                manualNumber: section.manualNumber,
                sectionType: section.section_type,
                disableNumbering: section.disableNumbering,
                disableTitle: section.disableTitle,
                shortName: section.shortName,
                children: section.children?.map((child) => {
                    return {
                        id: child.id,
                        title: child.title,
                        numbering: child.numbering || null,
                        manualNumber: child.manualNumber,
                        index: child.index,
                        active: active === child.index,
                        sectionType: child.section_type,
                        disableNumbering: child.disableNumbering,
                        disableTitle: child.disableTitle,
                        shortName: child.shortName,
                        onClick: () => {
                            setActive(child.index);
                        },
                        showValidationError: getShouldShowValidationError(child),
                    };
                }),
                index: section.index,
                active: active === section.index,
                onClick: () => {
                    setActive(section.index);
                },
                showValidationError:
                    getShouldShowValidationError(section) ||
                    !!section.children?.some((c) => getShouldShowValidationError(c)),
            };
        }
    );

    if (hideEmptyDividers) {
        return sections.filter(
            (section) => section.sectionType !== DIVIDER || section.children?.length
        );
    }

    return sections;
};

/**
 * Returns two lists of project sections to populate the "Add section" component
 *
 * @param {boolean} isIntake
 * @param {boolean} allowSectionDividers
 * @param {string[]} usedSections Array of section_type of the already used sections
 * @param {boolean} isDocBuilder
 * @returns {object}
 */
export const getAvailableSectionsToAdd = ({
    isIntake,
    allowSectionDividers,
    usedSections,
    isDocBuilder,
}) => {
    const sectionTypeOptions = defaultSectionConfigs.filter((config) => !config.isHidden);

    const { general, special } = sectionTypeOptions.reduce(
        (acc, sectionTypeOption) => {
            if (sectionTypeOption.section_type === INTRODUCTION) {
                return acc;
            }

            if (isIntake && !allowedIntakeSections.includes(sectionTypeOption.section_type)) {
                return acc;
            }

            if (sectionTypeOption.section_type === DIVIDER && !allowSectionDividers) {
                return acc;
            }

            if (sectionTypeOption.isGeneral) {
                // if the section is marked for docBuilder, we only want to show it if its a docBuilder
                // to this day this is only the signature section
                if (sectionTypeOption.isDocBuilderAllowed) {
                    if (isDocBuilder) {
                        acc.general.push(sectionTypeOption);
                    }
                } else {
                    // otherwise we always add them, these are list items and text area
                    acc.general.push(sectionTypeOption);
                }

                // Special use sections should only be allowed to be added once
            } else if (
                !usedSections.includes(sectionTypeOption.section_type) &&
                (!isDocBuilder || sectionTypeOption.isDocBuilderAllowed)
            ) {
                // Special use sections should only be added to non-docBuilders unless specified
                acc.special.push(sectionTypeOption);
            }

            return acc;
        },
        {
            general: [],
            special: [],
        }
    );

    return { general, special };
};

export const isEvaluationSection = (projectSection) => {
    return (
        projectSection.section_type === EVALUATION_CRITERIA ||
        projectSection.section_type === EVALUATION_PHASE
    );
};

/**
 * Returns a lists of template sections to populate the "Add section" component
 *
 * @param {boolean} isIntake
 * @param {boolean} allowSectionDividers
 * @param {object[]} templateSections Array of template sections
 * @param {string[]} usedTemplateSections Array of already used template section's sharedId
 * @param {string[]} usedSections Array of section_type of the already used sections
 * @param {boolean} isDocBuilder
 * @param {boolean} hasEvaluation
 * @returns {object}
 */
export const getAvailableTemplateSectionsToAdd = ({
    templateSections,
    isIntake,
    usedTemplateSections,
    usedSections,
    isDocBuilder,
    hasEvaluation,
}) => {
    return templateSections
        .filter((templateSection) => {
            const {
                projectSection,
                projectSection: { section_type: sectionType, sharedId },
            } = templateSection;

            const sectionConfig = defaultSectionConfigsMap[sectionType];

            if (isIntake && !allowedIntakeSections.includes(sectionType)) {
                return false;
            }

            // Template section can only be used once per template
            if (usedTemplateSections.includes(sharedId)) {
                return false;
            }

            // Only one type of evaluation section can be used
            if (isEvaluationSection(projectSection) && hasEvaluation) {
                return false;
            }

            // General use sections are always allowed
            if (sectionConfig.isGeneral) {
                return true;
            }

            // Handle case where special section is allowed and may have already been added
            const specialSectionNotUsed = !usedSections.includes(sectionType);
            const isSpecialSectionAllowed = !isDocBuilder || sectionConfig.isDocBuilderAllowed;
            return isSpecialSectionAllowed && specialSectionNotUsed;
        })
        .map((templateSection) => {
            const {
                id,
                projectSection: { section_type: sectionType, shortName, title },
            } = templateSection;
            const sectionConfig = defaultSectionConfigsMap[sectionType];

            return {
                label: `${title} (${shortName})`,
                icon: sectionConfig.icon,
                value: id,
            };
        });
};

/**
 * Returns the title for the Section Header form
 *
 * @param {boolean} isAdding
 * @param {string} sectionType
 * @returns {string}
 */
export const getTitle = ({ isAdding, sectionType }) => {
    const action = isAdding ? 'Add' : 'Edit';
    const title = sectionType === DIVIDER ? 'Divider' : 'Section';

    return `${action} ${title}`;
};

/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import React, { useState } from 'react';
import { Box } from '@og-pro/ui';

import { usesDocxHeadingFormatters } from '../../../selectors';
import { CDSButton, InputText, ProgressBar } from '../../../../../components';
import { limitTextLength } from '../../../../../Forms/normalizers';
import { Dropzone } from './Dropzone';
import { DocxTemplateFormFieldset } from './Fieldset';
import { HEADING_FORMATTERS, HEADING_FORMATTERS_KEYS, TITLE } from './constants';
import { validate } from './validate';
import { parseHeadingFormatter } from '../../../../../helpers/docxHeadingFormatters';
import { ALLOWED_VARIABLES } from '../../../../../constants/docxHeaderFormatters';
import { updateDocxTemplateFile } from '../../../../../actions/templatesAdmin';
import { HeadingFormatterAllowedVariables } from './HeadingFormatterAllowedVariables';

const PLACEHOLDER =
    `${ALLOWED_VARIABLES.NUMBER}.${ALLOWED_VARIABLES.TAB}${ALLOWED_VARIABLES.TITLE}`.toUpperCase();

const initialUploadState = {
    uploading: false,
    progress: 0,
    uploadError: null,
    uploadFileName: null,
    file: null,
};

// this is a dynamic form rendered in a list
// the 'form' prop comes as a prop specified when calling this component
export const WordTemplatesListForm = reduxForm({
    enableReinitialize: true,
    validate,
})(({ actions, disabled, docxTemplate, form, handleSubmit, submitting, pristine, onClose }) => {
    const dispatch = useDispatch();
    const [uploadState, setUploadState] = useState(initialUploadState);
    const headingFormattersValues = useSelector((state) =>
        formValueSelector(form)(state, HEADING_FORMATTERS)
    );
    const usesFormatters = useSelector(usesDocxHeadingFormatters);
    const [show, setShow] = useState(false);
    const styles = require('../shared.scss');

    const onFileDrop = ([file]) => {
        if (file) {
            setUploadState((s) => ({
                ...s,
                file,
            }));
        }
    };

    const onUploadCancel = () => {
        setUploadState(initialUploadState);
    };

    const handleSubmitUploadReplaceSubmit = (values) => {
        setUploadState((s) => ({
            ...s,
            uploading: true,
            progress: 0,
            uploadFileName: values[TITLE],
        }));

        dispatch(
            updateDocxTemplateFile(
                docxTemplate.id,
                uploadState.file,
                { [TITLE]: values[TITLE] },
                (progress) => setUploadState((s) => ({ ...s, progress }))
            )
        )
            .then(() => setUploadState(initialUploadState))
            .catch((error) =>
                setUploadState((s) => ({ ...s, uploading: false, uploadError: error.message }))
            );
    };

    if (uploadState.file) {
        if (uploadState.uploading) {
            return (
                <>
                    <div>{uploadState.uploadFileName}</div>
                    <ProgressBar now={uploadState.progress} />
                </>
            );
        }

        return (
            <form onSubmit={handleSubmit(handleSubmitUploadReplaceSubmit)}>
                <DocxTemplateFormFieldset
                    cancelHandler={onUploadCancel}
                    disabled={disabled}
                    initialValues={{
                        [TITLE]: uploadState.file.name,
                    }}
                    uploadError={uploadState.uploadError}
                />
            </form>
        );
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-xs-12 col-sm-8">
                    <Field
                        component={InputText}
                        disabled={disabled}
                        hasFeedback={false}
                        label="Template Title"
                        name={TITLE}
                        normalize={limitTextLength(128)}
                        qaTag={`docxTemplates-${TITLE}`}
                        showValidation
                        type="text"
                        useOpenGovStyle
                    />
                    <div className={styles.fakeInputData}>
                        <Box className={styles.label}>File Name</Box>
                        <Box alignItems="center" display="flex">
                            <Box className={styles.value}>{docxTemplate.filename}</Box>
                            <Box className={styles.listFormDropzoneContainer} ml={2}>
                                <Dropzone dropHandler={onFileDrop} updating={disabled}>
                                    <CDSButton
                                        className={styles.uploadReplacementButton}
                                        qaTag="docxTemplates-uploadReplacement"
                                        size="small"
                                        variant="text"
                                    >
                                        <i className="fa fa-upload" /> Upload Replacement
                                    </CDSButton>
                                </Dropzone>
                            </Box>
                        </Box>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-4">{actions}</div>
            </div>
            {usesFormatters && (
                <Box className={styles.separatorsContainer}>
                    {!show && (
                        <Box>
                            <CDSButton
                                className={styles.showHideButton}
                                onClick={() => setShow(true)}
                                qaTag="docxTemplates-showAdvancedSettings"
                                size="small"
                                variant="text"
                            >
                                <i className="fa fa-chevron-right" /> Show Advanced Formatting
                                Settings
                            </CDSButton>
                        </Box>
                    )}
                    {show && (
                        <Box>
                            <Box mb={3}>
                                <CDSButton
                                    className={styles.showHideButton}
                                    onClick={() => setShow(false)}
                                    qaTag="docxTemplates-hideAdvancedSettings"
                                    size="small"
                                    variant="text"
                                >
                                    <i className="fa fa-chevron-down" /> Hide Advanced Formatting
                                    Settings
                                </CDSButton>
                            </Box>
                            <h4 className={styles.subsectionTitle}>Numbered Heading Separators</h4>
                            <p className={styles.subsectionBrief}>
                                By default when documents are exported, the section number is
                                separated from the section title by a tab (i.e. Part 1 Project
                                Summary). For projects using auto numbering, there is a period after
                                the number (i.e. 1. Project Summary). If the default approach does
                                not work for this document, you can make changes to how section
                                numbers display next to their corresponding titles by editing the
                                headings below. If the fields are left blank, the default format
                                will be used in the export for all rendered documents using this
                                template.
                            </p>
                            <HeadingFormatterAllowedVariables
                                title="Available Formatting Variables:"
                                variables={[
                                    {
                                        variable: ALLOWED_VARIABLES.NUMBER,
                                        subtitle: 'Section number.',
                                    },
                                    {
                                        variable: ALLOWED_VARIABLES.TITLE,
                                        subtitle: 'Section title.',
                                    },
                                    {
                                        variable: ALLOWED_VARIABLES.TAB,
                                        subtitle: 'Inserts one tab character.',
                                    },
                                    {
                                        variable: ALLOWED_VARIABLES.SPACE,
                                        subtitle: 'Inserts one space character.',
                                    },
                                    {
                                        variable: ALLOWED_VARIABLES.NEWLINE,
                                        subtitle: 'Starts a new line.',
                                    },
                                    {
                                        variable: null,
                                        subtitle: 'Punctuation can be used as normal.',
                                    },
                                ]}
                            />
                            <Box>
                                {[1, 2, 3, 4, 5].map((value, index) => (
                                    <Box display="flex" key={index}>
                                        <Box flex={4} mr={8}>
                                            <Field
                                                component={InputText}
                                                disabled={disabled}
                                                hasFeedback={false}
                                                label={`Heading ${value}`}
                                                name={`${HEADING_FORMATTERS}.${HEADING_FORMATTERS_KEYS[index]}`}
                                                normalize={limitTextLength(128)}
                                                placeholder={PLACEHOLDER}
                                                qaTag={`docxTemplates-heading${value}`}
                                                showValidation={false}
                                                type="text"
                                                useOpenGovStyle
                                            />
                                        </Box>
                                        <Box className={styles.preview} flex={2}>
                                            <Box className={styles.previewTitle}>
                                                {index === 0 ? 'Preview' : <>&nbsp;</>}
                                            </Box>
                                            <Box className={styles.previewOutput}>
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: parseHeadingFormatter(
                                                            (headingFormattersValues &&
                                                                headingFormattersValues[
                                                                    HEADING_FORMATTERS_KEYS[index]
                                                                ]) ||
                                                                PLACEHOLDER,
                                                            '1.'.repeat(value),
                                                            `Heading ${value} Title`,
                                                            true
                                                        ),
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    )}
                </Box>
            )}
            <Box textAlign="right">
                <Box
                    component={CDSButton}
                    mr={1}
                    onClick={() => onClose()}
                    qaTag="docxTemplates-closeExpandable"
                    variant="tertiary"
                >
                    Close
                </Box>
                <CDSButton
                    disabled={disabled || submitting || pristine}
                    qaTag="docxTemplates-submitForm"
                    type="submit"
                >
                    Save Changes
                </CDSButton>
            </Box>
        </form>
    );
});

WordTemplatesListForm.propTypes = {
    actions: PropTypes.node,
    disabled: PropTypes.bool,
    docxTemplate: PropTypes.shape({
        filename: PropTypes.string.isRequired,
    }).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
};

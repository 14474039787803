import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { getFormSyncErrors } from 'redux-form';

import { BaseProjectSectionsNav } from '../../../../../components/SDv2';
import { form } from '../../constants';
import { ProjectCreateV2NavContext, ProjectCreateV2FunctionsContext } from '../context';
import { getProjectPropertiesNav } from '../helpers';

export const ProjectCreateV2ProjectPropertiesNav = () => {
    const errorsSelectors = getFormSyncErrors(form);
    const formErrors = useSelector((state) => errorsSelectors(state));

    const { activeSectionId, setActiveSectionId } = useContext(ProjectCreateV2NavContext);
    const { initiateSneakyUpdate, project, showFormErrors } = useContext(
        ProjectCreateV2FunctionsContext
    );

    const sections = getProjectPropertiesNav({
        showFormErrors,
        formErrors,
        project,
        initiateSneakyUpdate,
        setActiveSectionId,
        activeSectionId,
    });

    return <BaseProjectSectionsNav sections={sections} />;
};

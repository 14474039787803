import { createSelector } from 'reselect';

const getData = (state) => state.confirmation.get('simpleData');

export const getDataJS = createSelector([getData], (data) => {
    if (data) {
        return data.toJS();
    }
    return {};
});

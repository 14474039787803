import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

// NOTE: This component is not meant to be used directly, except by other editors.

export class GenericEditor extends PureComponent {
    static propTypes = {
        inputType: PropTypes.oneOf(['number', 'text']),
        node: PropTypes.shape({
            rowHeight: PropTypes.number.isRequired,
        }).isRequired,
        onChange: PropTypes.func.isRequired,
        value: PropTypes.node,
    };

    static defaultProps = {
        inputType: 'text',
    };

    get styles() {
        return require('./index.scss');
    }

    afterGuiAttached = () => {
        this.input.focus();

        // This is a hack to ensure the cursor is at the end of the string after focusing.
        // https://stackoverflow.com/questions/35951771/react-autofocus-sets-curser-to-beginning-of-input-value
        // TODO: this can't be the best option for this, can it?
        const tempValue = this.input.value;
        this.input.value = '';
        this.input.value = tempValue;
    };

    handleChange = (event) => {
        this.props.onChange(event);
    };

    setRef = (ref) => {
        this.input = ref;
    };

    render() {
        const {
            inputType,
            node: { rowHeight },
            value,
            ...rest
        } = this.props;

        return (
            <input
                {...rest}
                autoFocus
                className={this.styles.component}
                onChange={this.handleChange}
                ref={this.setRef}
                style={{ height: rowHeight }}
                type={inputType}
                value={value}
            />
        );
    }
}

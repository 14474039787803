import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { ALLOW_MULTI_SELECT, DATA, OPTIONS } from '@og-pro/shared-config/questionnaires';

import { QuestionPrompt } from '../../SharedDisplay';

export class MultipleChoiceDisplay extends PureComponent {
    static propTypes = {
        data: PropTypes.shape({
            allowMultiSelect: PropTypes.bool,
            options: PropTypes.array,
        }),
        isDocx: PropTypes.bool,
        isRequired: PropTypes.bool,
        prompt: PropTypes.string,
        title: PropTypes.string,
    };

    renderMultiSelectText() {
        if (!get(this.props, [DATA, ALLOW_MULTI_SELECT])) {
            return null;
        }

        return (
            <p>
                <em>Select all that apply</em>
            </p>
        );
    }

    renderIcon() {
        return <i className="fa fa-fw fa-lg fa-circle-thin multiple-choice-icon" />;
    }

    render() {
        const options = get(this.props, [DATA, OPTIONS]) || [];

        if (this.props.isDocx) {
            return (
                <QuestionPrompt {...this.props}>
                    {this.renderMultiSelectText()}
                    {options.map((option, index) => (
                        <div key={index} style={{ marginBottom: 0 }}>
                            <input type="checkbox" /> {option}
                        </div>
                    ))}
                </QuestionPrompt>
            );
        }

        return (
            <QuestionPrompt {...this.props}>
                {this.renderMultiSelectText()}
                {options.map((option, index) => (
                    <div className="multiple-choice-item" key={index}>
                        {this.renderIcon()}
                        {option}
                    </div>
                ))}
            </QuestionPrompt>
        );
    }
}

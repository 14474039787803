import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';

import { form, importTextPlaceholder, RAW_TEXT } from './constants';
import { validate } from './validate';
import { Button, InputText } from '../../../../../../../../../components';

const formConfig = {
    form,
    validate,
};

export class ConnectedListImportForm1 extends PureComponent {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        importError: PropTypes.string,
        invalid: PropTypes.bool.isRequired,
    };

    render() {
        const { handleSubmit, importError, invalid } = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <Field
                    component={InputText}
                    hasFeedback={false}
                    help={
                        <>
                            Use <strong>&lt;title&gt;</strong> tag to delineate when an item&#39;s
                            title starts.
                            <br />
                            Use <strong>&lt;body&gt;</strong> tag to delineate where an item&#39;s
                            body starts.
                            <br />
                            Each item must have a matching&nbsp;
                            <strong>&lt;title&gt;</strong> and <strong>&lt;body&gt;</strong> tag.
                            <br />
                            Spacing around tags is optional and will be automatically removed.
                        </>
                    }
                    minRows={12}
                    name={RAW_TEXT}
                    overrideFeedback
                    placeholder={importTextPlaceholder}
                    type="textarea"
                />
                <div className="text-right">
                    <Button bsStyle="primary" disabled={invalid} type="submit">
                        Next <i className="fa fa-angle-right" />
                    </Button>
                    {importError && <div className="error-block">{importError}</div>}
                </div>
            </form>
        );
    }
}

export const ListImportForm1 = reduxForm(formConfig)(ConnectedListImportForm1);

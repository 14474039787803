import classnames from 'classnames';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { ListGroupItem } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { getSectionNumberingString } from '@og-pro/shared-config/helpers';

import { ActionLabel } from './ActionLabel';
import { AssignedUserFormDisplay } from './AssignedUserFormDisplay';
import { assignUserForm, assignUserFieldNames } from './constants';
import { LastUpdatedDisplay } from './LastUpdatedDisplay';
import { QuestionnaireReviewStatusIcon } from './QuestionnaireReviewStatusIcon';
import { lgColWidths, smColWidths } from '../constants';
import { AssignUserForm } from '../..';
import { updateQuestionnaireReview } from '../../../actions/checklists';
import { getInvitedUsersSelectOptions } from '../../../containers/selectors';
import { questionnaireResponseComplete } from '../../../Forms/validation';

const { GOV_ASSIGNED_ID, VENDOR_ASSIGNED_ID } = assignUserFieldNames;

export const ContractChecklistItem = ({
    checklistId,
    contract,
    readOnly,
    isGovernmentView,
    questionnaire,
    questionnaire: {
        id: questionnaireId,
        questionnaireReview: {
            govAssignedUser,
            govStatus,
            isComplete,
            isGovAction,
            lastUpdater,
            lastUpdatedAt,
            vendorAssignedUser,
            vendorStatus,
        },
        sectionNumber,
        subsectionNumber,
        title,
        updateReviewError,
        updatingReview,
    },
    showQuestionnaire,
    vendorUserOptions,
}) => {
    const dispatch = useDispatch();
    const govUserOptions = useSelector(getInvitedUsersSelectOptions);

    const assignUser = (data) =>
        dispatch(updateQuestionnaireReview(checklistId, questionnaireId, data, 'User assigned'));

    const styles = require('./index.scss');
    const number = getSectionNumberingString({ sectionNumber, subsectionNumber });
    const hasValidResponse = !questionnaireResponseComplete(questionnaire);

    const [lg1, lg2, lg3, lg4] = lgColWidths;
    const [sm1, sm2, sm3, sm4] = smColWidths;

    const { responseStatusText, responseStatusIcon, responseStatusClass } = (() => {
        let text = 'No response yet';
        let icon = 'circle-thin';
        let className = 'text-muted';
        if (hasValidResponse && !isComplete && !isGovAction) {
            text = 'Response drafted';
            icon = 'pause-circle';
            className = 'text-warning';
        } else if (hasValidResponse) {
            text = 'Response submitted';
            icon = 'check-circle';
            className = 'text-info';
        }

        return {
            responseStatusText: text,
            responseStatusIcon: icon,
            responseStatusClass: className,
        };
    })();

    return (
        <ListGroupItem onClick={() => showQuestionnaire(questionnaire)}>
            <div className={`row ${styles.container}`}>
                <div
                    className={`col-xs-12 col-sm-${sm1} col-lg-${lg1} ${styles.xsRow} text-center`}
                >
                    <div className="row">
                        <div className={`col-xs-6 ${styles.skinnyColRight}`}>
                            <div className="visible-xs-block">Agency</div>
                            <QuestionnaireReviewStatusIcon
                                className={styles.statusIcon}
                                isActionable={isGovAction}
                                isComplete={isComplete}
                                status={govStatus}
                            />
                            {isGovAction && !isComplete && !readOnly && (
                                <ActionLabel isActionable={isGovernmentView} />
                            )}
                        </div>
                        <div className={`col-xs-6 ${styles.skinnyColLeft}`}>
                            <div className="visible-xs-block">Vendor</div>
                            <QuestionnaireReviewStatusIcon
                                className={styles.statusIcon}
                                isActionable={!isGovAction}
                                isComplete={isComplete}
                                status={vendorStatus}
                            />
                            {!isGovAction && !isComplete && !readOnly && (
                                <ActionLabel isActionable={!isGovernmentView} />
                            )}
                        </div>
                    </div>
                </div>
                <div className={`col-xs-12 col-sm-${sm2} col-lg-${lg2} ${styles.xsRow}`}>
                    <div>
                        {number} {title}
                    </div>
                    <div className={classnames(responseStatusClass, styles.responseStatus)}>
                        <i className={`fa fa-${responseStatusIcon}`} /> {responseStatusText}
                    </div>
                    {updateReviewError && <div className="error-block">{updateReviewError}</div>}
                </div>
                <div
                    className={`col-xs-12 col-sm-${sm3} col-lg-${lg3} ${styles.lastUpdateCol} ${styles.xsRow}`}
                >
                    <LastUpdatedDisplay
                        checklistId={checklistId}
                        hasVendorAssignedUser={!!vendorAssignedUser}
                        lastUpdatedAt={lastUpdatedAt}
                        lastUpdater={lastUpdater}
                        questionnaireId={questionnaireId}
                        showReminderButton={
                            !isGovAction && !isComplete && !readOnly && isGovernmentView
                        }
                    />
                </div>
                <div
                    className={`col-xs-12 col-sm-${sm4} col-lg-${lg4}`}
                    onClick={(e) => e.stopPropagation()}
                >
                    <div>
                        <AssignUserForm
                            assignUser={assignUser}
                            assignedUser={govAssignedUser}
                            assignedUserDisplay={
                                <AssignedUserFormDisplay
                                    organizationName={get(govAssignedUser, 'displayName')}
                                    userName={get(contract, 'government.organization.name')}
                                />
                            }
                            assigningUser={updatingReview}
                            cannotEdit={!isGovernmentView || readOnly}
                            fieldName={GOV_ASSIGNED_ID}
                            form={`${assignUserForm}_${questionnaireId}_gov`}
                            initialValues={{ [GOV_ASSIGNED_ID]: get(govAssignedUser, 'id') }}
                            showInviteUserHelp
                            unassignedClassName={styles.unassignedContainer}
                            unassignedText="Agency Unassigned"
                            userProfileClassName={styles.profilePicture}
                            users={govUserOptions}
                        />
                    </div>
                    <div className={styles.vendorAssignedUser}>
                        <AssignUserForm
                            assignUser={assignUser}
                            assignedUser={vendorAssignedUser}
                            assignedUserDisplay={
                                <AssignedUserFormDisplay
                                    organizationName={get(vendorAssignedUser, 'displayName')}
                                    userName={get(contract, 'contractParty.companyName')}
                                />
                            }
                            assigningUser={updatingReview}
                            cannotEdit={readOnly}
                            fieldName={VENDOR_ASSIGNED_ID}
                            form={`${assignUserForm}_${questionnaireId}_vendor`}
                            initialValues={{ [VENDOR_ASSIGNED_ID]: get(vendorAssignedUser, 'id') }}
                            unassignedClassName={styles.unassignedContainer}
                            unassignedText="Vendor Unassigned"
                            userProfileClassName={styles.profilePicture}
                            users={vendorUserOptions}
                        />
                    </div>
                </div>
            </div>
        </ListGroupItem>
    );
};

ContractChecklistItem.propTypes = {
    checklistId: PropTypes.number.isRequired,
    contract: PropTypes.shape({
        contractParty: PropTypes.shape({
            companyName: PropTypes.string,
        }),
        government: PropTypes.shape({
            organization: PropTypes.shape({
                name: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
    isGovernmentView: PropTypes.bool,
    questionnaire: PropTypes.shape({
        id: PropTypes.number.isRequired,
        questionnaireResponse: PropTypes.object,
        questionnaireReview: PropTypes.shape({
            govAssignedUser: PropTypes.shape({
                displayName: PropTypes.string.isRequired,
            }),
            govStatus: PropTypes.string.isRequired,
            isComplete: PropTypes.bool.isRequired,
            isGovAction: PropTypes.bool.isRequired,
            lastUpdater: PropTypes.object,
            lastUpdatedAt: PropTypes.string,
            vendorAssignedUser: PropTypes.shape({
                displayName: PropTypes.string.isRequired,
            }),
            vendorStatus: PropTypes.string.isRequired,
        }),
        sectionNumber: PropTypes.number.isRequired,
        subsectionNumber: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        updateReviewError: PropTypes.string,
        updatingReview: PropTypes.bool,
    }).isRequired,
    readOnly: PropTypes.bool,
    showQuestionnaire: PropTypes.func.isRequired,
    vendorUserOptions: PropTypes.array.isRequired,
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { ReviewerReminderEmailForm } from '../ReviewerReminderEmailForm/ReviewerReminderEmailForm';

export class ReviewerReminderEmailModalBody extends PureComponent {
    static propTypes = {
        approvalId: PropTypes.number.isRequired,
        hideModal: PropTypes.func.isRequired,
        reminderHandler: PropTypes.func.isRequired,
        reReview: PropTypes.bool.isRequired,
        reviewStepId: PropTypes.number.isRequired,
    };

    submitHandler = (formData) => {
        const { approvalId, hideModal, reminderHandler, reReview, reviewStepId } = this.props;

        const { personalNote } = formData;

        reminderHandler(approvalId, reReview, personalNote, reviewStepId);

        hideModal();
    };

    render() {
        const { hideModal } = this.props;

        return <ReviewerReminderEmailForm hideModal={hideModal} onSubmit={this.submitHandler} />;
    }
}

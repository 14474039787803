import React from 'react';
import ReactToPrint from 'react-to-print';

export const Print = (props) => {
    const applyMarginsToPrintView = () => {
        const margin = '0.5in';
        return `@page { margin: ${margin} !important; }`;
    };

    return (
        <>
            <ReactToPrint {...props} />
            {/* This is a workaround to set default margins to the print view.
            Reference: https://github.com/gregnb/react-to-print/issues/29#issuecomment-872004022 */}
            <style>{applyMarginsToPrintView()}</style>
        </>
    );
};

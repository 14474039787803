import React, { useState } from 'react';
import { tokens } from '@opengov/capital-style';
import { ContentCopy as ContentCopyIcon } from '@mui/icons-material';
import PropTypes from 'prop-types';

import { CopyToClipboardIcon } from '../CopyToClipboardIcon';

const { colors } = tokens;

const Contacts = ({ colorGrey = false, contacts, showCopyEmail }) => {
    const styles = require('./VendorProfile.scss');

    const [showTheRestOfTheUsers, setShowTheRestOfTheUsers] = useState(false);

    const contactsWithFilter = contacts.filter(
        (contact) =>
            contact?.fullName.trim() || contact?.firstName.trim() || contact?.lastName.trim()
    );

    if (!contactsWithFilter || contactsWithFilter?.length === 0) {
        return 'None specified';
    }
    const moreThanThree = contactsWithFilter.length > 3;
    let usersToDisplay = contactsWithFilter;
    if (moreThanThree) {
        usersToDisplay = contactsWithFilter.slice(0, 3);
    }
    if (showTheRestOfTheUsers) usersToDisplay = contactsWithFilter;
    const showAll = moreThanThree && !showTheRestOfTheUsers;
    return (
        <div
            className={styles.contact}
            style={colorGrey ? { color: colors.colorGray700 } : undefined}
        >
            {usersToDisplay.map((contact, index) => {
                let name = contact?.fullName
                    ? `${contact.fullName}`
                    : `${contact?.firstName ?? ''} ${contact?.lastName ?? ''}`;
                name = name.trim();
                return (
                    name && (
                        <div key={contact.id}>
                            <p className={styles.user}>
                                {name}
                                {contact?.title ? ` - ${contact.title}` : ''}
                            </p>
                            {contact?.email && (
                                <p className={styles.email}>
                                    {contact.email}
                                    {showCopyEmail && (
                                        <CopyToClipboardIcon
                                            icon={ContentCopyIcon}
                                            style={{
                                                color: colors.colorGray800,
                                                width: 16,
                                                height: 16,
                                            }}
                                            value={contact.email}
                                        />
                                    )}
                                </p>
                            )}
                            <p className={styles.phone}>
                                {(contact?.phoneComplete || contact?.phone) &&
                                    `${contact?.phoneComplete ?? contact?.phone?.replace(/\)(?=\d)/g, ') ')}`}
                            </p>
                            {(index !== usersToDisplay.length - 1 ||
                                (index === usersToDisplay.length - 1 && showAll)) && (
                                <hr className={styles.hr} />
                            )}
                        </div>
                    )
                );
            })}
            {showAll && (
                <p className={styles.italic}>
                    and {contactsWithFilter.length - 3} other contact
                    {contactsWithFilter.length - 3 > 1 ? 's' : ''}...
                    <span className={styles.showAll} onClick={() => setShowTheRestOfTheUsers(true)}>
                        {' '}
                        show all
                    </span>
                </p>
            )}
        </div>
    );
};

export default Contacts;

Contacts.propTypes = {
    colorGrey: PropTypes.bool,
    contacts: PropTypes.arrayOf(
        PropTypes.shape({
            email: PropTypes.string,
            firstName: PropTypes.string,
            fullName: PropTypes.string,
            id: PropTypes.number,
            lastName: PropTypes.string,
            phone: PropTypes.string,
            phoneComplete: PropTypes.string,
            title: PropTypes.string,
        })
    ),
    showCopyEmail: PropTypes.bool,
};

import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';

import { StepSectionLayout } from '.';
import { reviewSequenceStepFieldNames } from '../../../constants';
import { qaTagPageName } from '../../../../constants';
import { getRejectionPolicyOptions } from '../../../../selectors';
import { SearchSelect, Toggle } from '../../../../../../../../components';

const { REJECTION_POLICIES, REQUIRE_REJECTION_NOTE } = reviewSequenceStepFieldNames;

export const RejectionPolicySection = ({ disabled, fieldName, index, showFormValidation }) => {
    const allRejectionPolicyOptions = useSelector(getRejectionPolicyOptions);
    const rejectionPolicyOptions = allRejectionPolicyOptions.filter((policyOption) => {
        return isNil(policyOption.index) || policyOption.index < index;
    });

    return (
        <StepSectionLayout title="Rejection Policy">
            <Field
                backspaceRemovesValue={false}
                closeMenuOnSelect={false}
                component={SearchSelect}
                disabled={disabled}
                help="Specify the rejection options to make available to reviewers"
                isMulti
                isMultiSimpleValue
                isSearchable={false}
                name={`${fieldName}.${REJECTION_POLICIES}`}
                options={rejectionPolicyOptions}
                placeholder="Select rejection options"
                showValidation={showFormValidation}
            />
            <Field
                component={Toggle}
                disabled={disabled}
                label="Require Rejection Justification"
                labelInline
                name={`${fieldName}.${REQUIRE_REJECTION_NOTE}`}
                qaTag={`${qaTagPageName}-${REQUIRE_REJECTION_NOTE}`}
                tooltip="Require reviewers to provide justification for rejecting the request (optional otherwise)"
            />
        </StepSectionLayout>
    );
};

RejectionPolicySection.propTypes = {
    disabled: PropTypes.bool,
    fieldName: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    showFormValidation: PropTypes.bool,
};

import { createSelector } from 'reselect';

import { decryptCommentThreadKey, getThreadProjectField } from '../../../helpers';
import {
    getCommentThreadsJS,
    getCriteriaMap,
    getProjectJS,
    getProjectSubsectionsMap,
} from '../selectors';
import { getUserJS } from '../../selectors';

export const getThreadKey = (state) => state.govComments.get('selectedThreadKey');

export const getSelectedCommentThreadsJS = createSelector(
    [getCommentThreadsJS, getThreadKey],
    (commentThreads, selectedThreadKey) => {
        return commentThreads[selectedThreadKey] || [];
    }
);

export const getResolvedThreadCount = createSelector([getSelectedCommentThreadsJS], (threads) => {
    return threads.filter((thread) => thread.resolved).length;
});

export const getNewThreadFollowers = createSelector([getProjectJS, getUserJS], (project, user) => {
    if (project && user) {
        // If the user is the author only return a single initial follower
        const { user: projectAuthor } = project;
        if (projectAuthor.id === user.id) {
            return [user];
        }
        // Otherwise initial followers will be the user and author
        return [user, projectAuthor];
    }
    return [];
});

export const getThreadValues = createSelector([getThreadKey], (field) => {
    if (field) {
        return decryptCommentThreadKey(field);
    }
});

// Finds the project field item based on the selected comment thread data
export const getProjectFieldsFromThread = createSelector(
    [getProjectJS, getThreadKey, getProjectSubsectionsMap, getCriteriaMap],
    (project, field, projectSubsectionsMap, criteriaMap) => {
        if (!project || !field) return undefined;

        return getThreadProjectField(project, projectSubsectionsMap, criteriaMap, field);
    }
);

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const Card = ({ children, className, cardClassName, ...props }) => {
    const styles = require('./index.scss');

    return (
        <div className={classNames(styles.cardContentContainer, className)} {...props}>
            <div className={classNames(styles.cardContent, cardClassName)}>{children}</div>
        </div>
    );
};

Card.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    cardClassName: PropTypes.string,
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactToggle from 'react-toggle';

import { HelpToolTip } from '../../../../HelpToolTip/HelpToolTip';
import { TIMELINE_CONFIG, TIMELINES } from '../../../../../containers/GovApp/constants';
import {
    getDynamicTimelines,
    getProjectTimelines,
} from '../../../../../containers/GovApp/selectors';

const mapStateToProps = (state, props) => {
    return {
        dynamicTimelines: getDynamicTimelines(state, props),
        projectTimelines: getProjectTimelines(state, props),
    };
};

// @connect
class ConnectedTimelineDateToggles extends Component {
    static propTypes = {
        array: PropTypes.shape({
            remove: PropTypes.func.isRequired,
        }).isRequired,
        change: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        projectTimelines: PropTypes.arrayOf(
            PropTypes.shape({
                date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
                isIncluded: PropTypes.bool,
                required: PropTypes.bool,
                title: PropTypes.string.isRequired,
                uuid: PropTypes.string.isRequired,
            })
        ).isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    toggleProjectField = (timeline) => (e) => {
        const { isIncludedField, isRequiredField, locationField, projectField } = timeline;
        const { checked } = e.target;
        const { change } = this.props;

        if (checked) {
            return change(`${TIMELINE_CONFIG}.${isIncludedField}`, true);
        }

        if (locationField) change(`${TIMELINE_CONFIG}.${locationField}`, null);
        if (isRequiredField) change(`${TIMELINE_CONFIG}.${isRequiredField}`, false);
        if (projectField) change(`${projectField}`, null);
        return change(`${TIMELINE_CONFIG}.${isIncludedField}`, false);
    };

    removeDynamicTimeline = (index) => {
        this.props.array.remove(TIMELINES, index);
    };

    render() {
        const { disabled: disabledFormState, projectTimelines } = this.props;

        const ProjectFields = projectTimelines.map((timelineData) => {
            const { disabled, disabledText, isIncluded, required, title, uuid } = timelineData;

            return (
                <div className={`row ${this.styles.timelineField}`} key={uuid}>
                    <div className="col-xs-12">
                        <div className={this.styles.controlContainer}>
                            <ReactToggle
                                checked={required || isIncluded}
                                disabled={required || disabled || disabledFormState}
                                onChange={this.toggleProjectField(timelineData)}
                            />
                        </div>
                        <div className={this.styles.fieldName}>
                            {title}
                            {required && (
                                <HelpToolTip
                                    placement="right"
                                    text="Required system dates cannot be removed from timeline"
                                />
                            )}
                            {disabled && <HelpToolTip placement="right" text={disabledText} />}
                        </div>
                    </div>
                </div>
            );
        });

        return (
            <div>
                <h4 className={this.styles.timelineTypeTitle}>
                    Standard Timeline Dates Configuration
                </h4>
                {ProjectFields}
            </div>
        );
    }
}

export const TimelineDateToggles = connect(mapStateToProps)(ConnectedTimelineDateToggles);

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import React, { Component } from 'react';
import moment from 'moment';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { Box } from '@og-pro/ui';

import connectData from '../../ConnectData';
import { loadDashboard, loadEvents } from '../../../actions/globalEditorDashboard';
import {
    getEventsJS,
    getProjectsJS,
    getProjectsInAwardPendingChartDataJS,
    getProjectsInEvaluationChartDataJS,
    getUnsubmittedScorecardProjectsJS,
} from './selectors';
import {
    getPendingProjects,
    getProjectsInReviewChartData,
    getProjectsStagesBarChartData,
    getUnreleasedAddendaProjects,
    getUnresolvedQuestionsProjects,
} from './helpers';
import {
    DashboardDepartmentSelectForm,
    LoadingError,
    LoadingSpinner,
    Main,
    PageTitle,
} from '../../../components';
import { getUserJS, isInitialClientLoaded } from '../../selectors';
import { hasEvaluationSubscription, hasSourcingSubscription } from '../selectors';
import { NoProjects } from '../../../components/GovApp';
import NeedsAttentionProjectsList from './components/NeedsAttentionProjectsList';
import UpcomingEventsList from './components/UpcomingEventsList';
import ProjectStagesBarChart from './components/ProjectsStagesBarChart';
import ProjectsInEvaluation from './components/ProjectsDonutCharts/ProjectsInEvaluation';
import ProjectsInAwardPending from './components/ProjectsDonutCharts/ProjectsInAwardPending';
import ProjectsInReview from './components/ProjectsDonutCharts/ProjectsInReview';

const mapStateToProps = (state, props) => {
    return {
        events: getEventsJS(state),
        evaluationSubscription: hasEvaluationSubscription(state),
        hasProjects: getProjectsJS(state).length > 0,
        sourcingSubscription: hasSourcingSubscription(state),
        isClientLoaded: isInitialClientLoaded(state),
        loadError: state.globalEditorDashboard.get('loadDashboardError'),
        loadEventsError: state.globalEditorDashboard.get('loadEventsError'),
        loading: state.globalEditorDashboard.get('loadingDashboard'),
        loadingEvents: state.globalEditorDashboard.get('loadingEvents'),
        projects: getProjectsJS(state),
        projectsInAwardPendingChartData: getProjectsInAwardPendingChartDataJS(state, props),
        projectsInEvaluationChartData: getProjectsInEvaluationChartDataJS(state, props),
        unsubmittedScorecardsProjects: getUnsubmittedScorecardProjectsJS(state),
        user: getUserJS(state),
    };
};

const mapDispatchToProps = {
    loadDashboard,
    loadEvents,
};

function fetchData(getState, dispatch) {
    const endDate = moment().add(6, 'days').toISOString();
    const startDate = moment().toISOString();

    // Need to use `dispatch` because dispatch won't be bound to the action creator through react-redux `connect`.
    const authState = getState().auth;
    const isDepartmentEditor = authState.getIn(['user', 'userPermissions', 'isDepartmentEditor']);
    const isGlobalEditor = authState.getIn(['user', 'userPermissions', 'isGlobalEditor']);
    const filterByDepartment = isDepartmentEditor && !isGlobalEditor;
    const departmentId = authState.getIn(['user', 'department_id']);

    return Promise.all([
        dispatch(
            loadEvents({
                endDate,
                startDate,
                ...(filterByDepartment ? { departmentIds: [departmentId] } : {}),
            })
        ),
        dispatch(loadDashboard()),
    ]);
}

class ConnectedGlobalEditorDashboard extends Component {
    static propTypes = {
        events: PropTypes.array.isRequired,
        evaluationSubscription: PropTypes.bool.isRequired,
        hasProjects: PropTypes.bool,
        sourcingSubscription: PropTypes.bool.isRequired,
        isClientLoaded: PropTypes.bool,
        loadDashboard: PropTypes.func.isRequired,
        loadError: PropTypes.string,
        loadEvents: PropTypes.func.isRequired,
        loadEventsError: PropTypes.string,
        loading: PropTypes.bool,
        loadingEvents: PropTypes.bool,
        projects: PropTypes.array.isRequired,
        projectsInAwardPendingChartData: PropTypes.array.isRequired,
        projectsInEvaluationChartData: PropTypes.array.isRequired,
        router: PropTypes.object.isRequired,
        unsubmittedScorecardsProjects: PropTypes.array.isRequired,
        user: PropTypes.shape({
            department: PropTypes.shape({
                name: PropTypes.string.isRequired,
            }).isRequired,
            userPermissions: PropTypes.shape({
                isGlobalEditor: PropTypes.bool.isRequired,
            }).isRequired,
        }).isRequired,
    };

    static defaultProps = {
        isClientLoaded: false,
        loadError: undefined,
        loadEventsError: undefined,
    };

    filterDepartments = (data) => {
        const { departmentIds } = data;

        const endDate = moment().add(6, 'days').toISOString();
        const startDate = moment().toISOString();

        this.props.loadEvents({
            endDate,
            startDate,
            departmentIds,
        });
        this.props.loadDashboard({ departmentIds });
    };

    renderBody() {
        const {
            events,
            evaluationSubscription,
            hasProjects,
            sourcingSubscription,
            loadError,
            loadEventsError,
            loading,
            loadingEvents,
            projects,
            projectsInAwardPendingChartData,
            projectsInEvaluationChartData,
            router,
            unsubmittedScorecardsProjects,
            user: {
                department,
                userPermissions: { isGlobalEditor },
            },
        } = this.props;

        if (loading || loadingEvents) return <LoadingSpinner />;
        if (loadError || loadEventsError) {
            return <LoadingError error={loadError || loadEventsError} />;
        }
        if (!loading && !loadingEvents && !hasProjects) return <NoProjects />;

        return (
            <>
                {!isGlobalEditor && (
                    <Box component="h4" mb={2}>
                        Viewing Projects by Department: {department.name}
                    </Box>
                )}
                <ProjectStagesBarChart
                    projectsStagesBarChartData={getProjectsStagesBarChartData(projects, router)}
                />
                <div className="row">
                    <NeedsAttentionProjectsList
                        evaluationSubscription={evaluationSubscription}
                        pendingProjects={sourcingSubscription ? getPendingProjects(projects) : []}
                        sourcingSubscription={sourcingSubscription}
                        unreleasedAddendaProjects={
                            sourcingSubscription ? getUnreleasedAddendaProjects(projects) : []
                        }
                        unresolvedQuestionsProjects={
                            sourcingSubscription ? getUnresolvedQuestionsProjects(projects) : []
                        }
                        unsubmittedScorecardsProjects={
                            evaluationSubscription ? unsubmittedScorecardsProjects : []
                        }
                    />
                    <UpcomingEventsList events={events} />
                </div>
                <div className="row">
                    <ProjectsInReview
                        projectsInReviewChartData={getProjectsInReviewChartData(projects, router)}
                    />
                    <ProjectsInEvaluation
                        evaluationSubscription={evaluationSubscription}
                        projectsInEvaluationChartData={projectsInEvaluationChartData}
                    />
                    <ProjectsInAwardPending
                        evaluationSubscription={evaluationSubscription}
                        projectsInAwardPendingChartData={projectsInAwardPendingChartData}
                    />
                </div>
            </>
        );
    }

    render() {
        const {
            isClientLoaded,
            user: {
                userPermissions: { isGlobalEditor },
            },
        } = this.props;

        if (!isClientLoaded) return null;

        return (
            <Main>
                <PageTitle title="Projects Dashboard" />
                <h1 className="visually-hidden">Projects Dashboard</h1>
                {isGlobalEditor && (
                    <div className="row">
                        <div className="col-md-offset-6 col-md-6">
                            <DashboardDepartmentSelectForm
                                form="projectDashboardDepartmentSelectForm"
                                onSubmit={this.filterDepartments}
                            />
                        </div>
                    </div>
                )}
                {this.renderBody()}
            </Main>
        );
    }
}

export const GlobalEditorDashboard = compose(
    connectData(fetchData),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedGlobalEditorDashboard);

import React from 'react';

import { SDv2EditorLayout } from '../../../../../../../components/SDv2/Layout';
import { NavScrollContainer } from '../../../../../../../components/SDv2';

export const TemplateEditV2Checklist = () => {
    return (
        <SDv2EditorLayout
            content={<div>Content</div>}
            footer={<div>Footer</div>}
            header={<div>Header</div>}
            navigation={
                <NavScrollContainer>
                    <div>Navigation</div>
                </NavScrollContainer>
            }
        />
    );
};

import { fromJS } from 'immutable';

import * as vendorSearchActions from '../actions/vendorSearch';

const initialState = fromJS({
    vendors: [],
    loading: false,
    downloading: false,
});

export default function vendorSearchReducer(state = initialState, action = {}) {
    switch (action.type) {
        case vendorSearchActions.SEARCH_VENDORS:
            return state.merge(
                fromJS({
                    loading: true,
                })
            );
        case vendorSearchActions.SEARCH_VENDORS_SUCCESS:
            return state.merge(
                fromJS({
                    loading: false,
                    vendors: fromJS(action.result),
                })
            );
        case vendorSearchActions.SEARCH_VENDORS_FAIL:
            return state.merge(
                fromJS({
                    loading: false,
                })
            );
        case vendorSearchActions.DOWNLOAD_VENDOR_SEARCH:
            return state.merge(
                fromJS({
                    downloading: true,
                })
            );
        case vendorSearchActions.DOWNLOAD_VENDOR_SEARCH_SUCCESS:
            return state.merge(
                fromJS({
                    downloading: false,
                })
            );
        case vendorSearchActions.DOWNLOAD_VENDOR_SEARCH_FAIL:
            return state.merge(
                fromJS({
                    downloading: false,
                })
            );
        default:
            return state;
    }
}

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { ActionToolbarNavbarItem } from '../../../../components';
import { PUBLIC_DISPLAY_OPTIONS } from '../../../../constants/menuActions';
import { trackEvent } from '../../../../helpers';

export class PublicDisplayButton extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool.isRequired,
        isEditor: PropTypes.bool.isRequired,
        menuActionHandler: PropTypes.func.isRequired,
        showWarningBadge: PropTypes.bool,
    };

    get styles() {
        return require('./shared.scss');
    }

    handleClick = () => {
        const { menuActionHandler } = this.props;

        trackEvent('Public Display Options Button');
        menuActionHandler(PUBLIC_DISPLAY_OPTIONS);
    };

    render() {
        const { disabled, isEditor, showWarningBadge } = this.props;

        return (
            <ActionToolbarNavbarItem
                className="action-toolbar-public-display-btn"
                disabled={disabled}
                editOnly
                isEditor={isEditor}
                onClick={this.handleClick}
                qaTag="projectActionMenu-publicDisplay"
            >
                <i className="fa fa-sliders" /> Public Display
                {showWarningBadge && (
                    <i
                        className={classnames(
                            'fa fa-exclamation-circle',
                            this.styles.warningCircle
                        )}
                    />
                )}
            </ActionToolbarNavbarItem>
        );
    }
}

import Highcharts from 'highcharts';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { projectsInStagesColors } from '../../constants';

export default class ProjectsStagesBarChart extends Component {
    static propTypes = {
        projectsStagesBarChartData: PropTypes.array.isRequired,
    };

    componentDidMount() {
        this.renderChart();
    }

    renderChart() {
        const { projectsStagesBarChartData } = this.props;

        // eslint-disable-next-line new-cap
        return new Highcharts.chart({
            chart: {
                borderColor: '#dce0e0',
                borderWidth: 1,
                height: 200,
                renderTo: 'projects-stages-bar-chart',
                style: {
                    fontWeight: 500,
                    fontSize: '18px',
                    fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
                },
                type: 'bar',
            },
            colors: projectsInStagesColors,
            credits: {
                enabled: false,
            },
            legend: {
                labelFormatter() {
                    return `${this.name}: ${this.userOptions.label}`;
                },
                reversed: true,
            },
            plotOptions: {
                bar: {
                    borderWidth: 3,
                },
                series: {
                    stacking: 'normal',
                },
            },
            responsive: {
                rules: [
                    {
                        condition: {
                            maxWidth: 370,
                        },
                        chartOptions: {
                            chart: {
                                height: 400,
                            },
                        },
                    },
                ],
            },
            series: projectsStagesBarChartData,
            title: {
                text: 'Projects in Stages',
            },
            tooltip: {
                formatter() {
                    return `<strong>${this.series.name}</strong>: ${this.series.userOptions.label}<br />${this.series.userOptions.description}`;
                },
                style: {
                    width: 300,
                },
            },
            xAxis: {
                categories: [''],
            },
            yAxis: {
                allowDecimals: false,
                endOnTick: false,
                labels: {
                    enabled: true,
                },
                min: 0,
                title: {
                    text: 'Number of Projects',
                },
            },
        });
    }

    render() {
        return (
            <div className="row">
                <div className="col-xs-12">
                    <div id="projects-stages-bar-chart" />
                </div>
            </div>
        );
    }
}

export const [WINDOWS, MAC_OS, UNIX, LINUX] = ['Windows', 'Mac', 'X11', 'Linux'];

export const detectOS = () => {
    let os;
    const userAgent = window?.navigator?.userAgent;
    if (userAgent.indexOf(WINDOWS) !== -1) os = WINDOWS;
    if (userAgent.indexOf(MAC_OS) !== -1) os = MAC_OS;
    if (userAgent.indexOf(UNIX) !== -1) os = UNIX;
    if (userAgent.indexOf(LINUX) !== -1) os = LINUX;
    return os;
};

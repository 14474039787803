import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';

import { withFlags, FLAGS } from '@og-pro/launch-darkly/client';

import { form } from './constants';
import { validate } from './validate';
import { resetPassword, resetPasswordOld } from '../../../actions/auth';
import { Button, InputText, PageTitle } from '../../../components';

const mapStateToProps = (state) => {
    return {
        resetting: state.auth.get('resettingPassword'),
    };
};

const mapDispatchToProps = {
    resetPassword,
    resetPasswordOld,
};

const formConfig = {
    form,
    validate,
};

// @connect
// @reduxForm
class ConnectedPasswordResetForm extends Component {
    static propTypes = {
        getFlag: PropTypes.func.isRequired,
        invalid: PropTypes.bool,
        resetting: PropTypes.bool,
        resetPassword: PropTypes.func.isRequired,
        resetPasswordOld: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
    };

    handleReset = (data) => {
        if (this.props.getFlag(FLAGS.PLATFORM_PASSWORD_RESET)) {
            this.props.resetPassword(data.email);
        } else {
            this.props.resetPasswordOld(data.email);
        }
    };

    render() {
        const { handleSubmit, invalid, resetting } = this.props;

        const styles = require('./PasswordResetForm.scss');

        return (
            <form onSubmit={handleSubmit(this.handleReset)}>
                <PageTitle title="Password Reset" />
                <div className={styles.emailInput}>
                    <Field
                        component={InputText}
                        disabled={resetting}
                        label="Email Address"
                        name="email"
                        qaTag="resetPassword-email"
                        type="text"
                    />
                </div>
                <Button
                    block
                    bsStyle="primary"
                    disabled={invalid || resetting}
                    qaTag="resetPassword-submit"
                    type="submit"
                >
                    {resetting ? 'Sending Email...' : 'Send Password Reset Email'}
                </Button>
            </form>
        );
    }
}

export const PasswordResetForm = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig),
    withFlags()
)(ConnectedPasswordResetForm);

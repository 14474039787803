import React from 'react';

import { ReduxFormCheckboxGroup } from '@og-pro/ui';
import { useSelector } from 'react-redux';
import { Field, getFormValues } from 'redux-form';

import {
    customFieldFields,
    ENUM_OPTIONS_FIELD,
    instructionsModes,
} from '@og-pro/shared-config/customFormService/customField';

import { formConfig } from '../Form/form';

const { NAME, INSTRUCTIONS_MODE, INSTRUCTIONS_TEXT } = customFieldFields;
const { TOOLTIP, DESCRIPTION } = instructionsModes;

export const CheckboxPreview = () => {
    const formValues = useSelector((state) => getFormValues(formConfig.form)(state)) || {};
    const instructionsText = formValues[INSTRUCTIONS_TEXT];

    const checkBoxArray = formValues[ENUM_OPTIONS_FIELD];
    const checkBoxOptions = checkBoxArray.map((option) => {
        if (option === '') {
            return {
                label: 'Checkbox text goes here.',
                value: 'Checkbox text goes here.',
            };
        }

        return {
            label: option,
            value: option,
        };
    });

    return (
        <Field
            component={ReduxFormCheckboxGroup}
            description={
                formValues[INSTRUCTIONS_MODE] === DESCRIPTION ? instructionsText : undefined
            }
            disabled
            label={formValues[NAME] || 'Your title will go here'}
            name="checkbox_PREVIEW"
            options={checkBoxOptions}
            sx={{
                width: '100%',
            }}
            tooltip={formValues[INSTRUCTIONS_MODE] === TOOLTIP ? instructionsText : undefined}
        />
    );
};

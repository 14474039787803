import React from 'react';
import { Box } from '@og-pro/ui';

export const WebsiteCellRenderer = (params) => {
    if (!params?.value) {
        return null;
    }

    const renderLink = () => (
        <a href={params.value} rel="noreferrer" target="_blank">
            {params.value}
        </a>
    );

    return params.data.expanded ? (
        <Box pt={0.5}>{renderLink()}</Box>
    ) : (
        <Box alignItems="center" display="flex" height="100%">
            {renderLink()}
        </Box>
    );
};

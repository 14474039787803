import { get, set } from 'lodash';

import { ALL_SECTIONS, ADD_SECTION_TEMP_FIELDNAME, formSectionNames } from './constants';
import { validate as validateTemplate } from './TemplateForm/validate';
import {
    validate as validateProjectSections,
    validateProjectSection,
} from './TemplateProjectSectionsForm/validate';
import { validate as validateProjectContent } from './TemplateProjectContentForm/validate';
import { validate as validateQuestionLogic } from './TemplateQuestionLogicForm/validate';
import { validate as validateUpfrontQuestionnaire } from './TemplateUpfrontQuestionsForm/validate';
import { sectionsValidate } from '../../../../Forms/validation';
import { hasFormErrors } from '../../../../helpers';

const { AUTOMATION_LOGIC, CONTENT, SECTIONS, TEMPLATE, UPFRONT_QUESTIONNAIRE } = formSectionNames;

export const validateAddSectionData = (values) => {
    if (!get(values, ADD_SECTION_TEMP_FIELDNAME)) {
        return {};
    }

    const useManualNumbering = values.useManualNumbering;
    const { projectSectionErrors: errors } = validateProjectSection(
        get(values, ADD_SECTION_TEMP_FIELDNAME),
        useManualNumbering
    );

    return set({}, ADD_SECTION_TEMP_FIELDNAME, errors);
};

export const validate = (values) => {
    const templateErrors = validateTemplate(values);
    const upfrontQuestionnaireErrors = validateUpfrontQuestionnaire(values);
    const projectSectionErrors = validateProjectSections(values);
    const projectContentErrors = validateProjectContent(values);
    const questionLogicErrors = validateQuestionLogic(values);

    const sectionErrors = sectionsValidate(
        [TEMPLATE, templateErrors],
        [UPFRONT_QUESTIONNAIRE, upfrontQuestionnaireErrors],
        [SECTIONS, projectSectionErrors],
        [CONTENT, projectContentErrors],
        [AUTOMATION_LOGIC, questionLogicErrors]
    );

    return {
        [ALL_SECTIONS]: hasFormErrors(sectionErrors),
        ...templateErrors,
        ...upfrontQuestionnaireErrors,
        ...projectSectionErrors,
        ...projectContentErrors,
        ...questionLogicErrors,
        ...sectionErrors,
    };
};

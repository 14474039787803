import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Panel } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { getGovernmentsJS } from './selectors';
import connectData from '../../ConnectData';
import { loadGovs } from '../../../actions/publicProject';
import {
    GovList as GovListComponent,
    LoadingError,
    LoadingSpinner,
    Main,
    PageTitle,
    SectionTitle,
} from '../../../components';

function fetchData(getState, dispatch) {
    return dispatch(loadGovs());
}

const mapStateToProps = (state) => {
    return {
        governments: getGovernmentsJS(state),
        loadError: state.publicProject.get('loadGovListError'),
        loading: state.publicProject.get('loadingGovList'),
    };
};

// @connectData
// @connect
class ConnectedGovList extends Component {
    static propTypes = {
        governments: PropTypes.array.isRequired,
        loadError: PropTypes.string,
        loading: PropTypes.bool,
    };

    static defaultProps = {
        loadError: undefined,
        loading: false,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const { governments, loadError, loading } = this.props;

        if (loading) {
            return <LoadingSpinner />;
        }

        if (loadError) {
            return <LoadingError error={loadError} />;
        }

        return (
            <Main>
                <PageTitle title="OpenGov: List of Procurement Portals" />
                <div className="row">
                    <div className="col-sm-offset-1 col-sm-10">
                        <Panel className={this.styles.container}>
                            <Panel.Body>
                                <SectionTitle
                                    info="Select an organization to view opportunities"
                                    title="Search Projects by Organization"
                                />
                                <div className="row">
                                    <div className="col-md-offset-1 col-md-10">
                                        <GovListComponent governments={governments} />
                                    </div>
                                </div>
                            </Panel.Body>
                        </Panel>
                    </div>
                </div>
            </Main>
        );
    }
}

export const GovList = compose(connectData(fetchData), connect(mapStateToProps))(ConnectedGovList);

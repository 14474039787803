import React, { useContext, useEffect } from 'react';
import { reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { NoSsr } from '@mui/material';

import { formConfig } from './form';
import { getDeserializedRequisition } from './selectors';
import { setSectionCompleted, setSectionIncomplete } from '../../../../actions/requisitionsCreate';
import {
    getGovernmentReqSetting,
    getGovernmentSalesTax,
    getIsRequisitionUsingFMS,
    getRequisitionCurrentStep,
} from '../../../../selectors/govApp';
import { CustomFormSnapshotContext, CustomFormSnapshotProvider } from './CustomFormSnapshotContext';

// Pass through props into WrappedComponent, but add CustomFormSnapshotProvider around it
// This is necessary because redux-form needs to be configured with some custom form snapshot information
const withCustomFormSnapshotWrapper = (WrappedComponent) => {
    return (props) => {
        const requisition = useSelector(getDeserializedRequisition);

        const customFormSnapshotVersion = requisition.customFormSnapshotVersion;
        const customFormId = requisition.requestType.customFormId;

        return (
            <NoSsr>
                <CustomFormSnapshotProvider
                    customFormId={customFormId}
                    version={customFormSnapshotVersion}
                >
                    <WrappedComponent {...props} />
                </CustomFormSnapshotProvider>
            </NoSsr>
        );
    };
};

// Fetches data from hooks and passes to the reduxForm wrapped component.
// This is necessary for props that the `reduxForm` HOC needs for its form config and validation
const withCustomPropsToReduxForm = (ReduxFormWrappedComponent) => {
    return (props) => {
        // Pass `hasFMS` as a prop to reduxForm HOC so that redux-form `validate` can use the prop.
        // Prop will also be available to the `ReduxFormWrappedComponent`
        const hasFMS = useSelector(getIsRequisitionUsingFMS);

        // `initialValues` is dynamically set by the current requisition value, which is why it is
        // loaded here instead of in `formConfig`
        const requisition = useSelector(getDeserializedRequisition);

        const reqSetting = useSelector(getGovernmentReqSetting);

        const currentStep = useSelector(getRequisitionCurrentStep);

        const salesTaxRate = useSelector(getGovernmentSalesTax);

        const { customFields } = useContext(CustomFormSnapshotContext);

        const reduxFormProps = {
            hasFMS,
            initialValues: requisition,
            reqSetting,
            requireVendor: currentStep?.requireVendor,
            salesTaxRate,
            customFields,
        };

        return <ReduxFormWrappedComponent {...props} {...reduxFormProps} />;
    };
};

// Wraps a component with the redux-form decorator configured for the `requisitionsCreate` form
export const withRequisitionsCreateForm = () => (WrappedComponent) =>
    withCustomFormSnapshotWrapper(
        withCustomPropsToReduxForm(reduxForm(formConfig)(WrappedComponent))
    );

export const withSectionFormErrorHandling = (SectionComponent, sectionKey) => {
    const WrappedComponent = (props) => {
        const dispatch = useDispatch();
        const { formErrors } = props;

        useEffect(() => {
            if (formErrors) {
                dispatch(setSectionIncomplete(sectionKey));
            } else {
                dispatch(setSectionCompleted(sectionKey));
            }
        }, [dispatch, formErrors]);

        return <SectionComponent {...props} />;
    };

    WrappedComponent.propTypes = {
        formErrors: PropTypes.bool,
    };

    return WrappedComponent;
};

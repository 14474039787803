import { Box, Snackbar as MuiSnackbar, IconButton, Typography } from '@og-pro/ui';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
    CheckCircle as CheckCircleIcon,
    Close as CloseIcon,
    Error as ErrorIcon,
    Info as InfoIcon,
    Warning as WarningIcon,
} from '@mui/icons-material';

import { getSnackbarMessages } from './selectors';
import { removeSnackbarMessage } from '../../actions/notification';

const Icons = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const CloseButton = ({ handleClose }) => {
    return (
        <IconButton
            aria-label="close"
            onClick={handleClose}
            size="small"
            sx={{
                color: 'white',
                '&:hover': { backgroundColor: 'rgba(255,255,255,0.2)' },
            }}
        >
            <CloseIcon fontSize="small" />
        </IconButton>
    );
};

CloseButton.propTypes = {
    handleClose: PropTypes.func.isRequired,
};

const Content = ({ handleClose, messageData }) => {
    if (messageData?.title) {
        return (
            <>
                <Box sx={{ alignSelf: 'flex-start' }}>
                    {React.createElement(Icons[messageData.color] || Icons.info, {
                        sx: { marginTop: -0.5, paddingRight: 1 },
                    })}
                </Box>
                <Box>
                    <Typography
                        sx={{
                            paddingBottom: 0.5,
                        }}
                        variant="h5"
                    >
                        {messageData.title}
                    </Typography>
                    <Typography
                        sx={{
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                        variant="bodySmall"
                    >
                        {messageData.message}
                    </Typography>
                </Box>
                <Box sx={{ alignSelf: 'flex-start', marginLeft: 'auto' }}>
                    <CloseButton handleClose={handleClose} />
                </Box>
            </>
        );
    }

    if (messageData?.message) {
        return (
            <>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {React.createElement(Icons[messageData.color] || Icons.info, {
                        sx: { paddingRight: 1 },
                    })}
                    <Typography variant="h5">{messageData.message}</Typography>
                </Box>
                <Box sx={{ marginLeft: 'auto' }}>
                    <CloseButton handleClose={handleClose} />
                </Box>
            </>
        );
    }

    return null;
};

Content.propTypes = {
    handleClose: PropTypes.func.isRequired,
    messageData: PropTypes.shape({
        color: PropTypes.string,
        dismissAfter: PropTypes.number,
        hideCloseButton: PropTypes.bool,
        hideIcon: PropTypes.bool,
        key: PropTypes.string,
        message: PropTypes.string,
        title: PropTypes.string,
    }),
};

export const Snackbar = () => {
    const [open, setOpen] = useState(false);
    const [messageData, setMessageData] = useState(undefined);

    const messages = useSelector(getSnackbarMessages);
    const dispatch = useDispatch();

    useEffect(() => {
        if (messages.length > 0 && !messageData) {
            // Set a new snack when we don't have an active message being displayed
            setMessageData({ ...messages[0] });

            // Remove the added message from the queue
            dispatch(removeSnackbarMessage(messages[0]));

            // Display snackbar message
            setOpen(true);
        } else if (messages.length > 0 && messageData && open) {
            // Close an active snackbar message when a new one is added
            setOpen(false);
        }
    }, [messages, messageData, open]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleExited = () => {
        setMessageData(undefined);
    };

    return (
        <MuiSnackbar
            TransitionProps={{ onExited: handleExited }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            autoHideDuration={messageData?.dismissAfter || 3000}
            color={messageData?.color || 'info'}
            key={messageData?.key}
            onClose={handleClose}
            open={open}
            sx={{
                marginTop: 12.5,
                paddingY: 2,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <Content handleClose={handleClose} messageData={messageData} />
            </Box>
        </MuiSnackbar>
    );
};

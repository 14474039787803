import PropTypes from 'prop-types';

import { getDeserializedProject, hasQuestionnaire } from './selectors';

export function mapStateToProps(state) {
    return {
        hasQuestionnaire: hasQuestionnaire(state),
        loading: state.evaluations.get('loading'),
        // Used by redux-form to set initialValues in the form
        project: getDeserializedProject(state),
        showFormErrors: state.evaluations.get('showFormErrors'),
        updateError: state.evaluations.get('updateError'),
        updating: state.evaluations.get('updating'),
    };
}

export const propTypes = {
    departments: PropTypes.array.isRequired,
    hasQuestionnaire: PropTypes.bool.isRequired,
    initialized: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    project: PropTypes.object,
    updateError: PropTypes.string,
    updating: PropTypes.bool.isRequired,
    showFormErrors: PropTypes.bool.isRequired,
    users: PropTypes.array.isRequired,
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { QuestionnaireFileUploadForm } from '../../../shared';
import {
    createChecklistFileUploadResponse,
    createProposalFileUploadResponse,
} from '../../../../../../actions/questionnaire';

const mapDispatchToProps = {
    checklistUploadHandler: createChecklistFileUploadResponse,
    proposalUploadHandler: createProposalFileUploadResponse,
};

// @connect
class ConnectedFileUploadResponseInput extends Component {
    static propTypes = {
        checklistId: PropTypes.number,
        checklistUploadHandler: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        input: PropTypes.object.isRequired,
        isOGThemeEnabledForComponents: PropTypes.bool,
        isTemplateReadonlyView: PropTypes.bool,
        label: PropTypes.string,
        meta: PropTypes.object.isRequired,
        proposalId: PropTypes.number,
        proposalUploadHandler: PropTypes.func.isRequired,
        questionnaireId: PropTypes.number.isRequired,
        showValidation: PropTypes.bool,
    };

    render() {
        const {
            checklistId,
            checklistUploadHandler,
            disabled,
            input,
            isOGThemeEnabledForComponents,
            isTemplateReadonlyView,
            label,
            meta,
            proposalId,
            proposalUploadHandler,
            questionnaireId,
            showValidation,
        } = this.props;

        const uploadHandler = checklistId
            ? checklistUploadHandler(checklistId, questionnaireId)
            : proposalUploadHandler(proposalId, questionnaireId);

        return (
            <QuestionnaireFileUploadForm
                disabled={disabled}
                input={input}
                isOGThemeEnabledForComponents={isOGThemeEnabledForComponents}
                isTemplateReadonlyView={isTemplateReadonlyView}
                label={label}
                meta={meta}
                showValidation={showValidation}
                updateNestedData
                uploadHandler={uploadHandler}
            />
        );
    }
}

export const FileUploadResponseInput = connect(
    null,
    mapDispatchToProps
)(ConnectedFileUploadResponseInput);

import { mappedProcurementContactFields } from '@og-pro/shared-config/contacts';
import { listToDict } from '@og-pro/shared-config/helpers';
import { timelineDates } from '@og-pro/shared-config/timelines';

import { contactFields } from '../../../constants/contactFields';

export const form = 'evaluationCreate';

export const formConfig = {
    form,
    destroyOnUnmount: false,
};

const progressSections = ['overview', 'proposals', 'criteria', 'evaluators'];
export const progressSectionsDict = listToDict(progressSections);

export const fields = [
    ...contactFields,
    ...Object.values(mappedProcurementContactFields),
    'attachments',
    'budget',
    'categories',
    'contact_id',
    'contractorSelectedDate',
    'department_id',
    'departmentHead',
    'departmentHeadTitle',
    'departmentName',
    'evaluation',
    'financialId',
    'hasSealedBid',
    'procurement_contact_id',
    'proposalDeadline',
    'rawSummary',
    'releaseProjectDate',
    'requisitionIdentifier',
    'summary',
    'title',
    'timelines',
];
export const fieldNames = listToDict(fields);

export const evaluationFields = ['proposalDocuments', 'scoringCriteria', 'evaluators'];
export const evaluationFieldNames = listToDict(evaluationFields);

export const proposalDocumentFields = ['title', 'isRequired', 'orderById'];
export const proposalDocumentFieldNames = listToDict(proposalDocumentFields);

export const criteriaFields = ['title', 'description', 'weight', 'scoringMethod', 'orderById'];
export const criteriaFieldNames = listToDict(criteriaFields);

export const dateFields = timelineDates;

// Evaluators aren't actually saved on form submission, so this is just a virtual field used for
// dumping the evaluators into the evaluation create form
export const STAGED_EVALUATORS = 'stagedEvaluators';

export const progressSteps = [
    {
        icon: 'file-text-o',
        text: 'Project Overview',
        section: progressSectionsDict.OVERVIEW,
    },
    {
        icon: 'envelope-o',
        text: 'Response Docs',
        section: progressSectionsDict.PROPOSALS,
    },
    {
        icon: 'bar-chart-o',
        text: 'Evaluation Criteria',
        section: progressSectionsDict.CRITERIA,
    },
    {
        icon: 'star-o',
        text: 'Evaluators',
        section: progressSectionsDict.EVALUATORS,
    },
];

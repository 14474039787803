import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';

import { fieldNames, form, qaTagPageName } from './constants';
import { validate } from './validate';
import { InputText } from '../../../components';

const { OVER_BUDGET_NOTE } = fieldNames;

const formConfig = {
    form,
    validate,
};

// @reduxForm
const ConnectedOverBudgetNoteForm = ({ disabled, requireOverBudgetNote }) => {
    return (
        <form>
            <Field
                autoFocus={requireOverBudgetNote}
                characterLimit={1000}
                component={InputText}
                disabled={disabled}
                hasFeedback={false}
                label={`Reason For Overriding Budget Check Failure (${
                    requireOverBudgetNote ? 'Required' : 'Optional'
                })`}
                name={OVER_BUDGET_NOTE}
                placeholder="Enter text"
                qaTag={`${qaTagPageName}-overBudgetNote`}
                type="textarea"
            />
        </form>
    );
};

ConnectedOverBudgetNoteForm.propTypes = {
    disabled: PropTypes.bool,
    requireOverBudgetNote: PropTypes.bool,
};

export const OverBudgetNoteForm = reduxForm(formConfig)(ConnectedOverBudgetNoteForm);

import moment from 'moment';
import { createSelector } from 'reselect';
import { v4 as UUIDv4 } from 'uuid';

import {
    contractDateFilterTypesDict,
    contractFilterTypesDict,
} from '@og-pro/shared-config/contracts';

const { BETWEEN } = contractDateFilterTypesDict;
const { END_DATE, START_DATE } = contractFilterTypesDict;

const getInitialValuesJS = (props) => props.initialValues;

export const getInitialValues = createSelector([getInitialValuesJS], (initialValues) => {
    const formattedFilters = initialValues.map((val) => {
        // Date strings have to be converted to Date objects
        if (val.type === END_DATE || val.type === START_DATE) {
            if (val.dateFilterType === BETWEEN) {
                return { ...val, value: val.value.map((v) => moment(v).toDate()) }; // `Between` filters have two dates
            }
            return { ...val, value: moment(val.value).toDate() };
        }

        return { ...val, localCreatingUUID: UUIDv4() };
    });

    return { filters: formattedFilters };
});

import React, { useContext } from 'react';

import {
    ProjectCreateManageSectionContext,
    ProjectCreateV2FunctionsContext,
} from '../../../context';
import { DisconnectedEditSection } from '../EditSection';
import { projectCreateDrawerForm } from '../../../constants';

export const ProjectCreateDrawerContentEditSectionBlock = () => {
    const {
        change,
        data: { index },
        setData,
        sortSections,
    } = useContext(ProjectCreateManageSectionContext);
    const { project } = useContext(ProjectCreateV2FunctionsContext);
    const sections = project.projectSections;

    const unsetEditing = () => {
        setData({
            active: false,
            index: null,
        });
    };

    const onSave = (originalParentDividerIndex = null) => {
        sortSections(null, originalParentDividerIndex);
        unsetEditing();
    };

    if (index === null || !sections) {
        return null;
    }

    const selectedSection = sections[index];

    if (!selectedSection) {
        return null;
    }

    return (
        <DisconnectedEditSection
            change={change}
            form={projectCreateDrawerForm}
            index={index}
            onCancel={unsetEditing}
            onSave={onSave}
            showActionsOnFooter
        />
    );
};

ProjectCreateDrawerContentEditSectionBlock.propTypes = {};

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from 'redux-form';

import { updateVendorAssociatedPriceItems } from '../helpers';
import { getVendorOptions } from './selectors';
import { fieldNames } from '../constants';
import { requisitionsCreateFormValueSelector } from '../selectors';
import { priceItemFieldNames } from '../../../constants';
import { getVendorAssignmentModal } from '../../../../../selectors/govApp/requisitions';
import { hideVendorAssignmentModal } from '../../../../../actions/requisitions';
import { Button, SearchSelect } from '../../../../../components';

const { PRICE_ITEMS, PRICE_TABLE, VENDORS } = fieldNames;
const { CLIENT_VENDOR_ASSIGNMENT_UUID, VENDOR_NAME, VENDOR_ID_MODAL } = priceItemFieldNames;

export const VendorAssignmentModal = ({ change, submitHandler }) => {
    const dispatch = useDispatch();
    const styles = require('./index.scss');

    const [selectedVendorName, setSelectedVendorName] = useState(null);

    const vendorOptions = useSelector(getVendorOptions);
    const {
        data: { index },
        show,
    } = useSelector(getVendorAssignmentModal);

    const fieldName = `${PRICE_TABLE}.${PRICE_ITEMS}[${index}]`;

    const {
        clientVendorAssignmentUuid,
        description,
        requisitionVendorData: [selectedVendor] = [], // gets the first object in the array if the array exists, fails safely if not
        vendorIdModal,
        vendorAssignmentUuid: priceItemVendorAssignmentUuid,
    } = useSelector((state) => requisitionsCreateFormValueSelector(state, fieldName)) || {};
    const vendors = useSelector((state) => requisitionsCreateFormValueSelector(state, VENDORS));

    const selectedVendorUuid = clientVendorAssignmentUuid ?? selectedVendor?.vendorAssignmentUuid;

    useEffect(() => {
        if (show && selectedVendorUuid) {
            dispatch(change(`${fieldName}.${VENDOR_ID_MODAL}`, selectedVendorUuid));
        }
    }, [show]);

    const onVendorChange = (data) => {
        setSelectedVendorName(data?.label || null);
    };

    const closeModal = () => dispatch(hideVendorAssignmentModal());

    const onSave = async () => {
        if (vendorIdModal !== clientVendorAssignmentUuid) {
            updateVendorAssociatedPriceItems(
                dispatch,
                change,
                index,
                priceItemVendorAssignmentUuid,
                vendors,
                description,
                vendorIdModal,
                clientVendorAssignmentUuid
            );
        }

        dispatch(change(`${fieldName}.${VENDOR_NAME}`, selectedVendorName));

        // this has to be updated in the form before the form gets submitted
        await dispatch(change(`${fieldName}.${CLIENT_VENDOR_ASSIGNMENT_UUID}`, vendorIdModal));

        if (submitHandler) {
            submitHandler();
        }

        closeModal();

        dispatch(change(`${fieldName}.${VENDOR_ID_MODAL}`, null));
    };

    const renderSearch = () => (
        <Field
            component={SearchSelect}
            isClearable
            // VENDOR_ID_MODAL is used as a placeholder until the user clicks "Choose Vendor"
            name={`${fieldName}.${VENDOR_ID_MODAL}`}
            onChangeWithOption={onVendorChange}
            options={vendorOptions}
            placeholder="Search vendors"
        />
    );

    return (
        <Modal onHide={closeModal} show={show}>
            <Modal.Header closeButton>
                <Modal.Title>Choose a Vendor</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={styles.bodyText}>
                    Choose a vendor to purchase&nbsp;
                    <span className={styles.boldText}>Line Item {index + 1}</span> from:
                </div>
                {renderSearch()}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={closeModal} qaTag="vendorAssignmentModal-cancel">
                    Cancel
                </Button>
                <Button
                    bsStyle="primary"
                    onClick={onSave}
                    qaTag="vendorAssignmentModal-chooseVendor"
                >
                    Choose Vendor
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

VendorAssignmentModal.propTypes = {
    change: PropTypes.func.isRequired,
    submitHandler: PropTypes.func,
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { CommentIcon } from '../../../../containers/GovApp';
import { DocxHeading } from '../../..';

export class IntroductionTitle extends PureComponent {
    static propTypes = {
        title: PropTypes.string.isRequired,
        subsectionType: PropTypes.string.isRequired,
        projectSection: PropTypes.object.isRequired,
        itemNumber: PropTypes.string.isRequired,
        showComments: PropTypes.bool,
        isDocx: PropTypes.bool,
    };

    renderDocx() {
        return (
            <DocxHeading
                domElement="h4"
                legacy={<h4>{this.props.title}</h4>}
                numbering={this.props.itemNumber}
                title={this.props.title}
            />
        );
    }

    render() {
        const { title, projectSection, subsectionType, itemNumber, showComments } = this.props;

        if (this.props.isDocx) {
            return this.renderDocx();
        }

        let commentIcon;
        if (showComments) {
            commentIcon = (
                <CommentIcon
                    projectSection={projectSection}
                    pullRight
                    show={showComments}
                    subsectionType={subsectionType}
                />
            );
        }

        return (
            <h4 className="introduction-subsection-title">
                {commentIcon}
                {itemNumber} {title}
            </h4>
        );
    }
}

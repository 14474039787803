import { basicUserRoles } from '@og-pro/shared-config/userRoles';

import { listToDict } from '../../../../utils';

const { ADMIN, USER } = basicUserRoles;

export const form = 'adminUser';

export const fields = [
    'department_id',
    'email',
    'firstName',
    'isExternal',
    'lastName',
    'role',
    'userRoles',
];

export const fieldNames = listToDict(fields);

export const VENDOR_USER_ROLES_SELECT_OPTIONS = [
    { value: USER, label: 'User' },
    { value: ADMIN, label: 'Admin' },
];

import React, { useEffect, useState } from 'react';
import { useLoadCustomForm } from '../../../../../lib/customFormService/useLoadCustomForm';
import { useSelector } from 'react-redux';
import { getRequestType } from '../../../../../selectors/govApp';
import { FormControl, Select, MenuItem, Typography, Button, Box } from '@og-pro/ui';
import { useListCustomFields } from '../../../../../lib/customFormService/useListCustomFields';
import { fieldStatusesDict } from '@og-pro/shared-config/customFormService/customField';
import { LoadingSpinner } from '../../../../../components';
import { useUpdateCustomForm } from '../../../../../lib/customFormService/useUpdateCustomForm';
import { useQueryClient } from '@tanstack/react-query';
import { CUSTOM_FORM_SERVICE_QUERY_PREFIX } from '../../../../../lib/customFormService/useCustomFormService';

// Temporary component for development purposes
// To be replaced by actual UI for Custom Fields section
export const BasicCustomFieldEditor = () => {
    const requestType = useSelector(getRequestType);
    const { customFormId } = requestType;

    const queryClient = useQueryClient();

    const { data: listCustomFieldsData } = useListCustomFields();
    const { data: customFormData, isLoading: isCustomFormLoading } =
        useLoadCustomForm(customFormId);
    const { mutateAsync } = useUpdateCustomForm(customFormId);

    const [fieldsOrder, setFieldsOrder] = useState([]);
    useEffect(() => {
        if (!isCustomFormLoading) {
            setFieldsOrder(
                customFormData?.getCustomForm?.customFields?.map((customField) => customField.id)
            );
        }
    }, [isCustomFormLoading, customFormData, setFieldsOrder]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setFieldsOrder(typeof value === 'string' ? value.split(',') : value);
    };

    const handleSubmit = () => {
        mutateAsync({
            fieldsOrder,
        }).then(() => {
            queryClient.invalidateQueries({
                queryKey: [CUSTOM_FORM_SERVICE_QUERY_PREFIX, 'getCustomForm', { customFormId }],
            });
        });
    };

    if (!customFormId) {
        return null;
    }

    if (isCustomFormLoading) {
        return <LoadingSpinner useOpenGovStyle />;
    }

    return (
        <Box>
            <Typography>Debugging Information</Typography>
            <Typography>customFormId: {customFormId}</Typography>
            <Typography>
                backend:{' '}
                {JSON.stringify(
                    customFormData.getCustomForm.customFields.map((customField) => customField.id)
                )}
            </Typography>
            <Typography>frontend: {JSON.stringify(fieldsOrder)}</Typography>
            <FormControl sx={{ m: 1 }}>
                <Select multiple value={fieldsOrder} onChange={handleChange}>
                    {listCustomFieldsData.getAllCustomFields
                        .filter((field) => field.status === fieldStatusesDict.PUBLISHED)
                        .map((field) => (
                            <MenuItem key={field.id} value={field.id}>
                                {field.id} - {field.name}
                            </MenuItem>
                        ))}
                </Select>
                <Button onClick={handleSubmit}>Save Form's Custom Fields Section</Button>
            </FormControl>
        </Box>
    );
};

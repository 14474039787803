import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Box, Button, CircularProgress, Typography } from '@og-pro/ui';
import { Panel } from 'react-bootstrap';
import { Add as AddIcon } from '@mui/icons-material';

import VendorManagementHeader from '../VendorManagementHeader';
import { VendorListsTable } from './components';
import { VendorListDialog } from '../..';
import { getPortalUrl, getVendorLists, getVendorListsLoading } from '../../selectors';
import { loadVendorLists } from '../../../../actions/vendorList';
import { getUserJS } from '../../../selectors';

export const VendorListsManagerV2 = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { governmentId: govIdParam } = useParams();
    const governmentId = Number.parseInt(govIdParam, 10);

    useEffect(() => {
        dispatch(loadVendorLists(governmentId));
    }, []);

    const { government } = useSelector(getUserJS);
    const loadingVendorLists = useSelector(getVendorListsLoading);
    const portalUrl = useSelector(getPortalUrl);
    const vendorLists = useSelector(getVendorLists);

    const [showVendorListDialog, setShowVendorListDialog] = useState(false);

    return (
        <div>
            <Panel>
                <Panel.Body>
                    <VendorManagementHeader
                        governmentId={governmentId}
                        pathname={location.pathname}
                        portalUrl={portalUrl}
                    />
                    {loadingVendorLists && (
                        <Box display="flex" justifyContent="center">
                            <CircularProgress size="large" />
                        </Box>
                    )}
                    {!loadingVendorLists && (
                        <>
                            <Box display="flex" justifyContent="space-between">
                                <Typography
                                    sx={{
                                        fontSize: '24px',
                                        fontWeight: 500,
                                        color: 'black',
                                        lineHeight: '32px',
                                        marginBottom: '10px',
                                    }}
                                >
                                    Vendor Lists
                                </Typography>
                                <Box>
                                    <Button
                                        onClick={() => setShowVendorListDialog(true)}
                                        qaTag="vendorListsManagerV2-newVendorList"
                                        variant="outlined"
                                    >
                                        <AddIcon fontSize="small" /> New Vendor List
                                    </Button>
                                </Box>
                            </Box>
                            <VendorListDialog
                                displayPublicVendorInformation={
                                    government.displayPublicVendorInformation
                                }
                                governmentId={governmentId}
                                hideDialog={() => setShowVendorListDialog(false)}
                                open={showVendorListDialog}
                            />
                            <VendorListsTable
                                displayPublicVendorInformation={
                                    government.displayPublicVendorInformation
                                }
                                governmentId={governmentId}
                                vendorLists={vendorLists}
                            />
                        </>
                    )}
                </Panel.Body>
            </Panel>
        </div>
    );
};

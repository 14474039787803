import { browserHistory } from '@og-pro-migration-tools/react-router';

import { resetAuth } from './auth';

export const UNAUTHORIZED = 'error/general/UNAUTHORIZED';
export const RESET = 'error/general/RESET';

export function isUnauthorized(state) {
    return state.error && state.error.get('unauthorized');
}

export function unauthorizedError(nextRoute) {
    return (dispatch) => {
        dispatch({ type: UNAUTHORIZED, nextRoute });
        browserHistory.push('/login');

        dispatch(resetAuth());
    };
}

export function resetError() {
    return {
        type: RESET,
    };
}

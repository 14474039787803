import { createSelector } from 'reselect';

const getContractMilestones = (state) => state.contracts.get('contractMilestones');

export const getContractMilestonesJS = createSelector(
    [getContractMilestones],
    (rawContractMilestones) => {
        if (rawContractMilestones) {
            return rawContractMilestones.toJS();
        }
        return [];
    }
);

import moment from 'moment-timezone';

/**
 * Creates a new workbook for ai audit logs as an XLSX file and automatically initiates a browser download.
 * @param {object[]} audits An array of audit objects to write into a spreadsheet.
 * @param {string} timezone The timezone to use when formatting dates.
 * @returns {void} Returns nothing, but automatically initiates a browser download.
 */
export const writeAiAuditsLogFile = (audits, timezone) => {
    if (!window.XLSX) {
        throw new Error('XLSX package not found');
    }

    const formattedAudits = audits.map((audit) => {
        const { created_at: createdAt, project, prompt, user } = audit;
        return {
            'Prompt Phrase': prompt,
            'Project Title': !project ? 'Project Deleted' : project.title || 'Untitled Project',
            'User Name': user.displayName,
            'User Department': user.department.name,
            Date: moment(createdAt).tz(timezone).format('L LTS'),
        };
    });

    const workbook = window.XLSX.utils.book_new();

    const worksheet = window.XLSX.utils.json_to_sheet(formattedAudits);

    window.XLSX.utils.book_append_sheet(workbook, worksheet, 'Audit Log');

    return window.XLSX.writeFile(workbook, 'scope-of-work-ai-audit-log.xlsx');
};

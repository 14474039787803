import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Button, FixedFooter } from '../../..';

export class ApprovalsFooter extends PureComponent {
    static propTypes = {
        approvalStatus: PropTypes.string.isRequired,
        showApprovalsModal: PropTypes.func.isRequired,
    };

    handleApproveClick = () => {
        const { showApprovalsModal } = this.props;

        showApprovalsModal('approve');
    };

    handleEditClick = () => {
        const { showApprovalsModal } = this.props;

        showApprovalsModal('edit');
    };

    handleRejectClick = () => {
        const { showApprovalsModal } = this.props;

        showApprovalsModal('reject');
    };

    renderApprovedFooter() {
        const styles = require('./ApprovalsFooter.scss');
        const editLink = (
            <a className={styles.editLink} onClick={this.handleEditClick}>
                edit
            </a>
        );
        return (
            <FixedFooter bsStyle="success" offset={70}>
                <i className="fa fa-check-square-o fa-2x" />
                <span className={styles.statusText}>
                    You have approved this project! ({editLink})
                </span>
            </FixedFooter>
        );
    }

    renderPendingFooter() {
        const styles = require('./ApprovalsFooter.scss');
        return (
            <FixedFooter bsStyle="info" offset={70}>
                <Button
                    bsStyle="success"
                    className={styles.approveButton}
                    onClick={this.handleApproveClick}
                >
                    <i className="fa fa-check" /> Approve Project
                </Button>
                <Button onClick={this.handleRejectClick}>
                    <i className="fa fa-times text-danger" /> Reject Project
                </Button>
                <div className={styles.infoText}>
                    Your approval of this project has been requested. Please review the entire
                    project before approving.
                </div>
            </FixedFooter>
        );
    }

    renderRejectedFooter() {
        const styles = require('./ApprovalsFooter.scss');
        const editLink = (
            <a className={styles.editLink} onClick={this.handleEditClick}>
                Edit Project Approval
            </a>
        );
        return (
            <FixedFooter bsStyle="warning" offset={70}>
                <i className="fa fa-ban fa-2x" />
                <span className={styles.statusText}>
                    You have rejected this project - {editLink}
                </span>
            </FixedFooter>
        );
    }

    render() {
        switch (this.props.approvalStatus) {
            case 'approved':
                return this.renderApprovedFooter();
            case 'rejected':
                return this.renderRejectedFooter();
            default:
                return this.renderPendingFooter();
        }
    }
}

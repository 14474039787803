import { tokens } from '@opengov/capital-style';

export const accordionStyles = {
    borderBottom: 'none',
    borderLeft: 'none',
    borderRadius: '0',
    borderRight: 'none',
    minHeight: '16px',
    '&:first-of-type': {
        borderTop: 'none',
    },
    '&.Mui-expanded': {
        paddingBottom: '8px',
    },
    '.MuiAccordionSummary-root': {},
};

export const innerAccordionStyles = {
    ...accordionStyles,
    border: 'none',
    marginBottom: '3px', // preventing overlap
};

export const accordionSummaryStyles = {
    minHeight: '52px',

    '&.Mui-expanded': {
        backgroundColor: 'inherit !important',
    },

    '.MuiAccordionSummary-content': {
        margin: '0',
    },
};

export const innerAccordionSummaryStyles = {
    ...accordionSummaryStyles,
    minHeight: '20px',
};

export const accordionDetailsStyles = {
    paddingBottom: '0',
    paddingTop: '3px', // preventing overlap
};

export const chipStyles = {
    backgroundColor: tokens.colors.colorGray700,
    color: tokens.colors.colorWhite,
};

import { redirect } from 'react-router-dom';

export { default as loginRedirect } from './loginRedirect';
export { default as supportLoginRedirect } from './supportLoginRedirect';
export { default as supportRequireLogin } from './supportRequireLogin';
export { default as requireOpenGovUser } from './requireOpenGovUser';
export { default as redirectToLowercasePortal } from './redirectToLowercasePortal';
export { default as redirectFromOldProjectPathToPortal } from './redirectFromOldProjectPathToPortal';
export { default as requireAnyGovUser } from './requireAnyGovUser';
export { default as redirectToAdmin } from './redirectToAdmin';
export { default as requireVendorLogin } from './requireVendorLogin';
export { default as requireVendorAdmin } from './requireVendorAdmin';
export { default as requireGovLogin } from './requireGovLogin';
export * from './requireGovernmentUserPermissions';
export * from './requireSubscription';

// Mostly everywhere the app is prepared to "wait" for data
// to load. The only place where we want data to be blocking is when SSRing.
// while on the client, the code is filled with "if loading..."
export const fetchLoaderData = (loader) => {
    return async (props) => {
        if (process.env.SERVER) {
            await loader(props);
            return null;
        }

        loader(props);
        return null;
    };
};

export const legacyFetchData = (getState, dispatch, Component) => {
    return async (props) => {
        const {
            params,
            request: { url },
        } = props;

        if (Component.fetchData) {
            const loader = () => Component.fetchData(getState, dispatch, new URL(url), params);
            await fetchLoaderData(loader)(props);
        }

        return null;
    };
};

export const legacyFetchDataLowercaseGov = (getState, dispatch, Component) => {
    return async (props) => {
        const {
            params,
            request: { url },
        } = props;

        if (
            (!params.governmentCode ||
                params.governmentCode === params.governmentCode.toLowerCase()) &&
            Component.fetchData
        ) {
            const loader = () => Component.fetchData(getState, dispatch, new URL(url), params);
            await fetchLoaderData(loader)(props);
        }

        return null;
    };
};

export const clientNavigationOnly = () => {
    if (typeof window === 'undefined') {
        return redirect('/login');
    }

    return null;
};

import { VENDOR_DISCOVERY_PROMPT } from '@og-pro/shared-config/generativeAi';
import { statePostalAbbreviations } from '@og-pro/shared-config/global';
import { sectionTypeNames } from '@og-pro/shared-config/sections';

import { stripHtml } from '../../../utils';

// Formats data for creating a prompt and calculating an accuracy score
const getVendorDiscoveryProjectData = (project) => {
    return {
        priceItems: project.priceTables
            .flatMap(({ priceItems }) => priceItems.filter(({ description }) => !!description))
            .map(({ description }, index) => `${index + 1}. ${description}`),
        scopeOfWorkItems: project.criteria
            .filter(({ section_type: sectionType }) => sectionType === sectionTypeNames.SCOPE)
            .filter(({ description, title }) => !!description && !!title)
            .map(
                ({ description, title }, index) =>
                    `${index + 1}. ${title}: ${stripHtml(description)}`
            ),
        summary: project.summary,
        title: project.title,
    };
};

// Function for approximating accuracy based on the amount of data available to send
export const getAccuracyScoring = (project) => {
    const { priceItems, scopeOfWorkItems, summary, title } = getVendorDiscoveryProjectData(project);

    const hasSection = (type) =>
        project.projectSections.some(({ section_type: sectionType }) => sectionType === type);
    const hasScopeSection = hasSection(sectionTypeNames.SCOPE);
    const hasPricingSection = hasSection(sectionTypeNames.PRICING);

    let score = 95;
    const suggestions = [];

    if (!title) {
        score -= 40;
        suggestions.push('Add a title to your project');
    }

    if (!summary) {
        score -= 40;
        suggestions.push('Add a summary to your project');
    } else if (summary.length < 100) {
        score -= 15;
        suggestions.push('Add more summary detail to your project');
    } else if (summary.length < 300) {
        score -= 5;
        suggestions.push('Add more summary detail to your project');
    }

    if (!hasScopeSection) {
        score -= 10;
    } else if (scopeOfWorkItems.length === 0) {
        score -= 25;
        suggestions.push('Add a scope of work to your project');
    } else if (scopeOfWorkItems.length < 5) {
        score -= 5;
        suggestions.push('Add more scope of work items to your project');
    }

    if (!hasPricingSection) {
        score -= 5;
    } else if (priceItems.length === 0) {
        score -= 25;
        suggestions.push('Add pricing to your project');
    } else if (priceItems.length < 5) {
        score -= 5;
        suggestions.push('Add more price items to your project');
    }

    return { score: Math.max(score, 0), suggestions };
};

// Formats data for creating the vendor discovery user prompt
const createVendorDiscoveryUserPrompt = (project, isStateOnly) => {
    const { priceItems, scopeOfWorkItems, summary, title } = getVendorDiscoveryProjectData(project);
    const {
        government: {
            organization: { state: stateAbbreviation },
        },
    } = project;

    const state = statePostalAbbreviations.find(
        ({ postalAbbreviation }) => postalAbbreviation === stateAbbreviation
    );

    return [
        title && `I want to purchase: ${title}.`,
        isStateOnly && state && `All vendors to consider must be located in the state of ${state}`,
        summary && `A summary of the purchase is: ${stripHtml(summary)}`,
        scopeOfWorkItems.length > 0 && `Scope of Work:\n${scopeOfWorkItems.join('\n')}`,
        priceItems.length > 0 && `Pricing information:\n${priceItems.join('\n')}`,
    ]
        .filter((item) => !!item)
        .join('\n\n');
};

// Gets the request data needed to make a generative-ai request
export const getDiscoverVendorData = (project) => ({
    promptsKey: VENDOR_DISCOVERY_PROMPT,
    userPrompt: createVendorDiscoveryUserPrompt(project),
});

import { fromJS } from 'immutable';

import {
    GENERATE_AI_SCOPE_CONTENT,
    GENERATE_AI_SCOPE_CONTENT_FAIL,
    GENERATE_AI_SCOPE_CONTENT_SUCCESS,
} from '../../actions/govProjects/generativeAiScope';

const initialState = fromJS({
    loadError: null,
    generativeAiChat: null,
});

export default function generativeAiScopeReducer(state = initialState, action = {}) {
    switch (action.type) {
        case GENERATE_AI_SCOPE_CONTENT:
            return state.merge(
                fromJS({
                    loadError: null,
                    generativeAiChat: null,
                })
            );
        case GENERATE_AI_SCOPE_CONTENT_SUCCESS:
            return state.merge(
                fromJS({
                    generativeAiChat: fromJS(action.result.generativeAiChat),
                })
            );
        case GENERATE_AI_SCOPE_CONTENT_FAIL:
            return state.merge(
                fromJS({
                    loadError: action.error && action.error.message,
                })
            );
        default:
            return state;
    }
}

import React, { useContext } from 'react';

import { ReduxFormCustomForm } from '@og-pro/ui';

import PropTypes from 'prop-types';

import { CustomFormSnapshotContext } from '../../CustomFormSnapshotContext';
import { fieldNames } from '../../constants';

const { CUSTOM_FORM_DATA } = fieldNames;

export const CustomFieldsSection = ({ showFormValidation, disabled }) => {
    const { customFields } = useContext(CustomFormSnapshotContext);
    return (
        <ReduxFormCustomForm
            disabled={disabled}
            reduxFormPrefix={CUSTOM_FORM_DATA}
            showValidation={showFormValidation}
            snapshot={customFields}
        />
    );
};

CustomFieldsSection.propTypes = {
    showFormValidation: PropTypes.bool,
    disabled: PropTypes.bool,
};

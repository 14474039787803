import { createSelector } from 'reselect';

export const getModalData = createSelector(
    [(state) => state.contacts.get('contactModalData')],
    (rawModalData) => {
        if (rawModalData) {
            return rawModalData.toJS();
        }
        return {};
    }
);

import React from 'react';
import { useSelector } from 'react-redux';

import { ActiveBidLineItem } from './ActiveBidLineItem';
import { NoBidLineItem } from './NoBidLineItem';
import { getIsAuctionLive, getVendorPriceItemBids } from './selectors';
import { getProposalJS } from '../../selectors';

export const ReverseAuctionLineItemDetails = () => {
    const isAuctionLive = useSelector((state) => getIsAuctionLive(state));
    const proposal = useSelector((state) => getProposalJS(state));
    const { activeBids, noBids, rankAndBidMap } = useSelector(getVendorPriceItemBids);

    const {
        project: {
            allowsProxyBidding,
            auctionMaxFractionDigits,
            government: {
                organization: { timezone },
            },
        },
    } = proposal;

    return (
        <>
            <h3>Bids by Line Item</h3>
            {activeBids.map((item) => (
                <ActiveBidLineItem
                    allowsProxyBidding={allowsProxyBidding}
                    auctionMaxFractionDigits={auctionMaxFractionDigits}
                    isAuctionLive={isAuctionLive}
                    item={item}
                    key={item.id}
                    proposalId={proposal.id}
                    rankAndBidMap={rankAndBidMap[item.id]}
                    timezone={timezone}
                    vendorId={proposal.vendor.id}
                />
            ))}
            {noBids.map((item) => (
                <NoBidLineItem
                    auctionMaxFractionDigits={auctionMaxFractionDigits}
                    item={item}
                    key={item.id}
                />
            ))}
        </>
    );
};

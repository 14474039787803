import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { ApproveModalBody } from '.';

export class MarkApprovedModalBody extends PureComponent {
    static propTypes = {
        name: PropTypes.string.isRequired,
        approved: PropTypes.bool.isRequired,
        note: PropTypes.string,
        submitError: PropTypes.string,
        approveHandler: PropTypes.func.isRequired,
        hideModal: PropTypes.func.isRequired,
    };

    renderInfo() {
        const { approved, name } = this.props;
        if (approved) return null;
        const styles = require('../ApprovalsModal.scss');

        return (
            <div className={styles.markApproved}>
                <p className="text-danger">
                    <strong>WARNING: </strong>
                    You are about to approve this project on behalf of {name}. This action should
                    only be made if the approver has approved of the project, but cannot mark it
                    approved themself.
                </p>
                <p>
                    Upon marking approved, the approver will be emailed, so they are aware that you
                    have approved the project for them. You may also leave a note explaining why you
                    needed to approve the project for {name}.
                </p>
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.renderInfo()}
                <ApproveModalBody {...this.props} />
            </div>
        );
    }
}

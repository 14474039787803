import classNames from 'classnames';
import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Field } from 'redux-form';

import { fieldNames } from './constants';
import { DepartmentField } from './DepartmentField';
import { ExternalField } from './ExternalField';
import { UserRoleField } from './UserRoleField';
import { Button, InputText } from '../../../../components';
import { inviteUser, removeUser } from '../../../../actions/admin';

const { DEPARTMENT_ID, EMAIL, FIRST_NAME, IS_EXTERNAL, LAST_NAME, ROLE, USER_ROLES } = fieldNames;

export const NewUserForm = ({
    cancelHandler,
    currentUser,
    disabled,
    handleSubmit,
    invalid,
    isModalView,
    submitHandler,
    user,
}) => {
    const dispatch = useDispatch();
    const styles = require('../index.scss');

    const handleInviteUser = (formData) => {
        const data = pick(formData, [
            DEPARTMENT_ID,
            EMAIL,
            FIRST_NAME,
            IS_EXTERNAL,
            LAST_NAME,
            ROLE,
            USER_ROLES,
        ]);
        if (submitHandler) {
            return submitHandler(user.uuid, data);
        }
        dispatch(inviteUser(user.uuid, data));
    };

    const handleRemoveUser = () => {
        dispatch(removeUser(user.uuid));
    };

    const { isVendor } = currentUser;
    const { id, inviting, uuid } = user;

    const icon = inviting ? 'fa fa-spin fa-spinner' : 'fa fa-user-plus';
    const buttonText = inviting ? 'Inviting...' : 'Invite User';

    const userFormCol = isModalView ? '' : styles.userFormCol;
    const userCol = isModalView ? '' : styles.userCol;

    const UserRoleInput = (
        <UserRoleField disabled={disabled} isVendor={isVendor} showLabel={isModalView} />
    );

    const DepartmentInput = <DepartmentField disabled={disabled} showLabel={isModalView} />;

    return (
        <div className={classNames('row', !isModalView && styles.userListRow)}>
            <div className={`col-xs-12 col-md-4 ${userFormCol}`}>
                {!isVendor && (
                    <div className="row">
                        <div className={isModalView ? 'col-xs-12 ' : 'col-md-6'}>
                            <Field
                                autoFocus
                                component={InputText}
                                hasFeedback={false}
                                id={`form-group-first-name-${id || uuid}`}
                                label="First Name"
                                name={FIRST_NAME}
                                placeholder="First"
                                type="text"
                            />
                        </div>
                        <div className={isModalView ? 'col-xs-12 ' : 'col-md-6'}>
                            <Field
                                component={InputText}
                                hasFeedback={false}
                                id={`form-group-last-name-${id || uuid}`}
                                label="Last Name"
                                name={LAST_NAME}
                                placeholder="Last"
                                type="text"
                            />
                        </div>
                    </div>
                )}
                <div className="row">
                    <div className="col-xs-12">
                        <Field
                            autoFocus={isVendor}
                            component={InputText}
                            hasFeedback={false}
                            help={isVendor ? 'Email address of user to invite' : undefined}
                            id={`form-group-email-${id || uuid}`}
                            label={isVendor ? undefined : 'Email Address'}
                            name={EMAIL}
                            placeholder="username@email.org"
                            type="text"
                        />
                        {!isVendor && <ExternalField disabled={disabled} />}
                    </div>
                </div>
            </div>
            {isModalView ? (
                <div className="col-xs-12 col-md-4">
                    {UserRoleInput}
                    {!isVendor && <div>{DepartmentInput}</div>}
                </div>
            ) : (
                <>
                    <div className={`hidden-xs hidden-sm col-md-1 ${userCol}`} />
                    <div className={`hidden-xs hidden-sm col-md-1 ${userCol}`} />
                    <div className={`col-xs-12 col-md-2 ${userFormCol}`}>{UserRoleInput}</div>
                    {!isVendor && (
                        <div className={`col-xs-12 col-md-2 ${userFormCol}`}>{DepartmentInput}</div>
                    )}
                </>
            )}
            <div
                className={classNames(
                    `col-xs-12 text-center ${userCol}`,
                    isModalView ? 'col-md-4' : 'col-md-2'
                )}
            >
                {isModalView && <label>&nbsp;</label>}
                <Button
                    block
                    bsStyle="primary"
                    disabled={disabled || invalid}
                    onClick={handleSubmit(handleInviteUser)}
                >
                    <i className={icon} /> {buttonText}
                </Button>
                <Button
                    bsSize="sm"
                    bsStyle="link"
                    disabled={inviting}
                    onClick={cancelHandler || handleRemoveUser}
                >
                    Cancel
                </Button>
            </div>
        </div>
    );
};

NewUserForm.propTypes = {
    cancelHandler: PropTypes.func,
    currentUser: PropTypes.shape({
        isVendor: PropTypes.bool.isRequired,
    }).isRequired,
    disabled: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool.isRequired,
    isModalView: PropTypes.bool,
    user: PropTypes.shape({
        id: PropTypes.number,
        inviting: PropTypes.bool,
        uuid: PropTypes.string,
    }).isRequired,
    submitHandler: PropTypes.func,
};

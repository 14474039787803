import React from 'react';
import PropTypes from 'prop-types';

export const BulkUpdateHasErrorsField = ({ error }) => {
    if (!error) {
        return null;
    }

    return <div className="error-block text-center">{error}</div>;
};

BulkUpdateHasErrorsField.propTypes = {
    error: PropTypes.string,
};

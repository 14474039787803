import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';

import { fieldNames, flagStatusOptions, form } from './constants';
import { validate } from './validate';
import {
    Button,
    InputText,
    LoadingButton,
    SearchSelect,
    SearchSelectIconOption,
    SearchSelectIconValue,
} from '../../../../../components';

const { DESCRIPTION, IS_ACTIVE, NAME } = fieldNames;

const formConfig = {
    form,
    validate,
};

const ConnectedFlagForm = ({ handleSubmit, isNew, onCancel, updateError, updating }) => {
    return (
        <form onSubmit={handleSubmit}>
            <Field
                component={InputText}
                disabled={updating}
                hasFeedback={false}
                label="Flag Name"
                name={NAME}
                placeholder="IT Purchase"
                qaTag="flagFormModal-name"
                type="text"
            />
            <Field
                component={InputText}
                disabled={updating}
                hasFeedback={false}
                label="Description"
                minRows={2}
                name={DESCRIPTION}
                placeholder="Describe when flag should be used"
                qaTag="flagFormModal-description"
                type="textarea"
            />
            <Field
                backspaceRemovesValue={false}
                component={SearchSelect}
                components={{
                    Option: SearchSelectIconOption,
                    SingleValue: SearchSelectIconValue,
                }}
                disabled={updating}
                isSearchable={false}
                label="Flag Status"
                name={IS_ACTIVE}
                options={flagStatusOptions}
                placeholder="Select user for this position"
            />
            <div className="text-right">
                <Button onClick={onCancel} qaTag="flagFormModal-cancel">
                    Cancel
                </Button>
                &nbsp;&nbsp;
                <LoadingButton
                    bsStyle={isNew ? 'success' : 'primary'}
                    disabled={updating}
                    icon={isNew ? 'fa-plus' : 'fa-pencil'}
                    loading={updating}
                    qaTag="flagFormModal-submit"
                    text={isNew ? 'Create Flag' : 'Update Flag'}
                    type="submit"
                />
                {updateError && <div className="error-block">{updateError}</div>}
            </div>
        </form>
    );
};

ConnectedFlagForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    isNew: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    updateError: PropTypes.string,
    updating: PropTypes.bool,
};

export const FlagForm = reduxForm(formConfig)(ConnectedFlagForm);

import PropTypes from 'prop-types';
import React from 'react';
import { DropdownButton } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
    copyTemplateChecklist,
    deleteTemplateChecklist,
} from '../../../../actions/checklistsAdmin';
import { showConfirmationSimpleModal } from '../../../../actions/confirmation';
import {
    Button,
    ConnectedClients,
    MenuItem,
    PageTitle,
    TemplateStatusLabel,
} from '../../../../components';

const mapStateToProps = (state) => {
    return {
        copyTemplateError: state.checklistsAdmin.get('copyError'),
        deleteError: state.checklistsAdmin.get('deleteError'),
        updateError: state.checklistsAdmin.get('updateError'),
    };
};

const mapDispatchToProps = {
    copyTemplate: copyTemplateChecklist,
    deleteTemplate: deleteTemplateChecklist,
    showConfirmationSimpleModal,
};

const ConnectedEditTitleMenu = ({
    copyTemplate,
    copyTemplateError,
    deleteError,
    deleting,
    deleteTemplate,
    disabled,
    setShowGlobalFormValidation,
    showConfirmationSimpleModal: showConfirmationSimpleModalFn,
    submitFailed,
    templateChecklist: {
        id,
        template: { isPublished },
    },
    templateChecklistAdminPath,
    title,
    toggleLive,
    updateChecklistTemplate,
    updateError,
    updating,
}) => {
    const navigate = useNavigate();

    const copyChecklistTemplate = () => {
        const copyHandler = () => {
            // Copy template and then route to copied template page
            copyTemplate(id, (result) => navigate(`${templateChecklistAdminPath}/${result.id}`));
        };

        showConfirmationSimpleModalFn(copyHandler, {
            btnText: 'Copy Template',
            bsStyle: 'primary',
            icon: 'copy',
            text: 'Create a copy of this template?',
        });
    };

    const deleteChecklistTemplate = () => {
        const deleteHandler = () => {
            // Delete template and then route back to template admin list page
            deleteTemplate(id, () => navigate(templateChecklistAdminPath, { replace: true }));
        };

        showConfirmationSimpleModalFn(deleteHandler, {
            btnText: 'Delete Template',
            text: 'Are you sure you want to delete this template?\nThis action cannot be undone.',
        });
    };

    const styles = require('./EditTitleMenu.scss');

    const publishIcon = isPublished ? 'ban' : 'play-circle';
    const deleteIcon = deleting ? 'spinner fa-spin' : 'trash';
    const saveIcon = updating ? 'spinner fa-spin' : 'check';

    return (
        <div className={styles.titleContainer}>
            <PageTitle title="Edit Checklist" />
            <div className="row">
                <div className="col-sm-7 col-md-8">
                    <h3 className={styles.title}>{title || 'Untitled'}</h3>
                    <TemplateStatusLabel className={styles.statusLabel} isPublished={isPublished} />
                </div>
                <div className="col-sm-5 col-md-4 text-right">
                    <DropdownButton
                        disabled={disabled}
                        id="template-project-more-button"
                        pullRight
                        title="More"
                    >
                        <MenuItem
                            onClick={() => setShowGlobalFormValidation(true)}
                            qaTag="checklistsAdminEdit-showFormErrors"
                        >
                            <i className="fa fa-fw fa-exclamation-triangle" /> Show Form Errors
                        </MenuItem>
                        <MenuItem
                            onClick={copyChecklistTemplate}
                            qaTag="checklistsAdminEdit-copyTemplate"
                        >
                            <i className="fa fa-fw fa-copy" /> Copy Template
                        </MenuItem>
                        <MenuItem
                            onClick={deleteChecklistTemplate}
                            qaTag="checklistsAdminEdit-delete"
                        >
                            <i className={`fa fa-fw fa-${deleteIcon}`} /> Delete
                        </MenuItem>
                    </DropdownButton>
                    &nbsp;&nbsp;
                    <Button
                        bsStyle={isPublished ? 'default' : 'success'}
                        disabled={disabled}
                        onClick={toggleLive}
                        qaTag={`checklistsAdminEdit-${isPublished ? 'disable' : 'live'}`}
                    >
                        <i className={`fa fa-${publishIcon}`} />
                        &nbsp;
                        {isPublished ? 'Disable' : 'Set Live'}
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                        bsStyle="primary"
                        disabled={disabled}
                        onClick={updateChecklistTemplate}
                        qaTag="checklistsAdminEdit-save"
                    >
                        <i className={`fa fa-fw fa-${saveIcon}`} /> Save
                    </Button>
                    <div>
                        <ConnectedClients className={styles.connectedClients} isChecklist />
                    </div>
                </div>
            </div>
            {updateError && <div className="error-block text-right">{updateError}</div>}
            {submitFailed && (
                <div className="error-block text-right">
                    Please fix form errors before submitting. Invalid sections are those without
                    check marks below.
                </div>
            )}
            {deleteError && <div className="error-block text-right">{deleteError}</div>}
            {copyTemplateError && <div className="error-block text-right">{copyTemplateError}</div>}
        </div>
    );
};

ConnectedEditTitleMenu.propTypes = {
    copyTemplate: PropTypes.func.isRequired,
    copyTemplateError: PropTypes.string,
    deleteError: PropTypes.string,
    deleteTemplate: PropTypes.func.isRequired,
    deleting: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    setShowGlobalFormValidation: PropTypes.func.isRequired,
    showConfirmationSimpleModal: PropTypes.func.isRequired,
    submitFailed: PropTypes.bool.isRequired,
    templateChecklist: PropTypes.shape({
        id: PropTypes.number.isRequired,
        questionnaires: PropTypes.array.isRequired,
        template: PropTypes.shape({
            icon: PropTypes.string,
            isPublished: PropTypes.bool.isRequired,
        }).isRequired,
    }).isRequired,
    templateChecklistAdminPath: PropTypes.string.isRequired,
    title: PropTypes.string,
    toggleLive: PropTypes.func.isRequired,
    updateError: PropTypes.string,
    updateChecklistTemplate: PropTypes.func.isRequired,
    updating: PropTypes.bool.isRequired,
};

export const EditTitleMenu = connect(mapStateToProps, mapDispatchToProps)(ConnectedEditTitleMenu);

import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { formValueSelector, arrayMove, arrayRemoveAll, arrayRemove } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';

import { defaultSectionConfigsMap, sectionTypeNames } from '@og-pro/shared-config/sections';

import { getIndexToChangeParentDivider } from '../../../../../../../helpers';
import { form, fieldNames } from '../../../../forms/constants';
import {
    fieldNames as formInputFieldNames,
    PARENT_DIVIDER,
} from '../../../../forms/TemplateProjectSectionsForm/constants';
import { fieldNames as formFieldNames } from '../../../../forms/TemplateForm/constants';
import { fieldNames as projectContentFormFieldNames } from '../../../../forms/ProjectContentForm/constants';
import {
    SectionHeaderCompleteForm,
    SectionHeaderTitleNumberForm,
} from '../../../../../../../components/SDv2';
import { showConfirmationSimpleModal } from '../../../../../../../actions/confirmation';
import { TemplateEditV2FunctionsContext } from '../../../context';

const {
    ATTACHMENTS,
    DIVIDER,
    EVALUATION_CRITERIA,
    EVALUATION_PHASE,
    INTRODUCTION,
    PRICING,
    QUESTIONNAIRE,
    SCOPE,
    TERMS,
    TEXT_AREA,
} = sectionTypeNames;
const { CRITERIA, EVALUATION_PHASES, PRICE_TABLES, QUESTIONNAIRES, SECTION_DESCRIPTIONS } =
    projectContentFormFieldNames;
const { PROJECT_SECTIONS } = fieldNames;
const { IS_WRITING_FORM, SECTION_TYPE } = formInputFieldNames;
const { USE_MANUAL_NUMBERING } = formFieldNames;

const selector = formValueSelector(form);

export const TemplateEditV2EditSection = ({
    afterCancel,
    afterDelete,
    afterSave,
    allowDeletion = false,
    index,
    showActionsOnFooter,
    showAutomationsButton,
}) => {
    const dispatch = useDispatch();
    const { change, getParentDividerOptions, isSpecial, isIntake, sortSections, templateProject } =
        useContext(TemplateEditV2FunctionsContext);
    const [originalData, setOriginalData] = React.useState(null);
    const sections = useSelector((state) => selector(state, PROJECT_SECTIONS));
    const useManualNumbering = useSelector((state) => selector(state, USE_MANUAL_NUMBERING));

    useEffect(() => {
        if (index !== null && sections[index]) {
            const parentDividerOptions = getParentDividerOptions();

            if (parentDividerOptions) {
                // if we have parent divider options it means we have manual numbers and dividers enabled
                // find out which is the parent divider of the current section
                // to make the "parent section" select work
                let parent = 0;

                for (let i = 0; i < parentDividerOptions.length; i += 1) {
                    if (parentDividerOptions[i].value > index) {
                        break;
                    }

                    parent = parentDividerOptions[i].value;
                }

                change(`${PROJECT_SECTIONS}[${index}].${PARENT_DIVIDER}`, parent);
                setOriginalData({
                    ...sections[index],
                    [PARENT_DIVIDER]: parent,
                });
            } else {
                // store the data as it was before editing so that we can "cancel"
                // and rollback
                setOriginalData(sections[index]);
            }
        }
    }, [index]);

    const onCancel = () => {
        change(`${PROJECT_SECTIONS}[${index}]`, originalData);

        if (afterCancel) {
            afterCancel();
        }
    };

    const onSave = () => {
        const localSortSections = () => {
            // when sections get resorted we will have a different index
            // so to keep the same section active we need to get this data
            setTimeout(() => {
                const reorderedSections = sortSections();
                const modifiedSectionNewIndex = reorderedSections.findIndex(
                    (s) => s.id === originalData.id
                );

                afterSave(modifiedSectionNewIndex);
            }, 0);
        };

        // if the parent divider changed we will need to reorder the elements
        // to move the element to be a child of the divider
        const parentDividerChanged =
            !Number.isNaN(sections[index][PARENT_DIVIDER]) &&
            sections[index][PARENT_DIVIDER] !== originalData[PARENT_DIVIDER];

        if (parentDividerChanged) {
            const newParentIndex = getIndexToChangeParentDivider(
                sections[index][PARENT_DIVIDER],
                sections.length
            );

            dispatch(arrayMove(form, PROJECT_SECTIONS, index, newParentIndex));

            // afterwards we sort because items need to be sorted by the manual number
            // within each divider
            localSortSections();
        } else if (useManualNumbering) {
            localSortSections();
        } else {
            afterSave(index);
        }
    };

    const clearField = (name) => {
        change(name, null);
    };

    const removeSection = () => {
        const projectSectionToRemove = sections[index];

        // Remove all section descriptions from form
        projectSectionToRemove.projectSubsections.forEach((projectSub) => {
            change(`${SECTION_DESCRIPTIONS}.${projectSectionToRemove.id}_${projectSub.id}`, null);
        });

        // Remove all associated content
        switch (projectSectionToRemove[SECTION_TYPE]) {
            case EVALUATION_PHASE:
                dispatch(arrayRemoveAll(form, EVALUATION_PHASES));
                break;
            case EVALUATION_CRITERIA: {
                // In the case there is also an `EVALUATION_PHASE` section, we'll keep the evaluation
                // phases around
                const hasEvaluationPhaseSection = sections.some((projectSection) => {
                    return projectSection[SECTION_TYPE] === EVALUATION_PHASE;
                });
                if (!hasEvaluationPhaseSection) {
                    dispatch(arrayRemoveAll(form, EVALUATION_PHASES));
                }
                break;
            }
            case PRICING:
                dispatch(arrayRemoveAll(form, PRICE_TABLES));
                break;
            case QUESTIONNAIRE:
                dispatch(arrayRemoveAll(form, QUESTIONNAIRES));
                break;
            case DIVIDER:
            case SCOPE:
            case TERMS:
            case TEXT_AREA: {
                projectSectionToRemove.projectSubsections.forEach((projectSubsection) => {
                    dispatch(
                        arrayRemoveAll(
                            form,
                            `${CRITERIA}.${projectSectionToRemove.id}_${projectSubsection.id}`
                        )
                    );
                });
                break;
            }
            default:
                break;
        }

        // Remove the section itself
        dispatch(arrayRemove(form, PROJECT_SECTIONS, index));

        if (afterDelete) {
            afterDelete();
        }
    };

    const onDelete = () => {
        dispatch(
            showConfirmationSimpleModal(() => removeSection(), {
                btnText: 'Delete Section',
                text:
                    'This will delete the section and all the content defined within the section. ' +
                    'Are you sure you want to delete this section?',
            })
        );
    };

    if (index === null || !sections) {
        return null;
    }

    const selectedSection = sections[index];

    if (!selectedSection) {
        return null;
    }

    const shouldNotBeDeleted = () => {
        if (!allowDeletion) {
            return true;
        }

        const sectionType = selectedSection[SECTION_TYPE];

        if (isIntake) {
            const intakeSections = [
                INTRODUCTION,
                ATTACHMENTS,
                PRICING,
                EVALUATION_CRITERIA,
                EVALUATION_PHASE,
            ];
            return !intakeSections.includes(sectionType);
        }

        return isSpecial || (index === 0 && sectionType === DIVIDER);
    };

    if (selectedSection.section_type === DIVIDER) {
        return (
            <SectionHeaderTitleNumberForm
                defaultSection={defaultSectionConfigsMap[selectedSection.section_type]}
                disabled={false}
                member={`${PROJECT_SECTIONS}[${index}]`}
                onCancel={onCancel}
                onDelete={shouldNotBeDeleted() ? null : onDelete}
                onSave={onSave}
                showActionsOnFooter={showActionsOnFooter}
                showValidation
                useManualNumbering={useManualNumbering}
            />
        );
    }

    const canHaveInstructions = (!isSpecial || isIntake) && selectedSection[IS_WRITING_FORM];
    const parentDividerOptions = getParentDividerOptions();

    return (
        <SectionHeaderCompleteForm
            canHaveInstructions={canHaveInstructions}
            defaultSection={defaultSectionConfigsMap[selectedSection.section_type]}
            disabled={false}
            formName={form}
            member={`${PROJECT_SECTIONS}[${index}]`}
            onCancel={onCancel}
            onDelete={shouldNotBeDeleted() ? null : onDelete}
            onDeleteInstructions={clearField}
            onSave={onSave}
            parentDividerOptions={parentDividerOptions}
            showActionsOnFooter={showActionsOnFooter}
            showAutomationsButton={showAutomationsButton}
            showShortnameInput
            showValidation
            templateProject={templateProject}
            useManualNumbering={useManualNumbering}
            values={selectedSection}
        />
    );
};

TemplateEditV2EditSection.propTypes = {
    allowDeletion: PropTypes.bool,
    afterSave: PropTypes.func,
    afterCancel: PropTypes.func,
    afterDelete: PropTypes.func,
    // index of the section we are editing
    index: PropTypes.number.isRequired,
    showActionsOnFooter: PropTypes.bool,
    showAutomationsButton: PropTypes.bool,
};

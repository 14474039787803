import { createSelector } from 'reselect';

const getAutoNumbers = (state) => state.admin.get('autoNumbers');

const getAutoNumbersJS = createSelector([getAutoNumbers], (autoNumbers) => {
    if (autoNumbers) {
        return autoNumbers.toJS();
    }
});

export const getProjectAutoNumber = createSelector([getAutoNumbersJS], (autoNumbers) => {
    if (autoNumbers) {
        return autoNumbers.projectAutoNumber;
    }
});

export const getContractAutoNumber = createSelector([getAutoNumbersJS], (autoNumbers) => {
    if (autoNumbers) {
        return autoNumbers.contractAutoNumber;
    }
});

export const getRequisitionAutoNumber = createSelector([getAutoNumbersJS], (autoNumbers) => {
    if (autoNumbers) {
        return autoNumbers.requisitionAutoNumber;
    }
});

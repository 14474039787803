import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { projectStatusesDict } from '@og-pro/shared-config/projects';
import { Box, Button, CircularProgress } from '@og-pro/ui';
import { useQuery } from '@tanstack/react-query';

import { showSnackbar } from '../../../../actions/notification';
import { createElectronicSignature } from '../../../../actions/project/electronicSignatures';

export const DocusignActions = ({ contractDocument }) => {
    const dispatch = useDispatch();
    const { error, isFetching, refetch } = useQuery({
        enabled: false,
        queryKey: ['CreateElectronicSignature', contractDocument.id],
        queryFn: () => dispatch(createElectronicSignature({ projectId: contractDocument.id })),
        retry: false,
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (error) {
            dispatch(
                showSnackbar(error?.message || 'Failed to create electronic signature', {
                    isError: true,
                })
            );
        }
    }, [dispatch, error]);

    // TODO PRO-6519: account for other docusign statuses based on the electronic signatures
    // record
    if (contractDocument.status !== projectStatusesDict.FINAL) {
        return null;
    }

    return (
        <Box>
            <Button
                color="primary"
                disabled={isFetching}
                onClick={(e) => {
                    refetch();
                    e.stopPropagation();
                }}
                qaTag="assembledDocuments-sendForSignature"
                variant="text"
            >
                Send for Signatures {isFetching && <CircularProgress size="small" />}
            </Button>
        </Box>
    );
};

DocusignActions.propTypes = {
    contractDocument: PropTypes.shape({
        id: PropTypes.number.isRequired,
        status: PropTypes.string.isRequired,
    }).isRequired,
};

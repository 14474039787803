import { get, pick } from 'lodash';

import { vendorProposalSocketActionType } from './constants';

export const JOIN = `${vendorProposalSocketActionType}/JOIN`;
export const LEAVE = `${vendorProposalSocketActionType}/LEAVE`;

export function joinVendorProposal(proposalId) {
    return (dispatch, getState) => {
        const user = getState().auth.get('user').toJS();
        const userData = {
            ...pick(user, ['avatarUrl', 'displayName', 'email', 'initials', 'isVendor']),
            organizationId: get(user, 'organization.id'),
            organizationName: get(user, 'organization.name'),
        };
        return dispatch({ type: JOIN, proposalId, user: userData });
    };
}

export function leaveVendorProposal(proposalId) {
    return { type: LEAVE, proposalId };
}

export const CONNECTED_CLIENT_COUNT = 'vendorProposalSocket/server/CONNECTED_CLIENT_COUNT';
export const SYNC_EVENT = 'vendorProposalSocket/server/SYNC_EVENT';

import PropTypes from 'prop-types';
import React from 'react';
import { AccordionDetails, Box, Checkbox, FormControlLabel, FormGroup } from '@og-pro/ui';

import { languagesOptions } from '@og-pro/shared-config/vendorLanguages';

import { accordionDetailsStyles } from './sharedStyles';

export const LanguagesAccordion = ({ formData, setFormData }) => {
    return (
        <AccordionDetails sx={accordionDetailsStyles}>
            <Box pb={1} px={1}>
                {languagesOptions.map(({ label, value }) => (
                    <FormGroup key={label}>
                        <FormControlLabel
                            checked={formData.languages.indexOf(value) > -1}
                            control={<Checkbox name={value} size="small" />}
                            label={label}
                            onChange={() => {
                                const currentIndex = formData.languages.indexOf(value);
                                const updatedLanguages = [...formData.languages];
                                if (currentIndex === -1) {
                                    updatedLanguages.push(value);
                                } else {
                                    updatedLanguages.splice(currentIndex, 1);
                                }

                                setFormData({
                                    ...formData,
                                    languages: updatedLanguages,
                                });
                            }}
                            sx={{ marginBottom: '0' }}
                        />
                    </FormGroup>
                ))}
            </Box>
        </AccordionDetails>
    );
};

LanguagesAccordion.propTypes = {
    formData: PropTypes.object.isRequired,
    setFormData: PropTypes.func.isRequired,
};

import PropTypes from 'prop-types';
import React from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';

import { TemplateListItem } from './TemplateListItem';
import { ZeroState } from '../..';

export const TemplateListBody = ({
    hasMultipleTypes,
    hasTemplates,
    routingHandler,
    TemplateCreateButton,
    templateItems,
}) => {
    const styles = require('./index.scss');

    if (templateItems.length === 0) {
        const props = {
            button: hasTemplates ? undefined : <TemplateCreateButton bsSize="lg" />,
            title: hasTemplates ? 'No templates here!' : 'No templates created yet',
        };
        return <ZeroState {...props} />;
    }

    return (
        <>
            <div className={`row ${styles.listHeader} hidden-xs hidden-sm`}>
                <div className="col-md-5">Template</div>
                <div className="col-md-3">Owner</div>
                <div className="col-md-2">Created</div>
                <div className="col-md-2">Last Updated</div>
            </div>
            <ListGroup>
                {templateItems.map((templateItem) => {
                    return (
                        <ListGroupItem
                            key={templateItem.id}
                            onClick={routingHandler(templateItem.id)}
                        >
                            <TemplateListItem
                                hasMultipleTypes={hasMultipleTypes}
                                templateItem={templateItem}
                            />
                        </ListGroupItem>
                    );
                })}
            </ListGroup>
        </>
    );
};

TemplateListBody.propTypes = {
    hasMultipleTypes: PropTypes.bool,
    hasTemplates: PropTypes.bool,
    routingHandler: PropTypes.func.isRequired,
    TemplateCreateButton: PropTypes.func.isRequired,
    templateItems: PropTypes.arrayOf(
        PropTypes.shape({
            created_at: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
            template: PropTypes.shape({
                icon: PropTypes.string,
                title: PropTypes.string,
                updated_at: PropTypes.string.isRequired,
            }).isRequired,
            type: PropTypes.string,
            updated_at: PropTypes.string.isRequired,
            user: PropTypes.shape({
                displayName: PropTypes.string.isRequired,
            }),
        })
    ).isRequired,
};

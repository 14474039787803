import React from 'react';
import { useDispatch } from 'react-redux';

import { hideEditTimelinesModal } from '../../../../actions/govProjects';
import { Dialog } from '../../../../components';
import { ProjectEditTimelinesForm } from './Form';

export const ProjectEditTimelinesModal = () => {
    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(hideEditTimelinesModal());
    };

    return (
        <Dialog closeButton fullWidth maxWidth="md" onClose={onClose} title="Timelines">
            <ProjectEditTimelinesForm />
        </Dialog>
    );
};

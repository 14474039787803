import { createSelector } from 'reselect';

const navItems = [
    {
        path: '',
        title: 'Templates',
    },
    {
        path: '/shared-sections',
        title: 'Shared Sections',
    },
    {
        path: '/shared-questions',
        title: 'Shared Questions',
    },
    {
        path: '/export-documents',
        title: 'Export Documents',
    },
];

const getSelectedNavItem = createSelector(
    [(state, props) => props.location.pathname],
    (pathname) => {
        return navItems.find((item) => !!item.path && pathname.match(item.path)) || navItems[0];
    }
);

export const getNavItems = createSelector([getSelectedNavItem], (selectedNavItem) => {
    return navItems.map((navItem) => {
        return {
            ...navItem,
            isActive: navItem.title === selectedNavItem.title,
        };
    });
});

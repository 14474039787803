import React from 'react';
import PropTypes from 'prop-types';

export const RetentionBadge = ({ loading, retentionCode, retentionCodeId }) => {
    const styles = require('./index.scss');
    if (loading) {
        return <i className="fa fa-fw fa-spin fa-spinner" />;
    }

    if (retentionCode && retentionCodeId) {
        return <span className={styles.retentionLabel}>{retentionCode.abbreviation}</span>;
    }
    return (
        <span aria-label="No Retention Policy" className={styles.muted}>
            No Retention Policy
        </span>
    );
};

RetentionBadge.propTypes = {
    loading: PropTypes.bool.isRequired,
    retentionCode: PropTypes.object,
    retentionCodeId: PropTypes.number,
};

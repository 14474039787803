import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';

const localizer = momentLocalizer(moment);

// Custom component for the event in calendar view so we can style it differently than the agenda
const Event = ({ event }) => {
    const navigate = useNavigate();

    const cellStyle = {
        backgroundColor: event.color,
        borderRadius: '5px',
        color: '#fff',
        cursor: 'pointer',
        fontSize: '12px',
        overflow: 'hidden',
        padding: '2px 5px',
        textOverflow: 'ellipsis',
        textRendering: 'geometricPrecision',
        whiteSpace: 'noWrap',
    };

    return (
        <div onClick={() => navigate(event.path)} style={cellStyle}>
            {event.title}
        </div>
    );
};

// Custom component for the agenda so we can style it differently than the event
const EventAgenda = ({ event }) => {
    const navigate = useNavigate();

    return (
        <span
            onClick={() => navigate(event.path)}
            style={{ color: event.color, cursor: 'pointer' }}
        >
            {event.title}
        </span>
    );
};

const BaseBigCalendar = (props) => {
    const { events, formats, getNow, onNavigate, onSelectEvent, onView, views } = props;

    return (
        <Calendar
            components={{
                eventWrapper: Event,
                agenda: {
                    event: EventAgenda,
                },
            }}
            endAccessor="end"
            events={events}
            formats={formats}
            getNow={getNow}
            localizer={localizer}
            onNavigate={onNavigate}
            onSelectEvent={onSelectEvent}
            onView={onView}
            popup
            startAccessor="start"
            views={views}
        />
    );
};

BaseBigCalendar.propTypes = {
    events: PropTypes.arrayOf(
        PropTypes.shape({
            end: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            start: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
            title: PropTypes.string.isRequired,
        })
    ).isRequired,
    formats: PropTypes.object,
    getNow: PropTypes.func,
    onNavigate: PropTypes.func,
    onSelectEvent: PropTypes.func,
    onView: PropTypes.func,
    views: PropTypes.arrayOf(PropTypes.oneOf(['month', 'week', 'work_week', 'day', 'agenda'])),
};

BaseBigCalendar.defaultProps = {
    formats: undefined,
    getNow: undefined,
    onNavigate: undefined,
    onSelectEvent: undefined,
    onView: undefined,
    views: undefined,
};

Event.propTypes = {
    event: PropTypes.shape({
        color: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
    }).isRequired,
};

EventAgenda.propTypes = {
    event: PropTypes.shape({
        color: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
    }).isRequired,
};

export const BigCalendar = React.memo(BaseBigCalendar);

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';

import { vendorFollowTypeOptions, form, NONE, TYPE } from './constants';
import { SearchSelect } from '../../../components';

const mapStateToProps = (state, props) => {
    const onSubmit = (formData) => {
        const data = formData[TYPE] === NONE ? {} : formData;
        props.onSubmit(data);
    };

    return {
        onSubmit, // `onSubmit` must be specified to form for remote submission to work
    };
};

const formConfig = {
    form,
    initialValues: { [TYPE]: NONE },
};

// @reduxForm
class ConnectedVendorFollowTypeForm extends Component {
    static propTypes = {
        disabled: PropTypes.bool,
        handleSubmit: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const { disabled, handleSubmit } = this.props;

        return (
            <form className={this.styles.container} onSubmit={handleSubmit}>
                <p className={`${this.styles.text} text-muted`}>
                    Follow to receive updates on this project
                </p>
                <Field
                    component={SearchSelect}
                    disabled={disabled}
                    label="Follower Designation"
                    name={TYPE}
                    options={vendorFollowTypeOptions}
                />
            </form>
        );
    }
}

export const VendorFollowTypeForm = compose(
    connect(mapStateToProps),
    reduxForm(formConfig)
)(ConnectedVendorFollowTypeForm);

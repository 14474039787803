import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useNavigate } from 'react-router-dom';

import connectData from '../../../ConnectData';
import { loadGovernmentRetentionCodes } from '../../../../actions/retention';
import { getGovernmentRetentionCodesJS } from '../../selectors';
import { getUserJS } from '../../../selectors';
import { PageTitle, ZeroState } from '../../../../components';
import { OrderablePolicyList } from './OrderablePolicyList';

const fetchRetentionCodes = (getState, dispatch) => {
    const governmentId = getState().auth.get('user').get('government_id');
    return dispatch(
        loadGovernmentRetentionCodes({
            governmentId,
        })
    );
};

const mapStateToProps = (state) => {
    return {
        retentionCodes: getGovernmentRetentionCodesJS(state),
        user: getUserJS(state),
    };
};

const mapDispatchToProps = {
    loadGovernmentRetentionCodes,
};

export const ConnectedPolicyList = ({ retentionCodes, user }) => {
    const navigate = useNavigate();

    /**
     * Navigates a user to the edit retention code page.
     * @param retentionCodeId ID of the retention code to be edited
     */
    const onEditPolicy = (retentionCodeId) => {
        navigate(
            `/governments/${user.government_id}/retention-admin/manage/policy/${retentionCodeId}`
        );
    };

    return (
        <>
            <PageTitle title="Manage Policies" />
            {!retentionCodes.length && (
                <ZeroState title="There are no current policies. Please add a retention policy to start." />
            )}
            <OrderablePolicyList onEditPolicy={onEditPolicy} retentionCodes={retentionCodes} />
        </>
    );
};

ConnectedPolicyList.propTypes = {
    retentionCodes: PropTypes.array.isRequired,
    user: PropTypes.shape({
        government_id: PropTypes.number.isRequired,
    }).isRequired,
};

export const PolicyList = compose(
    connectData(fetchRetentionCodes),
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedPolicyList);

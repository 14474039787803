import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';

import { LUMP_SUM } from './constants';
import { form } from '../constants';
import { getScopeCriteriaValues } from '../selectors';

const formSelector = formValueSelector(form);

export const getPriceTables = (state) => formSelector(state, 'priceTables') || [];

export const getSuggestedPricingSOW = createSelector([getScopeCriteriaValues], (scope) => {
    return scope
        .filter((item) => item.title)
        .map((item) => ({
            id: item.id,
            title: item.title,
            description: item.title,
            miscData: LUMP_SUM,
        }));
});

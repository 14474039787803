import { fieldNames } from '../../constants';
import { priceItemFieldNames } from '../../../../constants';

const { PRICE_ITEMS, PRICE_TABLE, VENDOR_NAME } = fieldNames;
const { CLIENT_VENDOR_ASSIGNMENT_UUID } = priceItemFieldNames;

const addVendorToPriceItem = (dispatch, change, priceItemIndex, vendor) => {
    dispatch(
        change(
            `${PRICE_TABLE}.${PRICE_ITEMS}[${priceItemIndex}].${CLIENT_VENDOR_ASSIGNMENT_UUID}`,
            vendor.vendorAssignmentUuid
        )
    );
    dispatch(
        change(`${PRICE_TABLE}.${PRICE_ITEMS}[${priceItemIndex}].${VENDOR_NAME}`, vendor.vendorName)
    );
};

const removeVendorFromPriceItem = (dispatch, change, priceItemIndex) => {
    dispatch(
        change(
            `${PRICE_TABLE}.${PRICE_ITEMS}[${priceItemIndex}].${CLIENT_VENDOR_ASSIGNMENT_UUID}`,
            null
        )
    );
    dispatch(change(`${PRICE_TABLE}.${PRICE_ITEMS}[${priceItemIndex}].${VENDOR_NAME}`, null));
};

export const updatePriceItemVendors = (
    dispatch,
    change,
    priceItems,
    associatedLineItems,
    vendor
) => {
    priceItems.forEach((priceItem, priceItemIndex) => {
        const isPriceItemAssociated = associatedLineItems.some(
            (associatedLineItem) => associatedLineItem.value === priceItem.vendorAssignmentUuid
        );

        if (
            isPriceItemAssociated &&
            priceItem.clientVendorAssignmentUuid !== vendor.vendorAssignmentUuid
        ) {
            addVendorToPriceItem(dispatch, change, priceItemIndex, vendor);
        }

        if (
            !isPriceItemAssociated &&
            priceItem.clientVendorAssignmentUuid === vendor.vendorAssignmentUuid
        ) {
            removeVendorFromPriceItem(dispatch, change, priceItemIndex);
        }
    });
};

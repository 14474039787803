import PropTypes from 'prop-types';
import React from 'react';

import { CertificationForm } from './CertificationForm';
import { DisplayCertification } from '../../../Display';

export const Certification = ({
    addingCertification,
    certification,
    editing,
    form,
    index,
    setEditing,
    setShowAddCertification,
    user,
}) => {
    if (!certification.vendorCertification?.vendor_id || editing) {
        return (
            <CertificationForm
                certification={certification}
                form={form}
                initialValues={certification}
                setEditing={setEditing}
                setShowAddCertification={setShowAddCertification}
                showAddCertification={!certification.vendorCertification?.vendor_id}
                user={user}
            />
        );
    }

    return (
        <DisplayCertification
            addingCertification={addingCertification}
            certification={certification}
            index={index}
            loading={certification.loading}
            onEditCertification={() => {
                setEditing(index);
                setShowAddCertification(false);
            }}
        />
    );
};

Certification.propTypes = {
    addingCertification: PropTypes.bool,
    certification: PropTypes.object,
    editing: PropTypes.bool,
    form: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    setEditing: PropTypes.func.isRequired,
    setShowAddCertification: PropTypes.func.isRequired,
    user: PropTypes.shape({
        vendor: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }),
    }).isRequired,
};

import { contactFieldNames, mappedContactFields } from '@og-pro/shared-config/contacts';

import { address, email, phone } from '.';
import { REQUIRED_TEXT, REQUIRED_CONTACT_TEXT } from './constants';
import { hasFormErrors } from '../../helpers';

const { EMAIL, FIRST_NAME, LAST_NAME, TITLE } = contactFieldNames;

export function contact(
    values,
    opts = {
        fieldNames: mappedContactFields,
        idFieldName: 'contact_id',
        optional: false,
        skipIdFieldError: false,
    }
) {
    const { fieldNames, idFieldName, optional, skipIdFieldError } = opts;

    const errors = {
        ...email(values, fieldNames[EMAIL], { optional }),
        ...phone(values, { fieldNames, optional }),
        ...address(values, { fieldNames, optional }),
    };

    const firstNameFieldName = fieldNames[FIRST_NAME];
    const lastNameFieldName = fieldNames[LAST_NAME];
    const titleFieldName = fieldNames[TITLE];

    if (!optional && !values[idFieldName]) {
        errors[idFieldName] = REQUIRED_TEXT;
    }

    if (!optional && !values[firstNameFieldName]) {
        errors[firstNameFieldName] = REQUIRED_TEXT;
    } else if (values[firstNameFieldName] && values[firstNameFieldName].length > 32) {
        errors[firstNameFieldName] = 'Name is too long';
    }

    if (!optional && !values[lastNameFieldName]) {
        errors[lastNameFieldName] = REQUIRED_TEXT;
    } else if (values[lastNameFieldName] && values[lastNameFieldName].length > 32) {
        errors[lastNameFieldName] = 'Name is too long';
    }

    if (!optional && !values[titleFieldName]) {
        errors[titleFieldName] = REQUIRED_TEXT;
    }

    // If there are no id field errors, but there are other contact errors, we want to mark the id
    // field as invalid, so the error is displayed outside the contact modal.
    if (!errors[idFieldName] && hasFormErrors(errors) && !skipIdFieldError) {
        errors[idFieldName] = REQUIRED_CONTACT_TEXT;
    }

    return errors;
}

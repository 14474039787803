import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { TemplateSectionCreateForm } from './TemplateSectionCreateForm';
import { PROJECT_TYPE, USER_ID } from './TemplateSectionCreateForm/constants';
import { getUserJS } from '../../../selectors';
import { createTemplateSection } from '../../../../actions/templateSections';

export const TemplateSectionCreateModal = ({
    hideModal,
    projectTypeSelectOptions,
    routingHandler,
    selectedProjectType,
}) => {
    const [creating, setCreating] = useState(false);
    const [createError, setCreateError] = useState(null);

    const dispatch = useDispatch();
    const user = useSelector(getUserJS);

    const onSubmit = (formValues) => {
        setCreating(true);
        setCreateError(null);
        return dispatch(createTemplateSection(formValues))
            .then((result) => {
                routingHandler(result.id)();
            })
            .catch((error) => {
                setCreating(false);
                setCreateError(error.message);
            });
    };

    const projectTypeOptions = projectTypeSelectOptions.filter((option) => option.value !== 'all');

    let projectType;
    if (projectTypeOptions.length === 1) {
        projectType = [projectTypeOptions[0].value];
    } else if (selectedProjectType && selectedProjectType !== 'all') {
        projectType = selectedProjectType;
    }

    return (
        <Modal onHide={hideModal} show>
            <Modal.Header closeButton>
                <Modal.Title className="text-center">Create Shared Section</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-8 col-md-offset-2">
                        <TemplateSectionCreateForm
                            createError={createError}
                            creating={creating}
                            initialValues={{
                                [PROJECT_TYPE]: projectType,
                                [USER_ID]: user.id,
                            }}
                            onSubmit={onSubmit}
                            projectTypeSelectOptions={projectTypeOptions}
                        />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

TemplateSectionCreateModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    projectTypeSelectOptions: PropTypes.array.isRequired,
    routingHandler: PropTypes.func.isRequired,
    selectedProjectType: PropTypes.string,
};

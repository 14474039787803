import classNames from 'classnames';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@og-pro/ui';

import { questionTypesDisplayMap } from '@og-pro/shared-config/questionnaires';

import { getSectionNumberingString } from '@og-pro/shared-config/helpers';

import { ControlButtons } from './ControlButtons';
import { QuestionLogicDisplay } from '../../../../../QuestionLogicDisplay';
import { SharedQuestionLabel } from '../SharedQuestionLabel';
import { Button, DragIcon, HtmlBlurb, QuestionnaireResponseForm } from '../../../../..';

import { OGThemeContext } from '../../../../../../containers/GovApp/ogThemeProvider';
import { ContentBlock } from '../../../../../SDv2/ContentBlock';
import { DROPPABLE_PARENT_TYPE, DROPPABLE_CHILDREN_TYPE } from '../constants';

export class QuestionnaireListItem extends PureComponent {
    static propTypes = {
        draggableProvided: PropTypes.object.isRequired,
        disabled: PropTypes.bool,
        hasError: PropTypes.bool,
        index: PropTypes.number.isRequired,
        insertQuestion: PropTypes.func.isRequired,
        isLastQuestion: PropTypes.bool,
        isTemplate: PropTypes.bool,
        isTemplateQuestion: PropTypes.bool,
        moveQuestionDown: PropTypes.func.isRequired,
        moveQuestionUp: PropTypes.func.isRequired,
        onClick: PropTypes.func.isRequired,
        questionLogicIcon: PropTypes.node,
        questionnaire: PropTypes.shape({
            conditionalSubQuestionIds: PropTypes.arrayOf(PropTypes.number),
            conditionalSubQuestionNumber: PropTypes.number,
            containsPricing: PropTypes.bool,
            isConditionalSubQuestion: PropTypes.bool,
            isPublic: PropTypes.bool,
            isRequired: PropTypes.bool,
            isTitle: PropTypes.bool,
            orderById: PropTypes.number,
            prompt: PropTypes.string,
            questionLogic: PropTypes.object,
            rawPrompt: PropTypes.string,
            sectionNumber: PropTypes.number.isRequired,
            subsectionNumber: PropTypes.number.isRequired,
            title: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            attachments: PropTypes.array,
            data: PropTypes.object,
        }).isRequired,
        remove: PropTypes.func.isRequired,
        useRawPrompt: PropTypes.bool,
    };

    static defaultProps = {
        useRawPrompt: false,
    };

    static contextType = OGThemeContext;

    get sectionNumbering() {
        const {
            questionnaire: { conditionalSubQuestionNumber, sectionNumber, subsectionNumber },
        } = this.props;

        return getSectionNumberingString({
            sectionNumber,
            subsectionNumber,
            subSectionItemNumber: conditionalSubQuestionNumber,
            useLetterForSubsectionItem: false,
        });
    }

    get styles() {
        return require('./index.scss');
    }

    clickHandler = (event) => {
        const { disabled, index, isTemplateQuestion, onClick, questionnaire } = this.props;

        event.stopPropagation();
        if (disabled || isTemplateQuestion) {
            return;
        }
        onClick(index, questionnaire);
    };

    renderTitle() {
        const { isOGThemeEnabledForComponents } = this.context;
        const {
            questionnaire: { containsPricing, isPublic, isRequired, isTitle, title },
        } = this.props;

        const titleBody = (
            <>
                {this.sectionNumbering} {title}
                {isRequired && !isTitle && '*'}
                {containsPricing && !isTitle && ' ($)'}
                {isPublic && !isTitle && (
                    <span>
                        &nbsp;
                        <i className="fa fa-bullhorn" />
                    </span>
                )}
            </>
        );

        if (isOGThemeEnabledForComponents) {
            return <h3 className={this.styles.title}>{titleBody}</h3>;
        }

        return <div className={this.styles.title}>{titleBody}</div>;
    }

    renderPromptBlurb() {
        const {
            questionnaire: { prompt, rawPrompt },
            useRawPrompt,
        } = this.props;

        const promptValue = useRawPrompt ? rawPrompt : prompt;

        if (!promptValue) {
            return null;
        }

        return <HtmlBlurb content={promptValue} />;
    }

    render() {
        const { isOGThemeEnabledForComponents } = this.context;
        const {
            disabled,
            draggableProvided,
            hasError,
            index,
            insertQuestion,
            isLastQuestion,
            isTemplate,
            isTemplateQuestion,
            moveQuestionDown,
            moveQuestionUp,
            questionLogicIcon,
            questionnaire,
            questionnaire: { isConditionalSubQuestion, isTitle, questionLogic, type },
            remove,
        } = this.props;
        const questionType = questionTypesDisplayMap[type];

        const controlButtons = (
            <ControlButtons
                disabled={disabled}
                draggableProvided={draggableProvided}
                index={index}
                insertQuestion={insertQuestion}
                questionLogicIcon={questionLogicIcon}
                questionnaire={questionnaire}
                remove={remove}
            />
        );

        if (isOGThemeEnabledForComponents) {
            const onRemoveQuestionnaire = (e) => {
                e.stopPropagation();

                if (disabled || isTemplate) return;

                remove(index);
            };
            let attachments = questionnaire.attachments || [];
            // when we edit the question and add / remove an attachment
            // the information is stored in data.attachments
            // We read the attachments from questionnaire.attachments
            // so with this piece of code we keep that array up to date with whatever we modified
            if (questionnaire.data?.attachments?.length) {
                attachments = questionnaire.data?.attachments.map((formDataAttachment) => {
                    const matching = (questionnaire.attachments || []).find(
                        (a) => a.path === formDataAttachment.path
                    );

                    if (matching) {
                        return matching;
                    }

                    return formDataAttachment;
                });
            }

            return (
                <Box ml={questionnaire.isConditionalSubQuestion ? 4 : 0}>
                    <ContentBlock className={this.styles.contentBlock} withActions>
                        <ContentBlock.Main onClick={this.clickHandler}>
                            {questionLogic && (
                                <QuestionLogicDisplay
                                    questionLogic={questionLogic}
                                    useOpenGovStyle
                                />
                            )}
                            <QuestionnaireResponseForm
                                {...{ ...questionnaire, attachments }}
                                disabled
                                isTemplateReadonlyView
                                showValidation={false}
                                useRawPrompt
                            />
                        </ContentBlock.Main>
                        <ContentBlock.ActionSidebar>
                            {!isTemplate && (
                                <ContentBlock.ButtonGroup>
                                    {index !== 0 &&
                                        (!questionnaire.isConditionalSubQuestion ||
                                            (questionnaire.isConditionalSubQuestion &&
                                                questionnaire.conditionalSubQuestionNumber >
                                                    1)) && (
                                            <ContentBlock.Button>
                                                <Button
                                                    bsStyle="link"
                                                    onClick={() =>
                                                        moveQuestionUp(
                                                            index,
                                                            questionnaire.isConditionalSubQuestion
                                                                ? DROPPABLE_CHILDREN_TYPE
                                                                : DROPPABLE_PARENT_TYPE
                                                        )
                                                    }
                                                >
                                                    <i
                                                        aria-hidden="true"
                                                        className="fa fa-arrow-up"
                                                    />
                                                </Button>
                                            </ContentBlock.Button>
                                        )}
                                    <ContentBlock.Button>
                                        <DragIcon
                                            dragHandleProps={draggableProvided.dragHandleProps}
                                        />
                                    </ContentBlock.Button>
                                    {!isLastQuestion && (
                                        <ContentBlock.Button>
                                            <Button
                                                bsStyle="link"
                                                onClick={() =>
                                                    moveQuestionDown(
                                                        index,
                                                        questionnaire.isConditionalSubQuestion
                                                            ? DROPPABLE_CHILDREN_TYPE
                                                            : DROPPABLE_PARENT_TYPE
                                                    )
                                                }
                                            >
                                                <i
                                                    aria-hidden="true"
                                                    className="fa fa-arrow-down"
                                                />
                                            </Button>
                                        </ContentBlock.Button>
                                    )}
                                </ContentBlock.ButtonGroup>
                            )}

                            {!!questionLogicIcon && !isConditionalSubQuestion && (
                                <ContentBlock.ButtonGroup>
                                    <ContentBlock.Button>
                                        <div>{questionLogicIcon}</div>
                                    </ContentBlock.Button>
                                    {
                                        /* if the whole thing is isTemplate we are not showing the previous ButtonGroup. But we sill need the drag handle for react-dnd not to break
                                        rendering here to avoid having an "empty" ContentBlock.ButtonGroup */
                                        isTemplate && (
                                            <DragIcon
                                                disabled
                                                dragHandleProps={draggableProvided.dragHandleProps}
                                            />
                                        )
                                    }
                                </ContentBlock.ButtonGroup>
                            )}
                            {!isTemplate && (
                                <ContentBlock.ButtonGroup>
                                    <ContentBlock.Button>
                                        <Button bsStyle="link" onClick={onRemoveQuestionnaire}>
                                            <i aria-hidden="true" className="fa fa-trash" />
                                        </Button>
                                    </ContentBlock.Button>
                                </ContentBlock.ButtonGroup>
                            )}
                        </ContentBlock.ActionSidebar>
                    </ContentBlock>
                </Box>
            );
        }

        // Render section headers differently
        if (isTitle) {
            return (
                <div
                    className={`${this.styles.sectionHeader} ${this.styles.disabledListItem} ${
                        questionnaire.isConditionalSubQuestion ? this.styles.subheader : ''
                    }`}
                    onClick={this.clickHandler}
                >
                    {questionLogic && <QuestionLogicDisplay questionLogic={questionLogic} />}
                    {controlButtons}
                    {this.renderTitle()}
                    {this.renderPromptBlurb()}
                    <div className="clearfix" />
                </div>
            );
        }

        // NOTE: `ref` cannot be passed to `react-bootstrap` components at this time, so we must
        // manually set up the `<ListGroupItem>` component (<button> because `onClick` is used).
        return (
            <div
                className={classNames(
                    'list-group-item',
                    this.styles.listItem,
                    isConditionalSubQuestion && this.styles.conditional,
                    hasError && this.styles.error,
                    isTemplateQuestion && this.styles.notEditable,
                    disabled && this.styles.disabledListItem
                )}
                onClick={this.clickHandler}
            >
                {controlButtons}
                {questionLogic && <QuestionLogicDisplay questionLogic={questionLogic} />}
                {this.renderTitle()}
                {this.renderPromptBlurb()}
                <div className={`text-muted ${this.styles.questionType}`}>
                    <i className={`fa fa-${questionType.icon}`} />
                    &nbsp;&nbsp;
                    {questionType.title}
                </div>
                {isTemplateQuestion && (
                    <SharedQuestionLabel
                        includeLink
                        small
                        style={{ marginTop: 3 }}
                        upfrontQuestion={questionnaire}
                    />
                )}
                <div className="clearfix" />
            </div>
        );
    }
}

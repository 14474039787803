const { listToDict } = require('../helpers');

exports.MAX_NUM_APPROVAL_WORKFLOW_STEPS = 5;

exports.approvalStatuses = ['pending', 'approved', 'rejected'];

exports.approvalStatusesDict = listToDict(exports.approvalStatuses);

exports.approvalWorkflowTypes = ['builder', 'intake', 'contract'];

exports.approvalWorkflowTypesDict = listToDict(exports.approvalWorkflowTypes);

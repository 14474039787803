import { listToDict } from '@og-pro/shared-config/helpers';

export const form = 'projectDetailProjectSectionForm';

const fields = [
    'isDisplayed', // Inverse of `isHidden`. Used in place of `isHidden` to make a better toggle UI.
    'isHidden',
    'hideContact',
    'hideContactAddress',
    'hideProcurementContact',
    'hideTimeline',
    'orderById',
    'projectSections',
    'title',
];

export const fieldNames = listToDict(fields);

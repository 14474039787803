import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    getIsGroupAdminOrLeader,
    getIsLoading,
    getIsSearching,
    getLoadError,
    getRequisitionsSummaryJS,
} from './selectors';
import { LoadingError, LoadingSpinner } from '../../../../components';
import { RequisitionsDashboardVisualizations } from './Visualizations';
import { loadRequisitionsSummary } from '../../../../actions/requisitionsDashboard';
import { useIsMounted } from '../../../../hooks';

export const RequisitionsDashboardSummary = () => {
    const dispatch = useDispatch();

    const isMounted = useIsMounted();
    const isSearching = useSelector(getIsSearching);

    const isLoading = useSelector(getIsLoading);
    const loadError = useSelector(getLoadError);
    const isAdminOrLeader = useSelector((state) => getIsGroupAdminOrLeader(state));
    const requisitionsSummary = useSelector(getRequisitionsSummaryJS);

    useEffect(() => {
        dispatch(loadRequisitionsSummary());
    }, []);

    const isDoubleLoading = isLoading && isSearching;

    if (!isAdminOrLeader || isDoubleLoading || !isMounted()) {
        return null;
    }

    if (isLoading) {
        return <LoadingSpinner noPadding useOpenGovStyle />;
    }

    if (loadError || !requisitionsSummary) {
        return <LoadingError error={loadError} useOpenGovStyle />;
    }

    return <RequisitionsDashboardVisualizations requisitionsSummary={requisitionsSummary} />;
};

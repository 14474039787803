import PropTypes from 'prop-types';
import React from 'react';
import { Box } from '@og-pro/ui';

import { CDSButton } from '../../../../components';
import { Dropzone } from './DocxTemplateForm/Dropzone';

export const UploadBox = ({ labelId, updating, dropHandler, success }) => {
    const styles = require('./shared.scss');

    // NOTE: application/vnd.openxmlformats-officedocument.wordprocessingml.document does not seem to work on Windows machines
    return (
        <Box className={styles.uploadBox}>
            <Dropzone dropHandler={dropHandler} labelId={labelId} updating={updating}>
                <Box alignItems="center" display="flex" justifyContent="space-between">
                    <Box alignItems="center" display="flex">
                        <Box mr={2}>
                            <CDSButton
                                onClick={(e) => e.preventDefault()}
                                qaTag="wordTemplates-upload"
                            >
                                <i className="fa fa-upload" /> Upload Word Doc
                            </CDSButton>
                        </Box>
                        <Box className={styles.maxSize}>Max File Size: 1 MB</Box>
                    </Box>
                    {success && (
                        <Box className={styles.success}>
                            Upload Successful <i className="fa fa-check-circle" />
                        </Box>
                    )}
                </Box>
            </Dropzone>
        </Box>
    );
};

UploadBox.propTypes = {
    dropHandler: PropTypes.func.isRequired,
    labelId: PropTypes.string,
    success: PropTypes.bool,
    updating: PropTypes.bool,
};

import { serializeProjectItemDescriptionField } from './serializers';
import { showSnackbar } from '../notification';
import { emitProjectSocket } from '../utils';

export const ADD = 'gov/sections/ADD';
export const ADD_SUCCESS = 'gov/sections/ADD_SUCCESS';
export const ADD_FAIL = 'gov/sections/ADD_FAIL';

export function sectionDescriptionCreate(projectSectionId, projectSubsectionId) {
    return (dispatch, getState, client) => (postData) => {
        const projectId = getState().govProjects.getIn(['selectedProject', 'id']);
        const data = {
            project_section_id: projectSectionId,
            project_subsection_id: projectSubsectionId,
            ...postData,
        };
        const miscData = { projectSectionId, projectSubsectionId };

        const serializedData = serializeProjectItemDescriptionField(data);

        dispatch({ type: ADD, ...miscData });
        return client
            .post(`/project/${projectId}/section-description`, { data: serializedData })
            .then((result) => {
                const createAction = { type: ADD_SUCCESS, result, ...miscData };
                dispatch(emitProjectSocket(projectId, createAction));
                dispatch(showSnackbar('Item added'));
            })
            .catch((error) => dispatch({ type: ADD_FAIL, error, ...miscData }));
    };
}

export const UPDATE = 'gov/sections/UPDATE';
export const UPDATE_SUCCESS = 'gov/sections/UPDATE_SUCCESS';
export const UPDATE_FAIL = 'gov/sections/UPDATE_FAIL';

export function sectionDescriptionUpdate(sectionDescription) {
    return (dispatch, getState, client) => (data) => {
        const { id, project_id: projectId } = sectionDescription;

        const serializedData = serializeProjectItemDescriptionField(data);

        dispatch({ type: UPDATE, id });
        return client
            .put(`/project/${projectId}/section-description/${id}`, { data: serializedData })
            .then((result) => {
                const updateAction = { type: UPDATE_SUCCESS, result, id };
                dispatch(emitProjectSocket(projectId, updateAction));
                dispatch(showSnackbar('Item updated'));
            })
            .catch((error) => dispatch({ type: UPDATE_FAIL, error, id }));
    };
}

export const DELETE = 'gov/sections/DELETE';
export const DELETE_SUCCESS = 'gov/sections/DELETE_SUCCESS';
export const DELETE_FAIL = 'gov/sections/DELETE_FAIL';

export function sectionDescriptionDelete(sectionDescription) {
    return (dispatch, getState, client) => {
        const { id, project_id: projectId } = sectionDescription;

        dispatch({ type: DELETE, id });
        return client
            .del(`/project/${projectId}/section-description/${id}`)
            .then(() => {
                const deleteAction = { type: DELETE_SUCCESS, id };
                dispatch(emitProjectSocket(projectId, deleteAction));
                dispatch(showSnackbar('Item deleted'));
            })
            .catch((error) => dispatch({ type: DELETE_FAIL, error, id }));
    };
}

export const TOGGLE_FORM = 'gov/sections/TOGGLE_FORM';

export function sectionDescriptionToggleForm(show, projectSectionId, projectSubsectionId) {
    return { type: TOGGLE_FORM, show, projectSectionId, projectSubsectionId };
}

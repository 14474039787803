import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { invalidSaveTooltip } from './constants';
import { ActionToolbarNavbarItem } from '../../../../components';

export class SaveButton extends PureComponent {
    static propTypes = {
        clickHandler: PropTypes.func.isRequired,
        disabled: PropTypes.bool.isRequired,
        isEditor: PropTypes.bool.isRequired,
        isSaveValid: PropTypes.bool.isRequired,
        saving: PropTypes.bool.isRequired,
    };

    render() {
        const { clickHandler, disabled, isEditor, isSaveValid, saving } = this.props;

        const showPopover = !isSaveValid;
        const icon = saving ? 'spinner fa-spin' : 'save';
        const styles = require('../ProjectActionToolbar.scss');
        return (
            <ActionToolbarNavbarItem
                className="action-toolbar-save-btn"
                disabled={disabled}
                editOnly
                isEditor={isEditor}
                onClick={clickHandler}
                qaTag="projectActionMenu-save"
                tooltip={showPopover ? invalidSaveTooltip : undefined}
                tooltipTitle={showPopover ? 'Cannot Save Project' : undefined}
            >
                <i className={`fa fa-${icon} fa-fw ${styles.saveIcon}`} /> Save
            </ActionToolbarNavbarItem>
        );
    }
}

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { reduxForm, Field } from 'redux-form';

import { form, SELECTED_SAVED_FILTER } from './constants';
import { SearchSelect } from '../../..';

const formConfig = {
    form,
};

// @reduxForm
class ConnectedSavedFilterSelectForm extends PureComponent {
    static propTypes = {
        formClassName: PropTypes.string,
        options: PropTypes.array.isRequired,
    };

    render() {
        const { formClassName, options } = this.props;

        return (
            <Field
                component={SearchSelect}
                formClassName={formClassName}
                name={SELECTED_SAVED_FILTER}
                options={options}
                overrideFeedback
                useNullWhenUndefined
            />
        );
    }
}

export const SavedFilterSelectForm = reduxForm(formConfig)(ConnectedSavedFilterSelectForm);

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams, useSearchParams } from 'react-router-dom';

import { TemplateSectionCreateButton } from './TemplateSectionCreateButton';
import { TemplateSectionsList as TemplateSectionsListComponent } from './TemplateSectionsList';
import {
    getNavItems,
    getSelectedNavItem,
    getSelectedProjectType,
    getSelectedTemplateSections,
    getTemplateSections,
    getTemplateSectionSelectOptions,
} from './selectors';
import { TemplateListSelectForm } from '../components/TemplateListSelectForm';
import { getTemplatesAdminPath, getTemplateSectionsPath } from '../selectors';
import connectData from '../../../ConnectData';
import {
    loadTemplateSectionsList,
    resetTemplateSectionsList,
} from '../../../../actions/templateSections';
import {
    LoadingError,
    LoadingSpinner,
    Main,
    PageTitle,
    TemplateListNav,
} from '../../../../components';

function fetchData(getState, dispatch) {
    if (getState().templateSections.get('loadedList')) {
        return Promise.resolve();
    }
    return dispatch(loadTemplateSectionsList());
}

// @connectData
const ConnectedTemplateSectionsList = () => {
    const baseLocation = useLocation();
    const params = useParams();
    const [searchParams] = useSearchParams();
    const query = Object.fromEntries(searchParams);
    const location = { ...baseLocation, query };

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.templateSections.get('loadingList'));
    const loadError = useSelector((state) => state.templateSections.get('loadListError'));
    const templateSectionsPath = useSelector(() => getTemplateSectionsPath({ location, params }));
    const templatesAdminPath = useSelector((state) =>
        getTemplatesAdminPath(state, { location, params })
    );
    const allTemplateSections = useSelector(getTemplateSections);
    const navItems = useSelector((state) => getNavItems(state, { location, params }));
    const selectedNavItem = useSelector((state) => getSelectedNavItem(state, { location, params }));
    const selectedProjectType = useSelector((state) =>
        getSelectedProjectType(state, { location, params })
    );
    const projectTypeSelectOptions = useSelector(getTemplateSectionSelectOptions);
    const selectedTemplateSections = useSelector((state) =>
        getSelectedTemplateSections(state, { location, params })
    );

    useEffect(() => {
        return () => dispatch(resetTemplateSectionsList());
    }, [dispatch]);

    if (loading) {
        return <LoadingSpinner />;
    }

    if (loadError) {
        return <LoadingError error={loadError} />;
    }

    const routeToTemplateSection = (templateSectionId) => () => {
        navigate(`${templateSectionsPath}/${templateSectionId}`);
    };

    const SelectFilter = (
        <TemplateListSelectForm
            location={location}
            projectTypeSelectOptions={projectTypeSelectOptions}
            selectedProjectType={selectedProjectType}
        />
    );

    const TemplateSectionCreateButtonFn = (
        { bsSize } // eslint-disable-line react/prop-types
    ) => (
        <TemplateSectionCreateButton
            bsSize={bsSize}
            projectTypeSelectOptions={projectTypeSelectOptions}
            routingHandler={routeToTemplateSection}
            selectedProjectType={selectedProjectType}
        />
    );

    const hasNoCreatedSections = allTemplateSections.length === 0;

    return (
        <Main>
            <PageTitle title="Shared Sections" />
            <TemplateListNav
                SelectFilter={SelectFilter}
                TemplateCreateButton={TemplateSectionCreateButtonFn}
                location={location}
                navItems={navItems}
                selectedNavItem={selectedNavItem}
                title="Shared Sections"
            />
            <TemplateSectionsListComponent
                TemplateCreateButton={TemplateSectionCreateButtonFn}
                hasNoCreatedSections={hasNoCreatedSections}
                routingHandler={routeToTemplateSection}
                templateSections={selectedTemplateSections}
                templatesAdminPath={templatesAdminPath}
            />
        </Main>
    );
};

export const TemplateSectionsList = connectData(fetchData)(ConnectedTemplateSectionsList);

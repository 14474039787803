import { fromJS } from 'immutable';

import { timelineDatesDict } from '@og-pro/shared-config/timelines';

import * as rsvpsActions from '../actions/rsvps';

const initialState = fromJS({
    confirmingRsvp: false,
    confirmingRsvpError: null,
    loaded: false,
    loadError: null,
    loading: false,
    rsvpingVendor: false,
    timelinesWithRsvps: [],
    togglingRsvpEnded: false,
    vendorRsvps: [],
});

export default function rsvpsReducer(state = initialState, action = {}) {
    switch (action.type) {
        case rsvpsActions.CONFIRM_RSVP:
            return state.merge(
                fromJS({
                    confirmingRsvp: true,
                    confirmingRsvpError: null,
                })
            );
        case rsvpsActions.CONFIRM_RSVP_FAIL:
            return state.merge(
                fromJS({
                    confirmingRsvp: false,
                    confirmingRsvpError: action.error && action.error.message,
                })
            );
        case rsvpsActions.CONFIRM_RSVP_SUCCESS:
            return state
                .set('confirmingRsvp', false)
                .set('confirmingRsvpError', null)
                .set(
                    'timelinesWithRsvps',
                    state.get('timelinesWithRsvps').map((timeline) => {
                        const timelineId = timeline.get('id');
                        if (
                            action.result.timeline_id === timelineId ||
                            (!action.result.timeline_id &&
                                timelineId === timelineDatesDict.PRE_PROPOSAL_DATE)
                        ) {
                            const vendorRsvps = timeline.get('vendorRsvps').map((rsvp) => {
                                if (action.result.id === rsvp.get('id')) {
                                    return fromJS(action.result);
                                }
                                return rsvp;
                            });
                            return timeline.set('vendorRsvps', vendorRsvps);
                        }
                        return timeline;
                    })
                );
        case rsvpsActions.CREATE_GOV_ADDED_VENDOR_RSVP:
        case rsvpsActions.CREATE_VENDOR_RSVP:
            return state.merge(
                fromJS({
                    rsvpingVendor: true,
                })
            );
        case rsvpsActions.CREATE_GOV_ADDED_VENDOR_RSVP_SUCCESS:
            return state.set('rsvpingVendor', false).set(
                'timelinesWithRsvps',
                state.get('timelinesWithRsvps').map((timeline) => {
                    const timelineId = timeline.get('id');
                    if (
                        action.result.timeline_id === timelineId ||
                        (!action.result.timeline_id &&
                            timelineId === timelineDatesDict.PRE_PROPOSAL_DATE)
                    ) {
                        return timeline.set(
                            'vendorRsvps',
                            timeline.get('vendorRsvps').push(fromJS(action.result))
                        );
                    }
                    return timeline;
                })
            );
        case rsvpsActions.CREATE_VENDOR_RSVP_SUCCESS:
            return state
                .set('rsvpingVendor', false)
                .set('vendorRsvps', state.get('vendorRsvps').push(fromJS(action.result)));
        case rsvpsActions.CREATE_GOV_ADDED_VENDOR_RSVP_FAIL:
        case rsvpsActions.CREATE_VENDOR_RSVP_FAIL:
            return state.merge(
                fromJS({
                    rsvpingVendor: false,
                })
            );
        case rsvpsActions.DELETE_VENDOR_RSVP:
            return state.merge(
                fromJS({
                    rsvpingVendor: true,
                })
            );
        case rsvpsActions.DELETE_GOV_ADDED_VENDOR_RSVP_SUCCESS:
            return state.set('rsvpingVendor', false).set(
                'timelinesWithRsvps',
                state.get('timelinesWithRsvps').map((timeline) => {
                    const timelineId = timeline.get('id');
                    if (
                        action.result.timeline_id === timelineId ||
                        (!action.result.timeline_id &&
                            timelineId === timelineDatesDict.PRE_PROPOSAL_DATE)
                    ) {
                        return timeline.set(
                            'vendorRsvps',
                            timeline
                                .get('vendorRsvps')
                                .filter((rsvp) => rsvp.get('id') !== action.result.id)
                        );
                    }
                    return timeline;
                })
            );
        case rsvpsActions.DELETE_VENDOR_RSVP_SUCCESS:
            return state.set('rsvpingVendor', false).set(
                'vendorRsvps',
                state.get('vendorRsvps').filter((rsvp) => rsvp.get('id') !== action.result.id)
            );
        case rsvpsActions.DELETE_VENDOR_RSVP_FAIL:
            return state.merge(
                fromJS({
                    rsvpingVendor: false,
                })
            );
        case rsvpsActions.LOAD_TIMELINES_WITH_RSVPS:
            return state.merge(
                fromJS({
                    loading: true,
                    loaded: false,
                    loadError: null,
                })
            );
        case rsvpsActions.LOAD_TIMELINES_WITH_RSVPS_SUCCESS:
            return state.merge(
                fromJS({
                    loading: false,
                    loaded: true,
                    timelinesWithRsvps: fromJS(action.result),
                })
            );
        case rsvpsActions.LOAD_TIMELINES_WITH_RSVPS_FAIL:
            return state.merge(
                fromJS({
                    loading: false,
                    loaded: false,
                    loadError: action.error.message,
                })
            );
        case rsvpsActions.LOAD_VENDOR_RSVPS:
            return state.merge(
                fromJS({
                    loading: true,
                    loaded: false,
                })
            );
        case rsvpsActions.LOAD_VENDOR_RSVPS_SUCCESS:
            return state.merge(
                fromJS({
                    loading: false,
                    loaded: true,
                    vendorRsvps: fromJS(action.result),
                })
            );
        case rsvpsActions.LOAD_VENDOR_RSVPS_FAIL:
            return state.merge(
                fromJS({
                    loading: false,
                    loaded: true,
                    loadError: action.error.message,
                })
            );
        case rsvpsActions.RESET_RSVPS:
            return state.merge(initialState);
        case rsvpsActions.TOGGLE_RSVP_ENDED:
            return state.merge(
                fromJS({
                    togglingRsvpEnded: true,
                })
            );
        case rsvpsActions.TOGGLE_RSVP_ENDED_SUCCESS:
            return state.merge(
                fromJS({
                    togglingRsvpEnded: false,
                    timelinesWithRsvps: fromJS(action.result),
                })
            );
        case rsvpsActions.TOGGLE_RSVP_ENDED_FAIL:
            return state.merge(
                fromJS({
                    togglingRsvpEnded: false,
                })
            );
        default:
            return state;
    }
}

/**
 * Calls a function when enter key is pressed.
 * @param {*} event Keyboard event.
 * @param {*} onEnter Function to be called when enter key is pressed.
 */
export const onKeyDown = (event, onEnter) => {
    if (event.key === 'Enter') {
        onEnter(event);
    }
};

import PropTypes from 'prop-types';
import React from 'react';
import { components } from 'react-select';

export const SearchSelectSubTextOption = (props) => {
    const styles = require('./index.scss');

    const {
        data: { label, subText },
    } = props;

    return (
        <components.Option {...props}>
            <div className={styles.option}>
                <span>{label}</span>
                <span className={styles.subText}>{subText}</span>
            </div>
        </components.Option>
    );
};

SearchSelectSubTextOption.propTypes = {
    data: PropTypes.shape({
        label: PropTypes.string.isRequired,
        subText: PropTypes.string.isRequired,
    }).isRequired,
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Panel } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { resetLibrary } from '../../../actions/projectLibrary';

const mapDispatchToProps = {
    resetLibrary,
};

// @connect
class ConnectedProjectLibrary extends Component {
    static propTypes = {
        resetLibrary: PropTypes.func.isRequired,
    };

    componentWillUnmount() {
        this.props.resetLibrary();
    }

    render() {
        const styles = require('./ProjectLibrary.scss');
        return (
            <Panel className={styles.projectLibraryContainer} id="skip">
                <h1 className="visually-hidden">Project Library</h1>
                <Panel.Body>
                    <h3 className={styles.projectLibraryTitle}>Project Library Search</h3>
                    <Outlet />
                </Panel.Body>
            </Panel>
        );
    }
}

export const ProjectLibrary = connect(null, mapDispatchToProps)(ConnectedProjectLibrary);

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@og-pro/ui';
import { ArrowForwardIos as ArrowForwardIosIcon } from '@mui/icons-material';

export const CollapsableBox = ({ actions, children, title, subtitle }) => {
    return (
        <Accordion
            defaultExpanded
            sx={{
                border: 0,
                backgroundColor: 'white',
                '&:focus': {
                    outline: 'none',
                    boxShadow: 'none',
                    border: '0 !important',
                },
            }}
        >
            <AccordionSummary
                expandIcon={<ArrowForwardIosIcon sx={{ fontSize: 'medium' }} />}
                sx={{
                    px: 1,

                    '&.Mui-expanded': {
                        backgroundColor: 'inherit !important',
                        border: '0 !important',
                    },
                    '&:focus': {
                        outline: 'none',
                        boxShadow: 'none',
                        border: '0 !important',
                    },
                }}
            >
                <Box alignItems="center" display="flex" flex={1}>
                    <Box flex={4}>
                        <Typography variant="h3">{title}</Typography>
                        {!!subtitle && <Typography variant="bodySmall">{subtitle}</Typography>}
                    </Box>
                    {!!actions && actions}
                </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ px: 1, py: 0 }}>
                <Box>{children}</Box>
            </AccordionDetails>
        </Accordion>
    );
};

CollapsableBox.propTypes = {
    actions: PropTypes.array,
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
};

import { Box, Button as MuiButton, Typography } from '@og-pro/ui';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button } from '..';

const Title = ({ text, useOpenGovStyle }) => {
    if (useOpenGovStyle) {
        return <Typography variant="bodyXLarge">{text}</Typography>;
    }
    return (
        <Box component="span" fontSize="22px">
            {text}
        </Box>
    );
};

Title.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    useOpenGovStyle: PropTypes.bool,
};

const Info = ({ text, useOpenGovStyle }) => {
    if (useOpenGovStyle) {
        return <Typography variant="bodyDefault">{text}</Typography>;
    }
    return <Box fontSize="16px">{text}</Box>;
};

Info.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    useOpenGovStyle: PropTypes.bool,
};

export const ZeroState = ({
    button,
    buttonClickHandler,
    buttonProps,
    buttonText,
    containerClass,
    info,
    title,
    useOpenGovStyle,
}) => {
    const styles = require('./index.scss');

    const { ButtonComponent, ...buttonStyleProps } = useOpenGovStyle
        ? { ButtonComponent: MuiButton, color: 'success', size: 'large', variant: 'contained' }
        : { ButtonComponent: Button, bsSize: 'large', bsStyle: 'success' };

    return (
        <div className={classNames(styles.container, 'row text-muted', containerClass)}>
            <div className="col-sm-10 col-sm-offset-1">
                <Box marginBottom={2.5}>
                    <Title text={title} useOpenGovStyle={useOpenGovStyle} />
                </Box>
                <Box marginBottom={2.5}>
                    {!!button && button}
                    {buttonText && (
                        <ButtonComponent
                            onClick={buttonClickHandler}
                            qaTag={`zeroState-${buttonText}`}
                            {...buttonStyleProps}
                            {...buttonProps}
                        >
                            {buttonText}
                        </ButtonComponent>
                    )}
                </Box>
                {info && <Info text={info} useOpenGovStyle={useOpenGovStyle} />}
            </div>
        </div>
    );
};

ZeroState.propTypes = {
    button: PropTypes.node,
    buttonClickHandler: PropTypes.func,
    buttonProps: PropTypes.object,
    buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    containerClass: PropTypes.string,
    info: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    useOpenGovStyle: PropTypes.bool,
};

import React from 'react';
import { useSelector } from 'react-redux';

import { getDeleteError } from '../selectors';
import { getRequisitionCopyError, getRequisitionUpdateError } from '../../../../selectors/govApp';

export const RequisitionsCreateActionError = () => {
    const updateError = useSelector(getRequisitionUpdateError);
    const deleteError = useSelector(getDeleteError);
    const copyError = useSelector(getRequisitionCopyError);

    const errorMessage = updateError || deleteError || copyError;

    if (!errorMessage) {
        return null;
    }

    return <div className="col-xs-12 error-block text-right">{errorMessage}</div>;
};

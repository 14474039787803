import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';

import { CATEGORIES, CUSTOM_CATEGORY_NAME } from './constants';
import { validate } from './validate';
import { CategoryLinkInput } from '../../CategorySelect';
import { Button, InputText, Label } from '../../../components';

const formConfig = {
    validate,
};

// @reduxForm
class ConnectedCreateCustomCategoryForm extends Component {
    static propTypes = {
        createCustomCategory: PropTypes.func.isRequired,
        customCategoryName: PropTypes.string,
    };

    handleCustomCategoryChange = (event, categories) => {
        const { createCustomCategory, customCategoryName } = this.props;

        createCustomCategory(
            customCategoryName,
            categories.map((category) => category.id)
        );
    };

    render() {
        const { customCategoryName } = this.props;

        const disabled = !customCategoryName;

        return (
            <div className="row">
                <div className="col-xs-12">
                    <Label htmlFor="form-group-customCategoryName" label="Custom Category Name" />
                </div>
                <div className="col-sm-9">
                    <Field
                        component={InputText}
                        hasFeedback
                        name={CUSTOM_CATEGORY_NAME}
                        placeholder="Custom Category"
                    />
                </div>
                <div className="col-sm-3">
                    <Field
                        component={CategoryLinkInput}
                        disabled={disabled}
                        disallowCustomCategories
                        hasFeedback
                        name={CATEGORIES}
                        onChange={this.handleCustomCategoryChange}
                        targetComponent={
                            <Button block bsStyle="success" disabled={disabled}>
                                <i className="fa fa-plus" /> Create
                            </Button>
                        }
                        useSingleCodeSet
                    />
                </div>
            </div>
        );
    }
}

export const CreateCustomCategoryForm = reduxForm(formConfig)(ConnectedCreateCustomCategoryForm);

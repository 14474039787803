import { createSelector } from 'reselect';

import { getTemplateQuestions } from '../../../../../../selectors/govApp';

export const getTemplateQuestionUpfrontQuestionsMap = createSelector(
    [getTemplateQuestions],
    (templateQuestions) => {
        return templateQuestions.reduce((map, templateQuestion) => {
            return {
                ...map,
                [templateQuestion.upfrontQuestion.sharedId]: templateQuestion,
            };
        }, {});
    }
);

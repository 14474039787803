import { createSelector } from 'reselect';

const getContractAttachmentsBeingCreated = (state) =>
    state.contracts.get('contractAttachmentsBeingCreated');

export const getContractAttachmentsBeingCreatedJS = createSelector(
    [getContractAttachmentsBeingCreated],
    (rawContractAttachmentsBeingCreated) => {
        if (rawContractAttachmentsBeingCreated) {
            return rawContractAttachmentsBeingCreated.toJS();
        }

        return [];
    }
);

import { redirect } from 'react-router-dom';

/*
 * Redirects a user to the not-found page if they are not an OpenGov user or are not logged in.
 */
export default (getState) => async () => {
    const user = getState().auth.get('user');
    const email = user?.get('email');
    const isOpenGovUser =
        email && (!!email.match(/@procurenow\.com$/) || !!email.match(/@opengov\.com$/));

    if (!isOpenGovUser) {
        return redirect('/not-found');
    }

    return null;
};

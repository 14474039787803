import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import { connect } from 'react-redux';

import { contractPermissionsDict } from '@og-pro/shared-config/contracts';

import { buildMap } from '@og-pro/shared-config/helpers';

import { PermissionForm } from './PermissionForm';
import { getContractJS, getUserJS, getInvitedUsersJS } from '../../selectors';
import { changeContractPermission, hidePermissionsModal } from '../../../actions/contracts';

const mapStateToProps = (state) => {
    return {
        contract: getContractJS(state),
        saveError: state.contracts.get('saveContractPermissionError'),
        saving: state.contracts.get('savingContractPermission'),
        showModal: state.contracts.get('showContractPermissionsModal'),
        user: getUserJS(state),
        users: getInvitedUsersJS(state),
    };
};

const mapDispatchToProps = {
    changeContractPermission,
    hidePermissionsModal,
};

// @connect
class ConnectedContractPermissionsModal extends Component {
    static propTypes = {
        changeContractPermission: PropTypes.func.isRequired,
        contract: PropTypes.shape({
            id: PropTypes.number.isRequired,
            permissions: PropTypes.array.isRequired,
        }),
        hidePermissionsModal: PropTypes.func.isRequired,
        saveError: PropTypes.string,
        saving: PropTypes.bool,
        showModal: PropTypes.bool,
        user: PropTypes.shape({
            displayName: PropTypes.string,
            id: PropTypes.number.isRequired,
        }).isRequired,
        users: PropTypes.array,
    };

    static defaultProps = {
        saveError: undefined,
        saving: false,
        showModal: false,
        users: [],
    };

    get styles() {
        return require('./index.scss');
    }

    handleHideModal = () => {
        this.props.hidePermissionsModal();
    };

    render() {
        if (!this.props.contract || !this.props.users) {
            return null;
        }

        const {
            contract: { id: contractId },
            saveError,
            saving,
            showModal,
            users,
            user: { id: userId },
        } = this.props;

        const permissions = this.props.contract.permissions || [];
        const errorDisplay = saveError ? 'Error saving permission' : undefined;

        const permissionsDict = buildMap(permissions, 'user_id');

        const userSelectOptions = users.map((user) => {
            return {
                value: user.id,
                label: user.displayName,
                user,
            };
        });

        const userPermissionForms = permissions.map((contractPermission) => {
            return (
                <PermissionForm
                    changeContractPermission={this.props.changeContractPermission}
                    contractId={contractId}
                    creator={contractPermission.role === contractPermissionsDict.CREATOR}
                    disabled={saving}
                    enableReinitialize
                    form={`contractPermissionForm:${contractPermission.user_id}`}
                    initialValues={{
                        id: contractPermission.user_id,
                        role: contractPermission.role,
                    }}
                    invited
                    isSelf={userId === contractPermission.user_id}
                    key={`contractPermission:${contractPermission.user_id}`}
                    userId={contractPermission.user_id}
                    userSelectOptions={userSelectOptions}
                />
            );
        });

        userPermissionForms.unshift(
            <PermissionForm
                changeContractPermission={this.props.changeContractPermission}
                contractId={contractId}
                deleteHandler={this.handleRemovePermission}
                disabled={saving}
                form="contractPermissionForm:newUserPermission"
                initialValues={{
                    role: contractPermissionsDict.VIEW,
                }}
                key="contractPermission:newUserPermission"
                onSubmit={this.handleAddOrChangePermission}
                userSelectOptions={userSelectOptions.filter(
                    (option) => !permissionsDict[option.value]
                )}
            />
        );

        return (
            <Modal onHide={this.handleHideModal} show={showModal}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">Invite Contract Collaborators</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-danger text-center">{errorDisplay}</div>
                    <div className={this.styles.tableContainer}>
                        <Table className={this.styles.table}>
                            <thead>
                                <tr>
                                    <th>User</th>
                                    <th>Access</th>
                                    <th className="visually-hidden">Actions</th>
                                </tr>
                            </thead>
                            <tbody>{userPermissionForms}</tbody>
                        </Table>
                    </div>
                    <div className="text-center">
                        <Button onClick={this.handleHideModal}>Done</Button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export const ContractPermissionsModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedContractPermissionsModal);

import { truncate } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { AttachmentRowItem } from '../../..';

export const QuestionAttachments = ({
    attachments,
    isDocx = false,
    isOGThemeEnabledForComponents = false,
    isRevisionsDiff = false,
}) => {
    return (
        <ul className="list-unstyled questionnaire-attachments-table">
            {attachments.map((attachment, i) => {
                const name = attachment.name || attachment.filename;
                return (
                    <li key={attachment.path}>
                        {isDocx ? (
                            <a href={attachment.url}>{truncate(name, 100)}</a>
                        ) : (
                            <AttachmentRowItem
                                attachment={{ ...attachment, name }}
                                isLast={attachments.length === i + 1}
                                isNormalSizeIcons
                                isOGThemeEnabledForComponents={isOGThemeEnabledForComponents}
                                isReadOnly={isRevisionsDiff}
                            />
                        )}
                    </li>
                );
            })}
        </ul>
    );
};

QuestionAttachments.propTypes = {
    attachments: PropTypes.array.isRequired,
    isDocx: PropTypes.bool,
    isOGThemeEnabledForComponents: PropTypes.bool,
    isRevisionsDiff: PropTypes.bool,
};

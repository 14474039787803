import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export class LoginTitle extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        children: PropTypes.node.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const { className, children } = this.props;

        return <h3 className={classnames(this.styles.title, className)}>{children}</h3>;
    }
}

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Dropdown, MenuItem } from '../../..';

export class OptionDropdown extends PureComponent {
    static propTypes = {
        deleteHandler: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        editHandler: PropTypes.func.isRequired,
        id: PropTypes.string.isRequired,
    };

    get styles() {
        return require('./OptionDropdown.scss');
    }

    render() {
        const { deleteHandler, disabled, editHandler, id } = this.props;

        return (
            <Dropdown
                className={this.styles.dropdown}
                disabled={disabled}
                id={id}
                onClick={(e) => e.stopPropagation()}
                pullRight
                qaTag="templatesAdmin-evaluationPhase"
            >
                <Dropdown.Toggle noCaret>
                    <i className="fa fa-ellipsis-h" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <MenuItem onClick={editHandler} qaTag="evaluation-editPhaseName">
                        <i className="fa fa-fw fa-pencil" /> Edit Phase Name
                    </MenuItem>
                    <MenuItem onClick={deleteHandler} qaTag="evaluation-deletePhase">
                        <i className="fa fa-fw fa-trash-o" /> Delete Phase
                    </MenuItem>
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}

import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { formValueSelector, getFormSyncErrors } from 'redux-form';

import { BaseProjectSectionsNav } from '../../../../../../components/SDv2/ProjectSectionsNav/BaseProjectSectionsNav';
import { projectSectionsToNavSections } from '../../../../../../components/SDv2/helpers';
import { form, fieldNames } from '../../../forms/constants';
import { fieldNames as formFieldNames } from '../../../forms/TemplateForm/constants';
import { TemplateEditV2NavContext, TemplateEditV2FunctionsContext } from '../../context';

const { USE_MANUAL_NUMBERING } = formFieldNames;
const selector = formValueSelector(form);

export const TemplateDocumentBuilderNav = () => {
    const errorsSelectors = getFormSyncErrors(form);
    const formErrors = useSelector((state) => errorsSelectors(state));

    const projectSections = useSelector((state) => selector(state, fieldNames.PROJECT_SECTIONS));
    const useManualNumbering = useSelector((state) => selector(state, USE_MANUAL_NUMBERING));
    const { activeSectionId, setActiveSectionId } = useContext(TemplateEditV2NavContext);
    const { addingSectionData, showFormValidation, updateTemplateIfDirty } = useContext(
        TemplateEditV2FunctionsContext
    );

    // if we are adding a section
    // we fake an entry in the bottom of the list
    let rawSections = [...projectSections];
    if (addingSectionData.active) {
        rawSections = rawSections.concat([
            {
                ...addingSectionData.sectionType,
                title: 'New Section',
            },
        ]);
    }

    const sections = projectSectionsToNavSections({
        projectSections: rawSections,
        active: activeSectionId,
        setActive: (i) => {
            updateTemplateIfDirty();
            setActiveSectionId(i);
        },
        useManualNumbering,
        formErrors,
        showFormValidation,
    });

    return <BaseProjectSectionsNav sections={sections} showShortName />;
};

import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Button, UserProfilePicture } from '../../../../../components';

export class ContractSubscriber extends PureComponent {
    static propTypes = {
        contractSubscriber: PropTypes.shape({
            deleting: PropTypes.bool,
            deleteError: PropTypes.string,
            email: PropTypes.string,
            id: PropTypes.number.isRequired,
            user: PropTypes.shape({
                id: PropTypes.number.isRequired,
                displayName: PropTypes.string.isRequired,
            }),
        }).isRequired,
        isEditor: PropTypes.bool,
        onDelete: PropTypes.func.isRequired,
        user: PropTypes.shape({
            email: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
        }),
    };

    get styles() {
        return require('./index.scss');
    }

    deleteHandler = () => {
        const {
            contractSubscriber: { id },
            onDelete,
        } = this.props;

        onDelete(id);
    };

    renderUser() {
        const {
            contractSubscriber: { email, user },
        } = this.props;

        if (user) {
            return (
                <span>
                    <UserProfilePicture horizontal user={user} />
                    &nbsp;
                    <div className={this.styles.userName}>{user.displayName}</div>
                </span>
            );
        }

        return (
            <span>
                <i className="fa fa-lg fa-envelope-o" />
                <div className={this.styles.userName}>{email}</div>
            </span>
        );
    }

    render() {
        const {
            contractSubscriber: { deleting, deleteError, email, user: subscriberUser },
            isEditor,
            user,
        } = this.props;

        const isSelf = user.email === email || user.id === get(subscriberUser, 'id');

        const icon = deleting ? 'fa-spin fa-spinner' : 'fa-times';
        return (
            <li className={this.styles.itemContainer}>
                {(isEditor || isSelf) && (
                    <div className={this.styles.deleteButton}>
                        <Button
                            aria-label="Delete Button"
                            bsStyle="link"
                            className="pull-right"
                            disabled={deleting}
                            onClick={this.deleteHandler}
                            qaTag="contractSubscriber-delete"
                        >
                            <i className={`fa fa-lg fa-fw ${icon} text-danger`} />
                        </Button>
                    </div>
                )}
                &nbsp;
                {this.renderUser()}
                {deleteError && <div className="error-block">{deleteError}</div>}
            </li>
        );
    }
}

import { createSelector } from 'reselect';

const getPathname = (state, props) => props.location.pathname;
const getSignUpPageData = (state) =>
    !state.auth.get('vendorSignUpComplete') &&
    !state.auth.get('requestToJoinComplete') &&
    !state.auth.get('signUpOrganizationMatch');

export const getTermsText = createSelector(
    [getPathname, getSignUpPageData],
    (pathname, isSignupPage) => {
        if (pathname.match(/\/login\/?$/i)) {
            return 'Log In';
        }
        if (pathname.match(/\/signup\/?$/i) && isSignupPage) {
            return 'Sign Up';
        }
        return null;
    }
);

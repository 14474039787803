import PropTypes from 'prop-types';
import React from 'react';
import { Chip, Tooltip } from '@og-pro/ui';
import { Close as CloseIcon } from '@mui/icons-material';

import { categoryNames } from '@og-pro/shared-config/categories';

export const CategoryCodeChip = ({ code, setId, title, deleteHandler, categoryId }) => {
    const setName = categoryNames[setId];

    return (
        <Tooltip title={`${setName}: ${title}`}>
            <Chip
                deleteIcon={<CloseIcon />}
                label={code}
                onDelete={deleteHandler ? () => deleteHandler(categoryId) : undefined}
                size="small"
                variant="outlined"
            />
        </Tooltip>
    );
};

CategoryCodeChip.propTypes = {
    code: PropTypes.string.isRequired,
    setId: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    categoryId: PropTypes.number.isRequired,
    deleteHandler: PropTypes.func,
};

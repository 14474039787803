import PropTypes from 'prop-types';

export const CDS_BUTTON_SIZES = ['default', 'small', 'large'];
export const CDS_BUTTON_VARIANTS = [
    'primary',
    'secondary',
    'secondary-alt',
    'tertiary',
    'destructive',
    'text',
];

export const CDSButtonPropTypes = {
    size: PropTypes.oneOf(CDS_BUTTON_SIZES),
    variant: PropTypes.oneOf(CDS_BUTTON_VARIANTS),
    noPadding: PropTypes.bool,
};

export const CDSButtonDefaultProps = {
    size: 'default',
    variant: 'primary',
};

import { fromJS } from 'immutable';
import moment from 'moment';

import * as govProjectActions from '../actions/govProjects';
import * as proposalEvalActions from '../actions/proposalEvaluations';

const initialState = fromJS({
    aggregateEvaluationsUpdateDate: null,
    bidTabulationTotals: [],
    bidTabulationsPricingSealed: false,
    compareProposals: [],
    compareQuestionnaires: [],
    loaded: false,
    loading: false,
    loadingAggregate: false,
    loadingBidTabulationTotals: false,
    selectingProposal: false,
    showAnonymizedEvaluators: false,
    showDetailedProposals: true,
    showNotesModal: false,
    showProposalSelectModal: false,
    showScorecardModal: false,
});

export default function proposalEvaluationsReducer(state = initialState, action = {}) {
    switch (action.type) {
        case proposalEvalActions.LOAD_BID_TABULATIONS:
            return state.merge(
                fromJS({
                    loadBidTabulationsError: null,
                    loadingBidTabulations: true,
                })
            );
        case proposalEvalActions.LOAD_BID_TABULATIONS_SUCCESS:
            return state.merge(
                fromJS({
                    bidTabulationProposalsData: fromJS(action.result.proposalsData),
                    bidTabulations: fromJS(action.result.bidTabulations),
                    bidTabulationsPricingSealed: action.result.isPricingSealed,
                    loadingBidTabulations: false,
                    selectedBidTabulationPriceItemIds: fromJS(
                        action.result.selectedBidTabulationPriceItemIds
                    ),
                })
            );
        case proposalEvalActions.LOAD_BID_TABULATIONS_FAIL:
            return state.merge(
                fromJS({
                    loadingBidTabulations: false,
                    loadBidTabulationsError: action.error && action.error.message,
                })
            );
        case proposalEvalActions.LOAD_BID_TABULATIONS_TOTALS:
            return state.merge(
                fromJS({
                    loadBidTabulationTotalsError: null,
                    loadedBidTabulationTotals: false,
                    loadingBidTabulationTotals: true,
                })
            );
        case proposalEvalActions.LOAD_BID_TABULATIONS_TOTALS_SUCCESS:
            return state.merge(
                fromJS({
                    bidTabulationTotals: fromJS(action.result.proposalsData),
                    loadedBidTabulationTotals: true,
                    loadingBidTabulationTotals: false,
                })
            );
        case proposalEvalActions.LOAD_BID_TABULATIONS_TOTALS_FAIL:
            return state.merge(
                fromJS({
                    loadBidTabulationTotalsError: action.error && action.error.message,
                    loadedBidTabulationTotals: false,
                    loadingBidTabulationTotals: false,
                })
            );
        case proposalEvalActions.SET_SELECTED_BID_TABULATION_PRICE_ITEMS:
            return state.merge(
                fromJS({
                    savingSelectedBidTabulations: true,
                })
            );
        case proposalEvalActions.SET_SELECTED_BID_TABULATION_PRICE_ITEMS_SUCCESS:
        case proposalEvalActions.SET_SELECTED_BID_TABULATION_PRICE_ITEMS_FAIL:
            return state.merge(
                fromJS({
                    savingSelectedBidTabulations: false,
                })
            );
        case proposalEvalActions.RESET_BID_TABULATIONS:
            return state.merge(
                fromJS({
                    // NOTE: reset bid tab store to prevent errors related to async redux actions racing
                    // with history push state
                    bidTabulations: undefined,
                    loadingBidTabulations: undefined,
                    loadBidTabulationsError: undefined,
                    selectedBidTabulationPriceItemIds: undefined,
                    vendorNames: undefined,
                })
            );
        case proposalEvalActions.LOAD_AGGREGATE:
            return state.merge(
                fromJS({
                    loadingAggregate: true,
                    loadAggregateError: null,
                })
            );
        case proposalEvalActions.LOAD_AGGREGATE_SUCCESS:
            return state.merge(
                fromJS({
                    aggregateEvaluationsUpdateDate: moment().add(5, 'minutes').toDate(),
                    loadingAggregate: false,
                })
            );
        case proposalEvalActions.LOAD_AGGREGATE_FAIL:
            return state.merge(
                fromJS({
                    loadingAggregate: false,
                    loadAggregateError: action.error && action.error.message,
                })
            );
        case proposalEvalActions.LOAD:
            return state.merge(
                fromJS({
                    loading: true,
                    loaded: false,
                    loadError: null,
                })
            );
        case proposalEvalActions.LOAD_SUCCESS:
            return state.merge(
                fromJS({
                    loading: false,
                    loaded: true,
                    proposal: fromJS(action.result),
                })
            );
        case proposalEvalActions.LOAD_FAIL:
            return state.merge(
                fromJS({
                    loading: false,
                    loaded: false,
                    loadError: action.error && action.error.message,
                })
            );
        case proposalEvalActions.LOAD_PROPOSAL_COMPARE:
            return state.merge(
                fromJS({
                    loadingCompare: true,
                    loadCompareError: null,
                })
            );
        case proposalEvalActions.LOAD_PROPOSAL_COMPARE_SUCCESS:
            return state.merge(
                fromJS({
                    loadingCompare: false,
                    compareProposals: fromJS(action.result.proposals),
                    compareQuestionnaires: fromJS(action.result.questionnaires),
                })
            );
        case proposalEvalActions.LOAD_PROPOSAL_COMPARE_FAIL:
            return state.merge(
                fromJS({
                    loadingCompare: false,
                    loadCompareError: action.error && action.error.message,
                })
            );
        case proposalEvalActions.SHOW_SCORECARD_MODAL:
            return state.merge(
                fromJS({
                    showScorecardModal: true,
                    evaluationModalData: fromJS(action.data),
                })
            );
        case proposalEvalActions.HIDE_SCORECARD_MODAL:
            return state.merge(
                fromJS({
                    showScorecardModal: false,
                    evaluationModalData: null,
                })
            );
        case proposalEvalActions.SHOW_NOTES_MODAL:
            return state.merge(
                fromJS({
                    showNotesModal: true,
                    evaluationModalData: fromJS(action.data),
                })
            );
        case proposalEvalActions.HIDE_NOTES_MODAL:
            return state.merge(
                fromJS({
                    showNotesModal: false,
                    evaluationModalData: null,
                })
            );
        case proposalEvalActions.SHOW_PROPOSAL_SELECT_MODAL:
            return state.merge(
                fromJS({
                    showProposalSelectModal: true,
                })
            );
        case proposalEvalActions.HIDE_PROPOSAL_SELECT_MODAL:
            return state.merge(
                fromJS({
                    showProposalSelectModal: false,
                })
            );
        case proposalEvalActions.SELECT_PROPOSAL:
            return state.merge(
                fromJS({
                    selectingProposal: true,
                    selectProposalError: null,
                })
            );
        case proposalEvalActions.SELECT_PROPOSAL_SUCCESS:
            return state.merge(
                fromJS({
                    selectingProposal: false,
                })
            );
        case proposalEvalActions.SELECT_PROPOSAL_FAIL:
            return state.merge(
                fromJS({
                    selectingProposal: false,
                    selectProposalError: action.error && action.error.message,
                })
            );
        case proposalEvalActions.UPDATE_DISPLAY_SETTINGS:
            return state.merge(fromJS(action.displayOptions));
        case proposalEvalActions.RESET_PROPOSAL_TO_EVALUATE:
            return state.set('loaded', false);
        case proposalEvalActions.UPDATE_SUCCESS:
            return state.set('aggregateEvaluationsUpdateDate', null);
        case govProjectActions.RESET:
            return initialState;
        case proposalEvalActions.LOAD_LINE_ITEM_AWARDS:
            return state.merge(
                fromJS({
                    loadLineItemAwardsError: null,
                    loadingLineItemAwards: true,
                })
            );
        case proposalEvalActions.LOAD_LINE_ITEM_AWARDS_SUCCESS:
            return state.merge(
                fromJS({
                    lineItemAwardProposalsData: fromJS(action.result.proposalsData),
                    lineItemAwards: fromJS(action.result.lineItemAwards),
                    lineItemAwardsPricingSealed: action.result.isPricingSealed,
                    loadingLineItemAwards: false,
                })
            );
        case proposalEvalActions.LOAD_LINE_ITEM_AWARDS_FAIL:
            return state.merge(
                fromJS({
                    loadingLineItemAwards: false,
                    loadLineItemAwardsError: action.error && action.error.message,
                })
            );
        default:
            return state;
    }
}

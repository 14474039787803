import { useCustomFormService } from './useCustomFormService';
import { getAllCustomFields } from './queries';

export const useListCustomFields = () => {
    return useCustomFormService({
        queryKey: 'getAllCustomFields',
        query: getAllCustomFields,
        enabled: true,
        placeholderData: {
            getAllCustomFields: [],
        },
    });
};

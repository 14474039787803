import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ListGroup } from 'react-bootstrap';

import { ContractComplaintsListItem } from './ContractComplaintsListItem';

export class ContractComplaintsList extends Component {
    static propTypes = {
        className: PropTypes.string,
        contractReview: PropTypes.shape({
            contractComplaints: PropTypes.array.isRequired,
        }).isRequired,
        readOnly: PropTypes.bool,
        showContractComplaintModal: PropTypes.func.isRequired,
    };

    showContractComplaintModal = (contractComplaint) => {
        const { contractReview, readOnly, showContractComplaintModal } = this.props;

        showContractComplaintModal({
            contractComplaint,
            contractReview,
            readOnly,
        });
    };

    render() {
        const {
            className,
            contractReview: { contractComplaints },
        } = this.props;

        return (
            <ListGroup className={className}>
                {contractComplaints.map((contractComplaint) => (
                    <ContractComplaintsListItem
                        contractComplaint={contractComplaint}
                        key={contractComplaint.id}
                        showContractComplaintModal={this.showContractComplaintModal}
                    />
                ))}
            </ListGroup>
        );
    }
}

import PropTypes from 'prop-types';
import React from 'react';

export const SequenceToggleDisplay = ({ enabled, label }) => {
    return (
        <div>
            <i className={`fa fa-fw fa-${enabled ? 'check' : 'times'}`} /> {label}
        </div>
    );
};

SequenceToggleDisplay.propTypes = {
    enabled: PropTypes.bool,
    label: PropTypes.string.isRequired,
};

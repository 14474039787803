import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RequisitionsSettingsForm } from './RequisitionsSettingsForm';
import { getRequisitionSettings } from './selectors';
import connectData from '../../../ConnectData';
import { showSnackbar } from '../../../../actions/notification';
import { loadReqSetting, updateReqSetting } from '../../../../actions/requisitionsAdmin';
import { LoadingError, LoadingSpinner, Main, SectionTitle } from '../../../../components';

const fetchData = (getState, dispatch) => {
    return dispatch(loadReqSetting());
};

export const ConnectedRequisitionsSettings = () => {
    const [updating, setUpdating] = useState(false);
    const [updateError, setUpdateError] = useState(null);

    const requisitionSettings = useSelector(getRequisitionSettings);
    const isLoading = useSelector((state) => state.requisitionsAdmin.get('loadingReqSetting'));
    const loadingError = useSelector((state) => state.requisitionsAdmin.get('loadReqSettingError'));

    const dispatch = useDispatch();

    const onSubmit = (formValues) => {
        setUpdating(true);
        setUpdateError(null);
        return dispatch(updateReqSetting(formValues))
            .then(() => {
                setUpdating(false);
                dispatch(showSnackbar('Settings updated'));
            })
            .catch((error) => {
                setUpdating(false);
                setUpdateError(error.message);
            });
    };

    if (isLoading) {
        return <LoadingSpinner useOpenGovStyle />;
    }

    if (loadingError) {
        return <LoadingError error={loadingError} useOpenGovStyle />;
    }

    return (
        <Main>
            <SectionTitle
                info="Configure request management settings for your organization"
                title="Request Management Settings"
            />
            <div className="row">
                <div className="col-lg-offset-2 col-lg-8">
                    <RequisitionsSettingsForm
                        initialValues={requisitionSettings}
                        onSubmit={onSubmit}
                        updateError={updateError}
                        updating={updating}
                    />
                </div>
            </div>
        </Main>
    );
};

export const RequisitionsSettings = connectData(fetchData)(ConnectedRequisitionsSettings);

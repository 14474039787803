const isNumber = require('lodash/isNumber');
const sumBy = require('lodash/sumBy');

const { buildMap, listToDict } = require('../helpers');

exports.getTotalWeight = (scoringCriteria) => {
    const activeCriteria = (scoringCriteria || []).filter((item) => !item.isHiddenByLogic);
    const totalWeight = sumBy(activeCriteria, 'weight');
    if (totalWeight) {
        // Prevents value from having more than 2 decimals places.
        // Weird decimal values can happen (Ex: 40.46 + 40 = 80.46000000000001)
        return Math.round(totalWeight * 100) / 100;
    }
    return totalWeight;
};

exports.evaluationStatusTypes = ['draft', 'open', 'released', 'complete'];
exports.evaluationStatuses = listToDict(exports.evaluationStatusTypes);

exports.SCORED = 'scored';
exports.LOWEST_PRICE = 'lowestPrice';
exports.LINE_ITEM_AWARD = 'lineItemAward';

exports.EVALUATION_TYPES = [exports.LOWEST_PRICE, exports.SCORED, exports.LINE_ITEM_AWARD];

exports.lineItemAwardTypes = ['primary', 'backup'];

const PASS_FAIL = 1;
const POINTS_0_TO_5 = 2;
const POINTS_0_TO_10 = 3;
const POINTS_0_TO_100 = 4;
const POINTS_BASED = 5;
const REWARD_LOW_COST = 6;
const REWARD_AVERAGE_COST = 7;

exports.PASS_FAIL = PASS_FAIL;
exports.REWARD_LOW_COST = REWARD_LOW_COST;
exports.REWARD_AVERAGE_COST = REWARD_AVERAGE_COST;

const scoringMethods = [
    {
        label: 'Points Based',
        max: (scoringCriteria) => scoringCriteria.weight || 1,
        min: 0,
        value: POINTS_BASED,
    },
    {
        label: 'Pass / Fail',
        max: 1,
        min: 0,
        value: PASS_FAIL,
    },
    {
        label: '0-5 Points',
        max: 5,
        min: 0,
        value: POINTS_0_TO_5,
    },
    {
        label: '0-10 Points',
        max: 10,
        min: 0,
        value: POINTS_0_TO_10,
    },
    {
        label: '0-100 Points',
        max: 100,
        min: 0,
        value: POINTS_0_TO_100,
    },
    {
        label: 'Reward Low Cost',
        max: 1, // Note: `max` is not used with this method (relies on `computedMaxScore` from scoringCriteria)
        min: 0, // Note: `min` is not used with this method
        value: REWARD_LOW_COST,
        isCostFormula: true,
    },
    {
        label: 'Reward Average Cost',
        max: 1, // Note: `max` is not used with this method (relies on `computedMaxScore` from scoringCriteria)
        min: 0, // Note: `min` is not used with this method
        value: REWARD_AVERAGE_COST,
        isCostFormula: true,
    },
];

exports.scoringMethodOptions = scoringMethods.reduce(
    (optionsArray, scoringMethod) => {
        const optionIndex = scoringMethod.isCostFormula ? 1 : 0;
        optionsArray[optionIndex].options.push({
            label: scoringMethod.label,
            value: scoringMethod.value,
        });
        return optionsArray;
    },
    [
        { label: 'Standard Scoring Methods', options: [] },
        { label: 'Cost Formulas', options: [] },
    ]
);

exports.scoringMethodValues = scoringMethods.map((option) => option.value);

const scoringMethodsMap = buildMap(scoringMethods, 'value');

exports.scoringMethodsData = (scoringCriteria) => {
    const scoringMethodData = scoringMethodsMap[scoringCriteria.scoringMethod];
    if (scoringMethodData && typeof scoringMethodData.max === 'function') {
        return {
            ...scoringMethodData,
            max: scoringMethodData.max(scoringCriteria),
        };
    }
    return scoringMethodData;
};

exports.costFormulaScoringMethods = scoringMethods
    .filter((option) => option.isCostFormula)
    .map((option) => option.value);

exports.isCostFormulaScoringCriterium = (scoringCriterium) => {
    return exports.costFormulaScoringMethods.includes(scoringCriterium.scoringMethod);
};

exports.defaultClientScoringMethod = POINTS_BASED;
exports.defaultServerScoringMethod = POINTS_0_TO_10;
exports.defaultScoringWeight = 10;

exports.getScorePercentage = (score, scoringCriterium) => {
    if (exports.isCostFormulaScoringCriterium(scoringCriterium)) {
        // Score will typically be 0 in the case of an excluded proposal (score gets forced to 0).
        // We also never want to divide by 0, so treat as 0% even when 0 was directly entered.
        if (score === 0) {
            return 0;
        }

        if (scoringCriterium.scoringMethod === REWARD_LOW_COST) {
            return scoringCriterium.computedMaxScore / score;
        }

        if (scoringCriterium.scoringMethod === REWARD_AVERAGE_COST) {
            if (score <= scoringCriterium.computedMaxScore) {
                return score / scoringCriterium.computedMaxScore;
            }
            return scoringCriterium.computedMaxScore / score;
        }
    }
    return score / scoringCriterium.maxScore;
};

// Score is only valid if there is a score and in the case of a cost formula scoring method that
// there is also a `computedMaxScore`
exports.shouldComputeScorePercentage = (score, scoringCriterium) => {
    const isCostFormulaValid =
        !exports.isCostFormulaScoringCriterium(scoringCriterium) ||
        isNumber(scoringCriterium.computedMaxScore);

    return isNumber(score) && isCostFormulaValid;
};

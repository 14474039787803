export const ALL = 'all';
export const FOLLOWING = 'following';
export const SHARED = 'shared';

export const proposalFilters = [
    {
        text: 'My Responses',
        key: 'user',
        icon: 'pencil',
        qaTagDescription: 'myProposals',
    },
    // Temporarily disable "Shared Proposals" (no way to collaborate on proposals right now)
    // {
    //     text: 'Shared Proposals',
    //     key: SHARED,
    //     icon: 'user-plus',
    //     filter: SHARED,
    // },
    {
        text: 'Following',
        key: FOLLOWING,
        icon: 'rss',
        filter: FOLLOWING,
        qaTagDescription: 'followedProposals',
    },
    {
        text: 'All Responses',
        key: ALL,
        icon: 'star',
        filter: ALL,
        qaTagDescription: 'allProposals',
    },
];

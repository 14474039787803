import { getCommentThreadKey } from '../../helpers';

export function commentsNormalizer(threads) {
    if (!threads) {
        return {};
    }

    return threads.reduce((obj, thread) => {
        const commentKey = getCommentThreadKey(thread);

        // In the case that the item containing the thread has been deleted,
        // skip adding the thread.
        if (!commentKey) return obj;

        if (!obj[commentKey]) {
            obj[commentKey] = [];
        }
        obj[commentKey].push(thread);

        return obj;
    }, {});
}

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { isContentHtml } from '../../utils';

export class HtmlContent extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        content: PropTypes.string,
        determineHtml: PropTypes.bool,
    };

    static defaultProps = {
        determineHtml: false,
    };

    render() {
        const { className, content, determineHtml } = this.props;

        const isHtml = determineHtml ? isContentHtml(content) : true;

        const computedProp = isHtml
            ? { dangerouslySetInnerHTML: { __html: content } }
            : { children: content };

        return <div className={className} style={{ whiteSpace: 'pre-wrap' }} {...computedProp} />;
    }
}

import { Box } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { arrayPush, change } from 'redux-form';

import { vendorSelectionStates } from '@og-pro/shared-config/requisitions';

import { AdditionalVendorNotes } from './AdditionalVendorNotes';
import { AddVendor } from './AddVendor';
import { DisplayVendors } from './DisplayVendors';
import { NoVendors } from './NoVendors';
import { getSuggestedVendorContracts } from './selectors';
import { SuggestedVendors } from './SuggestedVendors';
import { fieldNames } from '../../constants';
import { formConfig } from '../../form';
import { getCanAddVendor, requisitionsCreateFormValueSelector } from '../../selectors';
import { setInitialVendorSelectionInputData } from '../../../../../../actions/requisitionsCreate';
import { getIsRequisitionUsingFMS } from '../../../../../../selectors/govApp';

const { VENDOR_NAME, VENDOR_SELECTION_STATE, VENDORS } = fieldNames;

export const ReviewVendorSelection = ({ isEditable, editHandler }) => {
    const suggestedVendorContracts = useSelector(getSuggestedVendorContracts);
    const vendorSelectionState = useSelector((state) =>
        requisitionsCreateFormValueSelector(state, VENDOR_SELECTION_STATE)
    );
    const selectedVendors = useSelector((state) =>
        requisitionsCreateFormValueSelector(state, VENDORS)
    );

    const hasFMS = useSelector(getIsRequisitionUsingFMS);

    const dispatch = useDispatch();
    const addVendor = (vendorName, contract) => {
        dispatch(
            setInitialVendorSelectionInputData({
                initialSearch: vendorName,
                showInput: true,
                suggestedContract: contract,
            })
        );
        editHandler();
    };

    const suggestedVendorHandler = (vendorData, contract) => {
        if (!vendorData) {
            return;
        }

        if (hasFMS) {
            addVendor(vendorData[VENDOR_NAME], contract);
            return;
        }

        dispatch(
            change(formConfig.form, VENDOR_SELECTION_STATE, vendorSelectionStates.SELECTED_VENDORS)
        );
        dispatch(arrayPush(formConfig.form, VENDORS, vendorData));
        editHandler();
    };

    const hasNoVendors =
        !selectedVendors?.length || vendorSelectionState === vendorSelectionStates.NO_VENDORS;
    const hasSuggestedVendors = suggestedVendorContracts.length > 0;
    const canAddVendor = useSelector(getCanAddVendor);

    return (
        <Box display="flex" flexDirection="column" gap={1.5}>
            <DisplayVendors selectedVendors={selectedVendors} />
            {isEditable && canAddVendor && hasSuggestedVendors && (
                <SuggestedVendors
                    defaultExpanded={hasNoVendors}
                    suggestedVendorContracts={suggestedVendorContracts}
                    suggestedVendorHandler={suggestedVendorHandler}
                />
            )}
            {hasNoVendors && (!hasSuggestedVendors || !isEditable) && <NoVendors />}
            {isEditable && <AddVendor canAddVendor={canAddVendor} onClick={() => addVendor()} />}
            <AdditionalVendorNotes />
        </Box>
    );
};

ReviewVendorSelection.propTypes = {
    editHandler: PropTypes.func.isRequired,
    isEditable: PropTypes.bool,
};

import { ReduxFormTextField } from '@og-pro/ui';
import React from 'react';
import { useSelector } from 'react-redux';
import { Field, getFormValues } from 'redux-form';

import { AttachMoneyOutlined, PercentOutlined, UnfoldMoreOutlined } from '@mui/icons-material';
import {
    NUMBER_KIND_FIELD,
    createCustomFieldFormName,
    customFieldFields,
    instructionsModes,
    numberKinds,
} from '@og-pro/shared-config/customFormService/customField';

const { NAME, INSTRUCTIONS_TEXT, INSTRUCTIONS_MODE } = customFieldFields;
const { TOOLTIP, DESCRIPTION } = instructionsModes;

export const NumberPreview = () => {
    const formValues =
        useSelector((state) => getFormValues(createCustomFieldFormName)(state)) || {};
    const instructionsText = formValues[INSTRUCTIONS_TEXT];
    const numberKind = formValues[NUMBER_KIND_FIELD];

    const getIcons = (kind) => {
        switch (kind) {
            case numberKinds.DECIMAL:
            case numberKinds.WHOLE:
                return {
                    endIcon: <UnfoldMoreOutlined />,
                };
            case numberKinds.PERCENTAGE:
                return {
                    endIcon: <PercentOutlined />,
                };
            case numberKinds.CURRENCY:
                return {
                    startIcon: <AttachMoneyOutlined />,
                };
            default:
                return {};
        }
    };

    const { startIcon, endIcon } = getIcons(numberKind);

    return (
        <Field
            component={ReduxFormTextField}
            description={
                formValues[INSTRUCTIONS_MODE] === DESCRIPTION ? instructionsText : undefined
            }
            disabled
            endIcon={endIcon}
            label={formValues[NAME] || 'Your title will go here'}
            name="number_PREVIEW"
            startIcon={startIcon}
            sx={{
                width: '100%',
            }}
            tooltip={formValues[INSTRUCTIONS_MODE] === TOOLTIP ? instructionsText : undefined}
        />
    );
};

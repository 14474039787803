import React from 'react';
import PropTypes from 'prop-types';

const HighlightedText = ({ highlight, text }) => {
    const styles = require('./HighlightedText.scss');
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));

    if (parts.length === 1) {
        // Nothing to highlight; return the input string
        return text;
    }

    return parts.map((part, i) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
            <mark className={styles.highlighted} key={i}>
                <strong>{part}</strong>
            </mark>
        ) : (
            part
        )
    );
};

HighlightedText.propTypes = {
    highlight: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
};

export default HighlightedText;

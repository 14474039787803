import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { columns } from './helpers';
import { Button } from '../../../../components';
import { exportArrayToCSV } from '../../../../utils';

export class PurchaseOrderImportComplete extends PureComponent {
    static propTypes = {
        createPurchaseOrders: PropTypes.array.isRequired,
        duplicatePurchaseOrders: PropTypes.array.isRequired,
        existingPurchaseOrders: PropTypes.array.isRequired,
        hideModal: PropTypes.func.isRequired,
        invalidPaidAmountPurchaseOrders: PropTypes.array,
        unmatchedPurchaseOrders: PropTypes.array.isRequired,
    };

    get styles() {
        return require('./PurchaseOrderImportComplete.scss');
    }

    downloadImportCSV = () => {
        const {
            createPurchaseOrders,
            duplicatePurchaseOrders,
            existingPurchaseOrders,
            invalidPaidAmountPurchaseOrders,
            unmatchedPurchaseOrders,
        } = this.props;

        const headers = columns.map((column) => column.header).concat('Log');
        const rows = [];
        [
            { purchaseOrders: createPurchaseOrders, log: 'Created new purchase order' },
            { purchaseOrders: existingPurchaseOrders, log: 'Updated existing purchase order' },
            {
                purchaseOrders: duplicatePurchaseOrders,
                log: 'Skipped: Multiple matching contracts. Please enter a unique Contract ID and/or Vendor ID so the purchase order can be matched to a single contract.',
            },
            {
                purchaseOrders: unmatchedPurchaseOrders,
                log: 'Skipped: No matching contract found. Please check that the entered Contract ID and/or Vendor ID appears on a contract.',
            },
            {
                purchaseOrders: invalidPaidAmountPurchaseOrders,
                log: 'Skipped: Paid amount is larger than order amount.',
            },
        ].forEach(({ purchaseOrders, log }) => {
            purchaseOrders.forEach((purchaseOrder) => {
                const purchaseOrderData = columns.map((column) => {
                    let value = purchaseOrder[column.itemProperty];
                    if (column.csvFormatter) {
                        value = column.csvFormatter(purchaseOrder);
                    }
                    return isNil(value) ? '' : value;
                });
                rows.push(purchaseOrderData.concat(log));
            });
        });

        const csvData = [headers].concat(rows);

        exportArrayToCSV(csvData, {
            fileName: 'purchase_order_import_log.csv',
            headers: true,
            timestamp: true,
        });
    };

    render() {
        const {
            createPurchaseOrders,
            duplicatePurchaseOrders,
            existingPurchaseOrders,
            hideModal,
            invalidPaidAmountPurchaseOrders,
            unmatchedPurchaseOrders,
        } = this.props;

        return (
            <div className={this.styles.container}>
                <i className="fa fa-5x fa-check-circle text-success" />
                <br />
                {[
                    {
                        purchaseOrders: createPurchaseOrders,
                        text: 'New Purchase Orders Created',
                    },
                    {
                        purchaseOrders: existingPurchaseOrders,
                        text: 'Existing Purchase Orders Updated',
                    },
                    {
                        purchaseOrders: duplicatePurchaseOrders,
                        text: 'Skipped: Multiple Matching Contracts',
                    },
                    {
                        purchaseOrders: unmatchedPurchaseOrders,
                        text: 'Skipped: No Matching Contract Found',
                    },
                    {
                        purchaseOrders: invalidPaidAmountPurchaseOrders,
                        text: 'Skipped: Purchases order(s) with Paid Amount Larger than Order Amount',
                    },
                ].map(
                    ({ purchaseOrders, text }, index) =>
                        purchaseOrders.length > 0 && (
                            <React.Fragment key={index}>
                                <strong>{purchaseOrders.length}</strong> {text}
                                <br />
                            </React.Fragment>
                        )
                )}
                <br />
                <Button onClick={this.downloadImportCSV}>
                    <i className="fa fa-download" /> Download Import Log
                </Button>
                &nbsp;&nbsp;
                <Button onClick={hideModal}>Done</Button>
            </div>
        );
    }
}

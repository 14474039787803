import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { Outlet, createSearchParams } from 'react-router-dom';

import {
    ProjectOverviewReview,
    ProposalDocumentsReview,
    EvaluationCriteriaReview,
    EvaluatorsReview,
    QuestionnaireReview,
} from './components';
import {
    getNavItems,
    ADDENDA,
    CRITERIA,
    EVALUATORS,
    OVERVIEW,
    PROPOSALS,
    QUESTION_ANSWER,
    QUESTIONNAIRE,
} from './constants';
import { getTotalWeight, hasQuestionnaire } from '../selectors';
import {
    getAgreementsJS,
    getCurrentEvaluation,
    getEvaluationPath,
    getProjectStandardDocJS,
    getReviewProjectJS,
} from '../../selectors';
import { Nav, NavItem } from '../../../../components';

const mapStateToProps = (state, props) => {
    return {
        agreements: getAgreementsJS(state),
        hasQuestionnaire: hasQuestionnaire(state),
        evaluation: getCurrentEvaluation(state, props),
        evaluationPath: getEvaluationPath(state, props),
        project: getReviewProjectJS(state),
        standardDocument: getProjectStandardDocJS(state),
        totalWeight: getTotalWeight(state, props),
    };
};

// @connect
class ConnectedEvaluationOverview extends Component {
    static propTypes = {
        agreements: PropTypes.array.isRequired,
        evaluation: PropTypes.object,
        evaluationPath: PropTypes.string.isRequired,
        hasQuestionnaire: PropTypes.bool.isRequired,
        location: PropTypes.shape({
            query: PropTypes.shape({
                section: PropTypes.string,
            }).isRequired,
            pathname: PropTypes.string.isRequired,
        }).isRequired,
        project: PropTypes.shape({
            questionnaires: PropTypes.array.isRequired,
            wasPosted: PropTypes.bool.isRequired,
        }),
        standardDocument: PropTypes.object,
        totalWeight: PropTypes.number.isRequired,
    };

    get styles() {
        return require('./EvaluationOverview.scss');
    }

    renderNavItems() {
        const {
            evaluationPath,
            hasQuestionnaire: hasQuestionnaireSection,
            location: { query },
            project: { wasPosted },
        } = this.props;

        const navItemsData = getNavItems(hasQuestionnaireSection, wasPosted);

        const NavItems = navItemsData.map(({ title, section, isRoute }, idx) => {
            const route = {
                pathname: isRoute ? `${evaluationPath}/${section}` : evaluationPath,
                search: createSearchParams({ ...query, section }).toString(),
            };
            const activeClass =
                (!query.section && idx === 0) || section === query?.section ? 'active' : '';
            return (
                <NavItem
                    className={activeClass}
                    key={title}
                    qaTag={`evaluation-${title}`}
                    to={route}
                >
                    {title}
                </NavItem>
            );
        });

        return (
            <div className={`${this.styles.navContainer} no-print`}>
                <Nav bsStyle="pills" stacked>
                    {NavItems}
                </Nav>
            </div>
        );
    }

    renderEvaluationSection() {
        const {
            agreements,
            location,
            evaluation: { evaluators, proposalDocuments, scoringCriteria },
            project,
            project: { questionnaires },
            standardDocument,
            totalWeight,
        } = this.props;

        switch (location.query.section) {
            case QUESTIONNAIRE:
                return <QuestionnaireReview questionnaires={questionnaires} />;
            case PROPOSALS:
                return <ProposalDocumentsReview proposalDocuments={proposalDocuments} />;
            case CRITERIA: {
                return (
                    <EvaluationCriteriaReview
                        scoringCriteria={scoringCriteria}
                        totalWeight={totalWeight}
                    />
                );
            }
            case EVALUATORS:
                return (
                    <EvaluatorsReview
                        agreements={agreements}
                        evaluators={evaluators}
                        standardDocument={standardDocument}
                    />
                );
            case ADDENDA:
            case QUESTION_ANSWER:
                return <Outlet />;
            case OVERVIEW:
            default: {
                if (
                    location.pathname.endsWith(ADDENDA) ||
                    location.pathname.endsWith(QUESTION_ANSWER)
                ) {
                    return <Outlet />;
                }

                return <ProjectOverviewReview project={project} />;
            }
        }
    }

    render() {
        const { project } = this.props;

        if (!project) {
            return null;
        }

        return (
            <div className="row">
                <div className="col-sm-3 col-xs-12">{this.renderNavItems()}</div>
                <div className="col-sm-9 col-xs-12">
                    <div className="row">
                        <div className="col-xs-12 col-sm-offset-1 col-sm-10 project-section">
                            {this.renderEvaluationSection()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export const EvaluationOverview = compose(
    withRouter,
    connect(mapStateToProps)
)(ConnectedEvaluationOverview);

export function password(values) {
    const errors = {};

    if (!values.newPassword1) {
        errors.newPassword1 = 'Password field is required';
    } else if (values.newPassword1.length < 12) {
        errors.newPassword1 = 'Password must be 12 or more characters in length';
    }

    if (!values.newPassword2) {
        errors.newPassword2 = 'Password confirmation field is required';
    } else if (values.newPassword1 !== values.newPassword2) {
        errors.newPassword2 = 'Passwords must be identical';
    }

    return errors;
}

const categorySets = {
    NIGP: 100,
    NAICS: 200,
    UNSPSC: 300,
};

exports.categorySets = categorySets;

exports.categorySetsArray = [categorySets.NIGP, categorySets.NAICS, categorySets.UNSPSC];

exports.categoryNames = {
    [categorySets.NIGP]: 'NIGP',
    [categorySets.NAICS]: 'NAICS',
    [categorySets.UNSPSC]: 'UNSPSC',
};

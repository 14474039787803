import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { reduxForm, Field } from 'redux-form';

import { form, fieldNames } from './constants';
import { InputText, Button } from '../../..';
import { validate } from './validate';
import { ResolveButton } from '../ResolveButton';

const formConfig = {
    form,
    validate,
};

// @reduxForm
class ConnectedCommentReplyForm extends PureComponent {
    static propTypes = {
        canComment: PropTypes.bool.isRequired,
        dirty: PropTypes.bool,
        handleSubmit: PropTypes.func.isRequired,
        replyError: PropTypes.string,
        reset: PropTypes.func.isRequired,
        resolveClickHandler: PropTypes.func.isRequired,
        resolveError: PropTypes.string,
        resolving: PropTypes.bool,
        submitHandler: PropTypes.func.isRequired,
        submitting: PropTypes.bool.isRequired,
        threadId: PropTypes.number.isRequired,
    };

    submitHandler = (data) => {
        return this.props.submitHandler(data).then(this.props.reset);
    };

    render() {
        const {
            canComment,
            dirty,
            handleSubmit,
            replyError,
            resolveClickHandler,
            resolveError,
            resolving,
            submitting,
            threadId,
        } = this.props;
        const styles = require('./CommentReplyForm.scss');

        return (
            <form className="clearfix" onSubmit={handleSubmit(this.submitHandler)}>
                <Field
                    aria-label="Reply to comments"
                    component={InputText}
                    disabled={!canComment || submitting || resolving}
                    minRows={2}
                    name={fieldNames.COMMENT}
                    placeholder="Reply to comments"
                    type="textarea"
                />
                <div>
                    <ResolveButton
                        disabled={!canComment || resolving || submitting || dirty}
                        resolveClickHandler={resolveClickHandler}
                        threadId={threadId}
                    />
                    <Button
                        bsStyle="primary"
                        className={styles.replyButton}
                        disabled={!canComment || submitting || resolving}
                        qaTag="connectedCommentReplyForm-reply"
                        type="submit"
                    >
                        {submitting ? 'Posting...' : 'Reply'}
                    </Button>
                </div>
                {(resolveError || replyError) && (
                    <div className="error-block">{resolveError || replyError}</div>
                )}
            </form>
        );
    }
}

export const CommentReplyForm = reduxForm(formConfig)(ConnectedCommentReplyForm);

import { Box, CircularProgress, Typography } from '@og-pro/ui';
import { circularProgressSizeProps } from '@opengov/capital-mui-theme';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const Spinner = ({ centered, size, text, useOpenGovStyle }) => {
    if (useOpenGovStyle) {
        return (
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 3,
                }}
            >
                <CircularProgress
                    {...circularProgressSizeProps[size]}
                    color="primaryShadowed"
                    size={size}
                />
                {text && <Typography variant="bodyLarge">{text}</Typography>}
            </Box>
        );
    }

    const styles = require('./LoadingSpinner.scss');
    const imageSrc = 'https://assets.procurement.opengov.com/assets/loading-spinner.gif';

    return (
        <>
            <img
                alt="Loading spinner"
                className={centered ? styles.loadingSpinnerCentered : styles.loadingSpinner}
                src={imageSrc}
            />
            {text && <div className={`${styles.text} text-info`}>{text}</div>}
        </>
    );
};

Spinner.propTypes = {
    centered: PropTypes.bool,
    size: PropTypes.string.isRequired,
    text: PropTypes.string,
    useOpenGovStyle: PropTypes.bool,
};

export const LoadingSpinner = ({
    centered,
    className,
    noPadding,
    size = 'large',
    text,
    useOpenGovStyle,
}) => {
    const styles = require('./LoadingSpinner.scss');

    return (
        <div className={classnames(!noPadding && styles.loadingSpinnerContainer, className)}>
            <Spinner
                centered={centered}
                size={size}
                text={text}
                useOpenGovStyle={useOpenGovStyle}
            />
        </div>
    );
};

LoadingSpinner.propTypes = {
    centered: PropTypes.bool,
    className: PropTypes.string,
    noPadding: PropTypes.bool,
    size: PropTypes.string,
    text: PropTypes.string,
    useOpenGovStyle: PropTypes.bool,
};

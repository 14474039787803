import { resourceManager, trackEvent } from '../helpers';

export const discoverVendors = (project, data) => {
    trackEvent('Discover Vendors AI', { projectId: project.id });

    return resourceManager({
        method: 'post',
        url: `/government/${project.government_id}/generative-ai`,
        requestOptions: { data },
        onSuccess: ({ result }) => {
            return result?.generativeAiChat?.content?.vendors || [];
        },
    });
};

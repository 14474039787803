import PropTypes from 'prop-types';
import React from 'react';

import { getPrimaryAndSecondaryApprovers } from './helpers';
import { StepApprovalGroup } from '../SequenceStep/StepApprovalGroup';

export const SinglePositionApproval = ({ stepPositionApproval }) => {
    const { position, stepPositionApprovers } = stepPositionApproval;

    const { primaryApprovers, secondaryApprovers } =
        getPrimaryAndSecondaryApprovers(stepPositionApprovers);

    return (
        <>
            <StepApprovalGroup
                approvers={primaryApprovers.map(({ user }) => user)}
                name={position.name}
            />
            <StepApprovalGroup
                approvers={secondaryApprovers.map(({ user }) => user)}
                name="Secondary Approvers"
            />
        </>
    );
};

SinglePositionApproval.propTypes = {
    stepPositionApproval: PropTypes.shape({
        position: PropTypes.shape({
            name: PropTypes.string.isRequired,
        }),
        stepPositionApprovers: PropTypes.array.isRequired,
    }).isRequired,
};

import { createSelector } from 'reselect';

const getSearch = (state) => state.searchProjects.get('searchResults');

export const getSearchResults = createSelector([getSearch], (searchResults) => {
    if (!searchResults) {
        return { rows: 0, projects: [] };
    }

    return searchResults.toJS();
});

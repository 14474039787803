import PropTypes from 'prop-types';
import { isString } from 'lodash';
import React, { PureComponent } from 'react';

import { HelpIcon, PageTitle } from '..';

export class SectionTitle extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        help: PropTypes.string,
        helpPlacement: PropTypes.string,
        helpStyle: PropTypes.object,
        info: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        onHelpClick: PropTypes.func,
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
        titlePlacement: PropTypes.string,
        useOpenGovStyle: PropTypes.bool,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const {
            className,
            help,
            helpPlacement,
            helpStyle,
            info,
            onHelpClick,
            title,
            titlePlacement,
            useOpenGovStyle,
        } = this.props;

        const {
            centerTitle,
            helpIcon,
            info: infoStyles,
            leftTitle,
            title: titleStyles,
            titleOnly,
        } = this.styles;

        const titleStyle = titlePlacement === 'left' ? leftTitle : centerTitle;

        return (
            <div className={className}>
                <span className={titleStyle}>
                    {isString(title) && <PageTitle title={title} />}
                    <h2 className={info ? titleStyles : titleOnly}>
                        {title}
                        {(help || onHelpClick) && (
                            <HelpIcon
                                className={helpIcon}
                                onClick={onHelpClick}
                                placement={helpPlacement}
                                style={helpStyle}
                                tooltip={onHelpClick ? undefined : help}
                                useOpenGovStyle={useOpenGovStyle}
                            />
                        )}
                    </h2>
                    {info && <div className={`text-muted ${infoStyles}`}>{info}</div>}
                </span>
            </div>
        );
    }
}

import { PROJECT_TYPE, SECTION_TYPE, USER_ID } from './constants';

export const validate = (values) => {
    const errors = {};

    if (!values[PROJECT_TYPE]) {
        errors[PROJECT_TYPE] = 'Please select a template type';
    }

    if (!values[SECTION_TYPE]) {
        errors[SECTION_TYPE] = 'Please select a section type';
    }

    if (!values[USER_ID]) {
        errors[USER_ID] = 'Please select an owner';
    }

    return errors;
};

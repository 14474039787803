import {
    hideConfirmationModal,
    showConfirmationModalError,
    updatingConfirmationModal,
} from './confirmation';
import { showSnackbar } from './notification';
import { emitProjectSocket } from './utils';

export const UPDATE = 'gov/approvals/UPDATE';
export const UPDATE_SUCCESS = 'gov/approvals/UPDATE_SUCCESS';
export const UPDATE_FAIL = 'gov/approvals/UPDATE_FAIL';

/**
 * Updates a ProjectApproval when user clicks Approve or Reject
 * @param {number} approvalId ID of ProjectApproval instance to update
 * @param {object} data request data object
 * @param {string} [data.comment] comment written by user on an approval
 * @param {string} [data.status] 'approved' or 'rejected' status
 * @returns {Promise} request to resolve
 */
export function updateApproval(approvalId, data) {
    return (dispatch, getState, client) => {
        dispatch({ type: UPDATE });
        return client
            .put(`/approval/${approvalId}`, { data })
            .then((result) => {
                const projectId = getState().govProjects.getIn(['selectedProject', 'id']);
                const updateAction = { type: UPDATE_SUCCESS, approvalId, result };
                dispatch(emitProjectSocket(projectId, updateAction));
            })
            .catch((error) => {
                dispatch({ type: UPDATE_FAIL, error });
            });
    };
}

export function approveProject(approvalId, data) {
    return updateApproval(approvalId, { ...data, status: 'approved' });
}

export function rejectProject(approvalId, data) {
    return updateApproval(approvalId, { ...data, status: 'rejected' });
}

export function resendApproval(approvalId) {
    return (dispatch, getState, client) => {
        dispatch({ type: UPDATE });
        return client
            .put(`/approval/${approvalId}/resend`)
            .then((result) => {
                const projectId = getState().govProjects.getIn(['selectedProject', 'id']);
                const updateAction = { type: UPDATE_SUCCESS, approvalId, result };
                dispatch(emitProjectSocket(projectId, updateAction));
            })
            .catch((error) => {
                dispatch({ type: UPDATE_FAIL, error });
            });
    };
}

// Checks whether user is approver and has ever viewed project. Updates the
// approval with the viewed at date if user is approver and has never viewed.
function handleInitialApprovalView(projectApprovalSteps) {
    return (dispatch, getState) => {
        const userId = getState().auth.getIn(['user', 'id']);

        const unviewedApprovals = [];

        projectApprovalSteps.forEach((step) => {
            const approvalToUpdate = step.projectApprovals.find(
                (approval) => approval.user.id === userId && !approval.viewedAt
            );

            if (approvalToUpdate) {
                unviewedApprovals.push(approvalToUpdate);
            }
        });

        unviewedApprovals.forEach((neverViewedApproval) =>
            dispatch(
                updateApproval(neverViewedApproval.id, {
                    viewedAt: new Date(),
                })
            )
        );
    };
}

export const LOAD = 'gov/approvals/LOAD';
export const LOAD_SUCCESS = 'gov/approvals/LOAD_SUCCESS';
export const LOAD_FAIL = 'gov/approvals/LOAD_FAIL';

/**
 * Load approvals for specified project
 * @param  {number} projectId Project ID to load approvals
 * @param  {object} [opts={}] Options object
 * @param  {boolean} [opts.broadcast] Whether to broadcast the approval event
 * @return {Promise} Request to resolve
 */
export function loadApprovals(projectId, opts = {}) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD });
        return client
            .get(`/project/${projectId}/approval`)
            .then((result) => {
                const loadAction = { type: LOAD_SUCCESS, result };

                // Sometimes we want to update all connected clients with the loaded approvals data
                if (opts.broadcast) {
                    dispatch(emitProjectSocket(projectId, loadAction));
                } else {
                    dispatch(loadAction);
                }

                dispatch(handleInitialApprovalView(result));
                return result;
            })
            .catch((error) => {
                dispatch({ type: LOAD_FAIL, error });
            });
    };
}

export const ADD = 'gov/approvals/ADD';
export const ADD_SUCCESS = 'gov/approvals/ADD_SUCCESS';
export const ADD_FAIL = 'gov/approvals/ADD_FAIL';

export function addApprovers(projectId, userIds) {
    return (dispatch, getState, client) => {
        const data = { userIds };
        dispatch({ type: ADD });
        return client
            .post(`/project/${projectId}/approval`, { data })
            .then((result) => {
                dispatch(emitProjectSocket(projectId, { type: ADD_SUCCESS, result }));
                return result;
            })
            .catch((error) => {
                dispatch({ type: ADD_FAIL, error });
                return error;
            });
    };
}

export const UPDATE_APPROVERS = 'gov/approvals/UPDATE_APPROVERS';
export const UPDATE_APPROVERS_SUCCESS = 'gov/approvals/UPDATE_APPROVERS_SUCCESS';
export const UPDATE_APPROVERS_FAIL = 'gov/approvals/UPDATE_APPROVERS_FAIL';

export function updateApprovers(projectId, userIds, reviewStepId) {
    return (dispatch, getState, client) => {
        const data = { reviewStepId, userIds };
        dispatch({ type: UPDATE_APPROVERS });
        return client
            .put(`/project/${projectId}/approval`, { data })
            .then((result) => {
                dispatch(showSnackbar('Review Step Updated'));
                dispatch(
                    emitProjectSocket(projectId, {
                        type: UPDATE_APPROVERS_SUCCESS,
                        result,
                        reviewStepId,
                    })
                );
                return result;
            })
            .catch((error) => {
                dispatch({ type: UPDATE_APPROVERS_FAIL, error });
                return error;
            });
    };
}

export const REMOVE = 'gov/approvals/REMOVE';
export const REMOVE_SUCCESS = 'gov/approvals/REMOVE_SUCCESS';
export const REMOVE_FAIL = 'gov/approvals/REMOVE_FAIL';

export function removeApprover(approvalId, reviewStepId) {
    return (dispatch, getState, client) => {
        dispatch({ type: REMOVE, approvalId, reviewStepId });
        return client
            .del(`/approval/${approvalId}`)
            .then(() => {
                const projectId = getState().govProjects.getIn(['selectedProject', 'id']);
                dispatch(
                    emitProjectSocket(projectId, {
                        type: REMOVE_SUCCESS,
                        approvalId,
                        reviewStepId,
                    })
                );
            })
            .catch((error) => {
                dispatch({ type: REMOVE_FAIL, approvalId, reviewStepId, error });
            });
    };
}

export const REMOVE_REVIEW_STEP = 'gov/approvals/REMOVE_REVIEW_STEP';
export const REMOVE_REVIEW_STEP_SUCCESS = 'gov/approvals/REMOVE_REVIEW_STEP_SUCCESS';
export const REMOVE_REVIEW_STEP_FAIL = 'gov/approvals/REMOVE_REVIEW_STEP_FAIL';

export function removeReviewStep(projectId, reviewStepId) {
    return (dispatch, getState, client) => {
        dispatch({ type: REMOVE_REVIEW_STEP, reviewStepId });
        return client
            .del(`/project/${projectId}/approval/${reviewStepId}`)
            .then(() => {
                dispatch(
                    emitProjectSocket(projectId, {
                        type: REMOVE_REVIEW_STEP_SUCCESS,
                        reviewStepId,
                    })
                );
            })
            .catch((error) => {
                dispatch({ type: REMOVE_REVIEW_STEP_FAIL, reviewStepId, error });
            });
    };
}

export const REPLACE_PROJECT_APPROVALS_SUCCESS = 'gov/approvals/REPLACE_PROJECT_APPROVALS_SUCCESS';

export function replaceProjectApprovals(projectId, departmentId, opts = {}) {
    return (dispatch, getState, client) => {
        dispatch(updatingConfirmationModal());

        return client
            .put(`/project/${projectId}/replace-project-approvals/${departmentId}`)
            .then((result) => {
                dispatch(
                    emitProjectSocket(projectId, {
                        type: REPLACE_PROJECT_APPROVALS_SUCCESS,
                        result,
                    })
                );
                dispatch(hideConfirmationModal());

                if (opts.onComplete) {
                    opts.onComplete();
                }
            })
            .catch((error) => {
                dispatch(showConfirmationModalError(error.message));
            });
    };
}

export const REMIND = 'gov/approvals/REMIND';
export const REMIND_SUCCESS = 'gov/approvals/REMIND_SUCCESS';
export const REMIND_FAIL = 'gov/approvals/REMIND_FAIL';

export function approvalReminder(approvalId, reReview = false, comment, reviewStepId) {
    return (dispatch, getState, client) => {
        const data = { comment, reReview };
        dispatch({ type: REMIND, approvalId, reviewStepId });
        return client
            .post(`/approval/${approvalId}/reminder`, { data })
            .then(() => {
                dispatch({ type: REMIND_SUCCESS, approvalId, reviewStepId });
            })
            .catch((error) => {
                dispatch({ type: REMIND_FAIL, approvalId, reviewStepId, error });
            });
    };
}

// Check if approvals needs to be loaded for the project
export function shouldLoadApprovals(state) {
    if (state.approvals.get('loading')) return false;

    return !state.approvals.get('loaded');
}

export const SHOW_MODAL = 'gov/approvals/SHOW_MODAL';
export const HIDE_MODAL = 'gov/approvals/HIDE_MODAL';

export function showModal(approvalType, data) {
    return { type: SHOW_MODAL, approvalType, data };
}

export function hideModal() {
    return { type: HIDE_MODAL };
}

export const RESET = 'gov/approvals/RESET';

export function resetApprovals() {
    return { type: RESET };
}

import { createSelector } from 'reselect';
import { get } from 'lodash';

import { sections, sectionsFilter } from './constants';
import { getUserJS } from '../../containers/selectors';

const getHasQuestionnaire = (props) => (get(props, 'proposal.questionnaires') || []).length > 0;
const getHasPriceTable = (props) => (get(props, 'proposal.priceTables') || []).length > 0;
const getHasAddendumSection = (props) => get(props, 'proposal.hasAddendumSection');
const getSkipCompanyPropfile = (props) => props.skipCompanyProfile;

export const getSections = createSelector(
    [getHasQuestionnaire, getHasPriceTable, getHasAddendumSection, getSkipCompanyPropfile],
    (hasQuestionnaire, hasPriceTable, hasAddendumSection, skipCompanyProfile) => {
        return sections.filter((section) => {
            return sectionsFilter(section, {
                hasAddendumSection,
                hasQuestionnaire,
                hasPriceTable,
                skipCompanyProfile,
            });
        });
    }
);

export const isVendorProfileComplete = createSelector([getUserJS], (user) => {
    return user.organization.isVendorComplete;
});

export const getProposalSyncEvent = createSelector(
    [(state) => state.vendProposals.get('proposalSyncEvent')],
    (rawSyncEvent) => {
        if (rawSyncEvent) {
            return rawSyncEvent.toJS();
        }
    }
);

export const getConnectedClients = createSelector(
    [(state) => state.vendProposals.get('connectedClients')],
    (connectedClients) => {
        return connectedClients.toJS();
    }
);

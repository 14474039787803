import React from 'react';
import classnames from 'classnames';
import { Box, Typography } from '@og-pro/ui';

export const TemplateEditNoSections = () => {
    const styles = require('./NoSections.scss');

    return (
        <Box className={styles.container}>
            <Box mb={3}>
                <i className={classnames('fa fa-list-ol', styles.icon)} />
            </Box>
            <Typography variant="h3">No Sections Defined Yet</Typography>
            <Box>Add a section in order to start adding content to this document.</Box>
        </Box>
    );
};

import moment from 'moment-timezone/builds/moment-timezone-with-data-1970-2030';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { DropdownButton, Panel } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import { withRouter } from '@og-pro-migration-tools/react-router';

import {
    getInvitedVendorListJS,
    getInvitedVendorOutstandingCount,
    getLoadingInvitedVendorList,
} from '../selectors';
import VendorManagementHeader from '../VendorManagementHeader';
import { getPortalUrl } from '../../App/selectors';
import connectData from '../../../ConnectData';
import {
    inviteVendorsToPortal,
    loadInvitedVendorList,
    removeVendorSubscription,
    shouldLoadInvitedVendorList,
} from '../../../../actions/vendorList';
import {
    DataTable,
    LoadingSpinner,
    Main,
    MenuItem,
    ProgressBar,
    ZeroState,
} from '../../../../components';

const MAX_ROWS = 100;
const MINIMUM_OVERFLOW_ROWS_FOR_PAGINATION = 100;

function fetchData(getState, dispatch, location, params) {
    const promises = [];
    const state = getState();
    const governmentId = Number.parseInt(params.governmentId, 10);

    if (shouldLoadInvitedVendorList(state)) {
        promises.push(dispatch(loadInvitedVendorList(governmentId)));
    }

    return Promise.all(promises);
}

const mapStateToProps = (state, props) => {
    const governmentId = Number.parseInt(props.params.governmentId, 10);
    return {
        governmentId,
        invitedVendorList: getInvitedVendorListJS(state),
        invitedVendorOutstandingCount: getInvitedVendorOutstandingCount(state),
        loading: getLoadingInvitedVendorList(state),
        portalUrl: getPortalUrl(state),
    };
};

const mapDispatchToProps = {
    inviteVendorsToPortal,
    removeVendorSubscription,
};

export class ConnectedInvitedVendorList extends PureComponent {
    static propTypes = {
        governmentId: PropTypes.number.isRequired,
        invitedVendorList: PropTypes.arrayOf(
            PropTypes.shape({
                email: PropTypes.string.isRequired,
                governmentSubscriptionId: PropTypes.number.isRequired,
                id: PropTypes.number.isRequired,
                initialInviteSent: PropTypes.string.isRequired,
                latestInviteSent: PropTypes.string.isRequired,
                isConverted: PropTypes.bool.isRequired,
            })
        ).isRequired,
        invitedVendorOutstandingCount: PropTypes.number.isRequired,
        inviteVendorsToPortal: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        location: PropTypes.object.isRequired,
        portalUrl: PropTypes.string.isRequired,
        removeVendorSubscription: PropTypes.func.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    formatDataForCSVExport = (data) => {
        const headers = ['Email', 'Status', 'Initial Invite Sent', 'Latest Invite Sent'];

        const rows = data.map((dataRow) => {
            return [
                dataRow.email,
                dataRow.isConverted ? 'Accepted' : 'Invited',
                moment(dataRow.initialInviteSent).format('lll'),
                moment(dataRow.latestInviteSent).format('lll'),
            ];
        });

        return [headers].concat(rows);
    };

    removeVendorSubscription = (governmentSubscriptionId) => {
        const { governmentId } = this.props;

        this.props.removeVendorSubscription(governmentId, governmentSubscriptionId);
    };

    resendInvite = (email) => {
        const { governmentId } = this.props;

        this.props.inviteVendorsToPortal(governmentId, { emails: [email] }, { isReminder: true });
    };

    progressBar() {
        const { invitedVendorList, invitedVendorOutstandingCount } = this.props;

        const invitedVendors = invitedVendorList.length;

        const acceptedVendors = invitedVendors - invitedVendorOutstandingCount;

        return (
            <div className={this.styles.progressBar}>
                <ProgressBar noBottomMargin>
                    <ProgressBar key={1} max={invitedVendors} min={0} now={acceptedVendors} />
                    <ProgressBar
                        bsStyle="warning-dark"
                        key={2}
                        max={invitedVendors}
                        min={0}
                        now={invitedVendorOutstandingCount}
                    />
                </ProgressBar>
                <div className={classNames('row', this.styles.legend)}>
                    <div className="col-sm-6 text-center">
                        <span className={classNames(this.styles.dot, this.styles.accepted)} />
                        {`Accepted: ${acceptedVendors}`}
                    </div>
                    <div className="col-sm-6 text-center">
                        <span className={classNames(this.styles.dot, this.styles.pending)} />
                        {`Pending: ${invitedVendorOutstandingCount}`}
                    </div>
                </div>
            </div>
        );
    }

    body() {
        const { invitedVendorList, invitedVendorOutstandingCount } = this.props;

        if (invitedVendorList.length === 0) {
            return <ZeroState title="No Vendors Have Been Invited Yet" />;
        }

        const columns = [
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: 'Status',
                accessor: 'isConverted',
                className: this.styles.statusCol,
                maxWidth: 150,
                minWidth: 80,
                Cell: (props) => {
                    const statusEl = (
                        <span className={`text-${props.value ? 'success' : 'primary'}`}>
                            <i className={`fa fa-fw fa-${props.value ? 'check' : 'envelope-o'}`} />
                            &nbsp;
                            {props.value ? 'Accepted' : 'Invited'}
                        </span>
                    );

                    const { email, governmentSubscriptionId: subId } = props.original;
                    return (
                        <div>
                            {props.value ? (
                                statusEl
                            ) : (
                                <DropdownButton
                                    bsStyle="link"
                                    className={this.styles.inviteDropdownButton}
                                    id={`invited-users-options-${email}`}
                                    title={statusEl}
                                >
                                    <MenuItem
                                        onClick={() => this.resendInvite(email)}
                                        qaTag="vendorManagement-resendInvite"
                                    >
                                        <i className="fa fa-fw fa-send" /> Resend Invite
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => this.removeVendorSubscription(subId)}
                                        qaTag="vendorManagement-remove"
                                    >
                                        <i className="fa fa-fw fa-ban" /> Remove
                                    </MenuItem>
                                </DropdownButton>
                            )}
                        </div>
                    );
                },
            },
            {
                Header: 'Initial Invite Sent',
                accessor: 'initialInviteSent',
                maxWidth: 200,
                Cell: (props) => (
                    <div className="text-center" title={moment(props.value).format('lll')}>
                        {moment(props.value).fromNow()}
                    </div>
                ),
            },
            {
                Header: 'Latest Invite Sent',
                accessor: 'latestInviteSent',
                maxWidth: 200,
                Cell: (props) => (
                    <div className="text-center" title={moment(props.value).format('lll')}>
                        {moment(props.value).fromNow()}
                    </div>
                ),
            },
        ];

        const usePagination =
            invitedVendorList.length > MAX_ROWS + MINIMUM_OVERFLOW_ROWS_FOR_PAGINATION;

        return (
            <>
                <div className="panel-title">
                    Outstanding Vendor Invites ({invitedVendorOutstandingCount || 0})
                </div>
                {this.progressBar()}
                <DataTable
                    columns={columns}
                    csvExportOptions={{
                        fileName: 'Invited Vendors',
                        getFormattedCSVData: this.formatDataForCSVExport,
                        headers: true,
                    }}
                    data={invitedVendorList}
                    defaultPageSize={usePagination ? MAX_ROWS : invitedVendorList.length}
                    showCSVExport
                    showPagination={usePagination}
                />
            </>
        );
    }

    render() {
        const {
            governmentId,
            loading,
            location: { pathname },
            portalUrl,
        } = this.props;

        return (
            <div className={this.styles.container}>
                <Panel>
                    <Panel.Body>
                        <VendorManagementHeader
                            governmentId={governmentId}
                            pathname={pathname}
                            portalUrl={portalUrl}
                        />
                        <Main className="row">
                            <div className="col-xs-12 col-md-offset-2 col-md-8">
                                {loading ? (
                                    <LoadingSpinner text="Loading Vendor Invites" />
                                ) : (
                                    this.body()
                                )}
                            </div>
                        </Main>
                    </Panel.Body>
                </Panel>
            </div>
        );
    }
}

export const InvitedVendorList = compose(
    connectData(fetchData),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedInvitedVendorList);

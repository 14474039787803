import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const CreateCustomFieldContext = createContext({
    showFormValidation: false,
    setShowFormValidation: () => {},
    formErrorsCount: 0,
    setFormErrorsCount: () => {},
});

export const CreateCustomFieldProvider = ({ children }) => {
    const [showFormValidation, setShowFormValidation] = useState(false);
    const [formErrorsCount, setFormErrorsCount] = useState(0);

    return (
        <CreateCustomFieldContext.Provider
            value={{
                showFormValidation,
                setShowFormValidation,
                formErrorsCount,
                setFormErrorsCount,
            }}
        >
            {children}
        </CreateCustomFieldContext.Provider>
    );
};

CreateCustomFieldProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

import { listToDict } from '../../../../../utils';

export const form = 'questionAnswerFormQuestion';

export const fields = [
    'isSubstitutionRequest',
    'subject',
    'description',
    'substitutionAttachments',
    'questionFormHasErrors',
];

export const fieldNames = listToDict(fields);

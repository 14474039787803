import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Checkbox as BootstrapCheckbox } from 'react-bootstrap';
import { connect } from 'react-redux';
import { change, Field, formValueSelector } from 'redux-form';

import { DEFAULT_VALUE, INPUT_DATA, YES_NO } from '@og-pro/shared-config/questionnaires';

import { InputText, SearchSelect } from '../../..';

const mapStateToProps = (state, props) => {
    const defaultValue = formValueSelector(props.form)(state, `${INPUT_DATA}.${DEFAULT_VALUE}`);
    return {
        hasDefaultValue: !!defaultValue || defaultValue === false || defaultValue === 0,
    };
};

const mapDispatchToProps = {
    changeForm: change,
};

// @connect
export class ConnectedDefaultValueInput extends Component {
    static propTypes = {
        changeForm: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        form: PropTypes.string.isRequired,
        hasDefaultValue: PropTypes.bool.isRequired,
        type: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.state = {
            showDefaultValue: props.hasDefaultValue,
        };
    }

    handleDefaultCheckboxChange = () => {
        const { changeForm, form } = this.props;

        // Clear default value on checkbox change
        changeForm(form, INPUT_DATA, null);

        this.setState((prevState) => {
            return {
                showDefaultValue: !prevState.showDefaultValue,
            };
        });
    };

    renderDefaultValueInput() {
        const { disabled, type } = this.props;

        const help = 'This value will be inserted as the default for the variable';
        const name = `${INPUT_DATA}.${DEFAULT_VALUE}`;

        if (type === YES_NO) {
            return (
                <Field
                    component={SearchSelect}
                    disabled={disabled}
                    help={help}
                    isSearchable={false}
                    name={name}
                    options={[
                        { label: 'Yes', value: true },
                        { label: 'No', value: false },
                    ]}
                    placeholder="Select default variable value"
                />
            );
        }

        return (
            <Field
                component={InputText}
                disabled={disabled}
                hasFeedback={false}
                help={help}
                name={name}
                placeholder="Enter default variable value"
                type="text"
            />
        );
    }

    render() {
        const { showDefaultValue } = this.state;

        return (
            <div>
                <BootstrapCheckbox
                    checked={showDefaultValue}
                    onChange={this.handleDefaultCheckboxChange}
                >
                    Add default value
                </BootstrapCheckbox>
                {showDefaultValue && this.renderDefaultValueInput()}
            </div>
        );
    }
}

export const DefaultValueInput = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedDefaultValueInput);

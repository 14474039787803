import classnames from 'classnames';
import { Draggable } from 'react-beautiful-dnd';
import { Panel } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import {
    defaultColumnData,
    DESCRIPTION,
    LINE_ITEM,
    NO_BID,
    QUANTITY,
    TOTAL_COST,
    UNIT_TO_MEASURE,
    UNIT_PRICE,
} from '@og-pro/shared-config/priceTables';

import { AgGridReact, DragIcon } from '../../../../../../components';
import { Legend, OptionsModal, OptionsTitle, PriceTableButtons } from '../components';
import { suppressKeyboardEvent } from '../../../../../../helpers/agGrid';
import {
    AUTO_HEIGHT_ROW_THRESHOLD,
    CONTAINER_STYLE,
    PANEL_STYLES,
    PANEL_BODY_STYLES,
} from '../constants';
import {
    generateCellStyle,
    getFormattedPriceTableDataForGrid,
    getRowNodeId,
    processCellFromClipboard,
    unitPricePercentageFormatter,
} from '../helpers';
import { currencyFormatter } from '../../../../../../helpers';

export const ReverseAuctionPriceTableBuilder = (props) => {
    const {
        auctionMaxFractionDigits,
        change,
        copyPriceTable,
        disabled = false,
        draggableIndex,
        draggableName,
        fields,
        isReverseAuction,
        priceTable,
        priceTable: {
            description,
            hasPercentage,
            hasNoBid,
            hasQuantity,
            omitLineItem,
            priceItems,
            specifyQuantity,
            specifyUnitPrice,
            title,
        },
        priceTableArrayName,
        removeTable,
        showHelpModal,
        showValidation = false,
    } = props;

    const styles = require('../index.scss');

    const [gridApi, setGridApi] = useState(null);
    const [showOptionsModal, setShowOptionsModal] = useState(false);
    const [showTableSettingsPage, setShowTableSettingsPage] = useState(true);

    const unitPriceCurrencyFormatter = (data) =>
        currencyFormatter(data, {
            maximumFractionDigits: auctionMaxFractionDigits,
            useSameMinAndMax: true,
        });

    const columnDefinitions = [
        {
            field: LINE_ITEM,
            cellEditor: 'textEditor',
            cellEditorParams: {
                maxLength: 64,
            },
            headerComponentParams: {
                isRequired: true,
                tooltipText: 'All cells in this column must have a value',
            },
            headerName: priceTable[defaultColumnData[LINE_ITEM].headerField],
            suppressKeyboardEvent,
            width: 100,
        },
        {
            field: DESCRIPTION,
            autoHeight: true,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorParams: {
                maxLength: 3000,
                rows: 4,
            },
            flex: 1,
            headerComponentParams: {
                isRequired: true,
                tooltipText: 'All cells in this column must have a value',
            },
            headerName: priceTable[defaultColumnData[DESCRIPTION].headerField],
            minWidth: 210,
            suppressKeyboardEvent,
        },
        {
            field: QUANTITY,
            cellEditor: 'numericEditor',
            headerComponentParams: {
                isRequired: specifyQuantity,
                tooltipText: specifyQuantity
                    ? 'All cells in this column must have a value'
                    : 'Optionally provide values in this column. Empty cells will be filled in by the vendor.',
            },
            headerName: priceTable[defaultColumnData[QUANTITY].headerField],
            hide: !hasQuantity,
            suppressKeyboardEvent,
            width: 100,
        },
        {
            field: UNIT_TO_MEASURE,
            cellEditor: 'textEditor',
            cellEditorParams: {
                maxLength: 64,
            },
            headerComponentParams: {
                isRequired: true,
                tooltipText: 'All cells in this column must have a value',
            },
            headerName: priceTable[defaultColumnData[UNIT_TO_MEASURE].headerField],
            suppressKeyboardEvent,
            width: 140,
        },
        {
            field: UNIT_PRICE,
            editable: specifyUnitPrice,
            headerComponentParams: {
                icon: specifyUnitPrice ? undefined : 'fa-lock',
                tooltipText: specifyUnitPrice
                    ? 'Optionally provide values in this column. Empty cells will be filled in by the vendor.'
                    : 'Cells in this column are reserved for the vendor to complete.',
            },
            headerName: priceTable[defaultColumnData[UNIT_PRICE].headerField],
            cellEditor: 'numericEditor',
            cellEditorParams: {
                auctionMaxFractionDigits,
                isReverseAuction,
            },
            suppressKeyboardEvent: specifyUnitPrice ? suppressKeyboardEvent : undefined,
            suppressNavigable: !specifyUnitPrice,
            valueFormatter: hasPercentage
                ? unitPricePercentageFormatter
                : unitPriceCurrencyFormatter,
            width: 150,
        },
        {
            field: TOTAL_COST,
            headerComponentParams: {
                icon: 'fa-lock',
                tooltipText: 'Cells in this column are reserved for the vendor to complete',
            },
            headerName: priceTable[defaultColumnData[TOTAL_COST].headerField],
            editable: false,
            hide: !hasQuantity,
            suppressNavigable: true,
            width: 100,
        },
        {
            editable: false,
            field: NO_BID,
            headerComponentParams: {
                icon: 'fa-lock',
                tooltipText: 'Cells in this column are reserved for the vendor to complete',
            },
            headerName: priceTable[defaultColumnData[NO_BID].headerField],
            hide: !hasNoBid,
            suppressNavigable: true,
            width: 85,
        },
    ];

    const getCellStyle = (params) => {
        return generateCellStyle(
            {
                hasQuantity,
                omitLineItem,
                showValidation,
                specifyQuantity,
            },
            params
        );
    };

    const defaultColDef = {
        cellStyle: getCellStyle,
        editable: true,
        headerComponent: 'priceTableHeader',
        suppressMenu: true,
    };

    const handleGridReady = (params) => setGridApi(params.api);

    const handleCellValueChanged = (event) => {
        const columnFieldOfUpdatedCell = event.column.colDef.field;
        change(`${fields.name}[0].${columnFieldOfUpdatedCell}`, event.newValue);

        if (event.column.colDef.field === DESCRIPTION) {
            gridApi.resetRowHeights();
        }
    };

    const handleColumnChange = () => {
        // Hack to get the grid to redraw the rows with latest form data
        setTimeout(() => gridApi.redrawRows(), 1);
    };

    const handleOptionsToggleClick = () => {
        setShowOptionsModal((current) => !current);
        setShowTableSettingsPage(true);
    };

    return (
        <Draggable
            draggableId={draggableName}
            index={draggableIndex}
            isDragDisabled={disabled}
            key={draggableName}
        >
            {(provided) => (
                <div ref={provided.innerRef} {...provided.draggableProps}>
                    <Panel defaultExpanded style={PANEL_STYLES}>
                        <Panel.Body style={PANEL_BODY_STYLES}>
                            <div className={styles.tableHeader}>
                                <DragIcon
                                    containerClassName={classnames('pull-left', styles.dragIcon)}
                                    disabled={disabled}
                                    dragHandleProps={provided.dragHandleProps}
                                />
                                <OptionsTitle
                                    description={description}
                                    optionsToggleHandler={handleOptionsToggleClick}
                                    showHelpModal={showHelpModal}
                                    title={title}
                                />
                                <div className="row">
                                    <div className="col-xs-4">
                                        <Legend showValidation={showValidation} />
                                    </div>
                                    <div className="col-xs-8 text-right">
                                        <PriceTableButtons
                                            copyPriceTable={copyPriceTable}
                                            disabled={disabled}
                                            handleOptionsToggleClick={handleOptionsToggleClick}
                                            removeTable={removeTable}
                                        />
                                    </div>
                                </div>
                            </div>
                            <OptionsModal
                                arrayName={priceTableArrayName}
                                auctionMaxFractionDigits={auctionMaxFractionDigits}
                                change={change}
                                columnChangeHandler={handleColumnChange}
                                disabled={disabled}
                                fields={fields}
                                isReverseAuction
                                optionsToggleHandler={handleOptionsToggleClick}
                                priceTable={priceTable}
                                showOptionsModal={showOptionsModal}
                                showTableSettingsPage={showTableSettingsPage}
                                showValidation={showValidation}
                            />
                            <AgGridReact
                                autoHeightMaxRows={AUTO_HEIGHT_ROW_THRESHOLD}
                                columns={columnDefinitions}
                                containerStyle={CONTAINER_STYLE}
                                defaultColDef={defaultColDef}
                                getRowNodeId={getRowNodeId}
                                hideSideBar
                                onCellValueChanged={handleCellValueChanged}
                                onGridReady={handleGridReady}
                                processCellFromClipboard={processCellFromClipboard}
                                rows={getFormattedPriceTableDataForGrid(priceItems)}
                            />
                        </Panel.Body>
                    </Panel>
                </div>
            )}
        </Draggable>
    );
};

ReverseAuctionPriceTableBuilder.propTypes = {
    auctionMaxFractionDigits: PropTypes.number.isRequired,
    change: PropTypes.func.isRequired,
    copyPriceTable: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    draggableIndex: PropTypes.number.isRequired,
    draggableName: PropTypes.string.isRequired,
    fields: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }),
    isReverseAuction: PropTypes.bool.isRequired,
    priceTable: PropTypes.shape({
        description: PropTypes.string,
        hasPercentage: PropTypes.bool,
        hasNoBid: PropTypes.bool,
        hasQuantity: PropTypes.bool,
        hasTotalRow: PropTypes.bool,
        omitLineItem: PropTypes.bool,
        priceItems: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                [LINE_ITEM]: PropTypes.string,
                [DESCRIPTION]: PropTypes.string,
                [QUANTITY]: PropTypes.number,
                [UNIT_PRICE]: PropTypes.number,
                [UNIT_TO_MEASURE]: PropTypes.string,
            })
        ).isRequired,
        specifyQuantity: PropTypes.bool,
        specifyUnitPrice: PropTypes.bool,
        title: PropTypes.string,
    }),
    priceTableArrayName: PropTypes.string.isRequired,
    removeTable: PropTypes.func.isRequired,
    showHelpModal: PropTypes.func.isRequired,
    showValidation: PropTypes.bool,
};

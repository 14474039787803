import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { fieldNames, formConfig } from './config';
import { Button, InputText, SearchSelect, UploadProgress } from '../../..';
import { getContractAttachmentTagSelectOptions } from '../../../../containers/selectors';

const { NOTE, TAGS, TITLE } = fieldNames;
const QA_TAG_NAME = 'vendorDocumentUploadForm';

const ConnectedVendorDocumentUploadForm = ({
    change,
    disabled,
    handleSubmit,
    removeHandler,
    uploadData,
    uuid,
}) => {
    const [showNote, setShowNote] = useState(false);
    const contractAttachmentTags = useSelector(getContractAttachmentTagSelectOptions);
    const dispatch = useDispatch();

    const removeNote = () => {
        dispatch(change(NOTE, null));
        setShowNote(false);
    };

    if (uploadData) {
        return <UploadProgress {...uploadData} useOpenGovStyle />;
    }

    return (
        <form className="row" onSubmit={handleSubmit}>
            <div className="col-md-5">
                <Field
                    component={SearchSelect}
                    disabled={disabled}
                    isClearable
                    label="Document Type"
                    name={TAGS}
                    noOptionsMessage={() => 'No types have been specified by the agency'}
                    options={contractAttachmentTags}
                    placeholder="Select Document Type"
                    qaTag={`${QA_TAG_NAME}-${TAGS}`}
                />
            </div>
            <div className="col-md-7">
                <Field
                    component={InputText}
                    disabled={disabled}
                    hasFeedback={false}
                    id={`${TITLE}-${uuid}`}
                    label="Title"
                    name={TITLE}
                    qaTag={`${QA_TAG_NAME}-${TITLE}`}
                    type="text"
                />
            </div>
            <div className="col-md-12">
                {showNote ? (
                    <>
                        <div className="pull-right">
                            <Button
                                bsStyle="link"
                                onClick={removeNote}
                                qaTag={`${QA_TAG_NAME}-removeNote`}
                                zeroPadding
                            >
                                <i className="fa fa-times" /> Remove Note
                            </Button>
                        </div>
                        <Field
                            component={InputText}
                            disabled={disabled}
                            id={`${NOTE}-${uuid}`}
                            label="Note"
                            name={NOTE}
                            overrideFeedback
                            qaTag={`${QA_TAG_NAME}-${NOTE}`}
                            type="text"
                        />
                    </>
                ) : (
                    <Button
                        bsStyle="link"
                        onClick={() => setShowNote(true)}
                        qaTag={`${QA_TAG_NAME}-addNote`}
                        zeroPadding
                    >
                        <i className="fa fa-plus" /> Add Note
                    </Button>
                )}
            </div>
            <div className="col-md-12 text-right">
                <Button
                    bsSize="sm"
                    disabled={disabled}
                    onClick={removeHandler}
                    qaTag={`${QA_TAG_NAME}-cancel`}
                >
                    Cancel
                </Button>
                &nbsp;&nbsp;
                <Button
                    bsSize="sm"
                    bsStyle="primary"
                    disabled={disabled}
                    qaTag={`${QA_TAG_NAME}-upload`}
                    type="submit"
                >
                    <i className="fa fa-upload" /> Upload
                </Button>
            </div>
        </form>
    );
};

ConnectedVendorDocumentUploadForm.propTypes = {
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    removeHandler: PropTypes.func.isRequired,
    uploadData: PropTypes.object,
    uuid: PropTypes.string.isRequired,
};

export const VendorDocumentUploadForm = reduxForm(formConfig)(ConnectedVendorDocumentUploadForm);

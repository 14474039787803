import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Button } from '..';

export class TourButton extends PureComponent {
    static propTypes = {
        text: PropTypes.string.isRequired,
        className: PropTypes.string,
        qaTag: PropTypes.string.isRequired,
    };

    static defaultProps = {
        className: '',
    };

    render() {
        const { className, text, qaTag } = this.props;

        return (
            <Button bsStyle="link" className={`${className} no-print`} qaTag={qaTag}>
                <i className="fa fa-play-circle" /> {text}
            </Button>
        );
    }
}

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Modal } from 'react-bootstrap';

import { AddendumsConfirmMatrix } from '../../..';

export class AddendumsConfirmModal extends PureComponent {
    static propTypes = {
        addendums: PropTypes.array.isRequired,
        hideModal: PropTypes.func.isRequired,
        proposals: PropTypes.array.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const { addendums, hideModal, proposals } = this.props;

        return (
            <Modal bsSize="lg" onHide={hideModal} show>
                <Modal.Header closeButton>
                    <Modal.Title>Addenda Receipt Acknowledgements</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddendumsConfirmMatrix addendums={addendums} proposals={proposals} />
                </Modal.Body>
            </Modal>
        );
    }
}

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { AggregateEvaluationTable, AggregateScoringCriteriaEvaluationTable } from './components';
import { getEvaluators, isEvaluationCompletable } from './selectors';
import { EvaluationTableDisplayMenu, ProposalScoresTable } from '../components';
import { EvaluationPhaseCreateButton } from '../EvaluationPhaseCreateButton';
import { getEvaluationLocationObject, getTotalWeight } from '../selectors';
import {
    getCurrentScoredProposals,
    getEvaluationAuditData,
    getProjectJS,
    isEvaluationEditor,
} from '../../selectors';
import connectData from '../../../ConnectData';
import { shouldLoadFromEvaluationAudit } from '../../../../actions/evaluations';
import { menuActionHandler } from '../../../../actions/govProjects';
import {
    loadAggregateProposalEvaluations,
    showProposalSelect,
} from '../../../../actions/proposalEvaluations';
import {
    Button,
    ButtonGroup,
    Collapse,
    LoadingError,
    LoadingSpinner,
    Main,
    PageTitle,
    ZeroState,
} from '../../../../components';
import { ReportsModalButton } from '../..';
import { CONSENSUS_EVALUATION } from '../../../../constants/menuActions';

function fetchData(getState, dispatch, location, params) {
    if (shouldLoadFromEvaluationAudit(getState(), Object.fromEntries(location.searchParams))) {
        return Promise.resolve();
    }
    const projectId = Number.parseInt(params.projectId, 10);
    return dispatch(loadAggregateProposalEvaluations(projectId));
}

const mapStateToProps = (state, props) => {
    const evaluationAudit = getEvaluationAuditData(state, props);

    return {
        error: state.proposalEvaluations.get('loadAggregateError'),
        evaluationAudit,
        evaluators: getEvaluators(state, props),
        getEvaluationPath: getEvaluationLocationObject(state, props),
        isCompletable: isEvaluationCompletable(state),
        isEditor: isEvaluationEditor(state),
        isPastPhase: !!evaluationAudit,
        loading: state.proposalEvaluations.get('loadingAggregate'),
        project: getProjectJS(state),
        proposals: getCurrentScoredProposals(state, props),
        showAnonymizedEvaluators: state.proposalEvaluations.get('showAnonymizedEvaluators'),
        showCriteriaDescriptions: state.proposalEvaluations.get('showCriteriaDescriptions'),
        showDetailedProposals: state.proposalEvaluations.get('showDetailedProposals'),
        showPercentageTotals: state.proposalEvaluations.get('showPercentageTotals'),
        totalWeight: getTotalWeight(state, props),
    };
};

const mapDispatchToProps = {
    menuActionHandler,
    showProposalSelect,
};

// @connect
class ConnectedAggregateEvaluation extends Component {
    static propTypes = {
        error: PropTypes.string,
        evaluationAudit: PropTypes.object,
        evaluators: PropTypes.array.isRequired,
        getEvaluationPath: PropTypes.func.isRequired,
        isCompletable: PropTypes.bool.isRequired,
        isEditor: PropTypes.bool.isRequired,
        isPastPhase: PropTypes.bool.isRequired,
        loading: PropTypes.bool.isRequired,
        menuActionHandler: PropTypes.func.isRequired,
        project: PropTypes.shape({
            evaluation: PropTypes.shape({
                hasConsensusEvaluation: PropTypes.bool.isRequired,
                status: PropTypes.string.isRequired,
            }).isRequired,
            government: PropTypes.shape({
                usesRankedScoring: PropTypes.bool.isRequired,
            }).isRequired,
        }),
        proposals: PropTypes.array.isRequired,
        showAnonymizedEvaluators: PropTypes.bool.isRequired,
        showCriteriaDescriptions: PropTypes.bool,
        showDetailedProposals: PropTypes.bool.isRequired,
        showPercentageTotals: PropTypes.bool,
        showProposalSelect: PropTypes.func.isRequired,
        totalWeight: PropTypes.number.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    get styles() {
        return require('./AggregateEvaluation.scss');
    }

    createConsensusEvaluation = () => {
        const { project } = this.props;

        this.props.menuActionHandler(CONSENSUS_EVALUATION, project);
    };

    createEvaluationScoresTableRef = (ref) => {
        this.evaluationScoresTable = ref;
    };

    evaluationScoresTableRef = () => {
        return this.evaluationScoresTable;
    };

    toggleProposal = (id) => {
        const stateKey = `hideProposal${id}`;
        const currentDisplay = !!this.state[stateKey];
        this.setState({ [stateKey]: !currentDisplay });
    };

    showProposalSelect = () => {
        return this.props.showProposalSelect();
    };

    renderReportButton() {
        return <ReportsModalButton bsSize="sm" type="evaluationTabulationReport" />;
    }

    renderDetailedProposalScoresTables() {
        const { proposals } = this.props;
        const { detailedProposalTable, detailedProposalTitle, proposalsSectionTitle } = this.styles;

        const proposalScoresTables = proposals.map((proposal) => {
            const { companyName, id } = proposal;
            const isHidden = this.state[`hideProposal${id}`];
            const icon = isHidden ? 'fa-angle-right' : 'fa-angle-down';
            return (
                <div className={detailedProposalTable} key={id}>
                    <h4 className={detailedProposalTitle}>
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                this.toggleProposal(id);
                            }}
                        >
                            <i className={`fa fa-fw ${icon}`} /> {companyName || 'Unnamed Company'}
                        </a>
                    </h4>
                    <Collapse in={!isHidden}>
                        <ProposalScoresTable
                            expandedViewClassName={this.styles.proposalTable}
                            proposal={proposal}
                            showCSVExport
                        />
                    </Collapse>
                </div>
            );
        });

        return (
            <>
                <h4 className={proposalsSectionTitle}>Individual Response Scores</h4>
                {proposalScoresTables}
            </>
        );
    }

    renderActionButtons() {
        const { isEditor, project } = this.props;

        return (
            <div className={`hidden-print ${this.styles.actionButtons}`}>
                <ButtonGroup>
                    <EvaluationPhaseCreateButton
                        bsSize="lg"
                        hasPermission={isEditor}
                        project={project}
                    />
                    {!project.evaluation.hasConsensusEvaluation && (
                        <Button
                            bsSize="lg"
                            disabled={!isEditor}
                            onClick={this.createConsensusEvaluation}
                            qaTag="connectedAggregateEvaluation-consensusScorecard"
                            tooltip={
                                isEditor
                                    ? undefined
                                    : 'Only evaluation editors can create Consensus Scorecard'
                            }
                        >
                            <i className="fa fa-sitemap" /> Consensus Scorecard
                        </Button>
                    )}
                    <Button
                        bsSize="lg"
                        bsStyle="success"
                        disabled={!isEditor}
                        onClick={this.showProposalSelect}
                        qaTag="connectedAggregateEvaluation-awardProject"
                        tooltip={
                            isEditor
                                ? undefined
                                : 'Only evaluation editors can complete the evaluation'
                        }
                    >
                        <i className="fa fa-trophy" /> Award Project
                    </Button>
                </ButtonGroup>
            </div>
        );
    }

    renderEvaluationScoresTable() {
        const {
            evaluationAudit,
            evaluators,
            getEvaluationPath,
            isCompletable,
            isPastPhase,
            project: {
                government: { usesRankedScoring },
            },
            proposals,
            showAnonymizedEvaluators,
            showCriteriaDescriptions,
            showDetailedProposals,
            showPercentageTotals,
            totalWeight,
        } = this.props;

        return (
            <div ref={this.createEvaluationScoresTableRef}>
                <PageTitle title="Aggregate Scores Summary" />
                <h2 className={this.styles.aggregateSectionTitle}>
                    Aggregate Scores Summary
                    {evaluationAudit && <>&nbsp;(Phase {evaluationAudit.evaluation.phaseNumber})</>}
                </h2>
                <AggregateEvaluationTable
                    evaluators={evaluators}
                    getEvaluationPath={getEvaluationPath}
                    proposals={proposals}
                    showAnonymizedEvaluators={showAnonymizedEvaluators}
                    showPercentageTotals={showPercentageTotals}
                    totalWeight={totalWeight}
                    usesRankedScoring={usesRankedScoring}
                />
                <div className="text-muted text-center hidden-print">
                    <div className={this.styles.info}>
                        Click a vendor&#39;s name to view the scorecard submitted by each evaluator
                        for that response.
                    </div>
                    <div>
                        Click an evaluator&#39;s score to view the detailed scorecard for each
                        response reviewed by that evaluator.
                    </div>
                    {usesRankedScoring && (
                        <div>
                            Ranked score for each evaluator is in parentheses next to the total
                            score
                        </div>
                    )}
                </div>
                {isCompletable && !isPastPhase && this.renderActionButtons()}
                <h3 className={this.styles.proposalsSectionTitle}>
                    Vendor Scores by Evaluation Criteria
                </h3>
                <AggregateScoringCriteriaEvaluationTable
                    getEvaluationPath={getEvaluationPath}
                    proposals={proposals}
                    showCriteriaDescriptions={showCriteriaDescriptions}
                    showPercentageTotals={showPercentageTotals}
                    totalWeight={totalWeight}
                />
                <div className="text-muted text-center hidden-print">
                    <div className={this.styles.info}>
                        Click a vendor&#39;s name to view the scorecard submitted by each evaluator
                        for that response.
                    </div>
                </div>
                {showDetailedProposals && this.renderDetailedProposalScoresTables()}
            </div>
        );
    }

    render() {
        const { error, loading, proposals } = this.props;

        if (loading) {
            return <LoadingSpinner />;
        }

        if (error) {
            return <LoadingError error={error} />;
        }

        if (proposals.length === 0) {
            return (
                <ZeroState
                    info="No responses have been received yet"
                    title="Aggregate Response Evaluations"
                />
            );
        }

        return (
            <Main>
                <EvaluationTableDisplayMenu
                    additionalButton={this.renderReportButton()}
                    printContent={this.evaluationScoresTableRef}
                    printText="Print All Scores"
                    showAnonymizedEvaluatorsOption
                    showDetailedProposalsOption
                />
                {this.renderEvaluationScoresTable()}
            </Main>
        );
    }
}

export const AggregateEvaluation = compose(
    connectData(fetchData),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedAggregateEvaluation);

import React from 'react';
import { useSelector } from 'react-redux';
import { reduxForm } from 'redux-form';

import { formConfig } from '.';
import { getIsRequisitionUsingFMS } from '../../../selectors/govApp';
import { useOpenFiscalPeriodsWithCalendarYear } from '../../../lib/ogFinancials';

// This is necessary for props that the `reduxForm` HOC needs for its form config and validation
const withCustomPropsToReduxForm = (ReduxFormWrappedComponent) => {
    return (props) => {
        // Pass `hasFMS` as a prop to reduxForm HOC so that redux-form `validate` can use the prop.
        // Prop will also be available to the `ReduxFormWrappedComponent`
        const hasFMS = useSelector(getIsRequisitionUsingFMS);

        const { data: fiscalPeriods } = useOpenFiscalPeriodsWithCalendarYear({ enabled: hasFMS });

        const reduxFormProps = {
            hasFMS,
            fiscalPeriods,
        };

        return <ReduxFormWrappedComponent {...props} {...reduxFormProps} />;
    };
};

// Wraps a component with the redux-form decorator configured for the `requisitionsCreate` form
export const withRequisitionConfirmationCreateForm = () => (WrappedComponent) =>
    withCustomPropsToReduxForm(reduxForm(formConfig)(WrappedComponent));

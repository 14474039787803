import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { components } from 'react-select';

// Used to add user profile pictures to the `SearchSelect` component options
export const UpfrontQuestionSelectOption = (props) => {
    const {
        data: { upfrontQuestion, label },
    } = props;

    const isConditionalSubQuestion = get(upfrontQuestion, 'isConditionalSubQuestion');

    return (
        <components.Option {...props}>
            {/* Indent sub-questions */}
            {!!isConditionalSubQuestion && <span style={{ width: 10, display: 'inline-block' }} />}
            {label}
        </components.Option>
    );
};

UpfrontQuestionSelectOption.propTypes = {
    data: PropTypes.shape({
        label: PropTypes.string.isRequired,
        upfrontQuestion: PropTypes.object,
    }).isRequired,
};

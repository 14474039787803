import { Chip, Typography } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React from 'react';

export const RequisitionGroupReviewSequence = ({ reviewSequence: { icon, isDefault, name } }) => {
    return (
        <>
            <Typography component="div">
                {icon && (
                    <>
                        <i className={`fa fa-fw fa-${icon}`} />
                        &nbsp;
                    </>
                )}
                {name || 'Untitled'}
            </Typography>
            {isDefault && (
                <Chip color="primary" label="Default Sequence" size="extraSmall" variant="tag" />
            )}
        </>
    );
};

RequisitionGroupReviewSequence.propTypes = {
    reviewSequence: PropTypes.shape({
        icon: PropTypes.string,
        isDefault: PropTypes.bool.isRequired,
        name: PropTypes.string,
    }).isRequired,
};

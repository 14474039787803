import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import { useGetContractReqRelations, useGetProjectReqRelations } from './hooks';
import { RequisitionsDisplay } from './RequisitionsDisplay';
import { addRequisitionToContract } from '../../actions/contracts';
import { addRequisitionToProject } from '../../actions/govProjects';

export const ProjectRequisitionsDisplay = ({ disabled, isEditor, label, projectId }) => {
    const { error, loading, requisitions } = useGetProjectReqRelations({ projectId });

    const dispatch = useDispatch();

    const addHandler = (requisitionIds) => {
        dispatch(addRequisitionToProject(projectId, requisitionIds));
    };

    return (
        <RequisitionsDisplay
            addHandler={addHandler}
            disabled={disabled}
            error={error}
            isEditor={isEditor}
            label={label}
            loading={loading}
            requisitions={requisitions}
        />
    );
};

ProjectRequisitionsDisplay.propTypes = {
    disabled: PropTypes.bool,
    isEditor: PropTypes.bool,
    label: PropTypes.node,
    projectId: PropTypes.number.isRequired,
};

export const ContractRequisitionsDisplay = ({ contractId, disabled, isEditor, label }) => {
    const { error, loading, requisitions } = useGetContractReqRelations({ contractId });

    const dispatch = useDispatch();

    const addHandler = (requisitionIds) => {
        dispatch(addRequisitionToContract(contractId, requisitionIds));
    };

    return (
        <RequisitionsDisplay
            addHandler={addHandler}
            disabled={disabled}
            error={error}
            isEditor={isEditor}
            label={label}
            loading={loading}
            requisitions={requisitions}
        />
    );
};

ContractRequisitionsDisplay.propTypes = {
    contractId: PropTypes.number.isRequired,
    disabled: PropTypes.bool,
    isEditor: PropTypes.bool,
    label: PropTypes.node,
};

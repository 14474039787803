import { useContext } from 'react';
import { useDispatch } from 'react-redux';

import { TemplateEditV2FunctionsContext } from './context';
import { showConfirmationSimpleModal } from '../../../../actions/confirmation';

export const useAddTemplateSection = ({ setAddingSection }) => {
    const dispatch = useDispatch();
    const { isDirty, updateTemplate } = useContext(TemplateEditV2FunctionsContext);

    const onAddSection = (templateSection, isTemplate) => {
        const addTemplateSection = () => {
            if (isTemplate) {
                return setAddingSection(templateSection, true);
            }

            setAddingSection(templateSection);
        };

        const onConfirm = async () => {
            const attemptUpdate = await updateTemplate();

            // If validation fails, `updateTemplate` returns the errors the form has
            // if there's errors somewhere stop the process
            if (!attemptUpdate.allSections) {
                addTemplateSection();
            }
        };

        // If form is not isDirty we can proceed with adding the section
        if (!isDirty) {
            return addTemplateSection();
        }

        dispatch(
            showConfirmationSimpleModal(onConfirm, {
                bsStyle: 'primary',
                btnText: 'Save Changes',
                cancelText: 'Discard Changes',
                icon: 'check',
                onCancel: addTemplateSection,
                text: 'You have unsaved changes.\n\nWould you like to save your changes before adding this section?\nIf you choose not to save, all unsaved changes will be lost.',
                title: 'Save Changes?',
            })
        );
    };

    return {
        onAddSection,
    };
};

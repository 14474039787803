import React from 'react';
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { HelpBlock as HelpBlockBS } from 'react-bootstrap';

export const HelpBlock = (props) => {
    const { className, useOpenGovStyle } = props;

    const styles = require('./HelpBlockOpenGovStyle.scss');

    return (
        <div className={classnames(useOpenGovStyle && styles.helpWrapper, className)} role="alert">
            <HelpBlockBS {...omit(props, 'useOpenGovStyle')} />
        </div>
    );
};

HelpBlock.propTypes = {
    className: PropTypes.string,
    useOpenGovStyle: PropTypes.bool,
};

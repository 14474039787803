import moment from 'moment';

import { ClosedDateCell, StatusCell, SelectCell, TextCell, TitleCell } from '../cells';

const checkboxColumn = {
    checkboxSelection: true,
    headerCheckboxSelection: true,
    pinned: 'left',
    suppressSizeToFit: true,
    width: 55,
    sortable: false,
};

const titleDepartmentContact = [
    {
        field: 'title',
        headerName: 'Title',
        cellRendererFramework: TitleCell,
        comparator: (a, b) => (a.title || 'Untitled').localeCompare(b.title || 'Untitled'),
    },
    {
        field: 'department',
        headerName: 'Department',
        cellRendererFramework: TextCell,
        comparator: (a, b) => a.localeCompare(b),
    },
    {
        field: 'contact',
        headerName: 'Contact',
        cellRendererFramework: TextCell,
        comparator: (a, b) => a.localeCompare(b),
    },
];

/**
 * Returns the column specifications for the RecordTable component.
 * @return {object[]} Array of column definitions
 */
export const recordTableColumnDef = [
    checkboxColumn,
    {
        field: 'status',
        headerName: 'Status',
        cellRendererFramework: StatusCell,
        sortable: false,
    },
    ...titleDepartmentContact,
    {
        field: 'lastUpdated',
        headerName: 'Last Updated',
        cellRendererFramework: TextCell,
        comparator: (a, b) => moment(a) - moment(b),
    },
];

/**
 * Returns the column specifications for the RECENTLY_CLOSED version of the table.
 * @return {object[]} Array of column definitions.
 */
export const recentlyClosedTableColumnDef = [
    checkboxColumn,
    ...titleDepartmentContact,
    {
        field: 'lastUpdated',
        headerName: 'Last Updated',
        cellRendererFramework: ClosedDateCell,
        comparator: (a, b) => moment(a.rawDate) - moment(b.rawDate),
    },
    {
        field: 'retentionPolicy',
        headerName: 'Retention Policy',
        cellRendererFramework: SelectCell,
        cellStyle: { overflow: 'visible' },
        sortable: false,
    },
];

/**
 * Returns the column specifications for the ENHANCED RECENTLY_CLOSED version of the table.
 * This set of column specs is only used if FLAG.RECORDS_RETENTION_ENHANCEMENTS is enabled.
 * @return {object[]} Array of column definitions.
 */
export const recentlyClosedTableEnhancedColumnDef = [
    checkboxColumn,
    ...titleDepartmentContact,
    {
        field: 'lastUpdated',
        headerName: 'Last Updated',
        cellRendererFramework: TextCell,
        comparator: (a, b) => moment(a) - moment(b),
    },
    {
        field: 'closedDate',
        headerName: 'Date Closed',
        cellRendererFramework: ClosedDateCell,
        comparator: (a, b) => moment(a.rawDate) - moment(b.rawDate),
    },
    {
        field: 'retentionPolicy',
        headerName: 'Retention Policy',
        cellRendererFramework: SelectCell,
        cellStyle: { overflow: 'visible' },
        sortable: false,
    },
];

/**
 * Returns the column specifications for the EXPIRED version of the table.
 * @return {object[]} Array of column definitions.
 */
export const expiredTableColumnDef = [
    checkboxColumn,
    ...titleDepartmentContact,
    {
        field: 'expirationDate',
        headerName: 'Expiration Date',
        cellRendererFramework: ClosedDateCell,
        comparator: (a, b) => moment(a.date) - moment(b.date),
    },
];

export const expiredEnhancedTableColumnDef = [
    checkboxColumn,
    ...titleDepartmentContact,
    {
        field: 'closedDate',
        headerName: 'Date Closed',
        cellRendererFramework: ClosedDateCell,
        comparator: (a, b) => moment(a.rawDate) - moment(b.rawDate),
    },
    {
        field: 'expirationDate',
        headerName: 'Expiration Date',
        cellRendererFramework: TextCell,
        comparator: (a, b) => moment(a) - moment(b),
    },
];

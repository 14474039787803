import { fieldNames } from './constants';

const { OVER_BUDGET_NOTE } = fieldNames;

export const validate = (values, props) => {
    const { requireOverBudgetNote } = props;
    const errors = {};

    if (requireOverBudgetNote && !values[OVER_BUDGET_NOTE]?.trim()) {
        errors[OVER_BUDGET_NOTE] = 'Please provide a reason for approving the request';
    }
    if (values[OVER_BUDGET_NOTE]?.length > 1000) {
        errors[OVER_BUDGET_NOTE] = 'Note is too long (1000 character limit)';
    }

    return errors;
};

import { capitalDesignTokens } from '@opengov/capital-mui-theme';

export const iconStyle = {
    color: capitalDesignTokens.semanticColors.dataVisualization.sequence400,
    fontSize: 'inherit',
};

export const titleStyle = {
    alignItems: 'center',
    display: 'flex',
    gap: 1,
};

export const headerStyle = {
    alignItems: 'center',
    display: 'flex',
    gap: 1,
    justifyContent: 'space-between',
};

export const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    padding: 3,
};

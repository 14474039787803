import { fromJS } from 'immutable';

import * as ratingsActions from '../actions/ratings';

const initialState = fromJS({
    procuratedVendor: null,
    procuratedVendorList: [],
    loadingProcuratedRating: false,
    loadProcuratedRatingError: false,
    loadedProcuratedRating: false,
    loadingProcuratedRatings: false,
    loadProcuratedRatingsError: false,
    loadedProcuratedRatings: false,
});

export default function ratingsReducer(state = initialState, action = {}) {
    switch (action.type) {
        case ratingsActions.LOAD_PROCURATED_RATING:
            return state.merge(
                fromJS({
                    loadingProcuratedRating: true,
                    loadProcuratedRatingError: null,
                })
            );
        case ratingsActions.LOAD_PROCURATED_RATING_FAIL:
            return state.merge(
                fromJS({
                    loadingProcuratedRating: false,
                    loadProcuratedRatingError: action.error.message,
                })
            );
        case ratingsActions.LOAD_PROCURATED_RATING_SUCCESS:
            return state.merge(
                fromJS({
                    procuratedVendor: fromJS(action.result),
                    loadedProcuratedRating: true,
                    loadingProcuratedRating: false,
                })
            );
        case ratingsActions.LOAD_PROCURATED_RATINGS:
            return state.merge(
                fromJS({
                    loadingProcuratedRatings: true,
                    loadProcuratedRatingsError: null,
                })
            );
        case ratingsActions.LOAD_PROCURATED_RATINGS_FAIL:
            return state.merge(
                fromJS({
                    loadingProcuratedRatings: false,
                    loadProcuratedRatingsError: action.error.message,
                })
            );
        case ratingsActions.LOAD_PROCURATED_RATINGS_SUCCESS:
            return state.merge(
                fromJS({
                    procuratedVendorList: fromJS(action.result),
                    loadedProcuratedRatings: true,
                    loadingProcuratedRatings: false,
                })
            );
        default:
            return state;
    }
}

import PropTypes from 'prop-types';
import React from 'react';

export const TemplateInfo = ({ template: { icon, processAcronym, title } }) => {
    if (!title) {
        return 'No template title set';
    }

    return (
        <span>
            {icon && (
                <>
                    <i className={`fa fa-fw fa-${icon}`} />
                    &nbsp;
                </>
            )}
            {title}
            {processAcronym && <span>&nbsp;({processAcronym})</span>}
        </span>
    );
};

TemplateInfo.propTypes = {
    template: PropTypes.shape({
        icon: PropTypes.string,
        processAcronym: PropTypes.string,
        title: PropTypes.string,
    }).isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import { Draggable, Droppable } from 'react-beautiful-dnd';

import { DROPPABLE_CHILDREN_TYPE } from './constants';
import { QuestionnaireListItem } from './QuestionnaireListItem';
import { getDndStyle } from '../../../../../constants/styles';

export const QuestionnaireSubList = ({
    conditionalSubQuestions,
    disabled,
    isTemplate = false,
    parentQuestionnaireId,
    usesTemplateQuestions,
}) => {
    return (
        <Droppable
            droppableId={`${DROPPABLE_CHILDREN_TYPE}:${parentQuestionnaireId}`}
            isDropDisabled={disabled}
            type={`${DROPPABLE_CHILDREN_TYPE}:${parentQuestionnaireId}`}
        >
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    style={getDndStyle(snapshot)}
                    {...provided.droppableProps}
                >
                    {conditionalSubQuestions.map((questionnaireListItemProps, i) => {
                        const { index, questionnaire } = questionnaireListItemProps;

                        const subQuestionnaireId = questionnaire.id || questionnaire.uuid;
                        return (
                            <Draggable
                                draggableId={`${DROPPABLE_CHILDREN_TYPE}:${parentQuestionnaireId}:${questionnaire.id}`}
                                index={index}
                                key={subQuestionnaireId}
                            >
                                {(draggableProvided) => (
                                    <div
                                        ref={draggableProvided.innerRef}
                                        {...draggableProvided.draggableProps}
                                    >
                                        <QuestionnaireListItem
                                            draggableProvided={draggableProvided}
                                            isTemplate={isTemplate}
                                            isTemplateQuestion={
                                                usesTemplateQuestions && questionnaire.isTemplate
                                            }
                                            {...questionnaireListItemProps}
                                            isLastQuestion={
                                                conditionalSubQuestions.length === i + 1
                                            }
                                        />
                                    </div>
                                )}
                            </Draggable>
                        );
                    })}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    );
};

QuestionnaireSubList.propTypes = {
    conditionalSubQuestions: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
    isTemplate: PropTypes.bool,
    parentQuestionnaireId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    usesTemplateQuestions: PropTypes.bool,
};

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@og-pro/ui';
import { useDispatch, useSelector } from 'react-redux';

import { ModalAttachment } from './ModalAttachment';
import { deleteContractAttachment, updateContractAttachment } from '../../../../actions/contracts';
import { showConfirmationSimpleModal } from '../../../../actions/confirmation';
import { getContractJS } from '../../../selectors';
import { fieldNames } from '../constants';

const { FILENAME, TAGS, NOTES } = fieldNames;

export const EditAttachment = ({ attachment, onFinish }) => {
    const dispatch = useDispatch();
    const contract = useSelector(getContractJS);

    const onSubmit = async (values) => {
        let tags = attachment[TAGS] || [];
        if (values[TAGS]) {
            tags = [values[TAGS]];
        }

        const updatedAttachment = { ...values, tags };

        await dispatch(updateContractAttachment(contract.id, attachment.id, updatedAttachment));

        return onFinish();
    };

    const handleDelete = async () => {
        dispatch(
            showConfirmationSimpleModal(
                async () => {
                    await dispatch(deleteContractAttachment(contract.id, attachment.id));

                    // avoid redux race condition
                    setTimeout(() => {
                        onFinish();
                    }, 1);
                },
                {
                    text: 'Are you sure you want to remove this document? This action is irreversible',
                    useOpenGovStyle: true,
                }
            )
        );
    };

    if (!attachment) {
        return null;
    }

    return (
        <ModalAttachment
            // actions defined here and not in a separate component so we can
            // make use of closures and access handleDelete in this component
            actions={({ submitting, handleClose, invalid }) => {
                return (
                    <Box alignItems="center" display="flex" justifyContent="space-between" mt={4}>
                        <Box flex={1}>
                            <Button
                                color="error"
                                disabled={submitting}
                                onClick={handleDelete}
                                qaTag="contractAttachments-delete"
                                variant="text"
                            >
                                Delete
                            </Button>
                        </Box>
                        <Box alignItems="center" display="flex" flex={2} justifyContent="flex-end">
                            <Box mr={1}>
                                <Button
                                    disabled={submitting}
                                    onClick={handleClose}
                                    qaTag="contractAttachments-cancel"
                                    variant="text"
                                >
                                    Cancel
                                </Button>
                            </Box>
                            <Box>
                                <Button
                                    disabled={invalid || submitting}
                                    qaTag="contractAttachments-save"
                                    type="submit"
                                    variant="contained"
                                >
                                    Save
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                );
            }}
            initialValues={{
                [FILENAME]: attachment[FILENAME],
                [TAGS]: attachment[TAGS].length ? attachment[TAGS][0].id : null,
                [NOTES]: attachment[NOTES],
            }}
            onClose={() => onFinish()}
            onSubmit={onSubmit}
            open
            title="Edit Contract Document"
        />
    );
};

EditAttachment.propTypes = {
    attachment: PropTypes.object,
    onFinish: PropTypes.func.isRequired,
};

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { SearchSelect } from '../SearchSelect/SearchSelect';
import { useDebounce } from '../../hooks';

export const AsyncSearchSelect = ({
    initialSearch,
    loadOptions,
    onInputChange,
    onOptionChange,
    selectedOption,
    ...props
}) => {
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState(
        selectedOption
            ? [
                  {
                      ...selectedOption,
                  },
              ]
            : []
    );

    const load = async (data) => {
        setLoading(true);

        const opts = await loadOptions(data);
        setOptions(opts);

        setLoading(false);
    };

    const debounceLoad = useDebounce(load);

    const onInputChangeHandler = (data, actionMeta) => {
        const { action } = actionMeta;

        // Only load when input is changed (skips blur and selection changes)
        if (action === 'input-change') {
            debounceLoad(data);
        }

        // Call custom handler if provided
        if (onInputChange) {
            onInputChange(data, actionMeta);
        }

        return data;
    };

    const onChangeWithOption = async (data) => {
        if (onOptionChange) {
            onOptionChange(data);
        }

        // Data will be null when form has been cleared
        if (data === null) {
            setOptions([]);
        }
    };

    useEffect(() => {
        if (initialSearch) {
            load(initialSearch);
        }
    }, []);

    return (
        <SearchSelect
            isLoading={loading}
            noOptionsMessage={() => (loading ? 'Loading...' : 'No results found')}
            onChangeWithOption={onChangeWithOption}
            onInputChange={onInputChangeHandler}
            options={options || []}
            {...props}
        />
    );
};

AsyncSearchSelect.propTypes = {
    initialSearch: PropTypes.string,
    loadOptions: PropTypes.func.isRequired,
    onInputChange: PropTypes.func,
    onOptionChange: PropTypes.func,
    selectedOption: PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
    }),
};

import { Typography } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { fieldNames } from '../../../constants';
import { qaTagPageName } from '../../../../constants';
import { Label, SearchSelect } from '../../../../../../../components';
import { NO_EMAIL_FOUND_TEXT } from '../../../../../../../constants/requisitionsCreate';

const { PRO_USER_ID } = fieldNames;

export const VendorContact = ({
    disabled,
    fieldName,
    hasFMS,
    showFormValidation,
    vendor,
    vendorUsers,
}) => {
    if (hasFMS) {
        return (
            <>
                <Label label="Vendor Email" useOpenGovStyle />
                <Typography
                    color={vendor.vendorEmail === NO_EMAIL_FOUND_TEXT ? 'secondary' : undefined}
                    variant="bodyDefault"
                >
                    {vendor.vendorEmail}
                </Typography>
            </>
        );
    }

    const email = vendorUsers.find((user) => user.id === vendor[PRO_USER_ID])?.email;

    const options = vendorUsers.map((user) => ({
        label: user.displayName,
        value: user.id,
    }));

    return (
        <>
            <Field
                component={SearchSelect}
                disabled={disabled}
                isClearable
                label="Vendor Contact *"
                name={`${fieldName}.${fieldNames.PRO_USER_ID}`}
                noMarginBottom
                options={options}
                placeholder="Select a Contact"
                qaTag={`${qaTagPageName}-vendorUser`}
                showValidation={showFormValidation}
                useOpenGovStyle
            />
            {email && (
                <Typography marginTop={0.5} variant="bodySmall">
                    {email}
                </Typography>
            )}
        </>
    );
};

VendorContact.propTypes = {
    disabled: PropTypes.bool,
    fieldName: PropTypes.string.isRequired,
    hasFMS: PropTypes.bool,
    showFormValidation: PropTypes.bool,
    vendor: PropTypes.shape({
        vendorEmail: PropTypes.string.isRequired,
    }).isRequired,
    vendorUsers: PropTypes.array.isRequired,
};

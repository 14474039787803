import { capitalDesignTokens } from '@opengov/capital-mui-theme';

export const vendorCardStyle = {
    border: `1px solid ${capitalDesignTokens.semanticColors.border.primary}`,
    padding: 3,
};

export const dividerStyle = {
    borderTop: `1px solid ${capitalDesignTokens.semanticColors.border.primary}`,
    my: 2,
};

export const validate = (values) => {
    const errors = {};

    Object.keys(values).forEach((status) => {
        if (!values[status] && values[status] !== 0) {
            errors[status] = 'Please enter a weight';
        }
    });

    return errors;
};

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ListGroupItem } from 'react-bootstrap';
import { connect } from 'react-redux';

import { showTermsReviewModal } from '../../../../actions/project/create/projectCreate';
import { CommentIcon } from '../../../../containers/GovApp';
import { OGThemeContext } from '../../../../containers/GovApp/ogThemeProvider';
import { CriteriaNeedsReviewItemV2 } from './V2';

const mapDispatchToProps = {
    showTermsReviewModal,
};

// @connect
class ConnectedCriteriaNeedsReviewItem extends Component {
    static propTypes = {
        arrayName: PropTypes.string.isRequired,
        criteria: PropTypes.shape({
            id: PropTypes.number.isRequired,
            isReviewed: PropTypes.bool,
            orderById: PropTypes.number,
            title: PropTypes.string.isRequired,
        }).isRequired,
        disabled: PropTypes.bool,
        dragHandleProps: PropTypes.object.isRequired,
        handleInsert: PropTypes.func,
        isTextArea: PropTypes.bool,
        projectSection: PropTypes.object.isRequired,
        showComment: PropTypes.bool,
        showTermsReviewModal: PropTypes.func.isRequired,
        showValidation: PropTypes.bool,
    };

    get styles() {
        return require('../shared.scss');
    }

    get itemStatus() {
        const {
            criteria: { isReviewed },
            showValidation,
        } = this.props;

        if (!showValidation && !isReviewed) {
            return undefined;
        }

        return isReviewed ? 'success' : 'danger';
    }

    static contextType = OGThemeContext;

    showTermsReviewModal = () => {
        const { arrayName, criteria } = this.props;

        this.props.showTermsReviewModal({
            arrayName,
            criteria,
        });
    };

    renderCommentIcon = () => {
        const {
            criteria: { id: criteriaId },
            projectSection,
            showComment,
        } = this.props;
        const { isOGThemeEnabledForComponents } = this.context;

        if (!showComment) return null;

        return (
            <CommentIcon
                criterionId={criteriaId}
                hideClassName={isOGThemeEnabledForComponents ? null : this.styles.displayComment}
                iconLeft
                openGovStyleIconOnly={isOGThemeEnabledForComponents}
                projectSection={projectSection}
                show
                useOpenGovStyle={isOGThemeEnabledForComponents}
            />
        );
    };

    renderInvisibleDragArea = () => {
        // `react-beautiful-dnd` requires `dragHandleProps` be defined in any draggable list, but
        // we do not want to allow these items to be dragged, so we create an unclickable div.
        return <div className={this.styles.hiddenDragIcon} {...this.props.dragHandleProps} />;
    };

    renderStatusIcon() {
        const itemStatus = this.itemStatus;

        if (!itemStatus) {
            return null;
        }

        const iconStyle =
            itemStatus === 'success'
                ? 'fa-check text-success'
                : 'fa-exclamation-triangle text-danger';

        return <i className={`fa fa-fw ${iconStyle} ${this.styles.needsReviewStatusIcon}`} />;
    }

    render() {
        const {
            criteria,
            disabled,
            handleInsert,
            isTextArea,
            projectSection,
            showComment,
            showValidation,
        } = this.props;
        const { isOGThemeEnabledForComponents } = this.context;

        const title = isTextArea ? projectSection.title : criteria.title;

        if (isOGThemeEnabledForComponents) {
            return (
                <CriteriaNeedsReviewItemV2
                    {...{
                        criteria,
                        handleInsert,
                        projectSection,
                        showTermsReviewModal: this.showTermsReviewModal,
                        showComment,
                        showValidation,
                        renderCommentIcon: this.renderCommentIcon,
                        renderInvisibleDragArea: this.renderInvisibleDragArea,
                        title,
                    }}
                />
            );
        }

        return (
            <div className={this.styles.criteria}>
                <div className="row">
                    <div className="col-xs-10 col-sm-11">
                        <ListGroupItem
                            bsStyle={this.itemStatus}
                            disabled={disabled}
                            onClick={this.showTermsReviewModal}
                        >
                            <div>
                                {this.renderStatusIcon()}
                                {title}
                            </div>
                            <div>
                                {criteria.isReviewed
                                    ? 'Review complete. Click item to view or revise your response.'
                                    : 'Click item to complete review'}
                            </div>
                        </ListGroupItem>
                    </div>
                    <div className="col-xs-2 col-sm-1">
                        <div
                            className={classnames(
                                this.styles.controlButtons,
                                this.styles.topControl
                            )}
                        >
                            {this.renderCommentIcon()}
                            {this.renderInvisibleDragArea()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export const CriteriaNeedsReviewItem = connect(
    null,
    mapDispatchToProps
)(ConnectedCriteriaNeedsReviewItem);

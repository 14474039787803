import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useBrowserHistoryAdapter } from '@og-pro-migration-tools/react-router';

import {
    closeOpenModals,
    storeSignUpReferrer,
} from '../../store/helpers/transitionMiddlewareHelper';

// Add any "on every route change" logic here, like analytics
export const RouteHelpers = () => {
    const [prevLocation, setPrevLocation] = useState(null);
    const dispatch = useDispatch();
    const state = useSelector((s) => s);

    const location = useLocation();
    const { location: nextLocation } = useNavigation();

    useEffect(() => {
        if (nextLocation) {
            closeOpenModals(state, dispatch);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextLocation]);

    useBrowserHistoryAdapter();

    // when leaving a route, store the previous location
    useEffect(() => {
        // Track page views on route updates with Segment
        // https://segment.com/docs/spec/page
        if (window?.analytics) {
            try {
                window.analytics.page();
            } catch (e) {} // eslint-disable-line
        }

        return () => {
            setPrevLocation(`${location.pathname}${location.search}`);
        };
    }, [location]);

    // when going to the signup page, store the referrer that we stored above
    useEffect(() => {
        if (prevLocation && location.pathname === '/signup') {
            storeSignUpReferrer(prevLocation);
        }
    }, [prevLocation, location.pathname]);

    return <Outlet />;
};

import { getFormValues } from 'redux-form';

import { form } from './constants';

export const selectOutlineNumberingFormValues = (state) => getFormValues(form)(state);

export const selectUpdatingOutlineNumbering = (state) =>
    state.admin.get('updatingOutlineNumbering');

export const selectUpdateOutlineNumberingError = (state) =>
    state.admin.get('updateOutlineNumberingError');

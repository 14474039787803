import { USE_PROCURATED } from '@og-pro/shared-config/procurated';

import { CACHE_BLOCK_SIZE } from './constants';

/**
 * Reaches out to retrieve Procurated ratings for a set of vendors
 * @param {*} params Request parameters
 * @param {*} response The result of the get vendor list call
 * @param {*} loadProcuratedRatingsClient Function that reaches out to the Procurated services
 * @param {*} dispatch Redux dispatch function
 * @returns List of vendors with ratings
 */
const getVendorRatings = (params, response, loadProcuratedRatingsClient, dispatch) => {
    if (USE_PROCURATED && response.rowData && response.rowData.length > 0) {
        const suppliers = response.rowData;
        const supplierIds = [...new Set(suppliers.map((supplier) => supplier.vendor_id))];
        return dispatch(
            loadProcuratedRatingsClient({
                supplierIds,
            })
        )
            .then((ratingsResponse) => {
                const suppliersWithRatings = suppliers.map((supplier) => {
                    const vendorRating = ratingsResponse.find((rating) => {
                        return parseInt(rating.partnerId, 10) === supplier.vendor_id;
                    });

                    return {
                        ...supplier,
                        rating: vendorRating && vendorRating.rating,
                    };
                });

                params.successCallback(suppliersWithRatings, response.rowCount);
            })
            .catch(() => {
                // We don't want to fail vendor list if ratings are unavailable
                params.successCallback(suppliers, response.rowCount);
            });
    }

    return params.successCallback(response.rowData, response.rowCount);
};

export const retrieveVendors = (
    serverFunction,
    governmentId,
    searchParams,
    loadProcuratedRatingsClient,
    dispatch
) => {
    return {
        getRows: (params) => {
            return dispatch(
                serverFunction(governmentId, {
                    limit: CACHE_BLOCK_SIZE,
                    offset: params.request.startRow,
                    // this table only supports one column sort
                    sort:
                        params.request.sortModel.length > 0
                            ? {
                                  column: params.request.sortModel[0].colId,
                                  direction: params.request.sortModel[0].sort.toUpperCase(),
                              }
                            : undefined,
                    search: {
                        certificationIds: searchParams.certificationIds,
                    },
                })
            )
                .then((response) => {
                    return getVendorRatings(
                        params,
                        response,
                        loadProcuratedRatingsClient,
                        dispatch
                    );
                })
                .catch(() => {
                    params.failCallback();
                });
        },
    };
};

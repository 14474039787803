import PropTypes from 'prop-types';
import classnames from 'classnames';
import React from 'react';
import { Field } from 'redux-form';
import { Box } from '@og-pro/ui';

import { limitTextLength } from '../../../../../Forms/normalizers';
import { TITLE } from './constants';
import { InputText, CDSButton } from '../../../../../components';

export const DocxTemplateFormFieldset = ({
    disabled,
    initialValues,
    cancelHandler,
    uploadError,
}) => {
    const styles = require('../shared.scss');

    return (
        <>
            <div className="col-xs-12 col-sm-8">
                <Field
                    component={InputText}
                    disabled={disabled}
                    hasFeedback={false}
                    label="Export Document Title"
                    name={TITLE}
                    normalize={limitTextLength(128)}
                    qaTag={`docxTemplates-${TITLE}`}
                    type="text"
                    useOpenGovStyle
                />
            </div>
            <div className={classnames('col-xs-12', styles.fakeInputData)}>
                <Box className={styles.label}>File Name</Box>
                <Box className={styles.value}>{initialValues[TITLE]}</Box>
            </div>
            <Box className="col-sm-12" mt={2}>
                <CDSButton
                    disabled={disabled}
                    qaTag="docxTemplate-upload"
                    type="submit"
                    variant="primary"
                >
                    <i className="fa fa-upload" /> Upload
                </CDSButton>
                &nbsp;&nbsp;
                <CDSButton
                    onClick={cancelHandler}
                    qaTag="docxTemplate-uploadCancel"
                    variant="secondary"
                >
                    Cancel
                </CDSButton>
            </Box>
            {uploadError && <div className="col-xs-12 text-center text-danger">{uploadError}</div>}
        </>
    );
};

DocxTemplateFormFieldset.propTypes = {
    disabled: PropTypes.bool,
    initialValues: PropTypes.object,
    cancelHandler: PropTypes.func.isRequired,
    uploadError: PropTypes.string,
};

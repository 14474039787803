import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@og-pro/ui';

import { SDv2Timeline } from '../../../../../../../components/GovApp';
import { ProjectCreateV2FunctionsContext } from '../../../context';

export const Timeline = () => {
    const timezone = useSelector((state) => state.auth.getIn(['user', 'organization', 'timezone']));
    const { array, change, disabled, form, project, showFormErrors } = useContext(
        ProjectCreateV2FunctionsContext
    );

    return (
        <Box>
            <SDv2Timeline
                array={array}
                change={change}
                disabled={disabled}
                form={form}
                isReverseAuction={project.template.isReverseAuction}
                project={project}
                showDatesByDefault
                showValidation={showFormErrors}
                timezone={timezone}
            />
        </Box>
    );
};

import { recordDocumentTypesDict } from '@og-pro/shared-config/recordsRetention';

const { DOCUMENTS } = recordDocumentTypesDict;

export const INFORMATION = 'information';
export const ADDENDA = 'addenda';
export const QUESTIONNAIRE = 'questionnaire';
export const PRICING = 'pricing';
export const COMPANY_PROFILE = 'company-profile';
export const SUBMIT = 'submit';

export const sections = [
    {
        title: 'Contact Information',
        query: INFORMATION,
    },
    {
        title: 'Addenda Confirmation',
        query: ADDENDA,
    },
    {
        title: 'Upload Documents',
        query: DOCUMENTS,
    },
    {
        title: 'Questionnaire',
        query: QUESTIONNAIRE,
    },
    {
        title: 'Pricing',
        query: PRICING,
    },
    {
        title: 'Company Profile',
        query: COMPANY_PROFILE,
    },
    {
        title: 'Submit',
        query: SUBMIT,
    },
];

export const sectionValues = sections.map((section) => section.query);

export function sectionsFilter(section, data = {}) {
    const { hasAddendumSection, hasQuestionnaire, hasPriceTable, skipCompanyProfile } = data;

    if (section.query === ADDENDA && !hasAddendumSection) {
        return false;
    }

    // Proposal will either have a documents or questionnaire section, but not both
    if (section.query === DOCUMENTS && hasQuestionnaire) {
        return false;
    }

    if (section.query === QUESTIONNAIRE && !hasQuestionnaire) {
        return false;
    }

    if (section.query === PRICING && !hasPriceTable) {
        return false;
    }

    if (section.query === COMPANY_PROFILE && skipCompanyProfile) {
        return false;
    }

    return true;
}

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { isString } from 'lodash';
import React, { PureComponent } from 'react';
import { DropdownButton, Panel } from 'react-bootstrap';

import { CSV, EXCEL } from './constants';
import { ButtonGroup, MenuItem } from '..';

// We only want local overrides which is why this is done here instead of via bsStyle/className
// TODO: Would love to get custom-styles working: https://react-bootstrap.github.io/utilities/custom-styles/
const PANEL_HEADING_STYLES = {
    backgroundColor: 'transparent',
    border: 'none',
    paddingLeft: 0,
    paddingRight: 0,
};

export class AgGridReactPanelHeading extends PureComponent {
    static propTypes = {
        buttons: PropTypes.arrayOf(PropTypes.node),
        exportFileName: PropTypes.string,
        // `gridApi` is not required because it is not available until after the `onGridReady`
        // callback on the AgGridReact has completed, but we don't want to block rendering of this
        // component until then. It does mean that the export buttons may not work on first render.
        gridApi: PropTypes.shape({
            exportDataAsCsv: PropTypes.func.isRequired,
            exportDataAsExcel: PropTypes.func.isRequired,
        }),
        headerClassName: PropTypes.string,
        hideButtons: PropTypes.bool,
        processCellCallback: PropTypes.func,
        processHeaderCallback: PropTypes.func,
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    };

    static defaultProps = {
        exportFileName: undefined,
        gridApi: undefined,
        hideButtons: false,
        processCellCallback: undefined,
        processHeaderCallback: undefined,
        title: undefined,
    };

    get styles() {
        return require('./index.scss');
    }

    // Ampersands are causing issues with Excel sheetnames. This replaces '&' with 'and'
    // as well as guarding against other illegal filename and worksheet characters.
    sanitizeText = (text) => {
        if (text && isString(text)) {
            return text.replace(/&/g, 'and').replace(/[/\\?%*:|"<>]/g, '_');
        }

        return 'Untitled';
    };

    /**
     * Handle exporting the grid data to CSV or Excel (xlsx).
     *
     * @param {Event} event The click event on the button - we prevent its default and propagation
     * @param {string} exportFormat The format to export the file in
     */
    handleExport = (event, exportFormat) => {
        event.preventDefault();
        event.stopPropagation();

        const { exportFileName, gridApi, processCellCallback, processHeaderCallback, title } =
            this.props;

        if (!gridApi) {
            return;
        }

        const exportOptions = {
            allColumns: false,
            columnGroups: true,
            fileName: exportFileName,
            // eslint-disable-next-line object-shorthand
            processCellCallback: function (params) {
                if (
                    ['custom1', 'custom2', 'custom3', 'custom4', 'custom5'].includes(
                        params.column.getColId()
                    ) &&
                    !!params.value &&
                    isString(params.value)
                ) {
                    if (/^[0-9]+$/.test(params.value)) {
                        return `'${params.value}`;
                    }
                }

                return params.value;
            },
        };

        if (title && !exportFileName) {
            exportOptions.fileName = this.sanitizeText(title);
        }

        if (processCellCallback) {
            exportOptions.processCellCallback = processCellCallback;
        }

        if (processHeaderCallback) {
            exportOptions.processHeaderCallback = processHeaderCallback;
        }

        if (exportFormat === CSV) {
            if (exportOptions.fileName) {
                exportOptions.fileName += '.csv';
            }

            gridApi.exportDataAsCsv(exportOptions);
        } else {
            if (exportOptions.fileName) {
                exportOptions.fileName += '.xlsx';
            }

            exportOptions.sheetName = this.sanitizeText(title);

            gridApi.exportDataAsExcel(exportOptions);
        }
    };

    /**
     * Handles clicking the button to export the grid data to a CSV.
     *
     * @param {Event} event The click event on the button
     */
    handleExportToCSVClick = (event) => {
        this.handleExport(event, CSV);
    };

    /**
     * Handles clicking the button to export the grid data to a Excel.
     *
     * @param {Event} event The click event on the button
     */
    handleExportToExcelClick = (event) => {
        this.handleExport(event, EXCEL);
    };

    render() {
        const { buttons, headerClassName, hideButtons, title } = this.props;

        return (
            <Panel.Heading style={PANEL_HEADING_STYLES}>
                <Panel.Title className={classnames(this.styles.panelTitle, headerClassName)}>
                    {title && <div className="pull-left">{title}</div>}
                    {!hideButtons && (
                        <div className={`no-print ${this.styles.exportButtons}`}>
                            <ButtonGroup bsSize="sm">{buttons}</ButtonGroup>
                            <DropdownButton bsSize="sm" title="Export This Table">
                                <MenuItem
                                    onClick={this.handleExportToExcelClick}
                                    qaTag="agGridReactPanelHeading-exportToExcel"
                                >
                                    <i className="fa fa-table" /> Export to Excel
                                </MenuItem>
                                <MenuItem
                                    onClick={this.handleExportToCSVClick}
                                    qaTag="agGridReactPanelHeading-exportToCsv"
                                >
                                    <i className="fa fa-download" /> Export to CSV
                                </MenuItem>
                            </DropdownButton>
                        </div>
                    )}
                    <div className="clearfix" />
                </Panel.Title>
            </Panel.Heading>
        );
    }
}

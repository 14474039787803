import { fieldNames } from './constants';

const { AMOUNT, BUDGET_ALLOCATIONS, TAG_ID } = fieldNames;

export const validate = (values) => {
    const errors = {};

    if (values[AMOUNT] && values[AMOUNT] >= 10000000000) {
        errors[AMOUNT] = 'Amount is too large ($9,999,999,999 max)';
    }

    errors[BUDGET_ALLOCATIONS] = (values[BUDGET_ALLOCATIONS] || []).map((budgetAllocation) => {
        const budgetAllocationErrors = {};

        if (!budgetAllocation[TAG_ID]) {
            budgetAllocationErrors[TAG_ID] = 'Please select a year';
        }

        if (!budgetAllocation[AMOUNT]) {
            budgetAllocationErrors[AMOUNT] = 'Please enter an amount';
        } else if (budgetAllocation[AMOUNT] >= 10000000000) {
            budgetAllocationErrors[AMOUNT] = 'Amount is too large ($9,999,999,999 max)';
        }

        return budgetAllocationErrors;
    });

    return errors;
};

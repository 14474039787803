import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { reduxForm, Field } from 'redux-form';

import { userRolePermissions } from '@og-pro/shared-config/userRoles';

import { form, fieldNames } from './constants';
import { validate } from './validate';
import { AdminRequired, Button, InputText, SearchSelect, Toggle } from '../../../../../components';

const { AWARD_NOTE, PROPOSAL_IDS, SHOULD_NOTIFY } = fieldNames;

const formConfig = {
    form,
    validate,
};

const { IS_BID_POSTER } = userRolePermissions;

// @reduxForm
class ConnectedProposalSelectForm extends PureComponent {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        hasAwardPending: PropTypes.bool.isRequired,
        hasBidPostAuthorization: PropTypes.bool.isRequired,
        proposals: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.number.isRequired,
            })
        ).isRequired,
        shouldNotify: PropTypes.bool,
        updateError: PropTypes.string,
        updating: PropTypes.bool,
        isLineItemAward: PropTypes.bool,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const {
            handleSubmit,
            hasAwardPending,
            hasBidPostAuthorization,
            isLineItemAward,
            proposals,
            shouldNotify,
            updateError,
            updating,
        } = this.props;

        const { errorContainer, submitBtn } = this.styles;

        const noProposals = proposals.length === 0;
        const icon = updating ? 'fa-spin fa-spinner' : 'fa-trophy';
        const disabled = updating || noProposals;

        return (
            <form onSubmit={handleSubmit}>
                {!isLineItemAward && (
                    <div>
                        <p className="text-center">
                            Please select the vendor(s) to&nbsp;
                            {hasAwardPending ? 'recommend for' : 'award'} the project
                        </p>
                        <Field
                            aria-label="Select vendor(s) to award"
                            component={SearchSelect}
                            disabled={disabled}
                            isMulti
                            name={PROPOSAL_IDS}
                            options={proposals}
                            qaTag={`proposalSelectModal-${PROPOSAL_IDS}`}
                        />
                    </div>
                )}
                <Field
                    component={InputText}
                    disabled={disabled}
                    help="Note will be publicly displayed if bid results are made public"
                    label="Award Note (optional)"
                    minRows={2}
                    name={AWARD_NOTE}
                    overrideFeedback
                    placeholder="Enter optional note"
                    qaTag={`proposalSelectModal-${AWARD_NOTE}`}
                    type="textarea"
                />
                <div className={submitBtn}>
                    <Button
                        bsSize="lg"
                        bsStyle="success"
                        disabled={!hasBidPostAuthorization || disabled}
                        qaTag="proposalSelectModal-selectVendors"
                        type="submit"
                    >
                        <i className={`fa ${icon}`} /> Select Vendor(s)
                    </Button>
                </div>
                {updateError && <div className={errorContainer}>{updateError}</div>}
                {hasBidPostAuthorization ? (
                    <>
                        <div className={this.styles.toggleEmail}>
                            <Field
                                component={Toggle}
                                disabled={disabled}
                                label={`Send email notifying vendors of the ${
                                    hasAwardPending ? 'recommendation' : 'award'
                                }?`}
                                labelInline
                                labelInlineTogglePosition="right"
                                name={SHOULD_NOTIFY}
                                qaTag={`proposalSelectModal-${SHOULD_NOTIFY}`}
                            />
                        </div>
                        <p className="text-warning text-center">
                            {shouldNotify
                                ? `We will send an email to each vendor following the project\nto notify them of the ${
                                      hasAwardPending ? 'recommendation' : 'award'
                                  }.`
                                : 'No emails will be sent to vendors.'}
                        </p>
                    </>
                ) : (
                    <AdminRequired
                        contactText="contact a bid poster"
                        permissionType={IS_BID_POSTER}
                    />
                )}
            </form>
        );
    }
}

export const ProposalSelectForm = reduxForm(formConfig)(ConnectedProposalSelectForm);

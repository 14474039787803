exports.notificationLevels = {
    NONE: 0,
    ALL: 1,
    CATEGORIES: 2,
};

exports.notificationLevelsText = {
    [exports.notificationLevels.NONE]: 'Unsubscribed',
    [exports.notificationLevels.ALL]: 'All',
    [exports.notificationLevels.CATEGORIES]: 'Categories only',
};

exports.notificationLevelOptions = [
    {
        icon: 'rss',
        label: 'Any new opportunity is issued',
        value: exports.notificationLevels.ALL,
    },
    {
        icon: 'filter',
        label: 'New opportunity matches company category codes',
        value: exports.notificationLevels.CATEGORIES,
    },
    {
        icon: 'ban',
        label: 'Never',
        value: exports.notificationLevels.NONE,
    },
];

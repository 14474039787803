import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { NO_BID, UNIT_PRICE } from '@og-pro/shared-config/priceTables';

import { AWARD_ID, AWARD_TYPE, PROJECT_ID, VENDOR_PRICE_ITEM_ID } from './constants';
import { createLineItemAward, updateLineItemAward } from '../../actions/proposalEvaluations';
import { showSnackbar } from '../../actions/notification';

export class ConnectedLineItemCellRenderer extends React.Component {
    static propTypes = {
        data: PropTypes.any.isRequired,
        node: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
        valueFormatted: PropTypes.any.isRequired,
        column: PropTypes.shape({
            colId: PropTypes.string,
        }),
        createLineItemAward: PropTypes.func.isRequired,
        updateLineItemAward: PropTypes.func.isRequired,
        displayError: PropTypes.func.isRequired,
        isViewOnly: PropTypes.bool,
        hasPercentage: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        const {
            data,
            column: { colId },
        } = props;
        this.state = {
            awardType: data[colId.replace(UNIT_PRICE, AWARD_TYPE)],
            awardId: data[colId.replace(UNIT_PRICE, AWARD_ID)],
        };
    }

    styles() {
        const {
            data,
            hasPercentage,
            value,
            column: { colId },
            valueFormatted,
        } = this.props;
        const { awardType } = this.state;
        const style = {
            color: 'black',
            appearance: 'none',
            backgroundColor: 'transparent',
            border: 'none',
            padding: '0 1em 0 0',
            margin: '0',
            width: '100%',
            fontFamily: 'inherit',
            fontSize: 'inherit',
            cursor: 'inherit',
            lineHeight: 'inherit',
            textAlignLast: 'left',
        };

        if (Object.keys(data || {}).length && valueFormatted !== 'No Bid') {
            const isValueGreaterArray = Object.keys(data || {})
                .filter((dataKey) => dataKey !== colId)
                .filter(
                    (dataKey) =>
                        dataKey.includes(UNIT_PRICE) &&
                        typeof data[dataKey] === 'number' &&
                        value > data[dataKey]
                )
                .filter((dataKey) => {
                    const id = dataKey.slice(0, dataKey.indexOf(':'));
                    return data[`${id}:${NO_BID}`] === false;
                });
            if (!isValueGreaterArray.length && !hasPercentage) {
                style.color = 'green';
            }
        }

        if (awardType && awardType === 'primary') {
            style.backgroundColor = 'forestgreen';
            style.color = 'white';
        } else if (awardType && awardType === 'backup') {
            style.backgroundColor = 'gray';
            style.color = 'white';
        }

        return style;
    }

    saveAward = (val) => {
        const {
            data,
            column: { colId },
        } = this.props;
        const projectId = data[colId.replace(UNIT_PRICE, PROJECT_ID)];
        const vendorPriceItemId = data[colId.replace(UNIT_PRICE, VENDOR_PRICE_ITEM_ID)];
        const { awardId } = this.state;
        if (!awardId && val === 'no award') {
            return;
        }
        if (awardId) {
            this.props
                .updateLineItemAward(projectId, vendorPriceItemId, awardId, val)
                .then(this.updateRowData)
                .catch(() =>
                    this.props.displayError('Error updating line item award', { isError: true })
                );
        } else if (val) {
            this.props
                .createLineItemAward(projectId, vendorPriceItemId, val)
                .then(this.updateRowData)
                .catch(() =>
                    this.props.displayError('Error creating line item award', { isError: true })
                );
        }
    };

    updateRowData = (result) => {
        const {
            node,
            column: { colId },
        } = this.props;
        const awardId = colId.replace(UNIT_PRICE, AWARD_ID);
        const awardType = colId.replace(UNIT_PRICE, AWARD_TYPE);
        if (!result) {
            // item deleted, update aggrid data
            node.setData({ ...node.data, [awardId]: null, [awardType]: null });
            this.setState({ awardId: null, awardType: null });
        } else {
            node.setData({ ...node.data, [awardId]: result.id, [awardType]: result.awardType });
            this.setState({ awardId: result.id, awardType: result.awardType });
        }
    };

    selectChange = (event) => {
        const val = event.target.value;
        if (['primary', 'backup', 'no award'].includes(val)) {
            this.saveAward(val);
        }
    };

    render() {
        const { valueFormatted, isViewOnly } = this.props;

        if (isViewOnly || valueFormatted === 'No Bid') {
            return <div style={this.styles()}>{valueFormatted}</div>;
        }

        return (
            <select onChange={this.selectChange} style={this.styles()} value={valueFormatted}>
                <option disabled value={valueFormatted}>
                    {valueFormatted}
                </option>
                <option value="primary">Primary</option>
                <option value="backup">Backup</option>
                <option value="no award">No Award</option>
            </select>
        );
    }
}

const mapProps = (state, ownProps) => {
    return ownProps;
};

const mapDispatch = {
    updateLineItemAward,
    createLineItemAward,
    displayError: showSnackbar,
};

export const LineItemCellRenderer = connect(mapProps, mapDispatch)(ConnectedLineItemCellRenderer);

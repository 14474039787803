export const REQUIRED_TEXT = 'This field is required.';
export const REQUIRED_TEXT_SHORT = 'Required';

export const REQUIRED_DEPARTMENT_TEXT =
    'Department information is not valid. ' +
    'Please click Edit Department Display Information to update the invalid fields.';

export const REQUIRED_CONTACT_TEXT =
    'Contact information is not valid. ' +
    'Please click Edit Contact Display Information to update the invalid fields.';

/*
 * The maximum limit a traditional MySQL `TEXT` column can hold is 65,535 characters. Our MSSQL
 * database uses a nvarchar(max) type that can hold up to 2 MB of text (millions of characters).
 * https://stackoverflow.com/a/11131977
 * https://chartio.com/resources/tutorials/understanding-strorage-sizes-for-mysql-text-data-types
 *
 * There are potential performance concerns if this column gets too big, so intentionlly setting to
 * approximate the MySQL `TEXT` column size.
 * https://docs.microsoft.com/en-us/sql/t-sql/data-types/nchar-and-nvarchar-transact-sql?view=sql-server-ver15
 *
 * Note: We had a user bump into limit when it was set to 20,000 characters on 1/16/20.
 */
export const MAX_TEXT_AREA_LENGTH = 500000;

export const TEMPLATE_TITLE_REQUIRED = 'Please give your template a title.';
export const TEMPLATE_TITLE_TOO_LONG = 'Title is too long.';
export const TEMPLATE_ICON_REQUIRED = 'Please select an icon.';
export const TEMPLATE_ACRONYM_REQUIRED = 'Please give your template an acronym or abbreviation.';
export const TEMPLATE_ACRONYM_TOO_LONG = 'Acronym is too long.';
export const TEMPLATE_USER_REQUIRED = 'Please select an owner.';

import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from 'redux-form';

import { updatePriceItemVendors } from '../helpers';
import { fieldNames } from '../../../constants';
import { getLineItemOptions, requisitionsCreateFormValueSelector } from '../../../selectors';
import { qaTagPageName } from '../../../../constants';
import { SearchSelect } from '../../../../../../../components';

const { ASSOCIATED_LINE_ITEMS, PRICE_TABLE } = fieldNames;

export const AssociatedLineItems = ({
    change,
    disabled,
    fieldName,
    fields,
    index,
    showFormValidation,
}) => {
    const dispatch = useDispatch();

    const lineItemOptions = useSelector(getLineItemOptions);
    const priceTable = useSelector((state) =>
        requisitionsCreateFormValueSelector(state, PRICE_TABLE)
    );
    const vendor = fields.get(index);

    // keeps vendor's associated line items in sync with line item's associated vendors
    const onAssociatedLineItemChange = (associatedLineItems) => {
        updatePriceItemVendors(
            dispatch,
            change,
            priceTable.priceItems,
            associatedLineItems,
            vendor
        );
    };

    return (
        <Field
            component={SearchSelect}
            disabled={disabled}
            isMulti
            isSearchable={false}
            label="Associated Line Items"
            name={`${fieldName}.${ASSOCIATED_LINE_ITEMS}`}
            onChangeWithOption={onAssociatedLineItemChange}
            options={lineItemOptions}
            placeholder="Search line items"
            qaTag={`${qaTagPageName}-associatedLineItems`}
            showValidation={showFormValidation}
            useOpenGovStyle
        />
    );
};

AssociatedLineItems.propTypes = {
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    fieldName: PropTypes.string.isRequired,
    fields: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    showFormValidation: PropTypes.bool,
};

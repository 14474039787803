import { fieldNames } from './constants';

const { FIRST_LEVEL, SECOND_LEVEL, THIRD_LEVEL, FOURTH_LEVEL, FIFTH_LEVEL } = fieldNames;

export const validate = (values) => {
    const errors = {};

    if (!values[FIRST_LEVEL]) {
        errors[FIRST_LEVEL] = 'Please specify a first level';
    }

    if (!values[SECOND_LEVEL]) {
        errors[SECOND_LEVEL] = 'Please specify a second level';
    }

    if (!values[THIRD_LEVEL]) {
        errors[THIRD_LEVEL] = 'Please specify a third level';
    }

    if (!values[FOURTH_LEVEL]) {
        errors[FOURTH_LEVEL] = 'Please specify a fourth level';
    }

    if (!values[FIFTH_LEVEL]) {
        errors[FIFTH_LEVEL] = 'Please specify a fifth level';
    }

    return errors;
};

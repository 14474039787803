/**
 * Capitalizes each word in a string
 * @param {string} str
 * @return {string}
 */
export function capitalizeAllWords(str) {
    if (!str) {
        return '';
    }

    return str.toLowerCase().replace(/(?:^|\s)\S/g, (char) => char.toUpperCase());
}

/**
 * Adds a percent sign to the number
 * @param {number} num
 * @return {string}
 */
export function formatPercent(num) {
    if (Number.isNaN(Number.parseFloat(num))) {
        return null;
    }
    return `${num}%`;
}

/**
 * AgGrid adds _1 _2 _3... to the columns to make them unique.
 * if we have more than one totalCost for example, name ends up totalCost_1
 * @param {string} name
 * @returns {string}
 */
export function cleanAgGridUniqueColName(name) {
    return (name || '').replace(/_(\d+)/g, '').trim();
}

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { ProjectProfile, ProjectMenuDropdown, SubscribeButton } from '../../../../components';

export class EvaluationProfile extends PureComponent {
    static propTypes = {
        actionHandler: PropTypes.func.isRequired,
        dashboardPath: PropTypes.string.isRequired,
        isGlobalEditor: PropTypes.bool.isRequired,
        isOwner: PropTypes.bool.isRequired,
        project: PropTypes.object.isRequired,
        subscribed: PropTypes.bool.isRequired,
        timezone: PropTypes.string.isRequired,
    };

    get styles() {
        return require('./EvaluationProfile.scss');
    }

    get projectPath() {
        const { government_id: governmentId, id: projectId } = this.props.project;

        return `/governments/${governmentId}/projects/${projectId}`;
    }

    renderMenu() {
        const { actionHandler, isGlobalEditor, isOwner, project, subscribed } = this.props;

        return (
            <ProjectMenuDropdown
                actionHandler={actionHandler}
                isEvaluation
                isGlobalEditor={isGlobalEditor}
                isOwner={isOwner}
                project={project}
                subscribed={subscribed}
            />
        );
    }

    renderSubscribeButton() {
        const { project } = this.props;

        return <SubscribeButton isGovernment project={project} />;
    }

    render() {
        const {
            dashboardPath,
            project,
            project: { government, status, title },
            subscribed,
            timezone,
        } = this.props;

        return (
            <ProjectProfile
                dashboardPath={dashboardPath}
                imageUrl={government.organization.logo}
                menuComponent={this.renderMenu()}
                project={project}
                showBidDetails
                status={status}
                subscribeButton={this.renderSubscribeButton()}
                subscribed={subscribed}
                timezone={timezone}
                title={title}
            />
        );
    }
}

import { last } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { formValueSelector, reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';

import {
    form,
    PROPOSAL_IDS,
    UNSEAL_ALL_PROPOSALS_PRICING,
    UNSEAL_BID_FIELD,
    UNSEAL_BID_WITH_PRICING,
    unsealBidOptions,
    unsealBidText,
    unsealBidHelp,
    unsealBidPricingHelp,
} from './constants';
import { validate } from './validate';
import { MultipleChoiceInput, Toggle } from '../../../components';
import { loadQualifiedProposals } from '../../../actions/evaluations';

const formSelector = formValueSelector(form);

const mapStateToProps = (state, props) => {
    const onSubmit = (rawData) => {
        const data = { isBidUnsealed: true };

        if (rawData[UNSEAL_BID_FIELD] === UNSEAL_BID_WITH_PRICING) {
            if (rawData[UNSEAL_ALL_PROPOSALS_PRICING]) {
                data.isBidPricingUnsealed = true;
            } else {
                data.proposalIds = rawData[PROPOSAL_IDS] || [];
            }
        }

        // Call the submit handler with the formatted data
        props.onSubmit(data);
    };

    const { isBidUnsealed } = props;

    const options = isBidUnsealed ? [unsealBidOptions[1]] : unsealBidOptions;

    return {
        initialValues: {
            [UNSEAL_BID_FIELD]: last(options).value,
            [UNSEAL_ALL_PROPOSALS_PRICING]: true,
        },
        onSubmit, // `onSubmit` must be specified to form for remote submission to work
        options,
        unsealAllProposalsPricing: formSelector(state, UNSEAL_ALL_PROPOSALS_PRICING),
        unsealBidWithPricing: formSelector(state, UNSEAL_BID_FIELD) === UNSEAL_BID_WITH_PRICING,
    };
};

const mapDispatchToProps = {
    loadQualifiedProposals,
};

const formConfig = {
    form,
    validate,
};

// @connect
// @reduxForm
class ConnectedUnsealBidsForm extends Component {
    static propTypes = {
        change: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        handleSubmit: PropTypes.func.isRequired,
        isBidUnsealed: PropTypes.bool.isRequired,
        loadQualifiedProposals: PropTypes.func.isRequired,
        options: PropTypes.array.isRequired,
        projectId: PropTypes.number.isRequired,
        text: PropTypes.string,
        unsealAllProposalsPricing: PropTypes.bool,
        unsealBidWithPricing: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            loadError: null,
            loading: false,
            proposals: null,
            selectedProposalIds: {},
        };
    }

    get styles() {
        return require('./index.scss');
    }

    toggleAllProposalsPricing = (e, isAllProposals) => {
        const { projectId } = this.props;

        if (!isAllProposals && !this.state.proposals) {
            this.setState({ loading: true, loadError: null });
            return this.props
                .loadQualifiedProposals(projectId)
                .then((qualifiedProposals) => {
                    const proposals = qualifiedProposals.filter((proposal) => {
                        return !proposal.isPricingUnsealed;
                    });
                    this.setState({
                        loading: false,
                        proposals,
                        selectedProposalIds: proposals.reduce((obj, proposal) => {
                            obj[proposal.id] = false;
                            return obj;
                        }, {}),
                    });
                })
                .catch((error) => {
                    this.setState({ loading: false, loadError: error.message });
                });
        }
    };

    toggleSelectedProposal = (proposal) => () => {
        const { change } = this.props;

        this.setState(
            (prevState) => {
                return {
                    selectedProposalIds: {
                        ...prevState.selectedProposalIds,
                        [proposal.id]: !prevState.selectedProposalIds[proposal.id],
                    },
                };
            },
            () => {
                const { selectedProposalIds } = this.state;

                const proposalIds = Object.keys(selectedProposalIds)
                    .filter((id) => selectedProposalIds[id])
                    .map((id) => Number.parseInt(id, 10));

                change(PROPOSAL_IDS, proposalIds);
            }
        );
    };

    renderProposals() {
        const { loadError, loading, proposals, selectedProposalIds } = this.state;

        if (loading) {
            return <div className="text-center">Loading Responses...</div>;
        }

        if (loadError) {
            return (
                <div className="text-center text-danger">Error Loading Responses: {loadError}</div>
            );
        }

        if (!proposals) {
            return null;
        }

        return (
            <div className="text-left">
                <h5 className={this.styles.header}>Select responses to unseal pricing:</h5>
                <ListGroup>
                    {proposals.map((proposal) => {
                        const isSelected = selectedProposalIds[proposal.id];
                        const icon = isSelected ? 'fa-check-circle text-success' : 'fa-circle-thin';
                        return (
                            <ListGroupItem
                                bsStyle={isSelected ? 'success' : undefined}
                                key={proposal.id}
                                onClick={this.toggleSelectedProposal(proposal)}
                            >
                                <i className={`fa fa-lg fa-fw ${icon}`} /> {proposal.companyName}
                            </ListGroupItem>
                        );
                    })}
                </ListGroup>
            </div>
        );
    }

    render() {
        const {
            disabled,
            handleSubmit,
            isBidUnsealed,
            options,
            text,
            unsealAllProposalsPricing,
            unsealBidWithPricing,
        } = this.props;

        return (
            <form className={this.styles.container} onSubmit={handleSubmit}>
                {(text || !isBidUnsealed) && (
                    <p className={`${this.styles.text} text-muted`}>{text || unsealBidText}</p>
                )}
                <p className={`${this.styles.text} text-muted`}>
                    {isBidUnsealed ? unsealBidPricingHelp : unsealBidHelp}
                </p>
                <Field
                    component={MultipleChoiceInput}
                    disabled={disabled}
                    name={UNSEAL_BID_FIELD}
                    options={options}
                />
                {unsealBidWithPricing && (
                    <>
                        <Field
                            component={Toggle}
                            disabled={disabled}
                            label="Unseal pricing for all responses"
                            labelInline
                            labelInlineTogglePosition="left"
                            name={UNSEAL_ALL_PROPOSALS_PRICING}
                            onChange={this.toggleAllProposalsPricing}
                        />
                        {!unsealAllProposalsPricing && this.renderProposals()}
                    </>
                )}
            </form>
        );
    }
}

export const UnsealBidsForm = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedUnsealBidsForm);

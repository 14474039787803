import { createSelector } from 'reselect';

import { approvalWorkflowTypesDict } from '@og-pro/shared-config/approval';

import {
    getApprovalWorkflowsJS,
    hasContractingSubscription,
    hasIntakeSubscription,
} from '../../GovApp/selectors';

const { BUILDER, CONTRACT, INTAKE } = approvalWorkflowTypesDict;

export const getNavItems = createSelector(
    [hasIntakeSubscription, hasContractingSubscription],
    (hasIntake, hasContracting) => {
        return [
            {
                name: 'Project',
                type: BUILDER,
            },
            ...(hasContracting
                ? [
                      {
                          name: 'Contract',
                          type: CONTRACT,
                      },
                  ]
                : []),
            ...(hasIntake
                ? [
                      {
                          name: 'Intake',
                          type: INTAKE,
                      },
                  ]
                : []),
        ];
    }
);
export const getSelectedModuleType = createSelector(
    [(state, props) => props.type, getNavItems],
    (type, navItems) => {
        return (navItems.find((item) => item.type === type) || navItems[0]).type;
    }
);

export const getSelectedApprovalWorkflows = createSelector(
    [getSelectedModuleType, getApprovalWorkflowsJS],
    (selectedNavItemType, approvalWorkflows) => {
        switch (selectedNavItemType) {
            case INTAKE:
                return approvalWorkflows.filter((workflow) => workflow.moduleType === INTAKE);
            case CONTRACT:
                return approvalWorkflows.filter((workflow) => workflow.moduleType === CONTRACT);
            case BUILDER:
            default:
                return approvalWorkflows.filter((workflow) => workflow.moduleType === BUILDER);
        }
    }
);

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Well } from '../../Well/Well';
import { CountdownTimerText } from '../../CountdownTimer';

export const ReverseAuctionNotStarted = ({ auctionEndDate, auctionStartDate, timezone }) => {
    const auctionEndDateTz = moment.tz(auctionEndDate, timezone);

    return (
        <Well className="no-print">
            <h3 className="text-center">
                <i className="fa fa-gavel" /> Auction Not Started
            </h3>
            <p style={{ textAlign: 'center', marginTop: '10px' }}>
                The auction will begin in <CountdownTimerText endTime={auctionStartDate} /> and go
                through {auctionEndDateTz.format('ll')} at {auctionEndDateTz.format('h:mm A')}.
            </p>
        </Well>
    );
};

ReverseAuctionNotStarted.propTypes = {
    auctionEndDate: PropTypes.string.isRequired,
    auctionStartDate: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired,
};

import { buildQaTag, componentNames } from '@og-pro/ui';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Dropdown as DropdownBS } from 'react-bootstrap';

export class Dropdown extends PureComponent {
    static propTypes = {
        qaTag: PropTypes.string.isRequired, // Format: <pageName>-<simpleDescription>
    };

    render() {
        const { qaTag, ...props } = this.props;

        const tagWithComponentName = buildQaTag(qaTag, componentNames.DROPDOWN);

        return <DropdownBS data-qa={tagWithComponentName} {...props} />;
    }
}

Dropdown.Toggle = DropdownBS.Toggle;
Dropdown.Menu = DropdownBS.Menu;

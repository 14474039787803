/* eslint-disable camelcase */
import qs from 'qs';
import moment from 'moment';
import { startCase } from 'lodash';

import { contractStatusesDict } from '@og-pro/shared-config/contracts';

import { checklistStatusTypes } from '@og-pro/shared-config/checklists';

import { purchaseOrderStatuses } from '@og-pro/shared-config/purchaseOrders';

import { chartColors } from './constants';
import { currencyFormatter } from '../../../helpers';

const { ENDED } = contractStatusesDict;

const { RELEASED } = checklistStatusTypes;

export const goToContracts = (govId, ids, router, additionalQueryParams) => {
    router.push(
        `/governments/${govId}/contracts?${qs.stringify(
            { ids, ...additionalQueryParams },
            { arrayFormat: 'comma', encode: false }
        )}`
    );
};

export const getInsuranceExpiringData = (contracts, router) => {
    const govId = contracts.length > 0 && contracts[0].government_id;

    const expiredCount = {
        custom: {
            ids: [],
        },
        get events() {
            return {
                click: () => goToContracts(govId, this.custom.ids, router),
            };
        },
        name: 'Expired',
        get y() {
            return this.custom.ids.length;
        },
    };

    const lessThan10DaysCount = {
        custom: {
            ids: [],
        },
        get events() {
            return {
                click: () => goToContracts(govId, this.custom.ids, router),
            };
        },
        name: 'Expiring Within 10 Days',
        get y() {
            return this.custom.ids.length;
        },
    };

    const between10And30DaysCount = {
        custom: {
            ids: [],
        },
        get events() {
            return {
                click: () => goToContracts(govId, this.custom.ids, router),
            };
        },
        name: 'Expiring Between 10-30 Days',
        get y() {
            return this.custom.ids.length;
        },
    };

    const between30And60DaysCount = {
        custom: {
            ids: [],
        },
        get events() {
            return {
                click: () => goToContracts(govId, this.custom.ids, router),
            };
        },
        name: 'Expiring Between 30-60 days',
        get y() {
            return this.custom.ids.length;
        },
    };

    const idsMap = {
        expiredCount: {},
        lessThan10DaysCount: {},
        between10And30DaysCount: {},
        between30And60DaysCount: {},
    };

    contracts.forEach((contract) => {
        const { id } = contract;
        if (contract.status !== ENDED) {
            contract.contractInsurances.forEach((insurance) => {
                const days = moment().diff(insurance.expirationDate, 'days');

                if (days > 0 && !idsMap.expiredCount[id]) {
                    idsMap.expiredCount[id] = id;
                    return expiredCount.custom.ids.push(id);
                }

                if (days <= 0 && days > -10 && !idsMap.lessThan10DaysCount[id]) {
                    idsMap.lessThan10DaysCount[id] = id;
                    return lessThan10DaysCount.custom.ids.push(id);
                }

                if (days < -10 && days > -30 && !idsMap.between10And30DaysCount[id]) {
                    idsMap.between10And30DaysCount[id] = id;
                    return between10And30DaysCount.custom.ids.push(id);
                }

                if (days < -30 && days > -60 && !idsMap.between30And60DaysCount[id]) {
                    idsMap.between30And60DaysCount[id] = id;
                    return between30And60DaysCount.custom.ids.push(id);
                }
            });
        }
    });

    return [expiredCount, lessThan10DaysCount, between10And30DaysCount, between30And60DaysCount];
};

export const getContractsEndingData = (contracts, router) => {
    const govId = contracts.length > 0 && contracts[0].government_id;

    const expiredCount = {
        custom: {
            ids: [],
        },
        get events() {
            return {
                click: () => goToContracts(govId, this.custom.ids, router),
            };
        },
        name: 'Ended Within 6 Months',
        get y() {
            return this.custom.ids.length;
        },
    };

    const lessThan10DaysCount = {
        custom: {
            ids: [],
        },
        get events() {
            return {
                click: () => goToContracts(govId, this.custom.ids, router),
            };
        },
        name: 'Ending Within 10 Days',
        get y() {
            return this.custom.ids.length;
        },
    };

    const between10And30DaysCount = {
        custom: {
            ids: [],
        },
        get events() {
            return {
                click: () => goToContracts(govId, this.custom.ids, router),
            };
        },
        name: 'Ending Between 10-30 Days',
        get y() {
            return this.custom.ids.length;
        },
    };

    const between30And60DaysCount = {
        custom: {
            ids: [],
        },
        get events() {
            return {
                click: () => goToContracts(govId, this.custom.ids, router),
            };
        },
        name: 'Ending Between 30-60 days',
        get y() {
            return this.custom.ids.length;
        },
    };

    contracts.forEach((contract) => {
        const { endDate, id } = contract;
        const days = moment().diff(endDate, 'days');

        if (days > 0) expiredCount.custom.ids.push(id);
        if (days <= 0 && days > -10) lessThan10DaysCount.custom.ids.push(id);
        if (days < -10 && days > -30) between10And30DaysCount.custom.ids.push(id);
        if (days < -30 && days > -60) between30And60DaysCount.custom.ids.push(id);
    });

    return [expiredCount, lessThan10DaysCount, between10And30DaysCount, between30And60DaysCount];
};

export const getChecklistsReleasedByAgeChartData = (contracts, router) => {
    const govId = contracts.length > 0 && contracts[0].government_id;

    const greaterThan30DaysCount = {
        custom: {
            ids: [],
        },
        get events() {
            return {
                click: () => goToContracts(govId, this.custom.ids, router),
            };
        },
        name: 'Released More Than 30 Days Ago',
        get y() {
            return this.custom.ids.length;
        },
    };

    const between15And30DaysCount = {
        custom: {
            ids: [],
        },
        get events() {
            return {
                click: () => goToContracts(govId, this.custom.ids, router),
            };
        },
        name: 'Released 15-30 Days Ago',
        get y() {
            return this.custom.ids.length;
        },
    };

    const lessThan14DaysCount = {
        custom: {
            ids: [],
        },
        get events() {
            return {
                click: () => goToContracts(govId, this.custom.ids, router),
            };
        },
        name: 'Released Within 14 Days',
        get y() {
            return this.custom.ids.length;
        },
    };

    const idsMap = {
        greaterThan30DaysCount: {},
        between15And30DaysCount: {},
        lessThan14DaysCount: {},
    };

    contracts.forEach((contract) => {
        const { id } = contract;
        if (contract.status !== ENDED) {
            contract.checklists
                .filter((checklist) => checklist.status === RELEASED)
                .forEach((checklist) => {
                    const days = moment().diff(checklist.releasedAt, 'days');

                    if (days <= 14 && !idsMap.lessThan14DaysCount[id]) {
                        idsMap.lessThan14DaysCount[id] = id;
                        return lessThan14DaysCount.custom.ids.push(id);
                    }

                    if (days > 14 && days <= 30 && !idsMap.between15And30DaysCount[id]) {
                        idsMap.between15And30DaysCount[id] = id;
                        return between15And30DaysCount.custom.ids.push(id);
                    }

                    if (days > 31 && !idsMap.greaterThan30DaysCount[id]) {
                        idsMap.greaterThan30DaysCount[id] = id;
                        return greaterThan30DaysCount.custom.ids.push(id);
                    }
                });
        }
    });

    return [
        greaterThan30DaysCount,
        { custom: { ids: [] } }, // Empty placeholder for orange color
        between15And30DaysCount,
        lessThan14DaysCount,
    ];
};

export const getContractsByDepartmentData = (contracts, router) => {
    const govId = contracts.length > 0 && contracts[0].government_id;
    const departmentCounts = {};
    const data = [];

    contracts.forEach((contract) => {
        const { departmentName, id } = contract;

        if (departmentName) {
            if (!departmentCounts[departmentName]) {
                departmentCounts[departmentName] = { count: 0, ids: [] };
            }

            departmentCounts[departmentName].ids.push(id);
            return departmentCounts[departmentName].count++;
        }
    });

    Object.keys(departmentCounts)
        .sort((a, b) => a.count - b.count)
        .forEach((department) => {
            const displayName = startCase(department);

            data.push({
                y: departmentCounts[department].count,
                description: `${displayName} Contracts`,
                cursor: 'pointer',
                events: {
                    click: () => goToContracts(govId, departmentCounts[department].ids, router),
                },
                label: departmentCounts[department].count,
                name: displayName,
            });
        });

    return data;
};

export const getContractSpendByDepartmentForDefaultFiscalYearData = (
    contracts,
    departments,
    defaultFiscalYear,
    router
) => {
    const govId = contracts.length > 0 && contracts[0].government_id;
    const departmentCounts = {};
    const series = [];

    departments.forEach((department) => {
        departmentCounts[department.id] = {
            contractIds: [],
            name: department.name,
            value: 0,
        };
    });

    contracts.forEach((contract) => {
        contract.budget.purchaseOrders
            .filter((purchaseOrder) => purchaseOrder.status === purchaseOrderStatuses.ISSUED)
            .forEach((purchaseOrder) => {
                if (purchaseOrder.tag_id === defaultFiscalYear.id) {
                    const departmentId = purchaseOrder.department_id;

                    // If the purchase order department has not been added to the departmentCounts (probably because it is a deleted department), don't add it to the chart data
                    if (!departmentCounts[departmentId]) {
                        return;
                    }

                    departmentCounts[departmentId].contractIds.push(contract.id);
                    departmentCounts[departmentId].value += purchaseOrder.amount;
                }
            });
    });

    Object.values(departmentCounts)
        .filter((department) => department.value)
        .sort((a, b) => a.value - b.value)
        .forEach((department) => {
            const displayName = department.name;

            series.push({
                data: [department.value],
                description: `${displayName} Contracts`,
                cursor: 'pointer',
                events: {
                    click: () => goToContracts(govId, department.contractIds, router),
                },
                label: currencyFormatter(department, { use0FractionDigits: true }),
                name: `${displayName}`,
            });
        });

    return series;
};

export const getContractsByVendorCertsData = (contracts, certifications, router) => {
    const govId = contracts.length > 0 && contracts[0].government_id;
    const certData = {};

    certifications.forEach((cert, idx) => {
        const color = chartColors[idx];

        certData[cert.id] = {
            color,
            ids: [],
            name: cert.title,
        };
    });

    contracts.forEach((contract) => {
        const vendor = contract.contractParty && contract.contractParty.vendor;

        if (vendor) {
            // vendors can self-report the same certification multiple times, so we need to dedupe
            new Set(
                vendor.vendorCertifications.map(
                    (vendorCertification) => vendorCertification.certification_id
                )
            ).forEach((cert) => {
                const targetCert = certData[cert];
                return targetCert && targetCert.ids.push(contract.id);
            });
        }
    });

    const certs = Object.values(certData);

    return {
        categories: certs.map((cert) => cert.name),
        series: [
            {
                cursor: 'pointer',
                name: 'Number of Contracts',
                data: certs.map((cert) => {
                    const { color, ids, name } = cert;
                    return {
                        y: ids.length,
                        name,
                        color,
                        events: {
                            click: () => goToContracts(govId, ids, router),
                        },
                    };
                }),
            },
        ],
    };
};

export const getContractsByContractTagsData = (contracts, tags, router, tagField = 'tags') => {
    const govId = contracts.length > 0 && contracts[0].government_id;
    const tagData = {};

    tags.forEach((tag, idx) => {
        const color =
            idx < chartColors.length ? chartColors[idx] : chartColors[chartColors.length - idx];

        tagData[tag.id] = {
            name: tag.name,
            ids: [],
            color,
        };
    });

    contracts.forEach((contract) => {
        contract[tagField].forEach((tag) => {
            tagData[tag.id].ids.push(contract.id);
        });
    });

    const tagValues = Object.values(tagData).filter((value) => value.ids.length > 0);

    return {
        categories: tagValues.map((value) => value.name),
        series: [
            {
                cursor: 'pointer',
                name: 'Number of Contracts',
                data: tagValues.map((value) => {
                    const { color, ids, name } = value;
                    return {
                        y: ids.length,
                        name,
                        color,
                        events: {
                            click: () => goToContracts(govId, ids, router),
                        },
                    };
                }),
            },
        ],
    };
};

export const getContractsByProcurementTagsData = (contracts, tags, router) => {
    const govId = contracts.length > 0 && contracts[0].government_id;
    const tagData = {};
    const data = [];

    tags.forEach((tag) => {
        tagData[tag.id] = {
            name: tag.name,
            ids: [],
        };
    });

    contracts.forEach((contract) => {
        const { id, procurement_tag_id } = contract;

        if (procurement_tag_id && tagData[procurement_tag_id]) {
            tagData[procurement_tag_id].ids.push(id);
        }
    });

    Object.values(tagData)
        .sort((a, b) => a.ids.length - b.ids.length)
        .forEach((tag) => {
            const { name, ids } = tag;

            data.push({
                y: ids.length,
                description: `${name} Contracts`,
                cursor: 'pointer',
                events: {
                    click: () => goToContracts(govId, ids, router),
                },
                label: ids.length,
                name,
            });
        });

    return data.filter((datum) => datum.y > 0);
};

import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { positionTypes } from '@og-pro/shared-config/positions';

import { fieldNames, fields } from './constants';
import { GroupPositionForm } from './GroupPositionForm';
import { showSnackbar } from '../../../../../actions/notification';
import { createPosition, updatePosition } from '../../../../../actions/requisitionsAdmin';

const { TYPE } = fieldNames;
const { GROUP } = positionTypes;

export const GroupPositionsModal = ({ groupPosition, hideModal }) => {
    const [updating, setUpdating] = useState(false);
    const [updateError, setUpdateError] = useState(null);

    const isNew = !groupPosition;

    const dispatch = useDispatch();

    const onSubmit = (formValues) => {
        setUpdating(true);
        setUpdateError(null);

        const submitGroupPositionAction = isNew
            ? dispatch(createPosition(formValues))
            : dispatch(updatePosition(groupPosition.id, formValues));

        return submitGroupPositionAction
            .then(() => {
                hideModal();
                dispatch(showSnackbar(`Group position ${isNew ? 'added' : 'updated'}`));
            })
            .catch((error) => {
                setUpdating(false);
                setUpdateError(error.message);
            });
    };

    return (
        <Modal onHide={hideModal} show>
            <Modal.Header closeButton>
                <Modal.Title className="text-center">
                    {isNew ? 'Create' : 'Edit'} Group Position
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-8 col-md-offset-2">
                        <GroupPositionForm
                            initialValues={{
                                [TYPE]: GROUP,
                                ...pick(groupPosition, fields),
                            }}
                            isNew={isNew}
                            onCancel={hideModal}
                            onSubmit={onSubmit}
                            updateError={updateError}
                            updating={updating}
                        />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

GroupPositionsModal.propTypes = {
    groupPosition: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }),
    hideModal: PropTypes.func.isRequired,
};

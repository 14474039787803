import { createSelector } from 'reselect';
import { get } from 'lodash';

import { categoryNames, categorySetsArray } from '@og-pro/shared-config/categories';
import { vendorUploadsList } from '@og-pro/shared-config/vendorUploads';

const getVendorAttachments = (props) => props.vendor.attachments || [];
const getVendorCategories = (props) => props.vendor.categories || [];

const getVendorAttachmentsMap = createSelector([getVendorAttachments], (attachments) => {
    return attachments.reduce((obj, attachment) => {
        const uploadType = get(attachment, 'vendorAttachments.vendor_upload_type');
        if (!obj[uploadType]) {
            obj[uploadType] = [];
        }
        obj[uploadType].push(attachment);
        return obj;
    }, {});
});

export const getVendorUploads = createSelector([getVendorAttachmentsMap], (attachmentsMap) => {
    return vendorUploadsList.map((vendorUpload) => {
        return {
            ...vendorUpload,
            attachments: attachmentsMap[vendorUpload.type],
        };
    });
});

export const getCategoryGroups = createSelector([getVendorCategories], (categories) => {
    return categorySetsArray.map((setId) => {
        return {
            title: `${categoryNames[setId]} Codes`,
            categories: categories.filter((category) => category.setId === setId),
        };
    });
});

import { buildQaTag, componentNames } from '@og-pro/ui';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'react-bootstrap';

export class RadioItem extends PureComponent {
    static propTypes = {
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
        children: PropTypes.node.isRequired,
        groupLabel: PropTypes.string.isRequired,
        hasTextField: PropTypes.bool,
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        qaTag: PropTypes.string.isRequired, // Format: <pageName>-<simpleDescription>
        textFieldDisabled: PropTypes.bool,
    };

    onChange = () => {
        const { onChange, value } = this.props;
        return onChange(value);
    };

    render() {
        const {
            value,
            children,
            groupLabel,
            hasTextField,
            name,
            onChange,
            qaTag,
            textFieldDisabled,
            ...props
        } = this.props;
        const styles = require('./RadioGroup.scss');

        const tagWithComponentName = buildQaTag(qaTag, componentNames.RADIO_ITEM);

        return (
            <Radio
                aria-label={`${name.toLowerCase()} button`}
                className={styles.radio}
                data-qa={tagWithComponentName}
                onChange={this.onChange}
                value={value}
                {...props}
            >
                {children}
            </Radio>
        );
    }
}

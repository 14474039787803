import React, { useContext } from 'react';

import {
    BaseSectionsNavNextButton,
    BaseSectionsNavBackNextButtons,
} from '../../../../../../../components/SDv2/ProjectSectionsNav';
import {
    NavScrollContainer,
    SectionHeaderViewTitleOnly,
} from '../../../../../../../components/SDv2';
import { TemplateProcessInformationNav } from '../../components/ProcessInformationNav';
import { SDv2EditorLayout } from '../../../../../../../components/SDv2/Layout';
import { PROCESS_INFORMATION_SECTIONS_TITLES } from '../../../constants';
import { TemplateEditV2NavContext, TemplateEditV2FunctionsContext } from '../../../context';
import { TemplateEditV2DocumentExportSettingsForm } from '../../components/forms/DocumentExportSettings';
import { TemplateEditV2GeneralPropertiesForm } from '../../components/forms/GeneralProperties';
import { TemplateEditV2SealedBidSettingsForm } from '../../components/forms/SealedBidSettings';
import { TemplateEditV2SummaryAndBackgroundForm } from '../../components/forms/SummaryAndBackground';
import { TemplateEditV2TimelineForm } from '../../components/forms/Timeline';
import { getProcessInformationSections } from '../../../helpers';

export const TemplateEditV2ProcessInformation = () => {
    const { activeSectionId, setActiveSectionId } = useContext(TemplateEditV2NavContext);
    const { templateProject, updateTemplateIfDirty, buildRouteFunction, router } = useContext(
        TemplateEditV2FunctionsContext
    );

    const sections = getProcessInformationSections({
        templateProject,
        setActiveSectionId: (i) => {
            updateTemplateIfDirty();
            setActiveSectionId(i);
        },
        activeSectionId,
    });

    return (
        <SDv2EditorLayout
            content={
                [
                    <TemplateEditV2GeneralPropertiesForm />,
                    <TemplateEditV2SummaryAndBackgroundForm />,
                    <TemplateEditV2TimelineForm />,
                    <TemplateEditV2DocumentExportSettingsForm />,
                    <TemplateEditV2SealedBidSettingsForm />,
                ][activeSectionId]
            }
            footer={
                <BaseSectionsNavBackNextButtons
                    lastSectionComponent={
                        <BaseSectionsNavNextButton
                            onClick={() => {
                                updateTemplateIfDirty();
                                router.push(buildRouteFunction('document-builder'));
                            }}
                        >
                            Document Builder
                        </BaseSectionsNavNextButton>
                    }
                    sections={sections}
                />
            }
            header={
                <SectionHeaderViewTitleOnly
                    description={PROCESS_INFORMATION_SECTIONS_TITLES[activeSectionId]?.description}
                    title={PROCESS_INFORMATION_SECTIONS_TITLES[activeSectionId]?.title}
                />
            }
            navigation={
                <NavScrollContainer>
                    <TemplateProcessInformationNav />
                </NavScrollContainer>
            }
            showSharedTextareaToolbar={activeSectionId === 1}
        />
    );
};

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { createSearchParams } from 'react-router-dom';

import { fieldNames, createForm, navItems } from './constants';
import { getSelectedDocumentNavItem, getSelectedStandardDocuments } from './selectors';
import { StandardDocumentForm } from './StandardDocumentForm';
import { StandardDocumentItem } from './StandardDocumentItem';
import connectData from '../../ConnectData';
import { getUserJS } from '../../selectors';
import {
    createStandardDocument,
    deleteStandardDocument,
    loadStandardDocuments,
    resetStandardDocuments,
    updateStandardDocument,
} from '../../../actions/adminGovernment';
import { showConfirmationSimpleModal } from '../../../actions/confirmation';
import {
    Button,
    LinkContainer,
    LoadingError,
    LoadingSpinner,
    Main,
    Nav,
    NavItem,
    PageTitle,
    SectionTitle,
    Well,
    ZeroState,
} from '../../../components';

const { TITLE, TYPE } = fieldNames;

function fetchData(getState, dispatch, location, params) {
    if (!getState().adminGovernment.get('loadedStandardDocuments')) {
        const governmentId = Number.parseInt(params.governmentId, 10);
        return dispatch(loadStandardDocuments(governmentId));
    }
}

const mapStateToProps = (state, props) => {
    return {
        loadError: state.adminGovernment.get('loadStandardDocumentsError'),
        loading: state.adminGovernment.get('loadingStandardDocuments'),
        selectedNavItem: getSelectedDocumentNavItem(state, props),
        standardDocuments: getSelectedStandardDocuments(state, props),
        user: getUserJS(state),
    };
};

const mapDispatchToProps = {
    createStandardDocument,
    deleteStandardDocument,
    loadStandardDocuments,
    resetStandardDocuments,
    showConfirmationSimpleModal,
    updateStandardDocument,
};

// @connect
class ConnectedStandardDocuments extends Component {
    static propTypes = {
        createStandardDocument: PropTypes.func.isRequired,
        deleteStandardDocument: PropTypes.func.isRequired,
        loadStandardDocuments: PropTypes.func.isRequired,
        loadError: PropTypes.string,
        loading: PropTypes.bool,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
        }).isRequired,
        resetStandardDocuments: PropTypes.func.isRequired,
        selectedNavItem: PropTypes.shape({
            defaultTitle: PropTypes.string,
            hasAttachmentOption: PropTypes.bool,
            isSingleDocument: PropTypes.bool,
            info: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            templateVariableOptions: PropTypes.array,
            type: PropTypes.string.isRequired,
        }).isRequired,
        standardDocuments: PropTypes.array.isRequired,
        showConfirmationSimpleModal: PropTypes.func.isRequired,
        updateStandardDocument: PropTypes.func.isRequired,
        user: PropTypes.shape({
            government_id: PropTypes.number.isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);

        this.state = this.initialState;
    }

    UNSAFE_componentWillUpdate(nextProps) {
        if (nextProps.selectedNavItem !== this.props.selectedNavItem) {
            this.setState(this.initialState); // eslint-disable-line react/no-will-update-set-state
        }
    }

    componentWillUnmount() {
        this.props.resetStandardDocuments();
    }

    get styles() {
        return require('./index.scss');
    }

    initialState = {
        createError: null,
        creating: false,
        showForm: false,
    };

    createStandardDocument = (formData) => {
        const {
            selectedNavItem: { type },
        } = this.props;

        this.setState({
            createError: null,
            creating: true,
        });

        const data = {
            ...formData,
            type,
        };

        this.props
            .createStandardDocument(data)
            .then(() => {
                this.setState({
                    creating: false,
                    showForm: false,
                });
                this.loadStandardDocuments();
                this.props.showConfirmationSimpleModal(() => {}, {
                    btnText: 'Okay',
                    bsStyle: 'primary',
                    icon: 'check',
                    hideCancelButton: true,
                    text: `"${data.title}" is now the active document.`,
                    title: 'Active Document Changed',
                });
            })
            .catch((error) => {
                this.setState({
                    createError: error.message,
                    creating: false,
                });
            });
    };

    loadStandardDocuments = () => {
        const { user } = this.props;

        this.props.loadStandardDocuments(user.government_id);
    };

    showForm = () => this.setState({ showForm: true });

    updateStandardDocument = (standardDocumentId, formData) => {
        return this.props.updateStandardDocument(standardDocumentId, formData);
    };

    renderNav() {
        const {
            location: { pathname },
            selectedNavItem,
        } = this.props;

        return (
            <div className={this.styles.documentsNav}>
                <Nav bsStyle="pills" stacked>
                    {navItems.map(({ name, type }, index) => (
                        <LinkContainer
                            className={classnames({ active: type === selectedNavItem.type })}
                            key={index}
                            to={{ pathname, search: createSearchParams({ type }).toString() }}
                        >
                            <NavItem qaTag={`admin-${name}`}>{name}</NavItem>
                        </LinkContainer>
                    ))}
                </Nav>
            </div>
        );
    }

    renderDocumentsList() {
        const {
            selectedNavItem: {
                info,
                hasAttachmentOption,
                isSingleDocument,
                name,
                templateVariableOptions,
            },
            standardDocuments,
            user,
        } = this.props;

        const { showForm } = this.state;

        const documentItems = standardDocuments.map((standardDocument) => (
            <ListGroupItem key={standardDocument.id}>
                <StandardDocumentItem
                    deleteStandardDocument={this.props.deleteStandardDocument}
                    governmentId={user.government_id}
                    hasAttachmentOption={hasAttachmentOption}
                    isSingleDocument={isSingleDocument}
                    loadStandardDocuments={this.loadStandardDocuments}
                    showConfirmationSimpleModal={this.props.showConfirmationSimpleModal}
                    standardDocument={standardDocument}
                    templateVariableOptions={templateVariableOptions}
                    updateStandardDocument={this.updateStandardDocument}
                />
            </ListGroupItem>
        ));

        return (
            <div>
                <SectionTitle info={info} title={name} />
                {showForm ? (
                    this.renderCreateForm()
                ) : (
                    <div className={this.styles.createButton}>
                        <Button bsStyle="success" onClick={this.showForm}>
                            <i className="fa fa-plus" /> New Document
                        </Button>
                    </div>
                )}
                <ListGroup>{documentItems}</ListGroup>
            </div>
        );
    }

    renderCreateForm() {
        const { selectedNavItem, user } = this.props;

        const { createError, creating } = this.state;

        return (
            <Well>
                <StandardDocumentForm
                    closeForm={() => this.setState({ showForm: false })}
                    disabled={creating}
                    form={createForm}
                    hasAttachmentOption={selectedNavItem.hasAttachmentOption}
                    initialValues={{
                        [TITLE]: selectedNavItem.defaultTitle,
                        [TYPE]: selectedNavItem.type,
                    }}
                    onSubmit={this.createStandardDocument}
                    s3Url={`/government/${user.government_id}/s3`}
                    serverError={createError}
                    templateVariableOptions={selectedNavItem.templateVariableOptions}
                />
            </Well>
        );
    }

    renderZeroState() {
        const { showForm } = this.state;

        if (showForm) {
            return this.renderCreateForm();
        }

        return (
            <ZeroState
                buttonClickHandler={this.showForm}
                buttonText={
                    <span>
                        <i className="fa fa-plus" /> Create Document
                    </span>
                }
                title="No Documents Have Been Created Yet"
            />
        );
    }

    render() {
        const { loadError, loading, standardDocuments } = this.props;

        if (loading) {
            return <LoadingSpinner />;
        }

        if (loadError) {
            return <LoadingError error={loadError} />;
        }

        return (
            <Main className="row">
                <PageTitle title="Documents" />
                <div className="col-sm-3">{this.renderNav()}</div>
                <div className="col-sm-9">
                    {standardDocuments.length === 0
                        ? this.renderZeroState()
                        : this.renderDocumentsList()}
                </div>
            </Main>
        );
    }
}

export const StandardDocuments = compose(
    connectData(fetchData),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedStandardDocuments);

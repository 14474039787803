import React, { useState, useEffect } from 'react';
import { isBoolean } from 'lodash';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { Box } from '@og-pro/ui';

import { loadAllCertifications } from '../../actions/adminVendor';
import { InputText, CDSDropdownButton, MenuItem } from '..';

const DefaultLabel = ({ count, text }) => {
    const styles = require('./index.scss');

    return (
        <>
            {text || 'Certifications'}
            <Box className={styles.filtersCount} component="span" ml={1}>
                {count}
            </Box>
        </>
    );
};
DefaultLabel.propTypes = { count: PropTypes.number.isRequired, text: PropTypes.string };

export const CertificationsDropdownSelect = ({
    className,
    closeOnSelect = false,
    defaultOpen = null,
    dropdownButtonProps = {},
    getMenuItemLabel = null,
    itemSelectedIcon = null,
    itemUnSelectedIcon = null,
    label,
    onChange,
    selected,
    showFilterInput = false,
}) => {
    // the controlled open/closed state is so that we can forcefully open it from outside
    // and avoid the menu from closing on select
    const [open, setOpen] = useState(false);
    const [filteringString, setFilteringString] = useState('');
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.adminVendor.get('loadingCertifications'));
    const certifications = useSelector((state) =>
        state.adminVendor.get('vendorCertifications')?.toJS()
    );

    useEffect(() => {
        if (!loading && !certifications?.length) {
            dispatch(loadAllCertifications());
        }
    }, [loading, certifications, dispatch]);

    useEffect(() => {
        if (isBoolean(defaultOpen)) {
            setOpen(defaultOpen);
        }
    }, [defaultOpen]);

    const onClick = (certification) => {
        const certificationIds = selected.includes(certification.id)
            ? selected.filter((id) => id !== certification.id)
            : [...selected, certification.id];
        onChange(certificationIds);
    };

    const styles = require('./index.scss');

    const filteredCertifications = filteringString
        ? certifications.filter((cert) => new RegExp(filteringString, 'i').test(cert.title))
        : certifications;
    return (
        <Box className={classnames(styles.container, className)}>
            <Box className={styles.dropdown} onClick={(e) => e.stopPropagation()}>
                <CDSDropdownButton
                    onToggle={(isOpen, event, { source }) => {
                        if (source === 'select' && open && !closeOnSelect) {
                            return;
                        }

                        if (!isOpen) {
                            setFilteringString('');
                        }

                        setOpen(isOpen);
                    }}
                    open={open}
                    qaTag="certificationsDropdownSelect"
                    size="small"
                    title={<DefaultLabel count={selected.length} text={label} />}
                    variant="secondary"
                    {...dropdownButtonProps}
                >
                    {showFilterInput && (
                        <MenuItem>
                            <Box className={styles.filterInput} py={1}>
                                <InputText
                                    input={{
                                        value: filteringString,
                                        onChange: (e) => {
                                            setFilteringString(e.target.value);
                                        },
                                        onBlur: () => {},
                                    }}
                                    meta={{}}
                                    placeholder="Search certifications"
                                    qaTag="certificationsDropdownSelect-filter"
                                    size="small"
                                    useOpenGovStyle
                                />
                            </Box>
                        </MenuItem>
                    )}

                    {filteredCertifications.map((certification) => {
                        return (
                            <MenuItem
                                className="certification-dropdown-menuitem"
                                key={certification.id}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    onClick(certification);
                                }}
                                qaTag={`certificationsDropdownSelect-certification${certification.id}`}
                            >
                                <Box
                                    className="certification-dropdown-menuitem-container"
                                    display="flex"
                                    py={1}
                                >
                                    <Box className="certification-dropdown-menuitem-icon" pr={2}>
                                        {selected.includes(certification.id)
                                            ? itemSelectedIcon || <i className="fa fa-check" />
                                            : itemUnSelectedIcon || (
                                                  <i
                                                      className={classnames(
                                                          'fa fa-check',
                                                          styles.hidden
                                                      )}
                                                  />
                                              )}
                                    </Box>
                                    <Box className="certification-dropdown-menuitem-label">
                                        {getMenuItemLabel
                                            ? getMenuItemLabel(certification)
                                            : certification.title}
                                    </Box>
                                </Box>
                            </MenuItem>
                        );
                    })}
                </CDSDropdownButton>
            </Box>
        </Box>
    );
};

CertificationsDropdownSelect.propTypes = {
    className: PropTypes.string,
    closeOnSelect: PropTypes.bool,
    defaultOpen: PropTypes.bool,
    dropdownButtonProps: PropTypes.object,
    getMenuItemLabel: PropTypes.func,
    itemSelectedIcon: PropTypes.node,
    itemUnSelectedIcon: PropTypes.node,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    selected: PropTypes.arrayOf(PropTypes.number),
    showFilterInput: PropTypes.bool,
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { every } from 'lodash';

import { AttachmentDropzoneFileForm } from './AttachmentDropzoneFileForm';
import { AttachmentFileItemHeader } from './AttachmentFileItemHeader';
import { AttachmentDropzoneMultiFileForm } from './AttachmentDropzoneMultiFileForm';

/**
 * Used for uploading attachments anywhere in the app.
 * Renders a drop area for dragging attachments and displays an a form for completing upload to s3
 */
export class AttachmentDropzoneFiles extends PureComponent {
    static propTypes = {
        fields: PropTypes.object.isRequired,
        formKey: PropTypes.string,
        uploadFile: PropTypes.func.isRequired,
        availableAppendices: PropTypes.array.isRequired,
        disabled: PropTypes.bool,
        hideAppendixLetter: PropTypes.bool,
        handleSubmit: PropTypes.func.isRequired,
        isOGThemeEnabledForComponents: PropTypes.bool,
    };

    componentDidUpdate() {
        const { fields } = this.props;

        /**
         * Remove all form items when all uploads have completed. Form items need to be removed
         * here as opposed to individually because individual removal changes the index number,
         * which effects the form item that will be updated.
         */
        if (fields.length > 0 && every(fields.getAll(), 'done')) {
            fields.removeAll();
        }
    }

    render() {
        const {
            fields,
            formKey,
            uploadFile,
            handleSubmit,
            availableAppendices,
            hideAppendixLetter,
            isOGThemeEnabledForComponents,
            disabled,
        } = this.props;

        if (isOGThemeEnabledForComponents) {
            const filteredAttachments = fields
                .map((member, index) => {
                    const attachment = fields.get(index);

                    // Only select the attachments of the specified formKey
                    if (formKey && attachment.formKey !== formKey) {
                        return null;
                    }

                    return { attachment, member, index };
                })
                .filter((item) => !!item);

            if (!filteredAttachments.length) {
                return null;
            }

            return (
                <AttachmentDropzoneMultiFileForm
                    appendixOptions={availableAppendices}
                    attachments={filteredAttachments}
                    disabled={disabled}
                    handleSubmit={handleSubmit}
                    hideAppendixLetter={hideAppendixLetter}
                    isOGThemeEnabledForComponents={isOGThemeEnabledForComponents}
                    removeAll={fields.removeAll}
                    removeFile={fields.remove}
                    uploadFile={uploadFile}
                />
            );
        }

        let hasFileItems = false;
        const FileItems = fields.map((member, index) => {
            const attachment = fields.get(index);

            // Only select the attachments of the specified formKey
            if (formKey && attachment.formKey !== formKey) {
                return null;
            }

            hasFileItems = true;
            return (
                <AttachmentDropzoneFileForm
                    appendixOptions={availableAppendices}
                    arrayName={member}
                    attachment={attachment}
                    disabled={disabled}
                    handleSubmit={handleSubmit}
                    hideAppendixLetter={hideAppendixLetter}
                    index={index}
                    isOGThemeEnabledForComponents={isOGThemeEnabledForComponents}
                    key={index}
                    removeFile={fields.remove}
                    uploadFile={uploadFile}
                />
            );
        });

        if (!hasFileItems) {
            return null;
        }

        const styles = require('./AttachmentDropzone.scss');
        return (
            <div className={styles.filesToUpload}>
                <AttachmentFileItemHeader hideAppendixLetter={hideAppendixLetter} />
                {FileItems}
            </div>
        );
    }
}

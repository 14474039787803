import PropTypes from 'prop-types';
import classnames from 'classnames';
import React, { Component } from 'react';
import { ListGroup, Panel } from 'react-bootstrap';

export default class ProjectPanel extends Component {
    static propTypes = {
        eventKey: PropTypes.number.isRequired,
        listItems: PropTypes.array.isRequired,
        moduleEnabled: PropTypes.bool.isRequired,
        type: PropTypes.string.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    renderIcon = (numProjects) => {
        if (numProjects >= 3) {
            return (
                <i
                    className={classnames(
                        'fa fa-exclamation-triangle',
                        this.styles.panelIcon,
                        this.styles.danger
                    )}
                />
            );
        }
        if (numProjects > 0 && numProjects < 3) {
            return (
                <i
                    className={classnames(
                        'fa fa-exclamation-circle',
                        this.styles.panelIcon,
                        this.styles.warning
                    )}
                />
            );
        }
        return (
            <i
                className={classnames(
                    'fa fa-check-circle',
                    this.styles.panelIcon,
                    this.styles.success
                )}
            />
        );
    };

    renderHeader = (numProjects) => {
        const { moduleEnabled, type } = this.props;

        if (!moduleEnabled) {
            return (
                <Panel.Heading className={classnames(this.styles.panelHeader)}>
                    <Panel.Title componentClass="h3">
                        <i
                            className={classnames(
                                'fa fa-lock',
                                this.styles.panelIcon,
                                this.styles.lock
                            )}
                        />
                        {`${type}`}
                        <span className={this.styles.disabledModule}>Module not enabled</span>
                    </Panel.Title>
                </Panel.Heading>
            );
        }

        return (
            <Panel.Heading
                className={classnames(
                    this.styles.panelHeader,
                    !numProjects && this.styles.noProjects
                )}
                role="button"
            >
                <Panel.Title componentClass="h3">
                    {this.renderIcon(numProjects)}
                    {`${type}: ${numProjects}${numProjects === 1 ? ' Project' : ' Projects'}`}
                </Panel.Title>
            </Panel.Heading>
        );
    };

    render() {
        const { eventKey, listItems } = this.props;

        const numProjects = listItems.length;

        return (
            <Panel eventKey={eventKey} role="tab">
                <Panel.Toggle className={!numProjects && this.styles.disabled}>
                    {this.renderHeader(numProjects)}
                </Panel.Toggle>
                <Panel.Body className={this.styles.panelBody} collapsible>
                    <ListGroup>{listItems}</ListGroup>
                </Panel.Body>
            </Panel>
        );
    }
}

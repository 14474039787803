/* eslint no-use-before-define: ["error", { "functions": false }] */

// NOTE: every callback should dispatch at least a single action describing what happened
// NOTE: if your callback calls another function which _could_ return something (such as a Promise),
// don't forget to return the call to it so that our promise chain isn't broken.
// NOTE: if you create a callback that calls another callback, you're probably doing something that
// doesn't match the pattern of how we use actions. Do it in the reducer via the action.

import {
    hideConfirmationModal,
    showConfirmationModal,
    showConfirmationModalError,
    updatingConfirmationModal,
} from './confirmation';
import { showSnackbar } from './notification';
import { REASSIGN_TAG } from '../constants/menuActions';
import { resourceManager } from '../helpers';

export const CREATE = 'tags/CREATE';
export const CREATE_FAIL = 'tags/CREATE_FAIL';
export const CREATE_SUCCESS = 'tags/CREATE_SUCCESS';
export const DELETE = 'tags/DELETE';
export const DELETE_FAIL = 'tags/DELETE_FAIL';
export const DELETE_SUCCESS = 'tags/DELETE_SUCCESS';
export const LOAD_TAGS = 'tags/LOAD_TAGS';
export const LOAD_TAGS_FAIL = 'tags/LOAD_TAGS_FAIL';
export const LOAD_TAGS_SUCCESS = 'tags/LOAD_TAGS_SUCCESS';
export const SWAP = 'tags/SWAP';
export const SWAP_FAIL = 'tags/SWAP_FAIL';
export const SWAP_SUCCESS = 'tags/SWAP_SUCCESS';
export const UPDATE = 'tags/UPDATE';
export const UPDATE_FAIL = 'tags/UPDATE_FAIL';
export const UPDATE_SUCCESS = 'tags/UPDATE_SUCCESS';

const generateCreateTagOptions = ({ context }) => {
    return { data: context.tagData };
};

const generateDeleteTagOptions = ({ context }) => {
    if (!context.replacementTagId) return {};
    return { data: { replacementTagId: context.replacementTagId } };
};

const generateLoadTagsOptions = ({ context }) => {
    return { data: { governmentCode: context.governmentCode } };
};

const generateSwapTagsOptions = ({ context }) => {
    return { data: { tagAId: context.tagAId, tagBId: context.tagBId } };
};

const generateUpdateTagOptions = ({ context }) => {
    return { data: context.tagData };
};

const onCreateTagFailure = ({ error, dispatch }) => {
    dispatch({ type: CREATE_FAIL, error });
};

const onCreateTagStart = ({ dispatch }) => {
    dispatch({ type: CREATE });
};

const onCreateTagSuccess = ({ context, result, dispatch }) => {
    dispatch({ type: CREATE_SUCCESS, result });
    dispatch(showSnackbar('Tag Added'));
    if (context.onSuccess) {
        context.onSuccess(result);
    }
};

const onDeleteTagFailure = ({ context, error, dispatch }) => {
    if (context.modal) {
        dispatch(showConfirmationModalError(error.message));
    }
    dispatch({ type: DELETE_FAIL, error });
};

const onDeleteTagStart = ({ context, dispatch }) => {
    if (context.modal) {
        dispatch(updatingConfirmationModal());
    }
    dispatch({ type: DELETE });
};

const onDeleteTagSuccess = ({ context, result, dispatch }) => {
    if (result.replacementTags) {
        dispatch({ type: DELETE_FAIL, error: new Error('Tag is in use. Reassignment required') });
        return dispatch(
            showConfirmationModal(REASSIGN_TAG, {
                replacementTags: result.replacementTags,
                tagId: context.tagId,
            })
        );
    }
    if (context.modal) {
        dispatch(hideConfirmationModal());
    }
    dispatch(showSnackbar('Tag deleted'));
    dispatch({ type: DELETE_SUCCESS, result });
};

const onLoadTagsFailure = ({ error, dispatch }) => {
    dispatch({ type: LOAD_TAGS_FAIL, error });
};

const onLoadTagsStart = ({ dispatch }) => {
    dispatch({ type: LOAD_TAGS });
};

const onLoadTagsSuccess = ({ context, result, dispatch }) => {
    dispatch({
        type: LOAD_TAGS_SUCCESS,
        count: result.count,
        governmentCode: context.governmentCode,
        governmentId: context.governmentId,
        result: result.tags,
    });
};

const onSwapTagsFailure = ({ error, dispatch }) => {
    dispatch({ type: SWAP_FAIL, error });
};

const onSwapTagsStart = ({ dispatch }) => {
    dispatch({ type: SWAP });
};

const onSwapTagsSuccess = ({ result, dispatch }) => {
    dispatch({ type: SWAP_SUCCESS, result });
};

const onUpdateTagFailure = ({ error, dispatch }) => {
    dispatch({ type: UPDATE_FAIL, error });
};

const onUpdateTagStart = ({ dispatch }) => {
    dispatch({ type: UPDATE });
};

const onUpdateTagSuccess = ({ result, dispatch }) => {
    dispatch(showSnackbar('Tag updated'));
    dispatch({ type: UPDATE_SUCCESS, result });
};

export function createTag(tagData, onSuccess) {
    return resourceManager({
        method: 'post',
        url: '/tags',
        requestOptions: generateCreateTagOptions,
        onStart: onCreateTagStart,
        onSuccess: onCreateTagSuccess,
        onFailure: onCreateTagFailure,
        context: { tagData, onSuccess },
    });
}

export function deleteTag(tagId, replacementTagId, options = {}) {
    return resourceManager({
        method: 'del',
        url: `/tags/${tagId}`,
        requestOptions: generateDeleteTagOptions,
        onStart: onDeleteTagStart,
        onSuccess: onDeleteTagSuccess,
        onFailure: onDeleteTagFailure,
        context: { ...options, replacementTagId, tagId },
    });
}

export function loadTags(options) {
    return resourceManager({
        method: 'post',
        url: '/tags/list',
        requestOptions: generateLoadTagsOptions,
        onStart: onLoadTagsStart,
        onSuccess: onLoadTagsSuccess,
        onFailure: onLoadTagsFailure,
        context: options,
    });
}

export function swapTags(tagAId, tagBId) {
    return resourceManager({
        method: 'post',
        url: '/tags/swap',
        requestOptions: generateSwapTagsOptions,
        onStart: onSwapTagsStart,
        onSuccess: onSwapTagsSuccess,
        onFailure: onSwapTagsFailure,
        context: { tagAId, tagBId },
    });
}

export function updateTag(tagId, tagData) {
    return resourceManager({
        method: 'put',
        url: `/tags/${tagId}`,
        requestOptions: generateUpdateTagOptions,
        onStart: onUpdateTagStart,
        onSuccess: onUpdateTagSuccess,
        onFailure: onUpdateTagFailure,
        context: { tagData },
    });
}

import { fieldNames } from './constants';

const { DESCRIPTION, NAME } = fieldNames;

export const validate = (values) => {
    const errors = {};

    if (!values[NAME]) {
        errors[NAME] = 'Please specify a flag name';
    } else if (values[NAME].length > 64) {
        errors[NAME] = 'Name is too long';
    }

    if (!values[DESCRIPTION]) {
        errors[DESCRIPTION] = 'Please specify a description';
    } else if (values[DESCRIPTION].length > 512) {
        errors[DESCRIPTION] = 'Description is too long';
    }

    return errors;
};

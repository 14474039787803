export const hasQuestionLogicOptions = [
    {
        label: 'Always',
        value: false,
    },
    {
        label: 'Conditionally',
        value: true,
    },
];

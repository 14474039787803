import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getPriceItemsSummary } from '@og-pro/shared-config/priceTables/requisitionUtils';

import { currencyFormatter } from '../../../../../../../helpers';
import { fieldNames } from '../../../constants';
import { requisitionsCreateFormValueSelector } from '../../../selectors';
import { getGovernmentSalesTax } from '../../../../../../../selectors/govApp';

export const PurchaseDetailsSummary = () => {
    const styles = require('./index.scss');

    const { PRICE_ITEMS, PRICE_TABLE } = fieldNames;

    const priceItems = useSelector((state) =>
        requisitionsCreateFormValueSelector(state, `${PRICE_TABLE}.${PRICE_ITEMS}`)
    );
    const tax = useSelector(getGovernmentSalesTax);

    const { subTotal, taxes, total } = useMemo(
        () => getPriceItemsSummary(priceItems, tax),
        [priceItems, tax]
    );

    return (
        <div className={styles.summaryContainer}>
            <div className={styles.summaryItems}>
                <div className={styles.summaryLine}>
                    <span className={styles.summaryText}>Sub Total</span>
                    <span className={classNames(styles.summaryText, styles.secondaryText)}>
                        {currencyFormatter({ value: subTotal })}
                    </span>
                </div>
                {tax && (
                    <div className={styles.summaryLine}>
                        <span className={styles.summaryText}>Tax ({tax}%)</span>
                        <span className={classNames(styles.summaryText, styles.secondaryText)}>
                            {/* Only Taxes should display as `N/A` if result is 0 */}
                            {taxes ? currencyFormatter({ value: taxes }) : 'N/A'}
                        </span>
                    </div>
                )}
            </div>
            <hr />
            <div>
                <div className={classNames(styles.summaryLine, styles.grandTotal)}>
                    <h3 className={styles.grandTotalLabel}>Grand Total</h3>
                    <h3 className={styles.grandTotalAmount}>
                        {currencyFormatter({ value: total })}
                    </h3>
                </div>
            </div>
        </div>
    );
};

import { capitalDesignTokens } from '@opengov/capital-mui-theme';

const font = {
    fontFamily: capitalDesignTokens.foundations.typography.baseFont,
};

const nonPrimaryStyle = {
    ...font,
    color: capitalDesignTokens.semanticColors.foreground.secondary,
    fontSize: capitalDesignTokens.foundations.typography.fontSize.bodySmall,
};

export const secondaryStyle = {
    ...nonPrimaryStyle,
    float: 'right',
    fontFamily: capitalDesignTokens.foundations.typography.baseFontCondensed,
};

export const labelStyle = {
    ...font,
    fontSize: capitalDesignTokens.foundations.typography.fontSize.bodyDefault,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
};

export const tertiaryStyle = {
    ...nonPrimaryStyle,
};

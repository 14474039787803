import React from 'react';
import { Field, reduxForm } from 'redux-form';

import { form, SEARCH } from './constants';
import { InputText } from '../../../../components';

const formConfig = {
    form,
};

// @reduxForm
const ConnectedDepartmentSearchForm = () => {
    return (
        <Field
            aria-label="Search departments"
            component={InputText}
            name={SEARCH}
            overrideFeedback
            placeholder="Search departments"
            qaTag="departmentList-search"
            type="text"
        />
    );
};

export const DepartmentSearchForm = reduxForm(formConfig)(ConnectedDepartmentSearchForm);

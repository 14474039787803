import React from 'react';
import { Box } from '@og-pro/ui';
import { NavBar } from '@opengov/components-nav-bar';

import { useGlobalNavbarConfig } from './config';

export const GlobalNavbar = () => {
    require('./styles.scss');

    // gov user
    const globalNavbarConfig = useGlobalNavbarConfig();

    const navBarConfig = {
        appName: 'Procurement',
        ...globalNavbarConfig,
    };

    return (
        <Box marginBottom={2.5}>
            <NavBar {...navBarConfig} />
        </Box>
    );
};

import { fieldNames } from './constants';

const { PROJECT_ID, VENDOR_LIST_ID, USER_IDS, SEARCH_PARAMS } = fieldNames;

export const validate = (values) => {
    const errors = {};

    if (!values[PROJECT_ID]) {
        errors[PROJECT_ID] = 'Please select at least one OPEN project';
    }

    if (
        !values[VENDOR_LIST_ID] &&
        (!values[VENDOR_LIST_ID] || values[VENDOR_LIST_ID].length === 0) &&
        !values[USER_IDS] &&
        !values[SEARCH_PARAMS]
    ) {
        errors[VENDOR_LIST_ID] =
            'Please select a vendor list, conduct a search or select group of users';
    }

    return errors;
};

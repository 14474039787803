import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { Button } from 'react-bootstrap';

import { form, fieldNames } from './constants';
import { sectionTypeOptions } from '../../constants';
import { validate } from './validate';
import { InputText, RichTextInput, SearchSelect } from '../../../../../components';

const { TITLE, DESCRIPTION, SECTION_TYPE } = fieldNames;

const formConfig = {
    form,
    validate,
};

// @reduxForm
class ConnectedAddContentForm extends PureComponent {
    static propTypes = {
        submitting: PropTypes.bool,
        pristine: PropTypes.bool.isRequired,
        submitServerError: PropTypes.string,
        hideLabels: PropTypes.bool,
        showSectionType: PropTypes.bool,
        handleSubmit: PropTypes.func.isRequired,
        closeForm: PropTypes.func.isRequired,
        buttonText: PropTypes.string.isRequired,
        buttonLoadingText: PropTypes.string.isRequired,
    };

    get styles() {
        return require('./AddContentForm.scss');
    }

    render() {
        const {
            buttonLoadingText,
            buttonText,
            closeForm,
            handleSubmit,
            hideLabels,
            pristine,
            showSectionType,
            submitServerError,
            submitting,
        } = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <Field
                    aria-label="Title"
                    component={InputText}
                    disabled={submitting}
                    label={hideLabels ? null : 'Title'}
                    name={TITLE}
                    placeholder="Name the suggested content"
                    type="text"
                />
                <Field
                    aria-label="Suggested Content"
                    component={RichTextInput}
                    disabled={submitting}
                    label={hideLabels ? null : 'Suggested Content'}
                    minRows={2}
                    name={DESCRIPTION}
                    placeholder="Enter the suggested content"
                    toolbarPlacement="bottom"
                />
                {showSectionType && (
                    <Field
                        component={SearchSelect}
                        disabled={submitting}
                        isSearchable={false}
                        label="Section"
                        name={SECTION_TYPE}
                        options={sectionTypeOptions}
                    />
                )}
                {submitServerError && <div className="error-block">{submitServerError}</div>}
                <div className="text-center">
                    <Button bsSize="sm" disabled={submitting} onClick={closeForm}>
                        Cancel
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                        bsStyle="success"
                        className={this.styles.submitButton}
                        disabled={pristine || submitting}
                        type="submit"
                    >
                        {submitting ? buttonLoadingText : buttonText}
                    </Button>
                </div>
            </form>
        );
    }
}

export const AddContentForm = reduxForm(formConfig)(ConnectedAddContentForm);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';

import { form, fieldNames } from './constants';
import { validate } from './validate';
import { Button, InputText, SearchSelect, SearchSelectUserOption, UserProfileList } from '../../..';

const { COMMENT, FOLLOWER_IDS } = fieldNames;

const formConfig = {
    form,
    validate,
};

// @reduxForm
class ConnectedNewThreadForm extends PureComponent {
    static propTypes = {
        canComment: PropTypes.bool.isRequired,
        users: PropTypes.array.isRequired,
        followerIdsValue: PropTypes.array.isRequired,
        submitting: PropTypes.bool.isRequired,
        submitServerError: PropTypes.string,
        showFollowersForm: PropTypes.bool.isRequired,
        showFollowersFormHandler: PropTypes.func.isRequired,
        hideFollowersFormHandler: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
    };

    renderServerError() {
        const { submitServerError } = this.props;
        const styles = require('./NewThreadForm.scss');

        if (submitServerError) {
            return <div className={`text-danger ${styles.errorMsg}`}>{submitServerError}</div>;
        }
    }

    renderFollowersList() {
        if (this.props.showFollowersForm) return null;

        const listFollowers = this.props.followerIdsValue.map((f) => f.userData);
        return (
            <UserProfileList
                addHandler={this.props.showFollowersFormHandler}
                listText="Followers"
                users={listFollowers}
            />
        );
    }

    renderFollowersInput() {
        const { canComment, hideFollowersFormHandler, showFollowersForm, submitting, users } =
            this.props;
        const styles = require('./NewThreadForm.scss');

        const selectOptions = users.map((user) => {
            return {
                value: user.id,
                label: user.displayName,
                userData: user,
            };
        });

        // Followers form should always be rendered, so it can be submitted.
        // The variable is if the follower form or list should be displayed.
        return (
            <div className={styles.followersContainer}>
                <div className={showFollowersForm ? '' : 'hidden'}>
                    <div className="clearfix visible-xs-block" />
                    <i
                        className={`fa fa-close fa-2x text-primary ${styles.closeBtn}`}
                        onClick={hideFollowersFormHandler}
                    />
                    <div className={styles.selectInput}>
                        <Field
                            component={SearchSelect}
                            components={{
                                Option: (props) => (
                                    <SearchSelectUserOption userKey="userData" {...props} />
                                ),
                            }}
                            disabled={!canComment || submitting}
                            isMulti
                            name={FOLLOWER_IDS}
                            options={selectOptions}
                            placeholder="Select follower(s) or start typing"
                            showInviteUserHelp
                            tabSelectsValue={false}
                        />
                    </div>
                </div>
                {this.renderFollowersList()}
            </div>
        );
    }

    render() {
        const { canComment, handleSubmit, submitting } = this.props;
        const styles = require('./NewThreadForm.scss');

        return (
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <Field
                        aria-label="Start a new conversation"
                        component={InputText}
                        disabled={!canComment || submitting}
                        minRows={2}
                        name={COMMENT}
                        placeholder="Start a new conversation"
                        type="textarea"
                    />
                </div>
                {this.renderServerError()}
                <Button
                    bsStyle="success"
                    className={styles.submitButton}
                    disabled={!canComment || submitting}
                    tooltip={canComment ? undefined : 'You must have view permission to comment'}
                    type="submit"
                >
                    {submitting ? 'Posting...' : 'Comment'}
                </Button>
                {this.renderFollowersInput()}
            </form>
        );
    }
}

export const NewThreadForm = reduxForm(formConfig)(ConnectedNewThreadForm);

import { fieldNames } from './constants';

const { NAME } = fieldNames;

export const validate = (values) => {
    const errors = {};

    if (!values[NAME] || !values[NAME].trim()) {
        errors[NAME] = 'Name is required';
    } else if (values[NAME].length > 256) {
        errors[NAME] = 'Name is too long';
    }

    return errors;
};

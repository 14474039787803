import { createSelector } from 'reselect';

const getProposalsPricingData = (state) => state.govProposals.get('proposalsPricingData');

export const getProposalsPricingDataJS = createSelector(
    [getProposalsPricingData],
    (proposalsPricingData) => {
        if (proposalsPricingData) {
            return proposalsPricingData.toJS();
        }
    }
);

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Panel } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, Field, formValueSelector, change } from 'redux-form';
import { Box } from '@og-pro/ui';

import { projectTypesDict } from '@og-pro/shared-config/projects';

import { form, fieldNames } from './constants';
import { validate } from './validate';
import { Button, Checkbox, DateTimePicker, SearchSelect } from '../../../../../components';

const { PURCHASE, CONTRACT } = projectTypesDict;

const formSelector = formValueSelector(form);

const {
    DEPARTMENT_IDS,
    END_DATE,
    IS_CUSTOM_DATE,
    PROCUREMENT_CONTACT_IDS,
    START_DATE,
    TIME_RANGE,
    TEMPLATE_IDS,
} = fieldNames;

const qaTagName = 'analyticsFiltersForm';

const mapStateToProps = (state) => {
    return {
        endDateValue: formSelector(state, END_DATE),
        isCustomDateValue: formSelector(state, IS_CUSTOM_DATE),
    };
};

const formConfig = {
    form,
    validate,
};

// @connect
// @reduxForm
class ConnectedAnalyticsFiltersForm extends Component {
    static propTypes = {
        departmentOptions: PropTypes.array.isRequired,
        disabled: PropTypes.bool,
        endDateValue: PropTypes.instanceOf(Date),
        handleSubmit: PropTypes.func.isRequired,
        isAdminUser: PropTypes.bool,
        isCustomDateValue: PropTypes.bool,
        templateOptions: PropTypes.array.isRequired,
        timeRangeOptions: PropTypes.array.isRequired,
        userOptions: PropTypes.array.isRequired,
        todayDate: PropTypes.instanceOf(Date).isRequired,
        projectType: PropTypes.oneOf([PURCHASE, CONTRACT]).isRequired,
        dispatch: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            showMoreFilters: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.projectType !== this.props.projectType) {
            const { dispatch, templateOptions } = this.props;
            dispatch(change(form, TEMPLATE_IDS, templateOptions));
        }
    }

    renderDateSelect() {
        const { disabled, isCustomDateValue, timeRangeOptions } = this.props;

        if (isCustomDateValue) {
            return null;
        }

        return (
            <Field
                component={SearchSelect}
                disabled={disabled || isCustomDateValue}
                isSearchable={false}
                label="Time Range"
                name={TIME_RANGE}
                options={timeRangeOptions}
                overrideFeedback
            />
        );
    }

    renderCustomDateRange() {
        const { disabled, endDateValue, isCustomDateValue, todayDate } = this.props;

        if (!isCustomDateValue) {
            return null;
        }

        return (
            <div className="row form-group">
                <div className="col-xs-6">
                    <Field
                        component={DateTimePicker}
                        dateFormat="MM/DD/YYYY"
                        disabled={disabled}
                        hasFeedback={false}
                        label="Start Date"
                        max={endDateValue || todayDate}
                        name={START_DATE}
                    />
                </div>
                <div className="col-xs-6">
                    <Field
                        component={DateTimePicker}
                        dateFormat="MM/DD/YYYY"
                        disabled={disabled}
                        hasFeedback={false}
                        label="End Date"
                        max={todayDate}
                        name={END_DATE}
                    />
                </div>
            </div>
        );
    }

    renderProcurementContactSelect() {
        const { disabled, userOptions } = this.props;

        return (
            <Field
                component={SearchSelect}
                disabled={disabled}
                isClearable={false}
                isMulti
                label="Procurement Contact"
                name={PROCUREMENT_CONTACT_IDS}
                options={userOptions}
                placeholder="Select a Procurement Contact or leave blank for all"
                qaTag={`${qaTagName}-userIds`}
            />
        );
    }

    renderDepartmentSelect() {
        const { disabled, departmentOptions } = this.props;

        return (
            <Field
                component={SearchSelect}
                disabled={disabled}
                isClearable={false}
                isMulti
                label="Department"
                name={DEPARTMENT_IDS}
                options={departmentOptions}
                placeholder="Select a Department or leave blank for all"
                qaTag={`${qaTagName}-departmentIds`}
            />
        );
    }

    render() {
        const { disabled, isAdminUser, handleSubmit, templateOptions, projectType } = this.props;
        const { showMoreFilters } = this.state;

        const btnIcon = disabled ? 'spinner fa-spin' : 'line-chart';

        return (
            <Panel className="print-panel">
                <Panel.Body>
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-sm-5">
                                {this.renderDateSelect()}
                                {this.renderCustomDateRange()}
                                <Field
                                    component={Checkbox}
                                    disabled={disabled}
                                    name={IS_CUSTOM_DATE}
                                    qaTag={`${qaTagName}-useCustomDateRange`}
                                    text="Use Custom Date Range"
                                />
                            </div>
                            <div className="col-sm-5">
                                <Field
                                    component={SearchSelect}
                                    disabled={disabled}
                                    isClearable={false}
                                    isMulti
                                    label={`${
                                        projectType === PURCHASE ? 'Project' : 'Contract'
                                    } Templates`}
                                    name={TEMPLATE_IDS}
                                    options={templateOptions}
                                />
                            </div>
                            <div className="col-sm-2 no-print" style={{ marginTop: '25px' }}>
                                <Button
                                    bsStyle="primary"
                                    disabled={disabled}
                                    qaTag={`${qaTagName}-filter`}
                                    type="submit"
                                >
                                    <i className={`fa fa-fw fa-${btnIcon}`} /> Filter
                                </Button>
                                <Box mt={0.5}>
                                    <Button
                                        bsStyle="link"
                                        onClick={() =>
                                            this.setState({ showMoreFilters: !showMoreFilters })
                                        }
                                        qaTag={`${qaTagName}-moreOptions`}
                                        zeroPadding
                                    >
                                        More filters
                                    </Button>
                                </Box>
                            </div>
                        </div>
                        {showMoreFilters && (
                            <div className="row">
                                <div className="col-sm-5">
                                    {this.renderProcurementContactSelect()}
                                </div>
                                <div className="col-sm-5">
                                    {isAdminUser && this.renderDepartmentSelect()}
                                </div>
                            </div>
                        )}
                    </form>
                </Panel.Body>
            </Panel>
        );
    }
}

export const AnalyticsFiltersForm = compose(
    connect(mapStateToProps),
    reduxForm(formConfig)
)(ConnectedAnalyticsFiltersForm);

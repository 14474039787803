import { TYPE } from './constants';

export function validate(values) {
    const errors = {};

    if (!values[TYPE]) {
        errors[TYPE] = 'Please select an evaluation type';
    }

    return errors;
}

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Box } from '@og-pro/ui';

export const ScoreDisplay = ({ score, suggestion }) => {
    const [showSuggestion, setShowSuggestion] = useState(false);
    const [showAdditionalLoading, setShowAdditionalLoading] = useState(false);

    // This is purely to provide some visual progress during the very long wait time for the data
    useEffect(() => {
        // Suggestion for improving accuracy will display after this timeout is reached
        setTimeout(() => {
            setShowSuggestion(true);
        }, 2000);
        // Additional loading message will display after this timeout is reached
        setTimeout(() => {
            setShowAdditionalLoading(true);
        }, 6000);
    }, []);

    let textColor = 'text-danger';
    if (score >= 40) {
        textColor = 'text-warning';
    }
    if (score >= 80) {
        textColor = 'text-success';
    }

    const styles = require('./index.scss');

    return (
        <div>
            <div>
                AI Accuracy Score: <strong className={textColor}>{score}</strong>
            </div>
            {showSuggestion && (
                <div className={styles.suggestionText}>
                    {suggestion
                        ? `To improve accuracy: ${suggestion}`
                        : 'Great job, you have a well detailed project!'}
                </div>
            )}
            {showAdditionalLoading && (
                <Box mt={2}>Using AI to recommend vendors for your project...</Box>
            )}
        </div>
    );
};

ScoreDisplay.propTypes = {
    score: PropTypes.number.isRequired,
    suggestion: PropTypes.string,
};

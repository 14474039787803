import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export class ModuleTitle extends PureComponent {
    static propTypes = {
        isComplete: PropTypes.bool,
        isCurrent: PropTypes.bool,
        isEnabled: PropTypes.bool,
        isPhaseComplete: PropTypes.bool,
        isSkipped: PropTypes.bool,
        name: PropTypes.string.isRequired,
        show: PropTypes.bool,
        step: PropTypes.number,
        toggleDisplay: PropTypes.func.isRequired,
    };

    get displayData() {
        const { isComplete, isCurrent, isEnabled, isPhaseComplete, isSkipped } = this.props;

        if (!isEnabled) return ['lock', this.styles.titleDisabled];
        if (isSkipped) return ['ban', this.styles.titleDisabled];
        if (isComplete) return ['check', isPhaseComplete ? 'text-success' : 'text-primary'];
        if (isCurrent) return ['star', 'text-primary'];

        return ['clock-o', 'text-muted'];
    }

    get styles() {
        return require('../shared.scss');
    }

    render() {
        const { isComplete, name, show, step, toggleDisplay } = this.props;

        const [icon, textColor] = this.displayData;
        const toggleIcon = show ? 'down' : 'left';
        const stepDisplay = (
            <span className={`fa-stack ${this.styles.moduleTitleIcon}`}>
                <i className="fa fa-stack-2x fa-circle" />
                {isComplete ? (
                    <i className={`fa fa-check fa-stack-1x ${this.styles.iconText}`} />
                ) : (
                    <strong className={`fa-stack-1x ${this.styles.iconText}`}>{step}</strong>
                )}
            </span>
        );

        return (
            <button
                className={this.styles.moduleTitleContainer}
                data-qa={`module-title-${step}-${name}-button`}
                onClick={toggleDisplay}
            >
                <h3 className={`${this.styles.moduleTitle} ${textColor}`}>
                    <i className={`fa fa-angle-${toggleIcon} pull-right`} />
                    {stepDisplay} <span className={this.styles.titleText}>{name}</span>
                    {icon && <i className={`fa fa-${icon} ${this.styles.completeIcon}`} />}
                </h3>
            </button>
        );
    }
}

import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { RequisitionAttachment } from '../../FormComponents/Attachments/RequisitionAttachment';
import { requisitionsCreateFormValueSelector } from '../../selectors';
import { ATTACHMENTS } from '../../../../../../constants/requisitionsCreate';

export const ReviewAttachments = (props) => {
    const fieldName = props.fieldNamePrefix
        ? `${props.fieldNamePrefix}.${ATTACHMENTS}`
        : ATTACHMENTS;

    const attachments = useSelector((state) =>
        requisitionsCreateFormValueSelector(state, fieldName)
    );

    const title = attachments && attachments.length > 0 ? 'Documents' : 'No Documents Uploaded';
    return <RequisitionAttachment renderAsDropPanel title={title} {...props} disabled />;
};

ReviewAttachments.propTypes = {
    fieldNamePrefix: PropTypes.string,
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Box } from '@og-pro/ui';

import { DocxTemplateForm } from './DocxTemplateForm';
import { TITLE, HEADING_FORMATTERS } from './DocxTemplateForm/constants';
import { getDocxTemplatesJS } from '../selectors';
import connectData from '../../../ConnectData';
import { showConfirmationSimpleModal } from '../../../../actions/confirmation';
import {
    deleteDocxTemplate,
    loadTemplatesList,
    updateDefaultDocxTemplate,
    uploadDocxTemplate,
    updateDocxTemplate,
} from '../../../../actions/templatesAdmin';
import {
    Button,
    LoadingError,
    LoadingSpinner,
    Main,
    PageTitle,
    ProgressBar,
} from '../../../../components';
import { WordTemplatesList } from './List';
import { UploadBox } from './UploadBox';

function fetchData(getState, dispatch) {
    if (!getState().templatesAdmin.get('loadedList')) {
        return dispatch(loadTemplatesList());
    }
    return Promise.resolve();
}

const mapStateToProps = (state) => {
    return {
        docxTemplates: getDocxTemplatesJS(state),
        loadError: state.templatesAdmin.get('loadListError'),
        loading: state.templatesAdmin.get('loadingList'),
    };
};

const mapDispatchToProps = {
    deleteDocxTemplate,
    showConfirmationSimpleModal,
    updateDefaultDocxTemplate,
    uploadDocxTemplate,
    updateDocxTemplate,
};

// @connectData
// @connect
class ConnectedWordTemplates extends Component {
    static propTypes = {
        deleteDocxTemplate: PropTypes.func.isRequired,
        docxTemplates: PropTypes.arrayOf(
            PropTypes.shape({
                created_at: PropTypes.string.isRequired,
                governmentDocxTemplates: PropTypes.shape({
                    isDefault: PropTypes.bool.isRequired,
                }).isRequired,
                id: PropTypes.number.isRequired,
                title: PropTypes.string.isRequired,
                url: PropTypes.string.isRequired,
            })
        ).isRequired,
        loadError: PropTypes.string,
        loading: PropTypes.bool.isRequired,
        showConfirmationSimpleModal: PropTypes.func.isRequired,
        updateDocxTemplate: PropTypes.func.isRequired,
        updateDefaultDocxTemplate: PropTypes.func.isRequired,
        uploadDocxTemplate: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = this.initialState;
    }

    initialState = {
        progress: 0,
        showForm: false,
        updating: false,
        uploadError: null,
        uploadFileName: null,
        uploading: false,
        success: false,
    };

    get styles() {
        return require('./shared.scss');
    }

    cancelHandler = () => this.setState(this.initialState);

    deleteHandler = (docxTemplateId) => {
        const deleteHandler = () => {
            this.setState({ updating: true });

            this.props
                .deleteDocxTemplate(docxTemplateId)
                .then(() => this.setState({ updating: false }));
        };

        this.props.showConfirmationSimpleModal(deleteHandler, {
            btnText: 'Delete Template',
            text: 'Are you sure you want to delete this Word template?',
        });
    };

    dropHandler = (file) => {
        this.setState({
            file: file[0],
            showForm: true,
        });
    };

    updateDefaultDocxTemplate = (docxTemplateId) => {
        const updateHandler = () => {
            this.setState({ updating: true });

            this.props
                .updateDefaultDocxTemplate(docxTemplateId)
                .then(() => this.setState({ updating: false }));
        };

        this.props.showConfirmationSimpleModal(updateHandler, {
            bsStyle: 'warning',
            btnText: 'Make Default',
            icon: 'bookmark',
            iconSize: '',
            text:
                'Are you sure you want to make this the default Word template?\n\n' +
                'All templates without a specified Word template will be switched over to use this Word document.',
        });
    };

    uploadDocxTemplate = (data) => {
        const { file } = this.state;

        this.setState({
            progress: 0,
            uploadError: null,
            uploadFileName: data[TITLE],
            uploading: true,
            success: false,
        });

        this.props
            .uploadDocxTemplate(
                file,
                { [TITLE]: data[TITLE], [HEADING_FORMATTERS]: data[HEADING_FORMATTERS] || null },
                (progress) => this.setState({ progress })
            )
            .then(() => {
                this.setState({ ...this.initialState, success: true });

                setTimeout(() => {
                    this.setState((s) => ({ ...s, success: false }));
                }, 7000);
            })
            .catch((error) => this.setState({ uploading: false, uploadError: error.message }));
    };

    updateDocxTemplate = (id, data) => {
        this.setState((state) => ({
            ...state,
            updating: true,
        }));

        this.props.updateDocxTemplate(id, data).then(() =>
            this.setState((state) => ({
                ...state,
                updating: false,
            }))
        );
    };

    renderUploader() {
        const {
            file,
            progress,
            showForm,
            success,
            updating,
            uploadError,
            uploadFileName,
            uploading,
        } = this.state;

        if (uploading) {
            return (
                <>
                    <div>{uploadFileName}</div>
                    <ProgressBar now={progress} />
                </>
            );
        }

        if (showForm) {
            return (
                <DocxTemplateForm
                    cancelHandler={this.cancelHandler}
                    disabled={updating}
                    initialValues={{ [TITLE]: file.name }}
                    onSubmit={this.uploadDocxTemplate}
                    uploadError={uploadError}
                />
            );
        }

        return (
            <UploadBox
                dropHandler={this.dropHandler}
                labelId="Export Document Templates Label"
                success={success}
                updating={updating}
            />
        );
    }

    render() {
        const { docxTemplates, loadError, loading } = this.props;
        const { updating, uploading } = this.state;

        if (loading) {
            return <LoadingSpinner />;
        }

        if (loadError) {
            return <LoadingError error={loadError} />;
        }

        return (
            <Main>
                <PageTitle title="Export Document Templates" />
                <h2 className={this.styles.title} id="Export Document Templates Label">
                    Export Document Templates
                </h2>
                <div className={this.styles.subtitle}>
                    These documents are used to set the cover page and formatting for exported
                    documents.&nbsp;
                    <Button
                        bsStyle="link"
                        href="https://opengov.my.site.com/support/s/article/40e071dc-933e-43c6-8f71-365165a8033f"
                        qaTag="docxTemplates-templateVariables"
                        target="_blank"
                        zeroPadding
                    >
                        View list of available template variable
                    </Button>
                </div>
                <Box className="row" mt={4}>
                    <div className="col-xs-12">
                        <h3 className={this.styles.sectionTitle}>Add New Template</h3>
                        {this.renderUploader()}
                        {!!docxTemplates.length && (
                            <Box mt={3}>
                                <h3 className={this.styles.sectionTitle}>
                                    Manage Available Templates
                                </h3>
                                <WordTemplatesList
                                    deleteHandler={this.deleteHandler}
                                    docxTemplates={docxTemplates}
                                    updateDefaultDocxTemplate={this.updateDefaultDocxTemplate}
                                    updateDocxTemplate={this.updateDocxTemplate}
                                    updating={updating}
                                    uploading={uploading}
                                />
                            </Box>
                        )}
                    </div>
                </Box>
            </Main>
        );
    }
}

export const WordTemplates = compose(
    connectData(fetchData),
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedWordTemplates);

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';

import {
    getVendorProposalJS,
    getProposalTotalScoreText,
    getProposalTotalScoreColor,
} from './selectors';
import {
    ProposalScoresTable,
    EvaluationTableDisplayMenu,
    NoItems,
    ProposalBackButton,
} from '../components';
import { getEvaluationLocationObject } from '../selectors';
import connectData from '../../../ConnectData';
import { shouldLoadFromEvaluationAudit } from '../../../../actions/evaluations';
import {
    loadAggregateProposalEvaluations,
    shouldReloadAggregateEvaluations,
} from '../../../../actions/proposalEvaluations';
import { SectionTitle, LoadingSpinner, LoadingError } from '../../../../components';

function fetchData(getState, dispatch, location, params) {
    const state = getState();
    if (
        shouldLoadFromEvaluationAudit(state, Object.fromEntries(location.searchParams)) ||
        !shouldReloadAggregateEvaluations(state)
    ) {
        return Promise.resolve();
    }
    const projectId = Number.parseInt(params.projectId, 10);
    return dispatch(loadAggregateProposalEvaluations(projectId));
}

const mapStateToProps = (state, props) => {
    return {
        error: state.proposalEvaluations.get('loadAggregateError'),
        getEvaluationPath: getEvaluationLocationObject(state, props),
        loading: state.proposalEvaluations.get('loadingAggregate'),
        proposal: getVendorProposalJS(state, props),
        scoreColor: getProposalTotalScoreColor(state, props),
        scoreText: getProposalTotalScoreText(state, props),
    };
};

// @connectData
// @connect
class ConnectedVendorEvaluations extends Component {
    static propTypes = {
        error: PropTypes.string,
        getEvaluationPath: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        proposal: PropTypes.object,
        scoreColor: PropTypes.string,
        scoreText: PropTypes.string,
    };

    get styles() {
        return require('./VendorEvaluations.scss');
    }

    createScoresTableRef = (ref) => {
        this.scoresTable = ref;
    };

    handleExportScoresCSVClick = () => {
        this.scoresTable.handleExportCSVClick();
    };

    renderScoresTable() {
        const { proposal, scoreColor, scoreText } = this.props;

        return (
            <EvaluationTableDisplayMenu
                handleExportCSVClick={this.handleExportScoresCSVClick}
                showAnonymizedEvaluatorsOption
            >
                <ProposalScoresTable createRef={this.createScoresTableRef} proposal={proposal} />
                <div className={this.styles.totalScore}>
                    Total Cumulative Score:&nbsp;
                    <strong className={`text-${scoreColor}`}>{scoreText}</strong>
                </div>
            </EvaluationTableDisplayMenu>
        );
    }

    render() {
        const { error, getEvaluationPath, loading, proposal } = this.props;

        if (loading) {
            return <LoadingSpinner />;
        }

        if (error) {
            return <LoadingError error={error} />;
        }

        const vendorName = proposal.companyName || 'Unnamed Company';
        return (
            <div>
                <ProposalBackButton
                    text="Back to All Evaluations"
                    to={getEvaluationPath('/aggregate-evaluations')}
                />
                <SectionTitle
                    info={`Scores for each evaluator of the response submitted by ${vendorName}`}
                    title={`${vendorName} Response Evaluation`}
                />
                {proposal.proposalEvaluations.length === 0 ? (
                    <NoItems text="There are no evaluators assigned yet." />
                ) : (
                    this.renderScoresTable()
                )}
            </div>
        );
    }
}

export const VendorEvaluations = compose(
    connectData(fetchData),
    withRouter,
    connect(mapStateToProps)
)(ConnectedVendorEvaluations);

export const LOAD = 'vendorProfile/LOAD';
export const LOAD_SUCCESS = 'vendorProfile/LOAD_SUCCESS';
export const LOAD_FAIL = 'vendorProfile/LOAD_FAIL';

export function loadVendor(vendorId) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD });
        return client
            .get(`/vendor/${vendorId}/government`)
            .then((result) => dispatch({ type: LOAD_SUCCESS, result }))
            .catch((error) => dispatch({ type: LOAD_FAIL, error }));
    };
}

export const SHOW_MODAL = 'vendorProfile/SHOW_MODAL';
export const HIDE_MODAL = 'vendorProfile/HIDE_MODAL';

export function showVendorProfileModal(vendorId) {
    return { type: SHOW_MODAL, vendorId };
}

export function hideVendorProfileModal() {
    return { type: HIDE_MODAL };
}

import { createSelector } from 'reselect';

import { projectStatusesDict } from '@og-pro/shared-config/projects';

import { normalizeProject } from '../../../reducers/utils';

const { OPEN } = projectStatusesDict;

const getProject = (state) => state.publicProject.get('project');
const getGovernment = (state) => state.publicProject.get('government');

export const getProjectPath = (_state, props) => {
    const { governmentCode, projectId } = props.params;
    return `/portal/${governmentCode}/projects/${projectId}`;
};

export const getProjectUrl = createSelector(
    [getProjectPath],
    (projectPath) => `${process.env.SITE_URL}${projectPath}`
);

export const getProjectJS = createSelector([getProject], (rawProject) => {
    if (rawProject) {
        const project = rawProject.toJS();
        return normalizeProject(project);
    }
});

export const isProjectOpen = createSelector([getProjectJS], (project) => {
    if (project) {
        return project.status === OPEN;
    }
    return false;
});

export const getGovernmentJS = createSelector([getGovernment], (rawGovernment) => {
    if (rawGovernment) {
        return rawGovernment.toJS();
    }
});

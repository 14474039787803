import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isNil } from 'lodash';
import { Box } from '@og-pro/ui';

export const Main = ({
    disabled = false,
    hideInputsBorders = false,
    children,
    onClick,
    ...rest
}) => {
    const styles = require('./index.scss');

    const cssClasses = classnames('content-block-main', styles.main, {
        [styles.withOnClick]: !isNil(onClick),
        [styles.borderlessInputs]: hideInputsBorders,
        [styles.disabled]: disabled,
    });

    return (
        <Box className={cssClasses} onClick={onClick} {...rest}>
            {children}
        </Box>
    );
};

Main.propTypes = {
    hasError: PropTypes.bool,
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    hideInputsBorders: PropTypes.bool,
    onClick: PropTypes.func,
};

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Grid, Hidden, useMediaQuery, useTheme } from '@mui/material';
import { Box, Typography } from '@og-pro/ui';

import { Breadcrumb, ConnectedClients } from '../..';

export const TemplateProjectHUD = (props) => {
    const { actions, breadcrumbs, department, identification, projectLayout, status, title, type } =
        props;
    const styles = require('./index.scss');

    const theme = useTheme();

    const isSm = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
    const isXs = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

    return (
        <div className={styles.templateProjectHUDContainer} data-qa="templateEdit-header" id="skip">
            <Grid alignItems="center" container>
                <Grid item md={8} sm={6} xs={6}>
                    <Breadcrumb>
                        {breadcrumbs.links.map((link) => (
                            <Breadcrumb.Item
                                className={styles.breadcrumbs}
                                href={link.path}
                                key={link.label}
                            >
                                {link.label}
                            </Breadcrumb.Item>
                        ))}
                        {!!breadcrumbs.activePageLabel && (
                            <Breadcrumb.Item active>{breadcrumbs.activePageLabel}</Breadcrumb.Item>
                        )}
                    </Breadcrumb>
                </Grid>
                <Grid item md={4} sm={6} xs={6}>
                    <Box
                        className={classnames(styles.actionsContainer, {
                            [styles.mobileActionsContainer]: isXs,
                        })}
                    >
                        <Hidden smDown>
                            <Box className={styles.connectedClientsContainer} mr={1}>
                                <ConnectedClients
                                    className={classnames(styles.connectedClients, {
                                        [styles.negateLeftPadding]: isSm,
                                    })}
                                />
                            </Box>
                        </Hidden>
                        {actions?.length > 0 &&
                            actions.map((action, index) => (
                                <Box
                                    className={classnames(styles.action, {
                                        [styles.lastAction]: actions.length - 1 === index,
                                    })}
                                    key={index}
                                >
                                    {action}
                                </Box>
                            ))}
                    </Box>
                </Grid>
            </Grid>
            {!projectLayout && <h4>{type}</h4>}

            <Box mb={1}>
                <Box>
                    <Typography variant="h1">{title}</Typography>
                </Box>
            </Box>
            {projectLayout && !!(type || department || identification) && (
                <Box
                    className={classnames(styles.subtitlesContainer, {
                        [styles.tableSubstitlesContainer]: isSm,
                    })}
                >
                    <Box className={classnames(styles.subtitle, { [styles.blockSubtitle]: isSm })}>
                        {status.label}
                    </Box>
                    <Box display={isXs ? 'block' : 'flex'}>
                        {!!type && (
                            <Box
                                className={classnames(styles.subtitle, {
                                    [styles.blockSubtitle]: isXs,
                                })}
                            >
                                <span>Type: </span>
                                {type}
                            </Box>
                        )}
                        {!!department && (
                            <Box
                                className={classnames(styles.subtitle, {
                                    [styles.blockSubtitle]: isXs,
                                })}
                            >
                                <span>Department: </span>
                                {department}
                            </Box>
                        )}
                        {!!identification && (
                            <Box
                                className={classnames(styles.subtitle, {
                                    [styles.blockSubtitle]: isXs,
                                })}
                            >
                                <span>Project ID: </span>
                                {identification}
                            </Box>
                        )}
                    </Box>
                </Box>
            )}
            <div>
                {!projectLayout && status.label}
                &nbsp;{status.action}
            </div>
        </div>
    );
};

TemplateProjectHUD.propTypes = {
    actions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.node, PropTypes.string])),
    breadcrumbs: PropTypes.shape({
        links: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
                path: PropTypes.string.isRequired,
            })
        ),
        activePageLabel: PropTypes.string.isRequired,
    }).isRequired,
    department: PropTypes.string,
    identification: PropTypes.string,
    projectLayout: PropTypes.bool,
    status: PropTypes.shape({
        label: PropTypes.any,
        action: PropTypes.any,
    }).isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';

import { Button, InputText } from '../../../../../../components';
import { CategorySelectInput } from '../../../../..';
import { form, fieldNames } from './constants';

const { SUMMARY_SEARCH, CATEGORIES } = fieldNames;

const formConfig = {
    form,
};

// @reduxForm
class ConnectedLibrarySearchForm extends Component {
    static propTypes = {
        disabled: PropTypes.bool,
        handleSubmit: PropTypes.func.isRequired,
        categoriesSelectHandler: PropTypes.func.isRequired,
    };

    render() {
        const { handleSubmit, disabled, categoriesSelectHandler } = this.props;
        const styles = require('./LibrarySearchForm.scss');
        return (
            <form onSubmit={handleSubmit}>
                <div className={styles.searchBar}>
                    <Field
                        aria-label="Search Projects"
                        component={InputText}
                        disabled={disabled}
                        formGroupClassName={styles.searchInput}
                        hasFeedback={false}
                        name={SUMMARY_SEARCH}
                        overrideFeedback
                        placeholder="Search Projects"
                        qaTag="connectedLibrarySearchForm-searchProjects"
                        type="text"
                    />
                </div>
                <div className={styles.searchButton}>
                    <Button
                        bsStyle="primary"
                        disabled={disabled}
                        qaTag="connectedLibrarySearchForm-search"
                        type="submit"
                    >
                        Search
                    </Button>
                </div>
                <Field
                    component={CategorySelectInput}
                    disabled={disabled}
                    label="Search by Category"
                    maxLength={70}
                    name={CATEGORIES}
                    noSelectionText="Searching all categories"
                    onChange={categoriesSelectHandler}
                    qaTag="connectedLibrarySearchForm-categorySelectInput-searchByCategory"
                />
            </form>
        );
    }
}

export const LibrarySearchForm = reduxForm(formConfig)(ConnectedLibrarySearchForm);

import classnames from 'classnames';
import { get } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { compose } from 'redux';

import { ProposalScoresExpanded } from './ProposalScoresExpanded';
import { getDetailedProposalData, getEvaluators } from './selectors';
import { getTotalWeight } from '../../selectors';
import { CriteriaScoreCell, DataTable, ScoringCriteriaCell } from '../../../../../components';

const MAX_ROWS = 20;
const MINIMUM_OVERFLOW_ROWS_FOR_PAGINATION = 2;

const mapStateToProps = (state, props) => {
    return {
        evaluators: getEvaluators(state, props),
        scoringData: getDetailedProposalData(state, props),
        showAnonymizedEvaluators: state.proposalEvaluations.get('showAnonymizedEvaluators'),
        showComments: state.proposalEvaluations.get('showEvaluatorComments'),
        showCriteriaDescriptions: state.proposalEvaluations.get('showCriteriaDescriptions'),
        showExpandedView: state.proposalEvaluations.get('showExpandedView'),
        showPercentageTotals: state.proposalEvaluations.get('showPercentageTotals'),
        totalWeight: getTotalWeight(state, props),
    };
};

// @withRouter
// @connect
class ConnectedProposalScoresTable extends Component {
    static propTypes = {
        className: PropTypes.string,
        createRef: PropTypes.func,
        evaluators: PropTypes.arrayOf(
            PropTypes.shape({
                displayName: PropTypes.string.isRequired,
            })
        ).isRequired,
        expandedViewClassName: PropTypes.string,
        proposal: PropTypes.shape({
            companyName: PropTypes.string,
            proposalEvaluations: PropTypes.arrayOf(
                PropTypes.shape({
                    note: PropTypes.string,
                    totalScore: PropTypes.number,
                    totalScoreByPoints: PropTypes.number,
                })
            ).isRequired,
        }).isRequired,
        scoringData: PropTypes.array.isRequired,
        showAnonymizedEvaluators: PropTypes.bool,
        showComments: PropTypes.bool,
        showCriteriaDescriptions: PropTypes.bool,
        showCSVExport: PropTypes.bool,
        showExpandedView: PropTypes.bool,
        showPercentageTotals: PropTypes.bool,
        totalWeight: PropTypes.number.isRequired,
    };

    static defaultProps = {
        className: '',
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const {
            className,
            createRef,
            evaluators,
            expandedViewClassName,
            proposal,
            scoringData,
            showAnonymizedEvaluators,
            showComments,
            showCriteriaDescriptions,
            showCSVExport,
            showExpandedView,
            showPercentageTotals,
            totalWeight,
        } = this.props;

        const numRows = scoringData.length;

        const scoringCriteriaColumn = {
            Header: 'Evaluation Item',
            accessor: 'scoringCriteria.title',
            Cell: (props) => (
                <ScoringCriteriaCell
                    scoringCriteria={props.original.scoringCriteria}
                    separateLines
                    showDescription={showCriteriaDescriptions}
                    totalWeight={totalWeight}
                />
            ),
            Footer: <strong>Total{!showPercentageTotals && ` (Max Score ${totalWeight})`}</strong>,
        };

        const evaluatorColumns = evaluators.map((evaluator, idx) => {
            return {
                Header: showAnonymizedEvaluators ? `Evaluator ${idx + 1}` : evaluator.displayName,
                accessor: `proposalScores[${idx}].score`,
                Cell: (props) => (
                    <CriteriaScoreCell
                        comment={get(props, `original.proposalScores.[${idx}].comment`)}
                        scoringCriteria={props.original.scoringCriteria}
                        showComments={showComments}
                        value={props.value}
                    />
                ),
                Footer: () => {
                    const { note, totalScore, totalScoreByPoints } =
                        proposal.proposalEvaluations[idx];

                    return (
                        <CriteriaScoreCell
                            comment={note}
                            isTotal
                            showComments={showComments}
                            showPercentageTotals={showPercentageTotals}
                            totalScorePercentage={totalScore}
                            value={showPercentageTotals ? totalScore : totalScoreByPoints}
                        />
                    );
                },
            };
        });

        const columns = [scoringCriteriaColumn, ...evaluatorColumns];

        const usePagination = numRows > MAX_ROWS + MINIMUM_OVERFLOW_ROWS_FOR_PAGINATION;

        const formatDataForCSVExport = (data) => {
            const headers = [
                scoringCriteriaColumn.Header,
                ...evaluatorColumns.map((column) => column.Header),
            ];

            const rows = data.map((dataRow) => {
                return [
                    dataRow[scoringCriteriaColumn.accessor],
                    ...evaluatorColumns.map((column) => dataRow[column.accessor]),
                ];
            });

            if (showComments) {
                headers.push(...evaluatorColumns.map((column) => `${column.Header} Comments`));
                rows.forEach((row, rowIndex) => {
                    const dataRow = data[rowIndex];
                    const commentData = evaluatorColumns.map((col, colIndex) => {
                        return get(dataRow, `_original.proposalScores.[${colIndex}].comment`) || '';
                    });
                    row.push(...commentData);
                });
            }

            return [headers].concat(rows);
        };

        // DataTable always needs to be included for CSV export to work
        return (
            <>
                <DataTable
                    className={classnames(
                        '-striped',
                        '-highlight',
                        showExpandedView && this.styles.hiddenTable,
                        className
                    )}
                    columns={columns}
                    csvExportOptions={{
                        fileName: `${
                            proposal.companyName ? proposal.companyName : 'Unnamed Company'
                        } Response Scores`,
                        getFormattedCSVData: formatDataForCSVExport,
                        headers: true,
                    }}
                    data={scoringData}
                    defaultPageSize={usePagination ? MAX_ROWS : numRows}
                    ref={createRef}
                    showCSVExport={showCSVExport}
                    showPagination={usePagination}
                />
                {showExpandedView && (
                    <ProposalScoresExpanded
                        className={expandedViewClassName}
                        evaluators={evaluators}
                        scoringData={scoringData}
                        showAnonymizedEvaluators={showAnonymizedEvaluators}
                        showComments={showComments}
                        showCriteriaDescriptions={showCriteriaDescriptions}
                        totalWeight={totalWeight}
                    />
                )}
            </>
        );
    }
}

export const ProposalScoresTable = compose(
    withRouter,
    connect(mapStateToProps)
)(ConnectedProposalScoresTable);

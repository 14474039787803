import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { defaultError } from '../../constants';

export class FormError extends PureComponent {
    static propTypes = {
        error: PropTypes.string,
        useOpenGovStyle: PropTypes.bool,
    };

    render() {
        const { error, useOpenGovStyle } = this.props;
        if (!error) return null;

        const styles = require('./FormError.scss');

        if (useOpenGovStyle) {
            return (
                <div className={styles.opengovStyleError}>
                    <i className="fa fa-exclamation-triangle" /> {error || defaultError}
                </div>
            );
        }

        return <div className={`text-danger ${styles.error}`}>{error || defaultError}</div>;
    }
}

import { get } from 'lodash';

import { UNIT_PRICE } from '@og-pro/shared-config/priceTables';

import { questionnaireResponseComplete } from '../../../Forms/validation';

export const warnQuestionnaire = (values) => {
    const warnings = {};

    if (values.questionnaires) {
        warnings.questionnaires = values.questionnaires.map((questionnaire) => {
            return questionnaireResponseComplete(questionnaire, { optional: true });
        });
    }

    return warnings;
};

export const warnPricingTables = (values) => {
    const warnings = {};

    warnings.priceTables = (values.priceTables || []).map((priceTable) => {
        const { hasNoBid } = priceTable;
        return {
            priceItems: priceTable.priceItems.map((priceItem) => {
                const vendorResponse = get(priceItem, ['vendorResponse', UNIT_PRICE]);
                const warning = {};

                if (hasNoBid && vendorResponse === 0) {
                    warning.unitPrice =
                        '$0.00 is not the same as no bid. Please use the No Bid checkbox if you are not bidding on a line item.';
                }

                return warning;
            }),
        };
    });

    return warnings;
};

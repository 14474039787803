import { listToDict } from '@og-pro/shared-config/helpers';

const accountFields = [
    // id from database if this was loaded from a pre-existing requisition
    'id',
    // temporary id to uniquely identify an account number input
    // At least one of these should always be defined on an AccountField!!!
    'tempId',
    'accountNumber',
    'amount',
    'availableBudgetAmount',
    'accountObject',
    'accountExternalId',
];

export const accountFieldNames = listToDict(accountFields);

// TODO: [FMS] accountPseudoKey is a very "FIN" term.  We should rename it to something more generic for FMS connected requisitions.
const accountObjectFields = [
    'accountCompressedFormatted',
    'accountPseudoKey',
    'description',
    'accountType',
    'isExpenseAccount',
    'hasAccountGroup',
];

export const accountObjectFieldNames = listToDict(accountObjectFields);

export const columnsLayout = {
    accountNumber: 4,
    amount: 2,
    toggleAuto: 1,
    budgetCheck: 4,
    remove: 1,
};

import { List, fromJS } from 'immutable';

import { commentsNormalizer } from './helpers/govCommentsHelper';
import * as commentsActions from '../actions/govComments';
import * as criteriaActions from '../actions/govProjects/criteria';

// NOTE: For UI specific actions there are states that should only be viewable in the UI that they
// were interacted with.
// An action with an "isModal" key is provided when the interaction was in the comment modal view.
// Otherwise the interaction was in the comments list view.
function commentReducer(state, action) {
    // We only care about the thread that matches the threadId
    if (state.get('id') !== action.threadId) {
        return state;
    }

    switch (action.type) {
        case commentsActions.REPLY:
            if (action.isModal) {
                return state.merge(
                    fromJS({
                        replying: true,
                        replyError: null,
                    })
                );
            }
            return state.merge(
                fromJS({
                    listReplying: true,
                    listReplyError: null,
                })
            );
        case commentsActions.REPLY_SUCCESS:
            return fromJS(action.result);
        case commentsActions.REPLY_FAIL:
            if (action.isModal) {
                return state.merge(
                    fromJS({
                        replying: false,
                        replyError: action.error && action.error.message,
                    })
                );
            }
            return state.merge(
                fromJS({
                    listReplying: false,
                    listReplyError: action.error && action.error.message,
                })
            );
        case commentsActions.THREAD_ACTION:
            if (action.isModal) {
                return state.merge(
                    fromJS({
                        resolving: true,
                        resolveError: null,
                    })
                );
            }
            return state.merge(
                fromJS({
                    listResolving: true,
                    listResolveError: null,
                })
            );
        case commentsActions.THREAD_ACTION_SUCCESS:
            return fromJS(action.result);
        case commentsActions.THREAD_ACTION_FAIL:
            if (action.isModal) {
                return state.merge(
                    fromJS({
                        resolving: false,
                        resolveError: action.error && action.error.message,
                    })
                );
            }
            return state.merge(
                fromJS({
                    listResolving: false,
                    listResolveError: action.error && action.error.message,
                })
            );
        case commentsActions.ADD_FOLLOWERS:
            if (action.isModal) {
                return state.merge(
                    fromJS({
                        addingFollowers: true,
                        addFollowersError: null,
                    })
                );
            }
            return state.merge(
                fromJS({
                    listAddingFollowers: true,
                    listAddFollowersError: null,
                })
            );
        case commentsActions.ADD_FOLLOWERS_SUCCESS:
            return fromJS(action.result);
        case commentsActions.ADD_FOLLOWERS_FAIL:
            if (action.isModal) {
                // Will also remove showFollowersForm closing the form
                return state.merge(
                    fromJS({
                        addingFollowers: false,
                        addFollowersError: action.error && action.error.message,
                    })
                );
            }
            return state.merge(
                fromJS({
                    listAddingFollowers: false,
                    listAddFollowersError: action.error && action.error.message,
                })
            );
        case commentsActions.SHOW_FOLLOWERS_FORM:
        case commentsActions.HIDE_FOLLOWERS_FORM:
            if (action.isModal) {
                return state.merge(
                    fromJS({
                        showFollowersForm: action.type === commentsActions.SHOW_FOLLOWERS_FORM,
                    })
                );
            }
            return state.merge(
                fromJS({
                    listShowFollowersForm: action.type === commentsActions.SHOW_FOLLOWERS_FORM,
                })
            );
        default:
            return state;
    }
}

const commentModalInitialState = {
    selectedCommentId: null,
    selectedThreadKey: null,
    showModal: false,
    showNewThreadFollowersForm: false,
    showResolved: false,
};

const initialState = fromJS({
    ...commentModalInitialState,
    comments: {},
    createThreadError: null,
    creatingThread: false,
    listFilter: 'all',
    loaded: false,
    loading: false,
    loadError: null,
    showComments: false,
});

export default function commentsReducer(state = initialState, action = {}) {
    switch (action.type) {
        case commentsActions.LOAD:
            return state.merge(
                fromJS({
                    loading: true,
                    loaded: false,
                    loadError: null,
                })
            );
        case commentsActions.LOAD_SUCCESS:
            return state.merge(
                fromJS({
                    loading: false,
                    loaded: true,
                    comments: fromJS(commentsNormalizer(action.result)),
                })
            );
        case commentsActions.LOAD_FAIL:
            return state.merge(
                fromJS({
                    loading: false,
                    loaded: false,
                    loadError: action.error && action.error.message,
                })
            );
        case commentsActions.CREATE_THREAD:
            return state.merge(
                fromJS({
                    creatingThread: true,
                    createThreadError: null,
                })
            );
        case commentsActions.CREATE_THREAD_SUCCESS: {
            const threads = state.getIn(['comments', action.field]) || new List();
            const newThreads = threads.unshift(fromJS(action.result));
            return state.setIn(['comments', action.field], newThreads).merge(
                fromJS({
                    creatingThread: false,
                })
            );
        }
        case commentsActions.CREATE_THREAD_FAIL:
            return state.merge(
                fromJS({
                    creatingThread: false,
                    createThreadError: action.error && action.error.message,
                })
            );
        case commentsActions.SHOW_COMMENTS:
            return state.set('showComments', true);
        case commentsActions.HIDE_COMMENTS:
            return state.set('showComments', false);
        case commentsActions.VIEW_SELECTED_COMMENT:
            return state.set('selectedCommentId', action.commentId);
        case commentsActions.SHOW_MODAL:
            return state.merge(
                fromJS({
                    showModal: true,
                    selectedThreadKey: action.field,
                })
            );
        case commentsActions.HIDE_MODAL:
            return state.merge(fromJS(commentModalInitialState));
        case commentsActions.REPLY:
        case commentsActions.REPLY_SUCCESS:
        case commentsActions.REPLY_FAIL:
        case commentsActions.THREAD_ACTION:
        case commentsActions.THREAD_ACTION_SUCCESS:
        case commentsActions.THREAD_ACTION_FAIL:
        case commentsActions.ADD_FOLLOWERS:
        case commentsActions.ADD_FOLLOWERS_SUCCESS:
        case commentsActions.ADD_FOLLOWERS_FAIL:
        case commentsActions.SHOW_FOLLOWERS_FORM:
        case commentsActions.HIDE_FOLLOWERS_FORM: {
            // Give comments a default value in case a connected client isn't up to date
            const threads = state.getIn(['comments', action.field]) || new List();
            return state.setIn(
                ['comments', action.field],
                threads.map((thread) => {
                    return commentReducer(thread, action);
                })
            );
        }
        case commentsActions.SHOW_RESOLVED_COMMENTS:
            return state.set('showResolved', true);
        case commentsActions.HIDE_RESOLVED_COMMENTS:
            return state.set('showResolved', false);
        case commentsActions.LIST_FILTER:
            return state.set('listFilter', action.filter);
        case commentsActions.SHOW_NEW_FOLLOWERS_FORM:
            return state.set('showNewThreadFollowersForm', true);
        case commentsActions.HIDE_NEW_FOLLOWERS_FORM:
            return state.set('showNewThreadFollowersForm', false);
        // When a criteria item is deleted remove any associated comments
        case criteriaActions.DELETE_SUCCESS:
            return state.deleteIn(['comments', `${action.id}`]);
        case commentsActions.RESET:
            return initialState;
        default:
            return state;
    }
}

import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';

import { formConfig } from '../RequisitionsCreateForms/form';

const requisitionFormValues = (state) => getFormValues(formConfig.form)(state);

export const getRequisitionFormValues = createSelector([requisitionFormValues], (formValues) => {
    if (formValues) {
        return formValues;
    }
    return {};
});

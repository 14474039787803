import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import {
    bidBondQuestionnaireResponseFieldNamesDict,
    DATA,
} from '@og-pro/shared-config/questionnaires';

import { VerifyBidBondButton } from '../VerifyBidBondButton';

const { BID_BOND_ID, BID_BOND_VENDOR_ID } = bidBondQuestionnaireResponseFieldNamesDict;

export const BidBondResponseDisplay = ({ isDocx, questionnaireResponse, responseOnly }) => {
    const noResponse = <span className="response-display-text">No response submitted</span>;
    const bidBondId = get(questionnaireResponse, [DATA, BID_BOND_ID], '');
    const bidBondVendorId = get(questionnaireResponse, [DATA, BID_BOND_VENDOR_ID], '');
    const proposalId = get(questionnaireResponse, 'proposal_id');
    const questionnaireId = get(questionnaireResponse, 'questionnaire_id');

    return (
        <>
            <p>Bond ID: {bidBondId || noResponse}</p>
            <p>Vendor ID: {bidBondVendorId || noResponse}</p>
            {!isDocx && !responseOnly && (
                <VerifyBidBondButton
                    bidBondIdData={bidBondId}
                    bidBondVendorIdData={bidBondVendorId}
                    proposalId={proposalId}
                    questionnaireId={questionnaireId}
                />
            )}
        </>
    );
};

BidBondResponseDisplay.propTypes = {
    isDocx: PropTypes.bool,
    questionnaireResponse: PropTypes.shape({
        data: PropTypes.shape({
            bidBondId: PropTypes.string,
            bidBondVendorId: PropTypes.string,
        }),
        proposal_id: PropTypes.number.isRequired,
        questionnaire_id: PropTypes.number.isRequired,
    }),
    responseOnly: PropTypes.bool,
};

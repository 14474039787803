import { createSelector } from 'reselect';

import { statusTypes } from '@og-pro/shared-config/reviewSequences';

import {
    ALL as ALL_NAV,
    ARCHIVED as ARCHIVED_NAV,
    DRAFT as DRAFT_NAV,
    LIVE as LIVE_NAV,
} from './constants';

const { ARCHIVED, DRAFT, PUBLISHED } = statusTypes;

const getRawReviewSequences = (state) => state.reviewSequences.get('reviewSequences');
const getLocation = (state, props) => props.location;

export const getReviewSequences = createSelector([getRawReviewSequences], (rawReviewSequences) => {
    return rawReviewSequences.toJS();
});

const getAllReviewSequences = createSelector([getReviewSequences], (reviewSequences) => {
    return reviewSequences.filter((reviewSequence) => reviewSequence.status !== ARCHIVED);
});

const getDraftReviewSequences = createSelector([getReviewSequences], (reviewSequences) => {
    return reviewSequences.filter((reviewSequence) => reviewSequence.status === DRAFT);
});

const getLiveReviewSequences = createSelector([getReviewSequences], (reviewSequences) => {
    return reviewSequences.filter((reviewSequence) => reviewSequence.status === PUBLISHED);
});

const getArchivedReviewSequences = createSelector([getReviewSequences], (reviewSequences) => {
    return reviewSequences.filter((reviewSequence) => reviewSequence.status === ARCHIVED);
});

export const getNavItems = createSelector(
    [
        getAllReviewSequences,
        getDraftReviewSequences,
        getLiveReviewSequences,
        getArchivedReviewSequences,
    ],
    (allReviewSequences, draftReviewSequences, liveReviewSequences, archivedReviewSequences) => {
        return [
            {
                name: 'All',
                status: ALL_NAV,
                counter: allReviewSequences.length,
            },
            {
                name: 'Live',
                status: LIVE_NAV,
                counter: liveReviewSequences.length,
            },
            {
                name: 'Draft',
                status: DRAFT_NAV,
                counter: draftReviewSequences.length,
            },
            {
                name: 'Archived',
                status: ARCHIVED_NAV,
                counter: archivedReviewSequences.length,
            },
        ];
    }
);

export const getSelectedNavItem = createSelector(
    [getNavItems, getLocation],
    (navItems, location) => {
        const {
            query: { status },
        } = location;

        return navItems.find((item) => item.status === status) || navItems[0];
    }
);

export const getSelectedReviewSequences = createSelector(
    [
        getSelectedNavItem,
        getAllReviewSequences,
        getDraftReviewSequences,
        getLiveReviewSequences,
        getArchivedReviewSequences,
    ],
    (
        selectedNavItem,
        allReviewSequences,
        draftReviewSequences,
        liveReviewSequences,
        archivedReviewSequences
    ) => {
        switch (selectedNavItem.status) {
            case ALL_NAV:
                return allReviewSequences;
            case LIVE_NAV:
                return liveReviewSequences;
            case DRAFT_NAV:
                return draftReviewSequences;
            case ARCHIVED_NAV:
                return archivedReviewSequences;
            default:
                return [];
        }
    }
);

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFormSyncErrors, isDirty } from 'redux-form';

import { qaTagPageName } from '../constants';
import { formConfig } from '../RequisitionsCreateForms/form';
import { Button } from '../../../../components';
import { EDIT_MODE, REVIEW_MODE } from '../../../../constants/requisitionsCreate';
import { hasFormErrors } from '../../../../helpers';
import { useQueryParam } from '../../../../hooks';
import { scrollToTop } from '../../../../utils';
import {
    cleanRequisitionBudgetCheckResults,
    preCheckBudget,
    showRequisitionApprovalModal,
} from '../../../../actions/requisitions';
import { actionLabelNames } from '../../../../lib/ogFinancials/constants';
import { getRequisitionJS } from '../../../../selectors/govApp';

const { SUBMIT } = actionLabelNames;

const RequisitionsCreateNavButtons = ({
    disabled,
    hideGlobalFormValidation,
    saveRequisition,
    showGlobalFormValidation,
    submitRequisition,
}) => {
    const dispatch = useDispatch();
    const styles = require('./index.scss');

    const [formMode, , setMultipleQueryParams] = useQueryParam('mode', EDIT_MODE);

    const requisition = useSelector(getRequisitionJS);
    const formErrors = useSelector((state) => getFormSyncErrors(formConfig.form)(state));
    const valid = useMemo(() => !hasFormErrors(formErrors), [formErrors]);
    const isFormDirty = useSelector((state) => isDirty(formConfig.form)(state));

    const saveWhenFormIsDirty = () => {
        if (isFormDirty && !disabled) {
            saveRequisition();
        }
    };

    const showRequisitionSubmitConfirmation = () =>
        dispatch(
            showRequisitionApprovalModal(
                (approvalData) => {
                    if (!valid) {
                        showGlobalFormValidation();
                        return Promise.reject(
                            new Error('Please fix form errors before submitting')
                        );
                    }
                    return submitRequisition(approvalData);
                },
                {
                    actionLabel: SUBMIT,
                    bsStyle: 'success',
                    btnText: 'Submit Request',
                    icon: 'fa-send',
                    text:
                        'Are you sure you want to submit your request?\n' +
                        'This will start the approval process and notify the first approval group.',
                }
            )
        );

    const handleSubmitRequisition = () => {
        dispatch(
            preCheckBudget(requisition.id, SUBMIT, showRequisitionSubmitConfirmation, scrollToTop)
        );
    };

    const handleSaveRequisition = () => {
        if (!valid) {
            scrollToTop();
            showGlobalFormValidation();
            return;
        }

        saveWhenFormIsDirty();
        dispatch(cleanRequisitionBudgetCheckResults());
        setMultipleQueryParams({ mode: REVIEW_MODE });
        hideGlobalFormValidation();
        scrollToTop();
    };

    return (
        <nav className={styles.navButtons}>
            {formMode === REVIEW_MODE ? (
                <>
                    <Button
                        aria-label="Back to Edit Mode"
                        className={styles.navButton}
                        disabled={disabled}
                        onClick={() => {
                            setMultipleQueryParams({
                                mode: EDIT_MODE,
                                section: null,
                            });

                            scrollToTop();
                        }}
                        qaTag={`${qaTagPageName}-backButton`}
                        style={{ visibility: formMode === EDIT_MODE && 'hidden' }}
                    >
                        <i className="fa fa-angle-left fa-lg" /> Back
                    </Button>
                    <Button
                        aria-label="Submit Request"
                        bsStyle="primary"
                        className={styles.navButton}
                        disabled={disabled}
                        onClick={() => handleSubmitRequisition()} // Remotely submit form using redux-form `submit` action dispatcher
                        qaTag={`${qaTagPageName}-submitButton`}
                        type="submit"
                    >
                        Submit for Review
                        <i className="fa fa-angle-right fa-lg" />
                    </Button>
                </>
            ) : (
                <div className={styles.nextButtonContainer}>
                    <Button
                        aria-label="Review and Submit Request"
                        bsStyle="primary"
                        className={styles.navButton}
                        disabled={disabled}
                        onClick={() => handleSaveRequisition()}
                        qaTag={`${qaTagPageName}-nextButton`}
                        type="button"
                    >
                        Review &amp; Confirm
                        <i className="fa fa-angle-right fa-lg" />
                    </Button>
                </div>
            )}
        </nav>
    );
};

RequisitionsCreateNavButtons.propTypes = {
    disabled: PropTypes.bool,
    hideGlobalFormValidation: PropTypes.func.isRequired,
    saveRequisition: PropTypes.func.isRequired,
    showGlobalFormValidation: PropTypes.func.isRequired,
    submitRequisition: PropTypes.func.isRequired,
};

export default RequisitionsCreateNavButtons;

import qs from 'qs';
import { browserHistory } from '@og-pro-migration-tools/react-router';
import { arrayPush, arrayRemove } from 'redux-form';
import { v4 as UUIDv4 } from 'uuid';

import { attachmentTypesDict } from '@og-pro/shared-config/attachments';

import { proposalStatusesDict } from '@og-pro/shared-config/proposals';
import rollbar from '@og-pro/rollbar/client';

import { showSaveError, showSaveModal, showSaveSuccess } from './app';
import {
    showConfirmationModal,
    hideConfirmationModal,
    updatingConfirmationModal,
    showConfirmationModalError,
} from './confirmation';
import { showSnackbar, showNotification } from './notification';
import { emitVendorProposalSocket, emptyStringSerializer, reloadThrottler } from './utils';
import * as proposalActions from '../constants/proposalActions';
import { fieldsToClean } from '../containers/VendorApp/ProposalCreate';
import { trackEvent } from '../helpers';
import request from '../request';

const { PROPOSAL_DOCUMENT } = attachmentTypesDict;
const { DRAFT, PUBLISHED } = proposalStatusesDict;

export const LOAD_ALL = 'vend/proposals/LOAD_ALL';
export const LOAD_ALL_SUCCESS = 'vend/proposals/LOAD_ALL_SUCCESS';
export const LOAD_ALL_FAIL = 'vend/proposals/LOAD_ALL_FAIL';
export const LOAD_FOLLOWED_PROJECTS_SUCCESS = 'vend/proposals/LOAD_FOLLOWED_PROJECTS_SUCCESS';

export const LOAD_AWARDED_CONTRACTS = 'vendor/awards/LOAD_AWARDED_CONTRACTS';
export const LOAD_AWARDED_CONTRACTS_SUCCESS = 'vendor/awards/LOAD_AWARDED_CONTRACTS_SUCCESS';
export const LOAD_AWARDED_CONTRACTS_FAIL = 'vendor/awards/LOAD_AWARDED_CONTRACTS_FAIL';

export const LOAD_AWARDED_PROJECTS = 'vendor/awards/LOAD_AWARDED_PROJECTS';
export const LOAD_AWARDED_PROJECTS_SUCCESS = 'vendor/awards/LOAD_AWARDED_PROJECTS_SUCCESS';
export const LOAD_AWARDED_PROJECTS_FAIL = 'vendor/awards/LOAD_AWARDED_PROJECTS_FAIL';

// Not used by a reducer. Only used by sync server to broadcast proposal create form sync event.
export const UPDATE_QUESTIONNAIRE_RESPONSE = 'vend/proposals/UPDATE_QUESTIONNAIRE_RESPONSE';

export function loadAllProposals(url) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_ALL });
        return client
            .get(url)
            .then((result) => {
                dispatch({ type: LOAD_ALL_SUCCESS, result });
            })
            .catch((error) => dispatch({ type: LOAD_ALL_FAIL, error }));
    };
}

export function vendLoadAll(vendorId) {
    return loadAllProposals(`/vendor/${vendorId}/proposal`);
}
export function vendUserLoadAll() {
    return loadAllProposals('/user/me/proposal');
}
export function vendUserLoadAllShared() {
    return loadAllProposals('/user/me/shared-proposal');
}

export function vendUserLoadProjectSubscriptions() {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_ALL });
        return client
            .get('/user/me/followed-projects')
            .then((result) => {
                dispatch({ type: LOAD_FOLLOWED_PROJECTS_SUCCESS, result });
            })
            .catch((error) => dispatch({ type: LOAD_ALL_FAIL, error }));
    };
}

export function filterProposals(filter, vendorId) {
    switch (filter) {
        case 'user':
            return vendUserLoadAll();
        case 'all':
            return vendLoadAll(vendorId);
        case 'shared':
            return vendUserLoadAllShared();
        case 'following':
            return vendUserLoadProjectSubscriptions();
        default:
            return vendUserLoadAll();
    }
}

export function shouldLoadProposal(state, proposalId) {
    if (state.vendProposals.getIn(['proposal', 'loading'])) return false;
    return state.vendProposals.getIn(['proposal', 'id']) !== proposalId;
}

export const LOAD = 'vend/proposals/LOAD';
export const LOAD_SUCCESS = 'vend/proposals/LOAD_SUCCESS';
export const LOAD_FAIL = 'vend/proposals/LOAD_FAIL';

export function loadProposal(proposalId) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD });
        return client
            .get(`/proposal/${proposalId}`)
            .then((result) => {
                dispatch({ type: LOAD_SUCCESS, result });
            })
            .catch((error) => dispatch({ type: LOAD_FAIL, error }));
    };
}

export const RESET_PROPOSAL = 'vend/proposals/RESET_PROPOSAL';

export function resetProposal() {
    return { type: RESET_PROPOSAL };
}

export const DELETE_PROPOSAL = 'vend/proposals/DELETE_PROPOSAL';
export const DELETE_PROPOSAL_SUCCESS = 'vend/proposals/DELETE_PROPOSAL_SUCCESS';
export const DELETE_PROPOSAL_FAIL = 'vend/proposals/DELETE_PROPOSAL_FAIL';

export function deleteProposal(proposalId, opts = {}) {
    return (dispatch, getState, client) => {
        dispatch({ type: DELETE_PROPOSAL });
        if (opts.modal) {
            dispatch(updatingConfirmationModal());
        }
        return client
            .del(`/proposal/${proposalId}`)
            .then(() => {
                if (opts.modal) {
                    dispatch(hideConfirmationModal());
                    const vendorId = getState().auth.getIn(['user', 'vendor_id']);
                    const proposalListPath = `/vendors/${vendorId}/proposals`;
                    browserHistory.push(proposalListPath);
                }
                dispatch({ type: DELETE_PROPOSAL_SUCCESS, proposalId });
                dispatch(showNotification('Response successfully deleted'));
            })
            .catch((error) => {
                dispatch({ type: DELETE_PROPOSAL_FAIL, error });
                if (opts.modal) {
                    dispatch(
                        showConfirmationModalError(
                            `Response could not be deleted: ${error.message}`
                        )
                    );
                }
            });
    };
}

export function menuActionHandler(actionType, proposal) {
    return (dispatch) => {
        const { vendor_id: vendorId, id } = proposal;
        switch (actionType) {
            case proposalActions.EDIT_PROPOSAL: {
                const editRoute = `/vendors/${vendorId}/proposals/${id}/edit`;
                return browserHistory.push(editRoute);
            }
            case proposalActions.VIEW_PROPOSAL: {
                const viewRoute = `/vendors/${vendorId}/proposals/${id}`;
                return browserHistory.push(viewRoute);
            }
            case proposalActions.DELETE_PROPOSAL:
                return dispatch(showConfirmationModal(actionType, { proposal }));
            default:
                return null;
        }
    };
}

export const UPDATE_PROPOSAL = 'vend/proposals/UPDATE_PROPOSAL';
export const UPDATE_PROPOSAL_SUCCESS = 'vend/proposals/UPDATE_PROPOSAL_SUCCESS';
export const UPDATE_PROPOSAL_FAIL = 'vend/proposals/UPDATE_PROPOSAL_FAIL';

export function updateProposal(proposalId, formData, opts = {}) {
    return (dispatch, getState, client) => {
        const data = emptyStringSerializer(formData, fieldsToClean);

        if (opts.modal) {
            dispatch(updatingConfirmationModal());
        } else if (opts.notify) {
            dispatch(showSaveModal());
        }

        dispatch({ type: UPDATE_PROPOSAL });

        return client
            .put(`/proposal/${proposalId}`, { data })
            .then((result) => {
                const updateAction = { type: UPDATE_PROPOSAL_SUCCESS, result };
                const formAction = { type: UPDATE_PROPOSAL_SUCCESS };
                dispatch(emitVendorProposalSocket(result.id, updateAction, formAction));

                if (opts.modal) {
                    dispatch(hideConfirmationModal());
                } else if (opts.notify) {
                    dispatch(showSaveSuccess());
                }
                if (opts.onUpdate) {
                    opts.onUpdate(result);
                }
            })
            .catch((error) => {
                if (opts.modal) {
                    dispatch(showConfirmationModalError(error.message));
                } else if (opts.notify) {
                    dispatch(showSaveError(error.message));
                }
                dispatch({ type: UPDATE_PROPOSAL_FAIL, error });
                return error;
            });
    };
}

export const CONFIRM_ADDENDUM_SUCCESS = 'vend/proposals/CONFIRM_ADDENDUM_SUCCESS';

export function confirmAddendum(proposalId, addendumId) {
    return (dispatch, getState, client) => {
        return client.put(`/proposal/${proposalId}/addendums/${addendumId}`).then((addendum) => {
            const message = `Addendum #${addendum.number} confirmed`;
            const updateAction = { type: CONFIRM_ADDENDUM_SUCCESS, addendum };
            const formAction = {
                type: CONFIRM_ADDENDUM_SUCCESS,
                data: addendum,
            };
            dispatch(emitVendorProposalSocket(proposalId, updateAction, formAction, message));
            dispatch(showSnackbar(message));
            return addendum;
        });
    };
}

export function submitProposal(proposalId, formData) {
    return (dispatch) => {
        const submitData = {
            ...formData,
            status: PUBLISHED,
        };

        const onUpdate = (updatedProposal) => {
            trackEvent('Vendor Proposal Submitted');

            // Show the proposal review page
            const { id, vendor_id: vendorId } = updatedProposal;
            const nextRoute = `/vendors/${vendorId}/proposals/${id}`;
            browserHistory.push(nextRoute);
        };

        return dispatch(updateProposal(proposalId, submitData, { onUpdate }));
    };
}

export function unsubmitProposal(proposal) {
    return (dispatch) => {
        // Force an edit of the published proposal
        const data = {
            status: DRAFT,
            force: true,
        };

        const onUpdate = (updatedProposal) => {
            trackEvent('Vendor Proposal Unsubmitted');

            // Show the proposal review page
            const { id, vendor_id: vendorId } = updatedProposal;
            const nextRoute = `/vendors/${vendorId}/proposals/${id}/edit`;
            browserHistory.push(nextRoute);
        };

        return dispatch(updateProposal(proposal.id, data, { onUpdate }));
    };
}

export const START_UPLOAD = 'vend/proposals/START_UPLOAD';
export const UPLOAD_PROPOSAL = 'vend/proposals/UPLOAD_PROPOSAL';
export const UPLOAD_PROPOSAL_PROGRESS = 'vend/proposals/UPLOAD_PROPOSAL_PROGRESS';
export const UPLOAD_PROPOSAL_SUCCESS = 'vend/proposals/UPLOAD_PROPOSAL_SUCCESS';
export const UPLOAD_PROPOSAL_FAIL = 'vend/proposals/UPLOAD_PROPOSAL_FAIL';
export const COMPLETE_UPLOAD = 'vend/proposals/COMPLETE_UPLOAD';

export const CREATE_ATTACHMENT = 'vend/proposals/CREATE_ATTACHMENT';
export const CREATE_ATTACHMENT_SUCCESS = 'vend/proposals/CREATE_ATTACHMENT_SUCCESS';
export const CREATE_ATTACHMENT_FAIL = 'vend/proposals/CREATE_ATTACHMENT_FAIL';

export function createProposalAttachment(proposalId, formData, file) {
    return (dispatch, getState, client) => {
        // Temporary ID to use in identifying the upload before it is saved
        const uploadId = UUIDv4();
        const { proposalDocumentId, form, formKey } = formData;

        const uploadData = {
            id: uploadId,
            fileName: file.name,
            proposal_document_id: proposalDocumentId,
        };

        const filename = encodeURIComponent(file.name);
        const contentType = encodeURIComponent(file.type);

        const proposalSignedS3Endpoint = `/proposal/${proposalId}/s3?filename=${filename}&contentType=${contentType}`;

        // Add the upload item field to the upload list
        dispatch({ type: START_UPLOAD, result: uploadData });
        // Start the upload
        dispatch({ type: UPLOAD_PROPOSAL, uploadId });

        let signedData;
        // Get the a pre-signed s3 PUT url from OpenGov Procurement API
        return client
            .get(proposalSignedS3Endpoint)
            .then((result) => {
                signedData = result;
                const postData = {
                    data: file,
                    headers: { 'Content-Type': file.type },
                    onProgress: (e) => {
                        dispatch({
                            type: UPLOAD_PROPOSAL_PROGRESS,
                            progress: e.percent,
                            uploadId,
                        });
                    },
                };
                // Write to the s3 API
                return request.put(result.signedPutUrl, postData);
            })
            .then(() => {
                // Set progress to 100% on the upload
                dispatch({ type: UPLOAD_PROPOSAL_SUCCESS, uploadId });
                const data = {
                    bucket: signedData.bucket,
                    path: signedData.key,
                    filename: signedData.filename,
                    proposalDocumentId,
                    type: PROPOSAL_DOCUMENT,
                };

                // Start the creating of attachment
                dispatch({ type: CREATE_ATTACHMENT, uploadId });
                const apiRoute = `/proposal/${proposalId}/attachment`;

                // Create the attachment using the s3 file info
                return client.post(apiRoute, { data });
            })
            .then((result) => {
                // Remove the upload item field from the upload list
                dispatch({ type: COMPLETE_UPLOAD, uploadId });
                // Add newly created attachment to proposal
                dispatch({ type: CREATE_ATTACHMENT_SUCCESS, result });
                // Update the form with the new value
                dispatch(arrayPush(form, formKey, result));
            })
            .catch((error) => {
                dispatch({ type: UPLOAD_PROPOSAL_FAIL, error, uploadId });
            });
    };
}

export const DELETE_ATTACHMENT = 'vend/proposals/DELETE_ATTACHMENT';
export const DELETE_ATTACHMENT_SUCCESS = 'vend/proposals/DELETE_ATTACHMENT_SUCCESS';
export const DELETE_ATTACHMENT_FAIL = 'vend/proposals/DELETE_ATTACHMENT_FAIL';

export function deleteProposalAttachment(proposalId, formData, attachmentId) {
    return (dispatch, getState, client) => {
        const { form, formKey, index } = formData;
        dispatch({ type: DELETE_ATTACHMENT, attachmentId });
        return client
            .del(`/proposal/${proposalId}/attachment/${attachmentId}`)
            .then(() => {
                dispatch({ type: DELETE_ATTACHMENT_SUCCESS, attachmentId });
                // Update the form by removing the value
                dispatch(arrayRemove(form, formKey, index));
            })
            .catch((error) => {
                dispatch({ type: DELETE_ATTACHMENT_FAIL, error, attachmentId });
            });
    };
}

export const TOGGLE_FULL_WIDTH_VIEW = 'vend/proposals/TOGGLE_FULL_WIDTH_VIEW';

export function toggleFullWidthView(useFullWidthView) {
    return { type: TOGGLE_FULL_WIDTH_VIEW, useFullWidthView };
}

export function createNotaryLiveOrder(proposalId, orderData) {
    return (dispatch, getState, client) => {
        return client.post(`/proposal/${proposalId}/notary-live`, { data: orderData });
    };
}

export function getQuestionnaireResponse(proposalId, questionnaireId) {
    return (dispatch, getState, client) => {
        return client.get(`/proposal/${proposalId}/questionnaire-response/${questionnaireId}`);
    };
}

export function updateQuestionnaireResponse(proposalId, data) {
    return (dispatch, getState, client) => {
        return client
            .post(`/proposal/${proposalId}/questionnaire-response`, { data })
            .then(() => dispatch(showSnackbar('Response Updated')))
            .catch(() => dispatch(showSnackbar('Response Update Failed!'), { isError: true }));
    };
}

export function verifyBidBond(proposalId, data, opts = {}) {
    return (dispatch, getState, client) => {
        const verify = () => {
            return client.post(`/proposal/${proposalId}/surety2000`, { data }).catch((e) =>
                dispatch(showSnackbar(`Verification Failed: ${e.message}`), {
                    isError: true,
                })
            );
        };

        if (opts.updateResponse) {
            return dispatch(
                updateQuestionnaireResponse(proposalId, {
                    data,
                    questionnaire_id: data.questionnaireId,
                })
            ).then(() => verify());
        }

        return verify();
    };
}

export function loadPublicAwardedContracts(checklistsFilter) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_AWARDED_CONTRACTS });
        return client
            .get(`/user/me/awards/contracts?${qs.stringify({ checklistsFilter })}`)
            .then((result) => {
                dispatch({ type: LOAD_AWARDED_CONTRACTS_SUCCESS, result });
            })
            .catch((error) => dispatch({ type: LOAD_AWARDED_CONTRACTS_FAIL, error }));
    };
}

export function loadPublicAwardedProjects() {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_AWARDED_PROJECTS });
        return client
            .get('/user/me/awards/projects')
            .then((result) => {
                dispatch({ type: LOAD_AWARDED_PROJECTS_SUCCESS, result });
            })
            .catch((error) => dispatch({ type: LOAD_AWARDED_PROJECTS_FAIL, error }));
    };
}

const shouldThrottleProposalReload = reloadThrottler();

export function reloadVendorProposal(proposalId) {
    return (dispatch, getState, client) => {
        const lastUpdated = getState().vendProposals.getIn(['proposal', 'updated_at']);

        // In theory, this value should always exist, but check just to be sure to prevent an
        // infinite loop
        if (!lastUpdated) {
            return;
        }

        const shouldThrottle = shouldThrottleProposalReload(proposalId);
        if (shouldThrottle) {
            rollbar.info(`Stopping reload on proposal ${proposalId}`, {
                location: window?.location?.href,
            });
            return;
        }

        return client
            .get(`/proposal/${proposalId}`)
            .then((result) => {
                // Reload the proposal if an update has occurred
                if (result.updated_at !== lastUpdated) {
                    dispatch(showSnackbar('Received New Updates', { dismissAfter: 3500 }));
                    return dispatch(loadProposal(proposalId));
                }
            })
            .catch(() => {}); // Catch error to avoid it being unhandled, but don't need to do anything with it
    };
}

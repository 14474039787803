import { UPFRONT_QUESTIONS } from './constants';
import { questionnaireResponse, questionnaireResponseComplete } from '../../../Forms/validation';

export const validate = (values) => {
    const errors = {};

    errors[UPFRONT_QUESTIONS] = (values[UPFRONT_QUESTIONS] || []).map((question) => {
        return questionnaireResponse(question, { isUpfrontQuestion: true });
    });

    return errors;
};

export const warn = (values) => {
    const warnings = {};

    if (values[UPFRONT_QUESTIONS]) {
        warnings[UPFRONT_QUESTIONS] = values[UPFRONT_QUESTIONS].map((question) => {
            return questionnaireResponseComplete(question, {
                isUpfrontQuestion: true,
                optional: true,
            });
        });
    }

    return warnings;
};

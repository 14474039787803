import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { requisitionTabsNames } from '@og-pro/shared-config/requisitions';
import { TabsNavigation } from '@og-pro/ui';
import { Box } from '@mui/material';

import { getInitialTabs } from '../helpers';
import { useQueryParam } from '../../../../../hooks';
import { getIsGroupAdminOrLeader, getRequisitionsTabsData } from '../selectors';
import { RequisitionAccountsLink } from '../RequisitionAccountsLink';
import { useChartInteraction } from '../Visualizations/hooks/useChartInteraction';

const { MY_APPROVALS, ALL_REQUESTS } = requisitionTabsNames;

export const RequisitionsListHeader = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const isAdminOrLeader = useSelector((state) => getIsGroupAdminOrLeader(state));
    const requisitionTabsData = useSelector((state) => getRequisitionsTabsData(state));

    const tabs = useMemo(
        () => getInitialTabs(isAdminOrLeader, location, requisitionTabsData),
        [isAdminOrLeader, location]
    );

    const defaultTab = isAdminOrLeader ? ALL_REQUESTS : MY_APPROVALS;
    const [queryTab] = useQueryParam('tab', defaultTab);

    const { hasChartInteraction } = useChartInteraction();

    return (
        <Box
            display="flex"
            sx={{
                flexDirection: {
                    xs: 'column',
                    md: 'row',
                },
            }}
        >
            <Box
                sx={{
                    width: {
                        xs: '100%',
                        md: '67%',
                    },
                }}
            >
                {!hasChartInteraction && (
                    <TabsNavigation
                        defaultTab={tabs.find((tab) => tab.route.includes(queryTab)) || tabs[0]}
                        pathname={location.pathname}
                        redirectFn={navigate}
                        tabs={tabs}
                    />
                )}
            </Box>
            <Box
                pt={{
                    xs: 0,
                    md: 1,
                }}
                sx={{
                    width: {
                        xs: '100%',
                        md: '33%',
                    },
                    marginLeft: {
                        xs: '0',
                        md: '22px',
                    },
                }}
                textAlign={{
                    xs: 'center',
                    md: 'right',
                }}
            >
                <RequisitionAccountsLink />
            </Box>
        </Box>
    );
};

import { buildQaTag, componentNames } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React from 'react';
import { NavItem as NavItemBS } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

export const NavItem = ({ className, overrideIsActive = undefined, qaTag, to, ...props }) => {
    const tagWithComponentName = buildQaTag(qaTag, componentNames.NAV_ITEM);

    if (to) {
        // if we have a url, we want to use react-router
        return (
            <li className={className} role="presentation">
                <NavLink
                    className={({ isActive }) => {
                        if (overrideIsActive !== undefined) {
                            return overrideIsActive ? 'active' : '';
                        }

                        return isActive ? 'active' : '';
                    }}
                    data-qa={tagWithComponentName}
                    end
                    to={to}
                    {...props}
                />
            </li>
        );
    }

    // otherwise fallback to what we were doing before
    return <NavItemBS className={className} data-qa={tagWithComponentName} {...props} />;
};

NavItem.propTypes = {
    overrideIsActive: PropTypes.bool,
    className: PropTypes.string,
    qaTag: PropTypes.string,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

import React, { createContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useFlags, FLAGS } from '@og-pro/launch-darkly/client';

import { useLoadCustomFormSnapshot } from '../../../../lib/customFormService/useLoadCustomFormSnapshot';
import { LoadingError, LoadingSpinner } from '../../../../components';

export const CustomFormSnapshotContext = createContext({
    customFields: [],
});

export const CustomFormSnapshotProvider = ({ children, version, customFormId }) => {
    const isCustomFieldsFlagEnabled = useFlags(FLAGS.ENABLE_CUSTOM_FIELDS);
    const hasCustomForm = isCustomFieldsFlagEnabled && customFormId !== null && version !== null;
    const { data, isLoading, isError, error } = useLoadCustomFormSnapshot(customFormId, version);

    const customFields = useMemo(() => {
        if (!hasCustomForm) {
            return [];
        }
        return data?.getCustomFormSnapshot?.fields || [];
    }, [hasCustomForm, data]);

    if (!isCustomFieldsFlagEnabled) {
        return (
            <CustomFormSnapshotContext.Provider
                value={{
                    customFields,
                }}
            >
                {children}
            </CustomFormSnapshotContext.Provider>
        );
    }

    if (isLoading && hasCustomForm) {
        return <LoadingSpinner useOpenGovStyle />;
    }

    if (isError) {
        return <LoadingError error={error.message} useOpenGovStyle />;
    }

    return (
        <CustomFormSnapshotContext.Provider
            value={{
                customFields,
            }}
        >
            {children}
        </CustomFormSnapshotContext.Provider>
    );
};

CustomFormSnapshotProvider.propTypes = {
    customFormId: PropTypes.number,
    version: PropTypes.number,
    children: PropTypes.node.isRequired,
};

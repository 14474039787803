import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { extendSimpleWizardForm } from '../../../../hocs';
import { formConfig, form, progressSectionsDict, fieldNames } from '../constants';
import { mapStateToProps as evaluationCreateMapStateToProps } from '../mapProps';
import { ProjectInformationForm, SectionTitle } from '../../../../components';
import { attachmentsHelp } from './constants';

const { CONTRACTOR_SELECTED_DATE, PROPOSAL_DEADLINE, RELEASE_PROJECT_DATE } = fieldNames;

const formSelector = formValueSelector(form);

const mapStateToProps = (state) => {
    return {
        ...evaluationCreateMapStateToProps(state),
        releaseProjectDateValue: formSelector(state, RELEASE_PROJECT_DATE),
        proposalDealineValue: formSelector(state, PROPOSAL_DEADLINE),
        contractorSelectedDateValue: formSelector(state, CONTRACTOR_SELECTED_DATE),
    };
};

// @connect
// @reduxForm
// @extendSimpleWizardForm
class ConnectedProjectOverview extends Component {
    static propTypes = {
        change: PropTypes.func.isRequired,
        contractorSelectedDateValue: PropTypes.instanceOf(Date),
        project: PropTypes.object.isRequired,
        proposalDealineValue: PropTypes.instanceOf(Date),
        releaseProjectDateValue: PropTypes.instanceOf(Date),
        showFormErrors: PropTypes.bool,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
        updating: PropTypes.bool,
    };

    static defaultProps = {
        showFormErrors: false,
        tagOptions: undefined,
        templateVariableOptions: undefined,
        updating: false,
    };

    render() {
        const {
            contractorSelectedDateValue,
            change,
            project,
            proposalDealineValue,
            releaseProjectDateValue,
            showFormErrors,
            tagOptions,
            templateVariableOptions,
            updating,
        } = this.props;

        return (
            <div className="row">
                <div className="col-sm-offset-1 col-sm-10">
                    <SectionTitle
                        info="Information about the project to evaluate"
                        title="Project Overview"
                    />
                    <ProjectInformationForm
                        attachmentsHelpText={attachmentsHelp}
                        change={change}
                        contractorSelectedDateValue={contractorSelectedDateValue}
                        form={form}
                        project={project}
                        proposalDealineValue={proposalDealineValue}
                        releaseProjectDateValue={releaseProjectDateValue}
                        showDates
                        showFormErrors={showFormErrors}
                        tagOptions={tagOptions}
                        templateVariableOptions={templateVariableOptions}
                        updating={updating}
                    />
                </div>
            </div>
        );
    }
}

export const ProjectOverview = compose(
    connect(mapStateToProps),
    reduxForm(formConfig),
    extendSimpleWizardForm(progressSectionsDict.OVERVIEW)
)(ConnectedProjectOverview);

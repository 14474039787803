import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Typography, Box } from '@og-pro/ui';

export const SourcingStatusFilter = ({ dataKey, formData, setFormData }) => {
    return (
        <Box alignItems="center" display="flex" gap={0.5}>
            <Switch
                checked={formData[dataKey]}
                onChange={() =>
                    setFormData((prevState) => ({
                        ...prevState,
                        [dataKey]: !prevState[dataKey],
                    }))
                }
                size="small"
            />
            <Typography variant="bodySmall">Only Show Requests with Active Sourcing</Typography>
        </Box>
    );
};

SourcingStatusFilter.propTypes = {
    dataKey: PropTypes.string.isRequired,
    formData: PropTypes.object.isRequired,
    setFormData: PropTypes.func.isRequired,
};

import React from 'react';
import { Panel } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useParams } from 'react-router-dom';

import { getNavItems } from './selectors';
import { getTemplatesAdminPath } from '../selectors';
import { Nav, NavItem } from '../../../../components';

export const TemplateAdminNav = () => {
    const location = useLocation();
    const params = useParams();
    const navItems = useSelector((state) => getNavItems(state, { location, params }));
    const templatesAdminPath = useSelector((state) =>
        getTemplatesAdminPath(state, { location, params })
    );

    const styles = require('./index.scss');

    const NavItems = navItems.map(({ isActive, path, title }, index) => {
        return (
            <NavItem
                key={index}
                overrideIsActive={isActive}
                qaTag={`templateAdminNav-${title}`}
                to={`${templatesAdminPath}${path}`}
            >
                {title}
            </NavItem>
        );
    });

    return (
        <>
            <h1 className="visually-hidden">Templates Admin</h1>
            <Panel>
                <Panel.Body>
                    <Nav bsStyle="tabs" className={styles.navItems} justified>
                        {NavItems}
                    </Nav>
                    <Outlet />
                </Panel.Body>
            </Panel>
        </>
    );
};

import moment from 'moment';

export const calculateTimeRemaining = (endTime) => {
    const now = moment();

    return moment(endTime).diff(now);
};

/**
 *
 * @param {object} duration Moment object that gets length of time for different time units
 * @param {string} timerStyle Indicates whether timer is text or digital style
 * @returns {object} Map with values for days, hours, minutes, seconds
 */
export const getTimeUnits = (duration, timerStyle) => {
    const timeUnits = ['days', 'hours', 'minutes', 'seconds'];
    const calculatedTimeUnits = {};

    const timeUnitFunctionMap = {
        days: () => Math.floor(duration.asDays()),
        hours: () => duration.hours(),
        minutes: () => duration.minutes(),
        seconds: () => duration.seconds(),
    };

    timeUnits.forEach((unit) => {
        if (duration < 0) {
            calculatedTimeUnits[unit] = timerStyle === 'digital' ? '0'.padStart(2, '0') : 0;
        } else {
            const timeUnitValue = timeUnitFunctionMap[unit]();

            if (timerStyle === 'digital') {
                calculatedTimeUnits[unit] = timeUnitValue.toString().padStart(2, '0');
            } else {
                calculatedTimeUnits[unit] = timeUnitValue;
            }
        }
    });

    return calculatedTimeUnits;
};

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Fade, ListGroupItem } from 'react-bootstrap';
import { Box } from '@og-pro/ui';

import { qaTagName } from './constants';
import { Button, ContactAddress } from '../../../components';

export const VendorCard = ({ timeout, vendor }) => {
    const { address, city, description, stateAbbreviation, vendorName, website, zipCode } = vendor;

    const [show, setShow] = useState(false);

    // This logic along with the `timeout` prop is here to make the items fade in one at a time.
    // It's purely for display effect.
    useEffect(() => {
        setTimeout(() => setShow(true), timeout);
    }, []);

    return (
        <Fade in={show}>
            <ListGroupItem>
                <div>
                    <strong>{vendorName}</strong>
                </div>
                {website && (
                    <div>
                        <Button
                            bsSize="sm"
                            bsStyle="link"
                            href={website}
                            qaTag={`${qaTagName}-website`}
                            rel="noreferrer"
                            target="_blank"
                            zeroPadding
                        >
                            {website} <i className="fa fa-external-link" />
                        </Button>
                    </div>
                )}
                <ContactAddress
                    address1={address}
                    city={city}
                    state={stateAbbreviation}
                    zipCode={zipCode}
                />
                <Box className="text-muted" mt={0.5}>
                    <em>{description}</em>
                </Box>
            </ListGroupItem>
        </Fade>
    );
};

VendorCard.propTypes = {
    timeout: PropTypes.number.isRequired,
    vendor: PropTypes.object.isRequired,
};

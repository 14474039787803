import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { defaultSectionConfigsMap, sectionTypeNames } from '@og-pro/shared-config/sections';

import { fieldNames } from '../constants';
import { ProjectSectionsEditItem } from '../ProjectSectionsEditItem';
import { getDndStyle } from '../../../constants/styles';

const { ORDER_BY_ID } = fieldNames;

const { DIVIDER } = sectionTypeNames;

export class ProjectSectionsEditFieldArray extends Component {
    static propTypes = {
        change: PropTypes.func.isRequired,
        copyProjectSection: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        fields: PropTypes.object.isRequired,
        meta: PropTypes.object.isRequired,
        useManualNumbering: PropTypes.bool,
        useSectionDividers: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        // Should not be possible to render modal on server, but just in case (`document` won't exist on server)
        if (!process.env.SERVER) {
            this.portal = document.createElement('div');
            this.portal.classList.add('project-sections-edit-portal');

            document.body.appendChild(this.portal);
        }
    }

    componentWillUnmount() {
        document.body.removeChild(this.portal);
    }

    handleDragEnd = (result) => {
        const { change, fields } = this.props;

        const originLocation = result.source.index;
        const newLocation = result.destination ? result.destination.index : undefined;

        if (newLocation !== undefined && newLocation !== originLocation) {
            fields.move(originLocation, newLocation);

            // Hack to make sure the `move` function completes before re-numbering
            setTimeout(() => {
                fields.forEach((name, index) => {
                    change(`${name}.${ORDER_BY_ID}`, index + 1);
                });
            });
        }
    };

    render() {
        const {
            copyProjectSection,
            disabled,
            fields,
            meta,
            useManualNumbering,
            useSectionDividers,
        } = this.props;

        const projectSections = fields.map((member, index) => {
            const projectSection = fields.get(index);
            const {
                id: projectSectionId,
                isHiddenByLogic,
                section_type: sectionType,
            } = projectSection;
            const section = defaultSectionConfigsMap[sectionType];
            const sharedProps = {
                copyProjectSection,
                disabled,
                index,
                isHiddenByLogic,
                member,
                portal: this.portal,
                projectSectionId,
                section,
                useManualNumbering,
                useSectionDividers,
            };

            // The first section divider cannot be dragged or dropped above.
            // Drag and drop is also disabled when manual numbering is used.
            if (
                (useSectionDividers && index === 0 && sectionType === DIVIDER) ||
                useManualNumbering
            ) {
                return (
                    <ProjectSectionsEditItem
                        {...sharedProps}
                        key={projectSectionId}
                        provided={{}}
                        snapshot={{}}
                    />
                );
            }

            return (
                <Draggable
                    draggableId={`projectSectionItem:${projectSectionId}`}
                    index={index}
                    key={projectSectionId}
                >
                    {(provided, snapshot) => (
                        <ProjectSectionsEditItem
                            {...sharedProps}
                            provided={provided}
                            snapshot={snapshot}
                        />
                    )}
                </Draggable>
            );
        });

        return (
            <>
                <DragDropContext onDragEnd={this.handleDragEnd}>
                    <Droppable droppableId="project-sections-edit-modal" isDropDisabled={disabled}>
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                style={getDndStyle(snapshot)}
                                {...provided.droppableProps}
                            >
                                {projectSections}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                {meta.error && <div className="text-danger text-center">{meta.error}</div>}
            </>
        );
    }
}

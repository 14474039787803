import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { stepStatusHelper } from './helpers';
import { getStepRequisitionLogsDisplay } from './selectors';
import { SequenceStep } from '../SequenceStep';
import { SubmittedStepInfo } from './SubmittedStepInfo';

export const SubmittedStep = ({ requisition }) => {
    const styles = require('./index.scss');
    const { className, icon, stepName } = stepStatusHelper(requisition);
    const requisitionLogs = useSelector(getStepRequisitionLogsDisplay);

    return (
        <SequenceStep
            requisitionLogs={requisitionLogs}
            stepName={stepName}
            stepStatusIcon={icon}
            stepStatusIconClassName={styles[className]}
        >
            <SubmittedStepInfo requisition={requisition} />
        </SequenceStep>
    );
};

SubmittedStep.propTypes = {
    requisition: PropTypes.object.isRequired,
};

import moment from 'moment-timezone';
import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import PropTypes from 'prop-types';

import { currencyFormatter } from '../../../../../../helpers';

export const LineItemBidTimelineChart = ({ auctionMaxFractionDigits, series, timezone }) => {
    const vizColors = require('../../../../../../theme/cds-react/dataVizColors.scss');

    const renderBidTimelineChart = () => {
        return new Highcharts.Chart({
            chart: {
                renderTo: 'bid-timeline',
                type: 'spline',
                style: {
                    fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
                    fontSize: '16px',
                    lineHeight: '20px',
                },
            },
            colors: Object.values(vizColors),
            credits: {
                enabled: false,
            },
            legend: {
                align: 'center',
                verticalAlign: 'top',
                itemStyle: {
                    fontSize: '16px',
                },
            },
            plotOptions: {
                area: {
                    stacking: 'normal',
                    lineColor: '#666666',
                    lineWidth: 1,
                    marker: {
                        lineWidth: 1,
                        lineColor: '#666666',
                    },
                },
                series: {
                    marker: {
                        enabled: true,
                    },
                },
            },
            series,
            time: {
                moment,
                timezone,
            },
            title: {
                text: null,
            },
            tooltip: {
                split: true,
                valueDecimals: auctionMaxFractionDigits,
                valuePrefix: '$',
                xDateFormat: '%I:%M%P<br/>%a, %b %d, %Y',
            },
            xAxis: {
                type: 'datetime',
                labels: {
                    formatter({ value }) {
                        return Highcharts.dateFormat('%I:%M%P<br/>%m/%d', value);
                    },
                    style: {
                        fontSize: '12px',
                    },
                },
                title: {
                    text: 'Time',
                    style: {
                        color: '#777777',
                        fontWeight: 700,
                    },
                    margin: 15,
                },
            },
            yAxis: {
                gridLineColor: 'transparent',
                lineWidth: 1,
                labels: {
                    formatter({ value }) {
                        return currencyFormatter(
                            { value },
                            {
                                maximumFractionDigits: auctionMaxFractionDigits,
                                useSameMinAndMax: true,
                            }
                        );
                    },
                    style: {
                        fontSize: '12px',
                    },
                },
                title: {
                    text: 'Bid Amount (Unit Price)',
                    style: {
                        color: '#777777',
                        fontWeight: 700,
                    },
                    margin: 15,
                },
            },
        });
    };

    useEffect(() => {
        renderBidTimelineChart();
    }, [series]);

    return <div id="bid-timeline" />;
};

LineItemBidTimelineChart.propTypes = {
    auctionMaxFractionDigits: PropTypes.number.isRequired,
    series: PropTypes.array.isRequired,
    timezone: PropTypes.string.isRequired,
};

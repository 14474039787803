import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';

import { StandardDocumentModalButton } from '..';

export const ProposalViewerAgreementAccepted = ({ acceptedAt, standardDocument }) => {
    const styles = require('./index.scss');
    return (
        <div className="text-center">
            <StandardDocumentModalButton
                className={styles.proposalViewerAgreement}
                standardDocument={standardDocument}
            >
                <div className={`text-primary ${styles.acceptedText}`}>
                    <span className={`fa-stack ${styles.verified}`}>
                        <i className="fa fa-stack-2x fa-certificate" />
                        <i className="fa fa-stack-1x fa-inverse fa-check" />
                    </span>
                    Response Viewer Agreement Accepted
                </div>
                <div className={`text-muted ${styles.acceptedAt}`}>
                    <small>
                        <em>{moment(acceptedAt).format('lll')}</em>
                    </small>
                </div>
            </StandardDocumentModalButton>
        </div>
    );
};

ProposalViewerAgreementAccepted.propTypes = {
    acceptedAt: PropTypes.string.isRequired,
    standardDocument: PropTypes.object.isRequired,
};

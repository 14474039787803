import { fromJS } from 'immutable';

import * as projectActions from '../actions/exportProject';

const initialState = fromJS({
    error: null,
    exportData: {},
    filename: null,
    googleUrl: null,
    docxHeadingFormatters: {},
    loaded: false,
    loadedDocument: false,
    loading: false,
    loadingDocument: false,
    pdfUrl: null,
    saveOnExport: false,
    showModal: false,
    wordUrl: null,
    zipFilename: null,
    zipUrl: null,
});

export default function exportProjectReducer(state = initialState, action = {}) {
    switch (action.type) {
        case projectActions.SET_EXPORT_DATA:
            return state.set('exportData', fromJS(action.data));
        case projectActions.LOAD:
            return state.merge(
                fromJS({
                    loading: true,
                    error: null,
                })
            );
        case projectActions.LOAD_SUCCESS:
            return state.merge(
                fromJS({
                    loading: false,
                    zipUrl: action.zipUrl,
                    zipFilename: action.zipFilename,
                    loaded: true,
                    error: null,
                })
            );
        case projectActions.LOAD_FAIL:
            return state.merge(
                fromJS({
                    loading: false,
                    error: action.error && action.error.message,
                })
            );
        case projectActions.LOAD_DOCUMENT:
            return state.merge(
                fromJS({
                    loadingDocument: true,
                    error: null,
                })
            );
        case projectActions.LOAD_DOCUMENT_SUCCESS:
            return state.merge(
                fromJS({
                    filename: action.filename,
                    googleUrl: action.googleUrl,
                    loadedDocument: true,
                    loadingDocument: false,
                    pdfUrl: action.pdfUrl,
                    wordUrl: action.wordUrl,
                    error: null,
                })
            );
        case projectActions.LOAD_DOCUMENT_FAIL:
            return state.merge(
                fromJS({
                    loadingDocument: false,
                    error: action.error && action.error.message,
                })
            );
        case projectActions.SHOW_MODAL:
            return state.merge(
                fromJS({
                    saveOnExport: action.saveOnExport,
                    sectionId: action.sectionId,
                    showModal: action.text || true,
                    error: null,
                })
            );
        case projectActions.HIDE_MODAL:
            return initialState;
        case projectActions.LOAD_DOCX_HEADING_FORMATTERS_SUCCESS:
            return state.merge(
                fromJS({
                    docxHeadingFormatters: action.payload.headingFormatters || {},
                })
            );
        case projectActions.CONFIGURE_EXPORT:
            return state.merge(
                fromJS({
                    exportData: action.exportData,
                    sectionId: action.sectionId,
                })
            );
        default:
            return state;
    }
}

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import {
    BID_BOND,
    CONFIRMATION,
    CUSTOM_VARIABLE,
    DATA_VERIFY,
    DOWNLOAD,
    EXTERNAL_FORM,
    FILE_UPLOAD,
    LONG_ANSWER,
    MULTIPLE_CHOICE,
    NOTARIZE,
    questionnaireTypes,
    SECTION_HEADER,
    SHORT_ANSWER,
    YES_NO,
} from '@og-pro/shared-config/questionnaires';

import {
    BidBondDisplay,
    ConfirmationDisplay,
    CustomVariableDisplay,
    DataVerifyDisplay,
    ExternalFormDisplay,
    FileUploadDisplay,
    LongAnswerDisplay,
    MultipleChoiceDisplay,
    QuestionPrompt,
    ShortAnswerDisplay,
    YesNoDisplay,
} from './components';

export class QuestionnaireDisplay extends PureComponent {
    static propTypes = {
        type: PropTypes.oneOf(questionnaireTypes).isRequired,
    };

    render() {
        const { type, ...props } = this.props;

        switch (type) {
            case BID_BOND:
                return <BidBondDisplay {...props} />;
            case CONFIRMATION:
                return <ConfirmationDisplay {...props} />;
            case CUSTOM_VARIABLE:
                return <CustomVariableDisplay {...props} />;
            case DATA_VERIFY:
                return <DataVerifyDisplay {...props} />;
            case EXTERNAL_FORM:
                return <ExternalFormDisplay {...props} />;
            case FILE_UPLOAD:
                return <FileUploadDisplay {...props} />;
            case LONG_ANSWER:
                return <LongAnswerDisplay {...props} />;
            case MULTIPLE_CHOICE:
                return <MultipleChoiceDisplay {...props} />;
            case SHORT_ANSWER:
                return <ShortAnswerDisplay {...props} />;
            case YES_NO:
                return <YesNoDisplay {...props} />;
            case DOWNLOAD:
            case NOTARIZE:
            case SECTION_HEADER:
                return <QuestionPrompt {...props} />;
            default:
                return null;
        }
    }
}

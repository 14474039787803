import React, { Component } from 'react';
import { ListGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { CATEGORIES, createForm, updateForm } from './constants';
import { CreateCustomCategoryForm } from './CreateCustomCategoryForm';
import { CustomCategoryRowForm } from './CustomCategoryRowForm';
import { getCustomCategoryName } from './selectors';
import { getCustomCategoriesJS } from '../../GovApp/selectors';
import * as categoryActions from '../../../actions/category';
import { showConfirmationSimpleModal } from '../../../actions/confirmation';
import { Main, SectionTitle, Well } from '../../../components';

const mapStateToProps = (state) => {
    return {
        customCategories: getCustomCategoriesJS(state),
        customCategoryName: getCustomCategoryName(state),
    };
};

const mapDispatchToProps = {
    ...categoryActions,
    showConfirmationSimpleModal,
};

// @connect
class ConnectedCustomCategories extends Component {
    static propTypes = {
        createCustomCategory: PropTypes.func.isRequired,
        customCategories: PropTypes.arrayOf(
            PropTypes.shape({
                categories: PropTypes.array.isRequired,
                id: PropTypes.number.isRequired,
            })
        ).isRequired,
        customCategoryName: PropTypes.string,
        deleteCustomCategory: PropTypes.func.isRequired,
        setCustomCategory: PropTypes.func.isRequired,
        showConfirmationSimpleModal: PropTypes.func.isRequired,
    };

    renderCustomCategoryRow = (customCategory) => {
        const {
            deleteCustomCategory,
            setCustomCategory,
            showConfirmationSimpleModal: showModal,
        } = this.props;

        return (
            <CustomCategoryRowForm
                customCategory={customCategory}
                deleteCustomCategory={deleteCustomCategory}
                form={`${updateForm}:${customCategory.id}`}
                initialValues={{
                    [CATEGORIES]: customCategory.categories,
                }}
                key={customCategory.id}
                setCustomCategory={setCustomCategory}
                showConfirmationSimpleModal={showModal}
            />
        );
    };

    render() {
        const { createCustomCategory, customCategories, customCategoryName } = this.props;

        return (
            <Main className="row">
                <div className="col-md-offset-2 col-md-8">
                    <SectionTitle
                        info="Shortcuts for common groupings of category codes"
                        title="Custom Categories"
                    />
                    <Well>
                        <CreateCustomCategoryForm
                            createCustomCategory={createCustomCategory}
                            customCategoryName={customCategoryName}
                            form={createForm}
                        />
                    </Well>
                    <ListGroup>{customCategories.map(this.renderCustomCategoryRow)}</ListGroup>
                </div>
            </Main>
        );
    }
}

export const CustomCategories = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedCustomCategories);

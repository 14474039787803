import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';

import { form, PROJECT_TYPE, SECTION_TYPE, USER_ID } from './constants';
import { getSectionSelectOptions } from './helper';
import { validate } from './validate';
import {
    LoadingButton,
    SearchSelect,
    SearchSelectIconOption,
    SearchSelectIconValue,
    SearchSelectUserOption,
} from '../../../../../components';
import { getTemplateAdminUserList } from '../../../../../selectors/govApp';

const formConfig = {
    form,
    validate,
};

const ConnectedTemplateSectionCreateForm = ({
    change,
    createError,
    creating,
    handleSubmit,
    projectTypeSelectOptions,
}) => {
    const sectionType = useSelector((state) => formValueSelector(form)(state, PROJECT_TYPE));
    const userSelectOptions = useSelector(getTemplateAdminUserList);
    const sectionTypeOptions = getSectionSelectOptions(sectionType);

    return (
        <form onSubmit={handleSubmit}>
            <Field
                component={SearchSelect}
                disabled={creating}
                help="Cannot be edited after creation"
                label="Template Type"
                name={PROJECT_TYPE}
                onChange={() => {
                    change(SECTION_TYPE, null);
                }}
                options={projectTypeSelectOptions}
                placeholder="Select template type"
            />
            <Field
                component={SearchSelect}
                components={{
                    Option: SearchSelectIconOption,
                    SingleValue: SearchSelectIconValue,
                }}
                disabled={!sectionType || creating}
                help="Cannot be edited after creation"
                label="Section Type"
                name={SECTION_TYPE}
                options={sectionTypeOptions}
                placeholder="Select section type"
            />
            <Field
                backspaceRemovesValue={false}
                component={SearchSelect}
                components={{
                    Option: SearchSelectUserOption,
                }}
                disabled={creating}
                label="Owner"
                name={USER_ID}
                options={userSelectOptions}
                placeholder="Select shared section owner"
            />
            <div className="text-center">
                <LoadingButton
                    bsSize="lg"
                    bsStyle="success"
                    disabled={creating}
                    icon="fa-plus"
                    loading={creating}
                    qaTag="templateSectionCreateModal-create"
                    text="Create Shared Section"
                    type="submit"
                />
                {createError && <div className="error-block">{createError}</div>}
            </div>
        </form>
    );
};

ConnectedTemplateSectionCreateForm.propTypes = {
    change: PropTypes.func.isRequired,
    createError: PropTypes.string,
    creating: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    projectTypeSelectOptions: PropTypes.array.isRequired,
};

export const TemplateSectionCreateForm = reduxForm(formConfig)(ConnectedTemplateSectionCreateForm);

import { isFunction } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Checkbox, Tooltip } from 'react-bootstrap';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { proposalStatusesDict } from '@og-pro/shared-config/proposals';

import { Button, DataTable } from '../../../../components';

const { DRAFT, NO_BID, PUBLISHED } = proposalStatusesDict;

const DISQUALIFIED_STATUS = 'disqualified';
const EXCLUDED_STATUS = 'excluded';
const MAX_ROWS = 20;
const MINIMUM_OVERFLOW_ROWS_FOR_PAGINATION = 2;

class ConnectedProposalsListTable extends PureComponent {
    static propTypes = {
        addVendorsToList: PropTypes.func,
        isEditable: PropTypes.bool,
        mustAcceptAgreement: PropTypes.bool,
        proposals: PropTypes.array.isRequired,
        proposalsPath: PropTypes.string,
        readOnly: PropTypes.bool,
        showBids: PropTypes.bool.isRequired,
        router: PropTypes.object.isRequired,
        timezone: PropTypes.string.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    constructor(props) {
        super(props);
        this.state = {
            addToListOpen: false,
            userIds: [],
        };
    }

    formatDataForCSVExport = (data) => {
        const headers = [
            'Vendor',
            'Contact Name',
            'Contact Email',
            'Contact Phone',
            'Status',
            'Submission Date',
            'No Bid Reason',
        ];

        const rows = data.map((dataRow) => {
            return [
                dataRow['vendor-name-column'],
                dataRow._original.contactFullName || '', // eslint-disable-line no-underscore-dangle
                dataRow._original.contactEmail || '', // eslint-disable-line no-underscore-dangle
                dataRow._original.contactPhoneComplete || '', // eslint-disable-line no-underscore-dangle
                this.getStatusData(dataRow['proposal-status-column'])[0],
                dataRow['submission-date-column'] || '',
                dataRow._original.noBidReason || '', // eslint-disable-line no-underscore-dangle
            ];
        });

        return [headers].concat(rows);
    };

    getStatusData = (status) => {
        if (status === DISQUALIFIED_STATUS) {
            return ['Disqualified', 'fa-ban', 'text-danger'];
        }
        if (status === EXCLUDED_STATUS) {
            return ['Excluded', 'fa-minus-circle', 'text-muted'];
        }
        if (status === NO_BID) {
            return ['No Bid', 'fa-ban', 'text-warning'];
        }
        if (status === PUBLISHED) {
            return ['Submitted', 'fa-check', 'text-success'];
        }
        return ['Draft', 'fa-pencil', 'text-primary'];
    };

    updateUserIds(event, rowInfo) {
        if (rowInfo.user_id) {
            this.setState((prevState) => {
                let userIds = prevState.userIds;
                if (userIds.includes(rowInfo.user_id)) {
                    userIds = userIds.filter((item) => item !== rowInfo.user_id);
                } else {
                    userIds = userIds.concat([rowInfo.user_id]);
                }

                return { userIds };
            });
        }
    }

    addToListButtons() {
        const { addVendorsToList } = this.props;

        if (!addVendorsToList) {
            return null;
        }

        if (this.state.addToListOpen && this.state.userIds.length === 0) {
            return [
                <Button
                    className="no-print"
                    key="addToListCancel"
                    onClick={() => this.setState({ addToListOpen: false })}
                >
                    Cancel
                </Button>,
            ];
        }

        if (this.state.addToListOpen && this.state.userIds.length > 0) {
            return [
                <Button
                    className="no-print"
                    key="addToListClear"
                    onClick={() => this.setState({ addToListOpen: false, userIds: [] })}
                >
                    Clear Selection
                </Button>,
                <Button
                    className="no-print"
                    key="addToUsersToList"
                    onClick={() => addVendorsToList(this.state.userIds)}
                >
                    <i className="fa fa-plus" /> Add {this.state.userIds.length} to a List
                </Button>,
            ];
        }

        return [
            <Button
                className="no-print"
                key="addToList"
                onClick={() => this.setState({ addToListOpen: true })}
            >
                <i className="fa fa-plus" /> Add Vendors to List
            </Button>,
        ];
    }

    render() {
        const {
            isEditable,
            mustAcceptAgreement,
            proposals,
            proposalsPath,
            readOnly,
            showBids,
            router,
            timezone,
        } = this.props;

        const columns = [
            {
                Header: 'Status',
                id: 'proposal-status-column',
                accessor: (row) => {
                    if (row.isDisqualified) {
                        return DISQUALIFIED_STATUS;
                    }
                    if (row.isExcluded) {
                        return EXCLUDED_STATUS;
                    }
                    return row.status;
                },
                headerClassName: this.styles.header,
                maxWidth: 120,
                Cell: (props) => {
                    const [text, icon, className] = this.getStatusData(props.value);
                    return (
                        <span className={className}>
                            <i className={`fa fa-fw ${icon}`} /> {text}
                        </span>
                    );
                },
            },
            {
                Header: 'Vendor',
                id: 'vendor-name-column',
                accessor: (row) => (row && row.companyName ? row.companyName : 'Unnamed Company'),
                headerClassName: this.styles.header,
                style: { whiteSpace: 'unset' },
                Cell: (props) => (
                    <>
                        <div>{props.value}</div>
                        <Tooltip
                            className={this.styles.tooltip}
                            id={`${props.original.id}`}
                            placement="bottom"
                        >
                            Response will be
                            <br />
                            viewable once {mustAcceptAgreement ? 'agreement' : 'bid'}
                            <br />
                            is {mustAcceptAgreement ? 'accepted' : 'unsealed'}
                        </Tooltip>
                        {props.original.isGovernmentSubmitted && (
                            <div className={this.styles.manualEntryText}>Manually Added</div>
                        )}
                        {props.original.user && props.original.user.onVendorLists.length > 0 && (
                            <div className={this.styles.manualEntryText}>
                                Lists:{' '}
                                {props.original.user.onVendorLists
                                    .map((item) => item.name)
                                    .join(', ')}
                            </div>
                        )}
                    </>
                ),
            },
            {
                Header: 'Contact Info',
                accessor: 'contactLastName',
                headerClassName: this.styles.header,
                Cell: (props) => (
                    <>
                        <div>{props.original.contactFullName}</div>
                        <div>{props.original.contactEmail}</div>
                        <div>{props.original.contactPhoneComplete}</div>
                    </>
                ),
            },
            {
                Header: 'Submission Date',
                id: 'submission-date-column',
                accessor: (row) => {
                    if (row.submittedAt) {
                        return moment.tz(row.submittedAt, timezone).format('lll');
                    }
                    return null;
                },
                headerClassName: this.styles.header,
                maxWidth: 180,
            },
        ];

        if (this.state.addToListOpen) {
            columns.unshift({
                Header: '',
                id: 'select-vendor',
                isClickable: false,
                accessor: (row) => {
                    let option = <i className="fa fa-fw fa-ban" />;
                    if ((row || {}).user_id && !row.isGovernmentSubmitted) {
                        option = (
                            <Checkbox
                                checked={this.state.userIds.includes(row.user_id)}
                                onChange={(e) => this.updateUserIds(e, row)}
                            />
                        );
                    }
                    return <div className="text-center">{option}</div>;
                },
                headerClassName: this.styles.header,
                maxWidth: 40,
            });
        }
        const data = proposals;
        const usePagination = proposals.length > MAX_ROWS + MINIMUM_OVERFLOW_ROWS_FOR_PAGINATION;

        return (
            <DataTable
                buttons={this.addToListButtons()}
                className="-highlight"
                columns={columns}
                csvExportOptions={{
                    fileName: 'Response Contact Information',
                    getFormattedCSVData: this.formatDataForCSVExport,
                    headers: true,
                }}
                data={data}
                defaultPageSize={usePagination ? MAX_ROWS : proposals.length}
                getTrProps={(state, rowInfo) => {
                    // Bug in `react-table` passes undefined as rowInfo for extra rows with no data
                    // https://github.com/tannerlinsley/react-table/issues/1023
                    if (!rowInfo) {
                        return {};
                    }

                    const isClickable =
                        !mustAcceptAgreement &&
                        !readOnly &&
                        (showBids || rowInfo.original.isGovernmentSubmitted);

                    let className;
                    if (isClickable) {
                        className = this.styles.unsealedRows;
                    } else if (readOnly || mustAcceptAgreement) {
                        className = this.styles.rows;
                    } else {
                        className = this.styles.sealedRows;
                    }

                    return {
                        onClick: (e, handleOriginal) => {
                            if (e.target.type === 'checkbox') {
                                return;
                            }
                            if (isClickable) {
                                if (
                                    this.state.addToListOpen &&
                                    !rowInfo.original.isGovernmentSubmitted
                                ) {
                                    return this.updateUserIds(e, rowInfo.original);
                                }
                                const { id, status } = rowInfo.original;
                                const editPath = status === DRAFT && isEditable ? '/edit' : '';
                                router.push(`${proposalsPath}/${id}${editPath}`);
                            }

                            // 2/16/18: handleOriginal is not always a function:
                            // https://github.com/react-tools/react-table/issues/406
                            if (isFunction(handleOriginal)) return handleOriginal();
                        },
                        className,
                    };
                }}
                showCSVExport
                showPagination={usePagination}
            />
        );
    }
}

export const ProposalsListTable = withRouter(ConnectedProposalsListTable);

import PropTypes from 'prop-types';
import React from 'react';

import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';

import { ReportButton } from '../components';

export const ReportsTypeSelect = ({ onClick, reports }) => {
    const enableAllProposalsReport = useFlags(FLAGS.ALL_PROPOSALS_REPORT);

    return (
        <div>
            {reports
                .filter((report) => !report.omitFromList)
                .filter(
                    (report) => !report.hasAllProposalsReportFeatureFlag || enableAllProposalsReport
                )
                .map((report) => (
                    <ReportButton {...report} key={report.type} onClick={onClick} />
                ))}
        </div>
    );
};

ReportsTypeSelect.propTypes = {
    onClick: PropTypes.func.isRequired,
    reports: PropTypes.array.isRequired,
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import { isEmail } from 'validator';

import { getProjectJS } from '../../selectors';
import { loadPreInviteListRawRequest } from '../../../../actions/govProjects/preInvite';
import { CommonEmailListRow } from './CommonEmailListRow';
import { emailsFieldName } from '../constants';
import { SkeletonLoading } from './SkeletonLoading';

const ConnectedPreInviteListRow = ({
    change,
    disabled,
    meta,
    onRemove,
    onSelectedValueChange,
    selectedValue,
    setTotal,
}) => {
    const [metaValue, setMetaValue] = meta;
    const [loaded, setLoaded] = useState(false);
    const dispatch = useDispatch();
    const project = useSelector(getProjectJS);

    useEffect(() => {
        if (project.wasPosted) {
            onRemove();
        } else if (metaValue.shouldFetchPreInviteList) {
            dispatch(loadPreInviteListRawRequest(project.id))
                .then((results) => {
                    if (results?.length) {
                        change(emailsFieldName, results.join(','));

                        onSelectedValueChange(results);
                    } else if (!results?.length && !project.isPrivate) {
                        onRemove();
                    }

                    setMetaValue((v) => ({
                        ...v,
                        shouldFetchPreInviteList: false,
                    }));
                })
                .finally(() => setLoaded(true));
        } else {
            setLoaded(true);
        }
    }, [project.id]);

    useEffect(() => {
        setTotal(selectedValue.length);
    }, [selectedValue.length]);

    if (!loaded) {
        return <SkeletonLoading />;
    }

    return (
        <CommonEmailListRow
            disabled={disabled}
            label="Pre-Invite List"
            onRemove={onRemove}
            onSelectedValueChange={onSelectedValueChange}
            selectedValue={selectedValue}
        />
    );
};

ConnectedPreInviteListRow.propTypes = {
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    meta: PropTypes.object.isRequired,
    onRemove: PropTypes.func.isRequired,
    onSelectedValueChange: PropTypes.func.isRequired,
    selectedValue: PropTypes.arrayOf(PropTypes.string),
    setTotal: PropTypes.func.isRequired,
};

export const PreInviteListRow = reduxForm({
    form: 'preInviteList',
    validate: (values) => {
        const errors = {};

        if (values[emailsFieldName]) {
            const invalidEmail = values[emailsFieldName]
                .split(',')
                .find((email) => !isEmail(email.trim()));
            if (invalidEmail) {
                errors[emailsFieldName] = `Invalid email address provided: "${invalidEmail}"`;
            }
        }

        return errors;
    },
})(ConnectedPreInviteListRow);

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export class ProposalEvaluationsCompareTableHeader extends PureComponent {
    static propTypes = {
        companyName: PropTypes.string.isRequired,
        showEvaluationScorecard: PropTypes.func,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const { companyName, showEvaluationScorecard } = this.props;

        const { headerWrapper, isLinked } = this.styles;

        return (
            <div
                className={classNames(headerWrapper, showEvaluationScorecard && isLinked)}
                onClick={showEvaluationScorecard}
            >
                {companyName}
            </div>
        );
    }
}

import React from 'react';

import { ReduxFormTextField } from '@og-pro/ui';
import { useSelector } from 'react-redux';
import { Field, getFormValues } from 'redux-form';

import {
    createCustomFieldFormName,
    customFieldFields,
    CHARACTER_LIMIT_FIELD,
    instructionsModes,
} from '@og-pro/shared-config/customFormService/customField';

const { NAME, INSTRUCTIONS_TEXT, INSTRUCTIONS_MODE } = customFieldFields;
const { TOOLTIP, DESCRIPTION } = instructionsModes;

export const LongTextPreview = () => {
    const formValues =
        useSelector((state) => getFormValues(createCustomFieldFormName)(state)) || {};
    const instructionsText = formValues[INSTRUCTIONS_TEXT];
    const characterLimit = formValues[CHARACTER_LIMIT_FIELD];
    return (
        <Field
            {...(characterLimit !== 'None' && { characterLimit })}
            component={ReduxFormTextField}
            description={
                formValues[INSTRUCTIONS_MODE] === DESCRIPTION ? instructionsText : undefined
            }
            disabled
            fullWidth
            label={formValues[NAME] || 'Your title will go here'}
            multiline
            name="longText_PREVIEW"
            tooltip={formValues[INSTRUCTIONS_MODE] === TOOLTIP ? instructionsText : undefined}
        />
    );
};

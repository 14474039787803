import { fromJS } from 'immutable';

import * as requisitionsAdminActions from '../actions/requisitionsAdmin';

const initialState = fromJS({
    flags: [],
    loadFlagsError: null,
    loadFlagsSuccess: false,
    loadingFlags: false,
    loadingPositions: false,
    loadingReqSetting: false,
    loadPositionsError: null,
    loadReqSettingError: null,
    positions: [],
    reqSetting: null,
});

export default function requisitionsAdminReducer(state = initialState, action = {}) {
    switch (action.type) {
        case requisitionsAdminActions.CREATE_FLAG_SUCCESS:
            return state.set('flags', state.get('flags').unshift(fromJS(action.result)));
        case requisitionsAdminActions.CREATE_POSITION_SUCCESS:
            return state.set('positions', state.get('positions').unshift(fromJS(action.result)));
        case requisitionsAdminActions.DELETE_FLAG_SUCCESS:
            return state.set(
                'flags',
                state.get('flags').filter((flag) => flag.get('id') !== action.result.id)
            );
        case requisitionsAdminActions.DELETE_POSITION_SUCCESS:
            return state.set(
                'positions',
                state.get('positions').filter((position) => position.get('id') !== action.result.id)
            );
        case requisitionsAdminActions.LOAD_FLAGS:
            return state.merge(
                fromJS({
                    loadingFlags: true,
                    loadFlagsError: null,
                    loadFlagsSuccess: false,
                })
            );
        case requisitionsAdminActions.LOAD_FLAGS_FAIL:
            return state.merge(
                fromJS({
                    loadingFlags: false,
                    loadFlagsError: action.error && action.error.message,
                    loadFlagsSuccess: false,
                })
            );
        case requisitionsAdminActions.LOAD_FLAGS_SUCCESS:
            return state.merge(
                fromJS({
                    flags: fromJS(action.result),
                    loadingFlags: false,
                    loadFlagsSuccess: true,
                })
            );
        case requisitionsAdminActions.RESET_LOAD_FLAGS_SUCCESS:
            return state.merge(
                fromJS({
                    loadFlagsSuccess: false,
                })
            );
        case requisitionsAdminActions.LOAD_POSITIONS:
            return state.merge(
                fromJS({
                    loadingPositions: true,
                    loadPositionsError: null,
                })
            );
        case requisitionsAdminActions.LOAD_POSITIONS_FAIL:
            return state.merge(
                fromJS({
                    loadingPositions: false,
                    loadPositionsError: action.error && action.error.message,
                })
            );
        case requisitionsAdminActions.LOAD_POSITIONS_SUCCESS:
            return state.merge(
                fromJS({
                    loadingPositions: false,
                    positions: fromJS(action.result),
                })
            );

        case requisitionsAdminActions.LOAD_REQ_SETTING:
            return state.merge(
                fromJS({
                    loadingReqSetting: true,
                    loadReqSettingError: null,
                })
            );
        case requisitionsAdminActions.LOAD_REQ_SETTING_FAIL:
            return state.merge(
                fromJS({
                    loadingReqSetting: false,
                    loadReqSettingError: action.error && action.error.message,
                })
            );
        case requisitionsAdminActions.LOAD_REQ_SETTING_SUCCESS:
            return state.merge(
                fromJS({
                    loadingReqSetting: false,
                    reqSetting: fromJS(action.result),
                })
            );
        case requisitionsAdminActions.UPDATE_FLAG_SUCCESS:
            return state.set(
                'flags',
                state.get('flags').map((flag) => {
                    if (flag.get('id') !== action.result.id) {
                        return flag;
                    }
                    return fromJS(action.result);
                })
            );
        case requisitionsAdminActions.UPDATE_POSITION_SUCCESS:
            return state.set(
                'positions',
                state.get('positions').map((position) => {
                    if (position.get('id') !== action.result.id) {
                        return position;
                    }
                    return fromJS(action.result);
                })
            );
        case requisitionsAdminActions.UPDATE_REQ_SETTING_SUCCESS:
            return state.merge(
                fromJS({
                    reqSetting: fromJS(action.result),
                })
            );
        default:
            return state;
    }
}

import classnames from 'classnames';
import React, { PureComponent } from 'react';
import ReactTable from 'react-table';

import { ButtonGroup, Collapse, LoadingButton } from '..';
import { exportArrayToCSV } from '../../utils';
import { propTypes } from './propTypes';

export class DataTable extends PureComponent {
    static propTypes = propTypes;

    static defaultProps = {
        collapsible: false,
        csvExportOptions: undefined,
        showCSVExport: false,
    };

    constructor(props) {
        super(props);

        this.reactTable = React.createRef();
        this.state = { showTable: !this.props.collapsed };
    }

    get styles() {
        return require('./index.scss');
    }

    handleExportCSVClick = async () => {
        const { csvExportOptions } = this.props;

        let csvData;
        let fileName;
        let headers;
        const resolvedData = this.reactTable.current.getResolvedState();

        if (csvExportOptions) {
            fileName = csvExportOptions.fileName;
            headers = csvExportOptions.headers;
        }

        if (csvExportOptions && csvExportOptions.getFormattedCSVData) {
            csvData = await csvExportOptions.getFormattedCSVData(resolvedData.sortedData);
        } else {
            csvData = resolvedData.sortedData.map((dataRow) => {
                return resolvedData.columns.map((column) => {
                    if (column.id) {
                        return dataRow[column.id];
                    }

                    return dataRow[column.accessor];
                });
            });
        }

        exportArrayToCSV(csvData, { fileName, headers });
    };

    toggleShowTable = () => this.setState((prevState) => ({ showTable: !prevState.showTable }));

    renderTable() {
        const {
            buttons,
            collapsible,
            componentsAfterButtons,
            csvExportOptions,
            showCSVExport,
            title,
            ...props
        } = this.props;

        if (collapsible) {
            return (
                <Collapse in={this.state.showTable}>
                    <ReactTable {...props} ref={this.reactTable} />
                </Collapse>
            );
        }

        return <ReactTable {...props} ref={this.reactTable} />;
    }

    renderTitle(className) {
        const { collapsible, title } = this.props;

        const { showTable } = this.state;

        if (!collapsible && !title) {
            return null;
        }

        return (
            <div
                className={classnames(
                    this.styles.titleContainer,
                    collapsible && this.styles.collapsible,
                    className
                )}
                onClick={collapsible ? this.toggleShowTable : undefined}
            >
                {collapsible && (
                    <i className={`fa fa-fw fa-angle-${showTable ? 'down' : 'right'}`} />
                )}
                {title}
            </div>
        );
    }

    render() {
        const {
            buttons,
            componentsAfterButtons,
            componentsInlineWithButtons,
            csvExportIsLoading,
            showCSVExport,
        } = this.props;

        let buttonGroup = buttons || [];

        if (showCSVExport) {
            buttonGroup = buttonGroup.concat(
                <LoadingButton
                    className="no-print"
                    icon="fa-download"
                    key="csvExportButton"
                    loading={csvExportIsLoading}
                    onClick={this.handleExportCSVClick}
                    qaTag="dataTable-exportToCsv"
                    text="Export to CSV"
                />
            );
        }

        if (buttonGroup.length > 0) {
            return (
                <>
                    <div className={`text-right ${this.styles.buttonGroup}`}>
                        {this.renderTitle('pull-left')}
                        <ButtonGroup
                            bsSize="sm"
                            className={classnames(componentsInlineWithButtons && 'pull-right')}
                        >
                            {buttonGroup}
                        </ButtonGroup>
                        {componentsInlineWithButtons}
                    </div>
                    <div className="clearfix" />
                    {componentsAfterButtons}
                    {this.renderTable()}
                </>
            );
        }

        return (
            <>
                {this.renderTitle()}
                {this.renderTable()}
            </>
        );
    }
}

import React from 'react';
import { Box, Typography, Modal, IconButton, Button } from '@og-pro/ui';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import PropTypes from 'prop-types';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { customFieldsExitConfirmationModalContainer } from './styles';

const CustomFieldsExitConfirmationModal = ({ onClose, open, handleNavigate }) => {
    return (
        <Modal
            aria-describedby="field-modal-description"
            aria-labelledby="field-modal-title"
            onClose={onClose}
            open={open}
        >
            <Box sx={customFieldsExitConfirmationModalContainer}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        px: 3,
                        py: 2,
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <WarningIcon
                            fontSize="small"
                            sx={{ color: capitalDesignTokens.foundations.colors.terracotta600 }}
                        />
                        <Typography
                            color={capitalDesignTokens.foundations.colors.terracotta600}
                            id="field-modal-title"
                            variant="h3"
                        >
                            Unsaved Changes
                        </Typography>
                    </Box>

                    <IconButton
                        onClick={onClose}
                        qaTag="requisitionAdminCustomFields-modalClose"
                        sx={{
                            width: 24,
                            height: 24,
                        }}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </Box>

                <Box
                    sx={{
                        pt: 1,
                        pb: 1.5,
                        pl: 8,
                        pr: 3,
                    }}
                >
                    <Typography variant="body1">Are you sure you want to leave?</Typography>
                    <Typography sx={{ mt: 1, wordWrap: 'break-word' }} variant="body1">
                        Your changes have not been saved. If you leave without saving your changes,
                        your work will be lost.
                    </Typography>
                </Box>
                <Box
                    sx={{
                        p: 3,
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: 1,
                    }}
                >
                    <Button color="secondary" onClick={handleNavigate} variant="text">
                        Leave
                    </Button>
                    <Button onClick={onClose} variant="contained">
                        Stay
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

CustomFieldsExitConfirmationModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    handleNavigate: PropTypes.func.isRequired,
};

export default CustomFieldsExitConfirmationModal;

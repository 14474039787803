import React, { useEffect, useState } from 'react';
import { snakeCase } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Chip, CircularProgress, Typography } from '@og-pro/ui';
import { Panel } from 'react-bootstrap';
import {
    Add as AddIcon,
    ArrowBack as ArrowBackIcon,
    FileDownloadOutlined as FileDownloadOutlinedIcon,
    Edit as EditIcon,
} from '@mui/icons-material';
import { tokens } from '@opengov/capital-style';

import { getPortalUrl, getVendorList, getVendorListLoading } from '../../selectors';
import VendorManagementHeader from '../VendorManagementHeader';
import { VendorListDialog } from '../..';
import { UserProfilePicture } from '../../../../components';
import {
    loadProcuratedRatingsClient,
    loadVendorList,
    searchVendorList,
    showAddVendorsToListModal,
} from '../../../../actions/vendorList';
import { VendorListTable } from './VendorListTable';
import { exportArrayToCSV } from '../../../../utils';
import { getShowAddVendorListModal } from '../selectors';
import { AddVendorsToListModal } from '../AddVendorsToList/modal';
import { CategorySelectButton } from '../../../CategorySelect';
import { ActionsMenu } from './ActionsMenu';
import { getUserJS } from '../../../selectors';

const { layout } = tokens;

export const VendorListManagerV2 = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { governmentId: govIdParam, vendorListId: vendorListIdParam } = useParams();

    const governmentId = Number.parseInt(govIdParam, 10);
    const vendorListId = Number.parseInt(vendorListIdParam, 10);

    const loadingVendorList = useSelector(getVendorListLoading);
    const addVendorsToListModalOpen = useSelector(getShowAddVendorListModal);

    useEffect(() => {
        dispatch(loadVendorList(governmentId, vendorListId));
    }, []);

    const { government } = useSelector(getUserJS);
    const vendorList = useSelector((state) => getVendorList(state, vendorListId));
    const portalUrl = useSelector(getPortalUrl);

    const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
    const [showVendorListDialog, setShowVendorListDialog] = useState(false);
    const [vendorListWithRatings, setVendorListWithRatings] = useState([]);

    const filteredUserEmails =
        selectedCategoryIds.length > 0
            ? vendorList.listedUsers
                  .map((u) => u.email)
                  .concat(vendorList.listedPendingUsers.map((u) => u.email))
            : [];

    const fetchRatings = async () => {
        const allVendorsUsers = vendorList.listedUsers.concat(vendorList.listedPendingUsers);
        const supplierIds = allVendorsUsers.map((user) => user?.organization?.vendor?.id ?? 0);

        const newVendorList = { ...vendorList };

        dispatch(loadProcuratedRatingsClient({ supplierIds }))
            .then((ratingsResponse) => {
                allVendorsUsers.forEach((user) => {
                    const supplierId = user?.organization?.vendor?.id;
                    if (!supplierId) return;

                    const vendorRating = ratingsResponse.find((rating) => {
                        return parseInt(rating.partnerId, 10) === supplierId;
                    });
                    if (!vendorRating?.rating) return;

                    // Update the vendorList with the ratings
                    const idxFinder = (myUser) => myUser.organization.vendor.id === supplierId;
                    const idx = newVendorList.listedUsers.findIndex(idxFinder);
                    if (idx !== -1) {
                        newVendorList.listedUsers[idx].rating = vendorRating.rating;
                    } else {
                        const idxPending = newVendorList.listedPendingUsers.findIndex(idxFinder);
                        if (idxPending === -1) return;
                        newVendorList.listedPendingUsers[idxPending].rating = vendorRating.rating;
                    }
                });
                setVendorListWithRatings(newVendorList);
            })
            .catch(() => {
                // If it does fail we still want to set the vendor list so we're not dependent on procurated
                setVendorListWithRatings(vendorList);
            });
    };

    useEffect(() => {
        if (vendorList && !loadingVendorList) {
            fetchRatings();
        }
    }, [vendorListId, governmentId, loadingVendorList]);

    const categoriesSelectHandler = (selectedCategories, hasChanged) => {
        if (hasChanged) {
            const categoryIds = selectedCategories.map((category) => category.id.toString());
            const categories = selectedCategories.map((category) => category.code);
            setSelectedCategoryIds(categoryIds);
            return dispatch(searchVendorList(governmentId, vendorListId, { categories }));
        }
    };

    const clearCategoryIds = () => {
        setSelectedCategoryIds([]);
    };

    const exportClickHandler = () => {
        const headers = ['Vendor Legal Name', 'Contact', 'Email', 'Company Phone'];

        const rows = vendorList.listedUsers
            .map((user) => {
                return [
                    user.organization.name,
                    user.displayName,
                    user.email,
                    user.organization.phoneComplete,
                ];
            })
            .concat(
                vendorList.listedPendingUsers.map((user) => {
                    return [
                        user.organization?.name,
                        user.displayName,
                        user.email,
                        user.organization?.phoneComplete,
                    ];
                })
            );

        exportArrayToCSV([headers].concat(rows), {
            fileName: `${snakeCase(vendorList.name)}_contacts`,
            headers: true,
            timestamp: true,
        });
    };

    if (!vendorList) {
        return null;
    }

    return (
        <div>
            <Panel>
                <Panel.Body>
                    <VendorManagementHeader
                        governmentId={governmentId}
                        pathname={location.pathname}
                        portalUrl={portalUrl}
                    />
                    <Box sx={{ marginBottom: layout.unit1 }}>
                        <Button
                            onClick={() => navigate(`/governments/${governmentId}/vendors/lists`)}
                            qaTag="vendorListManager-backToVendorLists"
                            sx={{ paddingLeft: 0 }}
                            variant="text"
                        >
                            <ArrowBackIcon
                                sx={{
                                    color: tokens.colors.colorGray800,
                                    width: '21px',
                                    height: '21px',
                                }}
                            />
                            <Typography
                                sx={{
                                    color: tokens.colors.colorGray800,
                                    marginLeft: '4px',
                                    fontWeight: 600,
                                    fontSize: '12px',
                                }}
                            >
                                Back to Lists
                            </Typography>
                        </Button>
                    </Box>
                    {loadingVendorList && !addVendorsToListModalOpen && (
                        <Box display="flex" justifyContent="center">
                            <CircularProgress size="large" />
                        </Box>
                    )}
                    {!loadingVendorList && (
                        <>
                            <Box columnGap={1} display="flex">
                                <Box flexGrow={1}>
                                    <Typography sx={{ display: 'inline-flex' }} variant="h2">
                                        {vendorList.name}
                                    </Typography>
                                    {government.displayPublicVendorInformation ? (
                                        <Chip
                                            color={vendorList.isPublic ? 'success' : 'secondary'}
                                            label={vendorList.isPublic ? 'Public' : 'Private'}
                                            sx={{ marginBottom: 1, marginLeft: layout.unit1 }}
                                            variant="tag"
                                        />
                                    ) : null}
                                    <Button
                                        aria-label="Edit Vendor List Button"
                                        onClick={() => setShowVendorListDialog(true)}
                                        qaTag="vendorListManager-editVendorList"
                                        sx={{ marginBottom: 1 }}
                                        variant="text"
                                    >
                                        <EditIcon fontSize="small" />
                                    </Button>
                                </Box>
                                <Box alignSelf="flex-end">
                                    <Typography
                                        sx={{ display: 'inline-flex', marginRight: layout.unit1 }}
                                        variant="bodyDefault"
                                    >
                                        Creator:
                                    </Typography>
                                    <UserProfilePicture horizontal user={vendorList.user} />
                                    <Typography
                                        sx={{ display: 'inline-flex', marginLeft: layout.unit1 }}
                                        variant="bodyDefault"
                                    >
                                        {vendorList.user.displayName}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box columnGap={1} display="flex" mt={1}>
                                <Box flexGrow={1}>
                                    <Typography sx={{ display: 'inline-flex' }}>
                                        Filter by:{' '}
                                    </Typography>
                                    <div style={{ display: 'inline-flex' }}>
                                        <CategorySelectButton
                                            categoryIds={selectedCategoryIds}
                                            onSelect={categoriesSelectHandler}
                                        />
                                    </div>
                                </Box>
                                <Box alignSelf="flex-end" columnGap={1} display="flex">
                                    <Button
                                        color="secondary"
                                        onClick={exportClickHandler}
                                        qaTag="vendorListManager-export"
                                    >
                                        <FileDownloadOutlinedIcon sx={{ pb: 0.5 }} /> Export
                                    </Button>
                                    <ActionsMenu
                                        disableUsersActions={
                                            vendorList.listedPendingUsers.length === 0 &&
                                            vendorList.listedUsers.length === 0
                                        }
                                        filteredUserEmails={filteredUserEmails}
                                        governmentId={governmentId}
                                        listName={vendorList.name}
                                        vendorListId={vendorListId}
                                    />
                                    <Button
                                        onClick={() => {
                                            clearCategoryIds();
                                            dispatch(
                                                showAddVendorsToListModal(
                                                    governmentId,
                                                    vendorListId
                                                )
                                            );
                                        }}
                                        qaTag="vendorListManager-addVendorsToList"
                                        variant="outlined"
                                    >
                                        <AddIcon fontSize="small" /> Add Vendors to List
                                    </Button>
                                </Box>
                            </Box>
                            <VendorListDialog
                                clearCategoryIds={
                                    selectedCategoryIds.length > 0 ? clearCategoryIds : null
                                }
                                displayPublicVendorInformation={
                                    government.displayPublicVendorInformation
                                }
                                governmentId={governmentId}
                                hideDialog={() => setShowVendorListDialog(false)}
                                open={showVendorListDialog}
                                vendorList={vendorList}
                            />
                            <VendorListTable
                                governmentId={governmentId}
                                vendorList={vendorListWithRatings}
                            />
                        </>
                    )}
                    {addVendorsToListModalOpen && (
                        <AddVendorsToListModal
                            governmentId={governmentId}
                            id={vendorListId}
                            initialValues={{ vendorListId }}
                            useOpenGovStyle
                        />
                    )}
                </Panel.Body>
            </Panel>
        </div>
    );
};

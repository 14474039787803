import PropTypes from 'prop-types';
import React from 'react';
import { DropdownButton } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getUserJS } from '../../../selectors';
import { showConfirmationSimpleModal } from '../../../../actions/confirmation';
import {
    checkOutTemplateSection,
    copyTemplateSection,
    deleteTemplateSection,
} from '../../../../actions/templateSections';
import { Button, MenuItem } from '../../../../components';

export const TemplateSectionTitleMenuButtons = ({
    deleting,
    disabled,
    setShowFormValidation,
    showBroadcastModal,
    templateSection: { checkedOutUser, checked_out_user_id: checkedOutUserId, id, isPublished },
    templateSectionsPath,
    toggleLive,
    updateTemplateSection,
    updating,
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(getUserJS);

    const copyHandler = () => {
        const copyHandlerFn = () => {
            // Copy template section and then route to copied template section page
            dispatch(
                copyTemplateSection(id, (result) =>
                    navigate(`${templateSectionsPath}/${result.id}`)
                )
            );
        };

        dispatch(
            showConfirmationSimpleModal(copyHandlerFn, {
                btnText: 'Copy Shared Section',
                bsStyle: 'primary',
                icon: 'copy',
                text: 'Create a copy of this shared section?',
            })
        );
    };

    const deleteHandler = () => {
        const deleteHandlerFn = () => {
            // Delete template section and then route back to template section list page
            dispatch(
                deleteTemplateSection(id, () => navigate(templateSectionsPath, { replace: true }))
            );
        };

        dispatch(
            showConfirmationSimpleModal(deleteHandlerFn, {
                btnText: 'Delete Shared Section',
                text: 'Are you sure you want to delete this shared section?\nThis action cannot be undone.',
            })
        );
    };

    const checkOutHandlerFn = (isCheckingOut) => () =>
        dispatch(checkOutTemplateSection(id, isCheckingOut));

    const checkOutHandler = () => {
        dispatch(
            showConfirmationSimpleModal(checkOutHandlerFn(true), {
                btnText: 'Check Out Shared Section',
                bsStyle: 'primary',
                icon: 'download',
                text:
                    'Are you sure you want to check out this shared section?\n' +
                    'No other users will be able to make edits while you have it checked out.\n' +
                    'Be sure to check it back in once you have finished editing.',
            })
        );
    };

    const checkInHandler = () => {
        const attemptUpdate = updateTemplateSection();

        // If validation fails, `updateTemplateSection` does not return a Promise and we should not
        // proceed with checking in the template section
        if (typeof attemptUpdate.then === 'function') {
            attemptUpdate.then(checkOutHandlerFn(false));
        }
    };

    const deleteIcon = deleting ? 'spinner fa-spin' : 'trash';
    const saveIcon = updating ? 'spinner fa-spin' : 'check';
    const styles = require('./TemplateSectionTitleMenu.scss');

    if (!checkedOutUser) {
        return (
            <>
                <Button
                    className={styles.buttonPaddingRight}
                    disabled={disabled}
                    onClick={copyHandler}
                    qaTag="sharedSectionEdit-copy"
                >
                    <i className="fa fa-copy" /> Copy Shared Section
                </Button>
                <Button
                    bsStyle="primary"
                    disabled={disabled}
                    onClick={checkOutHandler}
                    qaTag="sharedSectionEdit-claim"
                >
                    <i className="fa fa-download" /> Claim & Edit
                </Button>
            </>
        );
    }

    if (checkedOutUserId && checkedOutUserId !== user.id) {
        return (
            <>
                <strong>Currently Working:</strong>
                &nbsp;
                {checkedOutUser.displayName}
                &nbsp;&nbsp;
                <Button disabled={disabled} onClick={copyHandler} qaTag="sharedSectionEdit-copy">
                    <i className="fa fa-copy" /> Copy Shared Section
                </Button>
            </>
        );
    }

    return (
        <>
            <DropdownButton
                className={styles.buttonPaddingRight}
                disabled={disabled}
                id="shared-section-more-button"
                pullRight
                title="More"
            >
                <MenuItem
                    onClick={() => setShowFormValidation(true)}
                    qaTag="sharedSectionEdit-showFormErrors"
                >
                    <i className="fa fa-fw fa-exclamation-triangle" /> Show Form Errors
                </MenuItem>
                {isPublished && (
                    <MenuItem onClick={toggleLive} qaTag="sharedSectionEdit-disable">
                        <i className="fa fa-fw fa-ban" /> Disable
                    </MenuItem>
                )}
                <MenuItem onClick={copyHandler} qaTag="sharedSectionEdit-copy">
                    <i className="fa fa-fw fa-copy" /> Copy Shared Section
                </MenuItem>
                <MenuItem onClick={deleteHandler} qaTag="sharedSectionEdit-delete">
                    <i className={`fa fa-fw fa-${deleteIcon}`} /> Delete
                </MenuItem>
            </DropdownButton>
            {isPublished && (
                <Button
                    className={styles.buttonPaddingRight}
                    disabled={disabled}
                    onClick={showBroadcastModal}
                    qaTag="sharedSectionEdit-broadcastUpdates"
                >
                    <i className="fa fa-rss" /> Broadcast Updates
                </Button>
            )}
            <Button
                className={styles.buttonPaddingRight}
                disabled={disabled}
                onClick={() => updateTemplateSection()}
                qaTag="sharedSectionEdit-save"
            >
                <i className={`fa fa-fw fa-${saveIcon}`} /> Save
            </Button>
            {!isPublished && (
                <Button
                    bsStyle="success"
                    className={styles.buttonPaddingRight}
                    disabled={disabled}
                    onClick={toggleLive}
                    qaTag="sharedSectionEdit-live"
                >
                    <i className="fa fa-play-circle" /> Set Live
                </Button>
            )}
            <Button
                bsStyle="primary"
                disabled={disabled}
                onClick={checkInHandler}
                qaTag="sharedSectionEdit-checkIn"
            >
                <i className="fa fa-upload" /> Done
            </Button>
        </>
    );
};

TemplateSectionTitleMenuButtons.propTypes = {
    deleting: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    setShowFormValidation: PropTypes.func.isRequired,
    showBroadcastModal: PropTypes.func.isRequired,
    templateSection: PropTypes.shape({
        checkedOutUser: PropTypes.object,
        checked_out_user_id: PropTypes.number,
        id: PropTypes.number.isRequired,
        isPublished: PropTypes.bool.isRequired,
        projectSection: PropTypes.shape({
            section_type: PropTypes.string.isRequired,
        }).isRequired,
        type: PropTypes.string.isRequired,
    }).isRequired,
    templateSectionsPath: PropTypes.string.isRequired,
    toggleLive: PropTypes.func.isRequired,
    updateTemplateSection: PropTypes.func.isRequired,
    updating: PropTypes.bool.isRequired,
};

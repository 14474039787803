import { get } from 'lodash';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';

import { DataTable } from '../../../components';

export const ProposalViewerAgreementsTable = ({ agreements }) => {
    const title = 'Response Viewer Agreements Accepted';

    const columns = [
        {
            Header: 'Accepted By',
            accessor: 'user.displayName',
        },
        {
            Header: 'Accepted At',
            id: 'confirm-date-column',
            accessor: (row) => {
                const confirmedAt = get(row, 'acceptedAt');
                if (confirmedAt) {
                    return moment(confirmedAt).format('lll');
                }
                return null;
            },
        },
    ];

    const formatDataForCSVExport = (data) => {
        const headers = ['Accepted By', 'Accepted At'];

        const rows = data.map((dataRow) => {
            return [get(dataRow, 'user.displayName') || '', dataRow['confirm-date-column'] || ''];
        });

        return [headers].concat(rows);
    };

    return (
        <DataTable
            className="-highlight -striped"
            columns={columns}
            csvExportOptions={{
                fileName: title,
                getFormattedCSVData: formatDataForCSVExport,
                headers: true,
            }}
            data={agreements}
            noDataText="No Agreements Accepted"
            pageSize={agreements.length}
            showCSVExport
            showPagination={false}
            title={title}
        />
    );
};

ProposalViewerAgreementsTable.propTypes = {
    agreements: PropTypes.array.isRequired,
};

import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '..';

export const AddButton = ({ disabled, onClick, text }) => {
    return (
        <Button bsSize="sm" bsStyle="link" disabled={disabled} onClick={onClick} zeroPadding>
            <i className="fa fa-plus" /> {text}
        </Button>
    );
};

AddButton.propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
};

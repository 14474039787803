import { Close as CloseIcon } from '@mui/icons-material';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import { Box, IconButton, Typography } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { qaTagName } from '../../../constants';

const containerStyle = {
    backgroundColor: capitalDesignTokens.semanticColors.background.primary,
    border: `1px solid ${capitalDesignTokens.semanticColors.border.secondary}`,
    borderRadius: '4px',
    overflow: 'hidden',
};

const headerStyle = {
    alignItems: 'center',
    backgroundColor: capitalDesignTokens.semanticColors.background.secondary,
    borderBottom: `1px solid ${capitalDesignTokens.semanticColors.border.secondary}`,
    display: 'flex',
    justifyContent: 'space-between',
    gap: 1,
    padding: 3,
};

const titleStyle = {
    alignItems: 'center',
    display: 'flex',
    gap: 1,
};

export const SectionSettings = ({ children, disabled, headerSx, onClose, title, TitleIcon }) => {
    return (
        <Box sx={containerStyle}>
            <Box sx={{ ...headerStyle, ...headerSx }}>
                <Typography sx={titleStyle} variant="h3">
                    {TitleIcon}
                    {title}
                </Typography>
                {onClose && (
                    <IconButton
                        aria-label="Close section"
                        disabled={disabled}
                        onClick={onClose}
                        qaTag={`${qaTagName}-closeSection`}
                        sx={{ padding: 0 }}
                    >
                        <CloseIcon sx={{ fontSize: 'inherit' }} />
                    </IconButton>
                )}
            </Box>
            {children}
        </Box>
    );
};

SectionSettings.propTypes = {
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    headerSx: PropTypes.object,
    onClose: PropTypes.func,
    title: PropTypes.string,
    TitleIcon: PropTypes.node,
};

import classnames from 'classnames';
import { startCase } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { projectTypesDict } from '@og-pro/shared-config/projects';

const { ADDENDUM, CONTRACT, OTHER } = projectTypesDict;

export class ProjectSelect extends PureComponent {
    static propTypes = {
        clickHandler: PropTypes.func.isRequired,
        hasContracting: PropTypes.bool.isRequired,
        hasDocBuilder: PropTypes.bool.isRequired,
        hasEvaluation: PropTypes.bool.isRequired,
        hasSourcing: PropTypes.bool.isRequired,
        isDocBuilder: PropTypes.bool,
    };

    get styles() {
        return require('./shared.scss');
    }

    get title() {
        const { hasContracting, isDocBuilder } = this.props;

        if (isDocBuilder) {
            return 'Which type of document would you like to draft?';
        }
        if (hasContracting) {
            return 'Where in the purchasing process would\nyou like to start?';
        }
        return 'Where Would You Like to Start?';
    }

    handleDocBuilderClick = (type) => {
        const { clickHandler } = this.props;

        clickHandler({ type });
    };

    handleEvaluationClick = () => {
        const { clickHandler } = this.props;

        clickHandler({ isEvaluationOnly: true });
    };

    handleSourcingClick = () => {
        const { clickHandler } = this.props;

        clickHandler({ isPostOnly: true });
    };

    handleWriterClick = () => {
        const { clickHandler } = this.props;

        clickHandler();
    };

    renderColumn = (renderFunction, i) => {
        return (
            <div className={classnames('col-xs-5', i === 0 && 'col-xs-offset-1')} key={i}>
                {renderFunction()}
            </div>
        );
    };

    renderEvaluation = () => {
        const { iconButton, iconText } = this.styles;

        return (
            <div>
                <span
                    className={iconButton}
                    data-qa="dashboard-button-evaluate-proposals"
                    onClick={this.handleEvaluationClick}
                >
                    <i className="fa fa-star fa-2x hidden-xs" />
                    <i className="fa fa-star fa-4x" />
                    <i className="fa fa-star fa-2x hidden-xs" />
                    <div className={iconText}>Evaluate Proposals</div>
                </span>
            </div>
        );
    };

    renderSourcing = () => {
        const { iconButton, iconText } = this.styles;

        return (
            <div>
                <span className={iconButton} onClick={this.handleSourcingClick}>
                    <i className="fa fa-flag fa-4x" />
                    <div className={iconText}>Post Bid Opportunity</div>
                </span>
            </div>
        );
    };

    renderWriter = () => {
        const { iconButton, iconText } = this.styles;

        return (
            <div>
                <span
                    className={iconButton}
                    data-qa="dashboard-button-draft-solicitation-document"
                    onClick={this.handleWriterClick}
                >
                    <i className="fa fa-pencil-square-o fa-4x" />
                    <div className={iconText}>Draft Solicitation Document</div>
                </span>
            </div>
        );
    };

    renderDocBuilder = (type, icon) => {
        const { iconButton, iconText } = this.styles;

        return (
            <div>
                <span className={iconButton} onClick={() => this.handleDocBuilderClick(type)}>
                    <i className={`fa fa-${icon} fa-4x`} />
                    <div className={iconText}>{startCase(type)} Document</div>
                </span>
            </div>
        );
    };

    renderOptions() {
        const { hasContracting, hasDocBuilder, hasEvaluation, hasSourcing, isDocBuilder } =
            this.props;

        const options = [];

        if (isDocBuilder) {
            if (hasContracting) {
                options.push(() => this.renderDocBuilder(CONTRACT, 'file-text-o'));
            }
            options.push(
                () => this.renderDocBuilder(ADDENDUM, 'paperclip'),
                () => this.renderDocBuilder(OTHER, 'star-o')
            );
        } else {
            if (hasDocBuilder) {
                options.push(this.renderWriter);
            }
            if (hasSourcing && !hasDocBuilder) {
                options.push(this.renderSourcing);
            }
            if (hasEvaluation) {
                options.push(this.renderEvaluation);
            }
        }

        return this.renderOptionItems(options);
    }

    // Assumption is there will only be 2-6 options. Need to update if more options added.
    renderOptionItems(options) {
        const firstRow = options.slice(0, 2);
        const secondRow = options.slice(2, 4);
        const thirdRow = options.slice(4, 6);

        if (options.length === 3) {
            return (
                <div className="row">
                    {options.map((option, index) => (
                        <div className="col-xs-4" key={index}>
                            {option()}
                        </div>
                    ))}
                </div>
            );
        }

        return (
            <div>
                <div className="row">{firstRow.map(this.renderColumn)}</div>
                {secondRow.length > 0 && (
                    <div className={`row ${this.styles.additionalRow}`}>
                        {secondRow.map(this.renderColumn)}
                    </div>
                )}
                {thirdRow.length > 0 && (
                    <div className={`row ${this.styles.additionalRow}`}>
                        {thirdRow.map(this.renderColumn)}
                    </div>
                )}
            </div>
        );
    }

    render() {
        const { projectCreateIcons, titleText } = this.styles;

        return (
            <div className={projectCreateIcons}>
                <div className={titleText}>{this.title}</div>
                {this.renderOptions()}
            </div>
        );
    }
}

import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { NoSsr } from '@mui/material';

import { purchaseOrderStatuses } from '@og-pro/shared-config/purchaseOrders';

import { DataTable, ZeroState } from '..';
import { currencyFormatter } from '../../helpers';

export class PurchaseOrderTable extends Component {
    static propTypes = {
        className: PropTypes.string,
        editHandler: PropTypes.func,
        purchaseOrders: PropTypes.arrayOf(
            PropTypes.shape({
                amount: PropTypes.number.isRequired,
                tag: PropTypes.shape({
                    name: PropTypes.string.isRequired,
                }).isRequired,
            })
        ).isRequired,
        showCSVExport: PropTypes.bool,
        title: PropTypes.node.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    formatDataForCSVExport = (data) => {
        const headers = [
            'Vendor',
            'Order Amount',
            'Paid Amount',
            'Status',
            'PO #',
            'Issue Date',
            'Fiscal Year',
            'Department',
            'Last Updated',
        ];

        const rows = data.map((dataRow) => {
            return [
                dataRow['contractParty.companyName'],
                currencyFormatter({ value: dataRow.amount }),
                currencyFormatter({ value: dataRow.paidAmount }),
                dataRow._original.statusText, // eslint-disable-line no-underscore-dangle
                dataRow.number || '',
                dataRow.date ? moment(dataRow.date).format('ll') : '',
                dataRow['tag.name'],
                dataRow['department.name'],
                moment(dataRow.updated_at).format('lll'),
            ];
        });

        return [headers].concat(rows);
    };

    renderStatusCell = (text, icon, color) => (
        <span className={`text-${color}`}>
            <i className={`fa fa-fw fa-${icon}`} />
            &nbsp;
            {text}
        </span>
    );

    renderStatus = (status) => {
        switch (status) {
            case purchaseOrderStatuses.ISSUED:
                return this.renderStatusCell('Issued', 'send', 'info');
            case purchaseOrderStatuses.REQUESTED:
                return this.renderStatusCell('Requested', 'clock-o', 'warning');
            case purchaseOrderStatuses.APPROVED:
                return this.renderStatusCell('Approved', 'check', 'primary');
            case purchaseOrderStatuses.DENIED:
                return this.renderStatusCell('Denied', 'times', 'danger');
            default:
                return this.renderStatusCell('Requested', 'clock-o', 'warning');
        }
    };

    get columns() {
        return [
            {
                Header: 'Vendor',
                accessor: 'contractParty.companyName',
            },
            {
                Header: 'Order Amount',
                accessor: 'amount',
                className: 'text-right',
                maxWidth: 120,
                Cell: (props) => currencyFormatter({ value: props.value }),
            },
            {
                Header: 'Paid Amount',
                accessor: 'paidAmount',
                className: 'text-right',
                maxWidth: 120,
                Cell: (props) => (props.value ? currencyFormatter({ value: props.value }) : null),
            },
            {
                Header: 'Status',
                accessor: 'status',
                className: 'text-center',
                maxWidth: 120,
                Cell: (props) => this.renderStatus(props.value),
            },
            {
                Header: 'Issue Date',
                accessor: 'date',
                maxWidth: 120,
                Cell: (props) => props.value && moment(props.value).format('ll'),
            },
            {
                Header: 'Fiscal Year',
                maxWidth: 60,
                accessor: 'tag.name',
            },
            {
                Header: 'PO #',
                accessor: 'number',
            },
            {
                Header: 'Department',
                accessor: 'department.name',
            },
            {
                Header: 'Last Updated',
                accessor: 'updated_at',
                Cell: (props) => (props.value ? moment(props.value).fromNow() : null),
            },
        ];
    }

    render() {
        const { className, editHandler, purchaseOrders, showCSVExport, title } = this.props;

        if (purchaseOrders.length === 0) {
            return <ZeroState title="No orders created yet" />;
        }

        return (
            <NoSsr>
                <DataTable
                    className={classnames('-highlight', '-striped', className)}
                    columns={this.columns}
                    csvExportOptions={{
                        fileName: `Orders - ${title}`,
                        getFormattedCSVData: this.formatDataForCSVExport,
                        headers: true,
                    }}
                    data={purchaseOrders}
                    getTrProps={(state, rowInfo) => {
                        // Bug in `react-table` passes undefined as rowInfo for extra rows with no data
                        // https://github.com/tannerlinsley/react-table/issues/1023
                        if (!rowInfo || !editHandler) {
                            return {};
                        }
                        return {
                            onClick: () => editHandler(rowInfo.original),
                            className: this.styles.purchaseOrderRow,
                        };
                    }}
                    pageSize={purchaseOrders.length}
                    showCSVExport={showCSVExport}
                    showPagination={false}
                    title={title}
                />
            </NoSsr>
        );
    }
}

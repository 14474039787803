const data = {};

export const localStoragePolyfill = {
    getItem: (key) => {
        if (process.env.SERVER) return false;

        if (localStorage && localStorage.getItem) return localStorage.getItem(key);
        return data[key];
    },
    setItem: (key, values) => {
        if (process.env.SERVER) return;

        if (localStorage && localStorage.setItem) {
            localStorage.setItem(key, values);
            return;
        }
        data[key] = values;
    },
    removeItem: (key) => {
        if (process.env.SERVER) return;

        if (localStorage && localStorage.removeItem) {
            localStorage.removeItem(key);
            return;
        }
        delete data[key];
    },
};

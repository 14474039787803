import { useMemo } from 'react';
import { getSectionNumberingString, numberItems } from '@og-pro/shared-config/helpers';
import { MULTIPLE_CHOICE, YES_NO } from '@og-pro/shared-config/questionnaires';

import { pseudoFieldNames } from '../constants';

const { UPFRONT_QUESTION } = pseudoFieldNames;

/**
 * Returns a list of upfront questions to be used in the upfront questions select (the first one),
 * and also a list of upfront questions values to be used in the third select.
 *
 * @param {array<object>} upfrontQuestions
 * @param {number} logicableId
 * @returns {object}
 */
export const useUpfrontQuestionsOptions = ({ upfrontQuestions, logicableId }) => {
    const upfrontQuestionsOptions = useMemo(() => {
        return numberItems(upfrontQuestions)
            .filter((upfrontQuestion) => {
                return upfrontQuestion.type === MULTIPLE_CHOICE || upfrontQuestion.type === YES_NO;
            })
            .map((upfrontQuestion) => {
                const { sectionNumber, subsectionNumber, conditionalSubQuestionNumber } =
                    upfrontQuestion;
                const sectionNumberString = getSectionNumberingString({
                    sectionNumber,
                    subsectionNumber,
                    subSectionItemNumber: conditionalSubQuestionNumber,
                    useLetterForSubsectionItem: false,
                });
                return {
                    label: `${sectionNumberString} ${upfrontQuestion.title}`,
                    [UPFRONT_QUESTION]: upfrontQuestion,
                    value: upfrontQuestion.id,
                };
            });
    }, [upfrontQuestions]);

    // the options for the third select are available after you select an upfront question
    const upfrontQuestionValueSelectOptions = useMemo(() => {
        if (!logicableId) {
            return [];
        }

        const upfrontQuestion = upfrontQuestions.find((q) => q.id === logicableId);

        if (upfrontQuestion?.type !== MULTIPLE_CHOICE && upfrontQuestion?.type !== YES_NO) {
            return [];
        }

        if (upfrontQuestion.type === YES_NO) {
            return [
                { label: 'Yes', value: true },
                { label: 'No', value: false },
            ];
        }

        return upfrontQuestion.data.options.map((option, index) => {
            return {
                label: option,
                value: index,
            };
        });
    }, [upfrontQuestions, logicableId]);

    return {
        upfrontQuestionsOptions,
        upfrontQuestionValueSelectOptions,
    };
};

import PropTypes from 'prop-types';

import { listToDict } from '../../../../utils';

export const evaluationPhaseFields = ['title'];
export const evaluationPhaseFieldNames = listToDict(evaluationPhaseFields);

export const criteriaFields = [
    'description',
    'isAdminScored',
    'isPubliclyHidden',
    'orderById',
    'rawDescription',
    'scoringMethod',
    'title',
    'weight',
];
export const criteriaFieldNames = listToDict(criteriaFields);

export const EVALUATION_CRITERIA = 'evaluationCriteria';

export const EVALUATION_CRITERIA_LIST_PROPTYPES = {
    canAddPhase: PropTypes.bool.isRequired,
    change: PropTypes.func.isRequired,
    deletePhaseHandler: PropTypes.func, // Required if `isMultiPhase`
    disabled: PropTypes.bool.isRequired,
    evaluationPhaseArrayName: PropTypes.string, // Required if `isMultiPhase`
    evaluationTitle: PropTypes.string, // Required if `isMultiPhase`
    fields: PropTypes.shape({
        forEach: PropTypes.func.isRequired,
        get: PropTypes.func.isRequired,
        length: PropTypes.number.isRequired,
        map: PropTypes.func.isRequired,
        move: PropTypes.func.isRequired,
        push: PropTypes.func.isRequired,
        remove: PropTypes.func.isRequired,
        splice: PropTypes.func.isRequired,
    }).isRequired,
    isEvaluationReleased: PropTypes.bool,
    isMultiPhase: PropTypes.bool,
    isOGThemeEnabledForComponents: PropTypes.bool,
    isTemplate: PropTypes.bool,
    meta: PropTypes.object.isRequired,
    renderQuestionLogicIcon: PropTypes.func,
    showConfirmationSimpleModal: PropTypes.func.isRequired,
    showFormErrors: PropTypes.bool.isRequired,
    skipEmptyFormInitialization: PropTypes.bool,
    totalWeight: PropTypes.number,
    useRawDescription: PropTypes.bool,
};

import { ButtonGroup, Button, buildQaTag, componentNames } from '@og-pro/ui';
import React from 'react';
import PropTypes from 'prop-types';

export const RadioButtons = ({ options, input, ...props }) => {
    const handleOnClick = (value) => {
        // Call the redux-form onChange function
        input.onChange(value);
    };

    return (
        <ButtonGroup color="primary" variant="outlined" {...props}>
            {options.map((option) => {
                const isSelected = input.value === option.value;
                const tagWithComponentName = buildQaTag(option.qaTag, componentNames.RADIO_ITEM);
                const color = isSelected ? 'primary' : 'secondary';
                const variant = isSelected ? 'contained' : 'outlined';

                return (
                    <Button
                        aria-selected={isSelected}
                        color={color}
                        key={option.value}
                        onClick={() => handleOnClick(option.value)}
                        qaTag={tagWithComponentName}
                        sx={(theme) => ({
                            borderColor: theme.palette.grey[800],
                            color: !isSelected && `${theme.palette.grey[800]} !important`,
                            whiteSpace: 'nowrap',
                        })}
                        variant={variant}
                    >
                        {option.label}
                    </Button>
                );
            })}
        </ButtonGroup>
    );
};

RadioButtons.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }).isRequired
    ).isRequired,
    input: PropTypes.object.isRequired, // Provided by redux-form
};

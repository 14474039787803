import { fromJS } from 'immutable';

import * as permissionActions from '../actions/govPermissions';

const initialState = fromJS({
    showModal: false,
});

export default function permissionsReducer(state = initialState, action = {}) {
    switch (action.type) {
        case permissionActions.RESET:
            return initialState;
        case permissionActions.SHOW_MODAL:
            return state.merge(
                fromJS({
                    showModal: true,
                    projectId: action.projectId,
                })
            );
        case permissionActions.HIDE_MODAL:
            return state.merge(
                fromJS({
                    showModal: false,
                    sendingError: null,
                })
            );
        case permissionActions.ADD_PERMISSION:
        case permissionActions.REMOVE_PERMISSION:
            return state.merge(
                fromJS({
                    sending: true,
                    sendingError: null,
                })
            );
        case permissionActions.ADD_PERMISSION_SUCCESS:
        case permissionActions.REMOVE_PERMISSION_SUCCESS:
            return state.merge(
                fromJS({
                    sending: false,
                })
            );
        case permissionActions.ADD_PERMISSION_FAIL:
        case permissionActions.REMOVE_PERMISSION_FAIL:
            return state.merge(
                fromJS({
                    sending: false,
                    sendingError: action.error && action.error.message,
                })
            );
        default:
            return state;
    }
}

import { createSelector } from 'reselect';

const getRawRequisitionCreateSections = (state) => state.requisitionsCreate.get('sections');

export const getIsDeleting = (state) => state.requisitions.get('deletingRequisition');
export const getDeleteError = (state) => state.requisitions.get('deleteRequisitionError');

export const getRequisitionsCreateSections = createSelector(
    [getRawRequisitionCreateSections],
    (rawSections) => rawSections.toJS()
);

export const getRequisitionsCreateEnabledSections = createSelector(
    [getRequisitionsCreateSections],
    (sections) =>
        Object.keys(sections).filter((sectionKey) => {
            const section = sections[sectionKey];
            return !section.disabled;
        })
);

export const getRequisitionsBasePath = (props) => {
    const { governmentId } = props.params;
    return `/governments/${governmentId}/requisitions`;
};

import { redirect } from 'react-router-dom';

import { isLoggedIn, isLoggingOut } from '../../actions/auth';
import { isUnauthorized } from '../../actions/error';
import { getUserHomepage } from '../../helpers';

/*
 * Redirects a user: if the user is logged in its taken to the proper index page.
 * Otherwise we let them reach the login page.
 */
export default (getState) => async () => {
    if (isLoggingOut(getState())) {
        return null;
    }

    const state = getState();

    if (isLoggedIn(state) && !isUnauthorized(state)) {
        const user = state.auth.get('user');
        const govId = user.get('government_id');

        if (govId) {
            return redirect(getUserHomepage(user.toJS()), { replace: true });
        }

        const vendorId = user.get('vendor_id');

        if (vendorId) {
            return redirect(`/vendors/${vendorId}`, { replace: true });
        }
    }

    return null;
};

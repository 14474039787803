import classNames from 'classnames';
import { times } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

export const ProcuratedStarsDisplay = (props) => {
    const styles = require('./index.scss');
    const { starSizeMultiplier, value = 0 } = props;

    const percentage = (value / 5) * 100;

    const renderStars = (starClass) => {
        return times(5, (i) => {
            return (
                <i
                    className={classNames(
                        `fa fa-${starSizeMultiplier}x fa-star ${styles.starStyle}`
                    )}
                    key={`${starClass}${i}`}
                />
            );
        });
    };

    return (
        <span className={styles.starContainer}>
            <div className={styles.starWrap}>
                <span className={styles.starsActive} style={{ width: `${percentage}%` }}>
                    {renderStars('activeStar')}
                </span>
                <span className={styles.starsInactive}>{renderStars('inActiveStar')}</span>
            </div>
        </span>
    );
};

ProcuratedStarsDisplay.propTypes = {
    starSizeMultiplier: PropTypes.number.isRequired,
    value: PropTypes.number,
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { Panel } from 'react-bootstrap';
import { Box, Skeleton } from '@og-pro/ui';

import { CDSButton, InputText } from '../../../../../components';
import { form, PROMPT } from './constants';
import { validate } from './validate';
import { getAiGeneratedScopeItemJS } from './selectors';
import { ScopeItem } from './ScopeItem';
import { trackEvent } from '../../../../../helpers';
import {
    clearAiScopeContent,
    generateAiScopeContent,
} from '../../../../../actions/govProjects/generativeAiScope';

const formSelector = formValueSelector(form);

const GenerativeAiPromptScopeForm = ({
    addSuggestedItems,
    govId,
    handleSubmit,
    invalid,
    projectId,
    submitFailed,
    toggleAiForm,
}) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [loadingMessageIdx, setLoadingMessageIdx] = useState(0);

    const aiGeneratedScopeItem = useSelector(getAiGeneratedScopeItemJS);
    const loadError = useSelector((state) =>
        state.govProjects.getIn(['selectedProject', 'generativeAiScope', 'loadError'])
    );
    const promptValue = useSelector((state) => formSelector(state, PROMPT));

    const loadingMessages = [
        'This may take a few moments',
        "We're still working on it",
        'Hold on tight',
    ];

    useEffect(() => {
        dispatch(clearAiScopeContent());
    }, []);

    useEffect(() => {
        let intervalId;

        if (loading) {
            intervalId = setInterval(() => {
                setLoadingMessageIdx((loadingMessageIdx + 1) % loadingMessages.length);
            }, 7000);
        }

        return () => clearInterval(intervalId);
    }, [loading, loadingMessageIdx]);

    const helpText = 'Write a detailed specification to be included in a scope of work for...';

    const {
        copyButton,
        footer,
        formContainer,
        formField,
        generateButton,
        heading,
        submitButton,
    } = require('./GenerativeAiPromptScope.scss');

    const label = <h4 className={heading}>Generate with AI</h4>;

    const setLoadingFalse = () => setLoading(false);

    const addSelectedItemsHandler = () => {
        trackEvent('Copy AI Generated Items to Scope of Work', {
            aiGeneratedScopeItem,
            prompt: promptValue,
        });
        addSuggestedItems([aiGeneratedScopeItem], true);
        toggleAiForm();
    };

    const submitHandler = (data) => {
        setLoading(true);
        dispatch(generateAiScopeContent({ ...data, projectId }, govId, setLoadingFalse));
    };

    return (
        <>
            <Panel>
                <Panel.Body>
                    <form className={formContainer} onSubmit={handleSubmit(submitHandler)}>
                        <div className={formField}>
                            <Field
                                autoFocus
                                component={InputText}
                                disabled={loading}
                                help={helpText}
                                label={label}
                                name={PROMPT}
                                overrideFeedback
                                placeholder="Complete the prompt"
                                showValidation={submitFailed && invalid}
                                type="text"
                                useOpenGovStyle
                            />
                            {loadError && <div className="error-block">{loadError}</div>}
                        </div>
                        <div className={submitButton}>
                            <CDSButton
                                className={generateButton}
                                disabled={loading}
                                qaTag="generativeAiForm-submit"
                                type="submit"
                                variant="primary"
                            >
                                Generate
                            </CDSButton>
                        </div>
                    </form>
                </Panel.Body>
                <Panel.Footer>
                    <span className={footer}>
                        <i className="fa fa-warning" /> Powered by <strong>OpenAI</strong>. May
                        produce inaccurate responses. Review items carefully.
                    </span>
                </Panel.Footer>
            </Panel>
            {loading && (
                <>
                    <Box mb={2} mt={2}>
                        <i className="fa fa-spinner fa-pulse fa-fw text-primary" />
                        &nbsp;{loadingMessages[loadingMessageIdx]}
                    </Box>
                    <Skeleton height="200px" />
                </>
            )}
            {aiGeneratedScopeItem && (
                <>
                    <ScopeItem {...aiGeneratedScopeItem} />
                    {!loading && (
                        <CDSButton
                            className={copyButton}
                            onClick={addSelectedItemsHandler}
                            qaTag="generativeAiForm-copyToScopeOfWork"
                            variant="primary"
                        >
                            Copy to Scope of Work
                        </CDSButton>
                    )}
                </>
            )}
        </>
    );
};

GenerativeAiPromptScopeForm.propTypes = {
    addSuggestedItems: PropTypes.func.isRequired,
    govId: PropTypes.number.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool.isRequired,
    projectId: PropTypes.number.isRequired,
    submitFailed: PropTypes.bool.isRequired,
    toggleAiForm: PropTypes.func.isRequired,
};

export const GenerativeAiPromptScope = reduxForm({
    form,
    validate,
})(GenerativeAiPromptScopeForm);

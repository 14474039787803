import PropTypes from 'prop-types';
import React from 'react';

import { SearchSelectMultiLabelOption } from '../../../../../../../../components';

export const ContractSearchOption = (props) => {
    const {
        data: {
            contract: { contractId, contractParty, title },
        },
    } = props;

    return (
        <SearchSelectMultiLabelOption
            {...props}
            label={title || 'Untitled Contract'}
            secondaryLabel={contractId}
            tertiaryLabel={contractParty?.companyName}
        />
    );
};

ContractSearchOption.propTypes = {
    data: PropTypes.shape({
        contract: PropTypes.shape({
            contractId: PropTypes.string,
            contractParty: PropTypes.shape({
                companyName: PropTypes.string,
            }),
            title: PropTypes.string,
        }).isRequired,
    }).isRequired,
};

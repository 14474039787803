import { createSelector } from 'reselect';

const getAwardedContractsSelector = (state) => state.vendProposals.get('awardedContracts');

export const getLoadingAwardedContracts = (state) =>
    state.vendProposals.get('loadingAwardedContracts');
export const getAwardedContractsError = (state) => state.vendProposals.get('awardedContractsError');

export const getAwardedContracts = createSelector([getAwardedContractsSelector], (contracts) => {
    if (contracts) {
        return contracts.toJS();
    }
});

import { connect, useDispatch } from 'react-redux';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { showConfirmationModal } from '../../actions/confirmation';
import { loadRetentionCode } from '../../actions/retention';
import { getRetentionCodeJS } from '../../containers/GovApp/selectors';
import { getContractJS } from '../../containers/selectors';
import { RetentionBadge } from './RetentionBadge';
import { UPDATE_RETENTION_POLICY } from '../../constants/menuActions';

const mapStateToProps = (state) => {
    return {
        loading: state.retention.get('loadingRetentionCode'),
        contract: getContractJS(state),
        retentionCode: getRetentionCodeJS(state),
    };
};

export const ConnectedContractRetentionPolicyButton = ({
    loading,
    contract,
    retentionCode,
    retentionCodeId,
}) => {
    const styles = require('./index.scss');
    const dispatch = useDispatch();

    useEffect(() => {
        if (retentionCodeId) {
            dispatch(loadRetentionCode({ retentionCodeId }));
        }
    }, [retentionCodeId]);

    const showRetentionPolicyModal = () => {
        return dispatch(
            showConfirmationModal(UPDATE_RETENTION_POLICY, {
                closeDate: contract.endDate,
                contractId: contract.id,
                retentionCodeId,
            })
        );
    };

    return (
        <span className={styles.clickableText} onClick={showRetentionPolicyModal}>
            <RetentionBadge
                loading={loading}
                retentionCode={retentionCode}
                retentionCodeId={retentionCodeId}
            />
            <i aria-label="edit" className={`fa fa-edit ${styles.icon}`} title="edit" />
        </span>
    );
};

ConnectedContractRetentionPolicyButton.propTypes = {
    contract: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    retentionCode: PropTypes.object,
    retentionCodeId: PropTypes.number,
};

export const ContractRetentionPolicyButton = connect(mapStateToProps)(
    ConnectedContractRetentionPolicyButton
);

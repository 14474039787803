import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { compose } from 'redux';

import { EvaluatorScoresExpanded } from './EvaluatorScoresExpanded';
import { getProposals } from './selectors';
import { getTotalWeight } from '../../selectors';
import { EvaluatorScoresTable as EvaluatorScoresTableComponent } from '../../../../../components';

const mapStateToProps = (state, props) => {
    return {
        proposals: getProposals(state, props),
        showComments: state.proposalEvaluations.get('showEvaluatorComments'),
        showCriteriaDescriptions: state.proposalEvaluations.get('showCriteriaDescriptions'),
        showExpandedView: state.proposalEvaluations.get('showExpandedView'),
        showPercentageTotals: state.proposalEvaluations.get('showPercentageTotals'),
        totalWeight: getTotalWeight(state, props),
    };
};

// @withRouter
// @connect
class ConnectedEvaluatorScoresTable extends Component {
    static propTypes = {
        createRef: PropTypes.func.isRequired,
        evaluator: PropTypes.shape({
            displayName: PropTypes.string.isRequired,
        }),
        isConsensusView: PropTypes.bool,
        proposals: PropTypes.arrayOf(
            PropTypes.shape({
                note: PropTypes.string,
                proposalEvaluation: PropTypes.shape({
                    submittedProposalCriteriaScores: PropTypes.arrayOf(
                        PropTypes.shape({
                            comment: PropTypes.string,
                            score: PropTypes.number,
                        })
                    ).isRequired,
                }).isRequired,
                totalScore: PropTypes.number,
            })
        ).isRequired,
        scoringCriteria: PropTypes.array.isRequired,
        showComments: PropTypes.bool,
        showCriteriaDescriptions: PropTypes.bool,
        showEvaluationScorecard: PropTypes.func,
        showExpandedView: PropTypes.bool,
        showPercentageTotals: PropTypes.bool,
        showUnsubmittedScores: PropTypes.bool,
        totalWeight: PropTypes.number.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const {
            createRef,
            evaluator,
            isConsensusView,
            proposals,
            scoringCriteria,
            showComments,
            showCriteriaDescriptions,
            showEvaluationScorecard,
            showExpandedView,
            showPercentageTotals,
            showUnsubmittedScores,
            totalWeight,
        } = this.props;

        // DataTable always needs to be included for CSV export to work
        return (
            <>
                <EvaluatorScoresTableComponent
                    className={showExpandedView ? this.styles.hiddenTable : undefined}
                    createRef={createRef}
                    evaluator={evaluator}
                    hide={showExpandedView}
                    isConsensusView={isConsensusView}
                    proposals={proposals}
                    scoringCriteria={scoringCriteria}
                    showComments={showComments}
                    showCriteriaDescriptions={showCriteriaDescriptions}
                    showEvaluationScorecard={showEvaluationScorecard}
                    showPercentageTotals={showPercentageTotals}
                    showUnsubmittedScores={showUnsubmittedScores}
                    totalWeight={totalWeight}
                />
                {showExpandedView && (
                    <EvaluatorScoresExpanded
                        proposals={proposals}
                        scoringCriteria={scoringCriteria}
                        showComments={showComments}
                        showCriteriaDescriptions={showCriteriaDescriptions}
                        showPercentageTotals={showPercentageTotals}
                        totalWeight={totalWeight}
                    />
                )}
            </>
        );
    }
}

export const EvaluatorScoresTable = compose(
    withRouter,
    connect(mapStateToProps)
)(ConnectedEvaluatorScoresTable);

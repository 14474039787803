import { createSelector } from 'reselect';

const getConfirmationData = (state) => state.confirmation.get('data');

export const getData = createSelector([getConfirmationData], (rawData) => {
    if (rawData) {
        return rawData.toJS();
    }
    return {};
});

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    getContractReqRelationsLoaded,
    getContractRequisitions,
    getProjectReqRelationsLoaded,
    getProjectRequisitions,
} from './selectors';
import { loadContractReqRelations } from '../../actions/contracts';
import { loadProjectReqRelations } from '../../actions/govProjects';
import { hasRequisitionSubscription } from '../../containers/GovApp/selectors';

const useGetReqRelations = ({ apiCall, loaded, requisitions, resourceId }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const hasRequisitions = useSelector(hasRequisitionSubscription);

    useEffect(() => {
        const getReqRelations = async () => {
            setLoading(true);
            setError(null);
            try {
                await apiCall();
            } catch (e) {
                setError(e.message);
            } finally {
                setLoading(false);
            }
        };

        if (hasRequisitions && resourceId && !loaded && !loading) {
            getReqRelations();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasRequisitions, resourceId]);

    return { loading, error, requisitions };
};

export const useGetContractReqRelations = ({ contractId }) => {
    const dispatch = useDispatch();

    const loaded = useSelector(getContractReqRelationsLoaded);
    const requisitions = useSelector(getContractRequisitions);

    return useGetReqRelations({
        apiCall: () => dispatch(loadContractReqRelations(contractId)),
        loaded,
        requisitions,
        resourceId: contractId,
    });
};

export const useGetProjectReqRelations = ({ projectId }) => {
    const dispatch = useDispatch();

    const loaded = useSelector(getProjectReqRelationsLoaded);
    const requisitions = useSelector(getProjectRequisitions);

    return useGetReqRelations({
        apiCall: () => dispatch(loadProjectReqRelations(projectId)),
        loaded,
        requisitions,
        resourceId: projectId,
    });
};

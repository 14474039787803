import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box } from '@og-pro/ui';

import { ProjectCreateV2FunctionsContext } from '../../context';
import {
    BaseSectionsNavBackButton,
    BaseSectionsNavBackNextButtons,
    BaseSectionsNavNextButton,
    CDSButton,
    SectionHeaderViewTitleOnly,
} from '../../../../../../components/SDv2';

export const ReviewContainer = ({
    children,
    description,
    onSubmit,
    showingSignaturesTab,
    showSubmitButton,
    submitButtonText,
    title,
}) => {
    const styles = require('./index.scss');

    const navigate = useNavigate();
    const { buildRouteFunction, showContractCompiler } = useContext(
        ProjectCreateV2FunctionsContext
    );
    const lastSectionComponent = () => {
        if (showContractCompiler) {
            return (
                <BaseSectionsNavNextButton
                    onClick={() => navigate(buildRouteFunction('approvals-editor'))}
                >
                    Approvals
                </BaseSectionsNavNextButton>
            );
        }

        if (showSubmitButton) {
            return (
                <CDSButton onClick={onSubmit} qaTag="reviewChecklist-review" variant="primary">
                    {submitButtonText}
                </CDSButton>
            );
        }

        return null;
    };

    return (
        <Box className={styles.container}>
            <Box className={styles.header}>
                <SectionHeaderViewTitleOnly description={description} title={title} />
            </Box>
            <Box className={styles.content}>{children}</Box>
            <Box className={styles.footer}>
                <BaseSectionsNavBackNextButtons
                    firstSectionComponent={
                        showingSignaturesTab ? (
                            <BaseSectionsNavBackButton
                                onClick={() => navigate(buildRouteFunction('signatures'))}
                            >
                                Signatures
                            </BaseSectionsNavBackButton>
                        ) : (
                            <BaseSectionsNavBackButton
                                onClick={() => navigate(buildRouteFunction('attachments'))}
                            >
                                Attachments
                            </BaseSectionsNavBackButton>
                        )
                    }
                    lastSectionComponent={lastSectionComponent()}
                    sections={[]}
                />
            </Box>
        </Box>
    );
};

ReviewContainer.propTypes = {
    children: PropTypes.node,
    description: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    showingSignaturesTab: PropTypes.bool,
    showSubmitButton: PropTypes.bool,
    submitButtonText: PropTypes.string.isRequired,
    title: PropTypes.string,
};

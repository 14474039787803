/* eslint no-use-before-define: ["error", { "functions": false }] */

// NOTE: every callback should dispatch at least a single action describing what happened
// NOTE: if your callback calls another function which _could_ return something (such as a Promise),
// don't forget to return the call to it so that our promise chain isn't broken.
// NOTE: if you create a callback that calls another callback, you're probably doing something that
// doesn't match the pattern of how we use actions. Do it in the reducer via the action.

import { showSnackbar } from './notification';
import { resourceManager } from '../helpers';

export const LOAD = 'calendar/LOAD';
export const LOAD_PUBLIC = 'calendar/LOAD_PUBLIC';
export const LOAD_FAIL = 'calendar/LOAD_FAIL';
export const LOAD_SUCCESS = 'calendar/LOAD_SUCCESS';
export const LOAD_PUBLIC_SUCCESS = 'calendar/LOAD_PUBLIC_SUCCESS';

const generateLoadCalendarOptions = ({ context }) => {
    return { data: context };
};

const onLoadCalendarFailure = ({ error, dispatch }) => {
    dispatch({ type: LOAD_FAIL, error });
    dispatch(showSnackbar(`Loading calendar failed: ${error.message}`, { isError: true }));
};

const onLoadCalendarStart = ({ dispatch }) => {
    dispatch({ type: LOAD });
};

const onLoadPublicCalendarStart = ({ dispatch }) => {
    dispatch({ type: LOAD_PUBLIC });
};

const onLoadCalendarSuccess = ({ result, dispatch }) => {
    dispatch({ type: LOAD_SUCCESS, result });
};

const onLoadPublicCalendarSuccess = ({ result, dispatch }) => {
    dispatch({ type: LOAD_PUBLIC_SUCCESS, result });
};

/**
 * Load a government's calendar.
 * @param {number} governmentId The id of the government
 * @param {object} options Any additional options to pass to the request, such as filters
 * @return {Function} The built resource fetcher for the government's calender
 */
export function loadCalendar(governmentId, options) {
    return resourceManager({
        method: 'post',
        url: `/government/${governmentId}/calendar`,
        requestOptions: generateLoadCalendarOptions,
        onStart: onLoadCalendarStart,
        onSuccess: onLoadCalendarSuccess,
        onFailure: onLoadCalendarFailure,
        context: options,
    });
}

/**
 * Load a government's public calendar.
 * @param {string} govCode The government's code
 * @return {Function} The built resource fetcher for the government's calender
 */
export function loadPublicCalendar(govCode) {
    return resourceManager({
        method: 'get',
        url: `/government/${govCode}/calendar/public`,
        onStart: onLoadPublicCalendarStart,
        onSuccess: onLoadPublicCalendarSuccess,
        onFailure: onLoadCalendarFailure,
    });
}

import { get } from 'lodash';
import { isEmail } from 'validator';

import { mappedContactFields } from '@og-pro/shared-config/contacts';

import {
    DESCRIPTION,
    DISCOUNT,
    NO_BID,
    QUANTITY,
    UNIT_PRICE,
    UNIT_TO_MEASURE,
} from '@og-pro/shared-config/priceTables';

import { recordDocumentTypesDict } from '@og-pro/shared-config/recordsRetention';

import {
    INFORMATION,
    QUESTIONNAIRE,
    PRICING,
    ADDENDA,
    COMPANY_PROFILE,
    SUBMIT,
} from '../../ProposalCreateNav/constants';
import {
    address,
    phone,
    questionnaireResponse,
    sectionsValidate,
    website,
} from '../../../Forms/validation';
import { warnPricingTables, warnQuestionnaire } from './warn';

const { DOCUMENTS } = recordDocumentTypesDict;

// Some of ours values might be 0, so we need to be explicit about what not exists means
const exists = (value) => {
    return value !== undefined && value !== null && value !== '';
};

const isPriceItemMissing = (field, priceItem) => {
    return !exists(priceItem[field]) && !exists(get(priceItem, ['vendorResponse', field]));
};

const isNumeric = (field, priceItem) => {
    const vendorResponse = get(priceItem, ['vendorResponse', field]);
    const valueToCheck = exists(vendorResponse) ? vendorResponse : priceItem[field];

    const parsedNumber = Number.parseFloat(valueToCheck);
    if (Number.isNaN(parsedNumber)) {
        return false;
    }

    if (parsedNumber >= 100000000) {
        return false;
    }

    return true;
};

const validateContactInformation = (values, props) => {
    const errors = {
        ...address(values, {
            fieldNames: mappedContactFields,
            optional: props.proposal.isGovernmentSubmitted,
        }),
        ...phone(values, { fieldNames: mappedContactFields, optional: true }),
        ...website(values, { fieldName: 'contactWebsite', optional: true }),
    };

    if (!values.contactEmail) {
        errors.contactEmail = 'This field is required';
    } else if (!isEmail(values.contactEmail)) {
        errors.contactEmail = 'Invalid email address';
        // Hack: Used only by the `invalidSave` validator below
        errors.emailInvalid = true;
    }

    if (!values.companyName) {
        errors.companyName = 'This field is required';
    } else if (values.companyName.length > 256) {
        errors.companyName = 'Name is too long';
    }

    if (values.contactFirstName && values.contactFirstName.length > 32) {
        errors.contactFirstName = 'Name is too long';
    }

    if (values.contactLastName && values.contactLastName.length > 32) {
        errors.contactLastName = 'Name is too long';
    }

    if (errors.emailInvalid || errors.contactPhone || errors.contactWebsite) {
        // Hack: error value is used to disable the save button, it has no
        // other function in form. Needed so server does not fail validation.
        errors.invalidSave = true;
    }

    return errors;
};

const validateDocumentsUpload = (values) => {
    const errors = {};

    errors.proposalDocuments = (values.proposalDocuments || []).map((doc) => {
        const docError = {};

        // Required documents must have an attachment uploaded
        if (doc.isRequired && doc.attachments.length === 0) {
            docError.isRequired = 'At least one file must be uploaded';
        }

        return docError;
    });

    return errors;
};

const validateQuestionnaire = (values, props) => {
    const errors = {};

    // For governments manually creating a proposal we want to allow them to skip the questionnaire
    if (props.skipQuestionnaireValidation) {
        return errors;
    }

    errors.questionnaires = (values.questionnaires || []).map(questionnaireResponse);

    return errors;
};

const validatePricingTables = (values) => {
    const errors = {};

    errors.priceTables = (values.priceTables || []).map((priceTable) => {
        return {
            priceItems: priceTable.priceItems.map((priceItem) => {
                const err = {};
                const noBid = get(priceItem, ['vendorResponse', NO_BID]);

                if (priceItem.isHeaderRow) {
                    return err;
                }

                if (isPriceItemMissing(DESCRIPTION, priceItem)) {
                    err.description = 'Description is required';
                }
                if (priceTable.hasQuantity && isPriceItemMissing(QUANTITY, priceItem)) {
                    if (!noBid) {
                        err.quantity = 'Quantity is required';
                    }
                } else if (priceTable.hasQuantity && !isNumeric(QUANTITY, priceItem)) {
                    err.quantity = 'Quantity must be a valid number';
                    errors.invalidSave = true; // Prevent saving when number is not valid
                }
                if (isPriceItemMissing(UNIT_PRICE, priceItem)) {
                    if (!noBid && !priceItem.discountOnly) {
                        err.unitPrice = 'Unit Cost is required';
                    }
                } else if (!isNumeric(UNIT_PRICE, priceItem)) {
                    err.unitPrice = 'Unit Cost must be a valid number';
                    errors.invalidSave = true; // Prevent saving when number is not valid
                }
                if (isPriceItemMissing(UNIT_TO_MEASURE, priceItem)) {
                    err.unitToMeasure = 'Unit to Measure is required';
                }
                if (priceTable.hasDiscount && !noBid) {
                    if (isPriceItemMissing(DISCOUNT, priceItem)) {
                        err.discount = 'Discount is required';
                    } else if (!isNumeric(DISCOUNT, priceItem)) {
                        err.discount = 'Discount must be a valid number';
                        errors.invalidSave = true; // Prevent saving when number is not valid
                    }
                }

                return err;
            }),
        };
    });

    return errors;
};

const validateAddendums = (values, props) => {
    const errors = {};

    // For governments manually creating a proposal we want to allow them to skip the addenda
    if (!props.proposal.hasAddendumSection) {
        return errors;
    }

    if (!values.isAddendumConfirmed) {
        errors.isAddendumConfirmed = 'Please confirm your agreement';
    }

    errors.addendums = (values.addendums || []).map((addendum) => {
        if ((addendum.addendumConfirms || []).length === 0) {
            return 'Please confirm that you have received and read this addendum';
        }
        return null;
    });

    return errors;
};

const validateCompanyProfile = (values, props) => {
    const errors = {};

    // No need to perform validation if company profile section is skipped
    if (props.skipCompanyProfile) {
        return errors;
    }

    if (!props.isVendorComplete) {
        errors.isVendorComplete = true;
    }

    return errors;
};

export const proposalCreateValidate = (values, props) => {
    const contactInformationErrors = validateContactInformation(values, props);
    const documentsUploadErrors = validateDocumentsUpload(values);
    const questionnaireErrors = validateQuestionnaire(values, props);
    const pricingTableErrors = validatePricingTables(values);
    const addendumsConfirmErrors = validateAddendums(values, props);
    const companyProfileErrors = validateCompanyProfile(values, props);

    const normalSectionErrors = sectionsValidate(
        [INFORMATION, contactInformationErrors],
        [DOCUMENTS, documentsUploadErrors],
        [QUESTIONNAIRE, questionnaireErrors],
        [PRICING, pricingTableErrors],
        [ADDENDA, addendumsConfirmErrors],
        [COMPANY_PROFILE, companyProfileErrors]
    );

    const submitSectionErrors = sectionsValidate([SUBMIT, normalSectionErrors]);

    const sectionErrors = {
        sections: {
            ...normalSectionErrors.sections,
            ...submitSectionErrors.sections,
        },
    };

    return {
        ...contactInformationErrors,
        ...documentsUploadErrors,
        ...questionnaireErrors,
        ...pricingTableErrors,
        ...addendumsConfirmErrors,
        ...companyProfileErrors,
        ...sectionErrors,
    };
};

export const proposalCreateWarn = (values) => {
    const pricingTableWarnings = warnPricingTables(values);
    const questionnaireWarnings = warnQuestionnaire(values);
    const normalSectionWarnings = sectionsValidate(
        [PRICING, pricingTableWarnings],
        [QUESTIONNAIRE, questionnaireWarnings]
    );

    const submitSectionWarnings = sectionsValidate([SUBMIT, normalSectionWarnings]);

    const sectionWarnings = {
        sections: {
            ...normalSectionWarnings.sections,
            ...submitSectionWarnings.sections,
        },
    };

    return {
        ...pricingTableWarnings,
        ...questionnaireWarnings,
        ...sectionWarnings,
        _warning: { ...sectionWarnings },
    };
};

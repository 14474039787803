import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { getUsersById } from '../selectors';
import { UserProfileList } from '../../../../../components';

export const RequisitionGroupSequence = ({ positions, sequence }) => {
    const getUserById = useSelector(getUsersById);

    if (!sequence) {
        return 'No sequence selected';
    }

    const styles = require('./shared.scss');

    return (
        <div>
            <i className="fa fa-fw fa-exchange" /> Sequence: {sequence.name}
            {positions.map(({ position, reviewGroupPositionUsers }) => {
                const users = reviewGroupPositionUsers
                    .map((reviewGroupPositionUser) => getUserById[reviewGroupPositionUser.user_id])
                    .filter((user) => user);
                return (
                    <div className={styles.reviewGroupPositionUsersList} key={position.id}>
                        <i className="fa fa-fw fa-user-plus" /> {position.name} (
                        {reviewGroupPositionUsers.length})&nbsp;&nbsp;&nbsp;
                        <UserProfileList className={styles.userProfileList} users={users} />
                    </div>
                );
            })}
        </div>
    );
};

RequisitionGroupSequence.propTypes = {
    positions: PropTypes.array.isRequired,
    sequence: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }),
};

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { projectStatusesDict } from '@og-pro/shared-config/projects';

import { Button } from '../../../../../components';
import { trackEvent } from '../../../../../helpers';

const { DRAFT, REQUEST_DRAFT } = projectStatusesDict;

class ConnectedControlButtons extends PureComponent {
    static propTypes = {
        backRoute: PropTypes.string,
        builderDisplayName: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
        isLastPage: PropTypes.bool.isRequired,
        meta: PropTypes.shape({
            valid: PropTypes.bool.isRequired,
        }).isRequired,
        nextRoute: PropTypes.string,
        projectStatus: PropTypes.string.isRequired,
        reviewPath: PropTypes.string.isRequired,
        router: PropTypes.object.isRequired,
        saveThenNavigate: PropTypes.func.isRequired,
        styledContainer: PropTypes.bool,
        writingPath: PropTypes.string.isRequired,
    };

    static defaultProps = {
        styledContainer: true,
    };

    get styles() {
        return require('./ControlButtons.scss');
    }

    handleBackClick = () => {
        const { backRoute, saveThenNavigate } = this.props;

        trackEvent('Back Footer Button', { route: backRoute });
        saveThenNavigate(backRoute, true);
    };

    handleBackToProjectClick = () => {
        const { reviewPath } = this.props;

        this.props.router.push(reviewPath);
    };

    handleNextClick = () => {
        const { nextRoute, saveThenNavigate } = this.props;

        trackEvent('Next Footer Button', { route: nextRoute });
        saveThenNavigate(nextRoute, true);
    };

    handleReviewOrCheckClick = () => {
        const { saveThenNavigate, writingPath } = this.props;

        trackEvent('Project confirmation', { location: 'controls' });
        saveThenNavigate(`${writingPath}?section=confirmation`, true);
    };

    renderBackButton() {
        const { disabled, backRoute } = this.props;

        if (backRoute) {
            return (
                <Button
                    bsStyle="link"
                    className={`back-btn ${this.styles.navButton}`}
                    disabled={disabled}
                    onClick={this.handleBackClick}
                    qaTag="controlButtons-back"
                >
                    <i className="fa fa-angle-left fa-lg" /> Back
                </Button>
            );
        }
    }

    renderNextButton() {
        const {
            builderDisplayName,
            disabled,
            isLastPage,
            meta: { valid },
            nextRoute,
            projectStatus,
        } = this.props;

        const isDraft = [DRAFT, REQUEST_DRAFT].includes(projectStatus);

        if (nextRoute) {
            const isPrimaryAction = !valid && isDraft;
            return (
                <Button
                    bsStyle={isPrimaryAction ? 'success' : 'link'}
                    className={`next-btn ${this.styles.navButton}`}
                    disabled={disabled}
                    onClick={this.handleNextClick}
                    qaTag="controlButtons-next"
                >
                    Next <i className="fa fa-angle-right fa-lg" />
                </Button>
            );
        }

        if (isLastPage && isDraft) {
            return (
                <Button
                    bsStyle="default"
                    disabled={disabled}
                    onClick={this.handleReviewOrCheckClick}
                    qaTag="controlButtons-reviewOrCheck"
                >
                    Check for Errors
                </Button>
            );
        }

        if (isLastPage) {
            return (
                <Button
                    disabled={disabled}
                    onClick={this.handleBackToProjectClick}
                    qaTag="controlButtons-backToReview"
                >
                    <i className="fa fa-mail-reply" /> Back to {builderDisplayName} Review
                </Button>
            );
        }
    }

    render() {
        const { styledContainer } = this.props;
        return (
            <div className={classnames('row', styledContainer && this.styles.container)}>
                <div className="col-xs-12">
                    <div className="pull-left">{this.renderBackButton()}</div>
                    <div className="pull-right">{this.renderNextButton()}</div>
                </div>
            </div>
        );
    }
}

export const ControlButtons = withRouter(ConnectedControlButtons);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { NavItem, Tooltip, LinkContainer } from '..';

export class ActionToolbarNavbarItem extends PureComponent {
    static propTypes = {
        editOnly: PropTypes.bool,
        isEditor: PropTypes.bool,
        children: PropTypes.node.isRequired,
        disabled: PropTypes.bool,
        tooltip: PropTypes.string,
        tooltipTitle: PropTypes.string,
        className: PropTypes.string,
        href: PropTypes.string,
        onClick: PropTypes.func,
        qaTag: PropTypes.string.isRequired, // Format: <pageName>-<simpleDescription>
    };

    get styles() {
        return require('./ActionToolbar.scss');
    }

    renderTooltipNavItem() {
        return (
            <Tooltip
                className={this.styles.popover}
                container={this}
                isPopover
                placement="bottom"
                rootClose
                tooltip={this.props.tooltip}
                tooltipTitle={this.props.tooltipTitle}
                trigger="click"
            >
                {this.renderNavbarItem()}
            </Tooltip>
        );
    }

    renderNavItem() {
        const { onClick, disabled, className, children, qaTag } = this.props;
        return (
            <NavItem className={className} disabled={disabled} onClick={onClick} qaTag={qaTag}>
                {children}
            </NavItem>
        );
    }

    renderNavbarItem() {
        if (!this.props.href) return this.renderNavItem();
        return (
            <LinkContainer disabled={this.props.disabled} to={this.props.href}>
                {this.renderNavItem()}
            </LinkContainer>
        );
    }

    render() {
        if (this.props.editOnly && !this.props.isEditor) return null;
        if (this.props.tooltip) return this.renderTooltipNavItem();
        return this.renderNavbarItem();
    }
}

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import { createQuestionnaire } from '../../../../../../../actions/templatesAdmin';
import { fieldNames } from '../../constants';
import { QuestionLogicIcon } from '../../../../components';
import { VendorQuestionnaireRenderLogicIcon } from '../../../../../ConditionalLogicIcon/VendorQuestionnaireRenderLogicIcon';
import {
    hasSourcingSubscription,
    questionnaireConditionalEnabled as getQuestionnaireConditionalEnabled,
} from '../../../../../selectors';
import { QuestionnairesForm } from '../../../../../../../components';
import { OGThemeContext } from '../../../../../ogThemeProvider';

const { QUESTIONNAIRES } = fieldNames;

const mapStateToProps = (state, props) => {
    const formSelector = formValueSelector(props.form);
    return {
        hasSourcing: hasSourcingSubscription(state),
        questionnaires: formSelector(state, QUESTIONNAIRES) || [],
        questionnaireConditionalEnabled: getQuestionnaireConditionalEnabled(state),
    };
};

const mapDispatchToProps = {
    createQuestionnaire,
};

// @connect
class ConnectedTemplateQuestionnairesForm extends Component {
    static propTypes = {
        addConditionalLogicHandler: PropTypes.func,
        array: PropTypes.object.isRequired,
        change: PropTypes.func.isRequired,
        // determines whether we create the question directly once it's added
        // making the necessary API call. Needed to handle conditional questions properly
        createBeforeAdd: PropTypes.bool,
        createQuestionnaire: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        hasSourcing: PropTypes.bool,
        hideQuestionLogic: PropTypes.bool,
        projectId: PropTypes.number,
        projectSection: PropTypes.object.isRequired,
        questionnaires: PropTypes.array.isRequired,
        questionnaireConditionalEnabled: PropTypes.bool.isRequired,
        questionLogicLinkable: PropTypes.string.isRequired,
        showFormValidation: PropTypes.bool,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
    };

    static contextType = OGThemeContext;

    renderQuestionLogicIcon = (questionnaire, showCreateQuestionnaireModal) => {
        const {
            addConditionalLogicHandler,
            hideQuestionLogic,
            projectSection,
            questionnaires,
            questionnaireConditionalEnabled,
            questionLogicLinkable,
        } = this.props;
        const { isOGThemeEnabledForComponents } = this.context;

        return (
            <>
                <div>
                    <QuestionLogicIcon
                        hidden={hideQuestionLogic}
                        linkable={questionLogicLinkable}
                        linkableItem={questionnaire}
                        projectSection={projectSection}
                    />
                </div>
                {!projectSection.isTemplate &&
                    VendorQuestionnaireRenderLogicIcon(
                        addConditionalLogicHandler,
                        questionnaires,
                        questionnaireConditionalEnabled && !hideQuestionLogic,
                        isOGThemeEnabledForComponents
                    )(questionnaire, showCreateQuestionnaireModal)}
            </>
        );
    };

    createQuestionnaire = async (data) => {
        return this.props.createQuestionnaire(this.props.projectId, data);
    };

    renderForm() {
        const {
            array,
            change,
            createBeforeAdd,
            disabled,
            hasSourcing,
            projectId,
            projectSection,
            questionnaires,
            showFormValidation,
            tagOptions,
            templateVariableOptions,
        } = this.props;

        return (
            <QuestionnairesForm
                array={array}
                change={change}
                createBeforeAddingHandler={
                    createBeforeAdd && projectId ? this.createQuestionnaire : null
                }
                disabled={disabled}
                formKey={QUESTIONNAIRES}
                includeContainsPricingOption={hasSourcing}
                isTemplate={projectSection.isTemplate}
                questionnaires={questionnaires}
                renderQuestionLogicIcon={this.renderQuestionLogicIcon}
                rerenderOnEveryChange
                showFormErrors={showFormValidation}
                tagOptions={tagOptions}
                templateVariableOptions={templateVariableOptions}
                validateBidBond={false}
            />
        );
    }

    render() {
        return <div>{this.renderForm()}</div>;
    }
}

export const TemplateQuestionnairesForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedTemplateQuestionnairesForm);

import { REQUIRED_TEXT } from './constants';

export function budget(values, props) {
    const budgetErrors = {};

    const budgetValue = values.budget || {};

    if (props.isBudgetUsed && props.isBudgetRequired && !budgetValue.amount) {
        budgetErrors.amount = REQUIRED_TEXT;
    } else if (budgetValue.amount && budgetValue.amount > 2000000000) {
        budgetErrors.amount = 'Budget amount is too high';
    }

    if (props.isBudgetUsed && props.isBudgetRequired && !budgetValue.description) {
        budgetErrors.description = REQUIRED_TEXT;
    } else if (budgetValue.description && budgetValue.description.length > 256) {
        budgetErrors.description = 'Description is too long';
    }

    if (props.isBudgetUsed && props.isBudgetRequired && !budgetValue.identifier) {
        budgetErrors.identifier = REQUIRED_TEXT;
    } else if (budgetValue.identifier && budgetValue.identifier.length > 64) {
        budgetErrors.identifier = 'ID is too long';
    }

    return {
        budget: budgetErrors,
    };
}

import { orderBy } from 'lodash';
import { formValueSelector } from 'redux-form';
import { createSelector } from 'reselect';

import { form, fieldNames } from './components/ContentFilters/constants';

const getSortDirections = (state) => state.admin.get('sortDirections');
const sortField = (state) => state.admin.get('contentSortField');
const getSuggestedContent = (state) => state.admin.get('suggestedContent');

export const getFormSectionType = (state) => {
    return formValueSelector(form)(state, fieldNames.SECTION_TYPE);
};

const getUnsortedSuggestedContentJS = createSelector(
    [getSuggestedContent, getFormSectionType],
    (rawContent, sectionType) => {
        if (rawContent) {
            return rawContent.toJS().filter((content) => content.section_type === sectionType);
        }
        return [];
    }
);

export const getSortDirectionsJS = createSelector([getSortDirections], (rawDirections) => {
    if (rawDirections) {
        return rawDirections.toJS();
    }
    return {};
});

export const getSuggestedContentJS = createSelector(
    [getUnsortedSuggestedContentJS, sortField, getSortDirectionsJS],
    (content, field, directions) => {
        return orderBy(content, field, [directions[field]]);
    }
);

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { onKeyDown } from '../../../helpers/accessibilityHelper';

export class StarDisplay extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        isHighlighted: PropTypes.bool.isRequired,
        highlightColor: PropTypes.string.isRequired,
        number: PropTypes.number.isRequired,
        onSelect: PropTypes.func,
        removeHoverValue: PropTypes.func.isRequired,
        setHoverValue: PropTypes.func.isRequired,
        size: PropTypes.number.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    onSelect = () => this.props.onSelect(this.props.number);

    setHoverValue = () => this.props.setHoverValue(this.props.number);

    render() {
        const { disabled, highlightColor, isHighlighted, onSelect, removeHoverValue, size } =
            this.props;

        const allowSelection = !!onSelect && !disabled;

        return (
            <span
                className={classnames(
                    `fa-stack fa-${size}x`,
                    this.styles.starContainer,
                    allowSelection && this.styles.selectable,
                    !isHighlighted && this.styles.unhighlighted,
                    disabled && this.styles.disabled
                )}
                onClick={allowSelection ? this.onSelect : undefined}
                onKeyDown={(event) => onKeyDown(event, this.onSelect)}
                onMouseEnter={allowSelection ? this.setHoverValue : undefined}
                onMouseLeave={allowSelection ? removeHoverValue : undefined}
                style={{ color: isHighlighted ? highlightColor : undefined }}
                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                tabIndex={allowSelection ? 0 : undefined}
            >
                <i className={classnames('fa fa-stack-2x fa-square')} />
                <i className={classnames('fa fa-stack-1x fa-inverse fa-star')} />
            </span>
        );
    }
}

import { fromJS } from 'immutable';

import * as publicVendorActions from '../actions/publicVendor';

const initialState = fromJS({
    loadingVendor: false,
    loadVendorError: null,
    vendor: null,
});

export default function publicVendorReducer(state = initialState, action = {}) {
    switch (action.type) {
        case publicVendorActions.LOAD_PUBLIC_VENDOR:
            return state.merge(
                fromJS({
                    loadingVendor: true,
                    loadVendorError: null,
                    vendor: null,
                })
            );
        case publicVendorActions.LOAD_PUBLIC_VENDOR_SUCCESS:
            return state.merge(
                fromJS({
                    loadingVendor: false,
                    loadVendorError: null,
                    vendor: fromJS(action.result),
                })
            );
        case publicVendorActions.LOAD_PUBLIC_VENDOR_FAIL:
            return state.merge(
                fromJS({
                    loadingVendor: false,
                    loadVendorError: action?.error ? action.error : 'Error loading vendor',
                    vendor: null,
                })
            );
        default:
            return state;
    }
}

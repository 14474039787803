import { fromJS } from 'immutable';

import * as requestTypeActions from '../actions/requestTypes';

const initialState = fromJS({
    list: [],
    loadingList: false,
    loadingItem: false,
    loadItemError: null,
    loadListError: null,
    requestType: null,
});

export default function requestTypesReducer(state = initialState, action = {}) {
    switch (action.type) {
        case requestTypeActions.LOAD_LIST:
            return state.merge(
                fromJS({
                    loadingList: true,
                    loadListError: null,
                })
            );
        case requestTypeActions.LOAD_LIST_SUCCESS:
            return state.merge(
                fromJS({
                    loadingList: false,
                    list: fromJS(action.result),
                })
            );
        case requestTypeActions.LOAD_LIST_FAIL:
            return state.merge(
                fromJS({
                    loadingList: false,
                    loadListError: action.error?.message,
                })
            );
        case requestTypeActions.LOAD_ITEM:
            return state.merge(
                fromJS({
                    loadingItem: true,
                    loadItemError: null,
                })
            );
        case requestTypeActions.LOAD_ITEM_SUCCESS:
            return state.merge(
                fromJS({
                    loadingItem: false,
                    requestType: fromJS(action.result),
                })
            );
        case requestTypeActions.LOAD_ITEM_FAIL:
            return state.merge(
                fromJS({
                    loadingItem: false,
                    loadItemError: action.error?.message,
                })
            );
        case requestTypeActions.UPDATE_ITEM_SUCCESS:
            return state.set('requestType', fromJS(action.result));
        default:
            return state;
    }
}

import React from 'react';

import { ProposalCreateNav } from '../../../components';
import * as Gov from '../../../containers/GovApp';
import { legacyFetchData } from '../../loaders';

export default (moduleName, opts = {}) =>
    (getState, dispatch) => {
        const ProposalReview = Gov.ProposalReview[moduleName];
        const ProposalCreate = Gov.GovernmentProposalCreate[moduleName];
        const ProposalVendorProfile = Gov.ProposalVendorProfile[moduleName];
        const ProposalDocumentsListViewer = Gov.ProposalDocumentsListViewer[moduleName];
        const ProposalDocumentViewer = Gov.ProposalDocumentViewer[moduleName];
        const ProposalSupplementalDocumentsListViewer =
            Gov.ProposalSupplementalDocumentsListViewer[moduleName];

        const OptsElement = opts.element;

        return [
            {
                element: opts.element ? <OptsElement /> : <Gov.ProposalReviewNav />,
                loader: legacyFetchData(getState, dispatch, opts.element || Gov.ProposalReviewNav),
                path: opts.path || 'proposals/:proposalId',
                children: [
                    {
                        element: <ProposalReview />,
                        index: true,
                    },
                    ...(!opts.skipEditingRoutes
                        ? [
                              {
                                  element: <ProposalCreateNav.Government />,
                                  path: 'edit',
                                  children: [
                                      {
                                          element: <ProposalCreate />,
                                          index: true,
                                      },
                                  ],
                              },
                          ]
                        : []),
                    {
                        element: <ProposalVendorProfile />,
                        loader: legacyFetchData(getState, dispatch, ProposalVendorProfile),
                        path: 'vendor',
                    },
                    {
                        element: <ProposalDocumentsListViewer />,
                        path: 'documents',
                    },
                    {
                        element: <ProposalDocumentViewer />,
                        path: 'documents/:attachmentNumber',
                    },
                    {
                        element: <ProposalSupplementalDocumentsListViewer />,
                        path: 'supplementals',
                    },
                    {
                        element: <ProposalDocumentViewer />,
                        path: 'supplementals/:attachmentNumber',
                    },
                ],
            },
        ];
    };

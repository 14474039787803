import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Panel } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Field, change as changeFieldValue } from 'redux-form';
import { Box } from '@og-pro/ui';
import { useFlags, FLAGS } from '@og-pro/launch-darkly/client';
import { tokens } from '@opengov/capital-style';

import { defaultSectionConfigsMap, sectionTypeNames } from '@og-pro/shared-config/sections';
import { MANUAL_NUMBER_CHAR_LIMIT } from '@og-pro/shared-config/manualNumber';
import { projectTypesDict } from '@og-pro/shared-config/projects';

import {
    form,
    BUILDER_SECTION,
    DISABLE_NUMBERING,
    DISABLE_TITLE,
    INSTRUCTIONS,
    MANUAL_NUMBER,
    PROJECT_SECTION,
    SHORT_NAME,
    TITLE,
    USER_ID,
} from './constants';
import { ProjectContentForm } from '../../forms';
import {
    Button,
    Checkbox,
    HelpIcon,
    InputText,
    RichTextInput,
    SearchSelect,
    SearchSelectUserOption,
} from '../../../../../components';
import { QuestionnaireDownloadContext } from '../../../../../components/contexts';
import { getTemplateAdminUserList } from '../../../../../selectors/govApp';
import { limitTextLength } from '../../../../../Forms/normalizers';

const normalizeManualNumber = limitTextLength(MANUAL_NUMBER_CHAR_LIMIT);

const normalizeShortName = limitTextLength(32);

const normalizeTitle = limitTextLength(128);

export const TemplateSectionForm = ({
    array,
    change,
    disabled,
    projectSection,
    projectSection: { builderSection, section_type: sectionType },
    showFormValidation,
    tagOptions,
    templateSectionId,
    templateVariableOptions,
    type,
    useManualNumbering,
}) => {
    const enableHideNumbering = useFlags(FLAGS.ENABLE_HIDE_SECTION_NUMBERING);
    const [showInstructions, setShowInstructions] = useState(!!builderSection.instructions);
    const userSelectOptions = useSelector(getTemplateAdminUserList);
    const styles = require('./index.scss');
    const dispatch = useDispatch();

    const handleShowSectionTitleChange = (event, newValue) => {
        if (newValue) {
            // If "Show Section Title" is unchecked, we uncheck "Show Section Numbering" (boolean is inverted)
            dispatch(changeFieldValue(form, `${PROJECT_SECTION}.${DISABLE_NUMBERING}`, true));
        }
    };
    const handleShowSectionNumberingChange = (event, newValue) => {
        if (!newValue) {
            // If "Show Section Numbering" is checked, we check "Show Section Title" (boolean is inverted)
            dispatch(changeFieldValue(form, `${PROJECT_SECTION}.${DISABLE_TITLE}`, false));
        }
    };

    const { shortName: defaultShortName, title: defaultTitle } =
        defaultSectionConfigsMap[sectionType];

    return (
        <>
            {useManualNumbering && (
                <div className="row">
                    <div className="col-md-4">
                        <Field
                            component={InputText}
                            disabled={disabled}
                            hasFeedback={false}
                            help="Only used in templates that are set to manual numbering. Can be changed once added to a template."
                            label={
                                <>
                                    Section Number
                                    <span style={{ fontWeight: 'normal' }}>&nbsp;(Optional)</span>
                                </>
                            }
                            name={`${PROJECT_SECTION}.${MANUAL_NUMBER}`}
                            normalize={normalizeManualNumber}
                            qaTag="sharedSectionForm-manualNumber"
                            showValidation={showFormValidation}
                            type="text"
                        />
                    </div>
                </div>
            )}
            <div className="row">
                <div className="col-md-4">
                    <Field
                        component={InputText}
                        disabled={disabled}
                        hasFeedback={false}
                        help="Title to use each time this shared section is included"
                        label="Shared Section Title"
                        name={`${PROJECT_SECTION}.${TITLE}`}
                        normalize={normalizeTitle}
                        placeholder={defaultTitle}
                        qaTag="sharedSectionForm-title"
                        showValidation={showFormValidation}
                        type="text"
                    />
                </div>
                <div className="col-md-3">
                    <Field
                        component={InputText}
                        disabled={disabled}
                        hasFeedback={false}
                        help="Abbreviated shared section title"
                        label="Short Name"
                        name={`${PROJECT_SECTION}.${SHORT_NAME}`}
                        normalize={normalizeShortName}
                        placeholder={defaultShortName}
                        qaTag="sharedSectionForm-shortName"
                        showValidation={showFormValidation}
                        type="text"
                    />
                </div>
                <div className="col-md-3">
                    <Field
                        backspaceRemovesValue={false}
                        component={SearchSelect}
                        components={{
                            Option: SearchSelectUserOption,
                        }}
                        disabled={disabled}
                        help="User who owns the shared section (must be an admin)"
                        label="Shared Section Owner"
                        name={USER_ID}
                        options={userSelectOptions}
                        placeholder="Select shared section owner"
                        qaTag="sharedSectionForm-userId"
                        showValidation={showFormValidation}
                    />
                </div>
                <div className="col-md-2">
                    <Button
                        bsStyle="link"
                        className={styles.addInstructionBtn}
                        disabled={disabled}
                        onClick={() => setShowInstructions(!showInstructions)}
                        zeroPadding
                    >
                        {showInstructions ? 'Close' : 'Add'} Instructions
                    </Button>
                </div>
                {showInstructions && (
                    <div className="col-xs-12 col-md-10">
                        <Field
                            component={RichTextInput}
                            disabled={disabled}
                            help="Include custom instructions for completing this section"
                            label="Section Instructions"
                            minRows={3}
                            name={`${PROJECT_SECTION}.${BUILDER_SECTION}.${INSTRUCTIONS}`}
                            qaTag="sharedSectionForm-instructions"
                            showValidation={showFormValidation}
                            toolbarPlacement="bottom"
                        />
                    </div>
                )}
            </div>
            {type === projectTypesDict.CONTRACT &&
                projectSection.section_type === sectionTypeNames.TEXT_AREA &&
                enableHideNumbering && (
                    <Box mb={2}>
                        <Box className={styles.pseudoLabel}>
                            Display Settings for Preview and Document Export
                            <HelpIcon
                                placement="right"
                                style={{
                                    color: tokens.colors.colorGray700,
                                }}
                                tooltip={
                                    'Section Title is needed for navigational purposes in ' +
                                    'the Document Builder and Preview mode. If unchecked, ' +
                                    'these settings will hide the title and/or numbering in the document export. '
                                }
                            />
                        </Box>
                        <Box className={styles.condensendCheckboxes}>
                            <Field
                                component={Checkbox}
                                disabled={disabled}
                                invertValue
                                name={`${PROJECT_SECTION}.${DISABLE_TITLE}`}
                                onChange={handleShowSectionTitleChange}
                                qaTag="projectSectionForm-disableTitle"
                                text="Show Section Title"
                                useOpenGovStyle
                            />
                        </Box>
                        <Box>
                            <Field
                                component={Checkbox}
                                disabled={disabled}
                                invertValue
                                name={`${PROJECT_SECTION}.${DISABLE_NUMBERING}`}
                                onChange={handleShowSectionNumberingChange}
                                qaTag="projectSectionForm-disableNumbering"
                                text="Show Section Numbering"
                                useOpenGovStyle
                            />
                        </Box>
                    </Box>
                )}
            <Box className={styles.sharedSectionContentHeader} component="h4" mt={4}>
                Shared Section Content ({defaultTitle})
            </Box>
            <Panel>
                <Panel.Body>
                    <QuestionnaireDownloadContext.Provider
                        value={`/sections/templates/${templateSectionId}/s3`}
                    >
                        <ProjectContentForm
                            array={array}
                            change={change}
                            disabled={disabled}
                            form={form}
                            hideQuestionLogic
                            isSharedSectionForm
                            projectSection={projectSection}
                            showFormValidation={showFormValidation}
                            tagOptions={tagOptions}
                            templateVariableOptions={templateVariableOptions}
                            useManualNumbering={useManualNumbering}
                        />
                    </QuestionnaireDownloadContext.Provider>
                </Panel.Body>
            </Panel>
        </>
    );
};

TemplateSectionForm.propTypes = {
    array: PropTypes.object.isRequired,
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    projectSection: PropTypes.shape({
        builderSection: PropTypes.shape({
            instructions: PropTypes.string,
        }).isRequired,
        section_type: PropTypes.string.isRequired,
    }).isRequired,
    showFormValidation: PropTypes.bool,
    tagOptions: PropTypes.array.isRequired,
    templateSectionId: PropTypes.number.isRequired,
    templateVariableOptions: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    useManualNumbering: PropTypes.bool.isRequired,
};

import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { AccordionDetails, AccordionSummary, StackedAccordion } from '@og-pro/ui';
import { ArrowForwardIos as ArrowForwardIosIcon } from '@mui/icons-material';

const sections = ['FILE_MANAGEMENT', 'SCANNER_CONTROL', 'IMAGE_CONTROLS'];
export const [FILE_MANAGEMENT_SECTION, SCANNER_CONTROL_SECTION, IMAGE_CONTROLS_SECTION] = sections;

const PanelContext = React.createContext();
const usePanel = () => useContext(PanelContext);

export const ScanningControlsPanel = ({ children, defaultOpened }) => {
    const [expandedSection, setExpandedSection] = useState(
        defaultOpened || FILE_MANAGEMENT_SECTION
    );
    const styles = require('../index.scss');

    const handleSectionExpanded = (panel) => (event, isExpanded) => {
        setExpandedSection(isExpanded ? panel : false);
    };
    return (
        <div className={styles.rightPanel}>
            <PanelContext.Provider value={{ handleSectionExpanded, expandedSection }}>
                {children}
            </PanelContext.Provider>
        </div>
    );
};

ScanningControlsPanel.propTypes = {
    children: PropTypes.node.isRequired,
    defaultOpened: PropTypes.oneOf(sections),
};

export const Section = ({ children, id, title }) => {
    const { expandedSection, handleSectionExpanded } = usePanel();
    return (
        <StackedAccordion
            expanded={expandedSection === id}
            noBorder
            onChange={handleSectionExpanded(id)}
            variant="arrowAlignmentRight"
        >
            <AccordionSummary expandIcon={<ArrowForwardIosIcon sx={{ fontSize: 'medium' }} />}>
                <h4>{title}</h4>
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
        </StackedAccordion>
    );
};

Section.propTypes = {
    id: PropTypes.oneOf(sections),
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

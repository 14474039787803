import { fieldNames } from './constants';
import { NO_LINE_ITEM } from './selectors';

const {
    AMOUNT,
    CONTRACT_PARTY_ID,
    DEPARTMENT_ID,
    INVOICE_IDENTIFIER,
    NUMBER,
    PAID_AMOUNT,
    PRICE_ITEM_ID,
    QUANTITY,
    TAG_ID,
} = fieldNames;

export const validate = (values) => {
    const errors = {};

    if (!values[AMOUNT] && values[AMOUNT] !== 0) {
        errors[AMOUNT] = 'Please enter an amount';
    } else if (values[AMOUNT] && values[AMOUNT] >= 10000000000) {
        errors[AMOUNT] = 'Amount is too large ($9,999,999,999 max)';
    }

    if (!values[QUANTITY] && values[PRICE_ITEM_ID] !== NO_LINE_ITEM) {
        errors[QUANTITY] = 'Please enter a quantity';
    }

    if (!values[TAG_ID]) {
        errors[TAG_ID] = 'Please select a year';
    }

    if (!values[DEPARTMENT_ID]) {
        errors[DEPARTMENT_ID] = 'Please select a department';
    }

    if (!values[CONTRACT_PARTY_ID]) {
        errors[CONTRACT_PARTY_ID] = 'Please select a vendor';
    }

    if (values[NUMBER] && values[NUMBER].length > 64) {
        errors[NUMBER] = 'PO Number is too long';
    }

    if (values[INVOICE_IDENTIFIER] && values[INVOICE_IDENTIFIER].length > 64) {
        errors[INVOICE_IDENTIFIER] = 'Invoice ID is too long';
    }

    if (Number.parseInt(values[PAID_AMOUNT], 10) > Number.parseInt(values[AMOUNT], 10)) {
        errors[PAID_AMOUNT] = 'Paid amount cannot be greater than order amount';
    }

    return errors;
};

export const vendorSubmissionInformationMessage =
    'Use the button below to submit your response.\n' +
    'After submission, you may still revise your response until the submission deadline.';

export const governmentSubmissionInformationMessage =
    'Use the button below to submit the response.\n' +
    'After submission, you may still revise the response.';

export const invalidFormErrorMessage =
    'Response cannot be submitted until all required sections and fields are completed.';

export const invalidFormHelpMessage =
    'Please review the checklist above and finish the incomplete sections.\n' +
    'Once you have completed all sections, please try submitting again.';

export const vendorValidFormMessage =
    'Your response is complete! Submit your response whenever you are ready.';

export const governmentValidFormMessage =
    'Response is complete! \nSubmit the response to make it accessible to your team members.';

export const projectUnsubmittableMessage =
    'Project is not currently accepting responses.\n' +
    'Only projects in the OPEN state accept responses.';

export const proposalDeadlinePassedMessage =
    'The Response Submission Deadline has passed.\nResponses can no longer be submitted.';

export const optionalQuestionnaireBlankMessage = '\n - Optional questionnaire item(s) skipped.';

export const zeroAmountsInPriceTableMessage =
    '\n - There are one or more $0.00 amounts in the pricing table(s).\n' +
    'If you intended on no-bidding a line item, please go back and check the No Bid checkbox.';

import { createSelector } from 'reselect';

import { getUserJS } from '../../selectors';

export const getUserGovernmentSubscriptions = createSelector([getUserJS], (user) => {
    if (user) {
        return user.governmentSubscriptions || [];
    }
    return [];
});

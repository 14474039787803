import qs from 'qs';
import React from 'react';
import moment from 'moment-timezone/builds/moment-timezone-with-data-1970-2030';
import clip from 'text-clipper';
import { maxBy, snakeCase } from 'lodash';

import { dataTypesDict } from './constants';

const { NODE } = dataTypesDict;

/**
 * Given a UTC date string and a target timezone, return a Date representing the the date string as
 * it would be in the targeted timezone.
 *
 * NOTE: Use this when you want to ensure that we preserve the actual time of a date/time in the
 * user's timezone time. For example, this would ensure that a user from a government based in EST
 * time would still be saving their times in EST even while traveling outside of EST time.
 *
 * @param {string} utcDateString The date string representing the time
 * @param {string} timezone The timezone to change the utcDateString to
 * @return {Date} A Date representing the utcDateString in the specified timezone
 */
export const convertToDate = (utcDateString, timezone) => {
    if (!utcDateString) {
        return null;
    }

    const utcDate = moment.tz(moment.utc(utcDateString), timezone);

    const year = utcDate.year();
    const month = utcDate.month();
    const day = utcDate.date();
    const hour = utcDate.hour();
    const minute = utcDate.minute();
    const second = utcDate.second();
    const millisecond = utcDate.millisecond();

    return new Date(year, month, day, hour, minute, second, millisecond);
};

/**
 * Given a Date and a target timezone, return a UTC date string that represents the two.
 *
 * NOTE: Use this when you need to preserve dates/times in the user's timezone. For example, this
 * would ensure that a user from a government based in EST time would still be viewing their times
 * in EST even while traveling outside of EST time.
 *
 * @param {Date} date The Date to convert
 * @param {string} timezone The timezone that the Date should be converted to prior to being
 *                          converted to UTC
 * @return {string} A UTC time string that represents the date in the specified timezone
 */
export const convertToDateString = (date, timezone) => {
    if (!date) {
        return null;
    }

    const tzDate = moment.tz(
        {
            year: date.getFullYear(),
            month: date.getMonth(),
            date: date.getDate(),
            hour: date.getHours(),
            minute: date.getMinutes(),
            second: date.getSeconds(),
            millisecond: date.getMilliseconds(),
        },
        timezone
    );

    return tzDate.utc().toISOString();
};

export const replaceNewline = function (input, customNewLine) {
    const newline = String.fromCharCode(13, 10);
    return input.replace(/\n/g, customNewLine || newline);
};

// Takes an array of strings and turns it into a map of with the value as an
// upcased and snakecased key and value equal to the original string value
export const listToDict = function (list) {
    const newDict = {};
    list.forEach((val) => {
        newDict[snakeCase(val).toUpperCase()] = val;
    });
    return newDict;
};

// Detects if content is Html
export function isContentHtml(content) {
    return !!content && content[0] === '<' && content[content.length - 1] === '>';
}

// Removes HTML tags from a string.
// Replaces closing paragraph and br tags with line breaks
export function stripHtml(content) {
    if (!isContentHtml(content)) return content;

    let escapedContent = content;

    // Unescape escaped HTML characters if in the browser
    // https://stackoverflow.com/a/7394787
    if (!process.env.SERVER) {
        // Safe with textarea, unsafe with div
        // https://stackoverflow.com/questions/7394748/whats-the-right-way-to-decode-a-string-that-has-special-html-entities-in-it
        // https://stackoverflow.com/questions/822452/strip-html-from-text-javascript
        const txt = document.createElement('textarea');
        txt.innerHTML = content;
        escapedContent = txt.value;
        // TODO: remove the node since it's no longer needed. Would require a polyfill for IE 9+
        // https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/remove
    }

    // Replace paragraphs with line breaks. Escape the rest of the HTML tags.
    // https://stackoverflow.com/a/822464
    return escapedContent
        .replace(/<\/p>(?=.)/g, '\n\n')
        .replace(/<br\/>/g, '\n')
        .replace(/<(?:.|\n)*?>/gm, '');
}

/**
 * Determines whether to truncate the HMTL string
 * @param  {string} html      The HTML to test
 * @param  {number} maxLength The max acceptable length
 * @return {boolean}
 */
export function shouldHtmlBeTruncated(html, maxLength) {
    if (!isContentHtml(html)) return html.length > maxLength;

    const fullHtml = clip(html, 1000000, { html: true });
    const blurb = clip(html, maxLength, { html: true });

    // Use the actual HTML if the blurb is the same as the actual
    return fullHtml !== blurb;
}

/**
 * Blurb of the given HTML string
 * @param  {string} html      The HTML to shorten
 * @param  {number} maxLength The max length of blurb
 * @return {string}           The shortened HTML string
 */
export function getHtmlBlurb(html, maxLength) {
    if (!html) return html;
    return clip(html, maxLength, { html: true });
}

export function getHtmlCharacterCount(content) {
    if (!content) {
        return 0;
    }

    if (!isContentHtml(content)) {
        return content.length;
    }

    return stripHtml(content).length;
}

export function prependUrl(url) {
    if (!url) return url;
    if (!/^https?:\/\//i.test(url)) {
        return `http://${url}`;
    }
    return url;
}

export function commentDateFormatter(date, timezone) {
    const format = 'MMM D YYYY [at] h:mm A';
    return timezone ? moment.tz(date, timezone).format(format) : moment(date).format(format);
}

// Converts a number string to an integer.
// If the string is not a valid number, it returns `undefined`
export function numberStringToInteger(numberString) {
    if (Number.isNaN(Number.parseFloat(numberString))) {
        return undefined;
    }
    return Number.parseInt(numberString, 10);
}

/**
 * Generate an [RFC 4180](https://tools.ietf.org/html/rfc4180) compliant CSV from a 2D array.
 * @param {Array.<any[]>} rows The set of data rows, with an optional header row
 * @param {object} [options={}] Options for the CSV generations
 * @param {string} [options.fileName='procure_now_download.csv'] The filename for the CSV
 * @param {boolean} [options.headers] When `true`, adds  `header=present` to the file's MIME type.
 *                                    Otherwise, adds `header=absent` to the file's MIME type.
 *                                    NOTE: respect for this field is up to the parsing program
 * @param {boolean} [options.timestamp] Include a timestamp prefix with the CSV filename
 */
export function exportArrayToCSV(rows, options = {}) {
    if (!rows || rows.length === 0) {
        throw new TypeError('`rows` must contain at least one row of data');
    }

    const rowLength = rows[0].length;
    rows.forEach((row, index) => {
        if (row.length !== rowLength) {
            throw new TypeError(
                `All rows in \`rows\` must contain the same number of values. The first row contained ${rowLength} values but row ${
                    index + 1
                } contained ${row.length} values`
            );
        }
    });

    const csvContent = rows
        .map((row) => {
            const formattedRow = row.map((columnValue) => {
                let stringColumnValue;
                if (columnValue === undefined) {
                    stringColumnValue = '';
                } else if (columnValue === null) {
                    stringColumnValue = 'null';
                } else {
                    stringColumnValue = columnValue.toString();
                }

                stringColumnValue = stringColumnValue.replace(/[“”]/g, '"').replace(/[‘’]/g, "'");

                // We need to escape certain column values that would break CSV parsing
                if (
                    stringColumnValue.indexOf('"') > -1 ||
                    stringColumnValue.indexOf(',') > -1 ||
                    stringColumnValue.indexOf('\n') > -1
                ) {
                    // We wrap the entire string in double quotes to escape the values that need
                    // escaping. In addition, if there are double quotes in the value we need to
                    // escape them individually with an additional double quotes
                    return `"${stringColumnValue.replace(/"/g, '""')}"`;
                }

                return stringColumnValue;
            });

            return formattedRow.join(',');
        })
        .join('\n');

    let fileName = options.fileName ? options.fileName : 'procure_now_download.csv';
    if (options.timestamp || !options.fileName) {
        fileName = `${moment().format('YYYY_MM_DD_HH_mm')}_${fileName}`;
    }
    if (fileName.substring(fileName.length - 4) !== '.csv') {
        fileName = `${fileName}.csv`;
    }

    if (navigator.msSaveBlob) {
        // IE support. https://stackoverflow.com/a/19857129/2518231
        const mimeType = `text/csv;charset=utf-8;header=${options.headers ? 'present' : 'absent'}`;
        const blob = new Blob([csvContent], { type: `${mimeType};` });
        navigator.msSaveBlob(blob, fileName);
    } else {
        // https://stackoverflow.com/a/14966131/2518231
        // `encodeURIComponent` needed for Chrome (# characters caused errors):
        // https://stackoverflow.com/a/55267469
        const encodedUri = `data:text/csv;charset=utf-8,${encodeURIComponent(csvContent)}`;

        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', fileName);
        document.body.appendChild(link); // Required for FF

        link.click();
        link.remove();
    }
}

export function getMaxNumberFromList(list, key) {
    if (list.length === 0) {
        return 0;
    }

    const maxItem = maxBy(list, (listItem) => listItem[key]) || {};
    return maxItem[key] || 0;
}

export function parseListItemsFromString(rawString) {
    if (!rawString) {
        return [];
    }

    let string = rawString;
    // Remove any strings before first title marker
    if (!rawString.match(/^<title>/i)) {
        string = rawString.replace(/^.+?(?=<title>)/i, '');
    }

    return string
        .split(/<title>/i)
        .map((listItemString) => listItemString.trim())
        .filter((listItemString) => !!listItemString)
        .map((listItemString) => listItemString.split(/<body>/i))
        .map((listItem, index) => {
            const [title, description] = listItem;
            if (!title || !description) {
                throw new Error(`Import failed at Item ${index + 1}: ${listItem}`);
            }
            return {
                description: description.trim().replace(/(?:\r\n|\r|\n)/g, '<br />'),
                title: title.trim(),
            };
        });
}

/**
 * Performs conditional checking for React prop types
 * @param {string|string[]} expectedTypes A string or string array of the valid data type(s) for the prop
 * @param {function({ object, string, string }): boolean} conditionCallback The condition required to initiate the prop types check
 * @return {function(object, string, string): Error|undefined} Validation callback to be called by prop-types
 */
export const conditionalPropCheck =
    (expectedTypes, conditionCallback) => (props, propName, componentName) => {
        const validateType = (type) =>
            type === NODE ? React.isValidElement(props[propName]) : typeof props[propName] === type; // eslint-disable-line valid-typeof

        const isValidType = Array.isArray(expectedTypes)
            ? expectedTypes.some(validateType)
            : validateType(expectedTypes);

        if (conditionCallback({ props, propName, componentName }) && !isValidType) {
            return new Error(
                `The prop '${propName}' is marked as required in '${componentName}', but its value is '${props[propName]}'.`
            );
        }
    };

export const getControlPanelLoginUrl = (userEmail) => {
    const queryString = qs.stringify({ email: userEmail });
    return `${process.env.API_HOST}/auth/opengov?${queryString}`;
};

export const scrollToTop = () => {
    if (window) {
        window.scrollTo({ top: 0, left: 0 });
    }
};

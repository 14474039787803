import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { getEmergencyProjectsJS } from './selectors';
import connectData from '../../../ConnectData';
import { loadEmergencyProjects, shouldLoadProjectsList } from '../../../../actions/emergencyPortal';
import { LoadingError, LoadingSpinner, ZeroState } from '../../../../components';
import { ProjectList } from '../../../../components/PublicApp';

function fetchData(getState, dispatch) {
    if (shouldLoadProjectsList(getState())) {
        return dispatch(loadEmergencyProjects());
    }
}

const mapStateToProps = (state) => {
    return {
        loadError: state.emergencyPortal.get('loadProjectsError'),
        loading: state.emergencyPortal.get('loadingProjects'),
        projects: getEmergencyProjectsJS(state),
    };
};

// @connectData
// @connect
class ConnectedEmergencyProjectsList extends Component {
    static propTypes = {
        loadError: PropTypes.string,
        loading: PropTypes.bool,
        projects: PropTypes.arrayOf(
            PropTypes.shape({
                government: PropTypes.shape({
                    code: PropTypes.string.isRequired,
                }).isRequired,
            })
        ).isRequired,
    };

    render() {
        const { loading, loadError, projects } = this.props;

        if (loading) {
            return <LoadingSpinner />;
        }

        if (loadError) {
            return <LoadingError error={loadError} />;
        }

        if (projects.length === 0) {
            return <ZeroState title="No projects found" />;
        }

        return (
            <ProjectList
                includeLogo
                projectPath={(project) => `/portal/${project.government.code}/projects`}
                projects={projects}
                showOrg
            />
        );
    }
}

export const EmergencyProjectsList = compose(
    connectData(fetchData),
    connect(mapStateToProps)
)(ConnectedEmergencyProjectsList);

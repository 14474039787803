import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Dialog, Typography } from '@og-pro/ui';
import { Field, initialize, reduxForm, reset } from 'redux-form';
import { useDispatch } from 'react-redux';
import { tokens } from '@opengov/capital-style';

import { fieldNames, form } from './constants';
import { validate } from './validate';
import { createOrUpdateVendorList } from '../../../../actions/vendorList';
import { InputText, Toggle } from '../../../../components';

const { IS_PUBLIC, NAME } = fieldNames;

const ConnectedVendorListDialog = ({
    clearCategoryIds,
    displayPublicVendorInformation,
    governmentId,
    handleSubmit,
    hideDialog,
    open,
    vendorList,
}) => {
    const dispatch = useDispatch();

    const wasOpen = useRef(open);

    const { id: vendorListId, name, isPublic } = vendorList || {};

    useEffect(() => {
        if (!wasOpen.current && open && vendorList) {
            dispatch(
                initialize(form, {
                    [NAME]: name,
                    [IS_PUBLIC]: isPublic,
                })
            );
        }
        wasOpen.current = open;
    }, [dispatch, open, vendorList, name, isPublic]);

    const submitHandler = (data) => {
        const list = {
            name: data[NAME],
            isPublic: data[IS_PUBLIC],
        };

        if (vendorList) {
            list.id = vendorListId;
        }

        dispatch(createOrUpdateVendorList(governmentId, list)).then(() => {
            if (clearCategoryIds) {
                // Editing the list refreshes the list, clearing the category search.
                // This ensures the category search button doesn't show that there are still selected categories.
                clearCategoryIds();
            }
            hideDialog();
            dispatch(reset(form));
        });
    };

    const titleText = vendorList ? `Edit Vendor List` : 'Create Vendor List';

    const helpText =
        'Public lists and their vendors are visible within your public vendor portal. ' +
        'If you only want a list and its vendors to be visible to your agency users, classify the list as "private."';

    return (
        <Dialog dialogTitle={titleText} fullWidth maxWidth="md" onClose={hideDialog} open={open}>
            <form onSubmit={handleSubmit(submitHandler)}>
                <Field
                    component={InputText}
                    label={<Typography sx={{ fontWeight: 500 }}>Name the List</Typography>}
                    name={NAME}
                    qaTag="vendorListDialog-name"
                    type="text"
                    useOpenGovStyle
                />
                {displayPublicVendorInformation ? (
                    <Field
                        component={Toggle}
                        help={
                            <Typography
                                sx={{ fontSize: '14px', color: tokens.colors.colorGray800 }}
                            >
                                {helpText}
                            </Typography>
                        }
                        label={<Typography sx={{ fontWeight: 500 }}>Select List Type</Typography>}
                        leftLabel={
                            <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                                Private
                            </Typography>
                        }
                        name={IS_PUBLIC}
                        qaTag="vendorListDialog-isPublic"
                        rightLabel={
                            <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                                Public
                            </Typography>
                        }
                        size="small"
                        useOpenGovStyle
                    />
                ) : null}
                <Box columnGap={2} display="flex" justifyContent="flex-end">
                    <Button color="secondary" onClick={hideDialog}>
                        Cancel
                    </Button>
                    <Button color="primary" type="submit" variant="contained">
                        {vendorList ? 'Edit & Save' : 'Create Vendor List'}
                    </Button>
                </Box>
            </form>
        </Dialog>
    );
};

ConnectedVendorListDialog.propTypes = {
    clearCategoryIds: PropTypes.func,
    displayPublicVendorInformation: PropTypes.bool,
    governmentId: PropTypes.number.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    hideDialog: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    vendorList: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        isPublic: PropTypes.bool,
    }),
};

export const VendorListDialog = reduxForm({
    form,
    validate,
})(ConnectedVendorListDialog);

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';

import { form, PASSWORD } from './constants';
import { loginValidate as validate } from './validate';
import { login as authLogin } from '../../../../actions/auth';
import { Button, InputText } from '../../../../components';

const mapStateToProps = (state) => {
    return {
        loggingIn: state.auth.get('loggingIn'),
    };
};

const mapDispatchToProps = {
    login: authLogin,
};

const formConfig = {
    form,
    validate,
};

// @connect
// @reduxForm
const ConnectedLoginPasswordForm = ({
    backHandler,
    email,
    handleSubmit,
    invalid,
    isSessionTimeout,
    loggingIn,
    login,
    onLogin,
}) => {
    const handleLogin = (data) => {
        login(email, data[PASSWORD], {
            // Optional prop can be passed to handle what happens after login
            onComplete: onLogin,
            isSessionTimeout,
        });
    };

    const styles = require('./index.scss');
    const buttonText = loggingIn ? (
        <span>
            <i className="fa fa-spinner fa-spin" /> Logging In...
        </span>
    ) : (
        'Log In'
    );

    return (
        <form onSubmit={handleSubmit(handleLogin)}>
            <label>Email Address</label>
            <p>
                {email}&nbsp;
                {!isSessionTimeout && (
                    <Button
                        bsSize="small"
                        bsStyle="link"
                        onClick={backHandler}
                        qaTag="login-edit"
                        zeroPadding
                    >
                        <i className="fa fa-pencil" /> edit
                    </Button>
                )}
            </p>
            <Field
                component={InputText}
                disabled
                formGroupClassName={styles.hiddenField}
                label="Email Address"
                name="email"
                overrideFeedback
                qaTag="login-disabledEmail"
                type="text" // NOTE: Using "hidden" type does not allow password input to be autofilled
            />
            <Field
                autoFocus
                component={InputText}
                disabled={loggingIn}
                label="Password"
                name={PASSWORD}
                placeholder="Enter your password"
                qaTag="login-password"
                type="password"
            />
            <Button
                block
                bsStyle="opengov"
                disabled={loggingIn || invalid}
                qaTag="login-submit"
                type="submit"
            >
                {buttonText}
            </Button>
        </form>
    );
};

ConnectedLoginPasswordForm.propTypes = {
    backHandler: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool.isRequired,
    isSessionTimeout: PropTypes.bool,
    loggingIn: PropTypes.bool,
    login: PropTypes.func.isRequired,
    onLogin: PropTypes.func,
};

export const LoginPasswordForm = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedLoginPasswordForm);

exports.MANUAL_NUMBER_CHAR_LIMIT = 15;
exports.MANUAL_NUMBER_ALLOWED_CHARS_REGEX = /^[a-zA-Z0-9 ,.-]+$/;

exports.isValidManualNumber = (value) => {
    if (value && !value.match(exports.MANUAL_NUMBER_ALLOWED_CHARS_REGEX)) {
        throw new Error(
            `Manual number: ${value} is invalid. Number can only include spaces, commas, periods, dashes, & alphanumeric characters.`
        );
    }
};

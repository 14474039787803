import { createSelector } from 'reselect';

import { getTemporarySingleRequestType } from '../../../../selectors/govApp';

const getRequisitionGroupNavItems = createSelector(
    [getTemporarySingleRequestType],
    (requestType) => [
        {
            path: '',
            title: 'Request Groups',
        },
        {
            path: `/${requestType.id}/sequences`,
            title: 'Review Sequences',
        },
    ]
);

const getSelectedRequisitionGroupNavItem = createSelector(
    [getRequisitionGroupNavItems, (state, props) => props.location.pathname],
    (requisitionGroupNavItems, pathname) => {
        return (
            requisitionGroupNavItems.find((navItem) => {
                return !!navItem.path && pathname.match(navItem.path);
            }) || requisitionGroupNavItems[0]
        );
    }
);

export const getNavItems = createSelector(
    [getRequisitionGroupNavItems, getSelectedRequisitionGroupNavItem],
    (requisitionGroupNavItems, selectedNavItem) => {
        return requisitionGroupNavItems.map((navItem) => {
            return {
                ...navItem,
                isActive: navItem.title === selectedNavItem.title,
            };
        });
    }
);

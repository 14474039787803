import React from 'react';
import PropTypes from 'prop-types';

import { TimeCard } from '../../../../../components/TimeCard';

export const NumberOfBidsDigital = ({ numberOfBids }) => {
    const styles = require('../index.scss');

    return (
        <div className={styles.numberOfBidsTotal}>
            <div className={styles.numberOfBidsLabel}># of Bids</div>
            <TimeCard label="TOTAL" value={numberOfBids} />
        </div>
    );
};

NumberOfBidsDigital.propTypes = {
    numberOfBids: PropTypes.number.isRequired,
};

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Panel } from 'react-bootstrap';

export class NoChartDataPanel extends PureComponent {
    static propTypes = {
        icon: PropTypes.string,
        moduleNotEnabled: PropTypes.bool,
        noDataSuccess: PropTypes.bool,
        subtitle: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    get moduleNotEnabledSubtitle() {
        return (
            <div className={this.styles.notEnabled}>
                <p>Contact us to enable the Evaluation module</p>
                <a href="mailto:sales@opengov.com">sales@opengov.com</a>
            </div>
        );
    }

    render() {
        const { icon, moduleNotEnabled, noDataSuccess, subtitle, title } = this.props;

        const defaultIcon = moduleNotEnabled ? 'lock' : 'check-circle';

        return (
            <Panel className={this.styles.noDataPanel}>
                <Panel.Body>
                    <h4>{title}</h4>
                    <i
                        className={classnames(
                            `fa fa-${icon || defaultIcon}`,
                            this.styles.noDataIcon,
                            !moduleNotEnabled && noDataSuccess && this.styles.noDataSuccess
                        )}
                    />
                    <h5>{moduleNotEnabled ? this.moduleNotEnabledSubtitle : subtitle}</h5>
                </Panel.Body>
            </Panel>
        );
    }
}

import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';

import { fieldNames } from './constants';
import { qaTagPageName } from '../constants';
import { getExceptionSequenceOptions } from '../selectors';
import { SearchSelect, SearchSelectIconOption } from '../../../../../components';

const { EXCEPTION_SEQUENCES } = fieldNames;

export const ReviewSequenceExceptionSequencesSection = ({ disabled, showFormValidation }) => {
    const exceptionSequenceOptions = useSelector(getExceptionSequenceOptions);

    return (
        <Field
            closeMenuOnSelect={false}
            component={SearchSelect}
            components={{
                Option: SearchSelectIconOption,
            }}
            disabled={disabled}
            help="Select all exceptions you want to allow"
            isClearable
            isMulti
            isMultiSimpleValue
            label="Exception Workflows"
            name={EXCEPTION_SEQUENCES}
            options={exceptionSequenceOptions}
            placeholder="Select exceptions"
            qaTag={`${qaTagPageName}-`}
            showValidation={showFormValidation}
        />
    );
};

ReviewSequenceExceptionSequencesSection.propTypes = {
    disabled: PropTypes.bool,
    showFormValidation: PropTypes.bool,
};

import React, { useContext } from 'react';

import { ReduxFormCustomForm } from '@og-pro/ui';

import { CustomFormSnapshotContext } from '../../CustomFormSnapshotContext';
import { fieldNames } from '../../constants';

const { CUSTOM_FORM_DATA } = fieldNames;

export const ReviewCustomFieldsSection = () => {
    const { customFields } = useContext(CustomFormSnapshotContext);
    return (
        <ReduxFormCustomForm disabled reduxFormPrefix={CUSTOM_FORM_DATA} snapshot={customFields} />
    );
};

ReviewCustomFieldsSection.propTypes = {};

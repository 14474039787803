import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { flexRender } from '@tanstack/react-table';

import { migrationTools } from './migrationTools';
import { propTypes } from '../propTypes';

const columnResizeMode = 'onChange';

export const Template = (props) => {
    const { getTrProps, table, className, noDataText, showPagination } = props;
    const styles = require('./legacyTheme.scss');
    const pageNavRef = useRef();

    // Since the page selector is uncontrolled, we need to update it when the page index changes
    useEffect(() => {
        if (pageNavRef.current) {
            pageNavRef.current.value = table.getState().pagination.pageIndex + 1;
        }
    }, [table.getState().pagination.pageIndex]);

    const hasNoResults = table.getPrePaginationRowModel().rows.length === 0;

    const pageSize = table.getState().pagination.pageSize;
    const rowsOnCurrentPage = table.getRowModel().rows.length;
    const padRowCount = pageSize - rowsOnCurrentPage;

    // this assumes standard table columns (non-grouped)
    const colCount = table.getHeaderGroups()[0].headers.length;

    return (
        <div className={classnames(styles.legacyReactTableContainer, className)}>
            <table
                {...{
                    style: {
                        width: table.getCenterTotalSize(),
                    },
                }}
            >
                <thead className="rt-thead">
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                const customHeaderProps = {
                                    className: classnames(
                                        header.column.columnDef.headerClassName,
                                        'rt-th',
                                        {
                                            '-sort-asc': header.column.getIsSorted() === 'asc',
                                            '-sort-desc': header.column.getIsSorted() === 'desc',
                                            'rt-resizable-header -cursor-pointer': header.resizable,
                                        }
                                    ),
                                };

                                return (
                                    <th
                                        {...{
                                            key: header.id,
                                            style: {
                                                width: header.getSize(),
                                                minWidth: `${header.column.columnDef.minSize}px`,
                                            },
                                            ...customHeaderProps,
                                        }}
                                    >
                                        {!hasNoResults ? (
                                            <>
                                                <div
                                                    {...{
                                                        className: header.column.getCanSort()
                                                            ? 'cursor-pointer select-none'
                                                            : '',
                                                        onClick:
                                                            header.column.getToggleSortingHandler(),
                                                    }}
                                                >
                                                    {header.isPlaceholder
                                                        ? null
                                                        : flexRender(
                                                              header.column.columnDef.header,
                                                              header.getContext()
                                                          )}
                                                </div>

                                                <div
                                                    {...{
                                                        onMouseDown: header.getResizeHandler(),
                                                        onTouchStart: header.getResizeHandler(),
                                                        className: `resizer ${
                                                            header.column.getIsResizing()
                                                                ? 'isResizing'
                                                                : ''
                                                        }`,
                                                        style: {
                                                            transform:
                                                                columnResizeMode === 'onEnd' &&
                                                                header.column.getIsResizing()
                                                                    ? `translateX(${
                                                                          table.getState()
                                                                              .columnSizingInfo
                                                                              .deltaOffset
                                                                      }px)`
                                                                    : '',
                                                        },
                                                    }}
                                                />
                                            </>
                                        ) : (
                                            // no results, basic headers
                                            flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )
                                        )}
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map((row, i) => {
                        const rowProps = getTrProps ? getTrProps(null, row) : {};

                        // handle legacy "striped" rows
                        rowProps.className = classnames(rowProps.className, {
                            '-odd': (i + 1) % 2 !== 0,
                            '-even': (i + 1) % 2 === 0,
                        });

                        return (
                            <tr key={row.id} {...rowProps}>
                                {row.getVisibleCells().map((cell) => (
                                    <td
                                        key={cell.id}
                                        {...{
                                            className: cell.column.columnDef.className,
                                            style: cell.column.columnDef.style,
                                        }}
                                    >
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            migrationTools.cellAdapter(cell)
                                        )}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}

                    {/*
                     * For style parity with react-table 6,
                     * we'll pad the table with empty rows to match the page size.
                     *
                     * This will also take care of the pad rows for no results.
                     */}
                    {padRowCount > 0 &&
                        Array.from({ length: padRowCount }, (_, i) => i).map((row, i) => {
                            return (
                                <tr
                                    className={classnames('-padRow', {
                                        '-odd': (i + rowsOnCurrentPage + 1) % 2 !== 0,
                                        '-even': (i + rowsOnCurrentPage + 1) % 2 === 0,
                                    })}
                                    key={`padRow_${row}`}
                                >
                                    <td colSpan={colCount} />
                                </tr>
                            );
                        })}
                </tbody>
            </table>
            {showPagination && (
                <div className="pagination-bottom">
                    <div className="-pagination">
                        <div className="-previous">
                            <button
                                className="-btn"
                                disabled={!table.getCanPreviousPage()}
                                onClick={() => table.previousPage()}
                                type="button"
                            >
                                Previous
                            </button>
                        </div>
                        <div className="-center">
                            <span className="-pageInfo">
                                Page{' '}
                                <div className="-pageJump">
                                    {/* 
                                        I think we want this input to be uncontrolled by using `defaultValue`
                                        instead of `value` so users can freely type a page number.
                                    */}
                                    <input
                                        aria-label="jump to page"
                                        defaultValue={table.getState().pagination.pageIndex + 1}
                                        max={table.getPageCount()}
                                        min={1}
                                        onBlur={(e) => {
                                            const value = e.target.value;

                                            // reset the page index if the user clears the input
                                            if (value === '') {
                                                table.setPageIndex(0);
                                                e.target.value = 1;
                                            }
                                        }}
                                        onChange={(e) => {
                                            const value = e.target.value;

                                            if (value === '') return;

                                            const page = value ? Number(value) - 1 : 0;
                                            table.setPageIndex(page);
                                        }}
                                        ref={pageNavRef}
                                        type="number"
                                    />
                                </div>{' '}
                                of <span className="-totalPages">{table.getPageCount()}</span>
                            </span>
                            <span className="select-wrap -pageSizeOptions">
                                <select
                                    aria-label="rows per page"
                                    onChange={(e) => {
                                        table.setPageSize(Number(e.target.value));
                                    }}
                                    value={table.getState().pagination.pageSize}
                                >
                                    <option value="5">5 rows</option>
                                    <option value="10">10 rows</option>
                                    <option value="20">20 rows</option>
                                    <option value="25">25 rows</option>
                                    <option value="50">50 rows</option>
                                    <option value="100">100 rows</option>
                                </select>
                            </span>
                        </div>
                        <div className="-next">
                            <button
                                className="-btn"
                                disabled={!table.getCanNextPage()}
                                onClick={() => table.nextPage()}
                                type="button"
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {hasNoResults && <div className="rt-noData">{noDataText || 'No data found'}</div>}
        </div>
    );
};

Template.propTypes = {
    getTrProps: PropTypes.func,
    table: PropTypes.object,
    className: PropTypes.string,
    noDataText: PropTypes.node,
    showPagination: propTypes.showPagination,
};

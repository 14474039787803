import classnames from 'classnames';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Field } from 'redux-form';

import { sectionTypeNames } from '@og-pro/shared-config/sections';

import { questionLogicLinkableModelNames } from '@og-pro/shared-config/questionLogics';

import { ProjectSectionDisplay } from './ProjectSectionDisplay';
import { ProjectSectionForm } from './ProjectSectionForm';
import { HAS_ERRORS } from '../../constants';
import { QuestionLogicIcon } from '../../../../components';

const { ATTACHMENTS, DIVIDER, EVALUATION_CRITERIA, EVALUATION_PHASE, INTRODUCTION, PRICING } =
    sectionTypeNames;

const { PROJECT_SECTION } = questionLogicLinkableModelNames;

export class ProjectSectionItem extends PureComponent {
    static propTypes = {
        changeParentDivider: PropTypes.func,
        defaultSection: PropTypes.shape({
            isRemovable: PropTypes.bool.isRequired,
        }).isRequired,
        disabled: PropTypes.bool,
        dividerDropdownOptions: PropTypes.array,
        draggableProvided: PropTypes.object.isRequired,
        index: PropTypes.number.isRequired,
        isIntake: PropTypes.bool.isRequired,
        isSpecial: PropTypes.bool.isRequired,
        member: PropTypes.string.isRequired,
        onDelete: PropTypes.func.isRequired,
        projectSection: PropTypes.shape({
            id: PropTypes.number.isRequired,
            isWritingForm: PropTypes.bool.isRequired,
            section_type: PropTypes.string.isRequired,
        }).isRequired,
        showForm: PropTypes.bool,
        showFormHandler: PropTypes.func.isRequired,
        showFormValidation: PropTypes.bool,
        sortUsingManualNumbering: PropTypes.func,
        useManualNumbering: PropTypes.bool.isRequired,
        useSectionDividers: PropTypes.bool.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    get isDivider() {
        const {
            projectSection: { section_type: sectionType },
        } = this.props;

        return sectionType === DIVIDER;
    }

    get canHaveInstructions() {
        const {
            isSpecial,
            isIntake,
            projectSection: { isWritingForm },
        } = this.props;

        // Intakes are the only special template that can have instructions
        return (!isSpecial || isIntake) && !this.isDivider && isWritingForm;
    }

    get shouldNotDelete() {
        const {
            defaultSection: { isRemovable },
            index,
            isSpecial,
            isIntake,
            projectSection: { section_type: sectionType },
        } = this.props;

        if (isIntake) {
            // Intakes are the only special template that can have attachments removed
            // Intakes may optionally include other sections
            const intakeSections = [ATTACHMENTS, PRICING, EVALUATION_CRITERIA, EVALUATION_PHASE];
            return !intakeSections.includes(sectionType);
        }

        // When section dividers are used, there must be at least one and it cannot be removed.
        const isFirstSectionDivider = this.isDivider && index === 0;

        return isSpecial || !isRemovable || isFirstSectionDivider;
    }

    toggleForm = () => {
        const { projectSection, showFormHandler } = this.props;

        showFormHandler(projectSection.id);
    };

    renderQuestionLogicIcon() {
        const { projectSection } = this.props;

        if (projectSection.section_type === INTRODUCTION || this.isDivider) {
            return null;
        }

        return (
            <QuestionLogicIcon
                linkable={PROJECT_SECTION}
                linkableItem={projectSection}
                projectSection={projectSection}
            />
        );
    }

    render() {
        const {
            changeParentDivider,
            defaultSection,
            disabled,
            dividerDropdownOptions,
            draggableProvided,
            index,
            member,
            onDelete,
            projectSection,
            showForm,
            showFormValidation,
            sortUsingManualNumbering,
            useManualNumbering,
            useSectionDividers,
        } = this.props;

        const questionLogicIcon = this.renderQuestionLogicIcon();

        return (
            <div
                className={classnames('list-group-item', !showForm && this.styles.listItem)}
                disabled={disabled}
                onClick={showForm ? undefined : this.toggleForm}
                ref={draggableProvided.innerRef}
                {...draggableProvided.draggableProps}
                {...draggableProvided.dragHandleProps}
            >
                {showForm ? (
                    <ProjectSectionForm
                        canHaveInstructions={this.canHaveInstructions}
                        changeParentDivider={changeParentDivider}
                        defaultSection={defaultSection}
                        disabled={disabled}
                        dividerDropdownOptions={dividerDropdownOptions}
                        hasInstructions={!!get(projectSection, 'builderSection.instructions')}
                        index={index}
                        isDivider={this.isDivider}
                        member={member}
                        onComplete={this.toggleForm}
                        onDelete={onDelete}
                        questionLogicIcon={questionLogicIcon}
                        shouldNotDelete={this.shouldNotDelete}
                        showFormValidation={showFormValidation}
                        sortUsingManualNumbering={sortUsingManualNumbering}
                        useManualNumbering={useManualNumbering}
                        useSectionDividers={useSectionDividers}
                    />
                ) : (
                    <Field
                        component={ProjectSectionDisplay}
                        defaultSection={defaultSection}
                        isDivider={this.isDivider}
                        name={`${member}.${HAS_ERRORS}`}
                        projectSection={projectSection}
                        questionLogicIcon={questionLogicIcon}
                        showFormValidation={showFormValidation}
                        useManualNumbering={useManualNumbering}
                        useSectionDividers={useSectionDividers}
                    />
                )}
            </div>
        );
    }
}

import React from 'react';
import { Outlet, redirect } from 'react-router-dom';

import { Admin } from '../../containers';
import {
    ApprovalsPreSetWorkflow,
    AutoNumbers,
    CustomCategories,
    DepartmentList,
    StandardDocuments,
    SuggestedContent,
    Tags,
    UserList,
    UserRolesAdmin,
    WorkloadWeights,
    Settings,
} from '../../containers/Admin';
import { legacyFetchData, requireSystemAdmin } from '../loaders';

export default (getState, dispatch) => {
    return [
        {
            element: <Admin />,
            loader: requireSystemAdmin(getState),
            path: 'admin',
            children: [
                {
                    element: <Outlet />,
                    loader: () => redirect('users'),
                    index: true,
                },
                {
                    element: <UserList />,
                    path: 'users',
                },
                {
                    element: <UserRolesAdmin />,
                    path: 'roles',
                },
                {
                    element: <AutoNumbers />,
                    loader: legacyFetchData(getState, dispatch, AutoNumbers),
                    path: 'auto-numbers',
                },
                {
                    element: <DepartmentList />,
                    path: 'departments',
                },
                {
                    element: <StandardDocuments />,
                    loader: legacyFetchData(getState, dispatch, StandardDocuments),
                    path: 'documents',
                },
                {
                    element: <SuggestedContent />,
                    loader: legacyFetchData(getState, dispatch, SuggestedContent),
                    path: 'content',
                },
                {
                    element: <Tags />,
                    path: 'tags',
                },
                {
                    element: <CustomCategories />,
                    path: 'custom-categories',
                },
                {
                    element: <ApprovalsPreSetWorkflow />,
                    loader: legacyFetchData(getState, dispatch, ApprovalsPreSetWorkflow),
                    path: 'approvals',
                },
                {
                    element: <WorkloadWeights />,
                    loader: legacyFetchData(getState, dispatch, WorkloadWeights),
                    path: 'workload',
                },
                {
                    element: <Settings />,
                    path: 'settings',
                },
            ],
        },
    ];
};

import { get } from 'lodash';
import { createSelector } from 'reselect';

import { sections, sectionsFilter } from '../../ProposalCreateNav/constants';

const getHasQuestionnaire = (props) =>
    (get(props, 'proposalFormData.questionnaires') || []).length > 0;
const getHasPriceTable = (props) => (get(props, 'proposalFormData.priceTables') || []).length > 0;
const getHasAddendumSection = (props) => get(props, 'proposalFormData.hasAddendumSection');
const getSkipCompanyPropfile = (props) => props.skipCompanyProfile;

export const getSections = createSelector(
    [getHasQuestionnaire, getHasPriceTable, getHasAddendumSection, getSkipCompanyPropfile],
    (hasQuestionnaire, hasPriceTable, hasAddendumSection, skipCompanyProfile) => {
        return sections.filter((section) => {
            return sectionsFilter(section, {
                hasAddendumSection,
                hasQuestionnaire,
                hasPriceTable,
                skipCompanyProfile,
            });
        });
    }
);

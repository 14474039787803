import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';

import { form, SORT } from './constants';
import { SearchSelect } from '../../../../components';

const formConfig = {
    enableReinitialize: true,
    form,
};

class ConnectedSortByFieldForm extends Component {
    static propTypes = {
        formClassName: PropTypes.string,
        options: PropTypes.array.isRequired,
        onProjectSortByField: PropTypes.func,
    };

    filterSelectStyles = {
        control: {
            height: 30,
            minHeight: 30,
            fontSize: '13px',
        },
    };

    render() {
        const { formClassName, options, onProjectSortByField } = this.props;

        return (
            <Field
                aria-label="Sort Projects By"
                blurInputOnSelect
                component={SearchSelect}
                formClassName={formClassName}
                isSearchable={false}
                name={SORT}
                onChange={onProjectSortByField}
                options={options}
                overrideFeedback
                styles={this.filterSelectStyles}
            />
        );
    }
}

export const SortByFieldForm = reduxForm(formConfig)(ConnectedSortByFieldForm);

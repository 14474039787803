import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '..';

export const LoadingButton = ({ icon, loading, loadingText, text, ...props }) => {
    const buttonIcon = loading ? 'fa-spin fa-spinner' : icon;
    const buttonText = loading ? loadingText || text : text;

    const showIcon = loading || icon;

    return (
        <Button {...props}>
            {showIcon && <i className={`fa fa-fw ${buttonIcon}`} />} {buttonText}
        </Button>
    );
};

LoadingButton.propTypes = {
    icon: PropTypes.string,
    loading: PropTypes.bool,
    loadingText: PropTypes.string,
    text: PropTypes.string.isRequired,
};

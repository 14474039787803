import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { UNITED_STATES_COUNTRY_CODE } from '@og-pro/shared-config/global';

export class ContactAddress extends PureComponent {
    static propTypes = {
        address1: PropTypes.string,
        address2: PropTypes.string,
        city: PropTypes.string,
        country: PropTypes.string,
        countryCode: PropTypes.string,
        excludeTrailingLineBreak: PropTypes.bool,
        state: PropTypes.string,
        zipCode: PropTypes.string,
    };

    static defaultProps = {
        address1: undefined,
        address2: undefined,
        city: undefined,
        country: undefined,
        countryCode: undefined,
        state: undefined,
        zipCode: undefined,
    };

    /*
     * IMPORTANT: This component is rendered by the DOCX templater. Doing anything fancy with
     * classes or components will likely not work well when converting to docx. If fancy formatting
     * is ever needed add a `isDocx` prop and create a simple render version to use with docx.
     */
    render() {
        const {
            address1,
            address2,
            city,
            country,
            countryCode,
            excludeTrailingLineBreak,
            state,
            zipCode,
        } = this.props;

        let cityStateZip = '';
        if (city) {
            cityStateZip = `${city}`;

            if (state || zipCode) {
                cityStateZip = `${cityStateZip}, `;
            }
        }
        if (state) {
            cityStateZip = `${cityStateZip}${state}`;
        }
        if (state && zipCode) {
            cityStateZip = `${cityStateZip} `;
        }
        if (zipCode) {
            cityStateZip = `${cityStateZip}${zipCode}`;
        }

        const addressFields = [
            address1,
            address2,
            cityStateZip,
            countryCode && countryCode !== UNITED_STATES_COUNTRY_CODE && country,
        ].filter((field) => !!field);

        return addressFields.map((field, index) => (
            <React.Fragment key={index}>
                {field}
                {(index !== addressFields.length - 1 || !excludeTrailingLineBreak) && <br />}
            </React.Fragment>
        ));
    }
}

/**
 * Sorts an array of items with the `manualNumber` property
 *
 * @param {array} items The items to sort by `manualNumber`
 * @returns {array} The sorted items
 */
export const sortItemsByManualNumber = (items) => {
    return items.sort((a, b) => {
        // Intentionally leaving this as a rudimentary sort. Will sort 1, 2, 3, 10 as 1, 10, 2, 3.
        // It's expected the user will use leading zeros to sort correctly when using number strings.
        if (a.manualNumber) {
            if (!b.manualNumber || a.manualNumber < b.manualNumber) {
                return -1;
            }

            if (a.manualNumber > b.manualNumber) {
                return 1;
            }
        }

        return 0;
    });
};

/**
 * Returns the proper parent divider index when modifying a section's parent divider
 * Maybe it's a bug with react-form but when you move an item and you try to move it to the last
 * position of the array, if you specify the last index + 1 there are undefined items inserted into the array.
 * Example:
 * [0, 1, 2, 3, 4, 5] where 0, 2 and 4 are dividers
 *
 * If you want to move section 5 under 0 the parent index is 0, but the actual position you want to put your
 * section is in index 1 [0, 5, 1, 2, 3, 4] being the end goal.
 *
 * If you want to move section 1 under 4, the parent index is 4, but the actual position you want to put your
 * section is in index 5; [0, 2, 3, 4, 1, 5]
 *
 * Both cases above represent newParentDividerIndex + 1.
 * ---
 *
 * [0, 1, 2, 3, 4, 5] where 0, 2 and 5 are dividers
 *
 * If you want to move section 3 under 5, the parent index is 5. But if we move the item to newParentDividerIndex + 1 = 6,
 * we are enlarging the array's length. So we just use 5 with the end result being
 * [0, 1, 2, 4, 5, 3]
 * (seems like react-redux handles smartly the case to understand we want the item at the end of the array instead of replacing the last element)
 *
 * @param {number} newParentDividerIndex Index of the divider in which we want to move the section below
 * @param {number} totalNumberOfSections Length of the template's sections
 * @returns {number}
 */
export const getIndexToChangeParentDivider = (newParentDividerIndex, totalNumberOfSections) => {
    if (newParentDividerIndex === totalNumberOfSections - 1) {
        return newParentDividerIndex;
    }

    return newParentDividerIndex + 1;
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';

import { InputText, Button } from '../../../..';
import { form, fieldNames } from './constants';

const formConfig = {
    form,
};

// @reduxForm
class ConnectedRejectForm extends PureComponent {
    static propTypes = {
        submitting: PropTypes.bool.isRequired,
        submitError: PropTypes.string,
        handleSubmit: PropTypes.func.isRequired,
        hideModal: PropTypes.func.isRequired,
    };

    renderSubmitError() {
        const { submitError } = this.props;
        if (!submitError) return null;

        return <div className="text-danger">{submitError}</div>;
    }

    render() {
        const { submitting, handleSubmit, hideModal } = this.props;
        const styles = require('../ApprovalsModal.scss');

        return (
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Add Note to Your Project Rejection (optional):</label>
                    <Field
                        component={InputText}
                        disabled={submitting}
                        name={fieldNames.COMMENT}
                        placeholder="Enter optional explanation for rejecting"
                        type="textarea"
                    />
                </div>
                {this.renderSubmitError()}
                <div className={`text-center ${styles.buttons}`}>
                    <Button
                        bsStyle="danger"
                        className={styles.actionButton}
                        disabled={submitting}
                        type="submit"
                    >
                        <i className="fa fa-ban" /> Reject Project
                    </Button>
                    <Button onClick={hideModal}>Cancel</Button>
                </div>
            </form>
        );
    }
}

export const RejectForm = reduxForm(formConfig)(ConnectedRejectForm);

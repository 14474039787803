import { createSelector } from 'reselect';

import {
    BID_DENOMINATION_ERROR,
    BID_NOT_LOWER_ERROR,
    MIN_BID_DECREMENT_ERROR,
    NEW_LOWEST_BID,
    UNKNOWN_ERROR,
} from './constants';
import { getPublicProjectJS } from '../../../../selectors';

const getVendorBids = (state) => state.reverseAuctions.get('vendorBids').toJS();
const getVendorPriceItemId = (state, vendorPriceItemId) => vendorPriceItemId;
const getProxyBids = (state) => state.reverseAuctions.get('proxyBids').toJS();
const getCurrentVendorProxyBid = (state, currentVendorProxyBid) => currentVendorProxyBid;

export const getBidError = createSelector(
    [getPublicProjectJS, getVendorBids, getVendorPriceItemId],
    (project, vendorBids, vendorPriceItemId) => {
        if (!vendorBids[vendorPriceItemId]) return null;

        const bidRejectedReason = vendorBids[vendorPriceItemId].bidRejectedReason;
        const timestamp = vendorBids[vendorPriceItemId].timestamp;
        switch (bidRejectedReason) {
            case BID_DENOMINATION_ERROR:
                return {
                    header: 'Bid Denomination Error',
                    body: `${BID_DENOMINATION_ERROR} of ${project.auctionMaxFractionDigits}`,
                    timestamp,
                };
            case BID_NOT_LOWER_ERROR:
            case MIN_BID_DECREMENT_ERROR:
                return {
                    header: NEW_LOWEST_BID,
                    body: 'Someone beat you to it. Try again based on the new lowest bid.',
                    timestamp,
                };
            case UNKNOWN_ERROR:
                return {
                    header: 'Something went wrong. Please try again.',
                    timestamp,
                };
            default:
                break;
        }
    }
);

export const getDeleteProxyBidError = createSelector(
    [getProxyBids, getCurrentVendorProxyBid],
    (proxyBids, currentVendorProxyBid) => {
        if (currentVendorProxyBid && !proxyBids[currentVendorProxyBid.id]) return null;

        if (currentVendorProxyBid && proxyBids[currentVendorProxyBid.id].proxyBidError) {
            return {
                header: 'Something went wrong clearing your proxy bid. Please try again',
            };
        }
    }
);

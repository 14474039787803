import { listToDict } from '@og-pro/shared-config/helpers';

export const CRITERIA_ITEM = 'criteriaItem';

const fields = [
    'description',
    'id',
    'instructionData',
    'instructions',
    'instructionType',
    'isReviewed',
    'isTitleLocked',
    'isUnused',
    'isUpload',
    'isUploadRequired',
    'manualNumber',
    'needsReview',
    'orderById',
    'rawDescription',
    'section_type',
    'subsection_type',
    'title',
];

export const fieldNames = listToDict(fields);

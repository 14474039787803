import {
    ALLOWED_VARIABLES,
    TARGET_EL_HEADING_SPACING_MAP_WITH_DIVIDERS,
    TARGET_EL_HEADING_SPACING_MAP_NO_DIVIDERS,
} from '../constants/docxHeaderFormatters';

export const getHeadingFormattersKey = ({ useSectionDividers, domElement }) => {
    const headingSeparatorsKey = useSectionDividers
        ? TARGET_EL_HEADING_SPACING_MAP_WITH_DIVIDERS[domElement]
        : TARGET_EL_HEADING_SPACING_MAP_NO_DIVIDERS[domElement];

    return headingSeparatorsKey;
};

export const parseHeadingFormatter = (
    rawString,
    numbering = '',
    title,
    replaceTabWithSpaces = false,
    disableTitle = false
) => {
    if (disableTitle) {
        return '';
    }

    if (!rawString) {
        return `${numbering}${numbering && '<t />'}${title}`;
    }

    const cleanNumber =
        numbering[numbering.length - 1] === '.'
            ? numbering.substring(0, numbering.length - 1)
            : numbering;

    return rawString
        .replace(
            new RegExp(ALLOWED_VARIABLES.TAB, 'gi'),
            replaceTabWithSpaces ? '&nbsp;&nbsp;&nbsp;&nbsp;' : '<t />'
        )
        .replace(new RegExp(ALLOWED_VARIABLES.NEWLINE, 'gi'), '<br />')
        .replace(new RegExp(ALLOWED_VARIABLES.SPACE, 'gi'), ' ')
        .replace(new RegExp(ALLOWED_VARIABLES.TITLE, 'gi'), title)
        .replace(new RegExp(ALLOWED_VARIABLES.NUMBER, 'gi'), cleanNumber);
};

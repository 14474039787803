import { Box } from '@og-pro/ui';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import React from 'react';

export const NoVendors = () => {
    const sharedStyles = require('../../../shared.scss');

    return (
        <div className={sharedStyles.approvalContainer}>
            <Box
                color={capitalDesignTokens.semanticColors.foreground.secondary}
                fontWeight={capitalDesignTokens.foundations.typography.fontWeight.medium}
            >
                No vendor selected at this time.
            </Box>
        </div>
    );
};

import { fromJS } from 'immutable';

import * as reverseAuctionActions from '../actions/reverseAuctions';
import * as reverseAuctionSocketActions from '../actions/reverseAuctionSocket';

const initialState = fromJS({
    loadingReverseAuctionPriceItems: false,
    loadReverseAuctionPriceItemsError: null,
    reverseAuctionItems: [],
    proxyBids: {},
    vendorBids: {},
});

export default function reverseAuctionsReducer(state = initialState, action = {}) {
    switch (action.type) {
        case reverseAuctionActions.LOAD_REVERSE_AUCTION_ITEMS:
            return state.merge(
                fromJS({
                    loadingReverseAuctionPriceItems: true,
                    loadReverseAuctionPriceItemsError: null,
                })
            );
        case reverseAuctionActions.LOAD_REVERSE_AUCTION_ITEMS_FAIL:
            return state.merge(
                fromJS({
                    loadingReverseAuctionPriceItems: false,
                    loadReverseAuctionPriceItemsError: action.error.message,
                })
            );
        case reverseAuctionActions.LOAD_REVERSE_AUCTION_ITEMS_SUCCESS:
            return state.merge(
                fromJS({
                    loadingReverseAuctionPriceItems: false,
                    reverseAuctionItems: fromJS(action.result),
                })
            );
        case reverseAuctionActions.DELETE_PROXY_BID_FAIL:
            return state.setIn(
                ['proxyBids', action.proxyBidId],
                fromJS({
                    proxyBidError: true,
                    proxyBidErrorMessage: action.error.message,
                })
            );
        case reverseAuctionActions.DELETE_PROXY_BID_SUCCESS: {
            const newState = state.getIn(['proxyBids', action.result.proxyBid.id])
                ? state.setIn(
                      ['proxyBids', action.result.proxyBid.id],
                      fromJS({
                          proxyBidError: false,
                          proxyBidErrorMessage: null,
                      })
                  )
                : state;

            return newState.merge(
                fromJS({
                    reverseAuctionItems: state.get('reverseAuctionItems').map((item) => {
                        const priceTable = item.toJS();
                        const priceItem = priceTable.priceItems[0];

                        if (priceItem.id === action.result.proxyBid.price_item_id) {
                            const proxyBids = priceItem.proxyBids.filter((proxyBid) => {
                                return proxyBid.id !== action.result.proxyBid.id;
                            });

                            return fromJS({
                                ...priceTable,
                                priceItems: [
                                    {
                                        ...priceItem,
                                        proxyBids,
                                    },
                                ],
                            });
                        }

                        return item;
                    }),
                })
            );
        }
        case reverseAuctionSocketActions.AUCTION_STARTED:
            return state.merge(
                fromJS({
                    auctionEnded: false,
                })
            );
        case reverseAuctionSocketActions.AUCTION_ENDED:
            return state.merge(
                fromJS({
                    auctionEnded: true,
                })
            );
        case reverseAuctionActions.PROCESSING_REVERSE_AUCTION_BID:
            return state.setIn(
                ['vendorBids', action.vendorPriceItemId],
                fromJS({
                    bidData: null,
                    bidRejected: false,
                    bidRejectedReason: null,
                    processingBid: true,
                })
            );
        case reverseAuctionActions.PROCESSING_REVERSE_AUCTION_BID_FAIL:
            return state.setIn(
                ['vendorBids', action.vendorPriceItemId],
                fromJS({
                    bidData: null,
                    bidRejected: true,
                    bidRejectedReason: action.error.message,
                    processingBid: false,
                    timestamp: new Date().getTime(),
                })
            );
        case reverseAuctionSocketActions.BID_REJECTED:
            return state.setIn(
                ['vendorBids', action.bidData.vendorPriceItemId],
                fromJS({
                    bidData: action.bidData,
                    bidRejected: true,
                    bidRejectedReason: action.bidRejectedReason,
                    processingBid: false,
                    timestamp: new Date().getTime(),
                })
            );
        case reverseAuctionSocketActions.BID_PRICE_UPDATED: {
            // This action goes to all users. If it's the bidder, set the `vendorBids` state accordingly.
            const newState = state.getIn([
                'vendorBids',
                action.reverseAuctionBid.vendor_price_item_id,
            ])
                ? state.setIn(
                      ['vendorBids', action.reverseAuctionBid.vendor_price_item_id],
                      fromJS({
                          bidData: action.reverseAuctionBid,
                          bidRejected: false,
                          bidRejectedReason: null,
                          processingBid: false,
                      })
                  )
                : state;
            return newState.set(
                'reverseAuctionItems',
                newState.get('reverseAuctionItems').map((item) => {
                    // Top-level props of item are for the price table
                    const priceTable = item.toJS();
                    const bidVendorPriceItemId = action.reverseAuctionBid.vendor_price_item_id;

                    if (priceTable.id === action.priceTableId) {
                        const priceItem = priceTable.priceItems[0];

                        const vendorPriceItems = priceItem.vendorPriceItems.map(
                            (vendorPriceItem) => {
                                if (vendorPriceItem.id === bidVendorPriceItemId) {
                                    const reverseAuctionBids = [
                                        action.reverseAuctionBid,
                                        ...vendorPriceItem.reverseAuctionBids,
                                    ];

                                    return {
                                        ...vendorPriceItem,
                                        reverseAuctionBids,
                                    };
                                }

                                return vendorPriceItem;
                            }
                        );

                        vendorPriceItems.sort((a, b) => {
                            if (
                                a.reverseAuctionBids.length > 0 &&
                                b.reverseAuctionBids.length > 0
                            ) {
                                return (
                                    a.reverseAuctionBids[0].unitPrice -
                                    b.reverseAuctionBids[0].unitPrice
                                );
                            }

                            return 0;
                        });

                        const reverseAuctionBids = [
                            action.reverseAuctionBid,
                            ...priceItem.reverseAuctionBids,
                        ];

                        return fromJS({
                            ...priceTable,
                            priceItems: [{ ...priceItem, reverseAuctionBids, vendorPriceItems }],
                        });
                    }

                    return item;
                })
            );
        }
        case reverseAuctionSocketActions.PROXY_BID_UPDATED: {
            return state.set(
                'reverseAuctionItems',
                state.get('reverseAuctionItems').map((item) => {
                    const priceTable = item.toJS();
                    const priceItem = priceTable.priceItems[0];

                    if (priceItem.id === action.bidData.price_item_id) {
                        const proxyBidIdx = priceItem.proxyBids.findIndex(
                            (proxyBid) => proxyBid.vendor_id === action.bidData.vendor_id
                        );

                        if (proxyBidIdx > -1) {
                            priceItem.proxyBids[proxyBidIdx] = action.bidData;
                        } else {
                            priceItem.proxyBids.push(action.bidData);
                        }

                        return fromJS(priceTable);
                    }

                    return item;
                })
            );
        }

        default:
            return state;
    }
}

export const getRemainingAmount = (fields, extendedPrice) => {
    const fieldsSum = fields.getAll().reduce((sum, current) => {
        if (!current.amount) {
            return sum;
        }

        return sum + Number(current.amount);
    }, 0);

    return extendedPrice - fieldsSum;
};

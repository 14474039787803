import React, { useState } from 'react';
import { createSearchParams, useLocation, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
    getCurrentLineItem,
    getLineItemBidTimelineData,
    getLineItemDetailNavItems,
    getCurrentItemBidHistory,
    getCurrentLineItemVendorBids,
} from './selectors';
import {
    LineItemBidTimelineChart,
    LineItemDetailsListItem,
    LineItemDetailsInfoHeader,
} from './components';
import { getAuctionMaxFractionDigits } from '../selectors';
import {
    Button,
    Nav,
    NavItem,
    PageTitle,
    ReverseAuctionBidHistoryTable,
} from '../../../../components';
import { getUserOrganizationTimezone } from '../../../selectors';

export const ReverseAuctionLineItemDetails = () => {
    const { pathname } = useLocation();
    const [baseQuery] = useSearchParams();
    const { priceItemQuery } = Object.fromEntries(baseQuery);

    const styles = require('./index.scss');

    const auctionMaxFractionDigits = useSelector((state) => getAuctionMaxFractionDigits(state));
    const bidDetailNavItems = useSelector((state) => getLineItemDetailNavItems(state));
    const bidHistoryData = useSelector((state) =>
        getCurrentItemBidHistory(state, { priceItemQuery })
    );
    const bidTimelineData = useSelector((state) =>
        getLineItemBidTimelineData(state, { priceItemQuery })
    );
    const timezone = useSelector((state) => getUserOrganizationTimezone(state));
    const {
        minBidDecrement,
        priceItems: {
            0: {
                description,
                quantity: currentItemQuantity,
                vendorPriceItems,
                vendorPriceItems: {
                    0: { reverseAuctionBids },
                },
            },
        },
    } = useSelector((state) => getCurrentLineItem(state, { priceItemQuery }));
    const { activeVendorPriceItems, bidRankingMap, noBidVendorPriceItems, lineItemTotalNumBids } =
        useSelector((state) => getCurrentLineItemVendorBids(state, { priceItemQuery }));

    const [showMore, setShowMore] = useState(false);

    const lowestUnitPrice = reverseAuctionBids.length > 0 ? reverseAuctionBids[0].unitPrice : 0;

    const NavItems = bidDetailNavItems.map(({ navTitle, itemId }, idx) => {
        const route = {
            pathname,
            search: createSearchParams({ priceItemQuery: itemId }).toString(),
        };

        const activeClass =
            (!priceItemQuery && idx === 0) || parseInt(priceItemQuery, 10) === itemId
                ? 'active'
                : undefined;

        return (
            <NavItem
                className={activeClass}
                key={navTitle}
                overrideIsActive={false}
                qaTag={`reverseAuction-${navTitle}`}
                to={route}
            >
                <div className={styles.navItem}>{navTitle}</div>
            </NavItem>
        );
    });

    const renderListItems = () => {
        if (activeVendorPriceItems.length === 0) {
            return <em className={styles.noBidText}>No suppliers bid on this line item.</em>;
        }

        const supplierRankingList = [...activeVendorPriceItems, ...noBidVendorPriceItems];
        const itemsToRender = showMore ? supplierRankingList : supplierRankingList.slice(0, 3);

        return itemsToRender.map((item) => {
            return (
                <LineItemDetailsListItem
                    auctionMaxFractionDigits={auctionMaxFractionDigits}
                    item={item}
                    key={item.id}
                    quantity={currentItemQuantity}
                    ranking={item.noBid ? null : bidRankingMap[item.id]}
                    timezone={timezone}
                />
            );
        });
    };

    const renderShowMoreButton = () => {
        const buttonText = showMore ? (
            <strong>
                Show less <i className="fa fa-angle-up" />
            </strong>
        ) : (
            <strong>
                Show more <i className="fa fa-angle-down" />
            </strong>
        );

        return (
            <div className={styles.showMoreButtonContainer}>
                <Button bsStyle="link" onClick={() => setShowMore(!showMore)}>
                    {buttonText}
                </Button>
            </div>
        );
    };

    const renderTimelineOfBids = () => {
        if (activeVendorPriceItems.length === 0) {
            return <em className={styles.noBidText}>No suppliers bid on this line item.</em>;
        }

        return (
            <div className={styles.timeline}>
                <LineItemBidTimelineChart
                    auctionMaxFractionDigits={auctionMaxFractionDigits}
                    series={bidTimelineData}
                    timezone={timezone}
                />
                <ReverseAuctionBidHistoryTable
                    auctionMaxFractionDigits={auctionMaxFractionDigits}
                    bidHistoryData={bidHistoryData}
                    isAgencyView
                    timezone={timezone}
                />
            </div>
        );
    };

    return (
        <div className={`container ${styles.container}`}>
            <PageTitle title="Reverse Auction Line Item Summary" />
            <div className="row">
                <div className="col-md-3">
                    <div className={styles.navContainer}>
                        <Nav bsStyle="pills" stacked>
                            {NavItems}
                        </Nav>
                    </div>
                </div>
                <div className="col-md-9">
                    <LineItemDetailsInfoHeader
                        auctionMaxFractionDigits={auctionMaxFractionDigits}
                        lineItemTotalNumBids={lineItemTotalNumBids}
                        lowestUnitPrice={lowestUnitPrice}
                        minBidDecrement={minBidDecrement}
                        quantity={currentItemQuantity}
                        title={description}
                    />
                    <div className="row">
                        <div className="col-xs-12">
                            <h4 className={styles.priceItemListHeader}>Supplier Ranking</h4>
                            {renderListItems()}
                            {activeVendorPriceItems.length > 0 &&
                                vendorPriceItems.length > 3 &&
                                renderShowMoreButton()}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <h4 className={styles.priceItemListHeader}>Timeline of Bids</h4>
                            {renderTimelineOfBids()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

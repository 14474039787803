import React from 'react';
import { withRouter } from '@og-pro-migration-tools/react-router';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from '@og-pro/ui';

import { getRequisitionsPath } from './selectors';
import { qaTagPageName } from './constants';

// @withRouter
const ConnectedRequisitionAccountsLink = ({ params }) => {
    const requisitionsPath = getRequisitionsPath({ params });

    return (
        <Button
            color="primary"
            component={Link}
            qaTag={`${qaTagPageName}-searchByAccount`}
            to={`${requisitionsPath}/accounts/details`}
            variant="text"
        >
            Search by Account
        </Button>
    );
};

ConnectedRequisitionAccountsLink.propTypes = {
    params: PropTypes.object.isRequired,
};

export const RequisitionAccountsLink = withRouter(ConnectedRequisitionAccountsLink);

import { fromJS } from 'immutable';

import * as notificationActions from '../actions/notification';

const initialState = fromJS({
    notificationHideTimeoutId: null,
    showConnectionAlert: false,
    showNotification: false,
    snackbarMessages: [],
});

export default function notificationReducer(state = initialState, action = {}) {
    switch (action.type) {
        case notificationActions.SHOW:
            // Remove the hide timeout if it has not already fired
            if (state.get('notificationHideTimeoutId')) {
                clearTimeout(state.get('notificationHideTimeoutId'));
            }
            return state.merge(
                fromJS({
                    showNotification: true,
                    notificationHideTimeoutId: action.hideTimeoutId,
                    text: action.text,
                    notificationType: action.notificationType,
                })
            );
        case notificationActions.HIDE:
            return state.merge(
                fromJS({
                    showNotification: false,
                    notificationHideTimeoutId: null,
                })
            );
        case notificationActions.ADD_SNACKBAR_MESSAGE: {
            const updatedMessages = state.get('snackbarMessages').push(fromJS(action.data));
            return state.set('snackbarMessages', updatedMessages);
        }
        case notificationActions.REMOVE_SNACKBAR_MESSAGE: {
            const updatedMessages = state
                .get('snackbarMessages')
                .filter((snack) => snack.get('key') !== action.snackbarKey);
            return state.set('snackbarMessages', updatedMessages);
        }
        case notificationActions.SHOW_CONNECTION_ALERT:
            return state.merge(
                fromJS({
                    showConnectionAlert: true,
                    connectionAlertText: action.text,
                    connectionAlertType: action.alertType,
                })
            );
        case notificationActions.HIDE_CONNECTION_ALERT:
            return state.merge(
                fromJS({
                    showConnectionAlert: false,
                    connectionAlertText: null,
                    connectionAlertType: null,
                })
            );
        default:
            return state;
    }
}

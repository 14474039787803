import { truncate } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Button, CategoryCodes } from '../../../../../components';

const SUMMARY_LENGTH_LIMIT = 180;

export class ProjectListItem extends PureComponent {
    static propTypes = {
        categories: PropTypes.array.isRequired,
        copyCount: PropTypes.number.isRequired,
        currentSearch: PropTypes.string,
        government: PropTypes.shape({
            organization: PropTypes.shape({
                name: PropTypes.string,
            }).isRequired,
        }).isRequired,
        onClick: PropTypes.func.isRequired,
        releaseProjectDate: PropTypes.string,
        summary: PropTypes.string,
        title: PropTypes.string,
    };

    static defaultProps = {
        currentSearch: undefined,
        summary: undefined,
        title: undefined,
    };

    renderSummary() {
        const { summary, currentSearch } = this.props;
        const regex = new RegExp(currentSearch, 'i');
        const queryMatch = summary && summary.match(regex);

        if (currentSearch && queryMatch) {
            const [searchText] = queryMatch;
            const { index } = queryMatch;
            const start = summary.slice(0, index);
            const end = summary.slice(index + currentSearch.length);
            const startSpan = start.length > 60 ? `...${start.slice(-50)}` : start;
            const endSpan = end.length > 110 ? `${end.slice(0, 100)}...` : end;

            const styles = require('./ProjectListItem.scss');
            return (
                <span>
                    {startSpan}
                    <span className={styles.searchTextHighlight}>{searchText}</span>
                    {endSpan}
                </span>
            );
        }
        if (summary && summary.length > SUMMARY_LENGTH_LIMIT) {
            return truncate(summary, {
                length: SUMMARY_LENGTH_LIMIT - 10,
                separator: ' ',
            });
        }
        return summary;
    }

    render() {
        const {
            categories,
            copyCount,
            government: {
                organization: { name },
            },
            onClick,
            releaseProjectDate,
            title,
        } = this.props;

        const styles = require('./ProjectListItem.scss');
        const copyClass = copyCount >= 5 ? styles.hotCopy : '';

        return (
            <Button className={styles.listItem} onClick={onClick} qaTag={`librarySearch-${title}`}>
                <h4 className={`${styles.row} ${styles.title}`}>{title || 'Untitled'}</h4>
                <div className={styles.row}>
                    <i className="fa fa-university" /> {name}
                    <span className={styles.iconMenu}>
                        <i className="fa fa-calendar" />
                        &nbsp;
                        {moment(releaseProjectDate).format('l')}
                    </span>
                    <span className={styles.iconMenu}>
                        <i className="fa fa-copy" />
                        &nbsp;Copies:&nbsp;
                        <span className={copyClass}>{copyCount}</span>
                    </span>
                </div>
                <div className={styles.row}>
                    <i className="fa fa-tag" />
                    &nbsp;
                    <CategoryCodes codes={categories} limit={5} />
                </div>
                <div className={`text-muted ${styles.row}`}>
                    <em>{this.renderSummary()}</em>
                </div>
            </Button>
        );
    }
}

import { get, sortBy, uniqBy } from 'lodash';
import { createSelector } from 'reselect';

import { getFiscalYearTagsJS } from '../../selectors';

const getContractBudget = (state) => state.contracts.get('contractBudget');
const getContractLineItems = (state) => state.contracts.get('lineItems');
const getFiscalYearQueryParam = (state, props) => props.location.query['fiscal-year'];

export const getContractBudgetJS = createSelector([getContractBudget], (contractBudget) => {
    if (contractBudget) {
        return contractBudget.toJS();
    }
});

export const getContractLineItemsJS = createSelector(
    [getContractLineItems],
    (contractLineItems) => {
        if (contractLineItems) {
            return contractLineItems.toJS();
        }
    }
);

export const getBudgetAllocations = createSelector(
    [getContractBudgetJS],
    (contractBudget) => get(contractBudget, 'budgetAllocations') || []
);

export const getPurchaseOrders = createSelector(
    [getContractBudgetJS],
    (contractBudget) => get(contractBudget, 'purchaseOrders') || []
);

export const getFiscalYearTags = createSelector(
    [getBudgetAllocations, getPurchaseOrders],
    (budgetAllocations, purchaseOrders) => {
        const tags = budgetAllocations
            .map((allocation) => allocation.tag)
            .concat(purchaseOrders.map((po) => po.tag));

        return sortBy(uniqBy(tags, 'id'), 'orderIndex');
    }
);

export const getSelectedFiscalYearTag = createSelector(
    [getFiscalYearTags, getFiscalYearQueryParam],
    (fiscalYearTags, fiscalYearQueryParam) => {
        return fiscalYearTags.find(
            (fiscalYearTag) => fiscalYearTag.id.toString() === fiscalYearQueryParam
        );
    }
);

export const getDefaultFiscalYearTag = createSelector([getFiscalYearTagsJS], (fiscalYearTags) =>
    fiscalYearTags.find((fiscalYearTag) => fiscalYearTag.default)
);

export const getSelectedPurchaseOrders = createSelector(
    [getPurchaseOrders, getSelectedFiscalYearTag],
    (purchaseOrders, selectedFiscalYearTag) => {
        return purchaseOrders.filter((purchaseOrder) => {
            return !selectedFiscalYearTag || selectedFiscalYearTag.id === purchaseOrder.tag_id;
        });
    }
);

export const getSelectedBudgetAllocation = createSelector(
    [getBudgetAllocations, getSelectedFiscalYearTag],
    (budgetAllocations, selectedFiscalYearTag) => {
        if (selectedFiscalYearTag) {
            return budgetAllocations.find((budgetAllocation) => {
                return selectedFiscalYearTag.id === budgetAllocation.tag_id;
            });
        }
    }
);

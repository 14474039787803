import { fieldNames } from './constants';

const { NOTIFICATIONS } = fieldNames;

export function validate(values) {
    const errors = {};

    errors[NOTIFICATIONS] = (values[NOTIFICATIONS] || []).map((notification) => {
        const currentNotificationErrors = {};

        if (!notification.offset) {
            currentNotificationErrors.offset = 'A value is required';
        }
        if (notification.offset < 1) {
            currentNotificationErrors.offset = 'A positive value is required';
        }
        if (!notification.offsetUnit) {
            currentNotificationErrors.offsetUnit = 'Please select a value';
        }

        return currentNotificationErrors;
    });

    return errors;
}

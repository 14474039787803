import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Modal } from 'react-bootstrap';

import { EvaluatorReminderEmailForm } from './EvaluatorReminderEmailForm';

export class EvaluatorReminderEmailModal extends PureComponent {
    static propTypes = {
        hideModal: PropTypes.func.isRequired,
        submitHandler: PropTypes.func.isRequired,
    };

    render() {
        const { hideModal, submitHandler } = this.props;

        return (
            <Modal onHide={hideModal} show>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">Send Reminder Email</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EvaluatorReminderEmailForm
                        hideModal={hideModal}
                        submitHandler={submitHandler}
                    />
                </Modal.Body>
            </Modal>
        );
    }
}

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { createSearchParams } from 'react-router-dom';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { ProposalCreateSaveButton } from '../ProposalCreateSaveButton';
import { Button } from '../../..';
import { sectionValues } from '../../../ProposalCreateNav/constants';

const sectionShape = {
    title: PropTypes.string.isRequired,
    query: PropTypes.string.isRequired,
};

// @withRouter
class ConnectedProposalSectionButtons extends Component {
    static propTypes = {
        disabled: PropTypes.bool,
        hideSaveButton: PropTypes.bool,
        isFormDirty: PropTypes.bool,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
            query: PropTypes.shape({
                section: PropTypes.string,
            }).isRequired,
        }).isRequired,
        onSave: PropTypes.func.isRequired,
        router: PropTypes.object.isRequired,
        section: PropTypes.oneOf(sectionValues),
        sections: PropTypes.arrayOf(PropTypes.shape(sectionShape)),
        updateError: PropTypes.string,
    };

    saveIfDirty = () => {
        const { isFormDirty, onSave } = this.props;

        if (isFormDirty) {
            onSave();
        }
    };

    /**
     * Based on the current section, get the next section in sections, if there is one.
     *
     * @return {object|undefined} The next section if it exists, `undefined` otherwise
     */
    getNextSection = () => {
        const { section, sections } = this.props;

        const currentSectionIndex = sections.findIndex((sec) => sec.query === section);

        if (currentSectionIndex + 1 < sections.length) {
            return sections[currentSectionIndex + 1];
        }
    };

    /**
     * Based on the current section, get the previous section in sections, if there is one.
     *
     * @return {object|undefined} The previous section if it exists, `undefined` otherwise
     */
    getPreviousSection = () => {
        const { section, sections } = this.props;

        const currentSectionIndex = sections.findIndex((sec) => sec.query === section);

        // One to account for 0-index, one to see if there is a next item
        if (currentSectionIndex !== 0) {
            return sections[currentSectionIndex - 1];
        }
    };

    handleBackClick = () => {
        const {
            location: { pathname },
            router,
        } = this.props;

        this.saveIfDirty();
        const previousSection = this.getPreviousSection();

        router.push({
            pathname,
            search: createSearchParams({ section: previousSection.query }).toString(),
        });
    };

    handleContinueClick = () => {
        const {
            location: { pathname },
            router,
        } = this.props;

        this.saveIfDirty();
        const nextSection = this.getNextSection();

        router.push({
            pathname,
            search: createSearchParams({ section: nextSection.query }).toString(),
        });
    };

    render() {
        const { disabled, hideSaveButton, onSave, updateError } = this.props;

        const nextSection = this.getNextSection();
        const previousSection = this.getPreviousSection();

        return (
            <div className="col-md-12 text-center">
                {previousSection && (
                    <Button onClick={this.handleBackClick}>
                        <i className="fa fa-angle-left" /> Back
                    </Button>
                )}
                &nbsp;
                {!hideSaveButton && (
                    <ProposalCreateSaveButton
                        disabled={disabled}
                        onClick={onSave}
                        text="Save Progress"
                        tooltipPlacement="top"
                    />
                )}
                &nbsp;
                {nextSection && (
                    <Button bsStyle="primary" onClick={this.handleContinueClick}>
                        Continue <i className="fa fa-angle-right" />
                    </Button>
                )}
                {updateError && <div className="error-block">{updateError}</div>}
            </div>
        );
    }
}

export const ProposalSectionButtons = withRouter(ConnectedProposalSectionButtons);

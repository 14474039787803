import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Button } from '../../components';

export class CategorySelectItem extends PureComponent {
    static propTypes = {
        category: PropTypes.shape({
            children: PropTypes.array,
            code: PropTypes.string,
            icon: PropTypes.string,
            isLeaf: PropTypes.bool,
            title: PropTypes.string.isRequired,
        }).isRequired,
        isSelected: PropTypes.bool,
        loadHandler: PropTypes.func.isRequired,
        selectHandler: PropTypes.func.isRequired,
    };

    get styles() {
        return require('./shared.scss');
    }

    addButtonHandler = (e) => {
        const { category, selectHandler, isSelected } = this.props;

        e.stopPropagation();

        if (!isSelected) {
            selectHandler(category);
        }
    };

    clickHandler = () => {
        const { category, isSelected, loadHandler, selectHandler } = this.props;

        if (isSelected && category.isLeaf) {
            return undefined;
        }

        const clickHandler = category.isLeaf ? selectHandler : loadHandler;
        clickHandler(category);
    };

    selectAll = (e) => {
        const { category, loadHandler, selectHandler } = this.props;

        e.stopPropagation();

        return loadHandler(category, { omitActions: true }).then((categories) => {
            categories.forEach((cat) => {
                selectHandler(cat);
            });
        });
    };

    renderIcon() {
        const {
            category: { icon, isLeaf },
            isSelected,
        } = this.props;

        if (icon) {
            return <i className={`fa fa-fw fa-lg fa-${icon} text-primary`} />;
        }
        if (isLeaf && isSelected) {
            return <i className="fa fa-fw fa-check text-info" />;
        }
        if (isLeaf) {
            return <i className="fa fa-fw fa-plus text-success" />;
        }
        return <i className="fa fa-fw fa-level-down" />;
    }

    renderAddButton() {
        const {
            category: { icon },
            isSelected,
        } = this.props;

        if (icon) {
            return null;
        }
        return (
            <Button
                active={isSelected}
                bsSize="xs"
                bsStyle={isSelected ? 'primary' : undefined}
                className={this.styles.addButton}
                onClick={this.addButtonHandler}
            >
                {!isSelected && (
                    <>
                        <i className="fa fa-plus text-info" />
                        &nbsp;
                    </>
                )}
                {isSelected ? 'Added' : 'Add'}
            </Button>
        );
    }

    render() {
        const {
            category: { children, code, isLeaf, title },
            isSelected,
        } = this.props;

        return (
            <li
                className={classnames(
                    'list-group-item',
                    isSelected && 'list-group-item-info',
                    isSelected && this.styles.listItemSelected,
                    isSelected && isLeaf && this.styles.leafItemSelected,
                    this.styles.listItem,
                    this.styles.listItemNoPadding
                )}
            >
                <Button
                    className={this.styles.listItemButton}
                    onClick={this.clickHandler}
                    qaTag={`categorySearch-${code}`}
                >
                    <div className="pull-left">
                        {this.renderIcon()}
                        &nbsp;
                        {typeof code === 'string' && (
                            <span className={this.styles.selectedItemCode}>{code}</span>
                        )}
                    </div>
                    <div className={this.styles.listItemText}>
                        {title}
                        {children && children.length > 1 && (
                            <div className="text-muted">
                                <small>
                                    <em>{children.join(', ')}</em>
                                </small>
                            </div>
                        )}
                    </div>
                </Button>
                <div className={this.styles.listItemRightIcons}>
                    {this.renderAddButton()}
                    {children && children.length > 1 && (
                        <Button
                            bsSize="sm"
                            bsStyle="link"
                            className={this.styles.selectAllButton}
                            onClick={this.selectAll}
                            qaTag={`categorySearch-selectAll${code}`}
                        >
                            Select All
                        </Button>
                    )}
                    <div className={this.styles.leafIconContainer}>
                        {!isLeaf && <i className="fa fa-angle-right" />}
                    </div>
                </div>
            </li>
        );
    }
}

import Highcharts from 'highcharts';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { pieChartStatusColors } from './constants';
import { getChecklistsReleasedByAgeChartData } from './helpers';
import { NoChartDataPanel } from '../../../components';

const CHART_TITLE = 'Contracts by Checklist Age';

class ConnectedContractsByReleasedChecklistsAge extends PureComponent {
    static propTypes = {
        contracts: PropTypes.array.isRequired,
        router: PropTypes.object.isRequired,
    };

    get checklistsReleasedByAgeChartData() {
        return getChecklistsReleasedByAgeChartData(this.props.contracts, this.props.router);
    }

    get noData() {
        return !this.checklistsReleasedByAgeChartData.some((datum) => datum.custom.ids.length > 0);
    }

    componentDidMount() {
        this.renderChart();
    }

    renderChart() {
        if (!this.noData) {
            // eslint-disable-next-line new-cap
            return new Highcharts.chart({
                chart: {
                    type: 'pie',
                    renderTo: 'released-checklists-by-age-donut',
                    plotBackgroundColor: null,
                    plotBorderWidth: 0,
                    plotShadow: false,
                    borderWidth: 1,
                    borderColor: '#dce0e0',
                    height: 300,

                    marginTop: 80,
                },
                title: {
                    text: CHART_TITLE,
                    align: 'center',
                    y: 20,
                    style: {
                        fontWeight: 500,
                        fontSize: '18px',
                        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
                    },
                },
                tooltip: {
                    pointFormatter() {
                        if (this.y > 0) {
                            return `Contract${this.y > 1 ? 's: ' : ': '}${this.y}<br>Click to view`;
                        }
                    },
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        borderWidth: 3,
                        cursor: 'pointer',
                        colors: pieChartStatusColors,
                        center: ['50%', '80%'],
                        size: '130%',
                        startAngle: -90,
                        endAngle: 90,
                        dataLabels: {
                            alignTo: 'connectors',
                            connectorShape: 'crookedLine',
                            formatter() {
                                if (this.point.y > 0) {
                                    return `${this.point.name}:<br>${this.point.y} Contract${
                                        this.point.y > 1 ? 's' : ''
                                    }`;
                                }
                            },
                            verticalAlign: 'top',
                        },
                    },
                },
                credits: {
                    enabled: false,
                },
                series: [
                    {
                        type: 'pie',
                        name: 'Number of Contracts',
                        innerSize: '60%',
                        data: this.checklistsReleasedByAgeChartData,
                    },
                ],
            });
        }
    }

    render() {
        return (
            <>
                <div id="released-checklists-by-age-donut" />
                {this.noData && (
                    <NoChartDataPanel
                        noDataSuccess
                        subtitle="No contracts with released checklists"
                        title={CHART_TITLE}
                    />
                )}
            </>
        );
    }
}

export const ContractsByReleasedChecklistsAge = withRouter(
    ConnectedContractsByReleasedChecklistsAge
);

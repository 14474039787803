import PropTypes from 'prop-types';

/*
 * Types used by: Button and CDSButton
 */
export const buttonPropTypes = {
    active: PropTypes.bool,
    block: PropTypes.bool,
    bsClass: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    exact: PropTypes.bool,
    qaTag: PropTypes.string.isRequired, // Format: <pageName>-<simpleDescription>
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    tooltip: PropTypes.string,
    tooltipPlacement: PropTypes.string,
    zeroPadding: PropTypes.bool,
};

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { pseudoSectionTypes, sectionTypeNames } from '@og-pro/shared-config/sections';

import { LinkContainer, NavItem } from '../../../../../components';

export class SectionNavButton extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        index: PropTypes.number.isRequired,
        isActive: PropTypes.bool,
        meta: PropTypes.shape({
            valid: PropTypes.bool.isRequired,
        }).isRequired,
        onClick: PropTypes.func.isRequired,
        projectSection: PropTypes.shape({
            manualNumber: PropTypes.string,
            path: PropTypes.string.isRequired,
            section_type: PropTypes.string.isRequired,
            shortName: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
        }).isRequired,
        useManualNumbering: PropTypes.bool,
        useSectionDividers: PropTypes.bool.isRequired,
        wasVisited: PropTypes.bool.isRequired,
    };

    get styles() {
        return require('../../ProjectCreate.scss');
    }

    render() {
        const {
            disabled,
            index,
            isActive,
            meta: { valid },
            onClick,
            projectSection,
            useManualNumbering,
            useSectionDividers,
            wasVisited,
        } = this.props;

        const {
            activeTab,
            checkContainer,
            inactiveTab,
            invalid: invalidStyle,
            navigationItem,
            sectionDivider,
            tabText,
            valid: validStyle,
        } = this.styles;

        const { manualNumber, path, section_type: sectionType, shortName, title } = projectSection;

        const isDivider = sectionType === sectionTypeNames.DIVIDER;
        const activeTabClass = isActive ? activeTab : inactiveTab;
        const validClass = valid && wasVisited ? validStyle : invalidStyle;
        const indentTitle =
            useSectionDividers && !pseudoSectionTypes.some((type) => type === sectionType);
        const manualNumbering = useManualNumbering && manualNumber ? `${manualNumber} ` : '';

        if (useSectionDividers && isDivider) {
            return (
                <div className={classnames('row', navigationItem, sectionDivider)}>
                    <NavItem
                        disabled={disabled}
                        eventKey={index}
                        qaTag={`projectCreate-${shortName}`}
                    >
                        <div className={classnames('col-xs-9', tabText, sectionDivider)}>
                            {manualNumbering}
                            {title}
                        </div>
                    </NavItem>
                </div>
            );
        }

        return (
            <div className={classnames('row', activeTabClass, navigationItem)}>
                <LinkContainer
                    active={false}
                    className={activeTabClass}
                    disabled={disabled}
                    onClick={(event) => {
                        // Prevent default LinkContainer action so saveThenNavigate can run
                        event.preventDefault();
                        onClick(path);
                    }}
                    to={{ pathname: path }}
                >
                    <NavItem
                        disabled={disabled}
                        eventKey={index}
                        qaTag={`projectCreate-${shortName}`}
                    >
                        <div
                            className={classnames(
                                indentTitle ? 'col-xs-8 col-xs-offset-1' : 'col-xs-9',
                                tabText
                            )}
                        >
                            {manualNumbering} {title}
                        </div>
                        <div
                            className={classnames(
                                'col-xs-1 col-xs-offset-1 fa-stack',
                                checkContainer
                            )}
                        >
                            <i className={classnames('fa fa-circle fa-stack-2x', validClass)} />
                            <i className="fa fa-check fa-stack-1x fa-inverse" />
                        </div>
                    </NavItem>
                </LinkContainer>
            </div>
        );
    }
}

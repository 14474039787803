import React from 'react';
import { Outlet, redirect } from 'react-router-dom';

import { App } from '../containers';
import { NotFound } from '../components';
import { RouteHelpers } from './components/RouteHelpers';
import PublicAuthRoutes from './Public/Auth';
import PublicPortalRoutes from './Public/Portal';
import GovernmentRoutes from './Governments';
import VendorsRoutes from './Vendors';
import { GenericError } from './components';

export default (getState, dispatch) => {
    const routes = [
        {
            element: <RouteHelpers />,
            children: [
                {
                    element: <App />,
                    errorElement: <GenericError />,
                    path: '/',
                    children: [
                        // /government/:id/*
                        ...GovernmentRoutes(getState, dispatch),
                        ...PublicAuthRoutes(getState, dispatch),
                        // /portal/*
                        ...PublicPortalRoutes(getState, dispatch),
                        // /vendors/*
                        ...VendorsRoutes(getState, dispatch),
                        { element: <Outlet />, loader: () => redirect('/login'), path: '/' },
                        { element: <NotFound />, path: '*' },
                    ],
                },
            ],
        },
    ];

    return routes;
};

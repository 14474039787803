import qs from 'qs';

export const SEARCH = 'public/discovery/SEARCH';
export const SEARCH_SUCCESS = 'public/discovery/SEARCH_SUCCESS';
export const SEARCH_FAIL = 'public/discovery/SEARCH_FAIL';

/**
 * Searches for projects based on the specified search criteria.
 * @param {object} data The search criteria to use when searching for projects.
 * @param {object} [queryParams={}] Optional query parameters to include in the search request.
 * @param {string} [queryParams.limit] The maximum number of results to return per page. API default is 20.
 * @param {string} [queryParams.page] The page number to retrieve. API default is 1.
 * @returns {Promise} A Promise that results in the search results or an error getting loaded into the Redux store.
 */
export function searchProjects(data, queryParams = {}) {
    return (dispatch, getState, client) => {
        dispatch({ type: SEARCH });
        return client
            .post(`/project/search?${qs.stringify(queryParams)}`, { data })
            .then((result) => {
                dispatch({ type: SEARCH_SUCCESS, result });
            })
            .catch((error) => dispatch({ type: SEARCH_FAIL, error }));
    };
}

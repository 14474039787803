import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Box } from '@og-pro/ui';

import { CDSButton } from '../../../CDSButtons';

export const Header = ({ invalid, title, onCancel, onDelete, onSave, showButtons = true }) => {
    const styles = require('../../shared.scss');

    return (
        <Box className={classnames(styles.row, styles.alignItemsCenter, styles.marginBottom)}>
            <Box className={styles.col}>
                <h3 className={styles.h3}>{title}</h3>
            </Box>
            {showButtons && (
                <Box className={classnames(styles.col2, styles.textRight)}>
                    <Box alignItems="center" display="flex" justifyContent="flex-end">
                        {onDelete && (
                            <Box mr={1}>
                                <CDSButton
                                    className={styles.deleteButton}
                                    noPadding
                                    onClick={() => onDelete()}
                                    qaTag="projectSectionForm-delete"
                                    size="small"
                                    variant="text"
                                >
                                    <i className="fa fa-trash" /> Delete section
                                </CDSButton>
                            </Box>
                        )}
                        <Box mr={1}>
                            <CDSButton
                                onClick={() => onCancel()}
                                qaTag="projectSectionForm-headerCancel"
                                variant="secondary"
                            >
                                Cancel
                            </CDSButton>
                        </Box>
                        <Box>
                            <CDSButton
                                disabled={invalid}
                                onClick={() => onSave()}
                                qaTag="projectSectionForm-headerSave"
                                variant="primary"
                            >
                                Save
                            </CDSButton>
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

Header.propTypes = {
    invalid: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onSave: PropTypes.func.isRequired,
    showButtons: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

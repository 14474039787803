import React, { useEffect, useMemo } from 'react';
import { initialize, reduxForm, getFormSyncErrors } from 'redux-form';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';

import { AccountSelect } from './AccountSelect';
import { FiscalYear } from './FiscalYear';
import { fieldsDict, formConfig, validate } from './form';
import { CDSButton } from '../../../../../components';
import { loadAccountData } from '../../../../../actions/requisitionAccountDetails';
import { getIsGovernmentUsingFMS, getIsRequisitionUsingFMS } from '../../../../../selectors/govApp';
import { useQueryParam } from '../../../../../hooks';
import { hasFormErrors } from '../../../../../helpers';

const { ACCOUNT_ID, FISCAL_YEAR, ACCOUNT_NUMBER } = fieldsDict;

// @reduxForm
const ConnectedAccountDetailsFilters = ({ handleSubmit }) => {
    const styles = require('./index.scss');
    const dispatch = useDispatch();
    const formErrors = useSelector((state) => getFormSyncErrors(formConfig.form)(state));
    const isFormValid = useMemo(() => !hasFormErrors(formErrors), [formErrors]);

    const [params, , setMultipleQueryParams] = useQueryParam();

    const hasFMS = useSelector(getIsGovernmentUsingFMS);

    const submitHandler = (values) => {
        const accountId = hasFMS ? values[ACCOUNT_ID] : values[ACCOUNT_NUMBER]; // When connected to an FMS, ACCOUNT_ID is the value we use to store the account data
        const fiscalYear = values[FISCAL_YEAR];

        // If user is searching for the same account and fiscal year, just reload the data
        if (accountId === Number(params.accountId) && fiscalYear === params.fiscalYear) {
            return dispatch(loadAccountData(accountId, fiscalYear));
        }

        return setMultipleQueryParams({
            accountId,
            fiscalYear,
        });
    };

    useEffect(() => {
        // Initialize form when user gets to this page from a link
        dispatch(
            initialize(formConfig.form, {
                // ACCOUNT_NUMBER is the name of the field used on the AccountNumberInput. AccountNumberInput on page load tries to load with the value in FIN as accountId
                [ACCOUNT_NUMBER]: params.accountId,
                [FISCAL_YEAR]: params.fiscalYear,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles.container}>
            <h3 className={styles.title}>Search by Account Number</h3>
            <form className={styles.formContainer} onSubmit={handleSubmit(submitHandler)}>
                <AccountSelect accountId={params.accountId} />
                <FiscalYear />
                <CDSButton
                    className={styles.searchButton}
                    disabled={!isFormValid}
                    qaTag="accountDetails-searchButton"
                    type="submit"
                    variant="primary"
                >
                    Search
                </CDSButton>
            </form>
        </div>
    );
};

ConnectedAccountDetailsFilters.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
};

export const AccountDetailsFilters = (props) => {
    const hasFMS = useSelector(getIsRequisitionUsingFMS);
    const ConnectedForm = reduxForm({
        ...formConfig,
        validate: (values) => validate(values, { ...props, hasFMS }),
    })(ConnectedAccountDetailsFilters);

    return <ConnectedForm />;
};

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Panel } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Box } from '@og-pro/ui';

import {
    AgGridReact,
    AgGridReactPanelHeading,
    CDSButton,
    CertificationsDropdownSelect,
    VerifiedIcon,
    WebsiteCellRenderer,
    ProcuratedVendorCell,
} from '..';
import { CACHE_BLOCK_SIZE } from './constants';
import { retrieveVendors } from './helpers';
import {
    loadProcuratedRatingsClient,
    loadSubscribedVendorCertifications,
} from '../../actions/vendorList';
import { showVendorProfileModal } from '../../actions/vendorProfile';

const PANEL_STYLES = {
    border: 'none',
    boxShadow: 'none',
};

const CENTERED_PANEL_STYLES = {
    ...PANEL_STYLES,
    margin: '0px auto',
};

const PANEL_BODY_STYLES = {
    padding: 0,
};

const DateCellRenderer = (params) =>
    params.value !== null
        ? new Date(params.value).toLocaleDateString('en-us', { timeZone: 'UTC' })
        : null;

const VendorNameCell = (params) => <ProcuratedVendorCell hideLogo params={params} />;

const VerifiedCellRenderer = (params) =>
    params?.value ? (
        <Box alignItems="center" display="flex" height="100%" justifyContent="center">
            <VerifiedIcon />
        </Box>
    ) : null;

export const SubscribedVendorCertificationTable = ({ governmentId, isCentered }) => {
    const styles = require('./index.scss');
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const [gridApi, setGridApi] = useState(null);
    const [searchParams] = useSearchParams();

    const loadVendors = (api) => {
        const certificationIds = searchParams.getAll('certificationId').map(Number);
        const searchApi = retrieveVendors(
            loadSubscribedVendorCertifications,
            governmentId,
            certificationIds?.length > 0 && {
                certificationIds,
            },
            loadProcuratedRatingsClient,
            dispatch
        );

        api?.setServerSideDatasource(searchApi);
    };

    useEffect(() => {
        loadVendors(gridApi);
    }, [searchParams]);

    const showVendorProfileModalWrapper = (params) => {
        if (params?.data?.vendor_id) {
            dispatch(showVendorProfileModal(params.data.vendor_id));
        }
    };

    const columns = [
        {
            field: 'organization_name',
            headerName: 'Vendor Legal Name & Rating (Powered by Procurated)',
            width: 310,
            onCellClicked: showVendorProfileModalWrapper,
            cellRendererFramework: VendorNameCell,
        },
        {
            field: 'doing_business_as',
            headerName: 'Doing Business As (DBA) Name',
            width: 245,
        },
        {
            autoHeight: true,
            cellClass: ['wrapText'], // Used exclusively for Excel export styles
            field: 'title',
            headerName: 'Certification Type',
            width: 280,
        },
        {
            field: 'is_verified',
            headerName: 'Verified',
            width: 90,
            cellRendererFramework: VerifiedCellRenderer,
        },
        {
            field: 'certifying_agency',
            headerName: 'Certifying Agency',
            width: 250,
        },
        {
            field: 'certificate_number',
            headerName: 'Certificate Number',
            width: 250,
        },
        {
            field: 'issued_date',
            headerName: 'Issued Date',
            width: 115,
            cellRendererFramework: DateCellRenderer,
        },
        {
            field: 'expiration_date',
            headerName: 'Expiration Date',
            width: 135,
            cellRendererFramework: DateCellRenderer,
        },
        {
            field: 'ethnicity',
            headerName: 'Ethnicity',
            width: 120,
        },
        {
            field: 'website',
            headerName: 'Website',
            width: 300,
            cellRendererFramework: WebsiteCellRenderer,
        },
        {
            field: 'phoneComplete',
            headerName: 'Phone Number',
            width: 145,
        },
        {
            field: 'address',
            headerName: 'Address',
            width: 300,
        },
        {
            field: 'state_of_incorporation',
            headerName: 'State of Incorporation',
            width: 190,
        },
    ];

    const defaultColDef = {
        editable: false,
        suppressMenu: true,
    };

    /**
     * Given a row of data from the grid, get a unique ID for it.
     *
     * @param {object} data The raw data in this row from `getFormattedCertificationDataForGrid`
     * @return {string} The unique title of the row
     */
    const getRowNodeId = (data) => {
        return data.vendor_certification_id;
    };

    /**
     * Callback for saving a reference to the underlying AgReactGrid's API once it is ready. We need
     * access to the API to do things such as export data to a CSV.
     *
     * @param {object} params The `onGridReady` callback params
     * @param {object} params.api The underlying AgReactGrid's API
     */
    const onGridReady = (params) => {
        loadVendors(params.api);
        setGridApi(params.api);
    };

    return (
        <Panel defaultExpanded id="skip" style={isCentered ? CENTERED_PANEL_STYLES : PANEL_STYLES}>
            <AgGridReactPanelHeading
                gridApi={gridApi}
                title={
                    <Box className={styles.filterButtonContainer} display="flex">
                        <CertificationsDropdownSelect
                            label="Quick Filter by Certification Type"
                            onChange={(certificationIds) => {
                                navigate({
                                    pathname: `${location.pathname}`,
                                    search: createSearchParams({
                                        certificationId: certificationIds,
                                    }).toString(),
                                });
                            }}
                            selected={searchParams.getAll('certificationId').map(Number)}
                        />
                        {searchParams.getAll('certificationId').length > 0 && (
                            <CDSButton
                                onClick={() =>
                                    navigate({
                                        pathname: `${location.pathname}`,
                                        search: createSearchParams().toString(),
                                    })
                                }
                                qaTag="subscribedVendorCertificationTable-clearAllFilters"
                                size="small"
                                variant="text"
                            >
                                <i className="fa fa-close" /> Clear All Filters
                            </CDSButton>
                        )}
                    </Box>
                }
            />
            <Panel.Body style={PANEL_BODY_STYLES}>
                <AgGridReact
                    cacheBlockSize={CACHE_BLOCK_SIZE}
                    columns={columns}
                    defaultColDef={defaultColDef}
                    domLayout="autoHeight"
                    getRowNodeId={getRowNodeId}
                    hideSideBar
                    onGridReady={onGridReady}
                    pagination
                    paginationPageSize={15}
                    rowModelType="serverSide"
                />
            </Panel.Body>
        </Panel>
    );
};

SubscribedVendorCertificationTable.propTypes = {
    governmentId: PropTypes.number.isRequired,
    isCentered: PropTypes.bool,
};

import { isArray, isPlainObject, some, values } from 'lodash';

/*
 * Recursively traverses a redux-form error object searching for any errors.
 */
export function hasFormErrors(error) {
    if (isArray(error)) {
        // Handle special case where there is an array wide error decoration
        // eslint-disable-next-line no-underscore-dangle
        if (error._error) {
            return true;
        }
        return some(error, hasFormErrors);
    }

    if (isPlainObject(error)) {
        return some(values(error), hasFormErrors);
    }

    return !!error;
}

/*
 * Recursively counts the number of errors in a redux-form error object.
 */
export function countFormErrors(errors) {
    let count = 0;

    if (isArray(errors)) {
        errors.forEach((item) => {
            count += countFormErrors(item);
        });
    }

    if (isPlainObject(errors)) {
        Object.values(errors).forEach((value) => {
            count += countFormErrors(value);
        });
    }

    if (typeof errors === 'string' && errors.trim() !== '') {
        count += 1;
    }

    return count;
}

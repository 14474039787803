import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ListGroup, ListGroupItem, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import { intakeResources, scopeResources } from './constants';
import { hidePartnerResourcesModal } from '../../actions/app';

const mapStateToProps = (state) => {
    return {
        showModal: state.app.get('showPartnerResourcesModal'),
    };
};

const mapDispatchToProps = {
    hideModal: hidePartnerResourcesModal,
};

// @connect
class ConnectedPartnerResourcesModal extends Component {
    static propTypes = {
        hideModal: PropTypes.func.isRequired,
        showModal: PropTypes.bool.isRequired,
        type: PropTypes.oneOf(['intake', 'scope']),
    };

    componentWillUnmount() {
        const { hideModal, showModal } = this.props;

        if (showModal) {
            hideModal();
        }
    }

    get styles() {
        return require('./index.scss');
    }

    get resourcePartners() {
        const { type } = this.props;

        switch (type) {
            case 'intake':
                return intakeResources;
            case 'scope':
                return scopeResources;
            default:
                return [];
        }
    }

    render() {
        const { showModal, hideModal } = this.props;

        if (!showModal) {
            return null;
        }

        const resourcePartners = this.resourcePartners;

        return (
            <Modal onHide={hideModal} show>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">Partner Resources</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup>
                        {resourcePartners.map((resourcePartner, index) => (
                            <ListGroupItem
                                href={resourcePartner.url}
                                key={index}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <div className="row">
                                    <div
                                        className={`col-xs-12 col-sm-2 ${this.styles.logoContainer}`}
                                    >
                                        <img
                                            alt={resourcePartner.name}
                                            className={`img-rounded ${this.styles.logo}`}
                                            src={resourcePartner.logo}
                                        />
                                    </div>
                                    <div className="col-xs-12 col-sm-10">
                                        <h4 className={this.styles.name}>{resourcePartner.name}</h4>
                                        <p>{resourcePartner.description}</p>
                                    </div>
                                </div>
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </Modal.Body>
            </Modal>
        );
    }
}

export const PartnerResourcesModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedPartnerResourcesModal);

exports.getCodeBranch = () => {
    if (__PROD__) {
        return 'envs/prod';
    }
    if (__SANDBOX__) {
        return 'envs/sandbox';
    }
    if (__STAGING__) {
        return 'envs/staging';
    }
    if (__INT__) {
        return 'envs/integration';
    }
    if (__RDEV__) {
        return 'envs/development';
    }
    if (__DEMO__) {
        return 'envs/demo';
    }
    if (__PERF__) {
        return 'envs/performance';
    }

    return undefined;
};

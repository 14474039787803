import React from 'react';
import PropTypes from 'prop-types';

import { PROJECTS_LIST_LIMIT } from '../../../actions/projects';
import { Pagination } from '../../../components';

const ProjectListPagination = ({
    activePage,
    isLoading,
    displayText,
    onSelect,
    projectsCount,
    totalPages,
}) => {
    // TODO: Update production Webpack config to allow ES6-style stylesheet imports
    const styles = require('./ProjectListPagination.scss');

    // Don't show the paginator if there are not enough projects or
    // transitioning from one filter to another
    const shouldHidePaginator = projectsCount <= PROJECTS_LIST_LIMIT || (isLoading && !activePage);

    if (shouldHidePaginator) {
        return null;
    }

    return (
        <div className={styles.paginationContainer}>
            <Pagination
                activePage={activePage}
                className={styles.pagination}
                items={totalPages}
                maxButtons={4}
                next={activePage !== totalPages}
                onSelect={onSelect}
                prev={activePage !== 1}
            />
            {displayText && (
                <div className={styles.paginationText}>
                    {(activePage - 1) * PROJECTS_LIST_LIMIT + 1}-
                    {activePage * PROJECTS_LIST_LIMIT > projectsCount
                        ? projectsCount
                        : activePage * PROJECTS_LIST_LIMIT}
                    {' of '}
                    {projectsCount}
                </div>
            )}
        </div>
    );
};

ProjectListPagination.propTypes = {
    activePage: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    displayText: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
    projectsCount: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
};

export default ProjectListPagination;

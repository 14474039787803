import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';

import { form, fieldNames } from './constants';
import { Button, SearchSelect, SearchSelectUserOption } from '../../..';

const formConfig = {
    form,
};

// @reduxForm
class ConnectedAddFollowersForm extends PureComponent {
    static propTypes = {
        canComment: PropTypes.bool.isRequired,
        users: PropTypes.array.isRequired,
        submitting: PropTypes.bool.isRequired,
        submitServerError: PropTypes.string,
        className: PropTypes.string,
        handleSubmit: PropTypes.func.isRequired,
        cancelHandler: PropTypes.func.isRequired,
    };

    renderValidationError() {
        const { submitServerError } = this.props;
        const styles = require('./AddFollowersForm.scss');

        if (submitServerError) {
            return <div className={`text-danger ${styles.errorMessage}`}>{submitServerError}</div>;
        }
    }

    render() {
        const { canComment, handleSubmit, submitting, users, cancelHandler, className } =
            this.props;

        const styles = require('./AddFollowersForm.scss');

        const selectOptions = users.map((user) => {
            return {
                value: user.id,
                label: user.displayName,
                user,
            };
        });

        const addBtnIcon = submitting ? 'fa-spin fa-spinner' : 'fa-user-plus';
        const formStyle = className ? `clearfix ${className}` : 'clearfix';

        return (
            <form className={formStyle} onSubmit={handleSubmit}>
                <div className={`text-muted ${styles.inputLabel}`}>
                    Add or Remove Comment Followers:
                </div>
                <div className={styles.selectInput}>
                    <Field
                        component={SearchSelect}
                        components={{
                            Option: SearchSelectUserOption,
                        }}
                        disabled={!canComment || submitting}
                        isClearable
                        isMulti
                        name={fieldNames.FOLLOWER_IDS}
                        options={selectOptions}
                        placeholder="Select follower(s) or start typing name"
                        showInviteUserHelp
                        tabSelectsValue={false}
                    />
                    {this.renderValidationError()}
                </div>
                <div className={styles.buttons}>
                    <div className={styles.submitBtn}>
                        <Button
                            bsStyle="success"
                            disabled={!canComment || submitting}
                            type="submit"
                        >
                            <i className={`fa fa-fw ${addBtnIcon}`} /> Update
                        </Button>
                    </div>
                    <div className={styles.cancelBtn}>
                        <Button bsSize="sm" onClick={cancelHandler}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </form>
        );
    }
}

export const AddFollowersForm = reduxForm(formConfig)(ConnectedAddFollowersForm);

import { RAW_TEXT } from './constants';

export const validate = (values) => {
    const errors = {};

    if (!values[RAW_TEXT]) {
        errors[RAW_TEXT] = 'Text is required';
    }

    return errors;
};

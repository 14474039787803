import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { AddendumItem } from './AddendumItem';
import { FIXED_TOOLBAR_ADJUSTMENT_HEIGHT } from '../../constants/styles';

export class AddendumsList extends PureComponent {
    static propTypes = {
        addendums: PropTypes.array.isRequired,
        canEdit: PropTypes.bool,
        deleteHandler: PropTypes.func,
        downloadAttachments: PropTypes.func,
        hasAutoAddenda: PropTypes.bool,
        isGovernmentView: PropTypes.bool,
        isNotice: PropTypes.bool,
        isSubscribed: PropTypes.bool,
        loadingProposals: PropTypes.bool,
        location: PropTypes.shape({
            query: PropTypes.shape({
                'addendum-display': PropTypes.string,
                'addendum-id': PropTypes.string,
                'addendum-number': PropTypes.string,
                'notice-number': PropTypes.string,
            }).isRequired,
        }),
        proposals: PropTypes.array,
        project: PropTypes.object.isRequired,
        releaseHandler: PropTypes.func,
        updateHandler: PropTypes.func,
    };

    componentDidMount() {
        const { isGovernmentView } = this.props;

        const selectedAddendum = this.addendumFromQueryParams;

        // Scrolls to the selected addendum if an addendum query param is specified
        if (selectedAddendum) {
            const itemId = selectedAddendum.id;
            const targetEl = document.getElementById(`${this.classNameSelector}-${itemId}`);
            if (targetEl) {
                // Add styling to the selected element
                targetEl.className += ` ${this.styles.selectedField}`;

                // Need to take off the event loop for child rendering to
                // complete, which will allow the scroll to work properly
                setTimeout(() => {
                    targetEl.scrollIntoView();
                    const pageYOffset = window.pageYOffset;
                    if (pageYOffset) {
                        // On government view, offset scroll position to account for fixed navbar
                        const offset = isGovernmentView ? FIXED_TOOLBAR_ADJUSTMENT_HEIGHT : 10;

                        window.scroll(0, pageYOffset - offset);
                    }
                });
            }
        }
    }

    get styles() {
        return require('./index.scss');
    }

    get classNameSelector() {
        return this.props.isNotice ? 'notice-number' : 'addendum-number';
    }

    get addendumFromQueryParams() {
        const { addendums, location } = this.props;

        const addendumId = get(location, 'query.addendum-id');
        const addendumOrNoticeNumber = get(location, `query.${this.classNameSelector}`);

        if (addendumId) {
            const parsedId = Number.parseInt(addendumId, 10);
            return addendums.find((addendum) => addendum.id === parsedId);
        }

        if (addendumOrNoticeNumber) {
            const parsedNumber = Number.parseInt(addendumOrNoticeNumber, 10);
            return addendums.find((addendum) => addendum.number === parsedNumber);
        }

        return undefined;
    }

    render() {
        const {
            addendums,
            canEdit,
            deleteHandler,
            downloadAttachments,
            hasAutoAddenda,
            isGovernmentView,
            isNotice,
            isSubscribed,
            loadingProposals,
            location,
            proposals,
            project,
            releaseHandler,
            updateHandler,
        } = this.props;

        const showDiffModal = get(location, 'query.addendum-display') === 'diff';
        const selectedAddendumId = get(this.addendumFromQueryParams, 'id');

        return addendums.map((addendum) => {
            return (
                <AddendumItem
                    addendum={addendum}
                    canEdit={canEdit}
                    classNameSelector={this.classNameSelector}
                    deleteHandler={deleteHandler}
                    downloadAttachments={downloadAttachments}
                    hasAutoAddenda={hasAutoAddenda}
                    isGovernmentView={isGovernmentView}
                    isNotice={isNotice}
                    isSubscribed={isSubscribed}
                    key={addendum.id}
                    loadingProposals={loadingProposals}
                    project={project}
                    proposals={proposals}
                    releaseHandler={releaseHandler}
                    showDiffModalOnInitialization={
                        showDiffModal && selectedAddendumId === addendum.id
                    }
                    updateHandler={updateHandler}
                />
            );
        });
    }
}

export const searchSelectOptions = [
    { label: 'You', value: true },
    { label: 'Vendor', value: false },
];

export const searchSelectStyles = {
    control: {
        height: 28,
        minHeight: 28,
    },
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import { RejectForm } from '../RejectForm/RejectForm';

export class RejectModalBody extends PureComponent {
    static propTypes = {
        rejectHandler: PropTypes.func.isRequired,
        rejected: PropTypes.bool.isRequired,
        note: PropTypes.string,
        submitError: PropTypes.string,
        hideModal: PropTypes.func.isRequired,
    };

    renderRejectSuccess() {
        const { hideModal } = this.props;
        const styles = require('../ApprovalsModal.scss');

        return (
            <div className="text-center text-info">
                <i className="fa fa-3x fa-envelope" />
                <h3>Approval Decision Sent</h3>
                <div className={styles.confirmationInfo}>
                    Your approval decision has been sent to the project writer. The project writer
                    will send you a notification when the project has been revised and is ready to
                    be reviewed again.
                </div>
                <Button onClick={hideModal}>Close</Button>
            </div>
        );
    }

    render() {
        const { rejectHandler, rejected, note, hideModal, submitError } = this.props;

        return rejected ? (
            this.renderRejectSuccess()
        ) : (
            <RejectForm
                hideModal={hideModal}
                initialValues={{ comment: note }}
                onSubmit={rejectHandler}
                submitError={submitError}
            />
        );
    }
}

const activities = [
    'click',
    'mousedown',
    'mousemove',
    'mouseup',
    'keypress',
    'keyup',
    'touchstart',
    'touchend',
    'touchmove',
    'touchcancel',
    'scroll',
    'resize',
];

export const addSessionActivityListeners = (debouncedSessionCheck) => {
    activities.forEach((activity) => {
        document.addEventListener(activity, debouncedSessionCheck, true);
    });
};

export const removeSessionActivityListeners = (debouncedSessionCheck) => {
    activities.forEach((activity) => {
        document.removeEventListener(activity, debouncedSessionCheck, true);
    });
};

import { get, pick } from 'lodash';

import { reverseAuctionSocketActionType } from './constants';

export const JOIN = `${reverseAuctionSocketActionType}/JOIN`;
export const LEAVE = `${reverseAuctionSocketActionType}/LEAVE`;
export const PROXY_BID_DELETED = `${reverseAuctionSocketActionType}/PROXY_BID_DELETED`;

// These actions are only received by a broadcast from the socket server.
// The message originates from the API server and tells client when
// an auction has ended or been extended, or when a bid price has updated.
export const AUCTION_ENDED = 'reverseAuctionSocket/server/AUCTION_ENDED';
export const AUCTION_STARTED = 'reverseAuctionSocket/server/AUCTION_STARTED';
export const AUCTION_EXTENDED = 'reverseAuctionSocket/server/AUCTION_EXTENDED';
export const BID_PRICE_UPDATED = 'reverseAuctionSocket/server/BID_PRICE_UPDATED';
export const BID_REJECTED = 'reverseAuctionSocket/server/BID_REJECTED';
export const PROXY_BID_UPDATED = 'reverseAuctionSocket/server/PROXY_BID_UPDATED';

const getUserData = (state) => {
    const user = state.auth.get('user').toJS();
    return {
        ...pick(user, ['avatarUrl', 'displayName', 'email', 'id', 'initials', 'isVendor']),
        organizationId: get(user, 'organization.id'),
        organizationName: get(user, 'organization.name'),
    };
};

export function joinReverseAuction(projectId) {
    return (dispatch, getState) => {
        const state = getState();
        const user = getUserData(state);
        return dispatch({ type: JOIN, projectId, user });
    };
}

export function leaveReverseAuction(projectId) {
    return (dispatch, getState) => {
        const state = getState();
        const user = getUserData(state);
        return dispatch({ type: LEAVE, projectId, user });
    };
}

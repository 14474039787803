export const formatOptions = (requisitions) => {
    return requisitions.map((requisition) => {
        const name = requisition.descriptionOfRequest || 'Untitled Request';
        const label = `[${requisition.identifier}] ${name}`;
        return {
            requisition,
            label,
            value: requisition.id,
        };
    });
};

/* eslint-disable import/no-import-module-exports */
import { createStore, compose, applyMiddleware } from 'redux';

import thunkMiddleware from './thunkMiddleware';
import errorHandlerMiddleware from './errorHandlerMiddleware';
import socketsMiddleware from './socketsMiddleware';
import socketEmittingMiddleware from './socketEmittingMiddleware';
import { rootReducer } from '../../config';

export default function configureStore(client, browserHistory, initialState) {
    const customMiddleware = [
        socketEmittingMiddleware,
        ...socketsMiddleware(),
        errorHandlerMiddleware,
        thunkMiddleware(client),
    ];

    let finalCreateStore;
    // Add redux-devtools store middleware
    if (__DEV__) {
        const composeEnhancers =
            // eslint-disable-next-line
            (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
            compose;

        finalCreateStore = composeEnhancers(applyMiddleware(...customMiddleware))(createStore);
    } else {
        finalCreateStore = applyMiddleware(...customMiddleware)(createStore);
    }

    const store = finalCreateStore(rootReducer, initialState);

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept(['../reducers', '../../supportApp/reducers'], () => {
            const nextReducer = require('../../config').rootReducer;
            store.replaceReducer(nextReducer);
        });
    }

    return store;
}

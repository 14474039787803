import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import { form, SEARCH } from './constants';
import { InputText } from '../../../../components';

const formConfig = {
    form,
};

// @reduxForm
class ConnectedUserSearchForm extends Component {
    render() {
        return (
            <Field
                aria-label="Search user name or email"
                component={InputText}
                name={SEARCH}
                overrideFeedback
                placeholder="Search user name or email"
                qaTag="userForm-searchField"
                type="text"
            />
        );
    }
}

export const UserSearchForm = reduxForm(formConfig)(ConnectedUserSearchForm);

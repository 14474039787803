import { createSelector } from 'reselect';

import { generateOptions } from '../../../helpers/generateOptions';

const getRetentionCodes = (state) => state.retention.get('retentionCodes');
const getRetentionCode = (state) => state.retention.get('retentionCode');

export const getRetentionCodeJS = createSelector([getRetentionCode], (rawRetentionCode) => {
    if (rawRetentionCode) {
        return rawRetentionCode.toJS();
    }
    return [];
});

export const getGovernmentRetentionCodesJS = createSelector(
    [getRetentionCodes],
    (rawRetentionCodes) => {
        if (rawRetentionCodes) {
            return rawRetentionCodes.toJS();
        }
        return [];
    }
);

export const getActiveRetentionCodes = createSelector(
    [getGovernmentRetentionCodesJS],
    (retentionCodes) => {
        return retentionCodes.filter((retentionCode) => retentionCode.isActive);
    }
);

export const getRetentionCodesOptions = createSelector(
    [getActiveRetentionCodes],
    (activeRetentionCodes) => {
        return generateOptions(activeRetentionCodes, 'title', 'id');
    }
);

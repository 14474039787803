import { fieldNames } from '../constants';

const { NAME } = fieldNames;

export const validate = (values) => {
    const errors = {};

    if (!values[NAME]) {
        errors[NAME] = 'Please specify a name for the role';
    } else if (values[NAME].length > 128) {
        errors[NAME] = 'Name is too long';
    }

    return errors;
};

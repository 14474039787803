import { getFormValues } from 'redux-form';

import { form } from './constants';
import {
    getBuilderSection,
    getLoadInitialDataError,
    getProjectJS,
    getProjectSection,
    getProjectSubsectionsMap,
    getSuggestedScopeJS,
    getSuggestedPricingJS,
    getWritingSections,
    isLoadingInitialData,
} from './selectors';
import {
    getBuilderDisplayName,
    getProjectSectionTagOptions,
    getIntakePath,
    getIntakeWritingPath,
    getReviewPath,
    getWritingPath,
    isBudgetRequired,
    isBudgetUsed,
    isProjectIdRequired,
} from '../selectors';
import { getTemplateVariableOptions } from '../constants';
import { getUserJS } from '../../selectors';
import { shouldShowComments } from '../../../actions/govComments';

export function mapStateToProps(state, props) {
    const project = getProjectJS(state);
    const projectSubmitting = state.projectCreate.get('submitting');
    const updating = state.projectCreate.get('updating');
    const reviewPath =
        project && project.isIntake ? getIntakePath(state, props) : getReviewPath(state, props);
    const writingPath =
        project && project.isIntake
            ? getIntakeWritingPath(state, props)
            : getWritingPath(state, props);

    return {
        builderDisplayName: getBuilderDisplayName(state),
        builderSection: getBuilderSection(state, props),
        disabled: updating || projectSubmitting,
        formValues: getFormValues(form)(state) || {},
        hidePricing: state.projectCreate.get('hidePricing'),
        isBudgetRequired: isBudgetRequired(state), // Used by form `validate` function
        isBudgetUsed: isBudgetUsed(state), // Used by form `validate` function
        isProjectIdRequired: isProjectIdRequired(state), // Used by form `validate` function
        loaded: state.projectCreate.get('loaded'),
        loadingInitialData: isLoadingInitialData(state), // Whether any initial data is being loaded
        loadInitialDataError: getLoadInitialDataError(state),
        project,
        projectSection: getProjectSection(state, props),
        projectSubsectionsMap: getProjectSubsectionsMap(state, props),
        projectSubmitting,
        reviewPath,
        scrollToTop: state.projectCreate.get('scrollToTop'),
        shouldExport: state.projectCreate.get('shouldExport'),
        shouldExportDocument: state.projectCreate.get('shouldExportDocument'),
        shouldSneakyUpdate: state.projectCreate.get('shouldSneakyUpdate'),
        shouldSubmit: state.projectCreate.get('shouldSubmit'),
        shouldUpdate: state.projectCreate.get('shouldUpdate'),
        showComments: shouldShowComments(state),
        showFormErrors: state.projectCreate.get('showFormErrors'),
        sneakyNextRoute: state.projectCreate.get('sneakyNextRoute'),
        suggestedPricing: getSuggestedPricingJS(state),
        suggestedScope: getSuggestedScopeJS(state),
        tagOptions: getProjectSectionTagOptions(state),
        templateVariableOptions: getTemplateVariableOptions({ project }),
        timezone: state.auth.getIn(['user', 'organization', 'timezone']),
        updateError: state.projectCreate.get('updateError'),
        updating,
        user: getUserJS(state),
        writingPath,
        writingSections: getWritingSections(state),
    };
}

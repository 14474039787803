import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';

import { mappedContactFields } from '@og-pro/shared-config/contacts';

import { fieldNames } from './constants';
import { formConfig } from '../constants';
import { ProposalSectionButtons } from '../ProposalSectionButtons';
import { AddressFormSection, DateTimePicker, InputText, PhoneNumberInput, Well } from '../../..';
import { INFORMATION } from '../../../ProposalCreateNav/constants';

const {
    COMPANY_NAME,
    CONTACT_EMAIL,
    CONTACT_FIRST_NAME,
    CONTACT_LAST_NAME,
    CONTACT_WEBSITE,
    SUBMITTED_AT,
} = fieldNames;

// @reduxForm
class ConnectedProposalContactInformation extends Component {
    static propTypes = {
        allowSubmissionDateInput: PropTypes.bool,
        dirty: PropTypes.bool,
        disabled: PropTypes.bool,
        form: PropTypes.string.isRequired, // Also needed for @reduxForm above
        onSave: PropTypes.func.isRequired,
        proposalFormData: PropTypes.shape({
            isGovernmentSubmitted: PropTypes.bool.isRequired,
        }).isRequired,
        sections: PropTypes.array.isRequired,
        showValidation: PropTypes.bool,
        updateError: PropTypes.string,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const {
            allowSubmissionDateInput,
            dirty,
            disabled,
            form,
            onSave,
            proposalFormData,
            sections,
            showValidation,
            updateError,
        } = this.props;

        const isAddressRequired = !proposalFormData.isGovernmentSubmitted;

        return (
            <div className="row">
                <div className="col-xs-12 col-md-6">
                    <Field
                        component={InputText}
                        disabled={disabled}
                        hasFeedback={false}
                        label="Company Name*"
                        name={COMPANY_NAME}
                        placeholder="Required"
                        showValidation={showValidation}
                        type="text"
                    />
                    <AddressFormSection
                        disabled={disabled}
                        fieldNames={mappedContactFields}
                        form={form}
                        hasRequiredStar={isAddressRequired}
                        optional={!isAddressRequired}
                    />
                </div>
                <div className="col-xs-12 col-md-6">
                    <Field
                        component={InputText}
                        disabled={disabled}
                        hasFeedback={false}
                        label="Contact Email*"
                        name={CONTACT_EMAIL}
                        placeholder="Required"
                        showValidation={showValidation}
                        type="text"
                    />
                    <Field
                        component={InputText}
                        disabled={disabled}
                        hasFeedback={false}
                        label="Contact First Name"
                        name={CONTACT_FIRST_NAME}
                        placeholder="Optional"
                        showValidation={showValidation}
                        type="text"
                    />
                    <Field
                        component={InputText}
                        disabled={disabled}
                        hasFeedback={false}
                        label="Contact Last Name"
                        name={CONTACT_LAST_NAME}
                        placeholder="Optional"
                        showValidation={showValidation}
                        type="text"
                    />
                    <Field
                        component={InputText}
                        disabled={disabled}
                        hasFeedback={false}
                        label="Website"
                        name={CONTACT_WEBSITE}
                        placeholder="Optional"
                        showValidation={showValidation}
                        type="text"
                    />
                    <PhoneNumberInput
                        disabled={disabled}
                        fieldNames={mappedContactFields}
                        formName={form}
                        phoneFieldLabel="Contact Phone"
                        placeholder="Optional"
                        showValidation={showValidation}
                    />
                </div>
                {allowSubmissionDateInput && (
                    <div className="col-xs-12">
                        <Well>
                            <div className="row">
                                <div className="col-md-8 col-md-offset-2">
                                    <Field
                                        component={DateTimePicker}
                                        disabled={disabled}
                                        dropUp
                                        label="Response Submission Date"
                                        name={SUBMITTED_AT}
                                        showValidation={showValidation}
                                        time
                                    />
                                </div>
                            </div>
                        </Well>
                    </div>
                )}
                <div className={`col-xs-12 ${this.styles.proposalSectionButtons}`}>
                    <ProposalSectionButtons
                        disabled={disabled}
                        isFormDirty={dirty}
                        onSave={onSave}
                        section={INFORMATION}
                        sections={sections}
                        updateError={updateError}
                    />
                </div>
            </div>
        );
    }
}

export const ProposalContactInformation = reduxForm(formConfig)(
    ConnectedProposalContactInformation
);

import { get } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

import {
    getIncompleteRequiredVendorDatumKeysJS,
    getPublicGovernmentJS,
    getUserJS,
} from '../selectors';
import { hideVendorAccountModal } from '../../actions/adminVendor';
import { Button, OrganizationInfoForm } from '../../components';
import { VendorCategories, VendorCertifications, VendorDocuments } from '../VendorApp';
import {
    requiredVendorDatumFieldNames,
    vendorDatumAttachmentKeys,
    vendorDatumProfileKeys,
} from '../../constants/requiredVendorDatum';

const { CATEGORY_CODES_REQUIRED, DBE_CERTIFICATIONS_REQUIRED } = requiredVendorDatumFieldNames;

const mapStateToProps = (state) => {
    return {
        displayForm: state.adminVendor.get('displayVendorAccountForm'),
        government: getPublicGovernmentJS(state),
        incompleteRequiredVendorDatumKeys: getIncompleteRequiredVendorDatumKeysJS(state),
        onComplete: state.adminVendor.get('vendorAccountCompleteHandler'),
        showModal: state.adminVendor.get('showVendorAccountModal'),
        user: getUserJS(state),
    };
};

const mapDispatchToProps = {
    hideModal: hideVendorAccountModal,
};

// @connect
class ConnectedVendorAccountModal extends Component {
    static propTypes = {
        displayForm: PropTypes.bool,
        government: PropTypes.shape({
            categorySetId: PropTypes.number,
            requiredVendorDatum: PropTypes.object,
        }),
        hideModal: PropTypes.func.isRequired,
        incompleteRequiredVendorDatumKeys: PropTypes.array,
        onComplete: PropTypes.func,
        showModal: PropTypes.bool,
        user: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = this.initialState;
    }

    get hasIncompleteVendorDatumKeys() {
        const { incompleteRequiredVendorDatumKeys } = this.props;
        return incompleteRequiredVendorDatumKeys && incompleteRequiredVendorDatumKeys.length > 0;
    }

    get currPageIdx() {
        const { showPage, pages } = this.state;
        return pages.findIndex((page) => page === showPage);
    }

    get pages() {
        const { incompleteRequiredVendorDatumKeys } = this.props;
        const pages = [];

        if (this.hasIncompleteVendorDatumKeys) {
            if (
                incompleteRequiredVendorDatumKeys.some((key) =>
                    vendorDatumProfileKeys.includes(key)
                )
            ) {
                pages.push(1);
            }
            if (incompleteRequiredVendorDatumKeys.some((key) => key === CATEGORY_CODES_REQUIRED)) {
                pages.push(2);
            }
            if (
                incompleteRequiredVendorDatumKeys.some((key) => key === DBE_CERTIFICATIONS_REQUIRED)
            ) {
                pages.push(3);
            }
            if (
                incompleteRequiredVendorDatumKeys.some((key) =>
                    vendorDatumAttachmentKeys.includes(key)
                )
            ) {
                pages.push(4);
            }
        }
        return pages;
    }

    get styles() {
        return require('./index.scss');
    }

    initialState = {
        pages: this.pages,
        showForm: false,
    };

    componentDidUpdate(prevProps) {
        if (!prevProps.showModal && this.props.showModal) {
            return this.setState({
                pages: this.pages, // Setting `pages` just this once prevents pages from disappearing as they are completed in case any mistakes are made
                showForm: this.pages.length > 0,
                showPage: this.pages[0],
            });
        }
    }

    onComplete = () => {
        if (this.props.onComplete) {
            this.props.onComplete();
        }
        this.hideModal();
    };

    hideModal = () => {
        this.props.hideModal();
        this.setState(this.initialState);
    };

    showForm = () => {
        this.setState({ showForm: true });
    };

    renderMissingInfo() {
        return (
            <div className="row">
                <h4 className={`col-xs-12 text-info ${this.styles.header}`}>
                    Your Company Profile is Incomplete
                </h4>
                <div className={`col-xs-12 ${this.styles.subheader}`}>
                    Would you like to finish it now?
                </div>
                <div className="col-xs-12 text-center">
                    <Button
                        className={this.styles.smallBtn}
                        onClick={this.onComplete}
                        qaTag="vendorAccountModal-later"
                    >
                        Later
                    </Button>
                    <Button
                        bsStyle="primary"
                        className={this.styles.bigBtn}
                        onClick={this.showForm}
                        qaTag="vendorAccountModal-update"
                    >
                        <i className="fa fa-pencil" /> Update
                    </Button>
                </div>
            </div>
        );
    }

    backHandler = () => {
        const { pages } = this.state;
        return this.setState(() => {
            if (this.currPageIdx === 0) {
                return;
            }
            return { showPage: pages[this.currPageIdx - 1] };
        });
    };

    nextHandler = () => {
        const { pages } = this.state;
        return this.setState(() => {
            if (this.currPageIdx === pages.length - 1) {
                return;
            }
            return { showPage: pages[this.currPageIdx + 1] };
        });
    };

    renderPaginatedForm() {
        const {
            government: { categorySetId, requiredVendorDatum },
            incompleteRequiredVendorDatumKeys,
        } = this.props;
        const { pages, showPage } = this.state;

        const renderCurrentPage = () => {
            switch (showPage) {
                case 2:
                    return (
                        <VendorCategories
                            govCategorySetId={categorySetId}
                            isGovRequired={get(requiredVendorDatum, CATEGORY_CODES_REQUIRED)}
                            isModal
                        />
                    );
                case 3:
                    return <VendorCertifications isModal />;
                case 4:
                    return <VendorDocuments isModal requiredVendorDatum={requiredVendorDatum} />;
                case 1:
                default:
                    return (
                        <OrganizationInfoForm
                            incompleteRequiredVendorDatumKeys={incompleteRequiredVendorDatumKeys}
                            isModal
                            requiredVendorDatum={requiredVendorDatum}
                        />
                    );
            }
        };

        const nextButtonDisabled = this.pages.some((page) => page === this.state.showPage);

        return (
            <>
                <div className="row">
                    <div className="col-xs-12">
                        {this.currPageIdx !== 0 && (
                            <Button
                                className="pull-left"
                                onClick={this.backHandler}
                                qaTag="vendorAccountModal-back"
                            >
                                <i className="fa fa-angle-left" /> Back
                            </Button>
                        )}
                        <div className="pull-right">
                            {this.currPageIdx !== pages.length - 1 && (
                                <Button
                                    disabled={nextButtonDisabled}
                                    onClick={this.nextHandler}
                                    qaTag="vendorAccountModal-next"
                                    tooltip={
                                        nextButtonDisabled
                                            ? 'Please update & save the required information on this page before moving on to the next'
                                            : ''
                                    }
                                >
                                    Next <i className="fa fa-angle-right" />
                                </Button>
                            )}
                            <Button
                                bsStyle="primary"
                                className={this.styles.continueBtn}
                                disabled={this.hasIncompleteVendorDatumKeys}
                                onClick={this.onComplete}
                                qaTag="vendorAccountModal-continue"
                                tooltip={
                                    this.hasIncompleteVendorDatumKeys
                                        ? 'You will be able to continue when all agency-required information is complete'
                                        : ''
                                }
                            >
                                Continue <i className="fa fa-angle-double-right" />
                            </Button>
                        </div>
                    </div>
                </div>
                {renderCurrentPage()}
            </>
        );
    }

    renderForm() {
        if (this.state.pages.length > 0) {
            return this.renderPaginatedForm();
        }
        return <OrganizationInfoForm isModal onComplete={this.onComplete} />;
    }

    render() {
        if (!this.props.user) return null;

        const { displayForm, showModal } = this.props;
        const { showForm } = this.state;

        return (
            <Modal onHide={this.hideModal} show={showModal}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center" componentClass="div">
                        <h4>Complete Company Profile</h4>
                        {this.hasIncompleteVendorDatumKeys && (
                            <p className={`text-danger ${this.styles.modalSubtitle}`}>
                                This agency requires certain information to be complete before
                                continuing
                            </p>
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body data-qa="vendorAccount-modal-body">
                    {showForm || displayForm ? this.renderForm() : this.renderMissingInfo()}
                </Modal.Body>
            </Modal>
        );
    }
}

export const VendorAccountModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedVendorAccountModal);

import classnames from 'classnames';
import { get } from 'lodash';
import moment from 'moment-timezone/builds/moment-timezone-with-data-1970-2030';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ListGroupItem } from 'react-bootstrap';

import { projectStatusesDict } from '@og-pro/shared-config/projects';

import { LinkContainer, ProjectStatusLabel, UserProfilePicture } from '../..';
import { statusClass } from '../../helpers/statusHelper';

const { REQUEST_DRAFT, REQUEST_REVIEW } = projectStatusesDict;

export class IntakesListItem extends PureComponent {
    static propTypes = {
        project: PropTypes.shape({
            created_at: PropTypes.string.isRequired,
            financialId: PropTypes.string,
            isPaused: PropTypes.bool.isRequired,
            projectApprovalSteps: PropTypes.arrayOf(
                PropTypes.shape({
                    projectApprovals: PropTypes.arrayOf(
                        PropTypes.shape({
                            created_at: PropTypes.string.isRequired,
                            user: PropTypes.object.isRequired,
                        })
                    ),
                })
            ),
            status: PropTypes.string.isRequired,
            title: PropTypes.string,
            updated_at: PropTypes.string.isRequired,
            user: PropTypes.shape({
                displayName: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        to: PropTypes.string.isRequired,
    };

    get styles() {
        return require('../shared.scss');
    }

    get displayStatus() {
        const {
            project: { status },
        } = this.props;

        if (status === REQUEST_DRAFT) {
            return 'draft';
        }
        if (status === REQUEST_REVIEW) {
            return 'review';
        }
        return status;
    }

    defaultAssignedUser = {
        displayName: 'Unassigned',
    };

    renderStatusColumn(className) {
        const {
            project: { isPaused, status, updated_at: updatedAt },
        } = this.props;

        return (
            <div className={className} title={`Last updated ${moment(updatedAt).format('lll')}`}>
                <ProjectStatusLabel
                    className={classnames(
                        this.styles.statusLabel,
                        this.styles[statusClass(status)]
                    )}
                    status={status}
                />
                <div className={`text-center ${this.styles.date}`}>
                    {moment(updatedAt).fromNow()}
                </div>
                {isPaused && (
                    <div className={this.styles.onHoldLabel}>
                        <i className="fa fa-lg fa-pause-circle" /> On Hold
                    </div>
                )}
            </div>
        );
    }

    render() {
        const {
            project: { created_at: createdAt, financialId, projectApprovalSteps, title, user },
            to,
        } = this.props;

        const approver =
            get(projectApprovalSteps, '0.projectApprovals.0.user') || this.defaultAssignedUser;

        const assignedDate = get(projectApprovalSteps, '0.projectApprovals.0.created_at');

        return (
            <LinkContainer to={to}>
                <ListGroupItem className={this.styles.item}>
                    <div className="row">
                        {this.renderStatusColumn(
                            classnames('col-xs-12 col-sm-2 hidden-xs', this.styles.skinnyColRight)
                        )}
                        <div className={classnames('col-xs-12 col-sm-4', this.styles.titleCol)}>
                            <h4 className={this.styles.title}>{title || 'Untitled'}</h4>
                            {financialId && (
                                <div className={this.styles.projectId}>
                                    <span>Project ID:</span> {financialId}
                                </div>
                            )}
                        </div>
                        {this.renderStatusColumn('col-xs-12 col-sm-2 visible-xs-block')}
                        <div className={classnames('col-xs-12 col-sm-3', this.styles.skinnyCol)}>
                            <div className={this.styles.userLabel}>Created At:</div>
                            <UserProfilePicture
                                className={this.styles.userProfilePicture}
                                horizontal
                                user={user}
                            />
                            <div className={this.styles.user}>
                                {user.displayName}
                                <div className={this.styles.date}>
                                    {moment(createdAt).format('M/D/YY')}&nbsp;
                                    {moment(createdAt).format('h:mma')}
                                </div>
                            </div>
                        </div>
                        <div className={classnames('col-xs-12 col-sm-3', this.styles.skinnyCol)}>
                            <div className={this.styles.userLabel}>Assigned To:</div>
                            <UserProfilePicture
                                className={this.styles.userProfilePicture}
                                defaultIcon="fa-question"
                                horizontal
                                user={approver}
                            />
                            <div className={this.styles.user}>
                                {approver.displayName}
                                {assignedDate && (
                                    <div className={this.styles.date}>
                                        {moment(assignedDate).format('M/D/YY')}&nbsp;
                                        {moment(assignedDate).format('h:mma')}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </ListGroupItem>
            </LinkContainer>
        );
    }
}

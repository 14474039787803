import { useCallback, useState } from 'react';
import { memoize } from 'lodash';

import EsriGeolocation from '../lib/esri-geolocation';

export const useEsriGeolocation = () => {
    const [loading, setLoading] = useState(0);

    // memoized to prevent hitting the API multiple times with the same query
    const memoizedSuggest = useCallback(
        memoize(
            async (params) => {
                const { suggestions } = await EsriGeolocation.suggest(params);

                return suggestions;
            },
            // this function tells memoize what param to use as the key
            (params) => params.text
        ),
        []
    );

    // memoized to prevent hitting the API multiple times with the same query
    const memoizedFindAddressCandidates = useCallback(
        memoize(
            async (params) => {
                const { candidates } = await EsriGeolocation.findAddressCandidates(params);

                return candidates;
            },
            // this function tells memoize what param to use as the key
            (params) => params.SingleLine
        ),
        []
    );

    const suggest = useCallback(async (params) => {
        try {
            setLoading((l) => l + 1);

            const result = await memoizedSuggest(params);

            return result;
        } finally {
            setLoading((l) => l - 1);
        }
    }, []);

    const findAddressCandidates = useCallback(async (params) => {
        try {
            setLoading((l) => l + 1);

            const candidates = await memoizedFindAddressCandidates(params);

            return candidates;
        } finally {
            setLoading((l) => l - 1);
        }
    }, []);

    return {
        loading: loading > 0,
        suggest,
        findAddressCandidates,
    };
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createSearchParams } from 'react-router-dom';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { USE_PROCURATED } from '@og-pro/shared-config/procurated';

import { FOLLOWING, SHARED, proposalFilters } from './constants';
import { getFollowedProjectsJS, getLiveReverseAuctionList, getProposalsJS } from './selectors';
import connectData from '../../ConnectData';
import { getUserJS, isSystemAdminUser } from '../../selectors';
import {
    filterProposals,
    menuActionHandler,
    vendUserLoadProjectSubscriptions,
} from '../../../actions/vendProposals';
import {
    Button,
    ButtonGroup,
    DashboardControlNav,
    LoadingError,
    LoadingSpinner,
    Main,
    PageTitle,
    ProcuratedBox,
    TourButton,
} from '../../../components';
import { ReverseAuctionListBanner } from '../../../components/ReverseAuction';
import {
    FollowedProjectListItem,
    NoProposals,
    NoSharedProposals,
    ProposalsListItem,
} from '../../../components/VendorApp';

function fetchData(getState, dispatch, location, params) {
    return dispatch(filterProposals(location.searchParams?.get('filter'), params.vendorId));
}

const mapStateToProps = (state) => {
    return {
        followedProjects: getFollowedProjectsJS(state),
        isSystemAdmin: isSystemAdminUser(state),
        liveReverseAuctionList: getLiveReverseAuctionList(state),
        loadError: state.vendProposals.get('loadAllError'),
        loading: state.vendProposals.get('loadingAll'),
        proposals: getProposalsJS(state),
        user: getUserJS(state),
    };
};

const mapDispatchToProps = {
    menuActionHandler,
    vendUserLoadProjectSubscriptions,
};

// @connectData
// @connect
class ConnectedProposalsList extends Component {
    static propTypes = {
        followedProjects: PropTypes.array.isRequired,
        isSystemAdmin: PropTypes.bool.isRequired,
        liveReverseAuctionList: PropTypes.array.isRequired,
        loadError: PropTypes.string,
        loading: PropTypes.bool,
        location: PropTypes.object.isRequired,
        menuActionHandler: PropTypes.func.isRequired,
        proposals: PropTypes.array.isRequired,
        user: PropTypes.object.isRequired,
        vendUserLoadProjectSubscriptions: PropTypes.func.isRequired,
    };

    get styles() {
        return require('./ProposalsList.scss');
    }

    renderControlNav() {
        return (
            <DashboardControlNav logoUrl={this.props.user.organization.logo}>
                {this.renderFilterButtons()}
            </DashboardControlNav>
        );
    }

    renderFilterButtons() {
        const {
            location: { pathname },
            user,
        } = this.props;

        const filterButtons = proposalFilters.map((btn) => (
            <Button
                className={`filter-${btn.key} ${this.styles.filterBtn}`}
                key={btn.key}
                qaTag={`proposalsList-${btn.qaTagDescription}`}
                to={{ pathname, search: createSearchParams({ filter: btn.filter }).toString() }}
            >
                <i className={`fa fa-fw fa-${btn.icon}`} /> {btn.text}
            </Button>
        ));

        return (
            <div>
                <strong>Find Responses</strong>
                <ButtonGroup block className={this.styles.filterButtons} vertical>
                    {filterButtons}
                </ButtonGroup>
                {USE_PROCURATED && (
                    <ProcuratedBox supplierId={user.vendor_id} vendorSelfManagement />
                )}
            </div>
        );
    }

    renderNoProposals() {
        const {
            location: {
                query: { filter },
            },
            user,
        } = this.props;

        const noProjectsPath = `/vendors/${user.vendor_id}/my-subscriptions`;
        switch (filter) {
            case SHARED:
                return <NoSharedProposals />;
            case FOLLOWING:
                return (
                    <NoProposals
                        info="Once you follow a project, it will be displayed here."
                        link={noProjectsPath}
                        title="Not Following Any Projects Yet!"
                    />
                );
            default:
                return <NoProposals link={noProjectsPath} />;
        }
    }

    renderBody() {
        const {
            followedProjects,
            isSystemAdmin,
            liveReverseAuctionList,
            loadError,
            loading,
            location: {
                pathname,
                query: { filter },
            },
            proposals,
            user,
        } = this.props;

        if (loading) {
            return <LoadingSpinner centered />;
        }

        if (loadError) {
            return <LoadingError error={loadError} />;
        }

        if (filter === FOLLOWING) {
            if (followedProjects.length === 0) {
                return this.renderNoProposals();
            }

            return (
                <div className="list-unstyled">
                    {followedProjects.map((project) => (
                        <FollowedProjectListItem
                            key={project.id}
                            project={project}
                            user={user}
                            vendUserLoadProjectSubscriptions={
                                this.props.vendUserLoadProjectSubscriptions
                            }
                        />
                    ))}
                </div>
            );
        }

        if (proposals.length === 0) {
            return this.renderNoProposals();
        }

        const proposalItems = proposals.map((proposal) => (
            <li key={proposal.id}>
                <ProposalsListItem
                    {...proposal}
                    isOwner={proposal.user_id === user.id}
                    isSystemAdmin={isSystemAdmin}
                    key={proposal.id}
                    menuActionHandler={this.props.menuActionHandler}
                    pathname={pathname}
                    proposal={proposal}
                />
            </li>
        ));
        return (
            <>
                {liveReverseAuctionList.length > 0 && (
                    <ReverseAuctionListBanner liveReverseAuctionList={liveReverseAuctionList} />
                )}
                <ul className="list-unstyled">{proposalItems}</ul>
            </>
        );
    }

    render() {
        return (
            <Main className={`row ${this.styles.proposalListContainer}`}>
                <PageTitle title="Response List" />
                <h1 className="visually-hidden">Responses List</h1>
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-xs-6 col-sm-4 col-md-3">
                            <div className={this.styles.tourButtonContainer}>
                                <TourButton
                                    className="tutorial-btn"
                                    qaTag="vendorProjectList-tour"
                                    text="Show Tutorial"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-sm-4 col-md-3">{this.renderControlNav()}</div>
                <div className="col-sm-8 col-md-9">{this.renderBody()}</div>
            </Main>
        );
    }
}

export const ProposalsList = compose(
    connectData(fetchData),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedProposalsList);

import PropTypes from 'prop-types';
import React from 'react';

export const SequenceStepDisplayHeader = ({
    meta: { error },
    showFormValidation,
    stepName,
    stepNumber,
}) => {
    const styles = require('./index.scss');
    const showError = showFormValidation && !!error;

    return (
        <div>
            {showError && (
                <div className="text-danger">
                    <i className="fa fa-exclamation-triangle" /> {error}
                </div>
            )}
            <h4 className={styles.stepTitle}>
                Step #{stepNumber}
                {stepName && `: ${stepName}`}
            </h4>
        </div>
    );
};

SequenceStepDisplayHeader.propTypes = {
    meta: PropTypes.shape({
        error: PropTypes.string,
    }).isRequired,
    showFormValidation: PropTypes.bool,
    stepName: PropTypes.string,
    stepNumber: PropTypes.number.isRequired,
};

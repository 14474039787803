import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';

export const ProjectHoldStatus = ({ project }) => {
    if (!project?.isPaused) {
        return null;
    }

    const styles = require('./index.scss');

    return (
        <Alert bsStyle="warning" className={styles.alert}>
            <i className="fa fa-pause-circle" /> Project is On Hold
            {project.isPausedReason ? `: ${project.isPausedReason}` : ''}
        </Alert>
    );
};

ProjectHoldStatus.propTypes = {
    project: PropTypes.shape({
        isPaused: PropTypes.bool,
        isPausedReason: PropTypes.string,
    }).isRequired,
};

const { listToDict } = require('../helpers');

// https://sendgrid.com/docs/for-developers/tracking-events/event
const sendGridEventTypes = [
    // Delivery Events
    'processed',
    'dropped',
    'delivered',
    'deferred',
    'bounce',
    // Engagement Events
    'open',
    'click',
    'spamreport',
    'unsubscribe',
    'group_unsubscribe',
    'group_resubscribe',
    // Defined by us as a temporary default state
    'pending',
    // Defined by us signifying that the email never got sent
    'muted',
    'delayed',
];

exports.sendGridEventTypesDict = listToDict(sendGridEventTypes);

exports.sendGridEventTypesOrderDict = {
    // Delivery Events
    // Defined by us as a temporary default state
    pending: 0,
    // Internal to SendGrid
    processed: 1,
    // SendGrid might try again
    deferred: 2,
    // Delivery status confirmed
    dropped: 3,
    bounce: 3,
    delivered: 3,
    // User did something we don't care about
    unsubscribe: 4,
    group_unsubscribe: 4,
    group_resubscribe: 4,
    // User did something we care about
    open: 5,
    click: 6,
    spamreport: 7,
};

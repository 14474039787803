import { requisitionSearchModesNames } from '@og-pro/shared-config/requisitions';

import { resourceManager } from '../helpers';

const { BASIC } = requisitionSearchModesNames;

export const basicRequisitionsSearch = (searchData) => {
    return resourceManager({
        method: 'post',
        url: '/requisitions/requisitions/search',
        requestOptions: {
            data: {
                ...searchData,
                mode: BASIC,
            },
        },
    });
};

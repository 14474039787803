import { Link, Typography } from '@og-pro/ui';
import { upperFirst } from 'lodash';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';

import { labelStyle } from './styles';
import { contractStatusClass } from '../../../../../../../../components/helpers/statusHelper';

export const ContractDisplay = ({
    contract: { contractId, endDate, government_id: governmentId, id, startDate, status, title },
}) => {
    return (
        <>
            <Typography {...labelStyle}>Contract</Typography>
            <div>
                <Link
                    fontWeight="medium"
                    href={`/governments/${governmentId}/contracts/${id}`}
                    underline="hover"
                    variant="bodyDefault"
                >
                    {contractId ? `${contractId} - ` : ''}
                    {title || 'Untitled Contract'}
                </Link>
            </div>
            <Typography variant="bodySmall">
                {endDate ? 'Ends' : 'Starts'}: {moment(endDate || startDate).format('MM/DD/YYYY')}
            </Typography>
            <Typography
                className={`text-${contractStatusClass(status)}`}
                fontWeight="semiBold"
                variant="bodySmall"
            >
                {upperFirst(status)}
            </Typography>
        </>
    );
};

ContractDisplay.propTypes = {
    contract: PropTypes.shape({
        contractId: PropTypes.string,
        endDate: PropTypes.string,
        government_id: PropTypes.number.isRequired,
        id: PropTypes.number.isRequired,
        startDate: PropTypes.string,
        status: PropTypes.string.isRequired,
        title: PropTypes.string,
    }).isRequired,
};

import { Box, Typography } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { ARCHIVED, PUBLISHED } from '@og-pro/shared-config/reviewGroups';

import { RequisitionGroupReviewSequence } from './RequisitionGroupReviewSequence';
import {
    TemplateListTimestampColumns,
    TemplateStatusLabel,
    UserProfilePicture,
} from '../../../../components';

export const RequisitionGroupsListItem = ({
    requisitionGroup: {
        created_at: createdAt,
        icon,
        lastUpdatedAt,
        name,
        requestTypeGroupSequences,
        status,
        user,
    },
}) => {
    const { reviewSequence } = requestTypeGroupSequences?.[0] || {};
    return (
        <div className="row">
            <div className="col-md-9">
                <div className="row">
                    <div className="col-md-5">
                        <Typography component="div">
                            {icon && (
                                <Box
                                    className={`fa fa-fw fa-${icon}`}
                                    component="i"
                                    marginRight={0.5}
                                />
                            )}
                            {name || 'Untitled'}
                        </Typography>
                        <TemplateStatusLabel
                            isArchived={status === ARCHIVED}
                            isPublished={status === PUBLISHED}
                            useOpenGovStyle
                        />
                    </div>
                    <div className="col-md-3 hidden-xs hidden-sm">
                        {user && (
                            <>
                                <UserProfilePicture horizontal user={user} />
                                &nbsp;&nbsp;
                                <Typography component="span">{user.displayName}</Typography>
                            </>
                        )}
                    </div>
                    <div className="col-md-4 hidden-xs hidden-sm">
                        {reviewSequence && (
                            <RequisitionGroupReviewSequence reviewSequence={reviewSequence} />
                        )}
                    </div>
                </div>
            </div>
            <div className="col-md-3 hidden-xs hidden-sm">
                <TemplateListTimestampColumns
                    createdAt={createdAt}
                    lastUpdatedAt={lastUpdatedAt}
                    useOpenGovStyle
                />
            </div>
        </div>
    );
};

RequisitionGroupsListItem.propTypes = {
    requisitionGroup: PropTypes.shape({
        created_at: PropTypes.string.isRequired,
        icon: PropTypes.string,
        name: PropTypes.string,
        lastUpdatedAt: PropTypes.string.isRequired,
        requestTypeGroupSequences: PropTypes.arrayOf(
            PropTypes.shape({
                reviewSequence: PropTypes.object,
            })
        ).isRequired,
        status: PropTypes.number.isRequired,
        user: PropTypes.shape({
            displayName: PropTypes.string.isRequired,
        }),
    }).isRequired,
};

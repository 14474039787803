import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Panel } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, Outlet } from 'react-router-dom';

import { GovSubscriptionConfirmationModal } from './GovSubscriptionConfirmationModal';
import { getGovernmentSubscription } from './selectors';
import { getGovernmentJS } from '../selectors';
import { getUserJS } from '../../selectors';
import { updateGovSubscription } from '../../../actions/account/subscriptions';
import { subscribeToGov } from '../../../actions/publicProject';
import { Button } from '../../../components';

const DEFAULT_HELP_CENTER_URL =
    'https://opengov.my.site.com/support/s/topic/0TO8X000000Mhi0WAC/vendor-guides';

const mapStateToProps = (state) => {
    return {
        government: getGovernmentJS(state),
        governmentSubscription: getGovernmentSubscription(state),
        isSubscribing: state.publicProject.get('govSubscribing'),
        subscribeError: state.publicProject.get('govSubscribeError'),
        user: getUserJS(state),
    };
};

const mapDispatchToProps = {
    subscribeToGov,
    updateGovSubscription,
};

// @connect
class ConnectedGovPortalNav extends Component {
    static propTypes = {
        government: PropTypes.shape({
            helpCenterUrl: PropTypes.string,
            id: PropTypes.number.isRequired,
            organization: PropTypes.shape({
                city: PropTypes.string.isRequired,
                logo: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                state: PropTypes.string.isRequired,
                timezoneReadable: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        governmentSubscription: PropTypes.object,
        incompleteRequiredVendorDatumKeys: PropTypes.array,
        isSubscribing: PropTypes.bool,
        subscribeError: PropTypes.string,
        subscribeToGov: PropTypes.func.isRequired,
        updateGovSubscription: PropTypes.func.isRequired,
        user: PropTypes.shape({
            isVendor: PropTypes.bool,
            vendor_id: PropTypes.number,
        }),
    };

    static defaultProps = {
        isSubscribing: false,
        subscribeError: undefined,
        user: undefined,
    };

    constructor(props) {
        super(props);

        this.state = {
            showSubscriptionConfirmationModal: false,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    get subscribeButtonProps() {
        const { governmentSubscription } = this.props;

        if (governmentSubscription) {
            return {
                icon: 'check',
                text: 'Subscribed',
                textStyle: 'text-success',
            };
        }

        return {
            bsStyle: 'success',
            icon: 'plus',
            text: 'Subscribe',
            tooltip: 'Click here to get notifications for upcoming projects',
        };
    }

    hideSubscriptionModal = () => {
        this.setState({ showSubscriptionConfirmationModal: false });
    };

    subscribeToGov = () => {
        const { government, user } = this.props;

        return this.props.subscribeToGov(government.id, user, {
            onSuccess: () => this.setState({ showSubscriptionConfirmationModal: true }),
        });
    };

    renderBackButton() {
        if (__PROD__ || __SANDBOX__ || __STAGING__ || __PERF__ || __INT__ || __RDEV__) {
            return null;
        }

        const { user } = this.props;

        if (!user || user.isVendor) {
            return null;
        }

        return (
            <Link to="/portal">
                <i className="fa fa-angle-left" /> Back to Organizations
            </Link>
        );
    }

    renderSubscribeButton() {
        const { governmentSubscription, isSubscribing, user } = this.props;

        // Button styling
        const { icon, text, bsStyle, textStyle, tooltip } = this.subscribeButtonProps;

        // Button routing
        const buttonClick =
            user && user.isVendor && !!governmentSubscription
                ? { to: `/vendors/${user.vendor_id}/my-subscriptions` }
                : { onClick: this.subscribeToGov };

        return (
            <Button
                block
                bsSize="lg"
                bsStyle={bsStyle}
                disabled={isSubscribing}
                tooltip={tooltip}
                {...buttonClick}
            >
                <span className={textStyle}>
                    <i className={`fa fa-${icon}`} /> {text}
                </span>
            </Button>
        );
    }

    renderGovernmentLogo() {
        const {
            government: {
                organization: { city, logo, name, state, timezoneReadable },
            },
            subscribeError,
        } = this.props;

        return (
            <Panel className="text-center">
                <Panel.Body>
                    <div className="row">
                        <div className="col-xs-offset-3 col-xs-6 col-sm-offset-4 col-sm-4 col-md-offset-1 col-md-10">
                            <img
                                alt={`${name} logo`}
                                className={`img-responsive img-rounded ${this.styles.logo}`}
                                src={logo}
                            />
                        </div>
                    </div>
                    <h2 className={this.styles.name}>{name}</h2>
                    <div className={this.styles.location}>
                        {city}, {state}
                    </div>
                    <p className="text-muted">
                        <em>All dates and times in {timezoneReadable}</em>
                    </p>
                    {this.renderSubscribeButton()}
                    {subscribeError && <div className="error-block">{subscribeError}</div>}
                </Panel.Body>
            </Panel>
        );
    }

    render() {
        const {
            government,
            government: { helpCenterUrl },
            governmentSubscription,
        } = this.props;

        const { showSubscriptionConfirmationModal } = this.state;

        return (
            <>
                {this.renderBackButton()}
                <div className="row">
                    <div className="col-md-3">
                        <div className={this.styles.controlCol}>
                            {this.renderGovernmentLogo()}
                            <div className={this.styles.helpCenterContainer}>
                                <div>Questions?</div>
                                <Button
                                    bsStyle="link"
                                    href={helpCenterUrl || DEFAULT_HELP_CENTER_URL}
                                    target="_blank"
                                >
                                    <i className="fa fa-question-circle" /> Visit Help Center
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className={`col-md-9 ${this.styles.container}`}>
                        <Outlet />
                    </div>
                </div>
                {showSubscriptionConfirmationModal && governmentSubscription && (
                    <GovSubscriptionConfirmationModal
                        government={government}
                        governmentSubscription={governmentSubscription}
                        hideModal={this.hideSubscriptionModal}
                        updateGovSubscription={this.props.updateGovSubscription}
                    />
                )}
            </>
        );
    }
}

export const GovPortalNav = connect(mapStateToProps, mapDispatchToProps)(ConnectedGovPortalNav);

import { useCustomFormService } from './useCustomFormService';
import { getCustomField } from './queries';

/**
 * Load custom field if provided, otherwise does not load anything
 * @param {string | number | undefined} customFieldId if string is provided, automatically parsed to number
 * @returns {object} react-query usequery return value
 */
export const useLoadCustomField = (customFieldId) => {
    return useCustomFormService({
        queryKey: 'getCustomField',
        query: getCustomField,
        enabled: customFieldId !== undefined,
        params: {
            customFieldId: parseInt(customFieldId, 10),
        },
    });
};

export const validate = (values) => {
    const errors = {};
    const TITLE_LIMIT = 128;

    if (!values.title) {
        errors.title = 'Please enter a title for the suggestion';
    } else if (values.title.length > TITLE_LIMIT) {
        errors.title = `Title must be less than ${TITLE_LIMIT} characters`;
    }

    if (!values.description) {
        errors.description = 'Please enter suggested content';
    }

    return errors;
};

import React, { PureComponent } from 'react';

export class NoSharedProposals extends PureComponent {
    render() {
        const styles = require('./NoProposals.scss');
        return (
            <div className={`row ${styles.noProposalsContainer}`}>
                <div className="col-sm-8 col-sm-offset-2">
                    <div className={`text-muted ${styles.noProposalsText}`}>
                        <div className={styles.noProposalsHeader}>
                            You have no shared responses yet.
                        </div>
                        <div>
                            When a user in your organization shares a response with you, it will be
                            displayed here.
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Button, LoadingButton, PurchaseOrderTable } from '../../../../components';

export class PurchaseOrderImportConfirm extends PureComponent {
    static propTypes = {
        backHandler: PropTypes.func.isRequired,
        createPurchaseOrders: PropTypes.array.isRequired,
        duplicatePurchaseOrders: PropTypes.array.isRequired,
        existingPurchaseOrders: PropTypes.array.isRequired,
        importHandler: PropTypes.func.isRequired,
        invalidPaidAmountPurchaseOrders: PropTypes.array,
        unmatchedPurchaseOrders: PropTypes.array.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            importError: null,
            importing: false,
        };
    }

    get styles() {
        return require('./PurchaseOrderImportConfirm.scss');
    }

    importHandler = () => {
        const { importHandler } = this.props;

        this.setState({
            importError: null,
            importing: true,
        });

        return importHandler().catch((error) => {
            this.setState({
                importError: error.message,
                importing: false,
            });
        });
    };

    renderTable = ({ isWarning, purchaseOrders, title }) => {
        if (purchaseOrders.length === 0) {
            return null;
        }

        let titleText = `${title} (${purchaseOrders.length})`;
        if (isWarning) {
            titleText = (
                <span className="text-danger">
                    <i className="fa fa-exclamation-triangle" />
                    {titleText}
                </span>
            );
        }

        return (
            <PurchaseOrderTable
                className={this.styles.table}
                purchaseOrders={purchaseOrders}
                title={titleText}
            />
        );
    };

    render() {
        const {
            backHandler,
            createPurchaseOrders,
            duplicatePurchaseOrders,
            existingPurchaseOrders,
            invalidPaidAmountPurchaseOrders,
            unmatchedPurchaseOrders,
        } = this.props;

        const { importError, importing } = this.state;

        return (
            <>
                <this.renderTable
                    purchaseOrders={createPurchaseOrders}
                    title="New Purchase Orders to Create"
                />
                <this.renderTable
                    purchaseOrders={existingPurchaseOrders}
                    title="Existing Purchase Orders to Update"
                />
                <this.renderTable
                    isWarning
                    purchaseOrders={duplicatePurchaseOrders}
                    title="Entries with Multiple Matching Contracts to Skip"
                />
                <this.renderTable
                    isWarning
                    purchaseOrders={unmatchedPurchaseOrders}
                    title="Entries with No Matching Contracts to Skip"
                />
                <this.renderTable
                    isWarning
                    purchaseOrders={invalidPaidAmountPurchaseOrders}
                    title="Entries where Paid Amount is larger than Order Amount"
                />
                <div className={classnames('row', this.styles.buttonContainer)}>
                    <div className="col-xs-6">
                        <Button disabled={importing} onClick={backHandler}>
                            <i className="fa fa-arrow-left" /> Back
                        </Button>
                    </div>
                    <div className="col-xs-6 text-right">
                        <LoadingButton
                            bsStyle="success"
                            disabled={importing}
                            icon="fa-upload"
                            loading={importing}
                            loadingText="Importing..."
                            onClick={this.importHandler}
                            text="Import Purchase Orders"
                        />
                    </div>
                </div>
                {importError && <div className="error-block text-right">{importError}</div>}
            </>
        );
    }
}

export const checklistSocketActionType = 'checklistSocket/client';

export const projectSocketActionType = 'projectSocket/client';

export const requisitionSocketActionType = 'requisitionSocket/client';

export const reverseAuctionSocketActionType = 'reverseAuctionSocket/client';

export const userSocketActionType = 'userSocket/client';

export const vendorProposalSocketActionType = 'vendorProposalSocket/client';

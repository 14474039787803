import { fromJS } from 'immutable';

import * as authActions from '../actions/auth';
import * as tagActions from '../actions/tags';

const initialState = fromJS({
    tags: [],
    createTagError: null,
    creatingTag: false,
    deleteTagError: null,
    deletingTag: false,
    loadedTags: false,
    loadTagsError: null,
    loadingTags: false,
    updateTagError: null,
    updatingTag: false,
    // We'll only use one of these at a time, but depending on where we are in the app we'll have to
    // switch between using the government code (public app) or ID.
    governmentCodeOfTags: null,
    governmentIdOfTags: null,
});

export default function tagsReducer(state = initialState, action = {}) {
    switch (action.type) {
        case tagActions.CREATE:
            return state.merge(
                fromJS({
                    creatingTag: true,
                    createTagError: null,
                })
            );
        case tagActions.CREATE_FAIL:
            return state.merge(
                fromJS({
                    creatingTag: false,
                    createTagError: action.error ? action.error.message : 'Error creating tag',
                })
            );
        case tagActions.CREATE_SUCCESS:
            return state.merge(
                fromJS({
                    createdTag: true,
                    creatingTag: false,
                    tags: state.get('tags').push(fromJS(action.result)),
                })
            );
        case tagActions.DELETE:
            return state.merge(
                fromJS({
                    deletingTag: true,
                    deleteTagError: null,
                })
            );
        case tagActions.DELETE_FAIL:
            return state.merge(
                fromJS({
                    deletingTag: false,
                    deleteTagError: action.error ? action.error.message : 'Error deleting tag',
                })
            );
        case tagActions.DELETE_SUCCESS: {
            const deletedTag = action.result;

            return state.merge(
                fromJS({
                    deletedTag: true,
                    deletingTag: false,
                    tags: state.get('tags').filter((tag) => tag.get('id') !== deletedTag.id),
                })
            );
        }
        case tagActions.LOAD_TAGS:
            return state.merge(
                fromJS({
                    loadingTags: true,
                    loadTagsError: null,
                })
            );
        case tagActions.LOAD_TAGS_FAIL:
            return state.merge(
                fromJS({
                    loadingTags: false,
                    loadTagsError: action.error ? action.error.message : 'Error loading tags',
                })
            );
        case tagActions.LOAD_TAGS_SUCCESS:
            return state.merge(
                fromJS({
                    governmentCodeOfTags: action.governmentCode,
                    governmentIdOfTags: action.governmentId,
                    loadedTags: true,
                    loadingTags: false,
                    tags: fromJS(action.result),
                })
            );
        case tagActions.SWAP:
            return state.merge(
                fromJS({
                    updatingTag: true,
                    updateTagError: null,
                })
            );
        case tagActions.SWAP_FAIL:
            return state.merge(
                fromJS({
                    updatingTag: false,
                    updateTagError: action.error ? action.error.message : 'Error swapping tags',
                })
            );
        case tagActions.SWAP_SUCCESS: {
            return state.merge(
                fromJS({
                    tags: state
                        .get('tags')
                        .toJS()
                        .filter((tag) => tag.type !== action.result[0].type)
                        .concat(action.result),
                    updatingTag: false,
                })
            );
        }
        case tagActions.UPDATE:
            return state.merge(
                fromJS({
                    updatingTag: true,
                    updateTagError: null,
                })
            );
        case tagActions.UPDATE_FAIL:
            return state.merge(
                fromJS({
                    updatingTag: false,
                    updateTagError: action.error ? action.error.message : 'Error updating tag',
                })
            );
        case tagActions.UPDATE_SUCCESS: {
            const updatedTag = fromJS(action.result);
            return state.merge(
                fromJS({
                    tags: state.get('tags').map((tag) => {
                        if (tag.get('id') === updatedTag.get('id')) {
                            return updatedTag;
                        }

                        return tag;
                    }),
                    updatingTag: false,
                })
            );
        }
        case authActions.LOGOUT_SUCCESS:
            return initialState;
        default:
            return state;
    }
}

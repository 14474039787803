export const DOCUMENT_VERSIONS = '/versions/list';
export const SAVE_HISTORY = '/save-history';

export const navItems = [
    {
        name: 'Document Versions',
        path: DOCUMENT_VERSIONS,
    },
    {
        name: 'Save History',
        path: SAVE_HISTORY,
    },
];

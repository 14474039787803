import React from 'react';
import PropTypes from 'prop-types';

const BACKDROP_ID = 'drawer-backdrop';

export const SDv2Drawer = ({ children, onBackdropClick, open }) => {
    const styles = require('./index.scss');

    if (!open) {
        return null;
    }

    const handleBackdropClick = (e) => {
        e.stopPropagation();

        if (e.target.id === BACKDROP_ID && onBackdropClick) {
            onBackdropClick(e);
        }
    };

    return (
        <div className={styles.backdrop} id={BACKDROP_ID} onClick={handleBackdropClick}>
            <div className={styles.container} onClick={(e) => e.stopPropagation()}>
                {children}
            </div>
        </div>
    );
};

SDv2Drawer.propTypes = {
    children: PropTypes.node,
    onBackdropClick: PropTypes.func,
    open: PropTypes.bool.isRequired,
};

import { createSelector } from 'reselect';

import { actionTypesToDisplayInSteps } from './helpers';
import { getAllRequisitionLogs } from '../../selectors';

export const getRequisitionLogsByStep = createSelector(
    [getAllRequisitionLogs],
    (requisitionLogs) => {
        return requisitionLogs
            .filter(({ actionType }) => actionTypesToDisplayInSteps.includes(actionType))
            .reduce((map, requisitionLog) => {
                const reviewSequenceStepId = requisitionLog.review_sequence_step_id;
                if (!reviewSequenceStepId) {
                    return map;
                }
                if (!map[reviewSequenceStepId]) {
                    map[reviewSequenceStepId] = [];
                }
                map[reviewSequenceStepId].push(requisitionLog);
                return map;
            }, {});
    }
);

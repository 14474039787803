import { statusTypes } from '@og-pro/shared-config/requestTypes';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import {
    Box,
    Button,
    Dialog,
    DropdownMenu,
    ListItem,
    ListItemButton,
    ListItemText,
    Typography,
} from '@og-pro/ui';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { submit } from 'redux-form';

import { RequestTypeEditNameForm } from './RequestTypeEditNameForm';
import { qaTagName } from '../../constants';
import { useRequestTypeActions } from '../../RequestTypeActionsContext';
import { form } from '../../RequestTypeForm/constants';
import { getRequestType } from '../../../../../../selectors/govApp';

export const ButtonBar = () => {
    const [showEditNameDialog, setShowEditNameDialog] = React.useState(false);

    const {
        archiveRequestType,
        copyRequestType,
        deleteRequestType,
        disabled,
        error,
        saveRequestType,
        saveRequestTypeName,
        unpublishRequestType,
    } = useRequestTypeActions();

    const requestType = useSelector(getRequestType);
    const dispatch = useDispatch();

    const submitHandler = () => dispatch(submit(form));
    const hideEditNameDialog = useCallback(
        () => setShowEditNameDialog(false),
        [setShowEditNameDialog]
    );

    const isDraft = requestType.status === statusTypes.DRAFT;
    const isPublished = requestType.status === statusTypes.PUBLISHED;

    const menuItems = useMemo(() => {
        const restoreText = isPublished ? 'Unpublish' : 'Restore';
        return {
            COPY: {
                key: 'copy',
                onClick: copyRequestType,
                text: 'Copy Request Type',
            },
            ARCHIVE: {
                key: 'archive',
                onClick: archiveRequestType,
                text: 'Archive Request Type',
            },
            DELETE: {
                key: 'delete',
                onClick: deleteRequestType,
                text: 'Delete Request Type',
            },
            RENAME: {
                key: 'editName',
                onClick: () => setShowEditNameDialog(true),
                text: 'Edit Name',
            },
            RESTORE: {
                key: restoreText.toLowerCase(),
                onClick: unpublishRequestType,
                text: `${restoreText} Request Type`,
            },
        };
    }, [isPublished, archiveRequestType, copyRequestType, deleteRequestType, unpublishRequestType]);

    const menuOptions = useMemo(() => {
        if (isDraft) {
            return [menuItems.RENAME, menuItems.COPY, menuItems.ARCHIVE, menuItems.DELETE];
        }
        if (isPublished) {
            return [menuItems.RENAME, menuItems.COPY, menuItems.RESTORE, menuItems.ARCHIVE];
        }
        return [menuItems.RESTORE, menuItems.COPY];
    }, [isDraft, isPublished, menuItems]);

    return (
        <Box>
            <Box sx={{ alignItems: 'flex-start', display: 'flex', gap: 1 }}>
                <DropdownMenu
                    color="secondary"
                    disabled={disabled}
                    label="Actions"
                    variant="outlined"
                >
                    {menuOptions.map(({ key, onClick, text }) => (
                        <ListItem disablePadding key={key}>
                            <ListItemButton disabled={disabled} onClick={onClick}>
                                <ListItemText id={`requestType-${key}`} primary={text} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </DropdownMenu>
                {isDraft && (
                    <Button
                        color="primary"
                        disabled={disabled}
                        onClick={submitHandler}
                        qaTag={`${qaTagName}-publish`}
                        variant="outlined"
                    >
                        Publish
                    </Button>
                )}
                {(isDraft || isPublished) && (
                    <Button
                        color="primary"
                        disabled={disabled}
                        onClick={isPublished ? submitHandler : saveRequestType}
                        qaTag={`${qaTagName}-save`}
                        variant="contained"
                    >
                        Save{isDraft && ' Draft'}
                    </Button>
                )}
            </Box>
            {error && (
                <Typography
                    sx={{
                        color: capitalDesignTokens.semanticColors.foreground.errorSmall,
                        marginTop: 0.5,
                        textAlign: 'right',
                    }}
                >
                    {error}
                </Typography>
            )}
            <Dialog
                closeButton
                dialogTitle="Edit Request Name"
                fullWidth
                onClose={hideEditNameDialog}
                open={showEditNameDialog}
            >
                <RequestTypeEditNameForm
                    initialValues={{ name: requestType.name }}
                    onCancel={hideEditNameDialog}
                    onSubmit={({ name }) => {
                        saveRequestTypeName(name);
                        hideEditNameDialog();
                    }}
                />
            </Dialog>
        </Box>
    );
};

import { createSelector } from 'reselect';

const getLoadingContracts = (state) => {
    return state.contracts.toJS().loadingContractsForCSVExport;
};

export const getLoadingContractsForCSVExportJS = createSelector(
    [getLoadingContracts],
    (loadingContractsForCSVExport) => loadingContractsForCSVExport
);

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Panel } from 'react-bootstrap';

export class DashboardControlNav extends PureComponent {
    static propTypes = {
        children: PropTypes.node,
        logoUrl: PropTypes.string.isRequired,
    };

    get styles() {
        return require('./DashboardControlNav.scss');
    }

    render() {
        const { children, logoUrl } = this.props;

        return (
            <div className={this.styles.container}>
                <Panel>
                    <Panel.Body>
                        <div className={`row ${this.styles.logoContainer}`}>
                            <div className="col-xs-offset-3 col-xs-6 col-sm-offset-1 col-sm-10">
                                <img
                                    alt="Organization logo"
                                    className={`img-responsive img-rounded ${this.styles.logo}`}
                                    src={logoUrl}
                                />
                            </div>
                        </div>
                        {children}
                    </Panel.Body>
                </Panel>
            </div>
        );
    }
}

import PropTypes from 'prop-types';
import React from 'react';

import { TEMPLATE_TYPE } from './constants';
import { Form } from './Form';

export const TemplateListSelectForm = ({
    location,
    projectTypeSelectOptions,
    selectedProjectType,
}) => {
    return (
        <Form
            initialValues={{
                [TEMPLATE_TYPE]: selectedProjectType,
            }}
            location={location}
            options={projectTypeSelectOptions}
        />
    );
};

TemplateListSelectForm.propTypes = {
    location: PropTypes.object.isRequired,
    projectTypeSelectOptions: PropTypes.array.isRequired,
    selectedProjectType: PropTypes.string.isRequired,
};

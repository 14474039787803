import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import {
    MAX_NUM_APPROVAL_WORKFLOW_STEPS,
    approvalWorkflowTypesDict,
} from '@og-pro/shared-config/approval';

import { fieldNames } from '../../constants';
import { Button, DragIcon, SearchSelect, SearchSelectUserOption } from '../../../../../components';
import { getDndStyle } from '../../../../../constants/styles';

const { APPROVAL_WORKFLOW_STEPS } = fieldNames;

const { INTAKE } = approvalWorkflowTypesDict;

export const ApprovalStepList = ({
    approvalWorkflowStepValues,
    array,
    change,
    disabled,
    fields,
    form,
    multiUserSelect,
    selectedModuleType,
    usersSelectOptions,
}) => {
    const styles = require('../../index.scss');

    if (!multiUserSelect) {
        return fields.map((field) => (
            <Field
                aria-label={field}
                component={SearchSelect}
                components={{
                    Option: SearchSelectUserOption,
                }}
                disabled={disabled}
                help="Automatically include this approver"
                isClearable={false}
                isMulti={multiUserSelect}
                isMultiSimpleValue={multiUserSelect}
                key={field}
                name={`${field}.userIds`}
                options={usersSelectOptions}
                placeholder="Select approver"
            />
        ));
    }

    const handleDragEnd = (result) => {
        const { forEach, move } = fields;

        const originLocation = result.source.index;
        const newLocation = result.destination ? result.destination.index : undefined;

        if (newLocation !== undefined && newLocation !== originLocation) {
            move(originLocation, newLocation);

            forEach((name, index) => {
                change(`${name}.${'orderById'}`, index + 1);
            });
        }
    };

    const renderField = (field, index, provided) => {
        const handleRemove = () => fields.remove(index);

        const insertNewField = () => {
            const { forEach } = fields;
            const indexToInsertNewField = index + 1;
            forEach((name, stepIndex) => {
                if (stepIndex >= indexToInsertNewField) {
                    change(`${name}.${'orderById'}`, stepIndex + 2);
                }
            });

            array.splice(APPROVAL_WORKFLOW_STEPS, indexToInsertNewField, 0, {
                orderById: indexToInsertNewField + 1,
            });
        };

        return (
            <div className="row" key={index} ref={provided.innerRef} {...provided.draggableProps}>
                <div className="col-xs-10" style={{ marginBottom: '15px' }}>
                    <Field
                        component={SearchSelect}
                        components={{
                            Option: SearchSelectUserOption,
                        }}
                        disabled={disabled}
                        help="Automatically include these approvers"
                        isClearable={false}
                        isMulti={multiUserSelect}
                        isMultiSimpleValue={multiUserSelect}
                        label={selectedModuleType !== INTAKE ? `Review Step ${index + 1}` : ''}
                        name={`${field}.userIds`}
                        options={usersSelectOptions}
                        placeholder="Select approvers"
                    />
                </div>
                <div className="col-xs-2 pull-right">
                    <Button
                        aria-label="Remove Workflow Group Button"
                        bsStyle="link"
                        className={styles.removeBtn}
                        disabled={approvalWorkflowStepValues?.length === 1}
                        onClick={handleRemove}
                        qaTag="approvalPresetWorkflow-removeWorkflowGroup"
                    >
                        <i className="fa fa-times fa-2x" />
                    </Button>
                    <DragIcon
                        className={styles.dragIcon}
                        dragHandleProps={provided.dragHandleProps}
                    />
                    <Button
                        aria-label="Add Workflow Group Button"
                        bsStyle="link"
                        className={styles.addBtn}
                        disabled={
                            approvalWorkflowStepValues?.length === MAX_NUM_APPROVAL_WORKFLOW_STEPS
                        }
                        onClick={insertNewField}
                        qaTag="approvalPresetWorkflow-addWorkflowGroup"
                    >
                        <i className="fa fa-plus fa-2x" />
                    </Button>
                </div>
            </div>
        );
    };

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId={form} isDropDisabled={disabled}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        style={getDndStyle(snapshot)}
                        {...provided.droppableProps}
                    >
                        {fields.map((field, index) => {
                            return (
                                <Draggable draggableId={field} index={index} key={field}>
                                    {(draggableProvided) =>
                                        renderField(field, index, draggableProvided)
                                    }
                                </Draggable>
                            );
                        })}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

ApprovalStepList.propTypes = {
    approvalWorkflowStepValues: PropTypes.array,
    array: PropTypes.object.isRequired,
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    fields: PropTypes.object.isRequired,
    form: PropTypes.string.isRequired,
    multiUserSelect: PropTypes.bool.isRequired,
    selectedModuleType: PropTypes.string.isRequired,
    usersSelectOptions: PropTypes.array.isRequired,
};

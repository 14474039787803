import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ListGroupItem } from 'react-bootstrap';
import { reduxForm, Field } from 'redux-form';

import { CATEGORIES } from './constants';
import { validate } from './validate';
import { CategoryLinkInput } from '../../CategorySelect';
import { OutlineButton } from '../../../components';

const formConfig = {
    validate,
};

// @reduxForm
class ConnectedCustomCategoryRowForm extends Component {
    static propTypes = {
        customCategory: PropTypes.shape({
            category: PropTypes.string.isRequired,
            categories: PropTypes.array.isRequired,
            deleteError: PropTypes.string,
            deleting: PropTypes.bool,
            updateError: PropTypes.string,
            updating: PropTypes.bool,
        }).isRequired,
        deleteCustomCategory: PropTypes.func.isRequired,
        setCustomCategory: PropTypes.func.isRequired,
        showConfirmationSimpleModal: PropTypes.func.isRequired,
    };

    deleteCustomCategory = (customCategory) => {
        return () => {
            this.props.deleteCustomCategory(customCategory);
        };
    };

    handleCustomCategoryChange = (event, categories) => {
        const { customCategory, setCustomCategory } = this.props;

        setCustomCategory(
            customCategory,
            categories.map((category) => category.id)
        );
    };

    handleRemoveCustomCategory = () => {
        const { customCategory, showConfirmationSimpleModal } = this.props;

        showConfirmationSimpleModal(this.deleteCustomCategory(customCategory), {
            btnText: 'Delete Custom Category',
            text: `Are you sure you want to delete the "${customCategory.category}" custom category?`,
        });
    };

    render() {
        const {
            customCategory: { category, deleteError, deleting, updateError, updating },
        } = this.props;

        const styles = require('./CustomCategoryRowForm.scss');

        return (
            <ListGroupItem>
                <div className="row">
                    <div className="col-md-7">{category}</div>
                    <div className="col-md-5 text-right">
                        <div className={styles.buttonGroup}>
                            <Field
                                component={CategoryLinkInput}
                                disabled={deleting || updating}
                                disallowCustomCategories
                                hasFeedback
                                name={CATEGORIES}
                                onChange={this.handleCustomCategoryChange}
                                targetComponent={
                                    <OutlineButton bsSize="sm" bsStyle="primary">
                                        <i className="fa fa-pencil" /> Edit
                                    </OutlineButton>
                                }
                                useSingleCodeSet
                            />
                            &nbsp;
                            <OutlineButton
                                bsSize="sm"
                                bsStyle="danger"
                                disabled={deleting || updating}
                                onClick={this.handleRemoveCustomCategory}
                            >
                                <i className="fa fa-trash" /> Delete
                            </OutlineButton>
                        </div>
                        <br />
                        {deleteError ? <span className="text-danger">{deleteError}</span> : null}
                        {updateError ? <span className="text-danger">{updateError}</span> : null}
                    </div>
                </div>
            </ListGroupItem>
        );
    }
}

export const CustomCategoryRowForm = reduxForm(formConfig)(ConnectedCustomCategoryRowForm);

import classnames from 'classnames';
import { truncate } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Box } from '@og-pro/ui';

import { getFileExtensionIcon } from '../../helpers';
import { Button, DragIcon } from '..';

export class AttachmentRowItem extends PureComponent {
    static propTypes = {
        attachment: PropTypes.shape({
            fileExtension: PropTypes.string,
            name: PropTypes.string.isRequired,
            url: PropTypes.string,
        }).isRequired,
        deleteHandler: PropTypes.func,
        downloadHandler: PropTypes.func,
        dragHandleProps: PropTypes.any,
        isEditable: PropTypes.bool,
        isLast: PropTypes.bool,
        isNormalSizeIcons: PropTypes.bool,
        isOGThemeEnabledForComponents: PropTypes.bool,
        isReadOnly: PropTypes.bool,
        toggleEdit: PropTypes.func,
    };

    get styles() {
        return require('./index.scss');
    }

    downloadHandler = () => {
        const { attachment, downloadHandler } = this.props;

        downloadHandler(attachment);
    };

    render() {
        const {
            attachment: { fileExtension, name, url },
            deleteHandler,
            downloadHandler,
            dragHandleProps,
            isEditable,
            isLast,
            isNormalSizeIcons,
            isOGThemeEnabledForComponents,
            isReadOnly,
            toggleEdit,
        } = this.props;

        const fileIcon = getFileExtensionIcon(fileExtension);

        let downloadHandlerProps;
        const readOnly = isReadOnly || (!url && !downloadHandler);
        const LinkComponent = readOnly ? 'span' : 'a';
        const linkClassName = readOnly ? 'text-primary' : 'pseudoLink';

        if (!readOnly) {
            // `downloadHandler` is used when different behavior other than the href link is desired
            downloadHandlerProps = downloadHandler
                ? { onClick: this.downloadHandler }
                : { href: url };
        }

        if (isOGThemeEnabledForComponents) {
            return (
                <Box
                    className={classnames(this.styles.linkContainer, {
                        [this.styles.linkContainerBottomBordered]: isLast,
                    })}
                    mb={isLast ? 3 : 0}
                    py={1}
                >
                    <Box onClick={(e) => e.stopPropagation()}>
                        <span className={classnames({ [this.styles.link]: !readOnly })}>
                            <i className="fa fa-file-pdf-o" />
                            &nbsp;
                        </span>
                        <LinkComponent
                            className={classnames('text-break-word', {
                                [this.styles.link]: !readOnly,
                            })}
                            {...downloadHandlerProps}
                        >
                            {truncate(name, { length: 100 })}
                        </LinkComponent>
                    </Box>
                </Box>
            );
        }

        return (
            <>
                {isEditable && (
                    <>
                        <span className="attachment-action-buttons">
                            {dragHandleProps && (
                                <DragIcon
                                    className="dragIcon"
                                    dragHandleProps={dragHandleProps}
                                    useSpan
                                />
                            )}
                        </span>
                        <div className="attachment-file-info">
                            <Button
                                aria-label="Edit Button"
                                bsStyle="link"
                                className={this.styles.attachmentRowItemButton}
                                onClick={toggleEdit}
                                qaTag="attachmentRowItem-edit"
                                zeroPadding
                            >
                                <i className="fa fa-pencil fa-lg text-primary" />
                            </Button>
                            <Button
                                aria-label="Remove Button"
                                bsStyle="link"
                                className={this.styles.attachmentRowItemButton}
                                onClick={deleteHandler}
                                qaTag="attachmentRowItem-remove"
                                zeroPadding
                            >
                                <i className="fa fa-times fa-lg text-danger" />
                            </Button>
                        </div>
                    </>
                )}
                <i
                    className={classnames(`fa fa-${fileIcon}`, !isNormalSizeIcons && 'fa-lg')}
                    title={fileExtension}
                />
                &nbsp;&nbsp;
                <LinkComponent
                    className={classnames('text-break-word', linkClassName)}
                    {...downloadHandlerProps}
                >
                    {truncate(name, { length: 100 })}
                </LinkComponent>
            </>
        );
    }
}

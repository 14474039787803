import { get } from 'lodash';

export const getCustomStyles = (styles) => ({
    placeholder: (base) => {
        return {
            ...base,
            ...styles.placeholder,
        };
    },
    container: (base) => {
        return {
            ...base,
            ...styles.container,
        };
    },
    control: (base, state) => {
        return {
            ...base,
            cursor: state.isFocused ? 'text' : 'pointer',
            minHeight: 34,
            ...styles.control,
            ...(state.menuIsOpen || state.isFocused ? styles.controlFocus : {}),
        };
    },
    dropdownIndicator: (base) => {
        return {
            ...base,
            padding: 4,
            ...styles.dropdownIndicator,
        };
    },
    indicatorsContainer: (base) => ({
        ...base,
        ...styles.indicatorsContainer,
    }),
    clearIndicator: (base) => ({
        ...base,
        padding: 4,
    }),
    menu: (base) => ({
        ...base,
        marginTop: 1,
        ...styles.menu,
    }),
    option: (base, state) => {
        let style = { ...base, ...styles.option };

        if (state.isSelected) {
            style = { ...style, ...styles.optionSelected };
        } else if (state.isFocused) {
            style = { ...style, ...styles.optionHover };
        }

        if (state.isDisabled) {
            style = { ...style, color: 'rgb(204, 204, 204)' };
        }

        return style;
    },
    valueContainer: (base) => ({
        ...base,
        padding: '0px 6px',
    }),
    input: (base) => ({
        ...base,
        margin: 0,
        padding: 0,
        ...styles.input,
    }),
    menuPortal: (base) => {
        return {
            ...base,
            zIndex: 1301, // just above material's ui dialog
        };
    },
});

export const getGroupStateHelpText = (
    { styles, help, overrideFeedback, displayValidation, error, warning },
    useOpenGovStyle = false
) => {
    let groupState;
    let helpBlockText = help;
    if (overrideFeedback) {
        groupState = undefined;
    } else if (displayValidation && error) {
        groupState = `has-error ${styles.error}`;

        if (!useOpenGovStyle) {
            helpBlockText = error;
        }
    } else if (displayValidation && warning) {
        groupState = `has-warning ${styles.warning}`;

        if (!useOpenGovStyle) {
            helpBlockText = warning;
        }
    } else if (displayValidation && !useOpenGovStyle) {
        groupState = `has-success ${styles.success}`;
    }

    return { groupState, helpBlockText };
};

export const getValue = ({
    inputValue,
    isMulti,
    options,
    isMultiSimpleValue,
    useNullWhenUndefined,
}) => {
    let value = inputValue;
    const hasNestedOptionGroups = get(options, [0, 'options']);

    if (!isMulti) {
        if (hasNestedOptionGroups) {
            // Options with nested grouping (rarely used)
            const optionGroup = options.find((group) => {
                return group.options.find((opt) => opt.value === inputValue);
            });
            value = (get(optionGroup, 'options') || []).find((opt) => opt.value === inputValue);
        } else {
            // Standard options (commonly used)
            value = options.find((opt) => opt.value === inputValue);
        }
    }

    // `isMultiSimpleValue` uses array with simple values instead of standard array of options
    if (isMultiSimpleValue && Array.isArray(value)) {
        let formattedOptions = options;
        if (hasNestedOptionGroups) {
            // Options with nested grouping need to be flattened first (rarely used)
            formattedOptions = options.flatMap((opt) => opt.options);
        }
        const valueSet = new Set(value);
        value = formattedOptions.filter((opt) => valueSet.has(opt.value));
    }

    // Sometimes we want to set empty values to null (properly clears input on external change)
    if (useNullWhenUndefined && value === undefined) {
        value = null;
    }

    return value;
};

export const onChangeGetData = ({ option, isMulti, isMultiSimpleValue, rawValue }) => {
    let data;
    if (option === null) {
        data = isMulti ? [] : null;
    } else if (isMultiSimpleValue) {
        // `isMultiSimpleValue` uses array with simple values instead of standard array of options
        data = option.map((opt) => opt.value);
    } else if (isMulti || rawValue) {
        data = option;
    } else {
        data = option.value;
    }

    return data;
};

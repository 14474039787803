import { upperCase } from 'lodash';
import moment from 'moment-timezone/builds/moment-timezone-with-data-1970-2030';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { Button, StatusLabel } from '..';
import { timeClass, isUpcomingDate } from '../helpers/listUtils';
import {
    contractStatusClass,
    contractStatusIcon,
    statusClass,
    statusIcon,
} from '../helpers/statusHelper';
import { getContractDocBuilderRoute } from '../../helpers';

class ConnectedContractListItem extends PureComponent {
    static propTypes = {
        contract: PropTypes.shape({
            contactFullName: PropTypes.string.isRequired,
            contractDocBuilderDocuments: PropTypes.array,
            contractParty: PropTypes.shape({
                companyName: PropTypes.string,
                vendor: PropTypes.shape({
                    organization: PropTypes.shape({
                        name: PropTypes.string.isRequired,
                    }).isRequired,
                }),
            }).isRequired,
            created_at: PropTypes.string,
            docBuilder: PropTypes.shape({
                status: PropTypes.string.isRequired,
                title: PropTypes.string,
            }),
            endDate: PropTypes.string,
            government: PropTypes.shape({
                organization: PropTypes.shape({
                    timezone: PropTypes.string.isRequired,
                }).isRequired,
            }).isRequired,
            startDate: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
        }).isRequired,
        router: PropTypes.object.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    get contractDates() {
        const {
            contract: { endDate, startDate },
        } = this.props;

        return [
            { date: startDate, name: 'Start Date:' },
            { date: endDate, name: 'End Date:' },
        ];
    }

    routeToContractDocBuilder = (e, doc) => {
        e.preventDefault();
        this.props.router.push(getContractDocBuilderRoute(doc));
    };

    renderDateIcon(date) {
        const { dateIcon } = this.styles;

        if (!isUpcomingDate(date)) {
            return null;
        }

        return <i className={`fa fa-exclamation-triangle ${dateIcon}`} />;
    }

    render() {
        const {
            contract: {
                contactFullName,
                contractDocBuilderDocuments,
                contractParty: { companyName, vendor },
                created_at: createdAt,
                status,
                title: contractTitle,
                government: {
                    organization: { timezone },
                },
            },
        } = this.props;

        const {
            companyName: companyNameClass,
            contact,
            contractDate,
            contractDateName,
            detail,
            linkContainer,
            statusText,
            title: titleClass,
        } = this.styles;

        return (
            <div className={`row ${linkContainer}`}>
                <div className="text-center col-xs-12 col-sm-3 col-md-2">
                    <span className={`fa-stack fa-2x ${this.styles[contractStatusClass(status)]}`}>
                        <i className="fa fa-stack-2x fa-square" />
                        <i
                            className={`fa fa-stack-1x fa-inverse fa-${contractStatusIcon(status)}`}
                        />
                    </span>
                    <div className={`text-muted ${statusText}`}>{upperCase(status)}</div>
                </div>
                <div className={`col-xs-12 col-sm-9 col-md-10 ${detail}`}>
                    <h4 className={titleClass}>{contractTitle}</h4>
                    <h4 className={companyNameClass}>
                        {vendor ? vendor.organization.name : companyName}
                    </h4>
                    <div className={contact}>
                        <i className="fa fa-user text-info" />
                        &nbsp;
                        <strong className="text-info">{contactFullName}</strong>
                        &nbsp;
                        {createdAt && <em>Created {moment.tz(createdAt, timezone).format('l')}</em>}
                    </div>
                    <div>
                        {this.contractDates.map(({ name, date }, index) => (
                            <div className={timeClass(date)} key={index}>
                                <div className={contractDateName}>{name}</div>
                                <div className={contractDate}>
                                    {this.renderDateIcon(date)}
                                    {date ? moment.tz(date, timezone).format('l') : 'None'}
                                </div>
                            </div>
                        ))}
                    </div>
                    {contractDocBuilderDocuments && contractDocBuilderDocuments.length > 0 && (
                        <div className={this.styles.contractDocument}>
                            <strong>Assembled Contract Documents:</strong>
                            {contractDocBuilderDocuments.map((doc) => (
                                <div key={doc.id}>
                                    <StatusLabel bsStyle={statusClass(doc.status)}>
                                        <i className={`fa fa-${statusIcon(doc.status)}`} />
                                        &nbsp;
                                        {upperCase(doc.status)}
                                    </StatusLabel>
                                    &nbsp;
                                    <Button
                                        bsStyle="link"
                                        className={this.styles.contractDocumentButton}
                                        onClick={(e) => this.routeToContractDocBuilder(e, doc)}
                                    >
                                        {doc.title || 'Untitled'}
                                    </Button>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export const ContractListItem = withRouter(ConnectedContractListItem);

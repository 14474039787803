import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '../../../../components';

export const FlagsTableRow = ({
    flag,
    flag: { description, id, isActive, name },
    onDelete,
    onEdit,
}) => {
    const styles = require('../shared.scss');

    return (
        <tr key={id}>
            <td className={styles.settingsTableTruncatedCol}>{name}</td>
            <td className={styles.settingsTableTruncatedCol}>{description}</td>
            <td className={styles.settingsTableTruncatedCol}>{isActive ? 'Active' : 'Disabled'}</td>
            <td className={styles.settingsTableCol}>
                <Button
                    bsSize="sm"
                    bsStyle="link"
                    onClick={() => onEdit(flag)}
                    qaTag="flagAdmin-edit"
                    zeroPadding
                >
                    <i className="fa fa-pencil" /> Edit
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                    bsSize="sm"
                    bsStyle="link"
                    onClick={() => onDelete(id)}
                    qaTag="flagAdmin-delete"
                    zeroPadding
                >
                    <i className="text-danger fa fa-trash fa-lg" />
                </Button>
            </td>
        </tr>
    );
};

FlagsTableRow.propTypes = {
    flag: PropTypes.shape({
        description: PropTypes.string,
        id: PropTypes.number.isRequired,
        isActive: PropTypes.bool.isRequired,
        name: PropTypes.string.isRequired,
    }).isRequired,
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
};

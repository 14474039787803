import { listToDict } from '@og-pro/shared-config/helpers';

export const ALL = 'all';
export const DRAFT = 'draft';
export const LIVE = 'live';
export const ARCHIVED = 'archived';

const cols = ['name', 'status', 'owner', 'reviewGroupCount', 'lastUpdatedAt'];

export const colsDict = listToDict(cols);

const { NAME, STATUS, OWNER, REVIEW_GROUP_COUNT, LAST_UPDATED_AT } = colsDict;

export const defaultSortConfig = {
    sortBy: NAME,
    sortDirection: 'asc',
};

export const columns = {
    [NAME]: {
        label: 'Sequence Name',
        sortKey: NAME,
        headProps: { align: 'left' },
        bodyProps: { align: 'left' },
    },
    [STATUS]: {
        label: 'Status',
        sortKey: STATUS,
        headProps: { align: 'center' },
        bodyProps: { align: 'center', width: 90 },
    },
    [OWNER]: {
        label: 'Owner',
        sortKey: 'user.displayName',
        headProps: { align: 'left' },
        bodyProps: { align: 'left' },
    },
    [REVIEW_GROUP_COUNT]: {
        label: '# of Groups Using',
        sortKey: 'requestTypeGroupSequences.length',
        headProps: { align: 'center' },
        bodyProps: { align: 'center', width: 180 },
        initialSortDirection: 'desc',
    },
    [LAST_UPDATED_AT]: {
        label: 'Last Updated',
        sortKey: LAST_UPDATED_AT,
        headProps: { align: 'right' },
        bodyProps: { align: 'right', width: 180 },
    },
};

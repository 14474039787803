export const form = 'questionStatusFilterForm';

export const FILTER = 'filter';
export const ACTIVE = 'active';
export const EXCLUDED = 'excluded';
export const UNANSWERED = 'unanswered';
export const UNAPPROVED = 'unapproved';
export const UNRELEASED = 'unreleased';

export const options = [
    {
        label: 'Active',
        value: ACTIVE,
    },
    {
        label: 'Unapproved',
        value: UNAPPROVED,
    },
    {
        label: 'Unanswered',
        value: UNANSWERED,
    },
    {
        label: 'Unreleased',
        value: UNRELEASED,
    },
    {
        label: 'Excluded',
        value: EXCLUDED,
    },
];

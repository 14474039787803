import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Field } from 'redux-form';
import classnames from 'classnames';

import { Button, HelpIcon, InputText, Label, ProgressBar } from '../../..';

export class PasswordFormSection extends PureComponent {
    static propTypes = {
        autoFocus: PropTypes.bool,
        disabled: PropTypes.bool,
        showErrorFields: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            password: null,
            showPassword: false,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    // Borrowed from: https://stackoverflow.com/a/11268104
    computePasswordStrength = (password) => {
        let score = 0;

        if (!password) {
            return score;
        }

        // Award every unique letter until 5 repetitions
        const letters = {};
        password.split('').forEach((letter) => {
            letters[letter] = (letters[letter] || 0) + 1;
            score += 5 / letters[letter];
        });

        // Bonus points for mixing it up
        const variationCount = [
            /\d/.test(password), // digits
            /[a-z]/.test(password), // lower
            /[A-Z]/.test(password), // upper
            /\W/.test(password), // nonWords
        ].filter((testCase) => !!testCase).length;

        score += (variationCount - 1) * 10;

        return Number.parseInt(score, 10);
    };

    getPasswordStrengthData = (password) => {
        if (!password) {
            return ['Password is blank', 'muted', 0];
        }

        let tooShortText;
        let tooShortBsStyle;
        if (password.length < 12) {
            tooShortText = `Too short (${password.length}/12)`;
            tooShortBsStyle = 'danger';
        }

        const passwordStrength = this.computePasswordStrength(password);

        if (passwordStrength <= 30) {
            return [tooShortText || 'Weak', tooShortBsStyle || 'danger', 25];
        }
        if (passwordStrength <= 60) {
            return [tooShortText || 'Fair', tooShortBsStyle || 'warning', 50];
        }
        if (passwordStrength <= 80) {
            return [tooShortText || 'Good', tooShortBsStyle || 'primary', 75];
        }
        return [tooShortText || 'Strong', tooShortBsStyle || 'success', 100];
    };

    onPasswordChange = (e, password) => {
        this.setState({ password });
    };

    togglePasswordDisplay = () => {
        this.setState((prevState) => {
            return {
                showPassword: !prevState.showPassword,
            };
        });
    };

    renderPasswordStrengthMeter() {
        const { password } = this.state;

        const [text, bsStyle, progress] = this.getPasswordStrengthData(password);

        return (
            <>
                <ProgressBar
                    ariaLabel="Password Strength"
                    bsStyle={password ? bsStyle : undefined}
                    className={this.styles.progressBar}
                    max={100}
                    min={0}
                    now={progress}
                />
                {password && (
                    <span className={classnames('text-muted', this.styles.helpText)}>
                        Password Strength: <strong>{text}</strong>&nbsp;
                    </span>
                )}
            </>
        );
    }

    render() {
        const { autoFocus, disabled, showErrorFields } = this.props;

        const { showPassword } = this.state;

        return (
            <>
                <div className={this.styles.requiredNotice}>
                    All fields marked with * are required
                </div>
                <div>
                    <Label htmlFor="newPassword1" label="New Password *" />
                    <span className={this.styles.showPasswordToggle}>
                        <Button
                            bsSize="sm"
                            bsStyle="link"
                            className="pull-right"
                            onClick={this.togglePasswordDisplay}
                            zeroPadding
                        >
                            {showPassword ? 'Hide' : 'Show'}
                        </Button>
                    </span>
                </div>
                <Field
                    autoFocus={autoFocus}
                    component={InputText}
                    disabled={disabled}
                    formGroupClassName={this.styles.passwordInput}
                    id="newPassword1"
                    name="newPassword1"
                    onChange={this.onPasswordChange}
                    placeholder="Enter password"
                    type={showPassword ? 'text' : 'password'}
                />
                {this.renderPasswordStrengthMeter()}
                <span className={classnames('text-muted', this.styles.helpText)}>
                    12 characters minimum. Why so many characters?
                    <HelpIcon tooltip="Latest research shows that length is the best way to secure your passwords." />
                </span>
                <Field
                    component={InputText}
                    disabled={disabled}
                    formGroupClassName={this.styles.passwordInput2}
                    label="Confirm Password *"
                    name="newPassword2"
                    placeholder="Re-enter password"
                    type={showPassword ? 'text' : 'password'}
                />
                {showErrorFields && (
                    <div className={`help-block ${this.styles.formError}`} role="alert">
                        Your form has errors. Please fix them and submit again.
                    </div>
                )}
            </>
        );
    }
}

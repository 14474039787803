import React, { PureComponent } from 'react';

export class NoProjectsFound extends PureComponent {
    render() {
        const styles = require('./NoProjects.scss');

        return (
            <div className={`row ${styles.noProjectsContainer}`}>
                <div className="col-sm-6 col-sm-offset-3">
                    <div className={`text-muted ${styles.noProjectsText}`}>
                        <div className={styles.noProjectsHeader}>No projects found</div>
                        <div>
                            The specified time range returned no results. As soon as a project meets
                            the filter criteria it will appear here.
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export const StepStatus = ({
    StepStatusComponent,
    hasToggle,
    isOpen,
    stepName,
    stepNameClassName,
    stepStatusIcon,
    stepStatusIconClassName,
    stepStatusIconText,
    stepStatusText,
    stepStatusTextClassName,
}) => {
    const styles = require('./StepStatus.scss');

    return (
        <div className={styles.container}>
            <div className={styles.stepName}>
                <div className={classNames(styles.stepNameIconContainer, stepStatusIconClassName)}>
                    {stepStatusIcon ? (
                        <i className={classNames('fa fa-inverse', stepStatusIcon)} />
                    ) : (
                        <span>{stepStatusIconText}</span>
                    )}
                </div>
                <div className={stepNameClassName}>{stepName}</div>
                {hasToggle && (
                    <i
                        className={classNames(
                            `fa fa-fw`,
                            isOpen ? 'fa-angle-up' : 'fa-angle-down',
                            styles.toggle
                        )}
                    />
                )}
                {StepStatusComponent}
            </div>
            {stepStatusText && (
                <div className={classNames(styles.stepStatus, stepStatusTextClassName)}>
                    {stepStatusText}
                </div>
            )}
        </div>
    );
};

StepStatus.propTypes = {
    StepStatusComponent: PropTypes.node,
    hasToggle: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool.isRequired,
    stepName: PropTypes.string.isRequired,
    stepNameClassName: PropTypes.string,
    stepStatusIcon: PropTypes.string, // Either `stepStatusIcon` or `stepStatusIconText` prop is required to be present
    stepStatusIconClassName: PropTypes.string,
    stepStatusIconText: PropTypes.string, // Either `stepStatusIcon` or `stepStatusIconText` prop is required to be present
    stepStatusText: PropTypes.string,
    stepStatusTextClassName: PropTypes.string,
};

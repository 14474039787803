import React, { memo } from 'react';
import { Panel } from 'react-bootstrap';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { sections } from './constants';
import { Nav, NavItem, PageTitle } from '../../../components';
import { getUserJS } from '../../selectors';

export const VendorProfileEdit = memo(() => {
    const location = useLocation();

    const renderNavItems = () => {
        const { pathname } = location;
        const basePath = pathname.replace(/\/profile\/edit.+/, '/profile/edit');
        const isBasePath = !!pathname.match(/\/profile\/edit\/?$/i);

        return sections.map((item, index) => {
            const { path, title } = item;

            const route = `${basePath}/${path}`;
            const isActive = !!pathname.match(path) || (isBasePath && index === 0);
            return (
                <NavItem
                    key={index}
                    overrideIsActive={isActive}
                    qaTag={`vendorProfile-${title}`}
                    to={route}
                >
                    {title}
                </NavItem>
            );
        });
    };

    const { navItems, backContainer, backText } = require('./index.scss');
    const user = useSelector(getUserJS);
    const vendorId = user.vendor.id;

    return (
        <>
            {vendorId && (
                <Link className={backContainer} to={`/vendors/${vendorId}/profile`}>
                    <ArrowBackIcon />
                    <p className={backText}>Back to Profile</p>
                </Link>
            )}
            <Panel>
                <PageTitle title="Company Information" />
                <Panel.Body>
                    <h1 className="visually-hidden">Edit Profile</h1>
                    <Nav bsStyle="tabs" className={navItems} justified>
                        {renderNavItems()}
                    </Nav>
                    <Outlet />
                </Panel.Body>
            </Panel>
        </>
    );
});

export const vendorContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    marginBottom: 2,
};

export const addVendorContainerStyle = {
    marginBottom: 3,
};

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DropdownMenu, ListItem, ListItemButton, ListItemText } from '@og-pro/ui';

import { toggleCustomEmailModal } from '../../../../actions/app';
import { getShowCustomEmailModal, getShowInviteVendorListToProjectModal } from '../selectors';
import { CustomEmailModal } from '../../../../components';
import { InviteVendorsToProjectModal } from '../InviteVendorsToProjectModal/modal';
import {
    deleteVendorList,
    toggleInviteVendorListToProjectModal,
} from '../../../../actions/vendorList';
import { showConfirmationSimpleModal } from '../../../../actions/confirmation';

export const ActionsMenu = ({
    disableUsersActions,
    filteredUserEmails,
    governmentId,
    listName,
    vendorListId,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const customEmailModalOpen = useSelector(getShowCustomEmailModal);
    const inviteToProjectModalOpen = useSelector(getShowInviteVendorListToProjectModal);

    const deleteListHandler = async () => {
        await dispatch(
            deleteVendorList(governmentId, vendorListId, { skipConfirmationModal: true })
        );
        navigate(`/governments/${governmentId}/vendors/lists`);
    };

    const filteredUsersCount = filteredUserEmails.length;
    const pluralize = filteredUsersCount > 1 ? 's' : '';
    const emailTitle = filteredUsersCount
        ? `Email ${filteredUsersCount} Vendor Subscriber${pluralize}`
        : 'Email Vendor List';
    const inviteTitle = filteredUsersCount
        ? `Invite ${filteredUsersCount} Vendor Subscriber${pluralize} to Project`
        : 'Invite Vendor List to Project';

    const actionsList = [
        {
            disabled: disableUsersActions,
            id: 1,
            title: emailTitle,
            onClick: () => dispatch(toggleCustomEmailModal()),
        },
        {
            disabled: disableUsersActions,
            id: 2,
            title: inviteTitle,
            onClick: () => dispatch(toggleInviteVendorListToProjectModal()),
        },
        {
            id: 3,
            title: 'Delete Vendor List',
            onClick: () =>
                dispatch(
                    showConfirmationSimpleModal(deleteListHandler, {
                        btnText: 'Remove Vendor List',
                        dialogProps: { fullWidth: true, maxWidth: 'sm' },
                        text: `${listName} will be permanently deleted.\n\nThis action cannot be undone.`,
                        title: 'Are you sure you want to delete this vendor list?',
                        useOpenGovStyle: true,
                    })
                ),
        },
    ];

    const data = filteredUsersCount ? { userEmails: filteredUserEmails } : { vendorListId };

    return (
        <>
            <DropdownMenu color="primary" label="Actions" variant="text">
                {actionsList.map(({ disabled, id, onClick, title }) => (
                    <ListItem disablePadding key={id}>
                        <ListItemButton disabled={disabled} onClick={onClick}>
                            <ListItemText id={`vendor-list-actions-${id}`} primary={title} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </DropdownMenu>
            {customEmailModalOpen && (
                <CustomEmailModal
                    data={data}
                    hideModal={() => dispatch(toggleCustomEmailModal())}
                    useOpenGovStyle
                />
            )}
            {inviteToProjectModalOpen && (
                <InviteVendorsToProjectModal initialValues={data} useOpenGovStyle />
            )}
        </>
    );
};

ActionsMenu.propTypes = {
    disableUsersActions: PropTypes.bool.isRequired,
    filteredUserEmails: PropTypes.arrayOf(PropTypes.string).isRequired,
    governmentId: PropTypes.number.isRequired,
    listName: PropTypes.string.isRequired,
    vendorListId: PropTypes.number.isRequired,
};

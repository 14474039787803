import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { PurchaseOrderTable, ZeroState } from '../../../../components';

export class PurchaseOrderList extends Component {
    static propTypes = {
        editHandler: PropTypes.func.isRequired,
        purchaseOrders: PropTypes.arrayOf(
            PropTypes.shape({
                amount: PropTypes.number.isRequired,
                id: PropTypes.number.isRequired,
                tag: PropTypes.shape({
                    name: PropTypes.string.isRequired,
                }).isRequired,
                user: PropTypes.object.isRequired,
            })
        ).isRequired,
        title: PropTypes.string.isRequired,
    };

    render() {
        const { editHandler, purchaseOrders, title } = this.props;

        if (purchaseOrders.length === 0) {
            return <ZeroState title="No orders created yet" />;
        }

        return (
            <PurchaseOrderTable
                editHandler={editHandler}
                purchaseOrders={purchaseOrders}
                showCSVExport
                title={title}
            />
        );
    }
}

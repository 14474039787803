import { contactPhoneFields } from '../../../constants/contactFields';

export const form = 'vendorProposalCreate';

export const fields = [
    ...contactPhoneFields,
    'companyName',
    'contactEmail',
    'contactFirstName',
    'contactLastName',
    'contactWebsite',
    'proposalDocuments',
];

export const fieldsToClean = [...contactPhoneFields, 'contactEmail', 'contactWebsite'];

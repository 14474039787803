import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';

import { DateTimePicker } from '../../../components';
import { updateRecordCloseDateValidate as validate } from './validate';
import { form, fieldNames } from './constants';

const { RECORD_CLOSE_DATE } = fieldNames;

const formConfig = {
    form,
    validate,
};

// @reduxForm
const ConnectedUpdateRecordCloseDateForm = ({ handleSubmit }) => {
    const styles = require('./index.scss');
    return (
        <form className={styles.container} onSubmit={handleSubmit}>
            <div className={styles.endDateWrapper}>
                <div className={styles.endDateText}>What is the Project End Date?</div>
                <Field
                    allowEmpty
                    component={DateTimePicker}
                    label="Project End Date"
                    name={RECORD_CLOSE_DATE}
                    qaTag="updateRecordCloseForm-datePicker"
                />
            </div>
        </form>
    );
};

ConnectedUpdateRecordCloseDateForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
};

export const UpdateRecordCloseDateForm = reduxForm(formConfig)(ConnectedUpdateRecordCloseDateForm);

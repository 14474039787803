import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { getActiveQuestionsJS, getExcludedQuestionsJS } from './selectors';
import connectData from '../ConnectData';
import {
    getProjectJS as getGovernmentProjectJS,
    isSourcingEditor,
    isSourcingViewer,
} from '../GovApp/selectors';
import { getProjectJS as getPublicProjectJS, isProjectOpen } from '../PublicApp/selectors';
import { getQuestionsJS, getUserJS } from '../selectors';
import { loadQuestions, shouldLoadQuestions } from '../../actions/questions';
import { QuestionAnswer as QAComponent, SectionTitle, NoItems, Main } from '../../components';

const fetchData = (isGovernmentView) => (getState, dispatch, location, params) => {
    const projectId = Number.parseInt(params.projectId, 10);
    if (!shouldLoadQuestions(getState(), projectId)) return Promise.resolve();
    return dispatch(loadQuestions(projectId, isGovernmentView));
};

const mapStateToProps = (state) => {
    return {
        user: getUserJS(state),
    };
};

// @connect
class ConnectedQuestionAnswer extends Component {
    static propTypes = {
        canEdit: PropTypes.bool,
        canRelease: PropTypes.bool,
        excludedQuestions: PropTypes.array,
        hideSidebar: PropTypes.bool,
        isCompactView: PropTypes.bool,
        isGovUser: PropTypes.bool,
        isOpen: PropTypes.bool,
        isReverseAuctionView: PropTypes.bool,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
            query: PropTypes.shape({
                number: PropTypes.string,
            }).isRequired,
        }).isRequired,
        project: PropTypes.object.isRequired,
        questions: PropTypes.array.isRequired,
        user: PropTypes.object,
    };

    render() {
        const {
            canEdit,
            canRelease,
            hideSidebar,
            isCompactView,
            isGovUser,
            isOpen,
            isReverseAuctionView,
            location: {
                pathname,
                query: { number },
            },
            project,
            questions,
            excludedQuestions,
            user,
        } = this.props;

        if (!project.qaEnabled && !isReverseAuctionView) {
            return (
                <NoItems
                    header="Q&A Is Not Enabled"
                    subheader="Question & Answer has been disabled for this project"
                />
            );
        }

        let containerClassName = 'col-md-offset-1 col-md-10';
        if (isGovUser && !isReverseAuctionView) {
            containerClassName = 'col-md-offset-1 col-md-11';
        } else if (isCompactView) {
            containerClassName = 'col-xs-12';
        }

        const sharedProps = {
            canEdit,
            canRelease,
            excludedQuestions,
            hideSidebar,
            isGovUser,
            isOpen,
            isReverseAuctionView,
            pathname,
            project,
            questions,
            selectedNumber: number,
            user,
        };

        if (isReverseAuctionView) {
            return (
                <div className={containerClassName} style={{ marginTop: 16 }}>
                    <QAComponent {...sharedProps} />
                </div>
            );
        }

        return (
            <Main className="row">
                <div className={containerClassName}>
                    <SectionTitle
                        info="Questions and clarifications about the project"
                        title="Question & Answer"
                    />
                    <QAComponent {...sharedProps} />
                </div>
            </Main>
        );
    }
}

export const QuestionAnswer = compose(
    withRouter,
    connect(mapStateToProps)
)(ConnectedQuestionAnswer);

// Government view container
const QuestionAnswerGovernment = (props) => {
    const project = useSelector(getGovernmentProjectJS);
    const canEdit = useSelector(isSourcingViewer);
    const canRelease = useSelector(isSourcingEditor);
    const excludedQuestions = useSelector(getExcludedQuestionsJS);
    const questions = useSelector(getActiveQuestionsJS);

    return (
        <QuestionAnswer
            canEdit={canEdit}
            canRelease={canRelease}
            excludedQuestions={excludedQuestions}
            isGovUser
            project={project}
            questions={questions}
            {...props}
        />
    );
};
QuestionAnswer.Government = connectData(fetchData(true))(QuestionAnswerGovernment);
QuestionAnswer.GovernmentReverseAuction = connectData(fetchData(true))((props) => (
    <QuestionAnswerGovernment {...props} isReverseAuctionView />
));

// Shared public question answer component
const PublicQuestionAnswer = (props) => {
    const isOpen = useSelector(isProjectOpen);
    const questions = useSelector(getQuestionsJS);

    return <QuestionAnswer isOpen={isOpen} questions={questions} {...props} />;
};

// Government public view container
const QuestionAnswerGovernmentPublicView = (props) => {
    const project = useSelector(getGovernmentProjectJS);
    return <PublicQuestionAnswer hideSidebar isCompactView project={project} {...props} />;
};
QuestionAnswer.GovernmentPublicView = connectData(fetchData())(QuestionAnswerGovernmentPublicView);

// Public container
const QuestionAnswerPublic = (props) => {
    const project = useSelector(getPublicProjectJS);
    return <PublicQuestionAnswer project={project} {...props} />;
};
QuestionAnswer.Public = connectData(fetchData())(QuestionAnswerPublic);
QuestionAnswer.PublicReverseAuction = connectData(fetchData())((props) => (
    <QuestionAnswerPublic {...props} isReverseAuctionView />
));

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { Box } from '@og-pro/ui';

import { form, fieldNames } from './form/constants';
import { ALL_APPENDICES } from '../../../../constants/attachments';
import { validate } from './form/validate';
import { AttachmentDropzoneFiles } from './AttachmentDropzoneFiles';
import { StyledDropzone } from '../../..';
import { cleanFileName } from '../../../../helpers';

const { FILES } = fieldNames;

const selector = formValueSelector(form);

/**
 * Used for uploading attachments anywhere in the app.
 * Renders a drop area for dragging attachments and displays a form for completing upload to s3
 */
const formConfig = {
    form,
    validate,
};

// @reduxForm
class ConnectedAttachmentDropzone extends Component {
    static propTypes = {
        appendixIdsMap: PropTypes.object.isRequired,
        array: PropTypes.object.isRequired,
        disabled: PropTypes.bool,
        formKey: PropTypes.string, // Used when multiple different attachment dropzones are displayed
        formValues: PropTypes.array,
        handleSubmit: PropTypes.func.isRequired,
        hideAppendixLetter: PropTypes.bool,
        isOGThemeEnabledForComponents: PropTypes.bool,
        label: PropTypes.string,
        uploadFile: PropTypes.func.isRequired,
    };

    get styles() {
        return require('./AttachmentDropzone.scss');
    }

    onDrop = (files) => {
        const { array, appendixIdsMap, formKey, hideAppendixLetter } = this.props;

        const availableAppendices = ALL_APPENDICES.filter((letter) => !appendixIdsMap[letter]);

        (files || []).forEach((file, index) => {
            const { name, extension } = cleanFileName(file && file.name);
            const appendixId = hideAppendixLetter ? undefined : availableAppendices[index];

            array.push(FILES, {
                appendixId,
                file,
                formKey,
                title: name,
                fileExtension: extension,
                progress: 0,
            });
        });
    };

    renderFiles() {
        const {
            appendixIdsMap,
            disabled,
            formKey,
            handleSubmit,
            hideAppendixLetter,
            isOGThemeEnabledForComponents,
            uploadFile,
        } = this.props;

        const availableAppendices = ALL_APPENDICES.map((letter) => {
            return {
                disabled: !!appendixIdsMap[letter],
                label: letter,
                value: letter,
            };
        });

        return (
            <FieldArray
                availableAppendices={availableAppendices}
                component={AttachmentDropzoneFiles}
                disabled={disabled}
                formKey={formKey}
                handleSubmit={handleSubmit}
                hideAppendixLetter={hideAppendixLetter}
                isOGThemeEnabledForComponents={isOGThemeEnabledForComponents}
                name={FILES}
                uploadFile={uploadFile}
            />
        );
    }

    render() {
        const { disabled, formValues, isOGThemeEnabledForComponents, label } = this.props;

        if (isOGThemeEnabledForComponents) {
            return (
                <Box className={this.styles.attachmentsContainer}>
                    <Box className={this.styles.label}>Upload</Box>
                    {(!formValues || !formValues.length) && (
                        <Box className={this.styles.attachmentsDropzoneContainer}>
                            <StyledDropzone
                                disabled={disabled}
                                dropzoneClassName={this.styles.dropzone}
                                dropzoneContainerClassName={this.styles.dropzoneContainer}
                                isDragActiveClassName={this.styles.dropzoneActive}
                                label=""
                                labelId={label}
                                onDrop={this.onDrop}
                            >
                                <Box className={this.styles.dropzoneContent}>
                                    <i className="fa fa-upload" /> Drag a file here to upload or{' '}
                                    <span className={this.styles.link}>
                                        click here to select a file
                                    </span>
                                </Box>
                            </StyledDropzone>
                        </Box>
                    )}

                    {this.renderFiles()}
                </Box>
            );
        }

        return (
            <div>
                <div className={`row ${this.styles.gridContainer}`}>
                    <div className="col-xs-12">
                        <StyledDropzone
                            disabled={disabled}
                            label={label || 'Attachments'}
                            onDrop={this.onDrop}
                        />
                    </div>
                </div>
                {this.renderFiles()}
            </div>
        );
    }
}

export const AttachmentDropzone = reduxForm(formConfig)(
    connect((state) => ({ formValues: selector(state, FILES) }))(ConnectedAttachmentDropzone)
);

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, Typography } from '@og-pro/ui';
import { Warning as WarningIcon } from '@mui/icons-material';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { CustomGridLayout } from './CustomGridLayout';
import { useVendorSearch } from '../hooks';

export const ReadOnlySearchByLocationsRow = ({
    categories,
    notifiedUsers,
    // selectedValue is what we save, that's why it's stored one level above
    selectedValue,
    setError,
    setTotal,
}) => {
    const [results, setResults] = useState({ count: 0, users: 0 });
    const { loading, searchFilterAndSet } = useVendorSearch({
        categories,
        setError,
        setResults,
        setTotal,
        notifiedUsers,
    });

    useEffect(() => {
        searchFilterAndSet(selectedValue);
    }, [selectedValue?.length]);

    useEffect(() => {
        return () => {
            setResults({ count: 0, users: 0 });
        };
    }, []);

    const styles = require('../index.scss');

    return (
        <CustomGridLayout
            columns={[
                <>
                    <Box className={styles.vendorSearchTypeLabel}>Previously Added Locations</Box>
                    {selectedValue.map((location) => {
                        return (
                            <Box className={styles.readOnlyLocationChip} key={location.value}>
                                {location.label}
                            </Box>
                        );
                    })}
                    {results?.users > 0 && (
                        <Box
                            alignItems="center"
                            color={capitalDesignTokens.foundations.colors.terracotta600}
                            display="flex"
                            mt={1}
                        >
                            <WarningIcon fontSize="inherit" />
                            <Typography fontWeight={600} ml={0.5} variant="bodyXSmall">
                                New Vendors Available
                            </Typography>
                        </Box>
                    )}
                </>,
                loading ? (
                    <CircularProgress size="small" />
                ) : (
                    <Box className={styles.tableCount}>{results?.users}</Box>
                ),
                loading ? (
                    <CircularProgress size="small" />
                ) : (
                    <Box className={styles.tableCount}>{results?.count}</Box>
                ),
                <Box textAlign="right">&nbsp;</Box>,
            ]}
        />
    );
};

ReadOnlySearchByLocationsRow.propTypes = {
    categories: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number })),
    notifiedUsers: PropTypes.shape({ ids: PropTypes.object, organizationIds: PropTypes.object }), // javascript Set
    selectedValue: PropTypes.arrayOf(PropTypes.object).isRequired,
    setError: PropTypes.func.isRequired,
    setTotal: PropTypes.func.isRequired,
};

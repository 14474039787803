import PropTypes from 'prop-types';
import React from 'react';

import { SearchSelectMultiLabelOption } from '../../../../../../components';

export const ExceptionSequenceSelectOption = (props) => {
    const {
        data: {
            reviewSequence: { description, name },
        },
    } = props;

    return <SearchSelectMultiLabelOption {...props} label={name} tertiaryLabel={description} />;
};

ExceptionSequenceSelectOption.propTypes = {
    data: PropTypes.shape({
        reviewSequence: PropTypes.shape({
            description: PropTypes.string,
            name: PropTypes.string,
        }).isRequired,
    }).isRequired,
};

import React, { useEffect, useState } from 'react';
import { Panel } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { GovtDomain } from './GovtDomain';
import { GovtInvite } from './GovtInvite';
import { getOrgMatchJS } from './selectors';
import * as govSignUpActions from '../../actions/govSignUp';
import { Button, PageTitle } from '../../components';

export const GovernmentSignUp = () => {
    const [email, setEmail] = useState('');
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get('page');
    const currentPage = page && !Number.isNaN(Number(page)) ? Number(page) : 0;

    const orgMatch = useSelector(getOrgMatchJS);
    const hasOrgMatch = useSelector((state) => state.govSignUp.get('hasOrgMatch'));
    const matchLoading = useSelector((state) => state.govSignUp.get('matchLoading'));
    const matchSubmitted = useSelector((state) => state.govSignUp.get('matchSubmitted'));
    const govtInviteEmail = useSelector((state) => state.govSignUp.get('govtInviteEmail'));
    const govtInviteLoading = useSelector((state) => state.govSignUp.get('govtInviteLoading'));
    const govtInviteSent = useSelector((state) => state.govSignUp.get('govtInviteSent'));

    const updatePage = (pageChange) => {
        searchParams.set('page', currentPage + pageChange);
        setSearchParams(searchParams);
    };

    useEffect(() => {
        if (currentPage === 1 && (!email || !orgMatch)) {
            updatePage(-1);
        }
    }, [currentPage, email, orgMatch]);

    useEffect(() => {
        if (hasOrgMatch && email) {
            updatePage(1);
            dispatch(govSignUpActions.setGovtInviteEmail(email));
        }
    }, [hasOrgMatch, email]);

    const handleBackClick = () => {
        updatePage(-1);
    };

    const handleDomainSubmit = (data) => {
        const mail = data.email;
        const domain = mail.replace(/.*@/, '');

        setEmail(mail);
        return dispatch(govSignUpActions.domainMatch(domain));
    };

    const handleSendGovInviteEmail = () => {
        const orgMatchId = orgMatch.id;

        dispatch(govSignUpActions.govtInvite(govtInviteEmail, orgMatchId));
    };

    const renderContent = () => {
        const GovDomainComponent = (
            <GovtDomain
                isMatch={hasOrgMatch}
                loading={matchLoading}
                matchSubmitted={matchSubmitted}
                onSubmit={handleDomainSubmit}
            />
        );

        switch (currentPage) {
            case 0:
                return GovDomainComponent;
            case 1:
                return (
                    <GovtInvite
                        email={govtInviteEmail}
                        inviteSent={govtInviteSent}
                        loading={govtInviteLoading}
                        org={orgMatch}
                        sendEmail={handleSendGovInviteEmail}
                    />
                );
            default:
                return GovDomainComponent;
        }
    };

    const styles = require('./index.scss');

    return (
        <div className="row">
            <PageTitle title="Government Sign Up" />
            <div className="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 col-xs-10 col-xs-offset-1">
                <div className="row">
                    <div className="col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8 col-lg-offset-3 col-lg-6">
                        <img
                            alt="OpenGov Procurement logo"
                            className={`img-responsive ${styles.logo}`}
                            src="https://assets.procurement.opengov.com/assets/opengov/opengov_pro_logo_blue.svg"
                        />
                    </div>
                </div>
            </div>
            <Panel
                className={`col-xs-12 col-sm-offset-1 col-sm-10 col-md-offset-2 col-md-8 ${styles.panel}`}
            >
                <Panel.Body>
                    <div className="row">
                        <div className="col-md-offset-1 col-md-10 col-lg-offset-2 col-lg-8">
                            <div className="row">{renderContent()}</div>
                            <div className="row">
                                {currentPage === 1 && (
                                    <Button bsStyle="link" onClick={handleBackClick}>
                                        <i className="fa fa-angle-left" /> back
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                </Panel.Body>
            </Panel>
        </div>
    );
};

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';

import { Nav } from '..';

export class PublicNavbar extends PureComponent {
    static propTypes = {
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
        }).isRequired,
    };

    render() {
        const {
            location: { pathname },
        } = this.props;

        // not using bootstrap's NavItem inside Nav because those generate a link
        return (
            <div>
                <Nav pullRight>
                    <li
                        className={classnames({ active: !!pathname.match(/\/signup/) })}
                        role="presentation"
                    >
                        <NavLink data-qa="navbar-navItem-signUp" to="/signup">
                            <i className="fa fa-user-plus" /> Sign Up
                        </NavLink>
                    </li>
                    <li
                        className={classnames({ active: !!pathname.match(/\/login/) })}
                        role="presentation"
                    >
                        <NavLink data-qa="navbar-navItem-login" to="/login">
                            <i className="fa fa-sign-in" /> Login
                        </NavLink>
                    </li>
                </Nav>
            </div>
        );
    }
}

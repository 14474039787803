import Highcharts from 'highcharts';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { chartColors } from './constants';
import { getContractsByProcurementTagsData } from './helpers';
import { NoChartDataPanel } from '../../../components';

const CHART_TITLE = 'Active Contracts By Procurement Classification';

class ConnectedContractsByProcurementTags extends PureComponent {
    static propTypes = {
        contracts: PropTypes.array.isRequired,
        procurementTags: PropTypes.array.isRequired,
        router: PropTypes.object.isRequired,
    };

    get getData() {
        const { contracts, procurementTags, router } = this.props;

        if (procurementTags.length === 0) {
            return null;
        }

        const data = getContractsByProcurementTagsData(contracts, procurementTags, router);

        if (data.length === 0) {
            return null;
        }

        return data;
    }

    componentDidMount() {
        this.renderChart();
    }

    renderChart() {
        const data = this.getData;

        if (data) {
            // eslint-disable-next-line new-cap
            return new Highcharts.chart({
                chart: {
                    borderColor: '#dce0e0',
                    borderWidth: 1,
                    renderTo: 'contracts-by-procurement-tags-chart',
                    style: {
                        fontWeight: 500,
                        fontSize: '18px',
                        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
                    },
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie',
                },
                credits: {
                    enabled: false,
                },
                legend: {
                    labelFormatter() {
                        return `${this.name}: ${this.userOptions.label}`;
                    },
                    reversed: true,
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        borderWidth: 3,
                        cursor: 'pointer',
                        colors: chartColors,
                        size: '75%',
                        dataLabels: {
                            enabled: true,
                            alignTo: 'connectors',
                            connectorShape: 'crookedLine',
                            distance: 20,
                            format: '<b>{point.name}</b>: {point.y}',
                            style: {
                                color:
                                    (Highcharts.theme && Highcharts.theme.contrastTextColor) ||
                                    'black',
                            },
                        },
                    },
                },
                series: [
                    {
                        name: 'Contracts',
                        colorByPoint: true,
                        data,
                    },
                ],
                title: {
                    text: CHART_TITLE,
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.y}</b>',
                },
            });
        }
    }

    render() {
        return (
            <>
                <div id="contracts-by-procurement-tags-chart" />
                {!this.getData && (
                    <NoChartDataPanel
                        icon="pie-chart"
                        subtitle="No contracts with procurement classifications"
                        title={CHART_TITLE}
                    />
                )}
            </>
        );
    }
}

export const ContractsByProcurementTags = withRouter(ConnectedContractsByProcurementTags);

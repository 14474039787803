import { fromJS } from 'immutable';

import * as govSignUpActions from '../actions/govSignUp';

const initialState = fromJS({
    organization: {},
});

export default function govSignUpReducer(state = initialState, action = {}) {
    switch (action.type) {
        case govSignUpActions.DOMAIN_MATCH:
            return state.merge(
                fromJS({
                    matchLoading: true,
                    matchError: null,
                })
            );
        case govSignUpActions.DOMAIN_MATCH_SUCCESS:
            return state.merge(
                fromJS({
                    matchSubmitted: true,
                    matchLoading: false,
                    orgMatch: action.result,
                    hasOrgMatch: action.isMatch,
                })
            );
        case govSignUpActions.DOMAIN_MATCH_FAIL:
            return state.merge(
                fromJS({
                    matchLoading: false,
                    matchError: action.error,
                })
            );
        case govSignUpActions.GOVT_INVITE:
            return state.merge(
                fromJS({
                    govtInviteLoading: true,
                    govtInviteError: null,
                })
            );
        case govSignUpActions.GOVT_INVITE_SUCCESS:
            return state.merge(
                fromJS({
                    govtInviteLoading: false,
                    govtInviteSent: true,
                })
            );
        case govSignUpActions.GOVT_INVITE_FAIL:
            return state.merge(
                fromJS({
                    govtInviteLoading: false,
                    govtInviteError: action.error,
                })
            );
        case govSignUpActions.SET_GOVT_INVITE_EMAIL:
            return state.merge(
                fromJS({
                    govtInviteEmail: action.email,
                    govtInviteSent: false,
                })
            );
        default:
            return state;
    }
}

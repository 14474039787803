import {
    getFiscalPeriodsWithCalendarYear,
    getPeriodBounds,
} from '@og-pro/shared-config/requisitions/fiscalPeriods';

import { glOpenFiscalPeriods } from './queries';
import { useOgFinancials } from './useOgFinancials';

const useOpenFiscalPeriods = (options) =>
    useOgFinancials({ queryKey: 'fiscalYear', query: glOpenFiscalPeriods, ...options });

export const useOpenFiscalPeriodsWithCalendarYear = (options) =>
    useOpenFiscalPeriods({
        select: (data) => getFiscalPeriodsWithCalendarYear(data.glFiscalPeriods),
        ...options,
    });

export const useOpenFiscalPeriodsRange = (options) =>
    useOpenFiscalPeriods({
        select: (data) => getPeriodBounds(data.glFiscalPeriods),
        ...options,
    });

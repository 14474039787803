import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { getFormSyncErrors } from 'redux-form';

import { BaseProjectSectionsNav } from '../../../../../../components/SDv2/ProjectSectionsNav';
import { form } from '../../../forms/constants';
import { TemplateEditV2NavContext, TemplateEditV2FunctionsContext } from '../../context';
import { getProcessInformationSections } from '../../helpers';

export const TemplateProcessInformationNav = () => {
    const errorsSelectors = getFormSyncErrors(form);
    const formErrors = useSelector((state) => errorsSelectors(state));

    const { activeSectionId, setActiveSectionId } = useContext(TemplateEditV2NavContext);
    const { showFormValidation, updateTemplateIfDirty, templateProject } = useContext(
        TemplateEditV2FunctionsContext
    );

    const sections = getProcessInformationSections({
        templateProject,
        activeSectionId,
        setActiveSectionId: (i) => {
            updateTemplateIfDirty();
            setActiveSectionId(i);
        },
        formErrors,
        showFormValidation,
    });

    return <BaseProjectSectionsNav sections={sections} />;
};

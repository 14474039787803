import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { CommentReplyForm } from './CommentReplyForm/CommentReplyForm';
import { form } from './CommentReplyForm/constants';
import { UserProfilePicture } from '../..';

export class CommentReply extends PureComponent {
    static propTypes = {
        canComment: PropTypes.bool.isRequired,
        threadId: PropTypes.number.isRequired,
        user: PropTypes.object.isRequired,
        resolving: PropTypes.bool,
        replyError: PropTypes.string,
        resolveError: PropTypes.string,
        replySubmitHandler: PropTypes.func.isRequired,
        resolveClickHandler: PropTypes.func.isRequired,
    };

    render() {
        const {
            canComment,
            threadId,
            user,
            resolving,
            resolveError,
            replyError,
            resolveClickHandler,
            replySubmitHandler,
        } = this.props;

        const styles = require('./CommentReply.scss');
        const commentStyles = require('./Comment.scss');

        return (
            <div className={styles.replyContainer}>
                <div className={commentStyles.commentPicture}>
                    <UserProfilePicture showTooltip user={user} />
                </div>
                <div className={commentStyles.commentContent}>
                    <CommentReplyForm
                        canComment={canComment}
                        form={`${form}${threadId}`}
                        replyError={replyError}
                        resolveClickHandler={resolveClickHandler}
                        resolveError={resolveError}
                        resolving={resolving}
                        submitHandler={(data) => replySubmitHandler(threadId, data)}
                        threadId={threadId}
                    />
                </div>
            </div>
        );
    }
}

import { buildQaTag, componentNames, Typography } from '@og-pro/ui';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import ReactToggle from 'react-toggle';

import { HelpBlock, HelpIcon, Label } from '..';

export class Toggle extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        formClassName: PropTypes.string,
        help: PropTypes.string,
        input: PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
            onBlur: PropTypes.func.isRequired,
            onChange: PropTypes.func.isRequired,
        }).isRequired,
        inverseToggleDisplay: PropTypes.bool,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        labelId: PropTypes.string,
        labelInline: PropTypes.bool,
        labelInlineTogglePosition: PropTypes.string,
        leftLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        meta: PropTypes.object.isRequired,
        name: PropTypes.string,
        qaTag: PropTypes.string.isRequired, // Format: <pageName>-<simpleDescription>
        rightLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        useOpenGovStyle: PropTypes.bool,
        tooltip: PropTypes.string,
    };

    static defaultProps = {
        labelInlineTogglePosition: 'left',
        leftLabel: 'OFF',
        rightLabel: 'ON',
        useOpenGovStyle: false,
    };

    get styles() {
        if (this.props.useOpenGovStyle) {
            return require('./ToggleOpenGovStyle.scss');
        }

        return require('./Toggle.scss');
    }

    getLabelId() {
        const { label, labelId, ...props } = this.props;
        const labelIsString = typeof label === 'string';
        const alternateLabel = labelIsString ? label : props.input.name;
        return labelId || alternateLabel;
    }

    onChange = (e) => {
        const {
            input: { onChange },
            inverseToggleDisplay,
        } = this.props;

        if (inverseToggleDisplay) {
            return onChange(!e.target.checked);
        }

        return onChange(e);
    };

    onBlur = () => {
        const {
            input: { onBlur, value },
        } = this.props;

        return onBlur(value);
    };

    renderToggle() {
        const {
            formClassName,
            input,
            inverseToggleDisplay,
            label,
            labelInline,
            labelInlineTogglePosition,
            meta,
            qaTag,
            useOpenGovStyle,
            leftLabel,
            rightLabel,
            ...props
        } = this.props;

        const { value, onChange, onBlur, ...inputProps } = input;
        const tagWithComponentName = buildQaTag(qaTag, componentNames.TOGGLE);
        let checked = !!value;

        if (inverseToggleDisplay) {
            checked = !checked;
        }

        return (
            <ReactToggle
                {...inputProps}
                aria-label={this.getLabelId()}
                checked={checked}
                className={classnames(this.styles.input, {
                    [this.styles.disabledInput]: props.disabled,
                    [this.styles.checked]: checked,
                })}
                data-qa={tagWithComponentName}
                icons={!useOpenGovStyle}
                id={this.getLabelId()}
                onBlur={this.onBlur}
                onChange={this.onChange}
                {...props}
            />
        );
    }

    render() {
        const {
            disabled,
            formClassName,
            help,
            inverseToggleDisplay,
            input,
            label,
            labelInline,
            labelInlineTogglePosition,
            leftLabel,
            rightLabel,
            useOpenGovStyle,
            tooltip,
        } = this.props;

        if (useOpenGovStyle) {
            let checked = !!input.value;

            if (inverseToggleDisplay) {
                checked = !checked;
            }

            return (
                <div className={classnames('form-group', formClassName)}>
                    {label && (
                        <label
                            className={classnames(this.styles.label, {
                                [this.styles.noMarginBottom]: !!help,
                            })}
                        >
                            {label}
                            {tooltip && (
                                <HelpIcon className={this.styles.helpIcon} tooltip={tooltip} />
                            )}
                        </label>
                    )}
                    {help && <HelpBlock useOpenGovStyle={useOpenGovStyle}>{help}</HelpBlock>}

                    <Label className={this.styles.wrapperLabel}>
                        {leftLabel && (
                            <Typography
                                className={classnames(this.styles.leftLabel, {
                                    [this.styles.disabledLabel]: disabled,
                                })}
                                component="span"
                            >
                                {leftLabel}
                            </Typography>
                        )}

                        {this.renderToggle()}

                        {rightLabel && (
                            <Typography
                                className={classnames(this.styles.rightLabel, {
                                    [this.styles.disabledLabel]: disabled,
                                    [this.styles.checkedLabel]: checked,
                                })}
                                component="span"
                            >
                                {rightLabel}
                            </Typography>
                        )}
                    </Label>
                </div>
            );
        }

        let labelEl = label;
        if (tooltip) {
            labelEl = (
                <span>
                    {label}
                    <HelpIcon tooltip={tooltip} />
                </span>
            );
        }

        if (labelEl && labelInline) {
            return (
                <div className={classnames('form-group', formClassName)}>
                    {labelInlineTogglePosition === 'right' && (
                        <Label
                            className={this.styles.labelInlineToggleRight}
                            htmlFor={this.getLabelId()}
                            label={labelEl}
                        />
                    )}
                    {this.renderToggle()}
                    {labelInlineTogglePosition === 'left' && (
                        <Label
                            className={this.styles.labelInlineToggleLeft}
                            htmlFor={this.getLabelId()}
                            label={labelEl}
                        />
                    )}
                </div>
            );
        }

        if (labelEl) {
            return (
                <div className={classnames('form-group', formClassName)}>
                    <Label htmlFor={this.getLabelId()} label={labelEl} />
                    <div>{this.renderToggle()}</div>
                    {help && <HelpBlock>{help}</HelpBlock>}
                </div>
            );
        }

        return this.renderToggle();
    }
}

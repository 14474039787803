import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { Outlet } from 'react-router-dom';

import { isGovLoaded } from './selectors';
import { getGovernmentJS } from '../selectors';
import connectData from '../../ConnectData';
import { getUserJS } from '../../selectors';
import { loadGov } from '../../../actions/publicProject';
import { loadTags } from '../../../actions/tags';
import { LoadingSpinner, LoadingError, Main } from '../../../components';
import { getMetaTitle } from '../../../helpers';

function fetchData(getState, dispatch, location, params) {
    const { governmentCode } = params;
    const state = getState();

    const promises = [];

    if (state.publicProject.getIn(['government', 'code']) !== governmentCode) {
        promises.push(dispatch(loadGov(governmentCode)));
    }

    if (state.tags.get('governmentCodeOfTags') !== governmentCode) {
        promises.push(dispatch(loadTags({ governmentCode })));
    }

    return Promise.all(promises);
}

const mapStateToProps = (state, props) => {
    let loadError;
    const loadGovError = state.publicProject.get('loadGovError');
    const loadTagsError = state.tags.get('loadTagsError');
    if (loadGovError) {
        loadError = loadGovError;
    } else if (loadTagsError) {
        loadError = loadTagsError;
    }

    return {
        government: getGovernmentJS(state),
        govLoaded: isGovLoaded(state, props),
        loadError,
        loading: state.publicProject.get('loadingGov') || state.tags.get('loadingTags'),
        user: getUserJS(state),
    };
};

// @connectData
// @connect
class ConnectedGovPortal extends Component {
    static propTypes = {
        government: PropTypes.object,
        govLoaded: PropTypes.bool,
        loadError: PropTypes.string,
        loading: PropTypes.bool,
    };

    static defaultProps = {
        govLoaded: false,
        loading: false,
    };

    render() {
        const { government, govLoaded, loadError, loading } = this.props;

        if (loading && !govLoaded) {
            return <LoadingSpinner />;
        }

        if (loadError && !govLoaded) {
            return <LoadingError error={loadError} />;
        }

        if (!government) {
            return null;
        }

        const title =
            government.organization && government.organization.name
                ? `${government.organization.name} Procurement Portal`
                : 'Procurement Portal';

        const image =
            government.organization && government.organization.logo
                ? government.organization.logo
                : null;

        return (
            <Main>
                <h1 className="visually-hidden">Procurement Portal</h1>
                <Helmet>
                    <title>{getMetaTitle(title)}</title>
                    <meta content={image} property="og:image" />
                    <meta content={title} property="og:title" />
                </Helmet>
                <Outlet />
            </Main>
        );
    }
}

export const GovPortal = compose(
    connectData(fetchData),
    withRouter,
    connect(mapStateToProps)
)(ConnectedGovPortal);

import moment from 'moment';
import { createSelector } from 'reselect';

import { getRequisitionLogText } from '../helpers';
import { getRequisitionLogsByStep } from '../selectors';
import { getRequisitionJS } from '../../../../../../selectors/govApp';

export const getStepRequisitionLogsDisplay = createSelector(
    [getRequisitionLogsByStep, getRequisitionJS, (_, reviewSequenceStep) => reviewSequenceStep],
    (requisitionLogsByStep, requisition, reviewSequenceStep) => {
        const requisitionLogs = requisitionLogsByStep[reviewSequenceStep.id] || [];

        const displayRequisitionLogs = requisitionLogs.map((requisitionLog) => {
            return {
                text: getRequisitionLogText(requisitionLog, reviewSequenceStep),
                details: requisitionLog.details,
            };
        });
        if (requisition.current_step_id === reviewSequenceStep.id) {
            const date = reviewSequenceStep.stepApproval.lastActionAt;
            displayRequisitionLogs.unshift({
                text: `Active as of ${moment(date).format('M/D/YY [at] h:mma')}`,
            });
        }

        return displayRequisitionLogs;
    }
);

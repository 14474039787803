import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { OutlineButton, UserProfilePicture } from '../../../../components';
import { deleteDepartment } from '../../../../actions/admin';
import { showConfirmationSimpleModal } from '../../../../actions/confirmation';

const mapDispatchToProps = {
    deleteDepartment,
    showConfirmationSimpleModal,
};

// @connect
class ConnectedDepartmentFormDisplay extends Component {
    static propTypes = {
        deleteDepartment: PropTypes.func.isRequired,
        department: PropTypes.shape({
            contact: PropTypes.object,
            departmentHead: PropTypes.string.isRequired,
            departmentHeadTitle: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        }).isRequired,
        editDepartment: PropTypes.func.isRequired,
        organizationId: PropTypes.number.isRequired,
        showConfirmationSimpleModal: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            deleteError: null,
            deleting: false,
        };
    }

    get styles() {
        return require('../index.scss');
    }

    deleteDepartment = () => {
        const { department, organizationId } = this.props;

        this.setState({ deleting: true, deleteError: null });
        return this.props
            .deleteDepartment(organizationId, department.id)
            .then(() => this.setState({ deleting: false }))
            .catch((error) => {
                this.setState({ deleting: false, deleteError: error.message });
            });
    };

    handleDelete = () => {
        return this.props.showConfirmationSimpleModal(this.deleteDepartment, {
            text: 'Are you sure you want to delete this department?',
        });
    };

    render() {
        const {
            department: { contact, departmentHead, departmentHeadTitle, name },
            editDepartment,
        } = this.props;

        const { deleteError, deleting } = this.state;

        return (
            <div className={`row ${this.styles.deptListRow}`}>
                <div
                    className={`col-xs-12 col-md-3 ${this.styles.deptCol} ${this.styles.deptText}`}
                >
                    {name}
                </div>
                <div
                    className={`hidden-xs hidden-sm col-md-2 ${this.styles.deptCol} ${this.styles.deptText}`}
                >
                    {departmentHead}
                </div>
                <div
                    className={`hidden-xs hidden-sm col-md-2 ${this.styles.deptCol} ${this.styles.deptText}`}
                >
                    {departmentHeadTitle}
                </div>
                <div className={`col-xs-12 col-md-2 ${this.styles.deptCol}`}>
                    {contact ? (
                        <>
                            <UserProfilePicture
                                className={this.styles.userProfilePicture}
                                horizontal
                                user={contact}
                            />
                            &nbsp;
                            {contact.displayName}
                        </>
                    ) : (
                        <em>None Assigned</em>
                    )}
                </div>
                <div className={`col-xs-12 col-md-3 ${this.styles.deptCol}`}>
                    <OutlineButton
                        bsSize="sm"
                        bsStyle="primary"
                        disabled={deleting}
                        onClick={editDepartment}
                    >
                        <i className="fa fa-pencil" /> Edit
                    </OutlineButton>
                    &nbsp;&nbsp;
                    <OutlineButton
                        bsSize="sm"
                        bsStyle="danger"
                        disabled={deleting}
                        onClick={this.handleDelete}
                    >
                        <i className="fa fa-trash" /> {deleting ? 'Deleting...' : 'Delete'}
                    </OutlineButton>
                    {deleteError && <div className="error-block">{deleteError}</div>}
                </div>
            </div>
        );
    }
}

export const DepartmentFormDisplay = connect(
    null,
    mapDispatchToProps
)(ConnectedDepartmentFormDisplay);

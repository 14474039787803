import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';

import { InputText, Button } from '../../../..';
import { form, fieldNames } from './constants';

const formConfig = {
    form,
};

// @reduxForm
class ConnectedEditForm extends PureComponent {
    static propTypes = {
        submitting: PropTypes.bool.isRequired,
        pristine: PropTypes.bool.isRequired,
        submitError: PropTypes.string,
        handleSubmit: PropTypes.func.isRequired,
        hideModal: PropTypes.func.isRequired,
    };

    renderSubmitError() {
        const { submitError } = this.props;
        if (!submitError) return null;

        const styles = require('../ApprovalsModal.scss');
        return <div className={`text-danger ${styles.editFormError}`}>{submitError}</div>;
    }

    render() {
        const { submitting, handleSubmit, hideModal, pristine } = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Edit Note for Your Approval (optional):</label>
                    <Field
                        component={InputText}
                        disabled={submitting}
                        name={fieldNames.COMMENT}
                        placeholder="Enter note for your approval"
                        type="textarea"
                    />
                </div>
                <div>
                    <Button onClick={hideModal}>Cancel</Button>
                    <Button
                        bsStyle="primary"
                        className="pull-right"
                        disabled={pristine || submitting}
                        type="submit"
                    >
                        {submitting ? 'Saving' : 'Save Note'}
                    </Button>
                </div>
                {this.renderSubmitError()}
            </form>
        );
    }
}

export const EditForm = reduxForm(formConfig)(ConnectedEditForm);

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { AdminUserListButton } from '..';

export class AdminRequired extends PureComponent {
    static propTypes = {
        actionText: PropTypes.string,
        className: PropTypes.string,
        contactText: PropTypes.string,
        permissionType: PropTypes.string.isRequired,
    };

    static defaultProps = {
        actionText: 'take this action',
        contactText: 'contact an admin',
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const { actionText, className, contactText, permissionType } = this.props;

        return (
            <div className={classnames('text-center', className)}>
                <div className="text-danger">
                    <i className="fa fa-exclamation-triangle" /> Only designated users can{' '}
                    {actionText}
                </div>
                <div className={this.styles.info}>
                    Please&nbsp;
                    <AdminUserListButton permissionType={permissionType} text={contactText} />
                    &nbsp;once you are ready to take this action
                </div>
            </div>
        );
    }
}

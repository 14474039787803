import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { ChecklistCreateModal } from './ChecklistCreateModal';
import { getContractChecklists } from './selectors';
import { isContractEditor } from '../selectors';
import connectData from '../../ConnectData';
import { getContractJS } from '../../selectors';
import { loadContractChecklists } from '../../../actions/contracts';
import {
    Button,
    ChecklistsList,
    LoadingError,
    LoadingSpinner,
    Main,
    SectionTitle,
} from '../../../components';
import { getContractChecklistsPath } from '../../../selectors/govApp';

function fetchData(getState, dispatch, location, params) {
    const contractId = Number.parseInt(params.contractId, 10);
    return dispatch(loadContractChecklists(contractId));
}

// @connectData
const ConnectedContractChecklistsList = () => {
    const location = useLocation();
    const params = useParams();
    const loading = useSelector((state) => state.contracts.get('loadingChecklists'));
    const loadError = useSelector((state) => state.contracts.get('loadChecklistsError'));
    const checklists = useSelector(getContractChecklists);
    const contract = useSelector(getContractJS);
    const isEditor = useSelector(isContractEditor);
    const contractChecklistsPath = useSelector((state) =>
        getContractChecklistsPath(state, { location, params })
    );

    const [showModal, setShowModal] = useState(false);

    if (loading) {
        return <LoadingSpinner />;
    }

    if (loadError) {
        return <LoadingError error={loadError} />;
    }

    const styles = require('./index.scss');

    return (
        <Main>
            <SectionTitle
                info="Onboard vendors to your contract by adding and completing checklists"
                title="Checklists"
            />
            <div className="row">
                <div className="col-lg-10 col-lg-offset-1">
                    <div className={styles.addBtnContainer}>
                        <Button
                            bsStyle="primary"
                            disabled={!isEditor}
                            onClick={() => setShowModal(true)}
                            qaTag="govContracts-addChecklist"
                            tooltip={
                                isEditor ? undefined : 'Only contract editors can create checklist'
                            }
                        >
                            <i className="fa fa-plus" />
                            &nbsp;Add Checklist
                        </Button>
                    </div>
                    <ChecklistsList
                        checklists={checklists}
                        contractChecklistsPath={contractChecklistsPath}
                    />
                </div>
            </div>
            {showModal && (
                <ChecklistCreateModal contractId={contract.id} onHide={() => setShowModal(false)} />
            )}
        </Main>
    );
};

export const ContractChecklistsList = connectData(fetchData)(ConnectedContractChecklistsList);

import { PropTypes } from 'prop-types';
import React, { useState } from 'react';

import { UNIT_PRICE } from '@og-pro/shared-config/priceTables';

import { Button, PriceTable } from '..';
import { QUANTITY_REQUESTED } from '../../containers/GovApp/constants';

export const PurchaseOrderPriceTable = ({
    additionalColumns,
    selectedLineItemId,
    onCellValueChanged,
    priceTable,
    readOnly,
    salesTax,
}) => {
    const styles = require('./index.scss');
    const lineItemSelected = selectedLineItemId && selectedLineItemId !== -1;
    const [isCollapsed, setIsCollapsed] = useState(lineItemSelected);

    const handleCollapseToggle = (event) => {
        event.preventDefault();
        setIsCollapsed(!isCollapsed);
    };

    const filteredPriceItems = priceTable.priceItems.filter((priceItem) => {
        if (lineItemSelected) {
            return selectedLineItemId === priceItem.id;
        }
        return priceItem.quantityRequested !== 0;
    });

    const filteredAdditionalColumns = additionalColumns.filter((column) => {
        return priceTable.hasQuantity || column.data.field !== 'availableQuantity';
    });

    return (
        <div className={styles.purchaseOrderPriceTable}>
            <PriceTable
                additionalColumns={filteredAdditionalColumns}
                containerStyle={{ width: '100%', height: '200px' }}
                disableAutoHeight
                fields={{ name: '', QUANTITY_REQUESTED, UNIT_PRICE }}
                hideHeaderButtons
                onCellValueChanged={onCellValueChanged}
                priceTable={{
                    ...priceTable,
                    priceItems: isCollapsed ? filteredPriceItems : priceTable.priceItems,
                }}
                purchaseOrderCellsEditable
                readOnly={readOnly}
                salesTax={salesTax}
            />
            <Button bsSize="sm" onClick={handleCollapseToggle}>
                {isCollapsed ? 'Show all' : 'Collapse'}
            </Button>
        </div>
    );
};

PurchaseOrderPriceTable.propTypes = {
    additionalColumns: PropTypes.arrayOf(
        PropTypes.shape({
            data: PropTypes.object.isRequired, // Column definition object
            orderIndex: PropTypes.number,
        })
    ),
    selectedLineItemId: PropTypes.number,
    onCellValueChanged: PropTypes.func.isRequired,
    priceTable: PropTypes.shape({
        columnOrder: PropTypes.array.isRequired,
        description: PropTypes.string,
        hasDiscount: PropTypes.bool,
        hasNoBid: PropTypes.bool,
        hasPercentage: PropTypes.bool,
        hasQuantity: PropTypes.bool,
        hasSalesTaxRow: PropTypes.bool,
        hasTotalRow: PropTypes.bool,
        omitLineItem: PropTypes.bool,
        priceItems: PropTypes.arrayOf(
            PropTypes.shape({
                availableQuantity: PropTypes.number,
                bidQuantity: PropTypes.number,
                description: PropTypes.string,
                discount: PropTypes.number,
                discountOnly: PropTypes.bool,
                id: PropTypes.number.isRequired,
                isHeaderRow: PropTypes.bool,
                lineItem: PropTypes.string,
                quantity: PropTypes.number,
                quantityRequested: PropTypes.number,
                totalQuantityPurchased: PropTypes.number,
                unitPrice: PropTypes.number,
                unitToMeasure: PropTypes.string,
                vendorResponse: PropTypes.shape({
                    comment: PropTypes.string,
                    description: PropTypes.string,
                    quantity: PropTypes.number,
                    unitPrice: PropTypes.number,
                    unitToMeasure: PropTypes.string,
                }),
            })
        ).isRequired,
        title: PropTypes.string,
    }).isRequired,
    readOnly: PropTypes.bool,
    salesTax: PropTypes.number,
};

import { isEmpty } from 'lodash';

import { SEARCH_PRICE_ITEMS, QUICK_SEARCH_QUERY } from './constants';

export const MINIMUM_QUERY_LENGTH = 3;

export const validate = (values) => {
    const errors = {};

    const queryCharCount = isEmpty(values[QUICK_SEARCH_QUERY])
        ? 0
        : values[QUICK_SEARCH_QUERY].trim().length;

    if (values[SEARCH_PRICE_ITEMS] && queryCharCount < MINIMUM_QUERY_LENGTH) {
        errors[QUICK_SEARCH_QUERY] = 'Please enter 3 or more characters';
    }

    return errors;
};

import { createSelector } from 'reselect';

import { questionTypesDisplayMap } from '@og-pro/shared-config/questionnaires';

const getFormData = (state) => state.questionnaire.get('formData');
const getModalOptions = (state) => state.questionnaire.get('modalOptions');
const getQuestionType = (state) => state.questionnaire.getIn(['formData', 'type']);

export const getFormDataJS = createSelector([getFormData], (rawFormData) => {
    if (rawFormData) {
        return rawFormData.toJS();
    }
});

export const getQuestionTypeJS = createSelector([getQuestionType], (questionType) => {
    if (questionType) {
        return questionTypesDisplayMap[questionType];
    }
});

export const getModalOptionsJS = createSelector([getModalOptions], (rawModalOptions) => {
    if (rawModalOptions) {
        return rawModalOptions.toJS();
    }
    return {};
});

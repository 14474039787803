import { listToDict } from '@og-pro/shared-config/helpers';
import {
    attachmentOptionDocumentTypes,
    singleDocumentTypes,
    standardDocumentTypeNames,
} from '@og-pro/shared-config/standardDocuments';

import {
    projectTemplateVariableOptions,
    proposalTemplateVariableOptions,
    templateTemplateVariableOptions,
} from '../../GovApp/constants';

const { AWARD_NOTICE, PROPOSAL_VIEWER_AGREEMENT, INTERVIEW_INVITATION, NON_AWARD_LETTER } =
    standardDocumentTypeNames;

export const createForm = 'governmentDocumentForm';
export const updateForm = 'governmentDocumentUpdateForm';
export const renameForm = 'governmentDocumentRenameForm';

export const fields = ['attachment', 'attachment_id', 'isUsed', 'text', 'title', 'type'];

export const fieldNames = listToDict(fields);

const projectAndProposalVariableOptions = [
    {
        label: 'Response Variables',
        options: proposalTemplateVariableOptions,
    },
    {
        label: 'Template Variables',
        options: templateTemplateVariableOptions,
    },
    {
        label: 'Project Variables',
        options: projectTemplateVariableOptions,
    },
];

export const navItems = [
    {
        info:
            'This document must be agreed to by each user before viewing a vendor response.\n' +
            'The active document is the current agreement that the user will be shown and required to agree with.',
        name: 'Response Viewer Agreement',
        type: PROPOSAL_VIEWER_AGREEMENT,
    },
    {
        info: 'This document is used to generate Interview Invitation Letters.',
        name: 'Interview Invitation',
        type: INTERVIEW_INVITATION,
        defaultTitle: 'Interview Invitation',
        templateVariableOptions: projectAndProposalVariableOptions,
    },
    {
        info: 'This document is used to generate Non-Award Letters.',
        name: 'Non-Award Letter',
        type: NON_AWARD_LETTER,
        defaultTitle: 'Non-Award Letter',
        templateVariableOptions: projectAndProposalVariableOptions,
    },
    {
        info: 'This document is used to generate Notice of Intent to Award Letters.',
        name: 'Notice of Intent to Award',
        type: AWARD_NOTICE,
        defaultTitle: 'Notice of Intent to Award',
        templateVariableOptions: projectAndProposalVariableOptions,
    },
].map((item) => {
    return {
        ...item,
        isSingleDocument: singleDocumentTypes.includes(item.type),
        hasAttachmentOption: attachmentOptionDocumentTypes.includes(item.type),
    };
});

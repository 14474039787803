import React from 'react';
import { Outlet } from 'react-router-dom';

import { ApplyModal } from '../ApplyModal';
import { VendorProfileModal } from '../../../components';

export const App = () => {
    return (
        <div className="container-fluid">
            <Outlet />
            <ApplyModal />
            <VendorProfileModal />
        </div>
    );
};

import { requisitionSocketActionType } from './constants';
import { showSnackbar } from './notification';
import { UPDATE_REQUISITION_SUCCESS } from './requisitions';
import { emitRequisitionSocket } from './utils';

export const JOIN = `${requisitionSocketActionType}/JOIN`;
export const LEAVE = `${requisitionSocketActionType}/LEAVE`;

export const joinRequisition = (requisitionId) => {
    return { type: JOIN, requisitionId };
};

export const leaveRequisition = (requisitionId) => {
    return { type: LEAVE, requisitionId };
};

export const emitUpdateAction = (dispatch, result, message) => {
    const updateAction = { type: UPDATE_REQUISITION_SUCCESS, result };
    dispatch(emitRequisitionSocket(result.id, updateAction, message));
    if (message) {
        dispatch(showSnackbar(message));
    }
};

import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { fieldNames, form } from './constants';
import { validate } from './validate';
import { getInvitedUsersSelectOptions } from '../../../../selectors';
import {
    Button,
    InputText,
    LoadingButton,
    SearchSelect,
    SearchSelectUserOption,
} from '../../../../../components';

const { DESCRIPTION, NAME, USER_ID } = fieldNames;

const formConfig = {
    form,
    validate,
};

const ConnectedEntityPositionForm = ({ handleSubmit, isNew, onCancel, updateError, updating }) => {
    const userSelectOptions = useSelector(getInvitedUsersSelectOptions);

    return (
        <form onSubmit={handleSubmit}>
            <Field
                component={InputText}
                disabled={updating}
                hasFeedback={false}
                label="Position Name"
                name={NAME}
                placeholder="City Manager"
                qaTag="entityPositionFormModal-name"
                type="text"
            />
            <Field
                component={InputText}
                disabled={updating}
                hasFeedback={false}
                label="Description (optional)"
                minRows={2}
                name={DESCRIPTION}
                placeholder="Describe the position"
                qaTag="entityPositionFormModal-description"
                type="textarea"
            />
            <Field
                backspaceRemovesValue={false}
                component={SearchSelect}
                components={{
                    Option: SearchSelectUserOption,
                }}
                disabled={updating}
                label="User Assigned to Position"
                name={USER_ID}
                options={userSelectOptions}
                placeholder="Select user for this position"
            />
            <div className="text-right">
                <Button onClick={onCancel} qaTag="entityPositionFormModal-cancel">
                    Cancel
                </Button>
                &nbsp;&nbsp;
                <LoadingButton
                    bsStyle={isNew ? 'success' : 'primary'}
                    disabled={updating}
                    icon={isNew ? 'fa-plus' : 'fa-pencil'}
                    loading={updating}
                    qaTag="entityPositionFormModal-submit"
                    text={isNew ? 'Create Position' : 'Update Position'}
                    type="submit"
                />
                {updateError && <div className="error-block">{updateError}</div>}
            </div>
        </form>
    );
};

ConnectedEntityPositionForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    isNew: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    updateError: PropTypes.string,
    updating: PropTypes.bool,
};

export const EntityPositionForm = reduxForm(formConfig)(ConnectedEntityPositionForm);

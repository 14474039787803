import { showSnackbar } from './notification';

export const CONDITIONAL_LOGIC_MODAL_HIDE = 'app/CONDITIONAL_LOGIC_MODAL_HIDE';
export const CONDITIONAL_LOGIC_MODAL_SHOW = 'app/CONDITIONAL_LOGIC_MODAL_SHOW';
export const HIDE_INSERT_IMAGE_MODAL = 'app/HIDE_INSERT_IMAGE_MODAL';
export const HIDE_INSERT_TAG_MODAL = 'app/HIDE_INSERT_TAG_MODAL';
export const HIDE_INSERT_TEMPLATE_VARIABLE_MODAL = 'app/HIDE_INSERT_TEMPLATE_VARIABLE_MODAL';
export const HIDE_PARTNER_RESOURCES_MODAL = 'app/HIDE_PARTNER_RESOURCES_MODAL';
export const HIDE_REFRESH_MODAL = 'app/HIDE_REFRESH_MODAL';
export const HIDE_SUGGESTED_CONTENT_MODAL = 'app/HIDE_SUGGESTED_CONTENT_MODAL';
export const INITIAL_CLIENT_RENDER_COMPLETE = 'app/INITIAL_CLIENT_RENDER_COMPLETE';
export const SET_APP_VERSION = 'app/SET_APP_VERSION';
export const SHOW_INSERT_IMAGE_MODAL = 'app/SHOW_INSERT_IMAGE_MODAL';
export const SHOW_INSERT_TAG_MODAL = 'app/SHOW_INSERT_TAG_MODAL';
export const SHOW_INSERT_TEMPLATE_VARIABLE_MODAL = 'app/SHOW_INSERT_TEMPLATE_VARIABLE_MODAL';
export const SHOW_PARTNER_RESOURCES_MODAL = 'app/SHOW_PARTNER_RESOURCES_MODAL';
export const SHOW_REFRESH_MODAL = 'app/SHOW_REFRESH_MODAL';
export const SHOW_SUGGESTED_CONTENT_MODAL = 'app/SHOW_SUGGESTED_CONTENT_MODAL';
export const TOGGLE_CUSTOM_EMAIL_MODAL = 'app/TOGGLE_CUSTOM_EMAIL_MODAL';
export const UPLOAD_IMAGE = 'app/UPLOAD_IMAGE';
export const UPLOAD_IMAGE_FAIL = 'app/UPLOAD_IMAGE_FAIL';
export const UPLOAD_IMAGE_SUCCESS = 'app/UPLOAD_IMAGE_SUCCESS';
export const TINY_MCE_MODAL_IS_OPEN = 'app/TINY_MCE_MODAL_IS_OPEN';

export const SHOW_SAVE_MODAL = 'app/SHOW_SAVE_MODAL';
export const HIDE_SAVE_MODAL = 'app/HIDE_SAVE_MODAL';
export const SHOW_SAVE_SUCCESS = 'app/SHOW_SAVE_SUCCESS';
export const SHOW_SAVE_ERROR = 'app/SHOW_SAVE_ERROR';

export function isTinyMceModalOpen(isOpen) {
    return { type: TINY_MCE_MODAL_IS_OPEN, payload: isOpen };
}

export function hideInsertImageModal() {
    return { type: HIDE_INSERT_IMAGE_MODAL };
}

export function hideInsertTagModal() {
    return { type: HIDE_INSERT_TAG_MODAL };
}

export function hideInsertTemplateVariableModal() {
    return { type: HIDE_INSERT_TEMPLATE_VARIABLE_MODAL };
}

export function hidePartnerResourcesModal() {
    return { type: HIDE_PARTNER_RESOURCES_MODAL };
}

export function hideRefreshModal() {
    return { type: HIDE_REFRESH_MODAL };
}

export function hideSuggestedContentModal() {
    return { type: HIDE_SUGGESTED_CONTENT_MODAL };
}

export function initialClientRenderComplete() {
    return { type: INITIAL_CLIENT_RENDER_COMPLETE };
}

export function setAppVersion(appVersion) {
    return { type: SET_APP_VERSION, appVersion };
}

export function showInsertImageModal(data) {
    return { type: SHOW_INSERT_IMAGE_MODAL, data };
}

export function showInsertTagModal(data) {
    return { type: SHOW_INSERT_TAG_MODAL, data };
}

export function showInsertTemplateVariableModal(data) {
    return { type: SHOW_INSERT_TEMPLATE_VARIABLE_MODAL, data };
}

export function showPartnerResourcesModal() {
    return { type: SHOW_PARTNER_RESOURCES_MODAL };
}

export function showRefreshModal() {
    return { type: SHOW_REFRESH_MODAL };
}

export function showSuggestedContentModal() {
    return { type: SHOW_SUGGESTED_CONTENT_MODAL };
}

export function toggleCustomEmailModal() {
    return (dispatch) => {
        dispatch({ type: TOGGLE_CUSTOM_EMAIL_MODAL });
    };
}

export function uploadImage(imageDataUrl) {
    return (dispatch, getState, client) => {
        dispatch({ type: UPLOAD_IMAGE });
        return client
            .post('/images', { data: { imageDataUrl } })
            .then(({ imageUrl }) => {
                dispatch({ type: UPLOAD_IMAGE_SUCCESS });
                return imageUrl;
            })
            .catch((error) => {
                dispatch({ type: UPLOAD_IMAGE_FAIL, error });
                return error;
            });
    };
}

export function sendCustomEmail(governmentId, data) {
    return (dispatch, getState, client) => {
        return client
            .post(`/government/${governmentId}/email`, { data })
            .then((result) => {
                dispatch(showSnackbar(`Email sent to ${result.emailRecipients.length} recipients`));
            })
            .catch((error) => {
                dispatch(showSnackbar(`Error: ${error.message}`));
                return error;
            });
    };
}

export function hideConditionalLogicModal() {
    return { type: CONDITIONAL_LOGIC_MODAL_HIDE };
}

export function showConditionalLogicModal(data) {
    return { type: CONDITIONAL_LOGIC_MODAL_SHOW, data };
}

export function showSaveModal() {
    return { type: SHOW_SAVE_MODAL };
}

export function hideSaveModal() {
    return { type: HIDE_SAVE_MODAL };
}

export function showSaveSuccess(onComplete) {
    return (dispatch) => {
        dispatch({ type: SHOW_SAVE_SUCCESS, show: true });
        setTimeout(() => {
            dispatch(hideSaveModal());
            dispatch({ type: SHOW_SAVE_SUCCESS, show: false });
            if (onComplete) {
                onComplete();
            }
        }, 500);
    };
}
export function showSaveError(error) {
    return { type: SHOW_SAVE_ERROR, error };
}

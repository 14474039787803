import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Sticky from 'react-stickynode';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';

import {
    getCurrentDocumentVersionJS,
    getCurrentProjectVersionJS,
    getRevisionsPath,
} from '../../selectors';
import connectData from '../../../../ConnectData';
import { loadDocumentVersion } from '../../../../../actions/revisions';
import {
    Button,
    LoadingError,
    LoadingSpinner,
    ProjectDocumentReadOnly,
} from '../../../../../components';
import { FIXED_TOOLBAR_HEIGHT } from '../../../../../constants/styles';

function fetchData(getState, dispatch, location, params) {
    const projectId = Number.parseInt(params.projectId, 10);
    const versionId = Number.parseInt(params.versionId, 10);

    return dispatch(loadDocumentVersion(projectId, versionId));
}

const mapStateToProps = (state, props) => {
    return {
        documentVersion: getCurrentDocumentVersionJS(state),
        project: getCurrentProjectVersionJS(state),
        revisionsPath: getRevisionsPath(state, props),
        searchError: state.revisions.get('searchError'),
        searching: state.revisions.get('searching'),
    };
};

// @connectData
// @connect
class ConnectedVersionDisplay extends Component {
    static propTypes = {
        documentVersion: PropTypes.shape({
            name: PropTypes.string.isRequired,
        }),
        project: PropTypes.object,
        revisionsPath: PropTypes.string.isRequired,
        searchError: PropTypes.string,
        searching: PropTypes.bool,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const { documentVersion, project, revisionsPath, searchError, searching } = this.props;

        if (searching) {
            return <LoadingSpinner />;
        }

        if (searchError || !project) {
            return <LoadingError error={searchError} />;
        }

        return (
            <>
                <Sticky innerZ={1} top={FIXED_TOOLBAR_HEIGHT}>
                    <h2 className={this.styles.versionHeader}>
                        <Button
                            aria-label="Back"
                            bsStyle="link"
                            className={this.styles.backButton}
                            to={`${revisionsPath}/versions/list`}
                        >
                            <i className="fa fa-lg fa-angle-left" />
                        </Button>
                        &nbsp;&nbsp;
                        {get(documentVersion, 'name')}
                    </h2>
                </Sticky>
                <ProjectDocumentReadOnly project={project} />
            </>
        );
    }
}

export const VersionDisplay = compose(
    connectData(fetchData),
    withRouter,
    connect(mapStateToProps)
)(ConnectedVersionDisplay);

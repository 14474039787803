import { redirect } from 'react-router-dom';

export default ({ request: { url } }) => {
    // split base url and query string
    const [base, parsedQueryString] = url.split('?');
    if (base !== base.toLowerCase()) {
        // Add back query string which can have case sensitive values
        const queryString = parsedQueryString ? `?${parsedQueryString}` : '';
        const lowerCasedUrl = `${base.toLowerCase()}${queryString}`;
        return redirect(lowerCasedUrl);
    }

    return null;
};

import { fieldNames } from '../constants';

const { CREATOR_IDS } = fieldNames;

export default function validateCreators(values) {
    const errors = {};

    if (!values[CREATOR_IDS] || values[CREATOR_IDS].length === 0) {
        errors[CREATOR_IDS] = 'At least one creator must be added';
    }

    return errors;
}

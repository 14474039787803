import PropTypes from 'prop-types';
import React from 'react';
import { ArrowDropDown, Check } from '@mui/icons-material';
import {
    Chip,
    DropdownMenu,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from '@og-pro/ui';
import { tokens } from '@opengov/capital-style';

import { certificationsList } from '@og-pro/shared-config/certifications';

export const CertificationsMenu = ({ handleToggle, selected }) => {
    return (
        <DropdownMenu
            endIcon={
                <>
                    {selected?.length > 0 ? (
                        <Chip
                            label={selected.length}
                            size="extraSmall"
                            sx={{ fontSize: `${tokens.typography.fontSizeMinimum} !important` }}
                        />
                    ) : null}
                    <ArrowDropDown />
                </>
            }
            keepMenuOpenOnClick
            label="Certifications"
        >
            {certificationsList.map((certification) => (
                <ListItem disablePadding key={certification.id}>
                    <ListItemButton onClick={() => handleToggle(certification.id)} role={undefined}>
                        <ListItemIcon>
                            {selected.indexOf(certification.id) !== -1 && <Check />}
                        </ListItemIcon>
                        <ListItemText
                            id={`certification-list-label-${certification.id}`}
                            primary={certification.title}
                        />
                    </ListItemButton>
                </ListItem>
            ))}
        </DropdownMenu>
    );
};

CertificationsMenu.propTypes = {
    handleToggle: PropTypes.func.isRequired,
    selected: PropTypes.array.isRequired,
};

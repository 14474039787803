import { fromJS } from 'immutable';

import * as inboxNotificationsActions from '../actions/inboxNotifications';
import * as userSocketActions from '../actions/userSocket';

const initialState = fromJS({
    hasNewInboxNotification: false,
    loaded: false,
    loadError: null,
    loading: false,
    inboxNotifications: [],
    updateError: null,
    updating: false,
});

export default function inboxNotificationsReducer(state = initialState, action = {}) {
    switch (action.type) {
        case inboxNotificationsActions.LOAD_HAS_NEW_INBOX_NOTIFICATION_SUCCESS:
            return state.merge(
                fromJS({
                    hasNewInboxNotification: action.result.length > 0,
                })
            );
        case inboxNotificationsActions.LOAD_INBOX_NOTIFICATIONS:
            return state.merge(
                fromJS({
                    loading: true,
                    loaded: false,
                    loadError: null,
                })
            );
        case inboxNotificationsActions.LOAD_INBOX_NOTIFICATIONS_SUCCESS:
            return state.merge(
                fromJS({
                    loading: false,
                    loaded: true,
                    inboxNotifications: fromJS(action.result),
                })
            );
        case inboxNotificationsActions.LOAD_INBOX_NOTIFICATIONS_FAIL:
            return state.merge(
                fromJS({
                    loading: false,
                    loaded: false,
                    loadError: action.error.message,
                })
            );
        case inboxNotificationsActions.UPDATE_INBOX_NOTIFICATIONS:
            return state.merge(
                fromJS({
                    updating: true,
                    updateError: null,
                })
            );
        case inboxNotificationsActions.UPDATE_INBOX_NOTIFICATIONS_SUCCESS:
            return state.set('updating', false).set(
                'inboxNotifications',
                state.get('inboxNotifications').map((inboxNotification) => {
                    const id = inboxNotification.get('id');
                    const updatedNotification = action.result.find(
                        (notification) => notification.id === id
                    );
                    if (updatedNotification) {
                        return fromJS(updatedNotification);
                    }
                    return inboxNotification;
                })
            );
        case inboxNotificationsActions.UPDATE_INBOX_NOTIFICATIONS_FAIL:
            return state.merge(
                fromJS({
                    updating: false,
                    updateError: action.error.message,
                })
            );
        case userSocketActions.NEW_INBOX_NOTIFICATION: {
            return state.set('hasNewInboxNotification', true);
        }
        default:
            return state;
    }
}

import React, { useContext } from 'react';

import { BaseProjectSectionsNav } from '../../../../../../components/SDv2/ProjectSectionsNav';
import { TemplateEditV2NavContext } from '../../context';
import { QUESTIONS_AUTOMATION_SECTION_TITLES } from '../../constants';

export const QuestionsAutomationNav = () => {
    const { activeSectionId, setActiveSectionId } = useContext(TemplateEditV2NavContext);

    // TODO: get form data from the store,
    // get validation errors, success status or messages (if any)
    const sections = [
        {
            title: QUESTIONS_AUTOMATION_SECTION_TITLES[0].title,
            index: 0,
        },
        {
            title: QUESTIONS_AUTOMATION_SECTION_TITLES[1].title,
            index: 1,
        },
    ].map((section, i) => {
        return {
            ...section,
            active: activeSectionId === i,
            onClick: () => {
                setActiveSectionId(i);
            },
        };
    });

    return <BaseProjectSectionsNav sections={sections} />;
};

QuestionsAutomationNav.propTypes = {};

import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';

import { fieldNames, form } from './constants';
import { InputText } from '../../../components';
import { validate } from './validate';

const { COMMENT } = fieldNames;

const formConfig = {
    form,
    validate,
};

const ConnectedContractPublicAuditForm = ({ attachments, disabled, handleSubmit }) => {
    const styles = require('./index.scss');

    return (
        <form className={styles.container} onSubmit={handleSubmit}>
            <p className={`${styles.text} text-muted`}>
                Please specify why {attachments ? attachments[0].filename : 'this contract'} is
                being set to private.
            </p>
            <Field
                component={InputText}
                disabled={disabled}
                hasFeedback={false}
                name={COMMENT}
                placeholder="Enter reason for making private"
                qaTag="contractPublicAuditForm-reasonInput"
                type="textarea"
            />
        </form>
    );
};

ConnectedContractPublicAuditForm.propTypes = {
    attachments: PropTypes.arrayOf(PropTypes.object),
    disabled: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
};

export const ContractPublicAuditForm = reduxForm(formConfig)(ConnectedContractPublicAuditForm);

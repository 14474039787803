import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';

import { form, REPLACEMENT_ID } from './constants';
import { validate } from './validate';
import { SearchSelect } from '../../../components';

const formConfig = {
    form,
    validate,
};

// @reduxForm
class ConnectedReassignTagForm extends Component {
    static propTypes = {
        disabled: PropTypes.bool,
        handleSubmit: PropTypes.func.isRequired,
        options: PropTypes.array.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const { disabled, handleSubmit, options } = this.props;

        if (options.length === 0) {
            return (
                <p className={`${this.styles.text} text-muted`}>
                    Tag is currently in use!
                    <br />
                    Please create a new tag to reassign existing tags to before deleting this tag.
                </p>
            );
        }

        const searchSelectOptions = options.map((tag) => {
            return {
                label: tag.name,
                value: tag.id,
            };
        });

        return (
            <form className={this.styles.container} onSubmit={handleSubmit}>
                <p className={`${this.styles.text} text-muted`}>
                    Tag is currently in use!
                    <br />
                    Please reassign items using this tag to another tag before deleting.
                </p>
                <Field
                    component={SearchSelect}
                    disabled={disabled}
                    name={REPLACEMENT_ID}
                    options={searchSelectOptions}
                    placeholder="Select a tag for reassigning existing items"
                />
            </form>
        );
    }
}

export const ReassignTagForm = reduxForm(formConfig)(ConnectedReassignTagForm);

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import { CommentSection } from './CommentSection';
import { ResolvedToggle } from '..';
import { Button, ButtonGroup, Main } from '../..';

export class CommentsList extends PureComponent {
    static propTypes = {
        addFollowers: PropTypes.func.isRequired,
        canComment: PropTypes.bool.isRequired,
        documentsTabName: PropTypes.string.isRequired,
        filterComments: PropTypes.func.isRequired,
        hideFollowersForm: PropTypes.func.isRequired,
        listFilter: PropTypes.string.isRequired,
        projectPath: PropTypes.string.isRequired,
        reopenThread: PropTypes.func.isRequired,
        replyThread: PropTypes.func.isRequired,
        resolveThread: PropTypes.func.isRequired,
        sectionComments: PropTypes.arrayOf(
            PropTypes.shape({
                fields: PropTypes.array.isRequired,
                projectSection: PropTypes.object.isRequired,
            })
        ).isRequired,
        showFollowersForm: PropTypes.func.isRequired,
        showResolved: PropTypes.bool.isRequired,
        toggleResolvedComments: PropTypes.func.isRequired,
        user: PropTypes.object.isRequired,
        users: PropTypes.array.isRequired,
    };

    get styles() {
        return require('./CommentsList.scss');
    }

    handleFilterAllClick = () => {
        const { filterComments } = this.props;

        filterComments('all');
    };

    handleFilterMyClick = () => {
        const { filterComments } = this.props;

        filterComments('mine');
    };

    renderFilterButtons() {
        const { listFilter } = this.props;

        const isAllFilter = listFilter === 'all';
        const isMyFilter = listFilter === 'mine';

        const AllCommentButton = (
            <Button
                active={isAllFilter}
                bsSize="sm"
                bsStyle={isAllFilter ? 'primary' : 'default'}
                onClick={this.handleFilterAllClick}
                qaTag="commentsList-allComments"
            >
                All Comments
            </Button>
        );

        const MyCommentButton = (
            <Button
                active={isMyFilter}
                bsSize="sm"
                bsStyle={isMyFilter ? 'primary' : 'default'}
                onClick={this.handleFilterMyClick}
                qaTag="commentsList-myComment"
            >
                My Comments
            </Button>
        );

        return (
            <ButtonGroup>
                {AllCommentButton}
                {MyCommentButton}
            </ButtonGroup>
        );
    }

    renderNoComments() {
        const { documentsTabName, projectPath } = this.props;

        return (
            <div className={`row text-muted ${this.styles.noComments}`}>
                <div className="col-sm-10 col-sm-offset-1">
                    <div className={this.styles.noCommentsHeader}>No comments here!</div>
                    <div className={this.styles.noCommentsText}>
                        To add a comment, visit the&nbsp;
                        <Link to={`${projectPath}/document`}>{documentsTabName}</Link> tab and click
                        the&nbsp;
                        <span className="text-primary">
                            + <i className="fa fa-comment-o" />
                        </span>
                        &nbsp; icon on the item you would like to comment on.
                    </div>
                    <div className={this.styles.noCommentsText}>
                        You may also use the filters in the upper right to view previously resolved
                        comments.
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const {
            addFollowers,
            canComment,
            hideFollowersForm,
            listFilter,
            projectPath,
            reopenThread,
            replyThread,
            resolveThread,
            sectionComments,
            showFollowersForm,
            showResolved,
            toggleResolvedComments,
            user,
            users,
        } = this.props;

        const CommentSections = sectionComments.map((commentSection, idx) => (
            <CommentSection
                addFollowers={addFollowers}
                canComment={canComment}
                fields={commentSection.fields}
                hideFollowersForm={hideFollowersForm}
                key={idx}
                listFilter={listFilter}
                projectPath={projectPath}
                projectSection={commentSection.projectSection}
                reopenThread={reopenThread}
                replyThread={replyThread}
                resolveThread={resolveThread}
                showFollowersForm={showFollowersForm}
                showResolved={showResolved}
                user={user}
                users={users}
            />
        ));

        return (
            <Main className={`row ${this.styles.container}`}>
                <div className="col-xs-12 col-md-10 col-md-offset-1">
                    <div className={`row ${this.styles.filterBtnContainer}`}>
                        <div className="col-xs-12">{this.renderFilterButtons()}</div>
                    </div>
                    <div className={`row ${this.styles.resolveContainer}`}>
                        <div className="col-xs-12">
                            <ResolvedToggle
                                clickHandler={toggleResolvedComments}
                                showResolved={showResolved}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            {CommentSections.length === 0 ? (
                                this.renderNoComments()
                            ) : (
                                <>
                                    {!canComment && (
                                        <h5 className="text-center text-danger">
                                            <i className="fa fa-exclamation-triangle" /> You must
                                            have view permission to comment
                                        </h5>
                                    )}
                                    {CommentSections}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </Main>
        );
    }
}

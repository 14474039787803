import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import { useSelector } from 'react-redux';

import { useQueryParam } from '../../../../../hooks';
import { getIsGroupAdminOrLeader } from '../selectors';
import {
    numberOfRequestsColors,
    chartBaseProps,
    numberOfRequestsVisualization,
    titleBaseStyles,
    legendBaseProps,
    exportingBaseProps,
} from './constants';
import { highlightPoint, loadMoreIcon, unhighlightPoint } from './helpers';
import { getInitialParams } from '../helpers';
import { useRenderChart } from './hooks/useRenderChart';
import {
    chartInteractionParamsDict,
    handleChartInteractionInitialize,
} from './helpers/chartInteractions';

const { OLDER_THAN } = chartInteractionParamsDict;

export const NumberOfRequests = ({ data }) => {
    const [requestsOlderThan, , setQueryParams] = useQueryParam(OLDER_THAN);

    const isAdminOrLeader = useSelector((state) => getIsGroupAdminOrLeader(state));

    const initialParams = getInitialParams(isAdminOrLeader);

    const labelFormatter = (name, y) => {
        const textLabel = name === '20 days' ? '20+ days' : name;

        return `
        <div style="display:flex;">
            <p style="font-size: 14px; font-weight: 400;">${textLabel}:</p>
            <p style="font-size: 14px;">${y}</p>
        </div>
        `;
    };
    const renderChart = () => {
        loadMoreIcon(Highcharts);

        const chart = Highcharts.chart(numberOfRequestsVisualization, {
            chart: {
                type: 'pie',
                ...chartBaseProps,
            },
            title: {
                text: 'Number of Requests Older Than',
                align: 'left',
                y: 20,
                x: 5,
                style: titleBaseStyles,
            },
            tooltip: {
                pointFormatter() {
                    const { y } = this;
                    if (y > 0) {
                        return `${y} Request${y > 1 ? 's: ' : ': '}<br>Click to view`;
                    }
                    return null;
                },
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    borderWidth: 3,
                    cursor: 'pointer',
                    colors: numberOfRequestsColors,
                    center: ['60%', '100%'],
                    size: '170%',
                    startAngle: -90,
                    endAngle: 90,
                    dataLabels: {
                        enabled: false,
                    },
                    showInLegend: true,
                    events: {
                        click(e) {
                            const clickedPoint = e.point;

                            const selectedPeriod = e.point.options.name;
                            const numberOfDays = selectedPeriod.split(' ')[0];

                            const { points } = this;

                            if (clickedPoint.selected) {
                                setQueryParams({
                                    [OLDER_THAN]: null,
                                });
                                points.forEach((point) =>
                                    highlightPoint(point, numberOfRequestsVisualization)
                                );
                            } else {
                                setQueryParams({
                                    ...initialParams,
                                    [OLDER_THAN]: numberOfDays,
                                });

                                points.forEach((point) =>
                                    unhighlightPoint(point, numberOfRequestsVisualization)
                                );
                                highlightPoint(clickedPoint, numberOfRequestsVisualization);
                            }
                        },
                        // Add data-qa attribute to each point
                        afterAnimate() {
                            const { points } = this;
                            points.forEach((point) => {
                                point.graphic.element.setAttribute(
                                    'data-qa',
                                    `numberOfRequestsOlderThan-${point.name.split(' ')[0]}`
                                );
                            });
                        },
                    },
                },
            },
            credits: {
                enabled: false,
            },
            series: [
                {
                    type: 'pie',
                    name: 'Number of Requests',
                    innerSize: '50%',
                    data,
                },
            ],
            legend: legendBaseProps,
            responsive: {
                rules: [
                    {
                        condition: {
                            maxWidth: 300,
                        },
                        chartOptions: {
                            legend: {
                                align: 'center',
                                verticalAlign: 'bottom',
                                layout: 'horizontal',
                                itemMarginTop: 5,
                                x: 0,
                                itemStyle: {
                                    fontSize: '12px',
                                },
                                padding: 0,
                                labelFormatter() {
                                    const { name, y } = this;

                                    return labelFormatter(name, y);
                                },
                            },
                            title: {
                                text: 'Number Older Than',
                                style: {
                                    fontSize: '16px',
                                },
                            },
                            chart: {
                                height: 260,
                            },
                            plotOptions: {
                                pie: {
                                    center: ['50%', '100%'],
                                    size: '170%',
                                },
                            },
                        },
                    },
                    {
                        condition: {
                            minWidth: 301,
                        },
                        chartOptions: {
                            legend: {
                                align: 'left',
                                verticalAlign: 'middle',
                                layout: 'vertical',
                                itemMarginTop: 10,
                                x: 10,
                                y: 10,
                                labelFormatter() {
                                    const { name, y } = this;

                                    return labelFormatter(name, y);
                                },
                            },
                            title: {
                                style: {
                                    fontSize: '14px',
                                },
                            },
                            plotOptions: {
                                pie: {
                                    size: '130%',
                                },
                            },
                        },
                    },
                    {
                        condition: {
                            minWidth: 340,
                        },
                        chartOptions: {
                            plotOptions: {
                                pie: {
                                    size: '180%',
                                },
                            },
                            title: {
                                style: {
                                    fontSize: '18px',
                                },
                            },
                        },
                    },
                    {
                        condition: {
                            minWidth: 400,
                        },
                        chartOptions: {
                            plotOptions: {
                                pie: {
                                    size: '200%',
                                },
                            },
                        },
                    },
                ],
            },
            exporting: {
                ...exportingBaseProps,
            },
        });

        const { points } = chart.series[0];

        handleChartInteractionInitialize(
            requestsOlderThan,
            points,
            numberOfRequestsVisualization,
            `${requestsOlderThan} days`
        );

        return chart;
    };

    useRenderChart(numberOfRequestsVisualization, data, renderChart, requestsOlderThan);

    return <div id={numberOfRequestsVisualization} />;
};

NumberOfRequests.propTypes = {
    data: PropTypes.array.isRequired,
};

import { createSelector } from 'reselect';

import { navItems } from './constants';
import { getDocumentReviewPath } from '../selectors';

const getDocumentVersions = (state) => state.revisions.get('documentVersions');
const getCurrentProjectVersion = (state) => state.revisions.get('currentProjectVersion');
const getCurrentProjectAuditId = (state) => state.revisions.get('currentProjectAuditId');
const getProjectAudits = (state) => state.revisions.get('projectAudits');
const getHasMoreProjectAudits = (state) => state.revisions.get('hasMore');

export const getDocumentVersionsJS = createSelector(
    [getDocumentVersions],
    (rawDocumentVersions) => {
        return rawDocumentVersions.toJS();
    }
);

export const getCurrentProjectVersionJS = createSelector(
    [getCurrentProjectVersion],
    (rawCurrentDocumentVersion) => {
        if (rawCurrentDocumentVersion) {
            return rawCurrentDocumentVersion.toJS();
        }
    }
);

export const getCurrentDocumentVersionJS = createSelector(
    [getDocumentVersionsJS, getCurrentProjectAuditId],
    (documentVersions, projectAuditId) => {
        return documentVersions.find((version) => version.project_audit_id === projectAuditId);
    }
);

const getProjectAuditsJS = createSelector([getProjectAudits], (rawProjectAudits) => {
    return rawProjectAudits.toJS();
});

export const getProjectAuditsDisplayList = createSelector(
    [getProjectAuditsJS, getHasMoreProjectAudits],
    (projectAudits, hasMore) => {
        if (hasMore) {
            return projectAudits.slice(0, -1);
        }
        return projectAudits;
    }
);

export const getLastProjectAudit = createSelector([getProjectAuditsJS], (projectAudits) => {
    if (projectAudits.length > 0) {
        return projectAudits[projectAudits.length - 1];
    }
});

export const getRevisionsPath = createSelector([getDocumentReviewPath], (reviewPath) => {
    return `${reviewPath}/revisions`;
});

export const getNavItems = createSelector([getRevisionsPath], (revisionsPath) => {
    return navItems.map((item) => {
        return {
            ...item,
            route: `${revisionsPath}${item.path}`,
        };
    });
});

export const getSelectedRoute = createSelector(
    [getNavItems, (state, props) => props.location],
    (navs, location) => {
        const { pathname } = location;

        return (navs.find((item) => pathname.match(new RegExp(`^${item.route}`))) || navs[0]).route;
    }
);

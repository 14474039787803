import { Button, ButtonGroup } from '@og-pro/ui';
import {
    Crop as CropIcon,
    HighlightAlt as HighlightAltIcon,
    PanToolAlt as PanToolAltIcon,
} from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const imageManipulationModes = {
    PAN: 'pointer',
    SELECT: 'crosshair',
};

export const PanNCropControls = ({ disabled, DWObject }) => {
    const [imageManipulationMode, setImageManipulationMode] = useState(imageManipulationModes.PAN);
    const [selectedArea, setSelectedArea] = useState(null);

    useEffect(() => {
        if (DWObject && DWObject.Viewer) {
            DWObject.Viewer.cursor = imageManipulationMode;
        }
    }, [DWObject, imageManipulationMode]);

    useEffect(() => {
        const handlePageAreaSelected = (index, rect) => {
            setSelectedArea(rect[0]);
        };

        const handlePageAreaUnselected = () => {
            setSelectedArea(null);
        };
        if (DWObject && DWObject.Viewer) {
            DWObject.Viewer.on('pageAreaSelected', handlePageAreaSelected);
            DWObject.Viewer.on('pageAreaUnselected', handlePageAreaUnselected);
        }
        return () => {
            if (DWObject && DWObject.Viewer) {
                DWObject.Viewer.off('pageAreaSelected', handlePageAreaSelected);
                DWObject.Viewer.off('pageAreaUnselected', handlePageAreaUnselected);
            }
        };
    }, [DWObject]);

    return (
        <>
            <ButtonGroup disabled={disabled} variant="outlined">
                <Button
                    onClick={() => setImageManipulationMode(imageManipulationModes.PAN)}
                    qaTag="scannerControls-modePan"
                    variant={
                        imageManipulationMode === imageManipulationModes.PAN
                            ? 'contained'
                            : 'outlined'
                    }
                >
                    <PanToolAltIcon />
                </Button>
                <Button
                    onClick={() => setImageManipulationMode(imageManipulationModes.SELECT)}
                    qaTag="scannerControls-modeSelect"
                    variant={
                        imageManipulationMode === imageManipulationModes.SELECT
                            ? 'contained'
                            : 'outlined'
                    }
                >
                    <HighlightAltIcon />
                </Button>
            </ButtonGroup>
            <ButtonGroup variant="outlined">
                <Button
                    disabled={disabled || !selectedArea}
                    onClick={() => {
                        DWObject.CropAsync(
                            selectedArea.index,
                            selectedArea.x,
                            selectedArea.y,
                            selectedArea.x + selectedArea.width,
                            selectedArea.y + selectedArea.height
                        );
                    }}
                    qaTag="scannerControls-scanningCrop"
                >
                    <CropIcon />
                </Button>
            </ButtonGroup>
        </>
    );
};

PanNCropControls.propTypes = {
    disabled: PropTypes.bool,
    DWObject: PropTypes.object,
};

import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';

import { getContractReviewJS } from '../selectors';
import connectData from '../../../ConnectData';
import { loadContractReview } from '../../../../actions/contracts';
import { LoadingError, LoadingSpinner } from '../../../../components';

function fetchData(getState, dispatch, location, params) {
    const contractId = Number.parseInt(params.contractId, 10);
    const contractReviewId = Number.parseInt(params.contractReviewId, 10);
    return dispatch(loadContractReview(contractId, contractReviewId));
}

const ConnectedContractReviewNav = () => {
    const { contractReviewId } = useParams();
    const contractReview = useSelector((state) =>
        getContractReviewJS(state, { contractReviewId: parseInt(contractReviewId, 10) })
    );
    const loadError = useSelector((state) => state.contracts.get('loadContractReviewError'));
    const loading = useSelector((state) => state.contracts.get('loadingContractReview'));

    if (loading) {
        return <LoadingSpinner />;
    }

    if (loadError || !contractReview) {
        return <LoadingError error={loadError} />;
    }

    return <Outlet />;
};

export const ContractReviewNav = connectData(fetchData)(ConnectedContractReviewNav);

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { qaTagPageName } from '../../../constants';
import { Button } from '../../../../../../components';

export const FormMenu = ({
    cancelHandler,
    disabled,
    pristine,
    showSubmitFailed,
    submitHandler,
}) => {
    const styles = require('./index.scss');
    return (
        <nav className={styles.navButtons}>
            {showSubmitFailed && (
                <div className="text-danger">
                    <i className="fa fa-exclamation-triangle" /> Please fix form errors before
                    saving
                </div>
            )}
            <Button
                aria-label="Cancel"
                className={classNames(styles.navButton)}
                disabled={disabled}
                onClick={cancelHandler}
                qaTag={`${qaTagPageName}-cancel`}
            >
                Cancel
            </Button>
            <Button
                aria-label="Save Changes"
                bsStyle="primary"
                className={classNames(styles.navButton)}
                disabled={pristine || disabled}
                onClick={submitHandler}
                qaTag={`${qaTagPageName}-saveChanges`}
            >
                Save Changes
            </Button>
        </nav>
    );
};

FormMenu.propTypes = {
    cancelHandler: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    pristine: PropTypes.bool.isRequired,
    showSubmitFailed: PropTypes.bool.isRequired,
    submitHandler: PropTypes.func.isRequired,
};

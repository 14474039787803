import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Checkbox,
    Chip,
    FormControlLabel,
    FormGroup,
    Typography,
} from '@og-pro/ui';
import { ChevronRight } from '@mui/icons-material';
import { tokens } from '@opengov/capital-style';

import {
    accordionDetailsStyles,
    chipStyles,
    innerAccordionStyles,
    innerAccordionSummaryStyles,
} from './sharedStyles';

const onVendorListChange = (formData, setFormData, id) => {
    const currentIndex = formData.vendorLists.indexOf(id);
    const updatedVendorLists = [...formData.vendorLists];
    if (currentIndex === -1) {
        updatedVendorLists.push(id);
    } else {
        updatedVendorLists.splice(currentIndex, 1);
    }

    setFormData({
        ...formData,
        vendorLists: updatedVendorLists,
    });
};

export const VendorListsAccordion = ({ formData, isPublic, setFormData }) => {
    const vendorLists = useSelector((state) => state.vendorList.get('lists').toJS());

    const publicVendorLists = vendorLists.filter((list) => list.isPublic);
    const privateVendorLists = vendorLists.filter((list) => !list.isPublic);

    const renderPublicVendorLists = () => (
        <Box pb={1} px={1}>
            {vendorLists.map(({ name, id }) => (
                <FormGroup key={id}>
                    <FormControlLabel
                        checked={formData.vendorLists.indexOf(id) > -1}
                        control={<Checkbox name={name} size="small" />}
                        label={name}
                        onChange={() => onVendorListChange(formData, setFormData, id)}
                        sx={{ marginBottom: '0' }}
                    />
                </FormGroup>
            ))}
        </Box>
    );

    const renderVendorLists = () => (
        <>
            <Accordion sx={innerAccordionStyles}>
                <AccordionSummary expandIcon={<ChevronRight />} sx={innerAccordionSummaryStyles}>
                    <Box display="flex" gap={2}>
                        <Typography
                            alignContent="center"
                            color={tokens.colors.colorGray700}
                            variant="h5"
                        >
                            Public Lists
                        </Typography>
                        <Chip
                            label={
                                formData.vendorLists?.filter((vendorListId) =>
                                    publicVendorLists.some(
                                        (publicVendorList) => publicVendorList.id === vendorListId
                                    )
                                ).length || 0
                            }
                            size="extraSmall"
                            sx={chipStyles}
                        />
                    </Box>
                </AccordionSummary>
                <AccordionDetails sx={accordionDetailsStyles}>
                    {publicVendorLists.map(({ name, id }) => (
                        <FormGroup key={id}>
                            <FormControlLabel
                                checked={formData.vendorLists.indexOf(id) > -1}
                                control={<Checkbox name={id} size="small" />}
                                label={name}
                                onChange={() => onVendorListChange(formData, setFormData, id)}
                                sx={{ marginBottom: '0' }}
                            />
                        </FormGroup>
                    ))}
                </AccordionDetails>
            </Accordion>
            <Accordion sx={innerAccordionStyles}>
                <AccordionSummary expandIcon={<ChevronRight />} sx={innerAccordionSummaryStyles}>
                    <Box display="flex" gap={2}>
                        <Typography
                            alignContent="center"
                            color={tokens.colors.colorGray700}
                            variant="h5"
                        >
                            Private Lists
                        </Typography>
                        <Chip
                            label={
                                formData.vendorLists.filter((vendorListId) =>
                                    privateVendorLists.some(
                                        (publicVendorList) => publicVendorList.id === vendorListId
                                    )
                                ).length || 0
                            }
                            size="extraSmall"
                            sx={chipStyles}
                        />
                    </Box>
                </AccordionSummary>
                <AccordionDetails sx={accordionDetailsStyles}>
                    {privateVendorLists.map(({ name, id }) => (
                        <FormGroup key={id}>
                            <FormControlLabel
                                checked={formData.vendorLists.indexOf(id) > -1}
                                control={<Checkbox name={id} size="small" />}
                                label={name}
                                onChange={() => onVendorListChange(formData, setFormData, id)}
                                sx={{ marginBottom: '0' }}
                            />
                        </FormGroup>
                    ))}
                </AccordionDetails>
            </Accordion>
        </>
    );

    return (
        <AccordionDetails sx={accordionDetailsStyles}>
            {isPublic ? renderPublicVendorLists() : renderVendorLists()}
        </AccordionDetails>
    );
};

VendorListsAccordion.propTypes = {
    formData: PropTypes.object.isRequired,
    isPublic: PropTypes.bool,
    setFormData: PropTypes.func.isRequired,
};

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Alert as AlertBS } from 'react-bootstrap';

export class Alert extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const { className, ...props } = this.props;

        return (
            <AlertBS
                bsStyle="warning"
                className={classnames(this.styles.alert, className)}
                {...props}
            />
        );
    }
}

import { get } from 'lodash';
import { createSelector } from 'reselect';

import { getUserJS } from '../../selectors';
import {
    getAgreementsJS,
    getEvaluationAuditData,
    mustAcceptProposalViewerAgreement,
} from '../selectors';

const getProposalsList = (state) => state.govProposals.get('proposals');

export const getProposalsListJS = createSelector(
    [getProposalsList, getEvaluationAuditData],
    (proposals, evaluationAudit) => {
        if (evaluationAudit) {
            return evaluationAudit.scoredProposals;
        }
        return proposals.toJS();
    }
);

export const getProposalViewerAcceptanceDate = createSelector(
    [getAgreementsJS, getUserJS, mustAcceptProposalViewerAgreement],
    (agreements, user, mustAcceptAgreement) => {
        if (mustAcceptAgreement || !agreements) {
            return null;
        }
        const foundAgreement = agreements.find((agreement) => {
            return agreement.user_id === user.id && agreement.accepted;
        });
        return get(foundAgreement, 'acceptedAt');
    }
);

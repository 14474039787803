import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormControl } from 'react-bootstrap';

import { QuestionPrompt } from '../../SharedDisplay';

export class CustomVariableDisplay extends PureComponent {
    static propTypes = {
        isDocx: PropTypes.bool,
        title: PropTypes.string,
    };

    static defaultProps = {
        isDocx: false,
        title: 'Custom Variable Name',
    };

    render() {
        const { isDocx } = this.props;

        if (isDocx) {
            return <QuestionPrompt {...this.props} />;
        }

        return (
            <QuestionPrompt {...this.props}>
                <FormControl disabled placeholder="Enter variable value" type="text" />
            </QuestionPrompt>
        );
    }
}

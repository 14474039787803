import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { LoadingSpinner, FormError } from '../components';

export function extendSimpleWizardForm() {
    return function SimpleWizardFormHOC(FormComponent) {
        return class BaseSimpleWizardForm extends Component {
            static propTypes = {
                initialized: PropTypes.bool.isRequired,
                loading: PropTypes.bool.isRequired,
                loadError: PropTypes.string,
                project: PropTypes.object,
            };

            render() {
                const { initialized, loading, loadError, project } = this.props;

                const loadingData = loading;
                const error = loadError;

                /**
                 * We must wait for all data to be returned before rendering
                 *
                 * We also must wait for the form to be initialized,
                 * otherwise the form will be rendered and then the
                 * deserializer will be run and re-render the page. This
                 * has the negative effect of not emptying the form, so that it
                 * contains the previous values. This can effect any logic that
                 * compares the prior form state to the current form state.
                 */
                if ((loadingData || !initialized) && !error) {
                    return <LoadingSpinner />;
                }

                if (error || !project) {
                    return <FormError error={error} />;
                }

                return <FormComponent {...this.props} />;
            }
        };
    };
}

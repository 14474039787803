import { ReduxFormSelect } from '@og-pro/ui';
import React from 'react';
import { Field, getFormValues } from 'redux-form';

import { useSelector } from 'react-redux';

import {
    createCustomFieldFormName,
    customFieldFields,
    instructionsModes,
} from '@og-pro/shared-config/customFormService/customField';

const { NAME, INSTRUCTIONS_MODE, INSTRUCTIONS_TEXT } = customFieldFields;
const { TOOLTIP, DESCRIPTION } = instructionsModes;
export const DropdownPreview = () => {
    const formValues =
        useSelector((state) => getFormValues(createCustomFieldFormName)(state)) || {};

    const instructionsText = formValues[INSTRUCTIONS_TEXT] || '';

    return (
        <Field
            component={ReduxFormSelect}
            description={
                formValues[INSTRUCTIONS_MODE] === DESCRIPTION ? instructionsText : undefined
            }
            disabled
            label={formValues[NAME] || 'Your title will go here'}
            name="dropdown_PREVIEW"
            placeholder="Select"
            sx={{
                width: '100%',
            }}
            tooltip={formValues[INSTRUCTIONS_MODE] === TOOLTIP ? instructionsText : undefined}
        />
    );
};

const ARRAY_ERROR_KEY = '_error';

export function arrayError(errorMessage) {
    return { [ARRAY_ERROR_KEY]: errorMessage };
}

export function mutateArrayError(errorObject, errorMessage) {
    errorObject[ARRAY_ERROR_KEY] = errorMessage;
    return errorObject;
}

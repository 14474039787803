import { showNotification } from './notification';

export const NEXT_PAGE = 'govSignUp/NEXT_PAGE';
export const PREV_PAGE = 'govSignUp/PREV_PAGE';

export const DOMAIN_MATCH = 'govSignUp/DOMAIN_MATCH';
export const DOMAIN_MATCH_SUCCESS = 'govSignUp/DOMAIN_MATCH_SUCCESS';
export const DOMAIN_MATCH_FAIL = 'govSignUp/DOMAIN_MATCH_FAIL';

export const SET_GOVT_INVITE_EMAIL = 'govSignUp/SET_GOVT_INVITE_EMAIL';

export const GOVT_INVITE = 'govSignUp/GOVT_INVITE';
export const GOVT_INVITE_SUCCESS = 'govSignUp/GOVT_INVITE_SUCCESS';
export const GOVT_INVITE_FAIL = 'govSignUp/GOVT_INVITE_FAIL';

export function domainMatch(domain) {
    return (dispatch, getState, client) => {
        dispatch({ type: DOMAIN_MATCH });

        return client
            .get(`/organization?domain=${domain}`)
            .then((result) => {
                const isMatch = !!result.length;

                dispatch({
                    type: DOMAIN_MATCH_SUCCESS,
                    result: result[0],
                    isMatch,
                });
            })
            .catch((error) => {
                dispatch({ type: DOMAIN_MATCH_FAIL, error });
                dispatch(showNotification(error.message, { type: 'danger' }));
            });
    };
}

export function govtInvite(email, orgId) {
    return (dispatch, getState, client) => {
        dispatch({ type: GOVT_INVITE });

        return client
            .post('/user/self-invite', {
                data: {
                    email,
                    orgId,
                    isVendor: false,
                },
            })
            .then(() => {
                dispatch({
                    type: GOVT_INVITE_SUCCESS,
                });
            })
            .catch((error) => {
                dispatch({ type: GOVT_INVITE_FAIL, error });
                dispatch(showNotification(error.message, { type: 'danger' }));
            });
    };
}

export function setGovtInviteEmail(email) {
    return {
        type: SET_GOVT_INVITE_EMAIL,
        email,
    };
}

import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { formValueSelector } from 'redux-form';

import { fieldNames, form } from '../../../../forms/constants';
import { TemplateDocumentBuilderNav } from '../../components/DocumentBuilderNav';
import { TemplateSectionsBackNextButtons } from '../../components/BackNextButtons';
import { TemplateSectionHeader } from '../../components/SectionHeader';
import { SDv2EditorLayout } from '../../../../../../../components/SDv2/Layout';
import { TemplateEditV2AddSectionMenu } from '../../components/ManageSections/AddSectionMenu';
import { TemplateEditV2AddSection } from '../../components/ManageSections/AddSection';
import { TemplateEditV2ManageSectionsActionHeader } from '../../components/ManageSections/ActionHeader';
import { TemplateEditV2FunctionsContext } from '../../../context';
import { TemplateEditV2DocumentBuilderSDv2SectionsAdapter } from './SDv2SectionsAdapter';
import { NavScrollContainer } from '../../../../../../../components/SDv2';

const selector = formValueSelector(form);
const { PROJECT_SECTIONS } = fieldNames;

export const TemplateEditV2DocumentBuilder = () => {
    const { addingSectionData, disabled, setAddingSection, unsetAddingSection } = useContext(
        TemplateEditV2FunctionsContext
    );
    const projectSections = useSelector((state) => {
        return selector(state, PROJECT_SECTIONS);
    });

    // if we are adding a section, don't show content below
    const { active } = addingSectionData;

    return (
        <SDv2EditorLayout
            content={active ? null : <TemplateEditV2DocumentBuilderSDv2SectionsAdapter />}
            footer={<TemplateSectionsBackNextButtons />}
            header={
                active ? (
                    <TemplateEditV2AddSection
                        addingSectionData={addingSectionData}
                        unsetAddingSection={unsetAddingSection}
                    />
                ) : (
                    <TemplateSectionHeader />
                )
            }
            navigation={
                <>
                    <TemplateEditV2ManageSectionsActionHeader disabled={disabled} />
                    <NavScrollContainer>
                        <TemplateDocumentBuilderNav />
                        <TemplateEditV2AddSectionMenu setAddingSection={setAddingSection} />
                    </NavScrollContainer>
                </>
            }
            /* TODO we may need to be a bit more dynamic with showing the shared toolbar.
             *
             * Ex: We really only want it to show for sections that have or will have text area instances
             * configured to use the shared toolbar. I do think this will be most of them but we'll have to keep
             * an eye on it.
             */
            showSharedTextareaToolbar={!active && projectSections?.length > 0}
        />
    );
};

import { Box } from '@og-pro/ui';
import React from 'react';

// used to prevent AgGrid from adding display: flex to the cell
export const TextCellRenderer = (params) =>
    params.data?.expanded ? (
        <Box minWidth={0} overflow="hidden" pt={0.5} textOverflow="ellipsis">
            {params.value}
        </Box>
    ) : (
        <Box alignItems="center" display="flex" height="100%">
            <Box minWidth={0} overflow="hidden" textOverflow="ellipsis">
                {params.value}
            </Box>
        </Box>
    );

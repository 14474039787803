import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import { form, SORT_FIELD } from './constants';
import { SearchSelect } from '../../../../../../components';

const formConfig = {
    form,
};

// @reduxForm
class ConnectedLibrarySortForm extends Component {
    static propTypes = {
        onSelectChangeHandler: PropTypes.func.isRequired,
        options: PropTypes.array.isRequired,
    };

    render() {
        const { onSelectChangeHandler, options } = this.props;

        return (
            <Field
                aria-label="Library Sort By"
                component={SearchSelect}
                isSearchable={false}
                name={SORT_FIELD}
                onChangeWithOption={onSelectChangeHandler}
                options={options}
            />
        );
    }
}

export const LibrarySortForm = reduxForm(formConfig)(ConnectedLibrarySortForm);

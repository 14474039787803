import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getProjectBudget } from '../selectors';
import { currencyFormatter } from '../../../../../helpers';
import { getReverseAuctionProgressBarStyle } from '../../../../../helpers/progressBars';
import { ProgressBar } from '../../../../../components';

export const SummaryInfoHeader = ({ auctionMaxFractionDigits, currentAuctionTotal }) => {
    const projectBudget = useSelector((state) => getProjectBudget(state));

    const styles = require('../index.scss');
    const formatterOpts = {
        maximumFractionDigits: auctionMaxFractionDigits,
        useSameMinAndMax: true,
    };

    return (
        <div className={`row ${styles.summaryContainer}`}>
            <div className={`col-lg-9 ${styles.summaryTitleText}`}>Line Item Summary</div>
            <div className={`col-sm-12 col-md-10 col-lg-8 ${styles.progressBarSection}`}>
                <div className="row">
                    <div className="col-xs-6 col-sm-4">
                        <div className={styles.total}>Total (Lowest Bidder per Line Item)</div>
                        <div className={styles.currentTotalText}>
                            {currencyFormatter({ value: currentAuctionTotal }, formatterOpts)}
                        </div>
                    </div>
                    <div className={`col-xs-6 col-sm-4 col-sm-offset-4 ${styles.budgetedAmount}`}>
                        <h5>Budgeted Amount</h5>
                        <div className={styles.budgetedAmountText}>
                            {currencyFormatter({ value: projectBudget }, formatterOpts)}
                        </div>
                    </div>
                </div>
                <div className={`row ${styles.progressBar}`}>
                    <div className="col-sm-12">
                        <ProgressBar
                            bsStyle={getReverseAuctionProgressBarStyle(
                                currentAuctionTotal,
                                projectBudget
                            )}
                            max={projectBudget}
                            min={0}
                            now={currentAuctionTotal}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

SummaryInfoHeader.propTypes = {
    auctionMaxFractionDigits: PropTypes.number.isRequired,
    currentAuctionTotal: PropTypes.number.isRequired,
};

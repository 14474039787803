import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { CONFIRMATION_TEXT } from '../constants';
import { Checkbox } from '../../../..';

export class ConfirmationResponseForm extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        input: PropTypes.object.isRequired,
        isOGThemeEnabledForComponents: PropTypes.bool,
        meta: PropTypes.object.isRequired,
        showValidation: PropTypes.bool,
    };

    static defaultProps = {
        disabled: false,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const { disabled, input, isOGThemeEnabledForComponents, meta, showValidation } = this.props;

        return (
            <Checkbox
                className={this.styles.checkbox}
                disabled={disabled}
                input={input}
                meta={meta}
                showValidation={showValidation}
                text={CONFIRMATION_TEXT}
                useOpenGovStyle={isOGThemeEnabledForComponents}
            />
        );
    }
}

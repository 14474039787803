import { fromJS } from 'immutable';
import { isEmpty } from 'lodash';

import * as authActions from '../actions/auth';
import * as accountPersonalActions from '../actions/account/personal';
import * as accountActivateActions from '../actions/account/activate';
import * as adminActions from '../actions/admin';
import * as adminGovernmentActions from '../actions/adminGovernment';
import * as adminVendorActions from '../actions/adminVendor';
import * as publicProjectActions from '../actions/publicProject';
import * as accountSubscriptionsActions from '../actions/account/subscriptions';

const initialState = fromJS({
    cancelingRequestToJoin: false,
    cancelRequestToJoinError: null,
    lastFilter: null,
    loaded: false,
    loginWithOpenGov: false,
    requestingToJoin: false,
    requestToJoinAdmins: null,
    requestToJoinComplete: false,
    requestToJoinError: null,
    requestToJoinPendingUser: null,
    resentActivationEmail: null,
    showLoginModal: false,
    showManageSavedFilterModal: false,
    vendorSigningUp: false,
    vendorSignUpComplete: false,
    vendorSignUpEmail: null,
    vendorSignUpError: null,
});

function governmentSubscriptionsReducer(state, action) {
    if (state.get('id') !== action.subscriptionId) {
        return state;
    }

    switch (action.type) {
        case accountSubscriptionsActions.UPDATE:
            return state.merge(
                fromJS({
                    updating: true,
                    updateError: null,
                })
            );
        case accountSubscriptionsActions.UPDATE_SUCCESS:
            return state.set('updating', false);
        case accountSubscriptionsActions.UPDATE_FAIL:
            return state.merge(
                fromJS({
                    updating: false,
                    updateError: action.error && action.error.message,
                })
            );
        case accountSubscriptionsActions.SUBSCRIBE_SUCCESS:
            return fromJS(action.result);
        default:
            return state;
    }
}

export default function authReducer(state = initialState, action = {}) {
    switch (action.type) {
        case authActions.LOAD:
            return state.delete('loadError').set('loading', true);
        case authActions.LOAD_SUCCESS:
            return state.merge(
                fromJS({
                    loading: false,
                    loaded: true,
                    user: isEmpty(action.result) ? null : action.result,
                })
            );
        case authActions.LOAD_FAIL:
            return state.merge(
                fromJS({
                    loading: false,
                    loaded: false,
                    loadError: action.error,
                })
            );
        case authActions.LOGIN:
            return state.set('loggingIn', true);
        case authActions.LOGIN_SUCCESS:
            return state.merge(
                fromJS({
                    loggingIn: false,
                    user: action.result,
                    loginError: null,
                })
            );
        case authActions.LOGIN_FAIL:
            return state.merge(
                fromJS({
                    loggingIn: false,
                    // In the case of session timeout, we want to preserve the user if login fails to prevent loss of unsaved data
                    ...(!action.isSessionTimeout && { user: null }),
                    loginError: action.error,
                })
            );
        case authActions.ROUTE_LOGIN_EMAIL:
            return state.merge(
                fromJS({
                    loggingIn: false,
                    loginError: null,
                    loginWithOpenGov: action.loginWithOpenGov,
                })
            );
        case authActions.RESET_PASSWORD:
            return state.merge(
                fromJS({
                    resettingPassword: true,
                })
            );
        case authActions.RESET_PASSWORD_SUCCESS:
            return state.merge(
                fromJS({
                    resettingPassword: false,
                    resetPasswordError: null,
                })
            );
        case authActions.RESET_PASSWORD_FAIL:
            return state.merge(
                fromJS({
                    resettingPassword: false,
                    resetPasswordError: action.error,
                })
            );
        case authActions.SET_PASSWORD:
            return state.merge(
                fromJS({
                    settingPassword: true,
                })
            );
        case authActions.SET_PASSWORD_SUCCESS:
            return state.merge(
                fromJS({
                    settingPassword: false,
                })
            );
        case authActions.SET_PASSWORD_FAIL:
            return state.merge(
                fromJS({
                    settingPassword: false,
                    setPasswordError: action.error,
                })
            );
        case authActions.TOKEN_LOGIN:
            return state.set('tokenLoggingIn', true);
        case authActions.TOKEN_LOGIN_SUCCESS:
            return state.delete('tokenLoginError').merge(
                fromJS({
                    tokenLoggingIn: false,
                    user: action.result,
                })
            );
        case authActions.TOKEN_LOGIN_FAIL:
            return state.merge(
                fromJS({
                    tokenLoggingIn: false,
                    user: null,
                    tokenLoginError: action.error,
                })
            );
        case authActions.LOGIN_FAIL_REMOVE:
            return state.delete('loginError').delete('resetError').delete('setPasswordError');
        case authActions.VENDOR_SIGNUP:
            return state.merge(
                fromJS({
                    vendorSignUpError: null,
                    vendorSigningUp: true,
                })
            );
        case authActions.VENDOR_SIGNUP_SUCCESS:
            return state.merge(
                fromJS({
                    vendorSigningUp: false,
                    vendorSignUpComplete: true,
                    vendorSignUpEmail: action.email,
                })
            );
        case authActions.VENDOR_SIGNUP_FAIL:
            return state.merge(
                fromJS({
                    vendorSigningUp: false,
                    vendorSignUpError: action.error && action.error.message,
                })
            );
        case authActions.LOGOUT:
            return state.delete('logoutError').set('loggingOut', true);
        case authActions.LOGOUT_SUCCESS:
        case authActions.RESET_AUTH:
            return initialState.set('loaded', true);
        case authActions.LOGOUT_FAIL:
            return state.merge(
                fromJS({
                    loggingOut: false,
                    logoutError: action.error && action.error.message,
                })
            );
        case authActions.SHOW_LOGIN_MODAL:
            return state.merge(
                fromJS({
                    showLoginModal: true,
                    showLoginModalMessage: action.message,
                    showLoginModalLoginHandler: action.loginHandler,
                    showLoginModalOpts: fromJS(action.opts),
                    loginError: null,
                })
            );
        case authActions.HIDE_LOGIN_MODAL:
            return state.merge(
                fromJS({
                    showLoginModal: false,
                    showLoginModalMessage: null,
                    showLoginModalLoginHandler: null,
                    showLoginModalOpts: null,
                })
            );
        case authActions.ACCOUNT_ACTIVATION_RESENT:
            return state.merge(
                fromJS({
                    resentActivationEmail: action.email,
                    vendorSigningUp: false,
                })
            );
        case authActions.REQUEST_TO_JOIN_PENDING:
            return state.merge(
                fromJS({
                    requestToJoinPendingUser: fromJS(action.user),
                    vendorSigningUp: false,
                })
            );
        case authActions.FOUND_ORGANIZATION_TO_JOIN:
            return state.merge(
                fromJS({
                    signUpOrganizationMatch: fromJS(action.organization),
                    vendorSignUpEmail: action.email,
                    vendorSigningUp: false,
                })
            );
        case authActions.REQUEST_TO_JOIN:
            return state.merge(
                fromJS({
                    requestingToJoin: true,
                    requestToJoinError: null,
                })
            );
        case authActions.REQUEST_TO_JOIN_SUCCESS:
            return state.merge(
                fromJS({
                    requestingToJoin: false,
                    requestToJoinAdmins: fromJS(action.result.admins),
                    requestToJoinComplete: true,
                })
            );
        case authActions.REQUEST_TO_JOIN_FAIL:
            return state.merge(
                fromJS({
                    requestingToJoin: false,
                    requestToJoinError: action.error && action.error.message,
                })
            );
        case authActions.CANCEL_REQUEST_TO_JOIN:
            return state.merge(
                fromJS({
                    cancelingRequestToJoin: true,
                    cancelRequestToJoinError: null,
                })
            );
        case authActions.CANCEL_REQUEST_TO_JOIN_FAIL:
            return state.merge(
                fromJS({
                    cancelingRequestToJoin: false,
                    cancelRequestToJoinError: action.error && action.error.message,
                })
            );
        case accountPersonalActions.SAVE_SUCCESS:
            return state.merge(
                fromJS({
                    user: action.result,
                })
            );
        case accountPersonalActions.UPLOAD_AVATAR:
        case accountPersonalActions.UPLOAD_AVATAR_SUCCESS:
            return state.setIn(['user', 'avatarUrl'], action.avatarUrl);
        case adminActions.UPLOAD_LOGO:
        case adminActions.UPLOAD_LOGO_SUCCESS:
            return state.setIn(['user', 'organization', 'logo'], action.logoUrl);
        case adminActions.UPDATE_ORG_SUCCESS:
            return state
                .mergeIn(['user', 'organization'], fromJS(action.result))
                .mergeIn(['user', 'vendor'], fromJS(action.result.vendor));
        case adminVendorActions.UPDATE_CERTIFICATION_SUCCESS:
            return state.updateIn(['user', 'vendor', 'certifications'], () =>
                fromJS(action.result)
            );
        case adminVendorActions.CREATE_ATTACHMENT_SUCCESS:
            return state.setIn(
                ['user', 'vendor', 'attachments'],
                state.getIn(['user', 'vendor', 'attachments']).push(fromJS(action.result))
            );
        case adminVendorActions.DELETE_ATTACHMENT_SUCCESS:
            return state.setIn(
                ['user', 'vendor', 'attachments'],
                state
                    .getIn(['user', 'vendor', 'attachments'])
                    .filter((attach) => attach.get('id') !== action.attachmentId)
            );
        case adminActions.ADD_DEPARTMENT_SUCCESS:
        case adminActions.UPDATE_DEPARTMENT_SUCCESS:
        case adminActions.DELETE_DEPARTMENT_SUCCESS:
            return state.updateIn(['user', 'organization', 'departments'], () => {
                return fromJS(action.result);
            });
        case accountActivateActions.ACTIVATE_SUCCESS:
            return state.merge(
                fromJS({
                    user: action.result,
                })
            );
        case publicProjectActions.GOV_SUBSCRIBE_SUCCESS:
            return state.setIn(['user', 'governmentSubscriptions'], fromJS(action.result));
        case authActions.CREATE_FILTER:
            return state.merge(
                fromJS({
                    createFilterError: null,
                    creatingFilter: true,
                })
            );
        case authActions.CREATE_FILTER_FAIL: {
            return state.merge(
                fromJS({
                    createFilterError: action.error && action.error.message,
                    creatingFilter: false,
                })
            );
        }
        case authActions.CREATE_FILTER_SUCCESS: {
            const createdFilter = action.result;

            const mergeState = {
                creatingFilter: false,
            };

            if (!state.get('savedFilterBeingManaged')) {
                mergeState.savedFilterBeingManaged = createdFilter;
            }

            return state
                .setIn(
                    ['user', 'filters'],
                    state.getIn(['user', 'filters']).push(fromJS(createdFilter))
                )
                .merge(fromJS(mergeState));
        }
        case authActions.DELETE_FILTER:
            return state.merge(
                fromJS({
                    manageFilterError: null,
                    managingFilter: true,
                })
            );
        case authActions.DELETE_FILTER_FAIL: {
            return state.merge(
                fromJS({
                    manageFilterError: action.error && action.error.message,
                    managingFilter: false,
                })
            );
        }
        case authActions.DELETE_FILTER_SUCCESS: {
            const deletedFilter = action.result;

            const x = state
                .setIn(
                    ['user', 'filters'],
                    state
                        .getIn(['user', 'filters'])
                        .filter((filter) => filter.get('id') !== deletedFilter.id)
                )
                .merge(
                    fromJS({
                        managingFilter: false,
                        savedFilterBeingManaged: null,
                        showManageSavedFilterModal: false,
                    })
                );

            return x;
        }
        case authActions.MAKE_FILTER_DEFAULT:
            return state.merge(
                fromJS({
                    manageFilterError: null,
                    managingFilter: true,
                })
            );
        case authActions.MAKE_FILTER_DEFAULT_FAIL: {
            return state.merge(
                fromJS({
                    manageFilterError: action.error && action.error.message,
                    managingFilter: false,
                })
            );
        }
        case authActions.MAKE_FILTER_DEFAULT_SUCCESS: {
            const updatedFilter = fromJS(action.result);

            const newUserFilters = state.getIn(['user', 'filters']).map((filter) => {
                if (filter.get('type') !== updatedFilter.get('type')) {
                    return filter;
                }
                return filter.set('isTypeDefault', filter.get('id') === updatedFilter.get('id'));
            });

            return state.setIn(['user', 'filters'], newUserFilters).merge(
                fromJS({
                    managingFilter: false,
                    savedFilterBeingManaged: updatedFilter,
                })
            );
        }
        case authActions.UPDATE_FILTER:
            return state.merge(
                fromJS({
                    manageFilterError: null,
                    managingFilter: true,
                })
            );
        case authActions.UPDATE_FILTER_FAIL: {
            return state.merge(
                fromJS({
                    manageFilterError: action.error && action.error.message,
                    managingFilter: false,
                })
            );
        }
        case authActions.UPDATE_FILTER_SUCCESS: {
            const updatedFilter = fromJS(action.result);
            const indexInState = state
                .getIn(['user', 'filters'])
                .findIndex((filter) => filter.get('id') === updatedFilter.get('id'));

            return state.setIn(['user', 'filters', indexInState], updatedFilter).merge(
                fromJS({
                    managingFilter: false,
                    savedFilterBeingManaged: updatedFilter,
                })
            );
        }
        case authActions.STORE_LAST_FILTER:
            return state.setIn(['user', 'lastFilter', action.filterType], fromJS(action.filter));
        case authActions.SHOW_MANAGE_SAVED_FILTER_MODAL:
            return state.merge(
                fromJS({
                    savedFilterBeingManaged: fromJS(action.filter),
                    showManageSavedFilterModal: true,
                })
            );
        case authActions.HIDE_MANAGE_SAVED_FILTER_MODAL:
            return state.merge(
                fromJS({
                    savedFilterBeingManaged: null,
                    showManageSavedFilterModal: false,
                })
            );
        case accountSubscriptionsActions.UPDATE:
        case accountSubscriptionsActions.UPDATE_SUCCESS:
        case accountSubscriptionsActions.UPDATE_FAIL:
        case accountSubscriptionsActions.SUBSCRIBE_SUCCESS:
            return state.setIn(
                ['user', 'governmentSubscriptions'],
                state
                    .getIn(['user', 'governmentSubscriptions'])
                    .map((sub) => governmentSubscriptionsReducer(sub, action))
            );
        case accountSubscriptionsActions.DELETE_SUCCESS:
            return state.setIn(
                ['user', 'governmentSubscriptions'],
                state
                    .getIn(['user', 'governmentSubscriptions'])
                    .filter((sub) => sub.get('id') !== action.subscriptionId)
            );
        case adminGovernmentActions.PATCH_STATE_USER_GOVERNMENT_SETTINGS: {
            return state.mergeIn(['user', 'government'], fromJS(action.payload));
        }
        case adminActions.UPDATE_OUTLINE_NUMBERING_SUCCESS: {
            return state.setIn(['user', 'government', 'outlineNumbering'], fromJS(action.result));
        }
        default:
            return state;
    }
}

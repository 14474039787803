export const form = 'reverseAuctionBidForm';

export const BID = 'bid';

export const BID_DENOMINATION_ERROR = 'Bid has more decimal places than the accepted amount';
export const BID_NOT_LOWER_ERROR = 'Bid amount is not lower than current lowest bid';
export const MIN_BID_DECREMENT_ERROR =
    'Bid does not meet or exceed the minimum bid decrement amount';
export const UNKNOWN_ERROR = 'Unknown error encountered';
export const NEW_LOWEST_BID = 'New Lowest Bid';

import React from 'react';
import { Field } from 'redux-form';

import { customTimelineFieldsDict } from '@og-pro/shared-config/timelines';

import { TIMELINE_CONFIG, TIMELINES } from '../../../../../../containers/GovApp/constants';
import { Checkbox } from '../../../../..';
import { PROP_TYPES } from './constants';

const { IS_ATTENDANCE_REQUIRED, IS_PUBLIC } = customTimelineFieldsDict;

export const PublicInput = (props) => {
    const { change, disabled, showValidation, timeline } = props;
    const {
        index,
        isPublic,
        isRequiredField, // Also being used to identify `preProposal` timeline type
        title,
    } = timeline;

    const requiredFieldName = isRequiredField
        ? `${TIMELINE_CONFIG}.${isRequiredField}`
        : `${TIMELINES}.${index}.${IS_ATTENDANCE_REQUIRED}`;

    return (
        <>
            {isRequiredField ? (
                <Field
                    component={Checkbox}
                    disabled
                    input={{
                        value: true,
                        onChange: () => {},
                        onBlur: () => {},
                    }}
                    name={`${TIMELINES}.${index}.${IS_PUBLIC}`}
                    qaTag={`timelineForm-${title}PublicMeeting`}
                    text="This is a public meeting."
                    useOpenGovStyle
                />
            ) : (
                <Field
                    component={Checkbox}
                    disabled={disabled}
                    name={`${TIMELINES}.${index}.${IS_PUBLIC}`}
                    onChange={(e, value) => {
                        if (!value) {
                            change(requiredFieldName, false);
                        }
                    }}
                    qaTag={`timelineForm-${title}PublicMeeting`}
                    showValidation={showValidation}
                    text="This is a public meeting."
                    useOpenGovStyle
                />
            )}
            {(isRequiredField || isPublic) && (
                <Field
                    component={Checkbox}
                    disabled={disabled}
                    name={requiredFieldName}
                    qaTag={`timelineForm-${title}RequireAttendance`}
                    showValidation={showValidation}
                    text="Attendance is required for this meeting."
                    useOpenGovStyle
                />
            )}
        </>
    );
};
PublicInput.propTypes = PROP_TYPES;

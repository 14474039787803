import { reducer as form } from 'redux-form';

import * as categoryActions from '../actions/category';
import { ADD_EVALUATORS } from '../actions/evaluations';
import { createForm as createCustomCategoryForm } from '../containers/Admin/CustomCategories/constants';
import {
    form as evaluationCreateForm,
    STAGED_EVALUATORS,
} from '../containers/GovApp/EvaluationCreate/constants';

export const formReducer = form.plugin({
    [createCustomCategoryForm]: (state, action) => {
        switch (action.type) {
            case categoryActions.CREATE_CUSTOM_CATEGORY_SUCCESS:
                // Blow away form data on successful post
                return undefined;
            default:
                return state;
        }
    },
    [evaluationCreateForm]: (state, action) => {
        switch (action.type) {
            case ADD_EVALUATORS: {
                // Blow away stagedEvaluators field when evaluators are added
                const fieldToClear = { [STAGED_EVALUATORS]: undefined };
                return {
                    ...state,
                    values: { ...state.values, ...fieldToClear },
                    fields: { ...state.fields, ...fieldToClear },
                    registeredFields: { ...state.registeredFields, ...fieldToClear },
                };
            }
            default:
                return state;
        }
    },
});

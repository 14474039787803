import { splitCertificationsBySource } from '@og-pro/shared-config/certificationSource';
import { get } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import { DataTable, ProcuratedBadge } from '../../../../../components';
import { renderVendorCertificationCell } from '../../../../../helpers/formatDataForCsvExportUtils';
import { loadProcuratedRatings } from '../../../../../actions/ratings';
import { getProcuratedVendorListJS } from '../../../../selectors';

const mapStateToProps = (state) => {
    return {
        procuratedVendors: getProcuratedVendorListJS(state),
    };
};

// @connect
const ConnectedVendorUserStatsTable = ({ procuratedVendors, showVendorProfileModal, users }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(
            loadProcuratedRatings({
                supplierIds: users
                    .filter((user) => user.isVendor === true)
                    .map((user) => user.organization.vendor.id),
            })
        );
    }, [users]);
    const formatDataForCSVExport = (data) => {
        const headers = [
            'Vendor',
            'Email',
            'Followed',
            'Followed At',
            'Downloaded',
            'Downloaded At',
            'Applied',
            'Applied At',
            'No Bid',
            'Submitted',
            'Submitted At',
            'Address',
            'Address 2',
            'City',
            'State',
            'Zip Code',
            'Phone Number',
            'Self-Reported Certifications',
            'Verified Certifications',
        ];
        const formatTimestamp = (timestamp) =>
            timestamp ? moment(timestamp).format('M-D-YYYY h:mma') : '';

        const rows = data.map((dataRow) => {
            const originalData = dataRow._original; // eslint-disable-line no-underscore-dangle
            const organization = get(originalData, 'organization') || {}; // organization can be null if user is "pending"
            const vendor = organization.vendor;
            const { selfReportedCertifications, verifiedCertifications } =
                splitCertificationsBySource(vendor?.certifications || []);

            return [
                dataRow['organization.name'],
                originalData.email,
                dataRow.isFollower ? 1 : 0,
                formatTimestamp(originalData.followedAt),
                dataRow.isDownloader ? 1 : 0,
                formatTimestamp(originalData.downloadedAt),
                dataRow.isApplicant ? 1 : 0,
                formatTimestamp(originalData.appliedAt),
                dataRow.isNoBid ? 1 : 0,
                dataRow.isProposer ? 1 : 0,
                formatTimestamp(originalData.submittedAt),
                organization.address1 || '',
                organization.address2 || '',
                organization.city || '',
                organization.state || '',
                organization.zipCode || '',
                organization.phoneComplete || '',
                renderVendorCertificationCell(vendor ? selfReportedCertifications : []),
                renderVendorCertificationCell(vendor ? verifiedCertifications : []),
            ];
        });

        return [headers].concat(rows);
    };

    const csvExportOptions = {
        fileName: 'Vendor Analytics',
        getFormattedCSVData: formatDataForCSVExport,
        headers: true,
    };

    const renderVendorCell = ({ value, original }) => {
        const styles = require('./index.scss');

        const vendorId = get(original, 'organization.vendor.id');
        const hasVendorPage = !!vendorId;
        const companyClassName = hasVendorPage ? 'pseudoLink' : '';
        const clickHandler = hasVendorPage ? () => showVendorProfileModal(vendorId) : undefined;

        const vendorRating =
            procuratedVendors &&
            procuratedVendors.find((vendor) => {
                return parseInt(vendor.partnerId, 10) === vendorId;
            });

        return (
            <>
                <div className={styles.vendorCell}>
                    <div
                        className={`${companyClassName} ${styles.vendorName}`}
                        onClick={clickHandler}
                    >
                        {value}
                    </div>
                    <ProcuratedBadge ratingFromParent={vendorRating && vendorRating.rating} />
                </div>
                <div className={styles.vendorEmail}>{original.email}</div>
            </>
        );
    };

    const vendorColumn = {
        Header: 'Vendor',
        accessor: 'organization.name',
        Cell: renderVendorCell.bind(this),
    };

    const statisticColumns = [
        {
            Header: 'Followed',
            accessor: 'isFollower',
            className: 'text-center text-primary',
        },
        {
            Header: 'Downloaded',
            accessor: 'isDownloader',
            className: 'text-center text-info',
        },
        {
            Header: 'Applied',
            accessor: 'isApplicant',
            className: 'text-center text-warning',
        },
        {
            Header: 'No Bid',
            accessor: 'isNoBid',
            className: 'text-center text-danger',
        },
        {
            Header: 'Submitted',
            accessor: 'isProposer',
            className: 'text-center text-success',
        },
    ].map((col) => ({
        ...col,
        Cell: (cellProps) => {
            if (cellProps.value) {
                return <i className="fa fa-check" />;
            }
            return null;
        },
        maxWidth: 100,
    }));

    const columns = [vendorColumn, ...statisticColumns];
    return (
        <DataTable
            className="-striped -highlight"
            columns={columns}
            csvExportOptions={csvExportOptions}
            data={users}
            minRows={Math.max(users.length, 1)}
            pageSize={users.length}
            showCSVExport
            showPagination={false}
            title="Vendors"
        />
    );
};

ConnectedVendorUserStatsTable.propTypes = {
    procuratedVendors: PropTypes.array,
    showVendorProfileModal: PropTypes.func.isRequired,
    users: PropTypes.arrayOf(
        PropTypes.shape({
            email: PropTypes.string.isRequired,
            isApplicant: PropTypes.bool,
            isDownloader: PropTypes.bool,
            isFollower: PropTypes.bool,
            isNoBid: PropTypes.bool,
            isProposer: PropTypes.bool,
            organization: PropTypes.shape({
                // organization will not be present if user is a `pendingUser`
                name: PropTypes.isRequired,
            }),
        })
    ).isRequired,
};

export const VendorUserStatsTable = connect(mapStateToProps)(ConnectedVendorUserStatsTable);

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { Grid, Box } from '@og-pro/ui';

import { normalizeNumberWithCommas } from '@og-pro/shared-config/helpers';

import { BidSectionFormError } from './components';
import { BID } from './constants';
import { getBidError, getDeleteProxyBidError } from './selectors';
import { validate } from './validate';
import { Button, InputText } from '../../../../../components';
import { maskNumberWithCommas } from '../../../../../Forms/maskers';
import { dollarString } from '../../../../../Forms/normalizers';
import { currencyFormatter } from '../../../../../helpers';
import { MaskedInputText } from '../../../../../hocs';

const formConfig = {
    destroyOnUnmount: false,
    enableReinitialize: true,
    validate,
};

export const ConnectedBidSectionForm = ({
    allowsProxyBidding,
    auctionMaxFractionDigits,
    currentLowestBid,
    currentVendorProxyBid,
    form,
    handleSubmit,
    invalid,
    minBidDecrement,
    quantity,
    submitProxyBid,
    vendorPriceItemId,
}) => {
    const styles = require('./index.scss');
    const formSelector = formValueSelector(form);
    const MaskedBidInput = useCallback(MaskedInputText(InputText), []); // eslint-disable-line react-hooks/exhaustive-deps
    const bidError = useSelector((state) => getBidError(state, vendorPriceItemId));
    const proxyBidError = useSelector((state) =>
        getDeleteProxyBidError(state, currentVendorProxyBid)
    );
    const currentBid = useSelector((state) => formSelector(state, BID));
    const normalizedBid = (value) =>
        dollarString(value, { maximumFractionDigits: auctionMaxFractionDigits });
    const formatterOpts = {
        maximumFractionDigits: auctionMaxFractionDigits,
        useSameMinAndMax: true,
    };
    const [showBidError, setShowBidError] = useState(false);

    useEffect(() => {
        let timeoutId;
        let timeLeft = 0;
        if (bidError?.timestamp) {
            timeLeft = bidError.timestamp + 5000 - new Date().getTime();
            if (timeLeft > 0) {
                setShowBidError(true);
                timeoutId = setTimeout(() => {
                    setShowBidError(false);
                }, timeLeft);
            } else {
                setShowBidError(false);
            }
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [bidError]);

    const renderProxyBidButton = () => {
        if (currentVendorProxyBid) {
            return (
                <span
                    className={
                        currentVendorProxyBid.isCurrentLowest
                            ? styles.lowestBidSet
                            : styles.lowestBidReached
                    }
                >
                    <i className="fa fa-gavel" />{' '}
                    {currentVendorProxyBid.isCurrentLowest ? (
                        <span>
                            <strong>Lowest Bid Set to:</strong>{' '}
                            {currencyFormatter(
                                { value: currentVendorProxyBid.unitPrice },
                                formatterOpts
                            )}
                        </span>
                    ) : (
                        <span>
                            <strong>Lowest Bid Reached:</strong>{' '}
                            {currencyFormatter(
                                { value: currentVendorProxyBid.unitPrice },
                                formatterOpts
                            )}
                        </span>
                    )}
                    <Button
                        bsSize="sm"
                        bsStyle="link"
                        className={styles.changeButton}
                        onClick={submitProxyBid}
                        qaTag="reverseAuction-submitProxyBid"
                    >
                        Change
                    </Button>
                </span>
            );
        }

        return (
            <Button
                bsSize="sm"
                bsStyle="link"
                onClick={submitProxyBid}
                qaTag="reverseAuction-submitProxyBid"
            >
                Set Proxy Bid
            </Button>
        );
    };

    return (
        <form className={styles.bidSectionForm} onSubmit={handleSubmit}>
            <Grid container spacing={1}>
                <Grid item>
                    <Box display="flex" flexWrap="wrap">
                        <Box>
                            <h5 className={styles.listItemLabel}>Unit Price</h5>
                            <div className={styles.bidSectionContainer}>
                                <span className={styles.bidFormInput}>
                                    <Field
                                        component={MaskedBidInput}
                                        disabled={minBidDecrement > currentLowestBid}
                                        hasFeedback={false}
                                        inputGroupPrefix="$"
                                        mask={maskNumberWithCommas}
                                        maxLength={16}
                                        name={BID}
                                        normalizer={normalizedBid}
                                        qaTag="reverseAuction-bidForm"
                                        showValidation
                                        type="text"
                                        useOpenGovStyle
                                    />
                                </span>
                            </div>
                        </Box>
                        <Box>
                            <div className={styles.bidSectionContainer}>
                                <span className={styles.quantityLabel}>
                                    x Qty: {normalizeNumberWithCommas(quantity)} ={' '}
                                </span>
                            </div>
                        </Box>
                        <Box>
                            <div>
                                <h5 className={styles.listItemLabel}>Total</h5>
                                <div className={styles.totalLabel}>
                                    {currencyFormatter(
                                        { value: currentBid >= 0 ? currentBid * quantity : '' },
                                        formatterOpts
                                    )}
                                </div>
                            </div>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    {bidError && showBidError && <BidSectionFormError bidError={bidError} />}
                    {minBidDecrement > currentLowestBid && (
                        <BidSectionFormError
                            bidError={{ header: 'No more bids can be placed on this item!' }}
                        />
                    )}
                    {proxyBidError && <BidSectionFormError bidError={proxyBidError} />}
                </Grid>
                <Box display="flex" marginLeft="4px" marginTop={2}>
                    <Box xs={4}>
                        <Button
                            bsSize="sm"
                            bsStyle="primary"
                            disabled={invalid || !currentBid}
                            qaTag="reverseAuction-submitBid"
                            type="submit"
                        >
                            Place Bid
                        </Button>
                    </Box>
                    <Box xs={8}>
                        {allowsProxyBidding &&
                            currentLowestBid > minBidDecrement &&
                            renderProxyBidButton()}
                    </Box>
                </Box>
            </Grid>
        </form>
    );
};

ConnectedBidSectionForm.propTypes = {
    allowsProxyBidding: PropTypes.bool.isRequired,
    auctionMaxFractionDigits: PropTypes.number.isRequired,
    currentLowestBid: PropTypes.number.isRequired,
    currentVendorProxyBid: PropTypes.object,
    form: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool.isRequired,
    minBidDecrement: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
    submitProxyBid: PropTypes.func,
    vendorPriceItemId: PropTypes.number.isRequired,
};

export const BidSectionForm = reduxForm(formConfig)(ConnectedBidSectionForm);

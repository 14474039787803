import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@og-pro/ui';

import { ContentBlock } from '../../../SDv2/ContentBlock';
import { Button, Label } from '../../..';

export const CriteriaItemTextBuilder = ({
    criteriaInstructionsData,
    disabled,
    index,
    isLastItem,
    instructions,
    manualNumbering,
    moveFieldDown,
    moveFieldUp,
    title,
    shouldRenderCommentIcon,
    showCriteriaNeedsReviewModal,
    renderCommentIcon,
    renderDeleteButton,
    renderDragIcon,
    renderNeedsReviewButton,
}) => {
    const styles = require('./builder.scss');

    return (
        <ContentBlock className={styles.container} withActions>
            <ContentBlock.Main disabled={disabled} p={2}>
                <Box onClick={showCriteriaNeedsReviewModal}>
                    <Typography variant="h3">{manualNumbering || title}</Typography>
                    {!!manualNumbering && <Typography variant="h3">{title}</Typography>}
                    <Box className={styles.info}>
                        Item will be reviewed by the project editor when completing the form.
                    </Box>
                    <Box className={styles.icon} mb={1} mt={2}>
                        <Label
                            label={
                                <>
                                    <i className={`fa fa-fw fa-${criteriaInstructionsData.icon}`} />
                                    &nbsp;
                                    {criteriaInstructionsData.name}
                                </>
                            }
                        />
                    </Box>
                    <Box className={styles.info}>{instructions}</Box>
                </Box>
            </ContentBlock.Main>
            <ContentBlock.ActionSidebar>
                <ContentBlock.ButtonGroup>
                    {index !== 0 && !!moveFieldUp && (
                        <ContentBlock.Button>
                            <Button bsStyle="link" onClick={() => moveFieldUp(index)}>
                                <i aria-hidden="true" className="fa fa-arrow-up" />
                            </Button>
                        </ContentBlock.Button>
                    )}
                    <ContentBlock.Button>{renderDragIcon()}</ContentBlock.Button>
                    {!isLastItem && !!moveFieldDown && (
                        <ContentBlock.Button>
                            <Button bsStyle="link" onClick={() => moveFieldDown(index)}>
                                <i aria-hidden="true" className="fa fa-arrow-down" />
                            </Button>
                        </ContentBlock.Button>
                    )}
                </ContentBlock.ButtonGroup>
                <ContentBlock.ButtonGroup>
                    <ContentBlock.Button>{renderNeedsReviewButton(true)}</ContentBlock.Button>
                </ContentBlock.ButtonGroup>
                {shouldRenderCommentIcon() && (
                    <ContentBlock.ButtonGroup>
                        <ContentBlock.Button>{renderCommentIcon()}</ContentBlock.Button>
                    </ContentBlock.ButtonGroup>
                )}
                <ContentBlock.ButtonGroup>
                    <ContentBlock.Button>{renderDeleteButton()}</ContentBlock.Button>
                </ContentBlock.ButtonGroup>
            </ContentBlock.ActionSidebar>
        </ContentBlock>
    );
};

CriteriaItemTextBuilder.propTypes = {
    criteriaInstructionsData: PropTypes.object,
    disabled: PropTypes.bool,
    index: PropTypes.number.isRequired,
    instructions: PropTypes.string,
    isLastItem: PropTypes.bool,
    manualNumbering: PropTypes.string,
    moveFieldDown: PropTypes.func,
    moveFieldUp: PropTypes.func,
    renderCommentIcon: PropTypes.func.isRequired,
    renderDragIcon: PropTypes.func.isRequired,
    renderNeedsReviewButton: PropTypes.func.isRequired,
    renderDeleteButton: PropTypes.func.isRequired,
    shouldRenderCommentIcon: PropTypes.func.isRequired,
    showCriteriaNeedsReviewModal: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Box, Typography } from '@og-pro/ui';

import { timelineDatesDict } from '@og-pro/shared-config/timelines';

import { CDSButton } from '../../../..';
import { TimelineInputs } from './TimelineInputs';
import { TIMELINE_PROPTYPE } from '../constants';

const { AUCTION_END_DATE, AUCTION_START_DATE } = timelineDatesDict;

export const TimelineDates = (props) => {
    const {
        change,
        disabled,
        form,
        isTemplate,
        isReverseAuction,
        project,
        showValidation,
        timelineData,
        timezone,
        toggleViewMode,
    } = props;
    const styles = require('./shared.scss');

    return (
        <Box className={styles.container}>
            <Box mb={4}>
                <Typography variant="h3">Set the Project Dates</Typography>
            </Box>
            <Box
                className={classnames(styles.legend, styles.borderBottom)}
                display="flex"
                fontWeight={500}
                pb={1}
            >
                <Box flex={5}>Event</Box>
                <Box flex={8}>
                    <Box display="flex" flex={1}>
                        <Box flex={1}>Date</Box>
                        <Box flex={1} ml={6}>
                            Time (if needed)
                        </Box>
                    </Box>
                </Box>
            </Box>
            {timelineData
                .filter((timeline) => {
                    const { isCustomDate, isIncluded, projectField, required } = timeline;
                    const isAuctionDate =
                        projectField === AUCTION_START_DATE || projectField === AUCTION_END_DATE;
                    const removedCheck = isIncluded || required || isCustomDate;

                    return isAuctionDate ? !!isReverseAuction : removedCheck;
                })
                .map((timeline, i) => {
                    return (
                        <Box
                            className={classnames(styles.timeline, styles.timelineWithInputs)}
                            display="flex"
                            key={timeline.uuid || timeline.id || i}
                        >
                            <Box flex={5}>
                                <Typography variant="h4">{timeline.title}</Typography>
                            </Box>
                            <Box flex={8}>
                                <TimelineInputs
                                    change={change}
                                    disabled={disabled}
                                    form={form}
                                    isTemplate={isTemplate}
                                    project={project}
                                    showValidation={showValidation}
                                    timeline={timeline}
                                    timezone={timezone}
                                />
                            </Box>
                        </Box>
                    );
                })}
            <Box display="flex" mt={2}>
                <Box>
                    <CDSButton
                        onClick={toggleViewMode}
                        qaTag="timelineForm-seeDates"
                        variant="secondary"
                    >
                        <i className="fa fa-cog" />{' '}
                        {!project ? 'See Configuration' : 'Manage Events'}
                    </CDSButton>
                </Box>
            </Box>
        </Box>
    );
};

TimelineDates.propTypes = {
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    form: PropTypes.string.isRequired,
    isReverseAuction: PropTypes.bool,
    isTemplate: PropTypes.bool,
    project: PropTypes.object,
    showValidation: PropTypes.bool,
    timelineData: PropTypes.arrayOf(TIMELINE_PROPTYPE).isRequired,
    timezone: PropTypes.string,
    toggleViewMode: PropTypes.func.isRequired,
};

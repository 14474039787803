import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '../../../../components';

export const Policy = ({ onEditPolicy, policy, dragIcon }) => {
    const styles = require('./index.scss');

    /**
     * Renders a text element dependent on the value of archiveSchedule
     * @returns {HTMLElement} Describing when item can be archived
     */
    const renderArchiveSchedule = () => {
        if (policy.archiveSchedule === 0) {
            return (
                <>
                    <span className={styles.dateText}>Policy</span> Immediately Upon Close
                </>
            );
        }
        if (!policy.archiveSchedule) {
            return (
                <>
                    <span className={styles.dateText}>Policy</span> Never
                </>
            );
        }
        return (
            <>
                <span className={styles.dateText}>Policy</span>
                {policy.archiveSchedule} {policy.archiveSchedule === 1 ? 'year' : 'years'}
            </>
        );
    };

    return (
        <div className={`row ${styles.policy}`}>
            {dragIcon && <div className="col-xs-6 col-sm-2 col-md-3 col-lg-2">{dragIcon}</div>}
            <div className={`col-xs-6 col-sm-5 col-md-4 col-lg-5 ${styles.block}`}>
                <div className={styles.abbreviation}>{policy.abbreviation}</div>
                <div className={styles.title}>{policy.title}</div>
            </div>
            <div className={`col-xs-6 col-sm-4 ${styles.block} ${styles.padLeft}`}>
                {renderArchiveSchedule()}
            </div>
            <div className={`col-xs-6 col-sm-1 ${styles.block} ${styles.padLeft}`}>
                <Button
                    aria-label="Edit Policy"
                    bsStyle="link"
                    onClick={() => onEditPolicy(policy.id)}
                    qaTag="policy-editPolicy"
                    title="Edit Policy"
                >
                    <i className="fa fa-pencil-square-o fa-lg" />
                </Button>
            </div>
        </div>
    );
};

Policy.propTypes = {
    onEditPolicy: PropTypes.func.isRequired,
    policy: PropTypes.shape({
        abbreviation: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        archiveSchedule: PropTypes.number,
        id: PropTypes.number.isRequired,
    }).isRequired,
    dragIcon: PropTypes.node,
};

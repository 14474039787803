import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { fieldNames, fields } from './constants';
import { FlagForm } from './FlagForm';
import { showSnackbar } from '../../../../../actions/notification';
import { createFlag, updateFlag } from '../../../../../actions/requisitionsAdmin';

const { IS_ACTIVE } = fieldNames;

export const FlagsModal = ({ flag, hideModal }) => {
    const [updating, setUpdating] = useState(false);
    const [updateError, setUpdateError] = useState(null);

    const isNew = !flag;

    const dispatch = useDispatch();

    const onSubmit = (formValues) => {
        setUpdating(true);
        setUpdateError(null);

        const submitAction = isNew
            ? dispatch(createFlag(formValues))
            : dispatch(updateFlag(flag.id, formValues));

        return submitAction
            .then(() => {
                hideModal();
                dispatch(showSnackbar(`Flag ${isNew ? 'added' : 'updated'}`));
            })
            .catch((error) => {
                setUpdating(false);
                setUpdateError(error.message);
            });
    };

    return (
        <Modal onHide={hideModal} show>
            <Modal.Header closeButton>
                <Modal.Title className="text-center">{isNew ? 'Create' : 'Edit'} Flag</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-8 col-md-offset-2">
                        <FlagForm
                            initialValues={{
                                [IS_ACTIVE]: true, // Make new flags active by default
                                ...pick(flag, fields),
                            }}
                            isNew={isNew}
                            onCancel={hideModal}
                            onSubmit={onSubmit}
                            updateError={updateError}
                            updating={updating}
                        />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

FlagsModal.propTypes = {
    flag: PropTypes.object,
    hideModal: PropTypes.func.isRequired,
};

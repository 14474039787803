import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { Field, getFormValues, reduxForm } from 'redux-form';

import { contractReviewStatuses, contractReviewTypes } from '@og-pro/shared-config/contractReviews';

import { fieldNames, form } from './constants';
import { validate } from './validate';
import { ContractComplaintsList } from '../components';
import { getContractReviewJS } from '../selectors';
import { getContractPath } from '../../selectors';
import { getContractJS } from '../../../selectors';
import { showConfirmationSimpleModal } from '../../../../actions/confirmation';
import {
    deleteContractReview,
    showContractComplaintModal,
    updateContractReview,
} from '../../../../actions/contracts';
import { showSnackbar } from '../../../../actions/notification';
import {
    Button,
    InputText,
    OutlineButton,
    PageTitle,
    RouteLeaveWarning,
    SearchSelect,
    SectionTitle,
    StarsInput,
    StatusLabel,
} from '../../../../components';

const { COMMENT, OVERALL_RATING, TYPE } = fieldNames;

const { PUBLISHED } = contractReviewStatuses;

const contractReviewTypeOptions = [
    { label: 'In Progress', value: contractReviewTypes.IN_PROGRESS },
    { label: 'Complete', value: contractReviewTypes.COMPLETE },
];

const mapStateToProps = (state, props) => {
    const {
        params: { contractReviewId },
    } = props;

    const contractReview = getContractReviewJS(state, {
        contractReviewId: parseInt(contractReviewId, 10),
    });
    return {
        contract: getContractJS(state),
        contractPath: getContractPath(state, props),
        contractReview,
        formValues: getFormValues(form)(state) || {},
        initialValues: contractReview,
    };
};

const mapDispatchToProps = {
    showSnackbar,
    deleteContractReview,
    showConfirmationSimpleModal,
    showContractComplaintModal,
    updateContractReview,
};

const formConfig = {
    enableReinitialize: true,
    form,
    validate,
};

// @connect
// @reduxForm
class ConnectedContractReviewForm extends Component {
    static propTypes = {
        showSnackbar: PropTypes.func.isRequired,
        contract: PropTypes.shape({
            contractParty: PropTypes.shape({
                companyName: PropTypes.string,
            }).isRequired,
            id: PropTypes.number.isRequired,
        }).isRequired,
        contractPath: PropTypes.string.isRequired,
        contractReview: PropTypes.shape({
            contractComplaints: PropTypes.array.isRequired,
            id: PropTypes.number.isRequired,
            isPublished: PropTypes.bool.isRequired,
        }).isRequired,
        deleteContractReview: PropTypes.func.isRequired,
        dirty: PropTypes.bool.isRequired,
        formValues: PropTypes.object.isRequired,
        initialize: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        router: PropTypes.object.isRequired,
        showConfirmationSimpleModal: PropTypes.func.isRequired,
        showContractComplaintModal: PropTypes.func.isRequired,
        updateContractReview: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            forceLeave: false, // eslint-disable-line
            updateError: null,
            updating: false,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    deleteContractReview = () => {
        const { contract, contractReview } = this.props;

        const onSuccess = () => {
            this.routeToListPage(true);
            this.props.showSnackbar('Review Deleted');
        };

        this.setState({ updating: true, updateError: null });
        this.props
            .deleteContractReview(contract.id, contractReview.id, onSuccess)
            .catch((error) => {
                this.setState({ updating: false, updateError: error.message });
            });
    };

    deleteHandler = () => {
        this.props.showConfirmationSimpleModal(this.deleteContractReview, {
            btnText: 'Delete Review',
        });
    };

    routeToListPage = (forceLeave) => {
        const { contractPath, router } = this.props;
        // eslint-disable-next-line
        this.setState({ forceLeave }, () => {
            router.push(`${contractPath}/reviews`);
        });
    };

    saveHandler = () => {
        const { formValues, initialize } = this.props;

        this.updateContractReview(formValues, 'Review Saved', (result) => {
            initialize(result);
        });
    };

    submitHandler = (formData) => {
        const {
            contractReview: { isPublished },
        } = this.props;

        const data = { ...formData };

        if (!isPublished) {
            data.status = PUBLISHED;
        }

        this.updateContractReview(data, isPublished ? 'Review Saved' : 'Review Submitted', () => {
            this.routeToListPage(true);
        });
    };

    updateContractReview = (data, successMessage, onSuccess) => {
        const { contract, contractReview } = this.props;

        this.setState({ updating: true, updateError: null });
        this.props
            .updateContractReview(contract.id, contractReview.id, data)
            .then((result) => {
                this.setState({ updating: false });
                this.props.showSnackbar(successMessage);
                onSuccess(result);
            })
            .catch((error) => {
                this.setState({ updating: false, updateError: error.message });
            });
    };

    showContractComplaintModal = (contractComplaint) => {
        const { contractReview } = this.props;

        this.props.showContractComplaintModal({
            contractComplaint,
            contractReview,
        });
    };

    render() {
        const {
            contract,
            contractReview,
            contractReview: { contractComplaints, isPublished },
            dirty,
            handleSubmit,
            showContractComplaintModal: showContractComplaintModalProp,
        } = this.props;

        const { updateError, updating } = this.state;

        const vendorName =
            get(contract, 'contractParty.vendor.organization.name') ||
            contract.contractParty.companyName;

        return (
            <>
                <PageTitle title={`${vendorName} Review`} />
                <SectionTitle
                    title={
                        <>
                            {vendorName} Review
                            {!isPublished && (
                                <StatusLabel className={this.styles.draftLabel}>DRAFT</StatusLabel>
                            )}
                        </>
                    }
                />
                <RouteLeaveWarning blockingValue={dirty} />
                <form className="row" onSubmit={handleSubmit(this.submitHandler)}>
                    <div className="col-xs-12 col-md-10 col-md-offset-1">
                        <div className="row">
                            <div className="col-md-8">
                                <Field
                                    component={StarsInput}
                                    disabled={updating}
                                    label="Overall vendor rating"
                                    name={OVERALL_RATING}
                                />
                                <Field
                                    component={SearchSelect}
                                    disabled={updating}
                                    label="Contract status at time of review"
                                    name={TYPE}
                                    options={contractReviewTypeOptions}
                                />
                                <Field
                                    component={InputText}
                                    disabled={updating}
                                    name={COMMENT}
                                    overrideFeedback
                                    placeholder="Leave a review of the vendor's performance of this contract"
                                    type="textarea"
                                />
                                <div className="row">
                                    <div className="col-xs-6">
                                        <OutlineButton
                                            bsStyle="danger"
                                            disabled={updating}
                                            onClick={this.deleteHandler}
                                        >
                                            <i className="fa fa-trash" /> Delete
                                        </OutlineButton>
                                    </div>
                                    <div className="col-xs-6 text-right">
                                        <Button
                                            bsStyle="link"
                                            disabled={updating}
                                            onClick={() => this.routeToListPage(false)}
                                            style={{ marginRight: 10 }}
                                        >
                                            Cancel
                                        </Button>
                                        {!isPublished && (
                                            <Button
                                                disabled={updating}
                                                onClick={this.saveHandler}
                                                style={{ marginRight: 10 }}
                                            >
                                                Save
                                            </Button>
                                        )}
                                        <Button bsStyle="primary" disabled={updating} type="submit">
                                            {isPublished ? 'Save' : 'Post Review'}
                                        </Button>
                                        {updateError && (
                                            <div className="error-block">{updateError}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div>
                                    <b>Complaints</b>
                                </div>
                                <Button
                                    block
                                    bsStyle="danger"
                                    onClick={() => this.showContractComplaintModal()}
                                    style={{ marginBottom: 10 }}
                                >
                                    <i className="fa fa-bullhorn" />
                                    &nbsp;&nbsp;File Complaint
                                </Button>
                                {contractComplaints.length === 0 && (
                                    <div>
                                        <em>No complaints have been filed</em>
                                    </div>
                                )}
                                <ContractComplaintsList
                                    contractReview={contractReview}
                                    showContractComplaintModal={showContractComplaintModalProp}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </>
        );
    }
}

export const ContractReviewForm = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedContractReviewForm);

import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class ComingSoon extends Component {
    static propTypes = {
        displayText: PropTypes.string.isRequired,
        imageSrc: PropTypes.string.isRequired,
    };

    render() {
        const { displayText, imageSrc } = this.props;
        const styles = require('./ComingSoon.scss');
        return (
            <div className={styles.comingSoonContainer}>
                <div className={styles.overlay} />
                <img alt="Coming soon" className={styles.comingSoonImage} src={imageSrc} />
                <div className={styles.comingSoonText}>{displayText}</div>
            </div>
        );
    }
}

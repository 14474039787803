import PropTypes from 'prop-types';
import React from 'react';

import { DOCX_TABLE_LANDSCAPE_WIDTH, DOCX_TABLE_PORTRAIT_WIDTH } from '../../../../../constants';

export const ProjectOverviewTable = ({ rows, useLandscape }) => {
    return (
        <table
            style={{ fontSize: '10pt' }}
            width={useLandscape ? DOCX_TABLE_LANDSCAPE_WIDTH : DOCX_TABLE_PORTRAIT_WIDTH}
        >
            <tbody>
                {rows.map((row) => (
                    <tr key={row.description}>
                        <td style={{ backgroundColor: '#E7E6E6' }} width={100}>
                            <div style={{ fontWeight: 'bold' }}>{row.description}</div>
                        </td>
                        <td>
                            <div>{row.data}</div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

ProjectOverviewTable.propTypes = {
    rows: PropTypes.arrayOf(
        PropTypes.shape({
            description: PropTypes.string.isRequired,
            data: PropTypes.any,
        })
    ).isRequired,
    useLandscape: PropTypes.bool,
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

export class GovtInvite extends Component {
    static propTypes = {
        org: PropTypes.object,
        email: PropTypes.string,
        sendEmail: PropTypes.func,
        loading: PropTypes.bool,
        inviteSent: PropTypes.bool,
    };

    render() {
        const styles = require('./index.scss');
        const { org, email, sendEmail, loading, inviteSent } = this.props;

        const icon = loading ? 'fa-spinner fa-spin' : 'fa-envelope';
        const buttonText = inviteSent ? 'Re-Send Invite' : 'Send Invite';
        const confirmText =
            inviteSent && !loading ? (
                <div className={styles.successText}>Invite Successfully Sent!</div>
            ) : null;

        return (
            <div className={styles.govtInvite}>
                <h3 className={`text-success ${styles.topHeader}`}>
                    We found a match with the {org?.name}!
                </h3>
                <h4 className={styles.subHeader}>
                    To create your account, please click the button below. We will then send you a
                    confirmation email to activate your account.
                </h4>
                <h4 className={styles.subHeader}>
                    The email you entered was:&nbsp;
                    <span className="text-primary">{email}</span>
                </h4>
                <Button
                    bsSize="large"
                    bsStyle="success"
                    className="center-block"
                    disabled={loading}
                    onClick={sendEmail}
                >
                    <i className={`fa fa-fw ${icon}`} /> {buttonText}
                </Button>
                {confirmText}
            </div>
        );
    }
}

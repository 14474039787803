import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { mappedProcurementContactFields } from '@og-pro/shared-config/contacts';

import { subsectionTypeNames } from '@og-pro/shared-config/subsections';

import { IntroductionTitle } from './IntroductionTitle';
import { SectionIntro } from '../SectionIntro';
import { ContactInfo } from '../../..';

const { CONTACT } = subsectionTypeNames;

export class ContactSection extends PureComponent {
    static propTypes = {
        description: PropTypes.object.isRequired,
        isDocx: PropTypes.bool,
        isEditable: PropTypes.bool,
        itemNumber: PropTypes.string.isRequired,
        project: PropTypes.shape({
            departmentHead: PropTypes.string,
            departmentHeadTitle: PropTypes.string,
            departmentName: PropTypes.string,
            government: PropTypes.shape({
                hideDeptHead: PropTypes.bool.isRequired,
            }).isRequired,
            hideContact: PropTypes.bool.isRequired,
            hideContactAddress: PropTypes.bool.isRequired,
            hideProcurementContact: PropTypes.bool.isRequired,
        }).isRequired,
        projectSection: PropTypes.object.isRequired,
        projectSubsectionsMap: PropTypes.object.isRequired,
        sectionDescActions: PropTypes.object,
        showComments: PropTypes.bool,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
    };

    static defaultProps = {
        isDocx: false,
        isEditable: false,
        sectionDescActions: undefined,
        showComments: false,
        tagOptions: undefined,
        templateVariableOptions: undefined,
    };

    render() {
        const {
            description,
            isDocx,
            isEditable,
            project,
            projectSection,
            projectSubsectionsMap,
            sectionDescActions,
            showComments,
            itemNumber,
            tagOptions,
            templateVariableOptions,
        } = this.props;

        const showContactAddress = !project.hideContactAddress;
        const showContactLabels = !project.hideContact && !project.hideProcurementContact;
        const projectSubsection = projectSubsectionsMap[CONTACT];

        return (
            <div
                className="introduction-subsection"
                id={`project-field-project_subsection_id.${projectSubsection.id}`}
            >
                <div className="row">
                    <div className="col-xs-12">
                        <IntroductionTitle
                            isDocx={isDocx}
                            itemNumber={itemNumber}
                            projectSection={projectSection}
                            showComments={showComments}
                            subsectionType={CONTACT}
                            title={projectSubsection.title}
                        />
                    </div>
                </div>
                <SectionIntro
                    {...sectionDescActions}
                    isDocx={isDocx}
                    isEditable={isEditable}
                    isSubsection
                    projectSectionId={projectSection.id}
                    projectSubsectionId={projectSubsection.id}
                    sectionDescription={description[projectSubsection.id]}
                    tagOptions={tagOptions}
                    templateVariableOptions={templateVariableOptions}
                />
                <div className="row">
                    <div className="col-sm-6">
                        {!project.hideContact && (
                            <ContactInfo
                                className="contact-section"
                                label={showContactLabels ? 'Project Contact:' : undefined}
                                showContactAddress={showContactAddress}
                                {...project}
                            />
                        )}
                        {!project.hideProcurementContact && (
                            <ContactInfo
                                className="contact-section"
                                fieldNames={mappedProcurementContactFields}
                                label={showContactLabels ? 'Procurement Contact:' : undefined}
                                showContactAddress={showContactAddress}
                                {...project}
                            />
                        )}
                    </div>
                    <div className="col-sm-6">
                        <div className="contact-section">
                            <p>
                                <strong>Department:</strong>
                                <br />
                                {project.departmentName}
                            </p>
                            {project.departmentHead && !project.government.hideDeptHead && (
                                <p>
                                    <strong>Department Head:</strong>
                                    <br />
                                    {project.departmentHead}
                                    <br />
                                    {project.departmentHeadTitle}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const contractReviewStatuses = {
    DRAFT: 1,
    PUBLISHED: 2,
};

exports.contractReviewStatuses = contractReviewStatuses;

exports.contractReviewStatusesArray = [
    contractReviewStatuses.DRAFT,
    contractReviewStatuses.PUBLISHED,
];

const contractReviewTypes = {
    IN_PROGRESS: 1,
    COMPLETE: 2,
};

exports.contractReviewTypes = contractReviewTypes;

exports.contractReviewTypesArray = [contractReviewTypes.IN_PROGRESS, contractReviewTypes.COMPLETE];

exports.contractReviewTypeNames = {
    [contractReviewTypes.IN_PROGRESS]: 'In Progress',
    [contractReviewTypes.COMPLETE]: 'Complete',
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export class NoItems extends PureComponent {
    static propTypes = {
        text: PropTypes.string.isRequired,
    };

    get styles() {
        return require('./NoItems.scss');
    }

    render() {
        return (
            <div className={`row text-muted ${this.styles.noItems}`}>
                <div className="col-sm-10 col-sm-offset-1">
                    <div className={this.styles.noItemsHeader}>{this.props.text}</div>
                </div>
            </div>
        );
    }
}

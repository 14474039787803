import { useMemo } from 'react';

import { accountFieldNames } from './PriceItem/AccountSplit/AccountFields/AccountField/constants';
import { priceItemFieldNames } from '../../../../constants';

const { ACCOUNT_NUMBER, AMOUNT } = accountFieldNames;
const { ACCOUNT_SPLIT_PRICE_ITEMS } = priceItemFieldNames;
export const useSumOfAccountAmounts = (fields = []) =>
    useMemo(
        () => fields.reduce((total, field, index) => total + Number(fields[index][AMOUNT] || 0), 0),
        [fields.length, ...fields.map((field) => field[AMOUNT])]
    );

export const getTotalAmountUsedByAccountNumber = (priceItems = [], accountNumber) => {
    return priceItems.reduce((accumulator, priceItem) => {
        const accounts = priceItem[ACCOUNT_SPLIT_PRICE_ITEMS] || [];
        const accountAmounts = accounts
            .filter((eachAccount) => eachAccount[ACCOUNT_NUMBER] === accountNumber)
            .map((eachAccount) => eachAccount[AMOUNT]);
        const totalAmount = accountAmounts.reduce((acc, amount) => acc + Number(amount), 0);
        return accumulator + totalAmount;
    }, 0);
};

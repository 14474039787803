import { get } from 'lodash';

function attachmentObjectBuilder(attachmentKey, obj, attach, index) {
    const attachment = { ...attach, index };
    const docId = get(attachment, attachmentKey);
    if (obj[docId]) {
        return { ...obj, [docId]: obj[docId].concat(attachment) };
    }
    return { ...obj, [docId]: [attachment] };
}

export function attachmentObject(...args) {
    return attachmentObjectBuilder('proposalAttachments.proposal_document_id', ...args);
}

export function uploadObject(...args) {
    return attachmentObjectBuilder('proposal_document_id', ...args);
}

export function mapProposalDocuments(propDocs, attachmentObj, uploadObj) {
    const proposalDocs = propDocs.concat({
        // Used to identify the 'other' docs in attachmentObj
        id: null,
        key: 'otherDocuments',
        title: 'Other Documents',
    });
    return proposalDocs.map((doc) => {
        const attachments = attachmentObj[doc.id] || [];
        if (uploadObj) {
            // Map uploads if they are provided
            const uploads = uploadObj[doc.id] || [];
            return { ...doc, attachments, uploads };
        }
        return { ...doc, attachments };
    });
}

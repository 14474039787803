import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Box } from '@og-pro/ui';

import { fileFieldNames } from './form/constants';
import { CDSButton, InputText, ProgressBar, SearchSelect } from '../../..';

const { TITLE, APPENDIX_ID } = fileFieldNames;

export const AttachmentDropzoneMultiFileForm = (props) => {
    const [loading, setLoading] = useState(false);
    const styles = require('./AttachmentDropzoneMultiFileForm.scss');

    const {
        appendixOptions,
        attachments,
        disabled,
        handleSubmit,
        hideAppendixLetter,
        uploadFile,
        removeFile,
        removeAll,
    } = props;

    const onSubmit = async () => {
        setLoading(true);

        let i = 0;
        while (i < attachments.length) {
            const { attachment, member, index } = attachments[i];

            await uploadFile({ formKey: member, attachment, index });

            i += 1;
        }

        setLoading(false);
    };

    return (
        <Box className={styles.container} component="form" onSubmit={handleSubmit(onSubmit)}>
            {attachments.map(({ attachment, index, member }) => {
                if (attachment.loading) {
                    return (
                        <Box className={styles.attachmentRow} key={index}>
                            {attachment[TITLE]}
                            <ProgressBar
                                bsStyle="royal"
                                now={attachment.progress}
                                useOpenGovStyle
                            />
                        </Box>
                    );
                }

                return (
                    <Box className={styles.attachmentRow} display="flex" key={index}>
                        {!hideAppendixLetter && (
                            <Box flex={2} mr={3}>
                                <Field
                                    aria-label="Attachment ID"
                                    component={SearchSelect}
                                    disabled={disabled || loading}
                                    hideDisabledMenuOptions
                                    isOptionDisabled={(option) => option.disabled}
                                    isSearchable={false}
                                    label="Attachment ID"
                                    name={`${member}.${APPENDIX_ID}`}
                                    options={appendixOptions}
                                    qaTag="attachmentMultiFileForm-attachmentId"
                                    showValidation
                                    useOpenGovStyle
                                />
                            </Box>
                        )}
                        <Box alignItems="center" display="flex" flex={6}>
                            <Box flex={1} mr={1}>
                                <Field
                                    aria-label="Attachment Title"
                                    component={InputText}
                                    disabled={disabled || loading}
                                    hasFeedback={false}
                                    label="Title"
                                    name={`${member}.${TITLE}`}
                                    qaTag="attachmentMultiFileForm-attachmentTitle"
                                    showValidation
                                    type="text"
                                    useOpenGovStyle
                                />
                            </Box>
                            <Box pt={1}>
                                <CDSButton
                                    onClick={() => removeFile(index)}
                                    qaTag="attachmentMultiFileForm-removeFile"
                                    size="small"
                                    variant="text"
                                >
                                    <i className="fa fa-times" />
                                </CDSButton>
                            </Box>
                        </Box>
                    </Box>
                );
            })}
            <Box mt={3} textAlign="right">
                <Box
                    component={CDSButton}
                    disabled={disabled || loading}
                    mr={2}
                    onClick={() => removeAll()}
                    qaTag="attachmentMultiFileForm-cancelUpload"
                    variant="secondary"
                >
                    Cancel
                </Box>
                <CDSButton
                    disabled={disabled || loading}
                    qaTag="attachmentMultiFileForm-submit"
                    type="submit"
                    variant="primary"
                >
                    Upload
                </CDSButton>
            </Box>
        </Box>
    );
};

AttachmentDropzoneMultiFileForm.propTypes = {
    appendixOptions: PropTypes.array.isRequired,
    attachments: PropTypes.arrayOf({
        member: PropTypes.string.isRequired,
        index: PropTypes.number.isRequired,
        attachment: PropTypes.shape({
            appendixId: PropTypes.string,
            done: PropTypes.bool,
            error: PropTypes.string,
            file: PropTypes.object.isRequired,
            fileExtension: PropTypes.string,
            loading: PropTypes.bool,
            progress: PropTypes.number.isRequired,
            title: PropTypes.string,
        }).isRequired,
    }).isRequired,
    disabled: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    hideAppendixLetter: PropTypes.bool,
    removeAll: PropTypes.func.isRequired,
    removeFile: PropTypes.func.isRequired,
    uploadFile: PropTypes.func.isRequired,
};

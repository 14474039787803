import classnames from 'classnames';
import { get, omit, sortBy, uniq } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import ReactToggle from 'react-toggle';
import { connect } from 'react-redux';

import { categoryNames, categorySets } from '@og-pro/shared-config/categories';

import { CategorySearch } from './CategorySearch';
import { CategorySelectItem } from './CategorySelectItem';
import { CategorySetForm, SelectedCategories } from './components';
import {
    getInitialCategories,
    getSearchResultsJS,
    getSelectedCategory,
    getSelectedCategorySet,
} from './selectors';
import { getCustomCategoriesJS } from '../GovApp/selectors';
import * as categoryActions from '../../actions/category';
import { Button, LoadingSpinner, LoadingError, Well } from '../../components';

const mapStateToProps = (state, props) => {
    return {
        allowViewToggling: getCustomCategoriesJS(state).length > 0,
        customCategories: getCustomCategoriesJS(state),
        initCategories: getInitialCategories(state, props),
        loadingSelected: state.category.get('loadingSelectedCategory'),
        loadSelectedError: state.category.get('loadSelectedCategoryError'),
        searching: state.category.get('searchingCategories'),
        searchResults: getSearchResultsJS(state),
        selectedCategory: getSelectedCategory(state),
        selectedCategorySet: getSelectedCategorySet(state, props),
        showAll: state.category.get('showAllCategories'),
    };
};

const mapDispatchToProps = categoryActions;

// @connect
class ConnectedCategorySelect extends Component {
    static propTypes = {
        allowViewToggling: PropTypes.bool.isRequired,
        className: PropTypes.string,
        clickHandler: PropTypes.func,
        categories: PropTypes.array,
        changeCategorySet: PropTypes.func.isRequired,
        customCategories: PropTypes.array.isRequired,
        disabled: PropTypes.bool,
        disallowCustomCategories: PropTypes.bool,
        initCategories: PropTypes.array.isRequired,
        isRequired: PropTypes.bool,
        levelUpCategories: PropTypes.func.isRequired,
        loadingSelected: PropTypes.bool.isRequired,
        loadSubcategory: PropTypes.func.isRequired,
        loadSelectedError: PropTypes.string,
        resetCategoriesSearch: PropTypes.func.isRequired,
        searchCategories: PropTypes.func.isRequired,
        searching: PropTypes.bool.isRequired,
        searchResults: PropTypes.array.isRequired,
        selectedCategory: PropTypes.shape({
            categories: PropTypes.array.isRequired,
            icon: PropTypes.string,
            title: PropTypes.string.isRequired,
        }),
        selectedCategorySet: PropTypes.number.isRequired,
        showAll: PropTypes.bool.isRequired,
        showAllCategories: PropTypes.func.isRequired,
        showMoreCodeSetsHelp: PropTypes.bool,
        showNextArrow: PropTypes.bool,
        useSingleCodeSet: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        const initialCategories = props.categories || [];

        const categoriesMap = initialCategories.reduce((obj, category) => {
            obj[category.id] = true;
            return obj;
        }, {});

        this.state = {
            categories: initialCategories,
            categoriesMap,
            initialCategories,
            showAdvancedCategoryView: false,
        };
    }

    componentWillUnmount() {
        this.props.resetCategoriesSearch();
        this.submitHandler();
    }

    get styles() {
        return require('./shared.scss');
    }

    changeCategorySet = ({ setId }) => {
        if (setId) {
            this.props.changeCategorySet(setId);
        }
    };

    getMissingCategories = () => {
        const { categories } = this.state;

        const selectedCategories = uniq(categories.map((category) => category.setId));
        return Object.values(categorySets)
            .filter((setId) => !selectedCategories.includes(setId))
            .map((setId) => categoryNames[setId]);
    };

    loadSubcategory = (category, options) => {
        const { loadSubcategory, selectedCategorySet } = this.props;

        return loadSubcategory(category, selectedCategorySet, options);
    };

    removeCategory = (id) => {
        this.setState((prevState) => ({
            categories: prevState.categories.filter((cat) => cat.id !== id),
            categoriesMap: omit(prevState.categoriesMap, id),
        }));
    };

    // Search select stores the category data in the data key
    searchSelectCategory = (category) => {
        // When input is cleared null is returned, so need to check
        if (category) {
            this.selectCategory(category.data);
        }
    };

    selectCategory = (category) => {
        // Ignore empty or repeat category codes
        if (!category || this.state.categoriesMap[category.id]) return null;

        this.setState((state) => {
            return {
                categories: sortBy([...state.categories, category], 'code'),
                categoriesMap: {
                    ...state.categoriesMap,
                    [category.id]: true,
                },
            };
        });
    };

    selectCustomCategory = (customCategory) => {
        const categoriesMap = customCategory.categories.reduce((map, category) => {
            map[category.id] = true;
            return map;
        }, {});

        this.setState(
            {
                categories: customCategory.categories,
                categoriesMap,
            },
            this.submitHandler
        );
    };

    submitHandler = () => {
        const { clickHandler } = this.props;

        if (clickHandler) {
            const { categories, initialCategories } = this.state;

            const hasChanged =
                sortBy(categories, 'id')
                    .map((c) => c.id)
                    .join(',') !==
                sortBy(initialCategories, 'id')
                    .map((c) => c.id)
                    .join(',');

            clickHandler(categories, hasChanged);
        }
    };

    toggleSelect = () => {
        this.setState((state) => {
            return {
                showAdvancedCategoryView: !state.showAdvancedCategoryView,
            };
        });
    };

    renderAdvancedView() {
        const styles = require('./shared.scss');

        const { allowViewToggling, className, disallowCustomCategories } = this.props;

        const { showAdvancedCategoryView } = this.state;

        return (
            <div className={classnames(className, this.styles.advancedCategoryView)}>
                {this.renderSelected()}
                {this.renderSubcategory()}
                {this.renderCategoryList()}
                {allowViewToggling && !disallowCustomCategories && (
                    <div className={styles.toggleMode}>
                        <ReactToggle
                            aria-label="Toggle for basic category selection"
                            checked={showAdvancedCategoryView}
                            onChange={this.toggleSelect}
                        />
                        &nbsp;Toggle for custom category selection
                    </div>
                )}
            </div>
        );
    }

    renderBasicView() {
        const styles = require('./shared.scss');

        const { showAdvancedCategoryView } = this.state;

        if (!this.props.allowViewToggling) {
            return this.renderAdvancedView();
        }

        return (
            <div className={this.props.className}>
                {this.renderCustomCategoryList()}
                <div className={styles.toggleMode}>
                    <ReactToggle
                        aria-label="Toggle for advanced category selection"
                        checked={showAdvancedCategoryView}
                        onChange={this.toggleSelect}
                    />
                    &nbsp;Toggle for advanced category selection
                </div>
            </div>
        );
    }

    renderCategoryList() {
        const {
            initCategories,
            loadingSelected,
            loadSelectedError: error,
            selectedCategory,
            showAll,
            showAllCategories,
        } = this.props;

        const { categoriesMap } = this.state;

        if (loadingSelected) {
            return <LoadingSpinner />;
        }

        if (error) {
            return <LoadingError error={error} />;
        }

        const showPopular = !showAll && !selectedCategory;
        const categories = get(selectedCategory, 'categories', initCategories).map(
            (category, index) => {
                return (
                    <CategorySelectItem
                        category={category}
                        isSelected={categoriesMap[category.id]}
                        key={index}
                        loadHandler={this.loadSubcategory}
                        selectHandler={this.selectCategory}
                    />
                );
            }
        );

        return (
            <>
                <ListGroup>
                    <ul className={this.styles.categoryList}>{categories}</ul>
                </ListGroup>
                {showPopular && (
                    <Button bsStyle="link" key="showAll" onClick={showAllCategories}>
                        Show all categories...
                    </Button>
                )}
            </>
        );
    }

    renderCustomCategoryList() {
        const { customCategories } = this.props;

        const styles = require('./shared.scss');

        const customCategoriesToRender = customCategories.map((customCategory) => {
            return (
                <ListGroupItem
                    className={styles.listItem}
                    key={customCategory.id}
                    onClick={() => this.selectCustomCategory(customCategory)}
                >
                    {customCategory.category}
                </ListGroupItem>
            );
        });

        return (
            <ListGroup>
                <ul className={this.styles.categoryList}>{customCategoriesToRender}</ul>
            </ListGroup>
        );
    }

    renderSearch() {
        const { disabled, searching, searchCategories, searchResults, selectedCategorySet } =
            this.props;

        const { categoriesMap } = this.state;

        return (
            <CategorySearch
                categoriesMap={categoriesMap}
                categorySet={selectedCategorySet}
                disabled={disabled}
                label="Keyword Search"
                options={searchResults}
                search={searchCategories}
                searching={searching}
                selectHandler={this.searchSelectCategory}
            />
        );
    }

    renderSelectButton() {
        const { isRequired, showNextArrow } = this.props;
        const { categories, initialCategories } = this.state;
        const { checkIcon, nextArrow, selectButton } = this.styles;

        const hasSelections = categories.length > 0;
        const hasChanged = categories.length !== initialCategories.length;
        if (!hasSelections && isRequired) return null;
        if (!hasSelections && !hasChanged) return null;

        const text = showNextArrow ? 'Next' : 'Select';
        return (
            <div className={selectButton}>
                <Button bsStyle="primary" onClick={this.submitHandler}>
                    {!showNextArrow && <i className={`fa fa-check-square-o ${checkIcon}`} />}
                    {text}
                    {showNextArrow && <i className={`fa fa-angle-right ${nextArrow}`} />}
                </Button>
            </div>
        );
    }

    renderShowMoreCodeSetsHelp() {
        const missingCategoryNames = this.getMissingCategories();

        if (missingCategoryNames.length === 0) {
            return null;
        }

        return (
            <div className={`text-warning ${this.styles.showMoreCodeSetsHelp}`}>
                <i className="fa fa-exclamation-triangle" /> Be sure to also add categories from the{' '}
                {missingCategoryNames.join(', ')} code set
            </div>
        );
    }

    renderSelected() {
        const { selectedCategorySet, showMoreCodeSetsHelp, useSingleCodeSet } = this.props;

        const { categories } = this.state;

        return (
            <Well>
                {this.renderSelectButton()}
                <SelectedCategories
                    categories={categories}
                    className={this.styles.selectedCategories}
                    deleteHandler={this.removeCategory}
                />
                <div className="clearfix" />
                <div className={`row ${this.styles.categorySearchLabels}`}>
                    {!useSingleCodeSet && (
                        <div className="col-xs-12 col-sm-3">
                            <CategorySetForm
                                form="categorySetSelectForm"
                                formClassName={this.styles.categorySetSelect}
                                initialValues={{ setId: selectedCategorySet }}
                                label="Code Set"
                                onChange={this.changeCategorySet}
                            />
                        </div>
                    )}
                    <div
                        className={classnames(
                            'col-xs-12',
                            !useSingleCodeSet && 'col-sm-9',
                            !useSingleCodeSet && this.styles.categorySelectSearchContainer
                        )}
                    >
                        {this.renderSearch()}
                    </div>
                </div>
                {showMoreCodeSetsHelp && categories.length > 0 && this.renderShowMoreCodeSetsHelp()}
            </Well>
        );
    }

    renderSubcategory() {
        if (!this.props.selectedCategory) return null;

        const {
            levelUpCategories,
            selectedCategory: { icon, title },
        } = this.props;

        const { selectedContainer, selectedTitle } = this.styles;

        const iconEl = icon && <i className={`fa fa-${icon} fa-fw fa-lg text-primary`} />;

        return (
            <div className={selectedContainer}>
                <Button bsSize="sm" onClick={levelUpCategories}>
                    <i className="fa fa-arrow-left" />
                </Button>
                <strong className={selectedTitle}>
                    {iconEl} {title}
                </strong>
            </div>
        );
    }

    render() {
        return this.state.showAdvancedCategoryView || this.props.disallowCustomCategories
            ? this.renderAdvancedView()
            : this.renderBasicView();
    }
}

export const CategorySelect = connect(mapStateToProps, mapDispatchToProps)(ConnectedCategorySelect);

import { listToDict } from '../../../utils';

export const form = 'vendorActivate';

export const fields = ['firstName', 'lastName', 'organization'];

export const organizationFields = ['name', 'phone', 'phoneCountry', 'phoneExt'];

export const fieldNames = listToDict(fields);

export const organizationFieldNames = listToDict(organizationFields);

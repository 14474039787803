import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export class QuestionLogo extends PureComponent {
    static propTypes = {
        entityName: PropTypes.string,
        imageSrc: PropTypes.string,
    };

    static defaultProps = {
        entityName: 'Unknown',
        imageSrc: 'https://assets.procurement.opengov.com/assets/unknown-business-logo.png',
    };

    render() {
        const styles = require('./shared.scss');
        return (
            <div className={styles.logoContainer}>
                <img
                    alt={`${this.props.entityName} logo`}
                    className={`img-responsive img-rounded ${styles.logo}`}
                    src={this.props.imageSrc}
                />
            </div>
        );
    }
}

import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import PropTypes from 'prop-types';
import { Grid } from '@og-pro/ui';
import React from 'react';

export const MismatchInfo = ({ contract, vendor }) => {
    const rowPadding = {
        paddingTop: 2,
    };

    const firstGridItemStyle = {
        ...rowPadding,
        fontWeight: 'medium',
        paddingRight: 2,
    };

    const restGridItemStyle = {
        ...rowPadding,
        borderLeft: `1px solid ${capitalDesignTokens.semanticColors.border.primary}`,
        paddingLeft: 2,
    };

    return (
        <Grid container>
            <Grid container fontWeight="medium" item>
                <Grid item sx={{ ...firstGridItemStyle, paddingTop: 0 }} xs={4}>
                    &nbsp;
                </Grid>
                <Grid item sx={{ ...restGridItemStyle, paddingTop: 0 }} xs={4}>
                    Vendor Name
                </Grid>
                <Grid item sx={{ ...restGridItemStyle, paddingTop: 0 }} xs={4}>
                    Vendor Number
                </Grid>
            </Grid>
            <Grid container item>
                <Grid item sx={firstGridItemStyle} xs={4}>
                    On Contract
                </Grid>
                <Grid item sx={restGridItemStyle} xs={4}>
                    {contract.contractParty?.vendor?.organization.name ||
                        contract.contractParty?.companyName}
                </Grid>
                <Grid item sx={restGridItemStyle} xs={4}>
                    {contract.vendorAssignedNo || '-'}
                </Grid>
            </Grid>
            <Grid container item>
                <Grid item sx={firstGridItemStyle} xs={4}>
                    Selected Vendor
                </Grid>
                <Grid item sx={restGridItemStyle} xs={4}>
                    {vendor.vendorName}
                </Grid>
                <Grid item sx={restGridItemStyle} xs={4}>
                    {vendor.vendorAssignedNo || '-'}
                </Grid>
            </Grid>
        </Grid>
    );
};

MismatchInfo.propTypes = {
    contract: PropTypes.shape({
        contractParty: PropTypes.shape({
            vendor: PropTypes.shape({
                organization: PropTypes.shape({
                    name: PropTypes.string,
                }).isRequired,
            }),
            companyName: PropTypes.string,
        }),
        vendorAssignedNo: PropTypes.string.isRequired,
    }).isRequired,
    vendor: PropTypes.shape({
        vendorAssignedNo: PropTypes.string,
        vendorName: PropTypes.string.isRequired,
    }).isRequired,
};

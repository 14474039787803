import { Refresh as RefreshIcon, Warning as WarningIcon } from '@mui/icons-material';
import { Box, Typography } from '@og-pro/ui';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { defaultError } from '../../constants';

const ErrorDisplay = ({ error, info, useOpenGovStyle }) => {
    const styles = require('./LoadingError.scss');

    if (useOpenGovStyle) {
        const iconStyle = { fontSize: 54 };
        return (
            <>
                <Box marginBottom={1}>
                    {error ? <WarningIcon sx={iconStyle} /> : <RefreshIcon sx={iconStyle} />}
                </Box>
                <Typography className={styles.errorMessage} variant="bodyXLarge">
                    {error || defaultError}
                </Typography>
                {info && (
                    <Box component={Typography} marginTop={1} variant="bodyDefault">
                        {info}
                    </Box>
                )}
            </>
        );
    }

    return (
        <>
            <Box marginBottom={1}>
                <i className={`fa fa-${error ? 'exclamation-triangle' : 'refresh'} fa-2x`} />
            </Box>
            <div className={styles.errorMessage}>{error || defaultError}</div>
            {info && (
                <Box fontSize="16px" marginTop={1}>
                    {info}
                </Box>
            )}
        </>
    );
};

ErrorDisplay.propTypes = {
    error: PropTypes.string,
    info: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    useOpenGovStyle: PropTypes.bool,
};

export const LoadingError = ({ error, info, noOffset, onClick, useOpenGovStyle }) => {
    const styles = require('./LoadingError.scss');

    return (
        <div className="row">
            <div
                className={classnames(
                    styles.loadingError,
                    'text-danger',
                    noOffset ? 'col-xs-12' : 'col-sm-offset-2 col-sm-8',
                    onClick && styles.clickHandler
                )}
                onClick={onClick}
            >
                <ErrorDisplay error={error} info={info} useOpenGovStyle={useOpenGovStyle} />
            </div>
        </div>
    );
};

LoadingError.propTypes = {
    error: PropTypes.string,
    info: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    noOffset: PropTypes.bool,
    onClick: PropTypes.func,
    useOpenGovStyle: PropTypes.bool,
};

const wordFileExtensions = {
    docx: 'file-word-o',
    docm: 'file-word-o',
    dotx: 'file-word-o',
    dotm: 'file-word-o',
    docb: 'file-word-o',
    doc: 'file-word-o',
    dot: 'file-word-o',
};

const excelFileExtensions = {
    xls: 'file-excel-o',
    xlt: 'file-excel-o',
    xlm: 'file-excel-o',
    xlsx: 'file-excel-o',
    xlsm: 'file-excel-o',
    xltm: 'file-excel-o',
};

const powerpointFileExtensions = {
    ppt: 'file-powerpoint-o',
    pot: 'file-powerpoint-o',
    pps: 'file-powerpoint-o',
    pptx: 'file-powerpoint-o',
    pptm: 'file-powerpoint-o',
    potx: 'file-powerpoint-o',
    potm: 'file-powerpoint-o',
    ppam: 'file-powerpoint-o',
    ppsx: 'file-powerpoint-o',
    ppsm: 'file-powerpoint-o',
    sldx: 'file-powerpoint-o',
    sldm: 'file-powerpoint-o',
};

const pdfFileExtensions = {
    pdf: 'file-pdf-o',
};

const zipFileExtensions = {
    zip: 'file-zip-o',
};

const imageFileExtensions = {
    jpg: 'file-image-o',
    jpeg: 'file-image-o',
    gif: 'file-image-o',
    png: 'file-image-o',
    tif: 'file-image-o',
    tiff: 'file-image-o',
    bmp: 'file-image-o',
    eps: 'file-image-o',
};

const textFileExtensions = {
    txt: 'file-text-o',
    rtf: 'file-text-o',
};

const fileExtensionIconMap = {
    ...wordFileExtensions,
    ...excelFileExtensions,
    ...powerpointFileExtensions,
    ...pdfFileExtensions,
    ...zipFileExtensions,
    ...imageFileExtensions,
    ...textFileExtensions,
};

const defaultFileIcon = 'file-o';

export function getFileExtensionIcon(extension) {
    return fileExtensionIconMap[extension] || defaultFileIcon;
}

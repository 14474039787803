import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb as BreadcrumbBS } from 'react-bootstrap';

import { LinkContainer } from '..';

export class BreadcrumbItem extends PureComponent {
    static propTypes = {
        active: PropTypes.bool,
        href: PropTypes.string,
    };

    get styles() {
        return require('./Breadcrumb.scss');
    }

    render() {
        if (this.props.active) {
            return <BreadcrumbBS.Item {...this.props} />;
        }

        const { href, ...props } = this.props;
        return (
            <LinkContainer to={href}>
                <BreadcrumbBS.Item className={this.styles.item} {...props} />
            </LinkContainer>
        );
    }
}

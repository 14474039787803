const AUCTION_SUMMARY = 'summary';
const LINE_ITEM_DETAILS = 'line-item-details';
const QUESTION_ANSWER = 'q-and-a';
export const LIVE_Q_AND_A = 'Live Question & Answer';

export const navItems = [
    {
        name: 'Auction Summary',
        path: AUCTION_SUMMARY,
    },
    {
        name: 'Line Item Details',
        path: LINE_ITEM_DETAILS,
    },
    {
        name: LIVE_Q_AND_A,
        path: QUESTION_ANSWER,
    },
];

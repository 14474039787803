import { useSearchParams } from 'react-router-dom';

const decodeSearchParams = (searchParams) => {
    return [...searchParams.entries()].reduce((acc, [key, val]) => {
        try {
            return {
                ...acc,
                [key]: JSON.parse(val),
            };
        } catch {
            return {
                ...acc,
                [key]: val,
            };
        }
    }, {});
};

/**
 * Custom hook for handling query parameters in the URL.
 *
 * @param {string} [param] - The name of the query parameter. If not provided, returns all query parameters in an object.
 * @param {string} [defaultValue=null] - The default value for the query parameter.
 * @returns {Array} - An array containing the query parameter value and a function to update it. If no `param` is provided, returns an object with all query parameters.
 */
export const useQueryParam = (param, defaultValue = null) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const decodedParams = decodeSearchParams(searchParams);

    const queryParams = param ? decodedParams[param] || defaultValue : decodedParams;

    const setOrDeleteParam = (paramKey, paramValue) => {
        if (paramValue === null || paramValue === undefined || paramValue.length === 0) {
            searchParams.delete(paramKey);
        } else {
            searchParams.set(paramKey, paramValue);
        }
    };

    // 02/2024: subsequent calls of this function with different param will
    // cause a race condition under RR6
    const setQueryParam = (value) => {
        const parsedParam = typeof value === 'object' ? JSON.stringify(value) : value;

        setOrDeleteParam(param, parsedParam);

        setSearchParams(searchParams);
    };

    const setMultipleQueryParams = (values) => {
        const stringifyValue = (value) => {
            if (typeof value === 'object' && value !== null) {
                return JSON.stringify(value);
            }
            return value;
        };

        const parsedParams = Object.entries(values).reduce((acc, [key, value]) => {
            return {
                ...acc,
                [key]: stringifyValue(value),
            };
        }, {});

        Object.keys(parsedParams).forEach((key) => {
            setOrDeleteParam(key, parsedParams[key]);
        });

        setSearchParams(searchParams);
    };

    return [queryParams, setQueryParam, setMultipleQueryParams];
};

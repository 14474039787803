import { createSelector } from 'reselect';

import { templateSectionTypesDict } from '@og-pro/shared-config/templateSections';

import { ALL, DRAFT, LIVE } from './constants';
import { getProjectTypeSelectOptions } from '../selectors';

const { GENERAL } = templateSectionTypesDict;

const getRawTemplateSections = (state) => state.templateSections.get('templateSections');
const getLocation = (state, props) => props.location;

export const getTemplateSections = createSelector([getRawTemplateSections], (templateSections) =>
    templateSections.toJS()
);

export const getTemplateSectionSelectOptions = createSelector(
    [getProjectTypeSelectOptions],
    (options) => {
        return [
            ...options,
            {
                label: 'Both',
                value: GENERAL,
            },
        ];
    }
);

export const getSelectedProjectType = createSelector(
    [getTemplateSectionSelectOptions, getLocation],
    (projectTypeSelectOptions, location) => {
        const {
            query: { type },
        } = location;

        return (
            projectTypeSelectOptions.find((option) => option.value === type) ||
            projectTypeSelectOptions[0]
        ).value;
    }
);

const getFilteredTemplateSections = createSelector(
    [getTemplateSections, getSelectedProjectType],
    (templateSections, projectType) => {
        if (projectType === ALL) {
            return templateSections;
        }

        return templateSections.filter((templateSection) => templateSection.type === projectType);
    }
);

const getDraftTemplates = createSelector([getFilteredTemplateSections], (templateSections) => {
    return templateSections.filter((templateSection) => !templateSection.isPublished);
});

const getLiveTemplates = createSelector([getFilteredTemplateSections], (templateSections) => {
    return templateSections.filter((templateSection) => templateSection.isPublished);
});

export const getNavItems = createSelector(
    [getFilteredTemplateSections, getDraftTemplates, getLiveTemplates],
    (allTemplates, draftTemplates, liveTemplates) => {
        return [
            {
                name: 'All',
                status: ALL,
                counter: allTemplates.length,
            },
            {
                name: 'Live',
                status: LIVE,
                counter: liveTemplates.length,
            },
            {
                name: 'Draft',
                status: DRAFT,
                counter: draftTemplates.length,
            },
        ];
    }
);

export const getSelectedNavItem = createSelector(
    [getNavItems, getLocation],
    (navItems, location) => {
        const {
            query: { status },
        } = location;

        return navItems.find((item) => item.status === status) || navItems[0];
    }
);

export const getSelectedTemplateSections = createSelector(
    [getSelectedNavItem, getFilteredTemplateSections, getDraftTemplates, getLiveTemplates],
    (selectedNavItem, allTemplates, draftTemplates, liveTemplates) => {
        switch (selectedNavItem.status) {
            case ALL:
                return allTemplates;
            case LIVE:
                return liveTemplates;
            case DRAFT:
                return draftTemplates;
            default:
                return [];
        }
    }
);

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';

import { form, STATUS } from './constants';
import { SearchSelect } from '../../../../components';

const formConfig = {
    enableReinitialize: true,
    form,
};

// @reduxForm
class ConnectedStatusFilterForm extends Component {
    static propTypes = {
        formClassName: PropTypes.string,
        options: PropTypes.array.isRequired,
    };

    filterSelectStyles = {
        control: {
            height: 30,
            minHeight: 30,
            fontSize: '13px',
        },
    };

    render() {
        const { formClassName, options } = this.props;

        return (
            <Field
                aria-label="Filter Projects by Status"
                blurInputOnSelect
                component={SearchSelect}
                formClassName={formClassName}
                isSearchable={false}
                name={STATUS}
                options={options}
                overrideFeedback
                placeholder="Filter by Status"
                styles={this.filterSelectStyles}
            />
        );
    }
}

export const StatusFilterForm = reduxForm(formConfig)(ConnectedStatusFilterForm);

import { createSelector } from 'reselect';

import { navItems } from './ReverseAuctionNav/constants';
import { getProjectJS, getReverseAuctionPath } from '../App/selectors';
import { getReverseAuctionItemsJS } from '../../App/selectors';

export const getNavItems = createSelector([getReverseAuctionPath], (reverseAuctionPath) => {
    return navItems.map((item) => {
        return {
            ...item,
            route: `${reverseAuctionPath}/${item.path}`,
        };
    });
});

export const getSelectedRoute = createSelector(
    [getNavItems, (state, props) => props.location.pathname],
    (navs, pathname) => {
        return (navs.find((item) => pathname.match(new RegExp(`^${item.route}`))) || navs[0]).route;
    }
);

export const getAuctionItemSummaryInfo = createSelector(
    [getReverseAuctionItemsJS],
    (reverseAuctionItems) => {
        const activeLineItems = [];
        const noBidLineItems = [];
        let currentAuctionTotal = 0;
        let numberOfBidsTotal = 0;

        reverseAuctionItems.forEach((item) => {
            const priceItem = item.priceItems[0];

            if (priceItem.reverseAuctionBids.length > 0) {
                activeLineItems.push(item);
            } else {
                noBidLineItems.push(item);
            }

            priceItem.vendorPriceItems.forEach((vendorPriceItem, idx) => {
                if (!vendorPriceItem.noBid) {
                    numberOfBidsTotal += vendorPriceItem.reverseAuctionBids.length;

                    // vendorPriceItems[0] is the vendor with the lowest current bid
                    if (idx === 0) {
                        currentAuctionTotal +=
                            priceItem.quantity * vendorPriceItem.reverseAuctionBids[0].unitPrice;
                    }
                }
            });
        });

        return {
            activeLineItems,
            noBidLineItems,
            currentAuctionTotal,
            numberOfBidsTotal,
        };
    }
);

export const getNumberOfBidsTotal = createSelector(
    [getReverseAuctionItemsJS],
    (reverseAuctionItems) => {
        let numberOfBidsTotal = 0;

        reverseAuctionItems.forEach((item) => {
            const priceItem = item.priceItems[0];

            priceItem.vendorPriceItems.forEach((vendorPriceItem) => {
                if (!vendorPriceItem.noBid) {
                    numberOfBidsTotal += vendorPriceItem.reverseAuctionBids.length;
                }
            });
        });

        return numberOfBidsTotal;
    }
);

export const getAuctionMaxFractionDigits = createSelector([getProjectJS], (project) => {
    if (project) {
        return project.auctionMaxFractionDigits;
    }
});

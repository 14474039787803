import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export class NoContracts extends PureComponent {
    static propTypes = {
        isFiltered: PropTypes.bool,
        isPublic: PropTypes.bool,
    };

    static defaultProps = {
        isFiltered: false,
        isPublic: false,
    };

    get styles() {
        return require('./index.scss');
    }

    get message() {
        const { isFiltered, isPublic } = this.props;

        if (isFiltered) {
            return 'No contracts match the filters';
        }
        if (isPublic) {
            return 'No contracts have been added yet';
        }
        return 'No contracts created yet!';
    }

    render() {
        const { isFiltered, isPublic } = this.props;

        return (
            <div className={`row ${this.styles.noContractsContainer}`}>
                <div className="col-sm-8 col-sm-offset-2">
                    <div className={`text-muted ${this.styles.noContractsText}`}>
                        <div className={this.styles.noContractsHeader}>{this.message}</div>
                        {!isFiltered && !isPublic && (
                            <div>
                                To get started click the button to create your first contract. Your
                                list of contracts will then be displayed here.
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

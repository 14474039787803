import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ListGroup } from 'react-bootstrap';
import { connect } from 'react-redux';

import { getProjectContracts, getProjectDocBuilders } from '../../selectors';
import { getUserJS, getUserOrganizationTimezone } from '../../../../selectors';
import { LoadingSpinner, LoadingError, ProjectsListItem } from '../../../../../components';
import { ListView as ContractsListView } from '../../../../../components/ContractList/ListView';

const mapStateToProps = (state) => {
    return {
        contracts: getProjectContracts(state),
        docBuilders: getProjectDocBuilders(state),
        loadError: state.govProjects.get('loadProjectDocumentsError'),
        loading: state.govProjects.get('loadingProjectDocuments'),
        timezone: getUserOrganizationTimezone(state),
        user: getUserJS(state),
    };
};

// @connect
class ConnectedDocumentsList extends PureComponent {
    static propTypes = {
        contracts: PropTypes.array.isRequired,
        docBuilders: PropTypes.array.isRequired,
        loadError: PropTypes.string,
        loading: PropTypes.bool,
        timezone: PropTypes.string.isRequired,
        user: PropTypes.shape({
            government_id: PropTypes.number.isRequired,
        }).isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    renderNoItems(subscriptionName) {
        return (
            <div className={`text-muted ${this.styles.zeroState}`}>
                No {subscriptionName} have been created yet
            </div>
        );
    }

    renderContractsList() {
        const { contracts, timezone } = this.props;

        if (contracts.length === 0) {
            return (
                <div className={`text-muted ${this.styles.zeroState}`}>
                    No contracts have been added yet
                </div>
            );
        }

        return <ContractsListView contracts={contracts} fullWidth timezone={timezone} />;
    }

    renderDocBuildersList() {
        const {
            docBuilders,
            timezone,
            user: { government_id: governmentId },
        } = this.props;

        if (docBuilders.length === 0) {
            return this.renderNoItems('documents');
        }

        const docBuilderItems = docBuilders.map((project) => {
            return (
                <ProjectsListItem
                    key={project.id}
                    project={project}
                    timezone={timezone}
                    to={`/governments/${governmentId}/projects/${project.id}`}
                />
            );
        });

        return <ListGroup>{docBuilderItems}</ListGroup>;
    }

    render() {
        const { loadError, loading } = this.props;

        if (loading) {
            return <LoadingSpinner />;
        }

        if (loadError) {
            return <LoadingError error={loadError} />;
        }

        return this.renderContractsList();
    }
}

export const DocumentsList = connect(mapStateToProps)(ConnectedDocumentsList);

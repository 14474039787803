import React from 'react';

export const CertificationIcon = () => (
    <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.2 0.929688C1.424 0.929688 0 2.35369 0 4.12969V20.1297C0 20.9784 0.337142 21.7923 0.937258 22.3924C1.53737 22.9925 2.35131 23.3297 3.2 23.3297H16V31.3297L20.8 26.5297L25.6 31.3297V23.3297H28.8C29.6487 23.3297 30.4626 22.9925 31.0627 22.3924C31.6629 21.7923 32 20.9784 32 20.1297V8.92969V5.72969V4.12969C32 3.28099 31.6629 2.46706 31.0627 1.86695C30.4626 1.26683 29.6487 0.929688 28.8 0.929688H22.4H3.2ZM16 4.12969L20.8 7.32969L25.6 4.12969V9.72969L30.4 12.1297L25.6 14.5297V20.1297L20.8 16.9297L16 20.1297V14.5297L11.2 12.1297L16 9.72969V4.12969ZM3.2 4.12969H11.2V7.32969H3.2V4.12969ZM3.2 10.5297H8V13.7297H3.2V10.5297ZM3.2 16.9297H11.2V20.1297H3.2V16.9297Z"
            fill="#4D4F51"
        />
    </svg>
);

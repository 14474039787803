export const postOnlyInstructions =
    'Please fill in the dates below to meet your project needs. You may ' +
    'also edit the timline configuration to add, reorder, and remove ' +
    'timeline dates.';

export const writingFlowInstructions =
    'Please verify all the timeline dates previously entered are correct.';

export const helpPrivateBid =
    'A private bid is viewable only with a link. It will not be publicly displayed in your portal and will not notify vendors when the project posts.';

export const helpRequiredInvitation =
    'By default, a private bid is not posted publicly but can be accessed by vendors with a link to the bid. When this option is toggled on, only invited vendors will be able to view and submit responses.';

export const helpSealedBid =
    'A sealed bid process prevents vendor responses from being viewed until the bid is ' +
    'unsealed. Once unsealed, all responses will be viewable.';

export const helpNotifyVendorsPosting =
    'If enabled, vendors who are subscribed to you and who have the same Product Codes added to this project will be automatically notified when the project opens. This is not possible if the bid is Private.';

export const helpVendorFollowers =
    'Publicly displays all the vendors that are following updates on this project.';

export const helpPublicPricingResults =
    'Publicly displays the pricing tables or totals from each response received.';

export const helpPublicResponsesResults =
    'Publicly displays specified questionnaire responses from each vendor response received.';

export const helpQaEnabled = 'Enables the Question and Answer section for the project.';

export const helpAutoBidUnsealed =
    'Automatically unseals the bids at the response submission deadline.';

export const helpSubstitutionRequests =
    'Allow vendors to submit requests for substitution (ex. brand alternates) until the Question & Answer period ends.';

export const helpDisabledSubstitutionRequests =
    'To enable this setting, please upload a Request for Substitution form in the  "Design your template" section of Template Admin.';

export const publicPricingDisplayOptions = [
    {
        label: 'Show entire pricing table',
        value: false,
    },
    {
        label: 'Only show totals',
        value: true,
    },
];

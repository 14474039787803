import { Textarea } from './Textarea';

export const getValue = ({ value, type }) => {
    // As of react 15 all controlled inputs must have a string value.
    // https://facebook.github.io/react/blog/2016/04/07/react-v15.html
    // #new-deprecations-introduced-with-a-warning

    let inputValue = value;
    if (type === 'text' || type === 'textarea' || (type === 'number' && value !== 0)) {
        inputValue = value || '';
    }

    return inputValue;
};

export const getComponentClass = (type) => {
    let componentClass = 'input';
    if (type === 'textarea') {
        // Use custom Textarea class for textarea input types
        componentClass = Textarea;
    } else if (type === 'select') {
        componentClass = type;
    }

    return componentClass;
};

export const getBsStyleAndHelpText = (
    { help, overrideFeedback, displayValidation, error, warning },
    useOpenGovStyle = false
) => {
    let bsStyle;
    let helpText = help;
    if (overrideFeedback) {
        // bootstrap won't accept default here
        bsStyle = null;
    } else if (displayValidation && error) {
        bsStyle = 'error';

        if (!useOpenGovStyle) {
            helpText = error;
        }
    } else if (displayValidation && warning) {
        bsStyle = 'warning';

        if (!useOpenGovStyle) {
            helpText = warning;
        }
    } else if (displayValidation && !useOpenGovStyle) {
        bsStyle = 'success';
    }

    return { bsStyle, helpText };
};

import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { projectStatusesDict } from '@og-pro/shared-config/projects';

import { InternalInformationSection } from './InternalInformationSection';
import { PostInformationSection } from './PostInformationSection';
import { isBudgetUsed as isBudgetUsedSelector } from '../../../../containers/GovApp/selectors';

const { POST_PENDING } = projectStatusesDict;

export const BudgetAndPostInformation = ({ government, project, isDocx }) => {
    const isBudgetUsed = useSelector(isBudgetUsedSelector);

    return (
        <>
            {isBudgetUsed && project.budget && project.budget.amount && (
                <div className="row">
                    <div className="col-md-offset-1 col-md-10">
                        <InternalInformationSection isDocx={isDocx} project={project} />
                    </div>
                </div>
            )}
            {(project.status === POST_PENDING || project.wasPosted) && (
                <div className="row">
                    <div className="col-md-offset-1 col-md-10">
                        <PostInformationSection
                            isDocx={isDocx}
                            project={project}
                            timezone={government.timezone}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

BudgetAndPostInformation.propTypes = {
    government: PropTypes.object.isRequired,
    isDocx: PropTypes.bool,
    project: PropTypes.object.isRequired,
};

export const form = 'contractViewSelectForm';

export const VIEW = 'view';

export const DASHBOARD_VIEW = 'dashboard';
export const RESPONSES_VIEW = 'responses';

export const options = [
    { label: 'Dashboard', value: DASHBOARD_VIEW },
    { label: 'Responses', value: RESPONSES_VIEW },
];

export const VIEW_OPTIONS = options.map((option) => option.value);

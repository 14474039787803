import { AssignmentLateOutlined as AssignmentLateOutlinedIcon } from '@mui/icons-material';
import { Box } from '@og-pro/ui';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import PropTypes from 'prop-types';
import React from 'react';

import { ExceptionBody } from './ExceptionBody';
import { Step } from '../Step';

export const ExceptionStep = ({ requisition }) => {
    const styles = require('./index.scss');
    const { exceptionReviewSequence, wasExceptionRejected } = requisition;

    if (!exceptionReviewSequence) {
        return null;
    }

    const headingStyle = {
        fontSize: '14px',
        fontWeight: capitalDesignTokens.foundations.typography.fontWeight.medium,
    };

    const titleStyle = {
        alignItems: 'center',
        display: 'flex',
        gap: 1,
    };

    const iconStyle = {
        fontSize: '18px',
        minWidth: '18px',
    };

    if (!wasExceptionRejected) {
        return (
            <Step
                Heading={() => (
                    <Box sx={headingStyle}>
                        <Box
                            sx={{
                                ...titleStyle,
                                color: capitalDesignTokens.semanticColors.foreground.warningSmall,
                            }}
                        >
                            <AssignmentLateOutlinedIcon sx={iconStyle} />
                            Exception: {exceptionReviewSequence.name}
                        </Box>
                    </Box>
                )}
                panelHeadingClassName={() => styles.warning}
            />
        );
    }

    return (
        <Step
            Body={({ isOpen }) => isOpen && <ExceptionBody requisition={requisition} />}
            Heading={({ isOpen }) => (
                <Box sx={headingStyle}>
                    <Box display="flex" justifyContent="space-between">
                        <Box
                            sx={{
                                ...titleStyle,
                                color: capitalDesignTokens.semanticColors.foreground.errorSmall,
                            }}
                        >
                            <AssignmentLateOutlinedIcon sx={iconStyle} />
                            Exception Denied:
                        </Box>
                        <Box
                            className={`fa fa-fw ${isOpen ? 'fa-angle-up' : 'fa-angle-down'}`}
                            component="i"
                            sx={{ fontSize: '16px', width: '16px' }}
                        />
                    </Box>
                    <Box
                        color={capitalDesignTokens.semanticColors.foreground.primary}
                        paddingLeft={3.25}
                    >
                        {exceptionReviewSequence.name}
                    </Box>
                </Box>
            )}
            panelHeadingClassName={() => styles.error}
        />
    );
};

ExceptionStep.propTypes = {
    requisition: PropTypes.shape({
        exceptionReviewSequence: PropTypes.shape({
            name: PropTypes.string.isRequired,
        }),
        wasExceptionRejected: PropTypes.bool,
    }).isRequired,
};

import {
    createCustomFieldFormName,
    customFieldFields,
    instructionsModes,
} from '@og-pro/shared-config/customFormService/customField';
import { ReduxFormDatePicker } from '@og-pro/ui';
import React from 'react';
import { useSelector } from 'react-redux';
import { Field, getFormValues } from 'redux-form';

const { NAME, INSTRUCTIONS_MODE, INSTRUCTIONS_TEXT } = customFieldFields;
const { TOOLTIP, DESCRIPTION } = instructionsModes;

export const DatePreview = () => {
    const formValues =
        useSelector((state) => getFormValues(createCustomFieldFormName)(state)) || {};

    const instructionsText = formValues[INSTRUCTIONS_TEXT] || '';
    return (
        <Field
            component={ReduxFormDatePicker}
            description={
                formValues[INSTRUCTIONS_MODE] === DESCRIPTION ? instructionsText : undefined
            }
            disabled
            fullWidth
            label={formValues[NAME] || 'Your title will go here'}
            name="datePicker_PREVIEW"
            tooltip={formValues[INSTRUCTIONS_MODE] === TOOLTIP ? instructionsText : undefined}
        />
    );
};

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Alert, AlertTitle, Box, Button, DropdownMenu, MenuItem, Typography } from '@og-pro/ui';
import { isEmpty } from 'lodash';
import { Add as AddIcon, ChevronRight as ChevronRightIcon } from '@mui/icons-material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { Dialog } from '../../../components';
import { SDv2ErrorBanner } from '../../../components/SDv2';
import { getShowExpandedSupplierNetworkModal } from '../../../selectors/govApp';
import {
    hidePreInviteModal,
    putInviteVendorsSearchParams,
    createOrUpdatePreInviteList,
} from '../../../actions/govProjects/preInvite';
import { getProjectAssociatedCategories } from '../../../actions/project/create/projectCreate';
import { loadEmailAudits, inviteVendorsToProject } from '../../../actions/projectPost';
import { showSnackbar } from '../../../actions/notification';
import { trackEvent } from '../../../helpers';
import { getProjectJS, getWritingPath } from '../selectors';
import { SuppliersNetworkModalHeader } from './components/Header';
import { CustomGridLayout } from './components/CustomGridLayout';
import { AutomaticallyAddedRow } from './components/AutomaticallyAddedRow';
import { SearchByLocationRow } from './components/SearchByLocationRow';
import { ReadOnlySearchByLocationsRow } from './components/ReadOnlySearchByLocationsRow';
import { SearchByPersonRow } from './components/SearchByPersonRow';
import { EnterEmailAddressesRow } from './components/EnterEmailAddressesRow';
import { SearchByVendorListRow } from './components/SearchByVendorListRow';
import { PreInviteListRow } from './components/PreInviteListRow';
import {
    META_INITIAL_VALUES,
    SEARCH_METHODS,
    TOTALS_INITIAL_VALUES,
    NOTIFIED_USERS_INITIAL_VALUES,
    SELECTED_VALUES_INITIAL_VALUES,
    SEARCH_METHODS_INITIAL_VALUES,
    ASSOCIATED_CATEGORIES_INITIAL_VALUES,
} from './constants';
import { SkeletonLoading } from './components/SkeletonLoading';

const {
    EMAIL_ADDRESSES,
    SEARCH_BY_LOCATION,
    READONLY_SEARCH_BY_LOCATION,
    SEARCH_BY_PERSON,
    SEARCH_BY_VENDOR_LIST,
    AUTOMATICALLY_ADDED,
    PRE_INVITE_LIST,
} = SEARCH_METHODS;

export const SuppliersNetworkModal = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [associatedCategories, setAssociatedCategories] = useState(
        ASSOCIATED_CATEGORIES_INITIAL_VALUES
    );
    const [showCategoriesWarning, setShowCategoriesWarning] = useState(true);
    const [savingBlocked, setSavingBlocked] = useState(false);
    const [loading, setLoading] = useState(false);

    // tracks the totals for each vendor source / search method to display below.
    // It's connected to a context so child components can update these values.
    const [totals, setTotals] = useState(TOTALS_INITIAL_VALUES);
    // A list of users and their organizations that were notified when the project went live.
    const [notifiedUsers, setNotifiedUsers] = useState(NOTIFIED_USERS_INITIAL_VALUES);
    const [selectedValues, setSelectedValues] = useState(SELECTED_VALUES_INITIAL_VALUES);
    const [vendorSearchMethods, setVendorSearchMethods] = useState(SEARCH_METHODS_INITIAL_VALUES);
    // Used to store some metadata to handle complex behaviour of the component
    // when the same interaction has to cause different reactions depending on the context.
    // For example the search by location row should fetch from the API but only the first time its displayed.
    const [meta, setMeta] = useState(META_INITIAL_VALUES);

    const [error, setError] = useState(null);

    const showModal = useSelector(getShowExpandedSupplierNetworkModal);
    const project = useSelector(getProjectJS);
    const params = useParams();
    const useProjectPathBuilder = (pathname, activeSection) =>
        useSelector((state) =>
            getWritingPath(state, { params, location: { pathname }, activeSection })
        );

    const projectCategoryPath = useProjectPathBuilder('setup', 0);
    const projectPostingOptionsPath = useProjectPathBuilder('setup', 4);

    const onClose = () => {
        dispatch(hidePreInviteModal());
    };

    const closeAndNavigate = (url) => {
        onClose();
        navigate(url);
    };

    const onSave = async () => {
        setLoading(true);

        trackEvent('Expanded Supplier Network dialog save', {
            government: project.government.code,
            projectId: project.id,
            projectTitle: project.title,
            projectType: project.type,
            projectStatus: project.status,
            projectWasPosted: project.wasPosted ? 'yes' : 'no',
            templateTitle: project.template?.title,
            readOnlyLocations: selectedValues[READONLY_SEARCH_BY_LOCATION].map(
                (l) => `${l.type}: ${l.label}`
            ).join('; '),
            locationsToSave: selectedValues[SEARCH_BY_LOCATION].map(
                (l) => `${l.type}: ${l.label}`
            ).join('; '),
            ...Object.keys(totals).reduce((acc, key) => {
                return {
                    ...acc,
                    [key]: totals[key],
                };
            }, {}),
        });

        if (project.wasPosted) {
            const data = {};

            if (vendorSearchMethods[EMAIL_ADDRESSES] && selectedValues[EMAIL_ADDRESSES]) {
                data.emails = selectedValues[EMAIL_ADDRESSES];
            }

            if (vendorSearchMethods[SEARCH_BY_PERSON] && selectedValues[SEARCH_BY_PERSON]) {
                data.userEmails = selectedValues[SEARCH_BY_PERSON].map(
                    (userData) => userData.value
                );
            }

            if (
                vendorSearchMethods[SEARCH_BY_VENDOR_LIST] &&
                selectedValues[SEARCH_BY_VENDOR_LIST].length
            ) {
                data.vendorListId = selectedValues[SEARCH_BY_VENDOR_LIST][0];
            }

            if (!isEmpty(data)) {
                await dispatch(inviteVendorsToProject(project.id, data));
            }
        }

        if (!project.wasPosted) {
            await dispatch(
                createOrUpdatePreInviteList(project.id, {
                    emails: selectedValues[PRE_INVITE_LIST],
                })
            );
        }

        return dispatch(
            putInviteVendorsSearchParams(
                project.id,
                selectedValues[READONLY_SEARCH_BY_LOCATION].concat(
                    selectedValues[SEARCH_BY_LOCATION]
                )
            )
        )
            .then(() => {
                dispatch(
                    showSnackbar('Your vendors to be invited have been saved', {
                        isSuccess: true,
                    })
                );
                return onClose();
            })
            .catch((e) => {
                setError(e);
                setLoading(false);
            });
    };

    useEffect(() => {
        if (!showModal) {
            setSelectedValues(SELECTED_VALUES_INITIAL_VALUES);
            setVendorSearchMethods(SEARCH_METHODS_INITIAL_VALUES);
            setError(null);
            setLoading(false);
            setSavingBlocked(false);
            setShowCategoriesWarning(true);
            setNotifiedUsers(NOTIFIED_USERS_INITIAL_VALUES);
            setTotals(TOTALS_INITIAL_VALUES);
            setMeta(META_INITIAL_VALUES);
            setAssociatedCategories(ASSOCIATED_CATEGORIES_INITIAL_VALUES);
        }

        if (showModal) {
            dispatch(getProjectAssociatedCategories(project.id)).then((results) => {
                setAssociatedCategories({
                    loading: false,
                    categories: results,
                });
            });

            if (project.wasPosted) {
                // when the project was posted, we want to load the audit records
                // to be able to tell which users were notified already. This is strictly a UI thing
                // since the backend handles things correctly.
                dispatch(loadEmailAudits(project.id)).then(({ result }) => {
                    if (result.notifiedUsers) {
                        setNotifiedUsers({
                            ids: new Set(result.notifiedUsers.map((user) => user.id)),
                            organizationIds: new Set(
                                result.notifiedUsers.map((user) => user.organization_id)
                            ),
                            emails: new Set(result.notifiedUsers.map((user) => user.email)),
                            loading: false,
                        });
                    } else {
                        setNotifiedUsers((prev) => ({ ...prev, loading: false }));
                    }
                });
            } else {
                setNotifiedUsers((prev) => ({ ...prev, loading: false }));
            }
        }
    }, [showModal]);

    const styles = require('./index.scss');

    if (!showModal || !project) {
        return null;
    }

    const sections = [
        {
            label: 'Automatically Added',
            component: AutomaticallyAddedRow,
            key: AUTOMATICALLY_ADDED,
            hidden: project.wasPosted || project.isPrivate,
            removable: false,
            selectable: false,
        },
        // handles rendering the locations that were selected when the project was open
        // if you open the dialog, select locations, save and open again, you will see the locations
        // in this component. Those cant be removed but still trigger searches and when you save they need
        // to be saved along with whatever else you select
        {
            label: 'Added by Vendor Location',
            component: ReadOnlySearchByLocationsRow,
            key: READONLY_SEARCH_BY_LOCATION,
            hidden: !project.wasPosted || !selectedValues[READONLY_SEARCH_BY_LOCATION].length,
            selectable: false,
        },
        {
            label: 'Search by Location',
            component: SearchByLocationRow,
            key: SEARCH_BY_LOCATION,
            hidden: !vendorSearchMethods[SEARCH_BY_LOCATION] || project.isPrivate,
            removable: true,
            selectable: !vendorSearchMethods[SEARCH_BY_LOCATION] && !project.isPrivate,
            disabled: project.categories.length === 0,
        },
        {
            label: 'Pre-Invite List',
            component: PreInviteListRow,
            key: PRE_INVITE_LIST,
            hidden: !vendorSearchMethods[PRE_INVITE_LIST] || project.wasPosted,
            removable: true,
            selectable: !vendorSearchMethods[PRE_INVITE_LIST] && !project.wasPosted,
        },
        {
            label: 'Search by Vendor or Person',
            component: SearchByPersonRow,
            key: SEARCH_BY_PERSON,
            hidden: !vendorSearchMethods[SEARCH_BY_PERSON],
            removable: true,
            selectable: project.wasPosted && !vendorSearchMethods[SEARCH_BY_PERSON],
        },
        {
            label: 'Enter Email Addresses',
            component: EnterEmailAddressesRow,
            key: EMAIL_ADDRESSES,
            hidden: !vendorSearchMethods[EMAIL_ADDRESSES],
            removable: true,
            selectable: project.wasPosted && !vendorSearchMethods[EMAIL_ADDRESSES],
        },
        {
            label: 'Search by Vendor List',
            component: SearchByVendorListRow,
            key: SEARCH_BY_VENDOR_LIST,
            hidden: !vendorSearchMethods[SEARCH_BY_VENDOR_LIST],
            removable: true,
            selectable: project.wasPosted && !vendorSearchMethods[SEARCH_BY_VENDOR_LIST],
        },
    ];
    const addMoreVendorsOptions = sections.filter((section) => section.selectable);

    return (
        <Dialog
            aria-labelledby="vendors-to-be-invited-heading"
            classes={{
                scrollPaper: styles.scrollPaper,
                paper: styles.paper,
            }}
            contentProps={{ p: 0 }}
            fullWidth
            maxWidth="md"
        >
            <Box className={styles.container}>
                <SuppliersNetworkModalHeader onClose={onClose} project={project} />
                {(notifiedUsers.loading || associatedCategories.loading) && (
                    <Box px={3} py={3}>
                        <Box mb={2}>
                            <SkeletonLoading height={100} />
                        </Box>
                        <Box mb={2}>
                            <SkeletonLoading height={100} />
                        </Box>
                        <Box mb={2}>
                            <SkeletonLoading height={100} />
                        </Box>
                    </Box>
                )}
                {!(notifiedUsers.loading || associatedCategories.loading) && (
                    <>
                        <Box px={3} py={3}>
                            {!project.categories.length && showCategoriesWarning && (
                                <Box mb={3}>
                                    <Alert
                                        elevation={0}
                                        onClose={() => setShowCategoriesWarning(false)}
                                        severity="warning"
                                    >
                                        <AlertTitle>
                                            No Category Codes selected for this project
                                        </AlertTitle>
                                        <Typography>
                                            Without Category Codes, all subscribed vendors will be
                                            notified of this project. To notify more relevant
                                            vendors and enable the “Add by Vendor Location”
                                            function, select Category Codes.
                                        </Typography>
                                        <Button
                                            onClick={() => closeAndNavigate(projectCategoryPath)}
                                            qaTag="supplierNetworkModal-addCategoryCodes"
                                            size="small"
                                            variant="text"
                                        >
                                            Add Category Codes to project&nbsp;
                                            <ChevronRightIcon fontSize="inherit" />
                                        </Button>
                                    </Alert>
                                </Box>
                            )}

                            {project.isPrivate && (
                                <Box mb={3}>
                                    <Alert elevation={0} severity="info">
                                        <AlertTitle>This project is a private bid</AlertTitle>
                                        <Typography>
                                            A private bid is viewable only with a link. It will not
                                            be publicly displayed on your portal and will not notify
                                            vendors when the project posts.
                                        </Typography>
                                        <Button
                                            onClick={() =>
                                                closeAndNavigate(projectPostingOptionsPath)
                                            }
                                            qaTag="supplierNetworkModal-editPrivateBidControls"
                                            size="small"
                                            variant="text"
                                        >
                                            Edit Private Bid Controls&nbsp;
                                            <ChevronRightIcon fontSize="inherit" />
                                        </Button>
                                    </Alert>
                                </Box>
                            )}

                            {!project.notifyVendorsOnPosting && !project.isPrivate && (
                                <Box mb={3}>
                                    <Alert elevation={0} severity="info">
                                        <AlertTitle>
                                            Vendor Notifications have been turned off
                                        </AlertTitle>
                                        <Typography>
                                            Vendors subscribed to you and who have the same Category
                                            Codes added to this project will not be automatically
                                            notified when the project opens.
                                        </Typography>
                                        <Button
                                            onClick={() =>
                                                closeAndNavigate(projectPostingOptionsPath)
                                            }
                                            qaTag="supplierNetworkModal-editVendorNotifications"
                                            size="small"
                                            variant="text"
                                        >
                                            Edit Vendor Notifications&nbsp;
                                            <ChevronRightIcon fontSize="inherit" />
                                        </Button>
                                    </Alert>
                                </Box>
                            )}

                            {error && (
                                <Box mb={4}>
                                    <SDv2ErrorBanner
                                        description={
                                            error.message ||
                                            'There was an unexpected error. Close the dialog and try again.'
                                        }
                                        title="Something went wrong"
                                    />
                                </Box>
                            )}
                            <Box>
                                <Box alignItems="flex-end" display="flex" mb={1}>
                                    <CustomGridLayout
                                        columns={[
                                            <Box className={styles.tableHeaderText}>
                                                Notification List
                                            </Box>,
                                            <Box className={styles.tableHeaderText}>
                                                Vendor Contacts
                                            </Box>,
                                            <Box className={styles.tableHeaderText}>
                                                Vendor Companies
                                            </Box>,
                                            <Box>&nbsp;</Box>,
                                        ]}
                                    />
                                </Box>
                                {sections
                                    .filter((section) => !section.hidden)
                                    .map((section) => {
                                        return (
                                            <Box className={styles.tableRow} key={section.key}>
                                                <section.component
                                                    categories={associatedCategories.categories}
                                                    disabled={loading}
                                                    meta={[meta, setMeta]}
                                                    notifiedUsers={notifiedUsers}
                                                    onRemove={
                                                        section.removable
                                                            ? () => {
                                                                  setVendorSearchMethods(
                                                                      (prev) => ({
                                                                          ...prev,
                                                                          [section.key]: false,
                                                                      })
                                                                  );
                                                                  setSelectedValues((prev) => ({
                                                                      ...prev,
                                                                      [section.key]: [],
                                                                  }));
                                                                  setTotals((prev) => ({
                                                                      ...prev,
                                                                      [section.key]: 0,
                                                                  }));
                                                              }
                                                            : null
                                                    }
                                                    onSelectedValueChange={(
                                                        selected,
                                                        isSearchByLocationPreselectedValues = false
                                                    ) => {
                                                        if (!isSearchByLocationPreselectedValues) {
                                                            setSelectedValues((prev) => ({
                                                                ...prev,
                                                                [section.key]: selected || [],
                                                            }));
                                                        } else {
                                                            // handles the case of the search by location that,
                                                            // when the project is open shows an extra row of what you had previously
                                                            // selected. This is a little hack considering our dynamic component rendering
                                                            // since this is only relevant for SEARCH_BY_LOCATION.
                                                            setSelectedValues((prev) => ({
                                                                ...prev,
                                                                [READONLY_SEARCH_BY_LOCATION]:
                                                                    selected || [],
                                                            }));
                                                        }
                                                    }}
                                                    selectedValue={selectedValues[section.key]}
                                                    setError={setError}
                                                    setSavingBlocked={setSavingBlocked}
                                                    setTotal={(value) => {
                                                        setTotals((prev) => ({
                                                            ...prev,
                                                            [section.key]: value,
                                                        }));
                                                    }}
                                                />
                                            </Box>
                                        );
                                    })}
                            </Box>
                            <Box
                                alignItems="center"
                                borderTop={`1px solid ${capitalDesignTokens.foundations.colors.gray600}`}
                                display="flex"
                                pt={2}
                            >
                                <Box alignItems="center" display="fles" flex={1} pr={2}>
                                    <Box>
                                        <DropdownMenu
                                            color="primary"
                                            disabled={!addMoreVendorsOptions.length || loading}
                                            label={
                                                <>
                                                    <AddIcon fontSize="small" /> Add Vendors
                                                </>
                                            }
                                            variant="outlined"
                                        >
                                            {addMoreVendorsOptions.map((option) => (
                                                <MenuItem
                                                    disabled={option.disabled === true}
                                                    key={option.label}
                                                    onClick={() => {
                                                        setVendorSearchMethods((prev) => ({
                                                            ...prev,
                                                            [option.key]: true,
                                                        }));
                                                    }}
                                                    qaTag={`supplierNetworkModal-addMoreVendors-${option.key}`}
                                                    sx={{ px: 2, py: 0.9 }}
                                                    value={option.label}
                                                >
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </DropdownMenu>
                                    </Box>

                                    {project.wasPosted && (
                                        <Box ml={2}>
                                            <Link
                                                className={styles.previouslyNotified}
                                                target="_blank"
                                                to={`/governments/${project.government_id}/projects/${project.id}/sourcing/vendor-analytics?tab=4`}
                                            >
                                                View Previously Notified Vendors&nbsp;
                                                <ChevronRightIcon />
                                            </Link>
                                        </Box>
                                    )}
                                </Box>
                                <Box
                                    alignItems="center"
                                    display="flex"
                                    flex={1}
                                    justifyContent="flex-end"
                                >
                                    <Box className={styles.totalVendorsLabel}>
                                        {project.wasPosted
                                            ? 'Total New Invited Vendors'
                                            : 'Total Vendors to be Invited'}
                                    </Box>
                                    <Box className={styles.totalVendorsCount} pl={3}>
                                        <Box pr={4} textAlign="right">
                                            {Object.values(totals).reduce(
                                                (acc, cur) => acc + cur,
                                                0
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                                <Box style={{ minWidth: '40px' }}>&nbsp;</Box>
                            </Box>
                        </Box>

                        <Box className={styles.footer} px={3} py={3}>
                            <Box display="flex" justifyContent="flex-end">
                                <Box mr={2}>
                                    <Button
                                        color="secondary"
                                        disabled={loading}
                                        mr={2}
                                        onClick={onClose}
                                        qaTag="supplierNetworkModal-closeDialog"
                                        variant="text"
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                                <Box>
                                    <Button
                                        color="primary"
                                        disabled={loading || savingBlocked}
                                        onClick={onSave}
                                        qaTag="supplierNetworkModal-saveAndClose"
                                        variant="contained"
                                    >
                                        {project.wasPosted ? 'Notify Vendors' : 'Save & Close'}
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </>
                )}
            </Box>
        </Dialog>
    );
};

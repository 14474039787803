import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export class HeaderToggle extends PureComponent {
    static propTypes = {
        header: PropTypes.string.isRequired,
        arrowUp: PropTypes.bool,
        className: PropTypes.string,
        onClick: PropTypes.func,
    };

    render() {
        const { header, arrowUp, className, onClick } = this.props;
        const arrow = arrowUp ? 'caret-up' : 'caret-down';
        const styles = require('./HeaderToggle.scss');
        const classNames = className ? `${className} ${styles.header}` : styles.header;

        return (
            <span className={classNames} onClick={onClick}>
                {header} <i className={`fa fa-${arrow}`} />
            </span>
        );
    }
}

import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, useNavigate, useHref } from 'react-router-dom';

const isModifiedEvent = (event) =>
    !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);

export const LinkContainer = ({ children, exact, isButton, onClick, to, ...props }) => {
    const navigate = useNavigate();
    const href = useHref(to);

    if (exact) {
        return <NavLink to={to} {...props} end />;
    }

    return React.cloneElement(React.Children.only(children), {
        ...props,
        href,
        onClick: (event) => {
            if (children.props.onClick) {
                children.props.onClick(event);
            }

            if (onClick) {
                onClick(event);
            }

            if (
                !event.defaultPrevented && // onClick prevented default
                event.button === 0 && // ignore right clicks
                !isModifiedEvent(event) // ignore clicks with modifier keys
            ) {
                event.preventDefault();

                navigate(to);
            }
        },
    });
};

LinkContainer.propTypes = {
    children: PropTypes.node.isRequired,
    exact: PropTypes.bool,
    isButton: PropTypes.bool,
    onClick: PropTypes.func,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { getEvaluatorJS } from './selectors';
import {
    EvaluationTableDisplayMenu,
    EvaluatorScoresTable,
    ProposalBackButton,
    NoItems,
} from '../components';
import { getEvaluationLocationObject, getTotalWeight } from '../selectors';
import { getCurrentEvaluation, getCurrentScoredProposals } from '../../selectors';
import connectData from '../../../ConnectData';
import { shouldLoadFromEvaluationAudit } from '../../../../actions/evaluations';
import {
    loadAggregateProposalEvaluations,
    shouldReloadAggregateEvaluations,
} from '../../../../actions/proposalEvaluations';
import { LoadingError, LoadingSpinner, SectionTitle } from '../../../../components';

function fetchData(getState, dispatch, location, params) {
    const state = getState();
    if (
        shouldLoadFromEvaluationAudit(state, Object.fromEntries(location.searchParams)) ||
        !shouldReloadAggregateEvaluations(state)
    ) {
        return Promise.resolve();
    }
    const projectId = Number.parseInt(params.projectId, 10);
    return dispatch(loadAggregateProposalEvaluations(projectId));
}

const mapStateToProps = (state, props) => {
    return {
        error: state.proposalEvaluations.get('loadAggregateError'),
        evaluation: getCurrentEvaluation(state, props),
        evaluator: getEvaluatorJS(state, props),
        getEvaluationPath: getEvaluationLocationObject(state, props),
        loading: state.proposalEvaluations.get('loadingAggregate'),
        proposals: getCurrentScoredProposals(state, props),
        totalWeight: getTotalWeight(state, props),
    };
};

// @connectData
// @connect
class ConnectedEvaluatorEvaluations extends Component {
    static propTypes = {
        error: PropTypes.string,
        evaluation: PropTypes.shape({
            scoringCriteria: PropTypes.array,
        }).isRequired,
        evaluator: PropTypes.shape({
            displayName: PropTypes.string.isRequired,
        }),
        getEvaluationPath: PropTypes.func.isRequired,
        loading: PropTypes.bool,
        params: PropTypes.object.isRequired, // eslint-disable-line react/no-unused-prop-types
        proposals: PropTypes.array.isRequired,
        totalWeight: PropTypes.number.isRequired,
    };

    static defaultProps = {
        error: undefined,
        evaluator: undefined,
        loading: false,
    };

    createScoresTableRef = (ref) => {
        this.scoresTable = ref;
    };

    handleExportScoresCSVClick = () => {
        this.scoresTable.handleExportCSVClick();
    };

    renderScoresTable() {
        const { evaluation, evaluator, proposals, totalWeight } = this.props;

        return (
            <EvaluationTableDisplayMenu handleExportCSVClick={this.handleExportScoresCSVClick}>
                <EvaluatorScoresTable
                    createRef={this.createScoresTableRef}
                    evaluator={evaluator}
                    proposals={proposals}
                    scoringCriteria={evaluation.scoringCriteria}
                    totalWeight={totalWeight}
                />
            </EvaluationTableDisplayMenu>
        );
    }

    render() {
        const { error, evaluator, getEvaluationPath, loading, proposals } = this.props;

        if (loading) {
            return <LoadingSpinner />;
        }

        if (error || !evaluator) {
            return <LoadingError error={error || 'Evaluator not found'} />;
        }

        return (
            <>
                <ProposalBackButton
                    text="Back to All Evaluations"
                    to={getEvaluationPath('/aggregate-evaluations')}
                />
                <SectionTitle
                    info={`Scores for all responses evaluated by ${evaluator.displayName}`}
                    title={`${evaluator.displayName}'s Evaluations`}
                />
                {proposals.length === 0 ? (
                    <NoItems text="There are no responses to evaluate yet." />
                ) : (
                    this.renderScoresTable()
                )}
            </>
        );
    }
}

export const EvaluatorEvaluations = compose(
    connectData(fetchData),
    withRouter,
    connect(mapStateToProps)
)(ConnectedEvaluatorEvaluations);

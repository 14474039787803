import { orderBy } from 'lodash';

/**
 * Formats reverseAuctionBids data for user in ReverseAuctionBidHistoryTable
 * @param {array} reverseAuctionBids list of all bids on a price item
 * @param {number} quantity number of items for a specific price item
 * @param {number} vendorId
 * @returns {array} Mapped list of reverseAuctionBids
 */
export const getBidHistoryData = (reverseAuctionBids, quantity, vendorId) => {
    const initialBidSet = new Set();

    const highestToLowestBids = orderBy(reverseAuctionBids, ['unitPrice'], ['desc']);
    return highestToLowestBids.reduce((bidsArray, bid) => {
        const isCurrentVendor = bid.vendor_id === vendorId;
        let name = isCurrentVendor ? 'Your Bid' : 'Another Vendor';

        if (!initialBidSet.has(bid.vendor_id)) {
            initialBidSet.add(bid.vendor_id);
            name = isCurrentVendor ? 'Your Initial Bid' : "Another Vendor's Initial Bid";
        }

        // Use `unshift` to reorder the array from lowest to highest
        bidsArray.unshift({
            createdAt: bid.created_at,
            id: bid.id,
            name,
            totalBid: bid.unitPrice * quantity,
            unitPrice: bid.unitPrice,
            vendorUserId: bid.vendor_id,
        });

        return bidsArray;
    }, []);
};

export const getBidUpdateAnimationStyle = (vendorId, vendorIdOfCurrentLowestBid) => {
    return vendorId === vendorIdOfCurrentLowestBid ? 'bidUpdateWinning' : 'bidUpdateLosing';
};

import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { tokens } from '@opengov/capital-style';

import { currencyFormatter } from '@og-pro/shared-config/helpers';

const BAR_WIDTH = 20;

export const BudgetPurchaseOrdersOverview = ({
    budgetAmount,
    chartTitle,
    currentIssued,
    currentPaid,
    issuedTotal,
    paidTotal,
}) => {
    const defaultBudgetAmount = budgetAmount ?? 0;
    const purchaseOrdersTotalValue = currentIssued + currentPaid + issuedTotal + paidTotal;
    const yAxisMax = Math.max(defaultBudgetAmount, purchaseOrdersTotalValue); // This represents the total amount of money in the fiscal year/contract budget
    const remainingBudget = defaultBudgetAmount - purchaseOrdersTotalValue;
    const isOverBudget = remainingBudget < 0;

    const { colors, typography } = tokens;
    const {
        colorError300,
        colorError500,
        colorInfo300,
        colorInfo500,
        colorSuccess300,
        colorSuccess500,
        colorWarning300,
        colorWarning500,
    } = colors;

    const chartData = [
        ...(currentPaid > 0
            ? [
                  {
                      name: 'Current Paid',
                      data: [currentPaid],
                      color: isOverBudget ? colorError300 : colorSuccess300,
                      pointWidth: BAR_WIDTH,
                  },
              ]
            : []),
        {
            name: 'Paid Total',
            data: [paidTotal],
            color: isOverBudget ? colorError500 : colorSuccess500,
            pointWidth: BAR_WIDTH,
        },
        ...(currentIssued > 0
            ? [
                  {
                      name: 'Current Issued',
                      data: [currentIssued],
                      color: isOverBudget ? colorWarning300 : colorInfo300,
                      pointWidth: BAR_WIDTH,
                  },
              ]
            : []),
        {
            name: 'Issued Total',
            data: [issuedTotal],
            color: isOverBudget ? colorWarning500 : colorInfo500,
            pointWidth: BAR_WIDTH,
        },
    ];

    const chartOptions = {
        chart: {
            height: 175,
            type: 'bar',
            events: {
                render() {
                    const chart = this;
                    const { renderer, title } = chart;

                    chart.budgetAmountText?.destroy();
                    chart.budgetAmountValue?.destroy();
                    chart.remainingText?.destroy();
                    chart.remainingAmountValue?.destroy();

                    const titleYPosition = title.alignAttr.y;

                    const remainingAmountFormatted = currencyFormatter(remainingBudget);
                    const remainingAmountXPosition =
                        chart.chartWidth - chart.plotLeft - remainingAmountFormatted.length * 6;

                    const remainingTextXPosition = remainingAmountXPosition - 70;

                    const budgetAmountFormatted =
                        defaultBudgetAmount === 0 ? 'N/A' : currencyFormatter(defaultBudgetAmount);
                    const budgetAmountXPosition =
                        remainingAmountXPosition - 100 - budgetAmountFormatted.length * 6;
                    const budgetTextXPosition = budgetAmountXPosition - 50;

                    chart.budgetAmountText = renderer
                        .text('Budget:', budgetTextXPosition, titleYPosition)
                        .css({
                            fontWeight: 500,
                        })
                        .add();
                    chart.budgetAmountValue = renderer
                        .text(budgetAmountFormatted, budgetAmountXPosition, titleYPosition)
                        .css({
                            fontWeight: 400,
                        })
                        .add();

                    chart.remainingText = renderer
                        .text('Remaining:', remainingTextXPosition, titleYPosition)
                        .css({
                            fontWeight: 500,
                        })
                        .add();

                    chart.remainingAmountValue = renderer
                        .text(remainingAmountFormatted, remainingAmountXPosition, titleYPosition)
                        .css({
                            fontWeight: 400,
                        })
                        .add();
                },
            },
            style: {
                fontFamily: '"Benton Sans", Helvetica, Arial, sans-serif',
            },
        },
        credits: {
            enabled: false,
        },
        exporting: {
            enabled: false,
        },
        legend: {
            labelFormatter() {
                const { name, yData } = this;

                // TODO: Replace font weight with font-weight-medium and font-weight-regular when updating CDS
                return `<span style="font-weight:500">${name}:</span> <span style="font-weight:400">${currencyFormatter(
                    yData[0]
                )}</span>`;
            },
            reversed: true,
        },
        plotOptions: {
            series: {
                stacking: 'normal',
            },
        },
        series: chartData,
        title: {
            align: 'left',
            text: chartTitle,
            style: {
                fontSize: '14px',
                fontWeight: typography.fontWeightBold,
            },
        },
        tooltip: {
            formatter() {
                const { series } = this;
                const { name, data } = series;
                const { y } = data[0];

                return `<strong>${name}</strong>: ${currencyFormatter(y)}`;
            },
        },
        xAxis: {
            visible: false,
        },
        yAxis: {
            labels: {
                // Access to the axis labels is done with {text}
                // https://api.highcharts.com/highcharts/yAxis.labels
                // Demo: https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/highcharts/yaxis/labels-format/
                // eslint-disable-next-line no-template-curly-in-string
                format: '${text}',
            },
            max: yAxisMax,
            title: {
                text: undefined,
            },
        },
    };

    return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

BudgetPurchaseOrdersOverview.propTypes = {
    budgetAmount: PropTypes.number,
    currentIssued: PropTypes.number,
    currentPaid: PropTypes.number,
    issuedTotal: PropTypes.number.isRequired,
    paidTotal: PropTypes.number.isRequired,
    chartTitle: PropTypes.string.isRequired,
};

BudgetPurchaseOrdersOverview.defaultProps = {
    currentIssued: 0,
    currentPaid: 0,
};

import {
    isPoCreated,
    isRequisitionDraft,
    isRequisitionSubmitted,
} from '@og-pro/shared-config/requisitions';

import {
    stripesPattern,
    visualizationColors,
    visualizationItemsDict,
} from './Visualization/constants';

const { SPENT, COMMITTED, IN_PROCESS, AVAILABLE, OVER_BUDGET, TOTAL } = visualizationItemsDict;

const getSumOfValues = (data) => {
    return data.reduce((accumulator, currentValue) => {
        if (currentValue.value > 0) {
            return accumulator + currentValue.value;
        }
        return accumulator;
    }, 0);
};
export const formatChartData = (
    data,
    budgetCheckPass,
    requisitionStatus,
    usePreEncumbrance,
    isExpenseAccount
) => {
    const isDraft = isRequisitionDraft(requisitionStatus);
    const isSubmitted = isRequisitionSubmitted(requisitionStatus);
    const initialBars = [SPENT, COMMITTED];

    // If the government is including pre-encumbrances in the budget check, add the IN_PROCESS bar
    // If the government is excluding pre-encumbrances in the budget check, but the requisition is submitted, add the IN_PROCESS bar to display the requested amount
    if (usePreEncumbrance || isSubmitted) {
        initialBars.push(IN_PROCESS);
    }

    let totalRemaining = data[TOTAL];
    let accumulatedValue = 0;

    const chartData = [];
    let overTotalPlotLine = {};

    // Display hashed color when the value of the item is over the total
    initialBars.forEach((item) => {
        const amountUnderTotal = Math.min(data[item], totalRemaining);
        const amountOverTotal = data[item] - Math.max(totalRemaining, 0);

        chartData.push({
            name: item,
            value: Math.max(amountUnderTotal, 0),
            color: visualizationColors[item],
        });

        if (amountOverTotal > 0) {
            chartData.push({
                name: `${item}OverTotal`,
                value: amountOverTotal,
                color: {
                    pattern: {
                        ...stripesPattern,
                        color: visualizationColors[item],
                        opacity: 1,
                    },
                },
                showInLegend: false,
            });

            const plotLineValue = accumulatedValue + Math.max(amountUnderTotal, 0);

            if (amountUnderTotal > 0) {
                // Add a plot line to separate the over total value
                overTotalPlotLine = {
                    value: plotLineValue,
                    color: visualizationColors.separator,
                    width: 1,
                    zIndex: 4,
                };
            }
        }

        totalRemaining -= data[item];
        accumulatedValue += data[item];
    });

    if (isExpenseAccount && totalRemaining >= 0) {
        chartData.push({
            name: AVAILABLE,
            value: totalRemaining,
            color: {
                pattern: stripesPattern,
            },
        });
    }

    if (!budgetCheckPass && isDraft) {
        chartData.push({
            name: OVER_BUDGET,
            value: data.requestedAmount - Math.max(data.availableAmount, 0),
            color: {
                pattern: {
                    ...stripesPattern,
                    color: visualizationColors.red,
                    opacity: 1,
                },
            },
            showInLegend: false,
        });
    }

    return {
        data: chartData.reverse(),
        overTotalPlotLine,
        sumOfValues: getSumOfValues(chartData),
    };
};

export const getPlotLines = (sumOfValues, budgetCheckPass, data) => {
    return [
        {
            value: sumOfValues,
            color: visualizationColors.separator,
            width: 1,
            zIndex: 4,
        },
        ...(!budgetCheckPass && data[AVAILABLE] > 0
            ? [
                  {
                      value: data[TOTAL],
                      color: visualizationColors.separator,
                      width: 1,
                      zIndex: 4,
                  },
              ]
            : []),
    ];
};

export const getAnnotationPositions = (data, requisitionStatus, usePreEncumbrance) => {
    const isDraft = isRequisitionDraft(requisitionStatus);
    const isPOCreated = isPoCreated(requisitionStatus);
    const isSubmitted = isRequisitionSubmitted(requisitionStatus);

    let annotationStart = data[SPENT] + data[COMMITTED];

    // If the government is including pre-encumbrances in the budget check, the annotation rectangle should start from the IN_PROCESS value. Same for submitted requisitions
    if (usePreEncumbrance || isSubmitted) {
        annotationStart += data[IN_PROCESS];
    }

    if (!isDraft) {
        annotationStart -= data.requestedAmount;
    }

    if (isPOCreated) {
        // If the PO is created, the annotation should start from the committed value
        annotationStart -= data[IN_PROCESS];
    }

    const annotationEnd = annotationStart + data.requestedAmount;

    return {
        annotationStart,
        annotationEnd,
    };
};

/**
 * Format a value as percent.
 *
 * @param {object} data The data object to use to process the formatting
 * @param {number} data.value The number to format as percent
 * @return {any|number} The original value if `options.value` is not a number, the formatted
 *                        percent string if it is.
 */
const percentFormatter = (data) => {
    if (!Number.isNaN(Number.parseFloat(data.value))) {
        return `${data.value}%`;
    }

    return data.value;
};

export { percentFormatter };

import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';

import { fieldNames } from './constants';
import { SearchSelect } from '../../../../components';
import { getDepartmentsSelectOptions } from '../../../selectors';

const { DEPARTMENT_ID } = fieldNames;

export const DepartmentField = ({ disabled, showLabel }) => {
    const departmentsSelectOptions = useSelector(getDepartmentsSelectOptions);

    return (
        <Field
            aria-label="Select Department"
            component={SearchSelect}
            disabled={disabled}
            label={showLabel ? 'Department' : undefined}
            name={DEPARTMENT_ID}
            options={departmentsSelectOptions}
            placeholder="Select department..."
        />
    );
};

DepartmentField.propTypes = {
    disabled: PropTypes.bool,
    showLabel: PropTypes.bool,
};

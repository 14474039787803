import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { contractFilterTypesDict } from '@og-pro/shared-config/contracts';

import { ContractSearchOption } from './ContractSearchOption';
import { fieldNames } from '../../../../constants';
import { ContractSearchInput, VendorSearchInput } from '../../../../../../../../components';

const { CONTRACT_ID, VENDOR_ID } = fieldNames;

export const ResourceInput = ({
    disabled,
    isVendorSearch,
    onContractChange,
    onVendorChange,
    showFormValidation,
}) => {
    const sharedProps = {
        autoFocus: true,
        disabled,
        noMarginBottom: true,
        placeholder: '',
        showValidation: showFormValidation,
        useOpenGovStyle: true,
    };

    if (isVendorSearch) {
        return (
            <Field
                {...sharedProps}
                component={VendorSearchInput}
                filterPendingUsersWithoutOrganization
                includeVendorId
                name={VENDOR_ID}
                onOptionChange={onVendorChange}
                optionValueField="vendorId"
            />
        );
    }

    return (
        <Field
            {...sharedProps}
            component={ContractSearchInput}
            components={{
                Option: ContractSearchOption,
            }}
            name={CONTRACT_ID}
            onChange={onContractChange}
            rawValue
            searchData={{
                filters: [{ type: contractFilterTypesDict.HAS_VENDOR, value: true }],
            }}
        />
    );
};

ResourceInput.propTypes = {
    disabled: PropTypes.bool,
    isVendorSearch: PropTypes.bool,
    onContractChange: PropTypes.func.isRequired,
    onVendorChange: PropTypes.func.isRequired,
    showFormValidation: PropTypes.bool,
};

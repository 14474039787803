import { projectFilterTypesDict, projectStatusesDict } from '@og-pro/shared-config/projects';

import { fieldNames } from './GovProjectsFilterForm/constants';
import { DEFAULT_PAGE_SIZE } from './constants';

const {
    CATEGORIES,
    DEPARTMENT_ID,
    PROJECT_ID,
    QUICK_SEARCH_QUERY,
    SEARCH_PRICE_ITEMS,
    STATUS,
    TITLE,
} = fieldNames;

const { OPEN } = projectStatusesDict;

export const getInitialFormValuesFromQueryParams = (
    queryParams,
    includePaginationAndSort = false
) => {
    const formValues = {};

    const queryTitle = queryParams[TITLE];
    const queryProjectId = queryParams[PROJECT_ID];
    const queryDepartmentId = queryParams[DEPARTMENT_ID];
    const queryStatus = queryParams[STATUS];
    const queryLineItemSearch = queryParams.lineItemSearch;

    if (queryTitle) {
        formValues[TITLE] = queryTitle;
        formValues.isAdvancedSearch = true;
    }

    if (queryProjectId) {
        formValues[PROJECT_ID] = queryProjectId;
        formValues.isAdvancedSearch = true;
    }

    if (queryDepartmentId && queryDepartmentId !== 'all') {
        formValues[DEPARTMENT_ID] = parseInt(queryDepartmentId, 10);
    } else {
        formValues[DEPARTMENT_ID] = 'all';
    }

    if (queryStatus) {
        formValues[STATUS] = queryStatus;
    } else {
        formValues[STATUS] = OPEN;
    }

    if (queryLineItemSearch) {
        formValues[QUICK_SEARCH_QUERY] = queryLineItemSearch;
        formValues[SEARCH_PRICE_ITEMS] = true;
        formValues.isAdvancedSearch = true;
    }

    if (queryParams[CATEGORIES]) {
        try {
            const parsed = JSON.parse(queryParams[CATEGORIES]);
            if (Array.isArray(parsed) && parsed.length > 0) {
                formValues[CATEGORIES] = parsed;
            }
        } catch (e) {} // eslint-disable-line
    }

    if (includePaginationAndSort) {
        if (queryParams.page) {
            formValues.page = parseInt(queryParams.page, 10);
        }

        if (queryParams.limit) {
            formValues.limit = parseInt(queryParams.limit, 10);
        }

        if (queryParams.sortField) {
            formValues.sortField = queryParams.sortField;
        }

        if (queryParams.sortDirection) {
            formValues.sortDirection = queryParams.sortDirection;
        }
    }

    return formValues;
};

// Only used for category filtering on project search API requests (rest is done client side)
// Line item searching uses all for filtering on the API requests
export const buildServerRequest = (formData) => {
    const requestData = {
        filters: [],
        [QUICK_SEARCH_QUERY]: formData[QUICK_SEARCH_QUERY] || null,
        limit: Number.isNaN(parseInt(formData.limit, 10))
            ? DEFAULT_PAGE_SIZE
            : parseInt(formData.limit, 10),
        page: Number.isNaN(parseInt(formData.page, 10)) ? 1 : parseInt(formData.page, 10),
    };

    if (formData.sortField && formData.sortDirection) {
        requestData.sortField = formData.sortField;
        requestData.sortDirection = formData.sortDirection;
    }

    if (
        formData[CATEGORIES] &&
        Array.isArray(formData[CATEGORIES]) &&
        formData[CATEGORIES].length > 0
    ) {
        requestData.filters.push({
            type: projectFilterTypesDict.CATEGORIES,
            value: formData[CATEGORIES].map((category) => category.id),
        });
    }

    if (formData[DEPARTMENT_ID] && formData[DEPARTMENT_ID] !== 'all') {
        requestData.filters.push({
            type: projectFilterTypesDict.DEPARTMENT_ID,
            value: formData[DEPARTMENT_ID],
        });
    }

    if (formData[PROJECT_ID]) {
        requestData.filters.push({
            type: projectFilterTypesDict.FINANCIAL_ID,
            value: formData[PROJECT_ID],
        });
    }

    if (formData[TITLE]) {
        requestData.filters.push({
            type: projectFilterTypesDict.TITLE,
            value: formData[TITLE],
        });
    }

    // `COMING_SOON` is a filter and not a status in the project search API, so needs to be handled
    // differently than all other client status values
    if (formData[STATUS] === 'comingSoon') {
        delete requestData[STATUS];
        requestData.filters.push({
            type: projectFilterTypesDict.COMING_SOON,
            value: true,
        });
    } else if (formData[STATUS]) {
        requestData.filters.push({
            type: projectFilterTypesDict.STATUS,
            value: formData[STATUS],
        });
    }

    return requestData;
};

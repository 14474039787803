import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { CDSButton } from '../CDSButtons';

export const SectionDescriptionButton = (props) => {
    const { disabled, isEditable, handleClick } = props;
    const styles = {};

    if (!isEditable) {
        return null;
    }

    return (
        <CDSButton
            className={classnames(styles.addSectionContainer, disabled && styles.disabled)}
            disabled={disabled}
            onClick={disabled ? undefined : handleClick}
            qaTag="sectionDescription-add"
            size="small"
            variant="text"
        >
            <i className="fa fa-plus" /> Add Optional Description to Section
        </CDSButton>
    );
};

SectionDescriptionButton.propTypes = {
    disabled: PropTypes.bool,
    isEditable: PropTypes.bool,
    handleClick: PropTypes.func,
};

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@og-pro/ui';
import { Field } from 'redux-form';
import { isEmail } from 'validator';
import { debounce } from 'lodash';
import { Close as CloseIcon } from '@mui/icons-material';

import { CDSButton, InputText } from '../../../../components';
import { CustomGridLayout } from './CustomGridLayout';
import { emailsFieldName } from '../constants';

export const CommonEmailListRow = ({
    disabled,
    label,
    onRemove,
    onSelectedValueChange,
    selectedValue,
}) => {
    const styles = require('../index.scss');

    const onChange = useCallback((value) => {
        const asArray = value.split(',').reduce((acc, email) => {
            const trimmed = email.trim();

            if (trimmed === '' || trimmed === ',') {
                return acc;
            }

            return acc.concat([email.trim()]);
        }, []);

        const hasInvalid = asArray.some((email) => !isEmail(email.trim()));

        if (hasInvalid) {
            return onSelectedValueChange([]);
        }

        return onSelectedValueChange(asArray);
    }, []);
    const debouncedOnChange = debounce(onChange, 500);

    return (
        <CustomGridLayout
            columns={[
                <Field
                    component={InputText}
                    disabled={disabled}
                    hasFeedback={false}
                    help="Use commas to separate multiple emails"
                    label={label}
                    minRows={1}
                    name={emailsFieldName}
                    onChange={(e, v) => {
                        debouncedOnChange(v);
                    }}
                    placeholder="Enter email address"
                    qaTag="supplierNetworkModal-emails"
                    showValidation
                    type="textarea"
                    useOpenGovStyle
                />,
                <Box className={styles.tableCount} pt={3}>
                    {selectedValue.length || 0}
                </Box>,
                <Box className={styles.tableCount} pt={3}>
                    {selectedValue.length || 0}
                </Box>,
                <Box pt={3} textAlign="right">
                    <Box
                        aria-label="Remove search option"
                        className={styles.errorDark}
                        component={CDSButton}
                        disabled={disabled}
                        noPadding
                        onClick={onRemove}
                        qaTag="supplierNetworkModal-removeSearchOption"
                        variant="text"
                    >
                        <CloseIcon fontSize="small" />
                    </Box>
                </Box>,
            ]}
        />
    );
};

CommonEmailListRow.propTypes = {
    disabled: PropTypes.bool,
    label: PropTypes.string.isRequired,
    onRemove: PropTypes.func.isRequired,
    onSelectedValueChange: PropTypes.func.isRequired,
    selectedValue: PropTypes.arrayOf(PropTypes.string),
};

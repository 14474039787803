import { fromJS } from 'immutable';

import * as projectPostActions from '../actions/projectPost';
import * as projectCreateActions from '../actions/project/create/projectCreate';

const vendorInviteModalInitialState = {
    inviteVendorsError: null,
    invitingVendors: false,
    showVendorInviteModal: false,
};

const vendorAnalyticsDataInitialState = {
    loadedVendorClassificationUsers: false,
    loadedVendorData: false,
    loadedVendorEmailAudits: false,
    loadingVendorData: false,
    loadVendorDataError: null,
    vendorClassificationGovlia: null,
    vendorClassificationUsers: null,
    vendorData: null,
    vendorEmailAuditData: null,
};

const initialState = fromJS({
    ...vendorInviteModalInitialState,
    ...vendorAnalyticsDataInitialState,
    shouldSubmit: false,
    shouldUpdate: false,
    showFormErrors: false,
    showPostConfirmationModal: false,
    showShareLinkModal: false,
});

export default function projectPostReducer(state = initialState, action = {}) {
    switch (action.type) {
        case projectPostActions.INITIALIZE:
            return state.set('showFormErrors', false);
        case projectPostActions.INITIATE_UPDATE:
            return state.set('shouldUpdate', true);
        case projectPostActions.INITIATE_SUBMIT:
            return state.set('shouldSubmit', true);
        case projectPostActions.SHOW_FORM_VALIDATION:
            return state.set('showFormErrors', true);
        case projectCreateActions.UPDATE_SUCCESS:
        case projectCreateActions.UPDATE_FAIL:
            return state.merge(
                fromJS({
                    shouldUpdate: false,
                    shouldSubmit: false,
                })
            );
        case projectPostActions.SHOW_SHARE_LINK_MODAL:
            return state.merge(
                fromJS({
                    showShareLinkModal: true,
                    shareLink: action.value,
                })
            );
        case projectPostActions.HIDE_SHARE_LINK_MODAL:
            return state.merge(
                fromJS({
                    showShareLinkModal: false,
                    shareLink: null,
                })
            );
        case projectPostActions.SHOW_POST_MODAL:
            return state.set('showPostConfirmationModal', true);
        case projectPostActions.HIDE_POST_MODAL:
            return state.set('showPostConfirmationModal', false);
        case projectPostActions.SHOW_VENDOR_INVITE_MODAL:
            return state.set('showVendorInviteModal', true);
        case projectPostActions.HIDE_VENDOR_INVITE_MODAL:
            return state.merge(fromJS(vendorInviteModalInitialState));
        case projectPostActions.LOAD_VENDOR_DATA:
            return state.merge(
                fromJS({
                    loadedVendorData: false,
                    loadingVendorData: true,
                    loadVendorDataError: null,
                })
            );
        case projectPostActions.LOAD_VENDOR_DATA_SUCCESS:
            return state.merge(
                fromJS({
                    loadedVendorData: true,
                    loadingVendorData: false,
                    vendorData: fromJS(action.result),
                })
            );
        case projectPostActions.LOAD_VENDOR_DATA_FAIL:
            return state.merge(
                fromJS({
                    loadingVendorData: false,
                    loadVendorDataError: action.error && action.error.message,
                })
            );
        case projectPostActions.LOAD_VENDOR_EMAIL_AUDITS_SUCCESS:
            return state.merge(
                fromJS({
                    loadedVendorEmailAudits: true,
                    vendorEmailAuditData: action.result,
                })
            );
        case projectPostActions.LOAD_VENDOR_CLASSIFICATION_SUCCESS:
            return state.merge(
                fromJS({
                    loadedVendorClassificationUsers: true,
                    vendorClassificationUsers: fromJS(action.result.users),
                    vendorClassificationGovlia: fromJS(action.result.govliaClassifications),
                })
            );
        case projectPostActions.RESET_VENDOR_DATA:
            return state.merge(fromJS(vendorAnalyticsDataInitialState));
        case projectPostActions.INVITE_VENDORS:
            return state.merge(
                fromJS({
                    invitingVendors: true,
                    inviteVendorsError: null,
                })
            );
        case projectPostActions.INVITE_VENDORS_SUCCESS:
            return state.merge(
                fromJS({
                    invitingVendors: false,
                })
            );
        case projectPostActions.INVITE_VENDORS_FAIL:
            return state.merge(
                fromJS({
                    invitingVendors: false,
                    inviteVendorsError: action.error && action.error.message,
                })
            );
        default:
            return state;
    }
}

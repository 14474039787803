import PropTypes from 'prop-types';
import { orderBy } from 'lodash';
import React, { useState, useEffect } from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';

import { TemplateSectionsListItem } from './TemplateSectionsListItem';
import { Button, LoadingSpinner, ZeroState } from '../../../../components';

export const TemplateSectionsList = ({
    hasNoCreatedSections,
    routingHandler,
    TemplateCreateButton,
    templatesAdminPath,
    templateSections,
}) => {
    const [orderedTemplates, setOrderedTemplates] = useState([]);
    const [order, setOrder] = useState({ orderByField: 'title', isOrderDescending: true });
    const { orderByField, isOrderDescending } = order;

    const styles = require('./index.scss');

    useEffect(() => {
        setOrderedTemplates(
            orderBy(
                templateSections,
                [`projectSection.${orderByField}`],
                [isOrderDescending ? 'asc' : 'desc']
            )
        );
    }, [templateSections, order]);

    const onOrderClick = (field) => {
        setOrder({
            orderByField: field,
            // if the same field is click, just reverse order
            isOrderDescending: field === orderByField ? !isOrderDescending : isOrderDescending,
        });
    };

    if (templateSections.length === 0) {
        const props = {
            button: hasNoCreatedSections ? <TemplateCreateButton bsSize="lg" /> : undefined,
            title: hasNoCreatedSections
                ? 'No shared sections created yet'
                : 'No shared sections here!',
        };
        return <ZeroState {...props} />;
    }

    if (templateSections.length !== orderedTemplates.length) {
        return <LoadingSpinner />;
    }

    return (
        <>
            <div className={`row ${styles.listHeader} hidden-xs hidden-sm`}>
                <div className="col-md-3">
                    <Button
                        bsStyle="link"
                        className={styles.orderableHeading}
                        onClick={() => onOrderClick('title')}
                        qaTag="sharedTemplatesList-titleColumnWithSort"
                        zeroPadding
                    >
                        Title&nbsp;
                        <i
                            className={`fa ${
                                isOrderDescending ? 'fa-sort-alpha-asc' : 'fa-sort-alpha-desc'
                            }`}
                        />
                    </Button>
                </div>
                <div className="col-md-2">
                    <Button
                        bsStyle="link"
                        className={styles.orderableHeading}
                        onClick={() => onOrderClick('shortName')}
                        qaTag="sharedTemplatesList-shortNameColumnWithSort"
                        zeroPadding
                    >
                        Short Name&nbsp;
                        <i
                            className={`fa ${
                                isOrderDescending ? 'fa-sort-alpha-asc' : 'fa-sort-alpha-desc'
                            }`}
                        />
                    </Button>
                </div>
                <div className="col-md-2">Currently Working</div>
                <div className="col-md-3">
                    <div className="row">
                        <div className="col-md-4">Last Updated</div>
                        <div className="col-md-8">Templates In Use</div>
                    </div>
                </div>
                <div className="col-md-2">Owner</div>
            </div>
            <ListGroup>
                {orderedTemplates.map((templateSection) => {
                    return (
                        <ListGroupItem
                            key={templateSection.id}
                            onClick={routingHandler(templateSection.id)}
                        >
                            <TemplateSectionsListItem
                                templateSection={templateSection}
                                templatesAdminPath={templatesAdminPath}
                            />
                        </ListGroupItem>
                    );
                })}
            </ListGroup>
        </>
    );
};

TemplateSectionsList.propTypes = {
    hasNoCreatedSections: PropTypes.bool,
    routingHandler: PropTypes.func.isRequired,
    TemplateCreateButton: PropTypes.func.isRequired,
    templatesAdminPath: PropTypes.string.isRequired,
    templateSections: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
        })
    ).isRequired,
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Collapse as CollapseBS } from 'react-bootstrap';

export class Collapse extends PureComponent {
    static propTypes = {
        noEmptyDiv: PropTypes.bool,
    };

    render() {
        const { noEmptyDiv, ...props } = this.props;
        if (noEmptyDiv) {
            return <CollapseBS {...props} />;
        }

        // Adds an empty zero margin / zero padding div to ensure a smooth transition animation
        // https://react-bootstrap.github.io/utilities/transitions/#transitions-collapse
        const { children, ...rest } = props;
        return (
            <CollapseBS {...rest}>
                <div>{children}</div>
            </CollapseBS>
        );
    }
}

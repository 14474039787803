import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { RelationGroup } from './RelationGroup';
import { getReqRelationsForDisplay } from './selectors';

export const RequisitionRelationsDisplay = ({ disabled, isEditing, selectHandler }) => {
    const reqRelations = useSelector(getReqRelationsForDisplay);

    return reqRelations.map((reqRelation) => (
        <RelationGroup
            disabled={disabled}
            isEditing={isEditing}
            key={reqRelation.id}
            reqRelation={reqRelation}
            selectHandler={selectHandler}
        />
    ));
};

RequisitionRelationsDisplay.propTypes = {
    disabled: PropTypes.bool,
    isEditing: PropTypes.bool,
    selectHandler: PropTypes.func.isRequired,
};

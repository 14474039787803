import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Pagination as PaginationBS } from 'react-bootstrap';
import { times } from 'lodash';
import classNames from 'classnames';

import { CDSButton } from '../SDv2';

export class Pagination extends PureComponent {
    static propTypes = {
        activePage: PropTypes.number.isRequired,
        bsSize: PropTypes.string,
        className: PropTypes.string,
        items: PropTypes.number.isRequired,
        maxButtons: PropTypes.number.isRequired,
        next: PropTypes.bool,
        onSelect: PropTypes.func.isRequired,
        prev: PropTypes.bool,
        useOpenGovStyle: PropTypes.bool,
        nextDisabled: PropTypes.bool,
        prevDisabled: PropTypes.bool,
        nextHidden: PropTypes.bool,
        prevHidden: PropTypes.bool,
    };

    static defaultProps = {
        className: '',
        next: false,
        prev: false,
        useOpenGovStyle: false,
        nextDisabled: false,
        prevDisabled: false,
        nextHidden: false,
        prevHidden: false,
    };

    get pagesToDisplay() {
        return Math.min(this.props.maxButtons, this.props.items);
    }

    get startPage() {
        const { activePage, items } = this.props;
        const pagesToDisplay = this.pagesToDisplay;

        const firstPage = activePage - Math.floor((pagesToDisplay - 1) / 2);
        const lastPage = activePage + Math.ceil((pagesToDisplay - 1) / 2);

        if (firstPage >= 1 && lastPage <= items) return firstPage;
        if (firstPage >= 1) return firstPage - (lastPage - items);
        return 1;
    }

    handlePrevClick = () => {
        const { activePage, onSelect } = this.props;

        onSelect(activePage - 1);
    };

    handleNextClick = () => {
        const { activePage, onSelect } = this.props;

        onSelect(activePage + 1);
    };

    render() {
        const styles = require('./Pagination.scss');

        const {
            activePage,
            bsSize,
            className,
            next,
            onSelect,
            prev,
            useOpenGovStyle,
            nextHidden,
            prevHidden,
        } = this.props;

        const startPage = this.startPage;

        const paginationItems = times(this.pagesToDisplay, (itemNum) => {
            const page = startPage + itemNum;
            const isActive = activePage === page;
            return (
                <PaginationBS.Item
                    active={isActive}
                    className={classNames({
                        [styles.item]: useOpenGovStyle && !isActive,
                        [styles.activeItem]: useOpenGovStyle && isActive,
                    })}
                    key={page}
                    onClick={() => {
                        if (!isActive) {
                            onSelect(page);
                        }
                    }}
                >
                    {page}
                </PaginationBS.Item>
            );
        });

        const previousBtn = useOpenGovStyle ? (
            <CDSButton
                aria-label="Previous page button"
                className={classNames(styles.navButton, styles.previous)}
                disabled={this.props.prevDisabled}
                onClick={this.handlePrevClick}
                qaTag="pagination-previous"
                variant="secondary"
            >
                <i className="fa fa-chevron-left" />
                Back
            </CDSButton>
        ) : (
            <PaginationBS.Prev
                aria-label="Previous page button"
                disabled={!prev}
                onClick={this.handlePrevClick}
            />
        );

        const nextBtn = useOpenGovStyle ? (
            <CDSButton
                aria-label="Next page button"
                className={classNames(styles.navButton, styles.next)}
                disabled={this.props.nextDisabled}
                onClick={this.handleNextClick}
                qaTag="pagination-next"
                variant="secondary"
            >
                Next
                <i className="fa fa-chevron-right" />
            </CDSButton>
        ) : (
            <PaginationBS.Next
                aria-label="Next page button"
                disabled={!next}
                onClick={this.handleNextClick}
            />
        );

        return (
            <PaginationBS
                bsSize={bsSize}
                className={classNames(
                    {
                        [styles.container]: useOpenGovStyle,
                    },
                    className
                )}
            >
                {prev && !prevHidden && previousBtn}
                {paginationItems}
                {next && !nextHidden && nextBtn}
            </PaginationBS>
        );
    }
}

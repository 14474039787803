export const OVERVIEW = 'overview';
export const QUESTIONNAIRE = 'questionnaire';
export const PROPOSALS = 'proposals';
export const CRITERIA = 'criteria';
export const EVALUATORS = 'evaluators';
export const ADDENDA = 'addenda';
export const QUESTION_ANSWER = 'q-and-a';

export const getNavItems = (hasQuestionnaireSection, wasPosted) => {
    return [
        { title: 'Project Overview', section: OVERVIEW },
        hasQuestionnaireSection
            ? { title: 'Vendor Questionnaire', section: QUESTIONNAIRE }
            : { title: 'Response Documents', section: PROPOSALS },
        { title: 'Evaluation Criteria', section: CRITERIA },
        { title: 'Evaluators', section: EVALUATORS },
    ].concat(
        wasPosted
            ? [
                  { title: 'Addenda & Notices', section: ADDENDA, isRoute: true },
                  { title: 'Question & Answer', section: QUESTION_ANSWER, isRoute: true },
              ]
            : []
    );
};

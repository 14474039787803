import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';

import { form, TEMPLATE_QUESTION_ID } from './constants';
import {
    SearchSelect,
    SearchSelectIconOption,
    SearchSelectIconValue,
} from '../../../../components';

const formConfig = {
    form,
};

// @reduxForm
export const ConnectedTemplateQuestionAddForm = ({
    options,
    onBlur,
    size,
    useOpenGovStyle = false,
}) => {
    const styles = require('./index.scss');

    const searchSelectStyle = {
        menu: { width: 400 },
    };

    if (size === 'small') {
        searchSelectStyle.input = { fontSize: 14, lineHeight: 18, height: 25 };
    }

    return (
        <Field
            autoFocus
            component={SearchSelect}
            components={{
                Option: SearchSelectIconOption,
                SingleValue: SearchSelectIconValue,
            }}
            formClassName={size === 'small' ? styles.noMargin : null}
            name={TEMPLATE_QUESTION_ID}
            noOptionsMessage={() => 'No shared questions available'}
            openMenuOnFocus
            options={options}
            overrideFeedback
            placeholder="Add shared question"
            styles={searchSelectStyle}
            useOpenGovStyle={useOpenGovStyle}
            {...(onBlur ? { onBlur } : {})}
        />
    );
};

ConnectedTemplateQuestionAddForm.propTypes = {
    onBlur: PropTypes.func,
    options: PropTypes.array.isRequired,
    size: PropTypes.string,
    useOpenGovStyle: PropTypes.bool,
};

export const TemplateQuestionAddForm = reduxForm(formConfig)(ConnectedTemplateQuestionAddForm);

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Field, FieldArray, reduxForm } from 'redux-form';

import { form, DESCRIPTION, LIST_ITEMS, TITLE } from './constants';
import { validate } from './validate';
import { Button, InputText, RichTextInput } from '../../../../../../../../../components';

const formConfig = {
    form,
    validate,
};

export class ConnectedListImportForm2 extends PureComponent {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        invalid: PropTypes.bool.isRequired,
        onCancel: PropTypes.func.isRequired,
        submitFailed: PropTypes.bool.isRequired,
    };

    renderItems = ({ fields }) => {
        return fields.map((fieldName, index) => {
            return (
                <div key={index}>
                    <Field
                        component={InputText}
                        label="Title"
                        name={`${fieldName}.${TITLE}`}
                        type="text"
                    />
                    <Field
                        component={RichTextInput}
                        label="Description"
                        name={`${fieldName}.${DESCRIPTION}`}
                    />
                </div>
            );
        });
    };

    render() {
        const { handleSubmit, invalid, onCancel, submitFailed } = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <FieldArray component={this.renderItems} name={LIST_ITEMS} />
                <div className="text-right">
                    <Button bsSize="sm" onClick={onCancel}>
                        Cancel
                    </Button>
                    &nbsp;&nbsp;
                    <Button bsStyle="success" type="submit">
                        <i className="fa fa-download" /> Import
                    </Button>
                    {submitFailed && invalid && (
                        <div className="error-block">Please fix errors before importing</div>
                    )}
                </div>
            </form>
        );
    }
}

export const ListImportForm2 = reduxForm(formConfig)(ConnectedListImportForm2);

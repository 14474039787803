import { fromJS } from 'immutable';

import * as confirmationActions from '../actions/confirmation';

const initialState = fromJS({
    show: false,
    showSimple: false,
    updating: false,
});

export default function confirmationReducer(state = initialState, action = {}) {
    switch (action.type) {
        case confirmationActions.SHOW:
            return state.merge(
                fromJS({
                    show: true,
                    data: fromJS(action.data),
                    confirmationType: action.confirmationType,
                })
            );
        case confirmationActions.SHOW_ERROR:
            return state.merge(
                fromJS({
                    updating: false,
                    error: action.error,
                })
            );
        case confirmationActions.UPDATING:
            return state.merge(
                fromJS({
                    updating: true,
                    error: null,
                })
            );
        case confirmationActions.SHOW_SIMPLE:
            return state.merge(
                fromJS({
                    showSimple: true,
                    simpleOnConfirm: action.onConfirm,
                    simpleData: fromJS(action.data),
                })
            );
        case confirmationActions.HIDE:
        case confirmationActions.HIDE_SIMPLE:
            return initialState;
        default:
            return state;
    }
}

export const form = 'templateSectionForm';

export const BUILDER_SECTION = 'builderSection';
export const CRITERIA = 'criteria';
export const EVALUATION_PHASES = 'evaluationPhases';
export const INSTRUCTIONS = 'instructions';
export const MANUAL_NUMBER = 'manualNumber';
export const PROJECT_SECTION = 'projectSection';
export const QUESTIONNAIRES = 'questionnaires';
export const SHORT_NAME = 'shortName';
export const TITLE = 'title';
export const USER_ID = 'user_id';
export const DISABLE_NUMBERING = 'disableNumbering';
export const DISABLE_TITLE = 'disableTitle';

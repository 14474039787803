import PropTypes from 'prop-types';
import React from 'react';

import { StyledDropzone } from '../../../../../components';

const BYTES_PER_MEGABYTE = 1048576;
const MAX_FILE_SIZE = 1 * BYTES_PER_MEGABYTE;
const accept = {
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
};

export const Dropzone = ({ children, labelId, updating, dropHandler }) => {
    const styles = require('../shared.scss');

    // NOTE: application/vnd.openxmlformats-officedocument.wordprocessingml.document does not seem to work on Windows machines
    return (
        <StyledDropzone
            accept={accept}
            defaultRejectMessage="File could not be uploaded. Please be sure you are uploading a Word file in .docx format that is less than 1 MB in size."
            disabled={updating}
            dropzoneClassName={styles.dropzone}
            dropzoneContainerClassName={styles.dropzoneContainer}
            icons={['file-word-o']}
            label=""
            labelId={labelId}
            maxSize={MAX_FILE_SIZE}
            multiple={false}
            onDropAccepted={dropHandler}
            text="Drop a Word document here or click to select file (max file size: 1 MB)"
        >
            {children}
        </StyledDropzone>
    );
};

Dropzone.propTypes = {
    children: PropTypes.node.isRequired,
    dropHandler: PropTypes.func.isRequired,
    labelId: PropTypes.string,
    updating: PropTypes.bool,
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { OrderButton } from './OrderButton';

const OrderButtonDirection = {
    UP: -1,
    DOWN: 1,
};

export class OrderButtons extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
    };

    render() {
        const { className, ...props } = this.props;
        return (
            <div className={className}>
                <OrderButton direction={OrderButtonDirection.UP} {...props} />
                <OrderButton direction={OrderButtonDirection.DOWN} {...props} />
            </div>
        );
    }
}

OrderButtons.OrderButtonDirectionDict = OrderButtonDirection;

const EXCLUDED_FILE_EXTENSIONS = ['.exe', '.zip', '.tar', '.gzip', '.7z'];

export const fileExtensionValidator = (file) => {
    const fileName = file.name;

    // when dragging a file over the input, this is not set
    // only after its dropped
    if (!fileName) {
        return null;
    }

    const lastIndexDot = fileName.lastIndexOf('.');
    const fileExtension = fileName.substring(lastIndexDot).toLowerCase();
    const noFileExtension = lastIndexDot === -1; // No "." was found in the file name, meaning there's no file extension

    if (EXCLUDED_FILE_EXTENSIONS.includes(fileExtension) || noFileExtension) {
        return {
            code: 'file-extension-not-allowed',
        };
    }

    return null;
};

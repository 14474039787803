import { PASSWORD } from './constants';

export const loginValidate = (values) => {
    const errors = {};

    if (!values[PASSWORD]) {
        errors[PASSWORD] = 'Password field is required';
    }

    return errors;
};

import { fromJS } from 'immutable';

import * as calendarActions from '../actions/calendar';

const initialState = fromJS({
    calendar: null,
    loadCalendarError: null,
    loadingCalendar: false,
    publicCalendar: null,
});

export default function calendarReducer(state = initialState, action = {}) {
    switch (action.type) {
        case calendarActions.LOAD: {
            const calendar = state.get('calendar');
            return state.merge(
                fromJS({
                    calendar: calendar ? calendar.set('events', []) : null,
                    loadCalendarError: null,
                    loadingCalendar: true,
                })
            );
        }
        case calendarActions.LOAD_PUBLIC: {
            return state.merge(
                fromJS({
                    loadCalendarError: null,
                    loadingCalendar: true,
                })
            );
        }
        case calendarActions.LOAD_FAIL:
            return state.merge(
                fromJS({
                    loadCalendarError: action.error.message,
                    loadingCalendar: false,
                })
            );
        case calendarActions.LOAD_SUCCESS:
            return state.merge(
                fromJS({
                    calendar: fromJS(action.result),
                    loadingCalendar: false,
                })
            );
        case calendarActions.LOAD_PUBLIC_SUCCESS:
            return state.merge(
                fromJS({
                    publicCalendar: fromJS(action.result),
                    loadingCalendar: false,
                })
            );
        default:
            return state;
    }
}

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Button } from 'react-bootstrap';
import { reduxForm, Field } from 'redux-form';

import { form, fieldNames } from './constants';
import { validate } from './validate';
import { RichTextInput } from '../../..';

const formConfig = {
    form,
    validate,
};

// @reduxForm
class ConnectedSectionDescriptionForm extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        closeForm: PropTypes.func.isRequired,
        deleting: PropTypes.bool,
        handleDelete: PropTypes.func,
        handleSubmit: PropTypes.func.isRequired,
        isEditForm: PropTypes.bool.isRequired,
        pristine: PropTypes.bool.isRequired,
        submitServerError: PropTypes.string,
        submitting: PropTypes.bool,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
    };

    static defaultProps = {
        className: undefined,
        deleting: false,
        handleDelete: undefined,
        submitServerError: undefined,
        submitting: false,
        tagOptions: undefined,
        templateVariableOptions: undefined,
    };

    renderSubmitError() {
        const { submitServerError } = this.props;

        if (submitServerError) {
            const styles = require('./SectionDescriptionForm.scss');
            return <div className={`text-danger ${styles.errorMsg}`}>{submitServerError}</div>;
        }
    }

    renderDeleteButton() {
        const { isEditForm, submitting, deleting, handleDelete } = this.props;
        const styles = require('./SectionDescriptionForm.scss');

        if (!isEditForm) return null;
        return (
            <Button
                className={styles.deleteButton}
                disabled={submitting || deleting}
                onClick={handleDelete}
            >
                <i className="fa fa-lg fa-trash-o" /> Delete
            </Button>
        );
    }

    render() {
        const {
            className,
            closeForm,
            deleting,
            handleSubmit,
            isEditForm,
            pristine,
            submitting,
            tagOptions,
            templateVariableOptions,
        } = this.props;

        const styles = require('./SectionDescriptionForm.scss');
        const buttonLoadingText = isEditForm ? 'Updating' : 'Adding';
        const buttonText = isEditForm ? 'Update' : 'Add';

        return (
            <form className={className} onSubmit={handleSubmit}>
                <div className="form-group">
                    <Field
                        autoFocus
                        component={RichTextInput}
                        disabled={submitting || deleting}
                        minRows={2}
                        name={fieldNames.RAW_DESCRIPTION}
                        placeholder="Enter a description of the section"
                        tagOptions={tagOptions}
                        templateVariableOptions={templateVariableOptions}
                        toolbarPlacement="bottom"
                    />
                </div>
                {this.renderSubmitError()}
                <div className="text-center">
                    <Button
                        bsStyle={isEditForm ? 'primary' : 'success'}
                        className={styles.submitButton}
                        disabled={pristine || submitting || deleting}
                        type="submit"
                    >
                        {submitting ? buttonLoadingText : buttonText}
                    </Button>
                    <Button disabled={submitting || deleting} onClick={closeForm}>
                        Cancel
                    </Button>
                    {this.renderDeleteButton()}
                </div>
            </form>
        );
    }
}

export const SectionDescriptionForm = reduxForm(formConfig)(ConnectedSectionDescriptionForm);

import PropTypes from 'prop-types';
import React from 'react';
import { formValueSelector, Field, reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';

import { fieldNames, form, rejectionNoteOptions, OTHER } from './constants';
import { getRejectionPolicyOptions } from './selectors';
import { validate } from './validate';
import { InputText, SearchSelect } from '../../../../../../components';
import { qaTagPageName } from '../../../constants';

const { REJECTION_NOTE, REJECTION_NOTE_EXTRA, REJECTION_POLICY } = fieldNames;

const formConfig = {
    form,
    validate,
};

// @reduxForm
const ConnectedRejectionNoteForm = ({ disabled, handleSubmit, requireRejectionNote }) => {
    const rejectionNote = useSelector((state) => formValueSelector(form)(state, REJECTION_NOTE));
    const rejectionPolicyOptions = useSelector(getRejectionPolicyOptions);

    return (
        <form onSubmit={handleSubmit}>
            <Field
                component={SearchSelect}
                disabled={disabled}
                help="Specify where to route the request after rejecting"
                label="Rejection Policy*"
                name={REJECTION_POLICY}
                options={rejectionPolicyOptions}
                placeholder="Select rejection policy to use"
                qaTag={`${qaTagPageName}-rejectionPolicy`}
            />
            <Field
                component={SearchSelect}
                disabled={disabled}
                help="Select a pre-defined reason or add a custom note"
                label={`Reason For Rejecting This Request${
                    requireRejectionNote ? '*' : ' (Optional)'
                }`}
                name={REJECTION_NOTE}
                options={rejectionNoteOptions}
                placeholder="Select an option"
                qaTag={`${qaTagPageName}-rejectionNote`}
            />
            {rejectionNote === OTHER && (
                <Field
                    autoFocus
                    characterLimit={1000}
                    component={InputText}
                    disabled={disabled}
                    hasFeedback={false}
                    help="Provide a custom reason for rejecting"
                    label="Other Reason"
                    name={REJECTION_NOTE_EXTRA}
                    placeholder="Enter text"
                    qaTag={`${qaTagPageName}-rejectionNote`}
                    type="textarea"
                />
            )}
        </form>
    );
};

ConnectedRejectionNoteForm.propTypes = {
    disabled: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    requireRejectionNote: PropTypes.bool,
};

export const RejectionNoteForm = reduxForm(formConfig)(ConnectedRejectionNoteForm);

import { get } from 'lodash';
import { createSelector } from 'reselect';

import { userStatusTypes } from '@og-pro/shared-config/users';

import { getUserRolesJS } from '../selectors';
import { getUsersJS } from '../../selectors';

export const getShowDisabledUsers = (state) => state.admin.get('showDisabledUsers');
const getSortUsersDirection = (state) => state.admin.get('sortUsersDirection');
const getUsersListSearch = (state) => state.admin.get('usersListSearch');

export const getFilteredAndSortedUsersJS = createSelector(
    [getUsersJS, getShowDisabledUsers, getSortUsersDirection, getUsersListSearch],
    (users, showDisabledUsers, sortDirection, search) => {
        return users
            .filter((user) => {
                if (user.status === userStatusTypes.DISABLED && !showDisabledUsers) {
                    return false;
                }
                if (!search || !search.trim() || user.uuid) {
                    return true;
                }
                return [get(user, 'email'), get(user, 'fullName')].some(
                    (field) => field && field.toLowerCase().includes(search.toLowerCase())
                );
            })
            .sort((userA, userB) => {
                // Always include the form at the top
                if (!userA.id) {
                    return -1;
                }

                if (!userB.id) {
                    return 1;
                }

                const userAName = userA.lastName && userA.lastName.trim().toLowerCase();
                const userBName = userB.lastName && userB.lastName.trim().toLowerCase();

                // Always include unregistered users on bottom
                if (!userAName) {
                    return 1;
                }

                if (!userBName) {
                    return -1;
                }

                // Do the actual sorting
                if (userAName === userBName) {
                    return 0;
                }

                if (sortDirection === 'ASC') {
                    if (userAName > userBName) {
                        return 1;
                    }

                    return -1;
                }

                if (userAName > userBName) {
                    return -1;
                }

                return 1;
            });
    }
);

export const getUserRolesSelectOptions = createSelector([getUserRolesJS], (userRoles) => {
    return userRoles.map((userRole) => {
        return {
            label: userRole.name,
            value: userRole.id,
        };
    });
});

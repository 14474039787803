import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Button } from '../../../../../components';

export class ProposalBackButton extends PureComponent {
    static propTypes = {
        text: PropTypes.string.isRequired,
        to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const { text, to } = this.props;

        return (
            <div className={`${this.styles.backButton} no-print`}>
                <Button bsSize="sm" exact to={to}>
                    <i className="fa fa-angle-left" /> {text}
                </Button>
            </div>
        );
    }
}

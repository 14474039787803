const { listToDict } = require('../helpers');

exports.addPolicyFormNames = [
    'title',
    'abbreviation',
    'isActive',
    'archiveSchedule',
    'archiveScheduleHasErrors',
    'scheduleOptions',
    'expireAtFiscalYearStart',
];

exports.addPolicyFormNamesDict = listToDict(exports.addPolicyFormNames);

exports.YEARS = 'years';
exports.NEVER = 'never';
exports.IMMEDIATELY = 'immediately';

exports.recordDocumentTypes = ['intakes', 'documents', 'contracts'];

exports.recordDocumentTypesDict = listToDict(exports.recordDocumentTypes);

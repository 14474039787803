import { TabsNavigation } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { NAV_ITEMS } from './constants';
import { qaTagName } from '../../constants';
import { getRequestTypesPath } from '../../../selectors';

export const Nav = ({ requestTypeId }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const requestTypePath = useSelector(getRequestTypesPath);

    const tabs = NAV_ITEMS.map(({ label, path }) => {
        return {
            label,
            qaTag: `${qaTagName}-${label}`,
            path,
            route: `${requestTypePath}/${requestTypeId}${path}`,
        };
    });

    const activeTab = tabs.find(({ path }) => location.pathname.includes(path)) || tabs[0];

    return <TabsNavigation defaultTab={activeTab} redirectFn={navigate} tabs={tabs} />;
};

Nav.propTypes = {
    requestTypeId: PropTypes.number.isRequired,
};

import { createSelector } from 'reselect';
import { get } from 'lodash';

import { getCurrentEvaluation } from '../../../selectors';

const getProposal = (state, props) => props.proposal;

export const getDetailedProposalData = createSelector(
    [getProposal, getCurrentEvaluation],
    (proposal, evaluation) => {
        const { proposalEvaluations } = proposal;
        const scoringCriteria = evaluation.scoringCriteria || [];

        // Map data based on scoring criteria
        return scoringCriteria.map((criteria, idx) => {
            const scores = proposalEvaluations.map(
                (proposalEvaluation) => proposalEvaluation.submittedProposalCriteriaScores[idx]
            );
            return {
                proposalScores: scores,
                scoringCriteria: criteria,
            };
        });
    }
);

export const getEvaluators = createSelector([getProposal], (proposal) => {
    return get(proposal, 'proposalEvaluations', []).map(
        (proposalEvaluation) => proposalEvaluation.user
    );
});

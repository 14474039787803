import classnames from 'classnames';
import React, { useCallback, useState, useEffect } from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { compose } from 'redux';
import { createSearchParams, useSearchParams, useLocation } from 'react-router-dom';

import { approvalWorkflowTypesDict } from '@og-pro/shared-config/approval';

import { ApprovalWorkflowForm } from './ApprovalWorkflowForm';
import { ApprovalWorkflowItem } from './ApprovalWorkflowItem';
import { fieldNames, form } from './constants';
import { getNavItems, getSelectedApprovalWorkflows, getSelectedModuleType } from './selectors';
import connectData from '../../ConnectData';
import { getDepartmentsSelectOptions, getInvitedUsersSelectOptions } from '../../selectors';
import {
    createApprovalWorkflow as createApprovalWorkflowAction,
    deleteApprovalWorkflow as deleteApprovalWorkflowAction,
    loadApprovalWorkflows,
    resetApprovalWorkflow,
    updateApprovalWorkflow as updateApprovalWorkflowAction,
} from '../../../actions/adminGovernment';
import { showConfirmationSimpleModal } from '../../../actions/confirmation';
import {
    Button,
    LinkContainer,
    LoadingError,
    LoadingSpinner,
    Main,
    SectionTitle,
    UnderlineNav,
    Well,
    ZeroState,
} from '../../../components';

const { MODULE_TYPE } = fieldNames;

const { INTAKE } = approvalWorkflowTypesDict;

function fetchData(getState, dispatch, location, params) {
    if (!getState().adminGovernment.get('loadedApprovalWorkflows')) {
        const governmentId = Number.parseInt(params.governmentId, 10);
        return dispatch(loadApprovalWorkflows(governmentId));
    }
}

const ConnectedApprovalsPreSetWorkflow = () => {
    const [showForm, setShowForm] = useState(false);
    const { pathname } = useLocation();
    const [query] = useSearchParams();
    const type = query.get('type');

    const dispatch = useDispatch();
    const selectedModuleType = useSelector((state) => getSelectedModuleType(state, { type }));
    const approvalWorkflows = useSelector((state) => getSelectedApprovalWorkflows(state, { type }));
    const createError = useSelector((state) =>
        state.adminGovernment.get('createApprovalWorkflowsError')
    );
    const creating = useSelector((state) => state.adminGovernment.get('creatingApprovalWorkflows'));
    const departmentsSelectOptions = useSelector(getDepartmentsSelectOptions);
    const loadError = useSelector((state) =>
        state.adminGovernment.get('loadApprovalWorkflowsError')
    );
    const loading = useSelector((state) => state.adminGovernment.get('loadingApprovalWorkflows'));
    const navItems = useSelector(getNavItems);
    const usersSelectOptions = useSelector(getInvitedUsersSelectOptions);
    const multiUserSelect = selectedModuleType !== INTAKE;

    useEffect(() => {
        return () => {
            dispatch(resetApprovalWorkflow());
        };
    }, [dispatch]);

    useEffect(() => {
        if (showForm) {
            setShowForm(false);
        }
    }, [selectedModuleType]);

    const styles = require('./index.scss');

    const hideForm = useCallback(() => setShowForm(false), []);

    const createApprovalWorkflow = useCallback(
        (formData) => {
            dispatch(createApprovalWorkflowAction(formData)).then((workflowOrError) => {
                if (!(workflowOrError instanceof Error)) {
                    hideForm();
                }
            });
        },
        [dispatch, hideForm]
    );

    const deleteApprovalWorkflow = useCallback(
        (approvalWorkflowId) => {
            const confirmationHandler = () =>
                dispatch(deleteApprovalWorkflowAction(approvalWorkflowId));
            dispatch(
                showConfirmationSimpleModal(confirmationHandler, {
                    btnText: 'Delete',
                    text: 'Are you sure you want to delete this approval workflow?',
                })
            );
        },
        [dispatch]
    );
    const updateApprovalWorkflow = useCallback(
        (approvalWorkflowId, formData) => {
            return dispatch(updateApprovalWorkflowAction(approvalWorkflowId, formData));
        },
        [dispatch]
    );

    const renderCreateForm = () => {
        return (
            <Well>
                <ApprovalWorkflowForm
                    departmentsSelectOptions={departmentsSelectOptions}
                    disabled={creating}
                    form={form}
                    hideNewWorkflowForm={hideForm}
                    initialValues={{
                        [MODULE_TYPE]: selectedModuleType,
                    }}
                    isNew
                    multiUserSelect={multiUserSelect}
                    onSubmit={createApprovalWorkflow}
                    selectedModuleType={selectedModuleType}
                    serverError={createError}
                    usersSelectOptions={usersSelectOptions}
                />
            </Well>
        );
    };

    const renderApprovalWorkflowsList = () => {
        return (
            <div>
                {showForm ? (
                    renderCreateForm()
                ) : (
                    <div className={styles.createButton}>
                        <Button
                            aria-label="Add New Workflow Button"
                            bsStyle="success"
                            onClick={() => setShowForm(true)}
                            qaTag="approvalPresetWorkflow-createNewWorkflow"
                        >
                            <i className="fa fa-plus" /> New Workflow
                        </Button>
                    </div>
                )}
                <div className={`hidden-xs row ${styles.listHeaders}`}>
                    <div className="col-sm-4">Departments</div>
                    <div className="col-sm-1" />
                    <div className={`col-sm-7 ${styles.approversColumn}`}>Approvers</div>
                </div>
                <ListGroup>
                    {approvalWorkflows.map((approvalWorkflow) => (
                        <ListGroupItem key={approvalWorkflow.id}>
                            <ApprovalWorkflowItem
                                approvalWorkflow={approvalWorkflow}
                                deleteApprovalWorkflow={deleteApprovalWorkflow}
                                departmentsSelectOptions={departmentsSelectOptions}
                                multiUserSelect={multiUserSelect}
                                selectedModuleType={selectedModuleType}
                                updateApprovalWorkflow={updateApprovalWorkflow}
                                usersSelectOptions={usersSelectOptions}
                            />
                        </ListGroupItem>
                    ))}
                </ListGroup>
            </div>
        );
    };

    const renderZeroState = () => {
        if (showForm) {
            return renderCreateForm();
        }

        return (
            <ZeroState
                buttonClickHandler={() => setShowForm(true)}
                buttonText={
                    <span>
                        <i className="fa fa-plus" /> Create Workflow
                    </span>
                }
                title="No Approval Workflows Have Been Setup Yet"
            />
        );
    };

    if (loading) return <LoadingSpinner />;
    if (loadError) return <LoadingError error={loadError} />;

    return (
        <Main className="row">
            <div className="col-lg-offset-1 col-lg-10">
                <SectionTitle
                    info="List of approvers that will automatically be added to specific projects"
                    title="Approval Pre-Set Workflows"
                />
                {navItems.length === 1 ? null : (
                    <UnderlineNav className={styles.navItems}>
                        {navItems.map((item) => {
                            const { name, type: itemType } = item;
                            return (
                                <LinkContainer
                                    className={classnames({
                                        active: itemType === selectedModuleType,
                                    })}
                                    key={itemType}
                                    to={{
                                        pathname,
                                        search: createSearchParams({ type: itemType }).toString(),
                                    }}
                                >
                                    <UnderlineNav.NavItem>{name}</UnderlineNav.NavItem>
                                </LinkContainer>
                            );
                        })}
                    </UnderlineNav>
                )}
                {approvalWorkflows.length === 0 ? renderZeroState() : renderApprovalWorkflowsList()}
            </div>
        </Main>
    );
};

export const ApprovalsPreSetWorkflow = compose(connectData(fetchData))(
    ConnectedApprovalsPreSetWorkflow
);

import { createSelector } from 'reselect';

const getDocumentVersions = (state) => state.revisions.get('documentVersions');

export const getDocumentVersionsJS = createSelector(
    [getDocumentVersions],
    (rawDocumentVersions) => {
        return rawDocumentVersions.toJS();
    }
);

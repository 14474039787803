import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getShowExpandedSupplierNetworkModal } from '../../../../selectors/govApp';
import { getInviteVendorsSearchParams } from '../../../../actions/govProjects/preInvite';
import { getProjectJS } from '../../selectors';

export const useInitialValues = (shouldFetchLocations = true) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [initialValues, setInitialValues] = useState([]);

    const showModal = useSelector(getShowExpandedSupplierNetworkModal);
    const project = useSelector(getProjectJS);

    /**
     * Gets the initialValues for the locations form.
     * The values come from the stored search params or from the organization location.
     * If the stored search params are not available, we get the organization location and
     * get the address candidate for it via ESRI which is then normalized.
     */
    const getInitialValues = useCallback(async () => {
        try {
            setLoading(true);

            const storedInviteVendorSearchParams = await dispatch(
                getInviteVendorsSearchParams(project.id)
            );

            if (storedInviteVendorSearchParams?.length) {
                return setInitialValues(storedInviteVendorSearchParams);
            }

            setInitialValues([]);
        } catch (e) {
            setInitialValues([]);
        } finally {
            setLoading(false);
            setLoaded(true);
        }
    }, [project]);

    useEffect(() => {
        if (showModal && project && shouldFetchLocations) {
            getInitialValues();
        }

        return () => {
            setInitialValues([]);
        };
    }, [project, showModal, getInitialValues]);

    return { initialValues, loaded, loading };
};

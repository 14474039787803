import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';

import { form, NOTE } from './constants';
import { validate } from './validate';
import { InputText } from '../../..';

const formConfig = {
    form,
    validate,
};

// @reduxForm
const ConnectedQuestionnaireReviewNoteForm = ({ disabled, showValidation }) => {
    const styles = require('./index.scss');

    return (
        <div className={styles.container}>
            <Field
                component={InputText}
                disabled={disabled}
                hasFeedback={false}
                name={NOTE}
                placeholder="Leave an optional note"
                qaTag="checklistSwitchActionModal-note"
                showValidation={showValidation}
                type="textarea"
            />
        </div>
    );
};

ConnectedQuestionnaireReviewNoteForm.propTypes = {
    disabled: PropTypes.bool,
    showValidation: PropTypes.bool,
};

export const QuestionnaireReviewNoteForm = reduxForm(formConfig)(
    ConnectedQuestionnaireReviewNoteForm
);

import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ChecklistProgressBar, DisplaySection } from '../..';

export const ContractChecklists = ({
    contract: {
        checklists,
        contractParty: {
            vendor: { id: vendorId },
        },
    },
}) => {
    const navigate = useNavigate();

    const items = (checklists || []).map((checklist) => ({
        icon: checklist.template.icon,
        label: checklist.template.title,
        value: (
            <span
                className="pseudoLink pull-left"
                id="contractDetails-pseudoLink-checklistProgressBar"
                onClick={() => navigate(`/vendors/${vendorId}/checklists/${checklist.id}`)}
            >
                <ChecklistProgressBar questionnaires={checklist.questionnaires} textOnly />
            </span>
        ),
    }));

    return <DisplaySection header="CHECKLISTS" items={items} zeroState="No Checklists Added" />;
};

ContractChecklists.propTypes = {
    contract: PropTypes.shape({
        checklists: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                questionnaires: PropTypes.array,
                template: PropTypes.shape({
                    icon: PropTypes.string,
                    title: PropTypes.string,
                }),
            })
        ),
        contractParty: PropTypes.shape({
            vendor: PropTypes.shape({
                id: PropTypes.number.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
};

import { isEmpty } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';

import {
    getAwardedContracts,
    getAwardedContractsError,
    getLoadingAwardedContracts,
} from './selectors';
import { LoadingError, LoadingSpinner } from '../../../components';
import { TableView } from '../../../components/ContractList/TableView';

export const AwardedContractsBody = () => {
    const awardedContracts = useSelector(getAwardedContracts);
    const awardedContractsError = useSelector(getAwardedContractsError);
    const loadingAwardedContracts = useSelector(getLoadingAwardedContracts);

    if (loadingAwardedContracts) {
        return <LoadingSpinner />;
    }

    if (awardedContractsError) {
        return <LoadingError error={awardedContractsError} />;
    }

    if (isEmpty(awardedContracts)) {
        return (
            <p className="text-muted text-center">
                Your organization has not been awarded any public contracts yet.
            </p>
        );
    }

    return <TableView contracts={awardedContracts} isVendorView />;
};

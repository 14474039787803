import PropTypes from 'prop-types';
import React from 'react';

export const RequisitionGroupInfo = ({ requisitionGroup: { abbreviation, icon, name } }) => {
    if (!name) {
        return 'No group name set';
    }

    return (
        <span>
            {icon && (
                <>
                    <i className={`fa fa-fw fa-${icon}`} />
                    &nbsp;
                </>
            )}
            {name}
            {abbreviation && ` (${abbreviation})`}
        </span>
    );
};

RequisitionGroupInfo.propTypes = {
    requisitionGroup: PropTypes.shape({
        abbreviation: PropTypes.string,
        icon: PropTypes.string,
        name: PropTypes.string,
    }),
};

import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { ListGroup, ListGroupItem } from 'react-bootstrap';

import { getFileExtensionIcon } from '../../helpers';
import { ZeroState } from '../ZeroState';

const BaseContractPublicAuditLogTable = ({ publicAuditLogs }) => {
    const styles = require('./index.scss');

    const renderAuditEntry = (audit) => {
        const {
            action,
            attachment,
            comment,
            created_at: createdAt,
            user: { email, fullName },
        } = audit;

        const fileIcon = attachment
            ? getFileExtensionIcon(attachment.fileExtension)
            : 'file-text-o';

        return (
            <ListGroupItem key={audit.id}>
                <div className={`row ${styles.body}`}>
                    <div className="col-sm-2">
                        <div>{moment(createdAt).format('ll')}</div>
                        <div>{moment(createdAt).format('h:mm a')}</div>
                    </div>
                    <div className="col-sm-2">
                        <div className={styles.fullName}>{fullName}</div>
                        <div className={styles.email}>{email}</div>
                    </div>
                    <div className="col-sm-4">
                        <div className={styles.option}>
                            <i className={`fa fa-${fileIcon} ${styles.fileIcon}`} />
                            {action}
                        </div>
                    </div>
                    <div className="col-sm-4">{comment}</div>
                </div>
            </ListGroupItem>
        );
    };

    if (publicAuditLogs.length === 0) {
        return <ZeroState title="No disabled contract or document activity recorded yet" />;
    }

    return (
        <>
            <div className={`row hidden-xs hidden-sm ${styles.header}`}>
                <div className="col-xs-2">Timestamp</div>
                <div className="col-xs-2">User</div>
                <div className="col-xs-4">Action</div>
                <div className="col-xs-4">Reason</div>
            </div>
            <ListGroup>{publicAuditLogs.map(renderAuditEntry)}</ListGroup>
        </>
    );
};

BaseContractPublicAuditLogTable.propTypes = {
    publicAuditLogs: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export const ContractPublicAuditLogTable = React.memo(BaseContractPublicAuditLogTable);

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { getEmergencyContractsJS } from './selectors';
import connectData from '../../../ConnectData';
import {
    loadEmergencyContracts,
    shouldLoadContractsList,
} from '../../../../actions/emergencyPortal';
import { LoadingError, LoadingSpinner, ZeroState } from '../../../../components';
import { TableView } from '../../../../components/ContractList/TableView';

function fetchData(getState, dispatch) {
    if (shouldLoadContractsList(getState())) {
        return dispatch(loadEmergencyContracts());
    }
}

const mapStateToProps = (state) => {
    return {
        contracts: getEmergencyContractsJS(state),
        loadError: state.emergencyPortal.get('loadContractsError'),
        loading: state.emergencyPortal.get('loadingContracts'),
    };
};

// @connectData
// @connect
class ConnectedEmergencyContractsList extends Component {
    static propTypes = {
        contracts: PropTypes.array.isRequired,
        loadError: PropTypes.string,
        loading: PropTypes.bool,
    };

    render() {
        const { loading, loadError, contracts } = this.props;

        if (loading) {
            return <LoadingSpinner />;
        }

        if (loadError) {
            return <LoadingError error={loadError} />;
        }

        if (contracts.length === 0) {
            return <ZeroState title="No contracts found" />;
        }

        return <TableView contracts={contracts} isPublicView showOrganization />;
    }
}

export const EmergencyContractsList = compose(
    connectData(fetchData),
    connect(mapStateToProps)
)(ConnectedEmergencyContractsList);

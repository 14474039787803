import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { reduxForm, Field } from 'redux-form';

import { InputText } from '../../../components';
import { validate } from './validate';
import { form, fieldNames } from './constants';

const formConfig = {
    form,
    validate,
};

// @reduxForm
class ConnectedGovtDomain extends Component {
    static propTypes = {
        handleSubmit: PropTypes.func,
        invalid: PropTypes.bool.isRequired,
        saving: PropTypes.bool,
        isMatch: PropTypes.bool,
        loading: PropTypes.bool,
        matchSubmitted: PropTypes.bool,
    };

    render() {
        const styles = require('./index.scss');

        const { saving, invalid, isMatch, handleSubmit, loading, matchSubmitted } = this.props;

        const buttonClass = loading
            ? `${styles.icon} fa fa-spinner
            fa-spin fa-fw`
            : `${styles.icon} fa fa-envelope fa-fw`;

        let noMatch;
        if (matchSubmitted && !isMatch && !loading) {
            noMatch = (
                <div className="text-danger">
                    We could not find a government registered on OpenGov Procurement that matches
                    your email domain. Please contact your OpenGov Procurement administrator or
                    reach out to us at procurement-support@opengov.com.
                </div>
            );
        }

        return (
            <form onSubmit={handleSubmit}>
                <h3 className={styles.topHeader}>Government Account Sign Up</h3>
                <div className={styles.activate}>
                    <h4 className={styles.subHeader}>
                        Please enter your work email, and we&lsquo;ll match you up with the
                        government account you work for.
                    </h4>
                    <p className={styles.noSubscription}>
                        <em>
                            If your organization does not have an OpenGov Procurement subscription
                            yet, please contact us at&nbsp;
                            <a href="mailto:sales@opengov.com">sales@opengov.com</a> to set one up.
                        </em>
                    </p>
                    <div className={styles.emailInput}>
                        <div className="row">
                            <div className="col-xs-8">
                                <Field
                                    autoFocus
                                    component={InputText}
                                    disabled={saving}
                                    label="Email"
                                    name={fieldNames.EMAIL}
                                    type="email"
                                />
                            </div>
                            <div className={`col-xs-4 ${styles.submit}`}>
                                <Button
                                    bsStyle="primary"
                                    disabled={invalid || loading}
                                    type="submit"
                                >
                                    <i className={buttonClass} />
                                    Find Government
                                </Button>
                            </div>
                        </div>
                        {noMatch}
                    </div>
                </div>
            </form>
        );
    }
}

export const GovtDomain = reduxForm(formConfig)(ConnectedGovtDomain);

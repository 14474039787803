import { createSelector } from 'reselect';

import { navItems } from './constants';
import { getStandardDocumentsJS } from '../../GovApp/selectors';

export const getSelectedDocumentNavItem = createSelector(
    [(state, props) => props.location],
    (location) => {
        const {
            query: { type },
        } = location;

        return navItems.find((item) => item.type === type) || navItems[0];
    }
);

export const getSelectedStandardDocuments = createSelector(
    [getSelectedDocumentNavItem, getStandardDocumentsJS],
    (selectedDocumentNavItem, standardDocuments) => {
        return standardDocuments.filter((doc) => doc.type === selectedDocumentNavItem.type);
    }
);

import React from 'react';
import PropTypes from 'prop-types';

import { DashboardControlNav, ProjectListFilters } from '../../../components';

const ControlNav = ({
    children,
    filterId,
    logo,
    initialFilterFormValues,
    isDocBuilder,
    isIntake,
    onSearch,
    onStoreLastFilter,
}) => (
    <DashboardControlNav logoUrl={logo}>
        <ProjectListFilters
            filterId={filterId}
            filterSubmitHandler={onSearch}
            initialFilterFormValues={initialFilterFormValues}
            isDocBuilder={isDocBuilder}
            isIntake={isIntake}
            storeLastFilter={onStoreLastFilter}
        />
        {children}
    </DashboardControlNav>
);

ControlNav.propTypes = {
    children: PropTypes.node,
    filterId: PropTypes.number,
    logo: PropTypes.string,
    initialFilterFormValues: PropTypes.object,
    isDocBuilder: PropTypes.bool,
    isIntake: PropTypes.bool,
    onSearch: PropTypes.func.isRequired,
    onStoreLastFilter: PropTypes.func.isRequired,
};

export default ControlNav;

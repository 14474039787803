import { find } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

import { projectStatusesDict } from '@og-pro/shared-config/projects';

import { Button, DepartmentModal, SearchSelect } from '../../../../../../../../components';
import { fieldNames } from '../constants';
import { getDepartmentsJS, getDepartmentsSelectOptions } from '../../../../../../../selectors';
import {
    changeDepartmentForm,
    getApprovalWorkflowsFromProjectCreate,
    hideDepartmentModal,
    showDepartmentModal,
} from '../../../../../../../../actions/project/create/projectCreate';

const { DRAFT } = projectStatusesDict;
const { DEPARTMENT_ID } = fieldNames;

export const Department = ({ disabled, form, project, showValidation }) => {
    const dispatch = useDispatch();
    const dispatchHideModal = () => dispatch(hideDepartmentModal());
    const dispatchShowModal = () => {
        if (!disabled) {
            dispatch(showDepartmentModal());
        }
    };

    const departments = useSelector(getDepartmentsJS);
    const departmentsSelectOptions = useSelector(getDepartmentsSelectOptions);
    const showModal = useSelector((state) => state.projectCreate.get('showDepartmentModal'));

    const updateDepartmentInfo = (departmentId) => {
        const selectedDepartmentValue = find(
            departments,
            (departmentEntry) => departmentEntry.id === departmentId
        );

        if (project.status === DRAFT) {
            dispatch(
                getApprovalWorkflowsFromProjectCreate(
                    project.id,
                    departmentId,
                    selectedDepartmentValue,
                    project.departmentName
                )
            );
        }

        if (selectedDepartmentValue) {
            dispatch(changeDepartmentForm(selectedDepartmentValue, form));
        }
    };

    return (
        <>
            <DepartmentModal
                disabled={disabled}
                hideModal={dispatchHideModal}
                showModal={showModal}
                showValidation={showValidation}
            />
            <Field
                backspaceRemovesValue={false}
                component={SearchSelect}
                disabled={disabled}
                inlineButton={
                    <Button
                        bsStyle="link"
                        disabled={disabled}
                        onClick={dispatchShowModal}
                        qaTag="projectInformationForm-editDepartmentDisplayInformation"
                        zeroPadding
                    >
                        <i className="fa fa-pencil-square-o" />
                        &nbsp;Edit Display Information
                    </Button>
                }
                label="Department"
                name={DEPARTMENT_ID}
                onChange={updateDepartmentInfo}
                options={departmentsSelectOptions}
                placeholder="Select Department..."
                showValidation={showValidation}
                useOpenGovStyle
            />
        </>
    );
};

Department.propTypes = {
    disabled: PropTypes.bool.isRequired,
    form: PropTypes.string.isRequired,
    showValidation: PropTypes.bool,
    project: PropTypes.object.isRequired,
};

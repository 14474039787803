import { isDate, mapValues, pick, reduce } from 'lodash';
import moment from 'moment';

import {
    checklistSocketActionType,
    projectSocketActionType,
    requisitionSocketActionType,
    reverseAuctionSocketActionType,
    vendorProposalSocketActionType,
} from './constants';
import { convertToDateString } from '../utils';
import request from '../request';

/*
 * Serializer to cleanse empty string values and replace with `null` values.
 * Ensures server validation is not effected by empty string values.
 */
export function emptyStringSerializer(data, keys = []) {
    const dataToReplace = pick(data, keys);
    const cleanData = reduce(
        dataToReplace,
        (cleanObject, value, key) => {
            if (value !== '') return cleanObject;

            // Only build object with keys that have replaced empty string values
            return {
                ...cleanObject,
                [key]: null,
            };
        },
        {}
    );

    return {
        ...data,
        ...cleanData,
    };
}

// Convert project dates and custom timelines to UTC strings
export function projectDateSerializer(data, timezone) {
    const projectWithAdjustedDates = mapValues(data, (value) => {
        if (isDate(value)) return convertToDateString(value, timezone);
        return value;
    });

    const timelines = (data.timelines || []).map((timeline) => ({
        ...timeline,
        date: isDate(timeline.date) ? convertToDateString(timeline.date, timezone) : timeline.date,
    }));

    return {
        ...projectWithAdjustedDates,
        timelines,
    };
}

export function emitProjectSocket(
    projectId,
    actionData,
    broadcastUpdateMessage = 'New Update Received'
) {
    return {
        projectId,
        emit: true,
        emitType: projectSocketActionType,
        broadcastUpdateMessage,
        ...actionData,
    };
}

export function emitChecklistSocket(
    checklistId,
    actionData,
    broadcastUpdateMessage = 'New Update Received'
) {
    return {
        checklistId,
        emit: true,
        emitType: checklistSocketActionType,
        broadcastUpdateMessage,
        ...actionData,
    };
}

export function emitReverseAuctionSocket(
    projectId,
    actionData,
    broadcastUpdateMessage = 'New Update Received'
) {
    return {
        projectId,
        emit: true,
        emitType: reverseAuctionSocketActionType,
        broadcastUpdateMessage,
        ...actionData,
    };
}

export function emitRequisitionSocket(requisitionId, actionData, broadcastUpdateMessage) {
    return {
        requisitionId,
        emit: true,
        emitType: requisitionSocketActionType,
        broadcastUpdateMessage,
        ...actionData,
    };
}

export function emitVendorProposalSocket(
    proposalId,
    actionData,
    formAction,
    broadcastUpdateMessage = 'New Update Received'
) {
    return {
        proposalId,
        emit: true,
        emitType: vendorProposalSocketActionType,
        broadcastUpdateMessage,
        formAction,
        ...actionData,
    };
}

/*
 * This function is used to upload an attachment to S3.
 * @param {Object} context - The context object
 * @param {Object} result - The response returned from an S3 signed URL
 * @returns {Promise} - The promise from the request
 */
export const putToS3 = ({ context, result }) => {
    const { file, onProgress, title } = context;
    const { bucket, filename, key, signedPutUrl } = result;

    onProgress(3);
    const postData = {
        data: file,
        headers: { 'Content-Type': file.type },
        onProgress: (e) => onProgress(e.percent),
    };

    return request.put(signedPutUrl, postData).then(() => {
        // Set progress to 100% on the upload
        onProgress(100);

        return {
            bucket,
            filename,
            path: key,
            title,
        };
    });
};

// Throttles resource reloads to prevent excessive requests. Requests will be throttled when the
// `RELOAD_LIMIT` is reached within the `RELOAD_TIME_INTERVAL_IN_MINUTES` time interval.
export const reloadThrottler = () => {
    let reloads = 0;
    let throttleStartDate = new Date();
    let lastResourceId;

    const RELOAD_LIMIT = 10;
    const RELOAD_TIME_INTERVAL_IN_MINUTES = 5;

    return (currentResourceId) => {
        // Reset reload count and start date if resource changes.
        if (currentResourceId !== lastResourceId) {
            lastResourceId = currentResourceId;
            throttleStartDate = new Date();
            reloads = 0;
        }

        // Reset reload count and start date if time interval has passed.
        if (moment().diff(throttleStartDate, 'minutes') > RELOAD_TIME_INTERVAL_IN_MINUTES) {
            throttleStartDate = new Date();
            reloads = 0;
        }

        // If reload count exceeds limit, throttle reload.
        if (reloads >= RELOAD_LIMIT) {
            return true;
        }

        // Reload is allowed. Increment reload count.
        reloads++;
        return false;
    };
};

export const downloadFile = (url, filename) => {
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    document.body.appendChild(link); // Required for FF

    link.click();
    link.remove();
};

import { get } from 'lodash';

import { phone, REQUIRED_TEXT } from '../../../Forms/validation';

export const validate = (values) => {
    const errors = {
        organization: {
            ...phone(get(values, 'organization') || {}),
        },
    };

    if (!values.firstName) {
        errors.firstName = REQUIRED_TEXT;
    } else if (values.firstName.length > 32) {
        errors.firstName = 'Name is too long';
    }

    if (!values.lastName) {
        errors.lastName = REQUIRED_TEXT;
    } else if (values.lastName.length > 32) {
        errors.lastName = 'Name is too long';
    }

    if (!values.organization || !values.organization.name) {
        errors.organization.name = REQUIRED_TEXT;
    }

    return errors;
};

import PropTypes from 'prop-types';
import React from 'react';
import { Panel } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { LoadingError, LoadingSpinner } from '../../../../components';

const mapStateToProps = (state) => {
    return {
        loadError:
            state.checklistsAdmin.get('loadListError') || state.checklistsAdmin.get('loadError'),
        loading: state.checklistsAdmin.get('loadingList') || state.checklistsAdmin.get('loading'),
    };
};

// @connect
const ConnectedChecklistsAdminNav = ({ loadError, loading }) => {
    if (loading) {
        return <LoadingSpinner />;
    }

    if (loadError) {
        return <LoadingError error={loadError} />;
    }

    return (
        <Panel>
            <Panel.Body>
                <Outlet />
            </Panel.Body>
        </Panel>
    );
};

ConnectedChecklistsAdminNav.propTypes = {
    loading: PropTypes.bool.isRequired,
    loadError: PropTypes.string,
};

export const ChecklistsAdminNav = connect(mapStateToProps)(ConnectedChecklistsAdminNav);

import { OTHER } from '../constants';

export const CLOSE_OUT_REASON = 'closeOutReason';

export const getCloseOutOptions = (project) => {
    return [
        {
            label: 'Project canceled',
            value: 'Project canceled',
        },
        {
            label: 'No bids',
            value: 'No bids',
        },
        {
            label: 'No vendor selected',
            value: 'No vendor selected',
        },
        project.wasPosted
            ? null
            : {
                  label: 'Remainder of project handled externally of OpenGov Procurement',
                  value: 'Remainder of project handled externally of OpenGov Procurement',
                  excludeWhenPosted: true,
              },
        {
            label: 'Other',
            value: OTHER,
        },
    ].filter((option) => !!option);
};

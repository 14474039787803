import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';

import { comingSoonStatuses, intakeProjectStatuses } from '@og-pro/shared-config/projects';

import { fieldNames, form } from './constants';
import { getDeserializedPreInviteEmails } from './selectors';
import { validate } from './validate';
import { getProjectJS } from '../../selectors';
import { getPreInviteLoadingState, getShowPreInviteModal } from '../../../../selectors/govApp';
import { Button, InputText, LoadingButton, LoadingSpinner } from '../../../../components';
import {
    createOrUpdatePreInviteList,
    hidePreInviteModal,
} from '../../../../actions/govProjects/preInvite';

const { EMAILS } = fieldNames;

const mapStateToProps = (state) => {
    return {
        initialValues: { [EMAILS]: getDeserializedPreInviteEmails(state) },
        loading: getPreInviteLoadingState(state),
        project: getProjectJS(state),
        showPreInviteModal: !!getShowPreInviteModal(state),
    };
};

const mapDispatchToProps = {
    createOrUpdatePreInviteList,
    hidePreInviteModal,
};

const formConfig = {
    enableReinitialize: true,
    form,
    validate,
};

// @connect
// @reduxForm
class ConnectedPreInviteModal extends Component {
    static propTypes = {
        dirty: PropTypes.bool.isRequired,
        createOrUpdatePreInviteList: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        hidePreInviteModal: PropTypes.func.isRequired,
        invalid: PropTypes.bool.isRequired,
        loading: PropTypes.bool,
        project: PropTypes.shape({
            id: PropTypes.number,
            status: PropTypes.string,
        }),
        showPreInviteModal: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            saving: false,
            saveError: null,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    hideModal = (opts = {}) => {
        const { dirty } = this.props;

        if (
            opts.force ||
            !dirty ||
            window.confirm('You have unsaved edits, are you sure you want to exit?') // eslint-disable-line no-alert
        ) {
            this.props.hidePreInviteModal();
        }
    };

    savePreInviteList = (rawData) => {
        const { project } = this.props;

        const data = {
            [EMAILS]: rawData ? rawData[EMAILS].split(',').map((email) => email.trim()) : [],
        };

        this.setState({ saving: true, saveError: null });
        this.props
            .createOrUpdatePreInviteList(project.id, data)
            .then(() => {
                this.setState({ saving: false });
                this.hideModal({ force: true });
            })
            .catch((error) => {
                this.setState({ saving: false, saveError: error.message });
            });
    };

    renderBody() {
        const {
            handleSubmit,
            invalid,
            loading,
            project: { status },
        } = this.props;

        const { saveError, saving } = this.state;

        const editableStatuses = intakeProjectStatuses.concat(comingSoonStatuses);
        const editable = editableStatuses.includes(status);

        if (loading) {
            return <LoadingSpinner />;
        }

        return (
            <form onSubmit={handleSubmit(this.savePreInviteList)}>
                <Field
                    component={InputText}
                    disabled={!editable || saving}
                    hasFeedback={false}
                    help={
                        editable
                            ? 'Use commas to separate multiple emails'
                            : 'List is no longer editable'
                    }
                    label={editable ? 'Enter email addresses' : 'Email addresses'}
                    name={EMAILS}
                    placeholder={
                        editable
                            ? 'sales@globalcorp.com, info@abcsolutions.com'
                            : 'No email addresses entered'
                    }
                    type="textarea"
                />
                <div className="text-right">
                    <Button
                        bsSize="sm"
                        disabled={saving}
                        onClick={this.hideModal}
                        qaTag="connectedPreInviteModal-cancel"
                    >
                        Cancel
                    </Button>
                    &nbsp;&nbsp;
                    {editable && (
                        <LoadingButton
                            bsStyle="success"
                            disabled={invalid || saving}
                            icon="fa-save"
                            loading={saving}
                            text="Save & Close"
                            type="submit"
                        />
                    )}
                </div>
                {saveError && <div className="text-center error-block">{saveError}</div>}
                {editable && (
                    <div className={`text-muted ${this.styles.info}`}>
                        Email notifications will be sent to the email addresses in this list when
                        the project is released.
                    </div>
                )}
            </form>
        );
    }

    render() {
        return (
            <Modal onHide={this.hideModal} show={this.props.showPreInviteModal}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">Vendor Pre-Invite List</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.renderBody()}</Modal.Body>
            </Modal>
        );
    }
}

export const PreInviteModal = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedPreInviteModal);

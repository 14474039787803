import { useEffect } from 'react';
import qs from 'qs';
import { useNavigate, useSearchParams, createSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { activatePendingUser } from '../../actions/auth';

export const RoutePendingUser = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [query] = useSearchParams();
    const { id, token } = Object.fromEntries(query);

    const activateUser = async () => {
        dispatch(activatePendingUser(id, token))
            .then((result) => {
                if (result.foundMatch) {
                    return navigate(
                        {
                            pathname: '/signup',
                            search: createSearchParams({
                                email: result.email,
                            }).toString(),
                        },
                        { replace: true }
                    );
                }

                navigate(
                    {
                        pathname: '/activate-vendor',
                        search: createSearchParams({
                            id: result.id,
                            token: result.token,
                        }).toString(),
                    },
                    { replace: true }
                );
            })
            .catch((error) => {
                navigate(`/not-found?${qs.stringify({ message: error.message })}`, {
                    replace: true,
                });
            });
    };

    useEffect(() => {
        if (!id || !token) {
            return navigate('/not-found', { replace: true });
        }

        activateUser();
    }, []);

    return null;
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { CommentIcon as CommentIconContainer } from '../../containers/GovApp';

export class CommentIcon extends PureComponent {
    static propTypes = {
        projectSection: PropTypes.object.isRequired,
        subsectionType: PropTypes.string,
        criterionId: PropTypes.number,
        commentType: PropTypes.string,
        hideClassName: PropTypes.string,
    };

    render() {
        const { projectSection, subsectionType, criterionId, commentType, hideClassName } =
            this.props;

        const styles = require('./CommentIcon.scss');
        return (
            <CommentIconContainer
                className={styles.commentIcon}
                commentType={commentType}
                criterionId={criterionId}
                hideClassName={hideClassName}
                iconLeft
                projectSection={projectSection}
                show
                subsectionType={subsectionType}
            />
        );
    }
}

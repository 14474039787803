import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { proposalStatusesDict } from '@og-pro/shared-config/proposals';

import { AllProposalsSelectOptions } from './AllProposalsSelectOptions';
import { ProposalSelectOptions } from './ProposalSelectOptions';
import { EvaluationSelectOptions } from './EvaluationSelectOptions';
import { ProjectSummarySelectOptions } from './ProjectSummarySelectOptions';
import { ProposalDocumentOptions } from './ProposalDocumentOptions';
import { reportTypes } from '../constants';
import { backgroundReloadAggregateProposalEvaluations } from '../../../../../actions/proposalEvaluations';
import {
    loadAllProposalsReportOptions,
    loadAwardOptions,
    loadInterviewOptions,
    loadNonAwardOptions,
} from '../../../../../actions/reports';
import { LoadingError, LoadingSpinner } from '../../../../../components';

const {
    ALL_PROPOSALS_REPORT,
    AWARD_REPORT,
    EVALUATION_TABULATION_REPORT,
    INTERVIEW_INVITATION_REPORT,
    NON_AWARD_REPORT,
    PROJECT_SUMMARY_REPORT,
    PROPOSAL_DOCUMENT_REPORT,
} = reportTypes;

const mapDispatchToProps = {
    backgroundReloadAggregateProposalEvaluations,
    loadAllProposalsReportOptions,
    loadAwardOptions,
    loadInterviewOptions,
    loadNonAwardOptions,
};

class ConnectedReportOptionsSelect extends Component {
    static propTypes = {
        loadAllProposalsReportOptions: PropTypes.func.isRequired,
        backgroundReloadAggregateProposalEvaluations: PropTypes.func.isRequired,
        loadAwardOptions: PropTypes.func.isRequired,
        loadInterviewOptions: PropTypes.func.isRequired,
        loadNonAwardOptions: PropTypes.func.isRequired,
        onClick: PropTypes.func.isRequired,
        project: PropTypes.object.isRequired,
        reportType: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            loadingData: true,
        };
    }

    componentDidMount() {
        this.loadOptions();
    }

    loadOptions = () => {
        const { project } = this.props;

        const { getDataProps, loadData } = this.reportOptions;

        this.setState({ loadingData: true });
        return loadData()
            .then((results) => {
                const props = {
                    ...getDataProps(results),
                    project,
                };

                this.setState({
                    dataProps: props,
                    loadingData: false,
                });
            })
            .catch((error) => {
                this.setState({
                    loadError: error.message,
                    loadingData: false,
                });
            });
    };

    get reportOptions() {
        const { project, reportType } = this.props;

        switch (reportType) {
            case AWARD_REPORT:
                return {
                    OptionsComponent: ProposalSelectOptions,
                    loadData: () => this.props.loadAwardOptions(project.id),
                    getDataProps: (result) => {
                        return {
                            proposals: result,
                            reportName: 'Award Notice',
                        };
                    },
                };
            case EVALUATION_TABULATION_REPORT:
                return {
                    OptionsComponent: EvaluationSelectOptions,
                    loadData: () =>
                        this.props.backgroundReloadAggregateProposalEvaluations(project.id),
                    getDataProps: () => {
                        return {
                            evaluation: project.evaluation,
                            reportName: 'Evaluation Tabulation',
                        };
                    },
                };
            case INTERVIEW_INVITATION_REPORT:
                return {
                    OptionsComponent: ProposalSelectOptions,
                    loadData: () => this.props.loadInterviewOptions(project.id),
                    getDataProps: (result) => {
                        return {
                            proposals: result,
                            reportName: 'Interview Invitation',
                        };
                    },
                };
            case NON_AWARD_REPORT:
                return {
                    OptionsComponent: ProposalSelectOptions,
                    loadData: () => this.props.loadNonAwardOptions(project.id),
                    getDataProps: (result) => {
                        return {
                            proposals: result,
                            reportName: 'Non-Award Letter',
                        };
                    },
                };
            case PROJECT_SUMMARY_REPORT:
                return {
                    OptionsComponent: ProjectSummarySelectOptions,
                    loadData: () => Promise.resolve(),
                    getDataProps: () => {
                        return {
                            evaluation: project.evaluation,
                        };
                    },
                };
            case ALL_PROPOSALS_REPORT:
                return {
                    OptionsComponent: AllProposalsSelectOptions,
                    loadData: () => this.props.loadAllProposalsReportOptions(project.id),
                    getDataProps: (result) => {
                        return {
                            proposals: result.filter(
                                ({ status }) => status === proposalStatusesDict.PUBLISHED
                            ),
                        };
                    },
                };
            case PROPOSAL_DOCUMENT_REPORT:
                return {
                    OptionsComponent: ProposalDocumentOptions,
                    loadData: () => Promise.resolve(),
                    getDataProps: () => {},
                };
            default:
                return null;
        }
    }

    render() {
        const { onClick, project } = this.props;

        const { dataProps, loadError, loadingData } = this.state;

        if (loadingData) {
            return <LoadingSpinner text="Loading report options..." />;
        }

        if (loadError) {
            return <LoadingError error={loadError} />;
        }

        const { OptionsComponent } = this.reportOptions;

        return <OptionsComponent onClick={onClick} project={project} {...dataProps} />;
    }
}

export const ReportOptionsSelect = connect(null, mapDispatchToProps)(ConnectedReportOptionsSelect);

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { subsectionTypeNames } from '@og-pro/shared-config/subsections';

import { IntroductionTitle } from './IntroductionTitle';
import { Timeline } from './Timeline';
import { SectionIntro } from '../SectionIntro';

const { TIMELINE } = subsectionTypeNames;

export class TimelineSection extends PureComponent {
    static propTypes = {
        description: PropTypes.object.isRequired,
        government: PropTypes.object.isRequired,
        isDocx: PropTypes.bool,
        isEditable: PropTypes.bool,
        itemNumber: PropTypes.string.isRequired,
        project: PropTypes.object.isRequired,
        projectSection: PropTypes.object.isRequired,
        projectSubsectionsMap: PropTypes.object.isRequired,
        sectionDescActions: PropTypes.object,
        showComments: PropTypes.bool,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
    };

    static defaultProps = {
        isDocx: false,
        isEditable: false,
        sectionDescActions: undefined,
        showComments: false,
        tagOptions: undefined,
        templateVariableOptions: undefined,
    };

    render() {
        const {
            description,
            government: { timezone },
            isDocx,
            isEditable,
            itemNumber,
            project,
            projectSection,
            projectSubsectionsMap,
            sectionDescActions,
            showComments,
            tagOptions,
            templateVariableOptions,
        } = this.props;

        const projectSubsection = projectSubsectionsMap[TIMELINE];

        return (
            <div
                className="introduction-subsection"
                id={`project-field-project_subsection_id.${projectSubsection.id}`}
            >
                <div className="row">
                    <div className="col-xs-12">
                        <IntroductionTitle
                            isDocx={isDocx}
                            itemNumber={itemNumber}
                            projectSection={projectSection}
                            showComments={showComments}
                            subsectionType={TIMELINE}
                            title={projectSubsection.title}
                        />
                    </div>
                </div>
                <SectionIntro
                    {...sectionDescActions}
                    isDocx={isDocx}
                    isEditable={isEditable}
                    isSubsection
                    projectSectionId={projectSection.id}
                    projectSubsectionId={projectSubsection.id}
                    sectionDescription={description[projectSubsection.id]}
                    tagOptions={tagOptions}
                    templateVariableOptions={templateVariableOptions}
                />
                <Timeline isDocx={isDocx} project={project} timezone={timezone} />
            </div>
        );
    }
}

import React from 'react';
import { Box } from '@og-pro/ui';

import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { Content } from './Content';
import { MobileLayout } from './MobileLayout';
import { TabletLayout } from './TabletLayout';
import { PROP_TYPES } from './types';

const COLLAPSABLE_LEFT_MENU_BREAKPOINT = 1000;
const STACKED_LEFT_MENU_BREAKPOINT = 728;

export const SDv2EditorLayout = ({
    navigation,
    header,
    content,
    footer,
    showSharedTextareaToolbar = false,
}) => {
    const { width } = useWindowDimensions();
    const styles = require('./index.scss');

    const shouldShowCollapseNavbar =
        width && width < COLLAPSABLE_LEFT_MENU_BREAKPOINT && width > STACKED_LEFT_MENU_BREAKPOINT;
    const shouldHideNavbarCompletely = width && width < STACKED_LEFT_MENU_BREAKPOINT;

    if (shouldHideNavbarCompletely) {
        return (
            <MobileLayout {...{ navigation, header, content, footer, showSharedTextareaToolbar }} />
        );
    }

    if (shouldShowCollapseNavbar) {
        return (
            <TabletLayout {...{ navigation, header, content, footer, showSharedTextareaToolbar }} />
        );
    }

    return (
        <Box className={styles.menuAndMainContainer} flex={1} id="SDv2EditorLayout">
            <div className={styles.menuContainer}>
                <div className={styles.stickyContainer}>{navigation}</div>
            </div>
            <Content {...{ header, content, footer, showSharedTextareaToolbar }} />
        </Box>
    );
};

SDv2EditorLayout.propTypes = PROP_TYPES;

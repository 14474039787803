import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { ListGroup, ListGroupItem } from 'react-bootstrap';

import classNames from 'classnames';

import { useSelector } from 'react-redux';

import { getPriceItemExtendedPrice } from '@og-pro/shared-config/priceTables/requisitionUtils';

import { Button, RequisitionsEmptyState } from '../../../../../components';
import { currencyFormatter } from '../../../../../helpers';
import { getRequisitionsPath } from '../../../RequisitionsDashboard/RequisitionsDashboard/selectors';
import { getGovernmentSalesTax } from '../../../../../selectors/govApp';

export const AccountSplitLineItems = ({ accountData }) => {
    const params = useParams();
    const styles = require('./index.scss');

    const requisitionsPath = getRequisitionsPath({ params });
    const tax = useSelector(getGovernmentSalesTax);

    if (accountData.length === 0) {
        return (
            <RequisitionsEmptyState
                icon="fa fa-question-circle"
                subtitle="No price items were found for this account"
                title="No Items Found"
            />
        );
    }

    const isSplit = (accountSplitItem) => {
        const { priceItem, amount: splitAmount } = accountSplitItem;
        const lineItemAmount = getPriceItemExtendedPrice(
            priceItem.quantity,
            priceItem.unitPrice,
            priceItem.discount,
            priceItem.discountType,
            priceItem.taxable ? tax : null
        );

        return lineItemAmount !== splitAmount;
    };

    const totalAmount = accountData.reduce((acc, accountSplitItem) => {
        const { amount: splitAmount } = accountSplitItem;

        return acc + splitAmount;
    }, 0);

    return (
        <>
            <div className={styles.header}>
                <div className={styles.inlineSeparate}>
                    <h3 className={styles.title}>Open Requisitions (In Process)</h3>
                </div>
            </div>
            <ListGroup>
                <ListGroupItem className={styles.tableHeader}>
                    <div className="row">
                        <div className="col-md-2">Request #</div>
                        <div className="col-md-6">Description</div>
                        <div className="col-md-1">Line Item #</div>
                        <div className="col-md-1">Split</div>
                        <div className="col-md-2 text-right">Amount</div>
                    </div>
                </ListGroupItem>
                {accountData.map((accountSplitItem) => {
                    const { priceItem } = accountSplitItem;

                    return (
                        <ListGroupItem className={styles.tableRow} key={accountSplitItem.id}>
                            <div className="row">
                                <div
                                    className={classNames(
                                        'col-md-2',
                                        styles.requisitionLinkContainer
                                    )}
                                >
                                    <Button
                                        bsStyle="link"
                                        className={styles.requisitionLink}
                                        to={`${requisitionsPath}/${priceItem.priceTable.requisition.id}`}
                                        zeroPadding
                                    >
                                        {priceItem.priceTable.requisition.identifier}
                                    </Button>
                                </div>
                                <div className={classNames('col-md-6', styles.description)}>
                                    {priceItem.description}
                                </div>
                                <div className="col-md-1">{priceItem.orderById}</div>
                                <div className="col-md-1">
                                    {isSplit(accountSplitItem) ? 'Yes' : 'No'}
                                </div>
                                <div className="col-md-2 text-right">
                                    {currencyFormatter({
                                        value: accountSplitItem.amount,
                                    })}
                                </div>
                            </div>
                        </ListGroupItem>
                    );
                })}
                <ListGroupItem className={styles.total}>
                    <div className="row">
                        <div className="col-md-1">In Process Total</div>
                        <div className="col-md-9" />
                        <div className="col-md-2 text-right">
                            {currencyFormatter({ value: totalAmount })}
                        </div>
                    </div>
                </ListGroupItem>
            </ListGroup>
        </>
    );
};

AccountSplitLineItems.propTypes = {
    accountData: PropTypes.array,
};

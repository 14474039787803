import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { CategoryCode } from './CategoryCode';

export class CategoryCodes extends PureComponent {
    static propTypes = {
        codes: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
            })
        ),
        limit: PropTypes.number,
        noCategoriesText: PropTypes.string,
        showIcon: PropTypes.bool,
    };

    static defaultProps = {
        codes: [],
        limit: 5, // Use null to specify no limit
        noCategoriesText: null,
    };

    constructor(props) {
        super(props);
        this.state = { showAll: false };
    }

    showAll = (e) => {
        e.stopPropagation();
        this.setState({ showAll: true });
    };

    renderNoCodes() {
        const { noCategoriesText } = this.props;

        if (!noCategoriesText) {
            return null;
        }

        return (
            <span>
                {this.renderIcon()}
                {noCategoriesText}
            </span>
        );
    }

    renderIcon() {
        const { showIcon } = this.props;

        if (!showIcon) {
            return null;
        }

        return (
            <span>
                <i className="fa fa-fw fa-tags" />
                &nbsp;
            </span>
        );
    }

    render() {
        const { codes, limit } = this.props;
        if (codes.length === 0) return this.renderNoCodes();

        // Ensure more than 1 item are being hidden
        const imposedLimit = limit ? limit + 1 : codes.length;
        const hasLimit = imposedLimit < codes.length;
        const showAll = this.state.showAll || !hasLimit;
        const displayCodes = showAll ? codes : codes.slice(0, limit);

        const numCodes = displayCodes.length;
        const Codes = displayCodes.map((category, idx) => (
            <span key={category.id}>
                <CategoryCode {...category} />
                {idx < numCodes - 1 && <span>, </span>}
            </span>
        ));
        return (
            <span>
                {this.renderIcon()}
                {Codes}
                {!showAll && (
                    <>
                        ...&nbsp;
                        <span className="pseudoLink" onClick={this.showAll}>
                            show all
                        </span>
                    </>
                )}
            </span>
        );
    }
}

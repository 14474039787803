import { get } from 'lodash';
import { createSelector } from 'reselect';

import { getProjectsJS } from '../../containers/selectors';

const getPristine = (state, props) => get(props, 'meta.pristine');

const getProjectSelectOptionsLabel = (project) => {
    const projectFinancialId = project.financialId ? `[${project.financialId}] ` : '';
    const projectTitle = project.title || 'Untitled Project';

    return `${projectFinancialId}${projectTitle}`;
};

export const getProjectSelectOptions = createSelector(
    [getProjectsJS, (state, props) => props.selectedProject, getPristine],
    (projects, selectedProject, pristine) => {
        let foundProjects = projects;

        // Ensure selected project is in the list of options
        if (selectedProject && pristine && projects.every((p) => p.id !== selectedProject.id)) {
            foundProjects = [selectedProject].concat(projects);
        }
        return foundProjects.map((project) => {
            return {
                label: getProjectSelectOptionsLabel(project),
                project,
                value: project.id,
            };
        });
    }
);

import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';

import { fieldNames, MAX_NAME_LENGTH } from './constants';
import { qaTagPageName } from '../constants';
import {
    InputText,
    SearchSelect,
    SearchSelectIconOption,
    SearchSelectIconValue,
    SearchSelectUserOption,
} from '../../../../../components';
import { iconSelectOptions } from '../../../../../constants/templateAdmin';
import { limitTextLength } from '../../../../../Forms/normalizers';
import { getRequisitionGroupAdminUserList } from '../../../../../selectors/govApp';

const { DESCRIPTION, ICON, NAME, USER_ID } = fieldNames;

const normalizeName = limitTextLength(MAX_NAME_LENGTH);

export const ReviewSequenceInfoSection = ({ disabled, showFormValidation }) => {
    const requisitionGroupAdminUserSelectOptions = useSelector(getRequisitionGroupAdminUserList);

    return (
        <div className="row">
            <div className="col-md-4">
                <Field
                    component={InputText}
                    disabled={disabled}
                    hasFeedback={false}
                    help="Enter a name for identifying this sequence"
                    label="Sequence Name"
                    name={NAME}
                    normalize={normalizeName}
                    placeholder="Enter name"
                    qaTag={`${qaTagPageName}-${NAME}`}
                    showValidation={showFormValidation}
                    type="text"
                />
            </div>
            <div className="col-md-4">
                <Field
                    component={SearchSelect}
                    components={{
                        Option: SearchSelectIconOption,
                        SingleValue: SearchSelectIconValue,
                    }}
                    disabled={disabled}
                    help="Visual identifier for this sequence"
                    label="Sequence Icon"
                    name={ICON}
                    options={iconSelectOptions}
                    placeholder="Select an icon to use"
                    qaTag={`${qaTagPageName}-${ICON}`}
                    showValidation={showFormValidation}
                />
            </div>
            <div className="col-md-4">
                <Field
                    backspaceRemovesValue={false}
                    component={SearchSelect}
                    components={{
                        Option: SearchSelectUserOption,
                    }}
                    disabled={disabled}
                    help="User who owns the sequence (must be an admin)"
                    label="Sequence Owner"
                    name={USER_ID}
                    options={requisitionGroupAdminUserSelectOptions}
                    placeholder="Select sequence owner"
                    qaTag={`${qaTagPageName}-${USER_ID}`}
                    showValidation={showFormValidation}
                />
            </div>
            <div className="col-md-12">
                <Field
                    component={InputText}
                    disabled={disabled}
                    hasFeedback={false}
                    help="Enter a description for when to use this sequence"
                    label="Sequence Description"
                    minRows={2}
                    name={DESCRIPTION}
                    placeholder="Enter description"
                    qaTag={`${qaTagPageName}-${DESCRIPTION}`}
                    showValidation={showFormValidation}
                    type="textarea"
                />
            </div>
        </div>
    );
};

ReviewSequenceInfoSection.propTypes = {
    disabled: PropTypes.bool,
    showFormValidation: PropTypes.bool,
};

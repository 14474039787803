import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export class FixedFooter extends PureComponent {
    static propTypes = {
        bsStyle: PropTypes.string.isRequired,
        children: PropTypes.node.isRequired,
        className: PropTypes.string,
        offset: PropTypes.number,
    };

    static defaultProps = {
        offset: 50,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const { bsStyle, children, className, offset } = this.props;

        const classes = classnames(
            'footer',
            'navbar-fixed-bottom',
            `text-${bsStyle}`,
            this.styles.footer,
            this.styles[bsStyle],
            className
        );

        return (
            <>
                <div style={{ height: offset }} />
                <footer className={classes}>{children}</footer>
            </>
        );
    }
}

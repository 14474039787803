import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Field, getFormSyncErrors } from 'redux-form';
import { tokens } from '@opengov/capital-style';
import { get } from 'lodash';
import { Box } from '@og-pro/ui';

import { ContentBlock } from '../ContentBlock';
import { RichTextInput } from '../../RichTextInput';
import { CDSButton } from '../CDSButtons';
import { sectionDescriptionFieldNames } from '../../../containers/GovApp/TemplateAdmin/forms/ProjectContentForm/constants';

const { colors, typography } = tokens;
const { DESCRIPTION } = sectionDescriptionFieldNames;

export const SectionDescriptionForm = (props) => {
    const {
        disabled,
        form,
        formKey,
        showFormValidation,
        tagOptions,
        templateVariableOptions,
        remove,
    } = props;

    const formErrors = useSelector((state) => getFormSyncErrors(form)(state));

    const blockError = get(formErrors, `${formKey}.${DESCRIPTION}`);

    return (
        <>
            {
                // TODO MUI update to Typography
            }

            <Box
                color={colors.colorGray700}
                fontFamily={typography.baseFont}
                fontSize={16}
                fontWeight={500}
                marginBottom={1}
            >
                Description{' '}
                <Box component="span" fontWeight={400}>
                    (Optional)
                </Box>
            </Box>
            <ContentBlock error={showFormValidation && blockError} withActions>
                <ContentBlock.Main disabled={disabled} hideInputsBorders>
                    <Field
                        borderless
                        component={RichTextInput}
                        disabled={disabled}
                        minRows={2}
                        name={`${formKey}.${DESCRIPTION}`}
                        overrideFeedback
                        placeholder="Enter a description for the section"
                        showValidation={showFormValidation}
                        tagOptions={tagOptions}
                        templateVariableOptions={templateVariableOptions}
                        toolbarPlacement="bottom"
                        useOpenGovStyle
                        useSharedTextareaToolbar
                    />
                </ContentBlock.Main>
                <ContentBlock.ActionSidebar>
                    <ContentBlock.ButtonGroup>
                        <ContentBlock.Button>
                            <CDSButton
                                disabled={disabled}
                                onClick={remove}
                                size="small"
                                variant="tertiary"
                            >
                                <i className="fa fa-trash" />
                            </CDSButton>
                        </ContentBlock.Button>
                    </ContentBlock.ButtonGroup>
                </ContentBlock.ActionSidebar>
            </ContentBlock>
        </>
    );
};

SectionDescriptionForm.propTypes = {
    disabled: PropTypes.bool,
    form: PropTypes.any,
    formKey: PropTypes.string.isRequired,
    remove: PropTypes.func.isRequired,
    showFormValidation: PropTypes.bool,
    tagOptions: PropTypes.array,
    templateVariableOptions: PropTypes.array,
};

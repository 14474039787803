import { padStart } from 'lodash';
import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';

import { fieldNames } from './constants';

const { COUNTER, MASK, NUM_DIGITS } = fieldNames;

const getCounterValue = (state, props) => formValueSelector(props.form)(state, COUNTER);
const getNumDigitsValue = (state, props) => formValueSelector(props.form)(state, NUM_DIGITS);
const getMaskValue = (state, props) => formValueSelector(props.form)(state, MASK);

export const getExampleAutoNumber = createSelector(
    [getCounterValue, getNumDigitsValue, getMaskValue, (state, props) => props.formattingVariables],
    (counter, numDigits, mask, formattingVariables) => {
        if (mask) {
            const counterValue = padStart(counter, numDigits, '0');

            let exampleAutoNumber = (mask || '').replace(/{{\s*COUNTER\s*}}/gi, counterValue);

            formattingVariables.forEach(({ regexp, sampleValue }) => {
                if (regexp) {
                    exampleAutoNumber = exampleAutoNumber.replace(regexp, sampleValue);
                }
            });

            return exampleAutoNumber;
        }
    }
);

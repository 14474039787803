import {
    mappedContactFields,
    mappedProcurementContactFields,
} from '@og-pro/shared-config/contacts';
import { listToDict } from '@og-pro/shared-config/helpers';

export const form = 'contract';

export const contractPartyFields = [
    'companyEmail',
    'companyName',
    'vendor',
    'vendor_id',
    'vendor_organization_id', // Pseudo field used for collecting vnedor organization_id (but vendor_id is what is actually stored). Done due to limitation in vendor search API.
];
export const contractPartyFieldNames = listToDict(contractPartyFields);

export const fields = [
    ...Object.values(mappedContactFields),
    ...Object.values(mappedProcurementContactFields),
    'amount',
    'attachments',
    'budget',
    'categories',
    'contact_id',
    'contractAttachment',
    'contractId',
    'contractMilestones',
    'contractParty',
    'contractRenewals',
    'department_id',
    'departmentName',
    'departmentHead',
    'departmentHeadTitle',
    'durationType',
    'endDate',
    'fundingSourceTags',
    'hasClaim',
    'hasProtest',
    'initialTermLength',
    'initialTermLengthUnit',
    'isCooperative',
    'isEmergency',
    'isPiggyback',
    'isPublic',
    'note',
    'procurement_contact_id',
    'procurement_tag_id',
    'project_id',
    'rebid',
    'requisitionIdentifier',
    'startDate',
    'summary',
    'tag',
    'tags',
    'title',
    'vendorAssignedNo',
    'purchaseOrderNo',
];
export const fieldNames = listToDict(fields);

export const noTagsMessage = () =>
    'Your organization has not created any tags yet. ' +
    'Admin users can add tags through the admin interface.';

const { listToDict } = require('../helpers');

exports.timelineDates = [
    'releaseProjectDate',
    'preProposalDate',
    'qaDeadline',
    'qaResponseDeadline',
    'proposalDeadline',
    'auctionStartDate',
    'auctionEndDate',
    'contractorSelectedDate',
];
exports.timelineDatesDict = listToDict(exports.timelineDates);

const timelineDateNames = [
    'releaseProjectDateName',
    'preProposalDateName',
    'qaDeadlineName',
    'qaResponseDeadlineName',
    'proposalDeadlineName',
    'auctionStartDateName',
    'auctionEndDateName',
    'contractorSelectedDateName',
];
exports.timelineDateNamesDict = listToDict(timelineDateNames);

const timelineDataFields = [
    'auctionExtensionGracePeriod',
    'auctionExtensionTime',
    'hasProposalDeadlineLocation',
    'hasAuctionExtension',
    'isPreProposalRequired',
    'preProposalLocation',
    'proposalDeadlineLocation',
];
exports.timelineDataFieldsDict = listToDict(timelineDataFields);

const hasTimelineDateFields = [
    'hasPreProposalDate',
    'hasQaDeadline',
    'hasQaResponseDeadline',
    'hasContractorSelectedDate',
    'hasAuctionEndDate',
];
const hasTimelineDateFieldsDict = listToDict(hasTimelineDateFields);

const orderByIdNames = [
    'releaseProjectDateOrderById',
    'preProposalDateOrderById',
    'qaDeadlineOrderById',
    'qaResponseDeadlineOrderById',
    'proposalDeadlineOrderById',
    'auctionStartDateOrderById',
    'auctionEndDateOrderById',
    'contractorSelectedDateOrderById',
];
const orderByIdNamesDict = listToDict(orderByIdNames);

exports.PRE_PROPOSAL_AGENDA_URL = 'preProposalAgendaUrl';

// Used for rendering the project timeline forms on the client
exports.projectTimelineConfigurations = [
    {
        projectField: exports.timelineDatesDict.RELEASE_PROJECT_DATE,
        titleField: exports.timelineDateNamesDict.RELEASE_PROJECT_DATE_NAME,
        title: 'Release Project Date',
        displayTime: false,
        required: true,
        orderByIdField: orderByIdNamesDict.RELEASE_PROJECT_DATE_ORDER_BY_ID,
    },
    {
        projectField: exports.timelineDatesDict.PRE_PROPOSAL_DATE,
        titleField: exports.timelineDateNamesDict.PRE_PROPOSAL_DATE_NAME,
        title: 'Pre-Proposal Meeting',
        displayTime: true,
        locationField: exports.timelineDataFieldsDict.PRE_PROPOSAL_LOCATION,
        locationRequiredField: hasTimelineDateFieldsDict.HAS_PRE_PROPOSAL_DATE,
        isIncludedField: hasTimelineDateFieldsDict.HAS_PRE_PROPOSAL_DATE,
        isRequiredField: exports.timelineDataFieldsDict.IS_PRE_PROPOSAL_REQUIRED,
        preProposalAgendaUrlField: exports.PRE_PROPOSAL_AGENDA_URL,
        orderByIdField: orderByIdNamesDict.PRE_PROPOSAL_DATE_ORDER_BY_ID,
    },
    {
        projectField: exports.timelineDatesDict.QA_DEADLINE,
        titleField: exports.timelineDateNamesDict.QA_DEADLINE_NAME,
        title: 'Question Submission Deadline',
        displayTime: true,
        isIncludedField: hasTimelineDateFieldsDict.HAS_QA_DEADLINE,
        orderByIdField: orderByIdNamesDict.QA_DEADLINE_ORDER_BY_ID,
    },
    {
        projectField: exports.timelineDatesDict.QA_RESPONSE_DEADLINE,
        titleField: exports.timelineDateNamesDict.QA_RESPONSE_DEADLINE_NAME,
        title: 'Question Response Deadline',
        displayTime: true,
        isIncludedField: hasTimelineDateFieldsDict.HAS_QA_RESPONSE_DEADLINE,
        orderByIdField: orderByIdNamesDict.QA_RESPONSE_DEADLINE_ORDER_BY_ID,
    },
    {
        projectField: exports.timelineDatesDict.PROPOSAL_DEADLINE,
        titleField: exports.timelineDateNamesDict.PROPOSAL_DEADLINE_NAME,
        title: 'Response Submission Deadline',
        displayTime: true,
        required: true,
        hasProposalDeadlineLocationField:
            exports.timelineDataFieldsDict.HAS_PROPOSAL_DEADLINE_LOCATION,
        locationField: exports.timelineDataFieldsDict.PROPOSAL_DEADLINE_LOCATION,
        locationRequiredField: exports.timelineDataFieldsDict.HAS_PROPOSAL_DEADLINE_LOCATION,
        orderByIdField: orderByIdNamesDict.PROPOSAL_DEADLINE_ORDER_BY_ID,
    },
    {
        projectField: exports.timelineDatesDict.AUCTION_START_DATE,
        titleField: exports.timelineDateNamesDict.AUCTION_START_DATE_NAME,
        title: 'Reverse Auction Starting Date',
        displayTime: true,
        isIncludedField: hasTimelineDateFieldsDict.HAS_AUCTION_END_DATE, // This is intentionally reused since auction projects will have both
        orderByIdField: orderByIdNamesDict.AUCTION_START_DATE_ORDER_BY_ID,
        disabled: true,
        disabledText: 'Only available when "Reverse Auction" setting is used',
    },
    {
        projectField: exports.timelineDatesDict.AUCTION_END_DATE,
        titleField: exports.timelineDateNamesDict.AUCTION_END_DATE_NAME,
        title: 'Reverse Auction Ending Date',
        displayTime: true,
        auctionExtensionGracePeriodField:
            exports.timelineDataFieldsDict.AUCTION_EXTENSION_GRACE_PERIOD,
        auctionExtensionTimeField: exports.timelineDataFieldsDict.AUCTION_EXTENSION_TIME,
        hasAuctionEndDateField: hasTimelineDateFieldsDict.HAS_AUCTION_END_DATE,
        hasAuctionExtensionField: exports.timelineDataFieldsDict.HAS_AUCTION_EXTENSION,
        isIncludedField: hasTimelineDateFieldsDict.HAS_AUCTION_END_DATE,
        orderByIdField: orderByIdNamesDict.AUCTION_END_DATE_ORDER_BY_ID,
        disabled: true,
        disabledText: 'Only available when "Reverse Auction" setting is used',
    },
    {
        projectField: exports.timelineDatesDict.CONTRACTOR_SELECTED_DATE,
        titleField: exports.timelineDateNamesDict.CONTRACTOR_SELECTED_DATE_NAME,
        title: 'Contractor Selection Date',
        displayTime: false,
        isIncludedField: hasTimelineDateFieldsDict.HAS_CONTRACTOR_SELECTED_DATE,
        orderByIdField: orderByIdNamesDict.CONTRACTOR_SELECTED_DATE_ORDER_BY_ID,
    },
];

exports.customTimelineFields = [
    'agendaUrl',
    'date',
    'displayTime',
    'hasTextDate',
    'isAttendanceRequired',
    'isPublic',
    'location',
    'orderById',
    'showLocation',
    'textDate',
    'title',
];
exports.customTimelineFieldsDict = listToDict(exports.customTimelineFields);

exports.PROJECT_TIMELINE_HTML = 'projectTimelineHtml';

exports.TIMELINE_FIELDS = [
    ...exports.timelineDates,
    ...timelineDateNames,
    ...timelineDataFields,
    ...hasTimelineDateFields,
    exports.PRE_PROPOSAL_AGENDA_URL,
];

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { NavItem } from '../..';

export const NotificationsNav = ({ governmentId }) => {
    const hasNewInboxNotification = useSelector((state) =>
        state.inboxNotifications.get('hasNewInboxNotification')
    );
    const styles = require('./index.scss');

    return (
        <NavItem
            aria-label="Notifications Inbox"
            className="notifications-btn"
            eventKey="notifications-inbox"
            key="notifications"
            qaTag="govNavbar-notifications"
            to={`/governments/${governmentId}/notifications-inbox`}
        >
            <i className="fa fa-bell" />
            {hasNewInboxNotification && (
                <i className={classnames('fa fa-circle', styles.newBadge)} />
            )}
            <span className={styles.displayOnMobile}>&nbsp;Notifications</span>
        </NavItem>
    );
};

NotificationsNav.propTypes = {
    governmentId: PropTypes.number.isRequired,
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';

import { fieldNames, form } from './constants';
import { getExampleAutoNumber } from './selectors';
import { validate } from './validate';
import { Button, InputText, NumberWidget, Well } from '../../../../components';

const { COUNTER, MASK, NUM_DIGITS } = fieldNames;

const mapStateToProps = (state, props) => {
    return {
        exampleAutoNumber: getExampleAutoNumber(state, props),
    };
};

const formConfig = {
    enableReinitialize: true,
    form,
    validate,
};

// @connect
// @reduxForm
class ConnectedAutoNumberForm extends Component {
    static propTypes = {
        autoNumberId: PropTypes.number.isRequired,
        disabledText: PropTypes.string,
        exampleAutoNumber: PropTypes.string,
        formattingVariables: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                regexp: PropTypes.regexp,
                sampleValue: PropTypes.string,
                value: PropTypes.string.isRequired,
            })
        ).isRequired,
        handleSubmit: PropTypes.func.isRequired,
        title: PropTypes.string.isRequired,
        updateAutoNumber: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            updateError: null,
            updating: false,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    submitHandler = (data) => {
        const { autoNumberId, updateAutoNumber } = this.props;
        this.setState({ updateError: null, updating: true });
        return updateAutoNumber(autoNumberId, data)
            .then(() => {
                this.setState({ updating: false });
            })
            .catch((error) => {
                this.setState({ updateError: error.message, updating: false });
            });
    };

    renderExampleWell() {
        const { exampleAutoNumber, formattingVariables } = this.props;

        return (
            <Well>
                <b>Next Auto Number (based on configuration):</b>
                <br />
                {exampleAutoNumber || 'N/A'}
                <br />
                <br />
                <b>Available formatting variables:</b>

                {formattingVariables.map(({ label, value }) => (
                    <div key={label}>
                        <div className={this.styles.variableName}>{label}</div>
                        <div className={this.styles.variableExplainer}>{value}</div>
                    </div>
                ))}
            </Well>
        );
    }

    render() {
        const { disabledText, handleSubmit, title } = this.props;

        const { updateError, updating } = this.state;

        const disabled = !!disabledText || updating;

        return (
            <form className={this.styles.container} onSubmit={handleSubmit(this.submitHandler)}>
                <h3 className={this.styles.title}>{title}</h3>
                {!!disabledText && (
                    <div className={this.styles.disabledFormText}>
                        <i className="fa fa-lock" /> {disabledText}
                    </div>
                )}
                <div className="row">
                    <div className="col-xs-12 col-sm-6">
                        <Field
                            component={NumberWidget}
                            disabled={disabled}
                            hasFeedback={false}
                            help="Number to use as next counter. Automatically incremented everytime a new auto number is issued."
                            label="Counter Starting Number"
                            min={0}
                            name={COUNTER}
                            placeholder="1"
                        />
                        <Field
                            component={NumberWidget}
                            disabled={disabled}
                            hasFeedback={false}
                            help="Pads the counter by this many digits"
                            label="Number of Counter Digits"
                            max={20}
                            min={0}
                            name={NUM_DIGITS}
                            placeholder="3"
                        />
                        <Field
                            component={InputText}
                            disabled={disabled}
                            hasFeedback={false}
                            help={
                                'See "Available formatting variables" for options. {{COUNTER}} variable is required.'
                            }
                            label="Auto Number Format"
                            name={MASK}
                            placeholder="2023-{{COUNTER}}"
                            type="text"
                        />
                        <div className="text-right">
                            <Button bsStyle="primary" disabled={disabled} type="submit">
                                Update
                            </Button>
                            {updateError && <div className="error-block">{updateError}</div>}
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-6">{this.renderExampleWell()}</div>
                </div>
            </form>
        );
    }
}

export const AutoNumberForm = compose(
    connect(mapStateToProps),
    reduxForm(formConfig)
)(ConnectedAutoNumberForm);

import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { reduxForm, Field } from 'redux-form';

import { fieldNames } from './constants';
import { validate } from './validate';
import { Button, LoadingButton, SearchSelect, SearchSelectUserOption } from '../../../..';

const formConfig = { validate };

export const ConnectedAddApproversForm = ({
    addingApprovers,
    approvals,
    existingReviewStep,
    handleSubmit,
    hasApprovers,
    hideAddApproversForm,
    initialize,
    onSubmit,
    reviewStepNumber,
    submitServerError,
    users,
}) => {
    const submitHandler = (data) => {
        /**
         * Additional logic is needed beyond the onSubmit function to reset the form, so the form
         * can be used again immediately after submission. This logic is included here and not in
         * the parent component because this component contains the form and the `initialize` function
         * prop that comes with the `reduxForm` decorator.
         */
        onSubmit(data);
        initialize();
    };

    const styles = require('./AddApproversForm.scss');

    const selectOptions = users.map((user) => {
        return {
            value: user.id,
            label: `${user.displayName} ${user.isApprover ? '(Added)' : ''}`,
            disabled: user.isApprover,
            user,
        };
    });

    return (
        <form className="row" onSubmit={handleSubmit(submitHandler)}>
            {!existingReviewStep && <h4 className={styles.header}>Create New Review Step</h4>}
            <div
                className={classnames('col-xs-12', 'col-sm-9', 'col-lg-6', {
                    'col-lg-offset-3': !existingReviewStep,
                })}
            >
                <Field
                    aria-label="Select Approvers"
                    component={SearchSelect}
                    components={{
                        Option: SearchSelectUserOption,
                    }}
                    disabled={addingApprovers}
                    isClearable
                    isMulti
                    isOptionDisabled={(option) => option.disabled}
                    label={`Add Approvers to Review Step ${
                        reviewStepNumber || approvals.length + 1
                    }`}
                    name={fieldNames.APPROVER_IDS}
                    options={selectOptions}
                    placeholder="Select approver(s) or start typing a name"
                    showInviteUserHelp
                />
                {submitServerError && <div className="error-block">{submitServerError}</div>}
            </div>
            <div className={`col-xs-12 col-sm-3 ${styles.addBtn}`}>
                <LoadingButton
                    bsStyle="primary"
                    disabled={addingApprovers}
                    icon="fa-user-plus"
                    loading={addingApprovers}
                    qaTag="approvalsDashboard-submit"
                    text={existingReviewStep ? 'Update Step' : 'Add Step'}
                    type="submit"
                />
                {hasApprovers && (
                    <Button
                        aria-label="Cancel Add Review Step Button"
                        bsStyle="link"
                        onClick={hideAddApproversForm}
                        qaTag="approvalsDashboard-cancelAddReviewStep"
                    >
                        Cancel
                    </Button>
                )}
            </div>
        </form>
    );
};

ConnectedAddApproversForm.propTypes = {
    addingApprovers: PropTypes.bool.isRequired,
    approvals: PropTypes.array.isRequired,
    existingReviewStep: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired, // Passed from `reduxForm` decorator
    hasApprovers: PropTypes.bool.isRequired,
    hideAddApproversForm: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired, // passed from `reduxForm` decorator
    onSubmit: PropTypes.func.isRequired,
    reviewStepNumber: PropTypes.number,
    submitServerError: PropTypes.string,
    users: PropTypes.array.isRequired,
};

export const AddApproversForm = reduxForm(formConfig)(ConnectedAddApproversForm);

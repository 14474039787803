import { fieldNames, HAS_ERRORS } from './constants';
import { questionLogic as questionLogicValidator } from '../../../../../Forms/validation';

const { QUESTION_LOGICS } = fieldNames;

export function validate(values) {
    const errors = {};

    errors[QUESTION_LOGICS] = (values[QUESTION_LOGICS] || []).map((questionLogic) => {
        const questionLogicErrors = questionLogicValidator(questionLogic);

        if (Object.keys(questionLogicErrors).length > 0) {
            questionLogicErrors[HAS_ERRORS] = true;
        }

        return questionLogicErrors;
    });

    return errors;
}

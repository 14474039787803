import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { compose } from 'redux';

import { QuestionnaireFileUploadForm } from '../../../shared';
import { QuestionnaireDownloadContext } from '../../../../../contexts';
import { createFileDownloadInput } from '../../../../../../actions/questionnaire';
import { getParamsId } from '../../../../../../helpers';

const mapStateToProps = (state, props) => {
    const {
        params: { checklistId, projectId, templateChecklistId, templateId },
    } = props;

    let s3Url;
    const checklistIdFromParams = getParamsId(checklistId) || getParamsId(templateChecklistId);
    const projectIdFromParams = getParamsId(projectId) || getParamsId(templateId);

    if (projectIdFromParams) {
        s3Url = `/project/${projectIdFromParams}/s3`;
    } else if (checklistIdFromParams) {
        s3Url = `/checklists/${checklistIdFromParams}/s3`;
    }

    return {
        s3Url,
    };
};

const mapDispatchToProps = {
    uploadHandler: createFileDownloadInput,
};

// @withRouter
// @connect
class ConnectedFileUploadFormBuilderInput extends Component {
    static propTypes = {
        accept: PropTypes.string,
        disabled: PropTypes.bool,
        dropzoneIcons: PropTypes.array,
        dropzoneText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        input: PropTypes.object.isRequired,
        meta: PropTypes.object.isRequired,
        // Used by the selector
        params: PropTypes.object.isRequired, // eslint-disable-line react/no-unused-prop-types
        s3Url: PropTypes.string,
        uploadHandler: PropTypes.func.isRequired,
    };

    static contextType = QuestionnaireDownloadContext;

    render() {
        const {
            accept,
            disabled,
            dropzoneIcons,
            dropzoneText,
            input,
            meta,
            s3Url: s3UrlProp,
            uploadHandler,
        } = this.props;

        const s3UrlContext = this.context;

        // S3 URL can be provided via props or context
        const s3Url = s3UrlProp || s3UrlContext;

        return (
            <QuestionnaireFileUploadForm
                accept={accept}
                disabled={disabled}
                dropzoneIcons={dropzoneIcons}
                dropzoneText={dropzoneText}
                input={input}
                meta={meta}
                uploadHandler={uploadHandler(s3Url)}
            />
        );
    }
}

export const FileUploadFormBuilderInput = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedFileUploadFormBuilderInput);

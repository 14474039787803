import { fieldNames } from './constants';

const { NAME } = fieldNames;

export const validate = (values) => {
    const errors = {};

    if (!values[NAME] || values[NAME].trim().length === 0) {
        errors[NAME] = 'Version name is required';
    } else if (values[NAME].length > 100) {
        errors[NAME] = 'Name is too long. Max 100 characters allowed';
    }

    return errors;
};

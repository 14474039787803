import React from 'react';
import classnames from 'classnames';

import { Button } from '../..';
import { capitalizeAllWords } from '../../helpers/utils';
import { buttonPropTypes } from '../../Button';
import { CDSButtonPropTypes, CDSButtonDefaultProps } from './constants';

export const CDSButton = (props) => {
    const styles = require('./CDSButton.scss');

    const { variant, size, className, noPadding, ...buttonProps } = props;

    const classNames = classnames(
        styles[`variant${capitalizeAllWords(variant)}`],
        styles[`size${capitalizeAllWords(size)}`],
        {
            [styles.noPadding]: noPadding,
        },
        className
    );

    return <Button {...buttonProps} bsClass="cds" className={classNames} />;
};

CDSButton.propTypes = {
    ...buttonPropTypes,
    ...CDSButtonPropTypes,
};

CDSButton.defaultProps = CDSButtonDefaultProps;

import React, { useContext } from 'react';
import { Field } from 'redux-form';
import { Box, Typography } from '@og-pro/ui';

import { RichTextInput } from '../../../../../../../../components';
import { fieldNames } from '../../../../../forms/ProjectContentForm/constants';
import { TemplateEditV2FunctionsContext } from '../../../../context';
import { INTRODUCTION_VARIABLES } from '../../../../../../constants';

const { BACKGROUND, SUMMARY } = fieldNames;

export function TemplateEditV2SummaryAndBackgroundForm() {
    const { disabled, showFormValidation, tagOptions, templateVariableOptions } = useContext(
        TemplateEditV2FunctionsContext
    );

    const styles = require('../index.scss');

    const filteredTemplateVariableOptions = (templateVariableOptions || []).filter(
        (opt) => opt.label !== INTRODUCTION_VARIABLES
    );

    return (
        <div className={styles.container}>
            <div className={styles.section}>
                <div className={styles.row}>
                    <Box className={styles.noMargin} component="h3" mb={0.5}>
                        Summary{' '}
                        <Typography component="span" fontWeight={400}>
                            (optional)
                        </Typography>
                    </Box>
                    <Box className={styles.help} mb={1.5}>
                        The summary should be 1-3 paragraphs. Its purpose is to give the vendor a
                        brief idea of what the project aims to accomplish and to spark interest in
                        potential quality vendors.
                    </Box>
                    <Field
                        component={RichTextInput}
                        disabled={disabled}
                        forcePaddedContent
                        hasFeedback={false}
                        minRows={2}
                        name={SUMMARY}
                        showValidation={showFormValidation}
                        tagOptions={tagOptions}
                        templateVariableOptions={filteredTemplateVariableOptions}
                        useOpenGovStyle
                        useSharedTextareaToolbar
                    />
                </div>
            </div>
            <div className={styles.section}>
                <div className={styles.row}>
                    <Box className={styles.noMargin} component="h3" mb={0.5}>
                        Background{' '}
                        <Typography component="span" fontWeight={400}>
                            (optional)
                        </Typography>
                    </Box>
                    <Box className={styles.help} mb={1.5}>
                        Background information should be relevant to your project and help the
                        vendor better understand why this project is important, what the challenges
                        are, or what would hit the ball out of the park.
                    </Box>
                    <Field
                        component={RichTextInput}
                        disabled={disabled}
                        forcePaddedContent
                        hasFeedback={false}
                        minRows={2}
                        name={BACKGROUND}
                        showValidation={showFormValidation}
                        tagOptions={tagOptions}
                        templateVariableOptions={filteredTemplateVariableOptions}
                        useOpenGovStyle
                        useSharedTextareaToolbar
                    />
                </div>
            </div>
        </div>
    );
}

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { QuestionPrompt } from '..';

export class ResponseDisplayPrompt extends PureComponent {
    static propTypes = {
        canHaveResponse: PropTypes.bool,
        children: PropTypes.node,
        titleClassName: PropTypes.string,
    };

    static defaltProps = {
        titleClassName: '',
    };

    render() {
        const { canHaveResponse, children, titleClassName, ...props } = this.props;

        const useDefault = !children && canHaveResponse;
        return (
            <QuestionPrompt
                {...props}
                hideRequired
                promptClassName="text-muted"
                showPublicDisclaimer
                titleClassName={`text-muted ${titleClassName}`}
            >
                {useDefault ? (
                    <p className="response-display-text">No response submitted</p>
                ) : (
                    children
                )}
            </QuestionPrompt>
        );
    }
}

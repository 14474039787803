import { redirect } from 'react-router-dom';

import { loadProject } from '../../actions/publicProject';

/*
 * Redirects a user to the new project portal path
 */
export default (getState, dispatch) =>
    async ({ params }) => {
        return dispatch(loadProject(params.projectId))
            .then(() => {
                const project = getState().publicProject?.get('project')?.toJS();

                if (!project) {
                    return redirect('/not-found');
                }

                return redirect(`/portal/${project.government.code}/projects/${project.id}`);
            })
            .catch(() => {
                return redirect('/not-found');
            });
    };

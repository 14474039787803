import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getFormNames, getFormSyncErrors } from 'redux-form';

import { ContentBlock } from '../../../SDv2/ContentBlock';

const ERROR = 'This text area is empty. Please add content if only admins can edit.';

export const CriteriaItemTextArea = ({
    disabled,
    isCriteriaBuilder,
    projectSection,
    renderCommentIcon,
    renderDragIcon,
    renderTextAreaItemInput,
    renderNeedsReviewButton,
    shouldRenderCommentIcon,
    showValidation,
}) => {
    const [form] = useSelector((state) => getFormNames()(state));
    const errors = useSelector((state) => getFormSyncErrors(form)(state));

    const styles = require('../shared.scss');
    const hasError =
        showValidation && errors?.projectSectionErrors
            ? errors?.projectSectionErrors[projectSection.id]
            : false;

    return (
        <ContentBlock className={styles.textAreaItem} error={hasError ? ERROR : null} withActions>
            <ContentBlock.Main disabled={disabled} p={2}>
                {renderTextAreaItemInput()}
            </ContentBlock.Main>
            <ContentBlock.ActionSidebar>
                {shouldRenderCommentIcon() && (
                    <ContentBlock.ButtonGroup>
                        <ContentBlock.Button>{renderCommentIcon()}</ContentBlock.Button>
                        <ContentBlock.Button>{renderDragIcon()}</ContentBlock.Button>
                    </ContentBlock.ButtonGroup>
                )}
                <ContentBlock.ButtonGroup>
                    <ContentBlock.Button>{renderDragIcon()}</ContentBlock.Button>
                    <ContentBlock.Button>
                        {isCriteriaBuilder && renderNeedsReviewButton()}
                    </ContentBlock.Button>
                </ContentBlock.ButtonGroup>
            </ContentBlock.ActionSidebar>
        </ContentBlock>
    );
};

CriteriaItemTextArea.propTypes = {
    disabled: PropTypes.bool,
    isCriteriaBuilder: PropTypes.bool,
    projectSection: PropTypes.object,
    renderCommentIcon: PropTypes.func.isRequired,
    renderDragIcon: PropTypes.func.isRequired,
    renderNeedsReviewButton: PropTypes.func.isRequired,
    renderTextAreaItemInput: PropTypes.func.isRequired,
    shouldRenderCommentIcon: PropTypes.func.isRequired,
    showValidation: PropTypes.bool,
};

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { SelectedCode } from './SelectedCode';

export class SelectedCategories extends PureComponent {
    static propTypes = {
        categories: PropTypes.arrayOf(
            PropTypes.shape({
                code: PropTypes.string.isRequired,
                id: PropTypes.number.isRequired,
                setId: PropTypes.number.isRequired,
                title: PropTypes.string.isRequired,
            })
        ),
        deleteHandler: PropTypes.func.isRequired,
        className: PropTypes.string,
    };

    static defaultProps = {
        categories: [],
    };

    render() {
        const { categories, className, deleteHandler } = this.props;

        if (categories.length === 0) {
            return null;
        }

        const Selected = categories.map(({ code, id, setId, title }) => (
            <SelectedCode
                categoryId={id}
                code={code}
                deleteHandler={deleteHandler}
                key={id}
                setId={setId}
                title={title}
            />
        ));

        return (
            <div className={className}>
                <div>
                    <em>Selected Categories:</em>
                </div>
                <div>{Selected}</div>
            </div>
        );
    }
}

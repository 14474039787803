import { recordDocumentTypesDict } from '@og-pro/shared-config/recordsRetention';

import { CONTRACTS_WITH_RENEWALS_FILTER, CONTRACTS_WITHOUT_RENEWALS_FILTER } from '../constants';

const { CONTRACTS, DOCUMENTS, INTAKES } = recordDocumentTypesDict;

// Used to filter records on Recently Closed and Expired tabs, since they have the option to filter by contracts with renewal options
export const filterRecords = (record, currFilter, recordTypeFilter) => {
    if (!recordTypeFilter) {
        return true;
    }
    // if filtering by contracts with or without renewals
    if (
        record.contractRenewals &&
        (currFilter === CONTRACTS_WITH_RENEWALS_FILTER ||
            currFilter === CONTRACTS_WITHOUT_RENEWALS_FILTER)
    ) {
        const hasContractRenewals = record.contractRenewals.some(
            (renewal) => renewal.selected === false
        );
        return currFilter === CONTRACTS_WITH_RENEWALS_FILTER
            ? hasContractRenewals
            : !hasContractRenewals;
    }
    return recordTypeFilter === record.recordType;
};

// setFilter() is called to change the value of the dropdown in the UI
// setRecordTypeFilter() is called to change the value of the current record type filter
// this is only used for the Recently Closed and Expired tabs of Records Retention
export const handleFilterChange = (newFilter, setFilter, setRecordTypeFilter) => {
    setFilter(newFilter);
    if (!newFilter || newFilter === INTAKES || newFilter === DOCUMENTS) {
        setRecordTypeFilter(newFilter);
    } else {
        // if filter is for all contracts/contracts with or without renewals
        setRecordTypeFilter(CONTRACTS);
    }
};

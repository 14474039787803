export const validate = (values) => {
    const errors = {};

    if (values.subject && values.subject.length > 255) {
        errors.subject = 'Subject is too long (250 characters max)';
    }

    if (!values.description) {
        errors.description = 'Please enter a question';
    }

    return errors;
};

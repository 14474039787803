import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Button } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { VENDORS_EVENTS_DICT } from '@og-pro/shared-config/vendors/events';

import { getProjectJS, isProjectOpen, getProjectUrl } from '../selectors';
import connectData from '../../ConnectData';
import { getUserJS, isSubscribedToProject } from '../../selectors';
import { recordPublicProjectView, recordPublicProjectVendorEvent } from '../../../actions/events';
import { showLoginModal, hideLoginModal } from '../../../actions/auth';
import { applyToProject, loadProject } from '../../../actions/publicProject';
import { FixedFooter, LoadingError, LoadingSpinner, NotFound } from '../../../components';
import { ProjectNavbar } from '../../../components/PublicApp';
import { isPurchase } from '../../../helpers';

function fetchData(getState, dispatch, location, params) {
    const selectedProject = getState().publicProject.getIn(['project', 'id']);
    const projectId = Number.parseInt(params.projectId, 10);
    if (selectedProject !== projectId) {
        return dispatch(loadProject(projectId));
    }
}

const mapStateToProps = (state, props) => {
    return {
        isAppliable: isProjectOpen(state),
        isSubscribed: isSubscribedToProject(state),
        loadError: state.publicProject.get('loadError'),
        loadErrorStatus: state.publicProject.get('loadErrorStatus'),
        loading: state.publicProject.get('loading'),
        showingLoginModal: state.auth.get('showLoginModal'),
        project: getProjectJS(state),
        publicUrl: getProjectUrl(state, props),
        user: getUserJS(state),
    };
};

const mapDispatchToProps = {
    applyToProject,
    loadProject,
    showLoginModal,
    hideLoginModal,
    recordPublicProjectView,
    recordPublicProjectVendorEvent,
};

// @connectData
// @connect
class ConnectedProjectNav extends Component {
    static propTypes = {
        applyToProject: PropTypes.func.isRequired,
        isAppliable: PropTypes.bool.isRequired,
        isSubscribed: PropTypes.bool.isRequired,
        loadError: PropTypes.string,
        loadErrorStatus: PropTypes.number,
        loading: PropTypes.bool,
        location: PropTypes.shape({
            pathname: PropTypes.string,
        }).isRequired,
        params: PropTypes.shape({
            governmentCode: PropTypes.string,
            projectId: PropTypes.string,
        }).isRequired,
        showingLoginModal: PropTypes.bool,
        showLoginModal: PropTypes.func.isRequired,
        hideLoginModal: PropTypes.func.isRequired,
        loadProject: PropTypes.func.isRequired,
        project: PropTypes.shape({
            bidResults: PropTypes.object,
            evaluation: PropTypes.shape({
                selectedProposals: PropTypes.array.isRequired,
            }),
            government: PropTypes.shape({
                code: PropTypes.string.isRequired,
                hidePublicProjectContracts: PropTypes.bool.isRequired,
                id: PropTypes.number.isRequired,
                organization: PropTypes.shape({
                    timezone: PropTypes.string.isRequired,
                }).isRequired,
            }).isRequired,
            government_id: PropTypes.number.isRequired,
            hasPublicContracts: PropTypes.bool,
            id: PropTypes.number.isRequired,
            isEvaluationOnly: PropTypes.bool,
            isLibrary: PropTypes.bool,
            showPlanholders: PropTypes.bool.isRequired,
            scoredProposals: PropTypes.array,
            wasPosted: PropTypes.bool,
            projectSections: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
            type: PropTypes.string.isRequired,
        }),
        publicUrl: PropTypes.string,
        recordPublicProjectView: PropTypes.func.isRequired,
        recordPublicProjectVendorEvent: PropTypes.func.isRequired,
        router: PropTypes.object.isRequired,
        user: PropTypes.shape({
            government: PropTypes.shape({
                id: PropTypes.number.isRequired,
            }),
            government_id: PropTypes.number,
            isVendor: PropTypes.bool,
        }),
    };

    get styles() {
        return require('./index.scss');
    }

    componentDidMount() {
        this.recordPublicProjectView();

        if (this.props.loadErrorStatus === 401 && !this.props.showingLoginModal) {
            this.onLoginClick();
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.project && this.props.project) {
            this.recordPublicProjectView();
        }

        if (
            !prevProps.loadErrorStatus &&
            this.props.loadErrorStatus === 401 &&
            !this.props.showingLoginModal
        ) {
            this.onLoginClick();
        }
    }

    applyToProject = () => {
        const {
            project: { id: projectId },
            user,
        } = this.props;

        return this.props.applyToProject(projectId, user);
    };

    noBidProject = () => {
        const {
            project: { id: projectId },
            user,
        } = this.props;

        return this.props.applyToProject(projectId, user, { noBid: true });
    };

    recordPublicProjectView = () => {
        const { project, user } = this.props;

        // Only log the event if we are in the public view for a posted project, and if there is a
        // user, only if that user is not a government user or if they are a government user but not
        // viewing their own project page.
        if (
            project &&
            project.wasPosted &&
            (!user || user.isVendor || user.government.id !== project.government.id)
        ) {
            this.props.recordPublicProjectView(project.id);
        }
    };

    onLoginClick = () => {
        const {
            loadProject: load,
            showLoginModal: show,
            hideLoginModal: hide,
            params: { projectId },
        } = this.props;

        show('Log in to see the project', function () {
            load(parseInt(projectId, 10));
            hide();
        });
    };

    onNavItemClick = (type) => () => {
        const { project, user } = this.props;

        if (project && project.wasPosted && user && user.isVendor) {
            this.props.recordPublicProjectVendorEvent(project.id, type);
        }
    };

    get navItems() {
        const {
            location: { pathname },
            project: {
                bidResults,
                evaluation,
                government: { code, hidePublicProjectContracts },
                hasPublicContracts,
                id: projectId,
                isEvaluationOnly,
                isLibrary,
                scoredProposals,
                showPlanholders,
                type,
            },
        } = this.props;

        const displayOverviewTab = isPurchase(type);
        const urlPathname = `/portal/${code}/projects/${projectId}`;
        const navItems = [
            {
                title: 'Project Documents',
                link: `${urlPathname}/document`,
                indexRoute: !displayOverviewTab,
            },
        ];

        if (displayOverviewTab) {
            navItems.unshift({
                title: 'Overview',
                link: urlPathname,
                indexRoute: true,
            });
        }

        // Only show project documents for library and evaluation only projects
        if (isLibrary || isEvaluationOnly) {
            return navItems;
        }

        navItems.push(
            {
                title: 'Downloads',
                link: `${urlPathname}/downloads`,
                onClick: this.onNavItemClick(VENDORS_EVENTS_DICT.DOWNLOADS_VIEW),
            },
            {
                title: 'Addenda & Notices',
                link: `${urlPathname}/addenda`,
                onClick: this.onNavItemClick(VENDORS_EVENTS_DICT.ADDENDA_VIEW),
            },
            {
                title: 'Question & Answer',
                link: `${urlPathname}/q-and-a`,
                onClick: this.onNavItemClick(VENDORS_EVENTS_DICT.QUESTION_ANSWER_VIEW),
            }
        );

        if (showPlanholders) {
            navItems.push({
                title: 'Followers',
                link: `${urlPathname}/followers`,
                onClick: this.onNavItemClick(VENDORS_EVENTS_DICT.FOLLOWERS_VIEW),
            });
        }

        const selectedProposals = get(evaluation, 'selectedProposals') || [];
        if (bidResults || scoredProposals || selectedProposals.length > 0) {
            navItems.push({ title: 'Results', link: `${urlPathname}/selected-vendor` });
        }

        if (hasPublicContracts && !hidePublicProjectContracts) {
            const contractsPath = `${urlPathname}/contracts`;
            navItems.push({
                active: !!pathname.match(new RegExp(`^${contractsPath}`)),
                title: 'Contracts',
                link: `${urlPathname}/contracts`,
            });
        }

        return navItems;
    }

    renderFooter() {
        const {
            project: { government_id: governmentId, id: projectId },
            router,
        } = this.props;

        const projectDashboardUrl = `/governments/${governmentId}/projects/${projectId}`;

        return (
            <FixedFooter bsStyle="warning" className={this.styles.footer} offset={70}>
                <div className={this.styles.footerWarning}>
                    <strong>
                        <i className="fa fa-exclamation-triangle" />
                        &nbsp;You are viewing the Public project page
                    </strong>
                </div>
                <span className="pseudoLink" onClick={() => router.push(projectDashboardUrl)}>
                    Click here to go back to your Project Dashboard
                </span>
            </FixedFooter>
        );
    }

    render() {
        const {
            isAppliable,
            isSubscribed,
            loadError,
            loadErrorStatus,
            loading,
            params: { governmentCode },
            project,
            publicUrl,
            user,
        } = this.props;

        if (loading) {
            return <LoadingSpinner centered />;
        }

        if (loadError || !project) {
            if (loadErrorStatus === 401) {
                return (
                    <LoadingError
                        error={loadError}
                        info={
                            <div className="text-center">
                                <Button bsStyle="info" onClick={this.onLoginClick} size="small">
                                    Log in
                                </Button>
                            </div>
                        }
                    />
                );
            }

            return <LoadingError error={loadError} />;
        }

        if (governmentCode && project.government.code !== governmentCode) {
            return <NotFound />;
        }

        const isGovUser = !!user && user.government_id === project.government_id;
        const { timezone } = project.government.organization;

        return (
            <>
                <ProjectNavbar
                    applyToProject={this.applyToProject}
                    navItems={this.navItems}
                    noBidProject={this.noBidProject}
                    project={project}
                    publicUrl={publicUrl}
                    showApply={isAppliable}
                    showTimer
                    subscribed={isSubscribed}
                    timezone={timezone}
                    user={user}
                >
                    <Outlet />
                </ProjectNavbar>
                {isGovUser && this.renderFooter()}
            </>
        );
    }
}

export const ProjectNav = compose(
    connectData(fetchData),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedProjectNav);

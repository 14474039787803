import { fromJS } from 'immutable';
import { mapValues } from 'lodash';
import { browserHistory } from '@og-pro-migration-tools/react-router';

import ApiClient from '../api/apiClientRequestWrapper';
import configureStore from './configureStore';

const DEFAULT_OPTIONS = {
    newStore: false,
    serverCtx: {
        req: {
            headers: {},
        },
    },
};

let store;

/**
 * Get the Redux store object for the app. This object will differ if the function is called on the
 * server or on the client. The object will remain stable after the first call unless `newStore` is
 * set to `true`.
 * @param {object} [options] Options for configuration
 * @param {boolean} [options.newStore=false] When `true`, a new store object will be created
 * @param {object} [options.serverCtx] The request context when rendering on the server. Must be
 *                                     provided when `serverRender` is `true`
 * @param {object} [options.serverHistory] The history object when rendering on the server. Must be
 *                                         provided when `serverRender` is `true`
 * @return {object} The state
 */
export function getStore(options = DEFAULT_OPTIONS) {
    if (!options.newStore) {
        if (!store) {
            throw new Error(
                '`getStore` could not find an existing store, and was not asked to create a new one'
            );
        }

        return store;
    }

    if (process.env.SERVER) {
        const client = new ApiClient(options.serverCtx.req.headers);
        store = configureStore(client, options.serverHistory);
    } else {
        const serverComputedStore = window.__data; // eslint-disable-line no-underscore-dangle
        const client = new ApiClient();

        // Transform into Immutable.js collections, but leave top level keys untouched for Redux
        // redux-form does not use immutable currently so we do not force immutable on the form
        // key/value
        const immutableStore = mapValues(serverComputedStore, (initialState, key) => {
            return key === 'form' ? initialState : fromJS(initialState);
        });

        store = configureStore(client, browserHistory, immutableStore);
    }

    return store;
}

const getFinSubdomainUrl = (entityName) => {
    if (__DEV__ || __INT__) {
        return `https://${entityName}.fms.opengov.engineering/`;
    }

    if (__STAGING__) {
        return `https://${entityName}.fms.ogstaging.us/`;
    }

    if (__PROD__) {
        return `https://${entityName}.fms.opengov.com/`;
    }

    return '';
};

const getFinPurchaseOrderUrl = (entityName, vendorNumber, fiscalYear, poNumber) => {
    const poUrlQuery = `stw_php/stwap/approreqi.php?url_vendor_number=${vendorNumber}&url_fiscal_year=${fiscalYear}&url_po_number=${poNumber}&url_calling_program=aplpoci`;

    return getFinSubdomainUrl(entityName) + poUrlQuery;
};

export const getPurchaseOrderUrls = (purchaseOrderObject) =>
    purchaseOrderObject.map(
        ({
            entityName,
            vendor_number: vendorNumber,
            fiscal_year: fiscalYear,
            po_number: poNumber,
        }) => ({
            number: poNumber.toString(),
            href: getFinPurchaseOrderUrl(entityName, vendorNumber, fiscalYear, poNumber),
        })
    );

import classnames from 'classnames';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from '../Tooltip';

export class Badge extends PureComponent {
    static propTypes = {
        icon: PropTypes.string.isRequired,
        tooltipText: PropTypes.string.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const { icon, tooltipText } = this.props;

        return (
            <Tooltip placement="bottom" tooltip={tooltipText}>
                <span className={classnames(this.styles.badge, 'no-print', 'fa-stack')}>
                    <i className="fa fa-stack-2x fa-circle" />
                    <i className={`fa fa-stack-1x fa-inverse fa-${icon}`} />
                </span>
            </Tooltip>
        );
    }
}

import Highcharts from 'highcharts';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { chartColors } from './constants';
import { getContractsByDepartmentData } from './helpers';

const CHART_TITLE = 'Active Contracts By Department';

class ConnectedContractsByDepartment extends PureComponent {
    static propTypes = {
        contracts: PropTypes.array.isRequired,
        router: PropTypes.object.isRequired,
    };

    get contractsByDepartmentChartData() {
        return getContractsByDepartmentData(this.props.contracts, this.props.router);
    }

    componentDidMount() {
        this.renderChart();
    }

    renderChart() {
        // eslint-disable-next-line new-cap
        return new Highcharts.chart({
            chart: {
                borderColor: '#dce0e0',
                borderWidth: 1,
                renderTo: 'contracts-by-department-chart',
                style: {
                    fontWeight: 500,
                    fontSize: '18px',
                    fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
                },
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
            },
            credits: {
                enabled: false,
            },
            legend: {
                labelFormatter() {
                    return `${this.name}: ${this.userOptions.label}`;
                },
                reversed: true,
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    borderWidth: 3,
                    cursor: 'pointer',
                    colors: chartColors,
                    size: '75%',
                    dataLabels: {
                        enabled: true,
                        alignTo: 'connectors',
                        connectorShape: 'crookedLine',
                        distance: 20,
                        format: '<b>{point.name}</b>: {point.y}',
                        style: {
                            color:
                                (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
                        },
                    },
                },
            },
            series: [
                {
                    name: 'Contracts',
                    colorByPoint: true,
                    data: this.contractsByDepartmentChartData,
                },
            ],
            title: {
                text: CHART_TITLE,
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.y}</b>',
            },
        });
    }

    render() {
        return <div id="contracts-by-department-chart" />;
    }
}

export const ContractsByDepartment = withRouter(ConnectedContractsByDepartment);

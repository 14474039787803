import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';

import { ViewEmailNotificationModalBody } from './ViewEmailNotificationModalBody';

export const ViewEmailNotificationModal = ({ emailData, hideModal, loadError, loading }) => {
    const subject = get(emailData, 'subject') || 'Loading...';

    return (
        <Modal bsSize="lg" onHide={hideModal} show>
            <Modal.Header closeButton>
                <Modal.Title className="text-center">{subject}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ViewEmailNotificationModalBody
                    emailData={emailData}
                    loadError={loadError}
                    loading={loading}
                />
            </Modal.Body>
        </Modal>
    );
};

ViewEmailNotificationModal.propTypes = {
    emailData: PropTypes.shape({
        subject: PropTypes.string.isRequired,
    }),
    hideModal: PropTypes.func.isRequired,
    loadError: PropTypes.string,
    loading: PropTypes.bool.isRequired,
};

import { Add as AddIcon } from '@mui/icons-material';
import { Box, Button } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';

import { AddRelationInfo } from '..';
import { qaTagPageName } from '../../constants';

const formConfig = {
    form: 'requisitionRelationsSearch',
};

export const ConnectedRelationsSearchForm = ({
    backHandler,
    cancelHandler,
    children,
    createError,
    createHandler,
    createText,
    disabled,
}) => {
    const styles = require('./index.scss');

    return (
        <div className={styles.container}>
            <div>
                <AddRelationInfo />
            </div>
            <div className={styles.searchSelectContainer}>
                {children}
                {createError && <div className="error-block">{createError}</div>}
            </div>
            <Box display="flex">
                <Box flex={1}>
                    <Button
                        color="secondary"
                        disabled={disabled}
                        onClick={backHandler}
                        qaTag={`${qaTagPageName}-back`}
                        size="small"
                        variant="outlined"
                    >
                        Back
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                        color="secondary"
                        disabled={disabled}
                        onClick={cancelHandler}
                        qaTag={`${qaTagPageName}-cancelSearch`}
                        size="small"
                        variant="text"
                    >
                        Cancel
                    </Button>
                </Box>
                <Button
                    disabled={disabled}
                    onClick={createHandler}
                    qaTag={`${qaTagPageName}-createResource`}
                    size="small"
                    startIcon={<AddIcon />}
                    variant="text"
                >
                    {createText}
                </Button>
            </Box>
        </div>
    );
};

ConnectedRelationsSearchForm.propTypes = {
    backHandler: PropTypes.func.isRequired,
    cancelHandler: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    createError: PropTypes.string,
    createHandler: PropTypes.func.isRequired,
    createText: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
};

export const RelationsSearchForm = reduxForm(formConfig)(ConnectedRelationsSearchForm);

import { showSnackbar } from '../notification';
import { emitProjectSocket } from '../utils';

export const UPLOAD = 'gov/projects/attachments/UPLOAD';
export const UPLOAD_SUCCESS = 'gov/projects/attachments/UPLOAD_SUCCESS';
export const UPLOAD_FAIL = 'gov/projects/attachments/UPLOAD_FAIL';

export function addAttachment(projId) {
    return (dispatch, getState, client) => (data) => {
        dispatch({ type: UPLOAD });
        return client
            .post(`/project/${projId}/attachment`, { data })
            .then((result) => {
                dispatch(showSnackbar('File Uploaded!'));
                const addAction = { type: UPLOAD_SUCCESS, result };
                dispatch(emitProjectSocket(projId, addAction));
            })
            .catch((error) => {
                dispatch(showSnackbar('Upload Failed!', { isError: true }));
                dispatch({ type: UPLOAD_FAIL, error });
            });
    };
}

export const DELETE = 'gov/projects/attachments/DELETE';
export const DELETE_SUCCESS = 'gov/projects/attachments/DELETE_SUCCESS';
export const DELETE_FAIL = 'gov/projects/attachments/DELETE_FAIL';

export function deleteAttachment(projId) {
    return (dispatch, getState, client) => (attachmentId) => {
        dispatch({ type: DELETE });
        return client
            .del(`/project/${projId}/attachment/${attachmentId}`)
            .then(() => {
                dispatch(showSnackbar('Attachment Deleted!'));
                const deleteAction = { type: DELETE_SUCCESS, id: attachmentId };
                dispatch(emitProjectSocket(projId, deleteAction));
            })
            .catch((error) => {
                dispatch(showSnackbar('Delete Failed!', { isError: true }));
                dispatch({ type: DELETE_FAIL, error });
            });
    };
}

export const TOGGLE_EDIT = 'gov/projects/attachments/TOGGLE_EDIT';

export function toggleEditAttachment(attachmentId) {
    return { type: TOGGLE_EDIT, id: attachmentId };
}

export const UPDATE = 'gov/projects/attachments/UPDATE';
export const UPDATE_SUCCESS = 'gov/projects/attachments/UPDATE_SUCCESS';
export const UPDATE_FAIL = 'gov/projects/attachments/UPDATE_FAIL';

export function updateAttachment(projId) {
    return (dispatch, getState, client) =>
        (attachmentId, data, opts = {}) => {
            dispatch({ type: UPDATE, id: attachmentId });
            return client
                .put(`/project/${projId}/attachment/${attachmentId}`, { data })
                .then((result) => {
                    if (!opts.silenceNotifications) {
                        dispatch(showSnackbar('Attachment Updated!'));
                    }
                    const action = { type: UPDATE_SUCCESS, result, id: attachmentId };
                    dispatch(emitProjectSocket(projId, action));
                })
                .catch((error) => {
                    dispatch(showSnackbar('Update Failed!', { isError: true }));
                    dispatch({ type: UPDATE_FAIL, error, id: attachmentId });
                });
        };
}

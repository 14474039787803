import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { FileUploadResponseForm } from '../..';
import { CDSButton, Button, LoadingButton, OutlineButton } from '../../../..';
import { showSnackbar } from '../../../../../actions/notification';
import {
    createNotaryLiveOrder,
    getQuestionnaireResponse,
} from '../../../../../actions/vendProposals';

const mapDispatchToProps = {
    showSnackbar,
    createNotaryLiveOrder,
    getQuestionnaireResponse,
};

export class ConnectedNotarizeResponseForm extends PureComponent {
    static propTypes = {
        showSnackbar: PropTypes.func.isRequired,
        createNotaryLiveOrder: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        getQuestionnaireResponse: PropTypes.func.isRequired,
        id: PropTypes.number.isRequired,
        input: PropTypes.object.isRequired,
        isOGThemeEnabledForComponents: PropTypes.bool,
        meta: PropTypes.object.isRequired,
        proposalId: PropTypes.number.isRequired,
        showValidation: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = this.initialState;
    }

    initialState = {
        checkingStatus: false,
        checkStatusError: null,
        notaryLiveError: null,
        notarizedDocNotFound: false,
        loadingNotaryLive: false,
        useNotaryLive: null,
    };

    changeSkipUpdateValue = (skipUpdateValue) => {
        const { input } = this.props;

        input.onChange({
            ...input.value,
            data: {
                ...input.value.data,
                skipUpdate: skipUpdateValue,
            },
        });
    };

    getQuestionnaireResponse = () => {
        const { id, input, proposalId } = this.props;

        this.setState({
            checkingStatus: true,
            checkStatusError: null,
            notarizedDocNotFound: false,
        });

        return this.props
            .getQuestionnaireResponse(proposalId, id)
            .then((questionnaireResponse) => {
                if (get(questionnaireResponse, 'data.isNotarized')) {
                    this.setState({ checkingStatus: false });
                    this.props.showSnackbar('Notarized document added!', { isSuccess: true });
                    input.onChange(questionnaireResponse);
                } else {
                    this.setState({
                        checkingStatus: false,
                        notarizedDocNotFound: true,
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    checkingStatus: false,
                    checkStatusError: error.message,
                });
            });
    };

    manuallyUpload = () => {
        this.setState({ useNotaryLive: false });
        this.changeSkipUpdateValue(false);
    };

    undoSelection = () => this.setState(this.initialState);

    useNotaryLive = () => {
        const { id, proposalId } = this.props;

        this.setState({
            loadingNotaryLive: true,
            notaryLiveError: null,
            useNotaryLive: true,
        });
        this.changeSkipUpdateValue(true);

        const data = {
            questionnaireId: id,
        };

        return this.props
            .createNotaryLiveOrder(proposalId, data)
            .then((result) => {
                this.setState({ loadingNotaryLive: false });
                window.open(result.linkToOrder, '_blank');
            })
            .catch((error) => {
                this.setState({
                    loadingNotaryLive: false,
                    notaryLiveError: error.message,
                });
            });
    };

    renderNotaryLiveResponseInput() {
        const {
            checkingStatus,
            checkStatusError,
            loadingNotaryLive,
            notarizedDocNotFound,
            useNotaryLive,
            notaryLiveError,
        } = this.state;

        if (!useNotaryLive) {
            return (
                <>
                    <FileUploadResponseForm {...this.props} />
                    <div className="text-center">
                        <Button bsSize="sm" bsStyle="link" onClick={this.undoSelection}>
                            <span className="text-danger">
                                <i className="fa fa-times" /> Cancel Manual Upload
                            </span>
                        </Button>
                    </div>
                </>
            );
        }

        if (loadingNotaryLive) {
            return (
                <>
                    <i className="fa fa-spin fa-spinner" /> Connecting to NotaryLive. You will be
                    redirected momentarily...
                    <Button bsSize="sm" bsStyle="link" onClick={this.undoSelection}>
                        Cancel
                    </Button>
                    <br />
                    <small>Please make sure to allow any pop-ups for NotaryLive</small>
                </>
            );
        }

        if (notaryLiveError) {
            return (
                <>
                    <span className="text-danger">
                        <i className="fa fa-exclamation-triangle" /> Error connecting to Notary
                        Live: {notaryLiveError}
                    </span>
                    <Button bsSize="sm" bsStyle="link" onClick={this.undoSelection}>
                        <i className="fa fa-undo" /> Go back
                    </Button>
                </>
            );
        }

        return (
            <div>
                <label>Notarizing with Notorize Live</label>
                <LoadingButton
                    bsSize="sm"
                    bsStyle="link"
                    disabled={checkingStatus}
                    icon="fa-refresh"
                    loading={checkingStatus}
                    onClick={this.getQuestionnaireResponse}
                    text="Check on status"
                />
                &nbsp;&nbsp;
                <Button bsSize="sm" bsStyle="link" onClick={this.undoSelection}>
                    <span className="text-danger">
                        <i className="fa fa-times" /> Cancel
                    </span>
                </Button>
                {checkingStatus ? (
                    <div>Checking status of notarized document...</div>
                ) : (
                    <div>
                        Once you have completed notarizing with NotorizeLive,&nbsp;
                        <Button
                            bsStyle="link"
                            disabled={checkingStatus}
                            onClick={this.getQuestionnaireResponse}
                            zeroPadding
                        >
                            click here to load your notarized document
                        </Button>
                        .
                        {notarizedDocNotFound && (
                            <div style={{ marginTop: 5 }}>
                                <em>
                                    Notarized document has not been uploaded yet. Please make sure
                                    you have completed the NotaryLive notarization process and check
                                    again.
                                </em>
                            </div>
                        )}
                        {checkStatusError && <div className="error-block">{checkStatusError}</div>}
                    </div>
                )}
            </div>
        );
    }

    render() {
        const {
            disabled,
            input: { value },
            isOGThemeEnabledForComponents,
        } = this.props;

        const { useNotaryLive } = this.state;

        if ((get(value, 'attachments') || []).length > 0) {
            return <FileUploadResponseForm {...this.props} />;
        }

        if (useNotaryLive === null) {
            if (isOGThemeEnabledForComponents && disabled) {
                return (
                    <div>
                        <CDSButton
                            disabled={disabled}
                            qaTag="questionaireResponse-notarizeOnline"
                            size="small"
                            variant="secondary"
                        >
                            <i className="fa fa-magic" /> Notarize Online
                        </CDSButton>
                    </div>
                );
            }

            return (
                <div>
                    <OutlineButton
                        bsSize="sm"
                        bsStyle="success"
                        onClick={this.useNotaryLive}
                        tooltip="Get these document(s) notarized online in minutes using Notary Live. Once completed the notarized document(s) will automatically be stored here with your response."
                        tooltipPlacement="left"
                    >
                        <i className="fa fa-magic" /> Notarize Online
                    </OutlineButton>
                    &nbsp;&nbsp;or&nbsp;&nbsp;
                    <Button bsSize="sm" bsStyle="link" onClick={this.manuallyUpload} zeroPadding>
                        Manually upload notarized document
                    </Button>
                </div>
            );
        }

        return this.renderNotaryLiveResponseInput();
    }
}

export const NotarizeResponseForm = connect(
    null,
    mapDispatchToProps
)(ConnectedNotarizeResponseForm);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

export class BaseApprovalsModal extends PureComponent {
    static propTypes = {
        title: PropTypes.string.isRequired,
        showModal: PropTypes.bool.isRequired,
        hideModal: PropTypes.func.isRequired,
        children: PropTypes.node.isRequired,
    };

    render() {
        const { showModal, hideModal, title, children } = this.props;

        return (
            <Modal onHide={hideModal} show={showModal}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{children}</Modal.Body>
            </Modal>
        );
    }
}

import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

export class PermissionsButton extends Component {
    constructor(props) {
        super(props);
        this.state = { inviteButtonHover: false };
    }

    toggleHoverOn = () => {
        this.setState({ inviteButtonHover: true });
    };

    toggleHoverOff = () => {
        this.setState({ inviteButtonHover: false });
    };

    render() {
        return (
            <Button
                bsStyle={this.state.inviteButtonHover ? 'success' : 'default'}
                onMouseEnter={this.toggleHoverOn}
                onMouseLeave={this.toggleHoverOff}
            >
                <i className="fa fa-user-plus" /> Invite
            </Button>
        );
    }
}

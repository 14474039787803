import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@og-pro/ui';
import { Field, reduxForm } from 'redux-form';
import { Close as CloseIcon } from '@mui/icons-material';

import { CDSButton, VendorSearchInput } from '../../../../components';
import { CustomGridLayout } from './CustomGridLayout';

const ConnectedSearchByPersonRow = ({
    disabled,
    notifiedUsers,
    onRemove,
    onSelectedValueChange,
    selectedValue,
    setTotal,
}) => {
    const styles = require('../index.scss');

    const organizations = selectedValue.reduce((acc, cur) => {
        if (!notifiedUsers.organizationIds.has(cur.user.organizationId)) {
            acc.add(cur.organizationId);
        }

        return acc;
    }, new Set());
    const users = selectedValue.reduce((acc, cur) => {
        if (!notifiedUsers.ids.has(cur.user.id)) {
            return acc + 1;
        }

        return acc;
    }, 0);

    useEffect(() => {
        setTotal(organizations.size || 0);
    }, [selectedValue.length]);

    return (
        <CustomGridLayout
            columns={[
                <Field
                    closeMenuOnSelect={false}
                    component={VendorSearchInput}
                    disabled={disabled}
                    isClearable
                    isMulti
                    label="Search by Vendor or Person"
                    name="userEmails"
                    onChange={(v) => onSelectedValueChange(v)}
                    optionValueField="email"
                    useOpenGovStyle
                />,
                <Box className={styles.tableCount}>{users || 0}</Box>,
                <Box className={styles.tableCount}>{organizations.size || 0}</Box>,
                <Box textAlign="right">
                    <Box
                        aria-label="Remove search option"
                        className={styles.errorDark}
                        component={CDSButton}
                        disabled={disabled}
                        noPadding
                        onClick={onRemove}
                        qaTag="supplierNetworkModal-removeSearchOption"
                        variant="text"
                    >
                        <CloseIcon fontSize="small" />
                    </Box>
                </Box>,
            ]}
        />
    );
};

ConnectedSearchByPersonRow.propTypes = {
    disabled: PropTypes.bool,
    notifiedUsers: PropTypes.shape({ ids: PropTypes.object, organizationIds: PropTypes.object }), // javascript Set
    onRemove: PropTypes.func.isRequired,
    onSelectedValueChange: PropTypes.func.isRequired,
    selectedValue: PropTypes.arrayOf(
        PropTypes.shape({
            user: PropTypes.shape({
                id: PropTypes.number,
                organizationId: PropTypes.number,
            }),
        })
    ),
    setTotal: PropTypes.func.isRequired,
};

export const SearchByPersonRow = reduxForm({
    form: 'searchByPerson',
})(ConnectedSearchByPersonRow);

import { createSelector } from 'reselect';

const getRawFlags = (state) => state.requisitionsAdmin.get('flags');

export const getFlags = createSelector([getRawFlags], (rawFlags) => {
    return rawFlags.toJS();
});

export const getActiveFlags = createSelector([getFlags], (flags) =>
    flags.filter((flag) => flag.isActive)
);

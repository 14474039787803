import React from 'react';
import { Outlet } from 'react-router-dom';

import LoginRoutes from './Login';
import { RequireTokenInviteLogin, RequireUser, RoutePendingUser } from '../components';
import { SubscriptionExpired } from '../../components/SubscriptionExpired/SubscriptionExpired';
import { ActivateAccount, ActivateVendor, GovernmentSignUp, Logout } from '../../containers';
import * as Public from '../../containers/PublicApp';
import {
    clientNavigationOnly,
    loginRedirect,
    redirectToAdmin,
    requireAnyGovUser,
} from '../loaders';

const PublicAuthRoutes = (getState, dispatch) => [
    {
        element: <Public.App />,
        children: [
            ...LoginRoutes(getState, dispatch),
            {
                element: <GovernmentSignUp />,
                loader: async (props) => {
                    // this loader will return null if we are good, but returns a redirect if
                    // we shouldnt be here. Hence the "if" below
                    const permissionRedirect = await loginRedirect(getState, dispatch)(props);

                    if (permissionRedirect) {
                        return permissionRedirect;
                    }

                    return clientNavigationOnly(props);
                },
                path: '/government-signup',
            },
            {
                element: (
                    <RequireTokenInviteLogin>
                        <ActivateAccount />
                    </RequireTokenInviteLogin>
                ),
                path: '/activate',
            },
            {
                element: (
                    <RequireTokenInviteLogin>
                        <ActivateVendor />
                    </RequireTokenInviteLogin>
                ),
                path: '/activate-vendor',
            },
            {
                element: <RoutePendingUser />,
                path: '/activate-invited-user',
            },
            {
                element: (
                    <RequireUser>
                        <Logout />
                    </RequireUser>
                ),
                path: '/logout',
            },
            {
                element: <Outlet />,
                loader: async () => {
                    const permissionRedirect = await requireAnyGovUser(getState)();

                    if (permissionRedirect) {
                        return permissionRedirect;
                    }

                    return redirectToAdmin(getState)();
                },
                path: '/admin',
            },
            {
                element: <SubscriptionExpired />,
                path: '/subscription-expired',
            },
        ],
    },
];

export default PublicAuthRoutes;

export const validate = (values) => {
    const errors = {};
    const vendorName = values.govAddedVendorName;

    if (!vendorName) {
        errors.govAddedVendorName = 'Please enter a vendor name';
    }

    if (vendorName && vendorName.length > 128) {
        errors.govAddedVendorName = 'Name cannot exceed 128 characters';
    }

    return errors;
};

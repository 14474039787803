export const instructions = 'List the criteria that will be used in scoring vendor responses';

export const helpPopover =
    'Each criteria must be given a weight and scoring method that ' +
    'evaluators will use to score responses.';

export const helpSealedBid =
    'A sealed bid process prevents evaluators from viewing vendor ' +
    'responses until the bid is unsealed. ' +
    'You will have the ability to release the bids later in the process.';

import React from 'react';
import PropTypes from 'prop-types';

import { CDSButton } from '../../../../../../../../components';

export const TemplateEditV2DrawerContentAddDividerButton = ({ onClick }) => {
    const styles = require('../index.scss');

    return (
        <div className={styles.addDivider}>
            <div className={styles.addDividerLine}>
                <hr />
            </div>
            <div>
                <CDSButton
                    onClick={onClick}
                    qaTag="manageSections-addDivider"
                    size="small"
                    variant="text"
                >
                    <i className="fa fa-plus" /> Add Divider
                </CDSButton>
            </div>
            <div className={styles.addDividerLine}>
                <hr />
            </div>
        </div>
    );
};

TemplateEditV2DrawerContentAddDividerButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};

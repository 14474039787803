import React, { useContext } from 'react';
import { getFormValues } from 'redux-form';
import PropTypes from 'prop-types';

import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';

import { Box } from '@mui/material';

import { useSelector } from 'react-redux';

import { ErrorBanner } from '@og-pro/ui';
import { createCustomFieldFormName } from '@og-pro/shared-config/customFormService/customField';

import { LoadingError, Main, NotFound, PageTitle } from '../../../../components';
import { CreateCustomFieldHeader } from './CreateCustomFieldHeader';

import { pageContainerStyles } from './styles';
import { isSystemAdminUser } from '../../../selectors';
import { hasRequisitionSubscription } from '../../../GovApp/selectors';
import { CreateCustomFieldForm } from './CreateCustomFieldForm';
import { withCreateCustomFieldForm } from './CreateCustomFieldFormHOC';
import { scrollToFirstError } from '../../RequisitionsCreate/utils';
import { CreateCustomFieldContext } from './context';

const ConnectedRequisitionsCreateCustomField = ({ handleSubmit }) => {
    const enableCustomFields = useFlags(FLAGS.ENABLE_CUSTOM_FIELDS);
    const isSystemAdmin = useSelector(isSystemAdminUser);
    const hasRequisitions = useSelector(hasRequisitionSubscription);
    const formValues =
        useSelector((state) => getFormValues(createCustomFieldFormName)(state)) || {};

    const { showFormValidation, formErrorsCount } = useContext(CreateCustomFieldContext);

    const fieldType = formValues.fieldType;

    const showErrorBanner = showFormValidation && formErrorsCount > 0;

    if (!enableCustomFields) {
        return <NotFound />;
    }

    if (!isSystemAdmin || !hasRequisitions) {
        return (
            <LoadingError error="You do not have permission to view this page" useOpenGovStyle />
        );
    }

    return (
        <Main>
            <PageTitle title="Create New Custom Field" />
            <Box sx={pageContainerStyles}>
                <CreateCustomFieldHeader fieldType={fieldType} handleSubmit={handleSubmit} />
                {showErrorBanner && (
                    <ErrorBanner
                        errorCount={formErrorsCount}
                        jumpToErrorFn={scrollToFirstError}
                        title="This Field Cannot Be Saved Yet"
                    />
                )}
                <CreateCustomFieldForm fieldType={fieldType} />
            </Box>
        </Main>
    );
};

ConnectedRequisitionsCreateCustomField.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
};

export const RequisitionsCreateCustomField = withCreateCustomFieldForm()(
    ConnectedRequisitionsCreateCustomField
);

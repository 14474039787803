import { upperCase } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Button, ConnectedClients, StatusLabel } from '../../../../components';
import { checklistStatusClass } from '../../../../components/helpers/statusHelper';

export const ContractChecklistTitleMenu = ({
    checklist: {
        status,
        template: { title },
    },
    contractChecklistsPath,
    errorMessage,
    menuButtons,
}) => {
    const styles = require('./index.scss');

    return (
        <div className={styles.titleContainer}>
            <div>
                <Button
                    bsSize="sm"
                    bsStyle="link"
                    qaTag="contractChecklistTitleMenu-back"
                    to={contractChecklistsPath}
                >
                    <i className="fa fa-angle-left" /> All Checklists
                </Button>
            </div>
            <div className="row">
                <div className="col-xs-12 col-md-7 col-lg-8">
                    <h3 className={styles.title}>{title || 'Untitled'}</h3>
                    <StatusLabel
                        bsStyle={checklistStatusClass(status)}
                        className={styles.statusLabel}
                    >
                        {upperCase(status)}
                    </StatusLabel>
                </div>
                <div className="col-xs-12 col-md-5 col-lg-4 text-right">
                    {menuButtons}
                    <div>
                        <ConnectedClients
                            className={styles.connectedClients}
                            isChecklist
                            showOrganization
                        />
                    </div>
                </div>
            </div>
            {errorMessage && <div className="error-block text-right">{errorMessage}</div>}
        </div>
    );
};

ContractChecklistTitleMenu.propTypes = {
    checklist: PropTypes.shape({
        status: PropTypes.string.isRequired,
        template: PropTypes.shape({
            title: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    contractChecklistsPath: PropTypes.string.isRequired,
    errorMessage: PropTypes.string,
    menuButtons: PropTypes.node.isRequired,
};

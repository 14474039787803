import React from 'react';
import { Box, Button, ReduxFormTextField } from '@og-pro/ui';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import { fieldNames, form } from './constants';
import { validate } from './validate';
import { qaTagName } from '../../../constants';

const formConfig = {
    form,
    validate,
};

// @reduxForm
const ConnectedRequestTypeEditNameForm = ({ handleSubmit, onCancel, submitFailed }) => {
    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
            <Field
                component={ReduxFormTextField}
                fullWidth
                label="Name of Request Type*"
                name={fieldNames.NAME}
                qaTag={`${qaTagName}-name`}
                showValidation={submitFailed}
            />
            <Box sx={{ textAlign: 'right' }}>
                <Button
                    color="secondary"
                    onClick={onCancel}
                    qaTag={`${qaTagName}-cancel`}
                    sx={{ marginRight: 1 }}
                    variant="text"
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    qaTag={`${qaTagName}-create`}
                    type="submit"
                    variant="contained"
                >
                    Save
                </Button>
            </Box>
        </Box>
    );
};

ConnectedRequestTypeEditNameForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    submitFailed: PropTypes.bool,
};

export const RequestTypeEditNameForm = reduxForm(formConfig)(ConnectedRequestTypeEditNameForm);

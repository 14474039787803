import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { qaTagPageName } from '../../../../constants';
import { Button } from '../../../../../../../../components';

export const StepHeading = ({ disabled, onDelete, onInsert, stepNumber }) => {
    const styles = require('../index.scss');

    return (
        <div className="row">
            <div className="col-sm-6">
                <h4 className={styles.stepTitle}>Step #{stepNumber}</h4>
            </div>
            <div className="col-sm-6 text-right">
                <Button
                    bsSize="sm"
                    className={classnames(styles.stepButton, styles.insertButton)}
                    disabled={disabled}
                    onClick={onInsert}
                    qaTag={`${qaTagPageName}-insert`}
                >
                    Insert Step <i className="fa fa-level-up" />
                </Button>
                &nbsp;&nbsp;
                <Button
                    bsSize="sm"
                    className={styles.stepButton}
                    disabled={disabled}
                    onClick={onDelete}
                    qaTag={`${qaTagPageName}-remove`}
                >
                    <span className="text-danger">
                        <i className="fa fa-trash" /> Delete Step
                    </span>
                </Button>
            </div>
        </div>
    );
};

StepHeading.propTypes = {
    disabled: PropTypes.bool,
    onDelete: PropTypes.func.isRequired,
    onInsert: PropTypes.func.isRequired,
    stepNumber: PropTypes.number.isRequired,
};

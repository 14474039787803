import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change, formValueSelector } from 'redux-form';
import { Box } from '@og-pro/ui';

import { PostingOptionToggle } from './PostingOptionToggle';

export const PostingOptionToggleGroup = ({
    additionalOptions = [],
    disabled,
    fieldName,
    form,
    info,
    label,
    onChange,
    subHeading,
    uiForceDisabled,
}) => {
    const toggleValue = useSelector((state) => formValueSelector(form)(state, fieldName));
    const dispatch = useDispatch();

    // Reset all additional options if the main toggle is turned off
    const onChangeMerged = (event, value) => {
        if (!value) {
            additionalOptions.forEach(({ fieldName: optionFieldName }) => {
                dispatch(change(form, optionFieldName, false));
            });
        }
        if (onChange) {
            onChange(event, value);
        }
    };

    return (
        <>
            <PostingOptionToggle
                disabled={disabled}
                fieldName={fieldName}
                info={info}
                label={label}
                onChange={onChangeMerged}
                subHeading={subHeading}
                uiForceDisabled={uiForceDisabled}
            />
            {!uiForceDisabled &&
                toggleValue &&
                additionalOptions.map(
                    ({
                        disabled: optionDisabled,
                        fieldName: optionFieldName,
                        info: optionInfo,
                        label: optionLabel,
                        subHeading: optionSubHeading,
                    }) => (
                        <Box key={optionFieldName} paddingTop={1}>
                            <PostingOptionToggle
                                disabled={optionDisabled || disabled}
                                fieldName={optionFieldName}
                                info={optionInfo}
                                label={optionLabel}
                                subHeading={optionSubHeading}
                            />
                        </Box>
                    )
                )}
        </>
    );
};

PostingOptionToggleGroup.propTypes = {
    additionalOptions: PropTypes.arrayOf(
        PropTypes.shape({
            fieldName: PropTypes.string.isRequired,
            info: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            subHeading: PropTypes.string.isRequired,
        })
    ),
    disabled: PropTypes.bool,
    fieldName: PropTypes.string.isRequired,
    form: PropTypes.string.isRequired,
    info: PropTypes.string,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    subHeading: PropTypes.string,
    uiForceDisabled: PropTypes.bool,
};

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { DisplayOptionsForm } from './DisplayOptionsForm';
import { Button, ButtonGroup, Collapse, Print } from '../../../../../components';

export class EvaluationTableDisplayMenu extends PureComponent {
    static propTypes = {
        additionalButton: PropTypes.node,
        children: PropTypes.node,
        className: PropTypes.string,
        handleExportCSVClick: PropTypes.func,
        printContent: PropTypes.func,
        printText: PropTypes.string,
        showAnonymizedEvaluatorsOption: PropTypes.bool,
        showDetailedProposalsOption: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            showOptions: false,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    createEvaluationTableRef = (ref) => {
        this.evaluationTable = ref;
    };

    evaluationTableRef = () => {
        return this.evaluationTable;
    };

    toggleDisplayOptions = () => {
        this.setState((state) => {
            return { showOptions: !state.showOptions };
        });
    };

    renderPrintButton = () => {
        return (
            <Button bsSize="sm" qaTag="evaluationTableDisplayMenu-print">
                <i className="fa fa-print" /> {this.props.printText || 'Print'}
            </Button>
        );
    };

    render() {
        const {
            additionalButton,
            children,
            className,
            handleExportCSVClick,
            printContent,
            showAnonymizedEvaluatorsOption,
            showDetailedProposalsOption,
        } = this.props;

        const { showOptions } = this.state;

        return (
            <>
                <div className={classnames(this.styles.buttonsContainer, className)}>
                    <ButtonGroup className="no-print">
                        {additionalButton}
                        <Print
                            content={printContent || this.evaluationTableRef}
                            removeAfterPrint
                            trigger={this.renderPrintButton}
                        />
                        {handleExportCSVClick && (
                            <Button
                                bsSize="sm"
                                onClick={handleExportCSVClick}
                                qaTag="evaluationTableDisplayMenu-exportToCsv"
                            >
                                <i className="fa fa-download" /> Export to CSV
                            </Button>
                        )}
                        <Button
                            active={showOptions}
                            bsSize="sm"
                            onClick={this.toggleDisplayOptions}
                            qaTag="evaluationTableDisplayMenu-tableOptions"
                        >
                            <i className="fa fa-sliders" /> Table Options
                        </Button>
                    </ButtonGroup>
                    <Collapse in={showOptions}>
                        <DisplayOptionsForm
                            showAnonymizedEvaluatorsOption={showAnonymizedEvaluatorsOption}
                            showDetailedProposalsOption={showDetailedProposalsOption}
                        />
                    </Collapse>
                </div>
                {children && <div ref={this.createEvaluationTableRef}>{children}</div>}
            </>
        );
    }
}

import { browserHistory } from '@og-pro-migration-tools/react-router';

import { resourceManager, trackEvent } from '../helpers';
import { showSnackbar } from './notification';

export const LOAD_RETENTION_CODE = 'retention/LOAD_RETENTION_CODE';
export const LOAD_RETENTION_CODE_FAIL = 'retention/LOAD_RETENTION_CODE_FAIL';
export const LOAD_RETENTION_CODE_SUCCESS = 'retention/LOAD_RETENTION_CODE_SUCCESS';
export const LOAD_GOVERNMENT_RETENTION_CODES = 'retention/LOAD_GOVERNMENT_RETENTION_CODES';
export const LOAD_GOVERNMENT_RETENTION_CODES_FAIL =
    'retention/LOAD_GOVERNMENT_RETENTION_CODES_FAIL';
export const LOAD_GOVERNMENT_RETENTION_CODES_SUCCESS =
    'retention/LOAD_GOVERNMENT_RETENTION_CODES_SUCCESS';
export const ADD_NEW_POLICY = 'retention/ADD_NEW_POLICY';
export const ADD_NEW_POLICY_FAIL = 'retention/ADD_NEW_POLICY_FAIL';
export const ADD_NEW_POLICY_SUCCESS = 'retention/ADD_NEW_POLICY_SUCCESS';
export const EDIT_POLICY = 'retention/EDIT_POLICY';
export const EDIT_POLICY_FAIL = 'retention/EDIT_POLICY_FAIL';
export const EDIT_POLICY_SUCCESS = 'retention/EDIT_POLICY_SUCCESS';
export const REORDER_GOVERNMENT_RETENTIONS = 'retention/REORDER_GOVERNMENT_RETENTIONS';

const onLoadRetentionCodeFailure = ({ error, dispatch }) => {
    dispatch({ type: LOAD_RETENTION_CODE_FAIL, error });
    dispatch(showSnackbar('Error Loading Retention Policy', { isError: true }));
};

const onLoadRetentionCodeStart = ({ dispatch }) => {
    dispatch({ type: LOAD_RETENTION_CODE });
};

const onLoadRetentionCodeSuccess = ({ result, dispatch }) => {
    dispatch({
        type: LOAD_RETENTION_CODE_SUCCESS,
        result,
    });
};

const onLoadGovernmentRetentionCodesFailure = ({ error, dispatch }) => {
    dispatch({ type: LOAD_GOVERNMENT_RETENTION_CODES_FAIL, error });
    dispatch(showSnackbar('Error Loading Retention Policies', { isError: true }));
};

const onLoadGovernmentRetentionCodesStart = ({ dispatch }) => {
    dispatch({ type: LOAD_GOVERNMENT_RETENTION_CODES });
};

const onLoadGovernmentRetentionCodesSuccess = ({ result, dispatch }) => {
    dispatch({
        type: LOAD_GOVERNMENT_RETENTION_CODES_SUCCESS,
        result,
    });
};

const onAddNewPolicyFailure = ({ error, dispatch }) => {
    dispatch({ type: ADD_NEW_POLICY_FAIL, error });
    dispatch(showSnackbar('Error Adding Retention Policy', { isError: true }));
};

const onAddNewPolicyStart = ({ dispatch }) => {
    dispatch({ type: ADD_NEW_POLICY });
};

const onAddNewPolicySuccess = ({ result, dispatch }) => {
    trackEvent('Records Retention: Policy Created');
    browserHistory.push(`/governments/${result.government_id}/retention-admin/manage`);
    dispatch({
        type: ADD_NEW_POLICY_SUCCESS,
        result,
    });
};

const onEditPolicyFailure = ({ error, dispatch }) => {
    dispatch({ type: EDIT_POLICY_FAIL, error });
    dispatch(showSnackbar('Error Editing Retention Policy', { isError: true }));
};

const onEditPolicyStart = ({ dispatch }) => {
    dispatch({ type: EDIT_POLICY });
};

const onEditPolicySuccess = ({ result, dispatch }) => {
    browserHistory.push(`/governments/${result.government_id}/retention-admin/manage`);
    dispatch({
        type: EDIT_POLICY_SUCCESS,
        result,
    });
};

export function reorderPolicies({ data, governmentId }) {
    return resourceManager({
        method: 'put',
        url: `/retention-codes/governments/${governmentId}/reorder`,
        requestOptions: { data },
        onStart: onEditPolicyStart,
        onSuccess: ({ result, dispatch }) => {
            dispatch({
                type: EDIT_POLICY_SUCCESS,
                result,
            });
            dispatch({
                type: LOAD_GOVERNMENT_RETENTION_CODES_SUCCESS,
                result,
            });
            dispatch(showSnackbar('Retention policies order updated', { isSuccess: true }));
        },
        onFailure: onEditPolicyFailure,
        context: { data },
    });
}

/**
 * Load details about a retention code from the ID.
 * @param {object} [options={}] The set of options to pass to the request context
 * @param {number} [options.retentionCodeId] A retention code to get the details by
 * @return {Promise<object[]>} The fetched retention code
 */
export function loadRetentionCode(options = {}) {
    return resourceManager({
        method: 'get',
        url: `/retention-codes/${options.retentionCodeId}`,
        onStart: onLoadRetentionCodeStart,
        onSuccess: onLoadRetentionCodeSuccess,
        onFailure: onLoadRetentionCodeFailure,
        context: options,
    });
}

/**
 * Load the list of retention codes associated with a government.
 * @param {object} [options={}] The set of options to pass to the request context
 * @param {number} [options.governmentId] A government id to filter the list by
 * @return {Promise<object[]>} The fetched retention codes
 */
export function loadGovernmentRetentionCodes(options = {}) {
    return resourceManager({
        method: 'get',
        url: `/retention-codes/governments/${options.governmentId}/list`,
        onStart: onLoadGovernmentRetentionCodesStart,
        onSuccess: onLoadGovernmentRetentionCodesSuccess,
        onFailure: onLoadGovernmentRetentionCodesFailure,
        context: options,
    });
}

/**
 * Adds a new retention code to a government.
 * @param {object} [options={}] The set of options to pass to the request context
 * @param {string} [options.abbreviation] Abbreviation to be set on RetentionCode
 * @param {number} [options.archiveSchedule] How many years until Project is eligible for deletion
 * @param {string} [options.title] Title to be set on RetentionCode
 * @param {boolean} [options.expireAtFiscalYearStart] Should retention code expiration occur after following fiscal cycle
 * @param {number} [options.orderById] What order does this code appear in lists
 * @param {boolean} [options.isActive] Is Retention Code assignable to projects
 * @return {Promise<object[]>} The created retention code
 */
export function addNewPolicy(options = {}) {
    return resourceManager({
        method: 'post',
        url: '/retention-codes',
        requestOptions: options,
        onStart: onAddNewPolicyStart,
        onSuccess: onAddNewPolicySuccess,
        onFailure: onAddNewPolicyFailure,
        context: options,
    });
}

/**
 * Adds a new retention code to a government.
 * @param {object} [options={}] The set of options to pass to the request context
 * @param {string} [options.abbreviation] Abbreviation to be set on RetentionCode
 * @param {number} [options.archiveSchedule] How many years until Project is eligible for deletion
 * @param {string} [options.title] Title to be set on RetentionCode
 * @param {boolean} [options.expireAtFiscalYearStart] Should retention code expiration occur after following fiscal cycle
 * @param {number} [options.orderById] What order does this code appear in lists
 * @param {boolean} [options.isActive] Is Retention Code assignable to projects
 * @return {Promise<object[]>} The created retention code
 */
export function editPolicy(options = {}) {
    return resourceManager({
        method: 'put',
        url: `/retention-codes/${options.data.id}`,
        requestOptions: options,
        onStart: onEditPolicyStart,
        onSuccess: onEditPolicySuccess,
        onFailure: onEditPolicyFailure,
        context: options,
    });
}

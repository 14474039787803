import { getPreciseRoundFloat } from '@og-pro/shared-config/reverseAuctions/util';

import { PROXY_BID } from './constants';
import { currencyFormatter } from '../../../helpers';

export function validate(values, { auctionMaxFractionDigits, currentLowestBid, minBidDecrement }) {
    const errors = {};
    const maximumProxyAmount = getPreciseRoundFloat(
        getPreciseRoundFloat(currentLowestBid, auctionMaxFractionDigits) -
            getPreciseRoundFloat(minBidDecrement * 2, auctionMaxFractionDigits),
        auctionMaxFractionDigits
    );

    if (getPreciseRoundFloat(values[PROXY_BID], auctionMaxFractionDigits) > maximumProxyAmount) {
        errors[PROXY_BID] = `The maximum possible proxy bid is ${currencyFormatter(
            { value: maximumProxyAmount },
            { auctionMaxFractionDigits, useSameMinAndMax: true }
        )}`;
    }

    return errors;
}

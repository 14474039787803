import PropTypes from 'prop-types';
import React from 'react';
import { Panel } from 'react-bootstrap';

import { PurchaseOrderPanelBody } from './PurchaseOrderPanelBody';

export const PurchaseOrderPanel = ({ requisition }) => {
    const sharedStyles = require('../shared.scss');

    return (
        <Panel className={sharedStyles.panel}>
            <Panel.Body className={sharedStyles.panelContainer}>
                <div className={sharedStyles.panelBodyContainer}>
                    <PurchaseOrderPanelBody requisition={requisition} />
                </div>
            </Panel.Body>
        </Panel>
    );
};

PurchaseOrderPanel.propTypes = {
    requisition: PropTypes.object.isRequired,
};

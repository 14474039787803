import classnames from 'classnames';
import { get, isNumber, round } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { proposalEvaluationStatuses } from '@og-pro/shared-config/proposalEvaluations';

import { ProposalEvaluationsListItem } from '../ProposalEvaluationsListItem';
import { EvaluationTableDisplayMenu, EvaluatorScoresTable, NoItems } from '../../../components';
import { Button, ButtonGroup, OutlineButton } from '../../../../../../components';

const { COMPLETE } = proposalEvaluationStatuses;

export class ProposalEvaluationsList extends Component {
    static propTypes = {
        allEvaluationPhaseScores: PropTypes.arrayOf(
            PropTypes.shape({
                evaluation: PropTypes.shape({
                    phaseNumber: PropTypes.number.isRequired,
                }).isRequired,
                proposals: PropTypes.array.isRequired,
                totalWeight: PropTypes.number.isRequired,
            })
        ),
        canEvaluate: PropTypes.bool.isRequired,
        evaluation: PropTypes.shape({
            scoringCriteria: PropTypes.array.isRequired,
        }).isRequired,
        evaluator: PropTypes.object.isRequired,
        getEvaluationPathObj: PropTypes.func.isRequired,
        hasQuestionnaire: PropTypes.bool.isRequired,
        isConsensusView: PropTypes.bool,
        isPastPhase: PropTypes.bool.isRequired,
        params: PropTypes.shape({
            evaluatorId: PropTypes.string,
        }).isRequired,
        project: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }).isRequired,
        proposalsPath: PropTypes.string.isRequired,
        proposals: PropTypes.arrayOf(
            PropTypes.shape({
                companyName: PropTypes.string,
                isExcluded: PropTypes.bool.isRequired,
                proposalEvaluation: PropTypes.shape({
                    orderedProposalCriteriaScores: PropTypes.arrayOf(
                        PropTypes.shape({
                            score: PropTypes.number,
                        })
                    ).isRequired,
                    status: PropTypes.string.isRequired,
                }).isRequired,
            })
        ).isRequired,
        showEvaluationNotes: PropTypes.func.isRequired,
        showEvaluationScorecard: PropTypes.func.isRequired,
        showScoresOnly: PropTypes.bool.isRequired,
        submitProposalEvaluation: PropTypes.func.isRequired,
        totalWeight: PropTypes.number.isRequired,
        unsubmitProposalEvaluation: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            showScores: props.showScoresOnly,
            showAllPhases: false,
        };
    }

    get styles() {
        return require('../index.scss');
    }

    createScoresTableRef = (ref, tableNumber) => {
        const refKey = `scoresTable${tableNumber || ''}`;
        this[refKey] = ref;
    };

    handleExportScoresCSVClick = (e, tableNumber) => {
        const refKey = `scoresTable${tableNumber || ''}`;
        this[refKey].handleExportCSVClick();
    };

    toggleDisplay = () => {
        this.setState((prevState) => ({ showScores: !prevState.showScores }));
    };

    toggleShowAllPhasesDisplay = () => {
        this.setState((prevState) => ({ showAllPhases: !prevState.showAllPhases }));
    };

    renderProposalEvaluationList() {
        const {
            evaluation: { scoringCriteria },
            getEvaluationPathObj,
            proposalsPath,
            proposals,
            showEvaluationNotes,
            showEvaluationScorecard,
            submitProposalEvaluation,
            unsubmitProposalEvaluation,
        } = this.props;

        // Do not include admin scored items in criteria to score count
        const criteriaToScoreCount = scoringCriteria.filter((crit) => !crit.isAdminScored).length;
        const Proposals = proposals.map((proposal) => {
            const {
                companyName,
                proposalEvaluation: {
                    orderedProposalCriteriaScores,
                    status,
                    updateError,
                    updating,
                },
            } = proposal;

            // Do not include admin scored items in score count
            const criteriaWithScoreCount = orderedProposalCriteriaScores.filter(
                (critScore, index) => {
                    return (
                        !get(scoringCriteria, [index, 'isAdminScored']) &&
                        critScore &&
                        isNumber(critScore.score)
                    );
                }
            ).length;

            const percentComplete = round((criteriaWithScoreCount / criteriaToScoreCount) * 100, 1);

            return (
                <ProposalEvaluationsListItem
                    canEvaluate={this.props.canEvaluate}
                    companyName={companyName}
                    criteriaToScoreCount={criteriaToScoreCount || 1}
                    criteriaWithScoreCount={criteriaWithScoreCount}
                    href={getEvaluationPathObj(`${proposalsPath}/${proposal.id}`)}
                    isEvaluationSubmitted={status === COMPLETE}
                    isExcluded={proposal.isExcluded}
                    isScorecardComplete={percentComplete === 100}
                    key={proposal.id}
                    percentComplete={percentComplete}
                    proposal={proposal}
                    showEvaluationNotes={showEvaluationNotes(proposal)}
                    showEvaluationScorecard={showEvaluationScorecard(proposal)}
                    submitProposalEvaluation={submitProposalEvaluation}
                    unsubmitProposalEvaluation={unsubmitProposalEvaluation}
                    updateError={updateError}
                    updating={updating}
                />
            );
        });
        return (
            <>
                <div className={classnames('row', this.styles.tableHeader)}>
                    <div className="col-md-3">
                        <strong>Vendor</strong>
                    </div>
                    <div className={classnames('col-md-2')}>
                        <strong>Status</strong>
                    </div>
                    <div className={classnames('col-md-2 col-lg-3', this.styles.skinnyCol)}>
                        <strong>Progress</strong>
                    </div>
                    <div className={classnames('col-md-5 col-lg-4')}>
                        <strong>Actions</strong>
                    </div>
                </div>
                <ul className="list-group">{Proposals}</ul>
            </>
        );
    }

    renderScoresTable() {
        const {
            allEvaluationPhaseScores,
            evaluation,
            evaluator,
            isConsensusView,
            proposals,
            showEvaluationScorecard,
            totalWeight,
        } = this.props;

        const { showAllPhases } = this.state;

        if (showAllPhases) {
            return allEvaluationPhaseScores.map((phaseData, index) => {
                if (phaseData.proposals.length === 0) {
                    return null;
                }
                return (
                    <EvaluationTableDisplayMenu
                        additionalButton={this.renderAllPhaseScoresButton()}
                        className="pull-right"
                        handleExportCSVClick={(e) => this.handleExportScoresCSVClick(e, index + 1)}
                        key={index}
                    >
                        <h4 className={this.styles.evaluationPhaseTitle}>
                            Phase {phaseData.evaluation.phaseNumber} Scorecard
                        </h4>
                        <div className={this.styles.evaluationPhaseScorecard}>
                            <EvaluatorScoresTable
                                createRef={(ref) => this.createScoresTableRef(ref, index + 1)}
                                evaluator={evaluator}
                                isConsensusView={isConsensusView}
                                proposals={phaseData.proposals}
                                scoringCriteria={phaseData.evaluation.scoringCriteria}
                                totalWeight={phaseData.totalWeight}
                            />
                        </div>
                    </EvaluationTableDisplayMenu>
                );
            });
        }

        return (
            <EvaluationTableDisplayMenu
                additionalButton={this.renderAllPhaseScoresButton()}
                className="pull-right"
                handleExportCSVClick={this.handleExportScoresCSVClick}
            >
                <div className={this.styles.evaluationPhaseScorecard}>
                    <EvaluatorScoresTable
                        createRef={this.createScoresTableRef}
                        evaluator={evaluator}
                        isConsensusView={isConsensusView}
                        proposals={proposals}
                        scoringCriteria={evaluation.scoringCriteria}
                        showEvaluationScorecard={showEvaluationScorecard}
                        showUnsubmittedScores
                        totalWeight={totalWeight}
                    />
                </div>
            </EvaluationTableDisplayMenu>
        );
    }

    renderAllPhaseScoresButton() {
        const { allEvaluationPhaseScores, isConsensusView } = this.props;

        const { showAllPhases } = this.state;

        if (!allEvaluationPhaseScores || isConsensusView) {
            return null;
        }

        return (
            <Button bsStyle="link" onClick={this.toggleShowAllPhasesDisplay}>
                {showAllPhases ? 'Show Scores For Current Phase' : 'Show Scores From All Phases'}
            </Button>
        );
    }

    render() {
        const { getEvaluationPathObj, isPastPhase, proposals, proposalsPath, showScoresOnly } =
            this.props;

        const { showScores } = this.state;

        if (proposals.length === 0) {
            return <NoItems text="There are no responses to evaluate yet." />;
        }

        if (!proposals[0].proposalEvaluation) {
            return <NoItems text="This evaluation scorecard does not exist" />;
        }

        return (
            <div>
                {!showScoresOnly && (
                    <div className={`row ${this.styles.buttonBar}`}>
                        <div className="col-xs-12">
                            <ButtonGroup bsSize="sm">
                                {this.props.hasQuestionnaire && !isPastPhase && (
                                    <OutlineButton
                                        bsStyle="primary"
                                        to={getEvaluationPathObj(`${proposalsPath}/compare`)}
                                    >
                                        <i className="fa fa-align-left" /> Compare Responses
                                    </OutlineButton>
                                )}
                                <OutlineButton bsStyle="primary" onClick={this.toggleDisplay}>
                                    {showScores ? 'Show Evaluations' : 'Compare Scores'}
                                    &nbsp;
                                    <i className={`fa fa-caret-${showScores ? 'up' : 'down'}`} />
                                </OutlineButton>
                            </ButtonGroup>
                        </div>
                    </div>
                )}
                {showScores || showScoresOnly
                    ? this.renderScoresTable()
                    : this.renderProposalEvaluationList()}
            </div>
        );
    }
}

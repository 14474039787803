import { fieldNames } from './constants';

const { HIDE_PUBLIC_CLOSED_PROJECTS_LENGTH, HIDE_PUBLIC_CLOSED_PROJECTS_UNIT } = fieldNames;

export const serialize = (values) => {
    if (!values[HIDE_PUBLIC_CLOSED_PROJECTS_LENGTH] || !values[HIDE_PUBLIC_CLOSED_PROJECTS_UNIT]) {
        return {
            ...values,
            [HIDE_PUBLIC_CLOSED_PROJECTS_LENGTH]: null,
            [HIDE_PUBLIC_CLOSED_PROJECTS_UNIT]: null,
        };
    }

    return {
        ...values,
        [HIDE_PUBLIC_CLOSED_PROJECTS_LENGTH]: parseInt(
            values[[HIDE_PUBLIC_CLOSED_PROJECTS_LENGTH]],
            10
        ),
    };
};

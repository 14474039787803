import { get } from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { addendumStatusTypes } from '@og-pro/shared-config/addendums';

import { HtmlContent } from '../../../../../components';

const { RELEASED } = addendumStatusTypes;

export class AddendumReport extends PureComponent {
    static propTypes = {
        addendums: PropTypes.arrayOf(
            PropTypes.shape({
                attachments: PropTypes.array.isRequired,
            })
        ).isRequired,
        isRenderedBySummaryReport: PropTypes.bool,
        project: PropTypes.object.isRequired,
        proposals: PropTypes.array.isRequired,
    };

    render() {
        const {
            addendums,
            isRenderedBySummaryReport,
            project: {
                government: {
                    organization: { timezone },
                },
            },
            proposals,
        } = this.props;

        const filteredAddendums = addendums.filter((addendum) => {
            return !addendum.isNotice && addendum.status === RELEASED;
        });

        const filteredNotices = addendums.filter((addendum) => {
            return addendum.isNotice && addendum.status === RELEASED;
        });

        const hasNoAddendums = filteredAddendums.length === 0;
        const hasNoNotices = filteredNotices.length === 0;

        if (!isRenderedBySummaryReport && hasNoAddendums) {
            return (
                <p>
                    <em>No Addenda issued.</em>
                </p>
            );
        }

        const renderItem = (item) => {
            const { attachments, description, id, isNotice, number, releasedAt } = item;

            return (
                <div key={id}>
                    <strong>
                        {isNotice ? 'Notice' : 'Addendum'} #{number}
                    </strong>
                    <br />
                    <em>{moment(releasedAt).tz(timezone).format('lll')}</em>
                    <HtmlContent content={description} />
                    {attachments.length > 0 && (
                        <>
                            <div>
                                <em>Attachments:</em>
                            </div>
                            {attachments.map((attachment) => (
                                <div key={attachment.id}>
                                    &middot;&nbsp;<a href={attachment.url}>{attachment.title}</a>
                                </div>
                            ))}
                        </>
                    )}
                </div>
            );
        };

        const addendumsConfirmDisplay = filteredAddendums.map((addendum) => {
            const thStyle = {
                backgroundColor: '#003c81',
                color: '#FFFFFF',
                textAlign: 'center',
            };

            return (
                <div key={addendum.id}>
                    <h4>Addendum #{addendum.number}</h4>
                    <table style={{ width: '100%', fontSize: '10pt' }}>
                        <thead>
                            <tr>
                                <th style={{ ...thStyle, width: '35%' }}>Proposal</th>
                                <th style={{ ...thStyle, width: '15%' }}>Confirmed</th>
                                <th style={{ ...thStyle, width: '25%' }}>Confirmed At</th>
                                <th style={{ ...thStyle, width: '25%' }}>Confirmed By</th>
                            </tr>
                        </thead>
                        <tbody>
                            {proposals.map((proposal) => {
                                const addendumConfirm = proposal.addendumConfirms.find((addCon) => {
                                    return addCon && addCon.addendum_id === addendum.id;
                                });
                                const confirmedAt = get(addendumConfirm, 'created_at');
                                const confirmedBy = get(addendumConfirm, 'user.displayName');

                                return (
                                    <tr key={proposal.id}>
                                        <td>{proposal.companyName || 'Unnamed Company'}</td>
                                        <td style={{ textAlign: 'center' }}>
                                            {addendumConfirm ? 'X' : null}
                                        </td>
                                        <td>
                                            {confirmedAt &&
                                                moment(confirmedAt).tz(timezone).format('lll')}
                                        </td>
                                        <td>{confirmedBy}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            );
        });

        if (isRenderedBySummaryReport) {
            return (
                <>
                    <h5>Addenda Issued:</h5>
                    {hasNoAddendums ? (
                        <p>
                            <em>No Addenda issued.</em>
                        </p>
                    ) : (
                        <>
                            {filteredAddendums.map((addendum) => renderItem(addendum))}
                            <h5>Addenda Acknowledgements:</h5>
                            {addendumsConfirmDisplay}
                        </>
                    )}
                    <h5>Notices Issued:</h5>
                    {hasNoNotices ? (
                        <p>
                            <em>No Notices issued.</em>
                        </p>
                    ) : (
                        filteredNotices.map((notice) => renderItem(notice))
                    )}
                </>
            );
        }

        return (
            <>
                <h2>Addenda Issued:</h2>
                {filteredAddendums.map((addendum) => renderItem(addendum))}
                <h2>Addenda Acknowledgements:</h2>
                {addendumsConfirmDisplay}
            </>
        );
    }
}

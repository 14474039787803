export const intakeResources = [
    {
        name: 'CompareCoOps',
        logo: 'https://assets.procurement.opengov.com/assets/compare-coops-logo.jpeg',
        url: 'https://www.comparecoops.com/',
        description:
            'CompareCoOps is a faster and easier way to leverage the benefits of cooperative purchasing, reduce risk with a full audit trail, and save time and money on your purchases.\n\n' +
            'CompareCoOps offers a free, secure coop contract quoting platform with access to a range of cooperative contracts, suppliers, and competitive quotes. They do the comparisons, you get the results.',
    },
    {
        name: 'Purchasing Solutions Alliance',
        logo: 'https://assets.procurement.opengov.com/logos/purchasing-solutions-alliance.png',
        url: 'https://www.psabuy.org/Purchasing-Solutions-Alliance/Cooperative-Contracts',
        description:
            'The Purchasing Solutions Alliance (PSA) is a nationwide purchasing cooperative for public agencies. Cooperative Purchasing has the added benefit of saving you time and resources by relieving you of the burden of procurement documentation requirements, advertisement costs, and contract management.',
    },
];

export const scopeResources = [
    {
        name: 'Marketplace.city',
        logo: 'https://assets.procurement.opengov.com/assets/marketplace-city-logo.png',
        url: 'https://marketplace.city/news/procurenow-partnership',
        description:
            'Marketplace.city helps governments source, evaluate, and procure technology products and services. The Marketplace.city process injects key data including relevant vendors, case studies, RFPs and contracts into your procurement process. It is free for governments to use. Click here to learn more and get started.',
    },
];

import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import { form, SEARCH } from './constants';
import { InputText } from '../../../../../components';

const formConfig = {
    form,
};

// @reduxForm
class ConnectedVendorUserFilter extends Component {
    render() {
        return (
            <Field
                component={InputText}
                name={SEARCH}
                overrideFeedback
                placeholder="Enter vendor name or email"
                type="text"
            />
        );
    }
}

export const VendorUserFilter = reduxForm(formConfig)(ConnectedVendorUserFilter);

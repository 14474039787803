import { get } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { sendGridEventTypesDict } from '@og-pro/shared-config/external';

import {
    HelpIcon,
    LoadingError,
    LoadingSpinner,
    Main,
    ViewEmailNotificationModal,
    ZeroState,
} from '..';

const { BOUNCE, CLICK, DEFERRED, DELIVERED, DROPPED, OPEN, PENDING, PROCESSED, SPAMREPORT } =
    sendGridEventTypesDict;

export class VendorEmailAuditsTable extends PureComponent {
    static propTypes = {
        emailAudits: PropTypes.arrayOf(
            PropTypes.shape({
                sentAt: PropTypes.string.isRequired,
                emailStatus: PropTypes.string,
                pendingUser: PropTypes.shape({
                    email: PropTypes.string.isRequired,
                }),
                subject: PropTypes.string.isRequired,
                user: PropTypes.shape({
                    email: PropTypes.string.isRequired,
                    fullName: PropTypes.string,
                    organization: PropTypes.shape({
                        name: PropTypes.string.isRequired,
                    }).isRequired,
                }),
                emailUuid: PropTypes.string,
            })
        ).isRequired,
        loaded: PropTypes.bool.isRequired,
        loadEmailAudit: PropTypes.func.isRequired,
        loadEmailAudits: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = this.initialState;
    }

    componentDidMount() {
        const { loaded, loadEmailAudits } = this.props;

        if (!loaded) {
            this.setState({ loadingList: true, loadListError: null });
            return loadEmailAudits()
                .then(() => {
                    this.setState({ loadingList: false });
                })
                .catch((error) => {
                    this.setState({ loadingList: false, loadListError: error.message });
                });
        }
    }

    get styles() {
        return require('./index.scss');
    }

    initialState = {
        emailData: null,
        loading: false,
        loadingList: false,
        loadError: null,
        loadListError: null,
        showModal: false,
        subject: null,
    };

    getEmailStatus = (emailStatus) => {
        if (emailStatus === DEFERRED || emailStatus === PENDING || emailStatus === PROCESSED) {
            return ['text-warning', 'Pending', { tooltip: 'The email is on its way' }];
        }

        if (emailStatus === SPAMREPORT) {
            return [
                'text-success',
                'Delivered',
                { icon: 'fa-exclamation-triangle', tooltip: 'The user marked the email as spam' },
            ];
        }

        if (emailStatus === BOUNCE || emailStatus === DROPPED) {
            return [
                'text-danger',
                'Not Delivered',
                { tooltip: "The email was rejected by the user's email system" },
            ];
        }

        if (emailStatus === DELIVERED || emailStatus === OPEN || emailStatus === CLICK) {
            return ['text-success', 'Delivered'];
        }

        return [
            'text-muted',
            'No Delivery Information',
            { tooltip: 'Delivery information is not available for this email' },
        ];
    };

    hideModal = () => this.setState(this.initialState);

    showModal = (emailAudit) => {
        const { loadEmailAudit } = this.props;

        this.setState({
            loadError: null,
            loading: true,
            showModal: true,
        });

        loadEmailAudit(emailAudit.emailUuid)
            .then((result) => {
                this.setState({
                    emailData: {
                        ...result.data,
                        createdAt: emailAudit.sentAt,
                    },
                    loading: false,
                });
            })
            .catch((error) => {
                this.setState({
                    loadError: error && error.message,
                    loading: false,
                });
            });
    };

    renderEmailAudit = (emailAudit) => {
        const { sentAt, emailStatus, id, pendingUser, subject, user } = emailAudit;

        const { email } = user || pendingUser || { email: 'User deleted their account' };
        const organizationName = get(user, 'organization.name');
        const [statusClassName, statusText, statusTooltipData] = this.getEmailStatus(emailStatus);

        return (
            <li className="list-group-item" key={id}>
                <div className="row">
                    <div className="col-sm-2 hidden-xs">
                        <div>{moment(sentAt).format('ll')}</div>
                        <div>{moment(sentAt).format('h:mm a')}</div>
                    </div>
                    <div className="col-xs-12 visible-xs-block">{moment(sentAt).format('lll')}</div>
                    <div className="col-sm-4">
                        {user && user.fullName && (
                            <div className={this.styles.fullName}>{user.fullName}</div>
                        )}
                        <div className={this.styles.email}>{email}</div>
                        {organizationName && <div>{organizationName}</div>}
                    </div>
                    <div className="col-sm-6">
                        <span className="pseudoLink" onClick={() => this.showModal(emailAudit)}>
                            {subject}
                        </span>
                        <div className={statusClassName}>
                            <i className="fa fa-dot-circle-o" /> {statusText}
                            {statusTooltipData && <HelpIcon {...statusTooltipData} />}
                        </div>
                    </div>
                </div>
            </li>
        );
    };

    renderBody() {
        const { emailAudits } = this.props;
        const { emailData, loadError, loading, loadingList, loadListError, showModal } = this.state;

        if (loadingList) {
            return <LoadingSpinner noPadding />;
        }

        if (loadListError) {
            return <LoadingError error={loadListError} />;
        }

        return (
            <>
                {emailAudits.length === 0 && <ZeroState title="No email activity recorded yet" />}
                <ul className="list-group">{emailAudits.map(this.renderEmailAudit)}</ul>
                {showModal && (
                    <ViewEmailNotificationModal
                        emailData={emailData}
                        hideModal={this.hideModal}
                        loadError={loadError}
                        loading={loading}
                    />
                )}
            </>
        );
    }

    render() {
        return (
            <Main>
                <h4 className={this.styles.title}>Email Activity Log</h4>
                {this.renderBody()}
            </Main>
        );
    }
}

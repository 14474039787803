import { fieldNames } from '../constants';

const { ATTACHMENT, TEXT, TITLE } = fieldNames;

export const validate = (values) => {
    const errors = {};

    if (!values[TITLE]) {
        errors[TITLE] = 'Title is required';
    } else if (values[TITLE].length > 256) {
        errors[TITLE] = 'Title is too long';
    }

    if (!values[ATTACHMENT] && !values[TEXT]) {
        errors[TEXT] = 'Please either enter text or upload an attachment';
    }

    return errors;
};

import { fromJS } from 'immutable';

import {
    LOAD_PRE_INVITE_LIST,
    LOAD_PRE_INVITE_LIST_SUCCESS,
    LOAD_PRE_INVITE_LIST_FAIL,
    SHOW_PRE_INVITE_MODAL,
    HIDE_PRE_INVITE_MODAL,
    SHOW_EXPANDED_SUPPLIER_NETWORK_MODAL,
    HIDE_EXPANDED_SUPPLIER_NETWORK_MODAL,
} from '../../actions/govProjects/preInvite';

const initialState = fromJS({
    emailsList: [],
    loading: false,
    loadError: null,
    showModal: false,
    showExpandedSupplierNetworkModal: false,
});

export default function preInviteReducer(state = initialState, action = {}) {
    switch (action.type) {
        case LOAD_PRE_INVITE_LIST:
            return state.merge(
                fromJS({
                    loading: true,
                    loadError: null,
                })
            );
        case LOAD_PRE_INVITE_LIST_SUCCESS:
            return state.merge(
                fromJS({
                    loading: false,
                    emailsList: fromJS(action.result),
                })
            );
        case LOAD_PRE_INVITE_LIST_FAIL:
            return state.merge(
                fromJS({
                    loading: false,
                    loadError: action.error && action.error.message,
                })
            );
        case SHOW_PRE_INVITE_MODAL:
            return state.merge(
                fromJS({
                    showModal: true,
                })
            );
        case HIDE_EXPANDED_SUPPLIER_NETWORK_MODAL:
        case HIDE_PRE_INVITE_MODAL:
            return initialState;
        case SHOW_EXPANDED_SUPPLIER_NETWORK_MODAL: {
            return state.merge(
                fromJS({
                    showExpandedSupplierNetworkModal: true,
                })
            );
        }
        default:
            return state;
    }
}

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Well } from '../../../../../components';

export class AdminScorecardStatus extends PureComponent {
    static propTypes = {
        info: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
        isComplete: PropTypes.bool,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const { info, isComplete } = this.props;

        return (
            <Well className="text-center">
                <strong>Admin Scorecard Status:</strong>&nbsp;
                <strong className={isComplete ? 'text-success' : 'text-warning'}>
                    <i className={`fa fa-${isComplete ? 'check-circle' : 'dot-circle-o'}`} />
                    &nbsp;
                    {isComplete ? 'Submitted' : 'Draft'}
                </strong>
                {info && <div className={this.styles.info}>{info}</div>}
            </Well>
        );
    }
}

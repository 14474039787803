import { addPolicyFormNamesDict, YEARS } from '@og-pro/shared-config/recordsRetention';

const { TITLE, ABBREVIATION, ARCHIVE_SCHEDULE, ARCHIVE_SCHEDULE_HAS_ERRORS, SCHEDULE_OPTIONS } =
    addPolicyFormNamesDict;

export const validate = (values) => {
    const errors = {};

    if (!values[TITLE] || values[TITLE].trim().length === 0) {
        errors[TITLE] = 'This field is required';
    } else if (values[TITLE].length > 256) {
        errors[TITLE] = 'Title is too long. Max 256 characters allowed';
    }

    if (!values[ABBREVIATION] || values[ABBREVIATION].trim().length === 0) {
        errors[ABBREVIATION] = 'This field is required';
    } else if (values[ABBREVIATION].length > 16) {
        errors[ABBREVIATION] = 'Abbreviation is too long. Max 16 characters allowed';
    }

    if (!values[SCHEDULE_OPTIONS] && !values[ARCHIVE_SCHEDULE]) {
        errors[ARCHIVE_SCHEDULE] = {
            years: true,
        };
        errors[ARCHIVE_SCHEDULE_HAS_ERRORS] = 'Must select 0 or more years';
    }

    if (values[SCHEDULE_OPTIONS] === YEARS) {
        if (!values[ARCHIVE_SCHEDULE]) {
            errors[ARCHIVE_SCHEDULE] = {
                years: true,
            };
            errors[ARCHIVE_SCHEDULE_HAS_ERRORS] = 'Must select 0 or more years';
        }
    }

    return errors;
};

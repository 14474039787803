import PropTypes from 'prop-types';
import React from 'react';

export const PurchaseOrder = ({ indexNumber, purchaseOrder: { href, number } }) => {
    const styles = require('./shared.scss');

    return (
        <div className={styles.purchaseOrderItem}>
            <label>Purchase Order {indexNumber}</label>
            <a href={href} rel="noreferrer" target="_blank">
                PO #{number}&nbsp;&nbsp;
                <i className="fa fa-external-link" />
            </a>
        </div>
    );
};

PurchaseOrder.propTypes = {
    indexNumber: PropTypes.string.isRequired,
    purchaseOrder: PropTypes.shape({
        href: PropTypes.string.isRequired,
        number: PropTypes.string.isRequired,
    }).isRequired,
};

export const SEARCH_VENDORS = 'public/vendor/SEARCH_VENDORS';
export const SEARCH_VENDORS_SUCCESS = 'public/vendor/SEARCH_VENDORS_SUCCESS';
export const SEARCH_VENDORS_FAIL = 'public/vendor/SEARCH_VENDORS_FAIL';

export const LOAD_PUBLIC_VENDOR = 'public/vendor/LOAD_PUBLIC_VENDOR';
export const LOAD_PUBLIC_VENDOR_SUCCESS = 'public/vendor/LOAD_PUBLIC_VENDOR_SUCCESS';
export const LOAD_PUBLIC_VENDOR_FAIL = 'public/vendor/LOAD_PUBLIC_VENDOR_FAIL';

export function loadPublicVendor(vendorId, governmentCode) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_PUBLIC_VENDOR });
        return client
            .get(`/vendor/${vendorId}/public-info?governmentCode=${governmentCode}`)
            .then((result) => dispatch({ type: LOAD_PUBLIC_VENDOR_SUCCESS, result }))
            .catch((error) => dispatch({ type: LOAD_PUBLIC_VENDOR_FAIL, error: error?.message }));
    };
}

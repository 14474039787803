import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { Provider, connect } from 'react-redux';
import { MemoryRouter } from 'react-router-dom';

import { proposalStatusesDict } from '@og-pro/shared-config/proposals';

import {
    landscapeReportTypes,
    multiEmailReportTypes,
    reportFormats,
    reportTypes,
} from '../constants';
import {
    AddendumReport,
    AwardReport,
    InterviewInvitationReport,
    NonAwardReport,
    ProposalDocumentReport,
    QuestionAnswerReport,
    EvaluationTabulationReport,
    ProjectSummaryReport,
    ReverseAuctionSummaryReport,
} from '../../reports';
import {
    generateProjectProposalsReport,
    generateProjectReport,
    generateProjectReportWithAttachments,
    loadAddendumsReport,
    loadAllProposals,
    loadAwardReport,
    loadBidTabulationData,
    loadEvaluationTabulationReport,
    loadInterviewReport,
    loadNonAwardReport,
    loadProjectAgreementData,
    loadProposalsWithQuestionnaireResponses,
    loadQuestionAnswerReport,
    loadReverseAuctionSummaryReport,
} from '../../../../../actions/reports';
import { loadProjectStatusHistory } from '../../../../../actions/govProjects';
import { LoadingError, LoadingSpinner } from '../../../../../components';
import { getUserJS } from '../../../../App/selectors';
import { getStore } from '../../../../../store';
import { formatS3SignedURLForIframePreview } from '../../../../../helpers';

const {
    ADDENDUM_REPORT,
    ALL_PROPOSALS_REPORT,
    AWARD_REPORT,
    INTERVIEW_INVITATION_REPORT,
    EVALUATION_TABULATION_REPORT,
    NON_AWARD_REPORT,
    PROJECT_SUMMARY_REPORT,
    PROPOSAL_DOCUMENT_REPORT,
    QUESTION_ANSWER_REPORT,
    REVERSE_AUCTION_SUMMARY_REPORT,
} = reportTypes;

const { DOCX, HTML, PDF, PREVIEW } = reportFormats;

const renderToHtml = (ReportComponent, props) => {
    const El = React.createElement(ReportComponent, props);
    const store = getStore();
    const tree = (
        <Provider key="provider" store={store}>
            <MemoryRouter>{El}</MemoryRouter>
        </Provider>
    );
    return renderToStaticMarkup(tree);
};

const mapStateToProps = (state) => ({
    user: getUserJS(state),
});

const mapDispatchToProps = {
    generateProjectProposalsReport,
    generateProjectReport,
    generateProjectReportWithAttachments,
    loadAddendumsReport,
    loadAllProposals,
    loadAwardReport,
    loadBidTabulationData,
    loadEvaluationTabulationReport,
    loadInterviewReport,
    loadNonAwardReport,
    loadProjectAgreementData,
    loadProjectStatusHistory,
    loadProposalsWithQuestionnaireResponses,
    loadQuestionAnswerReport,
    loadReverseAuctionSummaryReport,
};

class ConnectedReportDisplay extends Component {
    static propTypes = {
        additionalRequestData: PropTypes.object,
        dataForReport: PropTypes.object,
        formatType: PropTypes.string.isRequired,
        generateProjectProposalsReport: PropTypes.func.isRequired,
        generateProjectReport: PropTypes.func.isRequired,
        generateProjectReportWithAttachments: PropTypes.func.isRequired,
        isReportEmailed: PropTypes.bool,
        loadAddendumsReport: PropTypes.func.isRequired,
        loadAllProposals: PropTypes.func.isRequired,
        loadAwardReport: PropTypes.func.isRequired,
        loadBidTabulationData: PropTypes.func.isRequired,
        loadEvaluationTabulationReport: PropTypes.func.isRequired,
        loadInterviewReport: PropTypes.func.isRequired,
        loadNonAwardReport: PropTypes.func.isRequired,
        loadProjectAgreementData: PropTypes.func.isRequired,
        loadProjectStatusHistory: PropTypes.func.isRequired,
        loadProposalsWithQuestionnaireResponses: PropTypes.func.isRequired,
        loadQuestionAnswerReport: PropTypes.func.isRequired,
        loadReverseAuctionSummaryReport: PropTypes.func.isRequired,
        options: PropTypes.object,
        project: PropTypes.object.isRequired,
        reportName: PropTypes.string.isRequired,
        reportType: PropTypes.string.isRequired,
        user: PropTypes.shape({
            email: PropTypes.string.isRequired,
        }).isRequired,
    };

    static defaultProps = {
        isReportEmailed: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            loadingData: true,
        };
    }

    componentDidMount() {
        this.generateReport();
    }

    get styles() {
        return require('./index.scss');
    }

    sendReportRequest = (html, requestData = {}) => {
        const {
            additionalRequestData,
            formatType,
            isReportEmailed,
            options,
            project,
            reportName,
            reportType,
        } = this.props;

        const useLandscape = options?.useLandscape || landscapeReportTypes.includes(reportType);

        const reportData = {
            filename: reportName,
            format: formatType,
            html,
            useLandscape,
            ...additionalRequestData, // Request data added by the modal
            ...requestData, // Request data added directly by the report generator
        };

        if (reportType === ALL_PROPOSALS_REPORT) {
            return this.props.generateProjectProposalsReport(project.id, reportData);
        }

        // Send to reporting API
        if (isReportEmailed) {
            return this.props.generateProjectReportWithAttachments(project.id, reportData);
        }

        return this.props.generateProjectReport(project.id, reportData);
    };

    generateReport = () => {
        const { formatType, isReportEmailed, options, project } = this.props;
        const { getDataProps, loadData, ReportComponent, customReportFunction } = this.reportData;

        this.setState({ loadingData: true });
        return loadData()
            .then((results) => {
                const props = {
                    ...getDataProps(results),
                    options,
                    project,
                };

                if (formatType === HTML) {
                    this.setState({
                        dataProps: props,
                        loadingData: false,
                    });
                    return HTML;
                }

                this.setState({
                    loadingData: false,
                    loadingReport: true,
                });

                // Custom report function is used for reports that don't use React components or
                // need customized report handling
                if (customReportFunction) {
                    return customReportFunction(ReportComponent, props);
                }

                const html = renderToHtml(ReportComponent, props);
                return this.sendReportRequest(html);
            })
            .then((result) => {
                if (result === HTML) {
                    return;
                }

                if (!isReportEmailed) {
                    this.setState({
                        reportFilename: result.filename,
                        reportUrl: result.url,
                    });
                }

                this.setState({
                    loadingReport: false,
                });
            })
            .catch((error) => {
                this.setState({
                    loadError: error.message,
                    loadingData: false,
                    loadingReport: false,
                });
            });
    };

    get reportData() {
        const { dataForReport, options, project, reportType } = this.props;

        switch (reportType) {
            case ADDENDUM_REPORT:
                return {
                    ReportComponent: AddendumReport,
                    loadData: () => this.props.loadAddendumsReport(project.id),
                    getDataProps: (results) => {
                        return {
                            addendums: results[0],
                            proposals: results[1],
                        };
                    },
                };
            case QUESTION_ANSWER_REPORT:
                return {
                    ReportComponent: QuestionAnswerReport,
                    loadData: () => this.props.loadQuestionAnswerReport(project.id),
                    getDataProps: (result) => {
                        return {
                            questions: result,
                        };
                    },
                };
            case AWARD_REPORT:
                return {
                    ReportComponent: AwardReport,
                    loadData: () =>
                        this.props.loadAwardReport(project.government_id, project.id, options),
                    getDataProps: (result) => {
                        return {
                            standardDocument: result,
                        };
                    },
                };
            case INTERVIEW_INVITATION_REPORT:
                return {
                    ReportComponent: InterviewInvitationReport,
                    loadData: () =>
                        this.props.loadInterviewReport(project.government_id, project.id, options),
                    getDataProps: (result) => {
                        return {
                            standardDocument: result,
                        };
                    },
                };
            case NON_AWARD_REPORT:
                return {
                    ReportComponent: NonAwardReport,
                    loadData: () =>
                        this.props.loadNonAwardReport(project.government_id, project.id, options),
                    getDataProps: (result) => {
                        return {
                            standardDocument: result,
                        };
                    },
                };
            case ALL_PROPOSALS_REPORT:
                return {
                    ReportComponent: null,
                    loadData: () => Promise.resolve(),
                    getDataProps: () => {},
                    customReportFunction: () => {
                        return this.sendReportRequest(undefined, {
                            proposalIds: options.proposalIds,
                        });
                    },
                };
            case PROPOSAL_DOCUMENT_REPORT:
                return {
                    ReportComponent: ProposalDocumentReport,
                    loadData: () => Promise.resolve(),
                    getDataProps: () => dataForReport,
                };
            case EVALUATION_TABULATION_REPORT:
                return {
                    ReportComponent: EvaluationTabulationReport,
                    loadData: () => {
                        return Promise.all([
                            this.props.loadEvaluationTabulationReport(
                                project.id,
                                project.evaluation.type
                            ),
                            this.props.loadProposalsWithQuestionnaireResponses(project.id),
                            this.props.loadProjectAgreementData(project.id),
                        ]);
                    },
                    getDataProps: (results) => {
                        const [reportData, proposalsWithQuestionnaireResponses, agreementData] =
                            results;

                        return {
                            agreementData,
                            data: reportData,
                            proposalsWithQuestionnaireResponses,
                            options,
                        };
                    },
                };
            case PROJECT_SUMMARY_REPORT:
                return {
                    ReportComponent: ProjectSummaryReport,
                    loadData: () => {
                        return Promise.all([
                            this.props.loadQuestionAnswerReport(project.id),
                            this.props.loadAddendumsReport(project.id),
                            project.evaluation &&
                                this.props.loadEvaluationTabulationReport(
                                    project.id,
                                    project.evaluation.type
                                ),
                            project.evaluation && this.props.loadBidTabulationData(project.id),
                            this.props.loadAllProposals(project.id),
                            this.props.loadProjectStatusHistory(project.id),
                            this.props.loadProposalsWithQuestionnaireResponses(project.id),
                            this.props.loadProjectAgreementData(project.id),
                        ]);
                    },
                    getDataProps: (results) => {
                        const [
                            questions,
                            addendumData,
                            evaluationReportData,
                            bidTabulationData,
                            proposalsData,
                            projectStatusHistoryData,
                            proposalsWithQuestionnaireResponses,
                            agreementData,
                        ] = results;

                        return {
                            addendums: addendumData[0],
                            addendumsProposals: addendumData[1],
                            agreementData,
                            ...(project.evaluation && { bidTabulationData }),
                            ...(project.evaluation && { evaluationReportData }),
                            options,
                            projectStatusHistoryData: projectStatusHistoryData.result,
                            proposalsListData: proposalsData.filter(
                                (proposal) => proposal.status !== proposalStatusesDict.DRAFT
                            ),
                            proposalsWithQuestionnaireResponses,
                            questions,
                        };
                    },
                };
            case REVERSE_AUCTION_SUMMARY_REPORT:
                return {
                    ReportComponent: ReverseAuctionSummaryReport,
                    loadData: () => this.props.loadReverseAuctionSummaryReport(project.id),
                    getDataProps: (result) => {
                        return {
                            reverseAuctionItems: result,
                        };
                    },
                };
            default:
                return null;
        }
    }

    render() {
        const { formatType, isReportEmailed, reportType, user } = this.props;

        const { dataProps, loadError, loadingData, loadingReport, reportFilename, reportUrl } =
            this.state;

        if (loadingData || loadingReport) {
            const text = loadingData ? 'Loading report data...' : 'Preparing report...';
            return <LoadingSpinner text={text} />;
        }

        if (loadError) {
            return <LoadingError error={loadError} />;
        }

        if (formatType === HTML) {
            const { ReportComponent } = this.reportData;

            return <ReportComponent {...dataProps} />;
        }

        if (formatType === PREVIEW) {
            return (
                <iframe
                    className={this.styles.preview}
                    frameBorder="0"
                    src={`https://view.officeapps.live.com/op/embed.aspx?src=${formatS3SignedURLForIframePreview(
                        reportUrl
                    )}`}
                    title="Report Preview Embed"
                />
            );
        }

        if (formatType === PDF) {
            const isMultiEmails = multiEmailReportTypes.includes(reportType);
            return (
                <div className={this.styles.downloadBody}>
                    {isReportEmailed ? (
                        <>
                            <i className="fa fa-check-circle fa-5x text-success" />
                            <h4 className={this.styles.confirmationHeader}>
                                Creating your document{isMultiEmails ? 's' : ''} now!
                            </h4>
                            <p>
                                We&apos;ll send {isMultiEmails ? 'multiple emails' : 'an email'} to{' '}
                                <strong>{user.email}</strong> once{' '}
                                {isMultiEmails ? 'they are' : "it's"} ready.
                            </p>
                        </>
                    ) : (
                        <a download={reportFilename} href={reportUrl}>
                            <i
                                className={classnames(
                                    'fa fa-file-pdf-o fa-5x',
                                    this.styles.iconLink
                                )}
                            />
                            <div className={this.styles.downloadReportText}>
                                Click To Download PDF Document
                            </div>
                        </a>
                    )}
                </div>
            );
        }

        if (formatType === DOCX) {
            return (
                <div className={this.styles.downloadBody}>
                    <a download={reportFilename} href={reportUrl}>
                        <i className="fa fa-file-word-o fa-5x" />
                        <div className={this.styles.downloadReportText}>
                            Click To Download Word Document
                        </div>
                    </a>
                </div>
            );
        }

        return null;
    }
}

export const ReportDisplay = connect(mapStateToProps, mapDispatchToProps)(ConnectedReportDisplay);

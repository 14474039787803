import { createSelector } from 'reselect';

import { getTotalWeight } from '@og-pro/shared-config/evaluations';

const getScoringCriteria = (state, props) => props.scoringCriteria;

export const getScoringCriteriaTotalWeight = createSelector(
    [getScoringCriteria],
    (scoringCriteria) => {
        if (scoringCriteria) {
            return getTotalWeight(scoringCriteria);
        }
    }
);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

/**
 * This component is needed because react-bootstrap wraps `Nav` children with
 * `active`, `activeKey`, and `activeHref` props that are unknown to li
 * elements and throw warnings.
 */
export class NavbarButton extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        children: PropTypes.node.isRequired,
    };

    render() {
        const { children, className } = this.props;
        return <li className={className}>{children}</li>;
    }
}

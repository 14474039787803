import { List, fromJS } from 'immutable';

import * as sectionDescActions from '../../actions/govProjects/sectionDescriptions';

const initialState = new List();

function sectionDescItemReducer(state = new Map(), action) {
    // We only care about the section description item that matches the ID
    if (state.get('id') !== action.id) return state;

    switch (action.type) {
        case sectionDescActions.UPDATE:
            return state.merge(
                fromJS({
                    updating: true,
                    updateError: null,
                })
            );
        case sectionDescActions.UPDATE_SUCCESS:
            return fromJS(action.result);
        case sectionDescActions.UPDATE_FAIL:
            return state.merge(
                fromJS({
                    updating: false,
                    updateError: action.error && action.error.message,
                })
            );
        case sectionDescActions.DELETE:
            return state.merge(
                fromJS({
                    deleting: true,
                    deleteError: null,
                })
            );
        case sectionDescActions.DELETE_FAIL:
            return state.merge(
                fromJS({
                    deleting: false,
                    deleteError: action.error && action.error.message,
                })
            );
        default:
            return state;
    }
}

function isSectionDescription(sectionDesc, action) {
    return (
        sectionDesc.get('project_section_id') === action.projectSectionId &&
        sectionDesc.get('project_subsection_id') === action.projectSubsectionId
    );
}

function sectionDescAddReducer(state = new Map(), action) {
    // We only care about the section description item that matches the criteria
    if (!isSectionDescription(state, action)) return state;

    switch (action.type) {
        case sectionDescActions.ADD:
            return state.merge(
                fromJS({
                    adding: true,
                    addError: null,
                })
            );
        case sectionDescActions.ADD_SUCCESS:
            return fromJS(action.result);
        case sectionDescActions.ADD_FAIL:
            return state.merge(
                fromJS({
                    adding: false,
                    addError: action.error && action.error.message,
                })
            );
        case sectionDescActions.TOGGLE_FORM:
            return state.set('showForm', action.show);
        default:
            return state;
    }
}

export default function sectionDescReducer(state = initialState, action) {
    switch (action.type) {
        case sectionDescActions.ADD:
        case sectionDescActions.ADD_SUCCESS:
        case sectionDescActions.ADD_FAIL:
        case sectionDescActions.TOGGLE_FORM: {
            let updatedState = state;
            if (!state.find((desc) => isSectionDescription(desc, action))) {
                // If new item, push dummy data into store to use for updating props
                updatedState = state.push(
                    fromJS({
                        project_section_id: action.projectSectionId,
                        project_subsection_id: action.projectSubsectionId,
                    })
                );
            }
            return updatedState.map((desc) => sectionDescAddReducer(desc, action));
        }
        case sectionDescActions.UPDATE:
        case sectionDescActions.UPDATE_SUCCESS:
        case sectionDescActions.UPDATE_FAIL:
        case sectionDescActions.DELETE:
        case sectionDescActions.DELETE_FAIL:
            return state.map((desc) => sectionDescItemReducer(desc, action));
        case sectionDescActions.DELETE_SUCCESS:
            return state.filter((desc) => desc.get('id') !== action.id);
        default:
            return state;
    }
}

import PropTypes from 'prop-types';
import React from 'react';
import { statusTypes } from '@og-pro/shared-config/requisitions';

import { AddButton } from './AddButton';

export const RequisitionsDisplayBody = ({
    disabled,
    error,
    isEditor,
    loading,
    requisitions,
    setShowForm,
}) => {
    const addButtonProps = {
        disabled,
        onClick: () => setShowForm(true),
    };

    if (loading) {
        return (
            <span>
                <i className="fa fa-spin fa-spinner" /> Loading...
            </span>
        );
    }

    if (error) {
        return (
            <span>
                <i className="text-danger fa fa-exclamation-triangle" /> Error loading requisitions:{' '}
                {error}
            </span>
        );
    }

    if (requisitions.length === 0) {
        return (
            <div>{isEditor ? <AddButton {...addButtonProps} text="Add Request" /> : 'None'}</div>
        );
    }

    return requisitions
        .map((requisition) => (
            <div key={requisition.id}>
                <a
                    href={`/governments/${requisition.government_id}/requisitions/${requisition.id}${requisition.status === statusTypes.DRAFT ? '/edit' : ''}`}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {requisition.identifier} - {requisition.descriptionOfRequest || 'Untitled'}{' '}
                    <i className="fa fa-external-link" />
                </a>
            </div>
        ))
        .concat(
            isEditor ? (
                <div key="button">
                    <AddButton {...addButtonProps} key="button" text="Add Another" />
                </div>
            ) : (
                []
            )
        );
};

RequisitionsDisplayBody.propTypes = {
    disabled: PropTypes.bool,
    error: PropTypes.string,
    isEditor: PropTypes.bool,
    loading: PropTypes.bool,
    requisitions: PropTypes.arrayOf(
        PropTypes.shape({
            descriptionOfRequest: PropTypes.string,
            government_id: PropTypes.number.isRequired,
            id: PropTypes.number.isRequired,
            identifier: PropTypes.string.isRequired,
        })
    ).isRequired,
    setShowForm: PropTypes.func.isRequired,
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Outlet } from 'react-router-dom';

import { getProposalJS } from '../selectors';
import connectData from '../../ConnectData';
import { govLoadProposal, shouldLoadProposal } from '../../../actions/govProposals';
import { LoadingSpinner, LoadingError } from '../../../components';

function fetchData(getState, dispatch, location, params) {
    const projectId = Number.parseInt(params.projectId, 10);
    const proposalId = Number.parseInt(params.proposalId, 10);

    if (!shouldLoadProposal(getState(), proposalId)) return Promise.resolve();
    return dispatch(govLoadProposal(projectId, proposalId));
}

const mapStateToProps = (state) => {
    return {
        loadError: state.govProposals.get('loadError'),
        loading: state.govProposals.get('loading'),
        proposal: getProposalJS(state),
    };
};

// @connectData
// @connect
class ConnectedProposalReviewNav extends Component {
    static propTypes = {
        loadError: PropTypes.string,
        loading: PropTypes.bool.isRequired,
        proposal: PropTypes.object,
    };

    render() {
        const { loadError, loading, proposal } = this.props;

        if (loading) {
            return <LoadingSpinner />;
        }

        if (loadError || !proposal) {
            return <LoadingError error={loadError} />;
        }

        return <Outlet />;
    }
}

export const ProposalReviewNav = compose(
    connectData(fetchData),
    connect(mapStateToProps)
)(ConnectedProposalReviewNav);

export const numberParser = (params, field = 'newValue') => {
    const value = params[field];

    if (value === null || value === undefined || value === '') {
        return null;
    }

    // Replace commas in string values
    const numericString = typeof value === 'string' ? value.replace(',', '') : value;
    const numericValue = Number.parseFloat(numericString);
    if (Number.isNaN(Number.parseFloat(numericValue))) {
        return null;
    }

    return numericValue;
};

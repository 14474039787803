import React, { Component } from 'react';
import { connect } from 'react-redux';

import { EvaluationCriteria } from './EvaluationCriteria';
import { getEvaluationPhases } from '../selectors';
import { showConfirmationSimpleModal } from '../../../../actions/confirmation';

const mapStateToProps = (state) => {
    return {
        evaluationPhases: getEvaluationPhases(state),
    };
};

const mapDispatchToProps = {
    showConfirmationModal: showConfirmationSimpleModal,
};

/**
 * The multi phase version of evaluation criteria. Used for generating San Jose's RFPs
 */

// @connect
class ConnectedEvaluationPhase extends Component {
    render() {
        return <EvaluationCriteria {...this.props} isMulti />;
    }
}

export const EvaluationPhase = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedEvaluationPhase);

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Modal, Button } from 'react-bootstrap';

import { modalTitles, scopeExamples } from './constants';
import { InputBasic } from '../../../../../components';

const PRODUCT_INPUT_STYLE = { height: '100px' };

export class ScopeHelp extends PureComponent {
    static propTypes = {
        hide: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = { currentPage: 0 };
    }

    get styles() {
        return require('./index.scss');
    }

    changePage = (changeAmount) => {
        this.setState((prevState) => ({ currentPage: prevState.currentPage + changeAmount }));
    };

    decrement = () => this.changePage(-1);

    increment = () => this.changePage(1);

    renderExamples() {
        return (
            <div>
                <p>
                    The following are a list of example scope and deliverables for a project. When
                    you&#39;re done reading the examples, click Finish to start writing your own.
                </p>
                {scopeExamples.map((example, index) => (
                    <React.Fragment key={index}>
                        <InputBasic
                            aria-label="Example Title"
                            disabled
                            type="text"
                            value={example.title}
                        />
                        <InputBasic
                            aria-label="Example Input"
                            className={this.styles.exampleInput}
                            disabled
                            type="textarea"
                            value={example.description}
                        />
                    </React.Fragment>
                ))}
            </div>
        );
    }

    renderQuestions() {
        return (
            <div>
                <div>
                    <p className={this.styles.heading}>
                        Questions to consider if you are listing technical requirements:
                    </p>
                </div>
                <ul>
                    <li>What capabilities should this tool offer?</li>
                    <li>What is absolutely necessary? What would be nice to have?</li>
                    <li>What back­end integrations will be necessary?</li>
                    <li>
                        Do we have a preference between an on­-premise vs. externally hosted
                        solution?
                    </li>
                    <li>
                        What type(s) of reporting, analytics, and/or access to raw data do we need?
                    </li>
                    <li>
                        What is the project timeline? Are there any hard deadlines? Do we plan to do
                        a pilot, incorporate user testing, and/or have multiple releases of the
                        solution?
                    </li>
                </ul>
            </div>
        );
    }

    renderProduct() {
        return (
            <div>
                <p>
                    Before we jump into examples, let&#39;s visit how you will use this tool once
                    you are out of this instruction tutorial.
                </p>
                <p>
                    We will ask you to write multiple goals and deliverables for the project. Each
                    goal has a title field and a description field which contains the content of the
                    goal.
                </p>
                <InputBasic aria-label="Example Title" disabled type="text" value="I am a title" />
                <InputBasic
                    aria-label="Example Deliverable or Goal"
                    disabled
                    style={PRODUCT_INPUT_STYLE}
                    type="textarea"
                    value="I am a Deliverable or Goal"
                />
                <p>
                    The above is just an example. Continue on to understand how to write your
                    deliverable or goal.
                </p>
            </div>
        );
    }

    renderFirstGuide() {
        return (
            <div>
                <ul>
                    <li className={this.styles.guideList}>
                        A completed document should have multiple goals and deliverables. Each goal
                        has its own title and description.
                    </li>
                    <li className={this.styles.guideList}>
                        These goals and deliverables are the core of the document. The level of
                        detail is completely at your choice, since each project has its own unique
                        character.
                    </li>
                    <li className={this.styles.guideList}>
                        The challenge in wording the goals and deliverables is to capture the City’s
                        business needs without getting bogged down in a laundry list of
                        requirements.
                    </li>
                    <li className={this.styles.guideList}>
                        Work with all relevant stakeholders (various City departments, end users,
                        etc.) to define the business/technical requirements. Then take a step back
                        and organize these specific requirements to line up with the project goals.
                        For example:
                        <ul>
                            <li>Easy to use</li>
                            <li>Serves the diversity of City’s workforce</li>
                            <li>Promotes active management</li>
                            <li>Seamlessly interfaces with City systems</li>
                            <li>Provides reliable and secure operation</li>
                        </ul>
                    </li>
                    <li className={this.styles.guideList}>
                        You may list one or several goals/deliverables and ask Vendors to bid on all
                        or some of them. The City can then choose to purchase all, some, or none of
                        those items.
                    </li>
                    <li className={this.styles.guideList}>
                        Be concise but detailed. Describe the specifics of all deliverables – e.g.,
                        requirements for services, background on departments and staff involved,
                        quality measures, etc.
                    </li>
                    <li className={this.styles.guideList}>
                        Specific technical information­­, an overview of your current technical
                        environment, a list of back­end systems, and/or a list of key definitions­
                        can be included later as an attachment.
                    </li>
                    <li className={this.styles.guideList}>
                        If working with a vendor on scope of work language, be sure to remove any
                        marketing speak as this document will become part of the future contract.
                    </li>
                </ul>
            </div>
        );
    }

    renderIntro() {
        return (
            <div>
                <p>
                    In the Scope of Work section you will list the vendor&#39;s deliverables for
                    your project.
                </p>
                <p>
                    You can also use the file uploader at the bottom of the page to add detailed
                    requirements as an attachment (if needed). Be sure to reference this attachment
                    in your Scope of Work if you choose to include.
                </p>
                <p className="text-center text-primary">
                    <i className="fa fa-star-o" />
                    &nbsp;
                    <i className="fa fa-star-o" />
                    &nbsp;
                    <i className="fa fa-star-o" />
                </p>
                <p>
                    For tips and suggestions continue reading. You can also click the &nbsp;
                    <i className={`${this.styles.questionIcon} fa fa-question-circle`} />
                    &nbsp; icon to reopen these instructions.
                </p>
            </div>
        );
    }

    renderContent() {
        const { currentPage } = this.state;

        switch (currentPage) {
            case 0:
                return this.renderIntro();
            case 1:
                return this.renderProduct();
            case 2:
                return this.renderFirstGuide();
            case 3:
                return this.renderQuestions();
            case 4:
                return this.renderExamples();
            default:
                return this.renderExamples();
        }
    }

    renderNextButton() {
        const { hide } = this.props;
        const { currentPage } = this.state;

        if (currentPage === 4) {
            return (
                <Button bsStyle="success" onClick={hide}>
                    Finish
                </Button>
            );
        }

        return (
            <Button bsStyle="success" onClick={this.increment}>
                Next <i className="fa fa-angle-double-right" />
            </Button>
        );
    }

    render() {
        const { hide } = this.props;
        const { currentPage } = this.state;

        const title = modalTitles[currentPage];

        return (
            <>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center" id="contained-modal-title-sm">
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={this.styles.modalBody}>{this.renderContent()}</Modal.Body>
                <Modal.Footer>
                    <div className="text-center">
                        {currentPage > 0 && (
                            <Button onClick={this.decrement}>
                                <i className="fa fa-angle-double-left" /> Back
                            </Button>
                        )}
                        {this.renderNextButton()}
                    </div>
                    <div className="skipInstructions">
                        <a href="#" onClick={hide}>
                            Skip Instructions
                        </a>
                    </div>
                </Modal.Footer>
            </>
        );
    }
}

import { listToDict } from '../../../../../utils';

export const form = 'publicGovProjectsFilters';

export const fields = [
    'categories',
    'title',
    'departmentId',
    'projectId',
    'quickSearchQuery',
    'searchPriceItems',
    'status',
];

export const fieldNames = listToDict(fields);

import { createSelector } from 'reselect';

import { contractModule, docBuilderModule, intakeModule, solicitationModules } from './helper';
import {
    getBidPosterAuthorized,
    getContractModulePath,
    getProjectJS,
    hasContractingSubscription,
    isDocumentEditable,
    isEvaluator as isUserEvaluator,
    isGlobalEditorForProject,
    isContractAdminForProject,
} from '../selectors';
import { getUserJS, isOpenGovAdmin, isProjectCreatorUser } from '../../selectors';

export const getProjectContracts = createSelector(
    [(state) => state.govProjects.get('projectContracts')],
    (rawContracts) => {
        if (rawContracts) {
            return rawContracts.toJS();
        }
        return [];
    }
);

export const getProjectDocBuilders = createSelector(
    [(state) => state.govProjects.get('projectDocBuilders')],
    (rawDocBuilders) => {
        if (rawDocBuilders) {
            return rawDocBuilders.toJS();
        }
        return [];
    }
);

export const getIntakeModuleData = createSelector(
    [getProjectJS, getUserJS, isGlobalEditorForProject],
    (project, user, isGlobalEditor) => {
        if (!project || !user) {
            return {};
        }

        return intakeModule({
            isGlobalEditor,
            project,
            user,
        });
    }
);

export const getSolicitationModuleData = createSelector(
    [
        getProjectJS,
        getUserJS,
        isUserEvaluator,
        isGlobalEditorForProject,
        getBidPosterAuthorized,
        isOpenGovAdmin,
        isProjectCreatorUser,
        isDocumentEditable,
    ],
    (
        project,
        user,
        isEvaluator,
        isGlobalEditor,
        isBidPoster,
        isOpenGovAdminUser,
        isProjectCreator,
        isEditableStatus
    ) => {
        if (!project || !user) {
            return {};
        }

        return solicitationModules({
            isBidPoster,
            isEditableStatus,
            isEvaluator,
            isGlobalEditor,
            isOpenGovAdminUser,
            isProjectCreator,
            project,
            user,
        });
    }
);

export const getContractModuleData = createSelector(
    [
        getProjectJS,
        getProjectContracts,
        hasContractingSubscription,
        getContractModulePath,
        isContractAdminForProject,
    ],
    (project, contracts, hasContracting, contractPath, isContractAdmin) => {
        if (!project) {
            return {};
        }

        return contractModule({
            contractPath,
            contracts,
            hasContracting,
            isContractAdmin,
            project,
        });
    }
);

export const getDocBuilderModuleData = createSelector(
    [getProjectJS, getUserJS, isGlobalEditorForProject, isDocumentEditable],
    (project, user, isGlobalEditor, isEditableStatus) => {
        if (project && project.isDocBuilder) {
            return docBuilderModule({
                isEditableStatus,
                isGlobalEditor,
                project,
                user,
            });
        }
    }
);

import { browserHistory } from '@og-pro-migration-tools/react-router';

import { showNotification, showSnackbar } from '../notification';
import { localStoragePolyfill } from '../../helpers';

export const ACTIVATE = 'account/activate/ACTIVATE';
export const ACTIVATE_SUCCESS = 'account/activate/ACTIVATE_SUCCESS';
export const ACTIVATE_FAIL = 'account/activate/ACTIVATE_FAIL';

export const NEXT_PAGE = 'account/activate/NEXT_PAGE';
export const PREV_PAGE = 'account/activate/PREV_PAGE';

export function activate() {
    return (dispatch, getState, client) => {
        dispatch({ type: ACTIVATE });

        return client
            .post('/user/me/activate', {})
            .then((result) => {
                dispatch({ type: ACTIVATE_SUCCESS, result });

                // Send vendors to the last page they were on prior to signing up
                let nextPath;
                if (result.isVendor && localStoragePolyfill.getItem('afterAuthReferrer')) {
                    nextPath = localStoragePolyfill.getItem('afterAuthReferrer');
                } else {
                    nextPath = '/';
                }

                browserHistory.push(nextPath);
                dispatch(
                    showSnackbar('Your account has been activated!', {
                        dismissAfter: 3000,
                    })
                );
            })
            .catch((error) => {
                const defaultMessage =
                    'uh-oh, something happened! Have you checked your connection?';
                const errorMessage = error.message || defaultMessage;

                dispatch({ type: ACTIVATE_FAIL, error });
                dispatch(showNotification(errorMessage, { type: 'danger' }));
            });
    };
}

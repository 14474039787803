import classnames from 'classnames';
import { get, round } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { ProgressBar } from '..';
import { getProgressBarBsStyle } from '../../helpers';

export const ChecklistProgressBar = ({ className, labelClassName, questionnaires, textOnly }) => {
    const questionnairesToInclude = questionnaires.filter(
        (questionnaire) => !questionnaire.isTitle
    );
    const totalQuestions = questionnairesToInclude.length || 1;
    const completeQuestions = questionnairesToInclude.reduce((sum, questionnaire) => {
        if (get(questionnaire, 'questionnaireReview.isComplete')) {
            return sum + 1;
        }
        return sum;
    }, 0);
    const percentComplete = round((completeQuestions / totalQuestions) * 100, 1);
    const progressLabel = percentComplete !== 0 ? `${percentComplete}%` : null;
    const styles = require('./index.scss');

    return (
        <>
            {!textOnly && (
                <ProgressBar
                    bsStyle={getProgressBarBsStyle(percentComplete)}
                    className={classnames(styles.progressBar, className)}
                    label={
                        percentComplete === 100 ? (
                            <span>
                                <i className="fa fa-check-circle" /> Complete!
                            </span>
                        ) : (
                            progressLabel
                        )
                    }
                    max={totalQuestions}
                    min={0}
                    now={completeQuestions}
                />
            )}
            <div className={classnames('text-center', labelClassName)}>
                {completeQuestions} of {questionnairesToInclude.length} items completed
            </div>
        </>
    );
};

ChecklistProgressBar.propTypes = {
    className: PropTypes.string,
    labelClassName: PropTypes.string,
    questionnaires: PropTypes.arrayOf(
        PropTypes.shape({
            isTitle: PropTypes.bool,
            questionnaireReview: PropTypes.shape({
                isComplete: PropTypes.bool.isRequired,
            }),
        })
    ).isRequired,
    textOnly: PropTypes.bool,
};

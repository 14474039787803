import classnames from 'classnames';
import React from 'react';
import { Panel } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useParams } from 'react-router-dom';

import { getNavItems, getSelectedRoute } from './selectors';
import { Main, PageTitle, UnderlineNav } from '../../../../components';

export const EmergencyPortalNav = () => {
    const location = useLocation();
    const params = useParams();
    const navItems = useSelector((state) => getNavItems(state, { location, params }));
    const selectedRoute = useSelector((state) => getSelectedRoute(state, { location, params }));

    const styles = require('./index.scss');

    return (
        <Main>
            <Panel>
                <Panel.Body>
                    <PageTitle title="Emergency Portal" />
                    <h1 className={styles.title}>
                        <i className="fa fa-plus-square text-danger" /> Emergency Portal
                    </h1>
                    <UnderlineNav className={styles.nav}>
                        {navItems.map(({ name, route }) => (
                            <UnderlineNav.NavItem
                                className={classnames({ active: route === selectedRoute })}
                                key={route}
                                overrideIsActive={false}
                                to={route}
                            >
                                {name}
                            </UnderlineNav.NavItem>
                        ))}
                    </UnderlineNav>
                    <Outlet />
                </Panel.Body>
            </Panel>
            <p className={classnames(styles.auntLynne, 'text-muted')}>
                <small>
                    The OpenGov Emergency Portal is dedicated to Lynne Rumery, an exceptional nurse,
                    mother, daughter and sister who devoted her life to helping others.
                </small>
            </p>
        </Main>
    );
};

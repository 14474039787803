import {
    contractCostFilterTypesDict,
    contractDateFilterTypesDict,
    contractFilterTypesDict,
    contractStatusesDict,
    durationTextMap,
    durationTypesDict,
} from '@og-pro/shared-config/contracts';

import { listToDict } from '../../../utils';

const { ABOVE, BELOW } = contractCostFilterTypesDict;

const { AFTER, BEFORE, BETWEEN } = contractDateFilterTypesDict;

const {
    BUDGET_AMOUNT,
    CONTRACT_ID,
    COOPERATIVE,
    DEPARTMENT,
    END_DATE,
    PIGGYBACK,
    PROCUREMENT_CONTACT,
    PUBLIC,
    REBID,
    RETENTION_CODE_ID,
    START_DATE,
    STATUS,
    TAG,
    TERM_TYPE,
    TITLE,
    VENDOR_NAME,
} = contractFilterTypesDict;

const { ACTIVE, ENDED, UPCOMING } = contractStatusesDict;

const { COMPLETION, ONGOING, TERM_BASED } = durationTypesDict;

export const costFilterOptions = [
    {
        label: 'Above',
        value: ABOVE,
    },
    {
        label: 'Below',
        value: BELOW,
    },
];

export const dateFilterOptions = [
    {
        label: 'After',
        value: AFTER,
    },
    {
        label: 'Before',
        value: BEFORE,
    },
    {
        label: 'Between',
        value: BETWEEN,
    },
];

export const termTypeOptions = [
    {
        label: durationTextMap[TERM_BASED],
        value: TERM_BASED,
    },
    {
        label: durationTextMap[COMPLETION],
        value: COMPLETION,
    },
    {
        label: durationTextMap[ONGOING],
        value: ONGOING,
    },
];

export const form = 'contractListFilters';

export const fields = ['filters'];

export const fieldNames = listToDict(fields);

export const trueFalseFilterOptions = [
    {
        label: 'True',
        value: 'true',
    },
    {
        label: 'False',
        value: 'false',
    },
];

export const typeOptions = [
    {
        label: 'Contract ID',
        value: CONTRACT_ID,
    },
    {
        label: 'Contract Amount',
        value: BUDGET_AMOUNT,
    },
    {
        label: 'Department Name',
        value: DEPARTMENT,
    },
    {
        label: 'End Date',
        value: END_DATE,
    },
    {
        label: 'Has Piggyback Language',
        value: PIGGYBACK,
    },
    {
        label: 'Contracts Using Cooperative',
        value: COOPERATIVE,
    },
    {
        label: 'Is Public',
        value: PUBLIC,
    },
    {
        label: 'Procurement Contact',
        value: PROCUREMENT_CONTACT,
    },
    {
        label: 'Re-bid',
        value: REBID,
    },
    {
        label: 'Retention Policy',
        value: RETENTION_CODE_ID,
    },
    {
        label: 'Start Date',
        value: START_DATE,
    },
    {
        label: 'Status',
        value: STATUS,
    },
    {
        label: 'Tag',
        value: TAG,
    },
    {
        label: 'Term Type',
        value: TERM_TYPE,
    },
    {
        label: 'Title',
        value: TITLE,
    },
    {
        label: 'Vendor Name',
        value: VENDOR_NAME,
    },
];

export const statusFilterOptions = [
    {
        label: 'Active',
        value: ACTIVE,
    },
    {
        label: 'Ended',
        value: ENDED,
    },
    {
        label: 'Upcoming',
        value: UPCOMING,
    },
];

export const dateFilterFields = [START_DATE, END_DATE];

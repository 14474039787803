export const AUTO_HEIGHT_ROW_THRESHOLD = 30;

export const CONTAINER_STYLE = {
    height: 'calc(100vh - 200px)',
    width: '100%',
};

export const PANEL_BODY_STYLES = {
    padding: 0,
};

// We only want local overrides which is why this is done here instead of via bsStyle/className
// TODO: Would love to get custom-styles working: https://react-bootstrap.github.io/utilities/custom-styles/
export const PANEL_STYLES = {
    border: 'none',
    boxShadow: 'none',
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export class NoItems extends PureComponent {
    static propTypes = {
        header: PropTypes.string.isRequired,
        subheader: PropTypes.string.isRequired,
        marginTop: PropTypes.number,
    };

    static defaultProps = {
        marginTop: 60,
    };

    render() {
        const styles = require('./NoItems.scss');
        return (
            <div
                className={`text-muted ${styles.container}`}
                style={{ marginTop: this.props.marginTop }}
            >
                <div className={styles.header}>{this.props.header}</div>
                <div>{this.props.subheader}</div>
            </div>
        );
    }
}

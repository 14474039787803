import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TabsNavigation } from '@og-pro/ui';

export const NavItems = ({ items, requisitionsAdminPath }) => {
    const navigate = useNavigate();

    const tabs = items.map(({ isActive, path, title }) => {
        return {
            label: title,
            route: `${requisitionsAdminPath}${path}`,
            qaTag: `requisitionAdminNav-${title}`,
            isActive,
        };
    });

    const activeTab = tabs.find(({ isActive }) => isActive) || tabs[0];

    return <TabsNavigation defaultTab={activeTab} redirectFn={navigate} tabs={tabs} />;
};

NavItems.propTypes = {
    items: PropTypes.array.isRequired,
    requisitionsAdminPath: PropTypes.string.isRequired,
};

import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { CreatedDate } from './CreatedDate';
import { PurchaseOrder } from './PurchaseOrder';
import { getIsRequisitionUsingFMS } from '../../../../../selectors/govApp';
import { getPurchaseOrderUrls } from '../../helpers';

export const PurchaseOrderPanelBody = ({ requisition: { closedAt, purchaseOrderObject } }) => {
    const hasFMS = useSelector(getIsRequisitionUsingFMS);
    const styles = require('./shared.scss');

    if (!hasFMS || !purchaseOrderObject?.length) {
        return (
            <>
                <div className={styles.successMessage}>
                    <div>
                        <i className="fa fa-lg fa-check-circle" /> Request Approved!
                    </div>
                </div>
                <CreatedDate date={closedAt} label="Date Approved" />
            </>
        );
    }

    const purchaseOrders = getPurchaseOrderUrls(purchaseOrderObject);

    const createdAt = purchaseOrderObject[0].date_created || '';

    return (
        <>
            {purchaseOrders.map((purchaseOrder, index) => (
                <PurchaseOrder
                    indexNumber={index + 1}
                    key={purchaseOrder.number}
                    purchaseOrder={purchaseOrder}
                />
            ))}
            <CreatedDate date={createdAt} label="Date PO Created" />
        </>
    );
};

PurchaseOrderPanelBody.propTypes = {
    requisition: PropTypes.shape({
        closedAt: PropTypes.string.isRequired,
        purchaseOrderObject: PropTypes.arrayOf(
            PropTypes.shape({
                po_number: PropTypes.number.isRequired,
                date_created: PropTypes.string.isRequired,
            })
        ),
    }).isRequired,
};

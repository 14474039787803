import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Box } from '@og-pro/ui';

import { Button, SearchSelect } from '../..';
import { getDepartmentsSelectOptions } from '../../../containers/selectors';

const DEPARTMENT_IDS = 'departmentIds';

const ConnectedDashboardDepartmentSelectForm = ({ handleSubmit }) => {
    const departmentOptions = useSelector(getDepartmentsSelectOptions);
    const styles = require('./index.scss');

    return (
        <form onSubmit={handleSubmit}>
            <Box alignItems="center" display="flex" marginBottom={2}>
                <label>View by Department</label>&nbsp;&nbsp;
                <Field
                    component={SearchSelect}
                    formClassName={styles.selectInput}
                    isClearable
                    isMulti
                    isMultiSimpleValue
                    name={DEPARTMENT_IDS}
                    options={departmentOptions}
                    placeholder="Select a Department or leave blank for all"
                    qaTag={`needsAttentionDashboard-${DEPARTMENT_IDS}`}
                />
                &nbsp;
                <Button type="submit">
                    <i className="fa fa-filter" /> Filter
                </Button>
            </Box>
        </form>
    );
};

ConnectedDashboardDepartmentSelectForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
};

export const DashboardDepartmentSelectForm = reduxForm()(ConnectedDashboardDepartmentSelectForm);

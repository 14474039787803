const { isPoCreated, isRequisitionDraft } = require('.');

exports.getExceptionStatus = (requisition) => {
    if (requisition.wasExceptionRejected) {
        return {
            color: 'semanticColors.foreground.errorSmall',
            text: 'Rejected',
        };
    }
    if (isRequisitionDraft(requisition.status)) {
        return {
            color: 'semanticColors.foreground.secondary',
            text: 'Draft',
        };
    }
    if (isPoCreated(requisition.status)) {
        return {
            color: 'semanticColors.foreground.successSmall',
            text: 'Approved',
        };
    }
    return {
        color: 'semanticColors.foreground.secondary',
        text: 'In Progress',
    };
};

import { buildQaTag, componentNames } from '@og-pro/ui';
import { tokens } from '@opengov/capital-style';
import classnames from 'classnames';
import React, { PureComponent } from 'react';
import Select from 'react-select';

import { CustomOption } from './CustomOption';
import { HelpBlock, Label } from '..';
import { InviteUserHelp } from '../connected';
import { getCustomStyles, getGroupStateHelpText, getValue, onChangeGetData } from './helpers';
import { SearchSelectPropTypes } from './types';

export class SearchSelect extends PureComponent {
    static propTypes = SearchSelectPropTypes;

    static defaultProps = {
        commentData: {},
        isMulti: false,
        rawValue: false,
        useOpenGovStyle: false,
    };

    get styles() {
        if (this.props.useOpenGovStyle) {
            return require('./SearchSelectOpenGovStyle.scss');
        }

        return require('./SearchSelect.scss');
    }

    onChange = (option) => {
        const {
            input: { onChange },
            isMulti,
            isMultiSimpleValue,
            onChangeWithOption,
            rawValue,
        } = this.props;

        const data = onChangeGetData({ option, isMulti, isMultiSimpleValue, rawValue });

        // Used to get the original options object
        if (onChangeWithOption) {
            onChangeWithOption(option);
        }

        onChange(data);
    };

    onBlur = () => {
        const {
            beforeClearOnBlur,
            clearOnBlur,
            input: { onBlur, value },
            isMulti,
        } = this.props;

        // The standard behavior is to return the current value on blur
        if (!clearOnBlur) {
            // Cannot pass value to `onBlur` due to issues on mobile
            // https://github.com/JedWatson/react-select/issues/2692#issuecomment-395743446
            // `onBlur` still need to be called to touch the field
            // https://github.com/JedWatson/react-select/issues/2692#issuecomment-488541149
            return onBlur();
        }

        /*
         * The following is to clear the form after making selections
         * Used by `CategorySearch` component
         */

        // Used to get the current value before clearing value
        if (beforeClearOnBlur) {
            beforeClearOnBlur(value);
        }

        // Use change handler to reset the input to empty
        const emptyValue = isMulti ? [] : null;
        this.onChange(emptyValue);

        return onBlur();
    };

    render() {
        const {
            beforeClearOnBlur, // exclude from select
            clearOnBlur, // exclude from select
            commentData,
            commentIcon,
            disabled,
            formClassName,
            help,
            hideDisabledMenuOptions,
            inlineButton,
            input,
            input: { value: inputValue },
            isMultiSimpleValue,
            label,
            labelClassName,
            meta: { error, touched, warning },
            noMarginBottom,
            onChangeWithOption, // exclude from select
            options,
            overrideFeedback,
            qaTag,
            rawValue, // exclude from select
            selectClassName,
            showInviteUserHelp,
            showValidation,
            styles = {},
            useNullWhenUndefined,
            useOpenGovStyle,
            ...props
        } = this.props;

        const displayValidation = touched || showValidation;

        const { groupState, helpBlockText } = getGroupStateHelpText(
            {
                styles: this.styles,
                help,
                overrideFeedback,
                displayValidation,
                error,
                warning,
            },
            useOpenGovStyle
        );

        // Adopted from https://github.com/JedWatson/react-select/issues/1322#issuecomment-436615179
        const customStyles = getCustomStyles({
            ...styles,
            ...(useOpenGovStyle
                ? {
                      control: {
                          border: '1px solid transparent',
                          minHeight: '37px',
                          ...styles.control,
                      },
                      controlFocus: {
                          border: `1px solid ${tokens.colors.colorPrimarySelection}`,
                          boxShadow: `0 0 0 1px ${tokens.colors.colorPrimarySelection}`,
                      },
                      dropdownIndicator: {
                          color: tokens.colors.colorGray700,
                          ...styles.dropdownIndicator,
                      },
                      option: {
                          cursor: 'pointer',
                          color: tokens.colors.colorGray1000,
                          fontSize: tokens.typography.fontSizeSmall,
                      },
                      optionHover: { backgroundColor: tokens.colors.colorPrimaryHover },
                      optionSelected: {
                          backgroundColor: tokens.colors.colorPrimarySelection,
                          color: tokens.colors.colorWhite,
                      },
                      placeholder: {
                          color: tokens.colors.colorGray700,
                      },
                  }
                : {}),
        });

        const value = getValue({
            inputValue,
            isMulti: props.isMulti,
            options,
            isMultiSimpleValue,
            useNullWhenUndefined,
        });

        const selectProps = {
            ...input,
            className: classnames(
                this.styles.selectBlock,
                hideDisabledMenuOptions && this.styles.hideDisabledMenuOptions,
                selectClassName
            ),
            classNamePrefix: 'react-select',
            isDisabled: disabled,
            onChange: this.onChange,
            onBlur: this.onBlur,
            styles: customStyles,
            value,
            ...props,
        };

        const selectId = input.name ? `searchSelect${input.name}` : 'searchSelect';

        const tagWithComponentName = buildQaTag(qaTag, componentNames.SELECT);

        return (
            <div
                className={classnames(
                    'form-group',
                    groupState,
                    formClassName,
                    noMarginBottom && this.styles.noMarginBottom
                )}
                data-qa={tagWithComponentName}
            >
                <div className={this.styles.labelContainer}>
                    <Label
                        className={classnames(labelClassName, this.styles.label, {
                            [this.styles.noMarginBottom]: !!help && useOpenGovStyle,
                        })}
                        commentData={commentData}
                        commentIcon={commentIcon}
                        htmlFor={selectId}
                        label={label}
                    />
                    {helpBlockText && useOpenGovStyle && (
                        <HelpBlock
                            className={classnames(
                                this.styles.help,
                                !inlineButton && this.styles.fullWidth
                            )}
                            useOpenGovStyle={useOpenGovStyle}
                        >
                            {helpBlockText}
                        </HelpBlock>
                    )}
                    {inlineButton && useOpenGovStyle && (
                        <div className={this.styles.inlineButton}>{inlineButton}</div>
                    )}
                </div>
                <Select
                    components={{ Option: CustomOption }}
                    {...selectProps}
                    inputId={selectId}
                    options={options}
                />
                {helpBlockText && !useOpenGovStyle && <HelpBlock>{helpBlockText}</HelpBlock>}
                {showInviteUserHelp && <InviteUserHelp />}
                {error && useOpenGovStyle && displayValidation && (
                    <HelpBlock className={this.styles.errorText} useOpenGovStyle={useOpenGovStyle}>
                        <i className="fa fa-exclamation-triangle" />
                        &nbsp; {error}
                    </HelpBlock>
                )}
            </div>
        );
    }
}

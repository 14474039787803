import PropTypes from 'prop-types';
import React from 'react';

import { FlagsTableRow } from './FlagsTableRow';
import { Table } from '../../../../components';

export const FlagsTable = ({ flags, onDelete, onEdit }) => {
    return (
        <Table>
            <colgroup>
                <col style={{ width: '35%' }} />
                <col style={{ width: '35%' }} />
                <col style={{ width: '15%' }} />
                <col style={{ width: '15%', minWidth: 80 }} />
            </colgroup>
            <thead>
                <tr>
                    <th>Flag Name</th>
                    <th>Description</th>
                    <th>Status</th>
                    <th aria-label="Empty cell">&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                {flags.map((flag) => {
                    return (
                        <FlagsTableRow
                            flag={flag}
                            key={flag.id}
                            onDelete={onDelete}
                            onEdit={onEdit}
                        />
                    );
                })}
            </tbody>
        </Table>
    );
};

FlagsTable.propTypes = {
    flags: PropTypes.arrayOf(
        PropTypes.shape({
            description: PropTypes.string,
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        })
    ).isRequired,
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';

export const ArchiveScheduleErrorsField = (props) => {
    const {
        meta: { error, touched },
    } = props;

    if (!error || !touched) {
        return null;
    }

    return <div className="error-block text-left">{error}</div>;
};

ArchiveScheduleErrorsField.propTypes = {
    meta: PropTypes.shape({
        error: PropTypes.string,
        touched: PropTypes.bool,
    }).isRequired,
};

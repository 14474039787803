import { last } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { PurchaseOrderFileUpload } from './PurchaseOrderFileUpload';
import { PurchaseOrderImportComplete } from './PurchaseOrderImportComplete';
import { PurchaseOrderImportConfirm } from './PurchaseOrderImportConfirm';
import { getDepartmentsJS, getFiscalYearTagsJS, getUserJS } from '../../../selectors';
import {
    importPurchaseOrders,
    loadContracts,
    loadPurchaseOrdersImportData,
} from '../../../../actions/contracts';

export const PurchaseOrderImportModal = ({ hideModal }) => {
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const [pages, setPages] = useState([1]);
    const [state, setState] = useState({
        createPurchaseOrders: [],
        duplicatePurchaseOrders: [],
        existingPurchaseOrders: [],
        unmatchedPurchaseOrders: [],
        invalidPaidAmountPurchaseOrders: [],
    });
    const departments = useSelector(getDepartmentsJS);
    const fiscalYearTags = useSelector(getFiscalYearTagsJS);
    const user = useSelector(getUserJS);

    const styles = require('./index.scss');

    const backHandler = () => {
        return setPages((prev) => prev.slice(0, -1));
    };

    const nextHandler = () => {
        return setPages((prev) => prev.concat(last(prev) + 1));
    };

    const fileUploadHandler = (uploadResultPurchaseOrders) => {
        setState({
            createPurchaseOrders: uploadResultPurchaseOrders.create,
            duplicatePurchaseOrders: uploadResultPurchaseOrders.duplicate,
            existingPurchaseOrders: uploadResultPurchaseOrders.existing,
            unmatchedPurchaseOrders: uploadResultPurchaseOrders.unmatched,
            invalidPaidAmountPurchaseOrders: uploadResultPurchaseOrders.invalidPaidAmount,
        });
        nextHandler();
    };

    const importHandler = () => {
        const { createPurchaseOrders, existingPurchaseOrders } = state;

        const importData = {
            create: createPurchaseOrders,
            update: existingPurchaseOrders,
        };

        return dispatch(importPurchaseOrders(importData)).then(() => {
            nextHandler();

            // Load contracts in background to get updated budget data
            dispatch(
                loadContracts({
                    page: searchParams.get('page'),
                    limit: searchParams.get('limit'),
                    ...user.lastFilter?.contract,
                })
            );
        });
    };

    const renderModalBody = () => {
        const {
            createPurchaseOrders,
            duplicatePurchaseOrders,
            existingPurchaseOrders,
            unmatchedPurchaseOrders,
            invalidPaidAmountPurchaseOrders,
        } = state;

        switch (last(pages)) {
            case 2:
                return (
                    <>
                        <h5 className={styles.pageName}>Confirm Import</h5>
                        <PurchaseOrderImportConfirm
                            backHandler={backHandler}
                            createPurchaseOrders={createPurchaseOrders}
                            duplicatePurchaseOrders={duplicatePurchaseOrders}
                            existingPurchaseOrders={existingPurchaseOrders}
                            importHandler={importHandler}
                            invalidPaidAmountPurchaseOrders={invalidPaidAmountPurchaseOrders}
                            unmatchedPurchaseOrders={unmatchedPurchaseOrders}
                        />
                    </>
                );
            case 3:
                return (
                    <>
                        <h5 className={styles.pageName}>Purchase Order Import Complete!</h5>
                        <PurchaseOrderImportComplete
                            createPurchaseOrders={createPurchaseOrders}
                            duplicatePurchaseOrders={duplicatePurchaseOrders}
                            existingPurchaseOrders={existingPurchaseOrders}
                            hideModal={hideModal}
                            invalidPaidAmountPurchaseOrders={invalidPaidAmountPurchaseOrders}
                            unmatchedPurchaseOrders={unmatchedPurchaseOrders}
                        />
                    </>
                );
            default:
                return (
                    <>
                        <h5 className={styles.pageName}>Import Instructions</h5>
                        <PurchaseOrderFileUpload
                            departments={departments}
                            fiscalYearTags={fiscalYearTags}
                            loadPurchaseOrdersImportData={() =>
                                dispatch(loadPurchaseOrdersImportData())
                            }
                            onUploadComplete={fileUploadHandler}
                            user={user}
                        />
                    </>
                );
        }
    };

    return (
        <Modal bsSize="lg" onHide={hideModal} show>
            <Modal.Header closeButton>
                <Modal.Title>Import Purchase Orders</Modal.Title>
            </Modal.Header>
            <Modal.Body className={styles.modalBody}>{renderModalBody()}</Modal.Body>
        </Modal>
    );
};

PurchaseOrderImportModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
};

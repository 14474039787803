import React, { useContext } from 'react';

import { ProjectReviewChecklist, ContractReviewChecklist } from '../../components/review';
import { ProjectCreateV2FunctionsContext } from '../../context';

export const ProjectCreateV2Review = () => {
    const { showContractCompiler } = useContext(ProjectCreateV2FunctionsContext);

    return showContractCompiler ? <ContractReviewChecklist /> : <ProjectReviewChecklist />;
};

const { buildMap, listToDict } = require('../helpers');

exports.subsectionTypes = ['background', 'body', 'contact', 'summary', 'timeline'];

exports.subsectionTypeNames = listToDict(exports.subsectionTypes);

const { BACKGROUND, BODY, CONTACT, SUMMARY, TIMELINE } = exports.subsectionTypeNames;

const defaultSubsectionConfigs = [
    {
        subsection_type: BACKGROUND,
        title: 'Background',
        projectDescriptionKey: 'background',
        hasCommentDescription: true,
        orderById: 300,
    },
    {
        subsection_type: BODY,
        hasCommentDescription: false,
        orderById: 100,
    },
    {
        subsection_type: CONTACT,
        title: 'Contact Information',
        hasCommentDescription: false,
        orderById: 400,
    },
    {
        subsection_type: SUMMARY,
        title: 'Summary',
        projectDescriptionKey: 'summary',
        hasCommentDescription: true,
        orderById: 200,
    },
    {
        subsection_type: TIMELINE,
        title: 'Timeline',
        hasCommentDescription: false,
        orderById: 500,
    },
];

exports.defaultSubsectionConfigsMap = buildMap(defaultSubsectionConfigs, 'subsection_type');

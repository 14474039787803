import { get } from 'lodash';
import { createSelector } from 'reselect';

import { getEvaluationProjectJS } from '../GovApp/selectors';
import { getProjectJS as getPublicProjectJS } from '../PublicApp/selectors';
import { generateVendorAwardData } from '../../helpers/priceTable';

const getSelectedProposalsFromProject = (project) => {
    if (project) {
        return get(project, ['evaluation', 'selectedProposals']) || [];
    }
    return [];
};

export const getGovSelectedProposalsJS = createSelector(
    [getEvaluationProjectJS],
    getSelectedProposalsFromProject
);

export const getPublicSelectedProposalsJS = createSelector(
    [getPublicProjectJS],
    getSelectedProposalsFromProject
);

export const getPublicScoredProposalsJS = createSelector([getPublicProjectJS], (project) => {
    if (project && project.scoredProposals) {
        return project.scoredProposals.map((proposal) => {
            return {
                ...proposal,
                proposalEvaluation: proposal.consensusEvaluation,
            };
        });
    }
});

export const getVendorAwardData = createSelector([getPublicProjectJS], (project) => {
    if (project.lineItemAwards) {
        const { proposalsData, lineItemAwards } = project.lineItemAwards;
        return generateVendorAwardData(proposalsData, lineItemAwards);
    }
});

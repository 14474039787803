import { createSelector } from 'reselect';

import { buildMap } from '@og-pro/shared-config/helpers';

import { getAvailableTemplateSections } from '../../selectors';

export const getTemplateSectionProjectSectionsMap = createSelector(
    [getAvailableTemplateSections],
    (templateSections) => {
        return buildMap(
            templateSections.map((template) => template.projectSection),
            'sharedId'
        );
    }
);

export const getTemplateSectionProjectSectionIdsMap = createSelector(
    [getAvailableTemplateSections],
    (templateSections) => {
        return buildMap(templateSections, 'project_section_id');
    }
);

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { Box, Button as MuiButton, Dialog, Typography } from '@og-pro/ui';

import { fieldNames, form } from './constants';
import { validate } from './validate';
import { getGovernmentId } from '../../containers/GovApp/selectors';
import { Button, InputText, LoadingButton } from '..';
import { sendCustomEmail } from '../../actions/app';

const { BODY, SUBJECT } = fieldNames;

const mapStateToProps = (state) => {
    return {
        governmentId: getGovernmentId(state),
    };
};

const mapDispatchToProps = {
    sendCustomEmail,
};

const formConfig = {
    form,
    validate,
};

// @connect
// @reduxForm
class ConnectedCustomEmailModal extends Component {
    static propTypes = {
        dirty: PropTypes.bool.isRequired,
        governmentId: PropTypes.number.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        hideModal: PropTypes.func.isRequired,
        invalid: PropTypes.bool.isRequired,
        data: PropTypes.shape({
            projectId: PropTypes.number,
            searchParams: PropTypes.any,
            userEmails: PropTypes.array,
            userIds: PropTypes.array,
            vendorListId: PropTypes.number,
            vendorCount: PropTypes.number,
        }).isRequired,
        sendCustomEmail: PropTypes.func.isRequired,
        useOpenGovStyle: PropTypes.bool,
        useOpenSearch: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            emailing: false,
            emailError: null,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    hideModal = (opts = {}) => {
        const { dirty, hideModal } = this.props;

        if (
            opts.force ||
            !dirty ||
            window.confirm('You have unsaved edits, are you sure you want to exit?') // eslint-disable-line no-alert
        ) {
            hideModal();
        }
    };

    sendCustomEmail = (formData) => {
        const { governmentId, data, useOpenSearch } = this.props;

        this.setState({ emailing: true });
        this.props
            .sendCustomEmail(governmentId, {
                ...formData,
                data,
                useOpenSearch,
            })
            .then(() => {
                this.setState({ emailing: false });
                this.hideModal({ force: true });
            })
            .catch((error) => {
                this.setState({ emailing: false, emailError: error.message });
            });
    };

    renderButtons() {
        const { hideModal, invalid, useOpenGovStyle } = this.props;

        const { emailing } = this.state;

        if (useOpenGovStyle) {
            return (
                <Box columnGap={2} display="flex" justifyContent="flex-end">
                    <MuiButton color="secondary" onClick={hideModal}>
                        Cancel
                    </MuiButton>
                    <MuiButton
                        color="primary"
                        disabled={invalid || emailing}
                        type="submit"
                        variant="contained"
                    >
                        Send Email
                    </MuiButton>
                </Box>
            );
        }

        return (
            <div className="text-right">
                <Button bsSize="sm" disabled={emailing} onClick={this.hideModal}>
                    Cancel
                </Button>
                &nbsp;&nbsp;
                <LoadingButton
                    bsStyle="primary"
                    disabled={invalid || emailing}
                    icon="fa-envelope"
                    loading={emailing}
                    text="Send Email"
                    type="submit"
                />
            </div>
        );
    }

    renderBody() {
        const { handleSubmit, useOpenGovStyle } = this.props;

        const { emailError, emailing } = this.state;

        const subjectLabel = useOpenGovStyle ? (
            <Typography variant="h4">Subject (Optional)</Typography>
        ) : (
            'Subject (Optional)'
        );

        const bodyLabel = useOpenGovStyle ? <Typography variant="h4">Body</Typography> : 'Body';

        return (
            <form onSubmit={handleSubmit(this.sendCustomEmail)}>
                <Field
                    component={InputText}
                    disabled={emailing}
                    hasFeedback={false}
                    label={subjectLabel}
                    name={SUBJECT}
                    placeholder={useOpenGovStyle ? '' : 'Enter email subject'}
                    type="text"
                    useOpenGovStyle={useOpenGovStyle}
                />
                <Field
                    component={InputText}
                    disabled={emailing}
                    hasFeedback={false}
                    label={bodyLabel}
                    name={BODY}
                    placeholder={useOpenGovStyle ? '' : 'Enter email body'}
                    type="textarea"
                    useOpenGovStyle={useOpenGovStyle}
                />
                {this.renderButtons()}
                {emailError && <div className="text-center error-block">{emailError}</div>}
            </form>
        );
    }

    render() {
        const {
            data: { vendorCount, vendorListId },
            useOpenGovStyle,
        } = this.props;

        if (useOpenGovStyle) {
            return (
                <Dialog
                    dialogTitle={`Send an Email${vendorListId ? ' to Vendor List' : ''}${vendorCount !== undefined ? ` to ${vendorCount} Vendor` : ''}${vendorCount > 1 ? 's' : ''}`}
                    fullWidth
                    maxWidth="sm"
                    onClose={this.hideModal}
                    open
                >
                    {this.renderBody()}
                </Dialog>
            );
        }

        return (
            <Modal onHide={this.hideModal} show>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">Send an Email</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.renderBody()}</Modal.Body>
            </Modal>
        );
    }
}

export const CustomEmailModal = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedCustomEmailModal);

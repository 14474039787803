import { get, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, FieldArray } from 'redux-form';

import { reviewGroupPositionFieldNames, reviewGroupPositionUsersFieldNames } from './constants';
import { getRequisitionGroupFormValues } from '../selectors';
import { RequisitionGroupPositionsUsersField } from './RequisitionGroupPositionsUsersField';
import { getInvitedUsersSelectOptions } from '../../../../selectors';
import { SearchSelect, SearchSelectUserOption, Well } from '../../../../../components';

const { REVIEW_GROUP_POSITION_USERS, USER_IDS: REVIEW_GROUP_POSITION_USER_IDS } =
    reviewGroupPositionFieldNames;
const { IS_PRIMARY, USER_ID } = reviewGroupPositionUsersFieldNames;

export const RequisitionGroupPositionsField = ({
    change,
    disabled,
    fields,
    showFormValidation,
}) => {
    const reviewGroupPositionUserSelectOptions = useSelector(getInvitedUsersSelectOptions);
    const formValues = useSelector(getRequisitionGroupFormValues);
    const dispatch = useDispatch();

    const onUserSelect = (member) => (userIds) => {
        const isFirstSelection = userIds.length === 1;
        const reviewGroupPositionUsersFormValue =
            get(formValues, `${member}.${REVIEW_GROUP_POSITION_USERS}`) || [];

        // First attempt to find user in form values, then move to select options if new user
        const userOptions = [
            ...reviewGroupPositionUsersFormValue,
            ...reviewGroupPositionUserSelectOptions,
        ];
        const reviewGroupPositionUsers = userIds.map((userId) => {
            const { isPrimary, ...reviewGroupPositionUserData } = userOptions.find(
                (option) => option.user.id === userId
            );
            return {
                ...reviewGroupPositionUserData,
                [IS_PRIMARY]: isPrimary || isFirstSelection,
                [USER_ID]: reviewGroupPositionUserData.user.id,
            };
        });
        const sortedReviewGroupPositionUsers = sortBy(reviewGroupPositionUsers, 'user.lastName');
        dispatch(
            change(`${member}.${REVIEW_GROUP_POSITION_USERS}`, sortedReviewGroupPositionUsers)
        );
    };

    return fields.map((member, index) => {
        const reviewGroupPosition = fields.get(index);
        const { position } = reviewGroupPosition;
        return (
            <Well key={position.id}>
                <div className="row">
                    <div className="col-sm-6">
                        {/* `REVIEW_GROUP_POSITION_USER_IDS` field is for form input UI only and not used by API.
                            API reads all data from `REVIEW_GROUP_POSITION_USERS` array. */}
                        <Field
                            closeMenuOnSelect={false}
                            component={SearchSelect}
                            components={{
                                Option: SearchSelectUserOption,
                            }}
                            disabled={disabled}
                            help={`Assign users to the "${position.name}" position group`}
                            isMulti
                            isMultiSimpleValue
                            label={`"${position.name}" Users`}
                            name={`${member}.${REVIEW_GROUP_POSITION_USER_IDS}`}
                            onChange={onUserSelect(member)}
                            options={reviewGroupPositionUserSelectOptions}
                            placeholder="Select users for this position"
                            showValidation={showFormValidation}
                        />
                    </div>
                    <div className="col-sm-6">
                        <label>Assigned Users</label>
                        <FieldArray
                            component={RequisitionGroupPositionsUsersField}
                            disabled={disabled}
                            name={`${member}.${REVIEW_GROUP_POSITION_USERS}`}
                            rerenderOnEveryChange
                        />
                    </div>
                </div>
            </Well>
        );
    });
};

RequisitionGroupPositionsField.propTypes = {
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    fields: PropTypes.object.isRequired,
    showFormValidation: PropTypes.bool,
};

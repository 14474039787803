import { fromJS } from 'immutable';

import * as evaluationActions from '../actions/evaluations';
import * as govProjectActions from '../actions/govProjects';

const initialState = fromJS({
    loading: false,
    shouldSubmit: false,
    shouldUpdate: false,
    showEmailEditModal: false,
    showFormErrors: false,
    subscribing: false,
    updating: false,
});

export default function evaluationsReducer(state = initialState, action = {}) {
    switch (action.type) {
        case evaluationActions.INITIALIZE:
            return state.merge(
                fromJS({
                    showFormErrors: false,
                })
            );
        case evaluationActions.INITIATE_UPDATE:
            return state.set('shouldUpdate', true);
        case evaluationActions.INITIATE_SUBMIT:
            return state.set('shouldSubmit', true);
        case govProjectActions.LOAD:
            // Sets all evaluation values back to initial
            return initialState.merge(
                fromJS({
                    loading: true,
                })
            );
        case govProjectActions.LOAD_SUCCESS:
        case govProjectActions.LOAD_FAIL:
            return state.merge(
                fromJS({
                    loading: false,
                })
            );
        case evaluationActions.CREATE:
            return state.merge(
                fromJS({
                    creatingEvaluation: true,
                    createEvaluationError: null,
                })
            );
        case evaluationActions.CREATE_SUCCESS:
            return state.merge(
                fromJS({
                    creatingEvaluation: false,
                    loaded: true,
                })
            );
        case evaluationActions.CREATE_FAIL:
            return state.merge(
                fromJS({
                    createEvaluationError: action.error.message,
                    creatingEvaluation: false,
                })
            );
        case evaluationActions.HIDE_EMAIL_EDIT_MODAL:
            return state.set('showEmailEditModal', false);
        case evaluationActions.SHOW_EMAIL_EDIT_MODAL:
            return state.merge(
                fromJS({
                    sendEmailHandler: action.sendEmailHandler,
                    showEmailEditModal: true,
                })
            );
        case evaluationActions.UPDATE:
            return state.merge(
                fromJS({
                    updating: true,
                    updateError: null,
                })
            );
        case evaluationActions.UPDATE_SUCCESS:
            return state.merge(
                fromJS({
                    updating: false,
                    shouldUpdate: false,
                    shouldSubmit: false,
                })
            );
        case evaluationActions.UPDATE_FAIL:
            return state.merge(
                fromJS({
                    updating: false,
                    updateError: action.error && action.error.message,
                    shouldUpdate: false,
                    shouldSubmit: false,
                })
            );
        case evaluationActions.SHOW_FORM_VALIDATION:
            return state.set('showFormErrors', true);
        case govProjectActions.RESET:
            return initialState;
        default:
            return state;
    }
}

import { fromJS } from 'immutable';

import * as requisitionAccountDetailsActions from '../actions/requisitionAccountDetails';

const initialState = fromJS({
    accountSplitPriceItems: [],
    loadAccountDataError: null,
    loadingAccountData: false,
});

export default function requisitionAccountDetails(state = initialState, action = {}) {
    switch (action.type) {
        case requisitionAccountDetailsActions.LOAD_ACCOUNT_DATA:
            return state.merge(
                fromJS({
                    loadAccountDataError: null,
                    loadingAccountData: true,
                })
            );
        case requisitionAccountDetailsActions.LOAD_ACCOUNT_DATA_FAILURE:
            return state.merge(
                fromJS({
                    loadAccountDataError: action.error?.message,
                    loadingAccountData: false,
                })
            );
        case requisitionAccountDetailsActions.LOAD_ACCOUNT_DATA_SUCCESS:
            return state.merge(
                fromJS({
                    accountSplitPriceItems: fromJS(action.result),
                    loadingAccountData: false,
                })
            );
        default:
            return state;
    }
}

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import { hideDownloadModal } from '../../actions/publicProject';
import { LoadingSpinner, LoadingError } from '../../components';
import { getUserJS } from '../selectors';

const mapStateToProps = (state) => {
    return {
        downloading: state.publicProject.get('downloading'),
        downloadDeferred: state.publicProject.get('downloadDeferred'),
        downloadFilePath: state.publicProject.get('downloadFilePath'),
        downloadError: state.publicProject.get('downloadError'),
        user: getUserJS(state),
    };
};

const mapDispatchToProps = {
    hideModal: hideDownloadModal,
};

// @connect
class ConnectedDownloadModal extends Component {
    static propTypes = {
        downloading: PropTypes.bool,
        downloadDeferred: PropTypes.bool,
        downloadError: PropTypes.string,
        downloadFilePath: PropTypes.string,
        hideModal: PropTypes.func.isRequired,
        user: PropTypes.shape({
            email: PropTypes.string.isRequired,
        }).isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    renderLoading() {
        return (
            <div>
                <LoadingSpinner className={this.styles.spinner} />
                <div className="text-center text-muted">
                    Downloading may take a few moments to complete
                </div>
            </div>
        );
    }

    renderEmailConfirmation() {
        const { user } = this.props;

        return (
            <div className="text-center text-muted">
                <i className={`fa fa-check-circle fa-5x text-success ${this.styles.successIcon}`} />
                <h4 className={this.styles.confirmationHeading}>Getting your files ready</h4>
                <p>
                    We&apos;ll send an email to <strong>{user.email}</strong> once your download is
                    ready.
                </p>
            </div>
        );
    }

    renderModalBody() {
        const { downloading, downloadDeferred, downloadError, downloadFilePath } = this.props;

        if (downloading) return this.renderLoading();
        if (downloadError) return <LoadingError error={downloadError} />;
        if (downloadDeferred) return this.renderEmailConfirmation();
        if (!downloadFilePath) return null;

        return (
            <div className={`text-primary ${this.styles.downloadLink}`}>
                <a href={downloadFilePath}>
                    <i className="fa fa-5x fa-cloud-download" />
                    <div>Click to Download Files</div>
                </a>
            </div>
        );
    }

    render() {
        const { hideModal } = this.props;

        return (
            <Modal onHide={hideModal} show>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">Document Download</Modal.Title>
                </Modal.Header>
                <Modal.Body className={this.styles.container}>{this.renderModalBody()}</Modal.Body>
            </Modal>
        );
    }
}

export const DownloadModal = connect(mapStateToProps, mapDispatchToProps)(ConnectedDownloadModal);

import PropTypes from 'prop-types';
import React from 'react';

import { currencyFormatter } from '../../../../../../helpers';

export const PriceItemDescriptionHeader = ({ priceItem, priceTable }) => {
    const { description, quantity, unitToMeasure } = priceItem;
    const { auctionMaxFractionDigits, minBidDecrement } = priceTable;
    return (
        <>
            <h3>{description}</h3>
            <p style={{ color: '#777777' }}>
                <b>Quantity:</b> {quantity}
                {'  '}|{'  '}
                <b>Unit of Measure:</b> {unitToMeasure}
                {'  '}|{'  '}
                <b>Min Decrement:</b>{' '}
                {currencyFormatter(
                    { value: minBidDecrement },
                    {
                        maximumFractionDigits: auctionMaxFractionDigits,
                        useSameMinAndMax: true,
                    }
                )}
            </p>
        </>
    );
};

PriceItemDescriptionHeader.propTypes = {
    priceItem: PropTypes.shape({
        description: PropTypes.string.isRequired,
        quantity: PropTypes.number.isRequired,
        unitToMeasure: PropTypes.string.isRequired,
    }).isRequired,
    priceTable: PropTypes.shape({
        auctionMaxFractionDigits: PropTypes.number.isRequired,
        minBidDecrement: PropTypes.number.isRequired,
    }).isRequired,
};

import React from 'react';
import { Field } from 'redux-form';
import { Box } from '@og-pro/ui';

import { projectFormProps, fieldNames, form } from '../constants';
import { Main, RichTextInput } from '../../../../components';
import { Timeline } from '../../../../components/GovApp';
import { INTRODUCTION_VARIABLES } from '../../constants';

const { RAW_BACKGROUND, RAW_SUMMARY } = fieldNames;

export const SummaryBackgroundTimeline = (props) => {
    const {
        array,
        change,
        disabled,
        showFormErrors,
        project,
        tagOptions,
        templateVariableOptions,
        timezone,
    } = props;
    const filteredVariableOptions = templateVariableOptions
        ? templateVariableOptions.filter((opt) => opt.label !== INTRODUCTION_VARIABLES)
        : [];

    return (
        <Main className="row">
            <div className="col-xs-12">
                <Box>
                    <Field
                        component={RichTextInput}
                        disabled={disabled}
                        help="Write a summary. Keep it short and snappy. Limit it to 1 to 3 paragraphs"
                        helpIcon
                        label="Summary"
                        name={RAW_SUMMARY}
                        placeholder="Enter Text"
                        showValidation={showFormErrors}
                        tagOptions={tagOptions}
                        templateVariableOptions={filteredVariableOptions}
                        toolbarPlacement="bottom"
                    />
                </Box>
                <Box>
                    <Field
                        component={RichTextInput}
                        disabled={disabled}
                        help={
                            'Add some background info. This is optional - Just ' +
                            "leave it blank if you don't need it"
                        }
                        helpIcon
                        label="Background (optional)"
                        minRows={3}
                        name={RAW_BACKGROUND}
                        placeholder="Enter Text (optional)"
                        showValidation={showFormErrors}
                        tagOptions={tagOptions}
                        templateVariableOptions={filteredVariableOptions}
                        toolbarPlacement="bottom"
                    />
                </Box>

                {!project.isIntake && (
                    <Box>
                        <Box component="label" mr={3}>
                            Timeline
                        </Box>
                        <p>
                            Please fill in the dates below to meet your project needs. You may also
                            edit the timline configuration to add, reorder, and remove timeline
                            dates.
                        </p>
                        <Timeline
                            array={array}
                            change={change}
                            disabled={disabled}
                            form={form}
                            project={project}
                            showValidation={showFormErrors}
                            timezone={timezone}
                        />
                    </Box>
                )}
            </div>
        </Main>
    );
};

SummaryBackgroundTimeline.propTypes = projectFormProps;

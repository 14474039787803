import { fieldNames } from './constants';

const { HIDE_PUBLIC_CLOSED_PROJECTS_LENGTH, HIDE_PUBLIC_CLOSED_PROJECTS_UNIT } = fieldNames;

export const validate = (values) => {
    const errors = {};

    if (values[HIDE_PUBLIC_CLOSED_PROJECTS_LENGTH]) {
        if (!values[HIDE_PUBLIC_CLOSED_PROJECTS_UNIT]) {
            errors[HIDE_PUBLIC_CLOSED_PROJECTS_UNIT] = 'Required';
        }

        if (values[HIDE_PUBLIC_CLOSED_PROJECTS_LENGTH] < 1) {
            errors[HIDE_PUBLIC_CLOSED_PROJECTS_LENGTH] = 'Must be greater than 0';
        }
    }

    if (values[HIDE_PUBLIC_CLOSED_PROJECTS_UNIT]) {
        if (!values[HIDE_PUBLIC_CLOSED_PROJECTS_LENGTH]) {
            errors[HIDE_PUBLIC_CLOSED_PROJECTS_LENGTH] = 'Required';
        }
    }

    return errors;
};

import classnames from 'classnames';
import { capitalize } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { buildMap } from '@og-pro/shared-config/helpers';

import { getFilteredAndSortedUsersJS, getShowDisabledUsers } from './selectors';
import { UserListItem } from './UserListItem';
import { UserSearchForm } from './UserSearchForm';
import {
    getBulkUpdateAndDisable,
    getBulkUpdateOldUser,
    getDepartmentsMap,
    getUserRolesJS,
} from '../selectors';
import { getUserJS, getUsersRequestingAccessJS } from '../../selectors';
import { hasUserRolesSubscription } from '../../GovApp/selectors';
import {
    addUser,
    setUsersListSearch,
    toggleDisabledUsers,
    toggleSortUsersDirection,
} from '../../../actions/admin';
import { Button, Main, SectionTitle, Well } from '../../../components';
import { exportArrayToCSV } from '../../../utils';
import { BulkUpdateContactsModal } from './BulkUpdateContactsModal';

const mapStateToProps = (state) => {
    return {
        bulkUpdateAndDisable: getBulkUpdateAndDisable(state),
        bulkUpdateOldUser: getBulkUpdateOldUser(state),
        departmentsMap: getDepartmentsMap(state),
        hasUserRoles: hasUserRolesSubscription(state),
        showDisabledUsers: getShowDisabledUsers(state),
        sortDirection: state.admin.get('sortUsersDirection'),
        user: getUserJS(state),
        userRoles: getUserRolesJS(state),
        users: getFilteredAndSortedUsersJS(state),
        usersRequestingAccess: getUsersRequestingAccessJS(state),
    };
};

const mapDispatchToProps = {
    addUser,
    setUsersListSearch,
    toggleDisabledUsers,
    toggleSortUsersDirection,
};

// @connect
class ConnectedUserList extends Component {
    static propTypes = {
        addUser: PropTypes.func.isRequired,
        bulkUpdateAndDisable: PropTypes.bool.isRequired,
        bulkUpdateOldUser: PropTypes.number,
        departmentsMap: PropTypes.object.isRequired,
        hasUserRoles: PropTypes.bool.isRequired,
        showDisabledUsers: PropTypes.bool.isRequired,
        setUsersListSearch: PropTypes.func.isRequired,
        sortDirection: PropTypes.string.isRequired,
        toggleDisabledUsers: PropTypes.func.isRequired,
        toggleSortUsersDirection: PropTypes.func.isRequired,
        user: PropTypes.shape({
            department: PropTypes.shape({
                id: PropTypes.number.isRequired,
            }),
            id: PropTypes.number.isRequired,
            isVendor: PropTypes.bool.isRequired,
            organization: PropTypes.shape({
                name: PropTypes.string.isRequired,
            }).isRequired,
            uuid: PropTypes.string,
        }),
        userRoles: PropTypes.array.isRequired,
        users: PropTypes.arrayOf(
            PropTypes.shape({
                department_id: PropTypes.number,
                id: PropTypes.number,
                role: PropTypes.string.isRequired,
                uuid: PropTypes.string,
            })
        ).isRequired,
        usersRequestingAccess: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
            })
        ).isRequired,
    };

    componentWillUnmount() {
        this.props.setUsersListSearch(null);
    }

    get styles() {
        return require('./index.scss');
    }

    addUser = () => {
        const { user } = this.props;
        const departmentId = user.isVendor ? undefined : user.department.id;
        return this.props.addUser(departmentId);
    };

    filterUsers = (data) => {
        const { search } = data;
        this.props.setUsersListSearch(search);
    };

    handleExportCSVClick = () => {
        const {
            departmentsMap,
            hasUserRoles,
            user: { isVendor },
            userRoles,
            users,
        } = this.props;

        const headers = [
            'First Name',
            'Last Name',
            'Email',
            'Status',
            'Invited Date',
            'Last Login',
        ];

        if (isVendor) {
            headers.push('Role');
        } else {
            headers.push('Department');
            headers.push('External');
            if (hasUserRoles) {
                headers.push(...userRoles.map((userRole) => userRole.name));
            } else {
                headers.push('Role');
            }
        }

        const rows = users
            .filter((user) => !!user.id) // Users without IDs have not been invited yet
            .map((user) => {
                const row = [
                    user.firstName || '',
                    user.lastName || '',
                    user.email,
                    capitalize(user.status),
                    moment(user.created_at).format('l'),
                    user.lastLogin ? moment(user.lastLogin).format('l') : 'None',
                ];

                if (isVendor) {
                    row.push(capitalize(user.role));
                } else {
                    const userRolesMap = buildMap(user.userRoles, 'id');
                    row.push(departmentsMap[user.department_id].name);
                    row.push(user.isExternal ? 'X' : '');
                    if (hasUserRoles) {
                        row.push(
                            ...userRoles.map((userRole) => {
                                return userRolesMap[userRole.id] ? 'X' : '';
                            })
                        );
                    } else {
                        row.push(capitalize(user.role));
                    }
                }

                return row;
            });

        exportArrayToCSV([headers].concat(rows), { fileName: 'Users', headers: true });
    };

    renderUsersRequestingAccess() {
        const {
            departmentsMap,
            hasUserRoles,
            user: currentUser,
            usersRequestingAccess,
        } = this.props;

        if (usersRequestingAccess.length === 0) {
            return null;
        }

        const userRequestForms = usersRequestingAccess.map((user) => {
            return (
                <UserListItem
                    currentUser={currentUser}
                    departmentsMap={departmentsMap}
                    hasUserRoles={hasUserRoles}
                    key={user.id}
                    shouldShowRequestForm
                    user={user}
                />
            );
        });

        return (
            <Well>
                <h4 className={this.styles.requestingUsersTitle}>
                    Member Requests for {currentUser.organization.name}
                </h4>
                {userRequestForms}
            </Well>
        );
    }

    renderUserList() {
        const { departmentsMap, hasUserRoles, user: currentUser, users } = this.props;

        return users.map((user) => {
            const userId = user.id || user.uuid;
            return (
                <UserListItem
                    currentUser={currentUser}
                    departmentsMap={departmentsMap}
                    hasUserRoles={hasUserRoles}
                    key={userId}
                    user={user}
                />
            );
        });
    }

    render() {
        const { bulkUpdateAndDisable, bulkUpdateOldUser, showDisabledUsers, sortDirection, user } =
            this.props;

        return (
            <Main>
                <SectionTitle info="Manage the users for your organization" title="Users" />
                {this.renderUsersRequestingAccess()}
                <div className={classnames('row', this.styles.controlsContainer)}>
                    <div className="col-md-4">
                        <UserSearchForm onChange={this.filterUsers} />
                    </div>
                    <div className="col-md-8 text-right">
                        <Button
                            bsSize="sm"
                            bsStyle="link"
                            onClick={this.props.toggleDisabledUsers}
                            qaTag="connectedUserList-disabledUsersToggle"
                        >
                            {showDisabledUsers ? 'Hide' : 'Show'} Disabled Users
                        </Button>
                        &nbsp;&nbsp;
                        <Button
                            bsSize="sm"
                            onClick={this.handleExportCSVClick}
                            qaTag="connectedUserList-exportToCsv"
                        >
                            <i className="fa fa-download" /> Export to CSV
                        </Button>
                        &nbsp;&nbsp;
                        <Button
                            bsStyle="success"
                            onClick={this.addUser}
                            qaTag="connectedUserList-addUser"
                        >
                            <i className="fa fa-plus" /> Add User
                        </Button>
                    </div>
                </div>
                <div className={`row ${this.styles.table}`}>
                    <div
                        className={`col-xs-5 col-md-3 ${this.styles.userHead} ${this.styles.sortable}`}
                    >
                        <Button
                            bsStyle="link"
                            className={`${this.styles.userButton}`}
                            onClick={this.props.toggleSortUsersDirection}
                            qaTag="connectedUserList-userColumnWithSort"
                            zeroPadding
                        >
                            User{' '}
                            <i
                                className={`fa ${
                                    sortDirection === 'ASC'
                                        ? 'fa-sort-alpha-asc'
                                        : 'fa-sort-alpha-desc'
                                }`}
                            />
                        </Button>
                    </div>
                    <div className={`hidden-xs hidden-sm col-md-1 ${this.styles.userHead}`}>
                        Status
                    </div>
                    <div className={`hidden-xs hidden-sm col-md-1 ${this.styles.userHead}`}>
                        Invited
                    </div>
                    <div className={`hidden-xs hidden-sm col-md-1 ${this.styles.userHead}`}>
                        Last Login
                    </div>
                    <div className={`col-xs-3 col-md-2 ${this.styles.userHead}`}>Role</div>
                    {!user.isVendor && (
                        <div className={`col-xs-4 col-md-2 ${this.styles.userHead}`}>
                            Department
                        </div>
                    )}
                    <div className={`hidden-xs hidden-sm col-md-2 ${this.styles.userHead}`}>
                        Actions
                    </div>
                    <div className="col-xs-12">{this.renderUserList()}</div>
                </div>
                <BulkUpdateContactsModal
                    bulkUpdateAndDisable={bulkUpdateAndDisable}
                    hideModal={this.hideBulkUpdateModal}
                    oldUser={bulkUpdateOldUser}
                />
            </Main>
        );
    }
}

export const UserList = connect(mapStateToProps, mapDispatchToProps)(ConnectedUserList);

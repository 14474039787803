import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Box } from '@og-pro/ui';

import { AwardNoteForm } from './AwardNoteForm';
import { AWARD_NOTE } from './constants';
import { Button, Well } from '../../../components';

export const AwardNote = ({ awardNote, isEditable, projectId }) => {
    const [showForm, setShowForm] = useState(false);

    const [icon, text, onClick] = showForm
        ? ['fa-times-circle', 'Cancel', () => setShowForm(false)]
        : ['fa-pencil', 'Edit', () => setShowForm(true)];

    const label = (
        <>
            <label htmlFor={AWARD_NOTE}>Award Note:</label>&nbsp;
            {isEditable && (
                <Button bsSize="sm" bsStyle="link" onClick={onClick} zeroPadding>
                    <i className={`fa fa-fw ${icon}`} /> {text}
                </Button>
            )}
        </>
    );

    if (showForm) {
        return (
            <Well>
                <AwardNoteForm
                    closeForm={() => setShowForm(false)}
                    initialValues={{ [AWARD_NOTE]: awardNote }}
                    label={label}
                    projectId={projectId}
                />
            </Well>
        );
    }

    return (
        <Well>
            {label}
            <Box component="p" mb={0}>
                {awardNote || 'None'}
            </Box>
        </Well>
    );
};

AwardNote.propTypes = {
    awardNote: PropTypes.string,
    isEditable: PropTypes.bool,
    projectId: PropTypes.number.isRequired,
};

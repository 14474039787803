import PropTypes from 'prop-types';
import React from 'react';

const SVGCircle = (props) => {
    return <circle cx="12" cy="12" r="11" {...props} />;
};

const SVGSquare = (props) => {
    return <rect rx="2.25" x="0.75" y="0.75" {...props} />;
};

export const SingleSelectSVGShape = ({ className, innerCircleClassName, isSelected }) => {
    if (isSelected) {
        return (
            <>
                <SVGCircle className={className} />
                <SVGCircle className={innerCircleClassName} r="8" />
            </>
        );
    }

    return <SVGCircle className={className} />;
};

export const MultiSelectSVGShape = ({ className, isSelected }) => {
    if (isSelected) {
        return (
            <>
                <SVGSquare className={className} />
                <path
                    d="M17.5492 6L9.01823 14.5134L6.10766 11.709L4 13.8123L6.81021 16.6167L8.91787 18.72L11.0255 16.6167L19.5565 8.10331C19.6569 8.20346 17.5492 6 17.5492 6Z"
                    fill="white"
                />
            </>
        );
    }

    return <SVGSquare className={className} />;
};

MultiSelectSVGShape.propTypes = {
    className: PropTypes.string,
    isSelected: PropTypes.bool,
};

SingleSelectSVGShape.propTypes = {
    className: PropTypes.string,
    innerCircleClassName: PropTypes.string,
    isSelected: PropTypes.bool,
};

import { approvalStatusTypes } from '@og-pro/shared-config/requisitions';
import { reviewTypes } from '@og-pro/shared-config/reviewSequences';

const { APPROVED, REJECTED } = approvalStatusTypes;

const getStatusTexts = (reviewType) => {
    switch (reviewType) {
        case reviewTypes.CONFIRM:
            return ['Waiting for Acknowledgement', 'Acknowledged'];
        case reviewTypes.NOTIFY:
            return ['Waiting for Notification', 'Notification Sent'];
        case reviewTypes.APPROVE:
        default:
            return ['Waiting for Approval', 'Approved'];
    }
};

const getStepStatusData = (reviewSequenceStep, isCurrent) => {
    const {
        stepApproval: { status: stepApprovalStatus },
        reviewType,
    } = reviewSequenceStep;

    const [pendingStatusText, approvedStatusText] = getStatusTexts(reviewType);

    if (stepApprovalStatus === APPROVED) {
        return ['success', 'fa-check', approvedStatusText];
    }
    if (stepApprovalStatus === REJECTED) {
        return ['danger', 'fa-ban', 'Rejected'];
    }
    if (isCurrent) {
        return ['royal', undefined, pendingStatusText];
    }
    return ['muted', undefined, 'Not Started'];
};

export const stepStatusHelper = (reviewSequenceStep, isCurrent) => {
    const [className, icon, statusText] = getStepStatusData(reviewSequenceStep, isCurrent);
    return {
        className,
        icon,
        statusText,
    };
};

export const getPrimaryAndSecondaryApprovers = (approvers) => {
    const primaryApprovers = [];
    const secondaryApprovers = [];
    approvers.forEach((approver) => {
        if (approver.isPrimary) {
            primaryApprovers.push(approver);
        } else {
            secondaryApprovers.push(approver);
        }
    });

    return {
        primaryApprovers,
        secondaryApprovers,
    };
};

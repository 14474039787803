import { fieldNames } from './constants';
import { arrayError } from '../../Forms/validation';

const { IS_DISPLAYED, PROJECT_SECTIONS, TITLE } = fieldNames;

export const validate = (values) => {
    const errors = {};
    let displayCount = 0;

    errors[PROJECT_SECTIONS] = (values[PROJECT_SECTIONS] || []).map((projectSection) => {
        const projectSectionError = {};

        if (!projectSection[TITLE]) {
            projectSectionError[TITLE] = 'Please enter a title for the section';
        } else if (projectSection[TITLE].length > 100) {
            projectSectionError[TITLE] = 'Title is too long (100 character max)';
        }

        if (projectSection[IS_DISPLAYED]) {
            displayCount++;
        }

        return projectSectionError;
    });

    if (displayCount === 0) {
        errors[PROJECT_SECTIONS] = arrayError('At least one section must be viewable');
    }

    return errors;
};

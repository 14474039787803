import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ListGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { evaluationStatuses } from '@og-pro/shared-config/evaluations';

import { EvaluatorItem } from './EvaluatorItem';
import { getEvaluatorProposalEvaluations, getTotalProposalEvaluationsToSubmit } from './selectors';
import { AdminScorecardStatus } from '../components';
import {
    getEvaluationLocationObject,
    hasAdminScoredCriteria,
    isAdminScorecardComplete as isAdminScorecardCompleteSelector,
    isEvaluatorScorecardReadOnly,
} from '../selectors';
import {
    getAgreementsJS,
    getCurrentEvaluation,
    getEvaluationProjectJS,
    getProjectStandardDocJS,
    isEvaluationEditor,
} from '../../selectors';
import connectData from '../../../ConnectData';
import {
    hideEvaluatorReminderEmailModal,
    sendEvaluatorReminderEmail,
    shouldLoadFromEvaluationAudit,
    showEvaluatorReminderEmailModal,
} from '../../../../actions/evaluations';
import { getProposalViewerAgreementData, menuActionHandler } from '../../../../actions/govProjects';
import {
    loadAggregateProposalEvaluations,
    shouldReloadAggregateEvaluations,
} from '../../../../actions/proposalEvaluations';
import { EvaluatorReminderEmailModal } from './EvaluatorReminderEmailModal/index';
import {
    Button,
    LoadingSpinner,
    LoadingError,
    SectionTitle,
    ZeroState,
    Main,
} from '../../../../components';
import { RELEASE_EVALUATION } from '../../../../constants/menuActions';

const { DRAFT, OPEN } = evaluationStatuses;

function fetchData(getState, dispatch, location, params) {
    const state = getState();

    // Do not load any data when reading from evaluation audit or preventing reloads
    if (
        shouldLoadFromEvaluationAudit(state, Object.fromEntries(location.searchParams)) ||
        !shouldReloadAggregateEvaluations(state)
    ) {
        return Promise.resolve();
    }

    const projectId = Number.parseInt(params.projectId, 10);
    return Promise.all([
        dispatch(getProposalViewerAgreementData(projectId)),
        dispatch(loadAggregateProposalEvaluations(projectId)),
    ]);
}

const mapStateToProps = (state, props) => {
    return {
        agreements: getAgreementsJS(state),
        evaluation: getCurrentEvaluation(state, props),
        evaluators: getEvaluatorProposalEvaluations(state, props),
        getEvaluationPath: getEvaluationLocationObject(state, props),
        hasAdminScoring: hasAdminScoredCriteria(state, props),
        isAdminScorecardComplete: isAdminScorecardCompleteSelector(state, props),
        isEditor: isEvaluationEditor(state),
        loadAgreementsError: state.govProjects.get('loadAgreementsError'),
        loadError: state.proposalEvaluations.get('loadAggregateError'),
        loading: state.proposalEvaluations.get('loadingAggregate'),
        loadingAgreements: state.govProjects.get('loadingAgreements'),
        numberToSubmit: getTotalProposalEvaluationsToSubmit(state, props),
        project: getEvaluationProjectJS(state),
        readOnly: isEvaluatorScorecardReadOnly(state, props),
        standardDocument: getProjectStandardDocJS(state),
    };
};

const mapDispatchToProps = {
    hideEvaluatorReminderEmailModal,
    menuActionHandler,
    sendEvaluatorReminderEmail,
    showEvaluatorReminderEmailModal,
};

// @connect
class ConnectedEvaluatorsAdmin extends Component {
    static propTypes = {
        agreements: PropTypes.array,
        evaluators: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
            })
        ).isRequired,
        evaluation: PropTypes.shape({
            scoringCriteria: PropTypes.array.isRequired,
            status: PropTypes.string.isRequired,
        }).isRequired,
        getEvaluationPath: PropTypes.func.isRequired,
        hasAdminScoring: PropTypes.bool.isRequired,
        hideEvaluatorReminderEmailModal: PropTypes.func.isRequired,
        isAdminScorecardComplete: PropTypes.bool.isRequired,
        isEditor: PropTypes.bool.isRequired,
        loadAgreementsError: PropTypes.string,
        loadError: PropTypes.string,
        loading: PropTypes.bool.isRequired,
        loadingAgreements: PropTypes.bool,
        menuActionHandler: PropTypes.func.isRequired,
        numberToSubmit: PropTypes.number.isRequired,
        project: PropTypes.object.isRequired,
        readOnly: PropTypes.bool.isRequired,
        sendEvaluatorReminderEmail: PropTypes.func.isRequired,
        showEvaluatorReminderEmailModal: PropTypes.func.isRequired,
        standardDocument: PropTypes.object,
    };

    get styles() {
        return require('./index.scss');
    }

    releaseEvaluation = () => {
        const { project } = this.props;

        this.props.menuActionHandler(RELEASE_EVALUATION, project);
    };

    renderAdminScorecardStatus() {
        const { getEvaluationPath, isAdminScorecardComplete, isEditor } = this.props;

        return (
            <div className="row">
                <div className="col-sm-6 col-sm-offset-3">
                    <AdminScorecardStatus
                        info={
                            <>
                                <em>This evaluation has admin scored items</em>
                                &nbsp;
                                {isEditor && (
                                    <Button
                                        bsSize="sm"
                                        bsStyle="link"
                                        to={getEvaluationPath('/admin-scorecard')}
                                        zeroPadding
                                    >
                                        View Admin Scores
                                    </Button>
                                )}
                            </>
                        }
                        isComplete={isAdminScorecardComplete}
                    />
                </div>
            </div>
        );
    }

    renderList() {
        const {
            agreements,
            evaluators,
            evaluation: { scoringCriteria, status },
            getEvaluationPath,
            hasAdminScoring,
            isEditor,
            numberToSubmit,
            project,
            readOnly,
            standardDocument,
        } = this.props;

        if (status === DRAFT) {
            return (
                <ZeroState title="Evaluators will appear here once evaluation setup is complete" />
            );
        }

        if (status === OPEN) {
            const releaseButton = (
                <Button
                    bsSize="lg"
                    bsStyle="success"
                    disabled={!isEditor}
                    onClick={this.releaseEvaluation}
                    tooltip={
                        isEditor ? undefined : 'Only evaluation editors can release the evaluation'
                    }
                >
                    <i className="fa fa-send" /> Release to Evaluators
                </Button>
            );

            return (
                <ZeroState
                    button={releaseButton}
                    title={
                        <>
                            Evaluation has not been released to evaluators yet
                            <br />
                            Evaluators will appear here once the evaluation has been released
                        </>
                    }
                />
            );
        }

        if (evaluators.length === 0) {
            return <ZeroState title="No evaluators have been added yet" />;
        }

        const numberOfCriteria = scoringCriteria.filter((crit) => !crit.isAdminScored).length;
        const numberToScore = numberToSubmit * numberOfCriteria;

        return (
            <>
                {hasAdminScoring && this.renderAdminScorecardStatus()}
                <div className={classnames('row hidden-xs hidden-sm', this.styles.tableHeader)}>
                    <div className="col-md-3">
                        <strong>Evaluator</strong>
                    </div>
                    <div className="col-md-3">
                        <strong>Score Progress</strong>
                    </div>
                    <div className="col-md-3">
                        <strong>Submit Progress</strong>
                    </div>
                    <div className="col-md-3">
                        <strong>Actions</strong>
                    </div>
                </div>
                <ListGroup>
                    {evaluators.map((evaluator) => {
                        const agreement = agreements.find((a) => a.user_id === evaluator.id);
                        return (
                            <EvaluatorItem
                                agreement={agreement}
                                evaluator={evaluator}
                                getEvaluationPath={getEvaluationPath}
                                hideEvaluatorReminderEmailModal={
                                    this.props.hideEvaluatorReminderEmailModal
                                }
                                isEditor={isEditor}
                                key={evaluator.id}
                                numberToScore={numberToScore}
                                numberToSubmit={numberToSubmit}
                                project={project}
                                readOnly={readOnly}
                                sendEvaluatorReminderEmail={this.props.sendEvaluatorReminderEmail}
                                showEvaluatorReminderEmailModal={
                                    this.props.showEvaluatorReminderEmailModal
                                }
                                standardDocument={standardDocument}
                            />
                        );
                    })}
                </ListGroup>
            </>
        );
    }

    render() {
        const { loadAgreementsError, loadError, loading, loadingAgreements } = this.props;

        if (loading || loadingAgreements) {
            return <LoadingSpinner />;
        }

        if (loadError || loadAgreementsError) {
            return <LoadingError error={loadError || loadAgreementsError} />;
        }

        return (
            <Main>
                <SectionTitle
                    info="Manage the users who are evaluating the project"
                    title="Evaluators"
                />
                {this.renderList()}
                <EvaluatorReminderEmailModal />
            </Main>
        );
    }
}

export const EvaluatorsAdmin = compose(
    connectData(fetchData),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedEvaluatorsAdmin);

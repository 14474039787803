import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, FieldArray } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { extendSimpleWizardForm } from '../../../../hocs';
import { formConfig, fieldNames, evaluationFieldNames, progressSectionsDict } from '../constants';
import { SectionTitle } from '../../../../components';
import { ProposalDocumentsList } from '../../../../components/GovApp';
import { mapStateToProps } from '../mapProps';
import { instructions, helpPopover } from './constants';

const { EVALUATION } = fieldNames;
const { PROPOSAL_DOCUMENTS } = evaluationFieldNames;

// @connect
// @reduxForm
// @extendSimpleWizardForm
class ConnectedProposalDocuments extends Component {
    static propTypes = {
        updating: PropTypes.bool.isRequired,
        showFormErrors: PropTypes.bool.isRequired,
    };

    render() {
        const styles = require('./ProposalDocuments.scss');
        return (
            <div className="row">
                <div className="col-sm-offset-1 col-sm-10">
                    <SectionTitle
                        help={helpPopover}
                        info={instructions}
                        title="Response Documents"
                    />
                    <div className={styles.proposalDocumentTitle}>
                        <b>Response Documents</b>
                    </div>
                    <FieldArray
                        component={ProposalDocumentsList}
                        disabled={this.props.updating}
                        name={`${EVALUATION}.${PROPOSAL_DOCUMENTS}`}
                        showFormErrors={this.props.showFormErrors}
                    />
                </div>
            </div>
        );
    }
}

export const ProposalDocuments = compose(
    connect(mapStateToProps),
    reduxForm(formConfig),
    extendSimpleWizardForm(progressSectionsDict.PROPOSALS)
)(ConnectedProposalDocuments);

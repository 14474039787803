// map of html element heading to the corresponding
// docx heading when section dividers are enabled
// Respects docx_helper.js file map.
export const TARGET_EL_HEADING_SPACING_MAP_WITH_DIVIDERS = {
    h1: 'heading1',
    h2: 'heading1',
    h3: 'heading2',
    h4: 'heading3',
    h5: 'heading4',
    h6: 'heading5',
};

// map of html element heading to the corresponding
// docx heading when section dividers are NOT enabled
// Respects docx_helper.js file map.
export const TARGET_EL_HEADING_SPACING_MAP_NO_DIVIDERS = {
    h1: 'heading1',
    h2: 'heading1',
    h3: 'heading1',
    h4: 'heading2',
    h5: 'heading3',
    h6: 'heading4',
};

export const ALLOWED_VARIABLES = {
    TITLE: '{{title}}',
    NUMBER: '{{number}}',
    TAB: '{{tab}}',
    SPACE: '{{space}}',
    NEWLINE: '{{newline}}',
};

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { ReportButton } from '../components';

export class ReportsFormatSelect extends PureComponent {
    static propTypes = {
        formats: PropTypes.array.isRequired,
        onClick: PropTypes.func.isRequired,
    };

    render() {
        const { formats, onClick } = this.props;

        return (
            <div>
                {formats.map((format) => (
                    <ReportButton {...format} key={format.type} onClick={onClick} />
                ))}
            </div>
        );
    }
}

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { attachmentsHelp } from './constants';
import { mapStateToProps } from '../mapProps';
import { form, formConfig } from '../constants';
import { ProjectInformationForm, SectionTitle } from '../../../../components';
import { extendSimpleWizardForm } from '../../../../hocs';

// @connect
// @reduxForm
// @extendSimpleWizardForm
class ConnectedBasicProjectOverview extends Component {
    static propTypes = {
        change: PropTypes.func.isRequired,
        project: PropTypes.object.isRequired,
        showFormErrors: PropTypes.bool.isRequired,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
        updating: PropTypes.bool,
    };

    static defaultProps = {
        tagOptions: undefined,
        templateVariableOptions: undefined,
        updating: false,
    };

    render() {
        const { change, project, showFormErrors, tagOptions, templateVariableOptions, updating } =
            this.props;

        return (
            <div className="row">
                <div className="col-sm-offset-1 col-sm-10">
                    <SectionTitle
                        info="Information about the project to post"
                        title="Project Overview"
                    />
                    <ProjectInformationForm
                        attachmentsHelp={attachmentsHelp}
                        change={change}
                        form={form}
                        project={project}
                        showFormErrors={showFormErrors}
                        tagOptions={tagOptions}
                        templateVariableOptions={templateVariableOptions}
                        updating={updating}
                    />
                </div>
            </div>
        );
    }
}

export const BasicProjectOverview = compose(
    withRouter,
    connect(mapStateToProps),
    reduxForm(formConfig),
    extendSimpleWizardForm()
)(ConnectedBasicProjectOverview);

import { listToDict } from '../../../../../../utils';

export const form = 'evaluationsTableDisplayOptions';

export const fields = [
    'showAnonymizedEvaluators',
    'showEvaluatorComments',
    'showCriteriaDescriptions',
    'showDetailedProposals',
    'showExpandedView',
    'showPercentageTotals',
];

export const fieldNames = listToDict(fields);

import { createSelector } from 'reselect';

import { getUserJS } from '../../selectors';

export const getOrganization = createSelector([getUserJS], (user) => {
    return user.organization;
});

export const getInitialValues = createSelector([getUserJS], (user) => {
    if (user) {
        const { vendor } = user;
        return {
            vendor: {
                categories: vendor.categories,
            },
        };
    }
});

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { ActionToolbarNavbarItem } from '../../../../components';
import { UNSEAL_BIDS } from '../../../../constants/menuActions';
import { trackEvent } from '../../../../helpers';

export class UnsealBidsButton extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool.isRequired,
        isDraftPage: PropTypes.bool.isRequired,
        isEditor: PropTypes.bool.isRequired,
        menuActionHandler: PropTypes.func.isRequired,
        project: PropTypes.shape({
            showBids: PropTypes.bool.isRequired,
            showBidsWithPricing: PropTypes.bool.isRequired,
        }).isRequired,
    };

    handleClick = () => {
        const { menuActionHandler } = this.props;

        trackEvent('Unseal Bid Toolbar Button');
        menuActionHandler(UNSEAL_BIDS);
    };

    render() {
        const {
            disabled,
            isDraftPage,
            isEditor,
            project: { showBids, showBidsWithPricing },
        } = this.props;

        if (isDraftPage || showBidsWithPricing) {
            return null;
        }

        return (
            <ActionToolbarNavbarItem
                className="action-toolbar-unseal-bid-btn"
                disabled={disabled}
                editOnly
                isEditor={isEditor}
                onClick={this.handleClick}
                qaTag="projectActionMenu-unseal"
            >
                <i className="fa fa-envelope-open" /> Unseal {showBids ? 'Pricing' : 'Bids'}
            </ActionToolbarNavbarItem>
        );
    }
}

import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import { ArticleOutlined as ArticleOutlinedIcon, Group as GroupIcon } from '@mui/icons-material';
import { Box, Chip, Typography } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { CONTRACT, VENDOR } from './constants';

export const ResourceSelect = ({ selectedSearch, setSelectedSearch }) => {
    const buttonProps = (isSelected) => ({
        size: 'small',
        sx: isSelected
            ? {
                  backgroundColor: capitalDesignTokens.semanticColors.background.actionLight,
                  borderColor: capitalDesignTokens.semanticColors.foreground.action,
                  color: capitalDesignTokens.semanticColors.foreground.action,
              }
            : undefined,
        variant: 'outlined',
    });

    const iconColor = (isSelected) =>
        isSelected ? capitalDesignTokens.semanticColors.foreground.action : undefined;

    return (
        <Box alignItems="center" display="flex" gap={1} marginTop={1.5}>
            <Typography variant="h6">Search by:</Typography>
            <Chip
                {...buttonProps(selectedSearch === VENDOR)}
                icon={<GroupIcon color={iconColor(selectedSearch === VENDOR)} />}
                label="Vendor"
                onClick={() => setSelectedSearch(VENDOR)}
            />
            <Chip
                {...buttonProps(selectedSearch === CONTRACT)}
                icon={<ArticleOutlinedIcon color={iconColor(selectedSearch === CONTRACT)} />}
                label="Contract"
                onClick={() => setSelectedSearch(CONTRACT)}
            />
        </Box>
    );
};

ResourceSelect.propTypes = {
    selectedSearch: PropTypes.string.isRequired,
    setSelectedSearch: PropTypes.func.isRequired,
};

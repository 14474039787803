import React from 'react';
import { Box } from '@og-pro/ui';

import { ProcuratedVendorCell } from '../../../Procurated';

export const VendorNameCellRenderer = (params) =>
    params.data?.expanded ? (
        <Box minWidth={0} overflow="hidden" pt={0.5} textOverflow="ellipsis">
            <ProcuratedVendorCell hideLogo params={params} />
        </Box>
    ) : (
        <ProcuratedVendorCell hideLogo params={params} />
    );

export const personalValidate = (values, props) => {
    const errors = {};

    if (!values.firstName) {
        errors.firstName = 'A first name is required';
    } else if (values.firstName.length > 32) {
        errors.firstName = 'Name is too long';
    }

    if (!values.lastName) {
        errors.lastName = 'A last name is required';
    } else if (values.lastName.length > 32) {
        errors.lastName = 'Name is too long';
    }

    if (!values.title) {
        errors.title = 'A job title is required';
    }

    if (!values.department_id && props.departments.length !== 0) {
        errors.department_id = 'A department is required';
    }

    return errors;
};

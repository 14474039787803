import React from 'react';
import PropTypes from 'prop-types';

import { ListGroup, LoadingError } from '../../../../components';

export const RequestTypeSelect = ({ clickHandler, reviewGroup }) => {
    const styles = require('./shared.scss');

    if (!reviewGroup) {
        return <LoadingError error="No review group selected" />;
    }

    const requestTypes = reviewGroup.requestTypeGroupSequences
        .map(({ requestType }) => requestType)
        .filter(Boolean);

    if (requestTypes.length === 0) {
        return (
            <p>
                The selected review group does not have any request types. Please contact a Request
                Management Admin for assistance.
            </p>
        );
    }

    return (
        <div className={styles.projectCreateIcons}>
            <div className={styles.titleText}>Select Request Type</div>
            <ListGroup
                itemTitleKey="name"
                items={requestTypes}
                onClick={(requestType) => clickHandler({ requestType })}
            />
        </div>
    );
};

RequestTypeSelect.propTypes = {
    clickHandler: PropTypes.func.isRequired,
    reviewGroup: PropTypes.object.isRequired,
};

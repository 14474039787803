import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, FieldArray, getFormSyncErrors } from 'redux-form';

import { Box, Typography } from '@og-pro/ui';

import { isRequisitionDraft, purchaseDetailsModes } from '@og-pro/shared-config/requisitions';

import { getPriceItemsSummary } from '@og-pro/shared-config/priceTables/requisitionUtils';

import { Button, InputText, RadioGroup } from '../../../../../../components';
import { Header } from './Header';
import { PurchaseDetailsSummary } from './Summary';
import { fieldNames } from '../../constants';
import { getEmptyPriceItem } from '../../helpers';
import { VendorAssignmentModal } from '../../VendorAssignmentModal';
import { qaTagPageName } from '../../../constants';
import { priceItemFieldNames } from '../../../../constants';
import {
    getGovernmentSalesTax,
    getIsRequisitionUsingFMS,
    getRequisitionJS,
} from '../../../../../../selectors/govApp';
import { getMaxNumberFromList } from '../../../../../../utils';
import { PriceItem } from './PriceItem';
import { formConfig } from '../../form';
import { requisitionsCreateFormValueSelector } from '../../selectors';
import { maskNumberWithCommas } from '../../../../../../Forms/maskers';
import { dollarString } from '../../../../../../Forms/normalizers';
import { MaskedInputText } from '../../../../../../hocs';

const { PRICE_ITEMS, PRICE_TABLE, PRICE_ITEMS_SUMMARY, PURCHASE_DETAILS_MODE } = fieldNames;
const { ORDER_BY_ID, UNIT_PRICE, DESCRIPTION, QUANTITY, UNIT_TO_MEASURE } = priceItemFieldNames;
const { AMOUNT_ONLY, LINE_ITEMS } = purchaseDetailsModes;

const PriceItemFields = ({
    change,
    disabled,
    fields,
    isDraft,
    showFormValidation,
    showVendorSelection,
    tax,
}) => {
    const styles = require('./index.scss');

    const MaskedInput = useCallback(MaskedInputText(InputText), []);

    const isApprovalView = !isDraft;

    const showDiscountInHeader = useMemo(() => {
        return fields.getAll().some((field) => field.discount);
    }, [fields]);

    const [expanded, setExpanded] = useState(0);

    const handleExpand = (index) => (event, isExpanded) => {
        setExpanded(isExpanded ? index : false);
    };

    const priceItems = useSelector((state) =>
        requisitionsCreateFormValueSelector(state, `${PRICE_TABLE}.${PRICE_ITEMS}`)
    );

    const { total } = useMemo(() => getPriceItemsSummary(priceItems, tax), [priceItems, tax]);

    const setFieldValues = (index, updates) => {
        const field = fields.get(index);
        const updatedField = {
            ...field,
        };

        updates.forEach((update) => {
            updatedField[update.key] = update.value;
        });

        fields.remove(index);
        fields.insert(index, updatedField);
    };

    const hasFMS = useSelector(getIsRequisitionUsingFMS);

    // purchaseDetailsMode is a selector that is used to determine if the user is in the AMOUNT_ONLY
    // or LINE_ITEMS mode
    const purchaseDetailsMode = useSelector((state) =>
        requisitionsCreateFormValueSelector(state, PURCHASE_DETAILS_MODE)
    );
    const dispatch = useDispatch();
    const handleModeChange = (event, newValue, previousValue) => {
        const newPriceItems = [];
        if (newValue !== previousValue) {
            if (newValue === AMOUNT_ONLY && previousValue === LINE_ITEMS) {
                newPriceItems.push({
                    ...getEmptyPriceItem(1),
                    [UNIT_PRICE]: total,
                    [DESCRIPTION]: 'Total Budget Amount',
                    [QUANTITY]: 1,
                    [UNIT_TO_MEASURE]: 'ea',
                });
            } else if (newValue === LINE_ITEMS && previousValue === AMOUNT_ONLY) {
                newPriceItems.push({
                    ...getEmptyPriceItem(1),
                    [UNIT_PRICE]: total,
                    [QUANTITY]: 1,
                });
            }
            dispatch(change(fields.name, newPriceItems));
        }
    };

    return (
        <div className={styles.priceItemsContainer}>
            {!hasFMS && // Only allow to select either AMOUNT_ONLY or LINE_ITEMS if no FMS
                isDraft && ( // Only allow to select either AMOUNT_ONLY or LINE_ITEMS if the requisition is in draft
                    <>
                        <Typography variant="h4">How much information do you have? *</Typography>
                        <Field
                            component={RadioGroup}
                            disabled={disabled}
                            groupLabel="amountOnly"
                            name={PURCHASE_DETAILS_MODE}
                            normalize={(value) => parseInt(value, 10)}
                            onChange={handleModeChange}
                            options={[
                                {
                                    name: 'Simple - I only have the total budget amount',
                                    value: AMOUNT_ONLY,
                                    qaTag: `${qaTagPageName}-purchaseDetailsModeAmountOnly`,
                                },
                                {
                                    name: 'Advanced - I have the full detailed line items',
                                    value: LINE_ITEMS,
                                    qaTag: `${qaTagPageName}-purchaseDetailsModeLineItems`,
                                },
                            ]}
                            showValidation={showFormValidation}
                            useOpenGovStyle
                        />
                    </>
                )}
            {purchaseDetailsMode === AMOUNT_ONLY && (
                <Box width="25%">
                    <Field
                        component={MaskedInput}
                        hasFeedback={false}
                        inputGroupPrefix="$"
                        label="Total Budget Amount *"
                        mask={maskNumberWithCommas}
                        name={`${fields.name}[0].${UNIT_PRICE}`}
                        normalizer={dollarString}
                        qaTag={`${qaTagPageName}-${UNIT_PRICE}`}
                        showValidation={showFormValidation}
                        style={{ zIndex: 1 }}
                        useOpenGovStyle
                    />
                </Box>
            )}
            {purchaseDetailsMode === LINE_ITEMS && (
                <>
                    <Typography variant="h5">Add line items *</Typography>
                    <Header showDiscountInHeader={showDiscountInHeader} tax={tax} />
                    <div className={styles.priceItem}>
                        {fields.map((fieldName, index) => {
                            return (
                                <div key={fieldName}>
                                    <PriceItem
                                        change={change}
                                        disabled={disabled}
                                        expanded={expanded === index}
                                        fieldName={fieldName}
                                        fields={fields}
                                        handleExpand={handleExpand}
                                        index={index}
                                        isApprovalView={isApprovalView}
                                        isDraft={isDraft}
                                        setFieldValues={setFieldValues}
                                        showDiscountInHeader={showDiscountInHeader}
                                        showFormValidation={showFormValidation}
                                        showVendorSelection={showVendorSelection}
                                        tax={tax}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    {!isApprovalView && (
                        <div className={styles.addAnotherLineItem}>
                            <Button
                                onClick={() => {
                                    const orderById =
                                        getMaxNumberFromList(fields.getAll(), ORDER_BY_ID) + 1;
                                    const newPriceItem = getEmptyPriceItem(orderById);
                                    fields.push(newPriceItem);
                                    setExpanded(fields.length);
                                }}
                                qaTag={`${qaTagPageName}-addPriceItem`}
                            >
                                <i className="fa fa-plus" />
                                Add Another Line Item
                            </Button>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

PriceItemFields.propTypes = {
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    fields: PropTypes.object.isRequired,
    isDraft: PropTypes.bool,
    showFormValidation: PropTypes.bool,
    showVendorSelection: PropTypes.bool,
    tax: PropTypes.number,
};

export const PurchaseDetails = ({ change, disabled, showFormValidation, showVendorSelection }) => {
    const styles = require('./index.scss');
    const requisition = useSelector(getRequisitionJS);
    const isDraft = isRequisitionDraft(requisition.status);
    const tax = useSelector(getGovernmentSalesTax);
    const isApprovalView = !isDraft;
    const errors = useSelector((state) => getFormSyncErrors(formConfig.form)(state));
    const currentAccountErrors = errors?.[PRICE_ITEMS_SUMMARY];
    const purchaseDetailsMode = useSelector((state) =>
        requisitionsCreateFormValueSelector(state, PURCHASE_DETAILS_MODE)
    );

    return (
        <div className={styles.purchaseDetails}>
            <FieldArray
                change={change}
                component={PriceItemFields}
                disabled={disabled}
                isDraft={isDraft}
                name={`${PRICE_TABLE}.${PRICE_ITEMS}`}
                showFormValidation={showFormValidation}
                showVendorSelection={showVendorSelection}
                tax={tax}
            />
            {currentAccountErrors && (
                <div className={styles.error}>
                    <i className="fa fa-warning" /> {currentAccountErrors}
                </div>
            )}

            {purchaseDetailsMode === LINE_ITEMS && (
                <>
                    {isApprovalView ? (
                        <div className={styles.summaryCard}>
                            <PurchaseDetailsSummary />
                        </div>
                    ) : (
                        <PurchaseDetailsSummary />
                    )}
                    <VendorAssignmentModal change={change} />
                </>
            )}
        </div>
    );
};

PurchaseDetails.propTypes = {
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    showFormValidation: PropTypes.bool,
    showVendorSelection: PropTypes.bool,
};

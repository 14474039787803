import { REQUIRED_TEXT } from './constants';

export function title(values) {
    const errors = {};
    if (!values.title) {
        errors.title = REQUIRED_TEXT;
    } else if (values.title.length >= 200) {
        errors.title = 'This field is too long';
    }

    return errors;
}

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { NewThreadForm } from './NewThreadForm/NewThreadForm';
import { UserProfilePicture } from '../..';

export class NewComment extends PureComponent {
    static propTypes = {
        canComment: PropTypes.bool.isRequired,
        createThreadError: PropTypes.string,
        followerIdsValue: PropTypes.array.isRequired,
        followers: PropTypes.array.isRequired,
        hideFollowersFormHandler: PropTypes.func.isRequired,
        showFollowersForm: PropTypes.bool.isRequired,
        showFollowersFormHandler: PropTypes.func.isRequired,
        submitHandler: PropTypes.func.isRequired,
        user: PropTypes.object.isRequired,
        users: PropTypes.array.isRequired,
    };

    render() {
        const {
            canComment,
            createThreadError,
            followerIdsValue,
            followers,
            hideFollowersFormHandler,
            showFollowersForm,
            showFollowersFormHandler,
            submitHandler,
            user,
            users,
        } = this.props;

        const styles = require('./NewComment.scss');
        const commentStyles = require('./Comment.scss');
        const initialFollowers = followers.map((follower) => {
            return {
                label: follower.displayName,
                userData: follower,
                value: follower.id,
            };
        });

        return (
            <div className={styles.newContainer}>
                <div className={commentStyles.commentPicture}>
                    <UserProfilePicture showTooltip user={user} />
                </div>
                <div className={commentStyles.commentContent}>
                    <NewThreadForm
                        canComment={canComment}
                        followerIdsValue={followerIdsValue}
                        hideFollowersFormHandler={hideFollowersFormHandler}
                        initialValues={{ followerIds: initialFollowers }}
                        onSubmit={submitHandler}
                        showFollowersForm={showFollowersForm}
                        showFollowersFormHandler={showFollowersFormHandler}
                        submitServerError={createThreadError}
                        users={users}
                    />
                </div>
                <div className="clearfix" />
            </div>
        );
    }
}

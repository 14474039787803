import { isNumber } from 'lodash';

import { fieldNames } from './constants';
import { hasFormErrors } from '../../../../../../helpers';

const { COMMENT, PROPOSAL_CRITERIA_SCORES, SCORE } = fieldNames;

export const warn = (values) => {
    const warnings = (values[PROPOSAL_CRITERIA_SCORES] || []).map((proposalCriteriaScore) => {
        const proposalCriteriaWarnings = {};

        if (proposalCriteriaScore.scoringCriterium.isAdminScored) {
            return proposalCriteriaWarnings;
        }

        if (!isNumber(proposalCriteriaScore[SCORE])) {
            proposalCriteriaWarnings[SCORE] = 'Each criteria must receive a score';
        }

        return proposalCriteriaWarnings;
    });

    return {
        [PROPOSAL_CRITERIA_SCORES]: warnings,
        _warning: hasFormErrors(warnings), // Used to check if evaluation is ready to be submitted
    };
};

export const validate = (values, props) => {
    const errors = (values[PROPOSAL_CRITERIA_SCORES] || []).map((proposalCriteriaScore) => {
        const proposalCriteriaErrors = {};

        if (!props.isCommentRequired || proposalCriteriaScore.scoringCriterium.isAdminScored) {
            return proposalCriteriaErrors;
        }

        const hasScore = isNumber(proposalCriteriaScore[SCORE]);
        if (hasScore && !(proposalCriteriaScore[COMMENT] || '').trim()) {
            proposalCriteriaErrors[COMMENT] = 'Comment is required';
        }

        return proposalCriteriaErrors;
    });

    return {
        [PROPOSAL_CRITERIA_SCORES]: errors,
    };
};

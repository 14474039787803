import PropTypes from 'prop-types';
import React from 'react';

import { Well } from '../../../../../components';

export const EditingDisabledWell = ({ templateTitle }) => {
    return (
        <Well>
            <strong className="text-warning">
                <i className="fa fa-exclamation-triangle" /> Editing Disabled
            </strong>
            <br />
            This Shared Question is in use by the &quot;
            {templateTitle || 'Untitled'}&quot; template.
            <br />
            Shared Questions in use cannot have their titles edited.
            <br />
            Remove this question from the template before editing or deleting.
        </Well>
    );
};

EditingDisabledWell.propTypes = {
    templateTitle: PropTypes.string,
};

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';

import { ContractMilestone } from './ContractMilestone';

export class ContractMilestones extends PureComponent {
    static propTypes = {
        contract: PropTypes.object.isRequired,
        contractMilestones: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
            })
        ).isRequired,
        isEditor: PropTypes.bool,
        reset: PropTypes.func.isRequired,
        timezone: PropTypes.string.isRequired,
        updateContractMilestoneNotifications: PropTypes.func.isRequired,
    };

    render() {
        const {
            contract,
            contractMilestones,
            isEditor,
            reset,
            timezone,
            updateContractMilestoneNotifications: updateNotifications,
        } = this.props;

        return (
            <ListGroup>
                {contractMilestones.map((contractMilestone) => (
                    <ListGroupItem key={contractMilestone.id}>
                        <ContractMilestone
                            contract={contract}
                            contractMilestone={contractMilestone}
                            isEditor={isEditor}
                            reset={reset}
                            timezone={timezone}
                            updateContractMilestoneNotifications={updateNotifications}
                        />
                    </ListGroupItem>
                ))}
            </ListGroup>
        );
    }
}

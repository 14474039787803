import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { RequisitionAttachment } from './RequisitionAttachment';
import { requisitionsCreateFormValueSelector } from '../../selectors';
import { ATTACHMENTS } from '../../../../../../constants/requisitionsCreate';
import { MAX_S3_FILE_SIZE_IN_MB } from './UploadDropzone';

export const Attachments = (props) => {
    const fieldName = props.fieldNamePrefix
        ? `${props.fieldNamePrefix}.${ATTACHMENTS}`
        : ATTACHMENTS;

    const attachments = useSelector((state) =>
        requisitionsCreateFormValueSelector(state, fieldName)
    );

    const title = attachments && attachments.length > 0 ? 'Upload' : 'No Documents Uploaded';
    return (
        <RequisitionAttachment
            renderAsDropPanel
            title={title}
            {...props}
            maxFileSize={MAX_S3_FILE_SIZE_IN_MB}
        />
    );
};

Attachments.propTypes = {
    fieldNamePrefix: PropTypes.string,
};

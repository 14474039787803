const purchaseOrderStatuses = {
    ISSUED: 1,
    REQUESTED: 3,
    APPROVED: 4,
    DENIED: 5,
};

exports.purchaseOrderStatuses = purchaseOrderStatuses;

exports.purchaseOrderStatusTypes = [
    purchaseOrderStatuses.REQUESTED,
    purchaseOrderStatuses.APPROVED,
    purchaseOrderStatuses.DENIED,
    purchaseOrderStatuses.ISSUED,
];

exports.getStatusText = (status) => {
    switch (status) {
        case purchaseOrderStatuses.ISSUED:
            return 'Issued';
        case purchaseOrderStatuses.REQUESTED:
            return 'Requested';
        case purchaseOrderStatuses.APPROVED:
            return 'Approved';
        case purchaseOrderStatuses.DENIED:
            return 'Denied';
        default:
            return 'Requested';
    }
};

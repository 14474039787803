import PropTypes from 'prop-types';
import React from 'react';
import { ListGroup } from 'react-bootstrap';

import { ChecklistsListItem } from './ChecklistsListItem';
import { ZeroState } from '..';

export const ChecklistsList = ({ checklists = [], contractChecklistsPath }) => {
    const styles = require('./index.scss');

    if (checklists.length === 0) {
        return (
            <ZeroState
                info="Once checklists have been added for the contract they will appear here"
                title="No Checklists Added"
            />
        );
    }

    return (
        <>
            <div className={`row hidden-xs ${styles.header}`}>
                <div className="col-xs-4">Checklist</div>
                <div className="col-xs-4">Progress</div>
                <div className="col-xs-4">Last Updated</div>
            </div>
            <ListGroup>
                {checklists.map((checklist) => (
                    <ChecklistsListItem
                        checklist={checklist}
                        contractChecklistsPath={contractChecklistsPath}
                        key={checklist.id}
                    />
                ))}
            </ListGroup>
        </>
    );
};

ChecklistsList.propTypes = {
    checklists: PropTypes.arrayOf(PropTypes.object).isRequired,
    contractChecklistsPath: PropTypes.string.isRequired,
};

import PropTypes from 'prop-types';
import React from 'react';
import { DropdownButton } from 'react-bootstrap';

import { Button, MenuItem } from '../../../../components';

export const MenuButtons = ({
    deleteChecklist,
    deleteError,
    deleting,
    disabled,
    isEditor,
    releaseChecklist,
    saveChecklist,
    setShowFormValidation,
    submitFailed,
    updateError,
    updating,
}) => {
    const deleteIcon = deleting ? 'spinner fa-spin' : 'trash';
    const saveIcon = updating ? 'spinner fa-spin' : 'check';

    return (
        <>
            <DropdownButton
                disabled={disabled}
                id="contract-checklist-more-button"
                pullRight
                title="More"
            >
                <MenuItem
                    onClick={() => setShowFormValidation(true)}
                    qaTag="contractChecklistTitleMenu-showFormErrors"
                >
                    <i className="fa fa-fw fa-exclamation-triangle" /> Show Form Errors
                </MenuItem>
                <MenuItem
                    disabled={!isEditor || disabled}
                    onClick={isEditor ? deleteChecklist : undefined}
                    qaTag="contractChecklistTitleMenu-delete"
                >
                    <i className={`fa fa-fw fa-${deleteIcon}`} /> Delete
                </MenuItem>
            </DropdownButton>
            &nbsp;&nbsp;
            <Button
                bsStyle="success"
                disabled={!isEditor || disabled}
                onClick={releaseChecklist}
                qaTag="contractChecklistTitleMenu-release"
                tooltip={isEditor ? undefined : 'Only contract editors can release the checklist'}
            >
                <i className="fa fa-send" /> Release Checklist
            </Button>
            &nbsp;&nbsp;
            <Button
                bsStyle="primary"
                disabled={disabled}
                onClick={() => saveChecklist()}
                qaTag="contractChecklistTitleMenu-save"
            >
                <i className={`fa fa-fw fa-${saveIcon}`} /> Save
            </Button>
            {updateError && <div className="error-block text-right">{updateError}</div>}
            {submitFailed && (
                <div className="error-block text-right">
                    Please fix form errors before submitting
                </div>
            )}
            {deleteError && <div className="error-block text-right">{deleteError}</div>}
        </>
    );
};

MenuButtons.propTypes = {
    deleteChecklist: PropTypes.func.isRequired,
    deleteError: PropTypes.string,
    deleting: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    isEditor: PropTypes.bool.isRequired,
    releaseChecklist: PropTypes.func.isRequired,
    saveChecklist: PropTypes.func.isRequired,
    setShowFormValidation: PropTypes.func.isRequired,
    submitFailed: PropTypes.bool,
    updateError: PropTypes.string,
    updating: PropTypes.bool.isRequired,
};

import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Typography } from '@og-pro/ui';

import { projectTypesPathsDict } from '@og-pro/shared-config/projects';

import {
    getHistoricContractStackedAnalytics,
    getHistoricPurchaseStackedAnalytics,
} from '../../../../containers/GovApp/AggregateAnalytics/selectors';
import { createNavItemId, ReactRouterLink } from './utils';
import { getUserJS } from '../../../../containers/selectors';

const { PROJECTS, CONTRACTS } = projectTypesPathsDict;

export const useAnalyticsNavItems = () => {
    const location = useLocation();
    const user = useSelector(getUserJS);

    const { pathname } = location;
    const {
        government_id: governmentId,
        government: { hasRequisitionOnly },
        userPermissions: {
            isContractAdmin,
            isDepartmentContractAdmin,
            isDepartmentEditor,
            isGlobalEditor,
            isSystemAdmin,
        },
    } = user;

    const navItems = [];
    const subNavItems = [];

    const isActive = pathname.match(/\/analytics\//);

    const hasPurchaseTemplates =
        useSelector((state) => getHistoricPurchaseStackedAnalytics(state)).series.length > 0;
    const hasContractsTemplates =
        useSelector((state) => getHistoricContractStackedAnalytics(state)).series.length > 0;

    const hasProjectPermission = isSystemAdmin || isGlobalEditor || isDepartmentEditor;
    const hasContractPermission = isSystemAdmin || isContractAdmin || isDepartmentContractAdmin;
    const hasAnalyticPermission = hasProjectPermission || hasContractPermission;

    const secondaryTextStyles = {
        display: {
            xs: 'none',
            md: 'block',
        },
    };

    if (!hasAnalyticPermission || hasRequisitionOnly) {
        return navItems;
    }
    if (hasProjectPermission) {
        subNavItems.push({
            id: createNavItemId('analytics-projects'),
            label: (
                <>
                    Projects
                    {!hasPurchaseTemplates && (
                        <Typography color="secondary" sx={secondaryTextStyles} variant="bodySmall">
                            There are no projects available yet.
                        </Typography>
                    )}
                </>
            ),
            linkComponent: ReactRouterLink,
            url: `/governments/${governmentId}/analytics/${PROJECTS}`,
        });
    }

    if (hasContractPermission) {
        subNavItems.push({
            id: createNavItemId('analytics-contracts'),
            label: (
                <>
                    Contracts
                    {!hasContractsTemplates && (
                        <Typography color="secondary" sx={secondaryTextStyles} variant="bodySmall">
                            There are no contracts available yet.
                        </Typography>
                    )}
                </>
            ),
            linkComponent: ReactRouterLink,
            url: `/governments/${governmentId}/analytics/${CONTRACTS}`,
        });
    }

    navItems.push({
        id: createNavItemId('analytics'),
        label: 'Analytics',
        submenuSections: [{ items: subNavItems }],
        isActive,
    });

    return navItems;
};

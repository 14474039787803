import { withFlags } from '@og-pro/launch-darkly/client';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { ModalContent } from './components';
import { getDefaultProcurementContactId, getInitialProjectCreateDataJS } from './selectors';
import {
    getActiveTemplates,
    hasContractingSubscription,
    hasDocBuilderSubscription,
    hasEvaluationSubscription,
    hasIntakeSubscription,
    hasRequisitionSubscription,
    hasSourcingSubscription,
    usesCategorySelect,
} from '../selectors';
import {
    getUserJS,
    isContractAdminUser,
    isDepartmentContractAdminUser,
    isProjectCreatorUser,
    isTemplateAdminUser,
} from '../../selectors';
import * as projectCreateActions from '../../../actions/project/create/projectCreate';
import { createRequisition } from '../../../actions/requisitionsCreate';
import { showModal as showLibraryModal } from '../../../actions/projectLibrary';

const mapStateToProps = (state) => {
    return {
        defaultProcurementContactId: getDefaultProcurementContactId(state),
        error: state.projectCreate.get('createError'),
        hasContracting: hasContractingSubscription(state),
        hasDocBuilder: hasDocBuilderSubscription(state),
        hasEvaluation: hasEvaluationSubscription(state),
        hasIntake: hasIntakeSubscription(state),
        hasRequisition: hasRequisitionSubscription(state),
        hasSourcing: hasSourcingSubscription(state),
        initialData: getInitialProjectCreateDataJS(state),
        isContractAdmin: isContractAdminUser(state) || isDepartmentContractAdminUser(state),
        isProjectCreator: isProjectCreatorUser(state),
        isTemplateAdmin: isTemplateAdminUser(state),
        loading: state.projectCreate.get('creating'),
        loadingText: state.projectCreate.get('createText'),
        showModal: state.projectCreate.get('showCreateModal'),
        templates: getActiveTemplates(state),
        user: getUserJS(state),
        usesCategorySelect: usesCategorySelect(state),
    };
};

const mapDispatchToProps = {
    ...projectCreateActions,
    createRequisition,
    showLibraryModal,
};

// @connect
class ConnectedProjectCreateModal extends Component {
    static propTypes = {
        createBuilderProject: PropTypes.func.isRequired,
        createContract: PropTypes.func.isRequired,
        createEvaluationProject: PropTypes.func.isRequired,
        createPostProject: PropTypes.func.isRequired,
        createIntakeProject: PropTypes.func.isRequired,
        createRequisition: PropTypes.func.isRequired,
        defaultProcurementContactId: PropTypes.number,
        error: PropTypes.string,
        hasContracting: PropTypes.bool.isRequired,
        hasDocBuilder: PropTypes.bool.isRequired,
        hasEvaluation: PropTypes.bool.isRequired,
        hasIntake: PropTypes.bool.isRequired,
        hasRequisition: PropTypes.bool.isRequired,
        hasSourcing: PropTypes.bool.isRequired,
        hideCreateModal: PropTypes.func.isRequired,
        initialData: PropTypes.object,
        isContractAdmin: PropTypes.bool.isRequired,
        isProjectCreator: PropTypes.bool.isRequired,
        isTemplateAdmin: PropTypes.bool.isRequired,
        loading: PropTypes.bool,
        loadingText: PropTypes.string,
        resetCreateError: PropTypes.func.isRequired,
        showLibraryModal: PropTypes.func.isRequired,
        showModal: PropTypes.bool,
        templates: PropTypes.array.isRequired,
        user: PropTypes.object.isRequired,
        usesCategorySelect: PropTypes.bool.isRequired,
    };

    render() {
        /**
         * Important to do this here so that state is reinitialized every time modal is shown
         */
        if (!this.props.showModal) {
            return null;
        }

        return <ModalContent {...this.props} />;
    }
}

export const ProjectCreateModal = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withFlags()
)(ConnectedProjectCreateModal);

import PropTypes from 'prop-types';
import React from 'react';
import { Panel } from 'react-bootstrap';
import classnames from 'classnames';

import { SectionTitleHeading } from './SectionTitleHeading';
import { Collapse } from '../..';

export const SectionContainer = ({
    children,
    disabled,
    info,
    isOpen,
    meta: { valid },
    number,
    onClick,
    qaTag,
    title,
}) => {
    const toggleHandler = (e) => {
        e.stopPropagation();

        if (disabled) {
            return;
        }

        if (!e.keyCode || e.keyCode === 13) {
            onClick(number);
        }
    };

    const styles = require('./index.scss');

    return (
        <Panel
            className={classnames({
                [styles.panelOpen]: isOpen,
                [styles.panelClosed]: !isOpen,
                [styles.panelDisabled]: disabled,
            })}
        >
            <Panel.Heading
                className={styles.panelHeader}
                data-qa={qaTag}
                onClick={toggleHandler}
                onKeyDown={toggleHandler}
                tabIndex={0}
            >
                <SectionTitleHeading
                    info={info}
                    isOpen={isOpen}
                    number={number}
                    showCheckMark={valid && !isOpen}
                    showInfo={!!info && !isOpen}
                    title={title}
                    toggleHandler={toggleHandler}
                />
            </Panel.Heading>
            <Collapse in={isOpen}>
                <Panel.Body>{isOpen && children}</Panel.Body>
            </Collapse>
        </Panel>
    );
};

SectionContainer.propTypes = {
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    info: PropTypes.node,
    isOpen: PropTypes.bool.isRequired,
    meta: PropTypes.shape({
        valid: PropTypes.bool.isRequired,
    }).isRequired,
    number: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    qaTag: PropTypes.string,
    title: PropTypes.string.isRequired,
};

import React, { useContext } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { SDv2Drawer, CDSButton } from '../../../../../../../components';
import { TemplateEditV2FunctionsContext } from '../../../context';
import { TemplateEditV2ManageSectionsDrawerContent } from './DrawerContent';

export const TemplateEditV2ManageSectionsActionHeader = ({ disabled }) => {
    const { manageSectionsDrawerOpened, toggleManageSectionsDrawerOpened } = useContext(
        TemplateEditV2FunctionsContext
    );

    const styles = require('./index.scss');

    return (
        <>
            {createPortal(
                <SDv2Drawer
                    onBackdropClick={() => toggleManageSectionsDrawerOpened()}
                    open={manageSectionsDrawerOpened}
                >
                    <TemplateEditV2ManageSectionsDrawerContent />
                </SDv2Drawer>,

                document.body
            )}
            <div className={classnames(styles.container, styles.containerBordered)}>
                <div className={styles.col}>
                    <h4>Document Outline</h4>
                </div>
                <div className={classnames(styles.col, styles.textRight)}>
                    <CDSButton
                        disabled={disabled}
                        onClick={(e) => {
                            e.stopPropagation();

                            toggleManageSectionsDrawerOpened();
                        }}
                        qaTag="templateEditor-manageSections"
                        size="small"
                        variant="secondary"
                    >
                        Manage Sections
                    </CDSButton>
                </div>
            </div>
        </>
    );
};

TemplateEditV2ManageSectionsActionHeader.propTypes = {
    disabled: PropTypes.bool,
};

import { capitalDesignTokens } from '@opengov/capital-mui-theme';

export const headerStyle = {
    backgroundColor: capitalDesignTokens.semanticColors.background.primary,
};

export const iconStyle = {
    color: capitalDesignTokens.semanticColors.dataVisualization.sequence400,
    fontSize: capitalDesignTokens.foundations.typography.fontSize.h3,
};

export const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    padding: 3,
};

export const groupHeaderStyle = {
    paddingBottom: 0.5,
};

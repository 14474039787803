import { createSelector } from 'reselect';

import { getUserJS } from '../../containers/selectors';

const getProject = (state, props) => props.project;

export const isSubscribedToProject = createSelector([getUserJS, getProject], (user, project) => {
    if (user && project) {
        return project.followers.some((follower) => follower.id === user.id);
    }
    return false;
});

export const isVendor = createSelector([getUserJS], (user) => {
    if (user) {
        return user.isVendor;
    }
    return false;
});

export const getSubscribers = createSelector([getProject], (project) => {
    if (project) {
        return project.followers;
    }
    return [];
});

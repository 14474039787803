import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { AdminUserListModal } from './AdminUserListModal';
import { showAdminUsersModal } from '../../../actions/admin';

const mapDispatchToProps = {
    showModal: showAdminUsersModal,
};

// @connect
class ConnectedAdminUserListButton extends Component {
    static propTypes = {
        className: PropTypes.string,
        permissionType: PropTypes.string.isRequired,
        showModal: PropTypes.func.isRequired,
        text: PropTypes.string,
    };

    static defaultProps = {
        className: '',
        text: 'see admins',
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const { className, permissionType, showModal, text } = this.props;

        return (
            <>
                <div className={`pseudoLink ${this.styles.link} ${className}`} onClick={showModal}>
                    {text}
                </div>
                <AdminUserListModal permissionType={permissionType} />
            </>
        );
    }
}

export const AdminUserListButton = connect(null, mapDispatchToProps)(ConnectedAdminUserListButton);

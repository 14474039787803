import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormControl } from 'react-bootstrap';
import { v4 as UUIDv4 } from 'uuid';

import { QuestionPrompt } from '../../SharedDisplay';

export class ShortAnswerDisplay extends PureComponent {
    static propTypes = {
        isDocx: PropTypes.bool,
    };

    static defaultProps = {
        isDocx: false,
    };

    render() {
        const { isDocx } = this.props;

        const id = `shortAnswerResponseDisplay-${UUIDv4()}`;

        if (isDocx) {
            return <QuestionPrompt {...this.props} />;
        }

        return (
            <QuestionPrompt htmlFor={id} {...this.props}>
                <FormControl disabled id={id} placeholder="Enter response" type="text" />
            </QuestionPrompt>
        );
    }
}

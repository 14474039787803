import { OpenInNew as OpenInNewIcon } from '@mui/icons-material';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import { Box, Typography } from '@og-pro/ui';
import { lowerCase, upperFirst } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { singleLineWithEllipsis } from '../../../styles';

export const RelationItem = ({ header, href, info, status, statusClass, title, variant }) => {
    const useCondensedLinkStyle = !!header || variant === 'secondary';
    const linkStyle = useCondensedLinkStyle
        ? {
              fontSize: capitalDesignTokens.foundations.typography.fontSize.bodySmall,
              fontWeight: capitalDesignTokens.foundations.typography.fontWeight.normal,
          }
        : {
              fontSize: capitalDesignTokens.foundations.typography.fontSize.bodyDefault,
              fontWeight: capitalDesignTokens.foundations.typography.fontWeight.medium,
          };

    return (
        <Box display="flex" flexDirection="column" gap={0.5}>
            <Box
                display="flex"
                fontSize={capitalDesignTokens.foundations.typography.fontSize.bodyXSmall}
                gap={0.5}
            >
                <Box flex={1}>{info}</Box>
                <Box
                    className={`text-${statusClass}`}
                    fontWeight={capitalDesignTokens.foundations.typography.fontWeight.semiBold}
                >
                    {upperFirst(lowerCase(status))}
                </Box>
            </Box>
            {header && <Typography variant="h4">{header}</Typography>}
            <Box
                component="a"
                href={href}
                rel="noreferrer"
                sx={{ ...linkStyle, ...singleLineWithEllipsis }}
                target="_blank"
            >
                {title}
                <OpenInNewIcon sx={{ fontSize: '1em', marginLeft: 0.5 }} />
            </Box>
        </Box>
    );
};

RelationItem.propTypes = {
    header: PropTypes.string,
    href: PropTypes.string.isRequired,
    info: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    statusClass: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    variant: PropTypes.string,
};

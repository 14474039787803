import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Field } from 'redux-form';

import { fieldNames, templateFieldNames, timelineConfigFieldNames } from './constants';
import {
    AttachmentUploader,
    Button,
    HelpToolTip,
    InputText,
    SearchSelect,
    SearchSelectIconOption,
    SearchSelectIconValue,
    SearchSelectUserOption,
    Toggle,
} from '../../../../../components';
import { iconSelectOptions } from '../../../../../constants/templateAdmin';
import { limitTextLength } from '../../../../../Forms/normalizers';
import {
    helpAutoBidUnsealed,
    helpPublicPricingResults,
    helpPublicResponsesResults,
    helpSealedBid,
    publicPricingDisplayOptions,
    helpPrivateBid,
    helpRequiredInvitation,
} from '../../../ProjectPostCreate/ProjectTimeline/constants';

const {
    HAS_SEALED_BID,
    IS_AUTO_BID_UNSEALED,
    IS_PRIVATE,
    IS_PUBLIC_BID_PRICING_RESULT,
    IS_PUBLIC_BID_RESULT,
    IS_PUBLIC_BID_TOTAL_ONLY,
    REQUIRES_INVITATION,
    TEMPLATE,
    TIMELINE_CONFIG,
    USE_MANUAL_NUMBERING,
    USE_SECTION_DIVIDERS,
    USER_ID,
} = fieldNames;

const {
    DOCX_ATTACHMENT_ID,
    FORCE_SEALED_BID_STATUS,
    ICON,
    IS_GLOBAL_CREATE,
    IS_REVERSE_AUCTION,
    OMIT_DOCX,
    PROCESS_ACRONYM,
    PROCUREMENT_TAG_ID,
    SUBSTITUTION_REQUEST_FORM_URL,
    TITLE,
} = templateFieldNames;

const { HAS_AUCTION_END_DATE, PROPOSAL_DEADLINE_NAME } = timelineConfigFieldNames;

export class TemplateForm extends PureComponent {
    static propTypes = {
        change: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        docxTemplateSelectOptions: PropTypes.array.isRequired,
        forceSealedBidStatus: PropTypes.bool,
        hasSealedBid: PropTypes.bool,
        isPublicBidPricingResult: PropTypes.bool,
        isPrivate: PropTypes.bool,
        isReverseAuction: PropTypes.bool,
        isSolicitation: PropTypes.bool.isRequired,
        omitDocx: PropTypes.bool,
        procurementClassificationSelectOptions: PropTypes.array.isRequired,
        projectId: PropTypes.number.isRequired,
        showFormValidation: PropTypes.bool,
        substitutionRequestFormUrl: PropTypes.string,
        toggleUseSectionDividers: PropTypes.func.isRequired,
        userOptions: PropTypes.array.isRequired,
        usesCustomDocxTemplate: PropTypes.bool,
        usesDocxHeadingFormatters: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            showDocxTemplateSelectForm: props.usesCustomDocxTemplate,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    handleSubstitutionFormUpload = (file) => {
        const { change } = this.props;
        const formUrl = `https://${file.bucket}.s3.amazonaws.com/${file.path}`;

        // Prevents state update on unmounted component error from React
        setTimeout(() => {
            return change(`${TEMPLATE}.${SUBSTITUTION_REQUEST_FORM_URL}`, formUrl);
        }, 1);
    };

    deleteSubstitutionFormUrl = () => {
        const { change } = this.props;

        change(`${TEMPLATE}.${SUBSTITUTION_REQUEST_FORM_URL}`, null);
    };

    normalizeProcessAcronym = limitTextLength(16);

    normalizeTitle = limitTextLength(128);

    reverseAuctionChangeHandler = (e, value) => {
        const { change } = this.props;

        change(`${TIMELINE_CONFIG}.${HAS_AUCTION_END_DATE}`, value);
        change(`${TEMPLATE}.${FORCE_SEALED_BID_STATUS}`, value);
        change(HAS_SEALED_BID, value);
        change(IS_AUTO_BID_UNSEALED, value);

        change(
            `${TIMELINE_CONFIG}.${PROPOSAL_DEADLINE_NAME}`,
            value ? 'Initial Bid Due' : 'Response Submission Deadline'
        );
    };

    useSectionDividersChangeHandler = (e, checked) => {
        const { toggleUseSectionDividers } = this.props;

        e.preventDefault();
        toggleUseSectionDividers(checked);
    };

    showDocxTemplateSelectForm = () => {
        this.setState({ showDocxTemplateSelectForm: true });
    };

    useDefaultDocxTemplate = () => {
        const { change } = this.props;

        this.setState({ showDocxTemplateSelectForm: false });
        change(`${TEMPLATE}.${DOCX_ATTACHMENT_ID}`, null);
    };

    render() {
        const {
            change,
            disabled,
            docxTemplateSelectOptions,
            forceSealedBidStatus,
            hasSealedBid,
            isPublicBidPricingResult,
            isPrivate,
            isReverseAuction,
            isSolicitation,
            omitDocx,
            procurementClassificationSelectOptions,
            projectId,
            showFormValidation,
            substitutionRequestFormUrl,
            userOptions,
            usesDocxHeadingFormatters,
        } = this.props;

        const { showDocxTemplateSelectForm } = this.state;

        return (
            <>
                <div className="row">
                    <div className="col-md-6">
                        <Field
                            component={InputText}
                            disabled={disabled}
                            hasFeedback={false}
                            help="Name used to identify the template"
                            label="Template Title"
                            name={`${TEMPLATE}.${TITLE}`}
                            normalize={this.normalizeTitle}
                            placeholder="Request For Proposal"
                            qaTag="templateForm-templateTitle"
                            showValidation={showFormValidation}
                            type="text"
                        />
                    </div>
                    <div className="col-md-3">
                        <Field
                            component={InputText}
                            disabled={disabled}
                            hasFeedback={false}
                            help="Short hand name"
                            label="Acronym / Abbreviation"
                            name={`${TEMPLATE}.${PROCESS_ACRONYM}`}
                            normalize={this.normalizeProcessAcronym}
                            placeholder="RFP"
                            qaTag={`templateForm-${PROCESS_ACRONYM}`}
                            showValidation={showFormValidation}
                            type="text"
                        />
                    </div>
                    <div className="col-md-3">
                        <Field
                            component={SearchSelect}
                            disabled={disabled}
                            help="Choose from your tags (*optional)"
                            isClearable
                            label="Procurement Classification*"
                            name={`${TEMPLATE}.${PROCUREMENT_TAG_ID}`}
                            options={procurementClassificationSelectOptions}
                            placeholder="Select from tags..."
                            qaTag="templateForm-procurementTag"
                            showValidation={showFormValidation}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Field
                            component={SearchSelect}
                            components={{
                                Option: SearchSelectIconOption,
                                SingleValue: SearchSelectIconValue,
                            }}
                            disabled={disabled}
                            help="Visual identifier for the template"
                            label="Icon"
                            name={`${TEMPLATE}.${ICON}`}
                            options={iconSelectOptions}
                            placeholder="Select an icon to use"
                            qaTag="templateForm-templateIcon"
                            showValidation={showFormValidation}
                        />
                    </div>
                    <div className="col-md-6">
                        <Field
                            backspaceRemovesValue={false}
                            component={SearchSelect}
                            components={{
                                Option: SearchSelectUserOption,
                            }}
                            disabled={disabled}
                            help="User who owns the template (must be an admin)"
                            label="Owner"
                            name={USER_ID}
                            options={userOptions}
                            placeholder="Select the template owner"
                            qaTag="templateForm-templateOwner"
                            showValidation={showFormValidation}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        {isSolicitation && (
                            <Field
                                component={Toggle}
                                inverseToggleDisplay
                                label="Generate Word Document for this template"
                                labelInline
                                name={`${TEMPLATE}.${OMIT_DOCX}`}
                                qaTag="templateForm-generateWordDocument"
                                tooltip="Automatically generates a project document PDF when projects are posted using the Word Document below. When disabled, the user will be responsible for uploading the project documents."
                            />
                        )}
                        {!omitDocx &&
                            (showDocxTemplateSelectForm ? (
                                <>
                                    <Button
                                        aria-label="Use Default"
                                        bsStyle="link"
                                        className="pull-right"
                                        onClick={this.useDefaultDocxTemplate}
                                        qaTag="templateForm-useDefaultWordDocument"
                                        zeroPadding
                                    >
                                        <i className="fa fa-bookmark" /> Use Default
                                    </Button>
                                    <Field
                                        component={SearchSelect}
                                        disabled={disabled}
                                        help="Word document to use when exporting"
                                        label="Word Document"
                                        name={`${TEMPLATE}.${DOCX_ATTACHMENT_ID}`}
                                        options={docxTemplateSelectOptions}
                                        placeholder="Select Word document to use"
                                        qaTag="templateForm-selectWordDocument"
                                        showValidation={showFormValidation}
                                    />
                                </>
                            ) : (
                                <>
                                    Using default Word template &nbsp;
                                    <Button
                                        aria-label="Edit"
                                        bsStyle="link"
                                        onClick={this.showDocxTemplateSelectForm}
                                        qaTag="templateForm-editWordDocument"
                                        zeroPadding
                                    >
                                        <i className="fa fa-pencil" /> Edit
                                    </Button>
                                </>
                            ))}
                    </div>
                    {isSolicitation && (
                        <div className="col-md-6">
                            <span className={this.styles.labelText}>
                                Request for Substitution Form
                            </span>
                            <HelpToolTip
                                isPopover
                                text={
                                    substitutionRequestFormUrl
                                        ? 'You have a Request for Substitution form allowing vendors to submit requests (ex. brand alternates) until the Q & A period ends. Click Remove to disallow this or to upload a new form.'
                                        : 'Upload your Request for Substitution form to allow vendors to submit requests (ex. brand alternates) until the Q & A period ends.'
                                }
                            />
                            {substitutionRequestFormUrl ? (
                                <>
                                    <Field
                                        component={InputText}
                                        disabled
                                        name={`${TEMPLATE}.${SUBSTITUTION_REQUEST_FORM_URL}`}
                                        qaTag={`templateForm-${SUBSTITUTION_REQUEST_FORM_URL}`}
                                        type="text"
                                    />
                                    <a
                                        href={substitutionRequestFormUrl}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        <Button
                                            aria-label="View"
                                            className={this.styles.subFormButton}
                                            qaTag="templateForm-viewSubstitutionRequestForm"
                                        >
                                            <i className="fa fa-external-link" /> View
                                        </Button>
                                    </a>
                                    <Button
                                        aria-label="Remove"
                                        bsStyle="danger"
                                        onClick={this.deleteSubstitutionFormUrl}
                                        qaTag="templateForm-removeSubstitutionRequestForm"
                                    >
                                        <i className="fa fa-trash" /> Remove
                                    </Button>
                                </>
                            ) : (
                                <AttachmentUploader
                                    allowSingleAttachmentOnly
                                    label="Form"
                                    onSuccess={this.handleSubstitutionFormUpload}
                                    s3GetUrl={`/templates/${projectId}/sub-req-form/s3`}
                                />
                            )}
                        </div>
                    )}
                </div>
                {isSolicitation && (
                    <div className="row">
                        <div className="col-md-6">
                            <Field
                                component={Toggle}
                                label="Global Creator Access"
                                labelInline
                                name={`${TEMPLATE}.${IS_GLOBAL_CREATE}`}
                                qaTag={`templateForm-${IS_GLOBAL_CREATE}`}
                                tooltip="Allows anyone in your organization to create a project using this template. The creator will also be given global edit ability over their created project so they can manage all phases from start to finish."
                            />
                            <Field
                                component={Toggle}
                                label="Reverse Auction"
                                labelInline
                                name={`${TEMPLATE}.${IS_REVERSE_AUCTION}`}
                                onChange={this.reverseAuctionChangeHandler}
                                qaTag={`templateForm-${IS_REVERSE_AUCTION}`}
                                tooltip="Uses reverse auction bid format"
                            />
                            <Field
                                component={Toggle}
                                label="Make Private Bid"
                                labelInline
                                name={`${IS_PRIVATE}`}
                                onChange={(e, value) => {
                                    if (!value) {
                                        change(REQUIRES_INVITATION, false);
                                    }
                                }}
                                qaTag={`templateForm-${IS_PRIVATE}`}
                                tooltip={helpPrivateBid}
                            />
                            {isPrivate && (
                                <Field
                                    component={Toggle}
                                    label="Require Invitation to Access Bid"
                                    labelInline
                                    name={REQUIRES_INVITATION}
                                    qaTag={`templateForm-${REQUIRES_INVITATION}`}
                                    tooltip={helpRequiredInvitation}
                                />
                            )}
                            {usesDocxHeadingFormatters && (
                                <>
                                    <Field
                                        component={Toggle}
                                        label="Use Section Dividers"
                                        labelInline
                                        name={USE_SECTION_DIVIDERS}
                                        onChange={this.useSectionDividersChangeHandler}
                                        qaTag={`templateForm-${USE_SECTION_DIVIDERS}`}
                                        tooltip="Allow section dividers to be added"
                                    />
                                    <Field
                                        component={Toggle}
                                        label="Use Manual Numbering"
                                        labelInline
                                        name={USE_MANUAL_NUMBERING}
                                        qaTag={`templateForm-${USE_MANUAL_NUMBERING}`}
                                        tooltip="Allow manual numbering to be added to dividers, sections, and items"
                                    />
                                </>
                            )}
                        </div>
                        <div className="col-md-6">
                            <span className={this.styles.labelText}>Sealed Bid</span>
                            <Field
                                component={Toggle}
                                disabled={isReverseAuction}
                                label="Force Sealed Bid Status?"
                                labelInline
                                name={`${TEMPLATE}.${FORCE_SEALED_BID_STATUS}`}
                                qaTag={`templateForm-${FORCE_SEALED_BID_STATUS}`}
                                tooltip="Sealed bid settings set here will be used for projects created from this template. These will unchangeable on the project for everyone except template admins."
                            />
                            {forceSealedBidStatus && (
                                <div>
                                    <Field
                                        component={Toggle}
                                        disabled={isReverseAuction}
                                        label="Use Sealed Bid Process?"
                                        labelInline
                                        name={HAS_SEALED_BID}
                                        qaTag={`templateForm-${HAS_SEALED_BID}`}
                                        tooltip={helpSealedBid}
                                    />
                                    {hasSealedBid && (
                                        <div className={this.styles.sealedBidOptions}>
                                            <h5>Sealed Bid Options</h5>
                                            <Field
                                                component={Toggle}
                                                disabled={isReverseAuction}
                                                label="Automatically Unseal Bid?"
                                                labelInline
                                                name={IS_AUTO_BID_UNSEALED}
                                                qaTag={`templateForm-${IS_AUTO_BID_UNSEALED}`}
                                                tooltip={helpAutoBidUnsealed}
                                            />
                                            <Field
                                                component={Toggle}
                                                label="Publish Vendor Pricing Results?"
                                                labelInline
                                                name={IS_PUBLIC_BID_PRICING_RESULT}
                                                tooltip={helpPublicPricingResults}
                                            />
                                            {isPublicBidPricingResult && (
                                                <Field
                                                    component={SearchSelect}
                                                    isSearchable={false}
                                                    name={IS_PUBLIC_BID_TOTAL_ONLY}
                                                    options={publicPricingDisplayOptions}
                                                    qaTag={`templateForm-${IS_PUBLIC_BID_TOTAL_ONLY}`}
                                                />
                                            )}
                                            <Field
                                                component={Toggle}
                                                label="Publish Vendor Responses?"
                                                labelInline
                                                name={IS_PUBLIC_BID_RESULT}
                                                qaTag={`templateForm-${IS_PUBLIC_BID_RESULT}`}
                                                tooltip={helpPublicResponsesResults}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </>
        );
    }
}

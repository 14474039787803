import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export class ProposalSectionTitle extends PureComponent {
    static propTypes = {
        isDocx: PropTypes.bool,
        printButton: PropTypes.node,
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    };

    static defaultProps = {
        printButton: undefined,
    };

    get styles() {
        return require('../../index.scss');
    }

    render() {
        const { isDocx, printButton, title } = this.props;

        if (isDocx) {
            return <h3 style={{ marginTop: 40 }}>{title}</h3>;
        }

        return (
            <div className={this.styles.sectionHeader}>
                {title}
                {printButton}
            </div>
        );
    }
}

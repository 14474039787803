import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Box, Chip, Typography } from '@og-pro/ui';

import { getReviewGeneralInformationData } from './selectors';
import { getFiscalYearSelectOptions } from '../../../../../selectors';
import { UserProfilePicture } from '../../../../../../components';
import { getIsRequisitionUsingFMS, getRequisitionJS } from '../../../../../../selectors/govApp';
import { CategoryCodesChipList } from '../../FormComponents/GeneralInformation/CategoryCodesChipList';

export const ReviewGeneralInformation = () => {
    const sharedStyles = require('../index.scss');

    const { creator, reviewGroup, requestor: requestorData } = useSelector(getRequisitionJS);
    const data = useSelector(getReviewGeneralInformationData);
    const hasFMS = useSelector(getIsRequisitionUsingFMS);

    const fiscalYearSelectOptions = useSelector(getFiscalYearSelectOptions);

    return (
        <>
            <Box className="row" marginBottom={2}>
                <div className={classNames('col-sm-8 col-xs-6', sharedStyles.field)}>
                    <h5 className={sharedStyles.label}>Name of Request </h5>
                    <span>{data.descriptionOfRequest || 'None'}</span>
                </div>
                <div className={classNames('col-sm-3 col-xs-6', sharedStyles.field)}>
                    <h5 className={sharedStyles.label}>Created On</h5>
                    <span>{moment(data.createdAt).format('MM/DD/YYYY')}</span>
                </div>
            </Box>
            <Box className="row" marginBottom={2}>
                <div className={classNames('col-sm-12', sharedStyles.field)}>
                    <h5 className={sharedStyles.label}>Summary of Request</h5>
                    <span>{data.summary || 'None'}</span>
                </div>
            </Box>
            {data.background && (
                <Box className="row" marginBottom={2}>
                    <div className={classNames('col-sm-12', sharedStyles.field)}>
                        <h5 className={sharedStyles.label}>Background</h5>
                        <span>{data.background}</span>
                    </div>
                </Box>
            )}
            {!data.background && (!data.categories || data.categories.length === 0) ? (
                <Box className="row" marginBottom={2}>
                    <div className={classNames('col-sm-12', sharedStyles.field)}>
                        <h5 className={sharedStyles.label}>No Background or Category Codes</h5>
                    </div>
                </Box>
            ) : (
                <>
                    {!data.background && (
                        <Box className="row" marginBottom={2}>
                            <div className={classNames('col-sm-12', sharedStyles.field)}>
                                <h5 className={sharedStyles.label}>No Background</h5>
                            </div>
                        </Box>
                    )}
                    {!data.categories ||
                        (data.categories.length === 0 && (
                            <Box className="row" marginBottom={2}>
                                <div className={classNames('col-sm-12', sharedStyles.field)}>
                                    <h5 className={sharedStyles.label}>No Category Codes</h5>
                                </div>
                            </Box>
                        ))}
                </>
            )}
            {data.categories && data.categories.length > 0 && (
                <Box className="row" marginBottom={2}>
                    <div className={classNames('col-sm-12', sharedStyles.field)}>
                        <Typography
                            alignItems="baseline"
                            className={sharedStyles.label}
                            display="flex"
                            gap={0.5}
                            variant="h5"
                        >
                            Category Codes
                            <Chip
                                label={data.categories.length}
                                size="extraSmall"
                                variant="filled"
                            />
                        </Typography>
                        <CategoryCodesChipList codes={data.categories} />
                    </div>
                </Box>
            )}
            <Box className="row" marginBottom={2}>
                <div className={classNames('col-md-4 col-xs-6', sharedStyles.field)}>
                    <h5 className={sharedStyles.label}>Requestor</h5>
                    <div className={sharedStyles.requestorContainer}>
                        <UserProfilePicture user={requestorData} />
                        <span>{requestorData?.displayName}</span>
                    </div>
                </div>
                <div className={classNames('col-md-4 col-xs-6', sharedStyles.field)}>
                    <h5 className={sharedStyles.label}>Approval Group</h5>
                    <span>{reviewGroup.name}</span>
                </div>
                <div className={classNames('col-md-4 col-xs-6', sharedStyles.field)}>
                    <h5 className={sharedStyles.label}>Creator</h5>
                    <div className={sharedStyles.requestorContainer}>
                        <UserProfilePicture user={creator} />
                        <span>{creator.displayName}</span>
                    </div>
                </div>
            </Box>
            <div className="row">
                {hasFMS && (
                    <div className={classNames('col-md-4 col-xs-6', sharedStyles.field)}>
                        <h5 className={sharedStyles.label}>Expected Purchase Order Date</h5>
                        <span>{moment(data.expectedPurchaseOrderDate).format('MM/DD/YYYY')}</span>
                    </div>
                )}
                <div className={classNames('col-md-4 col-xs-6', sharedStyles.field)}>
                    <h5 className={sharedStyles.label}>Desired Delivery Date</h5>
                    <span>{moment(data.desiredDeliveryDate).format('MM/DD/YYYY')}</span>
                </div>
                <div className={classNames('col-md-4 col-xs-12', sharedStyles.field)}>
                    <h5 className={classNames(sharedStyles.label, sharedStyles.budgetYearLabel)}>
                        Fiscal Year
                    </h5>
                    <span className={sharedStyles.textMuted}>
                        {hasFMS
                            ? data.fiscalPeriod
                            : fiscalYearSelectOptions.find(
                                  (option) => option.value === data.fiscalPeriodTagId
                              )?.label}
                    </span>
                </div>
            </div>
        </>
    );
};

ReviewGeneralInformation.propTypes = {
    data: PropTypes.shape({
        requestor: PropTypes.number,
        expectedPurchaseOrderDate: PropTypes.instanceOf(Date),
        desiredDeliveryDate: PropTypes.instanceOf(Date),
        descriptionOfRequest: PropTypes.string,
    }),
};

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { DIGITAL_STYLE } from '../constants';
import { calculateTimeRemaining, getTimeUnits } from '../helpers';
import { TimeCard } from '../../TimeCard';

export const CountdownTimerDigital = ({ endTime, label }) => {
    const styles = require('./index.scss');

    const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining(endTime));

    const duration = moment.duration(timeRemaining);
    const { days, hours, minutes, seconds } = getTimeUnits(duration, DIGITAL_STYLE);

    const tick = () => setTimeRemaining(calculateTimeRemaining(endTime));

    useEffect(() => {
        let interval;

        if (timeRemaining > 0 && !process.env.SERVER) {
            interval = setInterval(tick, 1000);
        }

        return () => clearInterval(interval);
    }, [timeRemaining]);

    return (
        <div className={styles.timeCardContainer}>
            <div className={styles.labelContainer}>
                <div className={`${styles.timeRemainingLabel}`}>Time Remaining</div>
                {label && <div className={styles.label}>{label}</div>}
            </div>
            <div className={styles.timeCardBoxesContainer}>
                <div>
                    <TimeCard label="DAYS" value={days} />
                </div>
                <div>
                    <TimeCard label="HRS" value={hours} />
                </div>
                <div>
                    <TimeCard label="MINS" value={minutes} />
                </div>
                <div>
                    <TimeCard label="SECS" value={seconds} />
                </div>
            </div>
        </div>
    );
};

CountdownTimerDigital.propTypes = {
    endTime: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
    label: PropTypes.string,
};

import { USER_ID } from './constants';
import { questionnaire } from '../../../../../Forms/validation';

export const validate = (values, props) => {
    const errors = questionnaire(values, props);

    if (!values[USER_ID]) {
        errors[USER_ID] = 'Please select an owner';
    }

    return errors;
};

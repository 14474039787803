export const PHASE = 'phase';

export const evaluationPhaseSetupOptions = [
    {
        text: 'Carry over the current evaluation criteria and scores',
        value: false,
    },
    {
        text: 'Start over with new evaluation criteria and scoring',
        value: true,
    },
    {
        text: 'Start over with a pre-defined evaluation phase',
        value: PHASE,
    },
];

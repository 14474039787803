import { createSelector } from 'reselect';

const getOrganizations = (state) => state.publicProject.get('govList');

export const getGovernmentsJS = createSelector([getOrganizations], (rawOrganizations) => {
    if (rawOrganizations) {
        return rawOrganizations.toJS().map((org) => ({
            organization: org,
            government: org.government,
        }));
    }
    return [];
});

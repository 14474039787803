import { find, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { ReportDocxTable } from '../../../../../components';

export class PassFailReport extends PureComponent {
    static propTypes = {
        headingStyle: PropTypes.object,
        project: PropTypes.shape({
            questionnaires: PropTypes.array.isRequired,
        }).isRequired,
        proposals: PropTypes.arrayOf(
            PropTypes.shape({
                companyName: PropTypes.string.isRequired,
                isDisqualified: PropTypes.bool,
                isExcluded: PropTypes.bool,
                questionnaireResponses: PropTypes.arrayOf(
                    PropTypes.shape({
                        isFailed: PropTypes.bool,
                    })
                ).isRequired,
            })
        ).isRequired,
        useLandscape: PropTypes.bool.isRequired,
    };

    getPassFailColumnDefs = () => {
        const { proposals } = this.props;

        const orderedProposals = orderBy(
            proposals,
            ['isDisqualified', 'isExcluded', 'companyName'],
            ['asc']
        );

        const colDefs = [
            {
                Header: 'Question Title',
                accessor: 'title',
            },
        ];

        orderedProposals.forEach((proposal) => {
            colDefs.push({
                Header: (
                    <div>
                        {proposal.companyName}
                        {proposal.isExcluded && <div>(Excluded)</div>}
                        {proposal.isDisqualified && <div>(Disqualified)</div>}
                    </div>
                ),
                id: proposal.companyName,
                accessor: (row) => {
                    // For title rows we do not want to display any cell information
                    if (row.isTitle) {
                        return '';
                    }

                    const response = find(proposal.questionnaireResponses, [
                        'questionnaire_id',
                        row.id,
                    ]);
                    if (!response) {
                        return 'No Response';
                    }
                    if (response.isFailed) {
                        return 'Fail';
                    }
                    if (response.isFailed === false) {
                        return 'Pass';
                    }
                    return '';
                },
            });
        });

        return colDefs;
    };

    render() {
        const {
            headingStyle,
            project: { questionnaires },
            proposals,
            useLandscape,
        } = this.props;

        const hasPassFail = proposals.some((proposal) => {
            return proposal.questionnaireResponses.some((response) => {
                return typeof response.isFailed === 'boolean';
            });
        });

        if (!hasPassFail) {
            return null;
        }

        return (
            <>
                {headingStyle && <div style={headingStyle}>VENDOR QUESTIONNAIRE PASS/FAIL</div>}
                <ReportDocxTable
                    columns={this.getPassFailColumnDefs()}
                    data={questionnaires.filter((q) => !q.isHiddenByLogic)}
                    useLandscape={useLandscape}
                />
            </>
        );
    }
}

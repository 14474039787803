import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FlagsCreateButton } from './FlagsCreateButton';
import { FlagsModal } from './FlagsModal';
import { FlagsTable } from './FlagsTable';
import connectData from '../../../ConnectData';
import { showConfirmationSimpleModal } from '../../../../actions/confirmation';
import { deleteFlag, loadFlags } from '../../../../actions/requisitionsAdmin';
import {
    LoadingError,
    LoadingSpinner,
    Main,
    SectionTitle,
    ZeroState,
} from '../../../../components';
import { getFlags } from '../../../../selectors/govApp';

const fetchData = (getState, dispatch) => {
    return dispatch(loadFlags());
};

const ConnectedRequisitionsFlags = () => {
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);

    const flags = useSelector(getFlags);
    const isLoading = useSelector((state) => state.requisitionsAdmin.get('loadingFlags'));
    const loadingError = useSelector((state) => state.requisitionsAdmin.get('loadFlagsError'));

    const dispatch = useDispatch();

    const showModalHandler = (data = null) => {
        setModalData(data);
        setShowModal(true);
    };

    const hideModalHandler = () => {
        setModalData(null);
        setShowModal(false);
    };

    const deleteHandler = (flagId) => {
        dispatch(
            showConfirmationSimpleModal(() => dispatch(deleteFlag(flagId)), {
                btnText: 'Delete Flag',
                text: 'Are you sure you want to delete this flag?',
            })
        );
    };

    if (isLoading) {
        return <LoadingSpinner useOpenGovStyle />;
    }

    if (loadingError) {
        return <LoadingError error={loadingError} useOpenGovStyle />;
    }

    return (
        <Main>
            <SectionTitle
                info="Define the items used to determine workflow routing logic"
                title="Flags"
            />
            <div className="row">
                <div className="col-lg-offset-1 col-lg-10">
                    <div className="text-right">
                        <FlagsCreateButton onClick={() => showModalHandler()} />
                    </div>
                    {flags.length === 0 ? (
                        <ZeroState title="No flags have been created yet" useOpenGovStyle />
                    ) : (
                        <FlagsTable
                            flags={flags}
                            onDelete={deleteHandler}
                            onEdit={showModalHandler}
                        />
                    )}
                    {showModal && <FlagsModal flag={modalData} hideModal={hideModalHandler} />}
                </div>
            </div>
        </Main>
    );
};

export const RequisitionsFlags = connectData(fetchData)(ConnectedRequisitionsFlags);

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getUserJS } from '../selectors';
import { logout } from '../../actions/auth';

const mapStateToProps = (state) => {
    return {
        user: getUserJS(state),
        error: state.auth.get('logoutError'),
    };
};

const mapDispatchToProps = {
    logout,
};

/**
 * Dummy container for logging users out. Front end routes users to this page
 * to ensure that any componentWillUnmount hooks are ran before logout happens
 */

// @connect
class ConnectedLogout extends Component {
    static propTypes = {
        error: PropTypes.string,
        logout: PropTypes.func.isRequired,
        user: PropTypes.object,
    };

    componentDidMount() {
        const { user } = this.props;

        // Only log out a logged in user
        if (user) {
            return this.props.logout(!user.isVendor);
        }
    }

    get styles() {
        return require('./index.scss');
    }

    render() {
        const { error } = this.props;

        if (error) {
            return (
                <h4 className={`text-danger ${this.styles.logout}`}>
                    Error logging out. Please try again.
                </h4>
            );
        }
        return <h3 className={`text-info ${this.styles.logout}`}>Logging out...</h3>;
    }
}

export const Logout = connect(mapStateToProps, mapDispatchToProps)(ConnectedLogout);

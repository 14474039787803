import React from 'react';
import { Panel } from 'react-bootstrap';
import PropTypes from 'prop-types';

export const TimeCard = ({ label, value }) => {
    const styles = require('./index.scss');
    return (
        <Panel className={styles.timeCard}>
            <div className={styles.timeCardUnitNumber}>{value}</div>
            <div className={styles.timeCardUnitLabel}>{label}</div>
        </Panel>
    );
};

TimeCard.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

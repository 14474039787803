/**
 * Generates a composite entity query key.
 *
 * @param {string} auditType - The type of the audit.
 * @param {string} entityType - The type of the entity.
 * @param {number} entityId - The identifier of the entity.
 * @returns {string} The composite entity query key.
 */
exports.getCompositeEntityQueryKey = (auditType, entityType, entityId) => {
    return `AUDITTYPE#${auditType}#ENTITYTYPE#${entityType}#ENTITYID#${entityId}`;
};

/**
 * Generates a composite Kafka query key.
 *
 * @param {string} auditType - The type of the audit.
 * @param {number} kafkaOffset - The Kafka offset.
 * @param {number} kafkaPartition - The Kafka partition.
 * @returns {string} The composite Kafka query key.
 */
exports.getCompositeKafkaQueryKey = (auditType, kafkaOffset, kafkaPartition) => {
    return `AUDITTYPE#${auditType}KAFKAOFFSET#${kafkaOffset}#KAFKAPARTITION#${kafkaPartition}`;
};

/**
 * Generates a composite user query key.
 *
 * @param {string} auditType - The type of the audit.
 * @param {string} userEntityType - The type of the user entity.
 * @param {number} userEntityId - The identifier of the user.
 * @returns {string} The composite user query key.
 */
exports.getCompositeUserQueryKey = (auditType, userEntityType, userEntityId) => {
    return `AUDITTYPE#${auditType}USERENTITYTYPE#${userEntityType}#USERID#${userEntityId}`;
};

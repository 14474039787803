import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Chip } from '@og-pro/ui';
import { tokens } from '@opengov/capital-style';

import { CategorySelectModal } from './CategorySelectModal';
import { loadCategoriesById, resetCategoriesSearch } from '../../actions/category';
import { getCategories } from './selectors';

export const CategorySelectButton = ({ categoryIds, onSelect }) => {
    const dispatch = useDispatch();
    const categories = useSelector(getCategories);

    const [showCategorySelectModal, setShowCategorySelectModal] = useState(false);

    useEffect(() => {
        const storedCategoryIds = categories?.map((category) => category.id.toString());
        if (
            categoryIds?.length > 0 &&
            !categoryIds.every((id) => storedCategoryIds?.includes(id))
        ) {
            dispatch(loadCategoriesById(categoryIds));
        } else if (categoryIds?.length === 0 && categories?.length > 0) {
            dispatch(resetCategoriesSearch());
        }
    }, [categoryIds, categories]);

    const selectHandler = (selectedCategories, hasChanged) => {
        setShowCategorySelectModal(false);
        onSelect(selectedCategories, hasChanged);
    };

    return (
        <div>
            <Button
                color="secondary"
                endIcon={
                    categoryIds?.length > 0 ? (
                        <Chip
                            label={categoryIds.length}
                            size="extraSmall"
                            sx={{ fontSize: `${tokens.typography.fontSizeMinimum} !important` }}
                        />
                    ) : null
                }
                onClick={() => setShowCategorySelectModal(true)}
                qaTag="vendorSearch-categories"
                variant="outlined"
            >
                Categories
            </Button>
            <CategorySelectModal
                categories={categories}
                hideModal={() => setShowCategorySelectModal(false)}
                selectHandler={selectHandler}
                showModal={showCategorySelectModal}
            />
        </div>
    );
};

CategorySelectButton.propTypes = {
    categoryIds: PropTypes.array,
    onSelect: PropTypes.func.isRequired,
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';

import { ScoringCriteriaFormDescription } from '../components';
import { CriteriaScore } from '../../../../components';

export class AdminScorecardDisplay extends Component {
    static propTypes = {
        adminScorecardProposalEvaluations: PropTypes.arrayOf(
            PropTypes.shape({
                proposalCriteriaScores: PropTypes.arrayOf(
                    PropTypes.shape({
                        proposal: PropTypes.shape({
                            companyName: PropTypes.string,
                        }).isRequired,
                        score: PropTypes.number,
                    })
                ).isRequired,
                scoringCriterium: PropTypes.shape({
                    description: PropTypes.string,
                    title: PropTypes.string.isRequired,
                }).isRequired,
            })
        ).isRequired,
        totalWeight: PropTypes.number.isRequired,
    };

    get styles() {
        return require('./AdminScorecardDisplay.scss');
    }

    render() {
        const { adminScorecardProposalEvaluations, totalWeight } = this.props;

        const items = adminScorecardProposalEvaluations.map((proposalEvaluationData) => {
            const { proposalCriteriaScores, scoringCriterium } = proposalEvaluationData;

            return (
                <ListGroupItem key={scoringCriterium.id}>
                    <div className="row">
                        <div className="col-sm-4">
                            <ScoringCriteriaFormDescription
                                scoringCriterium={scoringCriterium}
                                totalWeight={totalWeight}
                            />
                        </div>
                        <div className="col-sm-8">
                            {proposalCriteriaScores.map((proposalCriteriaScore) => {
                                const { proposal, score } = proposalCriteriaScore;

                                return (
                                    <div className="row" key={proposal.id}>
                                        <div className={`col-sm-6 ${this.styles.vendorCol}`}>
                                            {proposal.companyName}
                                        </div>
                                        <div className="col-sm-6 text-center">
                                            {proposal.isExcluded ? (
                                                <em>Excluded</em>
                                            ) : (
                                                <CriteriaScore
                                                    criteriaScore={score}
                                                    scoringCriteria={scoringCriterium}
                                                />
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </ListGroupItem>
            );
        });

        return <ListGroup>{items}</ListGroup>;
    }
}

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export class DisplayItems extends PureComponent {
    static propTypes = {
        items: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
            })
        ).isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const { items } = this.props;

        return items.map(({ label, value }, idx) => {
            if (!value) {
                return null;
            }
            return (
                <div className={classnames('row', this.styles.item)} key={idx}>
                    <div className={classnames('col-sm-3 col-md-2 text-muted', this.styles.label)}>
                        {label}
                    </div>
                    <div className="col-sm-9 col-md-10">{value}</div>
                </div>
            );
        });
    }
}

import { mappedProcurementContactFields } from '@og-pro/shared-config/contacts';

import { fieldNames, progressSectionsDict } from './constants';
import {
    arrayError,
    budget,
    contact,
    department,
    financialId,
    projectTimelines,
    questionnaire as questionnaireValidator,
    rawSummary,
    requisitionIdentifier,
    sectionsValidate,
    title,
} from '../../../Forms/validation';
import { hasFormErrors } from '../../../helpers';

const { PROCUREMENT_CONTACT_ID } = fieldNames;
const { OVERVIEW, PROPOSALS, QUESTIONNAIRE, TIMELINE } = progressSectionsDict;

function projectOverviewValidate(values, props) {
    // Skip validation unless it's an evaluation only project
    if (!values.isPostOnly) return {};

    return {
        ...title(values),
        ...financialId(values, props),
        ...requisitionIdentifier(values),
        ...budget(values, props),
        ...rawSummary(values),
        ...contact(values),
        ...contact(values, {
            fieldNames: mappedProcurementContactFields,
            idFieldName: PROCUREMENT_CONTACT_ID,
        }),
        ...department(values),
    };
}

function questionnaireValidate(values) {
    const errors = {};

    if (!values.questionnaires || values.questionnaires.length === 0) {
        errors.questionnaires = arrayError('Please add at least one vendor question');
        return errors;
    }

    errors.questionnaires = values.questionnaires.map((question) =>
        questionnaireValidator(question)
    );

    return errors;
}

function proposalDocumentsValidate(values) {
    const errors = {};

    if (!values.proposalDocuments || values.proposalDocuments.length === 0) {
        errors.proposalDocuments = arrayError('Please add at least one proposal document');
        return errors;
    }

    errors.proposalDocuments = values.proposalDocuments.map((doc) => {
        return title(doc);
    });

    return errors;
}

function timelineValidate(values) {
    const errors = {};
    if (!values.isTimelineConfirmed) {
        errors.isTimelineConfirmed = 'Please confirm the dates are correct';
    }

    return {
        ...errors,
        ...projectTimelines(values, { requireChronologicalOrder: true }),
    };
}

export function validate(values, props) {
    const { hasQuestionnaire } = props;

    // Run validation functions
    const projectOverviewErrors = projectOverviewValidate(values, props);
    const questionnaireErrors = hasQuestionnaire ? questionnaireValidate(values) : {};
    const proposalDocumentsErrors = hasQuestionnaire ? {} : proposalDocumentsValidate(values);
    const timelineErrors = timelineValidate({ ...values, timelineData: props.timelineData });

    const sectionsErrors = sectionsValidate(
        [OVERVIEW, projectOverviewErrors],
        [QUESTIONNAIRE, questionnaireErrors],
        [PROPOSALS, proposalDocumentsErrors],
        [TIMELINE, timelineErrors]
    );
    const formHasErrors = hasFormErrors(sectionsErrors);

    return {
        allSections: formHasErrors,
        ...projectOverviewErrors,
        ...questionnaireErrors,
        ...proposalDocumentsErrors,
        ...timelineErrors,
        ...sectionsErrors,
    };
}

import { orderBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { sectionTypeNames } from '@og-pro/shared-config/sections';

import { SelectedVendor } from '../EvaluationTabulationReport/components/SelectedVendor';
import { QuestionAnswerReport } from '../QuestionAnswerReport';
import { AddendumReport } from '../AddendumReport';
import { EvaluationTabulationReport } from '../EvaluationTabulationReport';
import { PassFailReport } from '../PassFailReport';
import { ProjectOverviewTable } from '../sharedComponents';
import {
    ReportDocxTable,
    ProjectSectionMapper,
    BidTabulationTable,
} from '../../../../../components';
import {
    getProjectSummaryOverviewData,
    getProposalsListColumnDefs,
    statusHistoryColumnDefs,
} from './helpers';
import { evaluationReportOptions } from '../../ReportsModal/constants';

const { INTRODUCTION } = sectionTypeNames;

export class ProjectSummaryReport extends PureComponent {
    static propTypes = {
        addendums: PropTypes.array.isRequired,
        addendumsProposals: PropTypes.array.isRequired,
        agreementData: PropTypes.object,
        bidTabulationData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        evaluationReportData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        options: PropTypes.object.isRequired,
        project: PropTypes.object.isRequired,
        projectStatusHistoryData: PropTypes.array.isRequired,
        proposalsListData: PropTypes.array.isRequired,
        proposalsWithQuestionnaireResponses: PropTypes.array.isRequired,
        questions: PropTypes.array.isRequired,
    };

    render() {
        const {
            addendums,
            addendumsProposals,
            agreementData,
            bidTabulationData,
            evaluationReportData,
            options,
            options: { reportOption, showAnonymizedEvaluators, showPercentageTotals, useLandscape },
            project,
            projectStatusHistoryData,
            proposalsListData,
            proposalsWithQuestionnaireResponses,
            questions,
        } = this.props;

        const headingStyle = {
            fontSize: 24,
            fontWeight: 'bold',
            marginTop: 15,
            textAlign: 'center',
        };
        const timezone = project.government.organization.timezone;
        const introSectionId = project.projectSections.find(
            (section) => section.section_type === INTRODUCTION
        )?.id;
        const projectPath = `/governments/${project.government_id}/projects/${project.id}/intake`;
        const proposalOrderBy = ['isDisqualified', 'isExcluded', 'companyName'];

        const {
            bidTabulations,
            isPricingSealed,
            proposalsData,
            selectedBidTabulationPriceItemIds,
        } = bidTabulationData ?? {};
        const isSummary = reportOption === evaluationReportOptions.SUMMARY;

        let docxSize;
        if (isSummary) {
            docxSize = 'summary';
        } else if (useLandscape) {
            docxSize = 'landscape';
        } else {
            docxSize = 'portrait';
        }

        return (
            <>
                <div style={headingStyle}>SOLICITATION OVERVIEW</div>
                <ProjectOverviewTable
                    rows={getProjectSummaryOverviewData(project, showAnonymizedEvaluators)}
                    useLandscape={useLandscape}
                />
                <ProjectSectionMapper
                    isDocx
                    project={project}
                    projectPath={projectPath}
                    projectSectionId={introSectionId}
                />
                <div style={headingStyle}>SOLICITATION STATUS HISTORY</div>
                <ReportDocxTable
                    columns={statusHistoryColumnDefs}
                    data={projectStatusHistoryData}
                    useLandscape={useLandscape}
                />
                {evaluationReportData && (
                    <SelectedVendor
                        headingStyle={headingStyle}
                        project={project}
                        showPercentageTotals={showPercentageTotals}
                        useLandscape={useLandscape}
                    />
                )}
                <div style={headingStyle}>PROPOSALS RECEIVED</div>
                <ReportDocxTable
                    columns={getProposalsListColumnDefs(timezone)}
                    data={orderBy(proposalsListData, proposalOrderBy, ['asc'])}
                    useLandscape={useLandscape}
                />
                <PassFailReport
                    headingStyle={headingStyle}
                    project={project}
                    proposals={proposalsWithQuestionnaireResponses}
                    useLandscape={useLandscape}
                />
                {bidTabulationData && !isPricingSealed && !isSummary && (
                    <>
                        <div style={headingStyle}>PRICING RESPONSES</div>
                        {bidTabulations.map((bidTabulation, i) => {
                            return (
                                <BidTabulationTable
                                    auctionMaxFractionDigits={
                                        project.template.isReverseAuction
                                            ? project.auctionMaxFractionDigits
                                            : null
                                    }
                                    bidTabulation={bidTabulation}
                                    defaultTitle={`Table ${i + 1}`}
                                    docxSize={docxSize}
                                    includeSelectedColumn={false}
                                    initiallySelectedPriceItemIds={
                                        selectedBidTabulationPriceItemIds
                                    }
                                    isDocx
                                    key={i + 1}
                                    proposalsData={proposalsData}
                                    salesTax={project.government.salesTax}
                                    showCustomColumns={!isSummary}
                                    showTotalOnly={isSummary}
                                    sortAscending={project.evaluation.bidTabulationSortAsc}
                                    totalAll
                                    useLandscape={useLandscape}
                                />
                            );
                        })}
                    </>
                )}
                <div style={headingStyle}>QUESTIONS AND ANSWERS</div>
                <QuestionAnswerReport project={project} questions={questions} />
                <div style={headingStyle}>ADDENDA & NOTICES</div>
                <AddendumReport
                    addendums={addendums}
                    isRenderedBySummaryReport
                    project={project}
                    proposals={addendumsProposals}
                />
                {evaluationReportData && (
                    <>
                        <div style={headingStyle}>EVALUATION</div>
                        <EvaluationTabulationReport
                            agreementData={agreementData}
                            data={evaluationReportData}
                            isRenderedBySummaryReport
                            options={options}
                            project={project}
                        />
                    </>
                )}
            </>
        );
    }
}

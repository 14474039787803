import { createSelector } from 'reselect';

import { financialSystems } from '@og-pro/shared-config/financialSystems';

import { approvalStatusTypes } from '@og-pro/shared-config/requisitions';

import { reviewTypes } from '@og-pro/shared-config/reviewSequences';

import { getIsGovernmentUsingFMS } from './government';
import { getLiveRequisitionGroups } from '../../containers/Requisitions/RequisitionGroups/RequisitionGroupsList/selectors';
import { getUserJS, isRequisitionGlobalEditorUser } from '../../containers/selectors';

export const getRequisitionIsLoading = (state) => state.requisitions.get('loadingRequisition');
export const getRequisitionLoadError = (state) => state.requisitions.get('loadRequisitionError');

export const getRequisitionIsUpdating = (state) => state.requisitions.get('updatingRequisition');
export const getRequisitionUpdateError = (state) =>
    state.requisitions.get('updateRequisitionError');

export const getRequisitionIsCopying = (state) => state.requisitions.get('copyingRequisition');
export const getRequisitionCopyError = (state) => state.requisitions.get('copyRequisitionError');

export const getRequisitionIsBudgetChecking = (state) =>
    state.requisitions.get('loadingBudgetCheck');
export const getRequisitionBudgetCheckError = (state) =>
    state.requisitions.get('loadBudgetCheckError');

const getRawVendorAssignmentModal = (state) => state.requisitions.get('vendorAssignmentModal');

const getRawRequisitionBudgetCheckAlertModal = (state) =>
    state.requisitions.get('budgetCheckAlertModal');

const getRawRequisitionBudgetCheckResults = (state) => state.requisitions.get('budgetCheckResults');

const getRawRequisitionApprovalModalData = (state) => state.requisitions.get('approvalModalData');

const getRawRequisition = (state) => state.requisitions.get('requisition');

const getRawReviewSequenceSteps = (state) => state.requisitions.get('reviewSequenceSteps');

export const getRequisitionJS = createSelector([getRawRequisition], (rawRequisition) => {
    if (rawRequisition) {
        return rawRequisition.toJS();
    }
});

export const getVendorAssignmentModal = createSelector(
    [getRawVendorAssignmentModal],
    (rawVendorAssignmentModal) => {
        if (rawVendorAssignmentModal) {
            return rawVendorAssignmentModal.toJS();
        }
    }
);

export const getRequisitionBudgetCheckAlertModal = createSelector(
    [getRawRequisitionBudgetCheckAlertModal],
    (rawRequisitionBudgetCheckAlertModal) => {
        if (rawRequisitionBudgetCheckAlertModal) {
            return rawRequisitionBudgetCheckAlertModal.toJS();
        }
    }
);

export const getRequisitionBudgetCheckResults = createSelector(
    [getRawRequisitionBudgetCheckResults],
    (rawRequisitionBudgetCheckResults) => {
        if (rawRequisitionBudgetCheckResults) {
            return rawRequisitionBudgetCheckResults.toJS();
        }
        return {};
    }
);

export const getRequisitionApprovalModalData = createSelector(
    [getRawRequisitionApprovalModalData],
    (rawRequisitionApprovalModalData) => {
        return rawRequisitionApprovalModalData.toJS();
    }
);

export const getIsRequisitionUsingFMS = createSelector(
    [getRequisitionJS, getIsGovernmentUsingFMS],
    (requisition, isGovernmentUsingFMS) => {
        return (
            requisition?.financialSystemIntegration !== financialSystems.NONE &&
            isGovernmentUsingFMS
        );
    }
);

export const getLiveUserRequisitionGroups = createSelector(
    [getUserJS, getLiveRequisitionGroups],
    (user, requisitionGroups) => {
        return requisitionGroups.filter((requisitionGroup) => {
            const { creators } = requisitionGroup;
            return creators.some((creator) => creator.id === user.id);
        });
    }
);

export const getLeaderUserRequisitionGroups = createSelector(
    [getUserJS, getLiveRequisitionGroups, isRequisitionGlobalEditorUser],
    (user, requisitionGroups, isRequisitionGlobalEditor) => {
        if (isRequisitionGlobalEditor) {
            return requisitionGroups;
        }

        return requisitionGroups.filter((requisitionGroup) => {
            const { leaders } = requisitionGroup;
            return leaders.some((leader) => leader.id === user.id);
        });
    }
);

export const hasRequisitionCreatorAccess = createSelector(
    [getUserJS, getRequisitionJS, isRequisitionGlobalEditorUser],
    (user, requisition, isRequisitionGlobalEditor) =>
        requisition.creator_id === user.id ||
        requisition.requestor_id === user.id ||
        isRequisitionGlobalEditor
);

const isLastApprovalForStep = (reviewSequenceStep) => {
    const approvalsReceived = reviewSequenceStep.stepApproval.stepPositionApprovals.filter(
        ({ status }) => status === approvalStatusTypes.APPROVED
    ).length;
    return reviewSequenceStep.minPositionReviews - approvalsReceived <= 1;
};

export const getReviewSequenceSteps = createSelector(
    [getRawReviewSequenceSteps],
    (rawReviewSequenceSteps) => {
        const reviewSequenceSteps = rawReviewSequenceSteps
            .toJS()
            .filter((step) => !step.isHiddenByLogic)
            .map((step, index) => {
                return {
                    ...step,
                    stepNumber: index + 1,
                    isPoCreationStep: false, // Step where PO creation is true is calculated below
                };
            });

        // PO Creation will occur on the last step that requires an approval
        const poCreationStepIndex = reviewSequenceSteps.findLastIndex(
            (step) => step.reviewType !== reviewTypes.NOTIFY
        );

        if (poCreationStepIndex !== -1) {
            const poCreationStep = reviewSequenceSteps[poCreationStepIndex];
            poCreationStep.isPoCreationStep = isLastApprovalForStep(poCreationStep);
        }

        return reviewSequenceSteps;
    }
);

export const getRequisitionCurrentStep = createSelector(
    [getRequisitionJS, getReviewSequenceSteps],
    (requisition, reviewSequenceSteps) => {
        if (requisition) {
            return reviewSequenceSteps.find((step) => step.id === requisition.current_step_id);
        }
    }
);

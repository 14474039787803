import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import { userStatusTypes } from '@og-pro/shared-config/users';

import { ExistingUserSubmitButtons } from './ExistingUserSubmitButtons';
import { DepartmentField } from './DepartmentField';
import { ExternalField } from './ExternalField';
import { UserRoleField } from './UserRoleField';

const { ACTIVE, DISABLED, INVITED, REQUESTED, RESET } = userStatusTypes;

export const ExistingUserForm = ({
    currentUser,
    disabled,
    handleSubmit,
    onHideEditForm,
    pristine,
    shouldShowRequestForm,
    user,
}) => {
    const styles = require('../index.scss');

    const UserStatus = () => {
        const getUserStatusInfo = () => {
            switch (user.status) {
                case RESET:
                case ACTIVE:
                    return ['text-success', 'fa fa-check-circle-o', 'Active'];
                case INVITED:
                    return ['text-info', 'fa fa-envelope-o', 'Invited'];
                case DISABLED:
                    return ['text-danger', 'fa fa-ban', 'Disabled'];
                case REQUESTED:
                    return ['text-info', 'fa fa-plus', 'Request'];
                default:
                    return [];
            }
        };

        const [textClass, icon, statusText] = getUserStatusInfo();

        return (
            <span className={textClass}>
                <i className={icon} /> {statusText}
            </span>
        );
    };

    return (
        <div
            className={classnames(
                'row',
                shouldShowRequestForm ? styles.userRequestRow : styles.userListRow
            )}
        >
            <div className={`col-xs-12 col-md-3 ${styles.userCol} ${styles.nameField}`}>
                <div className={styles.name}>{user.fullName}</div>
                <div>{user.email}</div>
                {!currentUser.isVendor && <ExternalField disabled={disabled} />}
            </div>
            <div className={`hidden-xs hidden-sm col-md-1 ${styles.userCol}`}>{UserStatus()}</div>
            <div className={`hidden-xs hidden-sm col-md-1 ${styles.userCol}`}>
                {moment(user.created_at).format('l')}
            </div>
            <div className={`hidden-xs hidden-sm col-md-1 ${styles.userCol}`}>
                {user.lastLogin ? moment(user.lastLogin).format('l') : 'None'}
            </div>
            <div className={`col-xs-12 col-md-2 ${styles.userCol}`}>
                <UserRoleField disabled={disabled} isVendor={currentUser.isVendor} />
            </div>
            {!currentUser.isVendor && (
                <div className={`col-xs-12 col-md-2 ${styles.userCol}`}>
                    <DepartmentField disabled={disabled} />
                </div>
            )}
            <div className={`col-xs-12 col-md-2 ${styles.userCol}`}>
                <ExistingUserSubmitButtons
                    disabled={disabled}
                    handleSubmit={handleSubmit}
                    isRequestForm={shouldShowRequestForm}
                    onHideEditForm={onHideEditForm}
                    pristine={pristine}
                    user={user}
                />
            </div>
        </div>
    );
};

ExistingUserForm.propTypes = {
    currentUser: PropTypes.shape({
        isVendor: PropTypes.bool.isRequired,
    }).isRequired,
    disabled: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    onHideEditForm: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    shouldShowRequestForm: PropTypes.bool,
    user: PropTypes.shape({
        created_at: PropTypes.string,
        email: PropTypes.string,
        fullName: PropTypes.string,
        lastLogin: PropTypes.string,
        status: PropTypes.string,
    }).isRequired,
};

import { fromJS } from 'immutable';

import * as projectActions from '../actions/project/create/projectCreate';
import * as govProjectActions from '../actions/govProjects';

const initialProjectCreateModalState = {
    initialProjectCreateData: null,
    showCreateModal: false,
};

const initialState = fromJS({
    ...initialProjectCreateModalState,
    builderSections: null,
    createError: null,
    creating: false,
    error: null,
    formCompleteConfirmation: null,
    helpModalData: null,
    loadBuilderSectionsError: null,
    loaded: false,
    loadedBuilderSections: false,
    loadError: null,
    loading: false,
    loadingBuilderSections: false,
    scrollToTop: false,
    shouldExport: false,
    shouldExportDocument: false,
    shouldSneakyUpdate: false,
    shouldSubmit: false,
    shouldUpdate: false,
    showAutomatedAddendumModal: false,
    showDepartmentModal: false,
    showFormErrors: false,
    showHelpModal: false,
    showTermsReviewModal: false,
    submitting: false,
    termsReviewModalData: null,
    updating: false,
});

export default function projectCreateReducer(state = initialState, action = {}) {
    switch (action.type) {
        case projectActions.INITIALIZE:
            return state.set('showFormErrors', false);
        case projectActions.SHOW_DEPARTMENT_MODAL:
            return state.set('showDepartmentModal', true);
        case projectActions.HIDE_DEPARTMENT_MODAL:
            return state.set('showDepartmentModal', false);
        case projectActions.SHOW_HELP_MODAL:
            return state.merge(
                fromJS({
                    showHelpModal: true,
                    helpModalData: fromJS(action.data),
                })
            );
        case projectActions.HIDE_HELP_MODAL:
            return state.merge(
                fromJS({
                    showHelpModal: false,
                    helpModalData: null,
                })
            );
        case projectActions.SHOW_CREATE_MODAL:
            return state.merge(
                fromJS({
                    showCreateModal: true,
                    createError: null,
                    initialProjectCreateData: action.data,
                })
            );
        case projectActions.HIDE_CREATE_MODAL:
            return state.merge(fromJS(initialProjectCreateModalState));
        case projectActions.SHOW_TERMS_REVIEW_MODAL:
            return state.merge(
                fromJS({
                    showTermsReviewModal: true,
                    termsReviewModalData: fromJS(action.data),
                })
            );
        case projectActions.HIDE_TERMS_REVIEW_MODAL:
            return state.merge(
                fromJS({
                    showTermsReviewModal: false,
                    termsReviewModalData: null,
                })
            );
        case projectActions.RESET_CREATE_ERROR:
            return state.set('createError', null);
        case projectActions.CREATE:
            return state.merge(
                fromJS({
                    creating: true,
                    createError: null,
                    createText: action.text,
                })
            );
        case projectActions.CREATE_SUCCESS:
            return state.merge(
                fromJS({
                    creating: false,
                    createText: null,
                    loaded: true,
                })
            );
        case projectActions.CREATE_FAIL:
            return state.merge(
                fromJS({
                    creating: false,
                    createText: null,
                    createError: action.error && action.error.message,
                })
            );
        case projectActions.INITIATE_EXPORT:
            return state.set('shouldExport', true);
        case projectActions.INITIATE_EXPORT_DOCUMENT:
            return state.set('shouldExportDocument', true);
        case projectActions.INITIATE_UPDATE:
            return state.set('shouldUpdate', true);
        case projectActions.INITIATE_SNEAKY_UPDATE:
            return state.merge(
                fromJS({
                    shouldSneakyUpdate: true,
                    sneakyNextRoute: action.nextRoute,
                })
            );
        case projectActions.RESET_SNEAKY_UPDATE:
            return state.merge(
                fromJS({
                    shouldSneakyUpdate: false,
                    sneakyNextRoute: null,
                })
            );
        case projectActions.UPDATE:
            return state.merge(
                fromJS({
                    updating: true,
                    updateError: null,
                })
            );
        case projectActions.UPDATE_SUCCESS:
            return state.merge(
                fromJS({
                    updating: false,
                    shouldUpdate: false,
                    shouldExport: false,
                    shouldExportDocument: false,
                })
            );
        case projectActions.UPDATE_FAIL:
            return state.merge(
                fromJS({
                    updating: false,
                    shouldUpdate: false,
                    shouldExport: false,
                    shouldExportDocument: false,
                    updateError: action.error && action.error.message,
                })
            );
        case projectActions.INITIATE_SUBMIT:
            return state.set('shouldSubmit', true);
        case projectActions.RESET_SUBMIT:
            return state.set('shouldSubmit', false);
        case projectActions.SUBMIT:
            return state.merge(
                fromJS({
                    submitting: true,
                    submitError: null,
                })
            );
        case projectActions.SUBMIT_SUCCESS:
            return state.merge(
                fromJS({
                    submitting: false,
                })
            );
        case projectActions.SUBMIT_FAIL:
            return state.merge(
                fromJS({
                    submitting: false,
                    submitError: action.error && action.error.message,
                })
            );
        case projectActions.LOAD_BUILDER_SECTIONS:
            return state.merge(
                fromJS({
                    loadingBuilderSections: true,
                    loadedBuilderSections: false,
                    loadBuilderSectionsError: null,
                })
            );
        case projectActions.LOAD_BUILDER_SECTIONS_SUCCESS:
            return state.merge(
                fromJS({
                    loadingBuilderSections: false,
                    loadedBuilderSections: true,
                    builderSections: fromJS(action.result),
                })
            );
        case projectActions.LOAD_BUILDER_SECTIONS_FAIL:
            return state.merge(
                fromJS({
                    loadingBuilderSections: false,
                    loadBuilderSectionsError: action.error && action.error.message,
                })
            );
        case projectActions.VISIT_PROJECT_SECTION:
            return state.setIn(
                ['builderSections', `${action.projectSectionId}`, 'wasVisited'],
                true
            );
        case projectActions.SHOW_AUTOMATED_ADDENDUM_MODAL:
            return state.set('showAutomatedAddendumModal', true);
        case projectActions.HIDE_AUTOMATED_ADDENDUM_MODAL:
            return state.set('showAutomatedAddendumModal', false);
        case projectActions.SET_FORM_COMPLETE_CONFIRMATION:
            return state.set('formCompleteConfirmation', action.data);
        case govProjectActions.LOAD:
            return state.merge(
                fromJS({
                    loaded: false,
                    loading: true,
                    loadError: null,
                })
            );
        case govProjectActions.LOAD_SUCCESS:
            return state.merge(
                fromJS({
                    loaded: true,
                    loading: false,
                })
            );
        case govProjectActions.LOAD_FAIL:
            return state.merge(
                fromJS({
                    loaded: false,
                    loading: false,
                    loadError: action.error && action.error.message,
                })
            );
        case projectActions.RESET:
            return initialState;
        case projectActions.SHOW_FORM_VALIDATION:
            return state.set('showFormErrors', true);
        case projectActions.SCROLL_TO_TOP:
            return state.set('scrollToTop', action.scrollToTop);
        default:
            return state;
    }
}

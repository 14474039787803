const { notificationLevels } = require('@og-pro/shared-config/governmentSubscriptions');

const { NONE, ALL, CATEGORIES } = notificationLevels;

export const getNotificationText = (notificationLevel) => {
    switch (notificationLevel) {
        case ALL:
            return ['rss', 'Notify me when any new opportunity is issued'];
        case CATEGORIES:
            return ['filter', 'Notify me when new opportunity matches company category codes'];
        case NONE:
        default:
            return ['ban', 'Never notify me about new opportunities'];
    }
};

import PropTypes from 'prop-types';
import React from 'react';
import { v4 as UUIDV4 } from 'uuid';
import { Box, Typography } from '@og-pro/ui';

import { DisplayCertification } from '../Display';

export const VerifiedCertificationsList = ({ verifiedCertifications }) => {
    const styles = require('./index.scss');

    return (
        <Box className={styles.verifiedCertificationsList} display="flex" flexDirection="column">
            <Box
                className={styles.verifiedCertificationsText}
                display="flex"
                flexDirection="column"
            >
                <Typography variant="h3">Verified Certifications</Typography>
                <Typography className={styles.description}>
                    If your company’s EIN is found on one of the state or federal lists that OpenGov
                    checks, that certification will be listed automatically. Based on the agency or
                    list, this may take some time after you’ve certified.
                </Typography>
            </Box>
            <Box className={styles.verifiedCertifications} display="flex" flexDirection="column">
                {verifiedCertifications.map((certification, index) => (
                    <DisplayCertification
                        certification={certification}
                        index={index}
                        isVerifiedCertification
                        // temporary key until we have a unique identifier
                        key={UUIDV4()}
                    />
                ))}
            </Box>
        </Box>
    );
};

VerifiedCertificationsList.propTypes = {
    verifiedCertifications: PropTypes.array.isRequired,
};

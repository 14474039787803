import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Button } from '../../../../../components';

export class DownloadAllButton extends PureComponent {
    static propTypes = {
        downloading: PropTypes.bool.isRequired,
        downloadError: PropTypes.string,
        downloadUrl: PropTypes.string,
        downloadHandler: PropTypes.func.isRequired,
    };

    get styles() {
        return require('../index.scss');
    }

    render() {
        const { downloading, downloadError, downloadUrl } = this.props;
        const icon = downloading ? 'fa-spin fa-spinner' : 'fa-cloud-download';

        if (downloadUrl) {
            return (
                <a href={downloadUrl}>
                    <i className="fa fa-3x fa-folder-open" />
                    <div>Click to download documents</div>
                </a>
            );
        }

        return (
            <div>
                <Button bsSize="sm" disabled={downloading} onClick={this.props.downloadHandler}>
                    <i className={`fa fa-fw fa-lg ${icon}`} /> Download All Documents
                </Button>
                {downloading && (
                    <div className={`${this.styles.downloadMsg} text-info`}>
                        Downloading documents! This may take a few moments...
                    </div>
                )}
                {downloadError && (
                    <div className={`${this.styles.downloadMsg} text-danger`}>{downloadError}</div>
                )}
            </div>
        );
    }
}

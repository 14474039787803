import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';

import { ExistingUserForm } from './ExistingUserForm';
import { NewUserForm } from './NewUserForm';
import { validate } from './validate';

const formConfig = {
    validate,
};

// @reduxForm
const ConnectedUserForm = ({
    cancelHandler,
    currentUser,
    disabled,
    handleSubmit,
    invalid,
    isModalView,
    onHideEditForm,
    pristine,
    shouldShowNewUserForm,
    shouldShowRequestForm,
    submitHandler,
    user,
}) => {
    if (shouldShowNewUserForm) {
        return (
            <NewUserForm
                cancelHandler={cancelHandler}
                currentUser={currentUser}
                disabled={disabled}
                handleSubmit={handleSubmit}
                invalid={invalid}
                isModalView={isModalView}
                submitHandler={submitHandler}
                user={user}
            />
        );
    }

    return (
        <ExistingUserForm
            currentUser={currentUser}
            disabled={disabled}
            handleSubmit={handleSubmit}
            onHideEditForm={onHideEditForm}
            pristine={pristine}
            shouldShowRequestForm={shouldShowRequestForm}
            user={user}
        />
    );
};

ConnectedUserForm.propTypes = {
    cancelHandler: PropTypes.func,
    currentUser: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool.isRequired,
    isModalView: PropTypes.bool,
    onHideEditForm: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    shouldShowNewUserForm: PropTypes.bool.isRequired,
    shouldShowRequestForm: PropTypes.bool,
    submitHandler: PropTypes.func,
    user: PropTypes.object.isRequired,
};

export const UserForm = reduxForm(formConfig)(ConnectedUserForm);

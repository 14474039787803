import { createSelector } from 'reselect';

import { getProjectJS } from '../../selectors';
import { getReverseAuctionItemsJS } from '../../../App/selectors';
import { getBidRankingMap } from '../../../../helpers/bidRankingHelper';

export const getAuctionExtensionCount = createSelector([getProjectJS], (project) => {
    if (project) {
        return project.auctionExtensionCount || 0;
    }
});

export const getProjectBudget = createSelector([getProjectJS], (project) => {
    if (project) {
        return project.budget.amount;
    }
});

export const getVendorTotals = createSelector([getReverseAuctionItemsJS], (reverseAuctionItems) => {
    // group by vendor
    const totals = reverseAuctionItems.reduce((vendorTotals, currentItem) => {
        // there should only be 1 priceItem per table, but this handles undesired states
        currentItem.priceItems.forEach((priceItem) => {
            // get totals, vendor info, etc.
            priceItem.vendorPriceItems.forEach((vendorPriceItem) => {
                const {
                    proposal: { companyName, vendor_id: vendorId },
                    reverseAuctionBids,
                } = vendorPriceItem;

                if (reverseAuctionBids.length > 0) {
                    const { created_at: createdAt, unitPrice } = reverseAuctionBids[0];

                    if (vendorTotals[vendorId]) {
                        const currentVendor = vendorTotals[vendorId];

                        const updatedVendor = {
                            ...currentVendor,
                            numBids: currentVendor.numBids + reverseAuctionBids.length,
                            grandTotal: unitPrice
                                ? currentVendor.grandTotal + unitPrice * priceItem.quantity
                                : currentVendor.grandTotal,
                            mostRecentBidDate:
                                createdAt > currentVendor.mostRecentBidDate
                                    ? createdAt
                                    : currentVendor.mostRecentBidDate,
                        };

                        vendorTotals[vendorId] = updatedVendor;
                    } else {
                        vendorTotals[vendorId] = {
                            companyName,
                            numBids: reverseAuctionBids.length,
                            grandTotal: unitPrice ? unitPrice * priceItem.quantity : 0,
                            mostRecentBidDate: createdAt,
                            vendorId,
                        };
                    }
                }
            });
        });

        return vendorTotals;
    }, {});

    const sortedVendorTotals = Object.values(totals).sort((a, b) => a.grandTotal - b.grandTotal);
    const vendorTotalsRanking = getBidRankingMap(
        sortedVendorTotals.map((item) => ({ id: item.vendorId, value: item.grandTotal }))
    );

    return sortedVendorTotals.map((item) => ({
        ...item,
        ranking: vendorTotalsRanking[item.vendorId],
    }));
});

import { createSelector } from 'reselect';

import { getProposalJS } from '../selectors';
import { getReverseAuctionItemsJS } from '../../App/selectors';
import { navItems } from './constants';

export const getSelectedRoute = createSelector(
    [() => navItems, (_state, props) => props.location],
    (navs, location) => {
        const { pathname } = location;

        return (navs.find((item) => pathname.match(new RegExp(`${item.route}`))) || navs[0]).route;
    }
);

export const getCurrentVendorStats = createSelector(
    [getReverseAuctionItemsJS, getProposalJS],
    (reverseAuctionItems, proposal) => {
        const numOfLineItemsTotal = reverseAuctionItems.length;

        let currentTotal = 0;
        let numOfBids = 0;
        let numOfLineItemsWinning = 0;

        reverseAuctionItems.forEach((item) => {
            item.priceItems[0].vendorPriceItems.forEach((vendorPriceItem, idx) => {
                if (vendorPriceItem.proposal.id === proposal.id) {
                    if (idx === 0) numOfLineItemsWinning += 1;

                    if (!vendorPriceItem.noBid) {
                        currentTotal +=
                            vendorPriceItem.reverseAuctionBids[0].unitPrice *
                            item.priceItems[0].quantity;
                        numOfBids += vendorPriceItem.reverseAuctionBids.length;
                    }
                }
            });
        });

        return {
            currentTotal,
            numOfBids,
            numOfLineItemsTotal,
            numOfLineItemsWinning,
        };
    }
);

import { buildQaTag, componentNames } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React from 'react';
import { DropdownButton } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { Button, MenuItem } from '../../../../../components';

export const ActionButtons = ({ buttons, disabled }) => {
    const dispatch = useDispatch();

    return buttons.map(({ header, icon, onClick, options, qaTag, text, ...props }, index) => {
        const buttonContents = (
            <>
                {icon && (
                    <>
                        <i className={icon} />
                        &nbsp;
                    </>
                )}
                {text}
            </>
        );
        const sharedProps = {
            disabled,
            key: index,
            ...props,
        };
        if (!options) {
            return (
                <Button {...sharedProps} onClick={() => onClick(dispatch)} qaTag={qaTag}>
                    {buttonContents}
                </Button>
            );
        }
        return (
            <DropdownButton
                {...sharedProps}
                data-qa={buildQaTag(qaTag, componentNames.BUTTON)}
                id={qaTag}
                pullRight
                title={buttonContents}
            >
                {header && (
                    <MenuItem header qaTag={`${qaTag}Header`}>
                        {header}
                    </MenuItem>
                )}
                {options.map(
                    ({ onClick: optionOnClick, text: optionText, ...optionProps }, optIndex) => (
                        <MenuItem
                            disabled={disabled}
                            key={optIndex}
                            onSelect={() => optionOnClick(dispatch)}
                            qaTag={`${qaTag}Option${optIndex}`}
                            {...optionProps}
                        >
                            {optionText}
                        </MenuItem>
                    )
                )}
            </DropdownButton>
        );
    });
};

ActionButtons.propTypes = {
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            header: PropTypes.string,
            icon: PropTypes.string,
            options: PropTypes.arrayOf(
                PropTypes.shape({
                    onClick: PropTypes.func.isRequired,
                    text: PropTypes.string.isRequired,
                })
            ),
            qaTag: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
        })
    ).isRequired,
    disabled: PropTypes.bool,
};

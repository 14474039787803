import { createSelector } from 'reselect';
import { pick } from 'lodash';

import { getUserJS } from '../../../containers/selectors';
import { buildLanguageOptions, fields, vendorFields } from './constants';

export const getOrganization = createSelector([getUserJS], (user) => {
    return user.organization;
});

export const getInitialValues = createSelector([getUserJS], (user) => {
    if (user) {
        const { vendor, organization } = user;
        return {
            ...pick(organization, fields),
            useDifferentBillingAddress: !!organization.billingAddress1,
            vendor: {
                ...pick(vendor, vendorFields),
                languages: buildLanguageOptions(vendor.languages),
            },
        };
    }
});

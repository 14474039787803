import { glLookupAccount } from './queries';
import { useOgFinancials } from './useOgFinancials';

const ONE_HOUR = 1000 * 60 * 60;

export const useAccountNumberLookup = ({ graphQLQueryFields, placeholderData, ...params }) => {
    return useOgFinancials({
        queryKey: 'glAccountNumber',
        query: glLookupAccount(
            graphQLQueryFields ||
                '\n' +
                    '      fund_no_size\n' +
                    '      account_object_size\n' +
                    '      account_dept_size\n' +
                    '      account_prog_size\n' +
                    '      account_proj_size\n' +
                    '      account_proj_cat_size\n' +
                    '      account_user1_size\n' +
                    '      account_user2_size\n' +
                    '      account_user3_size\n' +
                    '    \n' +
                    '      fund_no\n' +
                    '      fund_desc\n' +
                    '      account_pseudo_key\n' +
                    '    '
        ),
        staleTime: ONE_HOUR,
        placeholderData,
        params,
    });
};

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Skeleton } from '@og-pro/ui';

export const SkeletonLoading = ({ height = 40 }) => {
    return (
        <Box alignSelf="center" flex={1} height={height}>
            <Skeleton height="15%" sx={{ mb: 0.5 }} variant="rectangular" width="40%" />
            <Skeleton height="15%" sx={{ mb: 0.5 }} variant="rectangular" width="50%" />
            <Skeleton height="70%" variant="rectangular" />
        </Box>
    );
};

SkeletonLoading.propTypes = {
    height: PropTypes.number,
};

import { showSnackbar } from './notification';
import { emitChecklistSocket } from './utils';
import { resourceManager } from '../helpers';

export const COPY_TEMPLATE = 'checklistsAdmin/COPY_TEMPLATE';
export const COPY_TEMPLATE_FAIL = 'checklistsAdmin/COPY_TEMPLATE_FAIL';
export const COPY_TEMPLATE_SUCCESS = 'checklistsAdmin/COPY_TEMPLATE_SUCCESS';

export const CREATE = 'checklistsAdmin/CREATE';
export const CREATE_FAIL = 'checklistsAdmin/CREATE_FAIL';
export const CREATE_SUCCESS = 'checklistsAdmin/CREATE_SUCCESS';

export const DELETE = 'checklistsAdmin/DELETE';
export const DELETE_FAIL = 'checklistsAdmin/DELETE_FAIL';
export const DELETE_SUCCESS = 'checklistsAdmin/DELETE_SUCCESS';

export const LOAD = 'checklistsAdmin/LOAD';
export const LOAD_FAIL = 'checklistsAdmin/LOAD_FAIL';
export const LOAD_SUCCESS = 'checklistsAdmin/LOAD_SUCCESS';

export const LOAD_LIST = 'checklistsAdmin/LOAD_LIST';
export const LOAD_LIST_FAIL = 'checklistsAdmin/LOAD_LIST_FAIL';
export const LOAD_LIST_SUCCESS = 'checklistsAdmin/LOAD_LIST_SUCCESS';

export const RESET_TEMPLATE_EDIT = 'checklistsAdmin/RESET_TEMPLATE_EDIT';
export const RESET_TEMPLATE_LIST = 'checklistsAdmin/RESET_TEMPLATE_LIST';

export const UPDATE = 'checklistsAdmin/UPDATE';
export const UPDATE_FAIL = 'checklistsAdmin/UPDATE_FAIL';
export const UPDATE_SUCCESS = 'checklistsAdmin/UPDATE_SUCCESS';

export const copyTemplateChecklist = (templateId, onSuccess) => {
    return resourceManager({
        method: 'post',
        url: `/checklists/templates/${templateId}/copy`,
        onStart: ({ dispatch }) => dispatch({ type: COPY_TEMPLATE }),
        onSuccess: ({ dispatch, result }) => {
            dispatch({ type: COPY_TEMPLATE_SUCCESS });
            if (onSuccess) {
                onSuccess(result);
            }
        },
        onFailure: ({ dispatch, error }) => {
            dispatch({ type: COPY_TEMPLATE_FAIL, error });
            return error;
        },
    });
};

export const createTemplateChecklist = (onSuccess) => {
    return resourceManager({
        method: 'post',
        url: '/checklists/templates',
        onStart: ({ dispatch }) => dispatch({ type: CREATE }),
        onSuccess: ({ result, dispatch }) => {
            dispatch({ type: CREATE_SUCCESS, result });
            if (onSuccess) {
                onSuccess(result);
            }
        },
        onFailure: ({ dispatch, error }) => dispatch({ type: CREATE_FAIL, error }),
    });
};

export const deleteTemplateChecklist = (templateChecklistId, onSuccess) => {
    return resourceManager({
        method: 'del',
        url: `/checklists/templates/${templateChecklistId}`,
        onStart: ({ dispatch }) => dispatch({ type: DELETE }),
        onSuccess: ({ result, dispatch }) => {
            const deleteAction = { type: DELETE_SUCCESS, result };
            dispatch(
                emitChecklistSocket(
                    result.id,
                    deleteAction,
                    'Update Received: Checklist was deleted'
                )
            );
            if (onSuccess) {
                onSuccess(result);
            }
        },
        onFailure: ({ dispatch, error }) => dispatch({ type: DELETE_FAIL, error }),
    });
};

export const loadTemplateChecklist = (templateChecklistId) => {
    return resourceManager({
        method: 'get',
        url: `/checklists/templates/${templateChecklistId}`,
        onStart: ({ dispatch }) => dispatch({ type: LOAD }),
        onSuccess: ({ result, dispatch }) => dispatch({ type: LOAD_SUCCESS, result }),
        onFailure: ({ dispatch, error }) => dispatch({ type: LOAD_FAIL, error }),
    });
};

export const loadTemplateChecklists = () => {
    return resourceManager({
        method: 'get',
        url: '/checklists/templates',
        onStart: ({ dispatch }) => dispatch({ type: LOAD_LIST }),
        onSuccess: ({ dispatch, result }) => dispatch({ type: LOAD_LIST_SUCCESS, result }),
        onFailure: ({ dispatch, error }) => dispatch({ type: LOAD_LIST_FAIL, error }),
    });
};

export const reloadTemplateChecklist = (templateChecklistId) => {
    return resourceManager({
        method: 'get',
        url: `/checklists/templates/${templateChecklistId}`,
        onSuccess: ({ dispatch, getState, result }) => {
            const lastUpdated = getState().checklistsAdmin.getIn([
                'templateChecklist',
                'updated_at',
            ]);

            // Dates are returned as strings, so can be compared
            if (result.updated_at !== lastUpdated) {
                dispatch(
                    showSnackbar('Received New Updates', {
                        dismissAfter: 3500,
                    })
                );
                // Load the template again if new data has come in
                return dispatch(loadTemplateChecklist(result.id));
            }
        },
    });
};

export const resetTemplateChecklist = () => {
    return { type: RESET_TEMPLATE_EDIT };
};

export const resetTemplateChecklists = () => {
    return { type: RESET_TEMPLATE_LIST };
};

export const updateTemplateChecklist = (templateChecklistId, templateChecklistData) => {
    return resourceManager({
        method: 'put',
        url: `/checklists/templates/${templateChecklistId}`,
        requestOptions: { data: templateChecklistData },
        onStart: ({ dispatch }) => dispatch({ type: UPDATE }),
        onSuccess: ({ dispatch, result }) => {
            const updateAction = { type: UPDATE_SUCCESS, result };
            dispatch(emitChecklistSocket(result.id, updateAction));
            dispatch(showSnackbar('Checklist saved'));
            return result;
        },
        onFailure: ({ dispatch, error }) => {
            dispatch({ type: UPDATE_FAIL, error });
            return error;
        },
    });
};

import { listToDict } from '@og-pro/shared-config/helpers';

export const reportTypesList = [
    'projectSummaryReport',
    'questionAnswerReport',
    'addendumReport',
    'proposalDocumentReport',
    'interviewInvitationReport',
    'nonAwardReport',
    'awardReport',
    'evaluationTabulationReport',
    'reverseAuctionSummaryReport',
    'allProposalsReport',
];

export const reportTypes = listToDict(reportTypesList);

export const evaluationReportOptionsList = ['details', 'summary'];

export const evaluationReportOptions = listToDict(evaluationReportOptionsList);

const reportFormatsList = ['pdf', 'docx', 'preview', 'html'];

export const reportFormats = listToDict(reportFormatsList);

export const landscapeReportTypes = [
    reportTypes.ALL_PROPOSALS_REPORT,
    reportTypes.PROPOSAL_DOCUMENT_REPORT,
];

export const multiEmailReportTypes = [reportTypes.ALL_PROPOSALS_REPORT];

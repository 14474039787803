import React from 'react';

export const ProjectCreateV2FunctionsContext = React.createContext({});

export const ProjectCreateV2NavContext = React.createContext({
    activeSectionId: 0,
    setActiveSectionId: () => {},
});

export const ProjectCreateManageSectionContext = React.createContext({
    data: {
        active: false,
        index: null,
    },
    setData: () => {},
});

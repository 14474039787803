import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';

import { EditTitleMenu } from './EditTitleMenu';
import { QuestionsInfo } from './QuestionsInfo';
import { getTemplateChecklistFormValues } from './selectors';
import { TemplateInfo } from './TemplateInfo';
import { TemplateForm, TemplateQuestionnairesForm } from '../forms';
import {
    ALL_SECTIONS,
    form,
    FORM_SECTIONS_KEY,
    formSectionNames,
    formSections,
    incompleteSectionInstructions,
} from '../forms/constants';
import { validate } from '../forms/validate';
import { getTemplateChecklistAdminPath, getTemplateChecklistJS } from '../selectors';
import connectData from '../../../ConnectData';
import { isInitialClientLoaded } from '../../../selectors';
import { showConfirmationSimpleModal } from '../../../../actions/confirmation';
import {
    loadTemplateChecklist,
    resetTemplateChecklist,
    updateTemplateChecklist,
} from '../../../../actions/checklistsAdmin';
import {
    Button,
    Main,
    RouteLeaveWarning,
    SectionContainer,
    TemplateReviewSection,
} from '../../../../components';
import { shouldError } from '../../../../Forms/validation';
import { getTemplateAdminUserList } from '../../../../selectors/govApp';
import { useChecklistSocket } from '../../../hooks/checklistHooks';

const { QUESTIONS, TEMPLATE } = formSectionNames;

function fetchData(getState, dispatch, location, params) {
    const templateChecklistId = Number.parseInt(params.templateChecklistId, 10);
    return dispatch(loadTemplateChecklist(templateChecklistId));
}

const mapStateToProps = (state, props) => {
    const isClientLoaded = isInitialClientLoaded(state);
    const templateChecklist = getTemplateChecklistJS(state);
    return {
        copyingTemplate: state.checklistsAdmin.get('copying'),
        deleting: state.checklistsAdmin.get('deleting'),
        formValues: getTemplateChecklistFormValues(state),
        initialValues: isClientLoaded ? templateChecklist : undefined, // Skip server side initializing of form values (does not properly initialize on server)
        templateAdmins: getTemplateAdminUserList(state),
        templateChecklist,
        templateChecklistAdminPath: getTemplateChecklistAdminPath(state, props),
        updating: state.checklistsAdmin.get('updating'),
    };
};

const mapDispatchToProps = {
    resetTemplateEdit: resetTemplateChecklist,
    showConfirmationSimpleModal,
    updateTemplate: updateTemplateChecklist,
};

const formConfig = {
    enableReinitialize: true,
    form,
    shouldError,
    validate,
};

// @withRouter
// @connectData
// @connect
// @reduxForm
const ConnectedChecklistTemplateEdit = ({
    array,
    change,
    copyingTemplate,
    deleting,
    dirty,
    formValues,
    handleSubmit,
    params,
    resetTemplateEdit,
    submitFailed,
    templateChecklist,
    templateChecklistAdminPath,
    templateAdmins,
    updateTemplate,
    updating,
}) => {
    const [openSectionNumber, setOpenSectionNumber] = useState(null);
    const [showFormValidation, setShowFormValidation] = useState(false);
    const [showGlobalFormValidation, setShowGlobalFormValidation] = useState(false);

    const templateChecklistId = Number.parseInt(params.templateChecklistId, 10);
    // Subscribe to checklist socket
    useChecklistSocket(templateChecklistId, true);

    // On leave hook
    useEffect(() => {
        return () => {
            resetTemplateEdit();
        };
    }, [resetTemplateEdit]);

    const { template = {}, questionnaires = [] } = formValues;

    const disabled = updating || deleting || copyingTemplate;

    const showValidation = showFormValidation || showGlobalFormValidation;

    // If navigating to the page using back/forward page can render before loading function
    // starts, so need this check
    if (!templateChecklist) {
        return null;
    }

    const {
        id,
        template: { isPublished },
    } = templateChecklist;

    const openSectionHandler = (sectionNumber, shouldShowFormValidation = false) => {
        // No section should be selected if the numbers match (open section is being closed)
        const newSectionNumber = sectionNumber === openSectionNumber ? null : sectionNumber;
        setOpenSectionNumber(newSectionNumber);
        setShowFormValidation(shouldShowFormValidation);
    };

    const toggleLive = () => {
        if (isPublished) {
            const submitData = {
                ...formValues,
                template: {
                    ...formValues.template,
                    isPublished: false,
                },
            };
            return updateTemplate(id, submitData);
        }

        setShowGlobalFormValidation(true);

        return handleSubmit((data) => {
            const submitData = {
                ...data,
                template: {
                    ...data.template,
                    isPublished: true,
                },
            };
            setShowGlobalFormValidation(false);
            return updateTemplate(id, submitData);
        })();
    };

    const updateChecklistTemplate = () => {
        // If the template has been published we want to validate the form before submitting
        if (isPublished) {
            setShowGlobalFormValidation(true);
            return handleSubmit((data) => {
                setShowGlobalFormValidation(false);
                return updateTemplate(id, data);
            })();
        }

        return updateTemplate(id, formValues);
    };

    return (
        <Main>
            <div>
                <Button
                    bsSize="sm"
                    bsStyle="link"
                    qaTag="checklistsAdminEdit-back"
                    to={templateChecklistAdminPath}
                >
                    <i className="fa fa-angle-left" /> Checklists Admin
                </Button>
            </div>
            <RouteLeaveWarning blockingValue={dirty} />
            <EditTitleMenu
                deleting={deleting}
                disabled={disabled}
                setShowGlobalFormValidation={setShowGlobalFormValidation}
                submitFailed={submitFailed}
                templateChecklist={templateChecklist}
                templateChecklistAdminPath={templateChecklistAdminPath}
                title={template.title}
                toggleLive={toggleLive}
                updateChecklistTemplate={updateChecklistTemplate}
                updating={updating}
            />
            <Field
                component={SectionContainer}
                info={<TemplateInfo template={template} />}
                isOpen={openSectionNumber === 1}
                name={`${FORM_SECTIONS_KEY}.${TEMPLATE}`}
                number={1}
                onClick={openSectionHandler}
                title="Design your template"
            >
                <TemplateForm
                    disabled={disabled}
                    showFormValidation={showValidation}
                    userOptions={templateAdmins}
                />
            </Field>
            <Field
                component={SectionContainer}
                info={<QuestionsInfo questionnaires={questionnaires} />}
                isOpen={openSectionNumber === 2}
                name={`${FORM_SECTIONS_KEY}.${QUESTIONS}`}
                number={2}
                onClick={openSectionHandler}
                title="Specify your questions"
            >
                <TemplateQuestionnairesForm
                    array={array}
                    change={change}
                    disabled={disabled}
                    questionnaires={questionnaires}
                    showFormValidation={showValidation}
                />
            </Field>
            {!isPublished && (
                <Field
                    component={SectionContainer}
                    isOpen={openSectionNumber === 3}
                    name={ALL_SECTIONS}
                    number={3}
                    onClick={openSectionHandler}
                    title="Review your template and set it live"
                >
                    <Field
                        component={TemplateReviewSection}
                        disabled={disabled}
                        formSectionKey={FORM_SECTIONS_KEY}
                        formSections={formSections}
                        incompleteSectionInstructions={incompleteSectionInstructions}
                        name={ALL_SECTIONS}
                        openSectionHandler={openSectionHandler}
                        readyText="This template will be available for use by your team as soon as it's live"
                        saveHandler={updateChecklistTemplate}
                        setLiveHandler={toggleLive}
                    />
                </Field>
            )}
        </Main>
    );
};

ConnectedChecklistTemplateEdit.propTypes = {
    array: PropTypes.object.isRequired,
    change: PropTypes.func.isRequired,
    copyingTemplate: PropTypes.bool.isRequired,
    deleting: PropTypes.bool.isRequired,
    dirty: PropTypes.bool.isRequired,
    formValues: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    params: PropTypes.shape({
        templateChecklistId: PropTypes.string.isRequired,
    }).isRequired,
    resetTemplateEdit: PropTypes.func.isRequired,
    submitFailed: PropTypes.bool.isRequired,
    templateAdmins: PropTypes.array.isRequired,
    templateChecklist: PropTypes.shape({
        id: PropTypes.number.isRequired,
        questionnaires: PropTypes.array.isRequired,
        template: PropTypes.shape({
            icon: PropTypes.string,
            isPublished: PropTypes.bool.isRequired,
            title: PropTypes.string,
        }).isRequired,
    }),
    templateChecklistAdminPath: PropTypes.string.isRequired,
    updateTemplate: PropTypes.func.isRequired,
    updating: PropTypes.bool.isRequired,
};

export const ChecklistTemplateEdit = compose(
    connectData(fetchData),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedChecklistTemplateEdit);

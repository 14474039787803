import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';

import { questionLogicLinkableModels } from '@og-pro/shared-config/questionLogics';

import { form } from '../forms/constants';
import { getDocxTemplatesJS, getTemplateJS } from '../selectors';

const templateFormValues = (state) => getFormValues(form)(state);

export const getDeserializedTemplateProject = createSelector([getTemplateJS], (project) => {
    if (project) {
        return project;
    }
});

export const getTemplateFormValues = createSelector([templateFormValues], (formValues) => {
    if (formValues) {
        return formValues;
    }
    return {};
});

export const getDocxTemplateSelectOptions = createSelector(
    [getDocxTemplatesJS],
    (docxTemplates) => {
        return docxTemplates.map((docxTemplate) => ({
            label: `${docxTemplate.title}${
                docxTemplate.governmentDocxTemplates.isDefault ? ' (default)' : ''
            }`,
            value: docxTemplate.id,
        }));
    }
);

const getQuestionLogicFormValues = createSelector(
    [getTemplateFormValues],
    (formValues) => formValues.questionLogics || []
);

export const getQuestionLogicMap = createSelector(
    [getQuestionLogicFormValues],
    (questionLogics) => {
        const initialMap = {};
        questionLogicLinkableModels.forEach((linkableModelName) => {
            initialMap[linkableModelName] = {};
        });

        return questionLogics.reduce((map, questionLogic, index) => {
            map[questionLogic.linkable][questionLogic.linkable_id] = {
                ...questionLogic,
                editIndex: index, // Add index for editing items
            };
            return map;
        }, initialMap);
    }
);

export const getUpfrontQuestionsUsedInQuestionLogic = createSelector(
    [getQuestionLogicFormValues],
    (questionLogics) => {
        return questionLogics.reduce((set, questionLogic) => {
            return set.add(questionLogic.logicable_id);
        }, new Set());
    }
);

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { lgColWidths, smColWidths } from './constants';
import { ContractChecklistItem } from './ContractChecklistItem';
import { QuestionnaireResponseModal } from './QuestionnaireResponseModal';
import { getVendorUsersSelectOptions } from './selectors';
import { QuestionnaireDisplayList } from '..';
import { loadChecklistVendors } from '../../actions/checklists';

export const ChecklistDisplay = ({
    checklist: { id: checklistId, contract },
    isGovernmentView,
    isResponseView,
    questionnaires,
    readOnly,
}) => {
    const [selectedQuestionnaireId, setSelectedQuestionnaireId] = useState(null);
    const hideQuestionnaireModal = () => setSelectedQuestionnaireId(null);
    const showQuestionnaireModal = (questionnaire) => setSelectedQuestionnaireId(questionnaire.id);

    const dispatch = useDispatch();
    const vendorUserOptions = useSelector(getVendorUsersSelectOptions);

    // Ensures the questionnaire modal data is always up to date
    const selectedQuestionnaire = questionnaires.find((q) => q.id === selectedQuestionnaireId);

    useEffect(() => {
        dispatch(loadChecklistVendors(checklistId));
    }, [checklistId, dispatch]);

    const styles = require('./index.scss');
    const [lg1, lg2, lg3, lg4] = lgColWidths;
    const [sm1, sm2, sm3, sm4] = smColWidths;

    if (isResponseView) {
        return (
            <div className="row">
                <div className="col-lg-offset-1 col-lg-10">
                    <QuestionnaireDisplayList isResponseDisplay questionnaires={questionnaires} />
                </div>
            </div>
        );
    }

    return (
        <>
            <div className={`row hidden-xs ${styles.header}`}>
                <div className={`col-sm-${sm1} col-lg-${lg1} text-center`}>
                    <div className="row">
                        <div className="col-xs-6">Agency</div>
                        <div className="col-xs-6">Vendor</div>
                    </div>
                </div>
                <div className={`col-sm-${sm2} col-lg-${lg2}`}>Item</div>
                <div className={`col-sm-${sm3} col-lg-${lg3}`}>Last Updated</div>
                <div className={`col-sm-${sm4} col-lg-${lg4}`}>Assigned</div>
            </div>
            <ListGroup>
                {questionnaires.map((questionnaire) => (
                    <ContractChecklistItem
                        checklistId={checklistId}
                        contract={contract}
                        isGovernmentView={isGovernmentView}
                        key={questionnaire.id}
                        questionnaire={questionnaire}
                        readOnly={readOnly}
                        showQuestionnaire={showQuestionnaireModal}
                        vendorUserOptions={vendorUserOptions}
                    />
                ))}
            </ListGroup>
            {!!selectedQuestionnaire && (
                <QuestionnaireResponseModal
                    checklistId={checklistId}
                    hideModal={hideQuestionnaireModal}
                    isGovernmentView={isGovernmentView}
                    questionnaire={selectedQuestionnaire}
                    readOnly={readOnly}
                />
            )}
        </>
    );
};

ChecklistDisplay.propTypes = {
    checklist: PropTypes.shape({
        contract: PropTypes.object.isRequired,
        id: PropTypes.number.isRequired,
    }).isRequired,
    isGovernmentView: PropTypes.bool,
    isResponseView: PropTypes.bool.isRequired,
    questionnaires: PropTypes.array.isRequired,
    readOnly: PropTypes.bool.isRequired,
};

import { upperCase } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { ListGroup, ListGroupItem, Panel } from 'react-bootstrap';

import { checklistStatusTypes } from '@og-pro/shared-config/checklists';

import { StatusLabel } from '../../../components';
import { ChecklistsListItem } from '../../../components/ChecklistsList/ChecklistsListItem';
import { contractStatusClass } from '../../../components/helpers/statusHelper';

export const NeedsAttentionChecklists = ({ contracts, panelTitle }) => {
    const styles = require('./NeedsAttentionChecklists.scss');

    const checklistItems = contracts.map((contract) => {
        return (
            <ListGroupItem className={styles.listGroupItem} key={contract.id}>
                <h4 className={styles.contractTitle}>{contract.title}</h4>
                <StatusLabel
                    bsStyle={contractStatusClass(contract.status)}
                    className={styles.contractStatus}
                >
                    {upperCase(contract.status)}
                </StatusLabel>
                {contract.checklists.map(
                    (checklist) =>
                        checklist.status !== checklistStatusTypes.COMPLETE && (
                            <ChecklistsListItem
                                checklist={checklist}
                                contractChecklistsPath={`/governments/${contract.government_id}/contracts/${contract.id}/checklists`}
                                key={checklist.id}
                            />
                        )
                )}
            </ListGroupItem>
        );
    });

    const noItemsMessage = (
        <div className="text-center">
            <i className={`fa fa-check-circle ${styles.noChecklistsIcon}`} />
            <h5 className={styles.noChecklistsText}>No checklists in progress</h5>
        </div>
    );

    return (
        <Panel className={styles.checklistsPanel}>
            <Panel.Heading>
                <h4 className={styles.panelTitle}>{panelTitle}</h4>
            </Panel.Heading>
            <Panel.Body className={styles.panelBody}>
                <ListGroup>{contracts.length > 0 ? checklistItems : noItemsMessage}</ListGroup>
            </Panel.Body>
        </Panel>
    );
};

NeedsAttentionChecklists.propTypes = {
    contracts: PropTypes.array.isRequired,
    panelTitle: PropTypes.string.isRequired,
};

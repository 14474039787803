import { capitalDesignTokens } from '@opengov/capital-mui-theme';

export const requisitionsAdminNavContainerStyle = {
    backgroundColor: capitalDesignTokens.foundations.colors.gray50,
    borderBottom: `1px solid ${capitalDesignTokens.foundations.colors.gray200}`,
    mt: '-20px',
    pt: 2,
    pr: 8,
    pb: 0,
    pl: 8,
    gap: 0.5,
};

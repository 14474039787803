import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';

import { CustomVariableList } from './CustomVariableList';

export const CustomVariableMappingModal = ({ hideModal }) => {
    return (
        <Modal bsSize="lg" onHide={hideModal} show>
            <Modal.Header closeButton>
                <Modal.Title className="text-center">Custom Variables In Use</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CustomVariableList />
            </Modal.Body>
        </Modal>
    );
};

CustomVariableMappingModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
};

const { listToDict } = require('../helpers');
const { projectStatuses } = require('../projects');

const getStatusChangeEventName = (status) => `status:${status}`;

exports.getStatusFromEventName = (status) => status.replace('status:', '');

exports.projectStatusCycleTimeEventNamesMap = projectStatuses.reduce((map, status) => {
    map[status] = getStatusChangeEventName(status);
    return map;
}, {});

// We don't use `Object.values(projectStatusCycleTimeEventNamesMap)` here because ordering is
// important and we cannot guarantee the order returned from Object.values
exports.cycleTimeEvents = projectStatuses.map((status) => getStatusChangeEventName(status));

exports.projectEventTypes = [...exports.cycleTimeEvents, 'publicProjectView'];

exports.projectEventTypesDict = listToDict(exports.projectEventTypes);

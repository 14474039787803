import { Box, Typography } from '@og-pro/ui';
import qs from 'qs';
import React from 'react';
import PropTypes from 'prop-types';
import { OpenInNewOutlined } from '@mui/icons-material';

import { Link } from 'react-router-dom';

import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { useSelector } from 'react-redux';

import { lowerCase, upperFirst } from 'lodash';

import { projectScopeFilterTypesDict } from '@og-pro/shared-config/projects';

import { getMostRecentProject, parseReqRelations } from './sourcingStatus';

import { qaTagPageName } from '../constants';

import { getGovernmentId } from '../../../../GovApp/selectors';
import { formatResource } from '../../../RequisitionsApproval/RequisitionsApprovalHistory/Relations/RelationsDisplay/selectors';
import { getUserJS } from '../../../../selectors';

const { ALL_PROJECTS } = projectScopeFilterTypesDict;

const sourcingStatusSubtitleBaseProps = {
    alignItems: 'center',
    color: 'primary',
    component: Link,
    display: 'flex',
    fontWeight: 400,
    gap: 0.25,
    // Prevent opening requisition
    onClick: (e) => e.stopPropagation(),
    rel: 'noreferrer',
    target: '_blank',
    variant: 'bodyXSmall',
};

export const RequisitionListItemSourcingStatus = ({ reqRelations: rawReqRelations }) => {
    const reqRelations = parseReqRelations(rawReqRelations);
    const governmentId = useSelector(getGovernmentId);
    const user = useSelector(getUserJS);

    if (reqRelations.length === 0) {
        return null;
    }

    const isMultipleProjects = reqRelations.length > 1;
    const project = isMultipleProjects
        ? getMostRecentProject(reqRelations).project
        : reqRelations[0].project;

    const { id, financialId, isIntake } = project;
    const { statusClass, status: statusName } = formatResource(project, user, false);
    const type = isIntake ? 'Intake' : 'Solicitation';

    const projectListBasePath = `/governments/${governmentId}/projects`;
    const intakeListBasePath = `/governments/${governmentId}/intakes`;
    const basePath = isIntake ? intakeListBasePath : projectListBasePath;

    const path = isMultipleProjects
        ? `${basePath}?${qs.stringify({ scope: ALL_PROJECTS, ids: reqRelations.map((req) => req.project.id) })}`
        : `${projectListBasePath}/${id}${isIntake ? '/intake' : ''}`;

    const baseId = `#${financialId || id}`;
    const moreText = isMultipleProjects ? ` +${reqRelations.length - 1} More` : '';
    const additionalText = baseId + moreText;

    return (
        <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography className={`text-${statusClass}`} fontWeight={600} variant="h4">
                {upperFirst(lowerCase(statusName))}
            </Typography>
            <Box display="flex">
                <Typography
                    color={(theme) => theme.palette.text.secondary}
                    fontWeight={400}
                    variant="bodyXSmall"
                >
                    {type}:{' '}
                </Typography>
                <Typography
                    qaTag={`${qaTagPageName}-sourcingStatusLink-${id}`}
                    to={path}
                    {...sourcingStatusSubtitleBaseProps}
                >
                    <Typography fontWeight={400} sx={{ whiteSpace: 'nowrap' }} variant="bodyXSmall">
                        {additionalText}
                    </Typography>
                    <OpenInNewOutlined
                        fontSize={capitalDesignTokens.foundations.typography.fontSize.bodyXSmall}
                    />
                </Typography>
            </Box>
        </Box>
    );
};

RequisitionListItemSourcingStatus.propTypes = {
    reqRelations: PropTypes.array.isRequired,
};

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export class UploadError extends PureComponent {
    static propTypes = {
        meta: PropTypes.shape({
            error: PropTypes.string,
            touched: PropTypes.bool,
            valid: PropTypes.bool,
        }).isRequired,
        showValidation: PropTypes.bool,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const {
            meta: { error, touched, valid },
            showValidation,
        } = this.props;

        if ((!touched && !showValidation) || valid) {
            return null;
        }

        return (
            <div className={this.styles.formError}>
                <i className="fa fa-exclamation-triangle" /> {error}
            </div>
        );
    }
}

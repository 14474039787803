import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getShowConnectionAlert } from '../selectors';
import {
    hideConnectionAlert,
    reconnectionAlert,
    showConnectionAlert,
} from '../../actions/notification';

/**
 * Custom hook for connecting a socket and handling join, leave, and reconnect events.
 *
 * @param {Object} socket - The socket to connect to.
 * @param {Object} [handlers={}] - The event handlers for the socket.
 * @param {Function} [handlers.onJoin] - Function to call when a 'join' event occurs.
 * @param {Function} [handlers.onLeave] - Function to call when a 'leave' event occurs.
 * @param {Function} [handlers.onReconnect] - Function to call when a 'reconnect' event occurs.
 */
export const useConnectSocket = (socket, handlers = {}) => {
    const { onJoin, onLeave, onReconnect } = handlers;

    const dispatch = useDispatch();
    const shouldShowConnectionAlert = useSelector(getShowConnectionAlert);
    const shouldShowConnectionAlertRef = useRef(shouldShowConnectionAlert);

    const joinHandler = () => onJoin && onJoin();
    const leaveHandler = () => onLeave && onLeave();
    const reconnectHandler = () => onReconnect && onReconnect();

    useEffect(() => {
        shouldShowConnectionAlertRef.current = shouldShowConnectionAlert;
    }, [shouldShowConnectionAlert]);

    useEffect(() => {
        const socketReconnectHandler = () => {
            joinHandler();

            if (shouldShowConnectionAlertRef.current) {
                dispatch(reconnectionAlert());
            }

            reconnectHandler();
        };

        const socketConnectErrorHandler = () => {
            dispatch(showConnectionAlert());
        };

        socket.connect();
        socket.io.on('reconnect', socketReconnectHandler);
        socket.on('connect_error', socketConnectErrorHandler);
        joinHandler();

        return () => {
            if (shouldShowConnectionAlertRef.current) {
                dispatch(hideConnectionAlert());
            }

            socket.io.off('reconnect', socketReconnectHandler);
            socket.off('connect_error', socketConnectErrorHandler);

            // Take off event loop so any in flight messages can be sent before leaving the
            // room and disconnecting
            setTimeout(() => {
                leaveHandler();
                // Take off event loop so leave handler messages can be sent before disconnecting
                setTimeout(() => socket.disconnect());
            });
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

import React from 'react';
import { Panel } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { contractStatesDict } from '@og-pro/shared-config/contracts';

import { getContractJS } from '../../selectors';
import {
    ContractForm,
    LoadingError,
    LoadingSpinner,
    Main,
    SectionTitle,
} from '../../../components';
import { updateContract } from '../../../actions/contracts';

const { PUBLISHED } = contractStatesDict;

/*
 * NOTE: while this is "ContractCreate", really it's an update since we move a contract from the
 * DRAFT state to the PUBLISHED state
 */
export const ContractCreate = () => {
    const navigate = useNavigate();
    const params = useParams();

    const dispatch = useDispatch();
    const contract = useSelector(getContractJS);
    const createContractError = useSelector((state) => state.contracts.get('createContractError'));
    const creatingContract = useSelector((state) => state.contracts.get('creatingContract'));
    const updateContractError = useSelector((state) => state.contracts.get('updateContractError'));
    const updatingContract = useSelector((state) => !!state.contracts.get('updatingContract'));

    const handleContractCreateSuccess = (newContract) => {
        if (newContract.project_id) {
            navigate(
                `/governments/${newContract.government_id}/projects/${newContract.project_id}/contracts/${newContract.id}`,
                { replace: true }
            );
        } else {
            navigate(`/governments/${newContract.government_id}/contracts/${newContract.id}`, {
                replace: true,
            });
        }
    };

    const handleSubmit = (data) => {
        const { projectId } = params;

        data.state = PUBLISHED;

        // TODO: this should become a form field with a project association selector
        if (projectId) {
            data.projectId = Number.parseInt(projectId, 10);
        }

        dispatch(updateContract(contract.id, data, handleContractCreateSuccess));
    };

    const renderForm = () => {
        return (
            <Main className="row">
                <h1 className="visually-hidden">New Contract</h1>
                <div className="col-lg-offset-1 col-lg-10">
                    <SectionTitle title="New Contract" />
                    <ContractForm
                        buttonIcon={updatingContract ? 'fa-spin fa-spinner' : 'fa-plus'}
                        buttonStyle="success"
                        buttonText={updatingContract ? 'Creating Contract...' : 'Create Contract'}
                        contractId={contract.id}
                        contractProject={contract.project}
                        disabled={updatingContract}
                        onSubmit={handleSubmit}
                    />
                    {updateContractError && (
                        <div className="error-block text-center">{updateContractError}</div>
                    )}
                </div>
            </Main>
        );
    };

    const { projectId } = params;

    if (createContractError) {
        return <LoadingError error={createContractError} />;
    }

    if (creatingContract || !contract) {
        return <LoadingSpinner />;
    }

    if (projectId) {
        return renderForm();
    }

    return (
        <Panel>
            <Panel.Body>{renderForm()}</Panel.Body>
        </Panel>
    );
};

import { get } from 'lodash';

import { showSnackbar } from './notification';

export const LOAD_VENDOR = 'adminVendor/LOAD_VENDOR';
export const LOAD_VENDOR_SUCCESS = 'adminVendor/LOAD_VENDOR_SUCCESS';
export const LOAD_VENDOR_FAIL = 'adminVendor/LOAD_VENDOR_FAIL';

export function loadVendor(vendorId, data) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_VENDOR });
        return client
            .get(`/vendor/${vendorId}`, { data })
            .then((result) => {
                dispatch({ type: LOAD_VENDOR_SUCCESS, result });
            })
            .catch((error) => {
                dispatch({ type: LOAD_VENDOR_FAIL, error });
            });
    };
}

export const LOAD_ATTACHMENTS = 'adminVendor/LOAD_ATTACHMENTS';
export const LOAD_ATTACHMENTS_SUCCESS = 'adminVendor/LOAD_ATTACHMENTS_SUCCESS';
export const LOAD_ATTACHMENTS_FAIL = 'adminVendor/LOAD_ATTACHMENTS_FAIL';

export function loadVendorAttachments(vendorId) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_ATTACHMENTS });
        return client
            .get(`/vendor/${vendorId}/attachment`)
            .then((result) => {
                dispatch({ type: LOAD_ATTACHMENTS_SUCCESS, result });
            })
            .catch((error) => {
                dispatch({ type: LOAD_ATTACHMENTS_FAIL, error });
            });
    };
}

export const CREATE_ATTACHMENT_SUCCESS = 'adminVendor/CREATE_ATTACHMENT_SUCCESS';

export function createVendorAttachment(vendorId, attachmentData) {
    return (dispatch, getState, client) => {
        return client
            .post(`/vendor/${vendorId}/attachment`, { data: attachmentData })
            .then((result) => {
                dispatch(showSnackbar('Document added!'));
                dispatch({
                    type: CREATE_ATTACHMENT_SUCCESS,
                    result,
                    uploadType: attachmentData.vendorUploadType,
                });
            });
    };
}

export const DELETE_ATTACHMENT_SUCCESS = 'adminVendor/DELETE_ATTACHMENT_SUCCESS';

export function deleteVendorAttachment(vendorId, attachment) {
    return (dispatch, getState, client) => {
        const uploadType = get(attachment, 'vendorAttachments.vendor_upload_type');
        const attachmentId = attachment.id;
        return client.del(`/vendor/${vendorId}/attachment/${attachmentId}`).then(() => {
            dispatch(showSnackbar('Document deleted'));
            dispatch({ type: DELETE_ATTACHMENT_SUCCESS, uploadType, attachmentId });
        });
    };
}

export const SHOW_VENDOR_ACCOUNT_MODAL = 'adminVendor/SHOW_VENDOR_ACCOUNT_MODAL';
export const HIDE_VENDOR_ACCOUNT_MODAL = 'adminVendor/HIDE_VENDOR_ACCOUNT_MODAL';

/**
 * @param {object} [opts={}] Options to pass to the vendor account modal
 * @param {Function} [opts.onComplete] Function to run after completion of form
 * @param {boolean} [opts.immediatelyDisplayForm] Skip straight to the form
 * @return {object}
 */
export function showVendorAccountModal(opts = {}) {
    return { type: SHOW_VENDOR_ACCOUNT_MODAL, ...opts };
}

export function hideVendorAccountModal() {
    return { type: HIDE_VENDOR_ACCOUNT_MODAL };
}

export const ADD_CERTIFICATION = 'adminVendor/ADD_CERTIFICATION';
export const CANCEL_ADD_CERTIFICATION = 'adminVendor/CANCEL_ADD_CERTIFICATION';

export function addVendorCertification() {
    return {
        type: ADD_CERTIFICATION,
        certification: {
            vendorCertifications: [
                {
                    certification_source_id: 1,
                },
            ],
        },
    };
}

export function cancelAddVendorCertification() {
    return { type: CANCEL_ADD_CERTIFICATION };
}

export const LOAD_CERTIFICATIONS = 'adminVendor/LOAD_CERTIFICATIONS';
export const LOAD_CERTIFICATIONS_SUCCESS = 'adminVendor/LOAD_CERTIFICATIONS_SUCCESS';
export const LOAD_CERTIFICATIONS_FAIL = 'adminVendor/LOAD_CERTIFICATIONS_FAIL';

export function loadVendorCertifications(vendorId) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_CERTIFICATIONS });
        return client
            .get(`/vendor/${vendorId}/certification`)
            .then((result) => {
                dispatch({ type: LOAD_CERTIFICATIONS_SUCCESS, result });
            })
            .catch((error) => {
                dispatch({ type: LOAD_CERTIFICATIONS_FAIL, error });
            });
    };
}

export function loadAllCertifications() {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_CERTIFICATIONS });
        return client
            .get('/vendor/certifications')
            .then((result) => {
                dispatch({ type: LOAD_CERTIFICATIONS_SUCCESS, result });
            })
            .catch((error) => {
                dispatch({ type: LOAD_CERTIFICATIONS_FAIL, error });
            });
    };
}

export const UPDATE_CERTIFICATION = 'adminVendor/UPDATE_CERTIFICATION';
export const UPDATE_CERTIFICATION_SUCCESS = 'adminVendor/UPDATE_CERTIFICATION_SUCCESS';

export function updateVendorCertification(vendorId, certificationId, data) {
    return (dispatch, getState, client) => {
        dispatch({ type: UPDATE_CERTIFICATION, vendorId, certificationId, data });
        return client
            .put(`/vendor/${vendorId}/certification/${certificationId}`, { data })
            .then((result) => {
                dispatch({ type: UPDATE_CERTIFICATION_SUCCESS, result });
                if (data.id) {
                    dispatch(
                        showSnackbar(`Changes to your ${data.title} certification have been saved.`)
                    );
                } else {
                    dispatch(showSnackbar(`Your ${data.title} certification has been saved.`));
                }
            })
            .catch(() => {
                if (data.id) {
                    dispatch(
                        showSnackbar(`Error saving your ${data.title} certification.`, {
                            isError: true,
                        })
                    );
                } else {
                    dispatch(
                        showSnackbar(`Error saving changes to your ${data.title} certification.`, {
                            isError: true,
                        })
                    );
                }
            });
    };
}

export const DELETE_CERTIFICATION = 'adminVendor/DELETE_CERTIFICATION';
export const DELETE_CERTIFICATION_SUCCESS = 'adminVendor/DELETE_CERTIFICATION_SUCCESS';

export function deleteVendorCertification(vendorId, certificationId) {
    return (dispatch, getState, client) => {
        dispatch({ type: DELETE_CERTIFICATION, certificationId });
        return client
            .del(`/vendor/${vendorId}/certification/${certificationId}`)
            .then((result) => {
                dispatch({ type: DELETE_CERTIFICATION_SUCCESS, result });
                dispatch(showSnackbar('Your certification was removed.'));
            })
            .catch(() => {
                dispatch(showSnackbar('Error removing your certification.', { isError: true }));
            });
    };
}

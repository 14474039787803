import React from 'react';
import classnames from 'classnames';

import { SectionType } from '../constants';

export const ProjectSectionNavItemStatus = ({ section }) => {
    const styles = require('./index.scss');
    const { showValidationError, comments } = section;
    const hasComments = comments > 0;
    const showCommentCount = hasComments && !showValidationError;

    if (!showValidationError && !hasComments) {
        return null;
    }

    return (
        <div
            className={classnames(styles.sectionIcon, {
                [styles.error]: showValidationError,
                [styles.comments]: showCommentCount,
            })}
        >
            <i
                className={classnames('fa fa-fw', {
                    'fa-exclamation-triangle': showValidationError,
                    'fa-comment-o': showCommentCount,
                })}
            />{' '}
            {showCommentCount && <span>{comments}</span>}
        </div>
    );
};

ProjectSectionNavItemStatus.propTypes = {
    section: SectionType,
};

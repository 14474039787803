import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { setAriaLabel } from '../hooks/useSetAriaLabel';

export const AriaLabelSetter = ({ ariaLabel, query, children }) => {
    const closestRef = useRef(null);
    useEffect(() => {
        setAriaLabel(ariaLabel, query, closestRef.current);
    }, [ariaLabel, query, closestRef]);
    return (
        <div id={`AriaLabelSetter-${ariaLabel}`} ref={closestRef}>
            {children}
        </div>
    );
};

AriaLabelSetter.propTypes = {
    ariaLabel: PropTypes.string.isRequired,
    children: PropTypes.node,
    query: PropTypes.number.isRequired,
};

export default AriaLabelSetter;

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';

import { EMAIL, form } from './constants';
import { loginValidate as validate } from './validate';
import { loginRouter as authLoginRouter } from '../../../../actions/auth';
import { Button, InputText } from '../../../../components';

const mapStateToProps = (state) => {
    return {
        loggingIn: state.auth.get('loggingIn'),
        loginWithOpenGov: state.auth.get('loginWithOpenGov'),
    };
};

const mapDispatchToProps = {
    loginRouter: authLoginRouter,
};

const formConfig = {
    form,
    validate,
};

// @connect
// @reduxForm
const ConnectedLoginEmailForm = ({
    handleSubmit,
    invalid,
    isSessionTimeout,
    loggingIn,
    loginRouter,
    loginWithOpenGov,
    onSuccess,
    usePlatform,
}) => {
    const handleLoginEmail = (data) => {
        return loginRouter(data[EMAIL], usePlatform, onSuccess);
    };

    const styles = require('./index.scss');

    return (
        <form onSubmit={handleSubmit(handleLoginEmail)}>
            <Field
                autoFocus
                component={InputText}
                disabled={loggingIn || loginWithOpenGov || isSessionTimeout}
                label="Email Address"
                name={EMAIL}
                placeholder="Enter your email address"
                qaTag="login-email"
                type="text"
            />
            <Button
                block
                bsStyle="opengov"
                disabled={loggingIn || loginWithOpenGov || invalid}
                qaTag="login-continue"
                type="submit"
            >
                Continue
            </Button>
            {loginWithOpenGov && (
                <div className={classnames(styles.redirect, 'text-muted')}>
                    <i className="fa fa-spinner fa-spin" /> Redirecting to OpenGov...
                </div>
            )}
        </form>
    );
};

ConnectedLoginEmailForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool.isRequired,
    isSessionTimeout: PropTypes.bool,
    loggingIn: PropTypes.bool,
    loginWithOpenGov: PropTypes.bool.isRequired,
    loginRouter: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    usePlatform: PropTypes.bool,
};

export const LoginEmailForm = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedLoginEmailForm);

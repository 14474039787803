import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';

import { form, IS_PUBLISHED, USER_ID } from './constants';
import { EditingDisabledWell } from './EditingDisabledWell';
import { validate } from './validate';
import {
    LoadingButton,
    QuestionnaireDisplay,
    QuestionnaireFormBuilder,
    SearchSelect,
    SearchSelectUserOption,
    Toggle,
    Well,
} from '../../../../../components';
import { QuestionnaireMultipleChoiceFormContext } from '../../../../../components/contexts';
import { getTemplateAdminUserList } from '../../../../../selectors/govApp';

const formConfig = {
    form,
    validate,
};

// @reduxForm
const ConnectedTemplateQuestionCreateForm = ({
    disabled,
    handleSubmit,
    isEditForm,
    isEditingDisabled,
    selectedQuestionnaire,
    templateInUse,
    updateError,
}) => {
    const questionnaire = useSelector((state) => getFormValues(form)(state));
    const userSelectOptions = useSelector(getTemplateAdminUserList);

    const styles = require('./index.scss');

    const btnStyle = isEditForm ? 'primary' : 'success';
    const btnIcon = isEditForm ? 'fa-check' : 'fa-plus';
    const btnText = isEditForm ? 'Edit' : 'Create';

    return (
        <>
            {isEditingDisabled && (
                <div className={`row text-center ${styles.templateInUse}`}>
                    <div className="col-md-8 col-md-offset-2">
                        <EditingDisabledWell templateTitle={templateInUse.template.title} />
                    </div>
                </div>
            )}
            <div className="row">
                <div className="col-md-7">
                    <QuestionnaireMultipleChoiceFormContext.Provider value={{ isEditingDisabled }}>
                        <QuestionnaireFormBuilder
                            allowDefaultValue
                            disabled={disabled}
                            form={form}
                            isTitleEditingDisabled={isEditingDisabled}
                            type={selectedQuestionnaire.type}
                        />
                    </QuestionnaireMultipleChoiceFormContext.Provider>
                    <Field
                        backspaceRemovesValue={false}
                        component={SearchSelect}
                        components={{
                            Option: SearchSelectUserOption,
                        }}
                        disabled={disabled}
                        label="Shared Question Owner"
                        name={USER_ID}
                        options={userSelectOptions}
                        placeholder="Select shared question owner"
                    />
                    <Field
                        component={Toggle}
                        label="Set Shared Question Live"
                        labelInline
                        name={IS_PUBLISHED}
                        qaTag="sharedQuestionCreateModal-setLive"
                        tooltip="Shared Question will not be usable until it is live"
                    />
                </div>
                <div className="col-md-5">
                    <div className={styles.previewTitle}>Preview</div>
                    <Well className="questionnaire-item">
                        <QuestionnaireDisplay
                            {...questionnaire}
                            type={selectedQuestionnaire.type}
                        />
                    </Well>
                </div>
            </div>
            <div className="row">
                <div className="col-md-7 text-center">
                    <LoadingButton
                        bsStyle={btnStyle}
                        disabled={disabled}
                        icon={btnIcon}
                        loading={disabled}
                        onClick={handleSubmit}
                        qaTag="sharedQuestionCreateModal-create"
                        text={`${btnText} Shared Question`}
                    />
                    {updateError && <div className="error-block">{updateError}</div>}
                </div>
            </div>
        </>
    );
};

ConnectedTemplateQuestionCreateForm.propTypes = {
    disabled: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    isEditForm: PropTypes.bool,
    isEditingDisabled: PropTypes.bool,
    selectedQuestionnaire: PropTypes.shape({
        title: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
    }).isRequired,
    templateInUse: PropTypes.shape({
        template: PropTypes.shape({
            title: PropTypes.string,
        }).isRequired,
    }),
    updateError: PropTypes.string,
};

export const TemplateQuestionCreateForm = reduxForm(formConfig)(
    ConnectedTemplateQuestionCreateForm
);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { compact } from 'lodash';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Box, Typography } from '@og-pro/ui';

import { attachmentTypesDict } from '@og-pro/shared-config/attachments';

import { AttachmentListItem } from './AttachmentListItem';
import { getAppendixIdsMap } from '../../../containers/GovApp/selectors';
import { attachmentFieldsDict } from './constants';
import { ALL_APPENDICES } from '../../../constants/attachments';
import { getDndStyle } from '../../../constants/styles';
import { CDSDropdownButton, MenuItem } from '../..';

const { APPENDIX_ID, TYPE } = attachmentFieldsDict;

const mapStateToProps = (state, props) => {
    return {
        appendixIdsMap: getAppendixIdsMap(state, props),
    };
};

// @connect
class ConnectedAttachmentList extends Component {
    static propTypes = {
        allowedReassignmentTypes: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.string,
            })
        ),
        appendixIdsMap: PropTypes.object.isRequired,
        attachmentType: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.string),
        ]),
        change: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        emptyListTitle: PropTypes.string,
        emptyListSubtitle: PropTypes.string,
        excludeAttachmentType: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.string),
        ]),
        hideAppendixLetter: PropTypes.bool,
        isOGThemeEnabledForComponents: PropTypes.bool,
        fields: PropTypes.shape({
            forEach: PropTypes.func.isRequired,
            get: PropTypes.func.isRequired,
            map: PropTypes.func.isRequired,
            name: PropTypes.string.isRequired,
            remove: PropTypes.func.isRequired,
            move: PropTypes.func.isRequired,
        }).isRequired,
        // Used by the selector
        form: PropTypes.string.isRequired,
        hideNoAttachments: PropTypes.bool,
        renderQuestionLogicIcon: PropTypes.func,
        label: PropTypes.string,
        triggerUpdate: PropTypes.func,
    };

    static defaultProps = {
        label: 'Uploaded Attachments:',
    };

    get styles() {
        return require('./AttachmentList.scss');
    }

    attachmentHidden = (attachment) => {
        const { attachmentType, excludeAttachmentType } = this.props;

        const attachmentTypeAsArray = Array.isArray(attachmentType)
            ? attachmentType
            : [attachmentType];
        const excludeAttachmentTypeAsArray = Array.isArray(excludeAttachmentType)
            ? excludeAttachmentType
            : [excludeAttachmentType];

        if (
            attachment.isHiddenByLogic ||
            (attachmentType && !attachmentTypeAsArray.includes(attachment.type)) ||
            (excludeAttachmentType && excludeAttachmentTypeAsArray.includes(attachment.type))
        ) {
            return true;
        }

        return false;
    };

    handleDragEnd = (result) => {
        const {
            change,
            fields: { forEach, get, move },
            form,
        } = this.props;

        const originLocation = result.source.index;
        const newLocation = result.destination ? result.destination.index : undefined;

        if (newLocation !== undefined && newLocation !== originLocation) {
            const appendices = [];
            forEach((name, index) => {
                const attachment = get(index);

                if (!this.attachmentHidden(attachment)) {
                    appendices.push(attachment.appendixId);
                }
            });

            move(originLocation, newLocation);

            // Hack to make sure the `move` function completes before re-lettering
            setTimeout(() => {
                let shownIndex = 0;

                forEach((name, index) => {
                    const attachment = get(index);

                    if (!this.attachmentHidden(attachment)) {
                        change(form, `${name}.${APPENDIX_ID}`, appendices[shownIndex]);
                        shownIndex++;
                    }
                });
            });
        }
    };

    removeField = (index) => () => {
        this.props.fields.remove(index);
    };

    typeReassignmentButton = (member) => {
        const { allowedReassignmentTypes, appendixIdsMap, change, disabled, form, triggerUpdate } =
            this.props;

        if (!allowedReassignmentTypes?.length) {
            return null;
        }

        return (
            <CDSDropdownButton disabled={disabled} size="small" title="Move to" variant="text">
                {allowedReassignmentTypes.map((type) => (
                    <MenuItem
                        key={type.value}
                        onClick={() => {
                            change(form, `${member}.${TYPE}`, type.value);

                            // if we are moving an attachment to something other than OTHER
                            // remove the appendix letter
                            if (type.value !== attachmentTypesDict.OTHER) {
                                change(form, `${member}.${APPENDIX_ID}`, null);
                            } else {
                                // otherwise grab the first available letter
                                const availableAppendix = ALL_APPENDICES.find(
                                    (letter) => !appendixIdsMap[letter]
                                );
                                change(form, `${member}.${APPENDIX_ID}`, availableAppendix);
                            }

                            if (triggerUpdate) {
                                setTimeout(() => triggerUpdate(), 0);
                            }
                        }}
                        qaTag="projectCreateAttachments-typeReassignment"
                    >
                        {type.label}
                    </MenuItem>
                ))}
            </CDSDropdownButton>
        );
    };

    renderAttachmentItems() {
        const {
            appendixIdsMap,
            attachmentType,
            disabled,
            fields,
            hideAppendixLetter,
            isOGThemeEnabledForComponents,
            renderQuestionLogicIcon,
        } = this.props;

        const availableAppendices = ALL_APPENDICES.map((letter) => {
            return {
                disabled: !!appendixIdsMap[letter],
                label: letter,
                value: letter,
            };
        });

        return fields.map((member, index) => {
            const attachment = fields.get(index);

            if (this.attachmentHidden(attachment)) {
                return null;
            }

            const questionLogicIcon = renderQuestionLogicIcon
                ? renderQuestionLogicIcon(attachment)
                : undefined;
            const typeReassignmentButton = this.typeReassignmentButton(member);

            // Disallow drag and drop on project documents
            const dragAndDropDisabled =
                !attachment.appendixId || attachmentType === attachmentTypesDict.INTERNAL;

            // eslint-disable-next-line valid-jsdoc
            /**
             * Redux forms needs to know the exact index of the attachment.
             * Basically, attachment is just an array, and you remove the
             * index from the array to delete, or push onto the array to
             * add.
             */
            return (
                <Draggable
                    draggableId={index.toString()}
                    index={index}
                    isDragDisabled={dragAndDropDisabled || disabled}
                    key={index}
                >
                    {(provided) => (
                        <li ref={provided.innerRef} {...provided.draggableProps}>
                            <AttachmentListItem
                                appendixOptions={availableAppendices}
                                arrayName={member}
                                attachment={attachment}
                                disabled={disabled}
                                dragHandleProps={!dragAndDropDisabled && provided.dragHandleProps}
                                hideAppendixLetter={hideAppendixLetter}
                                isOGThemeEnabledForComponents={isOGThemeEnabledForComponents}
                                key={index}
                                questionLogicIcon={questionLogicIcon}
                                remove={this.removeField(index)}
                                typeReassignmentButton={typeReassignmentButton}
                            />
                        </li>
                    )}
                </Draggable>
            );
        });
    }

    render() {
        const {
            disabled,
            emptyListTitle,
            emptyListSubtitle,
            hideNoAttachments,
            isOGThemeEnabledForComponents,
            label,
        } = this.props;

        const attachmentItems = compact(this.renderAttachmentItems());

        if (hideNoAttachments && attachmentItems.length === 0) {
            if (isOGThemeEnabledForComponents) {
                return (
                    <Box className={this.styles.noAttachmentsContainer} py={9}>
                        <Box textAlign="center">
                            <Box>
                                <i className="fa fa-paperclip fa-flip-horizontal fa-flip-vertical" />
                            </Box>
                            <Typography variant="h3">
                                {emptyListTitle || 'No Attachments Included'}
                            </Typography>
                            <Typography>
                                {emptyListSubtitle || 'Add an attachment above to see it here.'}
                            </Typography>
                        </Box>
                    </Box>
                );
            }

            return null;
        }

        return (
            <Box
                className={classnames({
                    [this.styles.attachmentsContainer]: isOGThemeEnabledForComponents,
                })}
            >
                {!isOGThemeEnabledForComponents && (
                    <label className={this.styles.uploadedAttachmentsLabel}>{label}</label>
                )}
                {isOGThemeEnabledForComponents && <Typography variant="h4">{label}</Typography>}

                <DragDropContext onDragEnd={this.handleDragEnd}>
                    <Droppable
                        droppableId="edit-project-attachments-table"
                        isDropDisabled={disabled}
                    >
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                style={getDndStyle(snapshot)}
                                {...provided.droppableProps}
                            >
                                <div
                                    className={classnames({
                                        [this.styles.uploadedAttachments]:
                                            !isOGThemeEnabledForComponents,
                                    })}
                                >
                                    <ul className="list-unstyled">
                                        {attachmentItems}
                                        {provided.placeholder}
                                    </ul>
                                </div>
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </Box>
        );
    }
}

export const AttachmentList = connect(mapStateToProps)(ConnectedAttachmentList);

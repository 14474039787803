import { isNil } from 'lodash';

import {
    getQuestionLogicValueDisplay,
    questionLogicLogicableModelNames,
    questionLogicOperatorNamesDisplay,
    requisitionFieldLogicableIds,
} from '@og-pro/shared-config/questionLogics';

const getLogicableName = (questionLogic, flags) => {
    const { logicable, logicable_id: logicableId, operator, value } = questionLogic;
    if (!logicable || !operator || isNil(value)) {
        return;
    }

    switch (logicable) {
        case questionLogicLogicableModelNames.FLAG:
            return flags.find((f) => f.id === logicableId).name;
        case questionLogicLogicableModelNames.REQUISITION_FIELD: {
            if (requisitionFieldLogicableIds.TOTAL_SPEND) {
                return 'Total Spend';
            }
            return undefined;
        }
        default:
            return undefined;
    }
};

export const getQuestionLogicDisplayText = (questionLogic, flags) => {
    const logicableName = getLogicableName(questionLogic, flags);
    if (!logicableName) {
        return 'Condition pending';
    }

    return `When "${logicableName}" ${
        questionLogicOperatorNamesDisplay[questionLogic.operator]
    } ${getQuestionLogicValueDisplay(questionLogic)}`;
};

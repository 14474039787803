import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@og-pro/ui';
import { Field } from 'redux-form';
import classNames from 'classnames';

import { InputText } from '../../InputText';
import { fieldsEditableFieldNames } from './constants';

const { VALUE } = fieldsEditableFieldNames;

// SignatureHeader is just a regular Signature Block except the "styling" prop is different
// The block contains only 1 element in the "fields" prop which is the actual Header field.
// that's why we render like this, we are certain that's the only element and that it exists.
// Cant be removed, you have to remove the whole block.
export const SignatureHeader = ({ disabled, showValidation, name }) => {
    const styles = require('./index.scss');
    return (
        <Box>
            <Box mb={2}>
                <Box>
                    <Typography fontWeight={500}>Signature Header</Typography>
                </Box>
                <Box>
                    <Typography
                        className={classNames(styles.description, styles.descriptionMarginBottom)}
                    >
                        This header is intended to cover all signatures within the column, eg
                        &quot;City of Alameda&quot; or &quot;The RFPeeps, Inc.&quot; If you have
                        multiple organizations within one column, you can include multiple headers.
                    </Typography>
                </Box>
            </Box>
            <Box>
                <Field
                    component={InputText}
                    disabled={disabled}
                    hasFeedback={false}
                    label={null}
                    name={`${name}[0].${VALUE}`}
                    placeholder="Enter Text"
                    qaTag="signatureForm-fieldHeaderValue"
                    showValidation={showValidation}
                    useOpenGovStyle
                />
            </Box>
        </Box>
    );
};

SignatureHeader.propTypes = {
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    showValidation: PropTypes.bool,
};

import { fromJS } from 'immutable';

import * as questionsActions from '../actions/questions';

const initialReleaseResponsesModalState = {
    initialQuestionIdsToRelease: null,
    showReleaseResponsesModal: false,
};

const initialState = fromJS({
    ...initialReleaseResponsesModalState,
    loaded: false,
    loading: false,
    posted: false,
    postError: null,
    posting: false,
    questions: [],
    releaseResponsesError: null,
    releasingResponses: false,
    shouldShowQuestionForm: false,
});

function questionReducer(state, action) {
    if (state.get('id') !== action.questionId) return state;

    switch (action.type) {
        case questionsActions.POST_RESPONSE:
            return state.merge(
                fromJS({
                    responding: true,
                    respondError: null,
                })
            );
        case questionsActions.POST_RESPONSE_SUCCESS:
            return fromJS(action.result);
        case questionsActions.POST_RESPONSE_FAIL:
            return state.merge(
                fromJS({
                    responding: false,
                    respondError: action.error && action.error.message,
                })
            );
        case questionsActions.TOGGLE_EDIT_QUESTION_FORM:
            return state.set('showEditQuestionForm', !state.get('showEditQuestionForm'));
        case questionsActions.TOGGLE_EDIT_RESPONSE_FORM:
            return state.set('showEditResponseForm', !state.get('showEditResponseForm'));
        case questionsActions.ASSIGN_USER:
            return state.merge(
                fromJS({
                    assigningUser: true,
                    assignUserError: null,
                })
            );
        case questionsActions.ASSIGN_USER_SUCCESS:
            return fromJS(action.result);
        case questionsActions.ASSIGN_USER_FAIL:
            return state.merge(
                fromJS({
                    assigningUser: false,
                    assignUserError: action.error && action.error.message,
                })
            );
        default:
            return state;
    }
}

export default function questionsReducer(state = initialState, action = {}) {
    switch (action.type) {
        case questionsActions.RESET:
            return initialState;
        case questionsActions.LOAD_QUESTIONS:
            return state.merge(
                fromJS({
                    loading: true,
                    loaded: false,
                    loadError: null,
                })
            );
        case questionsActions.LOAD_QUESTIONS_SUCCESS:
            return state.merge(
                fromJS({
                    loading: false,
                    loaded: true,
                    questions: fromJS(action.result),
                    projectId: action.projectId,
                })
            );
        case questionsActions.LOAD_QUESTIONS_FAIL:
            return state.merge(
                fromJS({
                    loading: false,
                    loaded: false,
                    loadError: action.error.message,
                })
            );
        case questionsActions.POST_QUESTION:
            return state.merge(
                fromJS({
                    posting: true,
                    posted: false,
                    postError: null,
                })
            );
        case questionsActions.POST_QUESTION_SUCCESS: {
            // Because newly added questions are broadcasted from the sync server to all user, the
            // question might already be in the list of questions if the question was created
            // internally by a government user. For that reason, we need to check if the question
            // is already in the store before adding it.
            const alreadyHasQuestionNewQuestion = state
                .get('questions')
                .some((question) => question.get('id') === action.result.id);
            const questions = alreadyHasQuestionNewQuestion
                ? state.get('questions')
                : state.get('questions').push(fromJS(action.result));
            return state.merge(
                fromJS({
                    posting: false,
                    posted: true,
                    questions,
                })
            );
        }
        case questionsActions.POST_QUESTION_FAIL:
            return state.merge(
                fromJS({
                    posting: false,
                    posted: false,
                    postError: action.error.message,
                })
            );
        case questionsActions.POST_RESPONSE:
        case questionsActions.POST_RESPONSE_SUCCESS:
        case questionsActions.POST_RESPONSE_FAIL:
        case questionsActions.ASSIGN_USER:
        case questionsActions.ASSIGN_USER_SUCCESS:
        case questionsActions.ASSIGN_USER_FAIL:
        case questionsActions.TOGGLE_EDIT_QUESTION_FORM:
        case questionsActions.TOGGLE_EDIT_RESPONSE_FORM:
            return state.set(
                'questions',
                state.get('questions').map((question) => questionReducer(question, action))
            );
        case questionsActions.SHOW_QUESTION_FORM:
            return state.set('shouldShowQuestionForm', true);
        case questionsActions.HIDE_QUESTION_FORM:
            return state.set('shouldShowQuestionForm', false);
        case questionsActions.SHOW_RELEASE_RESPONSES_MODAL:
            return state.merge(
                fromJS({
                    showReleaseResponsesModal: true,
                    initialQuestionIdsToRelease: action.questionIds,
                })
            );
        case questionsActions.HIDE_RELEASE_RESPONSES_MODAL:
            return state.merge(fromJS(initialReleaseResponsesModalState));
        case questionsActions.RELEASE_RESPONSES:
            return state.merge(
                fromJS({
                    releasingResponses: true,
                    releaseResponsesError: null,
                })
            );
        case questionsActions.RELEASE_RESPONSES_SUCCESS:
            return state.set('releasingResponses', false).set(
                'questions',
                state.get('questions').map((question) => {
                    const foundQuestion = action.result.find((q) => q.id === question.get('id'));
                    if (foundQuestion) {
                        return fromJS(foundQuestion);
                    }
                    return question;
                })
            );
        case questionsActions.RELEASE_RESPONSES_FAIL:
            return state.merge(
                fromJS({
                    releasingResponses: false,
                    releaseResponsesError: action.error.message,
                })
            );
        default:
            return state;
    }
}

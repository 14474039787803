import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ListGroupItem } from 'react-bootstrap';

export class ContractComplaintsListItem extends Component {
    static propTypes = {
        contractComplaint: PropTypes.shape({
            id: PropTypes.number.isRequired,
            isResolved: PropTypes.bool.isRequired,
            resolvedAt: PropTypes.string,
            submittedAt: PropTypes.string,
            title: PropTypes.string.isRequired,
        }).isRequired,
        showContractComplaintModal: PropTypes.func.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    showContractComplaintModal = () => {
        const { contractComplaint, showContractComplaintModal } = this.props;

        showContractComplaintModal(contractComplaint);
    };

    render() {
        const {
            contractComplaint: {
                id: contractComplaintId,
                isResolved,
                resolvedAt,
                submittedAt,
                title,
            },
        } = this.props;

        return (
            <ListGroupItem key={contractComplaintId} onClick={this.showContractComplaintModal}>
                <h5>{title}</h5>
                <div className={this.styles.submittedAt}>
                    <small>
                        {submittedAt
                            ? moment(submittedAt).format('lll')
                            : 'Will be submitted when review is posted'}
                    </small>
                </div>
                <div className={isResolved ? 'text-success' : 'text-danger'}>
                    <i className={`fa fa-${isResolved ? 'check-circle-o' : 'ban'}`} />
                    &nbsp;
                    {isResolved ? 'Resolved' : 'Unresolved'}
                    {isResolved && (
                        <small className="text-muted">
                            &nbsp;on {moment(resolvedAt).format('lll')}
                        </small>
                    )}
                </div>
            </ListGroupItem>
        );
    }
}

import { get } from 'lodash';
import { createSelector } from 'reselect';
import {
    questionLogicActionNames,
    questionLogicFieldNames,
    questionLogicOperatorNames,
    questionLogicLogicableModelNames,
} from '@og-pro/shared-config/questionLogics';

import { deserializeData } from './utils';
import { pseudoFieldNames } from './constants';

const { HIDE } = questionLogicActionNames;

const { ACTION, OPERATOR, LOGICABLE } = questionLogicFieldNames;

const { EQUAL } = questionLogicOperatorNames;

const { PROJECT_SECTION_ID } = pseudoFieldNames;

const getModalFormData = (state) => state.templatesAdmin.get('questionLogicModalData');

export const getInitialFormValues = createSelector([getModalFormData], (rawModalData) => {
    const defaultValues = {
        [OPERATOR]: EQUAL,
        [ACTION]: HIDE,
        [LOGICABLE]: questionLogicLogicableModelNames.UPFRONT_QUESTION,
    };

    if (rawModalData) {
        const modalData = rawModalData.toJS();
        return deserializeData({
            ...defaultValues,
            ...modalData,
            [PROJECT_SECTION_ID]: get(modalData, 'projectSection.id'),
        });
    }
    return defaultValues;
});

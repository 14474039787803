import React, { useContext } from 'react';
import { formValueSelector } from 'redux-form';
import { useSelector } from 'react-redux';

import { form } from '../../../../../forms/constants';
import { fieldNames as formInputFieldNames } from '../../../../../forms/TemplateProjectSectionsForm/constants';
import { TemplateEditV2ManageSectionContext } from '../../../../context';
import { TemplateEditV2EditSection } from '../EditSection';

const { PROJECT_SECTIONS } = formInputFieldNames;

const selector = formValueSelector(form);

export const TemplateEditV2DrawerContentEditSectionBlock = () => {
    const {
        data: { index },
        setData,
    } = useContext(TemplateEditV2ManageSectionContext);
    const sections = useSelector((state) => selector(state, PROJECT_SECTIONS));

    const unsetEditing = () => {
        setData({
            active: false,
            editing: false,
            index: null,
            isTemplate: null,
            sectionType: null,
        });
    };

    if (index === null || !sections) {
        return null;
    }

    const selectedSection = sections[index];

    if (!selectedSection) {
        return null;
    }

    return (
        <TemplateEditV2EditSection
            afterCancel={unsetEditing}
            afterDelete={unsetEditing}
            afterSave={unsetEditing}
            allowDeletion
            index={index}
            showActionsOnFooter
            showAutomationsButton
        />
    );
};

TemplateEditV2DrawerContentEditSectionBlock.propTypes = {};

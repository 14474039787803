import { fieldNames } from './constants';
import { arrayError, questionnaire as questionnaireValidator } from '../../../../Forms/validation';

const { QUESTIONNAIRES, USER_ID } = fieldNames;

export const validate = (values) => {
    const errors = {};

    if (!values[USER_ID]) {
        errors[USER_ID] = 'Checklist owner is required';
    }

    // At least one questionnaire must be included
    if (!values[QUESTIONNAIRES] || values[QUESTIONNAIRES].length === 0) {
        errors[QUESTIONNAIRES] = arrayError('Please include at least one question');
        return errors;
    }

    errors[QUESTIONNAIRES] = values[QUESTIONNAIRES].map((question) =>
        questionnaireValidator(question)
    );

    return errors;
};

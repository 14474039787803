import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Main, SectionTitle, ZeroState } from '../../../../../components';
import { EvaluatorsListItem } from '../../../../../components/GovApp';

export class EvaluatorsReview extends PureComponent {
    static propTypes = {
        agreements: PropTypes.array.isRequired,
        evaluators: PropTypes.array.isRequired,
        standardDocument: PropTypes.object,
    };

    render() {
        const { agreements, evaluators, standardDocument } = this.props;

        const EvaluatorsList = evaluators.map((evaluator, idx) => {
            const agreement = agreements.find((a) => a.user_id === evaluator.id);
            return (
                <EvaluatorsListItem
                    agreement={agreement}
                    isFirstItem={idx === 0}
                    key={evaluator.id}
                    standardDocument={standardDocument}
                    user={evaluator}
                />
            );
        });

        return (
            <Main>
                <SectionTitle
                    info="List of colleagues who will be evaluating the responses"
                    title="Evaluators"
                />
                {this.props.evaluators.length === 0 ? (
                    <ZeroState title="No evaluators have been added yet" />
                ) : (
                    EvaluatorsList
                )}
            </Main>
        );
    }
}

export const bidBondSecuritySelectOptions = [
    {
        label: 'Percentage',
        value: true,
    },
    {
        label: 'Amount',
        value: false,
    },
];

export const govForm = 'governmentProposalCreate';
export const vendorForm = 'vendorProposalCreate';

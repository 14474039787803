import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { editForm } from '../constants';
import { DepartmentEditForm } from '../DepartmentEditForm';
import { DepartmentFormDisplay } from '../DepartmentFormDisplay';

export class DepartmentListItem extends Component {
    static propTypes = {
        department: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }).isRequired,
        organizationId: PropTypes.number.isRequired,
        users: PropTypes.array.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            showForm: false,
        };
    }

    toggleFormHandler = () => this.setState((prevState) => ({ showForm: !prevState.showForm }));

    render() {
        const { department, organizationId, users } = this.props;

        const { showForm } = this.state;

        if (showForm) {
            return (
                <DepartmentEditForm
                    cancelEditDepartment={this.toggleFormHandler}
                    department={department}
                    form={`${editForm}${department.id}`}
                    initialValues={department}
                    key={department.id}
                    organizationId={organizationId}
                    users={users}
                />
            );
        }

        return (
            <DepartmentFormDisplay
                department={department}
                editDepartment={this.toggleFormHandler}
                key={department.id}
                organizationId={organizationId}
            />
        );
    }
}

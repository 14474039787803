import React from 'react';
import { Field } from 'redux-form';
import { Box } from '@og-pro/ui';

import { customTimelineFieldsDict } from '@og-pro/shared-config/timelines';

import { TIMELINE_CONFIG, TIMELINES } from '../../../../../../containers/GovApp/constants';
import { CDSButton, InputText } from '../../../../..';
import { limitTextLength } from '../../../../../../Forms/normalizers';
import { PROP_TYPES } from './constants';

const { IS_ATTENDANCE_REQUIRED, IS_PUBLIC, LOCATION, SHOW_LOCATION } = customTimelineFieldsDict;

export const LocationInput = (props) => {
    const { change, disabled, showValidation, timeline } = props;
    const {
        locationField,
        hasProposalDeadlineLocation,
        hasProposalDeadlineLocationField,
        index,
        isCustomDate,
        showLocation,
        title,
    } = timeline;
    const styles = require('../shared.scss');

    const locationFieldName = locationField
        ? `${TIMELINE_CONFIG}.${locationField}`
        : `${TIMELINES}.${index}.${LOCATION}`;
    const locationInput = (
        <Field
            component={InputText}
            disabled={disabled}
            hasFeedback={false}
            help="Can be a conference room, address or teleconference number"
            label="Location"
            minRows={1}
            name={locationFieldName}
            normalize={limitTextLength(2000)}
            qaTag={`timelineForm-${title}Location`}
            showValidation={showValidation}
            type="textarea"
            useOpenGovStyle
        />
    );

    if (!hasProposalDeadlineLocationField && !isCustomDate) {
        return locationInput;
    }

    const hasLocationFieldname = hasProposalDeadlineLocationField
        ? `${TIMELINE_CONFIG}.${hasProposalDeadlineLocationField}`
        : `${TIMELINES}.${index}.${SHOW_LOCATION}`;

    return (
        <>
            {!hasProposalDeadlineLocation && !showLocation && (
                <Box mb={0.5}>
                    <CDSButton
                        className={styles.noPadding}
                        onClick={() => {
                            change(hasLocationFieldname, true);
                            change(`${TIMELINES}.${index}.${IS_PUBLIC}`, true);
                        }}
                        qaTag="timelineForm-addLocation"
                        size="small"
                        variant="text"
                    >
                        <i className="fa fa-plus" /> Add Location
                    </CDSButton>
                </Box>
            )}
            {(hasProposalDeadlineLocation || showLocation) && (
                <div className={styles.marginlessFormGroup}>
                    <Box display="flex">
                        <Box flex={11}>{locationInput}</Box>
                        <Box ml={1} pt={7}>
                            <CDSButton
                                className={styles.removeLocationButton}
                                onClick={() => {
                                    change(hasLocationFieldname, false);
                                    change(locationFieldName, null);

                                    if (isCustomDate) {
                                        change(`${TIMELINES}.${index}.${IS_PUBLIC}`, false);
                                        change(
                                            `${TIMELINES}.${index}.${IS_ATTENDANCE_REQUIRED}`,
                                            false
                                        );
                                    }
                                }}
                                qaTag="timelineForm-addLocation"
                                size="small"
                                variant="text"
                            >
                                <i className="fa fa-times" />
                            </CDSButton>
                        </Box>
                    </Box>
                </div>
            )}
        </>
    );
};
LocationInput.propTypes = PROP_TYPES;

import { isNil } from 'lodash';

import {
    fieldNames,
    MAX_NAME_LENGTH,
    reviewSequenceStepFieldNames,
    reviewSequenceStepPseudoFieldNames,
    reviewSequenceStepQuestionLogicFieldNames,
} from '../constants';
import { arrayError } from '../../../../../../Forms/validation';
import { hasFormErrors } from '../../../../../../helpers';

const { REVIEW_SEQUENCE_STEPS } = fieldNames;
const {
    MIN_POSITION_REVIEWS,
    NAME,
    POSITION_IDS,
    QUESTION_LOGIC,
    REJECTION_POLICIES,
    REVIEW_SEQUENCE_STEP_POSITIONS,
    REVIEW_TYPE,
} = reviewSequenceStepFieldNames;
const { LOGICABLE_FIELD } = reviewSequenceStepPseudoFieldNames;
const { LOGICABLE, LOGICABLE_ID, OPERATOR, VALUE } = reviewSequenceStepQuestionLogicFieldNames;

export default function validateSteps(values) {
    const errors = {};

    const reviewSequenceSteps = values[REVIEW_SEQUENCE_STEPS] || [];
    if (reviewSequenceSteps.length === 0) {
        errors[REVIEW_SEQUENCE_STEPS] = arrayError('At least one step is required');
        return errors;
    }

    errors[REVIEW_SEQUENCE_STEPS] = reviewSequenceSteps.map((sequenceStep) => {
        const sequenceStepErrors = {
            [QUESTION_LOGIC]: {},
        };

        if (!sequenceStep[NAME]) {
            sequenceStepErrors[NAME] = 'Please give your step a name';
        } else if (sequenceStep[NAME].length > MAX_NAME_LENGTH) {
            sequenceStepErrors[NAME] = 'Name is too long';
        }

        if (!sequenceStep[REJECTION_POLICIES] || sequenceStep[REJECTION_POLICIES].length === 0) {
            sequenceStepErrors[REJECTION_POLICIES] = 'Please select at least one rejection policy';
        }

        const questionLogic = sequenceStep[QUESTION_LOGIC];
        if (questionLogic) {
            if (!questionLogic[LOGICABLE] || isNil(questionLogic[LOGICABLE_ID])) {
                sequenceStepErrors[QUESTION_LOGIC][LOGICABLE_FIELD] =
                    'Please select condition for using step';
            }
            if (!questionLogic[OPERATOR]) {
                sequenceStepErrors[QUESTION_LOGIC][OPERATOR] = 'Please select an operator';
            }
            if (isNil(questionLogic[VALUE])) {
                sequenceStepErrors[QUESTION_LOGIC][VALUE] = 'Please select value for comparison';
            }
        }

        const positionIds = sequenceStep[POSITION_IDS] || [];
        if (positionIds.length === 0) {
            sequenceStepErrors[POSITION_IDS] = 'Please select at least one review position';
        }

        if (isNil(sequenceStep[REVIEW_TYPE])) {
            sequenceStepErrors[REVIEW_TYPE] = 'Please select a review type';
        }

        const sequenceStepPositions = sequenceStep[REVIEW_SEQUENCE_STEP_POSITIONS] || [];
        if (isNil(sequenceStep[MIN_POSITION_REVIEWS])) {
            sequenceStepErrors[MIN_POSITION_REVIEWS] = 'Specify min number of position approvals';
        } else if (sequenceStep[MIN_POSITION_REVIEWS] > sequenceStepPositions.length) {
            sequenceStepErrors[MIN_POSITION_REVIEWS] = 'Min number of position approvals too high';
        }

        return {
            hasError: hasFormErrors(sequenceStepErrors) && 'Step configuration is incomplete',
            ...sequenceStepErrors,
        };
    });

    return errors;
}

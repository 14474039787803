import { createSelector } from 'reselect';

export const getExceptionSequenceOptions = createSelector(
    [(state) => state.requisitionsCreate.get('exceptionSequences')],
    (exceptionSequences) =>
        exceptionSequences.toJS().map((reviewSequence) => ({
            label: reviewSequence.name,
            reviewSequence,
            value: reviewSequence.id,
        }))
);

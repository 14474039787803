import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

export class EditDecisionModalBody extends PureComponent {
    static propTypes = {
        note: PropTypes.string,
        approved: PropTypes.bool.isRequired,
        showModal: PropTypes.func.isRequired,
        hideModal: PropTypes.func.isRequired,
    };

    handleApproveClick = () => {
        const { showModal } = this.props;

        showModal('approve');
    };

    handleNoteClick = () => {
        const { showModal } = this.props;

        showModal('comment');
    };

    handleRejectClick = () => {
        const { showModal } = this.props;

        showModal('reject');
    };

    render() {
        const { approved, hideModal, note } = this.props;
        const styles = require('../ApprovalsModal.scss');
        const [icon, status, bsStyle] = approved
            ? ['check', 'Approved', 'success']
            : ['ban', 'Rejected', 'danger'];

        return (
            <div>
                <div className={`text-center text-${bsStyle} ${styles.status}`}>
                    <h4>
                        <i className={`fa fa-${icon}`} /> You have {status} this project
                    </h4>
                    <div className={styles.reviseInstructions}>
                        Revise approval using the buttons below
                    </div>
                </div>
                <div className="text-center">
                    <Button
                        bsSize="large"
                        bsStyle="success"
                        className={styles.actionButton}
                        disabled={approved}
                        onClick={this.handleApproveClick}
                    >
                        <i className="fa fa-check-square-o" /> Approve Project
                    </Button>
                    <Button
                        bsSize="large"
                        bsStyle="danger"
                        disabled={!approved}
                        onClick={this.handleRejectClick}
                    >
                        <i className="fa fa-times" /> Reject Project
                    </Button>
                    <div className={styles.editNote}>
                        <a onClick={this.handleNoteClick}>
                            <i className="fa fa-file-text" />
                            &nbsp;
                            {note ? 'Edit' : 'Add'} Note for Your Approval
                        </a>
                    </div>
                    <Button onClick={hideModal}>Close</Button>
                </div>
            </div>
        );
    }
}

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { QuestionPrompt } from '../../SharedDisplay';

export class FileUploadDisplay extends PureComponent {
    static propTypes = {
        isRevisionsDiff: PropTypes.bool,
    };

    static defaultProps = {
        isRevisionsDiff: false,
    };

    render() {
        const { isRevisionsDiff } = this.props;

        if (isRevisionsDiff) {
            return (
                <QuestionPrompt {...this.props}>
                    <em>Please upload your response</em>
                </QuestionPrompt>
            );
        }

        return <QuestionPrompt {...this.props} />;
    }
}

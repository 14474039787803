import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { commentDateFormatter } from '../../../utils';

export class CommentHeader extends PureComponent {
    static propTypes = {
        name: PropTypes.string.isRequired,
        commentDate: PropTypes.string.isRequired,
    };

    render() {
        const { name, commentDate } = this.props;
        const styles = require('./CommentHeader.scss');

        return (
            <div className={styles.headerContainer}>
                <span className={styles.name}>{name}</span>
                <span className="text-muted">{commentDateFormatter(commentDate)}</span>
            </div>
        );
    }
}

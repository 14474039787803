import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { ModalBody } from './ModalBody';
import {
    getDeserializedTemplateQuestion,
    getLoadError,
    getLoadingTemplatesInUse,
    getLoading,
    getLoadTemplatesInUseError,
    getTemplatesInUse,
} from './selectors';
import {
    loadTemplateQuestion,
    loadTemplatesInUse,
    resetTemplateQuestionEdit,
} from '../../../../../actions/templateQuestions';
import { isTinyMceModalOpenSelector } from '../../../../App/selectors';

export const TemplateQuestionEditModal = ({ hideModal, templateQuestionId }) => {
    const loading = useSelector(getLoading);
    const loadingTemplatesInUse = useSelector(getLoadingTemplatesInUse);
    const isTinyMceModalOpen = useSelector(isTinyMceModalOpenSelector);

    const loadError = useSelector(getLoadError);
    const loadTemplatesInUseError = useSelector(getLoadTemplatesInUseError);

    const templateQuestion = useSelector(getDeserializedTemplateQuestion);
    const templatesInUse = useSelector(getTemplatesInUse);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadTemplateQuestion(templateQuestionId));
        dispatch(loadTemplatesInUse(templateQuestionId));
        return () => dispatch(resetTemplateQuestionEdit());
    }, []);

    return (
        <Modal bsSize="lg" enforceFocus={!isTinyMceModalOpen} onHide={hideModal} show>
            <Modal.Header closeButton>
                <Modal.Title className="text-center">Edit Shared Question</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ModalBody
                    hideModal={hideModal}
                    loadError={loadError || loadTemplatesInUseError}
                    loading={loading || loadingTemplatesInUse}
                    templateQuestion={templateQuestion}
                    templateQuestionId={templateQuestionId}
                    templatesInUse={templatesInUse}
                />
            </Modal.Body>
        </Modal>
    );
};

TemplateQuestionEditModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    templateQuestionId: PropTypes.number.isRequired,
};

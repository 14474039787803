import { pseudoFieldNames } from './constants';
import {
    REQUIRED_TEXT,
    questionLogic as questionLogicValidator,
} from '../../../../../Forms/validation';

const { PROJECT_SECTION_ID } = pseudoFieldNames;

export function validate(values) {
    const errors = questionLogicValidator(values);

    if (!values[PROJECT_SECTION_ID]) {
        errors[PROJECT_SECTION_ID] = REQUIRED_TEXT;
    }

    return errors;
}

import { NoSsr } from '@mui/material';
import React from 'react';

import { Main, PageTitle } from '../../../../components';
import { AccountDetailsBody } from './AccountDetailsBody';
import { AccountDetailsHeader } from './AccountDetailsHeader';
import { AccountDetailsFilters } from './Filters';

export const AccountDetails = () => {
    const styles = require('./index.scss');

    return (
        <NoSsr>
            <Main className={styles.container}>
                <PageTitle title="Account Details" />
                <AccountDetailsHeader />
                <div className={styles.content}>
                    <AccountDetailsFilters />
                    <div className={styles.separator} />
                    <AccountDetailsBody />
                </div>
            </Main>
        </NoSsr>
    );
};

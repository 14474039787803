/**
 * Generates a Rollbar configuration. Reference:
 * https://docs.rollbar.com/docs/rollbarjs-configuration-reference
 *
 * @param {object} options Options for the config
 * @param {string} options.accessToken The Rollbar `accessToken` to use
 * @param {boolean} [options.enabled] If Rollbar reporting should be enabled
 * @param {string} [options.environment] The environment that is being run in
 * @param {boolean} [options.client] If the config is for a client
 * @param {string} [options.codeBranch] When on a server, the server code's git branch
 * @param {string} [options.codeVersion] When on a client, the client's code version
 * @param {boolean} [options.server] If the config is for a server
 * @return {object} A built Rollbar config
 */
exports.generateRollbarConfig = (options) => {
    const config = {
        accessToken: options.accessToken,
        captureEmail: true,
        captureIp: 'anonymize',
        captureUncaught: true,
        captureUnhandledRejections: true,
        enabled: options.enabled,
        environment: options.environment,
    };

    if (options.client) {
        config.payload = {
            client: {
                javascript: {
                    code_version: options.codeVersion,
                    guess_uncaught_frames: true,
                    source_map_enabled: true,
                },
            },
            server: {
                branch: options.codeBranch,
                root: 'webpack:///./',
            },
        };
    } else if (options.server) {
        config.payload = {
            server: {
                branch: options.codeBranch,
                host: require('os').hostname(),
            },
        };
    }

    return config;
};

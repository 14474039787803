import { truncate } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

export const CategoryCodeListItem = (props) => {
    const { code, maxCategoryTextLength, noBorder, showCode, title } = props;

    const { longFormListItem, longFormListItemNoBorder } = require('./CategoryCodes.scss');

    const categoryText = showCode ? `${code} - ${title}` : title;
    return (
        <li className={noBorder ? longFormListItemNoBorder : longFormListItem}>
            {truncate(categoryText, {
                length: maxCategoryTextLength,
                separator: ' ',
            })}
        </li>
    );
};

CategoryCodeListItem.propTypes = {
    code: PropTypes.string.isRequired,
    maxCategoryTextLength: PropTypes.number.isRequired,
    noBorder: PropTypes.bool,
    showCode: PropTypes.bool,
    title: PropTypes.string.isRequired,
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { EditForm } from '../EditForm/EditForm';

export class EditNoteModalBody extends PureComponent {
    static propTypes = {
        note: PropTypes.string,
        submitError: PropTypes.string,
        submitHandler: PropTypes.func.isRequired,
        hideModal: PropTypes.func.isRequired,
    };

    render() {
        const { submitHandler, note, hideModal, submitError } = this.props;

        return (
            <EditForm
                hideModal={hideModal}
                initialValues={{ comment: note }}
                onSubmit={submitHandler}
                submitError={submitError}
            />
        );
    }
}

import { createSelector } from 'reselect';

import { requisitionsCreateFormValueSelector } from '../selectors';
import { fieldNames } from '../constants';

const { VENDORS } = fieldNames;

export const getVendorOptions = createSelector(
    [(state) => requisitionsCreateFormValueSelector(state, VENDORS)],
    (vendors) =>
        vendors?.map((vendor, index) => ({
            label: `Vendor ${index + 1}`,
            value: vendor.vendorAssignmentUuid,
        })) || []
);

import { TITLE } from './constants';

export const validate = (values) => {
    const errors = {};

    if (!values[TITLE]) {
        errors[TITLE] = 'Template title is required.';
    }

    return errors;
};

import { buildQaTag, componentNames } from '@og-pro/ui';
import React from 'react';
import PropTypes from 'prop-types';

export const SDv2AddSectionSectionContainer = ({ onAddSection, sectionTypes, subtitle, title }) => {
    const styles = require('./index.scss');

    if (!sectionTypes || !sectionTypes.length) {
        return null;
    }

    return (
        <div className={styles.sectionContainer}>
            <h5 className={styles.subheader}>{title}</h5>
            <div className={styles.subheaderHelp}>{subtitle}</div>
            {sectionTypes.map((sectionType, i) => {
                return (
                    <div className={styles.sectionType} key={i}>
                        <button
                            data-qa={buildQaTag(
                                `templateAdmin-${sectionType.title}`,
                                componentNames.BUTTON
                            )}
                            onClick={() => onAddSection(sectionType)}
                        >
                            <div className={styles.sectionTypeTitle}>
                                <i className={`fa fa-fw fa-${sectionType.icon} fa-fw`} />{' '}
                                {sectionType.title}
                            </div>
                            <div className={styles.sectionTypeActions}>
                                <i className="fa fa-plus fa-fw" />
                            </div>
                        </button>
                    </div>
                );
            })}
        </div>
    );
};

SDv2AddSectionSectionContainer.propTypes = {
    onAddSection: PropTypes.func.isRequired,
    sectionTypes: PropTypes.arrayOf(PropTypes.shape({})),
    subtitle: PropTypes.string,
    title: PropTypes.string,
};

import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { TimelineDateGroup } from './TimelineDateGroup';
import { DOCX_TABLE_PORTRAIT_WIDTH } from '../../../../../constants';

/*
 * With the introduction of the timeline template variable,
 * any style changes that are made here will likely also need
 * to be made in the html table used for rendering the timeline
 * variable located here: server/app/project/template_utils.js
 */
export class Timeline extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        isDocx: PropTypes.bool,
        project: PropTypes.shape({
            isLibrary: PropTypes.bool,
            timelineConfig: PropTypes.shape({
                auctionExtensionGracePeriod: PropTypes.string,
                auctionExtensionTime: PropTypes.string,
                preProposalAgendaUrlField: PropTypes.string,
            }),
            timelines: PropTypes.array.isRequired,
        }).isRequired,
        timezone: PropTypes.string.isRequired,
    };

    renderTimelineDates() {
        const { isDocx, project, timezone } = this.props;

        return project.timelines.map((timeline) => {
            const {
                agendaUrl,
                date,
                displayTime,
                id,
                isAttendanceRequired,
                isRequiredField,
                location,
                locationField,
                preProposalAgendaUrlField,
                textDate,
                title,
                uuid,
            } = timeline;

            const timelineAgendaUrl =
                get(project.timelineConfig, preProposalAgendaUrlField) || agendaUrl || null;

            let requiredText = isRequiredField
                ? ` (${get(project, `timelineConfig.${isRequiredField}`) ? '' : 'Non-'}Mandatory)`
                : '';

            // custom timeline items have this field when its mandatory
            if (isAttendanceRequired) {
                requiredText = ' (Mandatory)';
            }

            const name = `${title}${requiredText}`;
            const locationText = (locationField && project[locationField]) || location;

            return (
                <TimelineDateGroup
                    agendaUrl={timelineAgendaUrl}
                    date={date}
                    format={displayTime ? 'MMMM D, YYYY, h:mma' : 'MMMM D, YYYY'}
                    isDocx={isDocx}
                    key={id || uuid}
                    location={locationText}
                    name={name}
                    textDate={textDate}
                    timezone={timezone}
                />
            );
        });
    }

    renderReverseAuctionExtension() {
        const {
            project: { timelineConfig },
            timezone,
        } = this.props;

        const auctionExtensionGracePeriod = get(timelineConfig, 'auctionExtensionGracePeriod');
        const auctionExtensionTime = get(timelineConfig, 'auctionExtensionTime');

        return (
            <>
                <TimelineDateGroup
                    name="Extension Grace Period"
                    textDate={`${auctionExtensionGracePeriod} minutes`}
                    timezone={timezone}
                />
                <TimelineDateGroup
                    name="Extension Time"
                    textDate={`${auctionExtensionTime} minutes`}
                    timezone={timezone}
                />
            </>
        );
    }

    render() {
        const {
            className,
            isDocx,
            project: { timelineConfig },
        } = this.props;

        const auctionExtensionGracePeriod = get(timelineConfig, 'auctionExtensionGracePeriod');

        if (isDocx) {
            // `tableLayout: 'fixed'` will make sure that the width set will be enforced, so that
            // the text inside the cells will have no influence on the size of each cell.
            return (
                <table
                    style={{
                        tableLayout: 'fixed',
                        margin: '0 auto',
                        width: DOCX_TABLE_PORTRAIT_WIDTH,
                    }}
                >
                    {this.renderTimelineDates()}
                    {auctionExtensionGracePeriod && this.renderReverseAuctionExtension()}
                </table>
            );
        }

        return (
            <div className={className}>
                <div>
                    {this.renderTimelineDates()}
                    {auctionExtensionGracePeriod && this.renderReverseAuctionExtension()}
                </div>
            </div>
        );
    }
}

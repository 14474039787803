import { fromJS } from 'immutable';

import * as contactActions from '../actions/contacts';

const initialState = fromJS({
    contactModalData: null,
    contactModalTab: null,
    showContactModal: false,
});

export default function contactsReducer(state = initialState, action = {}) {
    switch (action.type) {
        case contactActions.SET_CONTACT_MODAL_TAB:
            return state.set('contactModalTab', action.tab);
        case contactActions.SHOW_CONTACT_MODAL:
            return state.merge(
                fromJS({
                    contactModalData: fromJS(action.modalData),
                    showContactModal: true,
                })
            );
        case contactActions.HIDE_CONTACT_MODAL:
            return state.merge(
                fromJS({
                    contactModalData: null,
                    showContactModal: false,
                })
            );
        default:
            return state;
    }
}

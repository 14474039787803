import { camelCase, mapKeys } from 'lodash';

import { useOgFinancials } from './useOgFinancials';
import { glSearchAccountByPseudoKey } from './queries';

const mapCamelCaseKeys = (data) => mapKeys(data, (v, k) => camelCase(k));

export const useAccountSearch = (accountPseudoKey, { enabled }) =>
    useOgFinancials({
        queryKey: 'glSearchAccountByPseudoKey',
        query: glSearchAccountByPseudoKey,
        params: { accountPseudoKey },
        enabled: !!accountPseudoKey && enabled,
        select: (data) => mapCamelCaseKeys(data.glAccountsSearch?.edges[0]?.node),
    });

import { isNil } from 'lodash';

import { fieldNames } from './constants';

const {
    DESIRED_DELIVERY_DATE,
    HIDE_SECTION_ATTACHMENT,
    HIDE_SECTION_CUSTOM_FORM,
    HIDE_SECTION_PURCHASE,
    HIDE_SECTION_VENDOR,
    PURCHASE_DETAILS_MODE,
    SECTION_NAME_ADDITIONAL,
    SECTION_NAME_ATTACHMENT,
    SECTION_NAME_CUSTOM_FORM,
    SECTION_NAME_GENERAL,
    SECTION_NAME_PURCHASE,
    SECTION_NAME_VENDOR,
    SHOW_ACCOUNT_INFORMATION,
} = fieldNames;

export const validate = (values) => {
    const errors = {};

    [SECTION_NAME_ADDITIONAL, SECTION_NAME_GENERAL].forEach((sectionName) => {
        if (!values[sectionName]?.trim()) {
            errors[sectionName] = 'Section name is required';
        }
    });

    [
        [SECTION_NAME_ATTACHMENT, HIDE_SECTION_ATTACHMENT],
        [SECTION_NAME_PURCHASE, HIDE_SECTION_PURCHASE],
        [SECTION_NAME_VENDOR, HIDE_SECTION_VENDOR],
        [SECTION_NAME_CUSTOM_FORM, HIDE_SECTION_CUSTOM_FORM],
    ].forEach(([sectionName, isSectionHiddenField]) => {
        if (!values[isSectionHiddenField] && !values[sectionName]?.trim()) {
            errors[sectionName] = 'Section name is required';
        }
    });

    [
        SECTION_NAME_ADDITIONAL,
        SECTION_NAME_ATTACHMENT,
        SECTION_NAME_CUSTOM_FORM,
        SECTION_NAME_GENERAL,
        SECTION_NAME_PURCHASE,
        SECTION_NAME_VENDOR,
    ].forEach((sectionName) => {
        if (values[sectionName]?.length > 256) {
            errors[sectionName] = 'Section name is too long';
        }
    });

    if (isNil(values[PURCHASE_DETAILS_MODE])) {
        errors[PURCHASE_DETAILS_MODE] = 'Collection requirements are required';
    }

    if (isNil(values[SHOW_ACCOUNT_INFORMATION])) {
        errors[SHOW_ACCOUNT_INFORMATION] = 'Account information is required';
    }

    if (isNil(values[DESIRED_DELIVERY_DATE])) {
        errors[DESIRED_DELIVERY_DATE] = 'Desired delivery date is required';
    }

    return errors;
};

import { MAX_TEXT_AREA_LENGTH, REQUIRED_TEXT } from './constants';

export function rawSummary(values) {
    const errors = {};
    if (!values.rawSummary) {
        errors.rawSummary = REQUIRED_TEXT;
    } else if (values.rawSummary.length >= MAX_TEXT_AREA_LENGTH) {
        errors.rawSummary = 'This field is too long';
    }

    return errors;
}

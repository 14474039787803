import React from 'react';
import PropTypes from 'prop-types';

import { ReportDocxTable } from '../../../../../components';
import {
    getLumpSumSummaryRowData,
    getProjectSummaryOverviewData,
    lumpSumSummaryColumnDefs,
} from './helpers';
import { LineItemTimelineList, SummaryByLineItemList } from './components';
import { ProjectOverviewTable } from '../sharedComponents';

export const ReverseAuctionSummaryReport = ({ project, reverseAuctionItems }) => {
    const headingStyle = {
        fontSize: 24,
        fontWeight: 'bold',
        marginTop: 15,
    };
    return (
        <>
            <div style={headingStyle}>Overview</div>
            <ProjectOverviewTable rows={getProjectSummaryOverviewData(project)} />
            <div style={headingStyle}>Lump Sum Summary</div>
            <ReportDocxTable
                columns={lumpSumSummaryColumnDefs}
                data={getLumpSumSummaryRowData(reverseAuctionItems)}
            />
            <div style={headingStyle}>Summary by Line Item</div>
            <SummaryByLineItemList reverseAuctionItems={reverseAuctionItems} />
            <div style={headingStyle}>Timeline of Bids by Line Item</div>
            <p style={{ color: '#777777' }}>
                A full history of each line item, each vendor, and every bid they placed.
            </p>
            <LineItemTimelineList reverseAuctionItems={reverseAuctionItems} />
        </>
    );
};

ReverseAuctionSummaryReport.propTypes = {
    project: PropTypes.object,
    reverseAuctionItems: PropTypes.array,
};

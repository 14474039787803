import { get, isNumber } from 'lodash';
import { createSelector } from 'reselect';

import { evaluationStatuses } from '@og-pro/shared-config/evaluations';
import { proposalEvaluationStatuses } from '@og-pro/shared-config/proposalEvaluations';

import { getCurrentEvaluation, getCurrentScoredProposals } from '../../selectors';

const { DRAFT, OPEN } = evaluationStatuses;

const { COMPLETE } = proposalEvaluationStatuses;

export const getEvaluatorProposalEvaluations = createSelector(
    [getCurrentScoredProposals, getCurrentEvaluation],
    (proposals, evaluation) => {
        if (!evaluation || evaluation.status === DRAFT || evaluation.status === OPEN) {
            return [];
        }
        const { scoringCriteria } = evaluation;
        return evaluation.evaluators.map((evaluator) => {
            let numberScored = 0;
            let numberSubmitted = 0;
            proposals.forEach((proposal) => {
                const { isExcluded } = proposal;
                const proposalEvaluation = proposal.proposalEvaluations.find((propEvaluation) => {
                    return propEvaluation.user_id === evaluator.id;
                });

                if (!proposalEvaluation) {
                    return;
                }

                proposalEvaluation.orderedProposalCriteriaScores.forEach((propCriteriaScore, i) => {
                    if (
                        !get(scoringCriteria, [i, 'isAdminScored']) &&
                        !isExcluded &&
                        isNumber(get(propCriteriaScore, 'score'))
                    ) {
                        numberScored++;
                    }
                });

                if (!isExcluded && proposalEvaluation.status === COMPLETE) {
                    numberSubmitted++;
                }
            });
            return {
                ...evaluator,
                numberScored,
                numberSubmitted,
            };
        });
    }
);

export const getTotalProposalEvaluationsToSubmit = createSelector(
    [getCurrentScoredProposals],
    (proposals) => proposals.filter((proposal) => !proposal.isExcluded).length
);

import { Chip, TabsNavigation } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { actionTypes } from '@og-pro/shared-config/requisitionLogs';

import { qaTagPageName } from '../../constants';
import { getAllRequisitionLogs } from '../../selectors';
import { hasRequisitionOnlySubscription } from '../../../../GovApp/selectors';
import { getReqRelations } from '../../../../../selectors/govApp/reqRelations';

const Counter = ({ count }) => <Chip color="primary" label={count} size="extraSmall" />;

Counter.propTypes = {
    count: PropTypes.number.isRequired,
};

export const RequisitionsApprovalHistoryNav = ({ setNavIndex }) => {
    const hasRequisitionOnly = useSelector(hasRequisitionOnlySubscription);

    const commentCount = useSelector(getAllRequisitionLogs).filter(
        ({ actionType }) => actionType === actionTypes.COMMENT
    ).length;
    const relatedCount = useSelector(getReqRelations).length;

    const tabs = [
        {
            label: 'Workflow',
            qaTag: `${qaTagPageName}-workflowNav`,
        },
        {
            label: 'Comments',
            qaTag: `${qaTagPageName}-commentsNav`,
            count: commentCount,
        },
    ];

    if (!hasRequisitionOnly) {
        tabs.push({
            label: 'Related',
            qaTag: `${qaTagPageName}-relatedNav`,
            count: relatedCount,
        });
    }

    return <TabsNavigation onClick={(_tab, index) => setNavIndex(index)} tabs={tabs} />;
};

RequisitionsApprovalHistoryNav.propTypes = {
    setNavIndex: PropTypes.func.isRequired,
};

export const form = 'projectPublicDisplayOptionsForm';

export const IS_CONSENSUS_PUBLIC = 'isConsensusPublic';
export const IS_EVALUATION_PUBLIC = 'isPublic';
export const IS_PAUSED = 'isPaused';
export const IS_PUBLIC_BID_PRICING_RESULT = 'isPublicBidPricingResult';
export const IS_PUBLIC_BID_RESULT = 'isPublicBidResult';
export const IS_PUBLIC_BID_TOTAL_ONLY = 'isPublicBidTotalOnly';
export const REQUIRES_INVITATION = 'requiresInvitation';
export const QA_ENABLED = 'qaEnabled';
export const SHOW_PLANHOLDERS = 'showPlanholders';
export const COMING_SOON = 'comingSoon';
export const IS_PRIVATE = 'isPrivate';

export const vendorPricingDisplayOptions = [
    {
        label: 'Only show pricing totals',
        value: true,
    },
    {
        label: 'Show entire pricing table',
        value: false,
    },
];

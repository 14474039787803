import { createSelector } from 'reselect';

import { CURRENT_VERSION } from './constants';

const getDocumentVersions = (state) => state.revisions.get('documentVersions');

export const getDocumentVersionSelectOptions = createSelector(
    [getDocumentVersions],
    (rawDocumentVersions) => {
        // Allow current version to be an option along with the saved versions
        return [
            {
                label: 'Current Version',
                value: CURRENT_VERSION,
            },
        ].concat(
            rawDocumentVersions.toJS().map((documentVersion) => {
                return {
                    documentVersion,
                    label: documentVersion.name,
                    value: documentVersion.id,
                };
            })
        );
    }
);

import { createSelector } from 'reselect';

const getData = (state, props) => state.app.get(props.stateKey);

export const getDataJS = createSelector([getData], (data) => {
    if (data) {
        return data.toJS();
    }

    return {};
});

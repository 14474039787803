const getSort = (column, order = 'asc') => {
    switch (column) {
        case 'doingBusinessAs':
            return [
                {
                    doing_business_as_keyword: {
                        order,
                    },
                },
            ];
        case 'legalName':
            return [
                {
                    organization_name_keyword: {
                        order,
                    },
                },
            ];
        default:
            return [];
    }
};

export const getOpenSearchObject = ({ searchObject, size, from, sortModel }) => {
    let search;

    if (searchObject?.searchText === '') {
        search = {
            query: {},
            size,
            from,
            sort: sortModel ? getSort(sortModel[0]?.colId || 'legalName', sortModel[0]?.sort) : [],
            track_total_hits: true,
        };
    } else {
        search = {
            query: {
                or: [
                    {
                        prefix: {
                            organization_name: searchObject.searchText,
                        },
                    },
                    {
                        prefix: {
                            doing_business_as: searchObject.searchText,
                        },
                    },
                    {
                        prefix: {
                            organization_city: searchObject.searchText,
                        },
                    },
                    {
                        prefix: {
                            organization_state: searchObject.searchText,
                        },
                    },
                    {
                        prefix: {
                            organization_zip_code: searchObject.searchText,
                        },
                    },
                ],
            },
            size,
            from,
            sort: sortModel ? getSort(sortModel[0]?.colId || 'legalName', sortModel[0]?.sort) : [],
            track_total_hits: true,
        };
    }

    if (searchObject.categoryIds?.length > 0) {
        search.query.categories_ids = {
            operator: 'or',
            value: searchObject.categoryIds,
        };
    }

    if (searchObject.certificationIds?.length > 0) {
        search.query.certification_ids = {
            operator: 'or',
            value: searchObject.certificationIds,
        };
    }

    if (searchObject.ethnicities?.length > 0) {
        search.query.ethnicities = {
            operator: 'or',
            value: searchObject.ethnicities,
        };
    }

    if (searchObject.vendorLists?.length > 0) {
        search.query.vendor_list_ids = {
            operator: 'or',
            value: searchObject.vendorLists,
        };
    }

    if (searchObject?.languages?.length > 0) {
        search.query.languages = {
            operator: 'or',
            value: searchObject.languages,
        };
    }

    return search;
};

import { fieldNames } from './constants';

const { APPROVER_IDS } = fieldNames;

export const validate = (values) => {
    const errors = {};

    if (!values[APPROVER_IDS]) {
        errors[APPROVER_IDS] = 'Please select a user';
    }

    return errors;
};

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Button, ButtonGroup } from '../../..';

export class RevisionSearch extends Component {
    static propTypes = {
        className: PropTypes.string,
        revisionNodes: PropTypes.array.isRequired,
        revisionNumber: PropTypes.number.isRequired,
        scrollToRevision: PropTypes.func.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    handleScrollToNextClick = () => {
        const { revisionNodes, revisionNumber, scrollToRevision } = this.props;

        const revisions = revisionNodes.length;
        const nextNum = revisionNumber >= revisions ? 1 : revisionNumber + 1;

        scrollToRevision(nextNum);
    };

    handleScrollToPreviousClick = () => {
        const { revisionNodes, revisionNumber, scrollToRevision } = this.props;

        const revisions = revisionNodes.length;
        const prevNum = revisionNumber <= 1 ? revisions : revisionNumber - 1;

        scrollToRevision(prevNum);
    };

    render() {
        const { className, revisionNodes, revisionNumber } = this.props;
        const revisions = revisionNodes.length;

        if (revisions === 0) {
            return (
                <div className={this.styles.revisionSearch}>
                    <span className={this.styles.revisionCount}>No Changes</span>
                </div>
            );
        }

        return (
            <div className={classnames(this.styles.revisionSearch, className)}>
                <span className={this.styles.revisionCount}>
                    {revisionNumber === 0
                        ? `Total: ${revisions} edits`
                        : `Edit ${revisionNumber} of ${revisions}`}
                </span>
                <ButtonGroup bsSize="small">
                    <Button
                        aria-label="Scroll Up"
                        onClick={this.handleScrollToPreviousClick}
                        qaTag="revisionSearch-scrollUp"
                    >
                        <i className="fa fa-lg fa-angle-up" />
                    </Button>
                    <Button
                        aria-label="Scroll Down"
                        onClick={this.handleScrollToNextClick}
                        qaTag="revisionSearch-scrollDown"
                    >
                        <i className="fa fa-lg fa-angle-down" />
                    </Button>
                </ButtonGroup>
            </div>
        );
    }
}

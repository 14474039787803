import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Field } from 'redux-form';

import { DATA } from '@og-pro/shared-config/questionnaires';

import { FileUploadFormBuilderInput } from './FileUploadFormBuilderInput';
import { GenericFormBuilder } from '../../SharedFormBuilder';

export class DownloadFormBuilder extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        form: PropTypes.string.isRequired,
        includeContainsPricingOption: PropTypes.bool,
        isNotarize: PropTypes.bool,
        isTitleEditingDisabled: PropTypes.bool,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
        useRawPrompt: PropTypes.bool,
    };

    static defaultProps = {
        tagOptions: undefined,
        templateVariableOptions: undefined,
        useRawPrompt: false,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const {
            disabled,
            form,
            includeContainsPricingOption,
            isNotarize,
            isTitleEditingDisabled,
            tagOptions,
            templateVariableOptions,
            useRawPrompt,
        } = this.props;

        const accept = {
            'application/pdf': ['.pdf'],
        };

        return (
            <GenericFormBuilder
                disabled={disabled}
                form={form}
                includeContainsPricingOption={includeContainsPricingOption}
                isTitleEditingDisabled={isTitleEditingDisabled}
                tagOptions={tagOptions}
                templateVariableOptions={templateVariableOptions}
                useRawPrompt={useRawPrompt}
            >
                <Field
                    accept={isNotarize ? accept : undefined}
                    component={FileUploadFormBuilderInput}
                    disabled={disabled}
                    dropzoneIcons={isNotarize ? ['file-pdf-o'] : undefined}
                    dropzoneText={
                        isNotarize ? (
                            <>
                                Drop some files here or click to select files to upload.
                                <br />
                                Only PDF documents are supported for online notarization.
                            </>
                        ) : undefined
                    }
                    name={DATA}
                />
                {isNotarize && (
                    <div className={this.styles.notaryLiveText}>
                        Automated notarization powered via partnership with{' '}
                        <strong>NotaryLive</strong>
                        <img
                            alt="NotaryLive logo"
                            className={`img-rounded ${this.styles.logo}`}
                            src="https://assets.procurement.opengov.com/assets/notary-live-logo.jpeg"
                        />
                    </div>
                )}
            </GenericFormBuilder>
        );
    }
}

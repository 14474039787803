const BIDDING = 'reverse-auction/bidding';
const QUESTION_ANSWER = 'reverse-auction/q-and-a';

export const navItems = [
    {
        name: 'Auction Bidding',
        route: BIDDING,
    },
    {
        name: 'Live Question & Answer',
        route: QUESTION_ANSWER,
    },
];

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export class ListError extends PureComponent {
    static propTypes = {
        bsStyle: PropTypes.string,
        className: PropTypes.string,
        displayError: PropTypes.string,
        // Used for displaying errors on reduxForm FieldArray components
        meta: PropTypes.shape({
            error: PropTypes.string,
        }).isRequired,
        showError: PropTypes.bool,
        useOpenGovStyle: PropTypes.bool,
    };

    static defaultProps = {
        bsStyle: 'danger',
        showError: true,
    };

    render() {
        const {
            bsStyle,
            className,
            displayError,
            meta: { error },
            showError,
            useOpenGovStyle,
        } = this.props;

        if (!error || !showError) return null;

        const styles = require('./ListError.scss');

        if (useOpenGovStyle) {
            return (
                <div className={classnames(styles.opengovStyleError, className)}>
                    <i className="fa fa-exclamation-triangle" /> {displayError || error}
                </div>
            );
        }

        return (
            <div className={classnames(`text-${bsStyle}`, styles.error, className)}>
                {displayError || error}
            </div>
        );
    }
}

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';

import { EvaluatorScoreExpanded } from './EvaluatorScoreExpanded';

export class EvaluatorScoresExpanded extends PureComponent {
    static propTypes = {
        proposals: PropTypes.array.isRequired,
        scoringCriteria: PropTypes.array.isRequired,
        showComments: PropTypes.bool,
        showCriteriaDescriptions: PropTypes.bool,
        showPercentageTotals: PropTypes.bool,
        totalWeight: PropTypes.number.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const {
            proposals,
            scoringCriteria,
            showComments,
            showCriteriaDescriptions,
            showPercentageTotals,
            totalWeight,
        } = this.props;

        const rows = proposals.map((proposal) => (
            <ListGroupItem className={this.styles.listItem} key={proposal.id}>
                <EvaluatorScoreExpanded
                    proposal={proposal}
                    scoringCriteria={scoringCriteria}
                    showComments={showComments}
                    showCriteriaDescriptions={showCriteriaDescriptions}
                    showPercentageTotals={showPercentageTotals}
                    totalWeight={totalWeight}
                />
            </ListGroupItem>
        ));

        return <ListGroup>{rows}</ListGroup>;
    }
}

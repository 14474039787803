import { buildQaTag, componentNames } from '@og-pro/ui';
import React from 'react';
import PropTypes from 'prop-types';
import { DropdownButton as BSDropdownButton } from 'react-bootstrap';
import classnames from 'classnames';

import { capitalizeAllWords } from '../../helpers/utils';
import { CDSButtonPropTypes, CDSButtonDefaultProps } from './constants';

export const CDSDropdownButton = (props) => {
    const styles = require('./CDSButton.scss');

    const { variant, size, className, qaTag, noPadding, ...dropdownButtonProps } = props;

    const classNames = classnames(
        styles[`variant${capitalizeAllWords(variant)}`],
        styles[`size${capitalizeAllWords(size)}`],
        {
            [styles.noPadding]: noPadding,
        },
        className
    );

    const tagWithComponentName = buildQaTag(qaTag, componentNames.DROPDOWN);

    return (
        <BSDropdownButton
            id={`${qaTag}-dropdown`}
            {...dropdownButtonProps}
            className={classNames}
            data-qa={tagWithComponentName}
        />
    );
};

CDSDropdownButton.propTypes = {
    'aria-label': PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    pullRight: PropTypes.bool,
    qaTag: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    ...CDSButtonPropTypes,
};

CDSDropdownButton.defaultProps = CDSButtonDefaultProps;

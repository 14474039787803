import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { ActionPanelContainer } from '../ActionPanelContainer';
import { ActionPanelItem } from '../ActionPanelItem';
import { getResubmitActionButtons } from '../selectors';
import { UserProfilePicture } from '../../../../../../components';

export const ResubmitActionPanel = ({ disabled, requisition }) => {
    const { creator, requestor } = requisition;

    const buttons = useSelector(getResubmitActionButtons);

    return (
        <ActionPanelContainer buttons={buttons} disabled={disabled}>
            <ActionPanelItem label="Created By">
                <UserProfilePicture horizontal user={creator} /> {creator.displayName}
            </ActionPanelItem>
            {creator.id !== requestor.id && (
                <ActionPanelItem label="On Behalf Of">
                    <UserProfilePicture horizontal user={requestor} /> {requestor.displayName}
                </ActionPanelItem>
            )}
        </ActionPanelContainer>
    );
};

ResubmitActionPanel.propTypes = {
    disabled: PropTypes.bool,
    requisition: PropTypes.shape({
        creator: PropTypes.shape({
            displayName: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
        }).isRequired,
        requestor: PropTypes.shape({
            displayName: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
        }).isRequired,
    }).isRequired,
};

import { findIndex, get, startCase } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Panel } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';

import { projectStatusesDict } from '@og-pro/shared-config/projects';

import { subsectionTypeNames } from '@og-pro/shared-config/subsections';

import { basicUserRoles } from '@og-pro/shared-config/userRoles';

import {
    defaultSectionConfigsMap,
    pseudoSectionTypeNames,
    sectionTypeNames,
} from '@og-pro/shared-config/sections';

import { ControlButtons, SectionNavButton } from './components';
import { formConfig as projectCreateFormConfig, ALL_SECTIONS } from './constants';
import { ProjectCreateHelpModal } from './ProjectCreateHelpModal';
import {
    getBuilderSectionsJS,
    getDeserializedProject,
    getLoadInitialDataError,
    getProjectJS,
    getProjectSection,
    getSectionRoutes,
    getWritingSections,
    isConfirmationPage,
    isLoadingInitialData,
    isPseudoSectionPage,
} from './selectors';
import { isPseudoSectionType } from './utils';
import { validateAll as validate, warn } from './validate';
import { CommentIcon } from '..';
import {
    getBuilderDisplayName,
    getDashboardPath,
    getDocumentReviewPath,
    getDocumentWritingPath,
    isBudgetRequired,
    isBudgetUsed,
    isDocumentEditable,
    isDocumentEditor,
    isProjectIdRequired,
    isIntakeDraftPage,
    isBuilderDraftPage,
} from '../selectors';
import connectData from '../../ConnectData';
import { getUserJS, isInitialClientLoaded } from '../../selectors';
import { shouldLoadSuggestedContent, loadSuggestedContent } from '../../../actions/admin';
import { shouldLoadApprovals, loadApprovals } from '../../../actions/approvals';
import { shouldShowComments } from '../../../actions/govComments';
import {
    initialize as initializeProjectState,
    initiateSneakyUpdate,
    loadBuilderSections,
    scrollPageToTop,
    shouldLoadBuilderSections,
} from '../../../actions/project/create/projectCreate';
import {
    ConnectedClients,
    CreateFormNotEditable,
    Nav,
    PageTitle,
    ProjectModeToggle,
    ProjectSectionsEditButton,
    ProjectStatusLabel,
    RouteLeaveWarning,
    SaveModal,
    TourButton,
} from '../../../components';

const { DRAFT, REQUEST_DRAFT } = projectStatusesDict;

const { DIVIDER } = sectionTypeNames;

const { BODY } = subsectionTypeNames;

const { DOCUMENT_SETUP, GLOBAL_DATA } = pseudoSectionTypeNames;

const { BATMAN } = basicUserRoles;

function fetchData(getState, dispatch, location, params) {
    const promises = [];

    const state = getState();
    const projectId = Number.parseInt(params.projectId, 10);

    if (shouldLoadSuggestedContent(state)) {
        const governmentId = Number.parseInt(params.governmentId, 10);
        promises.push(dispatch(loadSuggestedContent(governmentId)));
    }

    if (shouldLoadApprovals(state)) {
        promises.push(dispatch(loadApprovals(projectId)));
    }

    if (shouldLoadBuilderSections(state)) {
        promises.push(dispatch(loadBuilderSections(projectId)));
    }

    return Promise.all(promises);
}

const mapStateToProps = (state, props) => {
    const writingPath = getDocumentWritingPath(state, props);
    const isClientLoaded = isInitialClientLoaded(state);

    const project = getProjectJS(state);
    // Toolbar is used for both intake and builder phases
    const isDraftPage = project.isIntake
        ? isIntakeDraftPage(state, props)
        : isBuilderDraftPage(state, props);

    return {
        builderDisplayName: getBuilderDisplayName(state),
        builderSectionsMap: getBuilderSectionsJS(state),
        dashboardPath: getDashboardPath(state, props),
        deserializedProject: getDeserializedProject(state),
        disabled: state.projectCreate.get('updating') || state.projectCreate.get('submitting'),
        initialValues: isClientLoaded ? getDeserializedProject(state) : undefined,
        isBudgetRequired: isBudgetRequired(state), // Used by form `validate` function
        isBudgetUsed: isBudgetUsed(state), // Used by form `validate` function
        isClientLoaded,
        isConfirmation: isConfirmationPage(state, props),
        isDraftPage,
        isEditableStatus: isDocumentEditable(state),
        isEditor: isDocumentEditor(state),
        isLoading: isLoadingInitialData(state),
        isProjectIdRequired: isProjectIdRequired(state), // Used by form `validate` function
        isPseudoSection: isPseudoSectionPage(state, props),
        loaded: state.projectCreate.get('loaded'),
        loadInitialDataError: getLoadInitialDataError(state),
        project,
        projectSection: getProjectSection(state, props),
        reviewPath: getDocumentReviewPath(state, props),
        sectionRoutes: getSectionRoutes(state, writingPath),
        showComments: shouldShowComments(state),
        showHelpModal: state.projectCreate.get('showHelpModal'),
        user: getUserJS(state),
        writingPath,
        writingSections: getWritingSections(state), // Used by form `validate` function
    };
};

const mapDispatchToProps = {
    initializeProject: initializeProjectState,
    initiateSneakyProjectUpdate: initiateSneakyUpdate,
    scrollPageToTop,
};

const formConfig = {
    ...projectCreateFormConfig,
    validate,
    warn,
};

// @withRouter
// @connectData
// @connect
// @reduxForm
class ConnectedProjectCreate extends Component {
    static propTypes = {
        builderDisplayName: PropTypes.string.isRequired,
        builderSectionsMap: PropTypes.object.isRequired,
        children: PropTypes.node.isRequired,
        dashboardPath: PropTypes.string.isRequired,
        deserializedProject: PropTypes.object,
        destroy: PropTypes.func.isRequired, // from reduxForm
        dirty: PropTypes.bool.isRequired, // from reduxForm
        disabled: PropTypes.bool,
        initialize: PropTypes.func.isRequired, // from reduxForm
        initializeProject: PropTypes.func.isRequired,
        initiateSneakyProjectUpdate: PropTypes.func.isRequired,
        isClientLoaded: PropTypes.bool.isRequired,
        isConfirmation: PropTypes.bool.isRequired,
        isDraftPage: PropTypes.bool,
        isEditableStatus: PropTypes.bool.isRequired,
        isEditor: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool.isRequired,
        isPseudoSection: PropTypes.bool.isRequired,
        loadInitialDataError: PropTypes.string,
        loaded: PropTypes.bool,
        location: PropTypes.object,
        params: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
        project: PropTypes.shape({
            departmentName: PropTypes.string.isRequired,
            financialId: PropTypes.string,
            isIntake: PropTypes.bool.isRequired,
            status: PropTypes.string.isRequired,
            template: PropTypes.shape({
                title: PropTypes.string.isRequired,
            }).isRequired,
            title: PropTypes.string,
            type: PropTypes.string.isRequired,
            useManualNumbering: PropTypes.bool,
            useSectionDividers: PropTypes.bool.isRequired,
            wasPosted: PropTypes.bool.isRequired,
        }),
        projectSection: PropTypes.shape({
            id: PropTypes.number.isRequired,
            manualNumber: PropTypes.string,
            section_type: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
        }),
        reviewPath: PropTypes.string.isRequired,
        router: PropTypes.object,
        scrollPageToTop: PropTypes.func.isRequired,
        sectionRoutes: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                path: PropTypes.string.isRequired,
            })
        ).isRequired,
        showComments: PropTypes.bool.isRequired,
        showHelpModal: PropTypes.bool.isRequired,
        user: PropTypes.object.isRequired,
        valid: PropTypes.bool.isRequired, // (DGW 2/22/19) WARNING: May not be inaccurate (have seen issues before)
        writingPath: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            displayIndex: this.findSectionIndex(props.projectSection),
        };
    }

    componentDidMount() {
        /**
         * Sets the internal state of the form component before mounting (unrelated to redux-form)
         */
        this.props.initializeProject();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { deserializedProject, isClientLoaded, loaded } = this.props;

        const { deserializedProject: nextDeserializedProject } = nextProps;

        /**
         * (DGW 2/24/19):
         * IMPORTANT: This form includes dates that need to be displayed in the user's timezone.
         * As such we cannot initialize the form with dates from the server as the dates from the
         * server will be adjusted to be in the server's local time. What we need are the dates in
         * the user's browser local time, so we need to wait for the client to load before
         * initializing the form data which includes these timezone dependent dates.
         *
         * The form can get initialized from:
         * 1. Server (via a page refresh or direct link)
         * 2. Front-end (via clicking into the form form another page)
         *
         * When initialized from the client (#2) the dates come from the browser and are properly
         * deserialized. This is handled in the connected `initialValues` prop.
         *
         * This initialization will get called when the form is loaded by the server (#1). The form
         * will not be initialized with values by the server and instead be initialized here
         * once the client has rendered. Doing the initialization this way allows the form to have
         * the correct dates that are localized for the browser's timezone.
         */
        if (nextDeserializedProject && loaded && !isClientLoaded && nextProps.isClientLoaded) {
            this.initializeForm(nextDeserializedProject);
        }

        /**
         * (DGW 2/24/19):
         * NOTE: Current logic prevents this path from happening (project is always loaded on
         * front-end before any route to the form). This could easily change though.
         * That means this path below will not be trafficked.
         */
        if (nextProps.loaded && !loaded) {
            this.initializeForm(nextDeserializedProject);
        }

        /**
         * This only happens when update is called without the sneaky flag.
         * Update will reinitialize the form while sneaky update will save
         * the project without updating/re-initializing the form in order to
         * preserve the form as is (sneaky is a background update)
         */
        if (nextDeserializedProject !== deserializedProject && nextProps.loaded) {
            this.initializeForm(nextDeserializedProject);
        }
    }

    componentDidUpdate(prevProps) {
        const { projectSection } = this.props;

        if (prevProps.projectSection !== projectSection) {
            this.setDisplayIndex(this.findSectionIndex(projectSection));
        }
    }

    componentWillUnmount() {
        this.props.destroy();
    }

    get styles() {
        return require('./ProjectCreate.scss');
    }

    get filteredSectionRoutes() {
        const { project, sectionRoutes } = this.props;

        if (project.useSectionDividers) {
            return sectionRoutes.filter((route) => route.section_type !== DIVIDER);
        }

        return sectionRoutes;
    }

    get pagesToDisplay() {
        const sectionRoutes = this.filteredSectionRoutes;

        if (sectionRoutes.length <= 6) {
            return sectionRoutes.length;
        }

        return 5;
    }

    get startIndex() {
        const sectionRoutes = this.filteredSectionRoutes;
        const { displayIndex } = this.state;
        const pagesToDisplay = this.pagesToDisplay;
        const totalSections = sectionRoutes.length;

        const firstIndex = displayIndex - Math.floor(pagesToDisplay / 2);
        const lastIndex = displayIndex + Math.ceil(pagesToDisplay / 2);

        if (firstIndex >= 0 && lastIndex <= totalSections) return firstIndex;
        if (firstIndex >= 0) return firstIndex - (lastIndex - totalSections);
        return 0;
    }

    get draftStatus() {
        const { project } = this.props;

        return [REQUEST_DRAFT, DRAFT].includes(project && project.status);
    }

    findSectionIndex = (projectSection) => {
        return this.filteredSectionRoutes.findIndex((section) => section.id === projectSection.id);
    };

    setDisplayIndex = (displayIndex) => {
        this.setState({ displayIndex });
    };

    incrementDisplayIndex = () => this.setDisplayIndex(this.startIndex + 3);

    decrementDisplayIndex = () => this.setDisplayIndex(this.startIndex + 1);

    initializeForm = (project) => {
        this.props.initialize(project);
        this.props.initializeProject();
    };

    saveThenNavigate = (nextRoute, alwaysScrollToTop) => {
        const {
            dirty,
            initiateSneakyProjectUpdate,
            project: { wasPosted },
            user: { role },
            valid,
        } = this.props;

        // Just navigate instead of navigate and saving when:
        // - There have been no changes made
        // - In review state and form is invalid (will cause an error on saving)
        // - Project has been posted (will prompt before saving)
        if (!dirty || role === BATMAN || (!this.draftStatus && !valid) || wasPosted) {
            if (alwaysScrollToTop) {
                this.props.scrollPageToTop();
            }
            this.props.router.push(nextRoute);
        } else {
            // If there are changes start saving the project before navigating
            initiateSneakyProjectUpdate(nextRoute);
        }
    };

    handleTitleClick = () => {
        const { dashboardPath } = this.props;

        this.props.router.push(dashboardPath);
    };

    renderLoadingTitle() {
        return (
            <div className="row">
                <div className="col-xs-12 text-center">
                    <h4 className={this.styles.header}>
                        <i className="fa fa-cog fa-spin text-muted" /> Preparing your Project...
                    </h4>
                </div>
            </div>
        );
    }

    renderSectionTitleError(errorText) {
        return (
            <div className="row">
                <div className="col-xs-12 text-center">
                    <h4 className={this.styles.header}>
                        <i className="fa fa-warning text-danger" /> {errorText}
                    </h4>
                </div>
            </div>
        );
    }

    renderTopMenu() {
        const { isLoading, loadInitialDataError, project } = this.props;

        const { connectedClients, topMenuButtons, sectionEditButton, tourButton } = this.styles;

        if (isLoading || !project || loadInitialDataError) {
            return null;
        }

        return (
            <div className={`row ${topMenuButtons}`}>
                {!project.isIntake && (
                    <>
                        <div className="col-xs-4 col-md-12" style={{ paddingLeft: 0 }}>
                            <ProjectSectionsEditButton
                                className={sectionEditButton}
                                project={project}
                                text="Edit Sections"
                            />
                        </div>
                        <div className="col-xs-4 col-md-12">
                            <TourButton
                                className={`tutorial-btn ${tourButton}`}
                                qaTag="projectCreate-tour"
                                text="Show Tutorial"
                            />
                        </div>
                    </>
                )}
                <div className="col-xs-4 col-md-12">
                    <ConnectedClients className={connectedClients} />
                </div>
            </div>
        );
    }

    renderProjectMode() {
        const {
            isEditor,
            isDraftPage,
            disabled,
            reviewPath,
            writingPath,
            location: {
                query: { section },
            },
        } = this.props;

        if (!isEditor) {
            return null;
        }

        return (
            <ProjectModeToggle
                checked={isDraftPage}
                className={`action-toolbar-mode-toggle ${this.styles.projectToggle}`}
                disabled={disabled}
                reviewPath={`${reviewPath}/document`}
                section={section}
                writingPath={writingPath}
            />
        );
    }

    renderProjectHeader() {
        const { project } = this.props;

        const {
            noHorizontalPadding,
            noVerticalPadding,
            projectDataContainer,
            projectHeaderRow,
            projectInfoText,
            projectTitle,
        } = this.styles;

        return (
            <div className={`row ${projectHeaderRow}`}>
                <div
                    className={`col-xs-11 col-sm-10 col-sm-offset-1 ${noVerticalPadding} ${noHorizontalPadding}`}
                >
                    <div className="row">
                        <div
                            className="col-md-2 col-md-push-10 col-xs-12"
                            style={{ paddingLeft: 0, paddingTop: '8px' }}
                        >
                            {this.renderTopMenu()}
                        </div>
                        <div className={`col-md-10 col-md-pull-2 col-xs-12 ${noHorizontalPadding}`}>
                            <h1 className={projectTitle}>
                                <span onClick={this.handleTitleClick}>
                                    {project.title || 'Untitled'}
                                </span>
                            </h1>
                        </div>
                        <div className={`col-xs-12 ${noHorizontalPadding}`}>
                            <div className={projectDataContainer}>
                                <div className={`${noHorizontalPadding} ${projectInfoText}`}>
                                    <ProjectStatusLabel status={project.status} />
                                </div>
                                <div
                                    className={`text-muted ${noHorizontalPadding} ${projectInfoText}`}
                                >
                                    <strong>Type: </strong>
                                    {startCase(project.template.title)}
                                </div>
                                <div
                                    className={`text-muted ${noHorizontalPadding} ${projectInfoText}`}
                                >
                                    <strong>Department: </strong>
                                    {project.departmentName}
                                </div>
                                <div className={`text-muted ${noHorizontalPadding}`}>
                                    <strong>Project ID: </strong>
                                    {project.financialId || 'None'}
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 text-right">{this.renderProjectMode()}</div>
                    </div>
                </div>
            </div>
        );
    }

    renderSectionTitle() {
        const {
            builderDisplayName,
            isConfirmation,
            isLoading,
            isPseudoSection,
            loadInitialDataError,
            project,
            projectSection: { manualNumber, section_type: sectionType },
            projectSection,
            showComments,
        } = this.props;

        const { commentIcon, header, sectionTypeHeader, titleContainer } = this.styles;

        // Render loading state when loading or no project and no load error
        if (isLoading || (!project && !loadInitialDataError)) {
            return this.renderLoadingTitle();
        }
        if (loadInitialDataError) {
            return this.renderSectionTitleError('Error Loading Project');
        }

        if (!projectSection && !isConfirmation) {
            return this.renderSectionTitleError('Section Not Found');
        }

        const title = isConfirmation
            ? `Review ${builderDisplayName} Confirmation`
            : projectSection.title;
        const manualNumbering =
            project.useManualNumbering && manualNumber ? `${manualNumber} ` : '';
        const allowComments = !isConfirmation && !isPseudoSection;

        const showType = sectionType !== DOCUMENT_SETUP && sectionType !== GLOBAL_DATA;

        return (
            <div className={`row ${titleContainer}`}>
                <PageTitle title={title} />
                <div className="col-xs-12 project-title" style={{ paddingTop: '4px' }}>
                    <h3 className={header}>
                        {manualNumbering}
                        {title}
                        {allowComments && (
                            <CommentIcon
                                className={commentIcon}
                                iconLeft
                                projectSection={projectSection}
                                show={showComments}
                                subsectionType={BODY}
                            />
                        )}
                    </h3>
                    {showType && defaultSectionConfigsMap[sectionType] && (
                        <div className={`text-muted ${sectionTypeHeader}`}>
                            Type: {defaultSectionConfigsMap[sectionType].title}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderSideNav() {
        const {
            builderSectionsMap,
            disabled,
            isConfirmation,
            project: { useManualNumbering, useSectionDividers },
            projectSection,
            sectionRoutes,
        } = this.props;

        const { mainNavItems } = this.styles;

        const links = sectionRoutes.map((sectionRoute, index) => {
            const isActive = !isConfirmation && sectionRoute.id === projectSection.id;
            const wasVisited =
                !!get(builderSectionsMap[sectionRoute.id], 'wasVisited') ||
                isPseudoSectionType(sectionRoute.section_type);

            return (
                <Field
                    component={SectionNavButton}
                    disabled={disabled}
                    index={index}
                    isActive={isActive}
                    key={index}
                    name={`sections.${sectionRoute.id}`}
                    onClick={this.saveThenNavigate}
                    projectSection={sectionRoute}
                    useManualNumbering={useManualNumbering}
                    useSectionDividers={useSectionDividers}
                    wasVisited={!this.draftStatus || wasVisited}
                />
            );
        });

        return (
            <div className="project-sections" style={{ paddingTop: '24px' }}>
                <Nav bsStyle="pills" className={mainNavItems} stacked>
                    {links}
                </Nav>
            </div>
        );
    }

    renderControlButtons() {
        const {
            builderDisplayName,
            disabled,
            isConfirmation,
            isLoading,
            loadInitialDataError,
            project,
            projectSection,
            reviewPath,
            writingPath,
        } = this.props;

        if (isLoading || loadInitialDataError || !project || isConfirmation || !projectSection) {
            return null;
        }

        const sectionRoutes = this.filteredSectionRoutes;
        const foundIndex = findIndex(sectionRoutes, (section) => section.id === projectSection.id);
        const index = foundIndex === -1 ? 0 : foundIndex;
        const backRoute = index > 0 ? sectionRoutes[index - 1].path : null;
        const nextRoute = index < sectionRoutes.length - 1 ? sectionRoutes[index + 1].path : null;
        const isLastPage = index === sectionRoutes.length - 1;
        return (
            <Field
                backRoute={backRoute}
                builderDisplayName={builderDisplayName}
                component={ControlButtons}
                disabled={disabled}
                isLastPage={isLastPage}
                name={ALL_SECTIONS}
                nextRoute={nextRoute}
                projectStatus={project.status}
                reviewPath={reviewPath}
                saveThenNavigate={this.saveThenNavigate}
                styledContainer={false}
                writingPath={writingPath}
            />
        );
    }

    renderBottomControlButtonsContainer() {
        const { bottomControlButtonsContainer, bottomControlButtonsDiv } = this.styles;

        return (
            <div className={`row ${bottomControlButtonsContainer}`}>
                <div className={`col-md-11 ${bottomControlButtonsDiv}`}>
                    {this.renderControlButtons()}
                </div>
            </div>
        );
    }

    render() {
        const { children, isEditor, isEditableStatus, project, showHelpModal } = this.props;

        const {
            container,
            contentContainer,
            contentPanel,
            editArea,
            formContainer,
            noHorizontalPadding,
            noVerticalPadding,
            sideNavContainer,
        } = this.styles;

        // Prevent finalized document from being edited in case someone navigates directly
        if (project && !isEditableStatus) {
            const message = isEditor
                ? 'Document has been finalized and is no longer editable'
                : 'You have not received permission to edit this document';

            return <CreateFormNotEditable text={message} />;
        }

        return (
            <div className={container}>
                <h1 className="visually-hidden">Create Project</h1>
                <RouteLeaveWarning blockingValue={this.props.dirty} />
                {this.renderProjectHeader()}
                <div className="row">
                    <Panel className={contentPanel}>
                        <Panel.Body className={noVerticalPadding}>
                            <div
                                className={`col-md-11 col-sm-10 col-sm-offset-1 col-xs-12 ${noHorizontalPadding} ${noVerticalPadding}`}
                            >
                                <div className="row">
                                    <div className={`col-sm-12 ${noVerticalPadding}`}>
                                        <div className={`col-md-3 ${sideNavContainer}`}>
                                            {this.renderSideNav()}
                                        </div>
                                        <div className={`col-md-9 ${contentContainer}`}>
                                            {this.renderSectionTitle()}
                                            <div className={`row ${formContainer}`}>
                                                <div className={`col-xs-12 col-md-10 ${editArea}`}>
                                                    {children}
                                                </div>
                                            </div>
                                            {this.renderBottomControlButtonsContainer()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel.Body>
                    </Panel>
                </div>
                <SaveModal />
                {showHelpModal && <ProjectCreateHelpModal />}
            </div>
        );
    }
}

export const ProjectCreate = compose(
    connectData(fetchData),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedProjectCreate);

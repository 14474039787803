import { createSelector } from 'reselect';

const getInstructionsModalData = (state) => state.govProjects.get('instructionsModalData');

export const getInstructionsModalDataJS = createSelector(
    [getInstructionsModalData],
    (instructionsModalData) => {
        if (instructionsModalData) {
            return instructionsModalData.toJS();
        }
        return {};
    }
);

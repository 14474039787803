import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Button, OutlineButton } from '..';

export class HoverButton extends PureComponent {
    static propTypes = {
        bsStyle: PropTypes.string,
        children: PropTypes.node,
        hoverChildren: PropTypes.node,
        hoverStyle: PropTypes.string,
        outlineButton: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.state = { isHovering: false };
    }

    toggleHoverOn = () => {
        this.setState({ isHovering: true });
    };

    toggleHoverOff = () => {
        this.setState({ isHovering: false });
    };

    render() {
        const { bsStyle, children, hoverChildren, hoverStyle, outlineButton, ...props } =
            this.props;

        const { isHovering } = this.state;

        const buttonStyle = isHovering && hoverStyle ? hoverStyle : bsStyle;
        const buttonChildren = isHovering && hoverChildren ? hoverChildren : children;

        if (outlineButton) {
            return (
                <OutlineButton
                    bsStyle={buttonStyle}
                    onMouseEnter={this.toggleHoverOn}
                    onMouseLeave={this.toggleHoverOff}
                    {...props}
                >
                    {buttonChildren}
                </OutlineButton>
            );
        }

        return (
            <Button
                bsStyle={buttonStyle}
                onMouseEnter={this.toggleHoverOn}
                onMouseLeave={this.toggleHoverOff}
                {...props}
            >
                {buttonChildren}
            </Button>
        );
    }
}

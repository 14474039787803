import classNames from 'classnames';
import React from 'react';

import { Button, buttonPropTypes } from '../Button';

export const DividerButton = ({ children, className, ...props }) => {
    const styles = require('./index.scss');

    return (
        <div className={styles.buttonWithDividerContainer}>
            <hr className={styles.divider} />
            <Button className={classNames(styles.buttonWithDivider, className)} {...props}>
                <i className="fa fa-plus" /> {children}
            </Button>
            <hr className={styles.divider} />
        </div>
    );
};

DividerButton.propTypes = buttonPropTypes;

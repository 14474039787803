import { fromJS } from 'immutable';
import moment from 'moment';

import {
    DOWNLOAD_VENDOR_LIST_FAIL,
    DOWNLOAD_VENDOR_LIST_START,
    DOWNLOAD_VENDOR_LIST_SUCCESS,
    LOAD_INVITED_VENDOR_LIST,
    LOAD_INVITED_VENDOR_LIST_FAIL,
    LOAD_INVITED_VENDOR_LIST_SUCCESS,
    LOAD_SUBSCRIBED_VENDOR_CERTIFICATIONS,
    LOAD_SUBSCRIBED_VENDOR_CERTIFICATIONS_SUCCESS,
    LOAD_SUBSCRIBED_VENDOR_CERTIFICATIONS_FAIL,
    LOAD_VENDOR_CLASSIFICATION_TOTALS,
    UPDATE_SEARCH_VENDOR_PARAMS,
    UPDATE_SEARCH_VENDOR_PARAMS_FAIL,
    VENDOR_SUBSCRIPTION_REMOVED,
    LOAD_VENDOR_LISTS,
    LOAD_VENDOR_LIST,
    LOAD_VENDOR_LIST_FAIL,
    LOAD_VENDOR_LIST_SUCCESS,
    ADDING_VENDORS_TO_LIST_MODAL,
    ADDING_VENDORS_TO_LIST_MODAL_SUCCESS,
    ADDING_VENDORS_TO_LIST_MODAL_FAIL,
    TOGGLE_ADD_VENDORS_TO_LIST_MODAL,
    FETCH_VENDORS_LISTS_SUCCESS,
    LOAD_VENDOR_CLASSIFICATION_TOTALS_SUCCESS,
    LOAD_VENDOR_CLASSIFICATION_TOTALS_FAIL,
    LOAD_VENDOR_LISTS_FAIL,
    LOAD_VENDOR_LISTS_SUCCESS,
    TOGGLE_INVITE_VENDOR_LIST_TO_PROJECT_MODAL,
    UPDATE_VENDOR_COUNT,
} from '../actions/vendorList';

const initialState = fromJS({
    invitedVendorList: [],
    loadingInvitedVendorList: false,
    loadInvitedVendorListError: null,
    vendorSearchDownloading: false,
    lists: [],
    subscribedVendorCertifications: [],
    vendorCount: 0,
    vendorListDetail: {},
    vendorSearchParams: {
        perPage: 10,
        page: 0,
    },
    vendorSearchError: null,
    showAddVendorsToListModal: false,
    addingVendorsToList: false,
    vendorUsersLists: {},
    vendorClassificationTotals: [],
    loadingClassificationTotal: false,
    loadingSubscribedVendorCertifications: false,
    loadingVendorLists: false,
    loadingVendorList: false,
    loadingVendorListError: null,
    showInviteVendorListToProjectModal: false,
    isVendorListSearch: false,
});

export default function adminGovernmentReducer(state = initialState, action = {}) {
    switch (action.type) {
        case LOAD_INVITED_VENDOR_LIST:
            return state.merge(
                fromJS({
                    loadingInvitedVendorList: true,
                    loadInvitedVendorListError: null,
                })
            );
        case LOAD_INVITED_VENDOR_LIST_SUCCESS:
            return state.merge(
                fromJS({
                    loadingInvitedVendorList: false,
                    invitedVendorList: fromJS(action.result),
                    invitedVendorListUpdateDate: moment().add(1, 'day').toDate(),
                })
            );
        case LOAD_INVITED_VENDOR_LIST_FAIL:
            return state.merge(
                fromJS({
                    loadingInvitedVendorList: false,
                    loadInvitedVendorListError: action.error && action.error.message,
                })
            );
        case VENDOR_SUBSCRIPTION_REMOVED:
            return state.set(
                'invitedVendorList',
                state.get('invitedVendorList').filter((user) => {
                    return user.get('governmentSubscriptionId') !== action.governmentSubscriptionId;
                })
            );
        case LOAD_VENDOR_CLASSIFICATION_TOTALS_SUCCESS:
            return state.merge(
                fromJS({
                    vendorClassificationTotals: fromJS(action.result),
                    loadingClassificationTotal: false,
                })
            );
        case LOAD_VENDOR_CLASSIFICATION_TOTALS:
            return state.set('loadingClassificationTotal', true);
        case LOAD_VENDOR_CLASSIFICATION_TOTALS_FAIL:
            return state.set('loadingClassificationTotal', false);
        case UPDATE_SEARCH_VENDOR_PARAMS:
            return state.merge(
                fromJS({
                    vendorSearchError: null,
                    vendorSearchParams: fromJS(action.searchParams),
                })
            );
        case UPDATE_SEARCH_VENDOR_PARAMS_FAIL:
            return state.set('vendorSearchError', true);
        case DOWNLOAD_VENDOR_LIST_START:
            return state.set('vendorSearchDownloading', true);
        case DOWNLOAD_VENDOR_LIST_FAIL:
            return state.set('vendorSearchDownloading', false);
        case DOWNLOAD_VENDOR_LIST_SUCCESS:
            return state.set('vendorSearchDownloading', false);
        case LOAD_VENDOR_LISTS:
            return state.set('loadingVendorLists', true);
        case LOAD_VENDOR_LISTS_FAIL:
            return state.set('loadingVendorLists', false);
        case LOAD_VENDOR_LISTS_SUCCESS:
            return state.merge(
                fromJS({
                    lists: fromJS(action.lists),
                    loadingVendorLists: false,
                })
            );
        case LOAD_VENDOR_LIST:
            return state.merge(
                fromJS({
                    loadingVendorList: true,
                    loadingVendorListError: null,
                })
            );
        case LOAD_VENDOR_LIST_FAIL:
            return state.merge(
                fromJS({
                    loadingVendorList: false,
                    loadingVendorListError: true,
                })
            );
        case LOAD_VENDOR_LIST_SUCCESS:
            return state.setIn(['vendorListDetail', action.result.id], fromJS(action.result)).merge(
                fromJS({
                    loadingVendorList: false,
                    isVendorListSearch: !!action.isSearch,
                })
            );
        case FETCH_VENDORS_LISTS_SUCCESS:
            return state.setIn(['vendorUsersLists', action.vendorUserId], fromJS(action.lists));
        case TOGGLE_ADD_VENDORS_TO_LIST_MODAL:
            return state.set('showAddVendorsToListModal', !state.get('showAddVendorsToListModal'));
        case TOGGLE_INVITE_VENDOR_LIST_TO_PROJECT_MODAL:
            return state.set(
                'showInviteVendorListToProjectModal',
                !state.get('showInviteVendorListToProjectModal')
            );
        case ADDING_VENDORS_TO_LIST_MODAL:
            return state.set('addingVendorsToList', true);
        case ADDING_VENDORS_TO_LIST_MODAL_SUCCESS:
            return state.set('addingVendorsToList', false);
        case ADDING_VENDORS_TO_LIST_MODAL_FAIL:
            return state.set('addingVendorsToList', false);
        case LOAD_SUBSCRIBED_VENDOR_CERTIFICATIONS:
            return state.set('loadingSubscribedVendorCertifications', true);
        case LOAD_SUBSCRIBED_VENDOR_CERTIFICATIONS_SUCCESS:
            return state.merge(
                fromJS({
                    subscribedVendorCertifications: fromJS(action.result),
                    loadingSubscribedVendorCertifications: false,
                })
            );
        case LOAD_SUBSCRIBED_VENDOR_CERTIFICATIONS_FAIL:
            return state.set('loadingSubscribedVendorCertifications', false);
        case UPDATE_VENDOR_COUNT:
            return state.set('vendorCount', action.vendorCount);
        default:
            return state;
    }
}

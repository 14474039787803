import {
    contractDateFilterTypesDict,
    contractFilterTypesDict,
} from '@og-pro/shared-config/contracts';

import { fieldNames } from './constants';

const {
    BUDGET_AMOUNT,
    CONTRACT_ID,
    COOPERATIVE,
    DEPARTMENT,
    END_DATE,
    PIGGYBACK,
    PUBLIC,
    REBID,
    RETENTION_CODE_ID,
    START_DATE,
    STATUS,
    TAG,
    TITLE,
    VENDOR_NAME,
} = contractFilterTypesDict;

const { BETWEEN } = contractDateFilterTypesDict;

const { FILTERS } = fieldNames;

export function validate(values) {
    const filters = values[FILTERS] || [];

    const formErrors = {};

    const filterErrors = filters.map((filter) => {
        const currentFilterErrors = {};

        if (!filter.type) {
            currentFilterErrors.type = 'A type is required';
        }

        switch (filter.type) {
            case BUDGET_AMOUNT:
                if (!filter.costFilterType) {
                    currentFilterErrors.costFilterType = 'A cost filter type is required';
                } else if (!filter.value) {
                    currentFilterErrors.value = 'A value is required';
                }
                break;
            case END_DATE:
            case START_DATE:
                if (!filter.dateFilterType) {
                    currentFilterErrors.dateFilterType = 'A date filter range type is required';
                } else if (filter.dateFilterType === BETWEEN) {
                    currentFilterErrors.value = [null, null];

                    if (!filter.value[0]) {
                        currentFilterErrors.value[0] = 'A value is required';
                    }
                    if (!filter.value[1]) {
                        currentFilterErrors.value[1] = 'A value is required';
                    }

                    if (filter.value[0] && filter.value[1]) {
                        const firstDate = filter.value[0];
                        const secondDate = filter.value[1];

                        if (firstDate > secondDate) {
                            currentFilterErrors.value[0] =
                                'The first date must be before the second date';
                        }
                    }
                } else if (!filter.value) {
                    currentFilterErrors.value = 'A value is required';
                }
                break;
            case COOPERATIVE:
            case DEPARTMENT:
            case PIGGYBACK:
            case PUBLIC:
            case REBID:
            case RETENTION_CODE_ID:
            case STATUS:
                if (!filter.value && filter.value !== 0) {
                    currentFilterErrors.value = 'A value is required';
                }
                break;
            case TAG:
                if (!filter.value || filter.value.length === 0) {
                    currentFilterErrors.value = 'At least one tag must be selected';
                }
                break;
            case CONTRACT_ID:
            case TITLE:
            case VENDOR_NAME:
            default:
                break;
            // We let the server handle empty TITLE and VENDOR_NAME filters
        }

        return currentFilterErrors;
    });

    formErrors[FILTERS] = filterErrors;

    return formErrors;
}

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { Box } from '@og-pro/ui';

import { Button, StatusLabel, ZeroState } from '../../../../../../components';

export const AllProposalsSelectOptions = ({ onClick, proposals }) => {
    const [selectedIds, setSelectedIds] = useState(new Set(proposals.map(({ id }) => id)));

    const selectProposal = (proposalId) => {
        const newSelectedIdsSet = selectedIds.has(proposalId)
            ? new Set([...selectedIds].filter((id) => id !== proposalId))
            : new Set([...selectedIds, proposalId]);

        setSelectedIds(newSelectedIdsSet);
    };

    const submitHandler = () => {
        onClick({
            includeAttachments: true,
            proposalIds: Array.from(selectedIds),
        });
    };

    if (proposals.length === 0) {
        return <ZeroState title="No responses to select" />;
    }

    return (
        <>
            <Box component="h5" marginBottom={2}>
                Choose Responses to Export
            </Box>
            <Box component="p" marginBottom={2}>
                <em>You will receive an individual email for each proposal selected</em>
            </Box>
            <ListGroup>
                {proposals.map((proposal) => (
                    <ListGroupItem
                        bsStyle={selectedIds.has(proposal.id) ? 'info' : undefined}
                        key={proposal.id}
                        onClick={() => selectProposal(proposal.id)}
                    >
                        <div>
                            <i
                                className={`fa fa-fw fa-${selectedIds.has(proposal.id) ? 'check-circle' : 'circle-thin'}`}
                            />
                            &nbsp;
                            {proposal.companyName || 'Unnamed Company'}
                        </div>
                        {proposal.isDisqualified && (
                            <>
                                <StatusLabel bsStyle="muted">Disqualified</StatusLabel>{' '}
                            </>
                        )}
                        {proposal.isExcluded && <StatusLabel bsStyle="muted">Excluded</StatusLabel>}
                    </ListGroupItem>
                ))}
            </ListGroup>
            <div className="pull-right">
                <Button bsStyle="primary" disabled={selectedIds.size === 0} onClick={submitHandler}>
                    <i className="fa fa-download" /> Export Responses
                </Button>
            </div>
        </>
    );
};

AllProposalsSelectOptions.propTypes = {
    onClick: PropTypes.func.isRequired,
    proposals: PropTypes.arrayOf(
        PropTypes.shape({
            companyName: PropTypes.string,
            isDisqualified: PropTypes.bool,
            isExcluded: PropTypes.bool,
            id: PropTypes.number.isRequired,
        })
    ).isRequired,
};

/**
 * Gets the number of bids that are tied if 2 or more bids have the same value
 * @param {array} bids List of bid objects containing an ID and value, sorted by ascending value
 * @param {number} startingIdx starting index to check for tying bids
 * @returns {number} the number of tied bids, beginning from the startingIdx (inclusive)
 * ex: getNumberOfTyingBids([0.50, 1.50, 2.25, 2.25, 2.25, 3.50], 2) -> returns 3
 */
const getNumberOfTyingBids = (bids, startingIdx) => {
    let numberOfTyingBids = 0;

    for (let i = startingIdx; i < bids.length; i++) {
        if (bids[i] && bids[i].value === bids[startingIdx].value) {
            numberOfTyingBids++;
        } else {
            break;
        }
    }

    return numberOfTyingBids;
};

/**
 * Creates a map of IDs (vendorPriceItemId or vendorId) and their corresponding rank within a reverse auction project.
 * Ranking is determined by a value (ex: unitPrice, grandTotal). Lower values have a higher rank.
 * Duplicate rank values will be issued in the event that 2 or more bids are equal (this scenario can only occur
 * if competing vendors submit proposals with the same initial bid, prior to a reverse auction starting).
 * Once a reverse auction starts, vendors can only bid lower than the current lowest price.
 * ex: bids = [0.50, 1.50, 2.25, 2.25, 2.25, 3.50]
 * rank: 1st, 2nd, 3rd, 3rd, 3rd, 6th
 * 4th & 5th place are intentionally skipped in this case, because we initially have 3 vendors in 3rd place.
 * @param {array} bids List of bid objects containing an ID and value, sorted by ascending value
 * @returns {object} map with ID as key, rank as value
 */
export const getBidRankingMap = (bids) => {
    const bidRankingMap = {};
    let currentRank = 1;

    for (let i = 0; i < bids.length; i++) {
        if (bids[i + 1] && bids[i].value === bids[i + 1].value) {
            const numberOfTyingBids = getNumberOfTyingBids(bids, i);

            for (let j = i; j < i + numberOfTyingBids; j++) {
                bidRankingMap[bids[j].id] = currentRank;
            }

            currentRank += numberOfTyingBids;
            i += numberOfTyingBids - 1;
        } else {
            bidRankingMap[bids[i].id] = currentRank;
            currentRank++;
        }
    }

    return bidRankingMap;
};

import { Box } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React from 'react';
import { components } from 'react-select';

import { labelStyle, secondaryStyle, tertiaryStyle } from './styles';

export const SearchSelectMultiLabelOption = ({
    label,
    secondaryLabel,
    tertiaryLabel,
    ...props
}) => {
    return (
        <components.Option {...props}>
            {secondaryLabel && <Box sx={secondaryStyle}>{secondaryLabel}</Box>}
            <Box sx={labelStyle}>{label}</Box>
            {tertiaryLabel && <Box sx={tertiaryStyle}>{tertiaryLabel}</Box>}
        </components.Option>
    );
};

SearchSelectMultiLabelOption.propTypes = {
    label: PropTypes.string.isRequired,
    secondaryLabel: PropTypes.string,
    tertiaryLabel: PropTypes.string,
};

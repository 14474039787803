import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Field } from 'redux-form';
import { Draggable } from 'react-beautiful-dnd';
import { Box, Typography } from '@og-pro/ui';

import { customTimelineFieldsDict, timelineDatesDict } from '@og-pro/shared-config/timelines';

import { CDSButton, DragIcon, InputText, Toggle } from '../../../..';
import { TIMELINE_CONFIG, TIMELINES } from '../../../../../containers/GovApp/constants';
import { limitTextLength } from '../../../../../Forms/normalizers';
import { TIMELINE_PROPTYPE } from '../constants';
import { AuctionInput } from './TimelineInputs/Auction';

const { TITLE } = customTimelineFieldsDict;

const { AUCTION_END_DATE, AUCTION_START_DATE } = timelineDatesDict;

export const TimelineToggles = ({
    change,
    disabled,
    editMode,
    index: timelineIndex,
    isReverseAuction,
    onCustomEventRemove,
    showValidation,
    timeline,
}) => {
    const styles = require('./shared.scss');

    const onToggleChange = (e) => {
        const { isIncludedField, isRequiredField, locationField, projectField } = timeline;
        const { checked } = e.target;

        if (checked) {
            return change(`${TIMELINE_CONFIG}.${isIncludedField}`, true);
        }

        if (locationField) {
            change(`${TIMELINE_CONFIG}.${locationField}`, null);
        }
        if (isRequiredField) {
            change(`${TIMELINE_CONFIG}.${isRequiredField}`, false);
        }
        if (projectField) {
            change(`${projectField}`, null);
        }

        return change(`${TIMELINE_CONFIG}.${isIncludedField}`, false);
    };

    const {
        hasAuctionExtension,
        index,
        isCustomDate,
        isIncluded,
        isIncludedField,
        projectField,
        required,
        title,
        titleField,
    } = timeline;
    const titleFieldName = titleField
        ? `${TIMELINE_CONFIG}.${titleField}`
        : `${TIMELINES}.${index}.${TITLE}`;

    const isAuctionEndDate = projectField === AUCTION_END_DATE;
    const isAuctionDate = projectField === AUCTION_START_DATE || isAuctionEndDate;
    const isRequired = required || (isAuctionDate && isReverseAuction);
    // to match the design...makes sure elements are centered, except when its a reverse auction
    // end date with the extension opened. In that case things need to be top aligned.
    const itemAlignment =
        isAuctionEndDate && hasAuctionExtension && isReverseAuction ? 'flex-start' : 'center';

    return (
        <Draggable
            draggableId={titleFieldName}
            index={timelineIndex}
            isDragDisabled={!isCustomDate}
            key={titleFieldName}
        >
            {(provided) => (
                <div
                    className={classnames(styles.timeline, {
                        [styles.hidden]: isAuctionDate && !isReverseAuction,
                    })}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                >
                    <Box alignItems={itemAlignment} display="flex">
                        {(editMode || isCustomDate) && (
                            <Box flex={5}>
                                <Field
                                    component={InputText}
                                    disabled={disabled || (!required && !isIncluded)}
                                    hasFeedback={false}
                                    name={titleFieldName}
                                    normalize={limitTextLength(128)}
                                    qaTag={`timelineForm-${title}TitleField`}
                                    showValidation={showValidation}
                                    type="text"
                                    useOpenGovStyle
                                />
                            </Box>
                        )}
                        {!editMode && !isCustomDate && (
                            <Box flex={5}>
                                <Typography variant="h4">{timeline.title}</Typography>
                            </Box>
                        )}
                        {!isCustomDate && (
                            <Box flex={6} ml={3} pt={1}>
                                {isRequired ? (
                                    <div className={styles.legend}>
                                        The system uses this date. It cannot be removed.
                                    </div>
                                ) : (
                                    <Field
                                        component={Toggle}
                                        disabled={disabled}
                                        input={{
                                            value: required || isIncluded,
                                            onChange: onToggleChange,
                                            onBlur: () => {},
                                        }}
                                        name={`${TIMELINE_CONFIG}.${isIncludedField}`}
                                        qaTag={`timelineForm-${title}ToggleField`}
                                        type="text"
                                        useOpenGovStyle
                                    />
                                )}
                                {isAuctionEndDate &&
                                    isReverseAuction &&
                                    timeline.hasAuctionEndDateField && (
                                        <Box className={styles.inputsContainer} mt={2}>
                                            <AuctionInput
                                                change={change}
                                                disabled={disabled}
                                                showValidation={showValidation}
                                                timeline={timeline}
                                            />
                                        </Box>
                                    )}
                            </Box>
                        )}
                        {isCustomDate && (
                            <>
                                <Box flex={5} ml={3} pt={0.5}>
                                    {onCustomEventRemove && (
                                        <CDSButton
                                            className={styles.removeButton}
                                            onClick={() => onCustomEventRemove(index)}
                                            qaTag="timelineForm-removeCustomEvent"
                                            size="small"
                                            variant="text"
                                        >
                                            <i className="fa fa-times" /> Remove Custom Event
                                        </CDSButton>
                                    )}
                                </Box>
                                <Box flex={1} pt={1} textAlign="right">
                                    <DragIcon
                                        className=""
                                        disabled={disabled}
                                        dragHandleProps={provided.dragHandleProps}
                                    />
                                </Box>
                            </>
                        )}
                    </Box>
                </div>
            )}
        </Draggable>
    );
};

TimelineToggles.propTypes = {
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    editMode: PropTypes.bool,
    index: PropTypes.number.isRequired,
    isReverseAuction: PropTypes.bool,
    onCustomEventRemove: PropTypes.func,
    showValidation: PropTypes.bool,
    timeline: TIMELINE_PROPTYPE.isRequired,
};

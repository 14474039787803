import { fieldNames } from './constants';

const { USERS } = fieldNames;

export function validate(values) {
    const errors = {};

    if (!values[USERS] || values[USERS].length === 0) {
        errors[USERS] = 'A user is required';
    }

    return errors;
}

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Panel } from 'react-bootstrap';

import { Collapse } from '../../../../../../components';

export const Step = ({ Heading, href, Body, panelHeadingClassName }) => {
    const [isOpen, setIsOpen] = useState(false);
    const hasBody = !!Body;

    const styles = require('./index.scss');

    const onClick = () => {
        if (hasBody) {
            setIsOpen((prevState) => !prevState);
        }

        if (href) {
            window.open(href, '_blank');
        }
    };

    return (
        <Panel className={styles.panel}>
            <Panel.Heading
                className={classNames(
                    styles.panelHeading,
                    (hasBody || href) && styles.panelHeadingButton,
                    panelHeadingClassName && panelHeadingClassName(isOpen)
                )}
                onClick={onClick}
            >
                <Heading isOpen={isOpen} />
            </Panel.Heading>
            {hasBody && (
                <Collapse in={isOpen}>
                    <Panel.Body className={styles.panelBody}>
                        <div className={styles.panelBodyContainer}>
                            <Body isOpen={isOpen} />
                        </div>
                    </Panel.Body>
                </Collapse>
            )}
        </Panel>
    );
};

Step.propTypes = {
    Body: PropTypes.node,
    Heading: PropTypes.node.isRequired,
    href: PropTypes.string,
    panelHeadingClassName: PropTypes.func,
};

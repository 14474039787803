import { fromJS, Map } from 'immutable';

import * as dashboardActions from '../actions/globalEditorDashboard';

const initialState = new Map({
    loadedDashboard: false,
    loadingDashboard: false,
    loadedEvents: false,
    loadingEvents: false,
});

export default function dashboardReducer(state = initialState, action = {}) {
    switch (action.type) {
        case dashboardActions.LOAD:
            return state.merge(
                fromJS({
                    loadedDashboard: false,
                    loadingDashboard: true,
                    loadDashboardError: null,
                })
            );
        case dashboardActions.LOAD_EVENTS:
            return state.merge(
                fromJS({
                    loadedEvents: false,
                    loadingEvents: true,
                    loadEventsError: null,
                })
            );
        case dashboardActions.LOAD_SUCCESS:
            return state.merge(
                fromJS({
                    loadedDashboard: true,
                    loadingDashboard: false,
                    projects: fromJS(action.result.projects),
                    projectsInAwardPending: fromJS(action.result.projectsInAwardPending),
                    projectsInEvaluation: fromJS(action.result.projectsInEvaluation),
                    unsubmittedScorecardProjects: fromJS(
                        action.result.unsubmittedScorecardProjects
                    ),
                })
            );
        case dashboardActions.LOAD_EVENTS_SUCCESS:
            return state.merge(
                fromJS({
                    loadedEvents: true,
                    loadingEvents: false,
                    events: fromJS(action.result.events),
                })
            );
        case dashboardActions.LOAD_FAIL:
            return state.merge(
                fromJS({
                    loadingDashboard: false,
                    loadedDashboard: false,
                    loadDashboardError: action.error && action.error.message,
                })
            );
        case dashboardActions.LOAD_EVENTS_FAIL:
            return state.merge(
                fromJS({
                    loadingEvents: false,
                    loadedEvents: false,
                    loadEventsError: action.error && action.error.message,
                })
            );
        default:
            return state;
    }
}

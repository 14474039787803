import React from 'react';
import PropTypes from 'prop-types';

import { BasicSectionSettings } from './BasicSectionSettings';
import { FormSettings } from './FormSettings';
import { GeneralSectionSettings } from './GeneralSectionSettings';
import { PurchaseSectionSettings } from './PurchaseSectionSettings';
import { fieldNames, sectionNames } from '../constants';

const { ADDITIONAL, ATTACHMENTS, CUSTOM_FORM, FORM_SETTINGS, GENERAL, PURCHASE, VENDOR } =
    sectionNames;
const {
    SECTION_INSTRUCTIONS_ADDITIONAL,
    SECTION_INSTRUCTIONS_CUSTOM_FORM,
    SECTION_INSTRUCTIONS_ATTACHMENT,
    SECTION_INSTRUCTIONS_VENDOR,
    SECTION_NAME_ADDITIONAL,
    SECTION_NAME_CUSTOM_FORM,
    SECTION_NAME_ATTACHMENT,
    SECTION_NAME_VENDOR,
} = fieldNames;

export const SettingsStickySidebar = ({ disabled, onClose, section }) => {
    const sharedSectionProps = {
        disabled,
        onClose,
    };

    switch (section) {
        case GENERAL:
            return <GeneralSectionSettings {...sharedSectionProps} title="Section 1" />;
        case PURCHASE:
            return <PurchaseSectionSettings {...sharedSectionProps} title="Section 2" />;
        case VENDOR:
            return (
                <BasicSectionSettings
                    {...sharedSectionProps}
                    sectionInstructionsField={SECTION_INSTRUCTIONS_VENDOR}
                    sectionNameField={SECTION_NAME_VENDOR}
                    title="Section 3"
                />
            );
        case CUSTOM_FORM:
            return (
                <BasicSectionSettings
                    {...sharedSectionProps}
                    sectionInstructionsField={SECTION_INSTRUCTIONS_CUSTOM_FORM}
                    sectionNameField={SECTION_NAME_CUSTOM_FORM}
                    title="Section 4"
                />
            );
        case ADDITIONAL:
            return (
                <BasicSectionSettings
                    {...sharedSectionProps}
                    sectionInstructionsField={SECTION_INSTRUCTIONS_ADDITIONAL}
                    sectionNameField={SECTION_NAME_ADDITIONAL}
                    title="Section 5"
                />
            );
        case ATTACHMENTS:
            return (
                <BasicSectionSettings
                    {...sharedSectionProps}
                    sectionInstructionsField={SECTION_INSTRUCTIONS_ATTACHMENT}
                    sectionNameField={SECTION_NAME_ATTACHMENT}
                    title="Section 6"
                />
            );
        case FORM_SETTINGS:
        default:
            return <FormSettings disabled={disabled} />;
    }
};

SettingsStickySidebar.propTypes = {
    disabled: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    section: PropTypes.string,
};

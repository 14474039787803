import { upperCase } from 'lodash';
import React, { useEffect } from 'react';
import { Panel } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import connectData from '../../ConnectData';
import { loadChecklist, resetChecklist } from '../../../actions/checklists';
import {
    ContractChecklist as ContractChecklistComponent,
    LoadingError,
    LoadingSpinner,
    StatusLabel,
} from '../../../components';
import { checklistStatusClass } from '../../../components/helpers/statusHelper';
import { getChecklistJS } from '../../../selectors/app';
import { useChecklistSocket } from '../../hooks/checklistHooks';

function fetchData(getState, dispatch, location, params) {
    const checklistId = Number.parseInt(params.checklistId, 10);
    return dispatch(loadChecklist(checklistId));
}

const ConnectedContractChecklist = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.checklists.get('loadingChecklist'));
    const loadError = useSelector((state) => state.checklists.get('loadChecklistError'));
    const checklist = useSelector(getChecklistJS);

    const checklistId = Number.parseInt(params.checklistId, 10);

    // Subscribe to checklist socket
    useChecklistSocket(checklistId);

    useEffect(() => {
        // On leave hook
        return () => {
            dispatch(resetChecklist());
        };
    }, []);

    if (loading) {
        return <LoadingSpinner />;
    }

    if (loadError || !checklist) {
        return <LoadingError error={loadError} />;
    }

    const styles = require('./index.scss');

    return (
        <Panel>
            <Panel.Body>
                <div className={styles.titleContainer}>
                    <h3 className={styles.title}>{checklist.template.title}</h3>
                    <StatusLabel
                        bsStyle={checklistStatusClass(checklist.status)}
                        className={styles.statusLabel}
                    >
                        {upperCase(checklist.status)}
                    </StatusLabel>
                </div>
                <ContractChecklistComponent checklist={checklist} />
            </Panel.Body>
        </Panel>
    );
};

export const ContractChecklist = connectData(fetchData)(ConnectedContractChecklist);

import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { Box, Button } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { qaTagPageName } from '../constants';

export const RelationsOptionsMenu = ({
    addHandler,
    cancelHandler,
    deleteHandler,
    disabled,
    editHandler,
    hasSelections,
    isEditing,
}) => {
    return (
        <Box display="flex" flexDirection="row" gap={1}>
            <Box flex={1}>
                {isEditing ? (
                    <Button
                        color="secondary"
                        disabled={disabled}
                        onClick={cancelHandler}
                        qaTag={`${qaTagPageName}-cancel`}
                        size="small"
                        variant="text"
                    >
                        Cancel
                    </Button>
                ) : (
                    <Button
                        disabled={disabled}
                        onClick={addHandler}
                        qaTag={`${qaTagPageName}-addAnother`}
                        size="small"
                        startIcon={<AddIcon />}
                        variant="text"
                    >
                        Add Another
                    </Button>
                )}
            </Box>
            <div>
                {isEditing ? (
                    <Button
                        color="error"
                        disabled={disabled || !hasSelections}
                        onClick={deleteHandler}
                        qaTag={`${qaTagPageName}-remove`}
                        size="small"
                        startIcon={<DeleteIcon />}
                        variant="text"
                    >
                        Remove Selected Item(s)
                    </Button>
                ) : (
                    <Button
                        color="secondary"
                        disabled={disabled}
                        onClick={editHandler}
                        qaTag={`${qaTagPageName}-edit`}
                        size="small"
                        startIcon={<EditIcon />}
                        variant="text"
                    >
                        Edit
                    </Button>
                )}
            </div>
        </Box>
    );
};

RelationsOptionsMenu.propTypes = {
    addHandler: PropTypes.func.isRequired,
    cancelHandler: PropTypes.func.isRequired,
    deleteHandler: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    editHandler: PropTypes.func.isRequired,
    hasSelections: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool.isRequired,
};

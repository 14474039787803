import { fieldNames } from './constants';

const { ATTACHMENTS, DESCRIPTION, TITLE } = fieldNames;

export const validate = (values, props) => {
    const errors = {};

    if (!values[DESCRIPTION] && (values[ATTACHMENTS] || []).length === 0) {
        errors[DESCRIPTION] = 'Please either enter a description or upload an attachment';
    }

    if (props.isNotice && !values[TITLE]) {
        errors[TITLE] = 'Title required';
    } else if (values[TITLE] && values[TITLE].length > 250) {
        errors[TITLE] = 'Title is too long';
    }

    return errors;
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'redux';

import { fieldNames, form } from './constants';
import { validate } from './validate';
import { getDocumentVersionsJS } from '../../selectors';
import { getProjectJS, isBuilderEditor, isIntakeEditor } from '../../../selectors';
import { createDocumentVersion } from '../../../../../actions/revisions';
import { Button, InputText, Well } from '../../../../../components';

const { NAME } = fieldNames;

const mapStateToProps = (state, props) => {
    const project = getProjectJS(state);
    const isEditor = project && project.isIntake ? isIntakeEditor(state) : isBuilderEditor(state);

    return {
        createError: state.revisions.get('createVersionError'),
        creating: state.revisions.get('creatingVersion'),
        isEditor,
        isZeroState: getDocumentVersionsJS(state).length === 0,
        projectId: Number.parseInt(props.params.projectId, 10),
    };
};

const mapDispatchToProps = {
    createDocumentVersion,
};

const formConfig = {
    form,
    validate,
};

// @withRouter
// @connect
// @reduxForm
class ConnectedVersionForm extends Component {
    static propTypes = {
        createDocumentVersion: PropTypes.func.isRequired,
        createError: PropTypes.string,
        creating: PropTypes.bool,
        handleSubmit: PropTypes.func.isRequired,
        isEditor: PropTypes.bool.isRequired,
        isZeroState: PropTypes.bool.isRequired,
        projectId: PropTypes.number.isRequired,
        reset: PropTypes.func.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    constructor(props) {
        super(props);

        this.state = {
            showCreateForm: false,
        };
    }

    cancelHandler = () => {
        const { reset } = this.props;

        this.setState({ showCreateForm: false });
        reset();
    };

    createVersionHandler = (data) => {
        const { isZeroState, projectId, reset } = this.props;

        this.props.createDocumentVersion(projectId, data).then((resultOrError) => {
            // In zero state, component gets unmounted, so we should not try to set state on it
            if (!(resultOrError instanceof Error) && !isZeroState) {
                this.setState({ showCreateForm: false });
                reset();
            }
        });
    };

    renderForm() {
        const { createError, creating, handleSubmit } = this.props;

        return (
            <form onSubmit={handleSubmit(this.createVersionHandler)}>
                <div className="row">
                    <div className="col-sm-8">
                        <Field
                            autoFocus
                            component={InputText}
                            disabled={creating}
                            hasFeedback={false}
                            name={NAME}
                            placeholder="Version 1"
                            type="text"
                        />
                    </div>
                    <div className="col-sm-4">
                        <Button block bsStyle="primary" disabled={creating} type="submit">
                            Save Version
                        </Button>
                        <Button
                            block
                            bsStyle="link"
                            className={this.styles.cancelButton}
                            disabled={creating}
                            onClick={this.cancelHandler}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
                {createError && <div className="text-danger">{createError}</div>}
            </form>
        );
    }

    render() {
        const { isEditor } = this.props;
        const { showCreateForm } = this.state;

        if (showCreateForm) {
            return (
                <div className="row">
                    <div className="col-md-offset-1 col-md-10">
                        <Well>
                            <label>Save Current Version</label>
                            {this.renderForm()}
                        </Well>
                    </div>
                </div>
            );
        }

        return (
            <div className={this.styles.createVersionButton}>
                <Button
                    bsStyle="primary"
                    disabled={!isEditor}
                    onClick={() => this.setState({ showCreateForm: true })}
                    tooltip={isEditor ? undefined : 'Only project editors can save versions'}
                >
                    <i className="fa fa-archive" /> Save Current Version
                </Button>
            </div>
        );
    }
}

export const VersionForm = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedVersionForm);

import React from 'react';
import PropTypes from 'prop-types';

import { recordDocumentTypesDict } from '@og-pro/shared-config/recordsRetention';

import { CellWrapper } from '../CellWrapper';
import { Tooltip } from '../../../../../components';

const { CONTRACTS } = recordDocumentTypesDict;

export const TitleCell = ({ api, node, value }) => {
    const styles = require('./index.scss');
    const idText = value.recordType === CONTRACTS ? 'Contract ID' : 'Project ID';
    const urlRecordType = value.recordType === CONTRACTS ? 'contracts' : 'projects';

    const projectIdText = `${idText}: ${value.financialId}`;
    const titleText = value.title || 'Untitled';
    const recordLink = `/governments/${value.governmentId}/${urlRecordType}/${value.projectId}`;

    const renderTitle = () => {
        return (
            <Tooltip container={this} isPopover placement="top" rootClose tooltip={titleText}>
                <div className={styles.titleStyles}>
                    <a href={recordLink} rel="noopener noreferrer" target="_blank">
                        {titleText}
                    </a>
                </div>
            </Tooltip>
        );
    };

    return (
        <CellWrapper api={api} node={node}>
            {renderTitle()}
            {value.financialId && (
                <Tooltip
                    container={this}
                    isPopover
                    placement="top"
                    rootClose
                    tooltip={projectIdText}
                >
                    <div className={styles.projectIdStyles}>{projectIdText}</div>
                </Tooltip>
            )}
            {value.openRenewals > 0 && (
                <div className={styles.renewalStyles}>
                    {value.openRenewals} open renewal{value.openRenewals > 1 ? 's' : ''}
                </div>
            )}
        </CellWrapper>
    );
};

TitleCell.propTypes = {
    api: PropTypes.object,
    node: PropTypes.object,
    value: PropTypes.shape({
        governmentId: PropTypes.number,
        title: PropTypes.string,
        projectId: PropTypes.number,
        financialId: PropTypes.string,
        openRenewals: PropTypes.number,
        recordType: PropTypes.string,
    }),
};

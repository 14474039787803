import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { groupedPurchaseOrders, parsePurchaseOrdersFile } from './helpers';
import { HelpIcon, StyledDropzone } from '../../../../components';

export class PurchaseOrderFileUpload extends PureComponent {
    static propTypes = {
        departments: PropTypes.array.isRequired,
        fiscalYearTags: PropTypes.array.isRequired,
        loadPurchaseOrdersImportData: PropTypes.func.isRequired,
        onUploadComplete: PropTypes.func.isRequired,
        user: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            uploadFileError: undefined,
        };
    }

    get styles() {
        return require('./PurchaseOrderFileUpload.scss');
    }

    render() {
        const {
            departments,
            fiscalYearTags,
            loadPurchaseOrdersImportData,
            onUploadComplete,
            user,
        } = this.props;

        const { loading, uploadFileError } = this.state;

        const csvFileUrl =
            'https://procure-now.s3.us-west-2.amazonaws.com/downloads/Purchase_Order_Template_new.csv';
        const xlsxFileUrl =
            'https://procure-now.s3.us-west-2.amazonaws.com/downloads/Purchase_Order_Template_new.xlsx';

        return (
            <div className="row text-center">
                <div className="col-xs-12 col-sm-10 col-sm-offset-1">
                    <p className={this.styles.steps}>
                        <strong>Step 1:</strong>
                        <br />
                        <a className="text-primary" href={xlsxFileUrl}>
                            <i className="fa fa-download" /> Download a correctly-formatted example
                            Purchase Order import file
                            <br />
                            [*.xlsx]
                        </a>
                        &nbsp;
                        <a className="text-primary" href={csvFileUrl}>
                            [*.csv]
                        </a>
                    </p>
                    <p className={this.styles.steps}>
                        <strong>Step 2:</strong>
                        <br />
                        Complete the Purchase Order import file.
                        <br />
                        View Instructions
                        <HelpIcon
                            placement="right"
                            tooltip={
                                <p>
                                    <strong>Instructions:</strong>
                                    <br />
                                    Use the &quot;Vendor ID&quot; or &quot;Contract ID&quot; columns
                                    to match purchase orders to vendors with contracts. At least one
                                    of these columns must be used for each row so we can properly
                                    match the Purchase Order to the contract and vendor.
                                    <br />
                                    <br />
                                    Entries that have an existing PO# will update those existing
                                    records with the new information from the uploaded file. Only
                                    include existing records that you want to have updated.
                                    <br />
                                    <br />
                                    The &quot;Order Amount&quot; column is required. &quot;Fiscal
                                    Year&quot; and &quot;Department&quot; names must match exactly
                                    with what is entered in your admin settings in order for them to
                                    be correctly matched.
                                    <br />
                                    <br />
                                    <strong>Important:</strong>
                                    <br />
                                    Please do not change the names of the column headers! Any
                                    columns with unrecognized headers will be ignored.
                                </p>
                            }
                        />
                    </p>
                    <div className={this.styles.steps}>
                        <strong>Step 3:</strong>
                        <br />
                        <StyledDropzone
                            disabled={loading}
                            icons={['file-o', 'file-excel-o', 'table']}
                            label="Purchase Order File"
                            multiple={false}
                            onDropAccepted={(files) => {
                                this.setState({
                                    loading: true,
                                    uploadFileError: undefined,
                                });
                                let purchaseOrdersData;

                                return parsePurchaseOrdersFile(
                                    files[0],
                                    fiscalYearTags,
                                    departments,
                                    user
                                )
                                    .then((importedSpreadsheetData) => {
                                        purchaseOrdersData = importedSpreadsheetData;
                                        return loadPurchaseOrdersImportData();
                                    })
                                    .then((contracts) => {
                                        this.setState({
                                            loading: false,
                                        });
                                        const data = groupedPurchaseOrders(
                                            contracts,
                                            purchaseOrdersData
                                        );
                                        return onUploadComplete(data);
                                    })
                                    .catch((error) => {
                                        this.setState({
                                            loading: false,
                                            uploadFileError: error,
                                        });
                                    });
                            }}
                            text={
                                <>
                                    Drop or click to upload your correctly-formatted *.csv, *.xls,
                                    *.xlsx, or *.ods Purchase Order import file
                                </>
                            }
                        />
                    </div>
                    {loading && (
                        <p className={this.styles.loadingText}>
                            <i className="fa fa-spin fa-spinner" /> Importing from spreadsheet...
                        </p>
                    )}
                    {uploadFileError && (
                        <p className="error-block">
                            <strong>File Upload Error:</strong>
                            <br />
                            {uploadFileError.message}
                        </p>
                    )}
                </div>
            </div>
        );
    }
}

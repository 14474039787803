import PropTypes from 'prop-types';
import React from 'react';

import { StepApprovalGroup } from '../SequenceStep/StepApprovalGroup';

export const SubmittedStepInfo = ({ requisition }) => {
    const { creator, requestor } = requisition;

    return (
        <>
            <StepApprovalGroup approvers={[creator]} name="Created By" />
            {creator.id !== requestor.id && (
                <StepApprovalGroup approvers={[requestor]} name="On Behalf Of" />
            )}
        </>
    );
};

SubmittedStepInfo.propTypes = {
    requisition: PropTypes.shape({
        creator: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }).isRequired,
        requestor: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }).isRequired,
    }).isRequired,
};

import { tokens } from '@opengov/capital-style';

// Actual height of the fixed toolbar. Useful when the exact value is needed
export const FIXED_TOOLBAR_HEIGHT = 40;

// Height of the fixed toolbar with an additional 10px of padding.
// Useful for applying to a sticky element so it has a little breathing room below the toolbar.
export const FIXED_TOOLBAR_ADJUSTMENT_HEIGHT = FIXED_TOOLBAR_HEIGHT + 10;

export const getDndStyle = (snapshot) => {
    return {
        backgroundColor: snapshot.isDraggingOver ? '#e6ebff' : undefined,
    };
};

export const getOpenGovDndStyle = (snapshot) => {
    return {
        backgroundColor: snapshot.isDraggingOver ? tokens.colors.colorPrimaryHoverLight : undefined,
    };
};

import React from 'react';
import { Box, Typography } from '@og-pro/ui';

export const VerifiedCertificationsDisclaimer = () => {
    const styles = require('./index.scss');
    return (
        <Box
            alignItems="flex-start"
            className={styles.verifiedCertificationsDisclaimer}
            display="flex"
            p={2}
        >
            <span className={`fa-stack fa-lg ${styles.verified}`}>
                <i className="fa fa-stack-2x fa-certificate" />
                <i className="fa fa-stack-1x fa-inverse fa-check" />
            </span>
            <Box display="flex" flexDirection="column">
                <Typography className={styles.title} variant="h4">
                    Verified Certifications May Appear
                </Typography>
                <div className={styles.body}>
                    If your company’s EIN is found on one of the state or federal lists that OpenGov
                    checks, that certification will be listed automatically. Based on the agency or
                    list, this may take some time after you’ve certified.
                </div>
            </Box>
        </Box>
    );
};

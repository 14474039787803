import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';

import { ProposalScoreExpanded } from './ProposalScoreExpanded';

export class ProposalScoresExpanded extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        evaluators: PropTypes.array.isRequired,
        scoringData: PropTypes.arrayOf(
            PropTypes.shape({
                proposalScores: PropTypes.array.isRequired,
                scoringCriteria: PropTypes.object.isRequired,
            })
        ).isRequired,
        showAnonymizedEvaluators: PropTypes.bool,
        showComments: PropTypes.bool,
        showCriteriaDescriptions: PropTypes.bool,
        totalWeight: PropTypes.number.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const {
            className,
            evaluators,
            scoringData,
            showAnonymizedEvaluators,
            showComments,
            showCriteriaDescriptions,
            totalWeight,
        } = this.props;

        const rows = scoringData.map(({ proposalScores, scoringCriteria }) => (
            <ListGroupItem
                className={classnames(this.styles.listItem, className)}
                key={scoringCriteria.id}
            >
                <ProposalScoreExpanded
                    evaluators={evaluators}
                    proposalScores={proposalScores}
                    scoringCriteria={scoringCriteria}
                    showAnonymizedEvaluators={showAnonymizedEvaluators}
                    showComments={showComments}
                    showCriteriaDescriptions={showCriteriaDescriptions}
                    totalWeight={totalWeight}
                />
            </ListGroupItem>
        ));

        return <ListGroup>{rows}</ListGroup>;
    }
}

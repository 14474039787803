import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { EvaluationPhase } from '..';

export class EvaluationPhases extends PureComponent {
    static propTypes = {
        change: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        fields: PropTypes.object.isRequired,
        isMulti: PropTypes.bool,
        isOGThemeEnabledForComponents: PropTypes.bool,
        showValidation: PropTypes.bool,
    };

    render() {
        const { change, disabled, fields, isMulti, isOGThemeEnabledForComponents, showValidation } =
            this.props;

        const EvaluationPhaseComponents = fields.map((member, index) => {
            const evaluationPhaseData = fields.get(index);
            return (
                <EvaluationPhase
                    {...evaluationPhaseData}
                    arrayName={member}
                    change={change}
                    deleteHandler={isMulti ? () => fields.remove(index) : undefined}
                    disabled={disabled}
                    isMulti={isMulti}
                    isOGThemeEnabledForComponents={isOGThemeEnabledForComponents}
                    key={index}
                    showValidation={showValidation}
                />
            );
        });

        return <div>{EvaluationPhaseComponents}</div>;
    }
}

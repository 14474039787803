import { createSelector } from 'reselect';

import { getContractJS } from '../../selectors';

export const getContractReviewJS = createSelector(
    [getContractJS, (state, props) => props.contractReviewId],
    (contract, contractReviewId) => {
        if (contract) {
            return contract.contractReviews.find(
                (contractReview) => contractReview.id === contractReviewId
            );
        }
    }
);

import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { TemplateQuestionCreateModal } from './TemplateQuestionCreateModal';
import { Button } from '../../../../components';

export const TemplateQuestionCreateButton = ({ bsSize }) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <Button
                bsSize={bsSize}
                bsStyle="primary"
                onClick={() => setShowModal(true)}
                qaTag="sharedQuestion-create"
            >
                <i className="fa fa-plus" /> New Shared Question
            </Button>
            {showModal && <TemplateQuestionCreateModal hideModal={() => setShowModal(false)} />}
        </>
    );
};

TemplateQuestionCreateButton.propTypes = {
    bsSize: PropTypes.string,
};

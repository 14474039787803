const { listToDict } = require('../helpers');

exports.filterTypes = [
    'docBuilder',
    'project',
    'intake',
    'contract',
    'contractTableLayoutSettings',
];

exports.filterTypesDict = listToDict(exports.filterTypes);

// Settings can only have one value
exports.settingsFilterTypes = [exports.filterTypesDict.CONTRACT_TABLE_LAYOUT_SETTINGS];

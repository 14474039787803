import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { CategorySelectModal } from './CategorySelectModal';

/**
 * Utility component used for dropping in a link that will open the category
 * select modal
 */

export class CategoryLinkInput extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        disallowCustomCategories: PropTypes.bool,
        input: PropTypes.object.isRequired,
        isRequired: PropTypes.bool,
        targetComponent: PropTypes.node.isRequired,
        useSingleCodeSet: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.state = { showModal: false };
    }

    componentWillUnmount() {
        this.hideModal();
    }

    // Necessary so a new array isn't generated each time causing a re-render
    defaultCategories = [];

    showModal = () => {
        if (this.props.disabled) return null;
        this.setState({ showModal: true });
    };

    hideModal = () => {
        this.setState({ showModal: false });
    };

    selectHandler = (categories, hasChanged) => {
        if (hasChanged) {
            this.props.input.onChange(categories);
            this.props.input.onBlur(categories); // blur touches the field
        }
        return this.hideModal();
    };

    render() {
        const {
            disallowCustomCategories,
            input: { value },
            isRequired,
            targetComponent,
            useSingleCodeSet,
        } = this.props;

        const categories = value || this.defaultCategories;
        return (
            <>
                <span onClick={this.showModal}>{targetComponent}</span>
                <CategorySelectModal
                    categories={categories}
                    disallowCustomCategories={disallowCustomCategories}
                    hideModal={this.hideModal}
                    isRequired={isRequired}
                    selectHandler={this.selectHandler}
                    showModal={this.state.showModal}
                    useSingleCodeSet={useSingleCodeSet}
                />
            </>
        );
    }
}

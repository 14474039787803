const { listToDict } = require('../helpers');

exports.vendorUploadTypes = ['w9', 'certificateOfInsurance', 'other'];

exports.vendorUploadTypesDict = listToDict(exports.vendorUploadTypes);

exports.vendorUploadsList = [
    {
        title: 'W-9 Form',
        type: exports.vendorUploadTypesDict.W_9,
    },
    {
        title: 'Certificate of Insurance',
        type: exports.vendorUploadTypesDict.CERTIFICATE_OF_INSURANCE,
    },
    {
        title: 'Other Documents',
        type: exports.vendorUploadTypesDict.OTHER,
    },
];

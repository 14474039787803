import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Panel } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { isInvalid, submit } from 'redux-form';
import { createSearchParams } from 'react-router-dom';

import { USE_OG_PLATFORM } from '@og-pro/shared-config/platform';

import { pageTitles } from './constants';
import { PersonalName, PersonalAddress, PersonalContact, SecurityInvited } from '../Account';
import { form as addressForm, fields as addressFields } from '../Account/PersonalAddress/constants';
import { form as contactForm, fields as contactFields } from '../Account/PersonalContact/constants';
import { form as nameForm, fields as nameFields } from '../Account/PersonalName/constants';
import { form as securityInvitedForm } from '../Account/SecurityInvited/constants';
import { getUserJS } from '../selectors';
import * as activateActions from '../../actions/account/activate';
import { save as savePersonal } from '../../actions/account/personal';
import { save as saveSecurity } from '../../actions/account/securityInvited';
import { Button, Main, PageTitle, ProgressBar } from '../../components';

const mapStateToProps = (state) => {
    return {
        activating: state.accountActivate.get('activating'),
        isAddressFormInvalid: isInvalid(addressForm)(state),
        isContactFormInvalid: isInvalid(contactForm)(state),
        isNameFormInvalid: isInvalid(nameForm)(state),
        isSecurityFormInvalid: isInvalid(securityInvitedForm)(state),
        personalError: state.accountPersonal.get('error'),
        personalSaving: state.accountPersonal.get('saving'),
        securityError: state.accountSecurityInvited.get('error'),
        securitySaving: state.accountSecurityInvited.get('saving'),
        user: getUserJS(state),
    };
};

const mapDispatchToProps = {
    ...activateActions,
    personalSave: savePersonal,
    securitySave: saveSecurity,
    submitForm: submit,
};

const LAST_PAGE = pageTitles.length;

// @connect
export class ConnectedActivateAccount extends Component {
    static propTypes = {
        activate: PropTypes.func.isRequired,
        activating: PropTypes.bool,
        isAddressFormInvalid: PropTypes.bool.isRequired,
        isContactFormInvalid: PropTypes.bool.isRequired,
        isNameFormInvalid: PropTypes.bool.isRequired,
        isSecurityFormInvalid: PropTypes.bool.isRequired,
        location: PropTypes.object.isRequired,
        router: PropTypes.object.isRequired,
        personalError: PropTypes.string,
        personalSave: PropTypes.func.isRequired,
        personalSaving: PropTypes.bool,
        securityError: PropTypes.string,
        securitySave: PropTypes.func.isRequired,
        securitySaving: PropTypes.bool,
        submitForm: PropTypes.func.isRequired,
        user: PropTypes.object,
    };

    get styles() {
        return require('./ActivateAccount.scss');
    }

    constructor(props) {
        super(props);
        this.state = {
            showErrorFields: false,
        };
    }

    get currentPage() {
        const { page } = this.props.location.query;
        return Number.isNaN(Number.parseFloat(page)) ? 0 : +page;
    }

    get saving() {
        return this.props.securitySaving || this.props.personalSaving;
    }

    get formName() {
        switch (this.currentPage) {
            case 1:
                return nameForm;
            case 2:
                return addressForm;
            case 3:
                return contactForm;
            case 4: {
                if (USE_OG_PLATFORM) {
                    return null;
                }
                return securityInvitedForm;
            }
            default:
                return null;
        }
    }

    get invalid() {
        const {
            isAddressFormInvalid,
            isContactFormInvalid,
            isNameFormInvalid,
            isSecurityFormInvalid,
        } = this.props;

        switch (this.currentPage) {
            case 1:
                return isNameFormInvalid;
            case 2:
                return isAddressFormInvalid;
            case 3:
                return isContactFormInvalid;
            case 4: {
                if (USE_OG_PLATFORM) {
                    return false;
                }
                return isSecurityFormInvalid;
            }
            default:
                return false;
        }
    }

    handleGoBackClick = () => {
        const { activating } = this.props;

        if (!activating) {
            this.decrementPage();
        }
    };

    handleKeyDown = (e) => {
        const disabled = this.invalid || this.saving;

        if (e.keyCode === 13 && !disabled) {
            this.triggerSubmit();
        }
    };

    handleSubmit = (data) => {
        const { personalSave, securitySave } = this.props;
        switch (this.currentPage) {
            case 1:
            case 2:
            case 3:
                return personalSave(data, false).then(() => {
                    if (!this.props.personalError) {
                        this.incrementPage();
                    }
                });
            case 4: {
                if (USE_OG_PLATFORM) {
                    return null;
                }
                return securitySave(data, false).then(() => {
                    if (!this.props.securityError) {
                        this.incrementPage();
                    }
                });
            }
            default:
                return null;
        }
    };

    triggerSubmit = () => {
        const formName = this.formName;
        if (formName) {
            return this.props.submitForm(formName);
        }
    };

    updatePage = (pageChange) => {
        const {
            location: { pathname, query },
            router,
        } = this.props;
        const newPage = this.currentPage + pageChange;
        return router.push({
            pathname,
            search: createSearchParams({ ...query, page: newPage }).toString(),
        });
    };

    incrementPage = () => {
        return this.updatePage(1);
    };

    decrementPage = () => {
        return this.updatePage(-1);
    };

    handleOnErrorClicked = () => {
        this.setState({ showErrorFields: false });
        setTimeout(() => {
            this.setState({ showErrorFields: true });
        }, 250);
    };

    componentDidUpdate(prevProps) {
        if (
            prevProps.isAddressFormInvalid !== this.props.isAddressFormInvalid ||
            prevProps.isContactFormInvalid !== this.props.isContactFormInvalid ||
            prevProps.isNameFormInvalid !== this.props.isNameFormInvalid ||
            prevProps.isSecurityFormInvalid !== this.props.isSecurityFormInvalid
        ) {
            this.setState({ showErrorFields: false });
        }
    }

    renderLogo() {
        if (this.currentPage !== 0 && this.currentPage !== LAST_PAGE) {
            return null;
        }

        return (
            <div className="text-center">
                <img
                    alt="OpenGov Procurement logo - blue"
                    aria-hidden
                    className={this.styles.logo}
                    src="https://assets.procurement.opengov.com/assets/opengov/opengov_pro_logo_blue.svg"
                />
            </div>
        );
    }

    renderProgressBar() {
        const currentPage = this.currentPage;
        const isCurrentPageValid = !this.invalid;

        const numeratorDiff = isCurrentPageValid ? 1 : 0;
        const percentComplete = ((currentPage - 1 + numeratorDiff) / (LAST_PAGE - 1)) * 100;

        const isComplete = percentComplete === 100;
        const style = isComplete ? 'success' : undefined;
        const label = isComplete ? (
            <span>
                <i className="fa fa-check" /> Complete!
            </span>
        ) : undefined;

        if (currentPage > 0 && currentPage < LAST_PAGE) {
            return (
                <div className="col-xs-12">
                    <ProgressBar
                        ariaLabel="Account Activation Progress Bar"
                        bsStyle={style}
                        label={label}
                        now={percentComplete || 1}
                        striped={!isComplete}
                    />
                </div>
            );
        }
    }

    renderContent() {
        const { user } = this.props;

        switch (this.currentPage) {
            case 0:
                return this.renderWelcomePage();
            case 1:
                return (
                    <div>
                        <PersonalName
                            autoFocus
                            initialValues={pick(user, nameFields)}
                            onSubmit={this.handleSubmit}
                            parentSubmit
                            showErrorFields={this.state.showErrorFields}
                        />
                    </div>
                );
            case 2:
                return (
                    <div>
                        <PersonalAddress
                            autoFocus
                            initialValues={pick(user, addressFields)}
                            onSubmit={this.handleSubmit}
                            parentSubmit
                            showErrorFields={this.state.showErrorFields}
                        />
                    </div>
                );
            case 3:
                return (
                    <div>
                        <PersonalContact
                            autoFocus
                            initialValues={pick(user, contactFields)}
                            onSubmit={this.handleSubmit}
                            parentSubmit
                            showErrorFields={this.state.showErrorFields}
                        />
                    </div>
                );
            case 4: {
                if (USE_OG_PLATFORM) {
                    return this.renderConfirmationPage();
                }
                return (
                    <div>
                        <SecurityInvited
                            onSubmit={this.handleSubmit}
                            parentSubmit
                            showErrorFields={this.state.showErrorFields}
                        />
                    </div>
                );
            }
            case 5:
                return this.renderConfirmationPage();
            default:
                return this.renderWelcomePage();
        }
    }

    renderConfirmationPage() {
        const { activate, activating } = this.props;

        const icon = activating ? 'fa-spinner fa-spin' : 'fa-user-plus';

        return (
            <div>
                <h2 className={`text-procurenow ${this.styles.topHeader}`}>Account Activation</h2>
                <h4 className={this.styles.subText}>Thank you for completing registration!</h4>
                <h4 className={this.styles.subHeader}>
                    Please click the button below to activate your account.
                </h4>
                <div className="row text-center">
                    <div className="col-xs-12">
                        <Button
                            bsSize="large"
                            bsStyle="success"
                            className={this.styles.activateButton}
                            disabled={activating}
                            onClick={activate}
                        >
                            <i className={`fa fa-fw ${icon}`} />{' '}
                            {activating ? 'Activating' : 'Activate'}
                        </Button>
                    </div>
                    <div className="col-xs-12">
                        <span className="pseudoLink" onClick={this.handleGoBackClick}>
                            <i className="fa fa-arrow-left" /> Go Back
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    renderWelcomePage() {
        const { user } = this.props;

        if (!user) return null;

        const welcomeText = "Let's get started on creating your user account.";

        return (
            <div>
                <h1 className={`text-procurenow ${this.styles.topHeader}`}>
                    Welcome to OpenGov Procurement!
                </h1>
                <div className={this.styles.subHeader}>We are excited to have you join!</div>
                <div className={this.styles.subText}>{welcomeText}</div>
                <div>
                    <Button
                        bsSize="large"
                        bsStyle="success"
                        className={`center-block ${this.styles.startButton}`}
                        onClick={this.incrementPage}
                    >
                        Start <i aria-hidden className="fa fa-angle-double-right" />
                    </Button>
                </div>
            </div>
        );
    }

    renderHeader() {
        const pageTitle = pageTitles[this.currentPage];

        if (pageTitle) {
            return <h1 className={this.styles.pageTitle}>{pageTitle}</h1>;
        }
    }

    renderButtons() {
        const nextContent = (
            <span>
                Next <i aria-hidden className="fa fa-angle-right" />
            </span>
        );

        if (pageTitles[this.currentPage]) {
            return (
                <div className="row">
                    <div className="col-xs-6 text-right">
                        <Button
                            aria-label="Previous"
                            bsSize="large"
                            disabled={this.saving}
                            onClick={this.decrementPage}
                        >
                            <i aria-hidden className="fa fa-angle-left" /> Previous
                        </Button>
                    </div>
                    <div className="col-xs-6">
                        <Button
                            aria-label="Next"
                            bsSize="large"
                            bsStyle="primary"
                            disabled={this.saving}
                            onClick={this.invalid ? this.handleOnErrorClicked : this.triggerSubmit}
                            type="submit"
                        >
                            {this.saving ? 'Saving...' : nextContent}
                        </Button>
                    </div>
                </div>
            );
        }
    }

    render() {
        /**
         * There is an issue with token log in on the server. Consequently the login does not
         * happen until the client is loaded, so the user will not be present on initial render
         */
        if (!this.props.user) return null;

        return (
            <Main className="row">
                <PageTitle title="Activate Account" />
                <div className="col-xs-12">{this.renderLogo()}</div>
                <Panel
                    className={`col-sm-offset-1 col-sm-10 col-md-offset-2 col-md-8 ${this.styles.panel}`}
                >
                    <Panel.Body>
                        <div className="row">
                            <div className="col-xs-12 col-md-offset-1 col-md-10 col-lg-offset-2 col-lg-8">
                                <div className="row">{this.renderHeader()}</div>
                                <div className="row">{this.renderProgressBar()}</div>
                                <div className="row" onKeyDown={this.handleKeyDown}>
                                    {this.renderContent()}
                                </div>
                                <div className="row">{this.renderButtons()}</div>
                            </div>
                        </div>
                    </Panel.Body>
                </Panel>
            </Main>
        );
    }
}

export const ActivateAccount = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedActivateAccount);

import moment from 'moment-timezone/builds/moment-timezone-with-data-1970-2030';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { AttachmentTable } from './AttachmentTable';
import { HtmlContent, Main, SectionTitle } from '../../../../../components';
import { Timeline } from '../../../../../components/GovApp/ProjectDetail/Introduction';
import { currencyFormatter } from '../../../../../helpers';

export class ProjectOverviewReview extends PureComponent {
    static propTypes = {
        project: PropTypes.object.isRequired,
    };

    get styles() {
        return require('./ProjectOverviewReview.scss');
    }

    renderTitle(title) {
        return <h4 className={this.styles.sectionTitle}>{title}</h4>;
    }

    renderDate(date) {
        const { timezone } = this.props.project.government.organization;
        return moment(date).tz(timezone).format('ll');
    }

    render() {
        const { project } = this.props;
        const { timezone } = project.government.organization;
        return (
            <Main className={this.styles.container}>
                <SectionTitle
                    info="Important information and dates for the project"
                    title="Project Overview"
                />
                {this.renderTitle('Timeline')}
                <Timeline
                    className={`introduction-section ${this.styles.body}`}
                    project={project}
                    timezone={timezone}
                />
                {this.renderTitle('Project Summary')}
                <div className={`article ${this.styles.body}`}>
                    <HtmlContent content={project.summary} />
                </div>
                {this.renderTitle('Contact Information')}
                <div className={this.styles.body}>
                    <dl className="dl-horizontal">
                        <dt>Project Contact:</dt>
                        <dd>{project.contactFullName}</dd>
                        <dt>Department:</dt>
                        <dd>{project.departmentName}</dd>
                    </dl>
                </div>
                {project.government.isBudgetUsed && (
                    <>
                        {this.renderTitle('Budget Information')}
                        <div className={this.styles.body}>
                            <dl className="dl-horizontal">
                                <dt>Amount:</dt>
                                <dd>
                                    {project.budget.amount || project.budget.amount === 0
                                        ? currencyFormatter({ value: project.budget.amount })
                                        : 'TBD'}
                                </dd>
                                <dt>Description:</dt>
                                <dd>{project.budget.description || 'TBD'}</dd>
                                <dt>Account ID:</dt>
                                <dd>{project.budget.identifier || 'TBD'}</dd>
                            </dl>
                        </div>
                    </>
                )}
                {this.renderTitle('Attachments')}
                <AttachmentTable attachments={project.attachments} />
            </Main>
        );
    }
}

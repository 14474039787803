import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Panel } from 'react-bootstrap';

export class IntakeStatus extends PureComponent {
    static propTypes = {
        bsStyle: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        info: PropTypes.node.isRequired,
        title: PropTypes.string.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const { bsStyle, icon, info, title } = this.props;

        return (
            <Panel>
                <Panel.Body className="text-center">
                    <i className={`fa fa-fw fa-5x fa-${icon} text-${bsStyle}`} />
                    <p className={`text-${bsStyle} ${this.styles.statusTitle}`}>{title}</p>
                    {info}
                </Panel.Body>
            </Panel>
        );
    }
}

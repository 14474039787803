import { isEmail } from 'validator';

import { fieldNames } from './constants';

const { EMAILS } = fieldNames;

export const validate = (values) => {
    const errors = {};

    if (!values[EMAILS]) {
        errors[EMAILS] = 'Please provide at least one email address';
    } else {
        const invalidEmail = values[EMAILS].split(',').find((email) => !isEmail(email.trim()));
        if (invalidEmail) {
            errors[EMAILS] = `Invalid email address provided: "${invalidEmail}"`;
        }
    }

    return errors;
};

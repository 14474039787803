import { get } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Button, StandardDocumentModalButton, UserProfilePicture } from '../../..';

export class EvaluatorsListItem extends PureComponent {
    static propTypes = {
        agreement: PropTypes.shape({
            accepted: PropTypes.bool,
            acceptedAt: PropTypes.string,
        }),
        disabled: PropTypes.bool,
        remove: PropTypes.func,
        standardDocument: PropTypes.object,
        user: PropTypes.shape({
            displayName: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
        }).isRequired,
    };

    get styles() {
        return require('./EvaluatorsListItem.scss');
    }

    renderDeleteButton() {
        const { disabled, remove } = this.props;

        return (
            <div className="pull-right">
                <Button
                    aria-label="Remove Button"
                    bsStyle="link"
                    className={this.styles.removeBtn}
                    disabled={disabled}
                    onClick={remove}
                    qaTag="evaluator-delete"
                >
                    <i className="fa fa-times fa-2x text-danger" />
                </Button>
            </div>
        );
    }

    renderAcceptance() {
        const { agreement, standardDocument } = this.props;

        const accepted = get(agreement, 'accepted');
        const acceptedAt = get(agreement, 'acceptedAt');

        const bsStyle = accepted ? 'text-primary' : 'text-warning';
        const icon = accepted ? (
            <span className={`fa-stack ${this.styles.verified}`}>
                <i className="fa fa-stack-2x fa-certificate" />
                <i className="fa fa-stack-1x fa-inverse fa-check" />
            </span>
        ) : (
            <i className="fa fa-lg fa-clock-o" />
        );
        const text = accepted ? 'Accepted' : 'Pending';

        return (
            <StandardDocumentModalButton
                className={this.styles.acceptanceContainer}
                standardDocument={standardDocument}
            >
                <div className={`${bsStyle} ${this.styles.acceptedText}`}>
                    {icon} Evaluator Agreement {text}
                </div>
                {accepted && acceptedAt && (
                    <div className="text-muted">
                        <em>{moment(acceptedAt).format('lll')}</em>
                    </div>
                )}
            </StandardDocumentModalButton>
        );
    }

    render() {
        const { remove, standardDocument, user } = this.props;

        return (
            <div className={this.styles.userRow}>
                <UserProfilePicture className={this.styles.profilePicture} user={user} />
                <div className={this.styles.userInfo}>
                    <span className={this.styles.userName}>{user.displayName}</span>
                    <div>
                        <em className="text-muted">{user.title}</em>
                    </div>
                    {standardDocument && this.renderAcceptance()}
                </div>
                {remove && this.renderDeleteButton()}
            </div>
        );
    }
}

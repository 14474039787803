import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';

import { form } from './constants';

const checklistFormValues = (state) => getFormValues(form)(state);

export const getChecklistFormValues = createSelector([checklistFormValues], (formValues) => {
    if (formValues) {
        return formValues;
    }
    return {};
});

import { AssignmentLateOutlined as AssignmentLateOutlinedIcon } from '@mui/icons-material';
import { Alert, AlertTitle, Typography } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React from 'react';

export const ExceptionBanner = ({ exceptionNote, exceptionReviewSequence: { name } }) => {
    return (
        <Alert
            icon={<AssignmentLateOutlinedIcon fontSize="inherit" />}
            severity="warning"
            variant="filled"
        >
            <AlertTitle>{`Exception: ${name}`}</AlertTitle>
            <Typography>{exceptionNote}</Typography>
        </Alert>
    );
};

ExceptionBanner.propTypes = {
    exceptionNote: PropTypes.string.isRequired,
    exceptionReviewSequence: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }).isRequired,
};

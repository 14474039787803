const SF_CHAT_CONFIG = {
    PROD: {
        ID: '00Dd0000000dzzT',
        SCRT2_URL: 'https://opengov.my.salesforce-scrt.com/',
        AGENCY_NAME: 'OpenGov_Service_Messaging_Procurement',
        AGENCY_URL: 'https://opengov.my.site.com/ESWOpenGovServiceMessag1694717953752',
        AGENCY_BOOTSTRAP_URL:
            'https://opengov.my.site.com/ESWOpenGovServiceMessag1694717953752/assets/js/bootstrap.min.js',
        VENDOR_NAME: 'OpenGov_Service_Messaging_Procurement_Vendors',
        VENDOR_URL: 'https://opengov.my.site.com/ESWOpenGovServiceMessag1699543166964',
        VENDOR_BOOTSTRAP_URL:
            'https://opengov.my.site.com/ESWOpenGovServiceMessag1699543166964/assets/js/bootstrap.min.js',
    },
    INT: {
        ID: '00Dcb0000004Q0j',
        SCRT2_URL: 'https://opengov--full.sandbox.my.salesforce-scrt.com',
        AGENCY_NAME: 'OpenGov_Service_Messaging_Procurement',
        AGENCY_URL:
            'https://opengov--full.sandbox.my.site.com/ESWOpenGovServiceMessag1694717953752',
        AGENCY_BOOTSTRAP_URL:
            'https://opengov--full.sandbox.my.site.com/ESWOpenGovServiceMessag1694717953752/assets/js/bootstrap.min.js',
        VENDOR_NAME: 'OpenGov_Service_Messaging_Procurement_Vendors',
        VENDOR_URL:
            'https://opengov--full.sandbox.my.site.com/ESWOpenGovServiceMessag1699543166964',
        VENDOR_BOOTSTRAP_URL:
            'https://opengov--full.sandbox.my.site.com/ESWOpenGovServiceMessag1699543166964/assets/js/bootstrap.min.js',
    },
};

export const getSFChatConfig = () => SF_CHAT_CONFIG[__PROD__ ? 'PROD' : 'INT'];

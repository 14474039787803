import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@og-pro/ui';
import { Field, reduxForm } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Close as CloseIcon } from '@mui/icons-material';

import { loadVendorLists, loadVendorList } from '../../../../actions/vendorList';
import {
    getVendorListsLoading,
    getVendorLists,
    getVendorList,
    getVendorListLoading,
} from '../../selectors';
import { CDSButton, SearchSelect } from '../../../../components';
import { CustomGridLayout } from './CustomGridLayout';

const ConnectedSearchByVendorListRow = ({
    disabled,
    notifiedUsers,
    onRemove,
    onSelectedValueChange,
    selectedValue,
    setTotal,
}) => {
    const { governmentId } = useParams();
    const dispatch = useDispatch();
    const [{ organizations, users }, setResults] = useState({ organizations: 0, users: 0 });

    const vendorLists = useSelector(getVendorLists);
    const loading = useSelector(
        (state) => getVendorListsLoading(state) || getVendorListLoading(state)
    );
    const vendorList = useSelector((state) => getVendorList(state, selectedValue[0]));

    const styles = require('../index.scss');

    useEffect(() => {
        dispatch(loadVendorLists(governmentId));
    }, []);

    useEffect(() => {
        if (selectedValue.length) {
            dispatch(loadVendorList(governmentId, selectedValue[0]));
        }
    }, [selectedValue]);

    useEffect(() => {
        if (selectedValue.length && vendorList?.id === selectedValue[0]) {
            const organizationsSet = vendorList.listedUsers.reduce((acc, cur) => {
                if (
                    cur.organization &&
                    !acc.has(cur.organization?.id) &&
                    !notifiedUsers.organizationIds.has(cur.organization?.id)
                ) {
                    acc.add(cur.organization?.id);
                }

                return acc;
            }, new Set());

            vendorList.listedPendingUsers.forEach((user) => {
                if (
                    user.organization &&
                    !organizationsSet.has(user.organization.id) &&
                    !notifiedUsers.organizationIds.has(user.organization.id)
                ) {
                    organizationsSet.add(user.organization.id);
                }
            });

            let usersTotal = 0;
            vendorList.listedUsers.forEach((user) => {
                if (!notifiedUsers.ids.has(user.id)) {
                    usersTotal++;
                }
            });
            vendorList.listedPendingUsers.forEach((user) => {
                if (!notifiedUsers.ids.has(user.id)) {
                    usersTotal++;
                }
            });

            setTotal(organizationsSet.size || 0);
            setResults({ organizations: organizationsSet.size, users: usersTotal });
        } else {
            setTotal(0);
            setResults({ organizations: 0, users: 0 });
        }
    }, [vendorList?.id, selectedValue]);

    return (
        <CustomGridLayout
            columns={[
                <Field
                    component={SearchSelect}
                    disabled={disabled}
                    isClearable
                    label="Select a Vendor List"
                    name="vendorListId"
                    onChange={(e, v) => onSelectedValueChange(v ? [v] : null)}
                    options={vendorLists.map((item) => {
                        return {
                            label: item.name,
                            value: item.id,
                        };
                    })}
                    placeholder="Select the vendor list to invite"
                    useOpenGovStyle
                />,
                <Box className={styles.tableCount}>
                    {loading ? <CircularProgress size="small" /> : users}
                </Box>,
                <Box className={styles.tableCount}>
                    {loading ? <CircularProgress size="small" /> : organizations}
                </Box>,
                <Box textAlign="right">
                    <Box
                        aria-label="Remove search option"
                        className={styles.errorDark}
                        component={CDSButton}
                        disabled={disabled}
                        noPadding
                        onClick={onRemove}
                        qaTag="supplierNetworkModal-removeSearchOption"
                        variant="text"
                    >
                        <CloseIcon fontSize="small" />
                    </Box>
                </Box>,
            ]}
        />
    );
};

ConnectedSearchByVendorListRow.propTypes = {
    disabled: PropTypes.bool,
    notifiedUsers: PropTypes.shape({ ids: PropTypes.object, organizationIds: PropTypes.object }), // javascript Set
    onRemove: PropTypes.func.isRequired,
    onSelectedValueChange: PropTypes.func.isRequired,
    selectedValue: PropTypes.arrayOf(PropTypes.number),
    setTotal: PropTypes.func.isRequired,
};

export const SearchByVendorListRow = reduxForm({
    form: 'searchByVendorListForm',
})(ConnectedSearchByVendorListRow);

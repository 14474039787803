import { omit } from 'lodash';
import { createSelector } from 'reselect';

import { numberItems } from '@og-pro/shared-config/helpers';

import {
    DASHBOARD_VIEW,
    RESPONSES_VIEW,
    VIEW,
    VIEW_OPTIONS,
} from './ChecklistViewSelectForm/constants';

const getRawVendorUsers = (state) => state.checklists.get('vendorUsers');

export const getVendorUsersSelectOptions = createSelector([getRawVendorUsers], (rawUsers) => {
    return rawUsers.toJS().map((user) => {
        return {
            label: user.fullName || `${user.email} (not activated)`,
            user,
            value: user.id,
        };
    });
});

export const getFormattedQuestionnaires = createSelector(
    [(questionnaires) => questionnaires],
    (rawQuestionnaires) => {
        return numberItems(rawQuestionnaires).map((questionnaire) => {
            return {
                ...omit(questionnaire, 'questionnaireResponses'),
                questionnaireResponse: questionnaire.questionnaireResponses[0],
            };
        });
    }
);

export const getSelectedView = createSelector(
    [(props) => props.query[VIEW], (props) => props.readOnly],
    (viewQueryParam, readOnly) => {
        const viewParam = viewQueryParam && viewQueryParam.toLowerCase();
        const defaultView = readOnly ? RESPONSES_VIEW : DASHBOARD_VIEW;
        return VIEW_OPTIONS.includes(viewParam) ? viewParam : defaultView;
    }
);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';

export class DraftReviewToggle extends PureComponent {
    static propTypes = {
        checked: PropTypes.bool,
        displayOnly: PropTypes.bool,
        onChange: PropTypes.func,
    };

    get styles() {
        return require('./DraftReviewToggle.scss');
    }

    renderEditIcon() {
        return <i className={`fa fa-pencil fa-inverse ${this.styles.editIcon}`} />;
    }

    renderReviewIcon() {
        return <i className={`fa fa-file-text fa-inverse ${this.styles.reviewIcon}`} />;
    }

    renderDisplayOnly() {
        return (
            <Toggle
                className={this.styles.projectToggle}
                icons={{
                    checked: this.renderEditIcon(),
                    unchecked: this.renderReviewIcon(),
                }}
                id="DraftReviewToggle"
            />
        );
    }

    render() {
        // In some cases we just want to display the component
        if (this.props.displayOnly) return this.renderDisplayOnly();

        return (
            <Toggle
                checked={this.props.checked}
                className={this.styles.projectToggle}
                icons={{
                    checked: this.renderEditIcon(),
                    unchecked: this.renderReviewIcon(),
                }}
                id="DraftReviewToggle"
                onChange={this.props.onChange}
            />
        );
    }
}

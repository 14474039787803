import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { vendorUploadTypesDict } from '@og-pro/shared-config/vendorUploads';

import { VendorUploader } from './components';
import { getVendorAttachmentsJS } from './selectors';
import connectData from '../../ConnectData';
import { getUserJS } from '../../selectors';
import {
    createVendorAttachment,
    deleteVendorAttachment,
    loadVendorAttachments,
} from '../../../actions/adminVendor';
import { LoadingSpinner, LoadingError, SectionTitle, Main } from '../../../components';
import { requiredVendorDatumFieldNames } from '../../../constants/requiredVendorDatum';
import { privateOrgInfoHelpText } from '../../../components/connected/OrganizationInfoForm/constants';

const { CERTIFICATE_OF_INSURANCE, W_9 } = vendorUploadTypesDict;

const { COI_DOC_REQUIRED, W_9_DOC_REQUIRED } = requiredVendorDatumFieldNames;

function fetchData(getState, dispatch, location, params) {
    const vendorId = Number.parseInt(params.vendorId, 10);
    return dispatch(loadVendorAttachments(vendorId));
}

const mapStateToProps = (state) => {
    return {
        loadError: state.adminVendor.get('loadAttachmentsError'),
        loading: state.adminVendor.get('loadingAttachments'),
        user: getUserJS(state),
        vendorAttachments: getVendorAttachmentsJS(state),
    };
};

const mapDispatchToProps = {
    createVendorAttachment,
    deleteVendorAttachment,
};

// @connectData
// @connect
class ConnectedVendorDocuments extends Component {
    static propTypes = {
        createVendorAttachment: PropTypes.func.isRequired,
        deleteVendorAttachment: PropTypes.func.isRequired,
        isModal: PropTypes.bool,
        loadError: PropTypes.string,
        loading: PropTypes.bool.isRequired,
        requiredVendorDatum: PropTypes.shape({
            [COI_DOC_REQUIRED]: PropTypes.bool,
            [W_9_DOC_REQUIRED]: PropTypes.bool,
        }),
        user: PropTypes.shape({
            vendor_id: PropTypes.number.isRequired,
        }).isRequired,
        vendorAttachments: PropTypes.arrayOf(
            PropTypes.shape({
                attachments: PropTypes.array.isRequired,
                title: PropTypes.string.isRequired,
                type: PropTypes.string.isRequired,
            })
        ).isRequired,
    };

    uploadHandler = (uploadData) => {
        const { user } = this.props;
        return this.props.createVendorAttachment(user.vendor_id, uploadData);
    };

    deleteHandler = (attachment) => {
        const { user } = this.props;
        return this.props.deleteVendorAttachment(user.vendor_id, attachment);
    };

    isGovRequired = (vendorUpload) => {
        const { requiredVendorDatum } = this.props;

        if (!requiredVendorDatum) {
            return false;
        }

        switch (vendorUpload.type) {
            case CERTIFICATE_OF_INSURANCE:
                return requiredVendorDatum[COI_DOC_REQUIRED];
            case W_9:
                return requiredVendorDatum[W_9_DOC_REQUIRED];
            default:
                break;
        }
    };

    render() {
        const { isModal, loadError, loading, user, vendorAttachments } = this.props;

        if (loading) {
            return <LoadingSpinner />;
        }

        if (loadError) {
            return <LoadingError error={loadError} />;
        }

        return (
            <Main>
                <SectionTitle
                    help={privateOrgInfoHelpText}
                    helpPlacement="right"
                    helpStyle={{ marginLeft: 6, marginBottom: -3, width: 20, height: 20 }}
                    info="Upload documents to your company account"
                    title="Documents"
                    useOpenGovStyle
                />
                <div className="row">
                    <div
                        className={
                            isModal ? 'col-sm-10 col-sm-offset-1' : 'col-sm-6 col-sm-offset-3'
                        }
                    >
                        {vendorAttachments.map((vendorUpload) => (
                            <VendorUploader
                                attachments={vendorUpload.attachments}
                                deleteHandler={this.deleteHandler}
                                isGovRequired={this.isGovRequired(vendorUpload)}
                                key={vendorUpload.type}
                                s3GetUrl={`/vendor/${user.vendor_id}/s3`}
                                uploadHandler={this.uploadHandler}
                                uploadTitle={vendorUpload.title}
                                uploadType={vendorUpload.type}
                            />
                        ))}
                    </div>
                </div>
            </Main>
        );
    }
}

export const VendorDocuments = compose(
    connectData(fetchData),
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedVendorDocuments);

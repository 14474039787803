import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ListGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import Sticky from 'react-stickynode';
import { compose } from 'redux';
import { propTypes as reduxFormPropTypes, reduxForm, FieldArray } from 'redux-form';
import { v4 as UUIDv4 } from 'uuid';

import { validate } from './validate';
import { form, fieldNamesDict } from '../constants';
import { ContractContact } from '../ContractContact';
import { getContactSelectOptions, getContractJS } from '../../../selectors';
import { Button, ContactModal, ZeroState } from '../../../../components';

const { CONTACTS, TAG_ID } = fieldNamesDict;

const mapStateToProps = (state) => {
    const contactTypeOptions = getContactSelectOptions(state);

    return {
        contactTypeOptions,
        hasContactTypeOptions: contactTypeOptions.length > 0,
        initialValues: getContractJS(state),
        manageContactsError: state.contracts.get('manageContactsError'),
    };
};

const formConfig = {
    enableReinitialize: true,
    form,
    validate,
};

// @connect
// @reduxForm
class ConnectedContractContactsForm extends PureComponent {
    static propTypes = {
        ...reduxFormPropTypes,
        contactTypeOptions: PropTypes.array.isRequired,
        disabled: PropTypes.bool,
        hasContactTypeOptions: PropTypes.bool,
        manageContactsError: PropTypes.string,
    };

    static defaultProps = {
        disabled: false,
        hasContactTypeOptions: false,
        manageContactsError: undefined,
    };

    get styles() {
        return require('./index.scss');
    }

    renderContractContact = (fields, index, fieldName) => {
        const { change, disabled } = this.props;

        const contractContact = fields.get(index);

        return (
            <ContractContact
                change={change}
                disabled={disabled}
                fieldName={fieldName}
                fields={fields}
                formName={form}
                index={index}
                key={contractContact.id || contractContact.localCreatingUUID}
            />
        );
    };

    renderContractContacts = ({ fields }) => {
        const { contactTypeOptions, dirty, disabled, error, manageContactsError } = this.props;

        return (
            <>
                <Sticky innerZ={1}>
                    {({ status }) => (
                        <div
                            className={this.styles.actionButtons}
                            style={{
                                borderColor: status === Sticky.STATUS_FIXED ? '#ddd' : undefined,
                            }}
                        >
                            {dirty && (
                                <>
                                    <Button bsStyle="success" disabled={disabled} type="submit">
                                        <i className="fa fa-check" /> Save
                                    </Button>
                                    &nbsp;&nbsp;
                                </>
                            )}
                            <Button
                                bsStyle="primary"
                                disabled={disabled}
                                onClick={() => {
                                    fields.unshift({
                                        localCreatingUUID: UUIDv4(),
                                        [TAG_ID]: contactTypeOptions[0].value,
                                    });
                                }}
                            >
                                <i className="fa fa-plus" />
                                &nbsp;Add Contact
                            </Button>
                            {dirty && error && <div className="error-block">{error}</div>}
                            {manageContactsError && (
                                <div className="error-block">{manageContactsError}</div>
                            )}
                        </div>
                    )}
                </Sticky>
                {fields.length > 0 ? (
                    <ListGroup>
                        {fields.map((fieldName, index) => {
                            return this.renderContractContact(fields, index, fieldName);
                        })}
                    </ListGroup>
                ) : (
                    <ZeroState title="No contacts have been added to this contract" />
                )}
            </>
        );
    };

    render() {
        const { handleSubmit, hasContactTypeOptions } = this.props;

        if (!hasContactTypeOptions) {
            return (
                <ZeroState title="At least one contact type tag must exist before contacts can be added" />
            );
        }

        return (
            <form onSubmit={handleSubmit}>
                <FieldArray
                    component={this.renderContractContacts}
                    name={CONTACTS}
                    rerenderOnEveryChange
                />
                <ContactModal formName={form} singlePage />
            </form>
        );
    }
}

export const ContractContactsForm = compose(
    connect(mapStateToProps),
    reduxForm(formConfig)
)(ConnectedContractContactsForm);

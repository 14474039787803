import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';

import { InputText } from '../../InputText';
import { form, SEARCH } from './constants';
import { Button } from '../../Button';

const formConfig = {
    form,
};

// @reduxForm
class ConnectedTableSearchForm extends Component {
    get styles() {
        return require('./TableSearchForm.scss');
    }

    render() {
        const { isGovView, onSubmitContractSearch } = this.props;

        return (
            <form className={this.styles.contractSearchForm} onSubmit={onSubmitContractSearch}>
                <Field
                    aria-label="Search by Vendor, Title, ID, or Contact"
                    component={InputText}
                    formGroupClassName={this.styles.searchInput}
                    name={SEARCH}
                    overrideFeedback
                    placeholder="Search by Vendor, Title, ID, or Contact"
                    qaTag="contractList-search"
                    type="
                    text"
                />
                {isGovView && (
                    <Button
                        bsSize="sm"
                        bsStyle="info"
                        className={this.styles.contractSearchButton}
                        qaTag="contracts-search"
                        type="submit"
                    >
                        <i className="fa fa-search" /> Search
                    </Button>
                )}
            </form>
        );
    }
}

ConnectedTableSearchForm.propTypes = {
    onSubmitContractSearch: PropTypes.func.isRequired,
    isGovView: PropTypes.bool,
};

export const TableSearchForm = reduxForm(formConfig)(ConnectedTableSearchForm);

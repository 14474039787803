import { createSelector } from 'reselect';

import { getProjectJS } from '../selectors';

export const getDocumentsTabName = createSelector([getProjectJS], (project) => {
    let documentsTabName = 'Project Documents';
    if (project && project.isIntake) {
        documentsTabName = 'Project Request';
    } else if (project && project.isDocBuilder) {
        documentsTabName = 'Document';
    }
    return documentsTabName;
});

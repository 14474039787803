import { fromJS, Map } from 'immutable';

import * as accountSecurityActions from '../actions/account/securityInvited';

const initialState = new Map({
    saving: false,
});

export default function accountSecurityInvitedReducer(state = initialState, action = {}) {
    switch (action.type) {
        case accountSecurityActions.SAVE:
            return state.set('saving', true);
        case accountSecurityActions.SAVE_SUCCESS:
            return state.delete('error').set('saving', false);
        case accountSecurityActions.SAVE_FAIL:
            return state.merge(
                fromJS({
                    saving: false,
                    error: action.error && action.error.message,
                })
            );
        default:
            return state;
    }
}

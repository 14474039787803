import { createSelector } from 'reselect';

import { getCurrentEvaluation } from '../../selectors';

const getEvaluatorIdParams = (state, props) => Number.parseInt(props.params.evaluatorId, 10);

export const getEvaluatorJS = createSelector(
    [getEvaluatorIdParams, getCurrentEvaluation],
    (evaluatorId, evaluation) => {
        if (evaluatorId && evaluation) {
            return evaluation.evaluators.find((evaluator) => evaluator.id === evaluatorId);
        }
    }
);

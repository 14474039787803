import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FieldArray } from 'redux-form';

import { ProjectSectionsForm } from './components';
import { fieldNames } from './constants';

const { PROJECT_SECTIONS } = fieldNames;

export class TemplateProjectSectionsForm extends PureComponent {
    static propTypes = {
        array: PropTypes.object.isRequired,
        change: PropTypes.func.isRequired,
        createProjectSection: PropTypes.func.isRequired,
        createProjectSectionError: PropTypes.string,
        createTemplateSection: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        isDirty: PropTypes.bool,
        isDocBuilder: PropTypes.bool.isRequired,
        isIntake: PropTypes.bool.isRequired,
        isSpecial: PropTypes.bool.isRequired,
        showConfirmationSimpleModal: PropTypes.func.isRequired,
        showFormValidation: PropTypes.bool,
        updateTemplate: PropTypes.func.isRequired,
        useManualNumbering: PropTypes.bool,
        useSectionDividers: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = { openSectionId: null };
    }

    get styles() {
        return require('./index.scss');
    }

    openSectionHandler = (sectionId) => {
        const { openSectionId } = this.state;

        // No section should be selected if the numbers match (open section is being closed)
        const newSectionId = sectionId === openSectionId ? null : sectionId;
        this.setState({ openSectionId: newSectionId });
    };

    render() {
        const {
            array,
            change,
            createProjectSection,
            createProjectSectionError,
            createTemplateSection,
            disabled,
            isDirty,
            isDocBuilder,
            isIntake,
            isSpecial,
            showConfirmationSimpleModal,
            showFormValidation,
            updateTemplate,
            useManualNumbering,
            useSectionDividers,
        } = this.props;

        const { openSectionId } = this.state;

        return (
            <div>
                <FieldArray
                    array={array}
                    change={change}
                    component={ProjectSectionsForm}
                    createProjectSection={createProjectSection}
                    createProjectSectionError={createProjectSectionError}
                    createTemplateSection={createTemplateSection}
                    disabled={disabled}
                    isDirty={isDirty}
                    isDocBuilder={isDocBuilder}
                    isIntake={isIntake}
                    isSpecial={isSpecial}
                    name={PROJECT_SECTIONS}
                    openSectionId={openSectionId}
                    showConfirmationSimpleModal={showConfirmationSimpleModal}
                    showFormHandler={this.openSectionHandler}
                    showFormValidation={showFormValidation}
                    updateTemplate={updateTemplate}
                    useManualNumbering={useManualNumbering}
                    useSectionDividers={useSectionDividers}
                />
            </div>
        );
    }
}

import { useCustomFormServiceMutation } from './useCustomFormService';
import { editCustomField } from './queries';

/**
 *
 * @param {number} customFieldId
 * @returns {Object} react-query useMutation object
 */
export const useUpdateCustomField = (customFieldId) => {
    return useCustomFormServiceMutation({
        mutationKey: 'updateCustomField',
        mutation: editCustomField,
        enabled: true,
        params: { customFieldId },
    });
};

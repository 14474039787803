import { fieldNames } from './constants';

const { COUNTER, MASK, NUM_DIGITS } = fieldNames;

export const validate = (values) => {
    const errors = {};

    if (!values[COUNTER] && values[COUNTER] !== 0) {
        errors[COUNTER] = 'Please specify a starting number for counter';
    } else if (values[COUNTER] < 0) {
        errors[COUNTER] = 'Starting number must be 0 or greater';
    }

    if (!values[NUM_DIGITS] && values[NUM_DIGITS] !== 0) {
        errors[NUM_DIGITS] = 'Please specify a number of digits';
    } else if (values[NUM_DIGITS] < 0) {
        errors[NUM_DIGITS] = 'Number of digits must be 0 or greater';
    } else if (values[NUM_DIGITS] > 20) {
        errors[NUM_DIGITS] = 'Number of digits must be 20 or less';
    }

    if (!values[MASK]) {
        errors[MASK] = 'Please specify a mask';
    } else if (!values[MASK].match(/{{\s*COUNTER\s*}}/i)) {
        errors[MASK] = 'Mask must contain "{{COUNTER}}" value';
    }

    return errors;
};

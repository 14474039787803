import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

export class ResolveButton extends PureComponent {
    static propTypes = {
        threadId: PropTypes.number.isRequired,
        disabled: PropTypes.bool,
        resolveClickHandler: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = { resolveButtonHover: false };
    }

    handleResolveClick = () => {
        const { threadId, resolveClickHandler } = this.props;

        resolveClickHandler(threadId);
    };

    toggleHoverOn = () => {
        if (!this.props.disabled) {
            this.setState({ resolveButtonHover: true });
        }
    };

    toggleHoverOff = () => {
        this.setState({ resolveButtonHover: false });
    };

    render() {
        const { disabled } = this.props;
        const resolveButtonStyle = this.state.resolveButtonHover ? 'success' : 'default';
        const resolveIcon = this.state.resolveButtonHover ? 'fa-check-square-o' : 'fa-square-o';

        return (
            <Button
                bsStyle={resolveButtonStyle}
                disabled={disabled}
                onClick={this.handleResolveClick}
                onMouseEnter={this.toggleHoverOn}
                onMouseLeave={this.toggleHoverOff}
            >
                <i className={`fa fa-fw ${resolveIcon}`} /> Resolve
            </Button>
        );
    }
}

import { replaceNewline } from '../../../../../utils';

export const titles = ['Overview (1/2)', 'Examples'];

export const backgroundExamples = [
    {
        title: 'Example 1 - City Website Design',
        description:
            'By the winter of 2015, we want to launch the first step ' +
            "in the transformation of the City's website -- the digital fr" +
            'ont door ' +
            'for the City. Each year, this site is visited by over seven mi' +
            'llion users, providing news, information, resources, and digit' +
            'al services to City residents and visitors. Users can browse' +
            ' its over 5,000 pages and web forms to do everything from plan' +
            'ning a trip to the City, to seeing the assessed value of a prope' +
            'rty, to watching a live press conference, to filing a report a' +
            'bout a pothole. The site is managed by the City’s Chief Digita' +
            'l Officer and is supported by a team of five with backgrounds ' +
            'in content, design, project management, and social media. As t' +
            'he ongoing owners of the website, the City’s web team will be a' +
            'n active participant during the redesign in order to learn, st' +
            "rengthen their own skills, and continue the evolution of the City's" +
            ' website. Most page content is authored, maintained, and updated ' +
            'by more than 20 authors across 50 City departments and initiat' +
            'ives. The overhaul of the City website is happening in two maj' +
            'or pha' +
            'ses. First, the City will be implementing a new content manage' +
            'ment system to help support the team in keeping content fresh ' +
            'and accurate. Second -- and the focus of this opportunity -- t' +
            'he City will partner with a design vendor to conduct user expe' +
            'rience research and provide visual design services to reimagin' +
            'e the presentation, usability, and overall user quality of the' +
            " City's official website, with an emphasis on visual -- vs. t" +
            'extual -- communication and navigation. Both phases will be ma' +
            'naged on a day-to-day basis by a dedicated Project Manager fro' +
            'm the City, who is supported by the Chief Digital Officer and ' +
            'Chief Information Officer. This projec' +
            't is work for hire, and we would strongly support our selected' +
            ' partner including this engagement in their public portfolio.',
    },
    {
        title: 'Example 2 - Enhancing City Hall Plaza',
        description: replaceNewline(
            'The effort to improve City Hall Plaza outlined in this documen' +
                't is one of three inter-related ' +
                'projects that the City is pursuing. The intent of these effort' +
                's, together, is to transform City Hall and CityHall Plaza, bre' +
                'athing new civic life into these spaces and reorganizing both ' +
                'spaces around the needs and interests of our constituents.\n\n' +
                'Those three efforts include a Campus Plan, a Plaza Partner, an' +
                'd a Civic/Cultural Partner:\n\nCampus Plan: We are currently i' +
                'n the process of contracting with a designer to develop a mast' +
                'er plan for City Hall, City Hall Plaza and 26 Court Street. Th' +
                'e result of this plan, which will be developed over the next f' +
                'ew years, will be a vision and roadmap for the operation and d' +
                'esign improvements to those facilities.\n\nPlaza Partner: Whil' +
                'e the campus plan is being developed, the City - through this ' +
                'document - is seeking a partner to improve City Hall Plaza ove' +
                'r the next three years. The awardee will be asked to be an act' +
                'ive participant i' +
                'n the Campus Plan process when various plaza options are consi' +
                'dered.\n\nCivic/Cultural Partner: We are also interested in pa' +
                'rtnering with an entity to operate a civic/cultural entity in ' +
                'City Hall whose presence could complement the cultural and his' +
                'toric improvements of City Hall Plaza institutions in the ar' +
                'ea and enhance the current building. This partner may be the s' +
                'ubject of a future solicitation. All three of these projects a' +
                "re led by the City's Chief of Operations and its Property and" +
                ' Construction Management Department.'
        ),
    },
    {
        title: 'Example 3 - Parks Recreational Management Solution',
        description: replaceNewline(
            'The City Parks and Recreation Department provides residents ' +
                'and visitors with clean, green, safe, and accessible open spac' +
                'e in 2,100 acres of park land throughout the city including pa' +
                'rks, playgrounds, athletic facilities, city squares, urban wil' +
                'ds, street trees, three active cemeteries, sixteen historic bu' +
                'rying grounds, and two golf courses.\n\n' +
                'The Department also programs a wide range of ' +
                'community events and live entertainment in the parks under its' +
                ' jurisdiction, and enhances public safety through the City P' +
                'ark Rangers. These operations are overseen by the City Parks' +
                ' and Recreation Commission, which is a policy-making citizen b' +
                'ody appointed by the Mayor.\n\nPermitting Division: Over one m' +
                'illion people annually participate in the athletics and specia' +
                'l events permitted by the Parks Department. The Permitting Div' +
                'ision consists of three full-time professionals: the Director,' +
                'Special Events Manager, and Administrative Secretary. The Divi' +
                'sion issued 1,671 permits in 2014 and collected $428,000 in fe' +
                'es. Active’s Class software is currently in use with five lice' +
                'nses, but this system will no longer be supported as of 2018. ' +
                'In addition to providing permits for special events and athlet' +
                'ics, this office also manages partnerships with local colleges' +
                ' and universities. The information gathered is disseminated to' +
                ' stakeholders including: the Maintenance, Ranger, and Lighting' +
                ' Divisions of the Parks Department; city-wide Public Safety ag' +
                'encies; and Licensing agencies.\n\nRecreation Division:  The ' +
                'City Parks and Recreation Department offers free youth athlet' +
                'ic programs, clinics, and camps in neighborhood parks citywide' +
                ', as well as local community centers.'
        ),
    },
];

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export class OrganizationLogo extends PureComponent {
    static propTypes = {
        organization: PropTypes.shape({
            logo: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        }),
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const {
            organization: { logo, name },
        } = this.props;

        return (
            <div
                className={this.styles.imageContainer}
                style={{ backgroundImage: `url(${logo})` }}
                title={`${name} logo`}
            />
        );
    }
}

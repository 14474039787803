const { listToDict } = require('../helpers');

exports.editableCustomFieldProperties = [
    'name',
    'shortTitle',
    'instructionsText',
    'instructionsMode',
    'characterLimit',
    'numberKind',
    'status',
    'enumOptions',
];

const createCustomFieldInitialFields = [
    ...exports.editableCustomFieldProperties,
    'id', // Only present for existing customFields
    'fieldType', // Always present. For existing customFields this is loaded from backend. For new fields, this is loaded from path params
];

exports.createCustomFieldFormName = 'requisitionsCreateCustomField';

exports.customFieldFields = listToDict(createCustomFieldInitialFields);

const SHORT_TEXT = 'shortText';
const LONG_TEXT = 'longText';
const NUMBER = 'number';
const DATE = 'date';
const DROPDOWN = 'dropdown';
const CHECKBOX = 'checkbox';
const YES_NO = 'yesNo';
const MULTIPLE_CHOICE = 'multipleChoice';
const SINGLE_CHOICE = 'singleChoice';

exports.fieldTypes = {
    SHORT_TEXT,
    LONG_TEXT,
    NUMBER,
    DATE,
    DROPDOWN,
    CHECKBOX,
    YES_NO,
    MULTIPLE_CHOICE,
    SINGLE_CHOICE,
};

exports.fieldTypesArray = [
    SHORT_TEXT,
    LONG_TEXT,
    NUMBER,
    YES_NO,
    MULTIPLE_CHOICE,
    SINGLE_CHOICE,
    DATE,
    DROPDOWN,
    CHECKBOX,
];

// Icon is a placeholder for now
exports.fieldDataTypes = {
    [SHORT_TEXT]: {
        label: 'Short Text',
        icon: 'short text',
    },
    [LONG_TEXT]: {
        label: 'Long Text',
        icon: 'long text',
    },
    [NUMBER]: {
        label: 'Number',
        icon: 'number',
    },
    [YES_NO]: {
        label: 'Yes/No',
        icon: 'yesno',
    },
    [MULTIPLE_CHOICE]: {
        label: 'Multiple Choice',
        icon: 'multiplechoice',
    },
    [SINGLE_CHOICE]: {
        label: 'Single Choice',
        icon: 'singleChoice',
    },
    [DATE]: {
        label: 'Date',
        icon: 'date',
    },
    [DROPDOWN]: {
        label: 'Dropdown',
        icon: 'dropdown',
    },
    [CHECKBOX]: {
        label: 'Checkbox',
        icon: 'checkbox',
    },
};

const dataTypes = {
    STRING: 'string',
    SINGLE_ENUM: 'singleEnum',
    MULTI_ENUM: 'multiEnum',
    NUMBER: 'number',
    BOOLEAN: 'boolean',
    DATE: 'date',
};

exports.dataTypes = dataTypes;

exports.fieldTypeToDataTypeMap = {
    [SHORT_TEXT]: dataTypes.STRING,
    [LONG_TEXT]: dataTypes.STRING,
    [NUMBER]: dataTypes.NUMBER,
    [DATE]: dataTypes.DATE,
    [DROPDOWN]: dataTypes.SINGLE_ENUM,
    [CHECKBOX]: dataTypes.SINGLE_ENUM,
    [YES_NO]: dataTypes.SINGLE_ENUM,
    [MULTIPLE_CHOICE]: dataTypes.MULTI_ENUM,
    [SINGLE_CHOICE]: dataTypes.SINGLE_ENUM,
};

exports.characterLimits = ['None', '256', '512', '1200', '5000'];

exports.characterLimitsArray = [
    {
        label: 'None',
        value: 'None',
    },
    {
        label: 'Up to 256 Characters',
        value: '256',
    },
    {
        label: 'Up to 512 Characters',
        value: '512',
    },
    {
        label: 'Up to 1200 Characters',
        value: '1200',
    },
    {
        label: 'Up to 5000 Characters',
        value: '5000',
    },
];

const DESCRIPTION = 'description';
const TOOLTIP = 'tooltip';

exports.instructionsModes = {
    DESCRIPTION,
    TOOLTIP,
};

exports.instructionsModeArray = [
    {
        label: 'Description Text',
        value: DESCRIPTION,
    },
    {
        label: 'Tool Tip',
        value: TOOLTIP,
    },
];

const numberKinds = {
    CURRENCY: 'currency',
    DECIMAL: 'decimal',
    WHOLE: 'whole',
    PERCENTAGE: 'percentage',
};

exports.numberKinds = numberKinds;

exports.numberKindsArray = [
    {
        label: 'Currency',
        value: numberKinds.CURRENCY,
    },
    {
        label: 'Decimal',
        value: numberKinds.DECIMAL,
    },
    {
        label: 'Whole Number',
        value: numberKinds.WHOLE,
    },
    {
        label: 'Percentage',
        value: numberKinds.PERCENTAGE,
    },
];

exports.yesNoOptions = ['Yes', 'No'];

exports.ENUM_OPTIONS_FIELD = 'enumOptions';

exports.CHARACTER_LIMIT_FIELD = 'characterLimit';

exports.NUMBER_KIND_FIELD = 'numberKind';

exports.fieldStatuses = ['draft', 'published', 'archived'];

exports.fieldStatusesDict = listToDict(exports.fieldStatuses);

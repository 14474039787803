import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';

import { tokens } from '@opengov/capital-style';

import { Tooltip } from '../../../../../components';
import { CellWrapper } from '../CellWrapper';

export const SelectCell = ({ api, node, setValue, value }) => {
    const styles = require('./index.scss');
    const getPolicyOptions = () =>
        value.options.map((policy) => ({
            value: policy.id,
            label: policy.abbreviation,
        }));

    const getSelectedValueAsObject = () => {
        const selectedValue = value.options.find((option) => option.id === value.selectedPolicy);
        if (selectedValue) {
            return {
                label: selectedValue.abbreviation,
                value: selectedValue.id,
            };
        }
        return undefined;
    };

    const selectedPolicyData = value.options.find((option) => option.id === value.selectedPolicy);

    const showExpiry =
        value.selectedPolicy && value.closeDate && selectedPolicyData.archiveSchedule;

    const errorText = 'Policy not applied. Try again later.';

    const renderPolicies = () => {
        return (
            <>
                {/* Per https://stackoverflow.com/questions/55830799/how-to-change-zindex-in-react-select-drowpdown */}
                <Select
                    aria-label="Select Policy Code"
                    menuPortalTarget={document.body}
                    onChange={(val) => {
                        setValue({ ...value, selectedPolicy: val.value });
                    }}
                    options={getPolicyOptions()}
                    placeholder="Select Policy Code"
                    styles={{
                        control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: value.rowError ? '#c72e1c' : '#d9d9d9',
                        }),
                        menuPortal: (base) => ({ ...base, zIndex: 10 }),
                        placeholder: (baseStyles) => ({
                            ...baseStyles,
                            color: tokens.colors.colorGray700,
                        }),
                    }}
                    value={[getSelectedValueAsObject()]}
                />
                {value.rowError && (
                    <Tooltip
                        container={this}
                        isPopover
                        placement="top"
                        rootClose
                        tooltip={errorText}
                    >
                        <span className={styles.errorText}>{errorText}</span>
                    </Tooltip>
                )}
                {!!showExpiry && !value.rowError && (
                    <div className={styles.subText}>
                        <i className="fa fa-clock-o" /> Expires:{' '}
                        {moment(value.closeDate)
                            .add(selectedPolicyData.archiveSchedule, 'y')
                            .format('MMMM Do, YYYY')}
                    </div>
                )}
            </>
        );
    };

    return (
        <CellWrapper api={api} node={node}>
            {renderPolicies()}
        </CellWrapper>
    );
};

SelectCell.propTypes = {
    api: PropTypes.object.isRequired,
    node: PropTypes.object.isRequired,
    setValue: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
};

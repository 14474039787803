import PropTypes from 'prop-types';
import React from 'react';

export const ReviewSequenceSteps = ({ reviewSequenceSteps }) => {
    if (reviewSequenceSteps.length === 0) {
        return 'No steps configured';
    }

    return (
        <div>
            <i className="fa fa-fw fa-cog" /> Steps: {reviewSequenceSteps.length}
        </div>
    );
};

ReviewSequenceSteps.propTypes = {
    reviewSequenceSteps: PropTypes.array.isRequired,
};

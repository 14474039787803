import PropTypes from 'prop-types';
import React from 'react';

export const QuestionsInfo = ({ questionnaires }) => {
    if (questionnaires.length === 0) {
        return 'No questions specified';
    }

    return (
        <div>
            <i className="fa fa-fw fa-gear" /> Checklist Questions: {questionnaires.length}
        </div>
    );
};

QuestionsInfo.propTypes = {
    questionnaires: PropTypes.array.isRequired,
};

export const validate = (values) => {
    const errors = {};

    if (!values.name) {
        errors.name = 'This field is required';
    }

    if (!values.departmentHead) {
        errors.departmentHead = 'This field is required';
    }

    if (!values.departmentHeadTitle) {
        errors.departmentHeadTitle = 'This field is required';
    }

    return errors;
};

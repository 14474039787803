import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';

import { form } from './TemplateSectionForm/constants';
import { getUserJS } from '../../../selectors';
import {
    buildCriteriaMap,
    buildSectionDescriptionMap,
} from '../../../../reducers/helpers/projectCreateHelper';

const getRawTemplateSection = (state) => state.templateSections.get('templateSection');
const templateFormValues = (state) => getFormValues(form)(state);
const getRawTemplateSectionProjects = (state) =>
    state.templateSections.get('templateSectionProjects');

export const getTemplateSection = createSelector([getRawTemplateSection], (rawTemplateSection) => {
    if (rawTemplateSection) {
        const originalTemplateSection = rawTemplateSection.toJS();
        const {
            projectSection: { sectionDescriptions, ...projectSectionData },
            ...templateSectionData
        } = originalTemplateSection;

        const formattedTemplateSection = {
            ...templateSectionData,
            projectSection: projectSectionData,
            sectionDescriptions,
        };

        return buildCriteriaMap(buildSectionDescriptionMap(formattedTemplateSection));
    }
});

export const getTemplateSectionFormValues = createSelector([templateFormValues], (formValues) => {
    if (formValues) {
        return formValues;
    }
    return {};
});

export const getTemplateSectionTagOptions = createSelector(
    [getTemplateSection, getUserJS],
    (templateSection, user) => {
        if (!templateSection) {
            return [];
        }
        const governmentCode = user.government.code;
        return [
            {
                label: 'Public Project URL',
                linkOnly: true,
                value: `${process.env.SITE_URL}/portal/${governmentCode}/projects/1`, // Hard coding ID of 1 as there is no project ID and it will get replaced on copying
            },
            {
                label: 'Procurement Portal URL',
                linkOnly: true,
                value: `${process.env.SITE_URL}/portal/${governmentCode}`,
            },
        ];
    }
);

export const getTemplateSectionProjects = createSelector(
    [getRawTemplateSectionProjects],
    (rawProjects) => {
        if (rawProjects) {
            return rawProjects.toJS();
        }
        return [];
    }
);

import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { formValueSelector } from 'redux-form';

import { SDv2Timeline } from '../../../../../../../../components/GovApp';
import { form } from '../../../../../forms/constants';
import { TemplateEditV2FunctionsContext } from '../../../../context';
import { fieldNames, templateFieldNames } from '../../../../../forms/TemplateForm/constants';

const { TEMPLATE } = fieldNames;
const { IS_REVERSE_AUCTION } = templateFieldNames;
const selector = formValueSelector(form);

export const TemplateEditV2TimelineForm = () => {
    const { array, change, disabled, showFormValidation } = useContext(
        TemplateEditV2FunctionsContext
    );
    const isReverseAuction = useSelector((state) =>
        selector(state, `${TEMPLATE}.${IS_REVERSE_AUCTION}`)
    );
    const styles = require('../index.scss');

    return (
        <div className={styles.container}>
            <SDv2Timeline
                array={array}
                change={change}
                disabled={disabled}
                form={form}
                isReverseAuction={isReverseAuction}
                isTemplate
                showValidation={showFormValidation}
            />
        </div>
    );
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getRequestingUserJS } from './selectors';
import { LoginTitle } from '../components/LoginTitle';
import { OrganizationLogo } from '../components/OrganizationLogo';
import { cancelRequestToJoinOrganization } from '../../../actions/auth';
import { showConfirmationSimpleModal } from '../../../actions/confirmation';
import { OutlineButton, PageTitle } from '../../../components';

const mapStateToProps = (state) => {
    return {
        requestingUser: getRequestingUserJS(state),
        updateError: state.auth.get('cancelRequestToJoinError'),
        updating: state.auth.get('cancelingRequestToJoin'),
    };
};

const mapDispatchToProps = {
    cancelRequestToJoinOrganization,
    showConfirmationSimpleModal,
};

// @connect
class ConnectedRequestToJoinPending extends Component {
    static propTypes = {
        cancelRequestToJoinOrganization: PropTypes.func.isRequired,
        requestingUser: PropTypes.shape({
            id: PropTypes.number.isRequired,
            organization: PropTypes.shape({
                name: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        showConfirmationSimpleModal: PropTypes.func.isRequired,
        updateError: PropTypes.string,
        updating: PropTypes.bool.isRequired,
    };

    cancelRequestToJoinOrganization = () => {
        const {
            requestingUser: {
                id: userId,
                organization: { name },
            },
        } = this.props;

        this.props.showConfirmationSimpleModal(
            () => this.props.cancelRequestToJoinOrganization(userId),
            {
                text: `Are you sure you want to cancel your request to join ${name}?`,
                btnText: 'Cancel Request',
                icon: 'user-times',
            }
        );
    };

    render() {
        if (!this.props.requestingUser) {
            return null;
        }

        const {
            requestingUser: {
                organization,
                organization: { name },
            },
            updateError,
            updating,
        } = this.props;

        return (
            <div className="text-center">
                <PageTitle title="Request Pending" />
                <OrganizationLogo organization={organization} />
                <LoginTitle className="text-primary">
                    Your request to join {name} is still pending!
                </LoginTitle>
                <p>
                    Once a {name} account admin has approved your request, we will send you an email
                    to activate your OpenGov Procurement account.
                </p>
                <p>
                    If you have decided to create your own organization instead, you can cancel your
                    request to join {name} and create a new organization.
                </p>
                <p>
                    <OutlineButton
                        bsStyle="danger"
                        disabled={updating}
                        onClick={this.cancelRequestToJoinOrganization}
                    >
                        <i className="fa fa-user-times" /> Cancel Request to Join
                    </OutlineButton>
                </p>
                {updateError && <div className="error-block">{updateError}</div>}
                <p>
                    If you have any questions please email us at
                    <br />
                    <a href="mailto:procurement-support@opengov.com">
                        procurement-support@opengov.com
                    </a>
                </p>
            </div>
        );
    }
}

export const RequestToJoinPending = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedRequestToJoinPending);

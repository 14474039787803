import { fromJS } from 'immutable';

import * as templateAdminActions from '../actions/templatesAdmin';

const initialStateTemplateEditState = {
    availableTemplateSections: [],
    copyTemplateError: null,
    copyingTemplate: false,
    createProjectSectionError: null,
    createUpfrontQuestionError: null,
    creatingProjectSection: false,
    creatingUpfrontQuestion: false,
    criteriaNeedsReviewData: null,
    deleteError: null,
    deleting: false,
    loadError: null,
    loading: false,
    questionLogicEditIndex: null,
    questionLogicLinkableItemDisabled: false,
    questionLogicModalData: null,
    showCriteriaNeedsReviewModal: false,
    showQuestionLogicModal: false,
    template: null,
    updateError: null,
    updating: false,
};

const initialState = fromJS({
    ...initialStateTemplateEditState,
    createError: null,
    creating: false,
    docxTemplates: [],
    loadedList: false,
    loadingList: false,
    loadListError: null,
    templates: [],
});

export default function templatesAdminReducer(state = initialState, action = {}) {
    switch (action.type) {
        case templateAdminActions.COPY_TEMPLATE:
            return state.merge(
                fromJS({
                    copyingTemplate: true,
                    copyTemplateError: null,
                })
            );
        case templateAdminActions.COPY_TEMPLATE_FAIL:
            return state.merge(
                fromJS({
                    copyingTemplate: false,
                    copyTemplateError: action.error && action.error.message,
                })
            );
        case templateAdminActions.COPY_TEMPLATE_SUCCESS:
            return state.merge(
                fromJS({
                    copyingTemplate: false,
                })
            );
        case templateAdminActions.CREATE:
            return state.merge(
                fromJS({
                    creating: true,
                    createError: null,
                })
            );
        case templateAdminActions.CREATE_FAIL:
            return state.merge(
                fromJS({
                    creating: false,
                    createError: action.error && action.error.message,
                })
            );
        case templateAdminActions.CREATE_SUCCESS:
            return state.merge(
                fromJS({
                    creating: false,
                })
            );
        case templateAdminActions.CREATE_PROJECT_SECTION:
            return state.merge(
                fromJS({
                    creatingProjectSection: true,
                    createProjectSectionError: null,
                })
            );
        case templateAdminActions.CREATE_PROJECT_SECTION_FAIL:
            return state.merge(
                fromJS({
                    creatingProjectSection: false,
                    createProjectSectionError: action.error && action.error.message,
                })
            );
        case templateAdminActions.CREATE_PROJECT_SECTION_SUCCESS:
        case templateAdminActions.CREATE_TEMPLATE_SECTION_SUCCESS:
            return state.merge(
                fromJS({
                    creatingProjectSection: false,
                })
            );
        case templateAdminActions.CREATE_UPFRONT_QUESTION:
            return state.merge(
                fromJS({
                    creatingUpfrontQuestion: true,
                    createUpfrontQuestionError: null,
                })
            );
        case templateAdminActions.CREATE_UPFRONT_QUESTION_FAIL:
            return state.merge(
                fromJS({
                    creatingUpfrontQuestion: false,
                    createUpfrontQuestionError: action.error && action.error.message,
                })
            );
        case templateAdminActions.CREATE_TEMPLATE_QUESTION_SUCCESS:
            return state.setIn(
                ['template', 'upfrontQuestions'],
                state.getIn(['template', 'upfrontQuestions']).push(fromJS(action.result))
            );
        case templateAdminActions.CREATE_UPFRONT_QUESTION_SUCCESS:
            return state.merge(
                fromJS({
                    creatingUpfrontQuestion: false,
                })
            );
        case templateAdminActions.CRITERIA_NEEDS_REVIEW_MODAL_HIDE:
            return state.merge(
                fromJS({
                    criteriaNeedsReviewData: null,
                    showCriteriaNeedsReviewModal: false,
                })
            );
        case templateAdminActions.CRITERIA_NEEDS_REVIEW_MODAL_SHOW:
            return state.merge(
                fromJS({
                    criteriaNeedsReviewData: fromJS(action.data),
                    showCriteriaNeedsReviewModal: true,
                })
            );
        case templateAdminActions.DELETE:
            return state.merge(
                fromJS({
                    deleting: true,
                    deleteError: null,
                })
            );
        case templateAdminActions.DELETE_FAIL:
            return state.merge(
                fromJS({
                    deleting: false,
                    deleteError: action.error && action.error.message,
                })
            );
        case templateAdminActions.DELETE_SUCCESS:
            return state.merge(
                fromJS({
                    deleted: true,
                    deleting: false,
                    templates: state
                        .get('templates')
                        .filter((template) => template.get('id') !== action.result.id),
                })
            );
        case templateAdminActions.LOAD:
            return state.merge(
                fromJS({
                    loading: true,
                    loadError: null,
                })
            );
        case templateAdminActions.LOAD_FAIL:
            return state.merge(
                fromJS({
                    loading: false,
                    loadError: action.error && action.error.message,
                })
            );
        case templateAdminActions.LOAD_SUCCESS: {
            return state.merge(
                fromJS({
                    loading: false,
                    template: fromJS(action.result),
                })
            );
        }
        case templateAdminActions.LOAD_LIST:
            return state.merge(
                fromJS({
                    loadingList: true,
                    loadListError: null,
                })
            );
        case templateAdminActions.LOAD_LIST_FAIL:
            return state.merge(
                fromJS({
                    loadingList: false,
                    loadListError: action.error && action.error.message,
                })
            );
        case templateAdminActions.LOAD_LIST_SUCCESS:
            return state.merge(
                fromJS({
                    docxTemplates: fromJS(action.result.docxTemplates),
                    loadedList: true,
                    loadingList: false,
                    templates: fromJS(action.result.templates),
                })
            );
        case templateAdminActions.LOAD_TEMPLATE_SECTIONS_SUCCESS:
            return state.merge(
                fromJS({
                    availableTemplateSections: fromJS(action.result),
                })
            );
        case templateAdminActions.QUESTION_LOGIC_MODAL_HIDE:
            return state.merge(
                fromJS({
                    questionLogicEditIndex: null,
                    questionLogicLinkableItemDisabled: false,
                    questionLogicModalData: null,
                    showQuestionLogicModal: false,
                })
            );
        case templateAdminActions.QUESTION_LOGIC_MODAL_SHOW:
            return state.merge(
                fromJS({
                    questionLogicEditIndex: action.editIndex,
                    questionLogicLinkableItemDisabled: action.disableLinkableItem,
                    questionLogicModalData: fromJS(action.data),
                    showQuestionLogicModal: true,
                })
            );
        case templateAdminActions.RESET_TEMPLATE_EDIT:
            return state.merge(fromJS(initialStateTemplateEditState));
        case templateAdminActions.RESET_TEMPLATE_LIST:
            return state.merge(
                fromJS({
                    loadedList: false,
                })
            );
        case templateAdminActions.SET_DOCX_TEMPLATES:
            return state.merge(
                fromJS({
                    docxTemplates: fromJS(action.result),
                })
            );
        case templateAdminActions.UPDATE:
            return state.merge(
                fromJS({
                    updating: true,
                    updateError: null,
                })
            );
        case templateAdminActions.UPDATE_FAIL:
            return state.merge(
                fromJS({
                    updating: false,
                    updateError: action.error && action.error.message,
                })
            );
        case templateAdminActions.UPDATE_SUCCESS:
            return state.merge(
                fromJS({
                    updating: false,
                    template: fromJS(action.result),
                    createProjectSectionError: null,
                })
            );
        default:
            return state;
    }
}

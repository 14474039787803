import PropTypes from 'prop-types';
import React from 'react';

import { SearchSelectMultiLabelOption } from '../../../../../../../components';

export const SearchSelectOption = (props) => {
    const {
        data: {
            contract: { contractId, contractParty, title },
        },
    } = props;

    const vendorName = contractParty?.companyName;

    return (
        <SearchSelectMultiLabelOption
            {...props}
            label={title || 'Untitled Contract'}
            secondaryLabel={contractId}
            tertiaryLabel={vendorName}
        />
    );
};

SearchSelectOption.propTypes = {
    data: PropTypes.shape({
        contract: PropTypes.shape({
            contractId: PropTypes.string,
            contractParty: PropTypes.shape({
                companyName: PropTypes.string,
            }),
            title: PropTypes.string,
        }),
    }).isRequired,
};

import { redirect } from 'react-router-dom';

import { isLoggedIn, isVendorUser } from '../../actions/auth';

/*
 * Redirects a user to the login page if they are not a vendor user or are not logged in.
 */
export default (getState) =>
    async ({ params }) => {
        const state = getState();

        if (!isLoggedIn(state) || !isVendorUser(state, params.vendorId)) {
            return redirect('/login');
        }

        return null;
    };

import PropTypes from 'prop-types';
import React from 'react';

export const ActionPanelItem = ({ children, label }) => {
    const styles = require('./index.scss');

    return (
        <div className={styles.actionPanelItem}>
            <label>{label}</label>
            <div>{children}</div>
        </div>
    );
};

ActionPanelItem.propTypes = {
    children: PropTypes.node.isRequired,
    label: PropTypes.string.isRequired,
};

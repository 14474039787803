import { resourceManager } from '../helpers';

export const LOAD_PROCURATED_RATING = 'ratings/LOAD_PROCURATED_RATING';
export const LOAD_PROCURATED_RATING_FAIL = 'ratings/LOAD_PROCURATED_RATING_FAIL';
export const LOAD_PROCURATED_RATING_SUCCESS = 'ratings/LOAD_PROCURATED_RATING_SUCCESS';

export const LOAD_PROCURATED_RATINGS = 'ratings/LOAD_PROCURATED_RATINGS';
export const LOAD_PROCURATED_RATINGS_FAIL = 'ratings/LOAD_PROCURATED_RATINGS_FAIL';
export const LOAD_PROCURATED_RATINGS_SUCCESS = 'ratings/LOAD_PROCURATED_RATINGS_SUCCESS';

const onLoadProcuratedRatingStart = ({ dispatch }) => {
    dispatch({ type: LOAD_PROCURATED_RATING });
};

const onLoadProcuratedRatingSuccess = ({ result, dispatch }) => {
    dispatch({
        type: LOAD_PROCURATED_RATING_SUCCESS,
        result,
    });
};

const onLoadProcuratedRatingFailure = ({ error, dispatch }) => {
    dispatch({ type: LOAD_PROCURATED_RATING_FAIL, error });
};

const onLoadProcuratedRatingsStart = ({ dispatch }) => {
    dispatch({ type: LOAD_PROCURATED_RATINGS });
};

const onLoadProcuratedRatingsSuccess = ({ result, dispatch }) => {
    dispatch({
        type: LOAD_PROCURATED_RATINGS_SUCCESS,
        result,
    });
};

const onLoadProcuratedRatingsFailure = ({ error, dispatch }) => {
    dispatch({ type: LOAD_PROCURATED_RATINGS_FAIL, error });
};

/**
 * Load the Procurated rating for a single vendor
 * @param {object} [options={}] The set of options to pass to the request context
 * @param {number} [options.supplierId] A supplierId to retrieve the ratings for
 * @return {Promise<object>} The fetched rating
 */
export function loadProcuratedRating(options = {}) {
    return resourceManager({
        method: 'get',
        url: `/procurated/supplier/${options.supplierId}`,
        onStart: onLoadProcuratedRatingStart,
        onSuccess: onLoadProcuratedRatingSuccess,
        onFailure: onLoadProcuratedRatingFailure,
        context: options,
    });
}

/**
 * Load the Procurated rating for a set of vendors
 * @param {object} [data={}] The set of options to pass to the request context
 * @return {Promise<object[]>} The fetched set of ratings
 */
export function loadProcuratedRatings(data = {}) {
    return resourceManager({
        method: 'post',
        url: '/procurated/suppliers',
        requestOptions: { data },
        onStart: onLoadProcuratedRatingsStart,
        onSuccess: onLoadProcuratedRatingsSuccess,
        onFailure: onLoadProcuratedRatingsFailure,
        context: data,
    });
}

import { fromJS } from 'immutable';
import { browserHistory } from '@og-pro-migration-tools/react-router';

import * as checklistAdminActions from '../actions/checklistsAdmin';

const initialStateChecklistEditState = {
    copyError: null,
    copying: false,
    deleteError: null,
    deleting: false,
    loadError: null,
    loading: false,
    templateChecklist: null,
    updateError: null,
    updating: false,
};

const initialState = fromJS({
    ...initialStateChecklistEditState,
    createError: null,
    creating: false,
    loadedList: false,
    loadingList: false,
    loadListError: null,
    templateChecklists: [],
});

export default function checklistsAdminReducer(state = initialState, action = {}) {
    switch (action.type) {
        case checklistAdminActions.COPY_TEMPLATE:
            return state.merge(
                fromJS({
                    copyError: null,
                    copying: true,
                })
            );
        case checklistAdminActions.COPY_TEMPLATE_FAIL:
            return state.merge(
                fromJS({
                    copyError: action.error && action.error.message,
                    copying: false,
                })
            );
        case checklistAdminActions.COPY_TEMPLATE_SUCCESS:
            return state.merge(
                fromJS({
                    copying: false,
                })
            );
        case checklistAdminActions.CREATE:
            return state.merge(
                fromJS({
                    creating: true,
                    createError: null,
                })
            );
        case checklistAdminActions.CREATE_FAIL:
            return state.merge(
                fromJS({
                    creating: false,
                    createError: action.error && action.error.message,
                })
            );
        case checklistAdminActions.CREATE_SUCCESS:
            return state.merge(
                fromJS({
                    creating: false,
                    templateChecklist: fromJS(action.result),
                })
            );
        case checklistAdminActions.DELETE:
            return state.merge(
                fromJS({
                    deleting: true,
                    deleteError: null,
                })
            );
        case checklistAdminActions.DELETE_FAIL:
            return state.merge(
                fromJS({
                    deleting: false,
                    deleteError: action.error && action.error.message,
                })
            );
        case checklistAdminActions.DELETE_SUCCESS:
            if (action.checklistSocketBroadcast) {
                // Navigate away from checklist when someone else deletes it
                // Note: Need to take off event loop so store can complete update before navigating away
                setTimeout(() => {
                    browserHistory.replace(
                        `/governments/${action.result.government_id}/checklists-admin`
                    );
                });
            }
            return state.merge(
                fromJS({
                    deleted: true,
                    deleting: false,
                    templateChecklists: state
                        .get('templateChecklists')
                        .filter((template) => template.get('id') !== action.result.id),
                })
            );
        case checklistAdminActions.LOAD:
            return state.merge(
                fromJS({
                    loading: true,
                    loadError: null,
                })
            );
        case checklistAdminActions.LOAD_FAIL:
            return state.merge(
                fromJS({
                    loading: false,
                    loadError: action.error && action.error.message,
                })
            );
        case checklistAdminActions.LOAD_SUCCESS:
            return state.merge(
                fromJS({
                    loading: false,
                    templateChecklist: fromJS(action.result),
                })
            );
        case checklistAdminActions.LOAD_LIST:
            return state.merge(
                fromJS({
                    loadingList: true,
                    loadListError: null,
                })
            );
        case checklistAdminActions.LOAD_LIST_FAIL:
            return state.merge(
                fromJS({
                    loadingList: false,
                    loadListError: action.error && action.error.message,
                })
            );
        case checklistAdminActions.LOAD_LIST_SUCCESS:
            return state.merge(
                fromJS({
                    loadedList: true,
                    loadingList: false,
                    templateChecklists: fromJS(action.result),
                })
            );
        case checklistAdminActions.RESET_TEMPLATE_EDIT:
            return state.merge(fromJS(initialStateChecklistEditState));
        case checklistAdminActions.RESET_TEMPLATE_LIST:
            return state.merge(
                fromJS({
                    loadedList: false,
                })
            );
        case checklistAdminActions.UPDATE:
            return state.merge(
                fromJS({
                    updating: true,
                    updateError: null,
                })
            );
        case checklistAdminActions.UPDATE_FAIL:
            return state.merge(
                fromJS({
                    updating: false,
                    updateError: action.error && action.error.message,
                })
            );
        case checklistAdminActions.UPDATE_SUCCESS:
            return state.merge(
                fromJS({
                    templateChecklist: fromJS(action.result),
                    updating: false,
                })
            );
        default:
            return state;
    }
}

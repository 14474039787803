import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { positionTypes } from '@og-pro/shared-config/positions';

import { fieldNames, fields } from './constants';
import { EntityPositionForm } from './EntityPositionForm';
import { showSnackbar } from '../../../../../actions/notification';
import { createPosition, updatePosition } from '../../../../../actions/requisitionsAdmin';

const { TYPE } = fieldNames;
const { ENTITY } = positionTypes;

export const EntityPositionsModal = ({ entityPosition, hideModal }) => {
    const [updating, setUpdating] = useState(false);
    const [updateError, setUpdateError] = useState(null);

    const isNew = !entityPosition;

    const dispatch = useDispatch();

    const onSubmit = (formValues) => {
        setUpdating(true);
        setUpdateError(null);

        const submitEntityPositionAction = isNew
            ? dispatch(createPosition(formValues))
            : dispatch(updatePosition(entityPosition.id, formValues));

        return submitEntityPositionAction
            .then(() => {
                hideModal();
                dispatch(showSnackbar(`Entity position ${isNew ? 'added' : 'updated'}`));
            })
            .catch((error) => {
                setUpdating(false);
                setUpdateError(error.message);
            });
    };

    return (
        <Modal onHide={hideModal} show>
            <Modal.Header closeButton>
                <Modal.Title className="text-center">
                    {isNew ? 'Create' : 'Edit'} Entity Position
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-8 col-md-offset-2">
                        <EntityPositionForm
                            initialValues={{
                                [TYPE]: ENTITY,
                                ...pick(entityPosition, fields),
                            }}
                            isNew={isNew}
                            onCancel={hideModal}
                            onSubmit={onSubmit}
                            updateError={updateError}
                            updating={updating}
                        />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

EntityPositionsModal.propTypes = {
    entityPosition: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }),
    hideModal: PropTypes.func.isRequired,
};

import { capitalDesignTokens } from '@opengov/capital-mui-theme';

export const accordionStyle = {
    borderBottom: 0,
    borderLeft: 0,
    borderRight: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
};

export const accordionSummaryStyle = (showMore) => {
    return {
        flexDirection: 'row',
        minHeight: 0,
        borderTopLeftRadius: '0 !important',
        borderTopRightRadius: '0 !important',
        ...(showMore && {
            backgroundColor: capitalDesignTokens.semanticColors.background.secondary,
            borderRadius: 0,
        }),
    };
};

export const accordionSummaryContentStyle = {
    display: 'flex',
    gap: 2,
};

export const accordionDetailsContainerStyle = {
    backgroundColor: capitalDesignTokens.semanticColors.background.secondary,
    display: 'flex !important',
    gap: 2,
    paddingTop: 0.5,
};

export const accordionDetailsStyle = {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: 2,
};

export const checkboxStyle = {
    width: '24px',
};

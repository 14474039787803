import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { formValueSelector } from 'redux-form';

import { form, fieldNames } from '../../../constants';
import { fieldNames as formFieldNames } from '../../../TemplateForm/constants';
import { projectSectionsToNavSections } from '../../../../../../../components/SDv2/helpers';
import { TemplateEditV2NavContext } from '../../../../V2/context';
import { DividerSectionLanding } from '../../../../../../../components/SDv2';

const { USE_MANUAL_NUMBERING } = formFieldNames;
const selector = formValueSelector(form);

export const TemplateDivider = () => {
    const projectSections = useSelector((state) => selector(state, fieldNames.PROJECT_SECTIONS));
    const useManualNumbering = useSelector((state) => selector(state, USE_MANUAL_NUMBERING));
    const { activeSectionId, setActiveSectionId } = useContext(TemplateEditV2NavContext);

    const sections = projectSectionsToNavSections({
        projectSections,
        active: activeSectionId,
        setActive: setActiveSectionId,
        useManualNumbering,
        formErrors: {},
        showFormValidation: false,
    });

    const section = (sections || []).find((s) => s.index === activeSectionId);

    return <DividerSectionLanding section={section} setActiveSectionId={setActiveSectionId} />;
};

import { createSelector } from 'reselect';

export const getContractReqRelationsLoaded = (state) => state.contracts.get('loadedReqRelations');
export const getProjectReqRelationsLoaded = (state) => state.govProjects.get('loadedReqRelations');

const getContractReqRelations = (state) => state.contracts.get('reqRelations');
const getProjectReqRelations = (state) => state.govProjects.get('reqRelations');

export const getContractRequisitions = createSelector([getContractReqRelations], (reqRelations) => {
    return reqRelations.toJS().map(({ requisition }) => requisition);
});

export const getProjectRequisitions = createSelector([getProjectReqRelations], (reqRelations) => {
    return reqRelations.toJS().map(({ requisition }) => requisition);
});

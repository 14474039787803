import { proposalCreateWarn as warn } from './validate';

/**
 * `warn` is used to mark a questionnaire response as incomplete without failing validation.
 * This allows us to provide UI feedback on what is incomplete vs what is invalid.
 * Validation will only fail if the response is incomplete and required.
 */
export const formConfig = {
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    warn,
};

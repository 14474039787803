import { createSelector } from 'reselect';

const getProposalsList = (state) => state.proposalEvaluations.get('compareProposals');
const getQuestionnairesList = (state) => state.proposalEvaluations.get('compareQuestionnaires');

export const getProposalsListJS = createSelector([getProposalsList], (proposals) =>
    proposals.toJS()
);

export const getQuestionnairesListJS = createSelector([getQuestionnairesList], (questionnaires) =>
    questionnaires.toJS()
);

import { useMemo } from 'react';

import { useSearchParams } from 'react-router-dom';

import { chartInteractionParamsDict } from '../helpers/chartInteractions';

const { OLDER_THAN, STATUS, TYPE } = chartInteractionParamsDict;

export const useChartInteraction = () => {
    const [searchParams] = useSearchParams();

    const params = Object.fromEntries(searchParams);

    const hasChartInteraction = useMemo(() => {
        return !!params[OLDER_THAN] || !!params[STATUS] || !!params[TYPE];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params[OLDER_THAN], params[STATUS], params[TYPE]]);

    return {
        hasChartInteraction,
    };
};

export const NAV_ITEMS = [
    {
        label: 'Form Builder',
        path: '/form',
    },
    {
        label: 'Sequences',
        path: '/sequences',
    },
    {
        label: 'Groups',
        path: '/review-groups',
    },
];

import PropTypes from 'prop-types';
import { tokens } from '@opengov/capital-style';
import React from 'react';
import { reduxForm } from 'redux-form';
import { Box } from '@og-pro/ui';

import { form } from './constants';
import { validate } from './validate';
import { DocxTemplateFormFieldset } from './Fieldset';

const formConfig = {
    form,
    validate,
};

// @reduxForm
const ConnectedDocxTemplateForm = (props) => {
    const { cancelHandler, disabled, handleSubmit, initialValues, uploadError } = props;

    return (
        <Box border={1} borderColor={tokens.colors.colorGray200} p={2}>
            <form className="row" onSubmit={handleSubmit}>
                <DocxTemplateFormFieldset
                    cancelHandler={cancelHandler}
                    disabled={disabled}
                    initialValues={initialValues}
                    uploadError={uploadError}
                />
            </form>
        </Box>
    );
};

ConnectedDocxTemplateForm.propTypes = {
    cancelHandler: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    uploadError: PropTypes.string,
};

export const DocxTemplateForm = reduxForm(formConfig)(ConnectedDocxTemplateForm);

import { ALL_SECTIONS, formSectionNames } from '../constants';
import validateExceptions from './validateExceptions';
import validateInfo from './validateInfo';
import validateSteps from './validateSteps';
import { sectionsValidate } from '../../../../../../Forms/validation';
import { hasFormErrors } from '../../../../../../helpers';

const { EXCEPTIONS, INFO, STEPS } = formSectionNames;

export const validate = (values) => {
    const infoErrors = validateInfo(values);
    const stepsErrors = validateSteps(values);
    const exceptionErrors = validateExceptions(values);

    const sectionErrors = sectionsValidate(
        [INFO, infoErrors],
        [STEPS, stepsErrors],
        [EXCEPTIONS, exceptionErrors]
    );

    return {
        [ALL_SECTIONS]: hasFormErrors(sectionErrors),
        ...infoErrors,
        ...stepsErrors,
        ...exceptionErrors,
        ...sectionErrors,
    };
};

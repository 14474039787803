import classnames from 'classnames';
import { kebabCase } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Collapse, CDSButton, HelpIcon } from '..';

export class DisplaySection extends PureComponent {
    static propTypes = {
        afterItemsElement: PropTypes.node,
        header: PropTypes.string.isRequired,
        helpMessage: PropTypes.string,
        items: PropTypes.arrayOf(
            PropTypes.shape({
                icon: PropTypes.string,
                label: PropTypes.string.isRequired,
                value: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
            })
        ).isRequired,
        preventCollapse: PropTypes.bool,
        zeroState: PropTypes.string,
    };

    static defaultProps = {
        preventCollapse: false,
    };

    get styles() {
        return require('./index.scss');
    }

    constructor(props) {
        super(props);
        this.state = { show: true };
    }

    toggleDisplay = () => {
        this.setState((prevState) => ({ show: !prevState.show }));
    };

    render() {
        const { afterItemsElement, header, helpMessage, items, preventCollapse, zeroState } =
            this.props;

        const { show } = this.state;

        return (
            <div className={this.styles.section} id={kebabCase(header)}>
                <CDSButton
                    className={classnames(
                        this.styles.sectionHeader,
                        !preventCollapse && this.styles.toggleHeader
                    )}
                    noPadding
                    onClick={!preventCollapse ? this.toggleDisplay : undefined}
                    size="small"
                    variant="text"
                >
                    {header}
                    {helpMessage && <HelpIcon tooltip={helpMessage} />}
                    {!preventCollapse && (
                        <i className={`fa fa-angle-${show ? 'down' : 'left'} pull-right`} />
                    )}
                </CDSButton>
                <Collapse in={show}>
                    {items.length === 0 && (
                        <div
                            className={classnames(
                                this.styles.sectionItem,
                                this.styles.lastItem,
                                'text-muted'
                            )}
                        >
                            <em>{zeroState}</em>
                        </div>
                    )}
                    {items.map(({ icon, label, value }, idx) => (
                        <div
                            className={classnames(this.styles.sectionItem, {
                                [this.styles.lastItem]: idx === items.length - 1,
                            })}
                            key={idx}
                        >
                            <div className="row">
                                <div className={`col-md-5 ${this.styles.label}`}>
                                    {icon && (
                                        <span className={this.styles.labelIcon}>
                                            <i className={`fa fa-fw fa-${icon}`} />
                                            &nbsp;
                                        </span>
                                    )}
                                    {label}
                                </div>
                                <div className="col-md-7">{value}</div>
                            </div>
                        </div>
                    ))}
                    {afterItemsElement}
                </Collapse>
            </div>
        );
    }
}

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { EvaluationCriteria } from './EvaluationCriteria';
import { getEvaluationPhases } from '../selectors';
import { showConfirmationSimpleModal } from '../../../../actions/confirmation';

const mapStateToProps = (state) => {
    return {
        evaluationPhases: getEvaluationPhases(state),
    };
};

const mapDispatchToProps = {
    showConfirmationModal: showConfirmationSimpleModal,
};

/**
 * The single phase version of evaluation criteria. For all practical purposes can think of this as
 * a flat evaluation criteria with no phasing.
 */

// @connect
class ConnectedEvaluationCriteriaBuilder extends Component {
    render() {
        return <EvaluationCriteria {...this.props} isMulti={false} />;
    }
}

export const EvaluationCriteriaBuilder = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedEvaluationCriteriaBuilder);

import { Button, ButtonGroup } from '@og-pro/ui';

import {
    Flip as FlipIcon,
    RotateLeft as RotateLeftIcon,
    RotateRight as RotateRightIcon,
} from '@mui/icons-material';

import React from 'react';

import PropTypes from 'prop-types';

export const RotateNMirrorControls = ({ disabled, DWObject }) => {
    const styles = require('../index.scss');

    return (
        <>
            {' '}
            <ButtonGroup disabled={disabled} variant="outlined">
                <Button
                    onClick={() => {
                        DWObject.RotateLeftAsync(DWObject.CurrentImageIndexInBuffer);
                    }}
                    qaTag="scanning-rotateLeft"
                >
                    <RotateLeftIcon />
                </Button>
                <Button
                    onClick={() => {
                        DWObject.RotateRightAsync(DWObject.CurrentImageIndexInBuffer);
                    }}
                    qaTag="scanning-rotateRight"
                >
                    <RotateRightIcon />
                </Button>
            </ButtonGroup>
            <ButtonGroup disabled={disabled} variant="outlined">
                <Button
                    className={styles.flipButton}
                    onClick={() => {
                        DWObject.FlipAsync(DWObject.CurrentImageIndexInBuffer);
                    }}
                    qaTag="scanning-flip"
                >
                    <FlipIcon />
                </Button>
                <Button
                    onClick={() => {
                        DWObject.MirrorAsync(DWObject.CurrentImageIndexInBuffer);
                    }}
                    qaTag="scanning-mirror"
                >
                    <FlipIcon />
                </Button>
            </ButtonGroup>
        </>
    );
};

RotateNMirrorControls.propTypes = {
    disabled: PropTypes.bool,
    DWObject: PropTypes.object,
};

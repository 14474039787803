import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import { fieldNames } from './constants';
import { inviteUser, rejectUserRequest, updateUser } from '../../../../actions/admin';
import { showConfirmationSimpleModal } from '../../../../actions/confirmation';
import { Button, OutlineButton } from '../../../../components';

const { DEPARTMENT_ID, EMAIL, IS_EXTERNAL, ROLE, USER_ROLES } = fieldNames;

export const ExistingUserSubmitButtons = ({
    disabled,
    handleSubmit,
    isRequestForm,
    onHideEditForm,
    pristine,
    user,
}) => {
    const dispatch = useDispatch();

    const handleConfirmUserRequest = (formData) => {
        const data = {
            ...pick(formData, [DEPARTMENT_ID, IS_EXTERNAL, ROLE, USER_ROLES]),
            [EMAIL]: user.email,
        };

        dispatch(inviteUser(user.id, data, { existingUser: true }));
    };

    const handleRejectUserRequest = () => {
        dispatch(
            showConfirmationSimpleModal(() => dispatch(rejectUserRequest(user.id)), {
                text: 'Are you sure you want to deny this user access to your organization?',
                icon: 'ban',
                btnText: 'Deny Access',
            })
        );
    };

    const handleSaveUser = (formData) => {
        const data = pick(formData, [DEPARTMENT_ID, IS_EXTERNAL, ROLE, USER_ROLES]);
        dispatch(updateUser(user.id, data, onHideEditForm));
    };

    if (isRequestForm) {
        return (
            <div>
                <Button
                    bsSize="sm"
                    bsStyle="success"
                    disabled={disabled}
                    onClick={handleSubmit(handleConfirmUserRequest)}
                >
                    <i className={`fa ${user.updating ? 'fa-spin fa-spinner' : 'fa-check'}`} />{' '}
                    Approve
                </Button>
                &nbsp;
                <OutlineButton
                    bsSize="sm"
                    bsStyle="danger"
                    disabled={disabled}
                    onClick={handleRejectUserRequest}
                >
                    <i className={`fa ${user.updating ? 'fa-spin fa-spinner' : 'fa-times'}`} />{' '}
                    Reject
                </OutlineButton>
            </div>
        );
    }

    return (
        <div className="text-center">
            <Button
                block
                bsStyle="primary"
                disabled={pristine || disabled}
                onClick={handleSubmit(handleSaveUser)}
            >
                {user.updating ? 'Saving...' : 'Save'}
            </Button>
            <Button bsSize="sm" bsStyle="link" disabled={disabled} onClick={onHideEditForm}>
                Cancel
            </Button>
        </div>
    );
};

ExistingUserSubmitButtons.propTypes = {
    disabled: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    isRequestForm: PropTypes.bool,
    pristine: PropTypes.bool,
    onHideEditForm: PropTypes.func.isRequired,
    user: PropTypes.shape({
        id: PropTypes.number.isRequired,
        email: PropTypes.string.isRequired,
        updating: PropTypes.bool,
    }).isRequired,
};

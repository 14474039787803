import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export class AttachmentFileItemHeader extends PureComponent {
    static propTypes = {
        hideAppendixLetter: PropTypes.bool,
    };

    render() {
        const { hideAppendixLetter } = this.props;
        const titleColWidth = hideAppendixLetter ? 10 : 7;
        return (
            <div className="row">
                <div className="col-xs-10">
                    <div className="row">
                        <div className="col-xs-2" />
                        {!hideAppendixLetter && (
                            <div className="col-xs-3">
                                <b>Attachment ID</b>
                            </div>
                        )}
                        <div className={`col-xs-${titleColWidth}`}>
                            <b>Title</b>
                        </div>
                    </div>
                </div>
                <div className="col-xs-2" />
            </div>
        );
    }
}

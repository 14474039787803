import { fromJS } from 'immutable';

import * as appActions from '../actions/app';
import * as vendProposalActions from '../actions/vendProposals';

// Would like to use this import, but it causes a circular dependency, so using string value instead
// Including the import as a comment so it can be discovered in searches
// import * as projectCreateActions from '../actions/project/create/projectCreate';
const projectCreateActions = {
    RESET: 'projectCreate/RESET',
};

const imageUploadModalInitialState = {
    uploadingImage: false,
    uploadImageError: null,
};

const insertImageModalInitialState = {
    insertImageModalData: null,
    showInsertImageModal: false,
};

const insertTagModalInitialState = {
    insertTagModalData: null,
    showInsertTagModal: false,
};

const insertTemplateVariableModalInitialState = {
    insertTemplateVariableModalData: null,
    showInsertTemplateVariableModal: false,
};

const saveModalInitialState = {
    showSaveModal: false,
    showSaveSuccess: false,
    showSaveError: null,
};

const initialState = fromJS({
    ...imageUploadModalInitialState,
    ...insertImageModalInitialState,
    ...insertTagModalInitialState,
    ...insertTemplateVariableModalInitialState,
    ...saveModalInitialState,
    initialClientRenderComplete: false,
    showConditionalLogicModal: false,
    showCustomEmailModal: false,
    showPartnerResourcesModal: false,
    showRefreshModal: false,
    showSuggestedContentModal: false,
    isTinyMceModalOpen: false,
});

export default function appReducer(state = initialState, action = {}) {
    switch (action.type) {
        case appActions.CONDITIONAL_LOGIC_MODAL_HIDE:
            return state.merge(
                fromJS({
                    conditionalLogicModalData: null,
                    showConditionalLogicModal: false,
                })
            );
        case appActions.CONDITIONAL_LOGIC_MODAL_SHOW:
            return state.merge(
                fromJS({
                    conditionalLogicModalData: fromJS(action.data),
                    showConditionalLogicModal: true,
                })
            );
        case appActions.TINY_MCE_MODAL_IS_OPEN:
            return state.set('isTinyMceModalOpen', action.payload);
        case appActions.HIDE_INSERT_IMAGE_MODAL:
            return state.merge(fromJS(insertImageModalInitialState));
        case appActions.HIDE_INSERT_TAG_MODAL:
            return state.merge(fromJS(insertTagModalInitialState));
        case appActions.HIDE_INSERT_TEMPLATE_VARIABLE_MODAL:
            return state.merge(fromJS(insertTemplateVariableModalInitialState));
        case appActions.HIDE_PARTNER_RESOURCES_MODAL:
            return state.set('showPartnerResourcesModal', false);
        case appActions.HIDE_REFRESH_MODAL:
            return state.set('showRefreshModal', false);
        case appActions.HIDE_SUGGESTED_CONTENT_MODAL:
            return state.set('showSuggestedContentModal', false);
        case appActions.INITIAL_CLIENT_RENDER_COMPLETE:
            return state.set('initialClientRenderComplete', true);
        case appActions.SET_APP_VERSION:
            return state.set('appVersion', action.appVersion);
        case appActions.SHOW_INSERT_IMAGE_MODAL:
            return state.merge(
                fromJS({
                    insertImageModalData: fromJS(action.data),
                    showInsertImageModal: true,
                })
            );
        case appActions.SHOW_INSERT_TAG_MODAL:
            return state.merge(
                fromJS({
                    insertTagModalData: fromJS(action.data),
                    showInsertTagModal: true,
                })
            );
        case appActions.SHOW_INSERT_TEMPLATE_VARIABLE_MODAL:
            return state.merge(
                fromJS({
                    insertTemplateVariableModalData: fromJS(action.data),
                    showInsertTemplateVariableModal: true,
                })
            );
        case appActions.SHOW_PARTNER_RESOURCES_MODAL:
            return state.set('showPartnerResourcesModal', true);
        case appActions.SHOW_REFRESH_MODAL:
            return state.set('showRefreshModal', true);
        case appActions.SHOW_SUGGESTED_CONTENT_MODAL:
            return state.set('showSuggestedContentModal', true);
        case appActions.SHOW_SAVE_MODAL:
            return state.set('showSaveModal', true);
        case appActions.HIDE_SAVE_MODAL:
        case projectCreateActions.RESET:
        case vendProposalActions.RESET_PROPOSAL:
            return state.merge(fromJS(saveModalInitialState));
        case appActions.SHOW_SAVE_SUCCESS:
            return state.set('showSaveSuccess', action.show);
        case appActions.SHOW_SAVE_ERROR:
            return state.set('showSaveError', action.error);
        case appActions.TOGGLE_CUSTOM_EMAIL_MODAL:
            return state.set('showCustomEmailModal', !state.get('showCustomEmailModal'));
        case appActions.UPLOAD_IMAGE:
            return state.merge(
                fromJS({
                    uploadingImage: true,
                    uploadImageError: null,
                })
            );
        case appActions.UPLOAD_IMAGE_FAIL:
            return state.merge(
                fromJS({
                    uploadingImage: false,
                    uploadImageError: action.error ? action.error.message : 'Error uploading image',
                })
            );
        case appActions.UPLOAD_IMAGE_SUCCESS:
            return state.merge(
                fromJS({
                    uploadingImage: false,
                })
            );
        default:
            return state;
    }
}

import { statePostalAbbreviations } from '@og-pro/shared-config/global';

export const stateOptions = statePostalAbbreviations.map(({ name, postalAbbreviation }) => {
    return {
        label: name,
        value: postalAbbreviation,
    };
});

export const statePostalOptions = statePostalAbbreviations.map(({ postalAbbreviation }) => {
    return {
        label: postalAbbreviation,
        value: postalAbbreviation,
    };
});

import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ListGroup, ListGroupItem, Panel } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createSearchParams } from 'react-router-dom';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { AddendumsAcknowledgements } from './AddendumsAcknowledgements';
import { ClassificationReport } from './ClassificationReport';
import { PlanholdersToggleForm } from './PlanholdersToggleForm';
import {
    getClassificationGovliaJS,
    getClassificationUsersJS,
    getMaxVendorInteractionCount,
    getProjectViewCount,
    getShowCustomEmailModal,
    getVendorEmailAudits,
    getVendorNotifiedUsers,
    getVendorPlanholders,
    getVendorStats,
    getVendorUserData,
} from './selectors';
import { VendorNotifiedTable } from './VendorNotifiedTable';
import { VendorStat } from './VendorStat';
import { VendorUserStatsTable } from './VendorUserStatsTable';
import { VendorUserFilter } from './VendorUserFilter';
import { getReviewProjectJS, isSourcingEditor } from '../../selectors';
import connectData from '../../../ConnectData';
import {
    loadClassificationReport,
    loadEmailAudit,
    loadEmailAudits,
    loadProjectVendorData,
    resetVendorAnalytics,
} from '../../../../actions/projectPost';
import { showExpandedSupplierNetworkModal } from '../../../../actions/govProjects/preInvite';
import { showVendorProfileModal } from '../../../../actions/vendorProfile';
import { toggleCustomEmailModal } from '../../../../actions/app';
import {
    Button,
    ButtonGroup,
    CustomEmailModal,
    LoadingError,
    LoadingSpinner,
    Main,
    Nav,
    NavItem,
    PlanholdersList,
    Print,
    SectionTitle,
    Tooltip,
    VendorEmailAuditsTable,
    ZeroState,
} from '../../../../components';

const filterVendors = (user, search) => {
    if (!search) {
        return true;
    }

    return [get(user, 'email'), get(user, 'fullName'), get(user, 'organization.name')].some(
        (data) => data && data.toLowerCase().includes(search.toLowerCase())
    );
};

function fetchData(getState, dispatch, location, params) {
    if (!getState().projectPost.get('loadedVendorData')) {
        const projectId = Number.parseInt(params.projectId, 10);
        return dispatch(loadProjectVendorData(projectId));
    }
}

const mapStateToProps = (state) => {
    return {
        classificationGovlia: getClassificationGovliaJS(state),
        classificationUsers: getClassificationUsersJS(state),
        customEmailModalOpen: getShowCustomEmailModal(state),
        isEditor: isSourcingEditor(state),
        loading: state.projectPost.get('loadingVendorData'),
        loadError: state.projectPost.get('loadVendorDataError'),
        loadedVendorClassificationUsers: state.projectPost.get('loadedVendorClassificationUsers'),
        loadedVendorEmailAudits: state.projectPost.get('loadedVendorEmailAudits'),
        maxInteractions: getMaxVendorInteractionCount(state),
        project: getReviewProjectJS(state),
        vendorEmailAudits: getVendorEmailAudits(state),
        vendorNotifiedUsers: getVendorNotifiedUsers(state),
        vendorPlanholders: getVendorPlanholders(state),
        vendorStats: getVendorStats(state),
        vendorUsers: getVendorUserData(state),
        viewCount: getProjectViewCount(state),
    };
};

const mapDispatchToProps = {
    loadClassificationReport,
    loadEmailAudit,
    loadEmailAudits,
    resetVendorAnalytics,
    showExpandedSupplierNetworkModal,
    showVendorProfileModal,
    toggleCustomEmailModal,
};

// @connectData
// @connect
class ConnectedProjectVendorAnalytics extends Component {
    static propTypes = {
        classificationGovlia: PropTypes.object,
        classificationUsers: PropTypes.array.isRequired,
        customEmailModalOpen: PropTypes.bool.isRequired,
        isEditor: PropTypes.bool.isRequired,
        loadClassificationReport: PropTypes.func.isRequired,
        loadedVendorClassificationUsers: PropTypes.bool.isRequired,
        loadedVendorEmailAudits: PropTypes.bool.isRequired,
        loadEmailAudit: PropTypes.func.isRequired,
        loadEmailAudits: PropTypes.func.isRequired,
        loadError: PropTypes.string,
        loading: PropTypes.bool,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
            query: PropTypes.shape({
                tab: PropTypes.string,
            }).isRequired,
        }).isRequired,
        maxInteractions: PropTypes.number.isRequired,
        project: PropTypes.shape({
            government: PropTypes.shape({
                id: PropTypes.number,
                hidePublicProposalSubmit: PropTypes.bool.isRequired,
            }).isRequired,
            id: PropTypes.number.isRequired,
            showPlanholders: PropTypes.bool.isRequired,
        }),
        resetVendorAnalytics: PropTypes.func.isRequired,
        showExpandedSupplierNetworkModal: PropTypes.func.isRequired,
        showVendorProfileModal: PropTypes.func.isRequired,
        toggleCustomEmailModal: PropTypes.func.isRequired,
        vendorEmailAudits: PropTypes.array.isRequired,
        vendorNotifiedUsers: PropTypes.array.isRequired,
        vendorPlanholders: PropTypes.array.isRequired,
        vendorStats: PropTypes.array.isRequired,
        vendorUsers: PropTypes.array.isRequired,
        viewCount: PropTypes.number.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            filteredVendorEmailAudits: undefined,
            filteredVendorUsers: undefined,
            search: undefined,
            showFilter: false,
        };
    }

    componentWillUnmount() {
        this.props.resetVendorAnalytics();
    }

    get styles() {
        return require('./index.scss');
    }

    createVendorStatsRef = (ref) => {
        this.vendorStats = ref;
    };

    filterVendors = (data) => {
        const { vendorEmailAudits, vendorNotifiedUsers, vendorPlanholders, vendorUsers } =
            this.props;

        const { search } = data;

        this.setState({
            filteredVendorEmailAudits: vendorEmailAudits.filter((audit) => {
                return filterVendors(audit.user || audit.pendingUser, search);
            }),
            filteredVendorNotifiedUsers: vendorNotifiedUsers.filter((user) => {
                return filterVendors(user, search);
            }),
            filteredVendorPlanholders: vendorPlanholders.filter((user) => {
                return filterVendors(user, search);
            }),
            filteredVendorUsers: vendorUsers.filter((user) => {
                return filterVendors(user, search);
            }),
            search,
        });
    };

    loadClassificationReport = () => {
        const { project } = this.props;

        return this.props.loadClassificationReport(project.id);
    };

    loadEmailAudit = (emailUuid) => {
        const { project } = this.props;

        return this.props.loadEmailAudit(project.id, emailUuid);
    };

    loadEmailAudits = () => {
        const { project } = this.props;

        return this.props.loadEmailAudits(project.id);
    };

    toggleFilterForm = () => this.setState((prevState) => ({ showFilter: !prevState.showFilter }));

    vendorStatsRef = () => {
        return this.vendorStats;
    };

    inviteVendors = () => {
        const { showExpandedSupplierNetworkModal: supplierNetworkModal } = this.props;

        return supplierNetworkModal();
    };

    renderVendorFilter() {
        const { search } = this.state;

        return (
            <div className={this.styles.vendorFilter}>
                <VendorUserFilter initialValues={{ search }} onChange={this.filterVendors} />
            </div>
        );
    }

    renderPrintButton() {
        return (
            <Button bsSize="sm">
                <i className="fa fa-lg fa-print" /> Print
            </Button>
        );
    }

    renderEmailButton() {
        const { isEditor } = this.props;

        if (!isEditor) {
            return null;
        }

        return (
            <Button
                bsSize="small"
                bsStyle="default"
                key="customEmailButton"
                onClick={() => this.props.toggleCustomEmailModal()}
            >
                <i className="fa fa-envelope" /> Send an Email to this List
            </Button>
        );
    }

    renderAddendaAcknowledgements() {
        const { project } = this.props;

        return <AddendumsAcknowledgements projectId={project.id} />;
    }

    renderNotifiedTab(notifiedUsers) {
        const { loadedVendorEmailAudits } = this.props;

        return (
            <div className="row">
                <div className="col-lg-offset-1 col-lg-10">
                    <VendorNotifiedTable
                        emailButton={this.renderEmailButton()}
                        loadEmailAudits={this.loadEmailAudits}
                        loaded={loadedVendorEmailAudits}
                        notifiedUsers={notifiedUsers}
                    />
                </div>
            </div>
        );
    }

    renderClassificationTab() {
        const { classificationGovlia, classificationUsers, loadedVendorClassificationUsers } =
            this.props;

        return (
            <div className="row">
                <div className="col-lg-offset-1 col-lg-10">
                    <ClassificationReport
                        govliaClassifications={classificationGovlia}
                        loadClassificationReport={this.loadClassificationReport}
                        loaded={loadedVendorClassificationUsers}
                        users={classificationUsers}
                    />
                </div>
            </div>
        );
    }

    renderEmailTab() {
        const { loadedVendorEmailAudits, vendorEmailAudits } = this.props;

        const { filteredVendorEmailAudits } = this.state;

        const emailAudits = filteredVendorEmailAudits || vendorEmailAudits;

        return (
            <div className="row">
                <div className={`col-lg-offset-1 col-lg-10 ${this.styles.emailAuditsContainer}`}>
                    <VendorEmailAuditsTable
                        emailAudits={emailAudits}
                        loadEmailAudit={this.loadEmailAudit}
                        loadEmailAudits={this.loadEmailAudits}
                        loaded={loadedVendorEmailAudits}
                    />
                </div>
            </div>
        );
    }

    renderPlanholdersList(planholders) {
        const { isEditor, project } = this.props;

        return (
            <div className={this.styles.emailAuditsContainer}>
                {isEditor && (
                    <div className="no-print text-right">
                        <PlanholdersToggleForm project={project} />
                    </div>
                )}
                {planholders.length === 0 ? (
                    <ZeroState title="No followers found" />
                ) : (
                    <PlanholdersList
                        emailButton={this.renderEmailButton()}
                        planholders={planholders}
                        showCustomEmailModal={this.props.toggleCustomEmailModal}
                        showSubmittedProposals={!project.government.hidePublicProposalSubmit}
                        title={`Followers List (${planholders.length})`}
                    />
                )}
            </div>
        );
    }

    renderStatsTab() {
        const {
            maxInteractions,
            showVendorProfileModal: showVendorModal,
            vendorStats,
            vendorUsers,
            viewCount,
        } = this.props;

        const { filteredVendorUsers } = this.state;

        const hasVendorUserData = vendorUsers.length > 0;
        const users = filteredVendorUsers || vendorUsers;

        if (!hasVendorUserData && viewCount === 0) {
            return (
                <h4 className={`text-muted ${this.styles.noDataTitle}`}>
                    No vendor data recorded yet
                </h4>
            );
        }

        return (
            <div className="row">
                <div className="col-md-4 col-lg-3">
                    <Panel className={this.styles.vendorStats}>
                        <ListGroup>
                            <ListGroupItem className="text-center">
                                <h4>Project View Count</h4>
                                <Tooltip
                                    delayShow={200}
                                    tooltip="Total number of public views the project has received"
                                    tooltipId="projectViewCount"
                                >
                                    <span className={this.styles.viewCount}>{viewCount}</span>
                                </Tooltip>
                            </ListGroupItem>
                            <ListGroupItem>
                                <h4 className="text-center">Vendor Funnel</h4>
                                {vendorStats.map((data) => (
                                    <VendorStat
                                        key={data.name}
                                        maxInteractions={maxInteractions}
                                        {...data}
                                    />
                                ))}
                            </ListGroupItem>
                        </ListGroup>
                    </Panel>
                </div>
                <div className="col-md-8 col-lg-9">
                    <VendorUserStatsTable showVendorProfileModal={showVendorModal} users={users} />
                </div>
            </div>
        );
    }

    render() {
        const {
            customEmailModalOpen,
            loading,
            loadError,
            location: {
                pathname,
                query: { tab },
            },
            project,
            vendorNotifiedUsers,
            vendorPlanholders,
        } = this.props;

        const { filteredVendorNotifiedUsers, filteredVendorPlanholders } = this.state;

        const notifiedUsers = filteredVendorNotifiedUsers || vendorNotifiedUsers;
        const planholders = filteredVendorPlanholders || vendorPlanholders;

        const { showFilter } = this.state;

        if (loading) {
            return <LoadingSpinner />;
        }

        if (loadError || !project) {
            return <LoadingError error={loadError} />;
        }

        const navItems = [
            { title: 'Vendor Stats' },
            { title: `Followers (${project.showPlanholders ? 'Public' : 'Private'})` },
            { title: 'Addenda Acknowledgements' },
            { title: 'Notified' },
            { title: 'Classification Report' },
            { title: 'Email Log' },
        ];

        const activeTab = Number.parseInt(tab || 1, 10);

        const getCustomEmailUserEmails = () => {
            if (activeTab === 2) {
                return planholders.map((planholder) => planholder.email);
            }
            if (activeTab === 4) {
                return notifiedUsers.map((notifiedUser) => notifiedUser.email);
            }
            return [];
        };

        return (
            <Main>
                <SectionTitle info="Vendor project interaction data" title="Vendor Analytics" />
                <div className="row">
                    <div className="col-sm-offset-6 col-sm-6 text-right">
                        <ButtonGroup bsSize="sm">
                            <Button bsStyle="primary" onClick={() => this.inviteVendors()}>
                                <i className="fa fa-envelope" /> Invite Vendors
                            </Button>
                            <Button active={showFilter} onClick={this.toggleFilterForm}>
                                <i className="fa fa-search" /> Search
                            </Button>
                        </ButtonGroup>
                        &nbsp;
                        <span className={this.styles.printContainer}>
                            <Print
                                content={this.vendorStatsRef}
                                removeAfterPrint
                                trigger={this.renderPrintButton}
                            />
                        </span>
                        {showFilter && this.renderVendorFilter()}
                    </div>
                </div>
                <Nav bsStyle="tabs" className={this.styles.tabsNav}>
                    {navItems.map((item, index) => (
                        <NavItem
                            className={activeTab - 1 === index ? 'active' : undefined}
                            key={index}
                            overrideIsActive={false}
                            qaTag={`project-${item.title}`}
                            to={{
                                pathname,
                                search: createSearchParams({ tab: index + 1 }).toString(),
                            }}
                        >
                            {item.title}
                        </NavItem>
                    ))}
                </Nav>
                <div ref={this.createVendorStatsRef}>
                    {activeTab === 1 && this.renderStatsTab()}
                    {activeTab === 2 && this.renderPlanholdersList(planholders)}
                    {activeTab === 3 && this.renderAddendaAcknowledgements()}
                    {activeTab === 4 && this.renderNotifiedTab(notifiedUsers)}
                    {activeTab === 5 && this.renderClassificationTab()}
                    {activeTab === 6 && this.renderEmailTab()}
                </div>
                {customEmailModalOpen && (
                    <CustomEmailModal
                        data={{
                            projectId: project.id,
                            userEmails: getCustomEmailUserEmails(),
                        }}
                        hideModal={this.props.toggleCustomEmailModal}
                    />
                )}
            </Main>
        );
    }
}

export const ProjectVendorAnalytics = compose(
    connectData(fetchData),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedProjectVendorAnalytics);

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { subsectionTypeNames } from '@og-pro/shared-config/subsections';

import { SectionIntro } from '../SectionIntro';
import { ProjectDetailTitle } from '../..';
import { PriceTable, PriceTableDocx } from '../../..';

const { BODY } = subsectionTypeNames;

export class ProjectPricingDetail extends PureComponent {
    static propTypes = {
        auctionMaxFractionDigits: PropTypes.number,
        description: PropTypes.object.isRequired,
        isDocx: PropTypes.bool,
        isEditable: PropTypes.bool,
        isReverseAuction: PropTypes.bool,
        isRevisionsDiff: PropTypes.bool,
        priceTables: PropTypes.array.isRequired,
        projectSection: PropTypes.object.isRequired,
        projectSubsectionsMap: PropTypes.object.isRequired,
        reviewRoute: PropTypes.string.isRequired,
        salesTax: PropTypes.number,
        section: PropTypes.string.isRequired,
        sectionDescActions: PropTypes.object,
        showComments: PropTypes.bool,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
        title: PropTypes.string,
        useManualNumbering: PropTypes.bool,
        useSectionDividers: PropTypes.bool,
    };

    static defaultProps = {
        isDocx: false,
        isEditable: false,
        isRevisionsDiff: false,
        sectionDescActions: undefined,
        showComments: false,
        tagOptions: undefined,
        templateVariableOptions: undefined,
        title: 'Pricing Response',
    };

    renderPricingTables() {
        const {
            auctionMaxFractionDigits,
            isDocx,
            isReverseAuction,
            isRevisionsDiff,
            priceTables,
            salesTax,
        } = this.props;

        if (priceTables.length === 0) {
            return (
                <div className="text-center price-table-container">
                    <em>No pricing table has been provided.</em>
                </div>
            );
        }

        return priceTables.map((priceTable) => {
            // Need to use basic table for generating docx or rendering a diff
            if (isDocx || isRevisionsDiff) {
                return (
                    <div className="price-table-container" key={priceTable.id}>
                        <PriceTableDocx
                            auctionMaxFractionDigits={auctionMaxFractionDigits}
                            isReverseAuction={isReverseAuction}
                            priceTable={priceTable}
                            priceTableClassName={
                                isRevisionsDiff ? 'price-table-centered' : undefined
                            }
                            salesTax={salesTax}
                        />
                    </div>
                );
            }

            return (
                <div className="price-table-container" key={priceTable.id}>
                    <PriceTable
                        auctionMaxFractionDigits={auctionMaxFractionDigits}
                        isReverseAuction={isReverseAuction}
                        priceTable={priceTable}
                        readOnly
                        salesTax={salesTax}
                    />
                </div>
            );
        });
    }

    render() {
        const {
            description,
            isDocx,
            isEditable,
            projectSection,
            projectSubsectionsMap,
            sectionDescActions,
            showComments,
            tagOptions,
            templateVariableOptions,
            title,
            useManualNumbering,
            useSectionDividers,
        } = this.props;

        const projectSubsectionId = projectSubsectionsMap[BODY].id;

        return (
            <div className="col-xs-12 col-md-offset-1 col-md-10 pricing-section">
                <ProjectDetailTitle
                    isDocx={isDocx}
                    projectSection={projectSection}
                    projectSubsectionId={projectSubsectionId}
                    showComments={showComments}
                    title={title}
                    useManualNumbering={useManualNumbering}
                    useSectionDividers={useSectionDividers}
                />
                <SectionIntro
                    {...sectionDescActions}
                    isDocx={isDocx}
                    isEditable={isEditable}
                    projectSectionId={projectSection.id}
                    projectSubsectionId={projectSubsectionId}
                    sectionDescription={description[projectSubsectionId]}
                    tagOptions={tagOptions}
                    templateVariableOptions={templateVariableOptions}
                />
                {this.renderPricingTables()}
            </div>
        );
    }
}

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button } from '@og-pro/ui';
import { Add as AddIcon } from '@mui/icons-material';

import { StyledDropzone } from '../../../../components';
import {
    uploadAndCreateContractAttachment,
    updateContractAttachment,
} from '../../../../actions/contracts';
import { getContractJS } from '../../../selectors';
import { ModalAttachment } from './ModalAttachment';
import { fieldNames } from '../constants';

const { FILENAME, TAGS } = fieldNames;

const ActionsButtons = ({ handleClose, invalid, submitting }) => {
    return (
        <Box alignItems="center" display="flex" justifyContent="flex-end" mt={4}>
            <Box mr={1}>
                <Button
                    disabled={submitting}
                    onClick={handleClose}
                    qaTag="contractAttachments-cancel"
                    variant="text"
                >
                    Cancel
                </Button>
            </Box>
            <Box>
                <Button
                    disabled={invalid || submitting}
                    qaTag="contractAttachments-save"
                    type="submit"
                    variant="contained"
                >
                    Add
                </Button>
            </Box>
        </Box>
    );
};
ActionsButtons.propTypes = {
    handleClose: PropTypes.func.isRequired,
    invalid: PropTypes.bool.isRequired,
    submitting: PropTypes.bool,
};

export const UploadAttachmentButton = ({ onUploadSuccess }) => {
    const dispatch = useDispatch();
    const contract = useSelector(getContractJS);
    const [file, setFile] = useState(null);

    const styles = require('../index.scss');

    const dropHandler = useCallback((selectedFiles) => {
        if (selectedFiles.length) {
            setFile(selectedFiles[0]);
        }
    }, []);

    const onSubmit = async (values) => {
        // this action takes care of getting the signed url, uploading the file and saving the attachment to the database
        // returns a database instance of an attachment with all the usual fields
        const attachment = await dispatch(uploadAndCreateContractAttachment(contract.id, file));

        if (!attachment) {
            // there was an error and the redux action takes care of showing a snackbar
            return setFile(null);
        }

        // once we have an attachment we will update the values of the fields from the form
        let tags = attachment[TAGS] || [];
        if (values[TAGS]) {
            tags = [values[TAGS]];
        }

        const updatedAttachment = { ...attachment, ...values, tags };

        await dispatch(
            updateContractAttachment(contract.id, updatedAttachment.id, updatedAttachment)
        );

        setFile(null);

        if (onUploadSuccess) {
            onUploadSuccess();
        }
    };

    return (
        // prevents the onClick to going up to the collapsable title
        <Box onClick={(e) => e.stopPropagation()}>
            {!!file && (
                <ModalAttachment
                    actions={ActionsButtons}
                    initialValues={{
                        [FILENAME]: file.name,
                    }}
                    onClose={() => setFile(null)}
                    onSubmit={onSubmit}
                    open
                    title="Add Contract Document"
                />
            )}

            <StyledDropzone
                defaultRejectMessage="File could not be uploaded."
                dropzoneClassName={styles.dropzone}
                dropzoneContainerClassName={styles.dropzoneContainer}
                labelId="Edit Attachment Label"
                // maxSize={MAX_FILE_SIZE}
                multiple={false}
                onDropAccepted={dropHandler}
            >
                <Button
                    onClick={(e) => {
                        e.preventDefault();
                    }}
                    qaTag="contractDocuments-upload"
                    size="small"
                    variant="outlined"
                >
                    <AddIcon fontSize="small" />
                    Upload Other Document
                </Button>
            </StyledDropzone>
        </Box>
    );
};

UploadAttachmentButton.propTypes = {
    onUploadSuccess: PropTypes.func,
};

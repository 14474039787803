import classnames from 'classnames';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSearchParams } from 'react-router-dom';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { compose } from 'redux';

import { purchaseOrderStatuses } from '@og-pro/shared-config/purchaseOrders';

import { FiscalYearSelectForm } from './FiscalYearSelectForm';
import { PurchaseOrderFormModal } from './PurchaseOrderFormModal';
import { PurchaseOrderList } from './PurchaseOrderList';
import { fieldNames } from './PurchaseOrderFormModal/constants';
import {
    getContractBudgetJS,
    getContractLineItemsJS,
    getDefaultFiscalYearTag,
    getFiscalYearTags,
    getSelectedBudgetAllocation,
    getSelectedFiscalYearTag,
    getSelectedPurchaseOrders,
} from './selectors';
import { isContractEditor } from '../selectors';
import connectData from '../../ConnectData';
import { getContractJS, getUserJS } from '../../selectors';
import {
    loadContractBudgetPurchaseOrders,
    loadContractLineItems,
    resetContractBudget,
} from '../../../actions/contracts';
import {
    Button,
    Label,
    LoadingError,
    LoadingSpinner,
    Main,
    SectionTitle,
} from '../../../components';
import { BudgetPurchaseOrdersOverview } from './BudgetPurchaseOrdersOverview';

const { CONTRACT_PARTY_ID, DEPARTMENT_ID, STATUS, TAG_ID, PRICE_ITEM_ID } = fieldNames;

const { REQUESTED } = purchaseOrderStatuses;

function fetchData(getState, dispatch, location, params) {
    const contractId = Number.parseInt(params.contractId, 10);
    const promises = [];

    if (isEmpty(getContractLineItemsJS(getState()))) {
        promises.push(dispatch(loadContractLineItems(contractId)));
    }

    if (!getState().contracts.get('loadedContractBudget')) {
        promises.push(dispatch(loadContractBudgetPurchaseOrders(contractId)));
    }

    return Promise.all(promises);
}

const mapStateToProps = (state, props) => {
    return {
        budget: getContractBudgetJS(state),
        budgetAllocation: getSelectedBudgetAllocation(state, props),
        contract: getContractJS(state),
        defaultFiscalYearTag: getDefaultFiscalYearTag(state),
        fiscalYearTags: getFiscalYearTags(state),
        isEditor: isContractEditor(state),
        loadError: state.contracts.get('loadContractBudgetError'),
        loading: state.contracts.get('loadingContractBudget'),
        purchaseOrders: getSelectedPurchaseOrders(state, props),
        selectedFiscalYearTag: getSelectedFiscalYearTag(state, props),
        user: getUserJS(state),
    };
};

const mapDispatchToProps = {
    resetContractBudget,
};

// @connectData
// @connect
class ConnectedContractSpendManagement extends Component {
    static propTypes = {
        budget: PropTypes.shape({
            amount: PropTypes.number,
            id: PropTypes.number.isRequired,
            issued: PropTypes.number.isRequired,
            paid: PropTypes.number.isRequired,
        }),
        budgetAllocation: PropTypes.shape({
            amount: PropTypes.number.isRequired,
        }),
        contract: PropTypes.shape({
            contractParty: PropTypes.shape({
                id: PropTypes.number.isRequired,
            }).isRequired,
            id: PropTypes.number.isRequired,
        }).isRequired,
        defaultFiscalYearTag: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }).isRequired,
        fiscalYearTags: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
            })
        ).isRequired,
        isEditor: PropTypes.bool,
        loadError: PropTypes.string,
        loading: PropTypes.bool,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
            query: PropTypes.shape({
                'fiscal-year': PropTypes.string,
                modal: PropTypes.string,
            }).isRequired,
        }).isRequired,
        purchaseOrders: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
            })
        ).isRequired,
        resetContractBudget: PropTypes.func.isRequired,
        router: PropTypes.object.isRequired,
        selectedFiscalYearTag: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        }),
        user: PropTypes.shape({
            department_id: PropTypes.number.isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            purchaseOrder: null,
            showPurchaseOrderFormModal:
                !!props.location.query.modal && props.location.query.modal !== 'undefined',
        };
    }

    componentWillUnmount() {
        this.props.resetContractBudget();
    }

    componentDidUpdate() {
        // The current purchase order in state represents which purchase order data is used in the modal
        // This component isn't aware of the new purchase order changes since there is no update to its local state after marking a purchase order as paid/unpaid
        // This will compare the purchaseOrder in state to the corresponding purchase order from the purchaseOrders prop which comes from the redux store
        // If they are different, update state with the new purchase order
        const { purchaseOrder } = this.state;
        const { purchaseOrders } = this.props;
        const updatedPurchaseOrder = purchaseOrders.find((po) => po.id === purchaseOrder?.id);

        if (purchaseOrder !== updatedPurchaseOrder) {
            this.setState({
                purchaseOrder: updatedPurchaseOrder,
            });
        }
    }

    get styles() {
        return require('./index.scss');
    }

    hidePurchaseOrderFormModal = () => {
        const {
            location: { pathname, query },
            router,
        } = this.props;

        // Clear modal visibility and line item selection query params
        router.replace({
            pathname,
            search: createSearchParams({
                ...query,
                'line-item': undefined,
                modal: undefined,
            }).toString(),
        });

        this.setState({
            purchaseOrder: null,
            showPurchaseOrderFormModal: false,
        });
    };

    selectFiscalYear = ({ fiscalYear }) => {
        const {
            location: { pathname, query },
            router,
        } = this.props;

        let fiscalYearQuery;
        if (fiscalYear && fiscalYear !== 'summary') {
            fiscalYearQuery = fiscalYear;
        }

        router.push({
            pathname,
            search: createSearchParams({ ...query, 'fiscal-year': fiscalYearQuery }).toString(),
        });
    };

    showPurchaseOrderFormModal = (purchaseOrder) => {
        this.setState({
            purchaseOrder,
            showPurchaseOrderFormModal: true,
        });
    };

    renderFiscalYearSelector() {
        const { fiscalYearTags, location } = this.props;
        const { query } = location;
        const selectedFiscalYearTagFromQuery = Number.parseInt(query['fiscal-year'], 10);
        const initialFiscalYearFormValue = Number.isNaN(selectedFiscalYearTagFromQuery)
            ? 'summary'
            : selectedFiscalYearTagFromQuery;

        const fiscalYearSelectOptions = [
            {
                label: 'Summary',
                value: 'summary',
            },
            ...fiscalYearTags.map((fiscalYearTag) => {
                return {
                    label: fiscalYearTag.name,
                    value: fiscalYearTag.id,
                };
            }),
        ];

        return (
            <div className={this.styles.phaseSelectContainer}>
                <Label htmlFor="searchSelect" label="Viewing" />
                &nbsp;
                <FiscalYearSelectForm
                    formClassName={this.styles.phaseSelectForm}
                    initialValues={{ fiscalYear: initialFiscalYearFormValue }}
                    onChange={this.selectFiscalYear}
                    options={fiscalYearSelectOptions}
                />
            </div>
        );
    }

    renderSpendManagement() {
        const { budget, budgetAllocation, isEditor, purchaseOrders, selectedFiscalYearTag } =
            this.props;
        const { amount, issued: budgetIssued, paid: budgetPaid } = budget;
        const hasSelectedFiscalYear = !!selectedFiscalYearTag;
        const title = get(selectedFiscalYearTag, 'name') || 'Summary';

        let paid = 0;
        let issued = 0;
        const budgetAmount = hasSelectedFiscalYear ? get(budgetAllocation, 'amount') : amount;

        if (hasSelectedFiscalYear) {
            const purchaseOrdersInSelectedFiscalYear = purchaseOrders.filter(
                (purchaseOrder) => purchaseOrder.tag.id === selectedFiscalYearTag.id
            );

            paid = purchaseOrdersInSelectedFiscalYear.reduce(
                (accumulator, purchaseOrder) => accumulator + purchaseOrder.paidAmount,
                0
            );

            issued = purchaseOrdersInSelectedFiscalYear.reduce(
                (accumulator, purchaseOrder) =>
                    accumulator + (purchaseOrder.amount - purchaseOrder.paidAmount),
                0
            );
        } else {
            paid = budgetPaid;
            issued = budgetIssued;
        }

        return (
            <>
                <BudgetPurchaseOrdersOverview
                    budgetAmount={budgetAmount}
                    chartTitle={title}
                    issuedTotal={issued}
                    paidTotal={paid}
                />
                <PurchaseOrderList
                    editHandler={this.showPurchaseOrderFormModal}
                    isEditor={isEditor}
                    purchaseOrders={purchaseOrders}
                    title={title}
                />
            </>
        );
    }

    render() {
        const {
            budget,
            contract: { contractParty, id: contractId },
            defaultFiscalYearTag,
            isEditor,
            loadError,
            loading,
            selectedFiscalYearTag,
            user,
        } = this.props;

        const { purchaseOrder, showPurchaseOrderFormModal } = this.state;

        if (loading) {
            return <LoadingSpinner />;
        }

        if (loadError) {
            return <LoadingError error={loadError} />;
        }

        return (
            <Main>
                <SectionTitle
                    info="Track spending against your contract"
                    title="Spend Management"
                />
                <div className={classnames('row', this.styles.controls)}>
                    <div className="col-xs-6">{this.renderFiscalYearSelector()}</div>
                    <div className="col-xs-6 text-right">
                        <Button
                            bsStyle="primary"
                            disabled={!isEditor}
                            onClick={() => this.showPurchaseOrderFormModal()}
                            tooltip={!isEditor ? 'Only contract editors can add orders' : undefined}
                        >
                            <i className="fa fa-plus" /> Add Order
                        </Button>
                    </div>
                </div>
                {this.renderSpendManagement()}
                {showPurchaseOrderFormModal && (
                    <PurchaseOrderFormModal
                        budget={budget}
                        contractId={contractId}
                        hideModal={this.hidePurchaseOrderFormModal}
                        initialValues={{
                            [CONTRACT_PARTY_ID]: contractParty.id,
                            [DEPARTMENT_ID]: user.department_id,
                            [STATUS]: REQUESTED,
                            [TAG_ID]: get(selectedFiscalYearTag || defaultFiscalYearTag, 'id'),
                            [PRICE_ITEM_ID]: -1,
                        }}
                        isCreateForm={!purchaseOrder}
                        isEditor={isEditor}
                        purchaseOrder={purchaseOrder}
                        user={user}
                    />
                )}
            </Main>
        );
    }
}

export const ContractSpendManagement = compose(
    connectData(fetchData),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedContractSpendManagement);

import { OTHER, OTHER_TEXT } from './constants';

export function validate(values, props) {
    const errors = {};

    const { characterLimit, fieldName } = props;

    if (!values[fieldName]) {
        errors[fieldName] = 'Please select an option';
    } else if (values[fieldName] === OTHER) {
        if (!values[OTHER_TEXT]) {
            errors[OTHER_TEXT] = 'Please provide a response';
        } else if (values[OTHER_TEXT].length > (characterLimit || 120)) {
            errors[OTHER_TEXT] = `Response is too long (${characterLimit || 120} character limit)`;
        }
    }

    return errors;
}

import { createSelector } from 'reselect';

import { addendumTypesDict } from '@og-pro/shared-config/addendums';

import { navItemTypes } from './constants';

const { ADDENDUM, NOTICE } = addendumTypesDict;

const getAddendums = (state) => state.addendums.get('addendums');

const getAddendumsList = createSelector([getAddendums], (rawAddendums) => rawAddendums.toJS());

export const getAddendumsJS = createSelector([getAddendumsList], (addendums) =>
    addendums.filter((addendum) => addendum.type === ADDENDUM)
);

export const getNoticesJS = createSelector([getAddendumsList], (addendums) =>
    addendums.filter((addendum) => addendum.type === NOTICE)
);

export const getNavItems = createSelector(
    [getAddendumsJS, getNoticesJS, (state, props) => props.location.query.type],
    (addendums, notices) => {
        return [
            {
                count: addendums.length + notices.length,
                label: 'All',
                type: navItemTypes.ALL,
            },
            {
                count: addendums.length,
                label: 'Addenda',
                type: navItemTypes.ADDENDUM,
            },
            {
                count: notices.length,
                label: 'Notices',
                type: navItemTypes.NOTICE,
            },
        ];
    }
);

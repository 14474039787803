import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FieldArray } from 'redux-form';
import { Box } from '@og-pro/ui';

import { CDSButton, CriteriaForm, CDSButtonGroup } from '../../../../components';
import { GenerativeAiPromptScope } from './GenerativeAiPromptScope/GenerativeAiPromptScope';
import { PROMPT } from './GenerativeAiPromptScope/constants';
import { SDv2Toolbar } from '../components';
import { aiEnablementData } from '../../App/selectors';

export const ScopeSDv2Toolbar = ({
    showAiForm,
    showLibraryModal,
    showPartnerResourcesModal,
    showSuggestedContentModal,
    toggleAiForm,
}) => {
    const isGenerativeAiEnabled = !!useSelector(aiEnablementData);

    return (
        <SDv2Toolbar
            buttons={[
                ...(isGenerativeAiEnabled
                    ? [
                          {
                              children: (
                                  <>
                                      {showAiForm && 'Cancel'}
                                      {!showAiForm && <Box>Use AI</Box>}
                                  </>
                              ),
                              inProgressButton: true,
                              onClick: toggleAiForm,
                              qaTag: 'scope-useAi',
                          },
                      ]
                    : []),
                {
                    children: 'Search Scope of Work Library',
                    disabled: showAiForm,
                    onClick: showLibraryModal,
                    qaTag: 'scope-scopeOfWorkLibrary',
                },
                {
                    children: 'Add Suggested Content',
                    disabled: showAiForm,
                    onClick: showSuggestedContentModal,
                    qaTag: 'scope-suggestedContent',
                },
                {
                    children: 'Partner Resources',
                    disabled: showAiForm,
                    onClick: showPartnerResourcesModal,
                    qaTag: 'scope-partnerResources',
                },
            ]}
        />
    );
};
ScopeSDv2Toolbar.propTypes = {
    showAiForm: PropTypes.bool.isRequired,
    showLibraryModal: PropTypes.func.isRequired,
    showPartnerResourcesModal: PropTypes.func.isRequired,
    showSuggestedContentModal: PropTypes.func.isRequired,
    toggleAiForm: PropTypes.func.isRequired,
};

export const ScopeSDv2 = ({
    change,
    copyHandler,
    disabled,
    form,
    formKey,
    insertCriteria,
    listItemRefs,
    project,
    projectSection,
    renderAttachmentBlock,
    scopeCriteria,
    showAiForm,
    showComments,
    showFormErrors,
    showLibraryModal,
    showPartnerResourcesModal,
    showSuggestedContentModal,
    updateListItemRefs,
    tagOptions,
    templateVariableOptions,
    toggleAiForm,
}) => {
    const isGenerativeAiEnabled = !!useSelector(aiEnablementData);

    const styles = require('./ScopeSDv2.scss');
    const itemRefsProps = {
        listItemRefs,
        updateListItemRefs,
    };

    return (
        <Box className={styles.container}>
            <ScopeSDv2Toolbar
                {...{
                    copyHandler,
                    project,
                    showAiForm,
                    showLibraryModal,
                    showPartnerResourcesModal,
                    showSuggestedContentModal,
                    toggleAiForm,
                }}
            />
            {isGenerativeAiEnabled && showAiForm && (
                <Box mt={2}>
                    <GenerativeAiPromptScope
                        addSuggestedItems={copyHandler}
                        govId={project.government_id}
                        initialValues={{ [PROMPT]: project.title || '' }}
                        projectId={project.id}
                        toggleAiForm={toggleAiForm}
                    />
                </Box>
            )}
            {!showAiForm && (
                <Box mt={4}>
                    <FieldArray
                        {...itemRefsProps}
                        change={change}
                        component={CriteriaForm}
                        disabled={disabled}
                        form={form}
                        insertItem={insertCriteria}
                        name={formKey}
                        projectSection={projectSection}
                        showComments={showComments}
                        showValidation={showFormErrors}
                        tagOptions={tagOptions}
                        templateVariableOptions={templateVariableOptions}
                        useManualNumbering={project.useManualNumbering}
                        useRawDescription
                    />
                    {!!scopeCriteria?.length && renderAttachmentBlock()}
                </Box>
            )}
            {!scopeCriteria?.length && !showAiForm && (
                <Box>
                    <CDSButtonGroup>
                        <CDSButton
                            aria-label="Insert Button"
                            disabled={disabled}
                            onClick={() => insertCriteria()}
                            qaTag="criteriaItem-insert"
                            size="small"
                            variant="secondary-alt"
                        >
                            <i className="fa fa-plus" /> Add a List Item
                        </CDSButton>
                    </CDSButtonGroup>
                </Box>
            )}
        </Box>
    );
};

ScopeSDv2.propTypes = {
    change: PropTypes.func.isRequired,
    copyHandler: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    form: PropTypes.string.isRequired,
    formKey: PropTypes.string.isRequired,
    insertCriteria: PropTypes.func.isRequired,
    listItemRefs: PropTypes.object,
    project: PropTypes.object.isRequired,
    projectSection: PropTypes.object.isRequired,
    renderAttachmentBlock: PropTypes.func.isRequired,
    scopeCriteria: PropTypes.object.isRequired,
    showAiForm: PropTypes.bool,
    showComments: PropTypes.bool,
    showFormErrors: PropTypes.bool,
    showLibraryModal: PropTypes.func.isRequired,
    showPartnerResourcesModal: PropTypes.func.isRequired,
    showSuggestedContentModal: PropTypes.func.isRequired,
    updateListItemRefs: PropTypes.object,
    tagOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    templateVariableOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    toggleAiForm: PropTypes.func.isRequired,
};

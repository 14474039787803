import React, { PureComponent } from 'react';

export class SectionNotFound extends PureComponent {
    render() {
        return (
            <div className="col-xs-12 text-warning text-center">
                <h3 className="text-center text-warning">
                    <i className="fa fa-warning" /> Section Not Found
                </h3>
                <h4 style={{ marginTop: '20px' }}>
                    <i>Please ensure the website address is correct.</i>
                </h4>
            </div>
        );
    }
}

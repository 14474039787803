import { startCase } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import { form } from './constants';
import { validate } from './validate';
import { Button, NumberWidget } from '../../../../../components';

const formConfig = {
    form,
    validate,
};

// @reduxForm
class ConnectedWorkloadWeightsForm extends Component {
    static propTypes = {
        cancelHandler: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        statuses: PropTypes.arrayOf(PropTypes.string).isRequired,
        updating: PropTypes.bool,
        updateError: PropTypes.bool,
    };

    render() {
        const { cancelHandler, handleSubmit, statuses, updateError, updating } = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <p className="text-center">
                    Assign a weight based on the workload required for each stage of a project
                </p>
                {statuses.map((status) => (
                    <div className="row" key={status}>
                        <div className="col-xs-6 text-right">
                            <label style={{ marginTop: 8 }}>{startCase(status)}</label>
                        </div>
                        <div className="col-xs-4">
                            <Field
                                component={NumberWidget}
                                disabled={updating}
                                hasFeedback={false}
                                hideLabel
                                label="Weight Quantity"
                                min={0}
                                name={status}
                                placeholder="Enter weight"
                            />
                        </div>
                    </div>
                ))}
                <div className="row text-right">
                    <div className="col-xs-12">
                        <Button bsStyle="link" disabled={updating} onClick={cancelHandler}>
                            Cancel
                        </Button>
                        &nbsp;&nbsp;
                        <Button bsStyle="primary" disabled={updating} type="submit">
                            Save Weights
                        </Button>
                    </div>
                    {updateError && <div className="col-xs-12 error-block">{updateError}</div>}
                </div>
            </form>
        );
    }
}

export const WorkloadWeightsForm = reduxForm(formConfig)(ConnectedWorkloadWeightsForm);

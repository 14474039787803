import { createSelector } from 'reselect';

const getVendorEmailAuditData = (state) => state.contracts.get('vendorEmailAuditData');

export const getVendorEmailAuditsJS = createSelector(
    [getVendorEmailAuditData],
    (vendorEmailAuditData) => {
        if (vendorEmailAuditData) {
            return vendorEmailAuditData.get('emailAudits').toJS();
        }
        return [];
    }
);

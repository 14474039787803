import { createSelector } from 'reselect';

import { getProjectJS, getGovernmentId } from '../../../selectors';

const getProjectAssociations = (state) => state.govProjects.get('projectAssociations');

export const getProjectAssociationsJS = createSelector(
    [getProjectAssociations, getProjectJS, getGovernmentId],
    (projectAssociations, project, govId) => {
        if (projectAssociations) {
            const projectPath = `/governments/${govId}/projects`;
            return projectAssociations
                .toJS()
                .filter((assocProject) => assocProject.id !== project.id)
                .map((assocProject) => ({
                    ...assocProject,
                    dashboardLink: `${projectPath}/${assocProject.id}`,
                    title: assocProject.title || 'Untitled',
                }));
        }
        return [];
    }
);

export const getProjectId = createSelector([getProjectJS], (project) => project.id);

import { get } from 'lodash';
import { createSelector } from 'reselect';

import { buildMap } from '@og-pro/shared-config/helpers';

import { getCurrentEvaluation, getCurrentScoredProposals } from '../../selectors';

export const getAdminScoringCriteriaJS = createSelector([getCurrentEvaluation], (evaluation) => {
    return evaluation.scoringCriteria.filter((scoringCriteria) => scoringCriteria.isAdminScored);
});

export const getAdminScorecardFormValuesMap = createSelector(
    [getCurrentEvaluation, getCurrentScoredProposals, getAdminScoringCriteriaJS],
    (evaluation, scoredProposals, adminScoringCriteria) => {
        const scorecardData = get(evaluation, 'adminScorecard.data') || [];
        const adminScorecardProposalEvaluationMap = buildMap(scorecardData, 'proposal_id');
        return scoredProposals.reduce((obj, proposal) => {
            if (proposal.isExcluded) {
                return obj;
            }
            const proposalEvaluation = adminScorecardProposalEvaluationMap[proposal.id];
            const proposalCriteriaScores = get(proposalEvaluation, 'proposalCriteriaScores') || [];
            const proposalCriteriaScoreMap = buildMap(
                proposalCriteriaScores,
                'scoring_criterium_id'
            );
            adminScoringCriteria.forEach((scoringCriterium) => {
                const proposalCriteriaScore = proposalCriteriaScoreMap[scoringCriterium.id];
                obj[`${proposal.id}_${scoringCriterium.id}`] = proposalCriteriaScore;
            });
            return obj;
        }, {});
    }
);

export const getAdminScorecard = createSelector(
    [getAdminScorecardFormValuesMap, getCurrentScoredProposals, getAdminScoringCriteriaJS],
    (adminScorecardMap, scoredProposals, adminScoringCriteria) => {
        return adminScoringCriteria.map((scoringCriterium) => {
            return {
                proposalCriteriaScores: scoredProposals.map((proposal) => {
                    return {
                        proposal,
                        ...adminScorecardMap[`${proposal.id}_${scoringCriterium.id}`],
                    };
                }),
                scoringCriterium,
            };
        });
    }
);

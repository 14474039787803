import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FieldArray } from 'redux-form';

import { getScoringCriteriaTotalWeight } from './selectors';
import { evaluationPhaseFieldNames } from '../../../constants';
import { EvaluationCriteriaList } from '../../../../../../components/GovApp';

const { SCORING_CRITERIA } = evaluationPhaseFieldNames;

const mapStateToProps = (state, props) => {
    return {
        scoringCriteriaTotalWeight: getScoringCriteriaTotalWeight(state, props),
    };
};

// @connect
class ConnectedEvaluationPhase extends Component {
    static propTypes = {
        arrayName: PropTypes.string.isRequired,
        change: PropTypes.func.isRequired,
        deleteHandler: PropTypes.func,
        disabled: PropTypes.bool,
        isMulti: PropTypes.bool,
        isOGThemeEnabledForComponents: PropTypes.bool,
        scoringCriteriaTotalWeight: PropTypes.number,
        showValidation: PropTypes.bool,
        title: PropTypes.string,
    };

    render() {
        const {
            arrayName,
            change,
            deleteHandler,
            disabled,
            isMulti,
            isOGThemeEnabledForComponents,
            scoringCriteriaTotalWeight,
            showValidation,
            title,
        } = this.props;

        return (
            <FieldArray
                change={change}
                component={EvaluationCriteriaList}
                deletePhaseHandler={deleteHandler}
                disabled={disabled}
                evaluationPhaseArrayName={arrayName}
                evaluationTitle={title}
                isMultiPhase={isMulti}
                isOGThemeEnabledForComponents={isOGThemeEnabledForComponents}
                name={`${arrayName}.${SCORING_CRITERIA}`}
                showFormErrors={showValidation}
                totalWeight={scoringCriteriaTotalWeight}
                useRawDescription
            />
        );
    }
}

export const EvaluationPhase = connect(mapStateToProps)(ConnectedEvaluationPhase);

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export class ApprovalStatus extends PureComponent {
    static propTypes = {
        isIntake: PropTypes.bool,
        status: PropTypes.string.isRequired,
    };

    render() {
        const { isIntake, status } = this.props;

        switch (status) {
            case 'approved':
                if (isIntake) {
                    return (
                        <span className="text-success">
                            <i className="fa fa-3x fa-check" />
                            <br />
                            Processed!
                        </span>
                    );
                }
                return (
                    <span className="text-success">
                        <i className="fa fa-3x fa-check" />
                        <br />
                        Approved!
                    </span>
                );
            case 'rejected':
                return (
                    <span className="text-danger">
                        <i className="fa fa-2x fa-times" />
                        <br />
                        Rejected
                    </span>
                );
            default:
                return (
                    <span className="text-info">
                        <i className="fa fa-2x fa-clock-o" />
                        <br />
                        Pending
                    </span>
                );
        }
    }
}

import { fieldDataTypes, fieldTypes } from '@og-pro/shared-config/customFormService/customField';
import { createElement } from 'react';

import ShortTextIcon from '@mui/icons-material/ShortTextOutlined';
import LongTextIcon from '@mui/icons-material/SubjectOutlined';
import NumberIcon from '@mui/icons-material/PinOutlined';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import CheckboxIcon from '@mui/icons-material/CheckBoxOutlined';
import DateIcon from '@mui/icons-material/InsertInvitation';
import { mdiFormDropdown } from '@mdi/js';
import MdiIcon from '@mdi/react';

const {
    SHORT_TEXT,
    LONG_TEXT,
    NUMBER,
    DATE,
    DROPDOWN,
    CHECKBOX,
    YES_NO,
    MULTIPLE_CHOICE,
    SINGLE_CHOICE,
} = fieldTypes;

export const createCustomFieldDisplayData = {
    [SHORT_TEXT]: {
        ...fieldDataTypes[SHORT_TEXT],
        icon: ShortTextIcon,
    },
    [LONG_TEXT]: {
        ...fieldDataTypes[LONG_TEXT],
        icon: LongTextIcon,
    },
    [NUMBER]: {
        ...fieldDataTypes[NUMBER],
        icon: NumberIcon,
    },
    [YES_NO]: {
        ...fieldDataTypes[YES_NO],
        icon: RadioButtonCheckedIcon,
    },
    [SINGLE_CHOICE]: {
        ...fieldDataTypes[SINGLE_CHOICE],
        icon: RadioButtonCheckedIcon,
    },
    [MULTIPLE_CHOICE]: {
        ...fieldDataTypes[MULTIPLE_CHOICE],
        icon: CheckboxIcon,
    },
    [DATE]: {
        ...fieldDataTypes[DATE],
        icon: DateIcon,
    },
    [DROPDOWN]: {
        label: 'Dropdown',
        icon: (props) => createElement(MdiIcon, { path: mdiFormDropdown, size: 1, ...props }),
    },
    [CHECKBOX]: {
        ...fieldDataTypes[CHECKBOX],
        icon: CheckboxIcon,
    },
};

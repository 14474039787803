import { createSelector } from 'reselect';

import { projectStatusesDict, projectStatusFilterTypesDict } from '@og-pro/shared-config/projects';

import { generateOptions as generateSearchSelectOptions } from '../../../helpers';
import { convertToDate } from '../../../utils';

const { CLOSED, OPEN } = projectStatusesDict;

const { ALL: ALL_STATUS_FILTER, PENDING_AWARD: PENDING_AWARD_STATUS_FILTER } =
    projectStatusFilterTypesDict;

const COMING_SOON = 'comingSoon';

const getProjects = (state) => state.publicProject.get('govProjects');
const getProjectsWithLineItems = (state) => state.publicProject.get('govProjectsWithLineItems');
const getProjectWithLineItemContractInfo = (state) =>
    state.projects.get('projectLineItemContractInfo');

const getProjectsJS = createSelector([getProjects], (rawProjects) => {
    if (rawProjects) {
        return rawProjects.toJS();
    }
    return { count: 0, rows: [] };
});

export const getDepartments = (state) => {
    const departments = state.publicProject.getIn(['government', 'organization', 'departments']);

    return generateSearchSelectOptions(departments?.toJS() || [], 'name', 'id');
};

const newFilterQuery = {
    status: [
        { name: 'All', status: ALL_STATUS_FILTER, isActive: false },
        { name: 'Active', status: OPEN, isActive: true },
        { name: 'Pending', status: PENDING_AWARD_STATUS_FILTER, isActive: false },
        { name: 'Closed', status: CLOSED, isActive: false },
        { name: 'Coming Soon', status: COMING_SOON, isActive: false },
    ],
    title: null,
    departmentId: null,
    projectId: null,
};

export const statusSelectOptions = generateSearchSelectOptions(
    newFilterQuery.status,
    'name',
    'status'
);

function changeComingSoonProjects(projects) {
    return projects.map((project) => {
        if (project.comingSoon) {
            return {
                ...project,
                status: 'coming soon',
                addendums: null,
                releaseProjectDate: null,
                proposalDeadline: null,
            };
        }
        return project;
    });
}

export const getFilteredProjects = createSelector([getProjectsJS], (projects) => {
    return { count: projects.count, rows: changeComingSoonProjects(projects.rows) };
});

export const getProjectsWithLineItemsJS = createSelector(
    [getProjectsWithLineItems],
    (rawProjectsWithLineItems) => {
        if (rawProjectsWithLineItems) {
            return rawProjectsWithLineItems.toJS();
        }
        return [];
    }
);

export const getProjectsWithLineItemContractInfoJS = createSelector(
    [getProjectWithLineItemContractInfo],
    (rawProjectsWithLineItemContractInfo) => {
        if (rawProjectsWithLineItemContractInfo) {
            return rawProjectsWithLineItemContractInfo.toJS();
        }
        return [];
    }
);

const getCalendar = (state) => state.calendar.get('publicCalendar');
const getLoadingCalendar = (state) => state.calendar.get('loadingCalendar');
const getVendorRsvps = (state) => state.rsvps.get('vendorRsvps');

export const getCalendarJS = createSelector(
    [getCalendar, getLoadingCalendar, getVendorRsvps],
    (rawCalendar, loadingCalendar, rawGetVendorRsvps) => {
        if (rawCalendar) {
            const calendar = rawCalendar.toJS();
            const vendorRsvps = rawGetVendorRsvps ? rawGetVendorRsvps.toJS() : [];

            /**
             * Date conversion is required to convert from the UTC date stored in the database
             * to the date displayed in the calender that the user views to specify the
             * date in the government's timezone.
             */
            calendar.events = loadingCalendar
                ? []
                : calendar.events.map((event) => {
                      let rsvp;

                      if (event.isPublic) {
                          if (!event.timelineId) {
                              rsvp = vendorRsvps.find(
                                  (vendRsvp) =>
                                      vendRsvp.isPreProposalRsvp &&
                                      vendRsvp.timeline_config_id === event.timelineConfigId
                              );
                          } else {
                              rsvp = vendorRsvps.find(
                                  (vendRsvp) => vendRsvp.timeline_id === event.timelineId
                              );
                          }
                      }

                      return {
                          ...event,
                          end: convertToDate(event.end, event.timezone),
                          rsvp,
                          start: convertToDate(event.start, event.timezone),
                      };
                  });

            return calendar;
        }
    }
);

const publicVendors = (state) => state.publicVendor.get('vendors');

export const getPublicVendorsJs = createSelector([publicVendors], (rawPublicVendors) => {
    if (rawPublicVendors) {
        return rawPublicVendors.toJS();
    }
    return [];
});

import { createSelector } from 'reselect';
import { chain, compact, reduce, sortBy } from 'lodash';

import {
    getCommentThreadsJS,
    getCriteriaMap,
    getProjectSubsectionsMap,
    getProjectJS,
} from '../selectors';
import { getUserJS } from '../../selectors';
import { getThreadProjectField } from '../../../helpers';

const getShowResolved = (state) => state.govComments.get('showResolved');
const getCommentListFilter = (state) => state.govComments.get('listFilter');

/**
 * NOTE: Exported for testing purposes only
 *
 * Sections are used to divide the project up on the project review view.
 *
 * Each section can have multiple fields, which can either be criteria or projectSubsections.
 *
 * Comment threads are attached to these project fields by association with the
 * `criterion_id` or `project_subsection_id`.
 *
 * This mapping nests each thread under its associated project field and nests each
 * project field under its associated project section.
 */
export const getCommentsJS = createSelector(
    [getCommentThreadsJS, getProjectJS, getProjectSubsectionsMap, getCriteriaMap],
    (commentThreads, project, projectSubsectionsMap, criteriaMap) => {
        if (!project) return [];

        const commentsMap = reduce(
            commentThreads,
            (result, threads, threadKey) => {
                const projectFieldData = getThreadProjectField(
                    project,
                    projectSubsectionsMap,
                    criteriaMap,
                    threadKey
                );

                const { project_section_id: projectSectionId } = projectFieldData;

                if (!result[projectSectionId]) {
                    result[projectSectionId] = [];
                }
                result[projectSectionId].push({ threadKey, projectFieldData, threads });
                return result;
            },
            {}
        );

        // Remove empty sections and properly sort the section fields by order
        return project.projectSections
            .filter((projectSection) => commentsMap[projectSection.id])
            .map((projectSection) => ({
                projectSection,
                fields: sortBy(commentsMap[projectSection.id], 'projectFieldData.orderById'),
            }));
    }
);

/**
 * Takes the comments mapped by project section and applies the specified
 * filter. Fields that no longer have any comment threads that match the filter
 * are removed and likewise sections that no longer have any matching fields
 * are also removed. This leaves a new comment map with only the matching
 * threads and their associated project fields and sections.
 */
export const getFilteredCommentsJS = createSelector(
    [getCommentsJS, getCommentListFilter, getShowResolved, getUserJS],
    (commentsData, listFilter, showResolved, user) => {
        if (listFilter === 'all' && showResolved) return commentsData;

        // Removes sections that contain no fields with threads matching filter
        const filteredComments = commentsData.map((commentSectionData) => {
            // Removes fields without threads matching the filter
            const filteredFields = chain(commentSectionData.fields)
                .map((field) => {
                    const filteredThreads = field.threads.filter((thread) => {
                        if (thread.resolved && !showResolved) return false;
                        if (listFilter === 'all') return true;

                        // Warning: This filter could cause a potential
                        // performance issue in projects with many comments
                        // that have many followers
                        return thread.followers.some((follower) => follower.id === user.id);
                    });
                    if (filteredThreads.length === 0) return null;
                    return { ...field, threads: filteredThreads };
                })
                .compact()
                .value();

            if (filteredFields.length === 0) return null;
            return { ...commentSectionData, fields: filteredFields };
        });

        return compact(filteredComments);
    }
);

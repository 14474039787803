import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { TEXT_STYLE } from '../constants';
import { calculateTimeRemaining, getTimeUnits } from '../helpers';

export class CountdownTimerText extends PureComponent {
    static propTypes = {
        endTime: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
        timeRemainingText: PropTypes.string,
        timeExpiredText: PropTypes.string,
    };

    constructor(props) {
        super(props);
        const timeRemaining = calculateTimeRemaining(this.props.endTime);

        // Do not want to set a timer on the server
        if (timeRemaining > 0 && !process.env.SERVER) {
            this.timer = setInterval(this.tick, 1000);
        }

        this.state = {
            timeRemaining,
        };
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    tick = () => {
        this.setState({
            timeRemaining: calculateTimeRemaining(this.props.endTime),
        });
    };

    render() {
        const { timeExpiredText, timeRemainingText } = this.props;
        const duration = moment.duration(this.state.timeRemaining);

        if (duration < 0 && !timeExpiredText) {
            return null;
        }
        if (duration < 0) {
            return <span className="text-muted">{timeExpiredText}</span>;
        }

        const { days, hours, minutes, seconds } = getTimeUnits(duration, TEXT_STYLE);

        const showDays = days > 0;
        const showHours = showDays || hours > 0;
        const showMinutes = true;
        const showSeconds = !showHours;

        let bsStyle;
        if (days > 0) bsStyle = 'text-info';
        else if (hours > 0) bsStyle = 'text-warning';
        else bsStyle = 'text-danger';

        return (
            <span className={bsStyle}>
                {timeRemainingText && <span>{timeRemainingText} </span>}
                {showDays && `${days} days, `}
                {showHours && `${hours} hours, `}
                {showMinutes && `${minutes} minutes`}
                {showSeconds && `, ${seconds} seconds`}
            </span>
        );
    }
}

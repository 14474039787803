import { List, Map, fromJS } from 'immutable';

import * as criteriaActions from '../../actions/govProjects/criteria';

function criteriaItemReducer(state = new Map(), action) {
    // We only care about the criteria item that matches the ID
    if (state.get('id') !== action.id) return state;

    switch (action.type) {
        case criteriaActions.UPDATE:
            return state.merge(
                fromJS({
                    updating: true,
                    updateError: null,
                })
            );
        case criteriaActions.UPDATE_SUCCESS:
            return fromJS(action.result);
        case criteriaActions.UPDATE_FAIL:
            return state.merge(
                fromJS({
                    updating: false,
                    updateError: action.error && action.error.message,
                })
            );
        case criteriaActions.DELETE:
            return state.merge(
                fromJS({
                    deleting: true,
                    deleteError: null,
                })
            );
        case criteriaActions.DELETE_FAIL:
            return state.merge(
                fromJS({
                    deleting: false,
                    deleteError: action.error && action.error.message,
                })
            );
        case criteriaActions.TOGGLE_FORM:
            return state.set('showForm', action.show);
        default:
            return state;
    }
}

export default function criteriaReducer(state = new List(), action) {
    switch (action.type) {
        case criteriaActions.UPDATE:
        case criteriaActions.UPDATE_SUCCESS:
        case criteriaActions.UPDATE_FAIL:
        case criteriaActions.DELETE:
        case criteriaActions.DELETE_FAIL:
        case criteriaActions.TOGGLE_FORM:
            return state.map((criteria) => criteriaItemReducer(criteria, action));
        case criteriaActions.DELETE_SUCCESS:
            return state.filter((criteria) => criteria.get('id') !== action.id);
        default:
            return state;
    }
}

import { splitCertificationsBySource } from '@og-pro/shared-config/certificationSource';
import { get } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import {
    ContactAddress,
    DataTable,
    LoadingError,
    LoadingSpinner,
    ZeroState,
} from '../../../../../components';
import { renderVendorCertificationCell } from '../../../../../helpers/formatDataForCsvExportUtils';

export class VendorNotifiedTable extends PureComponent {
    static propTypes = {
        emailButton: PropTypes.node,
        notifiedUsers: PropTypes.arrayOf(
            PropTypes.shape({
                notifiedAt: PropTypes.string.isRequired,
                email: PropTypes.string.isRequired,
                fullName: PropTypes.string,
                organization: PropTypes.shape({
                    name: PropTypes.string.isRequired,
                }),
            })
        ).isRequired,
        loaded: PropTypes.bool.isRequired,
        loadEmailAudits: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            csvExportOptions: {
                fileName: 'Notified Users',
                getFormattedCSVData: this.formatDataForCSVExport,
                headers: true,
            },
            loadingList: false,
            loadListError: null,
        };
    }

    componentDidMount() {
        const { loaded, loadEmailAudits } = this.props;

        if (!loaded) {
            this.setState({ loadingList: true, loadListError: null });
            return loadEmailAudits()
                .then(() => {
                    this.setState({ loadingList: false });
                })
                .catch((error) => {
                    this.setState({ loadingList: false, loadListError: error.message });
                });
        }
    }

    get styles() {
        return require('./index.scss');
    }

    formatDataForCSVExport = (data) => {
        const headers = [
            'Vendor',
            'Name',
            'Email',
            'Contact Address',
            'Contact Phone',
            'Notified At',
            'Self-Reported Certifications',
            'Verified Certifications',
        ];

        const rows = data.map((dataRow) => {
            /* eslint-disable no-underscore-dangle */
            const organization = get(dataRow, ['_original', 'organization']) || {};
            const vendor = organization.vendor;
            const { selfReportedCertifications, verifiedCertifications } =
                splitCertificationsBySource(vendor?.certifications || []);

            return [
                get(dataRow._original, 'organization.name') || '',
                dataRow._original.fullName || '',
                dataRow.email,
                ['address1', 'address2', 'city', 'state', 'zipCode']
                    .map(
                        (field) =>
                            // organization can be null if user is "pending"
                            get(dataRow, ['_original', field]) || get(organization, field)
                    )
                    .filter((field) => !!field)
                    .join(' '),
                dataRow._original.phoneComplete || get(organization, 'phoneComplete') || '',
                dataRow['notified-date-column'],
                renderVendorCertificationCell(vendor ? selfReportedCertifications : []),
                renderVendorCertificationCell(vendor ? verifiedCertifications : []),
            ];
            /* eslint-enable no-underscore-dangle */
        });

        return [headers].concat(rows);
    };

    renderBody() {
        const { emailButton, notifiedUsers } = this.props;
        const { csvExportOptions, loadingList, loadListError } = this.state;

        if (loadingList) {
            return <LoadingSpinner noPadding />;
        }

        if (loadListError) {
            return <LoadingError error={loadListError} />;
        }

        if (notifiedUsers.length === 0) {
            return <ZeroState title="No users have been notified yet" />;
        }

        const columns = [
            {
                Header: 'User',
                accessor: 'email',
                Cell: (props) => (
                    <div>
                        {props.original.fullName && (
                            <div className={this.styles.fullName}>{props.original.fullName}</div>
                        )}
                        <div className={this.styles.email}>{props.value}</div>
                        {props.original.organization && (
                            <div>{props.original.organization.name}</div>
                        )}
                    </div>
                ),
            },
            {
                Header: 'Contact',
                accessor: 'address',
                Cell: (props) => {
                    const contactData = [
                        'address1',
                        'address2',
                        'city',
                        'country',
                        'countryCode',
                        'phoneComplete',
                        'state',
                        'zipCode',
                    ].reduce((obj, field) => {
                        // organization can be null if user is "pending"
                        obj[field] =
                            props.original[field] ||
                            get(props, ['original', 'organization', field]) ||
                            '';
                        return obj;
                    }, {});

                    return (
                        <>
                            {contactData.address1 && <ContactAddress {...contactData} />}
                            {contactData.address1 && contactData.phoneComplete && <br />}
                            {contactData.phoneComplete}
                        </>
                    );
                },
            },
            {
                Header: 'Notified At',
                id: 'notified-date-column',
                accessor: (row) => {
                    return moment(row.notifiedAt).format('lll');
                },
                maxWidth: 200,
            },
        ];

        return (
            <DataTable
                buttons={emailButton ? [emailButton] : []}
                className="-striped -highlight"
                columns={columns}
                csvExportOptions={csvExportOptions}
                data={notifiedUsers}
                minRows={Math.max(notifiedUsers.length, 1)}
                pageSize={notifiedUsers.length}
                showCSVExport
                showPagination={false}
                title={`Notified Users (${notifiedUsers.length})`}
            />
        );
    }

    render() {
        return (
            <>
                <h4 className={this.styles.title}>Notified Users</h4>
                <p className="text-muted text-center">
                    Users that were notified by email about the project
                </p>
                {this.renderBody()}
            </>
        );
    }
}

import { createSelector } from 'reselect';

import { projectEventTypesDict } from '@og-pro/shared-config/events';

const { PUBLIC_PROJECT_VIEW } = projectEventTypesDict;

const getVendorData = (state) => state.projectPost.get('vendorData');
const getVendorEmailAuditData = (state) => state.projectPost.get('vendorEmailAuditData');
const getClassificationUsers = (state) => state.projectPost.get('vendorClassificationUsers');
const getClassificationGovlia = (state) => state.projectPost.get('vendorClassificationGovlia');
export const getShowCustomEmailModal = (state) => state.app.get('showCustomEmailModal');

const getVendorDataJS = createSelector([getVendorData], (rawVendorData) => {
    if (rawVendorData) {
        return rawVendorData.toJS();
    }
});

export const getVendorUserData = createSelector([getVendorDataJS], (vendorData) => {
    if (vendorData) {
        return vendorData.users;
    }
    return [];
});

export const getVendorPlanholders = createSelector([getVendorUserData], (vendorUsers) => {
    return vendorUsers.filter((user) => user.isFollower);
});

export const getVendorEmailAudits = createSelector(
    [getVendorEmailAuditData],
    (vendorEmailAuditData) => {
        if (vendorEmailAuditData) {
            return vendorEmailAuditData.get('emailAudits').toJS();
        }
        return [];
    }
);

export const getVendorNotifiedUsers = createSelector(
    [getVendorEmailAuditData, getVendorDataJS],
    (vendorEmailAuditData, vendorData) => {
        if (vendorEmailAuditData) {
            if (vendorData) {
                return vendorEmailAuditData
                    .get('notifiedUsers')
                    .toJS()
                    .map((notifiedUser) => {
                        const completeUser = vendorData.users.find(
                            (user) => user.id === notifiedUser.id
                        );
                        if (completeUser) {
                            return {
                                ...completeUser,
                                ...notifiedUser,
                                organization: { ...completeUser.organization },
                            };
                        }
                        return notifiedUser;
                    });
            }
            return vendorEmailAuditData.get('notifiedUsers').toJS();
        }
        return [];
    }
);

export const getVendorStats = createSelector([getVendorDataJS], (vendorData) => {
    if (vendorData) {
        const { applicantsCount, downloadersCount, followersCount, noBidCount, proposalsCount } =
            vendorData;

        const vendorStats = [
            {
                name: 'Followers',
                icon: 'rss',
                statistic: followersCount,
                bsStyle: 'primary',
                tooltip: 'Users that subscribed to project for updates',
            },
            {
                name: 'Downloaders',
                icon: 'download',
                statistic: downloadersCount,
                bsStyle: 'info',
                tooltip: 'Users that have downloaded the project documents',
            },
            {
                name: 'Applicants',
                icon: 'pencil',
                statistic: applicantsCount,
                bsStyle: 'warning',
                tooltip: 'Users that started or submitted a response',
            },
            {
                name: 'No Bids',
                icon: 'ban',
                statistic: noBidCount,
                bsStyle: 'danger',
                tooltip: 'Users that have no bid the project',
            },
            {
                name: 'Submissions',
                icon: 'envelope-o',
                statistic: proposalsCount,
                bsStyle: 'success',
                tooltip: 'Users that submitted a response',
            },
        ];

        return vendorStats;
    }
    return [];
});

export const getMaxVendorInteractionCount = createSelector([getVendorStats], (vendorStats) => {
    const statCounts = vendorStats.map((stat) => stat.statistic);
    return Math.max(...statCounts);
});

export const getProjectViewCount = createSelector([getVendorDataJS], (vendorData) => {
    if (vendorData) {
        const { projectEventCounts } = vendorData;
        return projectEventCounts[PUBLIC_PROJECT_VIEW] || 0;
    }

    return 0;
});

export const getClassificationUsersJS = createSelector(
    [getClassificationUsers],
    (rawClassificationUsers) => {
        if (rawClassificationUsers) {
            return rawClassificationUsers.toJS();
        }
        return [];
    }
);

export const getClassificationGovliaJS = createSelector(
    [getClassificationGovlia],
    (rawClassificationGovlia) => {
        if (rawClassificationGovlia) {
            return rawClassificationGovlia.toJS();
        }
    }
);

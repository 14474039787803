import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FieldArray, formValueSelector } from 'redux-form';
import { v4 as UUIDv4 } from 'uuid';

import { evaluationPhaseFieldNames, fieldNames } from '../../constants';
import { QuestionLogicIcon } from '../../../../components';
import { OutlineButton } from '../../../../../../../components';
import { EvaluationCriteriaList, ListError } from '../../../../../../../components/GovApp';
import { getMaxNumberFromList } from '../../../../../../../utils';
import { OGThemeContext } from '../../../../../ogThemeProvider';
import { AddPhaseButton } from './AddPhaseButton';

const { EVALUATION_PHASES } = fieldNames;

const { ORDER_BY_ID, SCORING_CRITERIA } = evaluationPhaseFieldNames;

const mapStateToProps = (state, props) => {
    const formSelector = formValueSelector(props.form);
    return {
        evaluationPhases: formSelector(state, EVALUATION_PHASES) || [],
    };
};

// @connect
class ConnectedTemplateEvaluationCriteriaForm extends Component {
    static propTypes = {
        array: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }).isRequired,
        change: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        evaluationPhases: PropTypes.array.isRequired,
        isMultiPhase: PropTypes.bool.isRequired,
        hideQuestionLogic: PropTypes.bool,
        projectSection: PropTypes.object.isRequired,
        questionLogicLinkable: PropTypes.string.isRequired,
        showFormValidation: PropTypes.bool,
    };

    static contextType = OGThemeContext;

    addInitialPhase = () => {
        const { array, evaluationPhases } = this.props;

        const orderById = getMaxNumberFromList(evaluationPhases, ORDER_BY_ID) + 1;
        const title = `Phase ${orderById}`;

        array.push(EVALUATION_PHASES, {
            orderById,
            title,
            uuid: UUIDv4(),
        });
    };

    renderQuestionLogicIcon = (scoringCriterium) => {
        const { hideQuestionLogic, projectSection, questionLogicLinkable } = this.props;

        return (
            <QuestionLogicIcon
                hidden={hideQuestionLogic}
                linkable={questionLogicLinkable}
                linkableItem={scoringCriterium}
                projectSection={projectSection}
            />
        );
    };

    renderAddPhaseButton() {
        const { disabled, isMultiPhase } = this.props;

        const text = isMultiPhase ? 'Add Evaluation Phase' : 'Add Evaluation Criteria';

        return (
            <div className="text-center">
                <OutlineButton
                    bsSize="lg"
                    bsStyle={isMultiPhase ? 'warning' : 'info'}
                    disabled={disabled}
                    onClick={this.addInitialPhase}
                >
                    <i className="fa fa-plus" /> {text}
                </OutlineButton>
            </div>
        );
    }

    renderEvaluationPhases = ({ fields, meta }) => {
        const { change, disabled, isMultiPhase, projectSection, showFormValidation } = this.props;
        const { isOGThemeEnabledForComponents } = this.context;

        const evaluationPhaseFields = fields.map((member, index) => {
            const evaluationPhaseData = fields.get(index);
            const totalWeight = (evaluationPhaseData.scoringCriteria || []).reduce((acc, cur) => {
                return acc + (cur.weight || 0);
            }, 0);

            return (
                <FieldArray
                    change={change}
                    component={EvaluationCriteriaList}
                    deletePhaseHandler={() => fields.remove(index)}
                    disabled={!!disabled}
                    evaluationPhaseArrayName={member}
                    evaluationTitle={evaluationPhaseData.title}
                    isMultiPhase={isMultiPhase}
                    isOGThemeEnabledForComponents={isOGThemeEnabledForComponents}
                    isTemplate={projectSection.isTemplate}
                    key={index}
                    name={`${member}.${SCORING_CRITERIA}`}
                    renderQuestionLogicIcon={this.renderQuestionLogicIcon}
                    showFormErrors={showFormValidation}
                    totalWeight={totalWeight}
                />
            );
        });

        const showAddButton = fields.length === 0 || isMultiPhase;

        return (
            <>
                <ListError meta={meta} showError={showFormValidation} />
                {evaluationPhaseFields}
                {showAddButton && !isOGThemeEnabledForComponents && this.renderAddPhaseButton()}
                {showAddButton && isOGThemeEnabledForComponents && !projectSection.isTemplate && (
                    <AddPhaseButton
                        disabled={this.props.disabled}
                        itemsLength={fields.length}
                        onClick={this.addInitialPhase}
                    />
                )}
            </>
        );
    };

    render() {
        return <FieldArray component={this.renderEvaluationPhases} name={EVALUATION_PHASES} />;
    }
}

export const TemplateEvaluationCriteriaForm = connect(mapStateToProps)(
    ConnectedTemplateEvaluationCriteriaForm
);

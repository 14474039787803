import { mappedUserFields } from '@og-pro/shared-config/contacts';

import { fieldNamesDict } from '../constants';
import { contact } from '../../../../Forms/validation';

const { CONTACTS, TAG_ID, USER_ID } = fieldNamesDict;

export function validate(values = {}) {
    const errors = {};

    let contactsInvalid = false;

    if (values[CONTACTS]) {
        errors[CONTACTS] = values[CONTACTS].map((contractContact) => {
            const contactErrors = {
                ...contact(contractContact, {
                    fieldNames: mappedUserFields,
                    idFieldName: USER_ID,
                    optional: true,
                    skipIdFieldError: true,
                }),
            };

            if (Object.keys(contactErrors).length > 0) {
                contactErrors.contactError =
                    'Contact information is not valid. ' +
                    'Please click the Edit button to update the invalid fields.';
            }

            if (!contractContact[TAG_ID]) {
                contactErrors[TAG_ID] = 'The contact type is required.';
            }

            if (Object.keys(contactErrors).length > 0) {
                contactErrors.contactEntry = 'Contact entry invalid';
                contactsInvalid = true;
            }

            return contactErrors;
        });
    }

    if (contactsInvalid) {
        errors._error = 'Please ensure all provided fields are valid'; // eslint-disable-line no-underscore-dangle
    }

    return errors;
}

import React from 'react';
import PropTypes from 'prop-types';

export const CDSButtonGroup = ({ children }) => {
    const styles = require('./CDSButton.scss');

    return (
        <div className={styles.buttonGroupWithDivider}>
            <hr />
            {children}
            <hr />
        </div>
    );
};

CDSButtonGroup.propTypes = {
    children: PropTypes.node.isRequired,
};

import { useEffect } from 'react';
import Highcharts from 'highcharts';

export const useRenderChart = (chartName, chartData, renderFn, param) => {
    useEffect(() => {
        // If there is a chart with the same ID, destroy it and render the new one
        const activeRequestsChart = [...Highcharts.charts].find(
            (chart) => chart?.renderTo.id === chartName
        );

        if (activeRequestsChart) {
            activeRequestsChart.destroy();
        }

        renderFn();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartData]);

    useEffect(() => {
        // Re-render chart if the query param is not set
        if (!param) {
            renderFn();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [param]);
};

import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import { getShowRsvpButton, getVendorRsvpsJS } from './selectors';
import {
    loadVendorRsvps,
    resetRsvps,
    rsvpLoginHandler,
    vendorRsvpHandler,
} from '../../../actions/rsvps';
import { dateTZFormatter } from '../../../helpers';
import { LoadingError, OutlineButton, RsvpButton } from '../..';

const mapStateToProps = (state, props) => {
    return {
        loadError: state.rsvps.get('loadError'),
        loading: state.rsvps.get('loading'),
        rsvpingVendor: state.rsvps.get('rsvpingVendor'),
        showRsvpButton: getShowRsvpButton(props),
        vendorRsvps: getVendorRsvpsJS(state),
    };
};

const mapDispatchToProps = {
    loadVendorRsvps,
    resetRsvps,
    rsvpLoginHandler,
    vendorRsvpHandler,
};

// @connect
const ConnectedEventsRsvpButton = ({
    loadError,
    loading,
    project: { id: projectId, timelineConfig, timelines },
    rsvpingVendor,
    showRsvpButton,
    timezone,
    user,
    vendorRsvps,
    ...props
}) => {
    useEffect(() => {
        if (user && user.isVendor) {
            props.loadVendorRsvps();
        }

        return props.resetRsvps;
    }, [user]);

    const styles = require('./EventsRsvpButton.scss');

    if (!showRsvpButton) {
        return null;
    }

    const RsvpEvents = timelines
        .filter((timeline) => timeline.isPublic || timeline.preProposalAgendaUrlField)
        .map((timeline, idx) => {
            const isPreProposalRsvp = !!timeline.preProposalAgendaUrlField;
            const timelineId = isPreProposalRsvp ? null : timeline.id;
            const isRsvpEnded = isPreProposalRsvp
                ? timelineConfig.isPreProposalRsvpEnded
                : timeline.isRsvpEnded;
            const rsvp = vendorRsvps.find((vendRsvp) =>
                isPreProposalRsvp
                    ? vendRsvp.isPreProposalRsvp &&
                      vendRsvp.timeline_config_id === timelineConfig.id
                    : vendRsvp.timeline_id === timelineId
            );

            return (
                <div className="text-center" key={idx}>
                    <div className={idx !== 0 ? `row ${styles.divider}` : null} />
                    <div>
                        {timeline.title}
                        <br />
                        <span className={styles.eventDate}>
                            {dateTZFormatter(timezone, 'lll')({ value: timeline.date })}
                        </span>
                    </div>
                    <RsvpButton
                        isRsvpEnded={isRsvpEnded}
                        projectId={projectId}
                        rsvp={rsvp}
                        rsvpLoginHandler={props.rsvpLoginHandler}
                        rsvpingVendor={rsvpingVendor}
                        timelineId={timelineId}
                        user={user}
                        vendorRsvpHandler={props.vendorRsvpHandler}
                    />
                </div>
            );
        });

    const renderOverlay = () => {
        return (
            <Popover className={styles.rsvpsPopover} id="rsvpsPopover">
                {loadError ? <LoadingError error={loadError} /> : RsvpEvents}
            </Popover>
        );
    };

    return (
        <OverlayTrigger overlay={renderOverlay()} placement="bottom" rootClose trigger="click">
            <OutlineButton bsSize="sm" bsStyle="primary">
                <i className={`fa ${loading ? 'fa-spin fa-spinner' : 'fa-calendar-check-o'}`} />
                &nbsp;Events RSVP&nbsp;
                <i className="fa fa-caret-down" />
            </OutlineButton>
        </OverlayTrigger>
    );
};

ConnectedEventsRsvpButton.propTypes = {
    loadError: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    loadVendorRsvps: PropTypes.func.isRequired,
    project: PropTypes.shape({
        id: PropTypes.number.isRequired,
        timelineConfig: PropTypes.shape({
            hasPreProposalDate: PropTypes.bool.isRequired,
            id: PropTypes.number.isRequired,
            isPreProposalRsvpEnded: PropTypes.bool.isRequired,
        }),
        timelines: PropTypes.arrayOf(
            PropTypes.shape({
                isPublic: PropTypes.bool,
            })
        ).isRequired,
    }).isRequired,
    resetRsvps: PropTypes.func.isRequired,
    rsvpingVendor: PropTypes.bool,
    rsvpLoginHandler: PropTypes.func.isRequired,
    showRsvpButton: PropTypes.bool,
    timezone: PropTypes.string.isRequired,
    user: PropTypes.shape({
        id: PropTypes.number.isRequired,
        isVendor: PropTypes.bool.isRequired,
    }),
    vendorRsvpHandler: PropTypes.func.isRequired,
    vendorRsvps: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
        })
    ),
};

export const EventsRsvpButton = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedEventsRsvpButton);

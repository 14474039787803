import { fromJS } from 'immutable';

import * as libraryActions from '../actions/projectLibrary';

const initialState = fromJS({
    showModal: false,
    loading: false,
    loaded: false,
    loadingProject: false,
    page: 1,
    paginationPage: 1,
    sortField: libraryActions.DEFAULT_SORT_FIELD,
    sortDirection: libraryActions.DEFAULT_SORT_DIRECTION,
    searchValue: '',
    categories: [],
});

const sortDirectionMap = {
    asc: 'desc',
    desc: 'asc',
};

function scopeCriteriaReducer(state, action) {
    switch (action.type) {
        case libraryActions.TOGGLE_SCOPE_ITEM:
            if (state.get('id') !== action.criteriaId) {
                return state;
            }
            return state.set('selected', !state.get('selected'));
        default:
            return state;
    }
}

export default function projectLibraryReducer(state = initialState, action = {}) {
    switch (action.type) {
        case libraryActions.SHOW_MODAL:
            return state.merge(
                fromJS({
                    showModal: true,
                    copyFunction: action.copyFunction,
                })
            );
        case libraryActions.RESET_LIBRARY:
            return initialState;
        case libraryActions.CHANGE_PAGE:
            return state.set('page', action.page);
        case libraryActions.SELECTED_CATEGORIES:
            return state.set('categories', fromJS(action.categories));
        case libraryActions.PAGINATION_SELECT:
            return state.set('paginationPage', action.paginationPage);
        case libraryActions.SORT_SELECT:
            return state.set('sortField', action.sortField);
        case libraryActions.TOGGLE_SORT_DIRECTION: {
            const sortDir = state.get('sortDirection');
            return state.set('sortDirection', sortDirectionMap[sortDir]);
        }
        case libraryActions.CHANGE_SEARCH_FIELD:
            return state.set('searchValue', action.searchValue);
        case libraryActions.SET_SEARCH_VALUE:
            return state.set('currentSearch', action.currentSearch);
        case libraryActions.SEARCH:
            return state.merge(
                fromJS({
                    loading: true,
                    loaded: false,
                    loadError: null,
                })
            );
        case libraryActions.SEARCH_SUCCESS:
            return state.merge(
                fromJS({
                    loading: false,
                    loaded: true,
                    projects: fromJS(action.projects),
                    projectsCount: action.count,
                })
            );
        case libraryActions.SEARCH_FAIL:
            return state.merge(
                fromJS({
                    loading: false,
                    loaded: false,
                    loadError: action.error && action.error.message,
                })
            );
        case libraryActions.LOAD_PROJECT:
            return state.merge(
                fromJS({
                    loadingProject: true,
                    loadedProject: false,
                    loadProjectError: null,
                })
            );
        case libraryActions.LOAD_PROJECT_SUCCESS:
            return state.merge(
                fromJS({
                    loadingProject: false,
                    loadedProject: true,
                    selectedProject: fromJS(action.result),
                })
            );
        case libraryActions.LOAD_PROJECT_FAIL:
            return state.merge(
                fromJS({
                    loadingProject: false,
                    loadedProject: false,
                    loadProjectError: action.error && action.error.message,
                })
            );
        case libraryActions.TOGGLE_SCOPE_ITEM:
            return state.setIn(
                ['selectedProject', 'criteria'],
                state
                    .getIn(['selectedProject', 'criteria'])
                    .map((criteria) => scopeCriteriaReducer(criteria, action))
            );
        case libraryActions.SELECT_ALL:
            return state.setIn(
                ['selectedProject', 'criteria'],
                state
                    .getIn(['selectedProject', 'criteria'])
                    .map((criteria) => criteria.set('selected', action.selected))
            );
        default:
            return state;
    }
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { approvalStatusesDict } from '@og-pro/shared-config/approval';

import { ApprovalReviewStepUser } from '../ApprovalReviewStepUser';
import { AddApproversForm } from '../../AddApproversForm/AddApproversForm';
import { form } from '../../AddApproversForm/constants';
import { Button } from '../../../../../Button/Button';
import { StatusLabel } from '../../../../../StatusLabel';

const { APPROVED } = approvalStatusesDict;

export const ApprovalReviewStep = ({
    activeReviewStep,
    addingApprovers,
    approvals,
    inDraft,
    inReview,
    isEditor,
    reminderHandler,
    removeApproverHandler,
    removeReviewStepHandler,
    reviewStep,
    reviewStepApproversMap,
    reviewStepNumber,
    showModal,
    statusLabel,
    updating,
    updateReviewStepHandler,
    user,
}) => {
    const [showForm, setShowForm] = useState(false);

    const showEditApproversForm = () => setShowForm(true);
    const hideEditApproversForm = () => setShowForm(false);

    const styles = require('./index.scss');

    const editReviewStepHandler = (formData) => {
        updateReviewStepHandler(formData, reviewStep.id, hideEditApproversForm);
    };

    const handleRemoveClick = () => {
        const { project_id: projectId, id: reviewStepId } = reviewStep;
        removeReviewStepHandler(projectId, reviewStepId);
    };

    const isCompletedReviewStep =
        !activeReviewStep || activeReviewStep.orderById > reviewStep.orderById;

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.flexRow}>
                    <div className={styles.reviewStepTitle}>
                        <h4
                            className={classnames(
                                styles.titleMargin,
                                showForm && styles.titlePadding
                            )}
                        >
                            Review Step {reviewStepNumber}
                        </h4>
                        {!inDraft && (
                            <StatusLabel
                                bsStyle={statusLabel.bsStyle}
                                className={styles.statusLabel}
                            >
                                {statusLabel.text}
                            </StatusLabel>
                        )}
                    </div>
                    {isEditor && !isCompletedReviewStep && (
                        <div className={styles.reviewStepButtons}>
                            <Button
                                aria-label="Edit Workflow Group Button"
                                bsStyle="link"
                                className={styles.linkButton}
                                disabled={updating}
                                onClick={showForm ? hideEditApproversForm : showEditApproversForm}
                                qaTag="approvalPresetWorkflow-editWorkflow"
                            >
                                {showForm ? (
                                    <>
                                        <i className="fa fa-times" /> Close
                                    </>
                                ) : (
                                    <>
                                        <i className="fa fa-plus" /> Add Approvers
                                    </>
                                )}
                            </Button>
                            &nbsp;
                            <Button
                                aria-label="Delete Workflow Group Button"
                                bsStyle="link"
                                className={styles.linkButton}
                                disabled={updating}
                                onClick={handleRemoveClick}
                                qaTag="approvalPresetWorkflow-removeWorkflow"
                            >
                                <i className="fa fa-trash" /> Remove Step
                            </Button>
                        </div>
                    )}
                </div>
                {showForm && (
                    <AddApproversForm
                        addingApprovers={addingApprovers}
                        approvals={approvals}
                        existingReviewStep={reviewStep}
                        form={`${form}${reviewStep.id}`}
                        hasApprovers={approvals.length > 0}
                        hideAddApproversForm={hideEditApproversForm}
                        onSubmit={editReviewStepHandler}
                        reviewStepNumber={reviewStepNumber}
                        users={reviewStepApproversMap[reviewStep.id]}
                    />
                )}
            </div>
            <div className={styles.reviewStepUser}>
                {reviewStep.projectApprovals.map((approver) => (
                    <ApprovalReviewStepUser
                        activeReviewStep={activeReviewStep}
                        approval={approver}
                        inDraft={inDraft}
                        isApprover={approver.user_id === user.id}
                        isCompletedReviewStep={isCompletedReviewStep}
                        isRejected={approver.status === 'rejected'}
                        isViewOnly={
                            (!inDraft && !inReview) ||
                            approver.status === APPROVED ||
                            (!isEditor && approver.user_id !== user.id)
                        }
                        key={approver.id}
                        reminderHandler={reminderHandler}
                        removeApproverHandler={removeApproverHandler}
                        reviewStep={reviewStep}
                        showModal={showModal}
                    />
                ))}
            </div>
        </div>
    );
};

ApprovalReviewStep.propTypes = {
    activeReviewStep: PropTypes.object,
    addingApprovers: PropTypes.bool.isRequired,
    approvals: PropTypes.array.isRequired,
    inDraft: PropTypes.bool.isRequired,
    inReview: PropTypes.bool.isRequired,
    isEditor: PropTypes.bool.isRequired,
    reminderHandler: PropTypes.func.isRequired,
    removeApproverHandler: PropTypes.func.isRequired,
    removeReviewStepHandler: PropTypes.func.isRequired,
    reviewStep: PropTypes.object.isRequired,
    reviewStepApproversMap: PropTypes.object.isRequired,
    reviewStepNumber: PropTypes.number.isRequired,
    showModal: PropTypes.func.isRequired,
    statusLabel: PropTypes.shape({
        bsStyle: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
    }),
    updateReviewStepHandler: PropTypes.func.isRequired,
    updating: PropTypes.bool,
    user: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }).isRequired,
};

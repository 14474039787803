import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ListGroupItem } from 'react-bootstrap';
import { Field } from 'redux-form';

import { UploadError } from './UploadError';
import { Button, Dropzone, Label, UploadProgress } from '../../../../..';
import { getFileExtensionIcon } from '../../../../../../helpers';

export class ProposalDocumentsUploadListItem extends PureComponent {
    static propTypes = {
        arrayName: PropTypes.string.isRequired,
        deleteAttachment: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        isComplete: PropTypes.bool,
        proposalDocument: PropTypes.shape({
            attachments: PropTypes.arrayOf(
                PropTypes.shape({
                    deleteError: PropTypes.string,
                    deleting: PropTypes.bool,
                    fileExtension: PropTypes.string,
                    fileName: PropTypes.string.isRequired,
                    id: PropTypes.number.isRequired,
                })
            ).isRequired,
            isRequired: PropTypes.bool,
            title: PropTypes.string,
            uploads: PropTypes.arrayOf(
                PropTypes.shape({
                    fileName: PropTypes.string,
                    uploadError: PropTypes.string,
                    uploadProgress: PropTypes.number,
                })
            ).isRequired,
        }).isRequired,
        showValidation: PropTypes.bool,
        uploadHandler: PropTypes.func.isRequired,
    };

    static defaultProps = {
        disabled: false,
        isComplete: false,
    };

    get styles() {
        return require('./index.scss');
    }

    renderUploadList() {
        const {
            proposalDocument: { uploads },
        } = this.props;
        if (!uploads || uploads.length === 0) return null;

        const List = uploads.map((upload, idx) => {
            return (
                <li className={this.styles.uploadItem} key={idx}>
                    <UploadProgress
                        error={upload.uploadError}
                        filename={upload.fileName}
                        progress={upload.uploadProgress}
                    />
                </li>
            );
        });

        return <ul className={`list-unstyled ${this.styles.uploadList}`}>{List}</ul>;
    }

    renderAttachmentList() {
        const {
            deleteAttachment,
            disabled,
            isComplete,
            proposalDocument: { attachments },
        } = this.props;

        if (!isComplete) return null;

        const List = attachments.map((attachment, idx) => {
            const fileIcon = getFileExtensionIcon(attachment.fileExtension);

            return (
                <li className={this.styles.attachmentItem} key={idx}>
                    <Button
                        bsSize="xs"
                        className={this.styles.deleteAttachmentButton}
                        disabled={disabled || attachment.deleting}
                        onClick={() => deleteAttachment(attachment.id, idx)}
                    >
                        <i className="fa fa-lg fa-trash-o" />
                    </Button>
                    <a href={attachment.url}>
                        <i className={`fa fa-${fileIcon} ${this.styles.fileIcon}`} />
                        {attachment.filename}
                    </a>
                    {attachment.deleteError && (
                        <div className={this.styles.attachmentError}>{attachment.deleteError}</div>
                    )}
                </li>
            );
        });

        return <ul className="list-unstyled">{List}</ul>;
    }

    render() {
        const {
            arrayName,
            disabled,
            isComplete,
            proposalDocument: { isRequired, title },
            showValidation,
        } = this.props;

        const stackIconClass = isComplete ? 'successCircle' : 'stackCircle';
        const dropzoneClass = isComplete ? 'successDropzone' : 'dropzone';
        const requiredColor = isRequired ? 'text-success' : 'text-primary';
        const requiredText = isRequired ? 'Required' : 'Optional';
        const requiredIcon = isRequired ? 'check-circle' : 'plus-circle';

        const dropzoneID = 'UploadFile';

        return (
            <ListGroupItem
                className={isComplete ? this.styles.completeListItem : this.styles.listItem}
            >
                <div className={`${requiredColor} pull-right`}>
                    <em>{requiredText}</em>&nbsp;
                    <i className={`fa fa-${requiredIcon}`} />
                </div>
                <div className={this.styles.documentInfo}>
                    <div className="fa-stack fa-lg">
                        <i className={`fa fa-circle fa-stack-2x ${this.styles[stackIconClass]}`} />
                        <i className="fa fa-check fa-stack-1x fa-inverse" />
                    </div>
                    <div className={this.styles.title}>{title}</div>
                </div>
                {this.renderAttachmentList()}
                {this.renderUploadList()}
                <div className={this.styles.dropzoneContainer}>
                    <Label htmlFor={dropzoneID} label="Upload File" />
                    <Dropzone
                        activeClassName={this.styles.activeDropzone}
                        className={this.styles[dropzoneClass]}
                        disabled={disabled}
                        dropzoneID={dropzoneID}
                        onDrop={this.props.uploadHandler}
                    >
                        <Button bsSize="sm" bsStyle="primary" disabled={disabled}>
                            <i className="fa fa-upload" /> Upload File
                        </Button>
                        <div className={this.styles.dropzoneText}>or drag and drop files here</div>
                    </Dropzone>
                </div>
                <Field
                    component={UploadError}
                    name={`${arrayName}.isRequired`}
                    showValidation={showValidation}
                />
            </ListGroupItem>
        );
    }
}

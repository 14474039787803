import { Typography } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React from 'react';

export const TemplateListHeader = ({ children, useOpenGovStyle }) => {
    if (useOpenGovStyle) {
        return (
            <Typography fontWeight="medium" variant="bodySmall">
                {children}
            </Typography>
        );
    }
    return children;
};

TemplateListHeader.propTypes = {
    children: PropTypes.node.isRequired,
    useOpenGovStyle: PropTypes.bool,
};

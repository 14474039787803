import PropTypes from 'prop-types';

export const propTypes = {
    buttons: PropTypes.array,
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    collapsed: PropTypes.bool,
    collapsible: PropTypes.bool,
    componentsAfterButtons: PropTypes.node,
    componentsInlineWithButtons: PropTypes.node,
    csvExportOptions: PropTypes.shape({
        getFormattedCSVData: PropTypes.func.isRequired,
        fileName: PropTypes.string,
        hasHeaderRow: PropTypes.bool,
        headers: PropTypes.bool,
    }),
    displayButtonsBeforeInlineComponent: PropTypes.bool,
    csvExportIsLoading: PropTypes.bool,
    showCSVExport: PropTypes.bool,
    showPagination: PropTypes.bool,
    title: PropTypes.node,
    noDataText: PropTypes.node,
};

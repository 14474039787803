import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm, formValueSelector, Field } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { fieldNames, form } from './constants';
import { Checkbox, DateTimePicker } from '../../../components';

const { CONTRACT_END_DATE, SHOULD_NOTIFY } = fieldNames;

const initialValues = {
    [CONTRACT_END_DATE]: null,
    [SHOULD_NOTIFY]: true,
};

const mapStateToProps = (state) => {
    return {
        contractEndDate: formValueSelector(form)(state, CONTRACT_END_DATE),
        initialValues,
        shouldNotify: formValueSelector(form)(state, SHOULD_NOTIFY),
    };
};

const formConfig = {
    form,
};

// @connect
// @reduxForm
class ConnectedFinalizeAwardForm extends Component {
    static propTypes = {
        disabled: PropTypes.bool,
        handleSubmit: PropTypes.func.isRequired,
        project: PropTypes.shape({
            evaluation: PropTypes.shape({
                selectedProposals: PropTypes.array.isRequired,
            }).isRequired,
        }).isRequired,
        shouldNotify: PropTypes.bool,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const {
            disabled,
            handleSubmit,
            project: {
                evaluation: { selectedProposals },
            },
            shouldNotify,
        } = this.props;
        const hasNoContracts = selectedProposals.every((proposal) => !proposal.contract);

        return (
            <form className={this.styles.container} onSubmit={handleSubmit}>
                {hasNoContracts && (
                    <div className={this.styles.endDateWrapper}>
                        <div className={this.styles.endDateText}>
                            What is the Contract End Date?
                        </div>
                        <div className={this.styles.endDateSubText}>
                            Please estimate, if you’re not sure. This can be updated later.
                        </div>
                        <Field
                            component={DateTimePicker}
                            disabled={disabled}
                            name={CONTRACT_END_DATE}
                        />
                    </div>
                )}
                <Field
                    component={Checkbox}
                    disabled={disabled}
                    inline
                    name={SHOULD_NOTIFY}
                    text="Send email notifying vendors of the award?"
                />
                <p className={this.styles.text}>
                    {shouldNotify
                        ? 'We will send an email to each vendor following the project\nto notify them of the award.'
                        : 'No emails will be sent to vendors.'}
                </p>
            </form>
        );
    }
}

export const FinalizeAwardForm = compose(
    connect(mapStateToProps),
    reduxForm(formConfig)
)(ConnectedFinalizeAwardForm);

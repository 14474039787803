import { createSelector } from 'reselect';

const getTimelinesWithRsvps = (state) => state.rsvps.get('timelinesWithRsvps');

export const getTimelinesWithRsvpsJS = createSelector(
    [getTimelinesWithRsvps],
    (rawTimelinesWithRsvps) => {
        if (rawTimelinesWithRsvps) {
            return rawTimelinesWithRsvps.toJS();
        }
    }
);

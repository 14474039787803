import { Box, Button, Dialog, ReduxFormTextField, Typography } from '@og-pro/ui';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, change, getFormValues } from 'redux-form';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import { useDispatch, useSelector } from 'react-redux';

import { createCustomFieldFormName } from '@og-pro/shared-config/customFormService/customField';

import { CreateCustomFieldContext } from '../context';

const BulkAddOptionsModal = ({ open, handleClose, handleAddOptions }) => {
    const dispatch = useDispatch();
    const formValues =
        useSelector((state) => getFormValues(createCustomFieldFormName)(state)) || {};

    const bulkAddOptionsFieldName = 'bulkAddOptions';

    function handleAddOptionsClick() {
        handleAddOptions(formValues[bulkAddOptionsFieldName]);

        dispatch(change(createCustomFieldFormName, bulkAddOptionsFieldName, ''));
    }

    return (
        <Dialog
            closeButton
            dialogTitle="Bulk Add Options to this Dropdown Field"
            fullWidth
            onClose={handleClose}
            open={open}
        >
            <Box>
                <Box sx={{ pt: 1, pb: 2 }}>
                    <Typography variant="bodyDefault">
                        Use this to copy and paste a list of options for this dropdown. Each option
                        must be on a separate line or separated by a comma.
                    </Typography>
                </Box>
                <Box sx={{ pb: 1.5, width: '100%' }}>
                    <Field
                        component={ReduxFormTextField}
                        fullWidth
                        label="List of Options"
                        multiline
                        name={bulkAddOptionsFieldName}
                    />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, pb: 1, pt: 3 }}>
                    <Button color="secondary" onClick={handleClose} variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        disabled={!formValues[bulkAddOptionsFieldName]}
                        onClick={handleAddOptionsClick}
                        variant="contained"
                    >
                        Add Options
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};

BulkAddOptionsModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func.isRequired,
    handleAddOptions: PropTypes.func.isRequired,
};

export const OptionsField = ({ fields = [], showBulkAddButton = false, disabled }) => {
    const [openBulkAddOptionsModal, setOpenBulkAddOptionsModal] = useState(false);
    const { showFormValidation } = useContext(CreateCustomFieldContext);

    const handleAddOptionClick = () => {
        fields.push('');
    };

    const handleBulkAddOptionsClick = () => {
        setOpenBulkAddOptionsModal(true);
    };

    const handleCloseBulkAddOptionsModal = () => {
        setOpenBulkAddOptionsModal(false);
    };

    const handleRemoveOptionClick = (fieldIndex) => {
        if (fields.length === 1) {
            return;
        }

        fields.remove(fieldIndex);
    };

    const convertBulkAddOptions = (bulkAddOptions) => {
        if (!bulkAddOptions) {
            return;
        }

        // options can be comma separated or on separate lines
        const options = bulkAddOptions.split(/[\r?\n|,]+/);

        if (options.length === 0) {
            return;
        }

        options.forEach((option) => {
            if (!option) {
                return;
            }

            fields.push(option);
        });
    };

    const handleAddBulkOptions = (bulkAddOptionsString) => {
        convertBulkAddOptions(bulkAddOptionsString);
        handleCloseBulkAddOptionsModal();
    };

    return (
        <>
            <Box display="flex" flexDirection="column" gap={2}>
                {fields.map((fieldName, index) => {
                    return (
                        <Box sx={{ display: 'flex', width: '100%' }}>
                            <Field
                                component={ReduxFormTextField}
                                disabled={disabled}
                                label={`Option ${index + 1} ${index === 0 ? '*' : ''}`}
                                name={fieldName}
                                showValidation={showFormValidation}
                                sx={{ width: '100%' }}
                            />
                            {!disabled && index !== 0 && (
                                <Box
                                    sx={{
                                        height: '100%',
                                        alignItems: 'start',
                                        display: 'flex',
                                        paddingTop: 3,
                                    }}
                                >
                                    <Button
                                        color="secondary"
                                        onClick={() => handleRemoveOptionClick(index)}
                                        sx={{
                                            paddingTop: 0.5,
                                            paddingBottom: 0.5,
                                            paddingLeft: 1,
                                            paddingRight: 1,
                                        }}
                                        variant="text"
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    );
                })}
                {!disabled ? (
                    <Box sx={{ display: 'flex', width: '100%', gap: 1 }}>
                        <Button
                            color="primary"
                            onClick={handleAddOptionClick}
                            startIcon={<AddIcon />}
                            variant="outlined"
                        >
                            Add Another Option
                        </Button>
                        {showBulkAddButton && (
                            <Button
                                color="secondary"
                                onClick={handleBulkAddOptionsClick}
                                startIcon={<ExitToAppIcon />}
                                variant="outlined"
                            >
                                Bulk Add
                            </Button>
                        )}
                    </Box>
                ) : null}
            </Box>
            <BulkAddOptionsModal
                disabled={disabled}
                handleAddOptions={(bulkAddOptionsString) =>
                    handleAddBulkOptions(bulkAddOptionsString)
                }
                handleClose={handleCloseBulkAddOptionsModal}
                open={openBulkAddOptionsModal}
            />
        </>
    );
};

OptionsField.propTypes = {
    fields: PropTypes.arrayOf(PropTypes.string),
    showBulkAddButton: PropTypes.bool,
    disabled: PropTypes.bool,
};

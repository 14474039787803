import Highcharts from 'highcharts';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { getContractsByContractTagsData } from './helpers';
import { NoChartDataPanel } from '../../../components';

const CHART_TITLE = 'Active Contracts By Contract Type';

class ConnectedContractsByContractTags extends PureComponent {
    static propTypes = {
        contracts: PropTypes.array.isRequired,
        contractTags: PropTypes.array.isRequired,
        router: PropTypes.object.isRequired,
    };

    get noData() {
        return this.props.contractTags.length === 0;
    }

    componentDidMount() {
        this.renderChart();
    }

    renderChart() {
        const { contracts, contractTags, router } = this.props;

        if (!this.noData) {
            const { categories, series } = getContractsByContractTagsData(
                contracts,
                contractTags,
                router
            );

            // eslint-disable-next-line new-cap
            return new Highcharts.chart({
                chart: {
                    type: 'column',
                    renderTo: 'contracts-by-contract-tags',
                    borderWidth: 1,
                    borderColor: '#dce0e0',
                    style: {
                        fontWeight: 500,
                        fontSize: '18px',
                        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
                    },
                },
                title: {
                    text: CHART_TITLE,
                },
                xAxis: {
                    categories,
                    title: {
                        text: null,
                    },
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: null,
                    },
                    allowDecimals: false,
                    endOnTick: false,
                },
                legend: {
                    enabled: false,
                },
                plotOptions: {
                    column: {
                        dataLabels: {
                            enabled: true,
                        },
                    },
                },
                credits: {
                    enabled: false,
                },
                series,
            });
        }
    }

    render() {
        return (
            <>
                <div id="contracts-by-contract-tags" />
                {this.noData && (
                    <NoChartDataPanel
                        hexColor="#777"
                        icon="bar-chart"
                        subtitle="No contract type data"
                        title={CHART_TITLE}
                    />
                )}
            </>
        );
    }
}

export const ContractsByContractTags = withRouter(ConnectedContractsByContractTags);

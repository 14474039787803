import { createSelector } from 'reselect';

import { getUserJS } from '../../selectors';

const getInitialProjectCreateData = (state) => state.projectCreate.get('initialProjectCreateData');

export const getInitialProjectCreateDataJS = createSelector(
    [getInitialProjectCreateData],
    (initialProjectCreateData) => {
        if (initialProjectCreateData) {
            return initialProjectCreateData.toJS();
        }
    }
);

export const getDefaultProcurementContactId = createSelector([getUserJS], (user) => {
    return user.department.contact_id;
});

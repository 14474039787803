import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { Calendar, momentLocalizer } from 'react-big-calendar';

import { MonthEventWrapper } from './MonthEventWrapper';
import { getCalendarJS } from '../selectors';
import connectData from '../../../ConnectData';
import { getUserJS } from '../../../selectors';
import { loadPublicCalendar } from '../../../../actions/calendar';
import {
    loadVendorRsvps,
    resetRsvps,
    rsvpLoginHandler,
    vendorRsvpHandler,
} from '../../../../actions/rsvps';
import { LoadingError, LoadingSpinner } from '../../../../components';
import { isEmbedded } from '../../../../helpers';

const localizer = momentLocalizer(moment);

const fetchData = (getState, dispatch, location, params) => {
    return dispatch(loadPublicCalendar(params.governmentCode));
};

const mapStateToProps = (state) => {
    return {
        calendar: getCalendarJS(state),
        loadError: state.calendar.get('loadCalendarError'),
        loading: state.calendar.get('loadingCalendar'),
        rsvpingVendor: state.rsvps.get('rsvpingVendor'),
        user: getUserJS(state),
    };
};

const mapDispatchToProps = {
    loadVendorRsvps,
    resetRsvps,
    rsvpLoginHandler,
    vendorRsvpHandler,
};

// @withRouter
// @connectData
// @connect
class ConnectedGovPortalCalendar extends Component {
    static propTypes = {
        calendar: PropTypes.shape({
            events: PropTypes.array.isRequired,
        }),
        loadError: PropTypes.string,
        loading: PropTypes.bool.isRequired,
        location: PropTypes.object.isRequired,
        loadVendorRsvps: PropTypes.func.isRequired,
        resetRsvps: PropTypes.func.isRequired,
        rsvpLoginHandler: PropTypes.func.isRequired,
        rsvpingVendor: PropTypes.bool,
        router: PropTypes.object,
        user: PropTypes.object,
        vendorRsvpHandler: PropTypes.func.isRequired,
    };

    get styles() {
        return require('./GovPortalCalendar.scss');
    }

    componentDidMount() {
        const { user } = this.props;

        if (user && user.isVendor) {
            this.props.loadVendorRsvps();
        }
    }

    componentWillUnmount() {
        this.props.resetRsvps();
    }

    onLinkClick = (to) => {
        this.props.router.push(to);
    };

    render() {
        const { calendar, loadError, loading, location, rsvpingVendor, user } = this.props;

        if (loading || !calendar) {
            return <LoadingSpinner />;
        }

        if (loadError) {
            return <LoadingError error={loadError} />;
        }

        const isEmbed = isEmbedded(location);

        return (
            <div className={this.styles.calendarContainer}>
                <Calendar
                    components={{
                        eventWrapper: (props) => {
                            return (
                                <MonthEventWrapper
                                    {...props}
                                    isEmbed={isEmbed}
                                    onLinkClick={this.onLinkClick}
                                    rsvpLoginHandler={this.props.rsvpLoginHandler}
                                    rsvpingVendor={rsvpingVendor}
                                    user={user}
                                    vendorRsvpHandler={this.props.vendorRsvpHandler}
                                />
                            );
                        },
                    }}
                    endAccessor="end"
                    events={calendar.events}
                    localizer={localizer}
                    showAllEvents
                    startAccessor="start"
                    tooltipAccessor={null}
                    user={user}
                    views={['month']}
                />
            </div>
        );
    }
}

export const GovPortalCalendar = compose(
    connectData(fetchData),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedGovPortalCalendar);

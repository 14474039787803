import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { Box } from '@og-pro/ui';

import { SectionDescriptionForm } from './Form';
import { SectionDescriptionButton } from './Button';

export const SectionDescription = (props) => {
    const {
        disabled,
        isEditable,
        addSectionDescription,
        form,
        formKey,
        removeSectionDescription,
        showFormValidation,
        tagOptions,
        templateVariableOptions,
    } = props;

    const sectionDescription = useSelector((state) => formValueSelector(form)(state, formKey));

    if (!sectionDescription) {
        return (
            <Box marginBottom={2}>
                <SectionDescriptionButton
                    disabled={disabled}
                    handleClick={addSectionDescription}
                    isEditable={isEditable}
                />
            </Box>
        );
    }

    return (
        <SectionDescriptionForm
            disabled={disabled}
            form={form}
            formKey={formKey}
            remove={removeSectionDescription}
            showFormValidation={showFormValidation}
            tagOptions={tagOptions}
            templateVariableOptions={templateVariableOptions}
        />
    );
};

SectionDescription.propTypes = {
    addSectionDescription: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    form: PropTypes.any,
    formKey: PropTypes.string,
    isEditable: PropTypes.bool,
    removeSectionDescription: PropTypes.func.isRequired,
    sectionDescription: PropTypes.shape({
        description: PropTypes.string,
    }),
    showFormValidation: PropTypes.bool,
    tagOptions: PropTypes.array,
    templateVariableOptions: PropTypes.array,
};

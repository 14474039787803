import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

export const SDv2ErrorContainer = ({
    children,
    hasLargeIcon,
    className,
    bannerClassName,
    variant,
}) => {
    const styles = require('./index.scss');

    return (
        <div className={classNames(styles.container, hasLargeIcon && styles.largeIcon, className)}>
            <div className={classNames(styles.banner, bannerClassName, styles[variant])}>
                {children}
            </div>
        </div>
    );
};

const commonProps = {
    className: PropTypes.string,
    hasLargeIcon: PropTypes.bool,
    bannerClassName: PropTypes.string,
    variant: PropTypes.string,
};

SDv2ErrorContainer.propTypes = {
    ...commonProps,
    children: PropTypes.node.isRequired,
};

export const SDv2ErrorBanner = ({
    className,
    description,
    extraInformation,
    hasLargeIcon,
    title,
    bannerClassName,
    variant = 'error',
}) => {
    const styles = require('./index.scss');

    return (
        <SDv2ErrorContainer
            bannerClassName={bannerClassName}
            className={className}
            hasLargeIcon={hasLargeIcon}
            variant={variant}
        >
            <div className={styles.icon}>
                <i className={classNames('fa fa-exclamation-triangle', hasLargeIcon && 'fa-2x')} />
            </div>
            {!!title && <div className={styles.title}>{title}</div>}
            {!!description && <div className={styles.description}>{description}</div>}
            {!!extraInformation && (
                <div className={styles.extraInformation}>
                    <div className={styles.description}>{extraInformation}</div>
                </div>
            )}
        </SDv2ErrorContainer>
    );
};

SDv2ErrorBanner.propTypes = {
    ...commonProps,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    extraInformation: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    variant: PropTypes.string,
};

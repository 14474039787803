import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { SummaryInfoHeader } from './components/SummaryInfoHeader';
import { SummaryListItem } from './components/SummaryListItem';
import { VendorTotalSummary } from './components/VendorTotalSummary';
import { getAuctionItemSummaryInfo, getAuctionMaxFractionDigits } from '../selectors';
import { PageTitle } from '../../../../components';

export const ReverseAuctionSummary = () => {
    const { pathname } = useLocation();
    const styles = require('./index.scss');
    const { activeLineItems, noBidLineItems, currentAuctionTotal } =
        useSelector(getAuctionItemSummaryInfo);
    const auctionMaxFractionDigits = useSelector((state) => getAuctionMaxFractionDigits(state));

    return (
        <div className={`container ${styles.container}`}>
            <PageTitle title="Reverse Auction Summary" />
            <SummaryInfoHeader
                auctionMaxFractionDigits={auctionMaxFractionDigits}
                currentAuctionTotal={currentAuctionTotal}
            />
            {activeLineItems.map((item) => (
                <SummaryListItem
                    auctionMaxFractionDigits={auctionMaxFractionDigits}
                    item={item}
                    key={item.id}
                    reverseAuctionPath={pathname}
                />
            ))}
            {noBidLineItems.map((item) => (
                <SummaryListItem
                    auctionMaxFractionDigits={auctionMaxFractionDigits}
                    isNoBid
                    item={item}
                    key={item.id}
                    reverseAuctionPath={pathname}
                />
            ))}
            <VendorTotalSummary auctionMaxFractionDigits={auctionMaxFractionDigits} />
        </div>
    );
};

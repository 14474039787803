import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import { PermissionsForm } from './PermissionsForm';
import { getModulePermissions } from './selectors';
import { getProjectModuleDataJS } from '../selectors';
import { getUserJS, getInvitedUsersJS } from '../../selectors';
import { Button } from '../../../components';
import * as permissionActions from '../../../actions/govPermissions';

const mapStateToProps = (state) => {
    return {
        error: state.govPermissions.get('sendingError'),
        moduleData: getProjectModuleDataJS(state),
        modulePermissions: getModulePermissions(state),
        projectId: state.govPermissions.get('projectId'),
        showModal: state.govPermissions.get('showModal'),
        user: getUserJS(state),
        users: getInvitedUsersJS(state),
    };
};

const mapDispatchToProps = permissionActions;

// @connect
class ConnectedProjectPermissionsModal extends Component {
    static propTypes = {
        addPermission: PropTypes.func.isRequired,
        error: PropTypes.string,
        hideInviteModal: PropTypes.func.isRequired,
        moduleData: PropTypes.object,
        modulePermissions: PropTypes.array.isRequired,
        projectId: PropTypes.number,
        removePermission: PropTypes.func.isRequired,
        showModal: PropTypes.bool,
        user: PropTypes.object.isRequired,
        users: PropTypes.array,
    };

    static defaultProps = {
        users: [],
    };

    submitHandler = (isInvite) => (formData) => {
        const { addPermission, removePermission, projectId } = this.props;
        const { role } = formData;

        // react-widget hack since the role field returns the map if
        // the default value has been changed and a string if it has not
        const cleanRole = typeof role === 'string' ? role : role.type;
        const data = { ...formData, role: cleanRole };

        if (isInvite) {
            return addPermission(projectId, data);
        }
        return removePermission(projectId, data);
    };

    render() {
        // Do not show invite modal if no project module data has been loaded
        if (!this.props.moduleData) {
            return null;
        }

        const { error, hideInviteModal, moduleData, modulePermissions, showModal, user, users } =
            this.props;

        const errorDisplay = error ? `Error updating permissions: ${error}` : null;

        const PermissionForms = modulePermissions.map((modulePermission) => {
            const { moduleType } = modulePermission;
            const isUsed = get(moduleData, `${moduleType}.isUsed`);
            const canInvite = get(moduleData, `${moduleType}.permissions.isEditor`);

            // Don't show permissions forms for any module that has not been used
            if (!isUsed) {
                return null;
            }

            return (
                <PermissionsForm
                    key={moduleType}
                    {...modulePermission}
                    canInvite={canInvite}
                    submitHandler={this.submitHandler}
                    userId={user.id}
                    users={users}
                />
            );
        });

        return (
            <Modal onHide={hideInviteModal} show={showModal}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">Invite Project Collaborators</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-danger text-center">{errorDisplay}</div>
                    {PermissionForms}
                    <div className="text-center">
                        <Button
                            onClick={hideInviteModal}
                            qaTag="connectedProjectPermissionsModal-done"
                        >
                            Done
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export const ProjectPermissionsModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedProjectPermissionsModal);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Button, HtmlContent } from '../../../../../components';

export class ScopeListItem extends PureComponent {
    static propTypes = {
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        selected: PropTypes.bool,
        onClick: PropTypes.func.isRequired,
    };

    render() {
        const { title, description, onClick, selected } = this.props;
        const styles = require('./ScopeSelect.scss');
        const selectIcon = selected ? 'check-circle' : 'circle-thin';
        const selectIconClass = selected ? styles.selectedIcon : styles.selectIcon;
        const selectClass = selected ? styles.selectedItem : styles.scopeItem;

        return (
            <div className={`row ${styles.listItem}`} onClick={onClick}>
                <div className={`col-xs-2 col-sm-1 text-right ${styles.selectIconWrapper}`}>
                    <Button zeroPadding>
                        <i className={`fa fa-lg fa-fw fa-${selectIcon} ${selectIconClass}`} />
                        <span className="sr-only">Select</span>
                    </Button>
                </div>
                <div className={`col-xs-10 col-sm-11 ${styles.itemCol}`}>
                    <div className={selectClass}>
                        <h5 className={styles.scopeItemTitle}>{title}</h5>
                        <HtmlContent content={description} />
                    </div>
                </div>
            </div>
        );
    }
}

import { createSelector } from 'reselect';

import { getProposalJS } from '../selectors';

export const getProjectUrl = createSelector([getProposalJS], (proposal) => {
    if (proposal) {
        const projectPath = `/projects/${proposal.project_id}`;
        return `${process.env.SITE_URL}${projectPath}`;
    }
});

import { get, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Alert, OutlineButton } from '../../..';
import { verifyBidBond } from '../../../../actions/vendProposals';

const mapDispatchToProps = {
    verifyBidBond,
};

// @connect
export const ConnectedVerifyBidBondButton = ({
    bidBondIdData,
    bidBondVendorIdData,
    proposalId,
    questionnaireId,
    setVerificationFailed,
    updateResponse,
    ...props
}) => {
    const [verifyingBidBond, setVerifyingBidBond] = useState(false);
    const [sentBondData, setSentBondData] = useState();
    const [bidBondVerification, setBidBondVerification] = useState();

    const styles = require('./styles.scss');
    const isValid = get(bidBondVerification, 'isValid');
    const formIsIncomplete = !bidBondIdData || !bidBondVendorIdData;
    const formHasNotChangedSinceVerify = isEqual(sentBondData, {
        bidBondIdData,
        bidBondVendorIdData,
    });
    const bondIsValid = !verifyingBidBond && isValid && formHasNotChangedSinceVerify;
    const bondIsInvalid = !verifyingBidBond && isValid === false && formHasNotChangedSinceVerify;

    const verifyBidBondHandler = () => {
        setVerifyingBidBond(true);
        setSentBondData({ bidBondIdData, bidBondVendorIdData });
        if (setVerificationFailed) {
            setVerificationFailed(false);
        }
        props
            .verifyBidBond(
                proposalId,
                {
                    bidBondId: bidBondIdData,
                    bidBondVendorId: bidBondVendorIdData,
                    questionnaireId,
                },
                { updateResponse }
            )
            .then((result) => {
                setVerifyingBidBond(false);
                setBidBondVerification(result);
                if (setVerificationFailed && !result.isValid) {
                    setVerificationFailed(true);
                }
            })
            .catch(() => setVerifyingBidBond(false));
    };

    return (
        <div>
            {bondIsValid && (
                <Alert bsStyle="success">
                    <i className="fa fa-check-circle" />
                    &nbsp;Bid Bond Verified
                </Alert>
            )}
            {bondIsInvalid && (
                <Alert bsStyle="danger">
                    <i className="fa fa-times-circle" />
                    &nbsp;
                    <strong>Verification Failed: </strong>
                    {`${bidBondVerification && bidBondVerification.message}. ${
                        updateResponse ? 'Please update bond info and try again.' : ''
                    }`}
                </Alert>
            )}
            {!bondIsValid && !bondIsInvalid && (
                <OutlineButton
                    bsStyle="primary"
                    disabled={formIsIncomplete || verifyingBidBond}
                    onClick={() => verifyBidBondHandler()}
                >
                    <i
                        className={`fa ${
                            verifyingBidBond ? 'fa-spinner fa-spin' : 'fa-external-link-square'
                        }`}
                    />
                    &nbsp;
                    {verifyingBidBond ? 'Verifying' : 'Verify Bid Bond'}
                </OutlineButton>
            )}
            {formIsIncomplete && (
                <div className={`text-muted ${styles.verificationHelpBlock}`}>
                    {updateResponse
                        ? 'Verify button will become active when both fields are filled in'
                        : 'Unable to verify. Bond information incomplete.'}
                </div>
            )}
        </div>
    );
};

ConnectedVerifyBidBondButton.propTypes = {
    bidBondIdData: PropTypes.string,
    bidBondVendorIdData: PropTypes.string,
    proposalId: PropTypes.number,
    questionnaireId: PropTypes.number,
    setVerificationFailed: PropTypes.func,
    updateResponse: PropTypes.bool,
    verifyBidBond: PropTypes.func.isRequired,
};

export const VerifyBidBondButton = connect(null, mapDispatchToProps)(ConnectedVerifyBidBondButton);

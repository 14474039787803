import { bulkUpdateFieldNames } from '../constants';

const {
    PROJECTS_ENABLED,
    PROJECTS_PROCUREMENT_CONTACT,
    PROJECTS_CONTACT,
    INTAKES_ENABLED,
    INTAKES_PROCUREMENT_CONTACT,
    INTAKES_CONTACT,
    CONTRACTS_ENABLED,
    CONTRACTS_PROCUREMENT_CONTACT,
    CONTRACTS_CONTACT,
} = bulkUpdateFieldNames;

export const validate = (values) => {
    const errors = {};
    const errorText = 'You must select at least one contact for each selected document type.';

    if (
        values[PROJECTS_ENABLED] &&
        !values[PROJECTS_PROCUREMENT_CONTACT] &&
        !values[PROJECTS_CONTACT]
    ) {
        errors[PROJECTS_PROCUREMENT_CONTACT] = errorText;
        errors[PROJECTS_CONTACT] = errorText;
    }

    if (
        values[INTAKES_ENABLED] &&
        !values[INTAKES_PROCUREMENT_CONTACT] &&
        !values[INTAKES_CONTACT]
    ) {
        errors[INTAKES_PROCUREMENT_CONTACT] = errorText;
        errors[INTAKES_CONTACT] = errorText;
    }

    if (
        values[CONTRACTS_ENABLED] &&
        !values[CONTRACTS_PROCUREMENT_CONTACT] &&
        !values[CONTRACTS_CONTACT]
    ) {
        errors[CONTRACTS_PROCUREMENT_CONTACT] = errorText;
        errors[CONTRACTS_CONTACT] = errorText;
    }

    return errors;
};

import { last } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';

import { CURRENT_VERSION, fieldNames, form } from './constants';
import { getDocumentVersionSelectOptions } from './selectors';
import { validate } from './validate';
import { Button, SearchSelect } from '../..';
import { loadAndCompareRevisions } from '../../../actions/revisions';
import { getProjectJS } from '../../../containers/GovApp/selectors';

const { VERSION_1, VERSION_2 } = fieldNames;

// Background: https://github.com/JedWatson/react-select/issues/1085
// Solution: https://github.com/JedWatson/react-select/issues/1076#issuecomment-253629943
const SELECT_MENU_STYLE = {
    menu: { zIndex: 5 },
};

const mapStateToProps = (state, props) => {
    const selectOptions = getDocumentVersionSelectOptions(state);

    return {
        initialValues: {
            [VERSION_1]: last(selectOptions).value,
            [VERSION_2]: selectOptions[0].value,
            ...props.initialValues,
        },
        loadingDiff: state.revisions.get('loadingDiff'),
        project: getProjectJS(state),
        selectOptions,
    };
};

const mapDispatchToProps = {
    loadAndCompareRevisions,
};

const formConfig = {
    form,
    validate,
};

// @connect
// @reduxForm
class ConnectedVersionCompareForm extends Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        initialize: PropTypes.func.isRequired,
        isPublic: PropTypes.bool,
        loadAndCompareRevisions: PropTypes.func.isRequired,
        loadingDiff: PropTypes.bool,
        onSuccess: PropTypes.func,
        project: PropTypes.object.isRequired,
        selectOptions: PropTypes.array.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    loadAndCompareRevisions = (data) => {
        const { initialize, isPublic, onSuccess, project, selectOptions } = this.props;

        const versionsToFetch = [data[VERSION_1], data[VERSION_2]].map((versionValue) => {
            if (versionValue === CURRENT_VERSION) {
                return project;
            }
            const selectedOption = selectOptions.find((opt) => opt.value === versionValue);
            return selectedOption.documentVersion.project_audit_id;
        });

        this.props
            .loadAndCompareRevisions(project.id, ...versionsToFetch, { isPublic })
            .then((resultOrError) => {
                if (!(resultOrError instanceof Error)) {
                    initialize(data);
                    if (onSuccess) {
                        onSuccess(resultOrError);
                    }
                }
            });
    };

    render() {
        const { loadingDiff, handleSubmit, selectOptions } = this.props;

        return (
            <form onSubmit={handleSubmit(this.loadAndCompareRevisions)}>
                <div className="row">
                    <div className={`col-md-4 ${this.styles.version1Column}`}>
                        <Field
                            aria-label="Base Template"
                            component={SearchSelect}
                            disabled={loadingDiff}
                            help="Base"
                            name={VERSION_1}
                            options={selectOptions}
                            styles={SELECT_MENU_STYLE}
                        />
                    </div>
                    <div className={`hidden-xs hidden-sm col-md-1 ${this.styles.arrowIcon}`}>
                        <i className="fa fa-lg fa-arrow-right" />
                    </div>
                    <div className={`col-md-4 ${this.styles.version2Column}`}>
                        <Field
                            aria-label="Comparison Template"
                            component={SearchSelect}
                            disabled={loadingDiff}
                            help="Compare"
                            name={VERSION_2}
                            options={selectOptions}
                            styles={SELECT_MENU_STYLE}
                        />
                    </div>
                    <div className="col-md-3">
                        <Button block bsStyle="primary" disabled={loadingDiff} type="submit">
                            <i className="fa fa-random" /> Compare
                        </Button>
                    </div>
                </div>
            </form>
        );
    }
}

export const VersionCompareForm = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedVersionCompareForm);

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { ScopeListItem } from './ScopeListItem';

export class ScopeList extends PureComponent {
    static propTypes = {
        items: PropTypes.array.isRequired,
        clickHandler: PropTypes.func.isRequired,
    };

    renderScopeListItems() {
        const { items, clickHandler } = this.props;
        return items.map((item) => (
            <ScopeListItem key={item.id} {...item} onClick={() => clickHandler(item)} />
        ));
    }

    render() {
        return <div>{this.renderScopeListItems()}</div>;
    }
}

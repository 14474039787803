import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import { ActionItem } from '../ActionItem';
import { PageButton } from '../PageButton';
import { PrimaryActionButton } from '../PrimaryActionButton';
import { Well } from '../../../../../components';

export class ModuleManager extends PureComponent {
    static propTypes = {
        actionHandler: PropTypes.func.isRequired,
        moduleData: PropTypes.shape({
            isComplete: PropTypes.bool,
            isCurrent: PropTypes.bool,
            isEnabled: PropTypes.bool,
            isPending: PropTypes.bool,
            isSkipped: PropTypes.bool,
            linksLabel: PropTypes.string,
            links: PropTypes.array,
            name: PropTypes.string.isRequired,
            pages: PropTypes.arrayOf(
                PropTypes.shape({
                    hidden: PropTypes.bool,
                    name: PropTypes.string.isRequired,
                })
            ),
            primaryActions: PropTypes.arrayOf(
                PropTypes.shape({
                    isActive: PropTypes.bool,
                })
            ).isRequired,
            secondaryActions: PropTypes.arrayOf(
                PropTypes.shape({
                    isHidden: PropTypes.bool,
                })
            ).isRequired,
        }).isRequired,
        Pages: PropTypes.elementType,
    };

    get skipped() {
        const {
            moduleData: { name },
        } = this.props;
        return `${name} phase was skipped`;
    }

    get nextStep() {
        return 'Please complete the current phase of your project before starting this phase';
    }

    get message() {
        const {
            moduleData: { isEnabled, isPending, isSkipped },
        } = this.props;

        if (!isEnabled) return this.renderUpgrade();
        if (isSkipped) return this.skipped;
        if (isPending) return this.nextStep;

        return false;
    }

    get styles() {
        return require('../shared.scss');
    }

    renderUpgrade() {
        const {
            moduleData: { name },
        } = this.props;

        return (
            <div>
                <div className={this.styles.upgradeSection}>
                    Contact us to enable the {name} module!
                </div>
                <div>
                    <a href="mailto:sales@opengov.com">sales@opengov.com</a>
                </div>
            </div>
        );
    }

    renderMessage() {
        return (
            <h4 className={`text-muted ${this.styles.moduleBodyMessage}`}>
                <em>{this.message}</em>
            </h4>
        );
    }

    render() {
        const {
            actionHandler,
            moduleData: { isCurrent, links, linksLabel, pages, primaryActions, secondaryActions },
            Pages,
        } = this.props;

        if (this.message) {
            return this.renderMessage();
        }

        const [primaryAction, ...otherActions] = primaryActions.filter((action) => action.isActive);
        const actions = [...otherActions, ...secondaryActions].filter((action) => !action.isHidden);

        const textColor = isCurrent ? 'text-primary' : this.styles.completedBtnColor;

        return (
            <div className={`row ${this.styles.moduleMangerContainer}`}>
                {pages && (
                    <div className={`col-sm-7 col-md-8 ${this.styles.pagesContainer}`}>
                        {pages &&
                            pages
                                .filter((page) => !page.hidden)
                                .map((page) => (
                                    <PageButton key={page.name} textColor={textColor} {...page} />
                                ))}
                        {links && links.length > 0 && (
                            <div>
                                <strong>{linksLabel}</strong>
                                {links.map(({ href, text }, index) => (
                                    <div key={index}>
                                        <i className="fa fa-angle-right text-primary" />
                                        &nbsp;
                                        <Link to={href}>{text}</Link>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}
                {Pages && (
                    <div className="col-sm-7 col-md-8">
                        <Pages />
                    </div>
                )}
                <div className="col-sm-5 col-md-4">
                    <Well>
                        {primaryAction && (
                            <div className={this.styles.primaryActionContainer}>
                                <h4 className={this.styles.subHeading}>Next Action:</h4>
                                <PrimaryActionButton
                                    {...primaryAction}
                                    actionHandler={actionHandler}
                                />
                            </div>
                        )}
                        <div className={this.styles.secondaryActions}>
                            <h4 className={this.styles.subHeading}>Other Actions:</h4>
                            <ul className="list-unstyled">
                                {actions.map((action) => (
                                    <ActionItem
                                        key={action.name}
                                        {...action}
                                        actionHandler={actionHandler}
                                    />
                                ))}
                            </ul>
                        </div>
                    </Well>
                </div>
            </div>
        );
    }
}

export const formatFilterFormData = (formData) => {
    if (formData.filters) {
        return {
            ...formData,
            filters: formData.filters.map((filter) => {
                const {
                    localCreatingUUID, // Strip out data the server doesn't care about
                    ...rest
                } = filter;

                return rest;
            }),
        };
    }

    return formData;
};

import { startCase } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Checkbox, ListGroup, ListGroupItem } from 'react-bootstrap';

import { LINE_ITEM_AWARD, SCORED } from '@og-pro/shared-config/evaluations';

import { evaluationReportOptionsList } from '../../constants';

export class ProjectSummarySelectOptions extends PureComponent {
    static propTypes = {
        onClick: PropTypes.func.isRequired,
        evaluation: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            showAnonymizedEvaluators: false,
            showPercentageTotals: false,
            useLandscape: false,
        };
    }

    toggleLandscape = () => {
        this.setState((prevState) => ({ useLandscape: !prevState.useLandscape }));
    };

    toggleShowAnonymizedEvaluators = () => {
        this.setState((prevState) => ({
            showAnonymizedEvaluators: !prevState.showAnonymizedEvaluators,
        }));
    };

    toggleShowPercentageTotals = () => {
        this.setState((prevState) => ({ showPercentageTotals: !prevState.showPercentageTotals }));
    };

    getOptionText = (reportOption) => {
        const { evaluation } = this.props;
        let optionText = `evaluation ${reportOption}`;

        if (evaluation.type === LINE_ITEM_AWARD) {
            optionText = reportOption === 'details' ? 'line item awards' : 'awards by vendor';
        }

        return startCase(optionText);
    };

    renderEvaluationOptions() {
        const { evaluation, onClick } = this.props;
        const { showAnonymizedEvaluators, showPercentageTotals, useLandscape } = this.state;

        return (
            <>
                <h5 style={{ marginTop: 20 }}>Evaluation Options</h5>
                {evaluation.type === SCORED && (
                    <>
                        <Checkbox
                            checked={showAnonymizedEvaluators}
                            onChange={this.toggleShowAnonymizedEvaluators}
                        >
                            Anonymize evaluators
                        </Checkbox>
                        <Checkbox
                            checked={showPercentageTotals}
                            onChange={this.toggleShowPercentageTotals}
                        >
                            Show totals as percentages
                        </Checkbox>
                    </>
                )}
                <ListGroup>
                    {evaluationReportOptionsList.map((reportOption) => (
                        <ListGroupItem
                            key={reportOption}
                            onClick={() =>
                                onClick({
                                    evaluation,
                                    reportOption,
                                    showAnonymizedEvaluators,
                                    showPercentageTotals,
                                    useLandscape,
                                })
                            }
                        >
                            <i className="fa fa-angle-right pull-right" />
                            {this.getOptionText(reportOption)}
                        </ListGroupItem>
                    ))}
                </ListGroup>
            </>
        );
    }

    render() {
        const { evaluation, onClick } = this.props;
        const { useLandscape } = this.state;

        return (
            <>
                <h5>Page Options</h5>
                <Checkbox checked={useLandscape} onChange={this.toggleLandscape}>
                    Landscape Layout
                </Checkbox>
                {evaluation ? (
                    this.renderEvaluationOptions()
                ) : (
                    <ListGroupItem key="generateReport" onClick={() => onClick({ useLandscape })}>
                        <i className="fa fa-angle-right pull-right" />
                        Generate Report
                    </ListGroupItem>
                )}
            </>
        );
    }
}

import { createSelector } from 'reselect';

import { ARCHIVED, DRAFT, PUBLISHED } from '@og-pro/shared-config/reviewGroups';

import {
    ALL as ALL_NAV,
    ARCHIVED as ARCHIVED_NAV,
    DRAFT as DRAFT_NAV,
    LIVE as LIVE_NAV,
} from './constants';

const getRawRequisitionGroups = (state) => state.requisitionGroups.get('requisitionGroups');
const getLocation = (state, props) => props.location;

export const getRequisitionGroups = createSelector(
    [getRawRequisitionGroups],
    (requisitionGroups) => {
        return requisitionGroups.toJS();
    }
);

const getAllRequisitionGroups = createSelector([getRequisitionGroups], (requisitionGroups) => {
    return requisitionGroups.filter((requisitionGroup) => requisitionGroup.status !== ARCHIVED);
});

const getDraftRequisitionGroups = createSelector([getRequisitionGroups], (requisitionGroups) => {
    return requisitionGroups.filter((requisitionGroup) => requisitionGroup.status === DRAFT);
});

export const getLiveRequisitionGroups = createSelector(
    [getRequisitionGroups],
    (requisitionGroups) => {
        return requisitionGroups.filter(
            (requisitionGroup) => requisitionGroup.status === PUBLISHED
        );
    }
);

const getArchivedRequisitionGroups = createSelector([getRequisitionGroups], (requisitionGroups) => {
    return requisitionGroups.filter((requisitionGroup) => requisitionGroup.status === ARCHIVED);
});

export const getNavItems = createSelector(
    [
        getAllRequisitionGroups,
        getDraftRequisitionGroups,
        getLiveRequisitionGroups,
        getArchivedRequisitionGroups,
    ],
    (
        allRequisitionGroups,
        draftRequisitionGroups,
        liveRequisitionGroups,
        archivedRequisitionGroups
    ) => {
        return [
            {
                name: 'All',
                status: ALL_NAV,
                counter: allRequisitionGroups.length,
            },
            {
                name: 'Live',
                status: LIVE_NAV,
                counter: liveRequisitionGroups.length,
            },
            {
                name: 'Draft',
                status: DRAFT_NAV,
                counter: draftRequisitionGroups.length,
            },
            {
                name: 'Archived',
                status: ARCHIVED_NAV,
                counter: archivedRequisitionGroups.length,
            },
        ];
    }
);

export const getSelectedNavItem = createSelector(
    [getNavItems, getLocation],
    (navItems, location) => {
        const {
            query: { status },
        } = location;

        return navItems.find((item) => item.status === status) || navItems[0];
    }
);

export const getSelectedRequisitionGroups = createSelector(
    [
        getSelectedNavItem,
        getAllRequisitionGroups,
        getDraftRequisitionGroups,
        getLiveRequisitionGroups,
        getArchivedRequisitionGroups,
    ],
    (
        selectedNavItem,
        allRequisitionGroups,
        draftRequisitionGroups,
        liveRequisitionGroups,
        archivedRequisitionGroups
    ) => {
        switch (selectedNavItem.status) {
            case ALL_NAV:
                return allRequisitionGroups;
            case LIVE_NAV:
                return liveRequisitionGroups;
            case DRAFT_NAV:
                return draftRequisitionGroups;
            case ARCHIVED_NAV:
                return archivedRequisitionGroups;
            default:
                return [];
        }
    }
);

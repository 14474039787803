import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { reportTypesList, reportTypes, reportFormats } from './constants';
import { ReportDisplay } from './ReportDisplay';
import { ReportsFormatSelect } from './ReportsFormatSelect';
import { ReportOptionsSelect } from './ReportOptionsSelect';
import { ReportsTypeSelect } from './ReportsTypeSelect';
import { getFormatsToDisplay, getReportsToDisplay } from './selectors';
import { getReviewProjectJS } from '../../selectors';
import { Button } from '../../../../components';

const {
    ALL_PROPOSALS_REPORT,
    AWARD_REPORT,
    EVALUATION_TABULATION_REPORT,
    INTERVIEW_INVITATION_REPORT,
    NON_AWARD_REPORT,
    PROJECT_SUMMARY_REPORT,
    PROPOSAL_DOCUMENT_REPORT,
} = reportTypes;

const { PDF } = reportFormats;

const mapStateToProps = (state) => {
    return {
        formats: getFormatsToDisplay(state),
        project: getReviewProjectJS(state),
        reports: getReportsToDisplay(state),
    };
};

// @connect
class ConnectedReportsModal extends Component {
    static propTypes = {
        dataForReport: PropTypes.any,
        formats: PropTypes.array.isRequired,
        hideModal: PropTypes.func.isRequired,
        project: PropTypes.object.isRequired,
        reports: PropTypes.array.isRequired,
        reportsModalReportType: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.state = {
            pages: [],
            additionalRequestData: {},
        };
    }

    get styles() {
        return require('./index.scss');
    }

    get reportName() {
        const { dataForReport, reports } = this.props;

        const { reportType } = this.state;

        const selectedReport = reports.find((report) => report.type === reportType);
        if (selectedReport) {
            // Use custom name for proposal document report
            if (reportType === PROPOSAL_DOCUMENT_REPORT) {
                const proposalName = dataForReport?.proposal?.companyName || 'Unnamed Company';
                return `[${proposalName}] ${selectedReport.fullName}`;
            }
            return selectedReport.fullName;
        }
        return null;
    }

    UNSAFE_componentWillMount() {
        const { reportsModalReportType } = this.props;

        if (reportsModalReportType) {
            return this.reportsTypeHandler(reportsModalReportType);
        }

        this.setState({
            pages: [1],
        });
    }

    backHandler = () => {
        return this.setState((prevState) => {
            return {
                pages: prevState.pages.slice(0, -1),
            };
        });
    };

    reportsTypeHandler = (reportType) => {
        this.setState({
            reportType,
        });

        switch (reportType) {
            // Some reports will need additional selection
            case ALL_PROPOSALS_REPORT:
            case AWARD_REPORT:
            case EVALUATION_TABULATION_REPORT:
            case INTERVIEW_INVITATION_REPORT:
            case NON_AWARD_REPORT:
            case PROJECT_SUMMARY_REPORT:
            case PROPOSAL_DOCUMENT_REPORT:
                return this.setState((prevState) => {
                    return {
                        pages: prevState.pages.concat(2),
                    };
                });
            // Rest can skip to format selection
            default:
                return this.setState((prevState) => {
                    return {
                        pages: prevState.pages.concat(3),
                    };
                });
        }
    };

    optionsHandler = (options) => {
        const { dataForReport, project } = this.props;
        const { reportType } = this.state;

        if (reportType === PROPOSAL_DOCUMENT_REPORT && get(options, 'includeAttachments')) {
            this.setState({
                options,
                additionalRequestData: {
                    proposalId: dataForReport.proposal.id,
                    module: dataForReport.module,
                },
            });
            return this.formatTypeHandler(PDF);
        }

        if (reportType === ALL_PROPOSALS_REPORT) {
            const defaultModule = project.evaluation_id ? 'evaluation' : 'sourcing';
            this.setState({
                options,
                additionalRequestData: {
                    module: dataForReport?.module || defaultModule,
                },
            });
            return this.formatTypeHandler(PDF);
        }

        return this.setState((prevState) => ({
            options,
            pages: prevState.pages.concat(3),
        }));
    };

    formatTypeHandler = (formatType) => {
        return this.setState((prevState) => {
            return {
                formatType,
                pages: prevState.pages.concat(4),
            };
        });
    };

    renderReport() {
        const { dataForReport, project } = this.props;

        const { additionalRequestData, formatType, options, reportType } = this.state;

        if (!reportTypesList.includes(reportType)) {
            return <h3 className="text-center text-warning">Report Not Found</h3>;
        }

        return (
            <ReportDisplay
                additionalRequestData={additionalRequestData}
                dataForReport={dataForReport}
                formatType={formatType}
                isReportEmailed={get(options, 'includeAttachments')}
                options={options}
                project={project}
                reportName={this.reportName}
                reportType={reportType}
            />
        );
    }

    renderModalBody() {
        const { formats, project, reports } = this.props;

        const { pages, reportType } = this.state;

        switch (pages[pages.length - 1]) {
            case 2:
                return (
                    <ReportOptionsSelect
                        onClick={this.optionsHandler}
                        project={project}
                        reportType={reportType}
                    />
                );
            case 3:
                return (
                    <>
                        <h5 className={classnames(this.styles.reportName)}>Select Report Format</h5>
                        <ReportsFormatSelect formats={formats} onClick={this.formatTypeHandler} />
                    </>
                );
            case 4:
                return this.renderReport();
            default:
                return (
                    <>
                        <h5 className={this.styles.reportName}>Select Report Type</h5>
                        <ReportsTypeSelect onClick={this.reportsTypeHandler} reports={reports} />
                    </>
                );
        }
    }

    render() {
        const { hideModal, project } = this.props;

        const { pages } = this.state;

        const title =
            pages[pages.length - 1] === 1
                ? `Reports: ${project.title || 'Untitled'}`
                : this.reportName;

        return (
            <Modal onHide={hideModal} show>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={this.styles.modalBody}>
                    {this.renderModalBody()}
                    {pages.length > 1 && (
                        <div className={this.styles.backButtonContainer}>
                            <Button onClick={this.backHandler}>
                                <i className="fa fa-arrow-left" /> Back
                            </Button>
                        </div>
                    )}
                    <div className="clearfix" />
                </Modal.Body>
            </Modal>
        );
    }
}

export const ReportsModal = connect(mapStateToProps)(ConnectedReportsModal);

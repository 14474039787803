import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { getNavItems, getSelectedNavItem, getSelectedTemplates } from './selectors';
import { getChecklistsAdminPath, getTemplateChecklistsJS } from '../selectors';
import connectData from '../../../ConnectData';
import {
    createTemplateChecklist,
    loadTemplateChecklists,
    resetTemplateChecklists,
} from '../../../../actions/checklistsAdmin';
import { Button, TemplateList as TemplateListComponent } from '../../../../components';

function fetchData(getState, dispatch) {
    if (!getState().checklistsAdmin.get('loadedList')) {
        return dispatch(loadTemplateChecklists());
    }
    return Promise.resolve();
}

const mapStateToProps = (state, props) => {
    return {
        checklistsAdminPath: getChecklistsAdminPath(state, props),
        createError: state.checklistsAdmin.get('createError'),
        creating: state.checklistsAdmin.get('creating'),
        hasTemplates: getTemplateChecklistsJS(state).length > 0,
        navItems: getNavItems(state, props),
        selectedNavItem: getSelectedNavItem(state, props),
        templates: getSelectedTemplates(state, props),
    };
};

const mapDispatchToProps = {
    createTemplateChecklist,
    resetTemplateChecklists,
};

// @connectData
// @connect
const ConnectedChecklistsAdminList = ({
    checklistsAdminPath,
    createError,
    createTemplateChecklist: createTemplateChecklistFn,
    creating,
    hasTemplates,
    location,
    navItems,
    resetTemplateChecklists: resetTemplateChecklistsFn,
    router,
    selectedNavItem,
    templates,
}) => {
    useEffect(() => resetTemplateChecklistsFn, []);

    const routeToTemplate = (templateId) => () => {
        router.push(`${checklistsAdminPath}/${templateId}`);
    };

    const createTemplateChecklistHandler = () => {
        createTemplateChecklistFn((templateChecklist) => {
            routeToTemplate(templateChecklist.id)();
        });
    };

    const TemplateCreateButtonFn = (props) => (
        <Button
            bsStyle="primary"
            disabled={creating}
            onClick={createTemplateChecklistHandler}
            qaTag="checklistsAdminList-newTemplate"
            {...props}
        >
            <i className={`fa fa-${creating ? 'spin fa-spinner' : 'plus'}`} /> New Template
        </Button>
    );

    return (
        <>
            <h1 className="visually-hidden">Checklist Admin</h1>
            <TemplateListComponent
                TemplateCreateButton={TemplateCreateButtonFn}
                createError={createError}
                hasTemplates={hasTemplates}
                location={location}
                navItems={navItems}
                routingHandler={routeToTemplate}
                selectedNavItem={selectedNavItem}
                templateItems={templates}
                title="Checklist Templates"
            />
        </>
    );
};

ConnectedChecklistsAdminList.propTypes = {
    checklistsAdminPath: PropTypes.string.isRequired,
    createError: PropTypes.string,
    createTemplateChecklist: PropTypes.func.isRequired,
    creating: PropTypes.bool.isRequired,
    hasTemplates: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    navItems: PropTypes.array.isRequired,
    resetTemplateChecklists: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
    selectedNavItem: PropTypes.object,
    templates: PropTypes.array.isRequired,
};

export const ChecklistsAdminList = compose(
    connectData(fetchData),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedChecklistsAdminList);

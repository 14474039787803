import React, { useContext, useState } from 'react';
import { formValueSelector, getFormSyncErrors } from 'redux-form';
import { useSelector } from 'react-redux';

import { form, fieldNames } from '../../../forms/constants';
import { fieldNames as formFieldNames } from '../../../forms/TemplateForm/constants';
import { SectionHeaderView } from '../../../../../../components/SDv2';
import { TemplateEditV2EditSection } from './ManageSections/EditSection';
import { TemplateEditV2NavContext, TemplateEditV2FunctionsContext } from '../../context';

const { PROJECT_SECTIONS } = fieldNames;
const { USE_MANUAL_NUMBERING } = formFieldNames;

const selector = formValueSelector(form);
const errorsSelectors = getFormSyncErrors(form);

export const TemplateSectionHeader = () => {
    const { activeSectionId, setActiveSectionId } = useContext(TemplateEditV2NavContext);
    const { disabled, updateTemplate } = useContext(TemplateEditV2FunctionsContext);
    const [editing, setEditing] = useState(false);
    const sections = useSelector((state) => selector(state, PROJECT_SECTIONS));
    const useManualNumbering = useSelector((state) => selector(state, USE_MANUAL_NUMBERING));
    const formErrors = useSelector((state) => errorsSelectors(state));

    const afterCancel = () => {
        setEditing(false);
    };

    const afterDelete = () => {
        setActiveSectionId(0);
        setEditing(false);
    };

    const afterSave = (modifiedSectionNewIndex = null) => {
        if (modifiedSectionNewIndex) {
            setActiveSectionId(modifiedSectionNewIndex);
        }

        if (updateTemplate) {
            setTimeout(updateTemplate, 1);
        }

        setEditing(false);
    };

    if (activeSectionId === null || !sections) {
        return null;
    }

    const selectedSection = sections[activeSectionId];

    if (!selectedSection) {
        return null;
    }

    if (!editing) {
        const hasError = formErrors?.projectSections?.[activeSectionId]?.hasErrors;

        return (
            <SectionHeaderView
                disabled={disabled}
                hasError={hasError}
                onEditSectionClick={() => setEditing(true)}
                section={selectedSection}
                showShortName
                useManualNumbering={useManualNumbering}
            />
        );
    }

    return (
        <TemplateEditV2EditSection
            afterCancel={afterCancel}
            afterDelete={afterDelete}
            afterSave={afterSave}
            allowDeletion
            index={activeSectionId}
        />
    );
};

TemplateSectionHeader.propTypes = {};

import { fieldNames } from './constants';
import { questionnaire as questionnaireValidator } from '../../../../../Forms/validation';

const { UPFRONT_QUESTIONS } = fieldNames;

export function validate(values) {
    const errors = {};

    errors[UPFRONT_QUESTIONS] = (values[UPFRONT_QUESTIONS] || []).map((question) =>
        questionnaireValidator(question)
    );

    return errors;
}

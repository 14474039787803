import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Field } from 'redux-form';

import { DATA, VALUE } from '@og-pro/shared-config/questionnaires';

import { GenericFormBuilder } from '../../SharedFormBuilder';
import { InputText } from '../../../..';

export class DataVerifyFormBuilder extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        form: PropTypes.string.isRequired,
        includeContainsPricingOption: PropTypes.bool,
        isTitleEditingDisabled: PropTypes.bool,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
        useRawPrompt: PropTypes.bool,
    };

    static defaultProps = {
        tagOptions: undefined,
        templateVariableOptions: undefined,
        useRawPrompt: false,
    };

    render() {
        const {
            disabled,
            form,
            includeContainsPricingOption,
            isTitleEditingDisabled,
            tagOptions,
            templateVariableOptions,
            useRawPrompt,
        } = this.props;

        return (
            <GenericFormBuilder
                disabled={disabled}
                form={form}
                includeContainsPricingOption={includeContainsPricingOption}
                isTitleEditingDisabled={isTitleEditingDisabled}
                tagOptions={tagOptions}
                templateVariableOptions={templateVariableOptions}
                useRawPrompt={useRawPrompt}
            >
                <Field
                    component={InputText}
                    disabled={disabled}
                    help="To add a wildcard value to your URL indicate the place the wildcard should be added using {}"
                    label="Data Verification Website URL"
                    name={`${DATA}.${VALUE}`}
                    placeholder="https://cadir.secure.force.com/ContractorSearch?inputSearch={}"
                    type="text"
                />
            </GenericFormBuilder>
        );
    }
}

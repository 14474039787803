import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { reduxForm, Field } from 'redux-form';

import { form, fieldNames } from './constants';
import { validate } from './validate';
import { LoadingButton, SearchSelect, SearchSelectUserOption } from '../../../..';

const { APPROVER_IDS } = fieldNames;

const formConfig = {
    form,
    validate,
};

// @reduxForm
class ConnectedAssignIntakeForm extends PureComponent {
    static propTypes = {
        addingApprovers: PropTypes.bool.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        invalid: PropTypes.bool.isRequired,
        isAbleToAssignApprover: PropTypes.bool.isRequired,
        isEditor: PropTypes.bool.isRequired,
        pristine: PropTypes.bool.isRequired,
        submitServerError: PropTypes.string,
        users: PropTypes.array.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const {
            addingApprovers,
            handleSubmit,
            invalid,
            isAbleToAssignApprover,
            isEditor,
            pristine,
            submitServerError,
            users,
        } = this.props;

        const disabled = !isEditor || !isAbleToAssignApprover;

        return (
            <form onSubmit={handleSubmit}>
                <Field
                    aria-label="Invite a user"
                    backspaceRemovesValue={false}
                    component={SearchSelect}
                    components={{
                        Option: SearchSelectUserOption,
                    }}
                    disabled={disabled || addingApprovers}
                    help={!disabled ? null : 'Only the assigned user can change the assignment'}
                    name={APPROVER_IDS}
                    options={users}
                    placeholder="Select user(s) or start typing a name"
                    showInviteUserHelp={!disabled}
                />
                <LoadingButton
                    block
                    bsStyle="success"
                    disabled={disabled || addingApprovers || pristine || invalid}
                    icon="fa-user-plus"
                    loading={addingApprovers}
                    text="Assign User"
                    tooltip={
                        !disabled ? undefined : 'Only the assigned user can change the assignment'
                    }
                    type="submit"
                />
                {submitServerError && <div className="error-block">{submitServerError}</div>}
            </form>
        );
    }
}

export const AssignIntakeForm = reduxForm(formConfig)(ConnectedAssignIntakeForm);

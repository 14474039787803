const get = require('lodash/get');

const { MULTIPLE_CHOICE } = require('../questionnaires');

const getDefaultValue = (upfrontQuestion) => {
    return get(upfrontQuestion, 'inputData.defaultValue');
};

exports.hasDefaultValue = (upfrontQuestion) => {
    // Add default value for upfront questions that have one specified
    const defaultValue = getDefaultValue(upfrontQuestion);
    return !!defaultValue || defaultValue === 0 || defaultValue === false;
};

exports.getDefaultValue = (upfrontQuestion) => {
    const hasDefaultValue = exports.hasDefaultValue(upfrontQuestion);
    if (hasDefaultValue) {
        const defaultValue = getDefaultValue(upfrontQuestion);
        return upfrontQuestion.type === MULTIPLE_CHOICE
            ? get(upfrontQuestion, 'data.options', [])[defaultValue]
            : defaultValue;
    }
};

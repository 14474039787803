import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { formValueSelector, initialize, reduxForm } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { CDSButton } from '../../../../../../../components';
import { projectSectionsToNumberedHierarchy } from '../../../../../../../components/SDv2/helpers';
import {
    ProjectCreateV2FunctionsContext,
    ProjectCreateManageSectionContext,
} from '../../../context';
import { projectCreateDrawerForm, fieldNames } from '../../../constants';
import { getOpenGovDndStyle } from '../../../../../../../constants/styles';
import { updateProjectSections } from '../../../../../../../actions/govProjects';
import {
    sortSections,
    moveArrayElement,
    getIndexToChangeParentDivider,
} from '../../../../../../../helpers';
import { ProjectCreateDrawerContentSection } from './Section';

const { PROJECT_SECTIONS, IS_DISPLAYED, IS_HIDDEN, ORDER_BY_ID, PARENT_DIVIDER } = fieldNames;

const SortableContainer = ({ children, disabled, allowSorting, handleDragEnd }) => {
    if (!allowSorting) {
        return children;
    }

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable
                droppableId="projectCreateSectionsList"
                isDropDisabled={disabled}
                type="projectSection"
            >
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        style={getOpenGovDndStyle(snapshot)}
                        {...provided.droppableProps}
                    >
                        {children}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

SortableContainer.propTypes = {
    allowSorting: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    handleDragEnd: PropTypes.func.isRequired,
};

const selector = formValueSelector(projectCreateDrawerForm);

export const ConnectedProjectCreateManageSectionsDrawerContent = ({ disableSortable, change }) => {
    const dispatch = useDispatch();
    // this feeds the context below, which is used in the DrawerContent edition functionalities
    const [manageSectionData, setManageSectionData] = useState({
        active: false,
        index: null,
    });

    const formSectionsValues = useSelector((state) => selector(state, PROJECT_SECTIONS));
    const { disabled, toggleManageSectionsDrawerOpened, project } = useContext(
        ProjectCreateV2FunctionsContext
    );
    const { useManualNumbering, useSectionDividers } = project;

    useEffect(() => {
        dispatch(
            initialize(projectCreateDrawerForm, {
                [PROJECT_SECTIONS]: project.projectSections.map((s) => ({
                    ...s,
                    [IS_DISPLAYED]: !s[IS_HIDDEN],
                })),
            })
        );
    }, []);

    const styles = require('../index.scss');
    const sections = projectSectionsToNumberedHierarchy(
        formSectionsValues || [],
        useManualNumbering
    );

    /**
     * Localized function to sort the sections, affecting only the form initialized in this
     * context
     * @param {Array<object>} updatedSections Array of sections. Used when the sections were already modified before getting to this function.
     * For example in the handleDragEnd function where we are manually moving array elements.
     *
     * @param {number} originalParentDividerIndex Index of the parent divider for a particular section. We use this to check if a section changed
     * its parent, whenever we have dividers and manual numbers enabled and the drag and drop is disabled - the change of parent is done by a
     * select -.
     */
    const formSortSections = (updatedSections = null, originalParentDividerIndex = null) => {
        const { index } = manageSectionData;

        let newParentIndex = null;

        if (!updatedSections) {
            // avoid this whenever we were called from a dragAndDrop action
            const parentDividerChanged =
                !Number.isNaN(formSectionsValues[index][PARENT_DIVIDER]) &&
                formSectionsValues[index][PARENT_DIVIDER] !== originalParentDividerIndex;

            if (parentDividerChanged) {
                newParentIndex = getIndexToChangeParentDivider(
                    formSectionsValues[index][PARENT_DIVIDER],
                    formSectionsValues.length
                );
            }
        }

        const sectionsToUseForChange = updatedSections || formSectionsValues;

        change(
            PROJECT_SECTIONS,
            sortSections({
                // if we need to change the parent divider, do the array moving before. Else use sections as is
                sections: newParentIndex
                    ? moveArrayElement(sectionsToUseForChange, index, newParentIndex)
                    : sectionsToUseForChange,
                useManualNumbering,
                useSectionDividers,
            })
        );
    };

    const handleDragEnd = (result) => {
        const originLocation = result.source.index;
        const newLocation = result.destination ? result.destination.index : undefined;

        if (newLocation !== undefined && newLocation !== originLocation) {
            setTimeout(() => {
                formSortSections(moveArrayElement(formSectionsValues, originLocation, newLocation));
            }, 0);
        }
    };

    const onSave = () => {
        dispatch(
            updateProjectSections(project.id, {
                [PROJECT_SECTIONS]: formSectionsValues.map((section, index) => {
                    return {
                        ...section,
                        [IS_HIDDEN]: !section[IS_DISPLAYED],
                        [ORDER_BY_ID]: index + 1,
                    };
                }),
            })
        );

        setTimeout(() => toggleManageSectionsDrawerOpened(), 0);
    };

    const sortable = !useManualNumbering && !disableSortable;

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.col}>
                    <h4>Document Outline</h4>
                </div>
                <div className={classnames(styles.col, styles.textRight)}>
                    <CDSButton
                        className={styles.marginRight}
                        onClick={() => toggleManageSectionsDrawerOpened()}
                        qaTag="projectCreate-drawerClose"
                        size="small"
                        variant="secondary"
                    >
                        Close
                    </CDSButton>
                    <CDSButton
                        onClick={() => onSave()}
                        qaTag="projectCreate-drawerSave"
                        size="small"
                        variant="primary"
                    >
                        Save Changes
                    </CDSButton>
                </div>
            </div>
            <ProjectCreateManageSectionContext.Provider
                value={{
                    change,
                    data: manageSectionData,
                    setData: setManageSectionData,
                    sortSections: formSortSections,
                }}
            >
                <SortableContainer
                    allowSorting={sortable}
                    disabled={disabled}
                    handleDragEnd={handleDragEnd}
                >
                    <div className={styles.sectionsContainer}>
                        {sections.map((section, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <ProjectCreateDrawerContentSection
                                        allowSorting={sortable}
                                        section={section}
                                        useSectionDividers={useSectionDividers}
                                    />
                                </React.Fragment>
                            );
                        })}
                    </div>
                </SortableContainer>
            </ProjectCreateManageSectionContext.Provider>
        </div>
    );
};

ConnectedProjectCreateManageSectionsDrawerContent.propTypes = {
    disableSortable: PropTypes.bool,
    change: PropTypes.func.isRequired,
};

export const ProjectCreateManageSectionsDrawerContent = reduxForm({
    form: projectCreateDrawerForm,
})(ConnectedProjectCreateManageSectionsDrawerContent);

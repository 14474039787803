// Used to toggle the routes to use for the particular app
export const getRoutes =
    process.env.APP_NAME === 'support'
        ? require('../supportApp/routes').default
        : require('../app/routes/index').default;

// Used to toggle the reducer to use for the particular app
export const rootReducer =
    process.env.APP_NAME === 'support'
        ? require('../supportApp/reducers').default
        : require('../app/reducers').default;

export const CLEANUP_DOCUMENTS = 'cleanupDocuments';
export const EXPIRED = 'expired';
export const POLICIES_ASSIGNED = 'policiesAssigned';
export const RECENTLY_CLOSED = 'recentlyClosed';

export const INTAKES_FILTER = 'intakes';
export const DOCUMENTS_FILTER = 'documents';
export const CONTRACTS_FILTER = 'contracts';
export const CONTRACTS_WITH_RENEWALS_FILTER = 'contractsWithRenewals';
export const CONTRACTS_WITHOUT_RENEWALS_FILTER = 'contractsWithoutRenewals';

export const filterLabels = {
    [INTAKES_FILTER]: 'Intakes',
    [DOCUMENTS_FILTER]: 'All Projects',
    [CONTRACTS_FILTER]: 'All Contract Records',
    [CONTRACTS_WITH_RENEWALS_FILTER]: 'Contract records with available renewals',
    [CONTRACTS_WITHOUT_RENEWALS_FILTER]: 'Contract records with no available renewals',
};

import { listToDict } from '@og-pro/shared-config/helpers';

import { contactPhoneFields } from '../../../../constants/contactFields';

const fields = [
    ...contactPhoneFields,
    'companyName',
    'contactEmail',
    'contactFirstName',
    'contactLastName',
    'contactWebsite',
    'submittedAt',
];

export const fieldNames = listToDict(fields);

import { useCustomFormService } from './useCustomFormService';
import { getCustomForm } from './queries';

/**
 * Load custom form
 * @param {number} customFormId
 * @returns {object} react-query usequery return value
 */
export const useLoadCustomForm = (customFormId) => {
    return useCustomFormService({
        queryKey: 'getCustomForm',
        query: getCustomForm,
        params: {
            customFormId,
        },
        placeholderData: {
            getCustomForm: {
                customFields: [],
            },
        },
    });
};

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { PageTitle } from '../../../components';
import { getReviewProjectJS as getGovernmentProjectJS } from '../selectors';
import { ProjectOverview as ProjectOverviewComponent } from '../../../components/GovApp/ProjectDetail/Introduction';
import { getProjectJS as getPublicProjectJS } from '../../PublicApp/selectors';
import { isPurchase } from '../../../helpers';

export const ProjectOverview = ({ project }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const section = searchParams.get('section');

    useEffect(() => {
        // This component is the default index for the view modes of the projects
        // if the project is not a purchase, this component won't display
        // so we are replacing to the /document tab which is the old "Project document"
        // and former index of the routing
        if ((project && !isPurchase(project.type)) || section) {
            navigate(
                {
                    pathname: `${location.pathname}/document`,
                    search: searchParams.toString(),
                },
                { replace: true }
            );
        }
    }, [project]);

    return (
        <div className="row project-section">
            <PageTitle title="Project overview" />
            <ProjectOverviewComponent
                government={project.government.organization}
                project={project}
            />
        </div>
    );
};

ProjectOverview.propTypes = {
    project: PropTypes.object.isRequired,
};

const GovernmentProjectOverview = (props) => {
    const project = useSelector(getGovernmentProjectJS);

    return <ProjectOverview {...{ ...props, project }} />;
};

const PublicProjectOverview = (props) => {
    const project = useSelector(getPublicProjectJS);

    return <ProjectOverview {...{ ...props, project }} />;
};

ProjectOverview.Public = PublicProjectOverview;
ProjectOverview.Government = GovernmentProjectOverview;

import classnames from 'classnames';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box } from '@og-pro/ui';

import {
    getTemplateSectionProjectSectionIdsMap,
    getTemplateSectionProjectSectionsMap,
} from './selectors';

export const SharedSectionLabel = ({
    className,
    includeLink,
    projectSection,
    small,
    style,
    useOpenGovStyle,
}) => {
    const navigate = useNavigate();
    const templateSectionProjectSectionsMap = useSelector(getTemplateSectionProjectSectionsMap);
    const templateSectionProjectSectionIdsMap = useSelector(getTemplateSectionProjectSectionIdsMap);
    const foundProjectSection = templateSectionProjectSectionsMap[projectSection.sharedId];
    const sectionTitle = get(foundProjectSection, 'title');
    const shortName = get(foundProjectSection, 'shortName');
    const projectSectionId = get(foundProjectSection, 'id');

    const templateSection = templateSectionProjectSectionIdsMap[projectSectionId];

    let clickHandler;
    if (includeLink && templateSection) {
        const link = `/governments/${templateSection.government_id}/templates-admin/shared-sections/${templateSection.id}`;
        clickHandler = (e) => {
            e.stopPropagation();
            navigate(link);
        };
    }

    const styles = require('./index.scss');

    if (useOpenGovStyle) {
        return (
            <Box className={styles.container}>
                <Box>
                    <i className="fa fa-lock" />
                </Box>
                <Box pt={0.5}>
                    <h4 className={styles.title}>This Section is Shared.</h4>
                    <div>
                        Content in sections that are shared cannot be edited from an individual
                        template.
                    </div>
                    <div className={styles.link} onClick={clickHandler}>
                        Edit Section Content
                    </div>
                </Box>
            </Box>
        );
    }

    return (
        <div
            className={classnames(styles.sharedSection, small && styles.small, className)}
            style={style}
        >
            <span className={classnames(clickHandler && 'pseudoLink')} onClick={clickHandler}>
                <i className="fa fa-external-link" /> Shared Section
                {sectionTitle && `: ${sectionTitle} (${shortName})`}
            </span>
        </div>
    );
};

SharedSectionLabel.propTypes = {
    className: PropTypes.string,
    includeLink: PropTypes.bool,
    projectSection: PropTypes.shape({
        sharedId: PropTypes.string.isRequired,
    }).isRequired,
    small: PropTypes.bool,
    style: PropTypes.object,
    useOpenGovStyle: PropTypes.bool,
};

const { listToDict } = require('../helpers');

exports.userStatuses = ['active', 'disabled', 'invited', 'requested', 'reset'];

exports.userStatusTypes = listToDict(exports.userStatuses);

exports.emailFrequencies = ['demand', 'mute', 'hourly'];

exports.emailFrequencyTypes = listToDict(exports.emailFrequencies);

exports.delayableFrequencies = ['hourly'];

exports.hasLoggedInWithPlatform = (usesOGPlatform, user) => {
    return (
        !usesOGPlatform ||
        user.isVendor ||
        user.status !== exports.userStatusTypes.ACTIVE ||
        user.isPlatformAuthorized
    );
};

/**
 * Scrubs sensitive information from the user object.
 *
 * @param {string} key - The key of the object property.
 * @param {any} value - The value of the object property.
 * @return {any} The scrubbed value.
 */
exports.userScrubber = (key, value) => {
    if (key === 'password') {
        return '*******';
    }
    return value;
};

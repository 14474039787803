import React from 'react';
import PropTypes from 'prop-types';
import { find, get } from 'lodash';

import CollapsiblePriceTable from './CollapsiblePriceTable';

export const FilteredPriceTables = ({
    descriptionFilter,
    displayAwardsColumn,
    headerClassName,
    isLoadingContractInfo,
    isPublicView,
    lineItemContractInfo,
    priceTables,
    projectId,
}) => {
    const tableContainsDescriptionMatch = ({ priceItems }) =>
        priceItems.filter(({ description }) =>
            description.toLowerCase().includes(descriptionFilter.toLowerCase())
        ).length > 0;

    return priceTables.filter(tableContainsDescriptionMatch).map((priceTable) => {
        const lineItemPriceTables = find(lineItemContractInfo && lineItemContractInfo.priceTables, {
            id: priceTable.id,
        });

        const lineItemContracts = get(lineItemContractInfo, 'contracts');

        return (
            <CollapsiblePriceTable
                descriptionFilter={descriptionFilter}
                displayAwardsColumn={displayAwardsColumn}
                evaluation={get(lineItemContractInfo, 'evaluation')}
                headerClassName={headerClassName}
                isLoadingContractInfo={isLoadingContractInfo}
                isPublicView={isPublicView}
                key={priceTable.id}
                lineItemContracts={lineItemContracts}
                lineItemPriceTables={lineItemPriceTables}
                priceTable={priceTable}
                projectId={projectId}
            />
        );
    });
};

FilteredPriceTables.propTypes = {
    descriptionFilter: PropTypes.string.isRequired,
    displayAwardsColumn: PropTypes.bool,
    headerClassName: PropTypes.string,
    isLoadingContractInfo: PropTypes.bool,
    isPublicView: PropTypes.bool,
    lineItemContractInfo: PropTypes.object,
    priceTables: PropTypes.arrayOf(
        PropTypes.shape({
            priceItems: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.number.isRequired,
                    description: PropTypes.string.isRequired,
                })
            ).isRequired,
        })
    ).isRequired,
    projectId: PropTypes.number.isRequired,
};

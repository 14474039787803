import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { ReportsModal } from '../ReportsModal';
import { Button, OutlineButton } from '../../../../components';

export class ReportsModalButton extends Component {
    static propTypes = {
        bsSize: PropTypes.string,
        dataForReport: PropTypes.any,
        icon: PropTypes.string,
        isOutline: PropTypes.bool,
        text: PropTypes.string,
        type: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
        };
    }

    showReportsModal = () => {
        this.setState({ showModal: true });
    };

    hideReportsModal = () => {
        this.setState({ showModal: false });
    };

    render() {
        const { dataForReport, icon, isOutline, text, type, ...props } = this.props;

        const { showModal } = this.state;

        const ButtonComponent = isOutline ? OutlineButton : Button;

        return (
            <>
                <ButtonComponent
                    onClick={this.showReportsModal}
                    qaTag="reportsModalButton-report"
                    {...props}
                >
                    <i className={`fa ${icon || 'fa-file-text-o'}`} /> {text || 'Report'}
                </ButtonComponent>
                {showModal && (
                    <ReportsModal
                        dataForReport={dataForReport}
                        hideModal={this.hideReportsModal}
                        reportsModalReportType={type}
                    />
                )}
            </>
        );
    }
}

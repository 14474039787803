const ARCHIVED = 0;
const DRAFT = 100;
const PUBLISHED = 200;

exports.statusTypes = {
    ARCHIVED,
    DRAFT,
    PUBLISHED,
};

exports.ARCHIVED = ARCHIVED;
exports.DRAFT = DRAFT;
exports.PUBLISHED = PUBLISHED;

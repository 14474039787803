import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';

import { form, reviewGroupPositionUsersFieldNames } from './constants';
import { Button, StatusLabel, UserProfilePicture } from '../../../../../components';

const { IS_PRIMARY } = reviewGroupPositionUsersFieldNames;

export const RequisitionGroupPositionsUsersField = ({ disabled, fields }) => {
    const dispatch = useDispatch();

    const onPrimarySelect = (selectedIndex) => {
        fields.forEach((name, index) => {
            dispatch(change(form, `${name}.${IS_PRIMARY}`, index === selectedIndex));
        });
    };

    if (fields.length === 0) {
        return (
            <div>
                <em>No users assigned</em>
            </div>
        );
    }

    const styles = require('./shared.scss');

    return (
        <div>
            {fields.map((member, index) => {
                const { isPrimary, user } = fields.get(index);
                return (
                    <div className={styles.reviewGroupPositionUser} key={user.id}>
                        <UserProfilePicture
                            className={styles.reviewGroupPositionUserProfile}
                            horizontal
                            user={user}
                        />
                        <div className={styles.reviewGroupPositionUserInfo}>
                            {user.displayName}&nbsp;
                            <StatusLabel bsStyle={isPrimary ? 'primary' : 'muted'}>
                                {isPrimary ? (
                                    <>
                                        <i className="fa fa-star" /> Primary
                                    </>
                                ) : (
                                    'Alternate'
                                )}
                            </StatusLabel>
                            {!isPrimary && (
                                <div className={styles.reviewGroupPositionUserAssignButton}>
                                    <Button
                                        bsSize="sm"
                                        bsStyle="link"
                                        disabled={disabled}
                                        onClick={() => onPrimarySelect(index)}
                                        qaTag="requisitionGroupEdit-makePrimary"
                                        zeroPadding
                                    >
                                        Make Primary
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

RequisitionGroupPositionsUsersField.propTypes = {
    disabled: PropTypes.bool,
    fields: PropTypes.object.isRequired,
};

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const OGThemeContext = React.createContext({ isOGThemeEnabledForComponents: false });

function OGThemeProvider({ isOGThemeEnabled = false, children }) {
    const isOGThemeEnabledForComponents = isOGThemeEnabled;

    const value = useMemo(
        () => ({ isOGThemeEnabledForComponents, isOGThemeLayoutEnabled: isOGThemeEnabled }),
        [isOGThemeEnabledForComponents]
    );

    return <OGThemeContext.Provider value={value}>{children}</OGThemeContext.Provider>;
}

OGThemeProvider.propTypes = {
    // Determines if section/area should use new OG theme and layout.
    isOGThemeEnabled: PropTypes.bool,
    children: PropTypes.node.isRequired,
};

function OGThemeConsumer({ children }) {
    return (
        <OGThemeContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('OGThemeConsumer must be used within a OGThemeProvider');
                }
                return children(context);
            }}
        </OGThemeContext.Consumer>
    );
}

OGThemeConsumer.propTypes = {
    children: PropTypes.func.isRequired,
};

function useOGTheme() {
    const context = React.useContext(OGThemeContext);
    if (context === undefined) {
        throw new Error('useCount must be used within a OGThemeProvider');
    }
    return context;
}

export {
    OGThemeContext, // use with class components in non-render methods
    OGThemeProvider,
    OGThemeConsumer, // use with class components in render method
    useOGTheme, // use with functional components
};

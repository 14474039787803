import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Main, SectionTitle, ZeroState } from '../../../../../components';
import { EvaluationCriteriaListDisplay } from '../../../../../components/GovApp';

export class EvaluationCriteriaReview extends PureComponent {
    static propTypes = {
        scoringCriteria: PropTypes.array.isRequired,
        totalWeight: PropTypes.number.isRequired,
    };

    render() {
        return (
            <Main className="evaluation-section">
                <SectionTitle
                    info={
                        'The criteria used to score vendor ' +
                        'responses during the evaluation process'
                    }
                    title="Evaluation Criteria"
                />
                {this.props.scoringCriteria.length === 0 ? (
                    <ZeroState title="No criteria have been added yet" />
                ) : (
                    <EvaluationCriteriaListDisplay
                        criteria={this.props.scoringCriteria}
                        totalWeight={this.props.totalWeight}
                    />
                )}
            </Main>
        );
    }
}

import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Panel } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { compose } from 'redux';
import { Outlet } from 'react-router-dom';
import { reduxForm, formValueSelector, Field, getFormValues } from 'redux-form';

import { evaluationStatuses } from '@og-pro/shared-config/evaluations';

import { projectStatusesDict } from '@og-pro/shared-config/projects';

import { progressSteps, form, formConfig as evaluationCreateFormConfig } from './constants';
import { mapStateToProps as evaluationCreateMapStateToProps } from './mapProps';
import { validateAll as validate } from './validate';
import {
    getDashboardPath,
    getEvaluationPath,
    getEvaluationWritingPath,
    isBudgetRequired,
    isBudgetUsed,
    isProjectIdRequired,
} from '../selectors';
import { isInitialClientLoaded } from '../../selectors';
import {
    initialize,
    initiateSubmit,
    initiateUpdate,
    showFormValidation,
    submitEvaluation,
    updateEvaluation,
} from '../../../actions/evaluations';
import {
    ConnectedClients,
    CreateFormNotEditable,
    LoadingError,
    Main,
    ProgressIconField,
    RouteLeaveWarning,
} from '../../../components';
import { FormControls, UpdateError } from '../../../components/GovApp';

const { COMPLETE, DRAFT } = evaluationStatuses;

const { EVALUATION } = projectStatusesDict;

const mapStateToProps = (state, props) => {
    const mappedProps = evaluationCreateMapStateToProps(state);
    const { project } = mappedProps;

    const isClientLoaded = isInitialClientLoaded(state);

    return {
        ...mappedProps,
        dashboardPath: getDashboardPath(state, props),
        evaluationPath: getEvaluationPath(state, props),
        formValues: getFormValues(form)(state) || {},
        initialValues: isClientLoaded ? project : undefined, // Initialize form with project data
        isBudgetRequired: isBudgetRequired(state), // Used by form `validate` function
        isBudgetUsed: isBudgetUsed(state), // Used by form `validate` function
        isClientLoaded,
        isEditableStatus:
            get(project, 'status') === EVALUATION && get(project, 'evaluation.status') !== COMPLETE,
        isProjectIdRequired: isProjectIdRequired(state), // Used by form `validate` function
        shouldSubmit: state.evaluations.get('shouldSubmit'),
        shouldUpdate: state.evaluations.get('shouldUpdate'),
        title: formValueSelector(form)(state, 'title') || 'Untitled',
        writingPath: getEvaluationWritingPath(state, props),
    };
};

const mapDispatchToProps = {
    initializeEvaluation: initialize,
    initiateSubmit,
    initiateUpdate,
    showFormValidation,
    submitEvaluation,
    updateEvaluation,
};

const formConfig = {
    ...evaluationCreateFormConfig,
    validate,
};

// @withRouter
// @connect
// @reduxForm
class ConnectedEvaluationCreate extends Component {
    static propTypes = {
        dashboardPath: PropTypes.string.isRequired,
        destroy: PropTypes.func.isRequired,
        dirty: PropTypes.bool.isRequired,
        evaluationPath: PropTypes.string.isRequired,
        formValues: PropTypes.object.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        hasQuestionnaire: PropTypes.bool.isRequired,
        initialize: PropTypes.func.isRequired,
        initializeEvaluation: PropTypes.func.isRequired,
        initiateSubmit: PropTypes.func.isRequired,
        initiateUpdate: PropTypes.func.isRequired,
        isClientLoaded: PropTypes.bool.isRequired,
        isEditableStatus: PropTypes.bool.isRequired,
        loadError: PropTypes.string,
        loading: PropTypes.bool.isRequired,
        location: PropTypes.object.isRequired,
        params: PropTypes.object.isRequired,
        project: PropTypes.shape({
            evaluation: PropTypes.shape({
                status: PropTypes.string.isRequired,
            }).isRequired,
            id: PropTypes.number.isRequired,
        }),
        router: PropTypes.object.isRequired,
        shouldSubmit: PropTypes.bool.isRequired,
        shouldUpdate: PropTypes.bool.isRequired,
        showFormErrors: PropTypes.bool.isRequired,
        showFormValidation: PropTypes.func.isRequired,
        submitEvaluation: PropTypes.func.isRequired,
        submitFailed: PropTypes.bool.isRequired,
        title: PropTypes.string.isRequired,
        updateError: PropTypes.string,
        updateEvaluation: PropTypes.func.isRequired,
        updating: PropTypes.bool.isRequired,
        writingPath: PropTypes.string.isRequired,
    };

    componentDidMount() {
        /**
         * Sets the internal state of the form component before mounting (unrelated to redux-form)
         */
        this.props.initializeEvaluation();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { isClientLoaded, project } = this.props;

        const { project: nextProject } = nextProps;

        /**
         * (DGW 2/24/19):
         * IMPORTANT: This form includes dates that need to be displayed in the user's timezone.
         * As such we cannot initialize the form with dates from the server as the dates from the
         * server will be adjusted to be in the server's local time. What we need are the dates in
         * the user's browser local time, so we need to wait for the client to load before
         * initializing the form data which includes these timezone dependent dates.
         *
         * The form can get initialized from:
         * 1. Server (via a page refresh or direct link)
         * 2. Front-end (via clicking into the form form another page)
         *
         * When initialized from the client (#2) the dates come from the browser and are properly
         * deserialized. This is handled in the connected `initialValues` prop.
         *
         * This initialization will get called when the form is loaded by the server (#1). The form
         * will not be initialized with values by the server and instead be initialized here
         * once the client has rendered. Doing the initialization this way allows the form to have
         * the correct dates that are localized for the browser's timezone.
         */
        if (nextProject && !isClientLoaded && nextProps.isClientLoaded) {
            this.initializeForm(nextProject);
        }

        /**
         * Whenever the project changes we want to reinitialize the form
         * to use the new project values.
         */
        if (nextProject !== project) {
            this.initializeForm(nextProject);
        }
    }

    componentDidUpdate(prevProps) {
        const { shouldSubmit, shouldUpdate } = this.props;

        if (shouldUpdate && !prevProps.shouldUpdate) {
            this.saveEvaluation();
        }

        if (shouldSubmit && !prevProps.shouldSubmit) {
            this.submitEvaluation();
        }
    }

    componentWillUnmount() {
        /**
         * We have to manually destroy the form when the parent container
         * is unmounted because we use the `destroyOnUnmount: false` option
         */
        this.props.destroy();
    }

    handleTitleClick = () => {
        const { dashboardPath, router } = this.props;

        router.push(dashboardPath);
    };

    initializeForm = (project) => {
        this.props.initialize(project);
        this.props.initializeEvaluation();
    };

    saveEvaluation = () => {
        const {
            formValues,
            project: { id },
        } = this.props;

        this.props.updateEvaluation(id, formValues, {
            notify: true,
        });
    };

    submitEvaluation = () => {
        const {
            project: { id },
        } = this.props;

        this.props.handleSubmit((data) => {
            return this.props.submitEvaluation(id, data);
        })();
    };

    get styles() {
        return require('./EvaluationCreate.scss');
    }

    get progressSteps() {
        const { project, hasQuestionnaire } = this.props;

        if (get(project, 'isEvaluationOnly')) {
            return progressSteps;
        }
        if (hasQuestionnaire) {
            return progressSteps.slice(2);
        }

        return progressSteps.slice(1);
    }

    get selectedSectionIndex() {
        const { pathname } = this.props.location;

        return this.progressSteps.findIndex((step, idx) => {
            return (
                pathname.match(new RegExp(`${step.section}/?$`, 'i')) ||
                (pathname.match(/\/create\/?$/i) && idx === 0)
            );
        });
    }

    get selectedSection() {
        return this.progressSteps[this.selectedSectionIndex] || {};
    }

    renderProgressIcons() {
        const { loading, writingPath, router } = this.props;

        if (loading) {
            return (
                <div className={this.styles.loadingHeader}>
                    <i className="fa fa-gear fa-spin text-muted" /> Loading Evaluation Data...
                </div>
            );
        }

        const selectedSection = this.selectedSection;
        return this.progressSteps.map((step, idx) => {
            const route = `${writingPath}/${step.section}`;
            return (
                <Field
                    {...step}
                    component={ProgressIconField}
                    hasPreviousIcon={idx !== 0}
                    isSelected={selectedSection.section === step.section}
                    key={step.icon}
                    name={`sections.${step.section}`}
                    onClick={() => router.push(route)}
                />
            );
        });
    }

    renderControlButtons() {
        const {
            evaluationPath,
            loading,
            params,
            project,
            project: {
                evaluation: { status },
            },
            showFormErrors,
            updating,
            writingPath,
        } = this.props;

        if (loading || !project) return null;

        const curPage = this.selectedSectionIndex;
        if (!curPage && curPage !== 0) return null;

        const hasPrevPage = curPage !== 0;
        const hasNextPage = curPage !== this.progressSteps.length - 1;

        const prevRoute = hasPrevPage
            ? `${writingPath}/${this.progressSteps[curPage - 1].section}`
            : undefined;
        const nextRoute = hasNextPage
            ? `${writingPath}/${this.progressSteps[curPage + 1].section}`
            : undefined;

        return (
            <Field
                component={FormControls}
                disabled={updating}
                isDraft={status === DRAFT}
                name="allSections"
                nextRoute={nextRoute}
                params={params}
                prevRoute={prevRoute}
                reviewPath={evaluationPath}
                saveHandler={this.props.initiateUpdate}
                showFormErrors={showFormErrors}
                showFormValidation={this.props.showFormValidation}
                submitButtonText="Complete Setup"
                submitHandler={this.props.initiateSubmit}
            />
        );
    }

    render() {
        const { isEditableStatus, loading, project, submitFailed, title, updateError } = this.props;

        const { container, panelBody, progressContainer, titleContainer } = this.styles;

        if (!project) {
            return <LoadingError />;
        }

        if (!isEditableStatus) {
            return (
                <CreateFormNotEditable text="Evaluation has been completed and is no longer editable" />
            );
        }

        return (
            <div className={`row ${container}`}>
                <RouteLeaveWarning blockingValue={this.props.dirty} />
                <h1 className="visually-hidden">Create Evaluation</h1>
                <div className={titleContainer}>
                    <h2 className="text-primary">
                        {!loading && (
                            <span className={this.styles.title} onClick={this.handleTitleClick}>
                                {title}
                            </span>
                        )}
                    </h2>
                </div>
                <div className={progressContainer}>{this.renderProgressIcons()}</div>
                <UpdateError error={updateError} submitFailed={submitFailed} />
                <div className="col-sm-offset-1 col-sm-10">
                    <ConnectedClients block />
                    <Panel>
                        <Panel.Body>
                            <Main className={panelBody}>
                                <Outlet />
                            </Main>
                            {this.renderControlButtons()}
                        </Panel.Body>
                    </Panel>
                </div>
            </div>
        );
    }
}

export const EvaluationCreate = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedEvaluationCreate);

import { showVendorAccountModal } from './adminVendor';
import { showLoginModal, hideLoginModal } from './auth';
import {
    hideConfirmationModal,
    showConfirmationModalError,
    updatingConfirmationModal,
} from './confirmation';
import { showNotification, showSnackbar } from './notification';
import { menuActionHandler } from './govProjects';
import { VENDOR_FOLLOW } from '../constants/menuActions';
import { getIncompleteRequiredVendorDatumKeysJS } from '../containers/selectors';

export const SUBSCRIBE_GOV_SUCCESS = 'subscribe/project/SUBSCRIBE_GOV_SUCCESS';
export const SUBSCRIBE_VENDOR_SUCCESS = 'subscribe/project/SUBSCRIBE_VENDOR_SUCCESS';

export function subscribeToProject(project, isGovernmentView, user, subscribeData, opts = {}) {
    return (dispatch, getState, client) => {
        if (opts.onStart) {
            opts.onStart();
        }
        if (opts.modal) {
            dispatch(updatingConfirmationModal());
        }
        const data = {
            ...subscribeData,
            projectId: project.id,
        };
        return client
            .post('/user/me/project/subscribe', { data })
            .then(() => {
                const type = isGovernmentView ? SUBSCRIBE_GOV_SUCCESS : SUBSCRIBE_VENDOR_SUCCESS;
                dispatch({ type, user });
                dispatch(showSnackbar('Following Project'));
                if (opts.onComplete) {
                    opts.onComplete();
                }
                if (opts.modal) {
                    dispatch(hideConfirmationModal());
                }
            })
            .catch((error) => {
                dispatch(showNotification(`Follow failed: ${error.message}`, { type: 'danger' }));
                if (opts.onComplete) {
                    opts.onComplete();
                }
                if (opts.modal) {
                    dispatch(showConfirmationModalError(error.message));
                }
            });
    };
}

export function subscribe(project, isGovernmentView, opts = {}) {
    return (dispatch, getState) => {
        const rawUser = getState().auth.get('user');

        // Prompt to login if user is not signed in
        if (!rawUser) {
            const loginHandler = () => {
                dispatch(hideLoginModal());
                // Reattempt to subscribe after logging in
                return dispatch(subscribe(project, isGovernmentView, opts));
            };
            return dispatch(
                showLoginModal(
                    'You must be logged in to subscribe to project updates. ' +
                        'Please log in or sign up!',
                    loginHandler
                )
            );
        }

        const user = rawUser.toJS();

        // Prompt vendor to complete vendor account info
        if (
            user.isVendor &&
            (getIncompleteRequiredVendorDatumKeysJS(getState()).length > 0 ||
                !user.organization.isVendorComplete) &&
            !opts.force
        ) {
            return dispatch(
                showVendorAccountModal({
                    onComplete: () =>
                        dispatch(subscribe(project, isGovernmentView, { ...opts, force: true })),
                })
            );
        }

        if (isGovernmentView) {
            return dispatch(subscribeToProject(project, true, user, {}, opts));
        }
        return dispatch(menuActionHandler(VENDOR_FOLLOW, project, opts));
    };
}

export const UNSUBSCRIBE_GOV_SUCCESS = 'subscribe/project/UNSUBSCRIBE_GOV_SUCCESS';
export const UNSUBSCRIBE_VENDOR_SUCCESS = 'subscribe/project/UNSUBSCRIBE_VENDOR_SUCCESS';

export function unsubscribe(project, isGovernmentView, opts = {}) {
    if (opts.onStart) {
        opts.onStart();
    }
    return (dispatch, getState, client) => {
        return client
            .post('/user/me/project/unsubscribe', { data: { projectId: project.id } })
            .then(() => {
                const userId = getState().auth.getIn(['user', 'id']);
                const type = isGovernmentView
                    ? UNSUBSCRIBE_GOV_SUCCESS
                    : UNSUBSCRIBE_VENDOR_SUCCESS;
                dispatch({ type, userId });
                dispatch(showSnackbar('Unfollowed Project'));
                if (opts.onComplete) {
                    opts.onComplete();
                }
            })
            .catch((error) => {
                dispatch(showNotification(`Unfollow failed: ${error.message}`, { type: 'danger' }));
                if (opts.onComplete) {
                    opts.onComplete();
                }
            });
    };
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import { hideRefreshModal } from '../../actions/app';
import { Button } from '../../components';

const mapStateToProps = (state) => {
    return {
        showModal: state.app.get('showRefreshModal'),
    };
};

const mapDispatchToProps = {
    hideModal: hideRefreshModal,
};

// @connect
class ConnectedRefreshModal extends Component {
    static propTypes = {
        hideModal: PropTypes.func.isRequired,
        showModal: PropTypes.bool.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    reloadPage = () => {
        window.location.reload();
    };

    render() {
        const { hideModal, showModal } = this.props;

        return (
            <Modal onHide={hideModal} show={showModal}>
                <Modal.Header closeButton>
                    <Modal.Title>We need you to reload OpenGov</Modal.Title>
                </Modal.Header>
                <Modal.Body className={this.styles.container}>
                    <p>
                        While you were working, we were too! To incorporate the updates, simply
                        click <strong>Reload</strong>.
                    </p>
                    <p>
                        If you were in the middle of typing something, please close this message,
                        copy the text, and then refresh.
                    </p>
                    <p>
                        We apologize for interrupting your flow. Thank you for accommodating us as
                        we continually improve OpenGov.
                    </p>
                    <div className="text-right">
                        <Button
                            bsStyle="primary"
                            onClick={this.reloadPage}
                            qaTag="connectedRefreshModal-reload"
                        >
                            <i className="fa fa-refresh" /> Reload
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export const RefreshModal = connect(mapStateToProps, mapDispatchToProps)(ConnectedRefreshModal);

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { UserProfileNavItem } from './UserProfileNavItem';
import { Nav, NavItem, MenuItem } from '..';

export const VendorNavbar = (props) => {
    const { pathname } = useLocation();

    const vendorPath = `/vendors/${props.user.vendor_id}`;

    const renderNavItems = () => {
        const proposalsPath = `${vendorPath}/proposals`;
        return [
            {
                title: 'Responses',
                link: proposalsPath,
                icon: 'file-text',
                active: !!pathname.match(new RegExp(`^${proposalsPath}`)),
            },
            {
                title: 'Awards',
                link: `${vendorPath}/awards`,
                icon: 'trophy',
                active: !!pathname.match(new RegExp(`^${vendorPath}/awards`)),
            },
            {
                title: 'Checklists',
                link: `${vendorPath}/checklists`,
                icon: 'check',
                active: !!pathname.match(new RegExp(`^${vendorPath}/checklists`)),
            },
            {
                title: 'Subscriptions',
                link: `${vendorPath}/my-subscriptions`,
                icon: 'rss',
                active: !!pathname.match(new RegExp(`^${vendorPath}/my-subscriptions`)),
            },
            {
                title: 'Network',
                link: `${vendorPath}/open-bids`,
                icon: 'share-alt',
                active: !!pathname.match(new RegExp(`^${vendorPath}/open-bids`)),
            },
        ].map((item) => {
            return (
                <NavItem
                    className={item.active ? 'active' : null}
                    eventKey={item.link}
                    key={item.title}
                    qaTag={`navbar-${item.title}`}
                    to={item.link}
                >
                    <i className={`fa fa-${item.icon}`} /> {item.title}
                </NavItem>
            );
        });
    };

    const renderNavRightItems = () => {
        const { isSystemAdmin, logout, user } = props;

        const accountPath = `${vendorPath}/account`;
        const adminPath = `${vendorPath}/admin`;
        const profilePath = `${vendorPath}/profile`;

        const isActive =
            !!pathname.match(new RegExp(`^${accountPath}`)) ||
            !!pathname.match(new RegExp(`^${adminPath}`)) ||
            !!pathname.match(new RegExp(`^${profilePath}`));

        return (
            <UserProfileNavItem
                className={classnames('user-nav', isActive && 'active')}
                user={user}
            >
                <MenuItem qaTag="navbar-settings" to={accountPath}>
                    <i className="fa fa-gear fa-fw" /> Settings
                </MenuItem>
                <MenuItem qaTag="navbar-companyProfile" to={profilePath}>
                    <i className="fa fa-user fa-fw" /> Company Profile
                </MenuItem>
                {isSystemAdmin && (
                    <MenuItem qaTag="navbar-admin" to={adminPath}>
                        <i className="fa fa-star fa-fw" /> Admin
                    </MenuItem>
                )}
                <MenuItem divider qaTag="navbar-divider" />
                <MenuItem onClick={logout} qaTag="navbar-logOut">
                    <i className="fa fa-sign-out fa-fw" /> Log out
                </MenuItem>
            </UserProfileNavItem>
        );
    };

    return (
        <div>
            <Nav>{renderNavItems()}</Nav>
            <Nav pullRight role="list">
                {renderNavRightItems()}
            </Nav>
        </div>
    );
};

VendorNavbar.propTypes = {
    isSystemAdmin: PropTypes.bool.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    logout: PropTypes.func.isRequired,
    user: PropTypes.shape({
        vendor_id: PropTypes.number.isRequired,
    }).isRequired,
};

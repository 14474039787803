import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, FieldArray } from 'redux-form';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { extendSimpleWizardForm } from '../../../../hocs';
import { formConfig, fieldNames } from '../constants';
import { SectionTitle } from '../../../../components';
import { ProposalDocumentsList } from '../../../../components/GovApp';
import { mapStateToProps } from '../mapProps';
import { instructions, helpPopover } from './constants';

const { PROPOSAL_DOCUMENTS } = fieldNames;

// @connect
// @reduxForm
// @extendSimpleWizardForm
class ConnectedProjectProposalDocuments extends Component {
    static propTypes = {
        updating: PropTypes.bool.isRequired,
        showFormErrors: PropTypes.bool.isRequired,
    };

    render() {
        return (
            <div className="row">
                <div className="col-sm-offset-1 col-sm-10">
                    <SectionTitle
                        help={helpPopover}
                        info={instructions}
                        title="Response Documents"
                    />
                    <label>Response Documents</label>
                    <FieldArray
                        component={ProposalDocumentsList}
                        disabled={this.props.updating}
                        name={PROPOSAL_DOCUMENTS}
                        showFormErrors={this.props.showFormErrors}
                    />
                </div>
            </div>
        );
    }
}

export const ProjectProposalDocuments = compose(
    withRouter,
    connect(mapStateToProps),
    reduxForm(formConfig),
    extendSimpleWizardForm()
)(ConnectedProjectProposalDocuments);

exports.NATURAL_DISASTER_EMERGENCY_PROVIDER = 'Natural Disaster Emergency Provider';
exports.HEALTH_CRISIS_EMERGENCY_PROVIDER = 'Health Crisis Emergency Provider';
exports.OTHER_EMERGENCY_PROVIDER = 'Other Emergency Provider';

exports.vendorEmergencyDesignationsColumnMapping = {
    [exports.NATURAL_DISASTER_EMERGENCY_PROVIDER]: 'emergency_disaster',
    [exports.HEALTH_CRISIS_EMERGENCY_PROVIDER]: 'emergency_health',
    [exports.OTHER_EMERGENCY_PROVIDER]: 'emergency_other',
};

exports.vendorEmergencyDesignationsStringBuilder = (data) => {
    const { emergencyDisaster, emergencyHealth, emergencyOther } = data;

    const selectedDesignations = [];

    if (emergencyDisaster) {
        selectedDesignations.push('Natural Disaster Emergency Provider');
    }
    if (emergencyHealth) {
        selectedDesignations.push('Health Crisis Emergency Provider');
    }
    if (emergencyOther) {
        selectedDesignations.push('Other Emergency Provider');
    }

    return selectedDesignations.length > 0 ? selectedDesignations.join(', ') : '';
};

import React, { useContext } from 'react';
import { Box } from '@og-pro/ui';

import { INPUT_DATA } from '@og-pro/shared-config/questionnaires';

import { UpfrontQuestionsResponseForm } from '../../../../../../components';
import { ProjectCreateV2FunctionsContext } from '../../context';

export const SDv2UpfrontQuestionsFormAdapter = () => {
    const { change, disabled, project, showFormErrors } = useContext(
        ProjectCreateV2FunctionsContext
    );

    if (project.upfrontQuestions.length === 0) {
        return (
            <Box bgcolor="white" border="1px solid #E5E5E5" p={2} textAlign="center">
                This project has no set up questions
            </Box>
        );
    }

    return (
        <UpfrontQuestionsResponseForm
            change={change}
            disabled={disabled}
            formKey="upfrontQuestions"
            responsePath={INPUT_DATA}
            showValidation={showFormErrors}
        />
    );
};

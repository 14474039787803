import { get, isNumber } from 'lodash';

import { COMMENT, SCORE } from './constants';
import { hasFormErrors } from '../../../../../helpers';

const hasScore = (score) => isNumber(score);

export const warn = (values) => {
    const warnings = {};

    Object.entries(values).forEach(([key, value]) => {
        if (!hasScore(get(value, SCORE))) {
            warnings[key] = {
                [SCORE]: 'Each criteria must receive a score',
            };
        }
    });

    return {
        ...warnings,
        _warning: hasFormErrors(warnings), // Used to check if evaluation is ready to be submitted
    };
};

export const validate = (values, props) => {
    const errors = {};

    if (!props.isCommentRequired) {
        return errors;
    }

    Object.entries(values).forEach(([key, value]) => {
        if (hasScore(get(value, SCORE)) && !(get(value, COMMENT) || '').trim()) {
            errors[key] = {
                [COMMENT]: 'Comment is required',
            };
        }
    });

    return errors;
};

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { excludeQuestionForm } from '../constants';
import { excludeOptions, EXCLUDE_REASON } from './constants';
import { MultiChoiceWithOtherForm } from '../MultiChoiceWithOtherForm';

export class ExcludeQuestionForm extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        onSubmit: PropTypes.func.isRequired,
    };

    render() {
        const { disabled, onSubmit } = this.props;

        return (
            <MultiChoiceWithOtherForm
                disabled={disabled}
                fieldName={EXCLUDE_REASON}
                form={excludeQuestionForm}
                info="Please specify why you are excluding this question."
                onSubmit={onSubmit}
                options={excludeOptions}
                otherLabel="Other Reason for Excluding"
            />
        );
    }
}

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button } from '../../../../../components';
import { getProjectAssociationsJS, getProjectId } from './selectors';
import { loadAssociatedProjects } from '../../../../../actions/govProjects';

const mapStateToProps = (state) => {
    return {
        loading: state.govProjects.get('loadingProjectAssociations'),
        projectId: getProjectId(state),
        projects: getProjectAssociationsJS(state),
    };
};

const mapDispatchToProps = {
    loadAssociatedProjects,
};

// @connect
class ConnectedAssociatedProjects extends Component {
    static propTypes = {
        loadAssociatedProjects: PropTypes.func.isRequired,
        loading: PropTypes.bool,
        projectId: PropTypes.number.isRequired,
        projects: PropTypes.arrayOf(
            PropTypes.shape({
                dashboardLink: PropTypes.string.isRequired,
                id: PropTypes.number.isRequired,
                title: PropTypes.string.isRequired,
            })
        ).isRequired,
    };

    loadAssociatedProjects = () => {
        return this.props.loadAssociatedProjects(this.props.projectId);
    };

    render() {
        const { loading, projects } = this.props;

        if (projects.length === 0) {
            const icon = loading ? 'fa-spinner fa-spin' : 'fa-files-o';

            return (
                <Button
                    bsStyle="link"
                    disabled={loading}
                    onClick={this.loadAssociatedProjects}
                    zeroPadding
                >
                    <i className={`fa fa-fw ${icon}`} /> Show Associated Projects
                </Button>
            );
        }

        return projects.map((project) => (
            <div key={project.id}>
                <Button bsStyle="link" to={project.dashboardLink} zeroPadding>
                    {project.title}
                </Button>
            </div>
        ));
    }
}

export const AssociatedProjects = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedAssociatedProjects);

import { showNotification } from '../notification';

export const SAVE = 'account/security/SAVE';
export const SAVE_SUCCESS = 'account/security/SAVE_SUCCESS';
export const SAVE_FAIL = 'account/security/SAVE_FAIL';

export function save(data) {
    return (dispatch, getState, client) => {
        dispatch({ type: SAVE });

        const postData = { data };

        return client
            .post('/auth/update-password', postData)
            .then((result) => {
                dispatch({
                    type: SAVE_SUCCESS,
                    result,
                });
                dispatch(showNotification('Your password has been successfully changed'));
            })
            .catch((error) => {
                const defaultMessage =
                    'uh-oh, something happened! Have you checked your connection?';
                const errorMessage = error.message || defaultMessage;

                dispatch({ type: SAVE_FAIL, error });
                dispatch(showNotification(errorMessage, { type: 'danger' }));
            });
    };
}

/* global Dynamsoft */
import React, { useEffect, useRef, useState } from 'react';
import { change, getFormValues, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { ArrowDropDown as ArrowDropDownIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Menu, MenuItem } from '@og-pro/ui';

import { formConfig } from './form';
import {
    SCANNER_CONTROL_SECTION,
    ScanningControlsPanel,
} from './ControlPanelSections/ScanningControlsPanel';
import {
    DYNAMSOFT_RESOURCES_PATH,
    fieldNames,
    NO_SOURCES_FOUND_MESSAGE,
    RETRY_TIMEOUT,
} from './constants';
import { PanNCropControls, RotateNMirrorControls, ZoomControls } from './EditionBarControls';
import {
    FileManagementSection,
    ImageControlsSection,
    ScannerControlsSection,
} from './ControlPanelSections';

import { ScannedPagesViewer } from './ScannedPagesViewer';
import { ScanningPagination } from './ScanningPagination';

const {
    FILE_NAME,
    COLOR_MODE,
    RESOLUTION,
    SCAN_SOURCE,
    USE_ADF,
    DUPLEX,
    BLANK_PAGE_DETECTION,
    IMAGE_BRIGHTNESS,
    IMAGE_CONTRAST,
} = fieldNames;

const CONTAINER_ID = 'dwtControlContainer';

// @reduxForm
export const ConnectedDynamsoftDocumentScanner = ({ onUpload, onClose }) => {
    const dispatch = useDispatch();

    const styles = require('./index.scss');

    const [DWObject, setDWObject] = useState(null);
    const [DWObjectError, setDWObjectError] = useState(false);

    const [isDWTBusy, setIsDWTBusy] = useState(false);
    const [sourceListLoadError, setSourceListLoadError] = useState(null);

    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    const loadDWTRetryTimeout = useRef(null);

    const {
        [SCAN_SOURCE]: selectedSource,
        [USE_ADF]: ifFeederEnabled,
        [BLANK_PAGE_DETECTION]: ifAutoDiscardBlankpages,
        [DUPLEX]: ifDuplexEnabled,
        [COLOR_MODE]: pixelType,
        [RESOLUTION]: resolution,
        [IMAGE_BRIGHTNESS]: brightness,
        [IMAGE_CONTRAST]: contrast,
        [FILE_NAME]: fileName,
    } = useSelector((state) => getFormValues(formConfig.form)(state) || {});

    useEffect(() => {
        if (DWObject) {
            if (currentPage !== null) {
                DWObject.Viewer.gotoPage(currentPage - 1);
            }
        }
    }, [DWObject, currentPage]);

    const handleReadinessChange = (DWTBusy, err) => {
        setIsDWTBusy(DWTBusy);
        setSourceListLoadError(err);
    };

    const load = () => {
        dispatch(change(formConfig.form, COLOR_MODE, Dynamsoft.DWT.EnumDWT_PixelType.TWPT_RGB));
        try {
            Dynamsoft.DWT.RegisterEvent('OnWebTwainReady', () => {
                const newDWObject = Dynamsoft.DWT.GetWebTwain(CONTAINER_ID);
                newDWObject.Viewer.border = '';
                newDWObject.Viewer.background = 'rgba(247, 249, 250, 1)';
                newDWObject.Viewer.height = '100%';
                newDWObject.Viewer.width = '100%';
                newDWObject.Viewer.allowSlide = false;
                setDWObject(newDWObject);
                setDWObjectError(false);
                const objThumbnailViewer = newDWObject.Viewer.createThumbnailViewer({
                    size: 200,
                    pageNumber: {
                        visibility: 'visible',
                    },
                });
                objThumbnailViewer.background = 'transparent';
                objThumbnailViewer.show();
                newDWObject.Viewer.on('pageRendered', (index) => {
                    setCurrentPage(index + 1);
                });
            });

            Dynamsoft.DWT.RegisterEvent('OnWebTwainError', () => {
                setDWObjectError(true);
                loadDWTRetryTimeout.current = setTimeout(() => {
                    load();
                }, RETRY_TIMEOUT);
            });

            Dynamsoft.DWT.ProductKey = process.env.DYNAMSOFT_PRODUCT_KEY;

            Dynamsoft.DWT.ResourcesPath = DYNAMSOFT_RESOURCES_PATH;

            Dynamsoft.DWT.Containers = [
                {
                    WebTwainId: 'dwtObject',
                    ContainerId: CONTAINER_ID,
                    Background: 'transparent',
                },
            ];

            Dynamsoft.DWT.Load();
        } catch (e) {
            setDWObjectError(true);
        }
    };

    useEffect(() => {
        load();
        return () => {
            clearTimeout(loadDWTRetryTimeout.current);
        };
    }, []);

    useEffect(() => {
        if (DWObject && pageCount > 0 && DWObject.Viewer) {
            DWObject.Viewer.selectedPageBackground = 'rgb(239,235,255)';
            DWObject.Viewer.selectedPageBorder = '1px solid rgb(107,71,122)';
        }
    }, [DWObject, pageCount]);

    const acquireImage = () => {
        if (!DWObject) {
            return;
        }

        return DWObject.AcquireImageAsync({
            IfDisableSourceAfterAcquire: true,
            IfFeederEnabled: ifFeederEnabled,
            IfAutoDiscardBlankpages: ifAutoDiscardBlankpages,
            IfDuplexEnabled: ifDuplexEnabled,
            PixelType: pixelType,
            Brightness: brightness || 0,
            Contrast: contrast || 0,
            Resolution: resolution || 200, // Loading the resolution list takes a while, if it's not loaded yet, default to 200
        })
            .then(() => {
                setPageCount(DWObject.HowManyImagesInBuffer);
            })
            .catch((exp) => {
                // ToDo: handle error
                console.error(exp.message);
            })
            .finally(() => {
                DWObject.CloseSourceAsync().catch((e) => {
                    // ToDo: handle error
                    console.error(e);
                });
            });
    };

    const prepareAndUpload = () => {
        const indices = [...Array(pageCount).keys()];
        const type = Dynamsoft.DWT.EnumDWT_ImageType.IT_PDF;
        DWObject.ConvertToBlob(
            indices,
            type,
            function (blob) {
                const scannedFile = new File([blob], `${fileName}.pdf`, {
                    type: 'application/pdf',
                    lastModified: new Date().getTime(),
                });

                onUpload([scannedFile]);
            },
            function (errorCode, errorString) {
                // ToDo: handle error
                console.error(errorString);
            }
        );
    };

    const showScanningViewer = !!DWObject && !DWObjectError && pageCount > 0 && !isDWTBusy;
    const isSomePageSelected =
        pageCount > 0 && DWObject && DWObject.CurrentImageIndexInBuffer !== -1;

    const [menuElement, setMenuElement] = useState(null);
    const open = Boolean(menuElement);
    const handleDeleteMenuItemClick = (event) => {
        setMenuElement(event.currentTarget);
    };
    const handleDeleteMenuClose = () => {
        setMenuElement(null);
    };

    const removeCurrentImage = () => {
        DWObject.RemoveImage(DWObject.CurrentImageIndexInBuffer);
        setPageCount(DWObject.HowManyImagesInBuffer);
        setMenuElement(null);
    };

    const removeAllImages = () => {
        DWObject.RemoveAllImages();
        setPageCount(0);
        setMenuElement(null);
    };

    const isWorking = (!DWObject && !DWObjectError) || isDWTBusy;
    const noScannerFound = !isDWTBusy && sourceListLoadError === NO_SOURCES_FOUND_MESSAGE;
    const isReadRForFirstScan =
        !isDWTBusy && !DWObjectError && !sourceListLoadError && pageCount === 0 && !!DWObject;
    const isScannerSourceSelected = Number.isInteger(selectedSource);
    const needsSetup = DWObjectError && !isWorking;

    return (
        <>
            <div className={styles.scanningDialogContainer}>
                <div className={styles.leftPanel}>
                    <div className={styles.editionBarContainer}>
                        <div className={styles.fakeThumbnailTop} />
                        <div className={styles.editionBar}>
                            <div className={styles.editionBarLeft}>
                                <PanNCropControls
                                    DWObject={DWObject}
                                    disabled={!isSomePageSelected}
                                />
                                <RotateNMirrorControls
                                    DWObject={DWObject}
                                    disabled={!isSomePageSelected}
                                />
                                <ZoomControls DWObject={DWObject} disabled={!isSomePageSelected} />
                            </div>
                            <div className={styles.editionBarRight}>
                                {DWObject && !DWObjectError && (
                                    <Button
                                        color="primary"
                                        disabled={!Number.isInteger(selectedSource)}
                                        onClick={() => acquireImage()}
                                        qaTag="documentScanner-scan"
                                        variant="outlined"
                                    >
                                        Scan {pageCount > 0 && ' Another Image'}
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        className={
                            showScanningViewer
                                ? styles.scanningViewport
                                : styles.hiddenScanningViewport
                        }
                        id={CONTAINER_ID}
                    />
                    {!showScanningViewer && (
                        <ScannedPagesViewer
                            acquireImage={acquireImage}
                            isReadRForFirstScan={isReadRForFirstScan}
                            isScannerSourceSelected={isScannerSourceSelected}
                            isWorking={isWorking}
                            needsSetup={needsSetup}
                            noScannerFound={noScannerFound}
                        />
                    )}
                </div>
                <ScanningControlsPanel defaultOpened={SCANNER_CONTROL_SECTION}>
                    <ScannerControlsSection
                        DWObject={DWObject}
                        onReadinessChange={handleReadinessChange}
                    />
                    <ImageControlsSection DWObject={DWObject} />
                    <FileManagementSection />
                </ScanningControlsPanel>
            </div>
            <div className={styles.uploadButtonContainer}>
                <Button
                    color="error"
                    disabled={
                        pageCount === 0 || !DWObject || DWObject.CurrentImageIndexInBuffer === -1
                    }
                    endIcon={<ArrowDropDownIcon />}
                    id="deleteButton"
                    onClick={handleDeleteMenuItemClick}
                    qaTag="documentScanner-delete"
                    startIcon={<DeleteIcon />}
                    variant="text"
                >
                    Remove
                </Button>
                <Menu
                    anchorEl={menuElement}
                    aria-labelledby="deleteButton"
                    id="deleteButtonMenu"
                    onClose={handleDeleteMenuClose}
                    open={open}
                >
                    <MenuItem onClick={removeCurrentImage} value={10}>
                        Remove Current
                    </MenuItem>
                    <MenuItem onClick={removeAllImages} value={20}>
                        Remove All
                    </MenuItem>
                </Menu>
                <ScanningPagination
                    currentPage={currentPage}
                    onPageChange={setCurrentPage}
                    pageCount={pageCount}
                />
                <div className={styles.rightGroup}>
                    <Button
                        color="primary"
                        onClick={onClose}
                        qaTag="documentScanner-cancel"
                        variant="text"
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        disabled={pageCount === 0 || !fileName}
                        onClick={prepareAndUpload}
                        qaTag="documentScanner-attach"
                        variant="contained"
                    >
                        Attach
                    </Button>
                </div>
            </div>
        </>
    );
};

ConnectedDynamsoftDocumentScanner.propTypes = {
    onUpload: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export const DynamsoftDocumentScanner = reduxForm(formConfig)(ConnectedDynamsoftDocumentScanner);

import { getSFChatConfig } from '../constants/salesForceEmbeddedService';

/**
 * Initializes a SalesForce Service Cloud chat session
 * Agency user chats will persist and require an authToken for JWT authentication
 * Vendor user chats will not persist and do not require an authToken
 * @param {object} user      User model instance
 * @param {object} config    SalesForce chat configuration
 * @returns {void}
 */
const initEmbeddedMessaging = (user, config) => {
    window.embeddedservice_bootstrap.settings.language = 'en_US'; // For example, enter 'en' or 'en-US'

    // Snippet for hidden prechat in OG platform
    window.addEventListener('onEmbeddedMessagingReady', () => {
        // set prechat fields
        window.embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields({
            FirstName: user.firstName || '', // Platform Users First Name
            LastName: user.lastName || '', // Platform Users Last Name
            Email: user.email || '', // Platform Users email
            UserRole: config.userRole, // Platform Users role
            Suite: 'Procurement', // This value should be dynamic based on the platform,
            // Restricted Active Values: Budgeting_Planning; Procurement; Financials; Permitting_Licensing; EnterpriseAssetManagement
        });
    });

    window.embeddedservice_bootstrap.init(config.id, config.name, config.url, {
        scrt2URL: config.scrt2Url,
    });
};

/**
 * Grabs an agency user's JWT token with audience set to SalesForce, and starts a new chat session
 * @param {object} user      User model instance
 * @returns {Promise<void>}
 */
export const getAgencyChat = (user) => () => {
    const chatConfig = getSFChatConfig();
    const config = {
        id: chatConfig.ID,
        name: chatConfig.AGENCY_NAME,
        url: chatConfig.AGENCY_URL,
        scrt2Url: chatConfig.SCRT2_URL,
        userRole: 'Agency',
    };
    initEmbeddedMessaging(user, config);
};

/**
 * Starts a new chat session for vendor users. No auth0 JWT validation required.
 * @param {object} user      User model instance
 * @returns {Promise<void>}
 */
export const getVendorChat = (user) => () => {
    const chatConfig = getSFChatConfig();
    const config = {
        id: chatConfig.ID,
        name: chatConfig.VENDOR_NAME,
        url: chatConfig.VENDOR_URL,
        scrt2Url: chatConfig.SCRT2_URL,
        userRole: 'Vendor',
    };
    initEmbeddedMessaging(user, config);
};

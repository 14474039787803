import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { compose } from 'redux';
import { arrayPush, arrayRemove, Field, FieldArray } from 'redux-form';

import { fieldNames, form, noTagsMessage } from '../constants';
import { getContractAttachmentsBeingCreatedJS } from './selectors';
import {
    deleteContractAttachment,
    uploadAndCreateContractAttachment,
    updateContractAttachment,
} from '../../../../actions/contracts';
import { FileUploadAttachment, SearchSelect, StyledDropzone, UploadProgress } from '../../..';
import { getContractAttachmentTagSelectOptions } from '../../../../containers/selectors';

const { ATTACHMENTS, TAGS } = fieldNames;

const mapStateToProps = (state) => {
    return {
        contractAttachmentTagSelectOptions: getContractAttachmentTagSelectOptions(state),
        uploadingAttachments: getContractAttachmentsBeingCreatedJS(state),
    };
};

const mapDispatchToProps = {
    arrayPush,
    arrayRemove,
    deleteContractAttachment,
    uploadAndCreateContractAttachment,
    updateContractAttachment,
};

// @withRouter
// @connect
class ConnectedContractAttachments extends Component {
    static propTypes = {
        arrayPush: PropTypes.func.isRequired,
        arrayRemove: PropTypes.func.isRequired,
        contractAttachmentTagSelectOptions: PropTypes.array,
        contractId: PropTypes.number.isRequired,
        deleteContractAttachment: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        uploadAndCreateContractAttachment: PropTypes.func.isRequired,
        uploadingAttachments: PropTypes.object.isRequired,
        updateContractAttachment: PropTypes.object.isRequired,
    };

    static defaultProps = {
        disabled: false,
    };

    get styles() {
        return require('./index.scss');
    }

    updateAttachmentHandler = () => {
        return (attachment) => {
            const { contractId } = this.props;

            return this.props.updateContractAttachment(contractId, attachment.id, attachment);
        };
    };

    deleteAttachmentHandler = (index) => {
        return (attachment) => {
            const { contractId } = this.props;

            return this.props
                .deleteContractAttachment(contractId, attachment.id)
                .then((attachmentOrError) => {
                    if (!(attachmentOrError instanceof Error)) {
                        this.props.arrayRemove(form, ATTACHMENTS, index);
                    }
                });
        };
    };

    handleDrop = (files) => {
        const { contractId } = this.props;

        if (!files || files.length === 0) {
            return;
        }

        files.forEach((file) => {
            this.props
                .uploadAndCreateContractAttachment(contractId, file)
                .then((attachmentOrError) => {
                    if (!(attachmentOrError instanceof Error)) {
                        const attachment = { ...attachmentOrError };
                        if (attachment[TAGS]) {
                            // We currently only support a single tag on an attachment, even though the
                            // database supports multiple tags on an attachment
                            attachment[TAGS] = attachment[TAGS][0];
                            this.props.arrayPush(form, ATTACHMENTS, attachment);
                        }
                    }
                });
        });
    };

    renderAttachments = ({ fields }) => {
        const { contractAttachmentTagSelectOptions, disabled } = this.props;

        if (fields.length === 0) {
            return (
                <div className={this.styles.noAttachmentsText}>
                    No contract documents have been uploaded yet.
                </div>
            );
        }

        return fields.map((field, index) => {
            const attachment = fields.get(index);

            return (
                <ListGroupItem key={attachment.id}>
                    <FileUploadAttachment
                        attachment={attachment}
                        className={this.styles.attachment}
                        deleteError={attachment.deleteError}
                        deleteHandler={this.deleteAttachmentHandler(index)}
                        disabled={disabled || attachment.deleting}
                        requireConfirmation
                        updateHandler={this.updateAttachmentHandler(index)}
                    />
                    <div className="row">
                        <div className="col-xs-12">
                            <Field
                                aria-label="Contract Document Type"
                                component={SearchSelect}
                                disabled={disabled}
                                formClassName={this.styles.tagSelect}
                                isClearable
                                name={`${field}.${TAGS}`}
                                noOptionsMessage={noTagsMessage}
                                options={contractAttachmentTagSelectOptions}
                                placeholder="Contract Document Type"
                                qaTag="contractAttachments-selectDocumentType"
                                styles={{ menu: { zIndex: 2 } }}
                            />
                        </div>
                    </div>
                </ListGroupItem>
            );
        });
    };

    renderUploadedAttachments = () => {
        return (
            <ListGroup className={this.styles.attachmentsList}>
                <FieldArray component={this.renderAttachments} name={ATTACHMENTS} />
            </ListGroup>
        );
    };

    renderUploadingFiles = () => {
        const { uploadingAttachments } = this.props;

        return Object.keys(uploadingAttachments).map((uploadId) => {
            return (
                <UploadProgress
                    className={this.styles.fileUpload}
                    filename={uploadingAttachments[uploadId].file.name}
                    key={uploadId}
                    progress={uploadingAttachments[uploadId].progress}
                />
            );
        });
    };

    render() {
        const { disabled } = this.props;

        return (
            <div className={this.styles.container}>
                {this.renderUploadedAttachments()}
                {this.renderUploadingFiles()}
                <StyledDropzone
                    disabled={disabled}
                    label="Files"
                    multiple
                    onDrop={this.handleDrop}
                />
            </div>
        );
    }
}

export const ContractAttachments = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedContractAttachments);

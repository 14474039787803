import { fromJS } from 'immutable';

import * as adminActions from '../actions/admin';
import * as adminGovernmentActions from '../actions/adminGovernment';
import * as authActions from '../actions/auth';
import * as vendProposalActions from '../actions/vendProposals';
import * as publicProjectActions from '../actions/publicProject';

const sortToggle = {
    ASC: 'DESC',
    DESC: 'ASC',
};

const initialState = fromJS({
    autoNumbers: null,
    bulkUpdateOldUser: null,
    bulkUpdateAndDisable: false,
    currentTab: 'user',
    loadAutoNumbers: null,
    loadedSuggestedContent: false,
    loadedUsers: false,
    loadingAutoNumbers: false,
    loadingSuggestedContent: false,
    loadingUsers: false,
    loadingWorkloadWeights: false,
    loadSuggestedContentError: null,
    loadWorkloadWeightsError: null,
    outlineNumbering: null,
    postingContent: false,
    showAdminUsersModal: false,
    showBulkUpdateModal: false,
    showDisabledUsers: false,
    sortUsersDirection: 'ASC',
    suggestedContent: [],
    templateWorkloadWeights: [],
    updateOutlineNumberingError: null,
    updatingOutlineNumbering: false,
    updatingOrg: false,
    usersListSearch: null,
});

function usersReducer(state, action) {
    // Newly added users are identified by uuid instead of ID
    const key = action.uuid ? 'uuid' : 'id';

    // We only care about the user that matches the user ID
    if (state.get(key) !== action[key]) return state;

    switch (action.type) {
        case adminActions.INVITE:
            return state.merge(
                fromJS({
                    inviting: true,
                    error: null,
                })
            );
        case adminActions.INVITE_SUCCESS:
            return fromJS(action.result);
        case adminActions.INVITE_FAIL:
            return state.merge(
                fromJS({
                    inviting: false,
                    error: action.error && action.error.message,
                })
            );
        case adminActions.RESEND_INVITE:
            return state.merge(
                fromJS({
                    reInviting: true,
                    error: null,
                })
            );
        case adminActions.RESEND_INVITE_SUCCESS:
            return state.set('reInviting', false);
        case adminActions.RESEND_INVITE_FAIL:
            return state.merge(
                fromJS({
                    reInviting: false,
                    error: action.error && action.error.message,
                })
            );
        case adminActions.UPDATE_USER:
            return state.merge(
                fromJS({
                    updating: true,
                    error: null,
                })
            );
        case adminActions.UPDATE_USER_SUCCESS:
            return fromJS(action.result);
        case adminActions.UPDATE_USER_FAIL:
            return state.merge(
                fromJS({
                    updating: false,
                    error: action.error && action.error.message,
                })
            );
        case adminActions.DISABLE_USER:
            return state.merge(
                fromJS({
                    disabling: true,
                    error: null,
                })
            );
        case adminActions.DISABLE_USER_SUCCESS:
            return fromJS(action.result);
        case adminActions.DISABLE_USER_FAIL:
            return state.merge(
                fromJS({
                    disabling: false,
                    error: action.error && action.error.message,
                })
            );
        case adminActions.ENABLE_USER:
            return state.merge(
                fromJS({
                    enabling: true,
                    error: null,
                })
            );
        case adminActions.ENABLE_USER_SUCCESS:
            return fromJS(action.result);
        case adminActions.ENABLE_USER_FAIL:
            return state.merge(
                fromJS({
                    enabling: false,
                    error: action.error && action.error.message,
                })
            );
        case adminActions.REJECT_USER_REQUEST:
            return state.merge(
                fromJS({
                    updating: true,
                    error: null,
                })
            );
        case adminActions.REJECT_USER_REQUEST_FAIL:
            return state.merge(
                fromJS({
                    updating: false,
                    error: action.error && action.error.message,
                })
            );
        default:
            return state;
    }
}

function suggestedContentReducer(state, action) {
    // We only care about the list item that matches the content ID
    if (state.get('id') !== action.contentId) return state;

    switch (action.type) {
        case adminActions.EDIT_SUGGESTED_CONTENT:
            return state.merge(
                fromJS({
                    updating: true,
                    updateError: null,
                })
            );
        case adminActions.EDIT_SUGGESTED_CONTENT_SUCCESS:
            return fromJS(action.result).merge(
                fromJS({
                    updating: false,
                })
            );
        case adminActions.EDIT_SUGGESTED_CONTENT_FAIL:
            return state.merge(
                fromJS({
                    updating: false,
                    updateError: action.error && action.error.message,
                })
            );
        case adminActions.DELETE_SUGGESTED_CONTENT:
            return state.merge(
                fromJS({
                    deleting: true,
                    deleteError: null,
                })
            );
        case adminActions.DELETE_SUGGESTED_CONTENT_FAIL:
            return state.merge(
                fromJS({
                    deleting: false,
                    deleteError: action.error && action.error.message,
                })
            );
        case adminActions.TOGGLE_EDIT_CONTENT_FORM:
            return state.set('showEditForm', action.show);
        default:
            return state;
    }
}

export default function adminReducer(state = initialState, action = {}) {
    switch (action.type) {
        case adminActions.CHANGE_TAB:
            return state.set('currentTab', action.tabName);
        case adminActions.LOAD_USERS:
            return state.merge(
                fromJS({
                    loadingUsers: true,
                    loadUsersError: null,
                })
            );
        case adminActions.LOAD_USERS_SUCCESS:
            return state.merge(
                fromJS({
                    loadingUsers: false,
                    loadedUsers: true,
                    users: fromJS(action.result),
                })
            );
        case adminActions.LOAD_USERS_FAIL:
            return state.merge(
                fromJS({
                    loadingUsers: false,
                    loadedUsers: false,
                    loadUsersError: action.error && action.error.message,
                })
            );
        case adminGovernmentActions.LOAD_GOVERNMENT_DATA_SUCCESS:
            return state.merge(
                fromJS({
                    users: fromJS(action.result.users),
                })
            );
        case adminActions.TOGGLE_DISABLED_USERS:
            return state.set('showDisabledUsers', !state.get('showDisabledUsers'));
        case adminActions.TOGGLE_SORT_USERS_DIRECTION:
            return state.set('sortUsersDirection', sortToggle[state.get('sortUsersDirection')]);
        case adminActions.SET_USERS_LIST_SEARCH:
            return state.set('usersListSearch', action.value);
        case adminActions.ADD_USER:
            return state.update('users', (list) => {
                return list.unshift(fromJS(action.user));
            });
        case adminActions.REMOVE_USER:
            return state.update('users', (list) => {
                const index = list.findIndex((user) => user.get('uuid') === action.uuid);
                return list.delete(index);
            });
        case adminActions.INVITE:
        case adminActions.INVITE_SUCCESS:
        case adminActions.INVITE_FAIL:
        case adminActions.RESEND_INVITE:
        case adminActions.RESEND_INVITE_SUCCESS:
        case adminActions.RESEND_INVITE_FAIL:
        case adminActions.UPDATE_USER:
        case adminActions.UPDATE_USER_SUCCESS:
        case adminActions.UPDATE_USER_FAIL:
        case adminActions.DISABLE_USER:
        case adminActions.DISABLE_USER_SUCCESS:
        case adminActions.DISABLE_USER_FAIL:
        case adminActions.ENABLE_USER:
        case adminActions.ENABLE_USER_SUCCESS:
        case adminActions.ENABLE_USER_FAIL:
        case adminActions.REJECT_USER_REQUEST:
        case adminActions.REJECT_USER_REQUEST_FAIL:
            return state.set(
                'users',
                state.get('users').map((user) => usersReducer(user, action))
            );
        case adminActions.REJECT_USER_REQUEST_SUCCESS:
            return state.set(
                'users',
                state.get('users').filter((user) => user.get('id') !== action.id)
            );
        case adminActions.LOAD_SUGGESTED_CONTENT:
            return state.merge(
                fromJS({
                    loadingSuggestedContent: true,
                    loadSuggestedContentError: null,
                })
            );
        case adminActions.LOAD_SUGGESTED_CONTENT_SUCCESS:
            return state.merge(
                fromJS({
                    loadedSuggestedContent: true,
                    loadingSuggestedContent: false,
                    suggestedContent: fromJS(action.result),
                })
            );
        case adminActions.LOAD_SUGGESTED_CONTENT_FAIL:
            return state.merge(
                fromJS({
                    loadingSuggestedContent: false,
                    loadSuggestedContentError: action.error && action.error.message,
                })
            );
        case adminActions.LOAD_TEMPLATE_WORKLOAD_WEIGHTS:
            return state.merge(
                fromJS({
                    loadingWorkloadWeights: true,
                    loadWorkloadWeightsError: null,
                })
            );
        case adminActions.LOAD_TEMPLATE_WORKLOAD_WEIGHTS_SUCCESS:
            return state.merge(
                fromJS({
                    loadingWorkloadWeights: false,
                    templateWorkloadWeights: fromJS(action.result),
                })
            );
        case adminActions.LOAD_TEMPLATE_WORKLOAD_WEIGHTS_FAIL:
            return state.merge(
                fromJS({
                    loadingWorkloadWeights: false,
                    loadWorkloadWeightsError: action.error && action.error.message,
                })
            );
        case adminActions.UPDATE_WORKLOAD_WEIGHTS_SUCCESS:
            return state.set(
                'templateWorkloadWeights',
                state.get('templateWorkloadWeights').map((template) => {
                    if (template.get('id') !== action.result.template_id) {
                        return template;
                    }
                    return template.set('workloadWeight', fromJS(action.result));
                })
            );
        case adminActions.LOAD_AUTO_NUMBERS:
            return state.merge(
                fromJS({
                    loadAutoNumbersError: null,
                    loadingAutoNumbers: true,
                })
            );
        case adminActions.LOAD_AUTO_NUMBERS_SUCCESS:
            return state.merge(
                fromJS({
                    autoNumbers: fromJS(action.result),
                    loadingAutoNumbers: false,
                })
            );
        case adminActions.LOAD_AUTO_NUMBERS_FAIL:
            return state.merge(
                fromJS({
                    loadAutoNumbersError: action.error && action.error.message,
                    loadingAutoNumbers: false,
                })
            );
        case vendProposalActions.LOAD_SUCCESS:
            return state.merge(
                fromJS({
                    outlineNumbering: fromJS(action.result.project.government.outlineNumbering),
                })
            );
        case publicProjectActions.LOAD_SUCCESS:
            return state.merge(
                fromJS({
                    outlineNumbering: fromJS(action.result.government.outlineNumbering),
                })
            );
        case adminActions.UPDATE_OUTLINE_NUMBERING:
            return state.merge(
                fromJS({
                    updatingOutlineNumbering: true,
                })
            );
        case adminActions.UPDATE_OUTLINE_NUMBERING_SUCCESS:
            return state.merge(
                fromJS({
                    outlineNumbering: fromJS(action.result),
                    updatingOutlineNumbering: false,
                })
            );
        case adminActions.UPDATE_OUTLINE_NUMBERING_FAIL:
            return state.merge(
                fromJS({
                    updateOutlineNumberingError: action.error && action.error.message,
                    updatingOutlineNumbering: false,
                })
            );
        case adminActions.POST_SUGGESTED_CONTENT:
            return state.merge(
                fromJS({
                    postContentError: null,
                    postingContent: true,
                })
            );
        case adminActions.POST_SUGGESTED_CONTENT_SUCCESS:
            return state.merge(
                fromJS({
                    postingContent: false,
                    postedContent: true,
                    suggestedContent: state.get('suggestedContent').unshift(fromJS(action.result)),
                })
            );
        case adminActions.POST_SUGGESTED_CONTENT_FAIL:
            return state.merge(
                fromJS({
                    postingContent: false,
                    postedContent: false,
                    postContentError: action.error && action.error.message,
                })
            );
        case adminActions.EDIT_SUGGESTED_CONTENT:
        case adminActions.EDIT_SUGGESTED_CONTENT_SUCCESS:
        case adminActions.EDIT_SUGGESTED_CONTENT_FAIL:
        case adminActions.DELETE_SUGGESTED_CONTENT:
        case adminActions.DELETE_SUGGESTED_CONTENT_FAIL:
        case adminActions.TOGGLE_EDIT_CONTENT_FORM:
            return state.set(
                'suggestedContent',
                state
                    .get('suggestedContent')
                    .map((content) => suggestedContentReducer(content, action))
            );
        case adminActions.DELETE_SUGGESTED_CONTENT_SUCCESS:
            return state.set(
                'suggestedContent',
                state
                    .get('suggestedContent')
                    .filter((content) => content.get('id') !== action.contentId)
            );
        case adminActions.SORT_SUGGESTED_CONTENT: {
            const SORT_DIR = { asc: 'desc', desc: 'asc' };
            const curDir = state.getIn(['sortDirections', action.field]);
            const newDir = SORT_DIR[curDir] || 'desc';
            return state.setIn(['sortDirections', action.field], newDir).merge(
                fromJS({
                    contentSortField: action.field,
                })
            );
        }
        case adminActions.TOGGLE_ADD_CONTENT_FORM:
            return state.set('showAddContentForm', action.show);
        case adminActions.UPLOAD_LOGO:
            return state.merge(
                fromJS({
                    uploadingLogo: true,
                    uploadLogoError: null,
                })
            );
        case adminActions.UPLOAD_LOGO_SUCCESS:
            return state.merge(
                fromJS({
                    uploadingLogo: false,
                })
            );
        case adminActions.UPLOAD_LOGO_FAIL:
            return state.merge(
                fromJS({
                    uploadingLogo: false,
                    uploadLogoError: action.error && action.error.message,
                })
            );
        case adminActions.UPDATE_ORG:
            return state.merge(
                fromJS({
                    updatingOrg: true,
                    updateOrgError: null,
                })
            );
        case adminActions.UPDATE_ORG_SUCCESS:
            return state.merge(
                fromJS({
                    updatingOrg: false,
                })
            );
        case adminActions.UPDATE_ORG_FAIL:
            return state.merge(
                fromJS({
                    updatingOrg: false,
                    updateOrgError: action.error && action.error.message,
                })
            );
        case adminActions.SHOW_ADMIN_USER_LIST_MODAL:
            return state.set('showAdminUsersModal', true);
        case adminActions.HIDE_ADMIN_USER_LIST_MODAL:
            return state.set('showAdminUsersModal', false);
        case authActions.LOGOUT_SUCCESS:
            return initialState;
        case adminActions.SHOW_BULK_UPDATE_MODAL:
            return state.merge(
                fromJS({
                    bulkUpdateOldUser: action.value.id,
                    bulkUpdateAndDisable: action.value.disable,
                    showBulkUpdateModal: true,
                })
            );
        case adminActions.HIDE_BULK_UPDATE_MODAL:
            return state.merge(
                fromJS({
                    bulkUpdateOldUser: null,
                    bulkUpdateAndDisable: false,
                    showBulkUpdateModal: false,
                })
            );
        default:
            return state;
    }
}

export const ASSOCIATED_PROJECT = 'associatedProject';
export const AWARD_PROJECT_FROM_PENDING = 'awardProjectFromPending';
export const CHANGE_PRESET_WORKFLOW = 'changePresetWorkflow';
export const CLOSE_PROJECT = 'closeProject';
export const CONFIRM_BULK_CONTACTS_UPDATE = 'confirmBulkContactsUpdate';
export const CONSENSUS_EVALUATION = 'consensusEvaluation';
export const COPY_CONTRACT = 'copyContract';
export const COPY_DOCUMENT = 'copyDocument';
export const COPY_LINK = 'copyLink';
export const CREATE_CONTRACT_FROM_INTAKE = 'createContractFromIntake';
export const CREATE_CONTRACT_FROM_PROJECT = 'createContractFromProject';
export const CREATE_INTAKE_FROM_PROJECT = 'createIntakeFromProject';
export const DELETE = 'delete';
export const DELETE_EVALUATION = 'deleteEvaluation';
export const DELETE_POST = 'deletePost';
export const DELETE_INTAKE = 'deleteIntake';
export const DISQUALIFY_PROPOSAL = 'disqualifyProposal';
export const EDIT = 'edit';
export const EDIT_POST = 'editPost';
export const EDIT_PRE_INVITE_LIST = 'editPreInviteList';
export const END_AUCTION_REJECT_RESULTS = 'endAuctionRejectResults';
export const EXCLUDE_QUESTION = 'excludeQuestion';
export const EVALUATE = 'evaluate';
export const EXPORT = 'export';
export const FINALIZE = 'finalize';
export const FINALIZE_AWARD = 'finalizeAward';
export const FINALIZE_CONTRACT_PACKAGE = 'finalizeContractPackage';
export const INVITE_COLLABORATORS = 'inviteCollaborators';
export const NEW_PROJECT_DOC_BUILDER = 'newProjectDocBuilder';
export const NO_BID_GOV_PROPOSAL = 'noBidGovProposal';
export const PAUSE_PROJECT = 'pauseProject';
export const POST = 'post';
export const PREVIEW = 'preview';
export const PROXY_BID_REVERSE_AUCTION = 'proxyBidReverseAuction';
export const PUBLIC_DISPLAY_OPTIONS = 'publicDisplayOptions';
export const PUBLISH = 'publish';
export const REASSIGN_TAG = 'reassignTag';
export const RELEASE_EVALUATION = 'releaseEvaluation';
export const REOPEN_BUILDER = 'reopenBuilder';
export const REOPEN_CLOSED_PROJECT = 'reopenClosedProject';
export const REOPEN_CLOSED_INTAKE = 'reopenClosedIntake';
export const REOPEN_DRAFT_STATUS = 'reopenDraftStatus';
export const REOPEN_PROJECT_INTAKE = 'reopenProjectIntake';
export const INTAKE_INCOMPLETE = 'intakeIncomplete';
export const RESEAL_BIDS = 'resealBids';
export const RETRACT_POST = 'retractPost';
export const SKIP_TO_NEXT_STAGE = 'skipToNextStage';
export const START_REVIEW_PROCESS = 'startReviewProcess';
export const SUBMIT_INTAKE_FOR_REVIEW = 'submitIntakeForReview';
export const SUBSCRIBE = 'subscribe';
export const TOGGLE_COMMENTS = 'toggleComments';
export const TOGGLE_CONTRACT_PUBLIC_PRIVATE = 'toggleContractPublicPrivate';
export const UNAWARD_PROJECT = 'unawardProject';
export const UNFINALIZE = 'unfinalize';
export const UNSEAL_BIDS = 'unsealBids';
export const UNSUBSCRIBE = 'unsubscribe';
export const UPDATE_RETENTION_POLICY = 'updateRetentionPolicy';
export const UPDATE_RECORD_CLOSE_DATE = 'updateRecordCloseDate';
export const VENDOR_DISCOVERY = 'vendorDiscovery';
export const VENDOR_FOLLOW = 'vendorFollow';
export const TOGGLE_EDIT_TIMELINES_MODAL = 'toggleEditTimelinesModal';
export const TOGGLE_SHOW_EXPANDED_SUPPLIER_NETWORK_MODAL = 'toggleShowExpandedSupplierNetworkModal';

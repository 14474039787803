import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';

import {
    DownloadAllButton,
    ProposalAttachmentItem,
} from '../ProposalDocumentsListViewer/components';
import { getSupplementalAttachmentsList } from '../selectors';
import {
    getGovernmentId,
    getProposalForEvaluationJS,
    getProposalJS,
    isSourcingEditor,
    isEvaluationEditor,
} from '../../selectors';
import {
    createProposalSupplementalAttachment,
    deleteProposalSupplementalAttachment,
    downloadAllProposalDocuments,
    resetProposalDocumentsDownload,
} from '../../../../actions/govProposals';
import { BackButton, SectionTitle, AttachmentUploader } from '../../../../components';

const mapStateToProps = (state, props) => {
    const proposalPath = props.location.pathname.replace(/\/supplementals\/?$/i, '');

    return {
        downloadError: state.govProposals.get('downloadError'),
        downloading: state.govProposals.get('downloading'),
        downloadUrl: state.govProposals.get('downloadUrl'),
        govId: getGovernmentId(state, props),
        isEditor: isSourcingEditor(state) || isEvaluationEditor(state),
        proposalPath,
        supplementalAttachments: getSupplementalAttachmentsList(state, props),
        viewerPath: `${proposalPath}/supplementals`,
    };
};

const mapDispatchToProps = {
    createProposalSupplementalAttachment,
    deleteProposalSupplementalAttachment,
    downloadAllProposalDocuments,
    resetProposalDocumentsDownload,
};

// @connect
class ConnectedProposalSupplementalDocumentsListViewer extends Component {
    static propTypes = {
        createProposalSupplementalAttachment: PropTypes.func.isRequired,
        deleteProposalSupplementalAttachment: PropTypes.func.isRequired,
        downloadAllProposalDocuments: PropTypes.func.isRequired,
        downloadError: PropTypes.string,
        downloading: PropTypes.bool.isRequired,
        downloadUrl: PropTypes.string,
        govId: PropTypes.number.isRequired,
        isEditor: PropTypes.bool.isRequired,
        isProposalEvaluation: PropTypes.bool,
        proposal: PropTypes.shape({
            companyName: PropTypes.string,
            id: PropTypes.number.isRequired,
        }),
        proposalPath: PropTypes.string.isRequired,
        resetProposalDocumentsDownload: PropTypes.func.isRequired,
        supplementalAttachments: PropTypes.arrayOf(
            PropTypes.shape({
                deleteError: PropTypes.string,
                deleting: PropTypes.bool,
            })
        ).isRequired,
        viewerPath: PropTypes.string.isRequired,
    };

    componentWillUnmount() {
        this.props.resetProposalDocumentsDownload();
    }

    get styles() {
        return require('./index.scss');
    }

    downloadAllDocuments = () => {
        const { proposal } = this.props;
        return this.props.downloadAllProposalDocuments(proposal.id, { supplementalsOnly: true });
    };

    handleSupplementalAttachmentUpload = (file) => {
        const { proposal } = this.props;
        return this.props.createProposalSupplementalAttachment(proposal, file);
    };

    deleteHandler = (attachmentId) => {
        const { proposal } = this.props;
        return this.props.deleteProposalSupplementalAttachment(proposal, attachmentId);
    };

    renderSupplementalAttachments() {
        const { isEditor, isProposalEvaluation, supplementalAttachments, viewerPath } = this.props;

        return (
            <div className={this.styles.section}>
                <div className={this.styles.sectionHeader}>SUPPLEMENTAL DOCUMENTS</div>
                <ul className="list-unstyled">
                    {supplementalAttachments.length === 0 ? (
                        <li className={this.styles.sectionItem}>
                            <em>No documents uploaded</em>
                        </li>
                    ) : (
                        supplementalAttachments.map((attachment) => {
                            const uploadedAt = moment(attachment.created_at).format(
                                'h:mma on MMM D, YYYY'
                            );
                            const uploadedBy = attachment.proposalSuppDocs.userDisplayName;
                            return (
                                <ProposalAttachmentItem
                                    deleteError={attachment.deleteError}
                                    deleteHandler={() => this.deleteHandler(attachment.id)}
                                    documentPath={`${viewerPath}/${attachment.paramsNumber}`}
                                    isEditor={isEditor}
                                    isProposalEvaluation={isProposalEvaluation}
                                    key={attachment.id}
                                    uploadedAt={uploadedAt}
                                    uploadedBy={uploadedBy}
                                    {...attachment}
                                />
                            );
                        })
                    )}
                </ul>
            </div>
        );
    }

    render() {
        const {
            downloadError,
            downloading,
            downloadUrl,
            isEditor,
            isProposalEvaluation,
            proposal,
            proposalPath,
            supplementalAttachments,
        } = this.props;

        if (!proposal) {
            return null;
        }

        const companyName = proposal.companyName || 'Unnamed Company';

        return (
            <div className="row">
                <div className="col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2">
                    {!isProposalEvaluation && (
                        <BackButton text="Back to Response" to={proposalPath} />
                    )}
                    <SectionTitle
                        info="Supplemental documents are for internal use only."
                        title={`${companyName} Response Supplemental Documents`}
                    />
                    {this.renderSupplementalAttachments()}
                    {isEditor && !isProposalEvaluation && (
                        <AttachmentUploader
                            label="Supplemental Attachment"
                            onSuccess={this.handleSupplementalAttachmentUpload}
                            s3GetUrl={`/proposal/${proposal.id}/supplemental/s3`}
                        />
                    )}
                    <div className={this.styles.downloadContainer}>
                        {supplementalAttachments.length > 0 && (
                            <DownloadAllButton
                                downloadError={downloadError}
                                downloadHandler={this.downloadAllDocuments}
                                downloadUrl={downloadUrl}
                                downloading={downloading}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export const ProposalSupplementalDocumentsListViewer = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedProposalSupplementalDocumentsListViewer);

// Government sourcing container
ProposalSupplementalDocumentsListViewer.Sourcing = connect((state) => {
    return {
        proposal: getProposalJS(state),
    };
})(ProposalSupplementalDocumentsListViewer);

// Government evaluation container
ProposalSupplementalDocumentsListViewer.Evaluation = connect((state) => {
    return {
        proposal: getProposalJS(state),
    };
})(ProposalSupplementalDocumentsListViewer);

// Government proposal evaluation container
ProposalSupplementalDocumentsListViewer.ProposalEvaluation = connect((state) => {
    return {
        isProposalEvaluation: true,
        proposal: getProposalForEvaluationJS(state),
    };
})(ProposalSupplementalDocumentsListViewer);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { onKeyDown } from '../../helpers/accessibilityHelper';

// Responsive up to four icons
export class ProgressIcon extends PureComponent {
    static propTypes = {
        icon: PropTypes.string.isRequired,
        bsSize: PropTypes.string,
        hasPreviousIcon: PropTypes.bool,
        isComplete: PropTypes.bool,
        isSelected: PropTypes.bool,
        onClick: PropTypes.func,
        text: PropTypes.string,
    };

    static defaultProps = {
        bsSize: 'md',
    };

    stylesSize = (type) => {
        return this.styles[`${type}-${this.props.bsSize}`];
    };

    get styles() {
        return require('./ProgressIcon.scss');
    }

    get iconStyle() {
        const { isSelected, isComplete } = this.props;
        if (isSelected && isComplete) {
            return this.stylesSize('completeSelectedIcon');
        }
        if (isSelected) {
            return this.stylesSize('incompleteSelectedIcon');
        }
        if (isComplete) {
            return this.stylesSize('completeIcon');
        }
        return this.stylesSize('incompleteIcon');
    }

    renderNextLine() {
        const lineStyle =
            !this.props.isComplete && this.props.isSelected
                ? this.stylesSize('activeNextLine')
                : this.stylesSize('nextLine');
        return (
            <div className={`hidden-xs ${this.styles.lineContainer}`}>
                <div className={lineStyle} />
            </div>
        );
    }

    renderIcon() {
        const { icon, isComplete } = this.props;
        return (
            <div className={this.iconStyle}>
                <i className={`fa fa-${isComplete ? 'check' : icon}`} />
            </div>
        );
    }

    renderText() {
        const { text, isSelected, isComplete } = this.props;
        let textStyle;
        if (isSelected) textStyle = this.styles.selectedText;
        else if (isComplete) textStyle = this.styles.completedText;
        else textStyle = this.styles.unselectedText;

        return (
            <div className={this.styles.textContainer}>
                <div className={textStyle}>{text}</div>
            </div>
        );
    }

    render() {
        const { hasPreviousIcon, onClick } = this.props;
        const addMargin = hasPreviousIcon ? this.stylesSize('addMargin') : '';
        return (
            <div className={this.styles.container}>
                {hasPreviousIcon && this.renderNextLine()}

                <div
                    className={`${this.styles.iconContainer} ${addMargin}`}
                    onClick={onClick}
                    onKeyDown={(event) => onKeyDown(event, onClick)}
                    role="button"
                    tabIndex={0}
                >
                    {this.renderIcon()}
                    {this.renderText()}
                </div>
            </div>
        );
    }
}

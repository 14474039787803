import { serializeProjectItemDescriptionField } from './serializers';
import { showSnackbar } from '../notification';
import { emitProjectSocket } from '../utils';

export const UPDATE = 'gov/criteria/UPDATE';
export const UPDATE_SUCCESS = 'gov/criteria/UPDATE_SUCCESS';
export const UPDATE_FAIL = 'gov/criteria/UPDATE_FAIL';

export function criteriaUpdate(criteriaData) {
    return (dispatch, getState, client) => (data) => {
        const { id, project_id: projectId } = criteriaData;

        const serializedData = serializeProjectItemDescriptionField(data);

        dispatch({ type: UPDATE, id });

        return client
            .put(`/project/${projectId}/criteria/${id}`, { data: serializedData })
            .then((result) => {
                const action = { type: UPDATE_SUCCESS, result, id };
                dispatch(emitProjectSocket(projectId, action));
                dispatch(showSnackbar('Item updated'));
            })
            .catch((error) => dispatch({ type: UPDATE_FAIL, error, id }));
    };
}

export const DELETE = 'gov/criteria/DELETE';
export const DELETE_SUCCESS = 'gov/criteria/DELETE_SUCCESS';
export const DELETE_FAIL = 'gov/criteria/DELETE_FAIL';

export function criteriaDelete(criteriaData) {
    return (dispatch, getState, client) => {
        const { id, project_id: projectId } = criteriaData;

        dispatch({ type: DELETE, id });

        return client
            .del(`/project/${projectId}/criteria/${id}`)
            .then(() => {
                const deleteAction = { type: DELETE_SUCCESS, id };
                dispatch(emitProjectSocket(projectId, deleteAction));
                dispatch(showSnackbar('Item deleted'));
            })
            .catch((error) => dispatch({ type: DELETE_FAIL, error, id }));
    };
}

export const TOGGLE_FORM = 'gov/criteria/TOGGLE_FORM';

export function criteriaToggleForm(show, criteria) {
    const { id } = criteria;
    return { type: TOGGLE_FORM, show, id };
}

export const CREATE_SUCCESS = 'gov/criteria/CREATE_SUCCESS';

export function criteriaCreate(projectId, data, actionHandlers) {
    return (dispatch, getState, client) => {
        const { onLoading, onSuccess, onError } = actionHandlers;

        const serializedData = serializeProjectItemDescriptionField(data);

        onLoading();

        return client
            .post(`/project/${projectId}/criteria`, { data: serializedData })
            .then((result) => {
                onSuccess();
                const action = { type: CREATE_SUCCESS, result };
                dispatch(emitProjectSocket(projectId, action));
                dispatch(showSnackbar('Item added'));
            })
            .catch(onError);
    };
}

import { get } from 'lodash';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { FileUploadResponseForm } from '../..';
import { CDSButton, Button, LoadingButton, OutlineButton } from '../../../..';
import { showSnackbar } from '../../../../../actions/notification';
import {
    createNotaryLiveOrder,
    getQuestionnaireResponse,
} from '../../../../../actions/vendProposals';

const mapDispatchToProps = {
    showSnackbar,
    createNotaryLiveOrder,
    getQuestionnaireResponse,
};

export class ConnectedExternalFormResponseForm extends PureComponent {
    static propTypes = {
        showSnackbar: PropTypes.func.isRequired,
        createNotaryLiveOrder: PropTypes.func.isRequired,
        data: PropTypes.shape({
            value: PropTypes.string.isRequired,
        }).isRequired,
        disabled: PropTypes.bool,
        getQuestionnaireResponse: PropTypes.func.isRequired,
        id: PropTypes.number.isRequired,
        input: PropTypes.object.isRequired,
        isOGThemeEnabledForComponents: PropTypes.bool,
        meta: PropTypes.object.isRequired,
        proposalId: PropTypes.number.isRequired,
        showValidation: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = this.initialState;
    }

    componentWillUnmount() {
        this.clearQuestionnairePoller();
    }

    initialState = {
        checkingStatus: false,
        checkStatusError: null,
        useDocusign: null,
    };

    pollAttempt = 1;

    changeSkipUpdateValue = (skipUpdateValue) => {
        const { input } = this.props;

        input.onChange({
            ...input.value,
            data: {
                ...input.value.data,
                skipUpdate: skipUpdateValue,
            },
        });
    };

    clearQuestionnairePoller = () => {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    };

    getQuestionnaireResponse = () => {
        const { id, input, proposalId } = this.props;

        this.setState({
            checkingStatus: true,
            checkStatusError: null,
        });

        return this.props
            .getQuestionnaireResponse(proposalId, id)
            .then((questionnaireResponse) => {
                if (get(questionnaireResponse, 'data.isCompleted')) {
                    this.setState({ checkingStatus: false });
                    this.props.showSnackbar('Completed document added!', { isSuccess: true });
                    input.onChange(questionnaireResponse);
                } else {
                    const timeoutInterval = this.pollAttempt++ < 10 ? 2000 : 5000;
                    this.timeoutId = setTimeout(this.getQuestionnaireResponse, timeoutInterval);
                }
            })
            .catch((error) => {
                this.setState({
                    checkingStatus: false,
                    checkStatusError: error.message,
                });
            });
    };

    manuallyUpload = () => {
        this.setState({ useDocusign: false });
        this.changeSkipUpdateValue(false);
        this.clearQuestionnairePoller();
    };

    undoSelection = () => {
        this.setState(this.initialState);
        this.clearQuestionnairePoller();
    };

    useDocusign = () => {
        this.setState({
            useDocusign: true,
        });
        this.changeSkipUpdateValue(true);
    };

    renderDocusignResponseInput() {
        const {
            data: { value },
            id: questionnaireId,
            proposalId,
        } = this.props;

        const { checkingStatus, checkStatusError, useDocusign } = this.state;

        if (!useDocusign) {
            return (
                <>
                    <FileUploadResponseForm {...this.props} />
                    <div className="text-center">
                        <Button bsSize="sm" bsStyle="link" onClick={this.undoSelection}>
                            <span className="text-danger">
                                <i className="fa fa-times" /> Cancel Manual Upload
                            </span>
                        </Button>
                    </div>
                </>
            );
        }

        const powerFormQueryParams = qs.stringify({
            EnvelopeField_connectSource: 'procurenow',
            EnvelopeField_connectEnv: process.env.REPORTING_ENV,
            EnvelopeField_procurenowProposalId: proposalId,
            EnvelopeField_procurenowQuestionnaireId: questionnaireId,
            // NOTE: We could pre-populate, but then the vendor can't edit this info, so instead
            // we leave empty
            // Vendor_Email: 'email@email.com',
            // Vendor_UserName: 'name',
        });

        const powerFormUrl = `${value}&${powerFormQueryParams}`;

        return (
            <div>
                <label>Complete using DocuSign</label>
                <LoadingButton
                    bsSize="sm"
                    bsStyle="link"
                    disabled={checkingStatus}
                    icon="fa-refresh"
                    loading={checkingStatus}
                    loadingText="Checking status (takes a few moments to complete)"
                    onClick={this.getQuestionnaireResponse}
                    text="Check on status"
                />
                &nbsp;&nbsp;
                <Button bsSize="sm" bsStyle="link" onClick={this.undoSelection}>
                    <span className="text-danger">
                        <i className="fa fa-times" /> Cancel
                    </span>
                </Button>
                {checkingStatus ? (
                    <div>
                        <em>
                            It can take a few moments for your completed document to be uploaded to
                            your response. We will keep checking for your document and notify you
                            once it has been uploaded, so you can continue working on the rest of
                            your response.
                            <br />
                            If for some reason your document is not uploaded automatically, you can
                            manually upload it instead.
                        </em>
                    </div>
                ) : (
                    <div>
                        Once you have completed signing with DocuSign,&nbsp;
                        <Button
                            bsStyle="link"
                            disabled={checkingStatus}
                            onClick={this.getQuestionnaireResponse}
                            zeroPadding
                        >
                            click here to load your completed document
                        </Button>
                        .{checkStatusError && <div className="error-block">{checkStatusError}</div>}
                    </div>
                )}
                <div>
                    <iframe
                        frameBorder="1"
                        src={powerFormUrl}
                        style={{ marginTop: 5, width: '100%', height: 600 }}
                        title="Docusign"
                    />
                </div>
            </div>
        );
    }

    render() {
        const {
            disabled,
            input: { value },
            isOGThemeEnabledForComponents,
        } = this.props;

        const { useDocusign } = this.state;

        if ((get(value, 'attachments') || []).length > 0) {
            return <FileUploadResponseForm {...this.props} />;
        }

        if (useDocusign === null) {
            if (isOGThemeEnabledForComponents && disabled) {
                return (
                    <div>
                        <CDSButton
                            disabled={disabled}
                            qaTag="questionaireResponse-docusign"
                            size="small"
                            variant="secondary"
                        >
                            <i className="fa fa-upload" /> Complete via DocuSign
                        </CDSButton>
                    </div>
                );
            }

            return (
                <div>
                    <OutlineButton bsSize="sm" bsStyle="success" onClick={this.useDocusign}>
                        <i className="fa fa-cloud-upload" /> Complete via DocuSign
                    </OutlineButton>
                    &nbsp;&nbsp;or&nbsp;&nbsp;
                    <Button bsSize="sm" bsStyle="link" onClick={this.manuallyUpload} zeroPadding>
                        Manually upload document
                    </Button>
                </div>
            );
        }

        return this.renderDocusignResponseInput();
    }
}

export const ExternalFormResponseForm = connect(
    null,
    mapDispatchToProps
)(ConnectedExternalFormResponseForm);

import { get } from 'lodash';
import qs from 'qs';

import { filterTypesDict } from '@og-pro/shared-config/filters';

const { CONTRACT, DOC_BUILDER, PROJECT, INTAKE } = filterTypesDict;

const getHomepage = (user, baseLink, filterType) => {
    let link = baseLink;

    const lastFilter = get(user, `lastFilter.${filterType}`);

    if (lastFilter) {
        return `${link}?${qs.stringify(lastFilter)}`;
    }

    const userDefaultFilter = user.filters.find((filter) => {
        return filter.type === filterType && filter.isTypeDefault;
    });

    if (userDefaultFilter) {
        link += `?${qs.stringify({ ...userDefaultFilter.data, filterId: userDefaultFilter.id })}`;
    }

    return link;
};

export const getUserProjectHomepage = (user) => {
    const baseLink = `/governments/${user.government_id}/projects`;
    return getHomepage(user, baseLink, PROJECT);
};

export const getUserDocBuilderHomepage = (user) => {
    const baseLink = `/governments/${user.government_id}/documents`;
    return getHomepage(user, baseLink, DOC_BUILDER);
};

export const getUserIntakeHomepage = (user) => {
    const baseLink = `/governments/${user.government_id}/intakes`;
    return getHomepage(user, baseLink, INTAKE);
};

export const getUserContractHomepage = (user) => {
    const baseLink = `/governments/${user.government_id}/contracts`;
    return getHomepage(user, baseLink, CONTRACT);
};

export const getContractDocBuilderRoute = (docBuilderDocument) => {
    return `/governments/${docBuilderDocument.government_id}/projects/${docBuilderDocument.id}/manage`;
};

export const getUserHomepage = (user) => {
    const hasRequisitionOnly = get(user, 'government.hasRequisitionOnly');
    if (hasRequisitionOnly) {
        return `/governments/${user.government_id}/requisitions`;
    }

    const hasEvaluation = get(user, 'government.hasEvaluation');
    const hasSourcing = get(user, 'government.hasSourcing');
    const hasContracting = get(user, 'government.hasContracting');

    if (!hasSourcing && !hasEvaluation && hasContracting) {
        return getUserContractHomepage(user);
    }

    return getUserProjectHomepage(user);
};

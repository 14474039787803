export const formatUserForLd = (user) => {
    if (user) {
        // We don't want vendors counting against our MAU limit for LaunchDarkly
        if (user.isVendor) {
            return {
                key: 'pro-anonymous-vendor-user',
                kind: 'user',
            };
        }
        return {
            kind: 'multi',
            user: {
                key: `pro-user-${user.id}`,
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
                name: user.displayName,
            },
            organization: {
                key: `pro-org-${user.organization_id}`,
                name: user.organization.name,
            },
        };
    }

    return {
        key: 'pro-anonymous-user',
        kind: 'user',
    };
};

import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { getUsersById } from '../selectors';
import { UserProfileList } from '../../../../../components';

export const RequisitionGroupCreators = ({ creatorIds, leaderIds }) => {
    const getUserById = useSelector(getUsersById);

    if (creatorIds.length === 0) {
        return 'No creators assigned';
    }

    const creators = creatorIds.map((creatorId) => getUserById[creatorId]).filter((user) => user);
    const leaders = leaderIds.map((leaderId) => getUserById[leaderId]).filter((user) => user);
    const styles = require('./shared.scss');

    return (
        <>
            <div>
                <i className="fa fa-fw fa-user-plus" /> Creators ({creatorIds.length}
                )&nbsp;&nbsp;&nbsp;
                <UserProfileList className={styles.userProfileList} users={creators} />
            </div>
            <div className={styles.reviewGroupPositionUsersList}>
                <i className="fa fa-fw fa-user-plus" /> Leaders ({leaderIds.length}
                )&nbsp;&nbsp;&nbsp;
                <UserProfileList className={styles.userProfileList} users={leaders} />
            </div>
        </>
    );
};

RequisitionGroupCreators.propTypes = {
    creatorIds: PropTypes.array.isRequired,
    leaderIds: PropTypes.array.isRequired,
};

import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';

import { fieldNames, form } from './constants';
import { validate } from './validate';
import { Button, InputText, LoadingButton } from '../../../../../components';

const { DESCRIPTION, NAME } = fieldNames;

const formConfig = {
    form,
    validate,
};

const ConnectedGroupPositionForm = ({ handleSubmit, isNew, onCancel, updateError, updating }) => {
    return (
        <form onSubmit={handleSubmit}>
            <Field
                component={InputText}
                disabled={updating}
                hasFeedback={false}
                label="Position Name"
                name={NAME}
                placeholder="Supervisor"
                qaTag="groupPositionFormModal-name"
                type="text"
            />
            <Field
                component={InputText}
                disabled={updating}
                hasFeedback={false}
                label="Description (optional)"
                minRows={2}
                name={DESCRIPTION}
                placeholder="Describe the position"
                qaTag="groupPositionFormModal-description"
                type="textarea"
            />
            <div className="text-right">
                <Button onClick={onCancel} qaTag="groupPositionFormModal-cancel">
                    Cancel
                </Button>
                &nbsp;&nbsp;
                <LoadingButton
                    bsStyle={isNew ? 'success' : 'primary'}
                    disabled={updating}
                    icon={isNew ? 'fa-plus' : 'fa-pencil'}
                    loading={updating}
                    qaTag="groupPositionFormModal-submit"
                    text={isNew ? 'Create Position' : 'Update Position'}
                    type="submit"
                />
                {updateError && <div className="error-block">{updateError}</div>}
            </div>
        </form>
    );
};

ConnectedGroupPositionForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    isNew: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    updateError: PropTypes.string,
    updating: PropTypes.bool,
};

export const GroupPositionForm = reduxForm(formConfig)(ConnectedGroupPositionForm);

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Chip } from '@og-pro/ui';
import { useNavigate } from 'react-router-dom';

import { AgGridReact } from '../../../../../../components';

export const VendorListsTable = ({ displayPublicVendorInformation, governmentId, vendorLists }) => {
    const navigate = useNavigate();
    const styles = require('../../../index.scss');

    const columns = [
        {
            field: 'name',
            headerName: 'List Name',
            width: 740,
            headerClass: `${styles.paddingForHeaders}`,
            cellClass: `${styles.paddingForCells}`,
            onCellClicked: (params) => {
                navigate(`/governments/${governmentId}/vendors/lists/${params.data.id}`);
            },
            cellRendererFramework: ({ value }) => <span className="pseudoLink">{value}</span>,
        },
        {
            field: 'user.displayName',
            headerName: 'Creator',
            width: 370,
            headerClass: `${styles.paddingForHeaders}`,
            cellClass: `${styles.paddingForCells}`,
        },
        {
            field: 'updated_at',
            headerName: 'Last Updated',
            width: 185,
            headerClass: `${styles.paddingForHeaders}`,
            cellClass: `${styles.paddingForCells}`,
            valueFormatter: (params) => {
                const date = new Date(params.value).toLocaleDateString('en-us', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                });
                const time = new Date(params.value).toLocaleTimeString('en-us', {
                    hour: '2-digit',
                    minute: '2-digit',
                });
                return `${date} at ${time}`;
            },
        },
        {
            field: 'listedUsers',
            headerName: 'Number of Vendors',
            width: 185,
            cellStyle: { textAlign: 'right' },
            headerClass: `${styles.paddingForHeaders}`,
            cellClass: `${styles.paddingForCells}`,
            valueFormatter: (params) => {
                const listedUsersCount = params.data.listedUsers?.length || 0;
                const listedPendingUsersCount = params.data.listedPendingUsers?.length || 0;
                return listedUsersCount + listedPendingUsersCount;
            },
        },
        displayPublicVendorInformation
            ? {
                  field: 'isPublic',
                  headerName: 'List Type',
                  width: 185,
                  headerClass: `${styles.paddingForHeaders}`,
                  cellClass: `${styles.paddingForCells}`,
                  cellRendererFramework: ({ value }) => (
                      <Chip
                          color={value ? 'success' : 'secondary'}
                          label={value ? 'Public' : 'Private'}
                          size="small"
                          variant="tag"
                      />
                  ),
              }
            : {},
    ];

    /**
     * Given a row of data from the grid, get a unique ID for it.
     *
     * @param {object} data The raw data in this row
     * @return {string} The unique title of the row
     */
    const getRowNodeId = (data) => {
        return data.id;
    };

    const defaultColDef = {
        editable: false,
        suppressMenu: true,
    };

    return (
        <Box mt={1}>
            <AgGridReact
                columns={columns}
                defaultColDef={defaultColDef}
                domLayout="autoHeight"
                getRowNodeId={getRowNodeId}
                hideSideBar
                pagination
                paginationPageSize={20}
                rowHeight={52}
                rows={vendorLists}
                useOpenGovStyle
            />
        </Box>
    );
};

VendorListsTable.propTypes = {
    displayPublicVendorInformation: PropTypes.bool,
    governmentId: PropTypes.number.isRequired,
    vendorLists: PropTypes.array.isRequired,
};

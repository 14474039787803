import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';

import { fieldsDict } from '../constants';
import { validate } from '../validate';
import { Button, Checkbox, InputText, Well } from '../../../../components';

const { DEFAULT, NAME } = fieldsDict;

const formConfig = {
    validate,
};

// @reduxForm
class ConnectedCreateTagForm extends Component {
    static propTypes = {
        creating: PropTypes.bool,
        createError: PropTypes.string,
        handleSubmit: PropTypes.func.isRequired,
        hasDefault: PropTypes.bool,
    };

    static defaultProps = {
        creating: false,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const { creating, createError, handleSubmit, hasDefault } = this.props;

        const icon = creating ? 'spinner fa-spin' : 'plus';

        return (
            <form onSubmit={handleSubmit}>
                <Well>
                    <div className="row">
                        <div className={hasDefault ? 'col-xs-8 col-sm-7' : 'col-xs-12 col-sm-9'}>
                            <Field
                                component={InputText}
                                disabled={creating}
                                formGroupClassName={this.styles.input}
                                hasFeedback={false}
                                label="New Tag Name"
                                name={NAME}
                            />
                        </div>
                        {hasDefault && (
                            <div className="col-xs-4 col-sm-2">
                                <Field
                                    component={Checkbox}
                                    disabled={creating}
                                    formGroupClass={this.styles.formGroup}
                                    helpIcon
                                    helpIconNode="Default insurance tags will be applied to all new contracts"
                                    label="Default"
                                    name={DEFAULT}
                                />
                            </div>
                        )}
                        <div className="col-xs-12 col-sm-3">
                            <Button
                                block
                                bsStyle="success"
                                className={this.styles.createButton}
                                disabled={creating}
                                type="submit"
                            >
                                <i className={`fa fa-fw fa-${icon}`} /> Create Tag
                            </Button>
                        </div>
                    </div>
                    {createError && <div className="error-block">{createError}</div>}
                </Well>
            </form>
        );
    }
}

export const CreateTagForm = reduxForm(formConfig)(ConnectedCreateTagForm);

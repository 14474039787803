import classNames from 'classnames';
import { omit } from 'lodash';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getFormSyncErrors } from 'redux-form';

import { Button } from '../../../../components';
import { SDv2ErrorContainer } from '../../../../components/SDv2';
import { countFormErrors } from '../../../../helpers/formErrors';
import { formConfig } from '../RequisitionsCreateForms/form';
import { scrollToFirstError } from '../utils';

export const RequisitionsCreateFormError = () => {
    const styles = require('./RequisitionsCreateFormError.scss');

    const formErrors = useSelector((state) => getFormSyncErrors(formConfig.form)(state));
    const errorsCount = useMemo(() => countFormErrors(omit(formErrors, 'sections')), [formErrors]);

    return (
        errorsCount > 0 && (
            <SDv2ErrorContainer bannerClassName={styles.errorContainer}>
                <div className={styles.errorMessageContainer}>
                    <div className={styles.errorTitleContainer}>
                        <i
                            className={classNames(
                                'fa fa-exclamation-triangle',
                                styles.icon,
                                styles.errorTitle
                            )}
                        />
                        <span className={styles.errorTitle}>This Request Cannot Be Saved Yet.</span>
                    </div>
                    <span className={styles.errorDescription}>
                        Please fix the <span className={styles.errorTitle}>{errorsCount}</span>{' '}
                        errors marked below, and save again.
                    </span>
                </div>
                <div>
                    <Button
                        bsStyle="link"
                        className={styles.jumpToErrorBtn}
                        onClick={() => scrollToFirstError()}
                        qaTag="requisitionFormError-jumpToErrorBtn"
                        zeroPadding
                    >
                        Jump To First Error
                    </Button>
                </div>
            </SDv2ErrorContainer>
        )
    );
};

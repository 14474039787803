import { listToDict } from '@og-pro/shared-config/helpers';

export const form = 'requestTypeCreateForm';

const fields = ['endsInPurchaseOrder', 'name'];

export const fieldNames = listToDict(fields);

export const endsInPurchaseOrderOptions = [
    {
        label: 'Yes',
        value: true,
    },
    {
        label: 'No',
        value: false,
    },
];

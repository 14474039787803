import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Panel } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';

import { OpenBidsForm } from './OpenBidsForm';
import { getSearchResults } from './selectors';
import { getUserJS } from '../../selectors';
import { searchProjects } from '../../../actions/searchProjects';
import {
    Button,
    LoadingSpinner,
    LoadingError,
    SectionTitle,
    Main,
    TourButton,
} from '../../../components';
import { ProjectList } from '../../../components/PublicApp';

// Only show org search to ProcureNow & OpenGov accounts or in non-prod environments
const SearchByOrganizationLink = ({ user }) => {
    const isOpenGovUser =
        !!user.email.match(/@procurenow\.com$/) || !!user.email.match(/@opengov\.com$/);
    if (
        !isOpenGovUser &&
        (__PROD__ || __SANDBOX__ || __STAGING__ || __PERF__ || __INT__ || __RDEV__)
    ) {
        return null;
    }

    return (
        <Button bsStyle="link" to="/portal">
            Search by Organization [Internal Only]
        </Button>
    );
};
SearchByOrganizationLink.propTypes = {
    user: PropTypes.object.isRequired,
};

export const OpenBids = () => {
    const useReactTable8 = useFlags(FLAGS.ENABLE_REACT_TABLE_8);
    const [pagination, setPagination] = useState({ page: 1, limit: 20 });
    const [sort, setSort] = useState({ sortField: 'id', sortDirection: 'DESC' });
    const [formData, setFormData] = useState({});
    const dispatch = useDispatch();
    const searchResults = useSelector(getSearchResults);
    const searchError = useSelector((state) => state.searchProjects.get('searchError'));
    const searching = useSelector((state) => state.searchProjects.get('searching'));
    const loaded = useSelector((state) => state.searchProjects.get('loaded'));
    const user = useSelector(getUserJS);

    const handleSubmit = (values, submitted = true) => {
        const categoryData = values.categories || [];
        const stateData = values.states || [];
        let page = pagination.page;
        const limit = pagination.limit;

        setFormData({ categories: categoryData, states: stateData });

        // if the form is submitted and the page is not 1, reset the page to 1
        // and let the useEffect hook call this function again
        if (submitted && page !== 1) {
            page = 1;

            return setPagination({ page, limit });
        }

        const data = {
            ...values,
            categories: categoryData.map((cat) => cat.id),
            states: stateData.map((state) => state.value),
        };

        return dispatch(
            searchProjects(data, {
                page,
                limit,
                sort: sort.sortField,
                direction: sort.sortDirection,
            })
        );
    };

    useEffect(() => {
        handleSubmit(formData, false);
    }, [pagination.limit, pagination.page, sort.sortField, sort.sortDirection]);

    const styles = require('./index.scss');

    const onPageChange = (pageIndex) => {
        setPagination({ ...pagination, page: pageIndex + 1 });
    };

    const onPageSizeChange = (pageSize) => {
        setPagination({ page: 1, limit: pageSize });
    };

    const onSortChange = ([{ id, desc }]) => {
        setSort({
            sortField: id,
            sortDirection: desc ? 'DESC' : 'ASC',
        });
    };

    const sorted = [{ id: sort.sortField, desc: sort.sortDirection === 'DESC' }];

    return (
        <Main className="row">
            <h1 className="visually-hidden">Open Bids</h1>
            <div className="col-md-12">
                <div className="row">
                    <div className="col-xs-3 col-sm-3 col-md-3">
                        <div className={styles.tourButtonContainer}>
                            <TourButton
                                className="tutorial-btn"
                                qaTag="vendorOpenbidsList-tour"
                                text="Show Tutorial"
                            />
                        </div>
                    </div>
                    <div className="col-xs-9 col-sm-9 col-md-9 text-right">
                        <SearchByOrganizationLink user={user} />
                    </div>
                </div>
            </div>

            <div className="col-md-3">
                <div className={styles.sideBar}>
                    <Panel className="text-center">
                        <Panel.Body>
                            <div className="row">
                                <div className="col-xs-offset-3 col-xs-6 col-sm-offset-4 col-sm-4 col-md-offset-1 col-md-10">
                                    <img
                                        alt="OpenGov logo"
                                        className={`img-responsive ${styles.logo}`}
                                        src="https://assets.procurement.opengov.com/assets/opengov/opengov_emblem_blue.svg"
                                    />
                                </div>
                            </div>
                            <h2 className={styles.name}>
                                OpenGov
                                <br />
                                Procurement Portal
                            </h2>
                            <p className="text-muted">
                                <em>
                                    List of open opportunities on the
                                    <br />
                                    OpenGov Procurement Platform
                                </em>
                            </p>
                            <div className="text-left">
                                <OpenBidsForm onSubmit={handleSubmit} searching={searching} />
                            </div>
                        </Panel.Body>
                    </Panel>
                </div>
            </div>
            <div className="col-md-9">
                <Panel className={styles.projectsContainer}>
                    <Panel.Body>
                        <SectionTitle title="OpenGov Current Opportunities" />
                        {(searching || !loaded) && <LoadingSpinner />}
                        {searchError && <LoadingError error={searchError} />}
                        {!searching && !searchError && loaded && (
                            <ProjectList
                                dataTableProps={
                                    !useReactTable8
                                        ? {
                                              defaultPageSize: 20,
                                              manual: true,
                                              onPageChange,
                                              onPageSizeChange,
                                              onSortedChange: onSortChange,
                                              page: pagination.page - 1,
                                              pageSize: pagination.limit,
                                              pages: Math.ceil(
                                                  searchResults.count / pagination.limit
                                              ),
                                              pageSizeOptions: [5, 10, 20],
                                              sorted,
                                          }
                                        : {
                                              manualPagination: true,
                                              manualSorting: true,
                                              getPageOptions: () => [5, 10, 20],
                                              onPaginationChange: (changeFunction) => {
                                                  const { pageIndex, pageSize } = changeFunction({
                                                      pageIndex: pagination.page - 1,
                                                      pageSize: pagination.limit,
                                                  });

                                                  setPagination({
                                                      page: pageIndex + 1,
                                                      limit: pageSize,
                                                  });
                                              },
                                              onSortedChange: onSortChange,
                                              rowCount: searchResults.count,
                                              usePagination: true,
                                              getPaginationRowModel: null,
                                              getSortedRowModel: null,
                                              state: {
                                                  pagination: {
                                                      pageIndex: pagination.page - 1,
                                                      pageSize: pagination.limit,
                                                  },
                                                  sorting: sorted,
                                              },
                                              showPagination: true,
                                          }
                                }
                                includeLogo
                                projectPath={(project) =>
                                    `/portal/${project.government.code}/projects`
                                }
                                projects={searchResults.projects}
                                showOrg
                            />
                        )}
                    </Panel.Body>
                </Panel>
            </div>
        </Main>
    );
};

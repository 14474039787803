export const validate = (values) => {
    const errors = {};

    if (values.subject && values.subject.length > 255) {
        errors.subject = 'Subject is too long (250 characters max)';
    }

    if (!values.description) {
        errors.description = values.isSubstitutionRequest
            ? 'Please enter a description'
            : 'Please enter a question';
    }

    if (
        values.isSubstitutionRequest &&
        values.description &&
        (!values.substitutionAttachments || values.substitutionAttachments.length === 0)
    ) {
        errors.questionFormHasErrors = 'Please upload a completed Request for Substitution form';
    }

    return errors;
};

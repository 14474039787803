import { every } from 'lodash';
import { formValueSelector } from 'redux-form';
import { createSelector } from 'reselect';

import { form, fieldNames } from '../constants';

const { POST_SCHEDULED_AT, QUESTIONNAIRES, RELEASE_PROJECT_DATE } = fieldNames;

const formSelector = formValueSelector(form);
export const getPostScheduledAt = (state) => formSelector(state, POST_SCHEDULED_AT);
export const getQuestionnaires = (state) => formSelector(state, QUESTIONNAIRES);
export const getReleaseProjectDate = (state) => formSelector(state, RELEASE_PROJECT_DATE);

export const getPostingDate = createSelector([getPostScheduledAt], (postScheduledAt) => {
    // If there is no postScheduledAt date that means it is scheduled
    // for instant delivery, so the date is the current date.
    return postScheduledAt || new Date();
});

export const isScheduledDateReleaseDate = createSelector(
    [getPostingDate, getReleaseProjectDate],
    (postingDate, releaseProjectDate) => {
        if (releaseProjectDate) {
            // Compare the two dates to see if they are on the same day
            return every(['getFullYear', 'getMonth', 'getDate'], (field) => {
                return releaseProjectDate[field]() === postingDate[field]();
            });
        }
        return false;
    }
);

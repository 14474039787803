import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useNavigate } from 'react-router-dom';

import { ScopeSelect as ScopeSelectComponent } from './components';
import connectData from '../../ConnectData';
import { ProjectLibraryHOC } from './hocs';
import { loadProject } from '../../../actions/projectLibrary';
import { showProjectCreateModal } from '../../../actions/project/create/projectCreate';

function fetchData(getState, dispatch, location, params) {
    const projId = +params.projectId;
    return dispatch(loadProject(projId));
}

const HOCScopeSelect = ProjectLibraryHOC(ScopeSelectComponent);

const ConnectedScopeSelect = (props) => {
    const navigate = useNavigate();

    const copyFunction = (scopeItems) => {
        props.showProjectCreateModal({ copiedScope: scopeItems });
    };

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <HOCScopeSelect
            backHandler={handleBack}
            copyButtonText="Copy Scope Items to New Project"
            copyFunction={copyFunction}
        />
    );
};

ConnectedScopeSelect.propTypes = {
    showProjectCreateModal: PropTypes.func.isRequired,
};

export const ScopeSelect = compose(
    connectData(fetchData),
    connect(null, { showProjectCreateModal })
)(ConnectedScopeSelect);

import { truncate } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { Field } from 'redux-form';

import { fileFieldNames } from './form/constants';
import { Button, InputText, SearchSelect } from '../../..';
import { getFileExtensionIcon } from '../../../../helpers';

const { TITLE, APPENDIX_ID } = fileFieldNames;

export class AttachmentDropzoneFileForm extends Component {
    static propTypes = {
        appendixOptions: PropTypes.array.isRequired,
        arrayName: PropTypes.string.isRequired,
        attachment: PropTypes.shape({
            appendixId: PropTypes.string,
            done: PropTypes.bool,
            error: PropTypes.string,
            file: PropTypes.object.isRequired,
            fileExtension: PropTypes.string,
            loading: PropTypes.bool,
            progress: PropTypes.number.isRequired,
            title: PropTypes.string,
        }).isRequired,
        disabled: PropTypes.bool,
        handleSubmit: PropTypes.func.isRequired,
        hideAppendixLetter: PropTypes.bool,
        index: PropTypes.number.isRequired,
        removeFile: PropTypes.func,
        uploadFile: PropTypes.func,
    };

    get styles() {
        return require('./AttachmentDropzoneFileForm.scss');
    }

    uploadFile = () => {
        const { arrayName, attachment, uploadFile } = this.props;

        return uploadFile({
            attachment,
            formKey: arrayName,
        });
    };

    removeFile = () => {
        const { index, removeFile } = this.props;

        removeFile(index);
    };

    renderIcon() {
        const {
            attachment: { fileExtension, loading },
            disabled,
        } = this.props;

        const fileIcon = getFileExtensionIcon(fileExtension);

        return (
            <div>
                {!loading && (
                    <Button
                        aria-label="Remove Button"
                        bsStyle="link"
                        className={this.styles.removeBtn}
                        disabled={disabled}
                        onClick={this.removeFile}
                        qaTag="attachmentDropzoneFileForm-removeFile"
                    >
                        <i className="fa fa-lg fa-times" />
                    </Button>
                )}
                <i className={`fa fa-lg fa-${fileIcon} ${this.styles.fileIcon}`} />
            </div>
        );
    }

    renderFileContents() {
        const {
            appendixOptions,
            arrayName,
            attachment: { appendixId, loading, progress, title },
            disabled,
            hideAppendixLetter,
        } = this.props;

        if (loading) {
            const fileName = hideAppendixLetter ? title : `${appendixId} - ${title}`;
            return (
                <div className={`row ${this.styles.loadingContainer}`}>
                    <div className={`col-xs-2 ${this.styles.iconContainer}`}>
                        {this.renderIcon()}
                    </div>
                    <div className="col-xs-10">
                        {truncate(fileName, { length: 60 })}
                        <ProgressBar className={this.styles.progressBar} now={progress} />
                    </div>
                </div>
            );
        }

        const titleColWidth = hideAppendixLetter ? 10 : 7;
        return (
            <div className="row">
                <div className={`col-xs-2 ${this.styles.iconContainer}`}>{this.renderIcon()}</div>
                {!hideAppendixLetter && (
                    <div className={`col-xs-3 ${this.styles.appendixSelectCol}`}>
                        <Field
                            aria-label="Attachment ID"
                            component={SearchSelect}
                            disabled={disabled}
                            hideDisabledMenuOptions
                            isOptionDisabled={(option) => option.disabled}
                            isSearchable={false}
                            name={`${arrayName}.${APPENDIX_ID}`}
                            options={appendixOptions}
                        />
                    </div>
                )}
                <div className={`col-xs-${titleColWidth}`}>
                    <Field
                        aria-label="Attachment Title"
                        component={InputText}
                        disabled={disabled}
                        hasFeedback={false}
                        name={`${arrayName}.${TITLE}`}
                        type="text"
                    />
                </div>
            </div>
        );
    }

    render() {
        const {
            attachment: { done, error, loading },
            handleSubmit,
            disabled,
        } = this.props;

        /**
         * `done` is used to prevent rendering the upload. The upload cannot be removed from the
         * form until all uploads are complete as removing an individual upload will change the
         * index position, which effects the form item that will be updated.
         *
         * The form item is eventually removed by the parent component when all uploads have
         * finished.
         */
        if (done) {
            return null;
        }

        return (
            <form className="row" onSubmit={handleSubmit(this.uploadFile)}>
                <div className="col-xs-10">{this.renderFileContents()}</div>
                <div className={`col-xs-2 ${this.styles.uploadButtonCol}`}>
                    <Button
                        block
                        bsSize="sm"
                        bsStyle="primary"
                        disabled={loading || disabled}
                        type="submit"
                    >
                        <i className="fa fa-upload" /> Upload
                    </Button>
                </div>
                {error && <div className={`col-xs-12 ${this.styles.errorText}`}>{error}</div>}
            </form>
        );
    }
}

/**
 * Turns a Signature record returned from the backend, which includes an array signaturesBlocks
 * with all the related blocks, into an array containing 1 array for each column.
 * @param {object} signature
 * @returns {object}
 */
export const signaturesBlocksToColumns = (signature) => {
    const columns = signature.signaturesBlocks.reduce((acc, block) => {
        if (!acc[block.column]) {
            acc[block.column] = [];
        }

        return {
            ...acc,
            [block.column]: [...acc[block.column], block].sort((a, b) => a.orderById - b.orderById),
        };
    }, {});

    return {
        ...signature,
        signaturesBlocks: Object.values(columns),
    };
};

/**
 * Turns an array of Signatures, each containing a converted list of signaturesBlocks (converted with signaturesBlocksToColumns)
 * into a plain array of signaturesBlocks for the backend to save. Additionally it adds the column and orderById properties to each
 * signatureBlock.
 *
 * @param {Array<object>} signatures
 * @returns {Array<object>}
 */
export const signaturesBlocksColumnsToArray = (signatures) => {
    if (!signatures) {
        return undefined;
    }

    return signatures.map((signature) => {
        const signaturesBlocks = signature.signaturesBlocks.reduce((acc, column, columnIndex) => {
            const blocks = column.map((block, i) => {
                return {
                    ...block,
                    orderById: i,
                    column: columnIndex + 1,
                };
            });

            return [...acc, ...blocks];
        }, []);

        return {
            ...signature,
            signaturesBlocks,
        };
    });
};

import React from 'react';
import PropTypes from 'prop-types';

import { SectionType } from '../constants';
import { ProjectSectionNavItem } from './Item';

export const BaseProjectSectionsNav = ({ sections, showShortName = false }) => {
    const hasChildren = sections.some((section) => section.children?.length);

    return sections.map((section, i) => {
        return (
            <ProjectSectionNavItem
                hasChildren={hasChildren}
                key={i}
                section={section}
                showShortName={showShortName}
            />
        );
    });
};

BaseProjectSectionsNav.propTypes = {
    sections: PropTypes.arrayOf(SectionType),
    showShortName: PropTypes.bool,
};

export const projectPhases = [
    'Document Creation',
    'Response Submission',
    'Vendor Selection',
    'Total Time',
];

export const customColors = [
    // Bootstrap colors
    '#428bca',
    '#5cb85c',
    '#f0ad4e',
    '#5bc0de', // '#d9534f',
    // Default highchart colors
    '#8085e9',
    '#2b908f',
    '#f15c80',
    '#e4d354',
    '#f45b5b',
    '#91e8e1',
    '#434348',
    '#90ed7d',
    '#7cb5ec',
    '#f7a35c',
];

export const timeRangeOptions = [
    { label: 'Last Month', value: '1.months' },
    { label: 'Last 3 Months', value: '3.months' },
    { label: 'Last Year', value: '1.years' },
    { label: 'All Time', value: '100.years' },
];

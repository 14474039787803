import { find } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';

import { mapContactFields } from '@og-pro/shared-config/contacts';

import { projectStatusesDict } from '@og-pro/shared-config/projects';

import { auctionMaxFractionDigitsOptions, budgetFieldNames, fieldNames } from './constants';
import {
    Button,
    Checkbox,
    ContactModal,
    DepartmentModal,
    FormError,
    InputText,
    Main,
    SearchSelect,
    SearchSelectUserOption,
    Toggle,
    Well,
} from '../..';
import { showConfirmationSimpleModal } from '../../../actions/confirmation';
import { changeContactForm, showContactModal } from '../../../actions/contacts';
import { showSnackbar } from '../../../actions/notification';
import {
    changeDepartmentForm,
    getApprovalWorkflowsFromProjectCreate,
    getProjectAutoNumber,
    hideDepartmentModal,
    incrementProjectAutoNumber,
    showDepartmentModal,
    updateProjectCategories,
} from '../../../actions/project/create/projectCreate';
import { CategorySelectInput } from '../../../containers';
import {
    isBudgetRequired,
    isBudgetUsed,
    isProjectIdRequired,
} from '../../../containers/GovApp/selectors';
import {
    getActiveUsersJS,
    getActiveUsersSelectOptions,
    getDepartmentsJS,
    getDepartmentsSelectOptions,
} from '../../../containers/selectors';
import { maskNumberWithCommas } from '../../../Forms/maskers';
import { dollarString, limitTextLength } from '../../../Forms/normalizers';
import { MaskedInputText } from '../../../hocs';

const {
    ALLOWS_PROXY_BIDDING,
    AUCTION_MAX_FRACTION_DIGITS,
    BUDGET,
    CATEGORIES,
    CONTACT_ID,
    DEPARTMENT_ID,
    FINANCIAL_ID,
    IS_EMERGENCY,
    PROCUREMENT_CONTACT_ID,
    REQUISITION_IDENTIFIER,
    TITLE,
} = fieldNames;

const { AMOUNT, DESCRIPTION, IDENTIFIER } = budgetFieldNames;

const { DRAFT } = projectStatusesDict;

const mapStateToProps = (state, props) => {
    return {
        departments: getDepartmentsJS(state),
        departmentsSelectOptions: getDepartmentsSelectOptions(state),
        hasFinancialIdValue: !!formValueSelector(props.form)(state, FINANCIAL_ID),
        isBudgetRequired: isBudgetRequired(state),
        isBudgetUsed: isBudgetUsed(state),
        isProjectIdRequired: isProjectIdRequired(state),
        shouldShowDepartmentModal: state.projectCreate.get('showDepartmentModal'),
        users: getActiveUsersJS(state),
        usersSelectOptions: getActiveUsersSelectOptions(state),
    };
};

const mapDispatchToProps = {
    showSnackbar,
    changeContactForm,
    changeDepartmentForm,
    getApprovalWorkflowsFromProjectCreate,
    getProjectAutoNumber,
    hideDepartmentModal,
    incrementProjectAutoNumber,
    showConfirmationSimpleModal,
    showContactModal,
    showDepartmentModal,
    updateProjectCategories,
};

// @connect
class ConnectedProjectOverviewFormSection extends Component {
    static propTypes = {
        showSnackbar: PropTypes.func.isRequired,
        change: PropTypes.func.isRequired,
        changeContactForm: PropTypes.func.isRequired,
        changeDepartmentForm: PropTypes.func.isRequired,
        contactCommentData: PropTypes.object,
        departments: PropTypes.array.isRequired,
        departmentsSelectOptions: PropTypes.array.isRequired,
        disabled: PropTypes.bool,
        form: PropTypes.string.isRequired,
        getApprovalWorkflowsFromProjectCreate: PropTypes.func.isRequired,
        getProjectAutoNumber: PropTypes.func.isRequired,
        hasCategories: PropTypes.bool,
        hasEmergency: PropTypes.bool,
        hasFinancialIdValue: PropTypes.bool.isRequired,
        hideDepartmentModal: PropTypes.func.isRequired,
        incrementProjectAutoNumber: PropTypes.func.isRequired,
        isBudgetRequired: PropTypes.bool.isRequired,
        isBudgetUsed: PropTypes.bool.isRequired,
        isProjectIdRequired: PropTypes.bool.isRequired,
        project: PropTypes.object.isRequired,
        shouldShowDepartmentModal: PropTypes.bool,
        showComments: PropTypes.bool,
        showConfirmationSimpleModal: PropTypes.func.isRequired,
        showContactModal: PropTypes.func.isRequired,
        showDepartmentModal: PropTypes.func.isRequired,
        showFormErrors: PropTypes.bool,
        showProcurementContact: PropTypes.bool,
        updateProjectCategories: PropTypes.func.isRequired,
        updateError: PropTypes.string,
        users: PropTypes.array.isRequired,
        usersSelectOptions: PropTypes.array.isRequired,
    };

    MaskedBudgetInput = MaskedInputText(InputText);

    normalizeBudgetIdentifier = limitTextLength(64);

    normalizeBudgetDecription = limitTextLength(256);

    normalizeFinancialId = limitTextLength(128);

    get styles() {
        return require('./index.scss');
    }

    getProjectAutoNumber = () => {
        const { change, project } = this.props;

        const incrementAutoNumber = () => {
            return this.props
                .incrementProjectAutoNumber(project.id)
                .then((autoNumber) => {
                    change(FINANCIAL_ID, autoNumber.nextNumber);
                })
                .catch((error) => {
                    this.props.showSnackbar(`Could not insert Auto Number: ${error.message}`, {
                        isError: true,
                    });
                });
        };

        return this.props
            .getProjectAutoNumber(project.id)
            .then((autoNumber) => {
                this.props.showConfirmationSimpleModal(incrementAutoNumber, {
                    bsStyle: 'primary',
                    btnText: 'Assign Auto Number',
                    icon: 'chevron-circle-down',
                    text: (
                        <>
                            Next auto number is:
                            <br key="break1" />
                            <strong key="key">{autoNumber.nextNumber}</strong>
                            <br key="break2" />
                            <br key="break3" />
                            Are you sure you want to assign this number to this project?
                        </>
                    ),
                });
            })
            .catch((error) => {
                this.props.showSnackbar(`Could not get Auto Number: ${error.message}`, {
                    isError: true,
                });
            });
    };

    handleEditContactDisplayInfoClick = (data) => {
        const { disabled, showFormErrors } = this.props;

        const { fieldPrefix, optional } = data;

        if (!disabled) {
            this.props.showContactModal({
                disabled,
                fieldPrefix,
                optional,
                showValidation: showFormErrors,
            });
        }
    };

    handleEditDepartmentDisplayInfoClick = () => {
        const { disabled } = this.props;

        if (!disabled) {
            this.props.showDepartmentModal();
        }
    };

    updateContactInfo = (data) => (contact) => {
        const { form, users } = this.props;

        const { fieldPrefix } = data;

        const contactValue = find(users, (user) => user.id === contact);
        if (contactValue) {
            this.props.changeContactForm(contactValue, form, mapContactFields(fieldPrefix));
        }
    };

    updateDepartmentInfo = (departmentId) => {
        const {
            departments,
            form,
            project: { departmentName: currentDepartmentName, id: projectId, status },
        } = this.props;

        const selectedDepartmentValue = find(
            departments,
            (departmentEntry) => departmentEntry.id === departmentId
        );

        if (status === DRAFT) {
            this.props.getApprovalWorkflowsFromProjectCreate(
                projectId,
                departmentId,
                selectedDepartmentValue,
                currentDepartmentName
            );
        }

        if (selectedDepartmentValue) {
            this.props.changeDepartmentForm(selectedDepartmentValue, form);
        }
    };

    updateCategories = (e, categories) => {
        const { project } = this.props;

        return this.props.updateProjectCategories(project.id, categories);
    };

    renderContactInput(data) {
        const { disabled, showComments, showFormErrors, usersSelectOptions } = this.props;

        const { contactCommentData, fieldName, label, optional = false, placeholder } = data;

        // `backspaceRemoves` and `deleteRemoves` needed to prevent select from being emptied
        // (server validation will fail)
        return (
            <>
                <Field
                    backspaceRemovesValue={optional}
                    commentData={
                        contactCommentData && {
                            ...contactCommentData,
                            hideClassName: this.styles.displayContactComment,
                        }
                    }
                    commentIcon={!!contactCommentData && showComments}
                    component={SearchSelect}
                    components={{ Option: SearchSelectUserOption }}
                    disabled={disabled}
                    formClassName={this.styles.contactField}
                    isClearable={optional}
                    label={label}
                    name={fieldName}
                    onChange={this.updateContactInfo(data)}
                    options={usersSelectOptions}
                    placeholder={placeholder}
                    showValidation={showFormErrors}
                />
                <div className={this.styles.contactEdit}>
                    <Button
                        bsStyle="link"
                        onClick={() => this.handleEditContactDisplayInfoClick(data)}
                        qaTag="projectOverviewFormSection-editContactDisplayInformation"
                        zeroPadding
                    >
                        Edit Contact Display Information
                    </Button>
                </div>
            </>
        );
    }

    renderDepartmentInput() {
        const { departmentsSelectOptions, disabled, showFormErrors } = this.props;

        // `backspaceRemoves` and `deleteRemoves` needed to prevent select from being emptied
        // (server validation will fail)
        return (
            <>
                <Field
                    backspaceRemovesValue={false}
                    component={SearchSelect}
                    disabled={disabled}
                    label="Department"
                    name={DEPARTMENT_ID}
                    onChange={this.updateDepartmentInfo}
                    options={departmentsSelectOptions}
                    placeholder="Select department..."
                    showValidation={showFormErrors}
                />
                <div className={this.styles.contactEdit}>
                    <Button
                        bsStyle="link"
                        onClick={this.handleEditDepartmentDisplayInfoClick}
                        qaTag="projectOverviewFormSection-editDepartmentDisplayInformation"
                        zeroPadding
                    >
                        Edit Department Display Information
                    </Button>
                </div>
            </>
        );
    }

    renderStandardFields() {
        const {
            contactCommentData,
            disabled,
            form,
            hasCategories,
            hasEmergency,
            hasFinancialIdValue,
            shouldShowDepartmentModal,
            showFormErrors,
            showProcurementContact,
            updateError,
        } = this.props;

        const projectIdHelp =
            this.props.isProjectIdRequired && this.props.project.isIntake
                ? 'This will be required once request becomes a solicitation'
                : '';

        return (
            <>
                <ContactModal formName={form} />
                <DepartmentModal
                    disabled={disabled}
                    hideModal={this.props.hideDepartmentModal}
                    showModal={shouldShowDepartmentModal}
                    showValidation={showFormErrors}
                />
                <FormError error={updateError} />
                <Field
                    component={InputText}
                    disabled={disabled}
                    label="Title"
                    name={TITLE}
                    placeholder="Enter Project Title"
                    qaTag="projectOverview-projectTitle"
                    showValidation={showFormErrors}
                    type="text"
                />
                {this.renderContactInput({
                    contactCommentData,
                    fieldName: CONTACT_ID,
                    fieldPrefix: 'contact',
                    label: 'Project Contact',
                    placeholder: 'Select User...',
                })}
                {showProcurementContact &&
                    this.renderContactInput({
                        fieldName: PROCUREMENT_CONTACT_ID,
                        fieldPrefix: 'procurement',
                        label: 'Procurement Contact',
                        optional: false,
                        placeholder: 'Select User...',
                    })}
                {this.renderDepartmentInput()}
                <Field
                    component={InputText}
                    disabled={disabled}
                    help={`Identification number or code for the project. ${projectIdHelp}`}
                    label={
                        <>
                            Project ID
                            {this.props.isProjectIdRequired && !this.props.project.isIntake
                                ? ''
                                : ' (optional)'}
                            <Button
                                bsStyle="link"
                                className={this.styles.autoNumberButton}
                                disabled={hasFinancialIdValue}
                                onClick={this.getProjectAutoNumber}
                                qaTag="projectOverview-getAutoNumber"
                                tooltip={
                                    hasFinancialIdValue
                                        ? 'Project ID already in use. Delete Project ID to get an auto number.'
                                        : undefined
                                }
                                zeroPadding
                            >
                                <i className="fa fa-magic" /> Get Auto Number
                            </Button>
                        </>
                    }
                    name={FINANCIAL_ID}
                    normalize={this.normalizeFinancialId}
                    placeholder="Enter Project ID"
                    qaTag="projectOverview-projectId"
                    showValidation={showFormErrors}
                    type="text"
                />
                <Field
                    component={InputText}
                    disabled={disabled}
                    label="Request ID (optional)"
                    name={REQUISITION_IDENTIFIER}
                    normalize={this.normalizeFinancialId}
                    placeholder="Enter Request ID"
                    qaTag="projectOverview-requisitionId"
                    showValidation={showFormErrors}
                    type="text"
                />
                {hasCategories && (
                    <Field
                        component={CategorySelectInput}
                        disabled={disabled}
                        maxLength={110}
                        name={CATEGORIES}
                        onChange={this.updateCategories}
                        useSingleCodeSet
                    />
                )}
                {hasEmergency && (
                    <Field
                        component={Checkbox}
                        disabled={disabled}
                        inline
                        name={IS_EMERGENCY}
                        qaTag="projectOverview-emergencyCheckbox"
                        showValidation={showFormErrors}
                        text="Are you purchasing an emergency good or service?"
                    />
                )}
            </>
        );
    }

    renderBudgetFields() {
        const { disabled, showFormErrors } = this.props;

        return (
            <Well className={this.styles.budgetWell}>
                <div className="row">
                    <div className="col-xs-12 col-sm-offset-1 col-sm-10">
                        <div className={this.styles.budgetTitle}>
                            <h4>Budget Information</h4>
                            <small className="text-muted">
                                <em>Internal Use Only (not publicly displayed)</em>
                            </small>
                        </div>
                        <Field
                            component={this.MaskedBudgetInput}
                            disabled={disabled}
                            inputGroupPrefix="$"
                            label={`Budget Amount${this.props.isBudgetRequired ? '' : ' (optional)'}`}
                            mask={maskNumberWithCommas}
                            name={`${BUDGET}.${AMOUNT}`}
                            normalizer={dollarString}
                            placeholder="60,000"
                            showValidation={showFormErrors}
                            type="text"
                        />
                        <Field
                            component={InputText}
                            disabled={disabled}
                            help="Account String/Number of the budget item"
                            label={`Budget Account${this.props.isBudgetRequired ? '' : ' (optional)'}`}
                            name={`${BUDGET}.${IDENTIFIER}`}
                            normalize={this.normalizeBudgetIdentifier}
                            placeholder="101-501-5300"
                            qaTag="projectOverview-budgetAccountNumber"
                            showValidation={showFormErrors}
                            type="text"
                        />
                        <Field
                            component={InputText}
                            disabled={disabled}
                            help="Description of the budget account"
                            label={`Budget Description${this.props.isBudgetRequired ? '' : ' (optional)'}`}
                            name={`${BUDGET}.${DESCRIPTION}`}
                            normalize={this.normalizeBudgetDecription}
                            placeholder="IT Help Desk Fund"
                            qaTag="projectOverview-budgetAccountDescription"
                            showValidation={showFormErrors}
                            type="text"
                        />
                    </div>
                </div>
            </Well>
        );
    }

    renderReverseAuctionFields() {
        const { disabled, showFormErrors } = this.props;

        return (
            <>
                <div className={this.styles.budgetTitle}>
                    <h5>Reverse Auction Settings</h5>
                    <small className="text-muted">
                        <em>Configuration for bid denomination and proxy bidding</em>
                    </small>
                </div>
                <Field
                    component={SearchSelect}
                    disabled={disabled}
                    formClassName={this.styles.searchSelect}
                    help="Amount all bids must be a multiple of"
                    isSearchable={false}
                    label="Bid Denomination"
                    name={`${AUCTION_MAX_FRACTION_DIGITS}`}
                    options={auctionMaxFractionDigitsOptions}
                    showValidation={showFormErrors}
                />
                <Field
                    component={Toggle}
                    help="Allow vendors to set automated bids during a reverse auction"
                    label="Proxy Bids"
                    name={`${ALLOWS_PROXY_BIDDING}`}
                />
            </>
        );
    }

    render() {
        const {
            project: {
                template: { isReverseAuction },
            },
        } = this.props;

        return (
            <Main>
                {this.renderStandardFields()}
                {isReverseAuction && (
                    <Well className={this.styles.budgetWell}>
                        <div className="row">
                            <div className="col-xs-12 col-sm-offset-1 col-sm-10">
                                {this.renderReverseAuctionFields()}
                            </div>
                        </div>
                    </Well>
                )}
                {this.props.isBudgetUsed && this.renderBudgetFields()}
            </Main>
        );
    }
}

export const ProjectOverviewFormSection = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedProjectOverviewFormSection);

import { fieldNames } from './constants';

const { OVERALL_RATING, TYPE } = fieldNames;

export const validate = (values) => {
    const errors = {};

    if (!values[TYPE]) {
        errors[TYPE] = 'Please enter the type of review';
    }

    if (!values[OVERALL_RATING]) {
        errors[OVERALL_RATING] = 'Please enter an overall review';
    }

    return errors;
};

import React from 'react';
import { pick } from 'lodash';
import PropTypes from 'prop-types';
import { connect, useSelector, useDispatch } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Box } from '@og-pro/ui';

import { TIMELINE_FIELDS } from '@og-pro/shared-config/timelines';

import { TIMELINE_CONFIG, TIMELINES } from '../../constants';
import { getProjectJS, getFilteredTimelines, getPostOpenEditableTimelines } from '../../selectors';
import { isInitialClientLoaded, getTimezone } from '../../../selectors';
import { Timeline } from '../../../../components/GovApp';
import { Button } from '../../../../components';
import { update } from '../../../../actions/projectPost';
import { projectTimelines } from '../../../../Forms/validation';

const FORM = 'projectPostOpenTimeline';

const ConnectedProjectEditTimelinesForm = ({ array, change, handleSubmit, pristine }) => {
    const dispatch = useDispatch();
    const timezone = useSelector(getTimezone);
    const project = useSelector(getProjectJS);
    const updating = useSelector((state) => state.projectCreate.get('updating'));
    const timelineData = useSelector(getPostOpenEditableTimelines);

    const onSubmit = (data) => {
        dispatch(
            update(pick(data, [...TIMELINE_FIELDS, TIMELINES, TIMELINE_CONFIG]), project.id, null, {
                snackbar: true,
            })
        );
    };

    const editableTimelineDataIds = timelineData.map((tl) => tl.id || tl.uuid);

    return (
        <Box>
            <Box bgcolor="white" p={2}>
                <Timeline
                    array={array}
                    change={change}
                    disableToggles
                    disabled={updating}
                    form={FORM}
                    project={project}
                    shouldConditionallyDisable={(timeline) => {
                        return (
                            (!timeline.isIncluded &&
                                !timeline.required &&
                                !timeline.isCustomDate) ||
                            !editableTimelineDataIds.includes(timeline.id || timeline.uuid)
                        );
                    }}
                    showValidation
                    timezone={timezone}
                />
                <Box mt={2} textAlign="center">
                    <Button
                        bsStyle="primary"
                        disabled={pristine || updating}
                        onClick={() => handleSubmit(onSubmit)()}
                        qaTag="timeline-edit"
                    >
                        Save
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

ConnectedProjectEditTimelinesForm.propTypes = {
    array: PropTypes.object.isRequired,
    change: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool,
};

export const ProjectEditTimelinesForm = connect((state, props) => {
    return {
        initialValues: isInitialClientLoaded(state) ? getProjectJS(state) : undefined,
        filteredTimelines: getFilteredTimelines(state, { ...props, form: FORM }), // used by the validation
    };
})(
    reduxForm({
        form: FORM,
        enableReinitialize: true,
        validate: (values, props) => {
            return projectTimelines(
                { ...values, timelineData: props.filteredTimelines },
                {
                    requireChronologicalOrder: true,
                }
            );
        },
    })(ConnectedProjectEditTimelinesForm)
);

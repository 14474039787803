import { useCustomFormService } from './useCustomFormService';
import { getCustomFormSnapshot } from './queries';

/**
 * Load custom form snapshot schema
 * @param {number | null} customFormId
 * @param {number | null} version
 * @returns {object} react-query useQuery return value
 */
export const useLoadCustomFormSnapshot = (customFormId, version) => {
    return useCustomFormService({
        queryKey: 'getCustomFormSnapshot',
        query: getCustomFormSnapshot,
        enabled: customFormId !== null && version !== null,
        params: {
            customFormId,
            version,
        },
    });
};

import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

export const CreatedDate = ({ date, label = 'Date Created' }) => {
    const styles = require('./shared.scss');

    return (
        <div className={classNames(styles.purchaseOrderItem, styles.date)}>
            <label>{label}</label>
            <div>{moment(date).format('l')}</div>
        </div>
    );
};

CreatedDate.propTypes = {
    date: PropTypes.string.isRequired,
    label: PropTypes.string,
};

export const form = 'unsealBidsForm';

export const UNSEAL_BID_FIELD = 'unsealBidField';
export const UNSEAL_BID_ONLY = 'unsealBidOnly';
export const UNSEAL_BID_WITH_PRICING = 'unsealBidWithPricing';
export const UNSEAL_ALL_PROPOSALS_PRICING = 'unsealAllProposalsPricing';
export const PROPOSAL_IDS = 'proposalIds';

export const unsealBidOptions = [
    {
        label: 'Unseal bid, but keep pricing sealed until later',
        value: UNSEAL_BID_ONLY,
    },
    {
        label: 'Unseal entire bid package including pricing',
        value: UNSEAL_BID_WITH_PRICING,
    },
];

export const unsealBidText =
    'Unsealing the bids will make the vendor responses accessible to all collaborators of this ' +
    'project. Unseal the bids when you have finished gathering responses and are ready to start ' +
    'evaluating them.';

export const unsealBidHelp =
    'Please choose whether you would like to unseal the entire bid package or wait until later ' +
    'to unseal the pricing information:';

export const unsealBidPricingHelp =
    'Please confirm that you would like to unseal the remainder of the bid package including ' +
    'pricing information:';

import React, { PureComponent } from 'react';

import { pseudoSectionTypeNames } from '@og-pro/shared-config/sections';

import { projectFormProps } from '../constants';
import { ProjectOverviewFormSection } from '../../../../components';

const { OVERVIEW } = pseudoSectionTypeNames;

export class Overview extends PureComponent {
    static propTypes = projectFormProps;

    formName = OVERVIEW;

    render() {
        return (
            <div className="row">
                <div className="col-lg-offset-1 col-lg-10">
                    <ProjectOverviewFormSection {...this.props} />
                </div>
            </div>
        );
    }
}

import PropTypes from 'prop-types';
import React from 'react';

export const ReviewSequenceExceptionSequences = ({ exceptionSequences }) => {
    if (exceptionSequences.length === 0) {
        return 'No exception workflows provided';
    }

    return (
        <div>
            <i className="fa fa-fw fa-code-fork" /> Exceptions: {exceptionSequences.length}
        </div>
    );
};

ReviewSequenceExceptionSequences.propTypes = {
    exceptionSequences: PropTypes.arrayOf(PropTypes.number).isRequired,
};

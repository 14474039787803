const countries = require('./countries.json');

const codeMap = {};

countries.forEach((country) => {
    codeMap[country.code.toLowerCase()] = country.name;
});

exports.countries = countries;

exports.getCountryNameFromCode = (code) => {
    return codeMap[code.toLowerCase()];
};

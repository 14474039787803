import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { loadEmailAudit, loadEmailAudits } from '../../../actions/contracts';
import { VendorEmailAuditsTable } from '../../../components';
import { getVendorEmailAuditsJS } from './selectors';
import { getContractJS } from '../../selectors';

const mapStateToProps = (state) => {
    return {
        contract: getContractJS(state),
        vendorEmailAudits: getVendorEmailAuditsJS(state),
    };
};

const mapDispatchToProps = {
    loadEmailAudit,
    loadEmailAudits,
};

// @connect
const ConnectedContractEmailLog = ({ contract, vendorEmailAudits, ...props }) => {
    const loadEmailAuditHandler = (emailUuid) => {
        return props.loadEmailAudit(contract.id, emailUuid);
    };

    const loadEmailAuditsHandler = () => {
        return props.loadEmailAudits(contract.id);
    };

    return (
        <VendorEmailAuditsTable
            emailAudits={vendorEmailAudits}
            loadEmailAudit={loadEmailAuditHandler}
            loadEmailAudits={loadEmailAuditsHandler}
            loaded={false}
        />
    );
};

ConnectedContractEmailLog.propTypes = {
    contract: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }).isRequired,
    loadEmailAudit: PropTypes.func.isRequired,
    loadEmailAudits: PropTypes.func.isRequired,
    vendorEmailAudits: PropTypes.array.isRequired,
};

export const ContractEmailLog = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedContractEmailLog);

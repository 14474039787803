import React from 'react';
import { PropTypes } from 'prop-types';
import { Helmet } from 'react-helmet-async';

import { getMetaTitle } from '../../helpers';

export const PageTitle = ({ title }) => (
    <Helmet>
        <title>{getMetaTitle(title)}</title>
        <meta content={title} property="og:title" />
    </Helmet>
);

PageTitle.propTypes = {
    title: PropTypes.string.isRequired,
};

import { useEffect, useState } from 'react';

/**
 * Custom React hook that detects the active scroll section based on the scroll position.
 * @param {string[]} ids - An array of HTML element IDs to observe and check for scroll section changes.
 * @param {function} callback - A callback function that will be triggered when the active scroll section changes. It will receive the ID of the active scroll section as a parameter.
 * @returns {string|null} - The ID of the active scroll section, or `null` if none is active.
 */
export const useFocusedItemChange = (ids, callback) => {
    const [activeSection, setActiveSection] = useState(null);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const sectionElements = ids
                .map((id) => document.getElementById(id))
                .filter((element) => element);

            sectionElements.forEach((sectionElement) => {
                const sectionTop = sectionElement.offsetTop;
                const sectionHeight = sectionElement.offsetHeight;

                if (scrollPosition >= sectionTop && scrollPosition < sectionTop + sectionHeight) {
                    const sectionId = sectionElement.id;
                    setActiveSection(sectionId);
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        callback(activeSection);
    }, [activeSection]);

    return activeSection;
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { ContentFilters, CustomContent } from './components';
import { fieldNames } from './components/ContentFilters/constants';
import { sectionTypeOptions } from './constants';
import { getFormSectionType, getSortDirectionsJS, getSuggestedContentJS } from './selectors';
import connectData from '../../ConnectData';
import {
    addSuggestedContent,
    deleteSuggestedContent,
    editSuggestedContent,
    loadSuggestedContent,
    shouldLoadSuggestedContent,
    sortContent,
    toggleSuggestedContentForm,
} from '../../../actions/admin';
import { showConfirmationSimpleModal } from '../../../actions/confirmation';
import { LoadingError, LoadingSpinner } from '../../../components';

function fetchData(getState, dispatch, location, params) {
    if (shouldLoadSuggestedContent(getState())) {
        const governmentId = Number.parseInt(params.governmentId, 10);
        return dispatch(loadSuggestedContent(governmentId));
    }
}

const mapStateToProps = (state) => {
    return {
        addContentError: state.admin.get('postContentError'),
        governmentId: state.auth.getIn(['user', 'government_id']),
        loading: state.admin.get('loadingSuggestedContent'),
        loadError: state.admin.get('loadSuggestedContentError'),
        sectionType: getFormSectionType(state),
        showAddContentForm: state.admin.get('showAddContentForm'),
        sortDirections: getSortDirectionsJS(state),
        suggestedContent: getSuggestedContentJS(state),
    };
};

const mapDispatchToProps = {
    addSuggestedContent,
    deleteSuggestedContent,
    editSuggestedContent,
    sortContent,
    toggleSuggestedContentForm,
    showConfirmationSimpleModal,
};

// @connect
class ConnectedSuggestedContent extends Component {
    static propTypes = {
        addContentError: PropTypes.string,
        addSuggestedContent: PropTypes.func.isRequired,
        deleteSuggestedContent: PropTypes.func.isRequired,
        editSuggestedContent: PropTypes.func.isRequired,
        governmentId: PropTypes.number.isRequired,
        loadError: PropTypes.string,
        loading: PropTypes.bool.isRequired,
        sectionType: PropTypes.string,
        showAddContentForm: PropTypes.bool,
        showConfirmationSimpleModal: PropTypes.func.isRequired,
        sortContent: PropTypes.func.isRequired,
        sortDirections: PropTypes.object.isRequired,
        suggestedContent: PropTypes.array.isRequired,
        toggleSuggestedContentForm: PropTypes.func.isRequired,
    };

    get styles() {
        return require('./SuggestedContent.scss');
    }

    addContentSubmitHandler = (formData) => {
        const { governmentId } = this.props;

        return this.props.addSuggestedContent(governmentId, formData);
    };

    deleteSuggestedContent = (contentId) => {
        this.props.showConfirmationSimpleModal(() => this.props.deleteSuggestedContent(contentId), {
            btnText: 'Delete',
            text: 'Are you sure you want to delete this item?',
        });
    };

    renderContent() {
        return (
            <CustomContent
                addContentError={this.props.addContentError}
                addContentHandler={this.addContentSubmitHandler}
                content={this.props.suggestedContent}
                deleteContentHandler={this.deleteSuggestedContent}
                editContentHandler={this.props.editSuggestedContent}
                sectionType={this.props.sectionType}
                showAddForm={this.props.showAddContentForm}
                sortColHandler={this.props.sortContent}
                sortDirections={this.props.sortDirections}
                toggleForm={this.props.toggleSuggestedContentForm}
            />
        );
    }

    render() {
        const { loadError, loading } = this.props;

        if (loading) {
            return <LoadingSpinner />;
        }

        if (loadError) {
            return <LoadingError error={loadError} />;
        }

        return (
            <div className={this.styles.container}>
                <div className="row">
                    <div className="col-xs-12 col-sm-4 col-md-3">
                        <ContentFilters
                            initialValues={{
                                [fieldNames.SECTION_TYPE]: sectionTypeOptions[0].value,
                            }}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-8 col-md-9">{this.renderContent()}</div>
                </div>
            </div>
        );
    }
}

export const SuggestedContent = compose(
    connectData(fetchData),
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedSuggestedContent);

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { EvaluatorScoresTable, ReportDocxTable } from '../../../../../../components';
import { getTotalWeight } from '../../../../../../helpers';
import {
    AggregateEvaluationTable,
    AggregateScoringCriteriaEvaluationTable,
} from '../../../../EvaluationNav/AggregateEvaluation/components';
import { PassFailReport } from '../../PassFailReport';
import {
    getConsensusScorecardData,
    getEvaluationsData,
    getEvaluationCriteriaListColumnDefs,
    getEvaluatorsListColumnDefs,
} from '../helpers';
import { ExpandedScoresList } from './ExpandedScoresList';
import { SelectedVendor } from './SelectedVendor';

export class BestValueReport extends PureComponent {
    static propTypes = {
        isRenderedBySummaryReport: PropTypes.bool,
        isSummary: PropTypes.bool.isRequired,
        options: PropTypes.shape({
            showAnonymizedEvaluators: PropTypes.bool,
            showPercentageTotals: PropTypes.bool,
            useLandscape: PropTypes.bool,
        }).isRequired,
        project: PropTypes.object.isRequired,
        proposalsWithQuestionnaireResponses: PropTypes.array.isRequired,
    };

    render() {
        const {
            isRenderedBySummaryReport,
            isSummary,
            options: { showAnonymizedEvaluators, showPercentageTotals, useLandscape },
            project,
            proposalsWithQuestionnaireResponses,
        } = this.props;

        const allEvaluationPhases = getEvaluationsData(project);
        const hasConsensusEvaluation = project.evaluation.hasConsensusEvaluation;
        const hasProposalViewerAgreement = !!project.standard_document_id;
        const timezone = project.government.organization.timezone;
        const { usesRankedScoring } = project.government;

        const headingStyle = { fontSize: 18, fontWeight: 'bold' };
        const phaseStyle = { fontSize: 24, fontWeight: 'bold', textAlign: 'center' };
        const subHeadingStyle = { fontSize: 14, fontWeight: 'bold' };

        return (
            <>
                {!isRenderedBySummaryReport && (
                    <SelectedVendor
                        headingStyle={headingStyle}
                        project={project}
                        showPercentageTotals={showPercentageTotals}
                        subHeadingStyle={subHeadingStyle}
                        useLandscape={useLandscape}
                    />
                )}
                {hasConsensusEvaluation && (
                    <>
                        <div style={headingStyle}>CONSENSUS SCORECARD SUMMARY</div>
                        <EvaluatorScoresTable
                            isConsensusView
                            isDocx
                            proposals={getConsensusScorecardData(project)}
                            scoringCriteria={project.evaluation.scoringCriteria}
                            showPercentageTotals={showPercentageTotals}
                            totalWeight={getTotalWeight(project.evaluation)}
                            useLandscape={useLandscape}
                        />
                        {!isSummary && (
                            <>
                                <div style={headingStyle}>CONSENSUS SCORECARD DETAILS</div>
                                <ExpandedScoresList
                                    isConsensusView
                                    proposals={getConsensusScorecardData(project)}
                                    scoringCriteria={project.evaluation.scoringCriteria}
                                    showAnonymizedEvaluators={showAnonymizedEvaluators}
                                    showPercentageTotals={showPercentageTotals}
                                    totalWeight={getTotalWeight(project.evaluation)}
                                />
                            </>
                        )}
                    </>
                )}
                {proposalsWithQuestionnaireResponses && (
                    <PassFailReport
                        headingStyle={{ ...headingStyle, marginTop: 20 }}
                        project={project}
                        proposals={proposalsWithQuestionnaireResponses}
                        useLandscape={useLandscape}
                    />
                )}
                {allEvaluationPhases.map((evaluationPhase, i) => {
                    const totalWeight = getTotalWeight(evaluationPhase);

                    return (
                        <React.Fragment key={i}>
                            <br />
                            <div style={phaseStyle}>PHASE {evaluationPhase.phaseNumber}</div>
                            {!showAnonymizedEvaluators && (
                                <>
                                    <div style={headingStyle}>EVALUATORS</div>
                                    <ReportDocxTable
                                        columns={getEvaluatorsListColumnDefs(
                                            timezone,
                                            hasProposalViewerAgreement
                                        )}
                                        data={evaluationPhase.evaluators}
                                        tableWidth={450}
                                        useLandscape={useLandscape}
                                    />
                                </>
                            )}
                            <div style={headingStyle}>EVALUATION CRITERIA</div>
                            <ReportDocxTable
                                columns={getEvaluationCriteriaListColumnDefs(totalWeight)}
                                columnsToDisplayOutside={['description']}
                                data={evaluationPhase.scoringCriteria}
                                useLandscape={useLandscape}
                            />
                            <div style={headingStyle}>AGGREGATE SCORES SUMMARY</div>
                            <AggregateEvaluationTable
                                evaluators={evaluationPhase.evaluators}
                                isDocx
                                proposals={evaluationPhase.scoredProposals}
                                showAnonymizedEvaluators={showAnonymizedEvaluators}
                                showPercentageTotals={showPercentageTotals}
                                totalWeight={totalWeight}
                                useLandscape={useLandscape}
                                usesRankedScoring={usesRankedScoring}
                            />
                            <div style={headingStyle}>VENDOR SCORES BY EVALUATION CRITERIA</div>
                            <AggregateScoringCriteriaEvaluationTable
                                isDocx
                                proposals={evaluationPhase.scoredProposals}
                                showPercentageTotals={showPercentageTotals}
                                totalWeight={totalWeight}
                                useLandscape={useLandscape}
                            />
                            {!isSummary && (
                                <>
                                    <div style={headingStyle}>INDIVIDUAL PROPOSAL SCORES</div>
                                    <ExpandedScoresList
                                        proposals={evaluationPhase.scoredProposals}
                                        scoringCriteria={evaluationPhase.scoringCriteria}
                                        showAnonymizedEvaluators={showAnonymizedEvaluators}
                                        showPercentageTotals={showPercentageTotals}
                                        totalWeight={getTotalWeight(project.evaluation)}
                                    />
                                </>
                            )}
                        </React.Fragment>
                    );
                })}
            </>
        );
    }
}

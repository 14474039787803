import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { ListGroup, ListGroupItem } from 'react-bootstrap';

import { currencyFormatter } from '../../../helpers';
import { ZeroState } from '../../ZeroState';

export const ReverseAuctionBidHistoryTable = ({
    auctionMaxFractionDigits,
    bidHistoryData,
    isAgencyView,
    timezone,
    vendorId,
}) => {
    const styles = require('./index.scss');

    const renderBidEntry = (bid) => {
        const { createdAt, id, name, totalBid, unitPrice, vendorUserId, isCreatedByProxy } = bid;
        const isBidPlacedByCurrentVendorUser = vendorId && vendorId === vendorUserId;
        const showProxyLabel = isAgencyView && isCreatedByProxy;
        const formatterOpts = {
            maximumFractionDigits: auctionMaxFractionDigits,
            useSameMinAndMax: true,
        };

        return (
            <ListGroupItem
                className={`${styles.bidRow} ${
                    isBidPlacedByCurrentVendorUser ? styles.currentVendorRow : ''
                }`}
                key={id}
            >
                <div className={`row ${styles.body}`}>
                    <div className="col-sm-3">
                        <div>
                            {moment.tz(createdAt, timezone).format('h:mm:ss a')} on{' '}
                            {moment.tz(createdAt, timezone).format('L')}
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div>{name}</div>
                        {showProxyLabel && <div className={styles.proxyLabel}>Proxy Bid</div>}
                    </div>
                    <div className="col-sm-3">
                        <div className="text-right">
                            {currencyFormatter({ value: unitPrice }, formatterOpts)}
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="text-right">
                            {currencyFormatter({ value: totalBid }, formatterOpts)}
                        </div>
                    </div>
                </div>
            </ListGroupItem>
        );
    };

    if (bidHistoryData.length === 0) {
        return <ZeroState title="No bid activity has been recorded yet" />;
    }

    return (
        <ListGroup>
            <ListGroupItem className={`${styles.header} ${styles.bidRow}`}>
                <div className={`row ${styles.body}`}>
                    <div className="col-xs-3">Bid Time</div>
                    <div className="col-xs-3">Bidder</div>
                    <div className="col-xs-3">
                        <div className="text-right">Unit Price</div>
                    </div>
                    <div className="col-xs-3">
                        <div className="text-right">Total Bid</div>
                    </div>
                </div>
            </ListGroupItem>
            {bidHistoryData.map(renderBidEntry)}
        </ListGroup>
    );
};

ReverseAuctionBidHistoryTable.propTypes = {
    auctionMaxFractionDigits: PropTypes.number.isRequired,
    bidHistoryData: PropTypes.arrayOf(PropTypes.object).isRequired,
    isAgencyView: PropTypes.bool,
    timezone: PropTypes.string.isRequired,
    vendorId: PropTypes.number,
};

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
    defaultSectionConfigsMap,
    sectionTypes,
    sectionTypeNames,
} from '@og-pro/shared-config/sections';

import { TemplateEditV2AddSectionMenu } from '../AddSectionMenu';
import { TemplateEditV2AddSection } from '../AddSection';
import { TemplateEditV2ManageSectionContext } from '../../../../context';
import { useAddTemplateSection } from '../../../../hooks';

const { DIVIDER } = sectionTypeNames;

export const TemplateEditV2DrawerContentAddSectionBlock = ({
    buttonComponent,
    index,
    sectionType,
    isChild,
}) => {
    // when adding a section from the drawer, there are multiple buttons to "add section"
    // we want only one of those to be active at the time, that's why we getting the data from context
    const { data: addingSectionData, setData } = useContext(TemplateEditV2ManageSectionContext);
    const { onAddSection } = useAddTemplateSection({
        setAddingSection: () => {
            setData({
                active: true,
                editing: false,
                index,
                isTemplate: false,
                sectionType: { ...defaultSectionConfigsMap[sectionType], title: '' },
            });
        },
    });

    const styles = require('../index.scss');

    const unsetAddingSectionData = () => {
        setData({
            active: false,
            editing: false,
            index: null,
            isTemplate: null,
            sectionType: null,
        });
    };

    const onAddSectionClick = () => {
        if (sectionType) {
            // if we have the sectionType (currently means we are adding a divider)
            // we want to trigger the check for changes dialog to prompt the user to save
            // we are reusing the hook from the left-side menu way of adding sections.
            // We can pass a null as the templateSection param because we already know what section
            // we are talking about (check first line of this comment).
            onAddSection(null, false);
        } else {
            setData({ active: true, editing: false, index, isTemplate: false, sectionType: null });
        }
    };

    const setAddingSectionFromMenu = (selectedSectionType, isTemplate) => {
        setData({
            active: true,
            editing: false,
            index,
            isTemplate,
            sectionType: { ...selectedSectionType },
        });
    };

    // since we only want the form to be active for one of the "add section" buttons
    // conditionally render if "active" but also if the index is right
    if (addingSectionData.active && addingSectionData.index === index) {
        // if we know the section type, display the form
        // The second part of the conditional prevents two "add section" forms opening when
        // we have dividers and there's repeated indexes because of the double "add section/divider" buttons
        if (
            addingSectionData.sectionType &&
            (addingSectionData.sectionType.section_type === sectionType ||
                (!sectionType && addingSectionData.sectionType.section_type !== DIVIDER))
        ) {
            return (
                <div
                    className={classnames(styles.addSectionBlock, {
                        [styles.addSectionBlockSpacing]: true,
                        [styles.childSection]: isChild,
                    })}
                >
                    <TemplateEditV2AddSection
                        addingSectionData={addingSectionData}
                        allowParentDividerSelection={false}
                        drawerButtonsLayout
                        unsetAddingSection={unsetAddingSectionData}
                    />
                </div>
            );
        }

        // if we don't know the section type we have to display
        // the section type selection menu
        if (!sectionType && !addingSectionData.sectionType) {
            return (
                <div
                    className={classnames(styles.addSectionBlockSpacing, {
                        [styles.childSection]: isChild,
                    })}
                >
                    <TemplateEditV2AddSectionMenu
                        disableCollapsable
                        hideDividers
                        setAddingSection={setAddingSectionFromMenu}
                        unsetAddingSection={unsetAddingSectionData}
                    />
                </div>
            );
        }
    }

    return (
        <>
            {React.createElement(buttonComponent, {
                onClick: () => onAddSectionClick(),
            })}
        </>
    );
};

TemplateEditV2DrawerContentAddSectionBlock.propTypes = {
    buttonComponent: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    isChild: PropTypes.bool,
    sectionType: PropTypes.oneOf(sectionTypes),
};

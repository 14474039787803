import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { buildMap } from '@og-pro/shared-config/helpers';

import { questionTypesDisplayMap } from '@og-pro/shared-config/questionnaires';

import { TemplateQuestionAddForm } from './TemplateQuestionAddForm';
import { TEMPLATE_QUESTION_ID } from './TemplateQuestionAddForm/constants';
import { showConfirmationSimpleModal } from '../../../actions/confirmation';
import { CDSButton, OutlineButton } from '../../../components';
import { getTemplateQuestions } from '../../../selectors/govApp';

export const TemplateQuestionAddButton = ({
    addQuestion,
    disabled,
    isDirty,
    size = null,
    updateTemplate,
    upfrontQuestionFormValues,
    useOpenGovStyle = false,
}) => {
    const templateQuestions = useSelector(getTemplateQuestions);
    const dispatch = useDispatch();
    const [showButton, setShowButton] = useState(true);
    const templateQuestionsInUse = upfrontQuestionFormValues.filter((q) => q.isTemplate);
    const templateQuestionSharedIdsInUse = buildMap(templateQuestionsInUse, 'sharedId');

    const templateQuestionOptions = templateQuestions
        .filter((templateQuestion) => {
            const {
                isPublished,
                upfrontQuestion: { sharedId },
            } = templateQuestion;

            // Template question can only be used once per template
            return isPublished && !templateQuestionSharedIdsInUse[sharedId];
        })
        .map((templateQuestion) => {
            const {
                id,
                upfrontQuestion: { title, type },
            } = templateQuestion;

            return {
                icon: questionTypesDisplayMap[type].icon,
                label: title,
                value: id,
            };
        });

    const addTemplateQuestionHandler = (formData) => {
        setShowButton(true);

        const addTemplateQuestion = () => {
            addQuestion(formData[TEMPLATE_QUESTION_ID]);
        };

        const onConfirm = () => {
            const attemptUpdate = updateTemplate();

            // If validation fails, `updateTemplate` does not return a Promise and we should not
            // proceed with adding the template question
            if (typeof attemptUpdate.then === 'function') {
                attemptUpdate.then(addTemplateQuestion);
            }
        };

        // If form is not isDirty we can proceed with adding the section
        if (!isDirty) {
            return addTemplateQuestion();
        }

        dispatch(
            showConfirmationSimpleModal(onConfirm, {
                bsStyle: 'primary',
                btnText: 'Save Changes',
                cancelText: 'Discard Changes',
                icon: 'check',
                onCancel: addTemplateQuestion,
                text: 'You have unsaved changes.\n\nWould you like to save your changes before adding this question?\nIf you choose not to save, all unsaved changes will be lost.',
                title: 'Save Changes?',
            })
        );
    };

    if (showButton) {
        const sharedProps = {
            children: (
                <>
                    <i className="fa fa-plus" /> Add Shared Question&nbsp;
                    <i className="fa fa-caret-down" />
                </>
            ),
            disabled,
            block: true,
            onClick: () => setShowButton(false),
            qaTag: 'templateAdmin-sharedQuestionsToAdd',
        };

        if (useOpenGovStyle) {
            return <CDSButton {...sharedProps} size={size} variant="text" />;
        }

        return <OutlineButton bsStyle="primary" {...sharedProps} />;
    }

    return (
        <TemplateQuestionAddForm
            onBlur={useOpenGovStyle ? () => setShowButton(true) : null}
            onChange={addTemplateQuestionHandler}
            options={templateQuestionOptions}
            size="small"
            useOpenGovStyle={useOpenGovStyle}
        />
    );
};

TemplateQuestionAddButton.propTypes = {
    addQuestion: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    isDirty: PropTypes.bool,
    size: PropTypes.string,
    updateTemplate: PropTypes.func.isRequired,
    upfrontQuestionFormValues: PropTypes.array.isRequired,
    useOpenGovStyle: PropTypes.bool,
};

import React from 'react';
import PropTypes from 'prop-types';

// fileRejectionErrors can be a string , or an array of rejection objects from the Dropzone component (defaultRejectMessage from StyledDropzone)
// When fileRejectionErrors is an array of rejection objects, build the rejection reasons together. This is typically just one object.
const DropzoneErrorMessages = ({ defaultRejectMessage, fileRejectionErrors }) => {
    let errorMessages = <span>{fileRejectionErrors}</span>;

    if (fileRejectionErrors instanceof Array) {
        const filesRejected = fileRejectionErrors.map((rejection) => rejection.file.name);

        errorMessages = (
            <span>{`${filesRejected.join(', ')} could not be uploaded. Please try again.`}</span>
        );
    }

    if (defaultRejectMessage) {
        errorMessages = defaultRejectMessage;
    }

    return (
        <div aria-invalid className="error-block">
            <i className="fa fa-warning" />
            &nbsp;{errorMessages}
        </div>
    );
};

const DropzoneFileRejectionPropType = PropTypes.shape({
    file: PropTypes.shape({
        name: PropTypes.string,
    }).isRequired,
});

export const FileRejectionErrorsPropType = PropTypes.oneOfType([
    PropTypes.arrayOf(DropzoneFileRejectionPropType),
    PropTypes.string,
]);

DropzoneErrorMessages.propTypes = {
    defaultRejectMessage: PropTypes.string,
    fileRejectionErrors: FileRejectionErrorsPropType,
};

export default DropzoneErrorMessages;

import { get } from 'lodash';
import { createSelector } from 'reselect';

import { evaluationStatuses } from '@og-pro/shared-config/evaluations';

import { getCurrentScoredProposals, getEvaluationProjectJS } from '../../selectors';

const { RELEASED } = evaluationStatuses;

export const getEvaluators = createSelector([getCurrentScoredProposals], (proposals) => {
    const [proposal] = proposals;
    return get(proposal, 'proposalEvaluations', []).map(
        (proposalEvaluation) => proposalEvaluation.user
    );
});

export const isEvaluationCompletable = createSelector([getEvaluationProjectJS], (project) => {
    if (project) {
        return project.evaluation.status === RELEASED;
    }
    return false;
});

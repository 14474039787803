import { fromJS } from 'immutable';

import * as proposalEvaluationActions from '../../actions/proposalEvaluations';

function proposalEvaluationsReducer(state, action) {
    if (state.get('id') !== action.proposalEvaluationId) {
        return state;
    }

    switch (action.type) {
        case proposalEvaluationActions.UPDATE:
            return state.merge(
                fromJS({
                    updating: true,
                    updateError: null,
                })
            );
        case proposalEvaluationActions.UPDATE_FAIL:
            return state.merge(
                fromJS({
                    updating: false,
                    updateError: action.error.message,
                })
            );
        case proposalEvaluationActions.UPDATE_SUCCESS:
            return fromJS(action.result);
        default:
            return state;
    }
}

function scoredProposalReducer(state, action) {
    // The scorecard data will not exist when actions called from outside modal
    if (state.get('id') !== action.proposalId) {
        return state;
    }

    switch (action.type) {
        case proposalEvaluationActions.BACKGROUND_RELOAD_AGGREGATE:
            return state.merge(
                fromJS({
                    scoringCriteria: action.result.scoringCriteria,
                    totalScore: action.result.totalScore,
                    totalScoreByPoints: action.result.totalScoreByPoints,
                })
            );
        case proposalEvaluationActions.UPDATE:
        case proposalEvaluationActions.UPDATE_SUCCESS:
        case proposalEvaluationActions.UPDATE_FAIL: {
            if (action.isConsensus) {
                return state.set(
                    'consensusEvaluation',
                    proposalEvaluationsReducer(state.get('consensusEvaluation'), action)
                );
            }
            return state.set(
                'proposalEvaluations',
                state.get('proposalEvaluations').map((p) => proposalEvaluationsReducer(p, action))
            );
        }
        default:
            return state;
    }
}

export default function scoredProposalsReducer(state, action) {
    switch (action.type) {
        case proposalEvaluationActions.BACKGROUND_RELOAD_AGGREGATE:
            return action.result.reduce((updatedState, prop) => {
                const newAction = { type: action.type, proposalId: prop.id, result: prop };
                return updatedState.map((proposal) => scoredProposalReducer(proposal, newAction));
            }, state);
        case proposalEvaluationActions.LOAD_AGGREGATE_SUCCESS:
            return fromJS(action.result);
        case proposalEvaluationActions.UPDATE:
        case proposalEvaluationActions.UPDATE_SUCCESS:
        case proposalEvaluationActions.UPDATE_FAIL:
            return state.map((proposal) => scoredProposalReducer(proposal, action));
        default:
            return state;
    }
}

import { createSelector } from 'reselect';

const getVendorRsvps = (state) => state.rsvps.get('vendorRsvps');

export const getVendorRsvpsJS = createSelector([getVendorRsvps], (rawVendorRsvps) => {
    if (rawVendorRsvps) {
        return rawVendorRsvps.toJS();
    }
});

const getProject = (props) => props.project;

export const getShowRsvpButton = createSelector([getProject], (project) => {
    if (project) {
        const { timelineConfig, timelines } = project;

        return timelineConfig.hasPreProposalDate || timelines.some((timeline) => timeline.isPublic);
    }
});

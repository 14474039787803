import React, { useCallback, useRef, useState } from 'react';
import { Box, Typography, IconButton } from '@og-pro/ui';
import { Download as DownloadIcon, Edit as EditIcon } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { AgGridReact, HelpIcon, Toggle } from '../../../../components';
import { getContractJS } from '../../../selectors';
import { showConfirmationSimpleModal } from '../../../../actions/confirmation';
import { updateContract } from '../../../../actions/contracts';
import { CollapsableBox } from './CollapsableBox';
import { UploadAttachmentButton } from './UploadAttachmentButton';
import { EditAttachment } from './EditAttachment';

export const AssociatedAttachments = () => {
    const styles = require('../index.scss');
    const contract = useSelector(getContractJS);
    const dispatch = useDispatch();
    const [attachmentBeingEdited, setAttachmentBeingEdited] = useState(null);
    const gridApiRef = useRef(null);
    const rows = contract.attachments;

    const handleGridReady = (params) => {
        gridApiRef.current = params.api;
    };

    const DateCellRenderer = (params) =>
        params.value !== null
            ? new Date(params.value).toLocaleDateString('en-us', { timeZone: 'UTC' })
            : null;

    const refreshGrid = () => {
        gridApiRef.current.refreshCells({ force: true });
    };

    const onEditFinish = () => {
        setAttachmentBeingEdited(null);
        refreshGrid();
    };

    const handleOnChangeToggle = useCallback((e, params) => {
        const attachment = params.data;
        const isPublicTarget = e.target.checked;
        const updatedAttachment = {
            ...attachment,
            contractAttachment: {
                ...attachment.contractAttachment,
                isPublic: isPublicTarget,
            },
            tags: attachment.tags[0]?.id || null,
        };

        const modalData = isPublicTarget
            ? {
                  bsStyle: 'warning',
                  btnText: 'Make Public',
                  icon: 'globe',
                  text: `Are you sure you want ${attachment.filename} to be Public?`,
              }
            : {
                  bsStyle: 'danger',
                  btnText: 'Make Private',
                  icon: 'lock',
                  text: `Are you sure you want ${attachment.filename} to be Private?`,
              };

        dispatch(
            showConfirmationSimpleModal(
                () =>
                    dispatch(
                        updateContract(contract.id, {
                            attachments: [updatedAttachment],
                        })
                    ).then(() => {
                        gridApiRef.current.refreshCells({ force: true });
                    }),
                modalData
            )
        );
    }, []);

    const columns = [
        {
            field: 'type',
            headerName: 'Document Type',
            width: 200,
            cellRendererFramework: (params) => params.data.tags.map((tag) => tag.name).join(', '),
        },
        {
            field: 'filename',
            headerName: 'File Name',
            width: 300,
        },
        {
            field: 'created_at',
            headerName: 'Date Added',
            width: 168,
            cellRendererFramework: DateCellRenderer,
        },
        {
            width: 180,
            headerComponentFramework: () => (
                <div className="ag-cell-label-container flex">
                    <HelpIcon
                        className={styles.tooltip}
                        tooltip="Public Documents will be accessible to the awarded vendor and to the general public. Non-public documents will remain private to internal staff only."
                        useOpenGovStyle
                    />
                    <div className="ag-header-cell-text">Display</div>
                </div>
            ),
            cellRendererFramework: (params) => (
                <Box display="flex">
                    <Toggle
                        className={styles.toggle}
                        id="IsAttachmentPublicToggle"
                        input={{
                            onBlur: () => {},
                            value: params.data.contractAttachment?.isPublic,
                        }}
                        leftLabel=""
                        onChange={(e) => handleOnChangeToggle(e, params)}
                        qaTag="contractDocuments-toggleIsPublic"
                        rightLabel=""
                        useOpenGovStyle
                    />
                    <Typography
                        sx={{
                            marginLeft: '8px !important',
                            color: capitalDesignTokens.foundations.colors.gray1000,
                        }}
                        variant="small"
                    >
                        Public
                    </Typography>
                </Box>
            ),
        },
        {
            headerName: 'Notes',
            flex: 1,
            minWidth: 300,
            cellRendererFramework: () => {
                return <div>Not implemented</div>;
            },
        },
        {
            cellClass: styles.iconCells,
            cellRendererFramework: (params) => (
                <Box display="flex">
                    <IconButton
                        component="a"
                        download
                        href={params.data?.url}
                        qaTag="contractDocuments-downloadAttachment"
                        size="small"
                    >
                        <DownloadIcon fontSize="small" />
                    </IconButton>
                    <IconButton qaTag="contractDocuments-editAttachment" size="small">
                        <EditIcon
                            fontSize="small"
                            onClick={() => {
                                setAttachmentBeingEdited(params.data);
                            }}
                        />
                    </IconButton>
                </Box>
            ),
            width: 80,
        },
    ];

    const defaultColDef = {
        editable: false,
        headerClass: styles.header,
        cellClass: styles.cell,
        suppressMenu: true,
        resizable: false,
    };
    return (
        <Box>
            <EditAttachment attachment={attachmentBeingEdited} onFinish={onEditFinish} />
            <CollapsableBox
                actions={
                    <Box flex={1} textAlign="right">
                        <UploadAttachmentButton onUploadSuccess={refreshGrid} />
                    </Box>
                }
                subtitle="Upload and manage all other document types, along with approved
                            documents from vendors."
                title="Associated Attachments"
            >
                {!rows.length && (
                    <Typography fontStyle="italic">No associated attachments</Typography>
                )}

                {!!rows.length && (
                    <AgGridReact
                        columns={columns}
                        defaultColDef={defaultColDef}
                        domLayout="autoHeight"
                        getRowNodeId={(data) => data.id}
                        hideSideBar
                        onGridReady={handleGridReady}
                        rowHeight={52}
                        rows={rows}
                        useOpenGovStyle
                    />
                )}
            </CollapsableBox>
        </Box>
    );
};

import { createSelector } from 'reselect';

import { getGovernmentJS } from '../selectors';
import { getUserJS } from '../../selectors';

export const getGovernmentSubscription = createSelector(
    [getUserJS, getGovernmentJS],
    (user, government) => {
        if (user && government) {
            return (user.governmentSubscriptions || []).find((subscription) => {
                return subscription.government_id === government.id;
            });
        }
    }
);

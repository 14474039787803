export const buildTabRoute = (location) => (route) => {
    let baseRoute = location.pathname;

    if (baseRoute.match(/[^\d]$/)) {
        const tabParameterIndex = baseRoute.lastIndexOf('/');

        baseRoute = baseRoute.substring(0, tabParameterIndex);
    }

    return `${baseRoute}/${route}`;
};

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Button, HelpToolTip } from '../../../../../../../components';

export class OptionsTitle extends PureComponent {
    static propTypes = {
        description: PropTypes.string,
        optionsToggleHandler: PropTypes.func.isRequired,
        showHelpModal: PropTypes.func.isRequired,
        title: PropTypes.string,
    };

    static defaultProps = {
        description: undefined,
        title: undefined,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const { description, optionsToggleHandler, showHelpModal, title } = this.props;

        return (
            <div className={this.styles.addTitleBtnContainer}>
                <div className={this.styles.titleContainer}>
                    <span className={this.styles.title}>{title || 'Untitled Table'}</span>
                    <HelpToolTip onClick={showHelpModal} text="Click to see instructions" />
                    &nbsp;&nbsp;
                    <Button
                        bsSize="sm"
                        bsStyle="link"
                        onClick={optionsToggleHandler}
                        qaTag="optionsTitle-edit"
                        zeroPadding
                    >
                        <i className="fa fa-pencil" /> Edit
                    </Button>
                </div>
                {description && <div className={this.styles.description}>{description}</div>}
            </div>
        );
    }
}

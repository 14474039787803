import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { ContractReviewsListItem } from './ContractReviewsListItem';
import { getUserContractReviewJS } from './selectors';
import {
    getContractAggregateRating,
    getContractComplaintsCounts,
    getContractPath,
    getContractReviewsJS,
    isContractEditor,
} from '../../selectors';
import connectData from '../../../ConnectData';
import { getContractJS, getUserJS } from '../../../selectors';
import {
    createContractReview,
    loadContractReviews,
    showContractComplaintModal,
} from '../../../../actions/contracts';
import {
    Button,
    LoadingError,
    LoadingSpinner,
    Main,
    PageTitle,
    StarsDisplay,
    ZeroState,
} from '../../../../components';

function fetchData(getState, dispatch, location, params) {
    const contractId = Number.parseInt(params.contractId, 10);
    return dispatch(loadContractReviews(contractId));
}

const mapStateToProps = (state, props) => {
    return {
        ...getContractComplaintsCounts(state),
        aggregateRating: getContractAggregateRating(state),
        contract: getContractJS(state),
        contractPath: getContractPath(state, props),
        contractReviews: getContractReviewsJS(state),
        isEditor: isContractEditor(state),
        loadError: state.contracts.get('loadContractsReviewError'),
        loading: state.contracts.get('loadingContractsReview'),
        user: getUserJS(state),
        userReview: getUserContractReviewJS(state),
    };
};

const mapDispatchToProps = {
    createContractReview,
    showContractComplaintModal,
};

// @connectData
// @connect
class ConnectedContractReviewsList extends Component {
    static propTypes = {
        aggregateRating: PropTypes.number,
        contract: PropTypes.shape({
            contractParty: PropTypes.object,
            id: PropTypes.number.isRequired,
        }).isRequired,
        contractComplaintsTotal: PropTypes.number.isRequired,
        contractComplaintsUnresolved: PropTypes.number.isRequired,
        contractPath: PropTypes.string.isRequired,
        contractReviews: PropTypes.array.isRequired,
        createContractReview: PropTypes.func.isRequired,
        isEditor: PropTypes.bool,
        loadError: PropTypes.string,
        loading: PropTypes.bool.isRequired,
        router: PropTypes.object.isRequired,
        showContractComplaintModal: PropTypes.func.isRequired,
        user: PropTypes.object.isRequired,
        userReview: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            createError: null,
            creating: null,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    createContractReview = () => {
        const { contract } = this.props;

        this.setState({ creating: true, createError: null });
        this.props
            .createContractReview(contract.id)
            .then((result) => {
                this.setState({ creating: false });
                this.routeToReviewPage(result.id);
            })
            .catch((error) => {
                this.setState({ creating: false, createError: error.message });
            });
    };

    routeToReviewPage = (contractReviewId) => {
        const { contractPath, router } = this.props;

        router.push(`${contractPath}/reviews/${contractReviewId}/edit`);
    };

    renderContractReviews() {
        const {
            contract: { contractParty },
            contractReviews,
            isEditor,
            user,
        } = this.props;

        if (!contractParty) {
            return <ZeroState title="No contract vendor has been added yet" />;
        }

        if (contractReviews.length === 0) {
            return (
                <ZeroState
                    title={
                        <>
                            No reviews have been left for the vendor yet.
                            <br />
                            Be the first to write a review or file a complaint if you have an issue.
                        </>
                    }
                />
            );
        }

        return (
            <ListGroup className={this.styles.contractReviewsList}>
                {contractReviews.map((contractReview) => (
                    <ListGroupItem
                        className={this.styles.contractReviewsListItem}
                        key={contractReview.id}
                    >
                        <ContractReviewsListItem
                            contractReview={contractReview}
                            readOnly={!isEditor && user.id !== contractReview.user_id}
                            showContractComplaintModal={this.props.showContractComplaintModal}
                        />
                    </ListGroupItem>
                ))}
            </ListGroup>
        );
    }

    render() {
        const {
            aggregateRating,
            contract,
            contractComplaintsTotal,
            contractComplaintsUnresolved,
            contractReviews,
            loadError,
            loading,
            userReview,
        } = this.props;

        const { createError, creating } = this.state;

        if (loading) {
            return <LoadingSpinner />;
        }

        if (loadError) {
            return <LoadingError error={loadError} />;
        }

        const vendorName =
            get(contract, 'contractParty.vendor.organization.name') ||
            get(contract, 'contractParty.companyName');

        return (
            <Main>
                <PageTitle title="Contract Reviews" />
                <div className="pull-right">
                    {userReview ? (
                        <Button
                            bsStyle="link"
                            onClick={() => this.routeToReviewPage(userReview.id)}
                        >
                            <i className="fa fa-pencil" /> Edit{!userReview.isPublished && ' Draft'}{' '}
                            Review
                        </Button>
                    ) : (
                        <Button
                            bsStyle="primary"
                            disabled={creating}
                            onClick={this.createContractReview}
                        >
                            <i className="fa fa-star-o" /> Write a Review
                        </Button>
                    )}
                    {createError && <div className="error-block">{createError}</div>}
                </div>
                <div className={this.styles.reviewDetails}>
                    <h3 className={this.styles.title}>{vendorName}</h3>
                    <div className={this.styles.stars}>
                        <StarsDisplay value={aggregateRating} />
                        &nbsp;
                        <strong>
                            {contractReviews.length === 0
                                ? '0 Reviews'
                                : `${aggregateRating} out of 5 (${contractReviews.length} Reviews)`}
                        </strong>
                    </div>
                    <div className={this.styles.complaintData}>
                        {contractComplaintsTotal} Total Complaints
                        {contractComplaintsTotal > 0 &&
                            ` (${contractComplaintsUnresolved} Unresolved)`}
                    </div>
                </div>
                {this.renderContractReviews()}
            </Main>
        );
    }
}

export const ContractReviewsList = compose(
    connectData(fetchData),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedContractReviewsList);

import { buildQaTag, componentNames } from '@og-pro/ui';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Checkbox as BootstrapCheckbox, FormGroup, HelpBlock } from 'react-bootstrap';

import { HelpBlock as CustomHelpBlock } from '../HelpBlock/HelpBlock';
import { Label } from '../InputText/Label';
import { MultiSelectSVGShape } from '../SVGShapes/Inputs';

export class Checkbox extends PureComponent {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.node,
        disabled: PropTypes.bool,
        formGroupClass: PropTypes.string,
        inline: PropTypes.bool,
        input: PropTypes.object.isRequired,
        helpIcon: PropTypes.bool,
        helpIconClick: PropTypes.func,
        helpIconNode: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        invertValue: PropTypes.bool,
        label: PropTypes.string,
        meta: PropTypes.shape({
            error: PropTypes.string,
            touched: PropTypes.bool.isRequired,
            warning: PropTypes.string,
        }).isRequired,
        qaTag: PropTypes.string.isRequired, // Format: <pageName>-<simpleDescription>
        showValidation: PropTypes.bool,
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        useOpenGovStyle: PropTypes.bool,
    };

    static defaultProps = {
        children: undefined,
        disabled: false,
        formGroupClass: undefined,
        helpIcon: false,
        helpIconClick: undefined,
        helpIconNode: undefined,
        label: undefined,
        showValidation: false,
    };

    get styles() {
        return require('./index.scss');
    }

    get validationState() {
        const {
            meta: { error, touched, warning },
            showValidation,
        } = this.props;

        const displayValidation = touched || showValidation;

        if (displayValidation && error) return ['error', error];
        if (displayValidation && warning) return ['warning', warning];
        return [];
    }

    handleBlur = () => {
        const { input } = this.props;

        input.onBlur(input.value);
    };

    handleChange = () => {
        const { input } = this.props;

        input.onChange(!input.value);
    };

    render() {
        const {
            children,
            className,
            formGroupClass,
            helpIcon,
            helpIconClick,
            helpIconNode,
            invertValue,
            input,
            label,
            meta,
            qaTag,
            showValidation,
            text,
            useOpenGovStyle,
            ...props
        } = this.props;

        const [validationState, helpText] = this.validationState;

        const tagWithComponentName = buildQaTag(qaTag, componentNames.CHECKBOX);

        // NOTE: The `checked` prop only gets set in redux-form if type="checkbox" passed to the
        // `Field` component, which is why we set it here.
        const id = tagWithComponentName || input.name || label;

        if (useOpenGovStyle) {
            const value = invertValue ? !input.value : input.value;

            return (
                <FormGroup
                    className={classnames(formGroupClass, this.styles.formGroup)}
                    validationState={validationState}
                >
                    <Label
                        className={this.styles.label}
                        helpIcon={helpIcon}
                        helpIconClick={helpIconClick}
                        helpIconNode={helpIconNode}
                        htmlFor={id}
                        label={label}
                    />
                    {helpText && useOpenGovStyle && (
                        <CustomHelpBlock useOpenGovStyle>{helpText}</CustomHelpBlock>
                    )}
                    <BootstrapCheckbox
                        {...input}
                        {...props}
                        checked={value}
                        className={classnames(
                            this.styles.checkbox,
                            label && props.inline && this.styles.inlineWithLabel,
                            className
                        )}
                        data-qa={tagWithComponentName}
                        id={id}
                        onBlur={this.handleBlur}
                        onChange={this.handleChange}
                        readOnly={props.disabled}
                    >
                        <svg
                            className={this.styles.optionIconSVGContainer}
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <MultiSelectSVGShape
                                className={classnames({
                                    [this.styles.optionIconSelectedSquare]: value,
                                    [this.styles.optionIconSquare]: !value,
                                    [this.styles.disabledOption]: this.props.disabled,
                                })}
                                isSelected={value}
                            />
                        </svg>
                        {text}
                    </BootstrapCheckbox>
                </FormGroup>
            );
        }

        return (
            <FormGroup className={formGroupClass} validationState={validationState}>
                <Label
                    helpIcon={helpIcon}
                    helpIconClick={helpIconClick}
                    helpIconNode={helpIconNode}
                    htmlFor={id}
                    label={label}
                />
                <BootstrapCheckbox
                    {...input}
                    {...props}
                    checked={input.value}
                    className={classnames(
                        this.styles.checkbox,
                        label && props.inline && this.styles.inlineWithLabel,
                        className
                    )}
                    data-qa={tagWithComponentName}
                    id={id}
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    readOnly={props.disabled}
                >
                    {text}
                </BootstrapCheckbox>
                {helpText && <HelpBlock>{helpText}</HelpBlock>}
            </FormGroup>
        );
    }
}

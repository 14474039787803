import React from 'react';
import { useParams } from 'react-router-dom';

import { Button } from '../../../../components';
import { getRequisitionsPath } from '../../RequisitionsDashboard/RequisitionsDashboard/selectors';

export const AccountDetailsHeader = () => {
    const params = useParams();
    const styles = require('./index.scss');

    const requisitionsPath = getRequisitionsPath({ params });

    return (
        <div className={styles.header}>
            <div>
                <Button
                    bsStyle="link"
                    className={styles.goBackBtn}
                    qaTag="accountDetails-requisitionsDashboardLink"
                    to={requisitionsPath}
                    zeroPadding
                >
                    <i className="fa fa-arrow-left" />
                    Requests Dashboard
                </Button>
            </div>
            <h2 className={styles.title}>Account Details</h2>
        </div>
    );
};

import { createSelector } from 'reselect';

import { getInvitedUsersJS } from '../../../selectors';

const getContractSubscribers = (state) =>
    state.contracts.getIn(['contract', 'contractSubscribers']);

const getSubscribersMap = createSelector([getContractSubscribers], (subscribers) => {
    if (subscribers) {
        return subscribers.toJS().reduce((subscriberObj, subscriber) => {
            subscriberObj[subscriber.user_id] = true;
            return subscriberObj;
        }, {});
    }
    return {};
});

export const getUsersSelectOptions = createSelector(
    [getInvitedUsersJS, getSubscribersMap],
    (users, subscribersMap) => {
        return users
            .filter((user) => !subscribersMap[user.id])
            .map((user) => {
                return {
                    value: user.id,
                    label: user.displayName,
                    user,
                };
            });
    }
);

import { browserHistory } from '@og-pro-migration-tools/react-router';
import { arrayPush, arrayRemove } from 'redux-form';
import { v4 as UUIDv4 } from 'uuid';

import { attachmentTypesDict } from '@og-pro/shared-config/attachments';

import { proposalStatusesDict } from '@og-pro/shared-config/proposals';

import { showSaveError, showSaveModal, showSaveSuccess } from './app';
import { uploadProposal } from './attachments';
import {
    hideConfirmationModal,
    showConfirmationModalError,
    updatingConfirmationModal,
} from './confirmation';
import { showNotification, showSnackbar } from './notification';
import { trackEvent, downloadCSV } from '../helpers';

const { PROPOSAL_DOCUMENT, PROPOSAL_SUPPLEMENTAL_DOCUMENT } = attachmentTypesDict;
const { DRAFT, PUBLISHED } = proposalStatusesDict;

export const LOAD = 'gov/proposals/LOAD';
export const LOAD_SUCCESS = 'gov/proposals/LOAD_SUCCESS';
export const LOAD_FAIL = 'gov/proposals/LOAD_FAIL';

export function govLoadProposal(projectId, proposalId) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD });
        return client
            .get(`/project/${projectId}/proposal/${proposalId}`)
            .then((result) => dispatch({ type: LOAD_SUCCESS, result }))
            .catch((error) => dispatch({ type: LOAD_FAIL, error }));
    };
}

export function shouldLoadProposal(state, proposalId) {
    if (state.govProposals.get('loading')) return false;
    if (!state.govProposals.get('loaded')) return true;
    return state.govProposals.getIn(['proposal', 'id']) !== proposalId;
}

export const LOAD_ALL = 'gov/proposals/LOAD_ALL';
export const LOAD_ALL_SUCCESS = 'gov/proposals/LOAD_ALL_SUCCESS';
export const LOAD_ALL_FAIL = 'gov/proposals/LOAD_ALL_FAIL';

export function loadAllProjectProposals(projectId) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_ALL });
        return client
            .get(`/project/${projectId}/proposal`)
            .then((result) => {
                dispatch({ type: LOAD_ALL_SUCCESS, result });
            })
            .catch((error) => {
                dispatch({ type: LOAD_ALL_FAIL, error });
            });
    };
}

export const LOAD_VENDOR = 'gov/proposals/LOAD_VENDOR';
export const LOAD_VENDOR_SUCCESS = 'gov/proposals/LOAD_VENDOR_SUCCESS';
export const LOAD_VENDOR_FAIL = 'gov/proposals/LOAD_VENDOR_FAIL';

export function govLoadProposalVendor(proposalId) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_VENDOR });
        return client
            .get(`/proposal/${proposalId}/vendor`)
            .then((result) => dispatch({ type: LOAD_VENDOR_SUCCESS, result }))
            .catch((error) => dispatch({ type: LOAD_VENDOR_FAIL, error }));
    };
}

export const DOWNLOAD_ALL = 'gov/proposalEvaluations/DOWNLOAD_ALL';
export const DOWNLOAD_ALL_SUCCESS = 'gov/proposalEvaluations/DOWNLOAD_ALL_SUCCESS';
export const DOWNLOAD_ALL_SUCCESS_QUEUED = 'gov/proposalEvaluations/DOWNLOAD_SUCCESS_QUEUED';
export const DOWNLOAD_ALL_FAIL = 'gov/proposalEvaluations/DOWNLOAD_ALL_FAIL';
export const DOWNLOAD_ALL_RESET = 'gov/proposalEvaluations/DOWNLOAD_ALL_RESET';

export function downloadAllProposalDocuments(proposalId, opts = {}) {
    const { supplementalsOnly } = opts;
    const url = supplementalsOnly
        ? `/proposal/${proposalId}/supplementals/download`
        : `/proposal/${proposalId}/download`;
    return (dispatch, getState, client) => {
        dispatch({ type: DOWNLOAD_ALL });
        return client
            .post(url)
            .then((result) => {
                if (result === null) {
                    // File processing was offloaded to queue, download link will be emailed
                    dispatch({ type: DOWNLOAD_ALL_SUCCESS_QUEUED });
                    return;
                }

                // Download is immediately available, link is provided
                dispatch({ type: DOWNLOAD_ALL_SUCCESS, result: result.downloadUrl });
            })
            .catch((error) => {
                dispatch({ type: DOWNLOAD_ALL_FAIL, error });
            });
    };
}

export function resetProposalDocumentsDownload() {
    return { type: DOWNLOAD_ALL_RESET };
}

export const INITIATE_CREATE = 'gov/proposals/INITIATE_CREATE';
export const RESET_CREATE = 'gov/proposals/RESET_CREATE';

export const CREATE = 'gov/proposals/CREATE';
export const CREATE_SUCCESS = 'gov/proposals/CREATE_SUCCESS';
export const CREATE_FAIL = 'gov/proposals/CREATE_FAIL';

export function resetProposalCreate() {
    return { type: RESET_CREATE };
}

export function createProposal(project, proposalsPath) {
    return (dispatch, getState, client) => {
        dispatch({ type: CREATE });
        dispatch({ type: INITIATE_CREATE });

        return client
            .post(`/project/${project.id}/proposal`)
            .then((proposal) => {
                dispatch(resetProposalCreate());
                dispatch({ type: CREATE_SUCCESS, result: proposal });
                browserHistory.push(`${proposalsPath}/${proposal.id}/edit`);
            })
            .catch((error) => {
                dispatch({ type: CREATE_FAIL, error });
            });
    };
}

export const UPDATE = 'gov/proposals/UPDATE';
export const UPDATE_SUCCESS = 'gov/proposals/UPDATE_SUCCESS';
export const UPDATE_FAIL = 'gov/proposals/UPDATE_FAIL';

export function updateProposal(proposalId, data, opts = {}) {
    return (dispatch, getState, client) => {
        if (opts.modal) {
            dispatch(updatingConfirmationModal());
        } else if (opts.notify) {
            dispatch(showSaveModal());
        }

        dispatch({ type: UPDATE });
        return client
            .put(`/proposal/${proposalId}`, { data })
            .then((result) => {
                dispatch({ type: UPDATE_SUCCESS, result });
                if (opts.modal) {
                    dispatch(hideConfirmationModal());
                } else if (opts.notify) {
                    dispatch(showSaveSuccess());
                }
                if (opts.onUpdate) {
                    opts.onUpdate();
                }
            })
            .catch((error) => {
                if (opts.modal) {
                    dispatch(showConfirmationModalError(error.message));
                } else if (opts.notify) {
                    dispatch(showSaveError(error.message));
                }
                dispatch({ type: UPDATE_FAIL, error });
                return error;
            });
    };
}

export function submitProposal(proposal, formData, nextRoute) {
    return (dispatch) => {
        const submitData = {
            ...formData,
            status: PUBLISHED,
        };

        const onUpdate = () => {
            trackEvent('Response Submitted by Government');
            browserHistory.push(nextRoute);
        };

        return dispatch(updateProposal(proposal.id, submitData, { onUpdate }));
    };
}

export function unsubmitProposal(proposal, nextRoute) {
    return (dispatch) => {
        // Force an edit of the published proposal
        const data = { status: DRAFT, force: true };

        const onUpdate = () => {
            trackEvent('Response Unsubmitted by Government');
            browserHistory.push(nextRoute);
        };

        return dispatch(updateProposal(proposal.id, data, { onUpdate }));
    };
}

export const START_UPLOAD = 'gov/proposals/START_UPLOAD';
export const COMPLETE_UPLOAD = 'gov/proposals/COMPLETE_UPLOAD';
export const CREATE_ATTACHMENT = 'gov/proposals/CREATE_ATTACHMENT';
export const CREATE_ATTACHMENT_SUCCESS = 'gov/proposals/CREATE_ATTACHMENT_SUCCESS';
export const CREATE_ATTACHMENT_FAIL = 'gov/proposals/CREATE_ATTACHMENT_FAIL';

export function createProposalAttachment(proposal, formData, file) {
    return (dispatch, getState, client) => {
        // Temporary ID to use in identifying the upload before it is saved
        const uploadId = UUIDv4();
        const { proposalDocumentId, form, formKey } = formData;
        const { id } = proposal;

        const cb = (fileData) => {
            const data = {
                bucket: fileData.bucket,
                path: fileData.key,
                filename: fileData.filename,
                proposalDocumentId,
                type: PROPOSAL_DOCUMENT,
            };

            dispatch({ type: CREATE_ATTACHMENT, uploadId });
            return client
                .post(`/proposal/${id}/attachment`, { data })
                .then((result) => {
                    // Remove the upload item field from the upload list
                    dispatch({ type: COMPLETE_UPLOAD, uploadId });
                    // Add newly created attachment to proposal
                    dispatch({ type: CREATE_ATTACHMENT_SUCCESS, result });
                    // Update the form with the new value
                    dispatch(arrayPush(form, formKey, result));
                })
                .catch((error) => {
                    dispatch({ type: CREATE_ATTACHMENT_FAIL, error, uploadId });
                });
        };

        const result = {
            id: uploadId,
            fileName: file.name,
            proposal_document_id: proposalDocumentId,
        };

        dispatch({ type: START_UPLOAD, result });

        return dispatch(
            uploadProposal(
                {
                    uploadId,
                    proposalId: id,
                    file,
                },
                cb
            )
        );
    };
}

export function createProposalSupplementalAttachment(proposal, file) {
    return (dispatch, getState, client) => {
        const { id } = proposal;

        const data = {
            bucket: file.bucket,
            path: file.path,
            filename: file.filename,
            type: PROPOSAL_SUPPLEMENTAL_DOCUMENT,
        };

        return client.post(`/proposal/${id}/attachment`, { data }).then((result) => {
            dispatch({ type: CREATE_ATTACHMENT_SUCCESS, result });
        });
    };
}

export const DELETE_ATTACHMENT = 'gov/proposals/DELETE_ATTACHMENT';
export const DELETE_ATTACHMENT_SUCCESS = 'gov/proposals/DELETE_ATTACHMENT_SUCCESS';
export const DELETE_ATTACHMENT_FAIL = 'gov/proposals/DELETE_ATTACHMENT_FAIL';

export function deleteProposalAttachment(proposal, formData, attachmentId) {
    return (dispatch, getState, client) => {
        const { id } = proposal;
        const { form, formKey, index } = formData;

        dispatch({ type: DELETE_ATTACHMENT, attachmentId });
        return client
            .del(`/proposal/${id}/attachment/${attachmentId}`)
            .then(() => {
                dispatch({ type: DELETE_ATTACHMENT_SUCCESS, attachmentId });
                // Update the form by removing the value
                dispatch(arrayRemove(form, formKey, index));
            })
            .catch((error) => {
                dispatch({ type: DELETE_ATTACHMENT_FAIL, error, attachmentId });
            });
    };
}

export function deleteProposalSupplementalAttachment(proposal, attachmentId) {
    return (dispatch, getState, client) => {
        const { id } = proposal;

        dispatch({ type: DELETE_ATTACHMENT, attachmentId, isSupplemental: true });
        return client
            .del(`/proposal/${id}/attachment/${attachmentId}`)
            .then(() => {
                dispatch({ type: DELETE_ATTACHMENT_SUCCESS, attachmentId, isSupplemental: true });
            })
            .catch((error) => {
                dispatch({
                    type: DELETE_ATTACHMENT_FAIL,
                    error,
                    attachmentId,
                    isSupplemental: true,
                });
            });
    };
}

export const DISQUALIFY = 'gov/proposals/DISQUALIFY';
export const DISQUALIFY_SUCCESS = 'gov/proposals/DISQUALIFY_SUCCESS';
export const DISQUALIFY_ERROR = 'gov/proposals/DISQUALIFY_ERROR';

export function disqualifyProposal(proposal, data, opts = {}) {
    return (dispatch, getState, client) => {
        const { id, project_id: projectId } = proposal;
        if (opts.modal) {
            dispatch(updatingConfirmationModal());
        }
        dispatch({ type: DISQUALIFY });
        return client
            .put(`/project/${projectId}/proposal/${id}/disqualify`, { data })
            .then((result) => {
                const message = result.isDisqualified ? 'Disqualified' : 'Disqualification Removed';
                dispatch(showSnackbar(`Response ${message}`));
                dispatch({ type: DISQUALIFY_SUCCESS, result });
                if (opts.modal) {
                    dispatch(hideConfirmationModal());
                }
            })
            .catch((error) => {
                dispatch({ type: DISQUALIFY_ERROR, error });
                if (opts.modal) {
                    dispatch(showConfirmationModalError(error.message));
                }
            });
    };
}

export function excludeProposal(proposal, data) {
    return (dispatch, getState, client) => {
        const { id, project_id: projectId } = proposal;
        return client
            .put(`/project/${projectId}/proposal/${id}/exclude`, { data })
            .then((result) => {
                const message = result.isExcluded ? 'Excluded' : 'Exclusion Removed';
                dispatch(showSnackbar(`Response ${message}`));
                dispatch({ type: UPDATE_SUCCESS, result });
            })
            .catch((error) => {
                dispatch(showSnackbar(`Error: ${error.message}`));
            });
    };
}

export const DELETE = 'gov/proposals/DELETE';
export const DELETE_SUCCESS = 'gov/proposals/DELETE_SUCCESS';
export const DELETE_FAIL = 'gov/proposals/DELETE_FAIL';

export function deleteProposal(proposal, nextRoute) {
    return (dispatch, getState, client) => {
        const { companyName, id: proposalId } = proposal;
        dispatch({ type: DELETE, proposalId });
        return client
            .del(`/proposal/${proposalId}`)
            .then(() => {
                browserHistory.push(nextRoute);
                dispatch({ type: DELETE_SUCCESS, proposalId });
                dispatch(showSnackbar(`${companyName || ''} Proposal deleted`));
            })
            .catch((error) => {
                dispatch({ type: DELETE_FAIL, error, proposalId });
                dispatch(showNotification(error.message, { type: 'danger' }));
            });
    };
}

export const LOAD_PRICING = 'gov/proposals/LOAD_PRICING';
export const LOAD_PRICING_SUCCESS = 'gov/proposals/LOAD_PRICING_SUCCESS';
export const LOAD_PRICING_FAIL = 'gov/proposals/LOAD_PRICING_FAIL';

export function loadProjectProposalsPricing(projectId) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_PRICING });
        return client
            .get(`/project/${projectId}/proposal/pricing`)
            .then((result) => {
                dispatch({ type: LOAD_PRICING_SUCCESS, result });
            })
            .catch((error) => {
                dispatch({ type: LOAD_PRICING_FAIL, error });
            });
    };
}

export const TOGGLE_QUESTIONNAIRE_IS_FAILED = 'vend/proposals/TOGGLE_QUESTIONNAIRE_IS_FAILED';

export function updateQuestionnaireResponse(projectId, questionnaireResponseId, data) {
    return (dispatch, getState, client) => {
        return client
            .put(
                `/project/${projectId}/proposal/questionnaire-response/${questionnaireResponseId}`,
                { data }
            )
            .then(() => {
                dispatch(showSnackbar('Questionnaire response updated'));
                dispatch({
                    type: TOGGLE_QUESTIONNAIRE_IS_FAILED,
                    questionnaireResponseId,
                    isFailed: data.isFailed,
                });
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(`Failed to update questionnaire response: ${error.message}`, {
                        isError: true,
                    })
                );
                throw error;
            });
    };
}

export function downloadVendorActivity(projectId, vendorId) {
    return (dispatch, getState, client) => {
        return client
            .get(`/project/${projectId}/vendors/events/${vendorId}?format=csv`)
            .then((result) => {
                downloadCSV(result, `vendors-activity-${Date.now()}.csv`);
            });
    };
}

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { useDispatch } from 'react-redux';

import { fieldNames, form } from './constants';
import { validate } from './validate';
import { qaTagPageName } from '../../constants';
import { createCommentRequisitionLog } from '../../../../../actions/requisitionsApprovals';
import { OutlineButton, InputText } from '../../../../../components';
import { limitTextLength } from '../../../../../Forms/normalizers';

const { COMMENT } = fieldNames;

const formConfig = {
    form,
    validate,
};

const normalizeComment = limitTextLength(1000);

export const ConnectedRequisitionCommentForm = ({ handleSubmit, requisition, reset }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitError, setSubmitError] = useState(null);

    const styles = require('./index.scss');

    const dispatch = useDispatch();

    const submitHandler = (data) => {
        setIsSubmitting(true);
        setSubmitError(null);
        dispatch(createCommentRequisitionLog(requisition.id, data[COMMENT]))
            .then(() => {
                setIsSubmitting(false);
                reset();
            })
            .catch((error) => {
                setIsSubmitting(false);
                setSubmitError(error.message);
            });
    };

    return (
        <form className={styles.container} onSubmit={handleSubmit(submitHandler)}>
            <Field
                characterLimit={1000}
                component={InputText}
                hasFeedback={false}
                label="New Comment"
                name={COMMENT}
                normalize={normalizeComment}
                qaTag={`${qaTagPageName}-comment`}
                type="textarea"
                useOpenGovStyle
            />
            <div className="text-right">
                <OutlineButton
                    bsSize="sm"
                    bsStyle="primary"
                    disabled={isSubmitting}
                    qaTag={`${qaTagPageName}-addComment`}
                    type="submit"
                >
                    Add Comment
                </OutlineButton>
                {submitError && <div className="error-block">{submitError}</div>}
            </div>
        </form>
    );
};

ConnectedRequisitionCommentForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    requisition: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }).isRequired,
    reset: PropTypes.func.isRequired,
};

export const RequisitionCommentForm = reduxForm(formConfig)(ConnectedRequisitionCommentForm);

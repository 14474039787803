import { resourceManager } from '../helpers';
import { showSnackbar } from './notification';

export const LOAD_GOVERNMENT_DATA = 'adminGovernment/LOAD_GOVERNMENT_DATA';
export const LOAD_GOVERNMENT_DATA_SUCCESS = 'adminGovernment/LOAD_GOVERNMENT_DATA_SUCCESS';
export const LOAD_GOVERNMENT_DATA_FAIL = 'adminGovernment/LOAD_GOVERNMENT_DATA_FAIL';

export function loadGovernmentData(governmentId) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_GOVERNMENT_DATA });

        return client
            .get(`/government/${governmentId}/data`)
            .then((result) => dispatch({ type: LOAD_GOVERNMENT_DATA_SUCCESS, result }))
            .catch((error) => dispatch({ type: LOAD_GOVERNMENT_DATA_FAIL, error }));
    };
}

export const LOAD_APPROVAL_WORKFLOWS = 'adminGovernment/LOAD_APPROVAL_WORKFLOWS';
export const LOAD_APPROVAL_WORKFLOWS_SUCCESS = 'adminGovernment/LOAD_APPROVAL_WORKFLOWS_SUCCESS';
export const LOAD_APPROVAL_WORKFLOWS_FAIL = 'adminGovernment/LOAD_APPROVAL_WORKFLOWS_FAIL';

export function loadApprovalWorkflows(governmentId) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_APPROVAL_WORKFLOWS });

        return client
            .get(`/government/${governmentId}/approval-workflows`)
            .then((result) => dispatch({ type: LOAD_APPROVAL_WORKFLOWS_SUCCESS, result }))
            .catch((error) => dispatch({ type: LOAD_APPROVAL_WORKFLOWS_FAIL, error }));
    };
}

export const CREATE_APPROVAL_WORKFLOW = 'adminGovernment/CREATE_APPROVAL_WORKFLOW';
export const CREATE_APPROVAL_WORKFLOW_SUCCESS = 'adminGovernment/CREATE_APPROVAL_WORKFLOW_SUCCESS';
export const CREATE_APPROVAL_WORKFLOW_FAIL = 'adminGovernment/CREATE_APPROVAL_WORKFLOW_FAIL';

export function createApprovalWorkflow(data) {
    return (dispatch, getState, client) => {
        dispatch({ type: CREATE_APPROVAL_WORKFLOW });

        return client
            .post('/approval/workflows', { data })
            .then((result) => {
                dispatch(showSnackbar('Workflow created'));
                dispatch({ type: CREATE_APPROVAL_WORKFLOW_SUCCESS, result });
            })
            .catch((error) => {
                dispatch({ type: CREATE_APPROVAL_WORKFLOW_FAIL, error });
                return error;
            });
    };
}

export const UPDATE_APPROVAL_WORKFLOW = 'adminGovernment/UPDATE_APPROVAL_WORKFLOW';
export const UPDATE_APPROVAL_WORKFLOW_SUCCESS = 'adminGovernment/UPDATE_APPROVAL_WORKFLOW_SUCCESS';
export const UPDATE_APPROVAL_WORKFLOW_FAIL = 'adminGovernment/UPDATE_APPROVAL_WORKFLOW_FAIL';

export function updateApprovalWorkflow(approvalWorkflowId, data) {
    return (dispatch, getState, client) => {
        dispatch({ type: UPDATE_APPROVAL_WORKFLOW, approvalWorkflowId });

        return client
            .put(`/approval/workflows/${approvalWorkflowId}`, { data })
            .then((result) => {
                dispatch(showSnackbar('Workflow updated'));
                dispatch({ type: UPDATE_APPROVAL_WORKFLOW_SUCCESS, result, approvalWorkflowId });
            })
            .catch((error) => {
                dispatch({ type: UPDATE_APPROVAL_WORKFLOW_FAIL, error, approvalWorkflowId });
                return error;
            });
    };
}

export const DELETE_APPROVAL_WORKFLOW = 'adminGovernment/DELETE_APPROVAL_WORKFLOW';
export const DELETE_APPROVAL_WORKFLOW_SUCCESS = 'adminGovernment/DELETE_APPROVAL_WORKFLOW_SUCCESS';
export const DELETE_APPROVAL_WORKFLOW_FAIL = 'adminGovernment/DELETE_APPROVAL_WORKFLOW_FAIL';

export function deleteApprovalWorkflow(approvalWorkflowId) {
    return (dispatch, getState, client) => {
        dispatch({ type: DELETE_APPROVAL_WORKFLOW, approvalWorkflowId });

        return client
            .del(`/approval/workflows/${approvalWorkflowId}`)
            .then((result) => {
                dispatch(showSnackbar('Workflow deleted'));
                dispatch({ type: DELETE_APPROVAL_WORKFLOW_SUCCESS, result, approvalWorkflowId });
            })
            .catch((error) => {
                dispatch({ type: DELETE_APPROVAL_WORKFLOW_FAIL, error, approvalWorkflowId });
            });
    };
}

export const RESET_APPROVAL_WORKFLOW = 'adminGovernment/RESET_APPROVAL_WORKFLOW';

export function resetApprovalWorkflow() {
    return { type: RESET_APPROVAL_WORKFLOW };
}

export const LOAD_STANDARD_DOCUMENTS = 'adminGovernment/LOAD_STANDARD_DOCUMENTS';
export const LOAD_STANDARD_DOCUMENTS_SUCCESS = 'adminGovernment/LOAD_STANDARD_DOCUMENTS_SUCCESS';
export const LOAD_STANDARD_DOCUMENTS_FAIL = 'adminGovernment/LOAD_STANDARD_DOCUMENTS_FAIL';

export function loadStandardDocuments(governmentId) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_STANDARD_DOCUMENTS });

        return client
            .get(`/government/${governmentId}/standard-documents`)
            .then((result) => dispatch({ type: LOAD_STANDARD_DOCUMENTS_SUCCESS, result }))
            .catch((error) => dispatch({ type: LOAD_STANDARD_DOCUMENTS_FAIL, error }));
    };
}

export function getStandardDocument(standardDocumentId) {
    return (dispatch, getState, client) => client.get(`/approval/workflows/${standardDocumentId}`);
}

export const CREATE_STANDARD_DOCUMENT_SUCCESS = 'adminGovernment/CREATE_STANDARD_DOCUMENT_SUCCESS';

export function createStandardDocument(data) {
    return (dispatch, getState, client) => {
        return client
            .post('/standard-documents', { data })
            .then((result) => dispatch({ type: CREATE_STANDARD_DOCUMENT_SUCCESS, result }));
    };
}

export const UPDATE_STANDARD_DOCUMENT_SUCCESS = 'adminGovernment/UPDATE_STANDARD_DOCUMENT_SUCCESS';

export function updateStandardDocument(standardDocumentId, data) {
    return (dispatch, getState, client) => {
        return client.put(`/standard-documents/${standardDocumentId}`, { data }).then((result) => {
            dispatch(showSnackbar('Document updated'));
            dispatch({ type: UPDATE_STANDARD_DOCUMENT_SUCCESS, result });
        });
    };
}

export const DELETE_STANDARD_DOCUMENT_SUCCESS = 'adminGovernment/DELETE_STANDARD_DOCUMENT_SUCCESS';

export function deleteStandardDocument(standardDocumentId) {
    return (dispatch, getState, client) => {
        return client.del(`/standard-documents/${standardDocumentId}`).then((result) => {
            dispatch(showSnackbar('Document deleted'));
            dispatch({ type: DELETE_STANDARD_DOCUMENT_SUCCESS, result });
        });
    };
}

export const RESET_STANDARD_DOCUMENTS = 'adminGovernment/RESET_STANDARD_DOCUMENTS';

export function resetStandardDocuments() {
    return { type: RESET_STANDARD_DOCUMENTS };
}

export const CREATE_USER_ROLE_SUCCESS = 'adminGovernment/CREATE_USER_ROLE_SUCCESS';
export const UPDATE_USER_ROLE_SUCCESS = 'adminGovernment/UPDATE_USER_ROLE_SUCCESS';
export const DELETE_USER_ROLE_SUCCESS = 'adminGovernment/DELETE_USER_ROLE_SUCCESS';

export function createUserRole(data) {
    return (dispatch, getState, client) => {
        return client.post('/user-roles', { data }).then((result) => {
            dispatch(showSnackbar('Role Added'));
            dispatch({ type: CREATE_USER_ROLE_SUCCESS, result });
        });
    };
}

export function updateUserRole(userRole, data) {
    return (dispatch, getState, client) => {
        return client.put(`/user-roles/${userRole.id}`, { data }).then((result) => {
            dispatch(showSnackbar('Role Updated'));
            dispatch({ type: UPDATE_USER_ROLE_SUCCESS, result });
        });
    };
}

export function deleteUserRole(userRole) {
    return (dispatch, getState, client) => {
        return client.del(`/user-roles/${userRole.id}`).then((result) => {
            dispatch(showSnackbar('Role Deleted'));
            dispatch({ type: DELETE_USER_ROLE_SUCCESS, result });
        });
    };
}

export const PATCH_GOVERNMENT_SETTINGS = 'adminGovernment/PATCH_GOVERNMENT_SETTINGS';
export const PATCH_GOVERNMENT_SETTINGS_SUCCESS =
    'adminGovernment/PATCH_GOVERNMENT_SETTINGS_SUCCESS';
export const PATCH_GOVERNMENT_SETTINGS_FAILURE =
    'adminGovernment/PATCH_GOVERNMENT_SETTINGS_FAILURE';
export const PATCH_STATE_USER_GOVERNMENT_SETTINGS =
    'adminGovernment/PATCH_STATE_USER_GOVERNMENT_SETTINGS';

export function patchGovernmentSettings(id, data) {
    return resourceManager({
        method: 'patch',
        url: `/government/${id}/settings`,
        requestOptions: { data },
        onStart: ({ dispatch }) => dispatch({ type: PATCH_GOVERNMENT_SETTINGS }),
        onSuccess: ({ dispatch, result }) => {
            dispatch({ type: PATCH_GOVERNMENT_SETTINGS_SUCCESS });
            dispatch(showSnackbar('Settings updated', { isSuccess: true }));
            dispatch({ type: PATCH_STATE_USER_GOVERNMENT_SETTINGS, payload: { ...result } });
        },
        onFailure: ({ dispatch }) => {
            dispatch({ type: PATCH_GOVERNMENT_SETTINGS_FAILURE });
            dispatch(showSnackbar('There was an error updating the settings', { isError: true }));
        },
        context: { data },
    });
}

export function getAiAudits(govId, onSuccess, onFailure) {
    return resourceManager({
        method: 'get',
        url: `/government/${govId}/generative-ai/audits`,
        onSuccess: ({ result }) => onSuccess(result),
        onFailure: ({ error }) => onFailure(error),
    });
}

import { get } from 'lodash';
import { isEmail } from 'validator';

import { mappedProcurementContactFields } from '@og-pro/shared-config/contacts';

import { durationTypesDict } from '@og-pro/shared-config/contracts';

import { contractPartyFieldNames, fieldNames } from './constants';
import { contact, department } from '../../../Forms/validation';

const {
    CONTRACT_ID,
    CONTRACT_MILESTONES,
    CONTRACT_PARTY,
    CONTRACT_RENEWALS,
    END_DATE,
    DURATION_TYPE,
    INITIAL_TERM_LENGTH,
    INITIAL_TERM_LENGTH_UNIT,
    PROCUREMENT_CONTACT_ID,
    START_DATE,
    TITLE,
} = fieldNames;

const { COMPANY_EMAIL, COMPANY_NAME, VENDOR_ORGANIZATION_ID } = contractPartyFieldNames;

const { TERM_BASED } = durationTypesDict;

export function validate(values) {
    const errors = {
        [CONTRACT_MILESTONES]: values[CONTRACT_MILESTONES]
            ? values[CONTRACT_MILESTONES].map(() => ({}))
            : [],
        [CONTRACT_PARTY]: {},
        [CONTRACT_RENEWALS]: values[CONTRACT_RENEWALS]
            ? values[CONTRACT_RENEWALS].map(() => ({}))
            : [],
        ...contact(values),
        ...contact(values, {
            fieldNames: mappedProcurementContactFields,
            idFieldName: PROCUREMENT_CONTACT_ID,
        }),
        ...department(values),
    };

    if (!values[TITLE]) {
        errors[TITLE] = 'A title is required';
    } else if (values[TITLE].length > 255) {
        errors[TITLE] = 'Title is too long. Maximum length is 256 characters';
    }

    if (values[CONTRACT_ID] && values[CONTRACT_ID].length > 128) {
        errors[CONTRACT_ID] = 'Contract ID is too long. Maximum length is 128 characters';
    }

    const contractPartyVendorId = get(values, `${CONTRACT_PARTY}.${VENDOR_ORGANIZATION_ID}`);

    if (!contractPartyVendorId) {
        const contractPartyCompanyName = get(values, `${CONTRACT_PARTY}.${COMPANY_NAME}`);
        const contractPartyCompanyEmail = get(values, `${CONTRACT_PARTY}.${COMPANY_EMAIL}`);

        if (contractPartyCompanyName && contractPartyCompanyName.length > 128) {
            errors[CONTRACT_PARTY][COMPANY_NAME] =
                'Company name is too long. Maximum length is 128 characters';
        }

        if (contractPartyCompanyEmail && !isEmail(contractPartyCompanyEmail)) {
            errors[CONTRACT_PARTY][COMPANY_EMAIL] = 'Company email must be a valid email';
        } else if (contractPartyCompanyEmail && contractPartyCompanyEmail.length > 128) {
            errors[CONTRACT_PARTY][COMPANY_EMAIL] =
                'Company email is too long. Maximum length is 128 characters';
        }
    }

    if (!values[START_DATE]) {
        errors[START_DATE] = 'Contract start date is required';
    }
    if (!values[END_DATE] && values[DURATION_TYPE] && values[DURATION_TYPE] === TERM_BASED) {
        errors[END_DATE] = 'Contract end date is required';
    }
    if (values[START_DATE] && values[END_DATE] && values[START_DATE] > values[END_DATE]) {
        errors[END_DATE] = 'Contract end date must be after contract start date';
        errors[START_DATE] = 'Contract start date must be before contract end date';
    }

    if (values[CONTRACT_MILESTONES]) {
        values[CONTRACT_MILESTONES].forEach((milestone, index) => {
            if (!milestone.name) {
                errors[CONTRACT_MILESTONES][index].name = 'Milestone name cannot be empty';
            } else if (milestone.name.length > 255) {
                errors[CONTRACT_MILESTONES][index].name =
                    'Name is too long. Maximum length is 256 characters';
            }
            if (!milestone.date) {
                errors[CONTRACT_MILESTONES][index].date = 'Milestone date cannot be empty';
            }
        });
    }

    if (values[CONTRACT_RENEWALS]) {
        values[CONTRACT_RENEWALS].forEach((renewal, index) => {
            if (!renewal.length) {
                errors[CONTRACT_RENEWALS][index].length = 'Renewal length cannot be empty';
            }
            if (!renewal.lengthUnit) {
                errors[CONTRACT_RENEWALS][index].lengthUnit = 'Renewal unit cannot be empty';
            }
        });
    }

    if (!values[DURATION_TYPE]) {
        errors[DURATION_TYPE] = 'Contract end trigger type cannot be empty';
    } else if (values[DURATION_TYPE] === TERM_BASED) {
        if (!values[INITIAL_TERM_LENGTH]) {
            errors[INITIAL_TERM_LENGTH] = 'Initial term length cannot be empty';
        }

        if (!values[INITIAL_TERM_LENGTH_UNIT]) {
            errors[INITIAL_TERM_LENGTH_UNIT] = 'Initial term unit cannot be empty';
        }
    }

    return errors;
}

import React from 'react';

import * as Gov from '../../containers/GovApp';
import { legacyFetchData, requireTemplateAdminPermissions } from '../loaders';

export default (getState, dispatch) => {
    return [
        {
            element: <Gov.ChecklistsAdminNav />,
            loader: requireTemplateAdminPermissions(getState),
            path: 'checklists-admin',
            children: [
                {
                    element: <Gov.ChecklistsAdminList />,
                    loader: legacyFetchData(getState, dispatch, Gov.ChecklistsAdminList),
                    index: true,
                },
                {
                    element: <Gov.ChecklistTemplateEdit />,
                    loader: legacyFetchData(getState, dispatch, Gov.ChecklistTemplateEdit),
                    path: ':templateChecklistId',
                },
            ],
        },
    ];
};

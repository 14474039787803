import { fromJS } from 'immutable';

import * as vendorProfileActions from '../actions/vendorProfile';

const initialState = fromJS({
    loadingVendor: false,
    showVendorModal: false,
});

export default function vendorProfileReducer(state = initialState, action = {}) {
    switch (action.type) {
        case vendorProfileActions.SHOW_MODAL:
            return initialState.merge(
                fromJS({
                    showVendorModal: true,
                    vendorId: action.vendorId,
                })
            );
        case vendorProfileActions.HIDE_MODAL:
            return state.set('showVendorModal', false);
        case vendorProfileActions.LOAD:
            return state.merge(
                fromJS({
                    loadingVendor: true,
                    loadVendorError: null,
                })
            );
        case vendorProfileActions.LOAD_SUCCESS:
            return state.merge(
                fromJS({
                    loadingVendor: false,
                    vendor: fromJS(action.result),
                })
            );
        case vendorProfileActions.LOAD_FAIL:
            return state.merge(
                fromJS({
                    loadingVendor: false,
                    loadVendorError: action.error && action.error.message,
                })
            );
        default:
            return state;
    }
}

import { useEffect } from 'react';

/**
 * Sets the ARIA label attribute for an element.
 *
 * @param {string} ariaLabel - The ARIA label to set.
 * @param {string} querySelector - The CSS selector to find the element.
 * @param {Element} closestRef - The closest ancestor element to start searching from (optional).
 */
export const setAriaLabel = (ariaLabel, querySelector, closestRef) => {
    if (ariaLabel && querySelector && document) {
        let element;
        if (closestRef) {
            element = closestRef.querySelector(querySelector);
        } else {
            element = document.querySelector(querySelector);
        }
        if (element) {
            element.setAttribute('aria-label', ariaLabel);
        }
    }
};

/**
 * Custom React hook to set an aria label to any element inside the DOM.
 *
 * @param {string} ariaLabel - The aria label prop.
 * @param {string} querySelector - The param to the function querySelector .
 */
export const useSetAriaLabel = (ariaLabel, querySelector) => {
    useEffect(() => {
        setAriaLabel(ariaLabel, querySelector);
    }, [ariaLabel, querySelector]);
};

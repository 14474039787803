import { listToDict } from '@og-pro/shared-config/helpers';

export const form = 'checklistTemplateForm';

export const fields = ['template', 'questionnaires'];

export const fieldNames = listToDict(fields);

export const formSections = ['template', 'questions'];

export const formSectionNames = listToDict(formSections);

// Fake field used for verifying if the specified section is valid
export const FORM_SECTIONS_KEY = 'sections';

// Fake field used for verifying if entire form is valid
export const ALL_SECTIONS = 'allSections';

export const incompleteSectionInstructions = {
    [formSectionNames.TEMPLATE]: 'Finish naming your template',
    [formSectionNames.QUESTIONS]: 'Your checklist questions are invalid',
};

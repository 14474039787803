import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';

import { form } from '../../../../../forms/constants';
import { fieldNames, templateFieldNames } from '../../../../../forms/TemplateForm/constants';
import { SearchSelect, Toggle, RadioGroup } from '../../../../../../../../components';
import { getDocxTemplateSelectOptions } from '../../../../../TemplateEdit/selectors';
import { TemplateEditV2FunctionsContext } from '../../../../context';

const { TEMPLATE } = fieldNames;

const { DOCX_ATTACHMENT_ID, OMIT_DOCX } = templateFieldNames;

const selector = formValueSelector(form);

export function TemplateEditV2DocumentExportSettingsForm() {
    const { change, disabled, showFormValidation } = useContext(TemplateEditV2FunctionsContext);
    const [showDocxSelection, setShowDocxSelection] = useState(false);
    const [radioValue, setRadioValue] = useState(false);
    const docxTemplateSelectOptions = useSelector(getDocxTemplateSelectOptions);
    const docxAttachmentId = useSelector((state) =>
        selector(state, `${TEMPLATE}.${DOCX_ATTACHMENT_ID}`)
    );
    const isSolicitation = useSelector(
        (state) => !selector(state, 'isDocBuilder') && !selector(state, `${TEMPLATE}.isSpecial`)
    );

    useEffect(() => {
        setShowDocxSelection(!!docxAttachmentId);
        setRadioValue(!docxAttachmentId);
    }, [docxAttachmentId]);

    const styles = require('../index.scss');

    const onDefaultTemplateToggleChange = (value) => {
        setRadioValue(value);

        if (value) {
            change(`${TEMPLATE}.${DOCX_ATTACHMENT_ID}`, null);
            setShowDocxSelection(false);
        } else {
            setShowDocxSelection(true);
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.section}>
                <h3>Template Settings</h3>
                <div className={styles.row}>
                    <RadioGroup
                        defaultChecked
                        disabled={disabled}
                        groupLabel=""
                        help="This template will be used whenever previewing or exporting a document."
                        input={{
                            value: radioValue,
                            onChange: onDefaultTemplateToggleChange,
                            onBlur: () => {},
                        }}
                        label="Which Word template should be used?"
                        meta={{}}
                        name={`${TEMPLATE}.templateDummyInput`}
                        options={[
                            {
                                name: 'Use the default Word template',
                                value: true,
                                qaTag: ' templateForm-templateDummyInput-default',
                            },
                            {
                                name: 'Choose another Word template',
                                value: false,
                                qaTag: 'templateForm-templateDummyInput-choose',
                            },
                        ]}
                        qaTag="templateForm-templateDummyInput"
                        useOpenGovStyle
                    />
                </div>
                {showDocxSelection && (
                    <div className={styles.row}>
                        <Field
                            component={SearchSelect}
                            disabled={disabled}
                            label="Choose a Word document to use when exporting"
                            name={`${TEMPLATE}.${DOCX_ATTACHMENT_ID}`}
                            options={docxTemplateSelectOptions}
                            qaTag="templateForm-selectWordDocument"
                            showValidation={showFormValidation}
                            useOpenGovStyle
                        />
                    </div>
                )}
            </div>
            {isSolicitation && (
                <div className={styles.section}>
                    <h3>Document Generation</h3>
                    <div className={styles.row}>
                        <Field
                            component={Toggle}
                            disabled={disabled}
                            help="When on, the project document will automatically be generated when a project is posted. When off, the user will be responsible for uploading the project documents."
                            inverseToggleDisplay
                            label="Automatically Generate a Project Document"
                            labelInline
                            name={`${TEMPLATE}.${OMIT_DOCX}`}
                            qaTag="templateForm-generateWordDocument"
                            useOpenGovStyle
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

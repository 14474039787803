import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { Button, ButtonGroup, OutlineButton, ProgressBar } from '../../../../../../components';

class ConnectedProposalEvaluationsListItem extends PureComponent {
    static propTypes = {
        canEvaluate: PropTypes.bool.isRequired,
        companyName: PropTypes.string,
        criteriaToScoreCount: PropTypes.number.isRequired,
        criteriaWithScoreCount: PropTypes.number.isRequired,
        href: PropTypes.object.isRequired,
        isEvaluationSubmitted: PropTypes.bool.isRequired,
        isExcluded: PropTypes.bool.isRequired,
        isScorecardComplete: PropTypes.bool.isRequired,
        percentComplete: PropTypes.number.isRequired,
        proposal: PropTypes.object.isRequired,
        router: PropTypes.object.isRequired,
        showEvaluationNotes: PropTypes.func.isRequired,
        showEvaluationScorecard: PropTypes.func.isRequired,
        submitProposalEvaluation: PropTypes.func.isRequired,
        unsubmitProposalEvaluation: PropTypes.func.isRequired,
        updateError: PropTypes.string,
        updating: PropTypes.bool,
    };

    get styles() {
        return require('../index.scss');
    }

    get isSubmittable() {
        const { canEvaluate, isEvaluationSubmitted, isScorecardComplete } = this.props;

        return isScorecardComplete && !isEvaluationSubmitted && canEvaluate;
    }

    get progressStyle() {
        const { isEvaluationSubmitted, percentComplete } = this.props;

        if (percentComplete < 33.33) return 'warning';
        if (percentComplete < 66.67) return 'info';
        if (percentComplete < 100) return undefined;
        return isEvaluationSubmitted ? 'success-dark' : undefined;
    }

    get statusStyle() {
        const { isEvaluationSubmitted, isExcluded, percentComplete } = this.props;

        if (isExcluded) return ['text-muted', 'fa-minus-circle', 'Excluded'];
        if (isEvaluationSubmitted) return ['text-success', 'fa-check', 'Submitted'];
        if (percentComplete === 0) return ['text-danger', 'fa-clock-o', 'Not Started'];
        return ['text-info', 'fa-pencil', 'In Progress'];
    }

    handleClick = () => {
        const { href, router } = this.props;

        router.push(href);
    };

    submitProposalEvaluation = (e) => {
        const { proposal, submitProposalEvaluation } = this.props;

        e.stopPropagation();
        submitProposalEvaluation(proposal);
    };

    unsubmitProposalEvaluation = (e) => {
        const { proposal, unsubmitProposalEvaluation } = this.props;

        e.stopPropagation();
        unsubmitProposalEvaluation(proposal);
    };

    renderProgressColumn() {
        const {
            criteriaToScoreCount,
            criteriaWithScoreCount,
            isEvaluationSubmitted,
            isExcluded,
            percentComplete,
        } = this.props;

        const progressLabel = percentComplete !== 0 ? `${percentComplete}%` : null;

        if (isExcluded) {
            return null;
        }

        return (
            <>
                <ProgressBar
                    bsStyle={this.progressStyle}
                    className={this.styles.progressBar}
                    label={
                        isEvaluationSubmitted ? (
                            <span>
                                <i className="fa fa-check-circle" /> Complete!
                            </span>
                        ) : (
                            progressLabel
                        )
                    }
                    max={criteriaToScoreCount}
                    min={0}
                    now={criteriaWithScoreCount}
                />
                {this.isSubmittable && (
                    <div className={this.styles.submitWarning}>
                        <small>
                            <i className="fa fa-exclamation-triangle text-danger" />
                            &nbsp;Submit scorecard for scores to be counted
                        </small>
                    </div>
                )}
            </>
        );
    }

    renderActionColumn() {
        const {
            canEvaluate,
            isEvaluationSubmitted,
            isExcluded,
            showEvaluationNotes,
            showEvaluationScorecard,
            updating,
            updateError,
        } = this.props;

        const isSubmittable = this.isSubmittable;
        const isUnsubmittable = isEvaluationSubmitted && canEvaluate;

        if (isExcluded) {
            return null;
        }

        return (
            <>
                <ButtonGroup bsSize="sm">
                    <OutlineButton
                        bsStyle="info"
                        onClick={(e) => {
                            e.stopPropagation();
                            showEvaluationScorecard();
                        }}
                    >
                        <i className="fa fa-pencil" /> Scorecard
                    </OutlineButton>
                    <OutlineButton
                        bsStyle="info"
                        onClick={(e) => {
                            e.stopPropagation();
                            showEvaluationNotes();
                        }}
                    >
                        <i className="fa fa-file-text" /> Notes
                    </OutlineButton>
                </ButtonGroup>
                &nbsp;&nbsp;
                {!isEvaluationSubmitted && (
                    <Button
                        bsSize="sm"
                        bsStyle={isSubmittable ? 'success' : undefined}
                        className={this.styles.submitButton}
                        disabled={!isSubmittable || updating}
                        onClick={this.submitProposalEvaluation}
                        tooltip={
                            isSubmittable
                                ? undefined
                                : 'Complete scoring response before submitting your scorecard'
                        }
                    >
                        <i className="fa fa-check" /> Submit Scores
                    </Button>
                )}
                {isUnsubmittable && (
                    <Button
                        bsSize="sm"
                        className={this.styles.submitButton}
                        disabled={updating}
                        onClick={this.unsubmitProposalEvaluation}
                    >
                        <i className="fa fa-undo" /> Unsubmit
                    </Button>
                )}
                {updateError && <div className="error-block">{updateError}</div>}
            </>
        );
    }

    render() {
        const { companyName, isExcluded } = this.props;

        const [statusClass, statusIcon, statusText] = this.statusStyle;

        return (
            <li
                className={classnames('list-group-item', !isExcluded && this.styles.listItem)}
                onClick={isExcluded ? undefined : this.handleClick}
            >
                <div className="row">
                    <div className={classnames('col-md-3')}>
                        <strong>{companyName || 'Unnamed Company'}</strong>
                    </div>
                    <div className={classnames('col-md-2', statusClass)}>
                        <i className={`fa fa-fw ${statusIcon}`} /> {statusText}
                    </div>
                    <div className={classnames('col-md-2 col-lg-3', this.styles.skinnyCol)}>
                        {this.renderProgressColumn()}
                    </div>
                    <div className={classnames('col-md-5 col-lg-4', this.styles.buttonCol)}>
                        {this.renderActionColumn()}
                    </div>
                </div>
            </li>
        );
    }
}

export const ProposalEvaluationsListItem = withRouter(ConnectedProposalEvaluationsListItem);

import React, { useRef } from 'react';
import PropTypes from 'prop-types';

export const CellWrapper = ({ children }) => {
    const styles = require('./index.scss');
    const ref = useRef();

    return (
        <div className={styles.cellWrapper} ref={ref}>
            {children}
        </div>
    );
};

CellWrapper.propTypes = {
    api: PropTypes.shape({
        onRowHeightChanged: PropTypes.func.isRequired,
    }).isRequired,
    children: PropTypes.node.isRequired,
    node: PropTypes.shape({
        rowHeight: PropTypes.number.isRequired,
        setRowHeight: PropTypes.func.isRequired,
    }).isRequired,
};

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { userRolePermissions } from '@og-pro/shared-config/userRoles';

export const PermissionsConditionalRender = ({
    children,
    permissionTypes,
    fallbackComponent,
    operator = 'OR',
}) => {
    const permissions = useSelector((state) =>
        state.auth.getIn(['user', 'userPermissions'])?.toJS()
    );

    if (operator === 'OR') {
        if (!permissionTypes.some((permissionType) => permissions[permissionType])) {
            return fallbackComponent;
        }
    } else if (operator === 'AND') {
        if (!permissionTypes.every((permissionType) => permissions[permissionType])) {
            return fallbackComponent;
        }
    }

    return children;
};

PermissionsConditionalRender.propTypes = {
    children: PropTypes.node.isRequired,
    fallbackComponent: PropTypes.node.isRequired,
    operator: PropTypes.oneOf(['AND', 'OR']),
    permissionTypes: PropTypes.arrayOf(PropTypes.oneOf(Object.values(userRolePermissions)))
        .isRequired,
};

import PropTypes from 'prop-types';
import React from 'react';

import { ProjectSectionAddButton } from './ProjectSectionAddButton';
import { TemplateSectionAddButton } from './TemplateSectionAddButton';

export const ProjectSectionAddButtons = ({
    addProjectSection,
    addTemplateSection,
    disabled,
    isDirty,
    isDocBuilder,
    isIntake,
    projectSectionFormValues,
    showSharedSections,
    updateTemplate,
    useSectionDividers,
}) => {
    const styles = require('./index.scss');
    return (
        <>
            <ProjectSectionAddButton
                addSection={addProjectSection}
                disabled={disabled}
                isDocBuilder={isDocBuilder}
                isIntake={isIntake}
                projectSectionFormValues={projectSectionFormValues}
                useSectionDividers={useSectionDividers}
            />
            {showSharedSections && (
                <div className={styles.templateSectionAddButtonContainer}>
                    <TemplateSectionAddButton
                        addSection={addTemplateSection}
                        disabled={disabled}
                        isDirty={isDirty}
                        isDocBuilder={isDocBuilder}
                        isIntake={isIntake}
                        projectSectionFormValues={projectSectionFormValues}
                        updateTemplate={updateTemplate}
                    />
                </div>
            )}
        </>
    );
};

ProjectSectionAddButtons.propTypes = {
    addProjectSection: PropTypes.func.isRequired,
    addTemplateSection: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    isDirty: PropTypes.bool,
    isDocBuilder: PropTypes.bool.isRequired,
    isIntake: PropTypes.bool.isRequired,
    projectSectionFormValues: PropTypes.array.isRequired,
    showSharedSections: PropTypes.bool,
    updateTemplate: PropTypes.func.isRequired,
    useSectionDividers: PropTypes.bool.isRequired,
};

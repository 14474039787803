import { customFieldFields } from '@og-pro/shared-config/customFormService/customField';

import { REQUIRED_TEXT } from '../../../../../Forms/validation';

const { ENUM_OPTIONS } = customFieldFields;

const validateCheckboxOptions = () => {};
const validateDateOptions = () => {};
const validateDropdownOptions = (values) => {
    const errors = {};

    if (!values[ENUM_OPTIONS]) {
        errors[ENUM_OPTIONS] = 'Please provide options for this field';
        return errors; // Return early since the field array is missing
    }

    if (!values[ENUM_OPTIONS].length) {
        errors[ENUM_OPTIONS] = 'Please provide at least one option for this field';
        return errors; // Return early since no options are provided
    }

    const optionsErrors = [];
    values[ENUM_OPTIONS].forEach((option, index) => {
        if (!option || option.trim() === '') {
            optionsErrors[index] = 'This field is required or must be deleted.';

            if (index === 0) {
                optionsErrors[index] = REQUIRED_TEXT; // First option is required
            }
        }
    });

    if (optionsErrors.length) {
        errors[ENUM_OPTIONS] = optionsErrors;
    }

    return errors;
};
const validateLongTextOptions = () => {};
const validateMultipleChoiceOptions = () => {};
const validateSingleChoiceOptions = () => {};
const validateNumberOptions = (values) => {
    const errors = {};

    if (!values.typeOfNumber) {
        errors.typeOfNumber = 'Please specify a type of number';
    }

    return errors;
};
const validateShortTextOptions = () => {};
const validateYesNoOptions = () => {};

export {
    validateCheckboxOptions,
    validateDateOptions,
    validateDropdownOptions,
    validateLongTextOptions,
    validateMultipleChoiceOptions,
    validateSingleChoiceOptions,
    validateNumberOptions,
    validateShortTextOptions,
    validateYesNoOptions,
};

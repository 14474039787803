import { fieldNames } from '../constants';

const { APPROVAL_WORKFLOW_STEPS, DEPARTMENT_IDS, USER_IDS } = fieldNames;

export const validate = (values) => {
    const errors = {};

    if (!values[DEPARTMENT_IDS] || values[DEPARTMENT_IDS].length === 0) {
        errors[DEPARTMENT_IDS] = 'Please include at least one department';
    }

    errors[APPROVAL_WORKFLOW_STEPS] = (values[APPROVAL_WORKFLOW_STEPS] || []).map(
        (workflowStep) => {
            const approvalWorkflowStepErrors = {};

            if ((workflowStep[USER_IDS] || []).length === 0) {
                approvalWorkflowStepErrors[USER_IDS] =
                    'At least one user must be assigned to the approval group';
            }

            return approvalWorkflowStepErrors;
        }
    );

    return errors;
};

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';

import {
    Button,
    LoadingError,
    LoadingSpinner,
    ProjectStatusLabel,
    ZeroState,
} from '../../../../../components';

export const TemplateSectionBroadcastModalBody = ({
    disabled,
    hideModal,
    loadError,
    loading,
    projects,
    submitFailed,
    updateTemplateSection,
}) => {
    const [selectedProjectIdsMap, setSelectedProjectIdsMap] = useState({});

    const toggleSelected = (project) => () => {
        setSelectedProjectIdsMap((prevState) => {
            return {
                ...prevState,
                [project.id]: !prevState[project.id],
            };
        });
    };

    const unselectAll = () => setSelectedProjectIdsMap({});
    const selectAll = () => {
        const allProjectIds = projects.reduce((obj, project) => {
            return { ...obj, [project.id]: true };
        }, {});
        setSelectedProjectIdsMap(allProjectIds);
    };

    const selectedProjectIds = Object.keys(selectedProjectIdsMap)
        .filter((id) => selectedProjectIdsMap[id])
        .map((id) => Number.parseInt(id, 10));

    const selectedProjectCount = selectedProjectIds.length;
    const noSelectedProjects = selectedProjectCount === 0;

    const styles = require('./index.scss');

    if (loading) {
        return <LoadingSpinner />;
    }

    if (loadError) {
        return <LoadingError error={loadError} />;
    }

    if (projects.length === 0) {
        return <ZeroState title="No draft projects are using this shared section" />;
    }

    return (
        <>
            <h4 className={styles.header}>
                Select the projects to broadcast the shared section updates
            </h4>
            <p className="text-center text-muted">
                <span className="text-warning">
                    <i className="fa fa-exclamation-triangle" /> Warning!
                </span>
                <br />
                Selected projects will have their entire sections deleted and replaced with the
                current contents of this shared section.
            </p>
            <div>
                <Button
                    bsSize="sm"
                    bsStyle="link"
                    className={styles.selectAllButton}
                    onClick={noSelectedProjects ? selectAll : unselectAll}
                >
                    <i
                        className={`fa fa-lg fa-${
                            noSelectedProjects ? 'square-o' : 'minus-square-o'
                        }`}
                    />
                    {noSelectedProjects ? 'Select' : 'Unselect'} All
                </Button>
            </div>
            <ListGroup>
                {projects.map((project) => {
                    const {
                        financialId,
                        template: { title: templateTitle },
                        status,
                        title,
                    } = project;
                    const isSelected = selectedProjectIdsMap[project.id];
                    const icon = isSelected ? 'fa-check-square text-success' : 'fa-square-o';
                    return (
                        <ListGroupItem
                            bsStyle={isSelected ? 'success' : undefined}
                            key={project.id}
                            onClick={toggleSelected(project)}
                        >
                            <div className={styles.checkboxCol}>
                                <i className={`fa fa-lg fa-fw ${icon}`} />
                            </div>
                            <div className={styles.projectInfoCol}>
                                {title || 'Untitled'}
                                <br />
                                <ProjectStatusLabel hideIcon status={status} />{' '}
                                <small>
                                    {financialId && <span>Project ID: {financialId}&nbsp;</span>}
                                    Template: {templateTitle}
                                </small>
                            </div>
                        </ListGroupItem>
                    );
                })}
            </ListGroup>
            <div className="text-center">
                <Button bsSize="lg" disabled={disabled} onClick={hideModal}>
                    Cancel
                </Button>
                &nbsp;&nbsp;
                <Button
                    bsSize="lg"
                    bsStyle="primary"
                    disabled={disabled}
                    onClick={() => {
                        const attemptUpdate = updateTemplateSection({
                            projectIds: selectedProjectIds,
                        });

                        // If validation fails, `updateTemplate` does not return a Promise and we
                        // should not proceed with adding the template section
                        if (typeof attemptUpdate.then === 'function') {
                            attemptUpdate.then(() => hideModal());
                        }
                    }}
                    qaTag="sharedSection-broadcast"
                >
                    <i className="fa fa-rss" /> Update {selectedProjectIds.length} Projects
                </Button>
                {submitFailed && (
                    <div className="error-block">Please fix form errors before submitting</div>
                )}
            </div>
        </>
    );
};

TemplateSectionBroadcastModalBody.propTypes = {
    disabled: PropTypes.bool,
    hideModal: PropTypes.func.isRequired,
    loadError: PropTypes.string,
    loading: PropTypes.bool,
    projects: PropTypes.array.isRequired,
    submitFailed: PropTypes.bool,
    updateTemplateSection: PropTypes.func.isRequired,
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export class TimelineHasErrorsField extends PureComponent {
    static propTypes = {
        meta: PropTypes.shape({
            error: PropTypes.string,
        }).isRequired,
        showError: PropTypes.bool,
    };

    render() {
        const {
            meta: { error },
            showError,
        } = this.props;

        if (!error || !showError) {
            return null;
        }

        return <div className="error-block text-center">{error}</div>;
    }
}

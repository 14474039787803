import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { RequisitionsDisplayBody } from './RequisitionsDisplayBody';
import { RequisitionsSearchDialog } from './RequisitionsSearchDialog';

export const RequisitionsDisplay = ({
    addHandler,
    disabled,
    error,
    isEditor,
    label,
    loading,
    requisitions,
}) => {
    const [showForm, setShowForm] = useState(false);

    return (
        <>
            {label}
            <RequisitionsDisplayBody
                disabled={disabled}
                error={error}
                isEditor={isEditor}
                loading={loading}
                requisitions={requisitions}
                setShowForm={setShowForm}
            />
            {showForm && (
                <RequisitionsSearchDialog
                    addHandler={addHandler}
                    disabled={disabled}
                    onClose={() => setShowForm(false)}
                />
            )}
        </>
    );
};

RequisitionsDisplay.propTypes = {
    addHandler: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    isEditor: PropTypes.bool,
    label: PropTypes.node,
    loading: PropTypes.bool,
    requisitions: PropTypes.array.isRequired,
};

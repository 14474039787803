import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@og-pro/ui';
import { DeleteOutlined as DeleteOutlinedIcon } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { tokens } from '@opengov/capital-style';

import { AgGridReact, ProcuratedVendorCell } from '../../../../components';
import { showVendorProfileModal } from '../../../../actions/vendorProfile';
import { addOrRemoveVendors } from '../../../../actions/vendorList';

export const VendorListTable = ({ governmentId, vendorList }) => {
    const dispatch = useDispatch();

    const styles = require('../index.scss');

    const columns = [
        {
            field: 'organization.name',
            headerName: 'Vendor Legal Name & Rating (Powered by Procurated)',
            width: 555,
            headerClass: `${styles.paddingForHeaders}`,
            cellClass: `${styles.paddingForCells}`,
            onCellClicked: (params) => {
                dispatch(showVendorProfileModal(params.data.organization.vendor.id));
            },
            cellRendererFramework: (params) => (
                <ProcuratedVendorCell
                    hideLogo
                    params={{
                        ...params,
                        data: {
                            ...params.data.organization,
                            rating: params.data.rating,
                        },
                    }}
                />
            ),
        },
        {
            field: 'displayName',
            headerName: 'Contact',
            width: 370,
            headerClass: `${styles.paddingForHeaders}`,
            cellClass: `${styles.paddingForCells}`,
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 370,
            headerClass: `${styles.paddingForHeaders}`,
            cellClass: `${styles.paddingForCells}`,
        },
        {
            field: 'organization.phoneComplete',
            headerName: 'Company Phone',
            width: 326,
            headerClass: `${styles.paddingForHeaders}`,
            cellClass: `${styles.paddingForCells}`,
        },
        {
            cellRendererFramework: () => (
                <DeleteOutlinedIcon
                    style={{
                        width: 18,
                        height: 18,
                        marginLeft: -5,
                        marginTop: 16,
                        cursor: 'pointer',
                        color: tokens.colors.colorGray800,
                    }}
                />
            ),
            onCellClicked: (params) => {
                const pendingUserId = params.data.isPendingUser && params.data.id;
                dispatch(
                    addOrRemoveVendors({
                        actionType: 'remove',
                        governmentId,
                        pendingUserIds: pendingUserId ? [pendingUserId] : [],
                        opts: {
                            email: params.data.email,
                            listName: vendorList.name,
                            useOpenGovStyle: true,
                            userName: params.data.displayName,
                            vendorName: params.data.organization && params.data.organization.name,
                        },
                        searchParams: null,
                        userIds: pendingUserId ? [] : [params.data.id],
                        vendorListId: vendorList.id,
                    })
                );
            },
            width: 44,
        },
    ];

    /**
     * Given a row of data from the grid, get a unique ID for it.
     *
     * @param {object} data The raw data in this row
     * @return {string} The unique title of the row
     */
    const getRowNodeId = (data) => {
        return data.id;
    };

    const defaultColDef = {
        editable: false,
        suppressMenu: true,
    };

    const rows = vendorList?.listedUsers?.concat(
        vendorList.listedPendingUsers.map((user) => ({ ...user, isPendingUser: true }))
    );

    return (
        <Box mt={1}>
            <AgGridReact
                columns={columns}
                defaultColDef={defaultColDef}
                domLayout="autoHeight"
                getRowNodeId={getRowNodeId}
                hideSideBar
                pagination
                paginationPageSize={20}
                rowHeight={52}
                rows={rows}
                useOpenGovStyle
            />
        </Box>
    );
};

VendorListTable.propTypes = {
    governmentId: PropTypes.number.isRequired,
    vendorList: PropTypes.object.isRequired,
};

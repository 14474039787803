export const iconSelectOptions = [
    { label: 'Auto', value: 'car' },
    { label: 'Bell', value: 'bell' },
    { label: 'Broadcast', value: 'rss' },
    { label: 'Building', value: 'building' },
    { label: 'Bullhorn', value: 'bullhorn' },
    { label: 'Calculator', value: 'calculator' },
    { label: 'Chart', value: 'bar-chart' },
    { label: 'Check', value: 'check-circle' },
    { label: 'Code', value: 'code' },
    { label: 'Electricity', value: 'bolt' },
    { label: 'Emergency', value: 'ambulance' },
    { label: 'Envelope', value: 'envelope' },
    { label: 'File', value: 'file-text' },
    { label: 'Finance', value: 'usd' },
    { label: 'Flag', value: 'flag' },
    { label: 'Gears', value: 'cog' },
    { label: 'Home', value: 'home' },
    { label: 'Info', value: 'info-circle' },
    { label: 'Institution', value: 'bank' },
    { label: 'Legal', value: 'legal' },
    { label: 'List (Bulleted)', value: 'list-ul' },
    { label: 'List (Numbered)', value: 'list-ol' },
    { label: 'Maintenance', value: 'wrench' },
    { label: 'Networking', value: 'wifi' },
    { label: 'Recycle', value: 'recycle' },
    { label: 'Security', value: 'lock' },
    { label: 'Server', value: 'server' },
    { label: 'Shopping Cart', value: 'shopping-cart' },
    { label: 'Star', value: 'star' },
    { label: 'Trash Can', value: 'trash' },
    { label: 'Tree', value: 'tree' },
    { label: 'Truck', value: 'truck' },
    { label: 'User', value: 'user' },
    { label: 'User Plus', value: 'user-plus' },
    { label: 'Video', value: 'video-camera' },
    { label: 'Wheelchair', value: 'wheelchair' },
].map((option) => {
    return {
        ...option,
        icon: option.value, // Added for search select icon renderer
    };
});

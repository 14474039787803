import { address, phone, REQUIRED_TEXT, state, website } from '../../../Forms/validation';
import { billingAddressFields, faxPhoneFields } from './constants';
import { requiredVendorDatumFieldNames } from '../../../constants/requiredVendorDatum';

const {
    BUSINESS_TYPE_REQUIRED,
    DUNS_REQUIRED,
    EIN_REQUIRED,
    FAX_PHONE_REQUIRED,
    UNIQUE_ENTITY_ID_REQUIRED,
    WEBSITE_REQUIRED,
} = requiredVendorDatumFieldNames;

export const validate = (values, props) => {
    const { requiredVendorDatum } = props;
    const errors = {
        vendor: {},
    };

    if (!values.name) {
        errors.name = REQUIRED_TEXT;
    }

    if (values.vendor) {
        const vendorValues = values.vendor;
        const vendorErrors = {};

        if (requiredVendorDatum) {
            if (requiredVendorDatum[BUSINESS_TYPE_REQUIRED] && !vendorValues.businessType) {
                vendorErrors.businessType = REQUIRED_TEXT;
            }
            if (requiredVendorDatum[WEBSITE_REQUIRED] && !vendorValues.website) {
                vendorErrors.webiste = REQUIRED_TEXT;
            }
        }

        if (requiredVendorDatum && requiredVendorDatum[EIN_REQUIRED] && !vendorValues.ein) {
            vendorErrors.ein = REQUIRED_TEXT;
        } else if (vendorValues.ein && !/^[0-9]+$/.test(vendorValues.ein)) {
            vendorErrors.ein = 'EIN must be numbers only';
        } else if (vendorValues.ein && vendorValues.ein.length !== 9) {
            vendorErrors.ein = 'EIN must be 9 digits long';
        }

        if (requiredVendorDatum && requiredVendorDatum[DUNS_REQUIRED] && !vendorValues.duns) {
            vendorErrors.ein = REQUIRED_TEXT;
        } else if (vendorValues.duns && !/^[0-9]+$/.test(vendorValues.duns)) {
            vendorErrors.duns = 'DUNS must be numbers only';
        } else if (
            vendorValues.duns &&
            (vendorValues.duns.length < 7 || vendorValues.duns.length > 13)
        ) {
            vendorErrors.duns = 'DUNS must be between 7 and 13 digits long';
        }

        if (
            requiredVendorDatum &&
            requiredVendorDatum[UNIQUE_ENTITY_ID_REQUIRED] &&
            !vendorValues.uniqueEntityId
        ) {
            vendorErrors.uniqueEntityId = REQUIRED_TEXT;
        } else if (vendorValues.uniqueEntityId && vendorValues.uniqueEntityId.length !== 12) {
            vendorErrors.uniqueEntityId = 'UEI must be 12 characters long';
        }

        errors.vendor = {
            ...vendorErrors,
            ...state(vendorValues, 'stateOfIncorporation', { allowNA: true }),
        };
    }

    return {
        ...errors,
        ...address(values),
        ...(values.useDifferentBillingAddress
            ? address(values, { fieldNames: billingAddressFields })
            : {}),
        ...phone(values),
        ...phone(values, {
            fieldNames: faxPhoneFields,
            optional: !requiredVendorDatum || !requiredVendorDatum[FAX_PHONE_REQUIRED],
        }),
        ...website(values, {
            fieldName: 'website',
            optional: !requiredVendorDatum || !requiredVendorDatum[WEBSITE_REQUIRED],
        }),
    };
};

import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { reduxForm, Field } from 'redux-form';

import { creatorSelectOption, permissionOptions, fieldNames } from './constants';
import { validate } from './validate';
import {
    Button,
    SearchSelect,
    SearchSelectUserOption,
    UserProfilePicture,
} from '../../../../components';

const { ID, ROLE } = fieldNames;

const formConfig = {
    validate, // Form name is passed in as a prop
};

class ConnectedPermissionForm extends PureComponent {
    static propTypes = {
        changeContractPermission: PropTypes.func.isRequired,
        contractId: PropTypes.number.isRequired,
        creator: PropTypes.bool,
        handleSubmit: PropTypes.func.isRequired,
        invalid: PropTypes.bool,
        invited: PropTypes.bool,
        isSelf: PropTypes.bool,
        pristine: PropTypes.bool,
        reset: PropTypes.func.isRequired,
        submitting: PropTypes.bool,
        userId: PropTypes.number,
        userSelectOptions: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.number.isRequired,
            })
        ).isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    handleAddOrChangePermission = (formData) => {
        const { changeContractPermission, contractId, reset } = this.props;

        changeContractPermission(contractId, formData[ID], formData[ROLE]).then(reset);
    };

    handleRemovePermission = () => {
        const { changeContractPermission, contractId, userId } = this.props;

        changeContractPermission(contractId, userId);
    };

    render() {
        const {
            creator,
            handleSubmit,
            invalid,
            invited,
            isSelf,
            pristine,
            submitting,
            userId,
            userSelectOptions,
        } = this.props;

        const roleSelectOptions = creator
            ? permissionOptions.concat(creatorSelectOption)
            : permissionOptions;

        const disabled = submitting || isSelf;

        let inviteButtonText;
        if (submitting) {
            inviteButtonText = 'Sending';
        } else if (invited) {
            inviteButtonText = 'Update';
        } else {
            inviteButtonText = 'Invite';
        }

        const inviteButton = creator ? null : (
            <Button
                bsStyle={invited ? 'primary' : 'success'}
                className={this.styles.inviteBtn}
                disabled={pristine || invalid || disabled}
                onClick={handleSubmit(this.handleAddOrChangePermission)}
            >
                {inviteButtonText}
            </Button>
        );

        const removeButton =
            invited && !creator && !disabled ? (
                <i
                    className={`fa fa-times fa-2x ${this.styles.removeBtn}`}
                    onClick={this.handleRemovePermission}
                />
            ) : null;

        const userData = get(
            userSelectOptions.find((opt) => opt.value === userId),
            'user'
        );

        return (
            <tr>
                <td className={this.styles.userCol}>
                    {userData && invited ? (
                        <div>
                            <UserProfilePicture horizontal user={userData} />
                            &nbsp;&nbsp;
                            {userData.displayName}
                        </div>
                    ) : (
                        <Field
                            component={SearchSelect}
                            components={{
                                Option: SearchSelectUserOption,
                            }}
                            disabled={invited || disabled}
                            isClearable
                            name={ID}
                            options={userSelectOptions}
                            placeholder="Select user or start typing name"
                            useNullWhenUndefined
                        />
                    )}
                </td>
                <td aria-label="Empty cell" className={this.styles.permissionCol}>
                    <Field
                        component={SearchSelect}
                        disabled={creator || disabled}
                        isSearchable={false}
                        name={ROLE}
                        options={roleSelectOptions}
                    />
                </td>
                <td className={this.styles.actionCol}>
                    {inviteButton}
                    {removeButton}
                </td>
            </tr>
        );
    }
}

export const PermissionForm = reduxForm(formConfig)(ConnectedPermissionForm);

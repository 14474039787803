import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';

import { fieldNames, form } from './constants';
import { validate } from './validate';
import { getGovernmentId } from '../../selectors';
import { Button, InputText, LoadingButton } from '../../../../components';
import { inviteVendorsToPortal } from '../../../../actions/vendorList';

const { EMAILS } = fieldNames;

const mapStateToProps = (state) => {
    return {
        governmentId: getGovernmentId(state),
    };
};

const mapDispatchToProps = {
    inviteVendorsToPortal,
};

const formConfig = {
    form,
    validate,
};

// @connect
// @reduxForm
class ConnectedVendorPortalInviteModal extends Component {
    static propTypes = {
        dirty: PropTypes.bool.isRequired,
        governmentId: PropTypes.number.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        hideModal: PropTypes.func.isRequired,
        invalid: PropTypes.bool.isRequired,
        inviteVendorsToPortal: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            inviting: false,
            inviteError: null,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    hideModal = (opts = {}) => {
        const { dirty, hideModal } = this.props;

        if (
            opts.force ||
            !dirty ||
            window.confirm('You have unsaved edits, are you sure you want to exit?') // eslint-disable-line no-alert
        ) {
            hideModal();
        }
    };

    inviteVendors = (rawData) => {
        const { governmentId } = this.props;

        const data = {
            [EMAILS]: rawData[EMAILS].split(',').map((email) => email.trim()),
        };

        this.setState({ inviting: true, inviteError: null });
        this.props
            .inviteVendorsToPortal(governmentId, data)
            .then(() => {
                this.hideModal({ force: true });
            })
            .catch((error) => {
                this.setState({ inviting: false, inviteError: error.message });
            });
    };

    renderBody() {
        const { handleSubmit, invalid } = this.props;

        const { inviteError, inviting } = this.state;

        return (
            <form onSubmit={handleSubmit(this.inviteVendors)}>
                <Field
                    component={InputText}
                    disabled={inviting}
                    hasFeedback={false}
                    help="Use commas to separate multiple emails"
                    label="Enter email addresses"
                    name={EMAILS}
                    placeholder="sales@globalcorp.com, info@abcsolutions.com"
                    type="textarea"
                />
                <div className="text-right">
                    <Button bsSize="sm" disabled={inviting} onClick={this.hideModal}>
                        Cancel
                    </Button>
                    &nbsp;&nbsp;
                    <LoadingButton
                        bsStyle="primary"
                        disabled={invalid || inviting}
                        icon="fa-envelope"
                        loading={inviting}
                        text="Invite to Portal"
                        type="submit"
                    />
                </div>
                {inviteError && <div className="text-center error-block">{inviteError}</div>}
                <div className={`text-muted ${this.styles.info}`}>
                    We will send email invitations to subscribe to your vendor portal
                </div>
            </form>
        );
    }

    render() {
        return (
            <Modal onHide={this.hideModal} show>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">Invite Vendors to Your Portal</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.renderBody()}</Modal.Body>
            </Modal>
        );
    }
}

export const VendorPortalInviteModal = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedVendorPortalInviteModal);

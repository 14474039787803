import { fieldNames } from '../../../constants';

const { ADDRESS_1, ADDRESS_2, CITY, STATE, VENDOR_ADDRESS, ZIP_CODE } = fieldNames;

export const getAddressData = (addresses) => {
    const data = {
        [VENDOR_ADDRESS]: addresses[0].value,
    };

    // if connected to FIN
    if (addresses[0].addressData) {
        return {
            ...data,
            [ADDRESS_1]: addresses[0].addressData.address1,
            [ADDRESS_2]: addresses[0].addressData.address2,
            [CITY]: addresses[0].addressData.city,
            [STATE]: addresses[0].addressData.state,
            [ZIP_CODE]: addresses[0].addressData.zipCode,
        };
    }

    return data;
};

import React, { useState } from 'react';

import { CustomVariableMappingModal } from './CustomVariableMappingModal';
import { Button } from '../../../../../../components';

export const CustomVariableMappingButton = () => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <Button
                bsStyle="link"
                onClick={() => setShowModal(true)}
                qaTag="templateEdit-viewCustomVariableUsage"
            >
                View Custom Variable Usage
            </Button>
            {showModal && <CustomVariableMappingModal hideModal={() => setShowModal(false)} />}
        </>
    );
};

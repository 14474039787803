import { buildQaTag, componentNames } from '@og-pro/ui';
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { sectionTypeNames } from '@og-pro/shared-config/sections';

import { SectionType } from '../constants';
import { ProjectSectionNavItemStatus } from './Status';

export const ProjectSectionNavItem = ({ section, hasChildren, isSubsection, showShortName }) => {
    const [expanded, setExpanded] = useState(false);
    const styles = require('./index.scss');

    useEffect(() => {
        if (section.children && section.children.some((child) => child.active) && !expanded) {
            setExpanded(true);
        }
    }, [section]);

    const onClick = (e) => {
        if (section.children?.length) {
            // propagation stops here so that, when on mobile the clicking of a divider
            // doesnt close the menu
            e.stopPropagation();
            setExpanded(!expanded);
        }

        if (section.onClick) {
            section.onClick();
        }

        if (typeof window !== 'undefined') {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
    };

    const tagWithComponentName = buildQaTag(`templateEdit-${section.title}`, componentNames.BUTTON);
    const shouldDisplayShortName =
        showShortName && !!section.shortName && section.sectionType !== sectionTypeNames.DIVIDER;

    return (
        <>
            <button
                className={classnames(styles.section, {
                    [styles.subSection]: isSubsection,
                })}
                data-qa={tagWithComponentName}
                onClick={onClick}
            >
                <div
                    className={classnames(styles.sectionSubContainer, {
                        [styles.active]: section.active,
                    })}
                >
                    <div className={styles.sectionTitle}>
                        {hasChildren && (
                            <div
                                className={styles.expandIconContainer}
                                onClick={() => setExpanded(!expanded)}
                            >
                                <i
                                    className={classnames('fa fa-fw', {
                                        'fa-chevron-down': expanded,
                                        'fa-chevron-right': !expanded,
                                        invisible: !section.children?.length,
                                    })}
                                />
                            </div>
                        )}

                        <div
                            className={classnames(styles.titleContainer, {
                                [styles.manualNumbered]: section.manualNumber,
                            })}
                        >
                            {((section.numbering && !section.disableNumbering) ||
                                section.manualNumber) && (
                                <h4
                                    className={classnames(styles.title, styles.numbering, {
                                        [styles.titleSmall]: isSubsection,
                                        [styles.manualNumber]: section.manualNumber,
                                    })}
                                >
                                    {section.numbering || section.manualNumber}
                                </h4>
                            )}
                            <h4
                                className={classnames(styles.title, {
                                    [styles.titleSmall]: isSubsection,
                                    [styles.fontWeightNormal]: isSubsection || section.manualNumber,
                                })}
                            >
                                {section.title}
                                {shouldDisplayShortName && (
                                    <span className={styles.shortName}>{section.shortName}</span>
                                )}
                            </h4>
                        </div>
                    </div>
                    <div className={styles.sectionStatus}>
                        <ProjectSectionNavItemStatus section={section} />
                    </div>
                </div>
            </button>
            {section.children &&
                expanded &&
                section.children.map((subsection, i) => {
                    return (
                        <ProjectSectionNavItem
                            isSubsection
                            key={i}
                            section={subsection}
                            showShortName={showShortName}
                        />
                    );
                })}
        </>
    );
};

ProjectSectionNavItem.propTypes = {
    section: SectionType,
    hasChildren: PropTypes.bool,
    isSubsection: PropTypes.bool,
    showShortName: PropTypes.bool,
};

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';

import { Collapse, PageTitle } from '../../../../../components';

export class ProjectModule extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        defaultOpen: PropTypes.bool,
        isComplete: PropTypes.bool.isRequired,
        isCurrent: PropTypes.bool.isRequired,
        isEnabled: PropTypes.bool.isRequired,
        isPending: PropTypes.bool,
        isSkipped: PropTypes.bool.isRequired,
        name: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            showModule: props.isCurrent || props.defaultOpen,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    toggleModule = () => {
        this.setState((prevState) => ({ showModule: !prevState.showModule }));
    };

    get displayData() {
        const { isComplete, isCurrent, isEnabled, isPending, isSkipped } = this.props;

        if (!isEnabled) {
            return {
                clickHandler: undefined,
                icon: 'fa-lock',
                panelStyle: undefined,
                text: 'Contact OpenGov to enable this module!',
                textStyle: this.styles.titleDisabled,
            };
        }
        if (isSkipped) {
            return {
                clickHandler: undefined,
                icon: 'fa-ban',
                panelStyle: undefined,
                text: 'Skipped',
                textStyle: this.styles.titleDisabled,
            };
        }
        if (isPending) {
            return {
                clickHandler: undefined,
                icon: 'fa-clock-o',
                panelStyle: undefined,
                text: 'Complete the current phase before starting',
                textStyle: this.styles.titleDisabled,
            };
        }
        if (isCurrent) {
            return {
                clickHandler: this.toggleModule,
                icon: 'fa-star',
                panelStyle: 'info',
                text: 'In progress',
                textStyle: 'text-primary',
            };
        }
        if (isComplete) {
            return {
                clickHandler: this.toggleModule,
                icon: 'fa-check',
                panelStyle: 'success',
                text: 'Complete!',
                textStyle: 'text-success',
            };
        }
        return {
            clickHandler: this.toggleModule,
            icon: 'fa-check',
            panelStyle: undefined,
            text: 'Not started yet',
            textStyle: 'text-muted',
        };
    }

    render() {
        const { children, name } = this.props;

        const { showModule } = this.state;

        const toggleIcon = showModule ? 'down' : 'left';

        const { clickHandler, icon, panelStyle, text, textStyle } = this.displayData;

        return (
            <ListGroup>
                <PageTitle title="Project Dashboard" />
                <h1 className="visually-hidden">Project Dashboard</h1>
                <ListGroupItem
                    bsStyle={panelStyle}
                    className={this.styles.listItem}
                    onClick={clickHandler}
                >
                    <div className={this.styles.statusIcon}>
                        <span className="fa-stack">
                            <i className={`fa fa-stack-2x fa-circle ${textStyle}`} />
                            <i className={`fa fa-stack-1x fa-inverse ${icon}`} />
                        </span>
                    </div>
                    <div className={classnames(this.styles.statusText, textStyle)}>
                        <h3>
                            {clickHandler && (
                                <i className={`fa fa-angle-${toggleIcon} pull-right`} />
                            )}
                            {name}
                        </h3>
                        <em>{text}</em>
                    </div>
                </ListGroupItem>
                <Collapse in={showModule}>
                    <ListGroupItem>{children}</ListGroupItem>
                </Collapse>
            </ListGroup>
        );
    }
}

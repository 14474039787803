import React, { Component } from 'react';
import PropTypes from 'prop-types';

const isSupportedType = (type) => {
    return type !== 'checkbox' && type !== 'file' && type !== 'select-multiple';
};

/**
 * HOC for normalizing a redux form field.
 * NOTE: If using from a functional component, be sure to memoize the component
 * with `useCallback` to prevent loss of focus on re-renders.
 * normalizer function handles the storing of the data (what goes to the DB)
 * mask function handles the displaying of the data (what is displayed to user)
 * Adapted from: http://stackoverflow.com/a/37448972
 *
 * @param {object} InputComponent The component to mask
 * @return {object} The masked input component
 */
export function MaskedInputText(InputComponent) {
    return class WrappedComponent extends Component {
        static propTypes = {
            input: PropTypes.object.isRequired,
            meta: PropTypes.object.isRequired,
            mask: PropTypes.func.isRequired,
            normalizer: PropTypes.func.isRequired,
        };

        normalizeData = (value, mask, normalizer, origBlur, origChange) => {
            return {
                // Display the value using the masking function
                value: mask(value),
                // Store the data using the normalizing function
                onBlur: (event) => {
                    if (isSupportedType(event.target.type)) {
                        return origBlur(normalizer(event.target.value));
                    }
                    return origBlur(event);
                },
                // Store the data using the normalizing function
                onChange: (event) => {
                    if (isSupportedType(event.target.type)) {
                        return origChange(normalizer(event.target.value));
                    }
                    return origChange(event);
                },
            };
        };

        render() {
            const { input, mask, normalizer, ...props } = this.props;
            const { value, onBlur, onChange } = input;
            const normalizedField = {
                ...input,
                ...this.normalizeData(value, mask, normalizer, onBlur, onChange),
            };

            return <InputComponent input={normalizedField} {...props} />;
        }
    };
}

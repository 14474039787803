import { fromJS } from 'immutable';
import { noop } from 'lodash/util';

import * as requisitionsActions from '../actions/requisitions';

const initialState = fromJS({
    approvalModalData: {
        data: {},
        onConfirm: noop,
        show: false,
    },
    copyingRequisition: false,
    copyRequisitionError: null,
    deletingRequisition: false,
    deleteRequisitionError: null,
    loadBudgetCheckError: null,
    loadingBudgetCheck: false,
    loadingRequisition: false,
    loadingReviewSequenceSteps: false,
    loadRequisitionError: null,
    loadReviewSequenceStepsError: null,
    requisition: null,
    reviewSequenceSteps: [],
    updatingRequisition: false,
    updateRequisitionError: null,
    vendorAssignmentModal: {
        show: false,
        data: {},
    },
    budgetCheckAlertModal: {
        show: false,
        onContinue: noop,
        onCancel: noop,
        data: {},
    },
    budgetCheckResults: {},
});

export default function requisitionsReducer(state = initialState, action = {}) {
    switch (action.type) {
        case requisitionsActions.COPY_REQUISITION:
            return state.merge(
                fromJS({
                    copyingRequisition: true,
                    copyRequisitionError: null,
                })
            );
        case requisitionsActions.COPY_REQUISITION_FAILURE:
            return state.merge(
                fromJS({
                    copyingRequisition: false,
                    copyRequisitionError: action.error.message,
                })
            );
        case requisitionsActions.COPY_REQUISITION_SUCCESS:
            return state.merge(
                fromJS({
                    copyingRequisition: false,
                })
            );
        case requisitionsActions.DELETE_REQUISITION:
            return state.merge(
                fromJS({
                    deletingRequisition: true,
                    deleteRequisitionError: null,
                })
            );
        case requisitionsActions.DELETE_REQUISITION_FAILURE:
            return state.merge(
                fromJS({
                    deletingRequisition: false,
                    deleteRequisitionError: action.error.message,
                })
            );
        case requisitionsActions.DELETE_REQUISITION_SUCCESS:
            return state.merge(
                fromJS({
                    deletingRequisition: false,
                })
            );
        case requisitionsActions.LOAD_REQUISITION:
            return state.merge(
                fromJS({
                    loadingRequisition: true,
                    loadRequisitionError: null,
                })
            );
        case requisitionsActions.LOAD_REQUISITION_FAILURE:
            return state.merge(
                fromJS({
                    loadingRequisition: false,
                    loadRequisitionError: action.error && action.error.message,
                })
            );
        case requisitionsActions.LOAD_REQUISITION_SUCCESS:
            return state.merge(
                fromJS({
                    loadingRequisition: false,
                    requisition: fromJS(action.result),
                })
            );
        case requisitionsActions.LOAD_REQUISITION_STEPS:
            return state.merge(
                fromJS({
                    loadingReviewSequenceSteps: true,
                    loadReviewSequenceStepsError: null,
                })
            );
        case requisitionsActions.LOAD_REQUISITION_STEPS_FAILURE:
            return state.merge(
                fromJS({
                    loadingReviewSequenceSteps: false,
                    loadReviewSequenceStepsError: action.error && action.error.message,
                })
            );
        case requisitionsActions.LOAD_REQUISITION_STEPS_SUCCESS:
            return state.merge(
                fromJS({
                    loadingReviewSequenceSteps: false,
                    reviewSequenceSteps: fromJS(action.result),
                })
            );
        case requisitionsActions.LOAD_BUDGET_CHECK:
            return state.merge(
                fromJS({
                    budgetCheckResults: {},
                    loadingBudgetCheck: true,
                    loadBudgetCheckError: null,
                })
            );
        case requisitionsActions.LOAD_BUDGET_CHECK_FAILURE:
            return state.merge(
                fromJS({
                    loadingBudgetCheck: false,
                    loadBudgetCheckError: action.error?.message,
                })
            );
        case requisitionsActions.LOAD_BUDGET_CHECK_SUCCESS:
            return state.merge(
                fromJS({
                    budgetCheckResults: fromJS(action.result),
                    loadingBudgetCheck: false,
                })
            );
        case requisitionsActions.RESET_REQUISITION: {
            // prevents flickering of an error state because of redux race conditions
            return initialState.set('loadingRequisition', state.get('loadingRequisition'));
        }
        case requisitionsActions.UPDATE_REQUISITION:
            return state.merge(
                fromJS({
                    updatingRequisition: true,
                    updateRequisitionError: null,
                })
            );
        case requisitionsActions.UPDATE_REQUISITION_FAILURE:
            return state.merge(
                fromJS({
                    updatingRequisition: false,
                    updateRequisitionError: action.error && action.error.message,
                })
            );
        case requisitionsActions.UPDATE_BUDGET_INFO: {
            const { changedAccountSplitPriceItems } = action.result;

            // To speed up the update, a map of the updated items
            const changedAccountSplitPriceItemsMap = changedAccountSplitPriceItems.reduce(
                (map, item) => {
                    map[item.id] = item.availableBudgetAmount;
                    return map;
                },
                {}
            );

            return state.updateIn(['requisition', 'priceTable', 'priceItems'], (priceItems) =>
                priceItems.map((priceItem) =>
                    priceItem.update('accountSplitPriceItems', (accountSplitPriceItems) =>
                        accountSplitPriceItems.map((item) => {
                            const updatedAvailableBudgetAmount =
                                changedAccountSplitPriceItemsMap[item.get('id')];
                            return updatedAvailableBudgetAmount !== undefined
                                ? item.set('availableBudgetAmount', updatedAvailableBudgetAmount)
                                : item;
                        })
                    )
                )
            );
        }

        case requisitionsActions.UPDATE_REQUISITION_SUCCESS:
            return state.merge(
                fromJS({
                    updatingRequisition: false,
                    requisition: fromJS(action.result),
                })
            );
        case requisitionsActions.SHOW_VENDOR_ASSIGNMENT_MODAL:
            return state.merge(
                fromJS({
                    vendorAssignmentModal: {
                        show: true,
                        data: fromJS(action.data),
                    },
                })
            );
        case requisitionsActions.HIDE_VENDOR_ASSIGNMENT_MODAL:
            return state.merge(
                fromJS({
                    vendorAssignmentModal: initialState.get('vendorAssignmentModal'),
                })
            );
        case requisitionsActions.SHOW_BUDGET_CHECK_ALERT_MODAL:
            return state.merge(
                fromJS({
                    budgetCheckAlertModal: {
                        show: true,
                        onContinue: action.onContinue,
                        onCancel: action.onCancel,
                        data: fromJS(action.modalData),
                    },
                })
            );
        case requisitionsActions.HIDE_BUDGET_CHECK_ALERT_MODAL:
            return state.merge(
                fromJS({
                    budgetCheckAlertModal: initialState.get('budgetCheckAlertModal'),
                })
            );
        case requisitionsActions.CLEAN_BUDGET_CHECK_RESULTS:
            return state.merge(
                fromJS({
                    budgetCheckResults: {},
                })
            );
        case requisitionsActions.SHOW_APPROVAL_MODAL:
            return state.merge(
                fromJS({
                    approvalModalData: {
                        data: action.data,
                        onConfirm: action.onConfirm,
                        show: true,
                    },
                })
            );
        case requisitionsActions.HIDE_APPROVAL_MODAL:
            return state.merge(
                fromJS({
                    approvalModalData: initialState.get('approvalModalData'),
                })
            );
        case requisitionsActions.UPDATE_IS_OVER_BUDGET:
            return state.setIn(['requisition', 'isOverBudget'], action.result.isOverBudget);
        default:
            return state;
    }
}

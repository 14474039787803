import { useMutation, useQuery } from '@tanstack/react-query';

import { customFormServiceClient } from './client';

export const CUSTOM_FORM_SERVICE_QUERY_PREFIX = 'CustomFormService';

export const useCustomFormService = ({ queryKey, query, params, ...additionalOptions }) => {
    const queryOptions = {
        queryKey: [CUSTOM_FORM_SERVICE_QUERY_PREFIX, queryKey, params, query],
        queryFn: () => customFormServiceClient.request(query, params),
        retry: false,
        refetchOnWindowFocus: false,
        ...additionalOptions,
    };
    return useQuery(queryOptions);
};

export const useCustomFormServiceMutation = ({
    mutationKey,
    mutation,
    params,
    ...additionalOptions
}) => {
    const mutationOptions = {
        mutationKey: [CUSTOM_FORM_SERVICE_QUERY_PREFIX, mutationKey, params, mutation],
        mutationFn: (body) =>
            /**
             * params are known when the hook is initialized,
             * while body is passed when `mutate(body)` is called
             */
            customFormServiceClient.request(mutation, {
                ...params,
                ...body,
            }),
        retry: false,
        refetchOnWindowFocus: false,
        ...additionalOptions,
    };
    return useMutation(mutationOptions);
};

import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { SearchSelectOption } from './SearchSelectOption';
import { RelationsSearchForm } from '../components';
import { CONTRACT_ID, qaTagPageName } from '../constants';
import { ContractSearchInput } from '../../../../../../../components';

export const RelationsContractSearchForm = ({
    backHandler,
    cancelHandler,
    createError,
    createHandler,
    createText,
    disabled,
    help,
    label,
    selectHandler,
}) => {
    const onChange = (data) => {
        if (data && data[CONTRACT_ID]) {
            return selectHandler({ [CONTRACT_ID]: data[CONTRACT_ID] });
        }
    };

    return (
        <RelationsSearchForm
            backHandler={backHandler}
            cancelHandler={cancelHandler}
            createError={createError}
            createHandler={createHandler}
            createText={createText}
            disabled={disabled}
            onChange={onChange}
        >
            <Field
                component={ContractSearchInput}
                components={{
                    Option: SearchSelectOption,
                }}
                disabled={disabled}
                help={help}
                label={label}
                name={CONTRACT_ID}
                placeholder="Type to search..."
                qaTag={`${qaTagPageName}-searchContracts`}
                useOpenGovStyle
            />
        </RelationsSearchForm>
    );
};

RelationsContractSearchForm.propTypes = {
    backHandler: PropTypes.func.isRequired,
    cancelHandler: PropTypes.func.isRequired,
    createError: PropTypes.string,
    createHandler: PropTypes.func.isRequired,
    createText: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    help: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    selectHandler: PropTypes.func.isRequired,
};

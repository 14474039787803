import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { reduxForm, Field } from 'redux-form';

import { form, fieldNames } from './constants';
import { InputText, Button } from '../../../..';

const { PERSONAL_NOTE } = fieldNames;

const formConfig = {
    form,
};

// @reduxForm
class ConnectedReviewerReminderEmailForm extends PureComponent {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        hideModal: PropTypes.func.isRequired,
    };

    render() {
        const { handleSubmit, hideModal } = this.props;

        const styles = require('./ReviewerReminderEmailForm.scss');

        return (
            <form onSubmit={handleSubmit}>
                <Field
                    component={InputText}
                    label="Add a Personal Note (Optional)"
                    minRows={4}
                    name={PERSONAL_NOTE}
                    overrideFeedback
                    placeholder="Enter Text"
                    qaTag="approvalsDashboard-reminderEmailNote"
                    type="textarea"
                />
                <div className={styles.buttonGroup}>
                    <Button
                        bsSize="sm"
                        onClick={() => hideModal()}
                        qaTag="approvalsDashboard-cancelReminderEmail"
                    >
                        Cancel
                    </Button>
                    <Button
                        bsStyle="primary"
                        className={styles.submitBtn}
                        qaTag="approvalsDashboard-sendReminderEmail"
                        type="submit"
                    >
                        Send Email
                    </Button>
                </div>
            </form>
        );
    }
}

export const ReviewerReminderEmailForm = reduxForm(formConfig)(ConnectedReviewerReminderEmailForm);

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { ProjectSectionsEditModal } from './ProjectSectionsEditModal';
import { Button } from '..';

export class ProjectSectionsEditButton extends PureComponent {
    static propTypes = {
        bsSize: PropTypes.string,
        className: PropTypes.string,
        project: PropTypes.object.isRequired,
        text: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = { showModal: false };
    }

    hideModalHandler = () => this.setState({ showModal: false });

    showModalHandler = () => this.setState({ showModal: true });

    render() {
        const { bsSize, className, text, project } = this.props;

        const { showModal } = this.state;

        return (
            <>
                <Button
                    bsSize={bsSize}
                    bsStyle="link"
                    className={className}
                    onClick={this.showModalHandler}
                    qaTag="projectSectionsEditButton-showModal"
                >
                    <i className="fa fa-pencil" /> {text}
                </Button>
                {showModal && (
                    <ProjectSectionsEditModal hideModal={this.hideModalHandler} project={project} />
                )}
            </>
        );
    }
}

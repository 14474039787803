import { fieldNames } from './constants';

const { COMPLAINT, REQUEST, TITLE } = fieldNames;

export const validate = (values) => {
    const errors = {};

    if (!values[TITLE]) {
        errors[TITLE] = 'Please enter a title';
    } else if (values[TITLE].length > 256) {
        errors[TITLE] = 'Field is too long';
    }

    if (!values[COMPLAINT]) {
        errors[COMPLAINT] = 'Please enter your complaint';
    } else if (values[COMPLAINT].length > 2000) {
        errors[COMPLAINT] = 'Field is too long';
    }

    if (!values[REQUEST]) {
        errors[REQUEST] = 'Please enter your desired resolution';
    } else if (values[REQUEST].length > 2000) {
        errors[REQUEST] = 'Field is too long';
    }

    return errors;
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { compose } from 'redux';

import { EvaluationNotesModal as EvaluationNotesModalComponent } from './components';
import { canEvaluate, getEvaluationModalProposal, isConsensusScorecardPage } from '../selectors';
import {
    hideEvaluationNotes,
    updateProposalEvaluation,
} from '../../../../actions/proposalEvaluations';

const mapStateToProps = (state, props) => {
    return {
        canEvaluate: canEvaluate(state, props),
        isConsensusView: isConsensusScorecardPage(state, props),
        proposal: getEvaluationModalProposal(state),
        showModal: state.proposalEvaluations.get('showNotesModal'),
    };
};

const mapDispatchToProps = {
    hideEvaluationNotes,
    updateProposalEvaluation,
};

// @withRouter
// @connect
class ConnectedEvaluationNotesModal extends Component {
    static propTypes = {
        canEvaluate: PropTypes.bool.isRequired,
        hideEvaluationNotes: PropTypes.func.isRequired,
        isConsensusView: PropTypes.bool.isRequired,
        params: PropTypes.shape({
            evaluatorId: PropTypes.string,
        }).isRequired,
        proposal: PropTypes.shape({
            proposalEvaluation: PropTypes.shape({
                status: PropTypes.string.isRequired,
            }).isRequired,
        }),
        showModal: PropTypes.bool.isRequired,
        updateProposalEvaluation: PropTypes.func.isRequired,
    };

    updateProposalEvaluation = (data) => {
        const {
            isConsensusView,
            params: { evaluatorId },
            proposal,
        } = this.props;

        const message = 'Notes Saved';
        this.props.updateProposalEvaluation(proposal, data, evaluatorId, isConsensusView, message);
    };

    render() {
        const { showModal, proposal } = this.props;

        if (!showModal) {
            return null;
        }

        const { status } = proposal.proposalEvaluation;
        return (
            <EvaluationNotesModalComponent
                canEvaluate={this.props.canEvaluate}
                hideModal={this.props.hideEvaluationNotes}
                isCompleteStatus={status === 'complete'}
                proposal={proposal}
                submitHandler={this.updateProposalEvaluation}
            />
        );
    }
}

export const EvaluationNotesModal = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedEvaluationNotesModal);

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm, formValueSelector, Field } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { OTHER, OTHER_TEXT } from '../constants';
import { validate } from '../validate';
import { InputText, SearchSelect } from '../../../../components';

const mapStateToProps = (state, props) => {
    const onSubmit = (rawData) => {
        const { fieldName } = props;

        // If `OTHER` is selected, take the value from `CLOSE_OUT_REASON_EXTRA` instead
        const closeOutReason =
            rawData[fieldName] === OTHER ? rawData[OTHER_TEXT] : rawData[fieldName];

        // Call the submit handler with the formatted data
        props.onSubmit({ [fieldName]: closeOutReason });
    };

    return {
        fieldValue: formValueSelector(props.form)(state, props.fieldName),
        onSubmit, // `onSubmit` must be specified to form for remote submission to work
    };
};

const formConfig = {
    validate,
};

// @connect
// @reduxForm
class ConnectedMultiChoiceWithOtherForm extends Component {
    static propTypes = {
        characterLimit: PropTypes.number,
        disabled: PropTypes.bool,
        fieldName: PropTypes.string.isRequired,
        fieldValue: PropTypes.string,
        handleSubmit: PropTypes.func.isRequired,
        info: PropTypes.string.isRequired,
        note: PropTypes.node,
        options: PropTypes.array.isRequired,
        otherLabel: PropTypes.string,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const {
            characterLimit,
            disabled,
            fieldName,
            fieldValue,
            handleSubmit,
            info,
            note,
            options,
            otherLabel,
        } = this.props;

        return (
            <form className={this.styles.container} onSubmit={handleSubmit}>
                <p className={`${this.styles.text} text-muted`}>{info}</p>
                <Field
                    aria-label="Select an option"
                    component={SearchSelect}
                    disabled={disabled}
                    name={fieldName}
                    options={options}
                    placeholder="Select an option"
                    qaTag="confirmationModal-options"
                />
                {fieldValue === OTHER && (
                    <Field
                        characterLimit={characterLimit}
                        component={InputText}
                        disabled={disabled}
                        hasFeedback={false}
                        label={otherLabel || 'Other'}
                        name={OTHER_TEXT}
                        placeholder="Enter text"
                        type="text"
                    />
                )}
                {note && (
                    <p className="text-center">
                        <strong>Please Note:</strong> {note}
                    </p>
                )}
            </form>
        );
    }
}

export const MultiChoiceWithOtherForm = compose(
    connect(mapStateToProps),
    reduxForm(formConfig)
)(ConnectedMultiChoiceWithOtherForm);

import classnames from 'classnames';
import { round } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { HtmlContent } from '..';

export class ScoringCriteriaCell extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        scoringCriteria: PropTypes.shape({
            description: PropTypes.string,
            scoringMethodText: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            weight: PropTypes.number.isRequired,
        }).isRequired,
        separateLines: PropTypes.bool,
        showDescription: PropTypes.bool,
        totalWeight: PropTypes.number.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    renderScoringMethodAndWeight() {
        const {
            separateLines,
            scoringCriteria: { scoringMethodText, weight },
            totalWeight,
        } = this.props;

        const percent = `${round((weight / totalWeight) * 100, 1)}%`;

        return separateLines ? (
            <div>
                {scoringMethodText}
                <br />
                {weight} Points ({percent})
            </div>
        ) : (
            ` ${scoringMethodText} | ${weight} Points (${percent})`
        );
    }

    render() {
        const {
            className,
            scoringCriteria: { description, title },
            showDescription,
        } = this.props;

        const normalDisplay = (
            <>
                <b>{title}</b>
                {this.renderScoringMethodAndWeight()}
            </>
        );

        // Expanded display with scoring criteria descriptions included
        if (showDescription) {
            return (
                <div className={classnames('data-table-text-wrap', className)}>
                    <div>{normalDisplay}</div>
                    <HtmlContent content={description} />
                </div>
            );
        }

        return <div className={className}>{normalDisplay}</div>;
    }
}

import React from 'react';
import PropTypes from 'prop-types';

import { DateFilter } from '../../../../../../components';
import { singleDateTypes } from '../../../../../../components/DateFilter/constants';
import { formConfig, isDateValid } from '../../form';
import { dateFormat, fieldNames } from '../../constants';
import { parseDate } from './helpers';

const { DATE_TYPE, ON, AFTER, BEFORE, START, END, ONE_DATE_TYPE } = fieldNames;

export const RequisitionDashboardDateFilter = ({ setFormData }) => {
    const handleOnChange = (date) => {
        if (!isDateValid(date.value) && date.value !== null) {
            return;
        }

        if (singleDateTypes.includes(date.type)) {
            setFormData((prevState) => ({
                ...prevState,
                // Clear all other date filters
                [ON]: null,
                [AFTER]: null,
                [BEFORE]: null,
                [START]: null,
                [END]: null,
                // Set single date filter
                [date.type]: date.value,
            }));
        } else {
            setFormData((prevState) => ({
                ...prevState,
                // Clear all single date filters
                [ON]: null,
                [AFTER]: null,
                [BEFORE]: null,
                // Set range date filter
                [date.type]: date.value,
            }));
        }
    };

    return (
        <DateFilter
            dateFormat={dateFormat}
            dateTypeFieldName={DATE_TYPE}
            formName={formConfig.form}
            onChange={handleOnChange}
            oneDateTypeFieldName={ONE_DATE_TYPE}
            oneDateValueFieldNames={{
                [ON]: ON,
                [AFTER]: AFTER,
                [BEFORE]: BEFORE,
            }}
            parseDate={parseDate}
            rangeDateValueFieldNames={{
                [END]: END,
                [START]: START,
            }}
        />
    );
};

RequisitionDashboardDateFilter.propTypes = {
    setFormData: PropTypes.func.isRequired,
};

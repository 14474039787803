import { fieldNames, maxCharacterLength } from './constants';

const { COMMENT } = fieldNames;

export function validate(values) {
    const errors = {};

    if (!values[COMMENT] || values[COMMENT].trim().length === 0) {
        errors[COMMENT] = 'Please provide a reason for turning off public view';
    } else if (values[COMMENT].length > maxCharacterLength) {
        errors[COMMENT] = `Reason is too long. ${values[COMMENT].length}/${maxCharacterLength}`;
    }

    return errors;
}

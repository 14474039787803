import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';

import { fieldNames, form } from './constants';
import { validate } from './validate';
import { InputText } from '../../../components';

const { INCOMPLETE_REASON } = fieldNames;

const formConfig = {
    form,
    validate,
};

// @reduxForm
class ConnectedIntakeIncompleteForm extends Component {
    static propTypes = {
        disabled: PropTypes.bool,
        handleSubmit: PropTypes.func.isRequired,
    };

    render() {
        const { disabled, handleSubmit } = this.props;

        const styles = require('./index.scss');

        return (
            <form className={styles.container} onSubmit={handleSubmit}>
                <p className={`${styles.text} text-muted`}>
                    Please explain why the request is incomplete and the updates that should be made
                    before it is submitted for review again.
                </p>
                <Field
                    component={InputText}
                    disabled={disabled}
                    hasFeedback={false}
                    name={INCOMPLETE_REASON}
                    placeholder="Reason request is incomplete"
                    type="textarea"
                />
            </form>
        );
    }
}

export const IntakeIncompleteForm = reduxForm(formConfig)(ConnectedIntakeIncompleteForm);

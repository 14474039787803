import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ListGroup, ListGroupItem } from 'react-bootstrap';

export class ProposalDocumentOptions extends Component {
    static propTypes = {
        onClick: PropTypes.func.isRequired,
    };

    get styles() {
        return require('./ProposalDocumentOptions.scss');
    }

    handleClick(includeAttachments) {
        this.props.onClick({ includeAttachments });
    }

    render() {
        return (
            <>
                <h5 className={this.styles.heading}>Report Options</h5>
                <ListGroup>
                    <ListGroupItem onClick={() => this.handleClick(true)}>
                        <i className="fa fa-angle-right pull-right" />
                        Include attachments (PDF)
                    </ListGroupItem>
                    <ListGroupItem onClick={() => this.handleClick(false)}>
                        <i className="fa fa-angle-right pull-right" /> Response only
                    </ListGroupItem>
                </ListGroup>
            </>
        );
    }
}

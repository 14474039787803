import { showSnackbar } from '../actions/notification';
import { trackEvent } from '../helpers';

export default ({ dispatch }) =>
    (next) =>
    (action) => {
        const showUpdateMessage = () => {
            dispatch(
                showSnackbar(action.broadcastUpdateMessage, {
                    dismissAfter: 3500,
                    snackbarStyle: { backgroundColor: '#449d44' },
                    color: 'success',
                })
            );
        };

        // Show update notification if projectSocketBroadcast flag is set
        if (action.projectSocketBroadcast) {
            trackEvent('Project Update Broadcast Received');

            if (action.broadcastUpdateMessage) {
                showUpdateMessage();
            }
        }

        // Show update notification if checklistSocketBroadcast flag is set
        if (action.checklistSocketBroadcast) {
            trackEvent('Checklist Update Broadcast Received');

            if (action.broadcastUpdateMessage) {
                showUpdateMessage();
            }
        }

        // Show update notification if requisitionSocketBroadcast flag is set
        if (action.requisitionSocketBroadcast) {
            trackEvent('Requisition Update Broadcast Received');

            if (action.broadcastUpdateMessage) {
                dispatch(showSnackbar(action.broadcastUpdateMessage, { dismissAfter: 3500 }));
            }
        }

        // Show update notification if reverseAuctionSocketBroadcast flag is set
        if (action.reverseAuctionSocketBroadcast) {
            trackEvent('Reverse Auction Update Broadcast Received');

            if (action.broadcastUpdateMessage) {
                showUpdateMessage();
            }
        }

        // Show update notification if vendorProposalSocketBroadcast flag is set
        if (action.vendorProposalSocketBroadcast) {
            trackEvent('Vendor Proposal Update Broadcast Received');

            if (action.broadcastUpdateMessage) {
                showUpdateMessage();
            }
        }

        // Pass the action on if no emit flag is set
        if (!action.emit) {
            return next(action);
        }

        next(action);

        // Dispatch a second action to be emitted to the socket server
        const emitActionType = `${action.emitType}/${action.type}`;
        return dispatch({ ...action, type: emitActionType, emit: false });
    };

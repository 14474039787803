import { Box } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { ReviewSequenceStepDisplay } from './ReviewSequenceStepDisplay';
import { ReviewSequenceStepField } from './ReviewSequenceStepField';
import { reviewSequenceStepFieldNames } from '../constants';
import { generateReviewSequenceStep, isReviewSequenceStepDeletable } from '../helper';
import { qaTagPageName } from '../../constants';
import { showConfirmationSimpleModal } from '../../../../../../actions/confirmation';
import { Button } from '../../../../../../components';

const { ORDER_BY_ID } = reviewSequenceStepFieldNames;

export const ReviewSequenceStepsField = ({
    appendReviewSequenceStep,
    change,
    disabled,
    fields,
    meta: { error: arrayError },
    showFormValidation,
}) => {
    const [openStep, setOpenStep] = useState(null);
    const dispatch = useDispatch();

    const appendStep = () => {
        setOpenStep(fields.length);
        appendReviewSequenceStep();
    };

    const insertStepAbove = (insertBeforeIndex) => (e) => {
        e.stopPropagation();

        // Re-number `orderById` field of each step to account for new step insertion into form
        fields.forEach((fieldName, index) => {
            if (index < insertBeforeIndex) {
                change(`${fieldName}.${ORDER_BY_ID}`, index + 1);
            } else {
                change(`${fieldName}.${ORDER_BY_ID}`, index + 2);
            }
        });

        fields.splice(insertBeforeIndex, 0, {
            ...generateReviewSequenceStep(),
            [ORDER_BY_ID]: insertBeforeIndex + 1,
        });

        setOpenStep(insertBeforeIndex + 1);
    };

    const removeReviewSequenceStep = (index) => (e) => {
        e.stopPropagation();

        const stepToDelete = fields.get(index);
        const steps = fields.map((field, idx) => fields.get(idx));
        const isDeletable = isReviewSequenceStepDeletable(stepToDelete, steps);

        if (!isDeletable) {
            return dispatch(
                showConfirmationSimpleModal(() => {}, {
                    bsStyle: 'warning',
                    btnText: 'Continue Without Deleting',
                    icon: 'exclamation-circle',
                    text: 'Step cannot be deleted because it is referenced by rejection policies.\n\nPlease remove references from rejection policies before deleting.',
                    title: 'Cannot Delete Step',
                })
            );
        }

        const removeStep = () => {
            setOpenStep(null);
            fields.remove(index);
        };
        dispatch(
            showConfirmationSimpleModal(removeStep, {
                btnText: 'Delete Step',
                text: 'Are you sure you want to delete this step?',
            })
        );
    };

    const items = fields.map((fieldName, index) => {
        const reviewSequenceStep = fields.get(index);
        const { id, uuid } = reviewSequenceStep;
        return openStep === index ? (
            <ReviewSequenceStepField
                change={change}
                closeHandler={() => setOpenStep(null)}
                disabled={disabled}
                fieldName={fieldName}
                index={index}
                key={id || uuid}
                onDelete={removeReviewSequenceStep(index)}
                onInsert={insertStepAbove(index)}
                reviewSequenceStep={reviewSequenceStep}
                showFormValidation={showFormValidation}
                stepNumber={index + 1}
            />
        ) : (
            <ReviewSequenceStepDisplay
                fieldName={fieldName}
                key={id || uuid}
                onClick={() => setOpenStep(index)}
                reviewSequenceStep={reviewSequenceStep}
                showFormValidation={showFormValidation}
                stepNumber={index + 1}
            />
        );
    });

    return (
        <>
            {fields.length === 0 ? (
                <Box marginBottom={2}>
                    <em>No steps added</em>
                </Box>
            ) : (
                <ListGroup>{items}</ListGroup>
            )}
            <Button
                bsStyle="primary"
                disabled={disabled}
                onClick={appendStep}
                qaTag={`${qaTagPageName}-addStep`}
            >
                <i className="fa fa-plus" /> Add Step
            </Button>
            {showFormValidation && !!arrayError && <div className="error-block">{arrayError}</div>}
        </>
    );
};

ReviewSequenceStepsField.propTypes = {
    appendReviewSequenceStep: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    fields: PropTypes.shape({
        forEach: PropTypes.func.isRequired,
        get: PropTypes.func.isRequired,
        length: PropTypes.number.isRequired,
        map: PropTypes.func.isRequired,
        remove: PropTypes.func.isRequired,
        splice: PropTypes.func.isRequired,
    }).isRequired,
    meta: PropTypes.shape({
        error: PropTypes.string,
    }).isRequired,
    showFormValidation: PropTypes.bool,
};

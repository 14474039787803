const { listToDict } = require('../helpers');
const { projectStatusesDict } = require('../projects');

exports.addendumStatuses = ['draft', 'released'];

exports.addendumStatusTypes = listToDict(exports.addendumStatuses);

exports.addendumTypes = ['addendum', 'notice'];

exports.addendumTypesDict = listToDict(exports.addendumTypes);

exports.getAddendumText = ({ isNotice }) => {
    return {
        lower: isNotice ? 'notice' : 'addendum',
        lowerPlural: isNotice ? 'notices' : 'addenda',
        upper: isNotice ? 'Notice' : 'Addendum',
        upperPlural: isNotice ? 'Notices' : 'Addenda',
    };
};

exports.amendableProjectStatuses = [
    projectStatusesDict.OPEN,
    projectStatusesDict.PENDING,
    projectStatusesDict.EVALUATION,
    projectStatusesDict.AWARD_PENDING,
    projectStatusesDict.CLOSED,
];

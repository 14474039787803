import PropTypes from 'prop-types';
import React from 'react';

import { SearchSelectMultiLabelOption } from '../../../../../../../components';

export const SearchSelectOption = (props) => {
    const {
        data: {
            project: { financialId, title },
        },
    } = props;

    return (
        <SearchSelectMultiLabelOption
            {...props}
            label={title || 'Untitled Project'}
            secondaryLabel={financialId}
        />
    );
};

SearchSelectOption.propTypes = {
    data: PropTypes.shape({
        project: PropTypes.shape({
            financialId: PropTypes.string,
            title: PropTypes.string,
        }),
    }).isRequired,
};

import { listToDict } from '@og-pro/shared-config/helpers';

export const form = 'questionAnswerFormAnswer';

export const assignUserForm = 'questionAnswerAssignUserForm';

export const fields = ['description'];

export const fieldNames = listToDict(fields);

const assignUserFields = ['userId'];

export const assignUserFieldNames = listToDict(assignUserFields);

const { sortBy } = require('lodash');

exports.certificationIds = {
    SMALL_BUSINESS: 1,
    DISABLED_VETERAN: 2,
    MBE: 3,
    WBE: 4,
    // There is a gap in IDs here due to deprecation of emergency certifications
    US_DOT_DBE: 8,
    LGBT: 9,
    VETERAN: 10,
    NATIVE_AMERICAN: 11,
    SECTION_3: 12,
    US_DOD_DISABLED_VETERAN: 13,
    MICRO_BUSINESS: 14,
    AIRPORT_CONCESSIONS: 15,
    SEDBE: 16,
    COMBINATION_BUSINESS: 17,
    MWBE: 18,
};

exports.certificationsList = [
    {
        id: exports.certificationIds.SMALL_BUSINESS,
        title: 'Certified Small Business',
        hasData: true,
    },
    {
        id: exports.certificationIds.DISABLED_VETERAN,
        title: 'Disabled Veteran Business Enterprise',
        hasData: true,
    },
    {
        id: exports.certificationIds.MBE,
        title: 'Minority Business Enterprise',
    },
    {
        id: exports.certificationIds.WBE,
        title: 'Woman Business Enterprise',
    },
    {
        id: exports.certificationIds.US_DOT_DBE,
        title: 'US DOT Certified DBE',
    },
    {
        id: exports.certificationIds.LGBT,
        title: 'LGBT Owned',
    },
    {
        id: exports.certificationIds.VETERAN,
        title: 'Veteran Owned',
    },
    {
        id: exports.certificationIds.NATIVE_AMERICAN,
        title: 'Native American Owned',
    },
    {
        id: exports.certificationIds.SECTION_3,
        title: 'Section 3',
    },
    {
        id: exports.certificationIds.US_DOD_DISABLED_VETERAN,
        title: 'US DOD Service-Disabled Veteran Owned Business',
    },
    {
        id: exports.certificationIds.MICRO_BUSINESS,
        title: 'Micro Business',
        hasData: true,
    },
    {
        id: exports.certificationIds.AIRPORT_CONCESSIONS,
        title: 'Airport Concessions Disadvantaged Business Enterprise',
    },
    {
        id: exports.certificationIds.SEDBE,
        title: 'Socially and Economically Disadvantaged Business Enterprise',
    },
    {
        id: exports.certificationIds.COMBINATION_BUSINESS,
        title: 'Combination Business Enterprise',
    },
    {
        id: exports.certificationIds.MWBE,
        title: 'Minority Women Business Enterprise',
    },
];

exports.certificationsLookup = exports.certificationsList.reduce((lookup, certification) => {
    lookup[certification.id] = certification;

    return lookup;
}, {});

exports.sortedCertificationsList = sortBy(exports.certificationsList, ['title']);

exports.certificationsOptions = exports.sortedCertificationsList.map((cert) => ({
    label: cert.title,
    value: cert.id,
}));

exports.certificationsStringBuilder = (data) => {
    const buildString = (items) => {
        return items.reduce((acc, item) => {
            if (data[`certification-${item.id}`]) {
                return acc.length ? `${acc}, ${item.title}` : item.title;
            }
            return acc;
        }, '');
    };

    return buildString(exports.sortedCertificationsList);
};

exports.ethnicities = [
    'African American',
    'Asian',
    'Hispanic',
    'Native American or Alaska Native',
    'White',
    'Other',
    'Prefer not to answer',
];

exports.ethnicityOptions = exports.ethnicities.map((ethnicity) => ({
    label: ethnicity,
    value: ethnicity,
}));

exports.getCertificationTitle = (certificationType) => {
    return exports.certificationsList.find((cert) => cert.id === certificationType)?.title;
};

import { getOpenSearchObject } from '../helpers/vendorSearch';
import { showSnackbar } from './notification';

export const SEARCH_VENDORS = 'public/vendor/SEARCH_VENDORS';
export const SEARCH_VENDORS_SUCCESS = 'public/vendor/SEARCH_VENDORS_SUCCESS';
export const SEARCH_VENDORS_FAIL = 'public/vendor/SEARCH_VENDORS_FAIL';

export function searchVendors(governmentId, search, isPublic) {
    return (dispatch, getState, client) => {
        dispatch({ type: SEARCH_VENDORS });

        const data = getOpenSearchObject(search);

        return client
            .post(
                isPublic
                    ? `/government/${governmentId}/vendor-search/searchPublic`
                    : `/government/${governmentId}/vendor-search/searchInternal`,
                {
                    data,
                }
            )
            .then((response) => {
                dispatch({ type: SEARCH_VENDORS_SUCCESS, response });
                return response;
            })
            .catch((error) => {
                dispatch({ type: SEARCH_VENDORS_FAIL, error });
            });
    };
}

export const DOWNLOAD_VENDOR_SEARCH = 'public/vendor/DOWNLOAD_VENDOR_SEARCH';
export const DOWNLOAD_VENDOR_SEARCH_SUCCESS = 'public/vendor/DOWNLOAD_VENDOR_SEARCH_SUCCESS';
export const DOWNLOAD_VENDOR_SEARCH_FAIL = 'public/vendor/DOWNLOAD_VENDOR_SEARCH_FAIL';

export function downloadVendorSearch(governmentId, search, isPublic, email, cloudflareToken) {
    return (dispatch, getState, client) => {
        dispatch({ type: DOWNLOAD_VENDOR_SEARCH });
        const data = {
            cloudflareToken,
            email,
            searchObject: getOpenSearchObject(search),
        };

        return client
            .post(
                isPublic
                    ? `/government/${governmentId}/vendor-search/downloadPublic`
                    : `/government/${governmentId}/vendor-search/downloadInternal`,
                {
                    data,
                }
            )
            .then(() => {
                dispatch(
                    showSnackbar('', {
                        isSuccess: true,
                        title: 'Working on your request',
                        message:
                            'Exports can take time to complete depending on size and complexity. File will be sent to your email once ready to download.',
                    })
                );
                dispatch({ type: DOWNLOAD_VENDOR_SEARCH_SUCCESS });
            })
            .catch(() => {
                dispatch(showSnackbar('Error Downloading', { isError: true }));
                dispatch({ type: DOWNLOAD_VENDOR_SEARCH_FAIL });
            });
    };
}

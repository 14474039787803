import { Add as AddIcon } from '@mui/icons-material';
import { Button, Dialog, Typography } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { RequestTypeCreateForm } from './RequestTypeCreateForm';
import { qaTagName } from '../constants';
import { getRequestTypesPath } from '../../selectors';
import { createRequestType } from '../../../../../actions/requestTypes';

export const RequestTypeCreateButton = ({ size }) => {
    const [creating, setCreating] = useState(false);
    const [createError, setCreateError] = useState(null);
    const [showDialog, setShowDialog] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const requestTypesPath = useSelector(getRequestTypesPath);

    const createHandler = async (data) => {
        setCreating(true);
        setCreateError(null);

        try {
            const createdRequestType = await dispatch(createRequestType(data));
            setCreating(false);
            navigate(`${requestTypesPath}/${createdRequestType.id}`);
        } catch (error) {
            setCreating(false);
            setCreateError(error.message);
        }
    };

    return (
        <>
            <Button
                color="primary"
                disabled={creating}
                onClick={() => setShowDialog(true)}
                qaTag={`${qaTagName}-create`}
                size={size}
                startIcon={<AddIcon />}
                variant="contained"
            >
                New Request Type
            </Button>
            {createError && (
                <div className="error-block">
                    <Typography component="span" variant="bodySmall">
                        {createError}
                    </Typography>
                </div>
            )}
            <Dialog
                closeButton
                dialogTitle="New Request Type"
                onClose={() => setShowDialog(false)}
                open={showDialog}
            >
                <RequestTypeCreateForm
                    disabled={creating}
                    onCancel={() => setShowDialog(false)}
                    onSubmit={createHandler}
                    serverError={createError}
                />
            </Dialog>
        </>
    );
};

RequestTypeCreateButton.propTypes = {
    size: PropTypes.string,
};

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Field } from 'redux-form';

import { priceTableFieldNames } from '../../../../../constants';
import { InputText } from '../../../../../../../../components';

const { DESCRIPTION, TITLE } = priceTableFieldNames;

export class TitleOptions extends PureComponent {
    static propTypes = {
        arrayName: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
        showValidation: PropTypes.bool,
    };

    static defaultProps = {
        disabled: false,
        showValidation: false,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const { arrayName, disabled, showValidation } = this.props;

        return (
            <>
                <h5 className={this.styles.optionsTitle}>Table Info (optional):</h5>
                <Field
                    aria-label="Title (Optional)"
                    component={InputText}
                    disabled={disabled}
                    hasFeedback={false}
                    name={`${arrayName}.${TITLE}`}
                    placeholder="Title (Optional)"
                    showValidation={showValidation}
                    type="text"
                />
                <Field
                    aria-label="Description (Optional)"
                    component={InputText}
                    disabled={disabled}
                    hasFeedback={false}
                    minRows={2}
                    name={`${arrayName}.${DESCRIPTION}`}
                    placeholder="Description (Optional)"
                    showValidation={showValidation}
                    type="textarea"
                />
            </>
        );
    }
}

import {
    purchaseDetailsModeOptions,
    showAccountInformationOptionsValues,
} from '@og-pro/shared-config/requisitions';

const { REQUIRED, OPTIONAL, HIDDEN } = showAccountInformationOptionsValues;

export const showAccountInformationOptions = [
    { label: 'Mark as Required', value: REQUIRED },
    { label: 'Mark as Optional', value: OPTIONAL },
    { label: 'Remove from Form', value: HIDDEN },
];

export const purchaseDetailsModeSelectOptions = [
    {
        label: "Requestor's Choice",
        description: 'Requestor can choose to give a general budgeted amount or full line items.',
        value: purchaseDetailsModeOptions.ANY,
    },
    {
        label: 'Simple Budgeted Amount Only',
        description: 'Requestor is required to give a general budgeted amount.',
        value: purchaseDetailsModeOptions.AMOUNT_ONLY,
    },
    {
        label: 'Detailed Line Items Required',
        description: 'Requestor is required to give detailed line items.',
        value: purchaseDetailsModeOptions.LINE_ITEMS,
    },
];

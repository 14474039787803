import { useSelector } from 'react-redux';

import { useOgFinancials } from './useOgFinancials';
import { glCoaSettings } from './queries';
import { getIsRequisitionUsingFMS } from '../../selectors/govApp';

const ONE_HOUR = 1000 * 60 * 60;

export const useCoaConfigLookup = () => {
    const hasFMS = useSelector(getIsRequisitionUsingFMS);

    return useOgFinancials({
        queryKey: 'glCoaSettings',
        query: glCoaSettings,
        staleTime: ONE_HOUR,
        enabled: hasFMS,
    });
};

import moment from 'moment-timezone/builds/moment-timezone-with-data-1970-2030';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import { CALENDAR_VIEWS } from './constants';
import { getCalendarJS } from './selectors';
import { getUserOrganizationTimezone } from '../../selectors';
import connectData from '../../ConnectData';
import { loadCalendar as loadCalendarAction } from '../../../actions/calendar';
import { BigCalendar, LoadingError, LoadingSpinner, Main, PageTitle } from '../../../components';
import { timezoneAbbreviations } from '../../../constants';

/**
 * @typedef {'agenda'|'month'} NavigationUnits
 */

/**
 * NOTE: `current` is defined by us to represent a view change within the current range. This could
 * impact the range, but should not change the perceived direction of navigation. For example,
 * switching from a 'month' view of July to an 'agenda' view of July when today's date is July 4th
 * will cause a range change (since 'agenda' shows 30 days from the current day, while 'month' just
 * shows the current month +/- 7 days), but we are not advancing the view in any way that the user
 * would perceive.
 * @typedef {'current'|'NEXT'|'PREV'|'TODAY'} NavigationDirections
 */

/**
 * Calculates the default range, which is specified as the start through the end of the month.
 * @param {string} timezone The timezone for the range
 * @param {Date} [dateTime=moment.tz(moment.utc(), timezone)] The value to initialize "now" with
 * @return {[Date, Date]}
 */
const getDefaultMonthRange = (timezone, dateTime = moment.tz(moment.utc(), timezone)) => {
    const startOfRange = dateTime.clone().startOf('month').subtract(7, 'days');
    const endOfRange = dateTime.clone().endOf('month').add(7, 'days');

    return [startOfRange, endOfRange];
};

/**
 * Given the existing range and a navigation event, compute the new range.
 * https://github.com/jquense/react-big-calendar/issues/342#issuecomment-293275570
 * @param {[Date, Date]} currentRange The current range
 * @param {Date} [navigatedToDate] The current "now" date represents by the navigation
 * @param {NavigationUnits} navigationUnit The unit of navigation
 * @param {NavigationDirections} navigationDirection The direction of navigation
 * @param {string} timezone
 * @return {[Date, Date]}
 */
const getRange = (currentRange, navigatedToDate, navigationUnit, navigationDirection, timezone) => {
    switch (navigationUnit) {
        case 'agenda':
            return [
                moment.tz(navigatedToDate, timezone).startOf('day'),
                moment.tz(navigatedToDate, timezone).endOf('day').add(1, 'month'),
            ];
        case 'month':
            switch (navigationDirection) {
                case 'current':
                    return getDefaultMonthRange(timezone, currentRange[0]);
                case 'NEXT':
                    return [currentRange[0].add(1, 'months'), currentRange[1].add(1, 'months')];
                case 'PREV':
                    return [
                        currentRange[0].subtract(1, 'months'),
                        currentRange[1].subtract(1, 'months'),
                    ];
                case 'TODAY':
                    return getDefaultMonthRange(timezone);
                default:
                    return currentRange;
            }
        default:
            return currentRange;
    }
};

const BaseCalendarNav = (props) => {
    const { calendar, loadCalendar, loadError, loading, timezone } = props;
    const { governmentId } = useParams();
    const navigate = useNavigate();

    const [currentRange, setRange] = useState(() => {
        return getDefaultMonthRange(timezone);
    });

    const handleSelectEvent = (event) => {
        navigate(event.path);
    };

    /**
     * Handle navigation events from the calendar.
     * http://jquense.github.io/react-big-calendar/examples/index.html#prop-onNavigate
     * @param {Date} navigatedToDate The current "now" date represents by the navigation
     * @param {NavigationUnits} navigationUnit The unit of navigation
     * @param {NavigationDirections} navigationDirection The direction of navigation
     */
    const handleNavigate = (navigatedToDate, navigationUnit, navigationDirection) => {
        setRange((prevState) =>
            getRange(prevState, navigatedToDate, navigationUnit, navigationDirection, timezone)
        );
    };

    /**
     * Handle view change events from the calendar.
     * http://jquense.github.io/react-big-calendar/examples/index.html#prop-onView
     * https://github.com/jquense/react-big-calendar/issues/342#issuecomment-293275570
     * @param {NavigationUnits} view The unit of navigation (view) that has been switched to
     */
    const handleViewChange = (view) => {
        setRange((prevState) => getRange(prevState, undefined, view, 'current', timezone));
    };

    useEffect(() => {
        loadCalendar(Number.parseInt(governmentId, 10), {
            endDate: currentRange[1].toISOString(),
            startDate: currentRange[0].toISOString(),
            timezone,
        });

        // Required for Subscribe to Calendar button to work: https://www.addevent.com/c/documentation/add-to-calendar-button-for-calendars
        if (window) {
            setTimeout(() => {
                window.addeventstc.refresh();
            }, 500);
        }
    }, [currentRange, governmentId, loadCalendar, timezone]);

    const styles = require('./index.scss');

    return (
        <Main className="row">
            <PageTitle title="Calendar" />
            <h1 className="visually-hidden">Calendar</h1>
            <div className="col-sm-12">
                {/*
                    TODO: if there's no calendar we also want to show this, but if we change the &&
                    to an || then we get a flash when the calendar updates.
                */}
                {!calendar && loading && <LoadingSpinner />}
                {loadError && <LoadingError error={loadError} />}
                {calendar && (
                    <div className={styles.calendarWrapper}>
                        <BigCalendar
                            date={moment.tz(new Date(), timezone).toDate()}
                            events={calendar.events}
                            formats={{
                                monthHeaderFormat: (date) => {
                                    const headerDate = moment(date);

                                    let header = headerDate.format('MMMM YYYY');

                                    if (loading) {
                                        header = `${header} (Loading...)`;
                                    }

                                    return header;
                                },
                            }}
                            onNavigate={handleNavigate}
                            onSelectEvent={handleSelectEvent}
                            onView={handleViewChange}
                            views={CALENDAR_VIEWS}
                        />
                        {calendar.addEventCalendarUniqueKey && (
                            <div className="pull-left">
                                <div
                                    className="addeventstc"
                                    data-id={calendar.addEventCalendarUniqueKey}
                                    style={{ fontFamily: 'inherit', fontWeight: '400' }}
                                    title="Add to Calendar"
                                >
                                    Subscribe to Calendar
                                </div>
                            </div>
                        )}
                        <div className={`pull-right text-muted ${styles.timezone}`}>
                            Calendar is in {timezoneAbbreviations[timezone]}
                        </div>
                    </div>
                )}
            </div>
        </Main>
    );
};

BaseCalendarNav.propTypes = {
    calendar: PropTypes.shape({
        events: PropTypes.array.isRequired,
        addEventCalendarUniqueKey: PropTypes.string,
    }),
    loadCalendar: PropTypes.func.isRequired,
    loadError: PropTypes.string,
    loading: PropTypes.bool,
    timezone: PropTypes.string.isRequired,
};

BaseCalendarNav.defaultProps = {
    calendar: undefined,
    loadError: undefined,
    loading: false,
};

const MemoizedCalendarNav = React.memo(BaseCalendarNav);

const fetchData = (getState, dispatch, location, params) => {
    const timezone = getState().auth.getIn(['user', 'organization', 'timezone']);
    const currentRange = getDefaultMonthRange(timezone);

    dispatch(
        loadCalendarAction(Number.parseInt(params.governmentId, 10), {
            endDate: currentRange[1].toISOString(),
            startDate: currentRange[0].toISOString(),
            timezone,
        })
    );
};

const mapStateToProps = (state) => {
    return {
        calendar: getCalendarJS(state),
        loadError: state.calendar.get('loadCalendarError'),
        loading: state.calendar.get('loadingCalendar'),
        timezone: getUserOrganizationTimezone(state),
    };
};

const ConnectedCalendar = connect(mapStateToProps, { loadCalendar: loadCalendarAction })(
    MemoizedCalendarNav
);

const ConnectedCalendarWithData = connectData(fetchData)(ConnectedCalendar);

export const CalendarNav = ConnectedCalendarWithData;

/**
 * Catch the arrow-left and arrow-right keyboard presses and prevent propagation on them. This
 * will ensure that the user moves left/right within the input box when editing, rather than
 * moving to the cell left/right of the cell being edited, which is the default behavior.
 *
 * @param {object} params The `suppressKeyboardEvent` callback params
 * @param {boolean} params.editing If the cell we are currently looking at is being edited
 * @param {object} params.event The captured keyboard event to inspect
 * @param {number} params.event.keyCode The key code of the keyboard key that fired the event
 * @return `true` if `params.event.keyCode` is left-arrow (37) or right-arrow (39), `false`
 *         otherwise
 */
export const suppressKeyboardEvent = (params) => {
    return params.editing && (params.event.keyCode === 37 || params.event.keyCode === 39);
};

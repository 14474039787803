import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { getCommentThreadsJS } from '../selectors';
import { showCommentsModal } from '../../../actions/govComments';
import { getCommentThreadKey } from '../../../helpers';
import { Button } from '../../../components';
import { CommentIconOpenGovStyle } from './components/CommentIconOpenGovStyle';

const mapStateToProps = (state) => {
    return {
        comments: getCommentThreadsJS(state),
    };
};

const mapDispatchToProps = {
    showModal: showCommentsModal,
};

// @connect
class ConnectedCommentIcon extends Component {
    static propTypes = {
        className: PropTypes.string,
        comments: PropTypes.object.isRequired,
        commentType: PropTypes.string,
        criterionId: PropTypes.number,
        hideClassName: PropTypes.string,
        iconLeft: PropTypes.bool,
        openGovStyleIconOnly: PropTypes.bool,
        projectSection: PropTypes.shape({
            projectSubsections: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.number.isRequired,
                    subsection_type: PropTypes.string.isRequired,
                })
            ).isRequired,
        }).isRequired,
        pullRight: PropTypes.bool,
        qaTag: PropTypes.string,
        show: PropTypes.bool,
        showModal: PropTypes.func.isRequired,
        subsectionType: PropTypes.string,
        useOpenGovStyle: PropTypes.bool,
    };

    static defaultProps = {
        className: undefined,
        commentType: undefined,
        criterionId: undefined,
        iconLeft: false,
        openGovStyleIconOnly: false,
        pullRight: false,
        qaTag: 'commentIcon',
        show: false,
        subsectionType: undefined,
        useOpenGovStyle: false,
    };

    get className() {
        const { className, hideClassName, pullRight } = this.props;

        let commentClass = this.styles.commentContainer;
        if (pullRight) {
            commentClass += ` ${this.styles.commentRight}`;
        }
        if (className) {
            commentClass += ` ${className}`;
        }
        if (hideClassName) {
            commentClass += ` ${hideClassName}`;
        }
        return commentClass;
    }

    get commentKey() {
        const { commentType, criterionId } = this.props;

        // Map data to thread fields to compute the comment key
        return getCommentThreadKey({
            criterion_id: criterionId,
            project_subsection_id: this.projectSubsectionId,
            type: commentType,
        });
    }

    get projectSubsectionId() {
        const {
            projectSection: { projectSubsections },
            subsectionType,
        } = this.props;

        if (subsectionType) {
            return projectSubsections.find((sub) => sub.subsection_type === subsectionType).id;
        }
        return null;
    }

    get styles() {
        return require('./index.scss');
    }

    handleClick = () => {
        const { showModal } = this.props;

        showModal(this.commentKey);
    };

    render() {
        const {
            comments,
            criterionId,
            iconLeft,
            openGovStyleIconOnly,
            qaTag,
            show,
            useOpenGovStyle,
        } = this.props;

        // Do not show comment icon unless enabled and comments are loaded
        if (!show || !comments || (!this.projectSubsectionId && !criterionId)) return null;

        const threads = comments[this.commentKey];
        const hasComments = threads && threads.length > 0;

        // Control for the order of icon and text
        const iconText = hasComments ? threads.length : '+';
        const icon = <i className={`fa fa-comment-o ${this.styles.icon}`} />;
        const first = iconLeft ? icon : iconText;
        const second = iconLeft ? iconText : icon;
        const className = hasComments
            ? `${this.className} ${this.styles.hasComments}`
            : this.className;

        return useOpenGovStyle ? (
            <CommentIconOpenGovStyle
                className={className}
                clickHandler={this.handleClick}
                iconOnly={openGovStyleIconOnly}
                projectSubsectionId={this.projectSubsectionId}
                qaTag={qaTag}
                threads={threads}
            />
        ) : (
            <Button
                bsStyle="link"
                className={classNames(className, this.styles.noUnderline)}
                onClick={this.handleClick}
                qaTag={qaTag}
                zeroPadding
            >
                {first} {second}
            </Button>
        );
    }
}

export const CommentIcon = connect(mapStateToProps, mapDispatchToProps)(ConnectedCommentIcon);

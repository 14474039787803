import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useParams } from 'react-router-dom';

import { getNavItems, getSelectedVersionsRoute } from './selectors';
import { VersionForm } from './VersionForm';
import { getDocumentVersionsJS } from '../selectors';
import { Nav, NavItem, ZeroState } from '../../../../components';

export const VersionHistory = () => {
    const location = useLocation();
    const params = useParams();

    const documentVersions = useSelector(getDocumentVersionsJS);
    const navItems = useSelector((state) => getNavItems(state, { location, params }));
    const selectedVersionsRoute = useSelector((state) =>
        getSelectedVersionsRoute(state, { location, params })
    );

    const styles = require('./index.scss');

    if (documentVersions.length === 0) {
        return (
            <>
                <VersionForm />
                <ZeroState title="No Document Versions Have Been Created Yet" />
            </>
        );
    }

    return (
        <div className="row">
            <div className="col-sm-4 col-md-3">
                <div className={styles.versionNav}>
                    <Nav bsStyle="pills" stacked>
                        {navItems.map(({ label, route }, index) => (
                            <NavItem
                                className={route === selectedVersionsRoute ? 'active' : ''}
                                end={false}
                                key={index}
                                overrideIsActive={false}
                                qaTag={`revisions-${label}`}
                                to={route}
                            >
                                {label}
                            </NavItem>
                        ))}
                    </Nav>
                </div>
            </div>
            <div className="col-sm-8 col-md-9">
                <Outlet />
            </div>
        </div>
    );
};

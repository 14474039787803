import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { projectStatusesDict } from '@og-pro/shared-config/projects';

import { getSelectedVendorsColumnDefs } from '../helpers';
import { ReportDocxTable } from '../../../../../../components';
import { roundNumber } from '../../../../../../helpers';

export class SelectedVendor extends PureComponent {
    static propTypes = {
        headingStyle: PropTypes.object,
        project: PropTypes.object.isRequired,
        showPercentageTotals: PropTypes.bool.isRequired,
        subHeadingStyle: PropTypes.object,
        useLandscape: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        headingStyle: { fontSize: 18, fontWeight: 'bold' },
        subHeadingStyle: { fontSize: 16, fontWeight: 'bold' },
    };

    getSelectedVendorWithScoreColumnDefs = () => {
        const { showPercentageTotals } = this.props;
        const columnDefs = getSelectedVendorsColumnDefs();
        return [
            ...columnDefs,
            {
                Header: `Score (${showPercentageTotals ? 'Percent' : 'Points'})`,
                accessor: (params) =>
                    `${roundNumber(params.score, 2)}${showPercentageTotals ? '%' : ''}`,
            },
        ];
    };

    getSelectedVendorWithScoreRowData = () => {
        const { project, showPercentageTotals } = this.props;
        return project.evaluation.selectedProposals.map((proposal) => {
            const [matchingProposal] = project.scoredProposals.filter((scoredProposal) => {
                return scoredProposal.id === proposal.id;
            });

            return {
                ...proposal,
                score: showPercentageTotals
                    ? matchingProposal.consensusEvaluation.totalScore
                    : matchingProposal.consensusEvaluation.totalScoreByPoints,
            };
        });
    };

    render() {
        const { headingStyle, project, subHeadingStyle, useLandscape } = this.props;

        const hasConsensus = project.evaluation.hasConsensusEvaluation;
        const columns = hasConsensus
            ? this.getSelectedVendorWithScoreColumnDefs()
            : getSelectedVendorsColumnDefs();
        const data = hasConsensus
            ? this.getSelectedVendorWithScoreRowData()
            : project.evaluation.selectedProposals;
        const awardType =
            project.status === projectStatusesDict.AWARD_PENDING ? 'RECOMMENDED' : 'AWARDED';
        const vendorNum = project.evaluation.selectedProposals.length;
        const vendorText = vendorNum > 1 ? 'VENDORS' : 'VENDOR';

        if (vendorNum === 0) {
            return null;
        }

        return (
            <>
                <div style={headingStyle}>{`SELECTED ${vendorText}`}</div>
                <div
                    style={subHeadingStyle}
                >{`${vendorText} ${awardType} BY THE EVALUATION PROCESS`}</div>
                <ReportDocxTable
                    columns={columns}
                    data={data}
                    tableWidth={450}
                    useLandscape={useLandscape}
                />
            </>
        );
    }
}

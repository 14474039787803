import { getExceptionStatus } from '@og-pro/shared-config/requisitions/exceptionSequences';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Box, TableCell as MuiTableCell, TableRow, Typography } from '@og-pro/ui';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { isRequisitionClosed, statusTypes } from '@og-pro/shared-config/requisitions';

import { getLastActionDisplayData } from '@og-pro/shared-config/requisitions/lastActions';

import { getPriceItemsSummary } from '@og-pro/shared-config/priceTables/requisitionUtils';

import { getRequisitionsPath } from '../selectors';

import { currencyFormatter } from '../../../../../helpers';
import { getGovernmentSalesTax } from '../../../../../selectors/govApp';

import { getPurchaseOrderUrls } from '../../../RequisitionsApproval/helpers';
import { colsDict, columns } from './constants';
import { qaTagPageName } from '../constants';
import { RequisitionListItemSourcingStatus } from './RequisitionListItemSourcingStatus';
import { hasSourcingSubscription } from '../../../../GovApp/selectors';

const {
    REQUEST_NUMBER,
    DESCRIPTION,
    TOTAL_AMOUNT,
    LAST_ACTION,
    CURRENT_STEP,
    EXCEPTION,
    SOURCING_STATUS,
    NEXT,
    CREATOR,
    APPROVAL_GROUP,
} = colsDict;

const PurchaseOrders = ({ purchaseOrders }) => {
    if (purchaseOrders.length === 0) {
        return null;
    }

    if (purchaseOrders.length === 1) {
        const { href, number } = purchaseOrders[0];

        return (
            <Typography
                color={capitalDesignTokens.semanticColors.callToAction.primaryActionDark}
                component={Link}
                fontWeight={400}
                onClick={(e) => e.stopPropagation()}
                rel="noreferrer"
                target="_blank"
                to={href}
                variant="h4"
            >
                PO #{number}
            </Typography>
        );
    }

    return (
        <Typography fontWeight={400} variant="h4">
            {purchaseOrders.length} Purchase Orders
        </Typography>
    );
};

PurchaseOrders.propTypes = {
    purchaseOrders: PropTypes.array,
};

const CurrentStep = ({ currentStep, currentStepIndex, staticStepIds, requisitionStatus }) => {
    const isDraftRejected = requisitionStatus === statusTypes.DRAFT_REJECTED;
    const title = isDraftRejected ? 'Request Returned' : currentStep?.name;

    return (
        <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography
                color={(theme) =>
                    isDraftRejected ? theme.palette.error.main : theme.palette.text.primary
                }
                fontWeight={400}
                variant="h4"
            >
                {title}
            </Typography>
            <Typography
                color={(theme) => theme.palette.text.secondary}
                fontWeight={400}
                variant="bodyXSmall"
            >
                Step {currentStepIndex + 1} of {staticStepIds.length}
            </Typography>
        </Box>
    );
};

CurrentStep.propTypes = {
    currentStep: PropTypes.object,
    currentStepIndex: PropTypes.number,
    staticStepIds: PropTypes.array,
    requisitionStatus: PropTypes.number,
};

const Exception = ({ requisition }) => {
    const { color, text } = getExceptionStatus(requisition);
    return (
        <>
            <Typography marginBottom={0.5}>{requisition.exceptionReviewSequence.name}</Typography>
            <Typography color={get(capitalDesignTokens, color)} variant="bodyXSmall">
                {text}
            </Typography>
        </>
    );
};

Exception.propTypes = {
    requisition: PropTypes.shape({
        exceptionReviewSequence: PropTypes.shape({
            name: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

const ApproverDisplay = ({ approvers }) => {
    if (!approvers || approvers.length === 0) {
        return null;
    }

    const firstApprover = approvers[0].user.displayName;
    const approverCount = approvers.length;
    const otherCount = approverCount - 1;

    let displayText = firstApprover;

    if (approverCount > 2) {
        displayText += ` & ${otherCount} others`;
    } else if (approverCount === 2) {
        displayText += ` & ${otherCount} other`;
    }

    return displayText;
};

ApproverDisplay.propTypes = {
    approvers: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const NextApprover = ({ approvers, requisitionStatus }) => {
    if (isRequisitionClosed(requisitionStatus)) {
        return null;
    }
    if (requisitionStatus === statusTypes.DRAFT_REJECTED) {
        return (
            <Box display="flex" flexDirection="column" gap={0.5}>
                <Typography fontWeight={400} variant="h4">
                    Returned to Creator
                </Typography>
                <Typography fontWeight={400} variant="bodyXSmall">
                    Draft
                </Typography>
            </Box>
        );
    }

    if (approvers.length === 0) {
        return null;
    }

    if (approvers.length === 1) {
        return (
            <Box display="flex" flexDirection="column" gap={0.5}>
                <Typography fontWeight={400} variant="h4">
                    {approvers[0].user.displayName}
                </Typography>
                <Typography
                    color={(theme) => theme.palette.text.secondary}
                    fontWeight={400}
                    variant="bodyXSmall"
                >
                    Next Approver
                </Typography>
            </Box>
        );
    }

    return (
        <>
            <Typography
                fontWeight={400}
                title={approvers
                    .slice(1)
                    .map((approver) => approver.user.displayName)
                    .join(', ')}
                variant="h4"
            >
                <ApproverDisplay approvers={approvers} />
            </Typography>
            <Typography
                color={(theme) => theme.palette.text.secondary}
                fontWeight={400}
                variant="bodyXSmall"
            >
                Next Approvers
            </Typography>
        </>
    );
};

NextApprover.propTypes = {
    approvers: PropTypes.arrayOf(PropTypes.object).isRequired,
    requisitionStatus: PropTypes.number,
};

const TableCell = ({ columnName, ...props }) => {
    const column = columns[columnName];
    return (
        <MuiTableCell
            align={column.contentAlign}
            sx={(theme) => ({
                padding: `${theme.spacing(2)} !important`,
                verticalAlign: 'top',
            })}
            width={column.width}
            {...props}
        />
    );
};

TableCell.propTypes = {
    columnName: PropTypes.string.isRequired,
};

const getNextApprovers = (requisition) => {
    const nextApprovers =
        requisition?.currentStep?.stepApproval?.stepPositionApprovals?.flatMap(
            (stepPositionApproval) => stepPositionApproval.stepPositionApprovers
        ) ?? [];

    return nextApprovers;
};

const getLastActor = (requisition) => {
    if (requisition.status === statusTypes.REVIEW) {
        return requisition.submitter;
    }

    if (requisition.status === statusTypes.CLOSED_CANCELLED) {
        return requisition.canceler;
    }

    return requisition.lastStep?.stepApproval?.lastActor;
};

export const RequisitionsListItem = ({ requisition }) => {
    const params = useParams();
    const navigate = useNavigate();
    const requisitionsPath = getRequisitionsPath({ params });
    const isDrafted = requisition.status === statusTypes.DRAFT;
    const route = isDrafted
        ? `${requisitionsPath}/${requisition.id}/edit`
        : `${requisitionsPath}/${requisition.id}`;

    const nextApprovers = useMemo(() => getNextApprovers(requisition), [requisition]);
    const lastActor = useMemo(() => getLastActor(requisition), [requisition]);

    const {
        muiIcon: LastActionIcon,
        text: lastActionText,
        color: lastActionColor,
    } = getLastActionDisplayData(requisition);

    const { priceItems } = requisition.priceTable;

    const taxRate = useSelector(getGovernmentSalesTax);
    const { total } = getPriceItemsSummary(priceItems, taxRate);

    const hasSourcing = useSelector(hasSourcingSubscription);
    const currentStepIndex = requisition.staticStepIds?.findIndex(
        (step) => step === requisition.current_step_id
    );

    const purchaseOrders = useMemo(
        () => getPurchaseOrderUrls(requisition.purchaseOrderObject || []),
        [requisition.purchaseOrderObject]
    );

    const lastActionDateTime = requisition.lastStep?.stepApproval?.lastActionAt;

    return (
        <TableRow
            data-qa={`${qaTagPageName}-listItem-${requisition.id}`}
            hover
            onClick={() => navigate(route)}
            sx={{
                ':hover': {
                    cursor: 'pointer',
                },
            }}
        >
            <TableCell columnName={REQUEST_NUMBER}>
                <Box display="flex" flexDirection="column" gap={0.5}>
                    <Typography
                        color={capitalDesignTokens.semanticColors.callToAction.primaryActionDark}
                        component={Link}
                        to={route}
                        variant="h4"
                    >
                        #{requisition.identifier}
                    </Typography>
                    {requisition.submittedAt && (
                        <Typography
                            color={(theme) => theme.palette.text.secondary}
                            fontWeight={400}
                            variant="bodyXSmall"
                        >
                            Submitted on {moment(requisition.submittedAt).format('MM/DD/YYYY')}
                        </Typography>
                    )}
                </Box>
            </TableCell>
            <TableCell columnName={DESCRIPTION}>
                <Typography
                    fontWeight={400}
                    style={{
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                    variant="h4"
                >
                    {requisition.descriptionOfRequest}
                </Typography>
            </TableCell>
            <TableCell columnName={TOTAL_AMOUNT}>
                <Box display="flex" flexDirection="column" gap={0.5}>
                    <Typography fontWeight={600} variant="h4">
                        {currencyFormatter({ value: total })}
                    </Typography>
                    <Typography
                        color={(theme) => theme.palette.text.secondary}
                        fontWeight={400}
                        variant="bodyXSmall"
                    >
                        {priceItems.length} Line Items
                    </Typography>
                </Box>
            </TableCell>
            <TableCell columnName={LAST_ACTION}>
                {!isDrafted && (
                    <Box display="flex" flexDirection="column" gap={0.5}>
                        <Typography
                            alignItems="center"
                            color={lastActionColor}
                            display="flex"
                            fontWeight={600}
                            gap={0.5}
                            variant="h4"
                        >
                            <LastActionIcon fontSize="10px" />
                            <span>{lastActionText}</span>
                        </Typography>
                        <Typography
                            color={(theme) => theme.palette.text.secondary}
                            fontWeight={400}
                            variant="bodyXSmall"
                        >
                            {lastActor && <> by {lastActor.displayName}</>}
                            {lastActionDateTime && (
                                <>
                                    &nbsp;on&nbsp;
                                    {moment(lastActionDateTime).format('MM/DD/YYYY [at] hh:mm a')}
                                </>
                            )}
                        </Typography>
                    </Box>
                )}
            </TableCell>
            <TableCell columnName={CURRENT_STEP}>
                {currentStepIndex >= 0 && (
                    <CurrentStep
                        currentStep={requisition.currentStep}
                        currentStepIndex={currentStepIndex}
                        requisitionStatus={requisition.status}
                        staticStepIds={requisition.staticStepIds}
                    />
                )}
                {purchaseOrders.length ? <PurchaseOrders purchaseOrders={purchaseOrders} /> : null}
            </TableCell>
            <TableCell columnName={EXCEPTION}>
                {requisition.exceptionReviewSequence && <Exception requisition={requisition} />}
            </TableCell>
            {hasSourcing && (
                <TableCell columnName={SOURCING_STATUS}>
                    <RequisitionListItemSourcingStatus reqRelations={requisition.reqRelations} />
                </TableCell>
            )}
            <TableCell columnName={NEXT}>
                {isDrafted && (
                    <Typography
                        color={(theme) => theme.palette.text.secondary}
                        fontWeight={600}
                        variant="h4"
                    >
                        Request In Draft
                    </Typography>
                )}
                {requisition.closed_at && (
                    <Box display="flex" flexDirection="column" gap={0.5}>
                        <Typography
                            color={(theme) => theme.palette.text.secondary}
                            fontWeight={600}
                            variant="h4"
                        >
                            Closed
                        </Typography>
                        <Typography
                            color={(theme) => theme.palette.text.secondary}
                            fontWeight={400}
                            variant="bodyXSmall"
                        >
                            {moment(requisition.closed_at).format('MM/DD/YYYY')}
                        </Typography>
                    </Box>
                )}
                <NextApprover approvers={nextApprovers} requisitionStatus={requisition.status} />
            </TableCell>
            <TableCell columnName={CREATOR}>
                <Box display="flex" flexDirection="column" gap={0.5}>
                    <Typography fontWeight={400} variant="h4">
                        {requisition.creator.displayName}
                    </Typography>
                    <Typography
                        color={(theme) => theme.palette.text.secondary}
                        fontWeight={400}
                        variant="bodyXSmall"
                    >
                        Created on {moment(requisition.created_at).format('MM/DD/YYYY')}
                    </Typography>
                </Box>
            </TableCell>
            <TableCell columnName={APPROVAL_GROUP}>
                <Typography fontWeight={400} variant="h4">
                    {requisition.reviewGroup.name}
                </Typography>
            </TableCell>
        </TableRow>
    );
};

RequisitionsListItem.propTypes = {
    requisition: PropTypes.shape({
        closed_at: PropTypes.string,
        created_at: PropTypes.string.isRequired,
        creator: PropTypes.object,
        current_step_id: PropTypes.number,
        currentStep: PropTypes.object,
        descriptionOfRequest: PropTypes.string,
        exceptionReviewSequence: PropTypes.object,
        identifier: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        requestor: PropTypes.object,
        lastStep: PropTypes.object,
        priceTable: PropTypes.object.isRequired,
        purchaseOrderObject: PropTypes.array,
        reqRelations: PropTypes.array.isRequired,
        reviewGroup: PropTypes.shape({
            name: PropTypes.string.isRequired,
        }).isRequired,
        staticStepIds: PropTypes.arrayOf(PropTypes.number),
        status: PropTypes.number.isRequired,
        submittedAt: PropTypes.string,
    }).isRequired,
};

import React from 'react';

import { ResetContract } from '../components';
import { Contract, ContractList } from '../../components';
import * as Public from '../../containers/PublicApp';
import { legacyFetchData, legacyFetchDataLowercaseGov } from '../loaders';

export const PublicContractsList = (getState, dispatch) => [
    {
        element: <ContractList.Public />,
        loader: legacyFetchDataLowercaseGov(getState, dispatch, ContractList.Public),
        path: 'contracts',
    },
];

export const PublicContract = (getState, dispatch) => [
    {
        element: (
            <ResetContract>
                <Public.ContractNav />
            </ResetContract>
        ),
        loader: legacyFetchData(getState, dispatch, Public.ContractNav),
        path: 'contracts/:contractId',
        children: [
            {
                element: <Contract.Public />,
                index: true,
            },
        ],
    },
];

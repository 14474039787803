import React, { PureComponent } from 'react';
import { Nav as NavBS } from 'react-bootstrap';

export class Nav extends PureComponent {
    render() {
        return <NavBS {...this.props} />;
    }
}

Nav.defaultProps = {
    role: 'navigation',
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { reset } from 'redux-form';
import { ListGroupItem } from 'react-bootstrap';
import classnames from 'classnames';
import { Grid } from '@og-pro/ui';

import { getBidHistoryData, getBidUpdateAnimationStyle } from './helper';
import { BidSectionForm } from '../BidSectionForm';
import { BidSectionModalText } from '../BidSectionForm/components';
import {
    showConfirmationModal,
    showConfirmationSimpleModal,
} from '../../../../../actions/confirmation';
import { submitReverseAuctionBid } from '../../../../../actions/reverseAuctions';
import { Button, ReverseAuctionBidHistoryTable } from '../../../../../components';
import { PROXY_BID_REVERSE_AUCTION } from '../../../../../constants/menuActions';
import { currencyFormatter } from '../../../../../helpers';
import { getOrdinalNumber } from '../../../../../helpers/numberFormatter';
import { usePrevious } from '../../../../../hooks';

export const ActiveBidLineItem = ({
    allowsProxyBidding,
    auctionMaxFractionDigits,
    isAuctionLive,
    item: {
        priceItems: {
            0: {
                description,
                quantity,
                vendorPriceItems: {
                    0: {
                        reverseAuctionBids: {
                            0: {
                                unitPrice: currentLowestBid,
                                vendor_id: vendorIdOfCurrentLowestBid,
                            },
                        },
                    },
                },
                reverseAuctionBids,
                unitToMeasure,
            },
        },
        minBidDecrement,
    },
    proposalId,
    timezone,
    rankAndBidMap,
    vendorId,
}) => {
    const styles = require('../index.scss');

    const dispatch = useDispatch();
    const [showBidActivity, setShowBidActivity] = useState(false);
    const prevCurrentLowestBid = usePrevious(currentLowestBid);

    const bidHistoryData = getBidHistoryData(reverseAuctionBids, quantity, vendorId);
    const { currentVendorBid, currentVendorProxyBid, rank, vendorPriceItemId } = rankAndBidMap;
    const isLowestBidder = rank === 1;
    const bidUpdated = prevCurrentLowestBid && prevCurrentLowestBid !== currentLowestBid;

    const toggleBidActivity = () => setShowBidActivity(!showBidActivity);

    const submitBid = ({ bid }) => {
        const bidAmount = Number.parseFloat(bid);

        dispatch(
            showConfirmationSimpleModal(
                () => {
                    dispatch(
                        submitReverseAuctionBid(proposalId, {
                            bidAmount,
                            vendorPriceItemId,
                        })
                    );
                    dispatch(reset(`bid${vendorPriceItemId}`));
                },
                {
                    bsStyle: 'primary',
                    btnText: 'Confirm & Place Bid',
                    title: 'Confirm Bid',
                    icon: null,
                    text: (
                        <BidSectionModalText
                            auctionMaxFractionDigits={auctionMaxFractionDigits}
                            bidAmount={bidAmount}
                            description={description}
                            minBidDecrement={minBidDecrement}
                            total={bidAmount * quantity}
                        />
                    ),
                }
            )
        );
    };

    const submitProxyBid = () => {
        dispatch(
            showConfirmationModal(PROXY_BID_REVERSE_AUCTION, {
                auctionMaxFractionDigits,
                currentLowestBid,
                currentVendorProxyBid,
                description,
                minBidDecrement,
                proposalId,
                quantity,
                vendorPriceItemId,
            })
        );
    };

    const proxyBidProps = {
        allowsProxyBidding,
        currentVendorProxyBid,
        submitProxyBid,
    };

    const formatterOpts = {
        maximumFractionDigits: auctionMaxFractionDigits,
        useSameMinAndMax: true,
    };

    return (
        <ListGroupItem
            className={classnames(
                styles.lineItem,
                bidUpdated &&
                    styles[getBidUpdateAnimationStyle(vendorId, vendorIdOfCurrentLowestBid)],
                isLowestBidder ? styles.lineItemBorderWinning : styles.lineItemBorderLosing
            )}
            key={`${proposalId}-${vendorPriceItemId}`}
        >
            <div className={styles.header}>
                <Grid container>
                    <Grid item md={10} xs={12}>
                        <h4 className={styles.description}>{description}</h4>
                    </Grid>
                    <Grid item md={2} xs={12}>
                        {isLowestBidder ? (
                            <h5 className={styles.statusText}>
                                <div className={styles.statusWinning}>
                                    <i className="fa fa-trophy" /> LOWEST BIDDER
                                </div>
                            </h5>
                        ) : (
                            <h5 className={styles.statusText}>
                                <div className={styles.statusLosing}>
                                    <i className="fa fa-exclamation-triangle" /> OUTBID
                                </div>
                            </h5>
                        )}
                    </Grid>
                </Grid>
                <div className="row">
                    <div className="col-xs-12">
                        <div className={styles.headerInfoText}>
                            Unit of Measure: {unitToMeasure}
                        </div>
                        <div className={classnames(styles.headerInfoText, styles.decrementText)}>
                            Minimum Decrement:{' '}
                            {currencyFormatter({ value: minBidDecrement }, formatterOpts)}
                        </div>
                    </div>
                    <div className={styles.divider} />
                </div>
            </div>
            <div className="row">
                <div className={`col-xs-12 col-md-7 ${styles.bidInfoSection}`}>
                    <div className="row">
                        <div className="col-xs-12 col-sm-1">
                            <h5 className={styles.listItemLabel}>Rank</h5>
                            <div className={styles.rank}>{getOrdinalNumber(rank)}</div>
                        </div>
                        <div className="col-xs-12 col-sm-4">
                            <h5 className={styles.listItemLabel}>Your Current Bid</h5>
                            <div className={styles.currentBidInfo}>
                                <div>
                                    <div className={styles.priceText}>
                                        {currencyFormatter(
                                            { value: currentVendorBid },
                                            formatterOpts
                                        )}
                                    </div>
                                    <div className={styles.priceLabel}>Unit Price</div>
                                </div>
                                <div>
                                    <div className={styles.priceText}>
                                        {currencyFormatter(
                                            { value: currentVendorBid * quantity },
                                            formatterOpts
                                        )}
                                    </div>
                                    <div className={styles.priceLabel}>Total</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-4 col-sm-offset-2">
                            <h5 className={styles.listItemLabel}>Current Lowest Bid</h5>
                            <div className={styles.currentBidInfo}>
                                <div>
                                    <div
                                        className={
                                            isLowestBidder
                                                ? styles.currentLowestBidTextWinning
                                                : styles.currentLowestBidTextLosing
                                        }
                                    >
                                        {currencyFormatter(
                                            { value: currentLowestBid },
                                            formatterOpts
                                        )}
                                    </div>
                                    <div className={styles.priceLabel}>Unit Price</div>
                                </div>
                                <div>
                                    <div
                                        className={
                                            isLowestBidder
                                                ? styles.currentLowestBidTextWinning
                                                : styles.currentLowestBidTextLosing
                                        }
                                    >
                                        {currencyFormatter(
                                            { value: currentLowestBid * quantity },
                                            formatterOpts
                                        )}
                                    </div>
                                    <div className={styles.priceLabel}>Total</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <Button
                            bsStyle="link"
                            className={styles.viewBidActivityButton}
                            onClick={toggleBidActivity}
                        >
                            {showBidActivity ? (
                                <>
                                    Hide Full Bid Activity&nbsp;
                                    <i className="fa fa-angle-up" />
                                </>
                            ) : (
                                <>
                                    View Full Bid Activity&nbsp;
                                    <i className="fa fa-angle-down" />
                                </>
                            )}
                        </Button>
                    </div>
                </div>
                {isAuctionLive && (
                    <div className={`col-xs-12 col-md-5 ${styles.bidFormSection}`}>
                        <BidSectionForm
                            {...proxyBidProps}
                            auctionMaxFractionDigits={auctionMaxFractionDigits}
                            currentLowestBid={currentLowestBid}
                            description={description}
                            form={`bid${vendorPriceItemId}`}
                            minBidDecrement={minBidDecrement}
                            onSubmit={submitBid}
                            quantity={quantity}
                            vendorPriceItemId={vendorPriceItemId}
                        />
                    </div>
                )}
            </div>
            {showBidActivity && (
                <div className="row">
                    <div className={styles.dividerTwo} />
                    <div className="col-xs-12">
                        <div className={styles.bidActivityLabel}>Bid Activity</div>
                        <ReverseAuctionBidHistoryTable
                            auctionMaxFractionDigits={auctionMaxFractionDigits}
                            bidHistoryData={bidHistoryData}
                            timezone={timezone}
                            vendorId={vendorId}
                        />
                    </div>
                </div>
            )}
        </ListGroupItem>
    );
};

ActiveBidLineItem.propTypes = {
    allowsProxyBidding: PropTypes.bool.isRequired,
    auctionMaxFractionDigits: PropTypes.number.isRequired,
    isAuctionLive: PropTypes.bool.isRequired,
    item: PropTypes.object.isRequired,
    proposalId: PropTypes.number.isRequired,
    rankAndBidMap: PropTypes.object,
    timezone: PropTypes.string.isRequired,
    vendorId: PropTypes.number.isRequired,
};

import { isNil, set } from 'lodash';

import {
    getPriceItemExtendedPrice,
    isAmountDiscount,
    isPercentageDiscount,
} from '@og-pro/shared-config/priceTables/requisitionUtils';

import { TAXABLE } from '@og-pro/shared-config/priceTables';

import {
    purchaseDetailsModes,
    showAccountInformationOptionsValues,
} from '@og-pro/shared-config/requisitions';

import { validateCustomForm } from '@og-pro/ui';

import { fieldNames, flagResponseFieldNames } from './constants';
import {
    ADDITIONAL_INFORMATION,
    CUSTOM_FIELDS,
    GENERAL_INFORMATION,
    PURCHASE_DETAILS,
    VENDOR_SELECTION,
} from '../../../../constants/requisitionsCreate';
import { REQUIRED_TEXT, sectionsValidate } from '../../../../Forms/validation';
import { ACCOUNTS_SUM, DECIMAL_PLACES } from './FormComponents/PurchaseDetails/PriceItem/constants';
import { priceItemFieldNames } from '../../constants';
import { accountFieldNames } from './FormComponents/PurchaseDetails/PriceItem/AccountSplit/AccountFields/AccountField/constants';

import { REQUIRED_TEXT_SHORT } from '../../../../Forms/validation/constants';
import { hasFormErrors } from '../../../../helpers';

import {
    FIN_DESCRIPTION_OF_REQUEST_MAX_LENGTH,
    PRO_DESCRIPTION_OF_REQUEST_MAX_LENGTH,
    EXCEPTION_NOTE_MAX_LENGTH,
} from './FormComponents/GeneralInformation/constants';

const { AMOUNT_ONLY } = purchaseDetailsModes;
const validateGeneralInformation = (values, props) => {
    const errors = {};
    const { hasFMS, reqSetting } = props;

    if (!values[fieldNames.REQUESTOR_ID]) {
        errors[fieldNames.REQUESTOR_ID] = REQUIRED_TEXT;
    }

    if (hasFMS && !values[fieldNames.EXPECTED_PURCHASE_ORDER_DATE]) {
        errors[fieldNames.EXPECTED_PURCHASE_ORDER_DATE] = REQUIRED_TEXT;
    }

    if (!values[fieldNames.DESIRED_DELIVERY_DATE]) {
        errors[fieldNames.DESIRED_DELIVERY_DATE] = REQUIRED_TEXT;
    }

    if (hasFMS) {
        if (!values[fieldNames.FISCAL_PERIOD]) {
            errors[fieldNames.FISCAL_PERIOD] = REQUIRED_TEXT;
        }
    } else if (!values[fieldNames.FISCAL_PERIOD_TAG_ID]) {
        errors[fieldNames.FISCAL_PERIOD_TAG_ID] = REQUIRED_TEXT;
    }

    if (reqSetting?.isDescriptionRequired && !values[fieldNames.DESCRIPTION_OF_REQUEST]) {
        errors[fieldNames.DESCRIPTION_OF_REQUEST] = REQUIRED_TEXT;
    }

    const maxDescriptionLength = hasFMS
        ? FIN_DESCRIPTION_OF_REQUEST_MAX_LENGTH
        : PRO_DESCRIPTION_OF_REQUEST_MAX_LENGTH;

    if (values[fieldNames.DESCRIPTION_OF_REQUEST]?.length > maxDescriptionLength) {
        errors[fieldNames.DESCRIPTION_OF_REQUEST] =
            `Description of request must be less than ${maxDescriptionLength} characters`;
    }

    const exceptionSequenceIdFormValue = values[fieldNames.EXCEPTION_SEQUENCE_ID];
    const exceptionNoteFormValue = values[fieldNames.EXCEPTION_NOTE];
    if (exceptionSequenceIdFormValue && !exceptionNoteFormValue?.trim()) {
        errors[fieldNames.EXCEPTION_NOTE] = REQUIRED_TEXT;
    } else if (exceptionNoteFormValue?.length > EXCEPTION_NOTE_MAX_LENGTH) {
        errors[fieldNames.EXCEPTION_NOTE] = 'Exception note is too long';
    }

    return errors;
};

const validateAdditionalInformation = (values) => {
    const errors = {};

    errors[fieldNames.FLAG_RESPONSES] = (values[fieldNames.FLAG_RESPONSES] || []).map(
        (flagResponse) => {
            const flagResponseError = {};

            if (isNil(flagResponse[flagResponseFieldNames.IS_ENABLED])) {
                flagResponseError[flagResponseFieldNames.IS_ENABLED] = true;
            }

            return flagResponseError;
        }
    );

    const hasFlagResponsesError = hasFormErrors(errors[fieldNames.FLAG_RESPONSES]);

    if (hasFlagResponsesError) {
        errors[fieldNames.FLAG_RESPONSES_SUMMARY] = 'These fields are required';
    }

    return errors;
};

const validateCustomFields = (values, props) => {
    return validateCustomForm(values, props.customFields, fieldNames.CUSTOM_FORM_DATA);
};

const validateVendorSelection = (values, props) => {
    const errors = {};
    const { hasFMS, requireVendor } = props;

    const { vendorSelectionState, vendors } = values;

    if (vendorSelectionState === null) {
        errors[fieldNames.VENDOR_SELECTION_STATE] = 'This question is required';
    }

    if (requireVendor && !vendors?.length) {
        errors[fieldNames.VENDOR_SELECTION_STATE] = REQUIRED_TEXT;
    }

    errors[fieldNames.VENDORS] = (vendors || []).map((vendor) => {
        const vendorError = {};

        if (!vendor.vendorId) {
            vendorError[fieldNames.VENDOR_ID] = REQUIRED_TEXT;
        }

        if (!vendor.deliveryCodeText) {
            vendorError[fieldNames.DELIVERY_CODE_TEXT] = REQUIRED_TEXT;
        }

        if (!vendor.paymentTermsText) {
            vendorError[fieldNames.PAYMENT_TERMS_TEXT] = REQUIRED_TEXT;
        }

        if (!hasFMS && !vendor[fieldNames.PRO_USER_ID]) {
            vendorError[fieldNames.PRO_USER_ID] = REQUIRED_TEXT;
        }

        return vendorError;
    });

    return errors;
};

const { DISCOUNT, DISCOUNT_TYPE, QUANTITY, UNIT_PRICE, UNIT_TO_MEASURE } = priceItemFieldNames;

const addError = (fieldName, message, errors, index) => {
    set(
        errors,
        `${fieldNames.PRICE_TABLE}.${fieldNames.PRICE_ITEMS}[${index}].${fieldName}`,
        message
    );
};

function validatePriceItemAccounts(priceItem, errors, index, salesTaxRate) {
    const { accountSplitPriceItems } = priceItem;
    const extendedPrice = getPriceItemExtendedPrice(
        priceItem[QUANTITY],
        priceItem[UNIT_PRICE],
        priceItem[DISCOUNT],
        priceItem[DISCOUNT_TYPE],
        priceItem[TAXABLE] ? salesTaxRate : null
    );

    const totalAmount =
        accountSplitPriceItems && accountSplitPriceItems.length
            ? accountSplitPriceItems.reduce(
                  (total, account) =>
                      total + Math.round(Number(account.amount || 0) * 10 ** DECIMAL_PLACES),
                  0
              ) /
              10 ** DECIMAL_PLACES
            : 0;
    const sumMismatch = totalAmount !== extendedPrice;
    if (sumMismatch) {
        set(
            errors,
            `${fieldNames.PRICE_TABLE}.${fieldNames.PRICE_ITEMS}[${index}].${ACCOUNTS_SUM}`,
            'The amounts must equal the Sub Total amount.'
        );
    }
    if (accountSplitPriceItems && accountSplitPriceItems.length) {
        accountSplitPriceItems.forEach((account, accountIndex) => {
            if (sumMismatch) {
                addError(
                    `${priceItemFieldNames.ACCOUNT_SPLIT_PRICE_ITEMS}[${accountIndex}].${accountFieldNames.AMOUNT}`,
                    'Error'
                );
            }
            if (!account[accountFieldNames.ACCOUNT_NUMBER]) {
                addError(
                    `${priceItemFieldNames.ACCOUNT_SPLIT_PRICE_ITEMS}[${accountIndex}].${accountFieldNames.ACCOUNT_NUMBER}`,
                    'Account information is required.',
                    errors,
                    index
                );
            }

            if (!account[accountFieldNames.AMOUNT] || account[accountFieldNames.AMOUNT] <= 0) {
                addError(
                    `${priceItemFieldNames.ACCOUNT_SPLIT_PRICE_ITEMS}[${accountIndex}].${accountFieldNames.AMOUNT}`,
                    REQUIRED_TEXT,
                    errors,
                    index
                );
            }
        });
    }
}

function validatePurchaseDetailsPriceItems(
    priceItems,
    errors,
    salesTaxRate,
    showAccountInformation,
    hasFMS,
    purchaseDetailsMode
) {
    priceItems.forEach((priceItem, index) => {
        if (!priceItem[priceItemFieldNames.DESCRIPTION]) {
            addError(priceItemFieldNames.DESCRIPTION, REQUIRED_TEXT, errors, index);
        }

        if (!priceItem[QUANTITY] || priceItem[QUANTITY] <= 0) {
            addError(priceItemFieldNames.QUANTITY, REQUIRED_TEXT_SHORT, errors, index);
        }

        if (!priceItem[UNIT_TO_MEASURE]) {
            addError(priceItemFieldNames.UNIT_TO_MEASURE, REQUIRED_TEXT_SHORT, errors, index);
        }

        if (!priceItem[UNIT_PRICE] || priceItem[UNIT_PRICE] <= 0) {
            addError(priceItemFieldNames.UNIT_PRICE, REQUIRED_TEXT_SHORT, errors, index);
        }

        if (
            isPercentageDiscount(priceItem[DISCOUNT_TYPE]) &&
            priceItem[DISCOUNT] &&
            priceItem[DISCOUNT] < 0
        ) {
            addError(
                priceItemFieldNames.DISCOUNT,
                'Please enter a valid discount percentage.',
                errors,
                index
            );
        }

        if (
            isPercentageDiscount(priceItem[DISCOUNT_TYPE]) &&
            priceItem[DISCOUNT] &&
            priceItem[DISCOUNT] > 100
        ) {
            addError(
                priceItemFieldNames.DISCOUNT,
                'Please enter a valid discount percentage.',
                errors,
                index
            );
        }

        if (isAmountDiscount(DISCOUNT_TYPE) && priceItem[DISCOUNT] && priceItem[DISCOUNT] < 0) {
            addError(
                priceItemFieldNames.DISCOUNT,
                'Please enter a valid discount amount.',
                errors,
                index
            );
        }
        if (
            (showAccountInformation === showAccountInformationOptionsValues.REQUIRED &&
                purchaseDetailsMode !== AMOUNT_ONLY) ||
            hasFMS
        ) {
            validatePriceItemAccounts(priceItem, errors, index, salesTaxRate);
        }
    });
}

const validatePurchaseDetails = (values, props) => {
    const errors = {};
    const { salesTaxRate, reqSetting, hasFMS } = props;

    const { priceTable, vendors, purchaseDetailsMode } = values;

    if (!priceTable || !priceTable.priceItems || !priceTable.priceItems.length) {
        return errors;
    }

    if (purchaseDetailsModes[purchaseDetailsMode] !== undefined && !hasFMS) {
        errors[fieldNames.PURCHASE_DETAILS_MODE] = 'This question is required';
    }
    if (priceTable.priceItems.length < vendors.length) {
        errors[fieldNames.PRICE_ITEMS_SUMMARY] =
            `There shouldn't be more vendors than line items. Please remove ${vendors.length - priceTable.priceItems.length} vendor(s) or add ${vendors.length - priceTable.priceItems.length} line item(s)`;
    }

    validatePurchaseDetailsPriceItems(
        priceTable.priceItems,
        errors,
        salesTaxRate,
        reqSetting.showAccountInformation,
        reqSetting.hasFMS,
        purchaseDetailsMode
    );

    return errors;
};

export const validate = (values, props) => {
    const generalInformationErrors = validateGeneralInformation(values, props);
    const additionalInformationErrors = validateAdditionalInformation(values);
    const customFieldErrors = validateCustomFields(values, props);
    const vendorSelection = validateVendorSelection(values, props);
    const purchaseDetailsErrors = validatePurchaseDetails(values, props);

    const sectionErrors = sectionsValidate(
        [GENERAL_INFORMATION, generalInformationErrors],
        [ADDITIONAL_INFORMATION, additionalInformationErrors],
        [CUSTOM_FIELDS, customFieldErrors],
        [VENDOR_SELECTION, vendorSelection],
        [PURCHASE_DETAILS, purchaseDetailsErrors]
    );

    return {
        ...generalInformationErrors,
        ...additionalInformationErrors,
        ...customFieldErrors,
        ...vendorSelection,
        ...purchaseDetailsErrors,
        ...sectionErrors,
    };
};

import { replaceNewline } from '../../../../../utils';

export const titles = ['Overview (1/2)', 'Examples'];

export const summaryExamples = [
    {
        title: 'Example 1 - City Website Design',
        description:
            'Picture a government website. Clunky? Confusing? Bland? ' +
            "Let’s change that picture. We believe the City's website can " +
            'be beauti' +
            "fully designed, delightful to use, and thoroughly useful. We'" +
            're looking for a design team who shares this vision.  We seek ' +
            'a partner willing to break stale convention, eager to challeng' +
            'e assumptions, and relentlessly focused on putting the user at' +
            ' the center of their work. Read on if you think that partner i' +
            's you.',
    },
    {
        title: 'Example 2 - Enhancing City Hall Plaza',
        description: replaceNewline(
            'City Hall Plaza is at the heart of downtown and is the ' +
                'front door for City Hall, and we want to make it a vibrant des' +
                'tination for everyone. This is the people’s space, and all sho' +
                'uld feel welcome.\n\nOver the last two years, we have taken se' +
                'veral steps in this effort. We have hosted some of the City’s ' +
                'signature celebrations here, from major festivals to Super Bow' +
                'l rallies. We have added events such as the Donna Summer Rolle' +
                'r Disco Party to complement existing favorites such as the Wed' +
                'nesday night concert series; and we have experimented with new' +
                ' amenities such as additional food trucks, temporary seating a' +
                'nd lawn games to bring life to the plaza when events are not h' +
                'appening.\n\nThrough this solicitation document, we are s' +
                'eeking a partner to work with us to build on this momentum. Th' +
                'is partner, who would work closely with the City, would have t' +
                'he opportunity to add new programming and new temporary amenit' +
                'ies to the plaza over the next three years.\n\nWe will select ' +
                'the partner who has the best combination of a strong team, com' +
                'pelling vision, and feasible plan to work with us to make City' +
                ' Hall Plaza an even better destination for residents and visit' +
                'ors alike. We are also seeking a partner who can accomplish th' +
                'ese goals in a manner that is at least revenue neutral for the' +
                ' City.'
        ),
    },
    {
        title: 'Example 3 - Parks Recreational Management Solution',
        description: replaceNewline(
            'Football, baseball, quidditch, movie filming, weddings-- all t' +
                'hese activities take place in City Parks and require a permi' +
                't. Mayor Walsh is committed to ensuring that applying for thes' +
                'e permits is as easy, transparent, and dynamic as possible.  ' +
                'We are seeking a partner who will provide a d' +
                'igital platform to make the permitting process a terrific expe' +
                "rience for residents and City employees.\n\nThe City's" +
                ' Parks and Recreation Department expects to launch city-wide o' +
                'nline park permitting and registration by January 1st, 2016. T' +
                'his solution will meet the needs of over a million diverse con' +
                'stituents, as well as provide management tools, reports, metri' +
                'cs, and analytics for Parks Department staff.'
        ),
    },
];

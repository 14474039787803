import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

export const RequisitionsEmptyState = ({ icon, title, subtitle, children }) => {
    const styles = require('./index.scss');

    return (
        <div className={styles.container}>
            <div>
                <i className={classNames('fa', icon, styles.icon)} />
            </div>
            <div>
                <h3 className={styles.title}>{title}</h3>
                <span className={styles.subtitle}>{subtitle}</span>
            </div>
            {children}
        </div>
    );
};

RequisitionsEmptyState.propTypes = {
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    children: PropTypes.node,
};

export const LIST = '/list';
export const COMPARE = '/compare';

export const navItems = [
    {
        label: 'Versions List',
        path: LIST,
    },
    {
        label: 'Compare Versions',
        path: COMPARE,
    },
];

import { formValueSelector } from 'redux-form';
import { createSelector } from 'reselect';

import { questionStatusTypes } from '@og-pro/shared-config/questions';

import {
    ACTIVE,
    EXCLUDED,
    FILTER,
    UNANSWERED,
    UNAPPROVED,
    UNRELEASED,
    form as questionFiltersForm,
} from './components/GovQuestionStatusFilterForm/constants';

const { DRAFT, PENDING } = questionStatusTypes;

const getQuestionFilterValue = (state) => formValueSelector(questionFiltersForm)(state, FILTER);

export const getFilteredQuestionsJS = createSelector(
    [
        (state, props) => props.questions,
        (state, props) => props.excludedQuestions,
        getQuestionFilterValue,
    ],
    (questions, excludedQuestions, filterValue) => {
        switch (filterValue) {
            case EXCLUDED:
                return excludedQuestions;
            case UNAPPROVED:
                return questions.filter((question) => !question.isApproved);
            case UNANSWERED:
                return questions.filter(
                    (question) => question.isApproved && question.status === PENDING
                );
            case UNRELEASED:
                return questions.filter((question) => question.status === DRAFT);
            case ACTIVE:
            default:
                return questions;
        }
    }
);

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Checkbox } from 'react-bootstrap';

export class QuestionnaireResponsePassFail extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool.isRequired,
        isDocx: PropTypes.bool,
        isFailed: PropTypes.oneOfType([PropTypes.bool, PropTypes.instanceOf(null)]),
        projectId: PropTypes.number.isRequired,
        questionnaireResponseId: PropTypes.number.isRequired,
        updateQuestionnaireResponse: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        const { isFailed } = this.props;

        this.state = {
            empty: isFailed === null,
            fail: isFailed,
            updating: false,
        };
    }

    changeHandler = (e) => {
        const { projectId, questionnaireResponseId, updateQuestionnaireResponse } = this.props;
        const { name, checked } = e.target;

        const value = !checked ? null : !!name;

        this.setState({ updating: true });

        updateQuestionnaireResponse(projectId, questionnaireResponseId, { isFailed: value })
            .then(() => {
                return this.setState({
                    empty: value === null,
                    fail: value,
                    updating: false,
                });
            })
            .catch(() => {
                return this.setState({
                    updating: false,
                });
            });
    };

    render() {
        const { disabled, isDocx } = this.props;

        const { empty, fail, updating } = this.state;

        if (isDocx) {
            if (!empty) {
                return (
                    <p>
                        <i>{fail ? 'Fail' : 'Pass'}</i>
                    </p>
                );
            }

            return null;
        }

        return (
            <div className="pull-right" style={{ display: 'inline-block' }}>
                <Checkbox
                    checked={!empty && !fail}
                    disabled={disabled || updating}
                    inline
                    onChange={this.changeHandler}
                >
                    <span className="text-muted">Pass</span>
                </Checkbox>
                <Checkbox
                    checked={!empty && fail}
                    disabled={disabled || updating}
                    inline
                    name="fail"
                    onChange={this.changeHandler}
                >
                    <span className="text-muted">Fail</span>
                </Checkbox>
            </div>
        );
    }
}

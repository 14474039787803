export const CONTRACTS = 'contracts';
export const PROJECTS = 'projects';
export const DOCUMENTS = 'documents';

export const awardsFilters = [
    {
        text: 'Contracts',
        key: CONTRACTS,
        icon: 'file-text',
    },
    {
        text: 'Projects',
        key: PROJECTS,
        icon: 'trophy',
    },
];

import { flatten, get } from 'lodash';
import { createSelector } from 'reselect';

import { getProjectJS } from '../selectors';

const getAttachmentIndex = (state, props) => Number.parseInt(props.params.attachmentNumber, 10) - 1;
const getProposal = (state, props) => props.proposal;
const isSupplementalViewer = (state, props) => props.location.pathname.includes('supplementals');

const getQuestionnaireUploads = createSelector([getProposal, getProjectJS], (proposal, project) => {
    if (proposal && project) {
        const questionnaireAttachments = proposal.questionnaires.map((questionnaire) => {
            const attachments = get(questionnaire, 'questionnaireResponse.attachments') || [];
            return attachments.map((attachment) => {
                return {
                    ...attachment,
                    isSealed: questionnaire.containsPricing && !project.showBidsWithPricing,
                };
            });
        });

        return flatten(questionnaireAttachments);
    }
    return [];
});

export const getAttachmentSections = createSelector(
    [getProposal, getQuestionnaireUploads],
    (proposal, questionnaireUploads) => {
        if (proposal) {
            const questionnaireSection = {
                attachments: questionnaireUploads,
                sectionName: 'QUESTIONNAIRE FILE UPLOADS',
            };

            const proposalDocumentSections = proposal.proposalDocuments.map((doc) => {
                const sectionName = doc.title || 'untitled';
                return {
                    attachments: doc.attachments,
                    sectionName: `${sectionName.toUpperCase()}${
                        doc.isRequired ? ' (Required)' : ''
                    }`,
                };
            });

            let attachmentNumber = 1;
            return [questionnaireSection].concat(proposalDocumentSections).map((section) => {
                return {
                    ...section,
                    attachments: section.attachments.map((attachment) => {
                        return {
                            ...attachment,
                            paramsNumber: attachment.isSealed ? undefined : attachmentNumber++,
                        };
                    }),
                };
            });
        }
        return [];
    }
);

export const getSupplementalAttachmentsList = createSelector([getProposal], (proposal) => {
    let attachmentNumber = 1;
    return proposal.supplementalAttachments.map((attachment) => {
        return {
            ...attachment,
            paramsNumber: attachmentNumber++,
        };
    });
});

const getAttachmentsList = createSelector(
    [getAttachmentSections, getSupplementalAttachmentsList, isSupplementalViewer],
    (attachmentSections, supplementalAttachments, isSupp) => {
        if (isSupp) {
            return supplementalAttachments;
        }
        return flatten(attachmentSections.map((section) => section.attachments)).filter(
            (attach) => {
                return !attach.isSealed;
            }
        );
    }
);

export const getAttachment = createSelector(
    [getAttachmentsList, getAttachmentIndex],
    (attachments, attachmentIndex) => {
        return attachments[attachmentIndex];
    }
);

export const getPrevAttachment = createSelector(
    [getAttachmentsList, getAttachmentIndex],
    (attachments, attachmentIndex) => {
        return attachments[attachmentIndex - 1];
    }
);

export const getNextAttachment = createSelector(
    [getAttachmentsList, getAttachmentIndex],
    (attachments, attachmentIndex) => {
        return attachments[attachmentIndex + 1];
    }
);

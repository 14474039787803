const { listToDict } = require('../helpers');

const priceTableFields = [
    'columnOrder',
    'hasComment',
    'hasCustom1',
    'hasCustom2',
    'hasCustom3',
    'hasCustom4',
    'hasCustom5',
    'hasDiscount',
    'hasNoBid',
    'hasPercentage',
    'hasQuantity',
    'hasSalesTaxRow',
    'hasTotalRow',
    'headerComment',
    'headerCustom1',
    'headerCustom2',
    'headerCustom3',
    'headerCustom4',
    'headerCustom5',
    'headerDescription',
    'headerDiscount',
    'headerDiscountOnly',
    'headerLineItem',
    'headerNoBid',
    'headerQuantity',
    'headerTaxable',
    'headerTotal',
    'headerUnitPrice',
    'headerUnitToMeasure',
    'maxBid',
    'minBid',
    'minBidDecrement',
    'omitLineItem',
    'specifyQuantity',
    'specifyUnitPrice',
    'title',
];

exports.priceTableFieldNames = listToDict(priceTableFields);

const priceItemFields = [
    'comment',
    'custom1',
    'custom2',
    'custom3',
    'custom4',
    'custom5',
    'description',
    'discount',
    'discountOnly',
    'isHeaderRow',
    'lineItem',
    'noBid',
    'quantity',
    'taxable',
    'totalCost',
    'unitPrice',
    'unitToMeasure',
];

exports.priceItemFieldNames = listToDict(priceItemFields);

// Standard price table columns
const COMMENT = 'comment';
const DESCRIPTION = 'description';
const DISCOUNT = 'discount';
const DISCOUNT_ONLY = 'discountOnly';
const IS_HEADER_ROW = 'isHeaderRow';
const LINE_ITEM = 'lineItem';
const NO_BID = 'noBid';
const QUANTITY = 'quantity';
const TAXABLE = 'taxable';
const TOTAL_COST = 'totalCost';
const UNIT_PRICE = 'unitPrice';
const UNIT_TO_MEASURE = 'unitToMeasure';

// Custom price table columns
const CUSTOM_1 = 'custom1';
const CUSTOM_2 = 'custom2';
const CUSTOM_3 = 'custom3';
const CUSTOM_4 = 'custom4';
const CUSTOM_5 = 'custom5';

exports.defaultColumnOrder = [
    LINE_ITEM,
    DESCRIPTION,
    QUANTITY,
    UNIT_TO_MEASURE,
    DISCOUNT_ONLY,
    TAXABLE,
    UNIT_PRICE,
    DISCOUNT,
    TOTAL_COST,
    NO_BID,
    CUSTOM_1,
    CUSTOM_2,
    CUSTOM_3,
    CUSTOM_4,
    CUSTOM_5,
];

exports.defaultColumnData = {
    [LINE_ITEM]: {
        defaultHeaderName: 'Line Item',
        headerField: exports.priceTableFieldNames.HEADER_LINE_ITEM,
        includedField: true,
        priceItemField: LINE_ITEM,
    },
    [DESCRIPTION]: {
        defaultHeaderName: 'Description',
        headerField: exports.priceTableFieldNames.HEADER_DESCRIPTION,
        includedField: true,
        priceItemField: DESCRIPTION,
    },
    [QUANTITY]: {
        defaultHeaderName: 'Quantity',
        headerField: exports.priceTableFieldNames.HEADER_QUANTITY,
        includedField: exports.priceTableFieldNames.HAS_QUANTITY,
        priceItemField: QUANTITY,
    },
    [UNIT_TO_MEASURE]: {
        defaultHeaderName: 'Unit of Measure',
        headerField: exports.priceTableFieldNames.HEADER_UNIT_TO_MEASURE,
        includedField: true,
        priceItemField: UNIT_TO_MEASURE,
    },
    [DISCOUNT_ONLY]: {
        defaultHeaderName: 'Discount Only',
        headerField: exports.priceTableFieldNames.HEADER_DISCOUNT_ONLY,
        includedField: exports.priceTableFieldNames.HAS_DISCOUNT,
        priceItemField: DISCOUNT_ONLY,
    },
    [TAXABLE]: {
        defaultHeaderName: 'Taxable',
        headerField: exports.priceTableFieldNames.HEADER_TAXABLE,
        includedField: exports.priceTableFieldNames.HAS_SALES_TAX_ROW,
        priceItemField: TAXABLE,
    },
    [UNIT_PRICE]: {
        defaultHeaderName: 'Unit Cost',
        headerField: exports.priceTableFieldNames.HEADER_UNIT_PRICE,
        includedField: true,
        priceItemField: UNIT_PRICE,
    },
    [DISCOUNT]: {
        defaultHeaderName: 'Discount (%)',
        headerField: exports.priceTableFieldNames.HEADER_DISCOUNT,
        includedField: exports.priceTableFieldNames.HAS_DISCOUNT,
        priceItemField: DISCOUNT,
    },
    [TOTAL_COST]: {
        defaultHeaderName: 'Total',
        headerField: exports.priceTableFieldNames.HEADER_TOTAL,
        includedField: exports.priceTableFieldNames.HAS_QUANTITY,
        priceItemField: TOTAL_COST,
    },
    [NO_BID]: {
        defaultHeaderName: 'No Bid',
        headerField: exports.priceTableFieldNames.HEADER_NO_BID,
        includedField: exports.priceTableFieldNames.HAS_NO_BID,
        priceItemField: NO_BID,
    },
    [COMMENT]: {
        defaultHeaderName: 'Comments',
        headerField: exports.priceTableFieldNames.HEADER_COMMENT,
        includedField: exports.priceTableFieldNames.HAS_COMMENT,
        priceItemField: COMMENT,
    },
    [CUSTOM_1]: {
        defaultHeaderName: 'Custom Column 1',
        headerField: exports.priceTableFieldNames.HEADER_CUSTOM_1,
        includedField: exports.priceTableFieldNames.HAS_CUSTOM_1,
        priceItemField: CUSTOM_1,
    },
    [CUSTOM_2]: {
        defaultHeaderName: 'Custom Column 2',
        headerField: exports.priceTableFieldNames.HEADER_CUSTOM_2,
        includedField: exports.priceTableFieldNames.HAS_CUSTOM_2,
        priceItemField: CUSTOM_2,
    },
    [CUSTOM_3]: {
        defaultHeaderName: 'Custom Column 3',
        headerField: exports.priceTableFieldNames.HEADER_CUSTOM_3,
        includedField: exports.priceTableFieldNames.HAS_CUSTOM_3,
        priceItemField: CUSTOM_3,
    },
    [CUSTOM_4]: {
        defaultHeaderName: 'Custom Column 4',
        headerField: exports.priceTableFieldNames.HEADER_CUSTOM_4,
        includedField: exports.priceTableFieldNames.HAS_CUSTOM_4,
        priceItemField: CUSTOM_4,
    },
    [CUSTOM_5]: {
        defaultHeaderName: 'Custom Column 5',
        headerField: exports.priceTableFieldNames.HEADER_CUSTOM_5,
        includedField: exports.priceTableFieldNames.HAS_CUSTOM_5,
        priceItemField: CUSTOM_5,
    },
};

exports.standardColumnsData = [
    exports.defaultColumnData[LINE_ITEM],
    exports.defaultColumnData[DESCRIPTION],
    exports.defaultColumnData[QUANTITY],
    exports.defaultColumnData[UNIT_TO_MEASURE],
    exports.defaultColumnData[DISCOUNT_ONLY],
    exports.defaultColumnData[TAXABLE],
    exports.defaultColumnData[UNIT_PRICE],
    exports.defaultColumnData[DISCOUNT],
    exports.defaultColumnData[TOTAL_COST],
    exports.defaultColumnData[NO_BID],
    exports.defaultColumnData[COMMENT],
];

exports.customColumnsData = [
    exports.defaultColumnData[CUSTOM_1],
    exports.defaultColumnData[CUSTOM_2],
    exports.defaultColumnData[CUSTOM_3],
    exports.defaultColumnData[CUSTOM_4],
    exports.defaultColumnData[CUSTOM_5],
];

exports.priceTableHeaderFields = [
    exports.priceTableFieldNames.HEADER_COMMENT,
    exports.priceTableFieldNames.HEADER_DESCRIPTION,
    exports.priceTableFieldNames.HEADER_DISCOUNT,
    exports.priceTableFieldNames.HEADER_DISCOUNT_ONLY,
    exports.priceTableFieldNames.HEADER_LINE_ITEM,
    exports.priceTableFieldNames.HEADER_NO_BID,
    exports.priceTableFieldNames.HEADER_QUANTITY,
    exports.priceTableFieldNames.HEADER_TAXABLE,
    exports.priceTableFieldNames.HEADER_TOTAL,
    exports.priceTableFieldNames.HEADER_UNIT_PRICE,
    exports.priceTableFieldNames.HEADER_UNIT_TO_MEASURE,
];

exports.customPriceTableHeaderFields = [
    exports.priceTableFieldNames.HEADER_CUSTOM_1,
    exports.priceTableFieldNames.HEADER_CUSTOM_2,
    exports.priceTableFieldNames.HEADER_CUSTOM_3,
    exports.priceTableFieldNames.HEADER_CUSTOM_4,
    exports.priceTableFieldNames.HEADER_CUSTOM_5,
];

exports.customPriceItemFields = [
    exports.priceItemFieldNames.CUSTOM_1,
    exports.priceItemFieldNames.CUSTOM_2,
    exports.priceItemFieldNames.CUSTOM_3,
    exports.priceItemFieldNames.CUSTOM_4,
    exports.priceItemFieldNames.CUSTOM_5,
];

exports.COMMENT = COMMENT;
exports.DESCRIPTION = DESCRIPTION;
exports.DISCOUNT = DISCOUNT;
exports.DISCOUNT_ONLY = DISCOUNT_ONLY;
exports.IS_HEADER_ROW = IS_HEADER_ROW;
exports.LINE_ITEM = LINE_ITEM;
exports.NO_BID = NO_BID;
exports.QUANTITY = QUANTITY;
exports.TAXABLE = TAXABLE;
exports.TOTAL_COST = TOTAL_COST;
exports.UNIT_PRICE = UNIT_PRICE;
exports.UNIT_TO_MEASURE = UNIT_TO_MEASURE;

exports.CUSTOM_1 = CUSTOM_1;
exports.CUSTOM_2 = CUSTOM_2;
exports.CUSTOM_3 = CUSTOM_3;
exports.CUSTOM_4 = CUSTOM_4;
exports.CUSTOM_5 = CUSTOM_5;

exports.priceTableTypes = {
    PROJECT: 0,
    REQUISITION: 1,
};

exports.discountTypes = {
    DOLLAR_AMOUNT: 0,
    PERCENTAGE: 1,
};

import { useCustomFormServiceMutation } from './useCustomFormService';
import { createCustomField } from './queries';

export const useCreateCustomField = () => {
    return useCustomFormServiceMutation({
        mutationKey: 'createCustomField',
        mutation: createCustomField,
        enabled: true,
        params: {},
    });
};

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { formValueSelector } from 'redux-form';

import { getAvailableTemplateSections } from '../../../../selectors';
import { SDv2AddSection } from '../../../../../../../components/SDv2/AddSection';
import { form, fieldNames } from '../../../../forms/constants';
import { TemplateEditV2FunctionsContext } from '../../../context';
import { useAddTemplateSection } from '../../../hooks';

const selector = formValueSelector(form);

export const TemplateEditV2AddSectionMenu = ({
    disableCollapsable,
    hideDividers = false,
    setAddingSection,
    unsetAddingSection,
}) => {
    const { isDocBuilder, isIntake } = useContext(TemplateEditV2FunctionsContext);
    const projectSections =
        useSelector((state) => selector(state, fieldNames.PROJECT_SECTIONS)) || [];
    const templateSections = useSelector(getAvailableTemplateSections);
    const useSectionDividers = useSelector((state) =>
        selector(state, fieldNames.USE_SECTION_DIVIDERS)
    );
    const { onAddSection } = useAddTemplateSection({ setAddingSection });

    const onCancel = () => {
        if (unsetAddingSection) {
            unsetAddingSection();
        }
    };

    return (
        <SDv2AddSection
            allowSectionDividers={useSectionDividers && !hideDividers}
            collapsable={disableCollapsable !== true}
            enableSharedSections
            forceOpen={projectSections?.length === 0}
            isDocBuilder={isDocBuilder}
            isIntake={isIntake}
            onAddSection={onAddSection}
            onCancel={unsetAddingSection ? onCancel : null}
            projectSections={projectSections}
            templateSections={templateSections}
        />
    );
};

TemplateEditV2AddSectionMenu.propTypes = {
    disableCollapsable: PropTypes.bool,
    hideDividers: PropTypes.bool,
    // function that accepts as first param a default section type
    // and as second param isTemplate
    setAddingSection: PropTypes.func.isRequired,
    unsetAddingSection: PropTypes.func,
};

import { get } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams, useNavigate, useParams } from 'react-router-dom';
import qs from 'qs';
import { formValueSelector } from 'redux-form';
import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';

import { buildServerRequest, getInitialFormValuesFromQueryParams } from './projectQueryHelper';
import { GovProjectsFilterForm } from './GovProjectsFilterForm';
import { form, fieldNames } from './GovProjectsFilterForm/constants';
import { ProjectsWithLineItemsList } from './ProjectsWithLineItemsList';
import { getDepartments, getFilteredProjects, getProjectsWithLineItemsJS } from '../selectors';
import { getGovernmentJS } from '../../selectors';
import connectData from '../../../ConnectData';
import { resetGovProjectsSearch, searchGovProjects } from '../../../../actions/publicProject';
import { LoadingSpinner, LoadingError } from '../../../../components';
import { ProjectList } from '../../../../components/PublicApp';
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from './constants';

const {
    CATEGORIES,
    DEPARTMENT_ID,
    PROJECT_ID,
    QUICK_SEARCH_QUERY,
    SEARCH_PRICE_ITEMS,
    STATUS,
    TITLE,
} = fieldNames;

function fetchData(getState, dispatch, location, params) {
    const promises = [];

    if (!getState().publicProject.get('searchedGovProjects')) {
        const formDataFromQueryParams = getInitialFormValuesFromQueryParams(
            Object.fromEntries(location.searchParams),
            true
        );
        const requestData = buildServerRequest(formDataFromQueryParams);

        promises.push(dispatch(searchGovProjects(params.governmentCode, false, requestData)));

        if (location.searchParams.get('lineItem') === 'true') {
            promises.push(dispatch(searchGovProjects(params.governmentCode, true, requestData)));
        }
    }

    return Promise.all(promises);
}

const valueSelector = formValueSelector(form);

const ConnectedGovProjectsList = () => {
    const useReactTable8 = useFlags(FLAGS.ENABLE_REACT_TABLE_8);
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const query = Object.fromEntries(searchParams);
    const [currentLineItemSearch, setCurrentLineItemSearch] = useState(null);

    const isLineItemSearchResult = query.lineItem === 'true';
    const government = useSelector(getGovernmentJS);
    const loadError = useSelector((state) => state.publicProject.get('searchGovProjectsError'));
    const loading = useSelector((state) => state.publicProject.get('searchingGovProjects'));
    const projects = useSelector(getFilteredProjects);
    const projectsWithLineItems = useSelector(getProjectsWithLineItemsJS);
    const departmentSelectOptions = useSelector(getDepartments);
    const isLineItemFormActive = useSelector((state) => valueSelector(state, SEARCH_PRICE_ITEMS));

    useEffect(() => {
        setCurrentLineItemSearch(query.lineItemSearch);
    }, [query.lineItemSearch]);

    useEffect(() => {
        return () => {
            dispatch(resetGovProjectsSearch());
        };
    }, []);

    const updateQueryParams = (formData) => {
        const newQuery = {
            lineItem: isLineItemFormActive || undefined,
            lineItemSearch: formData[QUICK_SEARCH_QUERY] || undefined,
            [DEPARTMENT_ID]: formData[DEPARTMENT_ID] || 'all',
            [PROJECT_ID]: formData[PROJECT_ID] || undefined,
            [STATUS]: formData[STATUS] || 'active',
            [TITLE]: formData[TITLE] || undefined,
            [CATEGORIES]:
                formData[CATEGORIES] &&
                Array.isArray(formData[CATEGORIES]) &&
                formData[CATEGORIES].length
                    ? JSON.stringify(formData[CATEGORIES])
                    : undefined,
            page: formData.page || 1,
            limit: formData.limit || DEFAULT_PAGE_SIZE,
            sortField: formData.sortField || undefined,
            sortDirection: formData.sortDirection || undefined,
        };

        navigate({
            pathname: '.',
            search: qs.stringify(newQuery),
        });
    };

    const getPaginationAndOrderFromQuery = () => {
        return {
            page: query.page ? parseInt(query.page, 10) - 1 : 0,
            limit: query.limit ? parseInt(query.limit, 10) : DEFAULT_PAGE_SIZE,
            sortField: query.sortField || 'proposalDeadline',
            sortDirection: query.sortDirection || 'ASC',
        };
    };

    const searchSubmitHandler = (formData, overridePagination = true) => {
        const { governmentCode } = params;

        if (isLineItemFormActive) {
            setCurrentLineItemSearch(get(formData, QUICK_SEARCH_QUERY));
        }

        // when this function is called from onPageChange, onPageSizeChange or onSortChange
        // page, pageSize, sortField and sortDirection will be defined in formData
        // overriding what we have in the query params
        const rawRequestData = { ...getPaginationAndOrderFromQuery(), ...formData };

        // if the form is being submitted, we are searching, go back to the first page
        // but respect page size and sorting which come from the query params (getPaginationAndOrderFromQuery)
        if (overridePagination) {
            rawRequestData.page = 1;
        }

        updateQueryParams(rawRequestData);

        const requestData = buildServerRequest(rawRequestData);

        return dispatch(searchGovProjects(governmentCode, isLineItemFormActive, requestData));
    };

    const onPageChange = (pageIndex) => {
        const formData = { ...getInitialFormValuesFromQueryParams(query), page: pageIndex + 1 };

        searchSubmitHandler(formData, false);
    };

    const onPageSizeChange = (pageSize) => {
        const formData = {
            ...getInitialFormValuesFromQueryParams(query),
            limit: pageSize,
            page: 1,
        };

        searchSubmitHandler(formData, false);
    };

    const onSortChange = ([{ id, desc }]) => {
        const formData = {
            ...getInitialFormValuesFromQueryParams(query),
            sortField: id,
            sortDirection: desc ? 'DESC' : 'ASC',
        };

        searchSubmitHandler(formData, false);
    };

    const renderProjects = () => {
        const { governmentCode } = params;

        if (loading) {
            return <LoadingSpinner />;
        }

        if (loadError) {
            return <LoadingError error={loadError} />;
        }

        if (isLineItemSearchResult) {
            return (
                <ProjectsWithLineItemsList
                    government={government}
                    lineItemSearch={currentLineItemSearch}
                    projects={projectsWithLineItems}
                />
            );
        }

        const { page, limit, sortField, sortDirection } = getPaginationAndOrderFromQuery();
        const sorted = [{ id: sortField, desc: sortDirection === 'DESC' }];

        return (
            <ProjectList
                dataTableProps={
                    !useReactTable8
                        ? {
                              defaultPageSize: DEFAULT_PAGE_SIZE,
                              manual: true,
                              onPageChange,
                              onPageSizeChange,
                              onSortedChange: onSortChange,
                              page,
                              pageSize: limit,
                              pages: Math.ceil(projects.count / limit),
                              pageSizeOptions: PAGE_SIZE_OPTIONS,
                              sorted,
                          }
                        : {
                              manualPagination: true,
                              manualSorting: true,
                              getPageOptions: () => PAGE_SIZE_OPTIONS,
                              onPaginationChange: (changeFunction) => {
                                  const { pageIndex, pageSize } = changeFunction({
                                      pageIndex: page,
                                      pageSize: limit,
                                  });

                                  const formData = {
                                      ...query,
                                      page: pageIndex + 1,
                                      limit: pageSize,
                                  };

                                  searchSubmitHandler(formData, false);
                              },
                              onSortedChange: onSortChange,
                              rowCount: projects.count,
                              usePagination: true,
                              getPaginationRowModel: null,
                              getSortedRowModel: null,
                              state: {
                                  pagination: {
                                      pageIndex: page,
                                      pageSize: limit,
                                  },
                                  sorting: sorted,
                              },
                              showPagination: true,
                          }
                }
                government={government}
                projectPath={`/portal/${governmentCode}/projects`}
                projects={projects.rows}
                showAddendaCount
                showProjectId={get(government, 'isProjectIdRequired')}
            />
        );
    };

    const { isAdvancedSearch, ...initialValues } = getInitialFormValuesFromQueryParams(query);

    return (
        <>
            <GovProjectsFilterForm
                defaultCategorySet={get(government, 'categorySetId')}
                departmentSelectOptions={departmentSelectOptions}
                disabled={loading}
                initialValues={initialValues}
                isAdvancedSearch={isAdvancedSearch}
                isLineItemFormActive={isLineItemFormActive}
                onSubmit={searchSubmitHandler}
            />
            {renderProjects()}
        </>
    );
};

export const GovProjectsList = connectData(fetchData)(ConnectedGovProjectsList);

import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import { Box, Button } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { AddRelationInfo } from '../components';
import { menuStates, qaTagPageName } from '../constants';
import { getSubscriptionInfo } from '../selectors';

const { INTAKE, PROJECT, CONTRACT } = menuStates;

export const RelationsSelect = ({ cancelHandler, selectHandler }) => {
    const { hasContracting, hasIntake, hasSolicitations } = useSelector(getSubscriptionInfo);

    const buttons = [];

    if (hasIntake) {
        buttons.push({ text: 'Intake', menuOption: INTAKE });
    }
    if (hasSolicitations) {
        buttons.push({ text: 'Solicitation', menuOption: PROJECT });
    }
    if (hasContracting) {
        buttons.push({ text: 'Contract', menuOption: CONTRACT });
    }

    return (
        <Box display="flex" flexDirection="column" gap={2}>
            <div>
                <AddRelationInfo />
            </div>
            <Box display="flex" flexDirection="column" gap={1}>
                <Box
                    fontSize={capitalDesignTokens.foundations.typography.fontSize.bodyDefault}
                    fontWeight={capitalDesignTokens.foundations.typography.fontWeight.medium}
                >
                    Choose What to Search By
                </Box>
                {buttons.map(({ menuOption, text }) => (
                    <Button
                        fullWidth
                        key={text}
                        onClick={() => selectHandler(menuOption)}
                        qaTag={`${qaTagPageName}-search${menuOption}`}
                        size="large"
                        variant="outlined"
                    >
                        {text}
                    </Button>
                ))}
            </Box>
            <div>
                <Button
                    color="secondary"
                    onClick={cancelHandler}
                    qaTag={`${qaTagPageName}-cancel`}
                    size="small"
                    variant="text"
                >
                    Cancel
                </Button>
            </div>
        </Box>
    );
};

RelationsSelect.propTypes = {
    cancelHandler: PropTypes.func.isRequired,
    selectHandler: PropTypes.func.isRequired,
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';

export class SubmitButtonPopover extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        submitting: PropTypes.bool,
        bsStyle: PropTypes.string,
        bsSize: PropTypes.string,
        className: PropTypes.string,
        popoverBody: PropTypes.string,
        popoverTitle: PropTypes.string,
        submitText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        text: PropTypes.node.isRequired,
        usePopover: PropTypes.bool,
        ignoreFirstClick: PropTypes.bool,
        onClick: PropTypes.func,
    };

    static defaultProps = {
        ignoreFirstClick: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            wasClicked: false,
        };
    }

    handleClick = () => {
        // Used by Popover to handle ignoreFirstClick

        const { wasClicked } = this.state;
        const { ignoreFirstClick } = this.props;

        if (!ignoreFirstClick || (ignoreFirstClick && wasClicked)) {
            this.props.onClick();
        }
        this.setState({ wasClicked: true });
    };

    renderWithPopover() {
        const {
            popoverTitle,
            popoverBody,
            submitText,
            text,
            submitting,
            disabled,
            className,
            bsStyle,
            bsSize,
        } = this.props;

        return (
            <OverlayTrigger
                id="submit-button-popover"
                overlay={
                    // an id has to be included otherwise a
                    // blind person cannot read it
                    <Popover id="submitwarn" title={popoverTitle}>
                        {popoverBody}
                    </Popover>
                }
                placement="top"
                rootClose
                trigger="click"
            >
                <Button
                    bsSize={bsSize || 'large'}
                    bsStyle={bsStyle || 'primary'}
                    className={className}
                    disabled={disabled}
                    onClick={this.handleClick}
                >
                    {submitting ? submitText : text}
                </Button>
            </OverlayTrigger>
        );
    }

    renderButton() {
        const { submitText, text, onClick, submitting, disabled, className, bsSize, bsStyle } =
            this.props;

        return (
            <Button
                bsSize={bsSize || 'large'}
                bsStyle={bsStyle || 'primary'}
                className={className}
                disabled={disabled}
                onClick={onClick}
            >
                {submitting ? submitText : text}
            </Button>
        );
    }

    render() {
        return this.props.usePopover ? this.renderWithPopover() : this.renderButton();
    }
}

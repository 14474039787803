import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { ContractChecklists } from './ContractChecklists';
import { ContractDocBuilderDocuments } from './ContractDocBuilderDocuments';
import { ContractDocuments } from './ContractDocuments';
import { ContractInfo } from './ContractInfo';
import { ContractInternalData } from './ContractInternalData';
import { ContractMilestones } from './ContractMilestones';
import { ContractPerformance } from './ContractPerformance';
import { GovernmentPendingDocuments } from './GovernmentPendingDocuments';
import { VendorPendingDocuments } from './VendorPendingDocuments';
import { isContractEditor } from '../../containers/GovApp/selectors';
import { getContractJS } from '../../containers/selectors';
import { useScrollToHashAnchorElement } from '../../helpers';

export const ContractDetails = ({ isAwardedVendor, isPublicView, timezone }) => {
    const isEditor = useSelector(isContractEditor);
    const contract = useSelector(getContractJS);

    useScrollToHashAnchorElement();

    const {
        government: { hideContractReview },
    } = contract;

    return (
        <div>
            <ContractInfo
                contract={contract}
                isEditor={isEditor}
                isPublicView={isPublicView}
                timezone={timezone}
            />
            {!isPublicView && <ContractDocBuilderDocuments contract={contract} />}
            <ContractDocuments
                contract={contract}
                isAwardedVendor={isAwardedVendor}
                isEditor={isEditor}
                isPublicView={isPublicView}
            />
            {!isPublicView && isEditor && <GovernmentPendingDocuments />}
            {isAwardedVendor && <VendorPendingDocuments />}
            {isAwardedVendor && <ContractChecklists contract={contract} />}
            {!isPublicView && <ContractMilestones contract={contract} timezone={timezone} />}
            {!isPublicView && !hideContractReview && <ContractPerformance />}
            {!isPublicView && <ContractInternalData contract={contract} />}
        </div>
    );
};

ContractDetails.propTypes = {
    isAwardedVendor: PropTypes.bool,
    isPublicView: PropTypes.bool,
    timezone: PropTypes.string.isRequired,
};

import React from 'react';
import classnames from 'classnames';
import { Box } from '@og-pro/ui';

import { SharedTextareaToolbar } from '../SharedTextareaToolbar';
import { PROP_TYPES } from './types';

export const Content = ({
    header,
    content,
    footer,
    showSharedTextareaToolbar = false,
    children,
}) => {
    const styles = require('./index.scss');

    return (
        <Box className={styles.mainContentContainer} display="flex" flexDirection="column">
            {!!children && children}
            <div className={styles.header}>{header}</div>
            {showSharedTextareaToolbar && <SharedTextareaToolbar />}
            <Box
                className={classnames(styles.content, { [styles.emptyContent]: !content })}
                flex={1}
            >
                {content && <div className={styles.contentBlock}>{content}</div>}
            </Box>
            {footer && <div className={styles.footer}>{footer}</div>}
        </Box>
    );
};
Content.propTypes = PROP_TYPES;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Panel } from 'react-bootstrap';
import { Typography } from '@og-pro/ui';

export const SimpleCountPanel = ({ clickHandler, count, title }) => {
    const styles = require('./index.scss');
    const clickable = count > 0 && clickHandler;

    return (
        <Panel
            className={classnames(styles.simpleCountPanel, clickable && styles.clickable)}
            onClick={clickable ? clickHandler : null}
        >
            <Panel.Body>
                <Typography component="h2" variant="h4">
                    {title}
                </Typography>
                <Typography component="h3" variant="h1">
                    {count}
                </Typography>
            </Panel.Body>
        </Panel>
    );
};

SimpleCountPanel.propTypes = {
    clickHandler: PropTypes.func,
    count: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
};

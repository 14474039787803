import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { useNavigate } from 'react-router-dom';

import { checklistStatusTypes } from '@og-pro/shared-config/checklists';

import { checklistQuestionTypesDisplay } from '@og-pro/shared-config/questionnaires';

import { fieldNames, form } from './constants';
import { MenuButtons } from './MenuButtons';
import { getChecklistFormValues } from './selectors';
import { validate } from './validate';
import { ContractChecklistTitleMenu } from '../ContractChecklistTitleMenu';
import { getInvitedUsersSelectOptions } from '../../../selectors';
import { deleteChecklist, updateChecklist } from '../../../../actions/checklists';
import { showConfirmationSimpleModal } from '../../../../actions/confirmation';
import {
    QuestionnairesForm,
    RouteLeaveWarning,
    SearchSelect,
    SearchSelectUserOption,
} from '../../../../components';

const { QUESTIONNAIRES, USER_ID } = fieldNames;

const { RELEASED } = checklistStatusTypes;

const formConfig = {
    enableReinitialize: true,
    form,
    validate,
};

const ConnectedContractChecklistForm = ({
    array,
    change,
    checklist,
    checklist: { id },
    contractChecklistsPath,
    dirty,
    handleSubmit,
    isEditor,
    submitFailed,
}) => {
    const navigate = useNavigate();
    const [deleting, setDeleting] = useState(false);
    const [deleteError, setDeleteError] = useState(null);
    const [updating, setUpdating] = useState(false);
    const [updateError, setUpdateError] = useState(null);
    const [showFormValidation, setShowFormValidation] = useState(false);

    const formValues = useSelector(getChecklistFormValues);
    const userOptions = useSelector(getInvitedUsersSelectOptions);

    const dispatch = useDispatch();

    const disabled = updating || deleting;

    const updateChecklistHandler = (data, isReleasing) => {
        const submitData = data || formValues;
        setUpdating(true);
        setUpdateError(null);
        return dispatch(updateChecklist(id, submitData, isReleasing && 'Checklist released!'))
            .then(() => {
                if (!isReleasing) {
                    setUpdating(false);
                }
            })
            .catch((error) => {
                setUpdating(false);
                setUpdateError(error.message);
            });
    };

    const saveChecklistHandler = () => {
        return updateChecklistHandler(formValues);
    };

    const releaseChecklistHandler = () => {
        setShowFormValidation(true);
        return handleSubmit((formData) => {
            const data = {
                ...formData,
                status: RELEASED,
            };
            setShowFormValidation(false);
            dispatch(
                showConfirmationSimpleModal(() => updateChecklistHandler(data, true), {
                    btnText: 'Release Checklist',
                    bsStyle: 'success',
                    icon: 'send',
                    text:
                        'Are you sure you want to release this checklist?\n' +
                        'Vendor will be notified that checklist is ready to complete\n' +
                        'You will not be able to edit the checklist once released.',
                })
            );
        })();
    };

    const deleteChecklistHandler = () => {
        const deleteHandler = () => {
            setDeleting(true);
            setDeleteError(null);
            // Delete template and then route back to checklist list page
            return dispatch(deleteChecklist(id))
                .then(() => {
                    navigate(contractChecklistsPath, { replace: true });
                })
                .catch((error) => {
                    setDeleting(false);
                    setDeleteError(error.message);
                });
        };

        dispatch(
            showConfirmationSimpleModal(deleteHandler, {
                btnText: 'Delete Checklist',
                text:
                    'Are you sure you want to delete this checklist?\n' +
                    'This action cannot be undone.',
            })
        );
    };

    const { questionnaires = [] } = formValues;

    const errorMessage =
        (submitFailed && 'Please fix form errors before submitting') || updateError || deleteError;

    const menuButtons = (
        <MenuButtons
            deleteChecklist={deleteChecklistHandler}
            deleting={deleting}
            disabled={disabled}
            errorMessage={errorMessage}
            isEditor={isEditor}
            releaseChecklist={releaseChecklistHandler}
            saveChecklist={saveChecklistHandler}
            setShowFormValidation={setShowFormValidation}
            updating={updating}
        />
    );

    return (
        <>
            <RouteLeaveWarning blockingValue={dirty} />
            <ContractChecklistTitleMenu
                checklist={checklist}
                contractChecklistsPath={contractChecklistsPath}
                menuButtons={menuButtons}
            />
            <div className="row">
                <div className="col-sm-5">
                    <Field
                        backspaceRemovesValue={false}
                        component={SearchSelect}
                        components={{
                            Option: SearchSelectUserOption,
                        }}
                        disabled={disabled}
                        label="Checklist Owner"
                        name={USER_ID}
                        options={userOptions}
                        placeholder="Select the checklist owner"
                        showValidation={showFormValidation}
                    />
                </div>
            </div>
            <QuestionnairesForm
                array={array}
                change={change}
                disabled={disabled}
                formKey={QUESTIONNAIRES}
                questionTypes={checklistQuestionTypesDisplay}
                questionnaires={questionnaires}
                rerenderOnEveryChange
                showFormErrors={showFormValidation}
            />
        </>
    );
};

ConnectedContractChecklistForm.propTypes = {
    array: PropTypes.object.isRequired,
    change: PropTypes.func.isRequired,
    checklist: PropTypes.shape({
        id: PropTypes.number.isRequired,
        template: PropTypes.shape({
            title: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    contractChecklistsPath: PropTypes.string.isRequired,
    dirty: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isEditor: PropTypes.bool.isRequired,
    submitFailed: PropTypes.bool.isRequired,
};

export const ContractChecklistForm = reduxForm(formConfig)(ConnectedContractChecklistForm);

export const SHOW_MODAL = 'gov/permissions/SHOW_MODAL';
export const HIDE_MODAL = 'gov/permissions/HIDE_MODAL';
export const RESET = 'gov/permissions/RESET';
export const ADD_PERMISSION = 'gov/permissions/ADD_PERMISSION';
export const ADD_PERMISSION_SUCCESS = 'gov/permissions/ADD_PERMISSION_SUCCESS';
export const ADD_PERMISSION_FAIL = 'gov/permissions/ADD_PERMISSION_FAIL';
export const REMOVE_PERMISSION = 'gov/permissions/REMOVE_PERMISSION';
export const REMOVE_PERMISSION_SUCCESS = 'gov/permissions/REMOVE_PERMISSION_SUCCESS';
export const REMOVE_PERMISSION_FAIL = 'gov/permissions/REMOVE_PERMISSION_FAIL';

export function showInviteModal(projectId) {
    return (dispatch) => {
        dispatch({ type: RESET });
        return dispatch({ type: SHOW_MODAL, projectId });
    };
}

export function hideInviteModal() {
    return { type: HIDE_MODAL };
}

export function addPermission(projectId, data) {
    return (dispatch, getState, client) => {
        dispatch({ type: ADD_PERMISSION });
        return client
            .post(`/project/${projectId}/invite`, { data })
            .then((result) => {
                dispatch({ type: ADD_PERMISSION_SUCCESS, result, projectId });
            })
            .catch((error) => dispatch({ type: ADD_PERMISSION_FAIL, error }));
    };
}

export function removePermission(projectId, data) {
    return (dispatch, getState, client) => {
        dispatch({ type: REMOVE_PERMISSION });
        return client
            .post(`/project/${projectId}/uninvite`, { data })
            .then((result) => {
                dispatch({ type: REMOVE_PERMISSION_SUCCESS, result, projectId });
            })
            .catch((error) => dispatch({ type: REMOVE_PERMISSION_FAIL, error }));
    };
}

import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { bulkUpdateFieldNames } from '../../constants';
import { SearchSelect, SearchSelectUserOption, Toggle } from '../../../../../components';

const {
    PROJECTS_ENABLED,
    INTAKES_ENABLED,
    CONTRACTS_ENABLED,
    PROJECTS_PROCUREMENT_CONTACT,
    PROJECTS_CONTACT,
    INTAKES_PROCUREMENT_CONTACT,
    INTAKES_CONTACT,
    CONTRACTS_PROCUREMENT_CONTACT,
    CONTRACTS_CONTACT,
} = bulkUpdateFieldNames;

export const BulkUpdateCollapsibleFormSection = ({ collapsed, name, onChange, selectOptions }) => {
    const styles = require('./index.scss');

    const getSectionLabel = () => {
        switch (name) {
            case PROJECTS_ENABLED:
                return 'Projects';
            case INTAKES_ENABLED:
                return 'Intakes';
            case CONTRACTS_ENABLED:
                return 'Contracts';
            default:
                return '';
        }
    };

    const getProcurementContactFieldName = () => {
        switch (name) {
            case PROJECTS_ENABLED:
                return PROJECTS_PROCUREMENT_CONTACT;
            case INTAKES_ENABLED:
                return INTAKES_PROCUREMENT_CONTACT;
            case CONTRACTS_ENABLED:
                return CONTRACTS_PROCUREMENT_CONTACT;
            default:
                return '';
        }
    };

    const getContactFieldName = () => {
        switch (name) {
            case PROJECTS_ENABLED:
                return PROJECTS_CONTACT;
            case INTAKES_ENABLED:
                return INTAKES_CONTACT;
            case CONTRACTS_ENABLED:
                return CONTRACTS_CONTACT;
            default:
                return '';
        }
    };

    const getContactLabel = () => {
        switch (name) {
            case PROJECTS_ENABLED:
                return 'Project Contact';
            case INTAKES_ENABLED:
                return 'Project Contact';
            case CONTRACTS_ENABLED:
                return 'Contract Contact';
            default:
                return '';
        }
    };

    return (
        <div className={!collapsed ? styles.dropdownContainer : undefined}>
            <Field
                component={Toggle}
                label={<span className={styles.user}>{getSectionLabel()}</span>}
                labelInline
                name={name}
                onChange={onChange}
                qaTag={`bulkUpdateContactsModal-toggle${getSectionLabel()}`}
            />
            {!collapsed && (
                <>
                    <Field
                        component={SearchSelect}
                        components={{
                            Option: SearchSelectUserOption,
                        }}
                        disabled={collapsed}
                        isClearable
                        label="Procurement Contact"
                        name={getProcurementContactFieldName()}
                        options={selectOptions}
                    />
                    <Field
                        component={SearchSelect}
                        components={{
                            Option: SearchSelectUserOption,
                        }}
                        disabled={collapsed}
                        isClearable
                        label={getContactLabel()}
                        name={getContactFieldName()}
                        options={selectOptions}
                    />
                </>
            )}
        </div>
    );
};

BulkUpdateCollapsibleFormSection.propTypes = {
    collapsed: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    selectOptions: PropTypes.array.isRequired,
};

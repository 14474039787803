import { listToDict } from '@og-pro/shared-config/helpers';

export const form = 'requisitionRejectionNoteForm';

export const fields = ['rejectionNote', 'rejectionNoteExtra', 'rejectionPolicy'];

export const fieldNames = listToDict(fields);

export const OTHER = 'other';

export const rejectionNoteOptions = [
    {
        label: 'Over budget',
        value: 'Over budget',
    },
    {
        label: 'Incorrect account codes',
        value: 'Incorrect account codes',
    },
    {
        label: 'Duplicate request',
        value: 'Duplicate request',
    },
    {
        label: 'Other',
        value: OTHER,
    },
];

import PropTypes from 'prop-types';
import React from 'react';

import { questionTypesDisplayMap } from '@og-pro/shared-config/questionnaires';

import { TemplateListItemProjects } from '../components';
import {
    TemplateListTimestampColumns,
    TemplateStatusLabel,
    UserProfilePicture,
} from '../../../../components';

export const TemplateQuestionsListItem = ({
    templateQuestion: {
        created_at: createdAt,
        isPublished,
        lastUpdatedAt,
        projects,
        upfrontQuestion: { title, type },
        user,
    },
    templatesAdminPath,
}) => {
    const styles = require('./index.scss');

    return (
        <div className="row">
            <div className="col-md-9">
                <div className="row">
                    <div className="col-md-5">
                        <span className={styles.templateTitle}>
                            <i className={`fa fa-fw fa-${questionTypesDisplayMap[type].icon}`} />
                            {title || 'Untitled'}
                        </span>
                        <TemplateStatusLabel
                            className={styles.statusLabel}
                            isPublished={isPublished}
                        />
                        <div className={styles.typeText}>{questionTypesDisplayMap[type].title}</div>
                    </div>
                    <div className="col-md-3 hidden-xs hidden-sm">
                        {user && (
                            <>
                                <UserProfilePicture horizontal user={user} />
                                &nbsp;&nbsp;
                                {user.displayName}
                            </>
                        )}
                    </div>
                    <div className="col-md-4 hidden-xs hidden-sm">
                        <TemplateListItemProjects
                            projects={projects}
                            templatesAdminPath={templatesAdminPath}
                        />
                    </div>
                </div>
            </div>
            <div className="col-md-3 hidden-xs hidden-sm">
                <TemplateListTimestampColumns createdAt={createdAt} lastUpdatedAt={lastUpdatedAt} />
            </div>
        </div>
    );
};

TemplateQuestionsListItem.propTypes = {
    templateQuestion: PropTypes.shape({
        created_at: PropTypes.string.isRequired,
        isPublished: PropTypes.bool.isRequired,
        lastUpdatedAt: PropTypes.string.isRequired,
        projects: PropTypes.array.isRequired,
        upfrontQuestion: PropTypes.shape({
            title: PropTypes.string,
            type: PropTypes.string.isRequired,
        }).isRequired,
        user: PropTypes.shape({
            displayName: PropTypes.string.isRequired,
        }),
    }).isRequired,
    templatesAdminPath: PropTypes.string.isRequired,
};

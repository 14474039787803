import React from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { ContractComplaintModal } from '../components';
import { hideContractComplaintModal } from '../../../../actions/contracts';

export const ContractReviewsNav = () => {
    const dispatch = useDispatch();
    const showContractComplaintModal = useSelector((state) =>
        state.contracts.get('showContractComplaintModal')
    );

    const hideModal = () => {
        dispatch(hideContractComplaintModal());
    };

    return (
        <>
            <Outlet />
            {showContractComplaintModal && <ContractComplaintModal hideModal={hideModal} />}
        </>
    );
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Navbar } from 'react-bootstrap';
import Sticky from 'react-stickynode';

export class ActionToolbar extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        className: PropTypes.string,
    };

    render() {
        const { children, className } = this.props;

        // `navbarToggle` class makes the toggle button conform to btn-sm, so
        // the height of the navbar does not change when collapsed
        const styles = require('./ActionToolbar.scss');
        return (
            <div className={styles.navbarContainer}>
                <Sticky>
                    <Navbar className={className} collapseOnSelect fluid staticTop>
                        <Navbar.Header>
                            <Navbar.Toggle className={styles.navbarToggle}>
                                Project Actions <i className="fa fa-caret-down" />
                            </Navbar.Toggle>
                        </Navbar.Header>
                        <Navbar.Collapse>{children}</Navbar.Collapse>
                    </Navbar>
                </Sticky>
            </div>
        );
    }
}

/**
 * @typedef {'del'|'get'|'post'|'put'} HTTPMethods
 */

/**
 * @callback fetchResource
 * @param {Function} dispatch The event dispatcher
 * @param {Function} getState The state getter
 * @param {object} client The SuperAgent client
 * @return {Promise} The resource request
 */

/**
 * Makes a network request using the provided configuration. Uses the configuration and any other
 * passed data to call success and failure handlers.
 * @param {object} options The type of HTTP request to make
 * @param {HTTPMethods} options.method The type of HTTP request to make
 * @param {string} options.url The URL to make the request to
 * @param {object|Function} [options.requestOptions] Options or function to generate options for the request
 * @param {Function} [options.onStart] A function called before the request has been made
 * @param {Function} [options.onSuccess] A function to be called if the request is successful
 * @param {Function} [options.onFailure] A function to be called if the request fails
 * @param {object} [options.context={}] Additional data to pass through to each part of the request
 * @return {fetchResource} The built resource fetcher
 */
export const resourceManager = ({
    method,
    url,
    requestOptions,
    onStart,
    onSuccess,
    onFailure,
    context = {},
}) => {
    return (dispatch, getState, client) => {
        const resourceData = {
            client,
            context,
            dispatch,
            getState,
        };

        if (onStart) {
            onStart(resourceData);
        }

        const options =
            typeof requestOptions === 'function' ? requestOptions(resourceData) : requestOptions;

        return client[method](url, options)
            .then((result) => {
                if (onSuccess) {
                    return onSuccess({ ...resourceData, result });
                }
                return result;
            })
            .catch((error) => {
                if (onFailure) {
                    return onFailure({ ...resourceData, error });
                }
                throw error;
            });
    };
};

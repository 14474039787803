import { includes } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { projectStatusesDict } from '@og-pro/shared-config/projects';

import { Dropdown, MenuItem } from '..';
import {
    COPY_LINK,
    DELETE,
    DELETE_EVALUATION,
    DELETE_POST,
    DELETE_INTAKE,
    REOPEN_BUILDER,
    REOPEN_PROJECT_INTAKE,
    SUBSCRIBE,
    TOGGLE_COMMENTS,
    UNSUBSCRIBE,
} from '../../constants/menuActions';

const { DRAFT, EVALUATION, POST_PENDING, REQUEST_DRAFT, REQUEST_REVIEW, REVIEW } =
    projectStatusesDict;

export class ProjectMenuDropdown extends PureComponent {
    static propTypes = {
        actionHandler: PropTypes.func.isRequired,
        bsSize: PropTypes.string,
        className: PropTypes.string,
        icon: PropTypes.string,
        isBuilder: PropTypes.bool,
        isEvaluation: PropTypes.bool,
        isGlobalEditor: PropTypes.bool,
        isLibrary: PropTypes.bool,
        isOwner: PropTypes.bool,
        isIntake: PropTypes.bool,
        isSourcing: PropTypes.bool,
        noCaret: PropTypes.bool,
        project: PropTypes.shape({
            isPostOnly: PropTypes.bool.isRequired,
            intake_id: PropTypes.number,
            status: PropTypes.string.isRequired,
            wasPosted: PropTypes.bool.isRequired,
        }).isRequired,
        showComments: PropTypes.bool,
        subscribed: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        icon: 'list-ul',
    };

    get deleteItem() {
        const {
            isBuilder,
            isEvaluation,
            isGlobalEditor,
            isOwner,
            isIntake,
            isSourcing,
            project: { isPostOnly, intake_id: intakeId, status },
        } = this.props;

        if (!isOwner && !isGlobalEditor) {
            return null;
        }

        if (isIntake && includes([REQUEST_DRAFT, REQUEST_REVIEW], status)) {
            return { action: DELETE_INTAKE };
        }
        if (isBuilder && includes([DRAFT, REVIEW], status)) {
            if (intakeId) {
                return { action: REOPEN_PROJECT_INTAKE };
            }
            return { action: DELETE };
        }
        if (isSourcing && includes([POST_PENDING], status)) {
            if (isPostOnly) {
                return { action: DELETE_POST };
            }
            return {
                action: REOPEN_BUILDER,
                icon: 'undo',
                text: 'Reopen Project Builder',
            };
        }
        if (isEvaluation && includes([EVALUATION], status)) {
            return { action: DELETE_EVALUATION };
        }

        return null;
    }

    stopPropagation = (e) => {
        e.stopPropagation();
    };

    subscribeMenuItem() {
        const { subscribed } = this.props;
        if (subscribed) {
            return this.renderMenuItem('Unfollow', 'rss', UNSUBSCRIBE);
        }
        return this.renderMenuItem('Follow', 'rss', SUBSCRIBE);
    }

    renderCommentsToggleItem() {
        const { showComments } = this.props;
        const menuText = showComments ? 'Hide comments' : 'Show comments';
        return this.renderMenuItem(menuText, 'comment-o', TOGGLE_COMMENTS);
    }

    renderMenuItem(menuText, icon, action) {
        return (
            <MenuItem
                key={action}
                onClick={() => this.props.actionHandler(action)}
                qaTag={`project-${menuText}`}
            >
                <i className={`fa fa-${icon} fa-fw`} /> {menuText}
            </MenuItem>
        );
    }

    renderMenuItems() {
        const { isBuilder, isIntake, project } = this.props;

        const items = [this.subscribeMenuItem()];

        // Show comments toggler if requested
        if (isBuilder || isIntake) {
            items.push(this.renderCommentsToggleItem());
        }

        if (project.wasPosted) {
            items.push(this.renderMenuItem('Get Shareable Link', 'link', COPY_LINK));
        }

        if (this.deleteItem) {
            const { action, icon = 'trash', text = 'Delete' } = this.deleteItem;

            items.push(this.renderMenuItem(text, icon, action));
        }

        return items;
    }

    render() {
        const { bsSize, className, icon, isLibrary, noCaret } = this.props;

        if (isLibrary) {
            return null;
        }

        const menuItems = this.renderMenuItems();

        return (
            <Dropdown
                bsSize={bsSize}
                className={`${className} no-print`}
                disabled={menuItems.length === 0}
                id="project-menu-dropdown"
                onClick={this.stopPropagation}
                pullRight
                qaTag="builder-projectMenu"
            >
                <Dropdown.Toggle noCaret={noCaret} title="Project Menu">
                    <i className={`fa fa-lg fa-${icon}`} />
                </Dropdown.Toggle>
                <Dropdown.Menu>{menuItems}</Dropdown.Menu>
            </Dropdown>
        );
    }
}

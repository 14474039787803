import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import { DragIcon } from '../../../../../components';

export const SectionDividerDisplay = ({
    icon,
    projectSection: { manualNumber, orderById, title },
    showErrors,
    twoColumnLayout,
    useManualNumbering,
}) => {
    const styles = require('./index.scss');
    const {
        dragIcon,
        isTwoColumnLayout,
        sectionInfoContainer,
        sectionTitle,
        sectionType,
        textDisplay,
    } = styles;

    return (
        <>
            <div
                className={classnames(
                    twoColumnLayout ? 'col-xs-12' : 'col-xs-10',
                    showErrors && 'text-danger'
                )}
            >
                <div className={sectionInfoContainer}>
                    <div className={classnames(sectionTitle, twoColumnLayout && isTwoColumnLayout)}>
                        {showErrors && (
                            <span>
                                <i className="fa fa-exclamation-triangle" />
                                &nbsp;
                            </span>
                        )}
                        {useManualNumbering && manualNumber !== null && (
                            <span className={textDisplay}>{manualNumber}&nbsp;&nbsp;&nbsp;</span>
                        )}
                        <span className={textDisplay}>{title}</span>
                    </div>
                    <div className={sectionType}>
                        <i className={`fa fa-fw fa-${icon}`} />
                        &nbsp;
                        <span className={textDisplay}>Divider</span>
                    </div>
                </div>
            </div>
            {!twoColumnLayout && orderById !== 1 && !useManualNumbering && (
                <div className={classnames('col-xs-2 text-center', dragIcon)}>
                    <DragIcon />
                </div>
            )}
        </>
    );
};

SectionDividerDisplay.propTypes = {
    icon: PropTypes.string.isRequired,
    projectSection: PropTypes.shape({
        manualNumber: PropTypes.string,
        orderById: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
    }).isRequired,
    showErrors: PropTypes.bool.isRequired,
    twoColumnLayout: PropTypes.bool,
    useManualNumbering: PropTypes.bool.isRequired,
};

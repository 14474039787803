import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export class ReviewError extends PureComponent {
    static propTypes = {
        meta: PropTypes.shape({
            error: PropTypes.string,
            touched: PropTypes.bool.isRequired,
        }).isRequired,
        showValidation: PropTypes.bool,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const {
            meta: { error, touched },
            showValidation,
        } = this.props;

        const displayError = (touched || showValidation) && !!error;

        if (displayError) {
            return <div className={this.styles.errorMsg}>{error}</div>;
        }
        return null;
    }
}

import PropTypes from 'prop-types';
import React from 'react';
import { Box, Tooltip } from '@og-pro/ui';

export const ArrayCellRenderer = ({ data, tooltipTitle, value }) => {
    if (!Array.isArray(value)) {
        return null;
    }

    const renderArray = () => {
        const text = value.filter((val) => val !== null).join(', ');
        return (
            <Box minWidth={0}>
                <Tooltip title={tooltipTitle || text}>
                    <Box overflow="hidden" textOverflow="ellipsis">
                        {text}
                    </Box>
                </Tooltip>
            </Box>
        );
    };

    return data?.expanded ? (
        <Box pt={0.5}>{renderArray()}</Box>
    ) : (
        <Box alignItems="center" display="flex" height="100%">
            {renderArray()}
        </Box>
    );
};

ArrayCellRenderer.propTypes = {
    data: PropTypes.object,
    tooltipTitle: PropTypes.string,
    value: PropTypes.array,
};

import React from 'react';
import { Box, Button, IconButton, Alert, Typography } from '@og-pro/ui';
import {
    Download as DownloadIcon,
    CheckCircle as CheckCircleIcon,
    DoNotDisturb as DoNotDisturbIcon,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { AgGridReact } from '../../../../components';
import { RejectionReasonModal } from '../../../../components/ContractDetails/GovernmentPendingDocuments/RejectionReasonModal';
import { contractAttachmentApproval } from '../../../../actions/contracts';
import { getContractJS } from '../../../selectors';
import { showConfirmationSimpleModal } from '../../../../actions/confirmation';

const PendingAttachments = () => {
    const styles = require('../index.scss');
    const gridApiRef = React.useRef(null);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [attachmentToReject, setAttachmentToReject] = React.useState(null);
    const contract = useSelector(getContractJS);
    const dispatch = useDispatch();
    const rows = contract.pendingAttachments;

    const approvalHandler = (attachmentId, isApproved, data) => {
        setIsSubmitting(true);
        setAttachmentToReject(null);
        dispatch(
            contractAttachmentApproval(contract.id, attachmentId, { isApproved, ...data })
        ).finally(() => {
            setIsSubmitting(false);
        });
    };

    const approveAttachment = (attachmentId) => () => {
        dispatch(
            showConfirmationSimpleModal(
                () => {
                    approvalHandler(attachmentId, true);
                },
                {
                    useOpenGovStyle: true,
                }
            )
        );
    };

    const columns = [
        {
            field: 'type',
            headerName: 'Document Type',
            width: 200,
            cellRendererFramework: (params) => params.data.tags.map((tag) => tag.name).join(', '),
        },
        {
            field: 'created_at',
            headerName: 'Date Added',
            width: 168,
            cellRendererFramework: (params) =>
                params.value !== null
                    ? new Date(params.value).toLocaleDateString('en-us', { timeZone: 'UTC' })
                    : null,
        },
        {
            field: 'filename',
            headerName: 'File Name',
            width: 300,
        },
        {
            headerName: 'Notes from Vendor',
            flex: 1,
            minWidth: 300,
            cellRendererFramework: () => {
                return <div>Not implemented</div>;
            },
        },
        {
            cellClass: styles.iconCells,
            cellRendererFramework: (params) => (
                <Button
                    disabled={isSubmitting}
                    onClick={approveAttachment(params.data.id)}
                    qaTag="contractDocuments-approvePendingAttachment"
                    sx={{
                        color: capitalDesignTokens.foundations.colors.pear700,
                        gap: '4px',
                    }}
                >
                    <CheckCircleIcon fontSize="small" /> Approve
                </Button>
            ),
            width: 103,
        },
        {
            cellClass: styles.iconCells,
            cellRendererFramework: (params) => (
                <Button
                    disabled={isSubmitting}
                    onClick={() => setAttachmentToReject(params.data)}
                    qaTag="contractDocuments-rejectPendingAttachment"
                    sx={{
                        color: capitalDesignTokens.foundations.colors.ruby700,
                        gap: '4px',
                    }}
                >
                    <DoNotDisturbIcon fontSize="small" /> Reject
                </Button>
            ),
            width: 91,
        },
        {
            cellClass: styles.iconCells,
            cellRendererFramework: (params) => (
                <IconButton
                    component="a"
                    download
                    href={params.data?.url}
                    qaTag="contractDocuments-downloadPendingAttachment"
                >
                    <DownloadIcon />
                </IconButton>
            ),
            width: 40,
        },
    ];

    const defaultColDef = {
        editable: false,
        suppressMenu: true,
        resizable: false,
        headerClass: styles.headerWhite,
        cellClass: styles.cell,
    };

    return (
        <Box>
            {rows.length > 0 && (
                <Box>
                    <Alert color="red" severity="warning" sx={{ borderRadius: '4px 4px 0px 0px' }}>
                        <Typography
                            color={capitalDesignTokens.foundations.colors.gray1000}
                            variant="h3"
                        >
                            Documents Pending Approval
                        </Typography>
                        <Typography
                            color={capitalDesignTokens.foundations.colors.gray800}
                            fontWeight={400}
                        >
                            Manage documents uploaded by vendors by approving or rejecting them.
                        </Typography>
                    </Alert>
                    <Box sx={{ my: 2, mx: 3 }}>
                        <AgGridReact
                            columns={columns}
                            defaultColDef={defaultColDef}
                            domLayout="autoHeight"
                            getRowNodeId={(data) => data.id}
                            hideSideBar
                            onGridReady={(params) => {
                                gridApiRef.current = params.api;
                            }}
                            rowHeight={52}
                            rows={rows}
                            useOpenGovStyle
                        />
                    </Box>
                </Box>
            )}
            {attachmentToReject && (
                <RejectionReasonModal
                    onHide={() => setAttachmentToReject(null)}
                    onSubmit={(data) => approvalHandler(attachmentToReject.id, false, data)}
                />
            )}
        </Box>
    );
};

export default PendingAttachments;

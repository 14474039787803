import {
    Inventory as InventoryIcon,
    List as ListIcon,
    Search as SearchIcon,
} from '@mui/icons-material';
import { Box, Button, InputAdornment, TextField } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { RequestTypeCreateButton } from './RequestTypeCreateButton';
import { qaTagName } from '../constants';

export const RequestTypesMenu = ({ setSearchValue, setShowArchived, showArchived }) => {
    return (
        <Box sx={{ display: 'flex', gap: 2 }}>
            <Box sx={{ flexGrow: 1 }}>
                <TextField
                    InputProps={{
                        startAdornment: (
                            <InputAdornment aria-label="search icon" position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    fullWidth
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder="Search by Name"
                    type="search"
                />
            </Box>
            <Box sx={{ flexGrow: 1, textAlign: 'right' }}>
                <Button
                    color="secondary"
                    onClick={() => setShowArchived(!showArchived)}
                    qaTag={`${qaTagName}-archive`}
                    startIcon={showArchived ? <ListIcon /> : <InventoryIcon />}
                    sx={{ marginRight: 1 }}
                    variant="text"
                >
                    View {showArchived ? 'Active' : 'Archived'}
                </Button>
                <RequestTypeCreateButton />
            </Box>
        </Box>
    );
};

RequestTypesMenu.propTypes = {
    setSearchValue: PropTypes.func.isRequired,
    setShowArchived: PropTypes.func.isRequired,
    showArchived: PropTypes.bool.isRequired,
};

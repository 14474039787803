import { createSelector } from 'reselect';

import {
    hasContractingSubscription,
    hasEvaluationSubscription,
    hasIntakeSubscription,
    hasSourcingSubscription,
} from '../../../../../GovApp/selectors';

export const getSubscriptionInfo = createSelector(
    [
        hasIntakeSubscription,
        hasSourcingSubscription,
        hasEvaluationSubscription,
        hasContractingSubscription,
    ],
    (hasIntake, hasSourcing, hasEvaluation, hasContracting) => {
        return {
            hasContracting,
            hasIntake,
            hasSolicitations: hasSourcing || hasEvaluation,
        };
    }
);

import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Button, Main } from '../../../../components';
import { getUserJS } from '../../../selectors';

export const ManagePoliciesNav = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const user = useSelector(getUserJS);
    const styles = require('./index.scss');
    const isAddOrEditPolicy = location.pathname.includes('policy');

    /**
     * Navigates a user to the add retention code page.
     */
    const onAddNewPolicy = () => {
        navigate(`/governments/${user.government_id}/retention-admin/manage/policy`);
    };

    const renderNewPolicyButton = () => (
        <Button bsStyle="primary" onClick={onAddNewPolicy} qaTag="managePolicies-addNewPolicy">
            <i className="fa fa-plus" /> Add New Policy
        </Button>
    );

    return (
        <Main className={styles.container}>
            <div className={styles.row}>
                {!isAddOrEditPolicy && (
                    <div className="text-muted">
                        The order of this list determines how these policies appear in the list when
                        choosing a policy.
                    </div>
                )}

                <div>{isAddOrEditPolicy || renderNewPolicyButton()}</div>
            </div>
            <div className={styles.policies}>
                <Outlet />
            </div>
        </Main>
    );
};

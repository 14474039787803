import { gql } from 'graphql-request';

export const getAllCustomFields = gql`
    query {
        getAllCustomFields {
            id
            updated_at
            shortTitle
            name
            status
            fieldType
        }
    }
`;

export const getCustomField = gql`
    query ($customFieldId: Int!) {
        getCustomField(customFieldId: $customFieldId) {
            id
            updated_at
            created_at
            name
            status
            fieldType
            shortTitle
            instructionsText
            instructionsMode
            characterLimit
            numberKind
            enumOptions
        }
        getCustomFieldLogs(customFieldId: $customFieldId) {
            id
            actionType
            date
        }
        getCustomFieldClientForms(customFieldId: $customFieldId) {
            clientFormId
            clientFormType
        }
    }
`;

export const createCustomField = gql`
    mutation (
        $name: String!
        $fieldType: String!
        $shortTitle: String
        $status: Status
        $instructionsText: String
        $instructionsMode: String
        $characterLimit: String
        $numberKind: String
        $enumOptions: [String]
    ) {
        createCustomField(
            fieldType: $fieldType
            name: $name
            shortTitle: $shortTitle
            status: $status
            instructionsText: $instructionsText
            instructionsMode: $instructionsMode
            characterLimit: $characterLimit
            numberKind: $numberKind
            enumOptions: $enumOptions
        ) {
            id
        }
    }
`;

export const copyCustomField = gql`
    mutation ($customFieldId: Int!) {
        copyCustomField(customFieldId: $customFieldId) {
            id
        }
    }
`;

export const editCustomField = gql`
    mutation (
        $customFieldId: Int!
        $name: String
        $shortTitle: String
        $status: Status
        $instructionsText: String
        $instructionsMode: String
        $characterLimit: String
        $numberKind: String
        $enumOptions: [String]
    ) {
        editCustomField(
            customFieldId: $customFieldId
            name: $name
            shortTitle: $shortTitle
            status: $status
            instructionsText: $instructionsText
            instructionsMode: $instructionsMode
            characterLimit: $characterLimit
            numberKind: $numberKind
            enumOptions: $enumOptions
        ) {
            id
        }
    }
`;

export const getCustomFormSnapshot = gql`
    query ($customFormId: Int!, $version: Int!) {
        getCustomFormSnapshot(customFormId: $customFormId, version: $version) {
            fields {
                id
                name
                fieldType
                shortTitle
                instructionsText
                instructionsMode
                characterLimit
                numberKind
                enumOptions
            }
        }
    }
`;

export const getCustomForm = gql`
    query ($customFormId: Int!) {
        getCustomForm(customFormId: $customFormId) {
            customFields {
                id
                name
                fieldType
            }
        }
    }
`;

export const editCustomForm = gql`
    mutation ($customFormId: Int!, $fieldsOrder: [ID!]!) {
        editCustomForm(customFormId: $customFormId, fieldsOrder: $fieldsOrder) {
            id
        }
    }
`;

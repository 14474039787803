import React from 'react';
import PropTypes from 'prop-types';
import { upperCase } from 'lodash';

import { CellWrapper } from '../CellWrapper';
import { StatusLabel } from '../../../../../components';
import { statusClass, statusIcon } from '../../../../../components/helpers/statusHelper';

export const StatusCell = ({ api, node, value }) => {
    const styles = require('./index.scss');
    return (
        <CellWrapper api={api} node={node}>
            <StatusLabel bsStyle={statusClass(value.status)} className={styles.statusLabel}>
                <i className={`fa fa-fw fa-${statusIcon(value.status)}`} />
                &nbsp;
                {upperCase(value.status)}
            </StatusLabel>
            <div className={styles.projectLabel}>{value.isIntake ? 'Intake' : 'Document'} </div>
        </CellWrapper>
    );
};

StatusCell.propTypes = {
    api: PropTypes.object,
    node: PropTypes.object,
    value: PropTypes.shape({
        status: PropTypes.string,
        isIntake: PropTypes.bool,
    }),
};

import { fieldNames } from './constants';

const { DESCRIPTION, NAME, USER_ID } = fieldNames;

export const validate = (values) => {
    const errors = {};

    if (!values[NAME]) {
        errors[NAME] = 'Please specify a position name';
    } else if (values[NAME].length > 128) {
        errors[NAME] = 'Name is too long';
    }

    if (values[DESCRIPTION] && values[DESCRIPTION].length > 512) {
        errors[DESCRIPTION] = 'Description is too long';
    }

    if (!values[USER_ID]) {
        errors[USER_ID] = 'Please specify a user for this position';
    }

    return errors;
};

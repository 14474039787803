import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { ApprovalReviewStep } from './ApprovalReviewStep';
import { getActiveReviewStep, getStatusLabelMap } from './selectors';

export const ApprovalList = ({
    addingApprovers,
    approvals,
    inDraft,
    inReview,
    isEditor,
    reminderHandler,
    removeApproverHandler,
    removeReviewStepHandler,
    reviewStepApproversMap,
    showModal,
    updateReviewStepHandler,
    user,
}) => {
    const activeReviewStep = useSelector((state) => getActiveReviewStep(state));
    const statusLabelMap = useSelector((state) => getStatusLabelMap(state));

    return (
        <>
            {approvals.map((reviewStep, index) => (
                <ApprovalReviewStep
                    activeReviewStep={activeReviewStep}
                    addingApprovers={addingApprovers}
                    approvals={approvals}
                    inDraft={inDraft}
                    inReview={inReview}
                    isEditor={isEditor}
                    key={reviewStep.id}
                    reminderHandler={reminderHandler}
                    removeApproverHandler={removeApproverHandler}
                    removeReviewStepHandler={removeReviewStepHandler}
                    reviewStep={reviewStep}
                    reviewStepApproversMap={reviewStepApproversMap}
                    reviewStepNumber={index + 1}
                    showModal={showModal}
                    statusLabel={statusLabelMap[reviewStep.id]}
                    updateReviewStepHandler={updateReviewStepHandler}
                    user={user}
                />
            ))}
        </>
    );
};

ApprovalList.propTypes = {
    addingApprovers: PropTypes.bool.isRequired,
    approvals: PropTypes.array.isRequired,
    inDraft: PropTypes.bool.isRequired,
    inReview: PropTypes.bool.isRequired,
    isEditor: PropTypes.bool.isRequired,
    reminderHandler: PropTypes.func.isRequired,
    removeApproverHandler: PropTypes.func.isRequired,
    removeReviewStepHandler: PropTypes.func.isRequired,
    reviewStepApproversMap: PropTypes.object.isRequired,
    showModal: PropTypes.func.isRequired,
    updateReviewStepHandler: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
};

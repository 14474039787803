import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ListGroupItem } from 'react-bootstrap';

import { currencyFormatter } from '../../../../../helpers';

export const NoBidLineItem = ({
    auctionMaxFractionDigits,
    item: {
        minBidDecrement,
        priceItems: {
            0: { description, id, unitToMeasure },
        },
    },
}) => {
    const styles = require('../index.scss');

    return (
        <ListGroupItem className={classnames(styles.lineItem, styles.lineItemBorderNoBid)} key={id}>
            <div className={styles.header}>
                <div className="row">
                    <div className="col-sm-4 col-sm-offset-4 col-sm-push-4">
                        <h5 className={styles.statusText}>
                            <div className={styles.statusNoBid}>NO BID</div>
                        </h5>
                    </div>
                    <div className="col-sm-4 col-sm-pull-8">
                        <h4 className={styles.description}>{description}</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <div className={styles.headerInfoText}>
                            Unit of Measure: {unitToMeasure}
                        </div>
                        <div className={classnames(styles.headerInfoText, styles.decrementText)}>
                            Minimum Decrement:{' '}
                            {currencyFormatter(
                                { value: minBidDecrement },
                                {
                                    maximumFractionDigits: auctionMaxFractionDigits,
                                    useSameMinAndMax: true,
                                }
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </ListGroupItem>
    );
};

NoBidLineItem.propTypes = {
    auctionMaxFractionDigits: PropTypes.number.isRequired,
    item: PropTypes.shape({
        minBidDecrement: PropTypes.number.isRequired,
        priceItems: PropTypes.arrayOf(
            PropTypes.shape({
                description: PropTypes.string.isRequired,
                unitToMeasure: PropTypes.string.isRequired,
            })
        ),
    }),
};

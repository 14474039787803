import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { buildVendorSearchQuery } from '@og-pro/shared-config/vendors/expanded-supplier-network';

import { useSearchService } from '../../../../hooks';
import { getProjectJS } from '../../selectors';
import { MAX_VENDOR_CONTACTS } from '../constants';
import { trackEvent } from '../../../../helpers';

export const useVendorSearch = ({ categories, setError, setResults, setTotal, notifiedUsers }) => {
    const project = useSelector(getProjectJS);
    const {
        vendors: { search: vendorSearch, loading },
    } = useSearchService();

    const search = useCallback(
        async (locations) => {
            const params = buildVendorSearchQuery({ categories, locations });

            // We want to have at least 1 location selected
            if (isEmpty(params.query.or)) {
                return { count: 0, results: [] };
            }

            // the limit is {MAX_VENDOR_CONTACTS}, we pass a higher limit to show a warning if more results are returned
            const res = await vendorSearch({
                ...params,
                size: MAX_VENDOR_CONTACTS + 100,
                sort: [{ id: { order: 'desc' } }],
            });

            return res;
        },
        [project]
    );

    const searchFilterAndSet = async (locations) => {
        try {
            setError(null);
            const res = await search(locations);

            // We need to filter the results from OS and keep only
            // those that were not notified. When the project is live,
            // notifiedUsers will contain a list of users already notified (coming from the audit table).
            // The idea is to display only the users that were not notified. (only when the project is posted)
            const { count, users } = res.results.reduce(
                (acc, cur) => {
                    if (!cur.users_ids) {
                        return acc;
                    }

                    const userIds = cur.users_ids.split(' ').filter((id) => {
                        const parsedNumber = parseInt(id, 10);

                        if (Number.isNaN(parsedNumber)) {
                            return false;
                        }

                        return !notifiedUsers.ids.has(parsedNumber);
                    });

                    if (!userIds.length) {
                        return acc;
                    }

                    return {
                        count: acc.count + 1,
                        users: acc.users + userIds.length,
                    };
                },
                { count: 0, users: 0 }
            );

            if (users > MAX_VENDOR_CONTACTS) {
                trackEvent('Expanded Supplier Network search results over limit', {
                    project_id: project.id,
                    project_title: project.title,
                    categories_ids: categories.map((category) => category.id).join(', '),
                    locations: locations
                        .map((location) => `${location.name}, ${location.type}`)
                        .join(' - '),
                    vendor_contacts_count: users,
                });
            }

            setTotal(count);
            setResults({ count, users });
        } catch (e) {
            setError(e);
        }
    };

    return { loading, search, searchFilterAndSet };
};

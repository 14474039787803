const { buildMap, listToDict } = require('../helpers');

// Normal questionnaire types
const BID_BOND = 'bidBond';
const CONFIRMATION = 'confirmation';
const CUSTOM_VARIABLE = 'customVariable'; // Behaves similarly to `SHORT_ANSWER` with some different wording
const DATA_VERIFY = 'dataVerify';
const DOWNLOAD = 'download';
const EXTERNAL_FORM = 'externalForm';
const FILE_UPLOAD = 'fileUpload';
const LONG_ANSWER = 'longAnswer';
const MULTIPLE_CHOICE = 'multipleChoice';
const NOTARIZE = 'notarize';
const SHORT_ANSWER = 'shortAnswer';
const YES_NO = 'yesNo';

// Format questionnaire types
const SECTION_HEADER = 'sectionHeader';

exports.questionnaireTypes = [
    BID_BOND,
    CONFIRMATION,
    CUSTOM_VARIABLE,
    DATA_VERIFY,
    DOWNLOAD,
    EXTERNAL_FORM,
    FILE_UPLOAD,
    LONG_ANSWER,
    MULTIPLE_CHOICE,
    NOTARIZE,
    SECTION_HEADER,
    SHORT_ANSWER,
    YES_NO,
];

exports.upfrontQuestionTypes = [CUSTOM_VARIABLE, MULTIPLE_CHOICE, SECTION_HEADER, YES_NO];

exports.upfrontQuestionConditionalTypes = [MULTIPLE_CHOICE, YES_NO];
exports.questionnaireQuestionConditionalTypes = [YES_NO];

exports.questionTypesDisplay = [
    {
        type: SHORT_ANSWER,
        title: 'Short Answer',
        icon: 'pencil',
        instructions: 'Collect a short response (single value or sentence)',
    },
    {
        type: LONG_ANSWER,
        title: 'Long Answer',
        icon: 'file-text-o',
        instructions: 'Collect a long response (paragraph or more)',
    },
    {
        type: YES_NO,
        title: 'Yes / No',
        icon: 'toggle-on',
        instructions: 'Ask a yes or no question',
    },
    {
        type: MULTIPLE_CHOICE,
        title: 'Multiple Choice',
        icon: 'list-ul',
        instructions: 'Choose from a set of defined response options',
    },
    {
        type: CONFIRMATION,
        title: 'Confirmation',
        icon: 'check-square-o',
        instructions: 'Request confirmation',
    },
    {
        type: FILE_UPLOAD,
        title: 'File Upload',
        icon: 'cloud-upload',
        instructions: 'Collect a file upload as the response',
    },
    {
        type: DOWNLOAD,
        title: 'Downloadable Documents',
        icon: 'download',
        instructions: 'Include documents to be downloaded, completed and uploaded',
    },
    {
        type: DATA_VERIFY,
        title: 'Data Verification',
        icon: 'search',
        instructions: 'Collect information that can be verified through another website',
    },
    {
        type: NOTARIZE,
        title: 'Notarize Documents',
        icon: 'shield',
        instructions: 'Require specified documents be signed and notarized',
        integration: 'NotaryLive',
        integrationLogo: 'https://assets.procurement.opengov.com/assets/notary-live-logo.jpeg',
    },
    {
        type: EXTERNAL_FORM,
        title: 'DocuSign PowerForm',
        icon: 'external-link',
        instructions: 'Provide a link to a DocuSign PowerForm for external form for completion',
        integration: 'DocuSign',
        integrationLogo: 'https://assets.procurement.opengov.com/assets/docusign-logo.jpeg',
    },
    {
        type: BID_BOND,
        title: 'Surety2000 Bid Bond Verification',
        icon: 'money',
        instructions: 'Require a verified bid bond ID through Surety2000',
        integration: 'Surety2000',
        integrationLogo: 'https://assets.procurement.opengov.com/assets/surety2000-logo.png',
    },
];

const upfrontQuestionOnlyTypesDisplay = [
    {
        type: CUSTOM_VARIABLE,
        title: 'Custom Variable',
        icon: 'tag',
        instructions: 'Define a custom variable that is available for use throughout the document',
    },
];

exports.formatTypesDisplay = [
    {
        type: SECTION_HEADER,
        title: 'Section Header',
        icon: 'indent',
        instructions: 'Insert a section header',
    },
];

const allQuestionTypesDisplay = [
    ...exports.questionTypesDisplay,
    ...upfrontQuestionOnlyTypesDisplay,
    ...exports.formatTypesDisplay,
];

// Map for quick access of questionType data
exports.questionTypesDisplayMap = buildMap(allQuestionTypesDisplay, 'type');

exports.upfrontQuestionTypesDisplay = [
    exports.questionTypesDisplayMap[CUSTOM_VARIABLE],
    exports.questionTypesDisplayMap[YES_NO],
    exports.questionTypesDisplayMap[MULTIPLE_CHOICE],
];

exports.checklistQuestionTypesDisplay = [
    exports.questionTypesDisplayMap[SHORT_ANSWER],
    exports.questionTypesDisplayMap[LONG_ANSWER],
    exports.questionTypesDisplayMap[YES_NO],
    exports.questionTypesDisplayMap[MULTIPLE_CHOICE],
    exports.questionTypesDisplayMap[CONFIRMATION],
    exports.questionTypesDisplayMap[FILE_UPLOAD],
    exports.questionTypesDisplayMap[DOWNLOAD],
];

exports.bidBondQuestionnaireFieldNames = [
    'bidBondProjectId',
    'bidBondSecurity',
    'bidBondSecurityIsPercentage',
];

exports.bidBondQuestionnaireFieldNamesDict = listToDict(exports.bidBondQuestionnaireFieldNames);

exports.bidBondQuestionnaireResponseFieldNames = ['bidBondId', 'bidBondVendorId'];

exports.bidBondQuestionnaireResponseFieldNamesDict = listToDict(
    exports.bidBondQuestionnaireResponseFieldNames
);

// Questionnaire type constants
exports.BID_BOND = BID_BOND;
exports.CONFIRMATION = CONFIRMATION;
exports.CUSTOM_VARIABLE = CUSTOM_VARIABLE;
exports.DATA_VERIFY = DATA_VERIFY;
exports.DOWNLOAD = DOWNLOAD;
exports.EXTERNAL_FORM = EXTERNAL_FORM;
exports.FILE_UPLOAD = FILE_UPLOAD;
exports.LONG_ANSWER = LONG_ANSWER;
exports.MULTIPLE_CHOICE = MULTIPLE_CHOICE;
exports.NOTARIZE = NOTARIZE;
exports.SECTION_HEADER = SECTION_HEADER;
exports.SHORT_ANSWER = SHORT_ANSWER;
exports.YES_NO = YES_NO;

// Raw form builder fields
exports.RAW_PROMPT = 'rawPrompt';

// Form builder fields
exports.CONTAINS_PRICING = 'containsPricing';
exports.DATA = 'data';
exports.IS_PUBLIC = 'isPublic';
exports.IS_REQUIRED = 'isRequired';
exports.IS_TITLE = 'isTitle';
exports.ORDER_BY_ID = 'orderById';
exports.PROMPT = 'prompt';
exports.TITLE = 'title';
exports.TYPE = 'type';

// Form builder data fields
exports.OPTIONS = 'options';
exports.ALLOW_MULTI_SELECT = 'allowMultiSelect';
exports.DEFAULT_VALUE = 'defaultValue';
exports.MAX_LENGTH = 'maxLength';

// Upfront question fields
exports.INPUT_DATA = 'inputData';

// Form response fields
// NOTE: `DATA` field is also used in response field, but is already defined above
exports.QUESTIONNAIRE_RESPONSE = 'questionnaireResponse';
exports.RESPONSE_DATA = 'data';
exports.ATTACHMENTS = 'attachments';
exports.VALUE = 'value';

export const validate = (values) => {
    const errors = {};

    if (!values.id) {
        errors.id = 'User is required';
    }
    if (!values.role) {
        errors.role = 'Permission level is required';
    }

    return errors;
};

import qs from 'qs';
import { createSelector } from 'reselect';
import { v4 as UUIDv4 } from 'uuid';

import { projectSortFieldTypesDict } from '@og-pro/shared-config/projects';

import { deserializeFilterQuery } from '../../../helpers';

const {
    CREATED_AT,
    CONTACT_FIRST_NAME,
    DEPARTMENT_NAME,
    PROCUREMENT_FIRST_NAME,
    PROPOSAL_DEADLINE,
    RELEASE_PROJECT_DATE,
    TITLE,
} = projectSortFieldTypesDict;

const getRawQueryParams = (_state, props) => props.location.query;

export const getProjectLineItemContractInfo = (state) =>
    state.projects.get('projectLineItemContractInfo');

export const getProjectLineItemContractInfoJS = createSelector(
    [getProjectLineItemContractInfo],
    (rawProjectLineItemContractInfo) =>
        rawProjectLineItemContractInfo ? rawProjectLineItemContractInfo.toJS() : []
);

export const getParsedQueryParams = createSelector([getRawQueryParams], (rawQueryParams) => {
    if (rawQueryParams) {
        const parsedQueryParams = qs.parse(rawQueryParams);
        return {
            ...parsedQueryParams,
            // Overrides `page` and `filter` keys with deserialized values
            ...deserializeFilterQuery(parsedQueryParams),
        };
    }
    return {};
});

export const getFilterFormValues = createSelector([getParsedQueryParams], (queryParams) => {
    const { filters } = queryParams;

    if (filters) {
        return {
            // Add in unique ID for handling form rendering
            filters: filters.map((filter) => ({ ...filter, localCreatingUUID: UUIDv4() })),
        };
    }
});

export const getProjectSortByFieldOptions = createSelector(
    [(state, props) => props.isDocBuilder, (state, props) => props.isIntake],
    (isDocBuilder, isIntake) => {
        if (isDocBuilder || isIntake) {
            return [
                { label: 'Project Contact', value: CONTACT_FIRST_NAME },
                { label: 'Procurement Contact', value: PROCUREMENT_FIRST_NAME },
                { label: 'Title', value: TITLE },
                { label: 'Created Date', value: CREATED_AT },
            ];
        }

        return [
            { label: 'Project Contact', value: CONTACT_FIRST_NAME },
            { label: 'Procurement Contact', value: PROCUREMENT_FIRST_NAME },
            { label: 'Department', value: DEPARTMENT_NAME },
            { label: 'Title', value: TITLE },
            { label: 'Created Date', value: CREATED_AT },
            { label: 'Release Date', value: RELEASE_PROJECT_DATE },
            { label: 'Due Date', value: PROPOSAL_DEADLINE },
        ];
    }
);

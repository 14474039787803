import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { SectionDescriptionDisplay } from './SectionDescriptionDisplay';
import { fieldNames } from '../../constants';
import { OGThemeConsumer } from '../../../../../ogThemeProvider';
import { SectionDescription as SectionDescriptionV2 } from '../../../../../../../components/SDv2/SectionDescription';

const { SECTION_DESCRIPTIONS } = fieldNames;

export class TemplateSectionDescriptionForm extends PureComponent {
    static propTypes = {
        change: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        form: PropTypes.string.isRequired,
        projectSectionId: PropTypes.number.isRequired,
        projectSubsectionId: PropTypes.number.isRequired,
        showFormValidation: PropTypes.bool,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
    };

    get formKey() {
        const { projectSectionId, projectSubsectionId } = this.props;

        return `${SECTION_DESCRIPTIONS}.${projectSectionId}_${projectSubsectionId}`;
    }

    addSectionDescription = () => {
        const { change, projectSectionId, projectSubsectionId } = this.props;

        change(`${this.formKey}.project_section_id`, projectSectionId);
        change(`${this.formKey}.project_subsection_id`, projectSubsectionId);
    };

    removeSectionDescription = () => {
        const { change } = this.props;

        change(this.formKey, null);
    };

    render() {
        const { disabled, form, showFormValidation, tagOptions, templateVariableOptions } =
            this.props;

        const sectionDescriptionDisplayProps = {
            addSectionDescription: this.addSectionDescription,
            disabled,
            form,
            formKey: this.formKey,
            isEditable: true,
            removeSectionDescription: this.removeSectionDescription,
            showFormValidation,
            tagOptions,
            templateVariableOptions,
        };

        return (
            <OGThemeConsumer>
                {({ isOGThemeEnabledForComponents }) =>
                    isOGThemeEnabledForComponents ? (
                        <SectionDescriptionV2 {...sectionDescriptionDisplayProps} />
                    ) : (
                        <SectionDescriptionDisplay {...sectionDescriptionDisplayProps} />
                    )
                }
            </OGThemeConsumer>
        );
    }
}

import { Box, Card, CardContent, Checkbox } from '@og-pro/ui';
import React from 'react';
import PropTypes from 'prop-types';

import { OtherItems } from './OtherItems';
import { activeItemsStyle, cardContentStyle, checkboxStyle } from './styles';
import { RelationItem } from '../../../../../../../components';

export const RelationGroup = ({ disabled, isEditing, reqRelation, selectHandler }) => {
    const { activeItems, otherItems } = reqRelation;

    return (
        <Card>
            <CardContent sx={cardContentStyle}>
                {isEditing && (
                    <Checkbox
                        disabled={disabled}
                        onChange={() => selectHandler(reqRelation.id)}
                        sx={checkboxStyle}
                    />
                )}
                <Box sx={activeItemsStyle}>
                    {activeItems.map((item, index) => (
                        <RelationItem key={index} {...item} />
                    ))}
                </Box>
            </CardContent>
            <OtherItems isEditing={isEditing} items={otherItems} />
        </Card>
    );
};

RelationGroup.propTypes = {
    disabled: PropTypes.bool,
    isEditing: PropTypes.bool,
    reqRelation: PropTypes.shape({
        activeItems: PropTypes.array.isRequired,
        id: PropTypes.number.isRequired,
        otherItems: PropTypes.array,
    }).isRequired,
    selectHandler: PropTypes.func.isRequired,
};

import { isEmail } from 'validator';

export function email(values, keyName = 'email', opts = {}) {
    const errors = {};
    const emailValue = values[keyName];

    if (emailValue) {
        if (!isEmail(emailValue)) {
            errors[keyName] = 'Please enter a valid email.';
        } else if (emailValue.length > 128) {
            errors[keyName] = 'Email is too long (max length is 128 characters).';
        }
    } else if (!opts.optional) {
        errors[keyName] = 'The email field is required.';
    }

    return errors;
}

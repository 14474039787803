import { get } from 'lodash';
import moment from 'moment';

export const getVendorPriceTableExportFilename = (project, proposal) => {
    return [
        get(project, 'template.processAcronym') || 'None',
        get(project, 'financialId') || 'ID',
        get(proposal, 'companyName') || 'Unknown',
        moment(proposal.submittedAt).format('YYYY-MM-DD-HH-mm'),
    ]
        .map((text) =>
            text
                .replace(/[<>"':/\\|?*]/g, '')
                .replace(/[.]+$/, '')
                .replace(' ', '')
        )
        .join('-');
};

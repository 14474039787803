import { createSelector } from 'reselect';

const getProposalsFromProps = (state, props) => props.proposals;
const getEvaluatorFromProps = (state, props) => props.evaluator;
const isConsensusView = (state, props) => props.isConsensusView;

export const getProposals = createSelector(
    [getProposalsFromProps, getEvaluatorFromProps, isConsensusView],
    (proposals, evaluator, isConsensus) => {
        return proposals.map((proposal) => {
            const proposalEvaluation = isConsensus
                ? proposal.consensusEvaluation
                : proposal.proposalEvaluations.find(
                      (propEval) => propEval.user_id === evaluator.id
                  );

            return {
                ...proposal,
                proposalEvaluation,
            };
        });
    }
);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { RichTextInput } from '..';

/*
 * Intended to mimic a disabled textarea input, but capable of displaying
 * rich text
 */
export class DisabledRichTextArea extends PureComponent {
    static propTypes = {
        value: PropTypes.string,
        toolbarPlacement: PropTypes.string,
    };

    noOp = () => {};

    render() {
        return (
            <RichTextInput
                disabled
                hideToolbar
                input={{
                    onBlur: this.noOp,
                    onChange: this.noOp,
                    onFocus: this.noOp,
                    value: this.props.value,
                }}
                {...this.props}
            />
        );
    }
}

import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { ProposalSectionTitle } from '..';

export class AddendumsConfirmSection extends PureComponent {
    static propTypes = {
        isDocx: PropTypes.bool,
        printButton: PropTypes.node,
        proposal: PropTypes.shape({
            addendums: PropTypes.arrayOf(
                PropTypes.shape({
                    addendumConfirms: PropTypes.array.isRequired,
                    id: PropTypes.number.isRequired,
                    number: PropTypes.number.isRequired,
                })
            ).isRequired,
            hasAddendumSection: PropTypes.bool.isRequired,
        }).isRequired,
    };

    static defaultProps = {
        printButton: undefined,
    };

    get styles() {
        return require('../../index.scss');
    }

    render() {
        const {
            isDocx,
            printButton,
            proposal: { addendums, hasAddendumSection },
        } = this.props;

        if (!hasAddendumSection) {
            return null;
        }

        return (
            <div className={this.styles.section} ref={this.setCompanySectionRef}>
                <ProposalSectionTitle
                    isDocx={isDocx}
                    printButton={printButton}
                    title="ADDENDA CONFIRMATION"
                />
                <div className={this.styles.sectionItem}>
                    {addendums.length === 0 && <em>No addenda issued</em>}
                    {addendums.map((addendum) => {
                        const { addendumConfirms, id, number } = addendum;

                        const [addendumConfirm] = addendumConfirms;
                        const icon = addendumConfirm ? 'check-circle' : 'circle-thin';
                        const text = addendumConfirm
                            ? `Confirmed ${moment(addendumConfirm.created_at).format('lll')} by ${
                                  addendumConfirm.user.displayName
                              }`
                            : 'Unconfirmed';

                        if (isDocx) {
                            return (
                                <div key={id} style={{ marginBottom: 8 }}>
                                    Addendum #{number}
                                    <br />
                                    <em>{text}</em>
                                </div>
                            );
                        }

                        return (
                            <div key={id}>
                                <i className={`fa fa-fw fa-lg fa-${icon}`} /> Addendum #{number}
                                <div className={this.styles.addendumConfirmText}>{text}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

import React from 'react';
import { useSelector } from 'react-redux';

import { getRequisitionCopyError, getRequisitionUpdateError } from '../../../../selectors/govApp';

export const ActionError = () => {
    const updateError = useSelector(getRequisitionUpdateError);
    const copyError = useSelector(getRequisitionCopyError);

    const errorMessage = updateError || copyError;

    if (!errorMessage) {
        return null;
    }

    return <div className="error-block text-right">{errorMessage}</div>;
};

import { get } from 'lodash';

import { email } from '../../../../Forms/validation';
import { fieldNames } from './constants';

const { DEPARTMENT_ID, FIRST_NAME, LAST_NAME, ROLE } = fieldNames;

export const validate = (values, props) => {
    const isVendor = get(props, ['currentUser', 'isVendor']);

    const errors = {
        ...email(values),
    };

    if (!values[DEPARTMENT_ID]) {
        errors[DEPARTMENT_ID] = 'Must include a department';
    }

    if (!values[ROLE]) {
        errors[ROLE] = 'Must specify a role';
    }

    if (!isVendor) {
        if (!values[FIRST_NAME]) {
            errors[FIRST_NAME] = 'First name is required';
        } else if (values[FIRST_NAME].length > 32) {
            errors[FIRST_NAME] = 'First name is too long';
        }

        if (!values[LAST_NAME]) {
            errors[LAST_NAME] = 'Last name is required';
        } else if (values[LAST_NAME].length > 32) {
            errors[LAST_NAME] = 'Last name is too long';
        }
    }

    return errors;
};

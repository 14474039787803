import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';
import { v4 as UUIDv4 } from 'uuid';

import { onKeyDown } from '../../helpers/accessibilityHelper';

export const HelpToolTip = ({ className, isPopover, onClick, placement = 'top', text }) => {
    const renderTooltip = () => {
        if (isPopover) {
            return <Popover id={`popover-${UUIDv4()}`}>{text}</Popover>;
        }
        return <Tooltip id={`tooltip-${UUIDv4()}`}>{text}</Tooltip>;
    };

    const styles = require('./HelpToolTip.scss');
    const classes = `${styles.questionIcon} ${className}`;

    return (
        <OverlayTrigger overlay={renderTooltip()} placement={placement}>
            <i
                aria-label="Help Button"
                className={`fa fa-question-circle ${classes}`}
                onClick={onClick}
                {...(onClick && { onKeyDown: (event) => onKeyDown(event, onClick) })}
                role="button"
                tabIndex={0}
            />
        </OverlayTrigger>
    );
};

HelpToolTip.propTypes = {
    isPopover: PropTypes.bool,
    text: PropTypes.string,
    placement: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
};

import { fieldNames } from './constants';

const { QUICK_SEARCH_QUERY, SEARCH_PRICE_ITEMS } = fieldNames;

export const validate = (values) => {
    const errors = {};

    if (values[SEARCH_PRICE_ITEMS] && (values[QUICK_SEARCH_QUERY] || '').trim().length < 3) {
        errors[QUICK_SEARCH_QUERY] = 'Please enter 3 or more characters';
    }

    return errors;
};

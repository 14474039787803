import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

export const WithStickyHeader = (props) => {
    const gridRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const headerElement = gridRef.current.querySelector('.ag-header');
            const { offset } = props;
            if (!headerElement) {
                return;
            }

            const tableElement = headerElement.parentElement.parentElement;
            const tableElementRect = tableElement.getBoundingClientRect();
            const headerElementRect = headerElement.getBoundingClientRect();
            const sideButtonsElement = tableElement.querySelector('.ag-side-buttons');
            const tableBodyElement = tableElement.querySelector('.ag-root');

            const isTableElementTopScrolledPastOffset = tableElementRect.top < offset;
            const isHeaderElementBottomAtTableElementBottom =
                tableElementRect.bottom < headerElementRect.height + offset;

            if (isTableElementTopScrolledPastOffset) {
                // Fix the header
                headerElement.style.position = 'fixed';
                // Keep the space for the now missing header
                tableElement.style.paddingTop = `${headerElement.clientHeight}px`;
                // Make sure the header is on top of the table but below another fixed headers (100)
                headerElement.style.zIndex = 50;
                // Make sure the header is the same width as the table
                headerElement.style.width = `${headerElement.parentElement.clientWidth}px`;

                // Fix the side buttons to the offset
                sideButtonsElement.style.position = 'fixed';
                sideButtonsElement.style.top = `${offset}px`;
                sideButtonsElement.style.height = `${tableElementRect.bottom - offset - 2}px`;
                sideButtonsElement.style.overflow = 'hidden';
                sideButtonsElement.parentElement.style.paddingLeft = `${sideButtonsElement.clientWidth}px`;
                sideButtonsElement.parentElement.style.marginTop = `-${offset}px`;

                // hack to show the side buttons left border when scrolling past the top
                tableBodyElement.style.marginTop = `-${offset}px`;
                tableBodyElement.style.paddingTop = `${offset}px`;

                // Now set the top of the header (main logic)
                if (isHeaderElementBottomAtTableElementBottom) {
                    // Fix the header to the bottom of the table
                    headerElement.style.top = `${
                        tableElementRect.bottom - headerElementRect.height
                    }px`;
                } else {
                    // Fix the header to the top of the "viewport" (with an offset)
                    headerElement.style.top = `${offset}px`;
                }
            } else {
                // Reset the header
                tableElement.style.paddingTop = '0px';
                headerElement.style.position = 'relative';
                headerElement.style.top = '0px';
                headerElement.style.zIndex = '0';
                headerElement.style.width = '100%';

                // Reset the side buttons and table body
                sideButtonsElement.style.position = 'relative';
                sideButtonsElement.style.top = '0px';
                sideButtonsElement.style.height = '100%';
                sideButtonsElement.style.overflow = 'auto';
                sideButtonsElement.parentElement.style.paddingLeft = '0px';
                sideButtonsElement.parentElement.style.marginTop = '0px';
                tableBodyElement.style.marginTop = '0px';
                tableBodyElement.style.paddingTop = '0px';
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [props.offset]);

    return <div ref={gridRef}>{props.children}</div>;
};

WithStickyHeader.propTypes = {
    offset: PropTypes.number,
    children: PropTypes.node,
};

WithStickyHeader.defaultProps = {
    offset: 0,
};

import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { fieldNames } from '../../../constants';
import { qaTagPageName } from '../../../../constants';
import { SearchSelect } from '../../../../../../../components';

const { DELIVERY_CODE_TEXT, PAYMENT_TERMS_TEXT } = fieldNames;

export const DeliveryCodesAndPaymentTerms = ({
    disabled,
    fieldName,
    showFormValidation,
    vendorSelectionFormDisplay,
}) => {
    const { deliveryCodeSelectOptions, paymentTermsSelectOptions } = vendorSelectionFormDisplay;

    return (
        <div className="row">
            <div className="col-xs-12 col-md-6">
                <Field
                    component={SearchSelect}
                    disabled={disabled}
                    isClearable
                    label="Delivery Code *"
                    name={`${fieldName}.${DELIVERY_CODE_TEXT}`}
                    options={deliveryCodeSelectOptions}
                    placeholder="Choose a Delivery Code"
                    qaTag={`${qaTagPageName}-deliveryCodeText`}
                    showValidation={showFormValidation}
                    useOpenGovStyle
                />
            </div>
            <div className="col-xs-12 col-md-6">
                <Field
                    component={SearchSelect}
                    disabled={disabled}
                    isClearable
                    label="Payment Terms *"
                    name={`${fieldName}.${PAYMENT_TERMS_TEXT}`}
                    options={paymentTermsSelectOptions}
                    placeholder="Choose Payment Terms"
                    qaTag={`${qaTagPageName}-paymentTermsText`}
                    showValidation={showFormValidation}
                    useOpenGovStyle
                />
            </div>
        </div>
    );
};

DeliveryCodesAndPaymentTerms.propTypes = {
    disabled: PropTypes.bool.isRequired,
    fieldName: PropTypes.string.isRequired,
    showFormValidation: PropTypes.bool,
    vendorSelectionFormDisplay: PropTypes.shape({
        deliveryCodeSelectOptions: PropTypes.array.isRequired,
        paymentTermsSelectOptions: PropTypes.array.isRequired,
    }).isRequired,
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Panel } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { Box } from '@og-pro/ui';

import { GovernmentSubscriptionItem } from './GovernmentSubscriptionItem';
import { getUserGovernmentSubscriptions } from './selectors';
import { getVendorPath } from '../selectors';
import {
    removeGovSubscription,
    updateGovSubscription,
} from '../../../actions/account/subscriptions';
import { showConfirmationSimpleModal } from '../../../actions/confirmation';
import { Button, Main, SectionTitle } from '../../../components';

const mapStateToProps = (state, props) => {
    return {
        governmentSubscriptions: getUserGovernmentSubscriptions(state),
        vendorPath: getVendorPath(state, props),
    };
};

const mapDispatchToProps = {
    removeGovSubscription,
    showConfirmationSimpleModal,
    updateGovSubscription,
};

// @connect
class ConnectedGovernmentSubscriptionsList extends Component {
    static propTypes = {
        governmentSubscriptions: PropTypes.array.isRequired,
        removeGovSubscription: PropTypes.func.isRequired,
        showConfirmationSimpleModal: PropTypes.func.isRequired,
        updateGovSubscription: PropTypes.func.isRequired,
        vendorPath: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            showQuestions: {},
        };
    }

    get styles() {
        return require('./index.scss');
    }

    toggleQuestion = (index) => {
        this.setState((prevState) => {
            return {
                showQuestions: {
                    ...prevState.showQuestions,
                    [index]: !prevState.showQuestions[index],
                },
            };
        });
    };

    renderInfo() {
        const { governmentSubscriptions } = this.props;

        if (governmentSubscriptions.length === 0) {
            return (
                <p className={`text-muted ${this.styles.noSubscriptions}`}>
                    You have not subscribed to any organizations yet.
                    <br />
                    To subscribe, visit an organization&apos;s portal and click the
                    &quot;Subscribe&quot; button next to the organization&apos;s name.
                </p>
            );
        }

        return <span>Select an organization to view opportunities.</span>;
    }

    renderQuestions() {
        const { vendorPath } = this.props;

        const questions = [
            {
                question: 'How do I find more agencies?',
                answer: (
                    <>
                        OpenGov Procurement does not currently publish a master list of our
                        agencies.&nbsp;
                        <a
                            href="https://opengov.my.site.com/support/s/article/f7fe1d1e-d79c-449a-ad8b-1d736d40b9cd"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            Click here
                        </a>{' '}
                        for more information and help finding the agency you are looking for.
                    </>
                ),
            },
            {
                question: "How do I manage my company's category codes?",
                answer: (
                    <>
                        <Link to={`${vendorPath}/profile`}>
                            Visit your company&#39;s profile page
                        </Link>
                        . From there you can set category codes and manage other information about
                        your company.
                    </>
                ),
            },
            {
                question:
                    'I received an email to respond to a project, how do I find that project?',
                answer:
                    'Simply go back to your email and click on the project link. Once the page loads, click the "Follow" button in the upper right hand corner.\n' +
                    'The project will then appear in your list of "Followed Projects" and you will receive email notifications about updates to the project.',
            },
        ];

        const { showQuestions } = this.state;
        return (
            <div>
                <h4 className="text-center">Questions?</h4>
                <div>
                    {questions.map(({ question, answer }, index) => (
                        <div key={index}>
                            <div className={this.styles.questionIcon}>
                                <i
                                    className={`fa fa-fw fa-${
                                        showQuestions[index] ? 'angle-down' : 'angle-right'
                                    } text-primary`}
                                />
                            </div>
                            <div className={this.styles.questionText}>
                                <Button
                                    bsStyle="link"
                                    onClick={() => this.toggleQuestion(index)}
                                    qaTag={`governmentSubscriptionsList-${question}`}
                                    zeroPadding
                                >
                                    <strong>{question}</strong>
                                </Button>
                                {showQuestions[index] && (
                                    <p className={this.styles.answer}>{answer}</p>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    renderGovernmentSubscriptions() {
        const { governmentSubscriptions } = this.props;

        const GovSubscriptions = governmentSubscriptions.map((governmentSubscription) => (
            <GovernmentSubscriptionItem
                governmentSubscription={governmentSubscription}
                key={governmentSubscription.id}
                removeGovSubscription={this.props.removeGovSubscription}
                showConfirmationSimpleModal={this.props.showConfirmationSimpleModal}
                updateGovSubscription={this.props.updateGovSubscription}
            />
        ));

        return (
            <Box component="ul" mb={2} pl={0}>
                {GovSubscriptions}
            </Box>
        );
    }

    render() {
        return (
            <Main className="row">
                <h1 className="visually-hidden">My Subscriptions</h1>
                <div className="col-sm-offset-1 col-sm-10 col-lg-offset-2 col-lg-8">
                    <Panel className={this.styles.container}>
                        <Panel.Body>
                            <SectionTitle info={this.renderInfo()} title="My Subscriptions" />
                            <div className="row">
                                <div className="col-md-offset-2 col-md-8">
                                    {this.renderGovernmentSubscriptions()}
                                    {this.renderQuestions()}
                                </div>
                            </div>
                        </Panel.Body>
                    </Panel>
                </div>
            </Main>
        );
    }
}

export const GovernmentSubscriptionsList = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedGovernmentSubscriptionsList);

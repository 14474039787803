import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { connect } from 'react-redux';
import Sticky from 'react-stickynode';
import { compose } from 'redux';
import { propTypes as reduxFormPropTypes, reduxForm, Field, FieldArray } from 'redux-form';
import { v4 as UUIDv4 } from 'uuid';

import { contractInsuranceFormFieldsDict } from '@og-pro/shared-config/contracts';

import { form, fieldNamesDict } from './constants';
import { getContractInsuranceDates } from './selectors';
import { validate } from './validate';
import { getContractInsuranceSelectOptions } from '../../../selectors';
import { Button, DateTimePicker, SearchSelect, ZeroState } from '../../../../components';

const { APPROVED_DATE, EXPIRATION_DATE, REQUESTED_DATE, TAG_ID } = contractInsuranceFormFieldsDict;

const { CONTRACT_INSURANCES } = fieldNamesDict;

const mapStateToProps = (state) => {
    const insuranceTypeOptions = getContractInsuranceSelectOptions(state);

    return {
        hasInsuranceTypeOptions: insuranceTypeOptions.length > 0,
        initialValues: { [CONTRACT_INSURANCES]: getContractInsuranceDates(state) },
        insuranceTypeOptions,
        manageInsurancesError: state.contracts.get('manageInsurancesError'),
    };
};

const formConfig = {
    enableReinitialize: true,
    form,
    validate,
};

// @connect
// @reduxForm
class ConnectedContractInsurancesForm extends PureComponent {
    static propTypes = {
        ...reduxFormPropTypes,
        disabled: PropTypes.bool,
        hasInsuranceTypeOptions: PropTypes.bool.isRequired,
        insuranceTypeOptions: PropTypes.array.isRequired,
        manageInsurancesError: PropTypes.string,
    };

    static defaultProps = {
        disabled: false,
    };

    get styles() {
        return require('./index.scss');
    }

    renderContractInsurances = ({ fields }) => {
        const { dirty, disabled, insuranceTypeOptions, manageInsurancesError, submitFailed } =
            this.props;

        return (
            <>
                <Sticky innerZ={1}>
                    {({ status }) => (
                        <div
                            className={this.styles.actionButtons}
                            style={{
                                borderColor: status === Sticky.STATUS_FIXED ? '#ddd' : undefined,
                            }}
                        >
                            {dirty && (
                                <>
                                    <Button bsStyle="success" disabled={disabled} type="submit">
                                        <i className="fa fa-check" /> Save
                                    </Button>
                                    &nbsp;&nbsp;
                                </>
                            )}
                            <Button
                                bsStyle="primary"
                                disabled={disabled}
                                onClick={() => {
                                    fields.unshift({
                                        localCreatingUUID: UUIDv4(),
                                        [TAG_ID]: insuranceTypeOptions[0].value,
                                    });
                                }}
                            >
                                <i className="fa fa-plus" />
                                &nbsp;Add Insurance
                            </Button>
                            {submitFailed && (
                                <div className="error-block">
                                    Please fix the errors below before saving
                                </div>
                            )}
                            {manageInsurancesError && (
                                <div className="error-block">{manageInsurancesError}</div>
                            )}
                        </div>
                    )}
                </Sticky>
                {fields.length > 0 ? (
                    <ListGroup>
                        {fields.map((fieldName, index) => {
                            const contractInsurance = fields.get(index);
                            return (
                                <ListGroupItem
                                    key={
                                        contractInsurance.id || contractInsurance.localCreatingUUID
                                    }
                                >
                                    <div className="row">
                                        <div className="col-xs-12 col-md-3">
                                            <Field
                                                backspaceRemovesValue={false}
                                                component={SearchSelect}
                                                disabled={disabled}
                                                label="Insurance Type"
                                                name={`${fieldName}.${TAG_ID}`}
                                                options={insuranceTypeOptions}
                                                placeholder="Select type"
                                            />
                                        </div>
                                        <div className="col-xs-12 col-md-8">
                                            <div className="row">
                                                <div className="col-xs-12 col-md-4">
                                                    <Field
                                                        component={DateTimePicker}
                                                        disabled={disabled}
                                                        label="Requested Date"
                                                        name={`${fieldName}.${REQUESTED_DATE}`}
                                                    />
                                                </div>
                                                <div className="col-xs-12 col-md-4">
                                                    <Field
                                                        component={DateTimePicker}
                                                        disabled={disabled}
                                                        label="Approved Date"
                                                        name={`${fieldName}.${APPROVED_DATE}`}
                                                    />
                                                </div>
                                                <div className="col-xs-12 col-md-4">
                                                    <Field
                                                        component={DateTimePicker}
                                                        disabled={disabled}
                                                        label="Expiration Date"
                                                        name={`${fieldName}.${EXPIRATION_DATE}`}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className={`col-xs-12 col-md-1 ${this.styles.deleteIcon}`}
                                        >
                                            <Button
                                                aria-label="Remove Button"
                                                block
                                                bsSize="sm"
                                                bsStyle="link"
                                                className="pull-right"
                                                disabled={disabled}
                                                onClick={() => fields.remove(index)}
                                                qaTag="contractInsurancesForm-remove"
                                            >
                                                <i className="fa fa-lg fa-trash text-danger" />
                                            </Button>
                                        </div>
                                    </div>
                                </ListGroupItem>
                            );
                        })}
                    </ListGroup>
                ) : (
                    <ZeroState title="No insurance items have been added to this contract" />
                )}
            </>
        );
    };

    render() {
        const { handleSubmit, hasInsuranceTypeOptions } = this.props;

        if (!hasInsuranceTypeOptions) {
            return (
                <ZeroState title="At least one contract insurance type tag must exist before insurance can be added" />
            );
        }

        return (
            <form onSubmit={handleSubmit}>
                <FieldArray
                    component={this.renderContractInsurances}
                    name={CONTRACT_INSURANCES}
                    rerenderOnEveryChange
                />
            </form>
        );
    }
}

export const ContractInsurancesForm = compose(
    connect(mapStateToProps),
    reduxForm(formConfig)
)(ConnectedContractInsurancesForm);

const { priceItemFieldNames, discountTypes } = require('.');

const { QUANTITY, TAXABLE, UNIT_PRICE, DISCOUNT, DISCOUNT_TYPE } = priceItemFieldNames;

const DISCOUNT_PCT_CALCULATION_DIGITS = 4;

exports.isPercentageDiscount = (discountType) => {
    return discountType === discountTypes.PERCENTAGE;
};

exports.isAmountDiscount = (discountType) => {
    return discountType === discountTypes.DOLLAR_AMOUNT;
};

/**
 * @param {number} amount arbitrary floating point number
 * @returns Rounded number up to 2 decimal points
 */
exports.roundUpToNearestCent = (amount) => {
    const cents = amount * 100;

    // This takes care of numbers that have a miniscule decimal value
    // due to floating point precision error
    // e.g. 1.09*100 = 109.00000000000001
    const centsTwoPrecision = parseFloat(cents.toFixed(2));

    // Then round up to the nearest cent
    const roundedUpCents = Math.ceil(centsTwoPrecision);

    // Return as a dollar value
    return roundedUpCents / 100;
};

const addTax = (amount, taxRate) => {
    return amount + amount * (taxRate / 100);
};

exports.getPriceItemExtendedPrice = (quantity, unitPrice, discount, discountType, tax) => {
    if (!quantity || !unitPrice) {
        return 0;
    }

    let price = quantity * unitPrice;

    if (exports.isPercentageDiscount(discountType)) {
        const decimal = discount / 100;
        price -= Math.round(price * decimal * 10 ** DISCOUNT_PCT_CALCULATION_DIGITS) / 10000;
    }

    if (exports.isAmountDiscount(discountType)) {
        price -= discount;
    }

    if (tax) {
        price = addTax(price, tax);
    }

    return exports.roundUpToNearestCent(price);
};

exports.getPriceItemsSummary = (priceItems, salesTaxRate = 0) => {
    if (!priceItems || !Array.isArray(priceItems) || !priceItems.length) {
        return {
            subTotal: 0,
            taxes: 0,
            total: 0,
        };
    }

    let subTotal = 0;
    let taxableSubTotal = 0;

    priceItems.forEach((priceItem) => {
        const quantity = Number(priceItem[QUANTITY]);
        const unitPrice = Number(priceItem[UNIT_PRICE]);
        const discount = Number(priceItem[DISCOUNT]);
        const discountType = Number(priceItem[DISCOUNT_TYPE]);

        const itemTotal = exports.getPriceItemExtendedPrice(
            quantity,
            unitPrice,
            discount,
            discountType
        );
        subTotal += itemTotal;

        if (priceItem[TAXABLE]) {
            taxableSubTotal += itemTotal;
        }
    });

    const taxes = exports.roundUpToNearestCent(taxableSubTotal * (salesTaxRate / 100));
    const total = subTotal + taxes;

    return {
        subTotal,
        taxes,
        total,
    };
};

import classnames from 'classnames';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone/builds/moment-timezone-with-data-1970-2030';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ListGroupItem, Well } from 'react-bootstrap';

import { projectStatusesDict } from '@og-pro/shared-config/projects';

import { FilteredPriceTables, LinkContainer, ProjectStatusLabel, UserProfilePicture } from '../..';
import { statusClass } from '../../helpers/statusHelper';
import { ProjectListItemBadgeGroup } from '../ProjectListItemBadgeGroup';

const { CLOSED, DRAFT, FINAL, REVIEW } = projectStatusesDict;

export class ProjectsListItem extends PureComponent {
    static propTypes = {
        displayAwardsColumn: PropTypes.bool,
        lineItemContractInfo: PropTypes.object,
        hasSourcing: PropTypes.bool.isRequired,
        isDocBuilder: PropTypes.bool,
        isLoadingContractInfo: PropTypes.bool,
        priceItemDescriptionFilter: PropTypes.string,
        project: PropTypes.shape({
            id: PropTypes.number.isRequired,
            contact: PropTypes.shape({
                displayName: PropTypes.string.isRequired,
            }),
            created_at: PropTypes.string.isRequired,
            departmentName: PropTypes.string.isRequired,
            financialId: PropTypes.string,
            isDocBuilder: PropTypes.bool.isRequired,
            isPaused: PropTypes.bool.isRequired,
            priceTables: PropTypes.arrayOf(
                PropTypes.shape({
                    priceItems: PropTypes.arrayOf(
                        PropTypes.shape({
                            id: PropTypes.number.isRequired,
                            description: PropTypes.string.isRequired,
                        })
                    ).isRequired,
                })
            ),
            procurementContact: PropTypes.shape({
                displayName: PropTypes.string.isRequired,
            }),
            proposalDeadline: PropTypes.string,
            releaseProjectDate: PropTypes.string,
            status: PropTypes.string.isRequired,
            template: PropTypes.shape({
                processAcronym: PropTypes.string.isRequired,
            }).isRequired,
            title: PropTypes.string,
            updated_at: PropTypes.string.isRequired,
        }).isRequired,
        timezone: PropTypes.string.isRequired,
        to: PropTypes.string.isRequired,
    };

    get styles() {
        return require('../shared.scss');
    }

    get displayStatus() {
        const {
            isDocBuilder,
            project: { status },
        } = this.props;

        // Move all documents that have progressed beyond builder stage to `FINAL`
        if (isDocBuilder && ![DRAFT, REVIEW, CLOSED].includes(status)) {
            return FINAL;
        }

        return status;
    }

    renderStatusColumn(className) {
        const {
            isDocBuilder,
            project: { isPaused, status, updated_at: updatedAt },
        } = this.props;

        return (
            <div className={className} title={`Last updated ${moment(updatedAt).format('lll')}`}>
                <ProjectStatusLabel
                    className={classnames(
                        this.styles.statusLabel,
                        this.styles[statusClass(status)]
                    )}
                    isDocBuilder={isDocBuilder}
                    status={status}
                />
                <div className={`text-center ${this.styles.date}`}>
                    {moment(updatedAt).fromNow()}
                </div>
                {isPaused && (
                    <div className={this.styles.onHoldLabel}>
                        <i className="fa fa-lg fa-pause-circle" /> On Hold
                    </div>
                )}
            </div>
        );
    }

    renderTitleColumn() {
        const {
            hasSourcing,
            project: {
                financialId,
                template: { processAcronym },
                title: projectTitle,
            },
            project,
        } = this.props;

        return (
            <div className={classnames('col-xs-12 col-lg-4', this.styles.titleCol)}>
                <h4 className={this.styles.title}>{projectTitle || 'Untitled'}</h4>
                {financialId && (
                    <div className={this.styles.projectId}>
                        <span>Project ID:</span> {financialId}
                    </div>
                )}
                <div className={this.styles.projectId}>
                    <span>Process:</span> {processAcronym}
                </div>
                {hasSourcing && <ProjectListItemBadgeGroup project={project} />}
            </div>
        );
    }

    render() {
        const {
            displayAwardsColumn,
            lineItemContractInfo,
            isLoadingContractInfo,
            priceItemDescriptionFilter,
            project: {
                id: projectId,
                contact,
                created_at: createdAt,
                departmentName,
                isDocBuilder,
                priceTables,
                procurementContact,
                proposalDeadline,
                releaseProjectDate,
            },
            timezone,
            to,
        } = this.props;

        const unassignedUser = { displayName: 'Unassigned' };
        const contactUser = contact || unassignedUser;
        const procurementContactUser = procurementContact || unassignedUser;

        return (
            <>
                <LinkContainer to={to}>
                    <ListGroupItem
                        className={classnames(this.styles.item, this.styles.projectListItem)}
                    >
                        <div className="row">
                            {this.renderStatusColumn(
                                classnames(
                                    'col-xs-12 col-sm-4 col-lg-2 visible-lg-block',
                                    this.styles.skinnyColRight
                                )
                            )}
                            {this.renderTitleColumn()}
                            {this.renderStatusColumn(
                                classnames(
                                    'col-xs-12 col-sm-4 col-lg-2 hidden-lg',
                                    this.styles.statusCol
                                )
                            )}
                            <div
                                className={classnames(
                                    'col-xs-12 col-sm-4 col-lg-3',
                                    this.styles.skinnyCol,
                                    this.styles.deptCol
                                )}
                            >
                                <div className={this.styles.department}>
                                    <i className="fa fa-university" /> {departmentName}
                                </div>
                                {!isDocBuilder && (
                                    <>
                                        <div className={this.styles.date}>
                                            <div className={this.styles.dateLabel}>Created:</div>
                                            <div className={this.styles.dateValue}>
                                                {moment.tz(createdAt, timezone).format('M/D/YY')}{' '}
                                                {moment.tz(createdAt, timezone).format('h:mma')}
                                            </div>
                                        </div>
                                        <div className={this.styles.date}>
                                            <div className={this.styles.dateLabel}>
                                                Release Date:
                                            </div>
                                            <div className={this.styles.dateValue}>
                                                {releaseProjectDate
                                                    ? moment
                                                          .tz(releaseProjectDate, timezone)
                                                          .format('M/D/YY')
                                                    : 'TBD'}
                                            </div>
                                        </div>
                                        <div className={this.styles.date}>
                                            <div className={this.styles.dateLabel}>
                                                Proposals Due:
                                            </div>
                                            <div className={this.styles.dateValue}>
                                                {proposalDeadline
                                                    ? `${moment
                                                          .tz(proposalDeadline, timezone)
                                                          .format('M/D/YY')} ${moment
                                                          .tz(proposalDeadline, timezone)
                                                          .format('h:mma')}`
                                                    : 'TBD'}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div
                                className={classnames(
                                    'col-xs-12 col-sm-4 col-lg-3',
                                    this.styles.skinnyCol
                                )}
                            >
                                <div>
                                    <UserProfilePicture
                                        className={this.styles.userProfilePicture}
                                        horizontal
                                        user={contactUser}
                                    />
                                    <div className={this.styles.user}>
                                        {contactUser.displayName}
                                        <div className={this.styles.contactRole}>
                                            Project Contact
                                        </div>
                                    </div>
                                </div>
                                <div className={this.styles.procurementContact}>
                                    <UserProfilePicture
                                        className={this.styles.userProfilePicture}
                                        horizontal
                                        user={procurementContactUser}
                                    />
                                    <div className={this.styles.user}>
                                        {procurementContactUser.displayName}
                                        <div className={this.styles.contactRole}>
                                            Procurement Contact
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ListGroupItem>
                </LinkContainer>
                {!isEmpty(priceItemDescriptionFilter) && Array.isArray(priceTables) && (
                    <Well className={this.styles.priceTableWell}>
                        <FilteredPriceTables
                            descriptionFilter={priceItemDescriptionFilter}
                            displayAwardsColumn={displayAwardsColumn}
                            isLoadingContractInfo={isLoadingContractInfo}
                            lineItemContractInfo={lineItemContractInfo}
                            priceTables={priceTables}
                            projectId={projectId}
                        />
                    </Well>
                )}
            </>
        );
    }
}

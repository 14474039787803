import { createSelector } from 'reselect';

import { userRolePermissions } from '@og-pro/shared-config/userRoles';

import { hasUserRolesSubscription } from '../../containers/GovApp/selectors';
import { getInvitedUsersJS } from '../../containers/selectors';
import { userHasPermissionRole } from '../../helpers';

const { IS_REQUISITION_GROUP_ADMIN } = userRolePermissions;

const getRawGovernmentReqSetting = (state) => state.adminGovernment.get('reqSetting');

export const getRequisitionGroupAdminUserList = createSelector(
    [getInvitedUsersJS, hasUserRolesSubscription],
    (users, hasUserRoles) => {
        return users
            .filter((user) => userHasPermissionRole(user, IS_REQUISITION_GROUP_ADMIN, hasUserRoles))
            .map((user) => ({
                label: user.displayName,
                user,
                value: user.id,
            }));
    }
);

export const getGovernmentReqSetting = createSelector(
    [getRawGovernmentReqSetting],
    (reqSetting) => {
        return reqSetting && reqSetting.toJS();
    }
);

import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { LibrarySearch as LibrarySearchComponent } from './components';
import { ProjectLibraryHOC } from './hocs';

const ConnectedLibrarySearch = ProjectLibraryHOC(LibrarySearchComponent);

export const LibrarySearch = () => {
    const { governmentId } = useParams();
    const navigate = useNavigate();

    const projectSelector = (project) => {
        return navigate(`/governments/${governmentId}/project-library/${project.id}`);
    };

    return <ConnectedLibrarySearch projectClickHandler={projectSelector} />;
};

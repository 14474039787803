import { orderBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ListGroup } from 'react-bootstrap';

import { IntakesListItem } from '../IntakesListItem';

export class IntakesList extends PureComponent {
    static propTypes = {
        governmentId: PropTypes.number.isRequired,
        projects: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                intakeProject: PropTypes.shape({
                    id: PropTypes.number.isRequired,
                }),
                projectApprovalSteps: PropTypes.arrayOf(
                    PropTypes.shape({
                        projectApprovals: PropTypes.arrayOf(
                            PropTypes.shape({
                                user: PropTypes.object.isRequired,
                            })
                        ),
                    })
                ),
                status: PropTypes.string.isRequired,
                title: PropTypes.string,
                user: PropTypes.shape({
                    displayName: PropTypes.string.isRequired,
                }).isRequired,
            })
        ).isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            sortDirection: undefined,
            sortedProjects: props.projects.map((project) => ({
                ...project,
                sortTitle: (project.title || 'untitled').toLowerCase(),
            })),
            sortField: undefined,
        };
    }

    DIRECTIONS = {
        asc: 'desc',
        desc: 'asc',
    };

    sortProjects = (field) => () => {
        this.setState((prevState) => {
            const sortDirection =
                prevState.sortField === field
                    ? this.DIRECTIONS[prevState.sortDirection]
                    : this.DIRECTIONS.desc;

            return {
                sortDirection,
                sortedProjects: orderBy(prevState.sortedProjects, field, [sortDirection]),
                sortField: field,
            };
        });
    };

    get styles() {
        return require('../shared.scss');
    }

    renderHeader = (props) => {
        const { className, field, label } = props;

        const { sortField, sortDirection } = this.state;

        const isSorted = field === sortField;

        return (
            <div className={className}>
                <strong className={this.styles.header} onClick={this.sortProjects(field)}>
                    {label}
                </strong>
                &nbsp;
                {isSorted && <i className={`fa fa-fw fa-sort-alpha-${sortDirection}`} />}
            </div>
        );
    };

    render() {
        const { governmentId } = this.props;

        const { sortedProjects } = this.state;

        const pathname = `/governments/${governmentId}/projects`;

        const intakeListItems = sortedProjects.map((project) => {
            const projectId = project.intakeProject ? project.intakeProject.id : project.id;

            return (
                <IntakesListItem
                    key={project.id}
                    project={project}
                    to={`${pathname}/${projectId}/manage`}
                />
            );
        });

        return <ListGroup>{intakeListItems}</ListGroup>;
    }
}

import { Button, CircularProgress } from '@og-pro/ui';
import { circularProgressSizeProps } from '@opengov/capital-mui-theme';
import {
    Download as DownloadIcon,
    Inventory as InventoryIcon,
    Scanner as ScannerIcon,
} from '@mui/icons-material';
import React from 'react';
import PropTypes from 'prop-types';

import { detectOS, LINUX, MAC_OS, UNIX, WINDOWS } from './detectOs';

const setupFileNames = {
    [WINDOWS]: 'DynamsoftServiceSetup.msi',
    [MAC_OS]: 'DynamsoftServiceSetup.pkg',
    [UNIX]: 'DynamsoftServiceSetup.deb',
    [LINUX]: 'DynamsoftServiceSetup.rpm',
};

export const ScannedPagesViewer = ({
    isWorking,
    isReadRForFirstScan,
    isScannerSourceSelected,
    noScannerFound,
    acquireImage,
    needsSetup,
}) => {
    const os = detectOS();
    const styles = require('./index.scss');

    return (
        <div className={styles.emptyStateViewport}>
            <div className={styles.fakeThumbnail} />
            <div className={styles.noImagesMessage}>
                {isWorking && (
                    <h3 className={styles.workingMessage}>
                        <CircularProgress
                            {...circularProgressSizeProps.large}
                            color="primaryShadowed"
                        />
                        Working...
                    </h3>
                )}
                {needsSetup && (
                    <div className={styles.setupInstructionsContainer}>
                        <h3>To Scan, Complete this One-Time Setup</h3>
                        <div className={styles.setupInstructionsIcons}>
                            <div>
                                <DownloadIcon />
                                <p>Download</p>
                            </div>
                            <div>
                                <InventoryIcon />
                                <p>Install</p>
                            </div>
                            <div>
                                <ScannerIcon />
                                <p>Scan</p>
                            </div>
                        </div>
                        {os ? (
                            <Button
                                color="primary"
                                href={`https://assets.procurement.opengov.com/dynamsoft/${setupFileNames[os]}`}
                                qaTag="scanning-downloadSetup"
                                variant="contained"
                            >
                                Download and Set Up
                            </Button>
                        ) : (
                            <p>Sorry, your operating system is not supported</p>
                        )}
                    </div>
                )}
                {noScannerFound && (
                    <>
                        <ScannerIcon />
                        <h3>No scanner found</h3>
                        <p>
                            We couldn&apos;t find any scanner connected to your computer. Please
                            check the network or cable connection.
                        </p>
                    </>
                )}
                {isReadRForFirstScan && (
                    <>
                        <ScannerIcon />
                        <h3>Ready to Scan?</h3>
                        <p>Add what you want to scan to your scanner, then hit Scan.</p>
                        <Button
                            color="primary"
                            disabled={!isScannerSourceSelected}
                            onClick={() => acquireImage()}
                            qaTag="scanning-scan"
                            variant="outlined"
                        >
                            Scan
                        </Button>
                    </>
                )}
            </div>
        </div>
    );
};

ScannedPagesViewer.propTypes = {
    isWorking: PropTypes.bool,
    isReadRForFirstScan: PropTypes.bool,
    isScannerSourceSelected: PropTypes.bool,
    noScannerFound: PropTypes.bool,
    acquireImage: PropTypes.func,
    needsSetup: PropTypes.bool,
};

import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';

import { fieldNames } from './constants';
import {
    InputText,
    SearchSelect,
    SearchSelectIconOption,
    SearchSelectIconValue,
    SearchSelectUserOption,
} from '../../../../../components';
import { iconSelectOptions } from '../../../../../constants/templateAdmin';
import { limitTextLength } from '../../../../../Forms/normalizers';
import { getRequisitionGroupAdminUserList } from '../../../../../selectors/govApp';

const { ABBREVIATION, ICON, NAME, USER_ID } = fieldNames;

const normalizeAbbreviation = limitTextLength(32);
const normalizeName = limitTextLength(128);

export const RequisitionGroupInfoSection = ({ disabled, showFormValidation }) => {
    const requisitionGroupAdminUserSelectOptions = useSelector(getRequisitionGroupAdminUserList);

    return (
        <div className="row">
            <div className="col-md-3">
                <Field
                    component={InputText}
                    disabled={disabled}
                    hasFeedback={false}
                    help="Name for identifying the group"
                    label="Group Name"
                    name={NAME}
                    normalize={normalizeName}
                    placeholder="Enter name"
                    qaTag="requisitionGroupForm-title"
                    showValidation={showFormValidation}
                    type="text"
                />
            </div>
            <div className="col-md-3">
                <Field
                    component={InputText}
                    disabled={disabled}
                    hasFeedback={false}
                    help="Abbreviation (can be included in request ID)"
                    label="Group Abbreviation"
                    name={ABBREVIATION}
                    normalize={normalizeAbbreviation}
                    placeholder="Enter abbreviation"
                    qaTag="requisitionGroupForm-abbreviation"
                    showValidation={showFormValidation}
                    type="text"
                />
            </div>
            <div className="col-md-3">
                <Field
                    component={SearchSelect}
                    components={{
                        Option: SearchSelectIconOption,
                        SingleValue: SearchSelectIconValue,
                    }}
                    disabled={disabled}
                    help="Visual identifier for this group"
                    label="Group Icon"
                    name={ICON}
                    options={iconSelectOptions}
                    placeholder="Select an icon to use"
                    showValidation={showFormValidation}
                />
            </div>
            <div className="col-md-3">
                <Field
                    backspaceRemovesValue={false}
                    component={SearchSelect}
                    components={{
                        Option: SearchSelectUserOption,
                    }}
                    disabled={disabled}
                    help="User who owns the group (must be an admin)"
                    label="Group Owner"
                    name={USER_ID}
                    options={requisitionGroupAdminUserSelectOptions}
                    placeholder="Select request group owner"
                    showValidation={showFormValidation}
                />
            </div>
        </div>
    );
};

RequisitionGroupInfoSection.propTypes = {
    disabled: PropTypes.bool,
    showFormValidation: PropTypes.bool,
};

import React from 'react';
import {
    fieldStatuses,
    fieldStatusesDict,
} from '@og-pro/shared-config/customFormService/customField';
import PropTypes from 'prop-types';

import { TemplateStatusLabel } from '../../../../components';

export const CustomFieldStatusTag = ({ status, variant = 'filled' }) => {
    if (status === fieldStatusesDict.PUBLISHED) {
        return <TemplateStatusLabel isPublished size="small" useOpenGovStyle variant={variant} />;
    }
    if (status === fieldStatusesDict.DRAFT) {
        return <TemplateStatusLabel size="small" useOpenGovStyle variant={variant} />;
    }
    if (status === fieldStatusesDict.ARCHIVED) {
        return <TemplateStatusLabel isArchived size="small" useOpenGovStyle variant={variant} />;
    }
    return null;
};

CustomFieldStatusTag.propTypes = {
    status: PropTypes.oneOf(fieldStatuses),
    variant: PropTypes.string,
};

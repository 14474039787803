import { Field, getFormValues } from 'redux-form';
import PropTypes from 'prop-types';
import React from 'react';

import { useSelector } from 'react-redux';

import { IMAGE_CONTROLS_SECTION, Section } from './ScanningControlsPanel';
import { Slider } from '../..';
import { formConfig } from '../form';
import { fieldNames } from '../constants';

const SLIDER_MARKS = [
    { value: -1000, label: '-100' },
    { value: 0, label: '0' },
    { value: 1000, label: '100' },
];

const { IMAGE_BRIGHTNESS, IMAGE_CONTRAST } = fieldNames;

export const ImageControlsSection = ({ DWObject }) => {
    const styles = require('../index.scss');

    const { [IMAGE_BRIGHTNESS]: brightness, [IMAGE_CONTRAST]: contrast } = useSelector(
        (state) => getFormValues(formConfig.form)(state) || {}
    );

    return (
        <Section id={IMAGE_CONTROLS_SECTION} title="Image Controls">
            <Field
                className={styles.slider}
                component={Slider}
                defaultValue={0}
                disabled={!DWObject}
                label={`Brightness: ${brightness / 10 || 0}`}
                marks={SLIDER_MARKS}
                max={1000}
                min={-1000}
                name={IMAGE_BRIGHTNESS}
                qaTag="scanning-brightness"
                step={100}
                useOpenGovStyle
            />
            <Field
                className={styles.slider}
                component={Slider}
                defaultValue={0}
                disabled={!DWObject}
                label={`Contrast: ${contrast / 10 || 0}`}
                marks={SLIDER_MARKS}
                max={1000}
                min={-1000}
                name={IMAGE_CONTRAST}
                qaTag="scanning-contrast"
                step={100}
                useOpenGovStyle
            />
        </Section>
    );
};

ImageControlsSection.propTypes = {
    DWObject: PropTypes.object,
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { PageTitle } from '../../../components';
import { LoginTitle } from '../components/LoginTitle';

const mapStateToProps = (state) => {
    return {
        email: state.auth.get('resentActivationEmail'),
    };
};

// @connect
class ConnectedResendActivation extends Component {
    static propTypes = {
        email: PropTypes.string.isRequired,
    };

    render() {
        const { email } = this.props;

        return (
            <div className="text-center">
                <PageTitle title="Resend Activation" />
                <i className="text-primary fa fa-user-circle-o fa-3x" />
                <LoginTitle className="text-primary">
                    Your OpenGov Procurement account still needs
                    <br />
                    to be activated!
                </LoginTitle>
                <p>
                    We just re-sent your activation email to
                    <br />
                    <strong>{email}</strong>
                </p>
                <p>Please check your inbox and follow the instructions to activate your account</p>
                <p>
                    If you need help activating your account please email us at
                    <br />
                    <a href="mailto:procurement-support@opengov.com">
                        procurement-support@opengov.com
                    </a>
                </p>
            </div>
        );
    }
}

export const ResendActivation = connect(mapStateToProps)(ConnectedResendActivation);

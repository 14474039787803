import AddIcon from '@mui/icons-material/Add';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import { Box, Button, Typography } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { qaTagPageName } from '../../../../constants';
import { hasContractingSubscription } from '../../../../../../GovApp/selectors';

export const AddVendor = ({ canAddVendor, onClick }) => {
    const hasContracting = useSelector(hasContractingSubscription);

    return (
        <Box>
            <Button
                color="secondary"
                disabled={!canAddVendor}
                onClick={onClick}
                qaTag={`${qaTagPageName}-addVendorFromApprovalPage`}
                startIcon={<AddIcon />}
                tooltip={
                    canAddVendor
                        ? undefined
                        : 'Add more line items before adding another vendor. There must be unassigned line items to add more vendors.'
                }
                variant="outlined"
            >
                Add Vendor
            </Button>
            <Typography
                color={capitalDesignTokens.semanticColors.foreground.secondary}
                marginLeft={1}
                variant="span"
            >
                by searching for a vendor{hasContracting && ' or contract'}
            </Typography>
        </Box>
    );
};

AddVendor.propTypes = {
    canAddVendor: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
};

const { listToDict } = require('../helpers');

exports.standardDocumentTypes = [
    'proposalViewerAgreement',
    'awardNotice',
    'interviewInvitation',
    'nonAwardLetter',
];

exports.standardDocumentTypeNames = listToDict(exports.standardDocumentTypes);

exports.singleActiveDocumentTypes = [exports.standardDocumentTypeNames.PROPOSAL_VIEWER_AGREEMENT];

exports.attachmentOptionDocumentTypes = [
    exports.standardDocumentTypeNames.PROPOSAL_VIEWER_AGREEMENT,
];

exports.singleDocumentTypes = [
    exports.standardDocumentTypeNames.AWARD_NOTICE,
    exports.standardDocumentTypeNames.INTERVIEW_INVITATION,
    exports.standardDocumentTypeNames.NON_AWARD_LETTER,
];

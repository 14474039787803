import { ALL_SECTIONS, formSectionNames } from '../constants';
import validateCreators from './validateCreators';
import validateInfo from './validateInfo';
import validateSequence from './validateSequence';
import { sectionsValidate } from '../../../../../../Forms/validation';
import { hasFormErrors } from '../../../../../../helpers';

const { INFO, CREATORS, SEQUENCE } = formSectionNames;

export const validate = (values) => {
    const infoErrors = validateInfo(values);
    const creatorsErrors = validateCreators(values);
    const sequenceErrors = validateSequence(values);

    const sectionErrors = sectionsValidate(
        [INFO, infoErrors],
        [CREATORS, creatorsErrors],
        [SEQUENCE, sequenceErrors]
    );

    return {
        [ALL_SECTIONS]: hasFormErrors(sectionErrors),
        ...infoErrors,
        ...creatorsErrors,
        ...sequenceErrors,
        ...sectionErrors,
    };
};

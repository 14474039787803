import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { categoryNames } from '@og-pro/shared-config/categories';

import { Button, Tooltip } from '../../../components';

export class SelectedCode extends PureComponent {
    static propTypes = {
        categoryId: PropTypes.number.isRequired,
        code: PropTypes.string.isRequired,
        deleteHandler: PropTypes.func.isRequired,
        setId: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
    };

    get styles() {
        return require('../shared.scss');
    }

    deleteHandler = () => {
        const { categoryId, deleteHandler } = this.props;

        return deleteHandler(categoryId);
    };

    render() {
        const { code, setId, title } = this.props;

        const setName = categoryNames[setId];

        return (
            <Tooltip placement="bottom" tooltip={`${setName}: ${title}`}>
                <div className={this.styles.multiSelectValueContainer}>
                    <div
                        aria-selected="true"
                        className={this.styles.multiSelectValueLabel}
                        role="option"
                    >
                        {code}
                        <div className={this.styles.multiSelectValueSetName}>{setName}</div>
                    </div>
                    <Button
                        aria-hidden="true"
                        bsStyle="link"
                        className={this.styles.multiSelectValueIcon}
                        onClick={this.deleteHandler}
                        tabIndex={0}
                    >
                        &times;
                    </Button>
                </div>
            </Tooltip>
        );
    }
}

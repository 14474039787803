import { find, get, pick, round } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { HelpBlock, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { Box } from '@og-pro/ui';

import { purchaseOrderStatuses } from '@og-pro/shared-config/purchaseOrders';

import { fieldNames, fields, form } from './constants';
import {
    getContractEvaluations,
    getContractPartySelectOptions,
    getFormBudgetAllocation,
    getFormFiscalYearTag,
    getFormPurchaseOrders,
    getLineItemSelectOptions,
    getPurchaseOrderPriceItems,
    getPurchaseOrderPriceTables,
    getSelectedContractPartyId,
    getSelectedVendorPriceItem,
    NO_LINE_ITEM,
    purchaseOrderStatusOptions,
} from './selectors';
import { validate } from './validate';
import { getDepartmentsSelectOptions, getFiscalYearSelectOptions } from '../../../selectors';
import {
    createPurchaseOrder,
    deletePurchaseOrder,
    updatePurchaseOrder,
} from '../../../../actions/contracts';
import { showConfirmationSimpleModal } from '../../../../actions/confirmation';
import {
    Button,
    CDSButton,
    DateTimePicker,
    HoverButton,
    InputText,
    Label,
    LoadingError,
    LoadingSpinner,
    OutlineButton,
    PurchaseOrderPriceTable,
    SearchSelect,
    Toggle,
} from '../../../../components';
import { maskNumberWithCommas } from '../../../../Forms/maskers';
import { dollarString } from '../../../../Forms/normalizers';
import { currencyFormatter } from '../../../../helpers';
import { MaskedInputText } from '../../../../hocs';
import { getContractBudgetJS } from '../selectors';
import { suppressKeyboardEvent } from '../../../../helpers/agGrid';
import { BudgetPurchaseOrdersOverview } from '../BudgetPurchaseOrdersOverview';

const {
    AMOUNT,
    CONTRACT_PARTY_ID,
    DATE,
    DEPARTMENT_ID,
    DESCRIPTION,
    NOTES,
    NUMBER,
    PAID_AMOUNT,
    PRICE_ITEM_ID,
    STATUS,
    SHOW_BID_RESULTS,
    TAG_ID,
} = fieldNames;

const { ISSUED } = purchaseOrderStatuses;

const mapStateToProps = (state, props) => {
    const parsedAmount = Number.parseFloat(formValueSelector(form)(state, AMOUNT));
    const amountFormValue = Number.isNaN(parsedAmount) ? null : parsedAmount;

    const parsedPaidAmount = Number.parseFloat(formValueSelector(form)(state, PAID_AMOUNT));
    const paidAmountFormValue = Number.isNaN(parsedPaidAmount) ? 0 : parsedPaidAmount;

    const initialValues = {
        ...props.initialValues,
        ...pick(props.purchaseOrder, fields),
        [DATE]: get(props.purchaseOrder, DATE) ? new Date(props.purchaseOrder[DATE]) : null,
        [PRICE_ITEM_ID]:
            get(props.purchaseOrder, PRICE_ITEM_ID) ||
            parseInt(props.location.query['line-item'], 10) ||
            NO_LINE_ITEM,
    };

    const budgetPriceItems = getPurchaseOrderPriceItems(state);
    const contractBudget = getContractBudgetJS(state);
    const contractEvaluations = getContractEvaluations(state);
    const selectedVendorPriceItem = getSelectedVendorPriceItem(state);

    if (!props.purchaseOrder) {
        const lineItemId = parseInt(props.location.query['line-item'], 10);
        const selectedLineItem = find(budgetPriceItems, { id: lineItemId });

        if (selectedLineItem) {
            if (contractEvaluations[0].isLineItemAward) {
                // Parse contract party ID associated with the selected line item
                const vendorId = get(selectedVendorPriceItem, 'proposal.vendor.id');
                const contractParty = find(contractBudget.contracts, {
                    contractParty: { vendor_id: vendorId },
                });

                initialValues[CONTRACT_PARTY_ID] =
                    get(contractParty, 'id') || initialValues[CONTRACT_PARTY_ID];
            } // else isLowestCost; all vendors my be assigned any line item

            initialValues[PRICE_ITEM_ID] = lineItemId;
        }
    }

    return {
        amountFormValue,
        budgetPriceItems,
        budgetPriceTables: getPurchaseOrderPriceTables(state, props.purchaseOrder),
        contractBudget: getContractBudgetJS(state),
        contractEvaluations,
        contractPartySelectOptions: getContractPartySelectOptions(state),
        departmentSelectOptions: getDepartmentsSelectOptions(state),
        fiscalYearSelectOptions: getFiscalYearSelectOptions(state),
        initialValues,
        lineItemSelectOptions: getLineItemSelectOptions(state),
        loadError: state.contracts.get('loadContractLineItemsError'),
        loading: state.contracts.get('loadingContractLineItems'),
        paidAmountFormValue,
        selectedBudgetAllocation: getFormBudgetAllocation(state),
        selectedContractPartyId: getSelectedContractPartyId(state),
        selectedFiscalYearTag: getFormFiscalYearTag(state),
        selectedLineItemId: formValueSelector(form)(state, PRICE_ITEM_ID),
        selectedPurchaseOrders: getFormPurchaseOrders(state),
        selectedVendorPriceItem,
        statusFormValue: formValueSelector(form)(state, STATUS),
    };
};

const mapDispatchToProps = {
    createPurchaseOrder,
    deletePurchaseOrder,
    showConfirmationSimpleModal,
    updatePurchaseOrder,
};

const formConfig = {
    form,
    validate,
};

// @connect
// @reduxForm
class ConnectedPurchaseOrderFormModal extends Component {
    static getDerivedStateFromProps(props, state) {
        const { budgetPriceTables, purchaseOrder, selectedLineItemId } = props;
        const isSelected = selectedLineItemId && selectedLineItemId !== -1;
        const hasPercentage = budgetPriceTables.some((priceTable) => priceTable.hasPercentage);

        // if any table has unit price as percentage, don't show tables
        if (hasPercentage) {
            return {
                ...state,
                hasPercentage,
                showBidResults: false,
            };
        }

        if (isSelected) {
            return {
                ...state,
                showBidResults: true,
            };
        }

        // if the purchase order doesn't have a quantity, it has price items
        if (purchaseOrder && purchaseOrder.priceItems.length > 0) {
            return {
                ...state,
                showBidResults: true,
            };
        }

        return state;
    }

    static propTypes = {
        amountFormValue: PropTypes.number,
        budget: PropTypes.shape({
            amount: PropTypes.number,
            issued: PropTypes.number.isRequired,
            paid: PropTypes.number.isRequired,
        }).isRequired,
        budgetPriceItems: PropTypes.array,
        budgetPriceTables: PropTypes.array,
        change: PropTypes.func,
        contractId: PropTypes.number.isRequired,
        contractBudget: PropTypes.shape({ contracts: PropTypes.array }),
        // contractEvaluations is used in `mapStateToProps`
        // eslint-disable-next-line react/no-unused-prop-types
        contractEvaluations: PropTypes.arrayOf(
            PropTypes.shape({
                isLineItemAward: PropTypes.bool,
            })
        ),
        contractPartySelectOptions: PropTypes.array.isRequired,
        createPurchaseOrder: PropTypes.func.isRequired,
        deletePurchaseOrder: PropTypes.func.isRequired,
        departmentSelectOptions: PropTypes.array.isRequired,
        dirty: PropTypes.bool.isRequired,
        initialize: PropTypes.func.isRequired,
        isEditor: PropTypes.bool.isRequired,
        loadError: PropTypes.string,
        loading: PropTypes.bool.isRequired,
        fiscalYearSelectOptions: PropTypes.array.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        hideModal: PropTypes.func.isRequired,
        isCreateForm: PropTypes.bool,
        location: PropTypes.shape({
            query: PropTypes.shape({
                'line-item': PropTypes.string,
            }),
        }),
        paidAmountFormValue: PropTypes.number,
        purchaseOrder: PropTypes.shape({
            amount: PropTypes.number.isRequired,
            id: PropTypes.number.isRequired,
            isPaid: PropTypes.bool.isRequired,
            isIssued: PropTypes.bool.isRequired,
            paidAmount: PropTypes.number,
            priceItems: PropTypes.array,
            status: PropTypes.string.isRequired,
            statusText: PropTypes.string.isRequired,
            user: PropTypes.object.isRequired,
        }),
        selectedBudgetAllocation: PropTypes.shape({
            amount: PropTypes.number.isRequired,
        }),
        selectedContractPartyId: PropTypes.number,
        selectedFiscalYearTag: PropTypes.object,
        selectedLineItemId: PropTypes.number,
        selectedPurchaseOrders: PropTypes.array.isRequired,
        showConfirmationSimpleModal: PropTypes.func.isRequired,
        statusFormValue: PropTypes.number,
        updatePurchaseOrder: PropTypes.func.isRequired,
        user: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            hasPercentage: false,
            isViewMode: !props.isCreateForm,
            showBidResults: false,
            updateError: null,
            updating: false,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    componentDidUpdate(prevProps) {
        const { budgetPriceItems, change, selectedContractPartyId, selectedLineItemId } =
            this.props;

        const selectedLineItem = find(budgetPriceItems, { id: selectedLineItemId });

        const didLineItemChange =
            prevProps.selectedLineItemId !== undefined &&
            prevProps.selectedLineItemId !== selectedLineItemId;

        const didContractPartyChange =
            prevProps.selectedContractPartyId !== undefined &&
            prevProps.selectedContractPartyId !== selectedContractPartyId;

        if (didLineItemChange) {
            if (selectedLineItemId === NO_LINE_ITEM) {
                change(AMOUNT, undefined);
            }

            change(DESCRIPTION, selectedLineItem ? selectedLineItem.description : '');
        }

        // Reset line item field when vendor selection changes
        if (didContractPartyChange) {
            change(PRICE_ITEM_ID, NO_LINE_ITEM);
        }
    }

    hideModal = (options = {}) => {
        const { dirty, hideModal } = this.props;
        const { force } = options;

        // Confirm exit if form is dirty and not forcing hide
        if (
            force ||
            !dirty ||
            window.confirm('Order form has unsaved changes. Are you sure you want to exit?') // eslint-disable-line no-alert
        ) {
            hideModal();
        }
    };

    MaskedBudgetInput = MaskedInputText(InputText);

    deleteHandler = () => {
        const { contractId, purchaseOrder } = this.props;

        const deleteHandler = () => {
            this.setState({ updating: true, updateError: null });
            this.props
                .deletePurchaseOrder(contractId, purchaseOrder.id)
                .then(() => {
                    this.hideModal({ force: true });
                })
                .catch((error) => {
                    this.setState({ updating: false, updateError: error.message });
                });
        };

        this.props.showConfirmationSimpleModal(deleteHandler, {});
    };

    statusHandler = () => {
        const { contractId, initialize, purchaseOrder } = this.props;
        this.setState({ updating: true, updateError: null });
        this.props
            .updatePurchaseOrder(contractId, purchaseOrder.id, { field: [STATUS] })
            .then(() => {
                const updatedPurchaseOrder = { ...purchaseOrder };
                initialize({
                    ...updatedPurchaseOrder,
                    [DATE]: new Date(updatedPurchaseOrder[DATE]),
                });
                this.setState({ updating: false });
            })
            .catch((error) => {
                this.setState({ updating: false, updateError: error.message });
            });
    };

    submitHandler = ({ price_item_id: priceItemId, ...data }) => {
        const { budgetPriceTables, contractId, isCreateForm, purchaseOrder } = this.props;
        const priceItems = budgetPriceTables.flatMap((table) => {
            return table.priceItems
                .filter((item) => item.edited)
                .map((item) => ({
                    price_item_id: item.id,
                    quantity: item.quantityRequested,
                    unitPrice: item.unitPrice,
                    discount: item.discount,
                }));
        });

        const paidAmount = data.status === ISSUED ? data.paidAmount : 0;
        const submitData = {
            ...data,
            paidAmount,
            priceItems,
        };

        this.setState({ updating: true, updateError: null });
        const updatePromise = isCreateForm
            ? this.props.createPurchaseOrder(contractId, submitData)
            : this.props.updatePurchaseOrder(contractId, purchaseOrder.id, submitData);

        updatePromise
            .then(() => {
                this.hideModal({ force: true });
            })
            .catch((error) => {
                this.setState({ updating: false, updateError: error.message });
            });
    };

    handleCellValueChanged = (event) => {
        const { budgetPriceTables, change, user } = this.props;
        const salesTaxMultiplier = 1 + user.government.salesTax / 100;

        budgetPriceTables.forEach((priceTable) => {
            const priceItemIndex = priceTable.priceItems.findIndex((priceItem) => {
                return priceItem.id === event.data.priceItemId;
            });

            if (priceItemIndex !== -1) {
                const columnFieldOfUpdatedCell = event.column.colDef.field;
                // modifying prop here triggers AgGrid re-render
                priceTable.priceItems[priceItemIndex][columnFieldOfUpdatedCell] = event.newValue;
                priceTable.priceItems[priceItemIndex].edited = true;
            }
        });

        // update the amount field to match sum of tables
        const amount = budgetPriceTables.reduce((acc, table) => {
            return (
                acc +
                table.priceItems.reduce((tableAcc, item) => {
                    const unitCost =
                        item.quantityRequested * item.unitPrice * (1 - item.discount / 100); // discount is stored as an integer
                    if (item.taxable) {
                        return tableAcc + unitCost * salesTaxMultiplier;
                    }

                    return tableAcc + unitCost;
                }, 0)
            );
        }, 0);

        change(AMOUNT, round(amount, 2));
    };

    onShowBidResultsChange = (e) => {
        e.preventDefault();
        this.setState({ showBidResults: e.target.checked });
    };

    renderStatusButton() {
        const { isEditor, purchaseOrder } = this.props;

        const { updateError, updating } = this.state;

        if (purchaseOrder.isPaid) {
            return (
                <>
                    <Button
                        active
                        block
                        bsStyle="success"
                        disabled={!isEditor || updating}
                        onClick={this.statusHandler}
                        tooltip={isEditor ? undefined : 'Only contract editors can update orders'}
                    >
                        <i className="fa fa-check-square-o" /> Paid
                    </Button>
                    <HelpBlock>{updateError || 'Click to unmark as paid'}</HelpBlock>
                </>
            );
        }

        if (purchaseOrder.isIssued) {
            return (
                <>
                    <HoverButton
                        block
                        disabled={!isEditor || updating}
                        hoverChildren={
                            <span>
                                <i className="fa fa-check" /> Mark Paid
                            </span>
                        }
                        hoverStyle="success"
                        onClick={this.statusHandler}
                        tooltip={isEditor ? undefined : 'Only contract editors can update orders'}
                    >
                        <span className="text-primary">
                            <i className="fa fa-send" /> Issued
                        </span>
                    </HoverButton>
                    <HelpBlock>{updateError || 'Click to mark as paid'}</HelpBlock>
                </>
            );
        }
    }

    updatePurchaseOrderPaidAmount = (markAsPaid) => {
        const { contractId, initialize, purchaseOrder } = this.props;
        this.setState({ updating: true, updateError: null });

        let newPaidAmount = 0;
        if (markAsPaid) {
            newPaidAmount = purchaseOrder.amount;
        }

        this.props
            .updatePurchaseOrder(
                contractId,
                purchaseOrder.id,
                { [PAID_AMOUNT]: newPaidAmount },
                { field: [PAID_AMOUNT] }
            )
            .then(() => {
                const updatedPurchaseOrder = { ...purchaseOrder };
                initialize({
                    ...updatedPurchaseOrder,
                    [PAID_AMOUNT]: newPaidAmount,
                    [DATE]: new Date(updatedPurchaseOrder[DATE]),
                });
                this.setState({ updating: false });
            })
            .catch((error) => {
                this.setState({ updating: false, updateError: error.message });
            });
    };

    renderMarkPaidUnpaidButton() {
        const { isEditor, purchaseOrder } = this.props;
        const { updateError, updating } = this.state;

        const { amount, paidAmount } = purchaseOrder;

        const purchaseOrderIsPaid = paidAmount === amount;

        if (purchaseOrderIsPaid) {
            return (
                <>
                    <Button
                        active
                        block
                        bsStyle="success"
                        disabled={!isEditor || updating}
                        onClick={() => this.updatePurchaseOrderPaidAmount(false)}
                        tooltip={isEditor ? undefined : 'Only contract editors can update orders'}
                    >
                        <i className="fa fa-check-square-o" /> Paid
                    </Button>
                    <HelpBlock>{updateError || 'Click to unmark as paid'}</HelpBlock>
                </>
            );
        }

        return (
            <>
                <HoverButton
                    block
                    disabled={!isEditor || updating}
                    hoverChildren={
                        <span>
                            <i className="fa fa-check" /> Mark Paid
                        </span>
                    }
                    hoverStyle="success"
                    onClick={() => this.updatePurchaseOrderPaidAmount(true)}
                    tooltip={isEditor ? undefined : 'Only contract editors can update orders'}
                >
                    <span className="text-primary">
                        <i className="fa fa-send" /> Issued
                    </span>
                </HoverButton>
                <HelpBlock>{updateError || 'Click to mark as paid'}</HelpBlock>
            </>
        );
    }

    renderBudgetBarCharts() {
        const {
            amountFormValue,
            budget: { amount, issued, paid },
            statusFormValue,
            paidAmountFormValue,
            purchaseOrder,
            selectedBudgetAllocation,
            selectedFiscalYearTag,
            selectedPurchaseOrders,
        } = this.props;

        const issuedPurchaseOrdersInSelectedFiscalYear = selectedPurchaseOrders.filter(
            (po) => po.status === ISSUED
        );

        // Do not include opened purchase order in fiscal year calculations
        // This is to prevent the saved purchase order data from being included in calculating what's issued and what's paid for, which is now outdated due to opening the form.
        // The bars in BudgetPurchaseOrdersOverview show the new issued and paid (unsaved) values, and how that affects what has already been issued and paid.
        const issuedPurchaseOrdersInSelectedFiscalYearExcludeCurrentPurchaseOrder =
            issuedPurchaseOrdersInSelectedFiscalYear.filter((po) => po.id !== purchaseOrder?.id);

        const paidAmountSumInSelectedFiscalYear =
            issuedPurchaseOrdersInSelectedFiscalYearExcludeCurrentPurchaseOrder
                .map((po) => po.paidAmount)
                .reduce((accumulator, paidAmount) => accumulator + paidAmount, 0);

        const orderAmountSumInFiscalYear =
            issuedPurchaseOrdersInSelectedFiscalYearExcludeCurrentPurchaseOrder
                .map(
                    (po) => po.amount - po.paidAmount // The "real" issued amount for a purchase order is how much has been unpaid, so subtract the purchase order's paid amount
                )
                .reduce((accumulator, orderAmount) => accumulator + orderAmount, 0);

        const currentIssued =
            statusFormValue === ISSUED ? amountFormValue - paidAmountFormValue : undefined; // The actual, current issued amount (unpaid amount) does not include the current paid amount
        const currentPaid = statusFormValue === ISSUED ? paidAmountFormValue : undefined;

        let totalIssuedInBudget = issued;
        let totalPaidInBudget = paid;
        if (purchaseOrder && purchaseOrder.status === ISSUED) {
            // Do not include opened purchase order in budget calculations
            // This is to prevent the saved purchase order data from being included in calculating what's issued and what's paid for, which is now outdated due to opening the form.
            // The bars in BudgetPurchaseOrdersOverview show the new issued and paid (unsaved) values, and how that affects what has already been issued and paid.
            totalIssuedInBudget -= purchaseOrder.amount - purchaseOrder.paidAmount;
            totalPaidInBudget -= purchaseOrder.paidAmount;
        }

        return (
            <Box marginTop="15px">
                <BudgetPurchaseOrdersOverview
                    budgetAmount={get(selectedBudgetAllocation, 'amount')}
                    chartTitle={`${get(selectedFiscalYearTag, 'name')} Budget`}
                    currentIssued={currentIssued}
                    currentPaid={currentPaid}
                    issuedTotal={orderAmountSumInFiscalYear}
                    paidTotal={paidAmountSumInSelectedFiscalYear}
                />
                <hr />
                <BudgetPurchaseOrdersOverview
                    budgetAmount={amount}
                    chartTitle="Total Budget"
                    currentIssued={currentIssued}
                    currentPaid={currentPaid}
                    issuedTotal={totalIssuedInBudget}
                    paidTotal={totalPaidInBudget}
                />
            </Box>
        );
    }

    renderView() {
        const { isEditor, loadError, loading, purchaseOrder, selectedContractPartyId } = this.props;

        const { showBidResults } = this.state;

        if (loading) {
            return <LoadingSpinner />;
        }

        if (loadError) {
            return <LoadingError error={loadError} />;
        }

        const fieldMapperFn = ({ formatter, label, value }, index) => (
            <React.Fragment key={index}>
                <label>{label}</label>
                <p>
                    {formatter ? formatter(get(purchaseOrder, value)) : get(purchaseOrder, value)}
                </p>
            </React.Fragment>
        );

        return (
            <>
                <div className="row">
                    {showBidResults && selectedContractPartyId && (
                        <div className="col-sm-12">{this.renderPriceTables(true)}</div>
                    )}
                    <div className="col-sm-4">
                        {[
                            {
                                label: 'PO Issue Date',
                                value: DATE,
                                formatter: (value) =>
                                    (value && moment(value).format('ll')) || 'None',
                            },
                            { label: 'Vendor', value: 'contractParty.companyName' },
                            { label: 'Fiscal Year', value: 'tag.name' },
                            { label: 'Department', value: 'department.name' },
                            { label: 'Created By', value: 'user.displayName' },
                        ].map(fieldMapperFn)}
                    </div>
                    <div className="col-sm-5">
                        {[
                            { label: 'Order Status', value: 'statusText' },
                            {
                                label: 'Order Amount',
                                value: AMOUNT,
                                formatter: (value) => currencyFormatter({ value }),
                            },
                            {
                                label: 'Amount Paid',
                                value: PAID_AMOUNT,
                                formatter: (value) => currencyFormatter({ value }),
                            },

                            {
                                label: 'PO Number',
                                value: NUMBER,
                                formatter: (value) => value || 'None',
                            },
                            {
                                label: 'Description',
                                value: DESCRIPTION,
                                formatter: (value) => value || 'None',
                            },
                            {
                                label: 'Comments',
                                value: NOTES,
                                formatter: (value) => value || 'None',
                            },
                        ].map(fieldMapperFn)}
                    </div>
                    {purchaseOrder.status === purchaseOrderStatuses.ISSUED && (
                        <div className="col-sm-3 text-center">
                            {this.renderMarkPaidUnpaidButton()}
                        </div>
                    )}
                    <div className="col-xs-12 text-right">
                        <Button
                            disabled={!isEditor}
                            onClick={() => this.setState({ isViewMode: false })}
                            tooltip={
                                isEditor ? undefined : 'Only contract editors can update orders'
                            }
                        >
                            <i className="fa fa-pencil" /> Edit
                        </Button>
                    </div>
                </div>
                {this.renderBudgetBarCharts()}
            </>
        );
    }

    getAdditionalColumns() {
        return [
            {
                orderIndex: 2,
                data: {
                    autoHeight: true,
                    cellClass: ['text-right'],
                    cellClassRules: {
                        // Used exclusively for Excel export styles
                        headerRow: (params) => params.data.isHeaderRow,
                    },
                    field: 'availableQuantity',
                    headerName: 'Available Qty',
                    width: 105,
                    suppressKeyboardEvent,
                    cellRenderer: ({ data }) => {
                        const available = data.availableQuantity;

                        const el = document.createElement('div');
                        el.innerHTML = available;

                        if (available < 0) {
                            el.classList.add('bg-danger', 'text-danger');
                        }

                        return el;
                    },
                },
            },
            {
                orderIndex: 3,
                data: {
                    autoHeight: true,
                    cellClass: ['text-right'],
                    cellClassRules: {
                        // Used exclusively for Excel export styles
                        headerRow: (params) => params.data.isHeaderRow,
                    },
                    cellEditor: 'numericEditor',
                    cellEditorParams: {
                        min: 0,
                        preventEmptyCell: true,
                    },
                    field: 'quantityRequested', // use shared config
                    headerName: 'Qty Requested',
                    width: 115,
                    suppressKeyboardEvent,
                },
            },
        ];
    }

    renderPriceTables(readOnly) {
        const { budgetPriceTables, selectedLineItemId, user } = this.props;
        return budgetPriceTables.map((budgetPriceTable, index) => (
            <PurchaseOrderPriceTable
                additionalColumns={this.getAdditionalColumns()}
                key={index}
                onCellValueChanged={this.handleCellValueChanged}
                priceTable={budgetPriceTable}
                readOnly={readOnly}
                salesTax={user.government.salesTax}
                selectedLineItemId={selectedLineItemId}
            />
        ));
    }

    renderForm() {
        const {
            contractPartySelectOptions,
            departmentSelectOptions,
            fiscalYearSelectOptions,
            handleSubmit,
            isCreateForm,
            loadError,
            loading,
            selectedLineItemId,
            statusFormValue,
            purchaseOrder,
            selectedContractPartyId,
            user,
        } = this.props;

        const { hasPercentage, showBidResults, updateError, updating } = this.state;

        const lineItemSelected = selectedLineItemId && selectedLineItemId === -1;
        const showBidResultsToggle = lineItemSelected && !hasPercentage;

        if (loading) {
            return <LoadingSpinner />;
        }

        if (loadError) {
            return <LoadingError error={loadError} />;
        }

        return (
            <>
                <div className="col-sm-12">
                    <Box display="flex" justifyContent="flex-end">
                        {showBidResultsToggle && (
                            <Toggle
                                disabled={
                                    updating ||
                                    (purchaseOrder && purchaseOrder.priceItems.length > 0)
                                }
                                formClassName={this.styles.toggle}
                                input={{
                                    onBlur: () => {},
                                    onChange: this.onShowBidResultsChange,
                                    value: showBidResults,
                                }}
                                label="Show Bid Results"
                                labelId="ShowBidResults"
                                labelInline
                                labelInlineTogglePosition="right"
                                name={SHOW_BID_RESULTS}
                            />
                        )}
                    </Box>
                </div>
                {showBidResults && selectedContractPartyId && this.renderPriceTables()}
                <form className="row">
                    <div className="col-sm-4">
                        <Field
                            allowEmpty
                            component={DateTimePicker}
                            disabled={updating}
                            label="PO Issue Date"
                            name={DATE}
                            placeholder="Enter Issue Date"
                        />
                        <Field
                            component={SearchSelect}
                            disabled={updating}
                            label="Vendor"
                            name={CONTRACT_PARTY_ID}
                            options={contractPartySelectOptions}
                        />
                        <Field
                            component={SearchSelect}
                            disabled={updating}
                            label="Fiscal Year"
                            name={TAG_ID}
                            noOptionsMessage={
                                'Your organization has not created any fiscal years yet. ' +
                                'Admin users can add fiscal year tags through the admin ' +
                                'interface.'
                            }
                            options={fiscalYearSelectOptions}
                        />
                        <Field
                            component={SearchSelect}
                            disabled={updating}
                            label="Department"
                            name={DEPARTMENT_ID}
                            options={departmentSelectOptions}
                        />
                        <Label htmlFor="CreatedBy" label="Created By" />
                        <p>{get(purchaseOrder, 'user.displayName') || user.displayName}</p>
                    </div>
                    <div className="col-sm-8">
                        <Field
                            component={SearchSelect}
                            disabled={updating}
                            label="Order Status"
                            name={STATUS}
                            options={purchaseOrderStatusOptions}
                        />
                        <Field
                            className={this.styles.noZIndex}
                            component={this.MaskedBudgetInput}
                            disabled={updating || this.state.showBidResults}
                            hasFeedback={false}
                            inputGroupPrefix="$"
                            label="Order Amount"
                            mask={maskNumberWithCommas}
                            name={AMOUNT}
                            normalizer={dollarString}
                            placeholder="Enter Amount"
                            type="text"
                        />
                        {statusFormValue === ISSUED && (
                            <Field
                                className={this.styles.noZIndex}
                                component={this.MaskedBudgetInput}
                                disabled={updating}
                                hasFeedback={false}
                                inputGroupPrefix="$"
                                label="Amount Paid"
                                mask={maskNumberWithCommas}
                                name={PAID_AMOUNT}
                                normalizer={dollarString}
                                placeholder="Enter Amount"
                                type="text"
                            />
                        )}
                        <Field
                            component={InputText}
                            disabled={updating}
                            hasFeedback={false}
                            label="PO Number"
                            name={NUMBER}
                            placeholder="Enter PO Number"
                            type="text"
                        />
                        <Field
                            component={InputText}
                            disabled={updating}
                            hasFeedback={false}
                            label="Description"
                            minRows={2}
                            name={DESCRIPTION}
                            placeholder="Enter Description"
                            type="textarea"
                        />
                        <Field
                            component={InputText}
                            disabled={updating}
                            hasFeedback={false}
                            label="Comments"
                            minRows={2}
                            name={NOTES}
                            placeholder="Enter Comments"
                            type="textarea"
                        />
                    </div>
                    <div className="col-xs-4">
                        {!isCreateForm && (
                            <OutlineButton bsStyle="danger" onClick={this.deleteHandler}>
                                <i className="fa fa-trash" /> Delete
                            </OutlineButton>
                        )}
                    </div>
                    <div className="col-xs-8 text-right">
                        <CDSButton onClick={() => this.hideModal()} variant="secondary">
                            Cancel
                        </CDSButton>
                        &nbsp;
                        <CDSButton onClick={handleSubmit(this.submitHandler)}>
                            {isCreateForm ? 'Create Order' : 'Save Order'}
                        </CDSButton>
                        {updateError && <div className="error-block">{updateError}</div>}
                    </div>
                </form>
                {this.renderBudgetBarCharts()}
            </>
        );
    }

    render() {
        const { isCreateForm } = this.props;

        const { isViewMode } = this.state;

        return (
            <Modal bsSize="lg" onHide={() => this.hideModal()} show>
                <Modal.Header closeButton>
                    <Modal.Title>{isCreateForm ? 'Create' : 'Edit'} Order</Modal.Title>
                </Modal.Header>
                <Modal.Body>{isViewMode ? this.renderView() : this.renderForm()}</Modal.Body>
            </Modal>
        );
    }
}

export const PurchaseOrderFormModal = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedPurchaseOrderFormModal);

import { isNil } from 'lodash';

import { IMMEDIATELY, NEVER, YEARS } from '@og-pro/shared-config/recordsRetention';

/**
 * Retrieves the archive schedule value from the archive schedule field.
 * @param archiveScheduleValue Value of the textbox when someone selects years
 * @param scheduleOptions Years, Immediately, or Never
 * @returns The amount of years to wait until retention code is eligible for purging
 */
export const getArchiveSchedule = (archiveScheduleValue, scheduleOptions) => {
    if (scheduleOptions === NEVER) {
        return null;
    }

    if (scheduleOptions === IMMEDIATELY) {
        return 0;
    }

    return archiveScheduleValue.years;
};

/**
 * Gets the default values for the schedule archive field based on policy archiveSchedule
 * @param archiveSchedule ArchiveSchedule of policy
 * @returns {string} Default option to be loaded into archive schedule field
 */
export const getArchiveScheduleFormOption = (archiveSchedule) => {
    if (archiveSchedule === 0) {
        return IMMEDIATELY;
    }

    if (isNil(archiveSchedule)) {
        return NEVER;
    }

    return YEARS;
};

import { ALL_SECTIONS, formSectionNames } from './constants';
import { validate as validateTemplate } from './TemplateForm/validate';
import { validate as validateQuestions } from './TemplateQuestionnairesForm/validate';
import { sectionsValidate } from '../../../../Forms/validation';
import { hasFormErrors } from '../../../../helpers';

const { QUESTIONS, TEMPLATE } = formSectionNames;

export const validate = (values) => {
    const templateErrors = validateTemplate(values);
    const questionErrors = validateQuestions(values);

    const sectionErrors = sectionsValidate([TEMPLATE, templateErrors], [QUESTIONS, questionErrors]);

    return {
        [ALL_SECTIONS]: hasFormErrors(sectionErrors),
        ...templateErrors,
        ...questionErrors,
        ...sectionErrors,
    };
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ScopeList } from './ScopeList';
import { ScopeSummary } from './ScopeSummary';
import { getProjectScopeJS, getProjectScopeSelectionJS } from '../../selectors';
import { Button, LoadingSpinner, LoadingError } from '../../../../../components';
import { stripHtml } from '../../../../../utils';

const mapStateToProps = (state) => {
    return {
        scopeItems: getProjectScopeJS(state),
        selectedScopeItems: getProjectScopeSelectionJS(state),
    };
};

// @connect
class ConnectedScopeSelect extends Component {
    static propTypes = {
        backHandler: PropTypes.func.isRequired,
        copyButtonText: PropTypes.string,
        copyClickHandler: PropTypes.func.isRequired,
        loadingProject: PropTypes.bool.isRequired,
        loadProjectError: PropTypes.string,
        scopeItems: PropTypes.array.isRequired,
        selectedProject: PropTypes.shape({
            id: PropTypes.number.isRequired,
            summary: PropTypes.string,
            title: PropTypes.string,
            government: PropTypes.shape({
                code: PropTypes.string,
            }),
        }),
        selectedScopeItems: PropTypes.array.isRequired,
        selectAllScope: PropTypes.func.isRequired,
        selectClickHandler: PropTypes.func.isRequired,
        showBackButton: PropTypes.bool,
    };

    static defaultProps = {
        copyButtonText: undefined,
        loadProjectError: undefined,
        selectedProject: undefined,
        showBackButton: false,
    };

    get hasSelection() {
        return this.props.selectedScopeItems.length !== 0;
    }

    get hasAllSelections() {
        return this.props.selectedScopeItems.length === this.props.scopeItems.length;
    }

    get selectAllIcon() {
        if (this.hasAllSelections) return 'check-square-o';
        if (this.hasSelection) return 'minus-square-o';
        return 'square-o';
    }

    handleCopyClick = () => {
        const { copyClickHandler, scopeItems } = this.props;

        copyClickHandler(scopeItems);
    };

    handleSelectAllClick = () => {
        const { selectAllScope } = this.props;

        selectAllScope(!this.hasSelection);
    };

    renderNoItems() {
        return (
            <div className="text-center">
                <p>This project has no scope of work items. Please select another project.</p>
                <div>
                    <Button bsStyle="primary" onClick={this.props.backHandler}>
                        <i className="fa fa-arrow-left" /> Back
                    </Button>
                </div>
            </div>
        );
    }

    renderSelectAll() {
        const text = this.hasSelection ? 'Unselect All' : 'Select All';
        const styles = require('./ScopeSelect.scss');

        return (
            <div className="row">
                <div className="col-xs-2 col-sm-1 text-right">
                    <Button
                        className={styles.selectAllButton}
                        onClick={this.handleSelectAllClick}
                        zeroPadding
                    >
                        <i
                            className={`fa fa-fw fa-lg fa-${this.selectAllIcon} ${styles.selectAllIcon}`}
                        />
                        <span className="sr-only">{text}</span>
                    </Button>
                </div>
                <div className={`col-xs-4 ${styles.itemCol}`}>
                    <Button bsStyle="link" onClick={this.handleSelectAllClick} zeroPadding>
                        {text}
                    </Button>
                </div>
                <div className="col-xs-6 col-sm-7 text-right">
                    <h5>Select Scope of Work Items to Copy</h5>
                </div>
            </div>
        );
    }

    render() {
        const { loadingProject, loadProjectError, scopeItems } = this.props;

        const styles = require('./ScopeSelect.scss');

        if (loadingProject) return <LoadingSpinner />;
        if (loadProjectError) return <LoadingError error={loadProjectError} />;
        if (scopeItems.length === 0) return this.renderNoItems();

        const {
            backHandler,
            copyButtonText,
            selectedProject: {
                id,
                summary,
                title,
                government: { code },
            },
            selectClickHandler,
            showBackButton,
        } = this.props;

        const textSummary = stripHtml(summary);

        return (
            <div className="row">
                <h4 className={`col-xs-12 ${styles.projectTitle}`}>{title}</h4>
                <div className="col-xs-12 col-md-offset-1 col-md-10 col-lg-offset-2 col-lg-8">
                    <ScopeSummary summary={textSummary || ''} />
                </div>
                <div className="clearfix" />
                <div className={`col-sm-2 ${styles.sidebar}`}>
                    {showBackButton && (
                        <div className={styles.backBtn}>
                            <Button bsSize="sm" bsStyle="primary" onClick={backHandler}>
                                <i className="fa fa-arrow-left" /> Back
                            </Button>
                        </div>
                    )}
                    <div>
                        <a
                            href={`/portal/${code}/projects/${id}`}
                            rel="noreferrer noopener"
                            target="_blank"
                        >
                            View Project <i className="fa fa-external-link" />
                        </a>
                    </div>
                </div>
                <div className="col-sm-9">
                    <div className={styles.selectAllContainer}>{this.renderSelectAll()}</div>
                    <ScopeList clickHandler={selectClickHandler} items={scopeItems} />
                </div>
                <div className="col-xs-12">
                    <div className={styles.copyBtnContainer}>
                        <Button
                            bsSize="lg"
                            bsStyle="success"
                            disabled={!this.hasSelection}
                            onClick={this.handleCopyClick}
                        >
                            {copyButtonText || 'Copy Scope Items'}
                        </Button>
                    </div>
                    {!this.hasSelection && (
                        <div className="text-center text-muted">
                            Please select at least one scope of work item to copy
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export const ScopeSelect = connect(mapStateToProps)(ConnectedScopeSelect);

import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import { v4 as UUIDv4 } from 'uuid';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Panel } from 'react-bootstrap';
import { Typography } from '@og-pro/ui';

export default class UpcomingEventsList extends Component {
    static propTypes = {
        events: PropTypes.array.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    generateDaysArray() {
        const days = [];
        const dateStart = moment();
        const dateEnd = moment().add(6, 'days');

        while (dateEnd.diff(dateStart, 'days') >= 0) {
            days.push({
                label: dateStart.format('ddd, MMM D'),
                events: this.props.events.filter((event) => {
                    return moment(event.end).day() === dateStart.day();
                }),
            });
            dateStart.add(1, 'days');
        }
        return days;
    }

    renderUpcomingEvents() {
        return this.generateDaysArray().map((day) => {
            let noEvents = '';
            if (day.events.length === 0) {
                noEvents = 'No Events Scheduled';
            }

            return (
                <div className={this.styles.dayContainer} key={UUIDv4()}>
                    <Typography component="h3" variant="h4">
                        {day.label}
                    </Typography>
                    <hr />
                    <div className={this.styles.eventsContainer}>
                        {noEvents
                            ? `${noEvents}`
                            : day.events.map((event) => {
                                  return (
                                      <div className="row" key={UUIDv4()}>
                                          <Link to={`${event.path}`}>
                                              <div
                                                  className={classnames(
                                                      'col-xs-3',
                                                      this.styles.eventTime
                                                  )}
                                              >
                                                  {event.allDay
                                                      ? 'All Day'
                                                      : `${moment(event.end).format('h:mm A')}`}
                                              </div>
                                              <div
                                                  className={classnames(
                                                      'col-xs-9',
                                                      this.styles.eventTitle
                                                  )}
                                              >{`${event.title}`}</div>
                                          </Link>
                                      </div>
                                  );
                              })}
                    </div>
                </div>
            );
        });
    }

    render() {
        return (
            <div className="col-md-6">
                <Panel className={this.styles.eventsPanel}>
                    <Panel.Heading className={this.styles.panelTitle}>
                        <Typography component="h2" variant="h4">
                            Upcoming Events
                        </Typography>
                    </Panel.Heading>
                    <Panel.Body className={this.styles.eventsPanelBody}>
                        {this.renderUpcomingEvents()}
                    </Panel.Body>
                </Panel>
            </div>
        );
    }
}

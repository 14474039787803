import moment from 'moment-timezone/builds/moment-timezone-with-data-1970-2030';

/**
 * Format a value as a date.
 *
 * @callback formatCallback
 * @param {object} options The options object to use to process the formatting
 * @param {number} options.value The value to format as a date
 * @return {any|string} The original value if `options.value` is not parseable as a Date, the
 *                        formatted Date string if it is.
 */

/**
 * Generate a function that will format a date value using the specified timezone in the specified
 * format. The generated function will use the `timezone` and `format` within Moment.js.
 *
 * @param {string} timezone The timezone to put the date in
 * @param {string} format The format to put the  date in
 * @return {formatCallback}
 */
const dateTZFormatter = (timezone, format) => {
    return (options) => {
        const date = moment(options.value);

        if (!date.isValid()) {
            return options.value;
        }

        return moment.tz(date, timezone).format(format);
    };
};

/**
 * Takes in a date and a timezone and converts it to the following
 * ex: Saturday, October 1, 2022 9:00pm
 * @param {Date} date
 * @param {string} timezone
 * @param {boolean} excludeTime Whether to show the time or not
 * @returns {string}
 */
const displayDateFormatter = (date, timezone, excludeTime) => {
    const format = 'dddd, MMMM D, YYYY';
    const dateDisplay = moment.tz(date, timezone).format(format);

    if (excludeTime) {
        return dateDisplay;
    }

    return `${dateDisplay} ${moment.tz(date, timezone).format('h:mma')}`;
};

export { dateTZFormatter, displayDateFormatter };

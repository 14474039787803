import { showSnackbar } from './notification';
import { resourceManager } from '../helpers';

export const CREATE_FLAG_SUCCESS = 'requisitionsAdmin/CREATE_FLAG_SUCCESS';
export const CREATE_POSITION_SUCCESS = 'requisitionsAdmin/CREATE_POSITION_SUCCESS';

export const LOAD_FLAGS = 'requisitionsAdmin/LOAD_FLAGS';
export const LOAD_FLAGS_FAIL = 'requisitionsAdmin/LOAD_FLAGS_FAIL';
export const LOAD_FLAGS_SUCCESS = 'requisitionsAdmin/LOAD_FLAGS_SUCCESS';
export const RESET_LOAD_FLAGS_SUCCESS = 'requisitionsAdmin/RESET_LOAD_FLAGS_SUCCESS';

export const LOAD_POSITIONS = 'requisitionsAdmin/LOAD_POSITIONS';
export const LOAD_POSITIONS_FAIL = 'requisitionsAdmin/LOAD_POSITIONS_FAIL';
export const LOAD_POSITIONS_SUCCESS = 'requisitionsAdmin/LOAD_POSITIONS_SUCCESS';

export const LOAD_REQ_SETTING = 'requisitionsAdmin/LOAD_REQ_SETTING';
export const LOAD_REQ_SETTING_FAIL = 'requisitionsAdmin/LOAD_REQ_SETTING_FAIL';
export const LOAD_REQ_SETTING_SUCCESS = 'requisitionsAdmin/LOAD_REQ_SETTING_SUCCESS';

export const DELETE_FLAG_SUCCESS = 'requisitionsAdmin/DELETE_FLAG_SUCCESS';
export const DELETE_POSITION_SUCCESS = 'requisitionsAdmin/DELETE_POSITION_SUCCESS';

export const UPDATE_FLAG_SUCCESS = 'requisitionsAdmin/UPDATE_FLAG_SUCCESS';
export const UPDATE_POSITION_SUCCESS = 'requisitionsAdmin/UPDATE_POSITION_SUCCESS';
export const UPDATE_REQ_SETTING_SUCCESS = 'requisitionsAdmin/UPDATE_REQ_SETTING_SUCCESS';

export const createFlag = (data) => {
    return resourceManager({
        method: 'post',
        requestOptions: { data },
        url: '/requisitions/flags',
        onSuccess: ({ result, dispatch }) => {
            dispatch({ type: CREATE_FLAG_SUCCESS, result });
        },
    });
};

export const createPosition = (data) => {
    return resourceManager({
        method: 'post',
        requestOptions: { data },
        url: '/requisitions/positions',
        onSuccess: ({ result, dispatch }) => {
            dispatch({ type: CREATE_POSITION_SUCCESS, result });
        },
    });
};

export const deleteFlag = (flagId) => {
    return resourceManager({
        method: 'del',
        url: `/requisitions/flags/${flagId}`,
        onSuccess: ({ result, dispatch }) => {
            dispatch(showSnackbar('Flag deleted'));
            dispatch({ type: DELETE_FLAG_SUCCESS, result });
        },
        onFailure: ({ error, dispatch }) => {
            dispatch(showSnackbar(error.message, { isError: true }));
        },
    });
};

export const deletePosition = (positionId) => {
    return resourceManager({
        method: 'del',
        url: `/requisitions/positions/${positionId}`,
        onSuccess: ({ result, dispatch }) => {
            dispatch(showSnackbar('Position deleted'));
            dispatch({ type: DELETE_POSITION_SUCCESS, result });
        },
        onFailure: ({ error, dispatch }) => {
            dispatch(showSnackbar(error.message, { isError: true }));
        },
    });
};

export const loadFlags = () => {
    return resourceManager({
        method: 'get',
        url: '/requisitions/flags',
        onStart: ({ dispatch }) => dispatch({ type: LOAD_FLAGS }),
        onSuccess: ({ result, dispatch }) => dispatch({ type: LOAD_FLAGS_SUCCESS, result }),
        onFailure: ({ error, dispatch }) => dispatch({ type: LOAD_FLAGS_FAIL, error }),
    });
};

export const loadPositions = () => {
    return resourceManager({
        method: 'get',
        url: '/requisitions/positions',
        onStart: ({ dispatch }) => dispatch({ type: LOAD_POSITIONS }),
        onSuccess: ({ result, dispatch }) => dispatch({ type: LOAD_POSITIONS_SUCCESS, result }),
        onFailure: ({ error, dispatch }) => dispatch({ type: LOAD_POSITIONS_FAIL, error }),
    });
};

export const loadReqSetting = () => {
    return resourceManager({
        method: 'get',
        url: '/requisitions/settings',
        onStart: ({ dispatch }) => dispatch({ type: LOAD_REQ_SETTING }),
        onSuccess: ({ result, dispatch }) => dispatch({ type: LOAD_REQ_SETTING_SUCCESS, result }),
        onFailure: ({ error, dispatch }) => dispatch({ type: LOAD_REQ_SETTING_FAIL, error }),
    });
};

export const updateFlag = (flagId, data) => {
    return resourceManager({
        method: 'put',
        requestOptions: { data },
        url: `/requisitions/flags/${flagId}`,
        onSuccess: ({ result, dispatch }) => {
            dispatch({ type: UPDATE_FLAG_SUCCESS, result });
        },
    });
};

export const updatePosition = (positionId, data) => {
    return resourceManager({
        method: 'put',
        requestOptions: { data },
        url: `/requisitions/positions/${positionId}`,
        onSuccess: ({ result, dispatch }) => {
            dispatch({ type: UPDATE_POSITION_SUCCESS, result });
        },
    });
};

export const updateReqSetting = (data) => {
    return resourceManager({
        method: 'put',
        requestOptions: { data },
        url: `/requisitions/settings`,
        onSuccess: ({ result, dispatch }) => {
            dispatch({ type: UPDATE_REQ_SETTING_SUCCESS, result });
        },
    });
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import { WorkloadWeightsForm } from './WorkloadWeightsForm';
import { updateWorkloadWeight } from '../../../../actions/admin';
import { showSnackbar } from '../../../../actions/notification';

const mapDispatchToProps = {
    showSnackbar,
    updateWorkloadWeight,
};

export class ConnectedWorkloadWeightsModal extends Component {
    static propTypes = {
        showSnackbar: PropTypes.func.isRequired,
        hideModal: PropTypes.func.isRequired,
        statuses: PropTypes.arrayOf(PropTypes.string).isRequired,
        updateWorkloadWeight: PropTypes.func.isRequired,
        workloadWeight: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            updateError: null,
            updating: false,
        };
    }

    hideModal = () => {
        const { hideModal } = this.props;

        return hideModal();
    };

    submitHandler = (data) => {
        const { workloadWeight } = this.props;

        this.setState({ updating: true, updateError: null });
        return this.props
            .updateWorkloadWeight(workloadWeight.id, data)
            .then(() => {
                this.setState({ updating: false });
                this.props.showSnackbar('Weights Saved');
                this.hideModal({ force: true });
            })
            .catch((error) => {
                this.setState({ updating: false, updateError: error.message });
            });
    };

    render() {
        const { statuses, workloadWeight } = this.props;

        const { updateError, updating } = this.state;

        return (
            <Modal onHide={this.hideModal} show>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">Edit Workload Weights</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <WorkloadWeightsForm
                        cancelHandler={this.hideModal}
                        initialValues={workloadWeight}
                        onSubmit={this.submitHandler}
                        statuses={statuses}
                        updateError={updateError}
                        updating={updating}
                    />
                </Modal.Body>
            </Modal>
        );
    }
}

export const WorkloadWeightsModal = connect(
    null,
    mapDispatchToProps
)(ConnectedWorkloadWeightsModal);

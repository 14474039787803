import React from 'react';
import PropTypes from 'prop-types';

export const BidSectionFormError = ({ bidError: { header, body } }) => {
    if (!header) return null;

    const styles = require('./index.scss');

    return (
        <div className={styles.errorContainer}>
            <div className={styles.error}>
                <i className="fa fa-exclamation-triangle text-danger" /> {header}
            </div>
            {body && <div className={styles.errorDetails}>{body}</div>}
        </div>
    );
};

BidSectionFormError.propTypes = {
    bidError: PropTypes.shape({
        header: PropTypes.string.isRequired,
        body: PropTypes.string,
    }).isRequired,
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';

import { form, VENDOR_CATEGORIES } from './constants';
import { getOrganization, getInitialValues } from './selectors';
import { CategorySelectInput } from '../..';
import { updateOrganization } from '../../../actions/admin';
import { Button, Main, SectionTitle } from '../../../components';

const mapStateToProps = (state) => {
    return {
        initialValues: getInitialValues(state),
        organization: getOrganization(state),
        updateOrgError: state.admin.get('updateOrgError'),
        updatingOrg: state.admin.get('updatingOrg'),
    };
};

const mapDispatchToProps = {
    updateOrganization,
};

const formConfig = {
    form,
};

// @connect
// @reduxForm
class ConnectedVendorCategories extends Component {
    static propTypes = {
        govCategorySetId: PropTypes.number,
        handleSubmit: PropTypes.func.isRequired,
        isGovRequired: PropTypes.bool,
        isModal: PropTypes.bool,
        organization: PropTypes.object.isRequired,
        onComplete: PropTypes.func,
        updateOrganization: PropTypes.func.isRequired,
        updateOrgError: PropTypes.string,
        updatingOrg: PropTypes.bool.isRequired,
    };

    submitHandler = (data) => {
        const { organization, updateOrganization: update } = this.props;

        update(organization.id, data).then(() => {
            const { updateOrgError, onComplete } = this.props;
            if (!updateOrgError && onComplete) {
                onComplete();
            }
        });
    };

    render() {
        const {
            govCategorySetId,
            handleSubmit,
            isGovRequired,
            isModal,
            updateOrgError,
            updatingOrg,
        } = this.props;

        const styles = require('./index.scss');

        return (
            <Main className="row">
                <div className={isModal ? 'col-sm-10 col-sm-offset-1' : 'col-sm-8 col-sm-offset-2'}>
                    <SectionTitle
                        info="Select the category codes that your company responds to"
                        title="Category Codes"
                    />
                    <form onSubmit={handleSubmit(this.submitHandler)}>
                        <Field
                            component={CategorySelectInput}
                            disabled={updatingOrg}
                            govCategorySetId={govCategorySetId}
                            isGovRequired={isGovRequired}
                            limit={50}
                            name={VENDOR_CATEGORIES}
                            segmentByCodeSet
                            showMoreCodeSetsHelp
                        />
                        <div className={styles.saveButton}>
                            <Button
                                bsSize="large"
                                bsStyle="primary"
                                disabled={updatingOrg}
                                type="submit"
                            >
                                {updatingOrg ? 'Saving...' : 'Save'}
                            </Button>
                        </div>
                        {updateOrgError && (
                            <div className="text-danger text-center">{updateOrgError}</div>
                        )}
                    </form>
                </div>
            </Main>
        );
    }
}

export const VendorCategories = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedVendorCategories);

const APPENDIX_LIMIT = 32;
const TITLE_LIMIT = 180;

export const validate = (values, props) => {
    const { hideAppendixLetter } = props;

    const errors = {};

    if (!values.files) return errors;

    errors.files = values.files.map((file) => {
        const fileErrors = {};

        if (!hideAppendixLetter) {
            if (!file.appendixId) {
                fileErrors.appendixId = 'Please select an attachment letter';
            } else if (file.appendixId.length > APPENDIX_LIMIT) {
                fileErrors.appendixId = `Attachment letter must be less than ${APPENDIX_LIMIT} characters`;
            }
        }

        if (!file.title) {
            fileErrors.title = 'Please enter a title for the attachment';
        } else if (file.title.length > TITLE_LIMIT) {
            fileErrors.title = `Title must be less than ${TITLE_LIMIT} characters`;
        }

        return fileErrors;
    });

    return errors;
};

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { resetProposal } from '../../actions/vendProposals';

export const ResetVendorProposal = ({ children }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(resetProposal());
        };
    }, []);

    return children;
};

ResetVendorProposal.propTypes = {
    children: PropTypes.node.isRequired,
};

import React from 'react';
import { Outlet } from 'react-router-dom';

import {
    ProjectAddendums,
    ProjectDetailNav,
    ProjectSection,
    QuestionAnswer,
} from '../../../containers';
import * as Gov from '../../../containers/GovApp';
import BaseProposalReviewRoutes from './ProposalReview';
import { legacyFetchData, requireMarketplaceSubscription } from '../../loaders';

export default (getState, dispatch) => {
    const SourcingProposalReviewRoutes = BaseProposalReviewRoutes('Sourcing');

    return [
        {
            element: <Outlet />,
            loader: requireMarketplaceSubscription(getState),
            path: 'sourcing',
            children: [
                {
                    element: <Gov.ProjectPostNav />,
                    children: [
                        {
                            element: <Gov.ProjectIntroOverview.Government />,
                            index: true,
                        },
                        {
                            element: (
                                <ProjectDetailNav.Government>
                                    <ProjectSection.Government />
                                </ProjectDetailNav.Government>
                            ),
                            path: 'document',
                        },
                        {
                            element: <Gov.AttachmentsList />,
                            path: 'attachments',
                        },
                        {
                            element: <Gov.ProposalsList.Sourcing />,
                            loader: legacyFetchData(getState, dispatch, Gov.ProposalsList.Sourcing),
                            path: 'proposals',
                        },
                        // /governments/:governmentId/projects/:projectId/sourcing/proposals/:proposalId/*
                        ...SourcingProposalReviewRoutes(getState, dispatch),
                        {
                            element: <ProjectAddendums.Government />,
                            loader: legacyFetchData(
                                getState,
                                dispatch,
                                ProjectAddendums.Government
                            ),
                            path: 'addenda',
                        },
                        {
                            element: <QuestionAnswer.Government />,
                            loader: legacyFetchData(getState, dispatch, QuestionAnswer.Government),
                            path: 'q-and-a',
                        },
                        {
                            element: <Gov.ProjectRsvpManager />,
                            loader: legacyFetchData(getState, dispatch, Gov.ProjectRsvpManager),
                            path: 'rsvp-manager',
                        },
                        {
                            element: <Gov.ProjectVendorAnalytics />,
                            loader: legacyFetchData(getState, dispatch, Gov.ProjectVendorAnalytics),
                            path: 'vendor-analytics',
                        },
                    ],
                },
            ],
        },
    ];
};

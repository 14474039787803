import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Accordion, AccordionDetails, buildQaTag } from '@og-pro/ui';

import { getPriceItemExtendedPrice } from '@og-pro/shared-config/priceTables/requisitionUtils';

import { showAccountInformationOptionsValues } from '@og-pro/shared-config/requisitions';

import { AccountSplit } from './AccountSplit';
import { LineItemSummary } from './LineItemSummary';
import { LineItemDetails } from './LineItemDetails';
import { MoreInformation } from './MoreInformation';
import {
    getGovernmentReqSetting,
    getGovernmentSalesTax,
    getIsRequisitionUsingFMS,
} from '../../../../../../../selectors/govApp';

export const PriceItem = ({ expanded, fields, handleExpand, index, ...props }) => {
    const priceItems = fields.getAll();
    const priceItem = fields.get(index);
    const { discount, discountType, quantity, unitPrice, taxable } = priceItem;

    const tax = useSelector(getGovernmentSalesTax);

    const { showAccountInformation } = useSelector(getGovernmentReqSetting);

    const extendedPrice = useMemo(
        () =>
            getPriceItemExtendedPrice(
                quantity,
                unitPrice,
                discount,
                discountType,
                taxable ? tax : null
            ),
        [priceItem]
    );

    const hasFMS = useSelector(getIsRequisitionUsingFMS);

    return (
        <Accordion
            data-qa={buildQaTag(`requisitionCreate-priceItem${index}`, 'accordion')}
            expanded={expanded}
            onChange={handleExpand(index)}
        >
            <LineItemSummary
                extendedPrice={extendedPrice}
                index={index}
                lineItemNumber={index + 1}
                priceItem={priceItem}
                priceItems={priceItems}
                {...props}
            />
            <AccordionDetails>
                <LineItemDetails
                    expanded={expanded}
                    extendedPrice={extendedPrice}
                    fields={fields}
                    index={index}
                    {...props}
                />
                {(showAccountInformation !== showAccountInformationOptionsValues.HIDDEN ||
                    hasFMS) && (
                    <AccountSplit extendedPrice={extendedPrice} priceItemIndex={index} {...props} />
                )}
                <MoreInformation priceItem={priceItem} {...props} />
            </AccordionDetails>
        </Accordion>
    );
};

PriceItem.propTypes = {
    expanded: PropTypes.bool,
    fields: PropTypes.shape({
        get: PropTypes.func.isRequired,
        getAll: PropTypes.func.isRequired,
    }).isRequired,
    handleExpand: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    tax: PropTypes.number,
};

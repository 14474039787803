import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Field } from 'redux-form';

import { IS_REQUIRED } from '@og-pro/shared-config/questionnaires';

import { Checkbox } from '../../..';

export class Required extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
    };

    render() {
        return (
            <Field
                component={Checkbox}
                disabled={this.props.disabled}
                name={IS_REQUIRED}
                text="Require response"
            />
        );
    }
}

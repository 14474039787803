import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { components } from 'react-select';
import { reduxForm, Field } from 'redux-form';

import { SearchSelect, CategoryCode } from '../../components';
import { form } from './constants';

const formConfig = {
    form,
};

/*
 * The multi prop is used to display multiple selections in the input
 * These selections do not get sent to the parent form until a blur event
 * happens. This is because if there are additions and then removal, the
 * parent will not know which items were removed (since it keeps its own array
 * of items that are independent of this search selector)
 *
 * Because of this, we also must keep track of selected items with an internal
 * `categoriesMap` object as well, so internal selections also get removed.
 *
 * Finally, in order to clear the input field, we send use `clearOnBlur` and
 * `beforeClearOnBlur` to get the current form value before blur and then set
 * the form to an empty value to clear the input field.
 */

// @reduxForm
class ConnectedCategorySearch extends PureComponent {
    static propTypes = {
        categorySet: PropTypes.number.isRequired,
        label: PropTypes.string,
        options: PropTypes.array.isRequired,
        searching: PropTypes.bool.isRequired,
        disabled: PropTypes.bool,
        categoriesMap: PropTypes.object.isRequired,
        isMulti: PropTypes.bool,
        fieldName: PropTypes.string, // Use if search is displayed on same form
        search: PropTypes.func.isRequired,
        selectHandler: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            options: props.options,
            categoriesMap: {},
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.options !== this.props.options) {
            this.setState({ options: nextProps.options });
        }
    }

    onInputChange = (value) => {
        const { categorySet, search } = this.props;

        const key = `${categorySet}.${value}`;
        if (this.cache[key]) {
            this.setState({ options: this.cache[key] });
        } else if (value) {
            search(value, categorySet).then((action) => {
                this.cache[key] = action.result;
            });
        }
        return value;
    };

    cache = {};

    get styles() {
        return require('./shared.scss');
    }

    get multiProps() {
        const { isMulti, selectHandler } = this.props;

        if (!isMulti) {
            return {
                // Non-multi selects category on each change
                onChange: selectHandler,
            };
        }
        return {
            isMulti: true,
            // Multi selects categories only once blurred
            beforeClearOnBlur: this.beforeBlurHandler,
            // On changes we only want to update the internal categories map
            // The parent form will get the new values on blur
            clearOnBlur: true,
            components: {
                MultiValueLabel: this.renderValue,
            },
            hideSelectedOptions: false,
            onChange: this.updateCategoriesMap,
        };
    }

    handleClose = () => {
        this.setState({ options: [] });
    };

    updateCategoriesMap = (categories) => {
        // Internal category map must be kept separately on multi select
        // because categories changes aren't sent to the parent form until
        // form is blurred
        this.setState({
            categoriesMap: categories.reduce((obj, cat) => ({ ...obj, [cat.value]: true }), {}),
        });
    };

    beforeBlurHandler = (values) => {
        // Reset categories map
        this.updateCategoriesMap([]);

        // Send values to parent form handler
        return this.props.selectHandler(values);
    };

    renderValue = (option) => {
        return (
            <components.MultiValueLabel {...option}>
                <CategoryCode
                    code={option.data.data.code}
                    setId={option.data.data.setId}
                    title={option.data.data.title}
                />
            </components.MultiValueLabel>
        );
    };

    render() {
        const { categoriesMap, disabled, fieldName, label, searching } = this.props;

        const catMap = {
            ...categoriesMap,
            ...this.state.categoriesMap,
        };
        const options = this.state.options.map((result) => {
            // WARNING: Mutating state is a BAD idea!
            // Done here to circumvent some sort of caching we need to mutate
            // the value in order for multi selecting to work properly
            result.disabled = catMap[result.value];
            return result;
        });

        // `blurInputOnSelect=false` needed to prevent blur error on mobile
        // https://github.com/JedWatson/react-select/issues/2692#issuecomment-395743446
        return (
            <Field
                aria-label="Search Categories"
                blurInputOnSelect={false}
                closeMenuOnSelect={false}
                component={SearchSelect}
                disabled={disabled}
                formClassName={this.styles.searchSelect}
                isClearable
                isLoading={searching}
                isOptionDisabled={(option) => option.disabled}
                label={label}
                name={fieldName || 'query'}
                noOptionsMessage={() => (searching ? 'Loading...' : 'Type to search')}
                onClose={this.handleClose}
                onInputChange={this.onInputChange}
                onSelectResetsInput={false}
                options={searching ? [] : options}
                overrideFeedback
                placeholder="Search Categories..."
                rawValue
                {...this.multiProps}
            />
        );
    }
}

export const CategorySearch = reduxForm(formConfig)(ConnectedCategorySearch);

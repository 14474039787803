import { createSelector } from 'reselect';

const getProjectStatusHistory = (state) => state.govProjects.get('projectStatusHistory');

export const getProjectStatusHistoryJS = createSelector(
    [getProjectStatusHistory],
    (statusHistory) => {
        if (statusHistory) {
            return statusHistory.toJS().slice().reverse();
        }
    }
);

import { projectVendorSubscriptionTypesDict } from '@og-pro/shared-config/projects';

const { PLAN_ROOM, PRIME, SUB } = projectVendorSubscriptionTypesDict;

export const form = 'vendorFollowTypeForm';

export const TYPE = 'type';

export const NONE = 'none';

export const vendorFollowTypeOptions = [
    {
        label: 'None',
        value: NONE,
    },
    {
        label: 'Prime Contractor',
        value: PRIME,
    },
    {
        label: 'Subcontractor',
        value: SUB,
    },
    {
        label: 'Plan Room',
        value: PLAN_ROOM,
    },
];

const ARCHIVED = 0;
const DRAFT = 100;
const PUBLISHED = 200;

exports.statusTypes = {
    ARCHIVED,
    DRAFT,
    PUBLISHED,
};

const CLOSED_REJECTED = 100;
const BACK_SPECIFIED_STEP = 200;
const BACK_TO_BEGINNING = 300;
const EXCEPTION_REJECTED = 400;

exports.rejectionPolicyTypes = {
    CLOSED_REJECTED,
    BACK_SPECIFIED_STEP,
    BACK_TO_BEGINNING,
    EXCEPTION_REJECTED,
};

exports.rejectionPolicies = Object.values(exports.rejectionPolicyTypes);

const APPROVE = 100;
const CONFIRM = 200;
const NOTIFY = 300;

exports.reviewTypes = {
    APPROVE,
    CONFIRM,
    NOTIFY,
};

exports.listViewQueries = {
    LIST_DISPLAY: 'list-display',
};

exports.reviewTypeOptionsDict = {
    [APPROVE]: {
        label: 'Approve / Reject',
        icon: 'user',
    },
    [CONFIRM]: {
        label: 'Confirmation Required',
        icon: 'check-square-o',
    },
    [NOTIFY]: {
        label: 'Notify Only',
        icon: 'bell',
    },
};

exports.reviewTypeOptions = [
    {
        ...exports.reviewTypeOptionsDict[APPROVE],
        value: APPROVE,
    },
    {
        ...exports.reviewTypeOptionsDict[CONFIRM],
        value: CONFIRM,
    },
    {
        ...exports.reviewTypeOptionsDict[NOTIFY],
        value: NOTIFY,
    },
];

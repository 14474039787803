import { upperCase } from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment-timezone/builds/moment-timezone-with-data-1970-2030';
import React, { PureComponent } from 'react';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { Box } from '@og-pro/ui';

import { CategoryCodes, SubscribeButton } from '../..';
import { statusClass, statusIcon } from '../../helpers/statusHelper';
import { timeClass, isUpcomingDate } from '../../helpers/listUtils';
import { onKeyDown } from '../../../helpers/accessibilityHelper';

class ConnectedFollowedProjectListItem extends PureComponent {
    static propTypes = {
        project: PropTypes.shape({
            addendums: PropTypes.array.isRequired,
            categories: PropTypes.array.isRequired,
            government: PropTypes.shape({
                code: PropTypes.string.isRequired,
                organization: PropTypes.shape({
                    name: PropTypes.string.isRequired,
                    timezone: PropTypes.string.isRequired,
                    timezoneReadable: PropTypes.string.isRequired,
                }).isRequired,
            }).isRequired,
            id: PropTypes.number.isRequired,
            isPrivate: PropTypes.bool.isRequired,
            projectVendorUserSubscriptions: PropTypes.shape({
                created_at: PropTypes.string.isRequired,
            }).isRequired,
            proposalDeadline: PropTypes.string,
            releaseProjectDate: PropTypes.string,
            status: PropTypes.string.isRequired,
            timelineConfig: PropTypes.shape({
                proposalDeadlineName: PropTypes.string.isRequired,
                releaseProjectDateName: PropTypes.string.isRequired,
            }).isRequired,
            title: PropTypes.string.isRequired,
        }).isRequired,
        router: PropTypes.object.isRequired,
        user: PropTypes.object.isRequired,
        vendUserLoadProjectSubscriptions: PropTypes.func.isRequired,
    };

    get styles() {
        return require('./ProposalsListItem.scss');
    }

    routeClick = () => {
        const {
            project: {
                government: { code },
                id,
            },
            router,
        } = this.props;

        router.push(`/portal/${code}/projects/${id}`);
    };

    renderDateIcon(date) {
        const hasUpcomingDate = isUpcomingDate(date);
        if (!hasUpcomingDate) return null;

        return <i className={`fa fa-exclamation-triangle ${this.styles.dateIcon}`} />;
    }

    renderProjectDates() {
        const {
            project: {
                government: {
                    organization: { timezone },
                },
                proposalDeadline,
                releaseProjectDate,
                timelineConfig: { proposalDeadlineName, releaseProjectDateName },
            },
        } = this.props;

        const dates = [];
        if (releaseProjectDate) {
            dates.push(
                <Box className={timeClass(releaseProjectDate)} component="dl" key="1" mb={0}>
                    <dt>
                        {this.renderDateIcon(releaseProjectDate)}
                        {releaseProjectDateName}
                    </dt>
                    <dd>{moment.tz(releaseProjectDate, timezone).format('ll')}</dd>
                </Box>
            );
        }
        if (proposalDeadline) {
            dates.push(
                <Box className={timeClass(proposalDeadline)} component="dl" key="2" mb={0}>
                    <dt>
                        {this.renderDateIcon(proposalDeadline)}
                        {proposalDeadlineName}
                    </dt>
                    <dd>
                        {moment.tz(proposalDeadline, timezone).format('ll')}{' '}
                        {moment.tz(proposalDeadline, timezone).format('h:mma')}
                    </dd>
                </Box>
            );
        }
        return dates;
    }

    render() {
        const {
            project: {
                addendums,
                categories,
                government: {
                    organization: { name, timezoneReadable },
                },
                isPrivate,
                projectVendorUserSubscriptions,
                status,
                title,
            },
            project,
            user,
            vendUserLoadProjectSubscriptions,
        } = this.props;

        const styles = this.styles;

        return (
            <div
                aria-label={`${title || 'Untitled'}. Press enter to view project details.`}
                className={`row ${styles.proposalListRow}`}
                onClick={this.routeClick}
                onKeyDown={(event) => onKeyDown(event, this.routeClick)}
                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                tabIndex={0}
            >
                <div className="text-center col-xs-12 col-sm-3 col-md-2">
                    <span className={`fa-stack fa-2x ${styles[statusClass(status)]}`}>
                        <i className="fa fa-stack-2x fa-square" />
                        <i className={`fa fa-stack-1x fa-inverse fa-${statusIcon(status)}`} />
                    </span>
                    <div className={`text-muted ${styles.statusText}`}>{upperCase(status)}</div>
                </div>
                <div className={`col-xs-12 col-sm-9 col-md-10 ${styles.detail}`}>
                    <div className={`row ${styles.titleRow}`}>
                        <div className="col-xs-12 col-sm-8 col-md-9 col-lg-10">
                            <h4 className={styles.title}>{title || 'Untitled'}</h4>
                        </div>
                        <div className="col-xs-12 col-sm-3 col-md-2" style={{ marginTop: 4 }}>
                            <SubscribeButton
                                onComplete={vendUserLoadProjectSubscriptions}
                                project={{ ...project, followers: [{ id: user.id }] }}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            <div className={styles.gov}>
                                <i className="fa fa-fw fa-institution" /> {name}
                            </div>
                            {isPrivate && (
                                <div className={this.styles.tags}>
                                    <i className="fa fa-fw fa-lock" /> Private Bid
                                </div>
                            )}
                            {categories.length > 0 && (
                                <div className={this.styles.tags}>
                                    <CategoryCodes codes={categories} limit={5} showIcon />
                                </div>
                            )}
                            <div className={this.styles.tags}>
                                <i className="fa fa-fw fa-bullhorn" /> Addenda & Notices:{' '}
                                {addendums.length}
                            </div>
                            <div className={this.styles.tags}>
                                <i className="fa fa-fw fa-rss" /> Followed:{' '}
                                {moment(projectVendorUserSubscriptions.created_at).format('l')}{' '}
                                {moment(projectVendorUserSubscriptions.created_at).format('LT')}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className={styles.dates}>{this.renderProjectDates()}</div>
                            <em className="text-muted">*All dates & times in {timezoneReadable}</em>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export const FollowedProjectListItem = withRouter(ConnectedFollowedProjectListItem);

import React from 'react';

import { GenericError, RequireTokenResetLogin } from '../components';
import {
    Login,
    LoginForm,
    PasswordResetForm,
    RequestToJoinPending,
    ResendActivation,
    SetPasswordForm,
    SignUpForm,
} from '../../containers';
import { clientNavigationOnly, loginRedirect } from '../loaders';

const LoginRoutes = (getState, dispatch) => [
    {
        element: <Login />,
        errorElement: <GenericError />,
        children: [
            {
                element: <LoginForm />,
                loader: loginRedirect(getState, dispatch),
                path: '/login',
            },
            {
                element: <PasswordResetForm />,
                loader: loginRedirect(getState, dispatch),
                path: '/reset-password',
            },
            {
                element: (
                    <RequireTokenResetLogin>
                        <SetPasswordForm />
                    </RequireTokenResetLogin>
                ),
                path: '/set-password',
            },
            {
                element: <SignUpForm />,
                loader: loginRedirect(getState, dispatch),
                path: '/signup',
            },
            {
                element: <ResendActivation />,
                loader: clientNavigationOnly,
                path: '/resend-activation',
            },
            {
                element: <RequestToJoinPending />,
                loader: clientNavigationOnly,
                path: '/request-pending',
            },
        ],
    },
];

export default LoginRoutes;

import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { formValueSelector, reset } from 'redux-form';

import {
    getSelectedCommentThreadsJS,
    getNewThreadFollowers,
    getProjectFieldsFromThread,
    getResolvedThreadCount,
    getThreadKey,
    getThreadValues,
} from './selectors';
import { getReviewProjectJS, isDocumentViewer } from '../selectors';
import { getUserJS, getInvitedUsersJS } from '../../selectors';
import * as govCommentsActions from '../../../actions/govComments';
import { Button } from '../../../components';
import {
    CommentThread,
    ItemDescription,
    NewComment,
    ResolvedToggle,
} from '../../../components/GovApp';
import { form, fieldNames } from '../../../components/GovApp/CommentThread/NewThreadForm/constants';

const formSelector = formValueSelector(form);

const mapStateToProps = (state) => {
    return {
        canComment: isDocumentViewer(state),
        commentAssociationField: get(getThreadValues(state), 'associationField'),
        commentAssociationId: get(getThreadValues(state), 'associationId'),
        comments: getSelectedCommentThreadsJS(state),
        commentType: get(getThreadValues(state), 'type'),
        createThreadError: state.govComments.get('createThreadError'),
        followerIdsValue: formSelector(state, fieldNames.FOLLOWER_IDS) || [],
        newThreadFollowers: getNewThreadFollowers(state),
        project: getReviewProjectJS(state),
        projectFieldDescription: get(getProjectFieldsFromThread(state), 'description'),
        resolvedThreadCount: getResolvedThreadCount(state),
        selectedCommentId: state.govComments.get('selectedCommentId'),
        showModal: state.govComments.get('showModal'),
        showNewThreadFollowersForm: state.govComments.get('showNewThreadFollowersForm'),
        showResolved: state.govComments.get('showResolved'),
        threadKey: getThreadKey(state),
        title: get(getProjectFieldsFromThread(state), 'title'),
        user: getUserJS(state),
        users: getInvitedUsersJS(state),
    };
};

const mapDispatchToProps = {
    ...govCommentsActions,
    resetForm: reset,
};

// @connect
class ConnectedCommentsModal extends Component {
    static propTypes = {
        addFollowers: PropTypes.func.isRequired,
        canComment: PropTypes.bool.isRequired,
        commentAssociationField: PropTypes.string,
        commentAssociationId: PropTypes.number,
        comments: PropTypes.array.isRequired,
        commentType: PropTypes.string,
        createCommentThread: PropTypes.func.isRequired,
        createThreadError: PropTypes.string,
        followerIdsValue: PropTypes.array.isRequired,
        hideCommentsModal: PropTypes.func.isRequired,
        hideFollowersForm: PropTypes.func.isRequired,
        newThreadFollowers: PropTypes.array.isRequired,
        project: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }),
        projectFieldDescription: PropTypes.string,
        reopenThread: PropTypes.func.isRequired,
        replyCommentThread: PropTypes.func.isRequired,
        resetForm: PropTypes.func.isRequired,
        resolvedThreadCount: PropTypes.number.isRequired,
        resolveThread: PropTypes.func.isRequired,
        selectedCommentId: PropTypes.number,
        showFollowersForm: PropTypes.func.isRequired,
        showModal: PropTypes.bool,
        showNewThreadFollowersForm: PropTypes.bool.isRequired,
        showResolved: PropTypes.bool,
        threadKey: PropTypes.string,
        title: PropTypes.string,
        toggleResolvedComments: PropTypes.func.isRequired,
        user: PropTypes.object.isRequired,
        users: PropTypes.array.isRequired,
    };

    componentDidMount() {
        const { selectedCommentId: id } = this.props;

        // Scrolls to the selected comment if a comment is specified
        if (id) {
            // Need to take off the event loop for child rendering to
            // complete, which will allow the target to be found
            setTimeout(() => {
                const targetEl = document.getElementById(`thread-comment-${id}`);
                if (targetEl) {
                    targetEl.scrollIntoView();
                }
            });
        }
    }

    newThreadSubmitHandler = (data) => {
        const {
            commentAssociationField,
            commentAssociationId,
            commentType,
            createCommentThread,
            hideFollowersForm,
            project,
            resetForm,
        } = this.props;

        const cleanData = {
            ...data,
            [commentAssociationField]: commentAssociationId,
            commentType,
            followerIds: (data.followerIds || []).map((user) => user.value),
        };

        return createCommentThread(project.id, cleanData).then((resultOrError) => {
            if (!(resultOrError instanceof Error)) {
                // Must reset the form so that the form is cleared, but the
                // initial values of followers is maintained.
                resetForm(form);
                hideFollowersForm();
            }
        });
    };

    replyCommentThreadHandler = (threadId, data) => {
        const { replyCommentThread, threadKey } = this.props;
        return replyCommentThread(threadId, data, threadKey, true);
    };

    resolveThreadHandler = (threadId) => {
        const { resolveThread, threadKey } = this.props;
        return resolveThread(threadId, threadKey, true);
    };

    reopenThreadHandler = (threadId) => {
        const { reopenThread, threadKey } = this.props;
        return reopenThread(threadId, threadKey, true);
    };

    addFollowersHandler = (threadId) => (formData) => {
        const { addFollowers, threadKey } = this.props;
        const followerIds = formData.followerIds || [];
        const userIds = followerIds.map((user) => user.value);
        return addFollowers(threadId, userIds, threadKey, true);
    };

    // Action must be called with null value to work properly for new threads. We pass `null`
    // because the event object is passed as the onClick parameter if no parameters are specified.
    showNewFollowersFormHandler = () => this.props.showFollowersForm(null);

    // Action must be called with null value to work properly for new threads. We pass `null`
    // because the event object is passed as the onClick parameter if no parameters are specified.
    hideNewFollowersFormHandler = () => this.props.hideFollowersForm(null);

    showFollowersFormHandler = (threadId) => {
        const { showFollowersForm, threadKey } = this.props;
        showFollowersForm(threadId, threadKey, true);
    };

    hideFollowersFormHandler = (threadId) => {
        const { hideFollowersForm, threadKey } = this.props;
        hideFollowersForm(threadId, threadKey, true);
    };

    renderDeletedComment() {
        return (
            <Modal onHide={this.props.hideCommentsModal} show>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">Comment Deleted</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    The item which was previously commented on has been deleted.
                </Modal.Body>
            </Modal>
        );
    }

    render() {
        const {
            canComment,
            comments,
            createThreadError,
            followerIdsValue,
            hideCommentsModal,
            newThreadFollowers,
            projectFieldDescription,
            resolvedThreadCount,
            selectedCommentId,
            showModal,
            showNewThreadFollowersForm,
            showResolved,
            title,
            toggleResolvedComments,
            user,
            users,
        } = this.props;

        if (!showModal) return null;

        // If a comment ID has been specified, but there are no comments
        // the item commented on has been deleted, so do not render the modal.
        if (comments.length === 0 && selectedCommentId) {
            return this.renderDeletedComment();
        }

        const CommentThreads = comments.map((thread) => (
            <CommentThread
                addFollowersError={thread.addFollowersError}
                addFollowersHandler={this.addFollowersHandler(thread.id)}
                canComment={canComment}
                comments={thread.threadComments}
                followers={thread.followers}
                hideFollowersFormHandler={() => this.hideFollowersFormHandler(thread.id)}
                key={thread.id}
                reopenThreadHandler={this.reopenThreadHandler}
                replyError={thread.replyError}
                replyThreadHandler={this.replyCommentThreadHandler}
                resolveError={thread.resolveError}
                resolveThreadHandler={this.resolveThreadHandler}
                resolved={thread.resolved}
                resolvedAt={thread.resolvedAt}
                resolvedBy={thread.resolvedBy}
                resolving={thread.resolving}
                selectedCommentId={selectedCommentId}
                show={!thread.resolved || showResolved}
                showFollowersForm={thread.showFollowersForm}
                showFollowersFormHandler={() => this.showFollowersFormHandler(thread.id)}
                threadId={thread.id}
                user={user}
                users={users}
            />
        ));

        const styles = require('./CommentsModal.scss');

        return (
            <Modal onHide={hideCommentsModal} show={showModal}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">Comments on {title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={styles.buttonsContainer}>
                        <div className="pull-right">
                            <ResolvedToggle
                                clickHandler={toggleResolvedComments}
                                count={resolvedThreadCount}
                                showResolved={showResolved}
                            />
                        </div>
                    </div>
                    <div className={styles.itemDescriptionContainer}>
                        <ItemDescription description={projectFieldDescription} />
                    </div>
                    {!canComment && (
                        <h5 className="text-center text-danger">
                            <i className="fa fa-exclamation-triangle" /> You must have view
                            permission to comment
                        </h5>
                    )}
                    <div className={styles.newCommentContainer}>
                        <NewComment
                            canComment={canComment}
                            createThreadError={createThreadError}
                            followerIdsValue={followerIdsValue}
                            followers={newThreadFollowers}
                            hideFollowersFormHandler={this.hideNewFollowersFormHandler}
                            showFollowersForm={showNewThreadFollowersForm}
                            showFollowersFormHandler={this.showNewFollowersFormHandler}
                            submitHandler={this.newThreadSubmitHandler}
                            user={user}
                            users={users}
                        />
                    </div>
                    <div className={styles.commentsContainer}>{CommentThreads}</div>
                    <div className="text-center">
                        <Button onClick={hideCommentsModal}>Close</Button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export const CommentsModal = connect(mapStateToProps, mapDispatchToProps)(ConnectedCommentsModal);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export class UpdateError extends PureComponent {
    static propTypes = {
        error: PropTypes.string,
        submitFailed: PropTypes.bool.isRequired,
    };

    get styles() {
        return require('./UpdateError.scss');
    }

    render() {
        if (!this.props.error && !this.props.submitFailed) return null;
        return (
            <h5 className={`text-danger ${this.styles.error}`}>
                {this.props.error ||
                    (this.props.submitFailed && 'Please fix form errors before submitting')}
            </h5>
        );
    }
}

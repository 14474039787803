import { capitalDesignTokens } from '@opengov/capital-mui-theme';

const baseContainer = {
    borderBottom: `1px solid ${capitalDesignTokens.semanticColors.border.primary}`,
    marginX: '-15px',
    paddingX: 7,
};

export const headerContainer = {
    ...baseContainer,
    paddingTop: '4px',
};

export const bodyContainer = {
    ...baseContainer,
    backgroundColor: capitalDesignTokens.semanticColors.background.primary,
    paddingY: 3,
};

export const formContainer = {
    ...baseContainer,
    paddingY: 3,
};

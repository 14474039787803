import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';

import { form, FISCAL_YEAR } from './constants';
import { SearchSelect } from '../../../../components';

const formConfig = {
    enableReinitialize: true,
    form,
};

// @reduxForm
class ConnectedFiscalYearSelectForm extends Component {
    static propTypes = {
        formClassName: PropTypes.string,
        options: PropTypes.array.isRequired,
    };

    render() {
        const { formClassName, options } = this.props;

        return (
            <Field
                blurInputOnSelect
                component={SearchSelect}
                formClassName={formClassName}
                isSearchable={false}
                name={FISCAL_YEAR}
                options={options}
                overrideFeedback
                placeholder="Select Fiscal Year"
            />
        );
    }
}

export const FiscalYearSelectForm = reduxForm(formConfig)(ConnectedFiscalYearSelectForm);

import { createSelector } from 'reselect';

import { projectTypesDict } from '@og-pro/shared-config/projects';

import { ALL, DRAFT, LIVE, SPECIAL, ARCHIVED } from './constants';
import { getProjectTypeSelectOptions, getTemplatesJS } from '../selectors';
import { hasContractingSubscription } from '../../selectors';

const { CONTRACT, PURCHASE } = projectTypesDict;

const getLocation = (state, props) => props.location;

export const getSelectedProjectType = createSelector(
    [getProjectTypeSelectOptions, getLocation],
    (projectTypeSelectOptions, location) => {
        const {
            query: { type },
        } = location;

        return (
            projectTypeSelectOptions.find((option) => option.value === type) ||
            projectTypeSelectOptions[0]
        ).value;
    }
);

const getFilteredTemplates = createSelector(
    [getTemplatesJS, getSelectedProjectType],
    (templates, projectType) => {
        if (projectType === ALL) {
            return templates;
        }

        return templates.filter((template) => template.type === projectType);
    }
);

const getFilteredTemplatesWithoutArchived = createSelector([getFilteredTemplates], (templates) =>
    templates.filter((t) => !t.template.archived)
);

const getNormalTemplates = createSelector([getFilteredTemplates], (templates) => {
    return templates.filter((templateProject) => !templateProject.template.isSpecial);
});

const getSpecialTemplates = createSelector([getFilteredTemplates], (templates) => {
    return templates.filter((templateProject) => templateProject.template.isSpecial);
});

const getDraftTemplates = createSelector([getNormalTemplates], (templates) => {
    return templates.filter(
        (templateProject) =>
            !templateProject.template.isPublished && !templateProject.template.archived
    );
});

const getLiveTemplates = createSelector([getNormalTemplates], (templates) => {
    return templates.filter(
        (templateProject) =>
            templateProject.template.isPublished && !templateProject.template.archived
    );
});

const getArchivedTemplates = createSelector([getNormalTemplates], (templates) => {
    return templates.filter((t) => t.template.archived);
});

export const getNavItems = createSelector(
    [
        getFilteredTemplatesWithoutArchived,
        getDraftTemplates,
        getLiveTemplates,
        getSpecialTemplates,
        getArchivedTemplates,
    ],
    (allTemplates, draftTemplates, liveTemplates, specialTemplates, archivedTemplates) => {
        return [
            {
                name: 'All',
                status: ALL,
                counter: allTemplates.length,
            },
            {
                name: 'Live',
                status: LIVE,
                counter: liveTemplates.length,
            },
            {
                name: 'Draft',
                status: DRAFT,
                counter: draftTemplates.length,
            },
            {
                name: 'Special',
                status: SPECIAL,
                counter: specialTemplates.length,
            },
            {
                name: 'Archived',
                status: ARCHIVED,
                counter: archivedTemplates.length,
            },
        ];
    }
);

export const getSelectedNavItem = createSelector(
    [getNavItems, getLocation],
    (navItems, location) => {
        const {
            query: { status },
        } = location;

        return navItems.find((item) => item.status === status) || navItems[0];
    }
);

export const getSelectedTemplates = createSelector(
    [
        getSelectedNavItem,
        getFilteredTemplatesWithoutArchived,
        getDraftTemplates,
        getLiveTemplates,
        getSpecialTemplates,
        getArchivedTemplates,
    ],
    (
        selectedNavItem,
        allTemplates,
        draftTemplates,
        liveTemplates,
        specialTemplates,
        archivedTemplates
    ) => {
        switch (selectedNavItem.status) {
            case ALL:
                return allTemplates;
            case LIVE:
                return liveTemplates;
            case DRAFT:
                return draftTemplates;
            case SPECIAL:
                return specialTemplates;
            case ARCHIVED:
                return archivedTemplates;
            default:
                return [];
        }
    }
);

export const getProjectTypeMenuItems = createSelector(
    [hasContractingSubscription],
    (hasContracting) => {
        return [
            {
                icon: 'bullhorn',
                label: 'Purchase',
                type: PURCHASE,
            },
            hasContracting && {
                icon: 'file-text',
                label: 'Contract',
                type: CONTRACT,
            },
            // {
            //     icon: 'paperclip',
            //     label: 'Addendum',
            //     type: ADDENDUM,
            // },
            // {
            //     icon: 'asterisk',
            //     label: 'Other',
            //     type: OTHER,
            // },
        ].filter((item) => !!item);
    }
);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';

import { LibrarySearch, ScopeSelect } from './components';
import { ProjectLibraryHOC } from './hocs';
import * as libraryActions from '../../../actions/projectLibrary';

const mapStateToProps = (state) => {
    return {
        copyFunction: state.projectLibrary.get('copyFunction'),
        page: state.projectLibrary.get('page'),
        show: state.projectLibrary.get('showModal'),
    };
};

const mapDispatchToProps = libraryActions;

// @connect
class ConnectedProjectLibraryModal extends Component {
    static propTypes = {
        show: PropTypes.bool.isRequired,
        page: PropTypes.number.isRequired,
        copyFunction: PropTypes.func,
        resetLibrary: PropTypes.func.isRequired,
        changePage: PropTypes.func.isRequired,
        loadProject: PropTypes.func.isRequired,
    };

    LibrarySearch = ProjectLibraryHOC(LibrarySearch);

    ScopeSelect = ProjectLibraryHOC(ScopeSelect);

    handleBack = () => {
        const { changePage } = this.props;

        changePage(1);
    };

    projectSelector = (project) => {
        this.props.loadProject(project.id);
        this.props.changePage(2);
    };

    renderBody() {
        switch (this.props.page) {
            case 1:
                return <this.LibrarySearch projectClickHandler={this.projectSelector} />;
            case 2:
                return (
                    <this.ScopeSelect
                        backHandler={this.handleBack}
                        copyFunction={this.props.copyFunction}
                        isModal
                        showBackButton
                    />
                );
            default:
                return null;
        }
    }

    render() {
        if (!this.props.show) return null;

        const { show, resetLibrary } = this.props;
        return (
            <Modal bsSize="lg" onHide={resetLibrary} show={show}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">Project Library Search</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.renderBody()}</Modal.Body>
                <Modal.Footer className="text-center">
                    <Button onClick={resetLibrary}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export const ProjectLibraryModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedProjectLibraryModal);

import Highcharts from 'highcharts';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { pieChartStatusColors } from '../../constants';
import { NoChartDataPanel } from '../../../../../components';

export default class ProjectsInReview extends Component {
    static propTypes = {
        projectsInReviewChartData: PropTypes.array.isRequired,
    };

    componentDidMount() {
        this.renderChart();
    }

    renderChart() {
        const { projectsInReviewChartData } = this.props;

        if (projectsInReviewChartData.length > 0) {
            // eslint-disable-next-line new-cap
            return new Highcharts.chart({
                chart: {
                    type: 'pie',
                    renderTo: 'review-projects-donut',
                    plotBackgroundColor: null,
                    plotBorderWidth: 0,
                    plotShadow: false,
                    borderWidth: 1,
                    borderColor: '#dce0e0',
                    height: 300,
                    marginTop: 80,
                },
                title: {
                    text: 'Projects in Review Stage',
                    align: 'center',
                    y: 20,
                    style: {
                        fontWeight: 500,
                        fontSize: '18px',
                        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
                    },
                },
                tooltip: {
                    pointFormatter() {
                        if (this.y > 0) {
                            return `Project${this.y > 1 ? 's: ' : ': '}${this.y}<br>Click to view`;
                        }
                    },
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        borderWidth: 3,
                        colors: pieChartStatusColors,
                        cursor: 'pointer',
                        center: ['50%', '80%'],
                        size: '130%',
                        startAngle: -90,
                        endAngle: 90,
                        dataLabels: {
                            alignTo: 'connectors',
                            connectorShape: 'crookedLine',
                            formatter() {
                                if (this.point.y > 0) {
                                    return `${this.point.name}:<br>${this.point.y} Project${
                                        this.point.y > 1 ? 's' : ''
                                    }`;
                                }
                            },
                            verticalAlign: 'top',
                        },
                    },
                },
                credits: {
                    enabled: false,
                },
                series: [
                    {
                        type: 'pie',
                        name: 'Number of Projects',
                        innerSize: '60%',
                        data: projectsInReviewChartData,
                    },
                ],
            });
        }
    }

    render() {
        const { projectsInReviewChartData } = this.props;

        return (
            <div className="col-md-4">
                <div id="review-projects-donut" />
                {!projectsInReviewChartData.length && (
                    <NoChartDataPanel
                        noDataSuccess
                        subtitle="No projects. Nice work!"
                        title="Projects in Review Stage"
                    />
                )}
            </div>
        );
    }
}

export const validate = (values) => {
    const errors = {};

    if (!values.templateIds || !values.templateIds.length) {
        errors.templateIds = 'Please select at least one process';
    }

    if (values.isCustomDate && !values.startDate) {
        errors.startDate = 'Please select a valid start date';
    }

    if (values.isCustomDate && !values.endDate) {
        errors.endDate = 'Please select a valid end date';
    }

    if (!values.isCustomDate && !values.timeRange) {
        errors.timeRange = 'Please select a time range';
    }

    return errors;
};

import React from 'react';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm, Field } from 'redux-form';

import { reorderPolicies } from '../../../../actions/retention';
import { InputText, DragIcon } from '../../../../components';
import { getDndStyle } from '../../../../constants/styles';
import { getUserJS } from '../../../selectors';
import { getGovernmentRetentionLoading, getGovernmentRetentionUpdating } from '../selectors';
import { Policy } from '../Policy';
import { useDebounce } from '../../../../hooks';

export const ConnectedOrderablePolicyList = ({
    retentionCodes,
    onEditPolicy,
    initialize,
    change,
}) => {
    const dispatch = useDispatch();
    const isGovernmeentRetentionLoading = useSelector(getGovernmentRetentionLoading);
    const isGovernmentRetentionUpdating = useSelector(getGovernmentRetentionUpdating);
    const loading = isGovernmeentRetentionLoading || isGovernmentRetentionUpdating;
    const user = useSelector(getUserJS);

    const styles = require('./index.scss');
    const [records, setRecords] = React.useState([]);

    React.useEffect(() => {
        setRecords(retentionCodes);
        // initialize the form with the order received from the API
        initialize({
            order: retentionCodes.reduce((acc, rc) => {
                return {
                    ...acc,
                    [rc.id]: rc.orderById,
                };
            }, {}),
        });
    }, [initialize, retentionCodes]);

    const onSave = (newOrder) => {
        dispatch(
            reorderPolicies({
                data: newOrder,
                governmentId: user.government_id,
            })
        );
    };
    const saveNewOrder = useDebounce(onSave, 500);

    if (!retentionCodes || !retentionCodes.length) {
        return null;
    }
    /**
     * Handles the case for when the user manually changes the input containing the order value
     * Manually changes the value in the records copy and then sorts them by the orderById prop
     * The call to change the redux-form value is not needed since the onChange gets called by the lib
     *
     * @param {number} policyId
     * @param {string|number} value
     * @param {number} previousValue
     */
    const handleManualChange = (policyId, value, previousValue) => {
        const numericValue = parseInt(value, 10);

        if (!numericValue || numericValue === previousValue) {
            return;
        }

        const reordered = records
            .map((policy) => {
                if (policy.id === parseInt(policyId, 10)) {
                    return {
                        ...policy,
                        // this here makes it so that if you do 3 => 1 the record will be placed
                        // before what was previously in the 1 position
                        // if you do 1 => 3 the record will be put in the last position and 3 will come to be second
                        orderById:
                            numericValue > previousValue
                                ? parseFloat(numericValue + 0.1)
                                : parseFloat(numericValue - 0.1),
                    };
                }

                return policy;
            })
            .sort((a, b) => a.orderById - b.orderById)
            .map((policy, index) => {
                return {
                    ...policy,
                    orderById: index + 1,
                };
            });

        setRecords(reordered);
        saveNewOrder(reordered.map((policy) => pick(policy, ['id', 'orderById'])));
    };

    const handleDragEnd = (result) => {
        const originLocation = result.source.index;
        const newLocation = result.destination ? result.destination.index : undefined;

        if (newLocation !== undefined && newLocation !== originLocation) {
            const movedRecord = records.find(
                (policy) => policy.id === parseInt(result.draggableId, 10)
            );

            const filtered = records.filter((policy) => policy.id !== movedRecord.id);

            const reordered = [
                ...filtered.slice(0, newLocation),
                movedRecord,
                ...filtered.slice(newLocation),
            ].map((policy, index) => {
                return {
                    ...policy,
                    orderById: index + 1,
                };
            });

            // set the newly ordered records and update the redux-form values accordingly
            setRecords(reordered);
            change(
                'order',
                reordered.reduce((acc, rc) => {
                    return {
                        ...acc,
                        [rc.id]: rc.orderById,
                    };
                }, {})
            );
            saveNewOrder(reordered.map((policy) => pick(policy, ['id', 'orderById'])));
        }
    };

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="retention-policies-list" isDropDisabled={loading}>
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getDndStyle(snapshot)}
                    >
                        {records.map((policy, index) => (
                            <Draggable
                                draggableId={policy.id.toString()}
                                index={index}
                                isDragDisabled={loading}
                                key={policy.id}
                            >
                                {(prov) => (
                                    <div
                                        ref={prov.innerRef}
                                        {...prov.draggableProps}
                                        {...prov.dragHandleProps}
                                    >
                                        <Policy
                                            dragIcon={
                                                <Row className={styles.flexRow}>
                                                    <Col className={styles.selfCenter} xs={2}>
                                                        <DragIcon
                                                            dragHandleProps={prov.dragHandleProps}
                                                        />
                                                    </Col>
                                                    <Col className={styles.selfCenter} xs={8}>
                                                        <Field
                                                            aria-label="Item order"
                                                            autoComplete="off"
                                                            className={styles.input}
                                                            component={InputText}
                                                            disabled={loading}
                                                            formGroupClassName={styles.noMargin}
                                                            hasFeedback={false}
                                                            label=""
                                                            name={`order.${policy.id}`}
                                                            onChange={(event) =>
                                                                handleManualChange(
                                                                    policy.id,
                                                                    event.target.value,
                                                                    policy.orderById
                                                                )
                                                            }
                                                            qaTag="retention-code-order-input"
                                                            type="number"
                                                        />
                                                    </Col>
                                                </Row>
                                            }
                                            onEditPolicy={onEditPolicy}
                                            policy={policy}
                                        />
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

ConnectedOrderablePolicyList.propTypes = {
    retentionCodes: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            orderById: PropTypes.number,
        })
    ).isRequired,
    onEditPolicy: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
};

export const OrderablePolicyList = reduxForm({
    form: 'retentionCodeOrderableList',
    initialValues: {
        order: {},
    },
})(ConnectedOrderablePolicyList);

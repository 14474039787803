import { hexToRGB } from '@og-pro/shared-config/helpers';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';

export const customFieldsModalContainerStyle = {
    bgcolor: 'background.paper',
    border: '1px #CED2D6 solid',
    borderRadius: 1,
    boxShadow: '0px 3px 5px rgba(19, 21, 23, 0.22)',
    display: 'flex',
    flexDirection: 'column',
    left: '50%',
    opacity: 1,
    overflow: 'hidden',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '600px',
    paddingTop: 2,
    paddingBottom: 3,
    paddingLeft: 3,
    paddingRight: 3,
};

export const customFieldsTileContainerStyle = {
    border: `1px ${capitalDesignTokens.semanticColors.border.primary} solid`,
    borderRadius: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: 2,
    width: '100%',
    background: hexToRGB(capitalDesignTokens.semanticColors.dataVisualization.sequence450, 0.2),
};

export const customFieldsTileStyle = {
    color: capitalDesignTokens.semanticColors.foreground.primary,
};

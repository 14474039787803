import { fieldNames } from './constants';

const { NAME } = fieldNames;

export const validate = (values) => {
    const errors = {};

    if (!values[NAME] || values[NAME].length === 0) {
        errors[NAME] = 'Please enter a name for this list';
    }

    return errors;
};

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { reduxForm, Field } from 'redux-form';

import { form, fieldNames } from './constants';
import { Button, InputText } from '../../../../../../../components';

const { PERSONAL_NOTE } = fieldNames;

const formConfig = {
    form,
};

// @reduxForm
class ConnectedEvaluatorReminderEmailForm extends PureComponent {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        hideModal: PropTypes.func.isRequired,
        submitHandler: PropTypes.func.isRequired,
    };

    render() {
        const { handleSubmit, hideModal, submitHandler } = this.props;

        const styles = require('./EvaluatorReminderEmailForm.scss');
        return (
            <form onSubmit={handleSubmit(submitHandler)}>
                <Field
                    component={InputText}
                    label="Add a Personal Note (Optional)"
                    minRows={4}
                    name={PERSONAL_NOTE}
                    overrideFeedback
                    placeholder="Enter Text"
                    type="textarea"
                />
                <div className={styles.buttonGroup}>
                    <Button bsSize="sm" onClick={() => hideModal()}>
                        Cancel
                    </Button>
                    <Button bsStyle="primary" className={styles.submitBtn} type="submit">
                        Send Reminder
                    </Button>
                </div>
            </form>
        );
    }
}

export const EvaluatorReminderEmailForm = reduxForm(formConfig)(
    ConnectedEvaluatorReminderEmailForm
);

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { GenericEditor } from '../GenericEditor';
import { dollarString } from '../../../../Forms/normalizers';

export class NumericEditor extends PureComponent {
    static propTypes = {
        auctionMaxFractionDigits: PropTypes.number,
        charPress: PropTypes.string,
        isReverseAuction: PropTypes.bool,
        min: PropTypes.number,
        node: PropTypes.object.isRequired,
        preventEmptyCell: PropTypes.bool,
        value: PropTypes.node,
    };

    constructor(props) {
        super(props);
        let value = props.value || '';
        if (props.charPress) {
            value += props.charPress;
        }

        this.state = {
            value,
        };
    }

    afterGuiAttached = () => {
        this.editor.afterGuiAttached();
    };

    getValue() {
        const value = this.state.value === '' ? null : this.state.value;
        const numericValue = Number.parseFloat(value);
        if (Number.isNaN(Number.parseFloat(numericValue))) {
            return null;
        }

        return numericValue;
    }

    handleChange = (event) => {
        const { auctionMaxFractionDigits, isReverseAuction } = this.props;

        // Thanks https://stackoverflow.com/a/9409894/2518231
        // NOTE: this does not prevent values such as "2...2" or "1.1.1.1"
        const value = event.target.value.replace(/[^\d.-]/g, '');

        this.setState({
            value: isReverseAuction
                ? dollarString(value, { maximumFractionDigits: auctionMaxFractionDigits })
                : value,
        });
    };

    isCancelAfterEnd = () => {
        const { min, preventEmptyCell } = this.props;
        const value = this.getValue();
        if (value !== null && min !== undefined) {
            return value < min;
        }

        if (preventEmptyCell) {
            return value === null;
        }

        return false;
    };

    setRef = (ref) => {
        this.editor = ref;
    };

    render() {
        return (
            <GenericEditor
                inputType="number"
                max="99999999.99"
                node={this.props.node}
                onChange={this.handleChange}
                ref={this.setRef}
                value={this.state.value}
            />
        );
    }
}

import { fromJS } from 'immutable';

import * as errorActions from '../actions/error';

const initialState = fromJS({
    nextRoute: null,
    unauthorized: false,
});

export default function errorReducer(state = initialState, action = {}) {
    switch (action.type) {
        case errorActions.UNAUTHORIZED:
            return state.merge(
                fromJS({
                    nextRoute: action.nextRoute,
                    unauthorized: true,
                })
            );
        case errorActions.RESET:
            return initialState;
        default:
            return state;
    }
}

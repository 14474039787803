import { get } from 'lodash';

import { attachmentTypesDict } from '@og-pro/shared-config/attachments';

import { emitVendorProposalSocket } from './utils';
import { UPDATE_QUESTIONNAIRE_RESPONSE } from './vendProposals';
import request from '../request';
import { resourceManager } from '../helpers';
import { updateQuestionnaireResponse } from './checklists';
import { showSnackbar } from './notification';

const { PROPOSAL_DOCUMENT, QUESTIONNAIRE } = attachmentTypesDict;

export const SHOW_QUESTIONNAIRE_MODAL = 'questionnaire/SHOW_QUESTIONNAIRE_MODAL';
export const SHOW_QUESTIONNAIRE_EDIT_MODAL = 'questionnaire/SHOW_QUESTIONNAIRE_EDIT_MODAL';
export const HIDE_QUESTIONNAIRE_MODAL = 'questionnaire/HIDE_QUESTIONNAIRE_MODAL';
export const SET_QUESTIONNAIRE_MODAL_DATA = 'questionnaire/SET_QUESTIONNAIRE_MODAL_DATA';

export function showQuestionnaireCreateModal(formData, modalOptions) {
    return { type: SHOW_QUESTIONNAIRE_MODAL, formData, modalOptions };
}

export function showQuestionnaireEditModal(formData, modalOptions) {
    return { type: SHOW_QUESTIONNAIRE_EDIT_MODAL, formData, modalOptions };
}

export function hideQuestionnaireCreateModal() {
    return { type: HIDE_QUESTIONNAIRE_MODAL };
}

export function setQuestionnaireModalData(formData, page) {
    return { type: SET_QUESTIONNAIRE_MODAL_DATA, formData, page };
}

/**
 * Uploads a file to s3 and returns the created attachment data
 * @param  {object} client API client
 * @param  {string} s3Endpoint ID of the the questionnaire to create a response to
 * @param  {string} attachmentType Attachment type
 * @param  {object} file File to upload
 * @param  {Function} onProgress Function to call with file progress info
 * @return {object} The newly created attachment object
 */
function createQuestionnaireFileUpload(client, s3Endpoint, attachmentType, file, onProgress) {
    const filename = encodeURIComponent(file.name);
    const contentType = encodeURIComponent(file.type);
    const signedS3Endpoint = `${s3Endpoint}?filename=${filename}&contentType=${contentType}`;

    let signedData;
    // Get the a pre-signed s3 PUT url from OpenGov Procurement API
    return client
        .get(signedS3Endpoint)
        .then((result) => {
            signedData = result;
            const postData = {
                data: file,
                headers: { 'Content-Type': file.type },
                onProgress: (e) => onProgress(e.percent),
            };
            // Write to the s3 API
            return request.put(result.signedPutUrl, postData);
        })
        .then(() => {
            // Set progress to 100% on the upload
            onProgress(100);

            // Build the attachment data that will be used by the questionnaire
            return {
                bucket: signedData.bucket,
                path: signedData.key,
                filename: signedData.filename,
                type: attachmentType,
            };
        });
}

/**
 * Uploads a file to s3 and inserts the attachment info into the questionnaire.
 * The UI update is managed internally by the component's state, not the redux store.
 * @param  {string} s3Url Location to get the signed S3 URL to upload the file for the questionnaire
 * @return {object} The newly created questionnaire data
 */
export function createFileDownloadInput(s3Url) {
    return (dispatch, getState, client) => {
        return (file, onProgress, values) => {
            return createQuestionnaireFileUpload(
                client,
                s3Url,
                QUESTIONNAIRE,
                file,
                onProgress
            ).then((attachmentData) => {
                // Build the questionnaire data
                const existingAttachments = get(values, 'attachments') || [];
                return { attachments: existingAttachments.concat(attachmentData) };
            });
        };
    };
}

/**
 * Uploads a file to s3 and creates a new questionnaireResponse with the upload data.
 * The UI update is managed internally by the component's state, not the redux store.
 * @param  {number} proposalId      ID of the proposal to upload the file and create the questionnaireResponse
 * @param  {number} questionnaireId ID of the the questionnaire to create a response to
 * @return {object}                 The newly created questionnaireResponse instance
 */
export function createProposalFileUploadResponse(proposalId, questionnaireId) {
    return (dispatch, getState, client) => {
        return (file, onProgress, values) => {
            return createQuestionnaireFileUpload(
                client,
                `/proposal/${proposalId}/s3`,
                PROPOSAL_DOCUMENT,
                file,
                onProgress
            )
                .then((attachmentData) => {
                    // Build the questionnaireResponse data
                    const existingAttachments = get(values, 'data.attachments') || [];
                    const data = {
                        data: {
                            attachments: existingAttachments.concat(attachmentData),
                        },
                        questionnaire_id: questionnaireId,
                    };

                    // Create the questionnaireResponse using the s3 file info and return the result
                    return client.post(`/proposal/${proposalId}/questionnaire-response`, { data });
                })
                .then((result) => {
                    const updateAction = { type: UPDATE_QUESTIONNAIRE_RESPONSE, result };
                    const formAction = {
                        type: UPDATE_QUESTIONNAIRE_RESPONSE,
                        data: result,
                    };
                    dispatch(
                        emitVendorProposalSocket(
                            proposalId,
                            updateAction,
                            formAction,
                            'New File Uploaded'
                        )
                    );
                    return result;
                });
        };
    };
}

/**
 * Uploads a file to s3 and creates a new questionnaireResponse with the upload data.
 * The UI update is managed internally by the component's state, not the redux store.
 * @param  {number} checklistId     ID of the checklist to upload the file and create the questionnaireResponse
 * @param  {number} questionnaireId ID of the the questionnaire to create a response to
 * @return {object}                 The newly created questionnaireResponse instance
 */
export function createChecklistFileUploadResponse(checklistId, questionnaireId) {
    return (dispatch, getState, client) => {
        return (file, onProgress, values) => {
            return createQuestionnaireFileUpload(
                client,
                `/checklists/${checklistId}/s3`,
                PROPOSAL_DOCUMENT,
                file,
                onProgress
            ).then((attachmentData) => {
                // Build the questionnaireResponse data
                const existingAttachments = get(values, 'data.attachments') || [];
                const data = {
                    data: {
                        attachments: existingAttachments.concat(attachmentData),
                    },
                };

                // Update the questionnaireResponse using the s3 file info and return the result
                // Need to save so we get attachment object back in questionnaireResponse, so
                // uploaded file can be properly displayed in file upload form
                return dispatch(updateQuestionnaireResponse(checklistId, questionnaireId, data));
            });
        };
    };
}

export function createQuestionnaire(id, data) {
    return resourceManager({
        method: 'post',
        url: `/project/${id}/questionnaire`,
        requestOptions: { data },
        onSuccess: ({ result }) => {
            return result;
        },
        onFailure: ({ error, dispatch }) => {
            dispatch(
                showSnackbar('Failed to create the question', {
                    isError: true,
                })
            );
            return error;
        },
    });
}

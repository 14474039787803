import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { reduxForm, Field } from 'redux-form';

import { form, fieldNames } from './constants';
import { validate } from './validate';
import { Button, SearchSelect, SearchSelectUserOption } from '../../../../../components';

const { USERS } = fieldNames;

const formConfig = {
    form,
    validate,
};

// @reduxForm
class ConnectedContractSubscribersForm extends PureComponent {
    static propTypes = {
        addError: PropTypes.string,
        disabled: PropTypes.bool,
        handleSubmit: PropTypes.func.isRequired,
        usersList: PropTypes.array.isRequired,
    };

    render() {
        const { addError, disabled, handleSubmit, usersList } = this.props;

        return (
            <form className="row" onSubmit={handleSubmit}>
                <div className="col-sm-8">
                    <Field
                        closeMenuOnSelect={false}
                        component={SearchSelect}
                        components={{
                            Option: SearchSelectUserOption,
                        }}
                        disabled={disabled}
                        isClearable={false}
                        isMulti
                        name={USERS}
                        options={usersList}
                        placeholder="Select Users to Notify"
                        showInviteUserHelp
                    />
                </div>
                <div className="col-sm-4">
                    <Button block bsStyle="primary" disabled={disabled} type="submit">
                        <i className="fa fa-user-plus" />
                        &nbsp;Add
                    </Button>
                    {addError && <div className="error-block">{addError}</div>}
                </div>
            </form>
        );
    }
}

export const ContractSubscribersForm = reduxForm(formConfig)(ConnectedContractSubscribersForm);

import React, { useState } from 'react';
import classnames from 'classnames';
import { Box } from '@og-pro/ui';

import { CDSButton } from '../CDSButtons';
import { Content } from './Content';
import { PROP_TYPES } from './types';

export const MobileLayout = ({
    navigation,
    header,
    content,
    footer,
    showSharedTextareaToolbar = false,
}) => {
    const [collapsed, setCollapsed] = useState(true);
    const styles = require('./index.scss');

    return (
        <div className={classnames(styles.menuAndMainContainer, styles.hiddenMenuAndMainContainer)}>
            {!collapsed && (
                <div
                    className={classnames(styles.menuContainer, styles.collapsedMenuContainer)}
                    onClick={() => setCollapsed(true)}
                >
                    <div className={styles.stickyContainer}>
                        <Box mb={2}>
                            <CDSButton
                                className={styles.expandButton}
                                noPadding
                                onClick={() => setCollapsed(true)}
                                qaTag="sdv2Layout-collapseMenu"
                                variant="text"
                            >
                                <i className="fa fa-chevron-left" />
                            </CDSButton>
                        </Box>

                        {navigation}
                    </div>
                </div>
            )}
            {collapsed && (
                <Content {...{ header, content, footer, showSharedTextareaToolbar }}>
                    <Box bgcolor="#FFFFFF" p={2}>
                        <CDSButton
                            className={styles.expandButton}
                            noPadding
                            onClick={() => setCollapsed(false)}
                            qaTag="sdv2Layout-expandMenu"
                            variant="text"
                        >
                            <i className="fa fa-list" /> MENU
                        </CDSButton>
                    </Box>
                </Content>
            )}
        </div>
    );
};
MobileLayout.propTypes = PROP_TYPES;

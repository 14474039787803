import React from 'react';

import { ReduxFormCheckboxGroup } from '@og-pro/ui';
import { useSelector } from 'react-redux';
import { Field, getFormValues } from 'redux-form';

import {
    customFieldFields,
    ENUM_OPTIONS_FIELD,
    instructionsModes,
} from '@og-pro/shared-config/customFormService/customField';

import { formConfig } from '../Form/form';

const { NAME, INSTRUCTIONS_TEXT, INSTRUCTIONS_MODE } = customFieldFields;
const { TOOLTIP, DESCRIPTION } = instructionsModes;

export const MultipleChoicePreview = () => {
    const formValues = useSelector((state) => getFormValues(formConfig.form)(state)) || {};
    const instructionsText = formValues[INSTRUCTIONS_TEXT] || '';
    const defaultDescription = 'Select all that apply.';

    const multipleChoiceOption = formValues[ENUM_OPTIONS_FIELD] || [];

    const multipleChoiceOptionArray = multipleChoiceOption.map((option, index) => ({
        value: option || `Option ${index + 1}`,
        label: option || `Option ${index + 1}`,
    }));

    const description =
        formValues[INSTRUCTIONS_MODE] === DESCRIPTION ? (
            <>
                {instructionsText && (
                    <>
                        {instructionsText}
                        <br />
                    </>
                )}
                {defaultDescription}
            </>
        ) : (
            defaultDescription
        );

    const tooltip = formValues[INSTRUCTIONS_MODE] === TOOLTIP ? instructionsText : undefined;

    return (
        <Field
            component={ReduxFormCheckboxGroup}
            description={description}
            disabled
            label={formValues[NAME] || 'Your title will go here'}
            name="multipleChoice_PREVIEW"
            options={multipleChoiceOptionArray}
            sx={{
                width: '100%',
            }}
            tooltip={tooltip}
        />
    );
};

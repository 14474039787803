import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getApprovalJS } from './selectors';
import * as approvalsActions from '../../../actions/approvals';
import { ApprovalsModal as ApprovalsModalComponent } from '../../../components/GovApp';

const mapStateToProps = (state) => {
    return {
        approval: getApprovalJS(state),
        modalType: state.approvals.get('modalType'),
        show: state.approvals.get('showModal'),
        submitError: state.approvals.get('updateError'),
    };
};

const mapDispatchToProps = approvalsActions;

// @connect
class ConnectedApprovalsModal extends Component {
    static propTypes = {
        show: PropTypes.bool.isRequired,
        modalType: PropTypes.string,
        submitError: PropTypes.string,
        approval: PropTypes.object,
        approveProject: PropTypes.func.isRequired,
        rejectProject: PropTypes.func.isRequired,
        updateApproval: PropTypes.func.isRequired,
        hideModal: PropTypes.func.isRequired,
        showModal: PropTypes.func.isRequired,
    };

    render() {
        const {
            show,
            modalType,
            approval,
            hideModal,
            submitError,
            approveProject,
            rejectProject,
            updateApproval,
            showModal,
        } = this.props;

        if (!approval || !modalType) return null;

        return (
            <ApprovalsModalComponent
                approval={approval}
                approveHandler={(data) => approveProject(approval.id, data)}
                hideModal={hideModal}
                rejectHandler={(data) => rejectProject(approval.id, data)}
                show={show}
                showModal={(type) => showModal(type, approval)}
                submitError={submitError}
                type={modalType}
                updateHandler={(data) => updateApproval(approval.id, data)}
            />
        );
    }
}

export const ApprovalsModal = connect(mapStateToProps, mapDispatchToProps)(ConnectedApprovalsModal);

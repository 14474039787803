import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link, createSearchParams } from 'react-router-dom';

import { UserProfilePicture } from '../../../../../components';
import { commentDateFormatter } from '../../../../../utils';

export class RevisionsList extends PureComponent {
    static propTypes = {
        activeIndex: PropTypes.number,
        hasMore: PropTypes.bool.isRequired,
        loadingMore: PropTypes.bool.isRequired,
        loadMoreError: PropTypes.string,
        loadMoreRevisions: PropTypes.func.isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
            query: PropTypes.object.isRequired,
        }),
        projectAudits: PropTypes.array.isRequired,
    };

    componentDidMount() {
        // Don't scroll the first element into view
        const { activeIndex } = this.props;
        if (!activeIndex || activeIndex === 0) return null;

        const ref = this[activeIndex.toString()];
        if (ref) {
            ref.scrollIntoView();
        }
    }

    get styles() {
        return require('./index.scss');
    }

    renderLoadMore() {
        if (!this.props.hasMore) return null;
        if (this.props.loadingMore) {
            return (
                <div className={this.styles.loadMore}>
                    <i className="fa fa-spinner fa-spin" /> Loading...
                </div>
            );
        }
        if (this.props.loadMoreError) {
            return (
                <div className={this.styles.loadMore}>
                    <i className="text-danger fa fa-warning" /> Loading error. &nbsp;
                    <span className="pseudoLink" onClick={this.props.loadMoreRevisions}>
                        Please try again.
                    </span>
                </div>
            );
        }
        return (
            <div
                className={`pseudoLink ${this.styles.loadMore}`}
                onClick={this.props.loadMoreRevisions}
            >
                Load More
            </div>
        );
    }

    setRef = (idx) => {
        return (ref) => {
            this[idx] = ref;
        };
    };

    render() {
        const {
            projectAudits,
            location: { pathname, query },
            activeIndex,
        } = this.props;

        const styles = this.styles;

        const revisionsList = projectAudits.map((projectAudit, idx) => {
            const selectedClass = parseInt(activeIndex, 10) === idx ? styles.selected : '';

            return (
                <Link
                    key={idx}
                    to={{
                        pathname,
                        search: createSearchParams({ ...query, revision: idx }).toString(),
                    }}
                >
                    <div
                        className={`${styles.userList} ${selectedClass}`}
                        key={idx}
                        ref={this.setRef(idx)}
                    >
                        <UserProfilePicture
                            className={styles.userPicture}
                            user={projectAudit.user}
                        />
                        <div className={styles.userInfo}>
                            <div className={styles.name}>{projectAudit.user.fullName}</div>
                            <div>{commentDateFormatter(projectAudit.created_at)}</div>
                        </div>
                    </div>
                </Link>
            );
        });

        return (
            <div className={styles.revisionList}>
                {revisionsList}
                {this.renderLoadMore()}
            </div>
        );
    }
}

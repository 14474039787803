import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ListGroup } from 'react-bootstrap';

import { GovListItem } from './GovListItem';

export class GovList extends PureComponent {
    static propTypes = {
        governments: PropTypes.array.isRequired,
    };

    render() {
        const { governments } = this.props;

        if (governments.length === 0) {
            return (
                <div className="text-center text-muted">
                    <em>No organizations found</em>
                </div>
            );
        }

        const GovListItems = governments.map((gov) => (
            <GovListItem
                government={gov.government}
                key={gov.government.id}
                organization={gov.organization}
                to={`/portal/${gov.government.code}`}
            />
        ));
        return <ListGroup>{GovListItems}</ListGroup>;
    }
}

import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, Field, FieldArray } from 'redux-form';

import { evaluationStatuses } from '@og-pro/shared-config/evaluations';

import { instructions, helpPopover, helpSealedBid } from './constants';
import { getTotalWeight } from './selectors';
import { getProjectJS } from '../../selectors';
import { formConfig, fieldNames, evaluationFieldNames, progressSectionsDict } from '../constants';
import { mapStateToProps as evaluationCreateMapStateToProps } from '../mapProps';
import { Checkbox, HelpIcon, Main, SectionTitle } from '../../../../components';
import { EvaluationCriteriaList } from '../../../../components/GovApp';
import { extendSimpleWizardForm } from '../../../../hocs';

const { COMPLETE, RELEASED } = evaluationStatuses;

const { EVALUATION, HAS_SEALED_BID } = fieldNames;
const { SCORING_CRITERIA } = evaluationFieldNames;

const mapStateToProps = (state) => {
    const evaluationPhases = get(getProjectJS(state), 'evaluationPhases') || [];
    return {
        ...evaluationCreateMapStateToProps(state),
        canAddPhase: evaluationPhases.length >= 2, // Single phase evaluations do not use this option
        totalWeight: getTotalWeight(state),
    };
};

// @connect
// @reduxForm
// @extendSimpleWizardForm
class ConnectedEvaluationCriteria extends Component {
    static propTypes = {
        canAddPhase: PropTypes.bool.isRequired,
        change: PropTypes.func.isRequired,
        project: PropTypes.shape({
            evaluation: PropTypes.shape({
                status: PropTypes.string.isRequired,
            }).isRequired,
            wasPosted: PropTypes.bool,
        }).isRequired,
        showFormErrors: PropTypes.bool.isRequired,
        totalWeight: PropTypes.number,
        updating: PropTypes.bool.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    renderCheckboxText() {
        return (
            <span>
                Use Sealed Bid Process?
                <HelpIcon placement="right" tooltip={helpSealedBid} />
            </span>
        );
    }

    render() {
        const {
            canAddPhase,
            change,
            project: {
                evaluation: { status },
                wasPosted,
            },
            showFormErrors,
            totalWeight,
            updating,
        } = this.props;

        return (
            <Main>
                <SectionTitle help={helpPopover} info={instructions} title="Evaluation Criteria" />
                <FieldArray
                    canAddPhase={canAddPhase}
                    change={change}
                    component={EvaluationCriteriaList}
                    disabled={updating}
                    isEvaluationReleased={status === RELEASED || status === COMPLETE}
                    isMultiPhase={false}
                    name={`${EVALUATION}.${SCORING_CRITERIA}`}
                    showFormErrors={showFormErrors}
                    totalWeight={totalWeight}
                    useRawDescription
                />
                {!wasPosted && (
                    <Field
                        component={Checkbox}
                        disabled={updating}
                        formGroupClass={this.styles.checkbox}
                        inline
                        name={HAS_SEALED_BID}
                        qaTag="evaluationCreate-sealedBid"
                        text={this.renderCheckboxText()}
                    />
                )}
            </Main>
        );
    }
}

export const EvaluationCriteria = compose(
    connect(mapStateToProps),
    reduxForm(formConfig),
    extendSimpleWizardForm(progressSectionsDict.CRITERIA)
)(ConnectedEvaluationCriteria);

import React from 'react';
import { FieldArray } from 'redux-form';

import PropTypes from 'prop-types';

import { OptionsField } from './OptionsField';

export const DropdownOptions = ({ disabled }) => {
    return (
        <FieldArray
            component={OptionsField}
            disabled={disabled}
            name="enumOptions"
            showBulkAddButton
        />
    );
};

DropdownOptions.propTypes = {
    disabled: PropTypes.bool,
};

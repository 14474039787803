import PropTypes from 'prop-types';
import React from 'react';

import { RouterLink } from '../../..';

export function generateOptionIcons(items) {
    const styles = require('../index.scss');

    // Need to add clearfixes at the end of each row which is dependent on the viewport
    const OptionIcons = [];

    items.forEach((component, idx) => {
        const itemNum = idx + 1;
        OptionIcons.push(component);

        // Don't add clearfix after the last item
        if (itemNum % 2 === 0 && itemNum !== items.length) {
            OptionIcons.push(
                <div className={`clearfix ${styles.categoryRow}`} key={`${itemNum}two`} />
            );
        }
    });

    OptionIcons.push(<div className="clearfix" key="lastClearfix" />);

    return OptionIcons;
}

export const TemplateSelect = ({
    isTemplateAdmin,
    onSelect,
    templateAdminUrl,
    templates,
    templateSupportUrl,
    type,
}) => {
    const styles = require('../index.scss');

    if (templates.length === 0) {
        return (
            <>
                <div className={styles.titleText}>No {type} templates have been added yet</div>
                <p>
                    <i className="fa fa-4x fa-file-text-o" />
                </p>
                {isTemplateAdmin && (
                    <p>
                        You may create and publish templates by going to
                        <br />
                        the{' '}
                        <RouterLink
                            qaTag="templateSelectModal-templateAdminUrl"
                            to={templateAdminUrl}
                        >
                            template admin panel
                        </RouterLink>
                        .
                    </p>
                )}
                <p>Once {type} templates have been added they will appear here.</p>
            </>
        );
    }

    const Templates = templates.map((template) => (
        <div className="col-xs-6" key={template.id}>
            <span className={styles.templateIconButton} onClick={() => onSelect(template)}>
                <i className={`fa fa-${template.icon} fa-3x`} />
                <div className={styles.iconText}>{template.title}</div>
            </span>
        </div>
    ));

    return (
        <>
            <div className={styles.titleText}>What Template Would You Like to Use?</div>
            {templateSupportUrl && (
                <div className={styles.templateSupportUrl}>
                    Not sure? &nbsp;
                    <a href={templateSupportUrl} rel="noopener noreferrer" target="_blank">
                        Click here for help!
                    </a>
                </div>
            )}
            <div className={`row ${styles.templateIcons}`}>{generateOptionIcons(Templates)}</div>
        </>
    );
};

TemplateSelect.propTypes = {
    isTemplateAdmin: PropTypes.bool.isRequired,
    onSelect: PropTypes.func.isRequired,
    templateAdminUrl: PropTypes.string.isRequired,
    templates: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
            title: PropTypes.string.isRequired,
        })
    ).isRequired,
    templateSupportUrl: PropTypes.string,
    type: PropTypes.string.isRequired,
};

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Field } from 'redux-form';

import { CONTAINS_PRICING } from '@og-pro/shared-config/questionnaires';

import { Checkbox, HelpIcon } from '../../..';

export class ContainsPricingCheckbox extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
    };

    render() {
        const tooltip = (
            <div>
                Select this option when the vendor&#39;s response will contain pricing information
                such as a bid table file upload.
                <br />
                <br />
                When using a sealed bid process, this will prevent the vendor&#39;s response to this
                question from being viewed until pricing information is unsealed.
            </div>
        );

        return (
            <Field
                component={Checkbox}
                disabled={this.props.disabled}
                name={CONTAINS_PRICING}
                text={
                    <span>
                        Contains pricing information
                        <HelpIcon tooltip={tooltip} />
                    </span>
                }
            />
        );
    }
}

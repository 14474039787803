import { listToDict } from '@og-pro/shared-config/helpers';
import { fieldTypes, fieldTypesValues } from '@og-pro/shared-config/signatures';

export const fieldNames = listToDict([
    'footer',
    'introduction',
    'signaturesBlocks',
    'blankBlocks',
    'signingOrderEnabled',
]);
export const blockFieldNames = listToDict([
    'styling',
    'pageBreakAfter',
    'signingOrder',
    'fields',
    'email',
]);
export const fieldsEditableFieldNames = listToDict(['label', 'value']);

export const defaultBlock = {
    styling: 'default',
    pageBreakAfter: false,
    signingOrder: 1,
    fields: [
        fieldTypesValues[fieldTypes.SIGNATURE],
        fieldTypesValues[fieldTypes.NAME],
        fieldTypesValues[fieldTypes.DATE_SIGNED],
        fieldTypesValues[fieldTypes.ROLE],
    ],
};

export const defaultSignatureHeaderBlock = {
    styling: 'header',
    pageBreakAfter: false,
    fields: [fieldTypesValues[fieldTypes.HEADER]],
};

export const stampOptions = [
    {
        label: 'Small',
        value: '1in',
    },
    {
        label: 'Medium',
        value: '3in',
    },
    {
        label: 'Large',
        value: '5in',
    },
];

export const tooltips = {
    [fieldTypes.DATE_SIGNED]:
        'This is required in order to implement e-signatures and will be used on the date of signing.',
};

import { REPLACEMENT_ID } from './constants';

export function validate(values) {
    const errors = {};

    if (!values[REPLACEMENT_ID]) {
        errors[REPLACEMENT_ID] = 'Please select a replacement tag';
    }

    return errors;
}

import { OTHER } from '../constants';

export const EXCLUDE_REASON = 'excludeReason';

export const excludeOptions = [
    {
        label: 'Duplicate',
        value: 'Duplicate',
    },
    {
        label: 'Not relevant',
        value: 'Not relevant',
    },
    {
        label: 'Errant',
        value: 'Errant',
    },
    {
        label: 'Inappropriate',
        value: 'Inappropriate',
    },
    {
        label: 'Other',
        value: OTHER,
    },
];

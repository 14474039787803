import { contactPhoneFields } from '../../../../constants/contactFields';

export const form = 'governmentProposalCreate';

export const fields = [
    ...contactPhoneFields,
    'companyName',
    'contactEmail',
    'contactFirstName',
    'contactLastName',
    'contactWebsite',
    'proposalDocuments',
];

export const helpText =
    'Please complete each section of the response as if you were the vendor. ' +
    'Use the navigation on the left to quickly switch between response sections. ' +
    'When a section is saved and complete, a check mark will appear next to it on the left.';

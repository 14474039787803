import { flatten, values } from 'lodash';

// Reformats criteria from the map structure to single array so it can be
// properly saved to the database
export function collapseCriteriaMap(criteriaMap) {
    if (!criteriaMap) {
        return undefined;
    }

    return flatten(values(criteriaMap));
}

/**
 * @param {object} parent object containing the value to be replaced
 * @param {string} value name of object property to be replaced
 * @param {string} rawValue name of raw object property
 * @returns parent object where value has been replaced by rawValue
 */
export function replaceValueWithRawValue(parent, value, rawValue) {
    parent[value] = parent[rawValue];
    delete parent[rawValue];
    return parent;
}

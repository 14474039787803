import { createSelector } from 'reselect';

import { getUsersJS } from '../../../selectors';

export const getUserSelectOptions = createSelector([getUsersJS], (users) => {
    return users.map((user) => {
        return {
            value: user.id,
            label: user.displayName,
        };
    });
});

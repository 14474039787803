import { UNSEAL_BID_FIELD } from './constants';

export function validate(values) {
    const errors = {};

    if (!values[UNSEAL_BID_FIELD]) {
        errors[UNSEAL_BID_FIELD] = 'Please select an option';
    }

    return errors;
}

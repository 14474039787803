import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, InputGroup, FormControl } from 'react-bootstrap';

// TODO: why are we binding the prop `onChange`?
/* eslint-disable react/jsx-no-bind */

export class SuggestionSearch extends PureComponent {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
    };

    render() {
        const styles = require('./SuggestionList.scss');

        return (
            <FormGroup className={styles.searchFormGroup}>
                <InputGroup>
                    <InputGroup.Addon>
                        <i className="fa fa-search" />
                    </InputGroup.Addon>
                    <FormControl
                        disabled={this.props.disabled}
                        onChange={this.props.onChange.bind(this)}
                        placeholder="Search"
                        type="text"
                    />
                </InputGroup>
            </FormGroup>
        );
    }
}

import { saveSerializer as saveBuilderSerializer } from '../../project/create/serializers';

/**
 * @typedef EvaluationRequestData
 * @property {string} rawSummary The raw version of the evaluation's summary
 */

/**
 * Serialize an evaluation project for saving to the server.
 * @param {EvaluationRequestData} data The evaluation project data to serialize
 * @param {string} timezone The timezone of the dates
 * @return {object} The serialized evaluation project
 */
export function saveSerializer(data, timezone) {
    return saveBuilderSerializer(data, null, timezone);
}

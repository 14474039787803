import classnames from 'classnames';
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { questionnaireReviewStatusTypes } from '@og-pro/shared-config/checklists';

import { ActionMenu } from './ActionMenu';
import { QUESTIONNAIRE } from './QuestionnaireResponseForm/constants';
import { QuestionnaireResponseForm } from './QuestionnaireResponseForm';
import { QuestionnaireResponseDisplay, Well } from '../..';

const { PENDING, REVIEW } = questionnaireReviewStatusTypes;

export const QuestionnaireResponseModal = ({
    checklistId,
    hideModal,
    isGovernmentView,
    questionnaire,
    questionnaire: {
        questionnaireReview,
        questionnaireReview: { isGovAction },
        title,
    },
    readOnly,
}) => {
    const myStatusKey = isGovernmentView ? 'govStatus' : 'vendorStatus';
    const myStatus = questionnaireReview[myStatusKey];

    const otherSideStatusKey = isGovernmentView ? 'vendorStatus' : 'govStatus';
    const otherSideNoteKey = isGovernmentView ? 'vendorNote' : 'govNote';
    const otherSideStatus = questionnaireReview[otherSideStatusKey];
    const otherSideNote = questionnaireReview[otherSideNoteKey];

    const isMyAction = !!isGovernmentView === isGovAction;
    const isApprovalRequired = myStatus === REVIEW;
    const isEditable = isMyAction && myStatus === PENDING;
    const isResponseInDraft = myStatus === PENDING && otherSideStatus === PENDING;

    const [showForm, setShowForm] = useState(isEditable && !readOnly);
    const [isResponseValid, setIsResponseValid] = useState(true);

    // Handle case where `isEditable` value changes while modal is open
    useEffect(() => {
        if (isEditable && !readOnly) {
            setShowForm(true);
        }
    }, [isEditable]);

    const renderQuestion = () => {
        if (showForm && !readOnly) {
            return (
                <QuestionnaireResponseForm
                    checklistId={checklistId}
                    initialValues={{ [QUESTIONNAIRE]: questionnaire }}
                    questionnaire={questionnaire}
                    showValidation={!isResponseValid}
                />
            );
        }

        // Do not show vendor response to government when it is being drafted
        const questionnaireData = omit(
            questionnaire,
            isResponseInDraft && isGovernmentView ? ['questionnaireResponse'] : []
        );

        return (
            <div className={classnames('questionnaire-response-item')}>
                <QuestionnaireResponseDisplay {...questionnaireData} />
            </div>
        );
    };

    return (
        <Modal bsSize="lg" onHide={hideModal} show>
            <Modal.Header closeButton>
                <Modal.Title className="text-center">{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!readOnly && (
                    <ActionMenu
                        checklistId={checklistId}
                        hideModal={hideModal}
                        isApprovalRequired={isApprovalRequired}
                        isGovernmentView={isGovernmentView}
                        isMyAction={isMyAction}
                        isResponseValid={isResponseValid}
                        questionnaire={questionnaire}
                        setIsResponseValid={setIsResponseValid}
                        showForm={showForm}
                        toggleEditHandler={() => setShowForm((prevValue) => !prevValue)}
                    />
                )}
                {otherSideNote && (
                    <Well>
                        <label>Note from {isGovernmentView ? 'Vendor' : 'Agency'}:</label>
                        <br />
                        {otherSideNote}
                    </Well>
                )}
                {renderQuestion()}
            </Modal.Body>
        </Modal>
    );
};

QuestionnaireResponseModal.propTypes = {
    checklistId: PropTypes.number.isRequired,
    hideModal: PropTypes.func.isRequired,
    isGovernmentView: PropTypes.bool,
    questionnaire: PropTypes.shape({
        questionnaireReview: PropTypes.shape({
            govNote: PropTypes.string,
            govStatus: PropTypes.string.isRequired,
            isGovAction: PropTypes.bool.isRequired,
            vendorNote: PropTypes.string,
            vendorStatus: PropTypes.string.isRequired,
        }).isRequired,
        title: PropTypes.string.isRequired,
    }).isRequired,
    readOnly: PropTypes.bool,
};

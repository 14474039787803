import { fromJS } from 'immutable';

import * as attachmentActions from '../../actions/govProjects/attachments';

function attachmentReducer(state, action) {
    if (state.get('id') !== action.id) return state;

    switch (action.type) {
        case attachmentActions.TOGGLE_EDIT:
            return state.set('showEditForm', !state.get('showEditForm'));
        case attachmentActions.UPDATE:
            return state.set('updating', true);
        case attachmentActions.UPDATE_SUCCESS:
            return fromJS(action.result);
        case attachmentActions.UPDATE_FAIL:
            return state.set('updating', false);
        default:
            return state;
    }
}

export default function attachmentsReducer(state, action) {
    switch (action.type) {
        case attachmentActions.UPLOAD_SUCCESS:
            return state.push(fromJS(action.result));
        case attachmentActions.DELETE_SUCCESS:
            return state.filter((attach) => attach.get('id') !== action.id);
        case attachmentActions.TOGGLE_EDIT: {
            const attachmentToCheck = state.find((attach) => attach.get('id') === action.id);
            if (attachmentToCheck.get('showEditForm')) {
                return state.map((attach) => attachmentReducer(attach, action));
            }
            // If form is closed, close all open forms before opening
            // Prevents the same appendix letter from being used
            return state.map((attach) => {
                const a = attach.get('showEditForm') ? attach.set('showEditForm', false) : attach;
                return attachmentReducer(a, action);
            });
        }
        case attachmentActions.UPDATE:
        case attachmentActions.UPDATE_SUCCESS:
        case attachmentActions.UPDATE_FAIL:
            return state.map((attach) => attachmentReducer(attach, action));
        default:
            return state;
    }
}

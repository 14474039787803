import { buildQaTag, componentNames } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

export class RouterLink extends PureComponent {
    static propTypes = {
        children: PropTypes.node,
        qaTag: PropTypes.string.isRequired, // Format: <pageName>-<simpleDescription>
    };

    render() {
        const { children, qaTag, ...props } = this.props;

        const tagWithComponentName = buildQaTag(qaTag, componentNames.LINK);

        return (
            <Link data-qa={tagWithComponentName} {...props}>
                {children}
            </Link>
        );
    }
}

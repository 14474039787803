import React from 'react';
import classnames from 'classnames';
import { isNil, pick } from 'lodash';
import PropTypes from 'prop-types';

import { RadioItem } from './RadioItem';
import { RadioItemWithTextInput } from './RadioItemWithTextInput';

export function RadioItems(props) {
    const {
        defaultChecked,
        groupLabel,
        help,
        input,
        label,
        meta,
        normalizeOption,
        options,
        showValidation,
        useOpenGovStyle,
        ...restProps
    } = props;

    // Only select the redux-form fields that react-bootstrap uses
    const radioProps = pick(input, [
        'checked',
        'name',
        'onBlur',
        'onChange',
        'onDragStart',
        'onDrop',
        'onFocus',
        'value',
    ]);
    const styles = useOpenGovStyle
        ? require('./RadioGroupOpenGovStyle.scss')
        : require('./RadioGroup.scss');

    return (
        <>
            {options.map(({ name, value, help: optionHelp, hasTextField, qaTag }) => {
                const checked = () => {
                    if (!isNil(radioProps.value)) {
                        return radioProps.value === value;
                    }

                    if (!isNil(defaultChecked)) {
                        return defaultChecked === value;
                    }

                    return false;
                };

                if (hasTextField) {
                    return (
                        <RadioItemWithTextInput
                            {...radioProps}
                            checked={checked()}
                            groupLabel={groupLabel}
                            key={value}
                            name={name}
                            normalizeOption={normalizeOption}
                            qaTag={qaTag}
                            textFieldDisabled={!checked()}
                            value={value}
                            {...restProps}
                        >
                            {name}
                        </RadioItemWithTextInput>
                    );
                }

                return (
                    <div
                        className={classnames(
                            'radio-input-item-container',
                            styles.radioItemContainer,
                            {
                                [styles.radioItemContainerWithHelp]: !!optionHelp,
                            }
                        )}
                        key={value}
                    >
                        <RadioItem
                            {...radioProps}
                            checked={checked()}
                            groupLabel={groupLabel}
                            name={name}
                            qaTag={qaTag}
                            value={value}
                            {...restProps}
                        >
                            {name}
                        </RadioItem>
                        {useOpenGovStyle && optionHelp && (
                            <div className={styles.radioItemHelp}>{optionHelp}</div>
                        )}
                    </div>
                );
            })}
        </>
    );
}

RadioItems.propTypes = {
    defaultChecked: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    groupLabel: PropTypes.string.isRequired,
    help: PropTypes.string,
    input: PropTypes.object.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    meta: PropTypes.object.isRequired,
    normalizeOption: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            hasTextField: PropTypes.bool,
            help: PropTypes.string,
            name: PropTypes.string.isRequired,
            qaTag: PropTypes.string.isRequired, // Format: <pageName>-<simpleDescription>
            value: PropTypes.any.isRequired,
        })
    ).isRequired,
    showValidation: PropTypes.bool,
    useOpenGovStyle: PropTypes.bool,
};

import createSocketIoMiddleware from 'redux-socket.io';

import {
    checklistSocketActionType,
    projectSocketActionType,
    requisitionSocketActionType,
    reverseAuctionSocketActionType,
    userSocketActionType,
    vendorProposalSocketActionType,
} from '../actions/constants';

export default function socketsMiddleware() {
    // Best to not load any sockets on the server
    // https://github.com/itaylor/redux-socket.io/issues/16
    if (process.env.SERVER) return [];

    // Do not load sockets on supportboard (there is no sync server to connect to)
    if (process.env.APP_NAME === 'support') return [];

    const sockets = require('../lib/sockets');

    const {
        checklistSocket,
        projectSocket,
        requisitionSocket,
        reverseAuctionSocket,
        userSocket,
        vendorProposalSocket,
    } = sockets;

    /**
     * Add more socket middlewares as the need arrives for more namespaces
     *
     * WARNING: DO NOT USE `.off()` WITHOUT A LISTENER NAME TO REMOVE SOCKET LISTENERS.
     * Listeners have to be removed intentionally to prevent unintended effects.
     * Ex. `checklistSocket.off('connect', myConnectHandler)`
     * Using the `.off()` catch-all also removes the `socket.on('action')` listener attached by the middleware here.
     * This prevents subsequent connections by the client from updating the Redux store.
     */
    return [
        createSocketIoMiddleware(checklistSocket, checklistSocketActionType),
        createSocketIoMiddleware(projectSocket, projectSocketActionType),
        createSocketIoMiddleware(requisitionSocket, requisitionSocketActionType),
        createSocketIoMiddleware(reverseAuctionSocket, reverseAuctionSocketActionType),
        createSocketIoMiddleware(userSocket, userSocketActionType),
        createSocketIoMiddleware(vendorProposalSocket, vendorProposalSocketActionType),
    ];
}

import { upperCase } from 'lodash';
import moment from 'moment-timezone/builds/moment-timezone-with-data-1970-2030';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Alert, Panel } from 'react-bootstrap';
import { Link, useParams, useNavigate, useLocation, useSearchParams } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';
import {
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterShareButton,
    XIcon,
    EmailShareButton,
    EmailIcon,
} from 'react-share';
import { useSelector } from 'react-redux';

import { projectStatusesDict } from '@og-pro/shared-config/projects';

import {
    CategoryCodes,
    CloseDateButton,
    CountdownTimerText,
    ProjectModeToggle,
    ProjectRetentionPolicyButton,
    StatusLabel,
} from '..';
import { statusClass } from '../helpers/statusHelper';
import { timezoneAbbreviations } from '../../constants';
import { getMetaTitle } from '../../helpers';
import {
    getBidPosterAuthorized,
    getDocumentReviewPath,
    getDocumentWritingPath,
} from '../../containers/GovApp/selectors';
import { getProjectContracts } from '../../containers/GovApp/ProjectDashboard/selectors';
import { isRetentionAdminUser } from '../../containers/selectors';
import { ProjectHoldStatus } from '../ProjectHoldStatus';

const { OPEN, CLOSED } = projectStatusesDict;

export const ProjectProfile = ({
    buttons,
    dashboardPath,
    imageUrl,
    isEditor,
    isPublicView,
    menuComponent,
    portalUrl,
    project: {
        auctionEndDate,
        auctionStartDate,
        bidPricingUnsealedAt,
        bidPricingUnsealedUser,
        bidUnsealedAt,
        bidUnsealedUser,
        categories,
        closeOutReason,
        comingSoon,
        departmentName,
        financialId,
        id,
        isIntake,
        isPaused,
        isPausedReason,
        isPrivate,
        lastAddendum,
        postedAt,
        postScheduledAt,
        proposalDeadline,
        releaseProjectDate,
        recordCloseDate,
        retention_code_id: retentionCodeId,
        showBids,
        showBidsWithPricing,
        summary,
        template,
        wasPosted,
    },
    publicUrl,
    showBidDetails,
    showShareButtons,
    showTimer,
    status,
    subscribed,
    subscribeButton,
    timezone,
    title,
}) => {
    const navigate = useNavigate();
    const params = useParams();
    const baseLocation = useLocation();
    const [searchParams] = useSearchParams();
    const location = { ...baseLocation, query: Object.fromEntries(searchParams) };

    const writingPath = useSelector((state) => {
        return getDocumentWritingPath(state, { location, params, isIntake });
    });

    const reviewPath = useSelector((state) => getDocumentReviewPath(state, { location, params }));
    const contracts = useSelector((state) => getProjectContracts(state));
    const hasBidPostAuthorization = useSelector((state) => getBidPosterAuthorized(state));
    const isRetentionAdmin = useSelector((state) => isRetentionAdminUser(state));
    const styles = require('./ProjectProfile.scss');

    const hasContracts = contracts && contracts.length > 0;
    const projectClosed = status === CLOSED;
    const showCloseDateButton = projectClosed && hasBidPostAuthorization;
    const showRetentionPolicyButton = projectClosed && isRetentionAdmin;

    const displayDate = (date, excludeTime) => {
        const format = 'dddd, MMMM D, YYYY';
        const dateDisplay = moment.tz(date, timezone).format(format);

        if (excludeTime) {
            return dateDisplay;
        }

        return `${dateDisplay} ${moment.tz(date, timezone).format('h:mma')}`;
    };

    const handleLogoClick = () => {
        if (portalUrl) {
            navigate(portalUrl);
        } else if (dashboardPath) {
            navigate(dashboardPath);
        }
    };

    const renderTags = () => {
        return (
            <div className={styles.tags}>
                {template.procurementClassification && (
                    <div className={styles.tag}>
                        <i className={`fa fa-${template.icon}`} />{' '}
                        {template.procurementClassification}
                    </div>
                )}
                <div className={styles.tag}>
                    <i className="fa fa-university" /> {departmentName}
                </div>
                <div className={styles.tag}>
                    <CategoryCodes codes={categories} showIcon />
                </div>
            </div>
        );
    };

    const renderRetentionInfo = () => {
        if (isPublicView) {
            return null;
        }

        return (
            <>
                {showCloseDateButton && (
                    <span className={styles.retentionInfo}>
                        <strong>Close Date:</strong>&nbsp;
                        <CloseDateButton
                            canEdit={!hasContracts}
                            closeDate={recordCloseDate}
                            projectId={id}
                        />
                        &nbsp;
                    </span>
                )}
                {showRetentionPolicyButton && (
                    <span className={styles.retentionInfo}>
                        <ProjectRetentionPolicyButton
                            canEdit={!hasContracts}
                            retentionCodeId={retentionCodeId}
                        />
                    </span>
                )}
            </>
        );
    };

    const renderPostingDetails = () => {
        if (!wasPosted && !postScheduledAt) return null;

        const [icon, text, date] = wasPosted
            ? ['flag', 'Posted', postedAt]
            : ['clock-o', 'Post Scheduled', postScheduledAt];

        return (
            <div className={styles.postDetails}>
                {isPrivate && (
                    <span className={styles.privateBid}>
                        <i className="fa fa-lock" /> Private Bid
                    </span>
                )}
                <i className={`fa fa-${icon}`} /> {text}
                <span className={styles.calendarDate}>
                    <i className="fa fa-calendar" /> {displayDate(date)}
                </span>
            </div>
        );
    };

    const renderSealedBidDetails = () => {
        if (!showBidDetails) {
            return null;
        }

        const icon = showBids ? 'envelope-open-o' : 'envelope-o';
        const text = showBids ? 'Bid Unsealed' : 'Bid Sealed';

        const pricingIcon = showBidsWithPricing ? 'envelope-open-o' : 'envelope-o';
        const pricingText = showBidsWithPricing ? 'Pricing Unsealed' : 'Pricing Sealed';

        return (
            <div className={styles.sealedBidDetails}>
                <i className={`fa fa-${icon}`} /> {text}
                {bidUnsealedAt && (
                    <span>
                        &nbsp;{displayDate(bidUnsealedAt)}
                        {bidUnsealedUser && ` by ${bidUnsealedUser.displayName}`}
                    </span>
                )}
                <div className={styles.sealedPricingDetails}>
                    <i className={`fa fa-${pricingIcon}`} /> {pricingText}
                    {bidPricingUnsealedAt && (
                        <span>
                            &nbsp;{displayDate(bidPricingUnsealedAt)}
                            {bidPricingUnsealedUser && ` by ${bidPricingUnsealedUser.displayName}`}
                        </span>
                    )}
                </div>
            </div>
        );
    };

    const renderProjectDetails = () => {
        const dates = [];

        if (releaseProjectDate) {
            dates.push(
                <div className={styles.timelineItem} key="releaseProjectDate">
                    <strong>Release Date: </strong>
                    {displayDate(releaseProjectDate, true)}
                </div>
            );
        }

        if (proposalDeadline) {
            dates.push(
                <div className={styles.timelineItem} key="proposalDeadline">
                    {dates.length > 0 && (
                        <span className={styles.timelineDot}>&nbsp;&middot;&nbsp;</span>
                    )}
                    <strong>{auctionEndDate ? 'Initial Bid Due:' : 'Due Date:'} </strong>
                    {displayDate(proposalDeadline)}&nbsp;
                </div>
            );
        }

        if (auctionEndDate) {
            dates.push(
                <div className={styles.timelineDetails}>
                    <div className={styles.timelineItem} key="auctionEndDate">
                        <strong>Reverse Auction: </strong>
                        {`${displayDate(auctionStartDate, false)} - ${displayDate(
                            auctionEndDate,
                            false
                        )}`}
                    </div>
                </div>
            );
        }

        return <div className={styles.timelineDetails}>{dates}</div>;
    };

    const renderShareButtons = () => {
        const displayTitle = title || 'New Opportunity on OpenGov Procurement';

        const SocialButtons = [
            {
                SocialButton: LinkedinShareButton,
                SocialIcon: LinkedinIcon,
                ariaLabel: 'linkedin',
            },
            {
                SocialButton: TwitterShareButton,
                SocialIcon: XIcon,
                title: displayTitle,
                hashtags: ['OpenGov'],
                ariaLabel: 'twitter',
            },
            {
                SocialButton: FacebookShareButton,
                SocialIcon: FacebookIcon,
                hashtag: '#OpenGov',
                ariaLabel: 'facebook',
            },
            {
                SocialButton: EmailShareButton,
                SocialIcon: EmailIcon,
                subject: 'Check Out this Opportunity on OpenGov Procurement',
                body: `${displayTitle}:\n`,
                openWindow: true,
                ariaLabel: 'email',
            },
        ].map(({ SocialButton, SocialIcon, ariaLabel, ...opts }, idx) => (
            <div className={styles.socialButton} key={idx}>
                <SocialButton aria-label={ariaLabel} url={publicUrl} {...opts}>
                    <SocialIcon round size={26} />
                </SocialButton>
            </div>
        ));

        return <div className={`${styles.socialButtonBar} no-print`}>{SocialButtons}</div>;
    };

    const renderButtons = () => {
        if (!buttons || buttons.length === 0) return null;

        return (
            <div className={styles.buttonBar}>
                {buttons.map((button, idx) => (
                    <div className={styles.actionButton} key={idx}>
                        {button}
                    </div>
                ))}
            </div>
        );
    };

    const renderSubscribeButton = () => {
        if (!subscribeButton) return null;

        return <div className={styles.subscribeButton}>{subscribeButton}</div>;
    };

    const renderProjectId = () => {
        if (!financialId) {
            return null;
        }

        return (
            <div className={styles.projectId}>
                <strong>Project ID:</strong>&nbsp;&nbsp;{financialId}
            </div>
        );
    };

    const renderAlert = (bsStyle, icon, text) => {
        return (
            <Alert bsStyle={bsStyle} className={styles.alert}>
                <i className={`fa fa-${icon}`} /> {text}
            </Alert>
        );
    };

    const renderPrivateBidAlert = () => {
        return (
            <>
                This is a private bid and does not appear in the public portal
                {isPublicView && !subscribed && (
                    <span>
                        . Please click the <strong>&quot;Follow&quot;</strong> button below to
                        receive updates about this project.
                    </span>
                )}
            </>
        );
    };

    const renderTitle = () => {
        const displayTitle = title || 'Untitled';

        if (!dashboardPath) return displayTitle;

        return (
            <Link className={styles.displayTitle} to={dashboardPath}>
                {displayTitle}
            </Link>
        );
    };

    const renderLastAddendum = () => {
        if (!lastAddendum) {
            return null;
        }

        return (
            <div className={styles.lastAddendum}>
                <em>
                    Last updated by&nbsp;
                    <Link
                        to={`${portalUrl}/projects/${id}/addenda?addendum-number=${lastAddendum.number}`}
                    >
                        {lastAddendum.titleDisplay}
                    </Link>{' '}
                    on&nbsp;
                    {moment(lastAddendum.releasedAt).format('lll')}
                </em>
                {lastAddendum.diff && (
                    <Link
                        className={styles.changedLink}
                        to={`${portalUrl}/projects/${id}/addenda?addendum-number=${lastAddendum.number}&addendum-display=diff`}
                    >
                        See what changed
                    </Link>
                )}
            </div>
        );
    };

    const renderMetaTags = () => {
        if (!title && !summary) {
            return;
        }
        return (
            <Helmet>
                <title>{getMetaTitle(title)}</title>
                <meta content={title} property="og:title" />
                <meta
                    content={(summary || '')
                        .replace(/(\r\n|\n|\r)/gm, '')
                        .replace(/(<([^>]+)>)/gi, '')}
                    property="og:description"
                />
                <meta content={imageUrl} property="og:image" />
            </Helmet>
        );
    };

    const renderProjectMode = () => {
        if (!isEditor) {
            return null;
        }

        const {
            query: { section },
        } = location;

        return (
            <ProjectModeToggle
                checked={false}
                className="action-toolbar-mode-toggle"
                reviewPath={reviewPath}
                section={section}
                writingPath={writingPath}
            />
        );
    };

    const isActiveStatus = comingSoon || status === OPEN;

    return (
        <>
            {renderMetaTags()}
            <Panel className="print-panel" id="skip">
                <Panel.Body>
                    <ProjectHoldStatus project={{ isPaused, isPausedReason }} />
                    {!!closeOutReason && renderAlert('warning', 'info-circle', closeOutReason)}
                    {isPrivate && renderAlert('info', 'lock', renderPrivateBidAlert())}
                    <div>
                        <div className={styles.logoContainer}>
                            <img
                                alt="Profile"
                                className={`img-responsive img-rounded ${styles.profilePicture}`}
                                onClick={handleLogoClick}
                                src={imageUrl}
                            />
                            <StatusLabel
                                bsStyle={statusClass(status)}
                                className={styles.statusLabel}
                            >
                                {upperCase(status)}
                            </StatusLabel>
                        </div>
                        <div className={classnames(isPublicView && styles.publicProfileDetails)}>
                            <div className={`hidden-xs ${styles.menuRight}`}>
                                {renderSubscribeButton()}
                                {menuComponent && (
                                    <div className={styles.menuComponent}>{menuComponent}</div>
                                )}
                            </div>
                            <h1 className={styles.title}>{renderTitle()}</h1>
                            {renderLastAddendum()}
                            {renderTags()}
                            {status === CLOSED && renderRetentionInfo()}
                            {renderProjectId()}
                            {renderProjectDetails()}
                            <div className="hidden-xs">
                                {showShareButtons && renderShareButtons()}
                            </div>
                            {renderPostingDetails()}
                            {renderSealedBidDetails()}
                            <div className={styles.timezone}>
                                <em>All dates & times in {timezoneAbbreviations[timezone]}</em>
                            </div>
                            <div className="col-xs-12 visible-xs-block">
                                {showShareButtons && renderShareButtons()}
                            </div>
                            {renderButtons()}
                            {showTimer && isActiveStatus && (
                                <div className={styles.timer}>
                                    <CountdownTimerText
                                        endTime={proposalDeadline}
                                        timeRemainingText="Time Remaining:"
                                    />
                                </div>
                            )}
                        </div>
                        <div className={`col-xs-12 visible-xs-block ${styles.menuBar}`}>
                            {renderSubscribeButton()}
                            {menuComponent}
                        </div>
                        <div className="col-xs-12 text-right">{renderProjectMode()}</div>
                    </div>
                </Panel.Body>
            </Panel>
        </>
    );
};

ProjectProfile.propTypes = {
    buttons: PropTypes.array,
    dashboardPath: PropTypes.string,
    imageUrl: PropTypes.string.isRequired,
    isEditor: PropTypes.bool,
    isPublicView: PropTypes.bool,
    location: PropTypes.object,
    menuComponent: PropTypes.node,
    params: PropTypes.object,
    portalUrl: PropTypes.string,
    project: PropTypes.shape({
        auctionStartDate: PropTypes.string,
        auctionEndDate: PropTypes.string,
        bidPricingUnsealedAt: PropTypes.string,
        bidPricingUnsealedUser: PropTypes.shape({
            displayName: PropTypes.string.isRequired,
        }),
        bidUnsealedAt: PropTypes.string,
        bidUnsealedUser: PropTypes.shape({
            displayName: PropTypes.string.isRequired,
        }),
        categories: PropTypes.array.isRequired,
        closeOutReason: PropTypes.string,
        comingSoon: PropTypes.bool.isRequired,
        departmentName: PropTypes.string,
        financialId: PropTypes.string,
        id: PropTypes.number.isRequired,
        isIntake: PropTypes.bool,
        isPaused: PropTypes.bool.isRequired,
        isPausedReason: PropTypes.string,
        isPrivate: PropTypes.bool.isRequired,
        lastAddendum: PropTypes.shape({
            diff: PropTypes.object,
            number: PropTypes.number.isRequired,
            releasedAt: PropTypes.string.isRequired,
            titleDisplay: PropTypes.string.isRequired,
        }),
        postedAt: PropTypes.string,
        postScheduledAt: PropTypes.string,
        proposalDeadline: PropTypes.string,
        recordCloseDate: PropTypes.string,
        releaseProjectDate: PropTypes.string,
        retention_code_id: PropTypes.number,
        showBids: PropTypes.bool,
        showBidsWithPricing: PropTypes.bool,
        template: PropTypes.shape({
            icon: PropTypes.string.isRequired,
            procurementClassification: PropTypes.string,
        }).isRequired,
        wasPosted: PropTypes.bool,
        summary: PropTypes.string,
    }).isRequired,
    publicUrl: PropTypes.string,
    showBidDetails: PropTypes.bool,
    showShareButtons: PropTypes.bool,
    showTimer: PropTypes.bool,
    status: PropTypes.string.isRequired,
    subscribeButton: PropTypes.node,
    subscribed: PropTypes.bool,
    timezone: PropTypes.string.isRequired,
    title: PropTypes.string,
};

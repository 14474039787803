import { get } from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FieldArray, formValueSelector } from 'redux-form';

import { fieldNames } from '../../constants';
import { CriteriaForm } from '../../../../../../../components';
import { OGThemeContext } from '../../../../../ogThemeProvider';

const { CRITERIA } = fieldNames;

const mapStateToProps = (state, props) => {
    const {
        projectSection: { id, projectSubsections },
    } = props;

    const projectSubsection = projectSubsections[0];
    const formKey = `${CRITERIA}.${id}_${projectSubsection.id}`;
    const formSelector = formValueSelector(props.form);

    return {
        formKey,
        hasTextArea: !!get(formSelector(state, formKey) || [], 0),
        projectSubsection,
    };
};

// @connect
class ConnectedTemplateTextAreaForm extends PureComponent {
    static propTypes = {
        array: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }).isRequired,
        change: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        form: PropTypes.string.isRequired,
        formKey: PropTypes.string.isRequired,
        hasTextArea: PropTypes.bool.isRequired,
        projectSection: PropTypes.shape({
            id: PropTypes.number.isRequired,
            isWritingForm: PropTypes.bool,
            projectSubsections: PropTypes.array.isRequired,
            section_type: PropTypes.string.isRequired,
        }).isRequired,
        projectSubsection: PropTypes.shape({
            id: PropTypes.number.isRequired,
            subsection_type: PropTypes.string.isRequired,
        }).isRequired,
        showFormValidation: PropTypes.bool,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
    };

    static contextType = OGThemeContext;

    componentDidMount() {
        const { hasTextArea } = this.props;

        if (!hasTextArea) {
            this.addTextArea();
        }
    }

    componentDidUpdate() {
        const { hasTextArea, projectSection } = this.props;
        const { isOGThemeEnabledForComponents } = this.context;

        if (!hasTextArea && isOGThemeEnabledForComponents && !projectSection.isWritingForm) {
            this.addTextArea();
        }
    }

    addTextArea = () => {
        const { array, formKey, projectSection, projectSubsection } = this.props;

        return array.push(formKey, {
            orderById: 1,
            project_section_id: projectSection.id,
            project_subsection_id: projectSubsection.id,
            section_type: projectSection.section_type,
            subsection_type: projectSubsection.subsection_type,
        });
    };

    render() {
        const {
            change,
            disabled,
            form,
            formKey,
            projectSection,
            showFormValidation,
            tagOptions,
            templateVariableOptions,
        } = this.props;
        const { isOGThemeEnabledForComponents } = this.context;

        return (
            <div className={classnames({ row: !isOGThemeEnabledForComponents })}>
                <div
                    className={classnames({
                        'col-md-offset-2 col-md-8': !isOGThemeEnabledForComponents,
                    })}
                >
                    <FieldArray
                        change={change}
                        component={CriteriaForm}
                        disabled={disabled}
                        form={form}
                        isCriteriaBuilder
                        isOGThemeEnabledForComponents={isOGThemeEnabledForComponents}
                        isTextArea
                        name={formKey}
                        projectSection={projectSection}
                        showArrayError
                        showValidation={showFormValidation}
                        tagOptions={tagOptions}
                        templateVariableOptions={templateVariableOptions}
                    />
                </div>
            </div>
        );
    }
}

export const TemplateTextAreaForm = connect(mapStateToProps)(ConnectedTemplateTextAreaForm);

import { OverlayTrigger, Popover } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '../../../../../../../components';

export const Legend = ({ className, showValidation }) => {
    const styles = require('./index.scss');

    const LegendInfo = (
        <Popover
            className={className}
            id="price-table-legend"
            title={<div className="text-center">Price Table Legend</div>}
        >
            <div className={styles.legendContainer}>
                <div
                    className={styles.legendColor}
                    style={{ backgroundColor: showValidation ? '#f2dede' : '#FDFDD8' }}
                />
                <div className={styles.legendText}>Required Cell</div>
            </div>
            <div className={styles.legendContainer}>
                <div className={styles.legendColor} />
                <div className={styles.legendText}>Optional Cell</div>
            </div>
            <div className={styles.legendContainer}>
                <div
                    className={styles.legendColor}
                    style={{
                        backgroundColor: '#edefed',
                        opacity: '0.65',
                        background:
                            'repeating-linear-gradient(-45deg, #edefed, #edefed 5px, #dce0e0 5px, #dce0e0 10px)',
                    }}
                />
                <div className={styles.legendText}>Reserved for Vendor to Complete</div>
            </div>
            <br />
            <small>
                <em>
                    Use the &quot;show table options&quot; button to the right to adjust the columns
                    in the table. You can also control whether you or the vendor enters values for a
                    specific column.
                </em>
            </small>
        </Popover>
    );

    return (
        <OverlayTrigger overlay={LegendInfo} placement="bottom">
            <Button bsStyle="link" qaTag="legend" zeroPadding>
                <i className="fa fa-info-circle" /> Legend
            </Button>
        </OverlayTrigger>
    );
};

Legend.propTypes = {
    className: PropTypes.string,
    showValidation: PropTypes.bool,
};

import { createSelector } from 'reselect';

import { getCurrentScoredProposals } from '../../selectors';
import { roundNumber, scoreText, scoreColor } from '../../../../helpers';

const getProposalIdParams = (state, props) => Number.parseInt(props.params.proposalId, 10);
const shouldShowPercentageTotals = (state) => state.proposalEvaluations.get('showPercentageTotals');

export const getVendorProposalJS = createSelector(
    [getCurrentScoredProposals, getProposalIdParams],
    (scoredProposals, proposalId) => {
        return scoredProposals.find((proposal) => proposal.id === proposalId);
    }
);

const getProposalTotalScoreData = createSelector(
    [getVendorProposalJS, shouldShowPercentageTotals],
    (proposal, showPercentageTotals) => {
        if (proposal) {
            const score = showPercentageTotals ? proposal.totalScore : proposal.totalScoreByPoints;
            return {
                criteriaScore: roundNumber(score, 2),
                scoringCriteria: { maxScore: 100 },
                isTotal: true,
                showPercentageTotals,
                totalScorePercentage: proposal.totalScore,
            };
        }
    }
);

export const getProposalTotalScoreText = createSelector(
    [getProposalTotalScoreData],
    (proposalTotalScoreData) => {
        if (proposalTotalScoreData) {
            return `${scoreText(proposalTotalScoreData)}`;
        }
    }
);

export const getProposalTotalScoreColor = createSelector(
    [getProposalTotalScoreData],
    (proposalTotalScoreData) => {
        if (proposalTotalScoreData) {
            const computedScoreColor = scoreColor(proposalTotalScoreData);
            if (computedScoreColor) {
                // Remove the class suffix (if any) for getting the text-* class name
                return computedScoreColor.replace(/-.+$/i, '');
            }
        }
    }
);

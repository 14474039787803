import { createSelector } from 'reselect';

import { questionStatusTypes } from '@og-pro/shared-config/questions';

import { getQuestionsJS } from '../selectors';

const { EXCLUDED } = questionStatusTypes;

export const getActiveQuestionsJS = createSelector([getQuestionsJS], (questions) => {
    return questions.filter((question) => question.status !== EXCLUDED);
});

export const getExcludedQuestionsJS = createSelector([getQuestionsJS], (questions) => {
    return questions.filter((question) => question.status === EXCLUDED);
});

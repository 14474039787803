import { fromJS } from 'immutable';

import * as questionnaireActions from '../actions/questionnaire';

const initialState = fromJS({
    createModalPage: null,
    formData: null,
    isEditFormModal: false,
    modalOptions: null,
    showCreateModal: false,
});

export default function questionnaireReducer(state = initialState, action = {}) {
    switch (action.type) {
        case questionnaireActions.SHOW_QUESTIONNAIRE_MODAL:
            return state.merge(
                fromJS({
                    formData: fromJS(action.formData),
                    modalOptions: fromJS(action.modalOptions),
                    showCreateModal: true,
                })
            );
        case questionnaireActions.SHOW_QUESTIONNAIRE_EDIT_MODAL:
            return state.merge(
                fromJS({
                    createModalPage: 2,
                    formData: fromJS(action.formData),
                    isEditFormModal: true,
                    modalOptions: fromJS(action.modalOptions),
                    showCreateModal: true,
                })
            );
        case questionnaireActions.SET_QUESTIONNAIRE_MODAL_DATA:
            return state.merge(
                fromJS({
                    createModalPage: action.page,
                    formData: fromJS(action.formData),
                })
            );
        case questionnaireActions.HIDE_QUESTIONNAIRE_MODAL:
            return initialState;
        default:
            return state;
    }
}

import { useEffect, useRef } from 'react';

/**
 * `usePreviousWithInitialValue` is a custom React Hook that stores and returns the previous value of a prop or state value in a functional component.
 * Unlike `usePrevious`, this hook initializes the ref with the initial value passed to it (`usePrevious` returns `undefined` on the initial render).
 *
 * @param {any} value - The value for which the previous value is to be stored.
 * @returns {any} - The previous value of the passed in `value`.
 *
 * @example
 * const Component = () => {
 *   const [count, setCount] = useState(0);
 *   const prevCount = usePreviousWithInitialValue(count);
 *   // On initial render, prevCount will be 0 (same as initial state value)
 *   // On subsequent renders, prevCount will hold the previous value of count
 * }
 *
 * @note
 * The difference between `usePrevious` and `usePreviousWithInitialValue` is that `usePrevious` initializes the ref with `undefined`,
 * which means that on the initial render, the previous value will be `undefined`. On the other hand, `usePreviousWithInitialValue` initializes
 * the ref with the initial `value` passed to it, so on the initial render, the previous value will be the same as the initial `value`.
 */
export const usePreviousWithInitialValue = (value) => {
    const ref = useRef(value);
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    DATA,
    IS_REQUIRED,
    MULTIPLE_CHOICE,
    OPTIONS,
    upfrontQuestionTypesDisplay,
} from '@og-pro/shared-config/questionnaires';

import { TemplateQuestionCreateForm } from '../TemplateQuestionCreateForm';
import { TYPE, USER_ID } from '../TemplateQuestionCreateForm/constants';
import { getUserJS } from '../../../../selectors';
import { createTemplateQuestion } from '../../../../../actions/templateQuestions';
import { Button, QuestionnaireTypeSelect } from '../../../../../components';

export const ModalBody = ({ hideModal }) => {
    const [page, setPage] = useState(1);
    const [selectedQuestionnaire, setSelectedQuestionnaire] = useState(null);
    const [creating, setCreating] = useState(false);
    const [createError, setCreateError] = useState(null);

    const dispatch = useDispatch();
    const user = useSelector(getUserJS);

    const selectQuestionType = (questionnaire) => {
        setSelectedQuestionnaire(questionnaire);
        setPage(2);
    };

    const onSubmit = (formValues) => {
        setCreating(true);
        setCreateError(null);
        return dispatch(createTemplateQuestion(formValues))
            .then(() => {
                hideModal();
            })
            .catch((error) => {
                setCreating(false);
                setCreateError(error.message);
            });
    };

    if (page === 1) {
        return (
            <>
                <QuestionnaireTypeSelect
                    formatTypes={[]}
                    onFormatSelect={() => {}}
                    onQuestionSelect={selectQuestionType}
                    questionTypes={upfrontQuestionTypesDisplay}
                />
                <div className="text-center text-muted">
                    <i className="fa fa-info-circle" /> Question type cannot be edited after
                    question is created
                </div>
            </>
        );
    }

    return (
        <>
            <Button bsStyle="link" onClick={() => setPage(1)}>
                <i className="fa fa-arrow-left" /> Back
            </Button>
            <TemplateQuestionCreateForm
                disabled={creating}
                initialValues={{
                    [IS_REQUIRED]: true,
                    [TYPE]: selectedQuestionnaire.type,
                    [USER_ID]: user.id,
                    ...(selectedQuestionnaire.type === MULTIPLE_CHOICE
                        ? {
                              [DATA]: {
                                  [OPTIONS]: [undefined, undefined],
                              },
                          }
                        : {}),
                }}
                onSubmit={onSubmit}
                selectedQuestionnaire={selectedQuestionnaire}
                updateError={createError}
            />
        </>
    );
};

ModalBody.propTypes = {
    hideModal: PropTypes.func.isRequired,
};

import { createSelector } from 'reselect';

import { getReverseAuctionItemsJS } from '../../../App/selectors';
import { getBidRankingMap } from '../../../../helpers/bidRankingHelper';

const getPriceItemQuery = (state, props) => props.priceItemQuery;

export const getLineItemDetailNavItems = createSelector(
    [getReverseAuctionItemsJS],
    (reverseAuctionItems) =>
        reverseAuctionItems.map(
            ({
                id,
                priceItems: {
                    0: { description },
                },
            }) => {
                return {
                    navTitle: description,
                    itemId: id,
                };
            }
        )
);

export const getCurrentLineItem = createSelector(
    [getPriceItemQuery, getReverseAuctionItemsJS],
    (priceItemQuery, reverseAuctionItems) => {
        if (!priceItemQuery) return reverseAuctionItems[0];
        return reverseAuctionItems.find((item) => item.id === Number.parseInt(priceItemQuery, 10));
    }
);

export const getCurrentLineItemVendorBids = createSelector(
    [getCurrentLineItem],
    (currentLineItem) => {
        const activeVendorPriceItems = [];
        const noBidVendorPriceItems = [];
        const lineItemTotalNumBids = currentLineItem.priceItems[0].reverseAuctionBids.length;

        currentLineItem.priceItems[0].vendorPriceItems.forEach((vendorPriceItem) =>
            vendorPriceItem.noBid
                ? noBidVendorPriceItems.push(vendorPriceItem)
                : activeVendorPriceItems.push(vendorPriceItem)
        );

        const bidRankingMap = getBidRankingMap(
            activeVendorPriceItems.map((item) => ({
                id: item.id,
                value: item.reverseAuctionBids[0].unitPrice,
            }))
        );

        return {
            activeVendorPriceItems,
            bidRankingMap,
            noBidVendorPriceItems,
            lineItemTotalNumBids,
        };
    }
);

export const getCurrentItemBidHistory = createSelector([getCurrentLineItem], (currentLineItem) => {
    const bidHistoryData = [];

    currentLineItem.priceItems[0].vendorPriceItems.forEach((vendorPriceItem) => {
        vendorPriceItem.reverseAuctionBids.forEach((bid) => {
            bidHistoryData.push({
                createdAt: bid.created_at,
                id: bid.id,
                isCreatedByProxy: bid.isCreatedByProxy,
                name: vendorPriceItem.proposal.companyName,
                totalBid: bid.unitPrice * currentLineItem.priceItems[0].quantity,
                unitPrice: bid.unitPrice,
            });
        });
    });

    return bidHistoryData.sort((a, b) => {
        if (a.createdAt < b.createdAt) return 1;
        if (a.createdAt > b.createdAt) return -1;
        return 0;
    });
});

export const getLineItemBidTimelineData = createSelector(
    [getCurrentLineItem],
    (currentLineItem) => {
        return currentLineItem.priceItems[0].vendorPriceItems.map((vendorPriceItem) => {
            return {
                name: vendorPriceItem.proposal.companyName,
                data: vendorPriceItem.reverseAuctionBids
                    .map((bid) => {
                        return [new Date(bid.created_at).getTime(), bid.unitPrice];
                    })
                    .sort((a, b) => a[0] - b[0]),
            };
        });
    }
);

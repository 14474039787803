import { pick } from 'lodash';

import { projectStatusesDict } from '@og-pro/shared-config/projects';

import { projectSocketActionType } from './constants';

export const JOIN = `${projectSocketActionType}/JOIN`;
export const LEAVE = `${projectSocketActionType}/LEAVE`;

// This action is only processed by the socket server
// (we do not want to force reload the sender's project)
export const EMIT_FORCE_RELOAD = `${projectSocketActionType}/EMIT_FORCE_RELOAD`;

export function joinProject(projectId) {
    return (dispatch, getState) => {
        const user = getState().auth.get('user').toJS();
        const selectedProject = getState().govProjects.get('selectedProject')?.toJS();
        const isReverseAuction = selectedProject?.status === projectStatusesDict.REVERSE_AUCTION;
        const isSourcingEditor = !!selectedProject?.moduleData.sourcing.permissions.isEditor;

        const userData = pick(user, ['avatarUrl', 'displayName', 'email', 'initials']);
        return dispatch({
            type: JOIN,
            projectId,
            project: { isReverseAuction },
            user: { ...userData, isSourcingEditor },
        });
    };
}

export function leaveProject(projectId) {
    return { type: LEAVE, projectId };
}

// Will cause all connected project's to reload themselves
export function forceReloadConnectedClientProjects(projectId) {
    return { type: EMIT_FORCE_RELOAD, projectId };
}

export const CONNECTED_CLIENT_COUNT = 'projectSocket/server/CONNECTED_CLIENT_COUNT';
export const FORCE_RELOAD_PROJECT = 'projectSocket/server/FORCE_RELOAD_PROJECT';

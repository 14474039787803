import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FieldArray } from 'redux-form';

import { purchaseDetailsModes } from '@og-pro/shared-config/requisitions';

import { ReviewPriceItem } from './PriceItem';
import { fieldNames } from '../../constants';
import { VendorAssignmentModal } from '../../VendorAssignmentModal';
import { PurchaseDetailsSummary } from '../../FormComponents/PurchaseDetails/Summary';
import { Header } from '../../FormComponents/PurchaseDetails/Header';
import { getGovernmentSalesTax } from '../../../../../../selectors/govApp';
import { requisitionsCreateFormValueSelector } from '../../selectors';
import { PurchaseDetailsTotal } from './PurchaseDetailsTotal';

const { PRICE_ITEMS, PRICE_TABLE, PURCHASE_DETAILS_MODE } = fieldNames;
const { AMOUNT_ONLY } = purchaseDetailsModes;

const PriceItemsReview = ({ fields, showVendorSelection, tax }) => {
    const showDiscountInHeader = fields.getAll().some((field) => field.discount);

    const [expanded, setExpanded] = useState(-1);

    const handleExpand = (index) => (event, isExpanded) => {
        setExpanded(isExpanded ? index : false);
    };

    return (
        <>
            <Header showDiscountInHeader={showDiscountInHeader} tax={tax} />
            {fields.map((fieldName, index) => (
                <ReviewPriceItem
                    expanded={expanded === index}
                    fields={fields}
                    handleExpand={handleExpand}
                    index={index}
                    key={index}
                    showDiscountInHeader={showDiscountInHeader}
                    showVendorSelection={showVendorSelection}
                    tax={tax}
                />
            ))}
        </>
    );
};

PriceItemsReview.propTypes = {
    fields: PropTypes.object.isRequired,
    showVendorSelection: PropTypes.bool,
    tax: PropTypes.number,
};

export const ReviewPurchaseDetails = ({
    change,
    showVendorSelection,
    vendorAssignmentModalSubmitHandler,
}) => {
    const styles = require('../index.scss');
    const tax = useSelector(getGovernmentSalesTax);

    const purchaseDetailsMode = useSelector((state) =>
        requisitionsCreateFormValueSelector(state, PURCHASE_DETAILS_MODE)
    );

    if (purchaseDetailsMode === AMOUNT_ONLY) {
        return (
            <div className={styles.purchaseDetails}>
                <PurchaseDetailsTotal />
            </div>
        );
    }
    return (
        <div className={styles.purchaseDetails}>
            <div className={styles.priceItemsContainer}>
                <FieldArray
                    component={PriceItemsReview}
                    name={`${PRICE_TABLE}.${PRICE_ITEMS}`}
                    showVendorSelection={showVendorSelection}
                    tax={tax}
                />
            </div>
            <div className={classNames(styles.container, styles.containerBackground)}>
                <PurchaseDetailsSummary />
            </div>
            <VendorAssignmentModal
                change={change}
                submitHandler={vendorAssignmentModalSubmitHandler}
            />
        </div>
    );
};

ReviewPurchaseDetails.propTypes = {
    change: PropTypes.func.isRequired,
    showVendorSelection: PropTypes.bool,
    vendorAssignmentModalSubmitHandler: PropTypes.func,
};

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { AWARD_NOTE, form } from './constants';
import { Button, InputText } from '../../../components';
import { updateEvaluation } from '../../../actions/evaluations';

const formConfig = {
    form,
};

const ConnectedAwardNoteForm = ({ closeForm, handleSubmit, label, projectId }) => {
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);
    const dispatch = useDispatch();

    const submitHandler = (values) => {
        setSaving(true);
        setError(null);
        const updateData = {
            evaluation: {
                [AWARD_NOTE]: values[AWARD_NOTE],
            },
            updateOptions: {
                forceEdit: true,
            },
        };

        dispatch(
            updateEvaluation(projectId, updateData, {
                notify: true,
                notifyDisplay: 'Award note saved',
            })
        )
            .then(() => {
                setSaving(false);
                closeForm();
            })
            .catch((e) => {
                setSaving(false);
                setError(e.message);
            });
    };

    return (
        <form onSubmit={handleSubmit(submitHandler)}>
            <Field
                component={InputText}
                disabled={saving}
                help="Note will be publicly displayed if bid results are made public"
                label={label}
                minRows={2}
                name={AWARD_NOTE}
                overrideFeedback
                placeholder="Enter award note"
                qaTag={`awardNoteForm-${AWARD_NOTE}`}
                type="textarea"
            />
            <div className="text-right">
                <Button
                    bsSize="sm"
                    bsStyle="link"
                    disabled={saving}
                    onClick={closeForm}
                    type="button"
                >
                    Cancel
                </Button>
                <Button bsSize="sm" bsStyle="primary" disabled={saving} type="submit">
                    Save
                </Button>
                {error && <div className="error-block">{error}</div>}
            </div>
        </form>
    );
};

ConnectedAwardNoteForm.propTypes = {
    closeForm: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    label: PropTypes.node.isRequired,
    projectId: PropTypes.number.isRequired,
};

export const AwardNoteForm = reduxForm(formConfig)(ConnectedAwardNoteForm);

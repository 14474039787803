import Highcharts from 'highcharts';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { projectTypesDict } from '@og-pro/shared-config/projects';

import { customColors } from '../../constants';
import { maskNumberWithCommas } from '../../../../../Forms/maskers';

const { PURCHASE, CONTRACT } = projectTypesDict;

export class AnalyticsCharts extends PureComponent {
    static propTypes = {
        cycleTimeSeriesData: PropTypes.array.isRequired,
        departmentData: PropTypes.array.isRequired,
        historicData: PropTypes.shape({
            categories: PropTypes.arrayOf(PropTypes.string).isRequired,
            series: PropTypes.array.isRequired,
        }).isRequired,
        templateData: PropTypes.shape({
            categories: PropTypes.arrayOf(PropTypes.string).isRequired,
            series: PropTypes.array.isRequired,
        }).isRequired,
        workloadWeightsData: PropTypes.shape({
            categories: PropTypes.arrayOf(PropTypes.string).isRequired,
            series: PropTypes.array.isRequired,
        }).isRequired,
        projectType: PropTypes.oneOf([PURCHASE, CONTRACT]).isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    componentDidMount() {
        this.renderWorkloadManagement();
        this.renderStackedHistoricProjects();
        this.renderCycleTimePhaseAverages();
        this.renderProjectsByDepartment();
        this.renderTemplatesChart();
    }

    renderWorkloadManagement() {
        const {
            workloadWeightsData: { categories, series },
            projectType,
        } = this.props;

        // eslint-disable-next-line new-cap
        return new Highcharts.chart({
            chart: {
                type: 'column',
                renderTo: 'workload-management',
                borderWidth: 1,
                borderColor: '#dce0e0',
            },
            title: {
                text: 'Workload Management',
            },
            xAxis: {
                categories,
                labels: {
                    rotation: -45,
                },
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Workload Points',
                },
                stackLabels: {
                    enabled: true,
                    formatter() {
                        return maskNumberWithCommas(this.total);
                    },
                },
            },
            plotOptions: {
                column: {
                    cursor: 'pointer',
                    stacking: 'normal',
                    pointPadding: 0.2,
                    borderWidth: 2,
                },
            },
            tooltip: {
                pointFormatter() {
                    return `${this.series.name} workload: ${maskNumberWithCommas(
                        this.y
                    )} points<br>Click to view ${this.category} ${
                        projectType === PURCHASE ? 'projects' : 'contract documents'
                    }`;
                },
            },
            credits: {
                enabled: false,
            },
            series,
        });
    }

    renderCycleTimePhaseAverages() {
        const { cycleTimeSeriesData, projectType } = this.props;

        // eslint-disable-next-line new-cap
        return new Highcharts.chart({
            chart: {
                borderColor: '#dce0e0',
                borderWidth: 1,
                renderTo: 'cycle-time-phase-averages',
                type: 'bar',
            },
            credits: {
                enabled: false,
            },
            legend: {
                reversed: true,
            },
            plotOptions: {
                bar: {
                    borderWidth: 3,
                },
                series: {
                    stacking: 'normal',
                },
            },
            series: cycleTimeSeriesData,
            title: {
                text: `Average ${projectType === PURCHASE ? 'Project' : ''} Cycle Time (Days)`,
            },
            tooltip: {
                formatter() {
                    return `<strong>${this.series.name}</strong>: ${this.series.userOptions.label}<br />${this.series.userOptions.description}`;
                },
                style: {
                    width: 300,
                },
            },
            xAxis: {
                // NOTE: if we ever broke cycle time out by project type, the types would become the
                // categories here
                categories: [''],
            },
            yAxis: {
                labels: {
                    enabled: true,
                },
                min: 0,
                title: {
                    text: 'Days',
                },
            },
        });
    }

    renderStackedHistoricProjects() {
        const {
            historicData: { categories, series },
            projectType,
        } = this.props;

        // eslint-disable-next-line new-cap
        return new Highcharts.chart({
            chart: {
                type: 'area',
                renderTo: 'historic-projects',
                borderWidth: 1,
                borderColor: '#dce0e0',
            },
            title: {
                text:
                    projectType === PURCHASE
                        ? 'Projects Created By Month'
                        : 'Total Number of Contract Documents',
            },
            xAxis: {
                categories,
                tickmarkPlacement: 'on',
                title: {
                    enabled: false,
                },
            },
            yAxis: {
                min: 0,
                title: {
                    text: `Number of ${
                        projectType === PURCHASE ? 'Projects' : 'Contract Documents'
                    }`,
                },
            },
            tooltip: {
                split: true,
            },
            plotOptions: {
                area: {
                    stacking: 'normal',
                    lineColor: '#666666',
                    lineWidth: 1,
                    marker: {
                        lineWidth: 1,
                        lineColor: '#666666',
                    },
                },
            },
            credits: {
                enabled: false,
            },
            series,
        });
    }

    renderProjectsByDepartment() {
        const { departmentData, projectType } = this.props;

        // eslint-disable-next-line new-cap
        return new Highcharts.chart({
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                renderTo: 'projects-by-department',
                borderWidth: 1,
                borderColor: '#dce0e0',
            },
            title: {
                text: `${
                    projectType === PURCHASE ? 'Projects' : 'Contract Documents'
                } by Department`,
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.y}</b>',
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    borderWidth: 3,
                    cursor: 'pointer',
                    colors: customColors,
                    size: '75%',
                    dataLabels: {
                        enabled: true,
                        distance: 20,
                        format: '<b>{point.name}</b>: {point.y}',
                        style: {
                            color:
                                (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
                        },
                    },
                },
            },
            credits: {
                enabled: false,
            },
            series: [
                {
                    name: 'Projects',
                    colorByPoint: true,
                    data: departmentData,
                },
            ],
        });
    }

    renderTemplatesChart() {
        const {
            templateData: { categories, series },
            projectType,
        } = this.props;

        // eslint-disable-next-line new-cap
        return new Highcharts.chart({
            chart: {
                type: 'column',
                renderTo: 'template-counts',
                borderWidth: 1,
                borderColor: '#dce0e0',
            },
            title: {
                text: `${projectType === PURCHASE ? 'Projects' : 'Contract Documents'} by Template`,
            },
            xAxis: {
                categories,
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Number of Projects',
                },
            },
            legend: {
                enabled: false,
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                    colors: customColors,
                    colorByPoint: true,
                    dataLabels: {
                        enabled: true,
                    },
                },
            },
            credits: {
                enabled: false,
            },
            series,
        });
    }

    render() {
        return (
            <>
                <div className={`row ${this.styles.row}`}>
                    <div className="col-xs-12">
                        <div id="workload-management" />
                    </div>
                </div>
                <div className={`row ${this.styles.row}`}>
                    <div className="col-xs-12">
                        <div id="historic-projects" />
                    </div>
                </div>
                <div className={`row ${this.styles.row}`}>
                    <div className="col-xs-12">
                        <div id="cycle-time-phase-averages" />
                    </div>
                </div>
                <div className={`row ${this.styles.row}`}>
                    <div className="col-md-6">
                        <div id="projects-by-department" />
                    </div>
                    <div className="col-md-6">
                        <div id="template-counts" />
                    </div>
                </div>
                <div className={`row ${this.styles.row}`}>
                    <div className="col-md-6">
                        <div id="projects-by-timeline" />
                    </div>
                    <div className="col-md-6">
                        <div id="timeline-percent" />
                    </div>
                </div>
            </>
        );
    }
}

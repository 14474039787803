import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ContractSubscribersForm } from './ContractSubscribersForm';
import { form } from './ContractSubscribersForm/constants';
import { ContractSubscriber } from './ContractSubscriber';
import { getUsersSelectOptions } from './selectors';
import { getUserJS } from '../../../selectors';
import { Well } from '../../../../components';

const mapStateToProps = (state) => {
    return {
        addingSubscribers: state.contracts.get('addingSubscribers'),
        addSubscriberErrors: state.contracts.get('addSubscribersError'),
        user: getUserJS(state),
        usersList: getUsersSelectOptions(state),
    };
};

// @connect
class ConnectedContractSubscribers extends Component {
    static propTypes = {
        addingSubscribers: PropTypes.bool,
        addSubscriberErrors: PropTypes.string,
        addContractSubscribers: PropTypes.func.isRequired,
        contract: PropTypes.shape({
            contractSubscribers: PropTypes.array.isRequired,
            id: PropTypes.number.isRequired,
        }).isRequired,
        isEditor: PropTypes.bool,
        removeContractSubscriber: PropTypes.func.isRequired,
        resetForm: PropTypes.func.isRequired,
        user: PropTypes.object.isRequired,
        usersList: PropTypes.array.isRequired,
    };

    deleteHandler = (contractSubscriberId) => {
        const { contract } = this.props;

        this.props.removeContractSubscriber(contract.id, contractSubscriberId);
    };

    handleSubmit = (data) => {
        const { contract, resetForm } = this.props;

        if (!data || !data.users) {
            return;
        }

        this.props.addContractSubscribers(
            contract.id,
            data.users.map((user) => user.value), // `value` is the `user.id`
            () => resetForm(form)
        );
    };

    renderSubscriberList() {
        const {
            contract: { contractSubscribers },
            isEditor,
            user,
        } = this.props;

        if (contractSubscribers.length === 0) {
            return null;
        }

        return (
            <ul className="list-unstyled">
                {contractSubscribers.map((subscriber) => (
                    <ContractSubscriber
                        contractSubscriber={subscriber}
                        isEditor={isEditor}
                        key={subscriber.id}
                        onDelete={this.deleteHandler}
                        user={user}
                    />
                ))}
            </ul>
        );
    }

    renderZeroState() {
        return (
            <div className="text-muted text-center">
                Add any users that should receive notifications.
                <br />
                Users may remove themselves from notifications and subscribe others.
            </div>
        );
    }

    render() {
        const { addingSubscribers, addSubscriberErrors, usersList } = this.props;

        return (
            <Well>
                <label htmlFor="searchSelect">Who to Notify</label>
                <ContractSubscribersForm
                    addError={addSubscriberErrors}
                    disabled={addingSubscribers}
                    onSubmit={this.handleSubmit}
                    usersList={usersList}
                />
                {this.renderSubscriberList()}
                {this.renderZeroState()}
            </Well>
        );
    }
}

export const ContractSubscribers = connect(mapStateToProps)(ConnectedContractSubscribers);

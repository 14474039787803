import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export class TimelineHeader extends PureComponent {
    static propTypes = {
        isDocx: PropTypes.bool,
        name: PropTypes.string.isRequired,
    };

    renderDocx() {
        return (
            <td
                style={{
                    width: 270,
                    color: '#337ab7',
                    padding: 10,
                    textAlign: 'right',
                }}
            >
                <strong>{this.props.name}</strong>
            </td>
        );
    }

    render() {
        const { isDocx, name } = this.props;

        if (isDocx) {
            return this.renderDocx();
        }

        return <div className="col-md-6 timeline-header">{name}:</div>;
    }
}

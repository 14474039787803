import { browserHistory } from '@og-pro-migration-tools/react-router';

import { CREATE, CREATE_FAIL, CREATE_SUCCESS, reset } from './project/create/projectCreate';
import { resourceManager } from '../helpers';

export const SET_SECTION_DISABLED = 'requisitionsCreate/SET_SECTION_DISABLED';
export const SET_SECTION_ENABLED = 'requisitionsCreate/SET_SECTION_ENABLED';
export const SET_SECTION_COMPLETED = 'requisitionsCreate/SET_SECTION_COMPLETED';
export const SET_SECTION_INCOMPLETE = 'requisitionsCreate/SET_SECTION_INCOMPLETE';
export const SET_SECTION_EDITABLE = 'requisitionsCreate/SET_SECTION_EDITABLE';
export const SET_SECTION_UNEDITABLE = 'requisitionsCreate/SET_SECTION_UNEDITABLE';

export const SET_INITIAL_VENDOR_SELECTION_INPUT_DATA =
    'requisitionsCreate/SET_INITIAL_VENDOR_SELECTION_INPUT_DATA';

export const LOAD_EXCEPTION_SEQUENCES = 'requisitionsCreate/LOAD_EXCEPTION_SEQUENCES';
export const LOAD_EXCEPTION_SEQUENCES_SUCCESS =
    'requisitionsCreate/LOAD_EXCEPTION_SEQUENCES_SUCCESS';
export const LOAD_EXCEPTION_SEQUENCES_FAILURE =
    'requisitionsCreate/LOAD_EXCEPTION_SEQUENCES_FAILURE';

export const setSectionDisabled = (section) => ({
    type: SET_SECTION_DISABLED,
    payload: { section },
});

export const setSectionEnabled = (section) => ({
    type: SET_SECTION_ENABLED,
    payload: { section },
});

export const setSectionCompleted = (section) => ({
    type: SET_SECTION_COMPLETED,
    payload: { section },
});

export const setSectionIncomplete = (section) => ({
    type: SET_SECTION_INCOMPLETE,
    payload: { section },
});

export const setSectionEditable = (section) => ({
    type: SET_SECTION_EDITABLE,
    payload: { section },
});

export const setSectionUneditable = (section) => ({
    type: SET_SECTION_UNEDITABLE,
    payload: { section },
});

export function createRequisition(data) {
    return resourceManager({
        method: 'post',
        url: '/requisitions/requisitions',
        requestOptions: { data },
        onStart: ({ dispatch }) => dispatch({ type: CREATE, text: 'Creating Request...' }),
        onSuccess: ({ result, dispatch }) => {
            const { government_id: governmentId, id: requisitionId } = result;
            dispatch({ type: CREATE_SUCCESS, result });

            dispatch(reset());

            browserHistory.push(`/governments/${governmentId}/requisitions/${requisitionId}/edit`);
        },
        onFailure: ({ context, error, dispatch }) => {
            window.scroll(0, 0);
            dispatch({ type: CREATE_FAIL, error });
            if (context.rethrowError) {
                throw error;
            }
        },
    });
}

// Used to set the initial state of the vendor selection input. Allows for pre-filling the input
// with an initial search and specifying a contract to associate on selection.
export const setInitialVendorSelectionInputData = ({
    initialSearch = null,
    showInput = false,
    suggestedContract = null,
} = {}) => ({
    type: SET_INITIAL_VENDOR_SELECTION_INPUT_DATA,
    payload: { showInput, initialSearch, suggestedContract },
});

export const loadRequisitionExceptionSequences = (id) => {
    return resourceManager({
        method: 'get',
        url: `/requisitions/requisitions/${id}/exception-sequences`,
        onStart: ({ dispatch }) => dispatch({ type: LOAD_EXCEPTION_SEQUENCES }),
        onSuccess: ({ dispatch, result }) =>
            dispatch({ type: LOAD_EXCEPTION_SEQUENCES_SUCCESS, result }),
        onFailure: ({ dispatch, error }) =>
            dispatch({ type: LOAD_EXCEPTION_SEQUENCES_FAILURE, error }),
    });
};

import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Label } from 'react-bootstrap';

import { ContactAddress, DataTable, Tooltip } from '..';

export class PlanholdersList extends PureComponent {
    static propTypes = {
        emailButton: PropTypes.node,
        planholders: PropTypes.arrayOf(
            PropTypes.shape({
                displayName: PropTypes.string.isRequired,
                email: PropTypes.string.isRequired,
                isProposer: PropTypes.bool.isRequired,
                organization: PropTypes.shape({
                    name: PropTypes.string.isRequired,
                    phoneComplete: PropTypes.string,
                    vendor: PropTypes.shape({
                        certifications: PropTypes.array,
                    }),
                }).isRequired,
                phoneComplete: PropTypes.string,
                projectVendorUserSubscriptions: PropTypes.shape({
                    type: PropTypes.string,
                }).isRequired,
            })
        ).isRequired,
        showSubmittedProposals: PropTypes.bool.isRequired,
        title: PropTypes.string,
    };

    get styles() {
        return require('./index.scss');
    }

    formatDataForCSVExport = (data) => {
        const { showSubmittedProposals } = this.props;

        const headers = [
            'Vendor',
            'Contact Name',
            'Contact Email',
            'Contact Address',
            'Contact Phone',
            'Designation',
            'Certifications',
        ];

        if (showSubmittedProposals) {
            headers.push('Response Submitted');
        }

        const rows = data.map((dataRow) => {
            const vendorData = dataRow._original.organization.vendor; // eslint-disable-line no-underscore-dangle
            const certifications = vendorData?.certifications || [];

            const row = [
                dataRow['organization.name'],
                dataRow.displayName,
                dataRow._original.email, // eslint-disable-line no-underscore-dangle
                [
                    dataRow._original.address1 || dataRow._original.organization.address1, // eslint-disable-line no-underscore-dangle
                    dataRow._original.address2 || dataRow._original.organization.address2, // eslint-disable-line no-underscore-dangle
                    dataRow._original.city || dataRow._original.organization.city, // eslint-disable-line no-underscore-dangle
                    dataRow._original.state || dataRow._original.organization.state, // eslint-disable-line no-underscore-dangle
                    dataRow._original.zipCode || dataRow._original.organization.zipCode, // eslint-disable-line no-underscore-dangle
                ]
                    .filter((field) => !!field)
                    .join(' '),
                dataRow._original.phoneComplete || // eslint-disable-line no-underscore-dangle
                    dataRow._original.organization.phoneComplete || // eslint-disable-line no-underscore-dangle
                    '',
                dataRow['designation-column'],
                certifications.length > 0
                    ? certifications.map((cert) => cert.title).join(', ')
                    : '',
            ];
            if (showSubmittedProposals) {
                row.push(dataRow.isProposer ? 'X' : '');
            }
            return row;
        });

        return [headers].concat(rows);
    };

    render() {
        const { emailButton, planholders, showSubmittedProposals, title } = this.props;

        const columns = [
            {
                Header: 'Vendor',
                accessor: 'organization.name',
                className: this.styles.rowContainer,
                Cell: (props) => {
                    const vendorData = props.original.organization.vendor;
                    const certifications = vendorData?.certifications || [];

                    return (
                        <>
                            <div
                                className={this.styles.logoContainer}
                                style={{
                                    backgroundImage: `url(${props.original.organization.logo})`,
                                }}
                                title={`${props.value} logo`}
                            >
                                &nbsp;
                            </div>
                            {props.value}
                            {certifications.length > 0 && (
                                <div className={this.styles.certifications}>
                                    {certifications.map((cert, i) => (
                                        <Tooltip
                                            key={`${cert.acronym} ${i}`}
                                            placement="bottom"
                                            tooltip={cert.title}
                                        >
                                            <Label className={this.styles.certLabel}>
                                                {cert.acronym}
                                            </Label>
                                        </Tooltip>
                                    ))}
                                </div>
                            )}
                        </>
                    );
                },
            },
            {
                Header: 'Contact',
                accessor: 'displayName',
                Cell: (props) => {
                    const contactData = [
                        'address1',
                        'address2',
                        'city',
                        'country',
                        'countryCode',
                        'phoneComplete',
                        'state',
                        'zipCode',
                    ].reduce((obj, field) => {
                        obj[field] = props.original[field] || props.original.organization[field];
                        return obj;
                    }, {});

                    return (
                        <>
                            {props.value}
                            <br />
                            <em>{props.original.email}</em>
                            {contactData.address1 && (
                                <>
                                    <br />
                                    <ContactAddress {...contactData} />
                                </>
                            )}
                            {!contactData.address1 && contactData.phoneComplete && <br />}
                            {contactData.phoneComplete}
                        </>
                    );
                },
            },
            {
                Header: 'Designation',
                id: 'designation-column',
                accessor: (row) => get(row, 'projectVendorUserSubscriptions.type') || 'None',
                className: this.styles.rowContainer,
                maxWidth: 120,
            },
        ];

        if (showSubmittedProposals) {
            columns.push({
                Header: 'Response Submitted',
                accessor: 'isProposer',
                className: `${this.styles.rowContainer} text-center`,
                Cell: (props) => {
                    if (props.value) {
                        return <i className="fa fa-check" />;
                    }
                    return null;
                },
                maxWidth: 150,
            });
        }

        return (
            <DataTable
                buttons={emailButton ? [emailButton] : []}
                className="-highlight -striped"
                columns={columns}
                csvExportOptions={{
                    fileName: 'Followers List',
                    getFormattedCSVData: this.formatDataForCSVExport,
                    headers: true,
                }}
                data={planholders}
                pageSize={planholders.length}
                showCSVExport
                showPagination={false}
                title={title}
            />
        );
    }
}

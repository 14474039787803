import { FieldArray } from 'redux-form';
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { v4 as UUIDv4 } from 'uuid';

import { ReverseAuctionPriceTableBuilder } from '../PriceTableBuilders/ReverseAuctionPriceTableBuilder';
import { StandardPriceTableBuilder } from '../PriceTableBuilders/StandardPriceTableBuilder';

export class PricingTables extends PureComponent {
    static propTypes = {
        auctionMaxFractionDigits: PropTypes.number.isRequired,
        change: PropTypes.func.isRequired,
        copyPriceTable: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        fields: PropTypes.shape({
            get: PropTypes.func.isRequired,
            map: PropTypes.func.isRequired,
            remove: PropTypes.func.isRequired,
        }).isRequired,
        isReverseAuction: PropTypes.bool.isRequired,
        salesTax: PropTypes.number,
        showHelpModal: PropTypes.func.isRequired,
        showValidation: PropTypes.bool,
        showConfirmationModal: PropTypes.func.isRequired,
        useOpenGovStyle: PropTypes.bool,
    };

    static defaultProps = {
        disabled: false,
        showValidation: false,
    };

    copyPriceTable = (index) => {
        const { copyPriceTable, fields } = this.props;

        const { priceItems, ...priceTable } = fields.get(index);

        const priceTableData = omit(priceTable, ['id', 'created_at']);

        const cleanPriceItems = (priceItems || []).map((priceItem) => {
            const newPriceItem = omit(priceItem, ['id', 'price_table_id', 'created_at']);
            newPriceItem.newPriceItemUuid = UUIDv4();
            return newPriceItem;
        });

        copyPriceTable({
            ...priceTableData,
            priceItems: cleanPriceItems,
        });
    };

    removeField = (index) => {
        const { fields, showConfirmationModal } = this.props;

        const onConfirm = () => {
            fields.remove(index);
        };

        showConfirmationModal(onConfirm, {
            text:
                'Deleting the price table will also delete all pricing ' +
                'items in the table. Please confirm you would like to proceed.',
            icon: 'trash-o',
            btnText: 'Delete',
        });
    };

    render() {
        const {
            auctionMaxFractionDigits,
            change,
            disabled,
            fields,
            isReverseAuction,
            salesTax,
            showHelpModal,
            showValidation,
            useOpenGovStyle,
        } = this.props;

        return fields.map((name, index) => {
            const priceTable = fields.get(index);
            return (
                <FieldArray
                    auctionMaxFractionDigits={auctionMaxFractionDigits}
                    change={change}
                    component={
                        isReverseAuction
                            ? ReverseAuctionPriceTableBuilder
                            : StandardPriceTableBuilder
                    }
                    copyPriceTable={() => this.copyPriceTable(index)}
                    disabled={disabled}
                    draggableIndex={index}
                    draggableName={name}
                    isReverseAuction={isReverseAuction}
                    key={index}
                    name={`${name}.priceItems`}
                    priceTable={priceTable}
                    priceTableArrayName={name}
                    removeTable={() => this.removeField(index)}
                    salesTax={salesTax}
                    showHelpModal={showHelpModal}
                    showValidation={showValidation}
                    useOpenGovStyle={useOpenGovStyle}
                />
            );
        });
    }
}

import { USE_OG_PLATFORM } from '@og-pro/shared-config/platform';

import { useProjectNavItems } from './useProjectNavItems';
import { useDashboardNavItems } from './useDashboardNavItems';
import { useAnalyticsNavItems } from './useAnalyticsNavItems';
import { useUtilityTrayOptions } from './useUtilityTrayOptions';
import { useContextSwitcherOptions } from './useContextSwitcherOptions';
import { ReactRouterLink } from './utils';

export const useGlobalNavbarConfig = () => {
    const projectNavItems = useProjectNavItems();
    const dashboardNavItems = useDashboardNavItems();
    const analyticsNavItems = useAnalyticsNavItems();
    const utilityTrayOptions = useUtilityTrayOptions();
    const contextSwitcherOptions = useContextSwitcherOptions();

    const menuOptions = [...projectNavItems, ...dashboardNavItems, ...analyticsNavItems];

    return {
        menuOptions,
        openGovLogoLinkComponent: ReactRouterLink,
        openGovLogoUrl: '/',
        utilityTrayOptions,
        ...(USE_OG_PLATFORM && { contextSwitcherOptions }),
    };
};

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { RichTextEditorContext as Context } from './constants';

const BaseRichTextEditorContext = (props) => {
    const { children, project } = props;

    const projectId = project ? project.id : undefined;

    // Ensure the absolute minimum number of changes to the context object to prevent re-renders
    const contextValue = useMemo(() => {
        const context = {};
        if (projectId) {
            context.imageUploadData = { projectId };
        }
        return context;
    }, [projectId]);

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

BaseRichTextEditorContext.propTypes = {
    children: PropTypes.node.isRequired,
    project: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }),
};

export const RichTextEditorContext = React.memo(BaseRichTextEditorContext);
RichTextEditorContext.Context = Context;

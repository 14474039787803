import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { DisplaySection } from '../..';
import { getContractDocBuilderRoute } from '../../../helpers';

export const ContractDocBuilderDocuments = ({ contract: { contractDocBuilderDocuments } }) => {
    const navigate = useNavigate();

    return (
        <DisplaySection
            header="ASSEMBLED CONTRACT DOCUMENTS"
            items={contractDocBuilderDocuments.map((doc) => {
                return {
                    icon: doc.template.icon,
                    label: doc.template.title,
                    value: (
                        <span
                            className="pseudoLink"
                            onClick={() => navigate(getContractDocBuilderRoute(doc))}
                        >
                            {doc.title || 'Untitled'}
                        </span>
                    ),
                };
            })}
            zeroState="No Documents Included"
        />
    );
};

ContractDocBuilderDocuments.propTypes = {
    contract: PropTypes.shape({
        contractDocBuilderDocuments: PropTypes.array.isRequired,
    }).isRequired,
};

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { isNil } from 'lodash';
import { getFormValues, Field, reduxForm } from 'redux-form';
import { AutoFixNormal as WandIcon } from '@mui/icons-material';
import { Box, Button, Dialog, Grid, Typography } from '@og-pro/ui';

import {
    questionLogicFieldNames,
    questionLogicLogicableModelAlias,
} from '@og-pro/shared-config/questionLogics';

import {
    SearchSelect,
    SearchSelectIconOption,
    SearchSelectIconValue,
} from '../../../../../components';
import { hideQuestionLogicModal } from '../../../../../actions/templatesAdmin';
import { operatorSelectOptions } from '../../../constants';
import { UpfrontQuestionSelectOption } from './UpfrontQuestionSelectOption';
import {
    actionSelectOptions,
    form,
    MULTI_VALUE_TYPES,
    pseudoFieldNames,
    VALUE_TYPE,
} from './constants';
import { validate } from './validate';
import { serializeData } from './utils';
import { getInitialFormValues } from './selectors';
import {
    useProjectSectionSelectOptions,
    useProjectSectionValueSelectOptions,
    useUpfrontQuestionsOptions,
} from './hooks';

const { LINKABLE_ITEM, PROJECT_SECTION_ID, PROJECT_SECTION, UPFRONT_QUESTION } = pseudoFieldNames;
const { ACTION, LINKABLE, LINKABLE_ID, OPERATOR, VALUE, LOGICABLE_ID } = questionLogicFieldNames;

const ConnectedQuestionLogicModal = reduxForm({ form, validate })(({
    change,
    handleSubmit,
    onAdd,
    onEdit,
    onRemove,
    projectSections,
    upfrontQuestions,
}) => {
    const dispatch = useDispatch();
    const modalFormData = useSelector((state) =>
        state.templatesAdmin.get('questionLogicModalData')?.toJS()
    );
    const disableLinkableItemSelection = useSelector((state) =>
        state.templatesAdmin.get('questionLogicLinkableItemDisabled')
    );
    const editIndex = useSelector((state) => state.templatesAdmin.get('questionLogicEditIndex'));
    const formValues = useSelector((state) => getFormValues(form)(state) || {});
    const isEditForm = !isNil(editIndex);
    const logicableId = formValues[LOGICABLE_ID];
    const projectSectionId = formValues[PROJECT_SECTION_ID];
    const linkableId = formValues[LINKABLE_ID];

    // options for the first and third select
    const { upfrontQuestionsOptions, upfrontQuestionValueSelectOptions } =
        useUpfrontQuestionsOptions({ upfrontQuestions, logicableId });

    // options for the second to last select
    const projectSectionSelectOptions = useProjectSectionSelectOptions({
        projectSections,
        modalFormData,
    });

    // once a project section as been selected we need to get the values for
    // the last select
    const selectedProjectSection = projectSectionSelectOptions.find(
        (ps) => ps.value === projectSectionId
    );

    const projectSectionValueSelectOptions = useProjectSectionValueSelectOptions({
        linkableId,
        modalFormData,
        projectSection: selectedProjectSection ? selectedProjectSection[PROJECT_SECTION] : null,
    });

    const hideModal = useCallback(() => {
        dispatch(hideQuestionLogicModal());
    }, [dispatch]);

    const onChangeWithOptionUpfrontQuestionHandler = useCallback(
        (option) => {
            // when the first select changes we clear the third select
            change(VALUE, null);

            if (!option) {
                change(UPFRONT_QUESTION, null);
                return;
            }

            // this is temporary until the form saves and we get the right values from the backend
            // until then, this matches the logicable alias
            change(questionLogicLogicableModelAlias.UPFRONT_QUESTION, option[UPFRONT_QUESTION]);
        },
        [change]
    );

    const operatorChangeHandler = useCallback(
        (e, newValue, prevValue) => {
            // when the operator changes, depending on what type of operator it is
            // we clear the third select because it needs to be reselected
            if (VALUE_TYPE[newValue] !== VALUE_TYPE[prevValue]) {
                change(VALUE, null);
            }
        },
        [change]
    );

    const onChangeWithOptionProjectSectionHandler = useCallback(
        (option) => {
            // when the second to last select changes, we will clear
            // the last select value and also the pseudo field linkable that holds the whole object
            change(LINKABLE_ID, null);
            change(LINKABLE, null);

            if (!option) {
                change(PROJECT_SECTION, null);
                return;
            }

            change(PROJECT_SECTION, option[PROJECT_SECTION]);
        },
        [change]
    );

    const onChangeWithOptionLinkableItemHandler = useCallback(
        (option) => {
            if (!option) {
                change(LINKABLE, null);
                change(LINKABLE_ITEM, null);
                return;
            }

            change(LINKABLE, option[LINKABLE]);
            change(LINKABLE_ITEM, option[LINKABLE_ITEM]);
        },
        [change]
    );

    const submitHandler = useCallback((data) => {
        const serializedData = serializeData(data);

        if (isEditForm) {
            onEdit(serializedData, editIndex);
        } else {
            onAdd(serializedData);
        }

        hideModal({ force: true });
    }, []);

    const removeHandler = useCallback(() => {
        onRemove(editIndex, () => hideModal({ force: true }));
    }, [editIndex, hideModal, onRemove]);

    if (!upfrontQuestionsOptions.length) {
        return (
            <Dialog fullWidth maxWidth="xs" onClose={hideModal} open>
                <Box p={4}>
                    <Box textAlign="center">
                        <WandIcon color="primary" fontSize="large" />
                    </Box>
                    <Typography align="center" variant="h3">
                        No Document Set up Question
                    </Typography>
                    <Typography align="center">
                        To add automation first add at least one Document Set Up Question that can
                        be used here.
                    </Typography>
                </Box>
            </Dialog>
        );
    }

    return (
        <Dialog
            allowOverflowedContent
            dialogTitle={`${isEditForm ? 'Edit' : 'Add'} Automation Logic`}
            fullWidth
            maxWidth="md"
            onClose={hideModal}
            open
            scroll="body"
        >
            <Box minHeight="200px" py={2}>
                <Box>
                    <Box mb={0.5}>
                        <Typography fontWeight={500}>
                            When the following condition is met
                        </Typography>
                    </Box>

                    <Grid container spacing={2} sx={{ '& .form-group': { marginBottom: '0px' } }}>
                        <Grid item md={4} xs={12}>
                            <Field
                                aria-label="Configuration Question"
                                component={SearchSelect}
                                components={{
                                    Option: UpfrontQuestionSelectOption,
                                }}
                                menuPortalTarget={document?.body}
                                name={LOGICABLE_ID}
                                onChangeWithOption={onChangeWithOptionUpfrontQuestionHandler}
                                options={upfrontQuestionsOptions}
                                placeholder="Configuration Question"
                                qaTag={`questionLogicModal-${LOGICABLE_ID}`}
                                useOpenGovStyle
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Field
                                aria-label="Operator"
                                component={SearchSelect}
                                menuPortalTarget={document?.body}
                                name={OPERATOR}
                                onChange={operatorChangeHandler}
                                options={operatorSelectOptions}
                                placeholder="Operator"
                                qaTag={`questionLogicModal-${OPERATOR}`}
                                useOpenGovStyle
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Field
                                aria-label="Value"
                                component={SearchSelect}
                                disabled={!logicableId}
                                isMulti={MULTI_VALUE_TYPES.includes(formValues[OPERATOR])}
                                isMultiSimpleValue={MULTI_VALUE_TYPES.includes(
                                    formValues[OPERATOR]
                                )}
                                menuPortalTarget={document?.body}
                                name={VALUE}
                                options={upfrontQuestionValueSelectOptions}
                                placeholder="Value"
                                useNullWhenUndefined
                                useOpenGovStyle
                            />
                            {!logicableId && (
                                <Typography variant="body2">Select a question first</Typography>
                            )}
                        </Grid>
                    </Grid>

                    <Box mb={0.5} mt={2}>
                        <Typography fontWeight={500}>Take the following action</Typography>
                    </Box>
                    <Grid container spacing={2} sx={{ '& .form-group': { marginBottom: '0px' } }}>
                        <Grid item md={4} xs={12}>
                            <Field
                                aria-label="Action"
                                component={SearchSelect}
                                components={{
                                    Option: SearchSelectIconOption,
                                    SingleValue: SearchSelectIconValue,
                                }}
                                name={ACTION}
                                options={actionSelectOptions}
                                placeholder="Action"
                                qaTag={`questionLogicModal-${ACTION}`}
                                useOpenGovStyle
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Field
                                aria-label="Section"
                                component={SearchSelect}
                                disabled={disableLinkableItemSelection}
                                menuPortalTarget={document?.body}
                                name={PROJECT_SECTION_ID}
                                onChangeWithOption={onChangeWithOptionProjectSectionHandler}
                                options={projectSectionSelectOptions}
                                placeholder="Section"
                                qaTag={`questionLogicModal-${PROJECT_SECTION_ID}`}
                                useOpenGovStyle
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Field
                                aria-label="Section Item"
                                component={SearchSelect}
                                disabled={!projectSectionId || disableLinkableItemSelection}
                                key={formValues[LINKABLE_ID]}
                                menuPortalTarget={document?.body}
                                name={LINKABLE_ID}
                                onChangeWithOption={onChangeWithOptionLinkableItemHandler}
                                options={projectSectionValueSelectOptions || []}
                                placeholder="Section Item"
                                qaTag={`questionLogicModal-${LINKABLE_ID}`}
                                useOpenGovStyle
                            />
                            {!projectSectionId && (
                                <Typography variant="body2">Select a section first</Typography>
                            )}
                        </Grid>
                    </Grid>
                </Box>
                <Box alignItems="center" display="flex" justifyContent="flex-end" mt={4}>
                    {isEditForm && (
                        <Box mr={2}>
                            <Button
                                color="error"
                                onClick={removeHandler}
                                qaTag="questionLogicModal-remove"
                                variant="outlined"
                            >
                                Remove Logic
                            </Button>
                        </Box>
                    )}
                    <Box>
                        <Button
                            color="primary"
                            onClick={handleSubmit(submitHandler)}
                            qaTag="questionLogicModal-submit"
                            variant="outlined"
                        >
                            {isEditForm ? 'Done' : 'Add Logic'}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Dialog>
    );
});

ConnectedQuestionLogicModal.propTypes = {
    onAdd: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    projectSections: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            section_type: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
        })
    ),
    upfrontQuestions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
        })
    ),
};

export const QuestionLogicModal = (props) => {
    const initialValues = useSelector(getInitialFormValues);

    return <ConnectedQuestionLogicModal {...props} initialValues={initialValues} />;
};

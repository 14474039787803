import PropTypes from 'prop-types';
import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { Field } from 'redux-form';

import { CompleteReview, IncompleteSection } from './components';

export const TemplateReviewSection = ({
    disabled,
    formSectionKey,
    formSections,
    incompleteSectionInstructions,
    meta: { valid },
    openSectionHandler,
    readyText,
    saveHandler,
    setLiveHandler,
}) => {
    const saveHandlerFn = () => {
        saveHandler().then((templateOrError) => {
            if (!(templateOrError instanceof Error)) {
                openSectionHandler(null);
            }
        });
    };

    if (valid) {
        return (
            <CompleteReview
                disabled={disabled}
                readyText={readyText}
                saveHandler={saveHandlerFn}
                setLiveHandler={setLiveHandler}
            />
        );
    }

    const FORM_SECTIONS_KEY = formSectionKey ? `${formSectionKey}.` : '';
    const styles = require('./index.scss');

    const incompleteFormSections = formSections.map((formSection, idx) => (
        <Field
            component={IncompleteSection}
            disabled={disabled}
            formSection={formSection}
            incompleteSectionInstructions={incompleteSectionInstructions}
            key={formSection}
            name={`${FORM_SECTIONS_KEY}${formSection}`}
            openSectionHandler={openSectionHandler}
            sectionNumber={idx + 1}
        />
    ));

    return (
        <div className="row">
            <h4 className={styles.incompleteTitle}>Almost there</h4>
            <div className="col-sm-offset-1 col-sm-10 col-md-offset-2 col-md-8">
                <ListGroup>{incompleteFormSections}</ListGroup>
            </div>
        </div>
    );
};

TemplateReviewSection.propTypes = {
    disabled: PropTypes.bool,
    formSectionKey: PropTypes.string,
    formSections: PropTypes.array.isRequired,
    incompleteSectionInstructions: PropTypes.object.isRequired,
    meta: PropTypes.shape({
        valid: PropTypes.bool.isRequired,
    }).isRequired,
    openSectionHandler: PropTypes.func.isRequired,
    readyText: PropTypes.string.isRequired,
    saveHandler: PropTypes.func.isRequired,
    setLiveHandler: PropTypes.func.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import { components as ReactSelectComponents } from 'react-select';

export const SearchSelectCustomControlWithIcon = ({ children, ...rest }) => (
    <ReactSelectComponents.Control {...rest}>
        <div
            style={{
                borderRight: '1px solid #B5B9BD',
                padding: '7px 14px',
            }}
        >
            <i className="fa fa-search" />
        </div>
        {children}
    </ReactSelectComponents.Control>
);

SearchSelectCustomControlWithIcon.propTypes = {
    children: PropTypes.node,
};

import { browserHistory } from '@og-pro-migration-tools/react-router';

import { projectStatusesDict } from '@og-pro/shared-config/projects';

import { showNotification } from './notification';
import { emitProjectSocket } from './utils';
import { getCommentThreadKey } from '../helpers';
import { numberStringToInteger } from '../utils';

const { DRAFT, REVIEW } = projectStatusesDict;

export const SHOW_MODAL = 'gov/comments/SHOW_MODAL';
export const HIDE_MODAL = 'gov/comments/HIDE_MODAL';
export const SHOW_RESOLVED_COMMENTS = 'gov/comments/SHOW_RESOLVED_COMMENTS';
export const HIDE_RESOLVED_COMMENTS = 'gov/comments/HIDE_RESOLVED_COMMENTS';
export const VIEW_SELECTED_COMMENT = 'gov/comments/VIEW_SELECTED_COMMENT';

export function showCommentsModal(commentFieldIdentifier) {
    return {
        type: SHOW_MODAL,
        field: commentFieldIdentifier,
    };
}

export function hideCommentsModal() {
    return { type: HIDE_MODAL };
}

export function showResolvedComments() {
    return { type: SHOW_RESOLVED_COMMENTS };
}

export function toggleResolvedComments() {
    return (dispatch, getState) => {
        if (getState().govComments.get('showResolved')) {
            return dispatch({ type: HIDE_RESOLVED_COMMENTS });
        }
        return dispatch({ type: SHOW_RESOLVED_COMMENTS });
    };
}

export function viewSelectedComment(id) {
    return { type: VIEW_SELECTED_COMMENT, commentId: id };
}

export const SHOW_FOLLOWERS_FORM = 'gov/comments/SHOW_FOLLOWERS_FORM';
export const HIDE_FOLLOWERS_FORM = 'gov/comments/HIDE_FOLLOWERS_FORM';
export const SHOW_NEW_FOLLOWERS_FORM = 'gov/comments/SHOW_NEW_FOLLOWERS_FORM';
export const HIDE_NEW_FOLLOWERS_FORM = 'gov/comments/HIDE_NEW_FOLLOWERS_FORM';

// Use the optional `isModal` argument when updating a field in the comment modal
// IMPORTANT: If no threadId is passed the follower form to show is on the new thread form
export function showFollowersForm(threadId, field, isModal) {
    if (!threadId) {
        return { type: SHOW_NEW_FOLLOWERS_FORM };
    }
    return { type: SHOW_FOLLOWERS_FORM, threadId, field, isModal };
}

export function hideFollowersForm(threadId, field, isModal) {
    if (!threadId) {
        return { type: HIDE_NEW_FOLLOWERS_FORM };
    }
    return { type: HIDE_FOLLOWERS_FORM, threadId, field, isModal };
}

export const ADD_FOLLOWERS = 'gov/comments/ADD_FOLLOWERS';
export const ADD_FOLLOWERS_SUCCESS = 'gov/comments/ADD_FOLLOWERS_SUCCESS';
export const ADD_FOLLOWERS_FAIL = 'gov/comments/ADD_FOLLOWERS_FAIL';

// Use the optional `isModal` argument when updating a field in the comment modal
export function addFollowers(threadId, followerIds, field, isModal) {
    return (dispatch, getState, client) => {
        const data = { userIds: followerIds };
        dispatch({ type: ADD_FOLLOWERS, threadId, field, isModal });
        return client
            .post(`/thread/${threadId}/followers`, { data })
            .then((result) => {
                const action = { type: ADD_FOLLOWERS_SUCCESS, result, field, threadId };
                dispatch(emitProjectSocket(result.project_id, action, false));
            })
            .catch((error) => {
                dispatch({ type: ADD_FOLLOWERS_FAIL, error, threadId, field, isModal });
            });
    };
}

export const LOAD = 'gov/comments/LOAD';
export const LOAD_SUCCESS = 'gov/comments/LOAD_SUCCESS';
export const LOAD_FAIL = 'gov/comments/LOAD_FAIL';

export function loadComments(projectId) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD });
        return client
            .get(`/project/${projectId}/thread`)
            .then((result) => {
                dispatch({ type: LOAD_SUCCESS, result });
            })
            .catch((error) => {
                dispatch({ type: LOAD_FAIL, error });
                dispatch(
                    showNotification(`Error loading comments: ${error.message}`, { type: 'danger' })
                );
            });
    };
}

export const CREATE_THREAD = 'gov/comments/CREATE_THREAD';
export const CREATE_THREAD_SUCCESS = 'gov/comments/CREATE_THREAD_SUCCESS';
export const CREATE_THREAD_FAIL = 'gov/comments/CREATE_THREAD_FAIL';

export function createCommentThread(projectId, data) {
    return (dispatch, getState, client) => {
        dispatch({ type: CREATE_THREAD });
        return client
            .post(`/project/${projectId}/thread`, { data })
            .then((result) => {
                const field = getCommentThreadKey(result);

                const createThreadAction = { type: CREATE_THREAD_SUCCESS, result, field };
                dispatch(emitProjectSocket(projectId, createThreadAction, 'New Comment Received'));

                return result;
            })
            .catch((error) => {
                dispatch({ type: CREATE_THREAD_FAIL, error });
                return error;
            });
    };
}

export const REPLY = 'gov/comments/REPLY';
export const REPLY_SUCCESS = 'gov/comments/REPLY_SUCCESS';
export const REPLY_FAIL = 'gov/comments/REPLY_FAIL';

// Use the optional `isModal` argument when updating a field in the comment modal
export function replyCommentThread(threadId, data, field, isModal) {
    return (dispatch, getState, client) => {
        dispatch({ type: REPLY, threadId, field, isModal });
        return client
            .post(`/thread/${threadId}/comment`, { data })
            .then((result) => {
                const action = { type: REPLY_SUCCESS, result, field, threadId };
                dispatch(emitProjectSocket(result.project_id, action, 'New Comment Received'));
            })
            .catch((error) => {
                dispatch({ type: REPLY_FAIL, error, threadId, field, isModal });
            });
    };
}

// Used for responding to a list comment. Initialized by calling the function
// with the field to update in comment list view. Second call contains the
// reply data and submits the post request.
export function listReplyCommentThread(field) {
    return (dispatch) => (threadId, data) => {
        return dispatch(replyCommentThread(threadId, data, field));
    };
}

export const THREAD_ACTION = 'gov/comments/THREAD_ACTION';
export const THREAD_ACTION_SUCCESS = 'gov/comments/THREAD_ACTION_SUCCESS';
export const THREAD_ACTION_FAIL = 'gov/comments/THREAD_ACTION_FAIL';

function threadAction(action, threadId, field, isModal) {
    return (dispatch, getState, client) => {
        dispatch({ type: THREAD_ACTION, threadId, field, isModal });
        return client
            .post(`/thread/${threadId}/${action}`)
            .then((result) => {
                const actionToDispatch = { type: THREAD_ACTION_SUCCESS, result, field, threadId };
                dispatch(emitProjectSocket(result.project_id, actionToDispatch, false));
            })
            .catch((error) => {
                dispatch({ type: THREAD_ACTION_FAIL, error, threadId, field, isModal });
            });
    };
}

// Convenience methods for resolving/reopening threads
const RESOLVE_THREAD = 'resolve';
const REOPEN_THREAD = 'reopen';

// Use the optional `isModal` argument when updating a field in the comment modal
export function resolveThread(threadId, field, isModal) {
    return threadAction(RESOLVE_THREAD, threadId, field, isModal);
}
export function reopenThread(threadId, field, isModal) {
    return threadAction(REOPEN_THREAD, threadId, field, isModal);
}

// Check if the state is configured to display comments
export function shouldShowComments(state) {
    if (!state.govComments.get('showComments')) return false;
    if (state.govComments.get('loading')) return false;
    if (!state.govComments.get('loaded')) return false;

    return true;
}

// Check if the state is ready to load comments
export function shouldLoadComments(state) {
    return !state.govComments.get('loading') && !state.govComments.get('loaded');
}

export const SHOW_COMMENTS = 'gov/comments/SHOW_COMMENTS';
export const HIDE_COMMENTS = 'gov/comments/HIDE_COMMENTS';

export function showComments() {
    return { type: SHOW_COMMENTS };
}

export function hideComments() {
    return { type: HIDE_COMMENTS };
}

export function toggleCommentsDisplay(projectId) {
    return (dispatch, getState) => {
        if (shouldShowComments(getState())) {
            return dispatch(hideComments());
        }

        // Check if comments have already been loaded for project
        if (!shouldLoadComments(getState())) {
            return dispatch(showComments());
        }
        // Load the comments otherwise
        return dispatch(loadComments(projectId)).then(() => dispatch(showComments()));
    };
}

export function loadAndShowComments(project) {
    return (dispatch, getState) => {
        const { id: projectId, isIntake, status } = project;

        let queryParams = {};

        if (browserHistory.location()?.search) {
            const searchParams = new URLSearchParams(browserHistory.location().search);
            queryParams = Object.fromEntries(searchParams);
        }

        // Check if the comment showing params are specified
        const {
            'comment-subsection': projectSubsectionIdString,
            'comment-criteria': criterionIdString,
            'comment-type': commentType,
            'comment-id': commentIdString,
        } = queryParams;

        // Guard against strings from old comment URLs
        const projectSubsectionId = numberStringToInteger(projectSubsectionIdString);
        const criterionId = numberStringToInteger(criterionIdString);
        const commentId = numberStringToInteger(commentIdString);

        const hasCommentParams = projectSubsectionId || criterionId;

        if (isIntake || [DRAFT, REVIEW].includes(status) || hasCommentParams) {
            dispatch(showComments());
            if (shouldLoadComments(getState())) {
                return dispatch(loadComments(projectId)).then(() => {
                    if (!hasCommentParams) {
                        return null;
                    }

                    if (commentId) {
                        // Alerts modal a specific comment has been selected
                        dispatch(viewSelectedComment(commentId));
                    }

                    const commentKey = getCommentThreadKey({
                        criterion_id: criterionId,
                        project_subsection_id: projectSubsectionId,
                        type: commentType,
                    });

                    // Show the modal if the param is specified
                    return dispatch(showCommentsModal(commentKey));
                });
            }
        } else {
            return dispatch(hideComments());
        }
    };
}

export const LIST_FILTER = 'gov/comments/LIST_FILTER';

export function filterComments(filter) {
    return { type: LIST_FILTER, filter };
}

export const RESET = 'gov/comments/RESET';

export function resetCommentThreads() {
    return { type: RESET };
}

import { capitalDesignTokens } from '@opengov/capital-mui-theme';

export const cardContentStyle = {
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
};

export const activeItemsStyle = {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: 2,
    '> div:not(:first-child)': {
        borderTop: `1px solid ${capitalDesignTokens.semanticColors.border.primary}`,
        paddingTop: 2,
    },
    maxWidth: '100%',
};

export const checkboxStyle = {
    alignSelf: 'flex-start',
    margin: 0,
    padding: 0,
};

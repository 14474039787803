import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Field, FieldArray } from 'redux-form';

import { AddendumConfirm } from './AddendumConfirm';
import { ADDENDUMS } from './constants';

export class AddendumsConfirmFormSection extends PureComponent {
    static propTypes = {
        change: PropTypes.func.isRequired,
        confirmAddendum: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        showValidation: PropTypes.bool,
    };

    renderAddendums = (props) => {
        const { change, confirmAddendum, disabled, showValidation } = this.props;

        return props.fields.map((member, index, fields) => {
            const addendum = fields.get(index);
            return (
                <Field
                    addendum={addendum}
                    change={change}
                    component={AddendumConfirm}
                    confirmAddendum={confirmAddendum}
                    disabled={disabled}
                    fieldName={member}
                    key={addendum.id}
                    name={member}
                    showValidation={showValidation}
                />
            );
        });
    };

    render() {
        return <FieldArray component={this.renderAddendums} name={ADDENDUMS} />;
    }
}

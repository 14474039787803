import { createSelector } from 'reselect';

import { navItems } from './constants';
import { getRevisionsPath } from '../selectors';

export const getNavItems = createSelector([getRevisionsPath], (revisionsPath) => {
    return navItems.map((item) => {
        return {
            ...item,
            route: `${revisionsPath}/versions${item.path}`,
        };
    });
});

export const getSelectedVersionsRoute = createSelector(
    [getNavItems, (state, props) => props.location],
    (navs, location) => {
        const { pathname } = location;

        return (navs.find((item) => pathname.match(new RegExp(`^${item.route}`))) || navs[0]).route;
    }
);

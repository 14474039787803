import React from 'react';
import PropTypes from 'prop-types';

import { currencyFormatter } from '../../../../../../helpers';

export const LineItemDetailsInfoHeader = ({
    auctionMaxFractionDigits,
    lineItemTotalNumBids,
    lowestUnitPrice,
    minBidDecrement,
    quantity,
    title,
}) => {
    const styles = require('../../index.scss');
    const formatterOpts = {
        maximumFractionDigits: auctionMaxFractionDigits,
        useSameMinAndMax: true,
    };

    return (
        <div className={`row ${styles.lineItemDetailsContainer}`}>
            <div className="col-xs-12 col-lg-5">
                <h3>{title}</h3>
                <div className={styles.quantityText}>Quantity: {quantity}</div>
            </div>
            <div className={`col-xs-6 col-lg-2 ${styles.lowestUnitPriceSection}`}>
                <strong>Lowest Unit Price</strong>
                <div className={styles.lowestUnitPriceText}>
                    {currencyFormatter({ value: lowestUnitPrice }, formatterOpts)}
                </div>
            </div>
            <div className={`col-xs-6 col-lg-2 ${styles.lowestTotalSection}`}>
                <strong>Lowest Total</strong>
                <div className={styles.lowestTotalText}>
                    {currencyFormatter({ value: lowestUnitPrice * quantity }, formatterOpts)}
                </div>
            </div>
            <div className={`col-xs-6 col-lg-3 ${styles.infoSection}`}>
                <div className={styles.infoSectionText}>
                    <span>Min Decrement</span>
                    <strong>{currencyFormatter({ value: minBidDecrement }, formatterOpts)}</strong>
                </div>
                <div className={styles.infoSectionText}>
                    <span>Total Number of Bids</span>
                    <strong>{lineItemTotalNumBids}</strong>
                </div>
            </div>
        </div>
    );
};

LineItemDetailsInfoHeader.propTypes = {
    auctionMaxFractionDigits: PropTypes.number.isRequired,
    lineItemTotalNumBids: PropTypes.number.isRequired,
    lowestUnitPrice: PropTypes.number.isRequired,
    minBidDecrement: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
};

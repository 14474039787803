import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';

import { createForm as form, fieldNames } from '../constants';
import { validate } from '../validate';
import { Button, InputText, SearchSelect, SearchSelectUserOption } from '../../../../components';
import { createDepartment } from '../../../../actions/admin';

const { CONTACT_ID, DEPARTMENT_HEAD, DEPARTMENT_HEAD_TITLE, NAME } = fieldNames;

const mapStateToProps = (state) => {
    return {
        orgId: state.auth.getIn(['user', 'organization', 'id']),
    };
};

const mapDispatchToProps = {
    createDepartment,
};

const formConfig = {
    form,
    validate,
};

// @connect
// @reduxForm
class ConnectedDepartmentCreateForm extends Component {
    static propTypes = {
        createDepartment: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        invalid: PropTypes.bool.isRequired,
        orgId: PropTypes.number,
        reset: PropTypes.func.isRequired,
        users: PropTypes.array.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            createError: null,
            creating: false,
        };
    }

    handleSubmit = (data) => {
        const { orgId, reset } = this.props;

        this.setState({ creating: true, createError: null });
        return this.props
            .createDepartment(orgId, data)
            .then(() => {
                this.setState({ creating: false });
                reset();
            })
            .catch((error) => {
                this.setState({ creating: false, createError: error.message });
            });
    };

    render() {
        const styles = require('../index.scss');

        const { handleSubmit, invalid, users } = this.props;

        const { createError, creating } = this.state;

        return (
            <form onSubmit={handleSubmit(this.handleSubmit)}>
                <div className={`${styles.departmentForm} row`}>
                    <div className="col-md-3">
                        <Field
                            autoFocus
                            component={InputText}
                            disabled={creating}
                            hasFeedback={false}
                            label="Department Name"
                            name={NAME}
                            type="text"
                        />
                    </div>
                    <div className="col-md-3">
                        <Field
                            component={InputText}
                            disabled={creating}
                            hasFeedback={false}
                            label="Department Head Name"
                            name={DEPARTMENT_HEAD}
                            type="text"
                        />
                    </div>
                    <div className="col-md-3">
                        <Field
                            component={InputText}
                            disabled={creating}
                            hasFeedback={false}
                            label="Department Head Title"
                            name={DEPARTMENT_HEAD_TITLE}
                            type="text"
                        />
                    </div>
                    <div className="col-md-3">
                        <Field
                            component={SearchSelect}
                            components={{
                                Option: SearchSelectUserOption,
                            }}
                            disabled={creating}
                            isClearable
                            label="Procurement Contact"
                            name={CONTACT_ID}
                            options={users}
                            placeholder="Select User"
                        />
                    </div>
                    <div className={`col-xs-12 col-lg-offset-9 col-lg-3 ${styles.createButton}`}>
                        <Button
                            block
                            bsStyle="success"
                            disabled={invalid || creating}
                            type="submit"
                        >
                            <i className="fa fa-plus" />{' '}
                            {creating ? 'Creating...' : 'Create Department'}
                        </Button>
                        {createError && <div className="error-block">{createError}</div>}
                    </div>
                </div>
            </form>
        );
    }
}

export const DepartmentCreateForm = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedDepartmentCreateForm);

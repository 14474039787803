import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { PERMISSION_REQUIRED_ACTION_TOOLTIP } from '../../constants';
import { Button } from '../../../../../components';

export const PrimaryActionButton = (props) => {
    const navigate = useNavigate();

    const clickHandler = () => {
        const { action, actionHandler, link } = props;

        if (link) {
            navigate(link);
        }

        if (action) {
            actionHandler(action);
        }
    };

    const { hasPermission, hasPermissionText, icon, name } = props;

    const tooltip = hasPermission
        ? undefined
        : hasPermissionText || PERMISSION_REQUIRED_ACTION_TOOLTIP;

    return (
        <Button
            bsStyle="success"
            disabled={!hasPermission}
            onClick={clickHandler}
            qaTag="primaryActionButton-executeAction"
            tooltip={tooltip}
            tooltipPlacement="left"
        >
            <i className={`fa ${icon}`} /> {name}
        </Button>
    );
};

PrimaryActionButton.propTypes = {
    action: PropTypes.string,
    actionHandler: PropTypes.func.isRequired,
    hasPermission: PropTypes.bool,
    hasPermissionText: PropTypes.string,
    icon: PropTypes.string.isRequired,
    link: PropTypes.string,
    name: PropTypes.string.isRequired,
};

import PropTypes from 'prop-types';
import React from 'react';

import { Typography } from '@og-pro/ui';

import { ActionButtons } from './ActionButtons';
import { ActionError } from './ActionError';
import { StatusBar } from './StatusBar';

export const RequisitionsApprovalHeader = ({
    disabled,
    requisition,
    requisition: { identifier, descriptionOfRequest },
}) => {
    const styles = require('./index.scss');
    return (
        <div className={styles.headerContainer}>
            <div className={styles.headerContent}>
                <div className={styles.headerDetails}>
                    {descriptionOfRequest ? (
                        <div className={styles.headerTitle}>
                            <Typography variant="bodyDefault">{identifier}</Typography>
                            <Typography maxWidth="100%" noWrap variant="h2">
                                {descriptionOfRequest}
                            </Typography>
                        </div>
                    ) : (
                        <h1>{identifier}</h1>
                    )}
                    <StatusBar requisition={requisition} />
                </div>
                <div>
                    <ActionButtons disabled={disabled} />
                </div>
            </div>
            <ActionError />
        </div>
    );
};

RequisitionsApprovalHeader.propTypes = {
    disabled: PropTypes.bool,
    requisition: PropTypes.shape({
        identifier: PropTypes.string.isRequired,
        descriptionOfRequest: PropTypes.string,
    }).isRequired,
};

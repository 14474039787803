import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getProposalForEvaluationJS } from '../../selectors';
import {
    showEvaluationNotes,
    showEvaluationScorecard,
} from '../../../../actions/proposalEvaluations';
import { Button, ButtonGroup, ButtonToolbar } from '../../../../components';

const mapStateToProps = (state) => {
    return {
        proposal: getProposalForEvaluationJS(state),
    };
};

const mapDispatchToProps = {
    showEvaluationNotes,
    showEvaluationScorecard,
};

// @connect
class ConnectedProposalEvaluationNav extends Component {
    static propTypes = {
        backUrl: PropTypes.string.isRequired,
        evaluator: PropTypes.object,
        isConsensusView: PropTypes.bool,
        proposal: PropTypes.object,
        showEvaluationNotes: PropTypes.func.isRequired,
        showEvaluationScorecard: PropTypes.func.isRequired,
    };

    get styles() {
        return require('./ProposalEvaluationNav.scss');
    }

    showEvaluationScorecard = () => {
        const { evaluator, isConsensusView, proposal } = this.props;

        const userId = isConsensusView ? null : evaluator.id;
        return this.props.showEvaluationScorecard(proposal.id, userId);
    };

    showEvaluationNotes = () => {
        const { evaluator, isConsensusView, proposal } = this.props;

        const userId = isConsensusView ? null : evaluator.id;
        return this.props.showEvaluationNotes(proposal.id, userId);
    };

    render() {
        const { backUrl } = this.props;

        return (
            <div className={this.styles.container}>
                <ButtonToolbar>
                    <Button bsSize="sm" exact to={backUrl}>
                        <i className="fa fa-angle-left" /> Back
                    </Button>
                    <ButtonGroup bsSize="sm">
                        <Button onClick={this.showEvaluationNotes}>
                            <i className="fa fa-file-text" /> Notes
                        </Button>
                        <Button onClick={this.showEvaluationScorecard}>
                            <i className="fa fa-pencil" /> Scorecard
                        </Button>
                    </ButtonGroup>
                </ButtonToolbar>
            </div>
        );
    }
}

export const ProposalEvaluationNav = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedProposalEvaluationNav);

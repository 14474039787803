import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showConditionalLogicModal } from '../../../actions/app';
import { Button } from '../../../components';

const mapDispatchToProps = {
    showModal: showConditionalLogicModal,
};

// @connect
class ConnectedConditionalLogicIcon extends PureComponent {
    static propTypes = {
        onAddQuestion: PropTypes.func.isRequired,
        onAddTemplateQuestion: PropTypes.func,
        showModal: PropTypes.func.isRequired,
        // this can be either an Upfront question or a Questionnaire one
        parentQuestion: PropTypes.object.isRequired,
        useOpenGovStyle: PropTypes.bool,
    };

    get styles() {
        return require('./index.scss');
    }

    showConditionalLogicModal = (e) => {
        const { onAddQuestion, onAddTemplateQuestion, showModal, parentQuestion } = this.props;

        e.stopPropagation();

        showModal({
            parentQuestion,
            onAddQuestion,
            onAddTemplateQuestion,
        });
    };

    render() {
        return (
            <Button
                bsStyle="link"
                className={this.styles.button}
                onClick={this.showConditionalLogicModal}
                tooltip="Add conditional logic to item"
                tooltipPlacement="left"
            >
                {this.props.useOpenGovStyle ? (
                    <i className="fa fa-sitemap" />
                ) : (
                    <i className="fa fa-random text-primary" />
                )}
            </Button>
        );
    }
}

export const ConditionalLogicIcon = connect(
    null,
    mapDispatchToProps
)(ConnectedConditionalLogicIcon);

import { hasFormErrors } from '../../helpers';

/**
 * Checks each sections for the existence of errors and produces an error
 * object with the key of the section and a true value if errors were found
 * @param  {Object.<string, Error>[]} sectionsToValidate Array of tuples [sectionName, errorObject]
 * @return {object}                   Error object with section name as keys
 */
export function sectionsValidate(...sectionsToValidate) {
    const errors = {};

    errors.sections = sectionsToValidate.reduce((errorsObj, sectionData) => {
        const [section, sectionErrors] = sectionData;
        const sectionValidate = hasFormErrors(sectionErrors) || undefined;
        return { ...errorsObj, [section]: sectionValidate };
    }, {});

    return errors;
}

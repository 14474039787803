import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { TemplateQuestionCreateForm } from '../TemplateQuestionCreateForm';
import { showConfirmationSimpleModal } from '../../../../../actions/confirmation';
import {
    deleteTemplateQuestion,
    updateTemplateQuestion,
} from '../../../../../actions/templateQuestions';
import { LoadingError, LoadingSpinner, OutlineButton } from '../../../../../components';

export const ModalBody = ({
    hideModal,
    loading,
    loadError,
    templateQuestion,
    templateQuestionId,
    templatesInUse,
}) => {
    const [deleting, setDeleting] = useState(false);
    const [deleteError, setDeleteError] = useState(null);

    const [updating, setUpdating] = useState(false);
    const [updateError, setUpdateError] = useState(null);

    const dispatch = useDispatch();

    const onSubmit = (formValues) => {
        setUpdating(true);
        setUpdateError(null);
        return dispatch(updateTemplateQuestion(templateQuestionId, formValues))
            .then(() => {
                hideModal();
            })
            .catch((error) => {
                setUpdating(false);
                setUpdateError(error.message);
            });
    };

    const deleteHandler = () => {
        setDeleting(true);
        setDeleteError(null);
        return dispatch(deleteTemplateQuestion(templateQuestionId))
            .then(() => {
                hideModal();
            })
            .catch((error) => {
                setDeleting(false);
                setDeleteError(error.message);
            });
    };

    const onDeleteHandler = () => {
        dispatch(
            showConfirmationSimpleModal(deleteHandler, {
                text: 'Are you sure you want to delete this Shared Question?',
            })
        );
    };

    if (loading) {
        return <LoadingSpinner />;
    }

    if (loadError || !templateQuestion) {
        return <LoadingError error={loadError} />;
    }

    const isEditingDisabled = templatesInUse.length > 0;

    return (
        <>
            <div className="text-right">
                <OutlineButton
                    bsStyle="danger"
                    disabled={deleting}
                    onClick={onDeleteHandler}
                    qaTag="sharedQuestionCreateModal-delete"
                >
                    <i className="fa fa-trash" />
                    &nbsp;Delete
                </OutlineButton>
                {deleteError && <div className="error-block">{deleteError}</div>}
            </div>
            <TemplateQuestionCreateForm
                disabled={updating}
                initialValues={templateQuestion}
                isEditForm
                isEditingDisabled={isEditingDisabled}
                onSubmit={onSubmit}
                selectedQuestionnaire={templateQuestion}
                templateInUse={templatesInUse[0]}
                updateError={updateError}
            />
        </>
    );
};

ModalBody.propTypes = {
    hideModal: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    loadError: PropTypes.string,
    templateQuestion: PropTypes.object,
    templateQuestionId: PropTypes.number.isRequired,
    templatesInUse: PropTypes.array.isRequired,
};

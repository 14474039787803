import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { patchGovernmentSettings } from '../../../actions/adminGovernment';
import { SectionTitle, Main } from '../../../components';
import { patchingSettings as patchingSettingsSelector } from '../../GovApp/App/selectors';
import { getUserJS } from '../../selectors';
import { serialize } from './serializers';
import { SettingsForm } from './SettingsForm';

export const Settings = () => {
    const dispatch = useDispatch();
    const user = useSelector(getUserJS);
    const loading = useSelector(patchingSettingsSelector);

    const onSubmit = (rawValues) => {
        const values = serialize(rawValues);

        dispatch(patchGovernmentSettings(user.government_id, values));
    };

    return (
        <Main>
            <SectionTitle title="Site Settings" />
            <SettingsForm loading={loading} onSubmit={onSubmit} />
        </Main>
    );
};

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Uses the current hash in the URL to scroll to an anchor element with the same ID.
 * @param {string} hash The hash from the URL
 */
export const scrollToHashAnchorElement = (hash) => {
    if (!hash) {
        return;
    }

    const anchorName = hash.replace('#', '');
    const anchorElement = document.getElementById(anchorName);
    if (anchorElement) {
        anchorElement.scrollIntoView();
    }
};

export const useScrollToHashAnchorElement = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            scrollToHashAnchorElement(location.hash);
        }
    }, []);
};

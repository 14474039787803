import { fieldNames } from './constants';

const { USER_IDS, VENDOR_LIST_ID, NAME } = fieldNames;

export const validate = (values) => {
    const errors = {};

    if (!values[USER_IDS] && (!values[USER_IDS] || values[USER_IDS].length === 0)) {
        errors[USER_IDS] = 'Please select at least one vendor to add to a list';
    }

    if (
        (!values[NAME] || !values[NAME].length) &&
        !values[VENDOR_LIST_ID] &&
        (!values[VENDOR_LIST_ID] || values[VENDOR_LIST_ID].length === 0)
    ) {
        errors[NAME] = 'Please enter a name for the new vendor list';
        errors[VENDOR_LIST_ID] = 'Please select at a vendor list';
    }

    return errors;
};

import { contractFilterTypes, contractFilterTypesDict } from '@og-pro/shared-config/contracts';
import { filterTypesDict } from '@og-pro/shared-config/filters';
import { isNumber } from '@og-pro/shared-config/helpers';
import {
    projectDateFilterTypesDict,
    projectFilterTypes,
    projectFilterTypesDict,
} from '@og-pro/shared-config/projects';

const { BETWEEN } = projectDateFilterTypesDict;

const { CONTRACT, PROJECT } = filterTypesDict;
const { DEPARTMENT_ID, IS_PAUSED, REMOVED_FROM_PUBLIC_VIEW, RETENTION_CODE_ID, TEMPLATE_ID } =
    projectFilterTypesDict;
const { DEPARTMENT, PROCUREMENT_CONTACT } = contractFilterTypesDict;

function deserializeFilterValue(filterType, filter = {}) {
    const { dateFilterType, type, value } = filter;

    // Exclude filters with no types or undefined types
    if (
        !type ||
        (filterType === PROJECT && !projectFilterTypes.includes(type)) ||
        (filterType === CONTRACT && !contractFilterTypes.includes(type))
    ) {
        return null;
    }

    // Convert date values from strings to dates (required by form component)
    if (dateFilterType === BETWEEN) {
        if (!value || !Array.isArray(value) || value.length !== 2) {
            return null;
        }
        return value.map((dateValue) => new Date(dateValue));
    }

    // Convert date values from strings to dates (required by form component)
    if (dateFilterType) {
        return new Date(value);
    }

    // Convert filters that have an ID query param to a number
    if (
        type === DEPARTMENT ||
        type === DEPARTMENT_ID ||
        type === PROCUREMENT_CONTACT ||
        type === RETENTION_CODE_ID ||
        type === TEMPLATE_ID
    ) {
        return isNumber(value) ? Number.parseInt(value, 10) : null;
    }

    if (type === IS_PAUSED || type === REMOVED_FROM_PUBLIC_VIEW) {
        if (value === 'false') {
            return false;
        }
        if (value === 'true') {
            return true;
        }
        return null;
    }

    return value;
}

/**
 * Deserializes a filter query based on the filter type.
 *
 * @param {string} query - The filter query to deserialize.
 * @param {string} [filterType] - Optional: Type of filter to apply to the query.
 * @returns {Object} The deserialized filter query.
 */
export function deserializeFilterQuery(query, filterType) {
    const { filterId, filters, limit, page, searchPriceItems, sort } = query;

    const deserializeProjectFilters = {};

    // Convert filterId to number
    if (filterId && isNumber(filterId)) {
        deserializeProjectFilters.filterId = Number.parseInt(filterId, 10);
    }

    // Convert page to number
    if (page && isNumber(page)) {
        deserializeProjectFilters.page = Number.parseInt(page, 10);
    }

    // Convert limit to number
    if (limit && isNumber(limit)) {
        deserializeProjectFilters.limit = Number.parseInt(limit, 10);
    }

    if (Array.isArray(filters)) {
        deserializeProjectFilters.filters = filters
            .map((filter) => {
                return {
                    ...filter,
                    value: deserializeFilterValue(filterType, filter),
                };
            })
            .filter((filter) => !!filter.value || filter.value === false);
    }

    if (Array.isArray(sort)) {
        deserializeProjectFilters.sort = sort.map((sortItem) => {
            const desc =
                typeof sortItem.desc === 'boolean' ? sortItem.desc : sortItem.desc === 'true';
            return {
                ...sortItem,
                desc,
            };
        });
    }

    if (typeof searchPriceItems === 'boolean') {
        deserializeProjectFilters.searchPriceItems = searchPriceItems;
    } else if (typeof searchPriceItems === 'string') {
        deserializeProjectFilters.searchPriceItems = searchPriceItems.toLowerCase() === 'true';
    }

    return deserializeProjectFilters;
}

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';

import { budgetSetupOptions, form, UNLINK_BUDGET } from './constants';
import { MultipleChoiceInput } from '../../../components';

const mapStateToProps = (state, props) => {
    const onSubmit = (rawData) => {
        props.onSubmit(rawData[UNLINK_BUDGET]);
    };

    return {
        initialValues: { [UNLINK_BUDGET]: false },
        onSubmit, // `onSubmit` must be specified to form for remote submission to work
    };
};

const formConfig = {
    form,
};

// @connect
// @reduxForm
class ConnectedContractFromProjectForm extends Component {
    static propTypes = {
        disabled: PropTypes.bool,
        handleSubmit: PropTypes.func.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const { disabled, handleSubmit } = this.props;

        return (
            <form className={this.styles.container} onSubmit={handleSubmit}>
                <p className={`${this.styles.text} text-muted`}>
                    New contract will be linked to this project.
                </p>
                <Field
                    component={MultipleChoiceInput}
                    disabled={disabled}
                    label="How would you like to setup the contract's budget?"
                    name={UNLINK_BUDGET}
                    options={budgetSetupOptions}
                />
            </form>
        );
    }
}

export const ContractFromProjectForm = compose(
    connect(mapStateToProps),
    reduxForm(formConfig)
)(ConnectedContractFromProjectForm);

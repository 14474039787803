import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { evaluationStatuses, SCORED } from '@og-pro/shared-config/evaluations';

import { evaluationProjectStatuses, projectStatusesDict } from '@og-pro/shared-config/projects';

import { isEvaluationFormValid, isEvaluationSaveValid } from './selectors';
import { InviteItem, PublicDisplayButton, SaveButton, UnsealBidsButton } from '../ToolbarItems';
import {
    getEvaluationAuditData,
    getEvaluationPath,
    isEvaluationDraftPage,
    isEvaluationEditor,
} from '../../selectors';
import {
    initiateSubmit,
    initiateUpdate,
    showFormValidation,
} from '../../../../actions/evaluations';
import { createProposal } from '../../../../actions/govProposals';
import { showProposalSelect } from '../../../../actions/proposalEvaluations';
import { ActionToolbarNavbarButton, ActionToolbarNavbarItem, Nav } from '../../../../components';
import { FINALIZE_AWARD, RELEASE_EVALUATION } from '../../../../constants/menuActions';
import { trackEvent } from '../../../../helpers';

const { AWARD_PENDING } = projectStatusesDict;

const { COMPLETE, DRAFT, OPEN, RELEASED } = evaluationStatuses;

const mapStateToProps = (state, props) => {
    return {
        disabled: state.evaluations.get('updating'),
        evaluationAudit: getEvaluationAuditData(state, props),
        evaluationPath: getEvaluationPath(state, props),
        isDraftPage: isEvaluationDraftPage(state, props),
        isEditor: isEvaluationEditor(state),
        isFormValid: isEvaluationFormValid(state),
        isSaveValid: isEvaluationSaveValid(state),
        saving: state.evaluations.get('updating'),
    };
};

const mapDispatchToProps = {
    createProposal,
    initiateSubmit,
    initiateUpdate,
    showFormValidation,
    showProposalSelect,
};

// @connect
class ConnectedEvaluationToolbar extends Component {
    static propTypes = {
        createProposal: PropTypes.func.isRequired,
        disabled: PropTypes.bool.isRequired,
        evaluationAudit: PropTypes.object,
        evaluationPath: PropTypes.string.isRequired,
        initiateSubmit: PropTypes.func.isRequired,
        initiateUpdate: PropTypes.func.isRequired,
        isDraftPage: PropTypes.bool.isRequired,
        isEditor: PropTypes.bool.isRequired,
        isFormValid: PropTypes.bool.isRequired,
        isSaveValid: PropTypes.bool.isRequired,
        location: PropTypes.object.isRequired,
        menuActionHandler: PropTypes.func.isRequired,
        project: PropTypes.shape({
            evaluation: PropTypes.shape({
                status: PropTypes.string.isRequired,
                type: PropTypes.string.isRequired,
            }).isRequired,
            id: PropTypes.number.isRequired,
            isPublicBidPricingResult: PropTypes.bool.isRequired,
            isPublicBidResult: PropTypes.bool.isRequired,
            priceTables: PropTypes.array.isRequired,
            questionnaires: PropTypes.array.isRequired,
            showBids: PropTypes.bool.isRequired,
            status: PropTypes.string.isRequired,
            wasPosted: PropTypes.bool.isRequired,
        }).isRequired,
        router: PropTypes.object.isRequired,
        saving: PropTypes.bool.isRequired,
        showFormValidation: PropTypes.func.isRequired,
        showProposalSelect: PropTypes.func.isRequired,
    };

    get styles() {
        return require('../ProjectActionToolbar.scss');
    }

    handleAddProposalClick = () => {
        const { evaluationPath, project } = this.props;

        trackEvent('Add Proposal Toolbar Click');
        this.props.createProposal(project, `${evaluationPath}/proposals`);
    };

    handleCompleteEvaluationClick = () => {
        trackEvent('Select Proposal Click');
        this.props.showProposalSelect();
    };

    handleEditSetupClick = () => {
        trackEvent('Edit Evaluation');
    };

    handleFinalizeAwardClick = () => {
        const { menuActionHandler } = this.props;

        trackEvent('View Finalize Award');
        menuActionHandler(FINALIZE_AWARD);
    };

    handleReviewClick = () => {
        trackEvent('View Evaluation');
    };

    handleSelectedVendorClick = () => {
        trackEvent('View Selected Vendor');
    };

    releaseEvaluation = () => {
        const { evaluationPath, isDraftPage, menuActionHandler } = this.props;

        if (isDraftPage) {
            this.props.router.push(evaluationPath);
        }

        menuActionHandler(RELEASE_EVALUATION);
    };

    renderInvite() {
        const {
            disabled,
            isEditor,
            project: { id },
        } = this.props;

        return <InviteItem disabled={disabled} isEditor={isEditor} projectId={id} />;
    }

    renderSave() {
        const { disabled, isDraftPage, isEditor, isSaveValid, saving } = this.props;

        if (!isDraftPage) return null;

        const clickHandler = isSaveValid
            ? this.props.initiateUpdate
            : this.props.showFormValidation;

        return (
            <SaveButton
                clickHandler={clickHandler}
                disabled={disabled}
                isEditor={isEditor}
                isSaveValid={isSaveValid}
                saving={saving}
            />
        );
    }

    renderCompleteDraft() {
        const { disabled, isDraftPage, isEditor, isFormValid } = this.props;

        if (!isDraftPage) return null;
        if (!isFormValid) return null;

        return (
            <ActionToolbarNavbarButton
                bsStyle="success"
                className="action-toolbar-finalize-btn"
                disabled={disabled}
                editOnly
                isEditor={isEditor}
                onClick={this.props.initiateSubmit}
                qaTag="evaluationActionToolbar-completeSetup"
            >
                <i className="fa fa-lg fa-check-square-o" /> Complete Set-up
            </ActionToolbarNavbarButton>
        );
    }

    renderUnsealBidButton() {
        const { disabled, isDraftPage, isEditor, menuActionHandler, project } = this.props;

        return (
            <UnsealBidsButton
                disabled={disabled}
                isDraftPage={isDraftPage}
                isEditor={isEditor}
                menuActionHandler={menuActionHandler}
                project={project}
            />
        );
    }

    renderEdit() {
        const {
            disabled,
            evaluationPath,
            isEditor,
            isDraftPage,
            project: {
                evaluation: { type },
            },
        } = this.props;

        if (isDraftPage || type !== SCORED) return null;

        return (
            <ActionToolbarNavbarItem
                className="action-toolbar-edit-evaluation-btn"
                disabled={disabled}
                editOnly
                href={`${evaluationPath}/create`}
                isEditor={isEditor}
                onClick={this.handleEditSetupClick}
                qaTag="evaluationActionToolbar-edit"
            >
                <i className={`fa fa-pencil ${this.styles.editIcon}`} /> Edit Setup
            </ActionToolbarNavbarItem>
        );
    }

    renderReview() {
        const { disabled, evaluationPath, isDraftPage } = this.props;

        if (!isDraftPage) return null;

        return (
            <ActionToolbarNavbarItem
                className="action-toolbar-review-evaluation-btn"
                disabled={disabled}
                href={evaluationPath}
                onClick={this.handleReviewClick}
                qaTag="evaluationActionToolbar-review"
            >
                <i className="fa fa-file-text" /> Review
            </ActionToolbarNavbarItem>
        );
    }

    renderAddProposal() {
        const { disabled, isDraftPage, isEditor } = this.props;

        if (isDraftPage) return null;
        return (
            <ActionToolbarNavbarItem
                className="action-toolbar-add-proposal-btn"
                disabled={disabled}
                editOnly
                isEditor={isEditor}
                onClick={this.handleAddProposalClick}
                qaTag="evaluationActionToolbar-addProposal"
            >
                <i className="fa fa-plus" /> Add Response
            </ActionToolbarNavbarItem>
        );
    }

    renderReleaseButton() {
        const { disabled, isDraftPage, isEditor } = this.props;

        if (isDraftPage) return null;
        return (
            <ActionToolbarNavbarButton
                bsStyle="success"
                className="action-toolbar-release-evaluation-btn"
                disabled={disabled}
                editOnly
                isEditor={isEditor}
                onClick={this.releaseEvaluation}
                qaTag="evaluationActionToolbar-release"
            >
                <i className="fa fa-send" /> Release to Evaluators
            </ActionToolbarNavbarButton>
        );
    }

    renderSelectProposalButton() {
        const { disabled, isDraftPage, isEditor } = this.props;

        if (isDraftPage) return null;

        return (
            <ActionToolbarNavbarButton
                bsStyle="success"
                className="action-toolbar-select-proposal-btn"
                disabled={disabled}
                editOnly
                isEditor={isEditor}
                onClick={this.handleCompleteEvaluationClick}
                qaTag="evaluationActionToolbar-award"
            >
                <i className="fa fa-trophy" /> Award Project
            </ActionToolbarNavbarButton>
        );
    }

    renderFinalizeAwardButton() {
        const { disabled, isDraftPage, isEditor } = this.props;

        if (isDraftPage) return null;

        return (
            <ActionToolbarNavbarButton
                bsStyle="success"
                className="action-toolbar-confirm-award-btn"
                disabled={disabled}
                editOnly
                isEditor={isEditor}
                onClick={this.handleFinalizeAwardClick}
                qaTag="evaluationActionToolbar-finalize"
            >
                <i className="fa fa-lg fa-check-square-o" /> Finalize Award
            </ActionToolbarNavbarButton>
        );
    }

    renderPublicDisplayButton() {
        const {
            disabled,
            isEditor,
            menuActionHandler,
            project: {
                isPublicBidPricingResult,
                isPublicBidResult,
                priceTables,
                questionnaires,
                showBids,
                status,
                wasPosted,
            },
        } = this.props;

        const hasDisplayableBidResults =
            priceTables.length > 0 ||
            questionnaires.some((questionnaire) => questionnaire.isPublic);

        const shouldDisplayVendorResponses =
            (evaluationProjectStatuses.includes(status) || status === projectStatusesDict.CLOSED) &&
            wasPosted &&
            showBids &&
            !isPublicBidResult &&
            !isPublicBidPricingResult;

        return (
            <PublicDisplayButton
                disabled={disabled}
                isEditor={isEditor}
                menuActionHandler={menuActionHandler}
                showWarningBadge={hasDisplayableBidResults && shouldDisplayVendorResponses}
            />
        );
    }

    renderSelectedVendor() {
        return (
            <ActionToolbarNavbarItem
                className="action-toolbar-view-selected-btn"
                href={`${this.props.evaluationPath}/selected-proposal`}
                onClick={this.handleSelectedVendorClick}
                qaTag="evaluationActionToolbar-selectVendor"
            >
                <i className="fa fa-trophy" /> Selected Vendor
            </ActionToolbarNavbarItem>
        );
    }

    renderDraft() {
        return (
            <div>
                <Nav>
                    {this.renderInvite()}
                    {this.renderReview()}
                    {this.renderEdit()}
                    {this.renderSave()}
                    {this.renderPublicDisplayButton()}
                    {this.renderCompleteDraft()}
                </Nav>
            </div>
        );
    }

    renderOpen() {
        return (
            <div>
                <Nav>
                    {this.renderInvite()}
                    {this.renderReview()}
                    {this.renderEdit()}
                    {this.renderSave()}
                    {this.renderAddProposal()}
                    {this.renderUnsealBidButton()}
                    {this.renderPublicDisplayButton()}
                    {this.renderReleaseButton()}
                </Nav>
            </div>
        );
    }

    renderReleased() {
        return (
            <div>
                <Nav>
                    {this.renderInvite()}
                    {this.renderReview()}
                    {this.renderEdit()}
                    {this.renderSave()}
                    {this.renderAddProposal()}
                    {this.renderUnsealBidButton()}
                    {this.renderPublicDisplayButton()}
                    {this.renderSelectProposalButton()}
                </Nav>
            </div>
        );
    }

    renderComplete() {
        const {
            project: { status },
        } = this.props;

        return (
            <div>
                <Nav>
                    {this.renderInvite()}
                    {this.renderPublicDisplayButton()}
                    {this.renderSelectedVendor()}
                    {status === AWARD_PENDING && this.renderFinalizeAwardButton()}
                </Nav>
            </div>
        );
    }

    renderPastPhase() {
        const {
            evaluationAudit: {
                evaluation: { phaseNumber },
            },
            location: { pathname },
        } = this.props;

        return (
            <div className={this.styles.pastEvaluationText}>
                <strong>
                    <i className="fa fa-exclamation-triangle text-danger" />
                    &nbsp;Viewing past Evaluation Phase {phaseNumber}
                </strong>
                &nbsp;&nbsp;
                <div
                    className={`pseudoLink ${this.styles.currentEvaluationLink}`}
                    onClick={() => {
                        window.scroll(0, 0);
                        this.props.router.push(pathname);
                    }}
                >
                    Go to current evaluation phase
                </div>
            </div>
        );
    }

    render() {
        const {
            evaluationAudit,
            project: {
                evaluation: { status },
            },
        } = this.props;

        if (evaluationAudit) {
            return this.renderPastPhase();
        }

        switch (status) {
            case DRAFT:
                return this.renderDraft();
            case OPEN:
                return this.renderOpen();
            case RELEASED:
                return this.renderReleased();
            case COMPLETE:
                return this.renderComplete();
            default:
                return null;
        }
    }
}

export const EvaluationToolbar = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedEvaluationToolbar);

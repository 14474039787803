import React, { forwardRef } from 'react';
import { NoSsr } from '@mui/material';

import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';

import { DataTable as DataTableLegacy } from './DataTableLegacy';
import { DataTable as DataTableNext } from './Next/DataTableNext';

export const DataTable = forwardRef((props, ref) => (
    <NoSsr>
        {useFlags(FLAGS.ENABLE_REACT_TABLE_8) ? (
            <DataTableNext ref={ref} {...props} />
        ) : (
            <DataTableLegacy ref={ref} {...props} />
        )}
    </NoSsr>
));

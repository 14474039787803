import { Button, ButtonGroup } from '@og-pro/ui';
import { ZoomIn as ZoomInIcon, ZoomOut as ZoomOutIcon } from '@mui/icons-material';
import Select from 'react-select';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const FIT = 'fit';

export const ZoomControls = ({ disabled, DWObject }) => {
    const [currentZoomLevel, setCurrentZoomLevel] = useState(1);
    const styles = require('../index.scss');

    const setZoomLevelFromViewer = () => {
        setCurrentZoomLevel((Math.round(DWObject.Viewer.zoom * 100) / 100).toFixed(2));
    };

    useEffect(() => {
        if (DWObject && DWObject.Viewer) {
            DWObject.Viewer.on('pageRendered', setZoomLevelFromViewer);
        }
    }, [DWObject]);

    const handleZoomChange = (option) => {
        if (option.value === FIT) {
            DWObject.Viewer.fitWindow();
        } else {
            DWObject.Viewer.zoom = option.value;
        }
        setZoomLevelFromViewer();
    };

    const adjustZoomLevel = (increment) => () => {
        DWObject.Viewer.zoom += increment;
        setZoomLevelFromViewer();
    };

    return (
        <ButtonGroup disabled={disabled} variant="outlined">
            <Button onClick={adjustZoomLevel(-0.1)} qaTag="scanning-zoomOut">
                <ZoomOutIcon />
            </Button>
            <Select
                className={styles.zoomSelect}
                components={{
                    IndicatorSeparator: () => null,
                }}
                defaultValue={{ value: 1, label: '100%' }}
                isDisabled={disabled}
                onChange={handleZoomChange}
                options={[
                    ...[...Array(16)].map((v, i) => ({
                        value: (i + 1) / 10,
                        label: `${(i + 1) * 10}%`,
                    })),
                    { value: FIT, label: 'Fit' },
                ]}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary: '#3e5de699', // border when enabled
                        neutral5: 'white', // background when disabled
                        neutral10: '#3e5de699', // border when disabled
                        neutral20: '#3e5de699', // border when focuses
                        neutral80: '#616365FF', // text when enabled
                    },
                    spacing: {
                        ...theme.spacing,
                        controlHeight: 32,
                        menuGutter: 0,
                        baseUnit: 0,
                    },
                })}
                value={{
                    value: currentZoomLevel,
                    label: `${(currentZoomLevel * 100).toFixed(0)}%`,
                }}
            />
            <Button onClick={adjustZoomLevel(0.1)} qaTag="scanning-zoomIn">
                <ZoomInIcon />
            </Button>
        </ButtonGroup>
    );
};

ZoomControls.propTypes = {
    disabled: PropTypes.bool.isRequired,
    DWObject: PropTypes.object,
};

import { resourceManager } from '../helpers';
import { hideConfirmationModal } from './confirmation';
import { emitReverseAuctionSocket } from './utils';

export const LOAD_REVERSE_AUCTION_ITEMS = 'reverseAuctions/LOAD_REVERSE_AUCTION_ITEMS';
export const LOAD_REVERSE_AUCTION_ITEMS_FAIL = 'reverseAuctions/LOAD_REVERSE_AUCTION_ITEMS_FAIL';
export const LOAD_REVERSE_AUCTION_ITEMS_SUCCESS =
    'reverseAuctions/LOAD_REVERSE_AUCTION_ITEMS_SUCCESS';

export const loadGovReverseAuctionPriceItems = (projectId) => {
    return resourceManager({
        method: 'get',
        url: `/project/${projectId}/reverse-auction/government`,
        onStart: ({ dispatch }) => dispatch({ type: LOAD_REVERSE_AUCTION_ITEMS }),
        onSuccess: ({ result, dispatch }) =>
            dispatch({
                type: LOAD_REVERSE_AUCTION_ITEMS_SUCCESS,
                result,
            }),
        onFailure: ({ error, dispatch }) =>
            dispatch({
                type: LOAD_REVERSE_AUCTION_ITEMS_FAIL,
                error,
            }),
    });
};

export const loadVendorReverseAuctionPriceItems = (proposalId) => {
    return resourceManager({
        method: 'get',
        url: `/proposal/${proposalId}/reverse-auction/vendor`,
        onStart: ({ dispatch }) => dispatch({ type: LOAD_REVERSE_AUCTION_ITEMS }),
        onSuccess: ({ result, dispatch }) =>
            dispatch({
                type: LOAD_REVERSE_AUCTION_ITEMS_SUCCESS,
                result,
            }),
        onFailure: ({ error, dispatch }) =>
            dispatch({
                type: LOAD_REVERSE_AUCTION_ITEMS_FAIL,
                error,
            }),
    });
};

// There is no 'success' action since a success response means a queue job was added where processing will continue.
// The queue will emit either `BID_REJECTED` or `BID_PRICE_UPDATED` actions, which end bid processing.
export const PROCESSING_REVERSE_AUCTION_BID = 'reverseAuctions/PROCESSING_REVERSE_AUCTION_BID';
export const PROCESSING_REVERSE_AUCTION_BID_FAIL =
    'reverseAuctions/PROCESSING_REVERSE_AUCTION_BID_FAIL';

export const submitReverseAuctionBid = (proposalId, data) => {
    const { vendorPriceItemId } = data;

    return resourceManager({
        method: 'post',
        url: `/proposal/${proposalId}/reverse-auction/bid`,
        requestOptions: { data },
        onStart: ({ dispatch }) => {
            return (
                dispatch({ type: PROCESSING_REVERSE_AUCTION_BID, vendorPriceItemId }),
                dispatch(hideConfirmationModal())
            );
        },
        onFailure: ({ error, dispatch }) =>
            dispatch({
                error,
                type: PROCESSING_REVERSE_AUCTION_BID_FAIL,
                vendorPriceItemId,
            }),
    });
};

export const DELETE_PROXY_BID_FAIL = 'reverseAuctions/DELETE_PROXY_BID_FAIL';
export const DELETE_PROXY_BID_SUCCESS = 'reverseAuctions/DELETE_PROXY_BID_SUCCESS';

export const deleteProxyBid = (proposalId, proxyBidId, description) => {
    return resourceManager({
        method: 'del',
        url: `/proposal/${proposalId}/reverse-auction/proxy-bid/${proxyBidId}`,
        onSuccess: ({ result, dispatch }) => {
            dispatch(hideConfirmationModal());
            dispatch(
                emitReverseAuctionSocket(
                    null,
                    { type: DELETE_PROXY_BID_SUCCESS, result },
                    `Update Received: Proxy bid for ${description} was deleted`
                )
            );
        },
        onFailure: ({ error, dispatch }) => {
            dispatch(hideConfirmationModal());
            dispatch({ type: DELETE_PROXY_BID_FAIL, error, proxyBidId });
        },
    });
};

exports.CANADA_COUNTRY_CODE = 'CA';

exports.UNITED_STATES_COUNTRY_CODE = 'US';
exports.UNITED_STATES_PHONE_COUNTRY_CODE = '1';

exports.canadianProvinces = [
    'Alberta',
    'British Columbia',
    'Manitoba',
    'New Brunswick',
    'Newfoundland and Labrador',
    'Northwest Territories',
    'Nova Scotia',
    'Nunavut',
    'Ontario',
    'Prince Edward Island',
    'Quebec',
    'Saskatchewan',
    'Yukon',
];

exports.statePostalAbbreviations = [
    { name: 'Alabama', postalAbbreviation: 'AL' },
    { name: 'Alaska', postalAbbreviation: 'AK' },
    { name: 'Arizona', postalAbbreviation: 'AZ' },
    { name: 'Arkansas', postalAbbreviation: 'AR' },
    { name: 'California', postalAbbreviation: 'CA' },
    { name: 'Colorado', postalAbbreviation: 'CO' },
    { name: 'Connecticut', postalAbbreviation: 'CT' },
    { name: 'Delaware', postalAbbreviation: 'DE' },
    { name: 'District of Columbia', postalAbbreviation: 'DC' },
    { name: 'Florida', postalAbbreviation: 'FL' },
    { name: 'Georgia', postalAbbreviation: 'GA' },
    { name: 'Hawaii', postalAbbreviation: 'HI' },
    { name: 'Idaho', postalAbbreviation: 'ID' },
    { name: 'Illinois', postalAbbreviation: 'IL' },
    { name: 'Indiana', postalAbbreviation: 'IN' },
    { name: 'Iowa', postalAbbreviation: 'IA' },
    { name: 'Kansas', postalAbbreviation: 'KS' },
    { name: 'Kentucky', postalAbbreviation: 'KY' },
    { name: 'Louisiana', postalAbbreviation: 'LA' },
    { name: 'Maine', postalAbbreviation: 'ME' },
    { name: 'Maryland', postalAbbreviation: 'MD' },
    { name: 'Massachusetts', postalAbbreviation: 'MA' },
    { name: 'Michigan', postalAbbreviation: 'MI' },
    { name: 'Minnesota', postalAbbreviation: 'MN' },
    { name: 'Mississippi', postalAbbreviation: 'MS' },
    { name: 'Missouri', postalAbbreviation: 'MO' },
    { name: 'Montana', postalAbbreviation: 'MT' },
    { name: 'Nebraska', postalAbbreviation: 'NE' },
    { name: 'Nevada', postalAbbreviation: 'NV' },
    { name: 'New Hampshire', postalAbbreviation: 'NH' },
    { name: 'New Jersey', postalAbbreviation: 'NJ' },
    { name: 'New Mexico', postalAbbreviation: 'NM' },
    { name: 'New York', postalAbbreviation: 'NY' },
    { name: 'North Carolina', postalAbbreviation: 'NC' },
    { name: 'North Dakota', postalAbbreviation: 'ND' },
    { name: 'Ohio', postalAbbreviation: 'OH' },
    { name: 'Oklahoma', postalAbbreviation: 'OK' },
    { name: 'Oregon', postalAbbreviation: 'OR' },
    { name: 'Pennsylvania', postalAbbreviation: 'PA' },
    { name: 'Rhode Island', postalAbbreviation: 'RI' },
    { name: 'South Carolina', postalAbbreviation: 'SC' },
    { name: 'South Dakota', postalAbbreviation: 'SD' },
    { name: 'Tennessee', postalAbbreviation: 'TN' },
    { name: 'Texas', postalAbbreviation: 'TX' },
    { name: 'Utah', postalAbbreviation: 'UT' },
    { name: 'Vermont', postalAbbreviation: 'VT' },
    { name: 'Virginia', postalAbbreviation: 'VA' },
    { name: 'Washington', postalAbbreviation: 'WA' },
    { name: 'West Virginia', postalAbbreviation: 'WV' },
    { name: 'Wisconsin', postalAbbreviation: 'WI' },
    { name: 'Wyoming', postalAbbreviation: 'WY' },
];

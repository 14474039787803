import { fieldNames, OTHER } from './constants';

const { DISQUALIFIED_REASON, DISQUALIFIED_REASON_EXTRA } = fieldNames;

export function validate(values) {
    const errors = {};

    if (!values[DISQUALIFIED_REASON]) {
        errors[DISQUALIFIED_REASON] = 'Please select an option';
    } else if (values[DISQUALIFIED_REASON] === OTHER) {
        if (!values[DISQUALIFIED_REASON_EXTRA]) {
            errors[DISQUALIFIED_REASON_EXTRA] =
                'Please indicate why the response is being disqualified';
        } else if (values[DISQUALIFIED_REASON_EXTRA].length > 2040) {
            errors[DISQUALIFIED_REASON_EXTRA] = 'Reason is too long (2000 character limit)';
        }
    }

    return errors;
}

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { BudgetCheck } from '../../../../FormComponents/PurchaseDetails/BudgetCheck';
import { getIsRequisitionUsingFMS } from '../../../../../../../../selectors/govApp';
import { currencyFormatter } from '../../../../../../../../helpers';

export const AccountSplitReview = ({ fieldName, priceItem }) => {
    const styles = require('./index.scss');
    const hasFMS = useSelector(getIsRequisitionUsingFMS);

    const getAccountNumberToDisplay = (accountSplitPriceItem) => {
        if (accountSplitPriceItem.accountObject?.accountCompressedFormatted) {
            return accountSplitPriceItem.accountObject.accountCompressedFormatted;
        }

        return accountSplitPriceItem.accountNumber;
    };

    return (
        <div className={styles.accountSplitReview}>
            <div className={`row hidden-xs hidden-sm ${styles.header}`}>
                {hasFMS ? (
                    <>
                        <h5 className="col-md-4">Budget Check</h5>
                        <h5 className="col-md-4">Account</h5>
                    </>
                ) : (
                    <h5 className="col-md-8">Account</h5>
                )}
                <h5 className="col-md-4 text-right">Amount</h5>
            </div>
            {priceItem.accountSplitPriceItems.map((accountSplitPriceItem) => (
                <div
                    className={classNames('row', styles.accounts)}
                    key={accountSplitPriceItem.price_item_id}
                >
                    {hasFMS && (
                        <div className="col-md-4">
                            <BudgetCheck field={accountSplitPriceItem} fieldName={fieldName} />
                        </div>
                    )}
                    <div className={hasFMS ? 'col-md-4' : 'col-md-8'}>
                        {hasFMS ? (
                            <>
                                <div className={styles.accountNumber}>
                                    {getAccountNumberToDisplay(accountSplitPriceItem)}
                                </div>
                                <div>{accountSplitPriceItem.accountObject?.description}</div>
                            </>
                        ) : (
                            <div className={styles.accountNumber}>
                                {accountSplitPriceItem.accountNumber}
                            </div>
                        )}
                    </div>
                    <div className={`col-md-4 text-right ${styles.amount}`}>
                        {currencyFormatter({ value: accountSplitPriceItem.amount })}
                    </div>
                </div>
            ))}
        </div>
    );
};

AccountSplitReview.propTypes = {
    fieldName: PropTypes.string,
    priceItem: PropTypes.object,
};

export const validate = (values, props) => {
    const errors = {};
    const APPENDIX_LIMIT = 32;
    const TITLE_LIMIT = 180;

    if (props.hideAppendixLetter) {
        // Don't need to validate `appendixId`
    } else if (!values.appendixId) {
        errors.appendixId = 'Please select an attachment letter';
    } else if (values.appendixId.length > APPENDIX_LIMIT) {
        errors.appendixId = `Attachment letter must be less than ${APPENDIX_LIMIT} characters`;
    }

    if (!values.title) {
        errors.title = 'Please enter a title for the attachment';
    } else if (values.title.length > TITLE_LIMIT) {
        errors.title = `Title must be less than ${TITLE_LIMIT} characters`;
    }

    return errors;
};

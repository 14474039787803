import { createSelector } from 'reselect';

import { proposalStatusesDict } from '@og-pro/shared-config/proposals';

import { uploadObject, attachmentObject, mapProposalDocuments } from '../../helpers';
import { generateCompleteProposal, getPublicProjectJS } from '../../selectors';

const { DRAFT, REVIEW } = proposalStatusesDict;

const getProposal = (state) => state.vendProposals.get('proposal');

export const getVendorPath = (_state, props) => {
    const { vendorId } = props.params;
    return `/vendors/${vendorId}`;
};
export const getProposalReviewPath = (_state, props) => {
    const { vendorId, proposalId } = props.params;
    return `/vendors/${vendorId}/proposals/${proposalId}`;
};
export const getProposalProjectPath = (_state, props) => {
    const { vendorId, proposalId, projectId } = props.params;
    return `/vendors/${vendorId}/proposals/${proposalId}/projects/${projectId}`;
};

export const getProposalJS = createSelector([getProposal], (rawProposal) => {
    if (rawProposal) {
        return rawProposal.toJS();
    }
});

const getProposalUploadObject = createSelector([getProposalJS], (proposal) => {
    if (proposal) {
        return (proposal.uploads || []).reduce(uploadObject, {});
    }
    return {};
});

const getProposalAttachmentObject = createSelector([getProposalJS], (proposal) => {
    if (proposal) {
        return proposal.attachments.reduce(attachmentObject, {});
    }
    return {};
});

export const getProposalDocuments = createSelector(
    [getPublicProjectJS, getProposalAttachmentObject, getProposalUploadObject],
    (project, attachmentObj, uploadObj) => {
        if (project) {
            return mapProposalDocuments(project.proposalDocuments, attachmentObj, uploadObj);
        }
        return [];
    }
);

export const getMappedProposalJS = createSelector(
    [getProposalJS, getPublicProjectJS, getProposalDocuments],
    generateCompleteProposal
);

export const isProposalEditable = createSelector(
    [getProposalJS, getProposalDocuments],
    (proposal) => {
        if (proposal) {
            return proposal.status === DRAFT || proposal.status === REVIEW;
        }
        return false;
    }
);

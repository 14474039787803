import { redirect } from 'react-router-dom';

import { userRolePermissions } from '@og-pro/shared-config/userRoles';

const { IS_SYSTEM_ADMIN } = userRolePermissions;

/*
 * Redirects a user to the admin page if they are a system admin and have a government id.
 * Otherwise we take them to the login page (where they will be redirected to where they belong)
 */
export default (getState) => async () => {
    const state = getState();
    const user = state.auth.get('user');

    const isSystemAdmin = state.auth.getIn(['user', 'userPermissions', IS_SYSTEM_ADMIN]);

    if (user?.get('government_id') && isSystemAdmin) {
        return redirect(`/governments/${user?.get('government_id')}/admin`);
    }

    return redirect('/login');
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { Button, LoadingSpinner, LoadingError } from '../../../components';
import { hideApplyModal } from '../../../actions/publicProject';

const mapStateToProps = (state) => {
    return {
        showModal: state.publicProject.get('showApplyModal'),
        applying: state.publicProject.get('applying'),
        creatingProposal: state.publicProject.get('creatingProposal'),
        error: state.publicProject.get('applyError'),
        appliedRoute: state.publicProject.get('appliedRoute'),
    };
};

const mapDispatchToProps = {
    hideModal: hideApplyModal,
};

// @connect
class ConnectedApplyModal extends Component {
    static propTypes = {
        showModal: PropTypes.bool.isRequired,
        applying: PropTypes.bool.isRequired,
        creatingProposal: PropTypes.bool.isRequired,
        error: PropTypes.string,
        appliedRoute: PropTypes.string,
        hideModal: PropTypes.func.isRequired,
        router: PropTypes.object.isRequired,
    };

    get styles() {
        return require('./ApplyModal.scss');
    }

    viewProposal = () => {
        this.props.hideModal();
        return this.props.router.push(this.props.appliedRoute);
    };

    renderLoading() {
        const text = this.props.creatingProposal ? 'Creating Response...' : 'Verifying Project...';

        return (
            <div>
                <LoadingSpinner className={this.styles.loadingContainer} noPadding />
                <h4 className={this.styles.title}>{text}</h4>
            </div>
        );
    }

    renderError() {
        return (
            <div>
                <LoadingError error={this.props.error} noOffset />
                <div className="text-center">
                    <Button onClick={this.props.hideModal}>Close</Button>
                </div>
            </div>
        );
    }

    renderAlreadyApplied() {
        return (
            <div className="text-center">
                <div className={this.styles.alreadyAppliedIcon}>
                    <i className="fa fa-3x fa-check-square-o text-success" />
                </div>
                <div className={`text-muted ${this.styles.alreadyApplied}`}>
                    Your organization has already started a response for this project
                </div>
                <Button bsSize="lg" bsStyle="success" onClick={this.viewProposal}>
                    View Response
                </Button>
            </div>
        );
    }

    renderModalBody() {
        if (this.props.applying) return this.renderLoading();
        if (this.props.error) return this.renderError();
        if (this.props.appliedRoute) return this.renderAlreadyApplied();
        return null;
    }

    render() {
        const { showModal, hideModal } = this.props;
        return (
            <Modal bsSize="sm" onHide={hideModal} show={showModal}>
                <Modal.Body className={this.styles.modalBody}>{this.renderModalBody()}</Modal.Body>
            </Modal>
        );
    }
}

export const ApplyModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ConnectedApplyModal));

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { reduxForm, Field } from 'redux-form';

import { attachmentTypesDict } from '@og-pro/shared-config/attachments';

import { form, fieldNames } from './constants';
import { validate } from './validate';
import { AttachmentUploader, Button, Checkbox, InputText } from '../../../..';
import { QuestionFormHasErrorsField } from './QuestionFormHasErrorsField';

const {
    IS_SUBSTITUTION_REQUEST,
    SUBJECT,
    DESCRIPTION,
    SUBSTITUTION_ATTACHMENTS,
    QUESTION_FORM_HAS_ERRORS,
} = fieldNames;
const { SUBSTITUTION_ATTACHMENT } = attachmentTypesDict;

const formConfig = {
    form,
    validate,
};

// @reduxForm
class ConnectedQuestionForm extends PureComponent {
    static propTypes = {
        allowSubstitutionRequests: PropTypes.bool.isRequired,
        change: PropTypes.func, // from redux-form
        closeForm: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        posting: PropTypes.bool,
        projectId: PropTypes.number,
        submitError: PropTypes.string,
        substitutionRequestFormUrl: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.state = {
            showSubstitutionRequestForm: false,
            substitutionAttachments: [],
        };
    }

    get styles() {
        return require('../shared.scss');
    }

    substitutionAttachmentsDeleteHandler = (file) => {
        this.setState(
            (prevState) => {
                const updatedAttachments = prevState.substitutionAttachments.filter(
                    (attachment) => {
                        return attachment.path !== file.path;
                    }
                );
                return {
                    substitutionAttachments: updatedAttachments,
                };
            },
            () => {
                this.props.change(SUBSTITUTION_ATTACHMENTS, this.state.substitutionAttachments);
            }
        );
    };

    substitutionAttachmentsUploadHandler = (file) => {
        this.setState(
            (prevState) => {
                return {
                    substitutionAttachments: [...prevState.substitutionAttachments, file],
                };
            },
            () => {
                this.props.change(SUBSTITUTION_ATTACHMENTS, this.state.substitutionAttachments);
            }
        );
    };

    substitutionRequestCheckboxHandler = (checked) => {
        const { change } = this.props;

        this.setState({ showSubstitutionRequestForm: checked });

        if (checked) {
            change(SUBJECT, 'Request for Substitution');
        } else {
            change(SUBJECT, '');
        }
    };

    renderButtonText() {
        const { posting } = this.props;
        const { showSubstitutionRequestForm } = this.state;

        if (posting) {
            return 'Submitting...';
        }

        if (showSubstitutionRequestForm) {
            return 'Submit Request for Substitution';
        }

        return 'Submit Question';
    }

    render() {
        const {
            allowSubstitutionRequests,
            closeForm,
            handleSubmit,
            posting,
            projectId,
            submitError,
            substitutionRequestFormUrl,
        } = this.props;
        const { showSubstitutionRequestForm, substitutionAttachments } = this.state;

        const attachmentUploadData = { type: SUBSTITUTION_ATTACHMENT };

        const icon = posting ? 'fa-spinner fa-spin' : 'fa-send';
        return (
            <form onSubmit={handleSubmit}>
                <div className="text-right">
                    <Button
                        bsSize="sm"
                        bsStyle="link"
                        className={this.styles.closeQuestionFormButton}
                        onClick={closeForm}
                        qaTag="addQuestionForm-close"
                    >
                        Close <i className="fa fa-close" />
                    </Button>
                </div>
                {allowSubstitutionRequests && substitutionRequestFormUrl && (
                    <Field
                        component={Checkbox}
                        helpIcon
                        helpIconNode="Use this option to inquire about approval if you need to substitute an alternate (ex. brand or methodology) in your proposal."
                        inline
                        label="Request for Substitution?"
                        name={IS_SUBSTITUTION_REQUEST}
                        onChange={this.substitutionRequestCheckboxHandler}
                        qaTag="addQuestionForm-requestForSubstitution"
                    />
                )}
                <Field
                    component={InputText}
                    disabled={showSubstitutionRequestForm || posting}
                    hasFeedback={false}
                    label={showSubstitutionRequestForm ? 'Subject' : 'Subject (optional)'}
                    name={SUBJECT}
                    placeholder={
                        showSubstitutionRequestForm
                            ? 'Request for Substitution'
                            : 'Add a subject (optional)'
                    }
                    qaTag="addQuestionForm-subject"
                    type="text"
                />
                <Field
                    component={InputText}
                    disabled={posting}
                    hasFeedback={false}
                    label={showSubstitutionRequestForm ? 'Description' : 'Question'}
                    minRows={4}
                    name={DESCRIPTION}
                    placeholder={
                        showSubstitutionRequestForm
                            ? 'Describe your request'
                            : 'Enter your question'
                    }
                    qaTag="addQuestionForm-question"
                    type="textarea"
                />
                {showSubstitutionRequestForm && (
                    <div className={this.styles.formLinkDiv}>
                        Please complete and attach this{' '}
                        <a
                            href={substitutionRequestFormUrl}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            Request for Substitution form
                        </a>{' '}
                        using the uploader below. You may upload additional documents to support
                        your request if necessary.
                    </div>
                )}
                {showSubstitutionRequestForm && (
                    <AttachmentUploader
                        attachmentUploadData={attachmentUploadData}
                        attachments={substitutionAttachments}
                        deleteHandler={this.substitutionAttachmentsDeleteHandler}
                        label="Substitution Request"
                        onSuccess={this.substitutionAttachmentsUploadHandler}
                        s3GetUrl={`/project/${projectId}/question/s3`}
                        skipForm
                    />
                )}
                {submitError && <p className="text-danger">{submitError}</p>}
                <Field component={QuestionFormHasErrorsField} name={QUESTION_FORM_HAS_ERRORS} />
                <div>
                    <Button
                        block
                        bsStyle="primary"
                        disabled={posting}
                        qaTag="addQuestionForm-submit"
                        type="submit"
                    >
                        <i className={`fa ${icon}`} />
                        &nbsp;
                        {this.renderButtonText()}
                    </Button>
                </div>
            </form>
        );
    }
}

export const QuestionForm = reduxForm(formConfig)(ConnectedQuestionForm);

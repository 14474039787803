import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { approvalStatusesDict } from '@og-pro/shared-config/approval';

import { userStatusTypes } from '@og-pro/shared-config/users';

import { ApprovalStatus, ApprovalViewStatus, RemindButton } from '../../../components';
import { Button, UserProfilePicture } from '../../../../..';
import { isDocumentEditor } from '../../../../../../containers/GovApp/selectors';

const { APPROVED } = approvalStatusesDict;
const { DISABLED } = userStatusTypes;

export const ApprovalReviewStepUser = ({
    activeReviewStep,
    approval: { comment, remindError, removeError, removing, status, user },
    approval,
    inDraft,
    isApprover,
    isCompletedReviewStep,
    isRejected,
    isViewOnly,
    reminderHandler,
    removeApproverHandler,
    reviewStep,
    showModal,
}) => {
    const [showComment, setShowComment] = useState(false);
    const isEditor = useSelector((state) => isDocumentEditor(state));

    const styles = require('./index.scss');

    const isActiveReviewStep = reviewStep.orderById === activeReviewStep?.orderById;

    const handleApproveClick = () => {
        showModal('approve', approval);
    };

    const handleEditApprovalDecisionClick = () => {
        showModal('edit', approval);
    };

    const handleMarkedApprovedClick = () => {
        showModal('markApproved', approval);
    };

    const handleRejectClick = () => {
        showModal('reject', approval);
    };

    const handleRemoveClick = () => {
        const { id: approvalId, project_approval_step_id: projectReviewStepId } = approval;
        removeApproverHandler(approvalId, projectReviewStepId);
    };

    const handleResendApprovalClick = () => {
        showModal('resend', approval);
    };

    const handleShowCommentsClick = () => {
        setShowComment(!showComment);
    };

    const renderShowComment = () => {
        if (!approval.comment) return null;

        const icon = showComment ? 'fa-file-text-o' : 'fa-file-text';

        return (
            <i
                className={`fa ${icon} text-primary ${styles.commentIcon}`}
                onClick={handleShowCommentsClick}
            />
        );
    };

    const renderEmailButton = () => {
        if (isViewOnly) return null;

        if (isApprover) {
            if (isRejected) {
                return (
                    <Button
                        bsSize="sm"
                        bsStyle="primary"
                        onClick={handleEditApprovalDecisionClick}
                        qaTag="approvalsDashboard-editApprovalDecision"
                    >
                        Edit Approval Decision
                    </Button>
                );
            }

            return (
                <span>
                    <Button
                        bsSize="sm"
                        bsStyle="success"
                        className={styles.approveButton}
                        onClick={handleApproveClick}
                        qaTag="approvalsDashboard-markApproved"
                    >
                        <i className="fa fa-check" /> Approve
                    </Button>
                    <Button
                        bsSize="sm"
                        bsStyle="danger"
                        onClick={handleRejectClick}
                        qaTag="approvalsDashboard-markRejected"
                    >
                        <i className="fa fa-times" /> Reject
                    </Button>
                </span>
            );
        }

        return (
            <RemindButton
                approval={approval}
                isActiveReviewStep={isActiveReviewStep}
                isRejected={isRejected}
                reminderHandler={reminderHandler}
                reviewStepId={reviewStep.id}
                showModal={showModal}
            />
        );
    };

    const renderApproveButton = () => {
        if (isViewOnly || isApprover) return null;

        return (
            <Button
                bsSize="sm"
                disabled={!isActiveReviewStep}
                onClick={handleMarkedApprovedClick}
                qaTag="approvalsDashboard-markApproved"
            >
                <span className="text-success">
                    <i className="fa fa-check-square-o" /> Mark Approved
                </span>
            </Button>
        );
    };

    const renderRemoveButton = () => {
        if (user.status === DISABLED && approval.status === APPROVED) {
            return null;
        }

        if (isViewOnly) {
            if (!isEditor) {
                return null;
            }

            return (
                <Button
                    bsSize="sm"
                    onClick={handleResendApprovalClick}
                    qaTag="approvalsDashboard-resendApproval"
                >
                    <span className="text-warning">
                        <i className="fa fa-history" /> Resend Approval
                    </span>
                </Button>
            );
        }

        return (
            <Button
                bsSize="sm"
                disabled={removing}
                onClick={handleRemoveClick}
                qaTag="approvalsDashboard-removeApprover"
            >
                <span className="text-danger">
                    <i className="fa fa-ban text-danger" /> Remove&nbsp;
                    {isApprover ? 'Self' : 'Approver'}
                </span>
            </Button>
        );
    };

    const renderReviewerComment = () => {
        if (!comment || !showComment) return null;

        return (
            <div className={`col-xs-12 col-sm-10 col-sm-offset-1 well well-sm ${styles.comment}`}>
                <div className="text-info">
                    <strong>Approval Note:</strong>
                </div>
                {comment}
            </div>
        );
    };

    const renderErrors = () => {
        if (!removeError && !remindError) return null;

        const errors = [removeError, remindError].filter((error) => error);

        return errors.map((error) => (
            <div className={`col-xs-12 text-danger ${styles.error}`}>{error}</div>
        ));
    };

    return (
        <div className={`row ${classnames(styles.container, styles.approvalsItem)}`}>
            <div className={`col-xs-12 col-md-3 ${styles.approver}`}>
                <UserProfilePicture className={styles.profilePicture} user={user} />
                {user.displayName}
                {user.status === DISABLED && ' (Deactivated)'}
                {renderShowComment()}
            </div>
            <div className="col-xs-12 col-sm-2 col-md-2 text-center">
                {!inDraft && (isActiveReviewStep || isCompletedReviewStep) && (
                    <ApprovalStatus status={status} />
                )}
            </div>
            <div className={`col-xs-12 col-sm-4 col-md-3 ${styles.viewed}`}>
                {!inDraft &&
                    (isActiveReviewStep || isCompletedReviewStep) &&
                    (approval.status === APPROVED || user.status !== DISABLED) && (
                        <>
                            <div className={styles.emailButton}>{renderEmailButton()}</div>
                            <ApprovalViewStatus
                                approval={approval}
                                isApprover={isApprover}
                                isRejected={isRejected}
                                isViewOnly={isViewOnly}
                            />
                        </>
                    )}
            </div>
            <div className="col-xs-6 col-sm-3 col-md-2 text-center">
                {!inDraft &&
                    (isActiveReviewStep || isCompletedReviewStep) &&
                    user.status !== DISABLED &&
                    renderApproveButton()}
            </div>
            <div
                className={classnames(
                    !inDraft && isActiveReviewStep
                        ? `col-xs-6 col-sm-3 col-md-2 text-center`
                        : `col-xs-12 col-md-2 text-center`
                )}
            >
                {reviewStep.orderById >= activeReviewStep?.orderById &&
                    reviewStep.projectApprovals.length > 1 &&
                    renderRemoveButton()}
            </div>
            {renderReviewerComment()}
            {renderErrors()}
        </div>
    );
};

ApprovalReviewStepUser.propTypes = {
    activeReviewStep: PropTypes.object,
    approval: PropTypes.object.isRequired,
    inDraft: PropTypes.bool.isRequired,
    isApprover: PropTypes.bool.isRequired,
    isCompletedReviewStep: PropTypes.bool.isRequired,
    isViewOnly: PropTypes.bool.isRequired,
    isRejected: PropTypes.bool.isRequired,
    reminderHandler: PropTypes.func.isRequired,
    removeApproverHandler: PropTypes.func.isRequired,
    reviewStep: PropTypes.object.isRequired,
    showModal: PropTypes.func.isRequired,
};

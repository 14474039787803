import React from 'react';

export const VerifiedIcon = () => (
    <svg fill="none" height="15" viewBox="0 0 15 15" width="15" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.7157 7.12654L13.0836 5.26701L13.311 2.80547L10.8963 2.25697L9.63211 0.129883L7.35786 1.10647L5.08361 0.129883L3.8194 2.25697L1.40468 2.79878L1.63211 5.26032L0 7.12654L1.63211 8.98607L1.40468 11.4543L3.8194 12.0028L5.08361 14.1299L7.35786 13.1466L9.63211 14.1232L10.8963 11.9961L13.311 11.4476L13.0836 8.98607L14.7157 7.12654ZM6.02007 10.471L3.34448 7.79543L4.28762 6.85229L6.02007 8.57804L10.4281 4.17002L11.3712 5.11985L6.02007 10.471Z"
            fill="#6B477A"
        />
    </svg>
);

import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '../../../..';

export const CompleteReview = ({ disabled, readyText, saveHandler, setLiveHandler }) => {
    const styles = require('./index.scss');

    return (
        <div className="text-center">
            <h4>You&#39;re ready to go</h4>
            <p className={`text-muted ${styles.completeText}`}>{readyText}</p>
            <div className={styles.completeButton}>
                <Button
                    bsStyle="success"
                    disabled={disabled}
                    onClick={setLiveHandler}
                    qaTag="templateReviewSection-setLive"
                >
                    Set Live
                </Button>
            </div>
            <div>
                or&nbsp;
                <Button
                    bsStyle="link"
                    className={styles.saveButton}
                    disabled={disabled}
                    onClick={saveHandler}
                    qaTag="templateReviewSection-saveAndClose"
                >
                    save and close
                </Button>
            </div>
        </div>
    );
};

CompleteReview.propTypes = {
    disabled: PropTypes.bool,
    readyText: PropTypes.string.isRequired,
    saveHandler: PropTypes.func.isRequired,
    setLiveHandler: PropTypes.func.isRequired,
};

import { fromJS, Map } from 'immutable';

import * as analyticsActions from '../actions/analytics';

const initialState = new Map({
    loadingAggregate: false,
    loadedAggregate: false,
});

export default function analyticsReducer(state = initialState, action = {}) {
    switch (action.type) {
        case analyticsActions.LOAD_AGGREGATE:
            return state.merge(
                fromJS({
                    loadingAggregate: true,
                    loadedAggregate: false,
                    loadAggregateError: null,
                })
            );
        case analyticsActions.LOAD_AGGREGATE_SUCCESS:
            return state.merge(
                fromJS({
                    aggregateEndDate: action.result.endDate,
                    aggregateProjects: fromJS(action.result.data.projects),
                    aggregateStartDate: action.result.startDate,
                    cycleTimes: fromJS(action.result.data.cycleTimes),
                    loadedAggregate: true,
                    loadingAggregate: false,
                    workloadWeights: fromJS(action.result.data.workloadWeights),
                })
            );
        case analyticsActions.LOAD_AGGREGATE_FAIL:
            return state.merge(
                fromJS({
                    loadingAggregate: false,
                    loadedAggregate: false,
                    loadAggregateError: action.error && action.error.message,
                })
            );
        default:
            return state;
    }
}

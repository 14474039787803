const { listToDict } = require('../helpers');

exports.tagTypes = [
    'contact',
    'contract',
    'contractAttachment',
    'contractInsurance',
    'deliveryCode',
    'fiscalYear',
    'fundingSource',
    'paymentTerms',
    'procurementClassification',
];

exports.tagTypesDict = listToDict(exports.tagTypes);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';

import { editForm as form, fieldNames } from '../constants';
import { validate } from '../validate';
import { Button, InputText, SearchSelect, SearchSelectUserOption } from '../../../../components';
import { updateDepartment } from '../../../../actions/admin';

const { CONTACT_ID, DEPARTMENT_HEAD, DEPARTMENT_HEAD_TITLE, NAME } = fieldNames;

const mapDispatchToProps = {
    updateDepartment,
};

const formConfig = {
    form,
    validate,
};

// @connect
// @reduxForm
class ConnectedDepartmentEditForm extends Component {
    static propTypes = {
        cancelEditDepartment: PropTypes.func.isRequired,
        department: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }).isRequired,
        handleSubmit: PropTypes.func.isRequired,
        organizationId: PropTypes.number.isRequired,
        pristine: PropTypes.bool.isRequired,
        reset: PropTypes.func.isRequired,
        updateDepartment: PropTypes.func.isRequired,
        users: PropTypes.array.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            updateError: null,
            updating: false,
        };
    }

    get styles() {
        return require('../index.scss');
    }

    handleCancelClick = () => {
        const { cancelEditDepartment, reset } = this.props;

        reset();
        cancelEditDepartment();
    };

    handleSubmit = (data) => {
        const { cancelEditDepartment, department, organizationId } = this.props;

        this.setState({ updating: true, updateError: null });
        return this.props
            .updateDepartment(organizationId, department.id, data)
            .then(() => {
                this.setState({ updating: false });
                cancelEditDepartment();
            })
            .catch((error) => {
                this.setState({ updating: false, updateError: error.message });
            });
    };

    render() {
        const { handleSubmit, pristine, users } = this.props;

        const { updateError, updating } = this.state;

        return (
            <div className={`row ${this.styles.deptListRow}`}>
                <div className={`col-xs-12 col-md-3 ${this.styles.deptCol}`}>
                    <Field
                        aria-label="Edit Department Name"
                        autoFocus
                        component={InputText}
                        disabled={updating}
                        hasFeedback={false}
                        name={NAME}
                        type="text"
                    />
                </div>
                <div className={`col-xs-12 col-md-2 ${this.styles.deptCol}`}>
                    <Field
                        aria-label="Edit Department Head"
                        component={InputText}
                        disabled={updating}
                        hasFeedback={false}
                        name={DEPARTMENT_HEAD}
                        type="text"
                    />
                </div>
                <div className={`col-xs-12 col-md-2 ${this.styles.deptCol}`}>
                    <Field
                        aria-label="Edit Department Head Title"
                        component={InputText}
                        disabled={updating}
                        hasFeedback={false}
                        name={DEPARTMENT_HEAD_TITLE}
                        type="text"
                    />
                </div>
                <div className={`col-xs-12 col-md-2 ${this.styles.deptCol}`}>
                    <Field
                        aria-label="Edit Procurement Contact"
                        component={SearchSelect}
                        components={{
                            Option: SearchSelectUserOption,
                        }}
                        disabled={updating}
                        isClearable
                        name={CONTACT_ID}
                        options={users}
                        placeholder="Select User"
                    />
                </div>
                <div className={`col-xs-12 col-md-3 ${this.styles.deptCol}`}>
                    <Button
                        block
                        bsStyle="primary"
                        disabled={pristine || updating}
                        onClick={handleSubmit(this.handleSubmit)}
                    >
                        {updating ? 'Saving...' : 'Save'}
                    </Button>
                    <div>
                        <Button
                            block
                            bsSize="sm"
                            bsStyle="link"
                            className={this.styles.cancelButton}
                            disabled={updating}
                            onClick={this.handleCancelClick}
                        >
                            Cancel
                        </Button>
                    </div>
                    {updateError && <div className="error-block">{updateError}</div>}
                </div>
            </div>
        );
    }
}

export const DepartmentEditForm = compose(
    connect(null, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedDepartmentEditForm);

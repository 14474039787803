import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getVendorTotals } from '../selectors';
import { VendorTotalListItem } from './VendorTotalListItem';

export const VendorTotalSummary = ({ auctionMaxFractionDigits }) => {
    const styles = require('../index.scss');
    const vendorTotals = useSelector(getVendorTotals);

    const renderList = () => {
        if (vendorTotals.length === 0) {
            return <div className={`row ${styles.emptyList}`}>No bids have been placed.</div>;
        }
        return vendorTotals.map((vendorTotal, index) => (
            <VendorTotalListItem
                auctionMaxFractionDigits={auctionMaxFractionDigits}
                key={index}
                rank={vendorTotal.ranking}
                vendor={vendorTotal}
            />
        ));
    };

    return (
        <>
            <div className={`row ${styles.summaryContainer}`}>
                <div className={`col-lg-9 ${styles.summaryTitleText}`}>Vendor Total Summary</div>
            </div>
            {renderList()}
        </>
    );
};

VendorTotalSummary.propTypes = {
    auctionMaxFractionDigits: PropTypes.number.isRequired,
};

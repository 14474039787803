import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, FieldArray } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { extendSimpleWizardForm } from '../../../../hocs';
import { formConfig, fieldNames, progressSectionsDict, evaluationFieldNames } from '../constants';
import { AddEvaluatorsForm, EvaluatorsList } from './components';
import * as evaluationActions from '../../../../actions/evaluations';
import { Well, SectionTitle, Main } from '../../../../components';
import { mapStateToProps as evaluationCreateMapStateToProps } from '../mapProps';
import { getStagedEvaluators, getUsersSelectOptions } from './selectors';

const { EVALUATION } = fieldNames;
const { EVALUATORS } = evaluationFieldNames;

const mapStateToProps = (state) => {
    return {
        ...evaluationCreateMapStateToProps(state),
        stagedEvaluators: getStagedEvaluators(state),
        usersSelectOptions: getUsersSelectOptions(state),
    };
};

const mapDispatchToProps = evaluationActions;

// @connect
// @reduxForm
// @extendSimpleWizardForm
export class ConnectedEvaluators extends Component {
    static propTypes = {
        stagedEvaluators: PropTypes.arrayOf(
            PropTypes.shape({
                user: PropTypes.object.isRequired,
            })
        ),
        usersSelectOptions: PropTypes.array.isRequired,
        updating: PropTypes.bool.isRequired,
        showFormErrors: PropTypes.bool.isRequired,
        array: PropTypes.object.isRequired,
        addEvaluators: PropTypes.func.isRequired,
    };

    formKey = `${EVALUATION}.${EVALUATORS}`;

    get styles() {
        return require('./Evaluators.scss');
    }

    // Evaluators aren't actually saved on form submission. Rather they are added to the evaluation
    // create form values and will be saved the next time the evaluation create form is submmited.
    addEvaluators = () => {
        const { array, stagedEvaluators, addEvaluators } = this.props;
        if (stagedEvaluators) {
            stagedEvaluators.forEach((evaluator) => {
                array.push(this.formKey, evaluator.user);
            });
            addEvaluators();
        }
    };

    render() {
        return (
            <Main className="row">
                <div className="col-md-offset-1 col-md-10">
                    <SectionTitle
                        info="Add all colleagues that needs to evaluate the responses"
                        title="Response Evaluators"
                    />
                    <Well className={this.styles.form}>
                        <AddEvaluatorsForm
                            clickHandler={this.addEvaluators}
                            disabled={this.props.updating}
                            usersSelectOptions={this.props.usersSelectOptions}
                        />
                    </Well>
                    <FieldArray
                        component={EvaluatorsList}
                        disabled={this.props.updating}
                        name={this.formKey}
                        showFormErrors={this.props.showFormErrors}
                    />
                </div>
            </Main>
        );
    }
}

export const Evaluators = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig),
    extendSimpleWizardForm(progressSectionsDict.EVALUATORS)
)(ConnectedEvaluators);

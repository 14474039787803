import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { NavItem } from '../..';

export const GovNavItems = ({ governmentId, navItems }) => {
    return navItems.map(({ className, exactRoute, icon, isActive, path, title }) => (
        <NavItem
            className={classnames(className, isActive && 'active')}
            eventKey={path}
            key={title}
            overrideIsActive={false}
            qaTag={`navbar-${title}`}
            to={exactRoute || `/governments/${governmentId}/${path}`}
        >
            {!!icon && (
                <>
                    <i className={`fa fa-${icon}`} />
                    &nbsp;
                </>
            )}
            {title}
        </NavItem>
    ));
};

GovNavItems.propTypes = {
    governmentId: PropTypes.number.isRequired,
    navItems: PropTypes.arrayOf(
        PropTypes.shape({
            className: PropTypes.string,
            exactRoute: PropTypes.string,
            icon: PropTypes.string,
            isActive: PropTypes.bool,
            path: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
        })
    ).isRequired,
};

import { resourceManager } from '../helpers';

export const LOAD_EMERGENCY_CONTRACTS = 'emergencyPortal/LOAD_EMERGENCY_CONTRACTS';
export const LOAD_EMERGENCY_CONTRACTS_SUCCESS = 'emergencyPortal/LOAD_EMERGENCY_CONTRACTS_SUCCESS';
export const LOAD_EMERGENCY_CONTRACTS_FAIL = 'emergencyPortal/LOAD_EMERGENCY_CONTRACTS_FAIL';

export function shouldLoadContractsList(state) {
    const nextUpdateDate = state.emergencyPortal.get('contractsListUpdateDate');
    return !nextUpdateDate || new Date() > nextUpdateDate;
}

export function loadEmergencyContracts() {
    return resourceManager({
        method: 'get',
        url: '/emergency/contracts',
        onStart: ({ dispatch }) => dispatch({ type: LOAD_EMERGENCY_CONTRACTS }),
        onSuccess: ({ dispatch, result }) =>
            dispatch({ type: LOAD_EMERGENCY_CONTRACTS_SUCCESS, result }),
        onFailure: ({ dispatch, error }) =>
            dispatch({ type: LOAD_EMERGENCY_CONTRACTS_FAIL, error }),
    });
}

export const LOAD_EMERGENCY_PROJECTS = 'emergencyPortal/LOAD_EMERGENCY_PROJECTS';
export const LOAD_EMERGENCY_PROJECTS_SUCCESS = 'emergencyPortal/LOAD_EMERGENCY_PROJECTS_SUCCESS';
export const LOAD_EMERGENCY_PROJECTS_FAIL = 'emergencyPortal/LOAD_EMERGENCY_PROJECTS_FAIL';

export function shouldLoadProjectsList(state) {
    const nextUpdateDate = state.emergencyPortal.get('projectsListUpdateDate');
    return !nextUpdateDate || new Date() > nextUpdateDate;
}

export function loadEmergencyProjects() {
    return resourceManager({
        method: 'get',
        url: '/emergency/projects',
        onStart: ({ dispatch }) => dispatch({ type: LOAD_EMERGENCY_PROJECTS }),
        onSuccess: ({ dispatch, result }) =>
            dispatch({ type: LOAD_EMERGENCY_PROJECTS_SUCCESS, result }),
        onFailure: ({ dispatch, error }) => dispatch({ type: LOAD_EMERGENCY_PROJECTS_FAIL, error }),
    });
}

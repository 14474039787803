import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { getQuestionLogicMap } from '../../TemplateEdit/selectors';
import { showQuestionLogicModal } from '../../../../../actions/templatesAdmin';
import { Button } from '../../../../../components';

const mapStateToProps = (state) => {
    return {
        questionLogicMap: getQuestionLogicMap(state),
    };
};

const mapDispatchToProps = {
    showModal: showQuestionLogicModal,
};

// @connect
class ConnectedQuestionLogicIcon extends PureComponent {
    static propTypes = {
        entireSectionOptionOverrides: PropTypes.object,
        hidden: PropTypes.bool,
        includeText: PropTypes.bool,
        linkable: PropTypes.string.isRequired,
        linkableItem: PropTypes.shape({
            id: PropTypes.number,
            sharedId: PropTypes.string.isRequired,
        }).isRequired,
        projectSection: PropTypes.object.isRequired,
        questionLogicMap: PropTypes.object.isRequired,
        showModal: PropTypes.func.isRequired,
        usingFakeSection: PropTypes.bool,
    };

    get styles() {
        return require('./index.scss');
    }

    get questionLogic() {
        const { linkable, linkableItem, questionLogicMap } = this.props;

        return questionLogicMap[linkable][linkableItem.id || linkableItem.sharedId];
    }

    showQuestionLogicModal = (e) => {
        const {
            entireSectionOptionOverrides,
            linkable,
            linkableItem,
            projectSection,
            showModal,
            usingFakeSection,
        } = this.props;

        e.stopPropagation();

        const hasQuestionLogic = this.questionLogic;

        if (hasQuestionLogic) {
            const questionLogic = { ...hasQuestionLogic };

            if (usingFakeSection) {
                questionLogic.projectSection = projectSection;
                questionLogic.entireSectionOptionOverrides = entireSectionOptionOverrides;
                questionLogic.usingFakeSection = true;
            }

            showModal(questionLogic, hasQuestionLogic.editIndex);
            return;
        }

        showModal(
            {
                entireSectionOptionOverrides,
                linkable,
                linkableItem,
                linkable_id: linkableItem.id || linkableItem.sharedId,
                projectSection,
                usingFakeSection,
            },
            null,
            true
        );
    };

    render() {
        const { hidden, includeText } = this.props;

        if (hidden) {
            return null;
        }

        const isActive = !!this.questionLogic;

        return (
            <Button
                active={isActive}
                aria-label="Add Automation Logic"
                bsStyle={isActive ? undefined : 'link'}
                className={this.styles.button}
                onClick={this.showQuestionLogicModal}
                qaTag="questionLogicIcon-addAutomationLogic"
                tooltip="Add automation logic to item"
                tooltipPlacement="left"
            >
                <i className="fa fa-magic text-primary" /> {includeText ? 'Add Automation' : ''}
            </Button>
        );
    }
}

export const QuestionLogicIcon = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedQuestionLogicIcon);

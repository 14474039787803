import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';

export class Notification extends PureComponent {
    static propTypes = {
        show: PropTypes.bool,
        hide: PropTypes.func,
        text: PropTypes.string,
        type: PropTypes.oneOf(['success', 'danger', 'warning', 'info']),
        className: PropTypes.string,
    };

    render() {
        if (!this.props.show) return null;

        const { hide, text, type, className } = this.props;

        const styles = require('./Notification.scss');
        const alertClassName = className || styles.alert;
        return (
            <div className={styles.notification}>
                <Alert bsStyle={type} className={alertClassName} onDismiss={hide}>
                    <p>{text}</p>
                </Alert>
            </div>
        );
    }
}

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { ProjectList } from './ProjectList';
import { LibrarySearchForm } from './LibrarySearchForm';
import { PageTitle } from '../../../../../components';

export class LibrarySearch extends PureComponent {
    static propTypes = {
        categories: PropTypes.array.isRequired,
        projects: PropTypes.array.isRequired,
        projectsCount: PropTypes.number,
        paginationPage: PropTypes.number.isRequired,
        sortField: PropTypes.string.isRequired,
        sortDirection: PropTypes.string.isRequired,
        searchValue: PropTypes.string.isRequired,
        currentSearch: PropTypes.string,
        loaded: PropTypes.bool.isRequired,
        loading: PropTypes.bool.isRequired,
        loadError: PropTypes.string,
        projectClickHandler: PropTypes.func.isRequired,
        pageResults: PropTypes.func.isRequired,
        sortHandler: PropTypes.func.isRequired,
        sortDirectionHandler: PropTypes.func.isRequired,
        categoriesSelectHandler: PropTypes.func.isRequired,
        searchSubmitHandler: PropTypes.func.isRequired,
    };

    render() {
        const {
            categories,
            loaded,
            projects,
            projectClickHandler,
            loading,
            projectsCount,
            paginationPage,
            sortField,
            sortDirection,
            loadError,
            pageResults,
            sortHandler,
            sortDirectionHandler,
            searchValue,
            currentSearch,
            searchSubmitHandler,
            categoriesSelectHandler,
        } = this.props;
        return (
            <div className="row">
                <PageTitle title="Project Library" />
                <div className="col-sm-3">
                    <LibrarySearchForm
                        categoriesSelectHandler={categoriesSelectHandler}
                        disabled={loading}
                        initialValues={{
                            categories,
                            summarySearch: searchValue,
                        }}
                        onSubmit={searchSubmitHandler}
                        searchSubmitHandler={searchSubmitHandler}
                    />
                </div>
                <div className="col-sm-9">
                    <ProjectList
                        clickHander={projectClickHandler}
                        currentSearch={currentSearch}
                        error={loadError}
                        hasSearchSelection={loaded}
                        loading={loading}
                        pageResults={pageResults}
                        paginationPage={paginationPage}
                        projects={projects}
                        projectsCount={projectsCount}
                        sortDirection={sortDirection}
                        sortDirectionHandler={sortDirectionHandler}
                        sortField={sortField}
                        sortHandler={sortHandler}
                    />
                </div>
            </div>
        );
    }
}

import { createSelector } from 'reselect';

import { projectStatusesDict } from '@og-pro/shared-config/projects';
import { proposalEvaluationStatuses } from '@og-pro/shared-config/proposalEvaluations';

import { generateTimeBasedPieChartData } from './helpers';

const { DRAFT, CLOSED } = projectStatusesDict;

const { OPEN } = proposalEvaluationStatuses;

const getEvents = (state) => state.globalEditorDashboard.get('events');
const getProjects = (state) => state.globalEditorDashboard.get('projects');
const getProjectsInAwardPendingChartData = (state) =>
    state.globalEditorDashboard.get('projectsInAwardPending');
const getProjectsInEvaluationChartData = (state) =>
    state.globalEditorDashboard.get('projectsInEvaluation');
const getUnsubmittedScorecardProjects = (state) =>
    state.globalEditorDashboard.get('unsubmittedScorecardProjects');

export const getEventsJS = createSelector([getEvents], (rawEvents) => {
    if (rawEvents) {
        return rawEvents
            .toJS()
            .filter((event) => {
                const { eventType, projectStatus } = event;
                return (
                    projectStatus !== DRAFT &&
                    projectStatus !== CLOSED &&
                    (eventType === 'project' || eventType === 'timeline')
                );
            })
            .reduce((eventsList, event) => {
                if (event.allDay) {
                    eventsList.unshift(event);
                } else eventsList.push(event);
                return eventsList;
            }, []);
    }
    return [];
});

export const getProjectsJS = createSelector([getProjects], (rawProjects) => {
    if (rawProjects) {
        return rawProjects.toJS();
    }
    return [];
});

export const getUnsubmittedScorecardProjectsJS = createSelector(
    [getUnsubmittedScorecardProjects],
    (rawProjects) => {
        if (rawProjects) {
            return rawProjects.toJS().reduce((unsubmittedScorecardsProjects, project) => {
                let submittedScorecardsCount = 0;
                let unsubmittedScorecardsCount = 0;
                project.proposals.forEach((proposal) => {
                    proposal.proposalEvaluations.forEach((evaluation) => {
                        if (evaluation.status === OPEN) {
                            unsubmittedScorecardsCount++;
                        } else submittedScorecardsCount++;
                    });
                });
                if (unsubmittedScorecardsCount > 0) {
                    unsubmittedScorecardsProjects.push({
                        ...project,
                        submittedScorecardsCount,
                        unsubmittedScorecardsCount,
                    });
                }
                return unsubmittedScorecardsProjects;
            }, []);
        }
        return [];
    }
);

export const getProjectsInEvaluationChartDataJS = createSelector(
    [getProjectsInEvaluationChartData, (state, props) => props.router],
    (rawProjects, router) => {
        if (rawProjects) {
            const projects = rawProjects.toJS();
            if (projects.length > 0) {
                return generateTimeBasedPieChartData(projects, router);
            }
            return [];
        }
        return [];
    }
);

export const getProjectsInAwardPendingChartDataJS = createSelector(
    [getProjectsInAwardPendingChartData, (state, props) => props.router],
    (rawProjects, router) => {
        if (rawProjects) {
            const projects = rawProjects.toJS();
            if (projects.length > 0) {
                return generateTimeBasedPieChartData(projects, router);
            }
            return [];
        }
        return [];
    }
);

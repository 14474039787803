import { showSnackbar } from './notification';
import { showLoginModal, hideLoginModal } from './auth';
import { showConfirmationSimpleModal } from './confirmation';

export const LOAD_TIMELINES_WITH_RSVPS = 'rsvps/LOAD_TIMELINES_WITH_RSVPS';
export const LOAD_TIMELINES_WITH_RSVPS_FAIL = 'rsvps/LOAD_TIMELINES_WITH_RSVPS_FAIL';
export const LOAD_TIMELINES_WITH_RSVPS_SUCCESS = 'rsvps/LOAD_TIMELINES_WITH_RSVPS_SUCCESS';

export function loadTimelinesWithRsvps(projectId) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_TIMELINES_WITH_RSVPS });
        return client
            .get(`/project/${projectId}/rsvp`)
            .then((result) => {
                dispatch({ type: LOAD_TIMELINES_WITH_RSVPS_SUCCESS, result });
            })
            .catch((error) => dispatch({ type: LOAD_TIMELINES_WITH_RSVPS_FAIL, error }));
    };
}

export const CONFIRM_RSVP = 'rsvps/CONFIRM_RSVP';
export const CONFIRM_RSVP_FAIL = 'rsvps/CONFIRM_RSVP_FAIL';
export const CONFIRM_RSVP_SUCCESS = 'rsvps/CONFIRM_RSVP_SUCCESS';

export function confirmVendorRsvp(data) {
    const { attendanceConfirmed, projectId, rsvpId } = data;
    return (dispatch, getState, client) => {
        dispatch({ type: CONFIRM_RSVP });
        return client
            .put(`/project/${projectId}/rsvp/${rsvpId}/confirm`, { data: { attendanceConfirmed } })
            .then((result) => {
                dispatch({ type: CONFIRM_RSVP_SUCCESS, result });
                dispatch(showSnackbar('Vendor Attendance Updated!'));
            })
            .catch((error) => {
                dispatch({ type: CONFIRM_RSVP_FAIL, error });
                dispatch(showSnackbar(`Error: ${error.message}`, { isError: true }));
            });
    };
}

export const TOGGLE_RSVP_ENDED = 'rsvps/TOGGLE_RSVP_ENDED';
export const TOGGLE_RSVP_ENDED_FAIL = 'rsvps/TOGGLE_RSVP_ENDED_FAIL';
export const TOGGLE_RSVP_ENDED_SUCCESS = 'rsvps/TOGGLE_RSVP_ENDED_SUCCESS';

export function toggleRsvpEnded(projectId, data) {
    const { isRsvpEnded } = data;
    return (dispatch, getState, client) => {
        dispatch({ type: TOGGLE_RSVP_ENDED });
        return client
            .put(`/project/${projectId}/rsvp/`, { data })
            .then((result) => {
                dispatch({ type: TOGGLE_RSVP_ENDED_SUCCESS, result });
                dispatch(showSnackbar(`RSVP Period ${isRsvpEnded ? 'Closed' : 'Open'}`));
            })
            .catch((error) => {
                dispatch({ type: TOGGLE_RSVP_ENDED_FAIL, error });
                dispatch(showSnackbar(`Error: ${error.message}`, { isError: true }));
            });
    };
}

export const LOAD_VENDOR_RSVPS = 'rsvps/LOAD_VENDOR_RSVPS';
export const LOAD_VENDOR_RSVPS_FAIL = 'rsvps/LOAD_VENDOR_RSVPS_FAIL';
export const LOAD_VENDOR_RSVPS_SUCCESS = 'rsvps/LOAD_VENDOR_RSVPS_SUCCESS';

export function loadVendorRsvps() {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_VENDOR_RSVPS });
        return client
            .get('/user/me/rsvps')
            .then((result) => {
                dispatch({ type: LOAD_VENDOR_RSVPS_SUCCESS, result });
            })
            .catch((error) => {
                dispatch({ type: LOAD_VENDOR_RSVPS_FAIL, error });
                dispatch(showSnackbar(`Error: ${error.message}`, { isError: true }));
            });
    };
}

export const CREATE_GOV_ADDED_VENDOR_RSVP = '/rsvps/CREATE_GOV_ADDED_VENDOR_RSVP';
export const CREATE_GOV_ADDED_VENDOR_RSVP_FAIL = '/rsvps/CREATE_GOV_ADDED_VENDOR_RSVP_FAIL';
export const CREATE_GOV_ADDED_VENDOR_RSVP_SUCCESS = '/rsvps/CREATE_GOV_ADDED_VENDOR_RSVP_SUCCESS';
export const CREATE_VENDOR_RSVP = 'rsvps/CREATE_VENDOR_RSVP';
export const CREATE_VENDOR_RSVP_FAIL = 'rsvps/CREATE_VENDOR_RSVP_FAIL';
export const CREATE_VENDOR_RSVP_SUCCESS = 'rsvps/CREATE_VENDOR_RSVP_SUCCESS';
export const DELETE_GOV_ADDED_VENDOR_RSVP_SUCCESS = '/rsvps/DELETE_GOV_ADDED_VENDOR_RSVP_SUCCESS';
export const DELETE_VENDOR_RSVP = 'rsvps/DELETE_VENDOR_RSVP';
export const DELETE_VENDOR_RSVP_FAIL = 'rsvps/DELETE_VENDOR_RSVP_FAIL';
export const DELETE_VENDOR_RSVP_SUCCESS = 'rsvps/DELETE_VENDOR_RSVP_SUCCESS';

export function rsvpLoginHandler() {
    return (dispatch) => {
        function loginHandler(loggedinUser) {
            dispatch(hideLoginModal());

            // Notify gov users they cannot rsvp to events
            if (loggedinUser && !loggedinUser.isVendor) {
                return dispatch(
                    showConfirmationSimpleModal(() => {}, {
                        bsStyle: 'primary',
                        btnText: 'Okay',
                        hideCancelButton: true,
                        icon: 'check',
                        text: 'Only vendors can RSVP to events',
                        title: 'Event RSVP',
                    })
                );
            }

            // Get RSVP data for logged in vendor
            return dispatch(loadVendorRsvps());
        }

        return dispatch(
            showLoginModal(
                'You must be logged in as a vendor to RSVP to public meetings. ' +
                    'Please log in or create a vendor account.',
                loginHandler
            )
        );
    };
}

export function vendorRsvpHandler(projectId, rsvpId, data = {}) {
    return (dispatch, getState, client) => {
        if (rsvpId) {
            dispatch({ type: DELETE_VENDOR_RSVP });
            return client
                .del(`/project/${projectId}/rsvp/${rsvpId}`)
                .then((result) => {
                    dispatch({
                        type: data.govAddedVendorName
                            ? DELETE_GOV_ADDED_VENDOR_RSVP_SUCCESS
                            : DELETE_VENDOR_RSVP_SUCCESS,
                        result,
                    });
                    dispatch(showSnackbar('RSVP Removed'));
                })
                .catch((error) => {
                    dispatch({ type: DELETE_VENDOR_RSVP_FAIL, error });
                    dispatch(showSnackbar(`Error: ${error.message}`, { isError: true }));
                });
        }

        dispatch({ type: CREATE_VENDOR_RSVP });
        return client
            .post(`/project/${projectId}/rsvp`, { data })
            .then((result) => {
                dispatch({ type: CREATE_VENDOR_RSVP_SUCCESS, result });
                dispatch(showSnackbar('Attending RSVP Created'));
            })
            .catch((error) => {
                dispatch({ type: CREATE_VENDOR_RSVP_FAIL, error });
                dispatch(showSnackbar(`Error: ${error.message}`, { isError: true }));
            });
    };
}

export function govAddVendorRsvp(projectId, data) {
    return (dispatch, getState, client) => {
        dispatch({ type: CREATE_GOV_ADDED_VENDOR_RSVP });
        return client
            .post(`/project/${projectId}/rsvp/add-vendor-rsvp`, { data })
            .then((result) => {
                dispatch({ type: CREATE_GOV_ADDED_VENDOR_RSVP_SUCCESS, result });
                dispatch(showSnackbar('Vendor RSVP Added'));
            })
            .catch((error) => {
                dispatch({ type: CREATE_GOV_ADDED_VENDOR_RSVP_FAIL, error });
                dispatch(showSnackbar(`Error: ${error.message}`, { isError: true }));
            });
    };
}

export const RESET_RSVPS = 'rsvps/RESET_RSVPS';

export function resetRsvps() {
    return { type: RESET_RSVPS };
}

import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';

import { form, fieldNames, evaluationFieldNames, STAGED_EVALUATORS } from '../constants';
import { getInvitedUsersJS } from '../../../selectors';

const { EVALUATION } = fieldNames;
const { EVALUATORS } = evaluationFieldNames;

const formSelector = formValueSelector(form);

const getEvaluationCreateFormEvaluators = (state) =>
    formSelector(state, `${EVALUATION}.${EVALUATORS}`);

export const getStagedEvaluators = (state) => formSelector(state, STAGED_EVALUATORS);

const getEvaluatorsMap = createSelector([getEvaluationCreateFormEvaluators], (users) => {
    if (users) {
        return users.reduce((userObj, user) => {
            return { ...userObj, [user.id]: true };
        }, {});
    }
    return {};
});

export const getUsersSelectOptions = createSelector(
    [getInvitedUsersJS, getEvaluatorsMap],
    (users, evaluatorsMap) => {
        return users.map((user) => {
            const isEvaluator = evaluatorsMap[user.id];
            return {
                user,
                value: user.id,
                label: user.displayName + (isEvaluator ? ' (Added)' : ''),
                disabled: isEvaluator,
            };
        });
    }
);

import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import { Box } from '@og-pro/ui';

import { Timeline } from '../../../../../../../components/GovApp';
import { fieldNames } from '../../constants';
import { Label, RichTextInput } from '../../../../../../../components';
import { INTRODUCTION_VARIABLES } from '../../../../../constants';

const { BACKGROUND, SUMMARY } = fieldNames;

export const TemplateSummaryBackgroundTimelineForm = (props) => {
    const filteredVariableOptions = props.templateVariableOptions
        ? props.templateVariableOptions.filter((opt) => opt.label !== INTRODUCTION_VARIABLES)
        : [];

    const { array, change, disabled, form, showFormValidation, tagOptions } = props;

    return (
        <div className="row">
            <div className="col-md-offset-2 col-md-8">
                <Box mb={1} textAlign="center">
                    <Label label="Summary Section" />
                </Box>
                <Field
                    component={RichTextInput}
                    disabled={disabled}
                    minRows={2}
                    name={SUMMARY}
                    placeholder="Enter Text (optional)"
                    qaTag={`templateIntroduction-${SUMMARY}`}
                    showValidation={showFormValidation}
                    tagOptions={tagOptions}
                    templateVariableOptions={filteredVariableOptions}
                    toolbarPlacement="bottom"
                />
                <Box mb={1} mt={4} textAlign="center">
                    <Label label="Background Section" />
                </Box>
                <Field
                    component={RichTextInput}
                    disabled={disabled}
                    minRows={2}
                    name={BACKGROUND}
                    placeholder="Enter Text (optional)"
                    qaTag={`templateIntroduction-${BACKGROUND}`}
                    showValidation={showFormValidation}
                    tagOptions={tagOptions}
                    templateVariableOptions={filteredVariableOptions}
                    toolbarPlacement="bottom"
                />
                <Box mb={1} mt={4} textAlign="center">
                    <Label label="Timeline Section" />
                </Box>
                <Timeline
                    array={array}
                    change={change}
                    disabled={disabled}
                    form={form}
                    isTemplate
                    showValidation={showFormValidation}
                />
            </div>
        </div>
    );
};

TemplateSummaryBackgroundTimelineForm.propTypes = {
    array: PropTypes.object.isRequired,
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    form: PropTypes.string.isRequired,
    showFormValidation: PropTypes.bool,
    tagOptions: PropTypes.array,
    templateVariableOptions: PropTypes.array,
};

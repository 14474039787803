import { fromJS } from 'immutable';
import { browserHistory } from '@og-pro-migration-tools/react-router';

import * as checklistsActions from '../actions/checklists';
import * as checklistSocketActions from '../actions/checklistSocket';

const initialState = fromJS({
    checklist: null,
    connectedClients: [],
    loadChecklistError: null,
    loadingChecklist: false,
    shouldForceReload: false,
    vendorUsers: [],
});

const checklistQuestionnaireReducer = (questionnaire, action) => {
    // We only care about the questionnaire that matches the questionnaireId
    if (questionnaire.get('id') !== action.questionnaireId) {
        return questionnaire;
    }

    switch (action.type) {
        case checklistsActions.UPDATE_QUESTIONNAIRE_RESPONSE:
            return questionnaire.merge(
                fromJS({
                    updatingResponse: true,
                    updateResponseError: null,
                })
            );
        case checklistsActions.UPDATE_QUESTIONNAIRE_RESPONSE_SUCCESS:
            return questionnaire.merge(
                fromJS({
                    questionnaireResponses: [action.result],
                    updatingResponse: false,
                })
            );
        case checklistsActions.UPDATE_QUESTIONNAIRE_RESPONSE_FAIL:
            return questionnaire.merge(
                fromJS({
                    updatingResponse: false,
                    updateResponseError: action.error.message,
                })
            );
        case checklistsActions.UPDATE_QUESTIONNAIRE_REVIEW:
            return questionnaire.merge(
                fromJS({
                    updatingReview: true,
                    updateReviewError: null,
                })
            );
        case checklistsActions.UPDATE_QUESTIONNAIRE_REVIEW_SUCCESS:
            return questionnaire.merge(
                fromJS({
                    questionnaireReview: action.result,
                    updatingReview: false,
                })
            );
        case checklistsActions.UPDATE_QUESTIONNAIRE_REVIEW_FAIL:
            return questionnaire.merge(
                fromJS({
                    updatingReview: false,
                    updateReviewError: action.error.message,
                })
            );
        default:
            return questionnaire;
    }
};

export default function checklistsReducer(state = initialState, action = {}) {
    switch (action.type) {
        case checklistsActions.DELETE_SUCCESS:
            if (action.checklistSocketBroadcast) {
                // Navigate away from checklist when someone else deletes it
                // Note: Need to take off event loop so store can complete update before navigating away
                setTimeout(() => {
                    browserHistory.replace(
                        `/governments/${action.result.government_id}/contracts/${action.result.contract_id}/checklists`
                    );
                });
            }
            return state;
        case checklistsActions.LOAD:
            return state.merge(
                fromJS({
                    loadingChecklist: true,
                    loadChecklistError: null,
                })
            );
        case checklistsActions.LOAD_SUCCESS:
            return state.merge(
                fromJS({
                    checklist: fromJS(action.result),
                    loadingChecklist: false,
                })
            );
        case checklistsActions.LOAD_FAIL:
            return state.merge(
                fromJS({
                    loadingChecklist: false,
                    loadChecklistError: action.error.message,
                })
            );
        case checklistsActions.LOAD_VENDORS_SUCCESS:
            return state.set('vendorUsers', fromJS(action.result));
        case checklistsActions.RESET:
            return initialState;
        case checklistsActions.UPDATE_SUCCESS:
            return state.merge(
                fromJS({
                    checklist: fromJS(action.result),
                })
            );
        case checklistSocketActions.CONNECTED_CLIENT_COUNT:
            return state.set('connectedClients', fromJS(action.data));
        case checklistSocketActions.LEAVE:
            return state.set('connectedClients', fromJS([]));
        case checklistsActions.UPDATE_QUESTIONNAIRE_RESPONSE:
        case checklistsActions.UPDATE_QUESTIONNAIRE_RESPONSE_SUCCESS:
        case checklistsActions.UPDATE_QUESTIONNAIRE_RESPONSE_FAIL:
        case checklistsActions.UPDATE_QUESTIONNAIRE_REVIEW:
        case checklistsActions.UPDATE_QUESTIONNAIRE_REVIEW_SUCCESS:
        case checklistsActions.UPDATE_QUESTIONNAIRE_REVIEW_FAIL:
            return state.setIn(
                ['checklist', 'questionnaires'],
                state.getIn(['checklist', 'questionnaires']).map((questionnaire) => {
                    return checklistQuestionnaireReducer(questionnaire, action);
                })
            );
        default:
            return state;
    }
}

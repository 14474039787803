import moment from 'moment';

function hasDatePassed(date) {
    return moment().isAfter(date, 'day');
}

export function isUpcomingDate(date) {
    if (!date) return false;
    if (hasDatePassed(date)) return false;
    return moment().add(7, 'days').isSameOrAfter(date, 'day');
}

export function timeClass(date) {
    if (!date) return '';
    if (hasDatePassed(date)) return 'text-muted';
    if (isUpcomingDate(date)) return 'text-primary';
    return '';
}

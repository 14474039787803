import { fromJS } from 'immutable';
import { get } from 'lodash';

import { vendorUploadsList } from '@og-pro/shared-config/vendorUploads';

import * as adminVendorActions from '../actions/adminVendor';
import * as authActions from '../actions/auth';

const vendorUploads = vendorUploadsList.map((vendorUpload) => {
    return {
        ...vendorUpload,
        attachments: [],
    };
});

const initialState = fromJS({
    loadingAttachments: false,
    loadingCertifications: false,
    vendorAttachments: vendorUploads,
    vendorCertifications: [],
});

function cleanAttachment(attachment) {
    const type = get(attachment, 'vendorAttachments.vendor_upload_type');
    return { ...attachment, uploadType: type };
}

function vendorAttachmentsReducer(state, action) {
    // We only care about the list item that matches the upload type
    if (state.get('type') !== action.uploadType) {
        return state;
    }

    switch (action.type) {
        case adminVendorActions.CREATE_ATTACHMENT_SUCCESS:
            return state.merge(
                fromJS({
                    attachments: state.get('attachments').push(fromJS(action.result)),
                })
            );
        case adminVendorActions.DELETE_ATTACHMENT_SUCCESS:
            return state.set(
                'attachments',
                state
                    .get('attachments')
                    .filter((attach) => attach.get('id') !== action.attachmentId)
            );
        default:
            return state;
    }
}

export default function adminVendorReducer(state = initialState, action = {}) {
    switch (action.type) {
        case adminVendorActions.LOAD_VENDOR:
            return state.merge(
                fromJS({
                    loadingVendor: true,
                    loadVendorError: null,
                })
            );
        case adminVendorActions.LOAD_VENDOR_SUCCESS:
            return state.merge(
                fromJS({
                    loadingVendor: false,
                    vendor: fromJS(action.result),
                })
            );
        case adminVendorActions.LOAD_VENDOR_FAIL:
            return state.merge(
                fromJS({
                    loadingVendor: false,
                    loadVendorError: action.error && action.error.message,
                })
            );
        case adminVendorActions.LOAD_ATTACHMENTS:
            return state.merge(
                fromJS({
                    loadingAttachments: true,
                    loadAttachmentsError: null,
                })
            );
        case adminVendorActions.LOAD_ATTACHMENTS_SUCCESS: {
            const loadedAttachmentsMap = action.result.reduce((obj, attachment) => {
                const mappedAttachment = cleanAttachment(attachment);
                if (!obj[mappedAttachment.uploadType]) {
                    obj[mappedAttachment.uploadType] = [];
                }
                obj[mappedAttachment.uploadType].push(mappedAttachment);
                return obj;
            }, {});
            return state.set('loadingAttachments', false).set(
                'vendorAttachments',
                state.get('vendorAttachments').map((upload) => {
                    const uploadType = upload.get('type');
                    const result = loadedAttachmentsMap[uploadType];
                    return upload.set('attachments', fromJS(result || []));
                })
            );
        }
        case adminVendorActions.LOAD_ATTACHMENTS_FAIL:
            return state.merge(
                fromJS({
                    loadingAttachments: false,
                    loadAttachmentsError: action.error && action.error.message,
                })
            );
        case adminVendorActions.CREATE_ATTACHMENT_SUCCESS:
        case adminVendorActions.DELETE_ATTACHMENT_SUCCESS:
            return state.set(
                'vendorAttachments',
                state
                    .get('vendorAttachments')
                    .map((upload) => vendorAttachmentsReducer(upload, action))
            );
        case adminVendorActions.ADD_CERTIFICATION:
            return state.update('vendorCertifications', (certifications) =>
                certifications.push(fromJS(action.certification))
            );
        case adminVendorActions.CANCEL_ADD_CERTIFICATION:
            return state.update('vendorCertifications', (certifications) => certifications.pop());
        case adminVendorActions.LOAD_CERTIFICATIONS:
            return state.merge(
                fromJS({
                    loadingCertifications: true,
                    loadCertificationsError: null,
                })
            );
        case adminVendorActions.LOAD_CERTIFICATIONS_SUCCESS:
            return state.merge(
                fromJS({
                    loadingCertifications: false,
                    vendorCertifications: fromJS(action.result),
                })
            );
        case adminVendorActions.LOAD_CERTIFICATIONS_FAIL:
            return state.merge(
                fromJS({
                    loadingCertifications: false,
                    loadCertificationsError: action.error && action.error.message,
                })
            );
        case adminVendorActions.DELETE_CERTIFICATION:
            return state.update('vendorCertifications', (certifications) =>
                fromJS(
                    certifications.toJS().map((certification) => {
                        return {
                            ...certification,
                            vendorCertifications: certification.vendorCertifications?.filter(
                                (vendorCertification) =>
                                    vendorCertification.id !== action.certificationId
                            ),
                        };
                    })
                )
            );
        case adminVendorActions.UPDATE_CERTIFICATION:
            return state.update('vendorCertifications', (certifications) => {
                // if there is no id, this is a new certification being added
                if (!action.data.id) {
                    return certifications.updateIn([certifications.size - 1], (certification) => {
                        const updatedCert = certification.toJS();
                        updatedCert.title = action.data.title;
                        updatedCert.vendorCertifications[0] = {
                            ...action.data,
                            ...updatedCert.vendorCertifications[0],
                            vendor_id: action.vendorId,
                        };
                        updatedCert.loading = true;

                        return fromJS(updatedCert);
                    });
                }
            });
        case adminVendorActions.UPDATE_CERTIFICATION_SUCCESS:
        case adminVendorActions.DELETE_CERTIFICATION_SUCCESS:
            return state.set('vendorCertifications', fromJS(action.result));
        case adminVendorActions.SHOW_VENDOR_ACCOUNT_MODAL:
            return state.merge(
                fromJS({
                    showVendorAccountModal: true,
                    vendorAccountCompleteHandler: action.onComplete,
                    displayVendorAccountForm: action.immediatelyDisplayForm,
                })
            );
        case adminVendorActions.HIDE_VENDOR_ACCOUNT_MODAL:
            return state.merge(
                fromJS({
                    showVendorAccountModal: false,
                    vendorAccountCompleteHandler: null,
                    displayVendorAccountForm: null,
                })
            );
        case authActions.LOGOUT_SUCCESS:
            return initialState;
        default:
            return state;
    }
}

import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';

import { fieldNames } from '../../../constants';
import { getInitialVendorSearch } from '../../../selectors';
import { AsyncSearchSelect, SearchSelectSubTextOption } from '../../../../../../../components';
import { fetchVendorSearchOptions } from '../../../../../../../lib/ogFinancials/useVendorSelection';

const { CONTRACT_ID, CONTRACT_ID_TO_UPDATE, VENDOR_ASSIGNED_NO, VENDOR_ID, VENDOR_NAME } =
    fieldNames;

export const FmsSelectedVendorSearch = ({
    disabled,
    onVendorChange,
    showFormValidation,
    showMismatchModal,
}) => {
    const { initialSearch, suggestedContract } = useSelector(getInitialVendorSearch);
    const hasInitialSearchRef = useRef(!!initialSearch);
    const suggestedContractRef = useRef(suggestedContract);

    const [loadError, setLoadError] = useState(null);
    const [inputValue, setInputValue] = useState(initialSearch);

    // Custom handler to update input value based on user input
    const handleInputChange = (newValue) => {
        setInputValue(newValue);
    };

    const loadVendorSelectOptions = async (searchData) => {
        try {
            setLoadError(null);
            return await fetchVendorSearchOptions(searchData);
        } catch (e) {
            setLoadError(' - Error loading vendors');
            return [];
        }
    };

    const onOptionChange = (data) => {
        if (isNil(data?.value)) {
            return;
        }

        const vendorNumber = data.value.toString();
        const contract = suggestedContractRef.current;
        const contractVendorNumber = contract?.vendorAssignedNo;

        if (contract && contractVendorNumber !== vendorNumber) {
            const vendor = {
                [VENDOR_ASSIGNED_NO]: vendorNumber,
                [VENDOR_NAME]: data.user.displayName,
            };
            return showMismatchModal({
                contract,
                onUpdate: () =>
                    onVendorChange(data, {
                        [CONTRACT_ID]: contract.id,
                        [CONTRACT_ID_TO_UPDATE]: contract.id,
                    }),
                onSkip: () => onVendorChange(data),
                vendor,
            });
        }
        onVendorChange(data, contract && { [CONTRACT_ID]: contract.id });
    };

    return (
        <Field
            autoFocus
            component={AsyncSearchSelect}
            components={{ Option: SearchSelectSubTextOption }}
            disabled={disabled}
            initialSearch={initialSearch}
            inputValue={inputValue}
            isClearable
            label={`Vendor Name * ${loadError || ''}`}
            loadOptions={loadVendorSelectOptions}
            name={VENDOR_ID}
            onInputChange={handleInputChange}
            onOptionChange={onOptionChange}
            openMenuOnFocus={hasInitialSearchRef.current}
            placeholder="Search vendors"
            showValidation={showFormValidation}
            useOpenGovStyle
        />
    );
};

FmsSelectedVendorSearch.propTypes = {
    disabled: PropTypes.bool,
    onVendorChange: PropTypes.func.isRequired,
    showFormValidation: PropTypes.bool,
    showMismatchModal: PropTypes.func,
};

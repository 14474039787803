import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import { SectionDescriptionForm } from '../SectionDescriptionForm';
import { EditIcon, HtmlContent } from '../../../../../../../../components';

const mapStateToProps = (state, props) => {
    return {
        sectionDescription: formValueSelector(props.form)(state, props.formKey),
    };
};

// @connect
class ConnectedSectionDescriptionDisplay extends PureComponent {
    static propTypes = {
        addSectionDescription: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        formKey: PropTypes.string,
        isEditable: PropTypes.bool,
        removeSectionDescription: PropTypes.func.isRequired,
        sectionDescription: PropTypes.shape({
            description: PropTypes.string,
        }),
        showFormValidation: PropTypes.bool,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
    };

    constructor(props) {
        super(props);
        this.state = { showForm: false };
    }

    get styles() {
        return require('./index.scss');
    }

    addSectionDescription = () => {
        const { addSectionDescription } = this.props;

        addSectionDescription();
        this.openForm();
    };

    openForm = () => {
        this.setState({ showForm: true });
    };

    closeForm = () => {
        this.setState({ showForm: false });
    };

    removeSectionDescription = () => {
        const { removeSectionDescription } = this.props;

        removeSectionDescription();
        this.closeForm();
    };

    renderAddSection() {
        const { disabled, isEditable } = this.props;

        if (!isEditable) {
            return null;
        }

        return (
            <div
                className={classnames(
                    this.styles.addSectionContainer,
                    disabled && this.styles.disabled
                )}
                onClick={disabled ? undefined : this.addSectionDescription}
            >
                Add optional description to section
            </div>
        );
    }

    renderForm() {
        const { disabled, formKey, showFormValidation, tagOptions, templateVariableOptions } =
            this.props;

        return (
            <SectionDescriptionForm
                closeForm={this.closeForm}
                disabled={disabled}
                formKey={formKey}
                remove={this.removeSectionDescription}
                showFormValidation={showFormValidation}
                tagOptions={tagOptions}
                templateVariableOptions={templateVariableOptions}
            />
        );
    }

    render() {
        const { disabled, isEditable, sectionDescription } = this.props;

        const { showForm } = this.state;

        if (!sectionDescription) {
            return this.renderAddSection();
        }

        if (showForm || !sectionDescription.description) {
            return this.renderForm();
        }

        return (
            <div className={this.styles.sectionDescriptionDisplay}>
                <HtmlContent content={sectionDescription.description} />
                <EditIcon
                    className={this.styles.editIcon}
                    disabled={disabled}
                    onClick={this.openForm}
                    show={isEditable}
                />
            </div>
        );
    }
}

export const SectionDescriptionDisplay = connect(mapStateToProps)(
    ConnectedSectionDescriptionDisplay
);

import classnames from 'classnames';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Label, ListGroup, ListGroupItem } from 'react-bootstrap';

import { questionStatusTypes } from '@og-pro/shared-config/questions';

import { AnswerForm, form } from './AnswerForm';
import { EditQuestionForm } from './EditQuestionForm';
import { GovQuestionApprovalMenu } from './GovQuestionApprovalMenu';
import { QuestionComment } from './QuestionComment';
import { commentDateFormatter } from '../../../../utils';

const { EXCLUDED, PENDING, RELEASED } = questionStatusTypes;

export class Question extends PureComponent {
    static propTypes = {
        assignedUser: PropTypes.object,
        assigningUser: PropTypes.bool,
        assignUserError: PropTypes.string,
        attachments: PropTypes.array.isRequired,
        canEdit: PropTypes.bool,
        canRelease: PropTypes.bool,
        comments: PropTypes.arrayOf(
            PropTypes.shape({
                description: PropTypes.string,
                id: PropTypes.number.isRequired,
            })
        ).isRequired,
        excludeReason: PropTypes.string,
        hasDeadlinePassed: PropTypes.bool,
        isApproved: PropTypes.bool.isRequired,
        isGovUser: PropTypes.bool,
        isSubstitutionRequest: PropTypes.bool.isRequired,
        number: PropTypes.number,
        postedAt: PropTypes.string.isRequired,
        questionId: PropTypes.number.isRequired,
        releasedAt: PropTypes.string,
        respondError: PropTypes.string,
        responding: PropTypes.bool,
        responseHandler: PropTypes.func,
        showEditQuestionForm: PropTypes.bool,
        showEditResponseForm: PropTypes.bool,
        showReleaseResponsesModal: PropTypes.func,
        status: PropTypes.string.isRequired,
        subject: PropTypes.string.isRequired,
        timezone: PropTypes.string.isRequired,
        toggleEditQuestionForm: PropTypes.func,
        toggleEditResponseForm: PropTypes.func,
        user: PropTypes.object,
    };

    get styles() {
        return require('./shared.scss');
    }

    getOrgImg = (user) => {
        return get(user, ['organization', 'logo']);
    };

    responseHandler = (type, questionCommentId, message) => {
        const { questionId, responseHandler } = this.props;

        return responseHandler(type, questionId, questionCommentId, message);
    };

    showReleaseResponsesModal = () => {
        const { questionId, showReleaseResponsesModal } = this.props;

        showReleaseResponsesModal([questionId]);
    };

    toggleEditQuestionForm = () => {
        const { questionId, toggleEditQuestionForm } = this.props;

        toggleEditQuestionForm(questionId);
    };

    toggleEditResponseForm = () => {
        const { questionId, toggleEditResponseForm } = this.props;

        toggleEditResponseForm(questionId);
    };

    renderAnswerForm(editFormProps) {
        const {
            assignedUser,
            assigningUser,
            assignUserError,
            canEdit,
            isApproved,
            questionId,
            respondError,
            responding,
            user,
        } = this.props;

        if (!isApproved) {
            return this.renderApproveMenu();
        }

        const orgName = get(user, ['organization', 'name']);
        const userImg = this.getOrgImg(user);

        return (
            <ListGroupItem className={this.styles.commentInput}>
                <AnswerForm
                    assignUserError={assignUserError}
                    assignedUser={assignedUser}
                    assigningUser={assigningUser}
                    canEdit={canEdit}
                    form={`${form}${questionId}`}
                    image={userImg}
                    isLoading={responding}
                    onSubmit={this.responseHandler('postResponse', null, 'Response Added')}
                    organization={orgName}
                    questionId={questionId}
                    submitError={respondError}
                    {...editFormProps}
                />
            </ListGroupItem>
        );
    }

    renderApproveMenu() {
        const { canEdit, canRelease, respondError, responding } = this.props;

        return (
            <ListGroupItem className={this.styles.commentInput}>
                <GovQuestionApprovalMenu
                    canEdit={canEdit}
                    canRelease={canRelease}
                    editHandler={this.toggleEditQuestionForm}
                    rejectQuestionHandler={this.responseHandler('excludeQuestion')}
                    updateError={respondError}
                    updateQuestionHandler={this.responseHandler(null, null, 'Question Approved')}
                    updating={responding}
                />
            </ListGroupItem>
        );
    }

    renderComment(comment, isAnswer) {
        // IMPORTANT: Do NOT rely on the user or org being present in a questionComment.
        // It is not given for vendors when accessed by other vendors, gov users with view only permissions,
        // and all gov users when a reverse auction is in progress.
        const {
            attachments,
            canEdit,
            canRelease,
            hasDeadlinePassed,
            isApproved,
            isGovUser,
            isSubstitutionRequest,
            releasedAt,
            respondError,
            responding,
            showEditQuestionForm,
            status,
            subject,
            timezone,
            user,
        } = this.props;

        const orgName = get(comment.user, ['organization', 'name']);
        const userName = get(comment.user, 'displayName');
        const imgSrc = this.getOrgImg(comment.user);

        if (showEditQuestionForm && !isAnswer) {
            return (
                <ListGroupItem>
                    <EditQuestionForm
                        canRelease={canRelease}
                        cancelHandler={this.toggleEditQuestionForm}
                        disabled={responding}
                        image={imgSrc}
                        initialValues={{
                            description: comment.description,
                            subject,
                        }}
                        onSubmit={(data) => {
                            if (!isGovUser) {
                                data.vendorUpdate = true;
                            }
                            return this.responseHandler(
                                'updateResponse',
                                comment.id,
                                'Question Updated'
                            )(data);
                        }}
                        organization={orgName}
                        rejectQuestionHandler={this.responseHandler('excludeQuestion')}
                        submitError={respondError}
                    />
                </ListGroupItem>
            );
        }

        return (
            <ListGroupItem
                className={classnames(
                    this.styles.questionComment,
                    isAnswer && this.styles.commentInput
                )}
            >
                <QuestionComment
                    attachments={attachments}
                    canEdit={status === RELEASED ? canRelease : canEdit}
                    canRelease={canRelease}
                    canVendorEdit={
                        !isGovUser &&
                        comment.user_id === get(user, 'id') &&
                        !isApproved &&
                        status === PENDING &&
                        !hasDeadlinePassed
                    }
                    date={isAnswer && releasedAt ? releasedAt : comment.created_at}
                    description={comment.description}
                    editHandler={
                        isAnswer ? this.toggleEditResponseForm : this.toggleEditQuestionForm
                    }
                    image={imgSrc}
                    isAnswer={isAnswer}
                    isGovUser={isGovUser}
                    isSubstitutionRequest={isSubstitutionRequest}
                    isVendor={comment.isVendor}
                    name={userName}
                    organization={orgName}
                    showReleaseResponsesModal={this.showReleaseResponsesModal}
                    status={status}
                    timezone={timezone}
                />
            </ListGroupItem>
        );
    }

    renderAnswer(response) {
        const { isApproved, excludeReason, isGovUser, showEditResponseForm, status } = this.props;
        const isExcluded = status === EXCLUDED;
        const isUnapproved = !isExcluded && !isApproved;
        if (status === EXCLUDED) {
            return (
                <ListGroupItem className={`text-center ${this.styles.commentInput}`}>
                    <em>Question excluded: {excludeReason}</em>
                </ListGroupItem>
            );
        }
        if (showEditResponseForm) {
            return this.renderAnswerForm({
                autoFocus: true,
                cancelHandler: this.toggleEditResponseForm,
                initialValues: { description: response.description },
                onSubmit: this.responseHandler('updateResponse', response.id, 'Response Updated'),
            });
        }
        if (response) {
            return this.renderComment(response, true);
        }
        if (isGovUser) {
            return this.renderAnswerForm();
        }
        if (isUnapproved) {
            return;
        }
        return (
            <ListGroupItem className={`text-center ${this.styles.commentInput}`}>
                <em>Question is acknowledged</em>
            </ListGroupItem>
        );
    }

    render() {
        const {
            comments,
            isApproved,
            isGovUser,
            isSubstitutionRequest,
            number,
            postedAt,
            questionId,
            status,
            subject,
            timezone,
        } = this.props;

        const [question, response] = comments;
        const isExcluded = status === EXCLUDED;
        const isUnapproved = !isExcluded && !isApproved;

        return (
            <div
                className={classnames(
                    this.styles.questionContainer,
                    `question-number-${number}`,
                    `question-id-${questionId}`
                )}
            >
                <div className={this.styles.subject}>
                    {isUnapproved && (
                        <>
                            <div className="text-danger">
                                <i className="fa fa-exclamation-triangle" /> Awaiting Approval
                            </div>
                            {!isGovUser && (
                                <p className="text-muted">
                                    <small>
                                        <em>
                                            Your question has been successfully submitted, it just
                                            needs to be approved by the issuing organization before
                                            it is publicly displayed. No further action is required
                                            on your part.
                                        </em>
                                    </small>
                                </p>
                            )}
                        </>
                    )}
                    <h4>
                        {isExcluded && (
                            <>
                                <i className="fa fa-ban text-danger" />
                                &nbsp;
                            </>
                        )}
                        {isApproved && <>{number}.&nbsp;</>}
                        {subject}
                        &nbsp;
                        <small className="text-muted">
                            <em>{commentDateFormatter(postedAt, timezone)}</em>
                            {isSubstitutionRequest && (
                                <span className={this.styles.reqBadge}>
                                    <Label bsStyle="info">
                                        <i className="fa fa-exchange" /> Request
                                    </Label>
                                </span>
                            )}
                        </small>
                    </h4>
                </div>
                <ListGroup className={this.styles.listGroup}>
                    {this.renderComment(question)}
                    {this.renderAnswer(response)}
                </ListGroup>
            </div>
        );
    }
}

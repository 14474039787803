import { fromJS } from 'immutable';
import moment from 'moment';

import * as emergencyPortalActions from '../actions/emergencyPortal';

const initialState = fromJS({
    contracts: [],
    contractsListUpdateDate: null,
    loadingContracts: false,
    loadingProjects: false,
    loadContractsError: null,
    loadProjectsError: null,
    projects: [],
    projectsListUpdateDate: null,
});

export default function emergencyPortalReducer(state = initialState, action = {}) {
    switch (action.type) {
        case emergencyPortalActions.LOAD_EMERGENCY_CONTRACTS:
            return state.merge(
                fromJS({
                    loadingContracts: true,
                    loadContractsError: null,
                })
            );
        case emergencyPortalActions.LOAD_EMERGENCY_CONTRACTS_SUCCESS:
            return state.merge(
                fromJS({
                    loadingContracts: false,
                    contracts: fromJS(action.result),
                    contractsListUpdateDate: moment().add(1, 'hour').toDate(),
                })
            );
        case emergencyPortalActions.LOAD_EMERGENCY_CONTRACTS_FAIL:
            return state.merge(
                fromJS({
                    loadingContracts: false,
                    loadContractsError: action.error.message,
                })
            );
        case emergencyPortalActions.LOAD_EMERGENCY_PROJECTS:
            return state.merge(
                fromJS({
                    loadingProjects: true,
                    loadProjectsError: null,
                })
            );
        case emergencyPortalActions.LOAD_EMERGENCY_PROJECTS_SUCCESS:
            return state.merge(
                fromJS({
                    loadingProjects: false,
                    projects: fromJS(action.result),
                    projectsListUpdateDate: moment().add(1, 'hour').toDate(),
                })
            );
        case emergencyPortalActions.LOAD_EMERGENCY_PROJECTS_FAIL:
            return state.merge(
                fromJS({
                    loadingProjects: false,
                    loadProjectsError: action.error.message,
                })
            );
        default:
            return state;
    }
}

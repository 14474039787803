import moment from 'moment';

import { dateFormat, fieldNames } from './constants';

export const isDateValid = (dateString) => {
    const parsedDate = moment(dateString, dateFormat, true);
    return parsedDate.isValid();
};

const { ON, AFTER, BEFORE, START, END } = fieldNames;

const validate = (values) => {
    const errors = {};

    [ON, AFTER, BEFORE, START, END].forEach((field) => {
        if (values[field] && !isDateValid(values[field])) {
            errors[field] = 'Invalid date';
        }
    });

    return errors;
};

export const formConfig = {
    enableReinitialize: true,
    form: 'requisitionsDashboard',
    validate,
    keepDirtyOnReinitialize: true,
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Main, QuestionnaireDisplayList, SectionTitle } from '../../../../../components';

export class QuestionnaireReview extends PureComponent {
    static propTypes = {
        questionnaires: PropTypes.array.isRequired,
    };

    render() {
        const { questionnaires } = this.props;
        return (
            <Main>
                <SectionTitle
                    info="List of questions vendors should answer as part of their response"
                    title="Vendor Questionnaire"
                />
                <QuestionnaireDisplayList questionnaires={questionnaires} />
            </Main>
        );
    }
}

import PropTypes from 'prop-types';

import { getDeserializedProject, hasQuestionnaire } from './selectors';
import { getTemplateVariableOptions } from '../constants';
import { getPostingPath, getProjectJS, getProjectSectionTagOptions } from '../selectors';

export function mapStateToProps(state, props) {
    const rawProject = getProjectJS(state);
    const project = getDeserializedProject(state);

    return {
        // Used by redux-form to set initialValues in the form
        hasQuestionnaire: hasQuestionnaire(state),
        loading: state.govProjects.get('loadingSelected'),
        loaded: state.govProjects.get('loadedSelected'),
        loadError: state.govProjects.get('loadSelectedError'),
        postPath: `${getPostingPath(state, props)}/create`,
        project,
        reviewPath: getPostingPath(state, props),
        shouldSubmit: state.projectPost.get('shouldSubmit'),
        shouldUpdate: state.projectPost.get('shouldUpdate'),
        showFormErrors: state.projectPost.get('showFormErrors'),
        tagOptions: getProjectSectionTagOptions(state),
        templateVariableOptions: getTemplateVariableOptions({ project: rawProject }),
        timezone: state.auth.getIn(['user', 'organization', 'timezone']),
        updateError: state.projectCreate.get('updateError'),
        updating: state.projectCreate.get('updating'),
    };
}

export const propTypes = {
    hasQuestionnaire: PropTypes.bool.isRequired,
    initialized: PropTypes.bool,
    loaded: PropTypes.bool,
    loadError: PropTypes.string,
    loading: PropTypes.bool,
    postPath: PropTypes.string.isRequired,
    project: PropTypes.object,
    reviewPath: PropTypes.string.isRequired,
    shouldSubmit: PropTypes.bool.isRequired,
    shouldUpdate: PropTypes.bool.isRequired,
    showFormErrors: PropTypes.bool.isRequired,
    tagOptions: PropTypes.array,
    templateVariableOptions: PropTypes.array.isRequired,
    timezone: PropTypes.string.isRequired,
    updateError: PropTypes.string,
    updating: PropTypes.bool.isRequired,
};

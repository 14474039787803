const get = require('lodash/get');

const {
    questionLogicActionNames,
    questionLogicLogicableModelNames,
    questionLogicOperatorNames,
    requisitionFieldLogicableIds,
} = require('.');
const { MULTIPLE_CHOICE } = require('../questionnaires');

const { SHOW, HIDE } = questionLogicActionNames;
const {
    EQUAL,
    GREATER,
    GREATER_OR_EQUAL,
    INCLUDE,
    LESSER,
    LESSER_OR_EQUAL,
    NOT_EQUAL,
    NOT_INCLUDE,
} = questionLogicOperatorNames;

/**
 * Gets the logicable input value to use for calculating logic
 * @param {object} questionLogic Sequelize QuestionLogic instance
 * @param {object} logicableItem Item that will be used for computing if logic condition is met
 * @returns {any} Logicable input value
 */
const getQuestionLogicInputValue = (questionLogic, logicableItem) => {
    switch (questionLogic.logicable) {
        case questionLogicLogicableModelNames.FLAG:
            // `logicalItem` is a `FlagResponse`
            return get(logicableItem, ['isEnabled']);
        case questionLogicLogicableModelNames.REQUISITION_FIELD:
            // `logicalItem` is a `Requisition`
            switch (questionLogic.logicable_id) {
                case requisitionFieldLogicableIds.TOTAL_SPEND:
                    return get(logicableItem, ['totalSpend']);
                default:
                    return;
            }
        case questionLogicLogicableModelNames.QUESTIONNAIRE:
            return get(logicableItem, ['questionnaireResponse', 'data', 'value']);
        case questionLogicLogicableModelNames.UPFRONT_QUESTION:
        default:
            // `logicalItem` is an `UpfrontQuestion`
            return get(logicableItem, ['inputData', 'value']);
    }
};

/**
 * Gets the question logic input value to use for calculating logic
 * @param {object} questionLogicInputValue QuestionLogic input value
 * @param {string} logicable QuestionLogic logicable type
 * @param {object} logicableItem Item that will be used for computing if logic condition is met
 * @returns {any} Question logic input value
 */
const getQuestionLogicValue = (questionLogicInputValue, logicable, logicableItem) => {
    if (Array.isArray(questionLogicInputValue)) {
        return questionLogicInputValue.map((inputValue) =>
            getQuestionLogicValue(inputValue, logicable, logicableItem)
        );
    }

    // Compute question logic value for logicable upfront questions that are multiple choice types
    if (
        logicable === questionLogicLogicableModelNames.UPFRONT_QUESTION &&
        get(logicableItem, 'type') === MULTIPLE_CHOICE
    ) {
        return get(logicableItem, `data.options.${questionLogicInputValue}`);
    }

    return questionLogicInputValue;
};

/**
 * Calculates if question logic condition is met
 * @param {object} questionLogic Sequelize QuestionLogic instance
 * @param {object} logicableItem Item that will be used for computing if logic condition is met
 * @returns {boolean} If the question logic condition is met
 */
const calculateIsConditionMet = (questionLogic, logicableItem) => {
    const { logicable, operator, value } = questionLogic;

    const inputValue = getQuestionLogicInputValue(questionLogic, logicableItem);
    const questionLogicValue = getQuestionLogicValue(value, logicable, logicableItem);

    // For array input values use different logic (happens when upfront question allows multi select)
    if (Array.isArray(inputValue)) {
        // Make question logic values into an array
        const logicValues =
            operator === INCLUDE || operator === NOT_INCLUDE
                ? questionLogicValue
                : [questionLogicValue];
        if (operator === EQUAL || operator === INCLUDE) {
            return inputValue.some((val) => logicValues.includes(val));
        }
        if (operator === NOT_EQUAL || operator === NOT_INCLUDE) {
            return inputValue.every((val) => !logicValues.includes(val));
        }
    }

    switch (operator) {
        case EQUAL:
            return inputValue === questionLogicValue;
        case NOT_EQUAL:
            return inputValue !== questionLogicValue;
        case INCLUDE:
            return questionLogicValue.includes(inputValue);
        case NOT_INCLUDE:
            return !questionLogicValue.includes(inputValue);
        case LESSER:
            return inputValue < questionLogicValue;
        case LESSER_OR_EQUAL:
            return inputValue <= questionLogicValue;
        case GREATER:
            return inputValue > questionLogicValue;
        case GREATER_OR_EQUAL:
            return inputValue >= questionLogicValue;
        default:
            return false;
    }
};

/**
 * Determines whether conditions are met for hiding an item
 * @param {object} questionLogic Sequelize QuestionLogic instance
 * @param {object} logicableItem Item that will be used for computing if logic condition is met
 * @returns {boolean} If the item should be hidden
 */
exports.shouldHideQuestionLogicItem = (questionLogic, logicableItem) => {
    const { action } = questionLogic;

    const mapActionsToHide = {
        [HIDE]: true,
        [SHOW]: false,
    };

    const isConditionMet = calculateIsConditionMet(questionLogic, logicableItem);

    if (isConditionMet) {
        // Take the specified action on the item
        return mapActionsToHide[action];
    }
    // Take the opposite action on the item
    return !mapActionsToHide[action];
};

import PropTypes from 'prop-types';
import React from 'react';

import { UserProfilePicture } from '../../../../../../../../components';

export const ReviewSequenceStepPositionsDisplay = ({ reviewSequenceStepPositions }) => {
    if (reviewSequenceStepPositions.length === 0) {
        return (
            <div>
                <em>No positions assigned</em>
            </div>
        );
    }

    return reviewSequenceStepPositions.map((reviewSequenceStepPosition) => {
        const {
            position: { id, name, user },
        } = reviewSequenceStepPosition;
        const displayUser = user ?? {
            displayName: 'Group position - Users assigned in review group',
        };
        return (
            <div key={id} style={{ marginBottom: 8 }}>
                <UserProfilePicture horizontal showTooltip user={displayUser} /> {name}
            </div>
        );
    });
};

ReviewSequenceStepPositionsDisplay.propTypes = {
    reviewSequenceStepPositions: PropTypes.array.isRequired,
};

import { round, isNumber, pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ListGroupItem } from 'react-bootstrap';

import { CriteriaScorecardForm } from './CriteriaScorecardForm';
import { fields, form } from './CriteriaScorecardForm/constants';
import { Button, Collapse, CriteriaScore } from '../../../../../components';

export class CriteriaScorecardItem extends PureComponent {
    static propTypes = {
        canEvaluate: PropTypes.bool.isRequired,
        criteria: PropTypes.object.isRequired,
        isCommentRequired: PropTypes.bool.isRequired,
        isComplete: PropTypes.bool.isRequired,
        score: PropTypes.shape({
            id: PropTypes.number,
            score: PropTypes.number,
        }),
        totalWeight: PropTypes.number.isRequired,
    };

    static defaultProps = {
        score: {},
    };

    constructor(props) {
        super(props);
        this.state = { showForm: false };
    }

    get hasScore() {
        return isNumber(this.props.score.score);
    }

    get buttonEl() {
        if (this.state.showForm) return 'Close';
        if (this.props.isComplete) return 'View';
        if (!this.props.canEvaluate) return 'View';
        return (
            <span>
                <i className="fa fa-pencil" /> {this.hasScore ? 'Edit' : 'Score'}
            </span>
        );
    }

    toggleForm = () => {
        this.setState((prevState) => ({ showForm: !prevState.showForm }));
    };

    render() {
        const { canEvaluate, criteria, isCommentRequired, isComplete, score, totalWeight } =
            this.props;

        const weight = `${round((criteria.weight / totalWeight) * 100, 1)}%`;
        const styles = require('./CriteriaScorecardItem.scss');

        return (
            <ListGroupItem>
                <div className="row">
                    <div className="col-sm-9">
                        <div className="row" style={{ marginBottom: 3 }}>
                            <div className="col-xs-9">
                                <strong>{criteria.title}</strong>
                            </div>
                            <div className="col-xs-3 text-center">
                                <CriteriaScore
                                    criteriaScore={score.score}
                                    scoringCriteria={criteria}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-9">
                                <em>{criteria.scoringMethodText}</em>
                            </div>
                            <div className="col-xs-3 text-center">
                                <em>{weight}</em>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3 text-center">
                        <Button bsSize="sm" className={styles.scoreBtn} onClick={this.toggleForm}>
                            {this.buttonEl}
                        </Button>
                    </div>
                </div>
                <Collapse in={this.state.showForm}>
                    <CriteriaScorecardForm
                        canEvaluate={canEvaluate}
                        closeForm={this.toggleForm}
                        form={`${form}${criteria.id}`}
                        initialValues={pick(score, fields)}
                        isCommentRequired={isCommentRequired}
                        isComplete={isComplete}
                        onSubmit={this.submitHandler}
                        proposalCriteriaScore={score}
                        scoringCriteria={criteria}
                    />
                </Collapse>
            </ListGroupItem>
        );
    }
}

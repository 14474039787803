import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { QuestionPrompt } from '../../SharedDisplay';

export class ExternalFormDisplay extends PureComponent {
    static propTypes = {
        data: PropTypes.shape({
            value: PropTypes.string,
        }),
        isDocx: PropTypes.bool,
    };

    render() {
        const { data, isDocx } = this.props;

        return (
            <QuestionPrompt {...this.props}>
                {isDocx && data && data.value && (
                    <div className="external-form-link-wrapper">
                        <a
                            className="text-primary"
                            href={data.value}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            Click here to go to the form
                        </a>
                    </div>
                )}
            </QuestionPrompt>
        );
    }
}

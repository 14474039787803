import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { UserProfilePicture } from '../../../../../../components';

export const StepApprovalGroup = ({ approvers, hasPosition, name }) => {
    const styles = require('./StepApprovalGroup.scss');

    if (approvers.length === 0) {
        return null;
    }

    return (
        <div className={styles.approvalGroup}>
            <div className={styles.name}>{name}</div>
            <div className={styles.approvers}>
                {approvers.map((user) => (
                    <div
                        className={classNames(
                            styles.approver,
                            !hasPosition && styles.approverNoPosition
                        )}
                        key={user.id}
                    >
                        <UserProfilePicture horizontal user={user} />
                        <div>
                            {user.displayName}
                            {hasPosition && (
                                <div className={styles.position}>{user.positionName}</div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

StepApprovalGroup.propTypes = {
    approvers: PropTypes.arrayOf(
        PropTypes.shape({
            displayName: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
        })
    ).isRequired,
    hasPosition: PropTypes.bool,
    name: PropTypes.string.isRequired,
};

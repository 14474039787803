import { fromJS } from 'immutable';

import * as authActions from '../actions/auth';
import * as categoryActions from '../actions/category';

const initialSelectedState = fromJS({
    categories: [],
    loadingSelectedCategory: false,
    loadSelectedCategoryError: null,
    searchCategoriesError: null,
    searchCategoriesResults: [],
    searchingCategories: false,
    selectedCategories: [],
    selectedCategorySet: null,
    showAllCategories: false,
});

const initialState = initialSelectedState.merge(
    fromJS({
        categories: [],
        customCategories: [],
        loaded: false,
    })
);

function customCategoryReducer(state, action) {
    if (state.get('id') !== action.id) return state;

    switch (action.type) {
        case categoryActions.DELETE_CUSTOM_CATEGORY:
            return state.merge(
                fromJS({
                    deleteError: null,
                    deleting: true,
                })
            );
        case categoryActions.DELETE_CUSTOM_CATEGORY_FAIL:
            return state.merge(
                fromJS({
                    deleteError: action.error && action.error.message,
                    deleting: false,
                })
            );
        case categoryActions.UPDATE_CUSTOM_CATEGORY:
            return state.merge(
                fromJS({
                    updateError: null,
                    updating: true,
                })
            );
        case categoryActions.UPDATE_CUSTOM_CATEGORY_SUCCESS:
            return fromJS(action.result);
        case categoryActions.UPDATE_CUSTOM_CATEGORY_FAIL:
            return state.merge(
                fromJS({
                    updateError: action.error && action.error.message,
                    updating: false,
                })
            );
        default:
            return state;
    }
}

export default function categoryReducer(state = initialState, action = {}) {
    switch (action.type) {
        case categoryActions.LOAD_CUSTOM_CATEGORIES:
            return state.merge(
                fromJS({
                    loadingCustomCategories: true,
                    loadedCustomCategories: false,
                    loadCustomCategoriesError: null,
                })
            );
        case categoryActions.LOAD_CUSTOM_CATEGORIES_SUCCESS:
            return state.merge(
                fromJS({
                    loadingCustomCategories: false,
                    loadedCustomCategories: true,
                    customCategories: fromJS(action.customCategories),
                })
            );
        case categoryActions.LOAD_CUSTOM_CATEGORIES_FAIL:
            return state.merge(
                fromJS({
                    loadingCustomCategories: false,
                    loadedCustomCategories: false,
                    loadCustomCategoriesError: action.error && action.error.message,
                })
            );
        case categoryActions.CREATE_CUSTOM_CATEGORY:
            return state.merge(
                fromJS({
                    createCustomCategoryError: null,
                    createdCustomCategory: false,
                    creatingCustomCategory: true,
                })
            );
        case categoryActions.CREATE_CUSTOM_CATEGORY_SUCCESS:
            return state.merge(
                fromJS({
                    createdCustomCategory: true,
                    creatingCustomCategory: false,
                    customCategories: state.get('customCategories').unshift(fromJS(action.result)),
                })
            );
        case categoryActions.CREATE_CUSTOM_CATEGORY_FAIL:
            return state.merge(
                fromJS({
                    createCustomCategoryError: action.error && action.error.message,
                    createdCustomCategory: false,
                    creatingCustomCategory: false,
                })
            );
        case categoryActions.DELETE_CUSTOM_CATEGORY_SUCCESS:
            return state.merge(
                fromJS({
                    customCategories: state
                        .get('customCategories')
                        .filter((customCategory) => customCategory.get('id') !== action.id),
                })
            );
        case categoryActions.DELETE_CUSTOM_CATEGORY:
        case categoryActions.DELETE_CUSTOM_CATEGORY_FAIL:
        case categoryActions.UPDATE_CUSTOM_CATEGORY:
        case categoryActions.UPDATE_CUSTOM_CATEGORY_SUCCESS:
        case categoryActions.UPDATE_CUSTOM_CATEGORY_FAIL:
            return state.set(
                'customCategories',
                state
                    .get('customCategories')
                    .map((customCategory) => customCategoryReducer(customCategory, action))
            );
        case categoryActions.LOAD_SUB_CATEGORIES:
            return state.merge(
                fromJS({
                    loadingSelectedCategory: true,
                    loadSelectedCategoryError: null,
                })
            );
        case categoryActions.LOAD_SUB_CATEGORIES_SUCCESS:
            return state
                .set('loadingSelectedCategory', false)
                .update('selectedCategories', (val) => val.push(fromJS(action.result)));
        case categoryActions.LOAD_SUB_CATEGORIES_FAIL:
            return state.merge(
                fromJS({
                    loadingSelectedCategory: false,
                    loadSelectedCategoryError: action.error && action.error.message,
                })
            );
        case categoryActions.SEARCH_CATEGORIES:
            return state.merge(
                fromJS({
                    searchingCategories: true,
                    searchCategoriesError: null,
                })
            );
        case categoryActions.SEARCH_CATEGORIES_SUCCESS:
            return state.merge(
                fromJS({
                    searchCategoriesResults: fromJS(action.result),
                    searchingCategories: false,
                })
            );
        case categoryActions.SEARCH_CATEGORIES_FAIL:
            return state.merge(
                fromJS({
                    searchingCategories: false,
                    searchCategoriesError: action.error && action.error.message,
                })
            );
        case categoryActions.LEVEL_UP:
            return state.update('selectedCategories', (val) => val.pop());
        case categoryActions.SHOW_ALL:
            return state.set('showAllCategories', true);
        case categoryActions.CHANGE_CATEGORY_SET:
            return state.merge(initialSelectedState).set('selectedCategorySet', action.setId);
        case categoryActions.RESET_CATEGORIES:
            return state.merge(initialSelectedState);
        case categoryActions.LOAD_CATEGORIES_SUCCESS:
            return state.merge(
                fromJS({
                    categories: fromJS(action.result),
                })
            );
        case authActions.LOGOUT_SUCCESS:
            return state.merge(
                fromJS({
                    // Custom categories are government specific
                    loadedCustomCategories: false,
                    loadCustomCategoriesError: null,
                })
            );
        default:
            return state;
    }
}

import { standardDocumentTypeNames } from '@og-pro/shared-config/standardDocuments';
import { SCORED, LOWEST_PRICE, LINE_ITEM_AWARD } from '@og-pro/shared-config/evaluations';

const { AWARD_NOTICE, INTERVIEW_INVITATION, NON_AWARD_LETTER } = standardDocumentTypeNames;

export function generateProjectReport(projectId, data) {
    return (dispatch, getState, client) => {
        return client.post(`/reports/projects/${projectId}`, { data });
    };
}

export function generateProjectReportWithAttachments(projectId, data) {
    return (dispatch, getState, client) => {
        return client.post(`/reports/projects/${projectId}/with-attachments`, { data });
    };
}

export function generateProjectProposalsReport(projectId, data) {
    return (dispatch, getState, client) => {
        return client.post(`/reports/projects/${projectId}/proposals`, { data });
    };
}

export function loadAddendumsReport(projectId) {
    return (dispatch, getState, client) => {
        const params = { view: 'government' };
        return Promise.all([
            client.get(`/project/${projectId}/addendums`, { params }),
            client.get(`/project/${projectId}/addendums/proposals`),
        ]);
    };
}

export function loadQuestionAnswerReport(projectId) {
    return (dispatch, getState, client) => {
        const params = { view: 'government' };
        return client.get(`/project/${projectId}/question`, { params });
    };
}

function loadStandardDocumentContent(governmentId, projectId, reportData, reportType) {
    return (dispatch, getState, client) => {
        let selectedStandardDocument;
        return client
            .get(`/government/${governmentId}/standard-documents`)
            .then((standardDocuments) => {
                selectedStandardDocument = standardDocuments.find((standardDocument) => {
                    return standardDocument.type === reportType && standardDocument.isActive;
                });

                if (!selectedStandardDocument) {
                    throw new Error(
                        'Notice has not been configured.\nPlease ask a system admin to set up this notice in the "Documents" section of the admin.'
                    );
                }

                const data = {
                    htmlContents: [selectedStandardDocument.text],
                    reportData,
                };
                return client.post(`/reports/projects/${projectId}/html-contents`, { data });
            })
            .then((htmlContents) => {
                return {
                    ...selectedStandardDocument,
                    text: htmlContents[0],
                };
            });
    };
}

export function loadAwardReport(governmentId, projectId, reportData) {
    return (dispatch) => {
        return dispatch(
            loadStandardDocumentContent(governmentId, projectId, reportData, AWARD_NOTICE)
        );
    };
}

export function loadEvaluationTabulationReport(projectId, evaluationType) {
    let path;

    switch (evaluationType) {
        case SCORED:
            path = 'proposal-evaluation-aggregate';
            break;
        case LOWEST_PRICE:
            path = 'bid-tabulation';
            break;
        case LINE_ITEM_AWARD:
            path = 'line-item-awards';
            break;
        default:
            break;
    }

    return (dispatch, getState, client) => {
        const params = { view: 'government' };
        return client.get(`/project/${projectId}/${path}`, { params });
    };
}

export function loadBidTabulationData(projectId) {
    return (dispatch, getState, client) => {
        return client.get(`/project/${projectId}/bid-tabulation`, {
            params: {
                view: 'government',
            },
        });
    };
}

export function loadInterviewReport(governmentId, projectId, reportData) {
    return (dispatch) => {
        return dispatch(
            loadStandardDocumentContent(governmentId, projectId, reportData, INTERVIEW_INVITATION)
        );
    };
}

export function loadNonAwardReport(governmentId, projectId, reportData) {
    return (dispatch) => {
        return dispatch(
            loadStandardDocumentContent(governmentId, projectId, reportData, NON_AWARD_LETTER)
        );
    };
}

export function loadAllProposals(projectId, status, opts) {
    const params = { status, opts };
    return (dispatch, getState, client) => {
        return client.get(`/project/${projectId}/proposal`, { params });
    };
}

function loadEligibleProposals(projectId, opts) {
    return (dispatch) => dispatch(loadAllProposals(projectId, 'evaluationEligible', opts));
}

export function loadAllProposalsReportOptions(projectId) {
    return (dispatch) => dispatch(loadAllProposals(projectId));
}

export function loadAwardOptions(projectId) {
    return (dispatch) => dispatch(loadEligibleProposals(projectId));
}

export function loadInterviewOptions(projectId) {
    return (dispatch) => dispatch(loadEligibleProposals(projectId));
}

export function loadNonAwardOptions(projectId) {
    return (dispatch) => dispatch(loadEligibleProposals(projectId));
}

export function loadProposalsWithQuestionnaireResponses(projectId) {
    return (dispatch) =>
        dispatch(loadEligibleProposals(projectId, 'includeQuestionnaireResponses'));
}

export function loadProjectAgreementData(projectId) {
    return (dispatch, getState, client) => {
        return client.get(`/project/${projectId}/agreement`);
    };
}

export function loadReverseAuctionSummaryReport(projectId) {
    return (dispatch, getState, client) => {
        return client.get(`/project/${projectId}/reverse-auction/government`);
    };
}

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { tokens } from '@opengov/capital-style';

import { QuestionPrompt } from '..';
import { OGThemeContext } from '../../../../containers/GovApp/ogThemeProvider';

const additionalQuestionsPrompt = `<p style="color: ${tokens.colors.colorGray700}; margin-top: -10px">Your answer to this question may show additional questions.</p>`;

export class ResponseFormPrompt extends PureComponent {
    static propTypes = {
        formComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
        input: PropTypes.object,
        isTemplateReadonlyView: PropTypes.bool,
        meta: PropTypes.shape({
            valid: PropTypes.bool,
            warning: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        }),
        showValidation: PropTypes.bool,
    };

    static defaultProps = {
        input: {},
        meta: {},
    };

    static contextType = OGThemeContext;

    renderFormComponent() {
        const { formComponent, ...props } = this.props;
        const { isOGThemeEnabledForComponents } = this.context;

        if (!formComponent) {
            return null;
        }

        const FormComponent = formComponent;
        return (
            <FormComponent
                {...props}
                isOGThemeEnabledForComponents={isOGThemeEnabledForComponents}
            />
        );
    }

    render() {
        const { formComponent, input, meta, ...props } = this.props;
        const { valid, warning } = meta;
        const subPrompt = props.conditionalSubQuestionIds?.length
            ? additionalQuestionsPrompt
            : null;
        const { isOGThemeEnabledForComponents } = this.context;

        return (
            <QuestionPrompt
                hideRequired
                htmlFor={input.name}
                isOGThemeEnabledForComponents={isOGThemeEnabledForComponents}
                showPublicDisclaimer
                subPrompt={subPrompt}
                valid={valid}
                warning={warning}
                {...props}
            >
                {this.renderFormComponent()}
            </QuestionPrompt>
        );
    }
}

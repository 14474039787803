const { uniq, map } = require('lodash');

exports.getFiscalYearOptions = (fiscalPeriods) => {
    const uniqueFiscalYears = uniq(map(fiscalPeriods, 'description'));

    return uniqueFiscalYears.map((year) => ({
        label: year,
        value: year,
    }));
};

exports.extractYear = (selectedFiscalPeriod, fiscalPeriods = []) => {
    const fiscalPeriod = fiscalPeriods.find(
        (period) => period.description === selectedFiscalPeriod
    );

    return fiscalPeriod ? fiscalPeriod.year : null;
};

import { fromJS } from 'immutable';

import * as templateSectionsActions from '../actions/templateSections';

const initialStateTemplateSectionEditState = {
    copyingItem: false,
    copyItemError: null,
    deleteItemError: null,
    deletingItem: false,
    loadingItem: false,
    loadItemError: null,
    templateSection: null,
    templateSectionProjects: null,
    updateItemError: null,
    updatingItem: false,
};

const initialState = fromJS({
    ...initialStateTemplateSectionEditState,
    loadedList: false,
    loadingList: false,
    loadListError: null,
    templateSections: [],
});

export default function templatesAdminReducer(state = initialState, action = {}) {
    switch (action.type) {
        case templateSectionsActions.CHECK_OUT_ITEM:
            return state.merge(
                fromJS({
                    checkingOutItem: true,
                    checkOutItemError: null,
                    templateSections: state.get('templateSections').map((templateSection) => {
                        if (templateSection.get('id') === action.templateSectionId) {
                            return templateSection.merge(
                                fromJS({
                                    checkingOut: true,
                                    checkOutError: null,
                                })
                            );
                        }
                        return templateSection;
                    }),
                })
            );
        case templateSectionsActions.CHECK_OUT_ITEM_FAIL:
            return state.merge(
                fromJS({
                    checkingOutItem: false,
                    checkOutItemError: action.error && action.error.message,
                    templateSections: state.get('templateSections').map((templateSection) => {
                        if (templateSection.get('id') === action.templateSectionId) {
                            return templateSection.merge(
                                fromJS({
                                    checkingOut: false,
                                    checkOutError: action.error && action.error.message,
                                })
                            );
                        }
                        return templateSection;
                    }),
                })
            );
        case templateSectionsActions.CHECK_OUT_ITEM_SUCCESS:
            return state.merge(
                fromJS({
                    checkingOutItem: false,
                    templateSection: state.get('templateSection')
                        ? state.get('templateSection').merge(
                              fromJS({
                                  checked_out_user_id: action.result.checked_out_user_id,
                                  checkedOutUser: action.result.checkedOutUser,
                              })
                          )
                        : null,
                    templateSections: state.get('templateSections').map((templateSection) => {
                        if (templateSection.get('id') === action.templateSectionId) {
                            return templateSection.merge(
                                fromJS({
                                    checked_out_user_id: action.result.checked_out_user_id,
                                    checkedOutUser: action.result.checkedOutUser,
                                    checkingOut: false,
                                })
                            );
                        }
                        return templateSection;
                    }),
                })
            );
        case templateSectionsActions.COPY_ITEM:
            return state.merge(
                fromJS({
                    copyingItem: true,
                    copyItemError: null,
                })
            );
        case templateSectionsActions.COPY_ITEM_FAIL:
            return state.merge(
                fromJS({
                    copyingItem: false,
                    copyItemError: action.error && action.error.message,
                })
            );
        case templateSectionsActions.COPY_ITEM_SUCCESS:
            return state.merge(
                fromJS({
                    copyingItem: false,
                })
            );
        case templateSectionsActions.DELETE_ITEM:
            return state.merge(
                fromJS({
                    deleteItemError: null,
                    deletingItem: true,
                })
            );
        case templateSectionsActions.DELETE_ITEM_FAIL:
            return state.merge(
                fromJS({
                    deleteItemError: action.error && action.error.message,
                    deletingItem: false,
                })
            );
        case templateSectionsActions.DELETE_ITEM_SUCCESS:
            return state.merge(
                fromJS({
                    deletingItem: false,
                })
            );
        case templateSectionsActions.LOAD_ITEM:
            return state.merge(
                fromJS({
                    loadingItem: true,
                    loadItemError: null,
                })
            );
        case templateSectionsActions.LOAD_ITEM_FAIL:
            return state.merge(
                fromJS({
                    loadingItem: false,
                    loadItemError: action.error && action.error.message,
                })
            );
        case templateSectionsActions.LOAD_ITEM_SUCCESS:
            return state.merge(
                fromJS({
                    checkingOutItem: false, // Check out item's state could be set from list view
                    checkOutItemError: null, // Check out item's state could be set from list view
                    loadingItem: false,
                    templateSection: fromJS(action.result),
                })
            );
        case templateSectionsActions.LOAD_LIST:
            return state.merge(
                fromJS({
                    loadingList: true,
                    loadListError: null,
                })
            );
        case templateSectionsActions.LOAD_LIST_FAIL:
            return state.merge(
                fromJS({
                    loadingList: false,
                    loadListError: action.error && action.error.message,
                })
            );
        case templateSectionsActions.LOAD_LIST_SUCCESS:
            return state.merge(
                fromJS({
                    loadedList: true,
                    loadingList: false,
                    templateSections: fromJS(action.result),
                })
            );
        case templateSectionsActions.LOAD_TEMPLATE_SECTION_PROJECTS:
            return state.set('templateSectionProjects', fromJS(action.result));
        case templateSectionsActions.RESET_EDIT:
            return state.merge(fromJS(initialStateTemplateSectionEditState));
        case templateSectionsActions.RESET_LIST:
            return state.merge(
                fromJS({
                    loadedList: false,
                })
            );
        case templateSectionsActions.UPDATE_ITEM:
            return state.merge(
                fromJS({
                    updatingItem: true,
                    updateItemError: null,
                })
            );
        case templateSectionsActions.UPDATE_ITEM_FAIL:
            return state.merge(
                fromJS({
                    updatingItem: false,
                    updateItemError: action.error && action.error.message,
                })
            );
        case templateSectionsActions.UPDATE_ITEM_SUCCESS:
            return state.merge(
                fromJS({
                    templateSection: fromJS(action.result),
                    updatingItem: false,
                })
            );
        default:
            return state;
    }
}

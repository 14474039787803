import { createSelector } from 'reselect';

import { navItems } from './constants';

export const getNavItems = createSelector(
    [(state, props) => props.params.governmentId],
    (governmentId) => {
        return navItems.map((item) => {
            return {
                ...item,
                route: `/governments/${governmentId}/emergency-portal/${item.path}`,
            };
        });
    }
);

export const getSelectedRoute = createSelector(
    [getNavItems, (state, props) => props.location.pathname],
    (navs, pathname) => {
        return (navs.find((item) => pathname.match(new RegExp(`^${item.route}`))) || navs[0]).route;
    }
);

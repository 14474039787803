import { pick, reduce } from 'lodash';

import { convertToDate } from '../utils';

export function deserializeProjectDates(project, dateFields, timezone) {
    const projectDateFields = pick(project, dateFields);
    const dateValues = reduce(
        projectDateFields,
        (dateObj, date, field) => {
            const dateValue = date && convertToDate(date, timezone);
            return {
                ...dateObj,
                [field]: dateValue,
            };
        },
        {}
    );

    const timelines = project.timelines.map((timeline) => ({
        ...timeline,
        date: convertToDate(timeline.date, timezone),
        showLocation: timeline.showLocation || !!timeline.location, // Needed by the form to properly validate
    }));

    return {
        ...dateValues,
        timelines,
    };
}

import moment from 'moment';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Panel, PanelGroup } from 'react-bootstrap';
import { Box } from '@og-pro/ui';

import { CDSButton, StatusLabel } from '../../../../components';
import { WordTemplatesListForm } from './DocxTemplateForm/ListForm';
import { HEADING_FORMATTERS, TITLE } from './DocxTemplateForm/constants';
import { WORD_TEMPLATES_LIST_PROP_TYPES } from './types';

export const SDv2WordTemplatesList = ({
    docxTemplates,
    updating,
    uploading,
    updateDefaultDocxTemplate,
    deleteHandler,
    updateDocxTemplate,
}) => {
    const [activeKey, setActiveKey] = useState(null);
    const styles = require('./shared.scss');

    useEffect(() => {
        setActiveKey(0);
    }, [docxTemplates.length]);

    const onSelect = (value) => {
        setActiveKey(value);
    };

    return (
        <PanelGroup
            accordion
            activeKey={activeKey}
            className={styles.panelGroup}
            id="docxList"
            onSelect={onSelect}
        >
            {docxTemplates.map((docxTemplate, index) => {
                const {
                    updated_at: updatedAt,
                    governmentDocxTemplates: { isDefault, headingFormatters },
                    id,
                    title,
                    url,
                } = docxTemplate;

                return (
                    <Panel eventKey={index} key={index} role="tab">
                        <Panel.Heading
                            className={classnames(styles.panelHeading, {
                                [styles.panelHeadingOpen]: activeKey === index,
                            })}
                            role="presentation"
                        >
                            <Panel.Title toggle>
                                <Box alignItems="center" display="flex">
                                    <Box alignItems="bottom" display="flex" flex={5}>
                                        <Box>
                                            <i
                                                className={classnames('fa', styles.chevron, {
                                                    'fa-chevron-right': activeKey !== index,
                                                    'fa-chevron-down': activeKey === index,
                                                })}
                                            />
                                        </Box>

                                        <Box className={styles.panelTitle}>{title}</Box>
                                        {isDefault && (
                                            <Box
                                                component="h4"
                                                display="inline"
                                                fontWeight="normal"
                                                ml={2}
                                            >
                                                <StatusLabel>DEFAULT</StatusLabel>
                                            </Box>
                                        )}
                                    </Box>
                                    <Box
                                        className={styles.timestamp}
                                        flex={1}
                                        textAlign="right"
                                        title={moment(updatedAt).format('lll')}
                                    >
                                        Updated {moment(updatedAt).fromNow()}
                                    </Box>
                                </Box>
                            </Panel.Title>
                        </Panel.Heading>
                        <Panel.Body className={styles.panelBody} collapsible>
                            <WordTemplatesListForm
                                actions={
                                    <div className="text-right">
                                        {!isDefault && (
                                            <Box mb={2}>
                                                <CDSButton
                                                    disabled={uploading || updating}
                                                    onClick={() => updateDefaultDocxTemplate(id)}
                                                    qaTag="docxTemplates-makeDefault"
                                                    size="small"
                                                    tooltip="Make default template"
                                                    variant="secondary"
                                                >
                                                    <i className="fa fa-bookmark-o fa-fw" /> Set As
                                                    Default
                                                </CDSButton>
                                            </Box>
                                        )}
                                        <Box mb={2}>
                                            <a href={url}>
                                                <CDSButton
                                                    aria-label="Download CDSButton"
                                                    disabled={uploading || updating}
                                                    qaTag="docxTemplates-download"
                                                    size="small"
                                                    tooltip="Download"
                                                    variant="secondary"
                                                >
                                                    <i className="fa fa-download fa-fw" /> Download
                                                </CDSButton>
                                            </a>
                                        </Box>
                                        <Box>
                                            <CDSButton
                                                aria-label="Delete Button"
                                                disabled={uploading || updating || isDefault}
                                                onClick={() => deleteHandler(id)}
                                                qaTag="docxTemplates-delete"
                                                size="small"
                                                tooltip={
                                                    isDefault
                                                        ? 'Please designate a different default template before deleting'
                                                        : 'Delete template'
                                                }
                                                variant="destructive"
                                            >
                                                <i className="fa fa-trash fa-fw" /> Delete
                                            </CDSButton>
                                        </Box>
                                    </div>
                                }
                                docxTemplate={docxTemplate}
                                form={`docxTemplatesHeadings-${index}`}
                                initialValues={{
                                    [TITLE]: docxTemplate.title,
                                    [HEADING_FORMATTERS]: headingFormatters || {},
                                }}
                                onClose={() => setActiveKey(null)}
                                onSubmit={(values) => updateDocxTemplate(docxTemplate.id, values)}
                            />
                        </Panel.Body>
                    </Panel>
                );
            })}
        </PanelGroup>
    );
};

SDv2WordTemplatesList.propTypes = WORD_TEMPLATES_LIST_PROP_TYPES;

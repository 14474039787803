import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { reduxForm, Field } from 'redux-form';

import { form, fieldNames } from './constants';
import { validate } from './validate';
import { Button, InputText, SearchSelect } from '../..';

const { TITLE, APPENDIX_ID } = fieldNames;

const formConfig = {
    form,
    validate,
};

// @reduxForm
class ConnectedAttachmentRowItemForm extends PureComponent {
    static propTypes = {
        appendixOptions: PropTypes.array.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        hideAppendixLetter: PropTypes.bool,
        toggleEdit: PropTypes.func.isRequired,
        updating: PropTypes.bool,
    };

    render() {
        const { appendixOptions, handleSubmit, hideAppendixLetter, toggleEdit, updating } =
            this.props;

        return (
            <div className="row">
                {!hideAppendixLetter && (
                    <div className="col-sm-3 col-md-2">
                        <Field
                            component={SearchSelect}
                            disabled={updating}
                            hideDisabledMenuOptions
                            isOptionDisabled={(option) => option.disabled}
                            isSearchable={false}
                            name={APPENDIX_ID}
                            options={appendixOptions}
                        />
                    </div>
                )}
                <div className={hideAppendixLetter ? 'col-sm-9 col-md-10' : 'col-sm-6 col-md-8'}>
                    <Field
                        component={InputText}
                        disabled={updating}
                        hasFeedback={false}
                        name={TITLE}
                    />
                </div>
                <div className="col-sm-3 col-md-2 text-center">
                    <Button
                        block
                        bsSize="sm"
                        bsStyle="primary"
                        disabled={updating}
                        onClick={handleSubmit}
                    >
                        Update
                    </Button>
                    <Button bsSize="sm" bsStyle="link" disabled={updating} onClick={toggleEdit}>
                        Cancel
                    </Button>
                </div>
            </div>
        );
    }
}

export const AttachmentRowItemForm = reduxForm(formConfig)(ConnectedAttachmentRowItemForm);

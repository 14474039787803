import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import {
    getQuestionLogicValueDisplayFromQuestion,
    questionLogicOperatorNamesDisplay,
    getYesNoQuestionLogicValueDisplay,
    questionLogicLinkableModelNames,
    questionLogicLogicableModelNames,
} from '@og-pro/shared-config/questionLogics';

const wording = (useOpenGovStyle) => (useOpenGovStyle ? 'Appears when' : 'When');

export const QuestionnaireQuestionLogicDisplay = ({
    isDocx,
    questionLogic,
    questionnaires,
    useOpenGovStyle,
}) => {
    if (questionLogic.linkable !== questionLogicLinkableModelNames.LINKED_QUESTIONNAIRE) {
        return null;
    }

    const parent = questionnaires.find((q) => q.id === questionLogic.logicable_id);

    return (
        <QuestionLogicDisplay
            isDocx={isDocx}
            logicableItem={parent}
            questionLogic={questionLogic}
            useOpenGovStyle={useOpenGovStyle}
        />
    );
};

QuestionnaireQuestionLogicDisplay.propTypes = {
    isDocx: PropTypes.bool,
    questionLogic: PropTypes.shape({
        operator: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
        logicable_id: PropTypes.number.isRequired,
        linkable: PropTypes.string.isRequired,
    }).isRequired,
    questionnaires: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
        })
    ).isRequired,
    useOpenGovStyle: PropTypes.bool,
};

const GenericQuestionQuestionLogicDisplay = ({
    isDocx,
    questionLogic,
    upfrontOrQuestionnaireQuestion,
    useOpenGovStyle,
}) => {
    const { operator, value } = questionLogic;

    const styles = require('./index.scss');

    const style = isDocx ? { marginBottom: 0, marginTop: 10 } : {};

    return (
        <div
            className={classnames(styles.questionLogic, {
                [styles.questionLogicDocx]: isDocx,
                [styles.openGovStyle]: useOpenGovStyle,
            })}
            style={style}
        >
            {wording(useOpenGovStyle)} {questionLogicOperatorNamesDisplay[operator]} &quot;
            {getQuestionLogicValueDisplayFromQuestion(upfrontOrQuestionnaireQuestion, value)}&quot;
        </div>
    );
};

GenericQuestionQuestionLogicDisplay.propTypes = {
    isDocx: PropTypes.bool,
    questionLogic: PropTypes.shape({
        operator: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
    }).isRequired,
    upfrontOrQuestionnaireQuestion: PropTypes.object.isRequired,
    useOpenGovStyle: PropTypes.bool,
};

export const QuestionLogicDisplay = ({ isDocx, useOpenGovStyle, questionLogic, logicableItem }) => {
    // if the logicable is either an UpfrontQuestion or a Questionnaire
    // and we have the logicable itself, show the classic question logic display
    // ex: "When x equals Yes then..."
    if (
        [
            questionLogicLogicableModelNames.UPFRONT_QUESTION,
            questionLogicLogicableModelNames.QUESTIONNAIRE,
        ].includes(questionLogic.logicable) &&
        logicableItem
    ) {
        return (
            <GenericQuestionQuestionLogicDisplay
                isDocx={isDocx}
                questionLogic={questionLogic}
                upfrontOrQuestionnaireQuestion={logicableItem}
                useOpenGovStyle={useOpenGovStyle}
            />
        );
    }

    // handles the legacy case for conditional upfront questions where the
    // component is used passing questionLogic with the logicableUpfrontQuestion included within
    if (
        questionLogicLogicableModelNames.UPFRONT_QUESTION === questionLogic.logicable &&
        questionLogic.logicableUpfrontQuestion
    ) {
        return (
            <GenericQuestionQuestionLogicDisplay
                isDocx={isDocx}
                questionLogic={questionLogic}
                upfrontOrQuestionnaireQuestion={questionLogic.logicableUpfrontQuestion}
                useOpenGovStyle={useOpenGovStyle}
            />
        );
    }

    const { operator, value } = questionLogic;
    const styles = require('./index.scss');

    // if there's no logicableItem it's a linked questionnaire
    // only yes/no supported for these
    return (
        <div
            className={classnames(styles.questionLogic, { [styles.openGovStyle]: useOpenGovStyle })}
        >
            {wording(useOpenGovStyle)} {questionLogicOperatorNamesDisplay[operator]} &quot;
            {getYesNoQuestionLogicValueDisplay(value)}&quot;
        </div>
    );
};

QuestionLogicDisplay.propTypes = {
    isDocx: PropTypes.bool,
    logicableItem: PropTypes.object,
    questionLogic: PropTypes.shape({
        logicable: PropTypes.oneOf(Object.values(questionLogicLogicableModelNames)).isRequired,
        logicableUpfrontQuestion: PropTypes.object,
        operator: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
    }).isRequired,
    useOpenGovStyle: PropTypes.bool,
};

import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';

const getInvitedVendorList = (state) => state.vendorList.get('invitedVendorList');
export const getVendorClassificationTotals = (state) =>
    state.vendorList.get('vendorClassificationTotals').toJS();
export const getVendorCertificationsTotalsChartData = createSelector(
    [getVendorClassificationTotals],
    (vendorClassificationTotals) => {
        return vendorClassificationTotals.map((classification) => ({
            name: classification.title,
            y: classification.total,
            percent: classification.percent,
        }));
    }
);

export const getVendorClassificationTotalsLoading = (state) =>
    state.vendorList.get('loadingClassificationTotal');
export const getVendorSearchDownloading = (state) =>
    state.vendorList.get('vendorSearchDownloading');

export const getLoadingInvitedVendorList = (state) =>
    state.vendorList.get('loadingInvitedVendorList');

export const getInvitedVendorListJS = createSelector(
    [getInvitedVendorList],
    (rawInvitedVendorList) => rawInvitedVendorList.toJS()
);

export const getInvitedVendorOutstandingCount = createSelector(
    [getInvitedVendorListJS],
    (invitedVendorList) =>
        invitedVendorList.filter((pendingUser) => !pendingUser.isConverted).length
);

const getVendorCertifications = (state) => state.adminVendor.get('vendorCertifications');

export const getAllVendorCertificationsJS = createSelector(
    [getVendorCertifications],
    (certifications) => certifications.toJS()
);

export const getVendorListsLoading = (state) => state.vendorList.get('loadingVendorLists');
export const getVendorLists = (state) => state.vendorList.get('lists').toJS();
export const getVendorList = (state, id) => {
    return state.vendorList.get('vendorListDetail').toJS()[id];
};
export const getVendorListLoading = (state) => state.vendorList.get('loadingVendorList');
export const getVendorListLoadingError = (state) => state.vendorList.get('loadingVendorListError');
export const getIsVendorListSearch = (state) => state.vendorList.get('isVendorListSearch');

export const getShowAddVendorListModal = (state) =>
    state.vendorList.get('showAddVendorsToListModal');
export const getShowCustomEmailModal = (state) => state.app.get('showCustomEmailModal');
export const getShowInviteVendorListToProjectModal = (state) =>
    state.vendorList.get('showInviteVendorListToProjectModal');

export const getVendorUsersLists = (state, id) => {
    return state.vendorList.get('vendorUsersLists').toJS()[id] || [];
};

export const getVendorSearchParams = (state) => state.vendorList.get('vendorSearchParams').toJS();
export const getVendorSearchError = (state) => state.vendorList.get('vendorSearchError');

export const getVendorSearchActive = createSelector([getVendorSearchParams], (rawSearchParams) => {
    const searchParams = rawSearchParams;
    return !isEmpty(searchParams.search);
});

export const getSubscribedVendorCertifications = (state) =>
    state.vendorList.get('subscribedVendorCertifications').toJS();
export const getSubscribedVendorCertificationsLoading = (state) =>
    state.vendorList.get('loadingSubscribedVendorCertifications');

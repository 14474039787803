import PropTypes from 'prop-types';
import React from 'react';

export const StepApprovalLog = ({ requisitionLog }) => {
    const { details, text } = requisitionLog;
    const styles = require('./StepApprovalLog.scss');

    return (
        <div>
            {text}
            {details && <div className={styles.blockquote}>{details}</div>}
        </div>
    );
};

StepApprovalLog.propTypes = {
    requisitionLog: PropTypes.shape({
        details: PropTypes.string,
        text: PropTypes.string.isRequired,
    }).isRequired,
};

import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';

import { isTinyMceModalOpenSelector } from '../../../../App/selectors';
import { ModalBody } from './ModalBody';

export const TemplateQuestionCreateModal = ({ hideModal }) => {
    const isTinyMceModalOpen = useSelector(isTinyMceModalOpenSelector);

    return (
        <Modal bsSize="lg" enforceFocus={!isTinyMceModalOpen} onHide={hideModal} show>
            <Modal.Header closeButton>
                <Modal.Title className="text-center">Create Shared Question</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ModalBody hideModal={hideModal} />
            </Modal.Body>
        </Modal>
    );
};

TemplateQuestionCreateModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
};

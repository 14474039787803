import { projectScopeFilterTypesDict } from '@og-pro/shared-config/projects';

const { MY_PROJECTS } = projectScopeFilterTypesDict;

/**
 * Takes a list of parameters for GetProjects, formats them, and sets default scope.
 * @param  {object[]} queryParams Key value pairs of parameters from a url
 * @param  {boolean} [isDocBuilder] Notes whether the project is a document builder project or not
 * @param  {boolean} [isIntake] Notes whether the project is currently an intake or not
 * @return {object} Formatted query parameters for get projects request
 */
export const prepareGetProjectsQuery = (queryParams, isDocBuilder, isIntake) => {
    return {
        ...queryParams,
        isDocBuilder,
        isIntake,
        scope: queryParams.scope || MY_PROJECTS, // Use `MY_PROJECTS` scope by default
    };
};

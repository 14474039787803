import { actionTypes } from '@og-pro/shared-config/requisitionLogs';
import { reviewTypes } from '@og-pro/shared-config/reviewSequences';
import moment from 'moment';

export const actionTypesToDisplayInSteps = [
    actionTypes.APPROVE,
    actionTypes.BUDGET_OVERRIDE,
    actionTypes.CLOSED_REJECTED,
    actionTypes.CLOSED_PO_CREATED,
    actionTypes.REJECT,
];

const getApproveActionName = (reviewType) => {
    switch (reviewType) {
        case reviewTypes.CONFIRM:
            return 'Acknowledged by';
        case reviewTypes.NOTIFY:
            return 'Notified';
        case reviewTypes.APPROVE:
        default:
            return 'Approved by';
    }
};

export const getRequisitionLogText = (requisitionLog, reviewSequenceStep) => {
    const { actionType, date, user } = requisitionLog;
    if (actionType === actionTypes.APPROVE) {
        const action = getApproveActionName(reviewSequenceStep.reviewType);
        return `${action} ${user.displayName} on ${moment(date).format('M/D/YY [at] h:mma')}`;
    }
    if (actionType === actionTypes.BUDGET_OVERRIDE) {
        return `Budget Override by ${user.displayName} on ${moment(date).format(
            'M/D/YY [at] h:mma'
        )}`;
    }
    if (actionType === actionTypes.CLOSED_PO_CREATED) {
        return `Completed by ${user.displayName} on ${moment(date).format('M/D/YY [at] h:mma')}`;
    }
    if (actionType === actionTypes.REJECT || actionType === actionTypes.CLOSED_REJECTED) {
        return `Rejected by ${user.displayName} on ${moment(date).format('M/D/YY [at] h:mma')}`;
    }
    return 'Unknown event';
};

import { listToDict } from '@og-pro/shared-config/helpers';
import {
    questionLogicActionNames,
    questionLogicOperatorNames,
} from '@og-pro/shared-config/questionLogics';

const { EQUAL, INCLUDE, NOT_EQUAL, NOT_INCLUDE } = questionLogicOperatorNames;
const { HIDE, SHOW } = questionLogicActionNames;

export const form = 'questionLogicForm';

export const actionSelectOptions = [
    {
        icon: 'ban',
        label: HIDE.toUpperCase(),
        value: HIDE,
    },
    {
        icon: 'check',
        label: SHOW.toUpperCase(),
        value: SHOW,
    },
];

// Pseudo form fields used to include display data with the form
const pseudoFields = ['linkableItem', 'projectSection', 'projectSectionId', 'upfrontQuestion'];
export const pseudoFieldNames = listToDict(pseudoFields);

export const VALUE_TYPE = {
    [EQUAL]: 'single',
    [NOT_EQUAL]: 'single',
    [INCLUDE]: 'multi',
    [NOT_INCLUDE]: 'multi',
};
export const MULTI_VALUE_TYPES = [INCLUDE, NOT_INCLUDE];

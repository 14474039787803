import PropTypes from 'prop-types';

import { TIMELINE_PROPTYPE } from '../../constants';

export const PROP_TYPES = {
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    isTemplate: PropTypes.bool,
    showValidation: PropTypes.bool,
    timeline: TIMELINE_PROPTYPE.isRequired,
    timezone: PropTypes.string,
};

export const AGENDA_FILE_MODE = {
    MANUAL: 'manual',
    UPLOAD: 'upload',
};

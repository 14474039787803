import { createSelector } from 'reselect';

const getChecklistTemplates = (state) => state.adminGovernment.get('checklistTemplates');

export const getPublishedChecklistTemplates = createSelector(
    [getChecklistTemplates],
    (checklistTemplates) => {
        return checklistTemplates
            .toJS()
            .filter(
                (checklistTemplate) => checklistTemplate.isPublished && !checklistTemplate.archived
            );
    }
);

import Highcharts from 'highcharts';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { getContractsByVendorCertsData } from './helpers';

const CHART_TITLE = 'Active Contracts By Vendor Certifications';

class ConnectedContractsByVendorCerts extends PureComponent {
    static propTypes = {
        certifications: PropTypes.array.isRequired,
        contracts: PropTypes.array.isRequired,
        router: PropTypes.object.isRequired,
    };

    componentDidMount() {
        this.renderChart();
    }

    renderChart() {
        const { certifications, contracts, router } = this.props;

        const { categories, series } = getContractsByVendorCertsData(
            contracts,
            certifications,
            router
        );

        // eslint-disable-next-line new-cap
        return new Highcharts.chart({
            chart: {
                type: 'bar',
                renderTo: 'contracts-by-vendor-certs',
                borderWidth: 1,
                borderColor: '#dce0e0',
                style: {
                    fontWeight: 500,
                    fontSize: '18px',
                    fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
                },
            },
            title: {
                text: CHART_TITLE,
            },
            xAxis: {
                categories,
                title: {
                    text: null,
                },
            },
            yAxis: {
                min: 0,
                title: {
                    text: null,
                },
                allowDecimals: false,
                endOnTick: false,
            },
            legend: {
                enabled: false,
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true,
                    },
                },
            },
            credits: {
                enabled: false,
            },
            series,
        });
    }

    render() {
        return <div id="contracts-by-vendor-certs" />;
    }
}

export const ContractsByVendorCerts = withRouter(ConnectedContractsByVendorCerts);

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { noBidProposalForm } from '../constants';
import { NO_BID_REASON, noBidOptions } from './constants';
import { MultiChoiceWithOtherForm } from '../MultiChoiceWithOtherForm';

export class NoBidForm extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        onSubmit: PropTypes.func.isRequired,
    };

    render() {
        const { disabled, onSubmit } = this.props;

        return (
            <MultiChoiceWithOtherForm
                disabled={disabled}
                fieldName={NO_BID_REASON}
                form={noBidProposalForm}
                info="Please specify why you are not bidding on the project."
                onSubmit={onSubmit}
                options={noBidOptions}
                otherLabel="Other Reason for No Bidding"
            />
        );
    }
}

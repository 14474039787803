import { capitalDesignTokens } from '@opengov/capital-mui-theme';

export const customFieldsExitConfirmationModalContainer = {
    bgcolor: 'background.paper',
    border: `1px ${capitalDesignTokens.foundations.colors.gray300} solid`,
    borderRadius: 1,
    boxShadow: '0px 4px 8px #000000',
    display: 'flex',
    flexDirection: 'column',
    left: '50%',
    overflow: 'hidden',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '600px',
};

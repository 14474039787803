import { isNil } from 'lodash';
import moment from 'moment-timezone';

// Background:
// https://github.com/SheetJS/sheetjs#dates
// https://github.com/oleg-koval/excel-date-to-js
const convertExcelDateToJSDate = (excelDate, columnName, rowNum) => {
    if (!Number(excelDate)) {
        throw new Error(`Wrong input format for column "${columnName}" at row #${rowNum}`);
    }

    const secondsInDay = 24 * 60 * 60;
    const missingLeapYearDay = secondsInDay * 1000;
    const delta = excelDate - (25567 + 2);
    const parsed = delta * missingLeapYearDay;
    const date = new Date(parsed);

    if (Object.prototype.toString.call(date) !== '[object Date]') {
        throw new Error(
            `Could not convert excel date input for column "${columnName}" at row #${rowNum}`
        );
    }

    if (Number.isNaN(date.getTime())) {
        throw new Error(`Wrong excel date input for column "${columnName}" at row #${rowNum}`);
    }

    // Dates are provided in UTC time, but we want to store them in local time
    const utcDate = moment(date).utc();
    const year = utcDate.year();
    const month = utcDate.month();
    const day = utcDate.date();
    const hour = utcDate.hour();
    const minute = utcDate.minute();
    const second = utcDate.second();
    const millisecond = utcDate.millisecond();

    return new Date(year, month, day, hour, minute, second, millisecond);
};

const getRowNumber = (rowFromSpreadsheet) => {
    return rowFromSpreadsheet.__rowNum__ + 1; // eslint-disable-line no-underscore-dangle
};

export const getErrorLocation = (rowFromSpreadsheet) => {
    const rowNum = getRowNumber(rowFromSpreadsheet);
    return `[Error in Row ${rowNum} of the imported file]\n`;
};

// Iterate over all columns in a row to create an object from the row data
export const prepareAndValidateRowEntry = (rowFromSpreadsheet, columnHeaderTableProperties) => {
    const rowData = {};

    // Check data for each column in the row and build an item using the item's fields instead of
    // the spreadsheet headers
    Object.entries(rowFromSpreadsheet).forEach(([rawHeaderKey, originalValue]) => {
        let value = originalValue;
        // We want header checks to be case-insensitive, but XLSX parses the headers
        // as-written, so we need to overwrite to lower case here
        const headerKey = rawHeaderKey.toLowerCase();

        // Skip if headerKey is not in the table properties
        if (!(headerKey in columnHeaderTableProperties)) {
            return;
        }

        const rowNum = getRowNumber(rowFromSpreadsheet);
        const errorLocation = getErrorLocation(rowFromSpreadsheet);
        const properties = columnHeaderTableProperties[headerKey];
        const typeErrorMsg =
            `${errorLocation}` +
            `"${rawHeaderKey}" values must be of type ${properties.enforceType}': ` +
            `"${originalValue}" in row ${rowNum} of the file is not a ${properties.enforceType}.`;
        const requiredErrorMsg =
            `${errorLocation}` +
            `"${rawHeaderKey}" is a required column: ` +
            `row ${rowNum} of the file has an empty value.`;

        // Validate the specified type is used and convert to specified format
        if (!isNil(value) && value !== '') {
            switch (properties.enforceType) {
                case 'boolean': {
                    if (typeof value !== 'boolean') {
                        // Coerce string boolean values to boolean type (CSV cannot format as boolean)
                        if (typeof value === 'string') {
                            if (value.toLowerCase() === 'true' || value.toLowerCase() === 'false') {
                                value = value.toLowerCase() === 'true';
                                break;
                            }
                        }
                        throw new Error(typeErrorMsg);
                    }
                    break;
                }
                case 'date': {
                    // By default, Excel stores dates as numbers with a format code that specifies date
                    // processing. For example, the date 19-Feb-17 is stored as the number 42785 with a
                    // number format of d-mmm-yy. The SSF module understands number formats and performs
                    // the appropriate conversion
                    if (Number.isNaN(value)) {
                        value = new Date(value);
                    } else {
                        value = convertExcelDateToJSDate(value, rawHeaderKey, rowNum);
                    }
                    if (!moment(value).isValid()) {
                        throw new Error(typeErrorMsg);
                    }
                    break;
                }
                case 'number':
                    value = Number(value);
                    if (Number.isNaN(value)) {
                        throw new Error(typeErrorMsg);
                    }
                    break;
                default: {
                    if (properties.convertToString) {
                        value = value.toString();
                    }
                    if (
                        properties.maxLength &&
                        typeof value === 'string' &&
                        value.length > properties.maxLength
                    ) {
                        value = value.slice(0, properties.maxLength);
                    }
                    break;
                }
            }
        } else if (properties.requireValue) {
            throw new Error(requiredErrorMsg);
        }

        rowData[properties.itemProperty] = value;
    });

    return rowData;
};

// Read the given File object and return a promise containing all text in the file
const readFile = (file, readAsText = true) => {
    // Convert the callback- and event-based FileReader api to a promise
    return new Promise((resolve, reject) => {
        const fr = new FileReader();
        if (readAsText) {
            fr.readAsText(file);
        } else {
            fr.readAsArrayBuffer(file);
        }
        fr.onerror = (error) => {
            reject(error);
        };
        fr.onloadend = () => {
            if (fr.readyState !== 2) {
                reject(new Error('unable to read file'));
            } else if (fr.error) {
                reject(fr.error);
            } else {
                resolve(fr.result);
            }
        };
    });
};

const parseFileToJSON = (file, opts) => {
    if (!window.XLSX) {
        throw new Error('XLSX package not found');
    }

    // Parse the file with XLSX library, return an array of objects representing all rows
    return readFile(file, false).then((arrayBuffer) => {
        const data = new Uint8Array(arrayBuffer);
        const { headers = {}, readOpts = {}, sheetToJsonOpts = {} } = opts;
        const workbook = window.XLSX.read(data, { type: 'array', ...readOpts });
        const sheetNames = opts.readOpts?.sheets;

        if (sheetNames) {
            return sheetNames.map((sheetName) => {
                const worksheet = workbook.Sheets[sheetName];
                const header = headers?.[sheetName];
                return window.XLSX.utils.sheet_to_json(worksheet, { header, ...sheetToJsonOpts });
            });
        }

        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];

        // Note: header rows are accounted for and skipped by default in XLSX.utils.sheet_to_json
        // Docs: https://www.npmjs.com/package/xlsx#json
        return window.XLSX.utils.sheet_to_json(worksheet, { ...sheetToJsonOpts });
    });
};

/**
 * Parse the given .xlsx file, return result as a promise
 * WARNING: Can only be run in the browser (XLSX package not bundled)
 * @param {file} file The file to parse
 * @param {object} opts Options to pass to the XLSX library
 * @param {object} [opts.headers] An object mapping sheet names to custom header rows
 * @param {object} [opts.readOpts] Options to pass to the XLSX read function
 * @param {object} [opts.sheetToJsonOpts] Options to pass to the XLSX sheet_to_json function
 * @return {Promise<object[]>} A promise containing an array of row objects representing all rows in the file.
 *                             Will be an array of arrays of row objects if multiple sheets are parsed.
 */
export const parseXLSXCompatibleFileToJSON = (file, opts = {}) => {
    // Could also do this check (perhaps more robustly) by checking `file.type` (not `file.name`)
    // For now I'm NOT doing that because I believe the string returned may vary across platforms,
    // so it's MUCH easier (and somewhat more consistent) to check the file suffix instead.

    // NOTE: the XLSX library we're using can work for MANY file types.
    // If required in future, we would only need to relax this regex and everything should just work
    // for those other files as well.  The full list of compatible file types is available here:
    // https://www.npmjs.com/package/xlsx#file-formats
    const fileIsXLSXCompatible = /.*\.(csv|ods|xlsx|xls)$/.test(file.name);
    if (!fileIsXLSXCompatible) {
        return new Promise((resolve, reject) => {
            reject(new Error('invalid file type, must be a *.csv, *.xlsx, *.xls, or *.ods file'));
        });
    }

    return parseFileToJSON(file, opts);
};

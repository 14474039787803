import PropTypes from 'prop-types';
import React from 'react';

import { EntityPositionsTableRow } from './EntityPositionsTableRow';
import { Table } from '../../../../components';

export const EntityPositionsTable = ({ onDelete, onEdit, positions }) => {
    return (
        <Table>
            <colgroup>
                <col style={{ width: '28%' }} />
                <col style={{ width: '28%' }} />
                <col style={{ width: '28%' }} />
                <col style={{ width: '16%', minWidth: 80 }} />
            </colgroup>
            <thead>
                <tr>
                    <th>Position Names</th>
                    <th>Description</th>
                    <th>Assigned User</th>
                    <th aria-label="Empty cell">&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                {positions.map((position) => {
                    return (
                        <EntityPositionsTableRow
                            key={position.id}
                            onDelete={onDelete}
                            onEdit={onEdit}
                            position={position}
                        />
                    );
                })}
            </tbody>
        </Table>
    );
};

EntityPositionsTable.propTypes = {
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    positions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
        })
    ).isRequired,
};

import { fromJS } from 'immutable';

import * as adminGovernmentActions from '../actions/adminGovernment';
import * as authActions from '../actions/auth';
import * as checklistAdminActions from '../actions/checklistsAdmin';
import * as requisitionsAdminActions from '../actions/requisitionsAdmin';
import * as templatesAdminActions from '../actions/templatesAdmin';

const initialApprovalWorkflowState = {
    createApprovalWorkflowsError: null,
    creatingApprovalWorkflows: false,
    loadApprovalWorkflowsError: null,
    loadedApprovalWorkflows: false,
    loadingApprovalWorkflows: false,
};

const initialStandardDocumentsState = {
    loadedStandardDocuments: false,
    loadingStandardDocuments: false,
    loadStandardDocumentsError: null,
};

const initialState = fromJS({
    ...initialApprovalWorkflowState,
    ...initialStandardDocumentsState,
    approvalWorkflows: [],
    checklistTemplates: [],
    loadedGovernmentData: false,
    loadGovernmentDataError: null,
    loadingGovernmentData: false,
    standardDocuments: [],
    templates: [],
    userRoles: [],
    patchingSettings: false,
});

const getTemplateKey = (actionType) => {
    return [
        checklistAdminActions.CREATE_SUCCESS,
        checklistAdminActions.DELETE_SUCCESS,
        checklistAdminActions.UPDATE_SUCCESS,
    ].includes(actionType)
        ? 'checklistTemplates'
        : 'templates';
};

function approvalWorkflowReducer(approvalWorkflow, action) {
    // We only care about the approval workflow that matches the ID
    if (approvalWorkflow.get('id') !== action.approvalWorkflowId) {
        return approvalWorkflow;
    }

    switch (action.type) {
        case adminGovernmentActions.DELETE_APPROVAL_WORKFLOW:
        case adminGovernmentActions.UPDATE_APPROVAL_WORKFLOW:
            return approvalWorkflow.merge(
                fromJS({
                    updateError: null,
                    updating: true,
                })
            );
        case adminGovernmentActions.DELETE_APPROVAL_WORKFLOW_FAIL:
        case adminGovernmentActions.UPDATE_APPROVAL_WORKFLOW_FAIL:
            return approvalWorkflow.merge(
                fromJS({
                    updateError: action.error && action.error.message,
                    updating: false,
                })
            );
        case adminGovernmentActions.UPDATE_APPROVAL_WORKFLOW_SUCCESS:
            return fromJS(action.result);
        default:
            return approvalWorkflow;
    }
}

export default function adminGovernmentReducer(state = initialState, action = {}) {
    switch (action.type) {
        case adminGovernmentActions.CREATE_APPROVAL_WORKFLOW:
            return state.merge(
                fromJS({
                    createApprovalWorkflowsError: null,
                    creatingApprovalWorkflows: true,
                })
            );
        case adminGovernmentActions.CREATE_APPROVAL_WORKFLOW_SUCCESS:
            return state.merge(
                fromJS({
                    approvalWorkflows: state
                        .get('approvalWorkflows')
                        .unshift(fromJS(action.result)),
                    creatingApprovalWorkflows: false,
                })
            );
        case adminGovernmentActions.CREATE_APPROVAL_WORKFLOW_FAIL:
            return state.merge(
                fromJS({
                    createApprovalWorkflowsError: action.error && action.error.message,
                    creatingApprovalWorkflows: false,
                })
            );
        case adminGovernmentActions.DELETE_APPROVAL_WORKFLOW_SUCCESS:
            return state.set(
                'approvalWorkflows',
                state
                    .get('approvalWorkflows')
                    .filter(
                        (approvalWorkflow) =>
                            approvalWorkflow.get('id') !== action.approvalWorkflowId
                    )
            );
        case adminGovernmentActions.LOAD_APPROVAL_WORKFLOWS:
            return state.merge(
                fromJS({
                    loadApprovalWorkflowsError: null,
                    loadingApprovalWorkflows: true,
                })
            );
        case adminGovernmentActions.LOAD_APPROVAL_WORKFLOWS_SUCCESS:
            return state.merge(
                fromJS({
                    approvalWorkflows: fromJS(action.result),
                    loadedApprovalWorkflows: true,
                    loadingApprovalWorkflows: false,
                })
            );
        case adminGovernmentActions.LOAD_APPROVAL_WORKFLOWS_FAIL:
            return state.merge(
                fromJS({
                    loadApprovalWorkflowsError: action.error && action.error.message,
                    loadingApprovalWorkflows: false,
                })
            );
        case adminGovernmentActions.DELETE_APPROVAL_WORKFLOW:
        case adminGovernmentActions.DELETE_APPROVAL_WORKFLOW_FAIL:
        case adminGovernmentActions.UPDATE_APPROVAL_WORKFLOW:
        case adminGovernmentActions.UPDATE_APPROVAL_WORKFLOW_SUCCESS:
        case adminGovernmentActions.UPDATE_APPROVAL_WORKFLOW_FAIL:
            return state.set(
                'approvalWorkflows',
                state
                    .get('approvalWorkflows')
                    .map((approvalWorkflow) => approvalWorkflowReducer(approvalWorkflow, action))
            );
        case adminGovernmentActions.RESET_APPROVAL_WORKFLOW:
            return state.merge(fromJS(initialApprovalWorkflowState));
        case adminGovernmentActions.LOAD_STANDARD_DOCUMENTS:
            return state.merge(
                fromJS({
                    loadStandardDocumentsError: null,
                    loadingStandardDocuments: true,
                })
            );
        case adminGovernmentActions.LOAD_STANDARD_DOCUMENTS_SUCCESS:
            return state.merge(
                fromJS({
                    loadedStandardDocuments: true,
                    loadingStandardDocuments: false,
                    standardDocuments: fromJS(action.result),
                })
            );
        case adminGovernmentActions.LOAD_STANDARD_DOCUMENTS_FAIL:
            return state.merge(
                fromJS({
                    loadStandardDocumentsError: action.error && action.error.message,
                    loadingStandardDocuments: false,
                })
            );
        case adminGovernmentActions.CREATE_STANDARD_DOCUMENT_SUCCESS:
            return state.set(
                'standardDocuments',
                state.get('standardDocuments').unshift(fromJS(action.result))
            );
        case adminGovernmentActions.UPDATE_STANDARD_DOCUMENT_SUCCESS:
            return state.set(
                'standardDocuments',
                state.get('standardDocuments').map((doc) => {
                    if (doc.get('id') === action.result.id) {
                        return fromJS(action.result);
                    }
                    return doc;
                })
            );
        case adminGovernmentActions.DELETE_STANDARD_DOCUMENT_SUCCESS:
            return state.set(
                'standardDocuments',
                state.get('standardDocuments').filter((doc) => doc.get('id') !== action.result.id)
            );
        case adminGovernmentActions.RESET_STANDARD_DOCUMENTS:
            return state.merge(fromJS(initialStandardDocumentsState));
        case adminGovernmentActions.LOAD_GOVERNMENT_DATA:
            return state.merge(
                fromJS({
                    loadingGovernmentData: true,
                    loadGovernmentDataError: null,
                })
            );
        case adminGovernmentActions.LOAD_GOVERNMENT_DATA_SUCCESS:
            return state.merge(
                fromJS({
                    loadedGovernmentData: true,
                    loadingGovernmentData: false,
                    approvalWorkflows: fromJS(action.result.approvalWorkflows),
                    requestTypes: fromJS(action.result.requestTypes),
                    reqSetting: fromJS(action.result.reqSetting),
                    templates: fromJS(action.result.templates),
                    checklistTemplates: fromJS(action.result.checklistTemplates),
                    userRoles: fromJS(action.result.userRoles),
                })
            );
        case adminGovernmentActions.LOAD_GOVERNMENT_DATA_FAIL:
            return state.merge(
                fromJS({
                    loadingGovernmentData: false,
                    loadGovernmentDataError: action.error && action.error.message,
                })
            );
        case adminGovernmentActions.CREATE_USER_ROLE_SUCCESS:
            return state.set('userRoles', state.get('userRoles').unshift(fromJS(action.result)));
        case adminGovernmentActions.UPDATE_USER_ROLE_SUCCESS:
            return state.set(
                'userRoles',
                state.get('userRoles').map((userRole) => {
                    if (userRole.get('id') === action.result.id) {
                        return fromJS(action.result);
                    }
                    return userRole;
                })
            );
        case adminGovernmentActions.DELETE_USER_ROLE_SUCCESS:
            return state.set(
                'userRoles',
                state.get('userRoles').filter((userRole) => userRole.get('id') !== action.result.id)
            );
        case authActions.LOGOUT_SUCCESS:
            return initialState;
        case templatesAdminActions.CREATE_SUCCESS:
        case checklistAdminActions.CREATE_SUCCESS:
            return state.set(
                getTemplateKey(action.type),
                state.get(getTemplateKey(action.type)).push(fromJS(action.result.template))
            );
        case templatesAdminActions.DELETE_SUCCESS:
        case checklistAdminActions.DELETE_SUCCESS:
            return state.set(
                getTemplateKey(action.type),
                state
                    .get(getTemplateKey(action.type))
                    .filter((template) => template.get('id') !== action.result.template.id)
            );
        case templatesAdminActions.UPDATE_SUCCESS:
        case checklistAdminActions.UPDATE_SUCCESS:
            return state.set(
                getTemplateKey(action.type),
                state.get(getTemplateKey(action.type)).map((template) => {
                    if (template.get('id') !== action.result.template.id) {
                        return template;
                    }
                    return fromJS({
                        ...action.result.template,
                        projectType: action.result.type,
                    });
                })
            );
        case requisitionsAdminActions.UPDATE_REQ_SETTING_SUCCESS:
            return state.merge(
                fromJS({
                    reqSetting: fromJS(action.result),
                })
            );
        case adminGovernmentActions.PATCH_GOVERNMENT_SETTINGS: {
            return state.merge(
                fromJS({
                    patchingSettings: true,
                })
            );
        }
        case adminGovernmentActions.PATCH_GOVERNMENT_SETTINGS_SUCCESS: {
            return state.merge(
                fromJS({
                    patchingSettings: false,
                })
            );
        }
        case adminGovernmentActions.PATCH_GOVERNMENT_SETTINGS_FAILURE: {
            return state.merge(
                fromJS({
                    patchingSettings: false,
                })
            );
        }
        default:
            return state;
    }
}

import React from 'react';

import { ReduxFormRadioGroup } from '@og-pro/ui';
import { useSelector } from 'react-redux';
import { Field, getFormValues } from 'redux-form';

import {
    customFieldFields,
    ENUM_OPTIONS_FIELD,
    instructionsModes,
} from '@og-pro/shared-config/customFormService/customField';

import { formConfig } from '../Form/form';

const { NAME, INSTRUCTIONS_MODE, INSTRUCTIONS_TEXT } = customFieldFields;
const { TOOLTIP, DESCRIPTION } = instructionsModes;

export const SingleChoicePreview = () => {
    const formValues = useSelector((state) => getFormValues(formConfig.form)(state)) || {};
    const instructionsText = formValues[INSTRUCTIONS_TEXT];

    const singleChoiceOptions = formValues[ENUM_OPTIONS_FIELD] || [];

    const singleChoiceOptionsArray = singleChoiceOptions.map((option, index) => ({
        value: option || `Option ${index + 1}`,
        label: option || `Option ${index + 1}`,
    }));

    return (
        <Field
            component={ReduxFormRadioGroup}
            description={
                formValues[INSTRUCTIONS_MODE] === DESCRIPTION ? instructionsText : undefined
            }
            disabled
            label={formValues[NAME] || 'Your title will go here'}
            name="singleChoice_PREVIEW"
            options={singleChoiceOptionsArray}
            sx={{
                width: '100%',
            }}
            tooltip={formValues[INSTRUCTIONS_MODE] === TOOLTIP ? instructionsText : undefined}
        />
    );
};

import classnames from 'classnames';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { FLAGS, withFlags } from '@og-pro/launch-darkly/client';
import { SCORED } from '@og-pro/shared-config/evaluations';

import { ProposalsListTable } from './ProposalsListTable';
import { getProposalsListJS, getProposalViewerAcceptanceDate } from './selectors';
import { ReportsModalButton } from '../ReportsModal';
import {
    areProposalsEditable,
    getAgreementsJS,
    getEvaluationPath,
    getPostingPath,
    getProjectJS,
    getProjectStandardDocJS,
    isViewingPastPhase,
    mustAcceptProposalViewerAgreement,
} from '../selectors';
import { getTimezone, isVendorAdminUser } from '../../selectors';
import connectData from '../../ConnectData';
import { shouldLoadFromEvaluationAudit } from '../../../actions/evaluations';
import { createProposal, loadAllProjectProposals } from '../../../actions/govProposals';
import { getProposalViewerAgreementData } from '../../../actions/govProjects';
import {
    Button,
    ButtonGroup,
    LoadingError,
    LoadingSpinner,
    Main,
    OutlineButton,
    ProposalViewerAgreement,
    ProposalViewerAgreementAccepted,
    SectionTitle,
} from '../../../components';
import { AddVendorsToListModal } from '../VendorManagement/AddVendorsToList/modal';
import { getShowAddVendorListModal } from '../VendorManagement/selectors';
import { showAddVendorsToListModal } from '../../../actions/vendorList';
import { ProposalViewerAgreementsTable } from './ProposalViewerAgreementsTable';

const mapStateToProps = (state, props) => {
    const { isEvaluation } = props;

    const basePath = isEvaluation ? getEvaluationPath(state, props) : getPostingPath(state, props);

    const readOnly = isEvaluation && isViewingPastPhase(state, props);

    return {
        acceptedAt: getProposalViewerAcceptanceDate(state, props),
        agreements: getAgreementsJS(state),
        isEditable: areProposalsEditable(state),
        loadAgreementsError: state.govProjects.get('loadAgreementsError'),
        loadError: state.govProposals.get('loadAllError'),
        loading: state.govProposals.get('loadingAll'),
        loadingAgreements: state.govProjects.get('loadingAgreements'),
        mustAcceptAgreement: mustAcceptProposalViewerAgreement(state, props),
        project: getProjectJS(state),
        proposals: getProposalsListJS(state, props),
        proposalsPath: `${basePath}/proposals`,
        readOnly,
        standardDocument: getProjectStandardDocJS(state),
        timezone: getTimezone(state),
        addVendorsToListModalOpen: getShowAddVendorListModal(state),
        isVendorAdmin: isVendorAdminUser(state),
    };
};

const mapDispatchToProps = {
    createProposal,
    showAddVendorsToListModal,
};

// @connect
class ConnectedProposalsList extends Component {
    static propTypes = {
        acceptedAt: PropTypes.string,
        agreements: PropTypes.array,
        createProposal: PropTypes.func.isRequired,
        getFlag: PropTypes.func.isRequired,
        isEditable: PropTypes.bool.isRequired,
        isEvaluation: PropTypes.bool,
        loadAgreementsError: PropTypes.string,
        loadError: PropTypes.string,
        loading: PropTypes.bool.isRequired,
        loadingAgreements: PropTypes.bool,
        mustAcceptAgreement: PropTypes.bool.isRequired,
        project: PropTypes.shape({
            evaluation: PropTypes.shape({
                type: PropTypes.string.isRequired,
            }),
            government_id: PropTypes.number,
            priceTables: PropTypes.array.isRequired,
            questionnaires: PropTypes.array.isRequired,
            showBids: PropTypes.bool,
            standard_document_id: PropTypes.number,
        }).isRequired,
        proposals: PropTypes.array.isRequired,
        proposalsPath: PropTypes.string.isRequired,
        readOnly: PropTypes.bool,
        standardDocument: PropTypes.object,
        timezone: PropTypes.string.isRequired,
        addVendorsToListModalOpen: PropTypes.bool.isRequired,
        showAddVendorsToListModal: PropTypes.func.isRequired,
        isVendorAdmin: PropTypes.bool.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    handleAppProposalClick = () => {
        const { project, proposalsPath } = this.props;

        this.props.createProposal(project, proposalsPath);
    };

    renderAddProposalButton() {
        const { isEditable, readOnly } = this.props;

        if (!isEditable || readOnly) {
            return null;
        }

        return (
            <Button bsSize="lg" bsStyle="success" onClick={this.handleAppProposalClick}>
                <i className="fa fa-plus" /> Add Response
            </Button>
        );
    }

    renderNoProposals() {
        const { isEditable, isEvaluation, readOnly } = this.props;

        return (
            <div className={classnames('row', 'text-muted', this.styles.noProposals)}>
                <div className="col-sm-10 col-sm-offset-1">
                    <div className={this.styles.noProposalsHeader}>
                        No responses have been submitted yet.
                    </div>
                    {!isEvaluation && (
                        <div className={this.styles.noProposalsText}>
                            As vendors complete their responses they will be displayed here. Check
                            back later to review your responses.
                        </div>
                    )}
                    {isEditable && !readOnly && (
                        <>
                            {isEvaluation ? (
                                <div className={this.styles.noProposalsText}>
                                    To get started click the button to add a vendor response. The
                                    response will then be displayed here.
                                </div>
                            ) : (
                                <div className={this.styles.noProposalsAdditionalText}>
                                    Or if you have received responses externally, you can click the
                                    button below to manually add responses.
                                </div>
                            )}
                            <div className={this.styles.noProposalsButton}>
                                {this.renderAddProposalButton()}
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    }

    addVendorsToList = (userIds) => {
        this.setState({ userIds }, () => {
            this.props.showAddVendorsToListModal(this.props.project.government_id);
        });
    };

    renderProposalsListTable() {
        const {
            acceptedAt,
            agreements,
            getFlag,
            isEditable,
            isEvaluation,
            mustAcceptAgreement,
            project: {
                evaluation,
                priceTables,
                questionnaires,
                showBids,
                government_id: governmentId,
            },
            proposals,
            proposalsPath,
            readOnly,
            standardDocument,
            timezone,
            isVendorAdmin,
        } = this.props;

        if (proposals.length === 0) {
            return this.renderNoProposals();
        }

        const proposalListTableButtons = [];
        const isAgreementAcceptanceNeeded = standardDocument && mustAcceptAgreement;
        if (isEvaluation) {
            if ((questionnaires || []).length > 0) {
                proposalListTableButtons.push(
                    <OutlineButton
                        bsStyle="primary"
                        key="proposals"
                        to={`${proposalsPath}/compare`}
                    >
                        <i className="fa fa-align-left" /> Compare Responses
                    </OutlineButton>
                );
            }
            if ((priceTables || []).length > 0 && get(evaluation, 'type') === SCORED) {
                proposalListTableButtons.push(
                    <OutlineButton bsStyle="primary" key="pricing" to={`${proposalsPath}/pricing`}>
                        <i className="fa fa-dollar" /> Compare Pricing
                    </OutlineButton>
                );
            }
        }

        if (getFlag(FLAGS.ALL_PROPOSALS_REPORT)) {
            const isReportDisabled = !showBids || isAgreementAcceptanceNeeded;
            proposalListTableButtons.push(
                <ReportsModalButton
                    bsSize="sm"
                    bsStyle="primary"
                    dataForReport={{ module: isEvaluation ? 'evaluation' : 'sourcing' }}
                    disabled={isReportDisabled}
                    isOutline
                    key="report"
                    tooltip={
                        isReportDisabled
                            ? 'Report will be available once responses are viewable'
                            : undefined
                    }
                    type="allProposalsReport"
                />
            );
        }

        return (
            <div className="row">
                <div className="col-md-10 col-md-offset-1">
                    {acceptedAt && (
                        <ProposalViewerAgreementAccepted
                            acceptedAt={acceptedAt}
                            standardDocument={standardDocument}
                        />
                    )}
                    {proposalListTableButtons.length > 0 && (
                        <ButtonGroup bsSize="sm" className="pull-left">
                            {proposalListTableButtons}
                        </ButtonGroup>
                    )}
                    <ProposalsListTable
                        addVendorsToList={isVendorAdmin ? this.addVendorsToList : null}
                        isEditable={isEditable}
                        mustAcceptAgreement={mustAcceptAgreement}
                        proposals={proposals}
                        proposalsPath={proposalsPath}
                        readOnly={readOnly}
                        showBids={showBids}
                        timezone={timezone}
                    />
                    {standardDocument && (
                        <div className={this.styles.agreementsTable}>
                            <ProposalViewerAgreementsTable agreements={agreements} />
                        </div>
                    )}
                    {this.props.addVendorsToListModalOpen && (
                        <AddVendorsToListModal
                            governmentId={governmentId}
                            initialValues={{
                                userIds: this.state.userIds,
                            }}
                            noUserSelection
                        />
                    )}
                    {!showBids && (
                        <p className={this.styles.bidSealedInfo}>
                            <i>
                                <strong>NOTE: </strong>
                                This project uses a sealed bid process. Vendor responses will not be
                                viewable until the bid has been unsealed.
                            </i>
                        </p>
                    )}
                    {isEditable && !readOnly && (
                        <p className={classnames(this.styles.bidSealedInfo)}>
                            If you have received responses externally, you can use the button below
                            to manually add them.
                        </p>
                    )}
                    <div className="text-center">{this.renderAddProposalButton()}</div>
                </div>
            </div>
        );
    }

    render() {
        const {
            isEvaluation,
            loadAgreementsError,
            loadError,
            loading,
            loadingAgreements,
            mustAcceptAgreement,
            project,
            standardDocument,
        } = this.props;

        if (loading || loadingAgreements || !project) {
            return <LoadingSpinner />;
        }

        if (loadError || loadAgreementsError) {
            return <LoadingError error={loadError || loadAgreementsError} />;
        }

        const isAgreementAcceptanceNeeded = standardDocument && mustAcceptAgreement;

        if (isAgreementAcceptanceNeeded) {
            return (
                <>
                    <ProposalViewerAgreement
                        isEvaluation={isEvaluation}
                        project={project}
                        standardDocument={standardDocument}
                    />
                    <p className={this.styles.proposalViewerAgreementInfo}>
                        You will be able to view responses after submitting the agreement
                    </p>
                    {this.renderProposalsListTable()}
                </>
            );
        }

        return (
            <Main>
                <SectionTitle
                    info="List of vendor responses submitted for the project"
                    title="Responses"
                />
                {this.renderProposalsListTable()}
            </Main>
        );
    }
}

export const ProposalsList = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withFlags()
)(ConnectedProposalsList);

function fetchProposals(getState, dispatch, location, params) {
    const state = getState();

    // Do not load any data when reading from evaluation audit or preventing reloads
    if (shouldLoadFromEvaluationAudit(state, params)) {
        return Promise.resolve();
    }

    const projectId = Number.parseInt(params.projectId, 10);
    return Promise.all([
        dispatch(getProposalViewerAgreementData(projectId)),
        dispatch(loadAllProjectProposals(projectId)),
    ]);
}

// Government sourcing container
const ProposalsListSourcing = (props) => {
    return <ProposalsList {...props} />;
};
ProposalsList.Sourcing = connectData(fetchProposals)(ProposalsListSourcing);

// Government evaluation container
const ProposalsListEvaluation = (props) => {
    return <ProposalsList isEvaluation {...props} />;
};
ProposalsList.Evaluation = connectData(fetchProposals)(ProposalsListEvaluation);

import PropTypes from 'prop-types';
import React from 'react';
import { components } from 'react-select';

// Used to add icons to the `SearchSelect` component input
export const SearchSelectIconValue = (props) => {
    const {
        data: { icon, label },
    } = props;

    return (
        <components.SingleValue {...props}>
            <i className={`fa fa-fw fa-${icon}`} />
            &nbsp;{label}
        </components.SingleValue>
    );
};

SearchSelectIconValue.propTypes = {
    data: PropTypes.shape({
        label: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
    }).isRequired,
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { truncate } from 'lodash';

import { Button } from '../../../../../components';

const TEXT_LENGTH_LIMIT = 210;

export class ScopeSummary extends PureComponent {
    static propTypes = {
        summary: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = { showAll: false };
    }

    handleHideClick = () => {
        this.setState({ showAll: false });
    };

    handleShowClick = () => {
        this.setState({ showAll: true });
    };

    render() {
        const { summary } = this.props;
        const styles = require('./ScopeSelect.scss');

        if (summary.length < TEXT_LENGTH_LIMIT) {
            return <div className={styles.scopeSummary}>{summary}</div>;
        }

        if (this.state.showAll) {
            return (
                <div className={styles.scopeSummary}>
                    <div>{summary}</div>
                    <Button bsStyle="link" onClick={this.handleHideClick} zeroPadding>
                        Hide Full Summary
                    </Button>
                </div>
            );
        }

        const truncatedSummary = truncate(summary, {
            length: TEXT_LENGTH_LIMIT - 20,
            separator: ' ',
        });
        return (
            <div className={styles.scopeSummary}>
                <span>{truncatedSummary}</span>
                &nbsp;
                <Button bsStyle="link" onClick={this.handleShowClick} zeroPadding>
                    Show Full Summary
                </Button>
            </div>
        );
    }
}

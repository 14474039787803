import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { submit } from 'redux-form';

import { LoadingButton, OwnershipForm } from '../../../../components';
import {
    fieldNames,
    form as ownershipForm,
} from '../../../../components/Forms/OwnershipForm/constants';

const { USER_ID } = fieldNames;

export const ChecklistCreateConfirm = ({
    createError,
    createHandler,
    creating,
    template: { title },
    user,
}) => {
    const dispatch = useDispatch();
    const styles = require('./ChecklistCreateConfirm.scss');

    return (
        <div className={styles.confirmationContainer}>
            <div className={styles.confirmTitle}>Ready to Go!</div>
            <dl className="dl-horizontal">
                <OwnershipForm
                    initialValues={{
                        [USER_ID]: user.id,
                    }}
                    onSubmit={createHandler}
                    user={user}
                />
                <dt>Template:</dt>
                <dd>{title}</dd>
            </dl>
            <div>
                <LoadingButton
                    bsSize="lg"
                    bsStyle="success"
                    disabled={creating}
                    icon="fa-plus"
                    loading={creating}
                    onClick={() => dispatch(submit(ownershipForm))}
                    qaTag="checklistCreateModal-create"
                    text="Create Checklist"
                />
                {creating && (
                    <div className={styles.creatingText}>Creating checklist from template...</div>
                )}
                {createError && <div className="error-block">{createError}</div>}
            </div>
        </div>
    );
};

ChecklistCreateConfirm.propTypes = {
    createError: PropTypes.string,
    createHandler: PropTypes.func.isRequired,
    creating: PropTypes.bool,
    template: PropTypes.shape({
        icon: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
    }),
    user: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }).isRequired,
};

import { useCustomFormServiceMutation } from './useCustomFormService';
import { copyCustomField } from './queries';

export const useCopyCustomField = (customFieldId) => {
    return useCustomFormServiceMutation({
        mutationKey: 'copyCustomField',
        mutation: copyCustomField,
        enabled: true,
        params: { customFieldId },
    });
};

import { orderBy } from 'lodash';
import {
    Table as MuiTable,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
} from '@og-pro/ui';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { columns, defaultSortConfig } from './constants';
import { RequestTypeCreateButton } from './RequestTypeCreateButton';
import { RequestTypesRow } from './RequestTypesRow';
import { getRequestTypesPath } from '../../selectors';
import { ZeroState } from '../../../../../components';

const { sortBy: defaultSortBy, sortDirection: defaultSortDirection } = defaultSortConfig;

export const RequestTypesTable = ({ isFilteredView, requestTypes: originalRequestTypes }) => {
    const [sortBy, setSortBy] = useState(defaultSortBy);
    const [sortDirection, setSortDirection] = useState(defaultSortDirection);

    const navigate = useNavigate();

    const requestTypesPath = useSelector(getRequestTypesPath);

    const requestTypes = useMemo(() => {
        return orderBy(
            originalRequestTypes,
            [sortBy, defaultSortBy],
            [sortDirection, defaultSortDirection]
        );
    }, [sortBy, sortDirection, originalRequestTypes]);

    if (requestTypes.length === 0) {
        return (
            <ZeroState
                button={isFilteredView ? undefined : <RequestTypeCreateButton size="lg" />}
                title={isFilteredView ? 'No request types found' : 'No request types created yet'}
                useOpenGovStyle
            />
        );
    }

    const sortHandler = (property, initialSortDirection) => {
        const isAlreadySortedByProperty = sortBy === property;
        const shouldSortDesc = isAlreadySortedByProperty
            ? sortDirection === 'asc'
            : initialSortDirection === 'desc';

        setSortBy(property);
        setSortDirection(shouldSortDesc ? 'desc' : 'asc');
    };

    const columnStyles = {
        fontWeight: capitalDesignTokens.foundations.typography.fontWeight.medium,
        fontSize: capitalDesignTokens.foundations.typography.fontSize.bodyDefault,
    };

    return (
        <TableContainer
            sx={{
                overflowX: 'auto',
                border: `1px solid ${capitalDesignTokens.semanticColors.border.primary}`,
                borderBottom: 0,
            }}
        >
            <MuiTable aria-label="Request Types Table">
                <TableHead>
                    <TableRow>
                        {Object.keys(columns).map((col) => {
                            const {
                                headProps,
                                sortKey,
                                initialSortDirection = defaultSortDirection,
                                label,
                            } = columns[col];

                            const isActive = sortBy === sortKey;
                            return (
                                <TableCell key={col} {...headProps}>
                                    <TableSortLabel
                                        active={isActive}
                                        direction={isActive ? sortDirection : initialSortDirection}
                                        onClick={() => sortHandler(sortKey, initialSortDirection)}
                                        sx={columnStyles}
                                    >
                                        {label}
                                    </TableSortLabel>
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {requestTypes.map((requestType) => {
                        return (
                            <RequestTypesRow
                                key={requestType.id}
                                onClick={() => navigate(`${requestTypesPath}/${requestType.id}`)}
                                requestType={requestType}
                            />
                        );
                    })}
                </TableBody>
            </MuiTable>
        </TableContainer>
    );
};

RequestTypesTable.propTypes = {
    isFilteredView: PropTypes.bool,
    requestTypes: PropTypes.array.isRequired,
};

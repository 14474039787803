import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { getFileExtensionIcon } from '../../../../helpers';

export class ProposalAttachmentItem extends PureComponent {
    static propTypes = {
        filename: PropTypes.string.isRequired,
        fileExtension: PropTypes.string,
        url: PropTypes.string.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const { fileExtension, filename, url } = this.props;
        const icon = getFileExtensionIcon(fileExtension);
        return (
            <li className={this.styles.container}>
                <a href={url}>
                    <i className={`fa fa-fw fa-${icon} ${this.styles.icon}`} />
                    {filename}
                </a>
            </li>
        );
    }
}

import { capitalize } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { hasLoggedInWithPlatform, userStatusTypes } from '@og-pro/shared-config/users';
import { USE_OG_PLATFORM } from '@og-pro/shared-config/platform';

import * as adminActions from '../../../../actions/admin';
import { showConfirmationSimpleModal } from '../../../../actions/confirmation';
import { Button, OutlineButton } from '../../../../components';

const { ACTIVE, DISABLED, INVITED, REQUESTED, RESET } = userStatusTypes;

const mapDispatchToProps = {
    ...adminActions,
    showConfirmationSimpleModal,
};

class ConnectedUserFormDisplay extends Component {
    static propTypes = {
        currentUser: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }).isRequired,
        departmentsMap: PropTypes.object.isRequired,
        disabled: PropTypes.bool,
        disableUser: PropTypes.func.isRequired,
        enableUser: PropTypes.func.isRequired,
        hasUserRoles: PropTypes.bool.isRequired,
        onShowEditForm: PropTypes.func.isRequired,
        resendInvite: PropTypes.func.isRequired,
        showBulkUpdateModal: PropTypes.func.isRequired,
        showConfirmationSimpleModal: PropTypes.func.isRequired,
        user: PropTypes.shape({
            created_at: PropTypes.string,
            department_id: PropTypes.number,
            disabling: PropTypes.bool,
            email: PropTypes.string,
            enabling: PropTypes.bool,
            fullName: PropTypes.string,
            id: PropTypes.number.isRequired,
            isExternal: PropTypes.bool.isRequired,
            isVendor: PropTypes.bool.isRequired,
            lastLogin: PropTypes.string,
            reInviting: PropTypes.bool,
            role: PropTypes.string,
            status: PropTypes.string.isRequired,
            userRoles: PropTypes.array,
        }).isRequired,
    };

    get userStatusInfo() {
        const {
            user: { status },
        } = this.props;

        switch (status) {
            case RESET:
            case ACTIVE:
                return ['text-success', 'fa fa-check-circle-o', 'Active'];
            case INVITED:
                return ['text-info', 'fa fa-envelope-o', 'Invited'];
            case DISABLED:
                return ['text-danger', 'fa fa-ban', 'Disabled'];
            default:
                return [];
        }
    }

    get styles() {
        return require('../index.scss');
    }

    handleDisableUserClick = () => {
        const {
            disableUser,
            showBulkUpdateModal,
            user: { id, isVendor },
        } = this.props;

        if (isVendor) {
            this.props.showConfirmationSimpleModal(() => disableUser(id), {
                text: "Are you sure you want to disable this user's account?\nThey'll need to re-activate if their account is ever restored.",
                icon: 'ban',
                btnText: 'Disable User',
            });
        } else {
            showBulkUpdateModal({ id, disable: true });
        }
    };

    handleEnableUserClick = () => {
        const {
            enableUser,
            user: { id: userId, lastLogin },
        } = this.props;

        const status = lastLogin ? ACTIVE : INVITED;
        enableUser(userId, status);
    };

    handleResendInviteClick = () => {
        const {
            resendInvite,
            user: { id: userId },
        } = this.props;

        resendInvite(userId);
    };

    handleUpdateContactsClick = () => {
        const {
            showBulkUpdateModal,
            user: { id },
        } = this.props;
        showBulkUpdateModal({ id, disable: false });
    };

    renderEditButton() {
        const {
            disabled,
            onShowEditForm,
            user: { status },
        } = this.props;

        if (status === DISABLED) {
            return null;
        }

        return (
            <div className={this.styles.button}>
                <OutlineButton
                    bsSize="sm"
                    bsStyle="primary"
                    disabled={disabled}
                    onClick={onShowEditForm}
                    qaTag="userForm-edit"
                >
                    <i className="fa fa-pencil" /> Edit
                </OutlineButton>
            </div>
        );
    }

    renderResendInviteButton() {
        const {
            disabled,
            user,
            user: { reInviting, status },
        } = this.props;

        const hasUserLoggedInWithPlatform = hasLoggedInWithPlatform(USE_OG_PLATFORM, user);
        if (status !== INVITED && hasUserLoggedInWithPlatform) {
            return null;
        }

        return (
            <div>
                <Button
                    bsSize="sm"
                    bsStyle="link"
                    disabled={disabled}
                    onClick={this.handleResendInviteClick}
                    qaTag="userForm-sendEmail"
                    zeroPadding
                >
                    <i className="fa fa-send" />{' '}
                    {reInviting ? 'Sending Email...' : 'Resend Invite Email'}
                </Button>
            </div>
        );
    }

    renderDisableButton() {
        const {
            currentUser,
            disabled,
            user: { disabling, id, status },
        } = this.props;

        if (currentUser.id === id || status === DISABLED) {
            return null;
        }

        return (
            <div className={this.styles.button}>
                <OutlineButton
                    bsSize="sm"
                    bsStyle="danger"
                    disabled={disabled}
                    onClick={this.handleDisableUserClick}
                    qaTag="userFormDisplay-disable"
                >
                    <i className="fa fa-ban" /> {disabling ? 'Disabling...' : 'Disable'}
                </OutlineButton>
            </div>
        );
    }

    renderEnableButton() {
        const {
            disabled,
            user: { enabling, status },
        } = this.props;

        if (status !== DISABLED) {
            return null;
        }

        return (
            <div className={this.styles.button}>
                <OutlineButton
                    bsSize="sm"
                    bsStyle="success"
                    disabled={disabled}
                    onClick={this.handleEnableUserClick}
                    qaTag="userFormDisplay-enable"
                >
                    <i className="fa fa-refresh" /> {enabling ? 'Enabling...' : 'Enable'}
                </OutlineButton>
            </div>
        );
    }

    renderReplaceContactsButton() {
        const {
            disabled,
            user: { status },
        } = this.props;

        if (status === INVITED || status === REQUESTED) {
            return null;
        }

        return (
            <div className={this.styles.button}>
                <OutlineButton
                    bsSize="sm"
                    bsStyle="primary"
                    disabled={disabled}
                    onClick={this.handleUpdateContactsClick}
                    qaTag="userFormDisplay-updateContacts"
                >
                    <i className="fa fa-pencil" />
                    &nbsp;Replace Contacts
                </OutlineButton>
            </div>
        );
    }

    renderUserStatus() {
        const [textClass, icon, statusText] = this.userStatusInfo;

        return (
            <span className={textClass}>
                <i className={icon} /> {statusText}
            </span>
        );
    }

    render() {
        const {
            departmentsMap,
            hasUserRoles,
            user: {
                created_at: createdAt,
                department_id: departmentId,
                email,
                fullName,
                isExternal,
                isVendor,
                lastLogin,
                role,
                userRoles,
            },
        } = this.props;

        return (
            <div className={`row ${this.styles.userListRow}`}>
                <div
                    className={`col-xs-12 col-md-3 ${this.styles.userCol} ${this.styles.nameField}`}
                >
                    <div className={this.styles.name}>{fullName}</div>
                    <div>{email}</div>
                    {isExternal && (
                        <div>
                            <em>
                                <small>*External Collaborator</small>
                            </em>
                        </div>
                    )}
                    {this.renderResendInviteButton()}
                </div>
                <div className={`hidden-xs hidden-sm col-md-1 ${this.styles.userCol}`}>
                    {this.renderUserStatus()}
                </div>
                <div className={`hidden-xs hidden-sm col-md-1 ${this.styles.userCol}`}>
                    {moment(createdAt).format('l')}
                </div>
                <div className={`hidden-xs hidden-sm col-md-1 ${this.styles.userCol}`}>
                    {lastLogin ? moment(lastLogin).format('l') : 'None'}
                </div>
                <div className={`col-xs-12 col-md-2 ${this.styles.userCol}`}>
                    {isVendor || !hasUserRoles
                        ? capitalize(role)
                        : userRoles.map((userRole) => userRole.name).join('\n')}
                </div>
                {!isVendor && departmentsMap[departmentId] && (
                    <div className={`col-xs-12 col-md-2 ${this.styles.userCol}`}>
                        {departmentsMap[departmentId].name}
                    </div>
                )}
                <div className={`col-xs-12 col-md-2 ${this.styles.userCol}`}>
                    {this.renderEditButton()}
                    {this.renderDisableButton()}
                    {!isVendor && this.renderReplaceContactsButton()}
                    {this.renderEnableButton()}
                </div>
            </div>
        );
    }
}

export const UserFormDisplay = connect(null, mapDispatchToProps)(ConnectedUserFormDisplay);

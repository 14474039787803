import { Box } from '@og-pro/ui';
import React from 'react';
import { Outlet } from 'react-router-dom';

import { bodyContainer } from '../styles';

export const RequestTypeReviewGroups = () => {
    return (
        <Box sx={bodyContainer}>
            <Outlet />
        </Box>
    );
};

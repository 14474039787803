import { change } from 'redux-form';

import { contactFieldNames, mappedContactFields } from '@og-pro/shared-config/contacts';

const {
    ADDRESS_1,
    ADDRESS_2,
    CITY,
    COUNTRY_CODE,
    EMAIL,
    FIRST_NAME,
    LAST_NAME,
    PHONE,
    PHONE_COUNTRY,
    PHONE_EXT,
    STATE,
    TITLE,
    ZIP_CODE,
} = contactFieldNames;

export function changeContactForm(values, formName, fieldNames = mappedContactFields) {
    return (dispatch) => {
        dispatch(change(formName, fieldNames[ADDRESS_1], values[ADDRESS_1]));
        dispatch(change(formName, fieldNames[ADDRESS_2], values[ADDRESS_2]));
        dispatch(change(formName, fieldNames[CITY], values[CITY]));
        dispatch(change(formName, fieldNames[COUNTRY_CODE], values[COUNTRY_CODE]));
        dispatch(change(formName, fieldNames[EMAIL], values[EMAIL]));
        dispatch(change(formName, fieldNames[FIRST_NAME], values[FIRST_NAME]));
        dispatch(change(formName, fieldNames[LAST_NAME], values[LAST_NAME]));
        dispatch(change(formName, fieldNames[PHONE], values[PHONE]));
        dispatch(change(formName, fieldNames[PHONE_COUNTRY], values[PHONE_COUNTRY]));
        dispatch(change(formName, fieldNames[PHONE_EXT], values[PHONE_EXT]));
        dispatch(change(formName, fieldNames[STATE], values[STATE]));
        dispatch(change(formName, fieldNames[TITLE], values[TITLE]));
        dispatch(change(formName, fieldNames[ZIP_CODE], values[ZIP_CODE]));
    };
}

export const SET_CONTACT_MODAL_TAB = 'contacts/SET_CONTACT_MODAL_TAB';
export const SHOW_CONTACT_MODAL = 'contacts/SHOW_CONTACT_MODAL';
export const HIDE_CONTACT_MODAL = 'contacts/HIDE_CONTACT_MODAL';

export function setContactModalTab(tab) {
    return { type: SET_CONTACT_MODAL_TAB, tab };
}

export function showContactModal(modalData) {
    return (dispatch) => {
        dispatch(setContactModalTab('Name'));
        dispatch({ type: SHOW_CONTACT_MODAL, modalData });
    };
}

export function hideContactModal() {
    return { type: HIDE_CONTACT_MODAL };
}

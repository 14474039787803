import React, { useState } from 'react';
import classnames from 'classnames';

import { CDSButton } from '../CDSButtons';
import { Content } from './Content';
import { PROP_TYPES } from './types';

export const TabletLayout = ({
    navigation,
    header,
    content,
    footer,
    showSharedTextareaToolbar = false,
}) => {
    const [collapsed, setCollapsed] = useState(true);
    const styles = require('./index.scss');

    return (
        <div
            className={classnames(styles.menuAndMainContainer, {
                [styles.collapsedMenuAndMainContainer]: collapsed,
            })}
        >
            <div
                className={classnames(styles.menuContainer, {
                    [styles.collapsedMenuContainer]: collapsed,
                })}
            >
                {!collapsed && (
                    <div className={styles.stickyContainer}>
                        <CDSButton
                            className={styles.collapseButton}
                            onClick={() => setCollapsed(true)}
                            qaTag="sdv2Layout-collapseMenu"
                            variant="text"
                        >
                            <i className="fa fa-caret-square-o-left" />
                        </CDSButton>

                        {navigation}
                    </div>
                )}
                {collapsed && (
                    <div>
                        <CDSButton
                            className={styles.expandButton}
                            noPadding
                            onClick={() => setCollapsed(false)}
                            qaTag="sdv2Layout-expandMenu"
                            size="large"
                            variant="text"
                        >
                            <i className="fa fa-list" />
                        </CDSButton>
                    </div>
                )}
            </div>

            <Content {...{ header, content, footer, showSharedTextareaToolbar }} />
        </div>
    );
};
TabletLayout.propTypes = PROP_TYPES;

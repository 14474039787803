import React from 'react';

import {
    ProjectAddendums,
    ProjectDetailNav,
    ProjectSection,
    QuestionAnswer,
    SelectedVendor,
} from '../../containers';
import * as Gov from '../../containers/GovApp';
import * as Public from '../../containers/PublicApp';
import { legacyFetchData } from '../loaders';

const PublicProjectRoutes = (getState, dispatch) => [
    {
        element: <ProjectDetailNav.Public />,
        path: 'document',
        children: [
            {
                element: <ProjectSection.Public />,
                index: true,
            },
        ],
    },
    {
        element: <Gov.ProjectIntroOverview.Public />,
        index: true,
    },
    {
        element: <ProjectAddendums.Public />,
        loader: legacyFetchData(getState, dispatch, ProjectAddendums.Public),
        path: 'addenda',
    },
    {
        element: <QuestionAnswer.Public />,
        loader: legacyFetchData(getState, dispatch, QuestionAnswer.Public),
        path: 'q-and-a',
    },
    {
        element: <Public.Downloads />,
        path: 'downloads',
    },
    {
        element: <Public.Planholders />,
        path: 'followers',
    },
    {
        element: <SelectedVendor.Public />,
        path: 'selected-vendor',
    },
];

export default PublicProjectRoutes;

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams, useNavigation } from 'react-router-dom';

import { resetApprovals } from '../../actions/approvals';
import { resetProject } from '../../actions/govProjects';
import { resetCommentThreads } from '../../actions/govComments';
import { resetLoadedContracts } from '../../actions/contracts';

export const ResetGovProject = ({ children }) => {
    const dispatch = useDispatch();
    const { projectId } = useParams();
    const { location: nextLocation } = useNavigation();

    const reset = () => {
        dispatch(resetApprovals());
        dispatch(resetCommentThreads());
        dispatch(resetProject());
        dispatch(resetLoadedContracts());
    };

    useEffect(() => {
        if (nextLocation) {
            // when navigating from one project to the other, or when navigating to somewhere not project related
            if (!projectId) {
                return reset();
            }

            if (
                !nextLocation.pathname.includes('projects') ||
                (nextLocation.pathname.includes('projects') &&
                    !nextLocation.pathname.includes(projectId))
            ) {
                return reset();
            }
        }
    }, [nextLocation]);

    return children;
};

ResetGovProject.propTypes = {
    children: PropTypes.node.isRequired,
};

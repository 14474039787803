import { listToDict } from '@og-pro/shared-config/helpers';

export const invalidSaveTooltip =
    'All form fields must be valid before saving. ' +
    'Valid sections will have a green checkmark next to the section name. ' +
    'Invalid form fields will be highlighted in red.';

export const exportButtonIds = listToDict([
    'previewMainDocumentDocx',
    'previewMainDocumentPdf',
    'previewCurrentSectionDocx',
    'previewCurrentSectionPdf',
    'previewContractPackagePdf',
    'downloadMainDocumentDocx',
    'downloadMainDocumentPdf',
    'downloadCurrentSectionDocx',
    'downloadCurrentSectionPdf',
    'downloadContractPackagePdf',
    'fullPackageWithAttachments',
]);

import { fromJS } from 'immutable';

import * as projectActions from '../actions/projects';
import { CLEANUP_DOCUMENTS, EXPIRED } from '../containers/GovApp/RecordsRetention/constants';

const initialState = fromJS({
    projects: [],
    projectsCount: 0,
    cleanupDocuments: [],
    cleanupDocumentsCount: 0,
    expired: [],
    expiredCount: 0,
    recentlyClosed: [],
    recentlyClosedCount: 0,
    assignedPolicies: false,
    assigningPolicies: false,
    assignPoliciesError: null,
    loadProjectsError: null,
    loadedProjects: false,
    loadingProjects: false,
    policiesAssigned: [],
    projectLineItemContractInfo: [],
    loadingProjectLineItemContractInfo: false,
    loadProjectLineItemContractInfoError: null,
    loadedRecentlyClosed: false,
    loadedCleanupDocuments: false,
    loadedExpired: false,
});

export default function projectsReducer(state = initialState, action = {}) {
    switch (action.type) {
        case projectActions.LOAD_PROJECTS:
            return state.merge(
                fromJS({
                    loadingProjects: true,
                    loadProjectsError: null,
                })
            );
        case projectActions.LOAD_PROJECTS_FAIL:
            return state.merge(
                fromJS({
                    loadingProjects: false,
                    loadProjectsError: action.error.message,
                })
            );
        case projectActions.LOAD_PROJECTS_SUCCESS:
            return state.merge(
                fromJS({
                    projects: fromJS(action.result),
                    projectsCount: action.count,
                    loadedProjects: true,
                    loadingProjects: false,
                })
            );
        case projectActions.LOAD_CLEANUP_DOCUMENTS:
            return state.merge(
                fromJS({
                    loadedCleanupDocuments: false,
                    loadingCleanupDocuments: true,
                    loadCleanupDocumentsError: null,
                })
            );
        case projectActions.LOAD_CLEANUP_DOCUMENTS_FAIL:
            return state.merge(
                fromJS({
                    loadingCleanupDocuments: false,
                    loadCleanupDocumentsError: action.error.message,
                })
            );
        case projectActions.LOAD_CLEANUP_DOCUMENTS_SUCCESS:
            return state.merge(
                fromJS({
                    cleanupDocuments: fromJS(action.result),
                    cleanupDocumentsCount: action.count,
                    loadedCleanupDocuments: true,
                    loadingCleanupDocuments: false,
                })
            );
        case projectActions.LOAD_EXPIRED:
            return state.merge(
                fromJS({
                    loadedExpired: false,
                    loadingExpired: true,
                    loadExpiredError: null,
                })
            );
        case projectActions.LOAD_EXPIRED_FAIL:
            return state.merge(
                fromJS({
                    loadingExpired: false,
                    loadExpiredError: action.error.message,
                })
            );
        case projectActions.LOAD_EXPIRED_SUCCESS:
            return state.merge(
                fromJS({
                    expired: fromJS(action.result),
                    expiredCount: action.count,
                    loadedExpired: true,
                    loadingExpired: false,
                })
            );
        case projectActions.LOAD_RECENTLY_CLOSED:
            return state.merge(
                fromJS({
                    loadedRecentlyClosed: false,
                    loadingRecentlyClosed: true,
                    loadRecentlyClosedError: null,
                })
            );
        case projectActions.LOAD_RECENTLY_CLOSED_FAIL:
            return state.merge(
                fromJS({
                    loadingRecentlyClosed: false,
                    loadRecentlyClosedError: action.error.message,
                })
            );
        case projectActions.LOAD_RECENTLY_CLOSED_SUCCESS:
            return state.merge(
                fromJS({
                    recentlyClosed: fromJS(action.result),
                    recentlyClosedCount: action.count,
                    loadedRecentlyClosed: true,
                    loadingRecentlyClosed: false,
                })
            );
        case projectActions.ASSIGN_POLICIES:
            return state.merge(
                fromJS({
                    assignedPolicies: false,
                    assigningPolicies: true,
                    assignPoliciesError: null,
                })
            );
        case projectActions.ASSIGN_POLICIES_FAIL:
            return state.merge(
                fromJS({
                    assigningPolicies: false,
                    assignPoliciesError: action.error.message,
                })
            );
        case projectActions.ASSIGN_POLICIES_SUCCESS:
            return state.merge(
                fromJS({
                    policiesAssigned: fromJS(action.result),
                    assignedPolicies: true,
                    assigningPolicies: false,
                })
            );
        case projectActions.DELETE_DOCUMENTS:
            return state.merge(
                fromJS({
                    deletingCleanupDocuments: true,
                    deleteCleanupDocumentsError: null,
                })
            );
        case projectActions.DELETE_DOCUMENTS_FAIL:
            return state.merge(
                fromJS({
                    deletingCleanupDocuments: false,
                    deleteCleanupDocumentsError: action.error.message,
                })
            );
        case projectActions.DELETE_DOCUMENTS_SUCCESS:
            return state.merge(
                fromJS({
                    cleanupDocuments: state.get(CLEANUP_DOCUMENTS).filter((document) => {
                        return !action.result.includes(document.get('id'));
                    }),
                    deletingCleanupDocuments: false,
                    deletedCleanupDocuments: true,
                })
            );
        case projectActions.DELETE_EXPIRED:
            return state.merge(
                fromJS({
                    deletingExpired: true,
                    deleteExpiredError: null,
                })
            );
        case projectActions.DELETE_EXPIRED_FAIL:
            return state.merge(
                fromJS({
                    deletingExpired: false,
                    deleteExpiredError: action.error.message,
                })
            );
        case projectActions.DELETE_EXPIRED_SUCCESS: {
            const contractRecords = state
                .get(EXPIRED)
                .filter((record) => record.get('recordType') === 'contracts');
            const projectRecords = state
                .get(EXPIRED)
                .filter(
                    (record) =>
                        record.get('recordType') === 'intakes' ||
                        record.get('recordType') === 'documents'
                );

            const expiredContracts = contractRecords.filter(
                (item) => !action.result.contractIds.includes(item.get('id'))
            );
            const expiredProjects = projectRecords.filter(
                (item) => !action.result.projectIds.includes(item.get('id'))
            );

            return state.merge(
                fromJS({
                    expired: [...expiredContracts, ...expiredProjects],
                    deletedExpired: true,
                    deletingExpired: false,
                })
            );
        }
        case projectActions.LOAD_PROJECT_LINE_ITEM_CONTRACT_INFO:
            return state.merge(
                fromJS({
                    projectLineItemContractInfo: [],
                    loadingProjectLineItemContractInfo: true,
                    loadProjectLineItemContractInfoError: null,
                })
            );
        case projectActions.LOAD_PROJECT_LINE_ITEM_CONTRACT_INFO_FAILURE:
            return state.merge(
                fromJS({
                    loadingProjectLineItemContractInfo: false,
                    loadProjectLineItemContractInfoError: action.error.message,
                })
            );
        case projectActions.LOAD_PROJECT_LINE_ITEM_CONTRACT_INFO_SUCCESS:
            return state.merge(
                fromJS({
                    loadingProjectLineItemContractInfo: false,
                    projectLineItemContractInfo: fromJS(action.result),
                })
            );
        default:
            return state;
    }
}

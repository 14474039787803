import { change } from 'redux-form';

import { showSnackbar } from './notification';
import { form } from '../components/Forms/Attachable/AttachmentDropzone';
import request from '../request';

export function uploadFile(projectId, formData, onSuccess, opts = {}) {
    return (dispatch, getState, client) => {
        const { attachment, formKey } = formData;
        const { file, fileExtension, title } = attachment;

        const rawFilename = fileExtension ? `${title}.${fileExtension}` : title;
        const filename = encodeURIComponent(rawFilename);
        const contentType = encodeURIComponent(file.type);

        const endpoint = `/project/${projectId}/s3?filename=${filename}&contentType=${contentType}`;

        dispatch(change(form, `${formKey}.loading`, true));
        dispatch(change(form, `${formKey}.error`, null));

        let signedData;
        return client
            .get(endpoint)
            .then((result) => {
                signedData = result;
                const postData = {
                    data: file,
                    headers: { 'Content-Type': file.type },
                    onProgress: (e) => dispatch(change(form, `${formKey}.progress`, e.percent)),
                };
                return request.put(result.signedPutUrl, postData);
            })
            .then(() => {
                dispatch(change(form, `${formKey}.progress`, 100));
                dispatch(change(form, `${formKey}.done`, true));
                if (opts.showNotification) {
                    dispatch(showSnackbar('File Uploaded!'));
                }
                onSuccess({
                    attachment,
                    signedData,
                });
            })
            .catch((error) => {
                const errorMsg = (error && error.message) || 'Error uploading attachment';
                dispatch(change(form, `${formKey}.loading`, false));
                dispatch(change(form, `${formKey}.error`, errorMsg));
            });
    };
}

export const UPLOAD_PROPOSAL = 'attachments/UPLOAD_PROPOSAL';
export const UPLOAD_PROPOSAL_PROGRESS = 'attachments/UPLOAD_PROPOSAL_PROGRESS';
export const UPLOAD_PROPOSAL_SUCCESS = 'attachments/UPLOAD_PROPOSAL_SUCCESS';
export const UPLOAD_PROPOSAL_FAIL = 'attachments/UPLOAD_PROPOSAL_FAIL';

export function uploadProposal(data, cb) {
    return (dispatch, getState, client) => {
        const { file, proposalId, uploadId } = data;
        const filename = encodeURIComponent(file.name);
        const contentType = encodeURIComponent(file.type);

        const endpoint = `/proposal/${proposalId}/s3?filename=${filename}&contentType=${contentType}`;

        dispatch({ type: UPLOAD_PROPOSAL, uploadId });

        let signedData;
        return client
            .get(endpoint)
            .then((result) => {
                signedData = result;
                const postData = {
                    data: file,
                    headers: { 'Content-Type': file.type },
                    onProgress: (e) => {
                        dispatch({
                            type: UPLOAD_PROPOSAL_PROGRESS,
                            progress: e.percent,
                            uploadId,
                        });
                    },
                };
                return request.put(result.signedPutUrl, postData);
            })
            .then(() => {
                dispatch({ type: UPLOAD_PROPOSAL_SUCCESS, uploadId });
                return cb(signedData);
            })
            .catch((error) => {
                dispatch({ type: UPLOAD_PROPOSAL_FAIL, error, uploadId });
            });
    };
}

export function universalUploadFile(s3GetUrl, file, onProgress, opts = {}) {
    return (dispatch, getState, client) => {
        const { name: rawFilename, type: fileType } = file;

        const { attachmentData, filename, onSuccess } = opts;

        const cleanFilename = encodeURIComponent(filename || rawFilename);
        const contentType = encodeURIComponent(fileType);

        const s3GetEndpoint = `${s3GetUrl}?filename=${cleanFilename}&contentType=${contentType}`;
        const maxProgress = onSuccess ? 95 : 100;

        let signedData;
        return client
            .get(s3GetEndpoint)
            .then((result) => {
                signedData = result;
                const postData = {
                    data: file,
                    headers: { 'Content-Type': fileType },
                    onProgress: (e) => onProgress(Math.min(e.percent, maxProgress), file),
                };
                return request.put(result.signedPutUrl, postData);
            })
            .then(() => {
                // Set progress to 100% on the upload unless there is more work to do
                onProgress(Math.min(100, maxProgress));

                const uploadData = {
                    ...attachmentData,
                    bucket: signedData.bucket,
                    path: signedData.key,
                    filename: signedData.filename,
                };

                if (!onSuccess) {
                    return uploadData;
                }

                return onSuccess(uploadData);
            });
    };
}

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@og-pro/ui';

export const CustomGridLayout = ({ columns }) => {
    return (
        <>
            <Box flex={1} pr={4}>
                <Box pr={6}>{columns[0]}</Box>
            </Box>
            <Box display="flex" flex={1}>
                <Box flex={1} pr={4} textAlign="right">
                    {columns[1]}
                </Box>
                <Box flex={1} pr={4} textAlign="right">
                    {columns[2]}
                </Box>
                <Box style={{ minWidth: '40px' }}>{columns[3] || <>&nbsp;</>}</Box>
            </Box>
        </>
    );
};

CustomGridLayout.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.node).isRequired,
};

import { contactFieldNames } from '@og-pro/shared-config/contacts';

export const form = 'contractContacts';

export const fieldNamesDict = {
    ...contactFieldNames,
    CONTACTS: 'contacts',
    TAG_ID: 'tag_id',
    USER_ID: 'user_id',
};

import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';

import { assignUserFieldNames, assignUserForm, fieldNames } from './constants';
import { validate } from './validate';
import { QuestionLogo } from '../QuestionLogo';
import { AssignUserForm, Button, InputText } from '../../../..';
import { getInvitedUsersSelectOptions } from '../../../../../containers/selectors';
import { assignUser } from '../../../../../actions/questions';

const { DESCRIPTION } = fieldNames;

const { USER_ID } = assignUserFieldNames;

const formConfig = {
    validate,
};

const mapStateToProps = (state) => {
    return {
        users: getInvitedUsersSelectOptions(state),
    };
};

const mapDispatchToProps = {
    assignUser,
};

// @connect
// @reduxForm
class ConnectedAnswerForm extends PureComponent {
    static propTypes = {
        assignedUser: PropTypes.object,
        assigningUser: PropTypes.bool,
        assignUser: PropTypes.func.isRequired,
        assignUserError: PropTypes.string,
        autoFocus: PropTypes.bool,
        cancelHandler: PropTypes.func,
        canEdit: PropTypes.bool,
        handleSubmit: PropTypes.func.isRequired,
        image: PropTypes.string.isRequired,
        isLoading: PropTypes.bool,
        organization: PropTypes.string,
        questionId: PropTypes.number.isRequired,
        submitError: PropTypes.string,
        users: PropTypes.array.isRequired,
    };

    get styles() {
        return require('../shared.scss');
    }

    render() {
        const {
            assignedUser,
            assigningUser,
            assignUserError,
            autoFocus,
            cancelHandler,
            canEdit,
            handleSubmit,
            image,
            isLoading,
            organization,
            questionId,
            submitError,
            users,
        } = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <QuestionLogo entityName={organization} imageSrc={image} />
                    <div className={`col-xs-12 ${this.styles.commentContainer}`}>
                        <div className="form-group">
                            <Field
                                autoFocus={autoFocus}
                                component={InputText}
                                disabled={!canEdit || isLoading}
                                hasFeedback={false}
                                id={`form-group-description${questionId}`}
                                minRows={2}
                                name={DESCRIPTION}
                                placeholder="Answer question..."
                                qaTag="connectedAnswerForm-answerQuestion"
                                type="textarea"
                            />
                        </div>
                        {submitError && <p className="text-danger">{submitError}</p>}
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-8">
                        <AssignUserForm
                            assignUser={(data) => this.props.assignUser(questionId, data)}
                            assignUserError={assignUserError}
                            assignedUser={assignedUser}
                            assigningUser={assigningUser}
                            cannotEdit={!canEdit}
                            fieldName={USER_ID}
                            form={`${assignUserForm}${questionId}`}
                            initialValues={{ [USER_ID]: get(assignedUser, 'id') }}
                            showInviteUserHelp
                            users={users}
                        />
                    </div>
                    <div className="col-xs-4">
                        <div className="text-right">
                            <Button
                                bsStyle="primary"
                                disabled={!canEdit || isLoading}
                                qaTag="connectedAnswerForm-reply"
                                tooltip={
                                    canEdit
                                        ? undefined
                                        : 'You have not received permission to submit responses'
                                }
                                type="submit"
                            >
                                {isLoading ? 'Replying...' : 'Reply'}
                            </Button>
                        </div>
                        {cancelHandler && (
                            <div className="text-right">
                                <Button
                                    bsSize="sm"
                                    bsStyle="link"
                                    onClick={cancelHandler}
                                    qaTag="connectedAnswerForm-cancel"
                                >
                                    Cancel
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </form>
        );
    }
}

export const AnswerForm = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedAnswerForm);

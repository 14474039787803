import { Typography } from '@og-pro/ui';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

const DateDisplay = ({ date, useOpenGovStyle }) => {
    if (useOpenGovStyle) {
        return <Typography variant="bodySmall">{date}</Typography>;
    }
    return date;
};

DateDisplay.propTypes = {
    date: PropTypes.string.isRequired,
    useOpenGovStyle: PropTypes.bool,
};

export const TemplateListTimestampColumns = ({ createdAt, lastUpdatedAt, useOpenGovStyle }) => {
    return (
        <div className="row">
            <div className="col-md-6" title={moment(createdAt).format('lll')}>
                <DateDisplay
                    date={moment(createdAt).format('ll')}
                    useOpenGovStyle={useOpenGovStyle}
                />
            </div>
            <div className="col-md-6" title={moment(lastUpdatedAt).format('lll')}>
                <DateDisplay
                    date={moment(lastUpdatedAt).fromNow()}
                    useOpenGovStyle={useOpenGovStyle}
                />
            </div>
        </div>
    );
};

TemplateListTimestampColumns.propTypes = {
    createdAt: PropTypes.string.isRequired,
    lastUpdatedAt: PropTypes.string.isRequired,
    useOpenGovStyle: PropTypes.bool,
};

import { resourceManager } from '../helpers';

export const LOAD_ACCOUNT_DATA = 'requisitionAccountDetails/LOAD_ACCOUNT_DATA';
export const LOAD_ACCOUNT_DATA_SUCCESS = 'requisitionAccountDetails/LOAD_ACCOUNT_DATA_SUCCESS';
export const LOAD_ACCOUNT_DATA_FAILURE = 'requisitionAccountDetails/LOAD_ACCOUNT_DATA_FAILURE';

export const loadAccountData = (accountNumber, fiscalPeriod) => {
    return resourceManager({
        method: 'get',
        url: `/requisitions/accounts/${accountNumber}/${fiscalPeriod}`,
        onStart: ({ dispatch }) => dispatch({ type: LOAD_ACCOUNT_DATA }),
        onSuccess: ({ dispatch, result }) => dispatch({ type: LOAD_ACCOUNT_DATA_SUCCESS, result }),
        onFailure: ({ dispatch, error }) => dispatch({ type: LOAD_ACCOUNT_DATA_FAILURE, error }),
    });
};

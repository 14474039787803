import { pick } from 'lodash';
import { createSelector } from 'reselect';

import { fields } from './constants';
import { getProposalJS } from '../../selectors';

export const getDeserializedProposal = createSelector([getProposalJS], (proposal) => {
    if (proposal) {
        return pick(proposal, [...fields, 'id', 'project_id']);
    }
});

export const isUploading = createSelector([getProposalJS], (proposal) => {
    if (proposal) {
        return proposal.proposalDocuments.some((doc) => {
            return doc.uploads.some((upload) => upload.uploading);
        });
    }
    return false;
});

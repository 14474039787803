import PropTypes from 'prop-types';
import React from 'react';

import { VendorDataGrid } from '../../../../components';

export const SubscribedVendorList = ({
    addVendorsToList,
    blockVendors,
    downloadVendorSearch,
    emailVendors,
    emailVendorsBySearchParams,
    governmentId,
    hideStatusColumn,
    includeExpandedData,
    inviteVendorsToProject,
    inviteVendorsToProjectBySearchParams,
    loadProcuratedRatingsClient,
    searchParams,
    showVendorProfileModal,
    vendorSearch,
    vendorSearchDownloading,
    addVendorsToListBySearch,
    searchActive,
    activeSearchParams,
}) => {
    return (
        <VendorDataGrid
            activeSearchParams={activeSearchParams}
            addVendorsToList={addVendorsToList}
            addVendorsToListBySearch={addVendorsToListBySearch}
            blockVendors={blockVendors}
            downloadVendorSearch={downloadVendorSearch}
            emailVendors={emailVendors}
            emailVendorsBySearchParams={emailVendorsBySearchParams}
            governmentId={governmentId}
            hideStatusColumn={hideStatusColumn}
            includeExpandedData={includeExpandedData}
            inviteVendorsToProject={inviteVendorsToProject}
            inviteVendorsToProjectBySearchParams={inviteVendorsToProjectBySearchParams}
            loadProcuratedRatingsClient={loadProcuratedRatingsClient}
            searchActive={searchActive}
            searchParams={searchParams}
            showVendorProfileModal={showVendorProfileModal}
            vendorSearch={vendorSearch}
            vendorSearchDownloading={vendorSearchDownloading}
        />
    );
};

SubscribedVendorList.propTypes = {
    addVendorsToList: PropTypes.func,
    blockVendors: PropTypes.func,
    downloadVendorSearch: PropTypes.func.isRequired,
    emailVendors: PropTypes.func,
    emailVendorsBySearchParams: PropTypes.func,
    governmentId: PropTypes.number.isRequired,
    hideStatusColumn: PropTypes.bool,
    includeExpandedData: PropTypes.bool,
    inviteVendorsToProject: PropTypes.func,
    inviteVendorsToProjectBySearchParams: PropTypes.func,
    loadProcuratedRatingsClient: PropTypes.func.isRequired,
    searchParams: PropTypes.shape({
        designations: PropTypes.arrayOf(PropTypes.number),
        isEmergencySearchDesignation: PropTypes.bool,
    }),
    showVendorProfileModal: PropTypes.func.isRequired,
    vendorSearch: PropTypes.func.isRequired,
    vendorSearchDownloading: PropTypes.bool,
    addVendorsToListBySearch: PropTypes.func,
    searchActive: PropTypes.bool.isRequired,
    activeSearchParams: PropTypes.any,
};

import { createSelector } from 'reselect';

import { ALL, DRAFT, LIVE } from './constants';
import { getTemplateChecklistsJS } from '../selectors';

const getLocation = (state, props) => props.location;

const getDraftTemplates = createSelector([getTemplateChecklistsJS], (templates) => {
    return templates.filter((templateChecklist) => !templateChecklist.template.isPublished);
});

const getLiveTemplates = createSelector([getTemplateChecklistsJS], (templates) => {
    return templates.filter((templateChecklist) => templateChecklist.template.isPublished);
});

export const getNavItems = createSelector(
    [getTemplateChecklistsJS, getDraftTemplates, getLiveTemplates],
    (allTemplates, draftTemplates, liveTemplates) => {
        return [
            {
                name: 'All',
                status: ALL,
                counter: allTemplates.length,
            },
            {
                name: 'Live',
                status: LIVE,
                counter: liveTemplates.length,
            },
            {
                name: 'Draft',
                status: DRAFT,
                counter: draftTemplates.length,
            },
        ];
    }
);

export const getSelectedNavItem = createSelector(
    [getNavItems, getLocation],
    (navItems, location) => {
        const {
            query: { status },
        } = location;

        return navItems.find((item) => item.status === status) || navItems[0];
    }
);

export const getSelectedTemplates = createSelector(
    [getSelectedNavItem, getTemplateChecklistsJS, getDraftTemplates, getLiveTemplates],
    (selectedNavItem, allTemplates, draftTemplates, liveTemplates) => {
        switch (selectedNavItem.status) {
            case ALL:
                return allTemplates;
            case LIVE:
                return liveTemplates;
            case DRAFT:
                return draftTemplates;
            default:
                return [];
        }
    }
);

import classnames from 'classnames';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { ListGroupItem } from 'react-bootstrap';
import { Box, Typography } from '@og-pro/ui';

import {
    getQuestionLogicValueDisplayFromQuestion,
    questionLogicActionNames,
    questionLogicLinkableModelNames,
    questionLogicOperatorNamesDisplay,
} from '@og-pro/shared-config/questionLogics';

import { useOGTheme } from '../../../../ogThemeProvider';
import { CDSButton } from '../../../../../../components';

const { HIDE } = questionLogicActionNames;

const { PROJECT_SECTION, SIGNATURE, SIGNATURE_BLOCK } = questionLogicLinkableModelNames;

export const QuestionLogicItem = (props) => {
    const { isOGThemeEnabledForComponents } = useOGTheme();
    const styles = require('./index.scss');

    const onDelete = (e) => {
        const { deleteHandler, index } = props;

        e.stopPropagation();

        deleteHandler(index);
    };

    const onShowQuestionLogicModal = (e) => {
        e.stopPropagation();

        const { disabled, index, questionLogic, showQuestionLogicModal } = props;

        if (disabled) {
            return;
        }

        showQuestionLogicModal(questionLogic, index);
    };

    const {
        disabled,
        meta: { invalid },
        questionLogic: {
            action,
            linkable,
            linkableItem,
            operator,
            projectSection,
            logicableUpfrontQuestion,
            value,
        },
        showFormValidation,
        templateProject,
    } = props;

    const showErrors = showFormValidation && invalid;
    const actionIcon = action === HIDE ? 'ban' : 'check';
    let linkableItemTitle =
        linkable === PROJECT_SECTION || linkable === SIGNATURE
            ? '(Entire section)'
            : `item: ${get(linkableItem, 'title') || 'Untitled'}`;

    // logic to determine the label for the automation logic.
    // its more complex because signatures/signatures blocks are not related to a project section
    if (linkable === SIGNATURE_BLOCK) {
        // when the linkable is a signature block we have an extra layer of complexity
        // if its a signature block header we will get the title from the header content
        linkableItemTitle = `Header: ${get(linkableItem, 'fields.0.value') || 'Untitled'}`;

        // if the signature block is a default block, we need to display the numbering to match the
        // numbering in the signatures form
        if (linkableItem.styling === 'default') {
            // for this we will find the relevant signature record
            const signature = (templateProject?.signatures || []).find((s) => {
                const [leftColumnBlocks = [], rightColumnBlocks = []] = s.signaturesBlocks;

                // we find it by matching the signature record that contains the signature block
                // which is the same as the linkable
                return (
                    leftColumnBlocks.some((b) => b.id === linkableItem.id) ||
                    rightColumnBlocks.some((b) => b.id === linkableItem.id)
                );
            });

            // once we have the signature, we will find the signature block that we care about
            // we will filter the signature headers and then find the index of the signature block
            const blocks = (signature?.signaturesBlocks || []).reduce((acc, signatureBlockCol) => {
                return [...acc, ...signatureBlockCol.filter((b) => b.styling === 'default')];
            }, []);
            const index = blocks.findIndex((b) => b.id === linkableItem.id);

            linkableItemTitle = `Block ${index + 1}`;
        }
    }
    let sectionTitle = get(projectSection, 'title') || 'Untitled Section';

    // signatures in the signature tab wont have a projectSection, so the title will be incorrectly
    // set as "untitled section"
    if (linkable === SIGNATURE || linkable === SIGNATURE_BLOCK) {
        sectionTitle = 'Signature';
    }

    if (!logicableUpfrontQuestion) {
        return null;
    }

    if (isOGThemeEnabledForComponents) {
        return (
            <Box className={styles.itemContainer} onClick={onShowQuestionLogicModal}>
                <Box>
                    <Box>
                        <Typography variant="h4">
                            If {logicableUpfrontQuestion.title}
                            <span className={classnames(styles.logicCondition, styles[operator])}>
                                {questionLogicOperatorNamesDisplay[operator].toUpperCase()}
                            </span>
                            &quot;
                            {getQuestionLogicValueDisplayFromQuestion(
                                logicableUpfrontQuestion,
                                value
                            )}
                            &quot;
                        </Typography>
                        <Box className={styles.logicResult}>
                            <i className="fa fa-arrow-right" />
                            Then
                            <span className={classnames(styles.logicCondition, styles[action])}>
                                {action.toUpperCase()}
                            </span>
                            {sectionTitle} {linkableItemTitle}
                        </Box>
                    </Box>
                    <Box mt={2} textAlign="right">
                        <Box
                            component={CDSButton}
                            // if there is not a project section we cant edit from here cause the form will not work properly
                            // so need to edit in the source where the projectSection can be faked
                            disabled={disabled || !projectSection}
                            mr={2}
                            onClick={onShowQuestionLogicModal}
                            qaTag="templateEdit-editQuestionLogic"
                            size="small"
                            title={!projectSection ? 'Can not be edited from here' : ''}
                            variant="text"
                        >
                            <i className="fa fa-edit" /> Edit
                        </Box>
                        <CDSButton
                            disabled={disabled}
                            onClick={onDelete}
                            qaTag="templateEdit-deleteQuestionLogic"
                            size="small"
                            variant="text"
                        >
                            <i className="fa fa-trash" /> Delete Automation
                        </CDSButton>
                    </Box>
                </Box>
            </Box>
        );
    }

    return (
        <ListGroupItem onClick={onShowQuestionLogicModal}>
            <div className={classnames(showErrors && 'text-danger')}>
                <div className="pull-right">
                    <span className={styles.deleteButton} onClick={onDelete}>
                        <i className="fa fa-lg fa-times text-danger" />
                    </span>
                </div>
                {showErrors && (
                    <span>
                        <i className="fa fa-exclamation-triangle" />
                        &nbsp;
                    </span>
                )}
                <div className={styles.condition}>
                    If <em>{logicableUpfrontQuestion.title}</em>
                    <strong className={styles.templateTag}>
                        {questionLogicOperatorNamesDisplay[operator].toUpperCase()}
                    </strong>
                    &quot;
                    {getQuestionLogicValueDisplayFromQuestion(logicableUpfrontQuestion, value)}
                    &quot;
                </div>
                <i className="fa fa-arrow-right text-primary" />
                &nbsp; Then
                <strong className={styles.templateTag}>
                    <i className={`fa fa-${actionIcon}`} /> {action.toUpperCase()}
                </strong>
                <em>
                    {get(projectSection, 'title') || 'Untitled Section'} {linkableItemTitle}
                </em>
            </div>
        </ListGroupItem>
    );
};

QuestionLogicItem.propTypes = {
    deleteHandler: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    index: PropTypes.number.isRequired,
    meta: PropTypes.shape({
        invalid: PropTypes.bool.isRequired,
    }).isRequired,
    questionLogic: PropTypes.shape({
        action: PropTypes.string,
        linkable: PropTypes.string,
        linkableItem: PropTypes.shape({
            id: PropTypes.number,
            styling: PropTypes.string, // a prop from signature blocks
            title: PropTypes.string,
        }).isRequired,
        operator: PropTypes.string,
        projectSection: PropTypes.shape({
            title: PropTypes.string,
        }).isRequired,
        logicableUpfrontQuestion: PropTypes.shape({
            data: PropTypes.shape({
                options: PropTypes.array,
            }),
            title: PropTypes.string.isRequired,
        }).isRequired,
        value: PropTypes.any,
    }).isRequired,
    showFormValidation: PropTypes.bool,
    showQuestionLogicModal: PropTypes.func.isRequired,
    templateProject: PropTypes.object,
};

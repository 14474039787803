/* eslint-disable react/prop-types */

import { assign } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ListGroup, ListGroupItem, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { pseudoSectionTypes, sectionTypeNames } from '@og-pro/shared-config/sections';

import { projectFormProps, ALL_SECTIONS } from '../constants';
import { menuActionHandler } from '../../../../actions/govProjects';
import { SUBMIT_INTAKE_FOR_REVIEW } from '../../../../constants/menuActions';

const mapStateToProps = (state) => {
    return {
        submitError: state.projectCreate.get('submitError'),
    };
};

const mapDispatchToProps = {
    menuActionHandler,
};

// @connect
class ConnectedSubmitConfirmation extends Component {
    static propTypes = assign({}, projectFormProps, {
        menuActionHandler: PropTypes.func.isRequired,
        project: PropTypes.shape({
            isIntake: PropTypes.bool.isRequired,
            useSectionDividers: PropTypes.bool.isRequired,
        }).isRequired,
        reviewPath: PropTypes.string.isRequired,
        submitError: PropTypes.string,
        writingPath: PropTypes.string.isRequired,
        writingSections: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                title: PropTypes.string.isRequired,
            })
        ).isRequired,
    });

    incompleteSections = {};

    get incompleteSectionCount() {
        return Object.keys(this.incompleteSections).length;
    }

    get styles() {
        return require('./SubmitConfirmation.scss');
    }

    componentDidMount() {
        // Touch all form fields so they will display error messages
        this.props.showFormValidation();
    }

    handleReviewProjectClick = () => {
        const { project, reviewPath } = this.props;

        if (project.isIntake) {
            this.props.menuActionHandler(SUBMIT_INTAKE_FOR_REVIEW, project);
        } else {
            this.props.router.push(`${reviewPath}/approvals`);
        }
    };

    handleSectionClick = (projectSection) => () => {
        const { scrollPageToTop, writingPath } = this.props;

        scrollPageToTop();
        this.props.router.push(`${writingPath}?section=${projectSection.id}`);
    };

    renderSectionStatus = ({ meta, projectSection, useSectionDividers }) => {
        const { valid } = meta;
        const listStyle = valid ? 'success' : undefined;
        const indentStyle =
            useSectionDividers &&
            !pseudoSectionTypes.some((type) => type === projectSection.section_type)
                ? this.styles.indent
                : undefined;

        if (!valid) {
            this.incompleteSections[projectSection.id] = true;
        }

        if (projectSection.section_type === sectionTypeNames.DIVIDER) {
            return (
                <ListGroupItem>
                    <div className={this.styles.sectionTitle} style={{ color: '#555555' }}>
                        {projectSection.title}
                    </div>
                </ListGroupItem>
            );
        }

        return (
            <ListGroupItem bsStyle={listStyle} onClick={this.handleSectionClick(projectSection)}>
                <div className={`${this.styles.sectionTitle} ${indentStyle}`}>
                    {projectSection.title}
                    {!valid && <i className={`fa fa-warning ${this.styles.warningIcon}`} />}
                </div>
                <span className={`fa-stack ${this.styles.checkContainer}`}>
                    <i
                        className={`fa fa-circle fa-stack-2x ${
                            valid ? '' : this.styles.iconCircle
                        }`}
                    />
                    <i className="fa fa-check fa-stack-1x fa-inverse" />
                </span>
            </ListGroupItem>
        );
    };

    renderSections() {
        const {
            project: { useSectionDividers },
            writingSections,
        } = this.props;

        return writingSections.map((projectSection) => (
            <Field
                component={this.renderSectionStatus}
                key={projectSection.id}
                name={`sections.${projectSection.id}`}
                projectSection={projectSection}
                useSectionDividers={useSectionDividers}
            />
        ));
    }

    renderNextSteps(valid) {
        const {
            builderDisplayName,
            project: { isIntake },
        } = this.props;

        if (valid) {
            return (
                <div className="text-center">
                    <p className="text-success">
                        <strong>
                            Great job, all the sections have been successfully completed!
                        </strong>
                    </p>
                    <p>
                        {isIntake
                            ? 'Click the button above to start the review and approval process.'
                            : 'Click the green “Review Approval Setup” button above to finalize those who will review this draft. Then, you will submit your draft for review.'}
                    </p>
                    <p>
                        Reviewing the {builderDisplayName.toLowerCase()} signals that your initial
                        draft is complete. After starting review, you will still be able to make
                        changes to your {builderDisplayName.toLowerCase()}. You will also be able to
                        add/remove approvers.
                    </p>
                </div>
            );
        }

        return (
            <div>
                <p className="text-center">
                    You have&nbsp;
                    <strong style={{ fontSize: '1.2em' }}>{this.incompleteSectionCount}</strong>
                    &nbsp;incomplete sections.
                </p>
                <p className="text-danger">
                    You must complete all required fields before starting{' '}
                    {builderDisplayName.toLowerCase()} review.
                </p>
                <p>
                    The sections with <i className="fa fa-warning text-danger" /> icons next to them
                    are missing required information. Please complete these sections before starting{' '}
                    {builderDisplayName.toLowerCase()} review.
                </p>
                <p>
                    The missing information within each section will be&nbsp;
                    <span className="text-danger">highlighted in red text</span>.
                </p>
            </div>
        );
    }

    renderSubmitContainer = ({ meta }) => {
        const { valid } = meta;
        const {
            builderDisplayName,
            disabled,
            project: { isIntake },
            projectSubmitting,
            submitError,
        } = this.props;

        const icon = projectSubmitting ? 'spinner fa-spin' : 'check-square-o';
        const buttonText = isIntake ? ` Submit ${builderDisplayName}` : ' Review Approval Setup';

        return (
            <div className={this.styles.submitBtnContainer}>
                <Button
                    bsStyle={valid ? 'success' : undefined}
                    className={this.styles.submitBtn}
                    disabled={!valid || disabled}
                    onClick={this.handleReviewProjectClick}
                >
                    <i className={`fa fa-fw fa-${icon}`} />
                    {buttonText}
                </Button>
                {submitError && (
                    <p className={this.styles.error}>Error submitting project: {submitError}</p>
                )}
                <div className={this.styles.nextSteps}>{this.renderNextSteps(valid)}</div>
            </div>
        );
    };

    render() {
        const { builderDisplayName } = this.props;

        const { sectionsStatus, title } = this.styles;

        return (
            <div className="row">
                <div className="col-xs-12 col-md-offset-2 col-md-8">
                    <h4 className={title}>{builderDisplayName} Sections Checklist</h4>
                    <ListGroup className={sectionsStatus}>{this.renderSections()}</ListGroup>
                    <Field component={this.renderSubmitContainer} name={ALL_SECTIONS} />
                </div>
            </div>
        );
    }
}

export const SubmitConfirmation = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedSubmitConfirmation);

import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Collapse, CriteriaScoreCell, ScoringCriteriaCell } from '../../../../../../../components';

export class EvaluatorScoreExpanded extends PureComponent {
    static propTypes = {
        proposal: PropTypes.shape({
            companyName: PropTypes.string,
            proposalEvaluation: PropTypes.shape({
                note: PropTypes.string,
                submittedProposalCriteriaScores: PropTypes.arrayOf(
                    PropTypes.shape({
                        comment: PropTypes.string,
                        score: PropTypes.number,
                    })
                ).isRequired,
                totalScore: PropTypes.number,
                totalScoreByPoints: PropTypes.number,
            }).isRequired,
        }).isRequired,
        scoringCriteria: PropTypes.array.isRequired,
        showComments: PropTypes.bool,
        showCriteriaDescriptions: PropTypes.bool,
        showPercentageTotals: PropTypes.bool,
        totalWeight: PropTypes.number.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = { showDetail: true };
    }

    get styles() {
        return require('./index.scss');
    }

    toggleShowDetails = () => this.setState((prevState) => ({ showDetail: !prevState.showDetail }));

    render() {
        const {
            proposal: { companyName, proposalEvaluation },
            scoringCriteria,
            showComments,
            showCriteriaDescriptions,
            showPercentageTotals,
            totalWeight,
        } = this.props;

        const { showDetail } = this.state;

        const scores = proposalEvaluation.submittedProposalCriteriaScores;

        return (
            <>
                <h4 className={this.styles.vendorName} onClick={this.toggleShowDetails}>
                    <div className="pull-right">
                        <i className={`fa fa-fw fa-angle-${showDetail ? 'down' : 'left'}`} />
                    </div>
                    {companyName || 'Unnamed Company'}
                </h4>
                <Collapse in={showDetail}>
                    {scoringCriteria.map((criterium, idx) => (
                        <React.Fragment key={criterium.id}>
                            {showCriteriaDescriptions && (
                                <ScoringCriteriaCell
                                    className={this.styles.criteriaDescription}
                                    scoringCriteria={criterium}
                                    showDescription
                                    totalWeight={totalWeight}
                                />
                            )}
                            <CriteriaScoreCell
                                comment={get(scores, [idx, 'comment'])}
                                criteriaScoreClassName={this.styles.criteriaScore}
                                scoringCriteria={criterium}
                                showComments={showComments}
                                showTitle={!showCriteriaDescriptions}
                                value={get(scores, [idx, 'score'])}
                            />
                        </React.Fragment>
                    ))}
                    <CriteriaScoreCell
                        comment={proposalEvaluation.note}
                        criteriaScoreClassName={this.styles.totalScore}
                        isTotal
                        showComments={showComments}
                        showPercentageTotals={showPercentageTotals}
                        showTitle
                        totalScorePercentage={proposalEvaluation.totalScore}
                        value={
                            showPercentageTotals
                                ? proposalEvaluation.totalScore
                                : proposalEvaluation.totalScoreByPoints
                        }
                    />
                </Collapse>
            </>
        );
    }
}

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { EvaluatorsListItem, ListError } from '../../../../../components/GovApp';

export class EvaluatorsList extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool.isRequired,
        fields: PropTypes.object.isRequired,
        meta: PropTypes.object.isRequired,
        showFormErrors: PropTypes.bool.isRequired,
    };

    get styles() {
        return require('./EvaluatorsList.scss');
    }

    render() {
        const { fields, meta, disabled, showFormErrors } = this.props;
        const Evaluators = fields.map((member, index) => {
            const evaluator = fields.get(index);
            return (
                <EvaluatorsListItem
                    disabled={disabled}
                    key={evaluator.id}
                    remove={() => fields.remove(index)}
                    user={evaluator}
                />
            );
        });

        const errorStyle = showFormErrors ? 'danger' : 'primary';
        return (
            <div>
                <ListError bsStyle={errorStyle} meta={meta} />
                <div className="row">
                    <div className="col-sm-offset-1 col-sm-10">{Evaluators}</div>
                </div>
            </div>
        );
    }
}

import { flatten } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ListGroup } from 'react-bootstrap';
import { connect } from 'react-redux';

import { form } from './constants';
import { getUserRolePermissionOptions } from './selectors';
import { UserRoleForm } from './UserRoleForm';
import { UserRoleListItem } from './UserRoleListItem';
import { getUserRolesJS } from '../selectors';
import { createUserRole, deleteUserRole, updateUserRole } from '../../../actions/adminGovernment';
import { showConfirmationSimpleModal } from '../../../actions/confirmation';
import { Button, Main, SectionTitle, Well, ZeroState } from '../../../components';
import { exportArrayToCSV } from '../../../utils';

const mapStateToProps = (state) => {
    return {
        userRolePermissionOptions: getUserRolePermissionOptions(state),
        userRoles: getUserRolesJS(state),
    };
};

const mapDispatchToProps = {
    createUserRole,
    deleteUserRole,
    showConfirmationSimpleModal,
    updateUserRole,
};

// @connect
class ConnectedUserRolesAdmin extends Component {
    static propTypes = {
        createUserRole: PropTypes.func.isRequired,
        deleteUserRole: PropTypes.func.isRequired,
        showConfirmationSimpleModal: PropTypes.func.isRequired,
        updateUserRole: PropTypes.func.isRequired,
        userRolePermissionOptions: PropTypes.array.isRequired,
        userRoles: PropTypes.array.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            createError: null,
            creating: false,
            showForm: false,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    createUserRole = (formData) => {
        this.setState({ createError: null, creating: true });
        this.props
            .createUserRole(formData)
            .then(() => {
                this.setState({ creating: false, showForm: false });
            })
            .catch((error) => {
                this.setState({ createError: error.message, creating: false });
            });
    };

    deleteConfirmation = (deleteHandler) => {
        this.props.showConfirmationSimpleModal(deleteHandler, {
            btnText: 'Delete',
            text:
                'Are you sure you want to delete this role?\n' +
                'All users assigned to this role will lose their associated permissions.',
        });
    };

    handleExportCSVClick = () => {
        const { userRoles, userRolePermissionOptions } = this.props;

        const headers = [
            'Name',
            ...flatten(
                userRolePermissionOptions.map((option) => {
                    return option.permissions.map((permission) => permission.label);
                })
            ),
        ];

        const rows = userRoles.map((userRole) => {
            return [
                userRole.name,
                ...flatten(
                    userRolePermissionOptions.map((option) => {
                        return option.permissions.map((permission) => {
                            return userRole[permission.name] ? 'X' : '';
                        });
                    })
                ),
            ];
        });

        exportArrayToCSV([headers].concat(rows), { fileName: 'User Roles', headers: true });
    };

    updateUserRole = (userRole, data) => {
        return this.props.updateUserRole(userRole, data);
    };

    toggleFormHandler = () => this.setState((prevState) => ({ showForm: !prevState.showForm }));

    renderUserRolesList() {
        const { userRolePermissionOptions, userRoles } = this.props;

        const { showForm } = this.state;

        const userRolesItems = userRoles.map((userRole) => (
            <UserRoleListItem
                deleteConfirmation={this.deleteConfirmation}
                deleteHandler={this.props.deleteUserRole}
                key={userRole.id}
                options={userRolePermissionOptions}
                updateHandler={this.updateUserRole}
                userRole={userRole}
            />
        ));

        return (
            <div>
                {showForm && this.renderCreateForm()}
                <div className={this.styles.exportButton}>
                    <Button
                        bsSize="sm"
                        onClick={this.handleExportCSVClick}
                        qaTag="connectedUserRolesAdmin-exportToCsv"
                    >
                        <i className="fa fa-download" /> Export to CSV
                    </Button>
                    {!showForm && (
                        <Button
                            bsStyle="success"
                            onClick={this.toggleFormHandler}
                            qaTag="connectedUserRolesAdmin-addRole"
                        >
                            <i className="fa fa-plus" /> Add Role
                        </Button>
                    )}
                </div>
                <ListGroup>{userRolesItems}</ListGroup>
            </div>
        );
    }

    renderCreateForm() {
        const { userRolePermissionOptions } = this.props;

        const { createError, creating } = this.state;

        return (
            <Well>
                <UserRoleForm
                    closeForm={this.toggleFormHandler}
                    disabled={creating}
                    form={form}
                    isNew
                    onSubmit={this.createUserRole}
                    options={userRolePermissionOptions}
                    serverError={createError}
                />
            </Well>
        );
    }

    renderZeroState() {
        const { showForm } = this.state;

        if (showForm) {
            return this.renderCreateForm();
        }

        return (
            <ZeroState
                buttonClickHandler={this.toggleFormHandler}
                buttonText={
                    <span>
                        <i className="fa fa-plus" /> Add Role
                    </span>
                }
                title="No user roles have been added yet"
            />
        );
    }

    render() {
        const { userRoles } = this.props;

        return (
            <Main className="row">
                <div className="col-md-offset-2 col-md-8">
                    <SectionTitle info="Define user roles for your system" title="Roles Admin" />
                    {userRoles.length === 0 ? this.renderZeroState() : this.renderUserRolesList()}
                </div>
            </Main>
        );
    }
}

export const UserRolesAdmin = connect(mapStateToProps, mapDispatchToProps)(ConnectedUserRolesAdmin);

import { flatten } from 'lodash';
import { createSelector } from 'reselect';

import { attachmentTypesDict } from '@og-pro/shared-config/attachments';

const { ADDENDUM, PROJECT_DOCUMENT, INTERNAL, NOTICE } = attachmentTypesDict;

const getProject = (props) => props.project;

const getAttachments = createSelector([getProject], (project) => {
    if (project) {
        const { documentAttachment, attachments } = project;

        const filteredAttachments = attachments.filter((attach) => !attach.isHiddenByLogic);

        return documentAttachment
            ? [documentAttachment].concat(filteredAttachments)
            : filteredAttachments;
    }
    return [];
});

const getAddendumAttachments = createSelector([getProject], (project) => {
    if (project) {
        const { addendums } = project;
        return flatten(addendums.map((addendum) => addendum.attachments));
    }
    return [];
});

export const getAttachmentSections = createSelector(
    [getAttachments, getAddendumAttachments],
    (attachments, addendumAndNoticeAttachments) => {
        const projectDocumentAttachments = attachments.filter(
            (attach) => attach.type === PROJECT_DOCUMENT
        );
        const otherAttachments = attachments.filter(
            (attach) =>
                attach.type !== PROJECT_DOCUMENT &&
                attach.type !== ADDENDUM &&
                attach.type !== INTERNAL
        );
        const addendumAttachments = addendumAndNoticeAttachments.filter(
            (attach) => attach.type === ADDENDUM
        );
        const noticeAttachments = addendumAndNoticeAttachments.filter(
            (attach) => attach.type === NOTICE
        );

        return [
            {
                title: 'Project Documents',
                attachments: projectDocumentAttachments,
            },
            {
                title: 'Attachments',
                attachments: otherAttachments,
            },
            {
                title: 'Addenda',
                attachments: addendumAttachments,
            },
            {
                title: 'Official Notices',
                attachments: noticeAttachments,
            },
        ].filter((section) => section.attachments.length > 0);
    }
);

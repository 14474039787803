import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Outlet } from 'react-router-dom';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { ProposalEvaluationNav as ProposalEvaluationNavComponent } from '../components';
import { getEvaluationPath, getEvaluator, getProposalForEvaluationJS } from '../../selectors';
import connectData from '../../../ConnectData';
import { LoadingSpinner, LoadingError } from '../../../../components';
import {
    loadProposalToEvaluate,
    resetProposalToEvaluation,
    shouldLoadProposalToEvaluate,
} from '../../../../actions/proposalEvaluations';

function fetchData(getState, dispatch, location, params) {
    const projectId = Number.parseInt(params.projectId, 10);
    const proposalId = Number.parseInt(params.proposalId, 10);

    if (!shouldLoadProposalToEvaluate(getState(), proposalId)) {
        return Promise.resolve();
    }
    return dispatch(loadProposalToEvaluate(projectId, proposalId, params.evaluatorId));
}

const mapStateToProps = (state, props) => {
    const {
        location: { pathname },
        params: { proposalId },
        proposalEvaluationsPath,
    } = props;

    let backUrl = `${getEvaluationPath(state, props)}${proposalEvaluationsPath}`;

    if (pathname.match(/\/vendor\/?$/)) {
        backUrl += `/${proposalId}`;
    } else if (pathname.match(/\/documents\/?$/)) {
        backUrl += `/${proposalId}`;
    } else if (pathname.match(/\/documents\/\d+\/?$/)) {
        backUrl += `/${proposalId}/documents`;
    }

    return {
        backUrl,
        evaluator: getEvaluator(state, props),
        loadError: state.proposalEvaluations.get('loadError'),
        loading: state.proposalEvaluations.get('loading'),
        proposal: getProposalForEvaluationJS(state),
    };
};

const mapDispatchToProps = {
    resetProposalToEvaluation,
};

// @connectData
// @connect
class ConnectedProposalEvaluationNav extends Component {
    static propTypes = {
        backUrl: PropTypes.string.isRequired,
        evaluator: PropTypes.object,
        isConsensusView: PropTypes.bool,
        loadError: PropTypes.string,
        loading: PropTypes.bool.isRequired,
        proposal: PropTypes.object,
        resetProposalToEvaluation: PropTypes.func.isRequired,
    };

    componentWillUnmount() {
        this.props.resetProposalToEvaluation();
    }

    render() {
        const { backUrl, evaluator, isConsensusView, loadError, loading, proposal } = this.props;

        if (loading) {
            return <LoadingSpinner />;
        }

        if (loadError || !proposal) {
            return <LoadingError error={loadError} />;
        }

        return (
            <>
                <ProposalEvaluationNavComponent
                    backUrl={backUrl}
                    evaluator={evaluator}
                    isConsensusView={isConsensusView}
                />
                <Outlet />
            </>
        );
    }
}

export const ProposalEvaluationNav = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedProposalEvaluationNav);

// Evaluator proposal evaluation nav container
const EvaluatorProposalEvaluationNav = (props) => {
    const {
        params: { evaluatorId },
    } = props;

    return (
        <ProposalEvaluationNav
            proposalEvaluationsPath={`/evaluators/${evaluatorId}/proposal-evaluations`}
            {...props}
        />
    );
};
EvaluatorProposalEvaluationNav.propTypes = {
    params: PropTypes.shape({
        evaluatorId: PropTypes.string.isRequired,
    }).isRequired,
};
ProposalEvaluationNav.Evaluator = compose(
    connectData(fetchData),
    withRouter
)(EvaluatorProposalEvaluationNav);

// Current user proposal evaluation nav container
const UserProposalEvaluationNav = (props) => {
    return <ProposalEvaluationNav proposalEvaluationsPath="/proposal-evaluations" {...props} />;
};
ProposalEvaluationNav.User = connectData(fetchData)(UserProposalEvaluationNav);

// Consensus proposal evaluation nav container
const ConsensusProposalEvaluationNav = (props) => {
    return (
        <ProposalEvaluationNav
            isConsensusView
            proposalEvaluationsPath="/consensus-scorecard"
            {...props}
        />
    );
};
ProposalEvaluationNav.Consensus = connectData(fetchData)(ConsensusProposalEvaluationNav);

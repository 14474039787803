import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '../../../../../../../components';

export const PriceTableButtons = ({
    copyPriceTable,
    disabled,
    handleOptionsToggleClick,
    removeTable,
}) => {
    const styles = require('./index.scss');

    return (
        <>
            <Button
                bsSize="sm"
                className={styles.tableButton}
                disabled={disabled}
                onClick={handleOptionsToggleClick}
                qaTag="priceTable-tableSettings"
            >
                <i className="fa fa-gear fa-lg" /> Table Settings
            </Button>
            <Button
                bsSize="sm"
                className={styles.tableButton}
                disabled={disabled}
                onClick={copyPriceTable}
                qaTag="priceTable-copyTable"
            >
                <i className="fa fa-copy" /> Copy Table
            </Button>
            <Button
                bsSize="sm"
                bsStyle="danger"
                className={styles.tableButton}
                disabled={disabled}
                onClick={removeTable}
                qaTag="priceTable-deleteTable"
            >
                <i className="fa fa-trash-o fa-lg" /> Delete Table
            </Button>
        </>
    );
};

PriceTableButtons.propTypes = {
    copyPriceTable: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    handleOptionsToggleClick: PropTypes.func.isRequired,
    removeTable: PropTypes.func.isRequired,
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export class StatusCircle extends PureComponent {
    static propTypes = {
        bsStyle: PropTypes.string,
        children: PropTypes.node.isRequired,
    };

    render() {
        const style = require('./StatusCircle.scss');
        const { bsStyle = 'primary' } = this.props;
        return (
            <div className={`${style.statusCircle} ${style[bsStyle]}`}>{this.props.children}</div>
        );
    }
}

import { createSelector } from 'reselect';

import { getActiveUsersJS } from '../../../containers/selectors';
import { hasUserRolesSubscription } from '../../../containers/GovApp/selectors';
import { userHasPermissionRole } from '../../../helpers';

export const getAdminUsersJS = createSelector(
    [getActiveUsersJS, hasUserRolesSubscription, (state, props) => props.permissionType],
    (users, hasUserRoles, permissionType) => {
        return users.filter((user) => userHasPermissionRole(user, permissionType, hasUserRoles));
    }
);

import { omit, set } from 'lodash';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { getUserJS } from '../selectors';
import { showProjectCreateModal } from '../../actions/project/create/projectCreate';

export const IntegrationRedirect = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(getUserJS);
    const [searchParams] = useSearchParams();
    const query = Object.fromEntries(searchParams);

    useEffect(() => {
        const { provider, ...queryData } = query;

        // Budget has moved to a separate nested model while the query params are flat
        // This adjusts the budget query params to conform to the budget model.
        // Other project fields can also be transformed, `dataField` is optional
        const fieldsToTransform = [
            {
                dataField: 'budget.amount',
                queryField: 'budget',
                transformFn: (data) => {
                    const budgetNumber = Number.parseInt(data, 10);
                    return Number.isNaN(budgetNumber) ? undefined : budgetNumber;
                },
            },
            {
                dataField: 'budget.description',
                queryField: 'budgetDescription',
                transformFn: (data) => data,
            },
            {
                dataField: 'budget.identifier',
                queryField: 'budgetId',
                transformFn: (data) => data,
            },
            {
                dataField: 'financialId',
                queryField: 'projectId',
                transformFn: (data) => data,
            },
            {
                dataField: 'requisitionIdentifier',
                queryField: 'requisitionId',
                transformFn: (data) => data,
            },
        ];

        const createData = {
            ...omit(
                queryData,
                fieldsToTransform.map((field) => field.queryField)
            ),
            budget: {},
        };

        fieldsToTransform.forEach(({ dataField, queryField, transformFn }) => {
            const value = queryData[queryField];
            if (value !== null && value !== undefined) {
                set(createData, dataField || queryField, transformFn(value));
            }
        });

        // Example query format:
        // ?provider=OpenGov&title=Integration%20Test&financialId=2020-001&budget=50000&budgetDescription=IT%20Help%20Desk%20Fund&budgetId=30078
        dispatch(
            showProjectCreateModal({
                createData,
                integration: provider || true,
            })
        );

        navigate(`/governments/${user.government_id}/projects`, { replace: true });
    }, []);

    return <h3 className="text-center text-procurenow">Redirecting your request...</h3>;
};

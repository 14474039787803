import { Menu as MenuIcon } from '@mui/icons-material';
import { ReduxFormTextField } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { FormGroup } from '..';
import { qaTagName } from '../../../../constants';

export const SectionTitleFormGroup = ({ disabled, sectionInstructionsField, sectionNameField }) => {
    return (
        <FormGroup Icon={MenuIcon} title="Title">
            <Field
                component={ReduxFormTextField}
                disabled={disabled}
                fullWidth
                label="Section Name*"
                name={sectionNameField}
                qaTag={`${qaTagName}-${sectionNameField}`}
            />
            <Field
                component={ReduxFormTextField}
                description="Optional secondary information intended to guide users on how to answer the question."
                disabled={disabled}
                fullWidth
                label="Instruction Text"
                name={sectionInstructionsField}
                qaTag={`${qaTagName}-${sectionInstructionsField}`}
            />
        </FormGroup>
    );
};

SectionTitleFormGroup.propTypes = {
    disabled: PropTypes.bool,
    sectionInstructionsField: PropTypes.string.isRequired,
    sectionNameField: PropTypes.string.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

export const RequireUser = ({ children }) => {
    const user = useSelector((state) => state.auth.get('user'));

    if (!user) {
        return <Navigate replace to="/login" />;
    }

    return children;
};

RequireUser.propTypes = {
    children: PropTypes.node.isRequired,
};

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Modal, ListGroup } from 'react-bootstrap';

import { CriteriaScorecardForm } from './CriteriaScorecardForm';
import { fieldNames } from './CriteriaScorecardForm/constants';
import { CriteriaScorecardItem } from './CriteriaScorecardItem';

const { PROPOSAL_CRITERIA_SCORES } = fieldNames;

export class EvaluationScorecardModal extends PureComponent {
    static propTypes = {
        canEvaluate: PropTypes.bool.isRequired,
        evaluatorId: PropTypes.number,
        hideModal: PropTypes.func.isRequired,
        isCommentRequired: PropTypes.bool.isRequired,
        isCompleteStatus: PropTypes.bool.isRequired,
        isConsensusView: PropTypes.bool.isRequired,
        proposal: PropTypes.shape({
            companyName: PropTypes.string,
            proposalEvaluation: PropTypes.shape({
                updateError: PropTypes.string,
                updating: PropTypes.bool,
            }).isRequired,
        }).isRequired,
        proposalCriteriaScores: PropTypes.array.isRequired,
        scoringCriteria: PropTypes.array.isRequired,
        submitProposalEvaluation: PropTypes.func.isRequired,
        totalWeight: PropTypes.number.isRequired,
        unsubmitProposalEvaluation: PropTypes.func.isRequired,
        updateProposalCriteriaScores: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            updateError: null,
            updating: false,
        };
    }

    get styles() {
        return require('./EvaluationScorecardModal.scss');
    }

    submitHandler = (closeModalOnSave, submitScorecardAfterSave) => (data) => {
        const {
            evaluatorId,
            hideModal,
            isConsensusView,
            proposal,
            submitProposalEvaluation,
            updateProposalCriteriaScores,
        } = this.props;

        const updateKey = closeModalOnSave ? 'updatingWithClose' : 'updating';

        this.setState({
            [updateKey]: true,
            updateError: null,
        });

        const opts = { skipDispatch: submitScorecardAfterSave };
        return updateProposalCriteriaScores(proposal, data, evaluatorId, isConsensusView, opts)
            .then(() => {
                if (submitScorecardAfterSave) {
                    return submitProposalEvaluation().then(hideModal);
                }
                if (closeModalOnSave) {
                    return hideModal();
                }
                this.setState({ [updateKey]: false });
            })
            .catch((error) => {
                this.setState({
                    [updateKey]: false,
                    updateError: error.message,
                });
            });
    };

    // TODO: Reuse this or delete
    renderScorecardItems() {
        const {
            canEvaluate,
            isCommentRequired,
            isCompleteStatus,
            proposalCriteriaScores,
            scoringCriteria,
            totalWeight,
        } = this.props;

        const scorecardItems = scoringCriteria.map((criteria) => {
            return (
                <CriteriaScorecardItem
                    canEvaluate={canEvaluate}
                    criteria={criteria}
                    isCommentRequired={isCommentRequired}
                    isComplete={isCompleteStatus}
                    key={criteria.id}
                    score={proposalCriteriaScores[criteria.id]}
                    totalWeight={totalWeight}
                />
            );
        });

        return <ListGroup>{scorecardItems}</ListGroup>;
    }

    renderScorecardForm() {
        const {
            canEvaluate,
            isCommentRequired,
            isCompleteStatus,
            proposal: { proposalEvaluation },
            proposalCriteriaScores,
            totalWeight,
            unsubmitProposalEvaluation,
        } = this.props;

        const { updateError, updating, updatingWithClose } = this.state;

        return (
            <CriteriaScorecardForm
                canEvaluate={canEvaluate}
                initialValues={{
                    [PROPOSAL_CRITERIA_SCORES]: proposalCriteriaScores,
                }}
                isCommentRequired={isCommentRequired}
                isCompleteStatus={isCompleteStatus}
                submitHandler={this.submitHandler}
                totalWeight={totalWeight}
                unsubmitProposalEvaluation={unsubmitProposalEvaluation}
                updateError={updateError || proposalEvaluation.updateError}
                updating={updating}
                updatingProposalEvaluation={proposalEvaluation.updating}
                updatingWithClose={updatingWithClose}
            />
        );
    }

    render() {
        const { hideModal, proposal } = this.props;

        return (
            <Modal bsSize="xl" onHide={hideModal} show>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">
                        {proposal.companyName || 'Unnamed Company'} Scorecard
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.renderScorecardForm()}</Modal.Body>
            </Modal>
        );
    }
}

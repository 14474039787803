import PropTypes from 'prop-types';

export const TIMELINE_PROPTYPE = PropTypes.shape({
    date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    displayTime: PropTypes.bool,
    hasProposalDeadlineLocation: PropTypes.bool,
    hasProposalDeadlineLocationField: PropTypes.string,
    hasAuctionExtension: PropTypes.bool,
    hasAuctionExtensionField: PropTypes.string,
    hasTextDate: PropTypes.bool,
    id: PropTypes.number,
    index: PropTypes.number,
    isCustomDate: PropTypes.bool,
    isIncluded: PropTypes.bool,
    isPreProposalPublic: PropTypes.bool,
    isPublic: PropTypes.bool,
    isRequiredField: PropTypes.string,
    location: PropTypes.string,
    locationField: PropTypes.string,
    orderById: PropTypes.number,
    orderByIdField: PropTypes.string,
    projectField: PropTypes.string,
    required: PropTypes.bool,
    showLocation: PropTypes.bool,
    textDate: PropTypes.string,
    title: PropTypes.string.isRequired,
    uuid: PropTypes.string,
});

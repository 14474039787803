import React from 'react';
import PropTypes from 'prop-types';
import { ListGroupItem } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export const GovListItem = (props) => {
    const navigate = useNavigate();

    const routeClick = () => {
        return navigate(props.to);
    };

    const { name, logo, city, state } = props.organization;
    const styles = require('./GovList.scss');
    return (
        <ListGroupItem onClick={routeClick}>
            <img alt={name} className={`img-rounded ${styles.logo}`} src={logo} />
            <div className={styles.info}>
                <h4>{name}</h4>
                <div className="text-muted">
                    <em>
                        {city}, {state}
                    </em>
                </div>
            </div>
        </ListGroupItem>
    );
};

GovListItem.propTypes = {
    organization: PropTypes.object.isRequired,
    to: PropTypes.string.isRequired,
};

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { LOADING_SPINNER_HTML } from './contants';
import { isInitialClientLoaded } from '../../../containers/selectors';

const mapStateToProps = (state) => {
    return {
        isClientLoaded: isInitialClientLoaded(state),
    };
};

// @connect
class ConnectedDocumentViewer extends PureComponent {
    static propTypes = {
        isClientLoaded: PropTypes.bool.isRequired,
        url: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            iframeTimeoutId: null,
            refreshUrl: false,
        };
    }

    componentDidMount() {
        // Google docs API often returns no response, so need to continually retry until we get one
        const iframeTimeoutId = setInterval(this.updateIframeSrc, 1000 * 3);
        this.setState({ iframeTimeoutId });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.url && this.props.url !== nextProps.url) {
            // Clear interval incase current doc hadn't finished loading
            this.clearInterval();

            // Creates the appearance of a new loading state for the iframe viewer and
            // clears the existing iframe image
            this.setState({ refreshUrl: true }, () => {
                setTimeout(() => {
                    // Start a new interval for loading iframe data
                    const iframeTimeoutId = setInterval(this.updateIframeSrc, 1000 * 3);
                    this.setState({
                        iframeTimeoutId,
                        refreshUrl: false,
                    });
                }, 400);
            });
        }
    }

    componentWillUnmount() {
        this.clearInterval();
    }

    clearInterval = () => {
        clearInterval(this.state.iframeTimeoutId);
    };

    getIframeLink = () => {
        const { url } = this.props;

        const { refreshUrl } = this.state;

        if (refreshUrl) {
            return LOADING_SPINNER_HTML;
        }

        // URL must be encoded to escape query params. Otherwise will not be
        // able to be rendered by Google Drive viewer
        const encodedUrl = encodeURIComponent(url);

        return `https://docs.google.com/viewer?url=${encodedUrl}&embedded=true`;
    };

    setRef = (ref) => {
        this.iframe = ref;
    };

    updateIframeSrc = () => {
        if (this.iframe) {
            this.iframe.src = this.getIframeLink();
        }
    };

    render() {
        const { isClientLoaded } = this.props;

        if (!isClientLoaded) {
            return null;
        }

        const styles = require('./DocumentViewer.scss');
        const src = this.getIframeLink();
        const isLoadingSpinner = src === LOADING_SPINNER_HTML;
        return (
            <div>
                <iframe
                    className={styles.previewer}
                    frameBorder="1"
                    onLoad={isLoadingSpinner ? undefined : this.clearInterval}
                    ref={this.setRef}
                    src={src}
                    title="Document viewer embed"
                />
            </div>
        );
    }
}

export const DocumentViewer = connect(mapStateToProps)(ConnectedDocumentViewer);

import { createSelector } from 'reselect';

import { getTimezone } from '../../selectors';
import { convertToDate } from '../../../utils';

const getCalendar = (state) => state.calendar.get('calendar');
const getLoadingCalendar = (state) => state.calendar.get('loadingCalendar');

export const getCalendarJS = createSelector(
    [getCalendar, getLoadingCalendar, getTimezone],
    (rawCalendar, loadingCalendar, timezone) => {
        if (rawCalendar) {
            const calendar = rawCalendar.toJS();

            /**
             * Date conversion is required to convert from the UTC date stored in the database
             * to the date displayed in the calender that the user views to specify the
             * date in their government's timezone.
             */
            calendar.events = loadingCalendar
                ? []
                : calendar.events.map((event) => {
                      return {
                          ...event,
                          end: convertToDate(event.end, timezone),
                          start: convertToDate(event.start, timezone),
                      };
                  });

            return calendar;
        }
    }
);

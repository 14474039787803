import { showSnackbar } from '../notification';

export const UPDATE = 'account/subscriptions/UPDATE';
export const UPDATE_SUCCESS = 'account/subscriptions/UPDATE_SUCCESS';
export const UPDATE_FAIL = 'account/subscriptions/UPDATE_FAIL';

export const DELETE_SUCCESS = 'account/subscriptions/DELETE_SUCCESS';

export function removeGovSubscription(subscriptionId) {
    return (dispatch, getState, client) => {
        dispatch({ type: UPDATE, subscriptionId });
        return client
            .del(`/user/me/government-subscriptions/${subscriptionId}`)
            .then(() => {
                dispatch(showSnackbar('Subscription removed'));
                dispatch({ type: UPDATE_SUCCESS, subscriptionId });
                dispatch({ type: DELETE_SUCCESS, subscriptionId });
            })
            .catch((error) => {
                dispatch({ type: UPDATE_FAIL, error, subscriptionId });
            });
    };
}

export const SUBSCRIBE_SUCCESS = 'account/subscriptions/SUBSCRIBE_SUCCESS';

export function updateGovSubscription(subscriptionId, data) {
    return (dispatch, getState, client) => {
        dispatch({ type: UPDATE, subscriptionId });
        return client
            .put(`/user/me/government-subscriptions/${subscriptionId}`, { data })
            .then((result) => {
                dispatch(showSnackbar('Subscription settings updated'));
                dispatch({ type: UPDATE_SUCCESS, subscriptionId });
                dispatch({ type: SUBSCRIBE_SUCCESS, subscriptionId, result });
            })
            .catch((error) => {
                dispatch({ type: UPDATE_FAIL, error, subscriptionId });
            });
    };
}

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';

import { form, options, ACTIVE, FILTER } from './constants';
import { SearchSelect } from '../../../..';

const formConfig = {
    form,
    initialValues: {
        [FILTER]: ACTIVE,
    },
};

// @reduxForm
class ConnectedGovQuestionStatusFilterForm extends Component {
    static propTypes = {
        formClassName: PropTypes.string,
    };

    searchSelectStyles = {
        control: {
            height: 30,
            minHeight: 30,
        },
    };

    render() {
        const { formClassName } = this.props;

        return (
            <Field
                aria-label="Filter by Status"
                blurInputOnSelect
                component={SearchSelect}
                formClassName={formClassName}
                isSearchable={false}
                name={FILTER}
                options={options}
                overrideFeedback
                placeholder="Filter by Status"
                styles={this.searchSelectStyles}
            />
        );
    }
}

export const GovQuestionStatusFilterForm = reduxForm(formConfig)(
    ConnectedGovQuestionStatusFilterForm
);

import PropTypes from 'prop-types';
import React from 'react';

import { approvalStatusTypes } from '@og-pro/shared-config/requisitions';

import { UserProfilePicture } from '../../../../../../components';

export const ReviewerDisplay = ({ currentStep }) => {
    const {
        minPositionReviews,
        stepApproval: { stepPositionApprovals },
    } = currentStep;

    const styles = require('./index.scss');

    // Display for a single position approval
    if (stepPositionApprovals.length === 1) {
        const stepPositionApproval = stepPositionApprovals[0];
        const { position, stepPositionApprovers } = stepPositionApproval;
        const { user: reviewer } =
            stepPositionApprovers.find((approver) => approver.isPrimary) ||
            stepPositionApprovers[0];
        return (
            <div className={styles.user}>
                <UserProfilePicture horizontal user={reviewer} />
                <div>
                    <div>{reviewer.displayName}</div>
                    <div className={styles.position}>{position.name}</div>
                </div>
            </div>
        );
    }

    // Display for multiple position approvals
    const approvals = stepPositionApprovals.filter(
        ({ status }) => status === approvalStatusTypes.APPROVED
    );
    return `${minPositionReviews - approvals.length} of ${minPositionReviews} Approvals`;
};

ReviewerDisplay.propTypes = {
    currentStep: PropTypes.shape({
        id: PropTypes.number.isRequired,
        minPositionReviews: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        stepApproval: PropTypes.shape({
            stepPositionApprovals: PropTypes.arrayOf(
                PropTypes.shape({
                    position: PropTypes.shape({
                        name: PropTypes.string.isRequired,
                    }).isRequired,
                    stepPositionApprovers: PropTypes.arrayOf(
                        PropTypes.shape({
                            user: PropTypes.shape({
                                displayName: PropTypes.string.isRequired,
                            }).isRequired,
                        }).isRequired
                    ).isRequired,
                })
            ),
        }).isRequired,
    }).isRequired,
};

import { useCustomFormServiceMutation } from './useCustomFormService';
import { editCustomForm } from './queries';

export const useUpdateCustomForm = (customFormId) => {
    return useCustomFormServiceMutation({
        mutationKey: 'updateCustomForm',
        mutation: editCustomForm,
        enabled: true,
        params: { customFormId },
    });
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Select from 'react-select';
import { createSearchParams } from 'react-router-dom';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { tokens } from '@opengov/capital-style';

import { getLineItemAwardsJS, generateVendorLineItemAwards, getIsViewOnly } from './selectors';
import { getProjectJS, isEvaluationEditor } from '../../selectors';
import connectData from '../../../ConnectData';
import { menuActionHandler, showInstructionsModal } from '../../../../actions/govProjects';
import { loadLineItemAwards, showProposalSelect } from '../../../../actions/proposalEvaluations';
import {
    Button,
    LoadingError,
    LoadingSpinner,
    SectionTitle,
    ZeroState,
} from '../../../../components';
import { ReportsModalButton } from '../..';
import { UNSEAL_BIDS } from '../../../../constants/menuActions';
import { VendorLineItemAwardsTable } from '../../../../components/LineItemAwardTable/VendorLineItemAwardsTable';

function fetchData(getState, dispatch, location, params) {
    const projectId = Number.parseInt(params.projectId, 10);
    return dispatch(loadLineItemAwards(projectId));
}

const mapStateToProps = (state) => {
    const awardsForOmitCheck = getLineItemAwardsJS(state);
    return {
        error: state.proposalEvaluations.get('loadLineItemAwardsError'),
        omitLineItem:
            (awardsForOmitCheck &&
                awardsForOmitCheck.length > 0 &&
                awardsForOmitCheck[0].omitLineItem) ||
            false,
        isEditor: isEvaluationEditor(state),
        isPricingSealed: state.proposalEvaluations.get('lineItemAwardsPricingSealed'),
        isViewOnly: getIsViewOnly(state),
        loading: state.proposalEvaluations.get('loadingLineItemAwards'),
        project: getProjectJS(state),
        vendorAwardData: generateVendorLineItemAwards(state),
    };
};

const mapDispatchToProps = {
    menuActionHandler,
    showInstructionsModal,
    showProposalSelect,
};

// @connectData
// @connect
class ConnectedVendorAwards extends Component {
    static propTypes = {
        vendorAwardData: PropTypes.array,
        error: PropTypes.string,
        omitLineItem: PropTypes.bool.isRequired,
        isEditor: PropTypes.bool,
        isPricingSealed: PropTypes.bool.isRequired,
        isViewOnly: PropTypes.bool,
        loading: PropTypes.bool,
        location: PropTypes.shape({
            pathname: PropTypes.string,
            query: PropTypes.shape({
                proposal: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.arrayOf(PropTypes.string),
                ]),
            }),
        }),
        menuActionHandler: PropTypes.func.isRequired,
        project: PropTypes.shape({
            auctionMaxFractionDigits: PropTypes.number,
            government: PropTypes.shape({
                salesTax: PropTypes.number,
            }).isRequired,
            id: PropTypes.number.isRequired,
            showBids: PropTypes.bool.isRequired,
            template: PropTypes.shape({
                isReverseAuction: PropTypes.bool.isRequired,
            }).isRequired,
        }).isRequired,
        router: PropTypes.object.isRequired,
        showProposalSelect: PropTypes.func.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    handleCompleteEvaluation = () => {
        this.props.showProposalSelect();
    };

    renderVendorLineItemAwardsTable = (vendorDataItem, i) => {
        const {
            project: {
                auctionMaxFractionDigits,
                government: { salesTax },
                template: { isReverseAuction },
            },
            omitLineItem,
        } = this.props;

        return (
            <React.Fragment key={i}>
                <h4 className={i === 0 ? '' : this.styles.vendorName}>
                    {vendorDataItem.vendorName}
                </h4>
                {vendorDataItem.priceTables.map((priceTable) => {
                    return (
                        <VendorLineItemAwardsTable
                            auctionMaxFractionDigits={
                                isReverseAuction ? auctionMaxFractionDigits : null
                            }
                            key={priceTable.id}
                            omitLineItem={omitLineItem}
                            priceTable={priceTable}
                            salesTax={salesTax}
                            vendorAwardData={vendorDataItem}
                        />
                    );
                })}
            </React.Fragment>
        );
    };

    renderSealedBids() {
        const { isEditor, project } = this.props;

        const title = project.showBids ? 'Bid Pricing is Sealed' : 'Bids are Sealed';
        const buttonText = (
            <span>
                <i className="fa fa-envelope-open" /> Unseal {project.showBids ? 'Pricing' : 'Bids'}
            </span>
        );

        return (
            <ZeroState
                buttonClickHandler={() => this.props.menuActionHandler(UNSEAL_BIDS, project)}
                buttonProps={{
                    disabled: !isEditor,
                    tooltip: isEditor
                        ? undefined
                        : 'Only evaluation editors and admins can unseal bids',
                }}
                buttonText={buttonText}
                info="Click the button above to unseal the pricing information and start bid tabulation"
                title={title}
            />
        );
    }

    renderZeroState() {
        const { vendorAwardData } = this.props;

        const message = vendorAwardData.length
            ? 'Vendor filter returned no results.'
            : 'No vendors awarded yet. Once you award vendors on the Line Item Awards tab, their awards will be shown here.';

        return <p style={{ textAlign: 'center', marginBottom: '20px' }}>{message}</p>;
    }

    getSelectedVendorAwards() {
        const {
            location: { query },
            vendorAwardData,
        } = this.props;

        if (!vendorAwardData || !query.proposal) {
            return vendorAwardData;
        }

        const selectedProposals = Array.isArray(query.proposal) ? query.proposal : [query.proposal];
        const parsedProposalIds = new Set(selectedProposals.map((id) => parseInt(id, 10)));

        return vendorAwardData.filter((vendorAward) =>
            parsedProposalIds.has(vendorAward.proposalId)
        );
    }

    updateVendorFilter(value) {
        const {
            location: { pathname },
            router,
        } = this.props;

        router.push({
            pathname,
            search: createSearchParams({
                proposal: !value || value.length === 0 ? undefined : value.map((v) => v.value),
            }).toString(),
        });
    }

    render() {
        const {
            error,
            isPricingSealed,
            isViewOnly,
            loading,
            location: { query },
            vendorAwardData,
        } = this.props;

        // NOTE: there is a loading edge case where if we come to this page via a route change then
        // it's possible that we render before the redux action has been completed digested by our
        // store. This puts us in a state where all expected store values are `undefined`, and can
        // cause rendering bugs where children of this component attempt to set state but have
        // unmounted due to this component re-rendering when the redux action finally catches up.
        if ((loading || !vendorAwardData) && !error) {
            return (
                <>
                    <SectionTitle title="Line Item Awards by Vendor" />
                    <LoadingSpinner />
                </>
            );
        }

        if (error) {
            return <LoadingError error={error} />;
        }

        if (isPricingSealed) {
            return this.renderSealedBids();
        }

        const filteredVendorAwardData = this.getSelectedVendorAwards();

        return (
            <>
                <SectionTitle title="Line Item Awards by Vendor" />
                <div className={this.styles.lineItemAwards}>
                    {filteredVendorAwardData.length ? (
                        <div className={this.styles.toolbar}>
                            <Select
                                className={classNames(
                                    'col-sm-6 col-md-4',
                                    this.styles.vendorFilter
                                )}
                                isMulti
                                isSearchable
                                onChange={(value) => this.updateVendorFilter(value)}
                                options={vendorAwardData.map((awardData) => ({
                                    label: awardData.vendorName,
                                    value: awardData.proposalId,
                                }))}
                                placeholder="Filter by Vendor"
                                styles={{
                                    placeholder: (baseStyles) => ({
                                        ...baseStyles,
                                        color: tokens.colors.colorGray700,
                                    }),
                                }}
                                value={
                                    !query.proposal
                                        ? null
                                        : filteredVendorAwardData.map((award) => ({
                                              label: award.vendorName,
                                              value: award.proposalId,
                                          }))
                                }
                            />
                            <ReportsModalButton type="evaluationTabulationReport" />
                        </div>
                    ) : (
                        this.renderZeroState()
                    )}
                    {this.getSelectedVendorAwards().map(this.renderVendorLineItemAwardsTable)}
                </div>
                {!isViewOnly && (
                    <div className={this.styles.completeEvaluationButtonWrapper}>
                        <Button
                            bsSize="lg"
                            bsStyle="success"
                            onClick={this.handleCompleteEvaluation}
                            qaTag="connectedVendorAwards-awardProject"
                        >
                            <i className="fa fa-trophy" /> Award Project
                        </Button>
                    </div>
                )}
            </>
        );
    }
}

export const VendorAwards = compose(
    connectData(fetchData),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedVendorAwards);

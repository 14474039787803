import { sortBy } from 'lodash';

import { reviewGroupPositionFieldNames } from './constants';

const { REVIEW_GROUP_POSITION_USERS } = reviewGroupPositionFieldNames;

// Replaces existing `ReviewGroupPositions` with positions from new sequence. Preserves the
// `ReviewGroupPosition` when same position is used in new sequence.
export const insertReviewGroupPositions = (
    existingReviewGroupPositionFormValues,
    reviewSequence
) => {
    const reviewGroupPositions = reviewSequence.positions.map((position) => {
        const existingPosition = existingReviewGroupPositionFormValues.find(
            (reviewGroupPosition) => reviewGroupPosition.position_id === position.id
        );
        // Re-use existing `ReviewGroupPosition`
        if (existingPosition) {
            return existingPosition;
        }
        // Seed new `ReviewGroupPosition`
        return {
            position,
            position_id: position.id,
            [REVIEW_GROUP_POSITION_USERS]: [],
        };
    });
    return sortBy(reviewGroupPositions, 'position.name');
};

import React from 'react';

import { Field } from 'redux-form';

import { ReduxFormTextField } from '@og-pro/ui';

import PropTypes from 'prop-types';

import { ENUM_OPTIONS_FIELD } from '@og-pro/shared-config/customFormService/customField';

export const CheckboxOptions = ({ disabled }) => {
    return (
        <Field
            component={ReduxFormTextField}
            disabled={disabled}
            label="Checkbox Text *"
            name={`${ENUM_OPTIONS_FIELD}[0]`}
        />
    );
};

CheckboxOptions.propTypes = {
    disabled: PropTypes.bool,
};

// Wraps the redux-thunk module with a request wrapper for client API requests
export default function thunkMiddleware(apiClientRequestWrapper) {
    return ({ dispatch, getState }) => {
        return (next) => {
            return (action) => {
                return typeof action === 'function'
                    ? action(dispatch, getState, apiClientRequestWrapper)
                    : next(action);
            };
        };
    };
}

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { categoryNames } from '@og-pro/shared-config/categories';

import { Tooltip } from '..';

export class CategoryCode extends PureComponent {
    static propTypes = {
        code: PropTypes.string.isRequired,
        setId: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
    };

    render() {
        const { code, setId, title } = this.props;

        const styles = require('./CategoryCodes.scss');
        const setName = categoryNames[setId];

        return (
            <Tooltip delayShow={200} placement="bottom" tooltip={`${setName}: ${title}`}>
                <span className={styles.code}>{code}</span>
            </Tooltip>
        );
    }
}

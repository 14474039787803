import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Modal } from 'react-bootstrap';

import { ConfirmationButton } from './components';
import { Button } from '..';

export class ConfirmationModal extends PureComponent {
    static propTypes = {
        body: PropTypes.node,
        buttonIcon: PropTypes.string,
        buttonStyle: PropTypes.string,
        buttonText: PropTypes.string,
        buttonUpdatingText: PropTypes.string,
        clickHandler: PropTypes.func,
        disabled: PropTypes.bool,
        error: PropTypes.string,
        hideButtons: PropTypes.bool,
        info: PropTypes.node,
        onHide: PropTypes.func.isRequired,
        secondaryButtonText: PropTypes.string,
        show: PropTypes.bool,
        tertiaryButton: PropTypes.node,
        title: PropTypes.string,
        updating: PropTypes.bool,
    };

    get styles() {
        return require('./ConfirmationModal.scss');
    }

    render() {
        const {
            body,
            buttonIcon,
            buttonStyle,
            buttonText,
            buttonUpdatingText,
            clickHandler,
            disabled,
            error,
            hideButtons,
            info,
            onHide,
            secondaryButtonText,
            show,
            tertiaryButton,
            title,
            updating,
        } = this.props;

        const { cancelBtn, errorMsg, infoMsg } = this.styles;

        if (!show) return null;

        return (
            <Modal onHide={onHide} show>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">
                        {title || 'Confirmation Required'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    {body}
                    {error && <div className={`text-danger ${errorMsg}`}>{error}</div>}
                    {hideButtons !== true && (
                        <div className={classnames(tertiaryButton && this.styles.buttonGroup)}>
                            {tertiaryButton && (
                                <div style={{ display: 'flex' }}>{tertiaryButton}</div>
                            )}
                            <div>
                                <Button bsSize="lg" className={cancelBtn} onClick={onHide}>
                                    {secondaryButtonText || 'Cancel'}
                                </Button>
                                <ConfirmationButton
                                    bsStyle={buttonStyle}
                                    disabled={disabled}
                                    icon={buttonIcon}
                                    onClick={clickHandler}
                                    text={buttonText}
                                    updating={updating}
                                    updatingText={buttonUpdatingText}
                                />
                            </div>
                        </div>
                    )}

                    {info && <div className={infoMsg}>{info}</div>}
                </Modal.Body>
            </Modal>
        );
    }
}

import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { isPoCreated } from '@og-pro/shared-config/requisitions';

import { ActionPanel } from './ActionPanel';
import { ExceptionBanner } from './ExceptionBanner';
import { PurchaseOrderPanel } from './PurchaseOrderPanel';
import { RejectionBanner } from './RejectionBanner';
import { RequisitionEditForm } from './RequisitionEditForm';
import { getRejectedStep } from '../selectors';
import { BudgetCheckAlertModal } from '../../BudgetCheckAlertModal';
import { BudgetCheckInfoBanner } from '../../BudgetCheckInfoBanner';
import { RequisitionApprovalModal } from '../../RequisitionApprovalModal';
import {
    getRequisitionApprovalModalData,
    getRequisitionBudgetCheckAlertModal,
} from '../../../../selectors/govApp/requisitions';

export const RequisitionsApprovalBody = ({ disabled, requisition, reviewSequenceSteps }) => {
    const { exceptionNote, exceptionReviewSequence, rejectionNote, status, wasExceptionRejected } =
        requisition;

    const styles = require('./index.scss');
    const hasPO = isPoCreated(status);
    const rejectedStep = useSelector(getRejectedStep);
    const { show: showBudgetCheckModal } = useSelector(getRequisitionBudgetCheckAlertModal);
    const { show: showApprovalModal } = useSelector(getRequisitionApprovalModalData);

    return (
        <>
            <ActionPanel
                disabled={disabled}
                requisition={requisition}
                reviewSequenceSteps={reviewSequenceSteps}
            />
            {hasPO && (
                <PurchaseOrderPanel
                    disabled={disabled}
                    requisition={requisition}
                    reviewSequenceSteps={reviewSequenceSteps}
                />
            )}
            <div className={styles.bodyContainer}>
                {rejectedStep && (
                    <RejectionBanner rejectedStep={rejectedStep} rejectionNote={rejectionNote} />
                )}
                {exceptionReviewSequence && !wasExceptionRejected && (
                    <ExceptionBanner
                        exceptionNote={exceptionNote}
                        exceptionReviewSequence={exceptionReviewSequence}
                    />
                )}
                <BudgetCheckInfoBanner />
                <RequisitionEditForm requisition={requisition} />
                {showBudgetCheckModal && <BudgetCheckAlertModal />}
                {showApprovalModal && <RequisitionApprovalModal />}
            </div>
        </>
    );
};

RequisitionsApprovalBody.propTypes = {
    disabled: PropTypes.bool,
    requisition: PropTypes.shape({
        exceptionNote: PropTypes.string,
        exceptionReviewSequence: PropTypes.object,
        rejectionNote: PropTypes.string,
        status: PropTypes.number.isRequired,
        wasExceptionRejected: PropTypes.bool,
    }).isRequired,
    reviewSequenceSteps: PropTypes.array.isRequired,
};

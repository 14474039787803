import { useQuery } from '@tanstack/react-query';

import { ogFinancialsClient } from './client';

export const OG_FINANCIALS_QUERY_PREFIX = 'OpenGovFinancials';

export const useOgFinancials = ({ queryKey, query, params, ...additionalOptions }) => {
    const queryOptions = {
        queryKey: [OG_FINANCIALS_QUERY_PREFIX, queryKey, query, params],
        queryFn: () => ogFinancialsClient.request(query, params),
        retry: false,
        refetchOnWindowFocus: false,
        ...additionalOptions,
    };
    return useQuery(queryOptions);
};

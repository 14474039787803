import { Box, IconButton, TextField, Typography, buildQaTag, componentNames } from '@og-pro/ui';
import {
    ArrowBackIosNew as ArrowBackIosNewIcon,
    ArrowForwardIos as ArrowForwardIosIcon,
} from '@mui/icons-material';
import React from 'react';
import PropTypes from 'prop-types';

export const ScanningPagination = ({ currentPage, pageCount, onPageChange }) => {
    const styles = require('./index.scss');

    const handlePrevious = () => {
        if (currentPage > 1) {
            onPageChange(currentPage - 1);
        }
    };
    const handlePageChange = (e) => {
        onPageChange(parseInt(e.target.value, 10) || 0);
    };

    const handleNext = () => {
        if (currentPage < pageCount) {
            onPageChange(currentPage + 1);
        }
    };

    return (
        <Box alignItems="center" className={styles.paginationBar} display="flex" gap={1}>
            <IconButton
                color="secondary"
                disabled={currentPage === 1 || !pageCount}
                onClick={handlePrevious}
                qaTag="scanningPagination-previousScan"
                size="small"
            >
                <ArrowBackIosNewIcon fontSize="inherit" />
            </IconButton>
            <Typography variant="small">Page</Typography>
            <TextField
                className={styles.currentPageInput}
                data-qa={buildQaTag('scanningPagination-currentPage', componentNames.INPUT_TEXT)}
                disabled={!pageCount}
                onChange={handlePageChange}
                size="small"
                value={currentPage}
            />
            <Typography variant="small">of {pageCount}</Typography>
            <IconButton
                color="secondary"
                disabled={currentPage === pageCount || !pageCount}
                onClick={handleNext}
                qaTag="scanningPagination-nextScan"
                size="small"
            >
                <ArrowForwardIosIcon fontSize="inherit" />
            </IconButton>
        </Box>
    );
};

ScanningPagination.propTypes = {
    currentPage: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getFormSyncErrors } from 'redux-form';
import { Link } from 'react-router-dom';

import { SDv2ErrorBanner } from '../../../../../../components/SDv2/ErrorBanner';
import { ERRORS } from '../../constants';
import { form } from '../../../forms/constants';
import { hasFormErrors } from '../../../../../../helpers';

const selector = getFormSyncErrors(form);

const getErrorsLinks = (errors, buildRouteFunction) => {
    const links = [];

    if (
        hasFormErrors(errors?.generalProperties) ||
        hasFormErrors(errors?.documentExportSettings) ||
        hasFormErrors(errors?.sealedBidSettings) ||
        hasFormErrors(errors?.timelines) ||
        hasFormErrors(errors?.timelineConfig)
    ) {
        links.push({
            name: 'Process Information',
            url: buildRouteFunction('process-information'),
            separator: '',
        });
    }

    if (hasFormErrors(errors?.projectSections)) {
        links.push({
            name: 'Document Builder',
            url: buildRouteFunction('document-builder'),
            separator: '',
        });
    }

    if (hasFormErrors(errors?.upfrontQuestions) || hasFormErrors(errors?.questionLogics)) {
        links.push({
            name: 'Questions & Automations',
            url: buildRouteFunction('questions-and-automations'),
            separator: '',
        });
    }

    return links.map((link, index) => {
        if (links.length === 1 || links.length === index + 1) {
            return link;
        }

        return {
            ...link,
            separator: links.length === index + 2 ? ' and ' : ', ',
        };
    });
};

export const TemplateEditV2ErrorBanner = ({
    attemptedSetLive,
    buildRouteFunction,
    showValidation,
}) => {
    const copyTemplateError = useSelector((state) => state.templatesAdmin.get('copyTemplateError'));
    const deleteError = useSelector((state) => state.templatesAdmin.get('deleteError'));
    const updateError = useSelector((state) => state.templatesAdmin.get('updateError'));
    const validationErrors = useSelector(selector);

    let title = null;
    let description = null;
    // eslint-disable-next-line
    const extraInformation = validationErrors?.projectSections?._error || null;

    const styles = require('./ErrorBanner.scss');

    if (copyTemplateError) {
        title = ERRORS.COPY.title;
        description = ERRORS.COPY.description;
    }

    if (deleteError) {
        title = ERRORS.DELETE.title;
        description = deleteError;
    }

    if (validationErrors?.allSections && updateError) {
        title = ERRORS.UPDATE.title;
        description = ERRORS.UPDATE.description;
    }

    if (validationErrors?.allSections && showValidation) {
        const links = getErrorsLinks(validationErrors, buildRouteFunction);

        if (attemptedSetLive) {
            title = ERRORS.LIVE.title;
            description = ERRORS.LIVE.description;
        } else {
            title = ERRORS.VALIDATION.title;
            description = ERRORS.VALIDATION.description;
        }

        if (links.length) {
            const parts = description.split('{{LINKS}}');
            description = (
                <>
                    {parts[0]}
                    {links.map((link, index) => (
                        <>
                            <Link key={index} to={link.url}>
                                {link.name}
                            </Link>
                            {link.separator}
                        </>
                    ))}
                    {parts[1]}
                </>
            );
        } else {
            description = description.replace('{{LINKS}}', 'the form below');
        }
    }

    if (!title && !description) {
        return null;
    }

    return (
        <SDv2ErrorBanner
            className={styles.container}
            description={description}
            extraInformation={extraInformation}
            title={title}
        />
    );
};

TemplateEditV2ErrorBanner.propTypes = {
    attemptedSetLive: PropTypes.bool,
    buildRouteFunction: PropTypes.func.isRequired,
    showValidation: PropTypes.bool,
};

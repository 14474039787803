import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { getSDv2WritingSections } from '../../../selectors';
import { ProjectCreateSectionMapper } from '../../../ProjectCreateSectionMapper';
import { ProjectCreateV2NavContext, ProjectCreateV2FunctionsContext } from '../../context';
import { ProjectCreateHelpModal } from '../../../ProjectCreateHelpModal';

export const ProjectCreateV2DocumentEditorSectionsAdapter = () => {
    const projectSections = useSelector(getSDv2WritingSections);
    const shouldShowHelpModal = useSelector((state) => state.projectCreate.get('showHelpModal'));
    const { activeSectionId } = useContext(ProjectCreateV2NavContext);
    const { location, params, initiateSneakyUpdate } = useContext(ProjectCreateV2FunctionsContext);

    const selectedSection = projectSections[activeSectionId];
    // TODO: move styles to another location
    const styles = require('../../components/shared.scss');

    if (!selectedSection || !selectedSection.id) {
        return null;
    }

    return (
        <div className={styles.container}>
            <ProjectCreateSectionMapper
                isOGThemeEnabledForComponents
                location={location}
                overrideSelectedProjectSectionId={selectedSection.id}
                params={params}
                sneakyUpdate={initiateSneakyUpdate}
            />
            {shouldShowHelpModal && (
                <ProjectCreateHelpModal overrideSelectedProjectSectionId={selectedSection.id} />
            )}
        </div>
    );
};

export function createElectronicSignature({ projectId }) {
    return (dispatch, getState, client) => {
        return client.post(`/project/${projectId}/electronic-signatures`);
    };
}

export function listElectronicSignatures({ projectId }) {
    return (dispatch, getState, client) => {
        return client.get(`/project/${projectId}/electronic-signatures`);
    };
}

export function getElectronicSignature({ projectId, electronicSignatureId }) {
    return (dispatch, getState, client) => {
        return client.get(`/project/${projectId}/electronic-signatures/${electronicSignatureId}`);
    };
}

export function cancelElectronicSignature({ projectId, electronicSignatureId }) {
    return (dispatch, getState, client) => {
        return client.del(`/project/${projectId}/electronic-signatures/${electronicSignatureId}`);
    };
}

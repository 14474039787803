import { categoryNames, categorySetsArray } from '@og-pro/shared-config/categories';

export const SET_ID = 'setId';

export const options = categorySetsArray.map((setId) => {
    return {
        label: categoryNames[setId],
        value: setId,
    };
});

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { compose } from 'redux';
import { change } from 'redux-form';

import { recordDocumentTypesDict } from '@og-pro/shared-config/recordsRetention';

import { getSections } from './selectors';
import { ProposalAddendumsConfirm } from './ProposalAddendumsConfirm';
import { ProposalContactInformation } from './ProposalContactInformation';
import { ProposalCreateSaveButton } from './ProposalCreateSaveButton';
import { ProposalDocumentsUpload } from './ProposalDocumentsUpload';
import { ProposalDropdownMenu } from './ProposalDropdownMenu';
import { ProposalQuestionnaire } from './ProposalQuestionnaire';
import { ProposalCompanyProfile } from './ProposalCompanyProfile';
import { ProposalPricing } from './ProposalPricing';
import { ProposalSubmit } from './ProposalSubmit';
import {
    ADDENDA,
    INFORMATION,
    QUESTIONNAIRE,
    PRICING,
    COMPANY_PROFILE,
    SUBMIT,
} from '../../ProposalCreateNav/constants';
import { SaveModal, SectionTitle } from '../..';
import { showVendorAccountModal } from '../../../actions/adminVendor';
import { toggleFullWidthView } from '../../../actions/vendProposals';
import { isInitialClientLoaded } from '../../../containers/selectors';

const { DOCUMENTS } = recordDocumentTypesDict;

const mapStateToProps = (state, props) => {
    return {
        isClientLoaded: isInitialClientLoaded(state),
        sections: getSections(props),
        useFullWidthView: state.vendProposals.get('useFullWidthView'),
    };
};

const mapDispatchToProps = {
    change,
    showVendorAccountModal,
    toggleFullWidthView,
};

// @withRouter
// @connect
class ConnectedProposalCreateForm extends Component {
    static propTypes = {
        allowSubmissionAfterDeadline: PropTypes.bool,
        allowSubmissionDateInput: PropTypes.bool,
        change: PropTypes.func.isRequired,
        confirmAddendum: PropTypes.func,
        deleteAttachment: PropTypes.func.isRequired,
        deleteProposal: PropTypes.func.isRequired,
        form: PropTypes.string.isRequired,
        isClientLoaded: PropTypes.bool.isRequired,
        location: PropTypes.shape({
            query: PropTypes.shape({
                section: PropTypes.string,
            }).isRequired,
        }).isRequired,
        noBidProposal: PropTypes.func.isRequired,
        onSave: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        project: PropTypes.object.isRequired,
        proposalDocuments: PropTypes.array.isRequired,
        proposalFormData: PropTypes.object.isRequired,
        sections: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
        showVendorAccountModal: PropTypes.func.isRequired,
        skipCompanyProfile: PropTypes.bool,
        skipQuestionnaireValidation: PropTypes.bool,
        title: PropTypes.string.isRequired,
        titleHelp: PropTypes.string,
        toggleFullWidthView: PropTypes.func.isRequired,
        updateError: PropTypes.string,
        updating: PropTypes.bool,
        uploadHandler: PropTypes.func.isRequired,
        uploading: PropTypes.bool.isRequired,
        useFullWidthView: PropTypes.bool.isRequired,
        vendor: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            showValidation: false,
        };
    }

    showFormValidationHandler = () => this.setState({ showValidation: true });

    get formProps() {
        const {
            form,
            proposalFormData,
            sections,
            skipCompanyProfile,
            updateError,
            updating,
            uploading,
        } = this.props;

        const { showValidation } = this.state;

        return {
            disabled: updating || uploading,
            form,
            onSave: this.onSave,
            proposalFormData,
            sections,
            showValidation,
            skipCompanyProfile,
            updateError,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    formChange = (args) => {
        const { form } = this.props;

        this.props.change(form, ...args);
    };

    onSave = () => {
        const { onSave } = this.props;

        return onSave().then((resultOrError) => {
            if (!(resultOrError instanceof Error)) {
                this.setState({ showValidation: false });
            }
        });
    };

    renderContactInformation() {
        const { allowSubmissionDateInput } = this.props;

        return (
            <div className="col-lg-offset-1 col-lg-10">
                <ProposalContactInformation
                    {...this.formProps}
                    allowSubmissionDateInput={allowSubmissionDateInput}
                />
            </div>
        );
    }

    renderDocumentsToUpload() {
        const { deleteAttachment, proposalDocuments, uploadHandler } = this.props;

        return (
            <div className="col-md-offset-1 col-md-10">
                <ProposalDocumentsUpload
                    {...this.formProps}
                    deleteAttachment={deleteAttachment}
                    proposalDocuments={proposalDocuments}
                    uploadHandler={uploadHandler}
                />
            </div>
        );
    }

    renderQuestionnaire() {
        const { skipQuestionnaireValidation } = this.props;

        return (
            <div className="col-md-11">
                <ProposalQuestionnaire
                    {...this.formProps}
                    skipQuestionnaireValidation={skipQuestionnaireValidation}
                />
            </div>
        );
    }

    renderPricing() {
        const {
            project: {
                auctionMaxFractionDigits,
                government: { salesTax },
                priceTables,
                template: { isReverseAuction },
            },
            useFullWidthView,
        } = this.props;

        const getSpecifyUnitPriceText = () => {
            const hasSpecifyUnitPrice = priceTables.some((table) => table.specifyUnitPrice);
            if (!isReverseAuction || !hasSpecifyUnitPrice) {
                return null;
            }
            return (
                <>
                    Tables with <i className="fa fa-exclamation-triangle" /> indicates that the
                    agency has already set the initial bid price.
                </>
            );
        };

        return (
            <div className="col-xs-12">
                <p className="text-center">
                    Please respond in the yellow cells below. {getSpecifyUnitPriceText()}
                </p>
                <ProposalPricing
                    auctionMaxFractionDigits={auctionMaxFractionDigits}
                    change={this.formChange}
                    isReverseAuction={isReverseAuction}
                    salesTax={salesTax}
                    toggleFullWidthView={this.props.toggleFullWidthView}
                    useFullWidthView={useFullWidthView}
                    {...this.formProps}
                />
            </div>
        );
    }

    renderAddendumConfirmation() {
        const { confirmAddendum } = this.props;

        return (
            <div className="col-xs-12">
                <ProposalAddendumsConfirm
                    {...this.formProps}
                    change={this.formChange}
                    confirmAddendum={confirmAddendum}
                />
            </div>
        );
    }

    renderCompanyProfile() {
        const { vendor } = this.props;

        return (
            <div className="col-md-offset-1 col-md-10">
                <ProposalCompanyProfile
                    {...this.formProps}
                    showVendorAccountModal={this.props.showVendorAccountModal}
                    vendor={vendor}
                />
            </div>
        );
    }

    renderSubmit() {
        const {
            allowSubmissionAfterDeadline,
            isClientLoaded,
            location,
            onSubmit,
            project,
            updating,
        } = this.props;

        return (
            <div className="col-md-offset-1 col-md-10">
                <ProposalSubmit
                    {...this.formProps}
                    allowSubmissionAfterDeadline={allowSubmissionAfterDeadline}
                    isClientLoaded={isClientLoaded}
                    location={location}
                    onSubmit={onSubmit}
                    project={project}
                    showFormValidationHandler={this.showFormValidationHandler}
                    updating={updating}
                />
            </div>
        );
    }

    renderSection() {
        switch (this.props.location.query.section) {
            case INFORMATION:
                return this.renderContactInformation();
            case DOCUMENTS:
                return this.renderDocumentsToUpload();
            case QUESTIONNAIRE:
                return this.renderQuestionnaire();
            case PRICING:
                return this.renderPricing();
            case ADDENDA:
                return this.renderAddendumConfirmation();
            case COMPANY_PROFILE:
                return this.renderCompanyProfile();
            case SUBMIT:
                return this.renderSubmit();
            default:
                return this.renderContactInformation();
        }
    }

    render() {
        const { deleteProposal, noBidProposal, title, titleHelp, updating, uploading } = this.props;

        const disabled = updating || uploading;

        return (
            <>
                <div className="text-right">
                    <ProposalDropdownMenu
                        deleteProposal={deleteProposal}
                        disabled={disabled}
                        noBidProposal={noBidProposal}
                        showFormValidationHandler={this.showFormValidationHandler}
                    />
                    &nbsp;&nbsp;
                    <ProposalCreateSaveButton
                        bsSize="sm"
                        bsStyle="primary"
                        disabled={disabled}
                        icon="check"
                        onClick={this.onSave}
                        text="Save"
                        tooltipPlacement="bottom"
                    />
                </div>
                <div className="clearfix" />
                <SectionTitle help={titleHelp} title={title} />
                <div className="row">{this.renderSection()}</div>
                <SaveModal />
            </>
        );
    }
}

export const ProposalCreateForm = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedProposalCreateForm);

import { upperCase } from 'lodash';
import moment from 'moment-timezone/builds/moment-timezone-with-data-1970-2030';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import { projectStatusesDict } from '@og-pro/shared-config/projects';

import { getProjectsWithLineItemContractInfoJS } from '../selectors';
import { loadLineItemContractInfo } from '../../../../actions/projects';
import {
    DataTable,
    FilteredPriceTables,
    StatusLabel,
    RouterLink,
    ZeroState,
} from '../../../../components';
import { statusClass } from '../../../../components/helpers/statusHelper';

const { AWARD_PENDING, CLOSED } = projectStatusesDict;

const PAGE_LIMIT = 10;

const mapStateToProps = (state) => ({
    isLoadingContractInfo: state.projects.get('loadingProjectLineItemContractInfo'),
    projectsWithLineItemContractInfo: getProjectsWithLineItemContractInfoJS(state),
});

const mapDispatchToProps = {
    loadLineItemContractInfo,
};

const ConnectedProjectsWithLineItemsList = ({
    government: {
        code: governmentCode,
        organization: { timezone },
    },
    isLoadingContractInfo,
    lineItemSearch,
    loadLineItemContractInfo: dispatchLoadLineItemContractInfo,
    projects,
    projectsWithLineItemContractInfo,
}) => {
    // Project IDs that might have line item award information
    const candidateProjectIds = useMemo(
        () =>
            new Set(
                projects.reduce((candidates, project) => {
                    const isCandidateForContractInfo =
                        [CLOSED, AWARD_PENDING].includes(project.status) && // Project status is closed or pending award
                        Array.isArray(project.priceTables) && // Project has at least one price table
                        project.priceTables.length > 0;

                    return isCandidateForContractInfo ? [...candidates, project.id] : candidates;
                }, [])
            ),
        [projects]
    );

    useEffect(() => {
        if (candidateProjectIds.size === 0) {
            return;
        }

        // Request line item contract information from the candidates so that we can determine
        // which projects have the desired information
        dispatchLoadLineItemContractInfo({ governmentCode, ids: Array.from(candidateProjectIds) });
    }, [candidateProjectIds]);

    const styles = require('./ProjectsWithLineItemsList.scss');

    if (projects.length === 0) {
        return <ZeroState info="Please try a different search query" title="No Projects Found" />;
    }

    const renderDateCell = (value) => {
        if (!value) {
            return '-';
        }
        return moment.tz(value, timezone).format('l');
    };

    const columns = [
        {
            Header: 'Project Title',
            accessor: 'title',
            minResizeWidth: 50,
            minWidth: 240,
            className: styles.rowWrapText,
            Cell: (cellProps) => {
                const {
                    id: projectId,
                    priceTables,
                    releaseProjectDate,
                    status,
                    title,
                } = cellProps.original;

                const lineItemContractInfo = projectsWithLineItemContractInfo.find(
                    (project) => project.id === projectId
                );

                return (
                    <>
                        <RouterLink
                            className={styles.projectLink}
                            qaTag="portal-projectWithLineItemPricing"
                            to={`/portal/${governmentCode}/projects/${projectId}`}
                        >
                            <div>
                                <span className={styles.title}>{title}</span>
                                <br />
                                <StatusLabel
                                    bsStyle={statusClass(status)}
                                    className={styles.statusLabel}
                                >
                                    {upperCase(status)}
                                </StatusLabel>
                            </div>
                        </RouterLink>
                        <span className={styles.releaseDate}>
                            Release Date: {renderDateCell(releaseProjectDate)}
                        </span>
                        <FilteredPriceTables
                            descriptionFilter={lineItemSearch}
                            displayAwardsColumn={candidateProjectIds.has(projectId)}
                            headerClassName={styles.priceTableHeader}
                            isLoadingContractInfo={isLoadingContractInfo}
                            isPublicView
                            lineItemContractInfo={lineItemContractInfo}
                            priceTables={priceTables}
                            projectId={projectId}
                        />
                    </>
                );
            },
        },
    ];

    const usePagination = projects.length > PAGE_LIMIT;

    return (
        <DataTable
            className="-striped -highlight"
            columns={columns}
            data={projects}
            defaultPageSize={usePagination ? PAGE_LIMIT : projects.length}
            showPagination={usePagination}
        />
    );
};

ConnectedProjectsWithLineItemsList.propTypes = {
    government: PropTypes.shape({
        code: PropTypes.string.isRequired,
        organization: PropTypes.shape({
            timezone: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    isLoadingContractInfo: PropTypes.bool.isRequired,
    lineItemSearch: PropTypes.string.isRequired,
    loadLineItemContractInfo: PropTypes.func.isRequired,
    projects: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            priceTables: PropTypes.array.isRequired,
            status: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
        })
    ).isRequired,
    projectsWithLineItemContractInfo: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
        })
    ).isRequired,
};

export const ProjectsWithLineItemsList = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedProjectsWithLineItemsList);

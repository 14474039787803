import { listToDict } from '../../../utils';

export const form = 'bulkUpdateContacts';

const bulkUpdateFields = [
    'projectsEnabled',
    'intakesEnabled',
    'contractsEnabled',
    'projectsProcurementContact',
    'projectsContact',
    'intakesProcurementContact',
    'intakesContact',
    'contractsProcurementContact',
    'contractsContact',
];

export const bulkUpdateFieldNames = listToDict(bulkUpdateFields);

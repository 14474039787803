const LOCATION_TYPES = {
    CITY: 'city',
    ZIP_CODE: 'zipCode',
    STATE: 'state',
};

const locationsToVendorIndexLocations = (locations) => {
    return locations.reduce((acc, location) => {
        if (location.type === LOCATION_TYPES.CITY) {
            acc.push({
                organization_city: location.name,
                organization_state: location.stateCode,
            });
        }

        if (location.type === LOCATION_TYPES.ZIP_CODE) {
            acc.push({ organization_zip_code: location.name });
        }

        if (location.type === LOCATION_TYPES.STATE) {
            acc.push({ organization_state: location.stateCode });
        }

        return acc;
    }, []);
};

/**
 * Builds the query to search for vendors in opensearch tailored for the
 * expanded supplier network logic.
 *
 * @param {object} categories Array of categories
 * @param {object[]} locations Array of locations to search for vendors as stored in the json_data
 * table; these are the locations the user selects in the frontend which are then passed through normalizeFindAddressCandidate
 * before saving.
 * @returns {object}
 */
const buildVendorSearchQuery = ({ categories, locations }) => {
    const vendorLocations = locationsToVendorIndexLocations(locations);

    const params = {
        query: {},
    };

    if (categories?.length) {
        params.query.categories_ids = categories.map((category) => category.id);
    }

    if (vendorLocations.length) {
        // the format to search by OR as per the search-service package
        params.query.or = vendorLocations;
    }

    return params;
};

module.exports = {
    LOCATION_TYPES,
    buildVendorSearchQuery,
};

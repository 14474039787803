import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { reduxForm } from 'redux-form';

import { form } from './constants';
import { AddendumsConfirmFormSection } from '../../..';

const formConfig = {
    form,
};

class ConnectedAddendumsConfirmModal extends Component {
    static propTypes = {
        change: PropTypes.func.isRequired,
        confirmAddendum: PropTypes.func.isRequired,
        hideModal: PropTypes.func.isRequired,
    };

    render() {
        const { change, confirmAddendum, hideModal } = this.props;

        return (
            <Modal bsSize="lg" onHide={hideModal} show>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">Addenda Acknowledgement</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddendumsConfirmFormSection
                        change={change}
                        confirmAddendum={confirmAddendum}
                        showValidation
                    />
                </Modal.Body>
            </Modal>
        );
    }
}

export const AddendumsConfirmModal = reduxForm(formConfig)(ConnectedAddendumsConfirmModal);

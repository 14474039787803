import { fieldNames, templateFieldNames } from './constants';
import {
    REQUIRED_TEXT,
    TEMPLATE_TITLE_REQUIRED,
    TEMPLATE_TITLE_TOO_LONG,
    TEMPLATE_ICON_REQUIRED,
    TEMPLATE_ACRONYM_REQUIRED,
    TEMPLATE_ACRONYM_TOO_LONG,
    TEMPLATE_USER_REQUIRED,
} from '../../../../../Forms/validation';

const { ALLOW_SUBSTITUTION_REQUESTS, TEMPLATE, USER_ID } = fieldNames;

const { ICON, PROCESS_ACRONYM, TITLE, SUBSTITUTION_REQUEST_FORM_URL } = templateFieldNames;

export function validate(values) {
    const template = values[TEMPLATE] || {};

    const errors = {};
    const templateErrors = {};

    // new v2 sections
    let generalPropertiesErrors = {};
    const documentExportSettingsErrors = {};
    const sealedBidSettingsErrors = {};

    if (!template[TITLE]) {
        templateErrors[TITLE] = TEMPLATE_TITLE_REQUIRED;
    } else if (template[TITLE].length > 128) {
        templateErrors[TITLE] = TEMPLATE_TITLE_TOO_LONG;
    }

    if (!template[ICON]) {
        templateErrors[ICON] = TEMPLATE_ICON_REQUIRED;
    }

    if (!template[PROCESS_ACRONYM]) {
        templateErrors[PROCESS_ACRONYM] = TEMPLATE_ACRONYM_REQUIRED;
    } else if (template[PROCESS_ACRONYM] && template[PROCESS_ACRONYM].length > 16) {
        templateErrors[PROCESS_ACRONYM] = TEMPLATE_ACRONYM_TOO_LONG;
    }

    if (!values[USER_ID]) {
        errors[USER_ID] = TEMPLATE_USER_REQUIRED;
    }

    if (values[ALLOW_SUBSTITUTION_REQUESTS] && !template[SUBSTITUTION_REQUEST_FORM_URL]) {
        templateErrors[SUBSTITUTION_REQUEST_FORM_URL] = REQUIRED_TEXT;
    }

    // assign to `templateErrors` for clarity below
    generalPropertiesErrors = templateErrors;

    return {
        ...errors,
        [TEMPLATE]: templateErrors,
        generalProperties: generalPropertiesErrors,
        documentExportSettings: documentExportSettingsErrors,
        sealedBidSettings: sealedBidSettingsErrors,
    };
}

import { get, pick } from 'lodash';

import { checklistSocketActionType } from './constants';

export const JOIN = `${checklistSocketActionType}/JOIN`;
export const LEAVE = `${checklistSocketActionType}/LEAVE`;

export function joinChecklist(checklistId) {
    return (dispatch, getState) => {
        const user = getState().auth.get('user').toJS();
        const userData = {
            ...pick(user, ['avatarUrl', 'displayName', 'email', 'initials', 'isVendor']),
            organizationId: get(user, 'organization.id'),
            organizationName: get(user, 'organization.name'),
        };
        return dispatch({ type: JOIN, checklistId, user: userData });
    };
}

export function leaveChecklist(checklistId) {
    return { type: LEAVE, checklistId };
}

export const CONNECTED_CLIENT_COUNT = 'checklistSocket/server/CONNECTED_CLIENT_COUNT';

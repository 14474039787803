import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { shouldShowComments } from '../../actions/govComments';
import { PageTitle, ProjectDetailNav as ProjectDetailNavComponent } from '../../components';
import { getSectionCommentCounts } from './selectors';
import {
    getReviewProjectJS as getGovernmentProjectJS,
    isDocumentEditable,
} from '../GovApp/selectors';
import { getProjectJS as getPublicProjectJS, isProjectOpen } from '../PublicApp/selectors';

export const ProjectDetailNav = ({
    isEditable = false,
    isGovernmentView = false,
    project = undefined,
    sectionCommentCounts = undefined,
    showComments = false,
    ...props
}) => {
    /**
     * The project will always be loaded on normal navigation, but on back
     * button nav the components get rendered before data is re-rendered
     * Possibly related:
     * https://github.com/ReactTraining/react-router/issues/5072
     */
    if (!project) return null;

    return (
        <>
            <PageTitle title="Project Detail" />
            <ProjectDetailNavComponent
                {...{
                    isEditable,
                    isGovernmentView,
                    project,
                    sectionCommentCounts,
                    showComments,
                    ...props,
                }}
                hasToolbar={isGovernmentView}
            />
        </>
    );
};

ProjectDetailNav.propTypes = {
    isEditable: PropTypes.bool,
    isGovernmentView: PropTypes.bool,
    project: PropTypes.shape({
        government_id: PropTypes.number.isRequired,
        id: PropTypes.number.isRequired,
        wasPosted: PropTypes.bool,
    }),
    sectionCommentCounts: PropTypes.object,
    showComments: PropTypes.bool,
};

// Government container
const ProjectDetailNavGovernment = (props) => {
    const isEditable = useSelector(isDocumentEditable);
    const project = useSelector(getGovernmentProjectJS);
    const showComments = useSelector(shouldShowComments);
    const sectionCommentCounts = useSelector(getSectionCommentCounts);

    return (
        <ProjectDetailNav
            isGovernmentView
            {...props}
            {...{ isEditable, project, showComments, sectionCommentCounts }}
        />
    );
};
ProjectDetailNav.Government = ProjectDetailNavGovernment;

// Public container
const ProjectDetailNavPublic = (props) => {
    const params = useParams();
    const isAppliable = useSelector((state) => (params.vendorId ? false : isProjectOpen(state)));
    const project = useSelector(getPublicProjectJS);

    return <ProjectDetailNav {...props} isAppliable={isAppliable} project={project} />;
};
ProjectDetailNav.Public = ProjectDetailNavPublic;

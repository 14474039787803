import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '../../../../components';

export const GroupPositionsTableRow = ({
    onDelete,
    onEdit,
    position,
    position: { description, id, name },
}) => {
    const styles = require('../shared.scss');

    return (
        <tr key={id}>
            <td className={styles.settingsTableTruncatedCol}>{name}</td>
            <td className={styles.settingsTableTruncatedCol}>{description}</td>
            <td className={styles.settingsTableCol}>
                <Button
                    bsSize="sm"
                    bsStyle="link"
                    onClick={() => onEdit(position)}
                    qaTag="groupPositionAdmin-edit"
                    zeroPadding
                >
                    <i className="fa fa-pencil" /> Edit
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                    bsSize="sm"
                    bsStyle="link"
                    onClick={() => onDelete(id)}
                    qaTag="groupPositionAdmin-delete"
                    zeroPadding
                >
                    <i className="text-danger fa fa-trash fa-lg" />
                </Button>
            </td>
        </tr>
    );
};

GroupPositionsTableRow.propTypes = {
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    position: PropTypes.shape({
        description: PropTypes.string,
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    }).isRequired,
};

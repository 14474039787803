import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { projectToolbarTypes } from './constants';
import { BuilderToolbar, ContractToolbar, EvaluationToolbar, SourcingToolbar } from './Toolbars';

const { BUILDER, CONTRACT, INTAKE, SOURCING, EVALUATION } = projectToolbarTypes;

export class ProjectToolbarSwitch extends PureComponent {
    static propTypes = {
        toolbarType: PropTypes.string.isRequired,
    };

    render() {
        const { toolbarType, ...props } = this.props;
        switch (toolbarType) {
            case BUILDER:
                return <BuilderToolbar {...props} />;
            case CONTRACT:
                return <ContractToolbar {...props} />;
            case INTAKE:
                return <BuilderToolbar {...props} isIntake />;
            case SOURCING:
                return <SourcingToolbar {...props} />;
            case EVALUATION:
                return <EvaluationToolbar {...props} />;
            default:
                return null;
        }
    }
}

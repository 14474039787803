import qs from 'qs';
import superagent from 'superagent';

const SUGGEST_URL = 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest';
const FIND_ADDRESS_CANDIDATES_URL =
    'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates';
const TOKEN = process.env.ESRI_ARCGIS_API_TOKEN;

export default {
    // https://developers.arcgis.com/rest/geocode/api-reference/geocoding-suggest.htm
    suggest: async (params) => {
        if (!params.text) {
            throw new Error('"text" is required to use the suggest method.');
        }

        const querystring = qs.stringify({
            maxSuggestions: 5,
            countryCode: 'USA,CAN',
            category: 'City,Region,Postal',
            returnCollections: false,
            ...params,
            token: TOKEN,
            f: 'json',
        });

        const response = await superagent.get(`${SUGGEST_URL}?${querystring}`);

        return response.body;
    },
    // https://developers.arcgis.com/rest/geocode/api-reference/geocoding-find-address-candidates.htm
    findAddressCandidates: async (params) => {
        const querystring = qs.stringify({
            // We only want one result since supposedly we ran suggest first and we know what we look for
            // can be overwritten
            maxLocations: 1,
            langCode: 'EN',
            countryCode: 'USA,CAN',
            category: 'City,Region,Postal',
            outFields: 'City,LongLabel,ShortLabel,Type,Region,RegionAbbr,Country',
            ...params,
            token: TOKEN,
            f: 'json',
        });

        const response = await superagent.get(`${FIND_ADDRESS_CANDIDATES_URL}?${querystring}`);

        return response.body;
    },
};

const { listToDict } = require('../helpers');

const statuses = listToDict(['pending', 'processing', 'completed', 'errored', 'cancelled']);
const providers = listToDict(['docusign']);

const getAnchorString = (key) => `**signature_${key}**`;

module.exports = {
    getAnchorString,
    providers,
    statuses,
};

/**
 * Gets Intl.NumberFormat settings based on maximumFractionDigits.
 * If maximumFractionDigits = 0, then minimumFractionDigits = 0 is needed to prevent error
 * https://github.com/andyearnshaw/Intl.js/issues/123#issuecomment-679155817
 *
 * @param {number} maximumFractionDigits max number of decimal places
 * @param {boolean} useSameMinAndMax whether to use the same value for minimumFractionDigits. Will pad with 0s if true.
 * @returns {object} Intl.NumberFormat instance
 */
const getFractionNumberIntlFormatter = (maximumFractionDigits = 2, useSameMinAndMax = false) => {
    let minimumFractionDigits;
    if (useSameMinAndMax) {
        minimumFractionDigits = maximumFractionDigits;
    } else {
        minimumFractionDigits = maximumFractionDigits === 0 ? 0 : 2;
    }
    return new Intl.NumberFormat('en-US', {
        currency: 'USD',
        maximumFractionDigits,
        minimumFractionDigits,
        style: 'currency',
    });
};

/**
 * Format a value as currency.
 *
 * @param {object} data The data object to use to process the formatting
 * @param {number} data.value The number to format as currency
 * @param {object} [options={}] The options object to use to process the
 * @param {number} options.maximumFractionDigits Maximum number of fraction digits to use
 * @param {boolean} options.use4FractionDigits Use maximum 4 fraction digits
 * @param {boolean} options.use0FractionDigits Use 0 fraction digits
 * @param {boolean} options.useSameMinAndMax Use the same value for both minimumFractionDigits and maximumFractionDigits. Will pad with 0s if true.
 * @return {any|number} The original value if `options.value` is not a number, the formatted
 *                        currency string if it is.
 */
const currencyFormatter = (data, options = {}) => {
    if (!Number.isNaN(Number.parseFloat(data.value))) {
        const { maximumFractionDigits, use4FractionDigits, use0FractionDigits, useSameMinAndMax } =
            options;

        if (maximumFractionDigits) {
            return getFractionNumberIntlFormatter(maximumFractionDigits, useSameMinAndMax).format(
                data.value
            );
        }

        if (use4FractionDigits) {
            return getFractionNumberIntlFormatter(4, useSameMinAndMax).format(data.value);
        }

        if (use0FractionDigits) {
            return getFractionNumberIntlFormatter(0, useSameMinAndMax).format(data.value);
        }

        return getFractionNumberIntlFormatter().format(data.value);
    }

    return data.value;
};

export { currencyFormatter };

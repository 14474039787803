import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { Main, PageTitle } from '..';

export const NotFound = () => {
    const [query] = useSearchParams();
    const message = query.get('message');

    const styles = require('./index.scss');

    return (
        <Main className={styles.notFound}>
            <PageTitle title="Page Not Found" />
            <div className={styles.overlay} />
            <h1>404</h1>
            <br />
            <h3>Sorry, we could not find this page</h3>
            {message && <div className={styles.message}>{message}</div>}
        </Main>
    );
};

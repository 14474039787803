import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Modal, Button } from 'react-bootstrap';

import { summaryExamples, titles } from './constants';
import { InputBasic } from '../../../../../components';

export class IntroSummaryHelp extends PureComponent {
    static propTypes = {
        hide: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = { currentPage: 0 };
    }

    get styles() {
        return require('./index.scss');
    }

    changePage = (changeAmount) => {
        this.setState((prevState) => ({ currentPage: prevState.currentPage + changeAmount }));
    };

    decrement = () => this.changePage(-1);

    increment = () => this.changePage(1);

    renderExamples() {
        return (
            <div>
                <p>
                    The following are a list of example summaries for a project. When you&#39;re
                    done reading the examples, click Finish to start writing your own.
                </p>
                {summaryExamples.map((example, index) => (
                    <React.Fragment key={index}>
                        <p className={this.styles.exampleHeading}>{example.title}</p>
                        <InputBasic
                            className={this.styles.exampleInput}
                            disabled
                            type="textarea"
                            value={example.description}
                        />
                    </React.Fragment>
                ))}
            </div>
        );
    }

    renderFirstGuide() {
        return (
            <div>
                <p>
                    The summary should be 1-3 paragraphs. Its purpose is to give the vendor a brief
                    idea of what the project aims to accomplish and to spark interest in potential
                    quality vendors.
                </p>
                <p>This high­ level description should briefly answer questions such as these:</p>
                <ul>
                    <li>
                        What is the problem/innovation/upgrade, etc. that this project will address?
                    </li>
                    <br />
                    <li>What is the solution you envision?</li>
                    <br />
                    <li>What will be the benefit from implementing this solution?</li>
                    <br />
                </ul>
            </div>
        );
    }

    renderContent() {
        const { currentPage } = this.state;

        switch (currentPage) {
            case 0:
                return this.renderFirstGuide();
            case 1:
                return this.renderExamples();
            default:
                return this.renderExamples();
        }
    }

    renderNextButton() {
        const { hide } = this.props;
        const { currentPage } = this.state;

        if (currentPage === 1) {
            return (
                <Button bsStyle="success" onClick={hide}>
                    Finish
                </Button>
            );
        }

        return (
            <Button bsStyle="success" onClick={this.increment}>
                Next&nbsp;
                <i className="fa fa-angle-double-right" />
            </Button>
        );
    }

    render() {
        const { hide } = this.props;
        const { currentPage } = this.state;

        const title = titles[currentPage];

        return (
            <>
                <Modal.Header closeButton>
                    <Modal.Title className={this.styles.modalTitle} id="contained-modal-title-sm">
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={this.styles.modalBody}>{this.renderContent()}</Modal.Body>
                <Modal.Footer>
                    <div className="text-center">
                        {currentPage > 0 && (
                            <Button onClick={this.decrement}>
                                <i className="fa fa-angle-double-left" />
                                &nbsp;Back
                            </Button>
                        )}
                        {this.renderNextButton()}
                    </div>
                    <div className="skipInstructions">
                        <a href="#" onClick={hide}>
                            Skip Instructions
                        </a>
                    </div>
                </Modal.Footer>
            </>
        );
    }
}

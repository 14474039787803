import { browserHistory } from '@og-pro-migration-tools/react-router';

import { unauthorizedError } from '../actions/error';
import { defaultError } from '../constants';

export default function errorHandlerMiddleware({ dispatch }) {
    return (next) => (action) => {
        const errorApiStatus = action && action.error && action.error.status;
        if (!errorApiStatus) {
            return next(action);
        }

        switch (errorApiStatus) {
            case 401: {
                const url = browserHistory.location()?.pathname;

                if (process.env.SERVER) {
                    return next(action);
                }

                // If the unauthorized response came from the login page do not
                // store a next route (else will be stuck on login)
                if (url === '/login') {
                    return next(action);
                }

                dispatch(unauthorizedError(url));
                return next(action);
            }
            default:
                // Add a default error message in case the API server did not return an error message.
                // Should not happen, but could be possible when the server times out or has an
                // unhandled exception.
                if (!action.error.message) {
                    action.error.message = defaultError;
                }
                return next(action);
        }
    };
}

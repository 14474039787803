import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box } from '@og-pro/ui';

import { getSubscribersToNotify } from '../../../../actions/govProjects';
import { CustomGridLayout } from './CustomGridLayout';
import { SkeletonLoading } from './SkeletonLoading';
import { FormError } from '../../../../components';

export const AutomaticallyAddedRow = ({ notifiedUsers, setTotal }) => {
    const [{ loading, error, data }, setState] = useState({
        loading: true,
        error: null,
        data: null,
    });
    const dispatch = useDispatch();
    const params = useParams();
    const styles = require('../index.scss');

    useEffect(() => {
        // on load, get the subscribers to notify
        // this returns an object { userIds: [], organizationIds: [] }
        dispatch(getSubscribersToNotify(params.projectId))
            .then(({ userIds, organizationIds }) => {
                const filteredUserIds = userIds.filter((id) => !notifiedUsers.ids.has(id));
                const filteredOrganizationIds = organizationIds.filter(
                    (id) => !notifiedUsers.organizationIds.has(id)
                );

                // set the totals and the data to display in the table correctly
                setTotal(filteredOrganizationIds.length);
                setState({
                    error: null,
                    loading: false,
                    data: {
                        userIds: filteredUserIds,
                        organizationIds: filteredOrganizationIds,
                    },
                });
            })
            .catch((err) => {
                setState({ error: err, loading: false, data: null });
            });
    }, [dispatch]);

    if (loading) {
        return <SkeletonLoading />;
    }

    return (
        <CustomGridLayout
            columns={[
                <>
                    {' '}
                    <Box className={styles.vendorSearchTypeLabel}>Automatically Added</Box>
                    <Box className={classnames(styles.tableHeaderText, styles.fontWeightRegular)}>
                        Subscribed to you with these category codes
                    </Box>
                    {error && (
                        <FormError error="Failed to retrieve the information" useOpenGovStyle />
                    )}
                </>,
                <Box className={styles.tableCount}>
                    {error ? 'N/A' : data?.userIds?.length || 0}
                </Box>,
                <Box className={styles.tableCount}>
                    {error ? 'N/A' : data?.organizationIds?.length || 0}
                </Box>,
                <Box>&nbsp;</Box>,
            ]}
        />
    );
};

AutomaticallyAddedRow.propTypes = {
    notifiedUsers: PropTypes.shape({ ids: PropTypes.object, organizationIds: PropTypes.object }), // javascript Set
    setTotal: PropTypes.func.isRequired,
};

import { createSelector } from 'reselect';

import { tagTypesDict } from '@og-pro/shared-config/tags';

import { tagTypeNavItems } from './constants';
import { hasContractingSubscription, hasRequisitionSubscription } from '../../GovApp/selectors';
import {
    getContactTagsJS,
    getContractTagsJS,
    getContractAttachmentTagsJS,
    getContractInsuranceTagsJS,
    getFiscalYearTagsJS,
    getFundingSourceTagsJS,
    getProcurementClassificationTagsJS,
} from '../../selectors';
import { getDeliveryCodeTagsJS, getPaymentTermsTagsJS } from '../../App/selectors';

const {
    CONTACT,
    CONTRACT,
    CONTRACT_ATTACHMENT,
    CONTRACT_INSURANCE,
    DELIVERY_CODE,
    FISCAL_YEAR,
    FUNDING_SOURCE,
    PROCUREMENT_CLASSIFICATION,
    PAYMENT_TERMS,
} = tagTypesDict;

export const getNavItems = createSelector(
    [hasContractingSubscription, hasRequisitionSubscription],
    (hasContracting, hasRequisition) => {
        return tagTypeNavItems(hasContracting, hasRequisition);
    }
);

export const getSelectedTagTypes = createSelector(
    [
        (state, props) => props.selectedNavItem,
        getContactTagsJS,
        getContractTagsJS,
        getContractAttachmentTagsJS,
        getContractInsuranceTagsJS,
        getDeliveryCodeTagsJS,
        getFiscalYearTagsJS,
        getFundingSourceTagsJS,
        getProcurementClassificationTagsJS,
        getPaymentTermsTagsJS,
    ],
    (
        selectedNavItem,
        contactTags,
        contractTags,
        contractAttachmentTags,
        contractInsuranceTags,
        deliveryCodeTags,
        fiscalYearTags,
        fundingSourceTags,
        procurementClassificationTags,
        paymentTermsTags
    ) => {
        switch (selectedNavItem && selectedNavItem.type) {
            case CONTACT:
                return contactTags;
            case CONTRACT:
                return contractTags;
            case CONTRACT_ATTACHMENT:
                return contractAttachmentTags;
            case CONTRACT_INSURANCE:
                return contractInsuranceTags;
            case DELIVERY_CODE:
                return deliveryCodeTags;
            case FISCAL_YEAR:
                return fiscalYearTags;
            case FUNDING_SOURCE:
                return fundingSourceTags;
            case PROCUREMENT_CLASSIFICATION:
                return procurementClassificationTags;
            case PAYMENT_TERMS:
                return paymentTermsTags;
            default:
                return [];
        }
    }
);

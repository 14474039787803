import { REQUIRED_TEXT, REQUIRED_DEPARTMENT_TEXT } from './constants';

export function department(values) {
    const errors = {};
    if (!values.department_id) {
        errors.department_id = REQUIRED_TEXT;
    }

    if (!values.departmentName) {
        errors.departmentName = REQUIRED_TEXT;
        errors.department_id = REQUIRED_DEPARTMENT_TEXT;
    } else if (values.departmentName.length > 128) {
        errors.departmentName = 'Field is too long (128 chars max)';
        errors.department_id = REQUIRED_DEPARTMENT_TEXT;
    }

    if (!values.departmentHead) {
        errors.departmentHead = REQUIRED_TEXT;
        errors.department_id = REQUIRED_DEPARTMENT_TEXT;
    } else if (values.departmentHead.length > 128) {
        errors.departmentHead = 'Field is too long (128 chars max)';
        errors.department_id = REQUIRED_DEPARTMENT_TEXT;
    }

    if (!values.departmentHeadTitle) {
        errors.departmentHeadTitle = REQUIRED_TEXT;
        errors.department_id = REQUIRED_DEPARTMENT_TEXT;
    } else if (values.departmentHeadTitle.length > 128) {
        errors.departmentHeadTitle = 'Field is too long (128 chars max)';
        errors.department_id = REQUIRED_DEPARTMENT_TEXT;
    }

    return errors;
}

import { orderBy } from 'lodash';
import {
    Table as MuiTable,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
} from '@og-pro/ui';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import { columns, defaultSortConfig } from './constants';
import { ReviewSequencesListItem } from './ReviewSequencesListItem';
import { ZeroState } from '../../../../components';

const { sortBy: defaultSortBy, sortDirection: defaultSortDirection } = defaultSortConfig;

export const ReviewSequencesList = ({
    hasNoCreatedSequences,
    ReviewSequenceCreateButton,
    reviewSequences: originalReviewSequences,
    routingHandler,
}) => {
    const [sortBy, setSortBy] = useState(defaultSortBy);
    const [sortDirection, setSortDirection] = useState(defaultSortDirection);

    const reviewSequences = useMemo(() => {
        return orderBy(
            originalReviewSequences,
            [sortBy, defaultSortBy],
            [sortDirection, defaultSortDirection]
        );
    }, [sortBy, sortDirection, originalReviewSequences]);

    if (reviewSequences.length === 0) {
        const props = {
            button: hasNoCreatedSequences ? <ReviewSequenceCreateButton size="lg" /> : undefined,
            title: hasNoCreatedSequences ? 'No sequences created yet' : 'No sequences here!',
        };
        return <ZeroState {...props} useOpenGovStyle />;
    }

    const sortHandler = (property, initialSortDirection) => {
        const isAlreadySortedByProperty = sortBy === property;
        const shouldSortDesc = isAlreadySortedByProperty
            ? sortDirection === 'asc'
            : initialSortDirection === 'desc';

        setSortBy(property);
        setSortDirection(shouldSortDesc ? 'desc' : 'asc');
    };

    const columnStyles = {
        fontWeight: capitalDesignTokens.foundations.typography.fontWeight.medium,
        fontSize: capitalDesignTokens.foundations.typography.fontSize.bodyDefault,
    };

    return (
        <TableContainer
            sx={{
                overflowX: 'auto',
                border: `1px solid ${capitalDesignTokens.semanticColors.border.primary}`,
                borderBottom: 0,
            }}
        >
            <MuiTable aria-label="Review Sequence List">
                <TableHead>
                    <TableRow>
                        {Object.keys(columns).map((col) => {
                            const {
                                headProps,
                                sortKey,
                                initialSortDirection = defaultSortDirection,
                                label,
                            } = columns[col];

                            const isActive = sortBy === sortKey;
                            return (
                                <TableCell key={col} {...headProps}>
                                    <TableSortLabel
                                        active={isActive}
                                        direction={isActive ? sortDirection : initialSortDirection}
                                        onClick={() => sortHandler(sortKey, initialSortDirection)}
                                        sx={columnStyles}
                                    >
                                        {label}
                                    </TableSortLabel>
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {reviewSequences.map((reviewSequence) => {
                        return (
                            <ReviewSequencesListItem
                                key={reviewSequence.id}
                                onClick={() => routingHandler(reviewSequence.id)}
                                reviewSequence={reviewSequence}
                            />
                        );
                    })}
                </TableBody>
            </MuiTable>
        </TableContainer>
    );
};

ReviewSequencesList.propTypes = {
    hasNoCreatedSequences: PropTypes.bool,
    ReviewSequenceCreateButton: PropTypes.func.isRequired,
    reviewSequences: PropTypes.array.isRequired,
    routingHandler: PropTypes.func.isRequired,
};

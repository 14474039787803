import { fromJS } from 'immutable';

import * as addendumActions from '../actions/addendums';

const initialState = fromJS({
    addendums: [],
    createError: null,
    creating: false,
    loadedList: false,
    loadingList: false,
    loadListError: null,
    loadingProposals: false,
    proposals: [],
});

function addendumReducer(state, action) {
    // We only care about the addendum that matches the addendumId
    if (state.get('id') !== action.addendumId) {
        return state;
    }

    switch (action.type) {
        case addendumActions.DELETE_ADDENDUM:
            return state.merge(
                fromJS({
                    deleting: true,
                    deleteError: null,
                })
            );
        case addendumActions.DELETE_ADDENDUM_FAIL:
            return state.merge(
                fromJS({
                    deleting: false,
                    deleteError: action.error.message,
                })
            );
        case addendumActions.UPDATE_ADDENDUM:
            return state.merge(
                fromJS({
                    updating: true,
                    updateError: null,
                })
            );
        case addendumActions.UPDATE_ADDENDUM_SUCCESS:
            return fromJS(action.result);
        case addendumActions.UPDATE_ADDENDUM_FAIL:
            return state.merge(
                fromJS({
                    updating: false,
                    updateError: action.error.message,
                })
            );
        default:
            return state;
    }
}

export default function addendumsReducer(state = initialState, action = {}) {
    switch (action.type) {
        case addendumActions.CREATE_ADDENDUM:
            return state.merge(
                fromJS({
                    createError: null,
                    creating: true,
                })
            );
        case addendumActions.CREATE_ADDENDUM_SUCCESS:
            return state.merge(
                fromJS({
                    addendums: state.get('addendums').push(fromJS(action.result)),
                    creating: false,
                })
            );
        case addendumActions.CREATE_ADDENDUM_FAIL:
            return state.merge(
                fromJS({
                    createError: action.error && action.error.message,
                    creating: false,
                })
            );
        case addendumActions.LOAD_ADDENDUM_PROPOSALS:
            return state.merge(
                fromJS({
                    loadingProposals: true,
                })
            );
        case addendumActions.LOAD_ADDENDUM_PROPOSALS_SUCCESS:
            return state.merge(
                fromJS({
                    loadingProposals: false,
                    proposals: fromJS(action.result),
                })
            );
        case addendumActions.LOAD_ADDENDUMS:
            return state.merge(
                fromJS({
                    loadedList: false,
                    loadingList: true,
                    loadListError: null,
                })
            );
        case addendumActions.LOAD_ADDENDUMS_SUCCESS:
            return state.merge(
                fromJS({
                    loadedList: true,
                    loadingList: false,
                    addendums: fromJS(action.result),
                })
            );
        case addendumActions.LOAD_ADDENDUMS_FAIL:
            return state.merge(
                fromJS({
                    loadedList: false,
                    loadingList: false,
                    loadListError: action.error && action.error.message,
                })
            );
        case addendumActions.DELETE_ADDENDUM:
        case addendumActions.DELETE_ADDENDUM_FAIL:
        case addendumActions.UPDATE_ADDENDUM:
        case addendumActions.UPDATE_ADDENDUM_SUCCESS:
        case addendumActions.UPDATE_ADDENDUM_FAIL:
            return state.set(
                'addendums',
                state.get('addendums').map((addendum) => {
                    return addendumReducer(addendum, action);
                })
            );
        case addendumActions.DELETE_ADDENDUM_SUCCESS:
            return state.set(
                'addendums',
                state.get('addendums').filter((addendum) => {
                    return addendum.get('id') !== action.addendumId;
                })
            );
        default:
            return state;
    }
}

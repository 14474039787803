import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Button } from '../../../../../components';

export class AddCriteriaButton extends PureComponent {
    static propTypes = {
        bsSize: PropTypes.string,
        className: PropTypes.string,
        disabled: PropTypes.bool,
        onClick: PropTypes.func.isRequired,
        text: PropTypes.string.isRequired,
    };

    static defaultProps = {
        className: '',
    };

    get styles() {
        return require('./index.scss');
    }

    // NOTE: Must call `onClick` with no argument or else the event object will be passed as data
    clickHandler = () => this.props.onClick();

    render() {
        const { bsSize, className, disabled, text } = this.props;

        return (
            <Button
                bsSize={bsSize}
                className={`${this.styles.criteriaBtn} ${className}`}
                disabled={disabled}
                onClick={this.clickHandler}
            >
                <i className="fa fa-plus" /> {text}
            </Button>
        );
    }
}

import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { projectScopeFilterTypesDict } from '@og-pro/shared-config/projects';

import { getProjectSelectOptions } from './selectors';
import { SearchSelect } from '..';
import { loadProjects } from '../../actions/projects';

export const ProjectSearchInput = (props) => {
    const { searchData, selectedProject, ...propsForSearchSelect } = props;

    const dispatch = useDispatch();
    const loading = useSelector((state) => !!state.projects.get('loadingProjects'));
    const projectSelectOptions = useSelector((state) => getProjectSelectOptions(state, props));

    const searchProjects = (data) =>
        dispatch(
            loadProjects({
                quickSearchQuery: data,
                scope: projectScopeFilterTypesDict.ALL_PROJECTS,
                ...searchData,
            })
        );

    const debouncedSearchProjects = debounce(searchProjects, 500);

    useEffect(() => {
        searchProjects();
    }, []);

    const handleProjectSearchInputChange = (data, { action }) => {
        // Only load projects when input is changed (skips blur and selection changes)
        if (action === 'input-change') {
            debouncedSearchProjects(data);
        }

        return data;
    };

    const loadProjectsOnClear = (data) => {
        // Data will be null when form has been cleared
        if (data === null) {
            debouncedSearchProjects(data);
        }
    };

    return (
        <SearchSelect
            isClearable
            isLoading={loading}
            noOptionsMessage={() => (loading ? 'Loading...' : 'No results found')}
            onChangeWithOption={loadProjectsOnClear}
            onInputChange={handleProjectSearchInputChange}
            options={loading ? [] : projectSelectOptions}
            overrideFeedback
            placeholder="Type to search projects by title..."
            {...propsForSearchSelect}
        />
    );
};

ProjectSearchInput.propTypes = {
    meta: PropTypes.shape({
        pristine: PropTypes.bool.isRequired,
    }).isRequired, // Used by `getProjectSelectOptions` selector
    searchData: PropTypes.object,
    selectedProject: PropTypes.object,
};

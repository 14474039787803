import { round } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { HtmlContent } from '../../../../../components';

export class ScoringCriteriaFormDescription extends PureComponent {
    static propTypes = {
        scoringCriterium: PropTypes.shape({
            description: PropTypes.string,
            scoringMethodText: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            weight: PropTypes.number.isRequired,
        }).isRequired,
        totalWeight: PropTypes.number.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const {
            scoringCriterium: { description, scoringMethodText, title, weight },
            totalWeight,
        } = this.props;

        const weightPercentageOfTotal = `${round((weight / totalWeight) * 100, 1)}%`;

        return (
            <>
                <div>
                    <strong>{title}</strong>
                    <br />
                </div>
                <small>
                    <em>{scoringMethodText}</em>
                    &nbsp;|&nbsp;
                    <em>
                        {weight} Points ({weightPercentageOfTotal} of total)
                    </em>
                </small>
                {description && (
                    <HtmlContent
                        className={this.styles.descriptionContainer}
                        content={description}
                    />
                )}
            </>
        );
    }
}

import PropTypes from 'prop-types';

import { getNavItems, hasQuestionnaire } from './selectors';
import {
    getEvaluationProjectJS,
    isEvaluationEditor,
    isEvaluationOwner,
    isSubscribed,
} from '../selectors';
import { getUserOrganizationTimezone } from '../../selectors';
import { menuActionHandler } from '../../../actions/govProjects';

export function mapStateToProps(state, props) {
    return {
        hasQuestionnaire: hasQuestionnaire(state),
        isEditor: isEvaluationEditor(state),
        isOwner: isEvaluationOwner(state),
        isSubscribed: isSubscribed(state),
        navItems: getNavItems(state, props),
        project: getEvaluationProjectJS(state),
        timezone: getUserOrganizationTimezone(state),
    };
}

export const actionCreators = {
    menuActionHandler,
};

export const propTypes = {
    hasQuestionnaire: PropTypes.bool.isRequired,
    isEditor: PropTypes.bool.isRequired,
    isOwner: PropTypes.bool.isRequired,
    isSubscribed: PropTypes.bool.isRequired,
    menuActionHandler: PropTypes.func.isRequired,
    navItems: PropTypes.array.isRequired,
    project: PropTypes.object,
    timezone: PropTypes.string.isRequired,
};

import React from 'react';

import { QuestionAnswer } from '../../containers';
import * as Vendor from '../../containers/VendorApp';
import { ProposalCreateNav } from '../../components';
import { ResetVendorProposal } from '../components';
import PublicProjectRoutes from '../Public/Project';
import { legacyFetchData } from '../loaders';

export default (getState, dispatch) => {
    return [
        {
            element: (
                <ResetVendorProposal>
                    <Vendor.ProposalNav />
                </ResetVendorProposal>
            ),
            loader: legacyFetchData(getState, dispatch, Vendor.ProposalNav),
            path: 'proposals/:proposalId',
            children: [
                {
                    element: <Vendor.ProposalReview />,
                    index: true,
                },
                {
                    element: <ProposalCreateNav.Vendor />,
                    path: 'edit',
                    children: [
                        {
                            element: <Vendor.ProposalCreate />,
                            index: true,
                        },
                    ],
                },
                {
                    element: <Vendor.ReverseAuction />,
                    loader: legacyFetchData(getState, dispatch, Vendor.ReverseAuction),
                    path: 'projects/:projectId/reverse-auction',
                    children: [
                        {
                            element: <Vendor.ReverseAuctionLineItemDetails />,
                            index: true,
                        },
                        {
                            element: <Vendor.ReverseAuctionLineItemDetails />,
                            path: 'bidding',
                        },
                        {
                            element: <QuestionAnswer.PublicReverseAuction />,
                            loader: legacyFetchData(
                                getState,
                                dispatch,
                                QuestionAnswer.PublicReverseAuction
                            ),
                            path: 'q-and-a',
                        },
                    ],
                },
                {
                    element: <Vendor.ProjectNav />,
                    path: 'projects/:projectId',
                    children: PublicProjectRoutes(getState, dispatch),
                },
            ],
        },
    ];
};

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { UserForm } from '../UserForm';
import { form } from '../UserForm/constants';
import { UserFormDisplay } from '../UserFormDisplay';

export class UserListItem extends PureComponent {
    static propTypes = {
        currentUser: PropTypes.shape({
            department_id: PropTypes.number,
            isVendor: PropTypes.bool.isRequired,
        }).isRequired,
        departmentsMap: PropTypes.object.isRequired,
        hasUserRoles: PropTypes.bool.isRequired,
        shouldShowRequestForm: PropTypes.bool,
        user: PropTypes.shape({
            department_id: PropTypes.number,
            disabling: PropTypes.bool,
            enabling: PropTypes.bool,
            id: PropTypes.number,
            inviting: PropTypes.bool,
            isExternal: PropTypes.bool.isRequired,
            reInviting: PropTypes.bool,
            updating: PropTypes.bool,
            userRoles: PropTypes.array,
            uuid: PropTypes.string,
        }).isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {};
    }

    get isDisabled() {
        const {
            user: { updating, inviting, reInviting, disabling, enabling },
        } = this.props;

        return updating || inviting || reInviting || disabling || enabling;
    }

    handleHideEditForm = () => {
        this.setState({ shouldShowEditUserForm: false });
    };

    handleShowEditForm = () => {
        this.setState({ shouldShowEditUserForm: true });
    };

    render() {
        const { currentUser, departmentsMap, hasUserRoles, shouldShowRequestForm, user } =
            this.props;

        const { shouldShowEditUserForm } = this.state;
        const shouldShowNewUserForm = !!user.uuid;

        const disabled = this.isDisabled;

        if (shouldShowNewUserForm || shouldShowEditUserForm || shouldShowRequestForm) {
            const initialValues = {};
            if (!currentUser.isVendor) {
                initialValues.department_id = user.department_id || currentUser.department_id;
                initialValues.userRoles = (user.userRoles || []).map((userRole) => userRole.id);
            }

            return (
                <UserForm
                    currentUser={currentUser}
                    disabled={disabled}
                    form={`${form}${user.id || user.uuid}`}
                    initialValues={{
                        ...user,
                        ...initialValues,
                    }}
                    onHideEditForm={this.handleHideEditForm}
                    shouldShowNewUserForm={shouldShowNewUserForm}
                    shouldShowRequestForm={shouldShowRequestForm}
                    user={user}
                />
            );
        }
        return (
            <UserFormDisplay
                currentUser={currentUser}
                departmentsMap={departmentsMap}
                disabled={disabled}
                hasUserRoles={hasUserRoles}
                onShowEditForm={this.handleShowEditForm}
                user={user}
            />
        );
    }
}

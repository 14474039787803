import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';

import { fieldNames, form } from './constants';
import { getContractComplaintModalDataJS } from './selectors';
import { validate } from './validate';
import { showConfirmationSimpleModal } from '../../../../../actions/confirmation';
import {
    createContractComplaint,
    deleteContractComplaint,
    updateContractComplaint,
} from '../../../../../actions/contracts';
import { showSnackbar } from '../../../../../actions/notification';
import { Button, InputText, OutlineButton, StatusLabel, Toggle } from '../../../../../components';
import { limitTextLength } from '../../../../../Forms/normalizers';

const { COMPLAINT, IS_RESOLVED, NOTES, REQUEST, TITLE } = fieldNames;

const mapStateToProps = (state) => {
    const { contractComplaint, contractReview, readOnly } = getContractComplaintModalDataJS(state);

    return {
        contractComplaint,
        contractReview,
        initialValues: contractComplaint,
        isNew: !contractComplaint,
        readOnly,
    };
};

const mapDispatchToProps = {
    showSnackbar,
    createContractComplaint,
    deleteContractComplaint,
    showConfirmationSimpleModal,
    updateContractComplaint,
};

const formConfig = {
    form,
    validate,
};

// @connect
// @reduxForm
class ConnectedContractComplaintModal extends Component {
    static propTypes = {
        showSnackbar: PropTypes.func.isRequired,
        contractComplaint: PropTypes.shape({
            complaint: PropTypes.string,
            id: PropTypes.number.isRequired,
            isResolved: PropTypes.bool,
            notes: PropTypes.string,
            request: PropTypes.string,
            title: PropTypes.string.isRequired,
        }),
        contractReview: PropTypes.shape({
            contract_id: PropTypes.number.isRequired,
            id: PropTypes.number.isRequired,
            isPublished: PropTypes.bool.isRequired,
        }).isRequired,
        createContractComplaint: PropTypes.func.isRequired,
        deleteContractComplaint: PropTypes.func.isRequired,
        dirty: PropTypes.bool.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        hideModal: PropTypes.func.isRequired,
        isNew: PropTypes.bool.isRequired,
        readOnly: PropTypes.bool,
        showConfirmationSimpleModal: PropTypes.func.isRequired,
        updateContractComplaint: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            updateError: null,
            updating: false,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    normalizeTitle = limitTextLength(250);

    normalizeTextArea = limitTextLength(2000);

    hideModal = (options = {}) => {
        const { dirty, isNew, hideModal } = this.props;
        const { force } = options;

        const confirmExitMessage = isNew
            ? 'You have not submitted your complaint, all information entered will be lost. Are you sure you want to exit?'
            : 'You have unsaved changes, are you sure you want to exit?';

        // Confirm exit if form is dirty and not forcing hide
        // eslint-disable-next-line no-alert
        if (force || !dirty || window.confirm(confirmExitMessage)) {
            hideModal();
        }
    };

    deleteContractComplaint = () => {
        const {
            contractComplaint,
            contractReview: { contract_id: contractId, id: contractReviewId },
        } = this.props;

        this.setState({ updating: true, updateError: null });
        this.props
            .deleteContractComplaint(contractId, contractReviewId, contractComplaint.id)
            .then(() => {
                this.setState({ updating: false });
                this.hideModal({ force: true });
                this.props.showSnackbar('Complaint Deleted');
            })
            .catch((error) => {
                this.setState({ updating: false, updateError: error.message });
            });
    };

    deleteHandler = () => {
        this.props.showConfirmationSimpleModal(this.deleteContractComplaint, {
            btnText: 'Delete Complaint',
        });
    };

    submitHandler = (data) => {
        const {
            contractComplaint,
            contractReview: { contract_id: contractId, id: contractReviewId, isPublished },
            createContractComplaint: createComplaint,
            isNew,
            updateContractComplaint: updateComplaint,
        } = this.props;

        this.setState({ updating: true, updateError: null });

        const submitPromise = isNew
            ? createComplaint(contractId, contractReviewId, data)
            : updateComplaint(contractId, contractReviewId, contractComplaint.id, data);

        submitPromise
            .then(() => {
                this.setState({ updating: false });
                this.props.showSnackbar(
                    isNew && isPublished ? 'Complaint Submitted' : 'Complaint Saved'
                );
                this.hideModal({ force: true });
            })
            .catch((error) => {
                this.setState({ updating: false, updateError: error.message });
            });
    };

    renderNewBody() {
        const { updating } = this.state;

        return (
            <>
                <Field
                    component={InputText}
                    disabled={updating}
                    hasFeedback={false}
                    label="Title"
                    name={TITLE}
                    normalize={this.normalizeTitle}
                    placeholder="Title of your complaint"
                    type="text"
                />
                <Field
                    component={InputText}
                    disabled={updating}
                    hasFeedback={false}
                    label="Description of incident"
                    name={COMPLAINT}
                    normalize={this.normalizeTextArea}
                    placeholder="Describe the incident that necessitated this complaint"
                    type="textarea"
                />
                <Field
                    component={InputText}
                    disabled={updating}
                    hasFeedback={false}
                    label="Desired Resolution"
                    name={REQUEST}
                    normalize={this.normalizeTextArea}
                    placeholder="Describe the desired resolution of this complaint"
                    type="textarea"
                />
                <Field
                    component={InputText}
                    disabled
                    help="Use notes to update the status of the complaint after it has been filed"
                    label="Notes"
                    name={NOTES}
                    normalize={this.normalizeTextArea}
                    overrideFeedback
                    placeholder="Add notes on how the complaint is being addressed"
                    type="textarea"
                />
            </>
        );
    }

    renderUpdateBody() {
        const {
            contractComplaint: { complaint, isResolved, notes, request },
            readOnly,
        } = this.props;

        const { updating } = this.state;

        return (
            <>
                <StatusLabel
                    bsStyle={isResolved ? 'success' : 'danger'}
                    className={this.styles.statusLabel}
                >
                    <i className={`fa fa-${isResolved ? 'check' : 'ban'}`} />
                    &nbsp;
                    {isResolved ? 'RESOLVED' : 'UNRESOLVED'}
                </StatusLabel>
                {!readOnly && (
                    <>
                        <Field
                            component={Toggle}
                            disabled={updating}
                            label={<label>Has Complaint Been Resolved?</label>}
                            labelInline
                            name={IS_RESOLVED}
                        />
                        <Field
                            component={InputText}
                            disabled={updating}
                            label="Notes"
                            name={NOTES}
                            normalize={this.normalizeTextArea}
                            overrideFeedback
                            placeholder="Use notes to update the status of the complaint after it has been filed"
                            type="textarea"
                        />
                    </>
                )}
                <label>Description of incident:</label>
                <p>{complaint}</p>
                <label>Desired resolution:</label>
                <p>{request}</p>
                {readOnly && (
                    <>
                        <label>Notes since complaint was filed:</label>
                        <p>{notes || <em>No updates</em>}</p>
                    </>
                )}
            </>
        );
    }

    renderModalBody() {
        const {
            contractReview: { isPublished },
            handleSubmit,
            isNew,
            readOnly,
        } = this.props;

        const { updateError, updating } = this.state;

        if (readOnly) {
            return this.renderUpdateBody();
        }

        return (
            <form onSubmit={handleSubmit(this.submitHandler)}>
                {isNew ? this.renderNewBody() : this.renderUpdateBody()}
                <div className="row">
                    <div className="col-xs-6">
                        {!isNew && (
                            <OutlineButton
                                bsStyle="danger"
                                disabled={updating}
                                onClick={this.deleteHandler}
                            >
                                <i className="fa fa-trash" /> Delete
                            </OutlineButton>
                        )}
                    </div>
                    <div className="col-xs-6 text-right">
                        <Button bsStyle="link" disabled={updating} onClick={this.hideModal}>
                            Cancel
                        </Button>
                        &nbsp;&nbsp;
                        <Button bsStyle="primary" disabled={updating} type="submit">
                            {isNew && isPublished ? 'Submit' : 'Save'} Complaint
                        </Button>
                    </div>
                    {updateError && <div className="col-xs-12 error-block">{updateError}</div>}
                    <div className="col-xs-12 text-center">
                        <br />
                        <em>
                            {isPublished
                                ? 'NOTE: Vendor will be emailed when this complaint is submitted'
                                : 'NOTE: Vendor will be emailed when your review is published'}
                        </em>
                    </div>
                </div>
            </form>
        );
    }

    render() {
        const { contractComplaint, isNew } = this.props;

        return (
            <Modal onHide={this.hideModal} show>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">
                        {isNew ? 'File A Complaint' : contractComplaint.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.renderModalBody()}</Modal.Body>
            </Modal>
        );
    }
}

export const ContractComplaintModal = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedContractComplaintModal);

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@og-pro/ui';

export const DividerSectionLanding = ({ section, setActiveSectionId }) => {
    const styles = require('./index.scss');

    if (!section) {
        return null;
    }

    return (
        <Box className={styles.container}>
            {section.children.map((child) => {
                return (
                    <Box
                        className={styles.section}
                        key={child.index}
                        onClick={() => setActiveSectionId(child.index)}
                    >
                        {child.manualNumber && (
                            <Typography className={styles.manualNumber} variant="h5">
                                {child.manualNumber}
                            </Typography>
                        )}
                        {!child.manualNumber && (
                            <span className={styles.sectionNumber}>{child.numbering}</span>
                        )}
                        {child.title}
                    </Box>
                );
            })}
        </Box>
    );
};

DividerSectionLanding.propTypes = {
    section: PropTypes.shape({
        children: PropTypes.arrayOf(
            PropTypes.shape({
                index: PropTypes.number,
                manualNumber: PropTypes.string,
                numbering: PropTypes.string,
                title: PropTypes.string,
            })
        ),
    }),
    setActiveSectionId: PropTypes.func.isRequired,
};

import { omit } from 'lodash';
import classnames from 'classnames';
import React, { PureComponent } from 'react';
import { FormGroup, FormControl, InputGroup } from 'react-bootstrap';
import { Box, buildQaTag, componentNames } from '@og-pro/ui';

import { Label } from '.';
import { HelpBlock } from '..';
import { getHtmlCharacterCount } from '../../utils';
import { getValue, getComponentClass, getBsStyleAndHelpText } from './helpers';
import { InputPropTypes } from './types';

export class InputText extends PureComponent {
    static propTypes = InputPropTypes;

    static defaultProps = {
        autoFocus: false,
        className: '',
        commentIcon: false,
        commentData: {},
        customChange: undefined,
        disabled: false,
        enforceCharacterLimit: false,
        formGroupClassName: '',
        hasFeedback: true,
        helpIcon: false,
        helpIconClick: undefined,
        helpIconNode: undefined,
        id: undefined,
        inputGroupPrefix: undefined,
        inputGroupSuffix: undefined,
        label: undefined,
        labelledBy: undefined,
        overrideFeedback: false,
        placeholder: undefined,
        showValidation: false,
        type: undefined,
        useOpenGovStyle: false,
    };

    get styles() {
        if (this.props.useOpenGovStyle) {
            return require('./InputTextOpenGovStyle.scss');
        }

        return require('./InputText.scss');
    }

    // this is here if you want to include an additional action
    // when the value changes
    onChange = (event) => {
        const {
            characterLimit,
            customChange,
            enforceCharacterLimit,
            input: { onChange, value },
            type,
        } = this.props;

        if (type === 'number' && !event.target.value && event.target.value !== 0) {
            event.target.value = null;
        }

        if (
            enforceCharacterLimit &&
            characterLimit &&
            event.target.value.length > characterLimit &&
            event.target.value.length > value.length
        ) {
            return;
        }

        if (customChange) {
            customChange(event);
        }

        return onChange(event);
    };

    onBlur = (event) => {
        const {
            input: { onBlur },
            type,
        } = this.props;

        if (type === 'number' && !event.target.value && event.target.value !== 0) {
            event.target.value = null;
        }

        return onBlur(event);
    };

    renderFormInputGroup() {
        const { inputGroupPrefix, inputGroupSuffix, useOpenGovStyle } = this.props;
        return (
            <InputGroup>
                {inputGroupPrefix && (
                    <InputGroup.Addon
                        className={classnames({ [this.styles.addon]: useOpenGovStyle })}
                    >
                        {inputGroupPrefix}
                    </InputGroup.Addon>
                )}
                {this.renderForm()}
                {inputGroupSuffix && (
                    <InputGroup.Addon
                        className={classnames({ [this.styles.addon]: useOpenGovStyle })}
                    >
                        {inputGroupSuffix}
                    </InputGroup.Addon>
                )}
            </InputGroup>
        );
    }

    renderForm() {
        const {
            characterLimit,
            className,
            commentIcon,
            commentData,
            customChange,
            disabled,
            formGroupClassName,
            hasFeedback,
            help,
            helpIcon,
            helpIconClick,
            helpIconNode,
            id,
            input: { name, onFocus, value },
            inputGroupPrefix,
            inputGroupSuffix,
            label,
            labelledBy,
            meta,
            overrideFeedback,
            placeholder,
            qaTag,
            showValidation,
            type,
            useOpenGovStyle,
            ...otherProps
        } = this.props;

        const props = omit(otherProps, ['defaultValue']);

        const tagWithComponentName = buildQaTag(qaTag, componentNames.INPUT_TEXT);

        const inputValue = getValue({ value, type });
        const componentClass = getComponentClass(type);
        const useOpenGovStyleShowValidationError =
            !!meta.error && useOpenGovStyle && showValidation;

        const controlId = id || `form-group-${name}`;

        return (
            <FormControl
                aria-describedby={meta.error && showValidation ? `${controlId}-help` : undefined}
                aria-invalid={!!meta.error}
                aria-labelledby={labelledBy}
                className={classnames(`${className} ${this.styles.input}`, {
                    [this.styles.errorInput]: useOpenGovStyleShowValidationError,
                })}
                componentClass={componentClass}
                data-qa={tagWithComponentName}
                disabled={disabled}
                onBlur={this.onBlur}
                onChange={this.onChange}
                onFocus={onFocus}
                placeholder={placeholder}
                type={type}
                value={inputValue}
                {...props}
            />
        );
    }

    render() {
        const {
            characterLimit,
            commentIcon,
            commentData,
            formGroupClassName,
            hasFeedback,
            help,
            helpIcon,
            helpIconClick,
            helpIconNode,
            id,
            input: { name, value },
            inputGroupPrefix,
            inputGroupSuffix,
            label,
            meta: { error, touched, warning },
            overrideFeedback,
            showValidation,
            useOpenGovStyle,
        } = this.props;

        const displayValidation = (touched && !useOpenGovStyle) || showValidation;

        const { bsStyle, helpText } = getBsStyleAndHelpText(
            {
                help,
                overrideFeedback,
                displayValidation,
                error,
                warning,
            },
            useOpenGovStyle
        );

        const controlId = id || `form-group-${name}`;
        const hasInputGroup = inputGroupPrefix || inputGroupSuffix;
        const useOpenGovStyleShowValidationError = !!error && useOpenGovStyle && showValidation;

        return (
            <FormGroup
                className={formGroupClassName}
                controlId={controlId}
                validationState={bsStyle}
            >
                <div
                    className={classnames({
                        [this.styles.labelWrapperForCharacterCount]:
                            !helpText && characterLimit && useOpenGovStyle,
                    })}
                >
                    <Label
                        className={classnames(this.styles.label, {
                            [this.styles.noMarginBottom]: !!helpText && useOpenGovStyle,
                        })}
                        commentData={commentData}
                        commentIcon={commentIcon}
                        helpIcon={helpIcon}
                        helpIconClick={helpIconClick}
                        helpIconNode={helpIconNode}
                        label={label}
                    />
                    {useOpenGovStyle && (helpText || characterLimit) && (
                        <Box
                            display={helpText && characterLimit ? 'flex' : 'block'}
                            justifyContent="space-between"
                            mb={0.5}
                        >
                            {helpText && (
                                <HelpBlock id={`${controlId}-help`} useOpenGovStyle>
                                    {helpText}
                                </HelpBlock>
                            )}
                            {characterLimit && (
                                <Box textAlign="right">
                                    <HelpBlock useOpenGovStyle>
                                        {getHtmlCharacterCount(value)}/{characterLimit}
                                    </HelpBlock>
                                </Box>
                            )}
                        </Box>
                    )}

                    {characterLimit && !useOpenGovStyle && (
                        <div className={this.styles.characterCount}>
                            {`${value.length}/${characterLimit}`}
                        </div>
                    )}
                    {hasFeedback && !overrideFeedback && <FormControl.Feedback />}
                </div>
                {hasInputGroup ? this.renderFormInputGroup() : this.renderForm()}
                {helpText && !useOpenGovStyle && (
                    <HelpBlock id={`${controlId}-help`}>{helpText}</HelpBlock>
                )}
                {useOpenGovStyleShowValidationError && (
                    <HelpBlock
                        className={this.styles.validationError}
                        id={`${controlId}-help`}
                        useOpenGovStyle={useOpenGovStyle}
                    >
                        <i className="fa fa-exclamation-triangle" />
                        &nbsp; {error}
                    </HelpBlock>
                )}
            </FormGroup>
        );
    }
}

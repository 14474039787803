import React from 'react';

import { ReduxFormRadioGroup } from '@og-pro/ui';
import { useSelector } from 'react-redux';
import { Field, getFormValues } from 'redux-form';

import {
    customFieldFields,
    ENUM_OPTIONS_FIELD,
    instructionsModes,
} from '@og-pro/shared-config/customFormService/customField';

import { formConfig } from '../Form/form';

const { NAME, INSTRUCTIONS_TEXT, INSTRUCTIONS_MODE } = customFieldFields;
const { TOOLTIP, DESCRIPTION } = instructionsModes;

export const YesNoPreview = () => {
    const formValues = useSelector((state) => getFormValues(formConfig.form)(state)) || {};
    const instructionsText = formValues[INSTRUCTIONS_TEXT];
    const yesNoOptions = formValues[ENUM_OPTIONS_FIELD] || [];

    const yesNoOptionsArray = yesNoOptions.map((option) => {
        return {
            value: option,
            label: option,
        };
    });

    return (
        <Field
            component={ReduxFormRadioGroup}
            description={
                formValues[INSTRUCTIONS_MODE] === DESCRIPTION ? instructionsText : undefined
            }
            disabled
            label={formValues[NAME] || 'Your title will go here'}
            name="yesNo_PREVIEW"
            options={yesNoOptionsArray}
            sx={{
                width: '100%',
            }}
            tooltip={formValues[INSTRUCTIONS_MODE] === TOOLTIP ? instructionsText : undefined}
        />
    );
};

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';

import { useSelector } from 'react-redux';

import {
    chartBaseProps,
    requestsNeedingAttentionVisualization,
    titleBaseStyles,
    visualizationFontFamily,
    exportingBaseProps,
} from './constants';
import { useRenderChart } from './hooks/useRenderChart';
import { useQueryParam } from '../../../../../hooks';
import { getIsGroupAdminOrLeader } from '../selectors';
import { getInitialParams } from '../helpers';
import { chartInteractionParamsDict } from './helpers/chartInteractions';
import { loadMoreIcon } from './helpers';

const { TYPE } = chartInteractionParamsDict;

export const RequestsNeedingAttention = ({ data }) => {
    const [params, , setQueryParams] = useQueryParam();

    const isAdminOrLeader = useSelector((state) => getIsGroupAdminOrLeader(state));

    const initialParams = getInitialParams(isAdminOrLeader);

    const labelFormatter = (name, y, color, yFontSize, nameFontSize) => {
        return `
        <div style="display:flex; width: 100%; flex-direction: column; gap: 4px; text-align: center; justify-content: center;">
            <div style="font-size: ${yFontSize}px; color: ${color}; font-family: Barlow Semi Condensed; font-weight: 300; line-height: 72px">${y}</div>
            <div style="font-size: ${nameFontSize}px; font-weight: 500; line-height: 24px; font-family: Benton Sans">${name}</div>
        </div>
        `;
    };

    const totalItems = data.length;

    const getLegendItemsDistance = (itemsDistance) => {
        // If there are only 2 items in the legend, we need to adjust the distance to space them evenly
        if (totalItems === 2) {
            return itemsDistance * 2;
        }

        // If there are 3 or more items in the legend, we don't need to adjust the distance
        return itemsDistance;
    };

    const renderChart = () => {
        loadMoreIcon(Highcharts);

        Highcharts.chart(requestsNeedingAttentionVisualization, {
            chart: {
                type: 'pie',
                ...chartBaseProps,
                events: {
                    // Workaround to center legend items when legend spans into multiple lines
                    // https://github.com/highcharts/highcharts/issues/13817#issuecomment-653483646
                    /* eslint-disable no-underscore-dangle */
                    render() {
                        const chart = this;
                        const legend = chart.legend;

                        const lines = [];
                        let itemY = null;
                        let index = 0;

                        legend.allItems.forEach(function (item) {
                            if (item._legendItemPos[1] === itemY) {
                                lines[index].items.push(item);
                                lines[index].width += item.itemWidth;
                            } else {
                                itemY = item._legendItemPos[1];
                                index =
                                    lines.push({
                                        items: [item],
                                        y: itemY,
                                        width: item.itemWidth,
                                    }) - 1;
                            }
                        });
                    },
                    load() {
                        // Add data-qa attributes to legend items
                        const chart = this;
                        chart.series[0].points.forEach((point, index) => {
                            const legendItem = chart.legend.allItems[index].legendGroup.element;
                            legendItem.setAttribute(
                                'data-qa',
                                `requestsNeedingAttention-${point.name}`
                            );
                        });
                    },
                },
            },
            title: {
                text: 'Requests Needing Attention',
                align: 'left',
                y: 20,
                x: 5,
                style: titleBaseStyles,
            },
            plotOptions: {
                pie: {
                    visible: false,
                    dataLabels: {
                        enabled: false,
                    },
                    showInLegend: true,
                    point: {
                        events: {
                            legendItemClick() {
                                const clickedPoint = this;
                                const onlyVisible =
                                    clickedPoint.series.points.filter((point) => point.visible)
                                        .length === 1 && clickedPoint.visible;

                                // If clicked element is the only visible one, de-select it
                                setTimeout(() => {
                                    setQueryParams({
                                        ...initialParams,
                                        [TYPE]: onlyVisible ? null : clickedPoint.options.value,
                                    });
                                }, 0);

                                return false;
                            },
                        },
                    },
                },
            },
            credits: {
                enabled: false,
            },
            series: [
                {
                    type: 'pie',
                    name: 'Requests Needing Attention',
                    innerSize: '50%',
                    data,
                },
            ],
            legend: {
                align: 'center',
                verticalAlign: 'middle',
                layout: 'horizontal',
                itemMarginTop: 10,
                itemStyle: {
                    fontFamily: visualizationFontFamily,
                    textAlign: 'center',
                },
                useHTML: true,
                symbolPadding: 0,
                itemDistance: getLegendItemsDistance(60),
                symbolWidth: 0,
                symbolHeight: 0,
                padding: 0,
                squareSymbol: false,
            },
            responsive: {
                rules: [
                    {
                        condition: {
                            minWidth: 505,
                        },
                        chartOptions: {
                            legend: {
                                labelFormatter() {
                                    const { name, y, color } = this;
                                    return labelFormatter(name, y, color, 64, 16);
                                },
                            },
                        },
                    },
                    {
                        condition: {
                            maxWidth: 504,
                            minWidth: 446,
                        },
                        chartOptions: {
                            legend: {
                                labelFormatter() {
                                    const { name, y, color } = this;
                                    return labelFormatter(name, y, color, 64, 16);
                                },
                                itemDistance: getLegendItemsDistance(50),
                            },
                        },
                    },
                    {
                        condition: {
                            maxWidth: 445,
                            minWidth: 387,
                        },
                        chartOptions: {
                            legend: {
                                labelFormatter() {
                                    const { name, y, color } = this;
                                    return labelFormatter(name, y, color, 64, 16);
                                },
                                itemDistance: getLegendItemsDistance(30),
                            },
                        },
                    },
                    {
                        condition: {
                            maxWidth: 386,
                            minWidth: 331,
                        },
                        chartOptions: {
                            legend: {
                                labelFormatter() {
                                    const { name, y, color } = this;
                                    return labelFormatter(name, y, color, 48, 14);
                                },
                                itemDistance: getLegendItemsDistance(30),
                            },
                        },
                    },
                    {
                        condition: {
                            maxWidth: 330,
                        },
                        chartOptions: {
                            legend: {
                                labelFormatter() {
                                    const { name, y, color } = this;
                                    return labelFormatter(name, y, color, 32, 12);
                                },
                                itemDistance: getLegendItemsDistance(15),
                            },
                        },
                    },
                    {
                        condition: {
                            maxWidth: 300,
                        },
                        chartOptions: {
                            chart: {
                                height: 260,
                            },
                            legend: {
                                labelFormatter() {
                                    const { name, y, color } = this;
                                    return labelFormatter(name, y, color, 32, 10);
                                },
                                itemDistance: getLegendItemsDistance(5),
                            },
                        },
                    },
                ],
            },
            exporting: {
                ...exportingBaseProps,
            },
        });
    };

    useEffect(() => {
        // Apply legend styles based on query params
        const chart = Highcharts.charts.find(
            (chartElement) => chartElement?.renderTo.id === requestsNeedingAttentionVisualization
        );

        const initialValue = params[TYPE];

        if (chart) {
            chart.series[0].points.forEach((point) => {
                const isVisible = point.options.value === initialValue || !initialValue;
                point.update({ visible: isVisible }, false);

                const legendItem = point.legendItem;
                if (legendItem) {
                    legendItem.css({
                        opacity: isVisible ? 1 : 0.3,
                        filter: isVisible ? 'grayscale(0)' : 'grayscale(1)',
                        color: isVisible ? '#000000' : '#A0A0A0',
                    });
                }
            });

            chart.redraw();
        }
    }, [params]);

    useRenderChart(requestsNeedingAttentionVisualization, data, renderChart, params[TYPE]);

    return <div id={requestsNeedingAttentionVisualization} />;
};

RequestsNeedingAttention.propTypes = {
    data: PropTypes.array.isRequired,
};

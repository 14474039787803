import React, { PureComponent } from 'react';
import { Panel } from 'react-bootstrap';

import { Main, PageTitle } from '..';

export class SubscriptionExpired extends PureComponent {
    render() {
        const styles = require('./SubscriptionExpired.scss');

        return (
            <Main className="col-md-8 col-md-offset-2">
                <PageTitle title="Subscription Expired" />
                <Panel className={styles.panel}>
                    <Panel.Body>
                        <h2 className={`text-danger ${styles.icon}`}>
                            <i className="fa fa-lg fa-warning" />
                        </h2>
                        <h2 className="text-danger">
                            Your organization&rsquo;s subscription has expired!
                        </h2>
                        <div className={styles.explanation}>
                            To renew your OpenGov Procurement subscription please contact us
                            at&nbsp;
                            <a href="mailto:sales@opengov.com">sales@opengov.com</a> or ask your
                            administrator for more information.
                        </div>
                    </Panel.Body>
                </Panel>
            </Main>
        );
    }
}

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Button, ImageUploadModal } from '..';

const MAX_MB_FILE_SIZE = 2;

export class AvatarEditor extends PureComponent {
    static propTypes = {
        imageSrc: PropTypes.string,
        onUpload: PropTypes.func.isRequired,
        width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    };

    static defaultProps = {
        imageSrc: 'https://assets.procurement.opengov.com/assets/placeholder-profile-image.jpg',
        width: '100%',
    };

    constructor(props) {
        super(props);

        this.state = { showModal: false };
    }

    get styles() {
        return require('./index.scss');
    }

    handleHideOrCancelModal = () => {
        this.setState({ showModal: false });
    };

    showModal = () => {
        this.setState({ showModal: true });
    };

    render() {
        const { imageSrc, onUpload, width } = this.props;

        return (
            <div className={this.styles.avatarContainer}>
                <div>
                    <Button
                        aria-label="Edit Avatar"
                        className={this.styles.imageContainer}
                        onClick={this.showModal}
                        qaTag="avatarEditor-userAvatarIcon"
                        style={{
                            backgroundImage: `url(${imageSrc})`,
                            width,
                            height: width,
                        }}
                    >
                        <div className={this.styles.editIcon}>
                            <i className="fa fa-2x fa-camera" />
                            <div>Edit</div>
                        </div>
                    </Button>
                </div>
                {this.state.showModal && (
                    <ImageUploadModal
                        allowEditing
                        maxFileSizeMb={MAX_MB_FILE_SIZE}
                        onCancel={this.handleHideOrCancelModal}
                        onHide={this.handleHideOrCancelModal}
                        showModal={this.state.showModal}
                        updateImage={onUpload}
                    />
                )}
            </div>
        );
    }
}

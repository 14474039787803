import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from '../Tooltip';

export const CustomOption = (props) => {
    const { children, innerProps, innerRef, data, getStyles } = props;
    const hasMessage = data.message && data.message.length > 0;
    const shortName = data.projectSection?.shortName || null;
    const styles = require('./CustomOption.scss');

    const optionContent = (
        <div
            {...innerProps}
            className={getStyles('option', props).default}
            data-qa={data.value ? `searchSelect-option-${data.value}` : null}
            ref={innerRef}
            style={{
                ...getStyles('option', props),
                cursor: hasMessage ? 'pointer' : 'default',
            }}
        >
            <div className={styles.customOptionContent}>
                {children}
                {shortName && <div className={styles.customOptionNote}>{shortName}</div>}
            </div>
        </div>
    );

    if (hasMessage) {
        return (
            <Tooltip id={`tooltip-${data.value}`} tooltip={data.message}>
                {optionContent}
            </Tooltip>
        );
    }

    return optionContent;
};

CustomOption.propTypes = {
    children: PropTypes.node,
    data: PropTypes.shape({
        message: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
        projectSection: PropTypes.shape({
            shortName: PropTypes.string,
        }),
    }),
    innerProps: PropTypes.object,
    innerRef: PropTypes.func,
    getStyles: PropTypes.func,
};

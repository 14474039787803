import { fieldNames } from './constants';

const { INCOMPLETE_REASON } = fieldNames;

export function validate(values) {
    const errors = {};

    if (!values[INCOMPLETE_REASON]) {
        errors[INCOMPLETE_REASON] = 'Please provide a reason request is incomplete';
    }

    return errors;
}

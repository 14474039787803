import { listToDict } from '@og-pro/shared-config/helpers';

export const fields = [
    'attachments',
    'contractorSelectedDate',
    'proposalDeadline',
    'rawSummary',
    'releaseProjectDate',
    'summary',
];
export const fieldNames = listToDict(fields);

import { CATEGORIES, CUSTOM_CATEGORY_NAME } from './constants';

export const validate = (values) => {
    const errors = {};

    if (!(values[CUSTOM_CATEGORY_NAME] || '').trim()) {
        errors[CUSTOM_CATEGORY_NAME] = 'Custom category name is required';
    }

    if (!values[CATEGORIES] || values[CATEGORIES].length === 0) {
        errors[CATEGORIES] = 'At least one category code is required';
    }

    return errors;
};

import PropTypes from 'prop-types';
import React from 'react';

import { Label } from '..';

export const TextDisplayField = ({ className, input, label, valueClassName }) => {
    return (
        <div className={`${className} form-group`}>
            <Label label={label} />
            <div className={valueClassName}>{input.value}</div>
        </div>
    );
};

TextDisplayField.propTypes = {
    className: PropTypes.string,
    input: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    valueClassName: PropTypes.string,
};

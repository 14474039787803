import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { FLAGS, withFlags } from '@og-pro/launch-darkly/client';
import { projectStatusesDict, projectTypesDict } from '@og-pro/shared-config/projects';

import { invalidFinalizeTooltip } from './constants';
import { InviteItem, ExportButton, SaveButton } from '../ToolbarItems';
import { getIsApprovalComplete } from '../../Approvals/selectors';
import {
    getBuilderDisplayName,
    getDocBuilderProjectDashboardPath,
    getIntakeDashboardPath,
    getIntakePath,
    getIntakeWritingPath,
    getReviewPath,
    getUserApprovalJS,
    getWritingPath,
    isBuilderDraftPage,
    isBuilderEditor,
    isBuilderFormValid,
    isBuilderSaveValid,
    isDocumentEditable,
    isIntakeDraftPage,
    isIntakeEditor,
} from '../../selectors';
import { initiateSave } from '../../../../actions/project/create/projectCreate';
import { ActionToolbarNavbarButton, ActionToolbarNavbarItem, Nav } from '../../../../components';
import {
    EVALUATE,
    FINALIZE,
    FINALIZE_CONTRACT_PACKAGE,
    POST,
    SUBMIT_INTAKE_FOR_REVIEW,
    UNFINALIZE,
} from '../../../../constants/menuActions';
import { trackEvent } from '../../../../helpers';

const { DRAFT, FINAL, REQUEST_DRAFT, REQUEST_REVIEW, REVIEW } = projectStatusesDict;

const mapStateToProps = (state, props) => {
    const { isIntake } = props;

    // Toolbar is used for both intake and builder phases
    const isDraftPage = isIntake
        ? isIntakeDraftPage(state, props)
        : isBuilderDraftPage(state, props);

    const isEditor = isIntake ? isIntakeEditor(state) : isBuilderEditor(state);

    const reviewPath = isIntake ? getIntakePath(state, props) : getReviewPath(state, props);

    const writingPath = isIntake
        ? getIntakeWritingPath(state, props)
        : getWritingPath(state, props);

    return {
        builderDisplayName: getBuilderDisplayName(state),
        disabled: !!state.projectCreate.get('updating'),
        docBuilderProjectDashboardPath: getDocBuilderProjectDashboardPath(state, props),
        isApprover: !!getUserApprovalJS(state),
        isDraftPage,
        isEditableStatus: isDocumentEditable(state),
        isEditor,
        isFinalizable: getIsApprovalComplete(state),
        isSaveValid: isBuilderSaveValid(state),
        isWritingFormValid: isBuilderFormValid(state),
        intakeDashboardPath: getIntakeDashboardPath(state, props),
        reviewPath,
        saving: !!state.projectCreate.get('updating'),
        writingPath,
        // used to take the user to the review tab when they click on the "Process Request" button
        // yes it's a weird hack but the juggling to handle all these different urls for two browsers is high
        // will get cleaned once we move away from the legacy editors
        sdv2WritingPathReview: getWritingPath(state, {
            ...props,
            location: { ...props.location, pathname: 'approvals' },
            isIntake,
        }),
    };
};

const mapDispatchToProps = {
    initiateSave,
};

// @connect
class ConnectedBuilderToolbar extends Component {
    static propTypes = {
        builderDisplayName: PropTypes.string.isRequired,
        disabled: PropTypes.bool.isRequired,
        docBuilderProjectDashboardPath: PropTypes.string.isRequired,
        getFlag: PropTypes.func.isRequired,
        hasAutoAddenda: PropTypes.bool.isRequired,
        hasEvaluation: PropTypes.bool.isRequired,
        hasSourcing: PropTypes.bool.isRequired,
        initiateSave: PropTypes.func.isRequired,
        isApprover: PropTypes.bool.isRequired,
        isBuilderUsed: PropTypes.bool.isRequired,
        isDraftPage: PropTypes.bool.isRequired,
        isEditableStatus: PropTypes.bool.isRequired,
        isEditor: PropTypes.bool.isRequired,
        isFinalizable: PropTypes.bool.isRequired,
        isIntake: PropTypes.bool,
        isSaveValid: PropTypes.bool.isRequired,
        isWritingFormValid: PropTypes.bool.isRequired,
        location: PropTypes.shape({
            query: PropTypes.shape({
                section: PropTypes.string,
            }).isRequired,
        }).isRequired,
        menuActionHandler: PropTypes.func.isRequired,
        project: PropTypes.shape({
            docBuilderProject: PropTypes.object,
            id: PropTypes.number.isRequired,
            isDocBuilder: PropTypes.bool.isRequired,
            intakeProjectId: PropTypes.number,
            status: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            wasPosted: PropTypes.bool.isRequired,
        }).isRequired,
        intakeDashboardPath: PropTypes.string.isRequired,
        reviewPath: PropTypes.string.isRequired,
        router: PropTypes.object.isRequired,
        saving: PropTypes.bool.isRequired,
        showConfirmationSimpleModal: PropTypes.func.isRequired,
        sdv2WritingPathReview: PropTypes.string,
        writingPath: PropTypes.string.isRequired,
    };

    get styles() {
        return require('../ProjectActionToolbar.scss');
    }

    saveHandler = () => {
        const {
            hasAutoAddenda,
            isSaveValid,
            project: { wasPosted },
            showConfirmationSimpleModal,
        } = this.props;

        if (wasPosted && isSaveValid) {
            const nextStepText = hasAutoAddenda
                ? 'If you decide to proceed, we will prompt you to create an Addendum ' +
                  'after saving to formally document the change.'
                : 'If you decide to proceed, you should issue an addenda that summarizes ' +
                  'the changes made.';
            return showConfirmationSimpleModal(() => this.props.initiateSave(isSaveValid), {
                bsStyle: 'primary',
                btnText: 'Save Changes',
                icon: 'pencil',
                text:
                    'Warning: Project has been publicly released!\n\n' +
                    'Are you sure you want to proceed with updating the document? ' +
                    'Changes to the published document could effect vendor responses ' +
                    `that have already been started.\n\n${nextStepText}`,
            });
        }

        return this.props.initiateSave(isSaveValid);
    };

    finalizeProject = () => {
        const { getFlag, isDraftPage, menuActionHandler, project, reviewPath, router } = this.props;

        if (isDraftPage) {
            router.push(`${reviewPath}/approvals`);
        }

        if (
            getFlag(FLAGS.ENABLE_CONTRACT_PACKAGE_COMPILER) &&
            project.type === projectTypesDict.CONTRACT
        ) {
            return menuActionHandler(FINALIZE_CONTRACT_PACKAGE);
        }

        return menuActionHandler(FINALIZE);
    };

    handleDraftClick = () => {
        const { menuActionHandler } = this.props;

        menuActionHandler(POST);
    };

    handleEditClick = () => {
        trackEvent('Edit Project Toolbar Button');
    };

    handleProcessIntakeClick = () => {
        const { reviewPath, router } = this.props;

        trackEvent('Process Intake Toolbar Button');
        router.push(`${reviewPath}/approvals`);
    };

    handleReOpenClick = () => {
        const { menuActionHandler } = this.props;

        menuActionHandler(UNFINALIZE);
    };

    handleReviewClick = () => {
        trackEvent('Review Project Toolbar Button');
    };

    handleStartClick = () => {
        const { menuActionHandler } = this.props;

        menuActionHandler(EVALUATE);
    };

    handleSubmitClick = () => {
        const {
            isIntake,
            isSaveValid,
            isWritingFormValid,
            location: {
                query: { section },
            },
            menuActionHandler,
            router,
            sdv2WritingPathReview,
        } = this.props;

        if (isIntake && section === 'confirmation' && isWritingFormValid) {
            menuActionHandler(SUBMIT_INTAKE_FOR_REVIEW);
        } else {
            trackEvent('Project confirmation');
            this.props.initiateSave(isSaveValid);

            router.push(sdv2WritingPathReview);
        }
    };

    renderInvite() {
        const { disabled, isEditor, project } = this.props;

        return <InviteItem disabled={disabled} isEditor={isEditor} projectId={project.id} />;
    }

    renderExport() {
        const { disabled, isBuilderUsed, isDraftPage } = this.props;

        if (!isBuilderUsed) {
            return null;
        }

        return (
            <ExportButton
                context={isDraftPage ? 'writing' : 'review'}
                disabled={disabled}
                shouldSave={isDraftPage}
            />
        );
    }

    renderSave() {
        const { disabled, isDraftPage, isEditor, isSaveValid, saving } = this.props;

        if (!isDraftPage) {
            return null;
        }

        return (
            <SaveButton
                clickHandler={this.saveHandler}
                disabled={disabled}
                isEditor={isEditor}
                isSaveValid={isSaveValid}
                saving={saving}
            />
        );
    }

    renderEditItem() {
        const { disabled, isDraftPage, isEditor, writingPath } = this.props;

        if (isDraftPage) {
            return null;
        }

        return (
            <ActionToolbarNavbarItem
                className="action-toolbar-edit-btn"
                disabled={disabled}
                editOnly
                href={writingPath}
                isEditor={isEditor}
                onClick={this.handleEditClick}
                qaTag="builderToolbar-edit"
            >
                <i className={`fa fa-pencil ${this.styles.editIcon}`} /> Edit
            </ActionToolbarNavbarItem>
        );
    }

    renderReviewItem() {
        const { disabled, isDraftPage, reviewPath } = this.props;

        if (!isDraftPage) {
            return null;
        }

        return (
            <ActionToolbarNavbarItem
                className="action-toolbar-review-btn"
                disabled={disabled}
                href={`${reviewPath}/document`}
                onClick={this.handleReviewClick}
                qaTag="builderToolbar-review"
            >
                <i className="fa fa-file-text" /> Review
            </ActionToolbarNavbarItem>
        );
    }

    renderSubmitForReviewButton() {
        const {
            builderDisplayName,
            disabled,
            isDraftPage,
            isEditor,
            isIntake,
            isWritingFormValid,
        } = this.props;

        const buttonText =
            isIntake && isWritingFormValid ? (
                <span>
                    <i className="fa fa-lg fa-check-square-o" />
                    &nbsp; Submit {builderDisplayName}
                </span>
            ) : (
                'Check for Errors'
            );

        return (
            <ActionToolbarNavbarButton
                bsStyle={isWritingFormValid && isIntake ? 'success' : 'default'}
                className={classnames(
                    'action-toolbar-submit-btn',
                    (!isIntake || (isDraftPage && !isWritingFormValid)) &&
                        this.styles.inactiveSubmitBtn
                )}
                disabled={disabled}
                editOnly
                isEditor={isEditor}
                onClick={this.handleSubmitClick}
                qaTag="builderToolbar-submitForReview"
            >
                {buttonText}
            </ActionToolbarNavbarButton>
        );
    }

    renderFinalizeButton() {
        const { builderDisplayName, disabled, isEditor, isFinalizable } = this.props;

        return (
            <ActionToolbarNavbarButton
                bsStyle="success"
                className="action-toolbar-finalize-btn"
                disabled={!isFinalizable || disabled}
                editOnly
                isEditor={isEditor}
                onClick={this.finalizeProject}
                qaTag="builderToolbar-finalize"
                tooltip={isFinalizable ? null : invalidFinalizeTooltip}
            >
                <i className="fa fa-lg fa-check-square-o" /> Finalize {builderDisplayName}
            </ActionToolbarNavbarButton>
        );
    }

    renderProcessButton() {
        const { disabled, isApprover } = this.props;

        return (
            <ActionToolbarNavbarButton
                bsStyle="success"
                className="action-toolbar-process-btn"
                disabled={!isApprover || disabled}
                onClick={this.handleProcessIntakeClick}
                qaTag="builderToolbar-processRequest"
                tooltip={isApprover ? null : 'Only the assigned user may process the request'}
            >
                <i className="fa fa-lg fa-check-square-o" /> Process Request
            </ActionToolbarNavbarButton>
        );
    }

    renderReOpenButton() {
        const { disabled, isEditor } = this.props;

        return (
            <ActionToolbarNavbarItem
                className="action-toolbar-reopen-btn"
                disabled={disabled}
                editOnly
                isEditor={isEditor}
                onClick={this.handleReOpenClick}
                qaTag="builderToolbar-reopen"
            >
                <i className="fa fa-unlock-alt fa-lg" /> Re-Open
            </ActionToolbarNavbarItem>
        );
    }

    renderPostButton() {
        const {
            disabled,
            hasSourcing,
            isEditor,
            project: { isDocBuilder },
        } = this.props;

        if (!hasSourcing || isDocBuilder) {
            return null;
        }

        return (
            <ActionToolbarNavbarButton
                bsStyle="success"
                className="action-toolbar-post-btn"
                disabled={disabled}
                editOnly
                isEditor={isEditor}
                onClick={this.handleDraftClick}
                qaTag="builderToolbar-draftPosting"
            >
                <i className="fa fa-lg fa-flag" /> Draft Posting
            </ActionToolbarNavbarButton>
        );
    }

    renderEvaluateButton() {
        const {
            disabled,
            hasEvaluation,
            isEditor,
            project: { isDocBuilder },
        } = this.props;

        if (!hasEvaluation || isDocBuilder) {
            return null;
        }

        return (
            <ActionToolbarNavbarButton
                className="action-toolbar-evaluate-btn"
                disabled={disabled}
                editOnly
                isEditor={isEditor}
                onClick={this.handleStartClick}
                qaTag="builderToolbar-startEvaluation"
            >
                <i className="fa fa-star" /> Start Evaluation
            </ActionToolbarNavbarButton>
        );
    }

    renderIntakeProject() {
        const {
            disabled,
            project: { intakeProjectId },
            intakeDashboardPath,
        } = this.props;

        if (!intakeProjectId) {
            return null;
        }

        return (
            <ActionToolbarNavbarItem
                className="action-toolbar-intake-project-btn"
                disabled={disabled}
                href={intakeDashboardPath}
                qaTag="builderToolbar-viewIntakeProject"
            >
                <i className="fa fa-folder-open" /> View Project
            </ActionToolbarNavbarItem>
        );
    }

    renderLinkedProject() {
        const {
            disabled,
            docBuilderProjectDashboardPath,
            project: { docBuilderProject, isDocBuilder },
        } = this.props;

        if (!isDocBuilder || !docBuilderProject) {
            return null;
        }

        return (
            <ActionToolbarNavbarItem
                className="action-toolbar-linked-project-btn"
                disabled={disabled}
                href={docBuilderProjectDashboardPath}
                qaTag="builderToolbar-viewLinkedProject"
            >
                <i className="fa fa-folder-open" /> View Project
            </ActionToolbarNavbarItem>
        );
    }

    renderDraft() {
        return (
            <div>
                <Nav>
                    {this.renderInvite()}
                    {this.renderExport()}
                    {this.renderEditItem()}
                    {this.renderReviewItem()}
                    {this.renderSave()}
                    {this.renderLinkedProject()}
                    {this.renderSubmitForReviewButton()}
                </Nav>
            </div>
        );
    }

    renderReview() {
        return (
            <div>
                <Nav>
                    {this.renderInvite()}
                    {this.renderExport()}
                    {this.renderEditItem()}
                    {this.renderReviewItem()}
                    {this.renderSave()}
                    {this.renderLinkedProject()}
                    {this.renderFinalizeButton()}
                </Nav>
            </div>
        );
    }

    renderFinal() {
        return (
            <div>
                <Nav>
                    {this.renderInvite()}
                    {this.renderExport()}
                    {this.renderLinkedProject()}
                    {this.renderReOpenButton()}
                    {this.renderEvaluateButton()}
                    {this.renderPostButton()}
                </Nav>
            </div>
        );
    }

    renderIntakeDraft() {
        return (
            <div>
                <Nav>
                    {this.renderInvite()}
                    {this.renderEditItem()}
                    {this.renderReviewItem()}
                    {this.renderSave()}
                    {this.renderSubmitForReviewButton()}
                </Nav>
            </div>
        );
    }

    renderIntakeReview() {
        return (
            <div>
                <Nav>
                    {this.renderInvite()}
                    {this.renderEditItem()}
                    {this.renderReviewItem()}
                    {this.renderSave()}
                    {this.renderProcessButton()}
                </Nav>
            </div>
        );
    }

    renderClosed() {
        const { isEditableStatus, isIntake } = this.props;

        if (isIntake) {
            return (
                <div>
                    <Nav>
                        {this.renderInvite()}
                        {this.renderIntakeProject()}
                    </Nav>
                </div>
            );
        }

        return (
            <div>
                <Nav>
                    {this.renderInvite()}
                    {this.renderExport()}
                    {/*
                        The following are only included to allow bid amenders to make edits to
                        posted project
                    */}
                    {isEditableStatus && this.renderEditItem()}
                    {isEditableStatus && this.renderReviewItem()}
                    {isEditableStatus && this.renderSave()}
                </Nav>
            </div>
        );
    }

    render() {
        switch (this.props.project.status) {
            case DRAFT:
                return this.renderDraft();
            case REVIEW:
                return this.renderReview();
            case FINAL:
                return this.renderFinal();
            case REQUEST_DRAFT:
                return this.renderIntakeDraft();
            case REQUEST_REVIEW:
                return this.renderIntakeReview();
            default:
                return this.renderClosed();
        }
    }
}

export const BuilderToolbar = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withFlags()
)(ConnectedBuilderToolbar);

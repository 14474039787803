import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';

import { form, PHASE } from './constants';
import { SearchSelect } from '../../../../../components';

const formConfig = {
    enableReinitialize: true,
    form,
};

// @reduxForm
class ConnectedEvaluationPhaseSelectForm extends Component {
    static propTypes = {
        formClassName: PropTypes.string,
        options: PropTypes.array.isRequired,
    };

    render() {
        const { formClassName, options } = this.props;

        return (
            <Field
                aria-label="Select Evaluation Phase"
                blurInputOnSelect
                component={SearchSelect}
                formClassName={formClassName}
                isSearchable={false}
                name={PHASE}
                options={options}
                overrideFeedback
                placeholder="Select Evaluation Phase"
            />
        );
    }
}

export const EvaluationPhaseSelectForm = reduxForm(formConfig)(ConnectedEvaluationPhaseSelectForm);

import PropTypes from 'prop-types';
import React from 'react';

import { ExceptionStep } from './ExceptionStep';
import { PurchaseOrderStep } from './PurchaseOrderStep';
import { ReviewSequenceStep } from './ReviewSequenceStep';
import { SubmittedStep } from './SubmittedStep';

export const RequisitionsApprovalHistorySteps = ({ requisition, reviewSequenceSteps }) => {
    const styles = require('./index.scss');

    return (
        <div className={styles.stepHistoryContainer}>
            <ExceptionStep requisition={requisition} />
            <SubmittedStep requisition={requisition} />
            {reviewSequenceSteps.map((reviewSequenceStep) => (
                <ReviewSequenceStep
                    isCurrent={requisition.current_step_id === reviewSequenceStep.id}
                    key={reviewSequenceStep.id}
                    reviewSequenceStep={reviewSequenceStep}
                />
            ))}
            <PurchaseOrderStep requisition={requisition} />
        </div>
    );
};

RequisitionsApprovalHistorySteps.propTypes = {
    requisition: PropTypes.object.isRequired,
    reviewSequenceSteps: PropTypes.array.isRequired,
};

import { startCase, upperCase } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { getProjectStatusHistoryJS } from './selectors';
import { getDashboardPath } from '../../selectors';
import connectData from '../../../ConnectData';
import { loadProjectStatusHistory } from '../../../../actions/govProjects';
import {
    Button,
    LoadingError,
    LoadingSpinner,
    PageTitle,
    UserProfilePicture,
} from '../../../../components';
import { statusClass, statusIcon } from '../../../../components/helpers/statusHelper';

function fetchData(getState, dispatch, location, params) {
    if (!getState().govProjects.get('loadedStatusHistory')) {
        const projectId = Number.parseInt(params.projectId, 10);
        return dispatch(loadProjectStatusHistory(projectId));
    }
}

const mapStateToProps = (state, props) => {
    return {
        dashboardPath: getDashboardPath(state, props),
        loadError: state.govProjects.get('loadStatusHistoryError'),
        loading: state.govProjects.get('loadingStatusHistory'),
        projectAudits: getProjectStatusHistoryJS(state),
    };
};

// @connectData
// @connect
class ConnectedProjectStatusHistory extends Component {
    static propTypes = {
        dashboardPath: PropTypes.string.isRequired,
        loadError: PropTypes.string,
        loading: PropTypes.bool,
        projectAudits: PropTypes.arrayOf(
            PropTypes.shape({
                created_at: PropTypes.string.isRequired,
                id: PropTypes.number.isRequired,
                status: PropTypes.string.isRequired,
                user: PropTypes.shape({
                    displayName: PropTypes.string.isRequired,
                }),
            })
        ),
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const { dashboardPath, loadError, loading, projectAudits } = this.props;

        if (loading) {
            return <LoadingSpinner />;
        }

        if (loadError || !projectAudits) {
            return <LoadingError error={loadError} />;
        }

        const statusChanges = projectAudits.map((projectAudit, index) => {
            const { created_at: createdAt, id, status, user } = projectAudit;

            return (
                <div className={this.styles.row} key={id}>
                    <div className={this.styles.dateCol}>
                        <strong>{moment(createdAt).fromNow()}</strong>
                        <br />
                        <small className="text-muted">
                            <em>{moment(createdAt).format('MMM D YYYY h:mma')}</em>
                        </small>
                    </div>
                    <div className={this.styles.statusIconCol}>
                        <div
                            className={`${this.styles.statusIcon} label-${statusClass(status)}`}
                            title={`${startCase(status)} icon`}
                        >
                            <i className={`fa fa-${statusIcon(status)} fa-lg fa-inverse`} />
                        </div>
                        {index !== projectAudits.length - 1 && (
                            <div className={this.styles.divider} />
                        )}
                    </div>
                    <div className={this.styles.statusTextCol}>
                        status changed to <strong>{upperCase(status)}</strong>
                        {user && (
                            <span className={this.styles.userInfo}>
                                &nbsp;by&nbsp;
                                <UserProfilePicture horizontal user={user} />
                                &nbsp;
                                <strong>{user.displayName}</strong>
                            </span>
                        )}
                    </div>
                </div>
            );
        });

        return (
            <>
                <PageTitle title="Project Status History" />
                <Button
                    bsStyle="link"
                    qaTag="connectedProjectStatusHistory-backToDashboard"
                    to={dashboardPath}
                >
                    <i className="fa fa-angle-left" /> Back to Dashboard
                </Button>
                <div className={this.styles.statusHistoryContainer}>{statusChanges}</div>
            </>
        );
    }
}

export const ProjectStatusHistory = compose(
    connectData(fetchData),
    withRouter,
    connect(mapStateToProps)
)(ConnectedProjectStatusHistory);

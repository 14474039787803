import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export class RequiredHeader extends PureComponent {
    static propTypes = {
        displayName: PropTypes.string.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const { displayName } = this.props;

        return (
            <div className={this.styles.headerWrapper}>
                {displayName}
                <span className="text-danger">*</span>
            </div>
        );
    }
}

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { criteriaInstructionsDict } from '@og-pro/shared-config/criteria';

import { fieldNames } from '../../constants';
import { Button, Well } from '../../../..';

const { IS_REVIEWED, IS_UNUSED } = fieldNames;

const { ATTACHMENT, CONFIRM, EDIT, RADIO, SELECT, TOGGLE, TOGGLE_EDIT } = criteriaInstructionsDict;

export class ReviewActionSidebar extends PureComponent {
    static propTypes = {
        arrayName: PropTypes.string.isRequired,
        change: PropTypes.func.isRequired,
        completeHandler: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        descriptionPristine: PropTypes.bool.isRequired,
        instructionType: PropTypes.string,
        isCompleted: PropTypes.bool.isRequired,
        isReviewed: PropTypes.bool,
        isUnused: PropTypes.bool,
    };

    get styles() {
        return require('./index.scss');
    }

    get status() {
        const { instructionType, isUnused } = this.props;

        switch (instructionType) {
            case TOGGLE:
            case TOGGLE_EDIT:
                return isUnused ? 'Item was marked to be removed.' : 'Item was marked to be kept.';
            case EDIT:
            case CONFIRM:
                return 'This item has been reviewed';
            case SELECT:
            case RADIO:
                return 'An option has been selected';
            case ATTACHMENT:
                return 'The attachment was marked as added.';
            default:
                return null;
        }
    }

    get instructions() {
        switch (this.props.instructionType) {
            case TOGGLE:
                return 'Please select whether to keep or remove this item';
            case TOGGLE_EDIT:
                return (
                    'Please select whether to keep or remove this item. ' +
                    'If keeping, please edit the item contents then click the ' +
                    '"Keep Item" button.'
                );
            case EDIT:
                return 'Please edit the item then click the "Complete" button';
            case CONFIRM:
                return (
                    'Please confirm the item is correct or edit the item ' +
                    'contents then click the "Complete" button'
                );
            case SELECT:
            case RADIO:
                return 'Please select an option then click the "Complete" button';
            case ATTACHMENT:
                return (
                    'Please confirm the attachment has been added ' +
                    'then click the "Complete" button'
                );
            default:
                return null;
        }
    }

    keepHandler = () => {
        const { arrayName, change, completeHandler } = this.props;
        change(`${arrayName}.${IS_REVIEWED}`, true);
        change(`${arrayName}.${IS_UNUSED}`, false);
        return completeHandler();
    };

    removeHandler = () => {
        const { arrayName, change, completeHandler } = this.props;
        change(`${arrayName}.${IS_REVIEWED}`, true);
        change(`${arrayName}.${IS_UNUSED}`, true);
        return completeHandler();
    };

    completeHandler = () => {
        const { arrayName, change, completeHandler } = this.props;
        change(`${arrayName}.${IS_REVIEWED}`, true);
        return completeHandler();
    };

    renderCompleteButtonText() {
        return (
            <>
                <i className="fa fa-check" /> Complete
            </>
        );
    }

    renderToggleAction(isIncomplete) {
        const { disabled } = this.props;

        return (
            <div>
                <Button
                    block
                    bsStyle="success"
                    disabled={isIncomplete || disabled}
                    onClick={this.keepHandler}
                >
                    <i className="fa fa-check" /> Keep Item
                </Button>
                <Button
                    block
                    className={this.styles.removeButton}
                    disabled={disabled}
                    onClick={this.removeHandler}
                >
                    <i className="fa fa-trash-o" /> Remove
                </Button>
            </div>
        );
    }

    renderEditAction() {
        const { descriptionPristine, isReviewed, disabled } = this.props;
        return (
            <div>
                <Button
                    block
                    bsStyle="success"
                    disabled={(descriptionPristine && !isReviewed) || disabled}
                    onClick={this.completeHandler}
                >
                    {this.renderCompleteButtonText()}
                </Button>
            </div>
        );
    }

    renderConfirmAction() {
        return (
            <div>
                <Button
                    block
                    bsStyle="success"
                    disabled={this.props.disabled}
                    onClick={this.completeHandler}
                >
                    {this.renderCompleteButtonText()}
                </Button>
            </div>
        );
    }

    renderSelectAction() {
        const { disabled, isCompleted, isReviewed } = this.props;

        return (
            <div>
                <Button
                    block
                    bsStyle="success"
                    disabled={(!isReviewed && !isCompleted) || disabled}
                    onClick={this.completeHandler}
                >
                    {this.renderCompleteButtonText()}
                </Button>
            </div>
        );
    }

    renderAttachmentAction() {
        return (
            <div>
                <Button
                    block
                    bsStyle="success"
                    disabled={this.props.disabled}
                    onClick={this.completeHandler}
                >
                    {this.renderCompleteButtonText()}
                </Button>
            </div>
        );
    }

    renderAction() {
        const { instructionType, isReviewed, descriptionPristine } = this.props;

        switch (instructionType) {
            case EDIT:
                return this.renderEditAction();
            case TOGGLE:
                return this.renderToggleAction();
            case TOGGLE_EDIT:
                return this.renderToggleAction(descriptionPristine && !isReviewed);
            case CONFIRM:
                return this.renderConfirmAction();
            case SELECT:
            case RADIO:
                return this.renderSelectAction();
            case ATTACHMENT:
                return this.renderAttachmentAction();
            default:
                return this.renderConfirmAction();
        }
    }

    render() {
        const { isReviewed } = this.props;

        return (
            <Well>
                <div className={this.styles.directions}>{this.instructions}</div>
                {this.renderAction()}
                {isReviewed && (
                    <div className={this.styles.status}>
                        <i className="fa fa-check" /> {this.status}
                    </div>
                )}
            </Well>
        );
    }
}

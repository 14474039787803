/* eslint-disable react/jsx-no-bind */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { AttachmentDropzone } from './AttachmentDropzone';
import { uploadFile } from '../../../actions/attachments';
import { showConfirmationSimpleModal } from '../../../actions/confirmation';
import { getAppendixIdsMap } from '../../../containers/GovApp/selectors';

const mapStateToProps = (state, props) => ({
    appendixIdsMap: getAppendixIdsMap(state, props),
});

const mapDispatchToProps = {
    showConfirmationSimpleModal,
    uploadFile,
};

// @connect
class ConnectedAttachable extends Component {
    static propTypes = {
        appendixIdsMap: PropTypes.object.isRequired,
        disabled: PropTypes.bool,
        // Used by the selector
        form: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
        formKey: PropTypes.string, // Used when multiple different attachment dropzones are displayed
        hideAppendixLetter: PropTypes.bool,
        isOGThemeEnabledForComponents: PropTypes.bool,
        label: PropTypes.string,
        onUploadComplete: PropTypes.func.isRequired,
        projectId: PropTypes.number.isRequired,
        requireConfirmationOptions: PropTypes.object,
        showConfirmationSimpleModal: PropTypes.func.isRequired,
        showNotification: PropTypes.bool,
        uploadFile: PropTypes.func.isRequired,
    };

    static defaultProps = {
        disabled: false,
        hideAppendixLetter: false,
        requireConfirmationOptions: undefined,
        showNotification: false,
    };

    /*
     * IMPORTANT:
     * This function should be unbound, but then the underlying components do not re-render on
     * form updates. This has basically been working by accident. Leaving alone for now until we
     * can find a better way to re-render on child form value updates.
     */
    uploadFile({ formKey, attachment = {}, index }) {
        const { onUploadComplete, projectId, requireConfirmationOptions, showNotification } =
            this.props;

        const upload = () =>
            this.props.uploadFile(projectId, { formKey, attachment, index }, onUploadComplete, {
                showNotification,
            });

        if (requireConfirmationOptions) {
            return this.props.showConfirmationSimpleModal(upload, requireConfirmationOptions);
        }
        return upload();
    }

    render() {
        const {
            appendixIdsMap,
            disabled,
            formKey,
            hideAppendixLetter,
            isOGThemeEnabledForComponents,
            label,
        } = this.props;

        return (
            <AttachmentDropzone
                appendixIdsMap={appendixIdsMap}
                disabled={disabled}
                formKey={formKey}
                hideAppendixLetter={hideAppendixLetter}
                isOGThemeEnabledForComponents={isOGThemeEnabledForComponents}
                label={label}
                uploadFile={this.uploadFile.bind(this)} // See note above for why function is bound
            />
        );
    }
}

export const Attachable = connect(mapStateToProps, mapDispatchToProps)(ConnectedAttachable);

import { fromJS } from 'immutable';

import * as approvalActions from '../actions/approvals';

function approvalReducer(state, action) {
    switch (action.type) {
        case approvalActions.REMOVE:
            if (state.get('id') !== action.approvalId) {
                return state;
            }
            return state.merge(
                fromJS({
                    removing: true,
                    removeError: null,
                })
            );
        case approvalActions.REMOVE_FAIL:
            if (state.get('id') !== action.approvalId) {
                return state;
            }
            return state.merge(
                fromJS({
                    removing: false,
                    removeError: action.error && action.error.message,
                })
            );
        case approvalActions.REMIND:
            if (state.get('id') !== action.approvalId) {
                return state;
            }
            return state.merge(
                fromJS({
                    reminding: true,
                    reminded: false,
                    remindError: null,
                })
            );
        case approvalActions.REMIND_SUCCESS:
            if (state.get('id') !== action.approvalId) {
                return state;
            }
            return state.merge(
                fromJS({
                    reminding: false,
                    reminded: true,
                })
            );
        case approvalActions.REMIND_FAIL:
            if (state.get('id') !== action.approvalId) {
                return state;
            }
            return state.merge(
                fromJS({
                    reminding: false,
                    reminded: false,
                    remindError: action.error && action.error.message,
                })
            );
        default:
            return state;
    }
}

const initialState = fromJS({
    added: false,
    addError: null,
    adding: false,
    list: [],
    loaded: false,
    loading: false,
    showModal: false,
});

export default function approvalsReducer(state = initialState, action = {}) {
    switch (action.type) {
        case approvalActions.LOAD:
            return state.merge(
                fromJS({
                    loading: true,
                    loaded: false,
                    loadError: null,
                })
            );
        case approvalActions.LOAD_SUCCESS:
            return state.merge(
                fromJS({
                    loading: false,
                    loaded: true,
                    list: fromJS(action.result),
                })
            );
        case approvalActions.LOAD_FAIL:
            return state.merge(
                fromJS({
                    loading: false,
                    loaded: false,
                    loadError: action.error && action.error.message,
                })
            );
        case approvalActions.ADD:
            return state.merge(
                fromJS({
                    adding: true,
                    added: false,
                    addError: null,
                })
            );
        case approvalActions.ADD_SUCCESS:
            return state.merge(
                fromJS({
                    adding: false,
                    added: true,
                    list: fromJS(action.result),
                })
            );
        case approvalActions.ADD_FAIL:
            return state.merge(
                fromJS({
                    adding: false,
                    added: false,
                    addError: action.error && action.error.message,
                })
            );
        case approvalActions.UPDATE_APPROVERS:
        case approvalActions.UPDATE:
            return state.merge(
                fromJS({
                    updating: true,
                    updateError: null,
                })
            );
        case approvalActions.UPDATE_SUCCESS: {
            return state
                .set(
                    'list',
                    state.get('list').map((item) => {
                        const reviewStep = item.toJS();

                        if (reviewStep.id === action.result.project_approval_step_id) {
                            const updatedProjectApprovals = reviewStep.projectApprovals.map(
                                (approval) => {
                                    if (approval.id === action.approvalId) {
                                        return action.result;
                                    }

                                    return approval;
                                }
                            );

                            return fromJS({
                                ...reviewStep,
                                projectApprovals: updatedProjectApprovals,
                            });
                        }

                        return item;
                    })
                )
                .merge(
                    fromJS({
                        updating: false,
                        modalData: action.result,
                    })
                );
        }
        case approvalActions.UPDATE_APPROVERS_SUCCESS: {
            return state.set(
                'list',
                state.get('list').map((item) => {
                    const reviewStep = item.toJS();

                    if (reviewStep.id === action.reviewStepId) {
                        return fromJS({
                            ...reviewStep,
                            projectApprovals: action.result,
                        });
                    }

                    return item;
                })
            );
        }
        case approvalActions.UPDATE_APPROVERS_FAIL:
        case approvalActions.UPDATE_FAIL:
            return state.merge(
                fromJS({
                    updating: false,
                    updateError: action.error && action.error.message,
                })
            );
        case approvalActions.REMOVE:
        case approvalActions.REMOVE_FAIL:
        case approvalActions.REMIND:
        case approvalActions.REMIND_SUCCESS:
        case approvalActions.REMIND_FAIL: {
            return state.set(
                'list',
                state.get('list').map((item) => {
                    const reviewStep = item.toJS();

                    if (reviewStep.id === action.reviewStepId) {
                        return fromJS({
                            ...reviewStep,
                            projectApprovals: reviewStep.projectApprovals.map((approval) =>
                                approvalReducer(fromJS(approval), action)
                            ),
                        });
                    }

                    return item;
                })
            );
        }
        case approvalActions.REMOVE_SUCCESS:
            return state.set(
                'list',
                state.get('list').map((item) => {
                    const reviewStep = item.toJS();

                    if (reviewStep.id === action.reviewStepId) {
                        const updatedProjectApprovals = reviewStep.projectApprovals.filter(
                            (approval) => approval.id !== action.approvalId
                        );

                        return fromJS({
                            ...reviewStep,
                            projectApprovals: updatedProjectApprovals,
                        });
                    }

                    return item;
                })
            );
        case approvalActions.REMOVE_REVIEW_STEP:
        case approvalActions.REMOVE_REVIEW_STEP_FAIL: {
            return state.set(
                'list',
                state.get('list').map((item) => {
                    const reviewStep = item.toJS();

                    if (reviewStep.id === action.reviewStepId) {
                        return approvalReducer(fromJS(reviewStep), action);
                    }

                    return item;
                })
            );
        }
        case approvalActions.REMOVE_REVIEW_STEP_SUCCESS: {
            return state.set(
                'list',
                state
                    .get('list')
                    .filter((reviewStep) => reviewStep.get('id') !== action.reviewStepId)
            );
        }
        case approvalActions.REPLACE_PROJECT_APPROVALS_SUCCESS: {
            return state.set('list', fromJS(action.result));
        }
        case approvalActions.SHOW_MODAL:
            return state.merge(
                fromJS({
                    showModal: true,
                    modalType: action.approvalType,
                    modalData: action.data,
                })
            );
        case approvalActions.HIDE_MODAL:
            return state.merge(
                fromJS({
                    showModal: false,
                    modalType: null,
                    modalData: null,
                    updateError: null,
                })
            );
        case approvalActions.RESET:
            return initialState;
        default:
            return state;
    }
}

import { fromJS, Map } from 'immutable';

import * as accountPersonalActions from '../actions/account/personal';

const initialState = new Map({
    saving: false,
});

export default function accountPersonalReducer(state = initialState, action = {}) {
    switch (action.type) {
        case accountPersonalActions.SAVE:
            return state.set('saving', true);
        case accountPersonalActions.SAVE_SUCCESS:
            return state.delete('error').set('saving', false);
        case accountPersonalActions.SAVE_FAIL:
            return state.merge(
                fromJS({
                    saving: false,
                    error: action.error && action.error.message,
                })
            );
        case accountPersonalActions.UPLOAD_AVATAR:
            return state.merge(
                fromJS({
                    uploadingAvatar: true,
                    uploadAvatarError: null,
                })
            );
        case accountPersonalActions.UPLOAD_AVATAR_SUCCESS:
            return state.merge(
                fromJS({
                    uploadingAvatar: false,
                })
            );
        case accountPersonalActions.UPLOAD_AVATAR_FAIL:
            return state.merge(
                fromJS({
                    uploadingAvatar: false,
                    uploadAvatarError: action.error && action.error.message,
                })
            );
        default:
            return state;
    }
}

import { get, includes, sortBy } from 'lodash';
import { createSelector } from 'reselect';

import { buildMap, isOpenGovAdmin as isOpenGovAdminHelper } from '@og-pro/shared-config/helpers';
import { sectionTypeNames } from '@og-pro/shared-config/sections';
import { tagTypesDict } from '@og-pro/shared-config/tags';
import { basicUserRoles, userRolePermissions } from '@og-pro/shared-config/userRoles';
import { userStatusTypes } from '@og-pro/shared-config/users';
import { questionLogicLogicableModelNames } from '@og-pro/shared-config/questionLogics';
import { shouldHideQuestionLogicItem } from '@og-pro/shared-config/questionLogics/utils';

import { generateOptions } from '../../helpers';
import { getIncompleteVendorDatumKeys } from '../../constants/requiredVendorDatum';

const { PRICING } = sectionTypeNames;

const { ACTIVE, INVITED, REQUESTED, RESET } = userStatusTypes;

const {
    IS_BID_AMENDER,
    IS_BID_POSTER,
    IS_CONTRACT_ADMIN,
    IS_DEPARTMENT_BID_AMENDER,
    IS_DEPARTMENT_BID_POSTER,
    IS_DEPARTMENT_CONTRACT_ADMIN,
    IS_DEPARTMENT_EDITOR,
    IS_GLOBAL_EDITOR,
    IS_GLOBAL_VIEWER,
    IS_PROJECT_CREATOR,
    IS_REQUISITION_GLOBAL_EDITOR,
    IS_REQUISITION_GROUP_ADMIN,
    IS_RETENTION_ADMIN,
    IS_SYSTEM_ADMIN,
    IS_TEMPLATE_ADMIN,
    IS_VENDOR_ADMIN,
} = userRolePermissions;

const { ADMIN, BATMAN } = basicUserRoles;

const getContract = (state) => state.contracts.get('contract');
const getContracts = (state) => state.contracts.get('contracts');
const getContractProcurementContacts = (state) =>
    state.contracts.get('contractProcurementContacts');
const getUser = (state) => state.auth.get('user');
const getUsers = (state) => state.admin.get('users');
const getDepartments = (state) => state.auth.getIn(['user', 'organization', 'departments']);
const getGovernment = (state) => state.publicProject.get('government');
const getProcuratedVendor = (state) => state.ratings.get('procuratedVendor');
const getProcuratedVendorList = (state) => state.ratings.get('procuratedVendorList');
const getProjects = (state) => state.projects.get('projects');
const getPublicProject = (state) => state.publicProject.get('project');
const getReverseAuctionItems = (state) => state.reverseAuctions.get('reverseAuctionItems');
const getQuestions = (state) => state.questions.get('questions');
const getTags = (state) => state.tags.get('tags');

export const getShowConnectionAlert = (state) => !!state.notification.get('showConnectionAlert');
export const getTimezone = (state) => state.auth.getIn(['user', 'organization', 'timezone']);

export const isInitialClientLoaded = (state) => state.app.get('initialClientRenderComplete');
export const isTinyMceModalOpenSelector = (state) => state.app.get('isTinyMceModalOpen');
export const getIsAuctionEnded = (state) => state.reverseAuctions.get('auctionEnded');

export const getTagsJS = createSelector([getTags], (rawTags) => {
    if (rawTags) {
        return rawTags.toJS();
    }

    return [];
});

export const getContractJS = createSelector([getContract], (rawContract) => {
    if (rawContract) {
        return rawContract.toJS();
    }
});

export const getContractsJS = createSelector([getContracts], (rawContracts) => {
    if (rawContracts) {
        return rawContracts.toJS();
    }

    return [];
});

export const getContractProcurementContactsJS = createSelector(
    [getContractProcurementContacts],
    (contractProcurementContacts) => {
        if (contractProcurementContacts) {
            return contractProcurementContacts.toJS();
        }

        return [];
    }
);

export const getContractTagsJS = createSelector([getTagsJS], (tags) => {
    return tags.filter((tag) => tag.type === tagTypesDict.CONTRACT);
});

export const getContractTagSelectOptions = createSelector([getContractTagsJS], (contractTags) =>
    generateOptions(contractTags, 'name', 'id')
);

export const getContractAttachmentTagsJS = createSelector([getTagsJS], (tags) => {
    return tags.filter((tag) => tag.type === tagTypesDict.CONTRACT_ATTACHMENT);
});

export const getContractAttachmentTagSelectOptions = createSelector(
    [getContractAttachmentTagsJS],
    (contractAttachmentTags) => generateOptions(contractAttachmentTags, 'name', 'id')
);

export const getContactTagsJS = createSelector([getTagsJS], (tags) => {
    return tags.filter((tag) => tag.type === tagTypesDict.CONTACT);
});

export const getContactSelectOptions = createSelector([getContactTagsJS], (contactTags) =>
    generateOptions(contactTags, 'name', 'id')
);

export const getProcurementContactSelectOptions = createSelector(
    [getContractProcurementContactsJS],
    (contractProcurementContacts) => {
        const procurementContacts = contractProcurementContacts.map((contact) => {
            return {
                contactId: contact.procurement_contact_id,
                displayName: contact.procurementContact.displayName,
            };
        });

        return generateOptions(
            sortBy(procurementContacts, 'displayName'),
            'displayName',
            'contactId'
        );
    }
);

export const getContractInsuranceTagsJS = createSelector([getTagsJS], (tags) => {
    return tags.filter((tag) => tag.type === tagTypesDict.CONTRACT_INSURANCE);
});

export const getContractInsuranceSelectOptions = createSelector(
    [getContractInsuranceTagsJS],
    (contractInsuranceTags) => generateOptions(contractInsuranceTags, 'name', 'id')
);

export const getFundingSourceTagsJS = createSelector([getTagsJS], (tags) => {
    return tags.filter((tag) => tag.type === tagTypesDict.FUNDING_SOURCE);
});

export const getFundingSourceTagSelectOptions = createSelector(
    [getFundingSourceTagsJS],
    (fundingSourceTags) => generateOptions(fundingSourceTags, 'name', 'id')
);

export const getDeliveryCodeTagsJS = createSelector([getTagsJS], (tags) => {
    return tags.filter((tag) => tag.type === tagTypesDict.DELIVERY_CODE);
});

export const getDeliveryCodeSelectOptions = createSelector(
    [getDeliveryCodeTagsJS],
    (deliveryCodeTags) => generateOptions(deliveryCodeTags, 'name', 'name')
);

export const getFiscalYearTagsJS = createSelector([getTagsJS], (tags) => {
    return tags.filter((tag) => tag.type === tagTypesDict.FISCAL_YEAR);
});

export const getFiscalYearSelectOptions = createSelector([getFiscalYearTagsJS], (fiscalYearTags) =>
    generateOptions(fiscalYearTags, 'name', 'id')
);

export const getPaymentTermsTagsJS = createSelector([getTagsJS], (tags) => {
    return tags.filter((tag) => tag.type === tagTypesDict.PAYMENT_TERMS);
});

export const getPaymentTermsSelectOptions = createSelector(
    [getPaymentTermsTagsJS],
    (paymentTermsTags) => generateOptions(paymentTermsTags, 'name', 'name')
);

export const getProcuratedVendorJS = createSelector(
    [getProcuratedVendor],
    (rawProcuratedVendor) => {
        if (rawProcuratedVendor) {
            return rawProcuratedVendor.toJS();
        }
    }
);

export const getProcuratedVendorListJS = createSelector(
    [getProcuratedVendorList],
    (rawProcuratedVendorList) => {
        if (rawProcuratedVendorList) {
            return rawProcuratedVendorList.toJS();
        }
    }
);

export const getProcurementClassificationTagsJS = createSelector([getTagsJS], (tags) => {
    return tags.filter((tag) => tag.type === tagTypesDict.PROCUREMENT_CLASSIFICATION);
});

export const getProcurementClassificationSelectOptions = createSelector(
    [getProcurementClassificationTagsJS],
    (procurementClassificationTags) => generateOptions(procurementClassificationTags, 'name', 'id')
);

export const getProjectsJS = createSelector([getProjects], (rawProjects) => {
    if (rawProjects) {
        return rawProjects.toJS();
    }
    return [];
});

export const getReverseAuctionItemsJS = createSelector(
    [getReverseAuctionItems],
    (reverseAuctionItems) => (reverseAuctionItems ? reverseAuctionItems.toJS() : [])
);

export const getUserJS = createSelector([getUser], (user) => {
    if (user) {
        return user.toJS();
    }
});

export const getUserOrganizationTimezone = createSelector([getUserJS], (user) => {
    return get(user, 'organization.timezone') || null;
});

export const getUsersJS = createSelector([getUsers], (rawUsers) => {
    if (rawUsers) {
        return rawUsers.toJS().filter((user) => user.status !== REQUESTED);
    }
    return [];
});

export const getUsersRequestingAccessJS = createSelector([getUsers], (rawUsers) => {
    if (rawUsers) {
        return rawUsers.toJS().filter((user) => user.status === REQUESTED);
    }
    return [];
});

export const getActiveUsersJS = createSelector([getUsersJS], (users) => {
    return users.filter((user) => {
        return (user.status === ACTIVE || user.status === RESET) && user.fullName;
    });
});

export const getInvitedUsersJS = createSelector([getUsersJS], (users) => {
    const selectableStatuses = [ACTIVE, RESET, INVITED];
    return users.filter((user) => includes(selectableStatuses, user.status));
});

export const getActiveUsersSelectOptions = createSelector([getActiveUsersJS], (users) => {
    return users.map((user) => {
        return {
            label: user.displayName,
            user,
            value: user.id,
        };
    });
});

export const getInvitedUsersSelectOptions = createSelector([getInvitedUsersJS], (users) => {
    return users.map((user) => {
        return {
            label: user.fullName || `${user.email} (not activated)`,
            user,
            value: user.id,
        };
    });
});

export const isSystemAdminUser = createSelector([getUserJS], (user) => {
    if (user && user.isVendor) {
        return user.role === ADMIN || user.role === BATMAN;
    }
    return get(user, ['userPermissions', IS_SYSTEM_ADMIN]) || false;
});

export const isContractAdminUser = createSelector([getUserJS], (user) => {
    return get(user, ['userPermissions', IS_CONTRACT_ADMIN]) || false;
});

export const isProjectCreatorUser = createSelector([getUserJS], (user) => {
    return get(user, ['userPermissions', IS_PROJECT_CREATOR]) || false;
});

export const isDepartmentContractAdminUser = createSelector([getUserJS], (user) => {
    return get(user, ['userPermissions', IS_DEPARTMENT_CONTRACT_ADMIN]) || false;
});

export const isDepartmentEditorUser = createSelector([getUserJS], (user) => {
    return get(user, ['userPermissions', IS_DEPARTMENT_EDITOR]) || false;
});

export const isGlobalEditorUser = createSelector([getUserJS], (user) => {
    return get(user, ['userPermissions', IS_GLOBAL_EDITOR]) || false;
});

export const isGlobalViewerUser = createSelector([getUserJS], (user) => {
    return get(user, ['userPermissions', IS_GLOBAL_VIEWER]) || false;
});

export const isBidPosterUser = createSelector([getUserJS], (user) => {
    return get(user, ['userPermissions', IS_BID_POSTER]) || false;
});

export const isBidAmenderUser = createSelector([getUserJS], (user) => {
    return get(user, ['userPermissions', IS_BID_AMENDER]) || false;
});

export const isDepartmentBidPosterUser = createSelector([getUserJS], (user) => {
    return get(user, ['userPermissions', IS_DEPARTMENT_BID_POSTER]) || false;
});

export const isDepartmentBidAmenderUser = createSelector([getUserJS], (user) => {
    return get(user, ['userPermissions', IS_DEPARTMENT_BID_AMENDER]) || false;
});

export const isRequisitionGlobalEditorUser = createSelector([getUserJS], (user) => {
    return get(user, ['userPermissions', IS_REQUISITION_GLOBAL_EDITOR]) || false;
});

export const isRequisitionGroupAdminUser = createSelector([getUserJS], (user) => {
    return get(user, ['userPermissions', IS_REQUISITION_GROUP_ADMIN]) || false;
});

export const isRetentionAdminUser = createSelector([getUserJS], (user) => {
    return get(user, ['userPermissions', IS_RETENTION_ADMIN]) || false;
});

export const isTemplateAdminUser = createSelector([getUserJS], (user) => {
    return get(user, ['userPermissions', IS_TEMPLATE_ADMIN]) || false;
});

export const isVendorAdminUser = createSelector([getUserJS], (user) => {
    return get(user, ['userPermissions', IS_VENDOR_ADMIN]) || false;
});

export const getDepartmentsJS = createSelector(
    [getDepartments, getUsersJS],
    (rawDepartments, users) => {
        if (rawDepartments) {
            const usersMap = buildMap(users, 'id');
            return rawDepartments.toJS().map((department) => {
                return {
                    ...department,
                    contact: usersMap[department.contact_id],
                };
            });
        }
        return [];
    }
);

export const getDepartmentsSelectOptions = createSelector([getDepartmentsJS], (departments) =>
    generateOptions(departments, 'name', 'id')
);

export const getPublicProjectJS = createSelector([getPublicProject], (rawProject) => {
    if (rawProject) {
        return rawProject.toJS();
    }
});

export const isSubscribedToProject = createSelector(
    [getUserJS, getPublicProjectJS],
    (user, project) => {
        if (user && project) {
            return project.followers.some((follower) => follower.id === user.id);
        }
        return false;
    }
);

export const getQuestionsJS = createSelector([getQuestions], (rawQuestions) => {
    return rawQuestions.toJS();
});

export const generateCompleteProposal = (proposal, project, proposalDocuments) => {
    if (!proposal || !project) {
        return;
    }

    const {
        questionnaireResponses,
        submittedAt: submittedAtString,
        vendorPriceItems,
        ...rest
    } = proposal;

    const vendorPriceItemsMap = buildMap(vendorPriceItems, 'price_item_id');
    const questionnaireResponsesMap = buildMap(questionnaireResponses, 'questionnaire_id');

    const hasPricingSection = !!project.projectSections.find((projectSection) => {
        return (
            projectSection.section_type === PRICING &&
            !projectSection.isHidden &&
            !projectSection.isHiddenByLogic
        );
    });

    const priceTables = !hasPricingSection
        ? []
        : project.priceTables.map((priceTable) => {
              const priceItems = priceTable.priceItems.map((priceItem) => {
                  const vendorPriceItem = vendorPriceItemsMap[priceItem.id];

                  if (!vendorPriceItem) {
                      return priceItem;
                  }

                  return {
                      ...priceItem,
                      vendorResponse: vendorPriceItem,
                  };
              });

              return {
                  ...priceTable,
                  priceItems,
              };
          });

    const questionnaires = project.questionnaires
        .map((questionnaire) => {
            const questionnaireResponse = questionnaireResponsesMap[questionnaire.id];

            if (!questionnaireResponse) {
                return questionnaire;
            }

            return {
                ...questionnaire,
                questionnaireResponse,
            };
        })
        // set the default values for isHiddenByLogic based on the responses
        .map((questionnaire, i, array) => {
            if (
                !questionnaire.isConditionalSubQuestion ||
                questionnaire.questionLogic?.logicable !==
                    questionLogicLogicableModelNames.QUESTIONNAIRE
            ) {
                return questionnaire;
            }

            const parentQuestion = array.find(
                (question) => question.id === questionnaire.questionLogic.logicable_id
            );

            if (!parentQuestion) {
                return questionnaire;
            }

            return {
                ...questionnaire,
                isHiddenByLogic: shouldHideQuestionLogicItem(
                    questionnaire.questionLogic,
                    parentQuestion
                ),
            };
        });

    const submittedAt = submittedAtString && new Date(submittedAtString);

    return {
        ...rest,
        proposalDocuments,
        priceTables,
        questionnaires,
        submittedAt,
    };
};

export const generateProjectSectionTagOptions = (project) => {
    if (!project || !project.projectSections) {
        return [];
    }

    const projectOptions = [
        {
            label: 'Public Project URL',
            linkOnly: true,
            value: `${process.env.SITE_URL}/portal/${project.government.code}/projects/${project.id}`,
        },
        {
            label: 'Procurement Portal URL',
            linkOnly: true,
            value: `${process.env.SITE_URL}/portal/${project.government.code}`,
        },
    ];

    return [
        {
            label: 'Project Links',
            options: projectOptions,
        },
        {
            label: 'Project Section Links',
            options: project.projectSections.map((projectSection) => {
                return {
                    label: projectSection.title,
                    value: `${process.env.SITE_URL}/portal/${project.government.code}/projects/${project.id}/document?section=${projectSection.id}`,
                };
            }),
        },
    ];
};

export const isOpenGovAdmin = createSelector([getUserJS], (user) => isOpenGovAdminHelper(user));

export const getPublicGovernmentJS = createSelector(
    [getGovernment, getPublicProjectJS],
    (rawGovernment, publicProjectJS) => {
        if (rawGovernment) {
            return rawGovernment.toJS();
        }
        if (publicProjectJS) {
            return publicProjectJS.government;
        }
    }
);

export const getIncompleteRequiredVendorDatumKeysJS = createSelector(
    [getPublicGovernmentJS, getUserJS],
    (government, user) => {
        if (government && user) {
            return getIncompleteVendorDatumKeys(government, user.vendor);
        }
    }
);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Panel } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Outlet } from 'react-router-dom';

import { getUserJS } from '../../selectors';
import { awardsFilters } from './constants';
import { Button, ButtonGroup, DashboardControlNav } from '../../../components';

const mapStateToProps = (state) => ({
    user: getUserJS(state),
});

class ConnectedAwards extends Component {
    static propTypes = {
        user: PropTypes.object.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    get awardsPath() {
        return `/vendors/${this.props.user.vendor_id}/awards`;
    }

    renderFilterButtons() {
        const filterButtons = awardsFilters.map((btn) => (
            <Button
                className={`filter-${btn.key} ${this.styles.filterBtn}`}
                key={btn.key}
                to={`${this.awardsPath}/${btn.key}`}
            >
                <i className={`fa fa-fw fa-${btn.icon}`} /> {btn.text}
            </Button>
        ));

        return (
            <div>
                <strong>Find Awards</strong>
                <ButtonGroup block vertical>
                    {filterButtons}
                </ButtonGroup>
            </div>
        );
    }

    renderSideBar() {
        return (
            <DashboardControlNav logoUrl={this.props.user.organization.logo}>
                {this.renderFilterButtons()}
            </DashboardControlNav>
        );
    }

    render() {
        return (
            <div className="row">
                <h1 className="visually-hidden">Awards</h1>
                <div className="col-md-3">{this.renderSideBar()}</div>
                <div className="col-md-9">
                    <Panel className={this.styles.awardsContainer}>
                        <Outlet />
                    </Panel>
                </div>
            </div>
        );
    }
}

export const Awards = compose(connect(mapStateToProps))(ConnectedAwards);

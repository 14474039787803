import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { RequisitionGroupCreateButton } from './RequisitionGroupCreateButton';
import { RequisitionGroupsList as RequisitionGroupsListComponent } from './RequisitionGroupsList';
import {
    getNavItems,
    getRequisitionGroups,
    getSelectedNavItem,
    getSelectedRequisitionGroups,
} from './selectors';
import connectData from '../../../ConnectData';
import {
    loadRequisitionGroups,
    resetRequisitionGroups,
} from '../../../../actions/requisitionGroups';
import {
    LoadingError,
    LoadingSpinner,
    Main,
    PageTitle,
    TemplateListNav,
} from '../../../../components';

function fetchData(getState, dispatch, location, params) {
    const requestTypeId = params.requestTypeId && Number.parseInt(params.requestTypeId, 10);
    if (requestTypeId) {
        return dispatch(loadRequisitionGroups({ requestTypeId }));
    }
    if (getState().requisitionGroups.get('loadedGroups')) {
        return Promise.resolve();
    }
    return dispatch(loadRequisitionGroups());
}

// @connectData
const ConnectedRequisitionGroupsList = () => {
    const baseLocation = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const location = { ...baseLocation, query: Object.fromEntries(searchParams) };

    const isFilteredByRequestType = params.requestTypeId !== undefined;

    const loading = useSelector((state) => state.requisitionGroups.get('loadingGroups'));
    const loadError = useSelector((state) => state.requisitionGroups.get('loadGroupsError'));
    const reviewGroupsPath = isFilteredByRequestType
        ? `/governments/${params.governmentId}/requisitions-admin/review-groups`
        : baseLocation.pathname.replace(/\/$/, ''); // Remove trailing slash if it exists
    const allGroups = useSelector(getRequisitionGroups);
    const navItems = useSelector((state) => getNavItems(state, { location, params }));
    const selectedNavItem = useSelector((state) => getSelectedNavItem(state, { location, params }));
    const selectedRequisitionGroups = useSelector((state) =>
        getSelectedRequisitionGroups(state, { location, params })
    );

    const dispatch = useDispatch();

    useEffect(() => {
        return () => dispatch(resetRequisitionGroups());
    }, []);

    if (loading) {
        return <LoadingSpinner useOpenGovStyle />;
    }

    if (loadError) {
        return <LoadingError error={loadError} useOpenGovStyle />;
    }

    const routeToReviewGroup = (requisitionGroupId) => {
        navigate(`${reviewGroupsPath}/${requisitionGroupId}`);
    };

    const RequisitionGroupCreateButtonFn = (
        { size } // eslint-disable-line react/prop-types
    ) => {
        if (isFilteredByRequestType) {
            return null;
        }
        return <RequisitionGroupCreateButton routingHandler={routeToReviewGroup} size={size} />;
    };

    const hasNoCreatedGroups = allGroups.length === 0 && !isFilteredByRequestType;

    return (
        <Main>
            <PageTitle title="Request Groups" />
            <TemplateListNav
                TemplateCreateButton={RequisitionGroupCreateButtonFn}
                location={location}
                navItems={navItems}
                selectedNavItem={selectedNavItem}
                title="Request Groups"
                useOpenGovStyle
            />
            <RequisitionGroupsListComponent
                RequisitionGroupCreateButton={RequisitionGroupCreateButtonFn}
                hasNoCreatedGroups={hasNoCreatedGroups}
                requisitionGroups={selectedRequisitionGroups}
                routingHandler={routeToReviewGroup}
            />
        </Main>
    );
};

export const RequisitionGroupsList = connectData(fetchData)(ConnectedRequisitionGroupsList);

import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import ReactToggle from 'react-toggle';
import { DropdownButton } from 'react-bootstrap';
import { Box } from '@og-pro/ui';

import { timelineDatesDict } from '@og-pro/shared-config/timelines';

import connectData from '../../../ConnectData';
import { getProjectJS, isBuilderEditor, isSourcingEditor } from '../../selectors';
import { getTimezone } from '../../../selectors';
import { getTimelinesWithRsvpsJS } from '../selectors';
import {
    confirmVendorRsvp,
    govAddVendorRsvp,
    loadTimelinesWithRsvps,
    toggleRsvpEnded,
    vendorRsvpHandler,
} from '../../../../actions/rsvps';
import { showVendorProfileModal } from '../../../../actions/vendorProfile';
import { dateTZFormatter } from '../../../../helpers';
import { form } from './constants';
import {
    DataTable,
    HelpBlock,
    LoadingError,
    LoadingSpinner,
    Main,
    MenuItem,
    NoItems,
    ProcuratedBadge,
    SectionTitle,
    Tooltip,
    UserProfilePicture,
} from '../../../../components';
import { RsvpAddVendorForm } from './RsvpAddVendorForm';

const { PRE_PROPOSAL_DATE } = timelineDatesDict;

const LAST_ROW_DROPDOWN_OPEN = 'lastRowDropdownOpen';

const fetchData = (getState, dispatch, location, params) => {
    const projectId = Number.parseInt(params.projectId, 10);
    return dispatch(loadTimelinesWithRsvps(projectId));
};

const mapStateToProps = (state) => {
    return {
        canEdit: isBuilderEditor(state) || isSourcingEditor(state),
        confirmingRsvp: state.rsvps.get('confirmingRsvp'),
        confirmingRsvpError: state.rsvps.get('confirmingRsvpError'),
        loadError: state.rsvps.get('loadError'),
        loading: state.rsvps.get('loading'),
        project: getProjectJS(state),
        rsvpingVendor: state.rsvps.get('rsvpingVendor'),
        timelinesWithRsvps: getTimelinesWithRsvpsJS(state),
        timezone: getTimezone(state),
        togglingRsvp: state.rsvps.get('togglingRsvpEnded'),
    };
};

const mapDispatchToProps = {
    confirmVendorRsvp,
    govAddVendorRsvp,
    showVendorProfileModal,
    toggleRsvpEnded,
    vendorRsvpHandler,
};

// @connectData
// @connect
class ConnectedProjectRsvpManager extends Component {
    static propTypes = {
        canEdit: PropTypes.bool.isRequired,
        confirmingRsvp: PropTypes.bool,
        confirmingRsvpError: PropTypes.string,
        confirmVendorRsvp: PropTypes.func.isRequired,
        govAddVendorRsvp: PropTypes.func.isRequired,
        loadError: PropTypes.string,
        loading: PropTypes.bool.isRequired,
        project: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }).isRequired,
        rsvpingVendor: PropTypes.bool.isRequired,
        showVendorProfileModal: PropTypes.func.isRequired,
        timelinesWithRsvps: PropTypes.arrayOf(
            PropTypes.shape({
                date: PropTypes.string.isRequired,
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
                isAttendanceRequired: PropTypes.bool.isRequired,
                isRsvpEnded: PropTypes.bool.isRequired,
                orderById: PropTypes.number.isRequired,
                title: PropTypes.string.isRequired,
                vendorRsvps: PropTypes.array,
            })
        ).isRequired,
        timezone: PropTypes.string.isRequired,
        toggleRsvpEnded: PropTypes.func.isRequired,
        togglingRsvpEnded: PropTypes.bool,
        vendorRsvpHandler: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {};
    }

    get styles() {
        return require('./index.scss');
    }

    get columns() {
        const { timezone } = this.props;

        return [
            {
                Header: 'Vendor',
                accessor: 'rsvpUser.organization.name',
                Cell: this.renderVendorCell,
            },
            {
                Header: "RSVP'd At",
                accessor: 'created_at',
                className: 'text-center',
                Cell: (props) => dateTZFormatter(timezone, 'lll')(props),
            },
            {
                Header: 'Attendance Updated By',
                accessor: 'confirmingUser.displayName',
                className: 'text-center',
                Cell: this.renderConfirmingUserCell,
            },
            {
                Header: 'Attendance Updated At',
                accessor: 'attendanceUpdatedAt',
                className: 'text-center',
                Cell: (props) => dateTZFormatter(timezone, 'lll')(props),
            },
            {
                Header: 'Attendance',
                accessor: 'attendanceConfirmed',
                className: `text-center ${this.styles.attendanceCell}`,
                Cell: this.renderAttendanceCell,
            },
        ];
    }

    toggleShowAddVendorForm = (id) => {
        if (this.state.showAddVendorForm[id]) {
            return this.setState({ showAddVendorForm: { [id]: false } });
        }
        return this.setState({ showAddVendorForm: { [id]: true } });
    };

    formatDataForCSVExport = (data) => {
        const { timezone } = this.props;

        const getAttendanceConfirmed = (value) => {
            switch (value) {
                case true:
                    return 'Present';
                case false:
                    return 'Not Present';
                default:
                    return 'Unconfirmed';
            }
        };

        const headers = [
            'Vendor',
            'Email',
            "RSVP'd At",
            'Attendance Updated By',
            'Attendance Updated At',
            'Attendance',
        ];

        const rows = data.map((dataRow) => {
            const govAddedVendorName = dataRow._original.govAddedVendorName; // eslint-disable-line no-underscore-dangle
            return [
                dataRow['rsvpUser.organization.name'] || govAddedVendorName,
                govAddedVendorName ? 'No Email' : dataRow._original.rsvpUser.email, // eslint-disable-line no-underscore-dangle
                dateTZFormatter(timezone, 'lll')({ value: dataRow.created_at }),
                dataRow['confirmingUser.displayName'],
                dateTZFormatter(timezone, 'lll')({ value: dataRow.attendanceUpdatedAt }) || '',
                getAttendanceConfirmed(dataRow.attendanceConfirmed),
            ];
        });

        return [headers].concat(rows);
    };

    renderVendorCell = (cellProps) => {
        const { project } = this.props;
        const { value, original } = cellProps;

        const govAddedVendorName = original.govAddedVendorName;
        const vendorId = get(original, 'rsvpUser.organization.vendor.id');
        const hasVendorPage = !!vendorId;
        const companyClassName = hasVendorPage ? 'pseudoLink' : '';
        const clickHandler = hasVendorPage
            ? () => this.props.showVendorProfileModal(vendorId)
            : undefined;

        if (govAddedVendorName) {
            return (
                <div>
                    <div className={this.styles.vendorDataCell}>
                        <div className={this.styles.vendorName}>{govAddedVendorName}</div>
                        <ProcuratedBadge supplierId={vendorId} />
                    </div>
                    <div
                        className={`text-danger ${this.styles.deleteLink}`}
                        onClick={() =>
                            this.props.vendorRsvpHandler(project.id, original.id, {
                                govAddedVendorName,
                            })
                        }
                    >
                        <i className="fa fa-times" />
                        &nbsp;Delete
                    </div>
                </div>
            );
        }

        return (
            <div>
                <div
                    className={`${companyClassName} ${this.styles.vendorName}`}
                    onClick={clickHandler}
                >
                    {value}
                </div>
                {original.rsvpUser && (
                    <div className={this.styles.vendorEmail}>{original.rsvpUser.email}</div>
                )}
            </div>
        );
    };

    renderConfirmingUserCell = (cellProps) => {
        const { value, original } = cellProps;

        if (!original.attendanceUpdatedAt) {
            return null;
        }

        return (
            <>
                <UserProfilePicture
                    className={this.styles.userProfilePicture}
                    horizontal
                    showTooltip={false}
                    user={original.confirmingUser}
                />
                {value}
            </>
        );
    };

    renderAttendanceCell = (cellProps) => {
        const {
            canEdit,
            confirmingRsvp,
            confirmingRsvpError,
            project: { id: projectId },
        } = this.props;

        const { index, original, pageSize, value } = cellProps;

        const timelineId = original.isPreProposalRsvp ? PRE_PROPOSAL_DATE : original.timeline_id;

        const getTitle = (currSelection) => {
            switch (currSelection) {
                case true:
                    return (
                        <span className="text-success">
                            <i className="fa fa-check-circle" /> Present
                        </span>
                    );
                case false:
                    return (
                        <span className="text-danger">
                            <i className="fa fa-times-circle" /> Not Present
                        </span>
                    );
                default:
                    return (
                        <span>
                            <i className="fa fa-circle-o" /> Unconfirmed
                        </span>
                    );
            }
        };

        const MenuOptions = [true, false, null].map((opt, idx) => {
            // Prevents displaying the current selection in the menu
            if (opt === value) {
                return null;
            }

            const title = getTitle(opt);

            return (
                <MenuItem
                    key={idx}
                    onClick={() =>
                        this.props.confirmVendorRsvp({
                            attendanceConfirmed: opt,
                            projectId,
                            rsvpId: original.id,
                        })
                    }
                    qaTag={`project-${title}`}
                >
                    {title}
                </MenuItem>
            );
        });

        return (
            <>
                <Tooltip
                    delayShow={500}
                    disabled={!canEdit}
                    placement="top"
                    tooltip={
                        canEdit
                            ? 'Confirm whether vendor was present or not'
                            : 'Only editors and admins can update vendor attendance'
                    }
                >
                    <DropdownButton
                        className={this.styles.attendanceBtn}
                        disabled={!canEdit || confirmingRsvp}
                        id="Attendance Status"
                        onToggle={
                            index === pageSize - 1
                                ? (isOpen) =>
                                      this.setState({
                                          [`${LAST_ROW_DROPDOWN_OPEN}[${timelineId}]`]: isOpen,
                                      })
                                : null
                        }
                        title={getTitle(value)}
                    >
                        {MenuOptions}
                    </DropdownButton>
                </Tooltip>
                {confirmingRsvpError && (
                    <HelpBlock className="text-danger">{confirmingRsvpError}</HelpBlock>
                )}
            </>
        );
    };

    renderTimelineRsvps() {
        const { canEdit, project, rsvpingVendor, timelinesWithRsvps, timezone, togglingRsvpEnded } =
            this.props;

        return timelinesWithRsvps.map((timeline, idx) => {
            const { date, id, isRsvpEnded, title, vendorRsvps } = timeline;

            const lastRowDropDownOpen = this.state[`${LAST_ROW_DROPDOWN_OPEN}[${id}]`];
            const rsvpsNum = vendorRsvps.length;

            const tableTitle = (
                <div className={this.styles.tableTitle}>
                    {title} ({rsvpsNum})
                    <span className={this.styles.timelineDate}>
                        {dateTZFormatter(timezone, 'lll')({ value: date })}
                    </span>
                </div>
            );

            const componentsInlineWithButtons = (
                <Box className={this.styles.timelineRsvpContainer} marginRight={0.5}>
                    <Tooltip
                        delayShow={500}
                        disabled={!canEdit}
                        placement="top"
                        tooltip={
                            canEdit
                                ? 'Toggle whether RSVP period is open or closed'
                                : 'Only editors and admins can update the RSVP Period'
                        }
                    >
                        <div className={this.styles.rsvpToggleBlock} key={project.id}>
                            RSVPs Allowed
                            <ReactToggle
                                aria-label="Toggle RSVPs"
                                checked={!isRsvpEnded}
                                className={this.styles.rsvpToggle}
                                disabled={!canEdit || togglingRsvpEnded}
                                onChange={() =>
                                    this.props.toggleRsvpEnded(project.id, {
                                        isRsvpEnded: !timeline.isRsvpEnded,
                                        timelineId: timeline.id,
                                    })
                                }
                            />
                        </div>
                    </Tooltip>
                    <RsvpAddVendorForm
                        form={`${form} ${idx}`}
                        govAddVendorRsvp={this.props.govAddVendorRsvp}
                        projectId={project.id}
                        rsvpingVendor={rsvpingVendor}
                        timelineId={id}
                        toggleShowAddVendorForm={() => this.toggleShowAddVendorForm(id)}
                    />
                </Box>
            );

            return (
                <Main className={this.styles.rsvpsTable} key={id}>
                    <DataTable
                        className="-striped -highlight"
                        collapsed={!rsvpsNum}
                        collapsible
                        columns={this.columns}
                        componentsInlineWithButtons={componentsInlineWithButtons}
                        csvExportOptions={{
                            fileName: `${title} Vendor RSVPs`,
                            getFormattedCSVData: this.formatDataForCSVExport,
                            headers: true,
                        }}
                        data={vendorRsvps}
                        minRows={lastRowDropDownOpen ? rsvpsNum + 1 : rsvpsNum}
                        pageSize={rsvpsNum}
                        showCSVExport
                        showPagination={false}
                        title={tableTitle}
                    />
                </Main>
            );
        });
    }

    render() {
        const { loadError, loading, timelinesWithRsvps } = this.props;

        if (loading) {
            return <LoadingSpinner />;
        }

        if (loadError) {
            return <LoadingError error={loadError} />;
        }

        if (!timelinesWithRsvps.length) {
            return (
                <NoItems
                    header="Project Does Not Have Public Meetings"
                    subheader="RSVP requires a pre-proposal meeting or custom date with public meeting option"
                />
            );
        }

        return (
            <>
                <SectionTitle
                    info="Vendor RSVPs and Attendance for Project's Public Meetings"
                    title="RSVP Manager"
                />
                {this.renderTimelineRsvps()}
            </>
        );
    }
}

export const ProjectRsvpManager = compose(
    connectData(fetchData),
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedProjectRsvpManager);

import PropTypes from 'prop-types';
import React from 'react';
import { Checkbox } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Box, Typography } from '@og-pro/ui';

import { Certifications } from './Certifications';
import { HelpIcon } from '../../../../../components';
import { updateOrganization } from '../../../../../actions/admin';

export const VendorCertificationSelfReportingForm = ({ certifications, user }) => {
    const dispatch = useDispatch();
    const styles = require('./index.scss');

    const toggleNoDbeDesignations = (e) => {
        e.preventDefault();
        dispatch(
            updateOrganization(user.organization_id, {
                vendor: { noDbeDesignations: e.target.checked },
            })
        );
    };

    return (
        <Box
            className={styles.vendorCertificationSelfReportingForm}
            display="flex"
            flexDirection="column"
        >
            <Typography variant="h3">Self-Reported Certifications</Typography>
            <div>
                <Certifications certifications={certifications} user={user} />
                {certifications?.length === 0 && (
                    <Box alignItems="center" display="flex" px={2} py={0.5}>
                        <Checkbox
                            checked={user.vendor.noDbeDesignations}
                            className={styles.checkbox}
                            data-qa="vendorCertifications-checkbox-noDbeDesignations"
                            onClick={toggleNoDbeDesignations}
                        >
                            I do not want to add certifications at this time or none of these apply
                            to my business.
                        </Checkbox>
                        <HelpIcon
                            className={styles.icon}
                            icon="fa-question-circle"
                            tooltip="Some agencies will require certifications to be complete. Use this option to indicate none apply to your company."
                        />
                    </Box>
                )}
            </div>
        </Box>
    );
};

VendorCertificationSelfReportingForm.propTypes = {
    certifications: PropTypes.array.isRequired,
    user: PropTypes.shape({
        organization_id: PropTypes.number.isRequired,
        vendor: PropTypes.shape({
            id: PropTypes.number.isRequired,
            noDbeDesignations: PropTypes.bool,
        }),
    }).isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import { tokens } from '@opengov/capital-style';
import { Box } from '@og-pro/ui';

import { HtmlContent } from '../../../../../components';

const { colors } = tokens;

export const ScopeItem = ({ description }) => {
    const { colorGray200, colorWhite } = colors;

    return (
        <Box
            bgcolor={colorWhite}
            border={1}
            borderColor={colorGray200}
            borderRadius={3}
            display="inline-block"
            flex={1}
            mb={2}
            p={4}
            w="100%"
        >
            <HtmlContent content={description} />
        </Box>
    );
};

ScopeItem.propTypes = {
    description: PropTypes.string.isRequired,
};

import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { RequisitionsSearchOption } from './RequisitionsSearchOption';
import { formatOptions } from './helpers';
import { SearchSelect } from '..';
import { basicRequisitionsSearch } from '../../actions/requisitionsSearch';

export const RequisitionsSearchInput = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const { components, getOptions, searchData, ...propsForSearchSelect } = props;

    const dispatch = useDispatch();

    const searchRequisitions = async (data) => {
        try {
            setIsLoading(true);
            const { requisitions } = await dispatch(
                basicRequisitionsSearch({
                    search: data,
                    limit: 20,
                    page: 1,
                    ...searchData,
                })
            );

            const formattedOptions = formatOptions(requisitions);
            setOptions(getOptions ? getOptions(formattedOptions) : formattedOptions);
        } finally {
            setIsLoading(false);
        }
    };

    const debouncedSearchRequisitions = debounce(searchRequisitions, 500);

    useEffect(() => {
        searchRequisitions();
    }, []);

    const onInputChange = (data, { action }) => {
        // Only load requisitions when input is changed (skips blur and selection changes)
        if (action === 'input-change') {
            debouncedSearchRequisitions(data);
        }

        return data;
    };

    const loadRequisitionsOnClear = (data) => {
        // Data will be null when form has been cleared
        if (data === null) {
            debouncedSearchRequisitions(data);
        }
    };

    return (
        <SearchSelect
            components={{
                Option: RequisitionsSearchOption,
                ...components,
            }}
            isClearable
            isLoading={isLoading}
            noOptionsMessage={() => (isLoading ? 'Loading...' : 'No results found')}
            onChangeWithOption={loadRequisitionsOnClear}
            onInputChange={onInputChange}
            options={isLoading ? [] : options}
            overrideFeedback
            {...propsForSearchSelect}
        />
    );
};

RequisitionsSearchInput.propTypes = {
    components: PropTypes.object,
    getOptions: PropTypes.func,
    searchData: PropTypes.object,
};

import { capitalDesignTokens } from '@opengov/capital-mui-theme';

export const actionPanelNavContainerStyle = {
    bgcolor: 'rgba(247, 249, 254, 1)',
    borderBottom: 1,
    borderColor: capitalDesignTokens.foundations.colors.gray200,
    pb: 0.5,
    pt: 0.75,
    px: 3,
    zIndex: 1,
};

export const jumpToStyle = {
    color: capitalDesignTokens.foundations.colors.gray700,
    fontSize: capitalDesignTokens.foundations.typography.fontSize.bodyXSmall,
};

export const counterStyle = {
    bgcolor: capitalDesignTokens.foundations.colors.gray100,
};

export const sectionButtonStyle = {
    color: capitalDesignTokens.foundations.colors.gray800,
};

import { PROMPT } from './constants';

export const validate = (values) => {
    const errors = {};

    if (!values[PROMPT] || !values[PROMPT]?.trim()) {
        errors[PROMPT] = 'Please enter a prompt.';
    }

    return errors;
};

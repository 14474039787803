import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Field } from 'redux-form';

import { contactFieldNames } from '@og-pro/shared-config/contacts';

import { InputText } from '../..';

const { FIRST_NAME, LAST_NAME, TITLE } = contactFieldNames;

export class ContactName extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        fieldNames: PropTypes.object.isRequired,
        optional: PropTypes.bool,
        showValidation: PropTypes.bool,
    };

    static defaultProps = {
        disabled: false,
        optional: false,
        showValidation: false,
    };

    render() {
        const { disabled, fieldNames, optional, showValidation } = this.props;

        return (
            <>
                <Field
                    component={InputText}
                    disabled={disabled}
                    hasFeedback={false}
                    label="First Name"
                    name={fieldNames[FIRST_NAME]}
                    placeholder={optional ? 'Optional' : 'Required'}
                    showValidation={showValidation}
                    type="text"
                />
                <Field
                    component={InputText}
                    disabled={disabled}
                    hasFeedback={false}
                    label="Last Name"
                    name={fieldNames[LAST_NAME]}
                    placeholder={optional ? 'Optional' : 'Required'}
                    showValidation={showValidation}
                    type="text"
                />
                <Field
                    component={InputText}
                    disabled={disabled}
                    hasFeedback={false}
                    label="Job Title"
                    name={fieldNames[TITLE]}
                    placeholder={optional ? 'Optional' : 'Required'}
                    showValidation={showValidation}
                    type="text"
                />
            </>
        );
    }
}

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Field } from 'redux-form';

import { STAGED_EVALUATORS } from '../../constants';
import { Button, SearchSelect, SearchSelectUserOption } from '../../../../../components';

export class AddEvaluatorsForm extends PureComponent {
    static propTypes = {
        clickHandler: PropTypes.func.isRequired,
        disabled: PropTypes.bool.isRequired,
        usersSelectOptions: PropTypes.array.isRequired,
    };

    get styles() {
        return require('./AddEvaluatorsForm.scss');
    }

    render() {
        const { clickHandler, disabled, usersSelectOptions } = this.props;

        return (
            <div className="row">
                <div className="col-sm-8 col-lg-7 col-lg-offset-2">
                    <Field
                        aria-label="Select Evaluators"
                        closeMenuOnSelect={false}
                        component={SearchSelect}
                        components={{
                            Option: SearchSelectUserOption,
                        }}
                        disabled={disabled}
                        isMulti
                        isOptionDisabled={(option) => option.disabled}
                        label="Select Evaluators"
                        name={STAGED_EVALUATORS}
                        options={usersSelectOptions}
                        placeholder="Select evaluator(s) or start typing a name"
                        qaTag="evaluationCreate-evaluatorsSelect"
                        showInviteUserHelp
                    />
                </div>
                <div className={`col-sm-4 col-lg-3 ${this.styles.addBtn}`}>
                    <Button
                        bsStyle="success"
                        disabled={disabled}
                        onClick={clickHandler}
                        qaTag="evaluationCreate-addEvaluators"
                    >
                        <i className="fa fa-user-plus" /> Add Evaluators
                    </Button>
                </div>
            </div>
        );
    }
}

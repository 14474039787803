import { listToDict } from '../../utils';

export const fields = [
    'fileName',
    'scanAttachments',
    'colorMode',
    'resolution',
    'scanSource',
    'duplex',
    'useAdf',
    'blankPageDetection',
    'imageBrightness',
    'imageContrast',
];

export const fieldNames = listToDict(fields);

export const RETRY_TIMEOUT = 5000;

export const NO_SOURCES_FOUND_MESSAGE = ' - No sources found';

export const DYNAMSOFT_RESOURCES_PATH = 'https://cdn.jsdelivr.net/npm/dwt@18.4.2/dist';

import { statusTypes } from '@og-pro/shared-config/requestTypes';
import { useMemo, useState } from 'react';

export const useRequestTypeFilters = ({ requestTypes }) => {
    const [showArchived, setShowArchived] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const filteredRequestTypes = useMemo(
        () =>
            requestTypes
                .filter((requestType) => {
                    // Filter by search value
                    if (!searchValue) {
                        return true;
                    }
                    return requestType.name?.toLowerCase().includes(searchValue.toLowerCase());
                })
                .filter((requestType) => {
                    // Filter by archived toggle
                    const isArchived = requestType.status === statusTypes.ARCHIVED;
                    return showArchived ? isArchived : !isArchived;
                }),
        [requestTypes, searchValue, showArchived]
    );

    return {
        filteredRequestTypes,
        isFilteredView: !!searchValue || showArchived,
        searchValue,
        setSearchValue,
        showArchived,
        setShowArchived,
    };
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { EvaluatorReminderEmailModal as EvaluatorReminderEmailModalComponent } from './components';
import { hideEvaluatorReminderEmailModal } from '../../../../../actions/evaluations';

const mapStateToProps = (state) => {
    return {
        sendEmailHandler: state.evaluations.get('sendEmailHandler'),
        showModal: state.evaluations.get('showEmailEditModal'),
    };
};

const mapDispatchToProps = {
    hideEvaluatorReminderEmailModal,
};

// @withRouter
// @connect
class ConnectedEvaluatorReminderEmailModal extends Component {
    static propTypes = {
        hideEvaluatorReminderEmailModal: PropTypes.func.isRequired,
        sendEmailHandler: PropTypes.func,
        showModal: PropTypes.bool.isRequired,
    };

    submitHandler = (formData) => {
        const { personalNote } = formData;
        this.props.sendEmailHandler(personalNote);
    };

    render() {
        const { showModal } = this.props;

        if (!showModal) {
            return null;
        }

        return (
            <EvaluatorReminderEmailModalComponent
                hideModal={this.props.hideEvaluatorReminderEmailModal}
                submitHandler={this.submitHandler}
            />
        );
    }
}

export const EvaluatorReminderEmailModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedEvaluatorReminderEmailModal);

import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import { getInstructionsModalDataJS } from './selectors';
import { getDocumentsTabName } from '../ProjectNav/selectors';
import {
    getBuilderDisplayName,
    hasContractingSubscription,
    hasSourcingSubscription,
} from '../selectors';
import { showExportModal } from '../../../actions/exportProject';
import * as govActions from '../../../actions/govProjects';
import { Button, DraftReviewToggle } from '../../../components';
import { CREATE_CONTRACT_FROM_PROJECT, POST } from '../../../constants/menuActions';

const mapStateToProps = (state) => {
    return {
        builderDisplayName: getBuilderDisplayName(state),
        documentsTabName: getDocumentsTabName(state),
        hasContracting: hasContractingSubscription(state),
        hasSourcing: hasSourcingSubscription(state),
        modalData: getInstructionsModalDataJS(state),
        modalType: state.govProjects.get('instructionsModalType'),
        showModal: !!state.govProjects.get('showInstructionsModal'),
    };
};

const mapDispatchToProps = {
    actionHandler: govActions.menuActionHandler,
    hideModal: govActions.hideInstructionsModal,
    showExportModal,
    showModalType: govActions.showInstructionsModal,
};

// @connect
class ConnectedInstructionsModal extends Component {
    static propTypes = {
        actionHandler: PropTypes.func.isRequired,
        builderDisplayName: PropTypes.string.isRequired,
        documentsTabName: PropTypes.string.isRequired,
        hasContracting: PropTypes.bool,
        hasSourcing: PropTypes.bool,
        hideModal: PropTypes.func.isRequired,
        modalData: PropTypes.object.isRequired,
        modalType: PropTypes.string,
        project: PropTypes.object,
        showExportModal: PropTypes.func.isRequired,
        showModal: PropTypes.bool,
        showModalType: PropTypes.func.isRequired,
    };

    static defaultProps = {
        hasSourcing: false,
        modalType: undefined,
        project: undefined,
        showModal: false,
    };

    get styles() {
        return require('./index.scss');
    }

    get builderNameLower() {
        return this.props.builderDisplayName.toLowerCase();
    }

    get builderNameUpper() {
        return this.props.builderDisplayName;
    }

    handleBackClick = () => {
        const { showModalType } = this.props;

        showModalType('reviewTools');
    };

    handleContinueClick = () => {
        const { showModalType } = this.props;

        showModalType('approvals');
    };

    handleDraftClick = () => {
        const { actionHandler, hideModal, project } = this.props;

        hideModal();
        actionHandler(POST, project);
    };

    handleExportClick = () => {
        const { hideModal } = this.props;

        hideModal();
        this.props.showExportModal();
    };

    renderApprovalDashboardInstructions() {
        return (
            <div>
                <p>
                    The Approvals Dashboard allows you to manage your entire list of{' '}
                    {this.builderNameLower} approvers all in one place! Use the dashboard to:
                </p>
                <ul>
                    <li>
                        Add approvers who need to review and approve the {this.builderNameLower}{' '}
                        before it is finalized.
                    </li>
                    <li>
                        Check who has viewed your {this.builderNameLower} and send reminder emails.
                    </li>
                    <li>Remove approvers if their approval is no longer needed.</li>
                    <li>
                        Approve the {this.builderNameLower} for an approver if they are unable to
                        approve it themselves.
                    </li>
                    <li>
                        View any note that was left with the approval by clicking the&nbsp;
                        <i className="fa fa-file-text text-primary" /> icon.
                    </li>
                </ul>
            </div>
        );
    }

    renderCreateContractsBody() {
        const { hideModal } = this.props;

        return (
            <div>
                {this.renderTitle('Contracts')}
                <div>
                    <p>
                        Contracting allows you to create and manage contracts with multiple vendors
                        for your project. Track start and end dates, and manage all documents
                        associated with the contract.
                    </p>
                    <p>
                        Within each contract you can create a list of users who are subscribed to
                        contract updates and notifications. This ensures that everyone knows when a
                        contract is ending or starting. Users with permission may add themselves and
                        others to the subscription list for a contract, and any user can remove
                        themselves from the list.
                    </p>
                    <p>
                        When ready, you can also toggle the contract to be visible to the public.
                        This will cause the contract to show up in your public portal.
                    </p>
                </div>
                <div className={this.styles.startButton}>
                    <Button bsStyle="success" onClick={hideModal}>
                        Start Contracting
                    </Button>
                </div>
            </div>
        );
    }

    renderFeatureList() {
        const { documentsTabName } = this.props;

        return (
            <div>
                <p>
                    The following tools are available to assist you in completing the review
                    process:
                </p>
                <ul>
                    <li>
                        Review your {this.builderNameLower} by visiting the {documentsTabName}{' '}
                        section.
                    </li>
                    <li>
                        Comment and collaborate with peers using the&nbsp;
                        <span className="text-primary">
                            + <i className="fa fa-comment-o text-primary" />
                        </span>{' '}
                        icons.
                    </li>
                    <li>
                        <div className="pull-right">
                            <DraftReviewToggle displayOnly />
                        </div>
                        Click the document mode toggle button in the toolbar to hop back into the
                        writing tool to make any needed revisions.
                    </li>
                </ul>
            </div>
        );
    }

    renderFinalizeExplanation() {
        return (
            <div>
                Once you have finalized your project and received all necessary approvals, click
                the&nbsp;
                <Button bsSize="xs" bsStyle="success">
                    <i className="fa fa-lg fa-check-square-o" /> Finalize {this.builderNameUpper}
                </Button>{' '}
                button to complete the drafting process. Doing so will mark the document as the
                final draft and ensures no more changes are made.
            </div>
        );
    }

    renderReviewBody() {
        const { hideModal } = this.props;

        return (
            <div>
                {this.renderStepCompleteTitle(
                    'Congratulations!',
                    `You have completed the first draft of your ${this.builderNameLower}!`
                )}
                <h5 className={this.styles.nextSteps}>Next Steps:</h5>
                <p>
                    Your {this.builderNameLower} is now in the &quot;Review&quot; stage and approval
                    request emails were sent to the assigned approvers in Review Step 1.
                    <br />
                    <br />
                    <b>Warning:</b> You can still make changes to the review steps, but when
                    approvers are added or removed from an <b>active</b> step, they will receive
                    notifications via email.
                </p>
                <div className={this.styles.startButton}>
                    <Button bsStyle="success" onClick={hideModal}>
                        OK
                    </Button>
                </div>
                <div className="text-center">
                    <a className="text-primary" href="#" onClick={this.handleContinueClick}>
                        Continue Learning About Review Tools
                        <i className={`fa fa-arrow-right ${this.styles.nextIcon}`} />
                    </a>
                </div>
            </div>
        );
    }

    renderIntakeIssuedBody() {
        const { hideModal, modalData } = this.props;

        const intakeName = 'request';
        const intakeFullName = 'Project Request';

        if (!modalData.isStepComplete) {
            return (
                <div>
                    {this.renderTitle(`Process ${intakeFullName}`)}
                    <p>
                        This dashboard allows the assigned user to route the {intakeName} to the
                        next step of the procurement process.
                    </p>
                    {modalData.isAssignedUser ? (
                        <>
                            <p>
                                To start drafting the solicitation documents, select the proper
                                template and the {intakeName} will be converted to a project.
                            </p>
                            <p>
                                Alternatively, if the solicitation should not move forward or needs
                                to happen outside of OpenGov Procurement, use the &quot;Close
                                Request&quot; option to close the {intakeName}.
                            </p>
                        </>
                    ) : (
                        <p>
                            The {intakeName} is currently waiting for the assigned user to process
                            the request.
                        </p>
                    )}
                    {!modalData.isAssignedUser && modalData.isEditor && (
                        <p>You may edit the user assigned to the {intakeName} at any time.</p>
                    )}
                    <div className={this.styles.nextButtons}>
                        <Button onClick={hideModal}>Done</Button>
                    </div>
                </div>
            );
        }

        return (
            <div>
                {this.renderStepCompleteTitle(
                    'Great Job!',
                    `Your ${intakeFullName} Has Been Issued!`
                )}
                <p>
                    {modalData.hasPresetApprovals
                        ? 'Your department has pre-set approval groups, so an approver has ' +
                          'already been assigned and notified automatically!'
                        : 'The next step is to assign a user to process your request. ' +
                          `You may edit the user assigned to your ${intakeName} at any time.`}
                </p>
                <p>You will receive a notification once your {intakeName} has been processed.</p>
                <div className={this.styles.nextButtons}>
                    <Button bsStyle="success" onClick={hideModal}>
                        Got it!
                    </Button>
                </div>
            </div>
        );
    }

    renderEvaluationOpenBody() {
        const { hideModal } = this.props;

        return (
            <div>
                {this.renderStepCompleteTitle(
                    'Great Job!',
                    'You have completed set up of your evaluation'
                )}
                <h5 className={this.styles.nextSteps}>Next Steps:</h5>
                <p>
                    The next step is to add vendor responses. Once you have finished adding all
                    responses, use the &quot;Release to Evaluators&quot; button to notify your
                    evaluators, so they can start reviewing and scoring responses.
                </p>
                <div className={this.styles.startButton}>
                    <Button bsStyle="success" onClick={hideModal}>
                        Start Adding Responses
                    </Button>
                </div>
            </div>
        );
    }

    renderEvaluationReleasedBody() {
        const { project } = this.props;

        const hasAwardPending = get(project, 'evaluation.hasAwardPending');

        return (
            <div>
                {this.renderStepCompleteTitle(
                    'Time to Start Evaluating!',
                    'All project evaluators have been notified'
                )}
                <h5 className={this.styles.nextSteps}>Next Steps:</h5>
                <p>
                    We have emailed all project evaluators to notify them that the responses have
                    been submitted and are ready to be reviewed. If you are also an evaluator you
                    may begin scoring the responses using the &quot;My Evaluations&quot; tab.
                </p>
                <p>
                    As scores are recorded, you may view the aggregate scorecard using the
                    &quot;Aggregate Evaluations&quot; tab.
                </p>
                <p>
                    Should your project need another evaluation phase after this one is completed,
                    you can start a new phase from the &quot;Aggregate Evaluations&quot; tab.
                </p>
                <p>
                    Once all evaluators have completed their review, the&nbsp;
                    {hasAwardPending ? 'next' : 'final'} step is to use the&nbsp;
                    <Button bsSize="xs" bsStyle="success">
                        <i className="fa fa-trophy" /> Award Project
                    </Button>{' '}
                    button to select the vendor that will be&nbsp;
                    {hasAwardPending ? 'recommended for award of' : 'awarded'} the project.
                </p>
                <div className={this.styles.startButton}>
                    <Button bsStyle="success" onClick={this.props.hideModal}>
                        Got It!
                    </Button>
                </div>
            </div>
        );
    }

    renderEvaluationPhaseCreatedBody() {
        const {
            hideModal,
            modalData: { phaseNumber, useNewScoringCriteria },
        } = this.props;

        return (
            <div>
                {this.renderStepCompleteTitle(
                    'New Evaluation Phase Added!',
                    `Phase ${phaseNumber} of the evaluation is ready for you to setup`
                )}
                {useNewScoringCriteria ? (
                    <p>
                        The Evaluators from the previous phase have been added for your convenience.
                        Please add your new Evaluation Criteria and make any modifications you like
                        to the Evaluators for this phase of the evaluation.
                    </p>
                ) : (
                    <p>
                        The Evaluation Criteria and Evaluators from the previous phase have been
                        added for your convenience. Please make any modifications you like to the
                        Evaluation Criteria and Evaluators for this phase of the evaluation.
                    </p>
                )}
                <p>
                    Once your setup is complete, release the evaluation so that your evaluators can
                    start scoring this new phase.
                    {!useNewScoringCriteria &&
                        ' If you want to keep the same set of criteria and evaluators, you can ' +
                            'simply release this new phase immediately without making any changes ' +
                            'to the setup.'}
                </p>
                <div className={this.styles.startButton}>
                    <Button bsStyle="success" onClick={hideModal}>
                        Setup Phase {phaseNumber}
                    </Button>
                </div>
            </div>
        );
    }

    renderConsensusEvaluationCreatedBody() {
        const { hideModal } = this.props;
        return (
            <div className="text-center">
                {this.renderTitle('Consensus Scorecard')}
                <p>We have generated a Consensus Scorecard for you!</p>
                <p>
                    We filled in the scorecard by taking the average score from all evaluators who
                    submitted scores. We also combined the comments for each evaluator who left
                    comments.
                </p>
                <p>
                    You can make any edits you like to the Consensus Scorecard. You can also make
                    the scorecard publicly viewable once the vendor has been selected.
                </p>
                <div className={this.styles.startButton}>
                    <Button bsStyle="primary" onClick={hideModal}>
                        Okay
                    </Button>
                </div>
            </div>
        );
    }

    renderAwardPendingBody() {
        const { actionHandler, hasContracting, project } = this.props;

        const createContract = () => actionHandler(CREATE_CONTRACT_FROM_PROJECT, project);

        return (
            <div>
                {this.renderStepCompleteTitle(
                    'Congratulations!',
                    'You have successfully submitted your recommendation!'
                )}
                <p>
                    The final step is to confirm the award once the recommendation has been
                    approved. If you need to revise your recommended vendor(s), go back to the
                    project dashboard and choose the &quot;Unaward Project&quot; option to make a
                    new recommendation.
                </p>
                {hasContracting && (
                    <p>
                        Don&apos;t forget to click Add Contract below to start onboarding the
                        selected vendor(s).
                    </p>
                )}
                <div className={this.styles.startButton}>
                    {hasContracting && (
                        <Button
                            bsStyle="success"
                            onClick={createContract}
                            style={{ marginRight: 10 }}
                        >
                            <i className="fa fa-plus" /> Add Contract
                        </Button>
                    )}
                    <Button onClick={this.props.hideModal}>Got It!</Button>
                </div>
            </div>
        );
    }

    renderEvaluationCompleteBody() {
        return (
            <div>
                {this.renderStepCompleteTitle(
                    'Congratulations!',
                    'You have successfully completed the evaluation!'
                )}
                <div className={this.styles.startButton}>
                    <Button bsStyle="success" onClick={this.props.hideModal}>
                        Done
                    </Button>
                </div>
            </div>
        );
    }

    renderStepCompleteTitle(congratsText, stepCompleteText) {
        return (
            <div className={`text-success ${this.styles.nextStepsTitle}`}>
                <h3>{congratsText}</h3>
                <div className={this.styles.completeIcon}>
                    <i className="fa fa-check-square-o fa-3x" />
                </div>
                <h4>{stepCompleteText}</h4>
            </div>
        );
    }

    renderTitle(title) {
        return <h4 className={`text-primary ${this.styles.instructionTitle}`}>{title}</h4>;
    }

    renderNextButtons(nextModal, prevModal) {
        const { showModalType } = this.props;

        const BackButton = prevModal ? (
            <Button className={this.styles.backButton} onClick={() => showModalType(prevModal)}>
                <i className="fa fa-arrow-left" /> Back
            </Button>
        ) : null;

        return (
            <div className={this.styles.nextButtons}>
                {BackButton}
                <Button bsStyle="primary" onClick={() => showModalType(nextModal)}>
                    Next <i className="fa fa-arrow-right" />
                </Button>
            </div>
        );
    }

    renderApprovalInstructionsBody() {
        return (
            <div>
                {this.renderTitle('Approvals Dashboard')}
                {this.renderApprovalDashboardInstructions()}
                {this.renderNextButtons('reviewTools')}
            </div>
        );
    }

    renderReviewToolsBody() {
        return (
            <div>
                {this.renderTitle('Review & Collaboration Tools')}
                {this.renderFeatureList()}
                {this.renderNextButtons('finalizing', 'approvals')}
            </div>
        );
    }

    renderPublishingBody() {
        const { hideModal } = this.props;

        return (
            <div>
                {this.renderTitle(`Finalizing Your ${this.builderNameUpper}`)}
                {this.renderFinalizeExplanation()}
                <div className={this.styles.nextButtons}>
                    <Button className={this.styles.backButton} onClick={this.handleBackClick}>
                        <i className="fa fa-arrow-left" /> Back
                    </Button>
                    <Button bsStyle="primary" onClick={hideModal}>
                        Got It!
                    </Button>
                </div>
            </div>
        );
    }

    renderFinalizedBody() {
        const {
            hasSourcing,
            hideModal,
            modalData: { isDocBuilder },
        } = this.props;

        const hasSouringNextStep = hasSourcing && !isDocBuilder;

        const nextActionText = hasSouringNextStep
            ? 'Use the button below to start drafting a posting for your ' +
              "project. Once you've finished drafting your posting, you " +
              'can make it publicly available to vendors.'
            : 'Use the button below to export the completed document in your desired format. ' +
              'Or you can always come back and export later.';
        const nextActionButton = hasSouringNextStep ? (
            <Button
                bsStyle="success"
                className={this.styles.backButton}
                onClick={this.handleDraftClick}
            >
                <i className="fa fa-lg fa-flag" /> Draft Posting
            </Button>
        ) : (
            <Button
                bsStyle="success"
                className={this.styles.backButton}
                onClick={this.handleExportClick}
            >
                <i className="fa fa-lg fa-cloud-download" /> Export {this.builderNameUpper}
            </Button>
        );

        return (
            <div>
                <div className={`text-success ${this.styles.nextStepsTitle}`}>
                    <h3>Great Job!</h3>
                    <div className={this.styles.completeIcon}>
                        <i className="fa fa-check-square-o fa-3x" />
                    </div>
                    <h4>You have completed the final draft of your {this.builderNameLower}!</h4>
                </div>
                {hasSouringNextStep && (
                    <p>
                        Now that you have a final draft of your {this.builderNameLower}, the next
                        step is to post it so vendors can start responding.
                    </p>
                )}
                <p>{nextActionText}</p>
                <div className={`row ${this.styles.startButton}`}>
                    {nextActionButton}
                    <Button onClick={hideModal}>Later</Button>
                </div>
            </div>
        );
    }

    renderCreatePostBody() {
        const { hideModal } = this.props;

        return (
            <div className="text-center">
                {this.renderTitle('Creating A Post')}
                <p>
                    To create a post, we will first make a list of documents that will be required
                    for vendors to submit as their response.
                </p>
                <p>We will then confirm the project timeline before publishing the post.</p>
                <p>Once the post has been published it will be publicly available for viewing.</p>
                <div className={this.styles.nextButtons}>
                    <Button bsStyle="success" onClick={hideModal}>
                        Get Started!
                    </Button>
                </div>
            </div>
        );
    }

    renderPostScheduledBody() {
        return (
            <div>
                {this.renderStepCompleteTitle(
                    'Post Scheduled!',
                    'Your project posting date has been scheduled! ' +
                        'You can revise the posting date by editing the post.'
                )}
                <div className="text-center">
                    <Button bsStyle="success" onClick={this.props.hideModal}>
                        Done
                    </Button>
                </div>
            </div>
        );
    }

    renderPublishedBody() {
        return (
            <div>
                {this.renderStepCompleteTitle('Great Job!', 'Your Project Has Been Posted!')}
                <div className="text-center">
                    <Button bsStyle="success" onClick={this.props.hideModal}>
                        Done
                    </Button>
                </div>
            </div>
        );
    }

    renderBidTabulationBodyText() {
        return (
            <div>
                <p>
                    The next step is to calculate the winning bid(s). You can toggle on and off rows
                    from the total, and see the current vendor totals ranked at the top of the page.
                    Your selections will be automatically saved as they change.
                </p>
                <p>
                    When you&apos;re ready, complete the evaluation by clicking&nbsp;
                    <Button bsSize="xs" bsStyle="success">
                        <i className="fa fa-trophy" /> Award Project
                    </Button>{' '}
                    to select the winning vendor(s).
                </p>
                <p>
                    If any responses were submitted outside the system, you can add them by using
                    the&nbsp;
                    <Button bsSize="xs">
                        <i className="fa fa-plus" />
                        &nbsp;Add Response
                    </Button>
                    &nbsp;button.
                </p>
            </div>
        );
    }

    renderBidTabulationHelpBody() {
        const { hideModal } = this.props;

        return (
            <div>
                {this.renderTitle('Bid Tabulations')}
                {this.renderBidTabulationBodyText()}
                <div className={this.styles.startButton}>
                    <Button bsStyle="primary" onClick={hideModal}>
                        Done
                    </Button>
                </div>
            </div>
        );
    }

    renderEvaluationLowestPriceCreatedBody() {
        const { hideModal } = this.props;

        return (
            <div>
                {this.renderStepCompleteTitle(
                    'Welcome to Bid Tabulation',
                    'You can now review submitted responses'
                )}
                {this.renderBidTabulationBodyText()}
                <div className={this.styles.startButton}>
                    <Button bsStyle="success" onClick={hideModal}>
                        Get Started
                    </Button>
                </div>
            </div>
        );
    }

    renderEvaluationLineItemAwardCreatedBody() {
        const { hideModal } = this.props;

        return (
            <div>
                {this.renderStepCompleteTitle(
                    'Welcome to Line Item Awarding',
                    'You can now start making awards by line item'
                )}
                <div>
                    <p>
                        The next step is to select the winning bid(s). To make draft line item
                        awards, click on the line item cell for the vendor you would like to award
                        and select award type of &quot;primary&quot; or &quot;backup&quot;. Your
                        selections will be automatically saved as they change.
                    </p>
                    <p>
                        As you make awards, summary tables of the awards for each vendor will be
                        populated in the &quot;Awards By Vendor&quot; tab.
                    </p>
                    <p>
                        Once you&apos;re done awarding line items, complete the evaluation by
                        clicking&nbsp;
                        <Button bsSize="xs" bsStyle="success">
                            <i className="fa fa-trophy" /> Award Project
                        </Button>{' '}
                        to confirm the selected vendor(s).
                    </p>
                    <p>
                        If any responses were submitted outside the system, you can add them by
                        using the&nbsp;
                        <Button bsSize="xs">
                            <i className="fa fa-plus" /> Add Response
                        </Button>
                        &nbsp;button.
                    </p>
                </div>
                <div className={this.styles.startButton}>
                    <Button bsStyle="success" onClick={hideModal}>
                        Get Started
                    </Button>
                </div>
            </div>
        );
    }

    renderModalBody() {
        const { modalType } = this.props;

        switch (modalType) {
            case 'intakeIssued':
                return this.renderIntakeIssuedBody();
            case 'review':
                return this.renderReviewBody();
            case 'approvals':
                return this.renderApprovalInstructionsBody();
            case 'createContracts':
                return this.renderCreateContractsBody();
            case 'reviewTools':
                return this.renderReviewToolsBody();
            case 'finalizing':
                return this.renderPublishingBody();
            case 'finalized':
                return this.renderFinalizedBody();
            case 'createPost':
                return this.renderCreatePostBody();
            case 'postScheduled':
                return this.renderPostScheduledBody();
            case 'published':
                return this.renderPublishedBody();
            case 'evaluationLowestPriceCreated':
                return this.renderEvaluationLowestPriceCreatedBody();
            case 'evaluationLineItemAwardCreated':
                return this.renderEvaluationLineItemAwardCreatedBody();
            case 'bidTabulationHelp':
                return this.renderBidTabulationHelpBody();
            case 'evaluationDraftComplete':
                return this.renderEvaluationOpenBody();
            case 'evaluationReleased':
                return this.renderEvaluationReleasedBody();
            case 'evaluationPhaseCreated':
                return this.renderEvaluationPhaseCreatedBody();
            case 'consensusEvaluationCreated':
                return this.renderConsensusEvaluationCreatedBody();
            case 'awardPending':
                return this.renderAwardPendingBody();
            case 'evaluationComplete':
                return this.renderEvaluationCompleteBody();
            default:
                return null;
        }
    }

    render() {
        const { hideModal, showModal } = this.props;

        return (
            <Modal onHide={hideModal} show={showModal}>
                <Modal.Body className={this.styles.modalBody}>{this.renderModalBody()}</Modal.Body>
            </Modal>
        );
    }
}

export const InstructionsModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedInstructionsModal);

import { actionTypes } from '@og-pro/shared-config/requisitionLogs';
import moment from 'moment';
import { createSelector } from 'reselect';

import { actionTypesToDisplayInSteps, getRequisitionLogText } from '../helpers';
import { getRequisitionLogsByStep } from '../selectors';
import { getAllRequisitionLogs } from '../../../selectors';
import { getRequisitionJS } from '../../../../../../selectors/govApp';

export const getIsLoadingRejectedExceptionSequenceSteps = (state) =>
    state.requisitionsApprovals.get('loadingRejectedExceptionSequenceSteps');

export const getLoadRejectedExceptionSequenceStepsError = (state) =>
    state.requisitionsApprovals.get('loadRejectedExceptionSequenceStepsError');

const getRejectedExceptionSequenceSteps = createSelector(
    [(state) => state.requisitionsApprovals.get('rejectedExceptionSequenceSteps')],
    (rawRejectedExceptionSequenceSteps) => rawRejectedExceptionSequenceSteps.toJS().reverse()
);

const getInitialSubmit = createSelector(
    [getAllRequisitionLogs, getRequisitionJS],
    (requisitionLogs, requisition) => {
        const submitLog = requisitionLogs.findLast(
            ({ actionType }) => actionType === actionTypes.SUBMIT
        );

        if (submitLog) {
            const { date, user } = submitLog;
            return {
                details: requisition.exceptionNote,
                text: `Submitted by ${user.displayName} on ${moment(date).format('M/D/YY [at] h:mma')}`,
            };
        }
    }
);

export const getExceptionSequenceRequisitionLogsDisplay = createSelector(
    [getRequisitionLogsByStep, getRejectedExceptionSequenceSteps, getInitialSubmit],
    (requisitionLogsByStep, rejectedExceptionSequenceSteps, initialSubmit) => {
        // Beyond the sequence steps, we also want to display the initial submit log
        const initialRequisitionLog = initialSubmit ? [initialSubmit] : [];
        return rejectedExceptionSequenceSteps
            .reduce((list, reviewSequenceStep) => {
                const requisitionLogs = requisitionLogsByStep[reviewSequenceStep.id] || [];
                return list.concat(
                    requisitionLogs
                        .filter(({ actionType }) =>
                            actionTypesToDisplayInSteps.includes(actionType)
                        )
                        .map((requisitionLog) => {
                            return {
                                text: getRequisitionLogText(requisitionLog, reviewSequenceStep),
                                details: requisitionLog.details,
                            };
                        })
                );
            }, [])
            .concat(initialRequisitionLog);
    }
);

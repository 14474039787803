import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ActionToolbarNavbarItem } from '../../../../components';
import { trackEvent } from '../../../../helpers';
import { showInviteModal } from '../../../../actions/govPermissions';

const mapDispatchToProps = {
    showInviteModal,
};

// @connect
class ConnectedInviteItem extends Component {
    static propTypes = {
        projectId: PropTypes.number.isRequired,
        disabled: PropTypes.bool,
        showInviteModal: PropTypes.func.isRequired,
    };

    handleClick = () => {
        const { projectId } = this.props;

        trackEvent('Permissions Modal');
        this.props.showInviteModal(projectId);
    };

    render() {
        const { disabled } = this.props;
        return (
            <ActionToolbarNavbarItem
                className="action-toolbar-invite-btn"
                disabled={disabled}
                onClick={this.handleClick}
                qaTag="projectActionMenu-inviteUser"
            >
                <i className="fa fa-user-plus" /> Invite
            </ActionToolbarNavbarItem>
        );
    }
}

export const InviteItem = connect(null, mapDispatchToProps)(ConnectedInviteItem);

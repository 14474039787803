export const navItems = [
    {
        name: 'Projects',
        path: 'projects',
    },
    {
        name: 'Contracts',
        path: 'contracts',
    },
];

import { upperFirst } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { ProjectCreateButton } from '..';

const BaseNoProjects = (props) => {
    const { filtered, isDocBuilder, isIntake } = props;

    const styles = require('./NoProjects.scss');
    let resourceName = 'project';
    if (isIntake) {
        resourceName = 'project request';
    } else if (isDocBuilder) {
        resourceName = 'document';
    }

    return (
        <div className={`row ${styles.noProjectsContainer}`}>
            <div className="col-sm-8 col-sm-offset-2">
                <div className={`text-muted ${styles.noProjectsText}`}>
                    {filtered ? (
                        <>
                            <div className={styles.noProjectsHeader}>No results found</div>
                            <div>No {resourceName}s were found for the current filters.</div>
                        </>
                    ) : (
                        <>
                            <div className={styles.noProjectsHeader}>
                                No {resourceName}s created yet!
                            </div>
                            {!isDocBuilder && !isIntake ? (
                                <div>
                                    To get started click the button to create your first{' '}
                                    {resourceName}. Your list of {resourceName}s will then be
                                    displayed here.
                                </div>
                            ) : (
                                <div>
                                    {upperFirst(resourceName)}s will be displayed here once created.
                                </div>
                            )}
                        </>
                    )}
                </div>
                {!filtered && !isDocBuilder && (
                    <ProjectCreateButton
                        className={`btn-success ${styles.createBtn}`}
                        isIntake={isIntake}
                        qaTag="projects-emptyCreateNewProject"
                    />
                )}
            </div>
        </div>
    );
};

BaseNoProjects.propTypes = {
    filtered: PropTypes.bool,
    isDocBuilder: PropTypes.bool,
    isIntake: PropTypes.bool,
};

BaseNoProjects.defaultProps = {
    filtered: false,
    isDocBuilder: false,
    isIntake: false,
};

export const NoProjects = React.memo(BaseNoProjects);

import { omit, pick } from 'lodash';

import { showSnackbar } from './notification';
import { resourceManager } from '../helpers';

export const CREATE_ITEM_SUCCESS = 'templateQuestions/CREATE_ITEM_SUCCESS';

export const DELETE_ITEM_SUCCESS = 'templateQuestions/DELETE_ITEM_SUCCESS';

export const LOAD_ITEM = 'templateQuestions/LOAD_ITEM';
export const LOAD_ITEM_FAIL = 'templateQuestions/LOAD_ITEM_FAIL';
export const LOAD_ITEM_SUCCESS = 'templateQuestions/LOAD_ITEM_SUCCESS';

export const LOAD_LIST = 'templateQuestions/LOAD_LIST';
export const LOAD_LIST_FAIL = 'templateQuestions/LOAD_LIST_FAIL';
export const LOAD_LIST_SUCCESS = 'templateQuestions/LOAD_LIST_SUCCESS';

export const LOAD_TEMPLATES_IN_USE = 'templateQuestions/LOAD_TEMPLATES_IN_USE';
export const LOAD_TEMPLATES_IN_USE_FAIL = 'templateQuestions/LOAD_TEMPLATES_IN_USE_FAIL';
export const LOAD_TEMPLATES_IN_USE_SUCCESS = 'templateQuestions/LOAD_TEMPLATES_IN_USE_SUCCESS';

export const RESET_EDIT = 'templateQuestions/RESET_EDIT';
export const RESET_LIST = 'templateQuestions/RESET_LIST';

export const UPDATE_ITEM_SUCCESS = 'templateQuestions/UPDATE_ITEM_SUCCESS';

const serializeTemplateQuestionData = (data) => {
    const templateQuestionData = pick(data, ['isPublished', 'user_id']);
    const upfrontQuestionData = omit(data, ['isPublished', 'user_id']);

    return {
        ...templateQuestionData,
        upfrontQuestion: upfrontQuestionData,
    };
};

export const createTemplateQuestion = (data) => {
    return resourceManager({
        method: 'post',
        requestOptions: { data: serializeTemplateQuestionData(data) },
        url: '/questionnaires/templates',
        onSuccess: ({ result, dispatch }) => {
            dispatch(showSnackbar('Shared question created!'));
            dispatch({ type: CREATE_ITEM_SUCCESS, result });
        },
    });
};

export const deleteTemplateQuestion = (templateQuestionId) => {
    return resourceManager({
        method: 'del',
        url: `/questionnaires/templates/${templateQuestionId}`,
        onSuccess: ({ result, dispatch }) => {
            dispatch(showSnackbar('Shared question deleted'));
            dispatch({ type: DELETE_ITEM_SUCCESS, result });
        },
    });
};

export const loadTemplateQuestion = (templateQuestionId) => {
    return resourceManager({
        method: 'get',
        url: `/questionnaires/templates/${templateQuestionId}`,
        onStart: ({ dispatch }) => dispatch({ type: LOAD_ITEM }),
        onSuccess: ({ result, dispatch }) => dispatch({ type: LOAD_ITEM_SUCCESS, result }),
        onFailure: ({ error, dispatch }) => dispatch({ type: LOAD_ITEM_FAIL, error }),
    });
};

export const loadTemplatesInUse = (templateQuestionId) => {
    return resourceManager({
        method: 'get',
        url: `/questionnaires/templates/${templateQuestionId}/templates`,
        onStart: ({ dispatch }) => dispatch({ type: LOAD_TEMPLATES_IN_USE }),
        onSuccess: ({ result, dispatch }) =>
            dispatch({ type: LOAD_TEMPLATES_IN_USE_SUCCESS, result }),
        onFailure: ({ error, dispatch }) => dispatch({ type: LOAD_TEMPLATES_IN_USE_FAIL, error }),
    });
};

export const loadTemplateQuestionsList = () => {
    return resourceManager({
        method: 'get',
        url: '/questionnaires/templates',
        onStart: ({ dispatch }) => dispatch({ type: LOAD_LIST }),
        onSuccess: ({ result, dispatch }) => dispatch({ type: LOAD_LIST_SUCCESS, result }),
        onFailure: ({ error, dispatch }) => dispatch({ type: LOAD_LIST_FAIL, error }),
    });
};

export function resetTemplateQuestionEdit() {
    return { type: RESET_EDIT };
}

export function resetTemplateQuestionsList() {
    return { type: RESET_LIST };
}

export const updateTemplateQuestion = (templateQuestionId, data) => {
    return resourceManager({
        method: 'put',
        requestOptions: { data: serializeTemplateQuestionData(data) },
        url: `/questionnaires/templates/${templateQuestionId}`,
        onSuccess: ({ result, dispatch }) => {
            dispatch(showSnackbar('Shared question updated'));
            dispatch({ type: UPDATE_ITEM_SUCCESS, result });
        },
    });
};

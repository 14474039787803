import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import { normalizeNumberWithCommas } from '@og-pro/shared-config/helpers';

import { getPreciseRoundFloat } from '@og-pro/shared-config/reverseAuctions/util';

import { PROXY_BID, form } from './constants';
import { validate } from './validate';
import { HelpToolTip, InputText } from '../../../components';
import { currencyFormatter } from '../../../helpers';
import { MaskedInputText } from '../../../hocs';
import { maskNumberWithCommas } from '../../../Forms/maskers';
import { dollarString } from '../../../Forms/normalizers';

const formConfig = {
    form,
    validate,
};

const ConnectedProxyBidReverseAuctionForm = ({
    auctionMaxFractionDigits,
    currentLowestBid,
    description,
    handleSubmit,
    minBidDecrement,
    quantity,
}) => {
    const styles = require('./index.scss');
    const formSelector = formValueSelector(form);
    const MaskedBidInput = useCallback(MaskedInputText(InputText), []); // eslint-disable-line react-hooks/exhaustive-deps

    const currentProxyBid = useSelector((state) => formSelector(state, PROXY_BID));

    const maximumFractionDigits = auctionMaxFractionDigits;
    const normalizedBid = (value) => dollarString(value, { maximumFractionDigits });
    const maximumProxyAmount = getPreciseRoundFloat(
        getPreciseRoundFloat(currentLowestBid, auctionMaxFractionDigits) -
            getPreciseRoundFloat(minBidDecrement * 2, auctionMaxFractionDigits),
        auctionMaxFractionDigits
    );
    const formatterOpts = {
        maximumFractionDigits,
        useSameMinAndMax: true,
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className={styles.description}>{description}</div>
            <div className={styles.minBidDecrement}>
                Minimum Decrement is {currencyFormatter({ value: minBidDecrement }, formatterOpts)}{' '}
                <br />
                Maximum Proxy Bid Amount is{' '}
                {currencyFormatter({ value: maximumProxyAmount }, formatterOpts)}
                <HelpToolTip
                    placement="right"
                    text="A proxy bid can only be set if the amount can be used more than once. Please use the Place Bid form if you only want to bid once."
                />
            </div>
            <br />
            <div className="row">
                <div className={`col-xs-10 ${styles.bidSectionContainer}`}>
                    <div>
                        <h5 className={styles.unitPriceLabel}>Unit Price</h5>
                        <div>
                            <div className={styles.unitPriceContainer}>
                                <span className={styles.bidFormInput}>
                                    <Field
                                        component={MaskedBidInput}
                                        hasFeedback={false}
                                        inputGroupPrefix="$"
                                        mask={maskNumberWithCommas}
                                        maxLength={16}
                                        name={PROXY_BID}
                                        normalizer={normalizedBid}
                                        qaTag="reverseAuction-bidForm"
                                        type="text"
                                    />
                                </span>
                                <span className={styles.quantityLabel}>
                                    {' '}
                                    x Qty: {normalizeNumberWithCommas(quantity)} ={' '}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.totalSection}>
                        <h5 className={styles.totalLabel}>Total</h5>
                        <div className={styles.totalPrice}>
                            {currencyFormatter(
                                { value: currentProxyBid >= 0 ? currentProxyBid * quantity : '' },
                                formatterOpts
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <p className={styles.confirmationInfo}>
                Confirming sets this as the lowest bid you allow the system to go for you.
            </p>
        </form>
    );
};

ConnectedProxyBidReverseAuctionForm.propTypes = {
    auctionMaxFractionDigits: PropTypes.number.isRequired,
    currentLowestBid: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    minBidDecrement: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
};

export const ProxyBidReverseAuctionForm = reduxForm(formConfig)(
    ConnectedProxyBidReverseAuctionForm
);

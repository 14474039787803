import { createSelector } from 'reselect';

const getGenerativeAiScopeChat = (state) =>
    state.govProjects.getIn(['selectedProject', 'generativeAiScope', 'generativeAiChat']);

export const getAiGeneratedScopeItemJS = createSelector(
    [getGenerativeAiScopeChat],
    (generativeAiChat) => {
        if (!generativeAiChat) {
            return null;
        }
        const chatJS = generativeAiChat.toJS();
        return { description: chatJS.content, title: '' };
    }
);

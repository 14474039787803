import PropTypes from 'prop-types';
import React from 'react';
import { DropdownButton } from 'react-bootstrap';

import { Button, MenuItem } from '../../../../components';

export const MenuButtons = ({
    completeChecklist,
    deleteChecklist,
    deleting,
    disabled,
    isEditor,
    isReleased,
    reopenChecklist,
    updating,
}) => {
    if (!isReleased) {
        const updateIcon = updating ? 'spinner fa-spin' : 'undo';
        return (
            <Button
                disabled={!isEditor || disabled}
                onClick={reopenChecklist}
                qaTag="contractChecklistTitleMenu-reopen"
                tooltip={isEditor ? undefined : 'Only contract editors can ro-open the checklist'}
            >
                <i className={`fa fa-fw fa-${updateIcon}`} /> Re-Open Checklist
            </Button>
        );
    }

    const deleteIcon = deleting ? 'spinner fa-spin' : 'trash';
    const updateIcon = updating ? 'spinner fa-spin' : 'check-square-o';

    return (
        <>
            <DropdownButton
                disabled={disabled}
                id="contract-checklist-more-button"
                pullRight
                title="More"
            >
                <MenuItem
                    disabled={!isEditor || disabled}
                    onClick={isEditor ? deleteChecklist : undefined}
                    qaTag="contractChecklistTitleMenu-delete"
                >
                    <i className={`fa fa-fw fa-${deleteIcon}`} /> Delete
                </MenuItem>
            </DropdownButton>
            &nbsp;&nbsp;
            <Button
                bsStyle="success"
                disabled={!isEditor || disabled}
                onClick={completeChecklist}
                qaTag="contractChecklistTitleMenu-complete"
                tooltip={isEditor ? undefined : 'Only contract editors can complete the checklist'}
            >
                <i className={`fa fa-fw fa-${updateIcon}`} /> Complete Checklist
            </Button>
        </>
    );
};

MenuButtons.propTypes = {
    completeChecklist: PropTypes.func.isRequired,
    deleteChecklist: PropTypes.func.isRequired,
    deleting: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    isEditor: PropTypes.bool.isRequired,
    isReleased: PropTypes.bool.isRequired,
    reopenChecklist: PropTypes.func.isRequired,
    updating: PropTypes.bool.isRequired,
};

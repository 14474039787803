import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { RelationsSearchForm } from '../components';
import { PROJECT_ID, qaTagPageName } from '../constants';
import { ProjectSearchInput } from '../../../../../../../components';
import { SearchSelectOption } from './SearchSelectOption';

export const RelationsProjectSearchForm = ({
    backHandler,
    cancelHandler,
    createError,
    createHandler,
    createText,
    disabled,
    help,
    label,
    searchData,
    selectHandler,
}) => {
    const onChange = (data) => {
        if (data && data[PROJECT_ID]) {
            return selectHandler({ [PROJECT_ID]: data[PROJECT_ID] });
        }
    };

    return (
        <RelationsSearchForm
            backHandler={backHandler}
            cancelHandler={cancelHandler}
            createError={createError}
            createHandler={createHandler}
            createText={createText}
            disabled={disabled}
            onChange={onChange}
        >
            <Field
                component={ProjectSearchInput}
                components={{
                    Option: SearchSelectOption,
                }}
                disabled={disabled}
                help={help}
                label={label}
                name={PROJECT_ID}
                placeholder="Type to search..."
                qaTag={`${qaTagPageName}-searchProjects`}
                searchData={searchData}
                useOpenGovStyle
            />
        </RelationsSearchForm>
    );
};

RelationsProjectSearchForm.propTypes = {
    backHandler: PropTypes.func.isRequired,
    cancelHandler: PropTypes.func.isRequired,
    createError: PropTypes.string,
    createHandler: PropTypes.func.isRequired,
    createText: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    help: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    searchData: PropTypes.object,
    selectHandler: PropTypes.func.isRequired,
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { QuestionPrompt } from '../../SharedDisplay';

export class YesNoDisplay extends PureComponent {
    static propTypes = {
        isDocx: PropTypes.bool,
        isRequired: PropTypes.bool,
        prompt: PropTypes.string,
        title: PropTypes.string,
    };

    renderIcon() {
        return <i className="fa fa-fw fa-lg fa-circle-thin multiple-choice-icon" />;
    }

    render() {
        if (this.props.isDocx) {
            return (
                <QuestionPrompt {...this.props}>
                    <div style={{ marginBottom: 0 }}>
                        <input type="checkbox" /> Yes
                    </div>
                    <div>
                        <input type="checkbox" /> No
                    </div>
                </QuestionPrompt>
            );
        }

        return (
            <QuestionPrompt {...this.props}>
                <div className="multiple-choice-item">{this.renderIcon()}Yes</div>
                <div className="multiple-choice-item">{this.renderIcon()}No</div>
            </QuestionPrompt>
        );
    }
}

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ListGroup } from 'react-bootstrap';

import { defaultSectionConfigsMap, sectionTypeNames } from '@og-pro/shared-config/sections';

import { ProjectSectionItem } from '../ProjectSectionItem';
import { fieldNames } from '../../constants';
import { fieldNames as projectContentFormFieldNames } from '../../../ProjectContentForm/constants';
import { ListError } from '../../../../../../../components/GovApp';
import { getDndStyle } from '../../../../../../../constants/styles';
import { ProjectSectionAddButtons } from '../ProjectSectionAddButtons';
import { getIndexToChangeParentDivider, sortSections } from '../../../../../../../helpers';

const { ORDER_BY_ID, PROJECT_SECTIONS, SECTION_TYPE } = fieldNames;

const { CRITERIA, EVALUATION_PHASES, PRICE_TABLES, QUESTIONNAIRES, SECTION_DESCRIPTIONS } =
    projectContentFormFieldNames;

const {
    DIVIDER,
    EVALUATION_CRITERIA,
    EVALUATION_PHASE,
    PRICING,
    QUESTIONNAIRE,
    SCOPE,
    TERMS,
    TEXT_AREA,
} = sectionTypeNames;

const PROJECT_SECTION = 'projectSection';

export class ProjectSectionsForm extends PureComponent {
    static propTypes = {
        array: PropTypes.shape({
            removeAll: PropTypes.func.isRequired,
        }).isRequired,
        change: PropTypes.func.isRequired,
        createProjectSection: PropTypes.func.isRequired,
        createProjectSectionError: PropTypes.string,
        createTemplateSection: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        fields: PropTypes.shape({
            forEach: PropTypes.func.isRequired,
            get: PropTypes.func.isRequired,
            getAll: PropTypes.func.isRequired,
            length: PropTypes.number.isRequired,
            map: PropTypes.func.isRequired,
            move: PropTypes.func.isRequired,
            name: PropTypes.string.isRequired,
            push: PropTypes.func.isRequired,
            remove: PropTypes.func.isRequired,
        }).isRequired,
        isDirty: PropTypes.bool,
        isDocBuilder: PropTypes.bool.isRequired,
        isIntake: PropTypes.bool.isRequired,
        isSpecial: PropTypes.bool.isRequired,
        meta: PropTypes.object.isRequired,
        openSectionId: PropTypes.number,
        showConfirmationSimpleModal: PropTypes.func.isRequired,
        showFormHandler: PropTypes.func.isRequired,
        showFormValidation: PropTypes.bool,
        updateTemplate: PropTypes.func.isRequired,
        useManualNumbering: PropTypes.bool.isRequired,
        useSectionDividers: PropTypes.bool.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    addProjectSection = (sectionData) => {
        const {
            createProjectSection,
            fields: { push },
        } = this.props;

        createProjectSection(sectionData.section_type).then((projectSectionOrError) => {
            if (!(projectSectionOrError instanceof Error)) {
                push(projectSectionOrError);
            }
        });
    };

    addTemplateSection = (templateSectionId) => {
        const { createTemplateSection } = this.props;

        createTemplateSection(templateSectionId);
    };

    handleDragEnd = (result) => {
        const {
            fields: { forEach, move },
            change,
        } = this.props;

        const originLocation = result.source.index;
        const newLocation = result.destination ? result.destination.index : undefined;

        if (newLocation !== undefined && newLocation !== originLocation) {
            move(originLocation, newLocation);

            forEach((name, index) => {
                change(`${name}.${ORDER_BY_ID}`, index + 1);
            });
        }
    };

    removeField = (index) => {
        const { showConfirmationSimpleModal } = this.props;

        showConfirmationSimpleModal(() => this.removeSection(index), {
            btnText: 'Delete Section',
            text:
                'This will delete the section and all the content defined within the section. ' +
                'Are you sure you want to delete this section?',
        });
    };

    removeSection = (index) => {
        const {
            array: { removeAll },
            change,
            fields: { get, getAll, remove },
        } = this.props;

        const projectSectionToRemove = get(index);

        // Remove all section descriptions from form
        projectSectionToRemove.projectSubsections.forEach((projectSub) => {
            change(`${SECTION_DESCRIPTIONS}.${projectSectionToRemove.id}_${projectSub.id}`, null);
        });

        // Remove all associated content
        switch (projectSectionToRemove.section_type) {
            case EVALUATION_PHASE:
                removeAll(EVALUATION_PHASES);
                break;
            case EVALUATION_CRITERIA: {
                // In the case there is also an `EVALUATION_PHASE` section, we'll keep the evaluation
                // phases around
                const hasEvaluationPhaseSection = getAll().some((projectSection) => {
                    return projectSection.section_type === EVALUATION_PHASE;
                });
                if (!hasEvaluationPhaseSection) {
                    removeAll(EVALUATION_PHASES);
                }
                break;
            }
            case PRICING:
                removeAll(PRICE_TABLES);
                break;
            case QUESTIONNAIRE:
                removeAll(QUESTIONNAIRES);
                break;
            case DIVIDER:
            case SCOPE:
            case TERMS:
            case TEXT_AREA: {
                projectSectionToRemove.projectSubsections.forEach((projectSubsection) => {
                    removeAll(`${CRITERIA}.${projectSectionToRemove.id}_${projectSubsection.id}`);
                });
                break;
            }
            default:
                break;
        }

        // Remove the section itself
        remove(index);
    };

    sortUsingManualNumbering = () => {
        const {
            change,
            fields: { getAll },
            useSectionDividers,
        } = this.props;

        const projectSections = getAll();

        return change(
            PROJECT_SECTIONS,
            sortSections({
                sections: projectSections,
                useManualNumbering: true,
                useSectionDividers,
            })
        );
    };

    changeParentDivider(sectionIndex, newParentIndex) {
        const {
            change,
            fields: { getAll },
        } = this.props;

        const projectSections = getAll();

        const [sectionToMove] = projectSections.splice(sectionIndex, 1);

        const updatedParentIndex = getIndexToChangeParentDivider(
            newParentIndex,
            projectSections.length
        );

        projectSections.splice(updatedParentIndex, 0, sectionToMove);

        const renumberedSections = projectSections.map((section, idx) => ({
            ...section,
            orderById: idx + 1,
        }));

        change(PROJECT_SECTIONS, renumberedSections);
    }

    renderProjectSectionItems = () => {
        const {
            disabled,
            fields,
            isIntake,
            isSpecial,
            openSectionId,
            showFormHandler,
            showFormValidation,
            useManualNumbering,
            useSectionDividers,
        } = this.props;

        const dividerDropdownOptions = [];

        if (useSectionDividers && useManualNumbering) {
            fields.forEach((_member, index) => {
                const projectSection = fields.get(index);

                if (projectSection.section_type === DIVIDER) {
                    dividerDropdownOptions.push({
                        icon: 'indent',
                        label: projectSection.title,
                        value: index,
                    });
                }
            });
        }

        return fields.map((member, index) => {
            const projectSection = fields.get(index);
            const defaultSection = defaultSectionConfigsMap[projectSection[SECTION_TYPE]];
            const isFirstDivider =
                useSectionDividers && index === 0 && projectSection.section_type === DIVIDER;
            const sharedProps = {
                defaultSection,
                disabled,
                index,
                isIntake,
                isSpecial,
                member,
                onDelete: this.removeField,
                projectSection,
                showForm: openSectionId === projectSection.id,
                showFormHandler,
                showFormValidation,
                useManualNumbering,
                useSectionDividers,
            };

            // Drag and drop is disabled when manual numbering is used and on the first section divider
            if (useManualNumbering || isFirstDivider) {
                return (
                    <ProjectSectionItem
                        {...sharedProps}
                        changeParentDivider={this.changeParentDivider.bind(this)} // eslint-disable-line react/jsx-no-bind
                        dividerDropdownOptions={dividerDropdownOptions}
                        draggableProvided={{}}
                        key={projectSection.id}
                        sortUsingManualNumbering={this.sortUsingManualNumbering}
                    />
                );
            }

            return (
                <Draggable
                    draggableId={`projectSectionItem:${projectSection.id}`}
                    index={index}
                    key={projectSection.id}
                >
                    {(provided) => (
                        <ProjectSectionItem {...sharedProps} draggableProvided={provided} />
                    )}
                </Draggable>
            );
        });
    };

    render() {
        const {
            createProjectSectionError,
            isDirty,
            disabled,
            isDocBuilder,
            isIntake,
            isSpecial,
            fields: { getAll },
            meta,
            showFormValidation,
            updateTemplate,
            useSectionDividers,
        } = this.props;

        return (
            <>
                <DragDropContext onDragEnd={this.handleDragEnd}>
                    <Droppable
                        droppableId="projectSectionsList"
                        isDropDisabled={disabled}
                        type={PROJECT_SECTION}
                    >
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                style={getDndStyle(snapshot)}
                                {...provided.droppableProps}
                            >
                                <ListGroup>{this.renderProjectSectionItems()}</ListGroup>
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <ListError
                    className={this.styles.listError}
                    meta={meta}
                    showError={showFormValidation}
                />
                {(!isSpecial || isIntake) && (
                    <ProjectSectionAddButtons
                        addProjectSection={this.addProjectSection}
                        addTemplateSection={this.addTemplateSection}
                        disabled={disabled}
                        isDirty={isDirty}
                        isDocBuilder={isDocBuilder}
                        isIntake={isIntake}
                        projectSectionFormValues={getAll() || []}
                        showSharedSections
                        updateTemplate={updateTemplate}
                        useSectionDividers={useSectionDividers}
                    />
                )}
                {createProjectSectionError && (
                    <div className="error-block">{createProjectSectionError}</div>
                )}
            </>
        );
    }
}

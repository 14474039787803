import { redirect } from 'react-router-dom';

import { isLoggedIn } from '../../actions/auth';

/*
 * Redirects a user to the not-found page if they are not a government user or are not logged in.
 */
export default (getState) => async () => {
    const state = getState();
    const user = state.auth.get('user');

    if (!isLoggedIn(state) || !user?.get('government_id')) {
        return redirect('/not-found');
    }

    return null;
};

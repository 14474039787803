import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { ActionToolbarNavbarItem } from '../../../../components';
import { trackEvent } from '../../../../helpers';

export class AddendaButton extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool.isRequired,
        isEditor: PropTypes.bool.isRequired,
        onClick: PropTypes.func.isRequired,
    };

    handleClick = () => {
        const { onClick } = this.props;

        trackEvent('Add Addenda');
        onClick();
    };

    render() {
        const { isEditor, disabled } = this.props;
        return (
            <ActionToolbarNavbarItem
                className="action-toolbar-addenda-btn"
                disabled={disabled}
                editOnly
                isEditor={isEditor}
                onClick={this.handleClick}
                qaTag="projectActionMenu-addAddenda"
            >
                <i className="fa fa-file-text" /> Add Addenda
            </ActionToolbarNavbarItem>
        );
    }
}

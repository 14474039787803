import { loadGovernmentRetentionCodes } from '../../../actions/retention';

/**
 * If no retention codes are loaded, get the government's retention codes in state
 * @param {*} dispatch dispatch function to dispatch the action to load codes
 * @param {*} retentionCodes Existing retention codes
 * @param {*} user The user currently logged in
 */
export const loadRetentionCodes = (dispatch, retentionCodes, user) => {
    if (retentionCodes.length === 0) {
        dispatch(
            loadGovernmentRetentionCodes({
                governmentId: user.government_id,
            })
        );
    }
};

/**
 * Gets a formatted object of the retention policy that works with react-select
 * @param {*} newPolicy The policy to be located in the options
 * @param {*} policyOptions The policy options available in the government
 * @returns Policy object compatible with a react-select component.
 */
export const getFormattedRetentionPolicyFromId = (newPolicy, policyOptions) => {
    const currentOption = policyOptions.find((option) => option.id === newPolicy);
    if (currentOption) {
        return {
            ...currentOption,
            value: currentOption.id,
            label: currentOption.abbreviation,
        };
    }
    return null;
};

/**
 * Formats government retention codes to be compatible with SearchSelect component
 * @param {*} policyOptions Options for the select dropdown
 * @returns Array of policies compatible with SearchSelect component
 */
export const formatPolicyOptions = (policyOptions) =>
    policyOptions.map((policyOption) => ({
        value: policyOption.id,
        label: policyOption.abbreviation,
    }));

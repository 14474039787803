import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import qs from 'qs';

/**
 * @desc Loads an external search bar powered by CoProcure per PRO-1035.
 *  After submittal, user will be directed to the OGPro - CoProcure cooperative page.
 * @param {string} publicEntityName OPTIONAL: If included, will remove entity search from search bar.
 *  No public entity: CoProcure will add this as a field to search for the user to fill out.
 * @param {string} projectTitle OPTIONAL: Used for search placeholder text.
 *  No title: Search Placeholder text falls back to default.
 * @param {string} userZipCode OPTIONAL: Used when the user searches for better results.
 *  No zip: CoProcure will auto attempt to detect zip through IP.
 * @param {string} sizing OPTIONAL: Options are sm and xs. Will decrease size of the widget
 * @returns The div that the script will render the CoProcure Bar on. Practically, it returns the whole component.
 */
export const CooperativeContractBar = ({ publicEntityName, projectTitle, userZipCode, sizing }) => {
    // If we pass in an empty value, the search bar can get goofy and render "Search for Undefined".
    const queryString = qs.stringify({
        entity: publicEntityName,
        title: projectTitle,
        zip: userZipCode,
        size: sizing,
    });

    useEffect(() => {
        const coprocureWidget = document.querySelector('#widget-coprocure-opengov iframe');
        if (coprocureWidget) {
            return;
        }

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = `https://www.coprocure.us/widgets/opengov.js?${queryString}`;
        document.getElementsByTagName('head')[0].appendChild(script);
    }, []);

    return <div id="widget-coprocure-opengov" />;
};

CooperativeContractBar.propTypes = {
    publicEntityName: PropTypes.string,
    projectTitle: PropTypes.string,
    userZipCode: PropTypes.string,
    sizing: PropTypes.string,
};

import PropTypes from 'prop-types';
import React from 'react';

import { Button, UserProfilePicture } from '../../../../components';

export const EntityPositionsTableRow = ({
    onDelete,
    onEdit,
    position,
    position: { description, id, name, user },
}) => {
    const styles = require('../shared.scss');

    return (
        <tr>
            <td className={styles.settingsTableTruncatedCol}>{name}</td>
            <td className={styles.settingsTableTruncatedCol}>{description}</td>
            <td className={styles.settingsTableTruncatedCol}>
                <UserProfilePicture horizontal user={user} />
                &nbsp;&nbsp;
                {user.displayName}
            </td>
            <td className={styles.settingsTableCol}>
                <Button
                    bsSize="sm"
                    bsStyle="link"
                    onClick={() => onEdit(position)}
                    qaTag="entityPositionAdmin-edit"
                    zeroPadding
                >
                    <i className="fa fa-pencil" /> Edit
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                    bsSize="sm"
                    bsStyle="link"
                    onClick={() => onDelete(id)}
                    qaTag="entityPositionAdmin-delete"
                    zeroPadding
                >
                    <i className="text-danger fa fa-trash fa-lg" />
                </Button>
            </td>
        </tr>
    );
};

EntityPositionsTableRow.propTypes = {
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    position: PropTypes.shape({
        description: PropTypes.string,
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        user: PropTypes.shape({
            displayName: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

export const createNavItemId = (label) => {
    return `pro-nav-${label}`;
};

export const usePathUrl = (governmentId) => {
    const getPathUrl = (path) => `/governments/${governmentId}/${path}`;
    return { getPathUrl };
};

// override conflicting Bootstrap link styles
const StyledLink = styled(Link)`
    :hover,
    :focus {
        text-decoration: none;
        outline: none;
    }
`;

export const ReactRouterLink = React.forwardRef((props, ref) => (
    <StyledLink ref={ref} to={props.href} {...props} />
));

ReactRouterLink.propTypes = {
    href: PropTypes.string.isRequired,
};

import React, { useContext } from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames';

import { SDv2Drawer, CDSButton } from '../../../../../../../components';
import { ProjectCreateV2FunctionsContext } from '../../../context';
import { ProjectCreateManageSectionsDrawerContent } from '../DrawerContent';

export const ProjectCreateManageSectionsActionHeader = () => {
    const { initiateSneakyUpdate, manageSectionsDrawerOpened, toggleManageSectionsDrawerOpened } =
        useContext(ProjectCreateV2FunctionsContext);

    const styles = require('../index.scss');

    return (
        <>
            {createPortal(
                <SDv2Drawer
                    onBackdropClick={() => toggleManageSectionsDrawerOpened()}
                    open={manageSectionsDrawerOpened}
                >
                    <ProjectCreateManageSectionsDrawerContent />
                </SDv2Drawer>,

                document.body
            )}
            <div className={classnames(styles.container, styles.containerBordered)}>
                <div className={styles.col}>
                    <h4>Document Outline</h4>
                </div>
                <div className={classnames(styles.col, styles.textRight)}>
                    <CDSButton
                        onClick={() => {
                            initiateSneakyUpdate();
                            toggleManageSectionsDrawerOpened();
                        }}
                        qaTag="projectCreate-manageSections"
                        size="small"
                        variant="secondary"
                    >
                        Manage Sections
                    </CDSButton>
                </div>
            </div>
        </>
    );
};

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';

import { SelectedProposal } from './SelectedProposal';

export class SelectedProposals extends PureComponent {
    static propTypes = {
        canContract: PropTypes.bool,
        contractPath: PropTypes.string.isRequired,
        createContractHandler: PropTypes.func,
        isContractAdmin: PropTypes.bool,
        isGovernmentView: PropTypes.bool,
        proposalPath: PropTypes.string,
        proposals: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
            })
        ).isRequired,
        isVendorAdmin: PropTypes.bool.isRequired,
        showVendorListModal: PropTypes.func,
    };

    render() {
        const {
            canContract,
            contractPath,
            createContractHandler,
            isContractAdmin,
            isGovernmentView,
            proposalPath,
            proposals,
            isVendorAdmin,
            showVendorListModal,
        } = this.props;

        return (
            <ListGroup>
                {proposals.map((proposal) => (
                    <ListGroupItem key={proposal.id}>
                        <SelectedProposal
                            canContract={canContract}
                            contractPath={contractPath}
                            createContractHandler={createContractHandler}
                            isContractAdmin={isContractAdmin}
                            isGovernmentView={isGovernmentView}
                            isVendorAdmin={isVendorAdmin}
                            proposal={proposal}
                            proposalPath={proposalPath}
                            showVendorListModal={showVendorListModal}
                        />
                    </ListGroupItem>
                ))}
            </ListGroup>
        );
    }
}

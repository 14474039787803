import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { ARCHIVED, PUBLISHED } from '@og-pro/shared-config/reviewGroups';

import { RequisitionGroupTitleMenuButtons } from './RequisitionGroupTitleMenuButtons';
import { TemplateStatusLabel } from '../../../../components';

export const RequisitionGroupTitleMenu = ({
    deleting,
    disabled,
    requisitionGroup,
    requisitionGroup: { status },
    reviewGroupsPath,
    setShowFormValidation,
    submitFailed,
    title,
    toggleArchive,
    toggleLive,
    updateRequisitionGroup,
    updating,
}) => {
    const deleteError = useSelector((state) => state.requisitionGroups.get('deleteItemError'));
    const updateError = useSelector((state) => state.requisitionGroups.get('updateItemError'));
    const copyError = useSelector((state) => state.requisitionGroups.get('copyItemError'));

    const styles = require('./RequisitionGroupTitleMenu.scss');

    return (
        <div className={styles.titleContainer}>
            <div className="row">
                <div className="col-sm-7 col-md-8">
                    <h3 className={styles.title}>{title || 'Untitled'}</h3>
                    <TemplateStatusLabel
                        className={styles.statusLabel}
                        isArchived={status === ARCHIVED}
                        isPublished={status === PUBLISHED}
                    />
                </div>
                <div className="col-sm-5 col-md-4 text-right">
                    <RequisitionGroupTitleMenuButtons
                        deleting={deleting}
                        disabled={disabled}
                        requisitionGroup={requisitionGroup}
                        reviewGroupsPath={reviewGroupsPath}
                        setShowFormValidation={setShowFormValidation}
                        toggleArchive={toggleArchive}
                        toggleLive={toggleLive}
                        updateRequisitionGroup={updateRequisitionGroup}
                        updating={updating}
                    />
                </div>
            </div>
            {updateError && <div className="error-block text-right">{updateError}</div>}
            {submitFailed && (
                <div className="error-block text-right">
                    Please fix form errors before submitting
                </div>
            )}
            {deleteError && <div className="error-block text-right">{deleteError}</div>}
            {copyError && <div className="error-block text-right">{copyError}</div>}
        </div>
    );
};

RequisitionGroupTitleMenu.propTypes = {
    deleting: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    requisitionGroup: PropTypes.shape({
        status: PropTypes.number.isRequired,
    }).isRequired,
    reviewGroupsPath: PropTypes.string.isRequired,
    setShowFormValidation: PropTypes.func.isRequired,
    submitFailed: PropTypes.bool.isRequired,
    title: PropTypes.string,
    toggleArchive: PropTypes.func.isRequired,
    toggleLive: PropTypes.func.isRequired,
    updateRequisitionGroup: PropTypes.func.isRequired,
    updating: PropTypes.bool.isRequired,
};

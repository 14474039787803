import { Box, Link, Typography } from '@og-pro/ui';
import { lowerCase, upperFirst } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { getUserJS } from '../../../../../../../selectors';
import { contractStatusClass } from '../../../../../../../../components/helpers/statusHelper';

export const SupplementalInfo = ({ contract: { endDate, id, startDate, status } }) => {
    const { government_id: governmentId } = useSelector(getUserJS);

    const contractHref = `/governments/${governmentId}/contracts/${id}`;
    const statusClass = contractStatusClass(status);

    return (
        <Box>
            <Box display="flex" gap={0.5}>
                <Typography flex={1} variant="bodySmall">
                    {endDate ? 'Ends' : 'Starts'}:{' '}
                    {moment(endDate || startDate).format('MM/DD/YYYY')}
                </Typography>
                <Link fontWeight="medium" href={contractHref} target="_blank" underline="hover">
                    View Contract
                </Link>
            </Box>
            <Typography className={`text-${statusClass}`} fontWeight="semiBold" variant="bodySmall">
                {upperFirst(lowerCase(status))}
            </Typography>
        </Box>
    );
};

SupplementalInfo.propTypes = {
    contract: PropTypes.shape({
        endDate: PropTypes.string,
        id: PropTypes.number.isRequired,
        startDate: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
    }).isRequired,
};

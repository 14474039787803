import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import { getFilteredCommentsJS } from './selectors';
import { getDocumentsTabName } from '../ProjectNav/selectors';
import { getIntakePath, getReviewPath, isDocumentViewer } from '../selectors';
import connectData from '../../ConnectData';
import { getUserJS, getInvitedUsersJS } from '../../selectors';
import * as govCommentsActions from '../../../actions/govComments';
import { LoadingSpinner, LoadingError, PageTitle } from '../../../components';
import { CommentsList as CommentsListComponent } from '../../../components/GovApp';

function fetchData(getState, dispatch, location, params) {
    const projectId = Number.parseInt(params.projectId, 10);

    // Ensure that comment display is turned on for this page
    if (!getState().govComments.get('showComments')) {
        dispatch(govCommentsActions.showComments());
    }

    if (govCommentsActions.shouldLoadComments(getState())) {
        return dispatch(govCommentsActions.loadComments(projectId));
    }
}

// @connectData
const ConnectedCommentsList = () => {
    const baseLocation = useLocation();
    const params = useParams();
    const [searchParams] = useSearchParams();
    const query = Object.fromEntries(searchParams);
    const location = { ...baseLocation, query };
    const isIntake = !!location.pathname.match(/\/intake/);

    const dispatch = useDispatch();
    const projectPath = useSelector((state) =>
        isIntake
            ? getIntakePath(state, { location, params })
            : getReviewPath(state, { location, params })
    );
    const canComment = useSelector(isDocumentViewer);
    const documentsTabName = useSelector(getDocumentsTabName);
    const listFilter = useSelector((state) => state.govComments.get('listFilter'));
    const loadError = useSelector((state) => state.govComments.get('loadError'));
    const loading = useSelector((state) => state.govComments.get('loading'));
    const sectionComments = useSelector(getFilteredCommentsJS);
    const showResolved = useSelector((state) => state.govComments.get('showResolved'));
    const user = useSelector(getUserJS);
    const users = useSelector(getInvitedUsersJS);

    const addFollowersHandler = (threadId, field) => (formData) => {
        const followerIds = formData.followerIds || [];
        const userIds = followerIds.map((u) => u.value);
        return dispatch(govCommentsActions.addFollowers(threadId, userIds, field));
    };

    if (loading) {
        return <LoadingSpinner />;
    }
    if (loadError) {
        return <LoadingError error={loadError} />;
    }

    return (
        <>
            <PageTitle title="Comments List" />
            <CommentsListComponent
                addFollowers={addFollowersHandler}
                canComment={canComment}
                documentsTabName={documentsTabName}
                filterComments={(p) => dispatch(govCommentsActions.filterComments(p))}
                hideFollowersForm={(id, key) =>
                    dispatch(govCommentsActions.hideFollowersForm(id, key))
                }
                listFilter={listFilter}
                projectPath={projectPath}
                reopenThread={(id, key) => dispatch(govCommentsActions.reopenThread(id, key))}
                replyThread={(field) => dispatch(govCommentsActions.listReplyCommentThread(field))}
                resolveThread={(threadId, field, isModal) =>
                    dispatch(govCommentsActions.resolveThread(threadId, field, isModal))
                }
                sectionComments={sectionComments}
                showFollowersForm={(threadId, field, isModal) =>
                    dispatch(govCommentsActions.showFollowersForm(threadId, field, isModal))
                }
                showResolved={showResolved}
                toggleResolvedComments={() => dispatch(govCommentsActions.toggleResolvedComments())}
                user={user}
                users={users}
            />
        </>
    );
};

export const CommentsList = connectData(fetchData)(ConnectedCommentsList);

import React from 'react';
import MUIDialog from '@mui/material/Dialog';
import PropTypes from 'prop-types';
import { Box, Typography } from '@og-pro/ui';

import { CDSButton } from '../SDv2';

export const Dialog = ({
    allowOverflowedContent = false,
    children,
    closeButton = true,
    onClose,
    title,
    contentProps,
    ...rest
}) => {
    const extraProps = {};

    if (allowOverflowedContent) {
        extraProps.PaperProps = {
            style: { overflow: 'visible' },
        };
    }

    return (
        <MUIDialog
            disableEnforceFocus
            maxWidth="md"
            onClose={onClose}
            open
            {...extraProps}
            {...rest}
        >
            {!!title && (
                <Box
                    borderBottom="1px solid #D5D5D5"
                    display="flex"
                    justifyContent="space-between"
                    pb={2}
                    pt={3}
                    px={4}
                >
                    <Box flex={1} textAlign="center">
                        <Typography variant="h4">{title}</Typography>
                    </Box>
                    {closeButton && (
                        <Box textAlign="right">
                            <Box
                                component={CDSButton}
                                onClick={onClose}
                                p={0}
                                qaTag="dialog-close"
                                variant="text"
                            >
                                <i className="fa fa-times" />
                            </Box>
                        </Box>
                    )}
                </Box>
            )}
            <Box p={2} {...contentProps}>
                {children}
            </Box>
        </MUIDialog>
    );
};

Dialog.propTypes = {
    allowOverflowedContent: PropTypes.bool,
    children: PropTypes.node.isRequired,
    closeButton: PropTypes.bool,
    contentProps: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

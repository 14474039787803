const { listToDict } = require('../helpers');

const VENDOR_EVENTS_MAP = {
    proposalCreated: 'Response created',
    proposalDeleted: 'Response deleted',
    proposalView: 'Response view',
    proposalsList: 'Responses list',
    proposalSaved: 'Response saved',
    proposalSaveFailed: 'Response save failed',
    proposalDeadlinePassed: 'Response deadline has passed',
    proposalUneditable: 'Response is published, cannot be edited',
    proposalSubmitted: 'Response submitted',
    proposalUnsubmitted: 'Response unsubmitted',
    proposalQuestionnaireResponse: 'Response questionnaire response',
    proposalCreateAttachment: 'Response create attachment',
    proposalConfirmAddendum: 'Response confirm addendum',
    publicProjectView: 'Public project view',
    proposalUploadStarted: 'Response file upload started',
    proposalUploadSuccess: 'Response file upload success',
    proposalUploadFailed: 'Response file upload failed',
    downloadsView: 'Project downloads page view',
    addendaView: 'Project Addenda page view',
    questionAnswerView: 'Project Questions and Answers page view',
    followersView: 'Project Followers page view',
};

const VENDORS_EVENTS = Object.keys(VENDOR_EVENTS_MAP);
const VENDORS_EVENTS_DICT = listToDict(Object.keys(VENDOR_EVENTS_MAP));

module.exports = {
    VENDORS_EVENTS,
    VENDOR_EVENTS_MAP,
    VENDORS_EVENTS_DICT,
};

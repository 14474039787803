import PropTypes from 'prop-types';
import React from 'react';
import { Field, FieldArray, getFormSyncErrors } from 'redux-form';

import { useSelector } from 'react-redux';

import { fieldNames, flagResponseFieldNames, radioOptions } from '../../constants';
import { HelpBlock, RadioGroup } from '../../../../../../components';
import { formConfig } from '../../form';

const FlagResponsesFieldArray = ({ disabled, fields, showFormValidation }) => {
    const styles = require('./index.scss');

    const normalizeBooleans = (value) => {
        return !!(value === 'true' || value === true);
    };

    const formErrors = useSelector((state) => getFormSyncErrors(formConfig.form)(state));
    const flagResponsesError = formErrors?.flagResponsesSummary;

    return (
        <>
            {fields.map((member, index) => {
                const { flag, id } = fields.get(index);
                return (
                    <div className={styles.flag} key={id}>
                        {flag.name}
                        <Field
                            className={styles.radioGroup}
                            component={RadioGroup}
                            disabled={disabled}
                            groupLabel={flag.name}
                            inline
                            name={`${member}.${flagResponseFieldNames.IS_ENABLED}`}
                            normalize={normalizeBooleans}
                            options={radioOptions}
                            showValidation={showFormValidation}
                            useOpenGovStyle
                        />
                    </div>
                );
            })}
            {showFormValidation && flagResponsesError && (
                <HelpBlock className={styles.error}>
                    <i className="fa fa-exclamation-triangle" />
                    &nbsp; {flagResponsesError}
                </HelpBlock>
            )}
        </>
    );
};

FlagResponsesFieldArray.propTypes = {
    disabled: PropTypes.bool,
    fields: PropTypes.object,
    showFormValidation: PropTypes.bool,
};

export const AdditionalInformation = ({ disabled, showFormValidation }) => {
    const styles = require('./index.scss');

    return (
        <div>
            <h4>Are any of the following true? *</h4>
            <div className={styles.flags}>
                <FieldArray
                    component={FlagResponsesFieldArray}
                    disabled={disabled}
                    name={fieldNames.FLAG_RESPONSES}
                    showFormValidation={showFormValidation}
                />
            </div>
        </div>
    );
};

AdditionalInformation.propTypes = {
    disabled: PropTypes.bool,
    showFormValidation: PropTypes.bool,
};

import { redirect } from 'react-router-dom';

import { loadGov } from '../../actions/publicProject';
import { isGovUser } from '../../actions/auth';

export default (getState, dispatch, allowInternal = false) =>
    async ({ params }) => {
        return dispatch(loadGov(params.governmentCode)).then(() => {
            const state = getState();
            const government = state.publicProject.get('government');
            const user = state.auth.get('user');

            const displayPublicVendorInformation = government.get('displayPublicVendorInformation');

            if (!displayPublicVendorInformation && !allowInternal) {
                return redirect('/not-found');
            }

            // if allowInternal is true, we allow internal users to view the page even if the government has disabled public vendor information
            const hasValidUser = user && isGovUser(state, String(government.get('id')));
            if (!displayPublicVendorInformation && allowInternal && !hasValidUser) {
                return redirect('/not-found');
            }

            return null;
        });
    };

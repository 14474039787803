import { userSocketActionType } from './constants';

export const JOIN = `${userSocketActionType}/JOIN`;
export const LEAVE = `${userSocketActionType}/LEAVE`;

// This action is only received by a broadcast from the socket server.
// The message originates from the API server and tells client when it has new notifications.
export const NEW_INBOX_NOTIFICATION = 'userSocket/server/NEW_INBOX_NOTIFICATION';

export function subscribeToUserSocketNamespace(userId) {
    return { type: JOIN, userId };
}

export function unsubscribeToUserSocketNamespace(userId) {
    return { type: LEAVE, userId };
}

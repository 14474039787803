import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ListGroupItem } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Field } from 'redux-form';

import {
    contactFieldNames,
    mapContactFields,
    mappedUserFields,
} from '@og-pro/shared-config/contacts';

import { getUserSelectOptions } from './selectors';
import { fieldNamesDict } from '../constants';
import { getContactSelectOptions, getUsersJS } from '../../../selectors';
import { changeContactForm, showContactModal } from '../../../../actions/contacts';
import { Button, ContactInfo, OutlineButton, SearchSelect } from '../../../../components';

const { FIRST_NAME, LAST_NAME } = contactFieldNames;

const { TAG_ID, USER_ID } = fieldNamesDict;

const mapStateToProps = (state) => {
    return {
        contactTypeOptions: getContactSelectOptions(state),
        users: getUsersJS(state),
        userSelectOptions: getUserSelectOptions(state),
    };
};

const mapDispatchToProps = {
    changeContactForm,
    showContactModal,
};

// @connect
class ConnectedContractContact extends PureComponent {
    static propTypes = {
        change: PropTypes.func.isRequired,
        changeContactForm: PropTypes.func.isRequired,
        contactTypeOptions: PropTypes.array.isRequired,
        disabled: PropTypes.bool,
        fieldName: PropTypes.string.isRequired,
        fields: PropTypes.shape({
            get: PropTypes.func.isRequired,
            remove: PropTypes.func.isRequired,
        }).isRequired,
        formName: PropTypes.string.isRequired,
        index: PropTypes.number.isRequired,
        showContactModal: PropTypes.func.isRequired,
        users: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
            })
        ).isRequired,
        userSelectOptions: PropTypes.array.isRequired,
    };

    static defaultProps = {
        disabled: false,
    };

    constructor(props) {
        super(props);

        const contractContact = props.fields.get(props.index);

        this.state = {
            editing: !contractContact.id,
            manualEntry: !!contractContact.id && !contractContact[USER_ID],
        };
    }

    get styles() {
        return require('./index.scss');
    }

    handleSelectUserChange = (userSelectOptionValue) => {
        const { fieldName, formName, users } = this.props;

        if (userSelectOptionValue) {
            const selectedUser = users.find((user) => user.id === userSelectOptionValue);
            this.props.changeContactForm(
                selectedUser,
                formName,
                mapContactFields(null, `${fieldName}.`)
            );
        }
    };

    handleToggleEditingClick = () => {
        this.setState((prevState) => {
            return {
                editing: !prevState.editing,
            };
        });
    };

    handleToggleEntryModeClick = () => {
        const { change, fieldName } = this.props;

        // Clear out current user info and pop up modal when switching to manual mode
        if (!this.state.manualEntry) {
            change(`${fieldName}.${USER_ID}`, null);
            this.showContactModal();
        }

        this.setState((prevState) => {
            return {
                manualEntry: !prevState.manualEntry,
            };
        });
    };

    showContactModal = () => {
        const { fieldName, disabled } = this.props;

        if (!disabled) {
            this.props.showContactModal({
                disabled,
                fieldAccessorPrefix: `${fieldName}.`,
                optional: true,
            });
        }
    };

    renderContactError = ({ meta }) => {
        if (meta.error) {
            return <div className="error-block">{meta.error}</div>;
        }

        return null;
    };

    renderContactEntryError = ({ meta, show }) => {
        const { disabled } = this.props;

        if (show) {
            return (
                <OutlineButton
                    bsSize="sm"
                    bsStyle="info"
                    disabled={disabled || meta.invalid}
                    onClick={this.handleToggleEditingClick}
                    tooltip={
                        meta.invalid
                            ? 'Contact entry is invalid, please update the invalid fields'
                            : undefined
                    }
                >
                    Done
                </OutlineButton>
            );
        }

        return null;
    };

    renderDisplay() {
        const { contactTypeOptions, disabled, fields, index } = this.props;

        const contractContact = fields.get(index);

        const selectedTypeOption = contactTypeOptions.find((contactTypeOption) => {
            return contactTypeOption.value === contractContact[TAG_ID];
        });

        return (
            <div className="row">
                <div className="col-xs-12 col-sm-4">
                    <strong>{get(selectedTypeOption, 'label')}</strong>
                    <br />
                    <Button
                        bsSize="sm"
                        bsStyle="link"
                        className={this.styles.editButton}
                        disabled={disabled}
                        onClick={this.handleToggleEditingClick}
                    >
                        <i className="fa fa-pencil" /> Edit
                    </Button>
                </div>
                <div className="col-xs-12 col-sm-8">
                    <ContactInfo {...contractContact} fieldNames={mappedUserFields} />
                </div>
            </div>
        );
    }

    renderEditing() {
        const { contactTypeOptions, disabled, fieldName, fields, index, userSelectOptions } =
            this.props;

        const { manualEntry } = this.state;

        const contractContact = fields.get(index);
        const contactFirstName = contractContact[FIRST_NAME] || '';
        const contactLastName = contractContact[LAST_NAME] || '';

        return (
            <div className="row">
                <div className="col-xs-12 col-sm-4">
                    <Field
                        backspaceRemovesValue={false}
                        component={SearchSelect}
                        disabled={disabled}
                        label="Contact Type"
                        name={`${fieldName}.${TAG_ID}`}
                        options={contactTypeOptions}
                        placeholder="Select type"
                    />
                </div>
                <div className="col-xs-12 col-sm-6">
                    {!manualEntry && (
                        <Field
                            component={SearchSelect}
                            disabled={disabled}
                            formClassName={this.styles.userSearchSelect}
                            label="Select a User"
                            name={`${fieldName}.${USER_ID}`}
                            onChange={this.handleSelectUserChange}
                            options={userSelectOptions}
                            placeholder="Select Existing User"
                        />
                    )}
                    <div>
                        {manualEntry && (
                            <div>
                                <span className={this.styles.manualEntryName}>
                                    {contactFirstName || contactLastName ? (
                                        <>
                                            {contactFirstName && `${contactFirstName} `}
                                            {contactLastName}
                                        </>
                                    ) : (
                                        'Name not provided'
                                    )}
                                </span>
                                &nbsp;&nbsp;
                                <Button
                                    bsSize="sm"
                                    bsStyle="link"
                                    className={this.styles.manualEditButton}
                                    disabled={disabled}
                                    onClick={this.showContactModal}
                                    zeroPadding
                                >
                                    <i className="fa fa-pencil" /> Edit
                                </Button>
                                <div className={`text-muted ${this.styles.manualEntryLabel}`}>
                                    Manual Entry
                                </div>
                            </div>
                        )}
                        <div className={`row ${this.styles.userFormEditButtons}`}>
                            <div className="col-xs-6">
                                <Button
                                    bsStyle="link"
                                    onClick={this.handleToggleEntryModeClick}
                                    qaTag="contractContact-entry"
                                    zeroPadding
                                >
                                    {manualEntry
                                        ? 'Select an Existing User'
                                        : 'Manually Enter Contact'}
                                </Button>
                            </div>
                            {!manualEntry && !!contractContact[USER_ID] && (
                                <div className="col-xs-6 text-right">
                                    <span className="pseudoLink" onClick={this.showContactModal}>
                                        Edit Contact Information
                                    </span>
                                </div>
                            )}
                        </div>
                        {/* Needed to properly display error between manual and select views */}
                        <Field
                            component={this.renderContactError}
                            name={`${fieldName}.contactError`}
                        />
                    </div>
                </div>
                <div className={`col-xs-12 col-sm-2 ${this.styles.actionContainer}`}>
                    <Field
                        component={this.renderContactEntryError}
                        name={`${fieldName}.contactError`}
                        show={!!contractContact.id}
                    />
                    &nbsp;
                    <Button
                        aria-label="Remove Button"
                        bsSize="sm"
                        bsStyle="link"
                        disabled={disabled}
                        onClick={() => fields.remove(index)}
                        qaTag="contractContract-removeButton"
                    >
                        <i className="fa fa-lg fa-trash text-danger" />
                    </Button>
                </div>
            </div>
        );
    }

    render() {
        return (
            <ListGroupItem>
                {this.state.editing ? this.renderEditing() : this.renderDisplay()}
            </ListGroupItem>
        );
    }
}

export const ContractContact = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedContractContact);

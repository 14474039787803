import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { CommentThread } from '..';
import { Button } from '../..';

export const CommentSubsection = (props) => {
    const navigate = useNavigate();

    const viewItemHandler = () => {
        const { projectPath, projectSectionId, threadKey } = props;

        navigate(`${projectPath}/document?section=${projectSectionId}&field=${threadKey}`);
    };

    const {
        addFollowers,
        canComment,
        hideFollowersForm,
        projectFieldData,
        reopenThread,
        replyThread,
        resolveThread,
        showFollowersForm,
        threads,
        threadKey,
        user,
        users,
    } = props;

    const CommentThreads = threads.map((thread) => (
        <CommentThread
            addFollowersError={thread.listAddFollowersError}
            addFollowersHandler={addFollowers(thread.id, threadKey)}
            canComment={canComment}
            comments={thread.threadComments}
            followers={thread.followers}
            hideFollowersFormHandler={() => hideFollowersForm(thread.id, threadKey)}
            key={thread.id}
            reopenThreadHandler={() => reopenThread(thread.id, threadKey)}
            replyError={thread.listReplyError}
            replyThreadHandler={replyThread}
            resolveError={thread.listResolveError}
            resolveThreadHandler={() => resolveThread(thread.id, threadKey)}
            resolved={thread.resolved}
            resolvedAt={thread.resolvedAt}
            resolvedBy={thread.resolvedBy}
            resolving={thread.listResolving}
            show
            showFollowersForm={thread.listShowFollowersForm}
            showFollowersFormHandler={() => showFollowersForm(thread.id, threadKey)}
            threadId={thread.id}
            user={user}
            users={users}
        />
    ));

    const styles = require('./CommentSubsection.scss');

    return (
        <div>
            <div className="row">
                <div className="col-xs-12">
                    <div className={styles.containerBorder} />
                </div>
                <div className={`col-sm-4 ${styles.leftCol}`}>
                    <h4 className="text-muted">Comments on {projectFieldData.title}</h4>
                    <Button
                        bsSize="sm"
                        className={styles.viewBtn}
                        onClick={viewItemHandler}
                        qaTag="commentSubsection-viewItem"
                    >
                        <span className="text-primary">
                            <i className="fa fa-reply" /> View Item
                        </span>
                    </Button>
                </div>
                <div className="col-sm-8 col-lg-7">{CommentThreads}</div>
            </div>
        </div>
    );
};

CommentSubsection.propTypes = {
    addFollowers: PropTypes.func.isRequired,
    canComment: PropTypes.bool.isRequired,
    hideFollowersForm: PropTypes.func.isRequired,
    projectFieldData: PropTypes.shape({
        title: PropTypes.string.isRequired,
    }).isRequired,
    projectPath: PropTypes.string.isRequired,
    projectSectionId: PropTypes.number.isRequired,
    reopenThread: PropTypes.func.isRequired,
    replyThread: PropTypes.func.isRequired,
    resolveThread: PropTypes.func.isRequired,
    showFollowersForm: PropTypes.func.isRequired,
    threadKey: PropTypes.string.isRequired,
    threads: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired,
};

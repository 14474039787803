import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button } from '../../../../Button/Button';
import { resendApproval } from '../../../../../actions/approvals';

const mapDispatchToProps = {
    dispatchResendApproval: resendApproval,
};

const mapStateToProps = (state) => ({
    updating: state.approvals.get('updating'),
    updateError: state.approvals.get('updateError'),
});

// @connect
const ConnectedResendApprovalModalBody = ({
    approvalId,
    dispatchResendApproval,
    hideModal,
    name,
    pending,
    updating,
    updateError,
}) => {
    const styles = require('../ApprovalsModal.scss');

    if (pending) {
        return (
            <div className="text-center text-success">
                <i className="fa fa-3x fa-history" />
                <h3>Approval Resent</h3>
                <div className={styles.confirmationInfo}>
                    {name} has been sent an email to request approval
                </div>
                <Button onClick={hideModal}>Close</Button>
            </div>
        );
    }

    return (
        <div>
            <div className={styles.markApproved}>
                <p className="text-danger">
                    <strong>WARNING: </strong>
                    You are about to reset and resend the approval of {name}.
                </p>
                <p>
                    Upon resending approval, the approver will be emailed so they are aware that you
                    have requested subsequent approval.
                </p>
            </div>
            {updateError && <div className="text-danger">{updateError}</div>}
            <div className={`text-center ${styles.buttons}`}>
                <Button
                    bsSize="large"
                    bsStyle="success"
                    className={styles.actionButton}
                    disabled={updating}
                    onClick={() => dispatchResendApproval(approvalId)}
                >
                    <i className="fa fa-history" /> Resend Approval
                </Button>
                <Button onClick={hideModal}>Cancel</Button>
            </div>
        </div>
    );
};

ConnectedResendApprovalModalBody.propTypes = {
    approvalId: PropTypes.number.isRequired,
    dispatchResendApproval: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    pending: PropTypes.bool.isRequired,
    updating: PropTypes.bool.isRequired,
    updateError: PropTypes.string,
};

export const ResendApprovalModalBody = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedResendApprovalModalBody);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { SectionTitle } from '../../../../../components';
import { ProposalDocumentsListDisplay } from '../../../../../components/GovApp';

export class ProposalDocumentsReview extends PureComponent {
    static propTypes = {
        proposalDocuments: PropTypes.array.isRequired,
    };

    render() {
        const { proposalDocuments } = this.props;
        return (
            <div>
                <SectionTitle
                    info="List of documents vendors should provide as part of their response"
                    title="Response Documents"
                />
                <ProposalDocumentsListDisplay proposalDocuments={proposalDocuments} />
            </div>
        );
    }
}

export const menuStates = {
    NONE: 'none',
    SELECT: 'select',
    INTAKE: 'intake',
    PROJECT: 'project',
    CONTRACT: 'contract',
};

export const CONTRACT_ID = 'contractId';
export const PROJECT_ID = 'projectId';

export const qaTagPageName = 'requisitionRelated';

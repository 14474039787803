import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { getAccuracyScoring } from './helper';
import { ScoreDisplay } from './ScoreDisplay';

export const LoadingDisplay = ({ project }) => {
    const styles = require('./index.scss');
    const { score, suggestions } = getAccuracyScoring(project);

    const [isInitialLoading, setIsInitialLoading] = useState(true);
    const [showScoreCalculating, setShowScoreCalculating] = useState(false);

    // This is purely to provide some visual progress during the very long wait time for the data
    useEffect(() => {
        // Initial loading message will display until score calculating timeout is reached
        setTimeout(() => {
            setShowScoreCalculating(true);
        }, 4000);
        // Score calculating message will display until this timeout is reached
        setTimeout(() => {
            setIsInitialLoading(false);
        }, 8000);
    }, []);

    if (isInitialLoading) {
        return (
            <div className={styles.loadingContainer}>
                {showScoreCalculating
                    ? 'Calculating AI Accuracy Score...'
                    : 'Generating data from project for recommending vendors...'}
            </div>
        );
    }

    return (
        <div className={styles.loadingContainer}>
            <ScoreDisplay score={score} suggestion={suggestions[0]} />
        </div>
    );
};

LoadingDisplay.propTypes = {
    project: PropTypes.object.isRequired,
};

import { replaceNewline } from '../../../../../utils';

export const modalTitles = [
    'Scope of Work Guide (1/5)',
    'Intro to Tool (2/5)',
    'Defining Goals (3/5)',
    'Questions (4/5)',
    'Examples',
];

export const scopeExamples = [
    {
        title: 'Project Scope',
        description:
            'There are five parts to this project: understanding our ' +
            'users; developing a design standard; creating key pages & temp' +
            'lates; supporting continuing iteration; and, ongoing services.' +
            ' We are looking for a partner who can help with each of these ' +
            'parts.',
    },
    {
        title: 'Understanding Our Users',
        description: replaceNewline(
            'Goal: Based on extensive but anecdotal feedback, we do not fee' +
                "l that the City's website currently works well for many of it" +
                's users, ' +
                'whether they are members of the public or employees of the Cit' +
                'y. We also lack a full picture of what current and potential u' +
                'sers would find attractive, intuitive, and accessible.\n\nThe ' +
                'goal phase of this project is to help us understand the needs ' +
                'and preferences of current and potential City website users. Thi' +
                's would cover overall site architecture, the top 1015 typical ' +
                'user scenarios, and specific design elements. While we expect ' +
                'this phase to be research informed, it should not be entirely ' +
                'research-led; we expect and encourage our partner to take a le' +
                'adership position on organizational and design elements.\n\nEx' +
                'pected Deliverable: We would expect our partner to deliver (1)' +
                ' user research on the current/ proposed City website. As part of' +
                ' the user research, the City would expect both a summary repor' +
                't as well as the raw data and instruments (e.g. surveys) used ' +
                'to collect the data. Support: To help with this phase, we will' +
                ' provide access to conference rooms for user testing, but any ' +
                'required specialized user testing facilities should be include' +
                'd as part of your proposal. In addition, we will provide websi' +
                'te analytics, access to citizen requests from Citizen’s Connec' +
                't and 311/hotline, and website feedback from visitors collecte' +
                'd with Foresee and Feedbackify.'
        ),
    },
    {
        title: 'Developing a Design Standard',
        description: replaceNewline(
            'Goal: With a better understanding of our user needs, we want t' +
                'o develop a comprehensive digital design standard for the webs' +
                'ite. We want this design standard to be reflective of the valu' +
                'es of the City and the aspirations of this project.\n\nThe des' +
                'ign must be easy to maintain and update, and be flexible to ad' +
                'apt to changing user needs and circumstances. Design elements ' +
                'must be optimized for all modern browsers, and built using mob' +
                'ile responsive design. All design elements must also comply wi' +
                'th ADA accessibility guidelines.\n\nExpected Deliverable: We w' +
                'ould expect our partner to deliver three products during this ' +
                'phase: (1) a proposed overall site architecture for City website' +
                '; (2) recommended refined user journeys for 510 of the most re' +
                'quested services; (3) a brand manual that leverages original d' +
                'esign assets to provide (i) principles around website design (' +
                'e.g., use graphics rather than text as much as possible), (ii)' +
                ' an overall digital brand for the City, and (iii) a style guide ' +
                'for the City that includes a pattern library for key digital a' +
                'ssets (including specific colors, fonts, icons). Support: To h' +
                'elp with this phase, we will provide its current style guide. ' +
                'In addition, we will discuss a budget for licensed fonts durin' +
                'g the design process.'
        ),
    },
    {
        title: 'Developing a Design Standard',
        description: replaceNewline(
            'Goal: The goal of this phase is to design and implement key pa' +
                'ges and page templates so that prominent pages and at least on' +
                'e typical user path can be relaunched in the winter of 2015, a' +
                'nd to prepare the City’s web team to continue to implement cha' +
                "nges to the City's website following the relaunch.\n\nExpecte" +
                'd Deliver' +
                'able: We would expect our partner to design and help implement' +
                ' each of the key pages & templates listed in Attachment B or an ' +
                'equivalent amount of work. Please note that the list in Append' +
                'ix B may change based on the user research and design standard' +
                ' work, but it is representative of the amount of work we would' +
                ' expect to accomplish in this phase. Implementation includes b' +
                'oth technical assistance as well as some help in presenting th' +
                'e designs back to key internal partners. We expect the migrati' +
                'on of content and microsites onto the new City website platform ' +
                'to continue after 2015. As a result, we would also expect our ' +
                'partner to work with the City’s web team to develop a migratio' +
                'n strategy to incorporate additional user paths and necessary ' +
                'content.\n\nSupport: To support this phase, the City’s web tea' +
                'm will work with the partner on implementing specific pages.'
        ),
    },
    {
        title: 'Supporting Continued Iteration',
        description: replaceNewline(
            "Goal: We want City's website to get better with age, even after it" +
                's relaunch in the winter of 2015.\n\nExpected Deliverable: We ' +
                'would expect our partner to provide in this phase: (1) trainin' +
                'g on best practices for conducting user testing and incorporat' +
                'ing user feedback to inform content development and organizati' +
                'on on an ongoing basis; (2) at least one round of feedback and' +
                ' usability testing following the public relaunch of City website' +
                ' done in collaboration with the City’s webteam.\n\nSupport: To' +
                ' support this phase, the City’s web team will be an active par' +
                'ticipant on all aspects in order to strengthen their own skill' +
                "s and carry the evolution of the City's website forward."
        ),
    },
    {
        title: 'On-Going Services',
        description: replaceNewline(
            "Goal: To continue the overhaul of City's website, there may be add" +
                'itional services that we will seek from your after we’ve compl' +
                'eted the first four parts of this work together.\n\nExpected D' +
                'eliverable: Continued support for two years on user experience' +
                ' and design issues.\n\nSupport: To support this phase, the Cit' +
                "y's web team will be an active participant on all aspects in " +
                'order to strengthen their own skills and carry the evolution o' +
                "f the City's website forward."
        ),
    },
    {
        title: 'On-Going Services',
        description: replaceNewline(
            'We’ve written out these five parts of this partnership sequent' +
                'ially. This was done for clarity. We do not expect them to nec' +
                'essarily be done this way. In fact, we expect that the process' +
                ' of conducting user research, setting design standards, and cr' +
                'eating pages will itself be iterative.'
        ),
    },
];

import { v4 as UUIDv4 } from 'uuid';

import { trackEvent } from '../helpers';

export const SHOW = 'notification/SHOW';
export const HIDE = 'notification/HIDE';

/**
 * Action for showing a notification alert
 * @param  {string} text The notification to display
 * @param  {object} [opts={}]
 * @param  {string} [opts.type] Bootstrap type to apply to alert
 * @param  {number} [opts.duration] Timeout duration to apply to alert
 * @return {Function}
 */
export function showNotification(text, opts = {}) {
    return (dispatch) => {
        const notificationType = opts.type || 'success';
        const defaultTimeout = notificationType === 'danger' ? 6000 : 3000;
        const timeoutDuration = opts.duration || defaultTimeout;

        window.scroll(0, 0);

        const hideTimeoutId = setTimeout(() => dispatch({ type: HIDE }), timeoutDuration);
        dispatch({ type: SHOW, hideTimeoutId, notificationType, text });
    };
}

export function hideNotification() {
    return {
        type: HIDE,
    };
}

export const REMOVE_SNACKBAR_MESSAGE = 'notification/REMOVE_SNACKBAR_MESSAGE';
export const ADD_SNACKBAR_MESSAGE = 'notification/ADD_SNACKBAR_MESSAGE';

export function removeSnackbarMessage(snackbarMessageData) {
    return {
        type: REMOVE_SNACKBAR_MESSAGE,
        snackbarKey: snackbarMessageData.key,
    };
}

/**
 * Displays a snackbar stack notification (can have multiple displayed at onComplete)
 * @param {string} message   Notification message to display
 * @param {object} [opts={}] Notification display options
 * @param {number} [opts.dismissAfter] Sets a custom timeout for the notification
 * @param {boolean} [opts.isError] Pre-defined style for displaying an error notification
 * @param {boolean} [opts.isSuccess] Pre-defined style for displaying a success notification
 * @param {object} [opts.snackbarStyle] Sets a custom timeout for the notification
 * @return {object}
 */
export function showSnackbar(message, opts = {}) {
    if (opts.isSuccess) {
        opts.color = 'success';
    }

    if (opts.isError) {
        opts.dismissAfter = opts.dismissAfter || 4000; // Use longer default display time for errors
        opts.color = 'error';
    }

    return {
        type: ADD_SNACKBAR_MESSAGE,
        data: { message, key: UUIDv4(), ...opts },
    };
}

export const SHOW_CONNECTION_ALERT = 'notification/SHOW_CONNECTION_ALERT';
export const HIDE_CONNECTION_ALERT = 'notification/HIDE_CONNECTION_ALERT';

export function showConnectionAlert(opts = {}) {
    return {
        type: SHOW_CONNECTION_ALERT,
        text: "It looks like you've gone offline. Please check your internet connection.",
        alertType: 'warning',
        ...opts,
    };
}

export function hideConnectionAlert() {
    return {
        type: HIDE_CONNECTION_ALERT,
    };
}

export function reconnectionAlert() {
    return (dispatch) => {
        dispatch(
            showConnectionAlert({
                text: 'Connection Restored!',
                alertType: 'success',
            })
        );
        trackEvent('Connection Restored');
        setTimeout(() => dispatch(hideConnectionAlert()), 3000);
    };
}

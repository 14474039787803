import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '../../../../components';

export const FlagsCreateButton = ({ onClick }) => {
    return (
        <Button bsStyle="primary" onClick={onClick} qaTag="flagAdmin-create">
            <i className="fa fa-plus" /> Add Flag
        </Button>
    );
};

FlagsCreateButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};

import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { formValueSelector } from 'redux-form';

import { fieldNames, form } from '../../../../forms/constants';
import {
    fieldNames as formFieldNames,
    templateFieldNames,
} from '../../../../forms/TemplateForm/constants';
import { ProjectContentSection } from '../../../../forms/TemplateProjectContentForm/components/index';
import { TemplateEditV2FunctionsContext, TemplateEditV2NavContext } from '../../../context';
import { TemplateEditNoSections } from '../../components/NoSections';

const { PROJECT_SECTIONS, USE_SECTION_DIVIDERS, TEMPLATE } = fieldNames;
const { USE_MANUAL_NUMBERING } = formFieldNames;
const { IS_REVERSE_AUCTION } = templateFieldNames;

const selector = formValueSelector(form);

export const TemplateEditV2DocumentBuilderSDv2SectionsAdapter = () => {
    const editContext = useContext(TemplateEditV2FunctionsContext);
    const { activeSectionId } = useContext(TemplateEditV2NavContext);
    const useSectionDividers = useSelector((state) => selector(state, USE_SECTION_DIVIDERS));
    const useManualNumbering = useSelector((state) => selector(state, USE_MANUAL_NUMBERING));
    const isReverseAuction = useSelector((state) =>
        selector(state, `${TEMPLATE}.${IS_REVERSE_AUCTION}`)
    );
    const projectSections = useSelector((state) => {
        return selector(state, PROJECT_SECTIONS);
    });

    if (projectSections.length === 0) {
        return <TemplateEditNoSections />;
    }

    const projectSection = projectSections[activeSectionId];

    if (!projectSection) {
        return null;
    }

    return (
        <ProjectContentSection
            addConditionalLogicHandler={editContext.addConditionalLogicHandler}
            array={editContext.array}
            change={editContext.change}
            disabled={editContext.disabled}
            isIntake={editContext.isIntake}
            isReverseAuction={isReverseAuction}
            key={projectSection.id}
            projectId={editContext.projectId}
            projectSection={projectSection}
            showForm
            showFormHandler={() => {}}
            showFormValidation={editContext.showFormValidation}
            tagOptions={editContext.tagOptions}
            templateVariableOptions={editContext.templateVariableOptions}
            useManualNumbering={useManualNumbering}
            useSectionDividers={useSectionDividers}
        />
    );
};

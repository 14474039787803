import React from 'react';
import { Field } from 'redux-form';
import { Box } from '@og-pro/ui';

import { TIMELINE_CONFIG } from '../../../../../../containers/GovApp/constants';
import { InputText, CDSButton } from '../../../../..';
import { limitTextLength } from '../../../../../../Forms/normalizers';
import { PROP_TYPES } from './constants';

export const AuctionInput = (props) => {
    const { change, disabled, showValidation, timeline } = props;
    const {
        auctionExtensionGracePeriodField,
        auctionExtensionTimeField,
        hasAuctionExtension,
        hasAuctionExtensionField,
    } = timeline;

    const onGracePeriodClick = () => {
        if (hasAuctionExtension) {
            change(`${TIMELINE_CONFIG}.${auctionExtensionGracePeriodField}`, null);
            change(`${TIMELINE_CONFIG}.${auctionExtensionTimeField}`, null);
        }

        change(`${TIMELINE_CONFIG}.${hasAuctionExtensionField}`, !hasAuctionExtension);
    };

    return (
        <Box>
            {!hasAuctionExtension && (
                <CDSButton
                    noPadding
                    onClick={onGracePeriodClick}
                    qaTag="timelineForm-hasAuctionExtension"
                    size="small"
                    variant="text"
                >
                    <i className="fa fa-plus" /> Add Grace Period and Extension Time
                </CDSButton>
            )}

            {hasAuctionExtension && (
                <>
                    <Field
                        component={InputText}
                        disabled={disabled}
                        hasFeedback={false}
                        help={`Time period prior to the end of the scheduled auction end date during which any new
                    bid will extend the auction by the below Extension Time`}
                        label="Extension Grace Period (in minutes)"
                        name={`${TIMELINE_CONFIG}.${auctionExtensionGracePeriodField}`}
                        normalize={limitTextLength(2)}
                        qaTag="timelineForm-auctionExtensionGracePeriod"
                        showValidation={showValidation}
                        type="number"
                        useOpenGovStyle
                    />
                    <Field
                        component={InputText}
                        disabled={disabled}
                        hasFeedback={false}
                        help="Time period to extend the auction when a bid is placed during the grace period"
                        label="Extension Time (in minutes)"
                        name={`${TIMELINE_CONFIG}.${auctionExtensionTimeField}`}
                        normalize={limitTextLength(2)}
                        qaTag="timelineForm-auctionExtensionTime"
                        showValidation={showValidation}
                        type="number"
                        useOpenGovStyle
                    />
                </>
            )}
            {hasAuctionExtension && (
                <Box textAlign="right">
                    <CDSButton
                        noPadding
                        onClick={onGracePeriodClick}
                        qaTag="timelineForm-removeHasAuctionExtension"
                        size="small"
                        variant="text"
                    >
                        <i className="fa fa-times" /> Remove Grace Period and Extension Time
                    </CDSButton>
                </Box>
            )}
        </Box>
    );
};

AuctionInput.propTypes = PROP_TYPES;

import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { basePageStyles } from '../../../../styles';

export const pageContainerStyles = {
    ...basePageStyles,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
};

export const pageBodyStyles = {
    height: '100%',
    width: '100%',
    background: capitalDesignTokens.foundations.colors.white,
    borderRadius: 1,
    padding: 4,
    border: `1px solid ${capitalDesignTokens.semanticColors.border.primary}`,
};

export const pageSectionContainerStyles = {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
};

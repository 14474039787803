export const projectsInStagesColors = [
    '#6e4e9e',
    '#886ab5',
    '#f0ad4e',
    '#52a552',
    '#df8a13',
    '#5cb85c',
    '#80c780',
    '#5bc0de',
];

export const pieChartStatusColors = ['#449d44', '#f0d54e', '#f0ad4e', '#a94442'];

import classnames from 'classnames';
import { upperCase } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { projectStatusesDict } from '@og-pro/shared-config/projects';

import { StatusLabel } from '..';
import { statusClass, statusIcon } from '../helpers/statusHelper';

const { CLOSED, DRAFT, FINAL, REQUEST_DRAFT, REQUEST_REVIEW, REVIEW } = projectStatusesDict;

export const ProjectStatusLabel = ({ className, hideIcon, isDocBuilder, status }) => {
    const getDisplayStatusName = () => {
        // Handle intake status display
        if (status === REQUEST_DRAFT) {
            return DRAFT;
        }
        if (status === REQUEST_REVIEW) {
            return REVIEW;
        }

        // Move all documents that have progressed beyond builder stage to `FINAL`
        if (isDocBuilder && ![DRAFT, REVIEW, CLOSED].includes(status)) {
            return FINAL;
        }

        return status;
    };

    const displayStatusName = getDisplayStatusName();
    const styles = require('./index.scss');

    const observedStatus = isDocBuilder ? displayStatusName : status;

    return (
        <StatusLabel
            bsStyle={statusClass(observedStatus)}
            className={classnames(
                styles.statusLabel,
                styles[statusClass(observedStatus)],
                className
            )}
        >
            {!hideIcon && (
                <>
                    <i className={`fa fa-fw fa-${statusIcon(observedStatus)}`} />
                    &nbsp;
                </>
            )}
            {upperCase(displayStatusName)}
        </StatusLabel>
    );
};

ProjectStatusLabel.propTypes = {
    className: PropTypes.string,
    hideIcon: PropTypes.bool,
    isDocBuilder: PropTypes.bool,
    status: PropTypes.string.isRequired,
};

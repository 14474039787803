import React from 'react';
import PropTypes from 'prop-types';
import { fieldTypesArray } from '@og-pro/shared-config/customFormService/customField';

import { fieldTypesData } from '../Form/constants';

export const FieldTypeOptions = ({ fieldType, disabled }) => {
    const FieldTypeOptionsComponent = fieldTypesData[fieldType].optionsComponent;
    if (!FieldTypeOptionsComponent) {
        return null;
    }

    return <FieldTypeOptionsComponent disabled={disabled} />;
};

FieldTypeOptions.propTypes = {
    fieldType: PropTypes.oneOf(fieldTypesArray).isRequired,
    disabled: PropTypes.bool,
};

import { createSelector } from 'reselect';

const getContractComplaintModalData = (state) => state.contracts.get('contractComplaintModalData');

export const getContractComplaintModalDataJS = createSelector(
    [getContractComplaintModalData],
    (contractComplaintModalData) => {
        if (contractComplaintModalData) {
            return contractComplaintModalData.toJS();
        }
        return {};
    }
);

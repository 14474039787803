import { Card, CardContent } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React from 'react';

export const Panel = ({ children }) => {
    return (
        <Card sx={{ overflow: 'visible' }}>
            <CardContent>{children}</CardContent>
        </Card>
    );
};

Panel.propTypes = {
    children: PropTypes.node.isRequired,
};

export const COMPANY_NAME = 'contractParty.companyName';
export const CONTACT_DISPLAY_NAME = 'contactDisplayName';
export const CONTRACT_BUDGET_AMOUNT = 'budget.amount';
export const CONTRACT_BUDGET_ISSUED = 'budget.issued';
export const CONTRACT_ID = 'contractId';
export const CONTRACT_RENEWALS = 'contractRenewals';
export const DEPARTMENT_HEAD = 'departmentHead';
export const DEPARTMENT_NAME = 'departmentName';
export const DURATION_TYPE = 'durationType';
export const END_DATE = 'endDate';
export const FUNDING_SOURCE_TAGS = 'fundingSourceTags';
export const HAS_CLAIM = 'hasClaim';
export const HAS_PROTEST = 'hasProtest';
export const IS_COOPERATIVE = 'isCooperative';
export const IS_EMERGENCY = 'isEmergency';
export const IS_PIGGYBACK = 'isPiggyback';
export const IS_PUBLIC = 'isPublic';
export const NOTE = 'note';
export const PROCUREMENT_CLASSIFICATION_TAG = 'procurementClassificationTag';
export const PROCUREMENT_DISPLAY_NAME = 'procurementDisplayName';
export const REBID = 'rebid';
export const REQUISITION_IDENTIFIER = 'requisitionIdentifier';
export const START_DATE = 'startDate';
export const STATUS = 'status';
export const SUMMARY = 'summary';
export const TAGS = 'tags';
export const TITLE = 'title';
export const VENDOR_ASSIGNED_NO = 'vendorAssignedNo';

export const form = 'contractListSearch';

export const SEARCH = 'search';

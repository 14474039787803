import React from 'react';
import { Box, Typography, Modal, Grid, IconButton, Button } from '@og-pro/ui';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

import { useNavigate, useParams } from 'react-router-dom';

import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import { useSelector } from 'react-redux';

import { hexToRGB } from '@og-pro/shared-config/helpers';

import {
    customFieldsModalContainerStyle,
    customFieldsTileContainerStyle,
    customFieldsTileStyle,
} from './styles';
import { createCustomFieldDisplayData } from './createCustomFieldDisplayData';
import { getRequisitionsAdminPath } from '../../selectors';

const CustomFieldsModal = ({ onClose, open, onFieldTypeClick }) => {
    const navigate = useNavigate();
    const params = useParams();
    const requisitionsAdminPath = useSelector((state) =>
        getRequisitionsAdminPath(state, { params })
    );

    function handleFieldTypeClick(fieldType) {
        onClose();
        onFieldTypeClick?.(fieldType);
        navigate(`${requisitionsAdminPath}/custom-fields/create/${fieldType}`);
    }

    return (
        <Modal
            aria-describedby="field-modal-description"
            aria-labelledby="field-modal-title"
            onClose={onClose}
            open={open}
        >
            <Box sx={customFieldsModalContainerStyle}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        pb: 2,
                    }}
                >
                    <Typography id="field-modal-title" variant="h3">
                        Choose a Type of Field to Create
                    </Typography>
                    <IconButton
                        onClick={onClose}
                        qaTag="requisitionAdminCustomFields-modalClose"
                        sx={{
                            width: 24,
                            height: 24,
                        }}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </Box>

                <Box
                    sx={{
                        flex: 1,
                        pt: 1,
                        pb: 1.5,
                        width: '100%',
                    }}
                >
                    <Grid container spacing={1}>
                        {Object.keys(createCustomFieldDisplayData).map((fieldType) => {
                            const { label, icon: IconComponent } =
                                createCustomFieldDisplayData[fieldType];

                            return (
                                <Grid item key={label} xs={4}>
                                    <Button
                                        color="secondary"
                                        onClick={() => handleFieldTypeClick(fieldType)}
                                        qaTag={`requisitionAdminCustomFields-${fieldType}`}
                                        sx={customFieldsTileContainerStyle}
                                        variant="text"
                                    >
                                        <Box alignItems="center" display="flex" gap={1}>
                                            <Box
                                                sx={{
                                                    background: hexToRGB(
                                                        capitalDesignTokens.semanticColors
                                                            .dataVisualization.sequence450,
                                                        0.3
                                                    ),
                                                    borderRadius: 1,
                                                    height: capitalDesignTokens.foundations.units
                                                        .unit3,
                                                    width: capitalDesignTokens.foundations.units
                                                        .unit3,
                                                    color: capitalDesignTokens.semanticColors
                                                        .highlights.tealDark,
                                                }}
                                            >
                                                <IconComponent
                                                    style={{
                                                        padding:
                                                            capitalDesignTokens.foundations.units
                                                                .unitHalf,
                                                    }}
                                                />
                                            </Box>
                                            <Typography sx={customFieldsTileStyle} variant="h5">
                                                {label}
                                            </Typography>
                                        </Box>
                                    </Button>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Box>
            </Box>
        </Modal>
    );
};

CustomFieldsModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onFieldTypeClick: PropTypes.func,
};

export default CustomFieldsModal;

import { useSelector } from 'react-redux';

import { useOgFinancials } from './useOgFinancials';
import { glAccountBudgetSummary as glAccountBudgetSummaryQuery } from './queries';
import { getGovernmentReqSetting } from '../../selectors/govApp';

// References
// spent: budget that has been used
// committed: encumbered budget
// inProcess: pre-encumbered budget
// available: budget that can be used

const select =
    (settings, onlyCheck) =>
    ({ glAccountBudgetSummary }) => {
        if (!settings) return 0;
        const { useBudgetGroup, usePreEncumbrance } = settings;
        if (!glAccountBudgetSummary) {
            if (onlyCheck) {
                return 0;
            }
            return null;
        }
        const {
            revised_budget_amount: totalBudgetAmount,
            account_group_revised_budget_amount: totalGroupBudgetAmount,
            transaction_amount: spentBudgetAmount,
            account_group_transaction_amount: spentGroupBudgetAmount,
            encumbrance_amount: committedBudgetAmount,
            account_group_encumbrance_amount: committedGroupBudgetAmount,
            req_preencumbrance_amount: inProcessBudgetAmount,
            account_group_req_preencumbrance_amount: inProcessGroupBudgetAmount,
            available_budget: availableBudgetAmount,
            account_group_available_budget: availableGroupBudgetAmount,
            available_budget_with_req: availableBudgetAmountConsideringInProcess,
            account_group_available_budget_with_req: availableGroupBudgetAmountConsideringInProcess,
            account_group_name: accountGroupName,
            account: { account_compressed: accountCompressed },
            belongs_to_account_group: hasAccountGroup,
        } = glAccountBudgetSummary;
        let availableAmountUsedForBudgetCheck = availableBudgetAmount;
        let availableGroupAmountUsedForBudgetCheck = availableGroupBudgetAmount;
        let availableAccountAmountUserForBudgetCheck = availableBudgetAmount;
        if (useBudgetGroup && accountGroupName) {
            // If no account group, then fallback to the account level budget amounts
            if (usePreEncumbrance) {
                availableAmountUsedForBudgetCheck = availableGroupBudgetAmountConsideringInProcess;
            }
            availableAmountUsedForBudgetCheck = availableGroupBudgetAmount;
        } else if (usePreEncumbrance) {
            availableAmountUsedForBudgetCheck = availableBudgetAmountConsideringInProcess;
            availableGroupAmountUsedForBudgetCheck = availableGroupBudgetAmountConsideringInProcess;
            availableAccountAmountUserForBudgetCheck = availableBudgetAmountConsideringInProcess;
        }

        if (onlyCheck) {
            return availableAmountUsedForBudgetCheck;
        }

        return {
            accountLevel: {
                totalBudgetAmount,
                spentBudgetAmount,
                committedBudgetAmount,
                inProcessBudgetAmount,
                availableBudgetAmount,
                availableBudgetAmountConsideringInProcess,
                accountCompressed,
                availableAmountUsedForBudgetCheck: availableAccountAmountUserForBudgetCheck,
            },
            groupLevel: {
                name: accountGroupName,
                totalBudgetAmount: totalGroupBudgetAmount,
                spentBudgetAmount: spentGroupBudgetAmount,
                committedBudgetAmount: committedGroupBudgetAmount,
                inProcessBudgetAmount: inProcessGroupBudgetAmount,
                availableBudgetAmount: availableGroupBudgetAmount,
                availableBudgetAmountConsideringInProcess:
                    availableGroupBudgetAmountConsideringInProcess,
                availableAmountUsedForBudgetCheck: availableGroupAmountUsedForBudgetCheck,
            },
            availableAmountUsedForBudgetCheck,
            fallbackToAccountBudgetLevel: useBudgetGroup && !accountGroupName,
            hasAccountGroup,
        };
    };

export const useAccountBudgetCheck = (
    accountPseudoKey,
    fiscalPeriodYear,
    onlyCheck = true,
    useHistoricalValues = false,
    { enabled = true } = {}
) => {
    const settings = useSelector(getGovernmentReqSetting);
    return useOgFinancials({
        queryKey: 'glAccountBudgetSummary',
        query: glAccountBudgetSummaryQuery,
        params: { accountPseudoKey, fiscalPeriodYear },
        enabled:
            !!accountPseudoKey &&
            !!fiscalPeriodYear &&
            !!settings &&
            !useHistoricalValues &&
            enabled,
        select: select(settings, onlyCheck),
    });
};

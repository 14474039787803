import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { checklistStatusTypes } from '@og-pro/shared-config/checklists';

import { ContractChecklistForm } from './ContractChecklistForm';
import { ContractChecklistReview } from './ContractChecklistReview';
import { isContractEditor } from '../selectors';
import connectData from '../../ConnectData';
import { loadChecklist, resetChecklist } from '../../../actions/checklists';
import { LoadingError, LoadingSpinner } from '../../../components';
import { getChecklistJS } from '../../../selectors/app';
import { getContractChecklistsPath } from '../../../selectors/govApp';
import { useChecklistSocket } from '../../hooks/checklistHooks';

const { DRAFT } = checklistStatusTypes;

function fetchData(getState, dispatch, location, params) {
    const checklistId = Number.parseInt(params.checklistId, 10);
    return dispatch(loadChecklist(checklistId));
}

// @connectData
const ConnectedContractChecklist = () => {
    const params = useParams();
    const location = useLocation();

    const dispatch = useDispatch();
    const loading = useSelector((state) => state.checklists.get('loadingChecklist'));
    const loadError = useSelector((state) => state.checklists.get('loadChecklistError'));
    const checklist = useSelector(getChecklistJS);
    const isEditor = useSelector(isContractEditor);
    const contractChecklistsPath = useSelector((state) =>
        getContractChecklistsPath(state, { location, params })
    );

    const checklistId = Number.parseInt(params.checklistId, 10);

    // Subscribe to checklist socket
    useChecklistSocket(checklistId);

    useEffect(() => {
        // On leave hook
        return () => {
            dispatch(resetChecklist());
        };
    }, []);

    if (loading) {
        return <LoadingSpinner />;
    }

    if (loadError || !checklist) {
        return <LoadingError error={loadError} />;
    }

    if (checklist.status === DRAFT) {
        return (
            <ContractChecklistForm
                checklist={checklist}
                contractChecklistsPath={contractChecklistsPath}
                initialValues={checklist}
                isEditor={isEditor}
            />
        );
    }

    return (
        <ContractChecklistReview
            checklist={checklist}
            contractChecklistsPath={contractChecklistsPath}
            isEditor={isEditor}
        />
    );
};

export const ContractChecklist = connectData(fetchData)(ConnectedContractChecklist);

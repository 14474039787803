import { createSelector } from 'reselect';

const getRawSnackbarMessages = (state) => state.notification.get('snackbarMessages');

export const getSnackbarMessages = createSelector(
    [getRawSnackbarMessages],
    (rawSnackbarMessages) => {
        if (rawSnackbarMessages) {
            return rawSnackbarMessages.toJS();
        }
        return [];
    }
);

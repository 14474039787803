import { Box } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RequisitionRelationsDisplay } from './RelationsDisplay';
import { RequisitionRelationsMenu } from './RelationsMenu';
import { getIsLoadingReqRelations, getLoadReqRelationsError } from './selectors';
import { containerStyle } from './styles';
import {
    deleteRequisitionRelation,
    loadRequisitionRelations,
} from '../../../../../actions/requisitionsApprovals';
import { LoadingError, LoadingSpinner } from '../../../../../components';

export const RequisitionRelations = ({ requisition }) => {
    const [deleting, setDeleting] = useState(false);
    const [selectedReqRelations, setSelectedReqRelations] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadRequisitionRelations(requisition.id));
    }, []);

    const isLoading = useSelector(getIsLoadingReqRelations);
    const loadError = useSelector(getLoadReqRelationsError);

    if (isLoading) {
        return <LoadingSpinner useOpenGovStyle />;
    }

    if (loadError) {
        return <LoadingError error={loadError} useOpenGovStyle />;
    }

    const setIsEditing = (isEditing) => setSelectedReqRelations(isEditing ? new Set() : null);
    const isEditing = selectedReqRelations !== null;
    const hasSelections = isEditing && selectedReqRelations.size > 0;

    const selectHandler = (reqRelationId) =>
        setSelectedReqRelations((reqRelationsSet) => {
            if (reqRelationsSet.has(reqRelationId)) {
                return new Set([...reqRelationsSet].filter((id) => id !== reqRelationId));
            }
            return new Set([...reqRelationsSet, reqRelationId]);
        });

    const deleteHandler = async () => {
        if (selectedReqRelations?.size > 0) {
            try {
                setDeleting(true);
                await Promise.all(
                    [...selectedReqRelations].map((reqRelationId) =>
                        dispatch(deleteRequisitionRelation(requisition.id, reqRelationId))
                    )
                );
            } finally {
                setSelectedReqRelations(null);
                setDeleting(false);
            }
        }
    };

    return (
        <Box {...containerStyle}>
            <RequisitionRelationsMenu
                deleteHandler={deleteHandler}
                disabled={deleting}
                hasSelections={hasSelections}
                isEditing={isEditing}
                requisition={requisition}
                setIsEditing={setIsEditing}
            />
            <RequisitionRelationsDisplay
                disabled={deleting}
                isEditing={isEditing}
                selectHandler={selectHandler}
            />
        </Box>
    );
};

RequisitionRelations.propTypes = {
    requisition: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }).isRequired,
};

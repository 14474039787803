import { omit } from 'lodash';
import qs from 'qs';

// the S3 elements for the preview are public
// the way S3 signs the url is fine if you want to download the file
// but for some reason breaks in an iframe
// adding this extra query param makes it work. Found no way to do this with the S3 sdk
export const formatS3SignedURLForIframePreview = (url) => {
    if (!url) {
        return '';
    }

    const [host, rawQueryString] = url.split('?');
    const parsedQueryString = qs.parse(rawQueryString);

    // Remove content disposition query param that is dependent on the filename, which can be
    // variable length. Can cause URL to be too long which the iframe can't handle.
    const cleanQueryString = qs.stringify(
        omit(parsedQueryString, ['response-content-disposition'])
    );

    return `${host}?X-Amx-ACL=public-read&${cleanQueryString}`;
};

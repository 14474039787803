import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm, formValueSelector, Field } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { disqualifiedOptions, fieldNames, form, OTHER } from './constants';
import { validate } from './validate';
import { InputText, SearchSelect, Toggle } from '../../../components';

const { DISQUALIFIED_REASON, DISQUALIFIED_REASON_EXTRA, NOTIFY_VENDOR } = fieldNames;

const formSelector = formValueSelector(form);

const mapStateToProps = (state, props) => {
    const onSubmit = (rawData) => {
        // If `OTHER` is selected, take the value from `DISQUALIFIED_REASON_EXTRA` instead
        const disqualifiedReason =
            rawData[DISQUALIFIED_REASON] === OTHER
                ? rawData[DISQUALIFIED_REASON_EXTRA]
                : rawData[DISQUALIFIED_REASON];

        const data = {
            [DISQUALIFIED_REASON]: disqualifiedReason,
        };

        if (!props.isGovernmentSubmitted) {
            data[NOTIFY_VENDOR] = rawData[NOTIFY_VENDOR];
        }

        // Call the submit handler with the formatted data
        props.onSubmit(data);
    };

    return {
        disqualifiedReason: formSelector(state, DISQUALIFIED_REASON),
        initialValues: { [NOTIFY_VENDOR]: !props.isGovernmentSubmitted },
        onSubmit, // `onSubmit` must be specified to form for remote submission to work
    };
};

const formConfig = {
    form,
    validate,
};

// @connect
// @reduxForm
class ConnectedDisqualifyProposalForm extends Component {
    static propTypes = {
        disqualifiedReason: PropTypes.string,
        disabled: PropTypes.bool,
        handleSubmit: PropTypes.func.isRequired,
        isGovernmentSubmitted: PropTypes.bool.isRequired,
    };

    render() {
        const { disqualifiedReason, disabled, handleSubmit, isGovernmentSubmitted } = this.props;

        const styles = require('./index.scss');

        return (
            <form className={styles.container} onSubmit={handleSubmit}>
                <p className={`${styles.text} text-muted`}>
                    Disqualifying this response will exclude it from the evaluation and selection
                    process.
                    <br />
                    <br />
                    Please specify why the response is being disqualified.
                </p>
                <Field
                    component={SearchSelect}
                    disabled={disabled}
                    name={DISQUALIFIED_REASON}
                    options={disqualifiedOptions}
                    placeholder="Select an option"
                />
                {disqualifiedReason === OTHER && (
                    <Field
                        component={InputText}
                        disabled={disabled}
                        hasFeedback={false}
                        label="Other Reason for Disqualifying"
                        name={DISQUALIFIED_REASON_EXTRA}
                        placeholder="Enter text"
                        type="textarea"
                    />
                )}
                {!isGovernmentSubmitted && (
                    <div className="text-center">
                        <Field
                            component={Toggle}
                            disabled={disabled}
                            label="Notify vendor that their response was disqualified?"
                            labelInline
                            labelInlineTogglePosition="right"
                            name={NOTIFY_VENDOR}
                        />
                    </div>
                )}
            </form>
        );
    }
}

export const DisqualifyProposalForm = compose(
    connect(mapStateToProps),
    reduxForm(formConfig)
)(ConnectedDisqualifyProposalForm);

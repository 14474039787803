import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { compose } from 'redux';

import { getAttachment, getPrevAttachment, getNextAttachment } from '../selectors';
import { getProposalForEvaluationJS, getProposalJS } from '../../selectors';
import { SectionTitle, BackButton } from '../../../../components';
import { DocumentViewer, DocumentViewerControls } from '../../../../components/GovApp';

const mapStateToProps = (state, props) => {
    const viewerPath = props.location.pathname.replace(/\/\d+\/?$/i, '');
    return {
        attachment: getAttachment(state, props),
        nextAttachment: getNextAttachment(state, props),
        prevAttachment: getPrevAttachment(state, props),
        viewerPath,
    };
};

// @connect
class ConnectedProposalDocumentViewer extends Component {
    static propTypes = {
        attachment: PropTypes.shape({
            fileExtension: PropTypes.string,
            name: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        }),
        isProposalEvaluation: PropTypes.bool,
        nextAttachment: PropTypes.shape({
            name: PropTypes.string.isRequired,
            paramsNumber: PropTypes.number.isRequired,
        }),
        prevAttachment: PropTypes.shape({
            name: PropTypes.string.isRequired,
            paramsNumber: PropTypes.number.isRequired,
        }),
        proposal: PropTypes.shape({
            companyName: PropTypes.string.isRequired,
        }),
        viewerPath: PropTypes.string.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const {
            attachment,
            isProposalEvaluation,
            nextAttachment,
            prevAttachment,
            proposal,
            viewerPath,
        } = this.props;

        /**
         * The proposal will always be loaded on normal navigation, but on back
         * button nav the components get rendered before data is re-rendered
         * Possibly related:
         * https://github.com/ReactTraining/react-router/issues/5072
         */
        if (!proposal) {
            return null;
        }

        if (!attachment) {
            return <SectionTitle title="No document selected" />;
        }

        const prevRoute = prevAttachment && `${viewerPath}/${prevAttachment.paramsNumber}`;
        const nextRoute = nextAttachment && `${viewerPath}/${nextAttachment.paramsNumber}`;
        const prevRouteName = prevAttachment && prevAttachment.name;
        const nextRouteName = nextAttachment && nextAttachment.name;

        return (
            <div>
                <div className="row">
                    <div className="col-md-10 col-md-offset-1">
                        {!isProposalEvaluation && (
                            <BackButton text="Back to Documents List" to={viewerPath} />
                        )}
                        <SectionTitle
                            info={`${proposal.companyName} Response`}
                            title={attachment.name}
                        />
                        <DocumentViewer
                            fileExtension={attachment.fileExtension}
                            url={attachment.url}
                        />
                    </div>
                </div>
                <div className={this.styles.controls}>
                    <DocumentViewerControls
                        downloadUrl={attachment.url}
                        nextRoute={nextRoute}
                        nextRouteName={nextRouteName}
                        prevRoute={prevRoute}
                        prevRouteName={prevRouteName}
                    />
                </div>
            </div>
        );
    }
}

export const ProposalDocumentViewer = compose(
    withRouter,
    connect(mapStateToProps)
)(ConnectedProposalDocumentViewer);

// Sourcing review container
const ProposalDocumentViewerSourcingAndEvaluation = (props) => {
    return <ProposalDocumentViewer {...props} />;
};
ProposalDocumentViewer.Sourcing = connect((state) => {
    return {
        proposal: getProposalJS(state),
    };
})(ProposalDocumentViewerSourcingAndEvaluation);

// Evaluation review container
ProposalDocumentViewer.Evaluation = connect((state) => {
    return {
        proposal: getProposalJS(state),
    };
})(ProposalDocumentViewerSourcingAndEvaluation);

// Proposal Evaluation review container
const ProposalDocumentViewerProposalEvaluation = (props) => {
    return <ProposalDocumentViewer {...props} />;
};
ProposalDocumentViewer.ProposalEvaluation = connect((state) => {
    return {
        isProposalEvaluation: true,
        proposal: getProposalForEvaluationJS(state),
    };
})(ProposalDocumentViewerProposalEvaluation);

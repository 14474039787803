import { Link, Typography } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { showVendorProfileModal } from '../../../actions/vendorProfile';
import { NO_ADDRESS_FOUND_TEXT } from '../../../constants/requisitionsCreate';
import { getIsRequisitionUsingFMS } from '../../../selectors/govApp';

export const VendorInfo = ({
    associatedVendorData,
    hasInvalidAddress,
    invalidAddressColor = 'warning.main',
    loading,
    vendor: { vendorAddress, vendorName },
}) => {
    const dispatch = useDispatch();
    const hasFMS = useSelector(getIsRequisitionUsingFMS);

    if (hasInvalidAddress && hasFMS) {
        return (
            <>
                <Typography fontWeight="medium">{vendorName}</Typography>
                <Typography color={invalidAddressColor}>{NO_ADDRESS_FOUND_TEXT}</Typography>
            </>
        );
    }

    if (!associatedVendorData) {
        if (loading) {
            return (
                <Typography color="secondary">
                    <i className="fa fa-spin fa-spinner" /> Loading vendor data...
                </Typography>
            );
        }
        return (
            <>
                <Typography fontWeight="medium">{vendorName}</Typography>
                <Typography
                    color={vendorAddress === NO_ADDRESS_FOUND_TEXT ? 'secondary' : undefined}
                >
                    {vendorAddress}
                </Typography>
            </>
        );
    }

    const {
        id,
        organization: { name, website },
    } = associatedVendorData;

    return (
        <>
            <div>
                <Link
                    fontWeight="medium"
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        dispatch(showVendorProfileModal(id));
                    }}
                    underline="hover"
                    variant="bodyDefault"
                >
                    {name}
                </Link>
            </div>
            <Typography variant="bodySmall">{vendorAddress}</Typography>
            {website && <Typography variant="bodySmall">{website}</Typography>}
        </>
    );
};

VendorInfo.propTypes = {
    associatedVendorData: PropTypes.shape({
        id: PropTypes.number.isRequired,
        organization: PropTypes.shape({
            name: PropTypes.string.isRequired,
            website: PropTypes.string,
        }).isRequired,
    }),
    hasInvalidAddress: PropTypes.bool,
    invalidAddressColor: PropTypes.string,
    loading: PropTypes.bool,
    vendor: PropTypes.shape({
        vendorName: PropTypes.string.isRequired,
        vendorAddress: PropTypes.string.isRequired,
    }).isRequired,
};

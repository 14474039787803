import { fieldNames } from './constants';

const { IS_PAUSED_REASON } = fieldNames;

export function validate(values) {
    const errors = {};

    if (values[IS_PAUSED_REASON] && values[IS_PAUSED_REASON].length > 255) {
        errors[IS_PAUSED_REASON] =
            `Reason is too long. Maximum ${values[IS_PAUSED_REASON].length}/255 characters`;
    }

    return errors;
}

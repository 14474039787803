import moment from 'moment-timezone';
import React from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import { VersionForm } from '../VersionForm';
import { getDocumentVersionsJS } from '../../selectors';
import { getDocumentReviewPath } from '../../../selectors';
import { LinkContainer, Main, UserProfilePicture } from '../../../../../components';

export const VersionsList = () => {
    const baseLocation = useLocation();
    const params = useParams();
    const [searchParams] = useSearchParams();
    const location = { ...baseLocation, query: Object.fromEntries(searchParams) };

    const documentVersions = useSelector(getDocumentVersionsJS);
    const revisionVersionsPath = useSelector(
        (state) => `${getDocumentReviewPath(state, { location, params })}/revisions/versions`
    );
    const styles = require('./index.scss');

    return (
        <Main>
            <h2 className={styles.sectionTitle}>Document Versions</h2>
            <VersionForm />
            <ListGroup>
                {documentVersions.map((version) => (
                    <LinkContainer
                        key={version.id}
                        to={`${revisionVersionsPath}/${version.project_audit_id}`}
                    >
                        <ListGroupItem>
                            {/* For now disable any actions on the versions */}
                            <span className="pull-right text-muted">
                                <i className="fa fa-lg fa-angle-right" />
                            </span>
                            <UserProfilePicture
                                className={styles.userPicture}
                                showTooltip
                                user={version.user}
                            />
                            <p className={styles.versionName}>{version.name}</p>
                            <em className="text-muted">
                                {moment(version.created_at).format('lll')}
                            </em>
                        </ListGroupItem>
                    </LinkContainer>
                ))}
            </ListGroup>
        </Main>
    );
};

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';

import { validate } from './validate';
import { fieldNames } from '../constants';
import { Button, InputText } from '../../../../components';

const { TITLE } = fieldNames;

const formConfig = {
    validate,
};

// @reduxForm
class ConnectedStandardDocumentRenameForm extends PureComponent {
    static propTypes = {
        closeForm: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        handleSubmit: PropTypes.func.isRequired,
        pristine: PropTypes.bool.isRequired,
        serverError: PropTypes.string,
    };

    render() {
        const { closeForm, disabled, handleSubmit, pristine, serverError } = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <Field
                    component={InputText}
                    disabled={disabled}
                    hasFeedback={false}
                    label="Document Title"
                    name={TITLE}
                    placeholder="Enter Title"
                    type="text"
                />
                <div className="text-right">
                    <Button bsStyle="link" disabled={disabled} onClick={closeForm}>
                        Cancel
                    </Button>
                    <Button bsStyle="primary" disabled={disabled || pristine} type="submit">
                        Update
                    </Button>
                </div>
                {serverError && <div className="error-block">{serverError}</div>}
            </form>
        );
    }
}

export const StandardDocumentRenameForm = reduxForm(formConfig)(
    ConnectedStandardDocumentRenameForm
);

import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import React, { Component } from 'react';
import { ListGroupItem, Panel, PanelGroup } from 'react-bootstrap';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { Typography } from '@og-pro/ui';

import { Tooltip } from '../../../../../components/Tooltip';
import ProjectPanel from './ProjectPanel';

class ConnectedNeedsAttentionProjectsList extends Component {
    static propTypes = {
        evaluationSubscription: PropTypes.bool.isRequired,
        pendingProjects: PropTypes.array.isRequired,
        router: PropTypes.object.isRequired,
        sourcingSubscription: PropTypes.bool.isRequired,
        unreleasedAddendaProjects: PropTypes.array.isRequired,
        unresolvedQuestionsProjects: PropTypes.array.isRequired,
        unsubmittedScorecardsProjects: PropTypes.array.isRequired,
    };

    constructor(props) {
        super(props);

        this.handleSelect = this.handleSelect.bind(this);

        this.state = {
            activeKey: '',
        };
    }

    componentDidMount() {
        const panelToOpen = () => {
            if (this.props.unresolvedQuestionsProjects.length > 0) return 0;
            if (this.props.unreleasedAddendaProjects.length > 0) return 1;
            if (this.props.pendingProjects.length > 0) return 2;
            if (this.props.unsubmittedScorecardsProjects > 0) return 3;
        };

        this.setState({
            activeKey: panelToOpen(),
        });
    }

    get styles() {
        return require('./index.scss');
    }

    handleSelect(activeKey) {
        const {
            unresolvedQuestionsProjects,
            unreleasedAddendaProjects,
            pendingProjects,
            unsubmittedScorecardsProjects,
        } = this.props;

        const projectsCount = [
            unresolvedQuestionsProjects.length,
            unreleasedAddendaProjects.length,
            pendingProjects.length,
            unsubmittedScorecardsProjects.length,
        ];

        if (projectsCount[activeKey] > 0) {
            this.setState({ activeKey });
        }
    }

    renderBadgeIcon(numItems, dangerStyle, tooltipText) {
        return (
            <Tooltip placement="top" tooltip={tooltipText}>
                <span
                    className={classnames(
                        'fa-stack',
                        this.styles.circleIcon,
                        dangerStyle ? this.styles.danger : this.styles.warning
                    )}
                >
                    <i className={classnames('fa fa-circle fa-stack-2x')} />
                    <span className="fa-stack-1x fa-inverse">{numItems ? `${numItems}` : ''}</span>
                </span>
            </Tooltip>
        );
    }

    renderUnresolvedQuestionsList() {
        const { router, sourcingSubscription, unresolvedQuestionsProjects } = this.props;
        const listItems = unresolvedQuestionsProjects.map((project) => {
            const {
                financialId,
                government_id: govId,
                id,
                qaDeadline,
                qaResponseDeadline,
            } = project;
            const numItems = project.questions.length;
            const dangerStyle = numItems > 3;
            const qaDeadlineDate = qaDeadline ? moment(qaDeadline).format('MM/DD/YYYY') : '';
            const responseDeadline = qaResponseDeadline
                ? moment(qaResponseDeadline).format('MM/DD/YYYY')
                : '';
            const tooltipText = 'Number of unresolved questions';

            return (
                <ListGroupItem
                    key={project.id}
                    onClick={() =>
                        router.push(`/governments/${govId}/projects/${id}/sourcing/q-and-a`)
                    }
                >
                    {this.renderBadgeIcon(numItems, dangerStyle, tooltipText)}
                    <span className={this.styles.projectTitle}>{`${project.title}`}</span>
                    <br />
                    <span className={this.styles.itemDetail}>
                        {`Project ID: ${financialId}`}
                        {qaDeadlineDate && ` | QA Deadline: ${qaDeadlineDate}`}
                        {responseDeadline && ` | QA Response Deadline: ${responseDeadline}`}
                    </span>
                </ListGroupItem>
            );
        });

        return (
            <ProjectPanel
                eventKey={0}
                listItems={listItems}
                moduleEnabled={sourcingSubscription}
                type="Unresolved Questions"
            />
        );
    }

    renderUnreleasedAddendaList() {
        const { router, sourcingSubscription, unreleasedAddendaProjects } = this.props;
        const listItems = unreleasedAddendaProjects.map((project) => {
            const { financialId, government_id: govId, id } = project;
            const numItems = project.addendums.length;
            const tooltipText = 'Number of unreleased addenda or notices';

            return (
                <ListGroupItem
                    key={project.id}
                    onClick={() =>
                        router.push(`/governments/${govId}/projects/${id}/sourcing/addenda`)
                    }
                >
                    {this.renderBadgeIcon(numItems, true, tooltipText)}
                    <span className={this.styles.projectTitle}>{`${project.title}`}</span>
                    <br />
                    <span className={this.styles.itemDetail}>{`Project ID: ${financialId}`}</span>
                </ListGroupItem>
            );
        });

        return (
            <ProjectPanel
                eventKey={1}
                listItems={listItems}
                moduleEnabled={sourcingSubscription}
                type="Unreleased Addenda & Notices"
            />
        );
    }

    renderPendingProjectsList() {
        const { router, sourcingSubscription, pendingProjects } = this.props;
        const listItems = pendingProjects.map((project) => {
            const { financialId, government_id: govId, id, lastUpdatedAt } = project;
            const lastUpdatedAtDate = moment(lastUpdatedAt).format('MM/DD/YYYY');

            return (
                <ListGroupItem
                    key={project.id}
                    onClick={() => router.push(`/governments/${govId}/projects/${id}`)}
                >
                    <span className={this.styles.projectTitle}>{`${project.title}`}</span>
                    <br />
                    <span className={classnames(this.styles.itemDetail, this.styles.pending)}>
                        {`Project ID: ${financialId}`}
                        {lastUpdatedAtDate && ` | Last Updated: ${lastUpdatedAtDate}`}
                    </span>
                </ListGroupItem>
            );
        });

        return (
            <ProjectPanel
                eventKey={2}
                listItems={listItems}
                moduleEnabled={sourcingSubscription}
                type="Pending Projects"
            />
        );
    }

    renderUnsubmittedEvaluationScorecardsList() {
        const { evaluationSubscription, router, unsubmittedScorecardsProjects } = this.props;
        const listItems = unsubmittedScorecardsProjects.map((project) => {
            const {
                financialId,
                government_id: govId,
                id,
                submittedScorecardsCount,
                unsubmittedScorecardsCount,
            } = project;
            const numItems = unsubmittedScorecardsCount;
            const dangerStyle = unsubmittedScorecardsCount / submittedScorecardsCount >= 0.5;
            const tooltipText = `${unsubmittedScorecardsCount} of ${
                unsubmittedScorecardsCount + submittedScorecardsCount
            } scorecards outstanding`;

            return (
                <ListGroupItem
                    key={project.id}
                    onClick={() =>
                        router.push(`/governments/${govId}/projects/${id}/evaluation/evaluators`)
                    }
                >
                    {this.renderBadgeIcon(numItems, dangerStyle, tooltipText)}
                    <span className={this.styles.projectTitle}>{`${project.title}`}</span>
                    <br />
                    <span className={this.styles.itemDetail}>{`Project ID: ${financialId}`}</span>
                </ListGroupItem>
            );
        });

        return (
            <ProjectPanel
                eventKey={3}
                listItems={listItems}
                moduleEnabled={evaluationSubscription}
                type="Unsubmitted Evaluation Scorecards"
            />
        );
    }

    render() {
        return (
            <div className="col-md-6">
                <Panel className={this.styles.projectsPanel}>
                    <Panel.Heading>
                        <Typography className={this.styles.panelTitle} component="h2" variant="h4">
                            Projects Needing Attention
                        </Typography>
                    </Panel.Heading>
                    <Panel.Body>
                        <PanelGroup
                            accordion
                            activeKey={this.state.activeKey}
                            id="needsAttentionProjects"
                            onSelect={this.handleSelect}
                        >
                            {this.renderUnresolvedQuestionsList()}
                            {this.renderUnreleasedAddendaList()}
                            {this.renderPendingProjectsList()}
                            {this.renderUnsubmittedEvaluationScorecardsList()}
                        </PanelGroup>
                    </Panel.Body>
                </Panel>
            </div>
        );
    }
}

export default withRouter(ConnectedNeedsAttentionProjectsList);

import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { DATA, MAX_LENGTH } from '@og-pro/shared-config/questionnaires';

import { getTextBasedResponseHelp } from '../../../utils';
import { InputText } from '../../../..';

export class ShortAnswerResponseForm extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        input: PropTypes.object.isRequired,
        isOGThemeEnabledForComponents: PropTypes.bool,
        meta: PropTypes.object.isRequired,
        showValidation: PropTypes.bool,
    };

    render() {
        const { disabled, input, isOGThemeEnabledForComponents, meta, showValidation } = this.props;

        return (
            <InputText
                characterLimit={get(this.props, [DATA, MAX_LENGTH])}
                disabled={disabled}
                enforceCharacterLimit
                help={getTextBasedResponseHelp(get(this.props, [DATA, MAX_LENGTH]), input.value)}
                input={input}
                meta={meta}
                placeholder={isOGThemeEnabledForComponents ? null : 'Enter response'}
                showValidation={showValidation}
                type="text"
                useOpenGovStyle={isOGThemeEnabledForComponents}
            />
        );
    }
}

import React from 'react';
import { Label as LabelBS } from 'react-bootstrap';
import { bootstrapUtils } from 'react-bootstrap/lib/utils';
import classnames from 'classnames';
import PropTypes from 'prop-types';

// Custom bootstrap progress bar styles. Allows passing these strings as `bsStyle` values.
bootstrapUtils.addStyle(LabelBS, 'muted');
bootstrapUtils.addStyle(LabelBS, 'dark-gray');
bootstrapUtils.addStyle(LabelBS, 'royal');
bootstrapUtils.addStyle(LabelBS, 'bg-royal');

export const StatusLabel = (props) => {
    const styles = require('./index.scss');

    return <LabelBS {...props} className={classnames(styles.label, props.className)} />;
};

StatusLabel.propTypes = {
    className: PropTypes.string,
    bsStyle: PropTypes.string,
};

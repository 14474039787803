import { listToDict } from '@og-pro/shared-config/helpers';

export const form = 'purchaseOrderForm';

export const fields = [
    'amount',
    'contract_party_id',
    'date',
    'department_id',
    'description',
    'invoiceIdentifier',
    'notes',
    'number',
    'paidAmount',
    'price_item_id',
    'quantity',
    'showBidResults',
    'status',
    'tag_id',
];

export const fieldNames = listToDict(fields);

import { createSelector } from 'reselect';

import { getUserJS } from '../../selectors';

export const getRequisitionsAdminPath = (_state, { params }) => {
    const { governmentId } = params;
    return `/governments/${governmentId}/requisitions-admin`;
};

export const getCustomFieldsLibraryPath = createSelector(
    [getRequisitionsAdminPath],
    (requisitionsAdminPath) => {
        return `${requisitionsAdminPath}/custom-fields`;
    }
);

export const getRequestTypesPath = createSelector([getUserJS], (user) => {
    return `/governments/${user.government_id}/requisitions-admin/request-types`;
});

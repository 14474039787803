import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { scoreColor, scoreText } from '../../helpers';

export class CriteriaScoreDocx extends PureComponent {
    static propTypes = {
        criteriaScore: PropTypes.number,
        isTotal: PropTypes.bool,
        rankedScore: PropTypes.number,
        scoringCriteria: PropTypes.shape({
            maxScore: PropTypes.number.isRequired,
            scoringMethod: PropTypes.number,
            title: PropTypes.string.isRequired,
        }).isRequired,
        showPercentageTotals: PropTypes.bool,
        style: PropTypes.object,
        totalScorePercentage: PropTypes.number,
        usesRankedScoring: PropTypes.bool,
    };

    render() {
        const { style, ...props } = this.props;

        const tdStyle = {
            backgroundColor: scoreColor({ ...props, isDocx: true }),
            textAlign: 'center',
            ...style,
        };

        return <td style={tdStyle}>{scoreText(props)}</td>;
    }
}

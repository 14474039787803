import { showNotification } from '../notification';

export const SAVE = 'account/personal/SAVE';
export const SAVE_SUCCESS = 'account/personal/SAVE_SUCCESS';
export const SAVE_FAIL = 'account/personal/SAVE_FAIL';

export function save(data, shouldShowNotification = true) {
    return (dispatch, getState, client) => {
        dispatch({ type: SAVE });

        const postData = { data };

        return client
            .put('/user/me', postData)
            .then((result) => {
                dispatch({
                    type: SAVE_SUCCESS,
                    result,
                });
                if (shouldShowNotification) {
                    dispatch(showNotification('Your information has been successfully saved'));
                }
            })
            .catch((error) => {
                dispatch({ type: SAVE_FAIL, error });
                dispatch(showNotification(error.message, { type: 'danger' }));
            });
    };
}

export const UPLOAD_AVATAR = 'account/personal/UPLOAD_AVATAR';
export const UPLOAD_AVATAR_SUCCESS = 'account/personal/UPLOAD_AVATAR_SUCCESS';
export const UPLOAD_AVATAR_FAIL = 'account/personal/UPLOAD_AVATAR_FAIL';

export function uploadAvatar(imageDataUrl) {
    return (dispatch, getState, client) => {
        const data = { imageDataUrl };
        dispatch({ type: UPLOAD_AVATAR, avatarUrl: imageDataUrl });
        return client
            .put('/user/me/avatar', { data })
            .then(({ avatarUrl }) => {
                dispatch({ type: UPLOAD_AVATAR_SUCCESS, avatarUrl });
            })
            .catch((error) => {
                dispatch({ type: UPLOAD_AVATAR_FAIL, error });
            });
    };
}

export function saveVendor(userId, formData) {
    return (dispatch, getState, client) => {
        const data = { ...formData, isVendorActivation: true, userId };
        dispatch({ type: SAVE });
        return client
            .put('/user/me', { data })
            .then((result) => dispatch({ type: SAVE_SUCCESS, result }))
            .catch((error) => dispatch({ type: SAVE_FAIL, error }));
    };
}

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';

import { COMMENT, form, SCORE } from './constants';
import { validate, warn } from './validate';
import { ScoreWithScaleInput, ScoringCriteriaFormDescription } from '../../components';
import { Button, ButtonGroup, InputText, LoadingButton } from '../../../../../components';
import { normalizeScoreInput } from '../../../../../Forms/normalizers';

const formConfig = {
    enableReinitialize: true,
    form,
    validate,
    warn,
};

// @reduxForm
class ConnectedAdminScorecardForm extends Component {
    static propTypes = {
        adminScorecardProposalEvaluations: PropTypes.arrayOf(
            PropTypes.shape({
                proposalCriteriaScores: PropTypes.arrayOf(
                    PropTypes.shape({
                        proposal: PropTypes.shape({
                            companyName: PropTypes.string,
                            id: PropTypes.number.isRequired,
                            isExcluded: PropTypes.bool,
                        }).isRequired,
                    })
                ).isRequired,
                scoringCriterium: PropTypes.object.isRequired,
            })
        ).isRequired,
        handleSubmit: PropTypes.func.isRequired,
        isCommentRequired: PropTypes.bool,
        submitHandler: PropTypes.func.isRequired,
        totalWeight: PropTypes.number.isRequired,
        updateError: PropTypes.string,
        updating: PropTypes.bool,
        updatingProposalEvaluation: PropTypes.bool,
        updatingWithClose: PropTypes.bool,
        valid: PropTypes.bool.isRequired,
        warning: PropTypes.bool,
    };

    get styles() {
        return require('./index.scss');
    }

    get disabled() {
        const { updating, updatingProposalEvaluation, updatingWithClose } = this.props;

        return updating || updatingWithClose || updatingProposalEvaluation;
    }

    renderButtons() {
        const {
            handleSubmit,
            isCommentRequired,
            submitHandler,
            updating,
            updatingWithClose,
            valid,
            warning,
        } = this.props;

        const disabled = this.disabled;

        const isScorecardValid = valid && !warning;
        return (
            <>
                <Button
                    bsStyle={isScorecardValid ? 'success' : undefined}
                    disabled={!isScorecardValid || disabled}
                    onClick={handleSubmit(submitHandler(true, true))}
                    tooltip={
                        isScorecardValid
                            ? undefined
                            : `Please add scores ${
                                  isCommentRequired ? 'and comments ' : ''
                              }for all scoring criteria before submitting`
                    }
                    tooltipPlacement="top"
                >
                    <i className="fa fa-send" /> Submit Scores
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <ButtonGroup>
                    <Button className={this.styles.saveButton} disabled={disabled} type="submit">
                        {updating ? 'Saving...' : 'Save'}
                    </Button>
                    <LoadingButton
                        bsStyle="primary"
                        className={this.styles.saveAndCloseButton}
                        disabled={disabled}
                        icon="fa-check"
                        loading={updatingWithClose}
                        loadingText="Saving..."
                        onClick={handleSubmit(submitHandler(true))}
                        text="Save & Close"
                    />
                </ButtonGroup>
                <p className={`text-muted ${this.styles.submitText}`}>
                    Submit your scorecard once you have completed scoring{' '}
                    {isCommentRequired && 'and added comments '}for each item.
                    <br />
                    Your scores will not be counted until they are submitted.
                    <br />
                    You can unsubmit later in case you need to make changes.
                </p>
            </>
        );
    }

    renderFormItems() {
        const { adminScorecardProposalEvaluations, isCommentRequired, totalWeight } = this.props;

        const disabled = this.disabled;

        return adminScorecardProposalEvaluations.map((proposalEvaluationData, index) => {
            const { proposalCriteriaScores, scoringCriterium } = proposalEvaluationData;

            return (
                <React.Fragment key={index}>
                    <div className={this.styles.descriptionContainer}>
                        <ScoringCriteriaFormDescription
                            scoringCriterium={scoringCriterium}
                            totalWeight={totalWeight}
                        />
                    </div>
                    <ListGroup>
                        {proposalCriteriaScores.map((proposalCriteriaScore) => {
                            const { proposal } = proposalCriteriaScore;

                            return (
                                <ListGroupItem key={proposal.id}>
                                    <div className="row">
                                        <div
                                            className={`col-sm-4 text-right ${classnames(
                                                !proposal.isExcluded && this.styles.vendorCol
                                            )}`}
                                        >
                                            <strong>{proposal.companyName}</strong>
                                        </div>
                                        <div className="col-sm-8">
                                            {proposal.isExcluded ? (
                                                <em>Excluded</em>
                                            ) : (
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <Field
                                                            component={ScoreWithScaleInput}
                                                            disabled={disabled}
                                                            name={`${proposal.id}_${scoringCriterium.id}.${SCORE}`}
                                                            normalize={normalizeScoreInput}
                                                            scoringCriteria={scoringCriterium}
                                                        />
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <Field
                                                            component={InputText}
                                                            disabled={disabled}
                                                            hasFeedback={false}
                                                            label={`Comment${
                                                                isCommentRequired
                                                                    ? ''
                                                                    : ' (optional)'
                                                            }`}
                                                            minRows={2}
                                                            name={`${proposal.id}_${scoringCriterium.id}.${COMMENT}`}
                                                            placeholder={
                                                                isCommentRequired
                                                                    ? 'Enter comments'
                                                                    : 'Enter any comments'
                                                            }
                                                            type="textarea"
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </ListGroupItem>
                            );
                        })}
                    </ListGroup>
                </React.Fragment>
            );
        });
    }

    render() {
        const { handleSubmit, submitHandler, updateError } = this.props;

        return (
            <form onSubmit={handleSubmit(submitHandler())}>
                {this.renderFormItems()}
                <div className="text-center">
                    {this.renderButtons()}
                    {updateError && <div className="error-block">{updateError}</div>}
                </div>
            </form>
        );
    }
}

export const AdminScorecardForm = reduxForm(formConfig)(ConnectedAdminScorecardForm);

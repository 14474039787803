import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { reduxForm, Field, FieldArray } from 'redux-form';

import { fieldNames, form } from './constants';
import { validate, warn } from './validate';
import { ScoreWithScaleInput, ScoringCriteriaFormDescription } from '../../../components';
import { Button, ButtonGroup, LoadingButton, InputText } from '../../../../../../components';
import { normalizeScoreInput } from '../../../../../../Forms/normalizers';

const { COMMENT, PROPOSAL_CRITERIA_SCORES, SCORE } = fieldNames;

const formConfig = {
    enableReinitialize: true,
    form,
    validate,
    warn,
};

// @reduxForm
class ConnectedCriteriaScorecardForm extends PureComponent {
    static propTypes = {
        canEvaluate: PropTypes.bool.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        isCommentRequired: PropTypes.bool.isRequired,
        isCompleteStatus: PropTypes.bool.isRequired,
        submitHandler: PropTypes.func.isRequired,
        totalWeight: PropTypes.number.isRequired,
        updateError: PropTypes.string,
        updating: PropTypes.bool,
        updatingProposalEvaluation: PropTypes.bool,
        updatingWithClose: PropTypes.bool,
        unsubmitProposalEvaluation: PropTypes.func.isRequired,
        valid: PropTypes.bool.isRequired,
        warning: PropTypes.bool,
    };

    get styles() {
        return require('./index.scss');
    }

    renderItems = ({ fields }) => {
        const { canEvaluate, isCommentRequired, isCompleteStatus, totalWeight, updating } =
            this.props;

        const disabled = updating || isCompleteStatus || !canEvaluate;

        return fields.map((member, index) => {
            const proposalCriteriaScore = fields.get(index);
            const { scoringCriterium } = proposalCriteriaScore;

            if (scoringCriterium.isAdminScored) {
                return (
                    <ListGroupItem key={index}>
                        <div className="row">
                            <div className="col-sm-4">
                                <ScoringCriteriaFormDescription
                                    scoringCriterium={scoringCriterium}
                                    totalWeight={totalWeight}
                                />
                            </div>
                            <div className="col-sm-8">
                                <em>Scoring to be determined by admin</em>
                            </div>
                        </div>
                    </ListGroupItem>
                );
            }

            return (
                <ListGroupItem key={index}>
                    <div className="row">
                        <div className="col-sm-4">
                            <ScoringCriteriaFormDescription
                                scoringCriterium={scoringCriterium}
                                totalWeight={totalWeight}
                            />
                        </div>
                        <div className="col-sm-4">
                            <Field
                                component={ScoreWithScaleInput}
                                disabled={disabled}
                                name={`${member}.${SCORE}`}
                                normalize={normalizeScoreInput}
                                scoringCriteria={scoringCriterium}
                            />
                        </div>
                        <div className="col-sm-4">
                            <Field
                                component={InputText}
                                disabled={disabled}
                                hasFeedback={false}
                                id={`form-group-scoring-criteria-${index}`}
                                label={`Comment${isCommentRequired ? '' : ' (optional)'}`}
                                minRows={2}
                                name={`${member}.${COMMENT}`}
                                placeholder={
                                    isCommentRequired ? 'Enter comments' : 'Enter any comments'
                                }
                                qaTag="adminScorecardForm-comment"
                                type="textarea"
                            />
                        </div>
                    </div>
                </ListGroupItem>
            );
        });
    };

    renderButtons() {
        const {
            canEvaluate,
            handleSubmit,
            isCommentRequired,
            isCompleteStatus,
            submitHandler,
            unsubmitProposalEvaluation,
            updating,
            updatingProposalEvaluation,
            updatingWithClose,
            valid,
            warning,
        } = this.props;

        if (!canEvaluate) {
            return null;
        }

        const disabled = updating || updatingWithClose || updatingProposalEvaluation;

        if (isCompleteStatus) {
            return (
                <>
                    <Button
                        disabled={disabled}
                        onClick={unsubmitProposalEvaluation}
                        qaTag="adminScorecardForm-unsubmitScores"
                    >
                        <i className="fa fa-undo" /> Unsubmit Scores
                    </Button>
                    <p className={`text-muted ${this.styles.submitText}`}>
                        Unsubmit your scores if you need to edit your scorecard.
                        <br />
                        Just be sure to resubmit once you have made your changes.
                    </p>
                </>
            );
        }

        const isScorecardComplete = valid && !warning;
        return (
            <>
                <Button
                    bsStyle={isScorecardComplete ? 'success' : undefined}
                    disabled={!isScorecardComplete || disabled}
                    onClick={handleSubmit(submitHandler(false, true))}
                    qaTag="adminScorecardForm-submitScores"
                    tooltip={
                        isScorecardComplete
                            ? undefined
                            : `Please add scores ${
                                  isCommentRequired ? 'and comments ' : ''
                              }for all scoring criteria before submitting`
                    }
                    tooltipPlacement="top"
                >
                    <i className="fa fa-send" /> Submit Scores
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <ButtonGroup>
                    <Button
                        className={this.styles.saveButton}
                        disabled={disabled}
                        qaTag="adminScorecardForm-save"
                        type="submit"
                    >
                        {updating ? 'Saving...' : 'Save'}
                    </Button>
                    <LoadingButton
                        bsStyle="primary"
                        className={this.styles.saveAndCloseButton}
                        disabled={disabled}
                        icon="fa-check"
                        loading={updatingWithClose}
                        loadingText="Saving..."
                        onClick={handleSubmit(submitHandler(true))}
                        qaTag="adminScorecardForm-saveAndClose"
                        text="Save & Close"
                    />
                </ButtonGroup>
                <p className={`text-muted ${this.styles.submitText}`}>
                    Submit your scorecard once you have completed scoring{' '}
                    {isCommentRequired && 'and added comments '}for each item.
                    <br />
                    Your scores will not be counted until they are submitted.
                    <br />
                    You can unsubmit later in case you need to make changes.
                </p>
            </>
        );
    }

    render() {
        const { handleSubmit, submitHandler, updateError } = this.props;

        return (
            <form onSubmit={handleSubmit(submitHandler())}>
                <ListGroup>
                    <FieldArray component={this.renderItems} name={PROPOSAL_CRITERIA_SCORES} />
                </ListGroup>
                <div className="text-center">
                    {this.renderButtons()}
                    {updateError && <div className="error-block">{updateError}</div>}
                </div>
            </form>
        );
    }
}

export const CriteriaScorecardForm = reduxForm(formConfig)(ConnectedCriteriaScorecardForm);

/*
 * Further information about these constants is available at https://opengovinc.atlassian.net/wiki/spaces/PRO/pages/3060990428/Developing+with+TinyMCE
 */

/*
 * Tinymce src is downloaded as a zip file using a tinymce developer account (it must be a developer account
 * to include premium plugins) and then uploaded to s3
 */
export const TINYMCE_SRC_URL =
    'https://assets.procurement.opengov.com/tinymce-assets/tinymce-v6.3.2/tinymce.min.js';

/*
 * Content css is created by the TinyMCE skin generator and can be updated by uploading the
 * skintool.json file (located in assets/tinymce)to the tinymce skin tool. The output from
 * the tool should be uploaded to s3
 */
export const CONTENT_CSS_URL =
    'https://assets.procurement.opengov.com/tinymce-assets/skins/content/pro-tinymce-skin/content.min.css';

/*
 * Custom content css is stored in the content.css file, but must be uploaded to s3 for tinymce to use it
 */
export const CUSTOM_CONTENT_CSS_URL =
    'https://assets.procurement.opengov.com/tinymce-assets/content.css';

export const DEFAULT_PLUGINS = [
    'autoresize',
    'autolink',
    'charmap',
    'formatpainter',
    'image',
    'lists',
    'link',
    'pagebreak',
    'powerpaste',
    'table',
    'visualblocks',
];

export const MINIMAL_TOOLBAR =
    'bold italic underline | align bullist numlist | link image insertTag insertTemplateVariable';

export const ADVANCED_TOOLBAR =
    'undo redo | fontsize bold italic underline strikethrough subscript superscript | align bullist numlist outdent indent | forecolor backcolor formatpainter removeformat | pagebreak table link image charmap insertTag insertTemplateVariable';

export const BLOCK_FORMAT_OPTIONS =
    'Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3; Header 4=h4; Header 5=h5';

export const INVALID_ELEMENTS_HEADER_TOOLBAR = 'table';

export const INVALID_ELEMENTS = 'h1, h2, h3, h4, h5, pre';

export const DEFAULT_OUTLINE_NUMBERING = `
ol {
    list-style-type: upper-alpha;
}

ol > li > ol {
    list-style-type: decimal;
}

ol > li > ol > li > ol {
    list-style-type: lower-alpha;
}

ol > li > ol > li > ol > li > ol {
    list-style-type: lower-roman;
}

ol > li > ol > li > ol > li > ol > li > ol {
    list-style-type: upper-roman;
}`;

export const FONT_SIZE_FORMATS = '8px 10px 12px 14px 16px 18px 24px 36px 48px'; // By default, TinyMCE sets the font sizes to use the pt unit, not px - https://www.tiny.cloud/docs/tinymce/6/user-formatting-options/#font_size_formats

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Checkbox } from 'react-bootstrap';
import { components } from 'react-select';
import { change as reduxFormChange, formValueSelector, Field } from 'redux-form';
import { connect } from 'react-redux';

import { contactFieldNames, mappedUserFields } from '@og-pro/shared-config/contacts';

import { UNITED_STATES_PHONE_COUNTRY_CODE } from '@og-pro/shared-config/global';

import { Button, InputText, SearchSelect } from '../../..';
import { phoneCountryCodeOptions } from '../../../../Forms/options';
import { maskPhone } from '../../../../Forms/maskers';
import { integerString, normalizePhone, normalizePhoneExt } from '../../../../Forms/normalizers';
import { MaskedInputText } from '../../../../hocs';

const { PHONE, PHONE_EXT, PHONE_COUNTRY } = contactFieldNames;

const mapStateToProps = (state, props) => {
    const { formName } = props;

    const selector = formValueSelector(formName);

    const fieldNames = props.fieldNames || mappedUserFields;

    const phoneCountryField = fieldNames[PHONE_COUNTRY];
    const phoneExtField = fieldNames[PHONE_EXT];

    const rawPhoneCountryCode = selector(state, phoneCountryField);
    const phoneCountryCode = rawPhoneCountryCode && rawPhoneCountryCode.replace(/^0+/, '');

    return {
        fieldNames,
        hasExt: !!selector(state, phoneExtField),
        isInternationalPhone:
            phoneCountryCode && phoneCountryCode !== UNITED_STATES_PHONE_COUNTRY_CODE,
    };
};

const mapDispatchToProps = {
    change: reduxFormChange,
};

// @connect
class ConnectedPhoneNumberInput extends Component {
    static propTypes = {
        autoFocus: PropTypes.bool,
        change: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        fieldNames: PropTypes.object.isRequired,
        formName: PropTypes.string.isRequired,
        hasExt: PropTypes.bool,
        hasRequiredStar: PropTypes.bool,
        hideExt: PropTypes.bool,
        isInternationalPhone: PropTypes.bool,
        phoneFieldLabel: PropTypes.string,
        placeholder: PropTypes.string,
        showErrorFields: PropTypes.bool,
        showRequiredFieldsText: PropTypes.bool,
        showValidation: PropTypes.bool,
    };

    static defaultProps = {
        autoFocus: false,
        disabled: false,
        hasExt: false,
        hideExt: false,
        isInternationalPhone: false,
        phoneFieldLabel: 'Phone Number *',
        placeholder: undefined,
        showErrorFields: false,
        showRequiredFieldsText: true,
        showValidation: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            showCodeField: props.isInternationalPhone,
            showExtField: props.hasExt,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    MaskedPhoneInput = MaskedInputText(InputText);

    toggleInternational = (e) => {
        const { checked } = e.target;

        const { change, fieldNames, formName } = this.props;

        if (!checked) {
            change(formName, fieldNames[PHONE_COUNTRY], UNITED_STATES_PHONE_COUNTRY_CODE);
        }

        this.setState({ showCodeField: checked });
    };

    handleAddExtButtonClick = () => {
        this.setState((prevState) => {
            return {
                showExtField: !prevState.showExtField,
            };
        });
    };

    phoneCodeMenuContainerStyle = {
        menu: { width: 220 },
    };

    phoneCodeOptionRenderer = (option) => (
        <components.Option {...option}>
            <strong>{option.label}</strong> +{option.value}
        </components.Option>
    );

    phoneCodeValueRenderer = (option) => (
        <components.SingleValue {...option}>+{option.data.value}</components.SingleValue>
    );

    renderAddExtButton() {
        return (
            <Button
                bsStyle="link"
                className={this.styles.extBtn}
                onClick={this.handleAddExtButtonClick}
                qaTag="phoneNumberInput-addExt"
            >
                Add Ext.
            </Button>
        );
    }

    renderExtField() {
        const { disabled, fieldNames, showValidation } = this.props;

        return (
            <Field
                component={InputText}
                disabled={disabled}
                hasFeedback={false}
                label="Extension"
                name={fieldNames[PHONE_EXT]}
                normalize={normalizePhoneExt}
                qaTag="phoneNumberInput-phoneExt"
                showValidation={showValidation}
                type="tel"
            />
        );
    }

    render() {
        const {
            autoFocus,
            disabled,
            fieldNames,
            hasRequiredStar,
            hideExt,
            isInternationalPhone,
            placeholder,
            phoneFieldLabel,
            showErrorFields,
            showRequiredFieldsText,
            showValidation,
        } = this.props;

        const { showCodeField, showExtField } = this.state;

        const countryCodeCol = classnames(this.styles.countryCodeCol, 'col-xs-4 col-sm-3');
        const phoneCol = showCodeField ? 'col-xs-5 col-sm-6' : 'col-xs-8';
        const extCol = classnames(this.styles.extCol, showCodeField ? 'col-xs-3' : 'col-xs-4');

        return (
            <div className="row">
                {showRequiredFieldsText && (
                    <div className={this.styles.requiredNotice}>
                        All fields marked with * are required
                    </div>
                )}
                {showCodeField && (
                    <div className={countryCodeCol}>
                        <Field
                            aria-required
                            backspaceRemovesValue={false}
                            component={SearchSelect}
                            components={{
                                Option: this.phoneCodeOptionRenderer,
                                SingleValue: this.phoneCodeValueRenderer,
                            }}
                            disabled={disabled}
                            label="Country Code *"
                            name={fieldNames[PHONE_COUNTRY]}
                            options={phoneCountryCodeOptions}
                            placeholder={placeholder}
                            showValidation={showValidation}
                            styles={this.phoneCodeMenuContainerStyle}
                        />
                    </div>
                )}
                <div className={phoneCol}>
                    <Field
                        aria-required
                        autoFocus={autoFocus}
                        component={isInternationalPhone ? InputText : this.MaskedPhoneInput}
                        disabled={disabled}
                        hasFeedback={false}
                        label={`${phoneFieldLabel}${hasRequiredStar ? '*' : ''}`}
                        mask={isInternationalPhone ? undefined : maskPhone}
                        name={fieldNames[PHONE]}
                        normalize={isInternationalPhone ? integerString : undefined}
                        normalizer={isInternationalPhone ? undefined : normalizePhone}
                        placeholder={placeholder}
                        qaTag="phoneNumberInput-phoneNumber"
                        showValidation={showValidation}
                        type="tel"
                    />
                </div>
                {!hideExt && (
                    <div className={extCol}>
                        {showExtField ? this.renderExtField() : this.renderAddExtButton()}
                    </div>
                )}
                <div className="col-xs-12">
                    <Checkbox
                        checked={showCodeField}
                        className={this.styles.checkbox}
                        onChange={this.toggleInternational}
                    >
                        Outside the United States & Canada?
                    </Checkbox>
                </div>
                {showErrorFields && (
                    <div className={`col-xs-12 help-block ${this.styles.formError}`} role="alert">
                        Your form has errors. Please fix them and submit again.
                    </div>
                )}
            </div>
        );
    }
}

export const PhoneNumberInput = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedPhoneNumberInput);

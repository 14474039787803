import { redirect } from 'react-router-dom';

import {
    isContractUser,
    isDocBuilderUser,
    isRequisitionUser,
    isEvaluationUser,
    isIntakeUser,
    isSourcingUser,
} from '../../actions/auth';

/*
 * Yes, this function returns a function that returns a function.
 * The reason for this controversial piece of code is to avoid repetition in the exported functions below.
 * selector is the redux selector by which we tell if a user has a feature or not. The returned function is what we export. If you check the usages in the
 * route definitions, we pass getState as a param and then that returns the actual loader.
 */
const requireSubscription =
    (selector) =>
    (getState) =>
    async ({ params }) => {
        const state = getState();
        const hasFeature = selector(state);

        if (!hasFeature) {
            const { governmentId, projectId } = params;

            if (projectId) {
                return redirect(`/governments/${governmentId}/projects/${projectId}`);
            }

            return redirect(`/governments/${governmentId}/projects`);
        }

        return null;
    };

export const requireContractSubscription = requireSubscription(isContractUser);
export const requireDocBuilderSubscription = requireSubscription(isDocBuilderUser);
export const requireRequisitionsSubscription = requireSubscription(isRequisitionUser);
export const requireEvaluationSubscription = requireSubscription(isEvaluationUser);
export const requireIntakeSubscription = requireSubscription(isIntakeUser);
export const requireMarketplaceSubscription = requireSubscription(isSourcingUser);

import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@og-pro/ui';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
    accordionStyle,
    accordionDetailsStyle,
    accordionDetailsContainerStyle,
    accordionSummaryStyle,
    accordionSummaryContentStyle,
    checkboxStyle,
} from './styles';
import { RelationItem } from '../../../../../../../../components';

export const OtherItems = ({ isEditing, items }) => {
    const [showMore, setShowMore] = useState(false);

    if (items.length === 0) {
        return null;
    }

    return (
        <Accordion expanded={showMore} onChange={() => setShowMore(!showMore)} sx={accordionStyle}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={accordionSummaryStyle(showMore)}>
                <Box {...accordionSummaryContentStyle}>
                    {isEditing && <Box {...checkboxStyle}>&nbsp;</Box>}
                    <Typography variant="h6">{showMore ? 'Hide' : 'Show'} Other Related</Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails sx={accordionDetailsContainerStyle}>
                {isEditing && <Box {...checkboxStyle}>&nbsp;</Box>}
                <Box {...accordionDetailsStyle}>
                    {items.map((item) => (
                        <RelationItem {...item} variant="secondary" />
                    ))}
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

OtherItems.propTypes = {
    isEditing: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired,
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { sectionDescriptionFieldNames } from '../../../constants';
import { Button, RichTextInput } from '../../../../../../../../components';

const { DESCRIPTION } = sectionDescriptionFieldNames;

export class SectionDescriptionForm extends PureComponent {
    static propTypes = {
        closeForm: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        formKey: PropTypes.string.isRequired,
        remove: PropTypes.func.isRequired,
        showFormValidation: PropTypes.bool,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
    };

    render() {
        const {
            closeForm,
            disabled,
            formKey,
            remove,
            showFormValidation,
            tagOptions,
            templateVariableOptions,
        } = this.props;

        return (
            <div>
                <Field
                    autoFocus
                    component={RichTextInput}
                    disabled={disabled}
                    minRows={2}
                    name={`${formKey}.${DESCRIPTION}`}
                    placeholder="Enter a description for the section"
                    showValidation={showFormValidation}
                    tagOptions={tagOptions}
                    templateVariableOptions={templateVariableOptions}
                    toolbarPlacement="bottom"
                />
                <div className="text-center">
                    <Button bsStyle="primary" disabled={disabled} onClick={closeForm}>
                        Done
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button disabled={disabled} onClick={remove}>
                        <span className="text-danger">
                            <i className="fa fa-lg fa-trash-o" /> Remove
                        </span>
                    </Button>
                </div>
            </div>
        );
    }
}

const { buildMap, listToDict } = require('../helpers');

// All criteria instructionTypes defined on the server
const criteriaInstructions = [
    { type: 'edit', name: 'Edit Item', icon: 'pencil' },
    { type: 'confirm', name: 'Confirm Item', icon: 'check' },
    { type: 'toggle', name: 'Keep or Remove', icon: 'toggle-on' },
    { type: 'toggleEdit', name: 'Keep or Remove (and Edit)', icon: 'toggle-on' },
    { type: 'radio', name: 'Multiple Choice (simple)', icon: 'list' },
    { type: 'select', name: 'Multiple Choice (detailed - 3 options max)', icon: 'list-ul' },
    { type: 'attachment', name: 'Attachment Upload', icon: 'paperclip' },
];

// All criteria instructionTypes defined on the server
exports.criteriaInstructionTypes = criteriaInstructions.map((item) => item.type);

exports.criteriaInstructionsDict = listToDict(exports.criteriaInstructionTypes);

exports.criteriaInstructionsMap = buildMap(criteriaInstructions, 'type');

exports.criteriaInstructionSelectOptions = criteriaInstructions.map((item) => ({
    icon: item.icon,
    label: item.name,
    value: item.type,
}));

exports.instructionsWithoutDescription = [
    exports.criteriaInstructionsDict.ATTACHMENT,
    exports.criteriaInstructionsDict.RADIO,
    exports.criteriaInstructionsDict.SELECT,
];

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { SplitButton } from 'react-bootstrap';
import { v4 as UUIDv4 } from 'uuid';
import classnames from 'classnames';

import { CDSButtonGroup, MenuItem } from '../../../../../../../components';

export class AddPriceItemButton extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        onClick: PropTypes.func.isRequired,
        useOpenGovStyle: PropTypes.bool,
    };

    static defaultProps = {
        disabled: false,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const { disabled, onClick, useOpenGovStyle } = this.props;

        const button = (
            <SplitButton
                bsSize="sm"
                className={classnames({
                    [this.styles.addItemBtn]: !useOpenGovStyle,
                })}
                disabled={disabled}
                id={`add-price-item-button-${UUIDv4()}`}
                onClick={() => onClick(1)}
                pullRight={!useOpenGovStyle}
                title={
                    <span>
                        <i className="fa fa-plus" /> Add Price Item
                    </span>
                }
            >
                <MenuItem
                    eventKey="headerRow"
                    onClick={() => onClick(1, true)}
                    qaTag="projectCreate-addSectionHeaderRow"
                >
                    Add Section Header Row
                </MenuItem>
                <MenuItem eventKey="1" onClick={() => onClick(1)} qaTag="projectCreate-add1Row">
                    Add 1 Row
                </MenuItem>
                <MenuItem eventKey="10" onClick={() => onClick(10)} qaTag="projectCreate-add10Rows">
                    Add 10 Rows
                </MenuItem>
                <MenuItem eventKey="20" onClick={() => onClick(20)} qaTag="projectCreate-add20Rows">
                    Add 20 Rows
                </MenuItem>
                <MenuItem eventKey="50" onClick={() => onClick(50)} qaTag="projectCreate-add50Rows">
                    Add 50 Rows
                </MenuItem>
            </SplitButton>
        );

        if (useOpenGovStyle) {
            return (
                <CDSButtonGroup>
                    <div className={this.styles.openGovStyle}>{button}</div>
                </CDSButtonGroup>
            );
        }

        return <div className={this.styles.addBtn}>{button}</div>;
    }
}

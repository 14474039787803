import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';

import { VENDOR_USER_ROLES_SELECT_OPTIONS, fieldNames } from './constants';
import { getUserRolesSelectOptions } from '../selectors';
import { SearchSelect } from '../../../../components';
import { hasUserRolesSubscription } from '../../../GovApp/selectors';

const { ROLE, USER_ROLES } = fieldNames;

export const UserRoleField = ({ disabled, isVendor, showLabel }) => {
    const hasUserRoles = useSelector(hasUserRolesSubscription);
    const userRolesSelectOptions = useSelector(getUserRolesSelectOptions);
    const vendorUserRolesSelectOptions = VENDOR_USER_ROLES_SELECT_OPTIONS;

    if (isVendor || !hasUserRoles) {
        return (
            <Field
                aria-label="Assign Role"
                component={SearchSelect}
                disabled={disabled}
                label={showLabel ? 'Role' : undefined}
                name={ROLE}
                options={vendorUserRolesSelectOptions}
                placeholder="Assign role..."
            />
        );
    }

    return (
        <Field
            aria-label="Assign Role"
            component={SearchSelect}
            disabled={disabled}
            isMulti
            isMultiSimpleValue
            label={showLabel ? 'Role' : undefined}
            name={USER_ROLES}
            options={userRolesSelectOptions}
            placeholder="Assign role(s)..."
        />
    );
};

UserRoleField.propTypes = {
    disabled: PropTypes.bool,
    isVendor: PropTypes.bool,
    showLabel: PropTypes.bool,
};

import classnames from 'classnames';
import { sortBy, uniq } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { CategorySearch } from './CategorySearch';
import { CategorySelectModal } from './CategorySelectModal';
import { CategorySetForm, SelectedCategories } from './components';
import { getSearchResultsJS, getSelectedCategorySet } from './selectors';
import { changeCategorySet, searchCategories, resetCategoriesSearch } from '../../actions/category';
import { Button, Label } from '../../components';

const mapStateToProps = (state, props) => {
    return {
        searching: state.category.get('searchingCategories'),
        searchResults: getSearchResultsJS(state),
        selectedCategorySet: getSelectedCategorySet(state, props),
    };
};

const mapDispatchToProps = {
    changeCategorySet,
    resetCategoriesSearch,
    searchCategories,
};

// @connect
class ConnectedCategorySearchInput extends Component {
    static propTypes = {
        changeCategorySet: PropTypes.func.isRequired,
        defaultCategorySet: PropTypes.number,
        disabled: PropTypes.bool,
        disallowCustomCategories: PropTypes.bool,
        input: PropTypes.object.isRequired,
        label: PropTypes.string,
        resetCategoriesSearch: PropTypes.func.isRequired, // from connect
        searchCategories: PropTypes.func.isRequired, // from connect
        searching: PropTypes.bool.isRequired, // from connect
        searchResults: PropTypes.array.isRequired, // from connect
        selectedCategorySet: PropTypes.number.isRequired,
        stacked: PropTypes.bool,
        useSingleCodeSet: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            categoriesMap: {},
            showModal: false,
        };
    }

    componentWillUnmount() {
        this.hideModal();
        this.props.resetCategoriesSearch();
    }

    get styles() {
        return require('./shared.scss');
    }

    changeCategorySet = ({ setId }) => {
        if (setId) {
            this.props.changeCategorySet(setId);
        }
    };

    // Necessary so a new array isn't generated each time causing a re-render
    defaultCategories = [];

    showModal = () => {
        this.setState({ showModal: true });
    };

    hideModal = () => {
        this.setState({ showModal: false });
    };

    updateCategoriesMap = (categories) => {
        this.setState({
            categoriesMap: categories.reduce((obj, cat) => {
                obj[cat.id] = true;
                return obj;
            }, {}),
        });
    };

    selectCategory = (rawCategories) => {
        const {
            input: { value },
        } = this.props;

        const { categoriesMap } = this.state;

        const selectedCategories = (rawCategories || [])
            .filter((cat) => !categoriesMap[cat.value])
            .map((cat) => cat.data);

        const categories = value || [];
        const updatedCategories = sortBy([...categories, ...selectedCategories], 'code');
        return this.selectHandler(updatedCategories);
    };

    selectHandler = (rawCategories) => {
        const {
            input: { onBlur, onChange },
        } = this.props;

        // Categories should be unique, but this makes sure
        const categories = uniq(rawCategories);

        // Update whether the field has changed or not
        onChange(categories);
        onBlur(categories); // blur touches the field
        this.updateCategoriesMap(categories);
    };

    removeCategory = (id) => {
        const {
            input: { value },
        } = this.props;

        const updatedCategories = (value || []).filter((cat) => cat.id !== id);
        return this.selectHandler(updatedCategories);
    };

    modalSelectHandler = (categories, hasChanged) => {
        if (hasChanged) {
            this.selectHandler(categories);
        }
        return this.hideModal();
    };

    render() {
        const {
            defaultCategorySet,
            disabled,
            disallowCustomCategories,
            input: { value },
            label,
            searchCategories: search,
            searching,
            searchResults,
            selectedCategorySet,
            stacked,
            useSingleCodeSet,
        } = this.props;

        const { categoriesMap, showModal } = this.state;

        const categories = value || this.defaultCategories;

        return (
            <div>
                <Label label={label} />
                <div className={`row ${this.styles.categorySearchLabels}`}>
                    {!useSingleCodeSet && (
                        <div className={classnames('col-xs-12', !stacked && 'col-sm-3')}>
                            <CategorySetForm
                                disabled={disabled}
                                form="categorySetSearchInputForm"
                                formClassName={this.styles.categorySetSelect}
                                initialValues={{ setId: selectedCategorySet }}
                                onChange={this.changeCategorySet}
                            />
                        </div>
                    )}
                    <div
                        className={classnames(
                            'col-xs-12',
                            !useSingleCodeSet && !stacked && 'col-sm-9',
                            !useSingleCodeSet &&
                                !stacked &&
                                this.styles.categorySelectSearchContainer,
                            stacked && this.styles.stackedSelectSearchContainer
                        )}
                    >
                        <CategorySearch
                            categoriesMap={categoriesMap}
                            categorySet={selectedCategorySet}
                            disabled={disabled}
                            fieldName="categorySearchInput"
                            isMulti
                            options={searchResults}
                            search={search}
                            searching={searching}
                            selectHandler={this.selectCategory}
                        />
                    </div>
                </div>
                <div className={this.styles.viewAllButton}>
                    <Button
                        className="pull-right btn btn-link"
                        onClick={this.showModal}
                        qaTag="categorySearchInput-viewCategories"
                    >
                        view categories
                    </Button>
                    <SelectedCategories
                        categories={categories}
                        deleteHandler={this.removeCategory}
                    />
                </div>
                <div className="clearfix" />
                <CategorySelectModal
                    categories={categories}
                    defaultCategorySet={defaultCategorySet}
                    disabled={disabled}
                    disallowCustomCategories={disallowCustomCategories}
                    hideModal={this.hideModal}
                    selectHandler={this.modalSelectHandler}
                    showModal={showModal}
                    useSingleCodeSet={useSingleCodeSet}
                />
            </div>
        );
    }
}

export const CategorySearchInput = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedCategorySearchInput);

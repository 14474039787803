import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { fieldNames, templateFieldNames } from './constants';
import {
    InputText,
    SearchSelect,
    SearchSelectIconOption,
    SearchSelectIconValue,
    SearchSelectUserOption,
} from '../../../../../components';
import { iconSelectOptions } from '../../../../../constants/templateAdmin';
import { limitTextLength } from '../../../../../Forms/normalizers';

const { TEMPLATE, USER_ID } = fieldNames;

const { ICON, PROCESS_ACRONYM, TITLE } = templateFieldNames;

export const TemplateForm = ({ disabled, showFormValidation, userOptions }) => {
    const normalizeProcessAcronym = limitTextLength(16);
    const normalizeTitle = limitTextLength(128);

    return (
        <>
            <div className="row">
                <div className="col-md-9">
                    <Field
                        component={InputText}
                        disabled={disabled}
                        hasFeedback={false}
                        help="Name used to identify the template"
                        label="Template Title"
                        name={`${TEMPLATE}.${TITLE}`}
                        normalize={normalizeTitle}
                        placeholder="Request For Proposal"
                        qaTag="checklistsAdminEdit-templateTitle"
                        showValidation={showFormValidation}
                        type="text"
                    />
                </div>
                <div className="col-md-3">
                    <Field
                        component={InputText}
                        disabled={disabled}
                        hasFeedback={false}
                        help="Short hand name"
                        label="Acronym / Abbreviation"
                        name={`${TEMPLATE}.${PROCESS_ACRONYM}`}
                        normalize={normalizeProcessAcronym}
                        placeholder="RFP"
                        qaTag="checklistsAdminEdit-templateAcronym"
                        showValidation={showFormValidation}
                        type="text"
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <Field
                        component={SearchSelect}
                        components={{
                            Option: SearchSelectIconOption,
                            SingleValue: SearchSelectIconValue,
                        }}
                        disabled={disabled}
                        help="Visual identifier for the template"
                        label="Icon"
                        name={`${TEMPLATE}.${ICON}`}
                        options={iconSelectOptions}
                        placeholder="Select an icon to use"
                        showValidation={showFormValidation}
                    />
                </div>
                <div className="col-md-6">
                    <Field
                        backspaceRemovesValue={false}
                        component={SearchSelect}
                        components={{
                            Option: SearchSelectUserOption,
                        }}
                        disabled={disabled}
                        help="User who owns the template (must be an admin)"
                        label="Owner"
                        name={USER_ID}
                        options={userOptions}
                        placeholder="Select the template owner"
                        showValidation={showFormValidation}
                    />
                </div>
            </div>
        </>
    );
};

TemplateForm.propTypes = {
    disabled: PropTypes.bool,
    showFormValidation: PropTypes.bool,
    userOptions: PropTypes.array.isRequired,
};

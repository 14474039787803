import React, { Component } from 'react';
import { SubmissionError } from 'redux-form';

import { projectStatusesDict } from '@og-pro/shared-config/projects';

import { projectFormProps } from './constants';
import { validateAll } from './validate';
import { LoadingSpinner, FormError } from '../../../components';
import { hasFormErrors } from '../../../helpers';

const { REQUEST_REVIEW } = projectStatusesDict;

export default function extendProjectForm() {
    return function ProjectFormHOC(FormComponent) {
        class BaseProjectCreateForm extends Component {
            static propTypes = projectFormProps;

            componentDidUpdate(prev) {
                const {
                    resetScrollPageToTop,
                    resetSneakyUpdate,
                    scrollPageToTop,
                    scrollToTop,
                    shouldExport,
                    shouldExportDocument,
                    shouldSneakyUpdate,
                    shouldSubmit,
                    shouldUpdate,
                    sneakyNextRoute,
                    updating,
                } = this.props;

                if (!prev.scrollToTop && scrollToTop) {
                    window.scrollTo(0, 0);
                    resetScrollPageToTop();
                }

                if (!prev.shouldUpdate && shouldUpdate && !updating) {
                    this.updateProject({ notify: true });
                }

                if (!prev.shouldSneakyUpdate && shouldSneakyUpdate && !updating) {
                    resetSneakyUpdate();
                    this.updateProject({
                        notify: true,
                        onComplete: () => {
                            if (sneakyNextRoute) {
                                scrollPageToTop();
                                this.router.push(sneakyNextRoute);
                            }
                        },
                    });
                }

                if (!prev.shouldSubmit && shouldSubmit) {
                    this.submitProject();
                }

                /**
                 * Necessary to allow the project to be saved before exported
                 */
                if (!prev.shouldExport && shouldExport && !updating) {
                    this.exportProject(true);
                }

                if (!prev.shouldExportDocument && shouldExportDocument && !updating) {
                    this.exportProject(false);
                }
            }

            exportProject = (isPackage) => {
                const { exportProject, exportProjectDocument } = this.props;

                const onComplete = isPackage ? exportProject : exportProjectDocument;

                this.updateProject({ onComplete });
            };

            markBuilderSectionVisited = (showHelpModalData) => {
                const { builderSection, markBuilderSectionVisited, project, showHelpModal } =
                    this.props;

                const wasUnvisited = markBuilderSectionVisited(project.id, builderSection);

                if (wasUnvisited && showHelpModalData) {
                    // prevents a race condition with the modals being closed by RouteHelpers
                    // introduced by react router 6. Before this happened in the transitionMiddleware
                    setTimeout(() => {
                        showHelpModal(showHelpModalData);
                    }, 1);
                }
            };

            updateProject = (opts) => {
                const { update, project, formValues } = this.props;

                return update(formValues, project.id, project.status, opts);
            };

            submitHandler = (data) => {
                const { submitProject, project, reviewPath } = this.props;

                const errors = validateAll(data, this.props);
                const isFormInvalid = hasFormErrors(errors);

                /**
                 * TODO (9/17/17): Watch for an update in redux-form
                 * Manual form validation is needed because redux-form does
                 * not perform form-wide validation on wizard forms. Only the
                 * form fields currently registered will be validated against
                 * during submit, so we need to perform the check manually.
                 * https://github.com/erikras/redux-form/issues/1520
                 *
                 * Current workaround based on:
                 * https://github.com/erikras/redux-form/issues/2321#issuecomment-322227435
                 *
                 * Another possible workaround would be to use `hasFormErrors`
                 * to set an error on a phantom field that is loaded into
                 * all form via `render` below. That field would always been
                 * invalid until the all form fields were valid.
                 *
                 * UPDATE (2/22/19): Cannot reproduce currently, but also does not appear to be
                 * fixed. Perhaps our use case has changed... leaving here for now though.
                 * TLRD, Cannot get here when the form is invalid.
                 */
                if (isFormInvalid) {
                    return Promise.reject(new SubmissionError(errors));
                }

                const nextRoute = `${reviewPath}/approvals`;

                return submitProject(data, project.id, nextRoute, REQUEST_REVIEW);
            };

            submitProject = () => {
                const { handleSubmit, resetSubmit } = this.props;

                // Reset the state so the form can be submitted again
                resetSubmit();

                // redux-form's handle submit automatically touches all
                // the fields so it will show the success/or error state.
                handleSubmit(this.submitHandler)();
            };

            render() {
                const {
                    initialized,
                    loadingInitialData,
                    loadInitialDataError,
                    project,
                    projectSection,
                } = this.props;

                /**
                 * We must wait for all data to be returned and the form to be initialized
                 * before rendering
                 */
                if ((loadingInitialData || !initialized) && !loadInitialDataError) {
                    return <LoadingSpinner centered />;
                }

                if (loadInitialDataError || !project) {
                    return <FormError error={loadInitialDataError} />;
                }

                // `key` ensures the form is unmounted every time a new section is loaded
                // (necessary when the same section type is navigated to)
                return (
                    <FormComponent
                        {...this.props}
                        key={projectSection.id}
                        markBuilderSectionVisited={this.markBuilderSectionVisited}
                        rawUpdateProject={this.updateProject}
                    />
                );
            }
        }

        return BaseProjectCreateForm;
    };
}

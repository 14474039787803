import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';

import { form } from './constants';
import { personalValidate as validate } from './validate';
import { getDepartmentsJS, getUserJS } from '../../selectors';
import { InputText, SearchSelect, AvatarEditor, Main } from '../../../components';
import * as personalAccountActions from '../../../actions/account/personal';

const mapStateToProps = (state) => {
    return {
        departments: getDepartmentsJS(state),
        saving: state.accountPersonal.get('saving'),
        uploadAvatarError: state.accountPersonal.get('uploadAvatarError'),
        user: getUserJS(state),
    };
};

const mapDispatchToProps = personalAccountActions;

const formConfig = {
    form,
    validate,
};

// @connect
// @reduxForm
export class ConnectedPersonalName extends Component {
    static propTypes = {
        autoFocus: PropTypes.bool,
        departments: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
            })
        ).isRequired,
        handleSubmit: PropTypes.func.isRequired,
        invalid: PropTypes.bool,
        parentSubmit: PropTypes.bool,
        save: PropTypes.func.isRequired,
        saving: PropTypes.bool,
        showErrorFields: PropTypes.bool,
        user: PropTypes.shape({
            avatarUrl: PropTypes.string,
        }).isRequired,
        uploadAvatar: PropTypes.func.isRequired,
        uploadAvatarError: PropTypes.string,
    };

    static defaultProps = {
        autoFocus: false,
        invalid: false,
        parentSubmit: false,
        saving: false,
        showErrorFields: false,
    };

    get styles() {
        return require('./index.scss');
    }

    handleSubmit = (data) => {
        const { save } = this.props;

        save(data);
    };

    renderAvatar() {
        const {
            parentSubmit,
            uploadAvatar,
            uploadAvatarError,
            user: { avatarUrl },
        } = this.props;

        // Do not bother with showing the avatar on initial account set-up
        if (parentSubmit) return null;

        return (
            <div className={this.styles.avatar}>
                <AvatarEditor
                    imageSrc={avatarUrl || undefined}
                    onUpload={uploadAvatar}
                    width={100}
                />
                {uploadAvatarError && <div className="text-danger">Error Uploading Image</div>}
            </div>
        );
    }

    renderSaveButton() {
        const { invalid, parentSubmit, saving } = this.props;

        if (!parentSubmit) {
            return (
                <Button
                    bsSize="large"
                    bsStyle="primary"
                    className="center-block"
                    disabled={invalid || saving}
                    type="submit"
                >
                    {saving ? 'Saving...' : 'Save'}
                </Button>
            );
        }
    }

    render() {
        const { autoFocus, departments, handleSubmit, saving, showErrorFields } = this.props;

        let departmentBlock;

        const selectOptions = departments.map((departmentEntry) => {
            return {
                label: departmentEntry.name,
                value: departmentEntry.id,
            };
        });

        // Vendors won't have a department
        if (departments.length) {
            departmentBlock = (
                <div className={this.styles.departmentBlock}>
                    <Field
                        aria-required
                        component={SearchSelect}
                        disabled={saving}
                        label="Department *"
                        name="department_id"
                        options={selectOptions}
                        placeholder="Select your department"
                    />
                </div>
            );
        }

        return (
            <form onSubmit={handleSubmit(this.handleSubmit)}>
                <Main className="col-xs-12">
                    {this.renderAvatar()}
                    <div className={this.styles.requiredNotice}>
                        All fields marked with * are required
                    </div>
                    <Field
                        aria-required
                        autoFocus={autoFocus}
                        component={InputText}
                        disabled={saving}
                        label="First Name *"
                        name="firstName"
                        placeholder="Enter your first name"
                        qaTag="connectedPersonalName-firstName"
                        type="text"
                    />
                    <Field
                        aria-required
                        component={InputText}
                        disabled={saving}
                        label="Last Name *"
                        name="lastName"
                        placeholder="Enter your last name"
                        qaTag="connectedPersonalName-lastName"
                        type="text"
                    />
                    <Field
                        aria-required
                        component={InputText}
                        disabled={saving}
                        label="Job Title *"
                        name="title"
                        placeholder="Enter your job title"
                        qaTag="connectedPersonalName-jobTitle"
                        type="text"
                    />
                    {departmentBlock}
                    {showErrorFields && (
                        <div className={`help-block ${this.styles.formError}`} role="alert">
                            Your form has errors. Please fix them and submit again.
                        </div>
                    )}
                    {this.renderSaveButton()}
                </Main>
            </form>
        );
    }
}

export const PersonalName = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedPersonalName);

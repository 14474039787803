import React from 'react';
import PropTypes from 'prop-types';

import { BudgetCheckDetails } from '../../../BudgetCheckDetails';

export const AccountBudgetCheck = ({
    accountNumber,
    budgetCheckData,
    fiscalPeriod,
    isExpenseAccount,
}) => {
    return (
        <BudgetCheckDetails
            budgetCheck={{
                accountNumber,
                fiscalYear: fiscalPeriod,
                ...budgetCheckData,
                isExpenseAccount,
            }}
            fullScreen
        />
    );
};

AccountBudgetCheck.propTypes = {
    budgetCheckData: PropTypes.object.isRequired,
    fiscalPeriod: PropTypes.string.isRequired,
    accountNumber: PropTypes.string.isRequired,
    isExpenseAccount: PropTypes.bool,
};

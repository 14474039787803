import React from 'react';

export const withListItemRefs = (WrappedComponent, { propMapKey }) => {
    return class ListItemRefs extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
                listItemRefs: (props[propMapKey] || []).map(() => React.createRef()),
            };
        }

        focusListItem = (index) => {
            const { listItemRefs } = this.state;

            if (listItemRefs && listItemRefs[index]) {
                setTimeout(() => {
                    listItemRefs[index].current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    });
                }, 1);
            }
        };

        updateListItemRefs = (removeIndex, cb) => {
            if (removeIndex !== null && removeIndex >= 0) {
                return this.setState(
                    (prevState) => ({
                        listItemRefs: prevState.listItemRefs.toSpliced(removeIndex, 1),
                    }),
                    cb
                );
            }

            return this.setState(
                (prevState) => ({
                    listItemRefs: [...prevState.listItemRefs, React.createRef()],
                }),
                cb
            );
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    focusListItem={this.focusListItem}
                    listItemRefs={this.state.listItemRefs}
                    updateListItemRefs={this.updateListItemRefs}
                />
            );
        }
    };
};

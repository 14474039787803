import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { CustomVariableListItem } from './CustomVariableListItem';
import { getCustomVariablesMap } from './selectors';

export const CustomVariableList = () => {
    const customVariables = useSelector(getCustomVariablesMap);

    return (
        <>
            {customVariables.length === 0 && (
                <h5 className="text-center">No custom variables in use</h5>
            )}
            <ListGroup>
                {customVariables.map((customVariableData, index) => (
                    <CustomVariableListItem key={index} {...customVariableData} />
                ))}
            </ListGroup>
        </>
    );
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export class ProposalDocumentsListDisplay extends PureComponent {
    static propTypes = {
        proposalDocuments: PropTypes.array.isRequired,
    };

    renderNoDocuments() {
        return <div className="text-center">No proposal documents have been specified yet.</div>;
    }

    render() {
        const { proposalDocuments } = this.props;
        if (proposalDocuments.length === 0) return this.renderNoDocuments();

        const styles = require('./ProposalDocumentsListDisplay.scss');
        const ProposalDocuments = proposalDocuments.map((doc) => (
            <li className={styles.listItem} key={doc.id}>
                <div className={`visible-xs-block ${styles.title}`}>{doc.title}</div>
                {doc.isRequired ? (
                    <div className={`text-success ${styles.requiredText}`}>
                        <i className="fa fa-fw fa-check-circle" /> Required
                    </div>
                ) : (
                    <div className={`text-info ${styles.requiredText}`}>
                        <i className="fa fa-fw fa-plus-circle" /> Optional
                    </div>
                )}
                <div className={`hidden-xs ${styles.title}`}>{doc.title}</div>
            </li>
        ));

        return <ul className="list-unstyled">{ProposalDocuments}</ul>;
    }
}

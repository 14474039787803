import { GraphQLClient } from 'graphql-request';
import { CUSTOM_FORM_SERVICE_GRAPHQL_ENDPOINT } from '@og-pro/shared-config/routes';

import { apiHost } from '../../api/utils';

export const customFormServiceClient = new GraphQLClient(
    apiHost() + CUSTOM_FORM_SERVICE_GRAPHQL_ENDPOINT,
    {
        credentials: `include`,
    }
);

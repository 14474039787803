import React, { PureComponent } from 'react';
import { reduxForm, Field } from 'redux-form';
import { Panel } from 'react-bootstrap';

import { form, fieldNames } from './constants';
import { sectionTypeOptions } from '../../constants';
import { SearchSelect } from '../../../../../components';

const { SECTION_TYPE } = fieldNames;

const formConfig = {
    form,
};

// @reduxForm
class ConnectedContentFilters extends PureComponent {
    render() {
        const styles = require('./ContentFilters.scss');
        const title = <div className="text-center">Suggested Content Filters</div>;

        return (
            <Panel bsStyle="primary" className={styles.panelBody}>
                <Panel.Heading>
                    <Panel.Title>{title}</Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                    <Field
                        component={SearchSelect}
                        isSearchable={false}
                        label="Section"
                        name={SECTION_TYPE}
                        options={sectionTypeOptions}
                        overrideFeedback
                    />
                </Panel.Body>
            </Panel>
        );
    }
}

export const ContentFilters = reduxForm(formConfig)(ConnectedContentFilters);

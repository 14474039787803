import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CDSButton } from '../../../../../components';
import { getQuestionLogicMap } from '../../TemplateEdit/selectors';
import { showQuestionLogicModal } from '../../../../../actions/templatesAdmin';

export const SDv2QuestionLogicIcon = ({
    entireSectionOptionOverrides = null,
    linkable,
    linkableItem,
    projectSection,
    usingFakeSection = false,
}) => {
    const dispatch = useDispatch();
    const questionLogicMap = useSelector(getQuestionLogicMap);

    const hasQuestionLogic =
        questionLogicMap[linkable] &&
        questionLogicMap[linkable][linkableItem.id || linkableItem.sharedId];

    const onClick = (e) => {
        e.stopPropagation();

        if (hasQuestionLogic) {
            const questionLogic = { ...hasQuestionLogic };

            if (usingFakeSection) {
                // when using a fake section (in the case of attachment/signatures tab)
                // once you are editing there won't be a projectSection in the API returned question logic
                // in order to keep the form working we need to fake it (faking it is the same thing we do when creating the question logic)
                questionLogic.projectSection = projectSection;
                questionLogic.usingFakeSection = true;
                questionLogic.entireSectionOptionOverrides = entireSectionOptionOverrides;
            }

            return dispatch(showQuestionLogicModal(questionLogic, hasQuestionLogic.editIndex));
        }

        return dispatch(
            showQuestionLogicModal(
                {
                    entireSectionOptionOverrides,
                    linkable,
                    linkableItem,
                    linkable_id: linkableItem.id || linkableItem.sharedId,
                    projectSection,
                    usingFakeSection,
                },
                null,
                true
            )
        );
    };

    return (
        <CDSButton
            noPadding
            onClick={onClick}
            qaTag="automations-toggle"
            size="small"
            variant="text"
        >
            {!hasQuestionLogic && (
                <>
                    <i className="fa fa-magic fa-fw" /> Add Automation
                </>
            )}
            {hasQuestionLogic && (
                <>
                    <i className="fa fa-pencil fa-fw" /> Edit Automation
                </>
            )}
        </CDSButton>
    );
};

SDv2QuestionLogicIcon.propTypes = {
    entireSectionOptionOverrides: PropTypes.object,
    linkable: PropTypes.string.isRequired,
    linkableItem: PropTypes.shape({
        id: PropTypes.number,
        sharedId: PropTypes.string.isRequired,
    }).isRequired,
    projectSection: PropTypes.object.isRequired,
    usingFakeSection: PropTypes.bool,
};

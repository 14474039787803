import { fromJS, Map } from 'immutable';

import * as accountActivateActions from '../actions/account/activate';

const initialState = new Map({
    activating: false,
});

export default function accountActivateReducer(state = initialState, action = {}) {
    switch (action.type) {
        case accountActivateActions.ACTIVATE:
            return state.merge(
                fromJS({
                    activating: true,
                })
            );
        case accountActivateActions.ACTIVATE_SUCCESS:
            return state.merge(
                fromJS({
                    activating: false,
                    error: null,
                })
            );
        case accountActivateActions.ACTIVATE_FAIL:
            return state.merge(
                fromJS({
                    activating: false,
                    error: action.error,
                })
            );
        default:
            return state;
    }
}

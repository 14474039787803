import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ListGroupItem } from 'react-bootstrap';

import { HtmlContent } from '../../..';

export const EvaluationPhaseSelectItem = ({ clickHandler, evaluationPhase, isSelected }) => {
    const [showDetails, setShowDetails] = useState(false);

    const toggleDetails = (e) => {
        e.stopPropagation();
        setShowDetails((prevShowDetails) => !prevShowDetails);
    };

    const styles = require('./index.scss');

    const icon = isSelected ? 'fa-check-square text-success' : 'fa-square-o';
    return (
        <ListGroupItem bsStyle={isSelected ? 'success' : undefined} onClick={clickHandler}>
            <div className="pull-right">
                <span className={`pseudoLink ${styles.toggleButton}`} onClick={toggleDetails}>
                    <i className={`fa fa-${showDetails ? 'angle-down' : 'angle-right'}`} />{' '}
                    {showDetails ? 'hide' : 'show'} details
                </span>
            </div>
            <i className={`fa fa-lg fa-fw ${icon}`} /> {evaluationPhase.title}
            {showDetails && (
                <div className={styles.detailsContainer}>
                    {evaluationPhase.scoringCriteria
                        .filter((scoringCriterium) => !scoringCriterium.isHiddenByLogic)
                        .map((scoringCriterium, index) => {
                            return (
                                <div className={styles.scoringCriterium} key={scoringCriterium.id}>
                                    <h5>
                                        {index + 1}. {scoringCriterium.title}
                                    </h5>
                                    {scoringCriterium.description && (
                                        <HtmlContent content={scoringCriterium.description} />
                                    )}
                                </div>
                            );
                        })}
                </div>
            )}
        </ListGroupItem>
    );
};

EvaluationPhaseSelectItem.propTypes = {
    clickHandler: PropTypes.func.isRequired,
    evaluationPhase: PropTypes.shape({
        scoringCriteria: PropTypes.array.isRequired,
        title: PropTypes.string.isRequired,
    }).isRequired,
    isSelected: PropTypes.bool.isRequired,
};

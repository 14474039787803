import PropTypes from 'prop-types';
import React from 'react';

export const Main = ({ className, children, ...props }) => {
    return (
        <main className={className} {...props} id="skip">
            {children}
        </main>
    );
};

Main.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

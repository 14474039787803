import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as UUIDv4 } from 'uuid';
import { Box } from '@og-pro/ui';

import { Certification } from './Certification';
import { CertificationIcon } from '../CertificationIcon';
import { CDSButton } from '../../../../../../components';
import { addVendorCertification } from '../../../../../../actions/adminVendor';
import { form } from '../../../constants';

export const Certifications = ({ certifications, user }) => {
    const dispatch = useDispatch();
    const styles = require('./index.scss');

    const [showAddCertification, setShowAddCertification] = useState(true);
    const [editing, setEditing] = useState();

    const onAddCertification = () => {
        dispatch(addVendorCertification());
        setShowAddCertification(false);
    };

    const renderAddCertificationButton = () => (
        <CDSButton
            className={styles.addCertificationButton}
            onClick={onAddCertification}
            qaTag="vendorCertifications-addCertification"
        >
            <i className="fa fa-plus" />
            Add Certification
        </CDSButton>
    );

    return (
        <>
            {certifications?.length === 0 && (
                <Box alignItems="center" className={styles.card} display="flex" p={2}>
                    <CertificationIcon />
                    <Box
                        className={styles.noCertificationsListed}
                        display="flex"
                        flexDirection="column"
                        flexGrow={1}
                    >
                        <h4>No Certifications Listed</h4>
                        <div>If your company holds any certifications, add it here.</div>
                    </Box>
                    {renderAddCertificationButton()}
                </Box>
            )}
            {certifications?.length > 0 && (
                <Box className={styles.certifications} display="flex" flexDirection="column">
                    {certifications.map((certification, index) => (
                        <Certification
                            addingCertification={!showAddCertification}
                            certification={certification}
                            editing={editing === index}
                            form={`${form}${certification.id || index}`}
                            index={index}
                            // temporary key until we have a unique identifier
                            key={UUIDv4()}
                            setEditing={setEditing}
                            setShowAddCertification={setShowAddCertification}
                            user={user}
                        />
                    ))}
                    {showAddCertification && (
                        <Box
                            alignItems="flex-end"
                            alignSelf="stretch"
                            display="flex"
                            justifyContent="flex-end"
                        >
                            {renderAddCertificationButton()}
                        </Box>
                    )}
                </Box>
            )}
        </>
    );
};

Certifications.propTypes = {
    certifications: PropTypes.array.isRequired,
    user: PropTypes.shape({
        vendor: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }),
    }).isRequired,
};

import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';

import { form } from '../../../../../forms/constants';
import { fieldNames, templateFieldNames } from '../../../../../forms/TemplateForm/constants';
import { Alert, SearchSelect, Toggle } from '../../../../../../../../components';
import { publicPricingDisplayOptions } from '../../../../../../ProjectPostCreate/ProjectTimeline/constants';
import { TemplateEditV2FunctionsContext } from '../../../../context';

const {
    HAS_SEALED_BID,
    IS_AUTO_BID_UNSEALED,
    IS_PUBLIC_BID_PRICING_RESULT,
    IS_PUBLIC_BID_RESULT,
    IS_PUBLIC_BID_TOTAL_ONLY,
    TEMPLATE,
} = fieldNames;

const { FORCE_SEALED_BID_STATUS, IS_REVERSE_AUCTION } = templateFieldNames;

const selector = formValueSelector(form);

export function TemplateEditV2SealedBidSettingsForm() {
    const { disabled } = useContext(TemplateEditV2FunctionsContext);
    // if need to use `change` or whatever function from higher scope
    // const editContext = useContext(TemplateEditV2FunctionsContext);
    const forceSealedBidStatus = useSelector((state) =>
        selector(state, `${TEMPLATE}.${FORCE_SEALED_BID_STATUS}`)
    );
    const isReverseAuction = useSelector((state) =>
        selector(state, `${TEMPLATE}.${IS_REVERSE_AUCTION}`)
    );
    const hasSealedBid = useSelector((state) => selector(state, HAS_SEALED_BID));
    const isPublicBidPricingResult = useSelector((state) =>
        selector(state, IS_PUBLIC_BID_PRICING_RESULT)
    );

    const styles = require('../index.scss');

    return (
        <div className={styles.container}>
            {isReverseAuction && (
                <Alert bsStyle="warning" className={styles.alert}>
                    <i className="fa fa-warning" /> Settings applied because this template is set as
                    a Reverse Auction.
                </Alert>
            )}
            <div className={styles.section}>
                <h3>Lock Sealed Bid Process</h3>
                <div className={styles.row}>
                    <Field
                        component={Toggle}
                        disabled={isReverseAuction || disabled}
                        help="If allowed, project editors can change the process for each project. If not allowed, all projects will follow the process set here (unless changed by a template admin)."
                        label="Do you want to allow changes to the sealed bid process for projects based on this template?"
                        leftLabel="Allow Changes"
                        name={`${TEMPLATE}.${FORCE_SEALED_BID_STATUS}`}
                        qaTag={`templateForm-${FORCE_SEALED_BID_STATUS}`}
                        rightLabel="Don't Allow Changes"
                        useOpenGovStyle
                    />
                </div>
            </div>
            {forceSealedBidStatus && (
                <div className={styles.section}>
                    <h3>Sealed Bid Process Options</h3>
                    <div className={styles.row}>
                        <Field
                            component={Toggle}
                            disabled={isReverseAuction || disabled}
                            help="A sealed bid process prevents vendor responses from being viewed until the bid is unsealed. Once unsealed, all responses will be visible."
                            label="Do you want to use the Sealed Bid Process?"
                            name={HAS_SEALED_BID}
                            qaTag={`templateForm-${HAS_SEALED_BID}`}
                            useOpenGovStyle
                        />
                    </div>
                    {hasSealedBid && (
                        <>
                            <div className={styles.row}>
                                <Field
                                    component={Toggle}
                                    disabled={isReverseAuction || disabled}
                                    help="Bids will be unsealed automatically at the response submission deadline."
                                    label="Should bids automatically unseal?"
                                    name={IS_AUTO_BID_UNSEALED}
                                    qaTag={`templateForm-${IS_AUTO_BID_UNSEALED}`}
                                    useOpenGovStyle
                                />
                            </div>
                            <div className={styles.row}>
                                <Field
                                    component={Toggle}
                                    disabled={disabled}
                                    help="Publicly displays the pricing tables or totals from each response received."
                                    label="Should vendor pricing results be published?"
                                    name={IS_PUBLIC_BID_PRICING_RESULT}
                                    qaTag={`templateForm-${IS_PUBLIC_BID_PRICING_RESULT}`}
                                    useOpenGovStyle
                                />
                            </div>
                            {isPublicBidPricingResult && (
                                <div className={styles.row}>
                                    <Field
                                        component={SearchSelect}
                                        disabled={disabled}
                                        isSearchable={false}
                                        label=""
                                        name={IS_PUBLIC_BID_TOTAL_ONLY}
                                        options={publicPricingDisplayOptions}
                                        qaTag={`templateForm-${IS_PUBLIC_BID_TOTAL_ONLY}`}
                                        useOpenGovStyle
                                    />
                                </div>
                            )}
                            <div className={styles.row}>
                                <Field
                                    component={Toggle}
                                    disabled={disabled}
                                    help="Publicly displays specified questionnaire responses from each vendor response received."
                                    label="Should vendor responses be published?"
                                    name={IS_PUBLIC_BID_RESULT}
                                    qaTag={`templateForm-${IS_PUBLIC_BID_RESULT}`}
                                    useOpenGovStyle
                                />
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
}

import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { FieldArray } from 'redux-form';

import { fieldNames, reviewSequenceStepFieldNames } from './constants';
import { ReviewSequenceStepsField } from './ReviewSequenceStepsField';
import { getReviewSequenceFormValues } from '../selectors';
import { getMaxNumberFromList } from '../../../../../utils';

const { REVIEW_SEQUENCE_STEPS } = fieldNames;
const { ORDER_BY_ID } = reviewSequenceStepFieldNames;

export const ReviewSequenceStepsSection = ({
    addReviewSequenceStep,
    change,
    disabled,
    showFormValidation,
}) => {
    const formValues = useSelector(getReviewSequenceFormValues);

    const appendReviewSequenceStep = () => {
        const maxOrderById = getMaxNumberFromList(
            formValues[REVIEW_SEQUENCE_STEPS] || [],
            ORDER_BY_ID
        );
        return addReviewSequenceStep({
            [ORDER_BY_ID]: maxOrderById + 1,
        });
    };

    return (
        <FieldArray
            appendReviewSequenceStep={appendReviewSequenceStep}
            change={change}
            component={ReviewSequenceStepsField}
            disabled={disabled}
            name={REVIEW_SEQUENCE_STEPS}
            rerenderOnEveryChange
            showFormValidation={showFormValidation}
        />
    );
};

ReviewSequenceStepsSection.propTypes = {
    addReviewSequenceStep: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    showFormValidation: PropTypes.bool,
};

import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Card, CardActions, CardContent } from '@og-pro/ui';
import moment from 'moment';
import propTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { getUserJS } from '../../../../../../selectors';
import { RelationItem } from '../../../../../../../components';
import { contractStatusClass } from '../../../../../../../components/helpers/statusHelper';

export const SuggestedVendorContractCard = ({
    contract,
    contract: {
        contractId,
        contractParty: { companyName },
        endDate,
        id,
        startDate,
        status,
        title,
    },
    onClick,
}) => {
    const { government_id: governmentId } = useSelector(getUserJS);
    const date = moment(endDate || startDate);
    const dateLabel = endDate ? 'Ends' : 'Starts';

    return (
        <Card>
            <CardContent sx={{ padding: 1 }}>
                <RelationItem
                    header={companyName}
                    href={`/governments/${governmentId}/contracts/${id}`}
                    info={contractId}
                    status={status}
                    statusClass={contractStatusClass(status)}
                    title={title || 'Untitled Contract'}
                />
            </CardContent>
            <CardActions
                sx={{
                    borderTop: `1px solid ${capitalDesignTokens.semanticColors.border.primary}`,
                    display: 'flex',
                }}
            >
                <Box flex={1}>
                    <Button
                        onClick={() => onClick(contract)}
                        size="small"
                        startIcon={<AddIcon />}
                        sx={{ padding: 0 }}
                        variant="text"
                    >
                        Add Vendor
                    </Button>
                </Box>
                <Box
                    color={capitalDesignTokens.semanticColors.foreground.secondary}
                    fontFamily={capitalDesignTokens.foundations.typography.baseFontCondensed}
                    fontSize={capitalDesignTokens.foundations.typography.fontSize.bodyXSmall}
                >
                    <Box
                        component="span"
                        fontWeight={capitalDesignTokens.foundations.typography.fontWeight.medium}
                    >
                        {dateLabel}:
                    </Box>{' '}
                    {date.format('MM/DD/YYYY')}
                </Box>
            </CardActions>
        </Card>
    );
};

SuggestedVendorContractCard.propTypes = {
    contract: propTypes.shape({
        contractId: propTypes.string,
        contractParty: propTypes.shape({
            companyName: propTypes.string.isRequired,
        }).isRequired,
        endDate: propTypes.string,
        id: propTypes.number.isRequired,
        startDate: propTypes.string.isRequired,
        status: propTypes.string.isRequired,
        title: propTypes.string,
    }).isRequired,
    onClick: propTypes.func.isRequired,
};

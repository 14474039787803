import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';

import { form } from '../forms/constants';

const templateFormValues = (state) => getFormValues(form)(state);

export const getTemplateChecklistFormValues = createSelector([templateFormValues], (formValues) => {
    if (formValues) {
        return formValues;
    }
    return {};
});

import PropTypes from 'prop-types';
import React from 'react';

import { TemplateListHeader } from '..';

export const TemplateListTimestampHeaders = ({ useOpenGovStyle }) => {
    return (
        <div className="row">
            <div className="col-md-6">
                <TemplateListHeader useOpenGovStyle={useOpenGovStyle}>Created</TemplateListHeader>
            </div>
            <div className="col-md-6">
                <TemplateListHeader useOpenGovStyle={useOpenGovStyle}>
                    Last Updated
                </TemplateListHeader>
            </div>
        </div>
    );
};

TemplateListTimestampHeaders.propTypes = {
    useOpenGovStyle: PropTypes.bool,
};

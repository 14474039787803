import PropTypes from 'prop-types';

export const WORD_TEMPLATES_LIST_PROP_TYPES = {
    docxTemplates: PropTypes.arrayOf(
        PropTypes.shape({
            created_at: PropTypes.string.isRequired,
            governmentDocxTemplates: PropTypes.shape({
                isDefault: PropTypes.bool.isRequired,
            }).isRequired,
            id: PropTypes.number.isRequired,
            title: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        })
    ).isRequired,
    updating: PropTypes.bool,
    uploading: PropTypes.bool,
    updateDefaultDocxTemplate: PropTypes.func.isRequired,
    deleteHandler: PropTypes.func.isRequired,
    updateDocxTemplate: PropTypes.func.isRequired,
};

import React from 'react';
import { Panel } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';

import { getNavItems } from './selectors';
import { Nav, NavItem } from '../../components';

export const Admin = () => {
    const location = useLocation();
    const navItems = useSelector(getNavItems);

    const styles = require('./Admin.scss');

    const { pathname } = location;
    const basePath = pathname.replace(/\/admin.+/, '/admin');

    return (
        <Panel>
            <h1 className="visually-hidden">Admin</h1>
            <Panel.Body>
                <Nav bsStyle="tabs" className={styles.navItems} role="tablist">
                    {navItems.map((item, index) => {
                        const route = { pathname: `${basePath}/${item.path}` };

                        return (
                            <NavItem key={index} qaTag={`admin-${item.title}`} to={route}>
                                {item.title}
                            </NavItem>
                        );
                    })}
                </Nav>
                <Outlet />
            </Panel.Body>
        </Panel>
    );
};

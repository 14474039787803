export const downloadCSV = (text, filename) => {
    // JS joys: POST requests don't allow downloading files, we have to create a blob, attach it to a link, click it and get rid of the link;
    const blob = new Blob(text, { type: 'text/csv' });
    const a = document.createElement('a');
    a.style = 'display: none';
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
};

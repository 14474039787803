export const pieChartStatusColors = ['#a94442', '#f0ad4e', '#f0d54e', '#449d44'];

export const chartColors = [
    // Bootstrap colors
    '#428bca',
    '#5cb85c',
    '#f0ad4e',
    '#5bc0de',
    // Default highchart colors
    '#8085e9',
    '#2b908f',
    '#f15c80',
    '#e4d354',
    '#f45b5b',
    '#91e8e1',
    '#434348',
    '#90ed7d',
    '#7cb5ec',
    '#f7a35c',
];

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import Sticky from 'react-stickynode';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { RevisionsList } from './RevisionsList';
import { getLastProjectAudit, getProjectAuditsDisplayList } from '../selectors';
import connectData from '../../../ConnectData';
import { loadMoreRevisions, loadSaveHistoryDiff } from '../../../../actions/revisions';
import { Main, RevisionDiff, ZeroState } from '../../../../components';
import { SCREEN_MD_MAX } from '../../../../constants/mediaQuery';
import { FIXED_TOOLBAR_HEIGHT } from '../../../../constants/styles';

function fetchData(getState, dispatch, location, params) {
    const revisionParam = location.searchParams.get('revision');
    const revisionIdx = Number.parseInt(revisionParam, 10);
    const projectId = Number.parseInt(params.projectId, 10);

    if (getState().revisions.get('loaded')) {
        return dispatch(loadSaveHistoryDiff(projectId, revisionIdx));
    }
}

const mapStateToProps = (state, props) => {
    return {
        activeIndex: state.revisions.get('activeIdx'),
        hasMore: state.revisions.get('hasMore'),
        lastProjectAudit: getLastProjectAudit(state),
        loadingDiff: state.revisions.get('loadingDiff'),
        loadingMore: state.revisions.get('loadingMore'),
        loadMoreError: state.revisions.get('loadMoreError'),
        projectAudits: getProjectAuditsDisplayList(state),
        projectId: Number.parseInt(props.params.projectId, 10),
    };
};

const mapDispatchToProps = {
    loadMoreRevisions,
    loadSaveHistoryDiff,
};

// @connectData
// @connect
class ConnectedRevisionHistory extends Component {
    static propTypes = {
        activeIndex: PropTypes.number,
        hasMore: PropTypes.bool.isRequired,
        lastProjectAudit: PropTypes.object,
        loadingDiff: PropTypes.bool.isRequired,
        loadingMore: PropTypes.bool.isRequired,
        loadMoreError: PropTypes.string,
        loadMoreRevisions: PropTypes.func.isRequired,
        loadSaveHistoryDiff: PropTypes.func.isRequired,
        location: PropTypes.shape({
            query: PropTypes.shape({
                revision: PropTypes.string,
            }).isRequired,
        }).isRequired,
        params: PropTypes.shape({
            projectId: PropTypes.string.isRequired,
        }).isRequired,
        projectAudits: PropTypes.array.isRequired,
        projectId: PropTypes.number.isRequired,
    };

    componentDidMount() {
        const {
            location: {
                query: { revision },
            },
            loadingDiff,
            projectId,
        } = this.props;

        // If diff is not loading when at this point that means the page is being loaded from the
        // server which does not load the diff on server render (revisions list is not ready)
        if (!loadingDiff) {
            const revisionIdx = Number.parseInt(revision, 10);
            this.props.loadSaveHistoryDiff(projectId, revisionIdx);
        }
    }

    get styles() {
        return require('../Revisions.scss');
    }

    loadMoreRevisions = () => {
        const { lastProjectAudit, projectId } = this.props;

        return this.props.loadMoreRevisions(projectId, lastProjectAudit);
    };

    renderRevisionList(noSticky) {
        const { activeIndex, hasMore, loadingMore, loadMoreError, location, projectAudits } =
            this.props;

        return (
            <Sticky bottomBoundary="#revision-diff" enabled={!noSticky} top={FIXED_TOOLBAR_HEIGHT}>
                <h2 className={this.styles.listHeading}>Save History</h2>
                <RevisionsList
                    activeIndex={activeIndex}
                    hasMore={hasMore}
                    loadMoreError={loadMoreError}
                    loadMoreRevisions={this.loadMoreRevisions}
                    loadingMore={loadingMore}
                    location={location}
                    projectAudits={projectAudits}
                />
            </Sticky>
        );
    }

    render() {
        const { projectAudits } = this.props;

        if (projectAudits.length === 0) {
            return <ZeroState title="No revisions created yet" />;
        }

        return (
            <Main className="row">
                <div className={`col-lg-4 ${this.styles.listWrapper}`}>
                    <Media query={`(max-width: ${SCREEN_MD_MAX}px)`}>
                        {(matches) => this.renderRevisionList(matches)}
                    </Media>
                </div>
                <div className="col-lg-8" id="revision-diff">
                    <RevisionDiff />
                </div>
            </Main>
        );
    }
}

export const RevisionHistory = compose(
    connectData(fetchData),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedRevisionHistory);

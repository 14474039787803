import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';

import { options, SET_ID } from './constants';
import { SearchSelect } from '../../../../components';

const formConfig = {
    enableReinitialize: true,
};

// @reduxForm
class ConnectedCategorySetForm extends Component {
    static propTypes = {
        disabled: PropTypes.bool,
        formClassName: PropTypes.string,
        label: PropTypes.string,
    };

    render() {
        const { disabled, formClassName, label } = this.props;

        return (
            <Field
                aria-label="Category Set"
                blurInputOnSelect
                component={SearchSelect}
                disabled={disabled}
                formClassName={formClassName}
                isSearchable={false}
                label={label}
                name={SET_ID}
                options={options}
                overrideFeedback
            />
        );
    }
}

export const CategorySetForm = reduxForm(formConfig)(ConnectedCategorySetForm);

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Modal, Button } from 'react-bootstrap';

import { backgroundExamples, titles } from './constants';
import { InputBasic } from '../../../../../components';

export class IntroBackgroundHelp extends PureComponent {
    static propTypes = {
        hide: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = { currentPage: 0 };
    }

    get styles() {
        return require('./index.scss');
    }

    changePage = (changeAmount) => {
        this.setState((prevState) => ({ currentPage: prevState.currentPage + changeAmount }));
    };

    decrement = () => this.changePage(-1);

    increment = () => this.changePage(1);

    renderExamples() {
        return (
            <div>
                <p>
                    The following are a list of example backgrounds for a project. When you&#39;re
                    done reading the examples, click Finish to start writing your own.
                </p>
                {backgroundExamples.map((example, index) => (
                    <React.Fragment key={index}>
                        <p className={this.styles.exampleHeading}>{example.title}</p>
                        <InputBasic
                            className={this.styles.exampleInput}
                            disabled
                            type="textarea"
                            value={example.description}
                        />
                    </React.Fragment>
                ))}
            </div>
        );
    }

    renderFirstGuide() {
        return (
            <p>
                Background information should be relevant to your project and help the vendor better
                understand why this project is important, what the challenges are, or what would hit
                the ball out of the park.
            </p>
        );
    }

    renderContent() {
        const { currentPage } = this.state;

        switch (currentPage) {
            case 0:
                return this.renderFirstGuide();
            case 1:
                return this.renderExamples();
            default:
                return this.renderExamples();
        }
    }

    renderNextButton() {
        const { hide } = this.props;
        const { currentPage } = this.state;

        if (currentPage === 1) {
            return (
                <Button bsStyle="success" onClick={hide}>
                    Finish
                </Button>
            );
        }

        return (
            <Button bsStyle="success" onClick={this.increment}>
                Next <i className="fa fa-angle-double-right" />
            </Button>
        );
    }

    render() {
        const { hide } = this.props;
        const { currentPage } = this.state;

        const title = titles[currentPage];

        return (
            <>
                <Modal.Header closeButton>
                    <Modal.Title className={this.styles.modalTitle} id="contained-modal-title-sm">
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={this.styles.modalBody}>{this.renderContent()}</Modal.Body>
                <Modal.Footer>
                    <div className="text-center">
                        {currentPage > 0 && (
                            <Button onClick={this.decrement}>
                                <i className="fa fa-angle-double-left" /> Back
                            </Button>
                        )}
                        {this.renderNextButton()}
                    </div>
                    <div className="skipInstructions">
                        <a href="#" onClick={hide}>
                            Skip Instructions
                        </a>
                    </div>
                </Modal.Footer>
            </>
        );
    }
}

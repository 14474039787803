import { listToDict } from '@og-pro/shared-config/helpers';

export const form = 'projectAddendumForm';

const fields = ['attachments', 'description', 'diff', 'title'];

const diffFields = ['document_version1_id', 'document_version2_id', 'html'];

export const fieldNames = listToDict(fields);

export const diffFieldNames = listToDict(diffFields);

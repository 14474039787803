import PropTypes from 'prop-types';
import React from 'react';

export const StepSectionLayout = ({ children, title }) => {
    const styles = require('./StepSectionLayout.scss');
    return (
        <div className={styles.container}>
            <div className="row">
                <div className="col-xs-12 col-md-3 col-lg-2">
                    <h5 className={styles.title}>{title}</h5>
                </div>
                <div className="col-xs-12 col-md-9 col-lg-10">{children}</div>
            </div>
        </div>
    );
};

StepSectionLayout.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
};

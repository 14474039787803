import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { isPoCreated } from '@og-pro/shared-config/requisitions';

import { SequenceStep } from '../SequenceStep';
import { getIsRequisitionUsingFMS } from '../../../../../../selectors/govApp';
import { getPurchaseOrderUrls } from '../../../helpers';

export const PurchaseOrderStep = ({ requisition: { purchaseOrderObject, status } }) => {
    const styles = require('./index.scss');
    const hasPurchaseOrder = isPoCreated(status);
    const hasFMS = useSelector(getIsRequisitionUsingFMS);

    // Do not show anything on final step for non-FMS requisitions
    if (!hasFMS) {
        return null;
    }

    if (hasPurchaseOrder) {
        if (!purchaseOrderObject?.length) {
            return (
                <SequenceStep
                    stepName="Final Approval Received"
                    stepStatusIcon="fa fa-dollar"
                    stepStatusIconClassName={styles.successBackground}
                />
            );
        }

        const purchaseOrders = getPurchaseOrderUrls(purchaseOrderObject);

        return purchaseOrders.map(({ href, number }) => (
            <SequenceStep
                StepStatusComponent={<i className={`fa fa-external-link ${styles.externalLink}`} />}
                href={href}
                key={number}
                stepName={`PO #${number}`}
                stepNameClassName={styles.actionText}
                stepStatusIcon="fa fa-dollar"
                stepStatusIconClassName={styles.actionBackground}
                stepStatusText="Purchase Order Created"
                stepStatusTextClassName={styles.actionText}
            />
        ));
    }

    return (
        <SequenceStep
            stepName="No Purchase Order Yet"
            stepNameClassName={styles.mutedText}
            stepStatusIcon="fa fa-dollar"
            stepStatusIconClassName={styles.mutedBackground}
        />
    );
};

PurchaseOrderStep.propTypes = {
    requisition: PropTypes.shape({
        purchaseOrderObject: PropTypes.arrayOf(
            PropTypes.shape({
                po_number: PropTypes.number,
            })
        ),
        status: PropTypes.number.isRequired,
    }).isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@og-pro/ui';

export const HeadingFormatterAllowedVariables = ({ title, variables }) => {
    const styles = require('../shared.scss');

    return (
        <Box className={styles.allowedVariablesContainer}>
            <Box className={styles.variableTitle} mb={1}>
                {title}
            </Box>
            <Box display="flex" justifyContent="space-between">
                {variables.map(({ variable, subtitle }, i) => (
                    <Box key={i}>
                        <Box className={styles.variableValue}>{variable || <>&nbsp;</>}</Box>
                        <Box className={styles.variableSubtitle}>{subtitle}</Box>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

HeadingFormatterAllowedVariables.propTypes = {
    title: PropTypes.string.isRequired,
    variables: PropTypes.arrayOf(
        PropTypes.shape({
            variable: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
        })
    ).isRequired,
};

import { isURL } from 'validator';

import { REQUIRED_TEXT } from './constants';

export function website(values, opts = { fieldName: 'website', optional: false }) {
    const { fieldName, optional } = opts;
    const errors = {};

    if (!values[fieldName] && !optional) {
        errors[fieldName] = REQUIRED_TEXT;
    } else if (values[fieldName] && !isURL(values[fieldName])) {
        errors[fieldName] = 'Must be a valid website url';
    } else if (values[fieldName] && values[fieldName].length > 256) {
        errors[fieldName] = 'Field too long';
    }

    return errors;
}

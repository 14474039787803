import { browserHistory } from '@og-pro-migration-tools/react-router';
import { createSearchParams } from 'react-router-dom';

import { showVendorAccountModal } from './adminVendor';
import { showLoginModal, hideLoginModal } from './auth';
import {
    hideConfirmationModal,
    showConfirmationModal,
    showConfirmationModalError,
    updatingConfirmationModal,
} from './confirmation';
import { showSnackbar } from './notification';
import { emitProjectSocket } from './utils';
import { EXCLUDE_QUESTION } from '../constants/menuActions';
import { getIncompleteRequiredVendorDatumKeysJS } from '../containers/selectors';

export function shouldLoadQuestions(state, projectId) {
    if (state.questions.get('loading')) return false;
    if (!state.questions.get('loaded')) return true;
    return state.questions.get('projectId') !== projectId;
}

export const RESET = 'questions/RESET';

export function resetQuestions() {
    return { type: RESET };
}

export const LOAD_QUESTIONS = 'questions/LOAD_QUESTIONS';
export const LOAD_QUESTIONS_SUCCESS = 'questions/LOAD_QUESTIONS_SUCCESS';
export const LOAD_QUESTIONS_FAIL = 'questions/LOAD_QUESTIONS_FAIL';

export function loadQuestions(projectId, isGovernmentView) {
    return (dispatch, getState, client) => {
        const params = isGovernmentView ? { view: 'government' } : undefined;
        dispatch({ type: LOAD_QUESTIONS });
        return client
            .get(`/project/${projectId}/question`, { params })
            .then((result) => {
                dispatch({ type: LOAD_QUESTIONS_SUCCESS, result, projectId });
            })
            .catch((error) => dispatch({ type: LOAD_QUESTIONS_FAIL, error }));
    };
}

export const SHOW_QUESTION_FORM = 'questions/SHOW_QUESTION_FORM';
export const HIDE_QUESTION_FORM = 'questions/HIDE_QUESTION_FORM';

export function showQuestionForm(opts = {}) {
    return (dispatch, getState) => {
        // Prompt to login if user is not logged in
        const user = getState().auth.get('user');
        if (!user) {
            const loginHandler = () => {
                dispatch(hideLoginModal());
                // Reattempt to show question form after logging in
                return dispatch(showQuestionForm({ reload: true }));
            };

            return dispatch(
                showLoginModal(
                    'You must be logged in to ask a question. Please log in ' +
                        'or create an account.',
                    loginHandler
                )
            );
        }

        // Prompt vendor to complete vendor account info
        if (
            user.get('isVendor') &&
            (getIncompleteRequiredVendorDatumKeysJS(getState()).length > 0 ||
                !user.getIn(['organization', 'isVendorComplete'])) &&
            !opts.force
        ) {
            return dispatch(
                showVendorAccountModal({
                    onComplete: () => dispatch(showQuestionForm({ force: true })),
                })
            );
        }

        // Reload Q&A when the user has just logged in
        if (opts.reload) {
            const projectId = getState().publicProject.getIn(['project', 'id']);
            dispatch(loadQuestions(projectId));
        }
        return dispatch({ type: SHOW_QUESTION_FORM });
    };
}

export function hideQuestionForm() {
    return { type: HIDE_QUESTION_FORM };
}

export const POST_QUESTION = 'questions/POST_QUESTION';
export const POST_QUESTION_SUCCESS = 'questions/POST_QUESTION_SUCCESS';
export const POST_QUESTION_FAIL = 'questions/POST_QUESTION_FAIL';

export function postQuestion(projectId, data) {
    return (dispatch, getState, client) => {
        dispatch({ type: POST_QUESTION });
        return client
            .post(`/project/${projectId}/question`, { data })
            .then((result) => {
                const questionAction = { type: POST_QUESTION_SUCCESS, result };
                dispatch(emitProjectSocket(projectId, questionAction, 'New Question Submitted'));

                const pathname = browserHistory.location().pathname;

                browserHistory.push({
                    pathname,
                    search: createSearchParams({ number: result.number || result.id }).toString(),
                });
                return dispatch(hideQuestionForm());
            })
            .catch((error) => dispatch({ type: POST_QUESTION_FAIL, error }));
    };
}

export const POST_RESPONSE = 'questions/POST_RESPONSE';
export const POST_RESPONSE_SUCCESS = 'questions/POST_RESPONSE_SUCCESS';
export const POST_RESPONSE_FAIL = 'questions/POST_RESPONSE_FAIL';

export function postResponse(type, projectId, questionId, questionCommentId, data, opts = {}) {
    return (dispatch, getState, client) => {
        if (type === 'excludeQuestion') {
            return dispatch(showConfirmationModal(EXCLUDE_QUESTION, { projectId, questionId }));
        }

        let action = 'put';
        let url = `/project/${projectId}/question/${questionId}`;
        if (type === 'postResponse') {
            action = 'post';
        } else if (type === 'updateResponse') {
            url = `/project/${projectId}/question/${questionId}/question-comments/${questionCommentId}`;
        }

        dispatch({ type: POST_RESPONSE, questionId });
        if (opts.modal) {
            dispatch(updatingConfirmationModal());
        }

        return client[action](url, { data })
            .then((result) => {
                const responseAction = { type: POST_RESPONSE_SUCCESS, result, questionId };
                dispatch(emitProjectSocket(projectId, responseAction));
                if (opts.modal) {
                    dispatch(hideConfirmationModal());
                }
                if (opts.message) {
                    dispatch(showSnackbar(opts.message));
                }
            })
            .catch((error) => {
                dispatch({ type: POST_RESPONSE_FAIL, error, questionId });
                if (opts.modal) {
                    dispatch(showConfirmationModalError(error.message));
                }
            });
    };
}

export const ASSIGN_USER = 'questions/ASSIGN_USER';
export const ASSIGN_USER_SUCCESS = 'questions/ASSIGN_USER_SUCCESS';
export const ASSIGN_USER_FAIL = 'questions/ASSIGN_USER_FAIL';

export function assignUser(questionId, data) {
    return (dispatch, getState, client) => {
        const projectId = getState().govProjects.getIn(['selectedProject', 'id']);
        dispatch({ type: ASSIGN_USER, questionId });
        return client
            .post(`/project/${projectId}/question/${questionId}/assign`, { data })
            .then((result) => {
                const assignAction = { type: ASSIGN_USER_SUCCESS, result, questionId };
                dispatch(emitProjectSocket(projectId, assignAction));
            })
            .catch((error) => {
                dispatch({ type: ASSIGN_USER_FAIL, error, questionId });
            });
    };
}

export const TOGGLE_EDIT_QUESTION_FORM = 'questions/TOGGLE_EDIT_QUESTION_FORM';
export const TOGGLE_EDIT_RESPONSE_FORM = 'questions/TOGGLE_EDIT_RESPONSE_FORM';

export function toggleEditQuestionForm(questionId) {
    return { type: TOGGLE_EDIT_QUESTION_FORM, questionId };
}

export function toggleEditResponseForm(questionId) {
    return { type: TOGGLE_EDIT_RESPONSE_FORM, questionId };
}

export const SHOW_RELEASE_RESPONSES_MODAL = 'questions/SHOW_RELEASE_RESPONSES_MODAL';
export const HIDE_RELEASE_RESPONSES_MODAL = 'questions/HIDE_RELEASE_RESPONSES_MODAL';

export function showReleaseResponsesModal(questionIds) {
    return { type: SHOW_RELEASE_RESPONSES_MODAL, questionIds };
}

export function hideReleaseResponsesModal() {
    return { type: HIDE_RELEASE_RESPONSES_MODAL };
}

export const RELEASE_RESPONSES = 'questions/RELEASE_RESPONSES';
export const RELEASE_RESPONSES_SUCCESS = 'questions/RELEASE_RESPONSES_SUCCESS';
export const RELEASE_RESPONSES_FAIL = 'questions/RELEASE_RESPONSES_FAIL';

export function releaseResponses(questionIds) {
    return (dispatch, getState, client) => {
        const projectId = getState().govProjects.getIn(['selectedProject', 'id']);
        const data = { questionIds };
        dispatch({ type: RELEASE_RESPONSES });
        return client
            .post(`/project/${projectId}/question/release`, { data })
            .then((result) => {
                const releaseAction = { type: RELEASE_RESPONSES_SUCCESS, result };
                dispatch(emitProjectSocket(projectId, releaseAction));
                dispatch(hideReleaseResponsesModal());
                dispatch(showSnackbar(`${result.length} Questions Released!`));
            })
            .catch((error) => {
                dispatch({ type: RELEASE_RESPONSES_FAIL, error });
            });
    };
}

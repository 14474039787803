import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { reduxForm, Field } from 'redux-form';

import { form, fieldNames } from './constants';
import { validate } from './validate';
import { creatorSelectData } from '../constants';
import {
    Button,
    SearchSelect,
    SearchSelectUserOption,
    UserProfilePicture,
} from '../../../../components';

const { ID, ROLE } = fieldNames;

const formConfig = {
    form,
    validate,
};

// @reduxForm
class ConnectedPermissionForm extends PureComponent {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        pristine: PropTypes.bool.isRequired,
        reset: PropTypes.func.isRequired,
        invalid: PropTypes.bool.isRequired,
        submitting: PropTypes.bool.isRequired,
        user: PropTypes.object,
        users: PropTypes.array.isRequired,
        invited: PropTypes.bool,
        creator: PropTypes.bool,
        permissionOptions: PropTypes.array.isRequired,
        canInvite: PropTypes.bool.isRequired,
        isSelf: PropTypes.bool,
        deleteHandler: PropTypes.func.isRequired,
    };

    clickHandler = (data) => {
        const { invited, handleSubmit, reset } = this.props;
        if (invited) return handleSubmit(data);
        return handleSubmit(data).then(reset);
    };

    render() {
        const styles = require('./PermissionForm.scss');
        const {
            canInvite,
            creator,
            deleteHandler,
            handleSubmit,
            invalid,
            invited,
            isSelf,
            permissionOptions,
            pristine,
            submitting,
            user,
            users,
        } = this.props;

        const selectOptions = users.map((userData) => ({
            value: userData.id,
            label: userData.displayName,
            user: userData,
        }));

        const roleSelectOptions = creator
            ? permissionOptions.concat(creatorSelectData)
            : permissionOptions;

        const disabled = submitting || !canInvite || isSelf;

        let inviteButtonText;
        if (submitting) {
            inviteButtonText = 'Sending';
        } else if (invited) {
            inviteButtonText = 'Update';
        } else {
            inviteButtonText = 'Invite';
        }

        const inviteButton = creator ? null : (
            <Button
                bsStyle={invited ? 'primary' : 'success'}
                className={styles.inviteBtn}
                disabled={pristine || invalid || disabled}
                onClick={this.clickHandler}
                type="submit"
            >
                {inviteButtonText}
            </Button>
        );

        const removeButton =
            invited && !creator && !disabled ? (
                <i
                    className={`fa fa-times fa-2x ${styles.removeBtn}`}
                    onClick={handleSubmit(deleteHandler)}
                />
            ) : null;

        return (
            <tr>
                <td className={styles.userCol}>
                    {user && invited ? (
                        <div>
                            <UserProfilePicture horizontal user={user} />
                            &nbsp;&nbsp;
                            {user.displayName}
                        </div>
                    ) : (
                        <Field
                            aria-label="Select a user"
                            component={SearchSelect}
                            components={{
                                Option: SearchSelectUserOption,
                            }}
                            disabled={invited || disabled}
                            isClearable
                            name={ID}
                            options={selectOptions}
                            placeholder="Select user or start typing name"
                            useNullWhenUndefined
                        />
                    )}
                </td>
                <td className={styles.permissionCol}>
                    <Field
                        aria-label="Access Level"
                        component={SearchSelect}
                        disabled={creator || disabled}
                        isSearchable={false}
                        name={ROLE}
                        options={roleSelectOptions}
                    />
                </td>
                <td className={styles.actionCol}>
                    {inviteButton}
                    {removeButton}
                </td>
            </tr>
        );
    }
}

export const PermissionForm = reduxForm(formConfig)(ConnectedPermissionForm);

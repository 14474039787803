import { createSelector } from 'reselect';

import { getContractJS, getUserJS } from '../../../selectors';

export const getUserContractReviewJS = createSelector(
    [getContractJS, getUserJS],
    (contract, user) => {
        return contract.contractReviews.find(
            (contractReview) => contractReview.user_id === user.id
        );
    }
);

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ScopedCssBaseline } from '@mui/material';
import { Box, Typography } from '@og-pro/ui';

import { getIsGovernmentUsingFMS } from '../../../../selectors/govApp';
import { AccountBudgetCheck } from './BudgetCheck';
import { AccountSplitLineItems } from './SplitLineItems';
import { getAccountSplitPriceItemsJS, getIsLoading, getLoadError } from './selectors';
import {
    useAccountBudgetCheck,
    useAccountSearch,
    useOpenFiscalPeriodsWithCalendarYear,
} from '../../../../lib/ogFinancials';
import { LoadingError, LoadingSpinner } from '../../../../components';
import { extractYear } from './Filters/helpers';
import { loadAccountData } from '../../../../actions/requisitionAccountDetails';
import { hasNoBudgetCheckData } from '../../helpers/budgetCheck';
import { useQueryParam } from '../../../../hooks';

export const AccountDetailsBody = () => {
    const styles = require('./AccountDetailsBody.scss');
    const [params] = useQueryParam();
    const dispatch = useDispatch();
    const { fiscalYear, accountId } = params;
    const hasFMS = useSelector(getIsGovernmentUsingFMS);

    useEffect(() => {
        if (accountId && fiscalYear) {
            dispatch(loadAccountData(accountId, fiscalYear));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fiscalYear, accountId]);

    const isLoadingAccountData = useSelector(getIsLoading);
    const accountDataLoadError = useSelector(getLoadError);
    const accountData = useSelector(getAccountSplitPriceItemsJS);

    const accountIdAsNumber = Number.parseInt(accountId, 10);
    const { data: accountSearchData } = useAccountSearch(accountIdAsNumber, {
        enabled: hasFMS && !Number.isNaN(accountIdAsNumber),
    });

    const {
        data: fiscalPeriods,
        error: fiscalPeriodsError,
        isFetching: isFetchingFiscalPeriods,
    } = useOpenFiscalPeriodsWithCalendarYear({ enabled: hasFMS });

    const {
        data: budgetCheckData,
        error: budgetCheckError,
        isFetching: isFetchingBudgetCheck,
    } = useAccountBudgetCheck(
        Number(accountId),
        extractYear(fiscalYear, fiscalPeriods),
        false,
        false,
        {
            enabled: hasFMS,
        }
    );

    const isLoadingData = isLoadingAccountData || isFetchingBudgetCheck || isFetchingFiscalPeriods;

    if (!accountId || !fiscalYear) {
        return (
            <span>Please enter an account number and fiscal period to view account details.</span>
        );
    }

    if (isLoadingData) {
        return <LoadingSpinner useOpenGovStyle />;
    }

    if (accountDataLoadError || budgetCheckError) {
        const error = accountDataLoadError || budgetCheckError || fiscalPeriodsError;
        return <LoadingError error={error} useOpenGovStyle />;
    }

    const noBudgetCheckData = hasNoBudgetCheckData({
        spent: budgetCheckData?.accountLevel?.spentBudgetAmount,
        committed: budgetCheckData?.accountLevel?.committedBudgetAmount,
        inProcess: budgetCheckData?.accountLevel?.inProcessBudgetAmount,
        available: budgetCheckData?.accountLevel?.availableBudgetAmount,
    });

    // Use formatted account number, fallback to unformatted account number. If these don't exist, use an empty string
    const accountNumber =
        accountSearchData?.accountCompressedFormatted ??
        budgetCheckData?.accountLevel?.accountNumber ??
        '';

    if (hasFMS && noBudgetCheckData) {
        return (
            <Box marginTop={3}>
                <Typography variant="h3">
                    No Results for #{accountNumber} in {fiscalYear}
                </Typography>
                {/* There seems to be a conflict with Bootstrap and font rendering within MUI components, so ScopedCssBaseline resets that to MUI's style normalizations */}
                <ScopedCssBaseline>
                    <Box className={styles.noBudgetCheckSubtitle}>
                        As of now, there are no funds in this account and no activity to display.
                    </Box>
                </ScopedCssBaseline>
            </Box>
        );
    }

    return (
        <div>
            {hasFMS && (
                <AccountBudgetCheck
                    accountNumber={accountNumber}
                    budgetCheckData={budgetCheckData}
                    fiscalPeriod={fiscalYear}
                    isExpenseAccount={accountSearchData?.isExpenseAccount}
                />
            )}
            <AccountSplitLineItems
                accountData={accountData}
                isExpenseAccount={accountSearchData?.isExpenseAccount}
            />
        </div>
    );
};

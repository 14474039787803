import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Sticky from 'react-stickynode';

import { RevisionSearch } from './RevisionSearch';
import { LoadingSpinner, LoadingError } from '../..';
import { resetRefreshRevisionHtmlDiff, resetRevisionDiff } from '../../../actions/revisions';
import { FIXED_TOOLBAR_HEIGHT } from '../../../constants/styles';

// The height of the sticky revision header
const FIXED_REVISION_SEARCH_HEIGHT = 47;

const SELECTED_CLASS_NAME = 'selectedRevision';

const mapStateToProps = (state) => {
    return {
        loadDiffError: state.revisions.get('loadDiffError'),
        loadingDiff: state.revisions.get('loadingDiff'),
        loadingDiffMessage: state.revisions.get('loadingDiffMessage'),
        refreshRevisionHtmlDiff: state.revisions.get('refreshRevisionHtmlDiff'),
        revisionHtmlDiff: state.revisions.get('revisionHtmlDiff'),
    };
};

const mapDispatchToProps = {
    resetRefreshRevisionHtmlDiff,
    resetRevisionDiff,
};

// @connect
class ConnectedRevisionDiff extends Component {
    static propTypes = {
        loadDiffError: PropTypes.string,
        loadingDiff: PropTypes.bool.isRequired,
        loadingDiffMessage: PropTypes.string,
        noSticky: PropTypes.bool,
        refreshRevisionHtmlDiff: PropTypes.bool,
        resetRefreshRevisionHtmlDiff: PropTypes.func.isRequired,
        resetRevisionDiff: PropTypes.func.isRequired,
        revisionDiffClassName: PropTypes.string,
        revisionHtmlDiff: PropTypes.string,
        revisionSearchClassName: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.state = {
            revisionNodes: [],
            revisionNumber: 0,
        };
    }

    // State should not be set from componentDidMount, but we do so here
    // because we need the DOM loaded before we can find revisions
    componentDidMount() {
        // If loaded from server, we need to set nodes when component mounts
        if (this.props.refreshRevisionHtmlDiff) {
            return this.setNodes();
        }
    }

    // State should not be set from componentDidUpdate, but we do so here
    // because we need the DOM loaded before we can find revisions
    componentDidUpdate() {
        // If loaded from client, we need to set nodes when diff changes
        if (this.props.refreshRevisionHtmlDiff) {
            return this.setNodes();
        }
    }

    componentWillUnmount() {
        this.props.resetRevisionDiff();
    }

    get styles() {
        return require('./index.scss');
    }

    setNodes = () => {
        this.setState({
            revisionNodes: [...document.querySelectorAll('ins, del')],
            revisionNumber: 0,
        });
        return this.props.resetRefreshRevisionHtmlDiff();
    };

    scrollToRevision = (revisionNumber) => {
        const { revisionNodes, revisionNumber: curRevisionNumber } = this.state;

        // Subtract 1 to get the index position of the nodes
        const previousNode = revisionNodes[curRevisionNumber - 1];
        const revisionNode = revisionNodes[revisionNumber - 1];

        // Add selected class and scroll to the selected revision
        if (revisionNode) {
            revisionNode.scrollIntoView();
            revisionNode.classList.add(SELECTED_CLASS_NAME);
            const pageYOffset = window.pageYOffset;
            if (pageYOffset) {
                // Offset scroll position to account for both fixed navbars
                window.scroll(
                    0,
                    pageYOffset - FIXED_TOOLBAR_HEIGHT - FIXED_REVISION_SEARCH_HEIGHT - 10
                );
            }
            this.setState({ revisionNumber });
        }

        // Remove selected class from old revision
        if (previousNode) {
            previousNode.classList.remove(SELECTED_CLASS_NAME);
        }
    };

    render() {
        const {
            loadDiffError,
            loadingDiff,
            loadingDiffMessage,
            noSticky,
            revisionDiffClassName,
            revisionHtmlDiff,
            revisionSearchClassName,
        } = this.props;

        if (loadingDiff) {
            return <LoadingSpinner text={loadingDiffMessage} />;
        }

        if (loadDiffError) {
            return <LoadingError error={loadDiffError} />;
        }

        return (
            <>
                <Sticky enabled={!noSticky} innerZ={1} top={FIXED_TOOLBAR_HEIGHT}>
                    <RevisionSearch
                        className={revisionSearchClassName}
                        revisionNodes={this.state.revisionNodes}
                        revisionNumber={this.state.revisionNumber}
                        scrollToRevision={this.scrollToRevision}
                    />
                </Sticky>
                <div
                    className={classnames(this.styles.revisionDiffContainer, revisionDiffClassName)}
                >
                    {/* eslint-disable-next-line react/no-danger */}
                    <div dangerouslySetInnerHTML={{ __html: revisionHtmlDiff }} />
                </div>
            </>
        );
    }
}

export const RevisionDiff = connect(mapStateToProps, mapDispatchToProps)(ConnectedRevisionDiff);

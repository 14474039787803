import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { SectionDividerDisplay, SharedSectionLabel } from '../../../../../components';
import { DragIcon } from '../../../../../../../../components';

export class ProjectSectionDisplay extends PureComponent {
    static propTypes = {
        defaultSection: PropTypes.object.isRequired,
        isDivider: PropTypes.bool.isRequired,
        meta: PropTypes.shape({
            invalid: PropTypes.bool.isRequired,
        }).isRequired,
        projectSection: PropTypes.shape({
            isTemplate: PropTypes.bool.isRequired,
            isWritingForm: PropTypes.bool.isRequired,
            manualNumber: PropTypes.string,
            section_type: PropTypes.string.isRequired,
            shortName: PropTypes.string,
            title: PropTypes.string,
        }).isRequired,
        questionLogicIcon: PropTypes.node,
        showFormValidation: PropTypes.bool,
        useManualNumbering: PropTypes.bool.isRequired,
        useSectionDividers: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        showFormValidation: false,
    };

    get showErrors() {
        const {
            meta: { invalid },
            showFormValidation,
        } = this.props;

        return showFormValidation && invalid;
    }

    renderSectionDisplay() {
        const {
            defaultSection,
            projectSection,
            questionLogicIcon,
            useManualNumbering,
            useSectionDividers,
        } = this.props;

        return (
            <>
                <div
                    className={classnames(
                        useSectionDividers ? 'col-xs-9 col-xs-offset-1' : 'col-xs-10',
                        this.showErrors && 'text-danger'
                    )}
                >
                    <div className="row">
                        <div className="col-md-4 col-lg-5">
                            {this.showErrors && (
                                <span>
                                    <i className="fa fa-exclamation-triangle" />
                                    &nbsp;
                                </span>
                            )}
                            {useManualNumbering && projectSection.manualNumber ? (
                                <span>{projectSection.manualNumber}&nbsp;&nbsp;&nbsp;</span>
                            ) : (
                                ''
                            )}
                            {projectSection.title}&nbsp;
                            <small>
                                (<em>{projectSection.shortName}</em>)
                            </small>
                            {projectSection.isTemplate && (
                                <SharedSectionLabel
                                    includeLink
                                    projectSection={projectSection}
                                    small
                                />
                            )}
                        </div>
                        <div className="col-md-4 col-lg-3">
                            {projectSection.isWritingForm ? (
                                <span>
                                    <i className="fa fa-fw fa-pencil" /> Project editors can edit
                                </span>
                            ) : (
                                <span>
                                    <i className="fa fa-fw fa-lock" /> Only admins can edit
                                </span>
                            )}
                        </div>
                        <div className="col-md-4">
                            <i className={`fa fa-fw fa-${defaultSection.icon}`} />
                            &nbsp;
                            {defaultSection.title} type
                        </div>
                    </div>
                </div>
                <div className="col-xs-2 text-center">
                    {questionLogicIcon || <div style={{ display: 'inline-block', width: 25 }} />}
                    &nbsp;&nbsp;
                    {!useManualNumbering && <DragIcon />}
                </div>
            </>
        );
    }

    render() {
        const {
            defaultSection: { icon },
            isDivider,
            projectSection,
            useManualNumbering,
        } = this.props;

        return (
            <div className="row">
                {isDivider ? (
                    <SectionDividerDisplay
                        icon={icon}
                        projectSection={projectSection}
                        showErrors={this.showErrors}
                        useManualNumbering={useManualNumbering}
                    />
                ) : (
                    this.renderSectionDisplay()
                )}
            </div>
        );
    }
}

import { hideVendorAccountModal } from '../../actions/adminVendor';
import { hideModal as hideApprovalsModal } from '../../actions/approvals';
import { hideLoginModal } from '../../actions/auth';
import { hideConfirmationModal, hideConfirmationSimpleModal } from '../../actions/confirmation';
import { hideContactModal } from '../../actions/contacts';
import { hideContractComplaintModal } from '../../actions/contracts';
import { hideExportModal } from '../../actions/exportProject';
import { hideCommentsModal } from '../../actions/govComments';
import { hideInviteModal } from '../../actions/govPermissions';
import { hideInstructionsModal } from '../../actions/govProjects';
import { hideCreateModal, hideHelpModal } from '../../actions/project/create/projectCreate';
import { hideDownloadModal } from '../../actions/publicProject';
import { localStoragePolyfill } from '../../helpers';

export function closeOpenModals(state, dispatch) {
    // NOTE: This function should not be run in the support app since not all
    // the keys in the store exist
    if (process.env.APP_NAME === 'support') return null;

    if (state.govComments.get('showModal')) {
        dispatch(hideCommentsModal());
    }

    if (state.confirmation.get('show')) {
        dispatch(hideConfirmationModal());
    }

    if (state.confirmation.get('showSimple')) {
        dispatch(hideConfirmationSimpleModal());
    }

    if (state.govProjects.get('showInstructionsModal')) {
        dispatch(hideInstructionsModal());
    }

    if (state.exportProject.get('showModal')) {
        dispatch(hideExportModal());
    }

    if (state.approvals.get('showModal')) {
        dispatch(hideApprovalsModal());
    }

    if (state.govPermissions.get('showModal')) {
        dispatch(hideInviteModal());
    }

    if (state.adminVendor.get('showVendorAccountModal')) {
        dispatch(hideVendorAccountModal());
    }

    if (state.publicProject.get('showDownloadModal')) {
        dispatch(hideDownloadModal());
    }

    if (state.contacts.get('showContactModal')) {
        dispatch(hideContactModal());
    }

    if (state.contacts.get('showContractComplaintModal')) {
        dispatch(hideContractComplaintModal());
    }

    if (state.projectCreate.get('showHelpModal')) {
        dispatch(hideHelpModal());
    }

    if (state.projectCreate.get('showCreateModal')) {
        dispatch(hideCreateModal());
    }

    // This modal relies on this middleware to close modal on transition
    if (state.auth.get('showLoginModal')) {
        dispatch(hideLoginModal());
    }
}

export function storeSignUpReferrer(prevLocation) {
    localStoragePolyfill.setItem('afterAuthReferrer', prevLocation);
}

import { listToDict } from '@og-pro/shared-config/helpers';

export const form = 'vendorCertifications';

export const qaTagName = 'vendorCertifications';

const certificationFields = [
    'certificateNumber',
    'certification_id',
    'certifyingAgency',
    'ethnicity',
    'expirationDate',
    'id',
    'issuedDate',
    'noCertifications',
    'title',
    'vendorCertification',
];

export const certificationFieldNames = listToDict(certificationFields);

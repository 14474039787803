import React from 'react';
import { Box } from '@og-pro/ui';

import { AssociatedAttachments } from './components/AssociatedAttachments';
import { AssembledAgreements } from './components/AssembledAgreements';
import PendingAttachments from './components/PendingAttachments';

export const ContractDocuments = () => {
    return (
        <Box>
            <Box mb={4}>
                <PendingAttachments />
            </Box>
            <Box mb={4}>
                <AssembledAgreements />
            </Box>
            <Box>
                <AssociatedAttachments />
            </Box>
        </Box>
    );
};

import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';

import { form as questionnaireResponseForm } from './QuestionnaireResponseForm/constants';
import { form as questionnaireReviewNoteForm } from './QuestionnaireReviewNoteForm/constants';

const checklistFormValues = (state) => getFormValues(questionnaireResponseForm)(state);

const reviewNoteFormValues = (state) => getFormValues(questionnaireReviewNoteForm)(state);

export const getQuestionnaireResponseFormValues = createSelector(
    [checklistFormValues],
    (formValues) => {
        if (formValues) {
            return formValues;
        }
        return {};
    }
);

export const getQuestionnaireReviewNoteFormValues = createSelector(
    [reviewNoteFormValues],
    (formValues) => {
        if (formValues) {
            return formValues;
        }
        return {};
    }
);

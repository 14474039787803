export const METHOD_NAME = 'fetchData';

/**
 * 1. Skip holes in route component chain and only
 * consider components that implement fetchData
 *
 * 2. Pull out fetch data methods
 *
 * 3. Call fetch data methods and gather promises
 *
 * 4. Resolve the promises
 */

/**
 * Resolve a chain of promises from the passes in components using the function returned by
 * accessing their `${METHOD_NAME}` key.
 *
 * @param {object[]} components The components to resolve promises for
 * @param {Function} getState A state accessor function to be passed to Each promise
 * @param {Function} dispatch The action dispatch function to be passed to each promise
 * @param {object} location The router `location` object to be passed to each promise
 * @param {object} params The router `params` object to be passed to each promise
 * @return {Promise<any[]>} A wrapped promised list of the promises being resolved
 */
export default (components, getState, dispatch, location, params) => {
    const promiseChain = components
        .filter((component) => component && component[METHOD_NAME]) // 1
        .map((component) => component[METHOD_NAME]) // 2
        .map((fetchData) => fetchData(getState, dispatch, location, params)); // 3

    return Promise.all(promiseChain); // 4
};

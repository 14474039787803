import { get } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

import { hideLoginModal } from '../../actions/auth';
import { getShowLoginModalOptsJS } from './selectors';
import { getUserJS } from '../selectors';
import { LoginModalBody } from './LoginModalBody';

const mapStateToProps = (state) => {
    return {
        error: state.auth.get('loginError'),
        message: state.auth.get('showLoginModalMessage'),
        onLogin: state.auth.get('showLoginModalLoginHandler'),
        opts: getShowLoginModalOptsJS(state),
        showModal: state.auth.get('showLoginModal'),
        user: getUserJS(state),
    };
};

const mapDispatchToProps = {
    hideModal: hideLoginModal,
};

// @connect
class ConnectedLoginModal extends Component {
    static propTypes = {
        showModal: PropTypes.bool.isRequired,
        message: PropTypes.string,
        error: PropTypes.string,
        hideModal: PropTypes.func.isRequired,
        onLogin: PropTypes.func,
        opts: PropTypes.shape({
            isSessionTimeout: PropTypes.bool,
        }),
        user: PropTypes.object,
    };

    render() {
        const { error, hideModal, message, onLogin, opts, showModal, user } = this.props;
        // When the modal is shown due to a session timeout, we disable
        // the ability to dismiss the modal for both security as well as
        // an effort to preserve any unsaved work for the user.
        const isSessionTimeout = get(opts, 'isSessionTimeout', false);
        const doNothing = () => {};

        return (
            <Modal
                backdrop={isSessionTimeout ? 'static' : true}
                bsSize="sm"
                onHide={isSessionTimeout ? doNothing : hideModal}
                show={showModal}
            >
                <Modal.Header closeButton={!isSessionTimeout}>
                    <Modal.Title className="text-center">Log In</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LoginModalBody
                        error={error}
                        isSessionTimeout={isSessionTimeout}
                        message={message}
                        onLogin={onLogin}
                        user={user}
                    />
                </Modal.Body>
            </Modal>
        );
    }
}

export const LoginModal = connect(mapStateToProps, mapDispatchToProps)(ConnectedLoginModal);

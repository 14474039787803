import React from 'react';
import PropTypes from 'prop-types';

import { currencyFormatter } from '../../../../../../../helpers';

export const BidSectionModalText = ({
    auctionMaxFractionDigits,
    bidAmount,
    description,
    minBidDecrement,
    total,
}) => {
    const styles = require('./index.scss');
    const formatterOpts = {
        maximumFractionDigits: auctionMaxFractionDigits,
        useSameMinAndMax: true,
    };

    return (
        <>
            <div className={styles.description}>{description}</div>
            <div className={styles.minDecrement}>
                Minimum Decrement is {currencyFormatter({ value: minBidDecrement }, formatterOpts)}{' '}
            </div>
            <br />
            <div className={styles.priceSection}>
                <div>
                    <div className={styles.priceLabel}>Unit Price</div>
                    <div className={styles.priceValue}>
                        {currencyFormatter({ value: bidAmount }, formatterOpts)}
                    </div>
                </div>
                <div>
                    <div className={styles.priceLabel}>Total</div>
                    <div className={styles.priceValue}>
                        {currencyFormatter({ value: total }, formatterOpts)}
                    </div>
                </div>
            </div>
        </>
    );
};

BidSectionModalText.propTypes = {
    auctionMaxFractionDigits: PropTypes.number.isRequired,
    bidAmount: PropTypes.number.isRequired,
    minBidDecrement: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
};

import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { fieldNames } from './constants';
import { Checkbox, HelpIcon } from '../../../../components';

const { IS_EXTERNAL } = fieldNames;

export const ExternalField = ({ disabled }) => {
    const styles = require('../index.scss');

    return (
        <Field
            className={styles.externalCheckbox}
            component={Checkbox}
            disabled={disabled}
            name={IS_EXTERNAL}
            text={
                <span>
                    External Collaborator?
                    <HelpIcon tooltip="External collaborators can only view the projects they are assigned to" />
                </span>
            }
        />
    );
};

ExternalField.propTypes = {
    disabled: PropTypes.bool,
};

import React from 'react';
import { Panel } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import {
    SubscribedVendorCertificationChart,
    SubscribedVendorCertificationTable,
} from '../../../../components';
import VendorManagementHeader from '../VendorManagementHeader';
import { getPortalUrl } from '../../App/selectors';

export const VendorCertificationReport = () => {
    const { pathname } = useLocation();
    const params = useParams();
    const styles = require('./styles.scss');

    const governmentId = Number.parseInt(params.governmentId, 10);
    const portalUrl = useSelector(getPortalUrl);

    return (
        <div className={styles.container}>
            <Panel>
                <Panel.Body>
                    <VendorManagementHeader
                        governmentId={governmentId}
                        pathname={pathname}
                        portalUrl={portalUrl}
                    />
                    <SubscribedVendorCertificationChart />
                    <SubscribedVendorCertificationTable governmentId={governmentId} isCentered />
                    <br />
                    <p className="text-muted text-center">
                        Data based on vendors subscribed to sourcing opportunities from your
                        organization.
                    </p>
                </Panel.Body>
            </Panel>
        </div>
    );
};

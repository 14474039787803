import PropTypes from 'prop-types';
import React from 'react';
import { Tooltip, OverlayTrigger, Popover } from 'react-bootstrap';
import { v4 as UUIDv4 } from 'uuid';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { onKeyDown } from '../../helpers/accessibilityHelper';

export const HelpIcon = ({
    className,
    icon,
    onClick,
    placement = 'top',
    style,
    tooltip,
    useOpenGovStyle = false,
}) => {
    const renderTooltip = () => {
        if (tooltip) {
            return <Popover id={`popover-${UUIDv4()}`}>{tooltip}</Popover>;
        }

        return <Tooltip id={`tooltip-${UUIDv4()}`}>Click to see instructions</Tooltip>;
    };

    const styles = require('./HelpIcon.scss');
    const iconStyle = `${styles.icon} ${className}`;

    return (
        <OverlayTrigger overlay={renderTooltip()} placement={placement}>
            {useOpenGovStyle ? (
                <InfoOutlinedIcon
                    aria-label="Help Button"
                    onClick={onClick}
                    {...(onClick && { onKeyDown: (event) => onKeyDown(event, onClick) })}
                    className={className}
                    style={style}
                />
            ) : (
                <i
                    aria-label="Help Button"
                    className={`fa ${icon || 'fa-question-circle'} ${iconStyle}`}
                    onClick={onClick}
                    {...(onClick && { onKeyDown: (event) => onKeyDown(event, onClick) })}
                    role="button"
                    style={style}
                    tabIndex={0}
                />
            )}
        </OverlayTrigger>
    );
};

HelpIcon.propTypes = {
    className: PropTypes.string,
    icon: PropTypes.string,
    onClick: PropTypes.func,
    placement: PropTypes.string,
    style: PropTypes.object,
    tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    useOpenGovStyle: PropTypes.bool,
};

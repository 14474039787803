import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import simpleNumberLocalizer from 'react-widgets-simple-number';
import NumberPicker from 'react-widgets/lib/NumberPicker';
import classNames from 'classnames';

simpleNumberLocalizer();

export class NumberWidget extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        hasDecimal: PropTypes.bool,
        help: PropTypes.string,
        hideLabel: PropTypes.bool,
        input: PropTypes.shape({
            onBlur: PropTypes.func.isRequired,
            onChange: PropTypes.func.isRequired,
            onFocus: PropTypes.func.isRequired,
            value: PropTypes.any,
        }).isRequired,
        label: PropTypes.string,
        max: PropTypes.number,
        meta: PropTypes.object.isRequired,
        min: PropTypes.number,
        placeholder: PropTypes.string,
        showValidation: PropTypes.bool,
    };

    onBlur = () => {
        const {
            input: { value, onBlur },
        } = this.props;
        onBlur(this.adjustedValue(value));
    };

    onChange = (value) => {
        this.props.input.onChange(this.adjustedValue(value));
    };

    adjustedValue = (value) => {
        return value === '' || Number.isNaN(Number.parseFloat(value)) ? null : value;
    };

    render() {
        const {
            disabled,
            hasDecimal,
            help,
            hideLabel = false,
            input: { onFocus, value },
            label,
            meta: { error, touched, warning },
            min,
            placeholder,
            showValidation,
            ...props
        } = this.props;

        const styles = require('./NumberWidget.scss');

        const displayValidation = touched || showValidation;

        let groupState = 'primary';
        if (displayValidation) {
            groupState = 'has-success';
        }

        let helpBlock;
        if (displayValidation && error) {
            groupState = 'has-error';
            helpBlock = <span className="help-block">{error}</span>;
        } else if (displayValidation && warning) {
            groupState = 'has-warning';
            helpBlock = <span className="help-block">{warning}</span>;
        } else if (help) {
            helpBlock = <span className="help-block">{help}</span>;
        }

        const decimalFormat = hasDecimal ? { format: '-#,###.##' } : {};

        return (
            <div className={`form-group ${groupState}`}>
                <label className={styles.labelWrapper}>
                    <div
                        className={classNames(styles.numberLabel, {
                            [`sr-only`]: hideLabel,
                        })}
                    >
                        {label}
                    </div>
                    <NumberPicker
                        disabled={disabled}
                        min={min}
                        onBlur={this.onBlur}
                        onChange={this.onChange}
                        onFocus={onFocus}
                        placeholder={placeholder}
                        value={value === '' ? null : value}
                        {...decimalFormat}
                        {...props}
                    />
                </label>
                {helpBlock}
            </div>
        );
    }
}

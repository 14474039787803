import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { getProposalsPricingDataJS } from './selectors';
import { getProjectJS } from '../../selectors';
import connectData from '../../../ConnectData';
import { loadProjectProposalsPricing } from '../../../../actions/govProposals';
import {
    BidTabulationTable,
    LoadingError,
    LoadingSpinner,
    Main,
    SectionTitle,
    ZeroState,
} from '../../../../components';

function fetchData(getState, dispatch, location, params) {
    const projectId = Number.parseInt(params.projectId, 10);
    return dispatch(loadProjectProposalsPricing(projectId));
}

const mapStateToProps = (state) => {
    return {
        loadError: state.govProposals.get('loadPricingError'),
        loading: state.govProposals.get('loadingPricing'),
        project: getProjectJS(state),
        proposalsPricingData: getProposalsPricingDataJS(state),
    };
};

// @connectData
// @connect
class ConnectedProposalPricingCompare extends Component {
    static propTypes = {
        loadError: PropTypes.string,
        loading: PropTypes.bool.isRequired,
        project: PropTypes.shape({
            auctionMaxFractionDigits: PropTypes.number,
            government: PropTypes.shape({
                salesTax: PropTypes.number,
            }),
            template: PropTypes.shape({
                isReverseAuction: PropTypes.bool.isRequired,
            }).isRequired,
        }).isRequired,
        proposalsPricingData: PropTypes.shape({
            bidTabulations: PropTypes.array.isRequired,
            bidTabulationSortAsc: PropTypes.bool.isRequired,
            isPricingSealed: PropTypes.bool.isRequired,
            proposalsData: PropTypes.array.isRequired,
        }),
    };

    renderPricingCompareTable() {
        const {
            project: {
                auctionMaxFractionDigits,
                government: { salesTax },
                template: { isReverseAuction },
            },
            proposalsPricingData: {
                bidTabulations,
                bidTabulationSortAsc,
                isPricingSealed,
                proposalsData,
            },
        } = this.props;

        if (isPricingSealed) {
            return <ZeroState title="Pricing information is sealed" />;
        }

        if (bidTabulations.length === 0) {
            return <ZeroState title="No pricing information submitted" />;
        }

        return bidTabulations.map((bidTabulation, index) => (
            <BidTabulationTable
                auctionMaxFractionDigits={isReverseAuction ? auctionMaxFractionDigits : null}
                bidTabulation={bidTabulation}
                defaultTitle={`Table ${index + 1}`}
                isViewOnly
                key={index}
                proposalsData={proposalsData}
                salesTax={salesTax}
                showCustomColumns
                sortAscending={bidTabulationSortAsc}
                totalAll
            />
        ));
    }

    render() {
        const { loadError, loading, proposalsPricingData } = this.props;

        if (loading) {
            return <LoadingSpinner />;
        }

        if (loadError || !proposalsPricingData) {
            return <LoadingError error={loadError} />;
        }

        return (
            <Main>
                <SectionTitle
                    info="Side-by-side comparison of vendor pricing responses"
                    title="Response Pricing Comparison"
                />
                {this.renderPricingCompareTable()}
            </Main>
        );
    }
}

export const ProposalPricingCompare = compose(
    connectData(fetchData),
    connect(mapStateToProps)
)(ConnectedProposalPricingCompare);

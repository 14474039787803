import React from 'react';
import PropTypes from 'prop-types';
import { ListGroupItem } from 'react-bootstrap';

import { currencyFormatter } from '../../../../../helpers';
import { LinkContainer } from '../../../../../components';

export const SummaryListItem = ({
    auctionMaxFractionDigits,
    isNoBid,
    item,
    reverseAuctionPath,
}) => {
    const styles = require('../index.scss');

    const bidDetailsPath = `${reverseAuctionPath.replace(/\/summary\/?$/i, '')}/line-item-details`;
    const { description, quantity, unitToMeasure } = item.priceItems[0];
    const formatterOpts = {
        maximumFractionDigits: auctionMaxFractionDigits,
        useSameMinAndMax: true,
    };

    const renderNoBidItem = () => {
        return (
            <>
                <div className="col-xs-3 col-sm-2">
                    <div className={styles.noBidPriceText}>
                        {currencyFormatter({ value: 0 }, formatterOpts)}
                    </div>
                    <div className={styles.priceLabel}>Unit Price</div>
                </div>
                <div className="col-xs-3 col-sm-2">
                    <div className={styles.noBidPriceText}>
                        {currencyFormatter({ value: 0 }, formatterOpts)}
                    </div>
                    <div className={styles.priceLabel}>Total Bid</div>
                </div>
                <div className="col-xs-3 col-sm-2">
                    <div className={styles.noBidSupplierText}>No Suppliers Bid</div>
                </div>
            </>
        );
    };

    const renderSummaryListItem = () => {
        const {
            priceItems: {
                0: {
                    vendorPriceItems: {
                        0: {
                            proposal: { companyName },
                            reverseAuctionBids: {
                                0: { unitPrice },
                            },
                        },
                    },
                    reverseAuctionBids: { length: lineItemTotalNumBids },
                },
            },
        } = item;

        return (
            <>
                <div className="col-xs-3 col-sm-2">
                    <div className={styles.priceText}>
                        {currencyFormatter({ value: unitPrice }, formatterOpts)}
                    </div>
                    <div className={styles.priceLabel}>Unit Price</div>
                </div>
                <div className="col-xs-3 col-sm-2">
                    <div className={styles.priceText}>
                        {currencyFormatter({ value: quantity * unitPrice }, formatterOpts)}
                    </div>
                    <div className={styles.priceLabel}>Total Bid</div>
                </div>
                <div className="col-xs-3 col-sm-2">
                    <div className={styles.listItemText}>{companyName}</div>
                    <div className={styles.listItemLabel}>Top Supplier</div>
                </div>
                <div className="col-xs-6 col-xs-offset-6 col-sm-3 col-sm-offset-0">
                    <div className="row">
                        <div
                            className={`col-xs-6 col-sm-3 col-sm-offset-3 pull-right ${styles.bidSection}`}
                        >
                            <div className={styles.listItemText}>{lineItemTotalNumBids}</div>
                            <div className={styles.listItemLabel}>Bids</div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <LinkContainer key={item.id} to={`${bidDetailsPath}?priceItemQuery=${item.id}`}>
            <ListGroupItem className={styles.summaryItem}>
                <div className="row">
                    <div className="col-xs-3">
                        <div className={styles.auctionItemText}>{description}</div>
                        <div className={styles.quantityLabel}>
                            Quantity: {quantity}&nbsp;
                            <span className={styles.priceLabel}>(Unit: {unitToMeasure})</span>
                        </div>
                    </div>
                    {isNoBid ? renderNoBidItem() : renderSummaryListItem()}
                </div>
            </ListGroupItem>
        </LinkContainer>
    );
};

SummaryListItem.propTypes = {
    auctionMaxFractionDigits: PropTypes.number.isRequired,
    isNoBid: PropTypes.bool,
    item: PropTypes.shape({
        id: PropTypes.number.isRequired,
        priceItems: PropTypes.arrayOf(
            PropTypes.shape({
                description: PropTypes.string.isRequired,
                quantity: PropTypes.number.isRequired,
                unitToMeasure: PropTypes.string.isRequired,
                vendorPriceItems: PropTypes.arrayOf(
                    PropTypes.shape({
                        unitPrice: PropTypes.number,
                    })
                ),
            })
        ),
    }),
    reverseAuctionPath: PropTypes.string.isRequired,
};

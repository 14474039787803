import classnames from 'classnames';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export class EditIcon extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        disabled: PropTypes.bool,
        onClick: PropTypes.func.isRequired,
        show: PropTypes.bool,
    };

    static defaultProps = {
        className: undefined,
        show: false,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const { className, disabled, onClick, show } = this.props;

        if (!show) {
            return null;
        }

        return (
            <div
                className={classnames(
                    this.styles.editIcon,
                    'edit-icon',
                    'no-print',
                    disabled && this.styles.disabled,
                    className
                )}
            >
                <span className="fa-stack" onClick={disabled ? undefined : onClick}>
                    <i className="fa fa-stack-2x fa-circle" />
                    <i className="fa fa-stack-1x fa-inverse fa-pencil" />
                </span>
            </div>
        );
    }
}

import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { AutoNumberForm } from './AutoNumberForm';
import { OutlineNumberForm } from './OutlineNumberForm';
import { fields as autoNumberFields, form as autoNumberForm } from './AutoNumberForm/constants';
import { getContractAutoNumber, getProjectAutoNumber, getRequisitionAutoNumber } from './selectors';
import connectData from '../../ConnectData';
import {
    hasContractingSubscription,
    hasRequisitionOnlySubscription,
    hasRequisitionSubscription,
} from '../../GovApp/selectors';
import { LoadingError, LoadingSpinner, Main, SectionTitle } from '../../../components';
import { loadAutoNumbers, updateAutoNumber, updateOutlineNumbering } from '../../../actions/admin';
import { getOutlineNumberingJS } from '../../../selectors/app';

const fetchData = (getState, dispatch) => {
    return dispatch(loadAutoNumbers());
};

const mapStateToProps = (state) => {
    return {
        contractAutoNumber: getContractAutoNumber(state),
        hasContracting: hasContractingSubscription(state),
        hasRequisition: hasRequisitionSubscription(state),
        hasRequisitionOnly: hasRequisitionOnlySubscription(state),
        loadError: state.admin.get('loadAutoNumbersError'),
        loading: state.admin.get('loadingAutoNumbers'),
        outlineNumbering: getOutlineNumberingJS(state),
        projectAutoNumber: getProjectAutoNumber(state),
        requisitionsAutoNumber: getRequisitionAutoNumber(state),
    };
};

const mapDispatchToProps = {
    updateAutoNumber,
    updateOutlineNumbering,
};

// @connect
class ConnectedAutoNumbers extends Component {
    static propTypes = {
        contractAutoNumber: PropTypes.object,
        hasContracting: PropTypes.bool,
        hasRequisition: PropTypes.bool,
        hasRequisitionOnly: PropTypes.bool,
        loadError: PropTypes.string,
        loading: PropTypes.bool,
        outlineNumbering: PropTypes.object,
        projectAutoNumber: PropTypes.object,
        requisitionsAutoNumber: PropTypes.object,
        updateAutoNumber: PropTypes.func.isRequired,
        updateOutlineNumbering: PropTypes.func.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    renderAutoNumber(autoNumber, title, formattingVariables, disabledText) {
        return (
            <AutoNumberForm
                autoNumberId={autoNumber.id}
                disabledText={disabledText}
                form={`${autoNumberForm}-${autoNumber.id}`}
                formattingVariables={formattingVariables}
                initialValues={pick(autoNumber, autoNumberFields)}
                title={title}
                updateAutoNumber={this.props.updateAutoNumber}
            />
        );
    }

    renderOutlineNumber() {
        const { outlineNumbering } = this.props;

        return (
            <OutlineNumberForm
                initialValues={outlineNumbering}
                outlineNumberingId={outlineNumbering.id}
                updateOutlineNumbering={this.props.updateOutlineNumbering}
            />
        );
    }

    render() {
        const {
            contractAutoNumber,
            hasContracting,
            hasRequisition,
            hasRequisitionOnly,
            loadError,
            loading,
            projectAutoNumber,
            requisitionsAutoNumber,
        } = this.props;

        if (loading) {
            return <LoadingSpinner />;
        }

        if (loadError) {
            return <LoadingError error={loadError} />;
        }

        return (
            <Main>
                <SectionTitle
                    info="Configure your auto numbering format for project and contract IDs"
                    title="Auto Numbering"
                />
                {this.renderAutoNumber(
                    projectAutoNumber,
                    'Project Auto Numbering',
                    [
                        {
                            label: '{{COUNTER}}',
                            value: 'Current counter. Gets incremented when a new number is issued.',
                        },
                        {
                            label: '{{PROCESS}}',
                            value: "Procurement process acronym used by the project's template",
                            regexp: /{{\s*PROCESS\s*}}/gi,
                            sampleValue: 'RFP',
                        },
                        {
                            label: '{{USER}}',
                            value: "Initials of the project's procurement contact",
                            regexp: /{{\s*USER\s*}}/gi,
                            sampleValue: 'AB',
                        },
                    ],
                    hasRequisitionOnly
                        ? 'Auto numbering for projects is only used with the procurement module'
                        : undefined
                )}
                {this.renderAutoNumber(
                    contractAutoNumber,
                    'Contract Auto Numbering',
                    [
                        {
                            label: '{{COUNTER}}',
                            value: 'Current counter. Gets incremented when a new number is issued.',
                        },
                        {
                            label: '{{USER}}',
                            value: "Initials of the contract's procurement contact",
                            regexp: /{{\s*USER\s*}}/gi,
                            sampleValue: 'AB',
                        },
                    ],
                    hasContracting
                        ? undefined
                        : 'Auto numbering for contracts is only used with the contracts module'
                )}
                {hasRequisition &&
                    this.renderAutoNumber(
                        requisitionsAutoNumber,
                        'Requests Auto Numbering',
                        [
                            {
                                label: '{{COUNTER}}',
                                value: 'Current counter. Gets incremented when a new number is issued.',
                            },
                            {
                                label: '{{ABBREVIATION}}',
                                value: "Abbreviation for the request's group",
                                regexp: /{{\s*ABBREVIATION\s*}}/gi,
                                sampleValue: 'IT',
                            },
                        ],
                        hasRequisition
                            ? undefined
                            : 'Auto numbering for requests is only used with the requests module'
                    )}
                {this.renderOutlineNumber()}
            </Main>
        );
    }
}

export const AutoNumbers = compose(
    connectData(fetchData),
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedAutoNumbers);

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export class CheckboxRenderer extends PureComponent {
    static propTypes = {
        column: PropTypes.object.isRequired,
        node: PropTypes.object.isRequired,
        readOnly: PropTypes.bool,
        value: PropTypes.bool,
    };

    changeHandler = (e) => {
        const { column, node } = this.props;

        const { checked } = e.target;
        const { colId } = column;
        node.setDataValue(colId, checked);
    };

    render() {
        const { readOnly, value } = this.props;

        if (readOnly) {
            return value ? <i className="fa fa-check" /> : null;
        }

        return (
            <input
                aria-label="checkbox"
                checked={value}
                onChange={this.changeHandler}
                type="checkbox"
            />
        );
    }
}

import { get } from 'lodash';
import superagent from 'superagent';

import { defaultError } from '../constants';
import { apiHost } from './utils';

const API_METHODS = ['get', 'post', 'put', 'patch', 'del'];

function formatUrl(path) {
    return apiHost() + path;
}

export default class ApiClient {
    constructor(requestHeaders) {
        API_METHODS.forEach((method) => {
            /**
             * Creates an API request wrapper each HTTP method
             * @param {string} path The API path to request
             * @param {object} opts Accepts two keys: params (for query params)
             *                      and data (for request bodies)
             * @return {Promise<object>} returned API response
             */
            this[method] = (path, { params, data } = {}) => {
                const request = superagent[method](formatUrl(path));

                // Set cookies if the request originated from the server
                if (process.env.SERVER && requestHeaders.cookie) {
                    request.set('cookie', requestHeaders.cookie);
                }

                const queryParams = params;

                if (queryParams) {
                    request.query(queryParams);
                }

                if (data) {
                    request.send(data);
                }

                // Enable sending of CORS cookies
                request.withCredentials();

                return request
                    .then((response = {}) => {
                        return response.body;
                    })
                    .catch((error) => {
                        // The standard status error code message is used as the error message by
                        // default. We prefer to use the message provided by the API server if it
                        // exists, so we replace it here.
                        const serverErrorMessage = get(error, 'response.body.message');
                        if (serverErrorMessage) {
                            error.message = serverErrorMessage;
                        }

                        // Network failures, timeouts, and other errors that produce no response
                        // will contain no `err.status` or `err.response` fields.
                        // https://visionmedia.github.io/superagent/#error-handling
                        if (!error.status) {
                            error.status = 500;

                            // Note message is set in these cases, but we want to control the
                            // message, so it is not browser specific.
                            error.message = defaultError;
                        }

                        return Promise.reject(error);
                    });
            };
        });
    }
}

import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import { approvalStatusTypes } from '@og-pro/shared-config/requisitions';

import { SDv2ErrorBanner } from '../../../../../components/SDv2';

export const RejectionBanner = ({ rejectedStep, rejectionNote }) => {
    const {
        stepApproval: { stepPositionApprovals, lastActionAt, lastActor },
    } = rejectedStep;

    const positionApproval = stepPositionApprovals.find(
        ({ status }) => status === approvalStatusTypes.REJECTED
    );

    return (
        <SDv2ErrorBanner
            description={rejectionNote}
            hasLargeIcon
            title={`Rejected by ${lastActor.displayName} on behalf of ${positionApproval.position.name} on ${moment(lastActionAt).format('M/D/YY [at] h:mma')}`}
        />
    );
};

RejectionBanner.propTypes = {
    rejectedStep: PropTypes.shape({
        stepApproval: PropTypes.shape({
            lastActor: PropTypes.shape({
                displayName: PropTypes.string.isRequired,
            }).isRequired,
            lastActionAt: PropTypes.string.isRequired,
            stepPositionApprovals: PropTypes.arrayOf(
                PropTypes.shape({
                    status: PropTypes.number.isRequired,
                })
            ).isRequired,
        }),
    }).isRequired,
    rejectionNote: PropTypes.string,
};

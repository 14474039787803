function isEmpty(value) {
    return value === undefined || value === null || value === '';
}

function findError(rules, value, data) {
    let error;
    rules.forEach((rule) => {
        error = rule(value, data);
    });
    return error;
}

export function required(value) {
    if (isEmpty(value)) {
        return 'Required';
    }
}

export function minLength(min) {
    return (value) => {
        if (!isEmpty(value) && value.length < min) {
            return `Must be at least ${min} characters`;
        }
    };
}

export function maxLength(max) {
    return (value) => {
        if (!isEmpty(value) && value.length > max) {
            return `Must be no more than ${max} characters`;
        }
    };
}

export function createValidator(rulesObject) {
    return (data = {}) => {
        const errors = {};
        Object.keys(rulesObject).forEach((fieldName) => {
            const rules = rulesObject[fieldName];
            const fieldValue = data[fieldName];
            const error = findError(rules, fieldValue, data);
            if (error) {
                errors[fieldName] = error;
            }
        });
        return errors;
    };
}

/*
 * Workaround needed for forms that frequently register and unregister fields. By default,
 * validation is not run when the registered fields are changed, so this function is needed to
 * provide that behavior. Everything here is the same as the default except for the last part of
 * the conditional that does the `registeredFields` comparison.
 *
 * Issue and workaround:
 * https://github.com/erikras/redux-form/issues/3276#issuecomment-378996666
 */
export function shouldError(params) {
    const {
        values,
        nextProps,
        props,
        initialRender,
        lastFieldValidatorKeys,
        fieldValidatorKeys,
        structure,
    } = params;

    if (initialRender) {
        return true;
    }

    return (
        !structure.deepEqual(values, nextProps && nextProps.values) ||
        !structure.deepEqual(lastFieldValidatorKeys, fieldValidatorKeys) ||
        props.registeredFields !== nextProps.registeredFields // Added to validate form when new form fields are registered
    );
}

import React from 'react';
import { Outlet } from 'react-router-dom';

import * as Gov from '../../containers/GovApp';
import { legacyFetchData, requireVendorListPermissions } from '../loaders';

export default (getState, dispatch) => {
    return [
        {
            element: <Outlet />,
            loader: requireVendorListPermissions(getState),
            path: 'vendors',
            children: [
                {
                    element: <Gov.VendorSearch />,
                    index: true,
                    loader: legacyFetchData(getState, dispatch, Gov.VendorSearch),
                },
                {
                    element: <Gov.VendorListsManager />,
                    path: 'lists',
                    loader: legacyFetchData(getState, dispatch, Gov.VendorListsManager),
                },
                {
                    element: <Gov.VendorListManager />,
                    path: 'lists/:vendorListId',
                    loader: legacyFetchData(getState, dispatch, Gov.VendorListManager),
                },
                {
                    element: <Gov.VendorCertificationReport />,
                    path: 'certifications',
                    loader: legacyFetchData(getState, dispatch, Gov.VendorCertificationReport),
                },
                {
                    element: <Gov.InvitedVendorList />,
                    path: 'invited',
                    loader: legacyFetchData(getState, dispatch, Gov.InvitedVendorList),
                },
            ],
        },
    ];
};

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Badge } from '../..';

export const HiddenFromPublicBadge = (props) => {
    if (
        !props.hidePublicClosedProjectsLength ||
        !props.hidePublicClosedProjectsUnit ||
        !props.project.recordCloseDate
    ) {
        return null;
    }

    if (
        moment(props.project.recordCloseDate).isAfter(
            moment().subtract(
                props.hidePublicClosedProjectsLength,
                props.hidePublicClosedProjectsUnit
            )
        )
    ) {
        return null;
    }

    return (
        <Badge
            icon="eye-slash"
            tooltipText={`Hidden from public after ${props.hidePublicClosedProjectsLength} ${props.hidePublicClosedProjectsUnit}`}
        />
    );
};

HiddenFromPublicBadge.propTypes = {
    hidePublicClosedProjectsLength: PropTypes.number,
    hidePublicClosedProjectsUnit: PropTypes.string,
    project: PropTypes.shape({
        recordCloseDate: PropTypes.string,
    }).isRequired,
};

import PropTypes from 'prop-types';

const BaseSectionType = {
    active: PropTypes.bool,
    numbering: PropTypes.string,
    title: PropTypes.string.isRequired,
    comments: PropTypes.number,
    onClick: PropTypes.func,
    index: PropTypes.number.isRequired,
    manualNumber: PropTypes.string,
    showValidationError: PropTypes.bool,
    shortName: PropTypes.string,
};

const SectionType = PropTypes.shape({
    ...BaseSectionType,
    children: PropTypes.arrayOf(PropTypes.shape({ ...BaseSectionType })),
});

export { SectionType };

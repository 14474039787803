import { showSnackbar } from '../notification';

export const LOAD_PRE_INVITE_LIST = 'gov/projects/preInvite/LOAD_PRE_INVITE_LISTS';
export const LOAD_PRE_INVITE_LIST_SUCCESS = 'gov/projects/preInvite/LOAD_PRE_INVITE_LIST_SUCCESS';
export const LOAD_PRE_INVITE_LIST_FAIL = 'gov/projects/preInvite/LOAD_PRE_INVITE_LIST_FAIL';

export function loadPreInviteListRawRequest(projectId) {
    return (dispatch, getState, client) => {
        return client.get(`/project/${projectId}/pre-invite-list`);
    };
}

export function loadPreInviteList(projectId) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_PRE_INVITE_LIST });
        return client
            .get(`/project/${projectId}/pre-invite-list`)
            .then((result) => {
                dispatch({ type: LOAD_PRE_INVITE_LIST_SUCCESS, result });
                return result;
            })
            .catch((error) => {
                dispatch({ type: LOAD_PRE_INVITE_LIST_FAIL });
                dispatch(showSnackbar(`Error: ${error.message}`));
                return null;
            });
    };
}

export const SHOW_PRE_INVITE_MODAL = 'gov/projects/preInvite/SHOW_PRE_INVITE_MODAL';
export const HIDE_PRE_INVITE_MODAL = 'gov/projects/preInvite/HIDE_PRE_INVITE_MODAL';
export const SHOW_EXPANDED_SUPPLIER_NETWORK_MODAL =
    'gov/projects/preInvite/SHOW_EXPANDED_SUPPLIER_NETWORK_MODAL';
export const HIDE_EXPANDED_SUPPLIER_NETWORK_MODAL =
    'gov/projects/preInvite/HIDE_EXPANDED_SUPPLIER_NETWORK_MODAL';

export function showPreInviteModal(projectId) {
    return (dispatch) => {
        dispatch(loadPreInviteList(projectId));
        dispatch({ type: SHOW_PRE_INVITE_MODAL });
    };
}

export function hidePreInviteModal() {
    return { type: HIDE_PRE_INVITE_MODAL };
}

export function showExpandedSupplierNetworkModal() {
    return { type: SHOW_EXPANDED_SUPPLIER_NETWORK_MODAL };
}

export function hideExpandedSupplierNetworkModal() {
    return { type: HIDE_EXPANDED_SUPPLIER_NETWORK_MODAL };
}

export function createOrUpdatePreInviteList(projectId, data) {
    return (dispatch, getState, client) => {
        return client.put(`/project/${projectId}/pre-invite-list`, { data }).then((result) => {
            return result;
        });
    };
}

export function getInviteVendorsSearchParams(projectId) {
    return (dispatch, getState, client) => {
        return client.get(`/project/${projectId}/invite-vendors-search-params`);
    };
}

export function putInviteVendorsSearchParams(projectId, data) {
    return (dispatch, getState, client) => {
        return client.put(`/project/${projectId}/invite-vendors-search-params`, { data });
    };
}

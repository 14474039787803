import { sectionTypeNames } from '@og-pro/shared-config/sections';

import { CRITERIA, EVALUATION_PHASES, PROJECT_SECTION, QUESTIONNAIRES, USER_ID } from './constants';
import {
    projectContentValidate,
    sectionTypesWithCriteria,
} from '../../forms/ProjectContentForm/validate';
import { basicProjectSectionValidate } from '../../forms/TemplateProjectSectionsForm/validate';
import { arrayError } from '../../../../../Forms/validation';

const { DIVIDER, EVALUATION_PHASE, PRICING, QUESTIONNAIRE, SCOPE, TERMS, TEXT_AREA } =
    sectionTypeNames;

export const validate = (values, props) => {
    const { useManualNumbering } = props;
    const projectSection = values && values[PROJECT_SECTION];
    const projectSections = projectSection ? [projectSection] : [];

    let shouldValidateManualNumbering = false;
    // if the section has criteria, we will determine if we need to validate
    // the manual number fields IF at least one of the items has the manual number set
    // Given that this is a shared section and that `useManualNumbering` is not strict, it only means
    // that the user CAN use manual numbers in their templates. Not neccesarily that it uses them.
    if (useManualNumbering && sectionTypesWithCriteria.includes(projectSection?.section_type)) {
        shouldValidateManualNumbering = Object.values(values.criteria).reduce((acc, criteria) => {
            return criteria.some((c) => !!c.manualNumber);
        }, shouldValidateManualNumbering);
    }

    const errors = projectContentValidate(values, projectSections, shouldValidateManualNumbering);

    if (!values[USER_ID]) {
        errors[USER_ID] = 'Please select an owner';
    }

    if (projectSection) {
        errors[PROJECT_SECTION] = basicProjectSectionValidate(projectSection, {
            isSharedSection: true,
            shouldValidateManualNumbering,
        });

        switch (projectSection.section_type) {
            case EVALUATION_PHASE: {
                if ((values[EVALUATION_PHASES] || []).length === 0) {
                    errors[EVALUATION_PHASES] = arrayError(
                        'Please include at least one evaluation phase'
                    );
                }
                break;
            }
            case QUESTIONNAIRE: {
                if ((values[QUESTIONNAIRES] || []).length === 0) {
                    errors[QUESTIONNAIRES] = arrayError(
                        'Please include at least one questionnaire'
                    );
                }
                break;
            }
            case TERMS: {
                const criteriaKey = `${projectSection.id}_${projectSection.projectSubsections[0].id}`;
                const criteria = (values[CRITERIA] || {})[criteriaKey] || [];
                if (criteria.length === 0) {
                    errors[CRITERIA] = {
                        [criteriaKey]: arrayError('Please include at least one list item'),
                    };
                }
                break;
            }
            case DIVIDER:
            case PRICING:
            case SCOPE:
            case TEXT_AREA:
            default:
                break;
        }
    }

    return errors;
};

import React from 'react';
import PropTypes from 'prop-types';

import { bulkUpdateFieldNames } from '../constants';
import { getUserFullName } from '../helpers';

const {
    PROJECTS_ENABLED,
    PROJECTS_PROCUREMENT_CONTACT,
    PROJECTS_CONTACT,
    INTAKES_ENABLED,
    INTAKES_PROCUREMENT_CONTACT,
    INTAKES_CONTACT,
    CONTRACTS_ENABLED,
    CONTRACTS_PROCUREMENT_CONTACT,
    CONTRACTS_CONTACT,
} = bulkUpdateFieldNames;

export const BulkUpdateConfirmationModal = ({ disable, formValues, oldUser, users }) => {
    const styles = require('./index.scss');
    const replace =
        formValues &&
        (formValues[PROJECTS_ENABLED] ||
            formValues[INTAKES_ENABLED] ||
            formValues[CONTRACTS_ENABLED]);
    const oldUserFullName = getUserFullName(users, oldUser);

    const renderTable = () => {
        return (
            <table className={styles.table}>
                <tbody>
                    <tr>
                        <td aria-label="Empty cell" />
                        <th>Procurement Contact</th>
                        <th>Project Contact</th>
                        <th>Contract Contact</th>
                    </tr>
                    {formValues[PROJECTS_ENABLED] && (
                        <tr>
                            <th>Projects</th>
                            <td>
                                {getUserFullName(users, formValues[PROJECTS_PROCUREMENT_CONTACT])}
                            </td>
                            <td>{getUserFullName(users, formValues[PROJECTS_CONTACT])}</td>
                            <td aria-label="Empty cell" />
                        </tr>
                    )}
                    {formValues[INTAKES_ENABLED] && (
                        <tr>
                            <th>Intakes</th>
                            <td>
                                {getUserFullName(users, formValues[INTAKES_PROCUREMENT_CONTACT])}
                            </td>
                            <td>{getUserFullName(users, formValues[INTAKES_CONTACT])}</td>
                            <td aria-label="Empty cell" />
                        </tr>
                    )}
                    {formValues[CONTRACTS_ENABLED] && (
                        <tr>
                            <th>Contracts</th>
                            <td>
                                {getUserFullName(users, formValues[CONTRACTS_PROCUREMENT_CONTACT])}
                            </td>
                            <td aria-label="Empty cell" />
                            <td>{getUserFullName(users, formValues[CONTRACTS_CONTACT])}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    };

    const renderDisableAndReplace = () => (
        <>
            <div className={styles.title}>
                Are you sure you want to disable and replace the following?
            </div>
            <div className={styles.title}>
                Disable: <span className={styles.subText}>{oldUserFullName}</span>
            </div>
            <div className={styles.title}>{`Replace ${oldUserFullName}'s assignments with: `}</div>
            {renderTable()}
        </>
    );

    const renderDisable = () => (
        <>
            <div className={styles.title}>Are you sure you want to disable the following?</div>
            <div className={styles.title}>{`Disable: ${oldUserFullName}`}</div>
        </>
    );

    const renderReplace = () => (
        <>
            <div className={styles.title}>Are you sure you want to replace the following?</div>
            <div className={styles.title}>{`Replace ${oldUserFullName}'s assignments with: `}</div>
            {renderTable()}
        </>
    );

    const renderBody = () => {
        if (disable && replace) {
            return renderDisableAndReplace();
        }

        if (disable) {
            return renderDisable();
        }

        return renderReplace();
    };

    return <div className={styles.modalContainer}>{renderBody()}</div>;
};

BulkUpdateConfirmationModal.propTypes = {
    disable: PropTypes.bool,
    formValues: PropTypes.object,
    oldUser: PropTypes.number,
    users: PropTypes.arrayOf(
        PropTypes.shape({
            department_id: PropTypes.number,
            id: PropTypes.number,
            role: PropTypes.string.isRequired,
            uuid: PropTypes.string,
        })
    ).isRequired,
};

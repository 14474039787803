import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Panel, Well } from 'react-bootstrap';
import { Outlet, createSearchParams } from 'react-router-dom';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { getTermsText } from './selectors';
import * as authActions from '../../actions/auth';

const mapStateToProps = (state, props) => {
    return {
        loginError: state.auth.get('loginError'),
        passwordSetError: state.auth.get('setPasswordError'),
        resetError: state.auth.get('resetPasswordError'),
        termsText: getTermsText(state, props),
    };
};

const mapDispatchToProps = authActions;

// @connect
class ConnectedLogin extends Component {
    static propTypes = {
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
            query: PropTypes.object.isRequired,
        }).isRequired,
        loginError: PropTypes.string,
        loginFailError: PropTypes.func.isRequired,
        passwordSetError: PropTypes.string,
        removeLoginError: PropTypes.func.isRequired,
        resetError: PropTypes.string,
        router: PropTypes.object.isRequired,
        termsText: PropTypes.string,
    };

    get styles() {
        return require('./Login.scss');
    }

    componentDidMount() {
        const {
            location: {
                pathname,
                query: { 'error-message': errorMessage, ...query },
            },
            loginFailError,
            router,
        } = this.props;

        if (errorMessage) {
            router.replace({ pathname, search: createSearchParams(query).toString() });
            loginFailError(errorMessage);
        }
    }

    renderError() {
        const {
            location: { pathname },
            loginError,
            passwordSetError,
            removeLoginError,
            resetError,
        } = this.props;

        let error;
        if (pathname.indexOf('/login') !== -1) {
            error = loginError;
        }

        if (pathname.indexOf('/reset-password') !== -1) {
            error = resetError;
        }

        if (pathname.indexOf('/set-password') !== -1) {
            error = passwordSetError;
        }

        if (!error) {
            return null;
        }

        return (
            <Well bsSize="sm" className={this.styles.errorWell} role="alert">
                {error}
                <i className={`${this.styles.removeIcon} fa fa-times`} onClick={removeLoginError} />
            </Well>
        );
    }

    renderTerms() {
        const { termsText } = this.props;

        if (!termsText) {
            return null;
        }

        return (
            <p className={`${this.styles.terms}`}>
                By clicking &quot;{termsText}&quot; you agree to our&nbsp;
                <a
                    className={`pseudo Link ${this.styles.termsLinks}`}
                    href="https://opengov.com/procurement-terms-of-service/"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    Terms
                </a>
                &nbsp;and&nbsp;
                <a
                    className={`pseudo Link ${this.styles.termsLinks}`}
                    href="https://opengov.com/privacy-policy"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    Privacy Policy
                </a>
                .
            </p>
        );
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 col-xs-10 col-xs-offset-1">
                    <div className={this.styles.container}>
                        <div className="row">
                            <div className="col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8 col-lg-offset-3 col-lg-6">
                                <img
                                    alt="OpenGov Procurement logo - blue"
                                    className={`img-responsive ${this.styles.logo}`}
                                    src="https://assets.procurement.opengov.com/assets/opengov/opengov_pro_logo_blue.svg"
                                />
                            </div>
                        </div>
                        {this.renderError()}
                        <Panel className={this.styles.loginPanel}>
                            <Panel.Body>
                                <Outlet />
                            </Panel.Body>
                        </Panel>
                        {this.renderTerms()}
                    </div>
                </div>
            </div>
        );
    }
}

export const Login = withRouter(connect(mapStateToProps, mapDispatchToProps)(ConnectedLogin));

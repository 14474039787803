/**
 * Wrapper for the Segment track function
 * https://segment.com/docs/spec/track
 *
 * @param {string} name The name of the event
 * @param {object} props The event properties
 * @return {false|undefined} `false` if `process.env.SERVER` is not defined. This indicates that
 *                             the event was not tracked
 */
export function trackEvent(name, props = {}) {
    if (process.env.SERVER) return false;

    if (window.analytics) {
        window.analytics.track(name, props);
    }
}

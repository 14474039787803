import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { StandardDocumentForm } from '../StandardDocumentForm';
import { StandardDocumentRenameForm } from '../StandardDocumentRenameForm';
import { fieldNames, renameForm, updateForm } from '../constants';
import { CDSButton, StandardDocumentModalButton, StatusLabel } from '../../../../components';

const { TITLE } = fieldNames;

export class StandardDocumentItem extends PureComponent {
    static propTypes = {
        deleteStandardDocument: PropTypes.func.isRequired,
        governmentId: PropTypes.number.isRequired,
        hasAttachmentOption: PropTypes.bool,
        isSingleDocument: PropTypes.bool,
        loadStandardDocuments: PropTypes.func.isRequired,
        showConfirmationSimpleModal: PropTypes.func.isRequired,
        standardDocument: PropTypes.shape({
            created_at: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
            isActive: PropTypes.bool.isRequired,
            title: PropTypes.string.isRequired,
        }).isRequired,
        templateVariableOptions: PropTypes.array,
        updateStandardDocument: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            deleteError: null,
            deleting: false,
            showForm: false,
            updateError: null,
            updating: false,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    deleteStandardDocument = () => {
        const { deleteStandardDocument, showConfirmationSimpleModal, standardDocument } =
            this.props;

        const confirmationHandler = () => {
            this.setState({
                deleteError: null,
                deleting: true,
            });
            return deleteStandardDocument(standardDocument.id).catch((error) => {
                this.setState({
                    deleteError: error.message,
                    deleting: false,
                });
            });
        };

        showConfirmationSimpleModal(confirmationHandler, {
            btnText: 'Delete',
            text: 'Are you sure you want to delete this document?',
        });
    };

    hideForm = () => this.setState({ showForm: false });

    showForm = () => this.setState({ showForm: true });

    setActive = () => {
        const { loadStandardDocuments: load, showConfirmationSimpleModal } = this.props;

        showConfirmationSimpleModal(
            () => this.updateStandardDocument({ isActive: true }, { onSuccess: load }),
            {
                btnText: 'Activate Document',
                bsStyle: 'primary',
                icon: 'power-off',
                text: 'Are you sure you want to make this the active document?',
            }
        );
    };

    setDeactive = () => {
        const { showConfirmationSimpleModal } = this.props;

        showConfirmationSimpleModal(() => this.updateStandardDocument({ isActive: false }), {
            btnText: 'Deactivate Document',
            bsStyle: 'danger',
            icon: 'power-off',
            text: 'Are you sure you want to make this the document inactive?\nYou will have no active document.',
        });
    };

    updateStandardDocument = (data, opts = {}) => {
        const { standardDocument, updateStandardDocument } = this.props;

        this.setState({
            updateError: null,
            updating: true,
        });

        return updateStandardDocument(standardDocument.id, data)
            .then(() => {
                this.setState({ updating: false });
                this.hideForm();
                if (opts.onSuccess) {
                    opts.onSuccess();
                }
            })
            .catch((error) => {
                this.setState({
                    updateError: error.message,
                    updating: false,
                });
            });
    };

    render() {
        const {
            governmentId,
            hasAttachmentOption,
            isSingleDocument,
            standardDocument,
            standardDocument: { created_at: createdAt, id, isActive, title },
            templateVariableOptions,
        } = this.props;

        const { deleteError, deleting, showForm, updateError, updating } = this.state;

        if (showForm && isSingleDocument) {
            return (
                <StandardDocumentForm
                    closeForm={() => this.setState({ showForm: false })}
                    disabled={updating}
                    form={updateForm}
                    hasAttachmentOption={hasAttachmentOption}
                    initialValues={standardDocument}
                    onSubmit={this.updateStandardDocument}
                    s3Url={`/government/${governmentId}/s3`}
                    serverError={updateError}
                    templateVariableOptions={templateVariableOptions}
                />
            );
        }

        if (showForm) {
            return (
                <StandardDocumentRenameForm
                    closeForm={this.hideForm}
                    disabled={updating}
                    form={`${renameForm}${id}`}
                    initialValues={{
                        [TITLE]: title,
                    }}
                    onSubmit={this.updateStandardDocument}
                    serverError={updateError}
                />
            );
        }

        return (
            <div className="row">
                <div className="col-md-7">
                    <StandardDocumentModalButton
                        className={this.styles.documentTitleContainer}
                        standardDocument={standardDocument}
                    >
                        <span className={this.styles.documentTitle}>{title}</span>
                        {isActive && (
                            <StatusLabel bsStyle="success" className={this.styles.activeLabel}>
                                Active
                            </StatusLabel>
                        )}
                    </StandardDocumentModalButton>
                    <CDSButton
                        className={this.styles.linkButton}
                        disabled={deleting || updating}
                        noPadding
                        onClick={isActive ? this.setDeactive : this.setActive}
                        size="small"
                        variant="text"
                    >
                        {isActive ? 'Deactivate' : 'Use this document'}
                    </CDSButton>
                    {(deleteError || updateError) && (
                        <div className="text-danger">{deleteError || updateError}</div>
                    )}
                </div>
                <div className="col-xs-6 col-md-3">{moment(createdAt).format('lll')}</div>
                <div className="col-xs-6 col-md-2 text-right">
                    <CDSButton
                        className={this.styles.linkButton}
                        disabled={deleting || updating}
                        noPadding
                        onClick={this.showForm}
                        size="small"
                        variant="text"
                    >
                        <i className="fa fa-pencil" /> {isSingleDocument ? 'Edit' : 'Rename'}
                    </CDSButton>
                    &nbsp;&nbsp;
                    <CDSButton
                        className={this.styles.linkButton}
                        disabled={deleting || updating}
                        noPadding
                        onClick={this.deleteStandardDocument}
                        size="small"
                        variant="text"
                    >
                        <i className="fa fa-lg fa-times text-danger" />
                    </CDSButton>
                </div>
            </div>
        );
    }
}

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../..';

export const FormControls = (props) => {
    const navigate = useNavigate();

    const styles = require('./FormControls.scss');

    const invalidSave = props.meta.invalid && !props.isDraft;

    const handleBackClick = () => {
        const { prevRoute } = props;

        navigate(prevRoute);
    };

    const handleDoneEditingClick = () => {
        const { reviewPath } = props;

        navigate(reviewPath);
    };

    const handleNextClick = () => {
        const { nextRoute } = props;

        navigate(nextRoute);
    };

    const renderSaveButton = () => {
        const { disabled, isDraft, saveHandler, showFormValidation } = props;

        const onClick = invalidSave ? showFormValidation : saveHandler;
        return (
            <Button
                bsSize={isDraft ? 'lg' : undefined}
                bsStyle={isDraft ? undefined : 'primary'}
                className={classnames(isDraft && styles.saveBtn)}
                disabled={disabled}
                onClick={onClick}
                qaTag="formControls-save"
            >
                Save
            </Button>
        );
    };

    const renderSubmitButton = () => {
        const {
            disabled,
            meta: { valid },
            showFormValidation,
            submitButtonText,
            submitHandler,
        } = props;

        const bsStyle = valid ? 'success' : 'default';
        const bsSize = valid ? 'lg' : undefined;
        const onClick = valid ? submitHandler : showFormValidation;
        return (
            <Button
                bsSize={bsSize}
                bsStyle={bsStyle}
                className={styles.submitBtn}
                disabled={disabled}
                onClick={onClick}
                qaTag="formControls-submit"
            >
                {submitButtonText}
            </Button>
        );
    };

    const renderReviewButton = () => {
        return (
            <Button
                disabled={props.disabled}
                onClick={handleDoneEditingClick}
                qaTag="formControls-doneEditing"
            >
                Done Editing
            </Button>
        );
    };

    const renderError = () => {
        // Only render error when showing form errors and form is not valid
        if (!props.showFormErrors || props.meta.valid) return null;

        const warningText = invalidSave
            ? 'All form fields must be completed before saving.'
            : 'Please complete all form fields before submitting.';
        return (
            <div className={`${styles.errorContainer} col-xs-12`}>
                {warningText} Incomplete fields will be highlighted in red text.
            </div>
        );
    };

    const {
        disabled,
        isDraft,
        meta: { valid },
        nextRoute,
        prevRoute,
    } = props;

    const btnStyle = valid ? styles.btnContainer : '';
    const colSize = valid ? 'sm' : 'xs';

    return (
        <div className={`row ${styles.container}`}>
            <div className={`col-${colSize}-3 ${btnStyle}`}>
                {prevRoute && (
                    <Button disabled={disabled} onClick={handleBackClick} qaTag="formControls-back">
                        <i className="fa fa-lg fa-angle-left" /> Back
                    </Button>
                )}
            </div>
            <div className={`col-${colSize}-6 text-center`}>
                <div className={styles.leftButton}>
                    {isDraft ? renderSaveButton() : renderReviewButton()}
                </div>
                {isDraft ? renderSubmitButton() : renderSaveButton()}
            </div>
            <div className={`col-${colSize}-3 text-right ${btnStyle}`}>
                {nextRoute && (
                    <Button
                        bsStyle={valid ? 'default' : 'primary'}
                        disabled={disabled}
                        onClick={handleNextClick}
                        qaTag="formControls-next"
                    >
                        Next <i className="fa fa-lg fa-angle-right" />
                    </Button>
                )}
            </div>
            {renderError()}
        </div>
    );
};

FormControls.propTypes = {
    meta: PropTypes.object.isRequired,
    isDraft: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    nextRoute: PropTypes.string,
    prevRoute: PropTypes.string,
    submitButtonText: PropTypes.string.isRequired,
    reviewPath: PropTypes.string.isRequired,
    showFormErrors: PropTypes.bool.isRequired,
    saveHandler: PropTypes.func.isRequired,
    submitHandler: PropTypes.func.isRequired,
    showFormValidation: PropTypes.func.isRequired,
};

import { createSelector } from 'reselect';
import { pick, sortBy } from 'lodash';

import { fields, dateFields } from './constants';
import { getUserJS } from '../../selectors';
import { getEvaluationProjectJS } from '../selectors';
import { deserializeProjectDates } from '../../../helpers';

// This deserializer's main function is to convert dates.
// This makes it extremely easy to set initial values for redux-forms
export const getDeserializedProject = createSelector(
    [getEvaluationProjectJS, getUserJS],
    (project, user) => {
        if (!project) return null;

        // redux-forms submits all initial data passed in. Omit fields we do
        // not want to be manipulated, so they will not be part of submit data
        const cleansedProjectData = pick(project, [
            ...fields,
            'id',
            'isEvaluationOnly',
            'template',
            'status',
            'wasPosted',
        ]);

        // Convert project dates from UTC strings to the user's time as date objects
        const { timezone } = user.organization;
        const projectDates = deserializeProjectDates(cleansedProjectData, dateFields, timezone);

        // Not ideal, but there does not appear to be a way to sort on a join
        // https://github.com/sequelize/sequelize/issues/3173
        const {
            evaluation: { evaluators },
        } = cleansedProjectData;

        const sortedEvaluators = sortBy(
            evaluators,
            (evaluator) => evaluator.userEvaluators.created_at
        );

        return {
            ...cleansedProjectData,
            ...projectDates,
            evaluation: {
                ...cleansedProjectData.evaluation,
                evaluators: sortedEvaluators,
            },
        };
    }
);

export const hasQuestionnaire = createSelector([getEvaluationProjectJS], (project) => {
    if (!project) return true;

    return project.questionnaires.length > 0;
});

const { listToDict } = require('../helpers');

const fieldTypes = listToDict([
    'signature',
    'date',
    'dateSigned',
    'role',
    'name',
    'string',
    'stamp',
    'header',
]);
const requiredValueFieldTypes = [fieldTypes.ROLE, fieldTypes.NAME, fieldTypes.HEADER];
const singleUseFieldTypes = [
    fieldTypes.SIGNATURE,
    fieldTypes.ROLE,
    fieldTypes.NAME,
    fieldTypes.STAMP,
    fieldTypes.DATE_SIGNED,
];

const fieldTypesValues = {
    [fieldTypes.SIGNATURE]: {
        type: fieldTypes.SIGNATURE,
        label: 'Signature',
        value: null,
    },
    [fieldTypes.NAME]: {
        type: fieldTypes.NAME,
        label: 'Full Name',
        value: null,
    },
    [fieldTypes.ROLE]: {
        type: fieldTypes.ROLE,
        label: 'Title',
        value: null,
    },
    [fieldTypes.DATE]: {
        type: fieldTypes.DATE,
        label: 'Date',
        value: null,
    },
    [fieldTypes.DATE_SIGNED]: {
        type: fieldTypes.DATE_SIGNED,
        label: 'Date Signed',
        value: null,
    },
    [fieldTypes.STAMP]: {
        type: fieldTypes.STAMP,
        label: 'Stamp',
        value: '1in',
    },
    [fieldTypes.STRING]: {
        type: fieldTypes.STRING,
        label: 'Text',
        value: null,
    },
    [fieldTypes.HEADER]: {
        type: fieldTypes.HEADER,
        label: 'Header',
        value: null,
    },
};

module.exports = {
    fieldTypes,
    fieldTypesValues,
    requiredValueFieldTypes,
    singleUseFieldTypes,
};

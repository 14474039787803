import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import { SEARCH_PRICE_ITEMS, QUICK_SEARCH_QUERY } from './constants';
import { validate } from './validate';
import { Checkbox, InputText } from '../../../../components';

const formConfig = {
    validate,
    enableReinitialize: true,
};

const mapStateToProps = (state, props) => ({
    isSearchingLineItems: formValueSelector(props.form)(state, SEARCH_PRICE_ITEMS),
});

// @connect
// @reduxForm
class ConnectedProjectQuickSearchForm extends Component {
    static propTypes = {
        isSearchingLineItems: PropTypes.bool,
        hideLineItemSearch: PropTypes.bool,
        placeholder: PropTypes.string,
    };

    static defaultProps = {
        hideLineItemSearch: false,
    };

    get styles() {
        return require('./ProjectQuickSearchForm.scss');
    }

    render() {
        const { isSearchingLineItems, hideLineItemSearch, placeholder } = this.props;

        return (
            <div className="row row-no-gutters">
                <div className="col-sm-8">
                    <Field
                        aria-label="Search Projects"
                        component={InputText}
                        formGroupClassName={this.styles.quickSearchInput}
                        inputGroupPrefix={<i className="fa fa-search" />}
                        name={QUICK_SEARCH_QUERY}
                        overrideFeedback={!isSearchingLineItems}
                        placeholder={
                            isSearchingLineItems ? 'Search by line item description' : placeholder
                        }
                        qaTag="connectedProjectQuickSearchForm-searchBy"
                        type="text"
                    />
                </div>
                <div className="col-sm-4">
                    {!hideLineItemSearch && (
                        <Field
                            className={this.styles.searchPriceTablesCheckbox}
                            component={Checkbox}
                            formGroupClass={this.styles.checkboxFormGroup}
                            name={SEARCH_PRICE_ITEMS}
                            qaTag="connectedProjectQuickSearchForm-searchLineItems"
                            text="Search line items"
                        />
                    )}
                </div>
            </div>
        );
    }
}

export const ProjectQuickSearchForm = compose(
    connect(mapStateToProps),
    reduxForm(formConfig)
)(ConnectedProjectQuickSearchForm);

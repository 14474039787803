export const getProgressBarBsStyle = (percentComplete) => {
    if (percentComplete < 33.33) return 'warning';
    if (percentComplete < 66.67) return 'info';
    if (percentComplete < 100) return undefined;
    return 'success-dark';
};

/**
 * Takes in the current total price of an auction and the agency's original budget
 * The progress bar will reflect a 'success' (green) state if the total is under budget
 * If the total exceeds the budget, the progress bar will show a 'warning' (yellow) state
 * @param {number} currentAuctionTotal
 * @param {number} projectBudget
 * @returns {string} Returns bootstrap style of the progress bar
 */
export const getReverseAuctionProgressBarStyle = (currentAuctionTotal, projectBudget) => {
    if (currentAuctionTotal > projectBudget) return 'warning';
    return 'success-cds';
};

import React from 'react';
import { Outlet } from 'react-router-dom';

import {
    ProjectAddendums,
    QuestionAnswer,
    SelectedVendor,
    ProjectDetailNav,
    ProjectSection,
} from '../../../containers';
import * as Gov from '../../../containers/GovApp';
import BaseProposalReviewRoutes from './ProposalReview';
import { legacyFetchData, requireEvaluationSubscription } from '../../loaders';

export default (getState, dispatch) => {
    const ProposalReviewRoutes = BaseProposalReviewRoutes('Evaluation');
    const EvaluatorsProposalReviewRoutes = BaseProposalReviewRoutes('ProposalEvaluation', {
        element: Gov.ProposalEvaluationNav.Evaluator,
        path: ':proposalId',
        skipEditingRoutes: true,
    });
    const ProposalEvaluationsReviewRoutes = BaseProposalReviewRoutes('ProposalEvaluation', {
        element: Gov.ProposalEvaluationNav.User,
        path: ':proposalId',
        skipEditingRoutes: true,
    });
    const ConsensusScorecardReviewRoutes = BaseProposalReviewRoutes('ProposalEvaluation', {
        element: Gov.ProposalEvaluationNav.Consensus,
        path: ':proposalId',
        skipEditingRoutes: true,
    });

    const baseProposalCompareRoute = {
        element: <Gov.ProposalEvaluationsCompare />,
        loader: legacyFetchData(getState, dispatch, Gov.ProposalEvaluationsCompare),
    };

    return [
        {
            element: <Outlet />,
            loader: requireEvaluationSubscription(getState),
            path: 'evaluation',
            children: [
                {
                    element: <Gov.EvaluationNav />,
                    children: [
                        {
                            element: <Gov.EvaluationOverview />,
                            index: true,
                        },
                        {
                            element: <Gov.EvaluationOverview />,
                            children: [
                                {
                                    element: <ProjectAddendums.GovernmentPublicView />,
                                    loader: legacyFetchData(
                                        getState,
                                        dispatch,
                                        ProjectAddendums.GovernmentPublicView
                                    ),
                                    path: 'addenda',
                                },
                                {
                                    element: <QuestionAnswer.GovernmentPublicView />,
                                    loader: legacyFetchData(
                                        getState,
                                        dispatch,
                                        QuestionAnswer.GovernmentPublicView
                                    ),
                                    path: 'q-and-a',
                                },
                            ],
                        },
                        {
                            element: <ProjectDetailNav.Government />,
                            path: 'document',
                            children: [
                                {
                                    element: <ProjectSection.Government />,
                                    index: true,
                                },
                            ],
                        },
                        {
                            element: <Gov.ProposalsList.Evaluation />,
                            loader: legacyFetchData(
                                getState,
                                dispatch,
                                Gov.ProposalsList.Evaluation
                            ),
                            path: 'proposals',
                        },
                        {
                            element: <Gov.ProposalPricingCompare />,
                            loader: legacyFetchData(getState, dispatch, Gov.ProposalPricingCompare),
                            path: 'proposals/pricing',
                        },
                        {
                            ...baseProposalCompareRoute,
                            path: 'proposals/compare',
                        },
                        // /governments/:governmentId/projects/:projectId/evaluation/proposals/:proposalId/*
                        ...ProposalReviewRoutes(getState, dispatch),
                        {
                            element: <Gov.BidTabulations />,
                            loader: legacyFetchData(getState, dispatch, Gov.BidTabulations),
                            path: 'bid-tabulations',
                        },
                        {
                            element: <Gov.LineItemAward />,
                            loader: legacyFetchData(getState, dispatch, Gov.LineItemAward),
                            path: 'line-item-award',
                        },
                        {
                            element: <Gov.VendorAwards />,
                            loader: legacyFetchData(getState, dispatch, Gov.VendorAwards),
                            path: 'line-item-award/vendors',
                        },
                        {
                            path: 'evaluators',
                            children: [
                                {
                                    element: <Gov.EvaluatorsAdmin />,
                                    loader: legacyFetchData(
                                        getState,
                                        dispatch,
                                        Gov.EvaluatorsAdmin
                                    ),
                                    index: true,
                                },
                                {
                                    path: ':evaluatorId/proposal-evaluations',
                                    children: [
                                        {
                                            element: <Gov.ProposalEvaluationsList.Evaluator />,
                                            loader: legacyFetchData(
                                                getState,
                                                dispatch,
                                                Gov.ProposalEvaluationsList.Evaluator
                                            ),
                                            index: true,
                                        },
                                        {
                                            ...baseProposalCompareRoute,
                                            path: 'compare',
                                        },
                                        // /governments/:governmentId/evaluation/evaluators/:evaluatorId/proposal-evaluations/:proposalId/*
                                        ...EvaluatorsProposalReviewRoutes(getState, dispatch),
                                    ],
                                },
                            ],
                        },
                        {
                            element: <Gov.AdminScorecard />,
                            path: 'admin-scorecard',
                        },
                        {
                            path: 'proposal-evaluations',
                            children: [
                                {
                                    element: <Gov.ProposalEvaluationsList.User />,
                                    loader: legacyFetchData(
                                        getState,
                                        dispatch,
                                        Gov.ProposalEvaluationsList.User
                                    ),
                                    index: true,
                                },
                                {
                                    ...baseProposalCompareRoute,
                                    path: 'compare',
                                },
                                // /governments/:governmentId/evaluation/proposal-evaluations/:proposalId/*
                                ...ProposalEvaluationsReviewRoutes(getState, dispatch),
                            ],
                        },
                        {
                            path: 'consensus-scorecard',
                            children: [
                                {
                                    element: <Gov.ProposalEvaluationsList.Consensus />,
                                    loader: legacyFetchData(
                                        getState,
                                        dispatch,
                                        Gov.ProposalEvaluationsList.Consensus
                                    ),
                                    index: true,
                                },
                                {
                                    ...baseProposalCompareRoute,
                                    path: 'compare',
                                },
                                // /governments/:governmentId/evaluation/consensus-scorecard/:proposalId/*
                                ...ConsensusScorecardReviewRoutes(getState, dispatch),
                            ],
                        },
                        {
                            element: <Gov.AggregateEvaluation />,
                            loader: legacyFetchData(getState, dispatch, Gov.AggregateEvaluation),
                            path: 'aggregate-evaluations',
                        },
                        {
                            element: <Gov.EvaluatorEvaluations />,
                            loader: legacyFetchData(getState, dispatch, Gov.EvaluatorEvaluations),
                            path: 'aggregate-evaluations/evaluators/:evaluatorId',
                        },
                        {
                            element: <Gov.VendorEvaluations />,
                            loader: legacyFetchData(getState, dispatch, Gov.VendorEvaluations),
                            path: 'aggregate-evaluations/proposals/:proposalId',
                        },
                        {
                            element: <SelectedVendor.Government />,
                            path: 'selected-proposal',
                        },
                    ],
                },
            ],
        },
    ];
};

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { sectionTypeNames } from '@og-pro/shared-config/sections';

import { Footer, Header, TitleNumberInputs } from './components';
import { getTitle } from '../../helpers';

export const SectionHeaderTitleNumberForm = ({
    defaultSection,
    disabled,
    invalid = false,
    isAdding = false,
    member,
    onCancel,
    onDelete,
    onSave,
    parentDividerOptions = [],
    saveButtonLabel = null,
    showValidation,
    showActionsOnFooter,
    showShortnameInput,
    useManualNumbering,
}) => {
    const styles = require('../shared.scss');

    return (
        <div className={styles.sectionHeader}>
            <Header
                invalid={invalid}
                onCancel={onCancel}
                onDelete={onDelete}
                onSave={onSave}
                showButtons={!showActionsOnFooter}
                title={getTitle({ isAdding, sectionType: defaultSection.section_type })}
            />
            <div className={classNames({ [styles.marginBottom]: showActionsOnFooter })}>
                <TitleNumberInputs
                    {...{
                        disabled,
                        member,
                        parentDividerOptions,
                        showValidation,
                        showShortnameInput,
                        useManualNumbering,
                    }}
                    type={
                        defaultSection.section_type === sectionTypeNames.DIVIDER
                            ? 'Divider'
                            : 'Section'
                    }
                />
            </div>
            {showActionsOnFooter && (
                <Footer
                    buttonLabel={saveButtonLabel || (isAdding ? 'Add' : 'Save')}
                    invalid={invalid}
                    onCancel={onCancel}
                    onDelete={onDelete}
                    onSave={onSave}
                />
            )}
        </div>
    );
};

SectionHeaderTitleNumberForm.propTypes = {
    defaultSection: PropTypes.shape({
        isGeneral: PropTypes.bool.isRequired,
        isWritingFormEditable: PropTypes.bool,
        section_type: PropTypes.oneOf(Object.values(sectionTypeNames)),
    }).isRequired,
    invalid: PropTypes.bool,
    isAdding: PropTypes.bool,
    disabled: PropTypes.bool,
    member: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onSave: PropTypes.func.isRequired,
    parentDividerOptions: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.number,
            label: PropTypes.string,
        })
    ),
    saveButtonLabel: PropTypes.string,
    showActionsOnFooter: PropTypes.bool,
    showShortnameInput: PropTypes.bool,
    showValidation: PropTypes.bool,
    useManualNumbering: PropTypes.bool,
};

import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { fieldNames, form, orderedListOptions } from './constants';
import {
    selectOutlineNumberingFormValues,
    selectUpdateOutlineNumberingError,
    selectUpdatingOutlineNumbering,
} from './selectors';
import { validate } from './validate';
import { Button, SearchSelect, Well } from '../../../../components';

const { FIRST_LEVEL, SECOND_LEVEL, THIRD_LEVEL, FOURTH_LEVEL, FIFTH_LEVEL } = fieldNames;

const formConfig = {
    enableReinitialize: true,
    form,
    validate,
};

const ConnectedOutlineNumberForm = ({
    handleSubmit,
    outlineNumberingId,
    updateOutlineNumbering,
}) => {
    const styles = require('./index.scss');
    const formValues = useSelector(selectOutlineNumberingFormValues);
    const updateError = useSelector(selectUpdateOutlineNumberingError);
    const updating = useSelector(selectUpdatingOutlineNumbering);

    const renderExampleWell = () => {
        const { firstLevel, secondLevel, thirdLevel, fourthLevel, fifthLevel } = formValues || {};

        return (
            <Well>
                <label>Current outline styles:</label>
                <ol style={firstLevel && { listStyle: firstLevel }}>
                    <li>First Level</li>
                    <li style={{ listStyle: 'none' }}>
                        <ol style={secondLevel && { listStyle: secondLevel }}>
                            <li>Second Level</li>
                            <li style={{ listStyle: 'none' }}>
                                <ol style={thirdLevel && { listStyle: thirdLevel }}>
                                    <li>Third Level</li>
                                    <li style={{ listStyle: 'none' }}>
                                        <ol style={fourthLevel && { listStyle: fourthLevel }}>
                                            <li>Fourth Level</li>
                                            <li style={{ listStyle: 'none' }}>
                                                <ol style={fifthLevel && { listStyle: fifthLevel }}>
                                                    <li>Fifth Level</li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                </ol>
            </Well>
        );
    };

    const onSubmit = (data) => {
        updateOutlineNumbering(outlineNumberingId, data);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <h4 className={styles.title}>Outline Numbering</h4>
            <div className={`text-muted ${styles.helpText}`}>
                Configure the numbering style used for all items in ordered lists.
            </div>
            <div className="row">
                <div className="col-xs-12 col-sm-6">
                    <Field
                        component={SearchSelect}
                        disabled={updating}
                        hasFeedback={false}
                        label="First Level"
                        name={FIRST_LEVEL}
                        options={orderedListOptions}
                    />
                    <Field
                        component={SearchSelect}
                        disabled={updating}
                        hasFeedback={false}
                        label="Second Level"
                        name={SECOND_LEVEL}
                        options={orderedListOptions}
                    />
                    <Field
                        component={SearchSelect}
                        disabled={updating}
                        hasFeedback={false}
                        label="Third Level"
                        name={THIRD_LEVEL}
                        options={orderedListOptions}
                    />
                    <Field
                        component={SearchSelect}
                        disabled={updating}
                        hasFeedback={false}
                        label="Fourth Level"
                        name={FOURTH_LEVEL}
                        options={orderedListOptions}
                    />
                    <Field
                        component={SearchSelect}
                        disabled={updating}
                        label="Fifth Level"
                        name={FIFTH_LEVEL}
                        options={orderedListOptions}
                    />
                    <div className="text-right">
                        <Button bsStyle="primary" disabled={updating} type="submit">
                            Update
                        </Button>
                        {updateError && <div className="error-block">{updateError}</div>}
                    </div>
                </div>
                <div className="col-xs-12 col-sm-6">{renderExampleWell()}</div>
            </div>
        </form>
    );
};

ConnectedOutlineNumberForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    outlineNumberingId: PropTypes.number.isRequired,
    updateOutlineNumbering: PropTypes.func.isRequired,
};

export const OutlineNumberForm = reduxForm(formConfig)(ConnectedOutlineNumberForm);

import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { getRequisitionBudgetCheckResults } from '../../../selectors/govApp/requisitions';

export const BudgetCheckInfoBanner = () => {
    const styles = require('./index.scss');
    const { changedAccounts } = useSelector(getRequisitionBudgetCheckResults);
    if (!changedAccounts?.length) {
        return null;
    }
    return (
        <div className={styles.infoMessageContainer}>
            <i className={classNames('fa fa-info fa-2x', styles.icon, styles.infoTitle)} />
            <div>
                <div className={styles.infoTitleContainer}>
                    <span className={styles.infoTitle}>Something Has Changed</span>
                </div>
                <span className={styles.infoDescription}>
                    Since last budget check, the available amount for the following account(s) has
                    changed:
                </span>
                <ul>
                    {changedAccounts?.map(({ accountNumber }) => (
                        <li>
                            View details for account <strong>#{accountNumber}</strong>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

import classnames from 'classnames';
import { startCase } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { getTemplatesWithWorkloads } from './selectors';
import { getWorkloadWeightStatuses } from '../../GovApp/selectors';
import { WorkloadWeightsModal } from './WorkloadWeightsModal';
import connectData from '../../ConnectData';
import { loadTemplatesWithWorkloadWeights } from '../../../actions/admin';
import {
    Button,
    DataTable,
    LoadingSpinner,
    LoadingError,
    SectionTitle,
    StatusLabel,
    ZeroState,
    Main,
} from '../../../components';

const fetchData = (getState, dispatch) => {
    return dispatch(loadTemplatesWithWorkloadWeights());
};

const mapStateToProps = (state) => {
    return {
        loadError: state.admin.get('loadWorkloadWeightsError'),
        loading: state.admin.get('loadingWorkloadWeights'),
        statuses: getWorkloadWeightStatuses(state),
        templates: getTemplatesWithWorkloads(state),
    };
};

// @connect
class ConnectedWorkloadWeights extends Component {
    static propTypes = {
        loadError: PropTypes.string,
        loading: PropTypes.bool,
        statuses: PropTypes.arrayOf(PropTypes.string).isRequired,
        templates: PropTypes.arrayOf(
            PropTypes.shape({
                workloadWeight: PropTypes.object.isRequired,
            })
        ).isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            showWorkloadWeightsModal: false,
            workloadWeight: null,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    hideWorkloadWeightsModal = () => this.setState({ showWorkloadWeightsModal: false });

    showWorkloadWeightsModal = (workloadWeight) => () => {
        this.setState({
            showWorkloadWeightsModal: true,
            workloadWeight,
        });
    };

    formatDataForCSVExport = (data) => {
        const { statuses } = this.props;

        const headers = ['Template', ...statuses.map((status) => startCase(status))];

        const rows = data.map((dataRow) => {
            return [
                dataRow.title || 'Untitled',
                ...statuses.map((status) => dataRow[`workloadWeight.${status}`]),
            ];
        });

        return [headers].concat(rows);
    };

    renderTemplates() {
        const { templates, statuses } = this.props;

        if (templates.length === 0) {
            return (
                <ZeroState
                    info="Once a template has been created you can assign workload weights to it here"
                    title="No templates created yet"
                />
            );
        }

        const columns = [
            {
                Header: 'Template',
                accessor: 'title',
                Cell: (props) => (
                    <>
                        <Button
                            bsSize="sm"
                            bsStyle="link"
                            className={this.styles.editButton}
                            onClick={this.showWorkloadWeightsModal(props.original.workloadWeight)}
                            zeroPadding
                        >
                            <i className="fa fa-pencil" /> Edit
                        </Button>
                        {props.value || 'Untitled'}
                        {!props.original.isPublished && (
                            <>
                                &nbsp;
                                <StatusLabel>Draft</StatusLabel>
                            </>
                        )}
                    </>
                ),
            },
            {
                Header: 'Type',
                accessor: 'projectType',
                className: 'text-center',
                maxWidth: 150,
                // eslint-disable-next-line react/prop-types
                Cell: ({ original: { projectType } }) => {
                    return (
                        <StatusLabel
                            className={classnames([
                                this.styles.projectTypeLabel,
                                this.styles[projectType],
                            ])}
                        >
                            {projectType}
                        </StatusLabel>
                    );
                },
            },
            ...statuses.map((status) => {
                return {
                    Header: startCase(status),
                    accessor: `workloadWeight.${status}`,
                    className: 'text-center',
                    maxWidth: 110,
                };
            }),
        ];

        return (
            <DataTable
                className={classnames('-striped', '-highlight')}
                columns={columns}
                csvExportOptions={{
                    fileName: 'Workload Weights',
                    getFormattedCSVData: this.formatDataForCSVExport,
                    headers: true,
                }}
                data={templates}
                defaultPageSize={templates.length}
                getTrProps={() => {
                    return {
                        className: this.styles.templateRow,
                    };
                }}
                showCSVExport
                showPagination={false}
            />
        );
    }

    render() {
        const { loadError, loading, statuses } = this.props;

        const { showWorkloadWeightsModal, workloadWeight } = this.state;

        if (loading) {
            return <LoadingSpinner />;
        }

        if (loadError) {
            return <LoadingError error={loadError} />;
        }

        return (
            <Main>
                <SectionTitle
                    info="Assign weights to the various stages of your templates to help estimate your staff's workload"
                    title="Workload Weights"
                />
                {this.renderTemplates()}
                {showWorkloadWeightsModal && (
                    <WorkloadWeightsModal
                        hideModal={this.hideWorkloadWeightsModal}
                        statuses={statuses.filter((status) => workloadWeight[status] !== undefined)}
                        workloadWeight={workloadWeight}
                    />
                )}
            </Main>
        );
    }
}

export const WorkloadWeights = compose(
    connectData(fetchData),
    connect(mapStateToProps)
)(ConnectedWorkloadWeights);

export const validate = (values) => {
    const errors = {};

    const { proposalIds } = values;
    if (!proposalIds || (Array.isArray(proposalIds) && proposalIds.length === 0)) {
        errors.proposalIds = 'Please select a vendor';
    }

    return errors;
};

import { buildQaTag, componentNames } from '@og-pro/ui';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'react-bootstrap';
import { Field } from 'redux-form';

import { InputText } from '../InputText';

export class RadioItemWithTextInput extends PureComponent {
    static propTypes = {
        value: PropTypes.string.isRequired,
        children: PropTypes.node.isRequired,
        groupLabel: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        normalizeOption: PropTypes.func,
        onChange: PropTypes.func.isRequired,
        qaTag: PropTypes.string.isRequired, // Format: <pageName>-<simpleDescription>
        textFieldDisabled: PropTypes.bool,
    };

    onChange = () => {
        const { onChange, value } = this.props;
        return onChange(value);
    };

    render() {
        const {
            value,
            children,
            groupLabel,
            name,
            normalizeOption,
            onChange,
            qaTag,
            textFieldDisabled,
            ...props
        } = this.props;
        const styles = require('./RadioGroup.scss');

        const tagWithComponentName = buildQaTag(qaTag, componentNames.RADIO_ITEM);

        return (
            <Radio
                aria-label={`${name.toLowerCase()} button`}
                className={styles.radio}
                onChange={this.onChange}
                value={value}
                {...props}
            >
                <Field
                    aria-label={name.toLowerCase()}
                    component={InputText}
                    data-qa={tagWithComponentName}
                    disabled={textFieldDisabled}
                    formGroupClassName={styles.textField}
                    name={`${groupLabel}.${name.toLowerCase()}`}
                    normalize={normalizeOption}
                    qaTag={`radioItem-${name.toLowerCase()}`}
                />
                {children}
            </Radio>
        );
    }
}

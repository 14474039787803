import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { useSearchParams } from 'react-router-dom';

import { form, TEMPLATE_TYPE } from './constants';
import { Label, SearchSelect } from '../../../../../components';

const formConfig = {
    form,
};

const ConnectedForm = ({ options }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const styles = require('./index.scss');

    return (
        <>
            <Label className={styles.projectTypeSelectLabel} htmlFor="searchSelect" label="Type" />
            <Field
                backspaceRemovesValue={false}
                component={SearchSelect}
                formClassName={styles.projectTypeSelect}
                id="ProjectTypeSelect"
                name={TEMPLATE_TYPE}
                onChange={(type) => {
                    searchParams.set('type', type);
                    setSearchParams(searchParams);
                }}
                options={options}
                overrideFeedback
            />
        </>
    );
};

ConnectedForm.propTypes = {
    options: PropTypes.array.isRequired,
};

export const Form = reduxForm(formConfig)(ConnectedForm);

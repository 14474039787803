import PropTypes from 'prop-types';
import { round } from 'lodash';
import React, { PureComponent } from 'react';

import { ProgressBar, Tooltip } from '../../../../../components';

export class VendorStat extends PureComponent {
    static propTypes = {
        bsStyle: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        maxInteractions: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        statistic: PropTypes.number.isRequired,
        tooltip: PropTypes.string.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const { bsStyle, icon, maxInteractions, name, statistic, tooltip } = this.props;
        const hasProgressBar = maxInteractions > 0;

        return (
            <div>
                <Tooltip delayShow={200} tooltip={tooltip} tooltipId={name}>
                    <div className={`row ${this.styles.container} text-${bsStyle}`}>
                        <div className="col-xs-8">
                            <span>
                                <i className={`fa fa-fw fa-${icon}`} />
                                &nbsp;&nbsp;{name}
                            </span>
                        </div>
                        <div className={`col-xs-4 ${this.styles.stat}`}>
                            <span>{statistic}</span>
                        </div>
                    </div>
                </Tooltip>
                {hasProgressBar && (
                    <div className="row hidden-print">
                        <div className="col-xs-12">
                            <ProgressBar
                                bsStyle={bsStyle}
                                className={this.styles.progressBar}
                                max={maxInteractions}
                                now={statistic}
                            />
                            <div className={`text-muted ${this.styles.progressPercent}`}>
                                <small>{round((statistic / maxInteractions) * 100, 1)}%</small>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

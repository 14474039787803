import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
    getWritingPath,
    getReviewPath,
    isBuilderDraftPage,
    getSDv2WritingSections,
} from '../../../selectors';
import { DraftReviewToggleV2 } from '../../../../../components';
import { trackEvent } from '../../../../../helpers';
import { ProjectCreateV2NavContext } from '../context';

export const ProjectModeToggleSDv2 = ({ isIntake, location, onClick, params }) => {
    const navigate = useNavigate();
    const sections = useSelector(getSDv2WritingSections);
    const writingPath = useSelector((state) =>
        getWritingPath(state, { location, params, isIntake })
    );
    const reviewPath = useSelector((state) => getReviewPath(state, { location, params, isIntake }));
    const checked = useSelector((state) =>
        isBuilderDraftPage(state, { location, params, isIntake })
    );
    const { activeSectionId } = useContext(ProjectCreateV2NavContext);

    const toggleHandler = (e) => {
        const switchToDraftMode = e.target.checked;

        const currentRoute = switchToDraftMode ? writingPath : reviewPath;
        let nextRoute = switchToDraftMode ? reviewPath : writingPath;

        trackEvent('Project mode toggle', { switchToDraftMode, currentRoute, nextRoute });

        const section = sections[activeSectionId];

        if (activeSectionId && section && location.pathname.endsWith('document-editor')) {
            nextRoute += `/document?section=${section.id}`;
        }

        if (onClick) {
            return onClick(nextRoute);
        }

        return navigate(nextRoute);
    };

    return (
        <div>
            <DraftReviewToggleV2 checked={!checked} onChange={toggleHandler} />
        </div>
    );
};

ProjectModeToggleSDv2.propTypes = {
    isIntake: PropTypes.bool,
    location: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    params: PropTypes.shape({
        governmentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }).isRequired,
};

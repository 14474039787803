import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';

import { fieldNames, form } from './constants';
import { validate } from './validate';
import { InputText } from '../../../components';

const { IS_PAUSED_REASON } = fieldNames;

const formConfig = {
    form,
    validate,
    initialValues: {
        [IS_PAUSED_REASON]: '',
    },
};

class ConnectedProjectHoldForm extends Component {
    static propTypes = {
        disabled: PropTypes.bool,
        children: PropTypes.node,
        handleSubmit: PropTypes.func.isRequired,
    };

    render() {
        const { disabled, children, handleSubmit } = this.props;

        const styles = require('./index.scss');

        return (
            <div>
                {children}
                <form className={styles.container} onSubmit={handleSubmit}>
                    <Field
                        autoComplete="off"
                        component={InputText}
                        disabled={disabled}
                        hasFeedback={false}
                        label="Reason for placing the project on hold (optional)"
                        name={IS_PAUSED_REASON}
                        type="text"
                    />
                </form>
            </div>
        );
    }
}

export const ProjectHoldForm = reduxForm(formConfig)(ConnectedProjectHoldForm);

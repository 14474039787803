import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { reduxForm, Field } from 'redux-form';

import { form, fieldNames } from './constants';
import { Button, RichTextInput } from '../../../../../../components';

const { NOTE } = fieldNames;

const formConfig = {
    form,
};

// @reduxForm
class ConnectedEvaluationNotesForm extends PureComponent {
    static propTypes = {
        canEvaluate: PropTypes.bool.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        hideModal: PropTypes.func.isRequired,
        updateError: PropTypes.string,
        updating: PropTypes.bool,
    };

    render() {
        const { canEvaluate, handleSubmit, hideModal, updateError, updating } = this.props;

        const disabled = updating || !canEvaluate;

        const styles = require('./EvaluationNotesForm.scss');
        return (
            <form onSubmit={handleSubmit}>
                <Field
                    component={RichTextInput}
                    disabled={disabled}
                    hasFeedback={false}
                    label="Notes"
                    minRows={4}
                    name={NOTE}
                    placeholder="Enter notes"
                    type="textarea"
                />
                {canEvaluate && (
                    <div className={styles.buttonGroup}>
                        <Button bsSize="sm" disabled={disabled} onClick={hideModal}>
                            Cancel
                        </Button>
                        <Button
                            bsStyle="primary"
                            className={styles.submitBtn}
                            disabled={disabled}
                            type="submit"
                        >
                            Save
                        </Button>
                    </div>
                )}
                {updateError && <div className={styles.errorContainer}>{updateError}</div>}
            </form>
        );
    }
}

export const EvaluationNotesForm = reduxForm(formConfig)(ConnectedEvaluationNotesForm);

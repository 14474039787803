import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Tooltip } from '../../../../../../components';

const PERMISSION_REQUIRED_TOOLTIP =
    'You have not received permission to access this report. ' +
    'Please contact a project editor and ask them to grant you access.';

export class ReportButton extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        disabledText: PropTypes.string,
        hasPermission: PropTypes.bool,
        hasPermissionText: PropTypes.string,
        icon: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        onClick: PropTypes.func,
        type: PropTypes.string.isRequired,
    };

    get disabled() {
        const { disabled, hasPermission } = this.props;

        return disabled || !hasPermission;
    }

    get styles() {
        return require('./index.scss');
    }

    clickHandler = () => {
        const { onClick, type } = this.props;

        if (!this.disabled) {
            return onClick(type);
        }
    };

    renderButton() {
        const { icon, name } = this.props;

        const disabledStyle = this.disabled ? this.styles.disabled : '';

        return (
            <div
                className={`${this.styles.reportButtonContainer} ${disabledStyle} text-primary`}
                onClick={this.clickHandler}
            >
                <div className={this.styles.reportButton}>
                    <i className={`fa ${icon} fa-2x fa-fw`} />
                </div>
                <div>
                    {name.split('<br>').map((line, idx) => (
                        <div key={idx}>{line}</div>
                    ))}
                </div>
            </div>
        );
    }

    render() {
        if (this.disabled) {
            const { disabledText, hasPermission, hasPermissionText } = this.props;

            let tooltip;
            if (hasPermission) {
                tooltip = disabledText;
            } else if (hasPermissionText) {
                tooltip = hasPermissionText;
            } else {
                tooltip = PERMISSION_REQUIRED_TOOLTIP;
            }

            return (
                <Tooltip placement="bottom" tooltip={tooltip}>
                    {this.renderButton()}
                </Tooltip>
            );
        }

        return this.renderButton();
    }
}

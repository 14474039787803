import React from 'react';
import PropTypes from 'prop-types';
import { Box, DropdownMenu, IconButton, MenuItem, Typography } from '@og-pro/ui';
import { Close as CloseIcon, InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material';

const subtitle = (project) => {
    if (project.isPrivate) {
        return 'This solicitation is private and will not notify subscribed vendors.';
    }

    if (project.wasPosted) {
        return "Email notifications will be sent to the vendors in this list. Adding by Location will expand your project invitation to vendors inside OpenGov's network who are not subscribed to you.";
    }

    return "Email notifications will be sent when the project moves to Open status. Adding by Location will expand your project invitation to vendors inside OpenGov's network who are not subscribed to you.";
};

export const SuppliersNetworkModalHeader = ({ onClose, project }) => {
    const styles = require('../index.scss');

    const slicedCategories = project.categories.slice(0, 3);

    return (
        <Box className={styles.header} p={2}>
            <Box display="flex" pb={2} px={1}>
                <Box flex={3} pr={3}>
                    <Typography id="vendors-to-be-invited-heading" variant="h2">
                        {project.wasPosted ? 'Invite Vendors' : 'Vendors to be Invited'}
                    </Typography>
                    <Typography color="grey.700">{subtitle(project)}</Typography>
                </Box>

                <Box textAlign="right">
                    <IconButton
                        aria-label="Close dialog"
                        color="secondary"
                        onClick={onClose}
                        qaTag="dialog-close"
                        size="small"
                        sx={{ padding: 0 }}
                        variant="text"
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </Box>
            </Box>
            <Box display="flex" px={1}>
                <Box flex={1}>
                    <Typography color="grey.700" component="div">
                        <Box component="span" fontWeight={600}>
                            Invitations for:
                        </Box>
                        &nbsp;
                        {project.title || 'Untitled project'}
                    </Typography>
                </Box>
                <Box flex={1} textAlign="right">
                    <DropdownMenu
                        className={styles.categoriesButton}
                        endIcon={null}
                        label={
                            <Box display="flex" flex={3} justifyContent="flex-end">
                                <Box component="span" mr={0.5}>
                                    <InfoOutlinedIcon fontSize="small" />
                                </Box>
                                <Box component="span" mr={0.5}>
                                    Category Codes:
                                </Box>
                                {slicedCategories.map((category, i) => (
                                    <Box component="span" key={category.id} mr={0.2}>
                                        {category.code}
                                        {i < slicedCategories.length - 1 ? ', ' : ''}
                                    </Box>
                                ))}
                                {project.categories.length > 3 && (
                                    <Box
                                        className={styles.categoriesMoreText}
                                        component="span"
                                        ml={0.5}
                                    >
                                        + {project.categories.length - 3} more
                                    </Box>
                                )}
                            </Box>
                        }
                        menuProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'right',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                        }}
                        pullRight
                        qaTag="supplierNetworkModal-categoriesDropdown"
                        variant="text"
                    >
                        <MenuItem className={styles.categoriesDropdownInfo} disabled>
                            <InfoOutlinedIcon fontSize="small" />
                            <Box component="span" ml={1}>
                                Category Codes cannot be edited here.
                            </Box>
                        </MenuItem>
                        {project.categories.map((category) => (
                            <MenuItem
                                disabled
                                key={category.id}
                                qaTag={`supplierNetworkModal-category${category.id}`}
                            >
                                <Box>
                                    {category.code} - {category.title}
                                </Box>
                            </MenuItem>
                        ))}
                    </DropdownMenu>
                </Box>
            </Box>
        </Box>
    );
};

SuppliersNetworkModalHeader.propTypes = {
    onClose: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
};

import React from 'react';
import { Panel } from 'react-bootstrap';

import { loadPublicAwardedContracts } from '../../../actions/vendProposals';
import { SectionTitle } from '../../../components';
import { AwardedContractsBody } from './AwardedContractsBody';

export const loader = (dispatch) => () => {
    return dispatch(loadPublicAwardedContracts());
};

export const AwardedContracts = () => {
    return (
        <Panel.Body id="skip">
            <SectionTitle title="Awarded Contracts" />
            <AwardedContractsBody />
        </Panel.Body>
    );
};

import classNames from 'classnames';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ControlLabel } from 'react-bootstrap';

import { HelpIcon } from './HelpIcon';
import { CommentIcon } from './CommentIcon';

export class Label extends PureComponent {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
        commentIcon: PropTypes.bool,
        commentData: PropTypes.object,
        helpIcon: PropTypes.bool,
        helpIconClick: PropTypes.func,
        helpIconNode: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        htmlFor: PropTypes.string,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        stylesForIcon: PropTypes.object,
        tooltipPlacement: PropTypes.string,
        useOpenGovStyle: PropTypes.bool,
        useOpenGovStyleForHelpIcon: PropTypes.bool,
    };

    get styles() {
        return require('./Label.scss');
    }

    render() {
        const {
            label,
            helpIcon,
            helpIconNode,
            helpIconClick,
            htmlFor,
            children,
            commentIcon,
            commentData,
            className,
            stylesForIcon,
            tooltipPlacement,
            useOpenGovStyle,
            useOpenGovStyleForHelpIcon,
        } = this.props;

        if (!label && !children) return null;

        return (
            <ControlLabel
                className={classNames(useOpenGovStyle && this.styles.ogLabel, className)}
                htmlFor={htmlFor}
                id={label}
            >
                {label || children}
                {helpIcon && (
                    <HelpIcon
                        onClick={helpIconClick}
                        placement={tooltipPlacement}
                        style={stylesForIcon}
                        tooltip={helpIconNode}
                        useOpenGovStyle={useOpenGovStyleForHelpIcon}
                    />
                )}
                {commentIcon && <CommentIcon {...commentData} />}
            </ControlLabel>
        );
    }
}

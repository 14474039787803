import { createSelector } from 'reselect';

const criteriaNeedsReviewData = (state) => state.templatesAdmin.get('criteriaNeedsReviewData');

export const getModalData = createSelector([criteriaNeedsReviewData], (rawModalData) => {
    if (rawModalData) {
        const modalData = rawModalData.toJS();

        const { criteria } = modalData;

        const parsedCriteria = {
            ...criteria,
            instructionData: criteria.instructionData && JSON.parse(criteria.instructionData),
            isReviewed: criteria.isReviewed || false,
        };

        return {
            ...modalData,
            criteria: parsedCriteria,
        };
    }
});

import { createSelector } from 'reselect';

export const getIsLoading = (state) => state.requisitionAccountDetails.get('loadingAccountData');
export const getLoadError = (state) => state.requisitionAccountDetails.get('loadAccountDataError');

const getRawAccountSplitPriceItems = (state) =>
    state.requisitionAccountDetails.get('accountSplitPriceItems');

export const getAccountSplitPriceItemsJS = createSelector(
    [getRawAccountSplitPriceItems],
    (rawAccountSplitPriceItems) => {
        if (!rawAccountSplitPriceItems) {
            return null;
        }

        const accountSplitPriceItems = rawAccountSplitPriceItems.toJS();

        if (!accountSplitPriceItems) {
            return null;
        }

        return accountSplitPriceItems;
    }
);

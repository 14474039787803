import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@og-pro/ui';

export const TemplateListItemProjects = ({ projects, templatesAdminPath }) => {
    const navigate = useNavigate();
    return projects.map((project) => (
        <Box key={project.id} mb={0.5}>
            <a
                href="#"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    const url = `${templatesAdminPath}/${project.id}`;

                    navigate(url);
                }}
            >
                {project.template.title || 'Untitled'}
            </a>
        </Box>
    ));
};

TemplateListItemProjects.propTypes = {
    projects: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            template: PropTypes.shape({
                title: PropTypes.string,
            }).isRequired,
        })
    ).isRequired,
    templatesAdminPath: PropTypes.string.isRequired,
};

import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { getSDv2WritingSections } from '../selectors';
import { projectSectionsToNavSections } from '../../../../components/SDv2/helpers';
import { ProjectCreateV2NavContext, ProjectCreateV2FunctionsContext } from '../V2/context';
import { DividerSectionLanding } from '../../../../components/SDv2';

export const Dividers = () => {
    const projectSections = useSelector(getSDv2WritingSections);
    const { activeSectionId, setActiveSectionId } = useContext(ProjectCreateV2NavContext);
    const {
        project: { useManualNumbering },
    } = useContext(ProjectCreateV2FunctionsContext);

    const sections = projectSectionsToNavSections({
        projectSections,
        active: activeSectionId,
        setActive: setActiveSectionId,
        useManualNumbering,
        formErrors: {},
        showFormValidation: false,
    });

    const section = (sections || []).find((s) => s.index === activeSectionId);

    return <DividerSectionLanding section={section} setActiveSectionId={setActiveSectionId} />;
};

import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { useMediaQuery, useTheme } from '@mui/material';
import { Box, Typography } from '@og-pro/ui';

import {
    certificationIds,
    certificationsOptions,
    ethnicityOptions,
} from '@og-pro/shared-config/certifications';

import { certificationFieldNames } from '../../../../../../constants';
import { DateTimePicker, InputText, SearchSelect } from '../../../../../../../../../components';
import { getVendorCertificationsJS } from '../../../../../../selectors';

const {
    CERTIFICATE_NUMBER,
    CERTIFICATION_ID,
    CERTIFYING_AGENCY,
    ETHNICITY,
    EXPIRATION_DATE,
    ISSUED_DATE,
    VENDOR_CERTIFICATION,
} = certificationFieldNames;

export const CertificationFormFields = ({ certificationId, change, editing, ethnicity }) => {
    const dispatch = useDispatch();
    const styles = require('../../../index.scss');

    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
    const vendorCertifications = useSelector(getVendorCertificationsJS);

    const selectedCertification = vendorCertifications.find(
        (certification) => certification.id === certificationId
    );

    const isEthnicityRequired = () =>
        certificationId === certificationIds.MWBE ||
        certificationId === certificationIds.COMBINATION_BUSINESS ||
        certificationId === certificationIds.MBE;

    if (!isEthnicityRequired() && ethnicity) {
        dispatch(change(`vendorCertification.${ETHNICITY}`, null));
    }

    const renderEthnicityField = () => {
        if (isEthnicityRequired()) {
            return (
                <Box className={styles.row} display="flex" width="100%">
                    <Box pr={isSm ? 0 : 1.5} width={isSm ? '100%' : '50%'}>
                        <Field
                            component={SearchSelect}
                            label="Ethnicity"
                            name={`${VENDOR_CERTIFICATION}.${ETHNICITY}`}
                            options={ethnicityOptions}
                            qaTag="addCertification-ethnicity"
                            useOpenGovStyle
                        />
                    </Box>
                </Box>
            );
        }
    };

    return (
        <>
            {(!editing || selectedCertification?.description) && (
                <Box
                    className={styles.certificationType}
                    display="flex"
                    flexDirection="column"
                    width="100%"
                >
                    {!editing && (
                        <Box pr={isSm ? 0 : 1.5} width={isSm ? '100%' : '50%'}>
                            <Field
                                component={SearchSelect}
                                disabled={editing}
                                label="Certification Type"
                                name={`${VENDOR_CERTIFICATION}.${CERTIFICATION_ID}`}
                                options={certificationsOptions}
                                useOpenGovStyle
                            />
                        </Box>
                    )}
                    {selectedCertification?.description && (
                        <Box className={styles.certificationDetails} padding={1}>
                            <Typography className={styles.certificationTitle}>
                                {selectedCertification.title} is defined as:
                            </Typography>
                            <Typography className={styles.certificationDescription}>
                                {selectedCertification.description}
                            </Typography>
                        </Box>
                    )}
                </Box>
            )}
            {certificationId && (
                <>
                    <Box
                        className={styles.row}
                        display="flex"
                        flexDirection={isSm ? 'column' : 'row'}
                        width="100%"
                    >
                        <Box width={isSm ? '100%' : '50%'}>
                            <Field
                                component={InputText}
                                label="Certifying Agency"
                                name={`${VENDOR_CERTIFICATION}.${CERTIFYING_AGENCY}`}
                                qaTag="addCertification-certifyingAgency"
                                useOpenGovStyle
                            />
                        </Box>
                        <Box width={isSm ? '100%' : '50%'}>
                            <Field
                                component={InputText}
                                label="Certificate Number"
                                name={`${VENDOR_CERTIFICATION}.${CERTIFICATE_NUMBER}`}
                                qaTag="addCertification-certificateNumber"
                                useOpenGovStyle
                            />
                        </Box>
                    </Box>
                    <Box className={styles.row} display="flex" width="100%">
                        <Box width="50%">
                            <Field
                                component={DateTimePicker}
                                label="Issued Date"
                                name={`${VENDOR_CERTIFICATION}.${ISSUED_DATE}`}
                                qaTag="addCertification-issuedDate"
                                useOpenGovStyle
                            />
                        </Box>
                        <Box width="50%">
                            <Field
                                component={DateTimePicker}
                                label="Expiration Date"
                                name={`${VENDOR_CERTIFICATION}.${EXPIRATION_DATE}`}
                                qaTag="addCertification-expirationDate"
                                useOpenGovStyle
                            />
                        </Box>
                    </Box>
                    {renderEthnicityField()}
                </>
            )}
        </>
    );
};

CertificationFormFields.propTypes = {
    certificationId: PropTypes.number,
    change: PropTypes.func.isRequired,
    editing: PropTypes.bool,
    ethnicity: PropTypes.string,
};

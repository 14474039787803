import { createSelector } from 'reselect';

const getChecklistClients = (state) => state.checklists.get('connectedClients');
const getProjectClients = (state) => state.govProjects.get('connectedClients');

export const getChecklistConnectedClients = createSelector(
    [getChecklistClients],
    (connectedClients) => {
        if (connectedClients) {
            return connectedClients.toJS();
        }
        return [];
    }
);

export const getProjectConnectedClients = createSelector(
    [getProjectClients],
    (connectedClients) => {
        if (connectedClients) {
            return connectedClients.toJS();
        }
        return [];
    }
);

import {
    customFieldFields,
    fieldTypes,
    yesNoOptions,
} from '@og-pro/shared-config/customFormService/customField';

const { ENUM_OPTIONS, FIELD_TYPE } = customFieldFields;

export const getCreateCustomFieldInitialFormValues = (fieldType) => {
    const enumOptionsInitialValue = {
        [ENUM_OPTIONS]: ['', '', ''],
    };

    const yesNoEnumOptions = {
        [ENUM_OPTIONS]: yesNoOptions,
    };

    const checkboxInitialValue = {
        [ENUM_OPTIONS]: [''],
    };

    const reduxFormProps = {
        initialValues: {
            [FIELD_TYPE]: fieldType,
            ...((fieldType === fieldTypes.DROPDOWN ||
                fieldType === fieldTypes.MULTIPLE_CHOICE ||
                fieldType === fieldTypes.SINGLE_CHOICE) &&
                enumOptionsInitialValue),
            ...(fieldType === fieldTypes.YES_NO && yesNoEnumOptions),
            ...(fieldType === fieldTypes.CHECKBOX && checkboxInitialValue),
        },
    };

    return reduxFormProps;
};

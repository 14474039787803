import { useEffect, useState } from 'react';

/**
 * Returns the dimensions of a given element (via ref)
 * Alternatively it also checks if a certain condition matches by running a passed function
 * Example:
 * const { width, height, matches } = useElementDimensions(ref, ({ width, height }) => width < 10 && height > 10)
 *
 * @param {Ref} ref
 * @param {Function} checkForConditionFunction Function that accepts as parameter an object { width, height } and returns a boolean
 * @returns {object}
 */
export const useElementDimensions = (ref, checkForConditionFunction = null) => {
    const [dimensions, setDimensions] = useState({
        width: undefined,
        height: undefined,
        matches: false,
    });

    useEffect(() => {
        if (typeof window === 'undefined' || !ref?.current) {
            return;
        }

        function handleResize() {
            const width = ref.current.clientWidth;
            const height = ref.current.clientHeight;
            setDimensions({
                width,
                height,
                matches: checkForConditionFunction
                    ? checkForConditionFunction({ width, height })
                    : false,
            });
        }
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return dimensions;
};

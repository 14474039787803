import { fieldNames } from './constants';

const { BODY } = fieldNames;

export const validate = (values) => {
    const errors = {};

    if (!values[BODY]) {
        errors[BODY] = 'Please enter some text for the body of the email';
    }

    return errors;
};

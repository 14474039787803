import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { TemplateSectionCreateModal } from './TemplateSectionCreateModal';
import { Button } from '../../../../components';

export const TemplateSectionCreateButton = ({
    bsSize,
    projectTypeSelectOptions,
    selectedProjectType,
    routingHandler,
}) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <Button
                bsSize={bsSize}
                bsStyle="primary"
                onClick={() => setShowModal(true)}
                qaTag="sharedSection-create"
            >
                <i className="fa fa-plus" /> New Shared Section
            </Button>
            {showModal && (
                <TemplateSectionCreateModal
                    hideModal={() => setShowModal(false)}
                    projectTypeSelectOptions={projectTypeSelectOptions}
                    routingHandler={routingHandler}
                    selectedProjectType={selectedProjectType}
                />
            )}
        </>
    );
};

TemplateSectionCreateButton.propTypes = {
    bsSize: PropTypes.string,
    projectTypeSelectOptions: PropTypes.array.isRequired,
    routingHandler: PropTypes.func.isRequired,
    selectedProjectType: PropTypes.string,
};

import React from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash';

/**
 * Replacement of withRouter from react-router v3.
 *
 * This helps us avoid a ton of refactoring.
 *
 * @param {ReactNode} Component Component to wrap with router props
 *
 * @return {ReactNode} Component with router props
 */
export function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        const location = useLocation();
        const navigate = useNavigate();
        const params = useParams();
        const [searchParams, setSearchParams] = useSearchParams();

        const routerProps = {
            location: {
                ...location,
                // patch location to include query params
                query: Object.fromEntries(searchParams),
            },
            navigate,
            params,
        };

        const handleNavigate = (path, { replace = false } = {}) => {
            const isSearchParamUpdateOnly = location.pathname === path && !isEmpty(path.search);

            return isSearchParamUpdateOnly
                ? setSearchParams(path.search)
                : navigate(path, { replace });
        };

        /**
         * For now, patched the router.[push|replace] methods to use the navigate hook.
         * Decide if we should just update all instances properly.
         */
        const router = {
            replace: (path) => handleNavigate(path, { replace: true }),
            push: handleNavigate,
        };
        return (
            <Component
                {...props}
                {...routerProps}
                route={{ path: location.pathname }}
                router={router}
            />
        );
    }

    return ComponentWithRouterProp;
}

/**
 * Replacement of browserHistory from react-router v3.
 */
let rr6Navigate;
let rr6Location;
export const useBrowserHistoryAdapter = () => {
    rr6Navigate = useNavigate();
    rr6Location = useLocation();
};
export const browserHistory = {
    push: (path) => rr6Navigate(path),
    replace: (path) => rr6Navigate(path, { replace: true }),
    go: (n) => rr6Navigate(n),
    goBack: () => rr6Navigate(-1),
    goForward: () => rr6Navigate(1),
    location: () => rr6Location,
};

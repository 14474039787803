import PropTypes from 'prop-types';
import React from 'react';

export const AssignedUserFormDisplay = ({ organizationName, userName }) => {
    const styles = require('./index.scss');

    return (
        <div className={styles.userNameText}>
            {userName}
            <br />
            <small>
                <em>{organizationName}</em>
            </small>
        </div>
    );
};

AssignedUserFormDisplay.propTypes = {
    organizationName: PropTypes.string,
    userName: PropTypes.string,
};

import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'react-bootstrap';

export const CheckboxGroup = ({ options = [], input: { value, onChange }, onOptionSelect }) => {
    const styles = require('./index.scss');

    const handleCheckboxChange = (option) => {
        if (onOptionSelect) {
            onOptionSelect(option);
        }

        const updatedValue = [...value];

        // Check if the option is already in the selected values
        const index = updatedValue.findIndex(
            (selectedOption) => selectedOption.value === option.value
        );

        if (index === -1) {
            // If not found, add the option to the selected values
            updatedValue.push(option);
        } else {
            // If found, remove the option from the selected values
            updatedValue.splice(index, 1);
        }

        onChange(updatedValue);
    };

    return (
        <ul className={styles.optionsContainer}>
            {options.map((option) => {
                const checked =
                    value && value.find((selectedOption) => selectedOption.value === option.value);

                return (
                    <li key={option.value}>
                        <Checkbox
                            checked={Boolean(checked)}
                            className={styles.checkbox}
                            data-qa={`checkboxGroup-checkbox-${option.value}`}
                            onChange={() => {
                                handleCheckboxChange(option);
                            }}
                        >
                            <span
                                className={styles.checkboxText}
                                title={`${option.label} ${option.description}`}
                            >
                                <span className={styles.checkboxLabel}>{option.label}</span>{' '}
                                <span className={styles.checkboxDescription}>
                                    {option.description}
                                </span>
                            </span>
                        </Checkbox>
                    </li>
                );
            })}
        </ul>
    );
};

const optionShape = PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    filter: PropTypes.string.isRequired,
    data: PropTypes.object,
});

CheckboxGroup.propTypes = {
    options: PropTypes.arrayOf(optionShape),
    input: PropTypes.object,
    onOptionSelect: PropTypes.func,
};

import { Box } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StepApprovalLogs } from '../SequenceStep/StepApprovalLogs';
import { loadRejectedExceptionSequenceSteps } from '../../../../../../actions/requisitionsApprovals';
import { LoadingError, LoadingSpinner } from '../../../../../../components';
import {
    getExceptionSequenceRequisitionLogsDisplay,
    getIsLoadingRejectedExceptionSequenceSteps,
    getLoadRejectedExceptionSequenceStepsError,
} from './selectors';

export const ExceptionBody = ({ requisition }) => {
    const dispatch = useDispatch();
    const loading = useSelector(getIsLoadingRejectedExceptionSequenceSteps);
    const loadError = useSelector(getLoadRejectedExceptionSequenceStepsError);
    const requisitionLogs = useSelector(getExceptionSequenceRequisitionLogsDisplay);

    useEffect(() => {
        dispatch(loadRejectedExceptionSequenceSteps(requisition.id));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (loading) {
        return (
            <Box component={LoadingSpinner} noPadding padding={4} size="medium" useOpenGovStyle />
        );
    }

    if (loadError) {
        return <LoadingError error={loadError} useOpenGovStyle />;
    }

    return <StepApprovalLogs requisitionLogs={requisitionLogs} />;
};

ExceptionBody.propTypes = {
    requisition: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }).isRequired,
};

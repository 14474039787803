import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { ChecklistCreateConfirm } from './ChecklistCreateConfirm';
import { getUserJS, isTemplateAdminUser } from '../../../selectors';
import { createContractChecklist } from '../../../../actions/contracts';
import { Button, TemplateSelect } from '../../../../components';
import { getPublishedChecklistTemplates } from '../../../../selectors/govApp';

export const ChecklistCreateModal = ({ contractId, onHide }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const user = useSelector(getUserJS);
    const isTemplateAdmin = useSelector(isTemplateAdminUser);
    const templates = useSelector(getPublishedChecklistTemplates);

    const [page, setPage] = useState(1);
    const [template, setTemplate] = useState();
    const [creating, setCreating] = useState(false);
    const [createError, setCreateError] = useState(null);

    const styles = require('./index.scss');

    const selectTemplate = (selectedTemplate) => {
        setTemplate(selectedTemplate);
        setPage((prevPage) => prevPage + 1);
    };

    const createChecklist = (checklistOwnershipFormData) => {
        const createData = {
            ...checklistOwnershipFormData,
            template_id: template.id,
        };

        setCreating(true);
        setCreateError(null);

        return dispatch(createContractChecklist(contractId, createData))
            .then((result) => {
                setCreating(false);
                navigate(`${location.pathname}/${result.id}`);
            })
            .catch((error) => {
                setCreating(false);
                setCreateError(error.message);
            });
    };

    return (
        <Modal className={styles.checklistCreateModal} onHide={onHide} show>
            <Modal.Body>
                <div className={styles.checklistCreateModalContainer}>
                    {page === 1 && (
                        <TemplateSelect
                            isTemplateAdmin={isTemplateAdmin}
                            onSelect={selectTemplate}
                            templateAdminUrl={`/governments/${user.government_id}/checklists-admin`}
                            templates={templates}
                            type="checklist"
                        />
                    )}
                    {page === 2 && (
                        <ChecklistCreateConfirm
                            createError={createError}
                            createHandler={createChecklist}
                            creating={creating}
                            template={template}
                            user={user}
                        />
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="row">
                    <div className="col-xs-6 text-left">
                        {page === 2 && (
                            <Button
                                disabled={creating}
                                onClick={() => setPage((prevPage) => prevPage - 1)}
                                qaTag="checklistCreateModal-back"
                            >
                                <i className="fa fa-arrow-left" /> Back
                            </Button>
                        )}
                    </div>
                    <div className="col-xs-6 text-right">
                        {page === 1 && (
                            <Button
                                disabled={creating}
                                onClick={onHide}
                                qaTag="checklistCreateModal-close"
                            >
                                Close
                            </Button>
                        )}
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

ChecklistCreateModal.propTypes = {
    contractId: PropTypes.number.isRequired,
    onHide: PropTypes.func.isRequired,
};

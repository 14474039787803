import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getProjectJS } from '../selectors';
import { DownloadsList } from '../../../components';

const mapStateToProps = (state) => {
    return {
        project: getProjectJS(state),
    };
};

// @connect
class ConnectedDownloads extends Component {
    static propTypes = {
        project: PropTypes.object,
    };

    render() {
        const { project } = this.props;

        // Project should always be available, but can sometimes be missing for a tick when
        // navigating using back/forward
        if (!project) {
            return null;
        }

        return <DownloadsList project={project} />;
    }
}

export const Downloads = connect(mapStateToProps)(ConnectedDownloads);

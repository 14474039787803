import { get, reduce } from 'lodash';

import { criteriaInstructionsDict } from '@og-pro/shared-config/criteria';

import { sectionTypeNames } from '@og-pro/shared-config/sections';

import {
    criteriaFieldNames,
    evaluationPhaseFieldNames,
    fieldNames,
    sectionDescriptionFieldNames,
} from './constants';
import {
    arrayError,
    evaluationCriteriaItem,
    manualNumber,
    MAX_TEXT_AREA_LENGTH,
    projectTimelines,
    signatureValidate,
    questionnaire as questionnaireValidator,
    REQUIRED_TEXT,
} from '../../../../../Forms/validation';

const { EVALUATION_PHASE, SCOPE, TERMS, TEXT_AREA } = sectionTypeNames;

const { CRITERIA, EVALUATION_PHASES, QUESTIONNAIRES, SECTION_DESCRIPTIONS, SIGNATURES } =
    fieldNames;

const {
    DESCRIPTION: CRITERIA_DESCRIPTION,
    INSTRUCTION_TYPE,
    INSTRUCTIONS,
    MANUAL_NUMBER: CRITERIA_MANUAL_NUMBER,
    NEEDS_REVIEW,
    NEST_LEVEL,
    TITLE: CRITERIA_TITLE,
} = criteriaFieldNames;

const { DESCRIPTION: SECTION_DESCRIPTION } = sectionDescriptionFieldNames;

const { SCORING_CRITERIA, TITLE: PHASE_TITLE } = evaluationPhaseFieldNames;

const { ATTACHMENT, RADIO, SELECT } = criteriaInstructionsDict;

function timelineNamesValidate(values) {
    return projectTimelines(values, { skipRequiredFields: true });
}

function criteriaItemsValidate(criteria, projectSection, useManualNumbering) {
    const nullInstructionTypes = [ATTACHMENT, RADIO, SELECT];
    const isTextArea = projectSection.section_type === TEXT_AREA;

    if (!projectSection.isWritingForm && criteria.length === 0) {
        return arrayError('Please include at least one item if only admins can edit');
    }

    return criteria.map((criteriaItem, idx) => {
        const criteriaErrors = {};

        if (isTextArea) {
            // Text area section types do not have titles
        } else if (!criteriaItem[CRITERIA_TITLE]) {
            criteriaErrors[CRITERIA_TITLE] = REQUIRED_TEXT;
        } else if (criteriaItem[CRITERIA_TITLE].length >= 200) {
            criteriaErrors[CRITERIA_TITLE] = 'This field is too long';
        }

        if (!criteriaItem[CRITERIA_DESCRIPTION]) {
            // Description can only be empty if it's part of a nested structure or needs review item
            // or is a `TEXT_AREA` item
            const nextItem = criteria[idx + 1];
            const isTopLevel = criteriaItem[NEST_LEVEL] === 0 && get(nextItem, NEST_LEVEL) === 1;
            const isNullInstructionType = nullInstructionTypes.includes(
                criteriaItem[INSTRUCTION_TYPE]
            );

            if (isTextArea && projectSection.isWritingForm) {
                // Text area writing sections do not need descriptions
            } else if (!isTopLevel && !isNullInstructionType) {
                criteriaErrors[CRITERIA_DESCRIPTION] = REQUIRED_TEXT;
            }
        } else if (criteriaItem[CRITERIA_DESCRIPTION].length >= MAX_TEXT_AREA_LENGTH) {
            criteriaErrors[CRITERIA_DESCRIPTION] = 'This field is too long';
        }

        if (criteriaItem[NEEDS_REVIEW] && !criteriaItem[INSTRUCTION_TYPE]) {
            criteriaErrors[INSTRUCTION_TYPE] = 'Must select instruction type if item needs review';
        }
        if (criteriaItem[NEEDS_REVIEW] && !criteriaItem[INSTRUCTIONS]) {
            criteriaErrors[INSTRUCTIONS] = 'Must include instructions if item needs review';
        }

        if (useManualNumbering && !isTextArea) {
            const manualNumbering = criteriaItem[CRITERIA_MANUAL_NUMBER];
            criteriaErrors[CRITERIA_MANUAL_NUMBER] = manualNumber(manualNumbering);
        }

        return criteriaErrors;
    });
}

function sectionDescriptionValidate(sectionDescription) {
    const sectionDescErrors = {};

    if (!sectionDescription[SECTION_DESCRIPTION]) {
        sectionDescErrors[SECTION_DESCRIPTION] = REQUIRED_TEXT;
    }

    return sectionDescErrors;
}

export const sectionTypesWithCriteria = [SCOPE, TERMS, TEXT_AREA];

export const projectContentValidate = (values, projectSections, manualNumberingEnabled) => {
    const { useManualNumbering } = values;

    const errors = timelineNamesValidate(values);

    errors[SECTION_DESCRIPTIONS] = reduce(
        values[SECTION_DESCRIPTIONS] || {},
        (errorObject, sectionDescription, key) => {
            if (!sectionDescription) {
                return errorObject;
            }
            errorObject[key] = sectionDescriptionValidate(sectionDescription);
            return errorObject;
        },
        {}
    );

    errors[QUESTIONNAIRES] = (values[QUESTIONNAIRES] || []).map((question) =>
        questionnaireValidator(question, { validateBidBond: false })
    );

    const isMultiPhase = projectSections.some((sec) => sec.section_type === EVALUATION_PHASE);
    errors[EVALUATION_PHASES] = (values[EVALUATION_PHASES] || []).map((phase) => {
        const phaseErrors = {};

        if (isMultiPhase) {
            if (!phase[PHASE_TITLE]) {
                phaseErrors[PHASE_TITLE] = REQUIRED_TEXT;
            } else if (phase[PHASE_TITLE].length > 250) {
                phaseErrors[PHASE_TITLE] = 'This field is too long';
            }
        }

        phaseErrors[SCORING_CRITERIA] = (phase[SCORING_CRITERIA] || []).map((scoringCriteria) => {
            return evaluationCriteriaItem(scoringCriteria, { skipWeight: true });
        });

        return phaseErrors;
    });

    errors[CRITERIA] = reduce(
        projectSections,
        (errorObject, projectSection) => {
            if (!sectionTypesWithCriteria.includes(projectSection?.section_type)) {
                return errorObject;
            }

            projectSection.projectSubsections.forEach((projectSubsection) => {
                const criteriaKey = `${projectSection.id}_${projectSubsection.id}`;
                const criteria = values[CRITERIA];
                const criteriaList = (criteria && criteria[criteriaKey]) || [];
                errorObject[criteriaKey] = criteriaItemsValidate(
                    criteriaList,
                    projectSection,
                    useManualNumbering || manualNumberingEnabled
                );
            });

            return errorObject;
        },
        {}
    );

    errors[SIGNATURES] = (values[SIGNATURES] || []).reduce((acc, signature, index) => {
        return {
            ...acc,
            [index]: signatureValidate(signature),
        };
    }, []);

    return errors;
};

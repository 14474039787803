import React from 'react';
import PropTypes from 'prop-types';
import { ListGroupItem } from 'react-bootstrap';

import { currencyFormatter, dateTZFormatter } from '../../../../../../helpers';

export const LineItemDetailsListItem = ({
    auctionMaxFractionDigits,
    item: {
        noBid,
        proposal: { companyName },
        reverseAuctionBids,
    },
    quantity,
    ranking,
    timezone,
}) => {
    const styles = require('../../index.scss');
    const formatterOpts = {
        maximumFractionDigits: auctionMaxFractionDigits,
        useSameMinAndMax: true,
    };

    const renderNoBidListItem = () => {
        return (
            <div className="col-sm-3">
                <div className={styles.noBidText}>No Bid</div>
            </div>
        );
    };

    const renderActiveListItem = () => {
        const {
            0: { created_at: firstPlaceBidCreatedAt, unitPrice: firstPlaceBidUnitPrice },
            length: bidCount,
        } = reverseAuctionBids;

        return (
            <>
                <div className="col-xs-4 col-sm-2">
                    <div className={styles.priceText}>
                        {currencyFormatter({ value: firstPlaceBidUnitPrice }, formatterOpts)}
                    </div>
                    <div className={styles.priceLabel}>Unit Price</div>
                </div>
                <div className="col-xs-4 col-sm-2">
                    <div className={styles.priceText}>
                        {currencyFormatter(
                            { value: quantity * firstPlaceBidUnitPrice },
                            formatterOpts
                        )}
                    </div>
                    <div className={styles.priceLabel}>Total</div>
                </div>
                <div className={`col-xs-12 col-sm-4 ${styles.listItemDateAndBidSection}`}>
                    <div className={styles.listItemInfoText}>
                        {bidCount > 0
                            ? dateTZFormatter(
                                  timezone,
                                  'h:mm:ss a on MM/DD/YYYY'
                              )({ value: firstPlaceBidCreatedAt })
                            : ''}
                    </div>
                    <div className={styles.listItemInfoText}>
                        <span>Number of Bids: </span>
                        <strong>{bidCount}</strong>
                    </div>
                </div>
            </>
        );
    };

    return (
        <ListGroupItem className={styles.lineItemDetailsListItem}>
            <div className="row">
                <div className="col-xs-4 col-sm-4">
                    {noBid ? (
                        <span className={styles.noBidRank} />
                    ) : (
                        <span className={styles.vendorRank}>{ranking}</span>
                    )}
                    <span className={styles.companyNameText}>{companyName}</span>
                </div>
                {noBid ? renderNoBidListItem() : renderActiveListItem()}
            </div>
        </ListGroupItem>
    );
};

LineItemDetailsListItem.propTypes = {
    auctionMaxFractionDigits: PropTypes.number.isRequired,
    item: PropTypes.shape({
        id: PropTypes.number.isRequired,
        noBid: PropTypes.bool.isRequired,
        proposal: PropTypes.shape({
            companyName: PropTypes.string.isRequired,
        }),
        reverseAuctionBids: PropTypes.arrayOf(
            PropTypes.shape({
                created_at: PropTypes.string.isRequired,
                unitPrice: PropTypes.number.isRequired,
            })
        ),
    }),
    quantity: PropTypes.number.isRequired,
    ranking: PropTypes.number.isRequired,
    timezone: PropTypes.string.isRequired,
};

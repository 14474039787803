import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { TemplateQuestionEditModal } from './TemplateQuestionEditModal';
import { TemplateQuestionsListDisplay } from './TemplateQuestionsListDisplay';
import { TemplateListTimestampHeaders, ZeroState } from '../../../../components';

export const TemplateQuestionsList = ({
    hasNoCreatedQuestions,
    TemplateCreateButton,
    templateQuestions,
    templatesAdminPath,
}) => {
    const [showModal, setShowModal] = useState(false);
    const [templateQuestionId, setTemplateQuestionId] = useState(false);

    const styles = require('./index.scss');

    if (templateQuestions.length === 0) {
        const props = {
            button: hasNoCreatedQuestions ? <TemplateCreateButton bsSize="lg" /> : undefined,
            title: hasNoCreatedQuestions
                ? 'No shared questions created yet'
                : 'No shared questions here!',
        };
        return <ZeroState {...props} />;
    }

    return (
        <>
            <div className={`row ${styles.listHeader} hidden-xs hidden-sm`}>
                <div className="col-md-9">
                    <div className="row">
                        <div className="col-md-5">Shared Question</div>
                        <div className="col-md-3">Owner</div>
                        <div className="col-md-4">Templates In Use</div>
                    </div>
                </div>
                <div className="col-md-3">
                    <TemplateListTimestampHeaders />
                </div>
            </div>
            <TemplateQuestionsListDisplay
                setTemplateQuestionId={setTemplateQuestionId}
                showModal={() => setShowModal(true)}
                templateQuestions={templateQuestions}
                templatesAdminPath={templatesAdminPath}
            />
            {showModal && !!templateQuestionId && (
                <TemplateQuestionEditModal
                    hideModal={() => setShowModal(false)}
                    templateQuestionId={templateQuestionId}
                />
            )}
        </>
    );
};

TemplateQuestionsList.propTypes = {
    hasNoCreatedQuestions: PropTypes.bool,
    TemplateCreateButton: PropTypes.func.isRequired,
    templateQuestions: PropTypes.array.isRequired,
    templatesAdminPath: PropTypes.string.isRequired,
};

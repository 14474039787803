import PropTypes from 'prop-types';
import { Box, Button, Dialog, Typography } from '@og-pro/ui';
import React from 'react';

import { MismatchInfo } from './MismatchInfo';
import { useDialogData } from './useDialogData';

export const MismatchContractDialog = ({
    data: { contract, onSkip, onUpdate, selectNewVendor, vendor },
    hideModal,
}) => {
    const {
        info,
        primaryActionHandler,
        primaryActionText,
        secondaryActionHandler,
        secondaryActionText,
        title,
        titleColor,
        titleIcon,
    } = useDialogData({ contract, onSkip, onUpdate, selectNewVendor });

    const dialogTitle = (
        <Typography color={titleColor} variant="h3">
            {titleIcon}
            {title}
        </Typography>
    );

    return (
        <Dialog dialogTitle={dialogTitle} fullWidth maxWidth="sm" onClose={hideModal} open>
            <Box paddingLeft={5}>
                <Typography paddingBottom={4} variant="bodyDefault">
                    {info}
                </Typography>
                <MismatchInfo contract={contract} vendor={vendor} />
            </Box>
            <Box display="flex" gap={1} justifyContent="flex-end" paddingTop={3}>
                <Button color="secondary" onClick={hideModal} variant="text">
                    Cancel
                </Button>
                <Button
                    color="secondary"
                    onClick={() => {
                        if (secondaryActionHandler) {
                            secondaryActionHandler();
                        }
                        hideModal();
                    }}
                    variant="outlined"
                >
                    {secondaryActionText}
                </Button>
                <Button
                    color="primary"
                    onClick={() => {
                        if (primaryActionHandler) {
                            primaryActionHandler();
                        }
                        hideModal();
                    }}
                    variant="contained"
                >
                    {primaryActionText}
                </Button>
            </Box>
        </Dialog>
    );
};

MismatchContractDialog.propTypes = {
    data: PropTypes.shape({
        contract: PropTypes.shape({
            contractId: PropTypes.string,
            id: PropTypes.number.isRequired,
            title: PropTypes.string,
            vendorAssignedNo: PropTypes.string,
        }).isRequired,
        onSkip: PropTypes.func,
        onUpdate: PropTypes.func,
        selectNewVendor: PropTypes.func,
        vendor: PropTypes.object.isRequired,
    }).isRequired,
    hideModal: PropTypes.func.isRequired,
};

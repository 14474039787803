import PropTypes from 'prop-types';
import React from 'react';

import { SectionSettings, SectionTitleFormGroup } from '../components';

export const BasicSectionSettings = ({
    disabled,
    onClose,
    sectionNameField,
    sectionInstructionsField,
    title,
}) => {
    return (
        <SectionSettings disabled={disabled} onClose={onClose} title={title}>
            <SectionTitleFormGroup
                disabled={disabled}
                sectionInstructionsField={sectionInstructionsField}
                sectionNameField={sectionNameField}
            />
        </SectionSettings>
    );
};

BasicSectionSettings.propTypes = {
    disabled: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    sectionInstructionsField: PropTypes.string.isRequired,
    sectionNameField: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

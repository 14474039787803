import { customFieldFields } from '@og-pro/shared-config/customFormService/customField';

import { REQUIRED_TEXT } from '../../../../../Forms/validation';
import { fieldTypesData } from './constants';

const { NAME } = customFieldFields;

export const validate = (values) => {
    const { fieldType } = values;
    let errors = {};

    const fieldTypeValidateFn = fieldTypesData[fieldType]?.validateFn || (() => ({}));

    if (!values[NAME]) {
        errors[NAME] = REQUIRED_TEXT;
    }

    errors = {
        ...errors,
        ...fieldTypeValidateFn(values),
    };

    return errors;
};

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useParams, useLocation } from 'react-router-dom';

import { getNavItems, getSelectedRoute } from './selectors';
import connectData from '../../ConnectData';
import { loadRevisions, resetRevisions } from '../../../actions/revisions';
import { LoadingError, LoadingSpinner, PageTitle, UnderlineNav } from '../../../components';

function fetchData(getState, dispatch, location, params) {
    const projectId = Number.parseInt(params.projectId, 10);

    if (!getState().revisions.get('loaded')) {
        return dispatch(loadRevisions(projectId));
    }
}

// @connect
const ConnectedRevisions = () => {
    const location = useLocation();
    const params = useParams();
    const dispatch = useDispatch();

    const error = useSelector((state) => state.revisions.get('error'));
    const loading = useSelector((state) => state.revisions.get('loading'));
    const navItems = useSelector((state) => getNavItems(state, { location, params }));
    const selectedRoute = useSelector((state) => getSelectedRoute(state, { location, params }));

    const styles = require('./Revisions.scss');

    useEffect(() => {
        return () => {
            dispatch(resetRevisions());
        };
    }, []);

    if (loading) {
        return <LoadingSpinner />;
    }

    if (error) {
        return <LoadingError error={error} />;
    }

    return (
        <>
            <PageTitle title="Revisions History" />
            <UnderlineNav className={styles.nav}>
                {navItems.map(({ name, route }) => (
                    <UnderlineNav.NavItem
                        className={route === selectedRoute ? 'active' : ''}
                        key={route}
                        overrideIsActive={false}
                        to={route}
                    >
                        {name}
                    </UnderlineNav.NavItem>
                ))}
            </UnderlineNav>
            <Outlet />
        </>
    );
};

export const Revisions = connectData(fetchData)(ConnectedRevisions);

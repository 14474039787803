import { formValueSelector } from 'redux-form';
import { createSelector } from 'reselect';

import { fieldNames } from './constants';
import { formConfig } from './form';
import { getEmptyPriceItem } from './helpers';
import { getRequisitionJS } from '../../../../selectors/govApp';

const { PRICE_TABLE, VENDORS } = fieldNames;

export const requisitionsCreateFormValueSelector = formValueSelector(formConfig.form);

// Requisition data from API deserialized for use within form
export const getDeserializedRequisition = createSelector([getRequisitionJS], (requisition) => {
    // Case where requisition exists and form should be populated with data from API
    if (requisition) {
        const { desiredDeliveryDate, expectedPurchaseOrderDate, priceTable, ...requisitionData } =
            requisition;

        const deserializedPriceItems = priceTable.priceItems.map((priceItem) => ({
            ...priceItem,
            clientVendorAssignmentUuid:
                priceItem.requisitionVendorData[0]?.vendorAssignmentUuid || null,
            vendorName: priceItem.requisitionVendorData[0]?.vendorName || null,
        }));

        return {
            ...requisitionData,
            [fieldNames.DESIRED_DELIVERY_DATE]:
                desiredDeliveryDate && new Date(desiredDeliveryDate),
            [fieldNames.EXPECTED_PURCHASE_ORDER_DATE]:
                expectedPurchaseOrderDate && new Date(expectedPurchaseOrderDate),
            priceTable: {
                ...priceTable,
                [fieldNames.PRICE_ITEMS]:
                    priceTable.priceItems.length > 0
                        ? deserializedPriceItems
                        : [getEmptyPriceItem()],
            },
        };
    }
});

export const getLineItemOptions = createSelector(
    [
        (state) => requisitionsCreateFormValueSelector(state, PRICE_TABLE),
        (state) => requisitionsCreateFormValueSelector(state, VENDORS),
    ],
    (priceTable, vendors) => {
        // prevent two vendors from having the same line item
        const assignedLineItems = vendors
            .filter((vendor) => vendor.associatedLineItems)
            .flatMap((vendor) => vendor.associatedLineItems.map((lineItem) => lineItem.value));

        const availableLineItems = priceTable.priceItems
            .map((priceItem, index) => ({
                ...priceItem,
                index,
            }))
            .filter((priceItem) =>
                assignedLineItems?.every((lineItem) => lineItem !== priceItem.vendorAssignmentUuid)
            );

        return availableLineItems.map((priceItem) => ({
            label: `Line Item ${priceItem.index + 1}`,
            value: priceItem.vendorAssignmentUuid,
            description: priceItem.description,
        }));
    }
);

export const getLineItems = createSelector([requisitionsCreateFormValueSelector], (values) => {
    if (values[PRICE_TABLE]?.priceItems) {
        return values[PRICE_TABLE].priceItems;
    }

    return [];
});

export const getCanAddVendor = createSelector(
    [getLineItemOptions, (state) => requisitionsCreateFormValueSelector(state, VENDORS)],
    (unassignedLineItems, vendors) => {
        const unassignedVendors = vendors.filter(
            (vendor) => vendor.associatedLineItems.length === 0
        );
        return unassignedLineItems.length > unassignedVendors?.length;
    }
);

export const getInitialVendorSearch = createSelector(
    [(state) => state.requisitionsCreate.get('initialVendorSearch')],
    (initialVendorSearch) => initialVendorSearch.toJS()
);

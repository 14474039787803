import PropTypes from 'prop-types';
import React from 'react';
import { ListGroupItem } from 'react-bootstrap';
import { Box } from '@og-pro/ui';

export const CustomVariableListItem = ({ matchCount, projectSections, upfrontQuestion }) => {
    return (
        <ListGroupItem>
            <Box fontWeight={700} mb={1}>
                {upfrontQuestion.title} ({matchCount})
            </Box>
            {projectSections.map(({ matches, projectSection }) => (
                <Box className="row" mb={1}>
                    <div className="col-xs-4 text-right">{projectSection.title}</div>
                    <div className="col-xs-8">
                        {matches.map((match, index) => (
                            <div key={index}>• {match}</div>
                        ))}
                    </div>
                </Box>
            ))}
        </ListGroupItem>
    );
};

CustomVariableListItem.propTypes = {
    matchCount: PropTypes.number.isRequired,
    projectSections: PropTypes.arrayOf(
        PropTypes.shape({
            matches: PropTypes.arrayOf(PropTypes.string).isRequired,
            projectSection: PropTypes.shape({
                title: PropTypes.string.isRequired,
            }).isRequired,
        })
    ).isRequired,
    upfrontQuestion: PropTypes.shape({
        title: PropTypes.string.isRequired,
    }).isRequired,
};

import React from 'react';
import { Outlet } from 'react-router-dom';

import {
    RequisitionsApproval,
    RequisitionsCreate,
    RequisitionsDashboard,
    AccountDetails,
} from '../../../containers/Requisitions';
import { legacyFetchData, requireRequisitionsSubscription } from '../../loaders';

export default (getState, dispatch) => {
    return [
        {
            element: <Outlet />,
            loader: requireRequisitionsSubscription(getState, dispatch),
            path: 'requisitions',
            children: [
                {
                    element: <RequisitionsDashboard />,
                    index: true,
                },
                {
                    element: <RequisitionsApproval />,
                    loader: legacyFetchData(getState, dispatch, RequisitionsApproval),
                    path: ':requisitionId',
                },
                {
                    element: <RequisitionsCreate />,
                    loader: legacyFetchData(getState, dispatch, RequisitionsCreate),
                    path: ':requisitionId/edit',
                },
                {
                    element: <AccountDetails />,
                    path: 'accounts/details',
                },
            ],
        },
    ];
};

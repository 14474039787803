import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
    Box,
    Table as MuiTable,
    TableBody,
    TableCell as MuiTableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
    TablePagination,
} from '@og-pro/ui';
import { hexToRGB } from '@og-pro/shared-config/helpers';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { chunk } from 'lodash';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { getCustomFieldsLibraryPath } from '../../selectors';

import { createCustomFieldDisplayData } from '../CustomFieldsModal/createCustomFieldDisplayData';

import { CustomFieldStatusTag } from '../../RequisitionsCreateCustomField/CustomFieldStatusTag';

function createColumn(title, style, alignment = 'left', sortKey = null) {
    return { title, style, alignment, sortKey };
}

const cols = [
    createColumn('Field Name', 'h4', 'left', 'fieldName'),
    createColumn('Report Name', 'h4', 'left', 'reportName'),
    createColumn('Last Updated', 'h4', 'left', 'lastUpdated'),
    createColumn('Status', 'h4', 'right', 'status'),
];

const TableCell = ({ columnName, children, ...props }) => {
    const column = cols.find((col) => col.title === columnName);
    return (
        <MuiTableCell
            align={column.alignment}
            sx={(theme) => ({
                padding: `${theme.spacing(2)} !important`,
                verticalAlign: 'top',
            })}
            {...props}
        >
            {children}
        </MuiTableCell>
    );
};

TableCell.propTypes = {
    columnName: PropTypes.string.isRequired,
    children: PropTypes.node,
};

const dateFormatter = new Intl.DateTimeFormat('en-US', { dateStyle: 'short' });
const timeFormatter = new Intl.DateTimeFormat('en-US', { timeStyle: 'short' });

/**
 * Shapes API response for table
 * @param {object} customField API response, see CustomField graphql
 * @returns {object} row formatted for table
 */
function formatCustomFieldRow(customField) {
    const dateObject = new Date(parseInt(customField.updated_at, 10));
    return {
        customFieldId: customField.id,
        fieldName: customField.name,
        reportName: customField.shortTitle,
        lastUpdated:
            `${dateFormatter.format(dateObject)} at ${timeFormatter.format(dateObject)}`.toLowerCase(),
        status: customField.status,
        fieldType: customField.fieldType,
    };
}

const sortRows = (rows, order, orderBy) => {
    return rows.slice().sort((a, b) => {
        switch (orderBy) {
            case 'fieldName':
                return order === 'asc'
                    ? a.fieldName.localeCompare(b.fieldName)
                    : b.fieldName.localeCompare(a.fieldName);
            case 'reportName':
                return order === 'asc'
                    ? (a.reportName || '').localeCompare(b.reportName || '')
                    : (b.reportName || '').localeCompare(a.reportName || '');
            case 'lastUpdated':
                return order === 'asc'
                    ? new Date(a.lastUpdated) - new Date(b.lastUpdated)
                    : new Date(b.lastUpdated) - new Date(a.lastUpdated);
            case 'status':
                return order === 'asc'
                    ? a.status.localeCompare(b.status)
                    : b.status.localeCompare(a.status);
            default:
                return 0;
        }
    });
};

export const CustomFieldsTable = ({ rows }) => {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('fieldName');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const formattedRows = rows.map(formatCustomFieldRow);

    const sortedRows = sortRows(formattedRows, order, orderBy);

    const navigate = useNavigate();
    const params = useParams();
    const customFieldsEditPathRoot = useSelector((state) =>
        getCustomFieldsLibraryPath(state, { params })
    );

    const totalFieldCount = sortedRows.length;
    const dataPages = chunk(sortedRows, rowsPerPage);
    const pageRows = dataPages[page] || [];

    const handleSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Box>
            <TableContainer sx={{ margin: 0 }}>
                <MuiTable
                    aria-label="custom-fields-table"
                    sx={{
                        borderTop: `1px solid ${capitalDesignTokens.semanticColors.border.primary}`,
                        borderRight: `1px solid ${capitalDesignTokens.semanticColors.border.primary}`,
                        borderLeft: `1px solid ${capitalDesignTokens.semanticColors.border.primary}`,
                    }}
                >
                    <TableHead>
                        <TableRow>
                            {cols.map((col) => (
                                <TableCell columnName={col.title} key={col.title}>
                                    <TableSortLabel
                                        active={orderBy === col.sortKey}
                                        direction={orderBy === col.sortKey ? order : 'asc'}
                                        onClick={() => handleSort(col.sortKey)}
                                    >
                                        <Typography fontWeight="500" variant={col.style}>
                                            {col.title}
                                        </Typography>
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {pageRows.map((row, index) => {
                            const { label, icon: IconComponent } =
                                createCustomFieldDisplayData[row.fieldType];

                            const { fieldName, reportName, lastUpdated, status } = row;

                            return (
                                <TableRow
                                    hover
                                    key={index}
                                    onClick={() =>
                                        navigate(`${customFieldsEditPathRoot}/${row.customFieldId}`)
                                    }
                                    sx={{
                                        ':hover': {
                                            cursor: 'pointer',
                                        },
                                    }}
                                >
                                    <TableCell columnName="Field Name">
                                        <Box display="flex">
                                            <Box
                                                sx={{
                                                    background: hexToRGB(
                                                        capitalDesignTokens.semanticColors
                                                            .dataVisualization.sequence450,
                                                        0.3
                                                    ),
                                                    borderRadius: 1,
                                                    height: capitalDesignTokens.foundations.units
                                                        .unit3,
                                                    width: capitalDesignTokens.foundations.units
                                                        .unit3,
                                                    color: capitalDesignTokens.semanticColors
                                                        .highlights.tealDark,
                                                }}
                                            >
                                                <IconComponent
                                                    style={{
                                                        padding:
                                                            capitalDesignTokens.foundations.units
                                                                .unitHalf,
                                                    }}
                                                />
                                            </Box>
                                            <Box ml={2}>
                                                <Typography
                                                    color={
                                                        capitalDesignTokens.semanticColors
                                                            .callToAction.primaryActionDark
                                                    }
                                                    component={Link}
                                                    to={`${customFieldsEditPathRoot}/${row.customFieldId}`}
                                                    variant="h4"
                                                >
                                                    {fieldName}
                                                </Typography>
                                                <Typography
                                                    sx={(theme) => ({
                                                        color: theme.palette.text.secondary,
                                                    })}
                                                    variant="bodySmall"
                                                >
                                                    {label}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell columnName="Report Name">
                                        <Typography variant="bodyDefault">{reportName}</Typography>
                                    </TableCell>
                                    <TableCell columnName="Last Updated">
                                        <Typography variant="bodyDefault">{lastUpdated}</Typography>
                                    </TableCell>
                                    <TableCell columnName="Status">
                                        <CustomFieldStatusTag status={status} variant="tag" />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </MuiTable>
            </TableContainer>
            <TablePagination
                component="div"
                count={totalFieldCount}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
                slotProps={{
                    select: {
                        inputProps: { 'aria-label': 'rows per page' }, // Accessibility fix
                        native: true,
                    },
                }}
                sx={{
                    '& p': {
                        margin: '0',
                    },
                    '& .MuiSelect-icon': {
                        marginRight: '-4px',
                    },
                }}
            />
        </Box>
    );
};

CustomFieldsTable.propTypes = {
    rows: PropTypes.arrayOf(
        PropTypes.shape({
            fieldName: PropTypes.string.isRequired,
            reportName: PropTypes.string,
            lastUpdated: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired,
        })
    ).isRequired,
};

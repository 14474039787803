const { listToDict } = require('../helpers');

exports.userRolePermissionTypes = [
    'isBidAmender',
    'isBidPoster',
    'isContractAdmin',
    'isDepartmentBidAmender',
    'isDepartmentBidPoster',
    'isDepartmentContractAdmin',
    'isDepartmentEditor',
    'isGlobalEditor',
    'isGlobalViewer',
    'isProjectCreator',
    'isRequisitionGlobalEditor',
    'isRequisitionGroupAdmin',
    'isRetentionAdmin',
    'isSystemAdmin',
    'isTemplateAdmin',
    'isVendorAdmin',
];

exports.userRolePermissions = listToDict(exports.userRolePermissionTypes);

exports.basicUserRoleTypes = ['user', 'admin', 'batman'];

exports.basicUserRoles = listToDict(exports.basicUserRoleTypes);

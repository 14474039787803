import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@og-pro/ui';
import { tokens } from '@opengov/capital-style';

const TitleNumberingDisabledWarning = ({ section }) => {
    if (section.disableNumbering || section.disableTitle) {
        return (
            <Box
                display="flex"
                mt={0.5}
                sx={{
                    color: tokens.colors.colorGray700,
                    fontSize: tokens.typography.fontSizeSmall,
                    fontWeight: tokens.typography.fontWeightRegular,
                }}
            >
                <Box mr={0.5}>
                    <i className="fa fa-exclamation-triangle fa-fw" />
                </Box>
                <Box>
                    {section.disableTitle ? 'Section Title ' : 'Section Numbering '}
                    is set to hide in Preview mode and Document Export
                </Box>
            </Box>
        );
    }

    return null;
};
TitleNumberingDisabledWarning.propTypes = {
    section: PropTypes.shape({
        disableNumbering: PropTypes.bool,
        disableTitle: PropTypes.bool,
    }).isRequired,
};

export const SectionTitle = ({ isChild, section }) => {
    if (section.manualNumber) {
        return (
            <Box>
                {!section.disableNumbering && !section.disableTitle && (
                    <Typography variant="h4">{section.manualNumber}</Typography>
                )}

                <div>{section.title}</div>
                <TitleNumberingDisabledWarning section={section} />
            </Box>
        );
    }

    return (
        <>
            <Typography variant="h4">
                {section.numbering && !section.disableNumbering && !section.disableTitle && (
                    <>{section.numbering} </>
                )}
                <Box component="span" fontWeight={isChild ? 400 : 500}>
                    {section.title}
                </Box>
            </Typography>

            <TitleNumberingDisabledWarning section={section} />
        </>
    );
};

SectionTitle.propTypes = {
    isChild: PropTypes.bool,
    section: PropTypes.shape({
        disableNumbering: PropTypes.bool,
        disableTitle: PropTypes.bool,
        title: PropTypes.string,
        numbering: PropTypes.string,
        manualNumber: PropTypes.string,
        showValidationError: PropTypes.bool,
    }).isRequired,
};

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { ActionToolbarNavbarItem, Nav } from '../../../../components';
import { trackEvent } from '../../../../helpers';
import { CREATE_CONTRACT_FROM_PROJECT } from '../../../../constants/menuActions';

export class ContractToolbar extends PureComponent {
    static propTypes = {
        isContractAdmin: PropTypes.bool.isRequired,
        menuActionHandler: PropTypes.func.isRequired,
    };

    handleContractCreateClick = () => {
        const { menuActionHandler } = this.props;

        menuActionHandler(CREATE_CONTRACT_FROM_PROJECT);

        trackEvent('Create Contract Toolbar Click');
    };

    renderContractCreateItem() {
        const { isContractAdmin } = this.props;

        return (
            <ActionToolbarNavbarItem
                className="action-toolbar-add-contract-btn"
                onClick={isContractAdmin ? this.handleContractCreateClick : undefined}
                tooltip={isContractAdmin ? undefined : 'Only contract admins can add new contracts'}
            >
                <i className="fa fa-plus" /> Add Contract
            </ActionToolbarNavbarItem>
        );
    }

    render() {
        return <Nav>{this.renderContractCreateItem()}</Nav>;
    }
}

/* eslint-disable react/prop-types */

import { assign, get, omit } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FieldArray } from 'redux-form';

import { CDSButton, CDSButtonGroup, FormError, HelpToolTip, Main } from '../../../../components';
import { EvaluationPhases } from './components';
import { GuidedSectionButton } from '../components';
import { projectFormProps, evaluationPhaseFieldNames, fieldNames } from '../constants';
import { ListError } from '../../../../components/GovApp';
import { getMaxNumberFromList } from '../../../../utils';

const { EVALUATION_PHASES } = fieldNames;
const { ORDER_BY_ID, TITLE } = evaluationPhaseFieldNames;

export class EvaluationCriteria extends PureComponent {
    static propTypes = assign({}, projectFormProps, {
        change: PropTypes.func.isRequired,
        evaluationPhases: PropTypes.array.isRequired,
        isMulti: PropTypes.bool,
    });

    componentDidMount() {
        const { evaluationPhases, markBuilderSectionVisited } = this.props;

        if (evaluationPhases.length > 0) {
            markBuilderSectionVisited({
                hasIncludedItems: true,
                onClose: this.hideHelpModal,
            });
        }
    }

    formKey = EVALUATION_PHASES;

    get styles() {
        return require('./EvaluationCriteria.scss');
    }

    addEvaluationPhase = (data = {}) => {
        const { array, evaluationPhases, isMulti } = this.props;

        const orderById =
            data[ORDER_BY_ID] || getMaxNumberFromList(evaluationPhases, ORDER_BY_ID) + 1;

        // Add titles for multi-phase
        const title = isMulti ? data[TITLE] || `Phase ${orderById}` : undefined;

        const hasScoringCriteria = get(data, 'scoringCriteria.0');
        const scoringCriteria = (
            hasScoringCriteria ? data.scoringCriteria : [{ [ORDER_BY_ID]: 1 }]
        ).map((criteria) => {
            return omit(criteria, ['id', 'created_at', 'evaluation_phase_id']);
        });

        array.push(this.formKey, {
            orderById,
            scoringCriteria,
            title,
        });
    };

    hideHelpModal = () => {
        const { evaluationPhases, markBuilderSectionVisited } = this.props;

        const hasEvaluationPhases = evaluationPhases.length > 0;

        if (!hasEvaluationPhases) {
            this.addEvaluationPhase();
            markBuilderSectionVisited();
        }
    };

    showHelpModal = () => {
        const { showHelpModal } = this.props;

        showHelpModal({
            onClose: this.hideHelpModal,
        });
    };

    renderInitialEvaluationCriteria() {
        const { disabled, isOGThemeEnabledForComponents, isMulti, showFormErrors } = this.props;

        if (isOGThemeEnabledForComponents) {
            if (isMulti) {
                return this.renderAddEvaluationPhaseButton();
            }

            return (
                <CDSButtonGroup>
                    <CDSButton
                        onClick={this.showHelpModal}
                        qaTag="projectCreate-addEvaluationPhase"
                        variant="secondary-alt"
                    >
                        <i className="fa fa-plus" /> Start Evaluation Criteria
                    </CDSButton>
                </CDSButtonGroup>
            );
        }

        return (
            <div>
                <FieldArray
                    component={ListError}
                    name={this.formKey}
                    showError={!!showFormErrors}
                />
                <GuidedSectionButton
                    className={this.styles.initButton}
                    disabled={disabled}
                    info={
                        'A few standard items will be added. Please feel free to edit or remove ' +
                        'as needed.'
                    }
                    onClick={this.showHelpModal}
                    text="Start Evaluation Criteria"
                    type="required"
                />
            </div>
        );
    }

    renderAddEvaluationPhaseButton = () => {
        return (
            <CDSButtonGroup>
                <CDSButton
                    onClick={this.addEvaluationPhase}
                    qaTag="projectCreate-addEvaluationPhase"
                    variant="secondary-alt"
                >
                    <i className="fa fa-plus" /> Add Evaluation Phase
                </CDSButton>
            </CDSButtonGroup>
        );
    };

    renderEvaluationCriteria() {
        const {
            change,
            disabled,
            evaluationPhases,
            isMulti,
            isOGThemeEnabledForComponents,
            projectSection,
            showFormErrors,
        } = this.props;

        const evaluationPhaseNum = evaluationPhases.length;

        return (
            <div>
                {!isOGThemeEnabledForComponents && (
                    <>
                        <label className={this.styles.title}>
                            {projectSection.title}
                            <HelpToolTip
                                onClick={this.showHelpModal}
                                text="Click to see instructions"
                            />
                        </label>
                        <p>
                            Define the criteria and the weighting system that will be used to
                            evaluate the proposals
                        </p>
                        {isMulti && evaluationPhaseNum > 1 && (
                            <p className="text-primary">
                                <em>Project has {evaluationPhaseNum} evaluation phases</em>
                            </p>
                        )}
                    </>
                )}

                <FieldArray
                    change={change}
                    component={EvaluationPhases}
                    disabled={disabled}
                    isMulti={isMulti}
                    isOGThemeEnabledForComponents={isOGThemeEnabledForComponents}
                    name={this.formKey}
                    showValidation={showFormErrors}
                />
                {isMulti && !isOGThemeEnabledForComponents && (
                    <GuidedSectionButton
                        customTypeText="Optional"
                        disabled={disabled}
                        onClick={this.addEvaluationPhase}
                        text="Add Another Evaluation Phase"
                        type="optional"
                    />
                )}
                {isMulti && isOGThemeEnabledForComponents && this.renderAddEvaluationPhaseButton()}
            </div>
        );
    }

    render() {
        const { evaluationPhases, updateError } = this.props;

        const hasEvaluationPhases = evaluationPhases.length > 0;

        return (
            <Main className={this.styles.container}>
                <FormError error={updateError} />
                {hasEvaluationPhases
                    ? this.renderEvaluationCriteria()
                    : this.renderInitialEvaluationCriteria()}
            </Main>
        );
    }
}

import React from 'react';
import { Outlet } from 'react-router-dom';

import { ProjectDetailNav, ProjectSection } from '../../../containers';
import * as Gov from '../../../containers/GovApp';
import { legacyFetchData, requireIntakeSubscription } from '../../loaders';

export default (getState, dispatch) => {
    return [
        {
            element: <Outlet />,
            loader: requireIntakeSubscription(getState),
            path: 'intake',
            children: [
                {
                    element: <Gov.ProjectNav.Intake />,
                    loader: legacyFetchData(getState, dispatch, Gov.ProjectNav.Intake),
                    children: [
                        {
                            index: true,
                            element: <Gov.ProjectIntroOverview.Government />,
                        },
                        {
                            element: (
                                <ProjectDetailNav.Government>
                                    <ProjectSection.Government />
                                </ProjectDetailNav.Government>
                            ),
                            path: 'document',
                        },
                        {
                            element: <Gov.AttachmentsList />,
                            path: 'attachments',
                        },
                        {
                            element: <Gov.UpfrontQuestionsList />,
                            path: 'setup',
                        },
                        {
                            element: <Gov.Approvals.Intake />,
                            path: 'approvals',
                        },
                        {
                            element: <Gov.CommentsList />,
                            loader: legacyFetchData(getState, dispatch, Gov.CommentsList),
                            path: 'comments',
                        },
                        {
                            element: <Gov.Revisions />,
                            loader: legacyFetchData(getState, dispatch, Gov.Revisions),
                            path: 'revisions',
                            children: [
                                {
                                    element: <Gov.VersionHistory />,
                                    path: 'versions',
                                    children: [
                                        {
                                            index: true,
                                            element: <Gov.VersionsList />,
                                        },
                                        {
                                            element: <Gov.VersionsList />,
                                            path: 'list',
                                        },
                                        {
                                            element: <Gov.VersionCompare />,
                                            path: 'compare',
                                        },
                                        {
                                            element: <Gov.VersionDisplay />,
                                            loader: legacyFetchData(
                                                getState,
                                                dispatch,
                                                Gov.VersionDisplay
                                            ),
                                            path: ':versionId',
                                        },
                                    ],
                                },
                                {
                                    element: <Gov.RevisionHistory />,
                                    loader: legacyFetchData(
                                        getState,
                                        dispatch,
                                        Gov.RevisionHistory
                                    ),
                                    path: 'save-history',
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ];
};

import {
    MANUAL_NUMBER_ALLOWED_CHARS_REGEX,
    MANUAL_NUMBER_CHAR_LIMIT,
} from '@og-pro/shared-config/manualNumber';

import { REQUIRED_TEXT } from './constants';

/**
 * Validates a manual number.
 * @param {string} value The value to validate.
 * @param {object} opts Options for validation.
 * @param {boolean} opts.isSharedSection Whether it's a shared section.
 * @returns {string|null} The error message or null if valid.
 */
export function manualNumber(value, opts = {}) {
    if (!value && !opts.isSharedSection) {
        return REQUIRED_TEXT;
    }
    if (value) {
        if (!value.match(MANUAL_NUMBER_ALLOWED_CHARS_REGEX)) {
            return 'Numbering can only include spaces, commas, periods, dashes, & alphanumeric characters';
        }

        if (value.length > MANUAL_NUMBER_CHAR_LIMIT) {
            return 'Numbering is too long (15 character max)';
        }
    }

    return null;
}

import { checklistStatusTypes } from '@og-pro/shared-config/checklists';

const { COMPLETE, RELEASED } = checklistStatusTypes;

export const ALL = 'all';

export const checklistsFilters = [
    {
        icon: 'tasks',
        key: RELEASED,
        qaTagDescription: 'checklistsInProgress',
        text: 'In Progress',
    },
    {
        filter: COMPLETE,
        icon: 'check-circle',
        key: COMPLETE,
        qaTagDescription: 'checklistsComplete',
        text: 'Completed',
    },
    {
        filter: ALL,
        icon: 'list',
        key: ALL,
        qaTagDescription: 'checklistsAll',
        text: 'All',
    },
];

import { TITLE } from './constants';

export const validate = (values) => {
    const errors = {};

    if (!values[TITLE] || values[TITLE].trim().length === 0) {
        errors[TITLE] = 'This field is required';
    } else if (values[TITLE].length > 250) {
        errors[TITLE] = 'Title is too long';
    }

    return errors;
};

export const form = 'publicEvaluationToggleForm';

export const IS_PUBLIC = 'isPublic';
export const IS_CONSENSUS_PUBLIC = 'isConsensusPublic';

export const publishTooltip =
    'Toggling this option on will make the selected vendor publicly viewable';

export const publishConsensusTooltip =
    'Toggling this option on will make the Consensus Scorecard publicly viewable';

export const publishBidResultsTooltip =
    'Toggling this option on will publicly display the pricing tables from each response received.';

export const disabledPublishToolip = `${publishTooltip}. Only evaluation editors and admins can modify this option.`;

export const disabledConsensusToolip = `${publishConsensusTooltip}. Only evaluation editors and admins can modify this option.`;

export const disabledPublishBidResultsTooltip = `${publishTooltip}. Only evaluation editors and admins can modify this option.`;

import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';
import classNames from 'classnames';
import { isNil } from 'lodash';
import { isRequisitionSubmitted } from '@og-pro/shared-config/requisitions';

import { fieldNames } from '../../../constants';
import { requisitionsCreateFormValueSelector } from '../../../selectors';
import { useAccountBudgetCheck } from '../../../../../../../lib/ogFinancials';
import { formConfig } from '../../../form';
import { Button, Tooltip } from '../../../../../../../components';
import { BudgetCheckDetailsModal } from '../../../../../BudgetCheckDetails/BudgetCheckDetailsModal';
import {
    accountFieldNames,
    accountObjectFieldNames,
} from '../PriceItem/AccountSplit/AccountFields/AccountField/constants';
import { getRequisitionJS } from '../../../../../../../selectors/govApp';
import {
    budgetCheckStates,
    getAccountBudgetCheckStatus,
    hasBudgetStatus,
} from '../../../../../helpers/budgetCheck';
import { getTotalAmountUsedByAccountNumber } from '../helpers';

const { AMOUNT, ACCOUNT_NUMBER, AVAILABLE_BUDGET_AMOUNT, ACCOUNT_OBJECT, ACCOUNT_EXTERNAL_ID } =
    accountFieldNames;
const { ACCOUNT_COMPRESSED_FORMATTED, ACCOUNT_PSEUDO_KEY, IS_EXPENSE_ACCOUNT, HAS_ACCOUNT_GROUP } =
    accountObjectFieldNames;

const { PRICE_TABLE, PRICE_ITEMS, FISCAL_PERIOD_OBJECT, YEAR, FISCAL_PERIOD } = fieldNames;

const useAccountFieldUpdates = (field, fieldName, useHistoricalValues = false) => {
    const dispatch = useDispatch();

    const account = field[ACCOUNT_OBJECT];
    const fiscalPeriodYear = useSelector((state) =>
        requisitionsCreateFormValueSelector(state, `${FISCAL_PERIOD_OBJECT}.${YEAR}`)
    );
    const {
        data: budgetCheckData,
        isError: hasErrorFetchingAvailableBudget,
        isFetching: isFetchingAvailableBudget,
        refetch: refetchBudgetData,
    } = useAccountBudgetCheck(
        account?.[ACCOUNT_PSEUDO_KEY],
        fiscalPeriodYear,
        false,
        useHistoricalValues
    );

    const availableBudgetAmount = budgetCheckData?.availableAmountUsedForBudgetCheck;

    useEffect(() => {
        if (availableBudgetAmount && availableBudgetAmount !== field[AVAILABLE_BUDGET_AMOUNT]) {
            dispatch(
                change(
                    formConfig.form,
                    `${fieldName}.${AVAILABLE_BUDGET_AMOUNT}`,
                    availableBudgetAmount
                )
            );
        }
    }, [availableBudgetAmount, dispatch, fieldName, field[AVAILABLE_BUDGET_AMOUNT]]);

    useEffect(() => {
        if (account && !field[ACCOUNT_NUMBER]) {
            dispatch(
                change(formConfig.form, `${fieldName}.${ACCOUNT_NUMBER}`, account.accountCompressed)
            );
            dispatch(
                change(
                    formConfig.form,
                    `${fieldName}.${ACCOUNT_COMPRESSED_FORMATTED}`,
                    account.accountCompressedFormatted
                )
            );
        }
    }, [account]);

    useEffect(() => {
        if (!field[ACCOUNT_NUMBER] && field[ACCOUNT_OBJECT]?.[ACCOUNT_COMPRESSED_FORMATTED]) {
            dispatch(change(formConfig.form, `${fieldName}.${AVAILABLE_BUDGET_AMOUNT}`, undefined));
            dispatch(change(formConfig.form, `${fieldName}.${ACCOUNT_OBJECT}`, null));
            dispatch(change(formConfig.form, `${fieldName}.${ACCOUNT_EXTERNAL_ID}`, null));
        }
    }, [field[ACCOUNT_NUMBER]]);

    useEffect(() => {
        if (!budgetCheckData || isNil(budgetCheckData?.[HAS_ACCOUNT_GROUP])) {
            return;
        }

        dispatch(
            change(
                formConfig.form,
                `${fieldName}.${ACCOUNT_OBJECT}.${HAS_ACCOUNT_GROUP}`,
                Boolean(budgetCheckData[HAS_ACCOUNT_GROUP])
            )
        );
    }, [budgetCheckData]);

    const hasInvalidBudgetCheckData = account?.[ACCOUNT_PSEUDO_KEY] && !budgetCheckData;

    return {
        availableBudgetAmount: useHistoricalValues
            ? field[AVAILABLE_BUDGET_AMOUNT]
            : availableBudgetAmount,
        hasErrorFetchingAvailableBudget,
        isFetchingAvailableBudget,
        refetchBudgetData,
        hasInvalidBudgetCheckData,
        ...budgetCheckData,
    };
};

export const BudgetCheck = ({ field, fieldName, useHistoricalValues }) => {
    const styles = require('./index.scss');

    const {
        availableBudgetAmount,
        hasErrorFetchingAvailableBudget,
        hasInvalidBudgetCheckData,
        isFetchingAvailableBudget,
        refetchBudgetData,
        ...budgetCheckData
    } = useAccountFieldUpdates(field, fieldName, useHistoricalValues);

    const account = field[ACCOUNT_OBJECT];

    const requisition = useSelector(getRequisitionJS);

    useEffect(() => {
        if (requisition?.status) {
            refetchBudgetData?.();
        }
    }, [requisition.status]);

    const [showDetails, setShowDetails] = useState(false);

    const priceItems =
        useSelector((state) =>
            requisitionsCreateFormValueSelector(state, `${PRICE_TABLE}.${PRICE_ITEMS}`)
        ) || [];

    const fiscalPeriodYear = useSelector((state) =>
        requisitionsCreateFormValueSelector(state, `${FISCAL_PERIOD_OBJECT}.${YEAR}`)
    );

    const fiscalPeriod = useSelector((state) =>
        requisitionsCreateFormValueSelector(state, `${FISCAL_PERIOD}`)
    );

    const totalAmountUsedByAccountNumber = useMemo(() => {
        const accountNumber = field[ACCOUNT_NUMBER];
        return getTotalAmountUsedByAccountNumber(priceItems, accountNumber);
    }, [field, priceItems, field[ACCOUNT_NUMBER]]);

    const budgetCheckStatus = useMemo(() => {
        const amount = parseFloat(field[AMOUNT]);

        return getAccountBudgetCheckStatus(
            !!amount,
            totalAmountUsedByAccountNumber,
            availableBudgetAmount,
            account?.[IS_EXPENSE_ACCOUNT],
            isRequisitionSubmitted(requisition?.status)
        );
    }, [
        field[AMOUNT],
        availableBudgetAmount,
        account,
        requisition?.status,
        totalAmountUsedByAccountNumber,
    ]);

    if (isFetchingAvailableBudget) {
        return (
            <div className={styles.budgetChecking}>
                <i className="fa fa-fw fa-spinner fa-spin" /> Checking...
            </div>
        );
    }

    if (hasErrorFetchingAvailableBudget) {
        return <div className={styles.budgetCheck} />;
    }

    if (hasInvalidBudgetCheckData) {
        return (
            <div className={styles.budgetCheck}>
                <Tooltip
                    placement="top"
                    tooltip="An error occurred while fetching this account's budget check data. Please try again later or contact support."
                >
                    <span>Error</span>
                </Tooltip>
            </div>
        );
    }

    const renderBudgetCheck = () => {
        const renderAccountLevelTooltip = () => {
            if (!budgetCheckData || !budgetCheckData.fallbackToAccountBudgetLevel) {
                return null;
            }

            return (
                <Tooltip
                    placement="top"
                    tooltip="This budget check is on the account level since this account is not part of a group."
                >
                    <i className={classNames('fa fa-fw fa-user', styles.budgetCheckFallbackIcon)} />
                </Tooltip>
            );
        };

        switch (budgetCheckStatus) {
            case budgetCheckStates.NON_EXPENSE:
                return <span>N/A</span>;
            case budgetCheckStates.FAIL:
                return (
                    <>
                        <span className={styles.overBudget}>Fail</span>
                        {renderAccountLevelTooltip()}
                    </>
                );
            case budgetCheckStates.PASS:
                return (
                    <>
                        <span className={styles.onBudget}>Pass</span>
                        {renderAccountLevelTooltip()}
                    </>
                );
            default:
                return null;
        }
    };

    const showBudgetDetails = hasBudgetStatus(budgetCheckStatus);

    return (
        <div className={styles.budgetCheck}>
            <h5 className={styles.mobileLabel}>Budget Check</h5>
            {renderBudgetCheck()}
            {showBudgetDetails && (
                <>
                    <Button
                        bsStyle="link"
                        className={classNames('pull-right', styles.viewDetails)}
                        onClick={() => setShowDetails(true)}
                        qaTag="requisitionCreate-priceItem-account-viewDetails"
                    >
                        <span>View Details</span>
                    </Button>
                    <BudgetCheckDetailsModal
                        budgetCheck={{
                            availableAmount: availableBudgetAmount,
                            accountNumber: field[ACCOUNT_NUMBER],
                            accountExternalId: field[ACCOUNT_EXTERNAL_ID],
                            budgetCheckPass: budgetCheckStatus === budgetCheckStates.PASS,
                            fiscalPeriod,
                            fiscalYear: fiscalPeriodYear,
                            requestedAmount: totalAmountUsedByAccountNumber,
                            isExpenseAccount: account?.[IS_EXPENSE_ACCOUNT],
                            accountNumberCompressedFormatted:
                                account?.[ACCOUNT_COMPRESSED_FORMATTED],
                            ...budgetCheckData,
                        }}
                        onClose={() => setShowDetails(false)}
                        show={showDetails}
                    />
                </>
            )}
        </div>
    );
};

BudgetCheck.propTypes = {
    field: PropTypes.shape({
        accountPseudoKey: PropTypes.number.isRequired,
    }).isRequired,
    fieldName: PropTypes.string,
    useHistoricalValues: PropTypes.bool,
};

BudgetCheck.defaultProps = {
    useHistoricalValues: false,
};

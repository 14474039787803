import {
    Box,
    Button,
    ReduxFormTextField,
    Tooltip,
    Typography,
    ReduxFormRadioGroup,
} from '@og-pro/ui';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, change, getFormValues } from 'redux-form';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { useDispatch, useSelector } from 'react-redux';

import {
    createCustomFieldFormName,
    customFieldFields,
    fieldTypes,
    fieldTypesArray,
    instructionsModeArray,
    fieldStatusesDict,
} from '@og-pro/shared-config/customFormService/customField';

import { FieldTypeOptions } from '../FieldTypeOptions';
import { CreateCustomFieldContext } from '../context';

const { NAME, SHORT_TITLE, INSTRUCTIONS_TEXT, INSTRUCTIONS_MODE, STATUS } = customFieldFields;

const fieldsWithNoOptions = [fieldTypes.SHORT_TEXT, fieldTypes.YES_NO, fieldTypes.DATE];

export const CreateCustomFieldSettings = ({ fieldType }) => {
    const dispatch = useDispatch();
    const formValues =
        useSelector((state) => getFormValues(createCustomFieldFormName)(state)) || {};
    const [showShortTitleField, setShowShortTitleField] = useState(!!formValues[SHORT_TITLE]);
    const { showFormValidation } = useContext(CreateCustomFieldContext);

    const showOptions = !fieldsWithNoOptions.includes(fieldType);

    function handleAddShortTitleClick() {
        setShowShortTitleField(true);
    }

    function handleRemoveShortTitleClick() {
        dispatch(change(createCustomFieldFormName, SHORT_TITLE, null));
        setShowShortTitleField(false);
    }

    const disabled = formValues[STATUS] === fieldStatusesDict.ARCHIVED;
    return (
        <Box
            component="form"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
            }}
        >
            <Field
                component={ReduxFormTextField}
                disabled={disabled}
                label="Question / Title *"
                name={NAME}
                showValidation={showFormValidation}
            />
            {showShortTitleField ? (
                <Box sx={{ display: 'flex', width: '100%' }}>
                    <Field
                        characterLimit={100}
                        component={ReduxFormTextField}
                        description="Shortened description that will show up us a column header in a report."
                        disabled={disabled}
                        label="Short Title"
                        name={SHORT_TITLE}
                        sx={{ width: '100%' }}
                    />
                    <Box sx={{ height: '100%', alignItems: 'end', display: 'flex' }}>
                        <Button
                            color="secondary"
                            onClick={handleRemoveShortTitleClick}
                            sx={{
                                paddingTop: 0.5,
                                paddingBottom: 0.5,
                                paddingLeft: 1,
                                paddingRight: 1,
                            }}
                            variant="text"
                        >
                            <DeleteIcon fontSize="small" />
                        </Button>
                    </Box>
                </Box>
            ) : (
                <Box sx={{ display: 'flex', gap: 1, width: '100%' }}>
                    <Button
                        color="secondary"
                        onClick={handleAddShortTitleClick}
                        startIcon={<AddIcon />}
                        sx={{ padding: 0 }}
                        variant="text"
                    >
                        Add Short Title
                    </Button>
                    <Tooltip title="The short title will show up in the report as a column header in the report">
                        <InfoOutlinedIcon
                            color="secondary"
                            fontSize="small"
                            sx={{ height: '100%' }}
                        />
                    </Tooltip>
                </Box>
            )}
            <Box>
                <Box sx={{ mb: 1 }}>
                    <Field
                        component={ReduxFormTextField}
                        description="Optional secondary information intended to guide users on how to answer the question."
                        disabled={disabled}
                        label="Instructions"
                        name={INSTRUCTIONS_TEXT}
                        showValidation={showFormValidation}
                        sx={{ width: '100%' }}
                    />
                </Box>
                <Box>
                    <Field
                        component={ReduxFormRadioGroup}
                        defaultSelected
                        disabled={disabled}
                        inline
                        label="Show Instructions As:"
                        name={INSTRUCTIONS_MODE}
                        options={instructionsModeArray}
                        showValidation={showFormValidation}
                    />
                </Box>
            </Box>
            {showOptions && (
                <Box display="flex" flexDirection="column" gap={2} pt={1}>
                    <Typography variant="h3">Response Options</Typography>
                    <FieldTypeOptions disabled={disabled} fieldType={fieldType} />
                </Box>
            )}
        </Box>
    );
};

CreateCustomFieldSettings.propTypes = {
    fieldType: PropTypes.oneOf(fieldTypesArray).isRequired,
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { destroy, getFormValues } from 'redux-form';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { form, helpText } from './constants';
import { getDeserializedProposal, isUploading } from './selectors';
import {
    getEvaluationPath,
    getPostingPath,
    getProjectJS,
    getProposalDocuments,
    getProposalJS,
} from '../../selectors';
import {
    showConfirmationModal,
    showConfirmationSimpleModal,
} from '../../../../actions/confirmation';
import {
    createProposalAttachment,
    deleteProposal,
    deleteProposalAttachment,
    submitProposal,
    updateProposal,
} from '../../../../actions/govProposals';
import { BackButton, ProposalCreateForm } from '../../../../components';
import { NO_BID_GOV_PROPOSAL } from '../../../../constants/menuActions';

const mapStateToProps = (state) => {
    return {
        formValues: getFormValues(form)(state) || {},
        project: getProjectJS(state),
        proposal: getDeserializedProposal(state),
        proposalDocuments: getProposalDocuments(state),
        proposalFormData: getProposalJS(state),
        updateError: state.govProposals.get('updateError'),
        updating: state.govProposals.get('updating'),
        uploading: isUploading(state),
    };
};

const mapDispatchToProps = {
    createProposalAttachment,
    deleteProposal,
    deleteProposalAttachment,
    destroy,
    showConfirmationModal,
    showConfirmationSimpleModal,
    submitProposal,
    updateProposal,
};

// @connect
class ConnectedGovernmentProposalCreate extends Component {
    static propTypes = {
        createProposalAttachment: PropTypes.func.isRequired,
        deleteProposal: PropTypes.func.isRequired,
        deleteProposalAttachment: PropTypes.func.isRequired,
        destroy: PropTypes.func.isRequired,
        formValues: PropTypes.object.isRequired,
        project: PropTypes.object.isRequired,
        proposal: PropTypes.object,
        proposalDocuments: PropTypes.array.isRequired,
        proposalFormData: PropTypes.object,
        proposalsPath: PropTypes.string.isRequired,
        showConfirmationModal: PropTypes.func.isRequired,
        showConfirmationSimpleModal: PropTypes.func.isRequired,
        submitProposal: PropTypes.func.isRequired,
        updateError: PropTypes.string,
        updateProposal: PropTypes.func.isRequired, // Required from @connect above
        updating: PropTypes.bool.isRequired, // Required from @connect above
        uploading: PropTypes.bool.isRequired, // Required from @connect above
    };

    componentWillUnmount() {
        this.props.destroy(form);
    }

    deleteProposal = () => {
        const { proposal, proposalsPath } = this.props;

        this.props.showConfirmationSimpleModal(() =>
            this.props.deleteProposal(proposal, proposalsPath)
        );
    };

    handleAttachmentDelete = (attachId, formKey, index) => {
        const data = { form, formKey, index };
        return this.props.deleteProposalAttachment(this.props.proposal, data, attachId);
    };

    handleSave = () => {
        const { formValues, proposal } = this.props;

        // `ProposalCreateForm` requires a promise be returned
        return this.props.updateProposal(proposal.id, formValues, {
            notify: true,
        });
    };

    handleSubmit = (data) => {
        const { proposal, proposalsPath } = this.props;

        const nextRoute = `${proposalsPath}/${proposal.id}?addAnother=true`;
        return this.props.submitProposal(proposal, data, nextRoute);
    };

    handleAttachmentUpload = (proposalDocumentId, formKey, files) => {
        const data = { proposalDocumentId, formKey, form };
        files.forEach((file) => {
            return this.props.createProposalAttachment(this.props.proposal, data, file);
        });
    };

    noBidHandler = () => {
        const { proposal, proposalsPath } = this.props;

        this.props.showConfirmationModal(NO_BID_GOV_PROPOSAL, {
            nextRoute: `${proposalsPath}/${proposal.id}`,
            proposal,
        });
    };

    render() {
        const {
            project,
            proposal,
            proposalDocuments,
            proposalFormData,
            proposalsPath,
            updateError,
            updating,
            uploading,
        } = this.props;

        if (!proposal || !proposalFormData) {
            return null;
        }

        return (
            <>
                <BackButton className="pull-left" text="Back to Responses" to={proposalsPath} />
                <ProposalCreateForm
                    allowSubmissionAfterDeadline
                    allowSubmissionDateInput
                    deleteAttachment={this.handleAttachmentDelete}
                    deleteProposal={this.deleteProposal}
                    form={form}
                    noBidProposal={this.noBidHandler}
                    onSave={this.handleSave}
                    onSubmit={this.handleSubmit}
                    project={project}
                    proposalDocuments={proposalDocuments}
                    proposalFormData={proposalFormData}
                    skipCompanyProfile
                    skipQuestionnaireValidation
                    title="Manual Response Creation"
                    titleHelp={helpText}
                    updateError={updateError}
                    updating={updating}
                    uploadHandler={this.handleAttachmentUpload}
                    uploading={uploading}
                />
            </>
        );
    }
}

export const GovernmentProposalCreate = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedGovernmentProposalCreate);

// Government sourcing container
GovernmentProposalCreate.Sourcing = compose(
    withRouter,
    connect((state, props) => {
        return {
            proposalsPath: `${getPostingPath(state, props)}/proposals`,
        };
    })
)(GovernmentProposalCreate);

// Government evaluation container
GovernmentProposalCreate.Evaluation = compose(
    withRouter,
    connect((state, props) => {
        return {
            proposalsPath: `${getEvaluationPath(state, props)}/proposals`,
        };
    })
)(GovernmentProposalCreate);

import { fieldNames } from './constants';
import { MAX_TEXT_AREA_LENGTH } from '../../../../Forms/validation';

const { RAW_DESCRIPTION, TITLE } = fieldNames;

export const validate = (values, props) => {
    const errors = {};
    if (!values[TITLE] && !props.isTextArea) {
        errors[TITLE] = 'Please enter a title for the item or remove it';
    } else if (values[TITLE] && values[TITLE].length >= 200) {
        errors[TITLE] = 'This field is too long';
    }

    if (!values[RAW_DESCRIPTION]) {
        errors[RAW_DESCRIPTION] = 'Please enter a description for the item or remove it';
    } else if (values[RAW_DESCRIPTION].length >= MAX_TEXT_AREA_LENGTH) {
        errors[RAW_DESCRIPTION] = 'This field is too long';
    }

    return errors;
};

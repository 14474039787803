import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { getSDv2WritingSections } from '../../../selectors';
import { NavScrollContainer, SDv2EditorLayout } from '../../../../../../components/SDv2';
import {
    ProjectCreateV2DocumentEditorNav,
    ProjectCreateV2DocumentEditorBackNextButtons,
    ProjectCreateV2DocumentEditorSectionHeader,
    ProjectCreateManageSectionsActionHeader,
} from '../../components';
import { ProjectCreateV2NavContext } from '../../context';
import { SECTIONS_WITH_TINYMCE_TOOLBAR } from '../../constants';
import { ProjectCreateV2DocumentEditorSectionsAdapter } from './SDv2SectionsAdapter';

export const ProjectCreateV2DocumentEditor = () => {
    const projectSections = useSelector(getSDv2WritingSections);
    const { activeSectionId } = useContext(ProjectCreateV2NavContext);

    const selectedSection = projectSections[activeSectionId];

    return (
        <SDv2EditorLayout
            content={
                <div>
                    <ProjectCreateV2DocumentEditorSectionsAdapter />
                </div>
            }
            footer={
                <div>
                    <ProjectCreateV2DocumentEditorBackNextButtons />
                </div>
            }
            header={
                <div>
                    <ProjectCreateV2DocumentEditorSectionHeader />
                </div>
            }
            navigation={
                <div>
                    <ProjectCreateManageSectionsActionHeader />
                    <NavScrollContainer>
                        <ProjectCreateV2DocumentEditorNav />
                    </NavScrollContainer>
                </div>
            }
            showSharedTextareaToolbar={SECTIONS_WITH_TINYMCE_TOOLBAR.includes(
                selectedSection?.section_type
            )}
        />
    );
};

import React from 'react';
import { Outlet } from 'react-router-dom';

import * as Gov from '../../../containers/GovApp';
import { requireMarketplaceSubscription } from '../../loaders';

export default (getState) => {
    return [
        {
            element: <Outlet />,
            loader: requireMarketplaceSubscription(getState),
            path: 'sourcing/create',
            children: [
                {
                    element: <Gov.ProjectPostCreate />,
                    children: [
                        {
                            element: <Gov.ProjectPostCreateIndex />,
                            index: true,
                        },
                        {
                            element: <Gov.BasicProjectOverview />,
                            path: 'overview',
                        },
                        {
                            element: <Gov.PostQuestionnaire />,
                            path: 'questionnaire',
                        },
                        {
                            element: <Gov.ProjectProposalDocuments />,
                            path: 'proposals',
                        },
                        {
                            element: <Gov.ProjectTimeline />,
                            path: 'timeline',
                        },
                    ],
                },
            ],
        },
    ];
};

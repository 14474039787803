import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { Button } from '../../../../../components';
import { getFileExtensionIcon } from '../../../../../helpers';

class ConnectedProposalAttachmentItem extends PureComponent {
    static propTypes = {
        deleteError: PropTypes.string,
        deleteHandler: PropTypes.func,
        documentPath: PropTypes.string.isRequired,
        fileExtension: PropTypes.string,
        filename: PropTypes.string.isRequired,
        isEditor: PropTypes.bool,
        isProposalEvaluation: PropTypes.bool,
        isSealed: PropTypes.bool,
        router: PropTypes.object.isRequired,
        uploadedAt: PropTypes.string,
        uploadedBy: PropTypes.string,
        url: PropTypes.string.isRequired,
    };

    get styles() {
        return require('../index.scss');
    }

    handleFilenameClick = () => {
        const { documentPath, router } = this.props;

        router.push(documentPath);
    };

    render() {
        const {
            deleteError,
            deleteHandler,
            documentPath,
            fileExtension,
            filename,
            isEditor,
            isProposalEvaluation,
            isSealed,
            uploadedAt,
            uploadedBy,
            url,
        } = this.props;

        if (isSealed) {
            return (
                <li className={this.styles.sectionItem}>
                    <i className={`fa fa-fw fa-lock ${this.styles.icon}`} />
                    <em>Pricing information has not been unsealed yet</em>
                </li>
            );
        }

        const icon = getFileExtensionIcon(fileExtension);
        return (
            <li className={this.styles.sectionItem}>
                <div className="pull-right">
                    <Button bsSize="xs" className={this.styles.attachmentButton} to={documentPath}>
                        <i className="fa fa-eye" /> View
                    </Button>
                    <Button bsSize="xs" className={this.styles.attachmentButton} href={url}>
                        <i className="fa fa-download" /> Download
                    </Button>
                    {deleteHandler && isEditor && !isProposalEvaluation && (
                        <Button
                            bsSize="xs"
                            className={this.styles.attachmentButton}
                            onClick={deleteHandler}
                        >
                            <i className="fa fa-trash" /> Delete
                        </Button>
                    )}
                </div>
                <span className="pseudoLink" onClick={this.handleFilenameClick}>
                    <i className={`fa fa-fw fa-${icon} ${this.styles.icon}`} />
                    {filename}
                </span>
                {uploadedAt && (
                    <div className={this.styles.createdInfo}>
                        Uploaded at {uploadedAt} by {uploadedBy}
                    </div>
                )}
                {deleteError && <div className="error-block">Delete Failed: {deleteError}</div>}
            </li>
        );
    }
}

export const ProposalAttachmentItem = withRouter(ConnectedProposalAttachmentItem);

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Comment } from './Comment';
import { CommentReopen } from './CommentReopen';
import { CommentReply } from './CommentReply';
import { CommentFollowers } from './Followers';

export class CommentThread extends PureComponent {
    static propTypes = {
        canComment: PropTypes.bool.isRequired,
        threadId: PropTypes.number.isRequired,
        comments: PropTypes.array.isRequired,
        user: PropTypes.object.isRequired,
        users: PropTypes.array.isRequired,
        followers: PropTypes.array.isRequired,
        selectedCommentId: PropTypes.number,
        resolved: PropTypes.bool,
        resolvedAt: PropTypes.string,
        resolvedBy: PropTypes.string,
        resolving: PropTypes.bool,
        replyError: PropTypes.string,
        resolveError: PropTypes.string,
        addFollowersError: PropTypes.string,
        show: PropTypes.bool,
        showFollowersForm: PropTypes.bool,
        replyThreadHandler: PropTypes.func.isRequired,
        resolveThreadHandler: PropTypes.func.isRequired,
        reopenThreadHandler: PropTypes.func.isRequired,
        addFollowersHandler: PropTypes.func.isRequired,
        showFollowersFormHandler: PropTypes.func.isRequired,
        hideFollowersFormHandler: PropTypes.func.isRequired,
    };

    render() {
        const {
            addFollowersError,
            addFollowersHandler,
            canComment,
            comments,
            followers,
            hideFollowersFormHandler,
            reopenThreadHandler,
            replyError,
            replyThreadHandler,
            resolved,
            resolvedAt,
            resolvedBy,
            resolveError,
            resolveThreadHandler,
            resolving,
            selectedCommentId,
            show,
            showFollowersForm,
            showFollowersFormHandler,
            threadId,
            user,
            users,
        } = this.props;

        if (!show) {
            return null;
        }

        const styles = require('./CommentThread.scss');
        const resolveStyle = resolved ? styles.resolvedThread : null;

        const Comments = comments.map((comment) => (
            <div id={`thread-comment-${comment.id}`} key={comment.id}>
                <Comment
                    comment={comment.description}
                    commentDate={comment.created_at}
                    isSelected={comment.id === selectedCommentId}
                    user={comment.user}
                />
            </div>
        ));

        const CommentResponse = resolved ? (
            <CommentReopen
                canComment={canComment}
                reopenClickHandler={reopenThreadHandler}
                resolveError={resolveError}
                resolvedAt={resolvedAt}
                resolvedBy={resolvedBy}
                resolving={resolving}
                threadId={threadId}
            />
        ) : (
            <CommentReply
                canComment={canComment}
                replyError={replyError}
                replySubmitHandler={replyThreadHandler}
                resolveClickHandler={resolveThreadHandler}
                resolveError={resolveError}
                resolving={resolving}
                threadId={threadId}
                user={user}
            />
        );

        return (
            <div className={styles.threadContainer}>
                <div className={`${styles.threadComments} ${resolveStyle}`}>{Comments}</div>
                {CommentResponse}
                <CommentFollowers
                    addFollowersError={addFollowersError}
                    addFollowersHandler={addFollowersHandler}
                    canComment={canComment}
                    followers={followers}
                    hideFollowersFormHandler={hideFollowersFormHandler}
                    showFollowersFormHandler={showFollowersFormHandler}
                    showForm={showFollowersForm}
                    threadId={threadId}
                    users={users}
                />
            </div>
        );
    }
}

export const SEARCH_RESULTS_LIMIT = 10;
export const DEFAULT_SORT_FIELD = 'releaseProjectDate';
export const DEFAULT_SORT_DIRECTION = 'desc';

export const SET_SEARCH_VALUE = 'projectLibrary/SET_SEARCH_VALUE';

export function setSearchValue(currentSearch) {
    return { type: SET_SEARCH_VALUE, currentSearch };
}

export const SEARCH = 'projectLibrary/SEARCH';
export const SEARCH_SUCCESS = 'projectLibrary/SEARCH_SUCCESS';
export const SEARCH_FAIL = 'projectLibrary/SEARCH_FAIL';

export function searchLibrary() {
    return (dispatch, getState, client) => {
        const library = getState().projectLibrary;

        // Request body
        const summarySearch = (library.get('searchValue') || '').trim() || null;
        const categories = library
            .get('categories')
            .toJS()
            .map((cat) => cat.id);
        const data = {
            categories,
            summarySearch,
            isPublished: true,
        };

        // Query params
        const limit = SEARCH_RESULTS_LIMIT;
        const page = library.get('paginationPage');
        const sort = library.get('sortField');
        const direction = library.get('sortDirection');
        const params = { limit, page, sort, direction };

        dispatch({ type: SEARCH });
        dispatch(setSearchValue(summarySearch));
        return client
            .post('/project/search', { data, params })
            .then(({ projects, count }) => {
                dispatch({ type: SEARCH_SUCCESS, projects, count });
            })
            .catch((error) => {
                dispatch({ type: SEARCH_FAIL, error });
            });
    };
}

export const LOAD_PROJECT = 'projectLibrary/LOAD_PROJECT';
export const LOAD_PROJECT_SUCCESS = 'projectLibrary/LOAD_PROJECT_SUCCESS';
export const LOAD_PROJECT_FAIL = 'projectLibrary/LOAD_PROJECT_FAIL';

export function loadProject(projectId) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_PROJECT });
        return client
            .get(`/project/${projectId}`)
            .then((result) => {
                result.criteria.forEach((crit) => {
                    crit.selected = true;
                });
                dispatch({ type: LOAD_PROJECT_SUCCESS, result });
            })
            .catch((error) => {
                dispatch({ type: LOAD_PROJECT_FAIL, error });
            });
    };
}

export const SELECTED_CATEGORIES = 'projectLibrary/SELECTED_CATEGORIES';

export function selectCategories(categories) {
    return { type: SELECTED_CATEGORIES, categories };
}

export const CHANGE_PAGE = 'projectLibrary/CHANGE_PAGE';

export function changePage(page) {
    return { type: CHANGE_PAGE, page };
}

export const SHOW_MODAL = 'projectLibrary/SHOW_MODAL';
export const RESET_LIBRARY = 'projectLibrary/RESET_LIBRARY';

export function showModal(copyFunction, opts = {}) {
    return (dispatch) => {
        dispatch({ type: SHOW_MODAL, copyFunction });
        if (opts.categories) {
            // Upon opening of modal performs a search with the specified categories
            dispatch(selectCategories(opts.categories));
            dispatch(searchLibrary());
        } else if (opts.projectId) {
            // Upon opening of modal loads a project and navigates directly to the scope select page
            dispatch(loadProject(opts.projectId));
            dispatch(changePage(2));
        }
    };
}

export function resetLibrary() {
    return { type: RESET_LIBRARY };
}

export const TOGGLE_SCOPE_ITEM = 'projectLibrary/TOGGLE_SCOPE_ITEM';

export function toggleScopeItem(criteriaId) {
    return { type: TOGGLE_SCOPE_ITEM, criteriaId };
}

export const SELECT_ALL = 'projectLibrary/SELECT_ALL';

export function selectAllScope(selected = true) {
    return { type: SELECT_ALL, selected };
}

export const PAGINATION_SELECT = 'projectLibrary/PAGINATION_SELECT';

export function pageResults(paginationPage) {
    return { type: PAGINATION_SELECT, paginationPage };
}

export function incrementCopyCount() {
    return (dispatch, getState, client) => {
        const projectId = getState().projectLibrary.getIn(['selectedProject', 'id']);
        return client.put(`/project/${projectId}/copy-count`);
    };
}

export const SORT_SELECT = 'projectLibrary/SORT_SELECT';
export const TOGGLE_SORT_DIRECTION = 'projectLibrary/TOGGLE_SORT_DIRECTION';
export const CHANGE_SEARCH_FIELD = 'projectLibrary/CHANGE_SEARCH_FIELD';

export function selectSort(sortField) {
    return { type: SORT_SELECT, sortField };
}

export function toggleSortDirection() {
    return { type: TOGGLE_SORT_DIRECTION };
}

export function updateSearchField(searchValue) {
    return { type: CHANGE_SEARCH_FIELD, searchValue };
}

import React, { Component } from 'react';

export class BostonBanner extends Component {
    render() {
        const styles = require('./BostonBanner.scss');

        return (
            <div className={styles.banner}>
                <div className={styles.leftImage}>
                    <img
                        alt="City of Boston in lack text, and Boston has a red underscore"
                        src="https://assets.procurement.opengov.com/logos/banner-boston-left.png"
                    />
                </div>
                <div className={styles.rightImage}>
                    <img
                        alt="Boston.gov in red text with the Boston B to the right in white text, on a blue field with a red underscore"
                        src="https://assets.procurement.opengov.com/logos/banner-boston-right.png"
                    />
                </div>
                <div className={styles.centerImage}>
                    <img
                        alt="An official website of the Boston city government"
                        src="https://assets.procurement.opengov.com/logos/banner-boston-center.png"
                    />
                </div>
            </div>
        );
    }
}

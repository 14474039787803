import { createSelector } from 'reselect';

const getTemplateChecklists = (state) => state.checklistsAdmin.get('templateChecklists');
const getTemplateChecklist = (state) => state.checklistsAdmin.get('templateChecklist');

export const getChecklistsAdminPath = (_state, props) => {
    const { governmentId } = props.params;
    return `/governments/${governmentId}/checklists-admin`;
};

export const getTemplateChecklistsJS = createSelector(
    [getTemplateChecklists],
    (rawTemplateChecklists) => rawTemplateChecklists.toJS()
);

export const getTemplateChecklistJS = createSelector(
    [getTemplateChecklist],
    (rawTemplateChecklist) => {
        if (rawTemplateChecklist) {
            return rawTemplateChecklist.toJS();
        }
    }
);

export const getTemplateChecklistAdminPath = (_state, props) => {
    const { governmentId } = props.params;
    return `/governments/${governmentId}/checklists-admin`;
};

export const LOAD = 'global_editor_dashboard/LOAD';
export const LOAD_EVENTS = 'global_editor_dashboard/LOAD_EVENTS';
export const LOAD_FAIL = 'global_editor_dashboard/LOAD_FAIL';
export const LOAD_EVENTS_FAIL = 'global_editor_dashboard/LOAD_EVENTS_FAIL';
export const LOAD_SUCCESS = 'global_editor_dashboard/LOAD_SUCCESS';
export const LOAD_EVENTS_SUCCESS = 'global_editor_dashboard/LOAD_EVENTS_SUCCESS';

export function loadDashboard(queryParams) {
    return (dispatch, getState, client) => {
        const govId = getState().auth.getIn(['user', 'government_id']);
        dispatch({ type: LOAD });
        return client
            .get(`/government/${govId}/dashboard`, { params: queryParams })
            .then((result) => {
                dispatch({ type: LOAD_SUCCESS, result });
            })
            .catch((error) => {
                dispatch({ type: LOAD_FAIL, error });
            });
    };
}

export function loadEvents(data) {
    return (dispatch, getState, client) => {
        const govId = getState().auth.getIn(['user', 'government_id']);
        dispatch({ type: LOAD_EVENTS });
        return client
            .post(`/government/${govId}/calendar`, { data })
            .then((result) => {
                dispatch({ type: LOAD_EVENTS_SUCCESS, result });
            })
            .catch((error) => {
                dispatch({ type: LOAD_EVENTS_FAIL, error });
            });
    };
}

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';

import { bidBondQuestionnaireResponseFieldNamesDict } from '@og-pro/shared-config/questionnaires';

import { CDSButton, CopyToClipboardIcon, InputText } from '../../../..';
import { VerifyBidBondButton } from '../VerifyBidBondButton';
import { govForm, vendorForm } from '../constants';
import { getUserJS } from '../../../../../containers/selectors';
import { getBidBondIdFormData, getBidBondVendorIdFormData } from '../selectors';

const { BID_BOND_ID, BID_BOND_VENDOR_ID } = bidBondQuestionnaireResponseFieldNamesDict;

const mapStateToProps = (state, props) => {
    const {
        input: { name: fieldName },
    } = props;
    const user = getUserJS(state);
    const form = user.isVendor ? vendorForm : govForm;

    return {
        bidBondIdFormData: getBidBondIdFormData(form, state, `${fieldName}.${BID_BOND_ID}`) || '',
        bidBondVendorIdFormData:
            getBidBondVendorIdFormData(form, state, `${fieldName}.${BID_BOND_VENDOR_ID}`) || '',
    };
};

// @connect
const ConnectedBidBondResponseForm = ({
    bidBondIdFormData,
    bidBondVendorIdFormData,
    data = {},
    disabled,
    id: questionnaireId,
    input: { name: fieldName },
    isOGThemeEnabledForComponents,
    proposalId,
    showValidation,
}) => {
    const styles = require('../styles.scss');
    const [verificationFailed, setVerificationFailed] = useState(false);
    const { bidBondProjectId, bidBondSecurity, bidBondSecurityIsPercentage } = data || {};
    const warn = () =>
        'Verification Failed. This will not prevent response submission, but you may want to try again.';

    return (
        <>
            <Field
                component={InputText}
                disabled={disabled}
                help="Please enter your Bond Number from the bid bond"
                label="Bond Number"
                name={`${fieldName}.${BID_BOND_ID}`}
                placeholder={isOGThemeEnabledForComponents ? null : 'Enter information'}
                showValidation={verificationFailed || showValidation}
                type="text"
                useOpenGovStyle={isOGThemeEnabledForComponents}
                warn={verificationFailed ? warn : undefined}
            />
            <Field
                component={InputText}
                disabled={disabled}
                help="Please enter your Contractor Vendor ID from the bid bond"
                label="Contractor Vendor ID"
                name={`${fieldName}.${BID_BOND_VENDOR_ID}`}
                placeholder={isOGThemeEnabledForComponents ? null : 'Enter information'}
                showValidation={verificationFailed || showValidation}
                type="text"
                useOpenGovStyle={isOGThemeEnabledForComponents}
                warn={verificationFailed ? warn : undefined}
            />
            {isOGThemeEnabledForComponents && disabled && (
                <CDSButton
                    disabled
                    qaTag="questionnaireBidBond-verify"
                    size="small"
                    variant="secondary"
                >
                    <i className="fa fa-external-link-square" /> Verify Bid Bond
                </CDSButton>
            )}
            {!isOGThemeEnabledForComponents && (
                <VerifyBidBondButton
                    bidBondIdData={bidBondIdFormData}
                    bidBondVendorIdData={bidBondVendorIdFormData}
                    proposalId={proposalId}
                    questionnaireId={questionnaireId}
                    setVerificationFailed={setVerificationFailed}
                    updateResponse
                />
            )}
            {!isOGThemeEnabledForComponents && (
                <div className={styles.loginBlurb}>
                    <i>
                        Don&apos;t have a bid bond yet? Login or register at&nbsp;
                        <a
                            href="https://dashboard.surety2000.com/#/register?refid=opengov"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            Surety2000
                        </a>
                        &nbsp;to create one using the following information for this project:
                    </i>
                    <br />
                    <strong>Project ID: </strong>
                    {bidBondProjectId}
                    &nbsp;&nbsp;
                    <CopyToClipboardIcon value={bidBondProjectId} />
                    <span className={styles.bidSecuritySpan}>
                        <strong>Bid Security: </strong>
                        {`${bidBondSecurityIsPercentage ? '' : '$'}${bidBondSecurity}${
                            bidBondSecurityIsPercentage ? '%' : ''
                        }`}
                        &nbsp;&nbsp;
                        <CopyToClipboardIcon value={bidBondSecurity} />
                    </span>
                </div>
            )}
        </>
    );
};

ConnectedBidBondResponseForm.propTypes = {
    bidBondIdFormData: PropTypes.string.isRequired,
    bidBondVendorIdFormData: PropTypes.string.isRequired,
    data: PropTypes.shape({
        bidBondProjectId: PropTypes.string.isRequired,
        bidBondSecurity: PropTypes.string.isRequired,
        bidBondSecurityIsPercentage: PropTypes.bool.isRequired,
    }).isRequired,
    disabled: PropTypes.bool,
    id: PropTypes.number.isRequired,
    input: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }).isRequired,
    isOGThemeEnabledForComponents: PropTypes.bool,
    proposalId: PropTypes.number.isRequired,
    showValidation: PropTypes.bool,
};

export const BidBondResponseForm = connect(mapStateToProps)(ConnectedBidBondResponseForm);

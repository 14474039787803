import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { LibrarySortForm } from './LibrarySortForm';
import { SORT_FIELD } from './LibrarySortForm/constants';
import { ProjectListItem } from './ProjectListItem';
import { SEARCH_RESULTS_LIMIT } from '../../../../../actions/projectLibrary';
import { Button, LoadingSpinner, LoadingError, Pagination } from '../../../../../components';
import { stripHtml } from '../../../../../utils';

const SORT_OPTIONS = [
    { label: 'Date', value: 'releaseProjectDate', sortIcon: 'numeric' },
    { label: 'Title', value: 'title', sortIcon: 'alpha' },
    { label: 'Copies', value: 'copyCount', sortIcon: 'numeric' },
    { label: 'City', value: 'government.organization.city', sortIcon: 'alpha' },
];

const SORT_DIRECTION_MAP = {
    asc: 'Ascending',
    desc: 'Descending',
};

export class ProjectList extends PureComponent {
    static propTypes = {
        projects: PropTypes.array.isRequired,
        loading: PropTypes.bool.isRequired,
        projectsCount: PropTypes.number,
        paginationPage: PropTypes.number.isRequired,
        sortField: PropTypes.string.isRequired,
        sortDirection: PropTypes.string.isRequired,
        hasSearchSelection: PropTypes.bool.isRequired,
        currentSearch: PropTypes.string,
        error: PropTypes.string,
        clickHander: PropTypes.func.isRequired,
        pageResults: PropTypes.func.isRequired,
        sortHandler: PropTypes.func.isRequired,
        sortDirectionHandler: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        const icon = SORT_OPTIONS.find((opt) => opt.value === props.sortField);
        this.state = {
            sortIcon: icon ? icon.sortIcon : 'amount',
        };
    }

    get paginationPages() {
        return Math.ceil(this.props.projectsCount / SEARCH_RESULTS_LIMIT);
    }

    get projectsFound() {
        const { paginationPage, projectsCount } = this.props;
        if (paginationPage === 1) return `${projectsCount} Projects Found`;
        return `Page ${paginationPage} of ${projectsCount} Projects`;
    }

    onSortByChange = (opt) => {
        this.setState({ sortIcon: opt.sortIcon });
        return this.props.sortHandler(opt.value);
    };

    renderNoSearch() {
        const styles = require('./ProjectList.scss');
        return (
            <div className={styles.noSearch}>
                <h4 className="text-primary">Welcome to the OpenGov Project Library</h4>
                <div>
                    <i className="fa fa-search fa-2x" />
                </div>
                Please select a category from the list on the left or enter a search keyword to
                start searching projects.
            </div>
        );
    }

    renderPagination() {
        if (this.props.projectsCount <= this.props.projects.length) return null;

        const { paginationPage, pageResults } = this.props;
        const styles = require('./ProjectList.scss');
        return (
            <Pagination
                activePage={paginationPage}
                className={styles.pagination}
                items={this.paginationPages}
                maxButtons={3}
                next={paginationPage !== this.paginationPages}
                onSelect={pageResults}
                prev={paginationPage !== 1}
            />
        );
    }

    renderList() {
        if (this.props.projects.length === 0) {
            return <h5 className="text-center">No projects found. Please try another search.</h5>;
        }
        return this.props.projects.map((project) => (
            <ProjectListItem
                key={project.id}
                {...project}
                currentSearch={this.props.currentSearch}
                onClick={() => this.props.clickHander(project)}
                summary={stripHtml(project.summary)}
            />
        ));
    }

    render() {
        const {
            error,
            hasSearchSelection,
            loading,
            sortDirection,
            sortDirectionHandler,
            sortField,
        } = this.props;

        const styles = require('./ProjectList.scss');

        if (loading) return <LoadingSpinner className={styles.loading} />;
        if (error) return <LoadingError error={this.props.error} />;
        if (!hasSearchSelection) return this.renderNoSearch();

        const { sortIcon } = this.state;
        return (
            <div>
                <div className="row">
                    <div className="col-xs-3">Sort By:</div>
                    <div className={`col-xs-3 ${styles.sortDirection}`}>Sort Order:</div>
                    <div className="col-xs-6 text-right text-muted">{this.projectsFound}</div>
                </div>
                <div className={`row ${styles.searchControls}`}>
                    <div className="col-xs-3">
                        <LibrarySortForm
                            initialValues={{ [SORT_FIELD]: sortField }}
                            onSelectChangeHandler={this.onSortByChange}
                            options={SORT_OPTIONS}
                        />
                    </div>
                    <div className={`col-xs-3 ${styles.sortDirection}`}>
                        <Button onClick={sortDirectionHandler}>
                            <i className={`fa fa-sort-${sortIcon}-${sortDirection}`} />
                            &nbsp;{SORT_DIRECTION_MAP[sortDirection]}
                        </Button>
                    </div>
                    <div className="col-xs-6 text-right">{this.renderPagination()}</div>
                </div>
                <div className={styles.projectsList}>{this.renderList()}</div>
                <div className="text-center">{this.renderPagination()}</div>
            </div>
        );
    }
}

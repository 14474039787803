import { fromJS } from 'immutable';

import * as templateQuestionsActions from '../actions/templateQuestions';

const initialStateTemplateQuestionEditState = {
    loadingItem: false,
    loadingTemplatesInUse: false,
    loadItemError: null,
    loadTemplatesInUseError: null,
    templateQuestion: null,
    templatesInUse: [],
};

const initialState = fromJS({
    ...initialStateTemplateQuestionEditState,
    loadedList: false,
    loadingList: false,
    loadListError: null,
    templateQuestions: [],
});

export default function templateQuestionsReducer(state = initialState, action = {}) {
    switch (action.type) {
        case templateQuestionsActions.CREATE_ITEM_SUCCESS:
            return state.set(
                'templateQuestions',
                state.get('templateQuestions').unshift(fromJS({ ...action.result, projects: [] }))
            );
        case templateQuestionsActions.DELETE_ITEM_SUCCESS:
            return state.set(
                'templateQuestions',
                state.get('templateQuestions').filter((templateQuestion) => {
                    return templateQuestion.get('id') !== action.result.id;
                })
            );
        case templateQuestionsActions.LOAD_ITEM:
            return state.merge(
                fromJS({
                    loadingItem: true,
                    loadItemError: null,
                })
            );
        case templateQuestionsActions.LOAD_ITEM_FAIL:
            return state.merge(
                fromJS({
                    loadingItem: false,
                    loadItemError: action.error && action.error.message,
                })
            );
        case templateQuestionsActions.LOAD_ITEM_SUCCESS:
            return state.merge(
                fromJS({
                    loadingItem: false,
                    templateQuestion: fromJS(action.result),
                })
            );
        case templateQuestionsActions.LOAD_LIST:
            return state.merge(
                fromJS({
                    loadingList: true,
                    loadListError: null,
                })
            );
        case templateQuestionsActions.LOAD_LIST_FAIL:
            return state.merge(
                fromJS({
                    loadingList: false,
                    loadListError: action.error && action.error.message,
                })
            );
        case templateQuestionsActions.LOAD_LIST_SUCCESS:
            return state.merge(
                fromJS({
                    loadedList: true,
                    loadingList: false,
                    templateQuestions: fromJS(action.result),
                })
            );
        case templateQuestionsActions.LOAD_TEMPLATES_IN_USE:
            return state.merge(
                fromJS({
                    loadingTemplatesInUse: true,
                    loadTemplatesInUseError: null,
                })
            );
        case templateQuestionsActions.LOAD_TEMPLATES_IN_USE_FAIL:
            return state.merge(
                fromJS({
                    loadingTemplatesInUse: false,
                    loadTemplatesInUseError: action.error && action.error.message,
                })
            );
        case templateQuestionsActions.LOAD_TEMPLATES_IN_USE_SUCCESS:
            return state.merge(
                fromJS({
                    loadingTemplatesInUse: false,
                    templatesInUse: fromJS(action.result),
                })
            );
        case templateQuestionsActions.RESET_EDIT:
            return state.merge(fromJS(initialStateTemplateQuestionEditState));
        case templateQuestionsActions.RESET_LIST:
            return state.merge(
                fromJS({
                    loadedList: false,
                })
            );
        case templateQuestionsActions.UPDATE_ITEM_SUCCESS:
            return state.set(
                'templateQuestions',
                state.get('templateQuestions').map((templateQuestion) => {
                    if (templateQuestion.get('id') !== action.result.id) {
                        return templateQuestion;
                    }
                    return templateQuestion.merge(fromJS(action.result));
                })
            );
        default:
            return state;
    }
}

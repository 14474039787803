import { NOTE } from './constants';

export const validate = (values) => {
    const errors = {};

    const noteValue = values[NOTE];
    if (noteValue && noteValue.length > 1000) {
        errors[NOTE] =
            `Note cannot be longer than 1,000 characters (${noteValue.length} chars used)`;
    }

    return errors;
};

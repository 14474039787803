import { listToDict } from '@og-pro/shared-config/helpers';
import { emailFrequencyTypes } from '@og-pro/shared-config/users';

import { emailFields } from '../../../constants/userFields';

const { HOURLY, MUTE, DEMAND } = emailFrequencyTypes;

export const form = 'personalEmail';

export const fields = emailFields;

export const fieldNames = listToDict(fields);

export const emailFrequencyOptions = [
    { label: 'All - Receive emails as events happen', value: DEMAND },
    {
        label: 'Hourly - Receive a summary with all notifications that happened in the past hour',
        value: HOURLY,
    },
    { label: 'Mute - Never receive notifications via email', value: MUTE },
];

import { redirect } from 'react-router-dom';

import { isGovActive, isGovUser, isLoggedIn } from '../../actions/auth';

/*
 * Redirects a user to the login page if they are not a government user or are not logged in.
 * Also takes them to a special page if their subscription is expired.
 */
export default (getState) =>
    async ({ params }) => {
        const state = getState();
        const govUser = isGovUser(state, params.governmentId);

        if (!isLoggedIn(state) || !govUser) {
            return redirect('/login');
        }

        if (!isGovActive(state)) {
            return redirect('/subscription-expired');
        }

        return null;
    };

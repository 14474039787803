import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export const ActionSidebar = ({ children }) => {
    const styles = require('./index.scss');
    return <div className={classnames('action-sidebar', styles.container)}>{children}</div>;
};

export const ButtonGroup = ({ hidden = false, children }) => {
    const styles = require('./index.scss');

    return (
        <div
            className={classnames(styles.actionGroup, {
                [styles.hidden]: hidden,
            })}
        >
            {children}
        </div>
    );
};

export const Button = ({ children }) => {
    return children;
};

ActionSidebar.propTypes = {
    children: PropTypes.node.isRequired,
};

ButtonGroup.propTypes = {
    children: PropTypes.node.isRequired,
    hidden: PropTypes.bool,
};

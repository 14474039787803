import { listToDict } from '@og-pro/shared-config/helpers';
import { requisitionFiltersNames } from '@og-pro/shared-config/requisitions';

const ONE_DATE = 'oneDate';
const DATE_RANGE = 'dateRange';

const dateFilterTypes = [ONE_DATE, DATE_RANGE];
export const dateFilterTypesDict = listToDict(dateFilterTypes);

const { ON, AFTER, BEFORE, START, END } = requisitionFiltersNames;

export const singleDateTypes = [ON, AFTER, BEFORE];
export const rangeDateTypes = [START, END];
export const dateTypes = [...singleDateTypes, ...rangeDateTypes];

export const dateTypesDict = listToDict(dateTypes);

export const initialValue = {
    [ON]: null,
    [AFTER]: null,
    [BEFORE]: null,
    [START]: null,
    [END]: null,
};

export const dateOptions = [
    {
        label: 'One Date',
        value: ONE_DATE,
    },
    {
        label: 'Date Range',
        value: DATE_RANGE,
    },
];

export const oneDateTypes = [
    {
        label: 'On',
        value: ON,
    },
    {
        label: 'Before',
        value: BEFORE,
    },
    {
        label: 'After',
        value: AFTER,
    },
];

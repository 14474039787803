import { buildQaTag } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';
import classnames from 'classnames';

import {
    ADVANCED_TOOLBAR,
    BLOCK_FORMAT_OPTIONS,
    CONTENT_CSS_URL,
    CUSTOM_CONTENT_CSS_URL,
    DEFAULT_OUTLINE_NUMBERING,
    DEFAULT_PLUGINS,
    FONT_SIZE_FORMATS,
    INVALID_ELEMENTS,
    INVALID_ELEMENTS_HEADER_TOOLBAR,
    MINIMAL_TOOLBAR,
    TINYMCE_SRC_URL,
} from './constants';
import { insertTag, insertTemplateVariable } from './utils';
import { Label } from '../InputText';
import { HelpBlock } from '../HelpBlock/HelpBlock';
import {
    showInsertTagModal,
    showInsertTemplateVariableModal,
    uploadImage,
    isTinyMceModalOpen,
} from '../../actions/app';
import { HtmlContent } from '../HtmlContent/HtmlContent';
import { isOpenGovAdmin } from '../../containers/selectors';
import { getOutlineNumberingJS } from '../../selectors/app';

export const RichTextInput = ({
    autoFocus,
    borderless = false,
    className,
    commentData,
    commentIcon,
    disabled = false,
    disableTables = false,
    forcePaddedContent = false,
    help,
    helpIcon,
    helpIconClick,
    helpIconNode,
    hideToolbar = false,
    input,
    label,
    meta,
    minimalToolbar,
    minRows = 4,
    overrideFeedback,
    placeholder,
    plugins,
    qaTag,
    removePastedWordFormatting = false,
    showHeaderToolbar = false,
    showValidation,
    tagOptions,
    templateVariableOptions,
    toolbar,
    toolbarPlacement,
    useOpenGovStyle,
    useSharedTextareaToolbar,
}) => {
    const dispatch = useDispatch();
    const styles = require('./index.scss');

    const userIsOpenGovAdmin = useSelector(isOpenGovAdmin);
    const outlineNumbering = useSelector(getOutlineNumberingJS);

    const [loading, setLoading] = useState(true);

    const { onBlur, onChange, onFocus, name, value } = input;
    const { error, touched } = meta || {};

    const validationIndicators = (touched && !useOpenGovStyle) || showValidation;
    const displayError = validationIndicators && !!error;
    const helpText = displayError && !overrideFeedback ? error : help;

    const setEditorDialogOpened = (opened) => {
        dispatch(isTinyMceModalOpen(opened));
    };

    const validationState = () => {
        if (overrideFeedback) {
            return undefined;
        }

        if (validationIndicators && error) {
            return 'error';
        }

        if (validationIndicators && !useOpenGovStyle) {
            return 'success';
        }

        return undefined;
    };

    const validationStateClass = () => {
        switch (validationState()) {
            case 'error':
                return styles.hasError;
            case 'success':
                return styles.hasSuccess;
            default:
                return styles.container;
        }
    };

    const handleImageUpload = async (blobInfo) => {
        // TinyMCE passes a Blob, this converts it to a DataURL to match the API spec
        return dispatch(uploadImage(`data:${blobInfo.blob().type};base64,${blobInfo.base64()}`));
    };

    const handleInsertTagClick = (editor) => {
        dispatch(
            showInsertTagModal({
                onHide: () => {
                    editor.focus();
                },
                onInsert: (tagName, tagLink, linkOnly) =>
                    insertTag(editor, tagName, tagLink, linkOnly),
                options: tagOptions,
            })
        );
    };

    const handleInsertTemplateVariableClick = (editor) => {
        dispatch(
            showInsertTemplateVariableModal({
                onHide: () => {
                    editor.focus();
                },
                onInsert: (templateLabel, templateValue) =>
                    insertTemplateVariable(editor, templateLabel, templateValue),
                options: templateVariableOptions,
            })
        );
    };

    const onInit = (event, editor) => {
        setLoading(false);

        editor.formatter.register('templateTag', {
            inline: 'span',
            styles: {
                backgroundColor: '#eee',
            },
        });

        if (autoFocus) {
            // move selection to end of text
            editor.selection.select(editor.getBody(), true);
            editor.selection.collapse(false);
        }
    };

    const setup = (editor) => {
        if (tagOptions) {
            editor.ui.registry.addButton('insertTag', {
                icon: 'bookmark',
                tooltip: 'Insert Project Link',
                onAction: () => handleInsertTagClick(editor),
            });
        }

        if (templateVariableOptions) {
            editor.ui.registry.addButton('insertTemplateVariable', {
                icon: 'addtag',
                tooltip: 'Insert Template Variable',
                onAction: () => handleInsertTemplateVariableClick(editor),
            });
        }

        editor.on('OpenWindow', () => {
            setEditorDialogOpened(true);
            // the dialog is not gaining focus, probably a side effect from the bootstrap dialog
            // relinquishing the focus monarchy after the redux prop is set
            // NOTE: this event has an object that lets you do `event.dialog.focus()` but does not work
            setTimeout(() => {
                const firstInput = document.querySelector('input.tox-textfield');
                if (firstInput) {
                    firstInput.focus();
                }
            }, 0);
        });
        editor.on('CloseWindow', () => {
            setEditorDialogOpened(false);
        });
    };

    const setupPlugins = () => {
        const pluginsBuilder = plugins ?? DEFAULT_PLUGINS;

        if (userIsOpenGovAdmin) {
            pluginsBuilder.push('code');
        }

        return pluginsBuilder;
    };

    const setupToolbar = () => {
        if (hideToolbar) {
            // setting the "toolbar" property to false hides the toolbar
            return false;
        }

        let toolbarBuilder = toolbar ?? ADVANCED_TOOLBAR;

        if (minimalToolbar) {
            toolbarBuilder = MINIMAL_TOOLBAR;
        }

        if (showHeaderToolbar) {
            toolbarBuilder = `blocks ${toolbarBuilder}`;
        }

        if (userIsOpenGovAdmin) {
            toolbarBuilder = `${toolbarBuilder} code`;
        }

        return toolbarBuilder;
    };

    const getInvalidElements = () => {
        if (disableTables) {
            return `${INVALID_ELEMENTS}, ${INVALID_ELEMENTS_HEADER_TOOLBAR}`;
        }

        return INVALID_ELEMENTS;
    };

    // cleans anchor nodes when they have a "name" attribute with a weird apostrophe
    // it breaks the docx export afterwards
    // this removes the apostrophe
    const cleanAnchorNodes = (node) => {
        if (node.nodeName === 'A') {
            if (node.attributes?.name?.value && /('|ʼ|ʻ|’)/gi.test(node.attributes?.name.value)) {
                node.setAttribute('name', node.attributes?.name?.value.replace(/('|ʼ|ʻ|’)/gi, ''));
            }
        }

        if (node.children?.length) {
            // this is not a common array, way to iterate is this:
            for (let i = 0; i < node.children.length; i += 1) {
                cleanAnchorNodes(node.children[i]);
            }
        }
    };

    const processPowerPasteData = (pluginApi, data) => {
        // this is HTML generated by Tiny, so it is safe to tinker with innerHTML
        data.node.innerHTML = data.node.innerHTML.replace(/font-family:.*?;/gim, '');

        // removes unicode character  from the pasted content \u0002
        // eslint-disable-next-line no-control-regex
        data.node.innerHTML = data.node.innerHTML.replace(/\u0002/gi, '');

        cleanAnchorNodes(data.node);
    };

    const getOrderedListStyles = () => {
        if (!outlineNumbering) {
            return DEFAULT_OUTLINE_NUMBERING;
        }

        const { firstLevel, secondLevel, thirdLevel, fourthLevel, fifthLevel } = outlineNumbering;

        return `ol {
            list-style-type: ${firstLevel};
        }

        ol > li > ol {
            list-style-type: ${secondLevel};
        }

        ol > li > ol > li > ol {
            list-style-type: ${thirdLevel};
        }

        ol > li > ol > li > ol > li > ol {
            list-style-type: ${fourthLevel};
        }

        ol > li > ol > li > ol > li > ol > li > ol {
            list-style-type: ${fifthLevel};
        }`;
    };

    const getBorderlessStyles = () => {
        return `body { margin: 0; }`;
    };

    const id = `${name}-${label?.replace(/\s/, '')}-input`;

    return (
        <FormGroup
            className={classnames(className, {
                [styles.borderless]: borderless,
                [styles.openGovStyle]: useOpenGovStyle,
            })}
            controlId={`form-group-${name}`}
            validationState={validationState()}
        >
            <Label
                commentData={commentData}
                commentIcon={commentIcon}
                helpIcon={helpIcon}
                helpIconClick={helpIconClick}
                helpIconNode={helpIconNode}
                htmlFor={id}
                label={label}
            />
            {loading && (
                <div className={styles.loadingBlock}>
                    <HtmlContent content={value || '&nbsp'} />
                </div>
            )}
            <div
                className={classnames(validationStateClass(), {
                    [styles.hidden]: loading,
                    [styles.padded]: forcePaddedContent,
                })}
            >
                <Editor
                    data-qa={buildQaTag(qaTag, 'tinymce')}
                    disabled={disabled}
                    id={id}
                    init={{
                        auto_focus: autoFocus,
                        autoresize_bottom_margin: 0,
                        block_formats: BLOCK_FORMAT_OPTIONS,
                        branding: false,
                        browser_spellcheck: true,
                        content_css: [CONTENT_CSS_URL, CUSTOM_CONTENT_CSS_URL],
                        content_style: `${getOrderedListStyles()}${
                            borderless ? getBorderlessStyles() : ''
                        }`,
                        contextmenu: false,
                        extended_valid_elements: 'svg[*],path[*]',
                        font_size_formats: FONT_SIZE_FORMATS,
                        invalid_elements: getInvalidElements(),
                        images_upload_handler: handleImageUpload,
                        indent: false,
                        link_assume_external_targets: 'https',
                        menubar: false,
                        min_height: minRows * 20 + 64,
                        placeholder,
                        plugins: setupPlugins(),
                        pagebreak_separator: '<p style="break-after: page;">&nbsp;</p>', // Previously this was <!-- pagebreak -->, but docx templater doesn't seem to render the page break when it's just the html comment.
                        paste_postprocess: processPowerPasteData,
                        ...(removePastedWordFormatting
                            ? {
                                  powerpaste_word_import: 'clean',
                                  powerpaste_googledocs_import: 'clean',
                              }
                            : {}),
                        readOnly: disabled,
                        remove_script_host: false,
                        relative_urls: false,
                        setup,
                        statusbar: false,
                        table_sizing_mode: 'fixed',
                        table_column_resizing: 'resizetable',
                        table_use_colgroups: false,
                        toolbar: setupToolbar(),
                        toolbar_location: toolbarPlacement,
                        toolbar_mode: 'sliding',
                        ...(useSharedTextareaToolbar && {
                            inline: true,
                            toolbar_location: 'auto',
                            fixed_toolbar_container: '#SharedToolbarContainer',
                            content_css: false,
                        }),
                    }}
                    onBlur={onBlur}
                    onEditorChange={onChange}
                    onFocus={onFocus}
                    onInit={onInit}
                    tinymceScriptSrc={TINYMCE_SRC_URL}
                    value={value}
                />
            </div>
            {helpText && (
                <HelpBlock>
                    {error && useOpenGovStyle && (
                        <>
                            <i className="fa fa-exclamation-triangle" />
                            &nbsp;
                        </>
                    )}
                    {helpText}
                </HelpBlock>
            )}
        </FormGroup>
    );
};

RichTextInput.propTypes = {
    autoFocus: PropTypes.bool,
    borderless: PropTypes.bool,
    className: PropTypes.string,
    commentData: PropTypes.object,
    commentIcon: PropTypes.bool,
    disabled: PropTypes.bool,
    disableTables: PropTypes.bool,
    forcePaddedContent: PropTypes.bool,
    help: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    helpIcon: PropTypes.bool,
    helpIconClick: PropTypes.func,
    helpIconNode: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    hideToolbar: PropTypes.bool,
    input: PropTypes.object.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    meta: PropTypes.object,
    minimalToolbar: PropTypes.bool,
    minRows: PropTypes.number,
    overrideFeedback: PropTypes.bool,
    placeholder: PropTypes.string,
    plugins: PropTypes.array,
    qaTag: PropTypes.string,
    removePastedWordFormatting: PropTypes.bool,
    showHeaderToolbar: PropTypes.bool,
    showValidation: PropTypes.bool,
    tagOptions: PropTypes.array,
    templateVariableOptions: PropTypes.array,
    toolbar: PropTypes.array,
    toolbarPlacement: PropTypes.string,
    useOpenGovStyle: PropTypes.bool,
    useSharedTextareaToolbar: PropTypes.bool,
};

import React from 'react';

import * as Gov from '../../containers/GovApp';
import { legacyFetchData, requireRetentionAdminPermissions } from '../loaders';

export default (getState, dispatch) => {
    return [
        {
            element: <Gov.RecordsRetentionNav />,
            loader: requireRetentionAdminPermissions(getState),
            path: 'retention-admin',
            children: [
                {
                    element: <Gov.RecordTableRecentlyClosed />,
                    index: true,
                },
                {
                    element: <Gov.RecordTableExpired />,
                    path: 'expired',
                },
                {
                    element: <Gov.RecordTableDocuments />,
                    path: 'documents',
                },
                {
                    element: <Gov.ManagePoliciesNav />,
                    path: 'manage',
                    children: [
                        {
                            element: <Gov.PolicyList />,
                            loader: legacyFetchData(getState, dispatch, Gov.PolicyList),
                            index: true,
                        },
                        {
                            element: <Gov.PolicyForm />,
                            path: 'policy',
                        },
                        {
                            element: <Gov.PolicyForm />,
                            path: 'policy/:retentionCodeId',
                        },
                    ],
                },
            ],
        },
    ];
};

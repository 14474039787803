import { createSelector } from 'reselect';

const getRequestToJoinAdmins = (state) => state.auth.get('requestToJoinAdmins');
const getSignUpOrganizationMatch = (state) => state.auth.get('signUpOrganizationMatch');

export const getRequestToJoinAdminsJS = createSelector([getRequestToJoinAdmins], (rawAdmins) => {
    if (rawAdmins) {
        return rawAdmins.toJS();
    }
    return [];
});

export const getSignUpOrganizationMatchJS = createSelector(
    [getSignUpOrganizationMatch],
    (rawOrganization) => {
        if (rawOrganization) {
            return rawOrganization.toJS();
        }
    }
);

export const form = 'contractFromProjectForm';

export const UNLINK_BUDGET = 'unlinkBudget';

export const budgetSetupOptions = [
    {
        label: 'Share Budget With Project',
        tooltip: "Link the new contract's budget to the project's budget.",
        value: false,
    },
    {
        label: 'Use Independent Budget',
        tooltip: "Make the new contract's budget independent of the project's budget.",
        value: true,
    },
];

import React from 'react';
import PropTypes from 'prop-types';

import { ProjectCreateButton } from '../../../components/GovApp';
import { Button, LinkContainer } from '../../../components';

const ActionButtons = ({ governmentId, hasSourcing, isIntake, onRouteToPortal }) => {
    // TODO: Update production Webpack config to allow ES6-style stylesheet imports
    const styles = require('./ActionButtons.scss');

    return (
        <>
            <ProjectCreateButton
                className={`btn-primary btn-block actions-new-project ${styles.createBtn}`}
                isIntake={isIntake}
                key="project-create-button"
                qaTag="projects-createNewProject"
            />
            {!isIntake && (
                <>
                    <LinkContainer
                        key="project-library-button"
                        to={{
                            pathname: `/governments/${governmentId}/project-library`,
                        }}
                    >
                        <Button block className="library-btn" qaTag="projects-library">
                            <i className="fa fa-folder-open-o" /> Browse Library
                        </Button>
                    </LinkContainer>
                    {hasSourcing && (
                        <>
                            <Button
                                block
                                className="public-portal"
                                key="public-portal-button"
                                onClick={onRouteToPortal}
                                qaTag="projects-publicPortal"
                            >
                                <i className="fa fa-external-link" /> Go To Public Portal
                            </Button>
                            <Button
                                block
                                className="emergency-portal"
                                key="emergency-portal-button"
                                qaTag="projects-emergencyPortal"
                                to={`/governments/${governmentId}/emergency-portal`}
                            >
                                <i className="fa fa-plus-square text-danger" /> Emergency Portal
                            </Button>
                        </>
                    )}
                </>
            )}
        </>
    );
};

ActionButtons.propTypes = {
    hasSourcing: PropTypes.bool.isRequired,
    isIntake: PropTypes.bool,
    governmentId: PropTypes.string.isRequired,
    onRouteToPortal: PropTypes.func.isRequired,
};

export default ActionButtons;

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import {
    ContainsPricingCheckbox,
    DefaultValueInput,
    Prompt,
    PublicCheckbox,
    Required,
    Title,
} from '.';

export class GenericFormBuilder extends PureComponent {
    static propTypes = {
        allowDefaultValue: PropTypes.bool,
        children: PropTypes.node,
        defaultValueType: PropTypes.string,
        disabled: PropTypes.bool,
        form: PropTypes.string.isRequired,
        includeContainsPricingOption: PropTypes.bool,
        isTitleEditingDisabled: PropTypes.bool,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
        titleLabel: PropTypes.string,
        titlePlaceholder: PropTypes.string,
        useRawPrompt: PropTypes.bool,
    };

    static defaultProps = {
        children: undefined,
        tagOptions: undefined,
        templateVariableOptions: undefined,
        useRawPrompt: false,
    };

    render() {
        const {
            allowDefaultValue,
            children,
            disabled,
            defaultValueType,
            form,
            includeContainsPricingOption,
            isTitleEditingDisabled,
            tagOptions,
            templateVariableOptions,
            titleLabel,
            titlePlaceholder,
            useRawPrompt,
        } = this.props;

        return (
            <>
                <Title
                    disabled={disabled || isTitleEditingDisabled}
                    label={titleLabel}
                    placeholder={titlePlaceholder}
                />
                <Prompt
                    disabled={disabled}
                    tagOptions={tagOptions}
                    templateVariableOptions={templateVariableOptions}
                    useRawPrompt={useRawPrompt}
                />
                {children}
                <Required disabled={disabled} />
                {allowDefaultValue && (
                    <DefaultValueInput disabled={disabled} form={form} type={defaultValueType} />
                )}
                {includeContainsPricingOption && <ContainsPricingCheckbox disabled={disabled} />}
                {includeContainsPricingOption && <PublicCheckbox disabled={disabled} />}
            </>
        );
    }
}

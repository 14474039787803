import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Box } from '@og-pro/ui';

import { Question } from './Question';
import { NoItems } from '../../..';
import { FIXED_TOOLBAR_ADJUSTMENT_HEIGHT } from '../../../../constants/styles';

export class QuestionList extends PureComponent {
    static propTypes = {
        canEdit: PropTypes.bool,
        canRelease: PropTypes.bool,
        isGovUser: PropTypes.bool,
        isOpen: PropTypes.bool,
        hasDeadlinePassed: PropTypes.bool,
        questions: PropTypes.array.isRequired,
        responseHandler: PropTypes.func.isRequired,
        selectedNumber: PropTypes.number,
        showRegularAndAuctionQuestions: PropTypes.bool,
        showReleaseResponsesModal: PropTypes.func,
        timezone: PropTypes.string.isRequired,
        toggleEditQuestionForm: PropTypes.func.isRequired,
        toggleEditResponseForm: PropTypes.func,
        user: PropTypes.object,
    };

    componentDidMount() {
        this.scrollToSelected();
    }

    componentDidUpdate(prevProps) {
        const { selectedNumber } = this.props;

        if (selectedNumber !== prevProps.selectedNumber) {
            this.scrollToSelected(prevProps.selectedNumber);
        }
    }

    scrollToSelected = (prevNumber) => {
        const { isGovUser, selectedNumber } = this.props;

        // Scrolls to the selected project field if a field param is specified
        if (selectedNumber) {
            const getSelectedElements = (number) => {
                return [
                    ...document.getElementsByClassName(`question-number-${number}`),
                    ...document.getElementsByClassName(`question-id-${number}`),
                ];
            };

            const [targetEl] = getSelectedElements(selectedNumber);

            // Remove styling from previously selected element
            if (prevNumber) {
                getSelectedElements(prevNumber).forEach((prevEl) => {
                    const className = prevEl.className.split(' ').slice(0, -1).join(' ');
                    prevEl.className = className;
                });
            }

            if (targetEl) {
                // Add styling to the selected element
                const styles = require('./shared.scss');
                targetEl.className += ` ${styles.selectedField}`;

                // Need to take off the event loop for child rendering to
                // complete, which will allow the scroll to work properly
                setTimeout(() => {
                    targetEl.scrollIntoView();
                    const pageYOffset = window.pageYOffset;
                    if (isGovUser && pageYOffset) {
                        // Offset scroll position to account for fixed navbar
                        window.scroll(0, pageYOffset - FIXED_TOOLBAR_ADJUSTMENT_HEIGHT);
                    }
                });
            }
        }
    };

    renderNoQuestions() {
        const { isGovUser, isOpen, hasDeadlinePassed } = this.props;

        if (isGovUser) {
            return (
                <NoItems header="No questions here!" subheader="Try a different search filter" />
            );
        }

        if (!isOpen) {
            return (
                <NoItems
                    header="No questions submitted"
                    subheader="The deadline to submit questions has passed"
                />
            );
        }

        if (hasDeadlinePassed) {
            return <NoItems header="No questions submitted" />;
        }

        return (
            <NoItems
                header="No questions submitted yet"
                subheader="Be the first to ask a question"
            />
        );
    }

    renderQuestions(questions) {
        const {
            canEdit,
            canRelease,
            hasDeadlinePassed,
            isGovUser,
            responseHandler,
            showReleaseResponsesModal,
            timezone,
            toggleEditQuestionForm,
            toggleEditResponseForm,
            user,
        } = this.props;

        return questions.map((question, idx) => {
            return (
                <Question
                    assignUserError={question.assignUserError}
                    assignedUser={question.assignedUser}
                    assigningUser={question.assigningUser}
                    attachments={question.attachments}
                    canEdit={canEdit}
                    canRelease={canRelease}
                    comments={question.questionComments}
                    excludeReason={question.excludeReason}
                    hasDeadlinePassed={hasDeadlinePassed}
                    isApproved={question.isApproved}
                    isGovUser={isGovUser}
                    isSubstitutionRequest={question.isSubstitutionRequest}
                    key={idx}
                    number={question.number}
                    postedAt={question.created_at}
                    questionId={question.id}
                    releasedAt={question.releasedAt}
                    respondError={question.respondError}
                    responding={question.responding}
                    responseHandler={responseHandler}
                    showEditQuestionForm={question.showEditQuestionForm}
                    showEditResponseForm={question.showEditResponseForm}
                    showReleaseResponsesModal={showReleaseResponsesModal}
                    status={question.status}
                    subject={question.subject}
                    timezone={timezone}
                    toggleEditQuestionForm={toggleEditQuestionForm}
                    toggleEditResponseForm={toggleEditResponseForm}
                    user={user}
                />
            );
        });
    }

    render() {
        const { questions, showRegularAndAuctionQuestions } = this.props;

        if (questions.length === 0) {
            return this.renderNoQuestions();
        }

        if (showRegularAndAuctionQuestions) {
            return (
                <div>
                    <Box mb={2} mt={3}>
                        <Box mb={2}>
                            <h3>Project Questions</h3>
                        </Box>
                        <Box>
                            {this.renderQuestions(questions.filter((q) => !q.isAuctionQuestion))}
                        </Box>
                    </Box>
                    <Box>
                        <Box mb={2}>
                            <h3>Reverse Auction Questions</h3>
                        </Box>
                        <Box>
                            {this.renderQuestions(questions.filter((q) => q.isAuctionQuestion))}
                        </Box>
                    </Box>
                </div>
            );
        }

        return <div>{this.renderQuestions(questions)}</div>;
    }
}

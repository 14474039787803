export const SHOW = 'confirmation/SHOW';
export const HIDE = 'confirmation/HIDE';
export const SHOW_ERROR = 'confirmation/SHOW_ERROR';
export const UPDATING = 'confirmation/UPDATING';

export function showConfirmationModal(confirmationType, data) {
    return { type: SHOW, confirmationType, data };
}

export function hideConfirmationModal() {
    return { type: HIDE };
}

export function showConfirmationModalError(error) {
    return { type: SHOW_ERROR, error };
}

export function updatingConfirmationModal() {
    return { type: UPDATING };
}

export const SHOW_SIMPLE = 'confirmation/SHOW_SIMPLE';
export const HIDE_SIMPLE = 'confirmation/HIDE_SIMPLE';

/**
 * Displays the simple confirmation modal utility
 * @param {Function} onConfirm The function to execute on confirmation
 * @param {object} [data={}] Options object for simple confirmation modal
 * @param {string} [data.bsSize] Bootstrap modal size
 * @param {string} [data.bsStyle] Bootstrap button color style
 * @param {string} [data.btnText] Text to use on confirmation button
 * @param {string} [data.cancelText] Text to use on cancel button
 * @param {boolean} [data.chainModals] Whether to chain modals
 * @param {object} [data.dialogProps] Additional props to pass to the dialog (use with `useOpenGovStyle`)
 * @param {string} [data.dialogStyle] Additional styles to apply to the dialog (use with `useOpenGovStyle`)
 * @param {boolean} [data.hideCancelButton] Whether to hide the cancel button
 * @param {string} [data.icon] Font awesome icon to use on confirmation button (no `fa-` prefix)
 * @param {string} [data.iconSize] Font awesome icon size
 * @param {Function} [data.onCancel] Function to run when not confirmed
 * @param {Function} [data.onHide] Function to run when modal is hidden
 * @param {string} [data.text] Text to use as confirmation message
 * @param {string} [data.title] Text to use as the confirmation modal title
 * @param {boolean} [data.useOpenGovStyle] Whether to use OpenGov styling (renders with the `Dialog` component)
 * @return {object} Action to dispatch
 */
export function showConfirmationSimpleModal(onConfirm, data = {}) {
    return { type: SHOW_SIMPLE, onConfirm, data };
}

export function hideConfirmationSimpleModal() {
    return { type: HIDE_SIMPLE };
}

import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';

import { form, TEMPLATE_SECTION_ID } from './constants';
import {
    SearchSelect,
    SearchSelectIconOption,
    SearchSelectIconValue,
} from '../../../../../../../../components';

const formConfig = {
    form,
};

// @reduxForm
export const ConnectedTemplateSectionAddForm = ({ options }) => {
    const searchSelectStyle = {
        menu: { width: 400 },
    };
    return (
        <Field
            autoFocus
            component={SearchSelect}
            components={{
                Option: SearchSelectIconOption,
                SingleValue: SearchSelectIconValue,
            }}
            name={TEMPLATE_SECTION_ID}
            noOptionsMessage={() => 'No shared sections available'}
            openMenuOnFocus
            options={options}
            overrideFeedback
            placeholder="Add shared section"
            styles={searchSelectStyle}
        />
    );
};

ConnectedTemplateSectionAddForm.propTypes = {
    options: PropTypes.array.isRequired,
};

export const TemplateSectionAddForm = reduxForm(formConfig)(ConnectedTemplateSectionAddForm);

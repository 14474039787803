import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';

import { notificationLevelOptions } from '@og-pro/shared-config/governmentSubscriptions';

import { form, NOTIFICATION_LEVEL } from './constants';
import { SearchSelect, SearchSelectIconOption, SearchSelectIconValue } from '../..';

const mapStateToProps = (state, props) => {
    return {
        form: `${form}${props.governmentSubscription.id}`,
        initialValues: {
            [NOTIFICATION_LEVEL]: props.governmentSubscription[NOTIFICATION_LEVEL],
        },
    };
};

// @connect
// @reduxForm
class ConnectedGovernmentSubscriptionNotificationForm extends Component {
    static propTypes = {
        disabled: PropTypes.bool,
        governmentSubscription: PropTypes.shape({
            id: PropTypes.number.isRequired,
            notificationLevel: PropTypes.number.isRequired,
        }).isRequired,
        label: PropTypes.string,
        updateGovSubscription: PropTypes.func.isRequired,
    };

    onNotificationChange = (e, notificationLevel) => {
        const {
            governmentSubscription: { id, notificationLevel: currentNotificationLevel },
            updateGovSubscription,
        } = this.props;

        // Can get called when same value is selected
        if (notificationLevel !== currentNotificationLevel) {
            updateGovSubscription(id, { notificationLevel });
        }
    };

    render() {
        const { disabled, label } = this.props;

        return (
            <Field
                blurInputOnSelect
                component={SearchSelect}
                components={{
                    Option: SearchSelectIconOption,
                    SingleValue: SearchSelectIconValue,
                }}
                disabled={disabled}
                isSearchable={false}
                label={label || 'Notify me when'}
                name={NOTIFICATION_LEVEL}
                onChange={this.onNotificationChange}
                options={notificationLevelOptions}
                overrideFeedback
            />
        );
    }
}

export const GovernmentSubscriptionNotificationForm = compose(
    connect(mapStateToProps),
    reduxForm()
)(ConnectedGovernmentSubscriptionNotificationForm);

import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import { Box, Typography } from '@og-pro/ui';
import React from 'react';

export const AddRelationInfo = () => {
    return (
        <>
            <Typography variant="h3">Add a Related Item</Typography>
            <Box color={capitalDesignTokens.foundations.colors.gray800}>
                Once an item is related to this request, all other parts of the procurement will be
                associated automatically.
            </Box>
        </>
    );
};

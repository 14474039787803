import React, { PureComponent } from 'react';

import { Main, RevisionDiff, VersionCompareForm } from '../../../../../components';

export class VersionCompare extends PureComponent {
    get styles() {
        return require('./index.scss');
    }

    render() {
        return (
            <Main>
                <h2 className={this.styles.sectionTitle}>Compare Versions</h2>
                <VersionCompareForm />
                <RevisionDiff />
            </Main>
        );
    }
}

export const LOAD_AGGREGATE = 'gov/analytics/LOAD_AGGREGATE';
export const LOAD_AGGREGATE_SUCCESS = 'gov/analytics/LOAD_AGGREGATE_SUCCESS';
export const LOAD_AGGREGATE_FAIL = 'gov/analytics/LOAD_AGGREGATE_FAIL';

export function loadGovAnalytics(queryData) {
    return (dispatch, getState, client) => {
        const govId = getState().auth.getIn(['user', 'government_id']);
        dispatch({ type: LOAD_AGGREGATE });
        return client
            .get(`/government/${govId}/analytics`, { params: queryData })
            .then((result) => {
                dispatch({ type: LOAD_AGGREGATE_SUCCESS, result });
            })
            .catch((error) => {
                dispatch({ type: LOAD_AGGREGATE_FAIL, error });
            });
    };
}

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { get, startCase } from 'lodash';
import classNames from 'classnames';

import { StatusLabel } from '..';
import { contractStatusClass } from '../helpers/statusHelper';

const PriceItemAwardInfo = ({ contract, label, proposalId }) => {
    const styles = require('./PriceItemAwardInfo.scss');
    const contractId = useMemo(() => get(contract, 'id'), [contract]);
    const status = useMemo(() => get(contract, 'status', 'No'), [contract]);

    return (
        <div>
            <span
                className={classNames('pseudoLink', styles.label)}
                data-contract-id={contractId}
                data-proposal-id={proposalId}
            >
                {label}
            </span>
            <StatusLabel bsStyle={contractStatusClass(get(contract, 'status'))}>
                {startCase(status)} contract
            </StatusLabel>
        </div>
    );
};

PriceItemAwardInfo.propTypes = {
    contract: PropTypes.shape({
        id: PropTypes.number,
        status: PropTypes.string,
    }),
    label: PropTypes.string.isRequired,
    proposalId: PropTypes.number.isRequired,
};

export default PriceItemAwardInfo;

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { HtmlContent } from '../../../../../components';

export class NonAwardReport extends PureComponent {
    static propTypes = {
        standardDocument: PropTypes.object.isRequired,
    };

    render() {
        const {
            standardDocument: { text },
        } = this.props;

        return <HtmlContent content={text} />;
    }
}

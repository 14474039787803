import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { TemplateSectionBroadcastModalBody } from './TemplateSectionBroadcastModalBody';
import { getTemplateSectionProjects } from '../selectors';
import { loadTemplateSectionProjects } from '../../../../../actions/templateSections';

export const TemplateSectionBroadcastModal = ({
    disabled,
    hideModal,
    submitFailed,
    templateSection,
    updateTemplateSection,
}) => {
    const [loading, setLoading] = useState(false);
    const [loadError, setLoadError] = useState(null);
    const projects = useSelector(getTemplateSectionProjects);
    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true);
        dispatch(loadTemplateSectionProjects(templateSection.id))
            .then(() => setLoading(false))
            .catch((error) => {
                setLoading(false);
                setLoadError(error.message);
            });
    }, []);

    return (
        <Modal onHide={hideModal} show>
            <Modal.Header closeButton>
                <Modal.Title className="text-center">Broadcast Shared Section Updates</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TemplateSectionBroadcastModalBody
                    disabled={disabled}
                    hideModal={hideModal}
                    loadError={loadError}
                    loading={loading}
                    projects={projects}
                    submitFailed={submitFailed}
                    updateTemplateSection={updateTemplateSection}
                />
            </Modal.Body>
        </Modal>
    );
};

TemplateSectionBroadcastModal.propTypes = {
    disabled: PropTypes.bool,
    hideModal: PropTypes.func.isRequired,
    submitFailed: PropTypes.bool,
    templateSection: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }).isRequired,
    updateTemplateSection: PropTypes.func.isRequired,
};

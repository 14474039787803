import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { CustomContentItem } from './CustomContentItem';
import { AddContentForm } from '..';
import { fieldNames } from '../AddContentForm/constants';
import { Button, HeaderToggle, Main, SectionTitle } from '../../../../../components';
import { sectionTypeOptions } from '../../constants';

const { SECTION_TYPE } = fieldNames;

export class CustomContent extends PureComponent {
    static propTypes = {
        content: PropTypes.array.isRequired,
        sectionType: PropTypes.string,
        addContentError: PropTypes.string,
        showAddForm: PropTypes.bool,
        sortDirections: PropTypes.object.isRequired,
        addContentHandler: PropTypes.func.isRequired,
        editContentHandler: PropTypes.func.isRequired,
        deleteContentHandler: PropTypes.func.isRequired,
        sortColHandler: PropTypes.func.isRequired,
        toggleForm: PropTypes.func.isRequired,
    };

    get styles() {
        return require('./CustomContent.scss');
    }

    handleSortByCreatedAtClick = () => {
        const { sortColHandler } = this.props;

        sortColHandler('created_at');
    };

    handleSortByTitleClick = () => {
        const { sortColHandler } = this.props;

        sortColHandler('title');
    };

    handleToggleFormClose = () => {
        const { toggleForm } = this.props;

        toggleForm(false);
    };

    handleToggleFormOpen = () => {
        const { toggleForm } = this.props;

        toggleForm(true);
    };

    renderAddComponent() {
        const { addContentError, addContentHandler, sectionType, showAddForm } = this.props;

        if (showAddForm) {
            return (
                <div className="well col-sm-offset-1 col-sm-10">
                    <AddContentForm
                        buttonLoadingText="Adding..."
                        buttonText="Add Content"
                        closeForm={this.handleToggleFormClose}
                        initialValues={{
                            [SECTION_TYPE]: sectionType,
                        }}
                        onSubmit={addContentHandler}
                        showSectionType
                        submitServerError={addContentError}
                    />
                </div>
            );
        }

        return (
            <div className="col-xs-12 text-right">
                <Button
                    bsStyle="success"
                    className="text-center"
                    onClick={this.handleToggleFormOpen}
                >
                    <i className="fa fa-plus" /> Add Suggested Content
                </Button>
            </div>
        );
    }

    renderItems() {
        const { content, deleteContentHandler, editContentHandler, sortDirections, toggleForm } =
            this.props;

        if (content.length === 0) {
            return (
                <div className="text-muted text-center">
                    No suggested content has been added for this section yet.
                    <br />
                    To add a suggested item, just click the button above!
                </div>
            );
        }

        const customItems = content.map((item) => (
            <CustomContentItem
                deleteContentHandler={deleteContentHandler}
                editContentHandler={editContentHandler(item.id)}
                item={item}
                key={item.id}
                toggleForm={toggleForm}
            />
        ));

        return (
            <div>
                <div className={`row ${this.styles.headerRow}`}>
                    <div className={`col-xs-8 ${this.styles.headerCol}`}>
                        <HeaderToggle
                            arrowUp={sortDirections.title === 'desc'}
                            header="Suggested Content"
                            onClick={this.handleSortByTitleClick}
                        />
                    </div>
                    <div className={`col-xs-4 ${this.styles.headerCol}`}>
                        <HeaderToggle
                            arrowUp={sortDirections.created_at === 'desc'}
                            header="Added"
                            onClick={this.handleSortByCreatedAtClick}
                        />
                    </div>
                </div>
                {customItems}
            </div>
        );
    }

    renderContent() {
        return (
            <div>
                <div className={`row ${this.styles.buttonContainer}`}>
                    {this.renderAddComponent()}
                </div>
                {this.renderItems()}
            </div>
        );
    }

    render() {
        const { sectionType } = this.props;

        return (
            <Main>
                <SectionTitle
                    info="Suggested content will be provided to users while drafting documents"
                    title="Suggested Content"
                />
                <div className={this.styles.filtersLabel}>
                    <div>
                        <strong>Section:</strong>&nbsp;
                        {get(
                            sectionTypeOptions.find((opt) => opt.value === sectionType),
                            'label'
                        )}
                    </div>
                </div>
                {this.renderContent()}
            </Main>
        );
    }
}

export const sections = [
    {
        title: 'Company Information',
        path: 'info',
    },
    {
        title: 'Category Codes',
        path: 'categories',
    },
    {
        title: 'Certifications',
        path: 'certifications',
    },
    {
        title: 'Documents',
        path: 'documents',
    },
];

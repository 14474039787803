import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { ModuleManager } from '../ModuleManager';
import { ModuleTitle } from '../ModuleTitle';
import { Collapse } from '../../../../../components';

export class ModuleManagerSequence extends PureComponent {
    static propTypes = {
        isPhaseComplete: PropTypes.bool,
        moduleData: PropTypes.shape({
            isLastUsed: PropTypes.bool,
        }).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = { showModule: props.moduleData.isLastUsed };
    }

    get styles() {
        return require('../shared.scss');
    }

    toggleModule = () => {
        this.setState((prevState) => ({ showModule: !prevState.showModule }));
    };

    render() {
        const { isPhaseComplete, moduleData } = this.props;

        return (
            <div className={this.styles.moduleSequenceContainer}>
                <ModuleTitle
                    {...moduleData}
                    isPhaseComplete={isPhaseComplete}
                    show={this.state.showModule}
                    toggleDisplay={this.toggleModule}
                />
                <Collapse in={this.state.showModule}>
                    <ModuleManager {...this.props} />
                </Collapse>
            </div>
        );
    }
}

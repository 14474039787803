import PropTypes from 'prop-types';
import React from 'react';

import { StatusLabel } from '..';

export const VendorCell = ({ isUnsubmittedScorecard, proposal: { isExcluded }, value }) => {
    let extraInformation;

    if (isExcluded) {
        extraInformation = (
            <div>
                <StatusLabel bsStyle="muted">Excluded</StatusLabel>
            </div>
        );
    } else if (isUnsubmittedScorecard) {
        extraInformation = (
            <div style={{ fontSize: '0.8em' }}>
                <em>
                    <i className="fa fa-exclamation-triangle" /> Unsubmitted scorecard
                </em>
            </div>
        );
    }

    return (
        <>
            {value}
            {extraInformation}
        </>
    );
};

VendorCell.propTypes = {
    isUnsubmittedScorecard: PropTypes.bool,
    proposal: PropTypes.shape({
        isExcluded: PropTypes.bool,
    }).isRequired,
    value: PropTypes.string.isRequired,
};

export function normalizeText(value) {
    if (!value) {
        return value;
    }

    return value.replace(/(\s\s)\s/g, '$1');
}

export function normalizePhone(value) {
    if (!value) {
        return value;
    }

    const onlyNums = value.replace(/[^\d]/g, '');
    const inputLength = onlyNums.length;

    if (inputLength === 0) {
        return '';
    }

    if (inputLength <= 3) {
        return `(${onlyNums}`;
    }

    if (inputLength <= 6) {
        return `(${onlyNums.slice(0, 3)})${onlyNums.slice(3)}`;
    }

    return `(${onlyNums.slice(0, 3)})${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
}

export function normalizePhoneExt(value) {
    if (!value) {
        return value;
    }

    const onlyPhoneKeyPad = value.replace(/[^\d*#]/g, '');
    const inputLength = onlyPhoneKeyPad.length;

    if (inputLength === 0) {
        return '';
    }

    if (inputLength > 16) {
        return onlyPhoneKeyPad.slice(0, 16);
    }

    return onlyPhoneKeyPad;
}

export function normalizeDate(value) {
    if (!value || typeof value !== 'string') return value;
    return new Date(value);
}

export function integerString(value) {
    if (!value) return null;
    return value.replace(/[^0-9]/g, '');
}

export function numberString(value) {
    if (!value) return null;
    return value.replace(/[^0-9.]/g, '');
}

/**
 * Normalizer for dollar strings. Will round any additional decimal places to
 * keep to 4 decimal places.
 * @param {string} value
 * @param {object} [opts]
 * @param {number} [opts.maximumFractionDigits]
 * @returns {number} Normalized string for redux-form
 */
export function dollarString(
    value,
    opts = {
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
        allowNegative: false,
        includeDollarSign: false,
    }
) {
    if (!value) {
        return value;
    }

    const isNegative = value < 0;

    const normalizedValue = numberString(value);
    const [dollars, cents] = normalizedValue.split('.');

    let formattedDollarString = dollars;

    if (opts.includeDollarSign) {
        formattedDollarString = `$${formattedDollarString}`;
    }

    if (opts.allowNegative && isNegative) {
        formattedDollarString = `-${formattedDollarString}`;
    }

    if (cents !== undefined) {
        return `${formattedDollarString}.${cents.substring(0, opts.maximumFractionDigits || 2)}`;
    }

    if (opts.minimumFractionDigits > 0) {
        return `${formattedDollarString}.${'0'.repeat(opts.minimumFractionDigits)}`;
    }

    return formattedDollarString;
}

export function normalizeNumber(value) {
    if (!value) return null;

    if (typeof value === 'number') {
        return value;
    }

    // Remove all non-numbers from the string and convert to number
    const formattedNumStr = numberString(value);

    return Number.parseFloat(formattedNumStr);
}

export function limitTextLength(maxLength) {
    return (value) => {
        if (value && value.length > maxLength) {
            return value.slice(0, maxLength);
        }
        return value;
    };
}

export function zipCodeString(value) {
    if (!value) return null;
    return value.replace(/[^0-9-]/g, '');
}

export function normalizeScoreInput(value) {
    // Prevents value from having more than 2 decimals places
    if (value) {
        return Math.round(value * 100) / 100;
    }
    return value;
}

import React from 'react';

import { FieldArray } from 'redux-form';

import { ENUM_OPTIONS_FIELD } from '@og-pro/shared-config/customFormService/customField';

import PropTypes from 'prop-types';

import { OptionsField } from './OptionsField';

export const MultipleChoiceOptions = ({ disabled }) => {
    return <FieldArray component={OptionsField} disabled={disabled} name={ENUM_OPTIONS_FIELD} />;
};

MultipleChoiceOptions.propTypes = {
    disabled: PropTypes.bool,
};

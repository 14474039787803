import React, { Component } from 'react';
import { Panel } from 'react-bootstrap';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { LoadingError, LoadingSpinner, SectionTitle } from '../../../components';
import { loadPublicAwardedProjects } from '../../../actions/vendProposals';
import connectData from '../../ConnectData';
import { getAwardedProjects } from './selectors';
import { ProjectList } from '../../../components/PublicApp';

const fetchData = (getState, dispatch) => dispatch(loadPublicAwardedProjects());

const mapStateToProps = (state) => ({
    awardedProjects: getAwardedProjects(state),
    awardedProjectsError: state.vendProposals.get('awardedProjectsError'),
    loadingAwardedProjects: state.vendProposals.get('loadingAwardedProjects'),
});

// @connectData
// @connect
class ConnectedAwardedProjects extends Component {
    static propTypes = {
        awardedProjects: PropTypes.arrayOf(PropTypes.object),
        awardedProjectsError: PropTypes.string,
        loadingAwardedProjects: PropTypes.bool,
    };

    renderProjectTable() {
        const { awardedProjects, awardedProjectsError, loadingAwardedProjects } = this.props;

        if (loadingAwardedProjects) {
            return <LoadingSpinner />;
        }

        if (awardedProjectsError) {
            return <LoadingError error={awardedProjectsError} />;
        }

        if (isEmpty(awardedProjects)) {
            return (
                <p className="text-muted text-center">
                    Your organization has not been publicly awarded any projects yet.
                </p>
            );
        }

        return (
            <ProjectList
                includeLogo
                projectPath={(project) => `/portal/${project.government.code}/projects`}
                projects={awardedProjects}
                showOrg
                showProjectId
            />
        );
    }

    render() {
        return (
            <Panel.Body id="skip">
                <SectionTitle title="Awarded Projects" />
                {this.renderProjectTable()}
            </Panel.Body>
        );
    }
}

export const AwardedProjects = compose(
    connectData(fetchData),
    connect(mapStateToProps)
)(ConnectedAwardedProjects);

export const ALL_APPENDICES = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    'AA',
    'BB',
    'CC',
    'DD',
    'EE',
    'FF',
    'GG',
    'HH',
    'II',
    'JJ',
    'KK',
    'LL',
    'MM',
    'NN',
    'OO',
    'PP',
    'QQ',
    'RR',
    'SS',
    'TT',
    'UU',
    'VV',
    'WW',
    'XX',
    'YY',
    'ZZ',
    'AAA',
    'BBB',
    'CCC',
    'DDD',
    'EEE',
    'FFF',
    'GGG',
    'HHH',
    'III',
    'JJJ',
    'KKK',
    'LLL',
    'MMM',
    'NNN',
    'OOO',
    'PPP',
    'QQQ',
    'RRR',
    'SSS',
    'TTT',
    'UUU',
    'VVV',
    'WWW',
    'XXX',
    'YYY',
    'ZZZ',
    'AAAA',
    'BBBB',
    'CCCC',
    'DDDD',
    'EEEE',
    'FFFF',
    'GGGG',
    'HHHH',
    'IIII',
    'JJJJ',
    'KKKK',
    'LLLL',
    'MMMM',
    'NNNN',
    'OOOO',
    'PPPP',
    'QQQQ',
    'RRRR',
    'SSSS',
    'TTTT',
    'UUUU',
    'VVVV',
    'WWWW',
    'XXXX',
    'YYYY',
    'ZZZZ',
    'AAAAA',
    'BBBBB',
    'CCCCC',
    'DDDDD',
    'EEEEE',
    'FFFFF',
    'GGGGG',
    'HHHHH',
    'IIIII',
    'JJJJJ',
    'KKKKK',
    'LLLLL',
    'MMMMM',
    'NNNNN',
    'OOOOO',
    'PPPPP',
    'QQQQQ',
    'RRRRR',
    'SSSSS',
    'TTTTT',
    'UUUUU',
    'VVVVV',
    'WWWWW',
    'XXXXX',
    'YYYYY',
    'ZZZZZ',
    'AAAAAA',
    'BBBBBB',
    'CCCCCC',
    'DDDDDD',
    'EEEEEE',
    'FFFFFF',
    'GGGGGG',
    'HHHHHH',
    'IIIIII',
    'JJJJJJ',
    'KKKKKK',
    'LLLLLL',
    'MMMMMM',
    'NNNNNN',
    'OOOOOO',
    'PPPPPP',
    'QQQQQQ',
    'RRRRRR',
    'SSSSSS',
    'TTTTTT',
    'UUUUUU',
    'VVVVVV',
    'WWWWWW',
    'XXXXXX',
    'YYYYYY',
    'ZZZZZZ',
    'AAAAAAA',
    'BBBBBBB',
    'CCCCCCC',
    'DDDDDDD',
    'EEEEEEE',
    'FFFFFFF',
    'GGGGGGG',
    'HHHHHHH',
    'IIIIIII',
    'JJJJJJJ',
    'KKKKKKK',
    'LLLLLLL',
    'MMMMMMM',
    'NNNNNNN',
    'OOOOOOO',
    'PPPPPPP',
    'QQQQQQQ',
    'RRRRRRR',
    'SSSSSSS',
    'TTTTTTT',
    'UUUUUUU',
    'VVVVVVV',
    'WWWWWWW',
    'XXXXXXX',
    'YYYYYYY',
    'ZZZZZZZ',
    'AAAAAAAA',
    'BBBBBBBB',
    'CCCCCCCC',
    'DDDDDDDD',
    'EEEEEEEE',
    'FFFFFFFF',
    'GGGGGGGG',
    'HHHHHHHH',
    'IIIIIIII',
    'JJJJJJJJ',
    'KKKKKKKK',
    'LLLLLLLL',
    'MMMMMMMM',
    'NNNNNNNN',
    'OOOOOOOO',
    'PPPPPPPP',
    'QQQQQQQQ',
    'RRRRRRRR',
    'SSSSSSSS',
    'TTTTTTTT',
    'UUUUUUUU',
    'VVVVVVVV',
    'WWWWWWWW',
    'XXXXXXXX',
    'YYYYYYYY',
    'ZZZZZZZZ',
];

const { listToDict } = require('@og-pro/shared-config/helpers');
const { vendorUploadTypesDict } = require('@og-pro/shared-config/vendorUploads');

exports.requiredVendorDatumFields = [
    'addressRequired',
    'businessTypeRequired',
    'categoryCodesRequired',
    'coiDocRequired',
    'dbeCertificationsRequired',
    'dunsRequired',
    'einRequired',
    'faxPhoneRequired',
    'stateOfIncorporationRequired',
    'uniqueEntityIdRequired',
    'w9DocRequired',
    'websiteRequired',
];

exports.requiredVendorDatumFieldNames = listToDict(exports.requiredVendorDatumFields);

exports.vendorDatumProfileKeys = [
    exports.requiredVendorDatumFieldNames.ADDRESS_REQUIRED,
    exports.requiredVendorDatumFieldNames.BUSINESS_TYPE_REQUIRED,
    exports.requiredVendorDatumFieldNames.DUNS_REQUIRED,
    exports.requiredVendorDatumFieldNames.EIN_REQUIRED,
    exports.requiredVendorDatumFieldNames.FAX_PHONE_REQUIRED,
    exports.requiredVendorDatumFieldNames.STATE_OF_INCORPORATION_REQUIRED,
    exports.requiredVendorDatumFieldNames.UNIQUE_ENTITY_ID_REQUIRED,
    exports.requiredVendorDatumFieldNames.WEBSITE_REQUIRED,
];

exports.vendorDatumAttachmentKeys = [
    exports.requiredVendorDatumFieldNames.COI_DOC_REQUIRED,
    exports.requiredVendorDatumFieldNames.W_9_DOC_REQUIRED,
];

/**
 * Checks if a vendor is missing data a particular government has required in order to perform
 * certain actions such as subscribe to the government or ask a question in Q & A.
 * Returns an array of the missing keys.
 * @param {object} government The government being checked
 * @param {object} vendor The vendor being checked
 * @return {string[]} Array of strings for any missing government required data keys
 */
exports.getIncompleteVendorDatumKeys = (government, vendor) => {
    const incompleteVendorDatumKeys = [];
    const requiredVendorDatum = government.requiredVendorDatum;

    if (!requiredVendorDatum || !vendor) {
        return incompleteVendorDatumKeys;
    }

    const requiredDatumKeys = Object.keys(requiredVendorDatum).reduce((acc, key) => {
        const value = requiredVendorDatum[key];
        if (typeof value === 'boolean' && value) {
            acc.push(key);
        }
        return acc;
    }, []);

    if (requiredDatumKeys.length === 0) {
        return incompleteVendorDatumKeys;
    }

    const {
        ADDRESS_REQUIRED,
        BUSINESS_TYPE_REQUIRED,
        CATEGORY_CODES_REQUIRED,
        COI_DOC_REQUIRED,
        DBE_CERTIFICATIONS_REQUIRED,
        DUNS_REQUIRED,
        EIN_REQUIRED,
        FAX_PHONE_REQUIRED,
        STATE_OF_INCORPORATION_REQUIRED,
        UNIQUE_ENTITY_ID_REQUIRED,
        W_9_DOC_REQUIRED,
        WEBSITE_REQUIRED,
    } = exports.requiredVendorDatumFieldNames;

    const { CERTIFICATE_OF_INSURANCE, W_9 } = vendorUploadTypesDict;

    const { organization: org } = vendor;

    const attachmentMissing = (type) => {
        if (vendor.attachments.length === 0) {
            return true;
        }

        return vendor.attachments.every(
            (attachment) => attachment.vendorAttachments.vendor_upload_type !== type
        );
    };

    const certificationsMissing = () => {
        return (
            !vendor.noDbeDesignations &&
            vendor.certifications.every((cert) => !cert.vendorCertifications)
        );
    };

    for (let i = 0; i < requiredDatumKeys.length; i++) {
        switch (requiredDatumKeys[i]) {
            case ADDRESS_REQUIRED:
                if (!org.address1 || !org.city || !org.countryCode || !org.state || !org.zipCode) {
                    incompleteVendorDatumKeys.push(ADDRESS_REQUIRED);
                }
                break;
            case BUSINESS_TYPE_REQUIRED:
                if (!vendor.businessType) {
                    incompleteVendorDatumKeys.push(BUSINESS_TYPE_REQUIRED);
                }
                break;
            case CATEGORY_CODES_REQUIRED:
                if (
                    vendor.categories.length === 0 ||
                    !vendor.categories.some((cat) => cat.setId === government.categorySetId)
                ) {
                    incompleteVendorDatumKeys.push(CATEGORY_CODES_REQUIRED);
                }
                break;
            case COI_DOC_REQUIRED:
                if (attachmentMissing(CERTIFICATE_OF_INSURANCE)) {
                    incompleteVendorDatumKeys.push(COI_DOC_REQUIRED);
                }
                break;
            case DBE_CERTIFICATIONS_REQUIRED:
                if (certificationsMissing()) {
                    incompleteVendorDatumKeys.push(DBE_CERTIFICATIONS_REQUIRED);
                }
                break;
            case DUNS_REQUIRED:
                if (!vendor.duns) {
                    incompleteVendorDatumKeys.push(DUNS_REQUIRED);
                }
                break;
            case EIN_REQUIRED:
                if (!vendor.ein) {
                    incompleteVendorDatumKeys.push(EIN_REQUIRED);
                }
                break;
            case FAX_PHONE_REQUIRED:
                if (!org.faxPhoneComplete) {
                    incompleteVendorDatumKeys.push(FAX_PHONE_REQUIRED);
                }
                break;
            case STATE_OF_INCORPORATION_REQUIRED:
                if (!vendor.stateOfIncorporation) {
                    incompleteVendorDatumKeys.push(STATE_OF_INCORPORATION_REQUIRED);
                }
                break;
            case UNIQUE_ENTITY_ID_REQUIRED:
                if (!vendor.uniqueEntityId) {
                    incompleteVendorDatumKeys.push(UNIQUE_ENTITY_ID_REQUIRED);
                }
                break;
            case W_9_DOC_REQUIRED:
                if (attachmentMissing(W_9)) {
                    incompleteVendorDatumKeys.push(W_9_DOC_REQUIRED);
                }
                break;
            case WEBSITE_REQUIRED:
                if (!org.website) {
                    incompleteVendorDatumKeys.push(WEBSITE_REQUIRED);
                }
                break;
            default:
                break;
        }
    }

    return incompleteVendorDatumKeys;
};

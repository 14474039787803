import { Add as AddIcon } from '@mui/icons-material';
import { Button, Typography } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';

import { createRequisitionGroup } from '../../../../actions/requisitionGroups';

export const RequisitionGroupCreateButton = ({ routingHandler, size }) => {
    const [creating, setCreating] = useState(false);
    const [createError, setCreateError] = useState(null);
    const dispatch = useDispatch();

    const enableCustomFields = useFlags(FLAGS.ENABLE_CUSTOM_FIELDS);

    const createHandler = () => {
        setCreating(true);
        setCreateError(null);
        dispatch(createRequisitionGroup({ excludeRequestType: enableCustomFields }))
            .then((reviewGroup) => {
                setCreating(false);
                routingHandler(reviewGroup.id);
            })
            .catch((error) => {
                setCreating(false);
                setCreateError(error.message);
            });
    };

    return (
        <>
            <Button
                color="primary"
                disabled={creating}
                onClick={createHandler}
                qaTag="requisitionGroup-create"
                size={size}
                startIcon={<AddIcon />}
                variant="contained"
            >
                New Request Group
            </Button>
            {createError && (
                <div className="error-block">
                    <Typography component="span" variant="bodySmall">
                        {createError}
                    </Typography>
                </div>
            )}
        </>
    );
};

RequisitionGroupCreateButton.propTypes = {
    routingHandler: PropTypes.func.isRequired,
    size: PropTypes.string,
};

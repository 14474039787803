import { listToDict } from '@og-pro/shared-config/helpers';

export const qaTagName = 'requestTypesList';

const cols = ['name', 'status', 'createdBy', 'updated_at'];

export const colsDict = listToDict(cols);

const { NAME, STATUS, CREATED_BY, UPDATED_AT } = colsDict;

export const defaultSortConfig = {
    sortBy: NAME,
    sortDirection: 'asc',
};

export const columns = {
    [NAME]: {
        label: 'Request Type Name',
        sortKey: NAME,
        headProps: { align: 'left' },
        bodyProps: { align: 'left' },
    },
    [STATUS]: {
        label: 'Status',
        sortKey: STATUS,
        headProps: { align: 'center' },
        bodyProps: { align: 'center', width: 90 },
    },
    [CREATED_BY]: {
        label: 'Created By',
        sortKey: 'user.displayName',
        headProps: { align: 'left' },
        bodyProps: { align: 'left' },
    },
    [UPDATED_AT]: {
        label: 'Last Updated',
        sortKey: UPDATED_AT,
        headProps: { align: 'right' },
        bodyProps: { align: 'right', width: 180 },
    },
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm } from 'redux-form';

import { BulkUpdateCollapsibleFormSection } from './BulkUpdateCollapsibleFormSection';
import { validate } from './validate';
import { bulkUpdateFieldNames, form } from '../constants';
import { getUserFullName } from '../helpers';
import {
    getBulkUpdateFormValues,
    getBulkUpdateSelectOptions,
    getShowBulkUpdateModal,
} from '../../selectors';
import { Button } from '../../../../components';
import { showConfirmationModal } from '../../../../actions/confirmation';
import { hideBulkUpdateModal } from '../../../../actions/admin';
import { CONFIRM_BULK_CONTACTS_UPDATE } from '../../../../constants/menuActions';
import { BulkUpdateHasErrorsField } from './BulkUpdateHasErrorsField';
import { getActiveUsersJS } from '../../../selectors';

const formConfig = {
    form,
    validate,
};

const {
    PROJECTS_ENABLED,
    INTAKES_ENABLED,
    CONTRACTS_ENABLED,
    PROJECTS_PROCUREMENT_CONTACT,
    PROJECTS_CONTACT,
    INTAKES_PROCUREMENT_CONTACT,
    INTAKES_CONTACT,
    CONTRACTS_PROCUREMENT_CONTACT,
    CONTRACTS_CONTACT,
} = bulkUpdateFieldNames;

const toggleInitialState = {
    [PROJECTS_ENABLED]: true,
    [INTAKES_ENABLED]: true,
    [CONTRACTS_ENABLED]: true,
};

const ConnectedBulkUpdateContactsModal = ({
    bulkUpdateAndDisable,
    change,
    handleSubmit,
    oldUser,
    reset,
}) => {
    const styles = require('./index.scss');
    const dispatch = useDispatch();

    const formValues = useSelector(getBulkUpdateFormValues);
    const selectOptions = useSelector(getBulkUpdateSelectOptions);
    const showModal = useSelector(getShowBulkUpdateModal);
    const users = useSelector(getActiveUsersJS);

    const [toggles, setToggles] = useState(toggleInitialState);
    const [error, setError] = useState(null);

    useEffect(() => {
        setToggles(toggleInitialState);
        setError(null);
    }, [showModal]);

    const validateForm = () => {
        if (
            bulkUpdateAndDisable &&
            toggles[PROJECTS_ENABLED] &&
            toggles[INTAKES_ENABLED] &&
            toggles[CONTRACTS_ENABLED]
        ) {
            return false;
        }

        if (
            !bulkUpdateAndDisable &&
            toggles[PROJECTS_ENABLED] &&
            toggles[INTAKES_ENABLED] &&
            toggles[CONTRACTS_ENABLED]
        ) {
            setError('You must select at least one replacement contact.');
            return true;
        }

        return false;
    };

    const resetToggles = () => {
        setToggles(toggleInitialState);
    };

    const handleToggleChange = (event) => {
        const fieldName = event.target.name;

        if (fieldName === PROJECTS_ENABLED && toggles[fieldName]) {
            change(PROJECTS_PROCUREMENT_CONTACT, '');
            change(PROJECTS_CONTACT, '');
        }

        if (fieldName === INTAKES_ENABLED && toggles[fieldName]) {
            change(INTAKES_PROCUREMENT_CONTACT, '');
            change(INTAKES_CONTACT, '');
        }

        if (fieldName === CONTRACTS_ENABLED && toggles[fieldName]) {
            change(CONTRACTS_CONTACT, '');
            change(CONTRACTS_PROCUREMENT_CONTACT, '');
        }

        setToggles({
            ...toggles,
            [fieldName]: !toggles[fieldName],
        });
        setError(null);
    };

    const getConfirmButtonText = () => {
        if (bulkUpdateAndDisable && !!formValues) {
            return 'Disable & Replace';
        }
        if (!bulkUpdateAndDisable) {
            return 'Replace';
        }
        return 'Disable';
    };

    const handleConfirm = (formData) => {
        const errors = validateForm();
        if (!errors) {
            dispatch(hideBulkUpdateModal());
            dispatch(
                showConfirmationModal(CONFIRM_BULK_CONTACTS_UPDATE, {
                    buttonText: getConfirmButtonText(),
                    disable: bulkUpdateAndDisable,
                    formValues: formData,
                    oldUser,
                    users,
                })
            );
            resetToggles();
        }
    };

    const handleCancel = () => {
        dispatch(hideBulkUpdateModal());
        dispatch(reset());
        resetToggles();
    };

    const renderForm = () => {
        const oldUserFullName = getUserFullName(users, oldUser);

        return (
            <form>
                {bulkUpdateAndDisable && (
                    <div className={styles.title}>
                        {`Disable: ${oldUserFullName}`}
                        <div className={styles.subText}>
                            They&apos;ll need to re-activate if their account is ever restored.
                        </div>
                    </div>
                )}
                <div
                    className={styles.title}
                >{`Replace ${oldUserFullName} on active assignments:`}</div>
                <BulkUpdateCollapsibleFormSection
                    collapsed={toggles[PROJECTS_ENABLED]}
                    name={PROJECTS_ENABLED}
                    onChange={handleToggleChange}
                    selectOptions={selectOptions}
                />
                <BulkUpdateCollapsibleFormSection
                    collapsed={toggles[INTAKES_ENABLED]}
                    name={INTAKES_ENABLED}
                    onChange={handleToggleChange}
                    selectOptions={selectOptions}
                />
                <BulkUpdateCollapsibleFormSection
                    collapsed={toggles[CONTRACTS_ENABLED]}
                    name={CONTRACTS_ENABLED}
                    onChange={handleToggleChange}
                    selectOptions={selectOptions}
                />
                <BulkUpdateHasErrorsField error={error} />
                <div className={styles.buttonContainer}>
                    <Button onClick={handleCancel} qaTag="bulkUpdateContactsModal-cancel">
                        Cancel
                    </Button>
                    <Button
                        bsStyle="primary"
                        onClick={handleSubmit(handleConfirm)}
                        qaTag="bulkUpdateContactsModal-confirm"
                    >
                        Continue
                    </Button>
                </div>
            </form>
        );
    };

    return (
        <Modal onHide={handleCancel} show={showModal}>
            <Modal.Header closeButton>
                <Modal.Title className="text-center">
                    {bulkUpdateAndDisable ? 'Disable User and ' : ''}Replace Contact
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>{renderForm()}</Modal.Body>
        </Modal>
    );
};

ConnectedBulkUpdateContactsModal.propTypes = {
    bulkUpdateAndDisable: PropTypes.bool.isRequired,
    change: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    oldUser: PropTypes.number,
    reset: PropTypes.func.isRequired,
};

export const BulkUpdateContactsModal = reduxForm(formConfig)(ConnectedBulkUpdateContactsModal);

import {
    fieldNames,
    requestTypeGroupSequenceFieldNames,
    reviewGroupPositionFieldNames,
    reviewGroupPositionUsersFieldNames,
} from '../constants';

const { REVIEW_GROUP_POSITIONS, REQUEST_TYPE_GROUP_SEQUENCES } = fieldNames;
const { REVIEW_SEQUENCE_ID } = requestTypeGroupSequenceFieldNames;
const { REVIEW_GROUP_POSITION_USERS, USER_IDS: REVIEW_GROUP_POSITION_USER_IDS } =
    reviewGroupPositionFieldNames;
const { IS_PRIMARY } = reviewGroupPositionUsersFieldNames;

export default function validateSequence(values) {
    const errors = {};

    const reviewSequenceId = values[REQUEST_TYPE_GROUP_SEQUENCES]?.[0]?.[REVIEW_SEQUENCE_ID];
    if (!reviewSequenceId) {
        errors[REQUEST_TYPE_GROUP_SEQUENCES] = [
            { [REVIEW_SEQUENCE_ID]: 'Please select an approval sequence to use' },
        ];
    }

    errors[REVIEW_GROUP_POSITIONS] = (values[REVIEW_GROUP_POSITIONS] || []).map((position) => {
        const positionErrors = {};

        if ((position[REVIEW_GROUP_POSITION_USER_IDS] || []).length === 0) {
            positionErrors[REVIEW_GROUP_POSITION_USER_IDS] =
                'At least one user must be assigned to the position group';
        } else if (
            (position[REVIEW_GROUP_POSITION_USERS] || []).every(
                (reviewGroupPositionUser) => !reviewGroupPositionUser[IS_PRIMARY]
            )
        ) {
            positionErrors[REVIEW_GROUP_POSITION_USER_IDS] = 'A primary user must be assigned';
        }

        return positionErrors;
    });

    return errors;
}

import { password } from '../../../Forms/validation';

export const validate = (values) => {
    const errors = {
        ...password(values),
    };

    if (!values.password) {
        errors.password = 'This field is required';
    }

    return errors;
};

import { pick } from 'lodash';
import { createSelector } from 'reselect';

import { IS_PUBLISHED, USER_ID } from '../TemplateQuestionCreateForm/constants';

export const getLoading = (state) => state.templateQuestions.get('loadingItem');
export const getLoadError = (state) => state.templateQuestions.get('loadItemError');

export const getLoadingTemplatesInUse = (state) =>
    state.templateQuestions.get('loadingTemplatesInUse');
export const getLoadTemplatesInUseError = (state) =>
    state.templateQuestions.get('loadTemplatesInUseError');

const getRawTemplateQuestion = (state) => state.templateQuestions.get('templateQuestion');
const getRawTemplatesInUse = (state) => state.templateQuestions.get('templatesInUse');

const getTemplateQuestion = createSelector([getRawTemplateQuestion], (rawTemplateQuestion) => {
    if (rawTemplateQuestion) {
        return rawTemplateQuestion.toJS();
    }
});

export const getDeserializedTemplateQuestion = createSelector(
    [getTemplateQuestion],
    (templateQuestion) => {
        if (templateQuestion) {
            const { upfrontQuestion, ...templateQuestionData } = templateQuestion;
            return {
                ...pick(templateQuestionData, [IS_PUBLISHED, USER_ID]),
                ...upfrontQuestion,
            };
        }
    }
);

export const getTemplatesInUse = createSelector([getRawTemplatesInUse], (rawTemplatesInUse) => {
    return rawTemplatesInUse.toJS();
});

import { Add as AddIcon } from '@mui/icons-material';
import { Box, Button, Typography } from '@og-pro/ui';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { qaTagPageName } from '../constants';
import { getSubscriptionInfo } from '../selectors';

const getResourceText = (hasIntake, hasSolicitations, hasContracts) => {
    const items = [];
    if (hasIntake) {
        items.push('Intakes');
    }
    if (hasSolicitations) {
        items.push('Solicitations');
    }
    if (hasContracts) {
        items.push('Contracts');
    }

    if (items.length === 3) {
        items[2] = `or ${items[2]}`;
        return items.join(', ');
    }
    if (items.length === 2) {
        return items.join(' or ');
    }
    return items.join('');
};

export const RelationsZeroState = ({ addHandler }) => {
    const { hasContracting, hasIntake, hasSolicitations } = useSelector(getSubscriptionInfo);
    const resourceText = getResourceText(hasIntake, hasSolicitations, hasContracting);

    return (
        <Box display="flex" flexDirection="column" gap={2} paddingTop={2} textAlign="center">
            <Box color={capitalDesignTokens.foundations.colors.gray600}>
                <i className="fa fa-folder-open fa-5x" />
            </Box>
            <div>
                <Typography
                    fontWeight={capitalDesignTokens.foundations.typography.fontWeight.normal}
                    paddingBottom={1}
                    variant="h3"
                >
                    No Related Items
                </Typography>
                <Box
                    color={capitalDesignTokens.foundations.colors.gray800}
                    fontSize={capitalDesignTokens.foundations.typography.fontSize.bodyDefault}
                >
                    No {resourceText} are currently associated with this request.
                </Box>
            </div>
            <div>
                <Button
                    onClick={addHandler}
                    qaTag={`${qaTagPageName}-addItem`}
                    startIcon={<AddIcon />}
                    variant="outlined"
                >
                    Add Related
                </Button>
            </div>
        </Box>
    );
};

RelationsZeroState.propTypes = {
    addHandler: PropTypes.func.isRequired,
};

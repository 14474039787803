import PropTypes from 'prop-types';
import React from 'react';

import { StepApprovalLog } from './StepApprovalLog';

export const StepApprovalLogs = ({ requisitionLogs = [] }) => {
    const styles = require('./StepApprovalLogs.scss');

    if (requisitionLogs.length === 0) {
        return null;
    }

    return (
        <div className={styles.requisitionLogs}>
            {requisitionLogs.map((requisitionLog, index) => (
                <div className={styles.requisitionLog} key={index}>
                    <StepApprovalLog requisitionLog={requisitionLog} />
                </div>
            ))}
        </div>
    );
};

StepApprovalLogs.propTypes = {
    requisitionLogs: PropTypes.array,
};

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'redux';
import moment from 'moment';
import { change, Field, reduxForm } from 'redux-form';

import { fieldNames, form } from './constants';
import { getUserJS } from '../../selectors';
import { Button, SearchSelect } from '../../../components';
import { getActiveRetentionCodes } from '../../GovApp/selectors';
import {
    formatPolicyOptions,
    getFormattedRetentionPolicyFromId,
    loadRetentionCodes,
} from './utils';

const { RETENTION_POLICY } = fieldNames;

const mapStateToProps = (state) => {
    return {
        retentionCodes: getActiveRetentionCodes(state),
        user: getUserJS(state),
    };
};

const formConfig = {
    form,
};

// @reduxForm
// connect
const ConnectedUpdateRetentionPolicyForm = ({
    closeDate,
    handleSubmit,
    initialValues,
    retentionCodes,
    user,
}) => {
    const dispatch = useDispatch();
    const [policy, setPolicy] = useState();
    const styles = require('./index.scss');

    const setPolicyDropdownAndFormValue = (newPolicy) => {
        setPolicy(newPolicy);
        dispatch(change(form, RETENTION_POLICY, newPolicy ? newPolicy.value : null));
    };

    useEffect(() => {
        loadRetentionCodes(dispatch, retentionCodes, user);
    }, []);

    useEffect(() => {
        if (retentionCodes.length > 0 && initialValues.retentionPolicy) {
            const formattedCurrentOption = getFormattedRetentionPolicyFromId(
                initialValues.retentionPolicy,
                retentionCodes
            );
            setPolicyDropdownAndFormValue(formattedCurrentOption);
        }
    }, [retentionCodes]);

    const showExpiry = closeDate && policy && policy.archiveSchedule;

    return (
        <form className={styles.container} onSubmit={handleSubmit}>
            <div className={styles.containerInnerWrapper}>
                <div className={styles.retentionCodeSelectContainer}>
                    <span className={styles.retentionCodeSelectTitle}>Retention Policy</span>
                    {policy && (
                        <Button
                            bsStyle="link"
                            onClick={() => {
                                setPolicyDropdownAndFormValue(null);
                            }}
                            qaTag="updateRetentionPolicyForm-clearPolicy"
                            zeroPadding
                        >
                            Clear Policy
                        </Button>
                    )}
                </div>
                <Field
                    component={SearchSelect}
                    formClassName={styles.searchSelect}
                    name={RETENTION_POLICY}
                    onChange={(val) => {
                        const newPolicy = getFormattedRetentionPolicyFromId(val, retentionCodes);
                        setPolicyDropdownAndFormValue(newPolicy);
                    }}
                    options={formatPolicyOptions(retentionCodes)}
                    placeholder="No Policy Selected"
                    qaTag="updateRetentionPolicyForm-editPolicy"
                    useNullWhenUndefined
                />
                {!!showExpiry && (
                    <div className={styles.subText}>
                        <i className="fa fa-clock-o" /> Expires:{' '}
                        {moment(closeDate).add(policy.archiveSchedule, 'y').format('MMMM Do, YYYY')}
                    </div>
                )}
            </div>
        </form>
    );
};

ConnectedUpdateRetentionPolicyForm.propTypes = {
    closeDate: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    retentionCodes: PropTypes.array,
    user: PropTypes.shape({
        government_id: PropTypes.number,
    }).isRequired,
};

export const UpdateRetentionPolicyForm = compose(
    connect(mapStateToProps),
    reduxForm(formConfig)
)(ConnectedUpdateRetentionPolicyForm);

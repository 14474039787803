import React, { Component } from 'react';

import { METHOD_NAME } from '../store/loadDataDependencies';

/**
 * @callback wrapWithFetchData
 * @param {Component} WrappedDataComponent The component to wrap
 * @return {Component} The wrapped component
 */

/**
 * Wrap a component with a data fetching method to be called when the component is loaded.
 *
 * NOTE:
 * When this decorator is used, it MUST be the first (outermost) decorator.
 * Else, the fetchData method cannot be found or called.
 *
 * NOTE:
 * This only works if the component is the direct child of a react-router Route.
 *
 * @param {Function} fetchData The data fetching function for the component being wrapped
 * @return {wrapWithFetchData}
 */
export default function fetchComponentData(fetchData) {
    return function wrapWithFetchData(WrappedDataComponent) {
        class ConnectData extends Component {
            render() {
                return <WrappedDataComponent {...this.props} />;
            }
        }

        ConnectData[METHOD_NAME] = fetchData;
        return ConnectData;
    };
}

import { startCase } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Checkbox, Panel } from 'react-bootstrap';

import { inboxNotificationStatusesDict } from '@og-pro/shared-config/audits';

import { Button, DataTable, ViewEmailNotificationModal, ZeroState } from '../../../components';
import { dateTZFormatter } from '../../../helpers';

const { NEW, IN_PROGRESS, COMPLETED } = inboxNotificationStatusesDict;

const DEFAULT_SELECTED_NOTIFICATION_IDS = {};

export const NotificationsTable = ({
    collapsed,
    inboxNotifications,
    loadInboxNotificationEmail,
    tableType,
    timezone,
    updateInboxNotifications,
    updatingInboxNotifications,
    onLoadNotificationId,
    displayOnLoadNotification,
}) => {
    const pageRowsRef = useRef();
    const [emailData, setEmailData] = useState(null);
    const [loadEmailError, setLoadEmailError] = useState(null);
    const [loadingEmail, setLoadingEmail] = useState(false);
    const [selectedNotificationIds, setSelectedNotificationIds] = useState(
        DEFAULT_SELECTED_NOTIFICATION_IDS
    );
    const [showModal, setShowModal] = useState(false);

    const styles = require('./index.scss');
    const inboxNotificationsNum = inboxNotifications.length;
    const tableTitle = startCase(tableType);

    const openNotification = (notification) => {
        setLoadingEmail(true);
        setLoadEmailError(null);
        setShowModal(true);
        loadInboxNotificationEmail(notification.id)
            .then((result) => {
                if (result) {
                    setEmailData({
                        ...result.emailAudit.data,
                        createdAt: result.created_at,
                    });
                } else {
                    setLoadEmailError('Email data not found');
                }
                setLoadingEmail(false);
            })
            .catch((error) => {
                setLoadEmailError(error.message);
                setLoadingEmail(false);
            });
    };

    const checkboxChangeHandler = (notificationId) => {
        const { [`${notificationId}`]: idToRemove, ...rest } = selectedNotificationIds;
        if (idToRemove) {
            return setSelectedNotificationIds(rest);
        }
        setSelectedNotificationIds({
            ...selectedNotificationIds,
            [notificationId]: notificationId,
        });
    };

    const selectAllHandler = (checked) => {
        const updatedNotificationIds = {};
        if (checked) {
            pageRowsRef.current.forEach((row) => {
                updatedNotificationIds[row.id] = row.id;
            });
        }
        return setSelectedNotificationIds(updatedNotificationIds);
    };

    const columns = [
        {
            Header: () => {
                return (
                    <Checkbox
                        bsClass={styles.checkbox}
                        checked={
                            pageRowsRef.current &&
                            pageRowsRef.current.length ===
                                Object.keys(selectedNotificationIds).length
                        }
                        disabled={inboxNotificationsNum === 0}
                        onChange={(e) => selectAllHandler(e.target.checked)}
                    />
                );
            },
            accessor: 'id',
            width: 110,
            className: 'text-center',
            sortable: false,
            getProps: (state) => {
                pageRowsRef.current = state.pageRows;
                return {};
            },
            Cell: (params) => {
                const { value } = params;
                return (
                    <Checkbox
                        bsClass={styles.checkbox}
                        checked={!!selectedNotificationIds[value]}
                        onChange={() => checkboxChangeHandler(value)}
                    />
                );
            },
        },
        {
            Header: 'Date',
            accessor: 'created_at',
            className: 'text-center',
            width: 200,
            Cell: (params) => dateTZFormatter(timezone, 'lll')(params),
        },
        {
            Header: 'Subject',
            accessor: 'subject',
            Cell: (params) => {
                return (
                    <Button
                        bsStyle="link"
                        onClick={() => openNotification(params.original)}
                        zeroPadding
                    >
                        {params.value}
                    </Button>
                );
            },
        },
    ];

    const renderButtons = () => {
        const ids = [...Object.keys(selectedNotificationIds)];

        const statusesWithIcon = {
            [NEW]: 'inbox',
            [IN_PROGRESS]: 'clock-o',
            [COMPLETED]: 'check',
        };

        const clickHandler = (data) => {
            updateInboxNotifications(data);
            setSelectedNotificationIds(DEFAULT_SELECTED_NOTIFICATION_IDS);
        };

        return Object.keys(statusesWithIcon).reduce((buttons, status, idx) => {
            const disabled = updatingInboxNotifications || ids.length === 0;
            if (status !== tableType) {
                buttons.push(
                    <Button
                        bsSize="sm"
                        disabled={disabled}
                        key={idx}
                        onClick={() => clickHandler({ ids, status })}
                        tooltip={disabled ? 'Select row(s) to enable' : null}
                        tooltipPlacement="top"
                    >
                        <span>
                            <i className={`fa fa-${statusesWithIcon[status]}`} />
                            &nbsp;Mark as {startCase(status)}
                        </span>
                    </Button>
                );
            }
            return buttons;
        }, []);
    };

    useEffect(() => {
        if (displayOnLoadNotification && !Number.isNaN(onLoadNotificationId)) {
            openNotification({ id: onLoadNotificationId });
        }
    }, []);

    return (
        <>
            {inboxNotifications.length > 0 ? (
                <DataTable
                    buttons={renderButtons()}
                    className="-striped -highlight"
                    collapsed={collapsed}
                    collapsible
                    columns={columns}
                    data={inboxNotifications}
                    defaultPageSize={inboxNotificationsNum < 20 ? inboxNotificationsNum : 20}
                    showPagination={inboxNotificationsNum > 20}
                    title={`${tableTitle}${
                        inboxNotificationsNum ? ` (${inboxNotificationsNum})` : ''
                    }`}
                />
            ) : (
                <Panel>
                    <Panel.Body>
                        <ZeroState
                            containerClass={styles.zeroStateContainer}
                            info="No notifications at this time"
                            title={tableTitle}
                        />
                    </Panel.Body>
                </Panel>
            )}
            {showModal && (
                <ViewEmailNotificationModal
                    emailData={emailData}
                    hideModal={() => setShowModal(false)}
                    loadError={loadEmailError}
                    loading={loadingEmail}
                />
            )}
        </>
    );
};

NotificationsTable.propTypes = {
    collapsed: PropTypes.bool.isRequired,
    inboxNotifications: PropTypes.array.isRequired,
    loadInboxNotificationEmail: PropTypes.func.isRequired,
    tableType: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired,
    updateInboxNotifications: PropTypes.func.isRequired,
    updatingInboxNotifications: PropTypes.bool.isRequired,
    onLoadNotificationId: PropTypes.number,
    displayOnLoadNotification: PropTypes.bool,
};

import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { FiltersMenu } from '@og-pro/ui';
import { requisitionFiltersNames } from '@og-pro/shared-config/requisitions';

import { getCheckboxFilters, getIsGroupAdminOrMultipleGroupsLeader } from '../../selectors';

import { qaTagPageName } from '../../constants';
import { getFilterSections } from './helpers';
import { hasSourcingSubscription } from '../../../../../GovApp/App/selectors';

export const RequisitionListDetailedFilters = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const hasSourcing = useSelector(hasSourcingSubscription);

    const checkboxFilters = useSelector(getCheckboxFilters);
    const isGroupAdminOrMultipleGroupsLeader = useSelector((state) =>
        getIsGroupAdminOrMultipleGroupsLeader(state)
    );

    const {
        CREATORS,
        REVIEW_GROUPS,
        EXCEPTION_REVIEW_SEQUENCES,
        LAST_ACTION,
        ON,
        BEFORE,
        AFTER,
        START,
        END,
        SOURCING_STATUS,
    } = requisitionFiltersNames;

    const EMPTY_FORM_STATE = {
        [CREATORS]: [],
        [REVIEW_GROUPS]: [],
        [EXCEPTION_REVIEW_SEQUENCES]: [],
        [LAST_ACTION]: [],
        [ON]: null,
        [AFTER]: null,
        [BEFORE]: null,
        [START]: null,
        [END]: null,
        [SOURCING_STATUS]: false,
    };

    const checkboxOptions = {
        [LAST_ACTION]: checkboxFilters.find(({ filter }) => filter === LAST_ACTION)?.options || [],
        [CREATORS]: checkboxFilters.find(({ filter }) => filter === CREATORS)?.options || [],
        [REVIEW_GROUPS]:
            checkboxFilters.find(({ filter }) => filter === REVIEW_GROUPS)?.options || [],
        [EXCEPTION_REVIEW_SEQUENCES]:
            checkboxFilters.find(({ filter }) => filter === EXCEPTION_REVIEW_SEQUENCES)?.options ||
            [],
    };

    const creators = searchParams.get(CREATORS) ? searchParams.get(CREATORS).split(',') : [];
    const reviewGroups = searchParams.get(REVIEW_GROUPS)
        ? searchParams.get(REVIEW_GROUPS).split(',')
        : [];
    const exceptionReviewSequences = searchParams.get(EXCEPTION_REVIEW_SEQUENCES)
        ? searchParams.get(EXCEPTION_REVIEW_SEQUENCES).split(',')
        : [];
    const lastActions = searchParams.get(LAST_ACTION)
        ? searchParams.get(LAST_ACTION).split(',')
        : [];
    const on = searchParams.get(ON) === 'null' ? null : searchParams.get(ON);
    const after = searchParams.get(AFTER) === 'null' ? null : searchParams.get(AFTER);
    const before = searchParams.get(BEFORE) === 'null' ? null : searchParams.get(BEFORE);
    const start = searchParams.get(START) === 'null' ? null : searchParams.get(START);
    const end = searchParams.get(END) === 'null' ? null : searchParams.get(END);
    const sourcingStatus = searchParams.get(SOURCING_STATUS) === 'true';

    const dates = {
        [ON]: on,
        [AFTER]: after,
        [BEFORE]: before,
        [START]: start,
        [END]: end,
        [SOURCING_STATUS]: sourcingStatus,
    };

    const initialFormData = {
        [CREATORS]: creators,
        [REVIEW_GROUPS]: reviewGroups,
        [EXCEPTION_REVIEW_SEQUENCES]: exceptionReviewSequences,
        [LAST_ACTION]: lastActions,
        ...dates,
    };

    const [formData, setFormData] = useState(initialFormData);

    const handleSubmit = () => {
        searchParams.set(CREATORS, formData[CREATORS].join(','));
        searchParams.set(REVIEW_GROUPS, formData[REVIEW_GROUPS].join(','));
        searchParams.set(
            EXCEPTION_REVIEW_SEQUENCES,
            formData[EXCEPTION_REVIEW_SEQUENCES].join(',')
        );
        searchParams.set(LAST_ACTION, formData[LAST_ACTION].join(','));
        searchParams.set(ON, formData[ON]);
        searchParams.set(AFTER, formData[AFTER]);
        searchParams.set(BEFORE, formData[BEFORE]);
        searchParams.set(START, formData[START]);
        searchParams.set(END, formData[END]);
        searchParams.set(SOURCING_STATUS, formData[SOURCING_STATUS]);
        setSearchParams(searchParams);
    };

    const filterSections = useMemo(() => {
        return getFilterSections(
            formData,
            setFormData,
            checkboxOptions,
            isGroupAdminOrMultipleGroupsLeader,
            hasSourcing
        );
    }, [formData, setFormData, checkboxOptions]);

    return (
        <FiltersMenu
            emptyFormState={EMPTY_FORM_STATE}
            filterSections={filterSections}
            formData={formData}
            initialFormData={initialFormData}
            onSubmit={handleSubmit}
            qaTag={`${qaTagPageName}-detailedFilters`}
            setFormData={setFormData}
        />
    );
};

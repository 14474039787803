import { useDispatch } from 'react-redux';

import { useConnectSocket } from './socketHooks';
import { joinChecklist, leaveChecklist } from '../../actions/checklistSocket';
import { reloadTemplateChecklist } from '../../actions/checklistsAdmin';
import { reloadChecklist } from '../../actions/checklists';
import { checklistSocket } from '../../lib/sockets';

export const useChecklistSocket = (checklistId, isTemplate) => {
    const dispatch = useDispatch();
    useConnectSocket(checklistSocket, {
        onJoin: () => dispatch(joinChecklist(checklistId)),
        onLeave: () => dispatch(leaveChecklist(checklistId)),
        onReconnect: () => {
            if (isTemplate) {
                dispatch(reloadTemplateChecklist(checklistId));
            } else {
                dispatch(reloadChecklist(checklistId));
            }
        },
    });
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { compose } from 'redux';

import { Box } from '@mui/material';

import { DownloadAllButton, ProposalAttachmentItem } from './components';
import { getAttachmentSections } from '../selectors';
import { getProposalForEvaluationJS, getProposalJS } from '../../selectors';
import {
    downloadAllProposalDocuments,
    resetProposalDocumentsDownload,
} from '../../../../actions/govProposals';
import { BackButton, SectionTitle } from '../../../../components';
import { getUserJS } from '../../../selectors';

const mapStateToProps = (state, props) => {
    const proposalPath = props.location.pathname.replace(/\/documents\/?$/i, '');

    return {
        attachmentSections: getAttachmentSections(state, props),
        downloadDeferred: state.govProposals.get('downloadDeferred'),
        downloadError: state.govProposals.get('downloadError'),
        downloading: state.govProposals.get('downloading'),
        downloadUrl: state.govProposals.get('downloadUrl'),
        proposalPath,
        user: getUserJS(state, props),
        viewerPath: `${proposalPath}/documents`,
    };
};

const mapDispatchToProps = {
    downloadAllProposalDocuments,
    resetProposalDocumentsDownload,
};

// @connect
class ConnectedProposalDocumentsListViewer extends Component {
    static propTypes = {
        attachmentSections: PropTypes.arrayOf(
            PropTypes.shape({
                attachments: PropTypes.arrayOf(
                    PropTypes.shape({
                        paramsNumber: PropTypes.number,
                    })
                ).isRequired,
                sectionName: PropTypes.string.isRequired,
            })
        ).isRequired,
        downloadAllProposalDocuments: PropTypes.func.isRequired,
        downloadDeferred: PropTypes.bool,
        downloadError: PropTypes.string,
        downloading: PropTypes.bool.isRequired,
        downloadUrl: PropTypes.string,
        isProposalEvaluation: PropTypes.bool,
        proposal: PropTypes.shape({
            companyName: PropTypes.string,
            id: PropTypes.number.isRequired,
        }),
        proposalPath: PropTypes.string.isRequired,
        resetProposalDocumentsDownload: PropTypes.func.isRequired,
        user: PropTypes.shape({
            email: PropTypes.string.isRequired,
        }).isRequired,
        viewerPath: PropTypes.string.isRequired,
    };

    componentWillUnmount() {
        this.props.resetProposalDocumentsDownload();
    }

    get styles() {
        return require('./index.scss');
    }

    downloadAllDocuments = () => {
        const { proposal } = this.props;
        return this.props.downloadAllProposalDocuments(proposal.id);
    };

    renderAttachmentSections() {
        const { attachmentSections, viewerPath } = this.props;

        return attachmentSections.map((attachmentSection, index) => (
            <div className={this.styles.section} key={index}>
                <div className={this.styles.sectionHeader}>{attachmentSection.sectionName}</div>
                <ul className="list-unstyled">
                    {attachmentSection.attachments.length === 0 ? (
                        <li className={this.styles.sectionItem}>
                            <em>No documents included</em>
                        </li>
                    ) : (
                        attachmentSection.attachments.map((attachment, attachmentIndex) => (
                            <ProposalAttachmentItem
                                documentPath={`${viewerPath}/${attachment.paramsNumber}`}
                                key={attachmentIndex}
                                {...attachment}
                            />
                        ))
                    )}
                </ul>
            </div>
        ));
    }

    renderEmailConfirmation() {
        const { user } = this.props;

        return (
            <Box p={2} textAlign="center">
                <h4>Getting your files ready</h4>
                <p>
                    We&apos;ll send an email to <strong>{user.email}</strong> once your download is
                    ready.
                </p>
            </Box>
        );
    }

    render() {
        const {
            downloadError,
            downloading,
            downloadUrl,
            downloadDeferred,
            isProposalEvaluation,
            proposal,
            proposalPath,
        } = this.props;

        if (!proposal) {
            return null;
        }

        const companyName = proposal.companyName || 'Unnamed Company';

        return (
            <div className="row">
                <div className="col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2">
                    {!isProposalEvaluation && (
                        <BackButton text="Back to Response" to={proposalPath} />
                    )}
                    <SectionTitle
                        info="View or download response documents"
                        title={`${companyName} Response Documents Viewer`}
                    />
                    {this.renderAttachmentSections()}
                    <div className={this.styles.downloadContainer}>
                        <DownloadAllButton
                            downloadError={downloadError}
                            downloadHandler={this.downloadAllDocuments}
                            downloadUrl={downloadUrl}
                            downloading={downloading}
                        />
                        {downloadDeferred && this.renderEmailConfirmation()}
                    </div>
                </div>
            </div>
        );
    }
}

export const ProposalDocumentsListViewer = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedProposalDocumentsListViewer);

// Government sourcing container
ProposalDocumentsListViewer.Sourcing = connect((state) => {
    return {
        proposal: getProposalJS(state),
    };
})(ProposalDocumentsListViewer);

// Government evaluation container
ProposalDocumentsListViewer.Evaluation = connect((state) => {
    return {
        proposal: getProposalJS(state),
    };
})(ProposalDocumentsListViewer);

// Government proposal evaluation container
ProposalDocumentsListViewer.ProposalEvaluation = connect((state) => {
    return {
        isProposalEvaluation: true,
        proposal: getProposalForEvaluationJS(state),
    };
})(ProposalDocumentsListViewer);

const _ = require('lodash');

const { projectTimelineConfigurations } = require('.');

/**
 * Normalizes standard and custom timelines for a project
 * @param {object} project Sequelize project instance in JSON format
 * @returns {object} Normalized `timelines` object with array of timelines sorted by `orderById`
 */
exports.timelineNormalizer = (project) => {
    const timelines = project.timelines || [];
    const timelineConfig = project.timelineConfig || {};

    const projectTimelines = projectTimelineConfigurations
        .map((configuration) => ({
            ...configuration,
            title: timelineConfig[configuration.titleField] || configuration.title,
            date: project[configuration.projectField],
            isIncluded:
                !configuration.isIncludedField || timelineConfig[configuration.isIncludedField],
            isRequired: timelineConfig[configuration.isRequiredField],
            location: timelineConfig[configuration.locationField],
            orderById: timelineConfig[configuration.orderByIdField],
            uuid: configuration.projectField,
        }))
        .filter((configuration) => configuration.isIncluded);

    const allTimelines = [...projectTimelines, ...timelines].map((timeline) => {
        if (timeline.date) {
            return { ...timeline, date: new Date(timeline.date) };
        }
        return timeline;
    });

    return { timelines: _.sortBy(allTimelines, 'orderById') };
};

import { fromJS } from 'immutable';

import * as requisitionsDashboardActions from '../actions/requisitionsDashboard';

const initialState = fromJS({
    list: [],
    searchError: null,
    searching: false,
    summary: {},
    loadingRequisitionsSummary: false,
    loadRequisitionsSummaryError: null,
});

export default function requisitionsDashboardReducer(state = initialState, action = {}) {
    switch (action.type) {
        case requisitionsDashboardActions.SEARCH_REQUISITIONS:
            return state.merge(
                fromJS({
                    searchError: null,
                    searching: true,
                })
            );
        case requisitionsDashboardActions.SEARCH_REQUISITIONS_FAILURE:
            return state.merge(
                fromJS({
                    searchError: action.error?.message,
                    searching: false,
                })
            );
        case requisitionsDashboardActions.SEARCH_REQUISITIONS_SUCCESS:
            return state.merge(
                fromJS({
                    list: fromJS(action.result),
                    searching: false,
                })
            );

        case requisitionsDashboardActions.LOAD_REQUISITIONS_SUMMARY:
            return state.merge(
                fromJS({
                    loadingRequisitionsSummary: true,
                    loadRequisitionsSummaryError: null,
                })
            );
        case requisitionsDashboardActions.LOAD_REQUISITIONS_SUMMARY_FAILURE:
            return state.merge(
                fromJS({
                    loadingRequisitionsSummary: false,
                    loadRequisitionsSummaryError: action.error && action.error.message,
                })
            );
        case requisitionsDashboardActions.LOAD_REQUISITIONS_SUMMARY_SUCCESS:
            return state.merge(
                fromJS({
                    summary: fromJS(action.result),
                    loadingRequisitionsSummary: false,
                })
            );
        default:
            return state;
    }
}

import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { PERMISSION_REQUIRED_PAGE_TOOLTIP } from '../../constants';
import { Tooltip } from '../../../../../components';
import { onKeyDown } from '../../../../../helpers/accessibilityHelper';

export const PageButton = (props) => {
    const navigate = useNavigate();

    const disabled = props.disabled || !props.hasPermission;
    const styles = require('../shared.scss');

    const clickHandler = () => {
        const { link } = props;

        if (!disabled) {
            return navigate(link);
        }
    };

    const renderButton = () => {
        const { icon, name, textColor } = props;

        const disabledStyle = disabled ? styles.disabled : '';

        return (
            <div
                className={`${styles.pageButtonContainer} ${disabledStyle} ${textColor}`}
                onClick={clickHandler}
                onKeyDown={(event) => onKeyDown(event, clickHandler)}
                role="button"
                tabIndex={0}
            >
                <div className={styles.pageButton}>
                    <i className={`fa ${icon} fa-2x fa-fw`} />
                </div>
                <div>
                    {name.split('<br>').map((line, idx) => (
                        <div key={idx}>{line}</div>
                    ))}
                </div>
            </div>
        );
    };

    if (disabled) {
        const { disabledText, hasPermission, hasPermissionText } = props;

        let tooltip;
        if (hasPermission) tooltip = disabledText;
        else if (hasPermissionText) tooltip = hasPermissionText;
        else tooltip = PERMISSION_REQUIRED_PAGE_TOOLTIP;

        return (
            <Tooltip placement="bottom" tooltip={tooltip}>
                {renderButton()}
            </Tooltip>
        );
    }

    return renderButton();
};

PageButton.propTypes = {
    name: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    textColor: PropTypes.string,
    hasPermission: PropTypes.bool,
    hasPermissionText: PropTypes.string,
    disabled: PropTypes.bool,
    disabledText: PropTypes.string,
};

import moment from 'moment-timezone/builds/moment-timezone-with-data-1970-2030';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { TimelineDate } from './TimelineDate';
import { TimelineHeader } from './TimelineHeader';

export class TimelineDateGroup extends PureComponent {
    static propTypes = {
        agendaUrl: PropTypes.string,
        date: PropTypes.instanceOf(Date),
        format: PropTypes.string,
        isDocx: PropTypes.bool,
        location: PropTypes.string,
        name: PropTypes.string.isRequired,
        textDate: PropTypes.string,
        timezone: PropTypes.string.isRequired,
    };

    static defaultProps = {
        format: 'll',
    };

    render() {
        const { agendaUrl, date, format, isDocx, location, name, textDate, timezone } = this.props;

        if (!date && !textDate) {
            return null;
        }

        const Component = isDocx ? 'tr' : 'div';

        return (
            <Component className="row timeline-group">
                <TimelineHeader isDocx={isDocx} name={name} />
                <TimelineDate
                    agendaUrl={agendaUrl}
                    date={date ? moment.tz(date, timezone).format(format) : textDate}
                    isDocx={isDocx}
                    location={location}
                />
            </Component>
        );
    }
}

/**
 * ZipCode validation
 * @param {object} values Form values
 * @param {string} [keyName='zipCode'] Form state field name to validate
 * @param {object} [opts={}] Options
 * @param {boolean} [opts.isInternational] Option to validate for international zip/postal codes
 * @param {boolean} [opts.optional] When `true`, no fields are required
 * @return {object} Form errors object
 */
export function zipCode(values, keyName = 'zipCode', opts = {}) {
    const errors = {};
    const zipCodeValue = values[keyName];

    if (zipCodeValue) {
        if (opts.isInternational) {
            // Special international zip/postal code validation
            if (zipCodeValue.length > 16) {
                errors[keyName] = 'Field is too long';
            }
        } else if (!/^\d{5}(?:-\d{4})?$/.test(zipCodeValue)) {
            errors[keyName] = 'Must be a proper zip code format (ex: 11111 or 11111-1111)';
        }
    } else if (!opts.optional) {
        errors[keyName] = 'Zip code is required.';
    }

    return errors;
}

import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import { Error as ErrorIcon, ReportProblem as ReportProblemIcon } from '@mui/icons-material';
import { Link } from '@og-pro/ui';
import { isNil } from 'lodash';
import React from 'react';

export const useDialogData = ({ contract, onSkip, onUpdate, selectNewVendor }) => {
    const hasContractVendorNumber = !isNil(contract.vendorAssignedNo);
    const contractHref = `/governments/${contract.government_id}/contracts/${contract.id}`;
    const contractLink = (
        <Link
            display="inline"
            fontWeight="medium"
            href={`${contractHref}`}
            target="_blank"
            underline="hover"
        >
            {contract.contractId ? `${contract.contractId} - ` : ''}
            {contract.title || 'Untitled'}
        </Link>
    );

    if (hasContractVendorNumber) {
        return {
            info: (
                <>
                    The contract cannot be selected unless the vendor number matches what&apos;s
                    listed on the contract ({contractLink}).
                </>
            ),
            primaryActionHandler: onSkip,
            primaryActionText: 'Add Vendor Without Contract',
            secondaryActionHandler: selectNewVendor,
            secondaryActionText: 'Choose A Different Vendor',
            title: 'Vendor Does Not Match',
            titleColor: 'error',
            titleIcon: <ErrorIcon />,
        };
    }

    return {
        info: (
            <>
                The contract ({contractLink}) you selected does not have a Vendor Number listed.
                <br />
                <br />
                Should the contract be updated?
            </>
        ),
        primaryActionHandler: onUpdate,
        primaryActionText: 'Update the Contract',
        secondaryActionText: "Don't Update",
        title: 'Update the Contract Record',
        titleColor: capitalDesignTokens.semanticColors.foreground.warningLarge,
        titleIcon: <ReportProblemIcon />,
    };
};

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getTemplateQuestionUpfrontQuestionsMap } from './selectors';

export const SharedQuestionLabel = ({ className, includeLink, small, style, upfrontQuestion }) => {
    const navigate = useNavigate();
    const templateQuestionUpfrontQuestionsMap = useSelector(getTemplateQuestionUpfrontQuestionsMap);
    const templateQuestion = templateQuestionUpfrontQuestionsMap[upfrontQuestion.sharedId];

    let clickHandler;
    if (includeLink && templateQuestion) {
        const link = `/governments/${templateQuestion.government_id}/templates-admin/shared-questions`;
        clickHandler = (e) => {
            e.stopPropagation();
            navigate(link);
        };
    }

    const styles = require('./index.scss');
    return (
        <div
            className={classnames(styles.sharedQuestion, small && styles.small, className)}
            style={style}
        >
            <span className={classnames(clickHandler && 'pseudoLink')} onClick={clickHandler}>
                <i className="fa fa-external-link" /> Shared Question (not editable)
            </span>
        </div>
    );
};

SharedQuestionLabel.propTypes = {
    className: PropTypes.string,
    includeLink: PropTypes.bool,
    small: PropTypes.bool,
    style: PropTypes.object,
    upfrontQuestion: PropTypes.shape({
        sharedId: PropTypes.string.isRequired,
    }).isRequired,
};

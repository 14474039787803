import PropTypes from 'prop-types';
import React from 'react';

import { StepApprovalLogs } from './StepApprovalLogs';
import { StepStatus } from './StepStatus';
import { Step } from '../Step';

export const SequenceStep = ({
    StepStatusComponent,
    children,
    href,
    requisitionLogs,
    stepName,
    stepNameClassName,
    stepStatusIcon,
    stepStatusIconClassName,
    stepStatusIconText,
    stepStatusText,
    stepStatusTextClassName,
}) => {
    const hasBody = !!children;

    const styles = require('./index.scss');

    return (
        <Step
            Body={() =>
                hasBody ? (
                    <>
                        {children}
                        <StepApprovalLogs requisitionLogs={requisitionLogs} />
                    </>
                ) : undefined
            }
            Heading={({ isOpen }) => (
                <StepStatus
                    StepStatusComponent={StepStatusComponent}
                    hasToggle={hasBody}
                    isOpen={isOpen}
                    stepName={stepName}
                    stepNameClassName={stepNameClassName}
                    stepStatusIcon={stepStatusIcon}
                    stepStatusIconClassName={stepStatusIconClassName}
                    stepStatusIconText={stepStatusIconText}
                    stepStatusText={stepStatusText}
                    stepStatusTextClassName={stepStatusTextClassName}
                />
            )}
            href={href}
            panelHeadingClassName={(isOpen) =>
                isOpen ? styles.panelHeadingOpen : styles.panelHeadingClosed
            }
        />
    );
};

SequenceStep.propTypes = {
    StepStatusComponent: PropTypes.node,
    children: PropTypes.node,
    href: PropTypes.string,
    requisitionLogs: PropTypes.array,
    stepName: PropTypes.string.isRequired,
    stepNameClassName: PropTypes.string,
    stepStatusIcon: PropTypes.string, // Either `stepStatusIcon` or `stepStatusIconText` prop is required to be present
    stepStatusIconClassName: PropTypes.string,
    stepStatusIconText: PropTypes.string, // Either `stepStatusIcon` or `stepStatusIconText` prop is required to be present
    stepStatusText: PropTypes.string,
    stepStatusTextClassName: PropTypes.string,
};

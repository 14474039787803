import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import simpleNumberLocalizer from 'react-widgets-simple-number';
import NumberPicker from 'react-widgets/lib/NumberPicker';

simpleNumberLocalizer();

export class DollarWidget extends PureComponent {
    static propTypes = {
        field: PropTypes.shape({
            error: PropTypes.string,
            onBlur: PropTypes.func.isRequired,
            onChange: PropTypes.func.isRequired,
            onFocus: PropTypes.func.isRequired,
            touched: PropTypes.bool,
            value: PropTypes.any,
        }).isRequired,
        label: PropTypes.string,
        placeholder: PropTypes.string,
        disabled: PropTypes.bool,
        min: PropTypes.number,
    };

    handleBlur = () => {
        const {
            field: { onBlur, value },
        } = this.props;

        onBlur(value);
    };

    render() {
        const {
            field: { value, onChange, onFocus, touched, error },
            label,
            disabled,
            placeholder,
            min,
        } = this.props;

        let errorMessage;
        let groupState = 'primary';

        if (touched && !error) {
            groupState = 'has-success';
        }

        if (touched && error) {
            groupState = 'has-error';
            errorMessage = <span className="help-block">{error}</span>;
        }

        return (
            <div className={`form-group ${groupState}`}>
                {label ? <label className="control-label">{label}</label> : null}
                <NumberPicker
                    disabled={disabled}
                    format="-$#,###.00"
                    min={min}
                    onBlur={this.handleBlur}
                    onChange={(val) => onChange(Number.isNaN(Number.parseFloat(val)) ? null : val)}
                    onFocus={onFocus}
                    placeholder={placeholder}
                    precision={2}
                    value={parseFloat(value)}
                />
                {errorMessage}
            </div>
        );
    }
}

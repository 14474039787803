import { fieldNames } from './constants';

const { COMMENT } = fieldNames;

export const validate = (values) => {
    const errors = {};

    if (!values[COMMENT]) {
        errors[COMMENT] = 'Please enter a comment';
    } else if (values[COMMENT].length > 1000) {
        errors[COMMENT] = 'Comment is too long';
    }

    return errors;
};

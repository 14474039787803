import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export class NoProposals extends PureComponent {
    static propTypes = {
        info: PropTypes.string,
        link: PropTypes.string.isRequired,
        title: PropTypes.string,
    };

    render() {
        const { info, link, title } = this.props;

        const styles = require('./NoProposals.scss');
        return (
            <div className={`row ${styles.noProposalsContainer}`}>
                <div className="col-sm-10 col-sm-offset-1">
                    <div className={`text-muted ${styles.noProposalsText}`}>
                        <div className={styles.noProposalsHeader}>
                            {title || 'No responses started yet!'}
                        </div>
                        <div>
                            Get started by searching for projects to bid on.
                            <br />
                            {info || 'Your list of responses will then be displayed here.'}
                        </div>
                    </div>
                    <br />
                    <Link to={link}>
                        <div className={`btn btn-success ${styles.createBtn}`}>Find Projects</div>
                    </Link>
                </div>
            </div>
        );
    }
}

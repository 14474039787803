import { fromJS } from 'immutable';

import * as reviewSequencesActions from '../actions/reviewSequences';

const initialReviewSequenceEditState = {
    copyingItem: false,
    copyItemError: null,
    deleteItemError: null,
    deletingItem: false,
    loadingItem: false,
    loadItemError: null,
    reviewSequence: null,
    setDefaultError: null,
    settingDefault: false,
    updateItemError: null,
    updatingItem: false,
};

const initialState = fromJS({
    ...initialReviewSequenceEditState,
    loadedSequences: false,
    loadingSequences: false,
    loadSequencesError: null,
    reviewSequences: [],
});

export default function reviewSequencesReducer(state = initialState, action = {}) {
    switch (action.type) {
        case reviewSequencesActions.COPY_SEQUENCE:
            return state.merge(
                fromJS({
                    copyingItem: true,
                    copyItemError: null,
                })
            );
        case reviewSequencesActions.COPY_SEQUENCE_FAIL:
            return state.merge(
                fromJS({
                    copyingItem: false,
                    copyItemError: action.error && action.error.message,
                })
            );
        case reviewSequencesActions.COPY_SEQUENCE_SUCCESS:
            return state.merge(
                fromJS({
                    copyingItem: false,
                })
            );
        case reviewSequencesActions.DELETE_SEQUENCE:
            return state.merge(
                fromJS({
                    deleteItemError: null,
                    deletingItem: true,
                })
            );
        case reviewSequencesActions.DELETE_SEQUENCE_FAIL:
            return state.merge(
                fromJS({
                    deleteItemError: action.error && action.error.message,
                    deletingItem: false,
                })
            );
        case reviewSequencesActions.DELETE_SEQUENCE_SUCCESS:
            return state.merge(
                fromJS({
                    deletingItem: false,
                })
            );
        case reviewSequencesActions.LOAD_SEQUENCE:
            return state.merge(
                fromJS({
                    loadingItem: true,
                    loadItemError: null,
                })
            );
        case reviewSequencesActions.LOAD_SEQUENCE_FAIL:
            return state.merge(
                fromJS({
                    loadingItem: false,
                    loadItemError: action.error && action.error.message,
                })
            );
        case reviewSequencesActions.LOAD_SEQUENCE_SUCCESS:
            return state.merge(
                fromJS({
                    loadingItem: false,
                    reviewSequence: fromJS(action.result),
                })
            );
        case reviewSequencesActions.LOAD_SEQUENCES:
            return state.merge(
                fromJS({
                    loadingSequences: true,
                    loadSequencesError: null,
                })
            );
        case reviewSequencesActions.LOAD_SEQUENCES_FAIL:
            return state.merge(
                fromJS({
                    loadedSequences: false,
                    loadingSequences: false,
                    loadSequencesError: action.error && action.error.message,
                })
            );
        case reviewSequencesActions.LOAD_SEQUENCES_SUCCESS:
            return state.merge(
                fromJS({
                    loadedSequences: !!action.setLoaded,
                    loadingSequences: false,
                    reviewSequences: fromJS(action.result),
                })
            );
        case reviewSequencesActions.RESET_SEQUENCES:
            return state.merge(
                fromJS({
                    loadedSequences: false,
                })
            );
        case reviewSequencesActions.RESET_SEQUENCE_EDIT:
            return state.merge(fromJS(initialReviewSequenceEditState));
        case reviewSequencesActions.SET_DEFAULT:
            return state.merge(
                fromJS({
                    settingDefault: true,
                    setDefaultError: null,
                })
            );
        case reviewSequencesActions.SET_DEFAULT_FAIL:
            return state.merge(
                fromJS({
                    settingDefault: false,
                    setDefaultError: action.error && action.error.message,
                })
            );
        case reviewSequencesActions.SET_DEFAULT_SUCCESS:
            return state.set('settingDefault', false).setIn(['reviewSequence', 'isDefault'], true);
        case reviewSequencesActions.UPDATE_SEQUENCE:
            return state.merge(
                fromJS({
                    updatingItem: true,
                    updateItemError: null,
                })
            );
        case reviewSequencesActions.UPDATE_SEQUENCE_FAIL:
            return state.merge(
                fromJS({
                    updatingItem: false,
                    updateItemError: action.error && action.error.message,
                })
            );
        case reviewSequencesActions.UPDATE_SEQUENCE_STOP:
            return state.merge(
                fromJS({
                    updatingItem: false,
                })
            );
        case reviewSequencesActions.UPDATE_SEQUENCE_SUCCESS:
            return state.merge(
                fromJS({
                    updatingItem: false,
                    reviewSequence: fromJS(action.result),
                })
            );
        default:
            return state;
    }
}

import { useSelector } from 'react-redux';
import { isEqual, uniqWith } from 'lodash';
import { useQuery } from '@tanstack/react-query';

import { OG_FINANCIALS_QUERY_PREFIX, useOgFinancials } from './useOgFinancials';
import { ogFinancialsClient } from './client';
import { searchVendors, vendorSelection, getDeliveryCodesPage } from './queries';
import {
    getDeliveryCodeSelectOptions,
    getPaymentTermsSelectOptions,
} from '../../containers/App/selectors';
import { NO_EMAIL_FOUND_TEXT } from '../../constants/requisitionsCreate';
import { generateOptions } from '../../helpers';
import { getIsRequisitionUsingFMS } from '../../selectors/govApp';

const useVendorSelection = (options) =>
    useOgFinancials({
        queryKey: 'vendorSelection',
        query: vendorSelection,
        ...options,
    });

const formatDeliveryCodes = (deliveryCodes) =>
    deliveryCodes.map((deliveryCode) => ({
        description: `${deliveryCode.delivery_location_code} - ${deliveryCode.entity_name}`,
        ...deliveryCode,
    }));

/* This is used in AsyncSelect which causes a hooks error if you use react-query's
 * useQuery.
 */
export const fetchVendorSearchOptions = async (search) =>
    ogFinancialsClient.request(searchVendors, { search }).then((res) =>
        res?.vendors?.data.map((vendor) => {
            const addresses = uniqWith(vendor.vendor_addresses, isEqual).map((vendorAddress) => {
                const addressArray = Object.keys(vendorAddress)
                    .filter((key) => vendorAddress[key] !== '')
                    .map((key) => vendorAddress[key]);

                const displayAddress = addressArray.join(', ');

                return {
                    label: displayAddress,
                    value: displayAddress,
                    addressData: {
                        address1: vendorAddress.entity_address_1,
                        address2: vendorAddress.entity_address_2,
                        city: vendorAddress.entity_city,
                        state: vendorAddress.entity_long_state,
                        zipCode: vendorAddress.entity_zip_11,
                    },
                };
            });

            return {
                label: vendor.vendor_name,
                value: vendor.vendor_number,
                subText: vendor.vendor_number.toString(),
                user: {
                    addresses,
                    displayName: vendor.vendor_name,
                    email: vendor.default_email_address || NO_EMAIL_FOUND_TEXT,
                },
                defaultPaymentTerms: vendor.payment_terms,
            };
        })
    ) || [];

const getAllDeliveryCodes = async () => {
    const data = [];

    let pageNumber = 1;
    let hasMorePages = true;
    while (hasMorePages) {
        const response = await ogFinancialsClient.request(getDeliveryCodesPage(pageNumber));

        data.push(...response.deliveryCodes.data);
        hasMorePages = response.deliveryCodes.paginatorInfo.hasMorePages;

        pageNumber++;
    }

    return {
        deliveryCodes: {
            data,
        },
    };
};

const useDeliveryCodes = () => {
    const queryOptions = {
        queryKey: [OG_FINANCIALS_QUERY_PREFIX, 'getAllDeliveryCodes'],
        queryFn: getAllDeliveryCodes,
        retry: false,
        refetchOnWindowFocus: false,
    };
    return useQuery(queryOptions);
};

export const useVendorSelectionFormDisplay = () => {
    const hasFMS = useSelector(getIsRequisitionUsingFMS);
    let deliveryCodeSelectOptions = useSelector(getDeliveryCodeSelectOptions);
    let paymentTermsSelectOptions = useSelector(getPaymentTermsSelectOptions);
    let defaultDeliveryCode;

    const { data: vendorSelectionData } = useVendorSelection();
    const { data: deliveryCodesData } = useDeliveryCodes();
    if (hasFMS) {
        if (deliveryCodesData?.deliveryCodes?.data) {
            const formattedDeliveryCodes = formatDeliveryCodes(
                deliveryCodesData.deliveryCodes.data
            );

            deliveryCodeSelectOptions = generateOptions(
                formattedDeliveryCodes,
                'description',
                'delivery_location_code'
            );
        }

        if (vendorSelectionData?.paymentTerms) {
            paymentTermsSelectOptions = generateOptions(
                vendorSelectionData.paymentTerms,
                'description',
                'payment_terms_code'
            );
        }

        if (vendorSelectionData?.accountsPayableSettings[0]?.default_delivery_location_code) {
            defaultDeliveryCode =
                vendorSelectionData?.accountsPayableSettings[0]?.default_delivery_location_code;
        }
    }

    return { deliveryCodeSelectOptions, paymentTermsSelectOptions, defaultDeliveryCode };
};

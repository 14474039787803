import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, formValueSelector, reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';

import { ApprovalStepList } from './ApprovalStepList';
import { validate } from './validate';
import { fieldNames } from '../constants';
import { Button, SearchSelect } from '../../../../components';

const { APPROVAL_WORKFLOW_STEPS, DEPARTMENT_IDS } = fieldNames;

const formConfig = { validate };

const ConnectedApprovalWorkflowForm = ({
    array,
    change,
    closeForm,
    departmentsSelectOptions,
    disabled,
    form,
    handleSubmit,
    hideNewWorkflowForm,
    invalid,
    isNew,
    multiUserSelect,
    selectedModuleType,
    serverError,
    usersSelectOptions,
}) => {
    const styles = require('../index.scss');
    const formSelector = formValueSelector(form);

    const approvalWorkflowStepValues = useSelector((state) =>
        formSelector(state, APPROVAL_WORKFLOW_STEPS)
    );

    const renderControlButtons = () => {
        if (isNew) {
            return (
                <div className="text-center">
                    <Button
                        aria-label="Create Workflow Button"
                        block
                        bsStyle="success"
                        disabled={disabled || invalid || !approvalWorkflowStepValues?.length > 0}
                        qaTag="approvalWorkflowForm-create"
                        type="submit"
                    >
                        <i className="fa fa-plus" /> Create
                    </Button>
                    <Button
                        aria-label="Cancel Create Button"
                        bsStyle="link"
                        disabled={disabled}
                        onClick={hideNewWorkflowForm}
                        qaTag="approvalWorkflowForm-cancelCreate"
                    >
                        Cancel
                    </Button>
                </div>
            );
        }

        return (
            <div className="text-center">
                <Button
                    aria-label="Update Workflow Button"
                    block
                    bsStyle="primary"
                    disabled={disabled || invalid || !approvalWorkflowStepValues?.length > 0}
                    qaTag="approvalWorkflowForm-update"
                    type="submit"
                >
                    <i className="fa fa-pencil" /> Update
                </Button>
                <Button
                    aria-label="Cancel Update Button"
                    bsStyle="link"
                    disabled={disabled}
                    onClick={closeForm}
                    qaTag="approvalWorkflowForm-cancelUpdate"
                >
                    Cancel
                </Button>
            </div>
        );
    };

    if (isNew && !approvalWorkflowStepValues) {
        array.push(APPROVAL_WORKFLOW_STEPS, {
            orderById: 1,
        });
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className={`col-sm-4 ${styles.departmentsColumn}`}>
                    <Field
                        aria-label="Select Departments"
                        component={SearchSelect}
                        disabled={disabled}
                        help="When these departments create a project"
                        isClearable={false}
                        isMulti
                        isMultiSimpleValue
                        name={DEPARTMENT_IDS}
                        options={departmentsSelectOptions}
                        placeholder="Select departments"
                    />
                </div>
                <div className={`hidden-xs col-sm-1 text-primary ${styles.arrowIcon}`}>
                    <i className="fa fa-lg fa-arrow-right" />
                </div>
                <div className={`col-sm-5 ${styles.approversColumn}`}>
                    <FieldArray
                        approvalWorkflowStepValues={approvalWorkflowStepValues}
                        array={array}
                        change={change}
                        component={ApprovalStepList}
                        form={form}
                        multiUserSelect={multiUserSelect}
                        name={APPROVAL_WORKFLOW_STEPS}
                        selectedModuleType={selectedModuleType}
                        usersSelectOptions={usersSelectOptions}
                    />
                </div>
                <div className="col-sm-2">
                    {renderControlButtons()}
                    {serverError && <div className="error-block">{serverError}</div>}
                </div>
            </div>
        </form>
    );
};

ConnectedApprovalWorkflowForm.propTypes = {
    array: PropTypes.object.isRequired,
    change: PropTypes.func.isRequired,
    closeForm: PropTypes.func,
    departmentsSelectOptions: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
    form: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    hideNewWorkflowForm: PropTypes.func,
    invalid: PropTypes.bool.isRequired,
    isNew: PropTypes.bool,
    multiUserSelect: PropTypes.bool.isRequired,
    selectedModuleType: PropTypes.string.isRequired,
    serverError: PropTypes.string,
    usersSelectOptions: PropTypes.array.isRequired,
};

export const ApprovalWorkflowForm = reduxForm(formConfig)(ConnectedApprovalWorkflowForm);

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import { resetProposalCreate } from '../../../../../actions/govProposals';
import { LoadingError, LoadingSpinner } from '../../../../../components';

const mapStateToProps = (state) => {
    return {
        error: state.govProposals.get('createError'),
        loading: state.govProposals.get('creating'),
        showModal: state.govProposals.get('showCreateModal'),
    };
};

const mapDispatchToProps = {
    hideModal: resetProposalCreate,
};

// @connect
class ConnectedProposalCreateModal extends Component {
    static propTypes = {
        error: PropTypes.string,
        hideModal: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        showModal: PropTypes.bool.isRequired,
    };

    get styles() {
        return require('./ProposalCreateModal.scss');
    }

    renderModalBody() {
        const { error, loading } = this.props;

        if (loading) {
            return (
                <div>
                    <LoadingSpinner className={this.styles.loadingContainer} noPadding />
                    <h4 className={this.styles.title}>Creating Proposal...</h4>
                </div>
            );
        }

        if (error) {
            return <LoadingError error={error} noOffset />;
        }

        return null;
    }

    render() {
        const { hideModal, showModal } = this.props;

        return (
            <Modal bsSize="sm" onHide={hideModal} show={showModal}>
                <Modal.Body className={this.styles.modalBody}>{this.renderModalBody()}</Modal.Body>
            </Modal>
        );
    }
}

export const ProposalCreateModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedProposalCreateModal);

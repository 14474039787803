exports.certificationSourceIds = {
    SELF_REPORTED: 1,
};

/**
 * Splits certifications into two arrays: self-reported and verified.
 * @param {Array} certifications - Array of Certifications.
 * @returns {Object} - Object with two arrays: selfReportedCertifications and verifiedCertifications.
 */
exports.splitCertificationsBySource = (certifications) =>
    certifications.reduce(
        (acc, certification) => {
            if (
                certification.vendorCertifications[0]?.certification_source_id ===
                this.certificationSourceIds.SELF_REPORTED
            ) {
                acc.selfReportedCertifications.push(certification);
            } else {
                acc.verifiedCertifications.push(certification);
            }
            return acc;
        },
        { selfReportedCertifications: [], verifiedCertifications: [] }
    );

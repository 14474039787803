import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { Well } from '../../../../../../components';
import { updateDisplaySettings } from '../../../../../../actions/proposalEvaluations';
import { fieldNames, form } from './constants';
import { OptionToggle } from './OptionToggle';

const {
    SHOW_ANONYMIZED_EVALUATORS,
    SHOW_EVALUATOR_COMMENTS,
    SHOW_CRITERIA_DESCRIPTIONS,
    SHOW_DETAILED_PROPOSALS,
    SHOW_EXPANDED_VIEW,
    SHOW_PERCENTAGE_TOTALS,
} = fieldNames;

const mapStateToProps = (state) => {
    return {
        initialValues: {
            [SHOW_ANONYMIZED_EVALUATORS]: state.proposalEvaluations.get(SHOW_ANONYMIZED_EVALUATORS),
            [SHOW_EVALUATOR_COMMENTS]: state.proposalEvaluations.get(SHOW_EVALUATOR_COMMENTS),
            [SHOW_CRITERIA_DESCRIPTIONS]: state.proposalEvaluations.get(SHOW_CRITERIA_DESCRIPTIONS),
            [SHOW_DETAILED_PROPOSALS]: state.proposalEvaluations.get(SHOW_DETAILED_PROPOSALS),
            [SHOW_EXPANDED_VIEW]: state.proposalEvaluations.get(SHOW_EXPANDED_VIEW),
            [SHOW_PERCENTAGE_TOTALS]: state.proposalEvaluations.get(SHOW_PERCENTAGE_TOTALS),
        },
    };
};

const mapDispatchToProps = {
    onChange: updateDisplaySettings,
};

const formConfig = {
    // Needed to preserve form between page views since form isn't always re-rendered
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    form,
};

// @connect
// @reduxForm
class ConnectedDisplayOptionsForm extends Component {
    static propTypes = {
        showAnonymizedEvaluatorsOption: PropTypes.bool,
        showDetailedProposalsOption: PropTypes.bool,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const { showAnonymizedEvaluatorsOption, showDetailedProposalsOption } = this.props;

        return (
            <Well className={this.styles.well}>
                <div>
                    <div className={this.styles.colToggleTitle}>
                        <p>Table Display Settings:</p>
                    </div>
                    <div className={this.styles.colToggle} />
                </div>
                {showDetailedProposalsOption && (
                    <OptionToggle
                        fieldName={SHOW_DETAILED_PROPOSALS}
                        label="Show individual response scores"
                    />
                )}
                {showAnonymizedEvaluatorsOption && (
                    <OptionToggle
                        fieldName={SHOW_ANONYMIZED_EVALUATORS}
                        label="Anonymize evaluators"
                    />
                )}
                <OptionToggle fieldName={SHOW_EXPANDED_VIEW} label="Expanded View" />
                <OptionToggle
                    fieldName={SHOW_CRITERIA_DESCRIPTIONS}
                    label="Show full evaluation criteria descriptions"
                />
                <OptionToggle
                    fieldName={SHOW_PERCENTAGE_TOTALS}
                    label="Show totals as percentages"
                />
                <OptionToggle fieldName={SHOW_EVALUATOR_COMMENTS} label="Show comments" />
            </Well>
        );
    }
}

export const DisplayOptionsForm = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedDisplayOptionsForm);

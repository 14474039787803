import { each } from 'lodash';
import superagent from 'superagent';

const API_METHODS = ['get', 'post', 'put', 'patch', 'del'];

class RequestClient {
    constructor() {
        API_METHODS.forEach((method) => {
            /**
             * Creates an API request wrapper each HTTP method
             * @param {string} url The API path to request
             * @param {object} opts Accepts two keys: params (for query params)
             *                      and data (for request bodies)
             * @return {Promise} returned API response
             */
            this[method] = (url, { params, data, headers, onProgress } = {}) => {
                const request = superagent[method](url);

                if (headers) {
                    each(headers, (val, key) => request.set(key, val));
                }

                if (params) {
                    request.query(params);
                }

                if (data) {
                    request.send(data);
                }

                if (onProgress) {
                    request.on('progress', onProgress);
                }

                return request
                    .then((response = {}) => response.body)
                    .catch((error = {}) => {
                        const response = error.response || {};
                        return Promise.reject(response.body || error);
                    });
            };
        });
    }
}

export default new RequestClient();

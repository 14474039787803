import { fromJS } from 'immutable';

import { normalizeProject } from './utils';
import * as govProjectActions from '../actions/govProjects';
import * as projectActions from '../actions/project/create/projectCreate';
import * as revisionActions from '../actions/revisions';

const revisionDiffInitialState = {
    loadDiffError: null,
    loadingDiff: false,
    loadingDiffMessage: null,
    refreshRevisionHtmlDiff: false,
    revisionHtmlDiff: null,
};

const initialState = fromJS({
    ...revisionDiffInitialState,
    activeIdx: null,
    createVersionError: null,
    creatingVersion: false,
    currentProjectAuditId: null,
    currentProjectVersion: null,
    documentVersions: [],
    error: null,
    hasMore: false,
    loaded: false,
    loading: false,
    loadingMore: false,
    loadMoreError: null,
    projectAudits: [],
    searchError: null,
    searching: false,
    searchResults: [],
});

export default function revisionReducer(state = initialState, action = {}) {
    switch (action.type) {
        case revisionActions.LOAD:
            return state.merge(
                fromJS({
                    loading: true,
                    loaded: false,
                })
            );
        case revisionActions.LOAD_SUCCESS:
            return state.merge(
                fromJS({
                    error: null,
                    loading: false,
                    loaded: true,
                    documentVersions: fromJS(action.result.documentVersions),
                    projectAudits: fromJS(action.result.projectAudits),
                    hasMore: action.result.hasMore,
                })
            );
        case revisionActions.LOAD_FAIL:
            return state.merge(
                fromJS({
                    loading: false,
                    error: action.error && action.error.message,
                })
            );
        case revisionActions.LOAD_MORE:
            return state.merge(
                fromJS({
                    loadingMore: true,
                    loadMoreError: null,
                })
            );
        case revisionActions.LOAD_MORE_SUCCESS:
            return state.merge(
                fromJS({
                    loadingMore: false,
                    projectAudits: state
                        .get('projectAudits')
                        .concat(fromJS(action.result.projectAudits)),
                    hasMore: action.result.hasMore,
                })
            );
        case revisionActions.LOAD_MORE_FAIL:
            return state.merge(
                fromJS({
                    loadingMore: false,
                    loadMoreError: action.error && action.error.message,
                })
            );
        case revisionActions.CREATE_VERSION:
            return state.merge(
                fromJS({
                    createVersionError: null,
                    creatingVersion: true,
                })
            );
        case revisionActions.CREATE_VERSION_SUCCESS:
            return state.merge(
                fromJS({
                    creatingVersion: false,
                    documentVersions: state.get('documentVersions').unshift(fromJS(action.result)),
                })
            );
        case revisionActions.CREATE_VERSION_FAIL:
            return state.merge(
                fromJS({
                    createVersionError: action.error && action.error.message,
                    creatingVersion: false,
                })
            );
        case revisionActions.SEARCH:
            return state.merge(
                fromJS({
                    searchError: null,
                    searching: true,
                })
            );
        case revisionActions.SEARCH_SUCCESS:
            return state.merge(
                fromJS({
                    searching: false,
                })
            );
        case revisionActions.SEARCH_FAIL:
            return state.merge(
                fromJS({
                    searchError: action.error && action.error.message,
                    searching: false,
                })
            );
        case revisionActions.SET_CURRENT_VERSION:
            return state.merge(
                fromJS({
                    currentProjectAuditId: action.result.id,
                    currentProjectVersion: fromJS(normalizeProject(action.result.state)),
                })
            );
        case revisionActions.LOAD_DIFF:
            return state.merge(
                fromJS({
                    loadDiffError: null,
                    loadingDiff: true,
                    loadingDiffMessage: action.message,
                })
            );
        case revisionActions.LOAD_DIFF_SUCCESS:
            return state.merge(
                fromJS({
                    loadingDiff: false,
                    loadingDiffMessage: null,
                    refreshRevisionHtmlDiff: true,
                    revisionHtmlDiff: action.result.diff,
                })
            );
        case revisionActions.LOAD_DIFF_FAIL:
            return state.merge(
                fromJS({
                    loadDiffError: action.error && action.error.message,
                    loadingDiff: false,
                    loadingDiffMessage: null,
                })
            );
        case revisionActions.SET_ACTIVE:
            return state.merge(
                fromJS({
                    activeIdx: action.idx,
                })
            );
        case revisionActions.RESET_REFRESH_DIFF:
            return state.set('refreshRevisionHtmlDiff', false);
        case revisionActions.RESET_REVISION_DIFF:
            return state.merge(fromJS(revisionDiffInitialState));
        case revisionActions.RESET:
            return initialState;
        case projectActions.UPDATE_SUCCESS:
        case projectActions.SUBMIT_SUCCESS:
        case govProjectActions.LOAD_SUCCESS:
            // Project loading will cancel out revision loading when loading the
            // revisions page on the server, so ignore server project loading
            if (process.env.SERVER) return state;
            return state.merge(
                fromJS({
                    loaded: false,
                })
            );
        default:
            return state;
    }
}

import {
    accountFieldNames,
    accountObjectFieldNames,
} from '../RequisitionsCreate/RequisitionsCreateForms/FormComponents/PurchaseDetails/PriceItem/AccountSplit/AccountFields/AccountField/constants';
import { getTotalAmountUsedByAccountNumber } from '../RequisitionsCreate/RequisitionsCreateForms/FormComponents/PurchaseDetails/helpers';

const { AMOUNT, AVAILABLE_BUDGET_AMOUNT, ACCOUNT_OBJECT, ACCOUNT_NUMBER } = accountFieldNames;

const { IS_EXPENSE_ACCOUNT } = accountObjectFieldNames;

export const budgetCheckStates = {
    NONE: 'none',
    PASS: 'pass',
    FAIL: 'fail',
    NON_EXPENSE: 'non-expense',
};

export const hasBudgetStatus = (status) => {
    return status === budgetCheckStates.PASS || status === budgetCheckStates.FAIL;
};

export const getAccountBudgetCheckStatus = (
    hasValidAmount,
    totalAmountUsedByAccountNumber,
    availableAmount,
    isExpenseAccount = true,
    isRequisitionSubmitted = false
) => {
    if (!isExpenseAccount) {
        return budgetCheckStates.NON_EXPENSE;
    }

    if (!hasValidAmount || (availableAmount !== 0 && !availableAmount)) {
        return budgetCheckStates.NONE;
    }

    if (isRequisitionSubmitted) {
        return availableAmount >= 0 ? budgetCheckStates.PASS : budgetCheckStates.FAIL;
    }

    if (totalAmountUsedByAccountNumber > availableAmount) {
        return budgetCheckStates.FAIL;
    }

    return budgetCheckStates.PASS;
};

export const getPriceItemBudgetCheckStatus = (
    currentPriceItem,
    isRequisitionSubmitted,
    priceItems
) => {
    const { accountSplitPriceItems, quantity, unitPrice } = currentPriceItem;

    if (!quantity || !unitPrice || !accountSplitPriceItems?.length) {
        return budgetCheckStates.NONE;
    }

    const statuses = [];

    accountSplitPriceItems.forEach((accountSplitPriceItem) => {
        const accountNumber = accountSplitPriceItem[ACCOUNT_NUMBER];
        const amount = parseFloat(accountSplitPriceItem[AMOUNT]);
        const totalAmountUsedByAccountNumber = getTotalAmountUsedByAccountNumber(
            priceItems,
            accountNumber
        );
        const status = getAccountBudgetCheckStatus(
            !!amount,
            totalAmountUsedByAccountNumber,
            accountSplitPriceItem[AVAILABLE_BUDGET_AMOUNT],
            accountSplitPriceItem[ACCOUNT_OBJECT]?.[IS_EXPENSE_ACCOUNT],
            isRequisitionSubmitted
        );

        statuses.push(status);
    });

    // If at least one split has failed, the entire price item fails
    if (statuses.includes(budgetCheckStates.FAIL)) {
        return budgetCheckStates.FAIL;
    }

    // If all splits are non-expense accounts, the entire price item is non-expense
    if (statuses.every((status) => status === budgetCheckStates.NON_EXPENSE)) {
        return budgetCheckStates.NON_EXPENSE;
    }

    // If there is at least one split that passes, and there are no splits that fail or incomplete, the entire price item passes
    // NOTE: If an account has splits that passes, and splits that are non-expense, the entire price item will pass
    if (
        statuses.includes(budgetCheckStates.PASS) &&
        !statuses.includes(budgetCheckStates.FAIL) &&
        !statuses.includes(budgetCheckStates.NONE)
    ) {
        return budgetCheckStates.PASS;
    }

    return budgetCheckStates.NONE;
};

/**
 * Checks if the given budget check data object has no budget check data.
 *
 * @param {Object} budgetCheckData - The budget check data object to check. Defaults to an empty object if not provided.
 *    - {number} spent: Spent amount.
 *    - {number} committed: Committed amount.
 *    - {number} inProcess: In process amount.
 *    - {number} available: Available amount.
 * @return {boolean} Returns true if the budget check data object has no budget check data, otherwise false.
 */
export const hasNoBudgetCheckData = (budgetCheckData = {}) => {
    const spent = budgetCheckData.spent;
    const committed = budgetCheckData.committed;
    const inProcess = budgetCheckData.inProcess;
    const available = budgetCheckData.available;

    return !spent && !committed && !inProcess && !available;
};

import React from 'react';
import { Outlet } from 'react-router-dom';

import * as Gov from '../../../containers/GovApp';
import { requireEvaluationSubscription } from '../../loaders';

export default (getState) => {
    return [
        {
            element: <Outlet />,
            loader: requireEvaluationSubscription(getState),
            path: 'evaluation/create',
            children: [
                {
                    element: <Gov.EvaluationCreate />,
                    children: [
                        {
                            element: <Gov.EvaluationCreateIndex />,
                            index: true,
                        },
                        {
                            element: <Gov.ProjectOverview />,
                            path: 'overview',
                        },
                        {
                            element: <Gov.ProposalDocuments />,
                            path: 'proposals',
                        },
                        {
                            element: <Gov.EvaluationCriteria />,
                            path: 'criteria',
                        },
                        {
                            element: <Gov.Evaluators />,
                            path: 'evaluators',
                        },
                    ],
                },
            ],
        },
    ];
};

import PropTypes from 'prop-types';
import React from 'react';

export const UserName = ({ showOrganization, user: { displayName, organizationName } }) => {
    if (!showOrganization) {
        return displayName;
    }

    const styles = require('./index.scss');

    return (
        <div className={styles.userNameContainer}>
            {displayName}
            <br />
            <small>
                <em>{organizationName}</em>
            </small>
        </div>
    );
};

UserName.propTypes = {
    showOrganization: PropTypes.bool,
    user: PropTypes.shape({
        displayName: PropTypes.string.isRequired,
        organizationName: PropTypes.string,
    }).isRequired,
};

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Panel } from './components';
import { menuStates } from './constants';
import { RelationsContractSearchForm } from './ContractSearchForm';
import { RelationsOptionsMenu } from './OptionsMenu';
import { RelationsProjectSearchForm } from './ProjectSearchForm';
import { RelationsSelect } from './RelationsSelect';
import { RelationsZeroState } from './ZeroState';
import { createRequisitionRelation } from '../../../../../../actions/requisitionsApprovals';
import { showProjectCreateModal } from '../../../../../../actions/project/create/projectCreate';
import { getReqRelations } from '../../../../../../selectors/govApp/reqRelations';

const { NONE, SELECT, INTAKE, PROJECT, CONTRACT } = menuStates;

const SEARCH_PROPS = {
    [INTAKE]: {
        createModalData: { isIntake: true },
        createText: 'Create New Intake',
        label: 'Find an Existing Intake',
        help: 'Search by Name or Project ID',
        searchData: { isIntake: true },
    },
    [PROJECT]: {
        createModalData: { steps: [2] },
        createText: 'Create New Solicitation',
        help: 'Search by Name or Project ID',
        label: 'Find an Existing Solicitation',
    },
    [CONTRACT]: {
        createModalData: { isContracts: true },
        createText: 'Create New Contract',
        help: 'Search by Name, Contract ID, or Vendor',
        label: 'Find an Existing Contract',
    },
};

export const RequisitionRelationsMenu = ({
    deleteHandler,
    disabled,
    hasSelections,
    isEditing,
    requisition,
    setIsEditing,
}) => {
    const [menuItem, setMenuItem] = useState(NONE);
    const [isDisabled, setIsDisabled] = useState(false);
    const [createError, setCreateError] = useState(null);

    const dispatch = useDispatch();
    const reqRelations = useSelector(getReqRelations);

    const addRelationHandler = () => setMenuItem(SELECT);
    const cancelHandler = () => setMenuItem(NONE);

    const selectHandler = async (data) => {
        setIsDisabled(true);
        setCreateError(null);
        try {
            await dispatch(createRequisitionRelation(requisition.id, data));
            setMenuItem(NONE);
        } catch (err) {
            setCreateError(err.message);
        } finally {
            setIsDisabled(false);
        }
    };

    const { createModalData, ...searchProps } = SEARCH_PROPS[menuItem] || {};

    const createHandler = () => {
        dispatch(
            showProjectCreateModal({
                ...createModalData,
                createData: {
                    requisitionId: requisition.id,
                },
            })
        );
    };

    const searchFormProps = {
        backHandler: addRelationHandler,
        cancelHandler,
        createError,
        createHandler,
        disabled: disabled || isDisabled,
        selectHandler,
        ...searchProps,
    };

    switch (menuItem) {
        case NONE:
            if (reqRelations.length === 0) {
                return <RelationsZeroState addHandler={addRelationHandler} />;
            }
            return (
                <RelationsOptionsMenu
                    addHandler={addRelationHandler}
                    cancelHandler={() => setIsEditing(false)}
                    deleteHandler={deleteHandler}
                    disabled={disabled}
                    editHandler={() => setIsEditing(true)}
                    hasSelections={hasSelections}
                    isEditing={isEditing}
                />
            );
        case SELECT:
            return (
                <Panel>
                    <RelationsSelect cancelHandler={cancelHandler} selectHandler={setMenuItem} />
                </Panel>
            );
        case INTAKE:
        case PROJECT:
            return (
                <Panel>
                    <RelationsProjectSearchForm {...searchFormProps} />
                </Panel>
            );
        case CONTRACT:
            return (
                <Panel>
                    <RelationsContractSearchForm {...searchFormProps} />
                </Panel>
            );
        default:
            return null;
    }
};

RequisitionRelationsMenu.propTypes = {
    deleteHandler: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    hasSelections: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool.isRequired,
    requisition: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }).isRequired,
    setIsEditing: PropTypes.func.isRequired,
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { EvaluationPhaseCreateModal } from './EvaluationPhaseCreateModal';
import { LoadingButton } from '../../../../components';
import { createEvaluationPhase, loadQualifiedProposals } from '../../../../actions/evaluations';

const mapDispatchToProps = {
    createEvaluationPhase,
    loadQualifiedProposals,
};

// @connect
class ConnectedEvaluationPhaseCreateButton extends Component {
    static propTypes = {
        bsSize: PropTypes.string,
        className: PropTypes.string,
        createEvaluationPhase: PropTypes.func.isRequired,
        hasPermission: PropTypes.bool.isRequired,
        loadQualifiedProposals: PropTypes.func.isRequired,
        project: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);

        this.state = this.initialState;
    }

    initialState = {
        loading: false,
        proposals: [],
        showModal: false,
    };

    hideModalHandler = () => this.setState(this.initialState);

    showModalHandler = () => {
        const { project } = this.props;

        this.setState({ loading: true });

        return this.props
            .loadQualifiedProposals(project.id)
            .then((proposals) => {
                this.setState({
                    loading: false,
                    proposals,
                    showModal: true,
                });
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };

    render() {
        const { bsSize, className, hasPermission, project } = this.props;

        const { loading, proposals, showModal } = this.state;

        return (
            <>
                <LoadingButton
                    bsSize={bsSize}
                    className={className}
                    disabled={!hasPermission || loading}
                    icon="fa-plus"
                    loading={loading}
                    onClick={this.showModalHandler}
                    text="New Phase"
                    tooltip={
                        hasPermission
                            ? undefined
                            : 'Only evaluation editors can create new evaluation phases'
                    }
                />
                {showModal && (
                    <EvaluationPhaseCreateModal
                        createEvaluationPhase={this.props.createEvaluationPhase}
                        hideModal={this.hideModalHandler}
                        project={project}
                        proposals={proposals}
                    />
                )}
            </>
        );
    }
}

export const EvaluationPhaseCreateButton = connect(
    null,
    mapDispatchToProps
)(ConnectedEvaluationPhaseCreateButton);

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useBlocker } from 'react-router-dom';

import { resetContract } from '../../actions/contracts';

export const ResetContract = ({ children }) => {
    const dispatch = useDispatch();

    useBlocker(({ currentLocation, nextLocation }) => {
        if (
            /contracts\/\d+/.test(nextLocation.pathname) &&
            !currentLocation.pathname.includes('contracts/create')
        ) {
            return false;
        }

        dispatch(resetContract());

        return false;
    });

    return children;
};

ResetContract.propTypes = {
    children: PropTypes.node.isRequired,
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';
import { connect } from 'react-redux';

import { projectStatusesDict } from '@og-pro/shared-config/projects';

import { proposalStatusesDict } from '@og-pro/shared-config/proposals';

import { AddendumsConfirmModal } from './AddendumsConfirmModal';
import { Button } from '../..';
import { getProposalJS } from '../../../containers/VendorApp/selectors';
import { getPublicProjectJS } from '../../../containers/selectors';
import { confirmAddendum } from '../../../actions/vendProposals';

const { OPEN } = projectStatusesDict;

const { PUBLISHED } = proposalStatusesDict;

const mapStateToProps = (state) => {
    return {
        project: getPublicProjectJS(state),
        proposal: getProposalJS(state),
    };
};

const mapDispatchToProps = {
    confirmAddendum,
};

// @connect
class ConnectedAddendumsConfirmAlert extends Component {
    static propTypes = {
        confirmAddendum: PropTypes.func.isRequired,
        project: PropTypes.shape({
            status: PropTypes.string.isRequired,
        }).isRequired,
        proposal: PropTypes.shape({
            addendums: PropTypes.array.isRequired,
            id: PropTypes.number.isRequired,
            status: PropTypes.string.isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    confirmAddendum = (addendumId) => {
        const { proposal } = this.props;

        return this.props.confirmAddendum(proposal.id, addendumId);
    };

    render() {
        const { project, proposal } = this.props;

        const { showModal } = this.state;

        if (!proposal || !project || proposal.status !== PUBLISHED || project.status !== OPEN) {
            return null;
        }

        const { addendums } = proposal;
        const unconfirmedAddendums = addendums.filter((addendum) => {
            return (addendum.addendumConfirms || []).length === 0;
        });

        if (unconfirmedAddendums.length === 0) {
            return null;
        }

        return (
            <>
                <Alert bsStyle="danger" className="text-center">
                    <i className="fa fa-lg fa-exclamation-triangle" /> You have addenda that must be
                    acknowledged! Failure to do so could cause your proposal to be disqualified.
                    <br />
                    <Button
                        bsStyle="danger"
                        className={this.styles.confirmButton}
                        onClick={() => this.setState({ showModal: true })}
                    >
                        Acknowledge Receipt of Addenda
                    </Button>
                </Alert>
                {showModal && (
                    <AddendumsConfirmModal
                        confirmAddendum={this.confirmAddendum}
                        hideModal={() => this.setState({ showModal: false })}
                        initialValues={{ addendums }}
                    />
                )}
            </>
        );
    }
}

export const AddendumsConfirmAlert = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedAddendumsConfirmAlert);

import React from 'react';

import { Box, Typography } from '@og-pro/ui';

import {
    createCustomFieldFormName,
    customFieldFields,
} from '@og-pro/shared-config/customFormService/customField';
import { actionTypes } from '@og-pro/shared-config/customFormService/customFieldLog';

import { useSelector } from 'react-redux';

import { formValueSelector } from 'redux-form';

import { useLoadCustomField } from '../../../../../lib/customFormService/useLoadCustomField';

const dateFormatter = new Intl.DateTimeFormat('en-US', { dateStyle: 'short' });
const timeFormatter = new Intl.DateTimeFormat('en-US', { timeStyle: 'short' });

const { ID } = customFieldFields;

const actionTypeToVerb = (actionType) => {
    switch (actionType) {
        case actionTypes.CREATE:
            return 'Created';
        case actionTypes.PUBLISH:
            return 'Published';
        case actionTypes.UNPUBLISH:
            return 'Unpublished';
        case actionTypes.ARCHIVE:
            return 'Archived';
        case actionTypes.UNARCHIVE:
            return 'Unarchived';
        default:
            return 'Performed unknown action';
    }
};

export const CustomFieldHistory = () => {
    const customFieldId = useSelector((state) =>
        formValueSelector(createCustomFieldFormName)(state, ID)
    );
    const {
        data: { getCustomFieldLogs: customFieldLogs },
    } = useLoadCustomField(customFieldId);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
            }}
        >
            <Typography variant="h4">History</Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                }}
            >
                {customFieldLogs.map((logEntry) => {
                    const dateObject = new Date(parseInt(logEntry.date, 10));
                    return (
                        <Typography
                            key={logEntry.id}
                            sx={(theme) => ({
                                color: theme.palette.text.secondary,
                                fontWeight: theme.typography.fontWeightRegular,
                            })}
                            variant="bodySmall"
                        >
                            {actionTypeToVerb(logEntry.actionType)} on{' '}
                            {dateFormatter.format(dateObject)} at {timeFormatter.format(dateObject)}
                        </Typography>
                    );
                })}
            </Box>
        </Box>
    );
};

CustomFieldHistory.propTypes = {};

import classnames from 'classnames';
import { get, round } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ListGroupItem } from 'react-bootstrap';

import {
    Button,
    ButtonGroup,
    OutlineButton,
    ProgressBar,
    StandardDocumentModalButton,
    UserProfilePicture,
} from '../../../../../components';
import { getProgressBarBsStyle } from '../../../../../helpers';

export class EvaluatorItem extends PureComponent {
    static propTypes = {
        agreement: PropTypes.shape({
            accepted: PropTypes.bool,
            acceptedAt: PropTypes.string,
        }),
        evaluator: PropTypes.shape({
            displayName: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
            numberScored: PropTypes.number.isRequired,
            numberSubmitted: PropTypes.number.isRequired,
            title: PropTypes.string.isRequired,
        }).isRequired,
        getEvaluationPath: PropTypes.func.isRequired,
        hideEvaluatorReminderEmailModal: PropTypes.func.isRequired,
        isEditor: PropTypes.bool.isRequired,
        numberToScore: PropTypes.number.isRequired,
        numberToSubmit: PropTypes.number.isRequired,
        project: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }).isRequired,
        readOnly: PropTypes.bool,
        sendEvaluatorReminderEmail: PropTypes.func.isRequired,
        showEvaluatorReminderEmailModal: PropTypes.func.isRequired,
        standardDocument: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            sendReminderError: null,
            sendingReminder: false,
            sentReminder: false,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    sendEvaluatorReminderEmail = (personalNote) => {
        const { evaluator, hideEvaluatorReminderEmailModal, project, sendEvaluatorReminderEmail } =
            this.props;

        this.setState({
            sendReminderError: null,
            sendingReminder: true,
        });
        hideEvaluatorReminderEmailModal();

        return sendEvaluatorReminderEmail(project.id, evaluator.id, personalNote)
            .then(() => {
                this.setState({
                    sendingReminder: false,
                    sentReminder: true,
                });
            })
            .catch((e) => {
                this.setState({
                    sendReminderError: e.message,
                    sendingReminder: false,
                });
            });
    };

    renderAcceptance() {
        const { agreement, standardDocument } = this.props;

        const accepted = get(agreement, 'accepted');
        const acceptedAt = get(agreement, 'acceptedAt');

        const bsStyle = accepted ? 'text-primary' : 'text-warning';
        const text = accepted ? 'Accepted' : 'Pending';
        const icon = (
            <span className={`fa-stack ${this.styles.agreementIcon}`}>
                <i className={`fa fa-stack-2x fa-${accepted ? 'certificate' : 'circle'}`} />
                <i className={`fa fa-stack-1x fa-inverse fa-${accepted ? 'check' : 'hourglass'}`} />
            </span>
        );

        return (
            <StandardDocumentModalButton
                className={this.styles.acceptanceContainer}
                standardDocument={standardDocument}
            >
                <span className={classnames(bsStyle, this.styles.acceptedText)}>
                    {icon} Agreement {text}
                </span>
                {accepted && acceptedAt && (
                    <span className="text-muted">
                        &nbsp;&nbsp;<em>{moment(acceptedAt).format('M/D/YY h:mma')}</em>
                    </span>
                )}
            </StandardDocumentModalButton>
        );
    }

    renderProgress(props) {
        const { max, now, text } = props;

        if (max === 0) {
            return <em>No responses</em>;
        }

        const percentComplete = round((now / max) * 100, 1);
        const progressLabel = percentComplete !== 0 ? `${percentComplete}%` : null;

        return (
            <>
                <ProgressBar
                    bsStyle={getProgressBarBsStyle(percentComplete)}
                    className={this.styles.progressBar}
                    label={
                        percentComplete === 100 ? (
                            <span>
                                <i className="fa fa-check-circle" /> Complete!
                            </span>
                        ) : (
                            progressLabel
                        )
                    }
                    max={max}
                    min={0}
                    now={now}
                />
                <div className="text-center">
                    <small>
                        {now} of {max} {text}
                    </small>
                </div>
            </>
        );
    }

    renderActionButtons() {
        const {
            evaluator: { id },
            getEvaluationPath,
            isEditor,
            readOnly,
            showEvaluatorReminderEmailModal,
        } = this.props;

        const { sendReminderError, sendingReminder, sentReminder } = this.state;

        if (readOnly) {
            return (
                <Button
                    bsSize="sm"
                    to={getEvaluationPath(`/evaluators/${id}/proposal-evaluations`)}
                >
                    <i className="fa fa-star" /> View Scores
                </Button>
            );
        }

        return (
            <>
                <ButtonGroup bsSize="sm">
                    <OutlineButton
                        bsSize="sm"
                        bsStyle="info"
                        disabled={!isEditor}
                        to={getEvaluationPath(`/evaluators/${id}/proposal-evaluations`)}
                        tooltip={
                            isEditor ? undefined : 'Only evaluation editors can manage evaluators'
                        }
                    >
                        <i className="fa fa-pencil" /> Manage
                    </OutlineButton>
                    <OutlineButton
                        bsSize="sm"
                        bsStyle="info"
                        className={this.styles.remindBtn}
                        disabled={sendingReminder || sentReminder || !isEditor}
                        onClick={() => {
                            showEvaluatorReminderEmailModal(this.sendEvaluatorReminderEmail);
                        }}
                        tooltip={
                            isEditor
                                ? undefined
                                : 'Only evaluation editors can send reminder emails'
                        }
                    >
                        <i className={`fa fa-${sentReminder ? 'send' : 'bell'}`} />
                        &nbsp;
                        {sentReminder ? 'Reminder Sent' : 'Send Reminder'}
                    </OutlineButton>
                </ButtonGroup>
                {sendReminderError && <div className="error-block">{sendReminderError}</div>}
            </>
        );
    }

    render() {
        const {
            evaluator,
            evaluator: { displayName, numberScored, numberSubmitted, title },
            numberToScore,
            numberToSubmit,
            standardDocument,
        } = this.props;

        return (
            <ListGroupItem>
                <div className={classnames('row', this.styles.container)}>
                    <div className="col-md-3">
                        <UserProfilePicture
                            className={this.styles.profilePicture}
                            user={evaluator}
                        />
                        <div className={this.styles.userInfo}>
                            <strong>{displayName}</strong>
                            <div className={`text-muted ${this.styles.positionText}`}>{title}</div>
                        </div>
                        {standardDocument && this.renderAcceptance()}
                    </div>
                    <div className="col-md-3">
                        {this.renderProgress({
                            max: numberToScore,
                            now: numberScored,
                            text: 'scores entered',
                        })}
                    </div>
                    <div className="col-md-3">
                        {this.renderProgress({
                            max: numberToSubmit,
                            now: numberSubmitted,
                            text: 'scorecards submitted',
                        })}
                    </div>
                    <div className="col-md-3">{this.renderActionButtons()}</div>
                </div>
            </ListGroupItem>
        );
    }
}

import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Collapse, CriteriaScoreCell, ScoringCriteriaCell } from '../../../../../../../components';

export class ProposalScoreExpanded extends PureComponent {
    static propTypes = {
        evaluators: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
            })
        ).isRequired,
        proposalScores: PropTypes.arrayOf(
            PropTypes.shape({
                comment: PropTypes.string,
                score: PropTypes.number,
            })
        ).isRequired,
        scoringCriteria: PropTypes.shape({
            title: PropTypes.string.isRequired,
        }).isRequired,
        showAnonymizedEvaluators: PropTypes.bool,
        showComments: PropTypes.bool,
        showCriteriaDescriptions: PropTypes.bool,
        totalWeight: PropTypes.number.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = { showDetail: true };
    }

    get styles() {
        return require('./index.scss');
    }

    toggleShowDetails = () => this.setState((prevState) => ({ showDetail: !prevState.showDetail }));

    render() {
        const {
            evaluators,
            proposalScores,
            scoringCriteria,
            showAnonymizedEvaluators,
            showComments,
            showCriteriaDescriptions,
            totalWeight,
        } = this.props;

        const { showDetail } = this.state;

        return (
            <>
                <h4 className={this.styles.criteriaTitle} onClick={this.toggleShowDetails}>
                    <div className="pull-right">
                        <i className={`fa fa-fw fa-angle-${showDetail ? 'down' : 'left'}`} />
                    </div>
                    {scoringCriteria.title}
                </h4>
                <Collapse in={showDetail}>
                    {showCriteriaDescriptions && (
                        <ScoringCriteriaCell
                            className={this.styles.criteriaDescription}
                            scoringCriteria={scoringCriteria}
                            showDescription
                            totalWeight={totalWeight}
                        />
                    )}
                    {evaluators.map((evaluator, idx) => (
                        <CriteriaScoreCell
                            comment={get(proposalScores, [idx, 'comment'])}
                            criteriaScoreClassName={this.styles.criteriaScore}
                            key={evaluator.id}
                            scoringCriteria={scoringCriteria}
                            showComments={showComments}
                            showTitle
                            titleText={
                                showAnonymizedEvaluators
                                    ? `Evaluator ${idx + 1}: `
                                    : `${evaluator.displayName}: `
                            }
                            value={get(proposalScores, [idx, 'score'])}
                        />
                    ))}
                </Collapse>
            </>
        );
    }
}

import PropTypes from 'prop-types';
import React from 'react';
import { DropdownButton } from 'react-bootstrap';

import { Button, MenuItem } from '../../../../components';

export const TemplateCreateButton = ({
    bsSize,
    createTemplate,
    creating,
    projectTypeMenuItems,
}) => {
    const title = (
        <>
            <i className={`fa fa-${creating ? 'spin fa-spinner' : 'plus'}`} /> New Template
        </>
    );

    if (projectTypeMenuItems.length === 1) {
        return (
            <Button
                bsSize={bsSize}
                bsStyle="primary"
                disabled={creating}
                onClick={() => createTemplate(projectTypeMenuItems[0].type)}
                qaTag="templateAdmin-create"
            >
                {title}
            </Button>
        );
    }

    return (
        <DropdownButton
            bsSize={bsSize}
            bsStyle="primary"
            disabled={creating}
            id="new-template-button"
            pullRight
            title={title}
        >
            {projectTypeMenuItems.map((option) => (
                <MenuItem
                    key={option.type}
                    onClick={() => createTemplate(option.type)}
                    qaTag={`templateAdmin-${option.label}`}
                >
                    <i className={`fa fa-fw fa-${option.icon}`} /> {option.label}
                </MenuItem>
            ))}
        </DropdownButton>
    );
};

TemplateCreateButton.propTypes = {
    bsSize: PropTypes.string,
    createTemplate: PropTypes.func,
    creating: PropTypes.bool,
    projectTypeMenuItems: PropTypes.array.isRequired,
};

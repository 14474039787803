import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Checkbox } from 'react-bootstrap';
import { connect } from 'react-redux';

import { LoadingButton, SectionTitle, StandardDocument } from '..';
import { submitProposalViewerAgreement } from '../../actions/govProjects';

const mapDispatchToProps = {
    submitAgreement: submitProposalViewerAgreement,
};

// @connect
export class ConnectedProposalViewerAgreement extends Component {
    static propTypes = {
        isEvaluation: PropTypes.bool,
        project: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }).isRequired,
        standardDocument: PropTypes.object.isRequired,
        submitAgreement: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            isChecked: false,
            submitError: null,
            submitting: false,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    checkboxChangeHandler = (e) => {
        this.setState({ isChecked: e.target.checked });
    };

    submitAgreement = () => {
        const { project, submitAgreement } = this.props;

        this.setState({
            submitting: true,
            submitError: null,
        });

        return submitAgreement(project.id).catch((error) => {
            this.setState({
                submitting: false,
                submitError: error.message,
            });
        });
    };

    render() {
        const { isEvaluation, standardDocument } = this.props;

        const { isChecked, submitError, submitting } = this.state;

        return (
            <div className="row">
                <div className="col-xs-12 col-md-6 col-md-offset-3">
                    <SectionTitle
                        info={
                            `Before ${
                                isEvaluation ? 'starting your evaluation' : 'viewing responses'
                            } you must agree to your organization's response viewer agreement. ` +
                            'Please review the below information and submit your agreement.'
                        }
                        title={
                            <>
                                <i className="fa fa-exclamation-triangle text-danger" />
                                &nbsp; Agreement Required
                            </>
                        }
                    />
                    <StandardDocument standardDocument={standardDocument} />
                    <div className={this.styles.checkbox}>
                        <Checkbox
                            checked={isChecked}
                            disabled={submitting}
                            onChange={this.checkboxChangeHandler}
                        >
                            I have read and agree to the terms of the agreement
                        </Checkbox>
                    </div>
                    <LoadingButton
                        block
                        bsStyle="primary"
                        disabled={!isChecked || submitting}
                        icon="fa-check"
                        loading={submitting}
                        onClick={this.submitAgreement}
                        text="Submit Agreement"
                    />
                    {submitError && <div className="error-block">{submitError}</div>}
                </div>
            </div>
        );
    }
}

export const ProposalViewerAgreement = connect(
    null,
    mapDispatchToProps
)(ConnectedProposalViewerAgreement);

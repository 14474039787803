import { tagTypesDict } from '@og-pro/shared-config/tags';

import { listToDict } from '../../../utils';

const {
    CONTACT,
    CONTRACT,
    CONTRACT_ATTACHMENT,
    CONTRACT_INSURANCE,
    DELIVERY_CODE,
    FISCAL_YEAR,
    FUNDING_SOURCE,
    PROCUREMENT_CLASSIFICATION,
    PAYMENT_TERMS,
} = tagTypesDict;

export const createTagForm = 'createTagForm';
export const form = 'tags';

export const fields = ['default', 'name'];

export const fieldsDict = listToDict(fields);

export const tagTypeNavItems = (hasContracting, hasRequisition) => {
    const tagTypes = [
        {
            name: 'Fiscal Years',
            type: FISCAL_YEAR,
            hasDefault: true,
        },
        {
            name: 'Procurement Classifications',
            type: PROCUREMENT_CLASSIFICATION,
        },
        {
            name: 'Contact Types',
            type: CONTACT,
        },
    ];

    if (hasContracting) {
        tagTypes.push(
            {
                name: 'Contract Types',
                type: CONTRACT,
            },
            {
                name: 'Contract Document Types',
                type: CONTRACT_ATTACHMENT,
            },
            {
                name: 'Contract Insurance Types',
                type: CONTRACT_INSURANCE,
                hasDefault: true,
            },
            {
                name: 'Funding Sources',
                type: FUNDING_SOURCE,
            }
        );
    }

    if (hasRequisition) {
        tagTypes.push(
            {
                name: 'Delivery Codes',
                type: DELIVERY_CODE,
            },
            {
                name: 'Payment Terms',
                type: PAYMENT_TERMS,
            }
        );
    }

    return tagTypes;
};

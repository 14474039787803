import PropTypes from 'prop-types';
import React from 'react';
import { Panel } from 'react-bootstrap';

import Sticky from 'react-stickynode';

import { ActionButtons } from './ActionButtons';
import { ActionPanelNav } from './ActionPanelNav';

export const ActionPanelContainer = ({ buttons, children, disabled }) => {
    const styles = require('./index.scss');
    const sharedStyles = require('../shared.scss');

    return (
        <Sticky innerActiveClass={sharedStyles.shadow} innerClass={sharedStyles.panelSticky}>
            <Panel className={sharedStyles.panel}>
                <Panel.Body className={sharedStyles.panelContainer}>
                    <div className={sharedStyles.panelBodyContainer}>
                        {children}
                        <div className={styles.actionButtons}>
                            <ActionButtons buttons={buttons} disabled={disabled} />
                        </div>
                    </div>
                </Panel.Body>
            </Panel>
            <ActionPanelNav />
        </Sticky>
    );
};

ActionPanelContainer.propTypes = {
    buttons: PropTypes.array.isRequired,
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { MenuItem } from '..';

export class ProposalMenuItem extends PureComponent {
    static propTypes = {
        icon: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        action: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
    };

    clickHandler = () => {
        this.props.onClick(this.props.action);
    };

    render() {
        const { icon, text } = this.props;
        return (
            <MenuItem onClick={this.clickHandler} qaTag={`proposal-${text}`}>
                <i className={`fa fa-${icon} fa-fw`} /> {text}
            </MenuItem>
        );
    }
}

import React from 'react';
import { tokens } from '@opengov/capital-style';
import { Box } from '@og-pro/ui';

const { colors } = tokens;

export const SharedTextareaToolbar = () => {
    const styles = require('./index.scss');

    return (
        <Box
            className={styles.toolbarContainer}
            minHeight={56}
            position="sticky"
            top={0}
            zIndex={900}
        >
            <Box
                alignItems="center"
                bgcolor={colors.colorWhite}
                borderBottom={`1px solid ${colors.colorGray200}`}
                display="flex"
                id="SharedToolbarContainer"
                minHeight={56}
                px={1}
            />
        </Box>
    );
};

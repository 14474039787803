import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '../../../../components';

export const EntityPositionsCreateButton = ({ onClick }) => {
    return (
        <Button bsStyle="primary" onClick={onClick} qaTag="entityPositionAdmin-create">
            <i className="fa fa-plus" /> Add Position
        </Button>
    );
};

EntityPositionsCreateButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};

import { canadianProvinces } from '@og-pro/shared-config/global';

/**
 * State validation
 * @param {object} values Form values
 * @param {string} [keyName='state'] Form state field name to validate
 * @param {object} [opts={}] Options
 * @param {boolean} [opts.allowNA] Option to allow 'NA' state option to be selected
 * @param {boolean} [opts.isCanada] Option to validate Canadian provinces
 * @param {boolean} [opts.isInternational] Option to validate for international states/provinces
 * @param {boolean} [opts.optional] When `true`, no fields are required
 * @return {object} Form errors object
 */
export function state(values, keyName = 'state', opts = {}) {
    const errors = {};
    const stateValue = values[keyName];

    if (stateValue) {
        if (opts.isInternational) {
            // Special international state/province/region validation
            if (stateValue.length > 50) {
                errors[keyName] = 'Field is too long (max 50 characters)';
            }
            // Regular U.S. state validation
        } else if (opts.isCanada) {
            if (!canadianProvinces.includes(stateValue)) {
                errors[keyName] = 'Must be a valid Canadian province';
            }
        } else if (
            !new RegExp(
                '^(?:A[KLRZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|PA|RI|S[CD]|T[NX]|UT|V[AT]|W[AIVY])*$',
                'i'
            ).test(stateValue)
        ) {
            if (!opts.allowNA || stateValue !== 'N/A') {
                errors[keyName] = 'Must be a valid state abbreviation';
            }
        }
    } else if (!opts.optional) {
        errors[keyName] = 'State is required.';
    }

    return errors;
}

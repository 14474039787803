import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { trackEvent } from '../../helpers';
import { onKeyDown } from '../../helpers/accessibilityHelper';

export class SuggestionListItem extends PureComponent {
    static propTypes = {
        addCriteria: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        isUsed: PropTypes.bool.isRequired,
        nextOrderById: PropTypes.number.isRequired,
        onClick: PropTypes.func,
        sectionType: PropTypes.string.isRequired,
        subsectionType: PropTypes.string.isRequired,
        suggestion: PropTypes.object,
    };

    handleClick = () => {
        const {
            addCriteria,
            disabled,
            isUsed,
            nextOrderById,
            onClick,
            sectionType,
            subsectionType,
            suggestion,
        } = this.props;

        if (disabled || isUsed) {
            return false;
        }

        addCriteria({
            orderById: nextOrderById,
            rawDescription: suggestion.description,
            section_type: sectionType,
            subsection_type: subsectionType,
            sharedId: suggestion.sharedId,
            title: suggestion.title,
        });

        trackEvent('Suggested Item Added', { ...suggestion });

        if (onClick) {
            onClick();
        }
    };

    render() {
        const styles = require('./SuggestionList.scss');
        const { disabled, isUsed, suggestion } = this.props;

        const disabledStyle = disabled ? 'disabled' : '';
        const iconStyle = isUsed ? 'fa-check' : 'fa-plus text-info';
        const listStyle = isUsed ? `list-group-item-info ${styles.disabledItem}` : styles.group;

        return (
            <li
                className={`list-group-item ${listStyle} ${disabledStyle}`}
                onClick={this.handleClick}
                onKeyDown={(event) => onKeyDown(event, this.handleClick)}
                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                tabIndex={0}
            >
                <i className={`fa fa-fw ${iconStyle} ${styles.icon}`} />
                {suggestion.title}
            </li>
        );
    }
}

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { CommentSubsection } from './CommentSubsection';

export class CommentSection extends PureComponent {
    static propTypes = {
        addFollowers: PropTypes.func.isRequired,
        canComment: PropTypes.bool.isRequired,
        fields: PropTypes.arrayOf(
            PropTypes.shape({
                projectFieldData: PropTypes.object.isRequired,
                threadKey: PropTypes.string.isRequired,
                threads: PropTypes.array.isRequired,
            })
        ).isRequired,
        hideFollowersForm: PropTypes.func.isRequired,
        listFilter: PropTypes.string.isRequired,
        projectPath: PropTypes.string.isRequired,
        projectSection: PropTypes.shape({
            id: PropTypes.number.isRequired,
            section_type: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
        }).isRequired,
        reopenThread: PropTypes.func.isRequired,
        replyThread: PropTypes.func.isRequired,
        resolveThread: PropTypes.func.isRequired,
        showFollowersForm: PropTypes.func.isRequired,
        showResolved: PropTypes.bool.isRequired,
        user: PropTypes.object.isRequired,
        users: PropTypes.array.isRequired,
    };

    render() {
        const {
            addFollowers,
            canComment,
            fields,
            hideFollowersForm,
            listFilter,
            projectPath,
            projectSection,
            reopenThread,
            replyThread,
            resolveThread,
            showFollowersForm,
            showResolved,
            user,
            users,
        } = this.props;

        const CommentSubsections = fields.map((subsection, idx) => (
            <CommentSubsection
                addFollowers={addFollowers}
                canComment={canComment}
                hideFollowersForm={hideFollowersForm}
                key={idx}
                listFilter={listFilter}
                projectFieldData={subsection.projectFieldData}
                projectPath={projectPath}
                projectSectionId={projectSection.id}
                reopenThread={reopenThread}
                replyThread={replyThread(subsection.threadKey)}
                resolveThread={resolveThread}
                showFollowersForm={showFollowersForm}
                showResolved={showResolved}
                threadKey={subsection.threadKey}
                threads={subsection.threads}
                user={user}
                users={users}
            />
        ));

        const styles = require('./CommentSection.scss');

        return (
            <div>
                <div className="row">
                    <div className="col-sm-4">
                        <h3 className={`text-primary ${styles.title}`}>{projectSection.title}</h3>
                    </div>
                    <div className="col-xs-12">
                        <div className={styles.titleBorder} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">{CommentSubsections}</div>
                </div>
            </div>
        );
    }
}

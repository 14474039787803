exports.financialSystems = {
    NONE: 0,
    FIN: 1,
};

exports.financialSystemsText = {
    [exports.financialSystems.NONE]: 'None',
    [exports.financialSystems.FIN]: 'OpenGov Financials',
};

exports.financialSystemsOptions = [
    {
        label: exports.financialSystemsText[exports.financialSystems.NONE],
        value: exports.financialSystems.NONE,
    },
    {
        label: exports.financialSystemsText[exports.financialSystems.FIN],
        value: exports.financialSystems.FIN,
        disabledMessage:
            "You need to have OpenGov's Financial Suite entitled in OpenGov's platform (controlpanel) to enable this integration.",
    },
];

import { buildQaTag, componentNames } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { MenuItem as MenuItemBS } from 'react-bootstrap';
import { useMatch, useNavigate } from 'react-router-dom';

const MenuItemLink = (props) => {
    const navigate = useNavigate();
    const matches = useMatch(props.to);
    const { qaTag, ...rest } = props;

    const tagWithComponentName = buildQaTag(qaTag, componentNames.MENU_ITEM);

    return (
        <MenuItemBS
            className={classnames({ active: !!matches })}
            data-qa={tagWithComponentName}
            onClick={(e) => {
                e.preventDefault();
                navigate(props.to);
            }}
            {...rest}
        />
    );
};

export const MenuItem = (props) => {
    const { qaTag, ...rest } = props;
    const tagWithComponentName = buildQaTag(qaTag, componentNames.MENU_ITEM);

    if (props.to) {
        return <MenuItemLink {...props} />;
    }

    return <MenuItemBS data-qa={tagWithComponentName} {...rest} />;
};

MenuItemLink.propTypes = {
    qaTag: PropTypes.string.isRequired, // Format: <pageName>-<simpleDescription>
    to: PropTypes.string,
};

MenuItem.propTypes = {
    qaTag: PropTypes.string.isRequired, // Format: <pageName>-<simpleDescription>
    to: PropTypes.string,
};

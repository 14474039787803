import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, getFormSyncErrors, getFormValues } from 'redux-form';

import { BudgetCheck } from '../../../../BudgetCheck';
import { getIsRequisitionUsingFMS } from '../../../../../../../../../../selectors/govApp';
import { MaskedInputText } from '../../../../../../../../../../hocs';
import { Button, InputText } from '../../../../../../../../../../components';
import { accountFieldNames, accountObjectFieldNames } from './constants';
import { AccountNumberInput } from '../../../../../../../../../../lib/ogFinancials';
import { maskNumberWithCommas } from '../../../../../../../../../../Forms/maskers';
import { dollarString } from '../../../../../../../../../../Forms/normalizers';
import { formConfig } from '../../../../../../form';

const { ACCOUNT_NUMBER, AMOUNT, ACCOUNT_OBJECT, ACCOUNT_EXTERNAL_ID, AVAILABLE_BUDGET_AMOUNT } =
    accountFieldNames;
const {
    ACCOUNT_COMPRESSED_FORMATTED,
    DESCRIPTION,
    ACCOUNT_PSEUDO_KEY,
    ACCOUNT_TYPE,
    IS_EXPENSE_ACCOUNT,
} = accountObjectFieldNames;

export const AccountField = ({
    coaConfigLookupData,
    change,
    disabled,
    isDraft,
    fieldName,
    field,
    hasSiblings,
    index,
    removeAccount,
    setRemaining,
    showFormValidation,
}) => {
    const dispatch = useDispatch();
    const styles = require('./index.scss');

    const hasFMS = useSelector(getIsRequisitionUsingFMS);
    const MaskedInput = useCallback(MaskedInputText(InputText), []);

    const errors = useSelector((state) => getFormSyncErrors(formConfig.form)(state));
    const currentAccountErrors = get(errors, fieldName, '');
    const formValues = useSelector((state) => getFormValues(formConfig.form)(state));

    const isDisabled = !!disabled;
    const isDisabledOutsideOfDraft = isDisabled || !isDraft;

    const handleAccountChange = (newAccount) => {
        if (newAccount?.account_pseudo_key) {
            const existingAccount = get(formValues, `${fieldName}.${ACCOUNT_OBJECT}`) || {};

            const accountAlreadyExists =
                existingAccount[ACCOUNT_PSEUDO_KEY] === newAccount.account_pseudo_key;

            if (!accountAlreadyExists) {
                dispatch(
                    change(`${fieldName}.${ACCOUNT_OBJECT}`, {
                        [ACCOUNT_COMPRESSED_FORMATTED]: newAccount.account_compressed_formatted,
                        [DESCRIPTION]: newAccount.description,
                        [ACCOUNT_PSEUDO_KEY]: newAccount.account_pseudo_key,
                        [ACCOUNT_TYPE]: newAccount.account_type,
                        [IS_EXPENSE_ACCOUNT]: newAccount.is_expense_account,
                    })
                );
                dispatch(change(`${fieldName}.${ACCOUNT_NUMBER}`, newAccount.account_compressed));
                dispatch(
                    change(`${fieldName}.${ACCOUNT_EXTERNAL_ID}`, newAccount.account_pseudo_key)
                );
            }
        }
    };

    const handleAccountClean = () => {
        dispatch(change(`${fieldName}.${ACCOUNT_OBJECT}`, null));
        dispatch(change(`${fieldName}.${ACCOUNT_NUMBER}`, null));
        dispatch(change(`${fieldName}.${ACCOUNT_EXTERNAL_ID}`, null));
        dispatch(change(`${fieldName}.${AVAILABLE_BUDGET_AMOUNT}`, null));
    };

    const isAmountFieldDisabled = isDisabledOutsideOfDraft || !hasSiblings;

    return (
        <>
            {hasFMS && <BudgetCheck field={field} fieldName={fieldName} />}
            <div className={styles.accountNumber}>
                <label className={styles.mobileLabel} id="accountField">
                    Account
                </label>
                <Field
                    accountCompressedFormatted={
                        field[ACCOUNT_OBJECT]?.[ACCOUNT_COMPRESSED_FORMATTED]
                    }
                    accountDescription={field[ACCOUNT_OBJECT]?.[DESCRIPTION]}
                    aria-labelledby="accountField"
                    coaConfigLookupData={coaConfigLookupData}
                    component={hasFMS ? AccountNumberInput : InputText}
                    disabled={isDisabled}
                    error={currentAccountErrors[ACCOUNT_NUMBER]}
                    hasFeedback={false}
                    inputKey={field.id || field.tempId}
                    isExpenseAccount={field[ACCOUNT_OBJECT]?.[IS_EXPENSE_ACCOUNT]}
                    name={`${fieldName}.${ACCOUNT_NUMBER}`}
                    onAccountChange={handleAccountChange}
                    onClear={() => handleAccountClean()}
                    qaTag={`requisitionCreate${fieldName}-${ACCOUNT_NUMBER}`}
                    showValidation={showFormValidation}
                    useOpenGovStyle
                />
            </div>
            <div className={styles.amount}>
                <label className={styles.mobileLabel} id="amount">
                    Amount
                </label>
                <Field
                    aria-labelledby="amount"
                    component={MaskedInput}
                    disabled={isAmountFieldDisabled}
                    error={currentAccountErrors[AMOUNT]}
                    hasFeedback={false}
                    inputGroupPrefix="$"
                    mask={(val) =>
                        maskNumberWithCommas(val, {
                            maximumFractionDigits: isAmountFieldDisabled && 2,
                        })
                    }
                    name={`${fieldName}.${AMOUNT}`}
                    normalizer={dollarString}
                    onBlur={setRemaining}
                    qaTag={`requisitionCreate${fieldName}-${AMOUNT}`}
                    showValidation={showFormValidation}
                    style={{ zIndex: 0 }}
                    useOpenGovStyle
                />
                {hasSiblings && (
                    <div>
                        <Button
                            bsStyle="link"
                            className={styles.unstyledButton}
                            disabled={isDisabledOutsideOfDraft}
                            onClick={() => removeAccount(index)}
                            qaTag={`requisitionCreate${fieldName}-remove`}
                        >
                            Remove Account
                        </Button>
                    </div>
                )}
            </div>
        </>
    );
};

AccountField.propTypes = {
    change: PropTypes.func.isRequired,
    coaConfigLookupData: PropTypes.object,
    disabled: PropTypes.bool,
    isDraft: PropTypes.bool,
    fieldName: PropTypes.string.isRequired,
    field: PropTypes.shape({
        id: PropTypes.number,
        tempId: PropTypes.string,
    }),
    index: PropTypes.number.isRequired,
    removeAccount: PropTypes.func.isRequired,
    setRemaining: PropTypes.func.isRequired,
    showFormValidation: PropTypes.bool,
    hasSiblings: PropTypes.bool,
};

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { chartColors } from './constants';
import { currencyFormatter } from '../../../helpers';
import { getContractSpendByDepartmentForDefaultFiscalYearData } from './helpers';
import { NoChartDataPanel } from '../../../components';
import { getDefaultFiscalYearTag } from '../ContractSpendManagement/selectors';

// using the HOC instead of the react-router-dom hook because the helper functions are prepareted
// to expect a router
export const ContractSpendByDepartment = withRouter(({ contracts, departments, router }) => {
    const defaultFiscalYearTag = useSelector(getDefaultFiscalYearTag);
    const baseChartTitle = `Issued Order Amounts by Department`;

    if (!defaultFiscalYearTag) {
        return (
            <NoChartDataPanel
                icon="bar-chart"
                subtitle="Default fiscal year has not been set"
                title={baseChartTitle}
            />
        );
    }

    const contractsByDepartmentForDefaultFiscalYearChartData =
        getContractSpendByDepartmentForDefaultFiscalYearData(
            contracts,
            departments,
            defaultFiscalYearTag,
            router
        );

    const chartTitleWithDefaultFiscalYear = `${baseChartTitle} for ${defaultFiscalYearTag.name}`;

    if (contractsByDepartmentForDefaultFiscalYearChartData.length === 0) {
        return (
            <NoChartDataPanel
                icon="bar-chart"
                subtitle="No departments with annual contract spend"
                title={chartTitleWithDefaultFiscalYear}
            />
        );
    }

    const chartOptions = {
        chart: {
            borderColor: '#dce0e0',
            borderWidth: 1,
            height: 300,
            renderTo: 'contract-spend-by-department-chart',
            style: {
                fontWeight: 500,
                fontSize: '18px',
                fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
            },
            type: 'bar',
        },
        colors: chartColors,
        credits: {
            enabled: false,
        },
        legend: {
            labelFormatter() {
                // this in this context is referring to the highcharts chart instance
                return `${this.name}: ${this.userOptions.label}`;
            },
            reversed: true,
        },
        plotOptions: {
            series: {
                stacking: 'normal',
            },
        },
        series: contractsByDepartmentForDefaultFiscalYearChartData,
        title: {
            text: chartTitleWithDefaultFiscalYear,
        },
        tooltip: {
            formatter() {
                // this in this context is referring to the highcharts chart instance
                return `<strong>${this.series.name}</strong>: ${this.series.userOptions.label}<br />${this.series.userOptions.description}`;
            },
            style: {
                width: 300,
            },
        },
        xAxis: {
            categories: [''],
        },
        yAxis: {
            allowDecimals: false,
            endOnTick: false,
            labels: {
                enabled: true,
                formatter() {
                    return currencyFormatter(this, { use0FractionDigits: true });
                },
            },
            min: 0,
            title: {
                text: null,
            },
        },
    };

    return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
});

ContractSpendByDepartment.propTypes = {
    contracts: PropTypes.array.isRequired,
    departments: PropTypes.array.isRequired,
    router: PropTypes.object.isRequired,
};

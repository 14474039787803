import { get } from 'lodash';

import { showSnackbar } from './notification';
import { renderProjectStaticMarkup } from './renderProjectStaticMarkup';
import { emitProjectSocket } from './utils';
import { resourceManager } from '../helpers';

export const LOAD = 'gov/revisions/LOAD';
export const LOAD_SUCCESS = 'gov/revisions/LOAD_SUCCESS';
export const LOAD_FAIL = 'gov/revisions/LOAD_FAIL';
export const LOAD_DIFF = 'gov/revision/LOAD_DIFF';
export const LOAD_DIFF_SUCCESS = 'gov/revisions/LOAD_DIFF_SUCCESS';
export const LOAD_DIFF_FAIL = 'gov/revisions/LOAD_DIFF_FAIL';
export const SET_ACTIVE = 'gov/revision/SET_ACTIVE';
export const RESET_REFRESH_DIFF = 'gov/revisions/RESET_REFRESH_DIFF';
export const RESET = 'gov/revisions/RESET';
export const RESET_REVISION_DIFF = 'gov/revisions/RESET_REVISION_DIFF';

export const LOAD_MORE = 'gov/revisions/LOAD_MORE';
export const LOAD_MORE_SUCCESS = 'gov/revisions/LOAD_MORE_SUCCESS';
export const LOAD_MORE_FAIL = 'gov/revisions/LOAD_MORE_FAIL';

export const CREATE_VERSION = 'gov/revisions/CREATE_VERSION';
export const CREATE_VERSION_SUCCESS = 'gov/revisions/CREATE_VERSION_SUCCESS';
export const CREATE_VERSION_FAIL = 'gov/revisions/CREATE_VERSION_FAIL';

export const SEARCH = 'gov/revisions/SEARCH';
export const SEARCH_SUCCESS = 'gov/revisions/SEARCH_SUCCESS';
export const SEARCH_FAIL = 'gov/revisions/SEARCH_FAIL';

export const SET_CURRENT_VERSION = 'gov/revisions/SET_CURRENT_VERSION';

function getActiveIndex(activeIdx, revisions, hasMore) {
    // Do not allow the last revision to be used as index if there are more
    const viewableRevisions = revisions.length - (hasMore ? 1 : 0);
    if (
        Number.isNaN(Number.parseFloat(activeIdx)) ||
        activeIdx >= viewableRevisions ||
        activeIdx < 0
    ) {
        return 0;
    }
    return activeIdx;
}

function loadDiff(projectId, projectData1, projectData2) {
    return resourceManager({
        method: 'post',
        url: `/project/${projectId}/revisions/diff`,
        requestOptions: {
            data: {
                html1: renderProjectStaticMarkup(projectData1, { isRevisionsDiff: true }),
                html2: renderProjectStaticMarkup(projectData2, { isRevisionsDiff: true }),
            },
        },
        onStart: ({ dispatch }) => {
            dispatch({ type: LOAD_DIFF, message: 'Generating revision diff' });
        },
        onSuccess: ({ dispatch, result }) => {
            dispatch({ type: LOAD_DIFF_SUCCESS, result });
            return result;
        },
        onFailure: ({ error, dispatch }) => {
            dispatch({ type: LOAD_DIFF_FAIL, error });
            return error;
        },
    });
}

function searchRevisions(projectId, projectAuditIds) {
    return resourceManager({
        method: 'post',
        url: `/project/${projectId}/revisions/search`,
        requestOptions: {
            data: { projectAuditIds },
        },
        onStart: ({ dispatch }) => {
            dispatch({ type: SEARCH });
        },
        onSuccess: ({ dispatch, result }) => {
            dispatch({ type: SEARCH_SUCCESS, result });
            return result;
        },
        onFailure: ({ error, dispatch }) => {
            dispatch({ type: SEARCH_FAIL, error });
            throw error;
        },
    });
}

export function loadAndCompareRevisions(projectId, projectAudit1, projectAudit2, opts = {}) {
    return (dispatch) => {
        dispatch({ type: LOAD_DIFF, message: 'Getting revisions to compare' });

        const REPLACE = 'replace';
        const queries = [];
        const results = [];

        // Project audit can be a project body if the current version is one of the items.
        // When that is the case we need to remove from the query, but add to the results.
        [projectAudit1, projectAudit2].forEach((projectAuditId) => {
            if (projectAuditId === Number.parseInt(projectAuditId, 10)) {
                queries.push(projectAuditId);
                results.push(REPLACE);
            } else {
                results.push(projectAuditId);
            }
        });

        return dispatch(searchRevisions(projectId, queries))
            .then((result) => {
                let projectData = results.map((data) => {
                    if (data === REPLACE) {
                        return get(result.shift(), 'state');
                    }
                    return data;
                });

                if (opts.isPublic) {
                    // Remove private info from public diffs
                    projectData = projectData.map((project) => {
                        return {
                            ...project,
                            budget: null,
                        };
                    });
                }

                return dispatch(loadDiff(projectId, projectData[0], projectData[1]));
            })
            .catch((error) => {
                dispatch({ type: LOAD_DIFF_FAIL, error });
                if (opts.rethrowError) {
                    throw error;
                }
                return error;
            });
    };
}

export function loadDocumentVersion(projectId, projectAuditId) {
    return (dispatch) => {
        return dispatch(searchRevisions(projectId, [projectAuditId]))
            .then((result) => {
                const documentVersion = result[0];

                if (!documentVersion || !documentVersion.state) {
                    return dispatch({
                        type: SEARCH_FAIL,
                        error: { message: 'Version not found' },
                    });
                }

                return dispatch({ type: SET_CURRENT_VERSION, result: documentVersion });
            })
            .catch(() => {}); // For this error updates to the store happen in `searchRevisions`
    };
}

export function loadSaveHistoryDiff(projectId, revisionIdx) {
    return (dispatch, getState) => {
        const projectAudits = getState().revisions.get('projectAudits').toJS();

        // Stop the process if there are no revisions
        if (projectAudits.length === 0) {
            return dispatch({ type: LOAD_DIFF_SUCCESS, result: { diff: null } });
        }

        let activeIdx = getState().revisions.get('activeIdx');
        // Since currently this is called via the fetch attribute
        // after a location transition, this must be responsible
        // for setting the active index which is a location param
        if (revisionIdx !== activeIdx) {
            const hasMore = getState().revisions.get('hasMore');
            activeIdx = getActiveIndex(revisionIdx, projectAudits, hasMore);
            dispatch({ type: SET_ACTIVE, idx: activeIdx });
        }

        const projectAudit1 = get(projectAudits, [activeIdx + 1, 'id']);
        const projectAudit2 = get(projectAudits, [activeIdx, 'id']);

        return dispatch(loadAndCompareRevisions(projectId, projectAudit1, projectAudit2));
    };
}

export function loadRevisions(projectId, options) {
    return resourceManager({
        method: 'get',
        url: `/project/${projectId}/revisions`,
        onStart: ({ dispatch }) => dispatch({ type: LOAD }),
        onSuccess: ({ result, dispatch }) => {
            dispatch({ type: LOAD_SUCCESS, result });
            return result;
        },
        onFailure: ({ context, error, dispatch }) => {
            dispatch({ type: LOAD_FAIL, error });
            if (context.retrowError) {
                throw error;
            }
        },
        context: options,
    });
}

export function loadMoreRevisions(projectId, lastRevision) {
    return resourceManager({
        method: 'get',
        url: `/project/${projectId}/revisions`,
        requestOptions: {
            params: { lastRevision: lastRevision.created_at },
        },
        onStart: ({ dispatch }) => dispatch({ type: LOAD_MORE }),
        onSuccess: ({ result, dispatch }) => dispatch({ type: LOAD_MORE_SUCCESS, result }),
        onFailure: ({ error, dispatch }) => dispatch({ type: LOAD_MORE_FAIL, error }),
    });
}

export function resetRefreshRevisionHtmlDiff() {
    return { type: RESET_REFRESH_DIFF };
}

export function createDocumentVersion(projectId, data) {
    return resourceManager({
        method: 'post',
        url: `/project/${projectId}/revisions/document-versions`,
        requestOptions: { data },
        onStart: ({ dispatch }) => dispatch({ type: CREATE_VERSION }),
        onSuccess: ({ result, dispatch }) => {
            const createAction = { type: CREATE_VERSION_SUCCESS, result };
            dispatch(emitProjectSocket(projectId, createAction, 'New Version Saved'));
            dispatch(showSnackbar('Version saved'));
            return result;
        },
        onFailure: ({ error, dispatch }) => {
            dispatch({ type: CREATE_VERSION_FAIL, error });
            return error;
        },
    });
}

export function resetRevisions() {
    return { type: RESET };
}

export function resetRevisionDiff() {
    return { type: RESET_REVISION_DIFF };
}

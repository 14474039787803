import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useBlocker } from 'react-router-dom';

export const RouteLeaveWarning = ({
    blockingValue,
    evaluationFunction,
    warningMessage = 'You have unsaved information, are you sure you want to leave this page?',
}) => {
    const blocker = useBlocker(({ currentLocation, nextLocation }) => {
        if (evaluationFunction) {
            return evaluationFunction({ currentLocation, nextLocation });
        }

        return currentLocation.pathname !== nextLocation.pathname && blockingValue;
    });

    useEffect(() => {
        if (blocker.state === 'blocked') {
            const response = window.confirm(warningMessage);

            if (response) {
                blocker.proceed();
            } else {
                blocker.reset();
            }
        }
    }, [blocker.state, warningMessage]);

    return null;
};

RouteLeaveWarning.propTypes = {
    blockingValue: PropTypes.bool,
    evaluationFunction: PropTypes.func,
    warningMessage: PropTypes.string,
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ListGroupItem } from 'react-bootstrap';
import { reduxForm, Field } from 'redux-form';
import { Box } from '@og-pro/ui';

import { fieldsDict } from '../constants';
import { validate } from '../validate';
import {
    Button,
    Checkbox,
    InputText,
    OrderButtons,
    OutlineButton,
    StatusLabel,
} from '../../../../components';

const { DEFAULT, NAME } = fieldsDict;

const formConfig = {
    enableReinitialize: true,
    validate,
};

// @reduxForm
class ConnectedTagRowForm extends Component {
    static propTypes = {
        deleteTag: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        handleSubmit: PropTypes.func.isRequired,
        hasDefault: PropTypes.bool,
        index: PropTypes.number.isRequired,
        isFirstItem: PropTypes.bool,
        isLastItem: PropTypes.bool,
        reset: PropTypes.func.isRequired,
        showConfirmationSimpleModal: PropTypes.func.isRequired,
        swapTags: PropTypes.func.isRequired,
        tag: PropTypes.shape({
            default: PropTypes.bool,
            id: PropTypes.number.isRequired,
            isGlobal: PropTypes.bool,
            name: PropTypes.string.isRequired,
        }).isRequired,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
            })
        ),
        updateTag: PropTypes.func.isRequired,
    };

    static defaultProps = {
        disabled: false,
        isFirstItem: false,
        isLastItem: false,
    };

    constructor(props) {
        super(props);

        this.state = { editing: false };
    }

    get styles() {
        return require('./index.scss');
    }

    handleEditClick = () => {
        this.setState({ editing: true });
    };

    handleCancelEditClick = () => {
        this.setState({ editing: false });
    };

    handleDeleteTag = () => {
        const {
            deleteTag,
            tag: { id: tagId, name },
            showConfirmationSimpleModal,
        } = this.props;

        showConfirmationSimpleModal(() => deleteTag(tagId), {
            btnText: 'Delete Tag',
            text: `Are you sure you want to delete the "${name}" tag?`,
        });
    };

    handleSwapTags = (index, direction) => {
        const {
            swapTags,
            tag: { id: tagAId },
            tags,
        } = this.props;

        const tagBId =
            direction === OrderButtons.OrderButtonDirectionDict.UP
                ? tags[index - 1].id
                : tags[index + 1].id;

        swapTags(tagAId, tagBId);
    };

    handleUpdateTag = (tagData) => {
        const {
            tag: { id: tagId },
            reset,
            updateTag,
        } = this.props;

        updateTag(tagId, tagData).then(() => {
            reset();
            this.setState({ editing: false });
        });
    };

    renderEdit() {
        const { disabled, handleSubmit, hasDefault } = this.props;

        return (
            <>
                <div className="row">
                    <div className={hasDefault ? 'col-xs-9' : 'col-xs-12'}>
                        <Field component={InputText} disabled={disabled} hasFeedback name={NAME} />
                    </div>
                    {hasDefault && (
                        <div className="col-xs-3">
                            <Field
                                component={Checkbox}
                                disabled={disabled}
                                name={DEFAULT}
                                text="Default"
                            />
                        </div>
                    )}
                </div>
                <div className="text-right">
                    <Button
                        bsSize="sm"
                        bsStyle="link"
                        disabled={disabled}
                        onClick={this.handleCancelEditClick}
                    >
                        Cancel
                    </Button>
                    &nbsp;&nbsp;
                    <OutlineButton
                        bsSize="sm"
                        bsStyle="danger"
                        disabled={disabled}
                        onClick={this.handleDeleteTag}
                    >
                        <i className="fa fa-trash-o" /> Delete
                    </OutlineButton>
                    &nbsp;&nbsp;
                    <Button
                        bsSize="sm"
                        bsStyle="primary"
                        disabled={disabled}
                        onClick={handleSubmit(this.handleUpdateTag)}
                    >
                        <i className="fa fa-pencil" /> Update
                    </Button>
                </div>
            </>
        );
    }

    renderRead() {
        const {
            disabled,
            tag: { default: isDefault, isGlobal, name },
        } = this.props;

        return (
            <>
                {!isGlobal && (
                    <div className="pull-right">
                        <Button
                            bsSize="sm"
                            bsStyle="link"
                            disabled={disabled}
                            onClick={this.handleEditClick}
                        >
                            <i className="fa fa-pencil" /> Edit
                        </Button>
                    </div>
                )}
                {name}
                {isGlobal && (
                    <div>
                        <StatusLabel bsStyle="primary">Global Tag</StatusLabel>
                    </div>
                )}
                {isDefault && (
                    <div>
                        <small>
                            <em>Default</em>
                        </small>
                    </div>
                )}
            </>
        );
    }

    render() {
        const { disabled, index, isFirstItem, isLastItem } = this.props;

        const { editing } = this.state;

        return (
            <ListGroupItem>
                <Box alignItems="center" display="flex">
                    <Box flex={10}>{editing ? this.renderEdit() : this.renderRead()}</Box>
                    <Box flex={2} textAlign="right">
                        <OrderButtons
                            className="pull-right"
                            disabled={disabled}
                            index={index}
                            isFirstItem={isFirstItem}
                            isLastItem={isLastItem}
                            swap={this.handleSwapTags}
                        />
                    </Box>
                </Box>
            </ListGroupItem>
        );
    }
}

export const TagRowForm = reduxForm(formConfig)(ConnectedTagRowForm);

import { listToDict } from '../../../../../utils';

export const form = 'analyticsFilters';

export const fields = [
    'timeRange',
    'startDate',
    'endDate',
    'isCustomDate',
    'templateIds',
    'documentType',
    'procurementContactIds',
    'departmentIds',
];

export const fieldNames = listToDict(fields);

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { isEmail } from 'validator';

import { CommonEmailListRow } from './CommonEmailListRow';
import { emailsFieldName } from '../constants';

const ConnectedEnterEmailAddressesRow = ({
    disabled,
    notifiedUsers,
    onRemove,
    onSelectedValueChange,
    selectedValue,
    setTotal,
}) => {
    const users = selectedValue.reduce((acc, cur) => {
        if (!notifiedUsers.emails.has(cur)) {
            return acc + 1;
        }

        return acc;
    }, 0);

    useEffect(() => {
        setTotal(users || 0);
    }, [selectedValue.length]);

    return (
        <CommonEmailListRow
            disabled={disabled}
            label="Enter Email Addresses"
            onRemove={onRemove}
            onSelectedValueChange={onSelectedValueChange}
            selectedValue={selectedValue}
        />
    );
};

ConnectedEnterEmailAddressesRow.propTypes = {
    disabled: PropTypes.bool,
    notifiedUsers: PropTypes.shape({ emails: PropTypes.arrayOf(PropTypes.string) }), // javascript Set
    onRemove: PropTypes.func.isRequired,
    onSelectedValueChange: PropTypes.func.isRequired,
    selectedValue: PropTypes.arrayOf(PropTypes.string),
    setTotal: PropTypes.func.isRequired,
};

export const EnterEmailAddressesRow = reduxForm({
    form: 'enterEmailAddresses',
    validate: (values) => {
        const errors = {};

        if (values[emailsFieldName]) {
            const invalidEmail = values[emailsFieldName]
                .split(',')
                .find((email) => !isEmail(email.trim()));
            if (invalidEmail) {
                errors[emailsFieldName] = `Invalid email address provided: "${invalidEmail}"`;
            }
        }

        return errors;
    },
})(ConnectedEnterEmailAddressesRow);

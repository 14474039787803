import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { Label, Toggle } from '../../../../../../components';

export class OptionToggle extends PureComponent {
    static propTypes = {
        label: PropTypes.string.isRequired,
        fieldName: PropTypes.string.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const { label, fieldName } = this.props;
        return (
            <div className={this.styles.colToggleContainer}>
                <Label className={this.styles.colToggleLabel} htmlFor={fieldName} label={label} />
                <div className={this.styles.colToggle}>
                    <Field component={Toggle} id={fieldName} name={fieldName} />
                </div>
            </div>
        );
    }
}

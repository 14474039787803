import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { GenericEditor } from '../GenericEditor';

export class TextEditor extends PureComponent {
    static propTypes = {
        charPress: PropTypes.string,
        keyPress: PropTypes.number,
        maxLength: PropTypes.number,
        node: PropTypes.object.isRequired,
        value: PropTypes.node,
    };

    constructor(props) {
        super(props);

        let value = props.value || '';

        if (props.keyPress === 8) {
            value = '';
        } else if (props.charPress) {
            value = props.charPress;
        }

        this.state = {
            value,
        };
    }

    afterGuiAttached = () => {
        this.editor.afterGuiAttached();
    };

    getValue() {
        return this.state.value === '' ? null : this.state.value;
    }

    handleChange = (event) => {
        const { maxLength } = this.props;

        let value = event.target.value;

        if (maxLength && typeof value === 'string' && value.length > maxLength) {
            value = value.slice(0, maxLength);
        }

        this.setState({ value });
    };

    setRef = (ref) => {
        this.editor = ref;
    };

    render() {
        return (
            <GenericEditor
                node={this.props.node}
                onChange={this.handleChange}
                ref={this.setRef}
                value={this.state.value}
            />
        );
    }
}

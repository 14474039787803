import { DESCRIPTION, LIST_ITEMS, TITLE } from './constants';

export const validate = (values) => {
    const errors = {};

    errors[LIST_ITEMS] = (values[LIST_ITEMS] || []).map((listItem) => {
        const listItemError = {};

        if (!listItem[TITLE]) {
            listItemError[TITLE] = 'Text is required';
        } else if (listItem[TITLE].length >= 200) {
            listItemError[TITLE] = 'This field is too long';
        }

        if (!listItem[DESCRIPTION]) {
            listItemError[DESCRIPTION] = 'Description is required';
        }

        return listItemError;
    });

    return errors;
};

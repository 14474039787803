import { createSelector } from 'reselect';

const getVendorCertifications = (state) => state.adminVendor.get('vendorCertifications');

export const getVendorCertificationsJS = createSelector(
    [getVendorCertifications],
    (certifications) =>
        certifications?.toJS().map((cert) => {
            const vendorCertification = cert.vendorCertifications && cert.vendorCertifications[0];
            return {
                ...cert,
                vendorCertification,
            };
        })
);

export const getSelfReportedCertificationsJS = createSelector(
    [getVendorCertifications],
    (certifications) => {
        return certifications?.toJS().reduce((certs, cert) => {
            if (cert.vendorCertifications) {
                cert.vendorCertifications.forEach((vendorCertification) => {
                    if (vendorCertification.certification_source_id === 1) {
                        certs.push({
                            ...cert,
                            vendorCertification,
                        });
                    }
                });
            }
            return certs;
        }, []);
    }
);

export const getVerifiedCertificationsJS = createSelector(
    [getVendorCertifications],
    (certifications) =>
        certifications?.toJS().reduce((certs, cert) => {
            if (cert.vendorCertifications) {
                cert.vendorCertifications.forEach((vendorCertification) => {
                    if (vendorCertification.certification_source_id !== 1) {
                        certs.push({
                            ...cert,
                            vendorCertification,
                        });
                    }
                });
            }
            return certs;
        }, [])
);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { ProgressIcon } from './ProgressIcon';

export class ProgressIconField extends PureComponent {
    static propTypes = {
        meta: PropTypes.object.isRequired,
        input: PropTypes.object.isRequired,
    };

    render() {
        const { meta, input, ...props } = this.props;
        return <ProgressIcon isComplete={meta.valid} {...props} />;
    }
}

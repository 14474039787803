import { get, startCase } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { submit } from 'redux-form';

import { Button, CategoryCodes, OwnershipForm } from '../../../../components';
import {
    fieldNames,
    form as ownershipForm,
} from '../../../../components/Forms/OwnershipForm/constants';

const { CONTACT_ID, DEPARTMENT_ID, PROCUREMENT_CONTACT_ID, USER_ID } = fieldNames;

// @connect
class ConnectedCreateConfirmation extends PureComponent {
    static propTypes = {
        associatedProject: PropTypes.object,
        categories: PropTypes.array.isRequired,
        copiedScopeCount: PropTypes.number.isRequired,
        createData: PropTypes.object,
        defaultProcurementContactId: PropTypes.number,
        isContracts: PropTypes.bool,
        isDocBuilder: PropTypes.bool,
        isEvaluationOnly: PropTypes.bool,
        isPostOnly: PropTypes.bool,
        isIntake: PropTypes.bool,
        showLibraryModal: PropTypes.func.isRequired,
        submitForm: PropTypes.func.isRequired,
        submitHandler: PropTypes.func.isRequired,
        template: PropTypes.string,
        type: PropTypes.string,
        user: PropTypes.object.isRequired,
        usesCategorySelect: PropTypes.bool.isRequired,
    };

    get isSolicitationDocument() {
        const { isContracts, isDocBuilder, isEvaluationOnly, isPostOnly, isIntake } = this.props;

        return !isContracts && !isDocBuilder && !isEvaluationOnly && !isPostOnly && !isIntake;
    }

    get itemToCreate() {
        const { isContracts, isDocBuilder } = this.props;

        if (isContracts) {
            return 'Contract';
        }
        if (isDocBuilder) {
            return 'Document';
        }
        return 'Project';
    }

    render() {
        const {
            associatedProject,
            categories,
            copiedScopeCount,
            createData,
            defaultProcurementContactId,
            isContracts,
            isDocBuilder,
            isEvaluationOnly,
            isPostOnly,
            isIntake,
            showLibraryModal,
            submitForm,
            submitHandler,
            template,
            type,
            usesCategorySelect,
            user,
        } = this.props;

        const styles = require('./shared.scss');

        const initialProjectOwnershipFormValues = {
            [CONTACT_ID]: get(createData, CONTACT_ID) || user.id,
            [DEPARTMENT_ID]: get(createData, DEPARTMENT_ID) || user.department_id,
            [PROCUREMENT_CONTACT_ID]:
                get(createData, PROCUREMENT_CONTACT_ID) || defaultProcurementContactId,
            [USER_ID]: get(createData, USER_ID) || user.id,
        };

        return (
            <div className={styles.confirmationContainer}>
                <div className={styles.confirmTitle}>Ready to Go!</div>
                <dl className="dl-horizontal">
                    <OwnershipForm
                        initialValues={initialProjectOwnershipFormValues}
                        onSubmit={submitHandler}
                        showDepartment
                        showProcurementContact
                        showProjectContact
                        user={user}
                    />
                    {associatedProject && (
                        <>
                            <dt>Associated Project:</dt>
                            <dd>{associatedProject.title}</dd>
                        </>
                    )}
                    {isIntake && (
                        <>
                            <dt>Project Type:</dt>
                            <dd>Project Request</dd>
                        </>
                    )}
                    {isPostOnly && (
                        <>
                            <dt>Project Type:</dt>
                            <dd>Post Bid Opportunity</dd>
                        </>
                    )}
                    {isEvaluationOnly && (
                        <>
                            <dt>Project Type:</dt>
                            <dd>Evaluation</dd>
                        </>
                    )}
                    {isContracts && (
                        <>
                            <dt>Project Type:</dt>
                            <dd>Contract Record</dd>
                        </>
                    )}
                    {usesCategorySelect && !isDocBuilder && (
                        <>
                            <dt>Categories:</dt>
                            <dd>
                                <CategoryCodes
                                    codes={categories}
                                    limit={null}
                                    noCategoriesText="None"
                                />
                            </dd>
                        </>
                    )}
                    {isDocBuilder && type && (
                        <>
                            <dt>Document Type:</dt>
                            <dd>{startCase(type)}</dd>
                        </>
                    )}
                    {template && (
                        <>
                            <dt>Template:</dt>
                            <dd>{template}</dd>
                        </>
                    )}
                    {copiedScopeCount > 0 && (
                        <>
                            <dt>Copied Scope of Work:</dt>
                            <dd>{copiedScopeCount} scope of work items will be added to project</dd>
                        </>
                    )}
                </dl>
                <div>
                    <Button bsSize="lg" bsStyle="success" onClick={() => submitForm(ownershipForm)}>
                        <i className="fa fa-plus" /> Create {this.itemToCreate}
                    </Button>
                </div>
                <div className={styles.libraryBtn}>
                    {(this.isSolicitationDocument || isIntake) && (
                        <Button bsStyle="link" onClick={showLibraryModal}>
                            <i className="fa fa-search" />
                            &nbsp; Search Scope of Work Library
                        </Button>
                    )}
                </div>
            </div>
        );
    }
}

export const CreateConfirmation = connect(null, { submitForm: submit })(
    ConnectedCreateConfirmation
);

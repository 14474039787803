import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export const SDv2Title = ({ hasPrompt, isChildQuestion, isSectionHeader, title }) => {
    const styles = require('./index.scss');

    return (
        <div
            className={classnames(styles.title, {
                [styles.sectionHeader]: isSectionHeader,
                [styles.childQuestionTitle]: isChildQuestion,
                [styles.noPromptBelow]: !hasPrompt,
            })}
        >
            {title}
        </div>
    );
};

SDv2Title.propTypes = {
    hasPrompt: PropTypes.bool,
    isChildQuestion: PropTypes.bool,
    isSectionHeader: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

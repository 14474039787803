import { useMemo } from 'react';
import { sectionTypeNames } from '@og-pro/shared-config/sections';

import { pseudoFieldNames } from '../constants';

const { PROJECT_SECTION } = pseudoFieldNames;

/**
 * Returns a list of project sections to be used in the select of project sections
 * (the second to last in the dialog).
 * There's a special case where the modal is being used to add logic to attachments or signatures
 * where theres no actual project section. In that case we fake it to keep consistency
 *
 * @param {object} modalFormData - the data passed to the modal, passed through a redux action
 * @param {array<object>} projectSections - an array of project sections as returned by the API
 * @returns {array<object>}
 */
export const useProjectSectionSelectOptions = ({ modalFormData, projectSections }) => {
    return useMemo(() => {
        // used to be able to add logic to attachments in the Attachment/Signatures tab
        // where theres no actual project section
        if (modalFormData?.usingFakeSection) {
            return [
                {
                    label: modalFormData.projectSection.title,
                    [PROJECT_SECTION]: modalFormData.projectSection,
                    value: modalFormData.projectSection.id,
                },
            ];
        }

        return projectSections
            .filter((projectSection) => {
                return projectSection.section_type !== sectionTypeNames.INTRODUCTION;
            })
            .map((projectSection) => {
                return {
                    label: projectSection.title,
                    [PROJECT_SECTION]: projectSection,
                    value: projectSection.id,
                };
            });
    }, [modalFormData, projectSections]);
};

import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

const dateFormat = 'M/D/YY h:mma';

export class ApprovalViewStatus extends PureComponent {
    static propTypes = {
        approval: PropTypes.shape({
            approvedAt: PropTypes.string,
            approvingUser: PropTypes.shape({
                displayName: PropTypes.string.isRequired,
                id: PropTypes.number.isRequired,
            }),
            user: PropTypes.shape({
                displayName: PropTypes.string.isRequired,
                id: PropTypes.number.isRequired,
            }).isRequired,
            viewedAt: PropTypes.string,
        }).isRequired,
        isApprover: PropTypes.bool,
        isRejected: PropTypes.bool,
        isIntake: PropTypes.bool,
        isViewOnly: PropTypes.bool,
    };

    get styles() {
        return require('./shared.scss');
    }

    render() {
        const {
            approval: { approvingUser, approvedAt, viewedAt, user },
            isApprover,
            isRejected,
            isIntake,
            isViewOnly,
        } = this.props;

        if (!isViewOnly && isApprover) {
            if (isRejected) {
                return <div className="text-muted">Revise your review</div>;
            }
            return <div className="text-success">Your approval is requested!</div>;
        }

        if (approvedAt) {
            return (
                <>
                    <div>
                        {isIntake ? 'Completed' : 'Approved'}:&nbsp;
                        {moment(approvedAt).format(dateFormat)}
                    </div>
                    {approvingUser && approvingUser.id !== user.id && (
                        <div className={this.styles.approvedBy}>
                            by <strong>{approvingUser.displayName}</strong>
                            <br />
                            on behalf of {user.displayName}
                        </div>
                    )}
                </>
            );
        }

        if (!viewedAt) {
            return <div className="text-muted">Not viewed yet</div>;
        }

        return <div className="text-info">Viewed: {moment(viewedAt).format(dateFormat)}</div>;
    }
}

import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { Body } from './Body';
import { getDiscoverVendorData } from './helper';
import { getProjectJS } from '../selectors';
import { hideVendorDiscoveryModal } from '../../../actions/govProjects';
import { discoverVendors } from '../../../actions/vendorDiscovery';

export const VendorDiscoveryModal = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [loadingError, setLoadingError] = useState(null);
    const [vendors, setVendors] = useState([]);
    const [searchNum, setSearchNum] = useState(1);

    const project = useSelector(getProjectJS);

    const dispatch = useDispatch();
    const hideModal = () => dispatch(hideVendorDiscoveryModal());

    // Note: to avoid long wait times when fetching data from OpenAI, you can enable dummy data and
    // set state outside the loading event. Dummy data can be imported from here:
    // import { dummyVendors } from './dummyData';
    // setVendors(dummyVendors);
    useEffect(() => {
        setIsLoading(true);
        setLoadingError(null);
        setVendors([]);

        const data = getDiscoverVendorData(project);
        dispatch(discoverVendors(project, data))
            .then((foundVendors) => {
                setVendors(foundVendors);
                setIsLoading(false);
            })
            .catch((error) => {
                setLoadingError(error.message);
                setIsLoading(false);
            });
    }, [searchNum]);

    const retrySearch = () => setSearchNum(searchNum + 1);

    const styles = require('./index.scss');

    return (
        <Modal onHide={hideModal} show>
            <Modal.Body>
                <div className={styles.closeButtonContainer}>
                    <button className="close" onClick={hideModal} type="button">
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <Body
                    isLoading={isLoading}
                    loadingError={loadingError}
                    project={project}
                    retrySearch={retrySearch}
                    vendors={vendors}
                />
            </Modal.Body>
        </Modal>
    );
};

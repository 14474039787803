import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ListGroup } from 'react-bootstrap';

import { ProposalDocumentsUploadListItem } from './ProposalDocumentsUploadListItem';

export class ProposalDocumentsUploadList extends PureComponent {
    static propTypes = {
        fields: PropTypes.shape({
            name: PropTypes.string,
        }).isRequired,
        proposalDocuments: PropTypes.array.isRequired,
        disabled: PropTypes.bool.isRequired,
        uploadHandler: PropTypes.func.isRequired,
        deleteAttachment: PropTypes.func.isRequired,
        showValidation: PropTypes.bool,
    };

    addAttachment = (proposalDocumentId, index) => (files) => {
        const {
            fields: { name },
            uploadHandler,
        } = this.props;
        const formKey = `${name}[${index}].attachments`;
        uploadHandler(proposalDocumentId, formKey, files);
    };

    removeAttachment = (proposalDocIdx) => (attachmentId, attachmentIdx) => {
        const {
            fields: { name },
            deleteAttachment,
        } = this.props;
        const formKey = `${name}[${proposalDocIdx}].attachments`;
        deleteAttachment(attachmentId, formKey, attachmentIdx);
    };

    render() {
        const {
            fields: { name },
            proposalDocuments,
            showValidation,
        } = this.props;

        /**
         * NOTE: proposalDocuments circumvents redux forms by using the store
         * values in all cases. The evaluations action creator is then used to
         * update the form in select places, so validation can happen.
         */
        const Docs = proposalDocuments.map((doc, index) => (
            <ProposalDocumentsUploadListItem
                arrayName={`${name}[${index}]`}
                deleteAttachment={this.removeAttachment(index)}
                disabled={this.props.disabled}
                isComplete={doc.attachments.length > 0}
                key={doc.id || doc.key}
                proposalDocument={doc}
                showValidation={showValidation}
                uploadHandler={this.addAttachment(doc.id, index)}
            />
        ));

        return <ListGroup>{Docs}</ListGroup>;
    }
}

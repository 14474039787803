import { Typography } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { ResourceSelect } from './ResourceSelect';
import { ResourceInput } from './ResourceInput';

const VENDOR = 'vendor';

export const SelectedVendorSearch = ({
    disabled,
    onContractChange,
    onVendorChange,
    showFormValidation,
}) => {
    const [selectedSearch, setSelectedSearch] = useState(VENDOR);

    return (
        <>
            <Typography variant="h4">Find a Vendor or a Contract</Typography>
            <Typography color="secondary" marginBottom={0.5} variant="bodySmall">
                Search by Company Name, Contract Title, or ID
            </Typography>
            <ResourceInput
                disabled={disabled}
                isVendorSearch={selectedSearch === VENDOR}
                onContractChange={onContractChange}
                onVendorChange={onVendorChange}
                showFormValidation={showFormValidation}
            />
            <ResourceSelect selectedSearch={selectedSearch} setSelectedSearch={setSelectedSearch} />
        </>
    );
};

SelectedVendorSearch.propTypes = {
    disabled: PropTypes.bool,
    onContractChange: PropTypes.func.isRequired,
    onVendorChange: PropTypes.func.isRequired,
    showFormValidation: PropTypes.bool,
};

import { OTHER } from '../constants';

export const NO_BID_REASON = 'noBidReason';

export const noBidOptions = [
    {
        label: 'The timeline to respond is too short',
        value: 'The timeline to respond is too short',
    },
    {
        label: 'We are too busy to respond',
        value: 'We are too busy to respond',
    },
    {
        label: 'We do not offer the products or services sought',
        value: 'We do not offer the products or services sought',
    },
    {
        label: 'The specifications are too confusing',
        value: 'The specifications are too confusing',
    },
    {
        label: 'The response process is too difficult/too many forms',
        value: 'The response process is too difficult/too many forms',
    },
    {
        label: 'The terms and conditions of the contract are not acceptable',
        value: 'The terms and conditions of the contract are not acceptable',
    },
    {
        label: 'We had a negative experience working with the agency in the past',
        value: 'We had a negative experience working with the agency in the past',
    },
    {
        label: 'Other',
        value: OTHER,
    },
];

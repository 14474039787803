import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';

import { form, fieldNames } from './constants';
import { getRequestToJoinAdminsJS, getSignUpOrganizationMatchJS } from './selectors';
import { signupValidate as validate } from './validate';
import { LoginTitle } from '../components/LoginTitle';
import { OrganizationLogo } from '../components/OrganizationLogo';
import { requestToJoinOrganization, vendorSignUp } from '../../../actions/auth';
import { Button, InputText, PageTitle } from '../../../components';

const { EMAIL } = fieldNames;

const mapStateToProps = (state) => {
    return {
        requestingToJoin: state.auth.get('requestingToJoin'),
        requestToJoinAdmins: getRequestToJoinAdminsJS(state),
        requestToJoinComplete: state.auth.get('requestToJoinComplete'),
        requestToJoinError: state.auth.get('requestToJoinError'),
        signingUp: state.auth.get('vendorSigningUp'),
        signUpComplete: state.auth.get('vendorSignUpComplete'),
        signUpError: state.auth.get('vendorSignUpError'),
        signUpOrganizationMatch: getSignUpOrganizationMatchJS(state),
        vendorEmail: state.auth.get('vendorSignUpEmail'),
    };
};

const mapDispatchToProps = {
    requestToJoinOrganization,
    vendorSignUp,
};

const formConfig = {
    form,
    validate,
};

// @connect
// @reduxForm
class ConnectedSignUpForm extends Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        invalid: PropTypes.bool.isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
            query: PropTypes.shape({
                email: PropTypes.string,
            }).isRequired,
        }).isRequired,
        requestingToJoin: PropTypes.bool.isRequired,
        requestToJoinAdmins: PropTypes.arrayOf(
            PropTypes.shape({
                displayName: PropTypes.string.isRequired,
            })
        ).isRequired,
        requestToJoinComplete: PropTypes.bool.isRequired,
        requestToJoinError: PropTypes.string,
        requestToJoinOrganization: PropTypes.func.isRequired,
        router: PropTypes.object.isRequired,
        signingUp: PropTypes.bool,
        signUpComplete: PropTypes.bool.isRequired,
        signUpError: PropTypes.string,
        signUpOrganizationMatch: PropTypes.shape({
            id: PropTypes.number.isRequired,
            logo: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        }),
        vendorEmail: PropTypes.string,
        vendorSignUp: PropTypes.func.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    componentDidMount() {
        const {
            location: {
                pathname,
                query: { email },
            },
            router,
        } = this.props;

        /**
         * We need to pass the `email` data to this container when activating a pending user.
         * However, we cannot call `vendorSignUp` from the server as it could lead to a route
         * change, which will throw on the server, so we wait for the client to load.
         *
         * We want to prevent users from refreshing the page or navigating back to the page,
         * which would cause the API call to be made again. To prevent this we need to remove the
         * query params from the URL before making the signup API call.
         */
        if (email) {
            router.replace(pathname);
            this.props.vendorSignUp({ [EMAIL]: email });
        }
    }

    handleSubmit = (data) => {
        this.props.vendorSignUp(data);
    };

    handleForceSignup = () => {
        const { vendorEmail } = this.props;

        this.props.vendorSignUp({
            email: vendorEmail,
            force: true,
        });
    };

    handleJoinOrganizationRequest = () => {
        const { signUpOrganizationMatch, vendorEmail } = this.props;

        this.props.requestToJoinOrganization({
            email: vendorEmail,
            organizationId: signUpOrganizationMatch.id,
        });
    };

    renderOrganizationFound() {
        const {
            requestingToJoin,
            requestToJoinError,
            signingUp,
            signUpError,
            signUpOrganizationMatch,
            signUpOrganizationMatch: { name },
        } = this.props;

        const disabled = requestingToJoin || signingUp;

        return (
            <div className="text-center">
                <OrganizationLogo organization={signUpOrganizationMatch} />
                <h1 className="visually-hidden">{name}</h1>
                <h2 className="visually-hidden">Sign Up</h2>
                <LoginTitle className="text-primary">
                    People at {name} are already using OpenGov Procurement!
                </LoginTitle>
                <p>
                    Request to join the <strong>{name}</strong> organization and start collaborating
                    with the rest of your team.
                </p>
                <p>Or you can create your own organization and invite your own coworkers.</p>
                <p>
                    <Button
                        block
                        bsStyle="primary"
                        disabled={disabled}
                        onClick={this.handleJoinOrganizationRequest}
                        qaTag="signUp-requestToJoin"
                    >
                        <i
                            className={`fa fa-fw fa-${
                                requestingToJoin ? 'spin fa-spinner' : 'user-plus'
                            }`}
                        />{' '}
                        Request to Join Organization
                    </Button>
                    <Button
                        block
                        disabled={disabled}
                        onClick={this.handleForceSignup}
                        qaTag="signUp-createNewOrg"
                    >
                        <i className={`fa fa-fw fa-${signingUp ? 'spin fa-spinner' : 'plus'}`} />{' '}
                        Create New Organization
                    </Button>
                    {(requestToJoinError || signUpError) && (
                        <span className="text-danger">{requestToJoinError || signUpError}</span>
                    )}
                </p>
                <p>
                    If you have any questions please email us at
                    <br />
                    <a href="mailto:procurement-support@opengov.com">
                        procurement-support@opengov.com
                    </a>
                </p>
            </div>
        );
    }

    renderJoinRequestSent() {
        const {
            requestToJoinAdmins,
            signUpOrganizationMatch,
            signUpOrganizationMatch: { name },
        } = this.props;

        return (
            <div className="text-center">
                <OrganizationLogo organization={signUpOrganizationMatch} />
                <LoginTitle className="text-primary">
                    <i className="fa fa-envelope" /> Request Sent!
                </LoginTitle>
                <p>
                    We sent your request to join <strong>{name}</strong> to the following OpenGov
                    Procurement admin{requestToJoinAdmins.length > 1 ? 's' : ''}:
                    {requestToJoinAdmins.map((admin, idx) => (
                        <React.Fragment key={idx}>
                            <br />
                            <i className="fa fa-star" /> {admin.displayName}
                        </React.Fragment>
                    ))}
                </p>
                <p>Once approved by an admin, we will send you an account activation email.</p>
                <p>
                    If you have any questions please email us at
                    <br />
                    <a href="mailto:procurement-support@opengov.com">
                        procurement-support@opengov.com
                    </a>
                </p>
            </div>
        );
    }

    renderSignUpSuccess() {
        const { vendorEmail } = this.props;

        return (
            <div className="text-center">
                <i className="text-success fa fa-check-square-o fa-3x" />
                <LoginTitle className="text-success">You have successfully signed up!</LoginTitle>
                <p>
                    We sent an activation email to
                    <br />
                    <strong>{vendorEmail}</strong>
                </p>
                <p>Please check your inbox and follow the instructions to activate your account</p>
                <p>
                    If you need help activating your account please email us at
                    <br />
                    <a href="mailto:procurement-support@opengov.com">
                        procurement-support@opengov.com
                    </a>
                </p>
            </div>
        );
    }

    renderSignUpForm() {
        const { handleSubmit, invalid, signingUp, signUpError } = this.props;

        const buttonText = signingUp ? (
            <span>
                <i className="fa fa-spinner fa-spin" /> Signing Up...
            </span>
        ) : (
            'Sign Up'
        );

        return (
            <form onSubmit={handleSubmit(this.handleSubmit)}>
                <PageTitle title="Sign Up" />
                <h1 className="visually-hidden">Sign Up</h1>
                <h2 className={`text-center ${this.styles.title}`}>Sign up with just an Email!</h2>
                <p className={`text-muted ${this.styles.subTitle}`}>
                    <em>We&#39;ll send you an email to activate your account</em>
                </p>
                <Field
                    aria-label="Enter Email Address"
                    component={InputText}
                    disabled={signingUp}
                    label="Email Address"
                    name={EMAIL}
                    placeholder="Enter your email address"
                    qaTag="signUp-email"
                    type="email"
                />
                <Button
                    block
                    bsStyle="primary"
                    disabled={signingUp || invalid}
                    qaTag="signUp-submit"
                    type="submit"
                >
                    {buttonText}
                </Button>
                {signUpError && <div className="error-block text-center">{signUpError}</div>}
                <div className={this.styles.loginLinkContainer}>
                    <Link className="pseudoLink" to="/login">
                        Already have an account?
                    </Link>
                </div>
            </form>
        );
    }

    render() {
        const { requestToJoinComplete, signUpComplete, signUpOrganizationMatch } = this.props;

        if (signUpComplete) {
            return this.renderSignUpSuccess();
        }
        if (requestToJoinComplete) {
            return this.renderJoinRequestSent();
        }
        if (signUpOrganizationMatch) {
            return this.renderOrganizationFound();
        }
        return this.renderSignUpForm();
    }
}

export const SignUpForm = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedSignUpForm);

import React from 'react';
import PropTypes from 'prop-types';

import { CDSButton } from '../../../../../../../../components';

export const TemplateEditV2DrawerContentAddSectionButton = ({ onClick }) => {
    const styles = require('../index.scss');

    return (
        <div className={styles.addSection}>
            <CDSButton
                noPadding
                onClick={onClick}
                qaTag="manageSections-addSection"
                size="small"
                variant="text"
            >
                <i className="fa fa-plus" /> Add Section
            </CDSButton>
        </div>
    );
};

TemplateEditV2DrawerContentAddSectionButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};

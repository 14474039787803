import React from 'react';
import PropTypes from 'prop-types';
import { ListGroupItem } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { currencyFormatter, dateTZFormatter } from '../../../../../helpers';
import { getUserOrganizationTimezone } from '../../../../selectors';

export const VendorTotalListItem = ({
    auctionMaxFractionDigits,
    vendor: { companyName, grandTotal, mostRecentBidDate, numBids },
    rank,
}) => {
    const styles = require('../index.scss');

    const timezone = useSelector((state) => getUserOrganizationTimezone(state));

    return (
        <ListGroupItem className={styles.summaryItem}>
            <div className="row">
                <div className="col-xs-4 col-sm-3">
                    <span className={styles.vendorRank}>{rank}</span>
                    <span className={styles.companyNameText}>{companyName}</span>
                </div>
                <div className="col-xs-4 col-sm-7">
                    <div className={styles.priceText}>
                        {currencyFormatter(
                            { value: grandTotal },
                            {
                                maximumFractionDigits: auctionMaxFractionDigits,
                                useSameMinAndMax: true,
                            }
                        )}
                    </div>
                    <div className={styles.priceLabel}>Grand Total</div>
                </div>
                <div className={`col-xs-4 col-sm-2 ${styles.vendorTotalDetails}`}>
                    <div>
                        {dateTZFormatter(
                            timezone,
                            'h:mm:ss a on MM/DD/YYYY'
                        )({ value: mostRecentBidDate })}
                    </div>
                    <div>
                        Number of Bids: <span className={styles.numBids}>{numBids}</span>
                    </div>
                </div>
            </div>
        </ListGroupItem>
    );
};

VendorTotalListItem.propTypes = {
    auctionMaxFractionDigits: PropTypes.number.isRequired,
    rank: PropTypes.number.isRequired,
    vendor: PropTypes.shape({
        companyName: PropTypes.string.isRequired,
        grandTotal: PropTypes.number.isRequired,
        mostRecentBidDate: PropTypes.string.isRequired,
        numBids: PropTypes.number.isRequired,
    }).isRequired,
};

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormGroup, ControlLabel, FormControl, HelpBlock } from 'react-bootstrap';
import { v4 as UUIDv4 } from 'uuid';

/**
 * Note: This component is only configured for <input> and <textarea> tags.
 * Needs to be extended before <select> tag can be used.
 */
export class InputBasic extends PureComponent {
    static propTypes = {
        help: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        type: PropTypes.string.isRequired,
        id: PropTypes.string,
    };

    render() {
        const { id, label, help, ...props } = this.props;

        // 'input' is used as the default value for component class
        if (props.type === 'select' || props.type === 'textarea') {
            props.componentClass = props.type;
            delete props.type;
        }

        return (
            <FormGroup controlId={id || `form-group-${UUIDv4()}`}>
                {label && <ControlLabel>{label}</ControlLabel>}
                <FormControl {...props} />
                {help && <HelpBlock>{help}</HelpBlock>}
            </FormGroup>
        );
    }
}

import classNames from 'classnames';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { DepartmentCreateForm } from './DepartmentCreateForm';
import { DepartmentListItem } from './DepartmentListItem';
import { DepartmentSearchForm } from './DepartmentSearchForm';
import { getDepartmentsJS, getInvitedUsersSelectOptions, getUserJS } from '../../selectors';
import { Button, Main, SectionTitle, Well } from '../../../components';
import { exportArrayToCSV } from '../../../utils';

const mapStateToProps = (state) => {
    return {
        departmentsList: getDepartmentsJS(state),
        user: getUserJS(state),
        users: getInvitedUsersSelectOptions(state),
    };
};

// @connect
class ConnectedDepartmentList extends Component {
    static propTypes = {
        departmentsList: PropTypes.arrayOf(
            PropTypes.shape({
                editing: PropTypes.bool,
                id: PropTypes.number.isRequired,
            })
        ).isRequired,
        user: PropTypes.shape({
            isVendor: PropTypes.bool.isRequired,
            organization: PropTypes.shape({
                id: PropTypes.number.isRequired,
            }).isRequired,
        }).isRequired,
        users: PropTypes.array.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            filteredDepartments: props.departmentsList,
            search: '',
            showForm: false,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    componentDidUpdate(prevProps) {
        if (prevProps.departmentsList !== this.props.departmentsList) {
            this.filterDepartments({ search: this.state.search });
        }
    }

    filterDepartments = (data) => {
        const { search } = data;
        const { departmentsList } = this.props;

        const filteredDepartments = departmentsList.filter((department) => {
            if (!search || !search.trim()) {
                return true;
            }
            return department.name?.toLowerCase().includes(search.toLowerCase());
        });

        this.setState({ filteredDepartments, search });
    };

    handleExportCSVClick = () => {
        const { filteredDepartments } = this.state;

        const headers = ['Department Name', 'Department Head', 'Department Head Title'];

        const rows = filteredDepartments.map((department) => {
            return [department.name, department.departmentHead, department.departmentHeadTitle];
        });

        exportArrayToCSV([headers].concat(rows), { fileName: 'Departments', headers: true });
    };

    toggleFormHandler = () => this.setState((prevState) => ({ showForm: !prevState.showForm }));

    renderDepartments() {
        const { user, users } = this.props;
        const { filteredDepartments } = this.state;

        return filteredDepartments.map((department) => {
            return (
                <DepartmentListItem
                    department={department}
                    key={department.id}
                    organizationId={user.organization.id}
                    users={users}
                />
            );
        });
    }

    render() {
        const { user, users } = this.props;

        const { showForm } = this.state;

        if (user.isVendor) {
            return null;
        }

        return (
            <Main>
                <SectionTitle
                    info="Manage the departments for your organization"
                    title="Departments"
                />
                {showForm && (
                    <Well>
                        <DepartmentCreateForm users={users} />
                    </Well>
                )}
                <div className="row">
                    <div className="col-md-4">
                        <DepartmentSearchForm onChange={this.filterDepartments} />
                    </div>
                    <div className={classNames('col-md-8', this.styles.exportButton)}>
                        <Button
                            bsSize="sm"
                            onClick={this.handleExportCSVClick}
                            qaTag="connectedDepartmentList-exportToCsv"
                        >
                            <i className="fa fa-download" /> Export to CSV
                        </Button>
                        {!showForm && (
                            <Button
                                bsStyle="success"
                                onClick={this.toggleFormHandler}
                                qaTag="connectedDepartmentList-addDepartment"
                            >
                                <i className="fa fa-plus" /> Add Department
                            </Button>
                        )}
                    </div>
                </div>
                <div className={`row ${this.styles.table}`}>
                    <div className={`col-md-3 ${this.styles.deptHead}`}>Department Name</div>
                    <div className={`col-md-2 ${this.styles.deptHead}`}>Department Head</div>
                    <div className={`col-md-2 ${this.styles.deptHead}`}>Department Head Title</div>
                    <div className={`col-md-2 ${this.styles.deptHead}`}>Procurement Contact</div>
                    <div className={`col-md-3 ${this.styles.deptHead}`}>Actions</div>
                    <div className="col-xs-12">{this.renderDepartments()}</div>
                </div>
            </Main>
        );
    }
}

export const DepartmentList = connect(mapStateToProps)(ConnectedDepartmentList);

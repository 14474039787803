import { fieldNames } from './constants';

const { VERSION_1, VERSION_2 } = fieldNames;

export const validate = (values) => {
    const errors = {};

    if (!values[VERSION_1]) {
        errors[VERSION_1] = 'Please select a version';
    }

    if (!values[VERSION_2]) {
        errors[VERSION_2] = 'Please select a version';
    }

    if (values[VERSION_1] && values[VERSION_1] === values[VERSION_2]) {
        errors[VERSION_2] = 'Same versions selected. Please compare against a different version.';
    }

    return errors;
};

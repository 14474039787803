import { createSelector } from 'reselect';

import { getMappedProposalJS } from '../selectors';
import { getUserJS } from '../../selectors';

export const isUploading = createSelector([getMappedProposalJS], (proposal) => {
    if (proposal) {
        return proposal.proposalDocuments.some((doc) => {
            return doc.uploads.some((upload) => upload.uploading);
        });
    }
    return false;
});

export const getVendor = createSelector([getUserJS], (user) => {
    return {
        ...user.vendor,
        organization: user.organization,
    };
});

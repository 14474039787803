import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Modal } from 'react-bootstrap';
import { Box } from '@og-pro/ui';

import { Button } from '../../../../components';

export const AiConfirmationModal = ({ hideModal, onAccept, show }) => {
    const [confirmed, setConfirmed] = useState(false);
    const clearConfirmed = () => setConfirmed(false);

    if (!show) return null;

    return (
        <Modal
            onHide={() => {
                clearConfirmed();
                hideModal();
            }}
            show
        >
            <Modal.Header closeButton>
                <Modal.Title className="text-center">
                    Disclaimer for AI-Assisted Functionality
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    The suggestions provided by using the AI integration (&quot;Integration&quot;)
                    are machine-generated and may be inaccurate and/or contain errors. We strongly
                    suggest reviewing before implementing such content.
                </p>
                <p>
                    OpenGov makes no representations or warranties of any kind, express or implied,
                    about the completeness, accuracy, reliability, suitability, or availability of
                    the Integration and its output.
                </p>
                <p>
                    Notwithstanding anything to the contrary agreed between the parties, in no event
                    will OpenGov be liable for any loss or damage including without limitation,
                    indirect or consequential loss or damage, or any loss or damage whatsoever
                    arising from loss of data or profits arising out of, or in connection with, the
                    use of the Integration.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Box textAlign="left">
                    <Checkbox checked={confirmed} onClick={() => setConfirmed(!confirmed)}>
                        I hereby represent and warrant to OpenGov that I have full authority to bind
                        the organization I am employed at under these terms.
                    </Checkbox>
                    <Box mt={4}>
                        <Button
                            onClick={() => {
                                clearConfirmed();
                                hideModal();
                            }}
                            qaTag="aiConfirmationModal-cancel"
                        >
                            Cancel
                        </Button>
                        <Button
                            bsStyle="primary"
                            disabled={!confirmed}
                            onClick={() => {
                                onAccept();
                                clearConfirmed();
                            }}
                        >
                            Accept
                        </Button>
                    </Box>
                </Box>
            </Modal.Footer>
        </Modal>
    );
};

AiConfirmationModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    onAccept: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
};

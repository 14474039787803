import { fieldNames } from '../constants';
import {
    arrayError,
    questionnaire as questionnaireValidator,
} from '../../../../../Forms/validation';

const { QUESTIONNAIRES } = fieldNames;

export function validate(values) {
    const errors = {};

    // At least one questionnaire must be included
    if (!values[QUESTIONNAIRES] || values[QUESTIONNAIRES].length === 0) {
        errors[QUESTIONNAIRES] = arrayError('Please include at least one question');
        return errors;
    }

    errors[QUESTIONNAIRES] = values[QUESTIONNAIRES].map((question) =>
        questionnaireValidator(question)
    );

    return errors;
}

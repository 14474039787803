import PropTypes from 'prop-types';
import React from 'react';

import { ReportDocxTable } from '../../../../../../components';
import { getLineItemSummaryRowData, lineItemSummaryColumnDefs } from '../helpers';
import { PriceItemDescriptionHeader } from '.';

export const SummaryByLineItemList = ({ reverseAuctionItems }) => {
    return reverseAuctionItems.map((itemTable) =>
        itemTable.priceItems.map((priceItem) => (
            <div key={priceItem.id}>
                <PriceItemDescriptionHeader priceItem={priceItem} priceTable={itemTable} />
                <ReportDocxTable
                    columns={lineItemSummaryColumnDefs}
                    data={getLineItemSummaryRowData(priceItem, itemTable)}
                    useLandscape={false}
                />
            </div>
        ))
    );
};

SummaryByLineItemList.propTypes = {
    reverseAuctionItems: PropTypes.array.isRequired,
};

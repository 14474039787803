import { Typography } from '@og-pro/ui';
import React from 'react';
import PropTypes from 'prop-types';

import { labelStyle } from './styles';

export const VendorUserDisplay = ({ loading, proUser, vendorEmail }) => {
    return (
        <>
            <Typography {...labelStyle}>Vendor Contact</Typography>
            {proUser ? (
                <>
                    <Typography>{proUser.fullName}</Typography>
                    <Typography>{proUser.email}</Typography>
                </>
            ) : (
                <Typography>{loading ? 'Loading...' : vendorEmail}</Typography>
            )}
        </>
    );
};

VendorUserDisplay.propTypes = {
    loading: PropTypes.bool,
    proUser: PropTypes.shape({
        email: PropTypes.string.isRequired,
        fullName: PropTypes.string.isRequired,
    }),
    vendorEmail: PropTypes.string,
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';

import { InputText, Button } from '../../../..';
import { form, fieldNames } from './constants';

const formConfig = {
    form,
};

// @reduxForm
class ConnectedApproveForm extends PureComponent {
    static propTypes = {
        submitting: PropTypes.bool.isRequired,
        submitError: PropTypes.string,
        handleSubmit: PropTypes.func.isRequired,
        hideModal: PropTypes.func.isRequired,
    };

    renderSubmitError() {
        const { submitError } = this.props;
        if (!submitError) return null;

        return <div className="text-danger">{submitError}</div>;
    }

    render() {
        const { submitting, handleSubmit, hideModal } = this.props;
        const styles = require('../ApprovalsModal.scss');

        return (
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Add Note to Your Project Approval (optional):</label>
                    <Field
                        component={InputText}
                        disabled={submitting}
                        name={fieldNames.COMMENT}
                        placeholder="Enter optional note"
                        qaTag="approvalsDashboard-approveProjectNote"
                        type="textarea"
                    />
                </div>
                {this.renderSubmitError()}
                <div className={`text-center ${styles.buttons}`}>
                    <Button
                        bsSize="large"
                        bsStyle="success"
                        className={styles.actionButton}
                        disabled={submitting}
                        qaTag="approvalsDashboard-approveProject"
                        type="submit"
                    >
                        <i className="fa fa-check-square-o" /> Approve Project
                    </Button>
                    <Button onClick={hideModal} qaTag="approvalsDashboard-closeApproveModal">
                        Cancel
                    </Button>
                </div>
            </form>
        );
    }
}

export const ApproveForm = reduxForm(formConfig)(ConnectedApproveForm);

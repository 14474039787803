import { get } from 'lodash';
import { createSelector } from 'reselect';

import { projectToolbarTypes } from './constants';
import {
    getProjectJS,
    getReviewPath,
    getContractModulePath,
    getPostingPath,
    getEvaluationPath,
    getIntakePath,
} from '../selectors';

const { BUILDER, CONTRACT, INTAKE, SOURCING, EVALUATION } = projectToolbarTypes;

const getPathname = (_state, props) => props.location.pathname;
const getProjectLoading = (state) => state.govProjects.get('loadingSelected');

export const getProjectToolbar = createSelector(
    [
        getPathname,
        getContractModulePath,
        getReviewPath,
        getPostingPath,
        getEvaluationPath,
        getIntakePath,
    ],
    (pathname, contractModulePath, reviewPath, postingPath, evalPath, intakePath) => {
        if (pathname.match(new RegExp(`^${reviewPath}`))) {
            return BUILDER;
        }
        if (pathname.match(new RegExp(`^${contractModulePath}`))) return CONTRACT;
        if (pathname.match(new RegExp(`^${intakePath}`))) return INTAKE;
        if (pathname.match(new RegExp(`^${postingPath}`))) return SOURCING;
        if (pathname.match(new RegExp(`^${evalPath}`))) return EVALUATION;
    }
);

export const shouldShowToolbar = createSelector(
    [getProjectLoading, getProjectJS, getProjectToolbar],
    (projectLoading, project, toolbar) => {
        return !projectLoading && !!project && !project.isLibrary && !!toolbar;
    }
);

export const isBuilderUsed = createSelector([getProjectJS], (project) => {
    return !!get(project, 'moduleData.builder.isUsed');
});

const loadingProjectExport = (state) => state.exportProject.get('loading');
const loadingProjectDocumentExport = (state) => state.exportProject.get('loadingDocument');

export const isLoadingExport = createSelector(
    [loadingProjectExport, loadingProjectDocumentExport],
    (loading, loadingDocument) => loading || loadingDocument
);

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { questionStatusTypes } from '@og-pro/shared-config/questions';

import { QuestionLogo } from './QuestionLogo';
import { Button, HelpIcon } from '../../..';
import { commentDateFormatter } from '../../../../utils';

const { DRAFT, EXCLUDED } = questionStatusTypes;

export class QuestionComment extends PureComponent {
    static propTypes = {
        attachments: PropTypes.array,
        canEdit: PropTypes.bool,
        canVendorEdit: PropTypes.bool,
        canRelease: PropTypes.bool,
        date: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        editHandler: PropTypes.func.isRequired,
        image: PropTypes.string,
        isAnswer: PropTypes.bool,
        isVendor: PropTypes.bool.isRequired,
        isGovUser: PropTypes.bool,
        name: PropTypes.string,
        organization: PropTypes.string,
        showReleaseResponsesModal: PropTypes.func,
        status: PropTypes.string.isRequired,
        timezone: PropTypes.string.isRequired,
    };

    static defaultProps = {
        name: 'Anonymous',
        organization: 'User information is private',
    };

    get styles() {
        return require('./shared.scss');
    }

    renderUserName() {
        const { date, name, isVendor, isGovUser, timezone } = this.props;

        return (
            <div>
                {(isGovUser || isVendor) && <strong>{name} </strong>}
                <em className="text-muted">{commentDateFormatter(date, timezone)}</em>
            </div>
        );
    }

    renderVerifiedBadge() {
        return (
            <span className={`fa-stack ${this.styles.verified}`}>
                <i className="fa fa-stack-2x fa-certificate text-primary" />
                <i className="fa fa-stack-1x fa-inverse fa-check" />
            </span>
        );
    }

    renderUserInfo() {
        const { isAnswer, isGovUser, isVendor, name } = this.props;

        return (
            <>
                <div>{this.renderUserName()}</div>
                <div>
                    <em>{this.props.organization}</em>
                    {!isAnswer && isVendor && !isGovUser && name !== 'Anonymous' && (
                        <em>
                            <br />
                            Your information is only visible to you
                            <HelpIcon
                                icon="fa-info-circle"
                                tooltip="Your information is only visible to you and the issuing organization for questions that you asked. For all other viewers (including other vendors) your information will be anonymized."
                            />
                        </em>
                    )}
                    {isAnswer && !isVendor && this.renderVerifiedBadge()}
                </div>
            </>
        );
    }

    renderActions() {
        const {
            canEdit,
            canVendorEdit,
            canRelease,
            editHandler,
            isAnswer,
            showReleaseResponsesModal,
            status,
        } = this.props;

        if (status === EXCLUDED) {
            return null;
        }

        const awaitingReview = isAnswer && status === DRAFT;

        const editButton = (canEdit || canVendorEdit) && (
            <Button
                bsSize="sm"
                bsStyle="link"
                className={classnames(!awaitingReview && !canVendorEdit && this.styles.editButton)}
                onClick={editHandler}
                qaTag="questionComment-edit"
            >
                <i className="fa fa-pencil" /> Edit
            </Button>
        );

        const releaseButton = awaitingReview && (
            <Button
                bsSize="sm"
                bsStyle="primary"
                disabled={!canRelease}
                onClick={showReleaseResponsesModal}
                qaTag="questionComment-release"
                tooltip={
                    canRelease
                        ? undefined
                        : 'Only sourcing editors and admins can release questions'
                }
            >
                <i className="fa fa-send" /> Release
            </Button>
        );

        if (!editButton && !releaseButton) {
            return null;
        }

        return (
            <div>
                <div className="pull-right">
                    {editButton} {releaseButton}
                </div>
                {awaitingReview && (
                    <div className="text-danger">
                        <i className="fa fa-exclamation-triangle" /> Awaiting Review
                    </div>
                )}
            </div>
        );
    }

    renderAttachments() {
        const { attachments } = this.props;

        return (
            <div className={this.styles.attachmentsList}>
                <div>
                    <u>Attachments</u>
                </div>
                {attachments.map((attachment) => {
                    return (
                        <div key={attachment.id}>
                            <a href={attachment.url}>{attachment.filename}</a>
                        </div>
                    );
                })}
            </div>
        );
    }

    render() {
        const { attachments, description, image, isAnswer, organization } = this.props;

        return (
            <div className="row">
                <QuestionLogo entityName={organization} imageSrc={image} />
                <div className={`col-xs-12 ${this.styles.commentContainer}`}>
                    <div className={this.styles.userInfo}>
                        {this.renderActions()}
                        {this.renderUserInfo()}
                    </div>
                    <article>{description}</article>
                    {attachments.length > 0 && !isAnswer && this.renderAttachments()}
                </div>
            </div>
        );
    }
}

import { createSelector } from 'reselect';

import { ALL, DRAFT, LIVE } from './constants';
import { getTemplateQuestions } from '../../../../selectors/govApp';

export const getLoading = (state) => state.templateQuestions.get('loadingList');
export const getLoadError = (state) => state.templateQuestions.get('loadListError');

const getLocation = (state, props) => props.location;

const getDraftTemplates = createSelector([getTemplateQuestions], (templateQuestions) => {
    return templateQuestions.filter((templateQuestion) => !templateQuestion.isPublished);
});

const getLiveTemplates = createSelector([getTemplateQuestions], (templateQuestions) => {
    return templateQuestions.filter((templateQuestion) => templateQuestion.isPublished);
});

export const getNavItems = createSelector(
    [getTemplateQuestions, getDraftTemplates, getLiveTemplates],
    (allTemplates, draftTemplates, liveTemplates) => {
        return [
            {
                name: 'All',
                status: ALL,
                counter: allTemplates.length,
            },
            {
                name: 'Live',
                status: LIVE,
                counter: liveTemplates.length,
            },
            {
                name: 'Draft',
                status: DRAFT,
                counter: draftTemplates.length,
            },
        ];
    }
);

export const getSelectedNavItem = createSelector(
    [getNavItems, getLocation],
    (navItems, location) => {
        const {
            query: { status },
        } = location;

        return navItems.find((item) => item.status === status) || navItems[0];
    }
);

export const getSelectedTemplateQuestions = createSelector(
    [getSelectedNavItem, getTemplateQuestions, getDraftTemplates, getLiveTemplates],
    (selectedNavItem, allTemplates, draftTemplates, liveTemplates) => {
        switch (selectedNavItem.status) {
            case ALL:
                return allTemplates;
            case LIVE:
                return liveTemplates;
            case DRAFT:
                return draftTemplates;
            default:
                return [];
        }
    }
);

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getCurrentVendorStats } from '../selectors';
import { CountdownTimerDigital } from '../../../../components';
import { currencyFormatter } from '../../../../helpers';

export const SummaryInfoHeader = ({
    project: { auctionEndDate, auctionExtensionCount, auctionMaxFractionDigits },
}) => {
    const styles = require('./index.scss');
    const { currentTotal, numOfBids, numOfLineItemsTotal, numOfLineItemsWinning } =
        useSelector(getCurrentVendorStats);

    function renderBidSummary() {
        return (
            <div className="row">
                <div className="col-xs-12">
                    <h3 className={styles.summaryHeader}>Bid Summary</h3>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-5">
                    <div>
                        <h5>Current Total</h5>
                    </div>
                    <div className={styles.summaryValue}>
                        {currencyFormatter(
                            { value: currentTotal },
                            {
                                maximumFractionDigits: auctionMaxFractionDigits,
                                useSameMinAndMax: true,
                            }
                        )}
                    </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-5">
                    <h5>Leading In</h5>
                    <div className={styles.summaryValue}>
                        {numOfLineItemsWinning} of {numOfLineItemsTotal} Line Items
                    </div>
                </div>
                <div className="col-sm-12 col-md-2">
                    <h5># of Bids</h5>
                    <div className={styles.summaryValue}>{numOfBids}</div>
                </div>
            </div>
        );
    }
    return (
        <div className="row">
            <div className="col-xs-12 col-sm-7 hidden-xs">{renderBidSummary()}</div>
            <div className="col-xs-12 col-sm-5">
                <div className={`${styles.countdownTimer}`}>
                    <CountdownTimerDigital
                        endTime={auctionEndDate}
                        label={`${auctionExtensionCount} Clock Extensions`}
                        numberOfBidsTotal={numOfBids}
                    />
                </div>
            </div>
            <div
                className={`${styles.mobileLabel} col-xs-12 col-sm-7 hidden-md hidden-sm hidden-lg hidden-xl`}
            >
                {renderBidSummary()}
            </div>
        </div>
    );
};

SummaryInfoHeader.propTypes = {
    project: PropTypes.shape({
        auctionEndDate: PropTypes.string.isRequired,
        auctionExtensionCount: PropTypes.number.isRequired,
        auctionMaxFractionDigits: PropTypes.number.isRequired,
    }),
};

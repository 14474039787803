import React from 'react';
import PropTypes from 'prop-types';

import { SectionType } from '../constants';
import { getNextSection, getBackSection, getNavigableSectionsIndexes } from '../../helpers';
import { BaseSectionsNavBackButton } from './Back';
import { BaseSectionsNavNextButton } from './Next';

export const BaseSectionsNavBackNextButtons = ({
    firstSectionComponent,
    lastSectionComponent,
    sections,
}) => {
    if (!sections.length && !firstSectionComponent && !lastSectionComponent) {
        return null;
    }

    const styles = require('./index.scss');

    const navigableIndexes = getNavigableSectionsIndexes(sections);
    const prevSection = getBackSection(sections, navigableIndexes);
    const nextSection = getNextSection(sections, navigableIndexes);

    const handleClick = (section) => {
        if (section.onClick) {
            section.onClick();
        }
    };
    const getLabel = (section) => {
        if (section.manualNumber) {
            return section.manualNumber;
        }

        return (
            (section.numbering && !section.disableNumbering ? `${section.numbering} ` : '') +
            section.title
        );
    };

    return (
        <div className={styles.container}>
            <div className={styles.back}>
                {prevSection && (
                    <BaseSectionsNavBackButton onClick={() => handleClick(prevSection)}>
                        {getLabel(prevSection)}
                    </BaseSectionsNavBackButton>
                )}
                {!prevSection && !!firstSectionComponent && firstSectionComponent}
            </div>
            <div className={styles.next}>
                {nextSection && (
                    <BaseSectionsNavNextButton onClick={() => handleClick(nextSection)}>
                        {getLabel(nextSection)}
                    </BaseSectionsNavNextButton>
                )}
                {!nextSection && !!lastSectionComponent && lastSectionComponent}
            </div>
        </div>
    );
};

BaseSectionsNavBackNextButtons.propTypes = {
    firstSectionComponent: PropTypes.node,
    lastSectionComponent: PropTypes.node,
    sections: PropTypes.arrayOf(SectionType),
};

const _ = require('lodash');

const { projectStatusesDict } = require('../projects');

const { AUCTION_PENDING, OPEN, REVERSE_AUCTION } = projectStatusesDict;

exports.isNextStatusAuctionPending = (project) => {
    return project.status === OPEN && !!_.get(project, 'template.isReverseAuction');
};

exports.isNextStatusReverseAuction = (project) => {
    return project.status === AUCTION_PENDING;
};

exports.isAuctionLive = (project) => {
    const now = new Date();
    return (
        project.status === REVERSE_AUCTION &&
        now >= new Date(project.auctionStartDate) &&
        now <= new Date(project.auctionEndDate)
    );
};

/**
 * Returns a number rounded to a precise number of decimal places.
 *
 * @param {number} number The number to be rounded
 * @param {number} auctionMaxFractionDigits The maximum number of decimal places
 * @return {number} The rounded number
 */
exports.getPreciseRoundFloat = (number, auctionMaxFractionDigits) => {
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(number) && !isNaN(auctionMaxFractionDigits)) {
        return Number(
            `${Math.round(`${number}e${auctionMaxFractionDigits}`)}e-${auctionMaxFractionDigits}`
        );
    }
    return number;
};

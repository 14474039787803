import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';

import { form, emailFrequencyOptions, fieldNames } from './constants';
import { personalValidate as validate } from './validate';
import * as personalAccountActions from '../../../actions/account/personal';
import { InputText, Main, SearchSelect } from '../../../components';

const mapStateToProps = (state) => {
    return {
        saving: state.accountPersonal.get('saving'),
    };
};

const mapDispatchToProps = personalAccountActions;

const formConfig = {
    form,
    validate,
};

// @connect
// @reduxForm
export class ConnectedPersonalEmail extends Component {
    static propTypes = {
        autoFocus: PropTypes.bool,
        handleSubmit: PropTypes.func.isRequired,
        invalid: PropTypes.bool.isRequired,
        parentSubmit: PropTypes.bool,
        save: PropTypes.func.isRequired,
        saving: PropTypes.bool,
        isVendor: PropTypes.bool.isRequired,
    };

    handleSubmit = (data) => {
        if (!this.props.parentSubmit) {
            this.props.save(data);
        }
    };

    renderSaveButton() {
        const { invalid, saving, parentSubmit } = this.props;

        if (!parentSubmit) {
            return (
                <Button
                    bsSize="large"
                    bsStyle="primary"
                    className="center-block"
                    disabled={invalid || saving}
                    type="submit"
                >
                    {saving ? 'Saving...' : 'Save'}
                </Button>
            );
        }
    }

    render() {
        const { autoFocus, handleSubmit, saving, isVendor } = this.props;

        return (
            <form onSubmit={handleSubmit(this.handleSubmit)}>
                <Main className="col-xs-12">
                    <Field
                        autoFocus={autoFocus}
                        component={InputText}
                        disabled={saving}
                        label="Email"
                        name={fieldNames.EMAIL}
                        type="text"
                    />
                    {!isVendor && (
                        <Field
                            backspaceRemovesValue={false}
                            className={this.props.isVendor}
                            component={SearchSelect}
                            disabled={saving}
                            label="Email Notification Frequency"
                            name={fieldNames.EMAIL_FREQUENCY}
                            options={emailFrequencyOptions}
                            placeholder="Select Frequency..."
                        />
                    )}
                    {!isVendor && (
                        <div className="text-center">
                            <Button
                                bsStyle="link"
                                href="https://opengov.my.site.com/support/s/article/35cf6783-1e51-45b3-adbc-a50d84e95224"
                                target="_blank"
                                zeroPadding
                            >
                                View List of Email Frequency Definitions and Exceptions
                            </Button>
                        </div>
                    )}
                    {!isVendor && <br />}
                    {this.renderSaveButton()}
                </Main>
            </form>
        );
    }
}

export const PersonalEmail = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedPersonalEmail);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import * as categoryActions from '../../actions/category';
import { CategorySelect } from './CategorySelect';

const mapDispatchToProps = categoryActions;

// @connect
class ConnectedCategorySelectModal extends Component {
    static propTypes = {
        categories: PropTypes.array,
        defaultCategorySet: PropTypes.number,
        disallowCustomCategories: PropTypes.bool,
        hideModal: PropTypes.func.isRequired,
        isRequired: PropTypes.bool,
        selectHandler: PropTypes.func.isRequired,
        showModal: PropTypes.bool.isRequired,
        showMoreCodeSetsHelp: PropTypes.bool,
        useSingleCodeSet: PropTypes.bool,
    };

    get styles() {
        return require('./shared.scss');
    }

    hideModal = () => {
        return this.props.hideModal();
    };

    render() {
        const {
            categories,
            defaultCategorySet,
            disallowCustomCategories,
            isRequired,
            selectHandler,
            showModal,
            showMoreCodeSetsHelp,
            useSingleCodeSet,
        } = this.props;

        return (
            <Modal onHide={this.hideModal} show={showModal}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">Select Categories</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CategorySelect
                        categories={categories}
                        clickHandler={selectHandler}
                        defaultCategorySet={defaultCategorySet}
                        disallowCustomCategories={disallowCustomCategories}
                        isRequired={isRequired}
                        showMoreCodeSetsHelp={showMoreCodeSetsHelp}
                        useSingleCodeSet={useSingleCodeSet}
                    />
                </Modal.Body>
            </Modal>
        );
    }
}

export const CategorySelectModal = connect(null, mapDispatchToProps)(ConnectedCategorySelectModal);

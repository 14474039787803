import PropTypes from 'prop-types';
import React from 'react';

import { AnalyticsNav } from './AnalyticsNav';
import { DashboardNav } from './DashboardNav';
import { HelpNav } from './HelpNav';
import { NotificationsNav } from './NotificationsNav';
import { ProjectNavItems } from './ProjectNavItems';
import { UserNav } from './UserNav';
import { NavbarButton } from '../NavbarButton';
import { ProjectCreateButton } from '../../GovApp';
import { Nav } from '../..';

export const GovNavbar = ({
    location: { pathname },
    logout,
    user,
    user: { government_id: governmentId },
}) => {
    const styles = require('./index.scss');
    return (
        <div>
            <Nav>
                <ProjectNavItems governmentId={governmentId} pathname={pathname} user={user} />
                <NavbarButton>
                    <ProjectCreateButton
                        className={`btn-primary navbar-btn ${styles.navButton}`}
                        qaTag="navbar-createNewProject"
                        text="New"
                    />
                </NavbarButton>
            </Nav>
            <Nav pullRight>
                <DashboardNav governmentId={governmentId} pathname={pathname} user={user} />
                <AnalyticsNav governmentId={governmentId} pathname={pathname} user={user} />
                <NotificationsNav governmentId={governmentId} />
                <UserNav
                    governmentId={governmentId}
                    logout={logout}
                    pathname={pathname}
                    user={user}
                />
                <HelpNav />
            </Nav>
        </div>
    );
};

GovNavbar.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    logout: PropTypes.func.isRequired,
    user: PropTypes.shape({
        government_id: PropTypes.number.isRequired,
    }).isRequired,
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { ListGroup } from 'react-bootstrap';

import { DROPPABLE_PARENT_TYPE } from './constants';
import { QuestionnaireFormattedList } from './QuestionnaireFormattedList';
import { processDragEvent, reorderAndNumber } from './utils';
import { ListError } from '../../../../GovApp';
import { getDndStyle } from '../../../../../constants/styles';

export class QuestionnaireList extends PureComponent {
    static propTypes = {
        change: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        editHandler: PropTypes.func.isRequired,
        fields: PropTypes.shape({
            forEach: PropTypes.func.isRequired,
        }).isRequired,
        formErrors: PropTypes.object,
        isOGThemeEnabledForComponents: PropTypes.bool,
        isTemplate: PropTypes.bool,
        meta: PropTypes.object.isRequired,
        renderQuestionLogicIcon: PropTypes.func,
        showConfirmationSimpleModal: PropTypes.func.isRequired,
        showCreateQuestionnaireModal: PropTypes.func.isRequired,
        showEditModal: PropTypes.func.isRequired,
        showFormErrors: PropTypes.bool,
        tagOptions: PropTypes.array,
        TemplateQuestionAddButton: PropTypes.func,
        templateVariableOptions: PropTypes.array,
        useRawPrompt: PropTypes.bool,
        usesTemplateQuestions: PropTypes.bool,
    };

    static defaultProps = {
        disabled: false,
        showFormErrors: false,
        tagOptions: undefined,
        templateVariableOptions: undefined,
        useRawPrompt: false,
    };

    handleDragEnd = (result) => {
        const { fields, change } = this.props;

        const { destination, source, type: dragType } = result;

        const originLocation = source.index;
        const newLocation = destination ? destination.index : undefined;

        if (newLocation !== undefined && newLocation !== originLocation) {
            // Processes all movement of items in array
            processDragEvent(fields, dragType, originLocation, newLocation);

            // Relies on actions in `processDragEvent` being reflected in store so
            // setTimeout to ensure processDragEvent propagated and there's no race condition
            setTimeout(() => reorderAndNumber(fields, change, dragType), 0);
        }
    };

    render() {
        const { disabled, isOGThemeEnabledForComponents, meta, showFormErrors } = this.props;

        return (
            <div>
                {!isOGThemeEnabledForComponents && (
                    <ListError meta={meta} showError={!!showFormErrors} />
                )}

                <DragDropContext onDragEnd={this.handleDragEnd}>
                    <Droppable
                        droppableId="questionnaireList"
                        isDropDisabled={disabled}
                        type={DROPPABLE_PARENT_TYPE}
                    >
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                style={getDndStyle(snapshot)}
                                {...provided.droppableProps}
                            >
                                <ListGroup>
                                    <QuestionnaireFormattedList {...this.props} />
                                </ListGroup>
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        );
    }
}

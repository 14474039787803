import { createSelector } from 'reselect';

import { sectionTypeNames } from '@og-pro/shared-config/sections';
import { subsectionTypeNames } from '@og-pro/shared-config/subsections';

import { getTemplateFormValues } from '../../../TemplateEdit/selectors';

const { BACKGROUND, BODY, CONTACT, SUMMARY, TIMELINE } = subsectionTypeNames;

const mapScoringCriteria = (evaluationPhases) => {
    return evaluationPhases.flatMap(({ scoringCriteria, title: phaseTitle }, index) => {
        const prefix = phaseTitle || `Phase ${index + 1}`;
        return scoringCriteria.map(({ description, title }) => ({
            content: description,
            title: `[${prefix}] ${title}`,
        }));
    });
};

const mapCriteria = (criteria, projectSection, titleOverride) => {
    const projectSubsection = projectSection.projectSubsections.find(
        ({ subsection_type: subsectionType }) => subsectionType === BODY
    );
    const criteriaKey = `${projectSection.id}_${projectSubsection?.id}`;
    return (criteria[criteriaKey] || []).map(({ description, title }) => ({
        content: description,
        title: titleOverride || title,
    }));
};

export const getCustomVariablesMap = createSelector([getTemplateFormValues], (project) => {
    const getSectionDescription = (projectSection, subsectionType) => {
        const projectSubsection = projectSection.projectSubsections.find(
            ({ subsection_type: type }) => type === subsectionType
        );
        const sectionDescKey = `${projectSection.id}_${projectSubsection?.id}`;
        return project.sectionDescriptions[sectionDescKey]?.description;
    };

    const sectionData = {
        [sectionTypeNames.ATTACHMENTS]: () => [],
        [sectionTypeNames.DIVIDER]: () => [],
        [sectionTypeNames.EVALUATION_CRITERIA]: () => mapScoringCriteria(project.evaluationPhases),
        [sectionTypeNames.EVALUATION_PHASE]: () => mapScoringCriteria(project.evaluationPhases),
        [sectionTypeNames.INTRODUCTION]: (projectSection) => {
            return [
                {
                    content: getSectionDescription(projectSection, SUMMARY),
                    title: 'Summary Header',
                },
                {
                    content: getSectionDescription(projectSection, BACKGROUND),
                    title: 'Background Header',
                },
                {
                    content: getSectionDescription(projectSection, CONTACT),
                    title: 'Contact Header',
                },
                {
                    content: getSectionDescription(projectSection, TIMELINE),
                    title: 'Timeline Header',
                },
                { content: project.summary, title: 'Summary' },
                { content: project.background, title: 'Background' },
            ];
        },
        [sectionTypeNames.PRICING]: () => [],
        [sectionTypeNames.QUESTIONNAIRE]: () =>
            project.questionnaires.map(({ title, prompt }) => ({ content: prompt, title })),
        [sectionTypeNames.SCOPE]: (projectSection) => mapCriteria(project.criteria, projectSection),
        [sectionTypeNames.TERMS]: (projectSection) => mapCriteria(project.criteria, projectSection),
        [sectionTypeNames.TEXT_AREA]: (projectSection) =>
            mapCriteria(project.criteria, projectSection, 'Section Body'),
    };

    return project.upfrontQuestions
        .map((upfrontQuestion) => {
            const projectSections = project.projectSections
                .map((projectSection) => {
                    const data = sectionData[projectSection.section_type];
                    const matches = [];
                    if (data) {
                        const sectionDescription = getSectionDescription(projectSection, BODY);
                        const contentsToSearch = [
                            { content: sectionDescription, title: 'Section Header' },
                            ...data(projectSection),
                        ];
                        contentsToSearch.forEach(({ content, title }, index) => {
                            const hasMatch = content?.includes(
                                `data-templatevariable="customVariable.${upfrontQuestion.id}"`
                            );
                            if (hasMatch) {
                                matches.push(title || `Untitled item #${index + 1}`);
                            }
                        });
                    }
                    return {
                        projectSection,
                        matches,
                    };
                })
                .filter(({ matches }) => matches.length > 0);

            return {
                matchCount: projectSections.flatMap(({ matches }) => matches).length,
                projectSections,
                upfrontQuestion,
            };
        })
        .filter(({ matchCount }) => matchCount > 0);
});

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb as BreadcrumbBS } from 'react-bootstrap';
import classNames from 'classnames';

import { BreadcrumbItem } from './BreadcrumbItem';

export class Breadcrumb extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
    };

    render() {
        const styles = require('./Breadcrumb.scss');
        const { className, ...props } = this.props;

        return <BreadcrumbBS className={classNames(styles.breadcrumb, className)} {...props} />;
    }
}

Breadcrumb.Item = BreadcrumbItem;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import {
    // Questionnaire types
    BID_BOND,
    CONFIRMATION,
    CUSTOM_VARIABLE,
    DOWNLOAD,
    DATA_VERIFY,
    EXTERNAL_FORM,
    FILE_UPLOAD,
    LONG_ANSWER,
    MULTIPLE_CHOICE,
    NOTARIZE,
    SECTION_HEADER,
    SHORT_ANSWER,
    YES_NO,
    // Questionnaire response fields
    QUESTIONNAIRE_RESPONSE,
    RESPONSE_DATA,
    VALUE,
} from '@og-pro/shared-config/questionnaires';

import {
    BidBondResponseForm,
    ConfirmationResponseForm,
    CustomVariableResponseForm,
    DataVerifyResponseForm,
    ExternalFormResponseForm,
    FileUploadResponseForm,
    LongAnswerResponseForm,
    MultipleChoiceResponseForm,
    NotarizeResponseForm,
    ResponseFormPrompt,
    ShortAnswerResponseForm,
    YesNoResponseForm,
} from './components';

export class QuestionnaireResponseForm extends PureComponent {
    static propTypes = {
        checklistId: PropTypes.number,
        fieldName: PropTypes.string.isRequired,
        isTemplateReadonlyView: PropTypes.bool,
        responsePath: PropTypes.string,
        showValidation: PropTypes.bool,
        proposalId: PropTypes.number,
        type: PropTypes.string.isRequired,
    };

    static defaultProps = {
        responsePath: `${QUESTIONNAIRE_RESPONSE}.${RESPONSE_DATA}`,
    };

    get responseFormData() {
        const { fieldName, responsePath, type } = this.props;

        const defaultFieldName = `${fieldName}.${responsePath}.${VALUE}`;

        switch (type) {
            case BID_BOND:
                return [`${fieldName}.${responsePath}`, BidBondResponseForm];
            case CONFIRMATION:
                return [defaultFieldName, ConfirmationResponseForm];
            case CUSTOM_VARIABLE:
                return [defaultFieldName, CustomVariableResponseForm];
            case DATA_VERIFY:
                return [defaultFieldName, DataVerifyResponseForm];
            case EXTERNAL_FORM:
                return [`${fieldName}.${QUESTIONNAIRE_RESPONSE}`, ExternalFormResponseForm];
            case DOWNLOAD:
            case FILE_UPLOAD:
                // NOTE: This questionnaire only works for vendor questionnaire. It needs to be
                // updated to work with upfront questions
                return [`${fieldName}.${QUESTIONNAIRE_RESPONSE}`, FileUploadResponseForm];
            case NOTARIZE:
                // NOTE: This questionnaire only works for vendor questionnaire. It needs to be
                // updated to work with upfront questions
                return [`${fieldName}.${QUESTIONNAIRE_RESPONSE}`, NotarizeResponseForm];
            case LONG_ANSWER:
                return [defaultFieldName, LongAnswerResponseForm];
            case MULTIPLE_CHOICE:
                return [defaultFieldName, MultipleChoiceResponseForm];
            case SHORT_ANSWER:
                return [defaultFieldName, ShortAnswerResponseForm];
            case YES_NO:
                return [defaultFieldName, YesNoResponseForm];
            case SECTION_HEADER:
                return [];
            default:
                return [];
        }
    }

    render() {
        const [fieldName, formComponent] = this.responseFormData;

        if (!fieldName) {
            return <ResponseFormPrompt {...this.props} />;
        }

        return (
            <Field
                component={ResponseFormPrompt}
                formComponent={formComponent}
                name={fieldName}
                {...this.props}
            />
        );
    }
}

import { fieldNames } from '../constants';

const { ABBREVIATION, ICON, NAME, USER_ID } = fieldNames;

export default function validateInfo(values) {
    const errors = {};

    if (!values[NAME]) {
        errors[NAME] = 'Please give your group a name';
    } else if (values[NAME].length > 128) {
        errors[NAME] = 'Name is too long';
    }

    if (!values[ABBREVIATION]) {
        errors[ABBREVIATION] = 'Please give your group an abbreviation';
    } else if (values[ABBREVIATION].length > 32) {
        errors[ABBREVIATION] = 'Abbreviation is too long';
    }

    if (!values[ICON]) {
        errors[ICON] = 'Please select an icon';
    }

    if (!values[USER_ID]) {
        errors[USER_ID] = 'Please select an owner';
    }

    return errors;
}

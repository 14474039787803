import PropTypes from 'prop-types';

export const InputPropTypes = {
    autoFocus: PropTypes.bool,
    characterLimit: PropTypes.number,
    className: PropTypes.string,
    commentIcon: PropTypes.bool,
    commentData: PropTypes.object,
    customChange: PropTypes.func,
    disabled: PropTypes.bool,
    formGroupClassName: PropTypes.string,
    hasFeedback: PropTypes.bool,
    help: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    helpIcon: PropTypes.bool,
    helpIconClick: PropTypes.func,
    helpIconNode: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    id: PropTypes.string,
    input: PropTypes.object.isRequired,
    inputGroupPrefix: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    inputGroupSuffix: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    labelledBy: PropTypes.string,
    meta: PropTypes.object.isRequired,
    minRows: PropTypes.number, // Used for setting min rows of textarea
    overrideFeedback: PropTypes.bool,
    placeholder: PropTypes.string,
    qaTag: PropTypes.string.isRequired, // Format: <pageName>-<simpleDescription>
    showValidation: PropTypes.bool,
    type: PropTypes.string,
    styles: PropTypes.shape({}),
    useOpenGovStyle: PropTypes.bool,
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getPlanholdersJS } from './selectors';
import { getProjectJS } from '../selectors';
import { PlanholdersList, SectionTitle, ZeroState } from '../../../components';

const mapStateToProps = (state) => {
    return {
        planholders: getPlanholdersJS(state),
        project: getProjectJS(state),
    };
};

// @connect
class ConnectedPlanholders extends Component {
    static propTypes = {
        planholders: PropTypes.array,
        project: PropTypes.shape({
            government: PropTypes.shape({
                hidePublicProposalSubmit: PropTypes.bool.isRequired,
            }).isRequired,
            showBids: PropTypes.bool.isRequired,
        }).isRequired,
    };

    render() {
        const { planholders, project } = this.props;

        const showSubmittedProposals = !project.government.hidePublicProposalSubmit;

        return (
            <>
                <SectionTitle
                    info="List of the users who are following updates on this project"
                    title="Followers List"
                />
                {(planholders || []).length === 0 ? (
                    <ZeroState
                        title={
                            planholders
                                ? 'No followers yet'
                                : 'Followers are not publicly available'
                        }
                    />
                ) : (
                    <>
                        <PlanholdersList
                            planholders={planholders}
                            showSubmittedProposals={showSubmittedProposals}
                        />
                        {!project.showBids && showSubmittedProposals && (
                            <p
                                className="text-muted text-center"
                                style={{ fontStyle: 'italic', marginTop: 15 }}
                            >
                                Response submitted column will populate once bid is unsealed
                            </p>
                        )}
                    </>
                )}
            </>
        );
    }
}

export const Planholders = connect(mapStateToProps)(ConnectedPlanholders);
